import { Article } from "@tm/models"
import { mapRimDetailsArticleWithArticleToGenerailItem, mapRimDetailsArticleWithoutArticleToGenerailItem } from "../mapper"
import { RimDetailsArticle } from "../model"


export function mapRimItemForOverview(selectedRimArticle: RimDetailsArticle): Article {
    if (Object.keys(selectedRimArticle.articleDetails).length) {
        return mapRimDetailsArticleWithArticleToGenerailItem(selectedRimArticle)
    }
    else {
        return mapRimDetailsArticleWithoutArticleToGenerailItem(selectedRimArticle)
    }
}