import { Box, Switch, TextField } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { memo, useState, useEffect } from "react"

import { getUIA, validateField } from "@tm/utils"
import { ISaveableOption } from "."
import { getBundleParams } from "../../../../utils"
import { SaveActions } from "./SaveActions"

type Props = ISaveableOption

export const OrderConfirmationOptions = memo(({ isSaving, onSave }: Props) => {
    const { translateText } = useLocalization()
    const [email, setEmail] = useState<string>()
    const [isEmailCorrect, setIsEmailCorrect] = useState(true)
    const { isOrderConfirmationMailRequired } = getBundleParams()

    const { userSettings, changeOrderOptions } = useUser() || {}
    const { wholesalerOrderConfirmation, orderConfirmationEmail } = userSettings?.orderOptions.repairShopResponse || {}

    const [orderConfirmationEnabled, setOrderConfirmationEnabled] = useState<boolean>(wholesalerOrderConfirmation ?? false)

    useEffect(() => {
        onSave(false)
        setOrderConfirmationEnabled(wholesalerOrderConfirmation ?? false)
    }, [onSave, wholesalerOrderConfirmation])

    useEffect(() => {
        onSave(false)
        setEmail(undefined)
    }, [onSave, orderConfirmationEmail])

    function handleChangeOrderConfirmationEnabled(state: boolean) {
        onSave(true)
        setOrderConfirmationEnabled(state)
        changeOrderOptions?.({ wholesalerOrderConfirmation: state })
    }

    function handleSaveEmail() {
        onSave(true)
        changeOrderOptions?.({ orderConfirmationEmail: email })
    }

    function handleResetEmail() {
        setIsEmailCorrect(true)
        setEmail(undefined)
    }

    function handleChangeEmail(value: string) {
        if (value !== undefined && isOrderConfirmationMailRequired) {
            const state = validateField(value).email(translateText(13525)).min(1, translateText(13525))

            setIsEmailCorrect(state.isValid)
        }

        setEmail(value)
    }

    if (wholesalerOrderConfirmation === undefined) {
        return null
    }

    return (
        <Box>
            <Switch
                checked={orderConfirmationEnabled}
                onChange={(e) => handleChangeOrderConfirmationEnabled(e.target.checked)}
                label={translateText(1811)}
                disabled={isSaving}
                {...getUIA("SettingsBasketOrderConfirmation")}
            />
            {orderConfirmationEnabled && (
                <Box ml={6} mt={1} display="flex" alignItems="center">
                    <TextField
                        inputProps={{
                            maxLength: 80,
                        }}
                        inputCounter
                        label={translateText(109)}
                        sx={{ width: "25em" }}
                        size="large"
                        disabled={isSaving}
                        error={!isEmailCorrect}
                        helperText={translateText(13525)}
                        value={email ?? orderConfirmationEmail}
                        onChange={(e) => handleChangeEmail(e.target.value)}
                    />
                    {email !== undefined && (
                        <SaveActions
                            onReset={handleResetEmail}
                            onSave={handleSaveEmail}
                            resetDisabled={isSaving}
                            saveDisabled={isSaving || !isEmailCorrect}
                        />
                    )}
                </Box>
            )}
        </Box>
    )
})
