import { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { combiner, initSelector } from "@tm/utils"
import { Box, Button } from "@tm/components"
import { MainState } from "../main"
import { MainActions } from "../main/business"

export let asWegasTransactionsWindow: Window | null = null

const selector = initSelector(
    (s: MainState) => s.transactions.url,
    (s) => s.transactions.getUrlInProgress,
    (s) => s.transactions.getUrlError,
    (s) => s.transactions.getUrlLoaded,
    combiner.array
)

export default function TransactionsComponent() {
    const { translateText } = useLocalization()
    const contentRef = useRef<HTMLDivElement>(null)
    const actions = useActions(MainActions, "getUrlForPage")
    const [url, getUrlInProgress, getUrlError, getUrlLoaded] = useSelector(selector)

    useEffect(() => {
        if (url && (asWegasTransactionsWindow?.closed || !asWegasTransactionsWindow)) {
            openTool()
        }
    }, [url])

    useEffect(() => {
        if (!url && !getUrlInProgress && !getUrlLoaded) {
            actions.getUrlForPage()
        }
    }, [url, getUrlInProgress, getUrlLoaded])

    const openTool = () => {
        const navigationDom = document?.querySelector(".sg-navigation")

        if (!contentRef?.current || !navigationDom) {
            return
        }

        const opts = `height=${contentRef.current.offsetHeight - navigationDom.clientHeight},width=${
            contentRef.current.offsetWidth
        },location=0,resizable=1,scrollbars=1,left=${window.screenLeft + 15},top=${window.screenTop + 260},status=yes`
        asWegasTransactionsWindow = window.open(url, "_blank", opts)
    }

    return (
        <Box flex="1" width="100%" height="100%" display="flex" flexDirection="column" alignItems="center" className="wrapper" ref={contentRef}>
            <Box display="flex" flex="1" alignItems="center" flexDirection="column" justifyContent="center" className="status">
                {!asWegasTransactionsWindow?.closed && !getUrlInProgress && <Loader type="connection" />}
                {getUrlInProgress && <Loader />}

                {getUrlLoaded && getUrlError && (
                    <Button size="large" onClick={() => actions.getUrlForPage()}>
                        {translateText(401)}
                    </Button>
                )}
            </Box>
        </Box>
    )
}
