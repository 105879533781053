import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../utils"

export async function setNotificationMessagesRead(notificationMessageIds: string[]) {
    const authorization = getStoredAuthorization()
    const bundleParams = getBundleParams()
    if (!bundleParams || !bundleParams.dataManagementServiceUrl) {
        console.log("Failed to determine url for dataManagementService from bundle parameters.")
    } else {
        const url = `${bundleParams.dataManagementServiceUrl}/SetNotificationsRead`
        const body = {
            NotificationMessageIds: notificationMessageIds,
        }

        await ajax({ method: "POST", url, body, authorization })
    }
}
