import {
    Vehicle,
    RepairTimeProvider,
    CustomWork,
    WorkCategory,
    CustomPart,
    ImportRepairEstimationRequest,
    OePart,
    CatalogPart,
    RepairEstimationMainWork,
    IncludedWorkRequest,
    RepairEstimationIncludedWork,
} from "@tm/models"
import { ICalculationItem, Work } from "../../models"

function mapCustomPart(oePart: OePart): CustomPart {
    return {
        articleNumber: oePart.oeArticleNumber,
        description: oePart.description,
        quantityValue: oePart.quantityValue,
        garagePrice: oePart.oePriceValue ?? undefined,
    }
}

function mapIncludedWork(includedWork: IncludedWorkRequest): RepairEstimationIncludedWork {
    return {
        categoryOfWork: includedWork.categoryOfWork,
        description: includedWork.description,
        providerWorkId: includedWork.providerWorkId,
        time: includedWork.time,
        sortNo: includedWork.sortNo,
    }
}

export function createCostEstimationRequest(
    workTaskId: string,
    items: ICalculationItem[],
    works: Work[],
    vehicle?: Vehicle,
    memo?: string
): ImportRepairEstimationRequest {
    const selectedItems = items.filter((x) => x.isSelected)

    const parts: CatalogPart[] = []
    const oeParts: OePart[] = []
    const customParts: CustomPart[] = []
    const mainWorks: RepairEstimationMainWork[] = []
    const customWorks: CustomWork[] = []

    selectedItems?.forEach((item) => {
        if (item.selectedPart) {
            const { ...rest } = item.selectedPart

            parts.push({
                ...rest,
                quantityValue: item.oeArticle.quantityValue,
                memo,
            })
        } else if (!item.oeArticle.oeArticleNumber) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeParts.push({ ...item.oeArticle, memo, oePriceValue: item.oeArticle.oePriceValue || undefined })
        }
    })

    works?.forEach((work) => {
        if (work.providerWorkId?.length) {
            mainWorks.push({
                providerWorkId: work.providerWorkId,
                hourlyRate: work.hourlyRate,
                categoryOfWork: work.categoryOfWork,
                description: work.description,
                time: work.time,
                sortNo: work.sortNo,
                includedWorks: work.includedWorks?.map(mapIncludedWork),
            })
        } else {
            customWorks.push({
                customWorkNumber: "",
                categoryOfWork: WorkCategory.NotCategorized,
                description: work.description,
                time: work.calculatedTime,
                hourlyRate: work.hourlyRate,
                fixedPriceValue: work.total,
            })
        }
    })

    return {
        workTaskId,
        vehicleId: vehicle?.id ?? "",
        oeParts,
        parts,
        customParts,
        works: mainWorks,
        customWorks,
        repairTimesProvider: RepairTimeProvider.None,
    }
}
