import { memo, useEffect, useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, FileDropField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"

type Props = {
    onImageUpload(image: string): void
    disabled?: boolean
}

function FileUpload(props: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])
    const [clipboardError, setClipboardError] = useState(false)

    const handleLoad = (fileData: string) => {
        fileData && props.onImageUpload(fileData)
    }

    useEffect(() => {
        if (clipboardError) {
            const timer = setTimeout(() => {
                setClipboardError(false)
            }, 5000)

            return () => clearTimeout(timer)
        }
    }, [clipboardError])

    return (
        <FileDropField
            className={classes(style.fileDropField, props.disabled ? style.disabled : "")}
            allowedFileTypes={["jpg", "png"]}
            maxFileSize={100000}
            onLoad={handleLoad}
        >
            <Button icon="upload" className={style.button}>
                {translateText(12621)}
            </Button>
        </FileDropField>
    )
}

export default memo(FileUpload)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        fileDropField: {
            padding: 0,
            width: "unset",
        },
        button: {
            padding: ".8rem 1rem",
        },
        disabled: {
            pointerEvents: "none",
            opacity: theme.opacity.disabled,
        },
    })(FileUpload)
}
