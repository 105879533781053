import { Stack, styled } from "@tm/components"

export const WrappedStack = styled(Stack)({
    flexWrap: "wrap",
    flexDirection: "row",
    marginBottom: 4,
    ">:not(style)": {
        margin: "0 4px 4px 0",
    },
})
