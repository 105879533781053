import { channel } from "@tm/models"
import { useState, useEffect } from "react"

// TODO Basket V2: check if these messages can be removed, to properly update the orderGroups not all warktasks
export function useOrderSending(workTaskId?: string) {
    const [orderSending, setOrderSending] = useState(false)

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENDING", () => {
                setOrderSending(true)
            })
        )

        unsubscriptions.push(
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENT", () => {
                setOrderSending(false)
            })
        )

        unsubscriptions.push(
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENT_ERROR", () => {
                setOrderSending(false)
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTaskId])

    return { orderSending, setOrderSending }
}
