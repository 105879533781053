import { useRef } from "react"
import { Button, Icon } from "@tm/components"
import { QuestionPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VoucherType } from "@tm/models"
import { bundleChannel } from "../../../../../bundle-channel"
import { useActionButtonStatus } from "../../../../../data/hooks/useActionButtonStatus"

export default function OrderActionButtons() {
    const { translateText } = useLocalization()
    const canBeOrdered = useActionButtonStatus(VoucherType.Order)
    const dialogRef = useRef<QuestionPrompt>(null)

    const handleOrderParts = (newWorkTask: boolean) => {
        bundleChannel().publish("ADD_ORDER_VOUCHER_TO_BASKET", { newWorkTask })
    }

    return (
        <>
            <Button
                color="highlight"
                startIcon={<Icon name="cart" />}
                disabled={!canBeOrdered}
                onClick={() => dialogRef.current?.show()}
                title={translateText(937)}
            >
                {translateText(133)}
            </Button>
            <QuestionPrompt
                text={translateText(1226)}
                confirmationButtonText={translateText(1228)}
                cancelButtonText={translateText(1227)}
                ref={dialogRef}
                onConfirm={() => handleOrderParts(true)}
                onCancel={() => handleOrderParts(false)}
            />
        </>
    )
}
