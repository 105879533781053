import * as React from "react"
import { styled } from "@tm/components"
import { useStyle } from "@tm/context-distribution"
import { Tag, TagIcon, Text, TextModifiers } from "@tm/controls"
import { LocalizationProps, useLocalization } from "@tm/localization"
import { AttributeAction, ModificationState, channel } from "@tm/models"
import { classes } from "@tm/utils"
import { bundleChannel } from "../../../bundle-channel"
import { KeyValueItemProps, KeyValueItemValue } from "../../../data/model"
import { mapTooltipArrayToElement } from "../../_shared/attribute-tooltip"

type Props = KeyValueItemProps & LocalizationProps & { className: string }

const KeyValueItem: React.FC<Props> = ({ className, description, value, bold, highlight }) => {
    const { translateText } = useLocalization()

    const renderValue = (value: KeyValueItemValue, idx?: number) => {
        if (typeof value === "string" || !value.asTag) {
            const valueModifiers: Array<TextModifiers> = ["block"]
            if (bold) {
                valueModifiers.push("strong")
            }
            if (highlight) {
                valueModifiers.push("highlight")
            }

            return (
                <Text key={idx} modifiers={valueModifiers}>
                    {typeof value === "string" ? value : value.text}
                </Text>
            )
        }

        let className = "article__attribute"
        if (bold) {
            className += " article__attribute--bold"
        }

        const icons: Array<TagIcon> = []

        switch (value.modificationState) {
            case ModificationState.Added:
                icons.push({ name: "plus", tooltip: translateText(883) })
                className += " article__attribute--added"
                break
            case ModificationState.Removed:
                icons.push({ name: "minus", tooltip: translateText(884) })
                className += " article__attribute--removed"
                break
            default:
                break
        }

        const tooltip = mapTooltipArrayToElement(value.tooltips)

        const textModifiers: Array<TextModifiers> = []
        if (bold) {
            textModifiers.push("strong")
        }
        if (highlight) {
            textModifiers.push("highlight")
        }

        let onClick
        switch (value.attributeAction) {
            case AttributeAction.DirectSearch:
                onClick = () => {
                    channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
                        forceReload: true,
                        inModal: true,
                        direct: { query: value.text },
                    })
                }
                break
            case AttributeAction.OpenAccessoryList:
                onClick = () => {
                    bundleChannel("DETAILS").publish("NAVIGATE", { subPage: "accessories" })
                }
                break
            case AttributeAction.OpenPartsList:
                onClick = () => {
                    bundleChannel("DETAILS").publish("NAVIGATE", { subPage: "partslist" })
                }
                break
            default:
                break
        }

        return (
            <Tag
                key={idx}
                className={className}
                layout={value.highlight ? "holo" : "ghost"}
                skin={value.highlight ? "highlight" : undefined}
                value={{ value: value.text, textModifiers }}
                icons={icons}
                tooltip={tooltip}
                onClick={onClick}
            />
        )
    }

    let content
    let isRemoved = false

    if (Array.isArray(value)) {
        content = value.map(renderValue)
        isRemoved = value.every((x) => typeof x !== "string" && x.modificationState === ModificationState.Removed)
    } else if (value) {
        content = renderValue(value)

        if (typeof value !== "string") {
            isRemoved = value.modificationState === ModificationState.Removed
        }
    }

    const modifiers: Array<TextModifiers> = []
    if (bold) {
        modifiers.push("strong")
    }
    if (highlight) {
        modifiers.push("highlight")
    }

    return (
        <>
            <div className="key-value-list__key-cell">
                <Text className={isRemoved ? "article__attribute--removed" : undefined} modifiers={modifiers}>
                    {description}
                </Text>
            </div>
            <div className={classes(style.valueCell, "key-value-list__value-cell", className)}>{content}</div>
        </>
    )
}

// using styled to be able to use the theme variable if needed
const StyledKeyValueItem = styled(KeyValueItem)(({ valuesAsList }) => {
    if (!valuesAsList) {
        return {}
    }
    return {
        "& .text:not(:last-child)": {
            marginBottom: "0.5em",
            borderBottom: "4px solid #e1e1e1",
            paddingBottom: "0.5em",
            marginRight: "-11px",
        },
    }
})

const style = useStyle({
    valueCell: {
        $nest: {
            ".text": {
                overflow: "unset",
            },
        },
    },
})(KeyValueItem)

export default StyledKeyValueItem
