
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"

export function getSvgImage(imageId: string) {
    const url = `${getServiceUrl()}/GetSvgImage`
    const authorization = getStoredAuthorization()
    const body = { imageId }

    return new Promise<string>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then(
            response => {
                    resolve(response)
            },
            reject
        ))
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.eurotaxGraphicSelectionUrl
}
