import { ReactNode, useMemo } from "react"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { ChatInfo, useOwnUserId } from "../../../data/hooks"
import { getIconNameByMimeType, getTextByMimeType } from "../helper"
import { getComponentStyles } from "./styles"

type Props = {
    chat: ChatInfo
    size: "s" | "m"
    className?: string
    bold?: boolean
}

export default function PreviewText({ chat, size, className, bold }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()
    const ownUserId = useOwnUserId()

    let pretext = ""
    let icon
    let text: ReactNode = <>&nbsp;</>

    if (chat.lastMessage) {
        const message = chat.lastMessage

        if (message.authorId === ownUserId) {
            pretext = `${translateText(3099)}: `
        } else if (chat.users.length > 1) {
            const found = chat.users.find((u) => u.userId === message.authorId)?.displayName
            if (found) {
                pretext = `${found}: `
            }
        }

        if (message.attachment) {
            icon = getIconNameByMimeType(message.attachment.mimeType)
        }

        if (message.text) {
            text = message.text
        } else if (message.attachment) {
            text = translateText(getTextByMimeType(message.attachment.mimeType))
        }
    }

    return (
        <>
            <Text modifiers={["strong", "block"]} size={size === "m" ? "l" : "m"}>
                {chat.users.map((u) => u.displayName).join(", ")}
            </Text>
            <div className={classes(classNames.main, className)}>
                <Text modifiers={bold ? ["strong"] : undefined} size={size} className={pretext ? classNames.pretext : undefined}>
                    {pretext}
                </Text>
                {icon ? <Icon name={icon} size={size} className={classNames.icon} /> : null}
                <Text modifiers={bold ? ["strong"] : undefined} size={size} className={classNames.text}>
                    {text}
                </Text>
            </div>
        </>
    )
}
