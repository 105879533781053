import { BundleMicro } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const workTaskInfo: BundleMicro = {
    name: "worktask-info",
    reduce,
    component,
}

export default workTaskInfo
