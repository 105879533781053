import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduceUniversalTagBaseState } from "../../business"

const ExternalCatalogs: BundleComponent = {
    name: "external-catalogs",
    component,
    reduce: reduceUniversalTagBaseState,
}

export default ExternalCatalogs
