import { NestedCSSProperties } from "typestyle/lib/types"
import { useTheme } from "@tm/components"

export function getComponentStyles(): NestedCSSProperties {
    const theme = useTheme()

    return {
        opacity: theme.opacity?.primary,
        padding: `${theme?.margin?.s} ${theme?.margin?.m}`,
        color: theme?.overwrites?.components?.worktaskModuleNavigation?.foregroundColor,
        $nest: {
            "&:before": { display: "none" },
            ".toolbar__title": {
                marginBottom: "0.2em",
                color: theme?.overwrites?.components?.worktaskModuleNavigation?.foregroundColor,
            },
            ".toolbar__inner": {
                borderLeft: `1px solid rgba(0,0,0,0.3)`,
                borderRight: `1px solid rgba(0,0,0,0.3)`,
            },
        },
    }
}
