import { combineReducers } from "redux"
import { Actions as detailsActions, ComponentActionType as DetailsComponentActionType, reduce as detailsReducer } from "../../details/business"
import { Actions as summaryActions, ComponentActionType as SummaryComponentActionType, reduce as summaryReducer } from "../../summary/business"
import { Actions as navigationActions, ComponentActionType as NavigationComponentActionType, reduce as navigationReducer } from "../../navigation/business"
import { Actions as managerActions, ComponentActionType as ManagerComponentActionType, reduce as managerReducer } from "../../moduleManager/business"

import deepAssign from "deep-assign"
import { MainState } from ".."
import { clone } from "@tm/utils"

export const combinedReducer = combineReducers({
    manager: managerReducer,
    navigation: navigationReducer,
    details: detailsReducer,
    summary: summaryReducer,
    loading: (s = true) => s
})

export const reduce = (state: MainState, action: MainActionsType) => {
    if (action.type == "STATE_LOADED") {
        const newState = clone(deepAssign(state, action.payload))
        // console.log(newState)

        return {
            ...newState,
            loading: false
        }
    }
    return combinedReducer(state, action)
}


export type IMainActions = typeof MainActions

export const MainActions = {
    ...managerActions,
    ...detailsActions,
    ...navigationActions,
    ...summaryActions
}


export * from "./selectors"

export type MainActionsType = SummaryComponentActionType | NavigationComponentActionType |
    DetailsComponentActionType | SummaryComponentActionType | ManagerComponentActionType