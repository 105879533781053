import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSendTruckDataToNext(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { sendTruckDataToNext } = data

    if (sendTruckDataToNext) {
        this.props.setWorkTaskTruckData?.({
            vehicleNr: sendTruckDataToNext.setVehicleNr,
            plateNr: sendTruckDataToNext.setPlateNr,
            customerName: sendTruckDataToNext.setCustomerName,
        })
    }
}
