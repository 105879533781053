import { useLocalization } from "@tm/localization"
import { Text, Headline } from "@tm/controls"
import { Stack, Switch } from "@tm/components"
import { useMemo } from "react"
import { SubUser, Permission } from "../../../data/model"

type Props = {
    title: string
    hint?: string
    permissions: Permission[]
    user: SubUser
    setUser(user: SubUser): void
}

export default function SubUserPermissionGroup(props: Props) {
    const { translateText, isTranslationId } = useLocalization()

    const { user, setUser, permissions, hint, title } = props

    const translatedPermissions = useMemo(() => {
        return permissions
            .map((permission) => ({ ...permission, name: isTranslationId(permission.name) ? translateText(permission.name) : permission.name }))
            .orderBy((permission) => permission.name)
    }, [translateText, isTranslationId, permissions])

    function handleModuleChange(key: string, state: boolean) {
        if (!state) {
            if (!user.revokedRights) {
                setUser({
                    ...user,
                    revokedRights: [key],
                })
            } else if (!user.revokedRights.includes(key)) {
                setUser({
                    ...user,
                    revokedRights: [...user.revokedRights, key],
                })
            }
        } else if (user.revokedRights) {
            setUser({
                ...user,
                revokedRights: user.revokedRights.filter((value) => value !== key),
            })
        }
    }

    return (
        <Stack p={1.5}>
            <Headline size="s">{title}</Headline>
            {hint ? <Text>{hint}</Text> : <></>}
            {translatedPermissions.map((permission) => {
                return (
                    <Switch
                        key={permission.key}
                        label={permission.name}
                        onChange={(state) => handleModuleChange(permission.key, state.target.checked)}
                        checked={!user.revokedRights?.includes(permission.key)}
                    />
                )
            })}
        </Stack>
    )
}
