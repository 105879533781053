import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Stack, Typography } from "@tm/components"
import { useHistory } from "react-router-dom"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { ConfigParams } from "@tm/models"
import ProductAreaTile from "./components/product-area-tile"

const bgrImgBaseUrl = "https://wmkat.de/Content/img/next/"

export default function ProductAreaComponent() {
    const history = useHistory()
    const { language, translateText } = useLocalization()
    const { userContext } = useUser() || {}
    const isDK = userContext?.system.traderDescription === "WMKAT+ DK"
    const { hideDigitalVKHTile } = Morpheus.getParams<ConfigParams>()

    function openWindowInNewTab(url: string) {
        window.open(url, "_blank")
    }

    function openUniSeachTree(nodeId: number) {
        history.push(renderRoute(`/:workTaskId/parts/universal/list/uninode?treeId=90&nodeId=${nodeId}`, { workTaskId: encodeUniqueId(uniqueId()) }))
    }

    let trader = "wm"
    let activeModule = "tyre-search"
    if (userContext) {
        // eslint-disable-next-line prefer-destructuring
        const token = userContext.externalAuthenticationTokens.WMPortalToken
        token !== undefined ? (trader = userContext.externalAuthenticationTokens.WMPortalToken.split("¬")[0]) : ""
        activeModule = userContext.modules?.some((x) => x.description === "TMTires") ? "tyres" : activeModule
    }

    return (
        <Stack direction="column" width="100%" margin="15px 0">
            <Typography variant="h2" color="#009866" ml="1%" mb="16px">
                {translateText(12438)}
            </Typography>
            <Stack direction="row" justifyContent="center">
                {!isDK && (
                    <ProductAreaTile
                        displayText={translateText(13189)}
                        onClick={() =>
                            openWindowInNewTab(
                                language === "nl"
                                    ? "https://www.wm.de/nl/service/werkplaatsplanning.html"
                                    : "https://www.wm.de/de/service/werkstattplanung.html"
                            )
                        }
                        imgSrc={`${bgrImgBaseUrl}werkstatt_bg.png`}
                        hoverText={translateText(13202)}
                    />
                )}
                <ProductAreaTile
                    displayText={translateText(13190)}
                    onClick={() => openUniSeachTree(2098069)}
                    imgSrc={`${bgrImgBaseUrl}Teaser_Lack_uLackzubehoer.png`}
                    hoverText={!isDK ? translateText(13204) : undefined}
                />
                {!hideDigitalVKHTile && (
                    <ProductAreaTile
                        displayText={translateText(!isDK ? 13362 : 13422)}
                        onClick={() => {
                            openWindowInNewTab(
                                !isDK ? "https://shop.wm.de/" : "https://tradium.dk/voksen-og-efteruddannelse/kursusudbud/auto-kursus/"
                            )
                        }}
                        imgSrc={!isDK ? `${bgrImgBaseUrl}DVKH.png` : "/styles/wm/images/wm-dk-tradium.jpg"}
                        hoverText={!isDK ? translateText(13363) : undefined}
                    />
                )}
                <ProductAreaTile
                    displayText={translateText(13191)}
                    onClick={() => openUniSeachTree(2097690)}
                    imgSrc={`${bgrImgBaseUrl}Teaser_Werkzeuge.png`}
                    hoverText={!isDK ? translateText(13206) : undefined}
                />
                <ProductAreaTile
                    displayText={translateText(13192)}
                    onClick={() =>
                        history.push(
                            renderRoute(`/:workTaskId/${activeModule}`, {
                                workTaskId: encodeUniqueId(uniqueId()),
                            })
                        )
                    }
                    imgSrc={`${bgrImgBaseUrl}RaederReifen.png`}
                    hoverText={!isDK ? translateText(13208) : undefined}
                />
                <ProductAreaTile
                    displayText={translateText(13193)}
                    onClick={() => openUniSeachTree(2097646)}
                    imgSrc={`${bgrImgBaseUrl}Teaser_Chemie.png`}
                    hoverText={!isDK ? translateText(13209) : undefined}
                />
            </Stack>
        </Stack>
    )
}
