import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Badge, Box, Loader, Switch, Tooltip } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useEffect, useState } from "react"
import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { getBundleParams } from "../../utils"

type Props = {
    checked: boolean
    loading: boolean
    onToggle: (value: boolean) => void
}

function CompactViewSwitch({ checked, loading, onToggle }: Props) {
    const { translateText } = useLocalization()

    return (
        <Box sx={{ height: "100%" }}>
            <Toolbar className="compact-view-switch" title={translateText(3009)}>
                <Tooltip disableHoverListener={loading} title={translateText(1804)}>
                    <Box>
                        {!loading && <Switch checked={checked} onChange={(e) => onToggle(e.target.checked)} />}
                        {loading && <Badge badgeContent={<Loader size="extrasmall" />} sx={{ top: "0em", left: "1.5em" }} />}
                    </Box>
                </Tooltip>
            </Toolbar>
        </Box>
    )
}

function CompactViewSwitchUserSettingsWrapper() {
    const { setUserSetting, userSettings } = useUser() || {}
    const { compactViewAsDefault } = getBundleParams()
    const [isChecked, setChecked] = useState<boolean>(compactViewAsDefault)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (userSettings?.articleListSettings?.viewOptions?.compactView !== undefined) {
            setChecked(userSettings?.articleListSettings?.viewOptions?.compactView)
        }
    }, [userSettings])

    useEffect(() => {
        setLoading(false)
    }, [isChecked])

    if (userSettings === undefined) {
        return null
    }

    function handleToggle(value: boolean) {
        if (userSettings) {
            const articleListSettings = userSettings.articleListSettings || { viewOptions: {} }
            articleListSettings.viewOptions = articleListSettings.viewOptions || {}

            TmaHelper.GeneralCountEvent.Call(value ? ECounterType.ArticleListShowCompact : ECounterType.ArticleListShowDetailed)

            setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...articleListSettings,
                viewOptions: {
                    ...articleListSettings.viewOptions,
                    compactView: value,
                },
            })

            setLoading(true)
        }
    }

    return <CompactViewSwitch checked={isChecked} loading={loading} onToggle={handleToggle} />
}

export default CompactViewSwitchUserSettingsWrapper
