import { WheelsBundleParams } from "@tm/models"
import Morpheus from "@tm/morpheus"

export const version = {
    name: "wheels",
    version: "1.0.0",
}

export function initWheelsBundle(params: WheelsBundleParams) {

    params.showSupplierLogos = Morpheus.getParams("parts")?.showSupplierLogos
    params.tyresPageSize = +Morpheus.getParams("tyres").pageSize || 25
    params.wheelsPagingSize = +Morpheus.getParams("wheels").pageSize || 30
    params.maxAutoRequest = params.maxAutoRequest || 3
    params.minAvailableItems = params.minAvailableItems || 4
    params.haynesProTdGenartsRoute = Morpheus.getParams("parts")?.haynesProTdGenartsRoute
    params.tecrmiTdGenartsRoute = Morpheus.getParams("parts")?.tecrmiTdGenartsRoute
    params.partsServiceUrl = Morpheus.getParams("parts")?.partsServiceUrl
    params.traderReferenceId = params.traderReferenceId
    params.showIndustrialAvailability = !!+Morpheus.getParams("wheels").showIndustrialAvailability
    params.wheelsHistoryServiceUrl = Morpheus.getParams("wheels").wheelsHistoryServiceUrl
    params.priceAvailabilityInformation = !!+Morpheus.getParams("wheels").priceAvailabilityInformation
    params.imagesPath = Morpheus.getParams("wheels").imagesPath

    bundleParams = params

    return bundleParams
}

let bundleParams: WheelsBundleParams

export function getBundleParams() {
    if (!bundleParams) {
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}
