import { Article } from "@tm/models"
import { PropsWithChildren, memo } from "react"
import { ArticleItemStateProvider } from "@tm/components"
import { useArticleItem } from "../../hooks/useArticleItem"

export type DefaultArticleItemStateProviderProps = PropsWithChildren<{
    article: Article
}>

export const DefaultArticleItemStateProvider = memo(({ article, children }: DefaultArticleItemStateProviderProps) => {
    const state = useArticleItem(article)

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
})
