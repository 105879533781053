import { IBundle } from "@tm/morpheus"
import OrderHistoryTotals from "./components/order-history-totals"
import VouchersList from "./components/vouchers-list"
import { WidgetBundle, Widget } from "./components/widget"
import { initVouchersBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [WidgetBundle, OrderHistoryTotals, VouchersList],
    init: initVouchersBundle,
}

export default bundle

// export available to be used in other bundles
export {
    //
    WidgetBundle,
    Widget,
}
