import { useWorkTask } from "@tm/context-distribution"
import { useCallback } from "react"
import { WholesalerPart } from "@tm/models"
import { useBasketParts } from "../../../../../../basket/src/hooks/basketState/useBasketParts"

export function useHandleAddWholesalerArticleNumberToBasket(part: WholesalerPart | undefined) {
    const { workTaskId, workTask } = useWorkTask() ?? {}

    const { addWholesalerPartList } = useBasketParts()

    const handleAddToBasket = useCallback(
        (quantity: number) => {
            if (!workTaskId || !part) {
                return Promise.reject()
            }

            const request = {
                vehicleId: workTask?.vehicle?.id,
                workTaskId,
                wholesalerParts: [{ ...part, quantityValue: quantity }],
                usePercentageValues: true,
                log: undefined,
            }

            return addWholesalerPartList(request)
        },
        [workTaskId, part, workTask?.vehicle?.id, addWholesalerPartList]
    )
    return handleAddToBasket
}
