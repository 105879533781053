import { Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { clone, equals } from "@tm/utils"
import { DetailsState } from "."
import * as Repositories from "../../../data"
import { ESufficentSelections } from "../../../data/enums"
import { addOrRemoveItem, getCommonItems } from "../../../data/helpers"
import { Criteria, ErePosition, ImagePosition, MmtGroup, Part, PartsGroup, Pictogram, VKNImage } from "../../../data/models"
import { GetCarExtendedInfoResponse } from "../../../data/repositories"
import { MainActionsType, MainState } from "../../main"
import { createCarExtendedRequest, createEreLinkImagesRequest, createGetPartsRequest, createImagePositionRequest } from "./helpers"
import { batch } from "react-redux"
import { getBundleParams } from "../../../utils"
export * from "./model"


export type ComponentActionType =
	| { type: "VKN_IMAGES_LOADING" }
	| { type: "VKN_IMAGES_ERROR" }
	| { type: "VKN_IMAGES_LOADED", payload: { fromModal?: boolean, items?: VKNImage[], fromRestore?: true, vknCriterias: Criteria[], sufficientSelection: ESufficentSelections, vehicle?: Vehicle } }
	| { type: "SELECT_VKN_IMAGE", payload: VKNImage, vehicle?: Vehicle }

	| { type: "CAR_INFO_EXTENDED_LOADING" }
	| { type: "CAR_INFO_EXTENDED_ERROR" }
	| { type: "CAR_INFO_EXTENDED_LOADED", payload: GetCarExtendedInfoResponse, vehicle?: Vehicle, selectedMmtGroup?: MmtGroup }

	| { type: "PARTS_LOADED", payload: PartsGroup[] }
	| { type: "PARTS_LOADING" }
	| { type: "PARTS_ERROR" }
	| { type: "SELECT_PART_ITEM", payload: Part }

	| { type: "UPDATE_VKN_CRITERIA", payload: { criteria: Criteria, isCheckbox?: boolean, vehicle?: Vehicle } }
	| { type: "UPDATE_BASE_CRITERIA", payload: { criteria: Criteria, isCheckbox?: boolean, vehicle?: Vehicle } }
	| { type: "RESET_VKN_CRITERIA_GROUP", payload: { group: string, vehicle?: Vehicle } }
	| { type: "RESET_BASE_CRITERIA_GROUP", payload: { group: string, vehicle?: Vehicle } }
	// | { type: "RESET_ALL_CRITERIAS" }

	| { type: "IMAGE_POSITIONS_LOADING" }
	| { type: "IMAGE_POSITIONS_ERROR" }
	| { type: "IMAGE_POSITIONS_LOADED", payload: { imageName: string, positions: ImagePosition[], criteria: Criteria[], pictograms: Pictogram[], vehicle?: Vehicle } }
	| { type: "SELECT_IMAGE_POSITION", payload: ImagePosition }

	| { type: 'UPDATE_FIELDS', payload: { path: string, value: any } }
	| { type: 'UPDATE_MMT_LIST' }

	| { type: "SVGIMAGE_LOADING" }
	| { type: "SVGIMAGE_LOADED", payload: string }
	| { type: "SVGIMAGE_ERROR" }


const DEFAULT_STATE: DetailsState = {
	selectedCriterias: [],
	savedCriterias: {},
	savedSelectedValues: {},
	criterias: {
		base: [],
		vkn: []
	},
	vknImages: {
		items: [],
	},
	parts: {
		items: []
	},
	mmtList: {
		items: []
	},
	extendedCarInfo: {},
	selectedValues: {},
}

export function reduce(state = clone(DEFAULT_STATE), action: MainActionsType): DetailsState {
	switch (action.type) {
		case "VKN_IMAGES_LOADING": {
			return {
				...state,
				vknImages: {
					...state.vknImages,
					loading: true,
					error: false
				},
				criterias: {
					...state.criterias,
					loading: true,
					error: false
				},
				selectedVKNImage: undefined
			}
		}
		case "VKN_IMAGES_ERROR": {
			return {
				...state,
				vknImages: {
					...state.vknImages,
					loading: false,
					items: [],
					error: true
				},
				criterias: {
					...state.criterias,
					error: true,
					vkn: [],
					loading: false
				},
				selectedVKNImage: undefined
			}
		}
		case "VKN_IMAGES_LOADED": {
			const { items, vknCriterias, sufficientSelection, fromModal, vehicle } = action.payload
			const { savedCriterias } = state
			const startVKNItems = items?.filter(x => x.isStartImage) ?? []


			const saved = savedCriterias[vehicle?.tecDocTypeId!]
			const communeFilters = getCommonItems(saved ?? [], vknCriterias ?? [], (x, y) => x.code == y.code)

			const newSelectedVknImage = startVKNItems?.find(x => x.criteria?.code == communeFilters?.[0]?.code)
			return {
				...state,
				vknImages: {
					...state.vknImages,
					loading: false,
					items: items ?? [],
					error: false,
					sufficientSelection: sufficientSelection
				},
				criterias: {
					...state.criterias,
					vkn: vknCriterias,
					base: [],
					error: !vknCriterias.length,
					loading: false
				},

				...!fromModal && {
					selectionAvailable: sufficientSelection && sufficientSelection != ESufficentSelections.Distinct
				},
				...!items?.length && {
					extendedCarInfo: {
						...state.extendedCarInfo,
						loading: true,
					}
				},
				// ...sufficientSelection && sufficientSelection == ESufficentSelections.Distinct &&
				// equals(state.mmtList.items, state.selectedMmtGroup?.mmtList) && {
				// 	selectionAvailable: false
				// },

				// selectedValues: DEFAULT_STATE.selectedValues,
				...startVKNItems.length == 1 && sufficientSelection == ESufficentSelections.Distinct && // SELECT FIRST ONE
				{
					selectedVKNImage: startVKNItems.first(),
					criterias: {
						...state.criterias,
						loading: true,
						error: false,
						forceShowVknCriterias: false
					},
					selectedCriterias: savedCriterias[startVKNItems[0].imageName] ?? [],
				},
				...startVKNItems.length > 1 && {
					selectedCriterias: communeFilters ?? [],
					selectedVKNImage: newSelectedVknImage,
					criterias: {
						...state.criterias,
						vkn: vknCriterias,
						base: [],
						error: !vknCriterias.length,
						loading: false,
						forceShowVknCriterias: newSelectedVknImage?.isStartImage,
					},
				}
			}
		}
		case "SELECT_VKN_IMAGE": {
			const { vehicle } = action
			let saved = clone(state.savedCriterias[vehicle?.tecDocTypeId!]) ?? []
			let selectedCriterias = state.selectedCriterias
			const criteria = state.criterias.vkn.find(x => x.code == action.payload.criteria?.code)
			if (criteria) {
				selectedCriterias = [...selectedCriterias, criteria]
				saved = [...saved, criteria]
			}

			return {
				...state,
				selectedVKNImage: action.payload,
				parts: {
					...state.parts,
					items: []
				},
				selectedCriterias,
				selectedPartItem: undefined,
				criterias: {
					...state.criterias,
					base: [],
					loading: true,
					forceShowVknCriterias: state.vknImages.items.filter(x => x.isStartImage).length > 1 && action.payload.isStartImage
				},
				savedCriterias: {
					...state.savedCriterias,
					[vehicle?.tecDocTypeId!]: saved
				},
			}
		}


		case "CAR_INFO_EXTENDED_LOADING": {
			return {
				...state,
				extendedCarInfo: {
					...state.extendedCarInfo,
					loading: true,
					error: false,
				}
			}
		}
		case "CAR_INFO_EXTENDED_ERROR": {
			return {
				...state,
				extendedCarInfo: {
					...state.extendedCarInfo,
					loading: false,
					error: true,

				}

			}
		}
		case "CAR_INFO_EXTENDED_LOADED": {
			const { carMMTs, registrationDate, initialRegistrationDate, equipmentLine, sufficientSelection, landCertificates, make, model, transmissionType, type, constructionPeriods } = action.payload

			const { selectedMmtGroup } = action

			const selectedValues = {
				...state.selectedValues,
				//if selectedValue is not in response -> set selectedValue to undefind
				initialReg: initialRegistrationDate,
				...state.selectedValues.constructionPeriod &&
				!constructionPeriods?.find(x => equals(state.selectedValues.constructionPeriod, x)) && { constructionPeriod: undefined },
				...state.selectedValues.kbaNo && !landCertificates?.includes(state.selectedValues.kbaNo) && { kbaNo: undefined },
				...state.selectedValues.make && !make?.includes(state.selectedValues.make) && { make: undefined },
				...state.selectedValues.equipmentLine && !equipmentLine?.includes(state.selectedValues.equipmentLine) && { equipmentLine: undefined },
				...state.selectedValues.model && !model?.includes(state.selectedValues.model) && { model: undefined },
				...state.selectedValues.transmissionType && !transmissionType?.includes(state.selectedValues.transmissionType) && { transmissionType: undefined },
				...state.selectedValues.type && !type?.includes(state.selectedValues.type) && { type: undefined },
			}

			return {
				...state,
				extendedCarInfo: {
					loading: false,
					error: false,
					...action.payload,
					registrationDate: registrationDate
				},
				selectedValues: selectedValues,
				...sufficientSelection != ESufficentSelections.Distinct && {
					selectionAvailable: true
				},
				savedSelectedValues: {
					...state.savedSelectedValues,
					[selectedMmtGroup?.svgEre ?? ""]: selectedValues,
				},
				mmtList: {
					sufficientSelection,
					items: carMMTs ?? []
				}
			}
		}
		case "IMAGE_POSITIONS_LOADING": {
			return {
				...state,
				selectedVKNImage: {
					...state.selectedVKNImage!,
					positionsLoading: true
				},
				criterias: {
					...state.criterias,
					loading: true
				}
			}
		}
		case "IMAGE_POSITIONS_ERROR": {
			return {
				...state,
				selectedVKNImage: {
					...state.selectedVKNImage!,
					positionsLoading: false,
					positions: [],
					error: true
				},
				criterias: {
					...state.criterias,
					error: true,
					loading: false
				}
			}
		}
		case "IMAGE_POSITIONS_LOADED": {
			const { criteria: criterias, pictograms, positions, imageName, vehicle } = action.payload
			const selectedCriterias = getCommonItems(state.savedCriterias[vehicle?.tecDocTypeId!] ?? [], criterias,
				(item1, item2) => item1.code == item2.code)

			return {
				...state,
				selectedVKNImage: {
					...state.selectedVKNImage!,
					imageName,
					positions,
					pictograms,
					criteriaError: !criterias.length,
					positionsLoading: false
				},
				criterias: {
					...state.criterias,
					loading: false,
					error: !criterias.length,
					base: criterias
				},
				selectedCriterias
			}
		}
		case "SELECT_IMAGE_POSITION": {
			return {
				...state,
				selectedImagePosition: action.payload
			}
		}
		case "SEND_DATA_TO_EUROTAX_DETAILS": {
			const vehicle = clone(action.payload.vehicle)
			return {
				...state,
				// mmtList: action.payload.selectedMmtGroup.mmtList ?? [],
				// selectedMmtGroup: action.payload.selectedMmtGroup,
				vknImages: {
					...state.vknImages,
					items: [],
				},
				criterias: {
					...state.criterias,
					base: [],
					vkn: [],
					error: false,
					loading: false
				},
				selectedCriterias: [],
				// ...state.selectedMmtGroup?.svgEre != action.payload.selectedMmtGroup.svgEre && {
				// 	selectionAvailable: !!state.savedSelectedValues[action.payload.selectedMmtGroup.svgEre ?? ""],
				// 	selectedValues: {
				// 		...DEFAULT_STATE.selectedValues,
				// 		...initialReg && { initialReg },
				// 		...kbaNo && { kbaNo },
				// 		...state.savedSelectedValues[action.payload.selectedMmtGroup?.svgEre ?? ""],
				// 	},
				// },

			}
		}
		case "UPDATE_VKN_CRITERIA": {
			const { criteria, isCheckbox, vehicle } = action.payload
			// let criteria = action.payload.criteria //for some reason destructing isn't working ??
			// let isCheckbox = action.payload.isCheckbox

			let { selectedCriterias, savedCriterias } = state

			let saved = savedCriterias[vehicle?.tecDocTypeId!] ?? []
			if (!isCheckbox) {
				selectedCriterias = selectedCriterias.filter(x => x.compatGroup != criteria.compatGroup) //remove items froms same group
				selectedCriterias.push(criteria)

				saved = saved.filter(x => x.compatGroup != criteria.compatGroup)
				saved.push(criteria)
			}
			else {
				selectedCriterias = addOrRemoveItem(selectedCriterias, criteria,
					(x, y) => x.code == y.code && x.compatGroup == y.compatGroup && x.description == y.description)
				saved = addOrRemoveItem(saved, criteria,
					(x, y) => x.code == y.code && x.compatGroup == y.compatGroup && x.description == y.description)

			}

			const newSelectedVknImage = state.vknImages.items.find(x => x.criteria?.code == selectedCriterias[0].code)
			return {
				...state,
				selectedCriterias,
				savedCriterias: {
					...savedCriterias,
					[vehicle?.tecDocTypeId!]: saved
				},
				...selectedCriterias.length == 1 && {
					selectedVKNImage: newSelectedVknImage,
					criterias: {
						...state.criterias,
						forceShowVknCriterias: newSelectedVknImage?.isStartImage
					}
				} || {
					selectedVKNImage: undefined
				}

			}
		}
		case "UPDATE_BASE_CRITERIA": {
			const { criteria, isCheckbox, vehicle } = action.payload
			let { selectedCriterias, savedCriterias, } = state
			const startVKNItems = state.vknImages.items.filter(x => x.isStartImage)
			let savedCriteriasArray = savedCriterias[vehicle?.tecDocTypeId!] ?? []

			if (!isCheckbox) {
				selectedCriterias = selectedCriterias.filter(x => x.compatGroup != criteria.compatGroup) //remove items froms same group
				selectedCriterias.push(criteria)

				savedCriteriasArray = savedCriteriasArray.filter(x => x.compatGroup != criteria.compatGroup) //remove items froms same group
				savedCriteriasArray.push(criteria)
			}
			else {
				const comparator = (x: Criteria, y: Criteria) => x.code == y.code && x.compatGroup == y.compatGroup && x.description == y.description
				selectedCriterias = addOrRemoveItem(selectedCriterias, criteria, comparator)

				savedCriteriasArray = addOrRemoveItem(savedCriteriasArray, criteria, comparator)
			}


			return {
				...state,
				selectedCriterias,
				savedCriterias: {
					...savedCriterias,
					[vehicle?.tecDocTypeId!]: savedCriteriasArray
				},
				...state.criterias.base.length == 1  //if has only one items and it's deselected
				&& selectedCriterias.length == 0
				&& startVKNItems.length > 1
				&& {
					selectedCriterias: [],
					selectedVKNImage: undefined,
				}
			}
		}
		case "RESET_BASE_CRITERIA_GROUP": {
			const { group, vehicle } = action.payload
			let { selectedCriterias, savedCriterias } = state
			selectedCriterias = selectedCriterias.filter(x => x.compatGroup != group)
			const startVKNItems = state.vknImages.items.filter(x => x.isStartImage)
			let savedCriteriasArray = savedCriterias[vehicle?.tecDocTypeId!] ?? []
			savedCriteriasArray = savedCriteriasArray.filter(x => x.compatGroup != group)

			return {
				...state,
				selectedCriterias,
				savedCriterias: {
					...savedCriterias,
					[vehicle?.tecDocTypeId!]: savedCriteriasArray
				},
				...state.criterias.base.length == 1 && selectedCriterias.length == 0 && startVKNItems.length > 1 && //if has only one items and it's deselected
				{
					selectedCriterias: [],
					selectedVKNImage: undefined,
				}
			}
		}
		case "RESET_VKN_CRITERIA_GROUP": {
			const { group, vehicle } = action.payload
			let { selectedCriterias, savedCriterias } = state
			selectedCriterias = selectedCriterias.filter(x => x.compatGroup != group)
			const saved = savedCriterias[vehicle?.tecDocTypeId!]?.filter(x => x.compatGroup != group) ?? []

			return {
				...state,
				selectedCriterias,
				savedCriterias: {
					...savedCriterias,
					[vehicle?.tecDocTypeId!]: saved
				},
				...selectedCriterias.length == 1 && {
					selectedVKNImage: state.vknImages.items.find(x => x.criteria?.code == selectedCriterias[0].code)
				} || {
					selectedVKNImage: undefined
				}
			}
		}
		// case "RESET_ALL_CRITERIAS": {
		// 	const { selectedVKNImage, savedCriterias } = state
		// 	return {
		// 		...state,
		// 		selectedCriterias: [],
		// 		savedCriterias: {
		// 			...savedCriterias,
		// 			...selectedVKNImage && {
		// 				[selectedVKNImage.imageName]: []
		// 			}
		// 		}
		// 	}
		// }
		case "VEHICLE_SET": {
			const { vehicle, isFirstUpdate, isWidgetSearch } = action.payload
			return {
				...state,
				...isFirstUpdate && getBundleParams().disableStateSave && {

					selectedValues: {
						...state.selectedValues,
						initialReg: vehicle.initialRegistration
					}
				},
				...isWidgetSearch && {
					selectedValues: {
						...state.selectedValues,
						initialReg: vehicle.initialRegistration
					}
				}
			}
		}
		case "PARTS_LOADING": {
			return {
				...state,
				parts: {
					...state.parts,
					error: false,
					items: [],
					loading: true
				}
			}
		}
		case "PARTS_ERROR": {
			return {
				...state,
				parts: {
					...state.parts,
					error: true,
					items: [],
					loading: false
				}
			}
		}
		case "PARTS_LOADED": {
			return {
				...state,
				parts: {
					...state.parts,
					error: false,
					items: action.payload,
					loading: false
				}
			}
		}
		case "SELECT_PART_ITEM": {
			return {
				...state,
				selectedPartItem: action.payload
			}
		}
		case "SELECT_ERE_POSITION": {
			return {
				...state,
				// selectedMmtGroup: { TODO
				// 	...state.selectedMmtGroup!,
				// 	selectedErePosition: action.payload
				// },
				vknImages: {
					...state.vknImages,
					items: [],
				},
				criterias: {
					...state.criterias,
					error: false,
					vkn: [],
					base: [],
					loading: false
				},
				selectedCriterias: [],
				extendedCarInfo: DEFAULT_STATE.extendedCarInfo
			}
		}
		case "UPDATE_FIELDS": {
			const { path, value } = action.payload
			const selectedValues = { ...state.selectedValues, [path]: value }

			return {
				...state,
				selectedValues,
			}
		}
		case "UPDATE_MMT_LIST": {
			return {
				...state,
				vknImages: {
					...state.vknImages,
					loading: true,
					items: []
				},
				criterias: {
					...state.criterias,
					error: false,
					vkn: [],
					loading: false
				},
				...state.extendedCarInfo.carMMTs && {
					mmtList: {
						...state.mmtList,
						items: state.extendedCarInfo.carMMTs,
					}
				}
			}
		}
		case "SVGIMAGE_LOADING": {
			return {
				...state,
				selectedVKNImage: {
					...state.selectedVKNImage!,
					svgLoading: true
				}
			}
		}
		case "SVGIMAGE_LOADED": {
			return {
				...state,
				selectedVKNImage: {
					...state.selectedVKNImage!,
					svg: action.payload,
					svgLoading: false
				}
			}
		}
		case "SVGIMAGE_ERROR": {
			return {
				...state,
				selectedVKNImage: {
					...state.selectedVKNImage!,
					error: true
				}
			}
		}
		case "RESET_LOCAL_STORE": {
			const { vehicle } = action.payload
			return {
				...DEFAULT_STATE,
				selectedValues: {
					...DEFAULT_STATE.selectedValues,
					initialReg: vehicle?.initialRegistration
				}
			}
		}
	}
	return state
}




function loadVKNImages(fromModal?: boolean): AsyncAction<MainActionsType, MainState> {
	return (dispatch, getState) => {
		const request = createEreLinkImagesRequest(getState())
		if (!request) return

		dispatch({ type: "VKN_IMAGES_LOADING" })
		Repositories.getEreLinkImages(request).then(
			response => {
				const { criteria: vknCriterias, imageGroups: items, sufficientSelection } = response
				dispatch({ type: "VKN_IMAGES_LOADED", payload: { fromModal, items, vknCriterias, sufficientSelection, vehicle: getState().manager.vehicle } })
				if (response.sufficientSelection == ESufficentSelections.Distinct && response.imageGroups?.length) {
					const startVKNItems = response.imageGroups.filter(x => x.isStartImage)
					const { details: { savedCriterias }, manager: { vehicle } } = getState()
					const saved = savedCriterias[vehicle?.tecDocTypeId!]
					const communeFilters = getCommonItems(saved ?? [], response.criteria ?? [], (x, y,) => x.code == y.code)

					// if(  && startVKNItems.length == 1){
					// 	dispatch(loadCarExtendedInfo())
					// }

					const haveDefaultOrSavedCriteria = startVKNItems.length == 1 || startVKNItems.length > 1 && communeFilters.length
					if (haveDefaultOrSavedCriteria)
						dispatch(loadImagePositions())
				}
				else
					dispatch(loadCarExtendedInfo())


			},
			_error => dispatch({ type: "VKN_IMAGES_ERROR" })
		)
	}
}

function loadSvgImage(): AsyncAction<MainActionsType, MainState> {
	return (dispatch, getState) => {
		const { selectedVKNImage } = getState().details

		if (!selectedVKNImage)
			return

		dispatch({ type: "SVGIMAGE_LOADING" })

		Repositories.getSvgImage(selectedVKNImage.imageName).then(
			response => {
				dispatch({ type: "SVGIMAGE_LOADED", payload: response })

				dispatch(loadImagePositions())
			},
			_ => dispatch({ type: "SVGIMAGE_ERROR" })
		)
	}
}


function updateFields(path: string, value: any): ComponentActionType {
	return { type: "UPDATE_FIELDS", payload: { path, value } }
}

function loadCarExtendedInfo(): AsyncAction<MainActionsType, MainState> {
	return (dispatch, getState) => {
		const request = createCarExtendedRequest(getState())
		if (!request) return

		dispatch({ type: "CAR_INFO_EXTENDED_LOADING" })
		Repositories.getCarExtendedInfo(request).then(
			response => {
				batch(() => {
					dispatch({
						type: "CAR_INFO_EXTENDED_LOADED", payload: response,
						selectedMmtGroup: getState().summary.selectedMmtGroup, vehicle: getState().manager.vehicle
					})
					if (response.sufficientSelection == ESufficentSelections.Distinct)
						dispatch(loadVKNImages(true))
				})

			},

			_ => dispatch({ type: "CAR_INFO_EXTENDED_ERROR" }),
		)
	}
}



function selectErePosition(position: ErePosition): MainActionsType {
	return { type: "SELECT_ERE_POSITION", payload: { position } }
}

function selectImagePosition(imagePostion: ImagePosition): MainActionsType {
	return { type: "SELECT_IMAGE_POSITION", payload: imagePostion }
}


function loadImagePositions(): AsyncAction<MainActionsType, MainState> {
	return (dispatch, getState) => {

		const request = createImagePositionRequest(getState())
		if (!request)
			return

		dispatch({ type: "IMAGE_POSITIONS_LOADING" })
		Repositories.getImagePositions(request).then(
			response => dispatch({ type: "IMAGE_POSITIONS_LOADED", payload: { ...response, vehicle: getState().manager.vehicle } }),
			_ => dispatch({ type: "IMAGE_POSITIONS_ERROR" })
		)
	}
}



function selectPartItem(part: Part): ComponentActionType {
	return { type: "SELECT_PART_ITEM", payload: part }
}



const updateBaseCriterias = (value: Criteria, isCheckbox?: boolean): AsyncAction<MainActionsType, MainState> => (dispatch, getState) => {
	dispatch({ type: "UPDATE_BASE_CRITERIA", payload: { criteria: value, isCheckbox, vehicle: getState().manager.vehicle } })
}

const updateVknCriterias = (value: Criteria, isCheckbox?: boolean): AsyncAction<MainActionsType, MainState> => (dispatch, getState) => {
	dispatch({ type: "UPDATE_VKN_CRITERIA", payload: { criteria: value, isCheckbox, vehicle: getState().manager.vehicle } })
}


function getParts(): AsyncAction<ComponentActionType, MainState> {
	return (dispatch, getState) => {
		const request = createGetPartsRequest(getState())
		if (!request) return

		dispatch({ type: "PARTS_LOADING" })
		Repositories.getParts(request).then(
			response => dispatch({ type: "PARTS_LOADED", payload: response.parts }),
			_error => dispatch({ type: "PARTS_ERROR" })
		)
	}
}


const resetBaseCriteriaGroup = (group: string): AsyncAction<MainActionsType, MainState> => (dispatch, getState) => {
	dispatch({ type: "RESET_BASE_CRITERIA_GROUP", payload: { group, vehicle: getState().manager.vehicle } })
}

const updateSelectedVKNImage = (image: VKNImage): AsyncAction<MainActionsType, MainState> => (dispatch, getState) => {
	dispatch({ type: "SELECT_VKN_IMAGE", payload: image, vehicle: getState().manager.vehicle })
}




const resetVknCriteriaGroup = (group: string): AsyncAction<MainActionsType, MainState> => (dispatch, getState) => {
	dispatch({ type: "RESET_VKN_CRITERIA_GROUP", payload: { group, vehicle: getState().manager.vehicle } })
}

function updateMMTList(): ComponentActionType {
	return { type: "UPDATE_MMT_LIST" }
}


export type IActions = typeof Actions

export const Actions = {
	updateBaseCriterias,
	updateVknCriterias,
	updateSelectedVKNImage,
	selectImagePosition,
	selectErePosition,
	loadImagePositions,
	getParts,
	loadVKNImages,
	selectPartItem,
	resetBaseCriteriaGroup,
	resetVknCriteriaGroup,
	updateFields,
	loadCarExtendedInfo,
	updateMMTList,
	loadSvgImage
}