import { getStyleTheme } from "@tm/context-distribution"
import { style } from "typestyle"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return style({
        flex: 1,
        display: "flex",
        flexDirection: "column",
        position: "relative",
        padding: margin.xl,
        paddingBottom: 0,
        $nest: {
            "&__toolbar.module.toolbar": {
                flex: "0 0 auto",
                margin: "-0.5em 0 1em 0",
                padding: 0,
            },
            "&__hint": {
                display: "block",
                marginBottom: `calc(${margin.l} - ${margin.xs})`,
                paddingBottom: margin.xs, // Fix scrollbar showing when not really necessary
                maxHeight: "50vh",
                overflowY: "auto",
                wordBreak: "break-word",
            },
            "&__exception": {
                display: "block",
                marginTop: `calc(${margin.l} * 2)`,
                wordBreak: "break-word",
            },
            "&__loader": {
                margin: "0 auto",
                $nest: {
                    "&.loader-spinner": {
                        width: "2em",
                        height: "2em",
                    },
                    "&.loader-connection": {
                        width: "15em",
                        padding: "5px 0",
                        $nest: {
                            ".loader-connection__line": {
                                height: "2px",
                            },
                            ".loader-connection__dots": {
                                top: "50%",
                                marginTop: "-6px",
                                lineHeight: "10px",
                                $nest: {
                                    div: {
                                        height: "10px",
                                        width: "10px",
                                        margin: `0 ${margin.s}`,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            "&__action": {
                marginTop: margin.l,
            },
            "&__voucher": {
                $nest: {
                    "&-wrapper": {
                        flex: 1,
                        display: "flex",
                    },
                    "&-list": {
                        flex: 1,
                        display: "flex",
                    },
                    "&-details-wrapper": {
                        flex: "0 0 65%",
                        margin: `0 -${margin.s}`, // Offset padding of child
                        position: "relative",
                        overflowY: "auto",
                    },
                    "&-details-error": {
                        margin: `${margin.xl} ${margin.s}`,
                    },
                    "&-details": {
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        padding: `0 ${margin.s}`,
                        $nest: {
                            "> *:first-child": {
                                marginTop: margin.xl,
                            },
                            "> *:last-child": {
                                marginBottom: margin.xl,
                            },
                        },
                    },
                },
            },
        },
    })
}
