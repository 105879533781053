import * as React from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { concat } from "@tm/utils"
import { getStyleTheme } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"

function getComponentStyles(): NestedCSSProperties {
    const { box } = getStyleTheme()

    return {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.25)",
        $nest: {
            "&__wrapper": {
                minWidth: "20em",
                maxWidth: "50em",
                margin: "auto",
            },
            "&__panel": {
                borderRadius: box.radius,
                textAlign: "center",
            },
        },
    }
}

type Props = {
    className?: string
}

export const OverlayMessage: React.FC<Props> = (props) => {
    const className = style(getComponentStyles())

    return (
        <div className={concat(" ", props.className, className)}>
            <div className={`${className}__wrapper`}>
                <PanelSection className={`${className}__panel`}>{props.children}</PanelSection>
            </div>
        </div>
    )
}
