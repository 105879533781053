import { Typography } from "@tm/components"
import { LightableText } from "../StyledComponents"

type Props = {
    email: string
    lightMode?: boolean
}

export default function EmailComponent({ email, lightMode }: Props) {
    return (
        <Typography component="a" variant="body2" fontWeight="bold" href={`mailto:${email}`} sx={{ textDecoration: "none" }}>
            <LightableText variant="body2" fontWeight="bold" lightMode={lightMode}>
                {email}
            </LightableText>
        </Typography>
    )
}
