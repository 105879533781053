import { BundleActionType, BundleActions } from "../../../business"

export type ComponentActionType = BundleActionType
export type DirectSearchState = {}

const DEFAULT_STATE: DirectSearchState = {}

export function reduce(state = DEFAULT_STATE): DirectSearchState {
    return state
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "MODULE_OPENED":
            return action
        default:
            break
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
}
