import { useEffect, useState } from "react"
import { Box, Button, Stack, Switch, TextField } from "@tm/components"
import { CostEstimationOptions, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import UserSettingsArea from "./UserSettingsArea"

export function CostEstimation() {
    const { translateText } = useLocalization()
    const { userSettings, changeCostEstimationOptions } = useUser() ?? {}
    const [userSettingsSaving, setUserSettingsSaving] = useState(false)
    const [useNoteTextByDefault, setUseNoteTextByDefault] = useState(userSettings?.costEstimationOptions?.useNoteTextByDefault ?? false)
    const [noteText, setNoteText] = useState(userSettings?.costEstimationOptions?.noteText ?? "")
    const [buttonsEnabled, setButtonsEnabled] = useState(false)

    useEffect(() => {
        setUseNoteTextByDefault(userSettings?.costEstimationOptions?.useNoteTextByDefault ?? false)
    }, [userSettings?.costEstimationOptions?.useNoteTextByDefault])

    useEffect(() => {
        setNoteText(userSettings?.costEstimationOptions?.noteText ?? "")
    }, [userSettings?.costEstimationOptions?.noteText])

    async function handleSaveNoteText() {
        const useNoteText = noteText !== undefined && noteText.length > 0 && useNoteTextByDefault

        // Save new noteText
        saveCostEstimationOptions({
            noteText,
            useNoteTextByDefault: useNoteText,
            version: userSettings?.costEstimationOptions?.version,
        })

        setButtonsEnabled(false)
    }

    function handleResetNoteText() {
        setNoteText(userSettings?.costEstimationOptions?.noteText ?? "")
        setButtonsEnabled(false)
    }

    function handleUseNoteTextByDefaultChanged(checked: boolean) {
        // Only save the switch state and pass currently saved noteText
        setUseNoteTextByDefault(checked)
        saveCostEstimationOptions({
            noteText: userSettings?.costEstimationOptions?.noteText,
            useNoteTextByDefault: checked,
            version: userSettings?.costEstimationOptions?.version,
        })
    }

    function handleChangeNoteText(value: string) {
        setNoteText(value)
        setButtonsEnabled(true)
    }

    function saveCostEstimationOptions(saveOptions: CostEstimationOptions) {
        setUserSettingsSaving(true)
        changeCostEstimationOptions(saveOptions)
        setUserSettingsSaving(false)
    }

    return (
        <UserSettingsArea id="user-settings__area__cost-estimation" title={translateText(82)} showLoader={!userSettings || userSettingsSaving}>
            <Stack spacing={1.5}>
                <Box>
                    <Button variant="contained" color="success" sx={{ marginRight: 0.5 }} onClick={handleSaveNoteText} disabled={!buttonsEnabled}>
                        {translateText(9)}
                    </Button>
                    <Button variant="contained" onClick={handleResetNoteText} disabled={!buttonsEnabled}>
                        {translateText(48)}
                    </Button>
                </Box>
                <TextField
                    sx={{ width: "36em" }}
                    value={noteText}
                    onChange={(e) => handleChangeNoteText(e.target.value)}
                    placeholder={translateText(13759)}
                    inputCounter
                    inputProps={{
                        maxLength: 400,
                    }}
                />
                <Box>
                    <Switch
                        label={translateText(13758)}
                        checked={useNoteTextByDefault}
                        onChange={(e) => handleUseNoteTextByDefaultChanged(e.target.checked)}
                        disabled={!userSettings?.costEstimationOptions?.noteText || userSettings?.costEstimationOptions?.noteText.length === 0}
                    />
                </Box>
            </Stack>
        </UserSettingsArea>
    )
}
