import { useSelector } from "react-redux"
import { Box, Typography, VehicleImage } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { dateToString } from "../../../data/helpers"
import { managerSelector } from "../../main/business"

export default function Header() {
    const { translateText } = useLocalization()
    const { vehicle, customer } = useSelector(managerSelector)
    if (!vehicle) {
        return null
    }

    return (
        <Box display="flex" alignItems="center" flex="1" gap=".5em">
            <Typography fontWeight="600">{`${translateText(1575)} ${translateText(1481)}`}</Typography>
            {vehicle.modelThumbnail && (
                <Box display="flex" alignItems="center" width="4em" height="3em">
                    <VehicleImage
                        modelImage={vehicle.modelThumbnail}
                        modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                        vehicleType={vehicle.vehicleType}
                        style={{ width: "3em", margin: "0 .5em" }}
                    />
                </Box>
            )}
            {vehicle.manufacturer && <Typography>{vehicle.manufacturer}</Typography>}
            {vehicle.modelSeries && <Typography>{vehicle.modelSeries}</Typography>}
            {vehicle.engineCode && (
                <>
                    <Typography fontWeight="bold">{`${translateText(105)}:`}</Typography>
                    <Typography>{vehicle.engineCode}</Typography>
                </>
            )}
            {vehicle.initialRegistration && (
                <>
                    <Typography fontWeight="bold">{`${translateText(124)}:`}</Typography>
                    <Typography>{dateToString(vehicle.initialRegistration)}</Typography>
                </>
            )}
            {vehicle.vin && (
                <>
                    <Typography fontWeight="bold">{`${translateText(101)}:`}</Typography>
                    <Typography>{vehicle.vin}</Typography>
                </>
            )}
            {(customer?.firstName || customer?.lastName) && (
                <Typography fontWeight="bold">{`  ${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`}</Typography>
            )}
        </Box>
    )
}
