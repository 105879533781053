import { Tab, Tabs } from "@tm/components"
import { TabControl, Loader } from "@tm/controls"
import { AdvertisementCategory } from "@tm/models"
import { useCallback } from "react"

type Props = {
    loading: boolean
    advertisementCategories: Array<AdvertisementCategory>
    activeCategory: AdvertisementCategory | undefined
    onChangeCategory(category: AdvertisementCategory): void
    components: "MUI" | "Controls"
}

export function OfferTabs({ loading, advertisementCategories, activeCategory, onChangeCategory: onChangeTab, components }: Props) {
    const handleChangeTab = useCallback(
        (tabId: string) => {
            const category = advertisementCategories.find((x) => x.id === tabId)

            if (category) {
                onChangeTab(category)
            }
        },
        [advertisementCategories, onChangeTab]
    )

    if (loading) {
        return <Loader />
    }

    if (components === "MUI") {
        return (
            <Tabs value={activeCategory?.id ?? ""} onChange={(e, value) => handleChangeTab(value)} size="small">
                {advertisementCategories.map((category) => (
                    <Tab key={category.id} value={category.id} label={category.description} />
                ))}
            </Tabs>
        )
    }

    return (
        <TabControl selectedTabIdentifier={activeCategory?.id ?? ""} onTabSelect={handleChangeTab} type="flat">
            {advertisementCategories.map((category) => (
                <TabControl.Tab key={category.id} identifier={category.id}>
                    {category.description}
                </TabControl.Tab>
            ))}
        </TabControl>
    )
}
