import { EFilterNames, ETyresCarType, TyreFilter, TyresSeason } from "@tm/models"
import { equals } from "@tm/utils"
import { SummaryMultiFilters } from "../../components/summary/business"
import { TireFilterSizes, addOrRemoveItem, getAvailableTireSize } from "../../data/helpers"
import { Filters, IWidgetFilters, SelectedFilters } from "./model"
import { TyresCritsRequest } from "../../data/repositories"
import { Statics } from "../../data/statics"

export function createTiresCritsRequest(selectedFilters: SelectedFilters): TyresCritsRequest {
    const crits: TyresCritsRequest = {
        width: selectedFilters.width?.query ?? "",
        height: selectedFilters.height?.query ??  "",
        inch: selectedFilters.inch?.query ?? "",
        ...(selectedFilters.season && {
            season: selectedFilters.season?.query as unknown as TyresSeason
        }),
        carTypes: selectedFilters.carType?.map(x => +x.query as ETyresCarType) ?? []
    }

    return crits
}

export function updateSelectedFilters(selectedFilters: SelectedFilters, filters: Filters, path: IWidgetFilters, value?: TyreFilter): SelectedFilters {

    const oldStoredValues = selectedFilters?.[path]

    let newValues: TyreFilter | TyreFilter[] | undefined

    if (SummaryMultiFilters.includes(path)) { //if is multiSelection
        newValues = addOrRemoveItem(oldStoredValues as TyreFilter[], value)
    } else {
        newValues = equals(value, oldStoredValues) ? undefined : value
    }

    let carTypeSizes: TireFilterSizes | undefined
    if (path == EFilterNames.carType) {
        newValues = selectedFilters.untouched ? (value ? [value] : undefined) : newValues
        
        if (!newValues || !(newValues as TyreFilter[])?.length) {
            newValues = [Statics.getDefaultCarType()]
        }
        carTypeSizes = getAvailableTireSize(newValues as TyreFilter[], filters)
    }

    return {
        ...selectedFilters,
        [path]: newValues,
        ...path == EFilterNames.carType && selectedFilters.untouched && {
            [path]: value ? [value] : undefined,
            untouched: false
        },
        ...carTypeSizes && {
            width: carTypeSizes.width,
            inch: carTypeSizes.inch,
            height: carTypeSizes.height ? carTypeSizes.height : undefined
        }
    }
}


export function resetSummaryFilter(selectedFilters: SelectedFilters, path: IWidgetFilters): SelectedFilters {
    switch (path) {
        case EFilterNames.width:
            return {
                ...selectedFilters,
                height: undefined,
                inch: undefined,
                [path]: undefined
            }

        case EFilterNames.height:
            return {
                ...selectedFilters,
                inch: undefined,
                [path]: undefined
            }
        default:
            return {...selectedFilters}
    }
}
