import { em, percent } from "csx"
import { useCallback, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"
import { RouteComponentProps, createQueryString, renderRoute, withRouter } from "@tm/utils"
import Filters from "./components/filters"
import SummaryHeader from "./components/header"
import Suggestions from "./components/suggestions"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps & {
	vehicleSelectionRoute: string
}

function SummaryComponent({ history, vehicleSelectionRoute, match }: Props) {
    const { isFiltersOnRightSide, useNewNavbar } = getBundleParams()
    const style = useMemo(() => getStyle(isFiltersOnRightSide), [])

	const handleVehicleRedirect = useCallback(() => {
		const vehicleSelectUrl = renderRoute(vehicleSelectionRoute, { ...match.params }) + createQueryString({ redirect: "/:workTaskId/tyres/summary" })
		history.push(vehicleSelectUrl)
	}, [])

	return (
		<div className={style.wrapper}>
			{!useNewNavbar && <SummaryHeader />}
			<div className={style.content}>
				<PanelSection className={style.filters} >
					<Filters />
				</PanelSection>
				<Suggestions className={style.suggestions} onVehicleRedirect={handleVehicleRedirect} />
			</div>
		</div>
	)
}

export default withRouter(SummaryComponent)

function getStyle(isFiltersOnRightSide?: boolean) {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            display: "flex",
            flexDirection: "column",
            width: percent(100)
        },
        content: {
            display: "flex",
            flex: 1,
            flexDirection: isFiltersOnRightSide ? "row-reverse" : "row"
        },
        filters: {
            flexBasis: em(22),
            padding: theme.margin.m
        },
        suggestions: {
            marginLeft: theme.margin.m,
            marginRight: isFiltersOnRightSide ? theme.margin.m : undefined,
            flex: 1
        }
    })(SummaryComponent)
}
