import { useQuery } from "react-query"
import { FastUp } from "@tm/data"
import { getBundleParams } from "../../utils"
import { QueryKeys } from ".."

export function useFastUpTransferInfo() {
    const baseUrl = getBundleParams().fastUpApiBaseUrl

    const { data, isLoading, isError, refetch, isRefetching } = useQuery([QueryKeys.FastUpTransferInfo], () => FastUp.getTransferInfo(baseUrl), {
        staleTime: 0,
        cacheTime: 0,
    })

    return { transferInfo: data, isLoading, isError, refetch, isRefetching }
}
