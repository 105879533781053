import { Icon, SuggestionFieldButtonGroup, Table, Text } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { CategoryType, SearchFilters } from "@tm/models"
import { bindSpecialReactMethods, CancelablePromise, concat, convertAutosuggestHit, makeCancelable, validateField } from "@tm/utils"
import { Component, createRef } from "react"

import { Tooltip } from "@tm/components"
import { AutosuggestFunction, Hit } from "../../../data/repositories"
import { AutosuggestHit } from "./AutosuggestHit"

type Props = LocalizationProps & {
    query?: string
    onSearch(query: string, searchFilters?: SearchFilters): void
    getSuggestions?: AutosuggestFunction
    hint?: string
    placeholder?: string
    minLengthForSearch: number
    extraButton?: JSX.Element
    activeCategory?: CategoryType
    tooltip?: string
    autofocus?: boolean
    autofocusTimeout?: number
    extendedSearch?: boolean
    isOnIndustryDashboard?: boolean
}

type State = {
    query?: string
    hint?: string
    suggestions: Array<Hit>
    isTooltipOpen: boolean
}

class Search extends Component<Props, State> {
    public searchFieldRef = createRef<SuggestionFieldButtonGroup<Hit>>()

    private suggestionRequest: CancelablePromise<Array<Hit>>

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)

        this.state = {
            hint: this.props.hint,
            suggestions: [],
            isTooltipOpen: false,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        const queryChanged = nextProps.query !== this.props.query
        const hintChanged = nextProps.hint !== this.props.hint

        if (this.props.getSuggestions !== nextProps.getSuggestions) {
            this.setState({ suggestions: [] })
        }

        if (queryChanged || hintChanged) {
            this.setState((prevState) => {
                return {
                    query: queryChanged ? undefined : prevState.query,
                    hint: hintChanged ? nextProps.hint : prevState.hint,
                }
            })
        }
        if (nextProps.activeCategory !== this.props.activeCategory) {
            setTimeout(() => {
                this.searchFieldRef.current?.focus(false)
            }, nextProps.autofocusTimeout ?? 25)
        }
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.hint !== prevState.hint) {
            if (this.state.hint) {
                this.setState({ isTooltipOpen: true })
            } else {
                this.hideTooltip()
            }
        }
    }

    componentDidMount() {
        if (this.props.autofocus) {
            setTimeout(() => {
                this.searchFieldRef.current?.focus(false)
            }, this.props.autofocusTimeout ?? 25)
        }
    }

    componentWillUnmount() {
        this.suggestionRequest?.cancel()
    }

    handleGetSuggestionData(query: string) {
        if (!this.props.getSuggestions) {
            return
        }

        this.suggestionRequest = makeCancelable(this.props.getSuggestions(query))

        this.suggestionRequest.promise
            .then((suggestions) => this.setState({ suggestions }))
            .catch((e) => {
                if (e.isCanceled) {
                    // do nothing
                }
            })
    }

    handleChange(query: string) {
        this.setState({ query, hint: undefined })
    }

    handleSearch(query: string, searchFilters?: SearchFilters) {
        const {
            minLengthForSearch,
            localization: { translateText },
            activeCategory,
            extendedSearch,
        } = this.props

        if (query.length < minLengthForSearch) {
            this.setState({
                hint: translateText(812).replace("{0}", minLengthForSearch.toString()),
            })
            return
        }

        let wildcardSearch = false
        let normalizedValue = query

        if (activeCategory == "directSearch") {
            if (query.charAt(query.length - 1) === "*") {
                wildcardSearch = true
                query = query.slice(0, -1) // remove * from string, so it can be added again, after the length has been checked.
            } else if (extendedSearch) {
                wildcardSearch = true
            }

            normalizedValue = query.replace(/[^a-zA-Z0-9*]/g, "") // underscore "_" is not allowed, therefore not \w
        }

        const state = validateField(normalizedValue).min(wildcardSearch ? 5 : minLengthForSearch, translateText(1231))

        if (state.messages.length) {
            this.setState({ hint: state.messages[0] })
            return
        }

        if (wildcardSearch) {
            query += "*"
        }

        this.handleChange(query)
        this.hideHintAndSuggestions()
        this.props.onSearch(query, searchFilters)
    }

    handleSuggestionSelect(suggestion: Hit) {
        this.handleSearch(concat(" ", suggestion.productGroup, suggestion.dataSupplier))
    }

    handleSearchButtonClick() {
        const query = this.state.query != undefined ? this.state.query : this.props.query
        this.handleSearch(query || "")
    }

    hideHintAndSuggestions = () => {
        this.hideTooltip()
        this.setState({
            suggestions: [],
        })
    }

    showTooltip = () => {
        this.setState({ isTooltipOpen: true })
    }

    hideTooltip = () => {
        this.setState({ isTooltipOpen: false })
    }

    renderHint() {
        const { hint } = this.state

        if (!hint) {
            return
        }

        return (
            <div className="search__hint">
                <Icon name="bulb" />
                <div className="search__hint__text">
                    <p className="text text--strong text--block">{this.props.localization.translate(813)}</p>
                    <p className="text text--block">{hint}</p>
                </div>
            </div>
        )
    }

    render() {
        const query = this.state.query != undefined ? this.state.query : this.props.query
        const { isOnIndustryDashboard } = this.props

        return (
            <div className="tk-parts search-field" style={isOnIndustryDashboard ? { width: "100%" } : {}}>
                <Tooltip
                    open={this.state.isTooltipOpen}
                    title={this.renderHint()}
                    variant="highlight"
                    color="highlight"
                    onClickAway={this.hideTooltip}
                    disableFocusListener
                    disableHoverListener
                >
                    <div onClick={this.showTooltip}>
                        {/* This div has to exist, otherwise the Tooltip destroys the ref to SuggestionFieldButtonGroup */}
                        <SuggestionFieldButtonGroup<Hit>
                            className="article-search"
                            // showClear
                            onInputClick={this.showTooltip}
                            size="m"
                            value={query || ""}
                            onChange={this.handleChange}
                            placeholder={this.props.placeholder}
                            onChangeConfirm={this.handleSearch}
                            ref={this.searchFieldRef}
                            tooltip={this.props.tooltip}
                            maxLength={50}
                            // enableLeadingTrim
                            handleSearchButtonClick={this.handleSearchButtonClick}
                            showClear
                            extraButton={this.props.extraButton}
                            suggestions={this.state.suggestions}
                            requestSuggestions={this.handleGetSuggestionData}
                            onSuggestionSelect={this.handleSuggestionSelect}
                            renderTableColumns={getSuggestionColumns}
                            minCharactersToSuggest={2}
                            suggestDelay={500}
                        />
                    </div>
                </Tooltip>
            </div>
        )
    }
}

export default withLocalization(Search)

function getSuggestionColumns() {
    return [
        <Table.Column
            key="hit"
            renderItemContent={(suggestion: Hit) => {
                const productGroup = convertAutosuggestHit(
                    suggestion.highlightProductGroupSynonym ||
                        suggestion.highlightProductGroup ||
                        suggestion.productGroupSynonym ||
                        suggestion.productGroup,
                    AutosuggestHit
                )

                const dataSupplier = convertAutosuggestHit(suggestion.highlightDataSupplier || suggestion.dataSupplier, AutosuggestHit)

                return (
                    <Table.Cell key="">
                        {productGroup}
                        {!!productGroup && !!dataSupplier && " | "}
                        {dataSupplier}
                        {!!suggestion.productGroupSynonym && !!suggestion.productGroup && (
                            <Text size="s" modifiers={["block", "sub"]}>
                                {suggestion.productGroup}
                            </Text>
                        )}
                    </Table.Cell>
                )
            }}
        />,
    ]
}
