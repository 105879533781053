import { getKtypeNr } from "../utils"
import { Article, MainService, ServiceBase, ServiceDirection } from "@tm/models"
import { AddRepairTimesToScBasket } from "../data/models"

export class SmartClientHelper {
    static addArticlesToBasket(items: Article[], callback: (items: Article[]) => void, sourceId?: string) {

        let data = items.map(item => ({

            wholesalerArticleNumber: item.traderArticleNo,
            supplierArticleNumber: item.supplierArticleNo,
            name: item.description,
            productGroup: {
                name: item.productGroup.name,
                id:  item.productGroup.id
            },
            supplier: {
                name: item.supplier.name,
                id: item.supplier.id
            },
            purchasePrice: 0,
            retailPrice: 0,
            vehicle: {
                id: getKtypeNr(),
            },
            quantity: item.quantity ?? 1,
            sourceId
        }))

        window.DVSE?.addArticlesToBasket?.(data);
        
    }

    static addRepairTimesToSCBasket(mainService: MainService, sourceId?: string) {
        const { calculatedServices, preparationWorks } = mainService
        let includedRepairTimes: Array<ServiceBase> = []

        if (calculatedServices && calculatedServices.length)
            includedRepairTimes = calculatedServices.filter(calculatedServices => calculatedServices.isVisible === true)
        else if (preparationWorks && preparationWorks.length)
            includedRepairTimes = preparationWorks.filter(preparationWork => preparationWork.direction === ServiceDirection.Include)

        const data : AddRepairTimesToScBasket[] = [{
            direction: 0,// main work 
            id: mainService.repairTimeNo,
            name: mainService.description,
            sourceName: 'DVSE',
            value: mainService.repairTime ?? 0,
            type: window.DVSE?.RT_TYPE_GARAGE_WORK ?? mainService.serviceType,
            vehicle: {
                id: getKtypeNr(),
            },
            included: includedRepairTimes.map(item => ({
                direction: 1,//including 
                id: item.repairTimeNo,
                name: item.description,
                sourceName: 'DVSE',
                value: item.repairTime ?? 0,
                type: window.DVSE?.RT_TYPE_GARAGE_WORK ?? item.serviceType,
            })),
            sourceId
        }]

        window.DVSE?.addRepairTimesToBasket?.(data);

    }
}