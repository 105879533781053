import { useReloadCisReturnItemsTotalNumbers, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Item, ReactQueryKeys } from "@tm/models"
import { useQuery, useQueryClient, useMutation, QueryClient } from "react-query"
import { ReturnItem, ShowReturnItemsResponse, SubmitReturnOrderRequest } from "../model"
import * as Data from "../repositories/cis/returnItems"
import { useReloadOrderDetails } from "./useOrderDetails"

const KEY_SHOW_RETURN_ITEMS = "cis_useReturnItems"
export function useShowReturnItems() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const enabled = enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY_SHOW_RETURN_ITEMS, telesalesCustomerNo, enabled],
        () => Data.showReturnItems({ customerNo: telesalesCustomerNo }),
        { enabled }
    )

    return { returnItems: data?.returnItems, returnItemsLoading: isLoading }
}

export function useSubmitReturnOrder() {
    const queryClient = useQueryClient()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(
        (body: Omit<SubmitReturnOrderRequest, "customerNo">) => Data.submitReturnOrder({ ...body, customerNo: telesalesCustomerNo }),
        {
            onSuccess: () => {
                queryClient.resetQueries(KEY_SHOW_RETURN_ITEMS)
                resetCisReturnItemsTotalNumbers()
            },
        }
    )

    return {
        submitReturnOrder: mutation.mutateAsync,
        returnOrderSending: mutation.isLoading,
        returnOrderResponse: mutation.data,
        returnOrderError: mutation.error,
        resetSubmitOrder: mutation.reset,
    }
}

export function useSubmitReturnOrderMail() {
    const queryClient = useQueryClient()
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(Data.submitReturnOrderMail, {
        onSuccess: () => {
            queryClient.resetQueries(KEY_SHOW_RETURN_ITEMS)
            queryClient.resetQueries(ReactQueryKeys.vouchers_useOrderDetails)
            resetCisReturnItemsTotalNumbers()
        },
    })

    return {
        submitReturnOrderEmail: mutation.mutateAsync,
        returnOrderEmailSending: mutation.isLoading,
        returnOrderEmailResponse: mutation.data,
        returnOrderEmailError: mutation.error,
        resetSubmitOrderEmail: mutation.reset,
    }
}

export function useRemoveReturnItems() {
    const queryClient = useQueryClient()
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(
        (returnItems: Array<ReturnItem>) => {
            return Data.removeReturnItemList(returnItems.map((returnItem) => returnItem.id))
        },
        {
            onSuccess: (_, returnItems) => {
                queryClient.resetQueries(KEY_SHOW_RETURN_ITEMS)
                queryClient.resetQueries(ReactQueryKeys.vouchers_useOrderDetails)
                returnItems.forEach((returnItem) => {
                    queryClient.resetQueries([ReactQueryKeys.vouchers_useOrderDetails, returnItem.voucherId])
                })
                resetCisReturnItemsTotalNumbers()
            },
        }
    )

    return { removeReturnItems: mutation.mutateAsync, isRemovingReturnItems: mutation.isLoading }
}

function updateReturnItemInCache(id: string, queryClient: QueryClient) {
    Data.showReturnItem({ id }).then((returnItemResponse) => {
        if (!returnItemResponse) {
            return
        }

        queryClient.setQueriesData<ShowReturnItemsResponse | undefined>(KEY_SHOW_RETURN_ITEMS, (prevItems) => {
            if (!prevItems?.returnItems) {
                return prevItems
            }

            return {
                returnItems: prevItems.returnItems.map((prev) =>
                    prev.id === returnItemResponse.returnItem.id ? returnItemResponse.returnItem : prev
                ),
            }
        })
    })
}

export function useIncludeItemsInReturn() {
    const queryClient = useQueryClient()
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(
        ({ include, items }: { include: boolean; items: Array<Item> }) =>
            include ? Data.includeItemsInReturn(items) : Data.excludeItemsFromReturn(items),
        {
            onSuccess: (_, request) => {
                request.items.forEach((item) => updateReturnItemInCache(item.id, queryClient))
                resetCisReturnItemsTotalNumbers()
            },
        }
    )

    return { includeInReturn: mutation.mutateAsync, isIncludingItemsInReturn: mutation.isLoading }
}

export function useChangeReturnItemQuantity() {
    const queryClient = useQueryClient()
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(Data.changeReturnQuantity, {
        onSuccess: (_, request) => {
            updateReturnItemInCache(request.item.id, queryClient)
            queryClient.resetQueries(ReactQueryKeys.vouchers_useOrderDetails)
            resetCisReturnItemsTotalNumbers()
        },
    })

    return { changeReturnItemQuantity: mutation.mutateAsync, isReturnQuantityChanging: mutation.isLoading }
}

export function useChangeReturnReason() {
    const queryClient = useQueryClient()
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(Data.changeReturnReason, {
        onSuccess: (_, request) => {
            updateReturnItemInCache(request.item.id, queryClient)
            resetCisReturnItemsTotalNumbers()
        },
    })

    return { changeReturnReason: mutation.mutateAsync, isChangingReturnReason: mutation.isLoading }
}

export function useEditReturnInfo() {
    const queryClient = useQueryClient()

    const mutation = useMutation(Data.editReturnInfo, {
        onSuccess: (_, request) => {
            updateReturnItemInCache(request.item.id, queryClient)
        },
    })

    return { editReturnInfo: mutation.mutateAsync, isChangingReturnInfo: mutation.isLoading }
}

export function useAddReturnItem() {
    const { invalidateShowOrderDetails } = useReloadOrderDetails()
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(Data.addReturnItem, {
        onSuccess: () => {
            invalidateShowOrderDetails()
            resetCisReturnItemsTotalNumbers()
        },
    })

    return { addReturnItem: mutation.mutateAsync }
}
