import { PropsWithChildren, useCallback, useMemo, useState } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { Article, ArticleAttribute, ArticleErpInfo } from "@tm/models"
import { ArticleListActionsProvider } from "../ArticleListActions"
import { PartsModuleStateProvider, usePartsModuleState } from "../PartsModuleState"
import { useShowArticleDetails } from "../hooks/useShowArticleDetails"
import { ArticleListActions, WholesalerArticleNumbersModuleState } from "../models"
import { useListOptions } from "../hooks/useListOptions"
import { useTradeReferences } from "../hooks/useTradeReferences"
import { useErpInfos } from "../hooks/useErpInfos"
import { useWatchListData } from "../hooks/useWatchListData"
import { useProductGroupRepairTimes } from "../hooks/useProductGroupRepairTimes"
import { useSupplierLogos } from "../hooks/useSupplierLogos"
import { useBasketQuantities, useWholesalerPartBasketQuantities } from "../hooks/useBasketQuantities"
import { useNotes } from "../hooks/useNotes"
import { useHasLoadingEnded } from "../hooks/useHasLoadingEnded"
import { useProductGroupTopicIds } from "../hooks/useProductGroupTopicIds"
import { useVehicle } from "../hooks/useVehicle"
import { useListParams } from "./hooks/useListParams"
import { useFiltersAndResults } from "./hooks/useFiltersAndResults"
import { mapWholesalerPartToArticle } from "../helpers"

export function WholesalerArticleNumberModuleStateProvider(props: PropsWithChildren<unknown>) {
    const [isEnabled, setIsEnabled] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([])

    const { workTaskId } = useWorkTask() ?? {}
    const vehicle = useVehicle()
    const options = useListOptions()
    const params = useListParams()
    const { filters, results, wholesalerNosArticles, isFiltered } = useFiltersAndResults(vehicle, params, isEnabled, true)
    const { productGroupTopicIds } = useProductGroupTopicIds(results.productGroupIds, isEnabled)
    const { tradeReferences, tradeReferenceNumbersLoaded } = useTradeReferences(results.articles, isEnabled)

    const erpArticles = useMemo(() => {
        const wholesalerToArticle = wholesalerNosArticles.map((x) => mapWholesalerPartToArticle(x))
        return [...results.articles, ...wholesalerToArticle]
    }, [results.articles, wholesalerNosArticles])

    const erpInfosData = useErpInfos({
        articles: erpArticles,
        isEnabled: isEnabled && tradeReferenceNumbersLoaded,
        startParams: params.startParams,
        erpInfos,
        setErpInfos,
        tradeReferences,
    })

    const supplierLogosData = useSupplierLogos(results.supplierIds, options.showDataSupplierLogos && isEnabled)
    const watchListData = useWatchListData(results.articles, !!options.hasWatchList && isEnabled && erpInfosData.isReady)
    const productGroupRepairTimesData = useProductGroupRepairTimes(results.productGroupIds, isEnabled && erpInfosData.isReady)
    const activeVehicleDataProvidersData = useActiveVehicleDataProviders(workTaskId)
    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(results.articles, isEnabled)
    const { basketQuantities: wholesalerBasketQuantities, updateBasketQuantities: updateWholesalerBasketQuantities } =
        useWholesalerPartBasketQuantities(wholesalerNosArticles, isEnabled)
    const notes = useNotes(results.articles, false, isEnabled && erpInfosData.isReady)

    const hasLoadingEnded = useHasLoadingEnded(
        supplierLogosData.isLoading,
        watchListData?.isLoading,
        productGroupRepairTimesData.isLoading,
        activeVehicleDataProvidersData.isLoading
    )

    const toggleInlineFilter = useCallback((attribute: ArticleAttribute, article: Article) => {
        params.toggleAttribute(attribute)
    }, [])

    const state: WholesalerArticleNumbersModuleState = {
        type: "default",
        params,
        start: useCallback(() => setIsEnabled(true), []),
        options,
        filters,
        articles: results,
        wholesalerNosArticles,
        isFiltered,
        supplierLogos: hasLoadingEnded ? supplierLogosData : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimes: hasLoadingEnded ? productGroupRepairTimesData : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProvidersData.activeProviders : undefined,
        basketQuantities,
        wholesalerBasketQuantities,
        productGroupTopicIds,
        notes,
        tradeReferences,
        erpInfosData,
    }

    const articleActionsValue: ArticleListActions = {
        toggleSupplier: filters.toggleSupplier,
        toggleInlineFilter,
        showArticleDetails,
        updateBasketQuantities,
        updateWholesalerBasketQuantities,
    }

    return (
        <PartsModuleStateProvider value={state}>
            <ArticleListActionsProvider value={articleActionsValue}>{props.children}</ArticleListActionsProvider>
        </PartsModuleStateProvider>
    )
}

export function useWholesalerArticleNumbersModuleState(): WholesalerArticleNumbersModuleState
export function useWholesalerArticleNumbersModuleState<TReturnType>(
    selector: (value: WholesalerArticleNumbersModuleState) => TReturnType
): TReturnType
export function useWholesalerArticleNumbersModuleState<TReturnType>(
    selector?: (value: WholesalerArticleNumbersModuleState) => TReturnType
): WholesalerArticleNumbersModuleState | TReturnType {
    return usePartsModuleState<WholesalerArticleNumbersModuleState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
