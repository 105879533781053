import { atom, selectorFamily, DefaultValue, useRecoilState, SetterOrUpdater, useSetRecoilState } from "recoil"

type OrderOptionsAtomType = {
    workTaskId?: string
    isDirty?: boolean
    warehouseId?: string
    distributorId?: number
    customerOrderComment?: string
    customerOrderNumber?: string
}

export type Selectors = {
    warehouseId?: string
    distributorId?: number
}

export const additionalOrderOptionsAtom = atom<OrderOptionsAtomType[]>({
    key: "basket.orderOptions.customerOrderOptionsAtom",
    default: [],
})

export const findCurrentActiveOption =
    (workTaskId: string, selectors: Selectors) =>
    (atomState: OrderOptionsAtomType): boolean => {
        if (selectors.warehouseId) {
            return atomState.workTaskId === workTaskId && atomState.warehouseId === selectors.warehouseId
        }

        if (selectors.distributorId) {
            return atomState.workTaskId === workTaskId && atomState.distributorId === selectors.distributorId
        }

        return atomState.workTaskId === workTaskId
    }

const additionalOrderOptionsSelector = selectorFamily<OrderOptionsAtomType | undefined, { workTaskId: string; selectors: Selectors }>({
    key: "basket.orderOptions.additionalOrderOptionsSelector",
    get:
        ({ workTaskId, selectors }) =>
        ({ get }) => {
            return get(additionalOrderOptionsAtom).find(findCurrentActiveOption(workTaskId, selectors))
        },
    set:
        ({ workTaskId, selectors }) =>
        ({ set }, newValue) => {
            if (newValue instanceof DefaultValue) {
                return
            }

            set(additionalOrderOptionsAtom, (prevState) => {
                const foundState = prevState.find(findCurrentActiveOption(workTaskId, selectors))

                if (foundState) {
                    return prevState.map((entry) => {
                        if (findCurrentActiveOption(workTaskId, selectors)(entry)) {
                            return { ...entry, ...newValue }
                        }

                        return entry
                    })
                }

                return prevState.concat({ ...newValue, ...prevState })
            })
        },
})

export const useMultiAdditionalOrderOption = (): OrderOptionsAtomType[] => {
    const [orderOptions] = useRecoilState<OrderOptionsAtomType[]>(additionalOrderOptionsAtom)
    return orderOptions
}

export function useSetAdditionalOrderOption() {
    return useSetRecoilState<OrderOptionsAtomType[]>(additionalOrderOptionsAtom)
}

export const useAdditionalOrderInformation = (
    workTaskId: string,
    selectors: Selectors
): [OrderOptionsAtomType | undefined, SetterOrUpdater<OrderOptionsAtomType | undefined>] => {
    return useRecoilState<OrderOptionsAtomType | undefined>(additionalOrderOptionsSelector({ workTaskId, selectors }))
}
