import { BundleComponent } from "@tm/morpheus"
import { reduce, receive, transmit } from "./business"
// import component from "./component"

const createTask: BundleComponent = {
    name: "toolbar-info",
    reduce,
    receive,
    transmit,
    component: () => null, // component,
}

export default createTask
