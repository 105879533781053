import { Box, Loader, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useCheckHasMemo, useModelDetails } from "@tm/utils"
import { Suspense } from "react"
import { StyledCarInfoIcon, StyledHeaderIcon } from "./styles"

type Props = {
}

function MemoInfoComponent(props: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()

    const vehicle = useWorkTask()?.workTask?.vehicle

    const { checkHasMemo, isMemoLoading } = useCheckHasMemo(true, vehicle?.manufacturer, vehicle?.tecDocTypeId, vehicle?.engineCode)

    const { modelDetails } = useModelDetails({
        vehicleType: vehicle?.vehicleType,
        modelId: vehicle?.tecDocTypeId,
    }) || {}

    if(!checkHasMemo?.hasMemoInfo) {
        return null
    }

    if(isMemoLoading || !vehicle) {
        return <Loader />
    }

    return (
        <Box m={"1em 0.5em"}>
            <Typography variant="h2">
                <StyledHeaderIcon name="atu-logo-filled" />
                {translateText(465)}
            </Typography>

            {!!vehicle && !!modelDetails && <Box display={"flex"} flexWrap={"wrap"} m={"2em 0"}>
                <StyledCarInfoIcon name="car-front" />
                <Typography> {modelDetails.fullDescription} | </Typography>
                <Typography variant="body1"> {translateText(329)}/{translateText(330)}: {modelDetails.enginePowerHp} / {modelDetails.enginePowerKw} | </Typography>
                <Typography variant="body1" > {translateText(331)}: {modelDetails.engineCapacityCcm} | </Typography>
                <Typography variant="body1"> {translateText(105)}: {vehicle.engineCode} | </Typography>
            </Box>}


            {renderMicro("memo-tool", "memo-info", {
                isModal: true,
                vehicleId: vehicle?.id,
                manufacturer: vehicle?.manufacturer
            })}
        </Box>
    )
}

function MemoInfoWrapper(props: Props) {
    return (
        <Suspense fallback={<Loader />}>
            <MemoInfoComponent {...props} />
        </Suspense>
    );
}

export default MemoInfoWrapper
