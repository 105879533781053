import { Button, Dialog, Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    dialogActive: boolean
    closeDialog: () => void
}

function Newsletter({ dialogActive, closeDialog }: Props) {
    const { translateText } = useLocalization()

    return (
        <Dialog open={dialogActive} onOutsideClick={closeDialog}>
            <Stack direction="row" justifyContent="space-between">
                <Typography variant="h2" color="highlight.main" fontWeight={700}>
                    {translateText(13199).toUpperCase()}
                </Typography>
                <Button onClick={closeDialog} startIcon={<Icon name="close" />} />
            </Stack>

            <Typography variant="h4" fontWeight={700}>
                {translateText(13750)}
            </Typography>

            <Stack spacing={1}>
                <Typography variant="body2" color="secondary.main" fontWeight={700}>
                    {translateText(13751)}
                </Typography>
                <iframe title="newsletter" src="https://newsletter.wmvserver.kiwi.de/iframe-register.php" width="100%" height="450px" />{" "}
            </Stack>

            <Typography variant="body2">
                {`${translateText(13752)} `}
                <a href="https://www.wm.de/de/sortiment-leistungen/newsletter-abmeldung" target="_blank" rel="noreferrer">
                    {translateText(13755)}
                </a>
                .
            </Typography>
        </Dialog>
    )
}

export default Newsletter
