import { useMicro } from "@tm/morpheus"
import { VoucherType, IMicros } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Collapsible } from "@tm/controls"
import { Box, Divider, Typography, Paper, Loader, Stack } from "@tm/components"
import VoucherHeader from "../_shared/VoucherDetailsHeader"
import OfferDetails from "./components/OfferDetails"
import CustomerOverview from "./components/CustomerOverview"
import { useCostEstimationDetails } from "../../../../business/hooks/useCostEstimationDetails"
import { useCostEstimationActionButtonsConnection } from "../../../../business/hooks/action-buttons-connection"
import Parts from "./components/parts"
import Works from "./components/works"
import CostEstimationTotalNumbers from "./components/CostEstimationTotalNumbers"
import VehicleImage from "./components/VehicleImage"
import OfferNote from "./components/OfferNote"
import { AllTotals } from "./components/totals/AllTotals"

type Props = {
    voucherId: string | undefined
    isBigScreen: boolean
}

export default function CostEstimationDetails({ voucherId, isBigScreen }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const { costEstimationDetails, costEstimationDetailsLoading, toggleSelected, selectAll, unselectAll, selectedIds } =
        useCostEstimationDetails(voucherId)
    useCostEstimationActionButtonsConnection(costEstimationDetails)

    if (costEstimationDetailsLoading) {
        return <Loader />
    }
    if (!costEstimationDetails) {
        return <Typography>{translateText(323)}</Typography>
    }
    const { totalNumbers, costEstimation, vehicle, creationDate, voucherNumber, customer, repairShopLogo } = costEstimationDetails
    const { details, currencyCode, currencySymbol, totalsB2B, totalsB2C, costEstimationOptions } = costEstimation || {}
    const { memo, validityPeriod, workSchedule } = details || {}
    const { priceTypeMode, customerGroupMode } = costEstimationOptions || {}

    function renderCostEstimationTotalNumbers() {
        return (
            <CostEstimationTotalNumbers partsPositionCount={totalNumbers?.partsPositionCount} worksPositionCount={totalNumbers?.worksPositionCount} />
        )
    }

    const registrationDate = vehicle?.initialRegistration ? new Date(vehicle.initialRegistration) : undefined
    let totalPurchasePrice
    let totalRetailPrice
    // Purchase
    if (priceTypeMode) {
        totalPurchasePrice = customerGroupMode ? totalsB2B?.offerPrice : totalsB2C?.offerPriceVatIncluded
    } else {
        totalRetailPrice = customerGroupMode ? totalsB2B?.offerPrice : totalsB2C?.offerPriceVatIncluded
    }

    return (
        <Stack spacing={2} divider={<Divider light />}>
            <VoucherHeader
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                orderDate={creationDate}
                voucherNumber={voucherNumber}
                totalPurchasePrice={totalPurchasePrice}
                totalRetailPrice={totalRetailPrice}
                type={VoucherType.CostEstimation}
            />
            <CustomerOverview customer={customer} repairShopLogo={repairShopLogo} />
            <Paper>
                <Collapsible name={vehicle?.displayName || translateText(99)} initiallyOpened renderHeaderAppendix={renderCostEstimationTotalNumbers}>
                    <Box px={2} pb={2}>
                        {renderMicro("crm", "vehicle-overview", {
                            readOnly: true,
                            description: vehicle?.displayName || "",
                            plateId: vehicle?.plateId || "",
                            initialRegistration: registrationDate,
                            mileAge: vehicle?.mileage || 0,
                            vin: vehicle?.vin || "",
                        })}
                        {vehicle?.vehicleImage && <VehicleImage base64Image={vehicle.vehicleImage} />}
                        <Parts
                            costEstimation={costEstimation}
                            voucherId={voucherId}
                            selectedIds={selectedIds}
                            vehicleId={vehicle?.id}
                            isBigScreen={isBigScreen}
                            onSelectAll={selectAll}
                            onSelectPart={toggleSelected}
                            onUnselectAll={unselectAll}
                        />
                        <Works costEstimation={costEstimation} />
                    </Box>
                </Collapsible>
            </Paper>
            <AllTotals costEstimation={costEstimation} />
            <OfferDetails workSchedule={workSchedule} validityPeriod={validityPeriod} />
            <OfferNote note={memo} />
        </Stack>
    )
}
