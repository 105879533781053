import { useLocalization } from "@tm/localization"
import { List, Text, Headline } from "@tm/controls"
import { Article } from "@tm/models"
import ArticleListItemView from "./select-article-list-item"

type Props = {
    articles: Array<Article>
    selectArticle(article: Article): void
}

export default function SelectArticlesComponent({ articles, selectArticle }: Props) {
    const { translateText } = useLocalization()

    function mapArticleToListItem(article: Article) {
        return {
            article,
            selectArticle,
        }
    }

    function renderList() {
        return <List className="part-list" items={articles.map(mapArticleToListItem)} view={ArticleListItemView} />
    }

    return (
        <div className="tk-cis select-articles parts-list article-list">
            <div className="select-articles__header">
                <div className="content">
                    <Headline size="l">
                        {translateText(389)} - {translateText(1324)}
                    </Headline>
                    <Text>{translateText(1325)}</Text>
                </div>
            </div>
            <div className="select-articles__content">{!!articles.length && renderList()}</div>
        </div>
    )
}
