import { lighten, useTheme } from "@tm/components"
import tinycolor from "tinycolor2"

export function useSummaryTheme() {
    const theme = useTheme()
    const basketSummaryTheme = theme.overwrites?.components?.basketSummary
    const highlightColor =
        basketSummaryTheme?.summaryTotals?.backgroundColor || basketSummaryTheme?.component?.backgroundColor || theme.palette.highlight.main
    const styledBoxBackgroundColor =
        theme.overwrites?.components?.basketSummary?.summaryDetails?.backgroundColor || lighten(theme.palette.common.black, 0.3)
    const styledBoxPadding = theme.spacing(1.5, 2)
    const boxShadow = theme.overwrites?.components?.basketSummary?.component?.boxShadow || "none"

    const linkButtonTheme = basketSummaryTheme?.linkButton
    const componentBackgroundColor = basketSummaryTheme?.component?.backgroundColor
    const linkButtonVariant: "textLight" | "text" = linkButtonTheme?.variant || tinycolor(componentBackgroundColor).isDark() ? "textLight" : "text"
    const contrastColor = linkButtonVariant === "textLight" ? theme.palette.common.white : theme.palette.common.black
    const linkButtonBorderRadius = linkButtonTheme?.borderRadius
    const linkButtonFontWeight = linkButtonTheme?.fontWeight
    const linkButtonFontFamily = linkButtonTheme?.fontFamily
    const linkButtonSize = linkButtonTheme?.size
    const linkButtonTextColor =
        linkButtonVariant === "text" && componentBackgroundColor !== theme.palette.highlight.main ? theme.palette.highlight.main : undefined

    return {
        boxShadow,
        componentBackgroundColor,
        contrastColor,
        highlightColor,
        linkButtonBorderRadius,
        linkButtonFontFamily,
        linkButtonFontWeight,
        linkButtonSize,
        linkButtonTextColor,
        linkButtonVariant,
        styledBoxBackgroundColor,
        styledBoxPadding,
    }
}
