import { CarmunicationBundleParams } from "@tm/models"
import Morpheus from "@tm/morpheus"

export const getBundleParams = (): CarmunicationBundleParams => {
    const bundleParams = {
        ...Morpheus.getParams("carmunication")
    }

    if (!bundleParams) {
        console.warn(`The toolkit params have to be set in order to use the bundle "TMtires"`)
    }

    return bundleParams
}
