import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Button } from "@tm/controls"
import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { bundleChannel } from "../../business"

function handleClick() {
    bundleChannel().publish("APPLY_BUTTON_CLICKED", true)
    TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSelectVehicle)
}

export default function ApplyButtonComponent() {
    const [disabled, setDisabled] = useState(true)
    const { translateText } = useLocalization()

    useEffect(() => {
        return bundleChannel().subscribe("SET_APPLY_BUTTON_STATUS", (status) => setDisabled(status.disabled))
    }, [setDisabled])

    return (
        <Button skin="success" size="l" disabled={disabled} onClick={handleClick}>
            {translateText(22)}
        </Button>
    )
}
