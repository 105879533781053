import { getStoredAuthorization, ajax } from "@tm/utils"
import { getCisServiceUrl } from "../.."
import { ShowToursResponse } from "../../model"

function getServiceUrl() {
    return `${getCisServiceUrl()}/Tours`
}

export function showTours(body: { customerNo?: string }) {
    const url = `${getServiceUrl()}/ShowTours`
    const authorization = getStoredAuthorization()
    return ajax<ShowToursResponse>({ url, body, authorization }, undefined, undefined, true)
}
