import { Container } from "@tm/nexus"
import { RegisteredModels } from "@tm/models"
import { getBundleParams } from "../utils"
import { addReturnItem, showTotalNumbers } from "./repositories/cis/returnItems"
import { loadCurrentCaller, showCustomer, findCustomers, getQueryTypes } from "./repositories/cis/customer"
import { showAllOrders, showOpenOrders, showOrdersByArticle } from "./repositories/cis/vouchers"
import { showCisOptions } from "./repositories/cis/options"

export * from "./repositories/cis/customer"
export * from "./repositories/cis/invoices"
export * from "./repositories/cis/options"
export * from "./repositories/cis/returnItems"
export * from "./repositories/cis/tours"
export * from "./repositories/cis/vouchers"

export function registerActions() {
    Container.register({
        name: RegisteredModels.CIS_Customer,
        modelActions: {
            load: showCustomer,
        },
        containerActions: {
            loadCurrentCaller,
            findCustomers,
            getQueryTypes,
        },
    })
    Container.register({
        name: RegisteredModels.CIS_ShowTotalNumbers,
        modelActions: {
            load: showTotalNumbers,
        },
    })
    Container.register({
        name: RegisteredModels.CIS_ShowAllOrders,
        modelActions: {
            load: showAllOrders,
        },
    })
    Container.register({
        name: RegisteredModels.CIS_ShowOpenOrders,
        modelActions: {
            load: showOpenOrders,
        },
    })
    Container.register({
        name: RegisteredModels.CIS_ShowOrdersByArticle,
        modelActions: {
            load: showOrdersByArticle,
        },
    })
    Container.register({
        name: RegisteredModels.CIS_ShowCisOptions,
        modelActions: {
            load: showCisOptions,
        },
    })
    Container.register({
        name: RegisteredModels.CIS,
        containerActions: {
            addReturnItem,
        },
    })
}

export function getCisServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.cisServiceUrl
}
