import { useState, useRef, useEffect } from "react"
import { Icon } from "@tm/controls"
import { style } from "typestyle"
import { registerOutsideClick } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import DocumentDialog, { DocumentCategoryType } from "./document-form"
import { MetaDataType } from "../../../../../data"
import { MergedVehicle } from "../../../../../data/hooks"
import { bundleChannel } from "../../../../../bundle-channel"

type Props = {
    attachment?: AttachmentType
    setAttachment(attachment?: AttachmentType, defaultMessage?: string): void
    vehicles: Array<MergedVehicle>
}

export type AttachmentType = { metaData: MetaDataType } | FileAttachment

export type FileAttachment = { file: string | Blob; mimeType: string; metaData: FileAttachmentMetaData }

export type DocumentAdditionalData = { mileage?: number; date?: Date; invoiceTotal?: number }

export type FileAttachmentMetaData =
    | ({ type: "attachment"; name: string; category?: DocumentCategoryType; externalVehicleId: string } & DocumentAdditionalData)
    | CostEstimateMetaData

export type CostEstimateMetaData = {
    type: "costEstimate"
    offerId: string
    externalOffer?: boolean
    name: string
    description: string
    externalVehicleId: string
} & DocumentAdditionalData

export default function Attachment({ attachment, setAttachment, vehicles }: Props) {
    const { translateText } = useLocalization()
    const [showPopover, setShowPopover] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [showDialog, setShowDialog] = useState<{ category?: DocumentCategoryType; externalVehicleId?: string }>()

    useEffect(() => {
        if (wrapperRef.current && showPopover) {
            return registerOutsideClick(wrapperRef.current, () => setShowPopover(false))
        }
    }, [wrapperRef.current, setShowPopover, showPopover])

    useEffect(() => {
        bundleChannel("CHAT").subscribe("OPEN_DOCUMENT_FORM", ({ category, externalVehicleId }) => {
            setShowDialog({ category, externalVehicleId })
        })
    }, [])

    return (
        <div className={style({ position: "relative", alignSelf: "center" })} ref={wrapperRef}>
            {showDialog && (
                <DocumentDialog
                    onSave={(document) => {
                        setShowDialog(undefined)
                        if (document.metaData.type === "attachment" && document.metaData.category === "Invoice") {
                            setAttachment(document, translateText(3091))
                        } else {
                            setAttachment(document)
                        }
                    }}
                    onClose={() => setShowDialog(undefined)}
                    vehicles={vehicles}
                    initialCategory={showDialog.category}
                    initialExternalVehicleId={showDialog.externalVehicleId}
                />
            )}
            <div
                className={style({ lineHeight: 0, cursor: "pointer", padding: "0 0.5rem", opacity: "0.65" })}
                onClick={() => {
                    setShowDialog({})
                }}
            >
                <Icon name="paperclip" size="xl" skin={attachment ? "primary" : undefined} />
            </div>
        </div>
    )
}
