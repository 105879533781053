import { TmaEModule, channel, OE } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenArticleList(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openArticleList, sourceId } = data

    if (!openArticleList) {
        return
    }

    switch (sourceId) {
        case "TM_MVC_DAT":
            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchContextV2(TmaEModule.DAT_FAST_CALCULATION, "")
            openArticleList.skipTma = true

            const vehicle = this.props.workTask?.vehicle
            const manufacturerId = vehicle?.tecDocManufacturerId ?? 0
            const manufacturerName = vehicle?.manufacturer

            openArticleList.oePositions?.forEach((oePosition) => {
                oePosition.parts?.forEach((part) => setArticleOrigin(part, manufacturerId, manufacturerName))

                oePosition.replacements?.forEach((replacement) => {
                    replacement.parts?.forEach((part) => setArticleOrigin(part, manufacturerId, manufacturerName))
                })
            })
            break

        default:
            break
    }

    channel("WORKTASK").publish("PARTS/REQUEST_LIST", openArticleList)
}

function setArticleOrigin(part: OE.OePart, manufacturerId?: number, manufacturerName?: string) {
    // eslint-disable-next-line no-param-reassign
    part.oeArticleOrigin = {
        module: OE.OeArticleModule.GraphicalPartsIdentification,
        provider: OE.OeArticleProvider.DAT,
    }

    if (!part.manufacturerId && manufacturerId) {
        // eslint-disable-next-line no-param-reassign
        part.manufacturerId = manufacturerId
    }

    if (!part.manufacturerName && manufacturerName) {
        // eslint-disable-next-line no-param-reassign
        part.manufacturerName = manufacturerName
    }
}
