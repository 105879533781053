import { Button, Box, styled, Loader, Badge } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, DATOePart, IMicros, RepairTimeProvider, RepairTimeRD, SystemType } from "@tm/models"
import { useActions, useMicro } from "@tm/morpheus"
import { getSystemTypeFromUserContext, useWindowSize } from "@tm/utils"
import { useSelector } from "react-redux"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { createMemo } from "../../../data/helpers"
import { calculationSelector, managerSelector } from "../../../selectors"
import { mapIAMPartToArticle, mapWorkToService } from "../../calculation/business/mappers"
import { MainActions } from "../../main/business"
import { Totals } from "../../_shared"
import { useBasketImports } from "../../../../../basket/src/hooks/basketState/useBasketImports"
import { useCostEstimationModuleParameter } from "../../../../../basket/src/hooks/useCostEstimationModuleParameter"

type Props = {
    showPrices?: boolean
}

export default function CalculationActions({ showPrices }: Props) {
    const user = useUser()
    const workTask = useWorkTask()
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()
    const { importToCostEstimation } = useBasketImports()
    const { importLoading, updateTotalsFailed, items, works, erpArticles } = useSelector(calculationSelector)
    const { vehicle, customer } = useSelector(managerSelector)
    const selectedItems = items?.some((x) => x.isSelected)
    const selectedWorks = works?.some((x) => x.isSelected)
    const disabled = updateTotalsFailed || importLoading || (!selectedItems && !selectedWorks)
    const totalsParentRef = useRef<HTMLElement>(null)
    const totalsChildRef = useRef<HTMLElement>(null)
    const [showAsTooltip, setShowAsTooltip] = useState(false)
    const [width] = useWindowSize()

    const actions = useActions(MainActions, "addToShoppingBasket")

    const { hasCostEstimation } = useCostEstimationModuleParameter()

    useEffect(() => {
        showAsTooltip && setShowAsTooltip(false)
    }, [width])

    useLayoutEffect(() => {
        if (
            showPrices &&
            (!totalsChildRef.current || (totalsChildRef.current?.offsetWidth || 0) > (totalsParentRef.current?.offsetWidth || 0) - 20)
        ) {
            setShowAsTooltip(true)
        }
    }, [showPrices])

    const handleAddToBasket = () => {
        const memo = user?.userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(vehicle, customer) : undefined

        actions.addToShoppingBasket(
            translateText,
            importToCostEstimation,
            user?.userSettings?.repairTimeOptions?.division,
            workTask?.workTaskId,
            memo
        )
    }

    const renderKVAButton = () => {
        if (hasCostEstimation && getSystemTypeFromUserContext(user?.userContext) === SystemType.Next) {
            return (
                <Badge invisible={!importLoading} badgeContent={<Loader size="extrasmall" />}>
                    <Button size="medium" color="highlight" onClick={handleAddToBasket} disabled={disabled}>
                        {translateText(1607)}
                    </Button>
                </Badge>
            )
        }

        if (getSystemTypeFromUserContext(user?.userContext) === SystemType.Redesign) {
            const selectedOeArticles: DATOePart[] = items
                ?.filter((x) => x.isSelected && !x.selectedPart)
                .map((x) => ({ ...x.oeArticle, replacements: x.alternativeParts?.map((alternativePart) => alternativePart.supplierArticleNo) }))
            const selectedIAMArticles: Article[] = items
                ?.filter((x) => x.isSelected && x.selectedPart)
                .map((x) =>
                    mapIAMPartToArticle(
                        x.selectedPart!,
                        erpArticles?.find((y) => y.dataSupplierArticleNumber === x.selectedPart!.supplierArticleNo)
                    )
                )

            const selectedRepairTimeWorks: RepairTimeRD[] = works?.filter((x) => x.isSelected).map(mapWorkToService)

            return renderMicro("standalone", "rd-add-articles-rt-to-basket", {
                oeArticles: selectedOeArticles,
                iamArticles: selectedIAMArticles,
                services: selectedRepairTimeWorks,
                repairTimeDivision: user?.userSettings?.repairTimeOptions?.division,
                provider: RepairTimeProvider.Dat,
                vehicle,
                sourceId: "TM_DMG_DAT",
                buttonText: translateText(358),
                useDefaultButtonStyle: true,
            })
        }

        return null
    }

    return (
        <Box ref={totalsParentRef} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", flexWrap: "wrap-reverse" }}>
            {showPrices && (
                <Box ref={totalsParentRef} sx={{ display: "flex", flex: 1, justifyContent: "flex-end", mt: ".5em", mr: "1em" }}>
                    <StyledTotals>
                        <Totals ref={totalsChildRef} showInHeader showAsTooltip={showAsTooltip} />
                    </StyledTotals>
                </Box>
            )}

            {renderKVAButton() && <StyledKVAToolbar title={translateText(916)}>{renderKVAButton()}</StyledKVAToolbar>}
        </Box>
    )
}

const StyledTotals = styled(Toolbar)(() => ({
    flex: 1,
    justifyContent: "flex-end",
    "&:before": {
        content: "unset",
    },
    ".toolbar__inner": {
        padding: 0,
    },
}))

const StyledKVAToolbar = styled(Toolbar)(() => ({
    ".toolbar__content": {
        justifyContent: "right",
    },
}))
