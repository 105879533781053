import { useLocalization } from "@tm/localization"
import { PriceType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { CellContentPosition, TableCellData, TableColumnData } from "@tm/components"
import { VoucherItem } from "../../../../../data/model"
import { StyledTable } from "../../../../_shared/StyledComponents"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import PriceCell from "../../../../_shared/cells/PriceCell"
import ArticleDescriptionCell from "../../../../_shared/cells/ArticleDescriptionCell"
import VoucherItemOptionsCell from "../../../../_shared/cells/VoucherItemOptionsCell"
import ArticleNumberCell from "../../../../_shared/cells/ArticleNumberCell"

type Props = {
    currencyCode?: string
    voucherItems: Array<VoucherItem>
}

export default function WmDeliveryNotesItemsTableComponent({ currencyCode, voucherItems }: Props) {
    const { translateText, currency } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    function getColumns() {
        const moduleHeader: TableColumnData[] = [
            { header: translateText(1310) },
            { header: translateText(477) },
            { header: translateText(377) },
            { header: translateText(56), alignContent: CellContentPosition.right },
        ]
        if (!hidePurchasePrice) {
            moduleHeader.push({ header: translateText(1625), alignContent: CellContentPosition.right })
        }
        moduleHeader.push(
            { header: translateText(782), alignContent: CellContentPosition.center },
            { header: translateText(1396), alignContent: CellContentPosition.center }
        )

        if (!hidePurchasePrice) {
            moduleHeader.push({ header: translateText(1197), alignContent: CellContentPosition.right })
        }
        moduleHeader.push({ header: translateText(1198), alignContent: CellContentPosition.right }, {})

        return moduleHeader
    }

    function getCells(voucherItem: VoucherItem) {
        const cellData: TableCellData[] = [
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="wholesaler" />, id: "item_1" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="supplier" />, id: "item_2" },
            {
                displayValue: <ArticleDescriptionCell supplierName={voucherItem.supplierName} articleDescription={voucherItem.articleDescription} />,
                id: "item_3",
            },
            {
                displayValue: <PriceCell priceType={PriceType.Retail} prices={voucherItem.prices} quantityUnit={voucherItem.quantityUnit} />,
                id: "item_4",
            },
        ]
        if (!hidePurchasePrice) {
            cellData.push({
                displayValue: <PriceCell priceType={PriceType.Purchase} prices={voucherItem.prices} quantityUnit={voucherItem.quantityUnit} />,
                id: "item_5",
            })
        }
        cellData.push(
            { displayValue: `${voucherItem.quantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_6" },
            { displayValue: `${voucherItem.returnQuantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_7" }
        )

        if (!hidePurchasePrice) {
            cellData.push({
                displayValue: currency(voucherItem.totalNet || 0, currencyCode || voucherItem.currencyCode),
                id: "item_8",
            })
        }
        cellData.push(
            {
                displayValue: currency(voucherItem.totalGross || 0, currencyCode || voucherItem.currencyCode),
                id: "item_9",
            },
            { displayValue: <VoucherItemOptionsCell item={voucherItem} showAddToCompilations isWm />, id: "item_10" }
        )

        return cellData
    }

    const displayData = voucherItems.map((voucherItem, index) => ({
        cells: getCells(voucherItem),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} />
}
