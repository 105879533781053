import { getStoredAuthorization, ajax } from "@tm/utils"
import { GetExternalTokenOptions } from "@tm/models"
import { getBundleParams } from "../../utils"
import { AuthToken } from "../../components/iframe-module/business"
import { ExternalToken, News } from "../models"

const tokenRequestRepo: { [key: string]: Promise<AuthToken> } = {}

export function getUnreadNews(fetchAdditionalInformation?: boolean): Promise<Array<News>> {
    return ajax({
        url: `${getBundleParams().authorityServiceUrl}/Authority/GetUnreadNews`,
        authorization: getStoredAuthorization(),
        body: { fetchAdditionalInformation },
        languageAsCustomerHeader: true,
    }).then((response) => response?.news)
}

export function setNewsRead(newsId: string): Promise<void> {
    return ajax({
        url: `${getBundleParams().authorityServiceUrl}/Authority/SetNewsRead`,
        authorization: getStoredAuthorization(),
        body: { newsId },
    })
}

function cacheToken(itemKey: string, token: ExternalToken) {
    sessionStorage.setItem(itemKey, JSON.stringify(token))
}

function getCachedToken(itemKey: string) {
    const tokenItem = sessionStorage.getItem(itemKey)

    if (tokenItem) {
        const token = JSON.parse(tokenItem) as ExternalToken
        if (Date.now() < token.expiration * 1000) {
            // unix timestamp * 1000 to compare with js timestamp
            return token
        }
        sessionStorage.removeItem(itemKey)
    }
}

function resetRequest(type: string, key?: string) {
    const repoKey = `${type}${key || ""}`
    if (Object.keys(tokenRequestRepo).indexOf(repoKey) !== -1) {
        delete tokenRequestRepo[repoKey]
    }
}

function getRequest(type: string, options?: GetExternalTokenOptions) {
    const { key, id, props, telesalesCustomerNo } = options || {}
    return new Promise<AuthToken>((resolve, reject) => {
        const itemKey = `token://${type}${key || id ? `:${key || id}` : ""}`

        const token = getCachedToken(itemKey)
        if (token) {
            resolve(token)
            return
        }

        const url = `${getBundleParams().authorityServiceUrl}/externalauthentication/GetExternalToken`
        const authorization = getStoredAuthorization()
        const body = { type, key, id, props, telesalesCustomerNo }

        ajax<ExternalToken>({ url, authorization, body, method: "GET" }).then(
            (response) => {
                if (response) {
                    cacheToken(itemKey, response)
                    resolve(response)
                } else {
                    reject(new Error("No token returned"))
                }
                resetRequest(type, key)
            },
            (reason) => reject(reason)
        )
    })
}

/**
 *
 * @param type - The name of the token handler in the service
 * @param options
 * @returns
 */
export function getExternalToken(type: string, options?: GetExternalTokenOptions): Promise<AuthToken> {
    const { key, id } = options || {}
    const repoKey = `${type}${key || id || ""}`
    if (!tokenRequestRepo[repoKey]) {
        tokenRequestRepo[repoKey] = getRequest(type, options)
    }

    return tokenRequestRepo[repoKey]
}

export function existsTokenInCache(type: string, key?: string, id?: string) {
    const itemKey = `token://${type}${key || id ? `:${key || id}` : ""}`
    return !!sessionStorage.getItem(itemKey)
}
