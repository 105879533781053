import { Alert, LinearProgress, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"

type Props = {
    seconds: number
    onClose: () => void
    onAbort?: () => void
}

export function AutoCloseInfo(props: Props) {
    const { onClose, onAbort, seconds } = props
    const { translateText } = useLocalization()
    const [progress, setProgress] = useState(0)

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((oldProgress) => {
                if (oldProgress === 100) {
                    onClose()
                }

                // 100% divided by number of seconds * 4, interval is set to 1/4 second
                const steps = 100 / (seconds * 4)

                return Math.min(oldProgress + steps, 100)
            })
        }, 250) // 1/4 seconds in milliseconds, small intervall to display a smooth progress

        return () => {
            clearInterval(timer)
        }
    }, [onClose, seconds])

    return (
        <Alert variant="outlined" severity="info" onClose={onAbort} sx={{ ".MuiAlert-message": { width: "100%" } }}>
            <Stack spacing={1}>
                <Typography>{translateText(13647).replace("{0}", seconds.toString())}</Typography>
                <LinearProgress variant="determinate" value={progress} />
            </Stack>
        </Alert>
    )
}
