import { Box } from "@tm/components"
import { CostEstimationVoucher } from "../../../data/model"

export type Props = {
    costEstimation: CostEstimationVoucher
    showCustomerNumber?: boolean
}

export default function CustomerInfo({ costEstimation, showCustomerNumber }: Props) {
    const { customerNumber, customerFirstName, customerLastName } = costEstimation
    return (
        <Box display="flex">
            {showCustomerNumber ? <Box mr={1}>{customerNumber}</Box> : <></>}
            <Box>
                {customerFirstName ? `${customerFirstName} ` : ""}
                {customerLastName || ""}
            </Box>
        </Box>
    )
}
