import { DamageCalculationGlassBundleParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createSelector } from "reselect"
import { isDevtoolEnabled } from "@tm/utils"
import { initRepositories } from "./data/repositories"

export const version = {
    name: "dmg-glass",
    version: "2.0",
}

let bundleParams: DamageCalculationGlassBundleParams

export function initDamageCalculationGlassBundle(params: DamageCalculationGlassBundleParams) {
    bundleParams = bundleParamsMapper(params)
    checkBundleParams(params)
    initRepositories()
    return bundleParams
}

function checkBundleParams({ glassServiceUrl }: DamageCalculationGlassBundleParams) {
    if (!isDevtoolEnabled()) {
        return
    }

    if (!glassServiceUrl) {
        console.warn("Glass service url is not passed")
    }
}

const bundleParamsMapper = createSelector(
    (x: DamageCalculationGlassBundleParams) => x,
    (params: DamageCalculationGlassBundleParams) => ({
        ...params,
        enableDATVinSearch: Morpheus.getParams("vehicle")?.enableDATVinSearch || false,
    })
)

export function getBundleParams() {
    if (!bundleParams) {
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}
