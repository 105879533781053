export enum FileCategory {
    Others = 3060,
    CostEstimate = 3019,
    Order = 3026,
    Invoice = 656,
    VehicleInspectionCertificate = 3010,
    EmissionsTestCertificate = 3011,
    GasSafetyInspection = 3035,
    AccidentPreventionRegulations = 3072,
    MaintenanceProtocol = 3078,
    AcceptanceProtocol = 3023,
    RegistrationOfVehicle = 3022,
    DeregistrationOfVehicle = 3014,
    CompletionCertificate = 3071,
    TakebackCertificate = 3056,
    VehicleRegistration = 3087,
    VehicleRegistrationDocument = 3092,
    TaxAssessment = 3061,
    Policies = 3052,
    InsuranceRepairAuthorisation = 3055,
    DamageReport = 3058,
    RecallReplacement = 3057,
    AccidentalDamage = 12843,
    PaintDamage = 12844,
    Defects = 12845,
    WheelCertificates = 13788,
}

export type CategoryDropDownProps = {
    id: number
    categoryName: string
    subCategories: FileCategory[]
}
