import { Note } from "@tm/models"
import { ajax, createBufferedRequestFunction, getStoredAuthorization } from "@tm/utils"
import { getWorktaskServiceUrl } from ".."

const findNotes = (workTaskIds: string[]): Promise<Note[]> => {
    const url = `${getWorktaskServiceUrl()}/Note/GetList`
    const authorization = getStoredAuthorization()

    return ajax<{ notes?: Note[] }>({ url, body: { noteIds: workTaskIds }, authorization, method: "POST" }).then((response) => response?.notes || [])
}

export const findNotesBuffered = createBufferedRequestFunction<string[], Note[], Note[]>({
    callService: (requests) => findNotes(requests.flat()),
    mapServiceResponseToResponse: (serviceResponse, request) => {
        const includeNoteForArticle = serviceResponse.filter((workTaskNote) =>
            request.some((noteId) => {
                return workTaskNote.noteId === noteId
            })
        )

        return includeNoteForArticle.length ? includeNoteForArticle : []
    },
})

export const saveNote = (body: Note): Promise<void> => {
    const url = `${getWorktaskServiceUrl()}/Note/Save`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export const deleteNote = (noteId: string): Promise<void> => {
    const url = `${getWorktaskServiceUrl()}/Note/Delete`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body: { noteId }, authorization, method: "POST" })
}
