import { DateField, Radio, SuggestionTextField, TextField, VinField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EFuelTypes, FuelType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { getValue, initSelector, ModelState } from "@tm/utils"
import * as React from "react"
import { useSelector } from "react-redux"
import { Actions } from "../../bussiness"
import { dateToString, hasFieldError, stringToDate } from "../../bussiness/helpers"
import { DocumentsState } from "../../bussiness/model"
import { fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { Field } from "../common"

type Props = {}

const selector = initSelector(fieldsSelector)

const DocumentVehicleTab: React.FC<Props> = () => {
    const { translateText } = useLocalization()
    const [model] = useSelector(selector)
    const actions = useActions(Actions, "updateField")
    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText)) as unknown as ModelState
    const fuelTypes = useSelector((s: DocumentsState) => s.fuelTypes)
    if (!model) {
        return null
    }
    const handleCheckboxChange = (path: Array<string>, value: EFuelTypes | string) => {
        if (getValue(model, path) == value) {
            value = ""
        }
        actions.updateField(path, value)
    }

    const handleVehicleFuelTypeChange = (item: FuelType) => {
        actions.updateField(["vehicleInfo", "fuelType"], item.value)
        actions.updateField(["vehicleInfo", "fuelTypeId"], item.id)
    }

    const handleDateFieldChange = (path: Array<string>, date: Date) => {
        actions.updateField(path, dateToString(date))
    }

    return (
        <>
            <Field.Container>
                <Field.Item m={6} title={translateText(1566)} mandatory={modelState["receitInfo.number"]}>
                    <TextField
                        showClear
                        value={model.receitInfo.number}
                        onChange={actions.updateField.bind(this, ["receitInfo", "number"])}
                        className={hasFieldError(modelState["receitInfo.number"])}
                    />
                </Field.Item>
                <Field.Item m={6} title={translateText(1567)}>
                    <DateField value={stringToDate(model.receitInfo.date)} onChange={handleDateFieldChange.bind(this, ["receitInfo", "date"])} />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={4} title={translateText(91)}>
                    <TextField
                        showClear
                        value={model.articleDetails.dealerPartNumber}
                        onChange={actions.updateField.bind(this, ["articleDetails", "dealerPartNumber"])}
                    />
                </Field.Item>
                <Field.Item m={4} title={translateText(92)}>
                    <TextField
                        showClear
                        value={model.articleDetails.manufacturerPartsCode}
                        onChange={actions.updateField.bind(this, ["articleDetails", "manufacturerPartsCode"])}
                    />
                </Field.Item>
                <Field.Item m={4} title={translateText(25)}>
                    <TextField
                        showClear
                        value={model.articleDetails.description}
                        onChange={actions.updateField.bind(this, ["articleDetails", "description"])}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={4} title={translateText(1364)} mandatory={modelState["vehicleInfo.vehicleType"]}>
                    <TextField
                        showClear
                        value={model.vehicleInfo.vehicleType}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "vehicleType"])}
                        className={hasFieldError(modelState["vehicleInfo.vehicleType"])}
                    />
                </Field.Item>
                <Field.Item m={4} title={translateText(329)} mandatory={modelState["vehicleInfo.kw"]}>
                    <TextField
                        showClear
                        value={model.vehicleInfo.kw}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "kw"])}
                        pattern={/[\d.]*/}
                        className={hasFieldError(modelState["vehicleInfo.kw"])}
                    />
                </Field.Item>
                <Field.Item m={4} title={translateText(330)} mandatory={modelState["vehicleInfo.hp"]}>
                    <TextField
                        showClear
                        value={model.vehicleInfo.hp}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "hp"])}
                        pattern={/[\d.]*/}
                        className={hasFieldError(modelState["vehicleInfo.hp"])}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={3} title={translateText(1365)} mandatory={modelState["vehicleInfo.motorCode"]}>
                    <SuggestionTextField
                        value={model.vehicleInfo.motorCode}
                        getSuggestionData={() => model.vehicleInfo.motorCodes ?? []}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "motorCode"])}
                        onChangeConfirm={actions.updateField.bind(this, ["vehicleInfo", "motorCode"])}
                        formatter={(value: string) => value.toUpperCase()}
                        className={hasFieldError(modelState["vehicleInfo.motorCode"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(818)} mandatory={modelState["vehicleInfo.vinNr"]}>
                    <VinField
                        value={model.vehicleInfo.vinNr}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "vinNr"])}
                        className={hasFieldError(modelState["vehicleInfo.vinNr"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(880)} mandatory={modelState["vehicleInfo.keyNumber"]}>
                    <SuggestionTextField
                        value={model.vehicleInfo.keyNumber}
                        getSuggestionData={() => model.vehicleInfo.keyNumbers ?? []}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "keyNumber"])}
                        onChangeConfirm={actions.updateField.bind(this, ["vehicleInfo", "keyNumber"])}
                        formatter={(value: string) => value.toUpperCase()}
                        className={hasFieldError(modelState["vehicleInfo.keyNumber"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(331)} mandatory={modelState["vehicleInfo.ccm"]}>
                    <TextField
                        showClear
                        value={model.vehicleInfo.ccm}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "ccm"])}
                        pattern={/[\d.]*/}
                        className={hasFieldError(modelState["vehicleInfo.ccm"])}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={2} title={translateText(1367)} mandatory={modelState["vehicleInfo.firstRegistrationYear"]}>
                    <DateField
                        value={stringToDate(model.vehicleInfo.firstRegistrationYear)}
                        className={hasFieldError(modelState["vehicleInfo.firstRegistrationYear"])}
                        onChange={handleDateFieldChange.bind(this, ["vehicleInfo", "firstRegistrationYear"])}
                    />
                </Field.Item>
                <Field.Item m={2} title={translateText(1368)} mandatory={modelState["vehicleInfo.partsInstallationDate"]}>
                    <DateField
                        value={stringToDate(model.vehicleInfo.partsInstallationDate)}
                        className={hasFieldError(modelState["vehicleInfo.partsInstallationDate"])}
                        onChange={handleDateFieldChange.bind(this, ["vehicleInfo", "partsInstallationDate"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1370)} mandatory={modelState["vehicleInfo.installationMileage"]}>
                    <TextField
                        showClear
                        value={model.vehicleInfo.installationMileage}
                        className={hasFieldError(modelState["vehicleInfo.installationMileage"])}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "installationMileage"])}
                        pattern={/[\d.]*/}
                    />
                </Field.Item>
                <Field.Item m={2} title={translateText(1369)} mandatory={modelState["vehicleInfo.partsDeinstallationDate"]}>
                    <DateField
                        value={stringToDate(model.vehicleInfo.partsDeinstallationDate)}
                        className={hasFieldError(modelState["vehicleInfo.partsDeinstallationDate"])}
                        onChange={handleDateFieldChange.bind(this, ["vehicleInfo", "partsDeinstallationDate"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1370)} mandatory={modelState["vehicleInfo.deinstallationMileage"]}>
                    <TextField
                        showClear
                        value={model.vehicleInfo.deinstallationMileage}
                        className={hasFieldError(modelState["vehicleInfo.deinstallationMileage"])}
                        onChange={actions.updateField.bind(this, ["vehicleInfo", "deinstallationMileage"])}
                        pattern={/[\d.]*/}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item title={translateText(299)} m={6} mandatory={modelState["vehicleInfo.fuelTypeId"]}>
                    {fuelTypes.map((item, idx) => (
                        <Radio
                            key={idx}
                            size="s"
                            value={item}
                            label={item.value}
                            checked={model.vehicleInfo.fuelTypeId == item.id}
                            onCheck={handleVehicleFuelTypeChange}
                        />
                    ))}
                </Field.Item>

                <Field.Item title={translateText(1366)} m={6} mandatory={modelState["vehicleInfo.transmissionType"]}>
                    <Radio
                        size="s"
                        label={translateText(1437)}
                        checked={model.vehicleInfo.transmissionType?.toLowerCase() == "schaltung"}
                        onCheck={handleCheckboxChange.bind(this, ["vehicleInfo", "transmissionType"], "schaltung")}
                    />
                    <Radio
                        size="s"
                        label={translateText(1438)}
                        checked={model.vehicleInfo.transmissionType?.toLowerCase() == "automatik"}
                        onCheck={handleCheckboxChange.bind(this, ["vehicleInfo", "transmissionType"], "automatik")}
                    />
                    <Radio
                        size="s"
                        label={translateText(1439)}
                        checked={model.vehicleInfo.transmissionType?.toLowerCase() == "eantrieb"}
                        onCheck={handleCheckboxChange.bind(this, ["vehicleInfo", "transmissionType"], "eantrieb")}
                    />
                    <Radio
                        size="s"
                        label={translateText(1440)}
                        checked={model.vehicleInfo.transmissionType?.toLowerCase() == "dsgetriebe"}
                        onCheck={handleCheckboxChange.bind(this, ["vehicleInfo", "transmissionType"], "dsgetriebe")}
                    />
                </Field.Item>
            </Field.Container>
        </>
    )
}

export default DocumentVehicleTab
