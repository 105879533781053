import { BundleMicro } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers/storeConenctor"
import { reduce } from "./business"
import component from "./component"

const errorSearch: BundleMicro = {
    name: "error-search",
    reduce,
    component: withStoreProvider(component),
}
export default errorSearch
