import { useLocalization } from "@tm/localization"
import { Table, Button, Text } from "@tm/controls"
import { CategoryType } from "../business"
import { SearchTreeNode } from "../../../data"

const { Cell, Column } = Table

type Props = {
    breadcrumbs: Array<SearchTreeNode>
    onReset(categoryType: CategoryType): void
}

const getRowClassName = () => "is-selected"
const renderNumber = (node: SearchTreeNode) => (
    <Cell>
        <Text>{node.repairTimesNo}</Text>
    </Cell>
)
const renderName = (node: SearchTreeNode) => (
    <Cell>
        <Text>{node.name}</Text>
    </Cell>
)

export default function BreadcrumbsTable(props: Props) {
    const { translate } = useLocalization()

    const renderActions = (_node: SearchTreeNode, idx: number) => (
        <Cell>
            <Button icon="close" layout={["ghost"]} onClick={() => props.onReset(idx === 1 ? CategoryType.sub : CategoryType.main)} />
        </Cell>
    )

    if (!props.breadcrumbs.length) {
        return null
    }

    const columns = [
        <Column className="fancy-list__block--number" renderItemContent={renderNumber}>
            {translate(130)}
        </Column>,
        <Column className="fancy-list__block--description" renderItemContent={renderName}>
            {translate(131)}
        </Column>,
        <Column className="fancy-list__block--actions" renderItemContent={renderActions} />,
    ]

    return (
        <div className="breadcrumbs-table">
            <Table data={props.breadcrumbs} columns={columns} getRowClassName={getRowClassName} />
        </div>
    )
}
