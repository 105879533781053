import { em, rem } from "csx"
import * as React from "react"
import { useSelector } from "react-redux"
import { useStyle } from "@tm/context-distribution"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes, initSelector } from "@tm/utils"
import { statusDataSelector } from "../../../main/business"
import { Cell, HeadlineBox } from "../../../shared"
import mapStyle from "./map-style"

const selector = initSelector(statusDataSelector)

const GpsComponent: React.FC<any> = () => {
    const container = React.useRef<HTMLDivElement>(null)
    let map: google.maps.Map | null = null
    const { translateText, translate } = useLocalization()
    const [statusData] = useSelector(selector)

    if (!statusData) return null

    const { gpsPositions: { latitude, longitude, altitude, headingOrientation } = {} } = statusData

    React.useLayoutEffect(() => {
        if (statusData?.gpsPositions) updateMap()
    }, [statusData])

    const updateMap = () => {
        if (!container.current) return

        const { HYBRID, ROADMAP, SATELLITE, TERRAIN } = google.maps.MapTypeId
        const MAP_TYPE_ID = "MAP_STYLE" as any

        let carPosition

        if (latitude && longitude)
            carPosition = new google.maps.LatLng(latitude, longitude)

        map = new google.maps.Map(container.current, {
            center: carPosition,
            zoom: 12,
            mapTypeId: MAP_TYPE_ID,
            mapTypeControlOptions: {
                mapTypeIds: [MAP_TYPE_ID, ROADMAP, TERRAIN, SATELLITE, HYBRID]
            }
        })

        map.mapTypes.set(MAP_TYPE_ID, new google.maps.StyledMapType(mapStyle as google.maps.MapTypeStyle[], {
            name: "TOPMOTIVE",
            alt: "Topmotive-Stil anzeigen"
        }))

        if (latitude && longitude) {
            const carMarker = new google.maps.Marker({
                position: carPosition,
                map: map,
                icon: {
                    fillColor: "#2196F3",
                    fillOpacity: 1,
                    path: "m 25.998,21.996 h 4 v -2 h -4 z m -8,0 h 4 v -2 h -4 z m 2,-8 h 8 l 2,4 h -12 z m 9.998,-2 H 17.998 l -4,8 v 4 h 2 c 0,1.106 0.894,2 2,2 1.106,0 2,-0.894 2,-2 h 8 c 0,1.106 0.894,2 2,2 1.106,0 2,-0.894 2,-2 h 2 v -4 z m -5.998,27.307 c 4.26,-3.994 12,-12.63 12,-19.306 0,-6.62 -5.382,-12 -12,-12 -6.618,0 -12,5.38 -12,12 0,6.676 7.74,15.312 12,19.306 z m 0,-35.306 c 8.822,0 16,7.178 16,16 0,8.824 -9.292,18.882 -16,24.634 -6.712,-5.756 -16,-15.814 -16,-24.634 0,-8.822 7.178,-16 16,-16 z",
                    strokeWeight: 0,
                    anchor: new google.maps.Point(21, 41)
                },
                title: "Fahrzeugposition",
            })
        }
    }

    let mapClassName = "box__content map"
    mapClassName += (!longitude || !latitude) ? " map--disabled" : ""

    return (
        <div className={style.gps}>
            <div className={style.box}>
                <div className={classes(style.map, mapClassName)} ref={container}></div>
                <HeadlineBox textId={12483} />
                <div className="box__content">
                    <div className={style.list}>
                        {
                            (!longitude || !latitude) &&
                            <Cell>
                                <Text modifiers="highlight">
                                    {translateText(583)}
                                </Text>
                            </Cell>
                        }
                        {
                            latitude &&
                            <Cell>
                                <Text modifiers="highlight">
                                    {latitude.format(5)}
                                </Text>
                                <Text size="s">{translateText(12490)}</Text>
                            </Cell>
                        }
                        {
                            longitude &&
                            <Cell>
                                <Text modifiers="highlight">
                                    {longitude.format(5)}
                                </Text>
                                <Text size="s">{translateText(12491)}</Text>
                            </Cell>
                        }
                        {
                            altitude &&
                            <Cell>
                                <Text modifiers="highlight">
                                    {altitude.format(0)}
                                </Text>
                                <Text size="s">{translateText(12492)}</Text>
                            </Cell>
                        }
                        {
                            headingOrientation &&
                            <Cell>
                                <Text modifiers="highlight">
                                    <div className="heading-orientation" style={{ transform: `rotateZ(${headingOrientation}deg)` }}>
                                        <Icon className="icon--primary icon" name="up" size="xs" />
                                    </div>
                                </Text>
                                <Text size="s">{translateText(12493)}</Text>
                            </Cell>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GpsComponent

const style = useStyle({
    gps: {
        display: "flex",
        flex: 1
    },
    box: {
        flex: 1,
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 5px rgba(0,0,0,.2)",
        padding: rem(1)
    },
    map: {
        height: em(27.6),
        margin: "-1rem -1rem 1rem",
        marginBottom: rem(1)
    },
    list: {
        display: "flex"
    }
})(GpsComponent)
