import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { PrepareCalculationDatAjaxResponse, PrepareCalculationDatRequest } from "./model"

export * from "./model"

export const getDATCalculationUrl = async (calculationUrlRequest: PrepareCalculationDatRequest) => {
    const url = `${getServiceUrl()}/PrepareCalculation`
    const authorization = getStoredAuthorization()
    const body = calculationUrlRequest

    return ajax<PrepareCalculationDatAjaxResponse>({ url, body, method: "POST", authorization }).then((response) => {
        return response?.baseInfo
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.datCalculationServiceUrl
}
