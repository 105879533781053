import { memo } from "react"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box, DefaultIcon as MuiIcon } from "@tm/components"

import { SearchType } from "../../../../business"

type Props = {
    searchType: SearchType
}

export const ArticleListHint = memo<Props>(({ searchType }) => {
    const { translateText } = useLocalization()

    switch (searchType) {
        case SearchType.TRADERARTICLENOS:
        case SearchType.ALTERNATIVES:
        case SearchType.ARTICLE_IDENTIFIER: {
            return (
                <Box alignItems="center" display="flex" whiteSpace="initial" pl={0.5} pb={1}>
                    <Box>
                        <MuiIcon>
                            <Icon name="info" size="xs" />
                        </MuiIcon>
                    </Box>
                    <Text size="xs">{translateText(12893)}</Text>
                </Box>
            )
        }

        default: {
            return null
        }
    }
})
