import { RegisteredModels, RepairTimesBundleParams } from "@tm/models"
import { Container } from "@tm/nexus"
import { hasRepairTimes, hasRepairTimesBuffered, loadMainServices, showManufacturerDivision, showRepairTimesByIds } from "./data"
import { getProductGroupTopicIds } from "./data/repositories/technical-data"
import { addCalculatedCost } from "./data/repositories/repair-shops"

export const version = {
    name: "repair-times",
    version: "1.0.0",
}

let bundleParams: RepairTimesBundleParams

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function initRepairTimesBundle(params: RepairTimesBundleParams) {
    bundleParams = params
    Container.register({
        name: RegisteredModels.RepairTimes_MainServices,
        modelActions: {
            load: loadMainServices,
        },
    })
    Container.register({
        name: RegisteredModels.RepairTimes_HasRepairTimes,
        modelActions: {
            load: hasRepairTimes,
        },
    })
    Container.register({
        name: "RepairTimes_HasRepairTimes_Buffered",
        modelActions: {
            load: hasRepairTimesBuffered,
        },
    })
    Container.register({
        name: RegisteredModels.RepairTimes_ShowByIds,
        modelActions: {
            load: showRepairTimesByIds,
        },
    })
    Container.register({
        name: RegisteredModels.RepairTimes_ShowManufacturerDivision,
        modelActions: {
            load: showManufacturerDivision,
        },
    })
    Container.register({
        name: RegisteredModels.RepairTimes_TechnicalData_GetProductGroupTopicIds,
        modelActions: {
            load: getProductGroupTopicIds,
        },
    })
    Container.register({
        name: RegisteredModels.RepairShop_AddCalculatedCosts,
        modelActions: {
            load: addCalculatedCost,
        },
    })
    return bundleParams
}
