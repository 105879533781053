import { useLocalization } from "@tm/localization"
import { Headline } from "@tm/controls"

export default function HeaderComponent() {
    const { translateText } = useLocalization()
    return (
        <div className="repair-times__header">
            <Headline size="s">{translateText(83)}</Headline>
        </div>
    )
}
