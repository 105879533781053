import { getStyleTheme } from "@tm/context-distribution"
import { stylesheet } from "typestyle"
import { important } from "csx"

export function getComponentStyles() {
    const { colors } = getStyleTheme()

    return stylesheet({
        buttonWrapper: {
            display: "flex",
        },
        shutterButton: {
            $nest: {
                ".btn__icon": {
                    height: "2rem",
                    width: "2rem",
                },
                ".btn__content": {
                    height: "2rem",
                    width: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
            },
        },
        timerButton: {
            $nest: {
                ".btn__icon": {
                    height: "1.75rem",
                    width: "1.75rem",
                    fill: important(colors.light),
                    // opacity: 1,
                },
            },
        },
        timerButtonActive: {
            $nest: {
                ".btn__icon": {
                    fill: important(colors.primary),
                },
            },
        },
    })
}
