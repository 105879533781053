import { Stack, styled } from "@tm/components"

export const CustomItemHeader = styled(Stack)(({ theme }) => ({
    marginBottom: theme.spacing(1),
    marginRight: "14px", // set so the basket button of the selection component matches the horizontal position of the ones from each list item
    minHeight: "44px", // set so the height of the selection component is used as minimum height to prevent height flickering
}))
CustomItemHeader.defaultProps = {
    direction: "row",
    alignItems: "center",
    justifyContent: "space-between",
    spacing: 1,
}
