import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"

export default function handleOpenMemoInfo(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (!data.openMemoInfo) {
        return
    }

    const { memoToolEnabled, memoInfoModalUrl } = getBundleParams()

    if (!memoToolEnabled || !memoInfoModalUrl) {
        return
    }

    const url = renderRoute(memoInfoModalUrl, { workTaskId: encodeUniqueId(this.props.workTaskId!) })

    Morpheus.showView("1", url)
}
