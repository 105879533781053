import { useState, useRef, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Loader, Icon, Button } from "@tm/components"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { openStreamPdf } from "@tm/utils"
import { loadOrderConfirmationPdf } from "../../../utils"

type Props = {
    orderId: string
    iconName?: string
    textId?: number
    padding: string
}

export default function PrintOrderButtonComponent({ orderId, iconName, textId, padding }: Props) {
    const { translateText } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const [generatingPdf, setGeneratingPdf] = useState(false)
    const [pdfHasErrors, setPdfHasErrors] = useState(false)
    const openPdfUnsubscribe = useRef<() => void>()

    useEffect(() => {
        return () => openPdfUnsubscribe.current?.()
    }, [])

    function handleOpenPdf(orderNumber: string) {
        setGeneratingPdf(true)
        setPdfHasErrors(false)
        const [promise, unsub] = loadOrderConfirmationPdf({ orderNumber, customerNo: telesalesCustomerNo })
        openPdfUnsubscribe.current = unsub
        promise
            .then((orderPdf) => {
                if (orderPdf) {
                    openStreamPdf(orderPdf, translateText)
                }
            })
            .catch(() => setPdfHasErrors(true))
            .finally(() => setGeneratingPdf(false))
    }

    return (
        <>
            {generatingPdf ? (
                <Loader size="small" />
            ) : (
                <Button
                    onClick={() => handleOpenPdf(orderId)}
                    title={translateText(pdfHasErrors ? 12847 : 49)}
                    sx={{ padding }}
                    startIcon={<Icon name={pdfHasErrors ? iconName : "file-pdf"} />}
                >
                    {textId && translateText(textId)}
                </Button>
            )}
        </>
    )
}
