import { WorkTaskReadModel } from "@tm/models"
import { WorkTaskListItem } from "./model"
import { removeBrackets } from "../../../helpers"

export function mapWorkTaskReadmodelToListItem(workTask: WorkTaskReadModel): WorkTaskListItem {
    const { workTaskId, customer, vehicle, modifiedDate, transmittedOrderStatus, statusValue, voucherNo, voucherType, version } = workTask
    let { workTaskNo, createdDate } = workTask
    workTaskNo = workTaskNo || ""
    createdDate = createdDate || new Date()

    let customerDisplayName: string | undefined
    if (customer) {
        const tokens = []
        if (customer.firstName && customer.firstName.length) {
            tokens.push(customer.firstName.split(" ")[0])
        }
        if (customer.lastName && customer.lastName.length) {
            tokens.push(customer.lastName)
        }
        if (tokens.length) {
            customerDisplayName = tokens.join(" ")
        } else {
            customerDisplayName = customer.companyName
        }
    }

    let vehicleDisplayName: string | undefined
    let vehiclePlateId: string | undefined
    if (vehicle) {
        const tokens = [vehicle.manufacturer, removeBrackets(vehicle.modelSeries), removeBrackets(vehicle.model)]
        vehicleDisplayName = tokens.join(" ")
        vehiclePlateId = vehicle.plateId
    }

    const result: WorkTaskListItem = {
        workTaskId,
        workTaskNo,
        customerDisplayName,
        vehicleDisplayName,
        vehiclePlateId,
        createdDate,
        modifiedDate,
        transmittedOrderStatus,
        status: statusValue,
        voucherNo,
        voucherType: voucherType && voucherType.description,
        version,
        customer,
        vehicle,
    }

    return result
}
