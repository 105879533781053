export default function Home() {
    return (
        <svg className="icon icon--l startpage-button__icon" xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28">
            <path
                fillRule="evenodd"
                d="M23.59 13.284c-.791 0-1.432.642-1.432 1.432v10.02h-2.863v-4.294c0-.79-.642-1.431-1.432-1.431h-5.726c-.79 0-1.432.64-1.432 1.431v4.295H7.842V14.716c0-.79-.641-1.432-1.431-1.432h-.84L15 3.856l9.428 9.428h-.839zm5.306.42L16.012.82a1.43 1.43 0 0 0-2.024 0L1.104 13.704a1.43 1.43 0 1 0 1.012 2.444H4.98v10.02A1.43 1.43 0 0 0 6.411 27.6h5.726a1.43 1.43 0 0 0 1.431-1.432v-4.294h2.864v4.294a1.43 1.43 0 0 0 1.431 1.432h5.726a1.43 1.43 0 0 0 1.432-1.432v-10.02h2.863a1.43 1.43 0 0 0 1.012-2.444z"
            />
        </svg>
    )
}
