import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { OrderDetailsBox, OrderDetailsLabel } from "./StyledComponents"

type Props = {
    warehouseName: string
    warehouseStreet: string
    warehousePostalCode: string
    warehouseCity: string
    height?: string
}

export function OrderDetailsWarehouseField({ warehouseStreet, warehouseCity, warehouseName, warehousePostalCode, height }: Props) {
    const { translateText } = useLocalization()
    return (
        <OrderDetailsBox display="flex" height={height}>
            <Stack direction="column" mr={2}>
                <OrderDetailsLabel>{translateText(1089)}</OrderDetailsLabel>
                <Typography variant="body2">{warehouseName}</Typography>
            </Stack>
            <Stack direction="column">
                <Typography variant="body2">{warehouseStreet}</Typography>
                <Typography variant="body2">{`${warehousePostalCode || ""} ${warehouseCity || ""}`}</Typography>
            </Stack>
        </OrderDetailsBox>
    )
}
