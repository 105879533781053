import { useMemo } from "react"
import { useWorkTask } from "@tm/context-distribution"

export const useVehicleCheck = (previousDataLoaded?: boolean, vinToCompare?: string) => {
    const worktaskVehicleVin = useWorkTask()?.workTask?.vehicle?.vin

    return useMemo(() => {
        if ((worktaskVehicleVin != vinToCompare) || !previousDataLoaded)
            return true
        else
            return false
    }, [worktaskVehicleVin, previousDataLoaded]);
}