import { SubmitMultipleSelectionsRequest } from "./model"

export function createSubmitMultipleSelectionsRequest(
    calcId: string,
    eTag: string,
    selections: { [key: string]: string[] }
): SubmitMultipleSelectionsRequest {
    return {
        calcId,
        eTag,
        selections,
    }
}
