import { Vehicle, Customer, ShowCarModelDetailsResponse } from "@tm/models"
import { NavigationSteps } from "./data/models"
import { GetCalculationDataResponse } from "./data/repositories/getCalculationData/model"
import { BaseInfo } from "./data/repositories/prepareCalculation/model"

export type BundleActionTypes =
    | { type: "RESET" }
    | { type: "CHANGE_STEP"; payload: { selectedStep: NavigationSteps; params?: Record<string, any> } }
    | { type: "VEHICLE_SET"; payload: Vehicle }
    | { type: "DETAILS_LOADED"; payload: ShowCarModelDetailsResponse }
    | { type: "CUSTOMER_SET"; payload: Customer }
    | { type: "PREPARE_CALC_LOADED"; payload: BaseInfo }
    | { type: "CALCULATION_DATA_LOADING" }
    | { type: "CALCULATION_DATA_LOADED"; payload: GetCalculationDataResponse }
    | { type: "CALCULATION_DATA_ERROR" }

export type IBundleActions = Record<string, never>

export const BundleActions: IBundleActions = {}
