import { useCallback, useEffect, useState } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { EFilterNames, TyreFilter, channel } from "@tm/models"
import { createBaseQuery, getAvailableTireSize, getCurrentSeason } from "../../data/helpers"
import { Statics } from "../../data/statics"
import { resetSummaryFilter, updateSelectedFilters } from "./helper"
import { IWidgetFilters, SelectedFilters } from "./model"
import { useGetTyresCrits } from "./useGetTyresCrits"

const useWidgetFilters = () => {
    const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
        carType: Statics.getCarTypes(),
        season: Statics.seasons.find(x => x.value == getCurrentSeason()),
        untouched: true
    })

    const [selectedFiltersDefaultVehicle, setSelectedFiltersDefaultVehicle] = useState<TyreFilter | undefined>(undefined)

    const vehicleType = useWorkTask()?.workTask?.vehicle?.vehicleType

    const { filters, loading, error } = useGetTyresCrits(selectedFilters)

    useEffect(() => {
        const defaultVehicle = selectedFilters.carType?.find(x => x.bitValue === vehicleType)
        setSelectedFiltersDefaultVehicle(defaultVehicle)
        setTireSizes(defaultVehicle)
    }, [vehicleType])

    useEffect(() => {
        if (selectedFilters.untouched || !filters) {
            return
        }

        if (filters.height?.length === 1 && selectedFilters.width && !selectedFilters.height) {
            handleFilterChange(EFilterNames.height, filters.height.first()!)
        } else if (filters.inch?.length === 1 && selectedFilters.height && !selectedFilters.inch) {
            handleFilterChange(EFilterNames.inch, filters.inch.first()!)
        }
    }, [filters?.height, filters?.inch])

    const setTireSizes = useCallback((defaultVehicle?: TyreFilter | undefined) => {
        const { width, inch, height } = defaultVehicle ?
            getAvailableTireSize([defaultVehicle], filters) || {} :
            selectedFilters.carType?.length > 0 && getAvailableTireSize(selectedFilters.carType, filters) || {}

        if (!width && !inch && !height) {
            return
        }

        setSelectedFilters(x => ({ ...x, width, inch, height, untouched: false }))
    }, [selectedFilters?.carType])

    const handleNormalSearch = useCallback(() => {
        const { carType, season, width, height, inch } = selectedFilters
        const size = createBaseQuery(width?.value, height?.value, inch?.value)

        if (!size) {
            return
        }

        const payload = {
            size,
            season: season?.value,
            carType: carType?.map(x => x.query).join()
        }

        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", payload)
    }, [selectedFilters])

    const handleFilterChange = useCallback((path: IWidgetFilters, filter: TyreFilter) => {
        if (path === EFilterNames.carType) {
            setSelectedFiltersDefaultVehicle(undefined)
        }       
         
        setSelectedFilters((selectedFilters) => {
            let newSelectedFilters = resetSummaryFilter(selectedFilters, path)
            if (filter) {
                newSelectedFilters = updateSelectedFilters(newSelectedFilters, filters, path, filter)
            }
            return newSelectedFilters
        })
    }, [filters])

    const handleResetAll = () => setTireSizes()

    return {
        state: {
            loading,
            filters,
            selectedFilters,
            selectedFiltersDefaultVehicle
        },
        handlers: {
            handleResetAll,
            handleNormalSearch,
            handleFilterChange,
        }
    }
}

export default useWidgetFilters
