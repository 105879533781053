import { WidgetModuleGroups } from "./models"

export const defaultWidgetModuleGroups: WidgetModuleGroups = {
    repairtimes: {
        sort: 1,
        moduleKey: "repairtimes",
        component: "widget-navigation-dropdown",
        id: "repairtimes__dropdown-widget",
        icon: "repairtimes",
        title: "{{83}}",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
    inspection: {
        sort: 2,
        moduleKey: "inspection",
        component: "widget-navigation-dropdown",
        id: "inspection__dropdown-widget",
        icon: "inspection",
        title: "{{237}}",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
    tech: {
        sort: 3,
        moduleKey: "tech",
        component: "widget-navigation-dropdown",
        id: "tech__dropdown-widget",
        icon: "tech-data",
        title: "{{570}}",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
    gti: {
        sort: 4,
        moduleKey: "gti",
        component: "widget-navigation-dropdown",
        id: "gti__dropdown-widget",
        icon: "group",
        title: "{{571}}",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
    tyres: {
        sort: 6,
        moduleKey: "tyres",
        component: "widget-navigation-dropdown",
        id: "tyres__dropdown-widget",
        icon: "tire",
        title: "{{240}}",
        items: {},
    },
    "damage-calculation": {
        sort: 7,
        moduleKey: "damage-calculation",
        component: "widget-navigation-dropdown",
        id: "damage-calculation__dropdown-widget",
        icon: "damage-calculation",
        title: "{{1482}}",
        items: {},
    },
    carmunication: {
        sort: 8,
        moduleKey: "carmunication",
        component: "widget-navigation-dropdown",
        id: "carmunication__dropdown-widget",
        icon: "carmunication",
        title: "carmunication",
        items: {},
    },
    drivemotive: {
        sort: 23,
        moduleKey: "drivemotive",
        component: "widget-navigation-dropdown",
        id: "drivemotive__widget",
        icon: "drivemotive",
        title: "Drivemotive",
        disabledFunction: "DRIVEMOTIVE-DISABLED",
        items: {},
    },
    "dutch-order-plates": {
        sort: 8,
        moduleKey: "dutch-order-plates",
        component: "widget-navigation-dropdown",
        id: "dop__dropdown-widget",
        icon: "damage-calculation",
        title: "DOP",
        items: {},
    },
    eds: {
        sort: 23,
        moduleKey: "eds",
        component: "widget-navigation-dropdown",
        id: "eds__widget",
        icon: "eds",
        title: "EDS",
        disabledFunction: "EDS-DISABLED",
        disabledMsgId: "13418",
        disabledIcon: "vehicle-vin",
        items: {},
    },
    "fast-calculator-next": {
        sort: 9,
        moduleKey: "fast-calculator-next",
        component: "widget-navigation-dropdown",
        id: "fast-calculator__widget",
        icon: "service-calculator",
        title: "{{598}}",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
    "fast-service": {
        sort: 22,
        moduleKey: "fast-service",
        component: "widget-navigation-dropdown",
        id: "fast-service__widget",
        icon: "fast-service",
        title: "{{12686}}",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
    wheels: {
        sort: 7,
        moduleKey: "wheels",
        component: "widget-navigation-dropdown",
        id: "wheels__widget",
        icon: "wheel-and-car",
        title: "{{608}}",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
    sindri: {
        sort: 23,
        moduleKey: "sindri",
        component: "widget-navigation-dropdown",
        id: "sindri__widget",
        icon: "telematics",
        title: "SINDRI",
        disabledFunction: "HAS-NO-VEHICLE-VIN",
        disabledMsgId: "13418",
        disabledIcon: "vehicle-vin",
        items: {},
    },
    vefiles: {
        sort: 24,
        moduleKey: "vefiles",
        component: "widget-navigation-dropdown",
        id: "vefiles__widget",
        icon: "vefiles",
        title: "VeFiles",
        disabledFunction: "HAS-NO-VEHICLE",
        items: {},
    },
}
