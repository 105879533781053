import { KeyboardEvent, useState } from "react"
import { useLocalization } from "@tm/localization"
import { CisQueryType, CisVoucherType } from "@tm/models"
import { ButtonKeyDefinition } from "@tm/utils"
import { Icon, Typography, Stack, Select, MenuItemWidget, DatePicker, TextField, SearchButton } from "@tm/components"
import { today } from "../../../business/helper"
import { CisFilters } from "../../../business/model"
import { DropdownInputViewProps } from "../component"
import { useFilterStore } from "../../../business/state"

type Props = {
    filters: CisFilters
    queryTypes?: Array<CisQueryType>
    voucherTypeId: CisVoucherType
    setFilters(filters: CisFilters): void
}

export default function StgOrdersFilterComponent({ queryTypes, voucherTypeId, filters, setFilters }: Props) {
    const { language, translateText } = useLocalization()
    const loading = useFilterStore((state) => state.loading)
    const [inputQuery, setInputQuery] = useState(filters.query)

    function handleQueryTypeStateChange(queryType: DropdownInputViewProps) {
        setFilters({
            ...filters,
            queryTypeId: queryType.id,
            queryTypeDescription: queryType.description,
            showSingleDateFilter: queryType.showSingleDateFilter,
        })
    }

    function handleChangeDateFrom(dateFrom: Date | null) {
        setFilters({
            ...filters,
            dateFrom: dateFrom || undefined,
            deliveryNoteDate: dateFrom || undefined,
            invoiceDate: dateFrom || undefined,
        })
    }

    function handleChangeDateTo(dateTo: Date | null) {
        setFilters({ ...filters, dateTo: dateTo || undefined })
    }

    function handleButtonClick() {
        setFilters({ ...filters, query: inputQuery })
    }

    function handleResetClick() {
        setFilters({
            queryTypeId: undefined,
            dateTo: undefined,
            dateFrom: undefined,
            deliveryNoteDate: undefined,
            invoiceDate: undefined,
            query: undefined,
        })
    }

    function searchQuery(query: string) {
        let change = false

        if (query !== filters.query) {
            change = true

            if (filters.query === undefined && query.trim() === "") {
                change = false
            }
        }

        if (change) {
            setFilters({ ...filters, query })
        }
    }

    function handleSearchKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === ButtonKeyDefinition.Enter) {
            const query = (e.target as HTMLInputElement).value
            searchQuery(query)
        }
    }

    let queryTypesItems: Array<DropdownInputViewProps> = []
    if (queryTypes) {
        queryTypesItems = queryTypes.map((queryType) => ({
            id: queryType.id,
            description: queryType.description,
            showSingleDateFilter: queryType.showSingleDateFilter,
        }))
    }

    const selectedQueryType = queryTypesItems.find((queryType) => queryType.id === filters.queryTypeId) || queryTypesItems[0]
    return (
        <Stack direction="row" spacing={1}>
            {voucherTypeId === CisVoucherType.ClosedOrders && queryTypes && !!queryTypes.length && (
                <Stack direction="column">
                    <Typography variant="label">{translateText(939)}</Typography>
                    <Select
                        color="primary"
                        value={selectedQueryType}
                        variant="filled"
                        size="large"
                        onKeyDown={handleSearchKeyDown}
                        onChange={(e) => handleQueryTypeStateChange(e.target.value as DropdownInputViewProps)}
                        sx={{ minWidth: "8.5rem" }}
                    >
                        {queryTypesItems.map((item) => {
                            return (
                                <MenuItemWidget key={item.description} value={item}>
                                    {item.description}
                                </MenuItemWidget>
                            )
                        })}
                    </Select>
                </Stack>
            )}

            <Stack direction="column">
                <Typography variant="label">{translateText(135)}</Typography>
                <TextField
                    value={inputQuery}
                    label={translateText(470)}
                    onKeyDown={handleSearchKeyDown}
                    onBlur={(e) => searchQuery(e.target.value)}
                    onChange={(e) => setInputQuery(e.target.value)}
                    inputProps={{ maxLength: 50 }}
                    size="large"
                />
            </Stack>

            <Stack direction="column">
                <Typography variant="label">{translateText(98)}</Typography>
                <Stack direction="row">
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={handleChangeDateFrom}
                        value={filters.dateFrom || null}
                        maxDate={filters.dateTo || today}
                        size="large"
                        label={translateText(344)}
                    />
                    {!selectedQueryType?.showSingleDateFilter ? (
                        <DatePicker
                            localisation={{ locale: language, region: null }}
                            onChange={handleChangeDateTo}
                            value={filters.dateTo || null}
                            minDate={filters.dateFrom || undefined}
                            maxDate={today}
                            size="large"
                            label={translateText(95)}
                        />
                    ) : (
                        <></>
                    )}
                    <SearchButton disabled={loading} onClick={handleButtonClick} sx={{ marginRight: "4px" }} />
                    <SearchButton startIcon={<Icon name="refresh" />} onClick={handleResetClick} />
                </Stack>
            </Stack>
        </Stack>
    )
}
