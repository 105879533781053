import * as React from "react"
import { useStyle } from "@tm/context-distribution"
import { Button, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { em, px } from "csx"
import { FastCalculator } from "@tm/data"
import { CalculationWidgetContext } from "../../../data/model"
import { CalcInputComponent, CalcStateButton } from "../../_shared"

type Props = RouteComponentProps<any> & {
    label?: string
    price?: FastCalculator.CalcInput
    buttonLabel?: string
    date?: string
    wkz?: string
    fastCalculatorRoute: string
    selectedCalcState?: FastCalculator.CalcState
    historyEntries?: FastCalculator.CalcInput
    changeFCRoute?: () => void
    selectedContext?: CalculationWidgetContext
}

const LastCalculationComponent: React.FC<Props> = ({ selectedContext, fastCalculatorRoute, match, selectedCalcState, changeFCRoute }) => {
    const { currency, translateText } = useLocalization()
    const { label, price, toCalcButtonLabel, date, wkz, historyEntries } = selectedContext || {}

    const fastCalcUrl = renderRoute(fastCalculatorRoute, { ...match.params })

    return (
        <div className={style.lastCalculation}>
            <div className={style.summary}>
                <Text className="title" modifiers={["strong"]}>
                    {label}
                </Text>
                <div className="total">
                    {price && (
                        <Text className="price" size="xl" modifiers={["strong"]}>
                            {currency(price.value, wkz || "")}
                        </Text>
                    )}
                    {date && <Text className="datetime">{new Date(date).toLocaleDateString()}</Text>}
                </div>
            </div>
            <div className={style.actions}>
                {selectedCalcState?.buttonGroups?.map((buttonGroup) =>
                    buttonGroup?.buttons?.map((button) => <CalcStateButton item={button} key={button.id} changeFCRoute={changeFCRoute} />)
                )}
                <Button linkTo={fastCalcUrl}>{toCalcButtonLabel}</Button>
            </div>
            {!!historyEntries?.dropDownItems.length && (
                <div className={style.calculationHistory}>
                    <Text className={style.paddingRight}>{translateText(1599)}</Text>
                    <CalcInputComponent
                        className={style.historyDropDown}
                        changeDropDownPosition
                        item={{ ...historyEntries, style: FastCalculator.ECalcInputStyle.DropDown, isDisabled: false }}
                        changeFCRoute={changeFCRoute}
                    />
                </div>
            )}
        </div>
    )
}

export default withRouter(LastCalculationComponent)

const style = useStyle({
    lastCalculation: {
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    summary: {
        display: "flex",
        justifyContent: "center",
        paddingBottom: em(1),
        borderBottom: px(1),
        borderStyle: "solid",
        borderColor: "#ddd",
        $nest: {
            ".title": {
                display: "flex",
                flex: 1,
                alignSelf: "flex-end",
                justifyContent: "flex-end",
                flexBasis: em(15),
                marginRight: em(0.5),
            },
            ".total": {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                flexBasis: em(15),
                marginLeft: em(0.5),
            },
        },
    },
    actions: {
        display: "flex",
        justifyContent: "center",
        paddingTop: em(1),
    },
    calculationHistory: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: em(0.5),
    },
    paddingRight: {
        paddingRight: em(1),
    },
    historyDropDown: {
        display: "flex",
        flex: 1,
        $nest: {
            ".dropdown": {
                flex: 0.15,
            },
            ".dropdown .btn__content>div": {
                maxWidth: em(10),
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
            },
        },
    },
})(LastCalculationComponent)
