import { AsyncAction } from "@tm/morpheus"
import { MainState } from "../components/main"
import { MainActionsType } from "../components/main/business"
import { useContainer } from "../data/repositories"
import { PrepareCalculationDatRequest } from "../data/repositories/prepareCalculation"

const getUrl = (): AsyncAction<MainActionsType, MainState> => (dispatch) => {
    const request = {
        customerInfo: {},
        vehicleInfo: {},
        calcMode: 2,
    } as PrepareCalculationDatRequest

    useContainer()
        .action("getDATCalculationUrl")(request)
        .then((response) => {
            dispatch({ type: "UPDATE_SETTINGS_PAGE", payload: { sphinxHtml: response.sphinxHtml, errorMessage: response.errorMessage } })
        })
}

export const SettingsActions = {
    getUrl,
}
