import { Button, Icon, Stack, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    onClick: () => void
}

const StyledDatVinTypography = styled(Typography)(({ theme }) => ({
    color: "inherit",
}))

export function EdsVinSearchButton(props: Props) {
    const { translateText, translate } = useLocalization()

    return (
        <Button
            id="search-area__btn--eds-vin"
            size="large"
            sx={{ justifyContent: "start" }}
            onMouseDown={(e) => {
                e.stopPropagation()
                props.onClick()
            }}
        >
            <Stack direction="row" spacing={1}>
                <Icon size="40" name="eds" />
                <Stack spacing="8px" alignItems="start" display="flex">
                    <StyledDatVinTypography variant="h4">{translate(13391)}</StyledDatVinTypography>
                    <StyledDatVinTypography variant="label">{translateText(13392)}</StyledDatVinTypography>
                </Stack>
            </Stack>
        </Button>
    )
}
