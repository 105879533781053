import { em, percent } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Headline, Icon, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps & {
    loading?: boolean
    max: number
    current: number
}

function SplashComponent({ loading, current, max }: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    return (
        <div className={style.wrapper}>
            <Icon name="group" />
            <Headline>Eurotax - {translateText(571)}</Headline>
            {
                !getBundleParams().disableStateSave &&
                <Text modifiers={["strong"]} size="l" className={style.progress}>Progress: {current}/{max}</Text>
            }
            <Loader className={style.loader} />
        </div>
    )
}

export default withRouter(SplashComponent)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            flex: 1,
            height: percent(100),
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            $nest: {
                ".icon": {
                    height: em(5),
                    width: em(5),
                },
                ".headline": {
                    marginBottom: theme.margin.m,
                },
            },
        },
        loader: {
            width: em(2),
            height: em(2),
            alignSelf: "center",
            marginBottom: theme.margin.m,
            // width: em(15),
            opacity: theme.opacity.primary,
        },
        progress: {
            marginBottom: theme.margin.m,
        },
    })(SplashComponent)
}
