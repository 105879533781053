import { useLocalization } from "@tm/localization"
import { Omit } from "@tm/utils"
import { SeparatorList, Tag, TagIcon, TagLayout, TagSkin, Icon, Button, Text } from "@tm/controls"
import { ArticleAttributes, ArticleAttribute, ModificationState } from "@tm/models"

type Props = {
    isCompact?: boolean
    attributes: Array<ArticleAttributes>
    toggleMoreAttributes(): void
}

type MultipleValueAttribute = Omit<ArticleAttribute, keyof AttributeValue> & {
    values: Array<ArticleAttribute>
}

type AttributeValue = {
    key: string
    value: string
    unit: string
    modificationState: ModificationState
    highlight: boolean
}

export default function ArticleAttributesComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const { isCompact, attributes } = props

    function renderAttributeValue(attribute: MultipleValueAttribute, attributeValue: ArticleAttribute, showLabel = true) {
        const icons: Array<TagIcon> = []

        let className = "article__attribute"

        switch (attributeValue.modificationState) {
            case ModificationState.Added:
                className += " article__attribute--added"
                icons.push({ name: "plus", tooltip: translateText(883) })
                break
            case ModificationState.Removed:
                className += " article__attribute--removed"
                icons.push({ name: "minus", tooltip: translateText(884) })
                break
            default:
                break
        }

        let layout: TagLayout | undefined = "ghost"
        let skin: TagSkin | undefined

        if (attributeValue.highlight) {
            layout = "holo"
            skin = "highlight"
        }

        const value = `${attributeValue.value} ${attributeValue.unit || ""}`.trim()

        return (
            <Tag
                key={value}
                className={className}
                layout={layout}
                skin={skin}
                label={showLabel ? attribute.abbreviation : undefined}
                value={value}
                icons={icons}
            />
        )
    }

    function renderAttribute(attribute: MultipleValueAttribute) {
        if (attribute.id === -1) {
            return (
                <Button className="more-btn" onClick={props.toggleMoreAttributes} layout={["ghost"]} size="xs">
                    {translate(isCompact ? 44 : 1208)}
                </Button>
            )
        }

        if (attribute.values.length === 1) {
            return renderAttributeValue(attribute, attribute.values[0])
        }

        return (
            <>
                <Tag key={attribute.abbreviation} className="article__attribute" layout="ghost" label={attribute.abbreviation} />
                {attribute.values.map((x) => renderAttributeValue(attribute, x, false))}
            </>
        )
    }

    function renderAttributes(attributes: Array<ArticleAttribute>, className: string, showMore?: boolean) {
        if (!attributes.length) {
            return
        }

        const items = mapAttributes(attributes)

        if (showMore) {
            items.push({ id: -1 } as any)
        }

        return <SeparatorList className={`article__attributes ${className}`} items={items} renderItem={renderAttribute} />
    }

    function renderAttributeGroup(attributes: ArticleAttributes, idx: number) {
        const minorAttributes = attributes.vehicleAttributes.concat(attributes.articleAttributes)

        return (
            <div className="article__attribute-group" key={idx}>
                <div className="article__attribute-group__description">
                    <Text size="s" title={`${idx + 1}. ${translateText(610)}`}>
                        {idx + 1}. <Icon name="car" />
                    </Text>
                </div>
                <div className="article__attribute-group__attributes">
                    {renderAttributes(attributes.topAttributes, "article__attributes--major", true)}
                    {renderAttributes(minorAttributes, "article__attributes--minor")}
                </div>
            </div>
        )
    }

    if (!attributes.length) {
        return null
    }

    if (attributes.length === 1) {
        const minorAttributes = attributes[0].vehicleAttributes.concat(attributes[0].articleAttributes)

        return (
            <>
                {renderAttributes(attributes[0].topAttributes, "article__attributes--major", true)}
                {renderAttributes(minorAttributes, "article__attributes--minor")}
            </>
        )
    }

    return <>{attributes.map(renderAttributeGroup)}</>
}

function mapAttributes(attributes: Array<ArticleAttribute>): Array<MultipleValueAttribute> {
    const mapped: Array<MultipleValueAttribute> = []

    attributes.forEach((x) => {
        let found = mapped.find(
            (y) =>
                y.id === x.id && // check if the attribute is already existing
                !!x.abbreviation // don't combine attributes without an abbrevation/label
        )

        if (!found) {
            found = {
                abbreviation: x.abbreviation,
                description: x.description,
                id: x.id,
                isBlockSeperator: x.isBlockSeperator,
                isDuplicatedAttribute: x.isDuplicatedAttribute,
                sortNo: x.sortNo,
                text: x.text,
                values: [],
                isOptimized: x.isOptimized,
            }

            mapped.push(found)
        }

        found.values.push(x)
    })

    return mapped
}
