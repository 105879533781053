import { useArticleByArticleNumberWithOptionalVehicle, useArticlesByWholesalerArticleNumber } from "@tm/utils"
import { CompilationShowViewModelItem } from ".."

export function useArticleInfos(item: CompilationShowViewModelItem) {
    const { articles } = useArticlesByWholesalerArticleNumber(item.wholesalerArticleNumber)

    const article = useArticleByArticleNumberWithOptionalVehicle(
        !item.wholesalerArticleNumber && item.productGroupId
            ? {
                  supplierId: item.supplierId,
                  supplierArticleNo: item.supplierArticleNumber,
                  productGroupId: item.productGroupId,
              }
            : undefined
    )

    return articles?.[0] || article
}
