import { Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { SelectedPriceVatMode } from "@tm/models"
import { B2BTotals, B2CTotals } from "@tm/data/vouchers/costEstimations"
import Total from "./Total"

type Props = {
    priceVatMode?: SelectedPriceVatMode
    totals?: B2CTotals | B2BTotals
    currencyCode: string
    showRepairTimesInHours: boolean
}

export function WorksTotals({ totals, showRepairTimesInHours, currencyCode, priceVatMode }: Props) {
    const { translateText, currency, number } = useLocalization()

    if (!totals) {
        return null
    }

    const repairTimesUnits = showRepairTimesInHours ? ` ${translateText(60)}` : ""
    return (
        <Stack alignSelf="flex-end" px={2} spacing={0.5} width="400px">
            <Total label={translateText(259)} value={number(totals?.repairTimeTotal || 0, 2) + repairTimesUnits} valueBold />
            <Total label={translateText(1721)} value={currency(totals?.workCosts || 0, currencyCode)} valueBold />
            {!!totals?.alreadyTaxedWorkCosts && priceVatMode !== SelectedPriceVatMode.Gross && (
                <Total label={translateText(12850)} value={currency(totals.alreadyTaxedWorkCosts, currencyCode)} valueBold />
            )}
        </Stack>
    )
}
