import { CarModelSeries } from "@tm/models"
import { callCarsService } from "."
import { CarBodyType, ModelYearFilter } from "../../model/filters"
import { MainModelSeriesResponse } from "../../hooks"

type SelectedModelSeriesFilters = {
    bodyTypeId?: number
    modelYear?: number
}

type ModelSeriesFilters = {
    bodyTypes: Array<CarBodyType>
    modelYears: Array<ModelYearFilter>
}

type ModelSeriesResponse = {
    modelSeries: Array<CarModelSeries>
    filters: ModelSeriesFilters
}

type ShowModelSeriesByArticleRequest = {
    manufacturerId: number
    articleId: number
    mainModelSeriesId?: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByArticle(request: ShowModelSeriesByArticleRequest) {
    return callCarsService<ShowModelSeriesByArticleRequest, ModelSeriesResponse>("ShowModelSeriesByArticle", request)
}

type ShowModelSeriesByPartListArticleRequest = {
    manufacturerId: number
    partsListArticleId: number
    mainModelSeriesId?: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByPartListArticle(request: ShowModelSeriesByPartListArticleRequest) {
    return callCarsService<ShowModelSeriesByPartListArticleRequest, ModelSeriesResponse>("ShowModelSeriesByPartListArticle", request)
}

type ShowModelSeriesByEngineCodeRequest = {
    manufacturerId: number
    engineCode: string
    mainModelSeriesId?: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByEngineCode(request: ShowModelSeriesByEngineCodeRequest) {
    return callCarsService<ShowModelSeriesByEngineCodeRequest, ModelSeriesResponse>("ShowModelSeriesByEngineCode", request)
}

type ShowMainModelSeriesWithModelSeriesRequest = {
    manufacturerId: number
    mainModelSeriesId?: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showMainModelSeriesWithModelSeries(request: ShowMainModelSeriesWithModelSeriesRequest) {
    return callCarsService<ShowMainModelSeriesWithModelSeriesRequest, MainModelSeriesResponse>("ShowMainModelSeriesWithModelSeries", request)
}

type ShowModelSeriesDetailsRequest = {
    manufacturerId: number
    modelSeriesId: number
}

type ShowModelSeriesDetailsResponse = {
    modelSeriesDetails: CarModelSeries
}

export function showModelSeriesDetails(request: ShowModelSeriesDetailsRequest) {
    return callCarsService<ShowModelSeriesDetailsRequest, ShowModelSeriesDetailsResponse>("ShowModelSeriesDetails", request)
}
