import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { Grid } from "@tm/components"
import { OrderDetails } from "../../../../data/model"
import { OrderDetailsField } from "../../../_shared/OrderDetailsField"
import { OrderDetailsWarehouseField } from "../../../_shared/OrderDetailsWarehouseField"

type Props = {
    orderDetails: OrderDetails
}

export default function PvOrderDetailsHeaderComponent({ orderDetails }: Props) {
    const {
        orderNumber,
        orderSubNumber,
        orderDate,
        deliveryType,
        articlePositionCount,
        customerOrderNumber,
        paymentMode,
        warehouseName1,
        warehouseStreet,
        warehousePostalCode,
        warehouseCity,
        orderTexts,
        orderValueNet,
        currencyCode,
        orderValueTotal,
        articleCount,
        userInformation,
    } = orderDetails
    const { translateText, date, currency } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    let name = ""
    if (userInformation?.firstName) {
        name = userInformation.firstName
    }
    if (userInformation?.lastName) {
        name += ` ${userInformation.lastName}`
    }

    let userName = ""
    if (userInformation?.userName) {
        userName = userInformation.userName
    }

    return (
        <Grid container rowSpacing={1} columnSpacing={1.5} my={1} columns={8}>
            {/* First Row */}
            <Grid item xs={2}>
                <OrderDetailsWarehouseField
                    height="213%"
                    warehouseName={warehouseName1}
                    warehouseStreet={warehouseStreet}
                    warehousePostalCode={warehousePostalCode}
                    warehouseCity={warehouseCity}
                />
            </Grid>
            <Grid item xs={2}>
                <OrderDetailsField value={deliveryType} label={translateText(40)} />
            </Grid>
            <Grid item xs={2}>
                <OrderDetailsField value={customerOrderNumber} label={translateText(1249)} />
            </Grid>
            <Grid item xs={2}>
                {!hidePurchasePrice && <OrderDetailsField value={currency(orderValueNet, currencyCode)} label={translateText(1867)} />}
            </Grid>
            {/* Second Row */}
            <Grid item xs={2} />
            <Grid item xs={2}>
                <OrderDetailsField value={paymentMode} label={translateText(440)} />
            </Grid>
            <Grid item xs={2}>
                <OrderDetailsField value={orderTexts && orderTexts[0]} label={translateText(1248)} />
            </Grid>
            <Grid item xs={2}>
                <OrderDetailsField value={currency(orderValueTotal, currencyCode)} label={translateText(1868)} />
            </Grid>
            {/* Third Row */}
            <Grid item xs={1}>
                <OrderDetailsField value={orderNumber + (orderSubNumber ? ` - ${orderSubNumber}` : "")} label={translateText(700)} />
            </Grid>
            <Grid item xs={1}>
                <OrderDetailsField value={orderDate && `${date(orderDate, "d")} / ${date(orderDate, "t")}`} label={translateText(776)} />
            </Grid>
            <Grid item xs={1}>
                <OrderDetailsField value={articleCount} label={translateText(90)} />
            </Grid>
            <Grid item xs={1}>
                <OrderDetailsField value={articlePositionCount} label={translateText(479)} />
            </Grid>
            <Grid item xs={2}>
                <OrderDetailsField value={`${name} / ${userName}`} label={translateText(1858)} />
            </Grid>
            <Grid item xs={2} />
        </Grid>
    )
}
