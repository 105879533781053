import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, getCurrentWorkTaskId, ModuleTab } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { decodeUniqueId, getSystemTypeFromUserContext } from "@tm/utils"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { useEffect } from "react"
import { managerSelector } from "../../selectors"
import { MainActions } from "../main/business"
import { isVehicleChanged } from "./business/helpers"

type Props = {
    workTaskId: string
}

export default function ModuleManager() {
    const matchParams = useParams<Props>()
    const user = useUser()
    const { translateText } = useLocalization()
    const { vehicle, userIsRegistered, checkCustomerLoading } = useSelector(managerSelector)
    const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(matchParams.workTaskId ?? "") ?? "FAKE_WORKTASK"

    const actions = useActions(MainActions, "setVehicle", "setCustomer", "resetStore", "checkCustomer")

    useEffect(() => {
        const module: ModuleTab = {
            isSelected: true,
            title: "{{13552}}",
            url: `${workTaskId}/damage-calculation/dat`,
            icon: "damage-calculation",
            needsVehicle: true,
        }

        channel("WORKTASK", workTaskId).publish("MODULE/OPENED", module)
    }, [])

    useEffect(() => {
        if (userIsRegistered || checkCustomerLoading) {
            return
        }

        actions.checkCustomer(translateText)
    }, [])

    useEffect(() => {
        const unsubModuleClosed = channel("WORKTASK", workTaskId).subscribe("MODULE/CLOSED", (module) => {
            module.includes("dat") && actions.resetStore()
        })

        const unsubCustomer = channel("WORKTASK", workTaskId).subscribe("CUSTOMER/LOADED", actions.setCustomer, true)
        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    actions.setVehicle(newVeh)
                }
            },
            true
        )

        return () => {
            unsubModuleClosed?.()
            unsubCustomer?.()
            unsubVehicle?.()
        }
    }, [getSystemTypeFromUserContext(user?.userContext), userIsRegistered])

    return null
}
