import { PayloadAction, ActionDispatch } from "@tm/morpheus"
import { Vehicle, Customer } from "@tm/models"
import { BundleActionType } from "../../../business"
import { ManagerState, WorkTaskFunctions } from "./models"

export * from "./actions"

export type ComponentActionType =
    | BundleActionType
    | { type: "VEHICLE_SET"; payload?: Vehicle }
    | { type: "CUSTOMER_SET"; payload?: Customer }
    | {
          type: "ATTACH_TO_WORKTASK"
          payload: { vehicleId?: string; vehicle?: Vehicle; customerId?: string; customer: Customer; requestLocalUrl?: string }
      }
    | { type: "CREATE_WORKTASK"; payload: { vehicleId?: string; customerId?: string; localUrl?: string } }
    | { type: "GOTO_WORKTASK"; payload: { workTaskId: string; localUrl?: string } }
    | { type: "RESET_WORKTASK_TO_GO" }
    | { type: "SET_WORK_TASK_FUNCTIONS"; payload: WorkTaskFunctions }

const DEFAULT_STATE: ManagerState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ManagerState {
    switch (action.type) {
        case "WORKTASK_LOADED": {
            return {
                ...state,
                workTask: action.payload,
            }
        }
        case "SET_WORK_TASK_FUNCTIONS": {
            return {
                ...state,
                ...action.payload,
            }
        }
    }
    return state
}

export function transmit(action: ComponentActionType): PayloadAction | undefined {
    switch (action.type) {
        case "CUSTOMER_SET":
        case "VEHICLE_SET":
            return action
    }
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType, ManagerState>, getState: () => ManagerState) {
    switch (action.type) {
        case "GOTO_WORKTASK": {
            const state = getState()
            const { workTaskId, localUrl } = action.payload
            state.openWorkTask && state.openWorkTask(workTaskId, localUrl)
            break
        }
        case "CREATE_WORKTASK": {
            const state = getState()
            const { vehicleId, customerId, localUrl } = action.payload

            state.createWorkTask && state.createWorkTask({ vehicle: vehicleId, customer: customerId, subPage: localUrl })
            break
        }
        case "ATTACH_TO_WORKTASK": {
            const state = getState()
            const { vehicleId, vehicle, customerId, customer, requestLocalUrl } = action.payload

            state.attachToWorkTask &&
                state.attachToWorkTask({ vehicle: vehicle || vehicleId, customer: customer || customerId, subPage: requestLocalUrl })
            break
        }
    }
}
