import { selector, useRecoilValueLoadable, useRecoilValue } from "recoil"
import { VrcLookupModule } from "../model/vrc-lookup"

import * as Data from "../repositories/vrc-lookup"

const vrcLookupModulesSelector = selector<Array<VrcLookupModule>>({
    key: "vehicle__vrc-lookup__getVrcLookupModules",
    get: () => Data.showLookupModulesResponse().catch(() => []),
})

export function useVrcLookupModules() {
    return useRecoilValue(vrcLookupModulesSelector)
}

export function useVrcLookupModulesLoadable() {
    const loadable = useRecoilValueLoadable(vrcLookupModulesSelector)

    if (loadable.state === "hasValue") {
        return loadable.contents
    }
    return []
}
