import { memo } from "react"
import { Box, Tooltip, Icon, styled, IconProps, HTMLBox } from "@tm/components"
import { getBundleParams } from "../utils"

type Props = {
    information: string
}

const StyledIcon = styled(Icon, {})<IconProps>(({ name }) => name == "info-filled" && { fill: "red" })

export const AdditionalInformation = memo<Props>(({ information }) => {
    return (
        <Tooltip variant="light" color="primary" title={<HTMLBox innerHTML={information} />}>
            <Box>
                <StyledIcon name={getBundleParams().additionalInformationIcon} height={20} width={20} />
            </Box>
        </Tooltip>
    )
})
