import { useGetEmailTemplate } from "../../hooks"
import { useEmailShare } from "./useEmailShare"

export function useVeFilesEmailShare(onShareCompleted?: (success: boolean) => void) {
    const { sendEmail } = useEmailShare(onShareCompleted)
    const { isLoading: emailTemplateLoading, getEmailTemplate } = useGetEmailTemplate()

    const handleSendEmail = async (emails: string[], fileUrl: string, validUntil: string, bcc?: string[]) => {
        const template = await getEmailTemplate(fileUrl, validUntil)

        if (!template?.url) {
            return
        }

        const response = await fetch(template.url)
        if (response.ok) {
            const htmlTemplate = await response.text()
            sendEmail(emails, htmlTemplate, bcc)
        }
    }

    return {
        isLoading: emailTemplateLoading,
        sendEmail: handleSendEmail,
    }
}
