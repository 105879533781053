import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../utils"
import { AlldataAuthenticationResponse } from "./model"

export * from "./model"

export function getAllDataSession(): Promise<string> {
    const url = `${getAuthorityServiceUrl()}/GetAllDataSession`
    const authorization = getStoredAuthorization()
    const body = {}

    return new Promise((resolve, reject) => ajax({ url, body, authorization }).then((data) => resolve(data.session), reject))
}

export function checkAlldataRegistration(url: string, token: string): Promise<boolean> {
    const authorization: any = { type: "", credentials: token }
    const body = {}

    return new Promise((resolve, reject) => ajax({ url, body, authorization }, true).then(resolve, reject))
}

export function authenticateAlldata(url: string, token: string): Promise<AlldataAuthenticationResponse> {
    const authorization: any = { type: "", credentials: token }
    const body = {}

    return new Promise((resolve) =>
        ajax({ url, body, authorization, withCredentials: true }, true).then(resolve, (response) => resolve(response.data))
    )
}

export function getJfUrlFromPV(url: string): Promise<string> {
    return new Promise((resolve, reject) => ajax({ url, method: "GET" }, true).then(resolve, reject))
}

function getAuthorityServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.authorityServiceUrl
}
