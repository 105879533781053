import { ajax, getStoredAuthorization } from "@tm/utils"
import { getDmsServiceUrl } from "../.."
import { ExportBasketRequest, ExportCXMLBasketRequest, ExportOCIBasketRequest } from "../../model/sap"

function exportBasket(url: string, request: ExportBasketRequest) {
    return ajax<string>({
        url,
        method: "POST",
        authorization: getStoredAuthorization(),
        body: request,
        responseType: "text",
    })
}

export function exportOCIBasket(request: ExportOCIBasketRequest) {
    return exportBasket(`${getDmsServiceUrl()}/oci/V4/ExportBasket`, request)
}

export function exportCXMLBasket(request: ExportCXMLBasketRequest) {
    return exportBasket(`${getDmsServiceUrl()}/cXML/ExportBasket`, request)
}
