import { Suspense } from "react"
import { IMicros } from "@tm/models"
import PricesComponent from "../../../_shared/prices"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-prices"]

function ErpInfoPricesComponent(props: Props) {
    const { loading, erpInfo } = useErpInfo(props.data, "list", undefined, undefined, props.searchQuery)

    if (loading || !erpInfo?.prices?.length) {
        return null
    }

    return (
        <PricesComponent
            classPrefix="erp-info-prices"
            prices={erpInfo.prices}
            erpInfo={erpInfo}
            hasGraduatedPrices={false}
            hideGraduatedPricesIcon
            hideRetailPrice={props.hideRetailPrice}
            priceNamesInTooltip={props.priceNamesInTooltip}
            article={props.data}
            isReplacementPart={erpInfo.isReplacementPart}
            hideSpecialIcons={props.hideSpecialIconsFromPrices}
        />
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info-prices">
                    <Suspense fallback={null}>
                        <ErpInfoPricesComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
