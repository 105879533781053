import { callService } from ".."
import { getBundleParams } from "../../../utils"

function getTrucksServiceUrl(): string {
    return getBundleParams().trucksServiceUrl
}

export function callTrucksService<TReq, TRes>(url: string, request: TReq): Promise<TRes | undefined> {
    return callService<TReq, TRes>(`${getTrucksServiceUrl()}/${url}`, request)
}
