import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, encodeUniqueId, uniqueId, useExternalCatalogUrl } from "@tm/utils"
import { Widget, Dialog } from "@tm/controls"
import { useUser, useCisCustomer, useTelesalesCustomerNumber, useHasCis } from "@tm/context-distribution"
import { Box, Loader, Typography, Button, LinkButton, Stack, Image } from "@tm/components"
import { useHistory, useParams } from "react-router"
import WarehouseInfoComponent from "../_shared/customer/WarehouseInfo"
import WarehouseOpeningTimesComponent from "../_shared/customer/WarehouseOpeningTimes"
import CustomerDataComponent from "../_shared/customer/CustomerData"
import { TypographyTitle, TextBody1Bold, VerticalDivider, RowStack } from "../_shared/StyledComponents"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"
import { getBundleParams } from "../../utils"

type Props = {
    dataTitleId?: string
    externalSystemId?: number
    hideLinks?: boolean
    mainTitleTextId?: number
    subTitleTextId: number
    summaryUrl?: string
    variant?: "pv"
    openDetailsVariant?: "fullScreen" | "workTask"
}

export default function WidgetMyAccount(props: Props) {
    const { translateText, translate } = useLocalization()
    const history = useHistory()
    const hasCis = useHasCis()
    const { userContext } = useUser() ?? {}
    const matchParams = useParams<{ workTaskId?: string }>()

    const { summaryUrl, mainTitleTextId, subTitleTextId, externalSystemId, dataTitleId, hideLinks, variant, openDetailsVariant } = props
    const { cisCustomer, cisCustomerLoading } = useCisCustomer(!externalSystemId)
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl, loadingExternalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })

    const externalDialogRef = useRef<Dialog>(null)

    function openExternalUrl() {
        if (openDetailsVariant === "fullScreen" && !!externalCatalogUrl) {
            history.push(`/external01?url=${encodeURIComponent(externalCatalogUrl)}`)
            return
        }
        externalDialogRef.current?.show()
    }

    function renderUserContextCustomer() {
        if (!userContext) {
            return null
        }

        return (
            <Stack>
                <TypographyTitle>{translate(1085)}</TypographyTitle>
                <TextBody1Bold>{userContext.generalContact && userContext.generalContact.company}</TextBody1Bold>
                <Typography variant="body2">{userContext.generalContact && userContext.generalContact.name}</Typography>
                <Typography variant="body2">{userContext.generalAddress && userContext.generalAddress.street}</Typography>
                <Typography variant="body2">
                    {userContext.generalAddress && `${userContext.generalAddress.zip} ${userContext.generalAddress.city}`}
                </Typography>
                <Box display="flex" alignContent="center" mt={1}>
                    <Typography variant="body2">{`${translate(102)}:`}</Typography>
                    <Typography variant="body2" color="primary" fontWeight="bold" ml={0.5}>
                        {userContext.account && userContext.account.customerNo}
                    </Typography>
                </Box>
            </Stack>
        )
    }

    function renderMyAccountData() {
        if (!cisCustomer) {
            return null
        }

        return (
            <Box flex={1}>
                <CustomerDataComponent cisCustomer={cisCustomer} titleTextId={dataTitleId || 1085} />
            </Box>
        )
    }

    function renderMyAccountWarehouse() {
        if (!cisCustomer) {
            return null
        }

        const defaultSalesOutlet = cisCustomer.salesOutlets?.find((salesOutlet) => salesOutlet.isDefault)

        return (
            <>
                {defaultSalesOutlet && (
                    <Stack flex={1}>
                        <WarehouseInfoComponent warehouse={defaultSalesOutlet} warehouseHeaderId={subTitleTextId} />
                        <Box mt={2}>
                            <WarehouseOpeningTimesComponent businnessTimesMode="shopHoursToday" warehouse={defaultSalesOutlet} />
                        </Box>
                    </Stack>
                )}
            </>
        )
    }

    function myAccountWidgetFooter(openUrlFunction: () => void) {
        let detailsUrl = ""
        if (summaryUrl) {
            detailsUrl = renderRoute(summaryUrl, { ...matchParams })
        } else {
            detailsUrl = renderRoute(getBundleParams().summaryUrl, {
                ...matchParams,
                workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()),
            })
        }

        if ((!detailsUrl && !externalCatalogUrl) || hideLinks) {
            return <></>
        }

        return (
            <Box position="absolute" bottom="0.5rem" right={variant === "pv" ? "0.5rem" : undefined}>
                {externalCatalogUrl && openDetailsVariant !== "workTask" ? (
                    <Button disabled={loadingExternalCatalogUrl} onClick={openUrlFunction} size="large">
                        {translate(43)}
                    </Button>
                ) : (
                    <LinkButton to={detailsUrl} disabled={loadingExternalCatalogUrl} size="large">
                        {translate(43)}
                    </LinkButton>
                )}
            </Box>
        )
    }

    function renderCisCustomer() {
        const companyLogo = cisCustomer?.logos?.filter((logo) => !!logo.type && !!logo.url).first()
        return (
            <Stack>
                {companyLogo && (
                    <Box height={60} alignSelf="flex-start">
                        <Image width="100%" height="100%" src={companyLogo.url} />
                    </Box>
                )}
                <RowStack direction={variant === "pv" ? "row-reverse" : "row"} divider={<VerticalDivider />}>
                    {renderMyAccountData()}
                    {renderMyAccountWarehouse()}
                </RowStack>
            </Stack>
        )
    }

    if (!hasCis) {
        return null
    }

    return (
        <>
            <Widget
                size="4x2"
                active
                title={translate(1084)}
                iconName="pv-account"
                header={
                    <WidgetHeaderTitleComponent
                        iconName="pv-account"
                        titleTextId={mainTitleTextId || 1084}
                        linkIconName={variant === "pv" ? "pv-more" : undefined}
                        linkButtonIconName={variant === "pv" ? "pv-arrow" : undefined}
                        hideLinks={hideLinks}
                    />
                }
                footer={myAccountWidgetFooter(openExternalUrl)}
            >
                {cisCustomerLoading ? (
                    <Loader />
                ) : (
                    <>
                        {externalSystemId && renderUserContextCustomer()}
                        {!externalSystemId && renderCisCustomer()}
                    </>
                )}
            </Widget>
            {openDetailsVariant !== "fullScreen" && (
                <Dialog className="form-confirmation" ref={externalDialogRef} text={translateText(1084)} iconName="users" layout="stretch">
                    <iframe className="PDF-IFrame" src={externalCatalogUrl} title={externalCatalogUrl} />
                </Dialog>
            )}
        </>
    )
}
