import { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { useUser } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ESubModules, UserModuleType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { combiner, getCostPerCalculation, getCurrencyFromUserContext, initSelector, renderRoute } from "@tm/utils"
import { Alert, Box, Button, Icon, Typography, styled } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { MatchParams } from "../../data/models"
import { MainActions, customerSelector, vehicleSelector } from "../main/business"
import { CustomerFields, Header, MainFields, UserAgrementModal, VehicleFields, VehicleInfo } from "./components"
import { customerFieldsSelector } from "./components/customerFields"
import { vehicleFieldsSelector } from "./components/vehicleFields"

const selector = initSelector(
    vehicleSelector,
    customerSelector,
    (s) => s.manager.userIsRegistered,
    (s) => s.manager.checkCustomerLoading,
    vehicleFieldsSelector,
    customerFieldsSelector,
    combiner.array
)

export default function SummaryComponent() {
    const { translateText, currency } = useLocalization()
    const user = useUser()
    const actions = useActions(MainActions, "updateFieldValue", "startCalculation", "checkCustomer", "changeModalDisplay")
    const [vehicle, userIsRegistered, checkCustomerLoading] = useSelector(selector)
    const history = useHistory()
    const matchParams = useParams<MatchParams>()

    const currencyCode = getCurrencyFromUserContext(user?.userContext)
    const calculationCost = getCostPerCalculation(UserModuleType.TMDamageCalculationGlass, ESubModules.DamageCalculationGlass, user?.userContext)
    const costAlertText = translateText(12408) + currency(calculationCost || 0, currencyCode)

    const redirectToDashboard = useCallback(() => {
        const url = renderRoute("/:workTaskId", { ...matchParams })
        history.push(url)
    }, [])

    // if (!vehicle) {
    //     //todo redirect to worktasks
    // }

    useEffect(() => {
        if (!userIsRegistered && !checkCustomerLoading) {
            actions.checkCustomer(translateText)
        }
    }, [])

    const renderCostAlert = () => {
        return (
            <StyledPriceAlert>
                <Toolbar>
                    <Alert color="warning" icon={<Icon name="info-filled" />} title={costAlertText} />
                </Toolbar>
            </StyledPriceAlert>
        )
    }

    return (
        <Box>
            <UserAgrementModal />

            {!!calculationCost && renderCostAlert()}
            <Header />

            <VehicleInfo />

            {!vehicle && (
                <Box>
                    <Typography fontWeight={600}>{translateText(1581)}</Typography>
                    <Button startIcon={<Icon name="dashboard" />} onClick={redirectToDashboard}>
                        {translateText(760)}
                    </Button>
                </Box>
            )}

            {vehicle && (
                <>
                    <MainFields />
                    <VehicleFields />
                    <CustomerFields />
                </>
            )}
        </Box>
    )
}

const StyledPriceAlert = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    position: "absolute",
    right: 0,
    top: ".5em",
}))
