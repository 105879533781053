import { BundleComponent } from "@tm/morpheus"
import { MultiWidget } from "./MultiWidget"
import type { MultiWidgetProps } from "./MultiWidget"

const MultiWidgetBundle: BundleComponent = {
    name: "multi-widget",
    component: MultiWidget,
}

export { MultiWidgetBundle, MultiWidget, MultiWidgetProps }
