import { Article, ArticleInfoType, OrderItem, WholesalerPart } from "@tm/models"
import { ArticleAddRequestWM, CompilationShowViewModelItem } from "./model"
import { ArticleInterface } from "./interfaces/compilation-article-interface"

export function mapCompilationShowViewModelItemToArticle(item: CompilationShowViewModelItem): Partial<Article> {
    return {
        id: item.id,
        internalId: -1,
        quantity: item.quantity.value,
        description: item.description,
        supplier: {
            id: item.supplierId,
            name: item.supplierName || item.supplierArticleNumber,
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: item.supplierArticleNumber,
        traderArticleNo: item.wholesalerArticleNumber,
        productGroup: {
            id: item.productGroupId || -1,
            name: item.productGroupName || "",
            sortId: -1,
            thumbnail: "",
        },
        additionalDescription: item.note,
        initialQuantity: item.quantity.value,
        requestErpInfo: true,
        showAddToBasket: true,
        attributes: [],
        references: [],
        information: [],
    }
}

export function mapArticleToArticleAddRequest(article: Article): ArticleAddRequestWM {
    return {
        description: article.productGroup.name,
        quantity: article.quantity,
        supplierArticleNumber: article.supplierArticleNo,
        wholesalerArticleNo: article.traderArticleNo?.replace(/\D/g, "") || "",
        customerArticleNumber: article.traderArticleNo,
        supplierId: article.supplier.id,
    }
}

export function mapWholesalerPartToArticleAddRequest(wholesalerPart: WholesalerPart, additionalInformation?: string): ArticleAddRequestWM {
    return {
        description: wholesalerPart.additionalDescription || additionalInformation || "",
        quantity: wholesalerPart.quantityValue,
        supplierArticleNumber: "",
        wholesalerArticleNo: wholesalerPart.wholesalerArticleNumber,
    }
}

interface WholesalerPartExtended extends WholesalerPart {
    additionalInformation?: string
}

export interface ArticleToMap {
    articles?: Article[]
    wholesaler?: WholesalerPartExtended
}

export const mapArticleToCompilationArticle = (articleCollection: ArticleToMap): ArticleInterface[] => {
    const { articles, wholesaler } = articleCollection

    if (articles) {
        return articles.map((article) => {
            return {
                description: article.description || "",
                quantity: article.quantity,
                supplierArticleNumber: article.supplierArticleNo,
                wholesalerArticleNo: article.traderArticleNo,
                customerArticleNumber: article.traderArticleNo,
                supplierId: article.supplier.id,
                productGroupId: article.productGroup.id,
                productGroupName: article.productGroup.name,
                supplierName: article.supplier.name,
            }
        })
    }
    if (wholesaler) {
        return [
            {
                description: wholesaler.additionalDescription || wholesaler.additionalInformation || "",
                quantity: wholesaler.quantityValue,
                supplierArticleNumber: "",
                wholesalerArticleNo: wholesaler.wholesalerArticleNumber,
                productGroupName: wholesaler.productGroupName,
            },
        ]
    }

    return []
}

export const mapOrderItemsToCompilationArticle = (orderItems: OrderItem[]): ArticleInterface[] => {
    return sortOutCustomArticles(orderItems).map((item) => ({
        description: item.articleInfo.description,
        quantity: item.quantity.value,
        supplierArticleNumber: item.articleInfo.dataSupplierArticleNumber || "",
        wholesalerArticleNo: item.articleInfo.wholesalerArticleNumber || "",
        customerArticleNumber: item.articleInfo.articleNumber,
        supplierId: item.articleInfo.dataSupplier?.id,
        productGroupId: item.articleInfo.productGroup?.id,
        productGroupName: item.articleInfo.productGroup?.name,
    }))
}

/*
    use KVA for customArticles! Actual reason: we do not know how to handle customArticles in this package
 */
const sortOutCustomArticles = (orderItems: OrderItem[]): OrderItem[] => {
    return orderItems.filter((item) => item.articleInfoType !== ArticleInfoType.CustomArticle)
}
