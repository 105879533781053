import { useCallback } from "react"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import { usePartsRoutes } from "./usePartsRoutes"
import { getArticleDetailsRoute } from "../../manager/business"

export function useShowArticleDetails() {
    const partsRoutes = usePartsRoutes()
    const { workTaskId } = useWorkTask() ?? {}

    return useCallback(
        (productGroupId: number, supplierId: number, supplierArticleNo: string, quantity: number) => {
            if (!workTaskId) {
                return
            }
            const route = getArticleDetailsRoute(
                { article: { productGroupId, supplierId, supplierArticleNo }, initialQuantity: quantity },
                true,
                partsRoutes
            )
            if (route) {
                const url = `${renderRoute(route?.route, { workTaskId: encodeUniqueId(workTaskId) })}?${route.queryString}`
                Morpheus.showView("1", url)
            }
        },
        [partsRoutes, workTaskId]
    )
}
