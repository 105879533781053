import { ArticleInfoType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { ArticleNumber, Stack, styled } from "@tm/components"
import { useOpenArticleDetails } from "@tm/utils"
import { getBundleParams } from "../../../../../../utils"

const StyledStack = styled(Stack)({
    flex: "0 0 17em",
    minWidth: "100px",
    alignItems: "flex-start !important",
})

type Props = {
    articleNumber?: string
    articleType: ArticleInfoType
    isLinkedItem?: boolean
    productGroupId?: number
    supplierId?: number
    wholesalerArticleNumber?: string
}

export default function ArticleNumbers(props: Props) {
    const { articleNumber, articleType, supplierId, wholesalerArticleNumber, isLinkedItem, productGroupId } = props
    const userContext = useUser()?.userContext
    const { partsDetailRoute } = getBundleParams()

    const handleOpenArticleDetails = useOpenArticleDetails({
        productGroupId,
        supplierId,
        supplierArticleNumber: articleType === ArticleInfoType.TecdocArticle ? articleNumber : undefined,
        partsDetailsUrl: partsDetailRoute,
        openModal: Morpheus.showView,
    })

    if ((articleType === ArticleInfoType.OeArticle || articleType === ArticleInfoType.CustomArticle) && articleNumber) {
        return (
            <StyledStack>
                <ArticleNumber articleNumber={articleNumber} articleNumberType={articleType === ArticleInfoType.OeArticle ? "oe" : "custom"} />
            </StyledStack>
        )
    }

    return (
        <StyledStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
            {wholesalerArticleNumber && !userContext?.parameter.hideDealerPartNumber && (
                <ArticleNumber
                    articleNumber={wholesalerArticleNumber}
                    articleNumberType={isLinkedItem ? "linked" : "wholesaler"}
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={handleOpenArticleDetails}
                />
            )}
            {articleNumber && (
                <ArticleNumber
                    articleNumber={articleNumber}
                    articleNumberType={isLinkedItem ? "linked" : "supplier"}
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={handleOpenArticleDetails}
                />
            )}
        </StyledStack>
    )
}
