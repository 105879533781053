import { stylesheet } from "typestyle"

export function getComponentStyles() {
    return stylesheet({
        button: {
            width: "100%",
            marginBottom: "0.6rem",
            $nest: {
                ".btn__content": {
                    flex: 1,
                    textAlign: "left",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: "1.3em",
                },
            },
        },
    })
}
