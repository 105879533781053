import { ActionDispatch } from "@tm/morpheus"
import { clone } from "@tm/utils"

import { BundleActionType } from "../../../business"
import { ComponentActionType } from "./actions"
import { NavigationState } from "./model"

export * from "./actions"
export * from "./model"

const DEFAULT_STATE: NavigationState = {
    urls: {},
}

export function reduce(state = clone(DEFAULT_STATE) as NavigationState, action: ComponentActionType): NavigationState {
    switch (action.type) {
        case "SET_URLS": {
            return {
                ...state,
                urls: action.payload,
            }
        }
        case "SET_SECTION_URL": {
            state = clone(state)

            const { categoryType, sectionType, url } = action.payload
            const categoryUrls = state.urls[categoryType]

            if (!categoryUrls) {
                state.urls[categoryType] = { [sectionType]: { isActive: true, url } }
            } else {
                Object.keys(categoryUrls)
                    .map((key) => categoryUrls[key])
                    .forEach((x) => (x.isActive = false))
                categoryUrls[sectionType] = { isActive: true, url }
            }

            return state
        }
        default:
            break
    }

    return state
}

export function receive(action: BundleActionType, dispatch: ActionDispatch<ComponentActionType>, getState: () => NavigationState) {
    switch (action.type) {
        case "SET_SECTION_URL":
            dispatch(action)
            break
        default:
            break
    }
}
