import { DateUnit } from "@tm/utils"
import { CarInfo } from "../models"
import { constructionYearToDate } from "./constructionYear"

export const filterModelsByInitialRegistration = (selectedInitialRegistration: Date, vehicleModels: CarInfo[]) => {
    return vehicleModels.filter((vehicleModel) => {
        const constructionYearFrom = vehicleModel.constructionYearFrom && constructionYearToDate(vehicleModel.constructionYearFrom)
        const constructionYearTo = vehicleModel.constructionYearTo && constructionYearToDate(vehicleModel.constructionYearTo)

        if (!constructionYearFrom) {
            return false
        }

        let validMatch =
            selectedInitialRegistration.isSame(constructionYearFrom) || (constructionYearTo && selectedInitialRegistration.isSame(constructionYearTo))

        if (!validMatch && !constructionYearTo && selectedInitialRegistration.isAfter(constructionYearFrom)) {
            validMatch = true
        }

        if (!validMatch && constructionYearTo) {
            validMatch = selectedInitialRegistration.isAfter(constructionYearFrom) && selectedInitialRegistration.isBefore(constructionYearTo)

            if (!validMatch && selectedInitialRegistration.isAfter(constructionYearTo)) {
                // if is after maxDate and the difference is maximum 6 months
                const carDateIncreased = constructionYearTo.add(24, DateUnit.Month)

                if (carDateIncreased.isSame(selectedInitialRegistration)) {
                    validMatch = true
                }

                if (!validMatch) {
                    const diff = monthDiff(carDateIncreased, selectedInitialRegistration)

                    validMatch = diff <= 0 && diff > -25
                }
            }
        }

        return validMatch
    })
}

const monthDiff = (dateFrom: Date, dateTo: Date) => dateTo.month() - dateFrom.month() + 12 * (dateTo.year() - dateFrom.year())
