import { ConstructionYear } from "@tm/models"

export const constructionYearToDate = (data: ConstructionYear): Date | undefined => {
    const { year, month } = data
    if (isNaN(year) || isNaN(month)) {
        return undefined
    }

    return new Date(year, month - 1)
}
