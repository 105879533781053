import { ajax, getStoredAuthorization } from "@tm/utils"
import { QueryClient } from "react-query"
import { getBundleParams } from "../../../utils"
import { UpdateHistoryRequest } from "./models"
import { CalculationHistoryStatus } from "../../models"

export function updateHistory(request: UpdateHistoryRequest) {
    const url = `${getBundleParams().historyServiceUrl}/UpdateHistoryWithData`
    const authorization = getStoredAuthorization()
    const body = request

    const queryClient = new QueryClient()
    return queryClient.fetchQuery({
        queryKey: ["UpdateHistoryWithData", { request }],
        queryFn: () => ajax({ url, body, authorization, method: "POST" }),
    })
}

export function getUpdateHistoryRequest(historyEntryId?: string, status?: CalculationHistoryStatus, historyDataJson?: string) {
    if (historyEntryId && status && historyDataJson) {
        const request: UpdateHistoryRequest = {
            id: historyEntryId,
            status,
            historyDataJson,
        }

        return request
    }

    return null
}
