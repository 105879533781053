import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Radio, RadioProps, Text } from "@tm/controls"

type Props = RadioProps

export default function CustomRadio({ label, ...rest }: Props) {
    const style = useMemo(() => getStyle(), [])

    return (
        <div className={style.wrapper}>
            <Radio {...rest} size="s" />
            <Text modifiers="sub" className={style.text}>
                {label}
            </Text>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            display: "flex",
        },
        text: {
            marginLeft: theme.margin.s,
        },
    })(CustomRadio)
}
