import { useLocalization } from "@tm/localization"
import { ErpPrice } from "@tm/models"
import { Table, Icon } from "@tm/controls"

type LinkedItemsProps = {
    prices: Array<ErpPrice>
}

export default function GraduatedPricesComponent(props: LinkedItemsProps) {
    const { translate, currency } = useLocalization()

    const renderQuantityCell = (item: ErpPrice) => {
        return (
            <Table.Cell>
                {translate(707)} {item.priceUnit} {translate(708)}
            </Table.Cell>
        )
    }

    const renderPriceCell = (item: ErpPrice) => {
        return <Table.Cell>{currency(item.value, item.currencySymbol)}</Table.Cell>
    }

    const renderVatCell = (item: ErpPrice) => {
        return <Table.Cell>{item.vat}%</Table.Cell>
    }

    const columns = [
        <Table.Column className="quantityCell" renderItemContent={renderQuantityCell}>
            {translate(89)}
        </Table.Column>,
        <Table.Column className="priceCell" renderItemContent={renderPriceCell}>
            {translate(53)}
        </Table.Column>,
        <Table.Column className="vatCell" renderItemContent={renderVatCell}>
            {translate(706)}
        </Table.Column>,
    ]

    return (
        <>
            <div className="graduated-prices__title">
                <Icon size="l" name="staffelpreis" />
                <span className="headline headline--xs">{translate(705)}</span>
            </div>
            <Table data={props.prices} columns={columns} />
        </>
    )
}
