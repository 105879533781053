import { AddCustomArticleItemsRequest, AddCustomArticleItemsResponse, EditCustomArticleItemRequest, EditCustomArticleItemResponse } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"

function getServiceUrl() {
    return getBundleParams().customArticlesServiceUrl
}

export function addCustomArticleItems(body: AddCustomArticleItemsRequest) {
    const url = `${getServiceUrl()}/AddCustomArticleItems`
    const authorization = getStoredAuthorization()
    return ajax<AddCustomArticleItemsResponse>({ url, body, authorization, method: "POST" })
}

export function editCustomArticleItem(body: EditCustomArticleItemRequest) {
    const url = `${getServiceUrl()}/EditCustomArticleItem`
    const authorization = getStoredAuthorization()
    return ajax<EditCustomArticleItemResponse>({ url, body, authorization, method: "POST" })
}
