import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { WarningPrompt } from "@tm/controls"
import { Button, Icon, SelectorPaper, Stack, Typography, styled } from "@tm/components"

type Props = {
    allSelected: boolean
    selectedIds: Array<string>
    onSelectAll(): void
    onUnselectAll(): void
    onDeleteCostEstimations(ids: Array<string>): void
}

const CancelButton = styled(Button)(({ theme }) => ({
    "&.MuiButton-contained": {
        borderRadius: 0,
        borderTopRightRadius: theme.radius?.default || "3px",
        borderBottomRightRadius: theme.radius?.default || "3px",
    },
}))

export default function CostEstimationSelection(props: Props) {
    const { translateText } = useLocalization()
    const dialogRef = useRef<WarningPrompt>(null)
    const { selectedIds, allSelected, onDeleteCostEstimations, onSelectAll, onUnselectAll } = props

    function handleSelectButtonClick() {
        if (allSelected) {
            onUnselectAll()
        } else {
            onSelectAll()
        }
    }

    return (
        <SelectorPaper sx={{ position: "relative", height: "2.4em", right: 0 }}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="label">{translateText(701).toUpperCase()}: </Typography>
                <Typography variant="label">
                    {selectedIds.length} {translateText(479)}
                </Typography>
                <Button size="small" onClick={handleSelectButtonClick}>
                    {translateText(allSelected ? 703 : 702)}
                </Button>
                <Button onClick={() => dialogRef.current?.show()} startIcon={<Icon name="delete" />} variant="text" />
                <CancelButton
                    size="large"
                    color="primary"
                    startIcon={<Icon name="cancel-x" />}
                    title={translateText(317)}
                    onClick={() => onUnselectAll()}
                />
            </Stack>
            <WarningPrompt
                text={translateText(1752)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={dialogRef}
                onConfirm={() => onDeleteCostEstimations(selectedIds)}
            />
        </SelectorPaper>
    )
}
