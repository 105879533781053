import { useMemo } from "react"
import { Checkbox, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes, ModelState } from "@tm/utils"
import { ShowVrcInformationResponse } from "../../../../../data/model/vrc-lookup"
import { getComponentStyles } from "../styles"

type Props = {
    enabled: boolean
    setEnabled(): void
    vrcLookup: ShowVrcInformationResponse
    setVrcLookup(vrmLookup: ShowVrcInformationResponse): void
    saving: boolean
    requestVehicles: boolean
    modelState: ModelState | undefined
}

export function CustomerForm({ enabled, setEnabled, vrcLookup, setVrcLookup, saving, requestVehicles, modelState }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()

    const customerDisabled = saving || requestVehicles || !enabled

    return (
        <div className={classes(classNames.form, !enabled && classNames.formDisabled)}>
            <Checkbox label={translateText(107)} size="l" checked={enabled} disabled={saving || requestVehicles} onToggle={setEnabled} />

            <TextField
                floatingLabel
                label={translateText(119)}
                model={vrcLookup}
                path={["info", "firstName"]}
                onChange={setVrcLookup}
                disabled={customerDisabled}
            />
            <TextField
                className="is-required"
                floatingLabel
                label={` ${translateText(155)} ${translateText(1098)} ${translateText(108)}`}
                model={vrcLookup}
                path={["info", "name"]}
                onChange={setVrcLookup}
                disabled={customerDisabled}
                modelState={modelState}
            />
            <TextField
                floatingLabel
                label={translateText(112)}
                model={vrcLookup}
                path={["info", "postalCode"]}
                onChange={setVrcLookup}
                disabled={customerDisabled}
            />
            <TextField
                floatingLabel
                label={translateText(113)}
                model={vrcLookup}
                path={["info", "city"]}
                onChange={setVrcLookup}
                disabled={customerDisabled}
            />
            <TextField
                floatingLabel
                label={translateText(111)}
                model={vrcLookup}
                path={["info", "street"]}
                onChange={setVrcLookup}
                disabled={customerDisabled}
            />
        </div>
    )
}
