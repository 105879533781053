import { useMemo } from "react"
import { useWorkTask } from "@tm/context-distribution"

export function useVehicle() {
    const workTaskContainer = useWorkTask()

    const vehicle = useMemo(() => {
        return workTaskContainer?.workTask?.vehicle
    }, [workTaskContainer?.workTask?.vehicle])

    return vehicle
}
