import { Icon, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ManufacturerBase } from "@tm/models"
import { classes, concat } from "@tm/utils"
import { Box, Typography, Divider } from "@tm/components"

import { getBundleParams } from "../../utils"

type Props = {
    primaryManufacturers: Array<ManufacturerBase>
    secondaryManufacturers?: Array<ManufacturerBase>
    topManufacturers?: Array<ManufacturerBase>
    selectedManufacturerId?: number
    onSelect(manufacturer: ManufacturerBase): void
}

export default function ManufacturerList(props: Props) {
    const { translateText } = useLocalization()
    const { hideManufacturerLogos } = getBundleParams()

    function isFavoriteManufacturer(manufacturer: ManufacturerBase): boolean {
        return props.topManufacturers?.some((x) => x.id === manufacturer.id) ?? false
    }

    function renderItem(item: ManufacturerBase, favorite?: boolean) {
        const { selectedManufacturerId, onSelect } = props
        const className = concat(
            " ",
            "card__inner",
            selectedManufacturerId && selectedManufacturerId === item.id && "card--selected",
            hideManufacturerLogos && "no-logos"
        )

        return (
            <li key={item.id} className="card manufacturers__item">
                {
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <figure className={className} onClick={() => onSelect(item)}>
                        {!hideManufacturerLogos && <Image url={item.thumbnail} type="manufacturer" />}
                        {favorite && <Icon name="vote-star" />}
                        <figcaption>{item.description}</figcaption>
                    </figure>
                }
            </li>
        )
    }

    function renderHead(text: string) {
        return (
            <div className="manufacturers__header">
                <Typography variant="h2" mt={2} className="manufacturers__headline">
                    {text}
                </Typography>
            </div>
        )
    }

    function renderList(manufacturers: Array<ManufacturerBase>, bemModifier?: string, text?: string) {
        if (!manufacturers.length) {
            return
        }

        return (
            <div className="manufacturers__list-wrapper">
                {text && renderHead(text)}
                <ul className={classes("manufacturers__list", bemModifier && `manufacturers__list--${bemModifier}`)}>
                    {manufacturers.map((item) => renderItem(item, isFavoriteManufacturer(item)))}
                </ul>
            </div>
        )
    }

    function renderTopList() {
        if (!props.topManufacturers?.length || props.primaryManufacturers.length + (props.secondaryManufacturers?.length ?? 0) < 10) {
            return
        }

        return (
            <div className="manufacturers__list-wrapper">
                {/* {text && renderHead(text)} */}
                <ul className="manufacturers__list manufacturers__list--top">{props.topManufacturers.map((item) => renderItem(item, true))}</ul>
            </div>
        )
    }

    return (
        <Box m={2} className="manufacturers">
            {renderTopList()}
            <Divider sx={{ my: 2 }} light />
            {renderList(props.primaryManufacturers, "prio")}
            {renderList(props.secondaryManufacturers ?? [], undefined, props.primaryManufacturers.length ? translateText(673) : undefined)}
        </Box>
    )
}
