import { combineReducers } from "redux"
import { Actions as externActions, ComponentActionType as ExternComponentActionType, reduce as externReducer } from "../../extern/business"
import { Actions as managerActions, ComponentActionType as SummaryComponentActionType, reduce as managerReducer } from "../../moduleManager/business"
import {
    Actions as navigationActions,
    ComponentActionType as NavigationComponentActionType,
    reduce as navigationReducer,
} from "../../navigation/business"
import { Actions as summaryActions, ComponentActionType as ManagerComponentActionType, reduce as summaryReducer } from "../../summary/business"
import { Actions as settingsActions, ComponentActionType as SettingsComponentActionType, reduce as settingsReducer } from "../../settings/business"
import {
    Actions as calculationActions,
    ComponentActionType as CalculationComponentActionType,
    reduce as calculationReducer,
} from "../../calculation/business"

export const reduce = combineReducers({
    manager: managerReducer,
    navigation: navigationReducer,
    summary: summaryReducer,
    extern: externReducer,
    settings: settingsReducer,
    calculation: calculationReducer,
})

export type IMainActions = typeof MainActions
export const MainActions = {
    ...managerActions,
    ...summaryActions,
    ...externActions,
    ...navigationActions,
    ...settingsActions,
    ...calculationActions,
}

export * from "./selectors"

export type MainActionsType =
    | SummaryComponentActionType
    | CalculationComponentActionType
    | NavigationComponentActionType
    | ManagerComponentActionType
    | ExternComponentActionType
    | SettingsComponentActionType
