import { Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    textId: number
}

export function VisibleOption({ textId }: Props) {
    const { translateText } = useLocalization()

    return (
        <Stack direction="row" spacing={1}>
            <Icon name="visible" />
            <Typography variant="body2"> {translateText(textId)}</Typography>
        </Stack>
    )
}
