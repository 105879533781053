import { ExternalModule } from "@tm/models"
import { useReplaceExternalCatalogUrl } from "../../../hooks/useReplaceExternalCatalogUrl"

type ComponentProps = {
    item: ExternalModule
    className: string
    requiresExternalToken?: boolean
}

export default function IframeComponent(props: ComponentProps) {
    const { className, requiresExternalToken, item } = props
    const { url } = useReplaceExternalCatalogUrl({}, item.replacedUrl, requiresExternalToken)

    if (!url) {
        return null
    }

    return (
        <iframe
            title={`external-catalogs--iframe${item.id}`}
            key={`external-catalogs--iframe${item.id}`}
            about={item.description}
            src={url}
            className={`external-catalogs--iframe${className}`}
        />
    )
}
