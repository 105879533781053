import { AsyncAction } from "@tm/morpheus"
import { RegisteredModels, CarModelDetailsResponse } from "@tm/models"
import { Container } from "@tm/nexus"
import { JfNetworkPvState } from "./model"
import { getJfUrlFromPV } from "../../../data"

export * from "./model"

export type ComponentActionType = { type: "URL_LOADING" } | { type: "URL_LOADED"; payload: string }

const DEFAULT_STATE: JfNetworkPvState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): JfNetworkPvState {
    switch (action.type) {
        case "URL_LOADING": {
            return {
                ...state,
                url: undefined,
            }
        }
        case "URL_LOADED": {
            return {
                ...state,
                url: action.payload,
            }
        }
    }

    return state
}

export interface IActions {
    getUrl(baseUrl: string, profileId: string, kTypNr?: number, kbaNumber?: string): void
}

function getUrl(baseUrl: string, profileId: string, kTypNr?: number, kbaNumber?: string): AsyncAction<ComponentActionType> {
    return async (dispatch) => {
        dispatch({ type: "URL_LOADING" })
        const requestVehicleParams = await getVehicleParameter(kTypNr, kbaNumber)
        dispatch(requestPV(baseUrl, profileId, requestVehicleParams))
    }
}

async function getVehicleParameter(kTypNr?: number, kbaNumber?: string) {
    if (kTypNr) {
        if (kbaNumber) {
            const kba = kbaNumber.split(" ")
            if (kba && kba.length > 1) {
                const hsn = kba[0]
                const tsn = kba[1]
                return { kTypNr, hsn, tsn }
            }
        }

        const container = Container.getInstance<CarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)

        const vehicle = await container
            .subscribe({ modelId: kTypNr })
            .load()
            .then((response) => {
                const kba = response.modelDetails && response.modelDetails.registrationNos[0].split(" ")
                if (kba && kba.length > 1) {
                    const hsn = kba[0]
                    const tsn = kba[1]
                    return { kTypNr, hsn, tsn }
                }
            })
        return vehicle
    }
}

function requestPV(baseUrl: string, profileId: string, vehicle?: { kTypNr: number; hsn: string; tsn: string }): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        let serviceUrl = `${baseUrl}/${profileId}`

        if (vehicle) {
            serviceUrl += `/${vehicle.kTypNr}/${vehicle.hsn}/${vehicle.tsn}`
        }

        getJfUrlFromPV(serviceUrl).then((response) => {
            dispatch({ type: "URL_LOADED", payload: response })
        })
    }
}

export const Actions: IActions = {
    getUrl,
}
