import { BikeModel, RegistrationNoType, ShowBikeModelDetailsRequest, ShowBikeModelDetailsResponse } from "@tm/models"

import { callBikesService } from "."
import { PagedRequest, PagedResponse } from ".."
import { EngineCapacityFilter, ModelYearFilter } from "../../model/filters"

type SelectedModelFilters = {
    modelYear?: number
    engineCapacity?: number
}

type ModelFilters = {
    modelYears: Array<ModelYearFilter>
    engineCapacities: Array<EngineCapacityFilter>
}

export type ModelsResponse = PagedResponse & {
    models: Array<BikeModel>
    filters: ModelFilters
}

type ShowModelsByArticleRequest = PagedRequest & {
    articleId: number
    productGroupId?: number
    manufacturerId?: number
    modelSeriesId?: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByArticle(request: ShowModelsByArticleRequest) {
    return callBikesService<ShowModelsByArticleRequest, ModelsResponse>("ShowModelsByArticle", request)
}

type ShowModelsByPartListArticleRequest = PagedRequest & {
    partsListArticleId: number
    modelSeriesId: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByPartListArticle(request: ShowModelsByPartListArticleRequest) {
    return callBikesService<ShowModelsByPartListArticleRequest, ModelsResponse>("ShowModelsByPartListArticle", request)
}

type ShowModelsByModelSeriesRequest = PagedRequest & {
    modelSeriesId: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByModelSeries(request: ShowModelsByModelSeriesRequest) {
    return callBikesService<ShowModelsByModelSeriesRequest, ModelsResponse>("ShowModelsByModelSeries", request)
}

type ShowModelsByQueryRequest = PagedRequest & {
    query: string
    manufacturerIds?: Array<number>
    modelSeriesId?: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByQuery(request: ShowModelsByQueryRequest) {
    return callBikesService<ShowModelsByQueryRequest, ModelsResponse>("ShowModelsByQuery", request)
}

type ShowModelsByRegistrationNoRequest = PagedRequest & {
    registrationNo: string
    registrationNoTypeId: RegistrationNoType
    selectedFilters?: SelectedModelFilters
}

export function showModelsByRegistrationNo(request: ShowModelsByRegistrationNoRequest) {
    return callBikesService<ShowModelsByRegistrationNoRequest, ModelsResponse>("ShowModelsByRegistrationNo", request)
}

export function showModelDetails(request: ShowBikeModelDetailsRequest) {
    return callBikesService<ShowBikeModelDetailsRequest, ShowBikeModelDetailsResponse>("ShowModelDetails", request)
}
