import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteInputChangeReason,
    AutocompleteRenderInputParams,
    Dialog,
    FormGroup,
    Icon,
    Loader,
    SearchButton,
    Stack,
    TextField,
    Typography,
    alpha,
    colors,
    filledInputClasses,
    styled,
} from "@tm/components"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType, VehicleType } from "@tm/models"
import { debounce, encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useParams } from "react-router"
import { searchVehiclesByVin } from "../../../business"
import { useModelsByQueryLoadable } from "../../../data/hooks"
import { useVehicleSearchOptionsQuota } from "../../../data/hooks/useVehicleSearchOptionsQuota"
import { VrmLookupErrorTextIds, handleDATRegistration } from "../../../helpers"
import { getBundleParams } from "../../../utils"
import RequestVinComponent from "../../request-vin/component"
import { VehicleSearchfieldV1Props } from "../VehicleSearchFieldSwitch"
import VrcScanButton from "../vrc-scan-button"
import { VehicleSearchOption } from "./VehicleSearchOption"
import { VehicleSearchPaper } from "./VehicleSearchPaper"
import { useVehicleSearch } from "./useVehicleSearch"
import { VehicleSuggestions, useVehicleSearchAutocomplete } from "./useVehicleSearchAutocomplete"

const StyledAutoComplete = styled(Autocomplete<VehicleSuggestions, false, true>)(({ theme, size }) => {
    const backgroundColor = theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100]
    const focusBorderColor = theme.overwrites?.components?.textfield?.focus?.border || theme.palette.primary.main
    const cutEdge = theme.overwrites?.components?.textfield?.cutEdge
    return {
        ...(cutEdge && {
            paddingRight: "15px",
        }),
        [`.${filledInputClasses.root}`]: {
            padding: "5px",
            opacity: 1,
            color: "black",
            backgroundColor,
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
            "&:before": {
                content: "none",
            },
            "&:after": {
                content: "none",
            },
            "&:hover": {
                backgroundColor,
            },
            [`&.${filledInputClasses.adornedEnd}.${filledInputClasses.focused}`]: {
                boxShadow: cutEdge ? "none !important" : "0 4px 10px 0 rgba(0,0,0,.2)",
            },
            [`&.${filledInputClasses.focused}`]: {
                backgroundColor,
                borderColor: `${focusBorderColor} !important`,
                boxShadow: cutEdge ? "none !important" : `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            },
            input: {
                fontSize: "18px",
            },
            ...(size === "small" && {
                padding: "1.5px",

                input: {
                    fontSize: "16px",
                },
            }),
        },
    }
})

const StyledTextField = styled(TextField, { shouldForwardProp: (prop) => prop !== "hideOutline" })<{ hideOutline?: boolean }>(
    ({ theme, hideOutline }) => {
        const borderColor = theme.overwrites?.components?.textfield?.border?.color || theme.palette.primary.main
        const cutEdge = theme.overwrites?.components?.textfield?.cutEdge
        return {
            [`.${filledInputClasses.root}`]: {
                backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor,
                ...(!hideOutline && { border: `1px solid ${borderColor}` }),
                borderRadius: theme.radius?.default || "3px",

                ...(!cutEdge && {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }),
                fontSize: "18px",

                ...(cutEdge && {
                    [`&::after`]: {
                        content: "''",
                        position: "absolute",
                        width: 0,
                        height: 0,
                        border: "7px solid transparent",
                        right: "-1px",
                        bottom: "-1px",
                        borderColor: "transparent #fff #fff transparent",
                    },

                    [`&::before`]: {
                        content: "''",
                        position: "absolute",
                        right: 0,
                        bottom: 0,
                        width: 0,
                        height: 0,
                        borderStyle: "solid",
                        borderWidth: "7px",
                        borderColor: `transparent  ${borderColor}  ${borderColor} transparent`,
                        background: theme.overwrites?.components?.textfield?.backgroundColor,
                    },
                }),
            },
        }
    }
)

const InlineSearchButton = styled(SearchButton)(({ theme }) => {
    const cutEdge = theme.overwrites?.components?.textfield?.cutEdge
    return {
        "&.MuiButton-sizeMedium": {
            borderRadius: `${theme.radius?.default || "2px"} !important`,
            ...(!cutEdge && {
                borderTopLeftRadius: "0 !important",
                borderBottomLeftRadius: "0 !important",
            }),
            padding: "7.5px",
        },
    }
})

const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "6px 10px",
    textTransform: "uppercase",
    backgroundColor: theme.palette.background.paper,
    zIndex: 1,
}))

const DEBOUNCE_IN_MS = 500
const VIN_CHAR_LENGTH = 17
const MIN_LENGTH_TO_START_SEARCH = 2

export type VehicleSearchError = {
    message: string
    type?: "registrationError" | "searchError"
}

export function VehicleSearchField(props: VehicleSearchfieldV1Props) {
    const {
        onUniqueVehicleFound,
        onStartSearch,
        onAttachVehicleId,
        placeholderPassengerCar,
        vehicleType,
        disableEdsSearch,
        showVrcScanButton,
        hideTooltip,
        size,
        isOnIndustryDashboard,
    } = props
    let initValue = props.initialValue
    const [query, setQuery] = useState<string>(initValue || "")
    const [searchQuery, setSearchQuery] = useState<string>()
    const [wasAutocompleteSelect, setWasAutocompleteSelect] = useState(false)
    const [error, setError] = useState<VehicleSearchError>()
    const [loading, setLoading] = useState(false)
    const [openRequestVinDialog, setOpenRequestVinDialog] = useState(false)
    const [openOptions, setOpenOptions] = useState(false)
    const { plateCode } = useCountryCodeToLicensePlate()

    const inputFieldRef = useRef<HTMLDivElement>(null)

    const history = useHistory()
    const params = useParams<{ workTaskId?: string }>()
    const { translateText } = useLocalization()
    const {
        isFetching: isFetchingAutoCompleteData,
        findSuggestions,
        suggestions,
        config,
        selectedRegNoType,
        clearSuggestions,
    } = useVehicleSearchAutocomplete(vehicleType)
    const { invalidateStore } = useVehicleSearchOptionsQuota(RegistrationNoType.DatVin)
    const { tooltipMessage, getGroupHeaderName, searchPlaceholder } = useVehicleSearch(vehicleType, placeholderPassengerCar)
    const [initialOptions, setInitialOptions] = useState(true)

    const { response: { customerVehicles, models } = {} } = useModelsByQueryLoadable({
        vehicleType,
        query: searchQuery,
        selectedFilters: {},
        selectedRegistrationNoType: selectedRegNoType,
    })

    useEffect(() => {
        if (initialOptions) {
            setInitialOptions(false)
        }
    }, [])

    // only display the options frame if there are results
    useEffect(() => {
        if (!isFetchingAutoCompleteData && !initialOptions) {
            setOpenOptions(suggestions.length > 0 || query.length === VIN_CHAR_LENGTH)
        }
    }, [isFetchingAutoCompleteData, query.length, suggestions.length])

    useEffect(() => {
        if (!searchQuery || (!customerVehicles && !models)) {
            return
        }

        setSearchQuery(undefined) // Reset search query to prevent second run

        // only automatically attach the customer vehicle if there aren't any tecdoc models found to keep the option to select another vehicle for the user
        if (customerVehicles?.length === 1 && !models?.length) {
            onUniqueVehicleFound?.(customerVehicles[0], searchQuery)
        }
        // only automatically attach the tecdoc model if there aren't any customer vehicles found
        // and the tecdoc model wasn't found by the keyword search (which could return fuzzy results) if it wasn't an autocomplete selection
        else if (
            !customerVehicles?.length &&
            models?.length === 1 &&
            (models[0].dataSourceId !== RegistrationNoType.KeywordSearch || wasAutocompleteSelect)
        ) {
            onUniqueVehicleFound?.(models[0], searchQuery)
        } else {
            setLoading(false)
            onStartSearch(searchQuery, undefined, config)
        }
    }, [customerVehicles, models, searchQuery, wasAutocompleteSelect, onUniqueVehicleFound, onStartSearch, config])

    const debouncedSendRequest = useMemo(() => {
        return debounce(findSuggestions, DEBOUNCE_IN_MS)
    }, [findSuggestions])

    function startSearch(value: string) {
        // Do not check for isFetchingAutoCompleteData, otherwise the search will always be blocked while
        // autocomplete data is loading. (see NEXT-28178)
        if (!value || value.length < MIN_LENGTH_TO_START_SEARCH) {
            ;(props.inputFieldRef ?? inputFieldRef).current?.focus()
            return
        }

        if (!props.onUniqueVehicleFound) {
            props.onStartSearch(value, undefined, config)
            return
        }

        setError(undefined)
        setSearchQuery(value) // changing the search query will trigger the models to be loaded
    }

    function handleSuggestionCustomerVehicleSelect(suggestionId: string) {
        onAttachVehicleId?.(suggestionId, query)
    }

    const onSearch = () => {
        setWasAutocompleteSelect(false)
        startSearch(query)
    }

    function handleDATSearchButtonClick() {
        const { enableDATVinSearch } = getBundleParams()

        if (!enableDATVinSearch || isFetchingAutoCompleteData) {
            return
        }

        setLoading(true)

        searchVehiclesByVin(query, RegistrationNoType.DatVin, true).then(
            (response) => {
                if (onUniqueVehicleFound && response?.models.length === 1) {
                    onUniqueVehicleFound(response?.models[0], query)
                    invalidateStore()
                } else {
                    setLoading(false)
                    onStartSearch(query, RegistrationNoType.DatVin, undefined)
                }
            },
            (errorTextId) => {
                const { datRegistrationPath } = getBundleParams()

                setLoading(false)

                if (errorTextId === VrmLookupErrorTextIds.UserNotRegistered && datRegistrationPath) {
                    // User is not already registered at DAT
                    const datRegistrationRoute = renderRoute(datRegistrationPath, {
                        ...params,
                        workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
                    })

                    handleDATRegistration(datRegistrationRoute).then(
                        () => handleDATSearchButtonClick(),
                        () => setError({ message: `${translateText(1476)} ${translateText(1477)} ${translateText(1478)}`, type: "registrationError" })
                    )
                } else if (errorTextId === VrmLookupErrorTextIds.NoResult || errorTextId === VrmLookupErrorTextIds.ManufacturerNotSupported) {
                    setError({ message: translateText(errorTextId), type: "searchError" })
                } else {
                    setError({ message: translateText(errorTextId) })
                }
            }
        )
    }

    function handleEdsSearchButtonClick() {
        const { edsVinSearchRoute } = getBundleParams()

        if (!edsVinSearchRoute) {
            return
        }

        const queryParams = new URLSearchParams()
        queryParams.set("vin", query)

        const url = `${renderRoute(edsVinSearchRoute, {
            workTaskId: params.workTaskId || encodeUniqueId(uniqueId()),
        })}?${queryParams.toString()}`

        history.push(url)
    }

    const handleRequestVin = () => {
        setOpenRequestVinDialog(true)
    }

    const renderInput = (inputParams: AutocompleteRenderInputParams) => {
        const InputProps = {
            ...inputParams.InputProps,
            endAdornment: (
                <Stack direction="row" spacing="4px" mr={1}>
                    {inputParams.InputProps.endAdornment}
                    {(isFetchingAutoCompleteData || loading) && <Loader size="extrasmall" />}
                    {query.length > 0 && (
                        <Icon
                            sx={{ cursor: "pointer" }}
                            name="close"
                            onClick={() => {
                                setQuery("")
                                clearSuggestions()
                                setOpenOptions(false)
                            }}
                        />
                    )}
                    {showVrcScanButton && vehicleType === VehicleType.PassengerCar && <VrcScanButton />}
                </Stack>
            ),
        }

        return (
            <StyledTextField
                {...inputParams}
                inputRef={props.inputFieldRef ?? inputFieldRef}
                InputProps={InputProps}
                onKeyDown={(e) => {
                    if (e.key === "Enter") {
                        startSearch(query)
                    }
                }}
                size="extralarge"
                placeholder={searchPlaceholder}
                hideOutline={isOnIndustryDashboard}
            />
        )
    }

    const onInputChange = (e: SyntheticEvent<Element, Event>, value: string, reason: AutocompleteInputChangeReason) => {
        setError(undefined)
        if (reason === "input") {
            if (value.length < MIN_LENGTH_TO_START_SEARCH || value === initValue) {
                initValue = undefined
                setQuery(value)
                clearSuggestions()
                setOpenOptions(false)
                return
            }
            const regex = /[^a-zA-Z0-9ßẞöäüÖÄÜ() ,.\-:_;+*#\[\]/\\]/g
            const illegalCharacters = value.match(regex)?.distinct().join("")
            // Replace all not allowed characters:
            // for example:
            // - % (creates invalid urls due to: https://github.com/ReactTraining/history/issues/505)
            // - ! (not supported by database)
            const cleanChangeQuery = value.replace(regex, "")

            setError(illegalCharacters ? { message: translateText(12777).replace("{0}", illegalCharacters) } : undefined)
            setQuery(cleanChangeQuery)
            if (!illegalCharacters) {
                debouncedSendRequest(value)
            }
        }
    }

    const onChange = (event: SyntheticEvent, value: VehicleSuggestions, reason: AutocompleteChangeReason) => {
        if (reason === "selectOption" && value?.suggestions) {
            const trimmedValue = value?.suggestions.trim()
            if (trimmedValue.length > 0) {
                setOpenOptions(false)
                setLoading(true)
                if (value.type === "CUSTOMER" && value.vehicle) {
                    setWasAutocompleteSelect(true)
                    handleSuggestionCustomerVehicleSelect(value.vehicle.id)
                } else if (value.type === "REGNO" && value.carModel) {
                    onUniqueVehicleFound?.(value.carModel, query)
                } else {
                    startSearch(value.suggestions)
                }
            }
            setQuery(value.suggestions)
        }
    }

    function onOpenOptions() {
        setOpenOptions((suggestions.length > 0 && query.length > 0) || query.length === VIN_CHAR_LENGTH)
    }

    const showPlateColumn = useMemo(() => suggestions.some((val) => val.vehicle?.plateId), [suggestions])

    return (
        <Stack width={isOnIndustryDashboard ? "550px" : "auto"}>
            <FormGroup row sx={{ flexWrap: "noWrap" }}>
                <StyledAutoComplete
                    autoComplete
                    clearOnBlur={false} // keep the input
                    disableClearable // remove default clearbutton, behaviour is buggy in this version
                    disablePortal // always render component under the input
                    filterOptions={(x) => x} // workaround like Mui, always refresh the suggestions list
                    forcePopupIcon={false} // remove little arrow to show dropdown
                    fullWidth
                    disabled={props.disable}
                    groupBy={(sugg) => sugg.type}
                    getOptionLabel={(sugg) => sugg.suggestions}
                    isOptionEqualToValue={(option, value) => option.suggestions === value.suggestions}
                    loading={isFetchingAutoCompleteData || loading}
                    open={openOptions}
                    onOpen={onOpenOptions}
                    onClose={() => setOpenOptions(false)}
                    onChange={onChange} // value from options change
                    onInputChange={onInputChange} // text input change
                    options={suggestions}
                    PaperComponent={(paperProps) => (
                        <VehicleSearchPaper
                            {...paperProps}
                            error={error}
                            onClickRequestVin={handleRequestVin}
                            onDATSearchButtonClick={handleDATSearchButtonClick}
                            onEdsSearchButtonClick={handleEdsSearchButtonClick}
                            query={query}
                            disableEdsSearch={disableEdsSearch}
                            vehicleType={vehicleType}
                        />
                    )}
                    noOptionsText={query.length > 0 ? translateText(12775) : translateText(327)}
                    loadingText={translateText(13510)}
                    renderInput={renderInput}
                    renderGroup={(groupParms) => (
                        <li key={groupParms.key}>
                            <GroupHeader>{getGroupHeaderName(groupParms.group)}</GroupHeader>
                            <ul>{groupParms.children}</ul>
                        </li>
                    )}
                    renderOption={(liProps, option) => (
                        <VehicleSearchOption liProps={liProps} option={option} plateCode={plateCode} showPlateColumn={showPlateColumn} />
                    )}
                    size={size || "medium"}
                    value={{ suggestions: query, type: "CUSTOMER" }}
                />
                <InlineSearchButton onClick={onSearch} />
            </FormGroup>
            {!hideTooltip && (
                <Stack direction="row" mt={1} alignItems="center">
                    <Icon name="search" />
                    <Typography variant="label" ml={1}>
                        {tooltipMessage}
                    </Typography>
                </Stack>
            )}
            <Dialog open={openRequestVinDialog}>
                <RequestVinComponent query={query} onClose={() => setOpenRequestVinDialog(false)} />
            </Dialog>
        </Stack>
    )
}
