import { Size } from "@tm/controls"
import { IBundle } from "@tm/morpheus"
import ToolbarForm from "./modul/toolbar-form"
import { initFeedbackBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [ToolbarForm],
    init: initFeedbackBundle,
}

export default bundle
