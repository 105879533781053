import { Vehicle } from "@tm/models"
import { CarInfo, HistoryVehicle } from "../../../../data/models"
import { IVehicleInfoFields } from "../model"

export const mapToHistoryVehicle = (vehicle: Vehicle, selectedModel: CarInfo, vehicleInfoFields: IVehicleInfoFields): HistoryVehicle => {
    return {
        dateFirstRegistered: vehicleInfoFields.initialRegistration!,
        description: selectedModel.description,
        eqPackage: selectedModel.equipment,
        kTypNr: vehicle.tecDocTypeId,
        mileage: vehicleInfoFields.mileage?.toString() ?? "",
        nextVehicleId: vehicle.id,
        typeNo: selectedModel.natCode,
        vin: vehicleInfoFields.vin ?? "",
        vrm: vehicleInfoFields.plateNumber ?? "",
    }
}
