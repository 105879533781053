import { Box, Tooltip, Typography, useMediaQuery, useTheme } from "@tm/components"
import { BasketPart } from "../../../models"
import { OverflowTypography } from "../../StyledComponents"

type Props = {
    part: BasketPart
}

export default function ArticleSupplier({ part }: Props) {
    const { supplierName } = part.partItem.articleInformation
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    if (!supplierName) {
        return null
    }

    return isBigScreen ? (
        <Typography>{supplierName}</Typography>
    ) : (
        <Tooltip title={supplierName} variant="light">
            <Box>
                <OverflowTypography width="50px" whiteSpace="nowrap">
                    {supplierName}
                </OverflowTypography>
            </Box>
        </Tooltip>
    )
}
