import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Alert, Button, Icon, Stack, TextField, Typography, AlertProps } from "@tm/components"
import { ErpSettings as ErpSettingsModel } from "@tm/context-distribution"
import { setErpSettings } from "../../../data/repositories/externalCredentials"
import { SaveDatCredentialsRequest } from "../../../data/model/externalCredentials"

type Props = {
    erpSettings: ErpSettingsModel
}

type InfoMessage = {
    message: string
    type: AlertProps["severity"]
}

export default function ErpCredentialSettings(props: Props) {
    const [isSaving, setIsSaving] = useState(false)
    const [wholesalerCustomerNumber, setWholesalerCustomerNumber] = useState("")
    const [wholesalerUsername, setWholesalerUsername] = useState("")
    const [wholesalerPassword, setWholesalerPassword] = useState("")
    const [infoMessage, setInfoMessage] = useState<InfoMessage>({ message: "", type: "success" })
    const [wholesalerCustomerNumberError, setWholesalerCustomerNumberError] = useState(false)
    const [wholesalerUsernameError, setWholesalerUsernameError] = useState(false)
    const [wholesalerPasswordError, setWholesalerPasswordError] = useState(false)
    const [disabledWholesaler, setDisabledWholesaler] = useState(true)
    const [customerNumberRequired, setCustomerNumberRequired] = useState(false)
    const passwordPlaceholder = "**********"

    const { translateText } = useLocalization()

    useEffect(() => {
        if (props.erpSettings.userName) {
            setWholesalerUsername(props.erpSettings.userName)
        }
        if (props.erpSettings.customerNumber) {
            setWholesalerCustomerNumber(props.erpSettings.customerNumber)
        }
        if (props.erpSettings.isPasswordSet === true) {
            setWholesalerPassword(passwordPlaceholder)
        }
        if (props.erpSettings.isCustomerNoRequired === true) {
            setCustomerNumberRequired(true)
        }
    }, [props.erpSettings])

    function setWholesalerActive() {
        setDisabledWholesaler(!disabledWholesaler)
        setWholesalerPassword("")
    }
    async function saveWholesalerData() {
        if (!wholesalerPassword || !wholesalerUsername) {
            if (!wholesalerPassword) {
                setWholesalerPasswordError(!wholesalerPasswordError)
            }
            if (!wholesalerUsername) {
                setWholesalerUsernameError(!wholesalerUsername)
            }
            return
        }

        setDisabledWholesaler(!disabledWholesaler)
        setIsSaving(true)

        const data: SaveDatCredentialsRequest = {
            customerNo: wholesalerCustomerNumber,
            username: wholesalerUsername,
            password: wholesalerPassword,
        }

        try {
            await setErpSettings(data)
            setInfoMessage({
                message: translateText(13451),
                type: "success",
            })
        } catch (error) {
            setInfoMessage({
                message: translateText(13452),
                type: "error",
            })
        } finally {
            setIsSaving(false)
            setWholesalerCustomerNumberError(false)
            setWholesalerUsernameError(false)
            setWholesalerPasswordError(false)
        }
    }

    return (
        <Stack spacing={2}>
            <Typography variant="body2">{translateText(13416)}</Typography>
            {infoMessage.message !== "" && <Alert severity={infoMessage.type}>{infoMessage.message}</Alert>}
            <Stack direction="row" spacing={1} width="70%">
                {customerNumberRequired && (
                    <TextField
                        fullWidth
                        sx={{ margin: "0" }}
                        value={wholesalerCustomerNumber}
                        onChange={(e) => setWholesalerCustomerNumber(e.target.value)}
                        label={translateText(102)}
                        disabled={disabledWholesaler || isSaving}
                        error={wholesalerCustomerNumberError}
                        helperText={translateText(12741)}
                    />
                )}
                <TextField
                    fullWidth
                    sx={{ margin: "0" }}
                    value={wholesalerUsername}
                    onChange={(e) => setWholesalerUsername(e.target.value)}
                    label={translateText(186)}
                    disabled={disabledWholesaler || isSaving}
                    error={wholesalerUsernameError}
                    helperText={translateText(13449)}
                />
                <TextField
                    fullWidth
                    value={wholesalerPassword}
                    onChange={(e) => setWholesalerPassword(e.target.value)}
                    label={translateText(187)}
                    type="password"
                    disabled={disabledWholesaler || isSaving}
                    error={wholesalerPasswordError}
                    helperText={translateText(13448)}
                />
                <Button
                    onClick={() => setWholesalerActive()}
                    startIcon={<Icon name="edit_change" />}
                    size="medium"
                    disabled={!disabledWholesaler || isSaving}
                />
                <Button
                    onClick={() => saveWholesalerData()}
                    color="success"
                    startIcon={<Icon name="check" />}
                    size="medium"
                    disabled={disabledWholesaler || isSaving}
                />
            </Stack>
        </Stack>
    )
}
