import { stylesheet } from "typestyle"
// import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    // const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            position: "relative",
        },
        buttonInPopover: {
            marginLeft: 0,
            marginTop: "0.2em",
        },
        popover: {
            right: 5,
            $nest: {
                ".popover__inner": {
                    padding: "1rem",
                    display: "flex",
                    flexDirection: "column",
                },
            },
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
