import { Box } from "@tm/components"
import * as React from "react"
import { SearchType } from "../../../business"

type Props = {
    className?: string
    searchType?: SearchType
}

export const FiltersAnchor: React.FC<Props> = (props) => {
    return (
        <div id="article-list-filters" key="article-list-filters" className="searchtreeV2">
            {/* Don't remove this! It is the mounting point for the filters component */}
            <Box className="tk-parts">
                <Box className={`filters filters--clipped ${props.className || ""}`} />
            </Box>
        </div>
    )
}
