import { MenuItem, Select, Image, Typography, styled, selectClasses } from "@tm/components"
import { useEffect, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"

import { VehicleLookupConfigSlim } from "../../../data/repositories/vehicleLookupConfigs"
import { useSelectedSimpleLookupConfig } from "../../../data/hooks/useSelectedSimpleLookupConfig"

type Props = {
    lookupConfigs?: VehicleLookupConfigSlim[]
    loading?: boolean
}

const StyledSelect = styled(Select)(({ theme }) => {
    const { select, icon } = theme.overwrites?.toolkits?.vehicle?.widget?.countrySelection ?? {}
    const { border: themedBorder } = select || {}
    const { color: themedHoveredIconColor } = icon?.hover || {}
    const { color: themedIconColor } = icon || {}

    return {
        [`.${selectClasses.select}`]: {
            padding: "0px 0.5em 0px 2em!important",
            ".MuiSelect-icon": {
                color: themedIconColor || theme.palette.getContrastText(theme.palette.primary.light),
            },
        },
        [`.${selectClasses.iconOpen}`]: {
            color: themedIconColor,
        },
        [`.MuiOutlinedInput-notchedOutline`]: {
            border: themedBorder || undefined,
        },
        ".widget__collapse+.widget__inner &": {
            right: "3.5em",
            pointerEvents: "all",
        },
        ".multiwidget__content .vehicle-widget &": {
            position: "absolute",
            top: 0,
            right: 0,
        },
        img: {
            width: 32,
            height: 32,
        },
        "&:hover .MuiSelect-icon": {
            color: themedHoveredIconColor,
        },
    }
})

export function SearchSelection(props: Props) {
    const { lookupConfigs, loading } = props
    const { setSelectedConfig } = useSelectedSimpleLookupConfig()

    const [selectedCountrycode, setSelectedCountrycode] = useState<number>(0)

    const sortedSelectedLookupConfigs = useMemo(() => {
        return lookupConfigs?.sort((a, b) => a.sortIndex - b.sortIndex)
    }, [lookupConfigs])

    const { translateText } = useLocalization()

    const onChange = (sortIndex: number) => {
        setSelectedCountrycode(sortIndex)
        const config = lookupConfigs?.find((e) => e.sortIndex === sortIndex)
        setSelectedConfig(config)
    }

    useEffect(() => {
        if (sortedSelectedLookupConfigs && sortedSelectedLookupConfigs.length) {
            const firstConfig = sortedSelectedLookupConfigs[0]
            setSelectedCountrycode(firstConfig.sortIndex)
            setSelectedConfig(firstConfig)
        }
    }, [sortedSelectedLookupConfigs])

    if (!sortedSelectedLookupConfigs || loading) {
        return null
    }

    if (sortedSelectedLookupConfigs.length === 1) {
        return (
            <Image
                src={`/styles/base/images/flags/${sortedSelectedLookupConfigs.first()?.countryCode.toLowerCase()}.png`}
                alt={sortedSelectedLookupConfigs.first()?.countryCode}
                type="flag"
            />
        )
    }

    const renderSelectValue = (sortIndex: number) => {
        const selectedConfig = lookupConfigs?.find((e) => e.sortIndex === sortIndex)

        return (
            <Image
                src={`/styles/base/images/flags/${selectedConfig?.countryCode.toLowerCase()}.png`}
                alt={translateText(selectedConfig?.textId || "")}
                type="flag"
            />
        )
    }

    return (
        <StyledSelect
            size="small"
            variant="outlined"
            onChange={(event) => onChange(event.target.value as number)}
            value={selectedCountrycode}
            renderValue={(e) => renderSelectValue(e as number)}
        >
            {sortedSelectedLookupConfigs.map((config) => (
                <MenuItem key={config.sortIndex} value={config.sortIndex}>
                    <Image
                        src={`/styles/base/images/flags/${config.countryCode.toLowerCase()}.png`}
                        alt={config.countryCode.toLowerCase()}
                        type="flag"
                    />
                    <Typography>{translateText(config.textId)}</Typography>
                </MenuItem>
            ))}
        </StyledSelect>
    )
}
