import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { DateUnit } from "@tm/utils"
import { TableCellData, CellContentPosition, TableColumnData, TableRowData } from "@tm/components"
import { VoucherType } from "@tm/models"
import { useRecoilValue } from "recoil"
import { useParams } from "react-router"
import { ReturnVoucherList } from "../../../../../data/model"
import { VoucherRouteParams } from "../../../../../business"
import { voucherListFilterAtom } from "../../../../../data/states/listFilters"
import { useOpenDetails } from "../../../../../hooks/useOpenDetails"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { StyledTable } from "../../../../_shared/StyledComponents"

type Props = {
    returns: ReturnVoucherList[]
}

export default function CompactReturnsList({ returns }: Props) {
    const { translateText, number, date } = useLocalization()
    const params = useParams<VoucherRouteParams>()
    const listFilters = useRecoilValue(voucherListFilterAtom(params.workTaskId))
    const { openDetails } = useOpenDetails()
    const selectedVoucherId = params.id && decodeURIComponent(params.id)

    const filteredData = useMemo(() => {
        return returns
            .filter(
                (x) =>
                    (x.returnDate.isAfter(listFilters.startDate, DateUnit.Day) || x.returnDate.isSame(listFilters.startDate, DateUnit.Day)) &&
                    (x.returnDate.isBefore(listFilters.endDate, DateUnit.Day) || x.returnDate.isSame(listFilters.endDate, DateUnit.Day))
            )
            .orderBy((x) => x.returnDate, true)
    }, [listFilters, returns])

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(470) }, // voucherNumber
            { header: translateText(98) }, // returnDate day
            { header: `${translateText(479)}`, alignContent: CellContentPosition.center }, // numberOfPositions
            { header: `${translateText(89)}`, alignContent: CellContentPosition.center }, // numberOfParts
        ]

        return columns
    }

    function getCells(returnOrder: ReturnVoucherList): TableCellData[] {
        return [
            { displayValue: returnOrder.voucherNumber, id: "1" },
            { displayValue: date(returnOrder.returnDate, "d"), id: "2" },
            { displayValue: returnOrder.totals?.numberOfPositions ? number(returnOrder.totals.numberOfPositions, 0) : "-", id: "3" },
            { displayValue: returnOrder.totals?.numberOfParts ? number(returnOrder.totals.numberOfParts, 0) : "-", id: "4" },
        ]
    }

    function onRowClick(row: TableRowData) {
        openDetails(row.id, VoucherType.Return)
    }

    const displayData = filteredData.map((returnOrder) => ({
        cells: getCells(returnOrder),
        id: `${returnOrder.returnVoucherId}`,
        customRow: false,
        active: selectedVoucherId === returnOrder.returnVoucherId,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable variant="small" columns={getColumns()} rows={displayData} rowCap={4} onRowClick={onRowClick} />
}
