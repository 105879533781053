import { OE, OePart } from "@tm/models"

export function mapOePart(part: OePart): OE.OePart {
    return {
        description: part.description,
        manufacturerName: part.vehicleManufacturerName,
        manufacturerId: part.vehicleManufacturerId,
        number: part.oeArticleNumber,
        additionalDescription: part.additionalDescription,
        quantity: part.quantityValue,
        traderNumber: part.wholesalerArticleNumber,
        oeArticleOrigin: part.oeArticleOrigin,
        prices: [
            {
                value: part.oePriceValue ?? 0,
                currency: part.currencyCode,
            },
        ],
    }
}
