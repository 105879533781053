import { useEffect, useState } from "react"
import { ArticleSelectionComponent, ArticleSelectionProps } from "../ListV2/components/ArticleSelection"
import { useArticleSelection } from "../ListV2/hooks/useArticleSelection"
import { ErpInfosData } from "../ListV2/models"
import { styled } from "@tm/components"

export const PureArticleSelection = styled(PureArticleSelectionComponent)({
    position: "absolute",
    transform: "transLateY(-50%)",
    margin: 0,
})

function PureArticleSelectionComponent(props: ArticleSelectionProps & { erpInfosData: ErpInfosData }) {
    const { selectedArticles } = useArticleSelection()
    const [show, setShow] = useState(false)

    useEffect(() => {
        setShow(!!selectedArticles.length)
    }, [selectedArticles])

    if (!show) {
        return null
    }

    return <ArticleSelectionComponent {...props} />
}
