import { Availability, AvailabilityStatus, ShipmentMode, Warehouse } from "@tm/models"
import { useLocalization } from "@tm/localization"

type Props = {
    availability: Availability
    warehouses?: Array<Warehouse>
    warehouseTableCurrentItem?: Warehouse
    selectedShipmentMode?: ShipmentMode
}

export function AvailabilityTooltip({ availability, warehouses, warehouseTableCurrentItem, selectedShipmentMode }: Props) {
    const { date, currency, translateText } = useLocalization()
    const stgBestellschluss = ": Mo-Fr 18:00 Uhr"

    switch (availability.type) {
        case AvailabilityStatus.AvailableInCentralWarehouse:
            return (
                <>
                    <div>{translateText(13454)}</div>
                    <div>{translateText(1129) + stgBestellschluss}</div>
                </>
            )
        case AvailabilityStatus.PartiallyAvailable:
            return (
                <>
                    <div>{translateText(13455)}</div>
                    <div>{translateText(1129) + stgBestellschluss}</div>
                </>
            )
        case AvailabilityStatus.NotAvailable:
            return (
                <>
                    <div>{translateText(13456)}</div>
                    <div>{translateText(13457)}</div>
                </>
            )
        case AvailabilityStatus.NightExpress:
            let wh = warehouses?.find((w) => w.nightExpressDelivery)

            if (!wh && warehouseTableCurrentItem?.nightExpressDelivery) {
                wh = warehouseTableCurrentItem
            }

            if (wh) {
                const timeLimit = wh.nightExpressDelivery?.[0]?.tours?.[0]?.tourOrderAcceptanceTimeLimit
                const costs = wh.nightExpressDelivery?.[0]?.costs
                const titleType = availability.isFakeAvailability ? translateText(13275) : `${translateText(13458)} ${wh.name}`

                return (
                    <>
                        <div>
                            {translateText(13459)} {titleType}
                        </div>
                        {timeLimit && (
                            <div>
                                {translateText(1129)} {date(timeLimit, "t")}
                            </div>
                        )}
                        <div>
                            {translateText(3138)}: {costs?.value ? currency(costs.value, costs.currencySymbol) : translateText(13460)}
                        </div>
                    </>
                )
            }
            break
        case AvailabilityStatus.ManufacturerAvailable:
            let teccomWh = warehouses?.find((w) => w.isManufacturerWarehouse)
            if (!teccomWh && warehouseTableCurrentItem?.isManufacturerWarehouse) {
                teccomWh = warehouseTableCurrentItem
            }

            return (
                <>
                    <div>{translateText(13461)}</div>
                    <div>
                        {selectedShipmentMode?.costs?.value
                            ? `${translateText(3138)}: ${currency(selectedShipmentMode?.costs.value, selectedShipmentMode?.costs.currencySymbol)}`
                            : translateText(13138)}
                    </div>
                </>
            )
        default:
            break
    }

    return <>{availability.description || availability.shortDescription}</>
}
