import { useState, useEffect } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Container } from "@tm/nexus"
import { RegisteredModels, CustomerContainer, Customer } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { getCollectiveCustomerParam } from "../../helpers"
import { searchPartner } from "../../data/repositories"
import { getBundleParams } from "../../utils"

function ManagerComponent() {
    const { userContext } = useUser() || {}
    const { collectiveCustomerQueryTypeId } = getBundleParams()
    const { workTask, attachToWorkTask, workTaskLoading, newWorkTaskLoading } = useWorkTask() || {}
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        // dont add check of worktask.statusValue !== WorkTaskStatus.Undefined, it blocks many handlings for Telesales NEXT-26920
        // NEXT-26649 works also without this check!
        if (userContext && workTask && !isLoading && !workTask.customer && !workTaskLoading && !newWorkTaskLoading) {
            const addToWorktask = async (customer: Customer) => {
                if (workTask.id) {
                    await attachToWorkTask?.({ customer: customer.id })
                    setIsLoading(false)
                }
            }

            const loadCustomer = () => {
                const collectiveCustomerNo = getCollectiveCustomerParam(userContext)
                if (collectiveCustomerNo) {
                    // search for next customer with the given number
                    const customerContainer: CustomerContainer = Container.getInstance(RegisteredModels.Customer)
                    customerContainer
                        .action("findCustomer")({ refCustomerNo: collectiveCustomerNo })
                        .then(addToWorktask, async () => {
                            // get cis customer and create a next customer
                            const result = await searchPartner(collectiveCustomerNo, collectiveCustomerQueryTypeId || 1)
                            const cisCustomer = result.first()

                            if (!cisCustomer) {
                                console.log(`No CIS Customer found with number '${collectiveCustomerNo}'`)
                                return
                            }

                            if (collectiveCustomerNo !== cisCustomer.partnerNo) {
                                // If the CIS service returns the customer number in a different format than the one stored in the MDM
                                // we have to check that also this number doesn't exist already. Otherwise we would always create new customers!
                                // (for example see https://jira.dvse.de/browse/NEXT-18860: "000052" vs "52" )
                                try {
                                    const customer = await customerContainer.action("findCustomer")({ refCustomerNo: cisCustomer.partnerNo })
                                    return addToWorktask(customer)
                                } catch {}
                            }

                            const customer: Customer = {
                                id: uniqueId(),
                                companyName: cisCustomer.company,
                                refCustomerNo: cisCustomer.partnerNo,
                                displayCustomerNo: cisCustomer.customerNumberToShow,
                                city: cisCustomer.city,
                                street: cisCustomer.street,
                                zip: cisCustomer.zip,
                            }
                            customerContainer.action("saveCustomer")(customer, true).then(addToWorktask)
                        })
                }
            }

            setIsLoading(true)
            loadCustomer()
        }
    }, [userContext?.id, workTask?.id, workTask?.customer])

    return null
}

export default ManagerComponent
