import { UserSettings } from "@tm/context-distribution"
import { Customer } from "@tm/models"
import { formatDate } from "@tm/utils"
import { useFastServiceStore } from "../../../data"
import { PrintOptions, RepairTime, SelectedItems } from "../../../data/models"
import { Additional, PrintModel, SectionPosition, StepsNavigation, WheelItem, WorkTime } from "../../../models"
import { WheelsAndTyresTab } from "../../../data/state/tyres-wheels"
import { getStepNameByLocation, getTranslationByKey, mapSafetyStatusPrint } from "../../../helpers"

export function createPrintRequest(
    translateText: (key: string | number) => string,
    sectionItems: Map<string, Record<string, SelectedItems>>,
    printOptions: PrintOptions,
    userSettings?: UserSettings,
    customer?: Customer
): PrintModel {
    const {
        tyresWheels,
        tester: qualityController,
        technician,
        inspectionDate,
        inspectionKmValue,
        maintenancePlan: { vehicleFields, selections, selectedService, workTimes },
        worksState: { works },
        stepNavigationState: { steps },
    } = useFastServiceStore.getState()

    const tiresWorks = works["Tires wheels"]

    const firstRegistration = vehicleFields.initialRegistration ?? new Date()
    const { repairShop } = userSettings ?? {}
    const { inspectionWorkTimes, additionalWorkTimes, followUpWorkTimes } = workTimes
    const totals = [...inspectionWorkTimes, ...additionalWorkTimes, ...followUpWorkTimes]

    let nextInspection = ""
    if (inspectionDate) {
        nextInspection += `${formatDate(inspectionDate, "d")} `
    }

    if (inspectionKmValue) {
        nextInspection += `${inspectionKmValue} `
    }

    return {
        appLogo: "https://tm2.carparts-cat.com/styles/base/images/logo-fast-service.svg",
        companyData: {
            companyLogo: repairShop?.logo ?? "",
            name: repairShop?.companyName ?? "",
            street: repairShop?.postalAddress?.street ?? "",
            postalCode: repairShop?.postalAddress?.zip ?? "",
            city: repairShop?.postalAddress?.city ?? "",
            telephone: repairShop?.contactInfo?.phone,
            webSite: repairShop?.contactInfo?.webPage ?? "",
            email: repairShop?.contactInfo?.email ?? "",
        },
        customerData: {
            company: customer?.companyName ?? "",
            fullName: `${customer?.firstName ?? ""} ${customer?.lastName ?? ""}`,
            street: customer?.street ?? "",
            postalCode: customer?.zip ?? "",
            city: customer?.city ?? "",
            telephone: customer?.phone ?? "",
        },
        vehicleData: {
            firstRegistration: (!!firstRegistration && formatDate(firstRegistration, "d")) || "",
            firstRegistrationLabel: translateText(124),
            licencePlate: vehicleFields.plateNumber ?? "",
            licencePlateImage: "https://tm2.carparts-cat.com/styles/default/images/eu-stars.svg",
            mileage: vehicleFields.mileage?.toString() ?? "",
            mileageLabel: translateText(125),
            motorcode: vehicleFields.engineCode ?? "",
            motorcodeLabel: translateText(105),
            vehicleType: selections?.find((x) => x.id === "haynesProCarType")?.items.find((y) => y.isSelected)?.label ?? "",
            vin: vehicleFields.vin ?? "",
            vinLabel: translateText(101),
        },
        ...(printOptions.inspectionTimes
            ? {
                  maintenanceSchedule: {
                      maintenanceSystem: selections?.find((x) => x.id === "haynesProMaintenanceSystem")?.items.find((y) => y.isSelected)?.label ?? "",
                      title: translateText(13237),
                      interval: selectedService.map((x) => x.label),
                      workTime: workTimesMapper<WorkTime>(inspectionWorkTimes),
                      additional: workTimesMapper<Additional>(additionalWorkTimes),
                      followUps: workTimesMapper<Additional>(followUpWorkTimes),
                      total: {
                          title: translateText(13145),
                          value: totals.reduce((accumulator, currentValue) => accumulator + (currentValue.quantity ?? 0), 0).toFixed(2),
                      },
                  },
              }
            : {
                  maintenanceSchedule: {
                      maintenanceSystem: selections?.find((x) => x.id === "haynesProMaintenanceSystem")?.items.find((y) => y.isSelected)?.label ?? "",
                      interval: selectedService.map((x) => x.label),
                  },
              }),
        ...(printOptions.tyres && {
            wheels: {
                headerIconType: "3",
                order: steps.findIndex((step) => step.stepName === "tiresWheels"),
                header: translateText(13062),
                items: wheelItemMapper(tyresWheels.tabs, translateText, tyresWheels.showBonus),
                ...(tiresWorks && {
                    positions: Object.entries(tiresWorks).map(([value, data]) =>
                        mapPositionDetails(data.label, { selectedStatusButton: "checkButton" }, printOptions, translateText)
                    ),
                }),
            },
        }),
        sections: sectionsMapper(sectionItems, printOptions, translateText, steps),
        legend: {
            title: translateText(13225),
            positions: [
                {
                    text: translateText(13233),
                    iconType: "1",
                },
                {
                    text: translateText(13234),
                    iconType: "2",
                },
                {
                    text: translateText(13235),
                    iconType: "3",
                },
                {
                    text: translateText(13226),
                    iconType: "4",
                },
                {
                    text: translateText(13236),
                    iconType: "5",
                },
                {
                    text: translateText(13227),
                    iconType: "6",
                },
            ],
        },

        remarks: {
            nextLabel: translateText(897),
            nextInspection: (printOptions.nextMaintenance && nextInspection) || "",
            nextInspectionLabel: translateText(574),
            remarkLabel: translateText(1700),
            technicianRemark: (printOptions.remarks && technician.comment) || "",
            qualityControllerRemark: (printOptions.remarks && qualityController.comment) || "",
        },
        signature: {
            date: formatDate(new Date(), "d"),
            dateLabel: translateText(98),
            technicianSignatureImage: technician.signature ?? "",
            technician: `${technician.firstName} ${technician.lastName}`,
            technicianLabel: translateText(12664),
            stampLabel: translateText(13154),
            stampImage: "",
            qualityControllerLabel: translateText(13153),
            qualityController: `${qualityController.firstName} ${qualityController.lastName}`,
            qualityControllerSignatureImage: qualityController.signature ?? "",
        },
    }
}

const wheelItemMapper = (wheelItems: WheelsAndTyresTab[], translateText: (key: string | number) => string, showBonus?: boolean): WheelItem[] => {
    const items = wheelItems.map((x) => ({
        title: translateText(x.translation),
        positions: [
            {
                title: translateText(13147),
                value: x.selectedFilters.manufacturer,
            },
            {
                title: translateText(13077),
                value: x.selectedFilters.tyreModel,
            },
            {
                title: translateText(13230),
                value: `${x.selectedFilters.size} ${x.selectedFilters.loadIndex}${x.selectedFilters.speedIndex}`,
            },
            {
                title: translateText(13148),
                value: x.collapsibleItems.find((x) => x.name === "tyreDot")?.infoData.profileDepth?.toString() || "",
            },
            {
                title: translateText(12497),
                value: x.collapsibleItems.find((x) => x.name === "tyrePressure")?.infoData.tyrePressure?.toString() || "",
            },
        ],
    }))
    return [
        ...items,
        ...((showBonus && [
            { title: translateText(13149), positions: [{ title: translateText(13147), value: wheelItems[0].selectedFilters.manufacturer }] },
        ]) ||
            []),
    ]
}

const sectionsMapper = (
    itemsMap: Map<string, Record<string, SelectedItems>>,
    printOptions: PrintOptions,
    translateText: (key: string | number) => string,
    steps: StepsNavigation[]
): PrintModel["sections"] => {
    return Array.from(itemsMap, ([key, items]) => ({
        headerIconType: iconTypeWorkMapper(+key || 0),
        header: translateText(key),
        order: steps.findIndex((step) => step.stepName === getStepNameByLocation(getTranslationByKey(key))),
        title: "",
        positions: Object.entries(items).map(([value, data]) => mapPositionDetails(value, data, printOptions, translateText)),
    }))
}

const mapPositionDetails = (
    key: string,
    item: SelectedItems,
    printOptions: PrintOptions,
    translateText: (key: string | number) => string
): SectionPosition => {
    const genArts = item.selectedGenArts?.filter((x) => x.fsArticle?.supplierId)
    let response

    response = {
        text: key,
        iconType: iconTypeMapper(item.selectedStatusButton),
    }

    if (item.selectedStatusButton !== "checkButton" && item.selectedStatusButton !== "repairedButton" && printOptions.showDefects) {
        response = {
            ...response,
            details: {
                ...(printOptions.images && {
                    images: item.selectedImages?.map((x) => x.imageUrl || ""),
                }),
                comments: item.comment,
                description: translateText(617),
                safetyInfo: mapSafetyStatusPrint(item.safetyStatus, translateText),
            },
        }
    }

    if (genArts?.length) {
        response = {
            ...response,
            details: {
                ...response.details,
                productGroups: genArts.map((x) => ({ text: x.label, iconType: "4" })),
                productGroupsTitle: translateText(1667),
            },
        }
    }

    return response
}

const iconTypeMapper = (statusButton: string): string => {
    switch (statusButton) {
        case "checkButton":
            return "1"
        case "repairedButton":
            return "3"
        case "defectButton":
            return "2"
        default:
            return "0"
    }
}

const iconTypeWorkMapper = (translationId: number): string => {
    switch (translationId) {
        case 12665:
            return "4"
        case 12668:
            return "5"
        case 12666:
            return "6"
        case 12667:
            return "7"
        case 380:
            return "8"
        case 12674:
            return "9"
        case 12673:
            return "10"
        default:
            return "0"
    }
}

const linkMapper = (statusType: number): string => {
    switch (statusType) {
        case 1:
            return "https://statics.dvse.de/imageData/theme/-1/FastService/Info.png"
        case 2:
            return "https://statics.dvse.de/imageData/theme/-1/FastService/Warning.png"
        case 3:
            return "https://statics.dvse.de/imageData/theme/-1/FastService/NoEntry.png"
        default:
            return "0"
    }
}

function workTimesMapper<T extends WorkTime | Additional>(workTimes: RepairTime[]): T[] {
    return workTimes.map(
        (r) =>
            ({
                value: r.quantity || 0,
                title: r.label,
                iconType: r.iconType,
            } as unknown as T)
    ) // TODO - needs a better solution
}
