import { useLocalization } from "@tm/localization"
import { Divider } from "@tm/components"
import { SelectedPriceVatMode } from "@tm/models"
import { B2BTotals } from "@tm/data/vouchers/costEstimations"
import { PartsAndWorks } from "./PartsAndWorks"
import Total from "../../Total"
import { TotalStack } from "../../../../../../../../../_shared/StyledComponents"

type Props = {
    currencyCode: string
    totals?: B2BTotals
}

export function B2BNet({ totals, currencyCode }: Props) {
    const { translateText, currency } = useLocalization()

    if (!totals) {
        return null
    }

    return (
        <TotalStack>
            <PartsAndWorks currencyCode={currencyCode} totals={totals} priceVatMode={SelectedPriceVatMode.Net} />
            <Divider />
            <Total label={translateText(650)} value={currency(totals?.offerPrice || 0, currencyCode)} labelVariant="h3" valueVariant="h3" />
            <Divider />
            {totals?.alreadyTaxedTotalCosts && (
                <Total
                    label={translateText(12828)}
                    value={currency(totals.alreadyTaxedTotalCosts, currencyCode)}
                    labelVariant="body2"
                    valueVariant="body2"
                />
            )}
        </TotalStack>
    )
}
