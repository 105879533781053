import { Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { equals } from "@tm/utils"
import { useSelector } from "react-redux"
import { CarInfo } from "../../../../data/models"
import { vehicleModelsSelector } from "../../../main/business"

type Props = {
    models: CarInfo[]
    onClickRow(model: CarInfo): void
    onDoubleClickRow(model: CarInfo): void
    selectionDisabled?: boolean
}

export default function VehicleList({ models, selectionDisabled, onClickRow, onDoubleClickRow }: Props) {
    const { translateText } = useLocalization()
    const { selectedVehicleModel } = useSelector(vehicleModelsSelector)

    const renderDescription = (carInfo: CarInfo) => <Table.Cell>{carInfo.description}</Table.Cell>
    const renderBodyType = (carInfo: CarInfo) => <Table.Cell>{carInfo.bodyType + (carInfo.doorsNr ? `/${carInfo.doorsNr}` : "")}</Table.Cell>
    const renderEquipment = (carInfo: CarInfo) => <Table.Cell>{carInfo.equipment}</Table.Cell>
    const renderConstruction = (carInfo: CarInfo) => (
        <Table.Cell>
            {carInfo.constructionYearFrom && `${carInfo.constructionYearFrom.month}.${carInfo.constructionYearFrom.year}`} -
            {carInfo.constructionYearTo && `${carInfo.constructionYearTo.month}.${carInfo.constructionYearTo.year}`}
        </Table.Cell>
    )
    const renderEngine = (carInfo: CarInfo) => <Table.Cell>{carInfo.natCode}</Table.Cell>

    const tableProps = {
        columns: [
            <Table.Column key="model" className="model" renderItemContent={renderDescription}>
                {translateText(610)}
            </Table.Column>,
            <Table.Column key="body-type" className="body-type" renderItemContent={renderBodyType}>
                {translateText(303)}
            </Table.Column>,
            <Table.Column key="equipment" className="equipment" renderItemContent={renderEquipment}>
                {translateText(1484)}
            </Table.Column>,
            <Table.Column key="construction" className="construction" renderItemContent={renderConstruction}>
                {translateText(1480)}
            </Table.Column>,
            <Table.Column key="engine" className="engine" renderItemContent={renderEngine}>
                ETG-Code
            </Table.Column>,
        ],
        getRowClassName: (carInfo: CarInfo) => (equals(selectedVehicleModel, carInfo) ? "is-selected" : ""),
        data: models,
        ...(!selectionDisabled && {
            onClickRow,
            onDoubleClickRow,
        }),
    }

    return <Table {...tableProps} />
}
