import { SupplierArticleQuantities, SupplierArticleQuantitiesRequest } from "@tm/models"
import { ajax, createBufferedRequestFunction, getStoredAuthorization, notUndefinedOrNull } from "@tm/utils"
import { getBasketServiceUrl } from "../../.."

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Order`
}

export const getSupplierArticleQuantities = createBufferedRequestFunction({
    callService: getShowQuantitiesBySupplierArticle,
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return (
            serviceResponse.find(
                (item) =>
                    item.article.dataSupplierId === request.article.dataSupplierId &&
                    item.article.dataSupplierArticleNumber === request.article.dataSupplierArticleNumber &&
                    item.article.productGroupId === request.article.productGroupId
            ) || {
                article: request.article,
                isInOrder: false,
            }
        )
    },
})

export function getShowQuantitiesBySupplierArticle(requests: Array<SupplierArticleQuantitiesRequest>) {
    const url = `${getServiceUrl()}/ShowQuantitiesBySupplierArticle`
    const authorization = getStoredAuthorization()
    const body = {
        workTaskId: requests[requests.length - 1].workTaskId,
        articles: requests.map((r) => r.article),
    }

    return ajax<{ supplierArticleQuantities?: Array<SupplierArticleQuantities> }>({ url, body, authorization, method: "POST" }).then(
        (data) => data?.supplierArticleQuantities?.filter(notUndefinedOrNull) || []
    )
}
