import {
    Button,
    Box,
    DataGrid,
    GridColDef,
    GridFooter,
    GridFooterContainer,
    GridRenderCellParams,
    GridRowId,
    GridRowParams,
    GridValueGetterParams,
} from "@tm/components"
import { useState } from "react"
import { LicensePlateField } from "@tm/controls"

import { useLocalization } from "@tm/localization"
import { NotificationMessage } from "../../data/model/notificationMessage"
import { NotificationMessageType } from "../../data/model/notificationMessageType"
import { NotificationMessageStatus } from "../../data/model/notificationMessageStatus"

export interface INotificationsTableProps {
    notificationMessages: NotificationMessage[]
    setNotificationsRead(notificationMessageIds: string[]): Promise<void>
}

export default function NotificationsTable(props: INotificationsTableProps) {
    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>(new Array<GridRowId>())
    const { translateText } = useLocalization()

    const columns: GridColDef[] = [
        { field: "customerName", headerName: translateText(155), flex: 2 },
        { field: "title", headerName: translateText(76), flex: 3, sortable: false },
        { field: "notificationMessageType", headerName: translateText(74), valueGetter: getMessageTypeValue, flex: 1 },
        { field: "notificationMessageStatus", headerName: translateText(212), valueGetter: getMessageStatusValue, flex: 1 },
        { field: "date", headerName: translateText(98), valueGetter: getDateValue, flex: 2 },
        { field: "sum", headerName: translateText(57), valueGetter: getSumValue, flex: 1, sortable: false },
        { field: "carType", headerName: translateText(99), flex: 1, sortable: false, disableColumnMenu: true },
        { field: "licensePlate", headerName: "", renderCell: renderLicensePlateCell, flex: 2, sortable: false, disableColumnMenu: true },
        { field: "other", headerName: "", flex: 2, renderCell: renderOtherCell, align: "right", disableColumnMenu: true },
    ]

    function getMessageTypeValue(params: GridValueGetterParams) {
        const notificationMessage = params.row
        if (notificationMessage !== undefined) {
            return NotificationMessageType[notificationMessage.notificationMessageType]
        }
        return ""
    }

    function getMessageStatusValue(params: GridValueGetterParams) {
        const notificationMessage = params.row
        if (notificationMessage !== undefined) {
            return NotificationMessageStatus[notificationMessage.notificationMessageStatus]
        }
        return ""
    }

    function getDateValue(params: GridValueGetterParams) {
        const notificationMessage = params.row
        if (notificationMessage === undefined) {
            return ""
        }
        const date = new Date(notificationMessage.date)
        return `${date.toLocaleDateString()}, ${date.toLocaleTimeString()}`
    }

    function getSumValue(params: GridValueGetterParams) {
        const notificationMessage = params.row
        if (notificationMessage === undefined || notificationMessage.sum === undefined || !notificationMessage.sum) {
            return ""
        }
        return `${notificationMessage.sum} €`
    }

    function renderLicensePlateCell(params: GridRenderCellParams) {
        if (params === undefined || !params.value) {
            return null
        }

        return <LicensePlateField readonly shortCountryCode="DE" value={params.value} />
    }

    function renderOtherCell() {
        return (
            <Button disabled variant="outlined">
                {translateText(43)}
            </Button>
        )
    }

    async function setNotificationsRead() {
        const ids = rowSelectionModel.map((x: GridRowId) => x.toString())
        if (ids.length > 0) {
            setRowSelectionModel(new Array<GridRowId>())
            await props.setNotificationsRead(ids)
        }
    }

    function CustomFooter() {
        return (
            <GridFooterContainer>
                <Button
                    disabled={rowSelectionModel.length === 0}
                    variant="contained"
                    color="primary"
                    onClick={setNotificationsRead}
                    sx={{ margin: 2 }}
                >
                    {translateText(13486)}
                </Button>
                <GridFooter />
            </GridFooterContainer>
        )
    }

    return (
        <Box sx={{ height: "auto" }}>
            <DataGrid
                autoHeight
                components={{
                    Footer: CustomFooter,
                }}
                hideFooterSelectedRowCount
                onSelectionModelChange={(newRowSelectionModel: GridRowId[]) => {
                    setRowSelectionModel(newRowSelectionModel)
                }}
                getRowId={(row) => row.notificationMessageId}
                rows={props.notificationMessages}
                columns={columns}
                rowsPerPageOptions={[10]}
                initialState={{
                    pagination: {
                        pageSize: 10,
                    },
                }}
                checkboxSelection
                isRowSelectable={(params: GridRowParams) => !params.row.isRead}
            />
        </Box>
    )
}
