import { useShowCisOptions, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { CisVoucherType } from "@tm/models"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { ShowComplaintsRequest } from "../model"
import * as Data from "../repositories/cis/vouchers"

function showComplaints(customerNo: string | undefined) {
    const showComplaintsRequest: ShowComplaintsRequest = {
        pageIndex: 1,
        pageSize: 5,
        customerNo,
    }

    return Data.showComplaints(showComplaintsRequest)
}

const KEY = "cis_useComplaintsRecent"
const VOUCHER_TYPE = CisVoucherType.Complaints
export function useComplaintsRecent() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { cisOptions } = useShowCisOptions()

    const voucherTypeEnabled = useMemo(() => {
        return !!cisOptions?.voucherTypes?.some((type) => type.typeId === VOUCHER_TYPE)
    }, [cisOptions])

    const enabled = voucherTypeEnabled && enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => {
            return showComplaints(telesalesCustomerNo)
        },
        {
            enabled,
        }
    )

    return {
        complaints: data?.complaints,
        complaintsLoading: isLoading,
    }
}
