export * from "./navigation"
export * from "./calculation"
export * from "./vehicle"
export * from "./history"

export type HourlyRates = {
    ElectronicWork: number
    ElectricalWork: number
    BodyWork: number
    PaintWork: number
    WorkshopWork: number // GarageWork
    AccessoryWork: number
    SaddlerWork: number
    SmartRepair: number
    EcoRepairPercent: number
    VatLabour: number
    VatMaterial: number
}

export enum ESubModules {
    ShowEREClick = 10,
    ShowEREFlatrate = 11,
}
export enum EModuleParameters {
    ModuleCosts = "471",
}
