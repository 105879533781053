import { Typography, Box, styled, Tip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useState } from "react"
import { isTouchDevice } from "../../../../../helpers/isTouchDevice"
import { DropDown } from "./dropDown"
import { tyresWheelsActions, useFastServiceStore } from "../../../../../data"
import { TyreSpecification } from "../../../../../data/models"
import { ExtendEFilterNames, SelectedFilters } from "../../../../../data/state/tyres-wheels"
import { extractSpecificationValues } from "../../../../../helpers/extractSpecificationValues"
import { loadTiresCrits } from "../../../../tyres-wheels/business"
import { ExtraInformation } from "./extraInformation"
import { getFilteredTyreSpecifications } from "../../../../../helpers/getFilteredTyreSpecifications"
import { enableFreeInputMode } from "../../../../../helpers/enableFreeInputMode"
import { TyresCritsResponse } from "../../../../../data/repositories"
import { compareTyreFilters } from "../../../../../helpers/compareTyreFilters"

type Props = {
    selectedFilters: SelectedFilters
    setSelectedFiltersTemp: (filters?: SelectedFilters) => void
    tyresCrits: Partial<TyresCritsResponse>
    selectedTabName: string
    freeInputMode: boolean
    setFreeInputMode: (freeMode: boolean) => void
    loading: boolean
    setTyreSpecValues: (tyreSpecValues: string[]) => void
}

export function TireSpecifications(props: Props) {
    const { selectedFilters, setSelectedFiltersTemp, tyresCrits, selectedTabName, freeInputMode, setFreeInputMode, loading, setTyreSpecValues } =
        props
    const { width, height, inch, loadIndex, speedIndex, size, axleSpecs } = selectedFilters
    const { translateText } = useLocalization()
    const tireSpecifications = useFastServiceStore((state) => state.maintenancePlan.tireSpecifications)

    const [selectedTireSpecification, setSelectedTireSpecification] = useState<TyreSpecification | undefined>(
        tireSpecifications?.length === 1 ? tireSpecifications[0] : undefined
    )

    const touchDevice = isTouchDevice()

    const mainSizesSelected = useMemo(() => !!(width && height && inch && loadIndex && speedIndex), [width, height, inch, loadIndex, speedIndex])
    const mainSizeText = useMemo(() => (mainSizesSelected ? `${width}/${height} R${inch} ${loadIndex}${speedIndex}` : ""), [mainSizesSelected])
    const sizeText = useMemo(() => (size !== "" && mainSizesSelected ? size : mainSizeText), [size, mainSizesSelected])

    const tireSpecificationsLabels = useMemo(() => {
        const labels: string[] = []

        tireSpecifications?.forEach((specification) => {
            if (specification.label) {
                labels.push(specification.label)
            }
        })

        return labels
    }, [tireSpecifications])

    const tireSpecificationsValues = useMemo(
        () => getFilteredTyreSpecifications(selectedTireSpecification, selectedTabName, selectedFilters, mainSizesSelected),
        [selectedTireSpecification, mainSizesSelected]
    )

    const sizeSpecFocus = useMemo(() => {
        if (tireSpecificationsValues.some((value) => value === size)) {
            return false
        }

        return mainSizesSelected && !!selectedTireSpecification?.label && (tireSpecificationsValues.length > 1 || !size) && !loading
    }, [mainSizesSelected, selectedTireSpecification, tireSpecificationsValues, size, loading])

    useEffect(() => {
        if (tireSpecifications?.length === 1) {
            setSelectedTireSpecification(tireSpecifications[0])
        }

        if (tireSpecificationsValues.length === 1) {
            handleSpecValueSelection(tireSpecificationsValues[0])
        }

        if (tireSpecificationsValues.length > 1) {
            setTyreSpecValues(tireSpecificationsValues)
        }
    }, [tireSpecifications, tireSpecificationsValues])

    const handleSpecValueSelection = (filter: string) => {
        if (selectedTireSpecification) {
            const newSelectedFilters: SelectedFilters = extractSpecificationValues(selectedTireSpecification, selectedFilters, filter)
            setSelectedFiltersTemp(newSelectedFilters)
            newSelectedFilters.axleSpecs && tyresWheelsActions.setTyreSpecification(newSelectedFilters.axleSpecs)
            compareTyreFilters(selectedFilters, newSelectedFilters) && loadTiresCrits(newSelectedFilters)
        }
    }

    const handleFilterSelect = (path: string, filter: string) => {
        if (!enableFreeInputMode(tyresCrits, path, filter)) {
            setFreeInputMode(true)
        }

        if (path === ExtendEFilterNames.vehicleLabel) {
            const newSelectedFilters: SelectedFilters = {
                ...selectedFilters,
                vehicleLabel: filter,
            }
            setSelectedTireSpecification(tireSpecifications?.find((specification) => specification.label === filter))
            setSelectedFiltersTemp(newSelectedFilters)
        }

        if (path === ExtendEFilterNames.size) {
            handleSpecValueSelection(filter)
        }
    }

    if (!tireSpecifications) {
        return null
    }

    return (
        <>
            <StyledBox>
                <Typography flexBasis="20%" minWidth="fit-content">
                    {translateText(13702)}
                </Typography>
                <Tip text={translateText(13701)} enableTipOnTouchDevice={touchDevice} />
            </StyledBox>
            <StyledBox>
                <Box sx={{ width: "100%" }}>
                    {tireSpecifications.length > 0 && (
                        <StyledBox>
                            <DropDown
                                name={translateText(12838)}
                                path={ExtendEFilterNames.vehicleLabel}
                                items={tireSpecificationsLabels}
                                onChange={handleFilterSelect}
                                selectedValue={selectedTireSpecification?.label ?? ""}
                                disableDropDown={freeInputMode ? false : loading}
                                focusInput={mainSizesSelected && !selectedTireSpecification?.label && !loading}
                            />
                        </StyledBox>
                    )}
                    <StyledBox>
                        <DropDown
                            name={translateText(712)}
                            path={ExtendEFilterNames.size}
                            items={tireSpecificationsValues}
                            onChange={handleFilterSelect}
                            selectedValue={sizeText}
                            disableDropDown={freeInputMode || !selectedTireSpecification ? false : loading}
                            focusInput={sizeSpecFocus}
                        />
                    </StyledBox>
                </Box>
                {size && axleSpecs && (
                    <ExtraInformation
                        selectedSize={axleSpecs?.frontAxle?.size ?? ""}
                        wheelSize={axleSpecs?.frontAxle?.wheelSize}
                        wheelOffset={axleSpecs?.frontAxle?.wheelOffset}
                    />
                )}
            </StyledBox>
        </>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    gap: ".5em",
    alignItems: "center",
    display: "flex",
    marginBottom: theme.spacing(1),
}))
