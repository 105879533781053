import { Stack, Typography } from "@tm/components"

type Props = {
    label: string
    value: number | string | undefined
    alignValueRight?: boolean
}

export function ValueLabelStack({ label, value, alignValueRight }: Props) {
    return (
        <Stack>
            <Typography whiteSpace="nowrap" variant="label">
                {label}
            </Typography>
            <Typography variant="body2" alignSelf={alignValueRight ? "flex-end" : "center"}>
                {value}
            </Typography>
        </Stack>
    )
}
