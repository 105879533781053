import { channel } from "@tm/models"
import Morpheus, { IPlugin, ModuleConfig, PluginProps } from "@tm/morpheus"
import { Location } from "history"

const plugin: IPlugin<ModuleConfig> = {
    name: "ForceWorktaskAfterLogin",
    type: "MODULE/MOUNT",
    plugin: ForceWorktaskAfterLogin,
}

export default plugin

type ForceWorktaskAfterLoginProps = PluginProps<ModuleConfig<{ forceWorktaskAfterLogin: boolean }>>

let unregister: any

function ForceWorktaskAfterLogin(props: ForceWorktaskAfterLoginProps) {
    if (!props.module.props?.forceWorktaskAfterLogin || unregister) {
        return
    }

    const history = Morpheus.getHistory()

    function listener(location: Location) {
        const hasFragment = location.pathname.length > 1
        const {
            history: his,
            current: { search },
        } = window.portalLocations
        if (!hasFragment && window.portalLocations.history.length === 0) {
            channel("GLOBAL").publish("WORKTASK/OPEN_NEW", { openExisting: true })
        }
    }

    unregister = history.listen(listener)
    listener(history.location)
}
