import { useEffect, useState } from "react"
import { Article, RegisteredModels, FittingGroupResponse, FittingGroupRequest } from "@tm/models"
import { Container } from "@tm/nexus"

export function useHasRepairTimesForProductGroup(workTaskId: string | undefined, article: Article) {
    const [fittingGroup, setFittingGroup] = useState<FittingGroupResponse>()

    const request: FittingGroupRequest | undefined = article.productGroup
        ? {
              fittingSide: article.fittingSide,
              productGroupId: article.productGroup.id,
          }
        : undefined

    useEffect(() => {
        if (!workTaskId || !request) {
            setFittingGroup(undefined)
            return
        }

        let unsub: (() => void) | undefined

        // TODO: Check if required anymore - Timeout to fix NEXT-12659. If the articlelist ever gets refactored, this can probably be removed
        const timeout = setTimeout(() => {
            // TODO: create and use container action
            const subscription = Container.getInstance<FittingGroupResponse>(RegisteredModels.Basket_HasRepairTimesForProductGroup).subscribe({
                workTaskId,
                request,
            })

            unsub = subscription.addListener("loaded", (response) => {
                setFittingGroup(response)
            })

            subscription.load()
        }, 0)

        return () => {
            clearTimeout(timeout)
            unsub?.()
        }
    }, [workTaskId, request?.fittingSide, request?.productGroupId])

    return { fittingGroup }
}
