import { SupplierInfo } from "../../../../data/model"
import { SupplierRequestForDocuments } from "../model"

export function mapSupplierForDocumentsRequest(supplierInfo?: SupplierInfo): SupplierRequestForDocuments {
    if (supplierInfo) {
        let supplierAdress = ""
        if (supplierInfo.description) {
            supplierAdress += `${supplierInfo.description}\n`
        }
        if (supplierInfo.street) {
            supplierAdress += `${supplierInfo.street}\n`
        }
        if (supplierInfo.zip) {
            supplierAdress += `${supplierInfo.zip} `
        }
        if (supplierInfo.city) {
            supplierAdress += supplierInfo.city
        }

        return {
            city: "",
            country: "",
            zip: "",
            street: "",
            state: "",
            companyName: "",
            postalAddress: supplierAdress,
            administrator: "",
        }
    }
    return {
        city: "",
        country: "",
        zip: "",
        street: "",
        state: "",
        companyName: "",
        postalAddress: "",
        administrator: "",
    }
}
