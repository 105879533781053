import { Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { Box, Icon, IconButton, Tooltip } from "@tm/components"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-pawn"]

function ErpInfoPawnComponent(props: Props) {
    const { translateText } = useLocalization()
    const { loading, erpInfo } = useErpInfo(props.data, "list")

    if (loading || !erpInfo?.hasPawnArticles) {
        return null
    }

    return (
        <Tooltip title={translateText(1995)}>
            <IconButton onClick={props.onClick}>
                <Icon name="productgroup" />
            </IconButton>
        </Tooltip>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <Box className="tk-erp">
                <Box className="erp-info-pawn" sx={{ backgroundColor: "yellow" }}>
                    <Suspense fallback={null}>
                        <ErpInfoPawnComponent {...props} />
                    </Suspense>
                </Box>
            </Box>
        </ErpWrapper>
    )
}
