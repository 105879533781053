import { ErpInformation, channel, ErpInformationRequestItem } from "@tm/models"

type CacheEntry = {
    expires: Date
    value: ErpInformation
}

const cache: Map<string, CacheEntry> = new Map() // globale cache for erp informations

channel("GLOBAL").subscribe("ERP/CLEAR_CACHE", () => {
    clearCache(false)
})

export function getValidCacheItems(
    distributorId: number,
    telesalesCustomerNo: string | undefined,
    requests: ErpInformationRequestItem[]
): ErpInformation[] {
    clearCache()

    const cacheEntries: ErpInformation[] = []

    requests.forEach((x) => {
        const entry = getCacheEntry(distributorId, telesalesCustomerNo, x)

        if (entry) {
            cacheEntries.push({ ...entry.value, itemId: x.itemId })
        }
    })

    return cacheEntries
}

function getCacheEntry(distributorId: number, telesalesCustomerNo: string | undefined, item: ErpInformationRequestItem) {
    return cache.get(generateCacheKey(distributorId, telesalesCustomerNo, item))
}

export function storeCacheEntry(
    distributorId: number,
    telesalesCustomerNo: string | undefined,
    item: ErpInformationRequestItem,
    response: ErpInformation,
    cachingDurationInSeconds: number
) {
    const expiryDate = new Date()
    expiryDate.setSeconds(expiryDate.getSeconds() + cachingDurationInSeconds)

    cache.set(generateCacheKey(distributorId, telesalesCustomerNo, item), {
        expires: expiryDate,
        value: response,
    })
}

function generateCacheKey(distributorId: number, telesalesCustomerNo: string | undefined, item: ErpInformationRequestItem): string {
    return [
        distributorId,
        telesalesCustomerNo,
        item.dataSupplierArticleNumber,
        item.dataSupplierId,
        item.dataSupplierName,
        item.productGroupId,
        item.wholesalerArticleNumber,
        item.quantityValue,
    ].join("_")
}

function clearCache(onlyExpired = true) {
    if (onlyExpired) {
        const dateNow = new Date()

        cache.forEach((value, key) => {
            if (value.expires < dateNow) {
                cache.delete(key)
            }
        })
    } else {
        cache.clear()
    }
}
