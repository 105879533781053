import { useCallback, useEffect, useMemo, useState } from "react"

import { WorkTaskInfo } from "@tm/context-distribution"
import { Alert, Box, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { notUndefinedOrNull, useSelection } from "@tm/utils"
import { WorkCategory } from "@tm/models"
import { mapCustomRepairTimeItemToCustomWork } from "../../../../data/mapper"
import { CustomItemSearch } from "../CustomItemSearch"
import { CustomItemSelection } from "../CustomItemSelection"
import { useDeleteCustomRepairTimes, useShowCustomRepairTimeItems } from "../../../../data/hooks/custom-basket-items/useCustomRepairTimes"

import { CustomRepairTimesList } from "./components/CustomRepairTimesList"
import { CustomItemHeader } from "../CustomItemHeader"
import { CustomRepairTimeItem } from "../../../../data/model/custom-items"
import { VatRateSelectionItem } from "../../../../data/model"
import { useWorkTaskBasketState } from "../../../../hooks/basketState/useWorkTaskBasketState"

type Props = {
    workTask: WorkTaskInfo
    hourlyRates: Record<WorkCategory, number>
    vatRates?: VatRateSelectionItem[]
    currencySymbol: string
    repairTimeDivision: number
    showRepairTimesInHours: boolean
}

export function CustomRepairTimesComponent({ workTask, currencySymbol, repairTimeDivision, showRepairTimesInHours, hourlyRates, vatRates }: Props) {
    const { translateText } = useLocalization()
    const { costEstimation } = useWorkTaskBasketState(workTask.id)
    const { actions, state } = costEstimation
    const { costEstimationOptions } = state.costEstimation ?? {}

    const [request, setRequest] = useState<Parameters<typeof useShowCustomRepairTimeItems>[0]>({
        repairTimeDivision,
        priceVatMode: costEstimationOptions?.priceVatMode,
    })
    const { data: { pages } = {}, isFetchingNextPage, isLoading, isError, fetchNextPage, remove: resetCache } = useShowCustomRepairTimeItems(request)
    const customRepairTimes = useMemo(() => pages?.flatMap((x) => x.data) ?? [], [pages])

    const { mutateAsync: deleteRepairTime } = useDeleteCustomRepairTimes()

    const getAllIds = useCallback(() => customRepairTimes.map((x) => x.id).filter((x) => x), [customRepairTimes])
    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllIds)

    // Reset query cache on unmount so on next component mount not all pages will be fetched again at once
    useEffect(() => {
        return () => {
            resetCache()
        }
    }, [resetCache])

    const handleToggleRepairTime = useCallback(
        (repairTime: CustomRepairTimeItem) => {
            toggleSelected(repairTime.id)
        },
        [toggleSelected]
    )

    const handleAddToBasket = useCallback(
        async (ids: Array<string>) => {
            const customWorks = ids
                .map((id) => {
                    const repairTime = customRepairTimes.find((a) => a.id === id)
                    return repairTime ? mapCustomRepairTimeItemToCustomWork(repairTime) : undefined
                })
                .filter(notUndefinedOrNull)

            if (customWorks.length) {
                await actions.addCustomWorks({
                    workTaskId: workTask.id,
                    repairTimeDivision,
                    customWorks,
                    vehicleId: workTask.vehicle?.id,
                    costEstimationOptions,
                })
            }
        },
        [customRepairTimes, workTask.vehicle?.id, repairTimeDivision, actions, workTask.id, costEstimationOptions]
    )

    const handleDeleteSelected = useCallback(
        async (ids: Array<string>) => {
            const filteredIds = ids.filter((id) => customRepairTimes.some((a) => a.id === id))

            if (filteredIds.length) {
                await deleteRepairTime(filteredIds)
                unselectAll()
            }
        },
        [customRepairTimes, unselectAll, deleteRepairTime]
    )

    const handleSearch = useCallback((query: string | undefined) => {
        setRequest((prev) => ({
            ...prev,
            query,
        }))
    }, [])

    let content
    let headerAttachment

    if (isLoading && !isFetchingNextPage) {
        content = <Loader />
    } else if (isError) {
        content = <Alert size="small" severity="error" title={translateText(163)} />
    } else if (customRepairTimes.length) {
        content = (
            <CustomRepairTimesList
                workTask={workTask}
                repairTimes={customRepairTimes}
                repairTimeDivision={repairTimeDivision}
                showRepairTimesInHours={showRepairTimesInHours ?? true}
                hourlyRates={hourlyRates}
                costEstimationOptions={state.costEstimation?.costEstimationOptions}
                currencySymbol={currencySymbol}
                loading={isLoading}
                selectedRepairTimeIds={selectedIds}
                vatRates={vatRates}
                onLoadMoreRepairTimes={fetchNextPage}
                onToggleRepairTime={handleToggleRepairTime}
                onAddCustomWorks={actions.addCustomWorks}
            />
        )

        headerAttachment = (
            <CustomItemSelection
                selectedIds={selectedIds}
                allSelected={customRepairTimes.every((x) => selectedIds.includes(x.id))}
                selectAll={selectAll}
                unselectAll={unselectAll}
                deleteSelected={handleDeleteSelected}
                onAddToBasket={handleAddToBasket}
                itemsType="repair-time"
            />
        )
    } else {
        content = <Alert size="small" severity="info" title={translateText(13291)} />
    }

    return (
        <Box p={1} pt={0}>
            <CustomItemHeader>
                <CustomItemSearch numberTextId={58} onSearch={handleSearch} />
                {headerAttachment}
            </CustomItemHeader>
            {content}
        </Box>
    )
}
