import { ERIKContentService } from "@tm/data"
import { useCallback } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useGetUploadKey } from "./useGetUploadKey"
import { useCompleteFileUpload } from "./useCompleteFileUpload"
import { QueryKeys } from ".."

function getBaseUrl(url: string) {
    return url?.split("/upload")?.[0] ?? ""
}

/**
 * @description Use this hook for single file upload.\
 * Only small files are supported for now\
 * Category and additional labels need to be added from getUploadKey hook
 */
export function useUploadFile() {
    const queryClient = useQueryClient()
    const { isLoading: uploadKeyLoading, getUploadKey } = useGetUploadKey()
    const { isLoading: fileUploadLoading, mutateAsync: uploadFileMutation } = useMutation(ERIKContentService.uploadSingleImage)
    const { data: uploadedFileData, isLoading: completeFileUploadLoading, completeFileUpload } = useCompleteFileUpload()

    const uploadFile = useCallback(
        async (file: File, vehicleId: string, category: string, mileage: string, date: Date, invoiceTotal?: string) => {
            const uploadDetails = await getUploadKey(1, vehicleId, category, mileage, date, invoiceTotal)

            if (uploadDetails) {
                const baseUrl = getBaseUrl(uploadDetails.uploadUrl)

                const request: ERIKContentService.UploadSingleImageRequest = {
                    baseUrl,
                    fileIndex: 0,
                    partId: uploadDetails.parts[0].id,
                    uploadRequestId: uploadDetails.id,
                    uploadKey: uploadDetails.uploadKey,
                    file,
                }

                try {
                    const uploaded = await uploadFileMutation(request)

                    if (uploaded) {
                        await completeFileUpload(baseUrl, uploadDetails.id, uploadDetails.uploadKey)
                        queryClient.invalidateQueries([QueryKeys.GetFileList])
                    }
                } catch (error) {
                    // TODO Error handling when messages are provided
                }
            }
        },
        [uploadFileMutation, getUploadKey, completeFileUpload, queryClient]
    )

    return { uploadedFileData, isLoading: uploadKeyLoading || fileUploadLoading || completeFileUploadLoading, uploadFile }
}
