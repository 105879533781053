import { Card, Image } from "@tm/controls"
import { ManufacturerBase, VehicleType } from "@tm/models"
import { Suspense } from "react"

import { getBundleParams } from "../../utils"
import { useTopManufacturers } from "../../data/hooks"

type Props = {
    vehicleType: VehicleType
    onSelect(manufacturer: ManufacturerBase): void
    imageHeight?: string
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <TopManufacturerCards {...props} />
        </Suspense>
    )
}

function TopManufacturerCards({ vehicleType, onSelect, imageHeight }: Props) {
    const { topManufacturers = [] } = useTopManufacturers({ vehicleType, selectedFilters: {} }) ?? {}

    if (!topManufacturers.length) {
        return null
    }

    return (
        <>
            {topManufacturers.map((x) => (
                <Card onClick={() => onSelect(x)} key={x.id}>
                    {getBundleParams().hideManufacturerLogos ? (
                        <span>{x.description}</span>
                    ) : (
                        <Image url={x.thumbnail} type="manufacturer" title={x.description} height={imageHeight} style={{ cursor: "pointer" }} />
                    )}
                </Card>
            ))}
        </>
    )
}
