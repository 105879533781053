import { IBundle } from "@tm/morpheus"
import Backorders from "./components/backorders"
import CisButton from "./components/cis-button"
import ConceptLogo from "./components/concept-logo"
import ConceptStyle from "./components/concept-style"
import Filter from "./components/filter"
import Invoices from "./components/invoices"
import Navigation from "./components/navigation"
import NextTour from "./components/next-tour"
import PrintOrder from "./micros/print-order"
import Redirect from "./components/redirect"
import ReturnOfItems from "./components/returns"
import Summary from "./components/summary"
import Tours from "./components/tours"
import Vouchers from "./components/vouchers"
import WarehouseSummary from "./components/warehouse-summary"
import Widget from "./components/widget"
import WidgetAccountTours from "./components/widget-account-tours"
import WidgetBackorders from "./components/widget-backorders"
import WidgetContacts from "./components/widget-contacts"
import WidgetCredit from "./components/widget-credit"
import WidgetForms from "./components/widget-forms"
import WidgetMyAccount from "./components/widget-my-account"
import WidgetMyData from "./components/widget-my-data"
import WidgetNextSupplier from "./components/widget-next-supplier"
import WidgetPvOrders from "./components/widget-pv-orders"
import WidgetReturns from "./components/widget-returns"
import WidgetStgOrders from "./components/widget-stg-orders"
import WidgetTours from "./components/widget-tours"
import WidgetWarehouse from "./components/widget-warehouse"
import CostOverview from "./components/cost-overview"
import { version, initCisBundle } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        Backorders,
        CisButton,
        CostOverview,
        ConceptLogo,
        ConceptStyle,
        Filter,
        Invoices,
        Navigation,
        NextTour,
        Redirect,
        ReturnOfItems,
        Summary,
        Tours,
        Vouchers,
        WarehouseSummary,
        Widget,
        WidgetBackorders,
        WidgetContacts,
        WidgetCredit,
        WidgetForms,
        WidgetMyAccount,
        WidgetMyData,
        WidgetNextSupplier,
        WidgetPvOrders,
        WidgetReturns,
        WidgetStgOrders,
        WidgetTours,
        WidgetWarehouse,
        WidgetAccountTours,
    ],
    micros: [PrintOrder],
    init: initCisBundle,
}

export default bundle
