import { Typography } from "@tm/components"
import { Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IncludedWorkRequest } from "@tm/models"
import { ReactElement } from "react"

type Props = {
    includedWorks: Array<IncludedWorkRequest>
    showSupplierArticleNumbers: boolean
}

export default function IncludedWorks({ includedWorks, showSupplierArticleNumbers }: Props) {
    const { translate } = useLocalization()
    const columns: Array<ReactElement> = []

    const renderNumberCell = (data: IncludedWorkRequest) => (
        <Table.Cell>
            {data.providerWorkId.split(/(?=[+])/).map((providerWorkId) => (
                <Typography key={providerWorkId}>{providerWorkId}</Typography>
            ))}
        </Table.Cell>
    )

    const renderDescriptionCell = (data: IncludedWorkRequest) => <Table.Cell>{data.description}</Table.Cell>

    if (showSupplierArticleNumbers) {
        columns.push(
            <Table.Column className="number" renderItemContent={renderNumberCell}>
                {translate(58)}
            </Table.Column>
        )
    }

    columns.push(
        <Table.Column className="description" renderItemContent={renderDescriptionCell}>
            {translate(61)}
        </Table.Column>
    )

    return <Table data={includedWorks} columns={columns} className="included-works" />
}
