import { VehicleImportData } from "@tm/models"
import { useState, useEffect } from "react"
import { getImportedVehicleDetails } from "../../business"

type State = {
    importedData?: VehicleImportData
    importedDataLoading: boolean
}

export function useImportedVehicleData(workTaskId: string | undefined) {
    const [state, setState] = useState<State>({ importedDataLoading: true })

    useEffect(() => {
        if (workTaskId) {
            getImportedVehicleDetails(workTaskId).then(
                (importedData) => setState({ importedDataLoading: false, importedData }),
                () => setState({ importedDataLoading: false })
            )
        }
    }, [workTaskId])

    return state
}
