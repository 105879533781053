import { VehicleType } from "@tm/models"

import { callService } from "."
import { getBundleParams } from "../../utils"

function getDataAvailabilitiesServiceUrl(): string {
    return getBundleParams().dataAvailabilitiesServiceUrl
}

function callDataAvailabilitiesService<TReq, TRes>(url: string, request: TReq): Promise<TRes | undefined> {
    return callService<TReq, TRes>(`${getDataAvailabilitiesServiceUrl()}/${url}`, request)
}

export type ShowAvailabilitiesRequest = {
    modelId: number
    vehicleType: VehicleType

    /** Used for TecRMI availabilities.
     * @default DE
     * */
    countryCode?: string
}

export type ShowAvailabilitiesResponse = {
    gpi: GpiAvailabilities
    technicalData: TechnicalDataAvailabilities
    inspectionData: InspectionDataAvailabilities
    repairTimes: RepairTimesAvailabilities
    tyres: TyresAvailabilities
    fastCalculator: FastCalculatorAvailabilities
}

export type GpiAvailabilities = {
    hasDat?: boolean
    hasEurotaxV3?: boolean
    hasTemot?: boolean
    hasTecRmi?: boolean
}

export type TechnicalDataAvailabilities = {
    hasAutodata?: boolean
    hasHaynesPro?: boolean
    hasTecRmi?: boolean
}

export type InspectionDataAvailabilities = {
    hasAutodata?: boolean
    hasHaynesPro?: boolean
    hasTecRmi?: boolean
}

export type RepairTimesAvailabilities = {
    hasAutodata?: boolean
    hasHaynesPro?: boolean
    hasTecRmi?: boolean
    hasAwDoc?: boolean
}

export type TyresAvailabilities = {
    hasDriveRight?: boolean
    hasTecRmi?: boolean
}

export type FastCalculatorAvailabilities = {
    hasAny: boolean
    hasAutodata?: boolean
    hasHaynesPro?: boolean
    hasTecRmi?: boolean
    activeServiceDataProviders: number
}

export function showAvailabilities(request: ShowAvailabilitiesRequest) {
    return callDataAvailabilitiesService<ShowAvailabilitiesRequest, ShowAvailabilitiesResponse>("ShowAvailabilities", request)
}
