import { CisVoucherType } from "@tm/models"
import { VoucherItem } from "../../../../data/model"
import WmBackorderItemsTable from "./voucher-items-tables/WmBackorderItemsTable"
import WmOldPartItemsTable from "./voucher-items-tables/WmOldPartItemsTable"
import WmComplaintItemsTable from "./voucher-items-tables/WmComplaintItemsTable"
import WmDeliveryNoteItemsTable from "./voucher-items-tables/WmDeliveryNoteItemsTable"
import WmStandardVoucherItemsTable from "./voucher-items-tables/WmStandardVoucherItemsTable"

type Props = {
    currencyCode?: string
    voucherItems: Array<VoucherItem>
    voucherTypeId?: number
}

export default function WmVoucherItemsTableComponent({ currencyCode, voucherItems, voucherTypeId }: Props) {
    switch (voucherTypeId) {
        case CisVoucherType.OldParts:
            return <WmOldPartItemsTable voucherItems={voucherItems} />
        case CisVoucherType.Complaints:
            return <WmComplaintItemsTable voucherItems={voucherItems} currencyCode={currencyCode} />
        case CisVoucherType.Backorder:
            return <WmBackorderItemsTable voucherItems={voucherItems} />
        case CisVoucherType.DeliveryNote:
            return <WmDeliveryNoteItemsTable voucherItems={voucherItems} currencyCode={currencyCode} />
        default:
            return <WmStandardVoucherItemsTable voucherItems={voucherItems} currencyCode={currencyCode} />
    }
}
