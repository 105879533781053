import { Box, styled } from "@tm/components"

type Props = React.HTMLAttributes<HTMLDivElement> & {
    top?: React.ReactNode
    bottom?: React.ReactNode
    className?: string
}

export default function VerticalRule(props: Props) {
    const { top, bottom, ...rest } = props

    return (
        <StyledBox {...rest}>
            {top}
            <StyledLine />
            {bottom}
        </StyledBox>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: `0 ${theme.margin?.xl}`,
}))

const StyledLine = styled(Box)(({ theme }) => ({
    flex: 1,
    width: "1px",
    backgroundColor: "#acacac",
    $nest: {
        "&:not(:first-child)": {
            marginTop: theme.margin?.l,
        },
        "&:not(:last-child)": {
            marginBottom: theme.margin?.l,
        },
    },
}))
