import { useUser, useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { SystemType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { Button } from "@tm/components"
import { createMemo } from "../../../data/helpers"
import { customerSelector, erpArticlesSelector, itemsSelector, MainActions, vehicleSelector, worksSelector } from "../../main/business"
import { useBasketImports } from "../../../../../basket/src/hooks/basketState/useBasketImports"

const selector = initSelector(
    vehicleSelector,
    customerSelector,
    itemsSelector,
    worksSelector,
    erpArticlesSelector,
    (s) => s.calculation.calculationLoading,
    (s) => s.calculation.calculationError
)

export default function AddToKVAButton() {
    const user = useUser()
    const { translateText } = useLocalization()
    const { importToCostEstimation } = useBasketImports()
    const fullWorkTask = useWorkTask()!

    const repairTimeDivision = user?.userSettings?.repairTimeOptions?.division
    const [vehicle, customer] = useSelector(selector)
    const actions = useActions(MainActions, "addToShoppingBasket")

    const handleAddToBasket = () => {
        if (user?.userContext?.system.systemType !== SystemType.Next) {
            // eslint-disable-next-line no-alert
            alert("Not implemented")
            // TODO
            return
        }

        const memo = user?.userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(vehicle, customer) : undefined

        repairTimeDivision && actions.addToShoppingBasket(importToCostEstimation, repairTimeDivision, fullWorkTask, memo)
    }

    return (
        <Toolbar className="take-damage" title={translateText(916)}>
            <Button color="highlight" size="large" sx={{ fontSize: "1.125rem !important" }} onClick={handleAddToBasket}>
                {translateText(1607)}
            </Button>
        </Toolbar>
    )
}
