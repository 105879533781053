/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import { getValue } from "@tm/utils"
import { ShowCarModelDetailsResponse, Vehicle } from "@tm/models"
import { addOrRemoveItem } from "../../../helpers/addOrRemoveItem"
import { MaintenancePlanSlice, SelectionKeys } from "./model"
import { CalcState, FastServiceSelection, MaintenanceData } from "../../models"
import { FastServiceStore } from "../.."
import { mapWorkTimes } from "./helpers"

export function deleteSelectionKey(state: MaintenancePlanSlice, selectionKey: SelectionKeys): Partial<MaintenancePlanSlice> {
    const temp = { ...state.maintenancePlan.selectionsToSend }
    delete temp?.[selectionKey]

    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            selectionsToSend: temp,
        },
    }
}

export function setUpdateSelections(
    state: MaintenancePlanSlice,
    selectionKey: SelectionKeys,
    path: string,
    value: string
): Partial<MaintenancePlanSlice> {
    const previousValues = getValue(state.maintenancePlan.selectionsToSend?.[selectionKey]!, [path])
    const newValue = addOrRemoveItem(previousValues, value)

    const previousUserSelections = getValue(state.maintenancePlan.userSelections?.[selectionKey]!, [path])
    const newUserSelections = addOrRemoveItem(previousUserSelections, value)

    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            selectionsToSend: {
                ...state.maintenancePlan.selectionsToSend,
                [selectionKey]: {
                    ...state.maintenancePlan.selectionsToSend?.[selectionKey],
                    [path]: newValue,
                },
            },
            userSelections: {
                ...state.maintenancePlan.userSelections,
                [selectionKey]: {
                    ...state.maintenancePlan.userSelections?.[selectionKey],
                    [path]: newUserSelections,
                },
            },
        },
    }
}

export function setMaintenanceData(state: MaintenancePlanSlice, maintenanceData: MaintenanceData): Partial<MaintenancePlanSlice> {
    const { selections, additionalWorkSelections, followupWorkSelections, recordWorks, results, tireSpecifications } = maintenanceData

    const workTimes = mapWorkTimes(results)

    const selectedService = maintenanceData.selections.find((x) => x.id === "haynesProMaintenancePeriod")?.items.filter((x) => x.isSelected) || []

    const selectionS = getSelectedSelectionsFromWS(selections, SelectionKeys.Selections)
    const selectionAdditionalWorks = additionalWorkSelections
        ? getSelectedSelectionsFromWS(additionalWorkSelections, SelectionKeys.AdditionalWorks)
        : {}
    const selectionFollowUps = followupWorkSelections ? getSelectedSelectionsFromWS(followupWorkSelections, SelectionKeys.FollowUps) : {}

    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            maintenancePlanLoading: false,
            selections,
            additionalWorks: additionalWorkSelections,
            followupWorks: followupWorkSelections,
            moreInfo: undefined,
            works: recordWorks,
            workTimes,
            selectedService,
            tireSpecifications,
            userSelections: { ...selectionS, ...selectionAdditionalWorks, ...selectionFollowUps },
        },
    }
}

export function setMaintenanceDataLoading(state: MaintenancePlanSlice): Partial<MaintenancePlanSlice> {
    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            maintenancePlanLoading: true,
        },
    }
}

export function resetSelections(state: MaintenancePlanSlice): Partial<MaintenancePlanSlice> {
    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            selections: [],
            additionalWorks: [],
            followupWorks: [],
        },
    }
}

export function setMaintenanceDataError(state: MaintenancePlanSlice): Partial<MaintenancePlanSlice> {
    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            maintenancePlanLoading: false,
        },
    }
}

export function updateVehicleField(state: MaintenancePlanSlice, path: string, value: any): Partial<MaintenancePlanSlice> {
    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            vehicleFields: {
                ...state.maintenancePlan.vehicleFields,
                [path]: value,
            },
        },
    }
}

export function setVehicle(
    state: MaintenancePlanSlice,
    get: FastServiceStore,
    vehicle: Vehicle,
    modelDetailsResponse: ShowCarModelDetailsResponse,
    shouldReset?: boolean
): Partial<MaintenancePlanSlice> {
    // When we set the vehicle ot modify data we should reset the store, as all the process is vehicle dependent!
    const { initialRegistration, plateId, vin, mileAge, engineCode, longlife } = vehicle
    const { engineCodes } = modelDetailsResponse.modelDetails || {}
    const { modelDetails } = modelDetailsResponse
    const minDate = modelDetails?.constructionYearFrom && new Date(modelDetails.constructionYearFrom.year, modelDetails.constructionYearFrom.month, 0)
    shouldReset && get.resetStore()

    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            stateVehicle: vehicle,
            modelDetails: modelDetailsResponse,
            vehicleFields: {
                ...state.maintenancePlan.vehicleFields,
                initialRegistration,
                vin,
                engineCode,
                plateNumber: plateId,
                mileage: mileAge,
                engineCodes,
                longLife: longlife,
                minDate,
            },
        },
    }
}

export function setCalcState(state: MaintenancePlanSlice, calcState: CalcState): Partial<MaintenancePlanSlice> {
    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            calcState,
        },
    }
}

export function setBaseCalculationId(state: MaintenancePlanSlice, baseCalculationCalcId: string): Partial<MaintenancePlanSlice> {
    return {
        maintenancePlan: {
            ...state.maintenancePlan,
            baseCalculationCalcId,
        },
    }
}

function getSelectedSelectionsFromWS(selections: FastServiceSelection[], selectionKey: SelectionKeys) {
    const multiSelections = selections.filter((s) => s.multiSelect)
    let selectedSelections: { [key: string]: { [key: string]: string[] } } = {}

    multiSelections.map((s) => {
        if (s.multiSelect) {
            s.items.map((i) => {
                if (i.isSelected) {
                    const previousValues = getValue(selectedSelections?.[selectionKey]!, [s.selectionId])
                    const newValue = addOrRemoveItem(previousValues, i.id)

                    selectedSelections = {
                        ...selectedSelections,
                        [selectionKey]: {
                            ...selectedSelections[selectionKey],
                            [s.selectionId]: newValue,
                        },
                    }
                }
            })
        }
    })

    return selectedSelections
}
