import { AttributeFilterModel, FittingPosition, ProductGroupFiltersModel, ShowVehicleRecordsViewMode } from "@tm/models"
import { useQuery, useQueryClient } from "react-query"
import * as Data from "../../../data/repository"

const KEY = "vehicle-records_useVehicleRecords"
const EMPTY_ARRAY: Array<ProductGroupFiltersModel> = []
export function useVehicleRecords(vehicleId: string | undefined, selectedFittingSide: FittingPosition) {
    const { data } = useQuery(
        [KEY, vehicleId, selectedFittingSide],
        () => {
            if (vehicleId) {
                return Data.showVehicleRecords({
                    vehicleId,
                    fittingSide: selectedFittingSide,
                    viewMode: ShowVehicleRecordsViewMode.ShowRecordedValuesOnly,
                }).then((response) => response?.productGroupFilters)
            }
        },
        { enabled: !!vehicleId }
    )

    const queryClient = useQueryClient()
    const selectAttributeFilter = (attribute: AttributeFilterModel) => {
        if (vehicleId) {
            Data.equipmentModified({ vehicleId, attribute }).then(() => {
                queryClient.resetQueries([KEY, vehicleId])
            })
        }
    }
    const deselectAttributeFilter = (attribute: AttributeFilterModel) => {
        if (vehicleId) {
            Data.declineVehicleRecord({ vehicleId, attribute }).then(() => {
                queryClient.resetQueries([KEY, vehicleId])
            })
        }
    }
    const clearAllFilters = () => {
        if (vehicleId) {
            Data.clearAllRecords({ vehicleId }).then(() => {
                queryClient.setQueriesData([KEY, vehicleId], undefined)
            })
        }
    }

    return { records: data || EMPTY_ARRAY, selectAttributeFilter, deselectAttributeFilter, clearAllFilters }
}
