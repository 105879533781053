import { RepairTimeRD, ServiceNote } from "@tm/models"
import { Work } from "../models"

export function mapWorkToService(work: Work): RepairTimeRD {
    return {
        attributes: [],
        description: work.description,
        hasCalculatedServices: true,
        hasPreparationWorks: false,
        isCalculated: true,
        note: {} as unknown as ServiceNote,
        productGroupIds: work.productGroupIds! || [],
        repairTimeNo: work.providerWorkId,
        serviceType: "",
        sortId: 0,
        repairTime: work.time,
        hourlyRate: work.hourlyRate,
    }
}
