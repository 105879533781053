import { useAvailableVehicleSearchOptions } from "@tm/utils"

export function useVehicleSearchOptionsQuota(lookupTypeId?: number) {
    const { availableVehicleSearches, invalidateStore } = useAvailableVehicleSearchOptions()
    if (!lookupTypeId) {
        return { quota: undefined, invalidateStore }
    }
    const configuredVehicleLookups = availableVehicleSearches?.configuredVehicleLookups.find(
        (vehicleLookup) => vehicleLookup.lookupTypeId === lookupTypeId
    )
    return { quota: configuredVehicleLookups?.quota, invalidateStore }
}
