import { styled } from "@tm/components"
import { ConfigParams, CountryFilter, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { VrcLookupModule } from "../../../../../data/model/vrc-lookup"
import { useSelectedVehicleLookup } from "../../../../../data/hooks/useSelectedLookupConfig"

type Props = {
    module: VrcLookupModule
}

const StyledImg = styled("img")({
    height: "1.5rem",
})

export default function Flag({ module }: Props) {
    const { nationalFlagsBaseUrl } = Morpheus.getParams<ConfigParams>()
    const { selectedVehicleLookup } = useSelectedVehicleLookup(VehicleType.PassengerCar)
    let url

    const countryCode = selectedVehicleLookup && module.countryCodes?.find((cc) => cc === selectedVehicleLookup.countryCode)
    if (countryCode) {
        url = `${nationalFlagsBaseUrl}/${countryCode}.svg`
        return <StyledImg src={url} />
    }

    switch (module.binLkz) {
        case CountryFilter.D:
            url = `${nationalFlagsBaseUrl}/de.svg`
            break
        case CountryFilter.A:
            url = `${nationalFlagsBaseUrl}/at.svg`
            break
        case CountryFilter.PL:
            url = `${nationalFlagsBaseUrl}/pl.svg`
            break
        case CountryFilter.CH:
            url = `${nationalFlagsBaseUrl}/ch.svg`
            break
        default:
            return <>{module.name}</>
    }

    return <StyledImg src={url} />
}
