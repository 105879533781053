import { PanelSection, Image } from "@tm/controls"
import { CarModelDetails, VehicleType } from "@tm/models"
import { VehicleImage } from "@tm/components"

type Props = {
    modelDetails: CarModelDetails
    vehicleType: VehicleType
}

function ImageViewComponent({ modelDetails, vehicleType }: Props) {
    modelDetails.manufacturerThumbnail = modelDetails.manufacturerThumbnail && modelDetails.manufacturerThumbnail.replace(/https?:/, "")

    return (
        <div className="images">
            <PanelSection>
                <Image className="manufacturer" url={modelDetails.manufacturerThumbnail} />
                <VehicleImage
                    className="model"
                    modelImage={modelDetails.image}
                    modelSeriesImage={modelDetails.modelSeriesImage}
                    vehicleType={vehicleType}
                />
            </PanelSection>
        </div>
    )
}

export default ImageViewComponent
