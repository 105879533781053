import { Container } from "@tm/nexus"
import { RegisteredModels, RequestArticleListPayload, channel } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenPromoPartsList(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (data.openPromoPartsList) {
        this.checkAndCreateWorktask()

        const container = Container.getInstance<Array<string>>(RegisteredModels.ERP_GetPromotedItems)

        container
            .subscribe(this.props.telesalesCustomerNo)
            .load()
            .then((wholesalerArticleNos) => {
                if (Array.isArray(wholesalerArticleNos) && wholesalerArticleNos.length) {
                    const request: RequestArticleListPayload = {
                        traderArticleNos: {
                            wholesalerParts: wholesalerArticleNos.map((x) => ({
                                wholesalerArticleNumber: x,
                                itemId: uniqueId(),
                            })),
                            hideDuplicatesWithDifferentProductGroups: true,
                        },
                    }

                    channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
                } else {
                    console.log("No promotional items")
                }
            }, console.log)
    }
}
