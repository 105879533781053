import { atom, useRecoilState } from "recoil"
import { VehicleLookupConfigSlim } from "../repositories/vehicleLookupConfigs"

export const simpleVehicleSearchAtom = atom<VehicleLookupConfigSlim | undefined>({
    key: "vehicle_simpleVehicleSearch",
    default: undefined,
})

export function useSelectedSimpleLookupConfig() {
    const [selectedConfig, setSelectedConfig] = useRecoilState(simpleVehicleSearchAtom)

    return {
        selectedConfig,
        setSelectedConfig,
    }
}
