import { useLocalization } from "@tm/localization"
import { Loader, Text, Card } from "@tm/controls"
import { Item } from "@tm/models"
import { ReturnItem } from "../../../data/model"
import PartsListItem from "./parts-list-item"
import { CisDisplayMode } from "../../../business/model"

type Props = {
    loading: boolean
    returnItems: Array<ReturnItem>
    selectedIds: Array<string>
    ordersUrl?: string
    cisDisplayMode?: CisDisplayMode
    isUpdating: boolean
    fromVouchersModule?: boolean
    onChangeReturnStatus(items: Array<Item>, includeInReturn: boolean): void
    onOpenDetails?(): void
    onRemoveItems(ids: Array<ReturnItem>): void
    onSelectItem(partId: string): void
    onShowOrder(voucherTypeId: number, orderId: string, voucherId?: string, subId?: string): void
    onResetSubmitOrder(): void
}

export default function PartsListComponent(props: Props) {
    const { cisDisplayMode, ordersUrl, returnItems, loading, selectedIds, isUpdating, fromVouchersModule } = props
    const { translateText } = useLocalization()

    function renderPartItem(returnItem: ReturnItem) {
        return (
            <PartsListItem
                key={returnItem.id}
                returnItem={returnItem}
                isSelected={selectedIds.includes(returnItem.id)}
                isUpdating={isUpdating}
                cisDisplayMode={cisDisplayMode}
                ordersUrl={ordersUrl}
                fromVouchersModule={fromVouchersModule}
                onChangeReturnStatus={props.onChangeReturnStatus}
                onSelectItem={props.onSelectItem}
                onShowOrder={props.onShowOrder}
                onRemoveItems={props.onRemoveItems}
                onOpenDetails={props.onOpenDetails}
                onResetSubmitOrder={props.onResetSubmitOrder}
            />
        )
    }

    function renderList() {
        return <div className="list part-list">{returnItems.map(renderPartItem)}</div>
    }

    function renderEmpty() {
        return (
            <Card className="empty-list">
                <Text>{translateText(323)}</Text>
            </Card>
        )
    }

    if (loading) {
        return <Loader />
    }
    if (!returnItems || !returnItems.length) {
        return renderEmpty()
    }
    return renderList()
}
