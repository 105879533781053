import { Dropdown, Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CalendarType } from "../business"

export type SelectCalendarTypeProps = {
    calendarType: CalendarType
    setCalendarType: (type: CalendarType) => void
    loading: boolean
}

export function SelectCalendarType(props: SelectCalendarTypeProps) {
    const { translate } = useLocalization()
    const { calendarType, setCalendarType } = props
    type Item = {
        key: CalendarType
        val: number
    }
    const items: Item[] = [
        { key: "LIST", val: 207 },
        { key: "MONTH", val: 1644 },
        { key: "DAY", val: 1645 },
    ]

    function ItemView(item: Item) {
        return <div>{translate(item.val)}</div>
    }

    function handleChange(item: Item) {
        setCalendarType(item.key)
    }
    return (
        <>
            <Dropdown
                size="xl"
                layout={["ghost", "iconRight"]}
                items={items}
                itemView={ItemView}
                value={items.find((x) => x.key === calendarType)}
                onChange={handleChange}
            />
            <Loader visible={props.loading} delay={500} />
        </>
    )
}
