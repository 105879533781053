import { em, important, percent } from "csx"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { initSelector, withRouter } from "@tm/utils"
import { ESufficentSelections } from "../../data/enums"
import { bem } from "../../data/helpers"
import { ErrorMessage, EurotaxPanel } from "../_shared"
import { MainState } from "../main"
import Category from "./components/category/category"
import BaseCriterias from "./components/filters/baseCriterias"
import VKNCriterias from "./components/filters/vknCriterias"
import VehicleParts from "./components/graphic/vehicleParts"
import VKNAlternativePicker from "./components/graphic/vknAlternativePicker"
import SelectionFilters from "./components/selectionModal/selectionFilters"

const selector = initSelector(
	(s: MainState) => s.details.selectedVKNImage,
	s => s.summary.selectedMmtGroup,
	s => s.details.criterias,
	s => s.details.vknImages,
	s => s.details.extendedCarInfo.sufficientSelection,
	s => s.details.extendedCarInfo.loading,
	s => s.details.selectedValues,
	s => s.details.selectionAvailable
)

function DetailsComponent() {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])
	const [selectedVKNImage, selectedMmtGroup, criterias, vknImages, extendedSufficentSelection, extendedCarLoading, selectedValues, selectionAvailable] = useSelector(selector)

	function renderCategory() {
		return (
			<EurotaxPanel className={style.categoryPanel}>
				<Category />
			</EurotaxPanel>
		)

	}

	const renderFilters = () => {
		if (vknImages.error || !vknImages.loading && vknImages.sufficientSelection != ESufficentSelections.Distinct || selectedVKNImage?.svgLoading ||
			!criterias.base.length && !criterias.vkn.length)
			return null

		return (
			<EurotaxPanel className={bem(style.filtersPanel, !!extendedSufficentSelection && extendedSufficentSelection != ESufficentSelections.Distinct && "covered")} title={translateText(209)} >
				{selectedVKNImage && !criterias.forceShowVknCriterias
					? <BaseCriterias />
					: <VKNCriterias />}
			</EurotaxPanel>
		)
	}

	const renderGraphic = () => {
		const { error: vknError, sufficientSelection, loading } = vknImages

		if (!loading && sufficientSelection != ESufficentSelections.Distinct) {
			return (
				<EurotaxPanel className={style.graphicPanel} title={selectedVKNImage?.imageTitle || selectedMmtGroup?.selectedErePosition?.description || "Graphic"}>
					<ErrorMessage title={1020} icon="no-results" />
				</EurotaxPanel>
			)
		}
		if (vknError)
			return (
				<EurotaxPanel className={style.graphicPanel} title={selectedVKNImage?.imageTitle || selectedMmtGroup?.selectedErePosition?.description || "Graphic"}>
					<ErrorMessage title={809} description={1088} icon="no-results" />
				</EurotaxPanel>
			)
		return (
			<EurotaxPanel className={bem(style.graphicPanel, !!extendedSufficentSelection && extendedSufficentSelection != ESufficentSelections.Distinct && "covered")} title={selectedVKNImage?.imageTitle || selectedMmtGroup?.selectedErePosition?.description || "Graphic"}>
				{selectedVKNImage && <VehicleParts /> || < VKNAlternativePicker />}
			</EurotaxPanel>
		)

	}

	return (
		<div className={style.details} >
			{ renderCategory()}
			<div className={style.rightSide} >
			{(selectionAvailable || Object.values(selectedValues).some(Boolean) || extendedCarLoading) &&
					<EurotaxPanel className={style.selectionFilters}>
						<SelectionFilters />
					</EurotaxPanel>
				}

				<div className={style.rightContent}>
					{renderFilters()}
					{renderGraphic()}
				</div>
			</div >
		</div >
	)
}

export default withRouter(DetailsComponent)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        details: {
            height: percent(100),
            display: "flex",
            flex: "1",
            overflow: "hidden",
        },
        categoryPanel: {
            padding: theme.margin.s,
            flexBasis: percent(25)
        },
        rightSide: {
            flex: 1,
            display: "flex",
            flexDirection: "column"
        },
        rightContent: {
            display: "flex",
            flex: 1,
        },
        filtersPanel: {
            padding: theme.margin.s,
            flexBasis: percent(20),
            marginLeft: theme.margin.s,
            $nest: {
                ".panel__content": {
                    marginTop: important(em(0)),
                },
                "&--covered": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                }
            }
        },
        selectionFilters: {
            marginBottom: theme.margin.s,
            marginLeft: theme.margin.s
        },
        graphicPanel: {
            flex: 1,
            marginLeft: theme.margin.s,
            $nest: {
                "&--covered": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                }
            }
        },
        modal: {
            minWidth: important(percent(25)),
            width: "auto !important",
        }
    })(DetailsComponent)
}
