import { Container } from "@tm/nexus"
import {
    RegisteredModels,
    SupplierArticleQuantitiesRequest,
    CatalogPart,
    OeArticleQuantitiesRequest,
    OePart,
    WholesalerPart,
    WholesalerArticleQuantitiesRequest,
} from "@tm/models"
import { getBundleParams } from "../utils"
import { getHasRepairTimesForProductGroup } from "./repositories/basket/fitting-groups"
import * as BasketOrderRepository from "./repositories/basket/order"
import { hasRepairTimesNexus } from "./repositories/basket/works"
import * as BasketCostEstimationRepository from "./repositories/basket/cost-estimation"
import { getOeArticleQuantities, getSupplierArticleQuantities, getWholesalerArticleQuantities } from "./repositories/basket/article-quantities"

export * from "./repositories/basket/cost-estimation"
export * from "./repositories/basket/fast-calculation"
export * from "./repositories/basket/order"
export * from "./repositories/basket/parts"
export * from "./repositories/basket/repair-estimation"
export * from "./repositories/basket/works"
export * from "./repositories/basket/WorkTaskBasket"
export * from "./repositories/custom-items/articles"
export * from "./repositories/custom-items/repair-times"
export * from "./repositories/erp"
export * from "./repositories/repairTimes"
export * from "./repositories/viewState"

export function reloadSupplierArticleQuantities(
    workTaskId: string,
    article: Pick<CatalogPart, "dataSupplierArticleNumber" | "dataSupplierId" | "productGroupId">
) {
    const request: SupplierArticleQuantitiesRequest = {
        workTaskId,
        article: {
            dataSupplierArticleNumber: article.dataSupplierArticleNumber,
            dataSupplierId: article.dataSupplierId,
            productGroupId: article.productGroupId,
        },
    }
    Container.getInstance(RegisteredModels.Basket_BasketInfo).subscribe(request).load()
}

export function reloadOeArticleQuantities(workTaskId: string, article: Pick<OePart, "oeArticleNumber" | "productGroupId" | "vehicleManufacturerId">) {
    const request: OeArticleQuantitiesRequest = {
        workTaskId,
        article: {
            oeArticleNumber: article.oeArticleNumber,
            productGroupId: article.productGroupId,
            vehicleManufacturerId: article.vehicleManufacturerId,
        },
    }
    Container.getInstance(RegisteredModels.Basket_OeBasketInfo).subscribe(request).loadIfRequired()
}

export function reloadWholesalerArticleQuantities(workTaskId: string, article: Pick<WholesalerPart, "wholesalerArticleNumber">) {
    const request: WholesalerArticleQuantitiesRequest = {
        workTaskId,
        article: {
            wholesalerArticleNumber: article.wholesalerArticleNumber,
        },
    }
    Container.getInstance(RegisteredModels.Basket_WholesalerBasketInfo).subscribe(request).loadIfRequired()
}

export function registerActions() {
    Container.register({
        name: RegisteredModels.Basket_BasketInfo,
        modelActions: {
            load: getSupplierArticleQuantities,
        },
    })
    Container.register({
        name: RegisteredModels.Basket_OeBasketInfo,
        modelActions: {
            load: getOeArticleQuantities,
        },
    })
    Container.register({
        name: RegisteredModels.Basket_HasRepairTimes,
        modelActions: {
            load: hasRepairTimesNexus,
        },
    })
    Container.register({
        name: RegisteredModels.Basket_HasRepairTimesForProductGroup,
        modelActions: {
            load: getHasRepairTimesForProductGroup,
        },
    })
    Container.register({
        name: RegisteredModels.Basket_Order_ShowItem,
        modelActions: {
            load: BasketOrderRepository.showItem,
        },
    })
    Container.register({
        name: RegisteredModels.Basket_WholesalerBasketInfo,
        modelActions: {
            load: getWholesalerArticleQuantities,
        },
    })
    // TODO: Since costEstimation is now part of the basket bundle, could the registered models that start with Basket_CostEstimation be removed from nexus?
    Container.register({
        name: RegisteredModels.Basket_CostEstimation_ShowTotalNumbersByWorkTask,
        modelActions: {
            load: BasketCostEstimationRepository.showTotalNumbersByWorkTask,
        },
    })
}

export function getBasketServiceUrl(): string {
    return getBundleParams().basketServiceUrl
}

export function getDmsServiceUrl(): string {
    return getBundleParams().dmsServiceUrl
}
