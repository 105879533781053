import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Text, Card, WarningPrompt } from "@tm/controls"
import { useDeleteCompilations } from "../../../../../data/hooks/useCompilations"

type Props = {
    allSelected: boolean
    selectedCompilationIds: Array<string>
    onSelectAll(): void
    onUnselectAll(): void
}

export default function CompilationsSelection(props: Props) {
    const { translateText } = useLocalization()
    const dialogRef = useRef<WarningPrompt>(null)
    const { deleteCompilations } = useDeleteCompilations()

    const handleWarningConfirm = () => {
        deleteCompilations(props.selectedCompilationIds)
    }

    const handleRemoveAll = () => {
        dialogRef.current?.show()
    }

    return (
        <Card className="card-compilations-selection">
            <Text>
                <Text size="s" className="selection">
                    {translateText(701)}:
                </Text>
                <Text size="s" className="numbers">
                    {props.selectedCompilationIds.length} {translateText(6)}
                </Text>
            </Text>
            <Button onClick={props.allSelected ? props.onUnselectAll : props.onSelectAll} size="s">
                {props.allSelected ? translateText(703) : translateText(702)}
            </Button>
            <Button onClick={handleRemoveAll} size="s" icon="delete" layout={["ghost"]} />
            <WarningPrompt
                text={translateText(1313)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={dialogRef}
                onConfirm={handleWarningConfirm}
            />
            <Button skin="primary" className="cancel" icon="cancel-x" size="l" title={translateText(317)} onClick={props.onUnselectAll} />
        </Card>
    )
}
