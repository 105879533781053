import { em, margin } from "csx"
import { useEffect, useMemo, useRef, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { bem, classes } from "@tm/utils"
import { EFilterNames, TyreFilter } from "@tm/models"
import { ISummaryFilters } from "../summary/business"
import CustomDropdown from "./customDropdown"
import { IWidgetFilters } from "../../hooks/widgetFilters/model"

type Props = {
    selectedWidth?: TyreFilter
    selectedHeight?: TyreFilter
    selectedInch?: TyreFilter
    widths: TyreFilter[]
    heights: TyreFilter[]
    inches: TyreFilter[]
    loading?: boolean
    className?: string
    maxItemsToShow?: number
    title?: string
    onResetAll?: () => void
    onSearch?: () => void
    onChange(filterName: IAllFilters, value: TyreFilter): void
}

type FocusedIndex = 0 | 1 | 2 | 3

export type IAllFilters = Partial<ISummaryFilters | IWidgetFilters>

export default function DiameterFilters({ heights, inches, onChange, widths, className, onSearch, onResetAll, loading, maxItemsToShow, selectedHeight, selectedInch, selectedWidth, title }: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])
    const [focusedDropdown, setFocusedDropdown] = useState<FocusedIndex>(0)
    const submitButtonRef = useRef<Button | null>(null)

    useEffect(() => {
        if (focusedDropdown == 2 && heights.length == 1) {
            setFocusedDropdown(3)
        }

        if (focusedDropdown == 3 && inches.length == 1) {
            setFocusedDropdown(0)
        }
    }, [heights, inches])

    const focusDropdown = (i: FocusedIndex) => setFocusedDropdown(i)

    const handleChange = (filterName: IAllFilters, filter: TyreFilter) => {
        const value = filter?.query
        
        switch (filterName) {
            case EFilterNames.width:
                if (value != selectedWidth?.value) {
                    onChange(filterName, filter)
                }
                focusDropdown(2)
                break
            case EFilterNames.height:
                if (value != selectedHeight?.value) {
                    onChange(filterName, filter)
                }
                focusDropdown(3)
                break
            case EFilterNames.inch:
                if (value != selectedInch?.value) {
                    onChange(filterName, filter)
                }
                focusDropdown(0)
                setTimeout(() => {
                    submitButtonRef?.current?.focus()
                }, 300)
                break
            default:
                onChange(filterName, filter)
                break
        }
    }

    const handleOnResetAll = () => {
        onResetAll?.()
        focusDropdown(0)
    }

    return (<>
        <div className={classes(className, style.toolbar)}>
            <CustomDropdown
                filterName={EFilterNames.width}
                isActive={focusedDropdown == 1}
                className={style.dropdown}
                loading={loading}
                onDropdownClick={() => { focusDropdown(1) }}
                defaultItem={translateText(713)}
                items={widths}
                onChange={handleChange}
                selectedValue={selectedWidth}
                withResetButton={false}
                hasInput={true}
                label={translateText(209)}
                maxItemsToShow={maxItemsToShow}
                submitOnTab
            />

            <CustomDropdown
                filterName={EFilterNames.height}
                isActive={focusedDropdown == 2}
                onDropdownClick={() => { focusDropdown(2) }}
                className={bem(style.dropdown, "height")}
                loading={loading}
                disabled={!selectedWidth}
                defaultItem={translateText(714)}
                items={heights}
                onChange={handleChange}
                selectedValue={selectedHeight}
                withResetButton={false}
                hasInput={true}
                label={translateText(209)}
                maxItemsToShow={maxItemsToShow}
                submitOnTab
            />

            <CustomDropdown
                filterName={EFilterNames.inch}
                isActive={focusedDropdown == 3}
                onDropdownClick={() => { focusDropdown(3) }}
                loading={loading}
                className={style.dropdown}
                disabled={!selectedWidth}
                defaultItem={translateText(715)}
                items={inches}
                onChange={handleChange}
                selectedValue={selectedInch}
                withResetButton={false}
                hasInput={true}
                label={translateText(209)}
                maxItemsToShow={maxItemsToShow}
                submitOnTab
            />
            {onResetAll &&
                <Button
                    layout={["ghost"]}
                    disabled={!selectedHeight && !selectedInch && !selectedWidth}
                    onClick={handleOnResetAll}
                    size="s"
                    icon="synchronize"
                />
            }
            {onSearch &&
                <Button
                    ref={submitButtonRef}
                    skin="highlight"
                    icon="search"
                    className={style.saveButton}
                    onClick={onSearch}
                    disabled={!selectedHeight && !selectedInch && !selectedWidth}
                >
                    {translateText(135)}
                </Button>
            }
        </div>
    </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        toolbar: {
            marginBottom: theme.margin.m,
            display: "flex",
        },
        dropdown: {

            // width: em(5.4),//TODO
            flex: 1,
            // border: border({ color: "#d4dadf", style: "solid", width: px(1) }),
            borderRadius: theme.box.radius,
            $nest: {
                "&--height": {
                    margin: margin(0, theme.margin.s)
                },
                ".btn": {
                    padding: theme.margin.m
                },
            }
        },
        saveButton: {
            flex: 1,
            marginLeft: em(.5),
            $nest: {
                "&:focus": {
                    border: "1px solid " + theme.colors["primary-active"]
                }
            }
        }
    })(DiameterFilters)
}
