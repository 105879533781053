import { stylesheet } from "typestyle"
// import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    // const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            display: "flex",
            alignItems: "center",
        },
        pretext: {
            marginRight: "0.2rem",
        },
        icon: {
            marginRight: "0.2rem",
            flex: "0 0 auto",
        },
        text: {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
