import { CarModelDetailsResponse, channel, Customer, RegisteredModels, Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { MainState } from "../components/main"
import { MainActions, MainActionsType } from "../components/main/business"
import { DatSelectionSteps } from "../data/enums"
import { useContainer } from "../data/repositories"

const setCustomerRegistrationStatus = (value: boolean): MainActionsType => ({ type: "SET_CUSTOMER_REGISTRATION_STATUS", payload: value })

const acceptConditions = (): MainActionsType => ({ type: "ACCEPT_CONDITIONS" })

const checkCustomer =
    (translateText?: (key: string | number) => string): AsyncAction<MainActionsType> =>
    (dispatch) => {
        dispatch({ type: "CHECK_CUSTOMER_LOADING" })

        useContainer()
            .action("checkCustomer")()
            .then(
                (response) => {
                    if (response.userMustEnterCredentials || !response.userIsRegistered) {
                        dispatch(MainActions.resetStore())
                    }

                    dispatch({
                        type: "CHECK_CUSTOMER",
                        payload: {
                            userIsRegistered: !!response.userIsRegistered,
                            agbUrl: response.agbUrl,
                            userMustEnterCredentials: !!response.userMustEnterCredentials,
                        },
                    })

                    if (response.userIsRegistered) {
                        translateText &&
                            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12883), closeDelay: 3000, skin: "success" })
                    }
                },
                () => {
                    translateText && channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12591), closeDelay: 3000, skin: "danger" })

                    dispatch(MainActions.resetStore())
                    dispatch({ type: "CHECK_CUSTOMER_ERROR" })
                }
            )
    }

const setVehicle = (vehicle: Vehicle): MainActionsType => ({ type: "VEHICLE_SET", payload: vehicle })

const setCustomer = (customer: Customer): MainActionsType => ({ type: "CUSTOMER_SET", payload: customer })

const loadVehicleDetails =
    (vehicle: Vehicle): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        const container = Container.getInstance<CarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
        container
            .subscribe({ modelId: vehicle.tecDocTypeId, registrationNoTypeId: vehicle.registrationTypeId })
            .load()
            .then((response) => dispatch({ type: "VEHICLE_DETAILS_LOADED", payload: response }))
    }

const openHistoryCalculation =
    (newContractId: string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        dispatch({ type: "OPEN_HISTORY_CALCULATION", payload: newContractId })
        dispatch({
            type: "CHANGE_STEP",
            payload: { selectedStep: DatSelectionSteps.CALCULATION, params: { contractId: newContractId }, disableNext: true },
        })
    }

const resetStore = (): MainActionsType => ({ type: "@@redux/INIT" })

export const ManagerActions = {
    setCustomerRegistrationStatus,
    acceptConditions,
    checkCustomer,
    setVehicle,
    setCustomer,
    loadVehicleDetails,
    openHistoryCalculation,
    resetStore,
}
