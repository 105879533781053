import { Icon } from "@tm/controls"

type IconComponentProps = {
    name: string
    size?: "xl" | "l" | "m" | "s" | "xs"
}

function IconComponent(props: IconComponentProps) {
    return <Icon name={props.name} size={props.size} />
}

export default IconComponent
