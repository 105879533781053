import { useEffect, useState, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { useCisReturnItemsTotalNumbers, useShowCisOptions } from "@tm/context-distribution"
import { CisCustomerVoucherType } from "@tm/models"
import { Badge, Box, LinkTab, Loader, MenuItemWidget, Select, Stack, Tabs, Typography, styled } from "@tm/components"
import { Link, useHistory, useLocation, useParams } from "react-router-dom"

type NavigationVoucherType = CisCustomerVoucherType & {
    showItemNumbers?: boolean
}

type ComponentRouteProps = {
    workTaskId: string
    voucherTypeId?: string
    subpage?: string
}

type Props = {
    rightTabs?: Array<NavigationVoucherType>
    subpageRoute: string
}

type MenuItem = {
    id: string
    label: string
    target: string
    subMenu?: Array<MenuItem>
    showItemNumbers?: boolean
}

const StyledTabs = styled(Tabs)({
    "& .MuiTab-root": {
        margin: "7px 4px 4px 8px",
    },

    "& .MuiTabs-indicator": {
        bottom: "4px",
    },
})

export default function CisAccountNavigationComponent({ rightTabs, subpageRoute }: Props) {
    const { translateText } = useLocalization()
    const { cisOptions } = useShowCisOptions()
    const { totalNumbers, totalNumbersLoading } = useCisReturnItemsTotalNumbers()
    const matchParams = useParams<ComponentRouteProps>()
    const location = useLocation()
    const history = useHistory()
    const [value, setValue] = useState(matchParams.voucherTypeId || matchParams.subpage)

    useEffect(() => {
        setValue(matchParams.voucherTypeId || matchParams.subpage)
    }, [matchParams.voucherTypeId, matchParams.subpage])

    const menuItems = useMemo(() => {
        function getMenuTarget(subpage: string, voucherTypeId?: number | string) {
            return renderRoute(subpageRoute, { ...matchParams, subpage, voucherTypeId })
        }

        const userDataMenuItem: MenuItem = {
            id: "cis-user-data",
            label: translateText(569),
            target: getMenuTarget("summary"),
        }

        const orderInfosSubMenuItems: Array<MenuItem> =
            cisOptions?.voucherTypes?.map((voucherType) => {
                return {
                    id: voucherType.typeId.toString(),
                    label: voucherType.description,
                    target: getMenuTarget("vouchers", voucherType.typeId),
                }
            }) || []

        if (cisOptions?.returnReasons) {
            orderInfosSubMenuItems.push({
                id: "returns",
                label: translateText(1515),
                target: getMenuTarget("returns"),
                showItemNumbers: true,
            })
        }

        if (rightTabs) {
            rightTabs.forEach((tab) => {
                orderInfosSubMenuItems.push({
                    id: tab.typeId.toString(),
                    label: translateText(tab.description),
                    target: getMenuTarget("vouchers", tab.typeId),
                    showItemNumbers: tab.showItemNumbers,
                })
            })
        }

        const orderInfoMenuItem: MenuItem = {
            id: "cis-order-info",
            label: translateText(1501),
            target: orderInfosSubMenuItems[0]?.target || getMenuTarget("orderinfo"),
            subMenu: orderInfosSubMenuItems,
        }

        const tourInfoMenuItem: MenuItem = {
            id: "cis-tour-info",
            label: translateText(1502),
            target: getMenuTarget("tours"),
        }

        return [userDataMenuItem, orderInfoMenuItem, tourInfoMenuItem]
    }, [cisOptions?.returnReasons, cisOptions?.voucherTypes, matchParams, subpageRoute, rightTabs, translateText])

    const activeDropdownItem = useMemo(() => {
        return (
            menuItems.find((item) => {
                let isActive = location.pathname === item.target

                if (!isActive && item.subMenu) {
                    isActive = item.subMenu.some((subItem) => subItem.target && location.pathname.indexOf(subItem.target) === 0)
                }

                return isActive
            }) || menuItems[0]
        )
    }, [menuItems, location.pathname])

    function handleDropdownChange(item: MenuItem) {
        history.push(item.target)
    }

    function renderSubMenuItem(menuItem: MenuItem) {
        const returnsBadgeProps =
            menuItem.id === "returns" && totalNumbers?.numberOfPositions !== 0
                ? {
                      icon: (
                          <Badge
                              size="small"
                              badgeContent={totalNumbersLoading ? <Loader size="extrasmall" /> : totalNumbers?.numberOfPositions}
                              sx={{ bottom: "12px", left: "8px" }}
                          />
                      ),
                      iconPosition: "end" as "end" | "bottom" | "top" | "start" | undefined,
                  }
                : {}

        return (
            <LinkTab
                component={Link}
                value={menuItem.id}
                key={menuItem.id}
                label={menuItem.label}
                to={menuItem.target}
                {...returnsBadgeProps}
                disableRipple
            />
        )
    }

    return (
        <Stack direction="row" spacing={2}>
            <Typography variant="h1" pt="14px">
                {translateText(500)}
            </Typography>
            {menuItems && (
                <Box pt="14px">
                    <Select
                        value={activeDropdownItem}
                        onChange={(e) => handleDropdownChange(e.target.value as MenuItem)}
                        renderValue={(val) => (val as MenuItem).label}
                        variant="outlined"
                    >
                        {menuItems.map((item) => {
                            return (
                                <MenuItemWidget key={item.label} value={item}>
                                    {item.label}
                                </MenuItemWidget>
                            )
                        })}
                    </Select>
                </Box>
            )}

            <StyledTabs value={value}>{!!activeDropdownItem?.subMenu?.length && activeDropdownItem.subMenu.map(renderSubMenuItem)}</StyledTabs>
        </Stack>
    )
}
