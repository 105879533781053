import { BoxProps, LoadingContainer, styled, Typography } from "@tm/components"
import { RefObject, useCallback, useEffect, useState } from "react"
import { FastService } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { useFileInfo } from "../../hooks/useFileInfo"

type Props = {
    imageId: string
    imageUrl?: string
    imageRef?: RefObject<HTMLImageElement>
} & BoxProps

export function ERIKImage({ imageId, imageUrl, imageRef, ...boxProps }: Props) {
    const { translateText } = useLocalization()
    const [url, setUrl] = useState(imageUrl)
    const [imageLoading, setImageLoading] = useState(true)

    const fileFetched = useCallback((data: FastService.GetFileInfoResponse) => {
        setImageLoading(true)
        setUrl(data.fileMeta?.thumbnail?.url)
    }, [])

    const { isLoading: fetchFileLoading, getFileInfo, isError } = useFileInfo(imageId, fileFetched)

    const isLoading = fetchFileLoading || imageLoading

    const refetchImage = () => {
        getFileInfo()
    }

    useEffect(() => {
        if (!imageUrl) {
            refetchImage()
        }
    }, [imageUrl])

    return (
        <LoadingContainer isLoading={isLoading && !isError} {...boxProps}>
            {isError && <Typography variant="body1">{translateText(13841)}</Typography>}
            {!isError && (
                <StyledImg
                    crossOrigin="anonymous"
                    isLoading={!url || isLoading}
                    src={url}
                    ref={imageRef}
                    onLoad={() => setImageLoading(false)}
                    onError={refetchImage}
                />
            )}
        </LoadingContainer>
    )
}

const StyledImg = styled("img", {
    shouldForwardProp: (prop) => prop !== "isLoading",
})<{ isLoading?: boolean }>(({ isLoading }) => ({
    maxWidth: "30em",
    maxHeight: "21em",
    ...(isLoading && {
        visibility: "hidden",
    }),
}))
