import { ETyresCarType, VehicleType } from "@tm/models";

export function mapVehicleTypeToTyresCarType(vehicleType: VehicleType | undefined): ETyresCarType {
    switch (vehicleType) {
        case VehicleType.Undefined:
            return ETyresCarType.NONE
        case VehicleType.PassengerCar:
            return ETyresCarType.PKW
        case VehicleType.CommercialVehicle:
            return ETyresCarType.LKW
        case VehicleType.LightCommercialVehicle:
            return ETyresCarType.LLKW
        case VehicleType.Motorcycle:
            return ETyresCarType.Motorrad
        case VehicleType.Engine:
        case VehicleType.Axle:
        case VehicleType.Universal:
        case VehicleType.DriversCab:
        case VehicleType.GearBox:
        case VehicleType.All:
            return ETyresCarType.PKW
        default:
            return ETyresCarType.PKW
    }
}