import { useStyle } from "@tm/context-distribution"
import { FC, ReactNode, memo } from "react"

type RenderPageProps = { height?: number; children: ReactNode }

const RenderPages: FC<RenderPageProps> = memo(({ height, children }) => {
    return (
        <div style={{ width: "100%", height }} className={style.page}>
            {children}
        </div>
    )
})

export default RenderPages

const style = useStyle({
    page: {
        overflow: "hidden",
        display: "flex",
        boxSizing: "border-box",
        flexDirection: "column",
    },
})(RenderPages)

function isEq(prevProps: RenderPageProps, nextProps: RenderPageProps) {
    return nextProps === prevProps
}
