import { Button, Checkbox, Icon, Typography, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { DATWork } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useSelector } from "react-redux"
import { useCallback, useState } from "react"
import { calculationSelector } from "../../../../selectors"
import { MainActions } from "../../../main/business"
import { IncludedWorks } from "./components"

export default function WorkList() {
    const { translateText, currency, number } = useLocalization()
    const user = useUser()
    const { works } = useSelector(calculationSelector)
    const currencyString = getCurrencyFromUserContext(user?.userContext)
    const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({})

    const actions = useActions(MainActions, "selectWork")

    const renderIncludes = (work: DATWork) => {
        return (
            <Table.Cell>
                <Button
                    startIcon={<Icon name={isExpanded[work.providerWorkId + work.description] ? "up" : "down"} />}
                    size="small"
                    disabled={!work.includedWorks?.length}
                    variant="text"
                    onClick={handleCollapse(work)}
                    sx={{ minWidth: 0, padding: 0 }}
                />
            </Table.Cell>
        )
    }

    const handleCollapse = useCallback(
        (work: DATWork) => () =>
            setIsExpanded((state) => ({ ...state, [work.providerWorkId + work.description]: !state[work.providerWorkId + work.description] })),
        []
    )

    const renderProviderId = (work: DATWork) => (
        <Table.Cell>
            {work.providerWorkId.split(/(?=[+])/).map((providerWorkId) => (
                <Typography key={providerWorkId}>{providerWorkId}</Typography>
            ))}
        </Table.Cell>
    )

    const renderDescription = (work: DATWork) => (
        <Table.Cell>
            <Typography>{work.description} </Typography>
        </Table.Cell>
    )

    const renderHourlyRate = (work: DATWork) => (
        <Table.Cell>
            <Typography>{currency(work.hourlyRate, currencyString)} </Typography>
        </Table.Cell>
    )

    const renderRtUnitValue = (work: DATWork) => (
        <Table.Cell>
            <Typography>{number(work.rtUnit ?? 0, 2)}</Typography>
        </Table.Cell>
    )

    const renderRtValue = (work: DATWork) => (
        <Table.Cell>
            <Typography>{number(work.time, 2)}</Typography>
        </Table.Cell>
    )

    const renderTotal = (work: DATWork) => (
        <Table.Cell>
            <Typography>{currency(work.total, currencyString)}</Typography>
        </Table.Cell>
    )

    const renderCheckbox = (work: DATWork) => (
        <Table.Cell>
            <Checkbox checked={work.isSelected} onChange={() => actions.selectWork(translateText, work)} />
        </Table.Cell>
    )

    const renderIncludedWorks = (work: DATWork) => {
        return <Table.Cell>{work.includedWorks && <IncludedWorks includedWorks={work.includedWorks} showSupplierArticleNumbers />}</Table.Cell>
    }

    const repairTimeDivision = user?.userSettings?.repairTimeOptions?.division ?? 1
    const rtValueTitle = repairTimeDivision === 1 ? translateText(84) : translateText(1550)

    const columns = [
        <Table.Column key="includes" className="includes" renderItemContent={renderIncludes} />,
        <Table.Column key="number" className="number" renderItemContent={renderProviderId}>
            {translateText(58)}
        </Table.Column>,
        <Table.Column key="description" className="description" renderItemContent={renderDescription}>
            {translateText(617)}
        </Table.Column>,
        <Table.Column key="hourly-rate price" className="hourly-rate price" renderItemContent={renderHourlyRate}>
            {translateText(622)}
        </Table.Column>,
        repairTimeDivision !== 1 && (
            <Table.Column key="rt-value time" className="rt-value time" renderItemContent={renderRtUnitValue}>
                {translateText(1723)}
            </Table.Column>
        ),
        <Table.Column key="rt-value time" className="rt-value time" renderItemContent={renderRtValue}>
            {rtValueTitle}
        </Table.Column>,
        <Table.Column key="price sum" className="price sum" renderItemContent={renderTotal}>
            {translateText(57)}
        </Table.Column>,
        <Table.Column key="actions" className="actions" renderItemContent={renderCheckbox} />,
        <Table.Column key="expandable-row" className="expandable-row" renderItemContent={renderIncludedWorks} />,
    ]

    return (
        <StyledTable
            data={works}
            columns={columns.filter(Boolean) as any}
            getRowClassName={(work: DATWork) => (isExpanded[work.providerWorkId + work.description] ? "is-expanded" : "")}
        />
    )
}

const StyledTable = styled(Table)(({ theme }) => ({
    ".fancy-list__body": {
        margin: theme.spacing(0, 1),
    },
    ".fancy-list__head, .fancy-list__item": {
        "&.is-expanded": {
            flexWrap: "wrap",
            ".expandable-row": {
                display: "block",
            },
        },
        ".expandable-row": {
            display: "none",
        },

        ".includes": {
            flex: "0 0 1.6rem",
        },
        ".provider, .category": {
            flex: "0 0 5.2em",
            justifyContent: "flex-start",
        },

        ".price": {
            flex: "0 0 5.7rem",
            textAlign: "right",

            "&.sum": {
                display: "flex",
                flex: "0 0 7.5rem",
                paddingLeft: "1.5em",
                justifyContent: "flex-end",
            },
        },

        ".time": {
            flex: "0 0 5.3rem",
            textAlign: "right",
            whiteSpace: "normal",
        },

        ".actions": {
            flex: "0 0 1.5rem",
        },

        ".type, .note": {
            flex: "0 0 1.2rem",
        },

        ".number": {
            flex: "0 0 9rem",
            display: "flex",
            flexDirection: "column",
        },
        ".description": {
            flex: 1,
        },
    },
}))
