import { encodeUniqueId, uniqueId } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenInternalModule(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { history } = this.props
    const { openInternalModule } = data

    if (openInternalModule && openInternalModule.module) {
        const { newWorkTask, workTaskId, module } = openInternalModule
        if (!newWorkTask && !workTaskId) {
            history.push(`/r/${module}`)
        } else {
            const id = encodeUniqueId(workTaskId || uniqueId())
            history.push(`/${id}/${module}`)
        }
    }
}
