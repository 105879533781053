const base64toBlob = (data: string, contentType: string) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substring(`data:${contentType};base64,`.length)

    const bytes = atob(base64WithoutPrefix)
    let { length } = bytes
    const out = new Uint8Array(length)

    while (length--) {
        out[length] = bytes.charCodeAt(length)
    }

    return new Blob([out], { type: contentType })
}

export function base64ToObjectUrl(data: string) {
    const mimeType = getMimeType(data)
    if (mimeType) {
        return URL.createObjectURL(base64toBlob(data, mimeType))
    }
}

export function getMimeType(data: string) {
    const result = /data:(\w+\/[-+.\w]+);base64,/.exec(data)
    if (result) {
        return result[1]
    }
}
