import { Article } from "@tm/models"
import { notUndefinedOrNull, uniqueId, useErpConfig } from "@tm/utils"
import { useRecoilState } from "recoil"
import { useEffect, useMemo, useState } from "react"
import { entries, uniqBy } from "lodash"
import { QueryStatus } from "react-query"
import { SupplierArticleWithId, loadMultiWholesalerArticleNosByTraderId } from "../../../data/repositories"
import { tradeReferenceNumbersState } from "../states"

export type TradeReferenceNumber = {
    erpSystemId: number
    tradeReferenceId: number
    traderArticleNumber: string
}
export type ArticleTradeReferences = [SupplierArticleWithId, TradeReferenceNumber[]]

export function useTradeReferences(articles: Article[], enabled: boolean) {
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const [status, setStatus] = useState<QueryStatus>("idle")
    const [tradeReferenceNumbers, setTradeReferenceNumbers] = useRecoilState(tradeReferenceNumbersState)

    const tradeReferenceIds = useMemo(() => {
        if (erpSystemConfigs) {
            const ids = erpSystemConfigs.map((x) => x.tradeReferenceId).filter(notUndefinedOrNull)
            const uniqueIds = uniqBy(ids, (id) => id)
            return uniqueIds
        }
    }, [erpSystemConfigs])

    useEffect(() => {
        const erpSystemId = erpSystemConfigs?.[0].id
        const tradeReferenceId = erpSystemConfigs?.[0].tradeReferenceId
        // When MRE is not active
        if (!useOrderByDistributor && erpSystemId && tradeReferenceId) {
            setTradeReferenceNumbers(() => {
                return articles.map((article) => {
                    return [
                        { supplierId: article.supplier.id, supplierArticleNo: article.supplierArticleNo, id: article.id },
                        [
                            {
                                erpSystemId,
                                tradeReferenceId,
                                traderArticleNumber: article.traderArticleNo,
                            },
                        ],
                    ]
                })
            })
            return
        }

        setTradeReferenceNumbers((prev) => {
            const requests: SupplierArticleWithId[] = uniqBy(
                articles
                    .filter(
                        (article) =>
                            !prev.some(([key]) => key.supplierArticleNo === article.supplierArticleNo && key.supplierId === article.supplier.id)
                    )
                    .map((article) => ({
                        supplierId: article.supplier.id,
                        supplierArticleNo: article.supplierArticleNo,
                        id: uniqueId(),
                    })),
                (x) => [x.supplierArticleNo, x.supplierId].join("|")
            )
            if (requests) {
                return [...prev, ...requests.map((request) => [request, undefined] as [SupplierArticleWithId, undefined])]
            }
            return prev
        })
    }, [articles, erpSystemConfigs, setTradeReferenceNumbers, useOrderByDistributor])

    useEffect(
        function loadData() {
            const fetchData = async (requests: SupplierArticleWithId[]) => {
                if (!tradeReferenceIds?.length) {
                    // Set status to success, so the erp infos are also loaded for traders without a TradeReferenceId
                    setStatus("success")
                    return
                }
                setStatus("loading")
                try {
                    const response = await loadMultiWholesalerArticleNosByTraderId(requests, tradeReferenceIds)
                    setTradeReferenceNumbers((prev) => {
                        let loadedData = prev
                        entries(response.wholesalerArticleNosDict).forEach(([articleId, articleResponse]) => {
                            const loadedKey = loadedData.find(([key]) => key.id === articleId)?.[0]

                            if (loadedKey) {
                                const entryNumbers: { erpSystemId: number; tradeReferenceId: number; traderArticleNumber: string }[] = []
                                entries(articleResponse).forEach(([tradeReferenceIdString, traderArticleNumber]) => {
                                    const tradeReferenceId = parseInt(tradeReferenceIdString) || 0
                                    const erpSystemIds = erpSystemConfigs
                                        ?.filter((x) => x.tradeReferenceId === tradeReferenceId)
                                        .map((erpConfig) => erpConfig.id)
                                    erpSystemIds?.forEach((erpSystemId) => {
                                        entryNumbers.push({
                                            erpSystemId,
                                            tradeReferenceId,
                                            traderArticleNumber,
                                        })
                                    })
                                })

                                loadedData = [[loadedKey, entryNumbers], ...loadedData.filter((x) => x[0] !== loadedKey)]
                            }
                        })

                        const hasNotFoundEntries = loadedData.some(([, value]) => !value)
                        if (hasNotFoundEntries) {
                            loadedData = loadedData.map(([key, value]) => [key, value ?? "NOT_FOUND"])
                        }

                        return loadedData
                    })
                    setStatus("success")
                } catch {
                    setStatus("error")
                }
            }
            const newRequests = tradeReferenceNumbers.filter(([, numbers]) => !numbers).map(([key]) => key)
            if (enabled && erpSystemConfigs && tradeReferenceNumbers.length) {
                if (newRequests.length) {
                    fetchData(newRequests)
                } else {
                    // Data already loaded -> set status to success
                    setStatus("success")
                }
            }
        },
        [tradeReferenceNumbers, tradeReferenceIds, enabled, erpSystemConfigs, setTradeReferenceNumbers]
    )

    const tradeReferences = useMemo(
        () => tradeReferenceNumbers.filter(([, value]) => value !== "NOT_FOUND") as ArticleTradeReferences[],
        [tradeReferenceNumbers]
    )

    return { tradeReferences, tradeReferenceNumbersLoaded: status === "success" || status === "error" }
}
