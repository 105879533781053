import { CisVoucherType } from "@tm/models"
import { Box } from "@tm/components"
import { useParams } from "react-router"
import { Voucher } from "../../../../data/model"
import WmOldPartsTable from "./voucher-tables/WmOldPartsTable"
import WmStandardVouchersTable from "./voucher-tables/WmStandardVouchersTable"
import WmVouchersDetails from "./WmVoucherDetails"
import { RowStackWithPadding, VerticalDivider } from "../../../_shared/StyledComponents"
import { CALCULATED_HEIGHT, COMPACT_WIDTH, FULL_WIDTH, VoucherRouteProps } from "../../component"

type Props = {
    openedConnectedVouchers: Array<string>
    voucherTypeId: number
    vouchers: Array<Voucher>
    endOfList: boolean
    loadNextPage(): void
    handleExpandRowClick(voucherId: string): void
}

export default function WmVouchersComponent(props: Props) {
    const { voucherTypeId, vouchers, openedConnectedVouchers, endOfList } = props
    const matchParams = useParams<VoucherRouteProps>()
    const selectedVoucherId = matchParams.id ? decodeURIComponent(matchParams.id) : undefined
    let table = <></>
    switch (voucherTypeId) {
        case CisVoucherType.OldParts:
            table = (
                <WmOldPartsTable
                    compact={!!matchParams.id}
                    vouchers={vouchers}
                    openedConnectedVouchers={openedConnectedVouchers}
                    voucherTypeId={voucherTypeId}
                    selectedVoucherId={selectedVoucherId}
                    variant={matchParams.id ? "small" : "normal"}
                    onExpandRowClick={props.handleExpandRowClick}
                    loadNextPage={props.loadNextPage}
                />
            )
            break
        default:
            table = (
                <WmStandardVouchersTable
                    vouchers={vouchers}
                    compact={!!matchParams.id}
                    voucherTypeId={voucherTypeId}
                    selectedVoucherId={selectedVoucherId}
                    variant={matchParams.id ? "small" : "normal"}
                    loadNextPage={props.loadNextPage}
                />
            )
            break
    }

    return (
        <RowStackWithPadding divider={<VerticalDivider />}>
            <Box width={matchParams.id ? COMPACT_WIDTH : FULL_WIDTH} height={CALCULATED_HEIGHT}>
                {table}
            </Box>
            {!!matchParams.id && (
                <WmVouchersDetails
                    endOfList={endOfList}
                    selectedVoucherId={decodeURIComponent(matchParams.id)}
                    vouchers={vouchers as Array<Voucher>}
                    voucherTypeId={voucherTypeId}
                />
            )}
        </RowStackWithPadding>
    )
}
