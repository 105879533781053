import { UserContext } from "@tm/models"
import { useQuery } from "react-query"
import { createWidgetModules } from "../helpers/createWidgetModules"
import { AllModulesKeys, ItemPosition, ModuleKeys, WidgetBaseProp } from "../helpers/models"

export function useWidgetModules(
    userContext: UserContext,
    position: ItemPosition,
    overrides?: Partial<Record<AllModulesKeys, WidgetBaseProp<ModuleKeys>>>
) {
    const { data, isFetched } = useQuery(
        [`${position}_widgetModules`],
        () => {
            const widgetModules = createWidgetModules(userContext, position, overrides)

            const widgetModuleList = Object.values(widgetModules).sort((a, b) => (a.sort <= b.sort ? -1 : 1))

            return { widgetModules, widgetModuleList }
        },
        { enabled: !!userContext, staleTime: Infinity, cacheTime: Infinity, refetchOnMount: false, suspense: true, refetchOnWindowFocus: false }
    )

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { widgetModules: data!.widgetModules, widgetModuleList: data!.widgetModuleList, loaded: isFetched }
}
