import { useState, useEffect } from "react"
import { Link } from "react-router-dom"

import { useWorkTask } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { Box, Typography, Grid, Paper, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CustomerVehicleShortInfo, ECounterType, RegistrationNoType, VehicleType } from "@tm/models"
import { withRouter, decodeUniqueId, renderRoute, TmaHelper, RouteComponentProps } from "@tm/utils"

import Morpheus from "@tm/morpheus"
import { getBundleParams } from "../../utils"
import { detachVehicle } from "../../data/repositories/customer"
import { showCustomerVehicles } from "../../data/repositories/vehicle"
import VehicleCard from "./components/vehicle-card"
import { bundleChannel } from "../../business"
import { useApplyButtonState } from "../../data/hooks/useApplyButtonState"

type Props = RouteComponentProps<{ customerId: string }>

function CustomerVehiclesComponent(props: Props) {
    const { translate } = useLocalization()
    const { attachToWorkTask } = useWorkTask() ?? {}
    const customerId = decodeUniqueId(props.match.params.customerId)!

    const [state, setState] = useState<{ loading: boolean; vehicles: Array<CustomerVehicleShortInfo> }>({ loading: false, vehicles: [] })
    const { dataChanged } = useApplyButtonState()

    useEffect(() => {
        setState({ loading: true, vehicles: [] })
        let cancelled = false
        showCustomerVehicles(customerId).then((vehicles) => {
            if (!cancelled && setState) {
                setState({ loading: false, vehicles })
            }
        })
        return () => {
            cancelled = true
        }
    }, [customerId])

    const handleDetachVehicle = (vehicle: CustomerVehicleShortInfo) => {
        detachVehicle(vehicle.customerId, vehicle.id, vehicle.version).then(() =>
            setState({ loading: false, vehicles: state.vehicles.filter((v) => v.id !== vehicle.id) })
        )
    }

    const handleApplyVehicle = (vehicle: CustomerVehicleShortInfo) => {
        if (dataChanged) {
            bundleChannel().publish("APPLY_CHANGES", { vehicle: vehicle.id })
        } else {
            TmaHelper.VehicleSelection.Apply(RegistrationNoType.VehicleBase)
            attachToWorkTask?.({ customer: vehicle.customerId, vehicle: vehicle.id })
            if (Morpheus.anyOpenView("1")) {
                Morpheus.closeView("1")
            } else {
                props.history.push(renderRoute("/:workTaskId", props.match.params))
            }
        }
    }

    const handleAttach = () => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerAddVehicle)
        attachToWorkTask?.({ customer: customerId })
    }

    const vehicleUrl = renderRoute(getBundleParams().vehiclesRoute, { ...props.match.params, vehicleType: VehicleType.PassengerCar })

    return (
        <Box m={2} className="tk-crm customer-vehicles">
            <Typography variant="h3" mb={1}>
                {translate(147)}
            </Typography>
            <Grid container spacing={1}>
                {state.vehicles.map((vehicle) => (
                    <Grid item container xs={6} md={3} key={vehicle.id}>
                        <VehicleCard vehicle={vehicle} handleApplyVehicle={handleApplyVehicle} handleDetachVehicle={handleDetachVehicle} />
                    </Grid>
                ))}

                <Loader visible={state.loading} />

                {!getBundleParams().nextLight && (
                    <Grid item container xs={6} md={3}>
                        <Paper
                            className="card--add"
                            sx={{
                                display: "flex",
                                position: "relative",
                                width: "100%",
                                border: "2px dashed #c3c3c3",
                                background: "none",
                                minHeight: "180px",
                                boxShadow: "none",
                            }}
                        >
                            <Link to={vehicleUrl} onClick={handleAttach}>
                                <Icon name="car" />
                                <span>{translate(457)}</span>
                            </Link>
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}

export default withRouter(CustomerVehiclesComponent)
