import { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, Icon, Loader, Typography } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Switch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CarModel, Customer, RegistrationNoType, VehicleShortInfo, VehicleType } from "@tm/models"
import {
    ModelState,
    classes,
    createQueryString,
    encodeUniqueId,
    isLicensePlateType,
    isRegNoType,
    isVin,
    makeCancelable,
    renderRoute,
    uniqueId,
    useAvailableVehicleSearchOptions,
    validateField,
} from "@tm/utils"
import { createVehicleFromModel, deleteImportedVehicleDetails } from "../../../../business"
import { ModelsResponse, useSelectedVehicleLookup } from "../../../../data/hooks"
import { ShowVrcInformationResponse, VrcLookupModule } from "../../../../data/model/vrc-lookup"
import { showVrcInformation } from "../../../../data/repositories/vrc-lookup"
import { VrmLookupErrorTextIds } from "../../../../helpers"
import { vehicleDetailsSubPageDatInfo } from "../../../../helpers/routing"
import { isVrcModuleUsingKbaNumbers } from "../../../../helpers/vrc"
import { getBundleParams } from "../../../../utils"
import { VrcUploadedFile } from "../../component"
import SharedLayout from "../_shared-layout"
import FilePreview from "../file-preview"
import { RetryError } from "../retry-error"
import { CustomerForm } from "./forms/customer-form"
import { VehicleForm } from "./forms/vehicle-form"
import { getComponentStyles } from "./styles"
import VehicleRequest from "./vehicle-request"

type Props = {
    file: VrcUploadedFile
    module: VrcLookupModule
    modelResponse?: ModelsResponse
    resetFile(): void
}

export default function Result(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])

    const { translateText } = useLocalization()
    const [error, setError] = useState(false)
    const [vrcLookup, setVrcLookup] = useState<ShowVrcInformationResponse>()
    const { userSettings, setUserSetting } = useUser() ?? {}
    const [vehicleEnabled, setVehicleEnabled] = useState(true)
    const [customerEnabled, setCustomerEnabled] = useState(true)
    const [saving, setSaving] = useState(false)
    const [requestVehicles, setRequestVehicles] = useState(false)
    const [showNoResultError, setShowNoResultError] = useState(false)
    const [datVinRequestActive, setDatVinRequestActive] = useState(false)
    const { attachToWorkTask } = useWorkTask() || {}

    const vrcModuleIsUsingKbaNumbers = isVrcModuleUsingKbaNumbers(props.module)

    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const { selectedVehicleLookup } = useSelectedVehicleLookup(VehicleType.PassengerCar)

    const plateType = useMemo(() => {
        return availableVehicleSearches?.configuredVehicleLookups?.find((type) => isLicensePlateType(type.lookupTypeId))?.lookupTypeId
    }, [availableVehicleSearches?.configuredVehicleLookups])

    const regNoType = useMemo(() => {
        return availableVehicleSearches?.configuredVehicleLookups?.find((type) =>
            // Get KBA or the first registration number type
            vrcModuleIsUsingKbaNumbers ? type.lookupTypeId === RegistrationNoType.Kba : isRegNoType(type.lookupTypeId)
        )?.lookupTypeId
    }, [availableVehicleSearches?.configuredVehicleLookups, vrcModuleIsUsingKbaNumbers])

    const analyzeFile = useCallback(() => {
        setError(false)

        return makeCancelable(
            showVrcInformation({ fastUpImageId: props.file.fastUpId, moduleId: props.module.moduleId }, props.module)
                .then((response) => {
                    setVrcLookup(response)
                })
                .catch((ajaxError) => {
                    if (!ajaxError || !ajaxError.isCanceled) {
                        setError(true)
                    }
                })
        )
    }, [props.file.fastUpId, props.module])

    useEffect(() => {
        const cancelable = analyzeFile()
        return () => {
            cancelable.cancel()
        }
    }, [analyzeFile])

    useEffect(() => {
        setCustomerEnabled(userSettings?.vrcScanOptions.customerEnabled ?? true)
        setVehicleEnabled(userSettings?.vrcScanOptions.vehicleEnabled ?? true)
    }, [userSettings?.vrcScanOptions.customerEnabled, userSettings?.vrcScanOptions.vehicleEnabled])

    const toggleCustomerEnabled = useCallback(() => {
        setCustomerEnabled((prev) => !prev)
        setUserSetting("VRC_SCAN_OPTIONS", { customerEnabled: !customerEnabled, vehicleEnabled })
    }, [customerEnabled, vehicleEnabled, setUserSetting])

    const toggleVehicleEnabled = useCallback(() => {
        setVehicleEnabled((prev) => !prev)
        setUserSetting("VRC_SCAN_OPTIONS", { customerEnabled, vehicleEnabled: !vehicleEnabled })
    }, [customerEnabled, vehicleEnabled, setUserSetting])

    const { handleApplyButtonClick, applyVehicleAndCustomer, applyCustomerVehicle, modelState } = useMemo(() => {
        if (vrcLookup && (vehicleEnabled || customerEnabled)) {
            const { registrationNumber, vin, numberPlate, name } = vrcLookup.info
            const state: ModelState = {
                "info.vin":
                    vin && vehicleEnabled
                        ? validateField(vin)
                              .min(17, translateText(289))
                              .regExp(/[A-Za-z0-9]{17}/, translateText(12638)).messages
                        : [],
                "info.registrationNumber":
                    registrationNumber && vehicleEnabled
                        ? validateField(registrationNumber.replace(/ /g, "")).min(regNoType === RegistrationNoType.Kba ? 7 : 1, translateText(289))
                              .messages
                        : [],
                "info.numberPlate": [] as Array<string>,
                "info.name": customerEnabled ? validateField(name).min(2, translateText(289)).messages : [],
            }

            if (vehicleEnabled && !registrationNumber && !vin && !numberPlate) {
                state["info.vin"].push(translateText(892))
                state["info.registrationNumber"].push(translateText(892))
                state["info.numberPlate"].push(translateText(892))
            }

            if (Object.values(state).some((x) => !!x.length)) {
                return { modelState: state }
            }
            const applyCustomerVehicle = async (vehicle: VehicleShortInfo) => {
                await attachToWorkTask?.({ vehicle: vehicle.id, customer: vehicle.customerId, subPage: "/" })
            }

            const apply = async (model?: CarModel, datVinErrorTextId?: VrmLookupErrorTextIds) => {
                setSaving(true)

                let vehicle
                let subPage = "/"
                if (vehicleEnabled && model) {
                    let searchQuery = vin
                    if (model.dataSourceId === regNoType) {
                        searchQuery = registrationNumber
                    } else if (model.dataSourceId === plateType) {
                        searchQuery = numberPlate
                    }

                    const vehicleType = VehicleType.PassengerCar // TODO?

                    vehicle = await createVehicleFromModel({
                        model,
                        vehicleType,
                        initialRegistration: vrcLookup.info.registrationDate || undefined,
                        vin: vin || undefined,
                        plateId: numberPlate || undefined,
                        searchQuery,
                        registrationNo: registrationNumber || undefined,
                        registrationNoTypeId: model.dataSourceId,
                        countryCode: selectedVehicleLookup.countryCode,
                        selectedLookupType: selectedVehicleLookup.lookupTypeId,
                    })
                    deleteImportedVehicleDetails()

                    if (getBundleParams().enableDATVinSearch && datVinRequestActive && isVin(vin)) {
                        subPage =
                            renderRoute(getBundleParams().vehicleDetailsUrlWithoutWorkTaskId, {
                                vehicleType,
                                vehicleId: encodeUniqueId(typeof vehicle === "string" ? vehicle : vehicle.id),
                                vehicleDetailsSubPage: vehicleDetailsSubPageDatInfo,
                            }) + (datVinErrorTextId ? createQueryString({ datVinErrorTextId }) : "")
                    }
                }

                let customer: Customer | undefined
                if (customerEnabled) {
                    customer = {
                        id: uniqueId(),
                        firstName: vrcLookup.info.firstName,
                        lastName: name,
                        zip: vrcLookup.info.postalCode,
                        city: vrcLookup.info.city,
                        street: vrcLookup.info.street,
                    }
                }

                await attachToWorkTask?.({ vehicle, customer, subPage })
            }

            if (vehicleEnabled) {
                return {
                    applyCustomerVehicle,
                    applyVehicleAndCustomer: apply,
                    handleApplyButtonClick: () => {
                        setRequestVehicles(true)
                    },
                }
            }

            return {
                applyCustomerVehicle,
                handleApplyButtonClick: () => {
                    apply()
                },
            }
        }
        return {}
    }, [
        vrcLookup,
        vehicleEnabled,
        customerEnabled,
        translateText,
        regNoType,
        attachToWorkTask,
        plateType,
        datVinRequestActive,
        selectedVehicleLookup,
    ])

    function renderDATVinSearch() {
        if (!getBundleParams().enableDATVinSearch) {
            return
        }

        const vehicleDisabled = saving || requestVehicles || !vehicleEnabled
        const validVin = isVin(vrcLookup?.info.vin)

        return (
            <div className={classNames.switchWrapper}>
                <Switch
                    status={datVinRequestActive && validVin}
                    disabled={vehicleDisabled || !validVin}
                    onChange={setDatVinRequestActive}
                    label={
                        (
                            <div className={classNames.switchContent}>
                                <Icon name="dat-vin-data" size="xl" className={classNames.switchContentIcon} />
                                <div className={classNames.switchContentText}>
                                    <Typography display="block">{translateText(1259)}</Typography>
                                    <Typography variant="body3">
                                        {translateText(1260).replace("{0}", getBundleParams().datPricePerRequest || "2€")}
                                    </Typography>
                                </div>
                            </div>
                        ) as any
                    }
                />
            </div>
        )
    }

    function renderVehicleRequest() {
        if (!vrcLookup || !requestVehicles || !applyVehicleAndCustomer) {
            return
        }

        const validVin = isVin(vrcLookup?.info.vin)

        return (
            <div>
                <VehicleRequest
                    vrcInfo={vrcLookup.info}
                    saving={saving}
                    datVinRequestActive={!!getBundleParams().enableDATVinSearch && datVinRequestActive && validVin}
                    plateType={plateType}
                    onClose={(noResult) => {
                        setRequestVehicles(false)

                        if (noResult) {
                            setShowNoResultError(true)
                        }
                    }}
                    applyVehicleAndCustomer={applyVehicleAndCustomer}
                    applyCustomerVehicle={applyCustomerVehicle}
                />
            </div>
        )
    }

    function renderContent() {
        if (error) {
            return (
                <Box mt={2} ml={2}>
                    <RetryError onRetry={analyzeFile} />
                </Box>
            )
        }

        if (!vrcLookup) {
            return <Loader />
        }

        return (
            <div className={classes(classNames.bottom, !props.file.isImage ? classNames.pdfBottom : undefined)}>
                <Typography variant="h2">{translateText(12629)}</Typography>
                <Typography>{translateText(12630)}</Typography>

                <div className={classNames.formsWrapper}>
                    <CustomerForm
                        enabled={customerEnabled}
                        setEnabled={toggleCustomerEnabled}
                        vrcLookup={vrcLookup}
                        setVrcLookup={setVrcLookup}
                        saving={saving}
                        requestVehicles={requestVehicles}
                        modelState={modelState}
                    />

                    <VehicleForm
                        enabled={vehicleEnabled}
                        setEnabled={toggleVehicleEnabled}
                        vrcLookup={vrcLookup}
                        setVrcLookup={setVrcLookup}
                        saving={saving}
                        requestVehicles={requestVehicles}
                        modelState={modelState}
                        showNoResultError={showNoResultError}
                        setShowNoResultError={setShowNoResultError}
                        regNoType={regNoType}
                        vrcModuleIsUsingKbaNumbers={vrcModuleIsUsingKbaNumbers}
                        appendix={
                            <>
                                {renderDATVinSearch()}
                                {renderVehicleRequest()}
                            </>
                        }
                    />
                </div>
            </div>
        )
    }

    return (
        <SharedLayout
            classNameHeader={classNames.header}
            classNameContent={!props.file.isImage ? classNames.pdfContent : undefined}
            headerChildren={
                <Button
                    className={classNames.headerButton}
                    color="highlight"
                    size="large"
                    disabled={!handleApplyButtonClick || saving || requestVehicles || showNoResultError}
                    onClick={handleApplyButtonClick}
                >
                    {translateText(22)}
                </Button>
            }
        >
            <Typography variant="h2">{translateText(12626)}</Typography>

            <div className={classes(classNames.top, !props.file.isImage ? classNames.pdfTop : undefined)}>
                <div className={classNames.resetWrapper}>
                    <Typography variant="body3">{translateText(12627)}</Typography>
                    <Button startIcon={<Icon name="refresh" />} onClick={props.resetFile} className={classNames.resetButton}>
                        {translateText(12628)}
                    </Button>
                </div>
                <FilePreview file={props.file} className={props.file.isImage ? classNames.image : classNames.pdf} rotation={props.file.rotation} />
                <div className={classNames.topSpacer}>{/* for spacing */}</div>
            </div>

            <hr />

            {renderContent()}
        </SharedLayout>
    )
}
