import { RimFilters } from "../../../components/wheels-list/business/model"
import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { RimFiltersRequest } from "./model"
import { mapRimFiltersResponse } from "./mapper"

export function loadRimFilters(request: RimFiltersRequest) {
    const url = `${getServiceUrl()}/GetRimFilter`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<RimFilters>((resolve, reject) =>
        ajax({ url, body, authorization, method: "GET" }).then(
            response => resolve(mapRimFiltersResponse(response)),
            reject
        )
    )
}


function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}