import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetRepairShopData(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { userSettings } = this.props

    if (data.getRepairShopData) {
        const setRepairShopData = {
            data: userSettings && userSettings.repairShop,
        }

        if (source) {
            source.postMessage({ setRepairShopData }, "*")
        }
    }
}
