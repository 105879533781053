import { Box, Typography } from "@tm/components"

type Props = {
    label: string
}

export default function NoResults({ label }: Props) {
    return (
        <Box padding="1em .5em">
            <Typography variant="body2">{label}</Typography>
        </Box>
    )
}
