import { styled } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Demo, Icon, TabControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CategoryType, ModuleOptionType, VehicleType } from "@tm/models"
import { useGetActivatableModules } from "@tm/utils"
import { CatalogNavigationProps } from "."

const { Tab } = TabControl

const getTabId = (id: CategoryType) => `parts__widget-tabs__${id}`

export default function CatalogSelection(props: CatalogNavigationProps & { categoryType: { [key in CategoryType]: CategoryType } }) {
    const { workTask } = useWorkTask() ?? {}
    const { vehicleType } = workTask?.vehicle ?? {}
    const { translate, translateText } = useLocalization()
    const { disabledCategories, availableCategories, directSearchText, universalSearchText, tiresSearchText, categoryType } = props
    const { vehicleParts, directSearch, universalParts, tiresSearch, predictive, eurotaxSearch, dtcSearch } = categoryType

    const { userContext } = useUser() || {}
    const activatableModulesLoadable = useGetActivatableModules(userContext)
    const isGTIDemo = activatableModulesLoadable.response?.moduleGroups
        ?.find((x) => x.id === "gpi")
        ?.modules?.find((x) => x.id.toLocaleLowerCase() === "eurotax")
        ?.moduleOptions.find((moduleOption) => moduleOption.type === ModuleOptionType.Demo)?.active

    return (
        <TabControl selectedTabIdentifier={props.activeCategory} onTabSelect={props.onChangeCategory} type={props.tabType}>
            {availableCategories.indexOf(vehicleParts) >= 0 && (
                <Tab identifier={vehicleParts} htmlId={getTabId(vehicleParts)} disabled={disabledCategories.indexOf(vehicleParts) !== -1}>
                    {translate(449)}
                </Tab>
            )}
            {availableCategories.indexOf(directSearch) >= 0 && (
                <Tab identifier={directSearch} htmlId={getTabId(directSearch)} disabled={disabledCategories.indexOf(directSearch) !== -1}>
                    {directSearchText}
                </Tab>
            )}
            {availableCategories.indexOf(universalParts) >= 0 && (
                <Tab identifier={universalParts} htmlId={getTabId(universalParts)} disabled={disabledCategories.indexOf(universalParts) !== -1}>
                    {universalSearchText}
                </Tab>
            )}
            {availableCategories.indexOf(tiresSearch) >= 0 && (
                <Tab identifier={tiresSearch} htmlId={getTabId(tiresSearch)} disabled={disabledCategories.indexOf(tiresSearch) !== -1}>
                    {tiresSearchText}
                </Tab>
            )}
            {availableCategories.indexOf(eurotaxSearch) >= 0 && (
                <StyledGtiTab identifier={eurotaxSearch} htmlId={getTabId(eurotaxSearch)} disabled={disabledCategories.includes(eurotaxSearch)}>
                    {isGTIDemo && <StyledDemo displayMode="edge" />}
                    {translateText(571)}
                </StyledGtiTab>
            )}
            {availableCategories.indexOf(predictive) >= 0 && vehicleType === VehicleType.PassengerCar && (
                <Tab
                    identifier={predictive}
                    className="predictive-nodes-tab"
                    htmlId={getTabId(predictive)}
                    disabled={disabledCategories.indexOf(predictive) !== -1}
                >
                    {props.showPartsIndicatorIcon && <Icon name="partsindicator" />}
                    {translate(1630)}
                </Tab>
            )}
            {availableCategories.indexOf(dtcSearch) >= 0 && (
                <Tab identifier={dtcSearch} className="none" htmlId={getTabId(dtcSearch)} disabled={disabledCategories.includes(dtcSearch)}>
                    {translateText(12926)}
                </Tab>
            )}
        </TabControl>
    )
}

const StyledDemo = styled(Demo)({
    position: "absolute",
    top: "0",
    right: "0",
})

const StyledGtiTab = styled(Tab)({
    padding: "0 1em",
    position: "relative",
})
