import Countdown, { CountdownRenderProps } from "react-countdown"
import { countdownStyle } from "../style"
import { Time } from "./Time"

export interface CountdownComponentProps {
    dueDate: string | number | Date
}

export function CountdownRenderer(props: CountdownRenderProps) {
    return (
        <div className={countdownStyle.timerText}>
            Noch <Time value={props.days} /> Tage <Time value={props.hours} /> Stunden <Time value={props.minutes} /> Minuten
        </div>
    )
}

export function CountdownComponent(props: CountdownComponentProps) {
    return <Countdown date={props.dueDate} renderer={CountdownRenderer} />
}
