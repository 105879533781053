import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import { initDrivemotiveBundle, version } from "./utils"
import HeaderButton from "./components/header-button"

const bundle: IBundle = {
    ...version,
    components: [Main, HeaderButton],
    init: initDrivemotiveBundle,
}

export default bundle
