import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { withRouter, createQueryString, RouteComponentProps } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { Widget, Loader, MessageToast, WidgetProps } from "@tm/controls"
import { connectComponent } from "@tm/morpheus"
import { Icon } from "@tm/components"
import { ComponentState, IActions, Actions } from "./business"

type Props = RouteComponentProps & {
    state: ComponentState
    actions: IActions
    size?: string
    className?: string
    targetIcon?: string
    hideMediaManager?: boolean
}

function WidgetConceptPageComponent(props: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { className, size, state, history, actions, targetIcon, hideMediaManager } = props
    const { loading, active, logo, description, mediaManager, hasCredentials, url } = state
    const [showMessage, setShowMessage] = useState<boolean>()

    useEffect(() => {
        const unsubscriptions: Array<Function> = []
        unsubscriptions.push(actions.subscribeChannels())
        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    useEffect(() => {
        if (userContext) {
            actions.loadConceptPageUrls(userContext)
        }
    }, [userContext])

    function handleClick() {
        if (!hasCredentials) {
            setShowMessage(true)
            return
        }
        history.push(`/external01${createQueryString({ url })}`)
    }

    function handleMediaManagerClick() {
        if (!hasCredentials) {
            setShowMessage(true)
            return
        }
        history.push(`/external01${createQueryString({ url: mediaManager })}`)
    }

    if (!active) {
        return null
    }

    let qualifiedClassName = "bundle-misc widget-concept-page widget widget--w1 widget--h1"
    if (className) {
        qualifiedClassName += ` ${className}`
    }

    const widgetProps: WidgetProps = {
        size: (size as any) || "1x1",
        active: false,
        className: qualifiedClassName,
        onClick: handleClick,
        cover: (
            <Widget.Cover>
                {logo && <div className="image" style={{ backgroundImage: `url(${logo})` }} />}
                <div className="widget__cover-text">{description}</div>
                {targetIcon && <Icon name={targetIcon} className="target-icon" />}
                {loading && <Loader visible />}
            </Widget.Cover>
        ),
    }
    const mediaManagerProps = mediaManager
        ? {
              ...widgetProps,
              onClick: handleMediaManagerClick,
              cover: (
                  <Widget.Cover>
                      {logo && <div className="image" style={{ backgroundImage: `url(${logo})` }} />}
                      <div className="widget__cover-text">{description} Marketing</div>
                      {targetIcon && <Icon name={targetIcon} className="target-icon" />}
                      {loading && <Loader visible />}
                  </Widget.Cover>
              ),
          }
        : undefined

    return (
        <>
            <Widget {...widgetProps} />
            {!hideMediaManager && mediaManagerProps && <Widget {...mediaManagerProps} />}
            {showMessage && <MessageToast skin="warning" message={translateText(1606)} onButtonClick={() => setShowMessage(false)} />}
        </>
    )
}

export default connectComponent(Actions, withRouter(WidgetConceptPageComponent))
