import { UserSettings, WorkTaskInfo } from "@tm/context-distribution"
import { concat, createBasketMemo, WorkTaskTruckData } from "@tm/utils"

export function getBasketPositionMemo(
    orderOptions: UserSettings["orderOptions"] | undefined,
    workTask: WorkTaskInfo | undefined,
    truckData: WorkTaskTruckData | undefined
): string | undefined {
    if (!orderOptions?.repairShopResponse?.addWorkTaskInfoToOrderItemMemo || orderOptions?.basketMemoContext === "perOrder") {
        return
    }

    return createBasketMemo({
        config: {
            sections: orderOptions.basketMemoSections ?? [],
        },
        vehicle: workTask?.vehicle,
        customer: workTask?.customer,
        truckData,
        workTask: workTask?.workTaskReadModel,
    })
}

export function getCatalogPartMemo(partOrderMemo: string | undefined, defaultMemo: string | undefined): string | undefined {
    if (partOrderMemo && defaultMemo) {
        return concat(" ", partOrderMemo, defaultMemo)
    }

    return partOrderMemo ?? defaultMemo
}
