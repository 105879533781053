import { merge } from "lodash"
import { useMemo } from "react"
import { Article, ArticleIdentifier } from "@tm/models"
import { useArticlesByArticleNumbersWithOptionalVehicle, useArticlesByWholesalerArticleNumbers } from "@tm/utils"
import { CompilationShowViewModelItem } from ".."
import { mapCompilationShowViewModelItemToArticle } from "../mapper"

export function useArticles(parts: CompilationShowViewModelItem[]) {
    const requests = useMemo(() => {
        const wholesalerArticleNumbers = parts.filter((p) => !!p.wholesalerArticleNumber)?.map((x) => x.wholesalerArticleNumber)

        const articleNumbers = parts
            .filter((p) => !p.wholesalerArticleNumber && (p.productGroupId ?? -1) > 0)
            ?.map(
                (x) =>
                    <ArticleIdentifier>{
                        supplierId: x.supplierId,
                        supplierArticleNo: x.supplierArticleNumber,
                        productGroupId: x.productGroupId,
                    }
            )

        return {
            wholesalerArticleNumbers,
            articleNumbers,
        }
    }, [parts])

    const { articles: wholesalerArticlesResults, isLoading: isWholesalerArticlesResultsLoading } = useArticlesByWholesalerArticleNumbers(
        requests.wholesalerArticleNumbers
    )

    const { articles: articlesResults, isLoading: isArticlesResultsLoading } = useArticlesByArticleNumbersWithOptionalVehicle(requests.articleNumbers)

    const articles: Article[] | undefined = useMemo(() => {
        if (isArticlesResultsLoading || isWholesalerArticlesResultsLoading) {
            return undefined
        }

        return parts.map((part) => {
            let articleSearchResult: Article | undefined

            if (part.wholesalerArticleNumber) {
                articleSearchResult = wholesalerArticlesResults?.[part.wholesalerArticleNumber]?.first()
            } else if ((part.productGroupId ?? -1) > 0) {
                articleSearchResult = articlesResults?.find(
                    (article) =>
                        part.supplierId === article.supplier.id &&
                        part.supplierArticleNumber === article.supplierArticleNo &&
                        part.productGroupId === article.productGroup.id
                )
            }

            if (!articleSearchResult) {
                const articleCompilation = mapCompilationShowViewModelItemToArticle(part) as Article
                return articleCompilation
            }

            const articleMerged = merge(articleSearchResult, {
                id: part.id,
                additionalDescription: part.note || "",
                initialQuantity: part.quantity.value,
                quantity: part.quantity.value,
            })
            return articleMerged
        })
    }, [parts, wholesalerArticlesResults, articlesResults, isArticlesResultsLoading, isWholesalerArticlesResultsLoading])

    return { articles, isLoading: isArticlesResultsLoading || isWholesalerArticlesResultsLoading }
}
