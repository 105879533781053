import { IBaseActions, BaseActions, BaseComponentActionType } from "./baseReducer"
import { IConfigActions, ConfigActions, ConfiguratorComponentActionType } from "./configuratorReducer"
export * from "./model"
import { IBundleActions, BundleActions } from "../../../business"

import { reduce as baseReducer } from "./baseReducer"
import { reduce as configuratorReducer } from "./configuratorReducer"
import { combineReducers } from "redux"

export const reduce = combineReducers({ base: baseReducer, configurator: configuratorReducer })

export type ComponentActionType = ConfiguratorComponentActionType | BaseComponentActionType

export type IActions = IBundleActions & IBaseActions & IConfigActions
export const Actions: IActions = { ...BundleActions, ...BaseActions, ...ConfigActions }