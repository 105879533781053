import { SyntheticEvent, memo, useState } from "react"
import { Collapsible } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { AddressSelectionItem, TourOrderOption } from "@tm/models"
import { Loader, Alert, Box, Stack } from "@tm/components"
import { BillingMode, ShipmentMode } from "../../../data/model"
import { useGlobalOrderOptionsState } from "../../../hooks/useGlobalOrderOptionsState"
import GlobalAdditionalInformation from "./components/GlobalAdditionalInformation"
import BackorderOptions from "./components/BackorderOptions"
import BillingOptions from "./components/BillingOptions"
import DeliveryDateOptions from "./components/DeliveryDateOptions"
import OrderProcessingOptions from "./components/OrderProcessingOptions"
import PaymentOptions from "./components/PaymentOptions"
import ShipmentOptions from "./components/ShipmentOptions"
import Summary from "./components/Summary"
import DeliveryAddressesInformation from "./components/DeliveryAddressesInformation"
import ErrorAlert from "../ErrorAlert"
import TourDateOptions from "./components/TourDateOptions"

export const GlobalOrderOptionsComponent = memo(() => {
    const { translateText } = useLocalization()
    const [optionsExpanded, setOptionsExpanded] = useState(true)
    const { globalOrderOptions, errorText, hasErrors, globalOrderOptionsLoading, setGlobalOrderOptionsValue, centralOrderParameters } =
        useGlobalOrderOptionsState()

    const toggleOptions = (e: SyntheticEvent | boolean) => {
        if (typeof e !== "boolean") {
            e.stopPropagation && e.stopPropagation()
        }

        setOptionsExpanded(!optionsExpanded)
    }

    function renderCollapsibleHeader() {
        return <Summary optionsExpanded={optionsExpanded} options={globalOrderOptions} onToggle={toggleOptions} />
    }

    function handleSetPayment(id: string) {
        if (globalOrderOptions?.paymentOptions) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                paymentOptions: {
                    ...globalOrderOptions.paymentOptions,
                    paymentOptions: globalOrderOptions.paymentOptions?.paymentOptions.map((option) => ({ ...option, isSelected: option.id === id })),
                },
            })
        }
    }

    function renderPaymentOptions() {
        if (globalOrderOptions?.paymentOptions) {
            return <PaymentOptions options={globalOrderOptions} disableFields={globalOrderOptionsLoading} onSetPayment={handleSetPayment} />
        }
    }

    function handleSetBilling(billingmode: BillingMode) {
        if (globalOrderOptions?.billingModes) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                billingModes: {
                    ...globalOrderOptions.billingModes,
                    billingModes: globalOrderOptions.billingModes?.billingModes.map((option) => ({
                        ...option,
                        isSelected: option.id === billingmode.id,
                    })),
                },
            })
        }
    }

    function renderBillingOptions() {
        if (globalOrderOptions?.billingModes) {
            return <BillingOptions options={globalOrderOptions} disableFields={globalOrderOptionsLoading} onSetBillingMode={handleSetBilling} />
        }
    }

    function handleSetShipment(shipmentMode: ShipmentMode) {
        if (globalOrderOptions?.shipmentModes) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                shipmentModes: {
                    ...globalOrderOptions.shipmentModes,
                    shipmentModes: globalOrderOptions.shipmentModes?.shipmentModes.map((option) => ({
                        ...option,
                        isSelected: option.id === shipmentMode.id,
                    })),
                },
            })
        }
    }

    function handleSetDeliveryDistribution(id: string) {
        if (globalOrderOptions?.deliveryDistribution) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                deliveryDistribution: {
                    ...globalOrderOptions.deliveryDistribution,
                    deliveryDistributionModes: globalOrderOptions.deliveryDistribution?.deliveryDistributionModes.map((option) => ({
                        ...option,
                        isSelected: option.id === id,
                    })),
                },
            })
        }
    }

    function renderShipmentOptions() {
        if (!globalOrderOptions?.shipmentModes?.shipmentModes?.length && !globalOrderOptions?.deliveryDistribution) {
            return null
        }

        return (
            <ShipmentOptions
                options={globalOrderOptions}
                disableFields={globalOrderOptionsLoading}
                onSetDeliveryDistribution={handleSetDeliveryDistribution}
                onSetShipment={handleSetShipment}
            />
        )
    }

    function handleUpdateDeliveryDate(deliveryDate: Date) {
        if (globalOrderOptions) {
            setGlobalOrderOptionsValue({ ...globalOrderOptions, deliveryDate })
        }
    }

    async function handleUpdateTourFilterDate(tourFilterDate: Date) {
        if (globalOrderOptions) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                tours: { ...globalOrderOptions.tours, tourFilterDate, tours: globalOrderOptions.tours?.tours ?? [] },
            })
        }
    }

    function handleSelectTour(selectedTour: TourOrderOption) {
        if (globalOrderOptions?.tours) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                tours: {
                    ...globalOrderOptions.tours,
                    tours: globalOrderOptions.tours?.tours.map((option) => ({ ...option, isSelected: option.id === selectedTour.id })),
                },
            })
        }
    }

    function renderDeliveryDateOptions() {
        if (!globalOrderOptions?.deliveryDate) {
            return null
        }

        return (
            <DeliveryDateOptions
                options={globalOrderOptions}
                disableFields={globalOrderOptionsLoading}
                onUpdateDeliveryDate={handleUpdateDeliveryDate}
            />
        )
    }

    function renderTourDateOptions() {
        if (!globalOrderOptions?.tours) {
            return null
        }

        return (
            <TourDateOptions
                options={globalOrderOptions}
                disableFields={globalOrderOptionsLoading}
                onSetTour={handleSelectTour}
                onUpdateTourFilterDate={handleUpdateTourFilterDate}
            />
        )
    }

    function handleSetOrderProcessing(id: string) {
        if (globalOrderOptions?.orderProcessingOptions) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                orderProcessingOptions: {
                    ...globalOrderOptions.orderProcessingOptions,
                    orderProcessingOptions: globalOrderOptions.orderProcessingOptions?.orderProcessingOptions.map((option) => ({
                        ...option,
                        isSelected: option.id === id,
                    })),
                },
            })
        }
    }

    function renderOrderProcessingOptions() {
        if (!globalOrderOptions?.orderProcessingOptions) {
            return null
        }

        return (
            <OrderProcessingOptions
                options={globalOrderOptions}
                disableFields={globalOrderOptionsLoading}
                onSetOrderProcessing={handleSetOrderProcessing}
            />
        )
    }

    function handleSelectBackorder(id: string) {
        if (globalOrderOptions?.backorderOptions) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                backorderOptions: {
                    ...globalOrderOptions.backorderOptions,
                    backorderOptions: globalOrderOptions.backorderOptions?.backorderOptions.map((option) => ({
                        ...option,
                        isSelected: option.id === id,
                    })),
                },
            })
        }
    }

    function renderBackorderOptions() {
        if (!globalOrderOptions?.backorderOptions) {
            return null
        }

        return <BackorderOptions options={globalOrderOptions} disableFields={globalOrderOptionsLoading} onSelectBackorder={handleSelectBackorder} />
    }

    function handleSetDeliveryAddress(selectedAddress: AddressSelectionItem) {
        if (globalOrderOptions?.deliveryAddresses) {
            setGlobalOrderOptionsValue({
                ...globalOrderOptions,
                deliveryAddresses: {
                    ...globalOrderOptions.deliveryAddresses,
                    addresses: globalOrderOptions.deliveryAddresses?.addresses.map((option) => {
                        if (option.id === selectedAddress.id) {
                            return {
                                ...selectedAddress,
                                isSelected: true,
                            }
                        }

                        return {
                            ...option,
                            isSelected: false,
                        }
                    }),
                },
            })
        }
    }

    function renderDeliveryAddressInformation() {
        if (globalOrderOptions?.deliveryAddresses?.addresses) {
            return (
                <DeliveryAddressesInformation
                    options={globalOrderOptions}
                    disableFields={globalOrderOptionsLoading}
                    onSetDeliveryAddress={handleSetDeliveryAddress}
                />
            )
        }
    }

    return (
        <>
            {hasErrors && !!errorText ? (
                <ErrorAlert additionalTextId={errorText} />
            ) : (
                <Box display="flex" alignItems="center" ml={2}>
                    {(globalOrderOptions?.hasCustomerOrderNumber ||
                        globalOrderOptions?.hasOrderComment ||
                        globalOrderOptions?.paymentOptions ||
                        globalOrderOptions?.shipmentModes ||
                        globalOrderOptions?.billingModes ||
                        globalOrderOptions?.backorderOptions) && (
                        <Collapsible
                            name={translateText(13129)}
                            initiallyOpened={optionsExpanded}
                            renderHeaderAppendix={renderCollapsibleHeader}
                            onChangeOpen={toggleOptions}
                        >
                            <Stack spacing={1}>
                                <Alert
                                    size="small"
                                    severity="info"
                                    sx={{ width: "50%" }}
                                    title={translateText(13130).replace("{0}", centralOrderParameters.orderPagingSize.toString())}
                                />
                                <>
                                    <Box display="flex">
                                        {renderPaymentOptions()}
                                        {renderShipmentOptions()}
                                        {renderDeliveryDateOptions()}
                                        {renderTourDateOptions()}
                                        {renderBillingOptions()}
                                        {renderOrderProcessingOptions()}
                                        {renderBackorderOptions()}
                                        {(globalOrderOptions?.hasCustomerOrderNumber || globalOrderOptions?.hasOrderComment) && (
                                            <GlobalAdditionalInformation />
                                        )}
                                    </Box>
                                    {globalOrderOptions?.deliveryAddresses && (
                                        <Box marginTop="1.5rem" width="75%">
                                            {renderDeliveryAddressInformation()}
                                        </Box>
                                    )}
                                </>
                            </Stack>
                        </Collapsible>
                    )}
                    {globalOrderOptionsLoading && <Loader size="small" />}
                </Box>
            )}
        </>
    )
})
