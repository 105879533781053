import { Box, Skeleton } from "@tm/components"

export function SkeletonSelectionLoading() {
    return (
        <Box height="45px">
            <Skeleton height="15px" width="100px" sx={{ transformOrigin: "0 70%" }} />
            <Skeleton height="50px" width="350px" sx={{ transformOrigin: "0 10%" }} />
        </Box>
    )
}
