import { stylesheet } from "typestyle"

export function getComponentStyles() {
    return stylesheet({
        actionsColumn: {
            width: 1,
        },
        thumbnails: {
            marginTop: "0.5em",

            $nest: {
                ".image": {
                    height: "2em",
                },
                ".error-image > svg": {
                    height: "2em",
                },
            },
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
