import { Button, Icon, Stack, Typography, styled, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType } from "@tm/models"
import { QuotaText } from "../QuotaText"
import { getBundleParams } from "../../../utils"
import { useVehicleSearchOptionsQuota } from "../../../data/hooks/useVehicleSearchOptionsQuota"

type Props = {
    onClick: () => void
}

const DAT_VIN_FALLBACK_PRICE = "2€"

const StyledDatVinTypography = styled(Typography)(({ theme }) => ({
    color: "inherit",
}))

export function DatVinSearchButton(props: Props) {
    const { datPricePerRequest } = getBundleParams()
    const { translate, translateText } = useLocalization()
    const { quota } = useVehicleSearchOptionsQuota(RegistrationNoType.DatVin)
    const priceLabel = translateText(1260).replace("{0}", datPricePerRequest || DAT_VIN_FALLBACK_PRICE)

    return (
        <>
            <QuotaText quota={quota} marginY="4px" />
            <Button
                id="search-area__btn--vin"
                sx={{ justifyContent: "start" }}
                onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    props.onClick()
                }}
            >
                <Stack direction="row">
                    <Icon height="40px" width="40px" name="dat-vin-data" />
                    <Stack spacing={1} alignItems="start" display="flex">
                        <StyledDatVinTypography variant="h4">{translate(1259)}</StyledDatVinTypography>
                        <StyledDatVinTypography variant="label">{priceLabel}</StyledDatVinTypography>
                    </Stack>
                </Stack>
            </Button>
        </>
    )
}
