import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { InspectionWorkSlice, InspectionWorkState } from "./model"

function getInitialState(): InspectionWorkState {
    return { inspectionDate: new Date(), inspectionKmValue: "" }
}

export const createInspectionWorkSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], InspectionWorkSlice> = (set) => ({
    ...getInitialState(),
    setSaveInspectionDate: (date: Date) => set(() => ({ inspectionDate: date }), false, `Set inspection date: ${date}`),
    setSaveInspectionKm: (value: string) => set(() => ({ inspectionKmValue: value }), false, `Set inspection km: ${value}`),
    reset: () => set(getInitialState(), false, "Reset Inspection Work Slice"),
})
