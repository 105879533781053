import { useState } from "react"
import { Button, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"

type Props = {
    onSave(description: string): void
}

export default function CreateCompilation(props: Props) {
    const { translateText } = useLocalization()
    const [isCreationMode, setIsCreationMode] = useState(false)
    const [text, setText] = useState<string>()

    const handleClickCreate = () => {
        setIsCreationMode(true)
    }

    const handleReset = () => {
        setIsCreationMode(false)
        setText(undefined)
    }

    const handleClickSave = () => {
        text && props.onSave(text)
        handleReset()
    }

    return (
        <div className="create-compilation">
            {isCreationMode ? (
                <>
                    <TextField
                        onChange={setText}
                        value={text}
                        placeholder={translateText(1241)}
                        onChangeConfirm={handleClickSave}
                        onChangeReset={handleReset}
                        autoFocus
                    />
                    <Button icon="close" onClick={handleReset} title={translateText(317)} />
                    <Button icon="check" skin="highlight" onClick={handleClickSave} title={translateText(51)} />
                </>
            ) : (
                <Button className="create" icon="add" layout={["ghost"]} onClick={handleClickCreate}>
                    {translateText(1242)}
                </Button>
            )}
        </div>
    )
}
