import { IBundle } from "@tm/morpheus"

import ExportButton from "./components/export-button"
import Gateway from "./components/gateway"
import Overview from "./components/overview"
import StatusButton from "./components/status-button"
import ArticleInfo from "./micros/article-info"
import { initDmsBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [ExportButton, Gateway, Overview, StatusButton],
    micros: [ArticleInfo],
    init: initDmsBundle,
}

export default bundle
