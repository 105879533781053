import { VehicleImage } from "@tm/components"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import * as React from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { MainState } from "../../main"
import { Actions, WheelsState } from "../business"

type Props = {
    disabledButton?: boolean
    displayConfiguratorDisplay: boolean
}

const selector = createSelector((s: MainState) => ({
    showRimInfo: s.wheelsList.base.showRimInfo,
    display: s.wheelsList.configurator.display
}), x => x)


const TopActions: React.FC<Props> = ({ displayConfiguratorDisplay, disabledButton }) => {
    const actions = useActions(Actions, "changeConfiguratorDisplay", "changeRimInfoAndSettingVisibility")
    const { translateText } = useLocalization()
    const { showRimInfo, display } = useSelector(selector)
    const vehicle = useSelector((s: MainState) => s.manager.vehicle)

    return (
        <div className="content_wheels-information">
            <Button isActive={showRimInfo} onClick={() => actions.changeRimInfoAndSettingVisibility(!showRimInfo, false)} icon="info" >
                {translateText(931)}
            </Button>

            {displayConfiguratorDisplay &&
                <Button
                    className="display-button-config"
                    disabled={disabledButton}
                    onClick={actions.changeConfiguratorDisplay}
                    layout={["dropshadow"]}
                >
                    <VehicleImage
                        modelImage={vehicle?.modelThumbnail || ""}
                        modelSeriesImage={vehicle?.modelSeriesThumbnail || ""}
                        vehicleType={vehicle?.vehicleType || VehicleType.PassengerCar}
                    />
                    {display ? translateText(973) : translateText(972)}
                </Button>
            }
        </div>
    )
}

export default TopActions