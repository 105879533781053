import React from "react"
import { useLocalization } from "@tm/localization"
import { ConfirmationDialogKeys, WorkType } from "@tm/models"
import { Button, Checkbox, Icon, Stack, Switch } from "@tm/components"
import DeleteButton from "../../../../_shared/DeleteButton"
import { BasketWork } from "../../../../../models"

type Props = {
    disableControls?: boolean
    work: BasketWork
    onIncludeExcludeWork(work: BasketWork): void
    onOpenWorkEditor(id: string): void
    onRemoveWorks(ids: Array<string>): void
    onResetRepairTimes(work: BasketWork): void
    onSelectWork(id: string): void
}

export default function WorkActionsComponent(props: Props) {
    const { work, disableControls, onIncludeExcludeWork, onOpenWorkEditor, onRemoveWorks, onResetRepairTimes, onSelectWork } = props
    const { workItem, states } = work
    const { isIncluded, type, id } = workItem
    const { translateText } = useLocalization()

    function handleEditWork() {
        if (states.isSelected) {
            onSelectWork(id)
        }
        onOpenWorkEditor(id)
    }

    return (
        <Stack direction="row" spacing={1}>
            <Switch
                label={translateText(49)}
                checked={isIncluded}
                labelPlacement="start"
                size="small"
                onChange={() => onIncludeExcludeWork(work)}
                disabled={disableControls}
            />
            <Button
                variant="text"
                title={translateText(122)}
                onClick={handleEditWork}
                startIcon={<Icon name="edit_change" />}
                disabled={!isIncluded || disableControls}
            />
            <Button
                title={translateText(48)}
                onClick={() => onResetRepairTimes(work)}
                disabled={type !== WorkType.CustomMainWork || disableControls}
                startIcon={<Icon name="refresh" />}
                variant="text"
            />
            <DeleteButton
                ids={[id]}
                buttonTitleId={624}
                disableButton={disableControls}
                dialogTextId={836}
                onConfirmRemove={(ids) => ids && onRemoveWorks(ids)}
                confirmationKey={ConfirmationDialogKeys.DeleteBasketItem}
            />
            <Checkbox checked={states.isSelected || false} onChange={() => onSelectWork(id)} disabled={disableControls} />
        </Stack>
    )
}
