import { CSSProperties } from "react"
import { KeyValueLoader } from "../helpers"

export const ExternalWidgetsKeyValueLoader = new KeyValueLoader<ExternalWidgetConfig[]>()

export type ExternalWidgetConfig = {
    target: string
    slot: string
    url: string
    styles?: CSSProperties
    scrolling?: boolean
}
