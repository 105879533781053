import { RepairTimeProvider, MainService, GetMainServicesResponse } from "@tm/models"
import { BundleActionType, BundleActions } from "../../../business"

export type ComponentActionType =
    | BundleActionType
    | { type: "MAIN_SERVICES_LOADING" }
    | { type: "MAIN_SERVICES_LOADED"; payload: GetMainServicesResponse | undefined }

export type RepairTimesSelectionState = {}

const DEFAULT_STATE: RepairTimesSelectionState = {}

export function reduce(state = DEFAULT_STATE): RepairTimesSelectionState {
    return state
}

function selectMainService(
    mainService: MainService,
    workTaskId: string,
    provider: RepairTimeProvider,
    repairTimeDivision: number
): ComponentActionType {
    return { type: "SELECT_MAIN_SERVICE", payload: { workTaskId, mainService, provider, repairTimeDivision } }
}

function clearMainService(): ComponentActionType {
    return { type: "CLEAR_MAIN_SERVICE" }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectMainService,
    clearMainService,
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "SELECT_MAIN_SERVICE":
        case "CLEAR_MAIN_SERVICE":
        case "MODULE_OPENED":
            return action
        default:
            break
    }
}
