import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { FieldValidationState, validateField, ModelState } from "@tm/utils"
import { TextField, Button, Text, InputGroup, Card, Alert, Modal } from "@tm/controls"
import { Article, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { NumberSelect } from "@tm/components"
import ArticleList from "./article-selection/list"
import { useAddArticle } from "../../../../../data/hooks/useCompilations"
import { isWM } from "../../../../../utils"
import { ArticleInterface } from "../../../../../data/interfaces/compilation-article-interface"
import { mapArticleToCompilationArticle } from "../../../../../data/mapper"

type Props = {
    compilationId: string
    onClose(): void
}

const wholesalerArticleNoPropertyName = "wholesalerArticleNo"
export default function PartCreatorComponent(props: Props) {
    const { translateText } = useLocalization()
    const [articleSelected, setArticleSelected] = useState<boolean>(false)
    const [articles, setArticles] = useState<Array<Article> | undefined>()
    const [articlesLoading, setArticlesLoading] = useState<boolean>(false)
    const [message, setMessage] = useState<string>()
    const [model, setModel] = useState<ModelState>()
    const [part, setPart] = useState<ArticleInterface>({
        description: "",
        quantity: 1,
        supplierArticleNumber: "",
        wholesalerArticleNo: "",
        customerArticleNumber: "",
        note: "",
    })

    const { addArticle } = useAddArticle()

    function handleChange(model: ArticleInterface, path?: Array<any>) {
        setPart(model)
        setModel(undefined)
        if (path && path.length && path[0] === wholesalerArticleNoPropertyName) {
            setArticleSelected(false)
        }
    }

    function handleQuantityChange(value: number | null) {
        setPart((prevPart) => ({ ...prevPart, quantity: value || 1 }))
    }

    function handleSearch() {
        if (!part) {
            return
        }

        setArticlesLoading(true)
        setMessage(undefined)
        const request = part.wholesalerArticleNo
        Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByWholesalerArticleNumber)
            .subscribe(request)
            .load()
            .then((articles) => {
                setArticlesLoading(false)
                if (articles.length === 1) {
                    handleSelectArticle(articles[0])
                } else if (articles.length > 1) {
                    setArticles(articles)
                } else {
                    setMessage(translateText(325))
                }
            })
    }

    function handleSelectArticle(article: Article) {
        if (!article.quantity) {
            article = { ...article, quantity: 1 }
        }

        const mappedArticle = mapArticleToCompilationArticle({
            articles: [article],
        })

        if (!mappedArticle) {
            return
        }

        setArticles(undefined)
        setPart(mappedArticle[0])
        setArticleSelected(true)
    }

    function handleAddToCompilation() {
        if (!part) {
            return
        }

        addArticle({
            compilationId: props.compilationId,
            articles: [part],
        }).then(() => {
            props.onClose()
        })
    }

    function validate(part: ArticleInterface): ModelState {
        return {
            [wholesalerArticleNoPropertyName]: validateArticleNumber(part.wholesalerArticleNo).messages,
        }
    }

    function validateArticleNumber(value: string): FieldValidationState {
        let minChars = 2
        if (value && value.lastIndexOf("*") !== -1 && value.lastIndexOf("*") === value.length - 1) {
            minChars = 6
        }

        return validateField(value).min(minChars, translateText(1231))
    }

    function renderModal() {
        if (!articles || !articles.length) {
            return null
        }

        return (
            <Modal onClose={() => setArticles(undefined)}>
                <ArticleList articles={articles} onSelect={handleSelectArticle} />
            </Modal>
        )
    }

    function renderMessage() {
        if (!message) {
            return null
        }

        return (
            <div className="adding-tools__alert">
                <Alert icon="info" message={message} buttonText={translateText(70)} onButtonClick={() => setMessage(undefined)} />
            </div>
        )
    }

    function renderAddButton(valid: boolean) {
        return (
            <div className="compilation-creator__buttons">
                <Button title={translateText(70)} onClick={() => props.onClose()} size="s" icon="close" />
                <Button
                    title={translateText(9)}
                    onClick={handleAddToCompilation}
                    size="s"
                    icon="check"
                    skin="success"
                    // className="custom-part__button"
                    disabled={!valid}
                />
            </div>
        )
    }

    const modelState = model || validate(part)

    let valid = true
    Object.keys(modelState).forEach((key) => {
        const value = modelState[key]
        if (value && value.length) {
            valid = false
        }
    })

    return (
        <>
            {renderModal()}
            <Card className="part-editor">
                <div className="content">
                    <Text className="part-editor__description">{translateText(1321)}</Text>
                    <InputGroup>
                        <TextField
                            maxLength={50}
                            className="part-editor__articlenumber"
                            label={translateText(isWM() ? 1310 : 376).toUpperCase()}
                            floatingLabel
                            path={[wholesalerArticleNoPropertyName]}
                            model={part}
                            onChange={handleChange}
                            onChangeConfirm={handleSearch}
                            preventConfirmOnBlur
                            modelState={modelState}
                            formatter={(value) => value.replace(/[^a-zA-Z0-9*]/g, "")}
                        />
                        <Button disabled={!!modelState.wholesalerArticleNo.length || articlesLoading} icon="search" onClick={handleSearch} />
                    </InputGroup>
                    <TextField
                        maxLength={50}
                        className="part-editor__articlenumber"
                        label={translateText(1305).toUpperCase()}
                        floatingLabel
                        path={["customerArticleNumber"]}
                        model={part}
                        onChange={handleChange}
                        modelState={modelState}
                        disabled={!articleSelected}
                        readonly={!articleSelected}
                    />
                    <TextField
                        maxLength={50}
                        className="part-editor__articlenumber"
                        label={translateText(1322).toUpperCase()}
                        floatingLabel
                        path={["supplierArticleNumber"]}
                        model={part}
                        onChange={handleChange}
                        modelState={modelState}
                        readonly
                    />
                    <TextField
                        maxLength={200}
                        className="part-editor__articlenumber"
                        label={translateText(25).toUpperCase()}
                        floatingLabel
                        path={["description"]}
                        model={part}
                        onChange={handleChange}
                        modelState={modelState}
                        readonly
                    />
                    <NumberSelect
                        classNameWrapper="part-editor__quantity"
                        value={part.quantity}
                        onValueChange={handleQuantityChange}
                        minAmount={1}
                        maxAmount={9999}
                        disabled={!articleSelected}
                    />
                    <TextField
                        maxLength={200}
                        className="part-editor__articlenumber"
                        label={translateText(39).toUpperCase()}
                        floatingLabel
                        path={["note"]}
                        model={part}
                        onChange={handleChange}
                        modelState={modelState}
                        disabled={!articleSelected}
                    />
                    {renderAddButton(valid && articleSelected)}
                </div>
                {renderMessage()}
            </Card>
        </>
    )
}
