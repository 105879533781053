import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { CheckCustomerResponse } from "./model"

export * from "./model"

export function checkCustomer() {
    const url = `${getServiceUrl()}/CheckCustomer`
    const authorization = getStoredAuthorization()

    return new Promise<CheckCustomerResponse>((resolve, reject) =>
        ajax({ url, method: "GET", authorization }).then((response) => resolve(response), reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.datCustomerUrl
}
