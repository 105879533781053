import { uniqueId } from "@tm/utils"
import { EDatRepairworkType, ICalculationItem, Work } from "../../models"
import { GetCalculationDataResponse } from "./model"

export function mapDatCalculationData({ items, works, ...rest }: GetCalculationDataResponse): GetCalculationDataResponse {
    return {
        ...rest,
        items: items?.map(mapOeArticle),
        works: works?.map(mapWork),
        totals: rest.totals,
    }
}

function mapOeArticle(item: any): ICalculationItem {
    return {
        alternativeParts: item.parts,
        oeArticle: { ...item.oeArticle },
        isSelected: item.isSelected,
        uniqueId: uniqueId(),
        showWMArticleFirst: item.showWMArticleFirst,
    }
}

function mapWork(work: any): Work {
    return {
        ...work,
        type: work.type || EDatRepairworkType.LabourWork,
    }
}
