import { ActivatableModule, ModuleGroupId, ModuleId, ModuleOption, ModulePackage, VehicleType } from "@tm/models"
import { clone } from "@tm/utils"

type TranslateText = (key: string | number) => string

export const getCorrectModuleGroupId = (
    moduleGroupId: ModuleGroupId | "inspectionData" | "repairTimes" | undefined,
    vehicleType?: VehicleType
): ModuleGroupId | undefined => {
    let moduleGroupIdAux = moduleGroupId

    if (moduleGroupId === "inspectionData" || moduleGroupId === "repairTimes") {
        moduleGroupIdAux = "technicalData"
    }

    if (vehicleType === VehicleType.CommercialVehicle && moduleGroupIdAux === "technicalData") {
        moduleGroupIdAux = "technicalDataTruck"
    }

    return moduleGroupIdAux as ModuleGroupId
}

export const getModuleTitle = (
    mainModule: ActivatableModule,
    moduleId: ModuleId | undefined,
    moduleNameAux: string,
    moduleGroupIdAux: string,
    translateText: TranslateText
) => {
    if (mainModule.externalId) {
        return mainModule.name
    }

    if (!moduleId) {
        return moduleNameAux
    }

    let moduleTitle = clone(moduleNameAux)

    if (moduleGroupIdAux === "technicalDataTruck") {
        moduleTitle = moduleTitle.replace("{{12987}}", "{{12986}}")
    }

    return moduleTitle.replace(/\{\{(.*?)\}\}/, (s, num) => translateText(num))
}

export function getMessage(
    moduleTitle: string,
    moduleName: string | undefined,
    defaultFullOptionModule: ModuleOption | undefined,
    defaultDemoOptionModule: ModuleOption | undefined,
    activeFullModulePackage: ModulePackage | undefined,
    activeDemoModulePackage: ModulePackage | undefined,
    activatableFullModulePackage: ModulePackage | undefined,
    activatableDemoModulePackage: ModulePackage | undefined,
    translateText: TranslateText
) {
    if (!moduleName) {
        return ""
    }

    // demo v2
    if (defaultFullOptionModule?.active) {
        if (activeFullModulePackage && activatableDemoModulePackage && activatableFullModulePackage) {
            return `${translateText(13260).replace("{0}", `"${`${moduleName} ${activeFullModulePackage.moduleInformation.name}`}"`)} ${translateText(
                12596
            ).replace("{0}", `"${`${moduleName} ${activatableFullModulePackage.moduleInformation.name}`}"`)}`
        }

        if (activeDemoModulePackage && activatableFullModulePackage) {
            return `${translateText(12799)
                .replace("{0}", `"${`${moduleName} ${activeDemoModulePackage.moduleInformation.name}`}"`)
                .replace("{1}", (activeDemoModulePackage.moduleAvailabilities.find((x) => x.type == 1 && x.active)?.amountOfDays ?? 0).toString())}`
        }

        if (activatableDemoModulePackage && activatableFullModulePackage) {
            return `${translateText(12596).replace("{0}", `"${`${moduleName} ${activatableFullModulePackage.moduleInformation.name}`}"`)}`
        }

        if (activatableDemoModulePackage) {
            return `${translateText(13250).replace("{0}", `"${`${moduleName} ${activatableDemoModulePackage.moduleInformation.name}`}"`)}`
        }
    }

    // demo v1
    if (defaultDemoOptionModule?.activatable && defaultFullOptionModule?.activatable) {
        return translateText(12596).replace("{0}", `"${moduleTitle}"`)
    }

    let message = ""

    if (defaultDemoOptionModule?.active) {
        message += `${translateText(12799)
            .replace("{0}", `"${moduleTitle}"`)
            .replace("{1}", (defaultDemoOptionModule.amountOfDays ?? 0).toString())} `
    } else if (defaultDemoOptionModule?.activatable) {
        message += `${translateText(13250).replace("{0}", `"${moduleTitle}"`)} `
    }

    if (defaultFullOptionModule?.active) {
        message += `${translateText(13260).replace("{0}", `"${moduleTitle}"`)} `
    } else if (defaultFullOptionModule?.activatable) {
        message += `${translateText(13251).replace("{0}", `"${moduleTitle}"`)} `
    }

    return message
}
