import { UserModuleType } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { CustomerBillingResponse } from "./model"

export * from "./model"

export function handleCustomerBilling() {
    const url = `${getServiceUrl()}/CustomerBilling`
    const authorization = getStoredAuthorization()
    const body = {
        moduleType: UserModuleType.TMDamageCalculationGlass,
    }

    return new Promise<CustomerBillingResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => {
            if (response?.successfull) {
                resolve(response)
            } else {
                reject(response)
            }
        }, reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.datCustomerUrl
}
