export const viewTranslate = new Map<string, number>([
    ["start", 671],
    ["maintenancePlan", 12633],
    ["technician", 12664],
    ["engineCompartment", 12665],
    ["interior", 12666],
    ["exterior", 12667],
    ["underVehicle", 12668],
    ["tiresWheels", 13062],
    ["general", 380],
    ["listOfDefects", 12670],
    ["maintenanceComplete", 12671],
    ["tester", 12672],
    ["maintenanceReview", 12669],
    ["checkWork", 12674],
    ["secondListOfDefects", 12670],
    ["testDrive", 12673],
    ["inspection", 12669],
    ["testComplete", 12675],
    ["end", 12676],
])
