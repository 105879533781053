import { TextField } from "@tm/controls"
import { Box, Icon, Typography, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { setValue } from "@tm/utils"
import { useSelector } from "react-redux"
import { useCallback } from "react"
import { customerFieldsSelector, MainActions } from "../../main/business"

export default function CustomerFields() {
    const { translateText } = useLocalization()
    const customerFields = useSelector(customerFieldsSelector)
    const actions = useActions(MainActions, "updateCustomerFields")

    const handleChange = (path: string, value: string) => {
        setValue(customerFields, [path], value)
        actions.updateCustomerFields(customerFields)
    }

    const handleNameChange = useCallback(
        (path: string) => (value: string) => {
            handleChange(path, value)
        },
        [handleChange]
    )

    return (
        <Box>
            <Box sx={{ alignItems: "center", mb: 1, display: "flex" }} className="field">
                <Icon name="customer" className="field__icon" width="28px" height="28px" />
                <Typography sx={{ fontSize: "1.5rem", margin: "0 0.5em" }}>{translateText(107)}</Typography>
            </Box>
            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                <TextField
                    floatingLabel
                    label={translateText(119)}
                    value={customerFields.firstName}
                    onChangeConfirm={handleNameChange("firstName")}
                />
                <TextField
                    floatingLabel
                    label={translateText(104)}
                    value={customerFields.lastName}
                    onChangeConfirm={handleNameChange("lastName")}
                />
            </Stack>
        </Box>
    )
}
