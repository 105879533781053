import { BundleActions, BundleActionTypes } from "../../../business"
import { MatchParams, NavigationSteps } from "../../../data/models"
import { MainActionsType } from "../../main/business"
import { NavigationState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "INIT"; payload: MatchParams }
    | { type: "CHANGE_STEP"; payload: { selectedStep: NavigationSteps; params?: any } }
    | { type: "RESET_MODULE" }

const DEFAULT_STATE: NavigationState = {
    params: {},
}

export function reduce(state = DEFAULT_STATE, action: MainActionsType): NavigationState {
    switch (action.type) {
        case "CHANGE_STEP": {
            const { params, selectedStep } = action.payload
            return {
                ...state,
                active: selectedStep,

                params: {
                    ...state.params,
                    ...params,
                },
                ...(selectedStep === "extern" && {
                    externVisited: true,
                }),
                ...(selectedStep === "calculation" && {
                    calcVisited: true,
                }),
            }
        }
        case "INIT": {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { view, ...rest } = action.payload
            return {
                ...state,
                initialized: true,
                active: "summary",
                params: {
                    ...DEFAULT_STATE.params,
                    ...rest,
                },
            }
        }
        case "PREPARE_CALC_LOADING": {
            return {
                ...state,
                calcVisited: false,
            }
        }
        case "RESET_MODULE": {
            return {
                ...DEFAULT_STATE,
                active: "summary",
            }
        }
        default: {
            return state
        }
    }
}

const reset = (): ComponentActionType => ({ type: "RESET_MODULE" })

const initNav = (matchParams: MatchParams): ComponentActionType => ({ type: "INIT", payload: matchParams })

const changeStep = (selectedStep: NavigationSteps, params?: any): ComponentActionType => ({ type: "CHANGE_STEP", payload: { selectedStep, params } })

export const Actions = {
    ...BundleActions,
    initNav,
    changeStep,
    reset,
}
