import { Box, DatePicker, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Item, OrderOptions } from "@tm/models"
import { useCallback } from "react"
import { useDateFilter } from "../hooks/useDateFilter"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onUpdateDeliveryDate(deliveryDate?: Date, selectedOptionsItem?: Item): void
}

const tomorrow = new Date()
tomorrow.setDate(tomorrow.getDate() + 1)
tomorrow.setUTCHours(0, 0, 0, 0) // so dates selected in the datepicker can be properly compared

export default function DeliveryDateOptionsComponent(props: Props) {
    const { translateText, translate, language } = useLocalization()
    const {
        options: { deliveryDate, selectedOptionsItem },
        disableFields,
        onUpdateDeliveryDate,
    } = props

    const updateDeliveryDate = useCallback(
        (date: Date | null) => onUpdateDeliveryDate(date ?? undefined, selectedOptionsItem),
        [onUpdateDeliveryDate, selectedOptionsItem]
    )

    const { selectedDate, handleChange } = useDateFilter(deliveryDate ?? null, updateDeliveryDate, tomorrow)

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="delivery-time" />
                <Typography variant="h4">{translate(13834)}</Typography>
            </BoxWithAlignItems>
            <BoxWithColumnPaddingRight pl="35px" pt="4px">
                <Box mb={0.5}>
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        disableHighlightToday
                        onChange={handleChange}
                        label={translateText(98)}
                        value={selectedDate}
                        disabled={disableFields}
                        minDate={tomorrow}
                    />
                </Box>
            </BoxWithColumnPaddingRight>
        </BoxWithColumnPaddingRight>
    )
}
