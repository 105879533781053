import { NestedCSSProperties } from "typestyle/lib/types"

export const styles: NestedCSSProperties = {
    height: "100%",
    lineHeight: 1.3,

    $nest: {
        "&__no": {
            flex: "0 0 5rem",
        },
        "&__name": {
            flex: "0 0 10rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "&__company": {
            flex: "0 0 10rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "&__street": {
            flex: "0 0 12rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "&__city": {
            flex: "0 0 11rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "&__phone": {
            flex: "0 0 10rem",
        },
        "&__email": {
            flex: "0 0 10rem",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "&__count": {
            flex: "0 0 4rem",
        },
        "&__vehicle": {
            flex: 1,
        },
        "&__actions": {
            flex: "0 0 auto",
            display: "flex",
        },
        "&__name-company": {
            flex: "0 0 12rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        "&__location": {
            flex: "0 0 14rem",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },

        "&__plate": {
            marginBottom: "0.25em",
        },

        "&__sortable": {
            cursor: "pointer",
            height: "1.5em",
            display: "flex",
            alignItems: "flex-end",
            $nest: {
                ".icon": {
                    marginLeft: "0.5em",
                },
            },
        },

        "&__no-result": {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
        },

        "& .plate-id": {
            width: "8em",
        },

        "& .fancy-list__head > .fancy-list__block": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
        },

        "&__break": {
            wordBreak: "break-all",
        },
    },
}
