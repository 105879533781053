import { useLocalization } from "@tm/localization"
import { encodeUniqueId, uniqueId } from "@tm/utils"
import { Text, Button, Widget } from "@tm/controls"

type Props = {
    className?: string
    catalogName?: string
}

export default function WelcomeComponent(props: Props) {
    const { translate, translateText } = useLocalization()
    const { catalogName, className } = props
    const newClassName = `tk-worktask welcome ${className || "widget--h2 widget--w4"}`
    const text = catalogName ? translateText(986).replace("NEXT™", catalogName) : translateText(986)

    return (
        <Widget size="4x2" className={newClassName} active title={translate(985)}>
            <Widget.Content>
                <Text>{text}</Text>
            </Widget.Content>
            <Widget.Footer>
                <Button linkTo={`/${encodeUniqueId(uniqueId())}`} icon="plus" skin="highlight" size="l">
                    {translate(987)}
                </Button>
            </Widget.Footer>
        </Widget>
    )
}
