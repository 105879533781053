import { renderRoute } from "@tm/utils"
import { useCallback } from "react"
import { useSelector } from "react-redux"
import { useLocalization } from "@tm/localization"
import { Box, Button, Icon, Typography } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { MatchParams } from "../../data/models"
import { managerSelector } from "../main/business"
import { CustomerFields, Header, MainFields, VehicleFields, VehicleInfo } from "./components"

export default function SummaryComponent() {
    const { translateText } = useLocalization()
    const { vehicle } = useSelector(managerSelector)
    const history = useHistory()
    const matchParams = useParams<MatchParams>()

    const redirectToDashboard = useCallback(() => {
        const url = renderRoute("/:workTaskId", { ...matchParams })
        history.push(url)
    }, [])

    return (
        <Box>
            <Header />

            <VehicleInfo />

            {!vehicle && (
                <Box>
                    <Typography size="m">{translateText(1581)}</Typography>
                    <Button startIcon={<Icon name="dashboard" />} className="dashboard" onClick={redirectToDashboard}>
                        {translateText(760)}
                    </Button>
                </Box>
            )}

            {vehicle && (
                <>
                    <MainFields />
                    <VehicleFields />
                    <CustomerFields />
                </>
            )}
        </Box>
    )
}
