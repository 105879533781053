
import { AddToSCBasketState } from "./model"
import { AsyncAction } from "@tm/morpheus"
// import { createAddItemsToRdRequest } from "./helpers"
import { Article } from "@tm/models"


export type ComponentActionType =
    | { type: "ADD_ITEMS_TO_SC_BASKET_LOADED", payload: { itemID: string } }

const DEFAULT_STATE: AddToSCBasketState = {
    states: {}
}

export function reduce(state = { ...DEFAULT_STATE }, action: ComponentActionType): AddToSCBasketState {
    switch (action.type) {
        case "ADD_ITEMS_TO_SC_BASKET_LOADED": {
            const { itemID } = action.payload
            return {
                ...state,
                states: {
                    ...state.states,
                    [itemID]: { loadedSuccesfully: true }
                }
            }
        }
    }

    return state
}

function addItemToScBasket(items: Article[]): AsyncAction<ComponentActionType, AddToSCBasketState> {
    return (dispatch) => {
        const itemID = items[0].id

        dispatch({ type: "ADD_ITEMS_TO_SC_BASKET_LOADED", payload: { itemID } })
    }
}

export interface IActions {
    addItemToScBasket(items: Article[]): void
}

export const Actions: IActions = {
    addItemToScBasket
}
