import { Genart } from "../data/models/genArt"

export function genartComparatorWithoutQuantity(oldGenart: Genart, newGenart: Genart): boolean {
    const { article: oldArticle } = oldGenart
    const { article: newArticle } = newGenart

    const { quantity: oldQuantity, prices: oldPrices, ...restOldArticle } = oldArticle || {}
    const { quantity: newQuantity, prices: newPrices, ...restNewArticle } = newArticle || {}

    return JSON.stringify(restOldArticle) === JSON.stringify(restNewArticle)
}
