import { ajax } from "@tm/utils"
import { selector, useRecoilValue } from "recoil"
import { chatAuthorizationRecoilSelector } from "."
import { getBundleParams } from "../../utils"

const chatOwnUserIdRecoilSelector = selector({
    key: "notifications_chat_ownid",
    get: async ({ get }) => {
        const authorization = get(chatAuthorizationRecoilSelector)
        if (authorization) {
            const url = `${getBundleParams().chatApiUrl}/api/v1/users/getownid`
            const response = await ajax<{ userId: string }>({ url, method: "GET", authorization })
            return response?.userId
        }
    },
})

export function useOwnUserId() {
    return useRecoilValue(chatOwnUserIdRecoilSelector)
}
