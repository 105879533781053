import { Scrollbar } from "@tm/controls"
import { useCisCustomer, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Stack, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useExternalCatalogUrl } from "@tm/utils"
import AccountBalance from "./components/AccountBalance"
import AccountData from "./components/AccountData"
import SalesOutlet from "./components/SalesOutlet"
import ShippingType from "./components/ShippingType"
import Address from "./components/Address"
import ElectronicAddress from "./components/ElectronicAddress"
import Note from "./components/Note"
import { NoResultHint } from "../_shared/NoResultHint"
import Area from "./components/Area"
import Contact from "./components/Contact"

type Props = {
    externalSystemId?: number
}

export default function SummaryComponent({ externalSystemId }: Props) {
    const { translateText } = useLocalization()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })

    if (externalSystemId) {
        return <iframe src={externalCatalogUrl} title={externalCatalogUrl} style={{ flex: 1 }} />
    }

    if (cisCustomerLoading) {
        return <Loader />
    }

    if (!cisCustomer) {
        return <NoResultHint />
    }

    const { account, companyName, notes, shippingTypes, salesOutlets, addresses, electronicAddresses, contacts } = cisCustomer
    return (
        <Scrollbar>
            <Stack spacing={1} px={2} pt={0.5}>
                {account?.currencyCode && account?.creditInfo && (
                    <Area name={translateText(431)}>
                        <AccountBalance account={account} />
                    </Area>
                )}
                <Area name={translateText(500) + (companyName ? ` | ${companyName}` : "")}>
                    <AccountData customer={cisCustomer} />
                </Area>
                {!!notes?.length && (
                    <Area name={translateText(14)}>
                        {notes.map((note, id) => (
                            <Note key={id} note={note} />
                        ))}
                    </Area>
                )}
                {!!shippingTypes?.length && (
                    <Area name={translateText(438)}>
                        {shippingTypes.map((shippingType, id) => (
                            <ShippingType key={id} shippingType={shippingType} />
                        ))}
                    </Area>
                )}
                {!!salesOutlets?.length && (
                    <>
                        {salesOutlets.map((salesOutlet, id) => (
                            <SalesOutlet key={id} salesOutlet={salesOutlet} />
                        ))}
                    </>
                )}
                {!!addresses?.length && (
                    <Area name={translateText(437)}>
                        {addresses.map((address, id) => (
                            <Address key={id} address={address} />
                        ))}
                    </Area>
                )}
                {!!electronicAddresses?.length && (
                    <Area name={translateText(3143)}>
                        {electronicAddresses.map((electronicAddress, id) => (
                            <ElectronicAddress key={id} electronicAddress={electronicAddress} />
                        ))}
                    </Area>
                )}
                {!!contacts?.length && (
                    <Area name={translateText(13373)}>
                        {contacts?.map((contact, idx) => (
                            <Contact key={idx} contact={contact} />
                        ))}
                    </Area>
                )}
            </Stack>
        </Scrollbar>
    )
}
