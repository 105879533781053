import { Box, Button } from "@tm/components"
import { ArticleListSortingMode } from "@tm/models"
import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { ArticleSelection } from "../components/ArticleSelection"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { usePartsModuleState } from "../PartsModuleState"

export function UniversalPartsList() {
    const { translateText } = useLocalization()

    const { productGroups, setForProductGroups } = usePartsModuleState((x) => x.params.sorting)
    const noResult = usePartsModuleState((x) => x.params.noResult)
    const options = usePartsModuleState((x) => x.options)
    const isFiltersLoading = usePartsModuleState((x) => x.params.isFiltersLoading)
    const articleGroups = usePartsModuleState((x) => x.articles.articleGroups)
    const isLoading = usePartsModuleState((x) => x.articles.isLoading)
    const isLoaded = usePartsModuleState((x) => x.articles.isLoaded)
    const isFetchingNextPage = usePartsModuleState((x) => x.articles.isFetchingNextPage)
    const hasNextPage = usePartsModuleState((x) => x.articles.hasNextPage)
    const loadNextPage = usePartsModuleState((x) => x.articles.loadNextPage)
    const isStalling = usePartsModuleState((x) => x.articles.isStalling)
    const isFrontendFiltered = usePartsModuleState((x) => x.articles.isFrontendFiltered)

    const { selectedArticles } = useArticleSelection()
    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            setForProductGroups([productGroupId], value)
        },
        [setForProductGroups]
    )

    const showPageLoader = ((hasNextPage || isLoading || isFiltersLoading) && !isFrontendFiltered) || isStalling || isFetchingNextPage

    if (noResult) {
        return <NoSearchResult searchType="universal" />
    }

    return (
        <Box position="relative" top={distance}>
            <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={productGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                    />
                ))}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
