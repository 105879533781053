import { useLocalization } from "@tm/localization"
import { Tooltip, Icon, Switch } from "@tm/components"
import { BasketPart } from "../../../../../../models"

type Props = {
    part: BasketPart
    infosLoading?: boolean
    onIncludeExcludePart?(part: BasketPart): void
}

export default function PartOrderStatus({ part, infosLoading, onIncludeExcludePart }: Props) {
    const { partItem, erpInfoResponse, states } = part
    const { translateText } = useLocalization()

    return (
        <>
            {erpInfoResponse?.itemOrderableInformation && (
                //  NEXT-7023
                <Tooltip title={erpInfoResponse.itemOrderableInformation} variant="light">
                    <Icon name="attention-dark" />
                </Tooltip>
            )}
            {!erpInfoResponse?.itemOrderableInformation && (
                <Switch
                    label={translateText(292)}
                    checked={partItem.orderItem?.isOrderable && erpInfoResponse?.isOrderable ? partItem.orderItem.isIncluded : false}
                    disabled={
                        !partItem.orderItem?.isOrderable ||
                        !erpInfoResponse?.isOrderable ||
                        states.isErpInfoBeingLoaded ||
                        states.isBeingUpdated ||
                        infosLoading
                    }
                    size="small"
                    labelPlacement="top"
                    onChange={() => onIncludeExcludePart?.(part)}
                    typographySx={{ color: "black", textTransform: "none" }}
                />
            )}
        </>
    )
}
