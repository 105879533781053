import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { FavoriteOrderOptions } from "@tm/models"
import { getBundleParams } from "../utils"

export function useFavoriteOrderOptions() {
    const { userSettings } = useUser() || {}

    const favoriteOrderOptions = useMemo((): FavoriteOrderOptions => {
        if (getBundleParams().deactivateFavoriteOrderOptions) {
            return {}
        }

        return {
            shipmentMode: userSettings?.orderOptions.repairShopResponse?.shipmentModes?.find((mode) => mode.isSelected),
            paymentMode: userSettings?.orderOptions.repairShopResponse?.paymentModes?.find((mode) => mode.isSelected),
        }
    }, [userSettings?.orderOptions.repairShopResponse?.shipmentModes, userSettings?.orderOptions.repairShopResponse?.paymentModes])

    return { favoriteOrderOptions }
}
