import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, transmit } from "./business"

const widget: BundleComponent = {
    name: "direct-search",
    component,
    reduce,
    transmit,
}

export default widget
