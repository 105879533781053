import { useEffect } from "react"
import { useLocation } from "react-router"
import { Box } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { mainActions, useEdsStore } from "../../data/state"
import { EdsQueryParams, NavigationSteps } from "../../models"
import Content from "../content/Content"
import Header from "../header/Header"

export default function Main() {
    const location = useLocation()
    const vehicle = useWorkTask()?.workTask?.vehicle

    const selectedUnit = useEdsStore((state) => state.graphic.selectedUnit)
    const activeView = useEdsStore((state) => state.main.view)
    const initializedFromUrl = useEdsStore((state) => state.main.initializedFromUrl)
    const queryParams = new URLSearchParams(location.search)
    const initialVin = queryParams.get(EdsQueryParams.Vin)

    useEffect(() => {
        if (!initializedFromUrl && initialVin) {
            mainActions.initializeFromUrl(initialVin)
        }

        if (!initializedFromUrl && vehicle?.vin) {
            mainActions.initializeFromUrl(vehicle.vin, true)
        }
    }, [initializedFromUrl, initialVin, vehicle])

    return (
        <Box sx={(theme) => ({ margin: theme.spacing(2), flex: "1", display: "flex" })}>
            <Box flex="1" display="flex" flexDirection="column">
                {activeView === NavigationSteps.GraphicSearch && selectedUnit ? null : <Header />}
                <Content />
            </Box>
        </Box>
    )
}
