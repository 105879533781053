import * as React from "react"
import { OE } from "@tm/models"
import { Price } from "./Price"

type Props = {
    prices: Array<OE.OePartPrice>
    quantity: number
}

const Prices: React.FC<Props> = (props) => {
    if (!props.prices.length) {
        return null
    }

    return (
        <div className="oe-prices">
            {props.prices.map((price, key) => (
                <Price key={key} price={price} quantity={props.quantity}/>
            ))}
        </div>
    )
}

export default Prices
