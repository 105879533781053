import { ajax } from "@tm/utils"
import { PreyssRim } from "../../model"
import { mapStatics2RimJsonToPreyssRims } from "./mapper"
import { getBundleParams } from "../../../utils"

export function load3DAvailableRims() {
	const url = `${getBundleParams().imagesPath}rims.json`
	return new Promise<PreyssRim[]>((resolve, reject) =>
		ajax({ url }).then(
			response => resolve(mapStatics2RimJsonToPreyssRims(response)),
			reject
		)
	)
}

