import { useLocalization } from "@tm/localization"
import { Article, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Box, Button, Icon, Typography, SelectorPaper } from "@tm/components"
import { RowStack } from "../../../../../_shared/StyledComponents"

type Props = {
    allSelected: boolean
    selectedArticles: Partial<Article>[]
    onSelectAll(): void
    onUnselectAll(): void
}

export default function PartsSelection({ allSelected, selectedArticles, onSelectAll, onUnselectAll }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    return (
        <SelectorPaper>
            <RowStack spacing={1} alignItems="center">
                <Typography variant="label">
                    {translateText(701)}: {selectedArticles?.length || 0} {translateText(479)}
                </Typography>
                <Button onClick={() => (allSelected ? onUnselectAll() : onSelectAll())} size="small">
                    {translateText(allSelected ? 703 : 702)}
                </Button>
                <Box mr={1}>
                    {renderMicro("basket", "add-to-basket", {
                        data: selectedArticles as Article[],
                        hideQuantityField: true,
                    })}
                </Box>
                <Button variant="text" startIcon={<Icon name="cancel-x" />} size="large" title={translateText(317)} onClick={() => onUnselectAll()} />
            </RowStack>
        </SelectorPaper>
    )
}
