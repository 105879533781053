import { TitleType } from "@tm/models"
import { PrepareCalculationRequest } from "../../../../data/repositories"
import { MainState } from "../../../main"

export const createPrepareCalculationRequest = ({ summary: { fields, isGermanOrAustrianVehicle } }: MainState): PrepareCalculationRequest => {
    const titles = {
        [TitleType.Undefined]: undefined,
        [TitleType.Mister]: "titleMr",
        [TitleType.Miss]: "titleMrs",
        [TitleType.Company]: "titleCompany",
    }

    return {
        customerInfo: {
            city: fields.city ?? "",
            companyName: fields.companyName ?? "",
            country: fields.country ?? "",
            email: fields.email ?? "",
            firstName: fields.firstName ?? "",
            lastName: fields.lastName ?? "",
            phone: fields.phone ?? "",
            street: fields.street ?? "",
            streetNo: fields.streetNo ?? "",
            title: titles[fields.titleType!] ?? "",
            titleEntry: fields.titleType?.toString() ?? "",
            zip: fields.zip ?? "",
        },
        damageDate: new Date(),
        damageNumber: "",
        declarationOfAssignment: true,
        insuranceId: "",
        retention: true,
        retentionAmount: 0,
        repairCoverage: fields.repairCoverage ?? "",
        orderNumber: fields.processName ?? "",
        policyNumber: "",
        vatAtCalculationTime: 0,
        vehicleInfo: {
            datEcode: "",
            ...(isGermanOrAustrianVehicle && {
                kbaNo: fields.keyNumber,
            }),
            licenseNumber: fields.plateNumber ?? "",
            mileage: parseInt(fields.mileage?.toString() ?? "0") ?? 0,
            registrationDate: fields.initialRegistration!,
            vin: fields.vin ?? "",
        },
    }
}
