import * as React from "react"
import { style } from "typestyle"

import { concat, plateCountryCodeMapper } from "@tm/utils"
import { RealTable, Text, Button, LicensePlateField } from "@tm/controls"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"

import { Vehicle } from "../../business"
import { getCustomerNameAndCompany, getVehicleName } from "../../helpers"

import { Selection, getComponentStyles } from "."

type Props = {
    vehicles: Array<Vehicle>
    onVehicleSelect?(vehicle: Vehicle): void
    loading?: boolean
    label?: string
    noItemsMessage?: string
    withCustomerInfo?: boolean
    onVehicleAttach?(vehicle: Vehicle): void
    onLoadNextVehicles?(): void
    onShowArticleHistory?(vehicle: Vehicle): void
}

export const VehicleSelection: React.FC<Props> = (props) => {
    const className = style(getComponentStyles())
    const { translate } = useLocalization()

    const { plateCode: defaultPlateCode } = useCountryCodeToLicensePlate()
    const renderVehicleName = (vehicle: Vehicle) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>{getVehicleName(vehicle)}</Text>
            </RealTable.Cell>
        )
    }

    const renderLicensePlate = (vehicle: Vehicle) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                {vehicle.registrationInformation?.plateId && (
                    <LicensePlateField
                        readonly
                        value={vehicle.registrationInformation.plateId}
                        shortCountryCode={plateCountryCodeMapper(vehicle.countryCode || defaultPlateCode)}
                        size="l"
                    />
                )}
            </RealTable.Cell>
        )
    }

    const renderCustomerNameAndCompany = (vehicle: Vehicle) => {
        const { customer } = vehicle

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{customer && getCustomerNameAndCompany(customer)}</Text>
            </RealTable.Cell>
        )
    }

    const renderStreet = (vehicle: Vehicle) => {
        const { customer } = vehicle

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{customer?.generalAddress?.street}</Text>
            </RealTable.Cell>
        )
    }

    const renderCity = (vehicle: Vehicle) => {
        const { customer } = vehicle

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{customer?.generalAddress && concat(" ", customer.generalAddress.zip, customer.generalAddress.city)}</Text>
            </RealTable.Cell>
        )
    }

    const renderActions = (vehicle: Vehicle) => {
        const attach = (e: React.MouseEvent) => {
            e.stopPropagation()
            props.onVehicleAttach?.(vehicle)
        }

        const showPartsHistory = (e: React.MouseEvent) => {
            e.stopPropagation()
            props.onShowArticleHistory?.(vehicle)
        }

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <div className={`${className}__actions`}>
                    {props.onShowArticleHistory && (
                        <Button icon="article-history" onClick={showPartsHistory}>
                            {translate(1928)}
                        </Button>
                    )}
                    {props.onVehicleAttach && (
                        <Button icon="paperclip" onClick={attach}>
                            {translate(1041)}
                        </Button>
                    )}
                </div>
            </RealTable.Cell>
        )
    }

    const getTableColumns = () => {
        return [
            <RealTable.Column key="vehicle" renderItemContent={renderVehicleName} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(99)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="plate" renderItemContent={renderLicensePlate} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(21)}
                </Text>
            </RealTable.Column>,

            ...(props.withCustomerInfo
                ? [
                      <RealTable.Column key="name" renderItemContent={renderCustomerNameAndCompany} className={`${className}__column`}>
                          <Text size="xs" modifiers="sub" className={`${className}__header`}>
                              {translate(155)} / {translate(108)}
                          </Text>
                      </RealTable.Column>,
                      <RealTable.Column key="street" renderItemContent={renderStreet} className={`${className}__column`}>
                          <Text size="xs" modifiers="sub" className={`${className}__header`}>
                              {translate(111)}
                          </Text>
                      </RealTable.Column>,
                      <RealTable.Column key="city" renderItemContent={renderCity} className={`${className}__column`}>
                          <Text size="xs" modifiers="sub" className={`${className}__header`}>
                              {translate(112)}, {translate(113)}
                          </Text>
                      </RealTable.Column>,
                  ]
                : []),

            <RealTable.Column
                key="actions"
                renderItemContent={renderActions}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
        ]
    }

    return (
        <Selection
            className={className}
            items={props.vehicles}
            columns={getTableColumns()}
            onSelect={props.onVehicleSelect}
            loading={props.loading}
            label={props.label}
            noItemsMessage={props.noItemsMessage}
            onLoadNextItems={props.onLoadNextVehicles}
        />
    )
}
