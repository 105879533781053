import * as React from "react"

type Props = {
    content?: () => React.ReactNode
}

type State = {
    hasError: boolean
}

// Cannot be converted to function component since the getDerivedStateFromError has not a corresponding alternative in function components
export class ErrorBoundary extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    static getDerivedStateFromError(): Partial<State> {
        return {
            hasError: true,
        }
    }

    public reset() {
        this.setState({ hasError: false })
    }

    render() {
        if (this.state.hasError) {
            return this.props.content?.() || null
        }

        return this.props.children
    }
}
