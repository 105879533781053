import { useEffect, useMemo, useState } from "react"
import { classes, style } from "typestyle"
import {
    AllowedFileSelectionTypes,
    Button,
    DateField,
    Dialog,
    Dropdown,
    FileDropField,
    FileSelectError,
    Icon,
    NumberField,
    Text,
    TextField,
} from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { uniqueId } from "@tm/utils"
import { DocumentAdditionalData, FileAttachment, FileAttachmentMetaData } from "."
import { documentTypes } from "../../../../../data/documentTypes"
import { MergedVehicle } from "../../../../../data/hooks"
import { dialogClassName } from "../../../styles"
import { fileDropClassName, getComponentStyles, textOpacity } from "../style"
import { VehicleSelection } from "./vehicleSelection"

type Props = {
    onSave(attachment: FileAttachment): void
    onClose(): void
    vehicles: Array<MergedVehicle>
    initialCategory?: DocumentCategoryType
    initialExternalVehicleId?: string
}

const FILETYPS_AUDIO: AllowedFileSelectionTypes = ["audioMP3", "audioOgg", "audioWAV"]
const FILETYPS_VIDEO: AllowedFileSelectionTypes = ["videoMP4", "videoWebM", "videoOgg"]
const FILETYPS_DOCUMENT: AllowedFileSelectionTypes = ["pdf"]
const FILETYPS_PICTURE: AllowedFileSelectionTypes = ["gif", "jpg", "png"]
const FILETYPES: AllowedFileSelectionTypes = [...FILETYPS_AUDIO, ...FILETYPS_VIDEO, ...FILETYPS_DOCUMENT, ...FILETYPS_PICTURE]
const DEFAULT_MAX_FILE_SIZE = 10000 // 10mb
const MAX_FILE_SIZE_BY_TYPE = {
    gif: { size: 5000 },
    png: { size: 5000 },
    jpg: { size: 10000 },
    pdf: { size: 10000 },
    audioMP3: { size: 10000 },
    audioOgg: { size: 10000 },
    audioWAV: { size: 10000 },
    videoMP4: { size: 100000 },
    videoWebM: { size: 100000 },
    videoOgg: { size: 100000 },
}

export type DocumentCategoryType = keyof typeof documentTypes[number]["categories"]

export default function DocumentDialog({ onSave, onClose, vehicles, initialCategory, initialExternalVehicleId }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()
    const [category, setCategory] = useState<DocumentCategoryType>(initialCategory || "Others")
    const [data, setData] = useState<DocumentAdditionalData>({ date: new Date() })
    const [file, setFile] = useState<{ data: string; name: string; mimeType: string }>()
    const [error, setError] = useState<string>()
    const [vehicleIndex, setVehicleIndex] = useState(0)
    const [openVehicleSelection, setOpenVehicleSelection] = useState<boolean>(false)

    useEffect(() => {
        if (initialExternalVehicleId) {
            const index = vehicles.findIndex((v) => v.vehicleInfo.id === initialExternalVehicleId)
            if (index >= 0 && vehicleIndex !== index) {
                setVehicleIndex(index)
            }
        }
    }, [initialExternalVehicleId])

    const handleConfirm = () => {
        const vehicle = vehicles[vehicleIndex]
        if (file && vehicle) {
            let metaData: FileAttachmentMetaData
            if (category === "Offer") {
                metaData = {
                    type: "costEstimate",
                    offerId: uniqueId(),
                    externalOffer: true,
                    name: file.name,
                    description: translateText(82),
                    externalVehicleId: vehicle.vehicleInfo.id,
                    ...data,
                }
            } else {
                metaData = { type: "attachment", name: file.name, category, externalVehicleId: vehicle.vehicleInfo.id, ...data }
            }

            onSave({ file: file.data, mimeType: file.mimeType, metaData })
        }
    }

    const handleCategoryChange = (cat: DocumentCategoryType) => {
        if (data.invoiceTotal && cat !== "Invoice") {
            setData({ ...data, invoiceTotal: undefined })
        }
        setCategory(cat)
    }

    const onFileLoad = (data: string, name: string, mimeType: string) => {
        setError(undefined)
        setFile({ data, mimeType, name })
    }

    const onFileUploadError = (type: FileSelectError, options?: { maxAllowedFileSize: number }) => {
        if (type === "FILE_TOO_BIG") {
            setError(translateText(12797).replace("{0}", ((options?.maxAllowedFileSize || DEFAULT_MAX_FILE_SIZE) / 1000).toString()))
        } else if (type === "WRONG_FILE_TYPE") {
            setError(translateText(12798).replace("{0}", FILETYPES.toString()))
        }
    }

    const isPicture = (value: string) => {
        return FILETYPS_PICTURE.find((e: string) => value.endsWith(e))
    }

    const isValid = () => {
        return !!file && !!data.date && !!data.mileage && !openVehicleSelection && (category === "Invoice" ? !!data.invoiceTotal : true)
    }

    return (
        <Dialog
            ref={(ref) => ref?.show()}
            onConfirm={handleConfirm}
            confirmButtonText={translateText(22)}
            onClose={onClose}
            text={translateText(3030)}
            confirmButtonDisabled={!isValid()}
            className={dialogClassName}
        >
            <VehicleSelection
                vehicles={vehicles}
                vehicleIndex={vehicleIndex}
                setVehicleIndex={setVehicleIndex}
                onVehicleEdit={setOpenVehicleSelection}
                editVehicle={openVehicleSelection}
            />

            <div className={classNames.documentInfoWrapper}>
                <Text className={textOpacity} size="l" modifiers={["block", "strong"]}>
                    {translateText(12820)}
                </Text>
                <div className={style({ paddingTop: "0.5em" })}>
                    <CategorySelection category={category} setCategory={handleCategoryChange} />
                    <DataForm data={data} category={category} onChange={setData} />
                </div>
            </div>

            <FileDropField
                allowPaste
                className={fileDropClassName}
                allowedFileTypes={FILETYPES}
                maxFileSize={DEFAULT_MAX_FILE_SIZE}
                maxFileSizesByType={MAX_FILE_SIZE_BY_TYPE}
                onLoad={onFileLoad}
                onError={onFileUploadError}
            >
                {file ? (
                    <div className={classNames.filePreviewWrapper}>
                        <div className={classNames.filePreviewContainer}>
                            <Button
                                icon="close"
                                layout={["ghost"]}
                                onClick={() => setFile(undefined)}
                                className={style({ position: "absolute", right: "0", top: "0", zIndex: 999 })}
                            />
                            {isPicture(file.mimeType) ? (
                                <img src={file.data} className={style({ maxWidth: "20rem", maxHeight: "10rem", marginTop: "0.2rem" })} />
                            ) : (
                                <Icon name="document" className={style({ width: "80px", height: "120px" })} />
                            )}
                        </div>
                        <Text modifiers={["block"]} className={style({ textAlign: "center", paddingTop: "0.5em" })}>
                            {file.name}
                        </Text>
                    </div>
                ) : (
                    <>
                        <Button fakeButton>{translateText(1163)}</Button>
                        <Text className={style({ paddingTop: "0.5em" })}>{translateText(12789)}</Text>
                    </>
                )}
            </FileDropField>
            <Text modifiers={["danger", "block"]}>{error}</Text>
        </Dialog>
    )
}

function CategorySelection({ category, setCategory }: { category: DocumentCategoryType; setCategory(c: DocumentCategoryType): void }) {
    const { translateText } = useLocalization()
    const current = documentTypes.find((i) => i.categories[category])
    const HeadlinePadding = style({ padding: ".9em 0 .3em" })

    let buttons
    if (current?.categories && Object.keys(current.categories).length > 1) {
        buttons = (
            <div>
                {Object.keys(current.categories).map((key) => (
                    <Button
                        key={key}
                        fakeButton
                        onClick={() => setCategory(key as DocumentCategoryType)}
                        skin={key === category ? "primary" : undefined}
                        className={style({
                            padding: ".5em .6em",
                            $nest: { "&.btn--primary": { backgroundColor: "#2196f3" }, "&.btn--primary .btn__content": { opacity: 1 } },
                        })}
                    >
                        {translateText(current.categories[key as DocumentCategoryType]!)}
                    </Button>
                ))}
            </div>
        )
    }

    return (
        <div>
            <Text className={classes(HeadlinePadding, textOpacity)} size="s" modifiers={["block", "strong"]}>
                {translateText(158).toUpperCase()}
            </Text>
            <Dropdown<typeof documentTypes[number]>
                items={documentTypes}
                value={current}
                onChange={(item) => setCategory(Object.keys(item.categories)[0] as DocumentCategoryType)}
                itemView={({ name }) => <>{translateText(name)}</>}
                className={style({ minWidth: "12em" })}
            />
            {buttons && (
                <Text className={classes(HeadlinePadding, textOpacity)} size="s" modifiers={["block", "strong"]}>
                    {translateText(12833).toUpperCase()}
                </Text>
            )}
            {buttons}
        </div>
    )
}

function DataForm({
    data,
    onChange,
    category,
}: {
    data: DocumentAdditionalData
    category: DocumentCategoryType
    onChange(data: DocumentAdditionalData): void
}) {
    const specialInputStyling = style({
        marginRight: "0.4rem",
        $nest: {
            ".input__label": {
                marginLeft: "-.5em",
                marginTop: "-50px",
                position: "absolute",
                pointerEvents: "none",
            },
            ".input__field": {
                padding: "0",
                fontSize: "13px",
            },
            ".input__inner": {
                padding: ".3em",
                display: "flex",
            },
        },
    })
    const { translateText, number } = useLocalization()
    const [milageInput, setMilageInput] = useState<string>("")

    const parseMilageToInt = (mileage?: string) => {
        return parseInt(`${mileage}`.replace(/[\.,\D]/g, ""))
    }

    const handleMileageChange = (mileage?: string) => {
        const milageInt = parseMilageToInt(mileage)
        if (!isNaN(milageInt)) {
            const formatMilAge = number(milageInt, 0)
            setMilageInput(formatMilAge || "")
        } else {
            setMilageInput("")
        }
    }

    const onChangeConfirm = (mileage?: string) => {
        const milageInt = parseMilageToInt(mileage)
        onChange({ ...data, mileage: milageInt || undefined })
    }

    return (
        <div className={style({ display: "flex", marginTop: "2em" })}>
            <TextField
                className={`${specialInputStyling} is-required`}
                label={translateText(125).toUpperCase()}
                onChange={handleMileageChange}
                onChangeConfirm={onChangeConfirm}
                maxLength={9}
                value={milageInput}
                pattern={/[\d.,]*/}
                showClear
            />

            <DateField
                className={`${specialInputStyling} is-required`}
                label={translateText(3089).toUpperCase()}
                value={data.date}
                onChange={(date: Date) => {
                    onChange({ ...data, date })
                }}
                showClear
            />

            {category === "Invoice" && (
                <NumberField
                    className={`${specialInputStyling} is-required`}
                    label={translateText(3090).toUpperCase()}
                    nullable
                    value={data.invoiceTotal}
                    onChange={(invoiceTotal: number) => {
                        onChange({ ...data, invoiceTotal })
                    }}
                    minimum={0.0}
                    maximum={9999999}
                    stepSize={0.01}
                    enforceDecimalDigits
                    showClear
                />
            )}
        </div>
    )
}
