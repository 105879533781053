import { ErpInformationRequestItem, IErpRequest } from "@tm/models"

export enum TypeOfContact {
    Undefined = 0,
    Email = 1,
    Phone = 2,
}

export type RequestMissingArticlesRequest = {
    warehouseNumber?: string
    contactPersonName?: string
    contactPersonPhoneNumber: string
    contactPersonEMailAddress: string
    additionalInformation?: string
    typeOfContact: TypeOfContact
    items: Array<ErpInformationRequestItem>
    customerNumberToShow?: string
    wareHouseEMailAddress: string | undefined
    companyName: string | undefined
    companyNameAddition1: string | undefined
    companyNameAddition2: string | undefined
}

export type RequestMissingArticlesResponse = {
    emailAddress: string
    messageID: string
    messageDescription: string
}

export type GetNextToursRequest = IErpRequest
export type GetPromotedItemsRequest = IErpRequest
