import { combineReducers } from "redux"
import { CalculationActions, ExternActions, ManagerActions, NavigationActions, SettingsActions, SummaryActions } from "../../../actions"
import {
    CalculationActionTypes,
    calculationReducer,
    ExternActionTypes,
    externReducer,
    ManagerActionTypes,
    managerReducer,
    NavigationActionTypes,
    navigationReducer,
    SettingsActionTypes,
    settingsReducer,
    SummaryActionTypes,
    summaryReducer,
} from "../../../reducers"

export const reduce = combineReducers({
    manager: managerReducer,
    navigation: navigationReducer,
    summary: summaryReducer,
    extern: externReducer,
    calculation: calculationReducer,
    settings: settingsReducer,
})

export const MainActions = {
    ...ManagerActions,
    ...NavigationActions,
    ...SummaryActions,
    ...ExternActions,
    ...CalculationActions,
    ...SettingsActions,
}

export type MainActionsType =
    | ManagerActionTypes
    | NavigationActionTypes
    | SummaryActionTypes
    | ExternActionTypes
    | CalculationActionTypes
    | SettingsActionTypes
