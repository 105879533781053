import { BundleComponent } from "@tm/morpheus"
import { reduce, receive } from "./business"
// import component from "./component"

const startWidget: BundleComponent = {
    name: "add-work",
    reduce,
    receive,
    component: () => null, // component,
}

export default startWidget
