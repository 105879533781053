import { em } from "csx"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Icon, SuggestionFieldButtonGroup, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { MainState } from "../main/business/model"

type Props = {
    onSubmit: (value: string) => void
    icon?: string
}

export default function ErrorSearch({ onSubmit, icon }: Props) {
    const { translateText } = useLocalization()
    const loading = useSelector((s: MainState) => s.isLoading)
    const style = useMemo(() => getStyle(), [])
    const [value, setValue] = useState("")

    useEffect(() => {
        if (!loading) {
            setValue("")
        }
    }, [loading])

    const onSubmitHandler = () => {
        onSubmit(value.toUpperCase())
    }

    const onChange = (val: string) => {
        setValue(val.toUpperCase().replace(/\s/g, ""))
    }

    return (
        <div className={classes("error-search", style.errorSearch)}>
            <SuggestionFieldButtonGroup
                size="m"
                value={value}
                buttonIcon={icon}
                onChange={onChange}
                placeholder={translateText(12925)}
                onChangeConfirm={onSubmitHandler}
                maxLength={50}
                handleSearchButtonClick={onSubmitHandler}
                showClear
                suggestions={[]}
                minCharactersToSuggest={2}
                suggestDelay={500}
                requestSuggestions={() => false}
            />
            <div className={style.tip}>
                <Icon name="info" />
                <Text className="tip" size="s">
                    {translateText(12924)}
                </Text>
            </div>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useStyle({
        errorSearch: {
            maxWidth: em(32),
        },
        tip: {
            marginTop: theme.margin.m,
            display: "flex",
            $nest: {
                ".text": {
                    paddingTop: em(0.2),
                    paddingLeft: em(0.5),
                },
            },
        },
    })(ErrorSearch)
}
