import { useState } from "react"
import { DialogContent, DialogActions, DialogContentText, Button } from "@tm/components"
import { Customer, Vehicle } from "@tm/models"
import { useLocalization } from "@tm/localization"
import Finish from "./finish"
import { CustomerInfo } from "../../../../../../data"
import { OpenWorkTaskDialogState } from ".."

type Props = {
    chatId: string
    customer: Customer | undefined
    vehicle: Vehicle | undefined
    tmCustomerId: string | undefined
    customerInfo: CustomerInfo
    openOptions: OpenWorkTaskDialogState
}

function hasConflict(customer: Customer | undefined, vehicle: Vehicle | undefined) {
    const customerId = customer?.id
    return !!customerId && !!vehicle?.customerId && vehicle.customerId !== customerId
}

export default function ConflictStart({ chatId, customer, vehicle, tmCustomerId, customerInfo, openOptions }: Props) {
    const { translateText } = useLocalization()
    const [conflictResolution, setConflictResolution] = useState<"switch" | "new">()

    const hasCustomerVehicleConflict = hasConflict(customer, vehicle)

    if (hasCustomerVehicleConflict && !conflictResolution) {
        return (
            <>
                <DialogContent>
                    <DialogContentText>{translateText(12990)}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setConflictResolution("switch")
                        }}
                    >
                        {translateText(12991)}
                    </Button>
                    <Button
                        onClick={() => {
                            setConflictResolution("new")
                        }}
                    >
                        {translateText(12501)}
                    </Button>
                </DialogActions>
            </>
        )
    }

    return (
        <Finish
            chatId={chatId}
            customer={customer}
            vehicle={vehicle}
            tmCustomerId={tmCustomerId}
            customerInfo={customerInfo}
            openOptions={openOptions}
            conflictResolution={conflictResolution}
        />
    )
}
