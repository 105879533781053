import { AddCustomWorkListRequest, RepairTimeProvider, WorkCategory } from "@tm/models"

import { PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"

export type AddCustomRepairTimesToBasketRequest = {
    division?: number
    provider?: RepairTimeProvider
    repairTimes: Array<CustomRepairTime>
}

type CustomRepairTime = {
    id: string
    description: string
    categoryOfWork?: WorkCategory
    time?: number
    hourlyRate?: number
    fixedPriceValue?: number
    rebate?: number
    surcharge?: number
}

export default function handleAddCustomRepairTimesToBasket(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { workTask, addCustomWorkList } = this.props
    const { addCustomRepairTimesToBasket } = data

    if (!addCustomRepairTimesToBasket || !workTask) {
        return
    }

    const { division, provider, repairTimes } = addCustomRepairTimesToBasket

    if (!repairTimes?.length) {
        return
    }

    const request: AddCustomWorkListRequest = {
        workTaskId: workTask.id,
        vehicleId: workTask.vehicle?.id,
        repairTimeDivision: division,
        repairTimeProvider: provider,
        customWorks: repairTimes.map((x) => ({
            customWorkNumber: x.id,
            categoryOfWork: x.categoryOfWork ?? WorkCategory.NotCategorized,
            description: x.description,
            time: x.time,
            hourlyRate: x.hourlyRate,
            fixedPriceValue: x.fixedPriceValue,
            rebate: x.rebate,
            surcharge: x.surcharge,
        })),
    }

    addCustomWorkList(request, true)
}
