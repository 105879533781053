/* eslint-disable camelcase */
export type LoginResponse = {
    access_token: string
    expires_in: number
    token_type: string
}

export type LoginErrorResponse = {
    error: LoginErrors
}

export enum LoginErrors {
    InvalidClient = "invalid_client",
}
