import { IBundle } from "@tm/morpheus"
import Manager from "./components/manager"
import Search from "./components/search"
import ModuleNavigation from "./components/module-navigation"
import CreateButton from "./components/create-button"
import WorkTaskNote from "./components/worktask-note"
import ChangeWorktaskConflictResolver from "./components/worktask-change-dialog"
import WorkTaskInfo from "./micros/worktask-info"
import RecentList from "./components/recent-list"
import Welcome from "./components/welcome"
import ForceWorktaskPlugin from "./plugins/force-worktask"
import ForceWorktaskAfterLoginPlugin from "./plugins/force-worktask-after-login"
import ForceNewWorktaskPlugin from "./plugins/force-new-worktask"
import { initWorkTaskBundle, version } from "./utils"
import CompleteWorkTaskButton from "./micros/CompleteWorkTaskButton"
import TelesalesCustomerNotification from "./components/telesales-customer-notification"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        Welcome,
        RecentList,
        Manager,
        Search,
        CreateButton,
        ModuleNavigation,
        ChangeWorktaskConflictResolver,
        WorkTaskNote,
        TelesalesCustomerNotification,
    ],
    micros: [WorkTaskInfo, CompleteWorkTaskButton],
    plugins: [ForceWorktaskPlugin, ForceWorktaskAfterLoginPlugin, ForceNewWorktaskPlugin],
    init: initWorkTaskBundle,
}

export default bundle
