import { useLocalization } from "@tm/localization"
import { SelectedPriceVatMode } from "@tm/models"
import { B2BTotals, B2CTotals } from "@tm/data/vouchers/costEstimations"
import Total from "../../Total"

type Props = {
    priceVatMode: SelectedPriceVatMode
    totals?: B2CTotals | B2BTotals
    currencyCode: string
}

export function PartsAndWorks({ totals, currencyCode, priceVatMode }: Props) {
    const { translateText, currency } = useLocalization()
    let partsDescription = translateText(1720)
    let worksDescription = translateText(1721)

    if (!priceVatMode) {
        partsDescription += totals?.alreadyTaxedPartCosts ? ` (${translateText(12830)})` : ""
        worksDescription += totals?.alreadyTaxedWorkCosts ? ` (${translateText(12830)})` : ""
    }

    return (
        <>
            <Total label={partsDescription} value={currency(totals?.partCosts || 0, currencyCode)} />
            <Total label={worksDescription} value={currency(totals?.workCosts || 0, currencyCode)} />
        </>
    )
}
