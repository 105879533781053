import { ActionDispatch, AsyncAction } from "@tm/morpheus"
import { TeccomErpInformation } from "@tm/models"
import { StockInfoState } from "./model"
import { BundleActionType } from "../../../business"
import * as Data from "../../../data"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "ERP_LOADING" }
    | { type: "ERP_LOADED" }
    | { type: "TECCOM_LOADING" }
    | { type: "TECCOM_LOADED"; payload: TeccomErpInformation | undefined }
    | { type: "TECCOM_ERROR"; payload: { message?: string } }
    | { type: "TECCOM_DETAILS_RESET" }

const DEFAULT_STATE: StockInfoState = {
    loading: true,
    loaded: false,
    teccomLoading: false,
    teccomLoaded: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): StockInfoState {
    switch (action.type) {
        case "ERP_INFO_DETAILS_LOADED": {
            state = {
                ...state,
                loading: false,
                loaded: true,
                erpInfo: action.payload.response,
                request: action.payload.request,
            }

            if (action.payload.response?.specialProcurementErpInformation) {
                state = {
                    ...state,
                    teccomLoaded: true,
                    teccomErpInfo: { ...action.payload.response },
                }
            }

            return state
        }
        case "ERP_INFO_DETAILS_ERROR": {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.payload,
            }
        }
        case "ERP_LOADING": {
            return {
                ...state,
                loading: true,
                teccomError: undefined,
                error: undefined,
            }
        }
        case "ERP_LOADED": {
            return {
                ...state,
                loading: false,
            }
        }
        case "ERP_INFO_DETAILS_RESET": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "TECCOM_LOADING": {
            return {
                ...state,
                teccomLoading: true,
                teccomLoaded: false,
                teccomError: undefined,
                error: undefined,
            }
        }
        case "TECCOM_LOADED": {
            return {
                ...state,
                teccomLoading: false,
                teccomLoaded: true,
                teccomErpInfo: action.payload,
            }
        }
        case "TECCOM_ERROR": {
            return {
                ...state,
                teccomLoading: false,
                teccomLoaded: true,
                teccomError: action.payload,
            }
        }
        case "TECCOM_DETAILS_RESET": {
            return {
                ...state,
                ...DEFAULT_STATE,
                teccomErpInfo: undefined,
                loading: false,
                loaded: true,
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "ERP_INFO_DETAILS_LOADED":
        case "ERP_INFO_DETAILS_ERROR":
        case "ERP_INFO_DETAILS_RESET": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

function loadTeccom(
    quantityValue: number,
    telesalesCustomerNo: string | undefined,
    distributorId: number | undefined
): AsyncAction<ComponentActionType, StockInfoState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.teccomLoading || !state.request) {
            return
        }

        dispatch({ type: "TECCOM_LOADING" })

        const request = { ...state.request, quantityValue }

        Data.getErpInfosTeccom(request, telesalesCustomerNo, distributorId)
            .then((response) => {
                dispatch({ type: "TECCOM_LOADED", payload: response })
            })
            .catch((error) => {
                dispatch({ type: "TECCOM_ERROR", payload: error })
            })
    }
}

function resetTeccom(): AsyncAction<ComponentActionType, StockInfoState> {
    return (dispatch, getState) => {
        dispatch({ type: "TECCOM_DETAILS_RESET" })
    }
}

function setErpLoading(): AsyncAction<ComponentActionType, StockInfoState> {
    return (dispatch, getState) => {
        dispatch({ type: "ERP_LOADING" })
    }
}

function setErpLoaded(): AsyncAction<ComponentActionType, StockInfoState> {
    return (dispatch, getState) => {
        dispatch({ type: "ERP_LOADED" })
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadTeccom,
    resetTeccom,
    setErpLoading,
    setErpLoaded,
}
