import { CarmunicationBundleParams } from "@tm/models"
import { initRepositories } from "./data/repositories"

export const version = {
    name: "carmunication",
    version: "1.0"
}
export let bundleParams: CarmunicationBundleParams | undefined

export function initCarmunicationBundle(params: CarmunicationBundleParams) {
    bundleParams = params
    initRepositories()
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw (`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
