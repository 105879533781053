import { useLocalization } from "@tm/localization"
import { MergedAppointment, useCalendarInfos } from "../../../../../../data/hooks"
import EditAppointmentButtons from "../../../../../_shared/edit-appointment-buttons"
import WideButton from "../../wide-button"
import { getDateWithoutTime } from "../../../../../_shared/helper"

type Props = {
    appointment?: MergedAppointment
}

export function getDateFromMetadata(appointment: MergedAppointment) {
    let date = appointment.initial.preferedDate
    if (appointment.last?.action === "customerProposal" || appointment.last?.action === "repairshopProposal") {
        date = appointment.last.proposedDate
    }
    return date
}

export default function VehicleAppointmentInfo({ appointment }: Props) {
    const { translateText, date: formatDate } = useLocalization()
    if (
        !appointment ||
        getDateWithoutTime(getDateFromMetadata(appointment)) < getDateWithoutTime(new Date()) ||
        appointment.last?.action === "customerRejection" ||
        appointment.last?.action === "repairshopRejection"
    ) {
        return <WideButton text={translateText(207)} icon="calendar" disabled />
    }

    const { initial, last, initialOrLastMessage } = appointment

    const date = getDateFromMetadata(appointment)

    const { endDate } = initial

    const { calendarUrl } = useCalendarInfos(initial, date, endDate)

    if (last?.action !== "repairshopConfirmation" && last?.action !== "customerConfirmation") {
        return <EditAppointmentButtons appointmentId={initial.appointmentId} date={date} message={initialOrLastMessage} asPopover />
    }

    return <WideButton text={formatDate(new Date(date), "g")} icon="calendar" linkTo={calendarUrl} />
}
