import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { ErpPrice, OrderOptions, PriceType } from "@tm/models"
import { Stack, Typography, Divider, styled } from "@tm/components"

type Props = {
    orderOptions?: OrderOptions
    procurementPrice?: ErpPrice
    useSpecialProcurement?: boolean
}

export default function PartAdditionalPricesComponent({ orderOptions, procurementPrice, useSpecialProcurement }: Props) {
    const { translateText, currency } = useLocalization()

    const additionalPrices = useMemo(() => {
        const prices: Array<{ name: string; price: string; priceType?: PriceType }> = []
        const selectedErpItem = orderOptions?.shipmentModes?.shipmentModes.find((e) => e.isSelected)
        if (selectedErpItem?.costs?.value) {
            prices.push({
                name: useSpecialProcurement ? translateText(3138) : `${selectedErpItem.description || ""} ${translateText(13139)}`,
                price: currency(selectedErpItem?.costs.value, selectedErpItem?.costs.currencySymbol),
            })
        } else if (procurementPrice?.value) {
            prices.push({
                name: procurementPrice.description || translateText(13139),
                price: currency(procurementPrice.value, procurementPrice.currencySymbol),
                priceType: PriceType.ProcurementCosts,
            })
        }

        return prices
    }, [procurementPrice, orderOptions?.shipmentModes?.shipmentModes, useSpecialProcurement, translateText, currency])

    return (
        <>
            {additionalPrices.map((price, id) => {
                return (
                    <Stack key={id} alignSelf="flex-end" sx={(theme) => ({ backgroundColor: "background.paper", boxShadow: theme.shadows[1] })}>
                        <Stack spacing={4} p={0.5} direction="row" justifyContent="space-between">
                            <Typography>{price.name}</Typography>
                            <Stack direction="row" spacing={1}>
                                <Typography>{translateText(55)}</Typography>
                                <Typography color="highlight">{price.price}</Typography>
                            </Stack>
                        </Stack>
                        <Divider sx={{ borderBottomWidth: 1 }} />
                    </Stack>
                )
            })}
        </>
    )
}
