import { Box, Typography, styled } from "@tm/components"
import { Icon } from "@tm/controls"
import { Memo_V1 } from "@tm/models"
import { sanitize } from "@tm/utils"

type Props = {
    item: Memo_V1
}

function HtmlItem(props: Props) {
    const { item } = props
    const sanitizedHTML = sanitize(item.richText, { USE_PROFILES: { html: true }})

    return (
        <Box sx={{ margin: (theme) => theme.spacing(1.5, 0) }}>
            <Box sx={{ display: "inline-flex", flex: 1, alignItems: "center", margin: (theme) => theme.spacing(1, 0) }} key={item.id}>
                <StyledIcon name="warning-dark" size="xl" />
                <Typography variant="h4">{item.code_Path}</Typography>
            </Box>
            <Box dangerouslySetInnerHTML={{ __html: `${sanitizedHTML}` }}></Box>
        </Box>
    )
}

const StyledIcon = styled(Icon)(({ theme }) => ({
    margin: theme.spacing(0, 1)
}))

export default HtmlItem