import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit } from "./business"
import component from "./component"

const repairTimesSelection: BundleComponent<unknown, typeof component> = {
    name: "repair-times-selection",
    reduce,
    component,
    transmit,
}

export default repairTimesSelection
