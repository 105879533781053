import { Box, styled } from "@tm/components"
import { percent, rem } from "csx"
import * as React from "react"
import { DictionaryItem } from "../../model"

type Props = {
    showOnlyFilledValues?: boolean
    items: Array<DictionaryItem>
}

const Dictionary: React.FC<Props> = ({ showOnlyFilledValues, items }) => {
    const renderItem = (item: DictionaryItem, idx: number) => {
        if (showOnlyFilledValues && item.value == null) {
            return
        }

        let content: React.ReactNode = item.value

        if (item.valueRenderer) {
            content = item.valueRenderer(item.value)
        } else if (Array.isArray(item.value)) {
            content = item.value.join(", ")
        }

        return (
            <StyledDictionaryItem key={idx}>
                <div className="key" key={item.key}>
                    {item.key}
                </div>
                <div className="value">{content}</div>
            </StyledDictionaryItem>
        )
    }

    return <StyledWrapper>{items.map(renderItem)}</StyledWrapper>
}

const StyledWrapper = styled(Box)(({ theme }) => ({
    display: "block",
    columns: 2,
    columnGap: 2,
    margin: theme.spacing(rem(-0.5), 0),
}))

const StyledDictionaryItem = styled(Box)(({ theme }) => ({
    display: "flex",
    flexBasis: percent(50),
    padding: theme.spacing(rem(0.5), 0),
    ".key": {
        flexBasis: percent(50),
    },
    ".value": {
        flexBasis: percent(50),
        fontWeight: theme.typography.fontWeightBold,
    },
}))

export default Dictionary
