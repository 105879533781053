import { Container } from "@tm/nexus"
import { useEffect, useMemo, useState } from "react"
import { RegisteredModels } from "@tm/models"
import { encodeUniqueId } from "@tm/utils"
import { useLocation } from "react-router"
import { bundleChannel } from "../../business"
import { getBundleParams } from "../../utils"
import { useCostEstimationModuleParameter } from "../useCostEstimationModuleParameter"

type ViewStateEntry = { key: string; value: { state: boolean } }

export function useCostEstimationVisible(workTaskId: string) {
    const { nextLight, hideCostEstimationButton, costEstimationAlwaysVisible } = getBundleParams()
    const location = useLocation()
    const { hasCostEstimation } = useCostEstimationModuleParameter()

    const [showCostEstimation, setShowCostEstimation] = useState(hasCostEstimation)
    const staticIsVisible = !nextLight && (costEstimationAlwaysVisible || location.pathname.includes("/cost-estimation"))

    const costEstimationVisible = useMemo((): boolean | undefined => {
        if (staticIsVisible) {
            return true
        }

        if (hideCostEstimationButton) {
            return false
        }

        return showCostEstimation
    }, [showCostEstimation, staticIsVisible, hideCostEstimationButton])

    useEffect(() => {
        // TODO: Remove Hermes and Nexus usages. Instead, store state in recoil with ViewState synchronization
        const container = Container.getInstance<ViewStateEntry>(RegisteredModels.ViewState)
        container
            .subscribe(`${encodeUniqueId(workTaskId)}__COST_ESTIMATION_VISIBLE`)
            .load()
            .then(updateState)

        const containerSubscription = container.subscribe("COST_ESTIMATION_VISIBLE")
        containerSubscription.addListener("loaded", updateState)
        containerSubscription.addListener("updated", updateState)

        const unsubscribeFromBundleChannel = bundleChannel().subscribe("ENABLE_COST_ESTIMATION", () => setShowCostEstimation(true))

        return () => {
            unsubscribeFromBundleChannel()
            containerSubscription.removeAllListeners()
        }
    }, [workTaskId])

    function updateState(resp: ViewStateEntry) {
        if (resp.value?.state) {
            setShowCostEstimation(true)
        } else {
            setShowCostEstimation(false)
        }
    }

    return { costEstimationVisible }
}
