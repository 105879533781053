import { ReactNode, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Grid, GridProps, List, Text } from "@tm/controls"
import { classes } from "@tm/utils"
import { DopCollapsible } from "."
import { IRowsItem, ISectionsItem } from "../../../data/models"
import Field from "./field"

type Props = {
    section: ISectionsItem
    aditionalContent?: ReactNode
}

const grids: Record<number, Partial<Record<"m" | "s" | "xs", GridProps["m"]>>> = {
    1: { m: 12 },
    2: { m: 6, s: 12 },
    3: { m: 4, s: 12 },
    4: { m: 3, s: 6, xs: 12 },
}

export default function Section({ section, aditionalContent }: Props) {
    const style = useMemo(() => getStyle(), [])

    const renderView = (x: IRowsItem) => (
        // <Toolbar modifiers={[]} title={x.description}>
        <Grid container className={classes(style.row, "field-error")}>
            <Text modifiers={["sub"]} className={style.rowTitle}>
                {x.description}
            </Text>
            <Grid container className={style.rowContent}>
                {x.columns.map((col, dix) => (
                    <Grid {...grids[x.columns.length]} key={dix} container className={style.col}>
                        {col.fields.map((field) => (
                            <Field key={field.id} item={field} />
                        ))}
                    </Grid>
                ))}
            </Grid>
        </Grid>
    )
    // </Toolbar>

    return (
        <DopCollapsible title={section.name} checked={section.markedComplete}>
            <Grid container>
                <Grid m={aditionalContent ? 8 : 12}>
                    <List className={style.list} items={section.rows} view={renderView} />
                </Grid>
                {aditionalContent && <Grid m={4}>{aditionalContent}</Grid>}
            </Grid>
        </DopCollapsible>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        row: {
            padding: theme.margin.s,
            display: "flex",
            flexDirection: "column",
        },
        rowTitle: {
            // flexBasis: em(15)
            marginLeft: theme.margin.m,
        },
        rowContent: {
            flex: 1,
            display: "flex",
        },
        col: {
            // flex: 1,
            padding: theme.margin.m,
            display: "flex",
        },
        field: {
            flex: 1,
        },
        list: {
            flex: 1,
        },
    })(Section)
}
