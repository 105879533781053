import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Text, Button, Card } from "@tm/controls"
import { LinearProgress, Stack } from "@tm/components"
import { CompilationArticleItem } from "./components/CompilationArticleItem"
import { useCompilationDetailsArticleList } from "./hooks"
import { ArticleListActionsProvider } from "../ListV2/ArticleListActions"
import { ArticleListActions } from "../ListV2/models"
import { ArticleListConfigurationProvider } from "../ListV2/ArticleListConfiguration"
import { CompilationShowViewModelItem } from "../../../../compilations/src/data"
import { ArticleListOptions } from "./components/ArticleListOptions"
import { ArticleGroupHeader } from "../list/components/ArticleGroupHeader/ArticleGroupHeader"

type Props = {
    encodedWorkTaskId?: string
    compilationId: string
    parts: Array<CompilationShowViewModelItem>
    elementId?: string
}

export function CompilationArticleList(props: Props) {
    const { elementId, ...rest } = props

    return (
        <ArticleListConfigurationProvider>
            <>
                <ArticleListOptions elementId={elementId} />
                <CompilationArticleListComponent {...rest} />
            </>
        </ArticleListConfigurationProvider>
    )
}

function CompilationArticleListComponent({ parts, compilationId, encodedWorkTaskId }: Props) {
    const { translateText } = useLocalization()

    const {
        isLoading,
        groupedArticles,
        hideExtendedDeliveryComponent,
        erpInfosData,
        productGroupRepairTimes,
        activeVehicleDataProviders,
        listOptions,
        basketQuantities,
        previouslyOrderedArticles,
        updateBasketQuantities,
    } = useCompilationDetailsArticleList(compilationId, parts)

    const articleListActions = useMemo<ArticleListActions>(() => ({}), [])

    if (isLoading) {
        return <LinearProgress />
    }

    if (!groupedArticles?.length) {
        return (
            <Card className="empty-list">
                <Text>{translateText(217)}</Text>
                <div className="empty-list__dashboard-button">
                    <Button icon="dashboard" linkTo={`/${encodedWorkTaskId}`}>
                        {translateText(760)}
                    </Button>
                </div>
            </Card>
        )
    }

    return (
        <ArticleListActionsProvider value={articleListActions}>
            <Stack flex={1} padding={0.5} position="relative" className="part-list">
                {groupedArticles.map((group) => {
                    const { productGroup, articles } = group
                    const title = productGroup.id === -1 || !productGroup.name ? translateText(13859) : productGroup.name
                    return (
                        <Stack key={productGroup.id}>
                            <ArticleGroupHeader title={title} productGroupId={productGroup.id} groupArticlesCount={articles.length} />
                            <Stack padding={0.5}>
                                {articles.map((article) => (
                                    <CompilationArticleItem
                                        key={article.id}
                                        article={article}
                                        compilationId={compilationId}
                                        hideExtendedDeliveryComponent={hideExtendedDeliveryComponent}
                                        erpInfosData={erpInfosData}
                                        productGroupRepairTimesData={productGroupRepairTimes}
                                        activeVehicleDataProviders={activeVehicleDataProviders}
                                        listOptions={listOptions}
                                        basketQuantities={basketQuantities}
                                        previouslyOrderedArticles={previouslyOrderedArticles}
                                        updateBasketQuantities={updateBasketQuantities}
                                    />
                                ))}
                            </Stack>
                        </Stack>
                    )
                })}
            </Stack>
        </ArticleListActionsProvider>
    )
}
