import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { GetSavedCalculationsRequest, GetSavedCalculationsResponse, SavedCalculation } from "./model"

export function getSavedCalculations(data: GetSavedCalculationsRequest): Promise<Array<SavedCalculation>> {
    const url = `${getServiceUrl()}/fastcalculator/GetSavedCalculations`
    const authorization = getStoredAuthorization()
    const body = data

    return new Promise<Array<SavedCalculation>>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then(
            (response: GetSavedCalculationsResponse) => resolve(response?.savedCalculations || []),
            reject
        )
    )
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
