import { Stack } from "@tm/components"
import { CostEstimationOptions, CisVoucherType } from "@tm/models"
import PartOrderInfo from "./PartOrderInfo"
import PartsTable from "./PartsTable"
import { BasketErpIndicator, BasketPart } from "../../../../../models"
import ErrorAlert from "../../../../_shared/ErrorAlert"

type Props = {
    costEstimationOptions?: CostEstimationOptions
    voucherTypeId?: CisVoucherType
    externalSystemId?: number
    currencyCode: string
    calculationLoading?: boolean
    cisVoucherUrl?: string
    creatingCostEstimation: boolean
    basketErpIndicator: BasketErpIndicator
    showAllPrices?: boolean
    showManufacturer: boolean
    showPurchasePrice?: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    part: BasketPart
    workTaskId?: string
    onSelectPart(id: string): void
    onIncludeExcludePart(part: BasketPart): void
}

export default function PartFooter(props: Props) {
    const {
        part,
        currencyCode,
        cisVoucherUrl,
        creatingCostEstimation,
        showAllPrices,
        basketErpIndicator,
        showManufacturer,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        showPurchasePrice,
        calculationLoading,
        costEstimationOptions,
        externalSystemId,
        voucherTypeId,
        workTaskId,
    } = props
    const { erpInfoResponse, linkedItems, partItem } = part
    return (
        <Stack p={0.5} spacing={0.5} flex={1}>
            {erpInfoResponse?.hasErrors && <ErrorAlert isItemAlert additionalTextId={13663} />}
            <PartOrderInfo
                workTaskId={workTaskId}
                externalSystemId={externalSystemId}
                partItem={partItem}
                cisVoucherUrl={cisVoucherUrl}
                voucherTypeId={voucherTypeId}
            />
            {!!linkedItems?.length && (
                <PartsTable
                    currencyCode={currencyCode}
                    calculationLoading={calculationLoading}
                    costEstimationOptions={costEstimationOptions}
                    parts={linkedItems}
                    creatingCostEstimation={creatingCostEstimation}
                    showAllPrices={showAllPrices}
                    basketErpIndicator={basketErpIndicator}
                    showManufacturer={showManufacturer}
                    showSupplierArticleNumbers={showSupplierArticleNumbers}
                    showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                    showPurchasePrice={showPurchasePrice}
                    onSelectPart={props.onSelectPart}
                    onIncludeExcludePart={props.onIncludeExcludePart}
                />
            )}
        </Stack>
    )
}
