import { ImageMapAreaV2Dto, PartSectionsListV2Dto, PartsListByCategoryV2Dto, UnitShortListV2Dto, UnitShortV2Dto, UnitV2Dto } from "../../../models"
import { useEdsStore } from "../store"

export function setUnitParts(unitParts: PartSectionsListV2Dto | undefined) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            loading: false,
            error: false,
            unitParts,
        },
    }))
}

export function setUnitInfo(unitInfo: UnitV2Dto | undefined) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            loading: false,
            error: false,
            unitInfo,
        },
    }))
}

export function setSelectedUnit(unit: UnitShortV2Dto | undefined) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            selectedUnit: unit,
        },
    }))
}

export function setLoading(loading: boolean) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            loading,
            error: false,
        },
    }))
}

export function setError(error: boolean) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            loading: false,
            error,
        },
    }))
}

export function setGroup(group: PartsListByCategoryV2Dto | undefined) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            group,
            loading: false,
            error: false,
        },
    }))
}

export function setUnit(unit: UnitShortListV2Dto | undefined) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            unit,
            loading: false,
            error: false,
        },
    }))
}

export function setSelectedArea(area: ImageMapAreaV2Dto | undefined) {
    useEdsStore.setState((state) => ({
        graphic: {
            ...state.graphic,
            selectedArea: area,
        },
    }))
}
