import { useLocalization } from "@tm/localization"
import { CisCustomerResponse } from "@tm/models"
import { Divider, Stack, Typography } from "@tm/components"
import { FindInvoicesResponse } from "../../../data/model"

type Props = {
    customer: CisCustomerResponse
    invoices: FindInvoicesResponse
}

export default function AccountBalanceComponent({ customer, invoices }: Props) {
    const { translate, currency } = useLocalization()
    const { balanceDue, currencyCode } = customer.account ?? {}

    return (
        <Stack divider={<Divider />} spacing={0.5}>
            <Stack alignItems="center">
                <Typography variant="h3">{translate(442)}</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2">{translate(443)}</Typography>
                    <Typography fontWeight="bold">{` ${translate(444)}`}</Typography>
                </Stack>
            </Stack>
            <Stack alignItems="center">
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2">{translate(445)}</Typography>
                    <Typography variant="h3">{currencyCode && currency(invoices.totalInvoiced, currencyCode)}</Typography>
                </Stack>
                {balanceDue && (
                    <Stack direction="row" spacing={1} alignItems="center">
                        <Typography variant="body2">{translate(446)}</Typography>
                        <Typography variant="h3" color="primary">
                            {balanceDue}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}
