import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { FullWidthTextField } from "../../../../../../_shared/StyledComponents"

type Props = {
    note?: string
}

export default function OfferNote({ note }: Props) {
    const { translateText } = useLocalization()

    return (
        <Stack>
            <Typography variant="h3">{translateText(1700)}</Typography>
            <FullWidthTextField value={note} multiline />
        </Stack>
    )
}
