import { createContext, useMemo } from "react"
import { AttributeFilterModel, FittingPosition } from "@tm/models"
import { Box } from "@tm/components"
import { ArticleGroupFilterAreaProps } from "."
import { useComponentBehavior } from "./useComponentBehavior"
import AttributeFilterDropdown from "./AttributeFilterDropdown"
import { ALL_AXLES, getModuleVehicleRecordMode } from "../../../../helper"

export const RecordedFilterContext = createContext<AttributeFilterModel[]>([])

export type ArticleGroupFiltersProps = ArticleGroupFilterAreaProps &
    Pick<ReturnType<typeof useComponentBehavior>, "selectedFittingSide" | "handleDeselectFilter" | "handleSelectionClick">

export default function ArticleGroupFilters(props: ArticleGroupFiltersProps) {
    const {
        filterOptions,
        usedAttributeFilters,
        modelId,
        selectedFittingSide,
        handleDeselectFilter,
        handleSelectionClick,
        prefilledAttributeFilters,
    } = props

    if (!filterOptions) {
        return null
    }

    const shrinkedList = useMemo(() => {
        const filteredList = filterOptions.attributeFilters.filter((filter) =>
            selectedFittingSide ? filter.fittingSide === selectedFittingSide : true
        )
        // to keep the original sort of the list, we need to put the groups into an array:
        const groupedList = filteredList.reduce<[number, AttributeFilterModel[]][]>((tuples, val) => {
            const group = tuples.find((tuple) => tuple[0] === val.id)
            if (!group) {
                return [...tuples, [val.id, [val]]]
            }
            group[1] = [...group[1], val].orderBy((attr) => attr.fittingSide)
            return tuples
        }, [])

        const result = groupedList
            .map(([_, list]) => {
                const needsFittingside = list.some((x) => x.fittingSide)
                let entry = list.find((x) => !!x.text) || (list.first() as AttributeFilterModel)
                if (!entry) {
                    return
                }
                if (needsFittingside) {
                    entry = { ...entry, fittingSide: getModuleVehicleRecordMode()?.hideAxleSwitch ? ALL_AXLES : selectedFittingSide }
                }
                return entry
            })
            .filter((x) => !!x) as AttributeFilterModel[]
        return result
    }, [filterOptions.attributeFilters, selectedFittingSide])

    return (
        <RecordedFilterContext.Provider value={filterOptions.attributeFilters || []}>
            <Box>
                {shrinkedList.map((attributeFilter) => (
                    <AttributeFilterDropdown
                        key={`ProductGroupFilterOptions#${attributeFilter.id}.${attributeFilter.text}`}
                        attributeFilter={attributeFilter}
                        modelId={modelId}
                        selectedAttributes={usedAttributeFilters}
                        prefilledAttributes={prefilledAttributeFilters}
                        onSelectFilter={handleSelectionClick}
                        onDeselectFilter={handleDeselectFilter}
                    />
                ))}
            </Box>
        </RecordedFilterContext.Provider>
    )
}
