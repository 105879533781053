import { getStyleTheme } from "@tm/context-distribution"
import { Dropdown, Icon, SuggestionFieldButtonGroup, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import * as React from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { useDmsInfo, useDmsPermissions } from "../../hooks"
import { SearchType } from "../business"

function getComponentStyles(): NestedCSSProperties {
    const { margin, font } = getStyleTheme()

    return {
        display: "flex",
        flexDirection: "column",
        $nest: {
            "&__text": {
                display: "block",
                marginBottom: margin.xl,
            },
            "&__search": {
                $nest: {
                    ".inputgroup": {
                        minWidth: "20em",
                        maxWidth: "50em",
                        $nest: {
                            "> .btn": {
                                paddingLeft: `calc(${margin.l} * 2)`,
                                paddingRight: `calc(${margin.l} * 2)`,
                            },
                        },
                    },
                    ".suggest": {
                        flexDirection: "row-reverse",
                        $nest: {
                            ".input__field": {
                                fontSize: font.textSize.l,
                            },
                        },
                    },
                    "& .input .icon, & .btn .icon": {
                        width: "1.75rem",
                        height: "1.75rem",
                    },
                },
            },
            "&__dropdown": {
                $nest: {
                    "> .btn": {
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        $nest: {
                            ".btn__content": {
                                minWidth: "5em",
                                textAlign: "left",
                            },
                        },
                    },
                },
            },
            "&__search-field-wrapper": {
                display: "flex",
            },
            "&__hint": {
                display: "flex",
                alignItems: "center",
            },
            "&__hint-icon": {
                margin: margin.m,
            },
            "&__hint-text": {},
        },
    }
}

type Props = {
    onStartSearch(query: string, type: SearchType): void
    onResetSearch?(): void
}

type SearchArea = {
    sort: number
    readonly key: SearchType
    description: string
}

export const Search: React.FC<Props> = ({ onStartSearch, onResetSearch }) => {
    const className = style(getComponentStyles())

    const { permittedOperations } = useDmsPermissions()
    const { name: dmsName } = useDmsInfo()
    const { translate, translateText } = useLocalization()

    const searchAreas = React.useMemo<Array<SearchArea>>(() => {
        const areas: Array<SearchArea> = []

        if (permittedOperations.includes("findCustomers")) {
            areas.push({ sort: 1, key: "vehicle", description: translateText(99) })
            areas.push({ sort: 3, key: "customer", description: translateText(107) })
        }

        if (permittedOperations.includes("findVouchers")) {
            areas.push({ sort: 2, key: "voucher", description: translateText(30) })
        }

        return areas
    }, [permittedOperations, translateText])

    const [selectedSearchArea, setSelectedSearchArea] = React.useState<SearchArea | undefined>(searchAreas[0])

    React.useEffect(() => {
        if (!selectedSearchArea) {
            setSelectedSearchArea(searchAreas[0])
        }
    }, [permittedOperations, selectedSearchArea])

    const placeholder = React.useMemo<string | undefined>(() => {
        switch (selectedSearchArea?.key) {
            case "vehicle":
                return translateText(1943)
            case "voucher":
                return translateText(1944)
            case "customer":
                return translateText(1945)
            default:
                break
        }
    }, [selectedSearchArea?.key, translateText])

    const [query, setQuery] = React.useState<string>("")

    const search = React.useCallback(() => {
        if (!query || !selectedSearchArea?.key) {
            return
        }
        onStartSearch(query, selectedSearchArea.key)
    }, [selectedSearchArea?.key, query, onStartSearch])

    const handleNothing = React.useCallback(() => {}, [])

    const renderSearchArea = React.useCallback((searchArea: SearchArea) => {
        return <Text size="l">{searchArea.description}</Text>
    }, [])

    return (
        <div className={className}>
            <Text size="l" className={`${className}__text`}>
                {translateText(1946).replace("{0}", dmsName)}
            </Text>

            <div className={`${className}__search`}>
                <SuggestionFieldButtonGroup
                    autoComplete="off"
                    size="xl"
                    value={query}
                    placeholder={placeholder}
                    onChange={setQuery}
                    onChangeConfirm={search}
                    handleSearchButtonClick={search}
                    onChangeReset={onResetSearch}
                    suggestions={[]}
                    requestSuggestions={handleNothing}
                    appendix={
                        <Dropdown<SearchArea>
                            className={`${className}__dropdown`}
                            size="xl"
                            layout={["iconRight"]}
                            items={searchAreas}
                            itemView={renderSearchArea}
                            value={selectedSearchArea}
                            onChange={setSelectedSearchArea}
                        />
                    }
                />
            </div>

            <div className={`${className}__hint`}>
                <Icon size="s" name="search" className={`${className}__hint-icon`} />
                <Text size="s" className={`${className}__hint-text`}>
                    {translate(1947)}
                </Text>
            </div>
        </div>
    )
}
