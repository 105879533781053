import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleDATRegistration(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { setDATUserCredentials } = data

    if (setDATUserCredentials) {
        channel("GLOBAL").publish("DMG_DAT/REGISTRATION_RESULT", { registrationDone: true })
    }
}
