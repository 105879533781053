import { useState } from "react"
import SearchInput from "../shared/SearchInput"
import Section from "../shared/Section"
import JobList from "./components/JobList"

export default function JobTable() {
    const [errorSearchQuery, setErrorSearchQuery] = useState<string | undefined>()

    return (
        <Section titleTextID="13527" sx={{ flex: "1", minHeight: "0", overflow: "auto" }}>
            <SearchInput handleSearch={(query: string | undefined) => setErrorSearchQuery(query)} />
            <JobList errorSearchQuery={errorSearchQuery} />
        </Section>
    )
}
