export function dateToString(date?: Date) {
    if (date) {
        if(typeof date == "string")
            date = new Date(date)
        let month: any = date.getMonth() + 1;
        let year = date.getFullYear();

        if (month < 10) {
            month = '0' + month;
        }
        
        return month + '.' + year;
    }
    else return ""
}