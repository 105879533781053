import { AsyncAction } from "@tm/morpheus"
import { UserContext } from "@tm/models"
import { getExternalCatalogUrl } from "./data/repositories/url"
import { getExternalToken } from "./data/repositories/portalAuthority"
import { AuthToken } from "./components/iframe-module/business"

export type BundleActionType =
    | { type: "URL_LOADING" }
    | { type: "URL_LOADED"; payload: string }
    | { type: "TOKEN_LOADING" }
    | { type: "TOKEN_LOADED"; payload: AuthToken }

export type IBundleActions = typeof BundleActions

export const BundleActions = {
    getUrl,
    getToken,
}

function getToken(type: string, key?: string): AsyncAction<BundleActionType, UniversalTagBasedState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.tokenLoading) {
            return
        }

        dispatch({ type: "TOKEN_LOADING" })

        getExternalToken(type, { key }).then((token) => {
            dispatch({ type: "TOKEN_LOADED", payload: token })
        })
    }
}

function getUrl(
    externalSystemId: number,
    telesalesCustomerNo: string | undefined,
    userContext: UserContext
): AsyncAction<BundleActionType, UniversalTagBasedState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.urlLoading) {
            return
        }

        dispatch({ type: "URL_LOADING" })

        getExternalCatalogUrl(externalSystemId, telesalesCustomerNo, userContext).then((url) => {
            dispatch({ type: "URL_LOADED", payload: url })
        })
    }
}

export function reduceUniversalTagBaseState(state: UniversalTagBasedState = {}, action: BundleActionType): UniversalTagBasedState {
    switch (action.type) {
        case "URL_LOADING": {
            return {
                ...state,
                url: undefined,
                urlLoading: true,
            }
        }
        case "URL_LOADED": {
            return {
                ...state,
                url: action.payload,
                urlLoading: false,
            }
        }
        case "TOKEN_LOADING": {
            return {
                ...state,
                token: undefined,
                tokenLoading: true,
            }
        }
        case "TOKEN_LOADED": {
            return {
                ...state,
                token: action.payload,
                tokenLoading: false,
            }
        }
        default:
            return state
    }
}

export type UniversalTagBasedState = {
    urlLoading?: boolean
    url?: string
    kbaNumbers?: Array<string>
    tokenLoading?: boolean
    token?: AuthToken
}
