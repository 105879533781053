import { AsyncAction } from "@tm/morpheus"
import { channel, WorkTaskStatus } from "@tm/models"
import { SearchState } from "./model"
import { FindWorkTasksRequest } from "../../../data"
import * as Data from "../../../data"
import { mapWorkTaskReadmodelToListItem } from "./mapper"
import { BundleActionType, loadTotalNumbers } from "../../../business"

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: SearchState = {
    loading: false,
    workTasks: [],
    loadingTotals: false,
    pageSize: 50,
    endOfList: false,
    pageIndex: 1,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): SearchState {
    switch (action.type) {
        case "LIST_LOADING": {
            return {
                ...state,
                loading: true,
                query: action.payload,
            }
        }
        case "LIST_LOADED": {
            return {
                ...state,
                workTasks: (action.payload || []).map(mapWorkTaskReadmodelToListItem),
                loading: false,
                endOfList: action.payload.length < state.pageSize,
            }
        }
        case "LIST_NEXTPAGE_LOADING": {
            return {
                ...state,
                loading: true,
                query: action.payload,
                pageIndex: state.pageIndex + 1,
            }
        }
        case "LIST_NEXTPAGE_LOADED": {
            return {
                ...state,
                workTasks: action.payload.currentWorkTasks.concat((action.payload.workTaskReadModel || []).map(mapWorkTaskReadmodelToListItem)),
                loading: false,
                endOfList: action.payload.workTaskReadModel.length < state.pageSize,
            }
        }
        case "TOTAL_NUMBERS_LOADING": {
            return {
                ...state,
                loadingTotals: true,
            }
        }
        case "TOTAL_NUMBERS_LOADED": {
            return {
                ...state,
                loadingTotals: false,
                workTasks: state.workTasks.map((workTask) => {
                    const totalNumbers = action.payload.totalNumbersByWorkTask.find(
                        (totalNumber: any) => totalNumber.workTaskId == workTask.workTaskId
                    )

                    if (totalNumbers) {
                        return {
                            ...workTask,
                            workPositionsTotal: totalNumbers.workPositionsTotal,
                            partPositionsTotal: totalNumbers.partPositionsTotal,
                        }
                    }

                    return workTask
                }),
            }
        }
    }
    return state
}

function findWorktasks(query?: string, showWithOrdersOnly?: boolean, showOnlyUserWorkTasks?: boolean): AsyncAction<ComponentActionType, SearchState> {
    return (dispatch, getState) => {
        const state = getState()
        dispatch({ type: "LIST_LOADING", payload: query })

        const queryReset = query?.length == 0 && state.query?.length && state.query?.length > 1
        const request: FindWorkTasksRequest = {
            query,
            status: WorkTaskStatus.Undefined,
            pageIndex: 1,
            pageSize: queryReset ? state.pageSize : (state.pageIndex > 1 ? state.pageIndex - 1 : state.pageIndex) * state.pageSize,
            showWithOrdersOnly,
            showOnlyUserWorkTasks,
        }

        Data.findWorktasks(request).then((list) => {
            dispatch({ type: "LIST_LOADED", payload: list.data })

            if (list.data?.length) {
                dispatch(loadTotalNumbers(list.data.map((workTask) => workTask.workTaskId)))
            }
        })
    }
}

function findWorktasksNextPage(
    query?: string,
    showWithOrdersOnly?: boolean,
    showOnlyUserWorkTasks?: boolean
): AsyncAction<ComponentActionType, SearchState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.loading || state.endOfList) {
            return
        }
        dispatch({ type: "LIST_NEXTPAGE_LOADING", payload: query })

        const request: FindWorkTasksRequest = {
            query: state.query,
            status: WorkTaskStatus.Undefined,
            pageIndex: state.pageIndex,
            pageSize: state.pageSize,
            showWithOrdersOnly,
            showOnlyUserWorkTasks,
        }

        Data.findWorktasks(request).then((list) => {
            dispatch({
                type: "LIST_NEXTPAGE_LOADED",
                payload: { currentWorkTasks: state.workTasks, workTaskReadModel: list.data },
            })

            if (list.data?.length) {
                dispatch(loadTotalNumbers([...state.workTasks, ...list.data].map((workTask) => workTask.workTaskId)))
            }
        })
    }
}

function hideWorkTask(workTaskId: string): AsyncAction<ComponentActionType, SearchState> {
    return (dispatch, getState) => {
        const state = getState()
        Data.setWorkTaskCompleted(workTaskId).then(() => {
            dispatch(findWorktasks(state.query))
            dispatch({ type: "WORKTASK_STATE_CHANGED", payload: workTaskId })
            channel("GLOBAL").publish("WORKTASK/DELETED", { id: workTaskId })
        })
    }
}

export type IActions = typeof Actions

export const Actions = {
    findWorktasks,
    findWorktasksNextPage,
    hideWorkTask,
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "WORKTASK_STATE_CHANGED":
            return action
    }
}
