import { Box, Breadcrumbs, Card, Loader, styled } from "@tm/components"
import { TreeNode } from "@tm/models"
import { useState, useMemo, useEffect } from "react"
import { graphicActions, treeNavigationActions, useEdsStore } from "../../../data/state"
import { ImageMapAreaV2Dto, NavigationDirection, NavigationLinksEnum, YqNode } from "../../../models"
import { OeArticleList } from "../../_shared/OeArticleList"
import { getBreadCrumbTreeNodes, getSelectedAreaInfo, getUnitInfo, getUnitParts, isUnitNavigationDisabled, unitNavigate } from "../business"
import AreaInfoBar from "./AreaInfoBar"
import GraphicImageMap from "./graphicImageMap"
import UnitNavigation from "./UnitNavigation"

const GraphicDetails = () => {
    const selectedNode = useEdsStore((state) => state.treeNavigation.selectedNode)
    const selectedUnit = useEdsStore((state) => state.graphic.selectedUnit)
    const unitInfo = useEdsStore((state) => state.graphic.unitInfo)
    const isLoading = useEdsStore((state) => state.graphic.loading)
    const unitParts = useEdsStore((state) => state.graphic.unitParts)
    const treeOption = useEdsStore((state) => state.main.treeOption)
    const groupGraphic = useEdsStore((state) => state.graphic.group)
    const unitGraphic = useEdsStore((state) => state.graphic.unit)
    const selectedArea = useEdsStore((state) => state.graphic.selectedArea)
    const vin = useEdsStore((state) => state.vehicle.data.vin)

    const [hoveredArea, setHoveredArea] = useState<ImageMapAreaV2Dto>()

    const selectedAreaInfo = useMemo(() => getSelectedAreaInfo(hoveredArea ?? selectedArea, unitParts), [hoveredArea, selectedArea, unitParts])
    const breadCrumbNodes = useMemo(() => getBreadCrumbTreeNodes(selectedNode), [selectedNode])
    const breadCrumbStrings = useMemo(() => breadCrumbNodes.map((x) => x.name), [breadCrumbNodes])

    const hideUnitNavigation = useMemo(() => isUnitNavigationDisabled(treeOption, groupGraphic, unitGraphic), [unitGraphic, groupGraphic, treeOption])

    useEffect(() => {
        if (!selectedUnit) {
            return
        }

        graphicActions.setLoading(true)
        graphicActions.setSelectedArea(undefined)
        const unitInfoToken = selectedUnit.links.find((a) => a.action === NavigationLinksEnum.GetUnitInfo)?.token
        const unitPartsToken = selectedUnit.links.find((a) => a.action === NavigationLinksEnum.GetUnitParts)?.token

        unitInfoToken && getUnitInfo(unitInfoToken, vin, breadCrumbStrings)
        unitPartsToken && getUnitParts(unitPartsToken, vin, breadCrumbStrings)

        if (!unitInfoToken && !unitPartsToken) {
            graphicActions.setLoading(false)
        }
    }, [selectedUnit])

    function onBreadcrumbClick(node: TreeNode<YqNode>) {
        if (node.id === 0) {
            graphicActions.setSelectedUnit(undefined)
            treeNavigationActions.setSelectedNode(undefined)
            return
        }

        if (node.extra) {
            graphicActions.setSelectedUnit(undefined)
            treeNavigationActions.setSelectedNode(node.extra)
        }
    }

    function handleAreaClick(area: ImageMapAreaV2Dto) {
        graphicActions.setSelectedArea(area)
    }

    function handleAreaHover(area?: ImageMapAreaV2Dto) {
        setHoveredArea(area)
    }

    function navigateRight() {
        unitNavigate(NavigationDirection.Right, treeOption, selectedUnit, groupGraphic, unitGraphic)
    }

    function navigateLeft() {
        unitNavigate(NavigationDirection.Left, treeOption, selectedUnit, groupGraphic, unitGraphic)
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em", height: "100%", width: "100%" }}>
            <Box sx={{ display: "flex", gap: "0.5em" }}>
                <Card sx={{ width: "40%", overflow: "hidden" }}>
                    <Breadcrumbs sx={{ overflow: "hidden" }} onBreadcrumbClick={onBreadcrumbClick} breadCrumbs={breadCrumbNodes} />
                </Card>
                <Card sx={{ width: "60%", overflow: "unset" }}>
                    <UnitNavigation
                        title={selectedUnit?.name}
                        navigateLeft={navigateLeft}
                        navigateRight={navigateRight}
                        hideNavigation={hideUnitNavigation}
                    />
                </Card>
            </Box>

            <Box sx={{ display: "flex", gap: "0.5em", height: "100%", width: "100%" }}>
                <StyledGraphicContainerBox>
                    <Card sx={{ height: "100%" }}>
                        {isLoading && (
                            <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Loader sx={{ zIndex: "1" }} />
                            </Box>
                        )}

                        {!isLoading && unitInfo && (
                            <GraphicImageMap
                                onAreaClick={handleAreaClick}
                                onAreaHover={handleAreaHover}
                                areas={unitInfo.imageMaps[0].areas}
                                src={unitInfo.imageMaps[0].imageName.replace("%size%", "source")}
                                selectedArea={selectedArea}
                            />
                        )}
                    </Card>

                    <AreaInfoBar selectedAreaCode={hoveredArea?.areaCode ?? selectedArea?.areaCode} selectedAreaInfo={selectedAreaInfo} />
                </StyledGraphicContainerBox>

                <Card sx={{ width: "60%", display: "flex" }}>
                    {unitParts?.partSections && unitInfo && (
                        <OeArticleList
                            partSections={unitParts?.partSections}
                            loading={isLoading}
                            selectedArea={selectedArea}
                            areas={unitInfo.imageMaps[0].areas}
                        />
                    )}
                </Card>
            </Box>
        </Box>
    )
}

const StyledGraphicContainerBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "0.5em",
    height: "100%",
    width: "40%",
    minWidth: "400px",
})

export default GraphicDetails
