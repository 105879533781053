import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function sindriFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const sindriModule = getModuleFromUserContext(userContext, UserModuleType.Sindri)

    if (!sindriModule) {
        return undefined
    }

    return {
        sindri: {
            items: {
                sindri: {
                    sort: 0,
                    name: "sindri",
                    active: true,
                    route: "/:workTaskId/sindri",
                },
            },
        },
    }
}
