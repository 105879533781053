import { AsyncAction } from "@tm/morpheus"
import { VehicleSelectionState } from "./model"
import { BundleActionTypes, BundleActions } from "../../../business"
import { CarPark } from "../../../data/model"
import { MainState } from "../../main"

export type ComponentActionType = BundleActionTypes
    | { type: "CAR_PARK_SELECT_ITEM", payload: CarPark }

export const VEHICLE_SELECTION_DEFAULT_STATE: VehicleSelectionState = {
}

export function reduce(state = VEHICLE_SELECTION_DEFAULT_STATE, action: ComponentActionType): VehicleSelectionState {
    switch (action.type) {
        case "CAR_PARK_SELECT_ITEM": {
            return {
                ...state,
                selectedCarPark: action.payload
            }
        }
        case "SEND_SELECTED_CARD_PARK": {
            return {
                ...state,
                sendSelectedCarpark: action.payload,
                selectedCarPark: action.payload,
                sentRegistrationNumber: state.selectedRegistrationNo
            }
        }
        case "REGISTRATION_NOS_SET": {
            return {
                ...state,
                registrationNos: action.payload
            }
        }
        case "SELECTED_REGISTRATION_NO_SET": {
            return {
                ...state,
                selectedRegistrationNo: action.payload
            }
        }
    }
    return state
}

function sendSelectedCarParkToWheelsList(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedCarPark } = getState().vehicleSelection
        if (!selectedCarPark) return
        dispatch({ type: "SEND_SELECTED_CARD_PARK", payload: selectedCarPark })
    }
}

function selectCarParkItem(carParkItem: CarPark): ComponentActionType {
    return { type: "CAR_PARK_SELECT_ITEM", payload: carParkItem }
}
export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    sendSelectedCarParkToWheelsList,
    selectCarParkItem,
}
