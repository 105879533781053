import { Container } from "@tm/nexus"
import { RegisteredModels, ViewState, ViewStateContainer } from "@tm/models"

function loadState(key: string) {
    const container = Container.getInstance<ViewState<{ uri?: string } | undefined>>(RegisteredModels.ViewState)
    const contract = container.subscribe(key)
    return contract.load().then((result) => result.value?.uri)
}

function loadExportState(key: string) {
    const container = Container.getInstance<ViewState<{ uri?: string; exportLabel?: string } | undefined>>(RegisteredModels.ViewState)
    const contract = container.subscribe(key)
    return contract.load().then((result) => {
        return {
            uri: result.value?.uri,
            exportLabel: result.value?.exportLabel,
        }
    })
}

export function getExportVoucherInfos(worktaskId: string) {
    return loadExportState(`${worktaskId}_EXPORT-VOUCHER`)
}

export function getOrderSentUrl(worktaskId: string) {
    return loadState(`${worktaskId}_ORDER-SENT`)
}

function getExpandedRepairTimesKey(workTaskId: string): string {
    return `${workTaskId}__EXPANDED_REPAIR_TIMES`
}

export function getViewStateContainer(): ViewStateContainer {
    return Container.getInstance(RegisteredModels.ViewState)
}

export async function loadExpandedWorkIds(workTaskId: string): Promise<string[]> {
    return getViewStateContainer()
        .action("loadViewState")(getExpandedRepairTimesKey(workTaskId))
        .then((response) => response?.value?.expandedWorks ?? [])
}

export function saveExpandedWorkIds(workTaskId: string, expandedWorkIds: string[]) {
    getViewStateContainer().action("saveViewState")({
        key: getExpandedRepairTimesKey(workTaskId),
        value: { expandedWorks: expandedWorkIds },
    })
}
