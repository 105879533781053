import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit } from "./business"
// import component from "./component"

const startWidget: BundleComponent = {
    name: "create-event",
    reduce,
    transmit,
    component: () => null, // component,
}

export default startWidget
