import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { useMemo } from "react"
import { useRecoilValue } from "recoil"
import { basketErp, basketPartsLoadingIds } from "./useCentralOrderBasketStateByWorkTask"
import { useCentralOrderWorkTasks } from "../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import { useSendCentralOrder } from "../../components/_shared/order-button/hooks/useSendCentralOrder"

export function useIsCentralOrderBasketsLoading(partsRequired?: boolean) {
    const { userContext } = useUser() ?? {}
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { includedWorkTaskIds, orderBeingSent, loading, loadingNextPage, orderCalculationLoading, workTasks } = useCentralOrderWorkTasks().state
    const workTasksPartsLoading = !!useRecoilValue(basketPartsLoadingIds).length
    const allBasketErp = useRecoilValue(basketErp)
    const { includedBasketOrderGroups } = useSendCentralOrder(telesalesCustomerNo)

    const isLoading = useMemo(() => {
        let disabled = userContext ? userContext.parameter.orderDisabled : false
        if (!disabled) {
            disabled =
                !!loading ||
                !!loadingNextPage ||
                orderCalculationLoading ||
                (!!partsRequired && includedWorkTaskIds?.length === 0) ||
                workTasksPartsLoading ||
                !!orderBeingSent ||
                Object.entries(allBasketErp).filter((erp) => !erp[1].basketErpInfosIndicator.isLoading && erp[1].basketErpInfosIndicator.isLoaded)
                    .length !== workTasks.length ||
                (!!partsRequired && !includedBasketOrderGroups.some((orderGroup) => orderGroup.hasIncludedPartsOrderable)) ||
                includedBasketOrderGroups.some((orderGroup) => orderGroup.hasPartsBeingUpdated || orderGroup.orderOptionsLoading) ||
                (!!partsRequired &&
                    includedBasketOrderGroups.some(
                        (orderGroup) => orderGroup.orderOptionsGroup?.hasErrors && !!orderGroup.orderOptionsGroup?.errorText
                    ))
        }
        return disabled
    }, [
        userContext,
        allBasketErp,
        workTasks.length,
        loading,
        loadingNextPage,
        orderCalculationLoading,
        partsRequired,
        includedWorkTaskIds,
        workTasksPartsLoading,
        orderBeingSent,
        includedBasketOrderGroups,
    ])
    return isLoading
}
