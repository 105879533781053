import { AsyncAction, ActionDispatch } from "@tm/morpheus"
import { WorkTaskStatus } from "@tm/models"
import { RecentListState } from "./model"
import * as Data from "../../../data"
import { mapWorkTaskReadmodelToListItem } from "./mapper"
import { BundleActionType, loadTotalNumbers } from "../../../business"

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: RecentListState = {
    initialized: false,
    loading: false,
    request: { query: undefined, status: 0 },
    workTasks: [],
    loadingTotals: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): RecentListState {
    switch (action.type) {
        case "LIST_LOADING": {
            return {
                ...state,
                initialized: true,
                loading: true,
            }
        }
        case "LIST_LOADED": {
            return {
                ...state,
                workTasks: (action.payload || []).map(mapWorkTaskReadmodelToListItem),
                loading: false,
            }
        }
        case "TOTAL_NUMBERS_LOADING": {
            return {
                ...state,
                loadingTotals: true,
            }
        }
        case "TOTAL_NUMBERS_LOADED": {
            return {
                ...state,
                loadingTotals: false,
                workTasks: state.workTasks.map((workTask) => {
                    const totalNumbers = action.payload.totalNumbersByWorkTask.find(
                        (totalNumber: any) => totalNumber.workTaskId == workTask.workTaskId
                    )

                    if (totalNumbers) {
                        return {
                            ...workTask,
                            workPositionsTotal: totalNumbers.workPositionsTotal,
                            partPositionsTotal: totalNumbers.partPositionsTotal,
                        }
                    }

                    return workTask
                }),
            }
        }
    }
    return state
}

function load(): AsyncAction<ComponentActionType, RecentListState> {
    return (dispatch) => {
        dispatch({ type: "LIST_LOADING" })

        Data.findWorktasks({ status: WorkTaskStatus.Undefined }).then((list) => {
            dispatch({ type: "LIST_LOADED", payload: list.data })

            if (list.data?.length) {
                dispatch(loadTotalNumbers(list.data.map((workTask) => workTask.workTaskId)))
            }
        })
    }
}

export type IActions = typeof Actions

export const Actions = {
    load,
}

export function receive(
    action: ComponentActionType,
    dispatch: ActionDispatch<ComponentActionType, RecentListState>,
    getState: () => RecentListState
) {
    switch (action.type) {
        case "WORKTASK_STATE_CHANGED": {
            const state = getState()
            if (state.workTasks.some((x) => x.workTaskId == action.payload)) {
                dispatch(load())
            }
        }
    }
}
