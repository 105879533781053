import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { TableCellData, Icon, TableVariants, CellContentPosition, Box, LinkButton, TableColumnData } from "@tm/components"
import { createQueryString, encodeUniqueId, renderRoute } from "@tm/utils"
import { useParams } from "react-router"
import { getBundleParams } from "../../../utils"
import { AllOrderListItem } from "../../../data/model"
import PrintOrderButton from "../buttons/PrintOrderButton"
import { NoResultHint } from "../NoResultHint"
import { StyledTable } from "../StyledComponents"
import DateCell from "../cells/DateCell"
import { VoucherRouteProps } from "../../vouchers/component"

type Props = {
    orders: Array<AllOrderListItem>
    selectedVoucherId?: string
    singleSearch?: boolean
    variant: TableVariants
    onLoadNextPage?(): void
}

export default function PvOrdersTableComponent({ variant, orders, selectedVoucherId, singleSearch, onLoadNextPage }: Props) {
    const { translateText, translate, currency } = useLocalization()
    const { userSettings } = useUser()
    const { cisVoucherUrl } = getBundleParams()
    const matchParams = useParams<VoucherRouteProps>()
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false
    const compact = !!matchParams.id

    function renderUserInformation(order: AllOrderListItem) {
        let name = ""
        if (order.userInformation?.firstName) {
            name = order.userInformation.firstName
        }
        if (order.userInformation?.lastName) {
            name += ` ${order.userInformation.lastName}`
        }

        return (
            <>
                {name && name}
                {order.userInformation?.userName || ""}
            </>
        )
    }

    function renderOrderButtons(order: AllOrderListItem) {
        const id = order.orderId
        const voucherTypeId = order.voucherType.typeId
        let url = renderRoute(cisVoucherUrl, {
            ...matchParams,
            subpage: "vouchers",
            voucherTypeId,
            id: encodeURI(id),
        })
        if (singleSearch) {
            url += createQueryString({ singleSearch: true })
        }

        const workTaskUrl = `/${encodeUniqueId(order.workTaskId || "")}`
        if (selectedVoucherId && selectedVoucherId === id) {
            url = renderRoute(cisVoucherUrl, { ...matchParams, voucherTypeId, id: null })
        }

        return (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <LinkButton
                    to={workTaskUrl}
                    title={translateText(29)}
                    disabled={!order.workTaskId}
                    sx={{ marginRight: "4px" }}
                    startIcon={<Icon name="menu" />}
                />

                <PrintOrderButton orderId={order.orderId} iconName="attention-light" padding="6px" />
                <LinkButton to={url} title={translateText(43)} sx={{ marginLeft: "4px" }} startIcon={<Icon name="file-details" />} />
            </Box>
        )
    }

    function getModuleColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(700) },
            { header: translateText(776) },
            { header: translateText(777) },
            { header: translateText(1858) },
            { header: translateText(40) },
            { header: translateText(440) },
            { header: translateText(1248) },
            { header: translateText(479), alignContent: CellContentPosition.center },
            { header: translateText(90), alignContent: CellContentPosition.center },
        ]
        if (!hidePurchasePrice) {
            columns.push({ header: `${translate(1197)}*`, alignContent: CellContentPosition.center })
        }

        columns.push({ header: `${translate(1198)}*`, alignContent: CellContentPosition.center }, { alignContent: CellContentPosition.right })

        return columns
    }

    function getCompactColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(700) },
            { header: translateText(776) },
            { header: translateText(479), alignContent: CellContentPosition.center },
        ]
        if (!hidePurchasePrice) {
            columns.push({ header: `${translate(1197)}*`, alignContent: CellContentPosition.right })
        } else {
            columns.push({ header: `${translate(1198)}*`, alignContent: CellContentPosition.right })
        }

        columns.push({ alignContent: CellContentPosition.right })

        return columns
    }

    function getWidgetColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(700) },
            { header: translateText(98), alignContent: CellContentPosition.center },
            { header: translateText(479), alignContent: CellContentPosition.center },
        ]
        if (!hidePurchasePrice) {
            columns.push({ header: `${translate(1197)}*`, alignContent: CellContentPosition.right })
        }

        columns.push({ header: `${translate(1198)}*`, alignContent: CellContentPosition.right }, { alignContent: CellContentPosition.right })

        return columns
    }

    function getColumns() {
        if (compact) {
            return getCompactColumns()
        }
        if (variant === "small") {
            return getWidgetColumns()
        }
        if (variant === "normal") {
            return getModuleColumns()
        }

        return []
    }

    function getModuleCellData(order: AllOrderListItem) {
        const cellData: TableCellData[] = [
            { displayValue: order.orderId, id: "order_1" },
            { displayValue: <DateCell value={order.orderDate} mode="dateSlashTime" />, id: "order_2" },
            { displayValue: order.orderType, id: "order_3" },
            { displayValue: renderUserInformation(order), id: "order_4" },
            { displayValue: order.deliveryType, id: "order_5" },
            { displayValue: order.paymentType, id: "order_6" },
            { displayValue: order.orderComment, id: "order_7" },
            { displayValue: order.articlePositionCount, id: "order_8" },
            { displayValue: order.articleCount, id: "order_9" },
        ]

        if (!hidePurchasePrice) {
            cellData.push({ displayValue: currency(order.purchaseTotalPrice || 0, order.currencyCode), id: "order_10" })
        }

        cellData.push(
            { displayValue: currency(order.retailTotalPrice || 0, order.currencyCode), id: "order_11" },
            { displayValue: renderOrderButtons(order), id: "order_12" }
        )
        return cellData
    }

    function getCompactCellData(order: AllOrderListItem) {
        const cellData: TableCellData[] = [
            { displayValue: order.orderId, id: "order_1" },
            { displayValue: <DateCell value={order.orderDate} mode="dateAboveTime" />, id: "order_2" },
            { displayValue: order.articlePositionCount, id: "order_8" },
        ]

        if (!hidePurchasePrice) {
            cellData.push({ displayValue: currency(order.purchaseTotalPrice || 0, order.currencyCode), id: "order_10" })
        } else {
            cellData.push({ displayValue: currency(order.retailTotalPrice || 0, order.currencyCode), id: "order_11" })
        }

        cellData.push({ displayValue: renderOrderButtons(order), id: "order_12" })

        return cellData
    }

    function getWidgetCellData(order: AllOrderListItem) {
        const cellData: TableCellData[] = [
            { displayValue: order.orderId, id: "order_1" },
            { displayValue: <DateCell value={order.orderDate} mode="dateAboveTime" alignCenter />, id: "order_2" },
            { displayValue: order.articlePositionCount, id: "order_8" },
        ]

        if (!hidePurchasePrice) {
            cellData.push({ displayValue: currency(order.purchaseTotalPrice || 0, order.currencyCode), id: "order_10" })
        }

        cellData.push(
            { displayValue: currency(order.retailTotalPrice || 0, order.currencyCode), id: "order_11" },
            { displayValue: renderOrderButtons(order), id: "order_12" }
        )

        return cellData
    }

    function getCells(order: AllOrderListItem) {
        let cells: TableCellData[] = []
        if (compact) {
            cells = getCompactCellData(order)
        } else if (variant === "small") {
            cells = getWidgetCellData(order)
        } else if (variant === "normal") {
            cells = getModuleCellData(order)
        }

        return cells
    }

    const displayData = orders?.map((order, index) => ({
        cells: getCells(order),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === order.orderId,
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return (
        <StyledTable
            columns={getColumns()}
            rows={displayData}
            variant={variant}
            headerStyle={variant === "small" && !compact ? "bold" : "default"}
            onScrollBottom={onLoadNextPage}
            headerBackground={variant === "small" && !compact ? "default" : "paper"}
        />
    )
}
