import { ERIKContentService } from "@tm/data"
import { FileCategory } from "../models"

export function getFileCategory(file: ERIKContentService.FileMeta): number {
    if (!file.labels.length) {
        return FileCategory.Others
    }

    const category = file.labels.filter((f) => f.startsWith("category:"))?.first()

    if (!category) {
        return FileCategory.Others
    }

    const normalizedCategory = category.toLowerCase().replace("category:", "")
    const categoryKey = Object.keys(FileCategory).find((fileCategory) => fileCategory.toLowerCase() === normalizedCategory)

    return categoryKey ? FileCategory[categoryKey] : FileCategory.Others
}
