import { Box, Stack } from "@tm/components"
import { useCallback, ReactNode } from "react"

export type DictionaryValue = string | number

export type DictionaryItemPair = {
    key: string
    value: DictionaryValue
}

export type DictionaryItem = DictionaryItemPair & {
    valueRenderer?: (value: DictionaryValue) => ReactNode
    keyRenderer?: (key: string) => string | ReactNode
}

type Props = {
    showOnlyFilledValues?: boolean
    items: Array<DictionaryItem>
}

export default function Dictionary({ showOnlyFilledValues, items }: Props) {
    const renderItem = useCallback(
        (item: DictionaryItem, idx: number) => {
            if (showOnlyFilledValues && item.value == null) {
                return null
            }

            const content: ReactNode = item.valueRenderer ? item.valueRenderer(item.value) : item.value
            const contentLabel: string | ReactNode = item.keyRenderer ? item.keyRenderer(item.key) : item.key

            return (
                <Box className="dictionary__item" key={idx}>
                    <Box className="dictionary__key">{contentLabel}</Box>
                    <Stack direction="row" className="dictionary__value" flexWrap="wrap">
                        {content}
                    </Stack>
                </Box>
            )
        },
        [showOnlyFilledValues]
    )

    return <Box className="dictionary">{items.map(renderItem)}</Box>
}
