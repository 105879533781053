import { memo } from "react"
import { withWorkTask, WithWorkTaskProps } from "@tm/context-distribution"
import { connectComponent } from "@tm/morpheus"
import { Box } from "@tm/components"

import { useLocalization } from "@tm/localization"
import { Actions, DetailsReferencesState, IActions } from "../details-references/business"

import { ArticleNote } from "../_shared/article-item/note"
import { getBundleParams } from "../../utils"

interface IProps extends WithWorkTaskProps {
    state: DetailsReferencesState
    actions: IActions
}

const Component = memo<IProps>(({ state, workTask }) => {
    const { translateText } = useLocalization()
    const { enableArticleNotes } = getBundleParams()

    if (!enableArticleNotes) {
        return null
    }

    if (!state.detailsResponse || !state.detailsResponse.article) {
        return <div className="headline headline--s">{translateText(14)}</div>
    }

    return (
        <Box width="100%" p={3}>
            <ArticleNote noBackground isActive article={state.detailsResponse.article} vehicle={workTask?.vehicle} />
        </Box>
    )
})

export default connectComponent(Actions, withWorkTask(Component))
