import { RegisteredModels, ViewState, ViewStateContainer } from "@tm/models"
import { Container } from "@tm/nexus"

function getInstance() {
    return _instance || (_instance = Container.getInstance(RegisteredModels.ViewState) as any)
}

let _instance: ViewStateContainer

export function saveState(state: ViewState, merge?: boolean): Promise<void> {
    return getInstance().action("saveViewState")(state, merge)
}

export function loadState<T = any>(key: string): Promise<ViewState<T>> {
    return getInstance().action("loadViewState")(key)
}
