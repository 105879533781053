import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetActivatableModules(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    if (!data?.getActivatableModules || !source) {
        return
    }

    this.props.getActivatableModules?.().then((response) => {
        source.postMessage({ getActivatableModulesResponse: response }, "*")
    })
}
