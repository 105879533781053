import { Box } from "@tm/components"
import { Collapsible } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ArticleInfoType } from "@tm/models"
import { CostEstimation } from "@tm/data/vouchers/costEstimations"
import PartsSelection from "../../../_shared/PartsSelection"
import { mapVoucherPartToArticle } from "../../../../../../../../data/mapper"
import PartsTable from "./components/PartsTable"
import { PartsTotals } from "../totals/PartsTotals"

type Props = {
    voucherId?: string
    selectedIds: string[]
    vehicleId?: string
    isBigScreen: boolean
    costEstimation?: CostEstimation
    onSelectAll(): void
    onUnselectAll(): void
    onSelectPart(id: string): void
}

export default function CostEstimationParts(props: Props) {
    const { selectedIds, vehicleId, voucherId, isBigScreen, costEstimation, onSelectAll, onSelectPart, onUnselectAll } = props
    const { parts, currencyCode, totalsB2B, totalsB2C, costEstimationOptions, currencySymbol } = costEstimation || {}
    const { translateText } = useLocalization()

    function renderPartsSelection() {
        if (parts?.length) {
            // Currently the add-to-basket micro only supports a list of a single artcileInfoType
            const tecDocParts = parts.filter((part) => part.articleInformation.articleInfoType === ArticleInfoType.TecdocArticle)

            const selectedParts = tecDocParts.filter((part) => selectedIds.includes(part.partItemId))
            if (selectedParts.length) {
                return (
                    <PartsSelection
                        allSelected={tecDocParts.length === selectedParts.length}
                        selectedArticles={selectedParts.map(mapVoucherPartToArticle)}
                        onSelectAll={onSelectAll}
                        onUnselectAll={onUnselectAll}
                    />
                )
            }
        }
    }

    return (
        <Collapsible name={translateText(1667)} initiallyOpened skin="dark">
            <Box position="relative" px={2} pb={1}>
                {renderPartsSelection()}
                <PartsTable
                    costEstimationOptions={costEstimationOptions}
                    parts={parts}
                    currencySymbol={currencySymbol ?? ""}
                    currencyCode={currencyCode ?? ""}
                    costEstimationVehicleId={vehicleId}
                    selectedIds={selectedIds}
                    onSelectPart={onSelectPart}
                    isBigScreen={isBigScreen}
                    voucherId={voucherId}
                />
                <Box py={1} pr={1} display="flex" justifyContent="flex-end">
                    <PartsTotals
                        priceVatMode={costEstimationOptions?.priceVatMode}
                        currencyCode={currencyCode ?? ""}
                        totals={totalsB2B ?? totalsB2C}
                    />
                </Box>
            </Box>
        </Collapsible>
    )
}
