import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { GetVinSuggestionsResponse } from "./model"

export const getVinSuggestions = (query: string, modelId: number): Promise<Array<string>> => {
    const url = `${getServiceUrl()}/Vins`
    const authorization = getStoredAuthorization()
    const body = { query, modelId }

    return ajax<GetVinSuggestionsResponse>({ url, body, authorization }).then((data) => {
        if (data) {
            return data?.vins ?? []
        }
        throw new Error()
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.vinPickerUrl
}
