import { Box, Button, Icon, Paper, Typography, VehicleImage } from "@tm/components"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { LicensePlateField, WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CustomerVehicleShortInfo } from "@tm/models"
import { useRef } from "react"
import { getBundleParams } from "../../../utils"

type Props = {
    vehicle: CustomerVehicleShortInfo
    handleDetachVehicle(vehicle: CustomerVehicleShortInfo): void
    handleApplyVehicle(vehicle: CustomerVehicleShortInfo): void
}

export default function VehicleCard({ vehicle, handleDetachVehicle, handleApplyVehicle }: Props) {
    const dialogRef = useRef<WarningPrompt>(null)
    const { date, translateText } = useLocalization()

    const { modelThumbnail, modelSeriesThumbnail, manufacturer, modelSeries, model, plateId, attachedOn, vehicleType } = vehicle

    const shortenedModelThumbnail = modelThumbnail?.replace(/https?:/, "") || ""
    const shortenedModelSeriesThumbnail = modelSeriesThumbnail?.replace(/https?:/, "") || ""
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)
    return (
        <Paper className="card--vehicle" sx={{ position: "relative", width: "100%" }}>
            <div className="card__actions">
                {!getBundleParams().nextLight && (
                    <>
                        <Button
                            size="small"
                            title={translateText(521)}
                            startIcon={<Icon name="close" />}
                            onClick={() => {
                                dialogRef.current?.show()
                            }}
                            sx={{ marginRight: "2px" }}
                        >
                            {translateText(624)}
                        </Button>
                        <WarningPrompt
                            text={translateText(1034)}
                            confirmationButtonText={translateText(585)}
                            cancelButtonText={translateText(584)}
                            ref={dialogRef}
                            onConfirm={() => {
                                handleDetachVehicle(vehicle)
                            }}
                        />
                        <Button
                            size="small"
                            title={translateText(520)}
                            startIcon={<Icon name="paperclip" />}
                            onClick={() => {
                                handleApplyVehicle(vehicle)
                            }}
                        >
                            {translateText(13)}
                        </Button>
                    </>
                )}
            </div>
            <Box mt={3} mb={1}>
                <VehicleImage
                    className="card--vehicle__image"
                    modelImage={shortenedModelThumbnail}
                    modelSeriesImage={shortenedModelSeriesThumbnail}
                    vehicleType={vehicleType}
                />
            </Box>
            <Typography variant="body1" fontWeight="bold">
                {manufacturer} {modelSeries} {model}
            </Typography>
            {plateId && <LicensePlateField readonly size="s" shortCountryCode={plateCode} value={plateId} />}
            <Typography variant="body1">
                {translateText(228)} {attachedOn ? date(attachedOn, "f") : ""}
            </Typography>
        </Paper>
    )
}
