import { useLocalization } from "@tm/localization"
import { useState, useEffect } from "react"

import { DMS } from "@tm/models"
import { getBundleParams } from "../utils"
import { settingsChannel } from "../business/messaging"

export type DmsInfo = {
    name: string
    icon: string
    version?: string
}

export const DEFAULT_DMS_NAME = "DMS"
export const DEFAULT_DMS_ICON = "user-car"

export function useDmsInfo(): DmsInfo {
    const { name, icon } = JSON.parse(localStorage.getItem("DMS_INFO") ?? "{}")
    const { dmsName, dmsIcon } = getBundleParams()
    const { translateText } = useLocalization()

    function getDmsInfo(settings: DMS.ShowSettingsResponse | undefined): DmsInfo {
        return {
            name: settings?.application?.id ?? name ?? dmsName ?? translateText(1875) ?? DEFAULT_DMS_NAME,
            icon: settings?.application?.icon ?? icon ?? dmsIcon ?? DEFAULT_DMS_ICON,
            version: settings?.application?.version,
        }
    }

    const [dmsInfo, setDmsInfo] = useState<DmsInfo>(() => getDmsInfo(settingsChannel().last(1, "SETTINGS_LOADED")[0]?.content))

    useEffect(() => settingsChannel().subscribe("SETTINGS_LOADED", (settings) => setDmsInfo(getDmsInfo(settings)), true), [])

    return dmsInfo
}

export type DmsPermission = {
    permittedOperations: Array<DMS.MessageType>
}

export function useDmsPermissions(): DmsPermission {
    function getDmsPermissions(settings: DMS.ShowSettingsResponse | undefined): DmsPermission {
        return {
            permittedOperations: settings?.operations?.map((x) => x?.name) ?? [],
        }
    }

    const [dmsPermissions, setDmsPermissions] = useState<DmsPermission>(() =>
        getDmsPermissions(settingsChannel().last(1, "SETTINGS_LOADED")[0]?.content)
    )

    useEffect(() => settingsChannel().subscribe("SETTINGS_LOADED", (settings) => setDmsPermissions(getDmsPermissions(settings)), true), [])

    return dmsPermissions
}

export function useGsiConnectVersion(): string | undefined {
    function getGsiConnectVersion(settings: DMS.ShowSettingsResponse | undefined): string | undefined {
        return settings?.application.gsiConnectVersion
    }

    const [gsiConnectVersion, setGsiConnectVersion] = useState<string | undefined>(() =>
        getGsiConnectVersion(settingsChannel().last(1, "SETTINGS_LOADED")[0]?.content)
    )

    useEffect(() => settingsChannel().subscribe("SETTINGS_LOADED", (settings) => setGsiConnectVersion(getGsiConnectVersion(settings)), true), [])

    return gsiConnectVersion
}
