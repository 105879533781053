import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers"
import { reduce } from "./business"
import component from "./component"

const managerComponent: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "main",
    component: withStoreProvider(component as any) as any,
    reduce,
    transmit: (action: any) => {
        if (action.type === "MODULE_OPENED") {
            return action
        }
    },
}

export type MainState = ReturnType<typeof reduce>

export default managerComponent
