import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"

export function getVinSuggestions(query: string, modelId: number): Promise<Array<string>> {
    const url = `${getServiceUrl()}/Vins`
    const authorization = getStoredAuthorization()
    const body = { query, modelId }

    return new Promise<Array<string>>((resolve, reject) => ajax({ url, body, authorization }).then((data) => resolve(data.vins || []), reject))
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.vinPickerUrl
}
