import { useLocalization } from "@tm/localization"
import { Typography, Stack } from "@tm/components"
import { CisCustomerResponse } from "@tm/models"
import { Image } from "@tm/controls"
import { TypographyTitle, TextBody1Bold } from "../StyledComponents"
import EmailComponent from "./Email"

type Props = {
    titleTextId: number | string
    cisCustomer: CisCustomerResponse
    logo?: string
    showElectronicAdress?: boolean
}
export default function CustomerDataComponent({ titleTextId, cisCustomer, logo, showElectronicAdress }: Props) {
    const { translate } = useLocalization()

    return (
        <Stack>
            <TypographyTitle>{translate(titleTextId)}</TypographyTitle>
            <TextBody1Bold>{cisCustomer.companyName}</TextBody1Bold>
            <Typography variant="body2">{cisCustomer.companyNameAddition1}</Typography>
            <Typography variant="body2">{cisCustomer.addresses && cisCustomer.addresses[0].street}</Typography>
            <Typography variant="body2">{cisCustomer.addresses && `${cisCustomer.addresses[0].zip} ${cisCustomer.addresses[0].city}`}</Typography>
            <Stack direction="row" spacing={0.5} mt={1}>
                <Typography variant="body2">{`${translate(102)}:`}</Typography>
                <Typography variant="body2" color="primary" fontWeight="bold">
                    {cisCustomer.customerNumberToShow || cisCustomer.customerNo}
                </Typography>
            </Stack>
            {!!logo && <Image url={logo} />}
            {showElectronicAdress && cisCustomer.electronicAddresses?.length && <EmailComponent email={cisCustomer.electronicAddresses[0].value} />}
        </Stack>
    )
}
