import { useFastServiceStore } from "../../../data"
import { DocumentElementExt, FullScreenType } from "./model"

declare let document: Document & FullScreenType & { documentElement: DocumentElementExt }

export const setFullScreen = (fullscreen: boolean) => {
    const { isFullScreen } = useFastServiceStore.getState()

    if (isFullScreen !== fullscreen) {
        useFastServiceStore.getState().setFullScreen(fullscreen)
    }
}

export const toggleFullScreen = (element: HTMLDivElement | null) => {
    if (!element) {
        return
    }

    const { isFullScreen } = useFastServiceStore.getState()

    const actualFullScreen =
        document.fullscreen ||
        document.Fullscreen ||
        document.mozFullScreen ||
        document.webkitFullscreen ||
        document.webkitIsFullScreen ||
        document.msRequestFullscreen ||
        false

    const fullscreen = !(actualFullScreen === isFullScreen ? isFullScreen : actualFullScreen)
    const el = <DocumentElementExt>element

    if (fullscreen) {
        let req: Promise<any>

        if (el.requestFullScreen) {
            req = el.requestFullScreen()
        } else if (el.requestFullscreen) {
            req = el.requestFullscreen()
        } else if (el.webkitRequestFullscreen) {
            req = el.webkitRequestFullscreen((Element as any).ALLOW_KEYBOARD_INPUT)
        } else if (el.mozRequestFullScreen) {
            req = el.mozRequestFullScreen()
        } else if (el.msRequestFullscreen) {
            req = el.msRequestFullscreen()
        } else {
            return
        }

        req.then(() => {
            useFastServiceStore.getState().setFullScreen(fullscreen)
        }).catch(() => {
            useFastServiceStore.getState().setFullScreen(actualFullScreen)
        })
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen()
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen()
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen()
        } else if (document.cancelFullScreen) {
            document.cancelFullScreen()
        }

        useFastServiceStore.getState().setFullScreen(fullscreen)
    }
}
