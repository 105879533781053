import { WorkTaskInfo } from "@tm/context-distribution"
import { AddWholesalerPartListRequest, ErpInformation, OrderWarehouseInfo, WholesalerPart } from "@tm/models"
import { createPriceDictionaryFromErpPrices } from "@tm/utils"

export function createAddWholesalerPartListRequest(
    part: WholesalerPart,
    quantity: number,
    workTask?: WorkTaskInfo,
    memo?: string,
    warehouse?: OrderWarehouseInfo,
    generatedWorktaskId?: string,
    erpInfo?: ErpInformation,
    distributorId?: number,
    distributorName?: string
): AddWholesalerPartListRequest | undefined {
    if (!workTask && !generatedWorktaskId) {
        return
    }

    return {
        wholesalerParts: [
            {
                ...part,
                quantityValue: quantity,
                memo,
                additionalDescription: erpInfo?.additionalInformation,
                distributorId,
                distributorName,
                warehouseId: warehouse?.id,
                warehouseName: warehouse?.name,
                isReplacementPart: erpInfo?.isReplacementPart,
                erpInformation: {
                    prices: erpInfo?.prices ? createPriceDictionaryFromErpPrices(erpInfo?.prices) : {},
                },
            },
        ],
        workTaskId: generatedWorktaskId || workTask?.id || "",
        vehicleId: workTask?.vehicle?.id,
        customerId: workTask?.customer?.id,
        usePercentageValues: true,
    }
}
