import { Image } from "@tm/controls"

export const AvailabilityImageOnly = ({ url, onClick }: { url: string; onClick?(): void }) => {
    let className = "availability-multi-quantity"

    if (onClick) {
        className = `${className} ${className}--clickable`
    }

    return (
        <div
            className={className}
            onClick={onClick}
            style={{ alignItems: "center", justifyContent: "center", height: "100%", borderRadius: "3px", background: "#e2e2e2" }}
        >
            <Image url={url} className="availability__standalone-image" />
        </div>
    )
}
