import { BundleComponent } from "@tm/morpheus"
import { reduce, receive } from "./business"
import component from "./component"

const detailsIndustrialInfo: BundleComponent = {
    name: "details-industrial-info",
    reduce,
    component,
    receive,
}

export default detailsIndustrialInfo
