import { useMemo } from "react"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { LicensePlateField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleInfo } from "../../../data"
import { getComponentStyles } from "./styles"

type Props = {
    vehicleInfo: VehicleInfo
    hideLicensePlate?: boolean
}

export default function VehicleData({ vehicleInfo, hideLicensePlate }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText, date } = useLocalization()
    const { plateCode: shortCountryCode } = useCountryCodeToLicensePlate(vehicleInfo?.countryCode)

    return (
        <div className={classNames.main}>
            {vehicleInfo.initialRegistration && (
                <div className={classNames.area}>
                    <Text modifiers={["block", "sub"]} size="s">
                        {translateText(124)}
                    </Text>
                    <Text modifiers="block">{date(new Date(vehicleInfo.initialRegistration), "d")}</Text>
                </div>
            )}
            {vehicleInfo.mileAge && (
                <div className={classNames.area}>
                    <Text modifiers={["block", "sub"]} size="s">
                        {translateText(125)}
                    </Text>
                    <Text modifiers="block">{vehicleInfo.mileAge}</Text>
                </div>
            )}
            {!hideLicensePlate && (
                <LicensePlateField
                    size="s"
                    readonly
                    shortCountryCode={shortCountryCode}
                    value={vehicleInfo.plateId}
                    className={classNames.licensePlateField}
                />
            )}
        </div>
    )
}
