import { Suspense, useCallback, useEffect, useMemo, useState } from "react"
import { Box, Icon, Button as NewButton, SharePopover, Tooltip } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { Button, MessageToast, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros, RegisteredModels, channel } from "@tm/models"
import { Container } from "@tm/nexus"
import { renderRoute } from "@tm/utils"
import { useIdStorageByWorkTaskLoadable, useSendChatMessageLoadable } from "../../data/hooks"
import { getBundleParams } from "../../utils"
import { MicroWrapper } from "../MicroWrapper"
import { getComponentStyles } from "./styles"

type Props = IMicros["notifications"]["send-cost-estimation-button"]

function SendCostEstimationButton({ asShareButton, onClick, disabled }: Props) {
    const { chatDetailsRoute } = getBundleParams()

    const classNames = useMemo(() => getComponentStyles(), [])
    const { translate, translateText } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const { chatId, vehicle: chatVehicle } = useIdStorageByWorkTaskLoadable() ?? {}
    const sendChatMessage = useSendChatMessageLoadable()

    const [message, setMessage] = useState<{ success: true; chatId: string } | { success: false }>()

    const handleClearMessage = useCallback(() => setMessage(undefined), [])

    useEffect(() => {
        if (!message) {
            return
        }

        const timeout = window.setTimeout(handleClearMessage, 5000)

        return () => {
            window.clearTimeout(timeout)
        }
    }, [message, handleClearMessage])

    useEffect(() => {
        if (!workTask?.id || !chatId || !chatVehicle?.seatbeltVehicleId) {
            return
        }

        // TODO: move this to a manager?
        return channel("WORKTASK", workTask?.id).subscribe("NOTIFICATIONS/SEND_COST_ESTIMATION_CHAT_MESSAGE", ({ costEstimationId }) => {
            Container.getInstance<string>(RegisteredModels.Vouchers_ShowCostEstimationPdf)
                .subscribe(costEstimationId)
                .load()
                .then(
                    (costEstimationPdf) => {
                        if (!costEstimationPdf) {
                            setMessage({ success: false })
                            return
                        }

                        const blob = new Blob([costEstimationPdf])

                        sendChatMessage({
                            chatId,
                            message: translateText(1033),
                            attachment: {
                                file: blob,
                                mimeType: "application/pdf",
                                metaData: {
                                    type: "costEstimate",
                                    offerId: costEstimationId,
                                    name: `${costEstimationId}.pdf`,
                                    description: `${translateText(82)} ${workTask?.no}`,
                                    externalVehicleId: chatVehicle.seatbeltVehicleId,
                                },
                            },
                        }).then(
                            () => {
                                setMessage({ success: true, chatId })

                                // LNT Hack from the old chat module
                                if (workTask?.vehicle?.tecDocModelId === 4618) {
                                    sendChatMessage({
                                        chatId,
                                        message: "LIVE",
                                        attachment: {
                                            metaData: {
                                                type: "link",
                                                url: "http://www.dvsegmbh.de/catclientpages/ExternalData/TMW/Vehicles/3D/Audi_A4_B5_grau.usdz",
                                                name: "AR Picture",
                                            },
                                        },
                                    })
                                }
                            },
                            () => setMessage({ success: false })
                        )
                    },
                    () => setMessage({ success: false })
                )
        })
    }, [chatId, chatVehicle?.seatbeltVehicleId, workTask?.id, workTask?.no, workTask?.vehicle?.tecDocModelId, sendChatMessage, translateText])

    const messageToast = !!message && (
        <MessageToast
            onButtonClick={handleClearMessage}
            skin={message.success ? "success" : "warning"}
            icon={message.success ? "success-dark" : "warning-dark"}
            message={
                message.success ? (
                    <>
                        <Text modifiers="block">{translate(3032)}</Text>
                        <Button
                            onClick={handleClearMessage}
                            size="s"
                            icon="chat"
                            layout={["holo"]}
                            className={classNames.detailsButton}
                            linkTo={renderRoute(chatDetailsRoute, { chatId: message.chatId })}
                        >
                            {translate(3104)}
                        </Button>
                    </>
                ) : (
                    translate(1706)
                )
            }
        />
    )

    const SendButton = asShareButton ? SharePopover.ShareButton : NewButton

    const button = (
        <SendButton
            disabled={disabled || !chatId}
            {...(asShareButton ? { noDisabledTooltip: !chatId } : {})}
            onClick={onClick}
            variant="outlined"
            startIcon={<Icon name="cartelligence-small" />}
        >
            {asShareButton ? translate(13358) : translate(642)}
            {messageToast}
        </SendButton>
    )

    if (!chatId) {
        return (
            <Tooltip placement={asShareButton ? "top" : "bottom"} title={translateText(13357)} enterDelay={1000}>
                <Box>{button}</Box>
            </Tooltip>
        )
    }

    return button
}

export default function Wrapper(props: Props) {
    return (
        <MicroWrapper>
            <Suspense fallback={null}>
                <SendCostEstimationButton {...props} />
            </Suspense>
        </MicroWrapper>
    )
}
