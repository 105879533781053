import { RegisteredModels, ViewState } from "@tm/models"
import { Container } from "@tm/nexus"

type QueryHistoryViewState = ViewState<undefined | { history: Array<string> }>

export const addErrQueryToHistory = (query: string) => {
    // eslint-disable-next-line no-param-reassign
    query = query.trim()

    getErrQueryHistory().then((history) => {
        const idx = history.findIndex((x) => x.toLowerCase() === query.toLowerCase())

        if (idx === 0) {
            return
        }
        let newHistory = [...history]
        if (idx !== -1) {
            newHistory.splice(idx, 1)
        }
        newHistory.unshift(query)
        newHistory = newHistory.slice(0, 20)

        const collection = Container.getInstance<QueryHistoryViewState>(RegisteredModels.ViewState)
        const subscription = collection.subscribe("ERR_SEARCH_HISTORY")
        subscription.save && subscription.save({ key: "ERR_SEARCH_HISTORY", value: { history: newHistory } })
    })
}
export const getErrQueryHistory = (): Promise<Array<string>> => {
    return Container.getInstance<QueryHistoryViewState>(RegisteredModels.ViewState)
        .subscribe("ERR_SEARCH_HISTORY")
        .load()
        .then(
            (response) => response.value?.history || [],
            () => []
        )
}

// export const delErrHistory = () => {
//     getERRQueryHistory().then(history => {
//         const collection = Container.getInstance<QueryHistoryViewState>(RegisteredModels.ViewState)
//         const subscription = collection.subscribe("ERR_SEARCH_HISTORY")
//         subscription.save && subscription.save({ key: "ERR_SEARCH_HISTORY", value: { history: [] } })
//     })
// }
