import { VoucherType } from "@tm/models"
import TotalNumbers from "../../_shared/TotalNumbers"
import { TotalNumberType } from "../../../../../business"

type Props = {
    partsPositionCount?: number
    worksPositionCount?: number
}

export default function CostEstimationTotalNumbers({ partsPositionCount, worksPositionCount }: Props) {
    return (
        <>
            <TotalNumbers type={TotalNumberType.parts} totalNumber={partsPositionCount || 0} voucherType={VoucherType.CostEstimation} />
            |
            <TotalNumbers type={TotalNumberType.works} totalNumber={worksPositionCount || 0} voucherType={VoucherType.CostEstimation} />
        </>
    )
}
