import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { useCallback } from "react"
import { WorktaskInfo } from "../../../../telesales/src/data/repositories"
import * as Data from "../repositories/cis/vouchers"

export function useShowOrderVoucherTypeId() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const getVoucherInfo = useCallback(
        (workTaskInfo: WorktaskInfo) => {
            if (enableServiceCalls) {
                return Data.showOrderVoucherTypeId({
                    orderNumber: workTaskInfo.orderNo,
                    customerNo: workTaskInfo.partnerNo || telesalesCustomerNo,
                })
            }
        },
        [enableServiceCalls, telesalesCustomerNo]
    )

    return { getVoucherInfo }
}
