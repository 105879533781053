import { useUser } from "@tm/context-distribution"
import { Button, Grid, SubTitle, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CarModelDetails, Vehicle } from "@tm/models"
import * as React from "react"
import { Box, styled, VehicleImage } from "@tm/components"
import { RepairShopDetails, VehicleDetails } from "./components"

type Props = {
    vehicle: Vehicle
    modelDetails?: CarModelDetails
    onVehicleAcceptance: (value: boolean) => void
}

const ImportAdditionalDetails: React.FC<Props> = ({ vehicle, modelDetails, onVehicleAcceptance }) => {
    const { translateText } = useLocalization()
    const user = useUser()

    const renderVehicleSections = () => {
        return (
            <StyledContent>
                <Grid container>
                    <Grid m={4}>
                        <SubTitle>{translateText(71)}</SubTitle>
                        <Text modifiers={["strong"]} size="l">
                            {vehicle.manufacturer}
                        </Text>
                    </Grid>
                    <Grid m={4}>
                        <SubTitle>{translateText(73)}</SubTitle>
                        <Text modifiers={["strong"]} size="l">
                            {vehicle.modelSeries}
                        </Text>
                    </Grid>
                    <Grid m={4}>
                        <SubTitle>{translateText(74)}</SubTitle>
                        <Text modifiers={["strong"]} size="l">
                            {vehicle.model}
                        </Text>
                    </Grid>
                </Grid>

                <StyledGrid container>
                    <Grid s={4}>
                        <StyledVehicleImage
                            className="stefimage"
                            modelImage={vehicle.modelSeriesImage || ""}
                            modelSeriesImage={vehicle.modelSeriesThumbnail?.replace("kmodthumb", "kmod") || ""}
                            vehicleType={vehicle.vehicleType}
                            // className={style.vehicleImage}
                        />
                    </Grid>
                    <Grid s={8}>
                        <VehicleDetails modelDetails={modelDetails} />
                    </Grid>
                </StyledGrid>
            </StyledContent>
        )
    }

    const renderCustomerSections = () => {
        if (!user?.userSettings?.repairShop) {
            return null
        }

        const { repairShop } = user.userSettings

        return (
            <StyledContent>
                <Grid container>
                    <Grid m={12}>
                        <Text modifiers={["strong"]} size="l">
                            {translateText(28)}
                        </Text>
                    </Grid>
                </Grid>

                <StyledGrid container>
                    <Grid s={12}>
                        <RepairShopDetails repairShop={repairShop} />
                    </Grid>
                </StyledGrid>
            </StyledContent>
        )
    }

    return (
        <StyledWrapper>
            <StyledHeader>
                <Button size="l" onClick={() => onVehicleAcceptance(true)}>
                    {translateText(585)}
                </Button>
                <Button size="l" onClick={() => onVehicleAcceptance(false)}>
                    {translateText(584)}
                </Button>
            </StyledHeader>

            <Text modifiers="strong">{translateText(862)}</Text>
            <Text>{translateText(12426)}</Text>

            <StyledContent>
                {renderVehicleSections()}
                {/* {renderCustomerSections()} */}
            </StyledContent>
        </StyledWrapper>
    )
}
const StyledVehicleImage = styled(VehicleImage)(() => ({
    width: "100%",
}))
const StyledWrapper = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
}))

const StyledHeader = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    margin: theme.spacing(0.5),
    zIndex: 22,
}))

const StyledContent = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(1),
}))

const StyledGrid = styled(Grid)(({ theme }) => ({
    marginTop: theme.spacing(1.5),
}))

export default ImportAdditionalDetails
