import * as React from "react"
import { useLocalization } from "@tm/localization"
import { Step, StepsContainer } from "@tm/controls"
import { WheelSelectionSteps } from "../../../data/enums"
import Morpheus from "@tm/morpheus"
import { ConfigParams } from "@tm/models"
import { useSelector } from "react-redux"
import { MainState } from "../../main"
import { Steps, StepsArray } from "../../../data/helpers"
import { Tooltip, styled } from "@tm/components"

type Props = {
    onChange(wheelSelectionStep: WheelSelectionSteps): void
}

const WheelsSelectionStepsComponent: React.FC<Props> = React.memo(({ onChange }) => {

    const handleStepSelect = React.useCallback((id?: number | string) => {
        onChange(id as WheelSelectionSteps)
    }, [])

    const renderSteps = (item: StepsArray, index: number) => {
        return (
            <StepsComponent key={index} title={item.title} tooltipTitle={item.tooltipTitle} id={item.id} current={item.current} onSelect={handleStepSelect} />
        )
    }

    return (
        <StepsContainer>
            {Steps.map(renderSteps)}
        </StepsContainer>
    )
})

export default WheelsSelectionStepsComponent

type StepsProps = {
    id: WheelSelectionSteps,
    current: string,
    title: number,
    tooltipTitle: number,
    onSelect: (id?: string | number | undefined) => void
}

const StepsComponent: React.FC<StepsProps> = React.memo(({ id, current, title, tooltipTitle, onSelect }) => {

    const { translateText } = useLocalization()
    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()

    const { selectedTabs, activeStep, isRDKSAvailable, showVehicleSelection } = useSelector((s: MainState) => ({
        selectedTabs: s.navigation.selectedTabs,
        activeStep: s.navigation.activeStep,
        isRDKSAvailable: s.navigation.isRDKSAvailable,
        showVehicleSelection: s.loadingScreen.showVehicleSelection
    }))

    const handleOnSelect = (id?: number | string) => {
        onSelect(id)
    }

    if (current == "vehicle-selection" && !showVehicleSelection)
        return null

    if (current == "rdks-list" && !isRDKSAvailable)
        return null

    if (current == "sensor-details" && (!isRDKSAvailable || articleDetailsInModal))
        return null

    if (current == "tyres-details" && articleDetailsInModal)
        return null

    return (
        <Tooltip key={current + id} placement="bottom" title={translateText(tooltipTitle)}>
            <StepWrapper>
                <Step
                    key={current}
                    id={id}
                    title={translateText(title)}
                    current={activeStep == id}
                    onClick={handleOnSelect}
                    disabled={!selectedTabs[id]?.wasSelected}
                    checked
                    hideIcon
                />
            </StepWrapper>
        </Tooltip>
    )
})

const StepWrapper = styled("div")({
    display: "flex",
    "& + &": {
        marginLeft: "-.4em"
    }
})