import { Article, Vehicle } from "@tm/models"

export const mapArticleToNoteIdentifier = (article: Article | string): string => {
    return typeof article === "string" ? article : `${article.supplierArticleNo}_${article.supplier.id}`
}

export const mapArticleAndVehicleToNoteIdentifier = (article: Article | string, vehicle: Vehicle): string => {
    return typeof article === "string" ? article : `${article.supplierArticleNo}_${article.supplier.id}_${vehicle.tecDocTypeId}`
}

export const mapArticleAndVehicleToNoteIdentifierList = (article: Article | string, vehicle?: Vehicle): string[] => {
    const noteIdentifier: string[] = []
    if (typeof article === "string") {
        return [article as string]
    }

    if (vehicle) {
        noteIdentifier.push(mapArticleAndVehicleToNoteIdentifier(article, vehicle))
    }

    noteIdentifier.push(mapArticleToNoteIdentifier(article))

    return noteIdentifier
}

export function getArticleNoteIdentifier(article: Article, vehicle?: Vehicle) {
    const ids = [`${article.supplierArticleNo}_${article.supplier.id}`]
    if (vehicle) {
        ids.push(`${article.supplierArticleNo}_${article.supplier.id}_${vehicle.tecDocTypeId}`)
    }
    return ids
}
