import { ajax, getStoredAuthorization } from "@tm/utils"
import { FastServiceData } from "../../models"
import { IncludeAdditionalWorksRequest } from "./model"
import { mapFastServiceResponse } from "../generalMapper"
import { getBundleParams } from "../../../utils"

export function submitIncludeAddtionalWorks(data: IncludeAdditionalWorksRequest): Promise<FastServiceData> {
    const url = `${getServiceUrl()}/fastcalculator/FastServiceSubmitCalculateIncludedAdditionalWork`
    const authorization = getStoredAuthorization()
    const body = data

    return ajax({ url, body, authorization, method: "POST" }).then((res) => {
        if (res && res.cockpitContext) {
            return mapFastServiceResponse(res)
        }
        throw new Error()
    })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
