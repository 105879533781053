import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { CheckCustomerResponse } from "./model"

export const checkCustomer = async (): Promise<CheckCustomerResponse> => {
    const url = `${getServiceUrl()}/CheckCustomer`
    const authorization = getStoredAuthorization()

    return ajax<CheckCustomerResponse>({ url, method: "GET", authorization }).then((response) => {
        if (!response) {
            throw new Error()
        }
        return response
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.datCustomerUrl
}
