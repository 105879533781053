import { PropsWithChildren, useCallback, useState } from "react"
import { Article, ArticleAttribute, ArticleErpInfo, ArticleListType } from "@tm/models"
import { ArticleListActionsProvider } from "../ArticleListActions"
import { PartsModuleStateProvider, usePartsModuleState } from "../PartsModuleState"
import { useErpInfos } from "../hooks/useErpInfos"
import { useListOptions } from "../hooks/useListOptions"
import { useShowArticleDetails } from "../hooks/useShowArticleDetails"
import { useSupplierLogos } from "../hooks/useSupplierLogos"
import { useWatchListData } from "../hooks/useWatchListData"
import { ArticleListActions, UniversalPartsModuleState } from "../models"
import { useArticles } from "./hooks/useArticles"
import { useFilters } from "./hooks/useFilters"
import { useListParams } from "./hooks/useListParams"
import { useBasketQuantities } from "../hooks/useBasketQuantities"
import { useNotes } from "../hooks/useNotes"
import { useHasLoadingEnded } from "../hooks/useHasLoadingEnded"
import { useTradeReferences } from "../hooks/useTradeReferences"
import { usePreviouslyOrderedArticles } from "../hooks/usePreviouslyOrderedArticles"

export function UniversalPartsModuleStateProvider({ children, inModal }: PropsWithChildren<{ inModal?: boolean }>) {
    const type: ArticleListType = "universal"
    const [isEnabled, setIsEnabled] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([])

    const options = useListOptions()
    const params = useListParams()
    const filtersData = useFilters(params, isEnabled)
    const articlesData = useArticles(params, erpInfos, isEnabled)
    const { tradeReferences, tradeReferenceNumbersLoaded } = useTradeReferences(articlesData.articles, isEnabled)
    const erpInfosData = useErpInfos({
        articles: articlesData.articles,
        isEnabled: isEnabled && tradeReferenceNumbersLoaded,
        startParams: params.startParams,
        tradeReferences,
        erpInfos,
        setErpInfos,
    })
    const supplierLogosData = useSupplierLogos(articlesData.supplierIds, options.showDataSupplierLogos && isEnabled)
    const watchListData = useWatchListData(articlesData.articles, !!options.hasWatchList && isEnabled && erpInfosData.isReady)
    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(articlesData.articles, isEnabled && erpInfosData.isReady)
    const notes = useNotes(articlesData.articles, false, isEnabled && erpInfosData.isReady)
    const previouslyOrderedArticles = usePreviouslyOrderedArticles(articlesData.articles)

    const hasLoadingEnded = useHasLoadingEnded(supplierLogosData.isLoading, watchListData?.isLoading)

    const toggleInlineFilter = useCallback((attribute: ArticleAttribute, article: Article) => {
        params.toggleAttribute(attribute)
    }, [])

    /**
     * Do not wrap it with `useMemo` here but threat it as not reference stable and therefor not use it as any dependency for other hooks.
     */
    const state: UniversalPartsModuleState = {
        type,
        start: useCallback(() => setIsEnabled(true), []),
        options,
        params,
        filters: filtersData,
        articles: articlesData,
        supplierLogos: hasLoadingEnded ? supplierLogosData : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimes: undefined,
        activeVehicleDataProviders: undefined,
        basketQuantities,
        notes,
        previouslyOrderedArticles,
        tradeReferences,
        inModal,
        erpInfosData,
    }

    const articleActionsValue: ArticleListActions = {
        toggleSupplier: filtersData.toggleSupplier,
        toggleInlineFilter,
        showArticleDetails,
        updateBasketQuantities,
    }

    return (
        <PartsModuleStateProvider value={state}>
            <ArticleListActionsProvider value={articleActionsValue}>{children}</ArticleListActionsProvider>
        </PartsModuleStateProvider>
    )
}

export function useUniversalPartsModuleState(): UniversalPartsModuleState
export function useUniversalPartsModuleState<TReturnType>(selector: (value: UniversalPartsModuleState) => TReturnType): TReturnType
export function useUniversalPartsModuleState<TReturnType>(
    selector?: (value: UniversalPartsModuleState) => TReturnType
): UniversalPartsModuleState | TReturnType {
    return usePartsModuleState<UniversalPartsModuleState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
