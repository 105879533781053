import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ConfigParams, IMicros, RepairTimeProvider, RepairTimeRD, SystemType, Vehicle } from "@tm/models"
import Morpheus, { useActions, useMicro } from "@tm/morpheus"
import { classes, equals, renderRoute, uniqueId } from "@tm/utils"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Box, Typography } from "@tm/components"
import { useCallback, useEffect } from "react"
import { createMemo, validateFields } from "../../data/helpers"
import { mapIAMPartToArticle, mapWorkToService } from "../../data/mappers"
import { ExtendedOePart, MatchParams, NavigationSteps } from "../../data/models"
import { getBundleParams } from "../../utils"
import { asWegasWindow } from "../extern/component"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { calculationSelector, managerSelector, navigationSelector, summarySelector } from "../main/business/selectors"
import { asWegasTransactionsWindow } from "../transactions/component"
import NavigationButton from "../calculation/components/shared/NavigationButton"
import { useBasketImports } from "../../../../basket/src/hooks/basketState/useBasketImports"
import { useCostEstimationModuleParameter } from "../../../../basket/src/hooks/useCostEstimationModuleParameter"

type Props = {
    route: string
}

export default function NavigationComponent({ route }: Props) {
    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()

    const { translateText } = useLocalization()
    const matchParams = useParams<MatchParams>()
    const history = useHistory()

    const state = useSelector(navigationSelector)
    const getArticlesInProgress = useSelector<MainState, boolean | undefined>((s) => s.extern.getArticlesInProgress)
    const taskId = useSelector<MainState, string | undefined>((s) => s.manager.taskId)
    const { renderMicro } = useMicro<IMicros>()

    const workTask = useWorkTask()
    const { importToCostEstimation } = useBasketImports()

    const { fields, prepareCalculationLoading } = useSelector(summarySelector)
    const { vehicle, customer } = useSelector(managerSelector)

    const modelState = validateFields(fields, translateText)
    const isInvalid = Object.values(modelState).some((val) => val.length)
    const user = useUser()
    const { items, works, erpArticles, importLoading } = useSelector(calculationSelector)
    const actions = useActions(MainActions, "changeStep", "reset", "getCalculationData", "startCalculation", "addToShoppingBasket")
    const { view } = matchParams

    const { hasCostEstimation } = useCostEstimationModuleParameter()

    const selectedOeArticles: ExtendedOePart[] = items
        ?.filter((x) => x.isSelected && !x.selectedPart)
        .map((x) => ({ ...x.oeArticle, replacements: x.alternativeParts?.map((y) => y.dataSupplierArticleNumber) }))
    const selectedIAMArticles: Article[] = items
        ?.filter((x) => x.isSelected && x.selectedPart && x.selectedPart.article)
        .map((x) =>
            mapIAMPartToArticle(
                x.selectedPart!,
                erpArticles?.find((y) => y.dataSupplierArticleNumber === x.selectedPart!.dataSupplierArticleNumber)
            )
        )

    const selectedWorks: RepairTimeRD[] = works?.filter((x) => x.isSelected).map(mapWorkToService)

    const handleChangeStep = (newStep: NavigationSteps) => {
        const routeParams = {
            workTaskId: matchParams.workTaskId,
            ...state.params[newStep],
            view: newStep,
        }

        // eslint-disable-next-line no-restricted-globals
        const url = renderRoute(route, routeParams) + location.search

        if (view === "" || view === newStep) {
            history.replace(url)
        } else {
            history.push(url)
        }
    }

    useEffect(() => {
        if (!view || (["extern", "calculation"].includes(view) && !matchParams.taskId)) {
            reset()
        }

        window.onbeforeunload = (event: BeforeUnloadEvent) => {
            asWegasWindow?.close()
        }
    }, [])

    useEffect(() => {
        state.active && handleChangeStep(state.active)

        !state.active && view && actions.changeStep(view, { ...(!state.init && { ...matchParams }) })
    }, [state.active])

    const getArticles = () => {
        asWegasWindow?.close()
        // would be nice to get the taskId and pass it forward
        const { taskId } = matchParams
        actions.getCalculationData(taskId)
    }

    const reset = () => {
        asWegasWindow?.close()
        asWegasTransactionsWindow?.close()

        actions.reset()
        actions.changeStep("summary")
    }

    const attachToWorkTask = useCallback(() => {
        const selectedKeyNumber = fields.keyNumber && fields.keyNumbers.find((x) => x.indexOf(fields.keyNumber!) === 0)

        const updatedVehicle: Vehicle = {
            ...vehicle!,
            ...(fields.initialRegistration !== vehicle?.initialRegistration && { initialRegistration: fields.initialRegistration }),
            ...(selectedKeyNumber !== vehicle?.registrationNo && { registrationNo: selectedKeyNumber }),
            ...(fields.mileage !== vehicle?.mileAge && { mileAge: fields.mileage }),
            ...(fields.vin !== vehicle?.vin && { vin: fields.vin }),
            ...(fields.plateNumber !== vehicle?.plateId && { plateId: fields.plateNumber }),
        }
        const shouldUpdateVehicle = !!vehicle && !equals(vehicle, updatedVehicle)

        const updatedCustomer = {
            id: uniqueId(),
            ...customer,
            ...(fields.titleType !== customer?.titleType && { titleType: fields.titleType }),
            ...(fields.firstName !== customer?.firstName && { firstName: fields.firstName }),
            ...(fields.lastName !== customer?.lastName && { lastName: fields.lastName }),
            ...(fields.street !== customer?.street && { street: fields.street }),
            ...(fields.zip !== customer?.zip && { zip: fields.zip }),
            ...(fields.city !== customer?.city && { city: fields.city }),
            ...(fields.country !== customer?.country && { country: fields.country }),
        }
        const shouldUpdateCustomer = !equals(customer, updatedCustomer) && !!fields.titleType && !!fields.firstName && !!fields.lastName

        if (shouldUpdateVehicle || shouldUpdateCustomer) {
            workTask?.attachToWorkTask({
                ...(shouldUpdateVehicle && { vehicle: updatedVehicle }),
                ...(shouldUpdateCustomer && { customer: updatedCustomer }),
            })
        }
    }, [fields])

    const handleStartCalcBtn = () => {
        getBundleParams().useAttachToWorktask && attachToWorkTask()

        actions.startCalculation(translateText)
    }
    const handleAddToBasket = () => {
        const memo = user?.userSettings?.orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ? createMemo(vehicle, customer) : undefined
        actions.addToShoppingBasket(importToCostEstimation, memo)
    }

    return (
        <Box display="flex" flex="0" padding="0 1rem !important" className={classes("module toolbar", "sg-navigation")}>
            <Box margin="auto 1.5em auto 0">
                <Typography variant="h2">{translateText(12527)} </Typography>
            </Box>

            <Toolbar title={translateText(176)}>
                <NavigationButton
                    onClick={() => actions.changeStep("summary")}
                    text={translateText(409)}
                    disabled={false}
                    icon="summary"
                    showBadge={false}
                    color={view === "summary" ? "primary" : undefined}
                />
                <NavigationButton
                    onClick={() => actions.changeStep("extern", state.params.extern || { taskId: matchParams.taskId })}
                    text={translateText(1613)}
                    disabled={view !== "extern" && !state.externVisited && !state.calcVisited}
                    icon="damage-calculation"
                    showBadge={false}
                    color={view === "extern" ? "primary" : undefined}
                />
                <NavigationButton
                    onClick={() => actions.changeStep("calculation", state.params.calculation || { taskId: matchParams.taskId })}
                    text={translateText(613)}
                    disabled={view !== "calculation" && !state.calcVisited}
                    icon="calculation"
                    showBadge={false}
                    color={view === "calculation" ? "primary" : undefined}
                />
                {!articleDetailsInModal && (
                    <NavigationButton
                        onClick={() => handleChangeStep("details")}
                        text={translateText(43)}
                        disabled={view !== "details" && !state.params.details}
                        icon="details"
                        showBadge={false}
                        color={view === "details" ? "primary" : undefined}
                    />
                )}
            </Toolbar>

            <Toolbar title={translateText(177)}>
                <NavigationButton
                    className="transactions"
                    onClick={() => actions.changeStep("transactions")}
                    text={translateText(12473)}
                    disabled={false}
                    icon="summary"
                    showBadge={false}
                    color={view === "transactions" ? "primary" : undefined}
                />
                <NavigationButton
                    className="reset"
                    onClick={reset}
                    text={translateText(48)}
                    disabled={!state.externVisited && !state.calcVisited}
                    icon="synchronize"
                    showBadge={false}
                    color="error"
                />
            </Toolbar>

            {view === "extern" && (
                <NavigationButton
                    sx={{
                        position: "absolute",
                        right: "2.5em",
                        alignSelf: "center",
                        width: "fit-content",
                        height: "2.5em",
                    }}
                    onClick={() => getArticles()}
                    text={translateText(3165)}
                    disabled={!taskId || getArticlesInProgress}
                    icon="calculation"
                    showBadge={getArticlesInProgress}
                    color="highlight"
                    variant="contained"
                />
            )}

            {view === "summary" && (
                <Toolbar className={style.takeDamage} title={translateText(12884)}>
                    <NavigationButton
                        sx={{ marginLeft: "auto", marginRight: "-.25em" }}
                        onClick={handleStartCalcBtn}
                        text={translateText(1461)}
                        disabled={prepareCalculationLoading || isInvalid}
                        icon="damage-calculation"
                        showBadge={prepareCalculationLoading}
                        variant="contained"
                        color="highlight"
                    />
                </Toolbar>
            )}

            {view === "calculation" && hasCostEstimation && user?.userContext?.system.systemType === SystemType.Next && (
                <Toolbar className={style.cart} title={translateText(916)}>
                    <NavigationButton
                        onClick={handleAddToBasket}
                        text={translateText(1607)}
                        disabled={importLoading}
                        showBadge={importLoading}
                        variant="contained"
                        color="highlight"
                    />
                </Toolbar>
            )}

            {view === "calculation" && user?.userContext?.system.systemType === SystemType.Redesign && (
                <Box ml="auto">
                    {renderMicro("standalone", "rd-add-articles-rt-to-basket", {
                        oeArticles: selectedOeArticles,
                        iamArticles: selectedIAMArticles,
                        services: selectedWorks,
                        repairTimeDivision: user?.userSettings?.repairTimeOptions?.division,
                        provider: RepairTimeProvider.Dat,
                        vehicle,
                        sourceId: "TM_DMG_ASWEGAS" as any,
                        buttonText: translateText(358),
                    })}
                </Box>
            )}
        </Box>
    )
}

const style = useStyle({
    takeDamage: {
        marginLeft: "auto",
        marginRight: "-.25em",
    },
    cart: {
        marginLeft: "auto",
    },
})(NavigationComponent)
