import { useReplaceUrlTags, WorkTaskInfo } from "@tm/context-distribution"
import {
    AddOePartListRequest,
    AddressSelectionItem,
    channel,
    ErpPrice,
    getCurrentWorkTaskId,
    OePart,
    PriceType,
    QuantitiyGroupsDto,
    SendOrderOptions,
} from "@tm/models"
import { concat, DateUnit, notUndefinedOrNull } from "@tm/utils"
import { xor } from "lodash"
import { mapOrderOptionsToSendOrderOptions } from "./data/mapper"
import { ErpGroupResponse, OrderGroup } from "./data/model"
import { BasketErpInfo, BasketErpInfoGroup, OrderOptionsGroup } from "./models"

export function notifyBasketChanges(workTask?: WorkTaskInfo) {
    if (typeof tmJSEvents !== "undefined" && tmJSEvents && tmJSEvents.onExportShoppingBasket && workTask?.refId) {
        tmJSEvents.onExportShoppingBasket(workTask.id)
    }
}

export function publishToChannel(workTaskId: string | undefined) {
    workTaskId = workTaskId ?? getCurrentWorkTaskId()

    if (!workTaskId) {
        return
    }

    channel("WORKTASK", workTaskId).publish("BASKET/ARTICLE_ADDED", {})
}

export function getQuantityFromQuantitiyGroupsDto(
    quantityGroups: QuantitiyGroupsDto | undefined,
    distributorId: number | undefined,
    warehouseId: string | undefined
) {
    let quantity = 0
    let ids: string[] = []
    if (quantityGroups) {
        const { allPartItemIds, totalQuantity, orderQuantityGroups } = quantityGroups

        if (distributorId || warehouseId) {
            orderQuantityGroups
                .filter((group) => (!distributorId || group.distributorId === distributorId) && (!warehouseId || group.warehouseId === warehouseId))
                .forEach((group) => {
                    quantity += group.quantityValue
                    ids.push(...group.partItemIds)
                })
        } else {
            quantity = totalQuantity
            ids = allPartItemIds
        }
    }
    return { quantity, ids }
}

export function useAddArticleExternalUrl(
    externalCatalogUrl: string | undefined,
    languageId: string,
    quantity?: number,
    wholesalerArticleNumber?: string,
    supplierArticleNumber?: string,
    supplierNumber?: number,
    productGroupId?: number
) {
    let url = externalCatalogUrl
    url = useReplaceUrlTags(url, { languageId, quantity, wholesalerArticleNumber, supplierArticleNumber, supplierNumber, productGroupId }) || url
    url = url ? `/external01^?url=${encodeURIComponent(url)}` : ""
    return url
}

export function getMatchingOrderOptionsGroup(orderGroup: OrderGroup, orderOptionGroups?: OrderOptionsGroup[]): OrderOptionsGroup | undefined {
    if (!orderOptionGroups?.length) {
        return undefined
    }
    if (orderGroup.distributorId) {
        return orderOptionGroups.find((orderOptionGroup) => orderOptionGroup.distributorId === orderGroup.distributorId)
    }
    if (orderGroup.warehouseId) {
        return orderOptionGroups.find((orderOptionGroup) => orderOptionGroup.warehouseId === orderGroup.warehouseId)
    }

    return orderOptionGroups.find((orderOptionGroup) => !orderOptionGroup.distributorId && !orderOptionGroup.warehouseId)
}

export function getMatchingErpInfo(orderGroup: OrderGroup, erpInformation?: BasketErpInfo[]): BasketErpInfo[] | undefined {
    if (!erpInformation?.length) {
        return undefined
    }

    return erpInformation.filter((erpInfo) => erpInfo.request.distributorId === orderGroup.distributorId)
}

export function getMatchingErpInfoGroup(orderGroup: OrderGroup, erpInformation?: BasketErpInfoGroup[]): BasketErpInfoGroup | undefined {
    if (!erpInformation?.length) {
        return undefined
    }
    return erpInformation.find((erpInfo) => erpInfo.distributorId === orderGroup.distributorId)
}

export function getMatchingUpdatedErpGroupResponse(orderGroup: OrderGroup, erpGroups?: ErpGroupResponse[]): ErpGroupResponse | undefined {
    if (!erpGroups?.length) {
        return undefined
    }
    return erpGroups.find((erpGroup) => erpGroup.distributorId === orderGroup.distributorId)
}

export function getErpInfoGroupOrderOptions(
    erpInfoGroup: BasketErpInfoGroup,
    orderOptionGroups?: OrderOptionsGroup[],
    wholesalerOrderConfirmation?: boolean, // TODO: Remove since it is not required in getBasketErpInfo
    orderConfirmationEmail?: string // TODO: Remove since it is not required in getBasketErpInfo
): SendOrderOptions | undefined {
    if (!orderOptionGroups?.length) {
        return undefined
    }

    const orderOptions = orderOptionGroups
        .filter((orderOptionGroup) => !orderOptionGroup.warehouseId)
        .find((orderOptionGroup) => orderOptionGroup.distributorId === erpInfoGroup.distributorId)?.orderOptions
    return orderOptions ? mapOrderOptionsToSendOrderOptions(orderOptions, wholesalerOrderConfirmation, orderConfirmationEmail) : undefined
}

export function getErpInfoGroupWarehouseOrderOptions(
    erpInfoGroup: BasketErpInfoGroup,
    orderOptionGroups?: OrderOptionsGroup[],
    wholesalerOrderConfirmation?: boolean, // TODO: Remove since it is not required in getBasketErpInfo
    orderConfirmationEmail?: string // TODO: Remove since it is not required in getBasketErpInfo
): SendOrderOptions[] | undefined {
    if (!orderOptionGroups?.length || erpInfoGroup.distributorId) {
        return undefined
    }

    const orderOptions = orderOptionGroups
        .map((optionGroup) =>
            !!optionGroup.orderOptions && optionGroup.warehouseId
                ? mapOrderOptionsToSendOrderOptions(optionGroup.orderOptions, wholesalerOrderConfirmation, orderConfirmationEmail)
                : undefined
        )
        .filter(notUndefinedOrNull)
    return orderOptions.length ? orderOptions : undefined
}

export const EMPTY_OPTION = "-"

export function getAddressLabels(addressItem: AddressSelectionItem, isSummary: boolean, titleText?: string, hideContactData?: boolean) {
    const title = addressItem.title || titleText || ""
    const name = concat(" ", addressItem.firstName, addressItem.lastName)
    let label = concat(", ", addressItem.companyName, title, name)
    const location = concat(" ", addressItem.zip, addressItem.city)
    let address = concat(", ", addressItem.street, location, addressItem.country)
    if (!hideContactData) {
        address = concat(", \n", address, addressItem.phone, addressItem.eMail)
    }
    if (address !== "" && label !== "" && isSummary) {
        address = "..."
    }

    label = concat(", \n", label, address)

    return label !== "" || !isSummary ? label.split(", \n") : [EMPTY_OPTION]
}

export function getAddressSelectLabels(addresses?: AddressSelectionItem[]) {
    if (!addresses) {
        return
    }

    const singleAddress = addresses.map((addressItem: AddressSelectionItem) => {
        const { title, description } = addressItem
        const desc = concat(" ", description, addressItem.companyName)
        const name = concat(" ", addressItem.firstName, addressItem.lastName)
        let label = concat(", ", desc, title, name)
        const location = concat(" ", addressItem.zip, addressItem.city)
        const address = concat(", ", addressItem.street, location, addressItem.country)

        label = concat(" | \n", label, address)

        return [addressItem.id, label]
    })

    return singleAddress
}

export function findPrice(prices: (ErpPrice | undefined)[] | undefined, type: PriceType) {
    return prices?.find((price) => price?.type === type)
}

export function createAddOePartListRequest(part: OePart, quantity: number, workTask: WorkTaskInfo, memo?: string): AddOePartListRequest {
    return {
        oeParts: [
            {
                ...part,
                quantityValue: quantity,
                memo,
            },
        ],
        workTaskId: workTask.id,
        vehicleId: workTask.vehicle?.id,
        customerId: workTask.customer?.id,
        usePercentageValues: true,
    }
}

/**
 * Creates and returns a function that accepts an array of toggle values and calls the provided setter with a function that returns the symmetric difference of the previous values and the toggle values.
 * Symmetric difference means the toggle values will be added to the previous array if they are not present, and removed if they are - basically toggling the values.
 * For more information @see https://lodash.com/docs#xor
 */
export function createArrayToggle<T extends string | number>(set: (value: (prev: T[]) => T[]) => void) {
    return (toggleValues: T[]) => {
        set((prev) => xor(prev, toggleValues))
    }
}

export function isSameDay(date1: Date | null, date2: Date | null): boolean {
    // if both dates are unset, they should be considered equal
    if (!date1 && !date2) {
        return true
    }

    // otherwise if only one of the dates is unset, they should not be considered equal
    if (!date1 || !date2) {
        return false
    }

    // otherwise compare dates
    return date1.isSame(date2, DateUnit.Day)
}

export function isDateValid(value: Date | null, minDate: Date): boolean {
    return value instanceof Date && value.getTime() >= minDate.getTime()
}
