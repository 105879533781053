import { useState, useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { encodeUniqueId } from "@tm/utils"
import { Dialog, Typography, Switch, Stack, Button, Icon, paperClasses, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useWorkTask, useModuleNavigation, useUser } from "@tm/context-distribution"
import { channel, UserModuleType, Vehicle } from "@tm/models"
import { useHistory } from "react-router-dom"
import { removeContextDependentIFrames } from "../../helper/remove-context-dependent-iframes"
import { Actions, IActions } from "./business/actions"
import DialogInfo from "./subcomponents/DialogInfo"
import { ChangeDialogState } from "./business/model"

type Props = {
    actions: IActions
    state: ChangeDialogState
}

const StyledIcon = styled(Icon)(({ theme }) => ({
    marginRight: theme.spacing(1),
    color: theme.palette.error.main,
}))
StyledIcon.defaultProps = { size: "25", name: "not" }

const UNIQUE_SEPERATOR = "[-]"

function WorktaskChangeConflictResolverComponent({ actions, state: { openTabs, openTabsLoading } }: Props) {
    const history = useHistory()
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { workTaskId, workTask, workTaskConflict, createWorkTask } = useWorkTask() ?? {}
    const { closeTabs, resetTabs } = useModuleNavigation()
    const [asCustomerVehicle, setAsCustomerVehicle] = useState<boolean>(false)
    const [newWorkTask, setNewWorkTask] = useState<boolean>(true)
    const [isDialogActive, setIsDialogActive] = useState<boolean>(false)

    const vehicleChangeEnabled = userContext?.modules?.find((module) => module.type === UserModuleType.Catalog)?.vehicleChangeEnabled

    useEffect(() => {
        if (workTaskConflict) {
            actions.loadOpenTabs(workTaskConflict.currentWorkTaskId)
            setIsDialogActive(true)
        }
    }, [actions, workTaskConflict])

    useEffect(() => {
        if (!vehicleChangeEnabled) {
            setNewWorkTask(true)
        }
    }, [vehicleChangeEnabled])

    if (!workTaskConflict) {
        return null
    }

    const vehicle = workTaskConflict.data && workTaskConflict.data.vehicle ? workTaskConflict.data.vehicle : undefined
    const splittedDialogMessage = translateText(13849).split(UNIQUE_SEPERATOR)

    async function handleConfirm() {
        if (!vehicleChangeEnabled && createWorkTask) {
            await createWorkTask({ customer: asCustomerVehicle ? workTask?.customer : undefined, vehicle: workTaskConflict?.data.vehicle })
            return
        }

        if (workTaskConflict) {
            if (!newWorkTask || !workTaskConflict.options.showNewWorkTask) {
                if (workTaskConflict.data.conflictedTabs?.length) {
                    closeTabs(workTaskConflict.data.conflictedTabs.map((x) => x.url))
                } else {
                    resetTabs()
                    workTaskId && history.push(`/${encodeUniqueId(workTaskId)}`)
                    removeContextDependentIFrames()
                    // TODO: Remove this action when attachVehicleImage supports the vehicle id
                    channel("WORKTASK", workTaskId).publish("WORKTASK/RESET", {})
                }
            }
            workTaskConflict.onConfirm(newWorkTask, asCustomerVehicle)
        }
    }

    function closeDialog() {
        setIsDialogActive(false)
        workTaskConflict?.onCancel()
    }

    function handleAsCustomerVehicleChange() {
        setAsCustomerVehicle((prev) => !prev)
    }

    function handleNewWorkTaskChange() {
        setNewWorkTask((prev) => !prev)
    }

    function getDialogTitle() {
        if (vehicleChangeEnabled) {
            return workTaskConflict?.data.conflictType ? translateText(13695) : translateText(921)
        }

        return translateText(13847)
    }

    function getVehicleName(vehicleData: string | Vehicle | undefined) {
        let vehicleName: string | undefined

        if (typeof vehicleData !== "string" && typeof vehicleData !== "undefined") {
            vehicleName = `${vehicleData.manufacturer} ${vehicleData.modelSeries} ${vehicleData.model}`
        }

        return vehicleName
    }

    function getDialogBody() {
        if (vehicleChangeEnabled) {
            return (
                <Stack gap={2}>
                    {workTaskConflict?.options.showNewWorkTask && (
                        <Stack>
                            <Switch label={translateText(928)} checked={newWorkTask} onChange={handleNewWorkTaskChange} />
                            <Typography variant="label">{newWorkTask ? translateText(1190) : translateText(1191)}</Typography>
                        </Stack>
                    )}
                    {vehicle && workTask?.customer && workTaskConflict?.options.showNewCustomerVehicle && (
                        <Stack>
                            <Switch label={translateText(923)} checked={asCustomerVehicle} onChange={handleAsCustomerVehicleChange} />
                            <Typography variant="label">
                                {(asCustomerVehicle ? translateText(1192) : translateText(1193)).replace("{{0}}", getVehicleName(vehicle) ?? "")}
                            </Typography>
                        </Stack>
                    )}
                    {!!workTaskConflict?.data.conflictedTabs?.length && (
                        <DialogInfo
                            actionsWillBeDone={workTaskConflict.data.conflictedTabs.map((x) => x.title)}
                            loading={openTabsLoading}
                            intro={translateText(1189)}
                        />
                    )}
                    {!workTaskConflict?.data.conflictedTabs && !!openTabs?.length && (
                        <DialogInfo actionsWillBeDone={openTabs.map((tab) => tab.title)} loading={openTabsLoading} intro={translateText(1189)} />
                    )}
                </Stack>
            )
        }

        return (
            <>
                <Typography variant="body1">
                    {`${splittedDialogMessage[0]} `}
                    <Typography variant="body1" fontWeight={600} display="inline-block">
                        {getVehicleName(vehicle)}
                    </Typography>{" "}
                    {splittedDialogMessage[1]}
                </Typography>
                {vehicle && workTask?.customer && (
                    <Switch label={translateText(13848)} checked={asCustomerVehicle} onChange={handleAsCustomerVehicleChange} />
                )}
            </>
        )
    }

    return (
        <Dialog
            open={isDialogActive}
            skin={vehicleChangeEnabled ? "error" : "info"}
            position="top"
            fullWidth
            customSkinIcon={vehicleChangeEnabled ? <StyledIcon /> : undefined}
            sx={{ [`& .${paperClasses.root}`]: { maxWidth: "950px" } }}
            disableEscapeKeyDown
        >
            <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={2}>
                <Stack gap={2.5}>
                    <Typography variant="h2">{getDialogTitle()}</Typography>
                    {getDialogBody()}
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Button variant="text" onClick={closeDialog}>
                        <Icon name="close" size="27" />
                    </Button>
                    <Button variant="contained" color="success" size="extralarge" onClick={handleConfirm}>
                        {vehicleChangeEnabled ? translateText(316) : translateText(13110)}
                    </Button>
                </Stack>
            </Stack>
        </Dialog>
    )
}

export default connectComponent(Actions, WorktaskChangeConflictResolverComponent)
