import { CarModelSeries, ModelSeriesBase, VehicleType } from "@tm/models"
import { selectorFamily, useRecoilValue } from "recoil"

import { RequestWithVehicleType } from "."
import { CarBodyType, ModelYearFilter } from "../model/filters"
import * as Bikes from "../repositories/bikes/showModelSeries"
import * as Cars from "../repositories/cars/showModelSeries"
import * as Trucks from "../repositories/trucks/showModelSeries"

type ModelSeriesResponse =
    | undefined
    | {
          modelSeries: Array<ModelSeriesBase>
          filters: ModelSeriesFilters
      }

type ModelSeriesFilters = {
    /** Only used for `VehicleType.PassengerCar` */
    bodyTypes: Array<CarBodyType>
    modelYears: Array<ModelYearFilter>
}

type SelectedModelSeriesFilters = {
    /** Only used for `VehicleType.PassengerCar` */
    bodyTypeId?: number
    modelYear?: number
}

type ModelSeriesByManufacturerRequest = RequestWithVehicleType & {
    manufacturerId: number
    mainModelSeriesId?: number
    selectedFilters: SelectedModelSeriesFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
}

const modelSeriesByManufacturer = selectorFamily<ModelSeriesResponse, ModelSeriesByManufacturerRequest>({
    key: "vehicle_modelSeriesByManufacturer",
    get:
        ({ vehicleType, manufacturerId, mainModelSeriesId, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar: {
                    return Cars.showMainModelSeriesWithModelSeries({ manufacturerId, selectedFilters }).then((response) => {
                        let modelSeries: CarModelSeries[]
                        if (mainModelSeriesId === 0) {
                            // Show all modelSeries, independently from mainModelSeries
                            modelSeries = response?.mainModelSeries.flatMap((x) => x.carModelSeries) ?? []
                        } else if (mainModelSeriesId === -1) {
                            // Show modelSeries from the undefined mainModelSeries
                            modelSeries = response?.mainModelSeries.find((x) => x.id === undefined)?.carModelSeries ?? []
                        } else {
                            modelSeries = response?.mainModelSeries.find((x) => x.id === mainModelSeriesId)?.carModelSeries ?? []
                        }
                        return {
                            modelSeries,
                            filters: {
                                bodyTypes: bodyTypesFilter(modelSeries, response?.filters?.bodyTypes),
                                modelYears: modelYearsFilter(modelSeries, response?.filters?.modelYears),
                            },
                        }
                    })
                }
                case VehicleType.Motorcycle:
                    return Bikes.showModelSeriesByManufacturer({ manufacturerId, selectedFilters }).then(mapModelSeriesResponse)
                case VehicleType.CommercialVehicle:
                    return Trucks.showModelSeriesByManufacturer({ manufacturerId, selectedFilters }).then(mapModelSeriesResponse)
                default:
            }
        },
})

export function useModelSeriesByManufacturer(request: ModelSeriesByManufacturerRequest) {
    return useRecoilValue(modelSeriesByManufacturer(request))
}

type ModelSeriesByArticleRequest = RequestWithVehicleType & {
    manufacturerId: number
    articleId: number
    mainModelSeriesId?: number
    selectedFilters: SelectedModelSeriesFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
}

const modelSeriesByArticle = selectorFamily<ModelSeriesResponse, ModelSeriesByArticleRequest>({
    key: "vehicle_modelSeriesByArticle",
    get:
        ({ vehicleType, manufacturerId, mainModelSeriesId, articleId, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showModelSeriesByArticle({ manufacturerId, mainModelSeriesId, articleId, selectedFilters })
                case VehicleType.Motorcycle:
                    return Bikes.showModelSeriesByArticle({ manufacturerId, articleId, selectedFilters }).then(mapModelSeriesResponse)
                case VehicleType.CommercialVehicle:
                    return Trucks.showModelSeriesByArticle({ manufacturerId, articleId, selectedFilters }).then(mapModelSeriesResponse)
                default:
            }
        },
})

export function useModelSeriesByArticle(request: ModelSeriesByArticleRequest) {
    return useRecoilValue(modelSeriesByArticle(request))
}

type ModelSeriesDetailsRequest = RequestWithVehicleType & {
    manufacturerId?: number
    modelSeriesId?: number
}

type ModelSeriesDetailsResponse = ModelSeriesBase | undefined

const modelSeriesDetails = selectorFamily<ModelSeriesDetailsResponse, ModelSeriesDetailsRequest>({
    key: "vehicle_modelSeriesDetails",
    get:
        ({ vehicleType, manufacturerId, modelSeriesId }) =>
        () => {
            if (!manufacturerId || !modelSeriesId) {
                return
            }

            let promise

            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    promise = Cars.showModelSeriesDetails({ manufacturerId, modelSeriesId })
                    break
                case VehicleType.Motorcycle:
                    promise = Bikes.showModelSeriesDetails({ manufacturerId, modelSeriesId })
                    break
                case VehicleType.CommercialVehicle:
                    promise = Trucks.showModelSeriesDetails({ manufacturerId, modelSeriesId })
                    break
                default:
            }

            return promise?.then((response) => response?.modelSeriesDetails)
        },
})

export function useModelSeriesDetails(request: ModelSeriesDetailsRequest) {
    return useRecoilValue(modelSeriesDetails(request))
}

function mapModelSeriesResponse(response: Bikes.ModelSeriesResponse | Trucks.ModelSeriesResponse | undefined): ModelSeriesResponse {
    return {
        modelSeries: response?.modelSeries ?? [],
        filters: {
            bodyTypes: [],
            modelYears: response?.filters?.modelYears ?? [],
        },
    }
}

function modelYearsFilter(cars: CarModelSeries[], modelYears: ModelYearFilter[] | undefined): ModelYearFilter[] {
    if (!cars || !modelYears) {
        return []
    }

    const highestYear = Math.max(...modelYears.map((x) => x.year))
    const yearList: number[] = []

    for (let i = 0; i < cars.length; i++) {
        const car = cars[i]

        if (car?.constructionYearFrom) {
            const list = createArrayRange(car.constructionYearFrom.year, car.constructionYearTo?.year ?? highestYear)
            yearList.push(...list.filter((x) => !yearList.includes(x)))
        }
    }

    return modelYears.filter((x) => yearList.includes(x.year))
}

function bodyTypesFilter(cars: CarModelSeries[], bodyTypes: CarBodyType[] | undefined): CarBodyType[] {
    if (!cars || !bodyTypes) {
        return []
    }

    const reducedTypes = cars
        .map((x) => x.bodyTypeIds)
        .reduce((acc, val) => acc.concat(val), [])
        .distinct()

    const result = bodyTypes.map((types) => {
        return { ...types, isAvailable: !!reducedTypes.includes(types.id) }
    })

    return result
}

function createArrayRange(first: number, last: number): number[] {
    const start = Math.floor(first)
    const end = Math.floor(last)

    const diff = end - start
    if (diff === 0) {
        return [start]
    }

    const keys = Array(Math.abs(diff) + 1).keys()
    return Array.from(keys).map((x) => {
        const increment = end > start ? x : -x
        return start + increment
    })
}
