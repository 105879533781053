import { UserModuleType } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { CustomerBillingResponse } from "./model"

export * from "./model"

export function handleCustomerBilling() {
    const url = `${getServiceUrl()}/CustomerBilling`
    const authorization = getStoredAuthorization()
    const body = {
        moduleType: UserModuleType.TMDamageCalculationDat,
    }

    return ajax<CustomerBillingResponse>({ url, body, method: "POST", authorization }).then((response) => {
        if (response?.successfull) {
            return response
        }

        throw new Error()
    })
}
function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.datCustomerUrl
}
