import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { TireBrands } from "./model"

export function getTiresBrands(): Promise<TireBrands> {
    const url = `${getServiceUrl()}/Tyres/TyresBrands`
    const authorization = getStoredAuthorization()

    return ajax({ url, authorization }).then((res) => {
        if (res) {
            return res
        }
        throw new Error()
    })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.wheelsBaseUrl
}
