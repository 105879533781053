import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { TestDriveSlice, TestDriveState } from "./model"

function getInitialState(): TestDriveState {
    return {
        testDriveState: {
            testDriveStatus: false,
            date: new Date(),
            mileageStart: undefined,
            mileageEnd: undefined,
            timeStart: undefined,
            timeEnd: undefined,
        },
    }
}

export const createTestDriveSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TestDriveSlice> = (set) => ({
    ...getInitialState(),
    testDriveActions: {
        setDate: (date: Date) => set((state) => ({ testDriveState: { ...state.testDriveState, date } }), false, "Save test drive date"),
        setMileageStart: (mileage: string) =>
            set((state) => ({ testDriveState: { ...state.testDriveState, mileageStart: mileage } }), false, "Save Test Drive Mileage Start"),
        setMileageEnd: (mileage: string) =>
            set((state) => ({ testDriveState: { ...state.testDriveState, mileageEnd: mileage } }), false, "Save Test Drive Mileage End"),
        setTimeStart: (time: string) =>
            set((state) => ({ testDriveState: { ...state.testDriveState, timeStart: time } }), false, "Save Test Drive Time Start"),
        setTimeEnd: (time: string) =>
            set((state) => ({ testDriveState: { ...state.testDriveState, timeEnd: time } }), false, "Save Test Drive Time End"),
        setTestDriveStatus: (status) =>
            set((state) => ({ testDriveState: { ...state.testDriveState, testDriveStatus: status } }), false, "Set test drive status"),
        reset: () => set(getInitialState(), false, "Reset Test Drive Slice"),
    },
})
