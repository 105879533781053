import { useHistory } from "react-router"
import { Advertisement, AdvertisementCategory, Article, channel, SupplierArticle } from "@tm/models"
import { encodeUniqueId, uniqueId } from "@tm/utils"
import { useInvalidateWorkTaskBasketQueries } from "../../../../../basket/src/data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

export type TraderArticle = {
    traderArticleNumber: string
    id: string
    isTraderArticle: true
}

export function mapAdvertisementToSupplierArticle(ads: Array<Advertisement>): Array<SupplierArticle> {
    const parts: Array<SupplierArticle> = []

    ads.forEach((ad) => {
        if (ad.dataSupplierId && ad.dataSupplierArticleNumber) {
            parts.push({
                supplierId: ad.dataSupplierId,
                supplierArticleNo: ad.dataSupplierArticleNumber,
            })
        }
    })

    return parts
}

export function getArticlesFromResponse(response: Record<string, Array<Article>>) {
    if (!response) {
        return
    }

    const parts: Array<Article | TraderArticle> = []

    Object.keys(response).forEach((traderArticleNo) => {
        if (response[traderArticleNo].length) {
            // only the first item should be displayed
            parts.push(response[traderArticleNo][0])
        } else {
            parts.push({
                traderArticleNumber: traderArticleNo,
                id: uniqueId(),
                isTraderArticle: true,
            })
        }
    })

    return parts
}

export function openPartsList(
    invalidateAllWorkTaskBasketRequests: ReturnType<typeof useInvalidateWorkTaskBasketQueries>["invalidateAllWorkTaskBasketRequests"],
    history: ReturnType<typeof useHistory>,
    workTaskId: string,
    advertisementCategory: AdvertisementCategory,
    basketUrl?: string
) {
    history.push(`/${encodeUniqueId(workTaskId)}`)

    channel("WORKTASK", workTaskId).subscribeOnce("WORKTASK/LOADED", () => {
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            offers: {
                advertisementCategory,
            },
        })

        if (basketUrl) {
            // Little hack for the TM3 update. We just need to execute this in the next render cycle,
            // because "WORKTASK/LOADED" gets executed just before the workTaskId gets set in the WorkTaskProvider.
            // TODO: Should be fixed correctly in the future.
            setTimeout(() => {
                invalidateAllWorkTaskBasketRequests(workTaskId)
            }, 0)

            channel("WORKTASK", workTaskId).publish("MODULE/OPENED", {
                icon: "cart",
                title: "{{291}}",
                isSelected: false,
                preventURLRewrite: true,
                url: basketUrl,
            })
        }

        channel("WORKTASK", workTaskId).publish("MODULE/CHANGED", {
            id: "parts",
            name: advertisementCategory.id,
            view: "{{1276}}",
            info: advertisementCategory.description,
        })
    })
}
