import { ActionDispatch } from "@tm/morpheus"
import { BundleActionType } from "../../../business"
import { OptionsState } from "./model"

export * from "./model"

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: OptionsState = {
    showArticleImages: true,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): OptionsState {
    switch (action.type) {
        case "SET_ARTICLE_LIST_SHOW_ARTICLE_IMAGES": {
            return {
                ...state,
                showArticleImages: action.payload,
            }
        }
        default:
            break
    }

    return state
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "SET_ARTICLE_LIST_SHOW_ARTICLE_IMAGES":
        case "TOGGLE_FILTERS_OPEN":
            return action
        default:
            break
    }
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "SET_ARTICLE_LIST_SHOW_ARTICLE_IMAGES":
            dispatch(action)
            break
        default:
            break
    }
}

function setArticleListShowArticleImages(showArticleImages: boolean): ComponentActionType {
    return { type: "SET_ARTICLE_LIST_SHOW_ARTICLE_IMAGES", payload: showArticleImages }
}

const toggleOpenedFilters = (active: boolean): ComponentActionType => {
    return {
        type: "TOGGLE_FILTERS_OPEN",
        payload: active,
    }
}

export type IActions = typeof Actions

export const Actions = {
    setArticleListShowArticleImages,
    toggleOpenedFilters,
}
