import { UserSettings } from "@tm/context-distribution"
import { WorkCategory } from "@tm/models"

export function getLabourRate(userSettings?: UserSettings): number | undefined {
    let hourlyRate

    if (userSettings?.hourlyRates) {
        hourlyRate = userSettings.hourlyRates.find((rate) => rate.category === WorkCategory.WorkshopWork)

        if (!hourlyRate) {
            hourlyRate = userSettings.hourlyRates.find((rate) => rate.category === undefined)
        }
    }

    return hourlyRate?.hourlyRate
}
