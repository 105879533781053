import { Box, Button, Icon, Toolbar, Tooltip, Typography } from "@tm/components"
import { ArticleInfoType, IMicros, VoucherVehicleInfo } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useMicro } from "@tm/morpheus"
import { useState } from "react"

type Props = {
    articleInfoType: ArticleInfoType
    foundBySearchTerm: string
    voucherVehicleInfo: VoucherVehicleInfo
    hideVehicleLogos: boolean | undefined
    onOpenArticleSearch(query: string): void
}

export default function ArticleTypeComponent(props: Props) {
    const { articleInfoType, foundBySearchTerm, voucherVehicleInfo, hideVehicleLogos, onOpenArticleSearch } = props
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const [openVehicleInfo, setOpenVehicleInfo] = useState<boolean>(false)

    function handleOpenArticleSearch() {
        onOpenArticleSearch(foundBySearchTerm)
    }

    function renderPartSearchLink() {
        return (
            <Toolbar title={translateText(241)}>
                <div className="vouchers-part-link-content">
                    <Typography modifiers="strong">
                        {translateText(775)} "{foundBySearchTerm}"
                    </Typography>
                    <Typography>{translateText(840)}</Typography>
                    <Button onClick={handleOpenArticleSearch}>{translateText(389)}</Button>
                </div>
            </Toolbar>
        )
    }

    if (articleInfoType === ArticleInfoType.OeArticle) {
        return (
            <Tooltip title={translateText(803)} variant="light">
                <Button startIcon={<Icon name="oe-reference" />} variant="text" />
            </Tooltip>
        )
    }
    if (articleInfoType === ArticleInfoType.CustomArticle) {
        return (
            <Tooltip title={translateText(757)} variant="light">
                <Button startIcon={<Icon name="individual-article" />} variant="text" />
            </Tooltip>
        )
    }

    if (articleInfoType === ArticleInfoType.TecdocArticle) {
        if (foundBySearchTerm) {
            return (
                <Tooltip title={renderPartSearchLink()} variant="light">
                    <Box padding="6px">
                        <Icon size="" name="articlesearch" />
                    </Box>
                </Tooltip>
            )
        }
        if (voucherVehicleInfo) {
            return (
                <Tooltip
                    title={<>{renderMicro("crm", "vehicle-info", { vehicleId: voucherVehicleInfo.id, hideLogos: hideVehicleLogos || false })}</>}
                    variant="light"
                    disableHoverListener
                    open={openVehicleInfo}
                    onClickAway={() => setOpenVehicleInfo(false)}
                >
                    <Button startIcon={<Icon name="vehicle-reference" />} variant="text" onClick={() => setOpenVehicleInfo(true)} />
                </Tooltip>
            )
        }
    }
    return null
}
