import { AsyncAction } from "@tm/morpheus"
import { clone } from "@tm/utils"
import { setField } from "../../../data/helpers"
import { IDOPModel, IFieldsItem } from "../../../data/models"
import { useContainer } from "../../../data/repositories"
import { DOPState } from "./model"

export type ComponentActionType =
    | { type: "DOP_DATA_LOADING" }
    | { type: "DOP_DATA_LOADED"; payload: IDOPModel }
    | { type: "DOP_DATA_ERORR"; payload: string }
    | { type: "UPDATE_FIELD"; payload: { id: string; value: IFieldsItem } }
    | { type: "RESET" }

const DEFAULT_STATE: DOPState = {
    // model: mockData
    model: {},
}

export function reduce(state = clone(DEFAULT_STATE), action: ComponentActionType): DOPState {
    switch (action.type) {
        case "DOP_DATA_LOADING": {
            return {
                ...state,
                loading: true,
                errrMessage: undefined,
            }
        }
        case "DOP_DATA_LOADED": {
            return {
                ...state,
                model: action.payload,
                loading: false,
            }
        }
        case "DOP_DATA_ERORR": {
            return {
                ...state,
                model: {
                    ...state.model,
                    plate: undefined,
                    articles: undefined,
                },
                errrMessage: action.payload,
                loading: false,
            }
        }
        case "RESET": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "UPDATE_FIELD": {
            const { id, value } = action.payload
            setField(state.model, id, value)

            return {
                ...state,
            }
        }
        default:
            break
    }
    return state
}

const loadData = (): AsyncAction<ComponentActionType, DOPState> => (dispatch, getState) => {
    const { model } = getState()
    dispatch({ type: "DOP_DATA_LOADING" })
    useContainer()
        .action("orderPlates")(model)
        .then(
            (res) => dispatch({ type: "DOP_DATA_LOADED", payload: res }),
            (_error) => dispatch({ type: "DOP_DATA_ERORR", payload: "ERROR" })
        )
}

const resetData = (): ComponentActionType => ({ type: "RESET" })

const updateField = (id: string, value: IFieldsItem): ComponentActionType => ({ type: "UPDATE_FIELD", payload: { id, value } })

export type IActions = typeof Actions

export const Actions = {
    loadData,
    updateField,
    resetData,
}
