import { lighten, styled } from "@tm/components"
import { StyledTextProps } from "./models"

const StyledText = styled("p")<StyledTextProps>(({ areaCode, selectedAreaCode, theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: areaCode === selectedAreaCode ? lighten(theme.palette.primary.main, 0.6) : "#F2F2F2",
    zIndex: 1,
    userSelect: "none",
    cursor: "pointer",
    transition: "all .2s ease",
    "&:hover": {
        backgroundColor: lighten(theme.palette.primary.main, 0.6),
    },
}))

const Container = styled("div")({
    overflow: "hidden",
    position: "relative",
    display: "flex",
    backgroundColor: "#fff",
})

const StyledImage = styled("img")({
    minWwidth: "100%",
    maxWidth: "100%",
    width: "auto",
    userSelect: "none",
})

export { Container, StyledText, StyledImage }
