import { Text, Icon, Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Compilation } from "../../../../data"
import { AddingState } from "../../component"

type Props = {
    compilation: Compilation
    state: AddingState | undefined
    onClick(compilationId: string): void
}

export default function CompilationItemWm(props: Props) {
    const { translateText } = useLocalization()

    const handleClick = () => {
        props.onClick(props.compilation.id)
    }

    let stateContent
    switch (props.state) {
        case "ADDING":
            stateContent = <Loader />
            break
        case "ERROR":
            stateContent = (
                <div title={translateText(787)}>
                    <Icon skin="danger" name="alert" />
                </div>
            )
            break
        case "ADDED":
            stateContent = (
                <div title={translateText(519)}>
                    <Icon skin="success" name="check" />
                </div>
            )
            break
    }

    const className = `compilations__item ${props.state === "ADDED" ? "isChecked" : ""}`

    return (
        <div className={className} onClick={handleClick}>
            <div className="state">{stateContent}</div>
            <Text title={props.compilation.description}>{props.compilation.name}</Text>
        </div>
    )
}
