import { MvcBundleParams } from "@tm/models"

export const version = {
    name: "mvc",
    version: "2.0",
}

let bundleParams: MvcBundleParams

/** Initial bundle parms */
export function initMvcBundle(params: MvcBundleParams) {
    bundleParams = params
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
