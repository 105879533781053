import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSetVehicleTecDocNumber(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { setVehicleTecDocNumber } = data

    if (setVehicleTecDocNumber && setVehicleTecDocNumber.tecDocNumber) {
        channel("WORKTASK").publish("VEHICLE/SET_VEHICLE_TECDOC_NUMBER", setVehicleTecDocNumber)
    }
}
