import { channel, RegistrationNoType, ShowCarModelDetailsRequest, Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { MainState } from "../components/main"
import { MainActions, MainActionsType } from "../components/main/business"
import { createDatCalculationRequest } from "../components/summary/business/helper"
import { DatSelectionSteps, ExternalToolSteps, getStatusByExternalTool } from "../data/enums"
import { getBundleParams } from "../utils"
import { useContainer } from "../data/repositories"

const updateFieldValue = (path: string, value: any): MainActionsType => ({ type: "UPDATE_FIELD", payload: { path, value } })

const handleUseNextHourlyRates = (value: boolean): MainActionsType => ({ type: "USE_NEXT_HOURLY_RATES", payload: value })

const initCustomer = (country: string): MainActionsType => ({ type: "INIT_CUSTOMER", payload: { country } })

const initVehicle =
    (countryCode: string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        const isGermanOrAustrianVehicle =
            countryCode.toLowerCase() === "d" ||
            countryCode.toLowerCase() === "de" ||
            countryCode.toLowerCase() === "at" ||
            countryCode.toLowerCase() === "a"
        dispatch({ type: "VEHICLE_HAS_KBA", payload: isGermanOrAustrianVehicle })
    }

const startCalculation =
    (translateText?: (key: string | number) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        if (!translateText) {
            return
        }

        const { summary } = getState()
        dispatch({ type: "TRIGGER_NEW_CALCULATION" })
        const request = createDatCalculationRequest(summary)
        const container = useContainer()

        container
            .action("getDATCalculationUrl")(request)
            .then(
                (response) => {
                    dispatch({
                        type: "INIT_NEW_CALCULATION",
                        payload: { sphinxHtml: response.sphinxHtml, contractId: response.contractId, errorMessage: response.errorMessage },
                    })

                    if (response.errorMessage) {
                        channel("APP").publish("TOAST_MESSAGE/SHOW", { message: response.errorMessage, closeDelay: 5000, skin: "danger" })
                        return
                    }

                    container
                        .action("handleCustomerBilling")()
                        .then((x) => x)

                    dispatch(
                        MainActions.changeStep(
                            DatSelectionSteps.EXTERN,
                            { contractId: response.contractId, status: getStatusByExternalTool(ExternalToolSteps.ContractOpening) },
                            true
                        )
                    )
                },
                () => {
                    dispatch({ type: "INIT_NEW_CALCULATION_ERROR" })
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12891), closeDelay: 5000, skin: "danger" })
                }
            )
    }

const validateVin =
    (
        attachToWorkTask?: (data: { vehicle: Vehicle }) => Promise<void>,
        translateText?: (key: string | number) => string
    ): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const {
            summary: { fields },
            manager: { vehicle },
        } = getState()
        const { useAttachToWorktask } = getBundleParams()

        if (!vehicle || (useAttachToWorktask && !attachToWorkTask) || !translateText) {
            return
        }

        dispatch({ type: "CHECK_VIN_LOADING", payload: true })

        useContainer()
            .action("datVehicleVinSearch")(fields.vin!)
            .then(
                (res) => {
                    if (useAttachToWorktask) {
                        attachToWorkTask!({
                            vehicle: {
                                ...vehicle,
                                vin: fields.vin,
                                initialRegistration: fields.initialRegistration,
                                registrationNo: fields.registrationNo,
                                plateId: fields.plateNumber,
                                mileAge: fields.mileage,
                                ...(vehicle.registrationTypeId !== RegistrationNoType.DatVin && {
                                    registrationTypeId: RegistrationNoType.DatVin,
                                }),
                            },
                        }).then(() => {
                            dispatch({ type: "CHECK_VIN", payload: res })
                        })
                    } else {
                        dispatch({ type: "CHECK_VIN", payload: res })
                    }
                },
                () => channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12496), closeDelay: 3000, skin: "danger" })
            )
    }

const checkVehicleDatInfo =
    (vehicle?: Vehicle, translateText?: (key: string | number) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        if (!vehicle || !translateText) {
            return
        }

        dispatch({ type: "SHOW_VEHICLE_MODELS_LOADING", payload: true })

        const request: ShowCarModelDetailsRequest = {
            modelId: vehicle.tecDocTypeId,
            forceUpdateRegistrationNoDetails: false,
            registrationNo: vehicle.vin,
            registrationNoTypeId: RegistrationNoType.DatVin,
            modelIdentifier: {
                plateId: vehicle.plateId,
                vin: vehicle.vin,
                initialRegistration: vehicle.initialRegistration,
            },
        }

        useContainer()
            .action("showModelDetails")(request)
            .then(
                (res) => {
                    dispatch({ type: "VEHICLE_HAS_DAT_INFO", payload: !!res.modelDetails?.registrationNoDetails })
                },
                () => {
                    dispatch({ type: "VEHICLE_HAS_DAT_INFO", payload: false })
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12589), closeDelay: 3000, skin: "danger" })
                }
            )
    }

export const SummaryActions = {
    startCalculation,
    updateFieldValue,
    handleUseNextHourlyRates,
    initCustomer,
    initVehicle,
    validateVin,
    checkVehicleDatInfo,
}
