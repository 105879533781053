import { useEffect, useState } from "react"
import { ajax } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { useChatAuthorization, ChatInfo, Message } from "./index"

export function useLastMessages(count: number) {
    const authorization = useChatAuthorization()
    const [state, setState] = useState<{ loading: boolean; chats?: Array<ChatInfo> }>({ loading: true })

    useEffect(() => {
        if (!authorization) {
            return
        }

        setState({ loading: true })

        let cancelled = false
        const url = `${getBundleParams().chatApiUrl}/api/v1/user/chats/getlastforeignactivechats/${count}`
        ajax({ url, method: "GET", authorization }).then((chats?: Array<ChatInfo>) => {
            if (cancelled) {
                return
            }

            Promise.all(
                chats?.map((chat) => {
                    const getLastMessagesUrl = `${getBundleParams().chatApiUrl}/api/v1/user/chats/messages/getlast/${chat.chatId}/${1}`
                    return ajax({ url: getLastMessagesUrl, method: "GET", authorization }) as Promise<Array<Message>>
                }) || []
            ).then(
                (lastMessages) => {
                    if (cancelled) {
                        return
                    }

                    if (chats) {
                        lastMessages.forEach((messages, index) => {
                            chats[index].lastMessage = messages?.[0] // Mutate object in response. Shouldn't be a problem at this point.
                        })
                    }
                    setState({ loading: false, chats })
                },
                () => {
                    setState({ loading: false, chats })
                }
            )
        })

        return () => {
            cancelled = true
        }
    }, [authorization, count])

    return state
}
