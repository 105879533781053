import { Vehicle, Customer, getWorkTaskId, channel } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { ServiceEvent, EventType } from "./data"

export type BundleActionType =
    | { type: "EVENT_LOADED"; payload: ServiceEvent }
    | { type: "EVENT_CREATED"; payload: ServiceEvent }
    | { type: "TYPES_LOADING" }
    | { type: "TYPES_LOADED"; payload: Array<EventType> }
    | { type: "VEHICLE_SET"; payload?: Vehicle }
    | { type: "CUSTOMER_SET"; payload?: Customer }

export type BundleState = {
    customer?: Customer
    vehicle?: Vehicle
}

const DEFAULT_STATE = {}

export function bundleReduce(state = DEFAULT_STATE, action: BundleActionType): BundleState {
    switch (action.type) {
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "CUSTOMER_SET": {
            return {
                ...state,
                customer: action.payload,
            }
        }
        default:
            break
    }
    return state
}

function init(): AsyncAction<BundleActionType> {
    return (dispatch) => {
        // subscribe to worktask channel and get the loaded vehicle and customer
        const unsubs: Array<() => void> = []
        let hasUnsubscribed = false
        getWorkTaskId((workTaskId) => {
            if (hasUnsubscribed) {
                return
            }

            unsubs.push(
                channel("WORKTASK", workTaskId).subscribe(
                    "CUSTOMER/LOADED",
                    (customer) => dispatch({ type: "CUSTOMER_SET", payload: customer }),
                    true
                )
            )
            unsubs.push(
                channel("WORKTASK", workTaskId).subscribe("VEHICLE/LOADED", (vehicle) => dispatch({ type: "VEHICLE_SET", payload: vehicle }), true)
            )
        })
        return function unsubscribeChannels() {
            unsubs.forEach((unsub) => unsub())
            unsubs.splice(0)
            hasUnsubscribed = true
        }
    }
}

export type IBundleActions = {
    init(...args: Parameters<typeof init>): () => void
}

export const BundleActions = {
    init,
}
