import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { useQuery } from "react-query"
import { findInvoices } from "../repositories/cis/invoices"

const KEY = "cis_useFindInvoicesSingle"
export function useFindInvoicesSingle(enabledExternal = true) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const enabled = enabledExternal && enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => findInvoices({ customerNo: telesalesCustomerNo, pageIndex: 1, pageSize: 1 }),
        { enabled }
    )

    return { invoices: data, invoicesLoading: isLoading }
}
