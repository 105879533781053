import { channel, ErpSystemConfig } from "@tm/models"
import { useCallback, useMemo, useRef, useState } from "react"
import { useUser } from "@tm/context-distribution"
import { notUndefinedOrNull, useDefaultErpSystem } from "@tm/utils"
import { useQueryClient } from "react-query"
import { useRecoilValue } from "recoil"
import { isEqual } from "lodash"
import * as Data from "../../../../data"
import { SendCentralOrderRequest } from "../../../../data/model"
import { useCentralOrderWorkTasks } from "../../../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import { ORDER_OPTIONS_KEY } from "../../../../data/hooks/useOrderOptions"
import { mapOrderOptionsToSelectedOrderOptions } from "../../../../data/mapper"
import { bundleChannel } from "../../../../business"
import { createSendCentralOrderRequest, mapDistributorGroups, openOrderSentUrl } from "./helper"
import { useGlobalOrderOptionsState } from "../../../../hooks/useGlobalOrderOptionsState"
import { basketOrderGroups } from "../../../../hooks/basketState/useCentralOrderBasketStateByWorkTask"
import { useInvalidateWorkTaskBasketQueries } from "../../../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"
import { BasketOrderGroup } from "../../../../models"

export function useSendCentralOrder(telesalesCustomerNo: string | undefined) {
    const {
        erpConfig: { erpSystemConfigs },
        erpSystemConfig: defaultErpSystemConfig,
    } = useDefaultErpSystem()
    const { userSettings } = useUser()
    const { includedWorkTaskIds } = useCentralOrderWorkTasks().state
    const { centralOrderParameters, globalOrderOptions, globalOrderOptionsEnabled } = useGlobalOrderOptionsState()
    const allBasketOrderGroups = useRecoilValue(basketOrderGroups)

    // Only return new Array when the values in the array have changed
    const includedBasketOrderGroupsRef = useRef<BasketOrderGroup[]>([])
    const includedBasketOrderGroups = useMemo(() => {
        const newVal = includedWorkTaskIds.flatMap((id) => allBasketOrderGroups[id]).filter(notUndefinedOrNull)
        if (!isEqual(newVal, includedBasketOrderGroupsRef.current)) {
            includedBasketOrderGroupsRef.current = newVal
        }
        return includedBasketOrderGroupsRef.current
    }, [allBasketOrderGroups, includedWorkTaskIds])

    const queryClient = useQueryClient()
    const { invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()

    const wholesalerOrderConfirmation = userSettings?.orderOptions.repairShopResponse?.wholesalerOrderConfirmation || false
    const confirmationEmail = userSettings?.orderOptions.repairShopResponse?.orderConfirmationEmail

    const selectedGlobalOrderOptions =
        globalOrderOptionsEnabled && globalOrderOptions ? mapOrderOptionsToSelectedOrderOptions(globalOrderOptions) : undefined

    const sendCentralOrder = useCallback(
        (extSys?: ErpSystemConfig) => {
            const requests: Array<{ request: SendCentralOrderRequest; erpSystemConfig: ErpSystemConfig }> = []

            if (!extSys) {
                includedWorkTaskIds.forEach((workTaskId) => {
                    const orderGroups = allBasketOrderGroups[workTaskId]
                    const orderGroupsByDistributor = orderGroups
                        .filter((group) => group.hasIncludedPartsOrderable)
                        .groupBy((group) => group.orderGroup.distributorId || defaultErpSystemConfig?.id || "")

                    Object.values(orderGroupsByDistributor).forEach((distriGroups) => {
                        const { orderGroup, completeDeliveryTourStart } = distriGroups[0]
                        const { distributorId } = orderGroup

                        const config = erpSystemConfigs?.find((c) => c.id === distributorId) ?? defaultErpSystemConfig
                        if (config) {
                            channel("WORKTASK", workTaskId).publish("BASKET/ORDER_SENDING", {})
                            const { bonusPointTotals, orderOptions, itemErpInformation } = mapDistributorGroups(distriGroups)
                            requests.push({
                                request: createSendCentralOrderRequest(
                                    [workTaskId],
                                    orderOptions,
                                    itemErpInformation,
                                    wholesalerOrderConfirmation,
                                    telesalesCustomerNo,
                                    config.id,
                                    distributorId,
                                    confirmationEmail,
                                    completeDeliveryTourStart,
                                    bonusPointTotals,
                                    centralOrderParameters.useOrderCollection,
                                    selectedGlobalOrderOptions,
                                    userSettings?.itemSorting
                                ),
                                erpSystemConfig: config,
                            })
                        }
                    })
                })
            } else {
                const orderGroups = includedWorkTaskIds.flatMap((id) => allBasketOrderGroups[id]).filter((group) => group.hasIncludedPartsOrderable)
                includedWorkTaskIds.forEach((workTaskId) => channel("WORKTASK", workTaskId).publish("BASKET/ORDER_SENDING", {}))
                const { bonusPointTotals, orderOptions, itemErpInformation } = mapDistributorGroups(orderGroups)

                requests.push({
                    request: createSendCentralOrderRequest(
                        includedWorkTaskIds,
                        orderOptions,
                        itemErpInformation,
                        wholesalerOrderConfirmation,
                        telesalesCustomerNo,
                        extSys.id,
                        undefined,
                        confirmationEmail,
                        orderGroups[0].completeDeliveryTourStart,
                        bonusPointTotals,
                        centralOrderParameters.useOrderCollection,
                        selectedGlobalOrderOptions,
                        userSettings?.itemSorting
                    ),
                    erpSystemConfig: extSys,
                })
            }

            if (!requests.length) {
                return
            }

            bundleChannel().publish("ANY_ORDERS_SENDING", {})

            Promise.all(
                requests.map(({ request, erpSystemConfig }) =>
                    Data.sendCentralOrder(request).then(
                        (response) => {
                            response?.forEach((result) => {
                                channel("WORKTASK", result.workTaskId).publish("BASKET/ORDER_SENT", {
                                    result,
                                    erpSystemConfig,
                                    isPartialOrder: false,
                                })
                                invalidatePartsRequests(result.workTaskId)
                                queryClient.invalidateQueries([ORDER_OPTIONS_KEY, result.workTaskId, request.telesalesCustomerNo])
                                if (result.allHaveSucceeded) {
                                    openOrderSentUrl(result.workTaskId)
                                }
                            })
                        },
                        (error) => {
                            request.workTaskOrders.forEach(({ workTaskId }) => {
                                channel("WORKTASK", workTaskId).publish("BASKET/ORDER_SENT_ERROR", {
                                    technicalErrorText: error.message,
                                    erpSystemConfig,
                                })
                                invalidatePartsRequests(workTaskId)
                            })
                        }
                    )
                )
            ).finally(() => bundleChannel().publish("ALL_ORDERS_SENT", {}))
        },
        [
            allBasketOrderGroups,
            centralOrderParameters.useOrderCollection,
            confirmationEmail,
            defaultErpSystemConfig,
            erpSystemConfigs,
            includedWorkTaskIds,
            invalidatePartsRequests,
            queryClient,
            selectedGlobalOrderOptions,
            telesalesCustomerNo,
            userSettings?.itemSorting,
            wholesalerOrderConfirmation,
        ]
    )

    return { includedBasketOrderGroups, sendCentralOrder }
}
