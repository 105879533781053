import { useLocalization } from "@tm/localization"
import { Warehouse } from "@tm/models"
import { TableCellData, TableColumnData, Table } from "@tm/components"

type Props = {
    warehouses: Warehouse[]
    transparentBackground?: boolean
    transparentHighlight?: boolean
}

export default function WarehouseQuantitiesTable({ warehouses, transparentBackground, transparentHighlight }: Props) {
    const { translateText } = useLocalization()

    function getCells(warehouse: Warehouse): TableCellData[] {
        return [
            { displayValue: warehouse?.quantities?.first()?.value, id: "warehouse_1" },
            { displayValue: warehouse?.name, id: "warehouse_2" },
        ]
    }

    function getColumns(): TableColumnData[] {
        return [{ header: translateText(89) }, { header: translateText(144) }]
    }

    const displayData = warehouses.map((warehouse, index) => ({
        cells: getCells(warehouse),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (warehouses.every((warehouse) => warehouse.quantities?.every((quantity) => !quantity.value))) {
        return null
    }

    return (
        <Table
            variant="small"
            columns={getColumns()}
            rows={displayData}
            overflowY="auto"
            rowCap={0}
            headerBackground="transparent"
            rowStyle={transparentBackground ? "transparent" : "stripped"}
            transparentHighlight={transparentHighlight}
        />
    )
}
