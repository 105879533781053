import { useLocalization } from "@tm/localization"
import { Omit } from "@tm/utils"
import { SeparatorList, Tag, TagIcon, TagLayout, TagSkin, Icon, Button, Text } from "@tm/controls"
import { ArticleAttributes, ArticleAttribute, ModificationState } from "@tm/models"

type Props = {
    isCompact?: boolean
    attributes: Array<ArticleAttributes>
    canSelect: boolean
    selected: Array<string>
    vehicleEngineCode?: string
    ignoreAttributeKey: boolean
    onSelect(attribute: ArticleAttribute): void
    toggleMoreAttributes(): void
}

type MultipleValueAttribute = Omit<ArticleAttribute, keyof AttributeValue> & {
    values: Array<ArticleAttribute>
}

type AttributeValue = {
    key: string
    value: string
    unit: string
    modificationState: ModificationState
    highlight: boolean
}

export default function ArticleAttributesComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const { selected, ignoreAttributeKey, canSelect, vehicleEngineCode, isCompact, attributes } = props

    function isSelected(attribute: MultipleValueAttribute, attributeValue: ArticleAttribute) {
        return selected.some((x) => {
            const [id, key] = x.split("|")
            return (
                attribute.id.toString() == id && (!ignoreAttributeKey && attributeValue.key ? attributeValue.key == key : attributeValue.value == key)
            )
        })
    }

    function handleAttributeSelect(attribute: ArticleAttribute) {
        props.onSelect(attribute)
    }

    function renderAttributeValue(attribute: MultipleValueAttribute, attributeValue: ArticleAttribute, showLabel = true) {
        const selected = isSelected(attribute, attributeValue)

        const icons: Array<TagIcon> = []

        let className = "article__attribute"

        switch (attributeValue.modificationState) {
            case ModificationState.Added:
                className += " article__attribute--added"
                icons.push({ name: "plus", tooltip: translateText(883) })
                break
            case ModificationState.Removed:
                className += " article__attribute--removed"
                icons.push({ name: "minus", tooltip: translateText(884) })
                break
        }

        let layout: TagLayout | undefined
        let skin: TagSkin | undefined
        let tooltip: string | undefined

        if (!selected) {
            layout = "ghost"
        } else {
            skin = "primary"
        }

        // If the attribute is engine code and the current engine code matches the value of the attribute ...
        if (attribute.id == 33 && vehicleEngineCode && vehicleEngineCode == attributeValue.value) {
            attributeValue.highlight = true // .. highlight the attribute
            tooltip = translateText(893)
        }

        if (attributeValue.highlight) {
            layout = !selected ? "holo" : undefined
            skin = "highlight"
        }

        const value = `${attributeValue.value} ${attributeValue.unit || ""}`.trim()

        return (
            <Tag
                key={value}
                className={className}
                layout={layout}
                skin={skin}
                label={showLabel ? attribute.abbreviation : undefined}
                value={value}
                onClick={canSelect && !!attributeValue.value ? () => handleAttributeSelect(attributeValue) : undefined}
                icons={icons}
                tooltip={tooltip}
            />
        )
    }

    function renderAttribute(attribute: MultipleValueAttribute) {
        if (attribute.id == -1) {
            return (
                <Button className="more-btn" onClick={props.toggleMoreAttributes} layout={["ghost"]} size="xs">
                    {translate(isCompact ? 44 : 1208)}
                </Button>
            )
        }

        if (attribute.values.length == 1) {
            return renderAttributeValue(attribute, attribute.values[0])
        }

        return (
            <>
                <Tag key={attribute.abbreviation} className="article__attribute" layout="ghost" label={attribute.abbreviation} />
                {attribute.values.map((x) => renderAttributeValue(attribute, x, false))}
            </>
        )
    }

    function renderAttributes(attributes: Array<ArticleAttribute>, className: string, showMore?: boolean) {
        if (!attributes.length) {
            return
        }

        const items = mapAttributes(attributes, vehicleEngineCode)

        if (showMore) {
            items.push({ id: -1 } as any)
        }

        return <SeparatorList className={`article__attributes ${className}`} items={items} renderItem={renderAttribute} />
    }

    function renderAttributeGroup(attributes: ArticleAttributes, idx: number) {
        const minorAttributes = attributes.vehicleAttributes.concat(attributes.articleAttributes)

        return (
            <div className="article__attribute-group" key={idx}>
                <div className="article__attribute-group__description">
                    <Text size="s" title={`${idx + 1}. ${translateText(610)}`}>
                        {idx + 1}. <Icon name="car" />
                    </Text>
                </div>
                <div className="article__attribute-group__attributes">
                    {renderAttributes(attributes.topAttributes, "article__attributes--major", true)}
                    {renderAttributes(minorAttributes, "article__attributes--minor")}
                </div>
            </div>
        )
    }

    if (!attributes.length) {
        return null
    }

    if (attributes.length == 1) {
        const minorAttributes = attributes[0].vehicleAttributes.concat(attributes[0].articleAttributes)

        return (
            <>
                {renderAttributes(attributes[0].topAttributes, "article__attributes--major", true)}
                {renderAttributes(minorAttributes, "article__attributes--minor")}
            </>
        )
    }

    return <>{attributes.map(renderAttributeGroup)}</>
}

function mapAttributes(attributes: Array<ArticleAttribute>, vehicleEngineCode?: string): Array<MultipleValueAttribute> {
    const mapped: Array<MultipleValueAttribute> = []

    attributes.forEach((x) => {
        let found = mapped.find(
            (y) =>
                y.id == x.id && // check if the attribute is already existing
                !!x.abbreviation // don't combine attributes without an abbrevation/label
            // && !x.highlight // don't combine highlighted attributes because highlighting is currently not possible only for specific values
            // && (y.id != 33 // don't combine the active engine code because of the highlighting
            //     || !vehicleEngineCode
            //     || (!y.values.some(z => z.value == vehicleEngineCode) && x.value != vehicleEngineCode))
        )

        if (!found) {
            found = {
                abbreviation: x.abbreviation,
                description: x.description,
                id: x.id,
                isBlockSeperator: x.isBlockSeperator,
                isDuplicatedAttribute: x.isDuplicatedAttribute,
                sortNo: x.sortNo,
                text: x.text,
                values: [],
                isOptimized: x.isOptimized,
            }

            mapped.push(found)
        }

        found.values.push(x)
    })

    return mapped
}
