import { Button, Icon, Box, styled, Typography, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { renderRoute } from "@tm/utils"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"
import { useEffect, useState } from "react"
import { DatSelectionSteps, ExternalToolSteps, getExternalToolStepByStatus, getStatusByExternalTool } from "../../data/enums"
import { getPageByDatMessage } from "../../data/helpers"
import { checkIfStatusIsNewer } from "../../data/helpers/checkIfStatusIsNewer"
import { ExternalToolMessage } from "../../data/model"
import { externSelector, managerSelector } from "../../selectors"
import { Wrapper } from "../../styles"
import { MainActions } from "../main/business"
import { CustomIFrame } from "../_shared"

type Props = {
    contractId: string
    status: string
}

export default function ExternComponent() {
    const history = useHistory()
    const match = useRouteMatch<Props>()
    const { translateText } = useLocalization()
    const { contractId } = useSelector(managerSelector)
    const { externalContent, getDocumentError } = useSelector(externSelector)
    const [reloadIframe, setReloadIframe] = useState<boolean>(false)
    const actions = useActions(MainActions, "changeStep", "loadDocumentByContactId", "updateExternPage", "finishCalculationProcess")

    useEffect(() => {
        if (!externalContent) {
            actions.loadDocumentByContactId(match.params.contractId, getExternalToolStepByStatus(match.params.status))
            setReloadIframe(true)
        }
    }, [])

    useEffect(() => {
        const subscription = channel("GLOBAL").subscribe("DMG_DAT/EXTERNAL_TOOL_DONE", (msg) => {
            const data = msg as ExternalToolMessage

            if (data.type === "RecalcReadyEvent") {
                const newPage = ExternalToolSteps.Calculation
                const newStatus = getStatusByExternalTool(newPage)

                actions.updateExternPage(newPage)

                history.replace(renderRoute(match.path, { ...match.params, status: newStatus }))
            }

            if (data.type === "pageLoaded") {
                const newPage = getPageByDatMessage(data)

                if (!newPage) {
                    return
                }

                const newStatus = getStatusByExternalTool(newPage)

                if (checkIfStatusIsNewer(newStatus, match.params.status)) {
                    actions.updateExternPage(newPage)

                    history.replace(renderRoute(match.path, { ...match.params, status: newStatus }))
                }
            }

            if (data.type === "relocate") {
                actions.finishCalculationProcess()
                actions.changeStep(DatSelectionSteps.CALCULATION, { contractId })
            }
        })

        return subscription
    }, [contractId])

    if (getDocumentError) {
        return (
            <Wrapper centeredContent>
                <Box display="flex" alignItems="center">
                    <Typography fontWeight={600}>{translateText(12888)}</Typography>
                    <Button
                        onClick={() => actions.loadDocumentByContactId(match.params.contractId, getExternalToolStepByStatus(match.params.status))}
                        color="error"
                        size="large"
                        startIcon={<Icon name="synchronize" />}
                    >
                        {translateText(401)}
                    </Button>
                </Box>
            </Wrapper>
        )
    }

    return (
        <Wrapper sx={{ width: "100%", height: "100%" }} centeredContent={!externalContent}>
            {externalContent ? (
                <StyledCustomIframe id="dat-external" srcDoc={externalContent} reloadIframe={reloadIframe} />
            ) : (
                <Loader size="small" />
            )}
        </Wrapper>
    )
}

const StyledCustomIframe = styled(CustomIFrame)(() => ({
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
}))
