import { getStoredAuthorization, ajax } from "@tm/utils"
import { getCisServiceUrl } from "../.."
import {
    ShowBackordersResponse,
    ShowBackordersCountResponse,
    DeleteBackorderRequest,
    DeleteBackorderResponse,
    ShowBackordersRequest,
} from "../../model"

function getServiceUrl() {
    return `${getCisServiceUrl()}/Backorders`
}

export function deleteBackorder(body: DeleteBackorderRequest) {
    const url = `${getServiceUrl()}/DeleteBackorder`
    const authorization = getStoredAuthorization()

    return ajax<DeleteBackorderResponse>({ url, body, authorization, method: "POST" })
}

export async function showBackorders(body: ShowBackordersRequest) {
    const url = `${getServiceUrl()}/ShowBackorders`
    const authorization = getStoredAuthorization()

    return ajax<ShowBackordersResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showBackordersCount(body: { customerNo?: string }) {
    const url = `${getServiceUrl()}/ShowBackordersCount`
    const authorization = getStoredAuthorization()

    return ajax<ShowBackordersCountResponse>({ url, body, authorization })
}
