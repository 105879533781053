import { getStoredAuthorization, ajax } from "@tm/utils"
import {
    CompareWithVehicleRecordsRequest,
    CompareWithVehicleRecordsResponse,
    ShowVehicleRecordsRequest,
    ShowVehicleRecordsResponse,
    DeclineVehicleRecordRequest,
    ClearAllRecordsRequest,
    ShowFilterOptionsRequest,
    ShowFilterOptionsResponse,
    RegisteredModels,
    VehicleRecordArticleAddedToBasketEventRequest,
    VehicleRecordSingleEventRequest,
    UserModuleType,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { mapArticleComparison } from "./mapper"
import { getBundleParams } from "../utils"

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.vehicleRecordsServiceUrl
}

/**
 * Returns true when the vehicleRecords mode is set to "DAT VIN only".
 * That means, only the DAT VIN request which is made from the service is allowed to write vehicle records.
 * NEXT-18762
 * @returns boolean
 */
export function denyWriteVehicleRecords() {
    return (
        window.userContext.modules?.find((x) => x.type === UserModuleType.VehicleRecords)?.parameters?.find((x) => x.key === "VehicleRecordMode")
            ?.value === "241002"
    )
}

export function showVehicleRecords(body: ShowVehicleRecordsRequest) {
    const url = `${getServiceUrl()}/Vehicle/ShowVehicleRecords`
    const authorization = getStoredAuthorization()

    return ajax<ShowVehicleRecordsResponse>({ url, body, authorization, method: "GET" })
}

export function loadFilterOptions(body: ShowFilterOptionsRequest) {
    const url = `${getServiceUrl()}/Vehicle/ShowFilterOptions`
    const authorization = getStoredAuthorization()

    return ajax<ShowFilterOptionsResponse>({ url, body, authorization, method: "GET" })
}

export function declineVehicleRecord(body: DeclineVehicleRecordRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Vehicle/DeclineVehicleRecord`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function compareWithVehicleRecords(body: CompareWithVehicleRecordsRequest) {
    const url = `${getServiceUrl()}/Vehicle/CompareWithVehicleRecords`
    const authorization = getStoredAuthorization()

    return ajax<CompareWithVehicleRecordsResponse>({ url, body, authorization, method: "POST" }).then(
        (response) => response && mapArticleComparison(response)
    )
}

export function clearAllRecords(body: ClearAllRecordsRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Vehicle/ClearAllRecords`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function articleAddedToBasket(body: VehicleRecordArticleAddedToBasketEventRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Events/ArticleAddedToBasket`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function equipmentModified(body: VehicleRecordSingleEventRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Events/EquipmentModified`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function quickFilterApplied(body: VehicleRecordSingleEventRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Events/QuickFilterApplied`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function inlineFilterApplied(body: VehicleRecordSingleEventRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Events/InlineFilterApplied`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function tyreFilterApplied(body: VehicleRecordSingleEventRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Events/TyreFilterApplied`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function wheelsFilterApplied(body: VehicleRecordSingleEventRequest) {
    if (denyWriteVehicleRecords()) {
        return Promise.resolve()
    }

    const url = `${getServiceUrl()}/Events/WheelsFilterApplied`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function registerActions() {
    Container.register({
        name: RegisteredModels.VehicleRecords,
        modelActions: {
            load: showVehicleRecords,
        },
        containerActions: {
            showVehicleRecords,
            declineVehicleRecord,
            compareWithVehicleRecords,
            clearAllRecords,
            articleAddedToBasket,
            equipmentModified,
            quickFilterApplied,
            inlineFilterApplied,
            tyreFilterApplied,
            wheelsFilterApplied,
            loadFilterOptions,
        },
    })
}
