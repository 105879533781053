import { Loader } from "@tm/controls"
import { CarManufacturer, VehicleType } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { useEffect, Suspense } from "react"

import { ArticleReferencesState } from "../_shared/article-references-business"
import ManufacturerListComponent from "../_shared/ManufacturerList"
import { useManufacturersByArticle } from "../../data/hooks"

type Props = RouteComponentProps<{ vehicleType: string }> & {
    state: ArticleReferencesState
    routes: {
        modelSeries: string
    }
}

function Wrapper(props: Props) {
    if (!props.state.internalArticleId) {
        return null
    }

    return (
        <div className="tk-vehicle vehicle-manufacturers">
            <Suspense fallback={<Loader />}>
                <ArticleReferencesManufacturersComponent {...props} articleId={props.state.internalArticleId} />
            </Suspense>
        </div>
    )
}

function ArticleReferencesManufacturersComponent(props: Props & { articleId: number }) {
    const vehicleType: VehicleType = parseInt(props.match.params.vehicleType)
    const { primaryManufacturers = [], secondaryManufacturers = [] } =
        useManufacturersByArticle({ vehicleType, articleId: props.articleId, selectedFilters: {} }) ?? {}

    useEffect(() => {
        const manufacturers = primaryManufacturers.length ? primaryManufacturers : secondaryManufacturers
        if (primaryManufacturers.length + secondaryManufacturers.length === 1) {
            const url =
                renderRoute(props.routes.modelSeries, { ...props.match.params, manufacturerId: manufacturers[0].id }) + (props.location.search || "")
            props.history.replace(url)
        }
    }, [primaryManufacturers, secondaryManufacturers])

    const handleSelect = (manufacturer: CarManufacturer) => {
        const url = renderRoute(props.routes.modelSeries, { ...props.match.params, manufacturerId: manufacturer.id }) + (props.location.search || "")
        props.history.push(url)
    }

    if (!primaryManufacturers.length && !secondaryManufacturers.length) {
        return null
    }

    return (
        <ManufacturerListComponent
            primaryManufacturers={primaryManufacturers}
            secondaryManufacturers={secondaryManufacturers}
            onSelect={handleSelect}
        />
    )
}

export default connectComponent({}, withRouter(Wrapper))
