import { useEffect, useMemo, useState } from "react"
import { Button, MessageToast, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { renderRoute } from "@tm/utils"
import { useIdStorageByWorkTask, useSendChatMessage } from "../../../../data/hooks"
import { getBundleParams } from "../../../../utils"
import { getComponentStyles } from "./styles"

export function FastCheckReceiver() {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()
    const chatStorage = useIdStorageByWorkTask()
    const send = useSendChatMessage()
    const [message, setMessage] = useState<{ success: true; chatId: string } | { success: false }>()

    useEffect(() => {
        if (message) {
            setTimeout(() => {
                setMessage(undefined)
            }, 5000)
        }
    }, [message])

    useEffect(() => {
        if (!chatStorage || !chatStorage.vehicle) {
            return
        }

        const { vehicle } = chatStorage
        return channel("WORKTASK").subscribe("NOTIFICATIONS/SEND_FAST_CHECK_CHAT_MESSAGE", (data) => {
            send({
                chatId: chatStorage.chatId,
                message: translateText(3036),
                attachment: {
                    file: data.pdfBlob,
                    mimeType: "application/pdf",
                    metaData: {
                        type: "attachment",
                        name: "FastCheck.pdf",
                        category: "AcceptanceProtocol",
                        externalVehicleId: vehicle.seatbeltVehicleId,
                    },
                },
            }).then(
                () => {
                    setMessage({ success: true, chatId: chatStorage.chatId })
                },
                () => setMessage({ success: false })
            )
        })
    }, [chatStorage?.chatId, chatStorage?.vehicle?.seatbeltVehicleId])

    if (message) {
        return (
            <MessageToast
                skin={message.success ? "success" : "warning"}
                message={
                    message.success ? (
                        <>
                            <Text modifiers="block">{translateText(12732)}</Text>
                            <Button
                                onClick={() => setMessage(undefined)}
                                size="s"
                                icon="chat"
                                layout={["holo"]}
                                className={classNames.detailsButton}
                                linkTo={renderRoute(getBundleParams().chatDetailsRoute, { chatId: message.chatId })}
                            >
                                {translateText(3104)}
                            </Button>
                        </>
                    ) : (
                        translateText(1706)
                    )
                }
                icon={message.success ? "success-dark" : "warning-dark"}
                onButtonClick={() => setMessage(undefined)}
            />
        )
    }

    return null
}
