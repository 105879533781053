import { margin, percent, rem } from "csx"
import { useEffect, useMemo } from "react"
import { useSelector, useStore } from "react-redux"
import { getStyleTheme, useCisCustomer, useStyle, useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { Loader, MessageInline, PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FuelType, IMicros, channel, getCurrentWorkTaskId } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { RouteComponentProps, decodeUniqueId, initSelector, withRouter } from "@tm/utils"
import { ExternalDocument } from "../../data/model"
import { Actions } from "./bussiness"
import { VehicleSelectionDocuments } from "./bussiness/contants"
import { createDocumentRequest, generateAvailableTabs, generateExternalSystemNameList, isVehicleChanged } from "./bussiness/helpers"
import { vehicleSelector } from "./bussiness/selectors"
import { DocumentItemBtn, DocumentModal, VehicleInfoModal } from "./components/common"
import { EDocumentIds } from "./bussiness/model"

type Props = IMicros["parts"]["external-system-documents"] & RouteComponentProps<{ workTaskId: string }> & {}

const selector = initSelector(
    vehicleSelector,
    (s) => s.articleDetailsResponse,
    (s) => s.model,
    (s) => s.mainDocument,
    (s) => s.loading,
    (s) => s.showModal,
    (s) => s.showVehicleModal
)

function DocumentsComponent(props: Props) {
    const {
        loadAllExternalSystemDocuments,
        match: { params },
    } = props
    const { translateText } = useLocalization()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()
    const style = useMemo(() => getStyle(), [])

    const user = useUser()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const store = useStore()
    const actions = useActions(Actions)
    let [vehicle, articleDetailsResponse, model, selectedDocument, loading, showModal, showVehicleModal] = useSelector(selector)

    const fallBackItems: FuelType[] = useMemo(
        () => [
            { id: 1, value: translateText(12900) },
            { id: 2, value: translateText(12901) },
            { id: 3, value: translateText(12902) },
            { id: 6, value: translateText(12903) },
            { id: 11, value: translateText(12904) },
            { id: 13, value: translateText(12905) },
            { id: 14, value: translateText(12906) },
        ],
        []
    )

    useEffect(() => {
        if (user?.userContext) {
            actions.getRelatedDocuments(user.userContext)
        }
        actions.getAllVehicleFuelTypes(fallBackItems)
    }, [user?.userContext])

    useEffect(() => {
        const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(params.workTaskId ?? "")

        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    actions.setVehicle(newVeh)
                    actions.loadCarModelDetails(newVeh.tecDocTypeId, newVeh.vehicleType)
                }
            },
            true
        )

        return unsubVehicle
    }, [])

    useEffect(() => {
        if (props.loadAllExternalSystemDocuments) {
            actions.getExternalSystemDocuments()
        }
    }, [])

    const openExternalDocument = (item: ExternalDocument, keepVehicleInfo?: boolean) => {
        if (cisCustomerLoading) {
            return null
        }
        const { licensorData, hideCustomerNr } = props

        model = createDocumentRequest(
            store.getState(),
            user?.userSettings?.repairShop,
            licensorData,
            hideCustomerNr,
            cisCustomer,
            user?.userContext,
            telesalesCustomerNo
        )

        const documents = generateExternalSystemNameList(item.relatedExternalSystemId, articleDetailsResponse?.externalDocuments ?? [], [item])
        const tabs = generateAvailableTabs(documents, translateText)

        actions.openExternalDocument(model!, tabs, documents, keepVehicleInfo) // OPEN DIALOG
    }

    const handleOpenDocument = (item: ExternalDocument) => {
        actions.setSelectedDocument(item)

        if (item.documentTypeId === EDocumentIds.GVAGuide && item.url) {
            window.open(item.url)
            return
        }

        if (vehicle && VehicleSelectionDocuments.includes(item.documentTypeId)) {
            actions.setShowVehicleModal(true)
        } else {
            openExternalDocument(item)
        }
    }

    const handleVehicleAcceptance = (keepVehicleInfo: boolean) => {
        actions.setShowVehicleModal(false)
        openExternalDocument(selectedDocument!, keepVehicleInfo)
    }

    const documents = articleDetailsResponse?.externalDocuments?.filter(
        (x, _, array) => !array.some((y) => y.relatedExternalSystemId == x.externalSystemId)
    )

    const orderedDocuments = useMemo(() => {
        const GVAGuide = documents?.find((d) => d.documentTypeId === EDocumentIds.GVAGuide)
        if (GVAGuide) {
            const newDocuments = documents?.filter((d) => d.documentTypeId !== EDocumentIds.GVAGuide)
            newDocuments?.unshift(GVAGuide)
            return newDocuments
        }

        return documents
    }, [documents])

    if ((!articleDetailsResponse || !articleDetailsResponse.externalDocuments) && !loadAllExternalSystemDocuments) {
        return null
    }

    const error = !documents?.length

    return (
        <PanelSection>
            <div className={style.wrapper}>
                {showVehicleModal && <VehicleInfoModal onVehicleAcceptance={handleVehicleAcceptance} />}
                {showModal && model && !cisCustomerLoading && <DocumentModal />}
                {!loading && !cisCustomerLoading && (
                    <>
                        {error && <MessageInline message={translateText(1789)} />}
                        {!error && orderedDocuments?.map((x) => <DocumentItemBtn key={x.name} item={x} onClick={handleOpenDocument} />)}
                    </>
                )}
                {loading || (cisCustomerLoading && <Loader className={style.loader} />)}
            </div>
        </PanelSection>
    )
}

export default withRouter(DocumentsComponent)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            width: percent(100),
        },
        loader: {
            margin: theme.margin.l,
        },
        errorMesage: {
            maxWidth: rem(21),
            margin: margin(rem(2), "auto"),
        },
    })(DocumentsComponent)
}
