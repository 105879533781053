import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { Article } from "@tm/models"
import { SensorItemsRequest } from "./model"
import { mapSensorArticle } from "./mapper"

export function loadSensorItems(request?: SensorItemsRequest): Promise<Article[]> {
    const url = `${getServiceUrl()}/Articles`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax({ url, body, authorization }).then(
        response => {
            if (response?.articles?.length) {
                return response.articles.map(mapSensorArticle)
            }
            else {
                return []
            }
        }
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.articlesServiceUrl
}