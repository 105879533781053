import { useWorkTask } from "@tm/context-distribution"
import { channel, ModuleGroupId, ModuleId, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { renderRoute, useMessage } from "@tm/utils"
import { FC, useEffect, useState } from "react"
import DemoBanner from "../demo-module-alert/components/DemoBanner"

type Props = {
    moduleGroupId: ModuleGroupId | "inspectionData"
    activationRoute?: string
}

const DemoAlertTruck: FC<Props> = ({ activationRoute, moduleGroupId }) => {
    const { workTaskId } = useWorkTask() || {}

    const [preventRender, setPreventRender] = useState<boolean>(false)
    const [moduleModeAux, setModuleModeAux] = useState<string | undefined>()
    const [moduleNameAux, setModuleNameAux] = useState<string | undefined>()
    const [moduleIdAux, setModuleIdAux] = useState<ModuleId | undefined>()

    useMessage((data) => {
        if (!data?.setModuleMode?.moduleMode) {
            return
        }

        if (data.setModuleMode.moduleMode === "ACTIVATION") {
            if (activationRoute) {
                const comparePageUrl = renderRoute(activationRoute, {})

                Morpheus.showView("1", comparePageUrl)
            }
            return
        }

        setModuleModeAux(data?.setModuleMode?.moduleMode)
        setPreventRender(false)

        if (!!data.setModuleMode.moduleId && !!data.setModuleMode.moduleName) {
            setModuleNameAux(data.setModuleMode.moduleName)
            setModuleIdAux(data.setModuleMode.moduleId)
        }
    })

    useEffect(() => {
        if (moduleIdAux) {
            channel("GLOBAL").publish("MVC/RESIZE", { moduleMode: moduleModeAux })
        }
    }, [moduleModeAux, moduleIdAux])

    if (preventRender || !workTaskId) {
        return null
    }

    if (!moduleGroupId || !moduleNameAux || !moduleIdAux || !moduleModeAux || !["TD", "SD"].includes(moduleModeAux)) {
        return null
    }

    return (
        <DemoBanner
            moduleGroupId={moduleGroupId}
            moduleGroupIdAux={moduleGroupId as ModuleGroupId}
            moduleId={moduleIdAux}
            vehicleType={VehicleType.CommercialVehicle}
            moduleName={moduleNameAux}
            activationRoute={activationRoute}
        />
    )
}

export default DemoAlertTruck
