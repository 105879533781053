import { Article } from "@tm/models"

export function createUpdateRequest(compilationId: string, article: Article, quantity: number, note: string) {
    return {
        compilationId,
        articleId: article.id,
        quantity,
        description: article.description,
        wholesalerArticleNumber: article.traderArticleNo,
        supplierArticleNumber: article.supplierArticleNo,
        note,
        customerArticleNumber: "",
    }
}
