import { getPureFastServiceState, navigationActions, useFastServiceStore } from ".."
import { bundleChannel } from "../../helpers"
import { AlertMessageTypes, CalculationHistoryStatus } from "../models"
import { getInsertHistoryRequest, getUpdateHistoryRequest, insertHistory, updateHistory } from "../repositories"
import { replacer } from "../sessionStorage/helper"

export function changeStepWithHistory(selectedStep: string, saveFromButton?: boolean) {
    const { stepNavigationState, setCalculationStatus, calculationStatus, calculationHistoryId } = useFastServiceStore.getState()

    navigationActions.changeStep(selectedStep)

    const selectedStepValue = stepNavigationState?.steps?.find((s) => s.stepName === selectedStep)
    const activeStepStatus = selectedStepValue?.calculationStatus

    if (activeStepStatus !== undefined) {
        if (activeStepStatus > calculationStatus) {
            setCalculationStatus(activeStepStatus)
            if (calculationStatus) {
                updateHistoryData(activeStepStatus, selectedStep, calculationHistoryId)
            } else {
                saveHistoryData(selectedStep)
            }
        }
    }

    if (saveFromButton) {
        if (calculationStatus) {
            updateHistoryData(activeStepStatus ?? calculationStatus, selectedStep, calculationHistoryId, saveFromButton)
        } else {
            saveHistoryData(selectedStep, saveFromButton)
        }
    }
}

function saveHistoryData(selectedStep: string, saveFromButton?: boolean) {
    const { maintenancePlan, setHistoryLoading, setCalculationHistoryId, version, calculationStatus } = useFastServiceStore.getState()
    const vehicleId = maintenancePlan.stateVehicle?.id
    const vinNumber = maintenancePlan.vehicleFields.vin
    const calculationId = maintenancePlan.calcState?.calcId

    setHistoryLoading(true)
    const JSONState = JSON.stringify(getPureFastServiceState("state"), replacer)
    const request = getInsertHistoryRequest(vehicleId, vinNumber, calculationId, JSONState, version)

    if (!request) {
        setHistoryLoading(false)
        return
    }

    insertHistory(request)
        .then((res) => {
            setHistoryLoading(false)
            setCalculationHistoryId(res.id)
            bundleChannel().publish("ALERT_MESSAGE", {
                messageType: AlertMessageTypes.HISTORY,
                message: saveFromButton ? "savedFromButton" : selectedStep,
                severity: "success",
            })
            updateHistoryData(calculationStatus, selectedStep, res.id)
        })
        .catch(() => setHistoryLoading(false))
}

function updateHistoryData(
    newHistoryStatus: CalculationHistoryStatus,
    selectedStep: string,
    calculationHistoryId?: string,
    saveFromButton?: boolean
) {
    const JSONState = JSON.stringify(getPureFastServiceState("state"), replacer)
    const request = getUpdateHistoryRequest(calculationHistoryId, newHistoryStatus, JSONState)

    if (!request) {
        saveHistoryData(selectedStep)
        return
    }

    updateHistory(request).then(() => {
        bundleChannel().publish("ALERT_MESSAGE", {
            messageType: AlertMessageTypes.HISTORY,
            message: saveFromButton ? "savedFromButton" : selectedStep,
            severity: "success",
        })
    })
}
