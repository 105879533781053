import { FastCalculator } from "@tm/data"
import WorkContent from "./WorkContent"
import CalcSelectionContent from "./CalcSelectionContent"

type Props = {
    calcState: FastCalculator.CalcState | undefined
    loading: boolean | undefined
    onClose?: () => void
}

export default function Content({ calcState, loading, onClose }: Props) {
    const interactionDialog = calcState && FastCalculator.ECalcState.OverlayCalcStates & calcState?.type
    const worksDialog = calcState && FastCalculator.ECalcState.WorksState & calcState?.type

    if (!calcState) {
        return null
    }

    if (worksDialog) {
        return <WorkContent calcState={calcState} loading={loading} onClose={onClose} />
    }

    if (interactionDialog) {
        return <CalcSelectionContent calcState={calcState} loading={loading} onClose={onClose} />
    }

    return null
}
