import { Box, styled } from "@tm/components"

export const StyledUploadField = styled(Box)(({ theme }) => ({
    padding: "5em",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.grey[200],
    border: "1px dashed #979797",
    borderRadius: "3px",
    transition: "0.2s",
    alignItems: "center",
}))

export const StyledLabel = styled("label")(({ theme }) => ({
    padding: ".8rem 1rem",
    marginBottom: ".5em",
    borderRadius: theme.radius?.default,
    backgroundColor: theme.palette.grey[300],
    "&:hover": {
        backgroundColor: theme.colors?.primary,
        color: theme.colors?.light,
        cursor: "pointer",
    },
}))
