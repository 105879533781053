import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, uniqueId, RouteComponentProps } from "@tm/utils"
import { Text, Widget, Button, Image, WidgetSizes } from "@tm/controls"
import { cssRaw } from "typestyle"

type RouteParams = {
    workTaskId: string
}

type Props = RouteComponentProps<RouteParams> & ConfigurationProps

type ConfigurationProps = {
    offers: Array<OffersItem>
    className?: string
    widgetSize?: WidgetSizes
    height?: number
}

type OffersItem = {
    theme: string
    discount: number
    headline: number | string
    text: number | string
    image?: string
    productGroupIds: Array<number>
    supplierIds: Array<number>
    details?: {
        supplierId: string
        productGroupId: string
        supplierArticleNo: string
        isUniversalArticle?: boolean
    }
}

function WidgetComponent(props: Props) {
    const { translate } = useLocalization()
    const [currentSlide, setCurrentSlide] = useState<number>(0)
    const { offers, history, widgetSize, height, match } = props

    function isUniversalOffer() {
        const offer = offers[currentSlide]
        return !!(offer && offer.details && offer.details.isUniversalArticle)
    }

    function offerHasDetailsLinkIds() {
        const offer = offers[currentSlide]
        return !!(offer && offer.details && offer.details.productGroupId && offer.details.supplierArticleNo && offer.details.supplierId)
    }

    function handlePageChange(value: number) {
        setCurrentSlide(value)
    }

    function handleOfferItemClick() {
        if (offerHasDetailsLinkIds()) {
            history.push(
                renderRoute(
                    `:workTaskId/parts/${isUniversalOffer() ? "universal" : "direct"}/details/:productGroupId/:supplierId/:supplierArticleNo`,
                    {
                        workTaskId: match.params.workTaskId || uniqueId(),
                        ...offers[currentSlide].details,
                    }
                )
            )
        }
    }

    function renderOfferButton() {
        const currentOffer = offers[currentSlide]

        return (
            ((currentOffer.productGroupIds && !!currentOffer.productGroupIds.length) || offerHasDetailsLinkIds()) && (
                <Button
                    disabled={!offerHasDetailsLinkIds()}
                    icon="next"
                    className="offers__slide__btn"
                    layout={["iconRight"]}
                    skin="highlight"
                    onClick={handleOfferItemClick}
                >
                    {translate(599)}
                </Button>
            )
        )
    }

    function renderCurrentPage() {
        if (offers && offers.length) {
            const currentOffer = offers[currentSlide]
            return (
                <div className={`offers__slide offers__slide--${currentOffer.theme}`}>
                    {currentOffer.image && (
                        <span className="offers__slide__image">
                            <Image onClick={handleOfferItemClick} url={currentOffer.image} />
                        </span>
                    )}
                    {!!currentOffer.discount && (
                        <Text className="offers__slide__discount headline" modifiers="strong">
                            -{currentOffer.discount}%
                        </Text>
                    )}
                    {!!currentOffer.headline && (
                        <Text className="offers__slide__headline headline--s">
                            {typeof currentOffer.headline === "number" ? translate(currentOffer.headline) : currentOffer.headline}
                        </Text>
                    )}
                    {!!currentOffer.text && (
                        <Text className="offers__slide__text">
                            {typeof currentOffer.text === "number" ? translate(currentOffer.text) : currentOffer.text}
                        </Text>
                    )}
                    {renderOfferButton()}
                </div>
            )
        }
    }

    function renderRadioButton(value: OffersItem, index: number) {
        let menuClass = "slides__menu__indicator"
        menuClass += currentSlide === index ? " is-active" : ""

        return <span className={menuClass} onClick={() => handlePageChange(index)} key={index} /> // <Radio key={index} value={index} checked={currentSlide === index} onCheck={this.handlePageChange} />
    }

    const className = `widget-offers ${props.className || ""}`

    return (
        <Widget size={widgetSize || "4x2"} active className={className} height={height}>
            <div className="widget-offers__current-page">{renderCurrentPage()}</div>

            {offers && offers.length > 1 && <div className="slides__menu">{offers.map(renderRadioButton)}</div>}
        </Widget>
    )
}

export default withRouter(WidgetComponent)

cssRaw(`
    .widget-offers.widget--h4 {
        flex: auto;
    }

    .widget-offers .widget__header {
        display: none;
    }

    .offers__slide {
        color: #494949;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        width: auto;
        align-items: flex-start;
    }

    .offers__slide__text,
    .offers__slide__headline,
    .offers__slide__discount,
    .offers__slide__btn {
        position: relative;
    }

    .offers__slide__btn.has-icon {
        padding-left: 15px;
    }

    .offers__slide__btn.has-icon .icon {
        margin-right: 0;
    }

    .offers__slide__image {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        overflow: hidden;
    }

    .offers__slide__image img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    .offers__slide__text,
    .offers__slide__headline {
        margin-left: -15px;
        padding: 15px;
    }

    .offers__slide__discount {
        display: block;
        color: #2196f3;
        margin-bottom: 0.15em;
    }

    .offers__slide__headline {
        text-transform: uppercase;
        margin-bottom: 0.15em;
    }

    .offers__slide__btn {
        position: absolute;
        bottom: 15px;
    }

    .offers__slide--highlight .offers__slide__text,
    .offers__slide--highlight .offers__slide__headline {
        background: rgba(243, 174, 33, 0.4);
    }

    .offers__slide--highlight .offers__slide__discount {
        color: #f3ae21;
    }

    .slides__menu {
        background: rgba(226, 226, 226, 0.6);
        padding: 0.5em;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        position: absolute;
        left: 15px;
        bottom: 15px;
    }

    .slides__menu__indicator {
        position: relative;
        padding: 4px 0 0 40px;
        cursor: pointer;
        height: 30px;
        width: 30px;
    }

    .slides__menu__indicator:last-child {
        padding-left: 0;
    }

    .slides__menu__indicator::before {
        border: 3px solid #494949;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        display: block;
        content: "";
        position: absolute;
        left: 0;
        top: 0;
    }

    .slides__menu__indicator.is-active::after {
        background: #2196f3;
        height: 16px;
        width: 16px;
        position: absolute;
        left: 7px;
        top: 7px;
        content: "";
        display: block;
        border-radius: 50%;
    }
`)
