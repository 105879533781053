import { Suspense } from "react"
import { IMicros, MemoType } from "@tm/models"
import { SeparatorList } from "@tm/controls"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-memo"]

function ErpInfoMemoComponent(props: Props) {
    const { loading, erpInfo } = useErpInfo(props.data, "list")
    const memos = erpInfo?.memos?.filter((m) => m.type === MemoType.ArticleListInformation) ?? []

    if (loading || !memos.length) {
        return null
    }

    return (
        <div className="article__cell article__cell--erp-memo">
            <SeparatorList items={memos.map((m) => ({ label: m.label, value: m.text }))} />
        </div>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <Suspense fallback={null}>
                <ErpInfoMemoComponent {...props} />
            </Suspense>
        </ErpWrapper>
    )
}
