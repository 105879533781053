import { useLocalization } from "@tm/localization"
import { PriceType } from "@tm/models"
import { CellContentPosition, TableCellData, TableColumnData } from "@tm/components"
import { VoucherItem } from "../../../../../data/model"
import { StyledTable } from "../../../../_shared/StyledComponents"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import PriceCell from "../../../../_shared/cells/PriceCell"
import ArticleDescriptionCell from "../../../../_shared/cells/ArticleDescriptionCell"
import VoucherItemOptionsCell from "../../../../_shared/cells/VoucherItemOptionsCell"
import ArticleNumberCell from "../../../../_shared/cells/ArticleNumberCell"

type Props = {
    voucherItems: Array<VoucherItem>
}

export default function WmOldPartsItemsTableComponent({ voucherItems }: Props) {
    const { translateText } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(1310) },
            { header: translateText(477) },
            { header: translateText(377) },
            { header: translateText(89), alignContent: CellContentPosition.center },
            { header: translateText(1287), alignContent: CellContentPosition.right },
            {},
        ]
        return columns
    }

    function getCells(voucherItem: VoucherItem) {
        const cellData: TableCellData[] = [
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="wholesaler" />, id: "item_1" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="supplier" />, id: "item_2" },
            {
                displayValue: <ArticleDescriptionCell supplierName={voucherItem.supplierName} articleDescription={voucherItem.articleDescription} />,
                id: "item_2",
            },
            { displayValue: `${voucherItem.returnQuantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_3" },
            {
                displayValue: <PriceCell priceType={PriceType.Deposit} prices={voucherItem.prices} quantityUnit={voucherItem.quantityUnit} />,
                id: "item_4",
            },
            { displayValue: <VoucherItemOptionsCell item={voucherItem} showAddToCompilations isWm />, id: "item_5" },
        ]
        return cellData
    }

    const displayData = voucherItems.map((voucherItem, index) => ({
        cells: getCells(voucherItem),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} />
}
