import { useRef, useEffect } from "react"
import { registerOutsideClick } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Table, Loader } from "@tm/controls"
import { Street } from "../../../data/model"

type Props = {
    list?: Array<Street>
    loading: boolean
    onSelect(street: Street): void
    resetAddressSuggestions(): void
}

export default function AddressSuggestBoxComponent(props: Props) {
    const { translate } = useLocalization()
    const { list, loading } = props
    const wrapperRef = useRef<HTMLDivElement>(null)

    function handleOutsideClick(e: any) {
        for (let i = 0; i < e.path?.length; i++) {
            const node = e.path[i]

            if (node.classList && node.classList.contains("address-field")) {
                return
            }
        }

        setTimeout(() => {
            // do we dont need this, because the suggestions will be reloaded anyway?!
            props.resetAddressSuggestions()
        }, 0)
    }

    useEffect(() => {
        if (wrapperRef.current) {
            return registerOutsideClick(wrapperRef.current, handleOutsideClick)
        }
    }, [])

    function renderTableColumns() {
        return [
            <Table.Column key="street" className="street" renderItemContent={(data) => <Table.Cell>{data.name}</Table.Cell>}>
                {translate(111)}
            </Table.Column>,
            <Table.Column key="zip" className="zip" renderItemContent={(data) => <Table.Cell>{data.postcode}</Table.Cell>}>
                {translate(112)}
            </Table.Column>,
            <Table.Column key="city" className="city" renderItemContent={(data) => <Table.Cell>{data.region}</Table.Cell>}>
                {translate(113)}
            </Table.Column>,
        ]
    }

    const active = !!list && !!list.length

    let suggestClassName = "user-settings__location__suggest suggest"
    if (active) {
        suggestClassName += " suggest--open"
    }

    let suggestBoxClassName = "suggest__box"
    if (active) {
        suggestBoxClassName += " is-visible"
    }

    return (
        <div className={suggestClassName} ref={wrapperRef} onClick={handleOutsideClick}>
            <div className={suggestBoxClassName}>
                <Table data={list} columns={renderTableColumns()} onClickRow={props.onSelect} />
                <Loader visible={loading} />
            </div>
        </div>
    )
}
