import {
    Article,
    ArticleErpInfo,
    ArticleListType,
    ErpBundleParams,
    RequestArticleListPayload,
    UserModuleType,
    Vehicle,
    WholesalerArticle,
} from "@tm/models"
import { useQuery } from "react-query"
import { getModuleFromUserContext, usePureArticleList, useUser, useWorkTaskId } from "@tm/context-distribution"
import { useMemo, useState } from "react"
import { uniq } from "lodash"
import Morpheus from "@tm/morpheus"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { useDefaultArticleItemState } from "@tm/components"
import { useVehicle } from "../../ListV2/hooks/useVehicle"
import { useSupplierLogos } from "../../ListV2/hooks/useSupplierLogos"
import { useNotes } from "../../ListV2/hooks/useNotes"
import { useProductGroupRepairTimes } from "../../ListV2/hooks/useProductGroupRepairTimes"
import { useArticleFeedbackConfiguration } from "../../ListV2/ArticleListConfiguration/useArticleFeedbackConfiguration"
import { useErpInfos } from "../../ListV2/hooks/useErpInfos"
import { useListOptions } from "../../ListV2/hooks/useListOptions"
import { useBasketQuantities, useWholesalerArticleBasketQuantities } from "../../ListV2/hooks/useBasketQuantities"
import { useHasLoadingEnded } from "../../ListV2/hooks/useHasLoadingEnded"
import { useWatchListData } from "../../ListV2/hooks/useWatchListData"
import { useTradeReferences } from "../../ListV2/hooks/useTradeReferences"
import { loadAlternatives } from "./loadAlternatives"
import { loadPartsList } from "./loadPartsList"
import { PartsListImage, PartsListImageCoordinate } from "../../../data/model"
import { getAccessoryList, getArticlesByArticleNoWithOptVehicle } from "../../../data/repositories/parts"
import { usePreviouslyOrderedArticles } from "../../ListV2/hooks/usePreviouslyOrderedArticles"

export function usePureList(listId: string, pureListType: string) {
    const listType: ArticleListType = "pure"
    const cacheKey = `${listType}_${pureListType}_${listId}`
    const workTaskId = useWorkTaskId()
    const vehicle = useVehicle()
    const { userContext } = useUser()
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([])

    const { hideExtendedDeliveryComponent } = Morpheus.getParams<ErpBundleParams>("erp")
    const { showDataSupplierLogos = false } = userContext.parameter
    const feedbackConfiguration = useArticleFeedbackConfiguration()
    const options = useListOptions()
    const activeVehicleDataProviders = useActiveVehicleDataProviders(workTaskId)
    // this is workaround! We need access to the parent article item action to send the callback.
    const recommendedArticles = useDefaultArticleItemState((x) => x.recommendedArticles)

    const { state } = usePureArticleList(listId)
    const isInitialized = !!state?.request

    const { data, isLoading } = useQuery({
        queryKey: ["pureList", workTaskId, pureListType, state?.request],
        queryFn: () => state.request && loadArticles(pureListType, state.request, vehicle),
        enabled: !!state?.request,
    })

    const hasArticlesLoaded = !!data?.articles
    const articles = useMemo(() => data?.articles ?? [], [data?.articles])
    const productGroupIds = useMemo(() => uniq(articles.map((x) => x.productGroup.id) ?? []), [articles])
    const supplierIds = useMemo(() => uniq(articles.map((x) => x.supplier.id) ?? []), [articles])
    const { tradeReferences, tradeReferenceNumbersLoaded } = useTradeReferences(articles, hasArticlesLoaded)
    const erpInfosData = useErpInfos({
        articles: data?.articles ?? [],
        isEnabled: hasArticlesLoaded && tradeReferenceNumbersLoaded,
        erpInfos,
        setErpInfos,
        tradeReferences,
    })

    const supplierLogos = useSupplierLogos(supplierIds, showDataSupplierLogos && erpInfosData.isReady)
    const watchListData = useWatchListData(articles, !!options.hasWatchList && erpInfosData.isReady)

    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(data?.articles ?? [], erpInfosData.isReady, cacheKey)
    const { basketQuantities: wholesalerBasketQuantities, updateBasketQuantities: updateWholesalerBasketQuantities } =
        useWholesalerArticleBasketQuantities(
            data?.type === "alternative" && data.wholesalerParts?.length ? data.wholesalerParts ?? [] : [],
            true,
            cacheKey
        )
    const notesParams = useNotes(data?.articles ?? [], false, erpInfosData.isReady)
    const productGroupRepairTimes = useProductGroupRepairTimes(productGroupIds, erpInfosData.isReady)
    const previouslyOrderedArticles = usePreviouslyOrderedArticles(data?.articles)
    const showNotes = useMemo<boolean>(() => !!getModuleFromUserContext(userContext, UserModuleType.ArticleNotes), [userContext.modules])

    const hasLoadingEnded = useHasLoadingEnded(
        supplierLogos.isLoading,
        watchListData?.isLoading,
        productGroupRepairTimes.isLoading,
        activeVehicleDataProviders.isLoading
    )
    return {
        data,
        erpInfosData,
        options,
        isLoading,
        supplierLogosData: hasLoadingEnded ? supplierLogos : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimesData: hasLoadingEnded ? productGroupRepairTimes : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProviders.activeProviders : undefined,
        feedbackConfiguration,
        notesParams,
        showNotes,
        previouslyOrderedArticles,
        showExtendedDelivery: !hideExtendedDeliveryComponent,
        listType,
        isInitialized,
        basketQuantities,
        wholesalerBasketQuantities,
        tradeReferences,
        updateBasketQuantities,
        updateWholesalerBasketQuantities,
        loadNextRecommendedArticle: recommendedArticles?.loadNextRecommendedArticle,
    }
}

type LoadArticlesResponse = {
    articles: Article[]
} & (
    | { type: null }
    | {
          type: "alternative"
          wholesalerParts?: WholesalerArticle[]
      }
    | {
          type: "partslist"
          imageCoordinates?: PartsListImageCoordinate[]
          partsListImage?: PartsListImage
      }
    | {
          type: "accessoryList"
      }
    | {
          type: "articlesAlreadyOrderedList"
      }
    | {
          type: "mainAccessoryList"
      }
    | {
          type: "recommended-articles"
      }
)

async function loadArticles(type: string, request: RequestArticleListPayload, vehicle?: Vehicle): Promise<LoadArticlesResponse> {
    const { traderArticleNos, partsList, accessoryList, articleIdentifier, articles } = request

    if (type === "alternative" && traderArticleNos) {
        return {
            type,
            ...(await loadAlternatives(request, vehicle)),
        }
    }

    if (type === "partslist" && partsList) {
        return {
            type,
            ...(await loadPartsList(request)),
        }
    }

    if (type === "accessoryList" && accessoryList) {
        return {
            type,
            articles: await getAccessoryList(accessoryList),
        }
    }

    if (type === "articlesAlreadyOrderedList" && articleIdentifier) {
        return {
            type,
            articles: await getArticlesByArticleNoWithOptVehicle(articleIdentifier),
        }
    }

    if (type === "mainAccessoryList" && articles) {
        return {
            type,
            articles,
        }
    }

    if (type === "recommended-articles" && articles) {
        return {
            type,
            articles,
        }
    }

    return { type: null, articles: [] }
}
