import { RepairShop } from "@tm/context-distribution"
import { CisCustomerResponse, UserContext } from "@tm/models"
import {
    dateToString,
    mapArticleDetailsForDocumentsRequest,
    mapCustomerForDocumentsRequest,
    mapRepairShopForDocumentsRequest,
    mapSupplierForDocumentsRequest,
    mapTraderForDocumentsRequest,
    mapVehicleInfoForDocumentsRequest,
} from "."
import { DocumentRequest, DocumentsState, LicensorData } from "../model"

export function createDocumentRequest(
    state: DocumentsState,
    repairShop?: RepairShop,
    licensorData?: LicensorData,
    hideCustomerNr?: boolean,
    customer?: CisCustomerResponse,
    userContext?: UserContext,
    telesalesCustomerNo?: string
): DocumentRequest {
    const { articleDetailsResponse, carModel, supplierInfo, vehicle } = state
    const isTelesales = window.userContext.hasTelesales
    let customerReference = customer?.customerNo ?? userContext?.parameter?.customerReferenceNumber ?? ""
    if (customerReference === "") {
        customerReference = userContext?.account?.customerNo || ""
    }

    return {
        documents: [],
        articleDetails: mapArticleDetailsForDocumentsRequest(articleDetailsResponse),
        customerInfo: mapCustomerForDocumentsRequest(customer),
        repairShopInfo: mapRepairShopForDocumentsRequest(repairShop, customer),
        supplierInfo: mapSupplierForDocumentsRequest(supplierInfo),
        traderInfo: mapTraderForDocumentsRequest(licensorData, customer, userContext?.parameter?.addressGVS),
        vehicleInfo: mapVehicleInfoForDocumentsRequest(carModel, vehicle),

        additionalCostsInfo: {
            additionalParts: [],
            referenceNumber: "",
            vehicleOwner: "",
            repairmentDay: "",
        },
        batteryInfo: {
            enableDeinstallationCost: false,
            deinstallationCost: "",
            consumers: {
                airConditioning: "",
                auxiliaryHeating: "",
                other: "",
                seatHeating: "",
                otherConsumerDescription: "",
            },
            deliveryNoteInformation: {
                date: "",
                number: "",
            },
            generalMeasurement: {
                dateOfMeasurement: "",
                measuredValueInEN: "",
                measuredValueInVolt: "",
            },
            serviceMeasurement: {
                dateOfMeasurement: "",
                measuredValueInEN: "",
                measuredValueInVolt: "",
            },
            claimDescription: "",
            durationOfDistance: 0,
            // hasStartStopTech: 0,
            otherSiteOfUseDescription: "",
            purposeOfUseSupplyDrive: 0,
            purposeOfUseStart: 0,
            siteOfUse: 0,
            batteryDetectedTime: "",
            batteryDetectedVoltage: "",
            batteryPlacement: 0,
            completionAtAcknowledgement: 0,
            completionAtRejection: 0,
            generatorDetectedVoltageWithLoad: "",
            generatorDetectedVoltageWithoutLoad: "",
        },
        returnsInfo: {
            returnType: 0,
            goodsReturnReason: "",
            isProductResalable: 0,
            additionalParts: [],
            completionAtReturn: 0,
            creditNoteNumber: "",
        },
        complainInfo: {
            completionAtAcknowledgement: 0,
            completionAtRejection: 0,
            additionalCosts: 0,
            additionalCostsDecription: "",
            claimDescription: "",
            errorOccurance: 0,
            errorOccuranceDescription: "",
            isAdditionalCosts: 0,
        },
        receitInfo: {
            date: "",
            number: "",
        },
        oldBatteriesInfo: {
            oldBatteryTypeList: [
                { id: "1AH–10AH", quantity: 0, dangerPointsNr: 0, value: 5, tons: 0, tonsValue: 0.002 },
                { id: "11AH–50AH", quantity: 0, dangerPointsNr: 0, value: 10, tons: 0, tonsValue: 0.012 },
                { id: "51AH–100AH", quantity: 0, dangerPointsNr: 0, value: 20, tons: 0, tonsValue: 0.018 },
                { id: "101AH–150AH", quantity: 0, dangerPointsNr: 0, value: 30, tons: 0, tonsValue: 0.025 },
                { id: "151AH–230AH", quantity: 0, dangerPointsNr: 0, value: 60, tons: 0, tonsValue: 0.05 },
            ],
            producerNumber: "",
        },
        customerReference,
        currentDate: dateToString(new Date()) || "",
        includeCustomerNr: !hideCustomerNr,
        notificationOfDefects: "",
        isTelesales,
        telesalesCustomerNumber: telesalesCustomerNo,
    }
}
