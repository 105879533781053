import { selectorFamily, useRecoilValue } from "recoil"
import { chatMessagesRecoilAtom, Message } from "."
import {
    CustomerInfo,
    VehicleInfo,
    MetaDataType,
    AttachmentTypeAppointment,
    AttachmentTypeAppointmentResponse,
    CostEstimateResponseMetaData,
} from ".."
import { FileAttachmentMetaData, CostEstimateMetaData } from "../../components/chats/components/input/attachment"

export type MergedVehicle = {
    tmVehicleId?: string
    vehicleInfo: VehicleInfo

    appointment?: MergedAppointment
    fastCheck?: Message
    costEstimate?: { initial: CostEstimateMetaData; initialMessage: Message; response?: CostEstimateResponseMetaData }
    invoice?: Message

    lastStatusRelevantAction?: "fastCheck" | "costEstimateSent" | "costEstimateConfirmation" | "costEstimateRejection" | "invoice"
}

export type MergedAppointment = {
    initial: AttachmentTypeAppointment
    last?: AttachmentTypeAppointmentResponse
    initialOrLastMessage: Message
}

const costEstimateMimeTypesToAccept = ["application/pdf", "image/jpeg", "image/png", "image/gif"]

export type CondensedChatInfos = {
    tmCustomerId?: string
    customerInfo?: CustomerInfo
    vehicles: Array<MergedVehicle>
}

const condensedChatInfosRecoilSelector = selectorFamily<CondensedChatInfos | undefined, string>({
    key: "notifications_chat_condensed-chat-infos",
    get:
        (chatId: string) =>
        ({ get }) => {
            const messages = get(chatMessagesRecoilAtom(chatId))

            if (messages?.length) {
                let tmCustomerId: string | undefined
                let customerInfo: CustomerInfo | undefined
                const vehicles: Array<MergedVehicle> = []

                messages.forEach((m) => {
                    if (m.appMetaData) {
                        const metaData: MetaDataType = JSON.parse(m.appMetaData)

                        if (metaData.type === "initial" || metaData.type === "appointment") {
                            if (metaData.action === "customerData" || metaData.action === "vehicleData" || metaData.action === "customerRequest") {
                                if (metaData.customerInfo) {
                                    customerInfo = metaData.customerInfo
                                }

                                if (metaData.vehicleInfo) {
                                    const index = vehicles.findIndex((x) => x.vehicleInfo.id === metaData.vehicleInfo?.id)
                                    if (index >= 0) {
                                        vehicles[index].vehicleInfo = metaData.vehicleInfo
                                    } else {
                                        vehicles.push({ vehicleInfo: metaData.vehicleInfo })
                                    }
                                }
                            } else if (metaData.action === "repairshopData") {
                                if (metaData.customer) {
                                    tmCustomerId = metaData.customer.tmCustomerId
                                }

                                metaData.vehicles.forEach((v) => {
                                    const index = vehicles.findIndex((x) => x.vehicleInfo.id === v.seatbeltVehicleId)
                                    if (index >= 0) {
                                        vehicles[index] = {
                                            ...vehicles[index],
                                            tmVehicleId: v.tmVehicleId,
                                        }
                                    }
                                    // else shouldn't happen
                                })
                            }
                        }

                        if (m.attachment) {
                            const metaData: FileAttachmentMetaData = JSON.parse(m.appMetaData)

                            if (metaData.type === "costEstimate") {
                                const vehicle = vehicles.find((v) => v.vehicleInfo.id === metaData.externalVehicleId)
                                if (vehicle && (!metaData.externalOffer || costEstimateMimeTypesToAccept.includes(m.attachment.mimeType))) {
                                    vehicle.costEstimate = { initial: metaData, initialMessage: m }
                                    vehicle.lastStatusRelevantAction = "costEstimateSent"
                                }
                            } else if (metaData.type === "attachment") {
                                switch (metaData.category) {
                                    case "Invoice": {
                                        const vehicle = vehicles.find((v) => v.vehicleInfo.id === metaData.externalVehicleId)
                                        if (vehicle) {
                                            vehicle.invoice = m
                                            vehicle.lastStatusRelevantAction = "invoice"
                                        }
                                        break
                                    }
                                    case "AcceptanceProtocol": {
                                        const vehicle = vehicles.find((v) => v.vehicleInfo.id === metaData.externalVehicleId)
                                        if (vehicle) {
                                            vehicle.fastCheck = m
                                            vehicle.lastStatusRelevantAction = "fastCheck"
                                        }
                                        break
                                    }
                                    default:
                                        break
                                }
                            }
                        } else if (metaData.type === "costEstimateResponse") {
                            const vehicle = vehicles.find((v) => v.costEstimate?.initial.offerId === metaData.offerId)
                            if (vehicle?.costEstimate) {
                                vehicle.costEstimate.response = metaData
                                vehicle.lastStatusRelevantAction =
                                    metaData.action === "customerConfirmation" ? "costEstimateConfirmation" : "costEstimateRejection"
                            }
                        } else if (metaData.type === "appointment") {
                            const vehicle = vehicles.find((v) => v.vehicleInfo.id === metaData.vehicleInfo?.id)
                            if (vehicle) {
                                vehicle.appointment = { initial: metaData, initialOrLastMessage: m }
                                vehicle.fastCheck = undefined
                                vehicle.costEstimate = undefined
                                vehicle.invoice = undefined
                                vehicle.lastStatusRelevantAction = undefined
                            }
                        } else if (metaData.type === "appointmentResponse") {
                            const vehicle = vehicles.find((v) => v.appointment?.initial.appointmentId === metaData.appointmentId)
                            if (vehicle?.appointment) {
                                vehicle.appointment.last = metaData
                                vehicle.appointment.initialOrLastMessage = m
                            }
                        }
                    }
                })

                const result: CondensedChatInfos = {
                    tmCustomerId,
                    customerInfo,
                    vehicles,
                }

                return result
            }
        },
})

export function useCondensedChatInfos(chatId: string): CondensedChatInfos | undefined {
    return useRecoilValue(condensedChatInfosRecoilSelector(chatId))
}
