import { Vehicle } from "@tm/models"
import { ActionDispatch } from "@tm/morpheus"
import { VehiclePrintOptions } from "../../../data/repositories/print"
import { DictionaryItemPair } from "../../_shared/Dictionary"

export * from "./actions"

export type NavigationState = {
    hasDatInfo: boolean
    hasOrderedParts: boolean
    printOptions: VehiclePrintOptions
    vehicle?: Vehicle
    manufacturerData: Array<DictionaryItemPair>
    modelImage: string
}

export type ComponentActionType =
    | { type: "HAS_DAT_INFO"; payload: boolean }
    | { type: "SET_PRINT_VEHICLE"; payload: Vehicle }
    | { type: "HAS_ORDERED_PARTS"; payload: boolean }
    | { type: "CHANGE_PRINT_OPTIONS"; payload: { state: boolean; name: string } }
    | { type: "SET_MANUFACTURER_DATA"; payload: Array<DictionaryItemPair> }
    | { type: "SET_MODEL_IMAGE"; payload: string }

const DEFAULT_STATE: NavigationState = {
    hasDatInfo: false,
    hasOrderedParts: false,
    printOptions: {
        printVehicleInfo: true,
        printManufacturerInfo: true,
        printOrderedParts: false,
        printDatInfo: false,
    },
    manufacturerData: [],
    modelImage: "",
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): NavigationState {
    switch (action.type) {
        case "HAS_DAT_INFO":
            return { ...state, hasDatInfo: action.payload, printOptions: { ...state.printOptions, printDatInfo: action.payload } }
        case "HAS_ORDERED_PARTS":
            return { ...state, hasOrderedParts: action.payload, printOptions: { ...state.printOptions, printOrderedParts: action.payload } }
        case "SET_PRINT_VEHICLE":
            return { ...state, vehicle: action.payload }
        case "CHANGE_PRINT_OPTIONS":
            const { name: optionName, state: optionState } = action.payload
            return { ...state, printOptions: { ...state.printOptions, [optionName]: optionState } }
        case "SET_MANUFACTURER_DATA":
            return { ...state, manufacturerData: action.payload }
        case "SET_MODEL_IMAGE":
            return { ...state, modelImage: action.payload }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "HAS_DAT_INFO": {
            dispatch(action)
            break
        }
        case "SET_MANUFACTURER_DATA": {
            dispatch(action)
            break
        }

        case "HAS_ORDERED_PARTS": {
            dispatch(action)
            break
        }

        case "SET_MODEL_IMAGE": {
            dispatch(action)
            break
        }

        case "SET_PRINT_VEHICLE": {
            dispatch(action)
            break
        }
        default:
            break
    }
}
