import { Box } from "@tm/components"
import { Collapsible } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CostEstimation } from "@tm/data/vouchers/costEstimations"
import WorksTable from "./components/WorksTable"
import { WorksTotals } from "../totals/WorksTotals"

type Props = {
    costEstimation?: CostEstimation
}

export default function Works({ costEstimation }: Props) {
    const { works, repairTimeDivision, totalsB2B, totalsB2C, currencyCode, currencySymbol, costEstimationOptions } = costEstimation || {}
    const { translateText } = useLocalization()
    const showRepairTimesInHours = repairTimeDivision === 1
    return (
        <Collapsible name={translateText(1668)} initiallyOpened skin="dark">
            <Box px={2} pb={1}>
                <WorksTable
                    showRepairTimesInHours={showRepairTimesInHours}
                    works={works}
                    currencyCode={currencyCode}
                    currencySymbol={currencySymbol}
                />
                <Box py={1} pr={1} display="flex" justifyContent="flex-end">
                    <WorksTotals
                        priceVatMode={costEstimationOptions?.priceVatMode}
                        currencyCode={currencyCode ?? ""}
                        showRepairTimesInHours={showRepairTimesInHours}
                        totals={totalsB2B ?? totalsB2C}
                    />
                </Box>
            </Box>
        </Collapsible>
    )
}
