import { Component } from "react"
import { Badge, Button, Icon } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { ConfigParams, IMicros, MainService } from "@tm/models"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { bindSpecialReactMethods } from "@tm/utils"
import { AddRepairTimesToRdBasket } from "../../data/models"
import { RedesignHelper } from "../rd-helper"
import { Actions, IActions } from "./business"
import { AddKVAToRDBasketState } from "./business/model"

type Props = LocalizationProps & IMicros["standalone"]["rd-add-rt-to-basket"] & {
    state: AddKVAToRDBasketState
    actions: IActions
}

type State = {
    addedToBasketRD?: boolean
}

class AddToRDBasketComponent extends Component<Props, State> {

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        this.state = { addedToBasketRD: false }
    }

    UNSAFE_componentWillMount() {
        const { state: { blockPostMessage }, sourceId } = this.props

        if (!blockPostMessage) {
            RedesignHelper.getAllRepairTimes(this.props.actions.getRepairItems, sourceId)
            this.props.actions.setBlockMessageStatus(true)
        }
    }

    componentWillUnmount() {
        this.props.actions.setBlockMessageStatus(false)
    }

    handleClick(addedSuccesfully?: boolean) {
        let { repairTimeDivision, vehicle, service, provider, isPreparationWork, sourceId } = this.props

        if (!vehicle || !repairTimeDivision) {
            return
        }

        if (!isPreparationWork) {
            service = service as MainService
        }

        const request: AddRepairTimesToRdBasket[] = [{
            addToKVA: true,
            docType: 10,
            provider,
            repairTimeDivision,
            kTypeId: vehicle.tecDocTypeId,
            repairTimes: [service],
            vehicleId: vehicle.id,
            workTaskId: ""
        }]

        if (addedSuccesfully) {
            RedesignHelper.deleteRepairTimes(request, this.props.actions.getRepairItems, sourceId)
            this.setState({ addedToBasketRD: false })
        }
        else {
            RedesignHelper.addItemsToKVA(request, sourceId)
            this.setState({ addedToBasketRD: true })
        }
    }

    render() {
        const { service, state: { repairTimeNos }, localization: { translateText, languageId } } = this.props
        const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()

        const addedSuccesfully = (repairTimeNos && repairTimeNos.some(x => x.vehicleLinkageId == service.vehicleLinkageId)) || this.state.addedToBasketRD

        return (
            <div className="tk-standalone rd-add-rt-to-basket">
                {addedSuccesfully &&
                    <Badge skin="dark" value={<Icon name="check" />} />
                }
                <Button
                    className="kva-button"
                    layout={["bordered"]}
                    skin={addedSuccesfully || deleteButtonColorError? "danger": "highlight"}
                    title={translateText(916)}
                    onClick={this.handleClick.bind(this, addedSuccesfully)}
                    icon={addedSuccesfully ? "delete" : languageId == "1" ? "voucher-kva" : "voucher-kva-international"}
                />
            </div>
        )
    }
}


export default connectComponent(Actions, withLocalization(AddToRDBasketComponent))
