import { MouseEvent, useRef, memo } from "react"

import { Button, WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Compilation } from "../../../../../data"
import { useDeleteCompilations } from "../../../../../data/hooks/useCompilations"

export interface IProps {
    compilation: Compilation
    openCompilations(): void
}

export const CompilationDeleteNavigation = memo<IProps>(({ compilation, openCompilations }) => {
    const { deleteCompilations } = useDeleteCompilations()
    const { translateText } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const _dialogRef = useRef<WarningPrompt>(null)

    const handleRemoveCompilation = (e?: MouseEvent) => {
        e?.stopPropagation()
        _dialogRef.current?.show()
    }

    const handlePartsWarningConfirm = () => {
        deleteCompilations([compilation.id])
        openCompilations()
    }

    return (
        <>
            <Button
                disabled={!compilation.isOwn}
                onClick={handleRemoveCompilation}
                icon="delete"
                title={translateText(69)}
                size="s"
                className="delete-compilation"
                skin={deleteButtonColorError ? "danger" : undefined}
            />
            <WarningPrompt
                text={translateText(1313)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={_dialogRef}
                onConfirm={handlePartsWarningConfirm}
            />
        </>
    )
})
