import { Box, NotesButton, Table, TableCellData, TableColumnData } from "@tm/components"
import { useMemo, useState } from "react"
import { ArticleInfoType, NoteTypes } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ReturnVoucherItem } from "../../../../../../../../data/model"
import ArticleThumbnail from "../../../_shared/ArticleThumbnail"
import ArticleNumbers from "../../../_shared/ArticleNumbers"
import ArticleDescription from "../../../_shared/ArticleDescription"
import { ValueLabelStack } from "../../../_shared/ValueLabelStack"
import OrderNumber from "./components/OrderNumber"
import PartNotes from "../../../_shared/PartNotes"

type Props = {
    returnItems: ReturnVoucherItem[]
    isBigScreen: boolean
    onShowOrder(returnNumber: string): void
}

export default function ReturnsPartsTable({ returnItems, isBigScreen, onShowOrder }: Props) {
    const { translateText, date } = useLocalization()
    const partNotesExpandedMap = new Map<string, boolean>()
    returnItems.forEach((part) => {
        partNotesExpandedMap.set(part.voucherItemId, false)
    })

    const [isNoteExpandedMap, setIsNoteExpandedMap] = useState(partNotesExpandedMap)
    const hasNotes = useMemo(() => {
        return returnItems.some((part) => !!part.returnInfo)
    }, [returnItems])

    function toggleIsNoteExpanded(partId: string) {
        if (isNoteExpandedMap.has(partId)) {
            const currentValue = isNoteExpandedMap.get(partId)
            setIsNoteExpandedMap(new Map(isNoteExpandedMap.set(partId, !currentValue)))
        }
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: "" }, // Article thumbnail
            { header: "" }, // Article manufacturer
            { header: "" }, // Numbers
            { header: "" }, // Article description
            { header: "" }, // Order Date
            { header: "" }, // Order Number
            { header: "" }, // Quantity
            { header: "" }, // Return Reason
        ]

        if (hasNotes) {
            columns.push({ header: "" }) // Return info
        }
        return columns
    }

    function getDisplayData() {
        return returnItems?.map((part, index) => ({
            cells: getCellData(part),
            id: `${index}`,
            customRow: false,
            active: false,
            extendedContent: isNoteExpandedMap.get(part.voucherItemId) && part?.returnInfo && (
                <Box p={0.5} display="flex" flex={1}>
                    <PartNotes note={part.returnInfo} />
                </Box>
            ),
        }))
    }

    function getCellData(returnItem: ReturnVoucherItem): TableCellData[] {
        const {
            orderDate,
            dataSupplierArticleNumber,
            dataSupplier,
            description,
            wholesalerArticleNumber,
            returnQuantity,
            productGroup,
            thumbnailUrl,
            returnReason,
            returnInfo,
            voucherItemId,
        } = returnItem

        const cellData: TableCellData[] = [
            {
                displayValue: <ArticleThumbnail articleInfoType={ArticleInfoType.TecdocArticle} thumbnailUrl={thumbnailUrl} />,
                id: "part_1",
            },
            { displayValue: dataSupplier?.name, id: "part_2" },
            {
                displayValue: (
                    <ArticleNumbers
                        articleType={ArticleInfoType.TecdocArticle}
                        articleNumber={dataSupplierArticleNumber}
                        productGroupId={productGroup?.id}
                        supplierId={dataSupplier?.id}
                        wholesalerArticleNumber={wholesalerArticleNumber}
                    />
                ),
                id: "part_3",
            },
            {
                displayValue: <ArticleDescription description={description} productGroupName={productGroup?.name} isBigScreen={isBigScreen} />,
                id: "part_4",
            },
            {
                displayValue: <ValueLabelStack label={translateText(776)} value={orderDate && date(orderDate, "d")} />,
                id: "part_5",
            },
            {
                displayValue: <OrderNumber returnItem={returnItem} onShowOrder={onShowOrder} />,
                id: "part_6",
            },
            {
                displayValue: <ValueLabelStack label={translateText(89)} value={returnQuantity} />,
                id: "part_7",
            },
            {
                displayValue: <ValueLabelStack label={translateText(1514)} value={returnReason.description} />,
                id: "part_8",
            },
        ]

        if (hasNotes) {
            cellData.push({
                displayValue: returnInfo && (
                    <NotesButton
                        onClick={() => toggleIsNoteExpanded(voucherItemId)}
                        hasNote
                        iconOnly
                        articleNotes={[
                            {
                                type: NoteTypes.ARTICLE,
                                message: returnInfo,
                                title: translateText(12874),
                            },
                        ]}
                    />
                ),
                id: "part_9",
            })
        }

        return cellData
    }

    return <Table headerBackground="transparent" columns={getColumns()} rows={getDisplayData()} overflowY="unset" />
}
