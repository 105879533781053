import { Article, Vehicle } from "@tm/models"
import { NavigationSteps } from "./models"

export type BundleActionTypes =
    | { type: "VEHICLE_SET", payload: Vehicle }
    | { type: "CHANGE_STEP", payload: { selectedStep: NavigationSteps, params?: any } }

    | { type: "CHANGE_ARTICLE_QUANTITY", payload: { article: Article, quantity: number } }
    | { type: 'UPDATE_SEARCH_VALUE', payload: string }
    | { type: "@@redux/INIT" }

const changeQuantity = (article: Article, quantity: number): BundleActionTypes =>
    ({ type: "CHANGE_ARTICLE_QUANTITY", payload: { article, quantity } })

const setVehicle = (vehicle: Vehicle): BundleActionTypes =>
    ({ type: "VEHICLE_SET", payload: vehicle })

const resetStore = (): BundleActionTypes =>
    ({ type: "@@redux/INIT" })



export type IBundleActions = typeof BundleActions

export const BundleActions = {
    setVehicle,
    changeQuantity,
    resetStore
}