import { getStoredAuthorization, RequestMethod, equals, ajax, Request } from "@tm/utils"
import { getBundleParams } from "../utils"

const TOKEN_ITEM = "token://OAuthFromDb:WERKSTATTPLANUNG"
let tokenPromise: Promise<OAuth2Token>

function getAuthorityServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.authorityServiceUrl
}

function getAuthToken(): Promise<OAuth2Token> {
    const url = `${getAuthorityServiceUrl()}/portal/externalauthentication/v1/GetExternalToken`
    const authorization = getStoredAuthorization()
    const [type, key] = TOKEN_ITEM.replace("token://", "").split(":")
    const body = { type, key }

    return ajax({ url, body, authorization }).catch((e) => {
        console.log(url, body, e)
    })
}

type OAuth2Token = {
    tokenType: "Bearer"
    expiresIn: number
    accessToken: string
    refreshToken: string
    scope: string
}

const prevState: {
    [url: string]: any
} = {}

/**
 * we had some trouble with infityloops with createRequest, so we decided to set a counter and break the recursion on purpose
 */
const counter = {
    limit: 10,
    cycleCount: {} as { [url: string]: number },
    reset: (url: string) => {
        counter.cycleCount[url] = 0
    },
    increase: (url: string) => {
        if (!counter.cycleCount[url]) {
            counter.reset(url)
        }

        ++counter.cycleCount[url]
    },
    limitReached: (url: string) => {
        return counter.cycleCount[url] && counter.cycleCount[url] !== 0 && counter.cycleCount[url] === counter.limit
    },
}

const resetCounterOnSuccess = (url: string) => {
    delete prevState[url]
    counter.reset(url)
}

const infinityCounterReached = (currentBody: any, url: string) => {
    const bodyChanged = () => {
        if (!prevState[url] && !currentBody) {
            return false
        }

        if (equals(prevState[url], currentBody)) {
            return false
        }

        return true
    }

    if (bodyChanged()) {
        // console.warn(url, currentBody, prevState, counter)
        prevState[url] = currentBody
        counter.reset(url)
    }

    if (counter.limitReached(url)) {
        return true
    }
    counter.increase(url)
    return false
}

export async function createRequest(url: string, method: RequestMethod, body: any): Promise<Request<unknown>> {
    if (infinityCounterReached(body, url)) {
        return Promise.reject()
    }

    const item = sessionStorage.getItem(TOKEN_ITEM)
    if (!item) {
        if (tokenPromise) {
            await tokenPromise
            return createRequest(url, method, body)
        }
        tokenPromise = getAuthToken()
        const result = await tokenPromise
        sessionStorage.setItem(TOKEN_ITEM, JSON.stringify(result))

        return createRequest(url, method, body)
    }

    if (item == "undefined") {
        sessionStorage.removeItem(TOKEN_ITEM)
        return createRequest(url, method, body)
    }

    const token = JSON.parse(item)

    resetCounterOnSuccess(url)

    return {
        url,
        method,
        body,
        headers: {
            group: "demo",
            center: "topmotive",
        },
        authorization: {
            type: token.schema,
            credentials: token.token,
        },
    }
}
