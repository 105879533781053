import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { isDevtoolEnabled } from "@tm/utils"
import { createMainSlice, MainSlice } from "./main"

export type DrivemotiveStore = MainSlice

export const useDrivemotiveStore = create<DrivemotiveStore>()(
    devtools(
        (...a) => ({
            ...createMainSlice(...a),
        }),
        { name: "Drivemotive Store", enabled: isDevtoolEnabled() }
    )
)

export const { resetStore, mainActions, summaryActions, calculationActions } = useDrivemotiveStore.getState()
