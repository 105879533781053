import { useCallback, useRef, useState } from "react"
import { Box, Icon, Stack, Button, Tooltip, LinkButton, Paper, styled, Typography } from "@tm/components"
import { WorkTaskInfo } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import {
    AddCustomArticleItemsRequest,
    AddCustomPartListRequest,
    ArticleInfoType,
    NoteTypes,
    TmaEModule,
    SelectedPriceTypeMode,
    VatRate,
    ECounterType,
    PartsBundleParams,
} from "@tm/models"
import { TmaHelper, useSaveNote } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { createCustomPartObject } from "../../../../../data/mapper"
import { addCustomArticleItems } from "../../../../../data/repositories/custom-items/articles"
import { useBasketMemo } from "../../../../../hooks/useBasketMemo"
import { ArticleNoteRefProps, ControllableArticleNote } from "../../../../_shared/article-note"
import PartFields from "./part-fields"
import { CostEstimation } from "../../../../../data/model"

type Props = {
    workTask: WorkTaskInfo
    costEstimation?: CostEstimation
    vehicleId?: string
    customerId?: string
    partsDirectListUrl: string
    partsVehicleListUrl: string
    currencyCode: string
    onAddCustomPart(request: AddCustomPartListRequest): void
}

export default function AddPart(props: Props) {
    const { workTask, customerId, partsVehicleListUrl, partsDirectListUrl, vehicleId, currencyCode, costEstimation } = props
    const { costEstimationOptions } = costEstimation ?? {}
    const { translateText } = useLocalization()
    const { saveNote } = useSaveNote()
    const [articleNote, setArticleNote] = useState<string>()
    const articleNoteRef = useRef<ArticleNoteRefProps>(null)
    const basketMemo = useBasketMemo(workTask)

    const [customPartEditorOpen, setCustomPartEditorOpen] = useState<boolean>(false)
    const [openTooltip, setOpenTooltip] = useState<boolean>(false)

    function toggleCustomPartEditorOpen() {
        setCustomPartEditorOpen((prev) => !prev)
        setOpenTooltip(false)
    }

    async function handleAddCustomPartToBasketClick(
        saveAsCustomArticle: boolean,
        articleNumber: string | undefined,
        retailPrice: number | undefined,
        purchasePrice: number | undefined,
        description: string,
        quantityValue: number,
        rebate: number | undefined,
        surcharge: number | undefined,
        category?: number,
        vatRate?: VatRate
    ) {
        props.onAddCustomPart({
            workTaskId: workTask.id,
            customParts: [
                createCustomPartObject(
                    articleNumber,
                    retailPrice,
                    purchasePrice,
                    description,
                    quantityValue,
                    rebate,
                    surcharge,
                    vatRate,
                    basketMemo.position
                ),
            ],
            vehicleId,
            customerId,
            usePercentageValues: true,
            log: TmaHelper.AddCustomToBasket.GetUserInteractionLog("customArticle", TmaEModule.ARTICLE_COMPILATIONS),
            costEstimationOptions,
        })
        setCustomPartEditorOpen(false)

        if (saveAsCustomArticle) {
            const request: AddCustomArticleItemsRequest = {
                customArticleItemsToAdd: [
                    {
                        articleNumber,
                        description,
                        vatRate,
                        category,
                        retailPrice: !costEstimationOptions?.priceTypeMode ? retailPrice : undefined,
                        purchasePrice: costEstimationOptions?.priceTypeMode === SelectedPriceTypeMode.Purchase ? purchasePrice : undefined,
                        isDefaultForCategory: false,
                    },
                ],
                priceVatMode: costEstimationOptions?.priceVatMode,
            }
            const response = await addCustomArticleItems(request)

            if (articleNote && response) {
                await saveNote({
                    noteId: response.addedCustomArticleItems[0]?.id,
                    type: NoteTypes.CUSTOM_ARTICLE,
                    text: articleNote,
                })
            }
        }
    }

    const handleSaveAsCustomArticle = useCallback(() => {
        if (articleNoteRef.current) {
            articleNoteRef.current.show({
                onChange: (value) => {
                    setArticleNote(value)
                },
            })
        }
    }, [])

    const handleOpenTooltip = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateAddArticle)
        setOpenTooltip(!openTooltip)
    }, [openTooltip])

    if (customPartEditorOpen) {
        return (
            <Paper sx={{ width: "100%" }}>
                <PartFields
                    costEstimationOptions={costEstimationOptions}
                    defaultCurrencyCode={currencyCode}
                    surcharge={costEstimation?.customerDefaults?.partsSurcharge}
                    rebate={costEstimation?.customerDefaults?.partsRebate}
                    articleInfoType={ArticleInfoType.CustomArticle}
                    customPartVatRates={costEstimation?.defaultPartVatRates}
                    onClose={toggleCustomPartEditorOpen}
                    onAddCustomPart={handleAddCustomPartToBasketClick}
                    onSaveAsCustomArticle={handleSaveAsCustomArticle}
                />
                <Box py={1} px={1.5}>
                    <ControllableArticleNote ref={articleNoteRef} />
                </Box>
            </Paper>
        )
    }

    const { disableArticleListAccess } = Morpheus.getParams<PartsBundleParams>("parts")

    return (
        <Tooltip
            title={
                <Stack spacing={0.5}>
                    <Button startIcon={<Icon name="individual-article" />} variant="outlined" onClick={toggleCustomPartEditorOpen}>
                        {translateText(757)}
                    </Button>
                    {!disableArticleListAccess && (
                        <LinkButton startIcon={<Icon name="catalog" />} variant="outlined" to={vehicleId ? partsVehicleListUrl : partsDirectListUrl}>
                            {translateText(136)}
                        </LinkButton>
                    )}
                </Stack>
            }
            variant="light"
            disableHoverListener
            open={openTooltip}
            onClickAway={() => setOpenTooltip(false)}
        >
            <Button
                // remove title from button if used inside from Tooltip to prevent double title use MUI errors
                // correct way is to use a Popper and a Tooltiop outside around the Button
                aria-label={translateText(1507)}
                onClick={handleOpenTooltip}
                startIcon={<Icon name="plus" />}
                variant="contained"
                color="highlight"
            />
        </Tooltip>
    )
}
