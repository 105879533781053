import { render } from "react-dom"
import { styled, Box, LinearProgress, linearProgressClasses } from "@tm/components"
import { Headline } from "@tm/controls"


const Wrapper = styled(Box)(({ theme }) => ({
    flex: 1,
    [`& .${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: `${theme.colors?.primary}80`
    },
    [`& .${linearProgressClasses.bar1Determinate}`]: {
        backgroundColor: theme.colors?.primary
    }
}))

export const displayStepLoader = (currentStep: number, totalSteps: number) => {
    return (
        render(
            <Wrapper>
                <LinearProgress variant="determinate" value={calculateProgres(currentStep, totalSteps)} />
                <Headline>{`Loading step ${currentStep} / ${totalSteps}`}</Headline>
            </Wrapper>,
            document.querySelector("#step-loader")
        )
    )
}

const calculateProgres = (intialValue: number, totalValue: number): number => {
    return (intialValue / totalValue) * 100
}