import { useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Card, Switch, Button, TextField, Loader } from "@tm/controls"
import { ButtonKeyDefinition } from "@tm/utils"
import { useCreateCompilation } from "../../../../../data/hooks/useCompilations"
import { isWM } from "../../../../../utils"

type Props = {
    onClose(): void
    short?: boolean
}

export default function CompilationCreator(props: Props) {
    const { translateText } = useLocalization()
    const descriptionRef = useRef<TextField>(null)
    const confirmationRef = useRef<Button>(null)

    const { createCompilation, creatingCompilation } = useCreateCompilation()

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [isPublic, setIsPublic] = useState(true)

    const handleNameChange = (value: string | null) => {
        setName(value || "")
    }

    const handleNameKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            descriptionRef.current?.focus()
        }
    }

    const handleDescriptionChange = (value: string | null) => {
        setDescription(value || "")
    }

    const handleDescriptionKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            confirmationRef.current?.focus()
        }
    }

    const handleCloseButtonClick = () => {
        props.onClose()
    }

    const handleConfirmButtonClick = () => {
        createCompilation({ name, description, isPublic })
    }

    return (
        <Card className="compilation-creator">
            <div className="compilation-creator__fields">
                <TextField
                    maxLength={50}
                    onKeyDown={handleNameKeyDown}
                    className="compilation-creator__fields__name"
                    label={translateText(155)}
                    floatingLabel
                    layout={["holo"]}
                    onChange={handleNameChange}
                    showLength
                    value={name}
                />
                {!props.short && (
                    <TextField
                        maxLength={200}
                        onKeyDown={handleDescriptionKeyDown}
                        ref={descriptionRef}
                        className="compilation-creator__fields__description"
                        label={translateText(617)}
                        floatingLabel
                        layout={["holo"]}
                        onChange={handleDescriptionChange}
                        showLength
                        value={description}
                    />
                )}
                {isWM() && <Switch label={translateText(1254)} alignLabel="left" onChange={setIsPublic} status={isPublic} />}
            </div>
            <div className="compilation-creator__buttons">
                {creatingCompilation ? (
                    <Loader />
                ) : (
                    <>
                        <Button title={translateText(70)} onClick={handleCloseButtonClick} size="s" icon="close" />
                        <Button
                            ref={confirmationRef}
                            title={translateText(9)}
                            onClick={handleConfirmButtonClick}
                            size="s"
                            icon="check"
                            skin="success"
                            disabled={!name}
                        />
                    </>
                )}
            </div>
        </Card>
    )
}
