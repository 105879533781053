import { Icon as IconControl, Image } from "@tm/controls"
import { ErpIcon } from "@tm/models"

type Props = {
    icons: Array<ErpIcon> | undefined
    className: string
}

export default function ServiceIconComponent({ icons, className }: Props) {
    function getImage(icon: ErpIcon): JSX.Element | null {
        if (!icon) {
            return null
        }

        if (icon.useIconFromUrl) {
            return <Image url={icon.url} className={className} title={icon.description} />
        }

        if (icon.topmotiveId) {
            return <IconControl name={icon.topmotiveId} size="s" />
        }

        return null
    }

    if (!icons) {
        return null
    }
    return <>{icons.map(getImage)}</>
}
