import { Article } from "@tm/models"
import { useQuery } from "react-query"
import { showSupplierArticleByVehicle } from "@tm/data/vouchers/orderVoucher"
import { getProductGroupsIdsFromArticles } from "@tm/utils"
import { useVehicle } from "./useVehicle"

const SHOW_SUPPLIER_ARTICLE_BY_VEHICLE = "SHOW_SUPPLIER_ARTICLE_BY_VEHICLE"

export function usePreviouslyOrderedArticles(articles?: Article[]) {
    const vehicle = useVehicle()

    const productGroupIds = getProductGroupsIdsFromArticles(articles)

    const { data } = useQuery({
        queryKey: [SHOW_SUPPLIER_ARTICLE_BY_VEHICLE, vehicle?.id, productGroupIds],
        queryFn: () => showSupplierArticleByVehicle({ vehicleId: vehicle!.id, productGroupFilterIds: productGroupIds.join(",") }),
        enabled: !!productGroupIds.length && !!vehicle?.id,
    })

    return data?.orderedSupplierArticles
}
