import { GetMemoImportantNoteRequest, GetMemoImportantNoteResponse } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getServiceUrl } from '../params'

export function getMemoImportantNote(body: GetMemoImportantNoteRequest): Promise<GetMemoImportantNoteResponse | undefined> {
    const url = `${getServiceUrl()}/GetImportantNotes`
    const authorization = getStoredAuthorization()

    return ajax<GetMemoImportantNoteResponse | undefined>({ url, body, authorization, method: "GET" })
        .then(response => !!response?.label?.memoStr?.length ? response : undefined)

}