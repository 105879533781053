import { DateField } from "@tm/controls"
import { CarModelDetails } from "@tm/models"
import { useStyle } from "@tm/context-distribution"
import { useMemo } from "react"
import { FastCalculator } from "@tm/data"
import { constructionYearToDate } from "../../../data/helpers"

type Props = {
    item: FastCalculator.CalcInput
    modelDetails?: CarModelDetails
    onChangeCallBack?: (data: any) => void
}

export default function CalcInputDate({ item, modelDetails, onChangeCallBack }: Props) {
    const style = useMemo(() => getStyle(), [])

    const handleChange = (date: Date) => {
        const value = new Date(date.setHours(12))

        if (date.getTime() !== new Date(item.value).getTime()) {
            onChangeCallBack?.(date)
        }
    }

    const maxDateValidator = item.valueValidators.find((x) => x.type == FastCalculator.ECalcInputValueValidator.Max)?.value
    const openToDate =
        (item.type == FastCalculator.ECalcInputType.RegDate &&
            modelDetails?.constructionYearFrom &&
            constructionYearToDate(modelDetails.constructionYearFrom)) ||
        undefined

    return (
        <div className={style.hiddenTitle}>
            <DateField
                floatingLabel
                label={item.label}
                disabled={item.isDisabled}
                value={(item.value && new Date(String(item.value))) || undefined}
                maxDate={maxDateValidator && new Date(maxDateValidator)}
                openToDate={openToDate}
                ignoreOnSelect
                onChange={handleChange}
            />
        </div>
    )
}
function getStyle() {
    return useStyle({
        hiddenTitle: {
            $nest: {
                ".react-datepicker": {
                    display: "flex",
                    $nest: {
                        "&__current-month, &__navigation": {
                            display: "none",
                        },
                        "&__header": {
                            $nest: {
                                "&__dropdown": {
                                    height: "2em",
                                    display: "flex",
                                    flex: 1,
                                    fontSize: "1rem",
                                    alignItems: "center",
                                },
                            },
                        },
                        "&__year-dropdown-container, &__month-dropdown-container": {
                            width: "50%",
                            display: "flex",
                        },
                        "&__year-dropdown-container": {
                            justifyContent: "flex-start",
                        },
                        "&__month-dropdown-container": {
                            justifyContent: "flex-end",
                        },
                    },
                },
            },
        },
    })(CalcInputDate)
}
