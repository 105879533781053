import { EdsBundleParams } from "@tm/models"

let bundleParams: EdsBundleParams

export const version = {
    name: "eds",
    version: "2.0.0",
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function checkBundleParams(params: EdsBundleParams) {
    // if(!isDevtoolEnabled()){
    //     return
    // }
    // check params
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle ${version.name}`)
    }

    return bundleParams
}

export function initEdsBundle(params: EdsBundleParams) {
    bundleParams = params
    checkBundleParams(params)
    return bundleParams
}
