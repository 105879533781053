import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { replaceUrlTags, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { LinkTarget } from "@tm/models"
import { SubLinkMenuItem } from "./SubLinkMenuItem"
// i know, we need a better way but for now it works
import { getExternalCatalogUrl } from "../../../../../misc/src/data/repositories/url"
import { ExternalModuleMdmMenu } from "../../hooks/useMdmMenuEntrys"

type Props = {
    item: ExternalModuleMdmMenu
    onClick?(): void
}

export function ExternalModuleMenuEntry({ item, onClick }: Props) {
    const localization = useLocalization()
    const user = useUser()
    const { userContext } = user
    const workTaskContent = useWorkTask()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const [targetUrl, setTargetUrl] = useState<string>()

    // replace all tokens with next informations
    useEffect(() => {
        if (item.id) {
            switch (item.target) {
                case LinkTarget.NewWindow:
                    getExternalCatalogUrl(item.id, telesalesCustomerNo, userContext).then((url) => {
                        const replacedTags = replaceUrlTags(url, { languageId: localization.languageId }, user, workTaskContent)
                        setTargetUrl(replacedTags)
                    })
                    break
                default:
                    const id = encodeUniqueId(workTaskContent?.workTaskId || uniqueId())
                    const route = renderRoute(`/:workTaskId/externalId?id=${item?.id}`, { workTaskId: id })
                    setTargetUrl(route)
                    break
            }
        }
    }, [item.id, item.target, telesalesCustomerNo, userContext, localization.languageId, user, workTaskContent])

    return (
        <SubLinkMenuItem onClick={onClick} to={targetUrl || ""}>
            {" "}
            {item.description}{" "}
        </SubLinkMenuItem>
    )
}
