import { ConfigQueryParams, useOffersUrl } from "../../shared"

type Props = {
    params: ConfigQueryParams
}

export function OffersFrame({ params }: Props) {
    const url = useOffersUrl(params)

    if (!url) {
        return null
    }

    return <iframe title="tk-offers widget-frame" width="100%" height="100%" src={url} className="widget-frame__preview-iframe" />
}
