import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import deepAssign from "deep-assign"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()
    const component = {
        active: {
            back: colors.highlight,
            fore: color(colors.light).setAlpha(opacity.primary).toRgbString(),
        },
        border: {
            radius: "3px",
        },
    }

    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&--selected": {
                transform: "unset",
                $nest: {
                    "&:hover .icon, &:hover .icon:hover": {
                        fill: `unset`,
                    },
                },
            },
            "&:hover:not(&--selected)": {
                $nest: {
                    ".icon": {
                        fill: "unset",
                    },
                },
            },
            "&--selected, &:active": {
                transform: "unset",
                background: component.active.back,
                backgroundColor: component.active.back,
                color: component.active.fore,
                borderRadius: component.border.radius,
                borderLeftColor: "transparent",
            },
            "&--selected .icon, &:active .icon": {
                // fill: "unset",
            },
            "&__close": {
                $nest: {
                    ".icon": {
                        fill: `${color(component.active.fore).setAlpha(0.87).toRgbString()} !important`,
                    },
                    "&:hover, &:active": {
                        background: "none",
                    },
                    "&:hover .icon": {
                        fill: `${colors.light} !important`,
                    },
                },
            },
        },
    }

    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}

export function getOverflowMenuStyles(): NestedCSSProperties {
    const { colors, opacity, pageNavigation } = getStyleTheme()

    const moreComponent = {
        back: "transparent",
        fore: pageNavigation && pageNavigation.itemColor ? pageNavigation.itemColor : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        hover: {
            back: color(colors.light).setAlpha(0.15).toRgbString(),
            fore: color(colors.light).setAlpha(opacity.primary).toRgbString(),
        },
        border: {
            color:
                pageNavigation && pageNavigation.itemBorderLeftColor
                    ? pageNavigation.itemBorderLeftColor
                    : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        },
    }

    const styles: NestedCSSProperties = {
        position: "relative",
        flex: 1,
        display: "flex",
        alignSelf: "stretch",
        height: "4em",
        marginRight: "0.5em",
        $nest: {
            "&__main": {
                width: "100%",
                overflow: "hidden",
                position: "relative",
                display: "flex",
                alignItems: "center",
                $nest: {
                    "&__content": {
                        position: "absolute",
                        display: "flex",
                        height: "100%",
                        top: 0,
                        alignItems: "center",
                    },
                },
            },
            "&__more": {
                // borderLeft: "2px solid transparent",
                // borderLeftColor: "#dedfe0",
                display: "flex",
                position: "absolute",
                height: "100%",
                alignItems: "center",
                $nest: {
                    "&__content": {
                        padding: 0,
                        overflow: "auto",
                        maxHeight: "25em",
                    },
                    ".close-all": {
                        width: "100%",
                        borderTopLeftRadius: 0,
                        borderTopRightRadius: 0,
                    },
                },
            },
            "&__btn": {
                height: "3rem",
                background: "none",
                $nest: {
                    ".icon": {
                        opacity: 1,
                        fill: `${moreComponent.fore} !important`,
                    },
                    "&:hover": {
                        // background: moreComponent.hover.back,
                        background: "transparent",
                        $nest: {
                            ".icon": {
                                // fill: `${moreComponent.hover.fore} !important`
                                fill: `${colors.light} !important`,
                            },
                        },
                    },
                    "&:focus": {
                        // background: colors.highlight,
                        background: "transparent",
                        color: "inherit",
                        $nest: {
                            ".icon": {
                                fill: `${colors.light} !important`,
                                // fill: `${colors.highlight} !important`
                            },
                        },
                    },
                },
            },
            "&__popover": {
                $nest: {
                    ".popover__inner": {
                        padding: 0,
                    },
                },
            },
        },
    }
    return deepAssign("getOverflowMenuStyles", styles)
}
