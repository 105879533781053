import { memo, useCallback, useMemo } from "react"
import { Box, DefaultIcon as MuiIcon, styled } from "@tm/components"
import { Article, NoteTypes, Vehicle } from "@tm/models"
import { useLoadNotes } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Icon } from "@tm/controls"

import { NoteItem } from "./note-item"
import { mapArticleAndVehicleToNoteIdentifier, mapArticleAndVehicleToNoteIdentifierList, mapArticleToNoteIdentifier } from "./helper/mapper"

interface IProps {
    article: Article | string
    isActive: boolean
    onClose?: () => void
    noBackground?: boolean
    vehicle?: Vehicle
}

const ClickableIcon = styled(MuiIcon)(({ theme }) => ({
    cursor: "pointer",
    background: theme.palette.primary.main,
    borderRadius: "3px",

    svg: {
        fill: theme.palette.common.white,
    },
}))

export const ArticleNote = memo<IProps>(({ article, isActive, vehicle, noBackground, onClose }) => {
    const { translateText } = useLocalization()
    const { isLoading, articleNotes } = useLoadNotes(mapArticleAndVehicleToNoteIdentifierList(article, vehicle))

    const isCustomArticle = useMemo((): boolean => typeof article === "string", [article])

    const getNoteId = useCallback(
        (type: NoteTypes): string => {
            // ArticleNoteTypes.CUSTOM_ARTICLE
            if (typeof article === "string") {
                return article
            }

            switch (type) {
                case NoteTypes.VEHICLE_ARTICLE: {
                    return articleNotes.find((note) => note.type === type)?.noteId || mapArticleAndVehicleToNoteIdentifier(article, vehicle!)
                }
                default: {
                    return articleNotes.find((note) => note.type === type)?.noteId || mapArticleToNoteIdentifier(article)
                }
            }
        },
        [article, articleNotes, vehicle]
    )

    if (!isActive) {
        return null
    }

    return (
        <Box px={1} pt={1} mb={1} ml={1} className={`${!noBackground && "article-list__panel"}`}>
            <NoteItem
                label={translateText(12874)}
                noteId={getNoteId(isCustomArticle ? NoteTypes.CUSTOM_ARTICLE : NoteTypes.ARTICLE)}
                note={articleNotes.find((note) => note.type === NoteTypes.ARTICLE)}
                placeholder=""
                noteType={isCustomArticle ? NoteTypes.CUSTOM_ARTICLE : NoteTypes.ARTICLE}
                isLoading={isLoading}
                itemIcon="notes"
            />

            {vehicle && (
                <NoteItem
                    label={translateText(12875)}
                    noteId={getNoteId(NoteTypes.VEHICLE_ARTICLE)}
                    note={articleNotes.find((note) => note.type === NoteTypes.VEHICLE_ARTICLE)}
                    placeholder=""
                    noteType={NoteTypes.VEHICLE_ARTICLE}
                    isLoading={isLoading}
                    itemIcon="vehicle-note"
                />
            )}
            {onClose && (
                <Box textAlign="center" pt={1}>
                    <ClickableIcon onClick={onClose}>
                        <Icon name="up" size="s" />
                    </ClickableIcon>
                </Box>
            )}
        </Box>
    )
})
