import { Suspense } from "react"
import { IMicros } from "@tm/models"
import { Typography } from "@tm/components"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-additional-information"]

function ErpInfoAdditionalInformationComponent({ data, maxLines, variant }: Props) {
    const { loading, erpInfo } = useErpInfo(data, "list")

    if (loading || !erpInfo?.additionalInformation) {
        return null
    }

    return (
        <Typography sx={{ display: "block" }} variant={variant} maxLines={maxLines}>
            {erpInfo.additionalInformation}
        </Typography>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <Suspense fallback={null}>
                <ErpInfoAdditionalInformationComponent {...props} />
            </Suspense>
        </ErpWrapper>
    )
}
