import { useRef, RefObject, useEffect, Suspense, useMemo } from "react"
import { Overwrite, useDefaultErpSystem } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { ErpInformation } from "@tm/models"
import { Box, Loader } from "@tm/components"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useParams } from "react-router"
import { StockInfoState, IActions, Actions } from "./business"
import WarehousesComponent from "./components/warehouses"
import PricesComponent from "./components/prices"
import AdditionalInfosComponent from "./components/additional-infos"
import MemosComponent from "./components/memos"
import { ScrollToPossibilities } from "../../business"
import MemoTooltipIcon from "./components/memo-tooltip-icon"
import TeccomComponent from "../../_shared/teccom/TeccomComponent"
import { AdvancedDeliveryRequestTableData } from "../../_shared/teccom/AdvancedDeliveryRequestTable"
import PriceTable from "../../_shared/price-table"
import { ErpWrapper } from "../../_shared/ErpWrapper"

type Props = {
    state: StockInfoState
    actions: IActions
}

type RouteProps = {
    scrollTo?: ScrollToPossibilities
}

function StockInfoComponent(props: {
    scrollTo?: ScrollToPossibilities
    state: Overwrite<StockInfoState, { erpInfo: ErpInformation }>
    loadTeccom(quantity: number): void
}) {
    const { erpInfo, loading, request, teccomErpInfo, teccomLoading, teccomLoaded } = props.state
    const warehousesRef = useRef<HTMLDivElement>(null)
    const teccomRef = useRef<HTMLDivElement>(null)
    const pricesRef = useRef<HTMLDivElement>(null)
    const infosRef = useRef<HTMLDivElement>(null)
    const memoTooltipIconRef = useRef<HTMLDivElement>(null)
    const { translate } = useLocalization()

    const teccomTableData: AdvancedDeliveryRequestTableData = useMemo(() => {
        // is needed for Birner and Carrat, they can have "special infos" at the shipmentModes
        const shipmentModes = teccomErpInfo?.specialProcurementErpInformation?.orderOptions.shipmentModes?.shipmentModes
        const shipmentModesInfoText = shipmentModes?.length ? shipmentModes[0].description : ""

        return {
            article: {
                requestedQuantity: teccomErpInfo?.quantity?.requestedValue,
                availability: teccomErpInfo?.specialProcurementErpInformation?.availability || erpInfo.availability,
                confirmedQuantity: teccomErpInfo?.quantity?.confirmedValue,
                expectedDelivery:
                    teccomErpInfo?.specialProcurementErpInformation?.expectedDelivery &&
                    new Date(teccomErpInfo?.specialProcurementErpInformation?.expectedDelivery),
                tourOrderAcceptanceTimeLimit:
                    teccomErpInfo?.specialProcurementErpInformation?.tourOrderAcceptanceTimeLimit &&
                    new Date(teccomErpInfo?.specialProcurementErpInformation?.tourOrderAcceptanceTimeLimit),

                // TODO: This mapping should be improved
                shortDescription:
                    teccomErpInfo?.specialProcurementErpInformation?.message ||
                    shipmentModesInfoText ||
                    teccomErpInfo?.specialProcurementErpInformation?.availability.description,
                additionalInformation: teccomErpInfo?.specialProcurementErpInformation?.availability.shortDescription,
                message: teccomErpInfo?.additionalInformation,
            },
            shipments: teccomErpInfo?.specialProcurementErpInformation?.orderOptions?.shipmentModes?.shipmentModes,
            refreshErp: teccomErpInfo?.specialProcurementErpInformation?.orderOptions?.shipmentModes?.updateErpInformationOnChange,
        }
    }, [teccomErpInfo, erpInfo])

    useEffect(() => {
        if (props.scrollTo) {
            let ref: RefObject<HTMLDivElement> | undefined

            switch (props.scrollTo) {
                case "warehouses":
                    ref = warehousesRef
                    break
                case "teccom":
                    ref = teccomRef
                    break
                case "prices":
                    ref = pricesRef
                    break
                case "infos":
                    ref = infosRef
                    break
                case "memo-tooltip-icon":
                    ref = memoTooltipIconRef
                    break
                default:
                    break
            }

            if (ref?.current) {
                ref.current.scrollIntoView(false)
            }
        }

        if (props.scrollTo === "teccom" && erpInfo.isTeccomRequestAvailable && !loading) {
            props.loadTeccom(request?.quantityValue || 1)
        }
        // Should only run when props.scrollTo is changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.scrollTo])

    return (
        <div className="tk-erp stock-info">
            <MemosComponent memos={erpInfo.memos} />
            <WarehousesComponent warehouses={erpInfo.warehouses} wrapperRef={warehousesRef} requestedQuantity={erpInfo.quantity?.requestedValue} />
            {!props?.state.erpInfo?.useSpecialProcurement && props.state.erpInfo.isTeccomRequestAvailable && (
                <PanelSection className="stock-info__teccom" title={translate(1079)} size="s">
                    <TeccomComponent
                        articleQuantity={erpInfo.quantity?.requestedValue || 1}
                        loadTeccom={props.loadTeccom}
                        wrapperRef={teccomRef}
                        tableData={teccomTableData}
                        loading={teccomLoading}
                        loaded={teccomLoaded}
                        hideShipmentOptions
                    />
                    <Box width="50%">
                        <PriceTable prices={teccomErpInfo?.prices} isReplacementPart={false} />
                    </Box>
                </PanelSection>
            )}
            <PricesComponent
                prices={erpInfo.prices}
                graduatedPrices={erpInfo.graduatedPrices}
                isReplacementPart={erpInfo.isReplacementPart}
                wrapperRef={pricesRef}
            />
            <AdditionalInfosComponent
                additionalInformation={erpInfo.additionalInformation}
                additionalInformationExtended={erpInfo.additionalInformationExtended}
                memos={erpInfo.memos}
                isReplacementPart={erpInfo.isReplacementPart}
                wrapperRef={infosRef}
            />
            <MemoTooltipIcon memos={erpInfo.memos} wrapperRef={memoTooltipIconRef} />
        </div>
    )
}

function StockInfoWrapper({ state, actions }: Props) {
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { erpSystemConfig } = useDefaultErpSystem()
    const matchParams = useParams<RouteProps>()
    const { erpInfo, loading } = state

    if (loading) {
        return <Loader />
    }

    if (!erpInfo) {
        return null
    }

    function loadTeccom(quantity: number) {
        actions.loadTeccom(quantity, telesalesCustomerNo, erpSystemConfig?.id)
    }

    return <StockInfoComponent state={{ ...state, erpInfo }} scrollTo={matchParams.scrollTo} loadTeccom={loadTeccom} />
}

function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <StockInfoWrapper {...props} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
