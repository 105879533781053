import { CisCustomerResponse, CisTour } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { Box, Icon, Typography, Stack, Divider } from "@tm/components"
import { useLocalization } from "@tm/localization"
import NextTour from "../../_shared/NextTour"

type Props = {
    tours?: Array<CisTour>
    customer?: CisCustomerResponse
    toursLoading?: boolean
    customerLoading?: boolean
    showVertical?: boolean
}

export default function GeneralInformationComponent({ tours, customer, customerLoading, toursLoading, showVertical }: Props) {
    const { userContext } = useUser() ?? {}
    const { translateText } = useLocalization()

    function renderRepairShopData() {
        if (!userContext?.generalAddress || !userContext?.generalContact) {
            return null
        }

        const { generalAddress, generalContact } = userContext

        if (showVertical) {
            return (
                <Stack direction="column" alignItems="center" mt={1} spacing={1}>
                    {generalContact.company && (
                        <Typography variant="h3" fontWeight="bold">
                            {generalContact.company}
                        </Typography>
                    )}
                    <Typography variant="body2">
                        {`${generalAddress.street},`} {generalAddress.zip} {generalAddress.city}
                    </Typography>
                    {customer && (
                        <Stack direction="row" spacing={0.5}>
                            <Typography variant="body2">{`${translateText(102)}:`}</Typography>
                            <Typography variant="body2" fontWeight="bold">
                                {customer.customerNo}
                            </Typography>
                        </Stack>
                    )}
                </Stack>
            )
        }

        return (
            <Stack width="350px" alignItems="center">
                <Icon height="50px" width="50px" name="info" />
                <Box mt={1}>
                    {generalContact.company && <Typography variant="h3">{generalContact.company}</Typography>}
                    {generalContact.name && <Typography variant="body3">Inh. {generalContact.name}</Typography>}
                </Box>
                <Box display="flex" width="100%" mt={1} alignItems="center">
                    <Icon name="user" height="30px" width="30px" />
                    <Stack>
                        <Typography>{generalAddress.street}</Typography>
                        <Typography>
                            {generalAddress.zip} {generalAddress.city}
                        </Typography>
                    </Stack>
                </Box>
            </Stack>
        )
    }

    return (
        <Stack
            direction={showVertical ? "column" : "row"}
            divider={
                !showVertical && userContext?.generalAddress && userContext?.generalContact && tours ? (
                    <Divider sx={{ height: "auto" }} />
                ) : (
                    tours && <Divider sx={{ margin: "0 2em" }} />
                )
            }
            spacing={1}
        >
            {renderRepairShopData()}
            <Box display="flex" alignItems="center" flexDirection="column" flex={1}>
                <NextTour customer={customer} tours={tours} customerLoading={customerLoading} toursLoading={toursLoading} />
            </Box>
        </Stack>
    )
}
