import { Article, IMicros } from "@tm/models"
import { useEffect, useMemo, useState } from "react"
import { styled, NumberSelect, Box } from "@tm/components"

import { Decimal } from "decimal.js-light"

type Props = Omit<IMicros["basket"]["add-to-basket"], "data"> & {
    article: Article
    minQuantity?: number
    maxQuantity?: number
    division?: number
    onQuantityChange(article: Article, newQuantity: number): void
}

const NumberSelectWrapper = styled(Box)(() => ({
    marginRight: ".25em",
}))

export function QuantityField(props: Props) {
    const { hideQuantityField, minQuantity, maxQuantity, article, onQuantityChange, disabled } = props

    const division = props.division || 1

    const [minAmount, setMinAmount] = useState(minQuantity || 1)

    useEffect(() => {
        if (minQuantity) {
            const dMinQuantiy = new Decimal(minQuantity)
            if (minQuantity > minAmount) {
                setMinAmount(dMinQuantiy.add(dMinQuantiy.modulo(division)).toNumber())
            }
            if (minQuantity > article.quantity) {
                onQuantityChange(article, dMinQuantiy.add(dMinQuantiy.modulo(division)).toNumber())
            }
        }
        // Edge Case, if Shippmentmode is change, somtimes the package / division can change
        else if (division < minAmount) {
            setMinAmount(division)
        }
        if (division > minAmount) {
            setMinAmount(division)
        }
        if (division > article.quantity) {
            onQuantityChange(article, division)
        }
    }, [minQuantity, article, division, onQuantityChange, minAmount])

    const maxAmount = useMemo(() => {
        let result = maxQuantity || 9999
        if (division > 1) {
            result -= result % division
        }
        return result
    }, [maxQuantity, division])

    function handleQuantityChange(amount: number) {
        const rest = new Decimal(amount).modulo(division).toNumber()
        const quantity = rest ? new Decimal(amount).add(division).sub(rest).toNumber() : amount
        onQuantityChange(article, quantity)
    }

    if (hideQuantityField) {
        return null
    }

    return (
        <NumberSelectWrapper>
            <NumberSelect
                classNameWrapper="amount-field"
                value={article.quantity}
                onValueChange={handleQuantityChange}
                minAmount={minAmount}
                maxAmount={maxAmount}
                steps={division || undefined}
                disabled={disabled}
            />
        </NumberSelectWrapper>
    )
}
