import { useState, useEffect, useCallback } from "react"
import { Button, Modal, TextField, PanelSection, Headline } from "@tm/controls"
import { connectComponent } from "@tm/morpheus"
import { useWorkTask, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Vehicle, UserContext } from "@tm/models"
import { validateField, ModelState } from "@tm/utils"
import { RequestState } from "./business"
import { handleSendMail } from "./business/mail-helper"
import getVehicle from "../../data/helpers/getVehicle"

type Props = {
    state: RequestState
}

function RequestRepairTimesComponent(props: Props) {
    const { translateText } = useLocalization()
    const context = useUser()?.userContext
    const workTask = useWorkTask()?.workTask
    const vehicle = getVehicle(workTask?.vehicle, context?.system.systemType)
    const userContext = useUser()?.userContext

    const [isModalInvoked, setIsModalInvoked] = useState(false)
    const [vehicleState, setVehicleState] = useState(vehicle)
    const [userContextState, setUserContextState] = useState(userContext)
    const [text, setText] = useState("")

    useEffect(() => {
        setVehicleState(vehicle)
    }, [vehicle])

    useEffect(() => {
        setUserContextState(userContext)
    }, [userContext])

    const invokeModal = useCallback(() => {
        setIsModalInvoked((curr) => !curr)
    }, [setIsModalInvoked])

    const handleClick = () => {
        handleSendMail(translateText(1705), translateText(1706), props.state.breadcrumbs, text, vehicleState, userContextState)
    }

    const getNamePath = () => {
        if (userContext?.generalContact?.company) {
            return ["generalContact", "company"]
        }
        return ["generalContact", "name"]
    }

    const validateVehicle = (vehicleState?: Vehicle): ModelState => {
        if (!vehicleState) {
            return { "": [""] }
        }

        return {
            modelSeries: validateField(vehicleState.modelSeries).min(3, translateText(289)).messages,
            tecDocTypeId: validateField(vehicleState.tecDocTypeId ? vehicleState.tecDocTypeId.toString() : "").min(3, translateText(289)).messages,
        }
    }

    const validateUserContext = (userContextState?: UserContext): ModelState => {
        if (!userContextState || !userContextState.generalContact) {
            return { "": [""] }
        }

        const name = getNamePath()

        let contextName
        if (name && name[1] === "company") {
            contextName = userContextState.generalContact.company
        } else {
            contextName = userContextState.generalContact.name
        }

        return {
            email: validateField(userContextState.generalContact.email).email(translateText(290)).min(1, translateText(289)).messages,
            phone: validateField(userContextState.generalContact.phone).min(3, translateText(289)).messages,
            companyOrName: validateField(contextName).min(3, translateText(289)).messages,
        }
    }

    const renderRequestForm = () => {
        const modelVehicleState = validateVehicle(vehicleState)
        let vehicleValid = true
        Object.keys(modelVehicleState).forEach((key) => {
            const value = modelVehicleState[key]
            if (value && value.length) {
                vehicleValid = false
            }
        })

        const modelUserContextState = validateUserContext(userContextState)
        let userContextValid = true
        Object.keys(modelUserContextState).forEach((key) => {
            const value = modelUserContextState[key]
            if (value && value.length) {
                userContextValid = false
            }
        })

        const textValid = !!text && text.length > 4
        const valid = vehicleValid && userContextValid && textValid

        return (
            <div className="layoutwrapper">
                <div className="tk-repair-times__repair-times-request">
                    <div className="button-wrapper">
                        <Headline>{translateText(1701)}</Headline>
                        <Button disabled={!valid} icon="available" skin="success" onClick={handleClick}>
                            {translateText(642)}
                        </Button>
                    </div>
                    <PanelSection className="panel--no-dropshadow" size="s" title={`${translateText(236)} (*)`}>
                        <ul>
                            <li>
                                <TextField
                                    label={`${translateText(99)} (*)`}
                                    floatingLabel
                                    model={vehicleState}
                                    path={["modelSeries"]}
                                    onChange={setVehicleState}
                                />
                            </li>
                            <li>
                                <TextField
                                    ignoreLocalFormatting
                                    label={`${translateText(123)} (*)`}
                                    floatingLabel
                                    model={vehicleState}
                                    path={["tecDocTypeId"]}
                                    onChange={setVehicleState}
                                />
                            </li>
                            <li>
                                <TextField label={translateText(101)} floatingLabel model={vehicleState} path={["vin"]} onChange={setVehicleState} />
                            </li>
                            <li>
                                <TextField
                                    label={translateText(105)}
                                    floatingLabel
                                    model={vehicleState}
                                    path={["engineCode"]}
                                    onChange={setVehicleState}
                                />
                            </li>
                            <li>
                                <TextField
                                    label={translateText(124)}
                                    floatingLabel
                                    formatter={(value) => {
                                        if (value) {
                                            // assuming that this is a date cast as locale string by textfield
                                            const splitDate = value.split(",")
                                            if (splitDate.length > 1) {
                                                return splitDate[0]
                                            }
                                        }
                                        return value
                                    }}
                                    model={vehicleState}
                                    path={["initialRegistration"]}
                                />
                            </li>
                        </ul>
                    </PanelSection>
                    <PanelSection className="panel--no-dropshadow" size="s" title={`${translateText(1703)} (*)`}>
                        <ul>
                            <li>
                                <TextField
                                    label={`${translateText(109)} (*)`}
                                    floatingLabel
                                    model={userContextState}
                                    path={["generalContact", "email"]}
                                    onChange={setUserContextState}
                                />
                            </li>
                            <li>
                                <TextField
                                    label={`${translateText(155)} (*)`}
                                    floatingLabel
                                    model={userContextState}
                                    path={getNamePath()}
                                    onChange={setUserContextState}
                                />
                            </li>
                            <li>
                                <TextField
                                    label={`${translateText(110)} (*)`}
                                    floatingLabel
                                    model={userContextState}
                                    path={["generalContact", "phone"]}
                                    onChange={setUserContextState}
                                />
                            </li>
                        </ul>
                    </PanelSection>
                    <PanelSection className="panel--stretch panel--no-dropshadow" size="s" title={`${translateText(1702)} (*)`}>
                        <TextField onChange={setText} multiline size="xl" type="text" value={text} />
                    </PanelSection>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Button onClick={invokeModal}>{translateText(1701)}</Button>
            {isModalInvoked && <Modal onClose={invokeModal}>{renderRequestForm()}</Modal>}
        </div>
    )
}

export default connectComponent({}, RequestRepairTimesComponent)
