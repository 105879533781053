export default function Logo() {
    return (
        <svg className="icon icon--l startpage-button__icon" width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
            <g id="ICONS/nextid_black" stroke="none" strokeWidth="1" opacity="0.742633929">
                <g id="Page-1" transform="translate(-0.313936, 1.824145)">
                    <path
                        d="M19.6979347,20.9358252 L9.39561626,12.3116866 L7.7451832,12.8292497 L16.0511426,28.1867345 C16.266319,28.5844183 16.7392589,28.7911564 17.192026,28.6863517 L26.603006,26.5098586 L19.6979347,20.9358252 Z"
                        className="primary"
                    />
                    <path
                        d="M7.73711408,12.8317622 L7.74533263,12.8296086 L9.39576569,12.3120455 L9.41519134,12.3055849 L22.2981326,8.2670129 L22.6358401,6.63392521 L4.58642317,5.63325522 C4.12095473,5.60741295 3.70180898,5.8902422 3.57479512,6.31592176 L0.999402288,14.9436496 L7.72665412,12.8353514 L7.73711408,12.8317622 Z"
                        className="primary"
                    />
                    <path
                        d="M23.0360084,19.0613277 L30.6792544,7.77041067 C30.9287992,7.40215837 30.879488,6.91546235 30.5589648,6.59315186 L24.0080381,-0.000215352222 L22.6362884,6.63406878 L22.2985809,8.26715646 L19.6977853,20.9356099 L21.0359137,22.0159602 L23.0360084,19.0613277 Z"
                        className="primary"
                    />
                    <polygon className="secondary" points="23.0360084 19.0613277 21.0359137 22.0159602 26.6028565 26.5096432" />
                    <polygon className="secondary" points="24.0078139 0 18.8420854 6.42323895 20.8010875 6.53235074 22.6360642 6.63356629" />
                    <polygon className="secondary" points="7.74510849 12.8292497 0.999925286 14.9432907 9.47548557 16.0293837" />
                </g>
            </g>
        </svg>
    )
}
