import { FastService } from "@tm/data"
import { useMutation } from "react-query"
import { useCallback } from "react"

export function useFileDelete() {
    const { isLoading, mutateAsync } = useMutation(FastService.deleteFile)
    const deleteFile = useCallback(
        (request: FastService.DeleteFileRequest) => {
            return mutateAsync(request)
        },
        [mutateAsync]
    )
    return { isLoading, deleteFile }
}
