import { FastCalculator } from "@tm/data"

export function getCalcStateLabel(item: FastCalculator.ECalcInputType, currency: string, translateText: (id: number) => string) {
    // TODO moveto helpers
    switch (item) {
        case FastCalculator.ECalcInputType.GlobalLabourRate:
            return translateText(622)
        case FastCalculator.ECalcInputType.ConsumableQuantity:
            return translateText(89)
        case FastCalculator.ECalcInputType.ConsumableLabel:
            return translateText(118)
        case FastCalculator.ECalcInputType.ConsumablePrice:
        case FastCalculator.ECalcInputType.GenArtPrice:
        case FastCalculator.ECalcInputType.RepairTimePrice:
        case FastCalculator.ECalcInputType.OeArticlePrice:
        case FastCalculator.ECalcInputType.ArticlePrice:
            return currency ?? "EUR"
    }
}
