import { isEqual, sortBy, uniq } from "lodash"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { notUndefinedOrNull } from "@tm/utils"
import { FittingPosition, ListFilter } from "@tm/models"
import { SelectedCriteriasState } from "../../../../_shared/uni-search-top-products/state/SelectedCriteriasState"
import { mapCriterionFilter, mapDataSupplierFilterAndShowOnTop, mapListFilterToQuery, mapProductGroupFilter } from "../../../helpers"
import { FilterActions, FiltersData, ListFilterGroup, UniversalPartsListParams } from "../../../models"
import { useFiltersByNode } from "./useFiltersByNode"
import { useFiltersByQuery } from "./useFiltersByQuery"
import {
    ExtendedAssortmentAtom,
    FixedAttributeFilterQueriesAtom,
    SelectedAttributeFilterQueriesAtom,
    SelectedProductGroupIdsAtom,
    SelectedSupplierIdsAtom,
} from "../../../states"
import { useFilterStorageKey } from "../../../hooks/useFilterStorageKey"
import { useFiltersByProductGroups } from "./useFiltersByProductGroups"
import { ProductGroupFilter } from "../../../../../data/model/productGroupFilters"

export const FILTERS_QUERY = "ARTICLE_UNI_FILTER_QUERY"
export const CHECK_DELAY = 500

export function useFilters(params: UniversalPartsListParams, isEnabled: boolean): FiltersData & FilterActions {
    const { startParams, setFittingPosition } = params
    const storageKey = useFilterStorageKey(startParams)

    const [showOnlyAvailable, setShowOnlyAvailable] = useState(!!params.showAvailable)
    const [showOnlyAvailableSecondary, setShowOnlyAvailableSecondary] = useState(!!params.showAvailableSecondary)
    const [extendedAssortmentEnabledByUser, setExtendedAssortmentEnabledByUser] = useState(!!params.extendedAssortment)
    const [extendedAssortmentEnabled, setExtendedAssortmentEnabled] = useRecoilState(ExtendedAssortmentAtom(storageKey))
    const [selectedProductGroupIds, setSelectedProductGroupIds] = useRecoilState(SelectedProductGroupIdsAtom(storageKey))
    const [selectedSupplierIds, setSelectedSupplierIds] = useRecoilState(SelectedSupplierIdsAtom(storageKey))
    const [selectedAttributeFilterQueries, setSelectedAttributeFilterQueries] = useRecoilState(SelectedAttributeFilterQueriesAtom(storageKey))
    const fixedAttributeFilterQueries = useRecoilValue(FixedAttributeFilterQueriesAtom(storageKey))
    const filtersByQuery = useFiltersByQuery(params, isEnabled && startParams.type === "unisearch")
    const filtersByNode = useFiltersByNode(params, isEnabled && startParams.type === "uninode")
    const filtersByProductGroups = useFiltersByProductGroups(params, isEnabled && startParams.type === "uniproductgroups")
    const [extendedAssortmentEnabledForced, setExtendedAssortmentEnabledForced] = useState(false)
    const [selectedCriteria, setSelectedCriterias] = useRecoilState(SelectedCriteriasState)

    const {
        data: loadedFilters,
        isLoading,
        isRefetching,
        isSuccess,
        remove: clearLoadedFilters,
    } = useMemo(() => {
        switch (startParams.type) {
            case "uninode":
                return filtersByNode
            case "uniproductgroups":
                return filtersByProductGroups
            case "unisearch":
            default:
                return filtersByQuery
        }
    }, [filtersByNode, filtersByProductGroups, filtersByQuery, startParams.type])

    const mapFilterSelectable = useCallback(
        (filter: ListFilter) => {
            if (!!filter.query && fixedAttributeFilterQueries.includes(filter.query)) {
                filter.isSelectable = false
            }
            return filter
        },
        [fixedAttributeFilterQueries]
    )

    useEffect(
        function setExtendedAssortmentEnabledForceValue() {
            if (extendedAssortmentEnabledForced) {
                return
            }
            if (!loadedFilters) {
                setExtendedAssortmentEnabledForced(false)
                return
            }
            const anyExtendedProductGroupsIsSelected =
                loadedFilters.productGroupFilters.some((x) => !x.hasTopPrioritySuppliers && selectedProductGroupIds.includes(x.id)) ||
                (!loadedFilters.productGroupFilters.some((x) => x.hasTopPrioritySuppliers) && !selectedProductGroupIds.length)
            const anyExtendedSupplierIsSelected = loadedFilters.supplierFilters.some((x) => !x.isTopPriority && selectedSupplierIds.includes(x.id))
            const noResult = isSuccess && !loadedFilters.productGroupFilters.length && !loadedFilters.supplierFilters.length // TODO: clarify if extended assortment should really be enabled automatically in this case (example: no filter results for current search query)

            setExtendedAssortmentEnabledForced(anyExtendedProductGroupsIsSelected || anyExtendedSupplierIsSelected || noResult)
        },
        [loadedFilters, selectedSupplierIds, selectedProductGroupIds, isSuccess]
    )

    const productGroupFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.productGroupFilters) {
            return []
        }
        return loadedFilters.productGroupFilters.map((filter) => [mapProductGroupFilter(filter), selectedProductGroupIds.includes(filter.id)])
    }, [selectedProductGroupIds, loadedFilters?.productGroupFilters])

    const dataSupplierFilters = useMemo<[ListFilter, boolean][]>(() => {
        if (!loadedFilters?.supplierFilters) {
            return []
        }
        return loadedFilters.supplierFilters.map((filter) => [mapDataSupplierFilterAndShowOnTop(filter), selectedSupplierIds.includes(filter.id)])
    }, [selectedSupplierIds, loadedFilters?.supplierFilters])

    const groupedAttributeFilters = useMemo<ListFilterGroup[]>(() => {
        if (!loadedFilters?.criterionFilterGroups) {
            return []
        }
        // TODO : ADD FILTERSCHANGED - TmaHelper.UniParts.List.FilterChanged({ criterionFilters: (request.selectedCriteria as CriterionFilter[]) ?? [] })
        // TmaHelper.UniParts.List.FilterChanged({ criterionFiltersGroup: loadedFilters.criterionFilterGroups ?? [] }) // add all criterionFilterGroup at once
        return loadedFilters.criterionFilterGroups.map((group) => ({
            ...group,
            filters: group.criterionFilters.map(mapCriterionFilter).map((filter) => {
                return [mapFilterSelectable(filter), !!filter.isSelected]
            }),
        }))
    }, [loadedFilters?.criterionFilterGroups, mapFilterSelectable])

    const resetAvailability = useCallback(() => {
        setShowOnlyAvailable(false)
        setShowOnlyAvailableSecondary(false)
    }, [setShowOnlyAvailable, setShowOnlyAvailableSecondary])

    const resetAll = useCallback(() => {
        setExtendedAssortmentEnabledForced(false)
        setSelectedProductGroupIds((prev) => (prev.length ? [] : prev))
        setSelectedSupplierIds((prev) => (prev.length ? [] : prev))
        setSelectedAttributeFilterQueries((prev) => (prev.length ? [] : prev))
        resetAvailability()
        setFittingPosition?.(FittingPosition.None)
    }, [setSelectedAttributeFilterQueries, setSelectedProductGroupIds, setSelectedSupplierIds, resetAvailability, setFittingPosition])

    useEffect(() => {
        resetAll()
        clearLoadedFilters()
    }, [startParams])

    useEffect(
        function transferSelectedFiltersToParams() {
            if (!loadedFilters) {
                return
            }

            if (!selectedProductGroupIds.length) {
                const filters = extendedAssortmentEnabledForced
                    ? loadedFilters.productGroupFilters
                    : getFilteredProductGroupFilters(loadedFilters.productGroupFilters)
                const productGroups = filters.map(mapProductGroupFilter).map(mapFilterForParams)
                params.setProductGroups(productGroups)
            } else {
                params.setProductGroups(
                    loadedFilters.productGroupFilters
                        .filter((filter) => selectedProductGroupIds.includes(filter.id))
                        .map(mapProductGroupFilter)
                        .map(mapFilterForParams)
                )
            }
            params.setSuppliers(
                loadedFilters.supplierFilters
                    .filter((filter) => selectedSupplierIds.includes(filter.id))
                    .map(mapDataSupplierFilterAndShowOnTop)
                    .map(mapFilterForParams)
            )
            params.setAttributes(
                loadedFilters.criterionFilterGroups
                    .flatMap((x) => x.criterionFilters)
                    .filter((x) => x.isSelected)
                    .map(mapCriterionFilter)
                    .map(mapFilterSelectable)
                    .map(mapFilterForParams)
            )
            params.setExtendedAssortment(extendedAssortmentEnabled)
        },
        [loadedFilters, extendedAssortmentEnabled]
    )

    useEffect(
        function transferAvailabilityToParams() {
            params.setAvailability(showOnlyAvailable)
        },
        [showOnlyAvailable]
    )

    useEffect(
        function transferAvailabilityToParams() {
            params.setAvailabilitySecondary(showOnlyAvailableSecondary)
        },
        [showOnlyAvailableSecondary]
    )

    useEffect(
        function transferIsLoadingToParams() {
            params.setIsFiltersLoading(isLoading || isRefetching)
        },
        [isLoading, isRefetching]
    )

    const startParamCriterias = params.startParams.type === "uninode" ? params.startParams.criterias : undefined
    useEffect(
        function transferParamAttributes() {
            setSelectedAttributeFilterQueries((state) => {
                const queries = sortBy(
                    uniq([...(startParamCriterias ?? []), ...params.attributes.map(mapListFilterToQuery).filter(notUndefinedOrNull)])
                )
                if (!isEqual(state, queries)) {
                    return queries
                }
                return state
            })
        },
        [params.attributes, setSelectedAttributeFilterQueries, startParamCriterias]
    )

    useEffect(() => {
        setExtendedAssortmentEnabled(extendedAssortmentEnabledByUser || extendedAssortmentEnabledForced)
    }, [extendedAssortmentEnabledByUser, extendedAssortmentEnabledForced])

    const toggleProductGroup = useCallback(
        (id: number, exclusive?: boolean) => {
            setSelectedProductGroupIds((state) => {
                if (exclusive) {
                    if (state.length === 1 && state.includes(id)) {
                        return []
                    }
                    return [id]
                }
                if (state.includes(id)) {
                    return state.filter((x) => x !== id)
                }
                return sortBy([...state, id])
            })
        },
        [setSelectedProductGroupIds]
    )

    const toggleSupplier = useCallback(
        (id: number, exclusive?: boolean) => {
            setSelectedSupplierIds((state) => {
                if (exclusive) {
                    if (state.length === 1 && state.includes(id)) {
                        return []
                    }
                    return [id]
                }
                if (state.includes(id)) {
                    return state.filter((x) => x !== id)
                }
                return sortBy([...state, id])
            })
        },
        [setSelectedSupplierIds]
    )

    const toggleAttribute = useCallback(
        (attribute: ListFilter, exclusive?: boolean) => {
            if (!attribute.query) {
                setSelectedAttributeFilterQueries(selectedAttributeFilterQueries)
                return
            }

            if (exclusive) {
                if (selectedAttributeFilterQueries.length === 1 && selectedAttributeFilterQueries.some((filter) => filter === attribute.query)) {
                    // when exclusively deselecting a filter all selectedCriteria should be cleared as well
                    setSelectedCriterias(undefined)
                    setSelectedAttributeFilterQueries([])
                } else {
                    setSelectedAttributeFilterQueries([attribute.query])
                }
                return
            }
            const index = selectedAttributeFilterQueries.findIndex((filter) => filter === attribute.query)
            if (index !== -1) {
                // when deselecting an attribute filter this should also clear the selectedCriteria when same attribute was used there
                if (selectedCriteria?.length) {
                    const indexInSelectedCriteria = selectedCriteria.findIndex((attr) => attr.queryStr === attribute.query)

                    if (indexInSelectedCriteria !== -1) {
                        setSelectedCriterias([
                            ...selectedCriteria.slice(0, indexInSelectedCriteria),
                            ...selectedCriteria.slice(indexInSelectedCriteria + 1),
                        ])
                    }
                }
                setSelectedAttributeFilterQueries(sortBy(selectedAttributeFilterQueries.filter((_, idx) => index !== idx)))
            } else {
                setSelectedAttributeFilterQueries(sortBy([...selectedAttributeFilterQueries, attribute.query]))
            }
        },
        [selectedAttributeFilterQueries, selectedCriteria]
    )

    const toggleExtendedAssortment = useCallback(() => {
        setExtendedAssortmentEnabledByUser((state) => {
            if (state) {
                setExtendedAssortmentEnabledForced(false)
            }
            return !state
        })
    }, [])

    const toggleAvailability = useCallback(() => {
        setShowOnlyAvailable((state) => !state)

        if (showOnlyAvailable === false && showOnlyAvailableSecondary) {
            setShowOnlyAvailableSecondary(false)
        }
    }, [showOnlyAvailable, showOnlyAvailableSecondary])

    const toggleAvailabilitySecondary = useCallback(() => {
        setShowOnlyAvailableSecondary((state) => !state)

        if (showOnlyAvailable && showOnlyAvailableSecondary === false) {
            setShowOnlyAvailable(false)
        }
    }, [showOnlyAvailable, showOnlyAvailableSecondary])

    const resetProductGroups = useCallback(() => setSelectedProductGroupIds((prev) => (prev.length ? [] : prev)), [setSelectedProductGroupIds])
    const resetSuppliers = useCallback(() => setSelectedSupplierIds((prev) => (prev.length ? [] : prev)), [setSelectedSupplierIds])
    const resetAttributes = useCallback(
        () => setSelectedAttributeFilterQueries((prev) => (prev.length ? [] : prev)),
        [setSelectedAttributeFilterQueries]
    )

    return useMemo<FiltersData & FilterActions>(
        () => ({
            extendedAssortment: {
                enabled: extendedAssortmentEnabledByUser,
                forced: extendedAssortmentEnabledForced,
            },
            showOnlyAvailable,
            showOnlyAvailableSecondary,
            productGroupFilters,
            dataSupplierFilters,
            attributeFilters: [],
            groupedAttributeFilters,
            isLoading,
            isRefetching,
            showExtendedAssortmentFilter: loadedFilters?.showExtendedAssortmentFilter ?? false,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            toggleAvailabilitySecondary,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
            resetAvailability,
            resetAll,
        }),
        [
            extendedAssortmentEnabledByUser,
            extendedAssortmentEnabledForced,
            showOnlyAvailable,
            showOnlyAvailableSecondary,
            productGroupFilters,
            dataSupplierFilters,
            groupedAttributeFilters,
            isLoading,
            isRefetching,
            loadedFilters?.showExtendedAssortmentFilter,
            toggleProductGroup,
            toggleSupplier,
            toggleAttribute,
            toggleExtendedAssortment,
            toggleAvailability,
            toggleAvailabilitySecondary,
            resetProductGroups,
            resetSuppliers,
            resetAttributes,
            resetAvailability,
            resetAll,
        ]
    )
}

function getFilteredProductGroupFilters(productGroupFilters: ProductGroupFilter[]) {
    let filters = productGroupFilters.filter((x) => x.showOnTop)
    if (!filters.length) {
        filters = productGroupFilters.filter((x) => x.isTopPriority)
    }
    if (!filters.length) {
        return productGroupFilters
    }
    return filters
}

// things like counts don't need to be stored for requesting new articles
function mapFilterForParams(filter: ListFilter): ListFilter {
    return {
        ...filter,
        articleCount: undefined,
        topPriorityArticleCount: undefined,
        isSelected: undefined,
    }
}
