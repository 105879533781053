export function getTranslationFromSafetyStatus(value: number, translateText: (key: string | number) => string): string {
    switch (value) {
        case 0:
            return translateText(12423)
        case 1:
            return translateText(12757)
        case 2:
            return translateText(12758)
        default:
            return ""
    }
}

export function mapSafetyStatusPrint(safetyStatus: number | undefined, translateText: (key: string | number) => string) {
    const safetyStatusValue = safetyStatus ?? 0
    return {
        iconType: (safetyStatusValue + 1).toString() ?? "0",
        text: getTranslationFromSafetyStatus(safetyStatusValue, translateText),
    }
}
