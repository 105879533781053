import { ajax, getStoredAuthorization } from "@tm/utils"
import { Booking, BookingDetailsRequest, BookingRequest } from "../models"
import { getBundleParams } from "../../utils"

export function getBookingResponse(request: BookingRequest) {
    const url = `${getBundleParams().drivemotiveServiceUrl}/GetBookingList`
    const authorization = getStoredAuthorization()
    const body: BookingRequest = request
    return ajax<{ result: Booking[] }>({ url, body, authorization, method: "POST" }).then((response) => {
        if (!response) {
            throw new Error()
        }
        return response.result
    })
}

export function getBookingDetailsResponse(request: BookingDetailsRequest) {
    const url = `${getBundleParams().drivemotiveServiceUrl}/GetBookingDetails`
    const authorization = getStoredAuthorization()
    const body: BookingDetailsRequest = request
    return ajax<{ result: Booking }>({ url, body, authorization, method: "POST" }).then((response) => {
        if (!response) {
            throw new Error()
        }
        return response.result
    })
}
