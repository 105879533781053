import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ErpPrice, Quantity } from "@tm/models"

type Props = {
    price: ErpPrice | undefined
    quantity: Quantity | undefined
}

export default function Priceunit(props: Props) {
    const { translateText } = useLocalization()

    if (props.price?.priceUnit && props.price.priceUnit > 1) {
        return (
            <Text modifiers={["sub"]} size="xs" className="price__unit">
                {translateText(1312)} {props.price.priceUnit} {props.quantity?.quantityUnit || ""}
            </Text>
        )
    }

    return null
}
