import { useLocalization } from "@tm/localization"
import { useCisCustomer } from "@tm/context-distribution"
import { Box, Loader, Stack, Typography, Image, Paper, styled, Grid } from "@tm/components"
import { CisAddress, CisContact, CisElectronicAddress, CisSalesOutlet } from "@tm/models"
import { renderElectronicAddresses, renderLabelValue } from "./helpers"
import SalesOutletPerson from "./components/SalesOutletPerson"

const StyledImage = styled(Image)({
    maxHeight: "21em",
    maxWidth: "100%",
})

export default function CisAccountComponent() {
    const { translate, translateText } = useLocalization()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()

    function renderCustomer() {
        if (!cisCustomer) {
            return
        }
        const lvpCustomerNo = [{ label: translateText(156), value: cisCustomer.customerNo }]

        return (
            <Stack>
                <Typography>{cisCustomer?.companyName}</Typography>
                <table>{lvpCustomerNo.map(renderLabelValue)}</table>
            </Stack>
        )
    }

    function renderCompanyAddress(addresses?: Array<CisAddress>, contacts?: Array<CisContact>) {
        const address = addresses?.first()
        const contact = contacts?.first()

        return (
            <Stack>
                {contact && (
                    <Typography>
                        {contact.firstName} {contact.lastName}
                    </Typography>
                )}
                {address && (
                    <Stack>
                        <Typography>{address.street}</Typography>
                        <Typography>
                            {address.zip} {address.city}
                        </Typography>
                    </Stack>
                )}
            </Stack>
        )
    }

    function renderContactsElectronicAddresses(contacts?: Array<CisContact>) {
        if (!contacts || !contacts[0] || !contacts[0].electronicAddresses) {
            return null
        }

        const eContacts: Array<CisElectronicAddress> = contacts[0].electronicAddresses.reverse().filter((x) => x.value?.trim())
        return renderElectronicAddresses(eContacts)
    }

    function renderSaleshouseAddress(address: CisAddress, outletName: string | undefined) {
        return (
            <Stack>
                <Typography>{outletName || ""}</Typography>
                <Typography>{address?.street}</Typography>
                <Typography>
                    {address?.zip} {address?.city}
                </Typography>
            </Stack>
        )
    }

    function renderSaleshouseOutletContacts(contacts: Array<CisContact>) {
        if (!contacts) {
            return null
        }

        // salesoutlet manager has a hardcoded id=1 in tm connect
        const manager = contacts.find((contact) => contact.id === "1" || contact.jobDescription === "Verkaufshausleiter")
        const fieldRepresentative = contacts.find(
            (contact) => contact.id !== "1" && !(contact.jobDescription === "Verkaufshausleiter" || contact.jobDescription === "VKH_EMail_Fax")
        )

        if (!manager && !fieldRepresentative) {
            return null
        }

        return (
            <Stack direction="row" justifyContent="space-between">
                {manager && <SalesOutletPerson contact={manager} />}
                {fieldRepresentative && <SalesOutletPerson contact={fieldRepresentative} />}
            </Stack>
        )
    }

    function renderSalesoutlet(outlet: CisSalesOutlet) {
        const electronicAddresses =
            outlet.contacts?.find((x) => x.jobDescription === "VKH_EMail_Fax" || x.id === "1")?.electronicAddresses?.reverse() || []
        if (!electronicAddresses?.some((x) => x.type === 3) && outlet.address?.phone) {
            electronicAddresses.unshift({ type: 3, description: "Telefonnummer", value: outlet.address.phone })
        }
        return (
            <Stack spacing={4}>
                <Stack spacing={1.5}>
                    {renderSaleshouseAddress(outlet.address, outlet?.description)}
                    {electronicAddresses && renderElectronicAddresses(electronicAddresses)}
                </Stack>
                {renderSaleshouseOutletContacts(outlet.contacts)}
            </Stack>
        )
    }

    function renderSalesoutlets(outlets?: Array<CisSalesOutlet>) {
        if (!outlets || !outlets[0]) {
            return null
        }

        return (
            <Stack spacing={1.5}>
                <Typography variant="h2">{translate(1304)}</Typography>
                {renderSalesoutlet(outlets[0])}
            </Stack>
        )
    }

    function renderSalesoutletImage(salesOutlets?: Array<CisSalesOutlet>) {
        if (!salesOutlets) {
            return null
        }
        /// get the images from notes
        const sales = salesOutlets.first()
        let imageUrl = ""
        if (sales && sales.notes) {
            const note = sales.notes.first()
            if (note) {
                imageUrl = note.imageUrl || ""
            }
        }

        return imageUrl ? <StyledImage src={imageUrl} /> : undefined
    }

    return (
        <Box width="100%" padding="1em">
            <Paper sx={{ minHeight: "10rem", padding: "1rem" }}>
                {cisCustomerLoading && <Loader />}
                {!cisCustomerLoading && !cisCustomer && <Typography>{translate(323)}</Typography>}
                {cisCustomer && (
                    <Stack spacing={4}>
                        <Typography variant="h1">{translate(1300)}</Typography>
                        <Grid container columns={12}>
                            <Grid item xs={3} paddingRight="100px">
                                <Stack spacing={1.5}>
                                    <Typography variant="h2">{translate(1301)}</Typography>
                                    {renderCustomer()}
                                    {renderCompanyAddress(cisCustomer.addresses, cisCustomer.contacts)}
                                    {renderContactsElectronicAddresses(cisCustomer.contacts)}
                                </Stack>
                            </Grid>
                            <Grid item xs={5} paddingRight="100px">
                                {renderSalesoutlets(cisCustomer.salesOutlets)}
                            </Grid>
                            <Grid item xs={4}>
                                {renderSalesoutletImage(cisCustomer.salesOutlets)}
                            </Grid>
                        </Grid>
                    </Stack>
                )}
            </Paper>
        </Box>
    )
}
