import { FuelType } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"

function getServiceUrl(): string {
    return getBundleParams().carsServiceUrl
}

function mapFuelTypes(data: any): Array<FuelType> {
    return Object.values(data.fuelTypes) as Array<FuelType>
}

export function getAllVehicleFuelTypes(): Promise<Array<FuelType>> {
    const url = `${getServiceUrl()}/GetFuelTypes`
    const authorization = getStoredAuthorization()

    return new Promise<Array<FuelType>>((resolve, reject) =>
        ajax({ url, authorization }).then((data) => (data?.fuelTypes?.length ? resolve(mapFuelTypes(data)) : reject()), reject)
    )
}
