import { RequestArticleListPayload } from "@tm/models"
import {
    ClearanceStatusResponse,
    TelematicsDataResponse,
    TelematicDateTimeSet,
    ConditionBasedServiceInfo,
    TelematicSet,
    TelematicNumberSet,
    SetType,
} from "."

export function mapClearanceStatusResponse(data: any): ClearanceStatusResponse {
    return {
        ...data,
        lastStateChanged: data.lastStateChanged ? new Date(data.lastStateChanged) : null,
    }
}

function mapConditionBasedServiceInfo(data: any): ConditionBasedServiceInfo | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        date: data.date ? new Date(data.date) : undefined,
    }
}

function mapTelematicDateTimeSet(data: any): TelematicDateTimeSet | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        value: data.value ? new Date(data.value) : null,
    }
}

function mapTelematicNumberSet(data: any): TelematicNumberSet | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        value: data.value || 0,
        decimalPlaces: data.decimalPlaces || 0,
    }
}

function mapTelematicSet(data: any): TelematicSet | undefined {
    if (!data) {
        return
    }
    switch (data.type) {
        case SetType.DateTime:
            return mapTelematicDateTimeSet(data)
        case SetType.Number:
            return mapTelematicNumberSet(data)
        default:
            return data
    }
}

export function mapTelematicsDataResponse(data: any): TelematicsDataResponse {
    return {
        ...data,
        dateOfLastUpdate: data.dateOfLastUpdate ? new Date(data.dateOfLastUpdate) : null,
        nextServiceDate: mapTelematicDateTimeSet(data.nextServiceDate),
        legalInspectionDate: mapTelematicDateTimeSet(data.legalInspectionDate),
        nextBrakeFluidChangeDate: mapTelematicDateTimeSet(data.nextBrakeFluidChangeDate),
        conditionBasedServiceInfos: data.conditionBasedServiceInfos ? data.conditionBasedServiceInfos.map(mapConditionBasedServiceInfo) : undefined,
        chassisStatus: data.chassisStatus ? data.chassisStatus.map(mapTelematicSet) : undefined,
        navigationData: data.navigationData ? data.navigationData.map(mapTelematicSet) : undefined,
        vehicleStatus: data.vehicleStatus ? data.vehicleStatus.map(mapTelematicSet) : undefined,
        electricCarSpecificStatus: data.electricCarSpecificStatus ? data.electricCarSpecificStatus.map(mapTelematicSet) : undefined,
        additionalServiceData: data.additionalServiceData ? data.additionalServiceData.map(mapTelematicSet) : undefined,
        environmentData: data.environmentData ? data.environmentData.map(mapTelematicSet) : undefined,
    }
}

export function mapShowPartsListByProductGroupsToRequestArticleList(payload: Array<number>): RequestArticleListPayload {
    return {
        productGroups: {
            ids: payload,
        },
    }
}
