import { Widget, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useCisCustomer } from "@tm/context-distribution"
import { Box, Divider, Loader, Stack } from "@tm/components"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"
import { useCisShowTours } from "../../data/hooks/useCisShowTours"
import CustomerDataComponent from "../_shared/customer/CustomerData"
import NextTour from "../_shared/NextTour"

type Props = {
    externalSystemId?: number
    mainTitleTextId?: number
    size?: WidgetSizes
    className?: string
}

export function WidgetAccountTours(props: Props) {
    const { size, externalSystemId, mainTitleTextId } = props
    const { translate } = useLocalization()

    const { cisCustomer, cisCustomerLoading } = useCisCustomer(!externalSystemId)

    const { tours, toursLoading } = useCisShowTours()

    // there are constellations where the cis module is active but no tile is desired.
    // This still needs to be tidied up when all the information has been collected
    if (!cisCustomer) {
        return null
    }

    return (
        <Widget
            size={size || "4x2"}
            active
            title={translate(491)}
            iconName="users"
            header={<WidgetHeaderTitleComponent iconName="user" titleTextId={mainTitleTextId || 491} linkTextId={cisCustomer ? 426 : undefined} />}
            className={props.className}
        >
            <Stack justifyContent="space-between" direction="row" flex={1}>
                <Box flex="0 0 50%">
                    {cisCustomerLoading ? <Loader /> : <>{cisCustomer && <CustomerDataComponent cisCustomer={cisCustomer} titleTextId={1085} />}</>}
                </Box>
                <Divider orientation="vertical" flexItem />
                <Box flex="0 0 50%">{tours && <NextTour tours={tours} toursLoading={toursLoading} />}</Box>
            </Stack>
        </Widget>
    )
}
