import {
    ShowCustomArticleItemsRequest,
    AddCustomArticleItemsResponse,
    AddCustomArticleItemsRequest,
    CustomArticleItem,
    EditCustomArticleItemRequest,
    EditCustomArticleItemResponse,
} from "@tm/models"
import * as Data from "../.."
import { useCustomItems, useDeleteCustomItem, useEditCustomItem } from "./useCustomItems"

const QUERY_KEY = "basket__custom-articles"

export function useShowCustomArticleItems(request: Omit<ShowCustomArticleItemsRequest, "pageIndex" | "pageSize">) {
    return useCustomItems(request, { queryKey: QUERY_KEY, dataFn: Data.showCustomArticleItems })
}

export function useDeleteCustomArticles() {
    return useDeleteCustomItem<string[], void, CustomArticleItem[]>({
        queryKey: QUERY_KEY,
        dataFn: (ids) => Data.deleteCustomArticles({ ids }),
        filterFn: (_, ids, prevData) => prevData.filter((x) => !ids.includes(x.id)),
    })
}

export function useAddCustomArticleItems() {
    return useEditCustomItem<AddCustomArticleItemsRequest, AddCustomArticleItemsResponse | undefined, CustomArticleItem[]>({
        queryKey: QUERY_KEY,
        dataFn: Data.addCustomArticleItems,
        mutateFn: (response, _, prevData) => [...prevData, ...(response?.addedCustomArticleItems ?? [])],
    })
}

export function useEditCustomArticleItem() {
    return useEditCustomItem<EditCustomArticleItemRequest, EditCustomArticleItemResponse | undefined, CustomArticleItem[]>({
        queryKey: QUERY_KEY,
        dataFn: Data.editCustomArticleItem,
        mutateFn: (response, customArticle, prevData) =>
            prevData.map((x) => (x.id === response?.editedCustomArticleItem.id ? { ...x, ...customArticle } : x)),
    })
}
