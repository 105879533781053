import { RepairShops } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"

export function useEmailShare(onShareCompleted?: (success: boolean) => void) {
    const { translateText } = useLocalization()

    const handleSendEmail = (emails: string[], fileUrl: string, bcc?: string[]) => {
        if (!fileUrl || !emails?.length) {
            return
        }

        try {
            const request: RepairShops.SendEmailRequest = {
                emails,
                bcc,
                body: fileUrl,
                subject: translateText(13782),
            }

            RepairShops.sendEmail(request)
        } catch (error) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", {
                message: translateText(13808),
                icon: "error",
                skin: "warning",
                closeDelay: 3000,
            })

            onShareCompleted?.(false)
        }

        channel("APP").publish("TOAST_MESSAGE/SHOW", {
            message: translateText(13807),
            icon: "check",
            skin: "success",
            closeDelay: 3000,
        })

        onShareCompleted?.(true)
    }

    return {
        isLoading: false,
        sendEmail: handleSendEmail,
    }
}
