import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce } from "./business"

const FormConfirmation: BundleComponent<unknown, typeof component> = {
    name: "form-confirmation",
    component,
    reduce,
}

export default FormConfirmation
