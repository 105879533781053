import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { lightGrayBorder } from "../../styles"

export function getComponentStyles() {
    const { box } = getStyleTheme()

    return stylesheet({
        wrapper: {
            flex: 3,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            position: "relative",
            background: "#fff",
            border: lightGrayBorder,
            borderRadius: box.radius,
        },
        wrapperLoader: {
            margin: "0.5rem",
        },
        usersArea: {
            padding: "0 1.2rem",
            borderBottom: lightGrayBorder,
            display: "flex",
            alignItems: "center",
            height: "4rem",
        },
        usersAreaText: {
            flex: 1,
        },
        scrollbarWrapper: {
            flex: 1,
        },
        scrollbarContent: {
            display: "flex",
            flexDirection: "column",
            padding: "1.2rem",
        },
        messageInputWrapper: {
            padding: "1rem",
        },
        imageViewerWrapper: {
            position: "absolute",
            width: "100%",
            height: "100%",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
