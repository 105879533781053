import { useShowCisOptions, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { CisVoucherType } from "@tm/models"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { FindVoucherRequest } from "../model"
import * as Data from "../repositories/cis/vouchers"

const VOUCHER_TYPE = CisVoucherType.Returns
function showReturns(customerNo: string | undefined) {
    const findVoucherRequest: FindVoucherRequest = {
        pageIndex: 1,
        pageSize: 5,
        customerNo,
        voucherTypeId: VOUCHER_TYPE,
    }

    return Data.findVouchers(findVoucherRequest)
}

const KEY = "cis_useReturnsRecent"
export function useReturnsRecent() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { cisOptions } = useShowCisOptions()

    const voucherTypeEnabled = useMemo(() => {
        return !!cisOptions?.voucherTypes?.some((type) => type.typeId === VOUCHER_TYPE)
    }, [cisOptions])

    const enabled = voucherTypeEnabled && enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => {
            return showReturns(telesalesCustomerNo)
        },
        {
            enabled,
        }
    )

    return {
        returns: data?.vouchers,
        returnsLoading: isLoading,
    }
}
