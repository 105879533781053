import { useCallback, useState, useEffect, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { useSelection } from "@tm/utils"
import { Loader, Text, Skeleton } from "@tm/controls"
import { useShowNewArticleList } from "@tm/context-distribution"
import CompilationInfo from "./components/compilation-info"
import PartsList from "./components/parts-list"
import PartsSelection from "./components/parts-selection"
import { useCompilation, useTotals } from "../../../../data/hooks/useCompilations"
import { isWM } from "../../../../utils"
import { CompilationArticleList } from "../../../../../../parts/src/components/CompilationArticleList/CompilationArticleList"
import { ArticleSelectionWrapper } from "./components/ArticleSelectionWrapper"

type Props = {
    compilationId: string
    encodedWorkTaskId: string
    singleCompilationList?: boolean
}

export default function CompilationDetailsComponent({ compilationId, singleCompilationList, encodedWorkTaskId }: Props) {
    const { translateText, currency } = useLocalization()

    const [selectionMode, setSelectionMode] = useState<"MOVE" | "DEFAULT">("DEFAULT")
    const { compilation, articles, isLoading } = useCompilation(compilationId)
    const { totals, totalsLoading } = useTotals(compilationId)

    const showNewArticleList = useShowNewArticleList()

    const getAllIds = useCallback(() => articles?.map((article) => article.id) || [], [articles])
    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllIds)
    const selectedArticles = useMemo(() => articles?.filter((article) => selectedIds.includes(article.id)), [articles, selectedIds])

    useEffect(() => {
        unselectAll()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [compilationId])

    const handleSelectPart = useCallback(
        (partId: string, moveMode?: boolean) => {
            setSelectionMode(moveMode ? "MOVE" : "DEFAULT")
            toggleSelected(partId)
        },
        [toggleSelected]
    )

    const renderPartsSelection = () => {
        if (!compilationId || !selectedArticles?.length || !articles?.length) {
            return null
        }

        const allSelected = articles.length === selectedArticles.length
        return (
            <PartsSelection
                compilationId={compilationId}
                allSelected={allSelected}
                selectedArticles={selectedArticles}
                onSelectAll={selectAll}
                onUnselectAll={unselectAll}
                selectionMode={selectionMode}
            />
        )
    }

    const renderPartList = () => {
        if (!articles) {
            return null
        }

        if (!showNewArticleList || isWM()) {
            return (
                <PartsList
                    encodedWorkTaskId={encodedWorkTaskId}
                    compilationId={compilationId}
                    loadingPositions={totalsLoading}
                    positionCount={totals?.positionCount || 0}
                    parts={articles}
                    selectedPartIds={selectedIds}
                    onSelectPart={handleSelectPart}
                    singleCompilationList={singleCompilationList}
                />
            )
        }

        return (
            <ArticleSelectionWrapper
                selectedArticles={selectedArticles}
                selectedIds={selectedIds}
                handleSelectPart={handleSelectPart}
                unselectAll={unselectAll}
            >
                <CompilationArticleList
                    encodedWorkTaskId={encodedWorkTaskId}
                    compilationId={compilationId}
                    parts={articles}
                    elementId="compilation-options-extra"
                />
            </ArticleSelectionWrapper>
        )
    }

    const renderTotals = () => {
        return (
            <div className="total">
                <Text size="m" className="label">
                    {translateText(62)}
                </Text>
                <Text size="m" className="price" modifiers={["strong"]}>
                    {totalsLoading ? <Loader /> : totals ? currency(totals.customerPriceTotal, totals.currencyCode || totals.currencySymbol) : "-"}
                </Text>
            </div>
        )
    }

    const classes = `tk-compilations compilation-details ${isWM() ? "wm" : ""}`

    return (
        <div className={classes}>
            {isLoading ? (
                <>
                    <Skeleton variant="text" height="63px" />
                    <Skeleton variant="text" height="63px" />
                    <Skeleton variant="text" height="63px" />
                </>
            ) : (
                compilation && (
                    <>
                        {!singleCompilationList && <CompilationInfo compilation={compilation} />}
                        <div className="parts-selection">{renderPartsSelection()}</div>
                        {renderPartList()}
                    </>
                )
            )}
            {isWM() && renderTotals()}
        </div>
    )
}
