import { PropsWithChildren, ReactNode, useMemo } from "react"
import { Headline } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { getComponentStyles } from "./styles"

type Props = {
    headerChildren?: ReactNode
    classNameHeader?: string
    classNameContent?: string
}

export default function SharedLayout(props: PropsWithChildren<Props>) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()

    return (
        <div className={classNames.main}>
            <div className={classes(classNames.header, props.classNameHeader)}>
                <Headline size="s" className={classNames.headline}>
                    {translateText(12617)}
                </Headline>
                {props.headerChildren}
            </div>
            <div className={classes(classNames.content, props.classNameContent)}>{props.children}</div>
        </div>
    )
}
