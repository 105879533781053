import { useState, useEffect } from "react"
import { Box, Chip, styled } from "@tm/components"
import { useStyle } from "@tm/context-distribution"
import { Loader, SubTitle, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getQueryHistory } from "../../helper/queryhistory"

const StyledChip = styled(Chip)(({ theme }) => ({
    "&.MuiChip-root": {
        color: theme.colors?.dark,
        borderRadius: theme.box?.radius,
        display: "flex",
        justifyContent: "space-between",
        "&:hover": {
            opacity: ".84",
        },
        ".MuiChip-deleteIcon": {
            color: "inherit",
        },
    },
}))

export type QueryHistoryProps = {
    handleSearch: (query: string) => void
}

export default function QueryHistory(props: QueryHistoryProps) {
    const { translate } = useLocalization()

    const [queryHistory, setQueryHistory] = useState<string[]>([])
    const [qHistoryLoading, setQHistoryLoading] = useState(true)
    function disableQHistoryLoading() {
        setQHistoryLoading(false)
    }

    useEffect(() => {
        getQueryHistory().then(setQueryHistory).finally(disableQHistoryLoading)
    }, [])

    return (
        <>
            <div className={style.titleWrapper}>
                <Text size="s">{translate(12587)}</Text>
            </div>
            <div className={`${style.queryhistoryContainer}${qHistoryLoading ? ` ${style.queryhistoryContainerLoading}` : ""}`}>
                {qHistoryLoading && <Loader />}
                {!qHistoryLoading &&
                    queryHistory.map((query, index) => {
                        return (
                            <Box p="3px" key={`${query}`} overflow="hidden" textOverflow="ellipsis">
                                <StyledChip title={query} variant="outlined" size="small" label={query} onClick={() => props.handleSearch(query)} />
                            </Box>
                        )
                    })}
            </div>
        </>
    )
}

const style = useStyle({
    titleWrapper: {
        width: "100%",
    },
    queryhistoryContainer: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        flex: 1,
        paddingTop: "0.55em",
    },
    queryhistoryContainerLoading: {
        justifyContent: "center",
        padding: "0.5em",
    },
})(QueryHistory)
