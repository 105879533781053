/* eslint-disable no-console */
import { DrivemotiveBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"
import { Provider } from "react-redux"
import { Store } from "redux"

let bundleParams: DrivemotiveBundleParams

export const version = {
    name: "drivemotive",
    version: "2.0.0",
}

function checkBundleParams(params: DrivemotiveBundleParams) {
    if (!isDevtoolEnabled()) {
        return null
    }
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle ${version.name}`)
    }

    return bundleParams
}

export function initDrivemotiveBundle(params: DrivemotiveBundleParams) {
    bundleParams = params
    checkBundleParams(params)
}

export const mapBookingStatus = (status: string, translateText: (key: string | number) => string) => {
    switch (status) {
        case "confirmed": {
            return { label: translateText(115), color: "#2196f3" }
        }
        case "cancelled": {
            return { label: translateText(13713), color: "#f75b3b" }
        }
        case "waiting-for-price": {
            return { label: translateText(13715), color: "#ffeb3b" }
        }
        case "done": {
            return { label: translateText(13714), color: "#c2e617" }
        }
        default: {
            return { label: undefined, color: undefined }
        }
    }
}

/* eslint-disable react/prefer-stateless-function */
type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(component: React.ComponentType<Omit<P, keyof StoreModel>>): React.ComponentType<P> {
    return class extends React.Component<P> {
        render() {
            const { store } = this.props
            return React.createElement(Provider, { store }, React.createElement(component, this.props))
        }
    }
}
