import { WorkTaskInfo } from "@tm/context-distribution"
import { OeArticleQuantities, OeArticleQuantitiesRequest, OePart, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useState, useEffect } from "react"

export function useOeBasketInfo(part: OePart, workTask?: WorkTaskInfo) {
    const [loading, setLoading] = useState(true)
    const [basketInfo, setBasketInfo] = useState<OeArticleQuantities>()

    useEffect(() => {
        setBasketInfo(undefined)

        if (!workTask?.id) {
            return
        }

        if (!part.oeArticleNumber) {
            setLoading(false)
            setBasketInfo(undefined)
            return
        }

        const request: OeArticleQuantitiesRequest = {
            workTaskId: workTask.id,
            // vehicleId: workTask.vehicle?.id,
            article: {
                oeArticleNumber: part.oeArticleNumber,
                productGroupId: part.productGroupId,
                vehicleManufacturerId: part.vehicleManufacturerId,
            },
        }

        const subscription = Container.getInstance<OeArticleQuantities>(RegisteredModels.Basket_OeBasketInfo).subscribe(request)
        const unregisterLoadingInfo = subscription.addListener("loading", () => {
            setLoading(true)
        })
        const unregisterLoadedInfo = subscription.addListener(
            "loaded",
            (response) => {
                setLoading(false)
                setBasketInfo(response)
            },
            () => {
                setLoading(false)
                setBasketInfo(undefined)
            }
        )

        subscription.load()

        return () => {
            unregisterLoadingInfo()
            unregisterLoadedInfo()
        }
    }, [workTask?.id, /* workTask?.vehicle?.id, */ part.oeArticleNumber, part.productGroupId, part.vehicleManufacturerId])

    return { basketInfo, basketInfoLoading: loading }
}
