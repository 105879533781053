import { em, margin, px } from "csx"
import { ReactElement, useCallback, useMemo, useState } from "react"
import { ArticleNumber, Stack } from "@tm/components"
import { getStyleTheme, useStyle, useUser } from "@tm/context-distribution"
import { Badge, Button, Image, MoreMenu, SeparatorList, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, IMicros, RequestArticleDetailsPayload, channel } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { bem, classes, clone } from "@tm/utils"
import { FastCalculator } from "@tm/data"
import { ECalcArticle } from "../../data/enums"
import { attributesHelper, createArticleComparer, isNumberType, mapCalcArticleToOePart } from "../../data/helpers"
import { AlternativeCalcStateContext, ArticleAttributeItem, CalcArticle, CalcGenArt } from "../../data/model"
import { getBundleParams } from "../../utils"
import ArticleCell from "./articleCell"
import ArticleErp from "./articleErp"
import AttributesTag from "./attributes-tag"
import CalcInputComponent from "./calcInput"
import NoArticleFound from "./noArticleFound"
import TotalPrice from "./totalPrice"
import CalcInputButton from "./calcInputs/calcInputButton"
import { fastCalculatorStore, useStoreContext } from "../../state"

type Props = {
    item: CalcArticle
    genArt: CalcGenArt
    hideAlternatives?: boolean
    showProductGroupAlternatives(genArt: CalcGenArt): void
}

export default function ArticleItem(props: Props): ReactElement {
    const { item, genArt, hideAlternatives, showProductGroupAlternatives } = props
    const instance = useStoreContext()
    const useFastCalculatorStore = fastCalculatorStore(instance)

    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const style = useMemo(() => getStyle(), [])
    const [opened, setOpened] = useState(false)

    const { alternativeCalcArticles, vehicle, additionalData } = useFastCalculatorStore((state) => ({
        alternativeCalcArticles: state.alternativeCalcArticles,
        vehicle: state.vehicle,
        additionalData: state.additionalData,
    }))

    const alternativesContext = alternativeCalcArticles?.context as AlternativeCalcStateContext

    const { loading: additionalArticleDataLoading, articles: additionalArticleData, orderHistory } = additionalData

    const isPart = () => {
        return item.type == ECalcArticle.Article || item.type == ECalcArticle.AlternativeArticle
    }

    const isOePart = () => {
        return item.type == ECalcArticle.OeArticle || item.type == ECalcArticle.OeAlternativeArticle
    }

    const handleToggleOpened = () => {
        setOpened(!opened)
    }

    const handleRequestArticleDetails = useCallback((request: RequestArticleDetailsPayload) => {
        request.categoryType = "partAlternatives"
        request.inModal = true
        channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
    }, [])

    const renderOeGroups = () => {
        if (!item.oeGroups?.length || (item.oeGroups.length == 1 && item.oeGroups[0].label == "...")) {
            return
        }

        return (
            <div className={classes("article-oegroups", style.oeGroups)}>
                {item.oeGroups.map((oeGroup) => {
                    let article: Article | undefined
                    let allAttrs = 0

                    if (oeGroup.isDisabled) {
                        article = additionalArticleData?.find(createArticleComparer(item, genArt))
                    } else if (
                        oeGroup.value2 &&
                        oeGroup.value2Type == FastCalculator.ECalcInputValue.Article &&
                        (oeGroup.value2.type == ECalcArticle.Article || oeGroup.value2.type == ECalcArticle.AlternativeArticle)
                    ) {
                        article = additionalArticleData?.find(createArticleComparer(oeGroup.value2, genArt))
                    }

                    if (article) {
                        if (oeGroup.value2) {
                            allAttrs = attributesHelper(
                                false,
                                article,
                                [...(oeGroup.value2.attributes || []), ...(oeGroup.value2.vknAttributes || [])].filter((x) => x.existsInSlfa),
                                vehicle?.engineCode
                            ) as number
                        } else {
                            allAttrs = attributesHelper(
                                false,
                                article,
                                [...(item.attributes || []), ...(item.vknAttributes || [])].filter((x) => x.existsInSlfa),
                                vehicle?.engineCode
                            ) as number
                        }
                    }

                    return (
                        <div key={oeGroup.id} className={style.oeGroup}>
                            {!!allAttrs && <Badge className={style.badge} value={allAttrs} skin="highlight" title={translateText(13377)} />}
                            <CalcInputComponent item={oeGroup} selected={oeGroup.isDisabled} />
                        </div>
                    )
                })}
            </div>
        )
    }

    const renderPriceInput = () => {
        return <CalcInputComponent item={item.price} />
    }

    const renderPriceInputInArticleCell = () => {
        return (
            <ArticleCell bemModifier="edit-price">
                <CalcInputComponent item={item.price} />
            </ArticleCell>
        )
    }

    const renderErpInfo = () => {
        if (item.type == ECalcArticle.OeArticle || item.priceEditable) {
            return renderPriceInputInArticleCell()
        }

        return <ArticleErp erpData={item.erpData} />
    }

    const renderTotalPrice = () => {
        return <TotalPrice totalPrice={item.totalPrice} displayOnTop />
    }

    const renderActions = () => {
        const isTotalPriceHeader = getBundleParams().showTotalPriceHeader

        return (
            <div className={classes("article-actions", style.actions)}>
                {isPart() && item.priceEditable && renderPriceInput()}
                {!isTotalPriceHeader && (
                    <div className="article-actions_price">
                        {translateText(2007)}
                        <TotalPrice totalPrice={item.totalPrice} />
                    </div>
                )}

                <CalcInputComponent item={item.quantity} />
                <CalcInputComponent item={item.removeInput} />
                <CalcInputButton className="showAlternatives" item={item.artListInput} />
            </div>
        )
    }

    const renderArticle = () => {
        const isTotalPriceHeader = getBundleParams().showTotalPriceHeader

        const part = additionalArticleData?.find(createArticleComparer(item, genArt))

        if (isPart() && (part || additionalArticleDataLoading)) {
            if (part) {
                part.id = item.id
                part.quantity = isNumberType(item.quantity.valueType) ? item.quantity.value : 1
                part.initialQuantity = part.quantity
            }

            const FCAttr = [...(item.attributes || []), ...(item.vknAttributes || [])].filter((x) => x.existsInSlfa)
            let partToSend = clone(part)

            if (FCAttr?.length && partToSend) {
                partToSend = attributesHelper(true, partToSend, FCAttr, vehicle?.engineCode) as Article
            }

            return renderMicro!("parts", "part-item", {
                part: partToSend,
                vehicleId: vehicle?.id,
                className: bem(style.actions, Morpheus.getParams("parts")?.templates?.articleItem?.bundle),
                // vehicleEngineCode: vehicle?.engineCode,
                // vehicleRecordsComparisons: vehicleRecords,
                shouldLoadVehicleRecords: false,
                previouslyOrdered: part
                    ? orderHistory?.some((x) => x.supplierId == part?.supplier.id && x.supplierArticleNumber == part.supplierArticleNo)
                    : undefined,
                isCompact: !opened,
                showActions: true,
                showArticleImage: true,
                onRequestArticleDetails: handleRequestArticleDetails,
                onToggleCollapse: handleToggleOpened,
                renderBuyActions: renderActions,
                ...(isTotalPriceHeader && { renderTotalPrice }),
                canFilterArticleAttributes: false,
            })
        }

        if (isOePart()) {
            const isCustomOePart = item.oeNrs.some((x) => !x)
            if (isCustomOePart) {
                return (
                    <NoArticleFound
                        showProductGroupAlternatives={showProductGroupAlternatives}
                        article={{
                            label: genArt.label,
                            price: item.price,
                            quantity: { ...item.quantity, value: item.quantity.value || 1 },
                            removeInput: item.removeInput,
                            totalPrice: item.totalPrice,
                        }}
                    />
                )
            }

            return renderMicro!("parts", "oe-part-item", {
                oePart: mapCalcArticleToOePart(item),
                renderErpInfos: renderErpInfo,
                renderBasketButton: renderActions,
                hiddenFakeActions: { costEstimation: true, selector: true },
            })
        }

        return (
            <div className="article-list__item article-list__item--compact article-list__panel">
                <ArticleCell bemModifier="prefix">
                    <Button icon="down" layout={["ghost"]} disabled />
                </ArticleCell>

                <ArticleCell bemModifier="thumbnail">
                    <Image className="image article__thumbnail " url={item.img ?? ""} type="article" />
                </ArticleCell>

                <ArticleCell bemModifier="supplier">
                    <Text className="supplier__name">{item.supplierName}</Text>
                </ArticleCell>

                <ArticleCell bemModifier="numbers">
                    <Stack alignItems="flex-start" direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
                        {!userContext?.parameter.hideDealerPartNumber && (
                            <ArticleNumber
                                articleNumber={item.hArtNr}
                                articleNumberType="wholesaler"
                                omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                            />
                        )}
                        <ArticleNumber
                            articleNumber={item.supplierArtNr}
                            articleNumberType="supplier"
                            omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                        />
                    </Stack>
                </ArticleCell>

                <ArticleCell bemModifier="description" />

                <ArticleCell bemModifier="erp-information">{renderErpInfo()}</ArticleCell>

                <ArticleCell bemModifier="actions">
                    {renderActions()}

                    {/* just show these two items without functionality to position the other buttons the same way as in the other articles */}
                    <Button disabled>{translateText(43)}</Button>
                    <MoreMenu disabled items={[]} onMenuItemSelect={() => {}} />
                </ArticleCell>

                {item.attributes && (
                    <ArticleCell bemModifier="attributes">
                        <SeparatorList
                            className="article__attributes article__attributes--major"
                            items={item.attributes}
                            renderItem={(articleAttributeItem: ArticleAttributeItem) => <AttributesTag item={articleAttributeItem} />}
                        />
                    </ArticleCell>
                )}
            </div>
        )
    }

    const renderAlternatives = () => {
        if (!opened || isPart() || isOePart()) {
            return
        }

        return (
            <div className="tk-parts">
                <div className="list">
                    <div className={classes("article-list", style.alternatives)}>
                        {alternativesContext?.articles?.map((x) => (
                            <ArticleItem {...props} key={x.id} item={x} />
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            {renderOeGroups()}
            {renderArticle()}
            {renderAlternatives()}
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        actions: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            $nest: {
                "&--wm .article-actions button.price": {
                    marginRight: em(0.5),
                    width: em(6.25),
                },
                "> :not(:last-child)": {
                    marginRight: em(0.25),
                },
                ".article-actions_price": {
                    display: "flex",
                    flexDirection: "column",
                    paddingLeft: em(0.5),
                    paddingRight: em(1.5),
                    fontSize: theme.font.subtitleSize.m,

                    $nest: {
                        ".text": {
                            padding: em(0),
                            color: theme.colors.primary,
                            fontWeight: theme.font.boldWeight,
                        },
                    },
                },
            },
        },
        alternatives: {
            margin: margin(em(1), 0, em(0.5), em(3)),
            padding: 0,
        },
        oeGroups: {
            display: "flex",
            flexWrap: "wrap",
            marginBottom: em(0.25),
            marginRight: em(-0.5),
        },
        oeGroup: {
            position: "relative",
            marginBottom: em(0.25),
            marginRight: em(0.5),
        },
        badge: {
            fontSize: px(14),
            width: em(2),
            height: em(1),
            top: em(-0.5),
            right: em(-0.5),
            borderWidth: 0,
            zIndex: 1,
        },
    })(ArticleItem)
}
