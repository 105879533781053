import { SearchField } from "@tm/components"
import { useVefilesSearch } from "../../hooks/useVefilesSearch"

export function Search() {
    const {
        search: { tempSearchQuery, setTempSearchQuery, handleSearch },
    } = useVefilesSearch()

    return <SearchField sx={{ width: "250px" }} value={tempSearchQuery} onChange={setTempSearchQuery} onStartSearch={handleSearch} />
}
