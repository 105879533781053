import { important, px } from "csx"
import { useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Icon, ReplaceButton } from "@tm/controls"
import { classes } from "@tm/utils"
import { TextField } from "@tm/components"
import { CustomArticle } from "@tm/models"
import { CalcConsumable, CalculationContext } from "../../../data/model"
import { ArticleCell, BorderedCollapsible, CalcInputComponent } from "../../_shared"
import ReplaceConsumableModal from "../../_shared/modals/ReplaceConsumableModal"
import { useFastCalculatorStore, useStoreContext } from "../../../state"
import { handleAddCustomPartToConsumable } from "../../../business"

export default function ConsumablesList() {
    const style = useMemo(() => getStyle(), [])
    const instance = useStoreContext()

    const selectedCalcState = useFastCalculatorStore((state) => state.selectedCalcState)

    const consumablesList = (selectedCalcState?.context as CalculationContext | undefined)?.consumables
    const [replacementModalItem, setReplacementModalItem] = useState<CalcConsumable | undefined>()

    const handleConsumableReplace = (customArticle: CustomArticle, item: CalcConsumable) => {
        handleAddCustomPartToConsumable(
            instance,
            customArticle.id,
            customArticle.description,
            customArticle.retailPrice ?? 0,
            customArticle.articleNumber,
            item.id
        )
    }

    const renderConsumableItem = (item: CalcConsumable, index: number) => {
        const selectedConsumable = item.articleInput?.dropDownItems?.find((x) => x.isSelected)

        return (
            <div className={style.articleItemEelement} key={item.id}>
                <div className={classes("article-list__item", "article-list__panel", style.articleItem)} key={item.id}>
                    <ArticleCell bemModifier="numbers" className={style.flex}>
                        <TextField style={{ width: "100%" }} label={item.genArtLabel} value={selectedConsumable?.label} size="medium" />
                    </ArticleCell>

                    <ArticleCell bemModifier="description" />

                    <ArticleCell bemModifier="actions" className={style.specifications}>
                        <CalcInputComponent item={item.specifications} />
                    </ArticleCell>

                    <ArticleCell bemModifier="erp-information">
                        <CalcInputComponent item={item.price} />
                    </ArticleCell>

                    <ArticleCell bemModifier="actions" className={style.actionButtons}>
                        <CalcInputComponent item={item.quantity} />
                        <CalcInputComponent item={item.removeInput} />
                        <ReplaceButton className="price" onClick={() => setReplacementModalItem(item)} isWm={false} />
                    </ArticleCell>
                </div>
            </div>
        )
    }

    if (!consumablesList?.consumables?.length) {
        return null
    }

    return (
        <>
            <BorderedCollapsible name={consumablesList?.label ?? ""} initiallyOpened>
                <div className="article-list">{consumablesList.consumables.map(renderConsumableItem)}</div>
            </BorderedCollapsible>
            <div className={style.hint}>
                <div className={style.colorPrimary}>
                    <Icon className={style.bulbColor} name="bulb" /> {`${consumablesList?.hint.title || ""}: `}
                </div>
                <div>{consumablesList?.hint.label || ""}</div>
            </div>
            {replacementModalItem && (
                <ReplaceConsumableModal
                    open
                    onClose={() => setReplacementModalItem(undefined)}
                    consumable={replacementModalItem}
                    replaceConsumable={handleConsumableReplace}
                />
            )}
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        articleItem: { padding: ".5em .5em .25em ", minHeight: "0 !important" },
        specifications: {
            flex: 1,
            $nest: {
                "&>div": {
                    flex: 1,
                },
            },
        },
        actionButtons: {
            paddingRight: "9em",
        },
        consumables: {
            display: "flex",
            alignSelf: "stretch",
            flex: 1,
            $nest: {
                ".suggest--new .scrollbar__view": {
                    position: important("absolute"),
                    marginRight: "-23px",

                    $nest: {
                        ".fancy-list__item": {
                            marginBottom: px(4),
                            backgroundColor: theme.colors.light,
                            boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                        },
                    },
                },
                ".suggest--new": {
                    flex: 1,
                },
                ".suggest": {
                    flex: 1,
                },
            },
        },
        anotherConsumable: {
            display: "flex",
            flex: 1,
            $nest: {
                ".suggest": {
                    flex: 1,
                },
            },
        },
        flex: {
            flex: 0.6,
        },
        hint: {
            display: "flex",
            alignItems: "center",
            padding: ".5em",
            gap: ".35em",
        },
        colorPrimary: {
            color: theme.colors.primary,
            display: "flex",
            alignItems: "center",
            gap: ".35em",
        },
        bulbColor: {
            fill: "currentColor",
        },
        articleItemEelement: {
            margin: "1em 0",
            $nest: {
                "&:last-child": {
                    marginBottom: 0,
                },
            },
        },
    })(ConsumablesList)
}
