import { useState } from "react"
import { MuiCustomModal } from "@tm/components"
import { GeneralDataFields } from "./selections/generalDataFields"
import { tyresWheelsActions, useFastServiceStore } from "../../../../data"
import { SelectedFilters, TireType } from "../../../../data/state/tyres-wheels"

type Props = {
    onComplete(name: string): void
    isFront: boolean
    selectedTabName: string
    tabType: string
    handleCloseModal: () => void
    open: boolean
}

export default function GeneralData({ handleCloseModal, onComplete, isFront, selectedTabName, tabType, open }: Props) {
    const { selectedFilters } = useFastServiceStore((state) => state.tyresWheels.selectedTab)
    const [selectedFiltersTemp, setSelectedFiltersTemp] = useState<SelectedFilters | undefined>(selectedFilters)
    const [selectedTire, setSelectedTire] = useState<TireType | undefined>(undefined)

    const allFiltersHaveValue = Object.keys(selectedFiltersTemp).every((key) => {
        switch (key) {
            case "offsetValue":
            case "tyreModel":
            case "isSummer":
            case "isWinter":
            case "size":
            case "vehicleLabel":
            case "sizeFromSpecs":
            case "rimSize":
                return true
            default:
                return !!selectedFiltersTemp?.[key as keyof SelectedFilters]
        }
    })

    function handleOk() {
        if (selectedFiltersTemp) {
            tyresWheelsActions.updateSelectedFilters(selectedTabName, tabType, selectedFiltersTemp)

            if (selectedTire) {
                tyresWheelsActions.setSelectedTireType(selectedTire)
            }
        }

        onComplete("general")
    }

    return (
        <MuiCustomModal open={open} onOutsideClick={handleCloseModal} handleOkButton={handleOk} disabledOkButon={!allFiltersHaveValue}>
            {selectedFiltersTemp && (
                <GeneralDataFields
                    isFront={isFront}
                    selectedTabName={selectedTabName}
                    tabType={tabType}
                    selectedFilters={selectedFiltersTemp}
                    setSelectedFiltersTemp={setSelectedFiltersTemp}
                    setSelectedTire={setSelectedTire}
                />
            )}
        </MuiCustomModal>
    )
}
