import { createContext, useContext } from "react"

export enum StoreInstanceEnum {
    normal = "normal",
    modal = "modal",
}

export type StoreInstance = StoreInstanceEnum | string

export const StoreContext = createContext<StoreInstance | null>(null)

export const useStoreContext = (): StoreInstance => {
    const instanceKey = useContext(StoreContext)
    if (!instanceKey) {
        // In order to use FastCalculator store you need to wrap it with context,
        // by default it will be normal mode
        return StoreInstanceEnum.normal
    }

    return instanceKey
}
