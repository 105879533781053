import { Link } from "react-router-dom"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { Box, styled } from "@tm/components"

type Props = LocalizationProps & {
    targetUrl?: string
    imageUrl?: string
}

function Component({ localization, targetUrl, imageUrl }: Props) {
    const url = `/external01?url=${encodeURIComponent(targetUrl ?? "")}`
    const background = `url(${imageUrl})`
    const { translateText } = localization

    return (
        <Link style={{ flex: "0 0 50%", paddingTop: "10px" }} className="widget" to={url}>
            <StyledBox sx={{ background }}>
                <StyledText>{translateText(1202).toUpperCase()}</StyledText>
            </StyledBox>
        </Link>
    )
}

const StyledBox = styled(Box)({
    width: "100%",
    backgroundColor: "#7898B1",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "180px",
    marginRight: "10px"
})

const StyledText = styled(Box)({
    backgroundColor: "rgba(51, 51, 51, 0.8)",
    width: "100%",
    bottom: 0,
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "40px",
    height: "40px",
    marginBottom: "10px",
    color: "white",
    fontSize: "14px",
    display: "inline-block"
})

export default withLocalization(Component)
