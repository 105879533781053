import { useUser, useWorkTask } from "@tm/context-distribution"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { decodeUniqueId } from "@tm/utils"
import { batch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { useEffect } from "react"
import { bundleChannel } from "../../business"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { useVehicle } from "../main/business/hooks"
import { MatchParams } from "../summary/components/models"

export default function managerComponent() {
    const vehicle = useSelector((x: MainState) => x.manager.vehicle)
    const reset = useSelector((x: MainState) => x.extern.resetIframe)
    const actions = useActions(MainActions, "setVehicle", "setCustomer", "getHistory", "getVehicleModels", "setErpArticles", "removeIframe", "reset")
    const matchParams = useParams<MatchParams>()

    const user = useUser()
    const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(matchParams?.workTaskId)
    const wor = useWorkTask()

    useVehicle(workTaskId, vehicle, (x) => {
        batch(() => {
            actions.setVehicle(x)
            actions.getVehicleModels()
        })
    })

    useEffect(() => {
        if (!wor?.workTaskLoading) {
            actions.getHistory(wor?.workTask?.vehicle?.tecDocTypeId, wor?.workTask?.vehicle?.id)
        }
    }, [wor?.workTaskLoading])

    useEffect(() => {
        const unsubCustomer = channel("WORKTASK", workTaskId).subscribe("CUSTOMER/LOADED", actions.setCustomer, true)

        const unsubErpModifiers = channel("GLOBAL").subscribe("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
            actions.setErpArticles(erpInfo)
        })

        const unsubModuleClosed = channel("WORKTASK", workTaskId).subscribe("MODULE/CLOSED", (module) => {
            if (module.includes("damage-calculation/eurotax")) {
                actions.reset()
            }
        })

        return () => {
            unsubCustomer?.()
            unsubErpModifiers?.()
            unsubModuleClosed?.()
        }
    }, [])

    useEffect(() => {
        bundleChannel().publish("SET_REPAIR_TIME_DIVISION", user?.userSettings?.repairTimeOptions?.division ?? 1)
    }, [user?.userSettings?.repairTimeOptions?.division])

    useEffect(() => {
        if (reset) {
            const iframe = document.querySelector("#ere-external-url")
            iframe && iframe.remove()

            actions.removeIframe(false)
        }
    }, [reset])

    return null
}
