import { ajax } from "@tm/utils"
import { selectorFamily, useRecoilValue } from "recoil"
import { chatAuthorizationRecoilSelector } from "."
import { getBundleParams } from "../../utils"

const chatUserDisplayNameRecoilSelector = selectorFamily({
    key: "notifications_chat_displayname",
    get:
        (userId: string) =>
        async ({ get }) => {
            const authorization = get(chatAuthorizationRecoilSelector)
            if (authorization) {
                const url = `${getBundleParams().chatApiUrl}/api/v1/users/getdisplayname?userId=${userId}`
                const response = await ajax<{ displayName: string }>({ url, method: "GET", authorization })
                return response?.displayName
            }
        },
})

export function useUserDisplayName(userId: string) {
    return useRecoilValue(chatUserDisplayNameRecoilSelector(userId))
}
