import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { Icon } from "@tm/controls"
import { TelematicsDataResponse, ConditionBasedServiceInfo } from "../../../../data"

type Props = {
    data?: TelematicsDataResponse
    showPartsByProductGroups(productGroups: Array<number>): void
}

enum MaintenanceItem {
    BrakeFluid = 1,
    EngineOil = 2,
}

export default function MaintenanceComponent(props: Props) {
    const { translate, date: formatDate } = useLocalization()
    const [openedOptions, setOpenedOptions] = useState<MaintenanceItem>()

    const handleDropDownOpen = (key: MaintenanceItem) => {
        setOpenedOptions((prev) => (key !== prev ? key : undefined))
    }

    const handleRequestArticleList = (item: MaintenanceItem) => {
        let productGroups: Array<number> = []

        switch (item) {
            case MaintenanceItem.BrakeFluid:
                productGroups = [
                    3357, // Bremsflüssigkeit
                    70, // Bremsbackensatz
                    281, // Bremsbacke
                    407, // Warnkontakt, Bremsbelagverschleiß
                    1537, // Bremsbelagsatz, Trommelbremse
                    402, // Bremsbelagsatz, Scheibenbremse
                    3405, // Bremsensatz, Scheibenbremse
                    3859, // Bremsensatz, Trommelbremse
                    82, // Bremsscheibe
                    515, // Bremsleitung
                    83, // Bremsschlauch
                    169, // Bremsdruckregler
                    74, // Bremskraftverstärker
                ]
                break
            case MaintenanceItem.EngineOil:
                productGroups = [
                    7, // Ölfilter
                    135, // Dichtring - Ölablassschraube
                    3224, // Motoröl
                    4543, // Steckschlüsseleinsatz - Ölablassschraube
                    7981, // Ölfilterschlüssel
                    4890, // Öl-Einfülltrichter
                    597, // Verschluss - Öleinfüllstutzen
                    590, // Gehäuse - Ölfilter
                    3323, // Deckel - Ölfiltergehäuse
                    591, // Dichtung - Ölfiltergehäuse
                ]
                break
            default:
                break
        }

        props.showPartsByProductGroups(productGroups)
    }

    const renderOptions = (item: MaintenanceItem) => {
        return (
            <div className="maintenance__options" title="Display options" onClick={() => handleDropDownOpen(item)}>
                <Icon size="xs" name="box" />
                {openedOptions === item && [
                    <div key="arrow" className="maintenance__options-dropdown-arrow" />,
                    <ul key="dd" className="maintenance__options-dropdown">
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                        <li className="maintenance__option" onClick={() => handleRequestArticleList(item)}>
                            {translate(580)}
                        </li>
                    </ul>,
                ]}
            </div>
        )
    }

    const renderMaintenanceItem = (serviceInfo: ConditionBasedServiceInfo, key: number) => {
        const { date, description, ok, title } = serviceInfo

        let item: MaintenanceItem | undefined
        const ttl = title.toLowerCase()

        // improve detection of maintenance items with link to article list
        if (ttl.indexOf("brems") !== -1 || ttl.indexOf("brake") !== -1) {
            item = MaintenanceItem.BrakeFluid
        } else if (ttl.indexOf("motor") !== -1 || ttl.indexOf("engine") !== -1) {
            item = MaintenanceItem.EngineOil
        }

        return (
            <div key={key} className="maintenance__item">
                <div className="maintenance__headline">{title}</div>
                {ok ? (
                    <div className="maintenance__icon maintenance__icon--okay">
                        OK <Icon name="check-filled" size="s" skin="success" />
                    </div>
                ) : (
                    <div className="maintenance__icon">
                        NICHT OK <Icon name="error-filled" size="s" skin="danger" />
                    </div>
                )}
                <div className="maintenance__term">{description}</div>
                <div className="maintenance__text">
                    {date && formatDate(date, "d")}
                    {item && renderOptions(item)}
                </div>
            </div>
        )
    }

    const { data } = props
    const conditionBasedServiceInfos = data?.conditionBasedServiceInfos || []

    if (!conditionBasedServiceInfos) {
        return null
    }

    return (
        <div className="maintenance">
            <div className="title headline--l">{translate(576)}</div>
            <div className="maintenance__items">{conditionBasedServiceInfos.map(renderMaintenanceItem)}</div>
        </div>
    )
}
