import { stylesheet, style } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export const colorLightGray = "#e2e2e2"
export const lightGrayBorder = `1px solid ${colorLightGray}`
export const headlineClassName = style({ marginTop: "1.5rem", marginBottom: "0.5rem", marginLeft: "-15px" })
export const iconClassName = style({ marginBottom: "15px" })

export const dialogClassName = style({
    $nest: {
        ".dialog-prompt__inner": { minWidth: "60rem" },
        ".dialog-prompt__content": { padding: "1em 1em 0 1.5em" },
        ".dialog-prompt__additional-content": {
            padding: "0 1.5em 1em",
        },
        ".dialog-prompt__text": { fontSize: "1.5em" },
    },
})

export function getComponentStyles() {
    const { box } = getStyleTheme()

    return stylesheet({
        main: {
            padding: ".5rem 1rem .5rem 2rem",
            width: "100%",
            display: "flex",
            flexDirection: "column",
        },
        headlineWrapper: {
            display: "flex",
            height: "3rem",
        },
        headline: {
            marginRight: "2rem",
            display: "flex",
            alignItems: "center",
        },
        contentWrapper: {
            display: "flex",
            flex: 1,
            marginTop: "1rem",
            width: "100%",
            maxWidth: 1600,
            alignSelf: "center",
            maxHeight: "82vh",
        },
        chatlistWrapper: {
            flex: 1,
            background: "#fff",
            border: lightGrayBorder,
            borderRadius: box.radius,
            marginRight: "2rem",
            overflow: "hidden",
            display: "flex",
            flexDirection: "column",
        },
        chatlistLoader: {
            margin: "0.5rem",
        },
        messagesWrapper: {
            flex: 4,
            display: "flex",
        },
        noChatSelected: {
            width: "100%",
            height: "100%",
            background: colorLightGray,
            borderRadius: box.radius,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        },
        noChatSelectedIcon: {
            height: "3rem",
            width: "3rem",
            marginBottom: "0.5rem",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
