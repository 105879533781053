import { Article, ArticleAttribute, AttributeFilterModel, AvailabilityStatus, Customer, ErpInformation, ModificationState } from "@tm/models"

import { FilterType, SearchType } from "../../../business"
import { getBundleParams } from "../../../utils"
import { UsedFilters } from "../business"
import { ConfigProps } from "../wrapper"
import { Props } from "../component"

export type PartListUserSettings = {
    compactView?: boolean // default value will be set by bundleparameters
    useEngineCodeFilter: boolean // default value: false
}

export const getSearchTypeDependentArticleTableProps = (vehicleId: string | undefined, usedFilters: UsedFilters, searchType: SearchType) => {
    let canFilterArticleAttributes = true
    let showMissingVehicleConnection = false
    let selectedArticleAttributes = usedFilters.articleAttributes
    let ignoreAttributeKey = false
    let hasInlineDataSupplierFilter = true

    switch (searchType) {
        case SearchType.DIRECT:
        case SearchType.ALTERNATIVES:
            canFilterArticleAttributes = false
            break
        case SearchType.PARTSLIST:
        case SearchType.ACCESSORYLIST:
        case SearchType.PASSED_IN_ARTICLES:
        case SearchType.OFFERS:
            canFilterArticleAttributes = false
            hasInlineDataSupplierFilter = false
            break
        case SearchType.TRADERARTICLENOS:
            canFilterArticleAttributes = false
            showMissingVehicleConnection =
                !!vehicleId &&
                !!usedFilters.vehicle &&
                !!usedFilters.vehicle.tecDocTypeId &&
                // A little hacky. It should be decided per article, if it was found via the trader article number
                // from the erp or direct search. But that is currently not possible. If the decision is made to show
                // the article source of each article in the alternatives list, this can be replaced.
                !usedFilters.articleForDbAlternatives
            hasInlineDataSupplierFilter = false
            break
        case SearchType.UNINODE:
        case SearchType.UNISEARCH:
        case SearchType.UNIPRODUCTGROUPS:
            selectedArticleAttributes = usedFilters.uniCriteria.map((crit) => `${crit.id}|${crit.value}`)
            ignoreAttributeKey = true
            break
        case SearchType.OE_POSITIONS:
            canFilterArticleAttributes = !getBundleParams().oePositionSearchAsDirectSearch
            break
        default:
    }

    return {
        canFilterArticleAttributes,
        showMissingVehicleConnection,
        selectedArticleAttributes,
        ignoreAttributeKey,
        hasInlineDataSupplierFilter,
    }
}

export const getTmaInfos = (
    usedFilters: UsedFilters,
    searchType: SearchType,
    customer?: Customer
): { vehicleId?: string; customerId?: string; foundBySearchTerm?: string } => {
    let vehicleId
    let foundBySearchTerm

    switch (searchType) {
        case SearchType.DIRECT:
        case SearchType.ALTERNATIVES: {
            foundBySearchTerm = usedFilters.query && usedFilters.query.text
            break
        }
        case SearchType.TRADERARTICLENOS:
        case SearchType.PARTSLIST:
        case SearchType.ACCESSORYLIST:
        case SearchType.PASSED_IN_ARTICLES: {
            if (usedFilters.foundBySearchTerm) {
                foundBySearchTerm = usedFilters.foundBySearchTerm
            } else {
                vehicleId = usedFilters.foundByVehicleId
            }
            break
        }
        default: {
            vehicleId = usedFilters.vehicle && usedFilters.vehicle.id
            foundBySearchTerm = usedFilters.query && usedFilters.query.text
            break
        }
    }

    return {
        vehicleId,
        customerId: customer && customer.id,
        foundBySearchTerm,
    }
}

export const castToArticleAttribute = (productGroupFilter: AttributeFilterModel): ArticleAttribute => {
    return {
        id: productGroupFilter.id,
        abbreviation: productGroupFilter.abbreviation,
        description: productGroupFilter.description,
        key: productGroupFilter.value,
        value: productGroupFilter.value,
        text: productGroupFilter.text,
        unit: productGroupFilter.unit,
        modificationState: ModificationState.NotModified,
        highlight: false,
        isBlockSeperator: false,
        isOptimized: false,
        isDuplicatedAttribute: false,
        sortNo: 0,
    }
}

export function containsReplacementNumber(replacementNumbers: Array<string>, number: string): boolean {
    return replacementNumbers.some((x) => compareReplacementNumbers(x, number))
}

export function compareReplacementNumbers(a: string, b: string): boolean {
    return normalizeReplacementNumber(a) == normalizeReplacementNumber(b)
}

export function normalizeReplacementNumber(number: string): string {
    // this normalization should match the one in the service
    return number.replace(/[\W_]/g, "").toUpperCase()
}

/**
 * returns the searchtype as Uppercase string
 * @param searchtype from routeProp parms from the partlist
 * @returns uppercase string
 */
export function getSearchTypeFromUrl(searchType?: string): SearchType | undefined {
    if (!searchType) {
        return
    }
    return (SearchType as any)[searchType.toUpperCase()] as number | undefined
}

/**
 *
 * @param props Returns the state of the Partlist, is it loading or has it no results
 * @returns [loading, noResult]
 */
export function getStatus(props: Props): [boolean, boolean] {
    const { searchType, loadingFilters, filters, result, oeResult } = props.state
    const { parts } = result

    let loading: boolean
    let noResult: boolean

    switch (searchType) {
        case SearchType.PRODUCTGROUPS: {
            const noFilter = !(loadingFilters & FilterType.ProductGroup) && !filters.productGroups.length
            loading = !noFilter ? !!result.loading : false
            noResult = !parts.length || noFilter
            break
        }
        case SearchType.TRADERARTICLENOS: {
            loading = !!result.loading
            noResult = !result.wholesalerParts?.length && !parts.length
            break
        }
        default: {
            // Show the loader, when result.loading is true or when its undefined (happens when the state gets reset by FILTERS_INITIALIZED)
            loading = result.loading !== false
            noResult = !parts.length

            // If new oe result (e.g. Autonet / Eurotax 4.0) is active ...
            if (oeResult) {
                loading = loading || (oeResult.loading ?? true) // ... also set loading to true when new oe result is loading
                noResult = noResult && !oeResult.parts.length // ... only set noResult to true when new oe result has also no result
            }
        }
    }

    return [loading, noResult]
}

/**
 * prepare configprops for the partlist, merge bundparms and component props
 * @param props partlist props
 * @returns merged configprops
 */
export function getConfigProps(props: Props): ConfigProps {
    const { groupParts, standaloneMode, scrollable, panelTitle, alwaysExpandedArticleItems, showArticleComparision, isInTab } = props

    const { partsRoutes, compactViewAsDefault } = getBundleParams()

    if (!partsRoutes || !partsRoutes.repairTimesRoute) {
        console.error(`Parts-List: 'repairTimesRoute' was not passed as property in the app config`)
    }

    return {
        // set default values for properties which are not specified in config
        groupParts: groupParts !== false,
        standaloneMode: standaloneMode !== false,
        scrollable: scrollable !== false,
        panelTitle: panelTitle !== undefined ? panelTitle.toString() : undefined,
        compactViewAsDefault,
        alwaysExpandedArticleItems,
        showArticleComparision,
        isInTab: !!isInTab,
    }
}

/**
 * creates userSettings from component props and UsersettingsContext
 * @param props partlist props
 * @returns partlisUserSettings
 */
export function getUserSettings(props: Props): PartListUserSettings {
    const { userSettings } = props

    const settings: PartListUserSettings = {
        useEngineCodeFilter: false,
    }

    if (userSettings?.articleListSettings) {
        settings.compactView = userSettings?.articleListSettings?.viewOptions?.compactView
        settings.useEngineCodeFilter = userSettings.articleListSettings.useEngineCodeFilter || false
    }

    return settings
}

/**
 * Returns only items for the given AvailabilityStatus
 * @param parts partlist that should be filterd
 * @param filter the filterd AvailabilityStatus
 * @param defaultErpInfo the erp informations from the default ERP system
 * @param partnerErpInfo optional the erpinformation from the PartnerERP System
 * @returns filterd article Array
 */
export function getFilteredPartsByAvailability(
    parts: Article[],
    filter: AvailabilityStatus[],
    defaultErpInfo: ErpInformation[] | undefined,
    partnerErpInfo: ErpInformation[] | undefined
) {
    return parts.filter((part) => {
        const defaultPartErp = defaultErpInfo?.find((erpData) => erpData.itemId === part.id)
        const partnerPartErp = partnerErpInfo?.find((erpData) => erpData.itemId === part.id)
        if (defaultPartErp && partnerPartErp) {
            const a = filter.includes(defaultPartErp.availability.type || AvailabilityStatus.Undefined)
            const b = filter.includes(partnerPartErp.availability.type || AvailabilityStatus.Undefined)
            return a || b
        }
        if (defaultPartErp) {
            return filter.includes(defaultPartErp.availability.type || AvailabilityStatus.Undefined)
        }
        return true
    })
}

export function getArticleErpInformation(partId: string, defaultErpInfo: ErpInformation[] | undefined) {
    return defaultErpInfo?.find((erpData) => erpData.itemId === partId)
}
