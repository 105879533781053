import { Box, BoxProps, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = BoxProps & {
    titleTextID: string
}

export default function Section({ titleTextID, children, ...rest }: Props) {
    const { translateText } = useLocalization()
    return (
        <StyledBox display="flex" gap="1em" flexDirection="column" {...rest}>
            <Typography variant="h2">{translateText(titleTextID)}</Typography>
            {children}
        </StyledBox>
    )
}

const StyledBox = styled(Box)({
    backgroundColor: "#fbfdff",
    padding: "10px 12px",
    borderRadius: "3px",
})
