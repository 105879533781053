import { TyreFilter } from "@tm/models"
import { TyresListState } from "../model"

export function getSelectedCriteria(state: TyresListState) {
    const { filters, selectedFilters } = state
    const fuelEfficiency = getAllFiltersBefore(filters.fuelEfficiency, selectedFilters.fuelEfficiency)
    const wetGripClass = getAllFiltersBefore(filters.wetGripClass, selectedFilters.wetGripClass)
    const externalRolling = getAllFiltersBefore(filters.externalRolling, selectedFilters.externalRolling)

    const selectedSeasons: TyreFilter[] = !selectedFilters.season ? [] : [selectedFilters.season]

    return [
        ...fuelEfficiency,
        ...wetGripClass,
        ...externalRolling,
        ...selectedFilters.speedIndex ?? [],
        ...selectedFilters.loadIndex ?? [],
        ...selectedFilters.manufacturer ?? [],
        ...selectedFilters.weight ?? [],
        ...selectedFilters.oeIdentifier ?? [],
        ...selectedFilters.extras ?? [],
        ...selectedSeasons
    ]
}

function getAllFiltersBefore(items: TyreFilter[], selected?: TyreFilter) {
    return !selected ? [] : items.filter(item => item.query <= selected.query)
}