import { ElementType } from "react"
import { ListItemIcon, ListItemText, MenuItem, MenuItemProps } from "@tm/components"

export function SubMenuItem<T extends ElementType<unknown>>(props: MenuItemProps<T>) {
    const { children, ...rest } = props

    return (
        <MenuItem {...rest}>
            <ListItemIcon sx={(theme) => ({ color: theme.palette.primary.main })}>▶</ListItemIcon>
            <ListItemText>{children}</ListItemText>
        </MenuItem>
    )
}
