import { PropsWithChildren, useCallback, useEffect, useState } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { Article, ArticleAttribute, ArticleErpInfo } from "@tm/models"
import { ArticleListActionsProvider } from "../ArticleListActions"
import { PartsModuleStateProvider, usePartsModuleState } from "../PartsModuleState"
import { useErpInfos } from "../hooks/useErpInfos"
import { useListOptions } from "../hooks/useListOptions"
import { useProductGroupRepairTimes } from "../hooks/useProductGroupRepairTimes"
import { useShowArticleDetails } from "../hooks/useShowArticleDetails"
import { useSupplierLogos } from "../hooks/useSupplierLogos"
import { useVehicle } from "../hooks/useVehicle"
import { useWatchListData } from "../hooks/useWatchListData"
import { OePartsModuleState, ArticleListActions } from "../models"
import { useArticles } from "./hooks/useArticles"
import { useFilters } from "./hooks/useFilters"
import { useListParams } from "./hooks/useListParams"
import { useBasketQuantities } from "../hooks/useBasketQuantities"
import { useProductGroupTopicIds } from "../hooks/useProductGroupTopicIds"
import { useNotes } from "../hooks/useNotes"
import { useHasLoadingEnded } from "../hooks/useHasLoadingEnded"
import { useTradeReferences } from "../hooks/useTradeReferences"
import { useLoadOePositionsErpInfos } from "../hooks/useErpInfos/useLoadOePartErpInfos"

export function OePartsModuleStateProvider(props: PropsWithChildren<unknown>) {
    const [isEnabled, setIsEnabled] = useState(false)
    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([])

    const { workTaskId } = useWorkTask() ?? {}
    const vehicle = useVehicle()
    const options = useListOptions()
    const params = useListParams()
    const filtersData = useFilters(vehicle, params, isEnabled)
    const articlesData = useArticles(vehicle, params, erpInfos, isEnabled)
    const { productGroupTopicIds } = useProductGroupTopicIds(articlesData.productGroupIds, isEnabled)
    const { tradeReferences, tradeReferenceNumbersLoaded } = useTradeReferences(articlesData.articles, isEnabled)
    const erpInfosData = useErpInfos({
        articles: articlesData.articles,
        isEnabled: isEnabled && tradeReferenceNumbersLoaded,
        startParams: params.startParams,
        tradeReferences,
        erpInfos,
        setErpInfos,
    })
    const supplierLogosData = useSupplierLogos(articlesData.supplierIds, options.showDataSupplierLogos && isEnabled)
    const watchListData = useWatchListData(articlesData.articles, !!options.hasWatchList && isEnabled && erpInfosData.isReady)
    const productGroupRepairTimesData = useProductGroupRepairTimes(articlesData.productGroupIds, isEnabled && erpInfosData.isReady)
    const activeVehicleDataProvidersData = useActiveVehicleDataProviders(workTaskId)
    const showArticleDetails = useShowArticleDetails()
    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(articlesData.articles, isEnabled && erpInfosData.isReady)
    const notes = useNotes(articlesData.articles, true, isEnabled && erpInfosData.isReady)

    const loadErpInfos = useLoadOePositionsErpInfos(erpInfosData)

    useEffect(() => {
        if (isEnabled && params.oePositions.length) {
            loadErpInfos(params.oePositions)
        }
    }, [params.oePositions, isEnabled])

    const hasLoadingEnded = useHasLoadingEnded(
        supplierLogosData.isLoading,
        watchListData?.isLoading,
        productGroupRepairTimesData.isLoading,
        activeVehicleDataProvidersData.isLoading
    )

    const toggleInlineFilter = useCallback((attribute: ArticleAttribute, article: Article) => {
        params.toggleAttribute(attribute)
    }, [])

    /**
     * Do not wrap it with `useMemo` here but threat it as not reference stable and therefor not use it as any dependency for other hooks.
     */
    const state: OePartsModuleState = {
        type: "oe",
        start: useCallback(() => setIsEnabled(true), []),
        options,
        params,
        filters: filtersData,
        articles: articlesData,
        supplierLogos: hasLoadingEnded ? supplierLogosData : undefined,
        watchListData: hasLoadingEnded ? watchListData : undefined,
        productGroupRepairTimes: hasLoadingEnded ? productGroupRepairTimesData : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProvidersData.activeProviders : undefined,
        basketQuantities,
        productGroupTopicIds,
        notes,
        tradeReferences,
        erpInfosData,
    }

    const articleActionsValue: ArticleListActions = {
        toggleSupplier: filtersData.toggleSupplier,
        toggleInlineFilter,
        showArticleDetails,
        updateBasketQuantities,
    }

    return (
        <PartsModuleStateProvider value={state}>
            <ArticleListActionsProvider value={articleActionsValue}>{props.children}</ArticleListActionsProvider>
        </PartsModuleStateProvider>
    )
}

export function useOePartsModuleState(): OePartsModuleState
export function useOePartsModuleState<TReturnType>(selector: (value: OePartsModuleState) => TReturnType): TReturnType
export function useOePartsModuleState<TReturnType>(selector?: (value: OePartsModuleState) => TReturnType): OePartsModuleState | TReturnType {
    return usePartsModuleState<OePartsModuleState, TReturnType>((context) => {
        if (!selector) {
            return context as unknown as TReturnType
        }

        return selector(context) as unknown as TReturnType
    })
}
