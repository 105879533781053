import { encodeBase64 } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { LoginErrorResponse, LoginResponse } from "../../models"

export async function SindriLogin(APIKey: string, APIKeyID: string) {
    const url = `${getBundleParams().sindriAuthUrl}/oauth2/token`
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Basic ${encodeBase64(`${APIKeyID}:${APIKey}`)}`,
            timeout: "100000",
        },
        body: new URLSearchParams({
            grant_type: "client_credentials",
            scope: "customer/api",
            client_id: APIKeyID,
        }),
    })

    if (!response.ok) {
        const data = (await response?.json()) as LoginErrorResponse
        throw new Error(data.error)
    }

    return response.json() as Promise<LoginResponse>
}
