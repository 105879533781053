import { useState, useEffect, useCallback } from "react"
import { MergedVehicle } from "../../../../../data/hooks"
import { bundleChannel } from "../../../../../bundle-channel"
import { CustomerInfo } from "../../../../../data"
import { OpenWorkTaskDialog } from "./workflow"

export type WrapperProps = {
    chatId: string
    tmCustomerId: string | undefined
    customerInfo: CustomerInfo
}

export type OpenWorkTaskDialogState = {
    selectedChatVehicle: MergedVehicle
    subRoute?: string
    skipRenderRoute?: boolean
    modal?: { route: string; params?: Record<string, string> }
}

export default function Wrapper({ chatId, tmCustomerId, customerInfo }: WrapperProps) {
    const [state, setState] = useState<OpenWorkTaskDialogState>()

    useEffect(() => {
        bundleChannel("CHAT").subscribe("OPEN_WORKTASK", setState)
    }, [])

    useEffect(() => {
        setState(undefined)
    }, [tmCustomerId])

    const handleClose = useCallback(() => {
        setState(undefined)
    }, [])

    return state ? (
        <OpenWorkTaskDialog chatId={chatId} tmCustomerId={tmCustomerId} customerInfo={customerInfo} state={state} onClose={handleClose} />
    ) : null
}
