import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { ConfiguratorImage } from "../../model"

export const getConfiguratorImage = (vehicleId: string, workTaskId: string) => {
    const url = `${getBundleParams().wheelsHistoryServiceUrl}/GetConfigurationFile`
    const authorization = getStoredAuthorization()
    const body = { vehicleId, workTaskId }

    return ajax<ConfiguratorImage>({ url, body, authorization, method: "GET" }).then((data) => data?.configurationFile || "")
}