import { useParams } from "react-router"

import { Divider, Grid } from "@tm/components"
import { VoucherRouteParams } from "../../business"
import { useDefaultVoucherType } from "../../hooks/useDefaultVoucherType"
import { RowStack } from "../_shared/StyledComponents"
import ExpandableVoucherList from "./components/ExpandableVoucherList"
import VoucherDetailsComponent from "./components/voucher-details"
import Navigation from "./components/navigation"

type Props = {
    externalSystemId?: number
    hideVehicleLogos?: boolean
}

export default function VouchersList({ externalSystemId, hideVehicleLogos }: Props) {
    const defaultVoucherType = useDefaultVoucherType()
    const params = useParams<VoucherRouteParams>()

    const selectedVoucherId = params.id && decodeURIComponent(params.id)

    if (!defaultVoucherType) {
        return null
    }

    return (
        <Grid display="grid" gridTemplateRows="auto 1fr" flex={1}>
            <Navigation externalSystemId={externalSystemId} />
            <RowStack divider={<Divider orientation="vertical" />} overflow="hidden">
                <ExpandableVoucherList externalSystemId={externalSystemId} isExpanded={!selectedVoucherId} />
                {!!selectedVoucherId && <VoucherDetailsComponent hideVehicleLogos={hideVehicleLogos || false} />}
            </RowStack>
        </Grid>
    )
}
