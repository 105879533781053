import { Badge, Icon, IconButton, Loader, styled } from "@tm/components"
import { useQueryClient } from "react-query"
import { openBase64Pdf } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { Repositories } from "../../../data"

type Props = {
    jobId: string
    disabled?: boolean
}

export default function PrintButton({ jobId, disabled }: Props) {
    const queryClient = useQueryClient()
    const { translateText } = useLocalization()
    const [loading, setLoading] = useState(false)

    async function handlePrintClick(e: React.MouseEvent<HTMLElement>) {
        setLoading(true)
        e.preventDefault()
        e.stopPropagation()
        try {
            const printData = await queryClient.fetchQuery({ queryKey: "sindri_PDF", queryFn: () => Repositories.getJobPDF(jobId) })
            if (printData) {
                openBase64Pdf(printData.pdfBase64, translateText)
            }
        } catch (error) {}

        setLoading(false)
    }

    return (
        <IconButton sx={{ marginLeft: "auto", marginRight: "24px" }} onClick={handlePrintClick} disabled={loading || disabled}>
            {loading && (
                <StyledBadge size="small">
                    <Loader size="extrasmall" />
                </StyledBadge>
            )}
            <Icon name="print" />
        </IconButton>
    )
}

const StyledBadge = styled(Badge)({
    position: "absolute",
    top: "0",
    right: "3px",
})
