import { useMemo } from "react"
import { Link, useRouteMatch } from "react-router-dom"
import { Icon } from "@tm/components"
import { classes, encodeUniqueId, renderRoute } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { ModuleNavigationProps } from "../component"
import { getStyleFromConfig, isDigit } from "../helpers"

type DashboardItemProps = Pick<ModuleNavigationProps, "style" | "workTaskRoute" | "worktaskDashboardTitle"> & {
    isSelected: boolean
    clickEvent: () => void
}

export function DashboardItem({ style, workTaskRoute, worktaskDashboardTitle, isSelected, clickEvent }: DashboardItemProps) {
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const routeMatch = useRouteMatch()

    function getComponentUrl() {
        // don't use worktaskId para if not set, as example "open Usersettings"
        const worktaskObj = workTaskId ? { workTaskId: encodeUniqueId(workTaskId) } : {}
        const urlParms = { ...routeMatch.params, ...worktaskObj }
        return renderRoute(workTaskRoute ?? routeMatch.path, urlParms).replace(/\/$/, "")
        // TODO: Fix hotfix, this.props.match.path has an ending / which causes "ModuleComponent.prototype.handleClearStoresOnRouteChange" to clear the store (url is not the same anymore)
        return renderRoute(routeMatch.path, routeMatch.params).replace(/\/$/, "")
    }

    const componentUrl = getComponentUrl()

    const dashboardTitle = useMemo(() => {
        if (worktaskDashboardTitle) {
            return isDigit.test(worktaskDashboardTitle) ? translateText(worktaskDashboardTitle) : worktaskDashboardTitle
        }
        return translateText(671)
    }, [worktaskDashboardTitle])

    const className = classes(classes("tab navigation__item", isSelected && "is-selected"), getStyleFromConfig("tab", style, { layout: "module" }))
    return (
        <Link to={componentUrl} onClick={clickEvent}>
            <div className={className}>
                <Icon name="dashboard" className="icon tab__icon" size="16px" />
                <div className="tab__content">
                    <div className="tab__title">{dashboardTitle}</div>
                </div>
            </div>
        </Link>
    )
}
