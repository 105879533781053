import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { Suspense } from "react"

import { useModelDetails } from "@tm/utils"
import SelectionsCard from "./SelectionsCard"

type Props = {
    vehicleType: VehicleType
    modelId: number | undefined
    backUrl: string
    isCurrent: boolean
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={<SelectionsCard isLoading isCurrent={props.isCurrent} isLast />}>
            <CardModel {...props} />
        </Suspense>
    )
}

function CardModel({ vehicleType, modelId, isCurrent, backUrl }: Props) {
    const { translateText } = useLocalization()
    const model = useModelDetails({ vehicleType, modelId })

    return (
        <SelectionsCard
            isCurrent={isCurrent}
            isLast
            description={!model?.modelDetails ? translateText(74) : undefined}
            content={model?.modelDetails?.description}
            resetUrl={model?.modelDetails ? backUrl : undefined}
        />
    )
}
