import { Tooltip } from "@tm/components"
import { Button, LicensePlateField, Table, Text } from "@tm/controls"
import { ILocalizationContext } from "@tm/localization"
import { VehicleShortInfo } from "@tm/models"
import { concat, plateCountryCodeMapper } from "@tm/utils"

export function renderVehicleShortInfoAutosuggestColumns(
    suggestions: Array<VehicleShortInfo>,
    localization: ILocalizationContext,
    plateCode: string
) {
    const hasPlateColumn = suggestions.some((x) => x.plateId)
    const defaultColumns = []

    if (hasPlateColumn) {
        defaultColumns.push(
            <Table.Column
                key="suggestions-license-plate-field"
                className="plate"
                renderItemContent={(suggestion: VehicleShortInfo) => (
                    <Table.Cell key={`${suggestion.plateId}`}>
                        {!!suggestion.plateId && (
                            <LicensePlateField
                                readonly
                                size="s"
                                shortCountryCode={plateCountryCodeMapper(suggestion.countryCode || plateCode)}
                                value={suggestion.plateId}
                            />
                        )}
                    </Table.Cell>
                )}
            />
        )
    }

    defaultColumns.push(
        <Table.Column
            key="suggestions-description"
            className="description"
            renderItemContent={(suggestion: VehicleShortInfo) => (
                <Table.Cell>
                    <Text size="s">{concat(" ", suggestion.manufacturer, suggestion.modelSeries, suggestion.model)}</Text>
                </Table.Cell>
            )}
        />,
        <Table.Column
            key="suggestions-vin"
            className="vin"
            renderItemContent={(suggestion: VehicleShortInfo) => <Table.Cell>{suggestion.vin && <Text>{suggestion.vin}</Text>}</Table.Cell>}
        />,
        <Table.Column
            key="suggestions-action"
            className="action"
            renderItemContent={() => (
                <Table.Cell>
                    <Tooltip title={localization.translateText(1041)}>
                        <Button icon="paperclip" size="xs" layout={["ghost"]} />
                    </Tooltip>
                </Table.Cell>
            )}
        />
    )

    return defaultColumns
}
