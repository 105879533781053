import { useCallback, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Alert, Box, Button, Checkbox, Modal, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { getBundleParams } from "../../../utils"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"

const selector = (s: MainState) => ({
    acceptPdf: s.summary.acceptPdf,
    showModal: s.summary.showAcceptModal,
})

export default function UserAgrementModal() {
    const { translateText } = useLocalization()
    const [accepted, setAccepted] = useState(false)
    const blocked = useRef(false)
    const actions = useActions(MainActions, "changeModalDisplay", "customerRegistered")
    const { acceptPdf, showModal } = useSelector(selector)
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch()
    const [open, setOpen] = useState(true)

    const handleModuleClose = useCallback(() => {
        setOpen(false)
        actions.changeModalDisplay(false)

        const workTaskId = getCurrentWorkTaskId()

        if (workTaskId && !blocked.current) {
            setTimeout(() => {
                channel("WORKTASK", workTaskId).publish("MODULE/CLOSED", location.pathname + location.search)
                history.push(renderRoute("/:workTaskId", { workTaskId: encodeUniqueId(workTaskId) }))
            }, 10)
        }
    }, [location, blocked])

    const handleConfirm = useCallback(() => {
        const { datRegistrationPath } = getBundleParams()
        const datRegistrationRoute = (datRegistrationPath && renderRoute(datRegistrationPath, match.params)) || ""

        actions.changeModalDisplay(false)
        blocked.current = false

        setTimeout(() => {
            Morpheus.showView("1", datRegistrationRoute)

            channel("GLOBAL").subscribeOnce("MODAL/CLOSED", () => {
                !blocked.current && handleModuleClose()
            })

            channel("GLOBAL").subscribeOnce("DMG_DAT/REGISTRATION_RESULT", ({ registrationDone }: { registrationDone?: boolean }) => {
                if (registrationDone) {
                    blocked.current = true
                    actions.customerRegistered()
                    Morpheus.closeView("1")
                }
            })
        }, 10)
    }, [match, blocked])

    const renderAlert = () => (
        <Alert
            title={translateText(827)}
            severity="error"
            variant="outlined"
            borderLeft
            action={
                <Button disabled={!accepted} onClick={handleConfirm} color="success">
                    {translateText(13110)}
                </Button>
            }
        >
            <Box className="content">
                <Typography variant="body2">{translateText(13108)}</Typography>
                <Box display="flex" alignItems="center" gap="1em">
                    <Checkbox checked={accepted} onClick={() => setAccepted(!accepted)} />
                    <Typography>{translateText(1709)}</Typography>
                </Box>
            </Box>
        </Alert>
    )

    if (!showModal) {
        return null
    }

    return (
        <Modal onOutsideClick={handleModuleClose} open={open}>
            <Box display="flex" flexDirection="column" height="100%" flex="1">
                {renderAlert()}
                <iframe title="Agrement PDF" style={{ flex: 1 }} src={acceptPdf} />
            </Box>
        </Modal>
    )
}
