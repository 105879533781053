import { Box, Stack, Tooltip, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CostEstimationOptions } from "@tm/models"
import { useMemo } from "react"

type Props = {
    costEstimationOptions?: CostEstimationOptions
}

export function CostEstimationMode({ costEstimationOptions }: Props) {
    const { customerGroupMode, priceTypeMode, priceVatMode } = costEstimationOptions || {}
    const { translateText } = useLocalization()

    const [activeMode, tooltiTitle] = useMemo(() => {
        let mode = ""
        const modeTexts = []
        if (priceVatMode) {
            mode = translateText(13658)
            modeTexts.push(`${translateText(13737)} (${translateText(13658)}).`)
        } else {
            mode = translateText(13657)
            modeTexts.push(`${translateText(13736)} (${translateText(13657)}).`)
        }

        if (priceTypeMode) {
            mode += ` - ${translateText(55)}`
            modeTexts.push(`${translateText(13738)} (${translateText(55)}).`)
        } else {
            mode += ` - ${translateText(1620)}`
            modeTexts.push(`${translateText(13739)} (${translateText(1620)}).`)
        }

        if (customerGroupMode) {
            mode += ` - ${translateText(13659)}`
            modeTexts.push(`${translateText(13740)} (${translateText(13659)}).`)
        } else {
            mode += ` - ${translateText(13660)}`
            modeTexts.push(`${translateText(13741)} (${translateText(13660)}).`)
        }

        const tooltip = (
            <Stack gap={0.5}>
                {modeTexts.map((modeText) => (
                    <Box key={modeText}>{modeText}</Box>
                ))}
            </Stack>
        )

        return [mode, tooltip]
    }, [priceVatMode, priceTypeMode, customerGroupMode])

    return (
        <Tooltip title={tooltiTitle} sx={{ whiteSpace: "pre" }} placement="bottom-end" width="none">
            <Typography variant="label" textTransform="uppercase">
                {`${translateText(13743)}: ${activeMode}`}
            </Typography>
        </Tooltip>
    )
}
