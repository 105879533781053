import { getModuleFromUserContext, useUser, useWorkTask } from "@tm/context-distribution"
import { merge } from "lodash"
import { UserModuleType } from "@tm/models"
import { useWidgetModules } from "../../../hooks/useWidgetModules"
import { AllWidgetKeys, Overrides, WidgetProp } from "../models"
import { useMultiWidgetTabsModules } from "../../../hooks/useMultiWidgetTabsModules"
import { useOtherModules } from "../../../hooks/useOtherModules"
import { createCatalogWidgetTree } from "../../../helpers/createCatalogWidgetTree"

const defaultOverrides: Partial<Record<AllWidgetKeys, WidgetProp>> = {
    tools: { hidden: true },
}

export function useDashboardTemplateSix(overrides?: Overrides) {
    const workTask = useWorkTask()
    const user = useUser()
    const userContext = user?.userContext

    const finalOverrides = merge(defaultOverrides, overrides)

    const { widgetModuleList } = useWidgetModules(userContext, "DASHBOARD", finalOverrides)
    const { multiWidgetTabs } = useMultiWidgetTabsModules(userContext, "DASHBOARD", finalOverrides)

    function getShown(widgetKey: AllWidgetKeys): boolean {
        return !finalOverrides?.[widgetKey]?.hidden
    }

    const otherModules = useOtherModules("DASHBOARD", !!userContext, () => {
        const offersModule = getModuleFromUserContext(userContext, UserModuleType.Offers)

        const fastDtcModule = getModuleFromUserContext(userContext, UserModuleType.FastDTC)
        const gpiEurotaxModule = getModuleFromUserContext(userContext, UserModuleType.TMEurotax)
        const tyresModule = getModuleFromUserContext(userContext, UserModuleType.TMTires)
        const uniPartsPremiumModule = getModuleFromUserContext(userContext, UserModuleType.UnipartsPremium)
        const partsIndicatorModule = getModuleFromUserContext(userContext, UserModuleType.PartsIndicator)

        const catalogWidgetTree = createCatalogWidgetTree(
            {
                eurotaxSearch: !!gpiEurotaxModule,
                universalParts: !!uniPartsPremiumModule,
                fastDTC: !!fastDtcModule,
                tiresSearch: !!tyresModule,
                predictive: !!partsIndicatorModule,
                vehicleParts: true,
                directSearch: true,
            },
            finalOverrides
        )

        return {
            catalogWidgetTree,
            offersWidget: !!offersModule || getShown("offersWidget"),
        }
    })

    return {
        workTaskId: workTask?.workTaskId,
        widgetModuleList,
        multiWidgetTabs,
        otherModules,
        finalOverrides,
    }
}
