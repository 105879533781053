import { PanelSection } from "@tm/controls"
import { Article, DMS } from "@tm/models"
import { useMemo } from "react"

import { DmsArticleInformation } from "@tm/components"
import { getComponentStyles } from "./styles"
import { useDmsArticleInfo } from "../../hooks/useDmsArticleInfo"

type Props = {
    part: Article
}

export function DmsArticleInfo({ part }: Props) {
    const className = useMemo(() => getComponentStyles(), [])
    const dmsArticleInfo = useDmsArticleInfo(part)
    const { stockItem } = dmsArticleInfo

    if (!stockItem || (!stockItem.invoiced && (stockItem.availability === undefined || stockItem.availability === DMS.Availability.Undefined))) {
        return null
    }

    return (
        <PanelSection className={className}>
            <DmsArticleInformation dmsArticleInfo={dmsArticleInfo} />
        </PanelSection>
    )
}
