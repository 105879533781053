import { ArticleDetailsResponse } from "../../../../data/model"
import { ArticleRequestForDocuments } from "../model"

export function mapArticleDetailsForDocumentsRequest(articleDetails?: ArticleDetailsResponse): ArticleRequestForDocuments {
    if (articleDetails && articleDetails.article) {
        return {
            dealerPartNumber: articleDetails.article.traderArticleNo,
            deliveryDate: "",
            description: articleDetails.article.productGroup?.name ?? "",
            manufacturerPartsCode: articleDetails.article.supplierArticleNo,
            quantity: (articleDetails.quantity ? articleDetails.quantity : articleDetails.article.quantity).toString(),
            productGroupName: articleDetails.article.productGroup.name,
        }
    }

    return {
        dealerPartNumber: "",
        deliveryDate: "",
        manufacturerPartsCode: "",
        quantity: "",
        description: "",
        productGroupName: "",
    }
}
