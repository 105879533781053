import { RepairShop } from "@tm/context-distribution"
import { CisCustomerResponse } from "@tm/models"
import { RepairShopRequestForDocuments } from "../model"

export function mapRepairShopForDocumentsRequest(repairShop?: RepairShop, customer?: CisCustomerResponse): RepairShopRequestForDocuments {
    const getPostalAddres = () => {
        let repairShopAdress = ""
        if (window.userContext.hasTelesales && customer?.addresses?.length && customer.addresses[0]) {
            const data = customer.addresses[0]
            repairShopAdress += `${customer.companyName || ""}\n`
            repairShopAdress += `${data?.street || ""} ${data?.streetNo || ""}\n`
            repairShopAdress += `${data.zip || ""} ${data.city || ""}`
        } else if (repairShop) {
            if (repairShop.companyName) {
                repairShopAdress += `${repairShop.companyName}\n`
            }
            if (repairShop.postalAddress) {
                if (repairShop.postalAddress.street) {
                    repairShopAdress += `${repairShop.postalAddress.street}\n`
                }
                if (repairShop.postalAddress.zip) {
                    repairShopAdress += `${repairShop.postalAddress.zip} `
                }
                if (repairShop.postalAddress.city) {
                    repairShopAdress += repairShop.postalAddress.city
                }
            }
        }
        return repairShopAdress
    }

    if (repairShop) {
        return {
            companyName: (repairShop.postalAddress && repairShop.companyName) || "",
            firstName: (repairShop.postalAddress && repairShop.contactPerson && repairShop.contactPerson.firstName) || "",
            lastName: (repairShop.postalAddress && repairShop.contactPerson && repairShop.contactPerson.lastName) || "",
            city: (repairShop.postalAddress && repairShop.postalAddress.city) || "",
            country: (repairShop.postalAddress && repairShop.postalAddress.country) || "",
            street: (repairShop.postalAddress && repairShop.postalAddress.street) || "",
            zip: (repairShop.postalAddress && repairShop.postalAddress.zip) || "",
            postalAddress: getPostalAddres(),

            repairshopNr: "",
            state: "",
            administrator: "",
        }
    }

    return {
        firstName: "",
        lastName: "",
        companyName: "",
        postalAddress: "",
        city: "",
        state: "",
        repairshopNr: "",
        country: "",
        street: "",
        zip: "",
        administrator: "",
    }
}
