import { Suspense } from "react"
import { IMicros } from "@tm/models"
import { Icon } from "@tm/controls"
import { Box, Tooltip, styled } from "@tm/components"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-sale"]

function ErpInfoSaleComponent(props: Props) {
    const { loading, erpInfo } = useErpInfo(props.data, "list")

    if (loading || !erpInfo?.isPromotional) {
        return null
    }

    return (
        <Tooltip title={erpInfo.promoText}>
            <Icon name="aktionspreis" size="xl" skin="danger" />
        </Tooltip>
    )
}

const StyledBox = styled(Box)({
    "& .icon": {
        height: "1.5rem",
        width: "4rem",
        opacity: 1,
    },
})

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <Box className="tk-erp">
                <StyledBox className="erp-info-sale">
                    <Suspense fallback={null}>
                        <ErpInfoSaleComponent {...props} />
                    </Suspense>
                </StyledBox>
            </Box>
        </ErpWrapper>
    )
}
