import { classes, useUser } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Box, Loader, Typography, styled } from "@tm/components"
import { MainState } from "../../main"
import { itemsSelector, totalsSelector, worksSelector } from "../../main/business"

type PanelDataModel = {
    panel: string
    total: string
    texts: { panelTitle: number }
    count?: number
}

const globalSelector = createSelector(
    totalsSelector,
    itemsSelector,
    worksSelector,
    (s: MainState) => s.calculation.initialSparePartsPrice,
    (s: MainState) => s.calculation.updateTotalsInProgress,
    (s: MainState) => s.calculation.items?.filter((x) => !!x.selectedPart)?.length > 0,
    (_, b, c, d, e, f) => ({
        ..._,
        itemsLength: b.length,
        worksLength: c.length,
        initialSparePartsPrice: d,
        updateTotalsInProgress: e,
        showInitialSparePartsPrice: f,
    })
)

export default function Totals() {
    const { translateText, currency } = useLocalization()
    const user = useUser()
    const currencyString = getCurrencyFromUserContext(user?.userContext)

    const {
        totalGrossPrice,
        totalNetPrice,
        totalSparePartsPrice,
        totalWorksPrice,
        itemsLength,
        worksLength,
        initialSparePartsPrice,
        updateTotalsInProgress,
        showInitialSparePartsPrice,
    } = useSelector(globalSelector)

    const totals: Array<PanelDataModel> = [
        {
            panel: "parts",
            count: itemsLength,
            total: currency(totalSparePartsPrice, currencyString),
            texts: { panelTitle: 90 },
        },
        {
            panel: "repair-times",
            count: worksLength,
            total: currency(totalWorksPrice, currencyString),
            texts: { panelTitle: 83 },
        },
        {
            panel: "total",
            total: currency(totalNetPrice, currencyString),
            texts: { panelTitle: 62 },
        },
        {
            panel: "total vat",
            total: currency(totalGrossPrice, currencyString),
            texts: { panelTitle: 263 },
        },
    ]

    const renderPanelSection = (data: PanelDataModel) => {
        const panelTitle = (data.count && `${data.count} ${translateText(data.texts.panelTitle)}`) || translateText(data.texts.panelTitle)
        return (
            <PanelSection className={classes(data.panel, "cadran")} title={panelTitle}>
                <Box className={classes("price", updateTotalsInProgress && "updating")}>
                    <Typography className="value">{data.total}</Typography>
                    {initialSparePartsPrice && showInitialSparePartsPrice && data.panel === "parts" && (
                        <Typography className="value additional-info">
                            ({`${translateText(3007)}: ${currency(initialSparePartsPrice, currencyString)}`})
                        </Typography>
                    )}
                </Box>

                {updateTotalsInProgress && (
                    <Box position="absolute" top="50%" left="25%">
                        <Loader />
                    </Box>
                )}
            </PanelSection>
        )
    }

    return <ArticlesSummaryStyled className="articles-summary">{totals.map(renderPanelSection)}</ArticlesSummaryStyled>
}

const ArticlesSummaryStyled = styled(Box)(() => ({
    alignContent: "center",
    display: "inline-flex",
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "1.8em",
    ".cadran": {
        backgroundColor: "transparent",
        boxShadow: "none !important",
        borderBottom: ".4em",
        borderBottomStyle: "solid",
        borderBottomColor: "#c3c3c3",
        margin: "1em",
        marginBottom: 0,
        marginTop: 0,
        paddingTop: "1em",
        padding: 0,
        ".panel": {
            "&__title": {
                fontSize: "1.4rem",
                border: "none",
                fontWeight: 200,
                paddingTop: ".2em",
                whiteSpace: "nowrap",
            },
            "&__content": {
                ".price": {
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    position: "relative",
                    flexWrap: "wrap",
                    "&.updating": {
                        opacity: 0.3,
                    },
                    ".value": {
                        fontSize: "1.5rem",
                        fontWeight: 600,
                        textIndent: "1rem",
                        whiteSpace: "nowrap",
                        "&.additional-info": {
                            flex: "0 0 100%",
                            fontSize: "1rem",
                            color: "black",
                        },
                    },
                },
            },
        },
    },
}))
