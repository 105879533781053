import { useEffect, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useFastUpStorageUris } from "./useFastUpStorageUris"
import { useFastUpTransferInfo } from "./useFastUpTransferInfo"
import useDebounce from "../useDebounce"

export function useFastUpUpload(onUploadComleted: (url: string) => void) {
    const { language } = useLocalization()
    const [isFinished, setIsFinished] = useState(false)
    const { transferInfo, refetch: refetchTransferInfo, isLoading } = useFastUpTransferInfo()
    const [retryCount, setRetryCount] = useState(0)

    const { storageUris } = useFastUpStorageUris(transferInfo?.transferId, !isFinished)

    const uploadStarted = useMemo(() => {
        return storageUris?.some((uri) => uri.endsWith(".start"))
    }, [storageUris])

    useEffect(() => {
        const finished = storageUris?.some((uri) => uri.endsWith(".finish"))
        const fileUrl = storageUris?.find((uri) => !uri.endsWith(".finish") && !uri.endsWith(".start"))

        if (finished && fileUrl) {
            onUploadComleted(fileUrl)
            setIsFinished(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageUris, isFinished])

    useEffect(() => {
        if (!transferInfo) {
            return
        }

        const expirationTime = new Date(transferInfo.expirationDateUtc).getTime()
        if (expirationTime <= 0) {
            return
        }

        const timeout = setTimeout(() => {
            setIsFinished(false)
            refetchTransferInfo()
        }, expirationTime - Date.now())

        return () => {
            clearTimeout(timeout)
        }
    }, [transferInfo, refetchTransferInfo])

    useDebounce(
        () => {
            if (uploadStarted || isFinished || retryCount >= 5) {
                return
            }

            if (!isLoading && !transferInfo) {
                refetchTransferInfo()
                setRetryCount((prev) => prev + 1)
            }
        },
        500,
        [isLoading, uploadStarted, isFinished, transferInfo]
    )

    return {
        isLoading,
        uploadStarted,
        uploadUri: transferInfo?.uploadUri ? `${transferInfo?.uploadUri}&lang=${language}` : undefined,
    }
}
