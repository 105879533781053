import { useState } from "react"
import { CarModel, channel, VehicleType } from "@tm/models"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useHistory, useParams } from "react-router"
import { renderRoute } from "@tm/utils"
import { getShowCategorizedSearchQuery } from "../../../../data/repositories"
import { showModelsByQuery } from "../../../../../../vehicle/src/data/repositories/cars/showModels"
import { handleUniqueVehicleFound } from "../../../../../../vehicle/src/helpers/vrm"
import { mapCategrizedSearchToArticleListRequest } from "./requestMapper"

const TMTYRES_MODULE_ID = 210

export function useAiSearch() {
    const [loading, setLoading] = useState(false)
    const { attachToWorkTask, workTask } = useWorkTask() ?? {}
    const history = useHistory()
    const matchParams = useParams()
    const { userContext } = useUser()

    // look for a vhicle, first try to finde it overe licensplateVinKba after that use the query
    const findeVehicle = async (query: string) => {
        if (query) {
            const vehicleResponse = await showModelsByQuery({ query })
            return vehicleResponse?.models
        }
    }

    const analyseQuery = async (query: string) => {
        const categorizedSearch = await getShowCategorizedSearchQuery({ SearchQuery: query })
        let firstVehicle: CarModel | undefined

        if (categorizedSearch.splitedSearchQueryParts) {
            // first, check if vehicle informations are part of the splitedSearchQuery and try to find a Vehicle
            const vehicleQuery =
                categorizedSearch.splitedSearchQueryParts.vin ||
                categorizedSearch.splitedSearchQueryParts.kba ||
                categorizedSearch.splitedSearchQueryParts.licencePlate ||
                categorizedSearch.splitedSearchQueryParts.vehicleDescription

            if (vehicleQuery) {
                // if there is a Vehicle, select the first one and attach it to the open worktask, important the last flag,
                // it will always create a new Worktask if we have conflicts
                const vehicleModel = await findeVehicle(vehicleQuery)
                firstVehicle = vehicleModel?.first()
                if (firstVehicle && attachToWorkTask) {
                    await handleUniqueVehicleFound(
                        firstVehicle,
                        VehicleType.All,
                        vehicleQuery,
                        attachToWorkTask,
                        undefined,
                        undefined,
                        undefined,
                        true
                    )
                }
            }
        }

        // need check if module is active!
        const tyresD = categorizedSearch.splitedSearchQueryParts.tyreDimensions
        const hasTyresModule = userContext.modules?.find((val) => val.moduleSubId === TMTYRES_MODULE_ID)
        if (tyresD?.height && tyresD?.width && tyresD?.rim && hasTyresModule) {
            const tyresSearchRoute = "/:workTaskId/tyres/list/:query?"
            const queryCombi = `${tyresD?.width}/${tyresD?.height}R${tyresD?.rim}`
            history.push(
                renderRoute(tyresSearchRoute, {
                    ...matchParams,
                    query: encodeURIComponent(queryCombi),
                })
            )
        } else {
            const request = mapCategrizedSearchToArticleListRequest(categorizedSearch, !!firstVehicle || !!workTask?.vehicle)

            if (request) {
                channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
            } else {
                // todo: display a buitifuly message in the Frontend
                console.warn("No Matching results: ", categorizedSearch)
            }
        }
    }

    const onSearch = (query: string) => {
        setLoading(true)
        analyseQuery(query).finally(() => {
            setLoading(false)
        })
    }

    return { onSearch, loading }
}
