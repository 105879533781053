import { Box, Dialog, Typography, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    dialogTextId: number
    showDeleteDialog: boolean
    onCloseRemoveDialog(): void
    onRemoveReturnItems(): void
}
export default function RemoveItemsDialogComponent({ dialogTextId, showDeleteDialog, onCloseRemoveDialog, onRemoveReturnItems }: Props) {
    const { translateText } = useLocalization()

    return (
        <Dialog open={showDeleteDialog} skin="warning" position="top" maxWidth="lg">
            <Typography component="span" pl={2}>
                {translateText(dialogTextId)}
            </Typography>
            <Box pl={2} textAlign="center" display="initial">
                <Button variant="contained" onClick={onCloseRemoveDialog}>
                    {translateText(584)}
                </Button>
                <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={onRemoveReturnItems}>
                    {translateText(585)}
                </Button>
            </Box>
        </Dialog>
    )
}
