import { useRef } from "react"
import { NumberSelect } from "@tm/components"

type Props = {
    value: number
    division?: number
    min?: number
    max?: number
    disabled?: boolean
    onChangeQuantity(value: number): void
}

export default function ArticleQuantity({ value, max, min, division, disabled, onChangeQuantity }: Props) {
    const minQuantity = min || 0
    const maxQuantity = max || 9999
    const steps = 1
    const minAmount = minQuantity > steps ? minQuantity : division
    const changeQuantityTimeout = useRef<number>()

    const handleChangeQuantity = (quantityValue: number) => {
        if (quantityValue !== value) {
            if (changeQuantityTimeout.current) {
                window.clearTimeout(changeQuantityTimeout.current)
            }

            changeQuantityTimeout.current = window.setTimeout(() => {
                onChangeQuantity(quantityValue)
            }, 250)
        }
    }

    return (
        <NumberSelect
            value={value}
            maxAmount={maxQuantity}
            minAmount={minAmount}
            steps={steps}
            division={division}
            onValueChange={handleChangeQuantity}
            disabled={disabled}
            roundedType="ceil"
        />
    )
}
