import React from "react"
import { useLocalization } from "@tm/localization"
import { Button, Icon, Loader } from "@tm/components"

type Props = {
    timeValue?: number
    loading?: boolean
    hasErrors?: boolean
}

export default function WorkTimeComponent({ loading, timeValue, hasErrors }: Props) {
    const { number, translateText } = useLocalization()

    if (loading) {
        return <Loader size="small" />
    }

    if (hasErrors) {
        return <Button variant="text" startIcon={<Icon name="error-filled" />} title={translateText(787)} />
    }

    return <>{timeValue ? number(timeValue, 2) : "-"}</>
}
