import { OE, Article, DataSupplier, ProductGroup } from "@tm/models"
/**
 * @deprecated implement useLoadErpInfos with OeParts and remove this mapper
 * @param part
 * @param quantity
 * @returns
 */
export function mapOePartToArticle(part: OE.OePart, quantity: number): Article {
    const id = part.id || (part.description + part.number).hashCode()
    return {
        id,
        internalId: id,
        description: part.description,
        traderArticleNo: part.traderNumber,
        supplierArticleNo: part.number,
        supplier: {
            id: part.manufacturerId,
            name: part.manufacturerName,
        } as DataSupplier,
        productGroup: {} as ProductGroup,
        requestErpInfo: true,
        oeArticleOrigin: part.oeArticleOrigin,
        quantity,
    } as Article
}
