import { em, margin, url } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EFilterNames } from "@tm/models"
import { bem, classes } from "@tm/utils"
import { CarTypeSeasonFilters, DiameterFilters } from "../../components/_shared"
import { SpecialCarTypes } from "../../data/enums"
import { Statics } from "../../data/statics"
import { useWidgetFilters } from "../../hooks/widgetFilters"

export default function TyresSearch() {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    const {
        state: {
            loading,
            filters,
            selectedFilters,
            selectedFiltersDefaultVehicle
        },
        handlers: {
            handleFilterChange,
            handleResetAll,
            handleNormalSearch
        }
    } = useWidgetFilters()

    return (
        <div className={classes(style.widget)}>
            <div className={style.wrapper}>
                <Text modifiers="sub">{translateText(1562)}</Text>
                <hr className={style.separator} />
                <CarTypeSeasonFilters
                    filterName={EFilterNames.carType}
                    disabled={loading}
                    items={Statics.getCarTypes()}
                    selectedItemValue={selectedFiltersDefaultVehicle ?? selectedFilters?.carType}
                    onChange={handleFilterChange}
                />

                <hr className={style.separator} />
                <CarTypeSeasonFilters
                    filterName={EFilterNames.season}
                    disabled={loading || selectedFilters?.carType.every(x => SpecialCarTypes.includes(+x.query))}
                    items={Statics.seasons}
                    selectedItemValue={selectedFilters.season}
                    onChange={handleFilterChange}
                />

                <hr className={style.separator} />
                <div className={bem(style.mainFilters, loading && "loading")}>
                    <DiameterFilters
                        widths={filters.width}
                        heights={filters.height}
                        inches={filters.inch}
                        selectedHeight={selectedFilters.height}
                        selectedInch={selectedFilters.inch}
                        selectedWidth={selectedFilters.width}
                        onChange={handleFilterChange}
                        loading={loading}
                        onResetAll={handleResetAll}
                    />
                    <div className={style.searchButton}>
                        <Button
                            skin="highlight"
                            icon="search"
                            onClick={handleNormalSearch}
                            disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}>
                            {translateText(135)}
                        </Button>
                    </div>
                </div>
            </div>
        </div>

    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        widget: {
            backgroundImage: url("/styles/base/images/bitmap-copy.png"),
            backgroundPositionY: em(2),
            backgroundRepeat: "no-repeat",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"

        },
        wrapper: {
            margin: margin(0, em(13)),
            width: em(25)
        },
        mainFilters: {
            display: "flex",
            flexDirection: "column",
            $nest: {
                "&--loading": {
                    position: "relative",
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                    $nest: {
                        ".popover": {
                            display: "none",
                        }
                    }
                },
                ".dropdown": {
                    position: "static",
                    $nest: {
                        ".popover": {
                            top: "30.5em",
                            width: "8em"
                        }
                    }
                }
            }
        },
        separator: {
            border: 0,
            opacity: theme.opacity.secondary,
            height: 0,
            borderTop: "1px solid rgba(0, 0, 0, 0.1)",
            borderBottom: "1px solid rgba(255, 255, 255, 0.3)"
        },
        searchButton: {
            display: "flex",
            justifyContent: "flex-end"
        }
    })(TyresSearch)
}
