import { useLocalization } from "@tm/localization"
import { Widget } from "@tm/controls"
import { useCisCustomer } from "@tm/context-distribution"
import { Loader } from "@tm/components"
import CustomerDataComponent from "../_shared/customer/CustomerData"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"

export default function WidgetMyDataComponent() {
    const { translate } = useLocalization()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()

    return (
        <Widget size="2x2" active title={translate(1085)} iconName="user" header={<WidgetHeaderTitleComponent iconName="user" titleTextId={1085} />}>
            {cisCustomerLoading && <Loader />}
            {!cisCustomerLoading && cisCustomer && <CustomerDataComponent cisCustomer={cisCustomer} titleTextId={1125} showElectronicAdress />}
        </Widget>
    )
}
