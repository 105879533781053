import { useMemo } from "react"
import { Box, Loader } from "@tm/components"
import { Voucher } from "../../../../data/model"
import WmVoucherDetailsHead from "./WmVoucherDetailsHeader"
import VoucherItemsSummary from "../../../_shared/VoucherItemsSummary"
import WmVoucherItemsTable from "./WmVoucherItemsTable"
import { useOrderDetails } from "../../../../data/hooks/useOrderDetails"
import { NoResultHint } from "../../../_shared/NoResultHint"
import { ErrorMessage } from "../../../_shared/ErrorMessage"
import { ColumnStack } from "../../../_shared/StyledComponents"

type Props = {
    endOfList: boolean
    selectedVoucherId: string
    voucherTypeId?: number
    vouchers: Array<Voucher>
}

export default function WmVoucherDetailsComponent({ endOfList, voucherTypeId, vouchers, selectedVoucherId }: Props) {
    const selectedVoucher = useMemo(() => {
        return vouchers.find((voucher) => voucher.voucherId === selectedVoucherId)
    }, [vouchers, selectedVoucherId])

    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && { voucherId: selectedVoucher.voucherId, voucherTypeId }
    )

    let content = <></>
    if (selectedVoucher) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (orderDetails?.voucherItems?.length) {
            content = (
                <ColumnStack>
                    <Box height="100%">
                        <WmVoucherDetailsHead key="AlternativeVoucherHead" voucher={selectedVoucher} voucherTypeId={voucherTypeId} />
                        <WmVoucherItemsTable key="VoucherItemsTable" voucherItems={orderDetails.voucherItems} voucherTypeId={voucherTypeId} />
                    </Box>
                    <VoucherItemsSummary
                        key="VoucherItemsSummary"
                        voucherItems={orderDetails.voucherItems}
                        currencyCode={selectedVoucher.currencyCode}
                        hideTotal
                    />
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    } else if (endOfList) {
        content = <NoResultHint />
    }
    return <Box flex={1}>{content}</Box>
}
