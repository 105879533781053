import { Container } from "@tm/nexus"
import { RegisteredModels } from "@tm/models"
import { encodeUniqueId } from "@tm/utils"
import { bundleChannel } from "../../business"

export function useEnableCostEstimation() {
    function enableCostEstimation(workTaskId: string) {
        // TODO: Remove Hermes and Nexus usages. Instead, store state in recoil with ViewState synchronization
        const container = Container.getInstance<{ key: string; value: { state: boolean } }>(RegisteredModels.ViewState)
        const subscribe = container.subscribe("COST_ESTIMATION_VISIBLE")
        subscribe.save?.({ key: `${encodeUniqueId(workTaskId)}__COST_ESTIMATION_VISIBLE`, value: { state: true } })
        bundleChannel().publish("ENABLE_COST_ESTIMATION", {})
    }

    return { enableCostEstimation }
}
