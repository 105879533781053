import { useState } from "react"
import { useUser } from "@tm/context-distribution"
import { Article, ArticleAttributes as ArticleAttributesModel } from "@tm/models"
import { Image, Text, Button } from "@tm/controls"
import { Stack } from "@tm/components"
import ArticleAttributes from "./select-article-attributes"
import ArticleNumber from "../../../_shared/ArticleNumber"

type Props = {
    className?: string
    article: Article
    selectArticle: (article: Article) => void
}

export default function ArticleListItemViewComponent({ className, article, selectArticle }: Props) {
    const userContext = useUser()?.userContext
    const [showAllAttributes, setShowAllAttributes] = useState<boolean>(false)

    function handleButtonClick() {
        selectArticle(article)
    }

    function handleAttributeMoreToggle() {
        setShowAllAttributes(!showAllAttributes)
    }

    function filterAttributes(attr: ArticleAttributesModel): ArticleAttributesModel {
        return {
            topAttributes: attr.topAttributes,
            articleAttributes: [],
            partsListAttributes: [],
            vehicleAttributes: [],
        }
    }

    function renderActions() {
        return <Button icon="search" skin="primary" onClick={handleButtonClick} />
    }

    function renderAttributes() {
        let { attributes } = article
        if (!showAllAttributes) {
            attributes = attributes.map(filterAttributes)
        }

        return <ArticleAttributes attributes={attributes} isCompact={!showAllAttributes} toggleMoreAttributes={handleAttributeMoreToggle} />
    }

    function renderNumbers() {
        return (
            <Stack
                sx={{ flex: "0 0 16em", minWidth: "100px", alignItems: "flex-start !important" }}
                direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}
            >
                {!userContext?.parameter.hideDealerPartNumber && (
                    <ArticleNumber
                        productGroupId={article.productGroup.id}
                        supplierArticleNumber={article.supplierArticleNo}
                        supplierId={article.supplier?.id}
                        wholesalerArticleNumber={article.traderArticleNo}
                        articleType="wholesaler"
                        onOpenDetails={() => {}}
                    />
                )}
                <ArticleNumber
                    productGroupId={article.productGroup.id}
                    supplierArticleNumber={article.supplierArticleNo}
                    supplierId={article.supplier?.id}
                    wholesalerArticleNumber={article.traderArticleNo}
                    articleType="supplier"
                    onOpenDetails={() => {}}
                />
            </Stack>
        )
    }

    function renderDescription() {
        const description = article.description ? article.description : ""
        const additionalDescription = article.additionalDescription ? article.additionalDescription : null
        return (
            <div className="description">
                <Text size="s">{article.productGroup && article.productGroup.name ? article.productGroup.name : null}</Text>
                <Text size="s">{description + (additionalDescription ? ` ${additionalDescription}` : "")}</Text>
            </div>
        )
    }

    const baseName = className || "part-list"

    return (
        <div className={`${baseName}__item`}>
            <div className={`${baseName}__properties`}>
                <div className="toggle-attributes no-separator">
                    <Button layout={["ghost"]} icon={showAllAttributes ? "up" : "down"} onClick={handleAttributeMoreToggle} />
                </div>
                <div className="thumbnail no-separator">
                    <Image url={article.thumbnail ? article.thumbnail : ""} type="article" />
                </div>
                <div className="manufacturer">{article.supplier && <Text title={article.supplier.name}>{article.supplier.name}</Text>}</div>
                {renderNumbers()}
                {renderDescription()}
                <div className="actions">{renderActions()}</div>
                <div className="article__cell article__cell--attributes no-separator">{renderAttributes()}</div>
            </div>
        </div>
    )
}
