import { CarMainModelSeries, CarManufacturer, CarModelSeries, VehicleType } from "@tm/models"
import { useCallback } from "react"
import { atomFamily, useRecoilState } from "recoil"

type Selection = {
    selectedManufacturer?: CarManufacturer
    selectedMainModelSeries?: CarMainModelSeries
    selectedModelSeries?: CarModelSeries
}

const selectionsRecoilAtom = atomFamily<Selection, VehicleType>({
    key: "vehicle_search-tree-widget_selections",
    default: {},
})

export function useSelections(vehicleType: VehicleType) {
    const [state, setState] = useRecoilState(selectionsRecoilAtom(vehicleType))

    const setManufacturer = useCallback((selectedManufacturer: CarManufacturer) => {
        setState({ selectedManufacturer })
    }, [])

    const setMainModelSeries = useCallback((selectedMainModelSeries: CarMainModelSeries) => {
        setState((prev) => ({ selectedManufacturer: prev.selectedManufacturer, selectedMainModelSeries }))
    }, [])

    const setModelSeries = useCallback((selectedModelSeries: CarModelSeries) => {
        setState((prev) => ({ ...prev, selectedModelSeries }))
    }, [])

    const removeState = () => {
        setState({})
    }

    return { ...state, setManufacturer, removeState, setMainModelSeries, setModelSeries }
}
