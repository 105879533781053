import { ChangeEvent, Dispatch, SetStateAction } from "react"
import { TextField, Icon, IconButton, SelectTextField, MenuItem, Stack } from "@tm/components"
import { Note as ArticleNoteInterface, CustomArticleItem } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { ArticleNote } from "../../../article-note"
import { EditorButtons } from "./EditorButtons"
import { useCustomArticlesCategories } from "../../../../../data/hooks/useCustomArticlesCategories"
import { VatRateSelectionItem } from "../../../../../data/model"
import { StackRow } from "../../../../StyledComponents"
import { VatRateSelector } from "../../../../VatRateSelector"

type Props = {
    articleNote?: ArticleNoteInterface
    activeArticle?: CustomArticleItem & { articleNote?: string }
    customPartVatRates?: VatRateSelectionItem[]
    onArticleChange: Dispatch<SetStateAction<(CustomArticleItem & { articleNote?: string | undefined }) | undefined>>
    saveArticleInformation(): Promise<void>
    closeEditor(): void
}

export default function RowToEdit(props: Props) {
    const { activeArticle, customPartVatRates, articleNote, saveArticleInformation, closeEditor, onArticleChange } = props
    const { translateText } = useLocalization()
    const { categories } = useCustomArticlesCategories()

    if (!activeArticle?.id) {
        return null
    }

    const onChangeArticleNumber = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        onArticleChange({
            ...activeArticle,
            articleNumber: value,
        })
    }

    const onChangeArticleDescription = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        onArticleChange({
            ...activeArticle,
            description: value,
        })
    }

    const handleCategoryChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        onArticleChange({
            ...activeArticle,
            category: parseInt(value),
        })
    }

    const handleArticleNoteChange = (note: string) => {
        onArticleChange({
            ...activeArticle,
            articleNote: note,
        })
    }

    const handleSelectVatRateType = (newVatRate: VatRateSelectionItem) => {
        onArticleChange({
            ...activeArticle,
            vatRate: {
                vatRate: newVatRate.vatValue,
                vatType: newVatRate.vatType,
            },
        })
    }

    const handleChangeDefaultCategory = () => {
        onArticleChange({
            ...activeArticle,
            isDefaultForCategory: !activeArticle.isDefaultForCategory,
        })
    }

    function parseValue(value: string) {
        let parsedValue = value
        if (/^\d+(,\d{0,2})?$/.test(value)) {
            parsedValue = parsedValue.replace(/,/g, ".")
        }
        return Number(parsedValue)
    }

    const handlePurchasePriceChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        if (!value) {
            onArticleChange({
                ...activeArticle,
                purchasePrice: undefined,
            })
            return
        }

        onArticleChange({
            ...activeArticle,
            purchasePrice: parseValue(value),
            purchasePriceRounded: parseValue(value),
        })
    }

    const handleRetailPriceChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) => {
        if (!value) {
            onArticleChange({
                ...activeArticle,
                retailPrice: undefined,
                retailPriceRounded: undefined,
            })
            return
        }

        onArticleChange({
            ...activeArticle,
            retailPrice: parseValue(value),
            retailPriceRounded: parseValue(value),
        })
    }

    return (
        <StackRow justifyContent="space-between" alignItems="flex-start" paddingY={1} spacing={1}>
            <Stack flex={1} spacing={0.5}>
                <StackRow spacing={2}>
                    <TextField
                        sx={{ mt: 0 }}
                        id="articleNumber"
                        fullWidth
                        label={translateText(87).toUpperCase()}
                        value={activeArticle.articleNumber}
                        onChange={onChangeArticleNumber}
                    />
                    <TextField
                        fullWidth
                        id="description"
                        required
                        error={!activeArticle.description}
                        label={translateText(25).toUpperCase()}
                        value={activeArticle.description}
                        onChange={onChangeArticleDescription}
                    />
                    <IconButton
                        disabled={!activeArticle.category || activeArticle.category === 3060}
                        onClick={handleChangeDefaultCategory}
                        title={translateText(12702).toUpperCase()}
                    >
                        {activeArticle.isDefaultForCategory ? <Icon name="filled-star" /> : <Icon name="vote-star" />}
                    </IconButton>
                    <SelectTextField
                        fullWidth
                        label={translateText(158).toUpperCase()}
                        value={`${categories?.find((x) => x.textId === activeArticle.category)?.textId}`}
                        maxLayerHeight={300}
                        customIcon={(props) => <Icon {...props} name="down" style={{ marginRight: "8px" }} />}
                        onChange={handleCategoryChange}
                        size="small"
                    >
                        {categories?.map((cat) => (
                            <MenuItem key={cat.textId} value={cat.textId}>
                                {translateText(cat.textId)}
                            </MenuItem>
                        )) || []}
                    </SelectTextField>
                    <TextField
                        label={translateText(1132).toUpperCase()}
                        type="number"
                        variant="filled"
                        sx={{ minWidth: "100px" }}
                        value={activeArticle.purchasePriceRounded}
                        onChange={handlePurchasePriceChange}
                    />
                    <TextField
                        label={translateText(1257).toUpperCase()}
                        type="number"
                        variant="filled"
                        sx={{ minWidth: "100px" }}
                        value={activeArticle.retailPriceRounded}
                        onChange={handleRetailPriceChange}
                    />
                    {customPartVatRates && (
                        <VatRateSelector
                            vatType={activeArticle.vatRate?.vatType}
                            vatRates={customPartVatRates}
                            onChange={(newVatRate) => handleSelectVatRateType(newVatRate)}
                        />
                    )}
                </StackRow>
                <ArticleNote saveInProgress={false} onChange={handleArticleNoteChange} note={articleNote} />
            </Stack>
            <EditorButtons canSave={!!activeArticle.description} onSave={saveArticleInformation} onAbort={closeEditor} />
        </StackRow>
    )
}
