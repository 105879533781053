import { Warehouse, Availability, AvailabilityStatus, ItemTour, SpecialProcurementErpInformation } from "@tm/models"
import { AvailabilityMultiQuantity } from "@tm/controls"
import { getBundleParams } from "../utils"
import { AvailabilityImageOnly } from "./components/AvailabilityImageOnly"
import { isManufacturerAvailable } from "./utils"
import { WarehouseTableCurrentItemImage } from "./components/WarehouseTableCurrentItemImage"

type Props = {
    availability?: Availability
    tour?: ItemTour | string
    warehouses?: Array<Warehouse>
    warehouseTableCurrentItem?: Warehouse
    specialProcurementInformation?: SpecialProcurementErpInformation
    onClick?(): void
}
export const AvailabilityComponent = ({
    tour,
    warehouses,
    warehouseTableCurrentItem,
    specialProcurementInformation,
    availability,
    onClick,
}: Props) => {
    if (warehouses || specialProcurementInformation) {
        let available = 0
        let otherWarehouses = 0
        let otherWarehousesPaid = 0

        const addAvailabilityFromWarehouse = (w: Warehouse) => {
            if (w.isDefault) {
                available += w.quantities?.[0]?.value || 0
            } else if (isManufacturerAvailable(w)) {
                otherWarehousesPaid += w.quantities?.[0]?.value || 0
            } else {
                otherWarehouses += w.quantities?.[0]?.value || 0
            }
        }

        if (warehouses && getBundleParams().availabilityFirstWarehouse) {
            if (warehouses[0]) {
                addAvailabilityFromWarehouse(warehouses[0])
            }
        } else {
            warehouses?.forEach(addAvailabilityFromWarehouse)
        }

        const availabilities: Array<{
            availability: Pick<Availability, "type" | "icon">
            quantity: number
        }> = []

        if (available) {
            availabilities.push({
                availability: {
                    type: AvailabilityStatus.Available,
                    icon: { useIconFromUrl: true, url: "/styles/coparts/images/availability_2.png" },
                },
                quantity: available,
            })
        }

        if (otherWarehouses) {
            availabilities.push({
                availability: {
                    type: AvailabilityStatus.AvailableInAlternativeWarehouse,
                    icon: { useIconFromUrl: true, url: "/styles/coparts/images/availability_5.png" },
                },
                quantity: otherWarehouses,
            })
        }

        if ((specialProcurementInformation && specialProcurementInformation.confirmedQuantity > 0) || otherWarehousesPaid) {
            availabilities.push({
                availability: {
                    type: AvailabilityStatus.AvailableInCentralWarehouse,
                    icon: { useIconFromUrl: true, url: "/styles/coparts/images/availability_7.png" },
                },
                quantity: specialProcurementInformation?.confirmedQuantity || otherWarehousesPaid,
            })
        }

        if (!availabilities.length) {
            return <AvailabilityImageOnly url="/styles/coparts/images/availability_0.png" onClick={onClick} />
        }

        let tourText
        if (!specialProcurementInformation) {
            tourText = tour && typeof tour === "object" && tour.expectedDeliveryString
        }

        return (
            <AvailabilityMultiQuantity
                availabilities={availabilities}
                onClick={onClick}
                additionalText={tourText || specialProcurementInformation?.availability.shortDescription || availability?.shortDescription}
            />
        )
    }
    if (warehouseTableCurrentItem) {
        return <WarehouseTableCurrentItemImage warehouseTableCurrentItem={warehouseTableCurrentItem} />
    }

    return null
}
