import { important, percent } from "csx"
import { useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { SubTitle, SuggestionFieldButtonGroup, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EFilterNames, channel } from "@tm/models"
import { classes } from "@tm/utils"
import { SpecialCarTypes } from "../../data/enums"
import { Statics } from "../../data/statics"
import { useWidgetFilters } from "../../hooks/widgetFilters"
import { CarTypeSeasonFilters, DiameterFilters } from "../_shared"
import { SimpleCard, SxProps, Theme } from "@tm/components"

type Props = {
    sx?: SxProps<Theme>
}

export function TyresSearchWidgetCompact(props: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])
    const [query, setQuery] = useState<string>("")

    const {
        state: {
            loading,
            filters,
            selectedFilters,
        },
        handlers: {
            handleFilterChange,
            handleResetAll,
            handleNormalSearch
        }
    } = useWidgetFilters()

    const handleMatchCodeSearch = () => {
        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { size: query })
    }

    return (
        <SimpleCard className="tyresWidgetCompact" icon="tire" title={translateText(240)} sx={props.sx}>
            <SubTitle>{translateText(1883)}</SubTitle>
            <SuggestionFieldButtonGroup
                value={query}
                onChange={setQuery}
                onChangeConfirm={handleMatchCodeSearch}
                placeholder={translateText(1828)}
                handleSearchButtonClick={handleNormalSearch}
                suggestions={[]}
                requestSuggestions={() => { } }
                tooltip={translateText(1836)} 
            />
            <br />

            <SubTitle>{translateText(1562)}</SubTitle>
            <div className={style.wrapper}>
                <div className={classes(style.element, style.carTypes)}>
                    <SubTitle className={style.label}>{translateText(1782)}</SubTitle>
                    <CarTypeSeasonFilters
                        filterName={EFilterNames.carType}
                        disabled={loading}
                        items={Statics.getCarTypes()}
                        selectedItemValue={selectedFilters?.carType}
                        onChange={handleFilterChange} />
                </div>
                <div className={classes(style.element, style.seasons)}>
                    <SubTitle className={style.label}>{translateText(738)}</SubTitle>
                    <CarTypeSeasonFilters
                        filterName={EFilterNames.season}
                        disabled={loading || selectedFilters.carType.every(x => SpecialCarTypes.includes(+x.value))}
                        items={Statics.seasons}
                        selectedItemValue={selectedFilters?.season}
                        onChange={handleFilterChange} />
                </div>
            </div>
            <div>
                <SubTitle className={style.label}>{translateText(743)}</SubTitle>
                <DiameterFilters
                    className={style.diameterFilters}
                    widths={filters.width}
                    heights={filters.height}
                    inches={filters.inch}
                    selectedHeight={selectedFilters.height}
                    selectedInch={selectedFilters.inch}
                    selectedWidth={selectedFilters.width}
                    onChange={handleFilterChange}
                    onSearch={handleNormalSearch}
                    onResetAll={handleResetAll}
                    loading={loading}
                    maxItemsToShow={6}
                />
            </div>
        </SimpleCard>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        widget: {
            $nest: {
                ".widget__content": {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around"
                }
            }
        },
        element: {
            flexBasis: "50%",
            $nest: {
                ".btn": {
                    marginLeft: theme.margin.xs
                }
            }
        },
        carTypes: {
            flexBasis: percent(55)
        },
        seasons: {
            flexBasis: percent(45)
        },
        label: {
            overflow: important("visible")
        },
        wrapper: {
            display: "flex",
        },
        diameterFilters: {
            flex: 1
        }
    })(TyresSearchWidgetCompact)
}
