import { ajax, getStoredAuthorization } from "@tm/utils"
import { DMS } from "@tm/models"
import { getServiceUrl } from "."

export type ImportCustomerRequest = {
    workTaskId: string
    customer: DMS.Customer
    vehicle?: DMS.Vehicle
}

export function importCustomer(request: ImportCustomerRequest): Promise<void> {
    return ajax({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportCustomer`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}
