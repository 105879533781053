import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
// eslint-disable-next-line import/no-unresolved
import tinycolor from "tinycolor2"

export function getComponentStyles(): NestedCSSProperties {
    const { colors, opacity } = getStyleTheme()
    const headerForegroundColor = tinycolor(colors.header).isLight() ? colors.dark : colors.light

    return {
        display: "flex",
        alignItems: "center",
        opacity: opacity.primary,
        padding: "0 1rem",
        $nest: {
            "&__icon": {
                opacity: opacity.primary,
                marginRight: "0.5rem",
                fill: headerForegroundColor,
                height: "1.5rem",
                width: "1.5rem",
            },
            "&__content > .text": {
                color: headerForegroundColor,
            },
        },
    }
}
