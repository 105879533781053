import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import tinycolor from "tinycolor2"
import { types } from "typestyle"
// eslint-disable-next-line import/no-unresolved
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { ButtonLayout, ButtonSkins, Dropdown, Icon, PanelSection, SubTitle, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { OeReplacementManufacturer } from "../../../../../data/model"
import { compareReplacementNumbers, containsReplacementNumber } from "../../utils"

type Props = {
    query?: string
    manufacturers: Array<OeReplacementManufacturer>
    onReplacementSelect(replacement: string): void
}

type OeReplacementNumber = {
    number: string
}

export default function OeReplacements(props: Props) {
    const style = useMemo(() => getStyle(), [])
    const { translate } = useLocalization()

    const { query, manufacturers, onReplacementSelect } = props

    const [selectedManufacturerId, setSelectedManufacturerId] = useState<number>()
    const [selectedReplacementNumber, setSelectedReplacementNumber] = useState<string>()

    useEffect(() => {
        if (!query) {
            return
        }

        const manufacturer =
            manufacturers.find((x) => x.id == selectedManufacturerId) ?? // First keep already selected manufacturer if it is still present in manufacturer list
            manufacturers.find((x) => containsReplacementNumber(x.replacementNumbers, query)) ?? // If not select the first manufacturer which has a replacement number matching the query
            manufacturers.first() // At least select the first manufacturer
        setSelectedManufacturerId(manufacturer?.id)

        setSelectedReplacementNumber(
            manufacturer?.replacementNumbers.find((x) => compareReplacementNumbers(x, query)) // Select the replacement number of the currently selected manufacturer
        )
    }, [query, manufacturers, selectedManufacturerId])

    const selectedManufacturer = useMemo(() => {
        return manufacturers.find((x) => x.id == selectedManufacturerId)
    }, [selectedManufacturerId, manufacturers])

    const selectedReplacement = useMemo(() => {
        return selectedReplacementNumber ? { number: selectedReplacementNumber } : undefined
    }, [selectedReplacementNumber])

    const replacements: Array<OeReplacementNumber> = useMemo(() => {
        return selectedManufacturer?.replacementNumbers.map((number) => ({ number })) ?? []
    }, [selectedManufacturer])

    const selectManufacturer = useCallback((manufacturer: OeReplacementManufacturer) => {
        setSelectedManufacturerId(manufacturer.id)
    }, [])

    const selectReplacement = useCallback(
        (replacement: OeReplacementNumber) => {
            setSelectedReplacementNumber(replacement.number)
            onReplacementSelect(replacement.number)
        },
        [onReplacementSelect]
    )

    const manufacturersItemView = useCallback((manufacturer: OeReplacementManufacturer) => {
        return <div className={style.item}>{manufacturer.name}</div>
    }, [])

    const replacementsDisplayView = useCallback((replacement: OeReplacementNumber) => {
        return <div className={style.item}>{replacement.number}</div>
    }, [])

    const replacementsItemView = useCallback(
        (replacement: OeReplacementNumber) => {
            const currentIndex = replacements.findIndex((x) => x.number == replacement.number)

            return (
                <div className={style.item}>
                    <Icon className={classes(style.itemIcon, currentIndex == 0 ? style.itemIconHidden : undefined)} name="arrow-long-left-up" />
                    {replacement.number}
                </div>
            )
        },
        [replacements]
    )

    const replacementsItemWrapper = useCallback(
        (props: { children: ReactNode }) => {
            if (replacements.length <= 1) {
                return <>{props.children}</>
            }

            return (
                <>
                    <SubTitle size="s" className={classes(style.dropdownLabel, style.dropdownLabelTop)}>
                        {translate(3006)}
                    </SubTitle>
                    {props.children}
                    <SubTitle size="s" className={classes(style.dropdownLabel, style.dropdownLabelBottom)}>
                        {translate(3005)}
                    </SubTitle>
                </>
            )
        },
        [replacements.length]
    )

    const hasValue = !!selectedReplacement

    return (
        <PanelSection className={style.panel}>
            <SubTitle className={style.label} size="s">
                {translate(3004)}
            </SubTitle>

            <Dropdown<OeReplacementManufacturer>
                className={style.dropdown}
                layout={getButtonLayout(hasValue)}
                skin={getButtonSkin(hasValue)}
                isActive={hasValue}
                items={manufacturers}
                disabled={!manufacturers.length}
                displayView={manufacturersItemView}
                itemView={manufacturersItemView}
                coverView={useCallback(
                    () => (
                        <Text>{translate(71)}</Text>
                    ),
                    []
                )}
                value={selectedManufacturer}
                onChange={selectManufacturer}
                amountItemsToShow={15}
            />

            <Dropdown<OeReplacementNumber>
                className={style.dropdown}
                layout={getButtonLayout(hasValue)}
                skin={getButtonSkin(hasValue)}
                isActive={hasValue}
                items={replacements}
                disabled={!selectedManufacturer}
                displayView={replacementsDisplayView}
                itemView={replacementsItemView}
                coverView={useCallback(
                    () => (
                        <Text>{translate(758)}</Text>
                    ),
                    []
                )}
                itemWrapper={replacementsItemWrapper}
                value={selectedReplacement}
                onChange={selectReplacement}
                amountItemsToShow={15}
            />

            <div className={style.hint}>
                <Icon className={style.hintIcon} name="info" />
                <div className={style.hintTexts}>
                    <Text className={style.hintText1} modifiers="block" size="s">
                        {translate(12440)}
                    </Text>
                    <Text className={style.hintText2} modifiers="block" size="xs">
                        {translate(12441)}
                    </Text>
                </div>
            </div>
        </PanelSection>
    )
}

function getButtonLayout(isActive: boolean): Array<ButtonLayout> {
    const layout: Array<ButtonLayout> = ["iconRight"]

    switch (getStyleTheme().name) {
        case "lkq":
        case "stg":
        case "neimcke": {
            if (!isActive) {
                layout.push("holo")
            }
            break
        }
        default: {
            layout.push("holo")
            break
        }
    }

    return layout
}

function getButtonSkin(isActive: boolean): ButtonSkins | undefined {
    if (getStyleTheme().name === "neimcke" && isActive) {
        return "highlight"
    }
}

function getPanelStyles(): types.NestedCSSProperties {
    const theme = getStyleTheme()

    const styles: types.NestedCSSProperties = {
        margin: theme.margin.s,
        padding: theme.margin.l,
        width: `calc(100% - 2 * ${theme.margin.s})`,
        borderRadius: theme.box.radius,
        $nest: {
            "> .panel__content": {
                display: "flex",
                alignItems: "center",
            },
        },
    }

    switch (theme.name) {
        case "lkq":
        case "stg":
        case "pv":
        case "neimcke": {
            return {
                ...styles,
                padding: theme.margin.s,
                backgroundColor: theme.name == "stg" ? "#f6f6f6" : undefined,
                $nest: {
                    "> .panel__content": {
                        ...styles.$nest?.["> .panel__content"],
                        padding: theme.margin.m,
                        border: "3px solid rgba(194, 203, 210, .2)",
                        borderRadius: theme.box.radius,
                    },
                },
            }
        }
        case "default": {
            return {
                ...styles,
                backgroundColor: tinycolor(theme.colors.highlight).lighten(30).toHexString(),
            }
        }
        default:
    }

    return styles
}

type HintElements = "hint" | "hintIcon" | "hintTexts" | "hintText1" | "hintText2"

function getHintStyles(): Record<HintElements, types.NestedCSSProperties> {
    const theme = getStyleTheme()

    const styles: Record<HintElements, types.NestedCSSProperties> = {
        hint: {
            display: "flex",
            alignItems: "center",
            marginLeft: theme.margin.m,
        },
        hintIcon: {
            marginRight: theme.margin.l,
        },
        hintTexts: {},
        hintText1: {},
        hintText2: {},
    }

    switch (theme.name) {
        case "lkq":
        case "stg":
        case "neimcke": {
            return styles
        }
        case "pv": {
            return {
                ...styles,
                hintIcon: {
                    display: "none",
                },
                hintText1: {
                    fontWeight: "bold",
                },
            }
        }
        default: {
            return {
                ...styles,
                hint: {
                    display: "none",
                },
            }
        }
    }
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        panel: getPanelStyles(),
        label: {
            alignSelf: "flex-start",
            marginRight: `calc(${theme.margin.xl} * 2)`,
        },
        dropdown: {
            marginRight: theme.margin.l,
            $nest: {
                "> .btn": {
                    $nest: {
                        ".btn__content": {
                            minWidth: "7.5em",
                            textAlign: "left",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        },
                        ".btn__icon": {
                            height: "1.5em",
                            width: "1.5em",
                            margin: "-0.5em 0",
                        },
                    },
                },
            },
        },
        dropdownLabel: {
            textAlign: "center",
        },
        dropdownLabelTop: {
            marginBottom: theme.margin.m,
        },
        dropdownLabelBottom: {
            marginTop: theme.margin.m,
        },
        button: {
            marginLeft: "auto",
            padding: 0,
            $nest: {
                ".icon": {
                    height: "1.5em",
                    width: "1.5em",
                },
            },
        },
        item: {
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
        },
        itemIcon: {
            marginRight: theme.margin.m,
        },
        itemIconHidden: {
            visibility: "hidden",
        },
        ...getHintStyles(),
    })(OeReplacements)
}
