import { ShowBikeModelDetailsResponse, ShowCarModelDetailsResponse, ShowTruckModelDetailsResponse, Vehicle } from "@tm/models"

import { dateToString } from "."
import { VehicleRequestForDocuments } from "../model"
import { getFullVehicleName } from "./getFullVehicleName"

export function mapVehicleInfoForDocumentsRequest(
    carModel?: ShowCarModelDetailsResponse & ShowBikeModelDetailsResponse & ShowTruckModelDetailsResponse,
    vehicle?: Vehicle
): VehicleRequestForDocuments {
    const { modelDetails } = carModel ?? {}

    let request: VehicleRequestForDocuments = {
        ccm: "",
        deinstallationMileage: "",
        firstRegistrationYear: "",
        fuelType: "",
        hp: "",
        installationMileage: "",
        keyNumber: "",
        keyNumbers: [],
        kw: "",
        motorCode: "",
        motorCodes: [],
        partsDeinstallationDate: "",
        partsInstallationDate: "",
        transmissionType: "",
        vehicleType: "",
        vinNr: "",
    }

    if (vehicle) {
        request = {
            ...request,
            firstRegistrationYear: dateToString(vehicle.initialRegistration),
            keyNumber: vehicle.registrationNo,
            motorCode: vehicle.engineCode || "",
            vehicleType: getFullVehicleName(vehicle) || "",
            vinNr: vehicle.vin || "",
        }

        if (modelDetails) {
            request = {
                ...request,
                ccm: modelDetails.engineCapacityCcm?.toString() || "",
                fuelType: modelDetails.fuelType || "",
                fuelTypeId: modelDetails.fuelTypeId || 0,
                hp: modelDetails.enginePowerHp?.toString() || "",
                keyNumber: vehicle.registrationNo || modelDetails.registrationNos?.[0] || "",
                keyNumbers: !vehicle.registrationNo ? modelDetails.registrationNos ?? [] : [],
                kw: modelDetails.enginePowerKw?.toString() || "",
                motorCodes: modelDetails.engineCodes || [],
                transmissionType: modelDetails.transmissionType || "",
            }
        }
    }

    return request
}
