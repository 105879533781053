import { EFilterNames, ETyresSeason, FilterNames, TyreFilter } from "@tm/models"

const seasons: TyreFilter[] = Object.values(ETyresSeason)
    .filter(x => x != ETyresSeason.default)
    .map((season) => ({ query: season, value: season, group: EFilterNames.season }))

const multiSelectionFilters: Array<FilterNames> = [
    EFilterNames.manufacturer,
    EFilterNames.loadIndex,
    EFilterNames.speedIndex,
    EFilterNames.oeIdentifier,
    EFilterNames.extras
]

export const Statics = {
    seasons,
    multiSelectionFilters,
}