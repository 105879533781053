import { useMemo } from "react"
import { useCondensedChatInfos } from "."
import { AttachmentTypeAppointment } from ".."
import { getBundleParams } from "../../utils"
import { openWorkTask } from "../../bundle-channel"

export function useOpenFastCalc(chatId: string, initialMetadata: AttachmentTypeAppointment) {
    const infos = useCondensedChatInfos(chatId)

    const vehicle = useMemo(
        () => infos?.vehicles.find((v) => v.vehicleInfo.id === initialMetadata.vehicleInfo?.id),
        [infos, initialMetadata.vehicleInfo?.id]
    )

    const handleFastCalculatorClick = useMemo(() => {
        if (vehicle) {
            const route = getBundleParams().fastCalculatorRouteWithoutWorkTaskId
            const initialCalcId = initialMetadata.serviceInfo?.fastCalculatorSessionId
            if (route && initialCalcId) {
                return () => {
                    const params = new URLSearchParams({ initialCalcId, isWorkshop: "true" })
                    openWorkTask({ selectedChatVehicle: vehicle, subRoute: `${route}?${params.toString()}`, skipRenderRoute: true })
                }
            }

            const modalRoute = getBundleParams().fastCalculatorModalRoute
            if (modalRoute && (initialMetadata.serviceInfo?.genArts?.length || initialMetadata.serviceInfo?.consumables?.length)) {
                return () => {
                    const productGroupIds: Array<number> = []
                    initialMetadata.serviceInfo?.genArts?.forEach((x) => x.genArtNr && productGroupIds.push(x.genArtNr))
                    initialMetadata.serviceInfo?.consumables?.forEach((x) => x.genArtNr && productGroupIds.push(x.genArtNr))

                    openWorkTask({
                        selectedChatVehicle: vehicle,
                        modal: { route: modalRoute, params: { productGroupIds: productGroupIds.join(",") } },
                    })
                }
            }
        }
    }, [
        vehicle,
        initialMetadata.serviceInfo?.fastCalculatorSessionId,
        initialMetadata.serviceInfo?.genArts,
        initialMetadata.serviceInfo?.consumables,
    ])

    return { handleFastCalculatorClick }
}
