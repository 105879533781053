import { useMemo } from "react"
import { Button } from "@tm/controls"
import { encodeUniqueId, uniqueId } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { getCollectiveCustomerParam } from "../../helpers"

function CreateButtonComponent() {
    const { userContext } = useUser()

    const hasCollectiveCustomer = useMemo(() => {
        return !!getCollectiveCustomerParam(userContext)
    }, [userContext])

    if (!hasCollectiveCustomer) {
        return null
    }

    return <Button icon="plus" skin="highlight" linkTo={`/${encodeUniqueId(uniqueId())}`} className="worktask-create-button" />
}

export default CreateButtonComponent
