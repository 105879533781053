import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Box, CellContentPosition, Table, TableProps, TableRowData, Icon, Skeleton } from "@tm/components"
import { WorkTaskInfo } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useVatRateText } from "@tm/utils"
import { AddCustomPartListRequest, CostEstimationOptions, CustomArticleItem, EditCustomArticleItemRequest } from "@tm/models"
import { useArticleNotes } from "../../../../../hooks/useArticleNotes"
import { getBundleParams } from "../../../../../utils"
import { useEditCustomArticleItem } from "../../../../../data/hooks/custom-basket-items/useCustomArticles"
import { CustomArticlesActions } from "./CustomArticlesActions"
import RowToEdit from "./RowToEdit"
import { VatRateSelectionItem } from "../../../../../data/model"

type Props = {
    articles: CustomArticleItem[]
    costEstimationOptions?: CostEstimationOptions
    currencySymbol?: string
    customPartVatRates?: VatRateSelectionItem[]
    loading: boolean
    quantities: Map<string, number>
    selectedArticleIds: string[]
    workTask: WorkTaskInfo
    onAddCustomParts(request: AddCustomPartListRequest): Promise<unknown>
    onLoadMoreArticles(): void
    onQuantityChange(article: CustomArticleItem, quantity: number): void
    onToggleArticle(article: CustomArticleItem): void
}

const CATEGORY_OTHER = 3060

export const CustomArticlesList = memo(
    ({
        workTask,
        articles,
        currencySymbol,
        quantities,
        loading,
        customPartVatRates,
        selectedArticleIds,
        costEstimationOptions,
        onAddCustomParts,
        onLoadMoreArticles,
        onQuantityChange,
        onToggleArticle,
    }: Props) => {
        const { enableArticleNotes } = getBundleParams()

        const { translateText, currency } = useLocalization()
        const { vatRateTypeDisplayShortText } = useVatRateText(translateText)
        const getArticleIds = useCallback(() => articles.map((x) => x.id).filter((x) => x), [articles])
        const { articleNotes, saveNote } = useArticleNotes(enableArticleNotes ? getArticleIds() : [])

        const [editedArticle, setEditedArticle] = useState<CustomArticleItem & { articleNote?: string }>()
        const { mutateAsync: saveCustomArticle } = useEditCustomArticleItem()

        useEffect(() => {
            if (editedArticle && customPartVatRates) {
                const defaultVatRate = customPartVatRates[0]

                if (!editedArticle.vatRate) {
                    setEditedArticle((prev) =>
                        prev
                            ? {
                                  ...prev,
                                  vatRate: {
                                      vatRate: defaultVatRate.vatValue,
                                      vatType: defaultVatRate.vatType,
                                  },
                              }
                            : prev
                    )
                }
            }
        }, [editedArticle, customPartVatRates])

        const findNoteByArticleId = useCallback((articleId?: string) => articleNotes.find((note) => note.noteId === articleId), [articleNotes])

        const handleEditClick = useCallback(
            (article: CustomArticleItem | undefined) => {
                setEditedArticle(article)

                if (article && selectedArticleIds.includes(article.id)) {
                    onToggleArticle(article)
                }
            },
            [selectedArticleIds, onToggleArticle]
        )

        const handleSaveArticleInformation = useCallback(async (): Promise<void> => {
            if (editedArticle) {
                await saveNote(editedArticle.id, editedArticle.articleNote)
                const request: EditCustomArticleItemRequest = {
                    customArticleItemToEdit: {
                        ...editedArticle,
                        purchasePrice: editedArticle.purchasePrice,
                        retailPrice: editedArticle.retailPrice,
                    },
                    priceVatMode: costEstimationOptions?.priceVatMode,
                }
                await saveCustomArticle(request)
            }
        }, [editedArticle, saveNote, costEstimationOptions?.priceVatMode, saveCustomArticle])

        const renderDefaultForCategory = useCallback(
            (article: CustomArticleItem) => {
                return article.isDefaultForCategory && article.category && article.category !== CATEGORY_OTHER ? (
                    <div title={translateText(12702)}>
                        <Icon name="filled-star" />
                    </div>
                ) : (
                    <Box width="50" />
                )
            },
            [translateText]
        )

        const tableData: TableProps = useMemo(
            () => ({
                columns: [
                    { header: translateText(87) }, // Article Number
                    { header: translateText(25) }, //  Description
                    { alignContent: CellContentPosition.right }, // Defaut category
                    { header: translateText(158) }, // Category
                    { header: translateText(12807), alignContent: CellContentPosition.right }, // Vat
                    { header: translateText(1132), alignContent: CellContentPosition.right }, // Purchase price
                    { header: translateText(1257), alignContent: CellContentPosition.right }, // Retail price
                    { alignContent: CellContentPosition.right }, // actions
                ],
                rows: articles.map(
                    (article): TableRowData => ({
                        id: article.id,
                        cells: [
                            { displayValue: article.articleNumber, id: "1" },
                            { displayValue: article.description, id: "2" },
                            { displayValue: renderDefaultForCategory(article), id: "3" },
                            { displayValue: translateText(article?.category || CATEGORY_OTHER), id: "4" },
                            { displayValue: !!article.vatRate && vatRateTypeDisplayShortText(article.vatRate), id: "5" },
                            {
                                displayValue: article.purchasePriceRounded ? currency(article.purchasePriceRounded, currencySymbol || "") : "-",
                                id: "7",
                            },
                            { displayValue: article.retailPriceRounded ? currency(article.retailPriceRounded, currencySymbol || "") : "-", id: "6" },
                            {
                                displayValue: (
                                    <CustomArticlesActions
                                        article={article}
                                        articleNote={findNoteByArticleId(article.id)?.text}
                                        costEstimationOptions={costEstimationOptions}
                                        isSelected={selectedArticleIds.includes(article.id)}
                                        quantity={quantities.get(article.id) || 1}
                                        workTask={workTask}
                                        onAddCustomParts={onAddCustomParts}
                                        onEditClick={handleEditClick}
                                        onQuantityChange={onQuantityChange}
                                        onToggleSelection={onToggleArticle}
                                    />
                                ),
                                id: "8",
                            },
                        ],
                        active: article.id === editedArticle?.id,
                        customRow: (
                            <RowToEdit
                                activeArticle={editedArticle}
                                articleNote={findNoteByArticleId(editedArticle?.id)}
                                saveArticleInformation={handleSaveArticleInformation}
                                onArticleChange={setEditedArticle}
                                customPartVatRates={customPartVatRates}
                                closeEditor={() => setEditedArticle(undefined)}
                            />
                        ),
                        replaceCustomRow: article.id === editedArticle?.id,
                    })
                ),
            }),
            [
                translateText,
                articles,
                renderDefaultForCategory,
                vatRateTypeDisplayShortText,
                currency,
                currencySymbol,
                findNoteByArticleId,
                costEstimationOptions,
                selectedArticleIds,
                quantities,
                workTask,
                onAddCustomParts,
                handleEditClick,
                onQuantityChange,
                onToggleArticle,
                editedArticle,
                handleSaveArticleInformation,
                customPartVatRates,
            ]
        )

        return (
            <Box maxHeight={500}>
                <Table overflowY="auto" {...tableData} onScrollBottom={onLoadMoreArticles} />
                {loading && <Skeleton height={54} width="100%" />}
            </Box>
        )
    }
)
