import { useHidePriceTypes, useTelesalesCustomerNumber } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { useExternalCatalogUrl } from "@tm/utils"
import { useMemo } from "react"
import { ListOptions } from "@tm/models"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { getBundleParams } from "../../../utils"
import { usePartsViewOptions } from "./usePartsViewOptions"
import { useBasketModuleParameter } from "../../../../../basket/src/hooks/useBasketModuleParameter"

/** @todo Merge somehow with `ArticleListConfiguration` */
export function useListOptions(): ListOptions {
    const { addToBasketExternalSystemId } = Morpheus.getParams("basket")
    const { isPriceTypeHidden } = useHidePriceTypes()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId: addToBasketExternalSystemId,
        telesalesCustomerNo,
    })
    const { hideCostEstimationButton, hideAddToBasketButton, highlightWatchlistButton, hasNarrowRightActionsContainer } = getBundleParams()
    const { showBasketButton, showCostEstimationButton } = useBasketModuleParameter()

    const { showDataSupplierLogos, highlightCostEstimationButton, hasWatchList } = useArticleListConfiguration()
    const {
        showPurchasePrice,
        partsViewSettings: { showArticleImages },
    } = usePartsViewOptions()

    return useMemo(
        () => ({
            showDocumentsInline: false,
            showDocumentsInCompact: false,
            openDocumentsAsModal: true,
            showReferenceLinksInCompact: false,
            showDataSupplierLogos,
            highlightCostEstimationButton,
            externalCatalogUrl,
            hasWatchList,
            hideCostEstimationButton: !showCostEstimationButton || hideCostEstimationButton,
            hideAddToBasketButton: !showBasketButton || hideAddToBasketButton,
            highlightWatchlistButton,
            showPurchasePrice,
            showArticleImages,
            isPriceTypeHidden,
            hasNarrowRightActionsContainer,
        }),
        [
            externalCatalogUrl,
            highlightCostEstimationButton,
            showDataSupplierLogos,
            hasWatchList,
            hideAddToBasketButton,
            showBasketButton,
            hideCostEstimationButton,
            showCostEstimationButton,
            highlightWatchlistButton,
            showPurchasePrice,
            showArticleImages,
            isPriceTypeHidden,
            hasNarrowRightActionsContainer,
        ]
    )
}
