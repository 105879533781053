import { FormEvent, useEffect, useMemo, useRef, useState } from "react"
import { style } from "typestyle"
import { Button, Icon, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useCondensedChatInfos, useSendChatMessage } from "../../../../data/hooks"
import Attachment, { AttachmentType, FileAttachment } from "./attachment"
import { getComponentStyles } from "./style"

type Props = {
    chatId: string
}

export default function MessageInput({ chatId }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const send = useSendChatMessage()
    const [message, setMessage] = useState("")
    const [sending, setSending] = useState(false)
    const [attachment, setAttachment] = useState<AttachmentType>()
    const infos = useCondensedChatInfos(chatId)
    const { translateText } = useLocalization()
    const textFieldRef = useRef<TextField>(null)

    useEffect(() => {
        textFieldRef.current?.focus()
    }, [textFieldRef, sending])

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        setSending(true)

        send({ chatId, message, attachment }).then(
            () => {
                setMessage("")
                if (attachment) {
                    setAttachment(undefined)
                }
                setSending(false)
            },
            () => {
                setSending(false)
            }
        )
    }

    const handleSetAttachment = (attachment?: AttachmentType, defaultMessage?: string) => {
        setAttachment(attachment)
        if (!message && defaultMessage) {
            setMessage(defaultMessage)
        }
    }

    const getAttachmentIconName = () => {
        if (attachment?.metaData.type === "attachment") {
            if ((attachment as FileAttachment).metaData.type === "attachment") {
                switch ((attachment as FileAttachment).mimeType) {
                    case "image/jpeg":
                    case "image/png":
                    case "image/gif":
                        return "document"
                    case "audio/mpeg":
                    case "audio/mp4":
                    case "audio/ogg":
                        return "sound"
                    case "video/mpeg":
                    case "video/mp4":
                    case "video/ogg":
                        return "document"
                    case "application/pdf":
                        return "file-pdf"
                    default:
                        break
                }
            }
        }
        return "document"
    }

    const getAttachmentName = () => {
        if (attachment?.metaData.type === "attachment") {
            return (attachment as FileAttachment).metaData.name
        }
        return attachment?.metaData.type
    }

    return (
        <form onSubmit={handleSubmit} className={classNames.inputForm}>
            <TextField
                value={message}
                onChange={setMessage}
                disabled={sending}
                ref={textFieldRef}
                autoComplete="no"
                autoFocus
                placeholder={`${translateText(3084)}...`}
                className={style({ flex: 1, $nest: { ".input__inner": { marginRight: attachment ? "16em" : "5em" } } })}
            />

            <div className={classNames.inputFormActionWrapper}>
                {!!attachment && (
                    <div className={`btn has-icon ${style({ maxWidth: "11em" })}`}>
                        <Icon className={style({ minWidth: "1.2em" })} name={getAttachmentIconName()} />
                        <div className={`btn__content ${style({ height: "20px", textOverflow: "ellipsis", overflow: "hidden" })}`}>
                            {getAttachmentName()}
                        </div>
                        <Button
                            icon="close"
                            layout={["ghost"]}
                            fakeButton
                            onClick={() => setAttachment(undefined)}
                            className={style({ padding: "0" })}
                        />
                    </div>
                )}
                {!!infos?.vehicles?.length && <Attachment attachment={attachment} setAttachment={handleSetAttachment} vehicles={infos.vehicles} />}
                <Button
                    disabled={!message && !attachment}
                    icon="paperplane"
                    layout={["ghost"]}
                    skin="primary"
                    className={classNames.inputFormSendButton}
                />
            </div>
        </form>
    )
}
