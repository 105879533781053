import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { ImagesSlice, ImagesState } from "./model"
import { PrintImage } from "../../models"

function getInitialState(): ImagesState {
    return {
        imagesState: {
            images: {},
        },
    }
}

export const createImagesSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], ImagesSlice> = (set, get) => ({
    ...getInitialState(),
    imagesActions: {
        addImage: (step: string, work: string, imageData: PrintImage) => set((state) => addImage(state, step, work, imageData), false, "Add image"),
        replaceImage: (step: string, work: string, imageData: PrintImage) =>
            set((state) => replaceImage(state, step, work, imageData), false, "Replace image"),
        deleteImage: (step: string, work: string, id: string) => set((state) => deleteImage(state, step, work, id), false, "Delete image"),
        getImage: (step: string, work: string) => {
            return getImage(get(), step, work)
        },
        reset: () => set(getInitialState(), false, "Reset images slice"),
    },
})

function addImage(state: ImagesSlice, step: string, work: string, imageData: PrintImage): ImagesState {
    const availableImages = { ...state.imagesState.images }

    if (!availableImages[step]) {
        availableImages[step] = {}
    }

    if (!availableImages[step][work]) {
        availableImages[step][work] = []
    }

    availableImages[step][work].push(imageData)

    return {
        imagesState: {
            images: availableImages,
        },
    }
}

function replaceImage(state: ImagesSlice, step: string, work: string, imageData: PrintImage): ImagesState {
    const availableImages = { ...state.imagesState.images }

    if (availableImages[step]?.[work]) {
        const newImages = availableImages[step][work].map((image) => {
            if (image.id === imageData.id) {
                return imageData
            }

            return image
        })

        availableImages[step][work] = newImages
    }

    return {
        imagesState: {
            images: availableImages,
        },
    }
}

function deleteImage(state: ImagesSlice, step: string, work: string, id: string): ImagesState {
    const availableImages = { ...state.imagesState.images }

    if (availableImages[step]?.[work]) {
        const newImages = availableImages[step][work].filter((image) => image.id !== id)
        availableImages[step][work] = newImages
    }

    return {
        imagesState: {
            images: availableImages,
        },
    }
}

function getImage(state: ImagesSlice, step: string, work: string) {
    return state.imagesState.images[step]?.[work]
}
