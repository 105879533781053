import { UserContext } from "@tm/models"
import { merge, clone } from "lodash"
import { autodataFactory } from "./navigationItems/autodataFactory"
import { awDocFactory } from "./navigationItems/awDocFactory"
import { ItemPosition, ModuleItemFactory, WidgetModuleGroups } from "./models"
import { defaultWidgetModuleGroups } from "./defaultWidgetModuleGroups"
import {
    alldataFactory,
    carmunicationFactory,
    damageCalculationAsWegasFactory,
    damageCalculationDatFactory,
    damageCalculationEurotaxFactory,
    damageCalculationGlassFactory,
    datFactory,
    demoActivationFactory,
    drivemotiveFactory,
    dutchOrderPlatesFactory,
    edsFactory,
    eurotaxFactory,
    fastCalculatorNextFactory,
    fastServiceFactory,
    haynesProFactory,
    sindriFactory,
    tecrmiFactory,
    temotFactory,
    tmTyresFactory,
    wheelsFactory,
} from "./navigationItems"

export function createWidgetModuleGroups(userContext: UserContext, position: ItemPosition) {
    let widgetModuleGroups: WidgetModuleGroups = clone(defaultWidgetModuleGroups)

    const loadModule = (payload: ModuleItemFactory | undefined) => {
        if (!payload) {
            return
        }
        widgetModuleGroups = merge(widgetModuleGroups, payload)
    }

    // this should come first than active modules
    loadModule(demoActivationFactory(userContext))

    // load items into groups
    loadModule(alldataFactory(userContext))
    loadModule(autodataFactory(userContext))
    loadModule(awDocFactory(userContext))
    loadModule(carmunicationFactory(userContext))
    loadModule(damageCalculationAsWegasFactory(userContext))
    loadModule(damageCalculationDatFactory(userContext))
    loadModule(damageCalculationEurotaxFactory(userContext))
    loadModule(damageCalculationGlassFactory(userContext))
    loadModule(datFactory(userContext))
    loadModule(drivemotiveFactory(userContext))
    loadModule(dutchOrderPlatesFactory(userContext))
    loadModule(edsFactory(userContext))
    loadModule(eurotaxFactory(userContext))
    loadModule(fastCalculatorNextFactory(userContext))
    loadModule(fastServiceFactory(userContext))
    loadModule(haynesProFactory(userContext))
    loadModule(sindriFactory(userContext))
    loadModule(tecrmiFactory(userContext))
    loadModule(temotFactory(userContext))
    loadModule(tmTyresFactory(userContext))
    loadModule(wheelsFactory(userContext))

    return widgetModuleGroups
}
