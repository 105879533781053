import { Box, LinkButton } from "@tm/components"
import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { useParams } from "react-router"
import { useCisShowTours } from "../../data/hooks/useCisShowTours"
import { getBundleParams } from "../../utils"
import NextTour from "../_shared/NextTour"
import { RowStack, VerticalDivider } from "../_shared/StyledComponents"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"
import ToursTableComponent from "../_shared/tables/ToursTable"

type Props = {
    titleTextId?: string
}

export default function WidgetToursComponent({ titleTextId }: Props) {
    const { translate } = useLocalization()
    const matchParams = useParams()

    const { tours, toursLoading } = useCisShowTours()

    return (
        <Widget
            size="4x2"
            iconName="voucher"
            title={translate(1121)}
            header={<WidgetHeaderTitleComponent iconName="voucher" titleTextId={titleTextId || 1121} />}
            active
        >
            <RowStack divider={<VerticalDivider />} height="100%">
                <Box width="380px">
                    <ToursTableComponent variant="small" loading={toursLoading} tours={tours} />
                </Box>
                <Box display="flex" flexDirection="column" justifyContent="space-between" flex={1}>
                    <NextTour tours={tours} toursLoading={toursLoading} />
                    <Box bottom={0} right={0} mr={2} mb={0.5} position="absolute">
                        <LinkButton to={renderRoute(getBundleParams().toursRoute, { ...matchParams })} disabled={toursLoading}>
                            {translate(43)}
                        </LinkButton>
                    </Box>
                </Box>
            </RowStack>
        </Widget>
    )
}
