// tm-telematics-interfaces

import { TelematicsProvider } from "@tm/models"

// enums
export enum ClearanceState {
    NotRequested = 0, // Noch nicht angefragt
    NotSupported = 1, // Freigabe nicht möglich da Auto nicht Telematik-fähig oder kein bestehender Vertrag mit Kunde vorhanden
    Requested = 2, // Freigabe angefragt
    Granted = 3, // Freigabe gewährt
    Rejected = 4, // Freigabe abgelehnt
    OutDated = 5, // Timeout nach 14 Tagen, Kunde hat nicht reagiert
}

export enum SetType {
    String = 1,
    Number = 2,
    DateTime = 3,
}

// interfaces
export type ClearanceStatusResponse = {
    clearanceState: ClearanceState
    lastStateChanged?: Date
}

export interface TelematicsDataResponse {
    dataProvider: TelematicsProvider
    dateOfLastUpdate?: Date
    gpsPosition?: GpsPosition
    conditionBasedServiceInfoCount: TelematicNumberSet
    conditionBasedServiceInfos: Array<ConditionBasedServiceInfo>
    mileage: TelematicNumberSet
    nextServiceDate: TelematicDateTimeSet
    nextServiceDistance: TelematicNumberSet
    legalInspectionDate: TelematicDateTimeSet
    nextBrakeFluidChangeDate: TelematicDateTimeSet
    batteryVoltage: TelematicNumberSet
    checkControlMessage: TelematicStringSet

    chassisStatus?: Array<TelematicSet>
    chassisStatusLabelMap?: LabelMap
    navigationData?: Array<TelematicSet>
    navigationDataLabelMap?: LabelMap
    vehicleStatus?: Array<TelematicSet>
    vehicleStatusLabelMap?: LabelMap
    electricCarSpecificStatus?: Array<TelematicSet>
    electricCarSpecificStatusLabelMap?: LabelMap
    additionalServiceData?: Array<TelematicSet>
    additionalServiceDataLabelMap?: LabelMap
    environmentData?: Array<TelematicSet>
    environmentDataLabelMap?: LabelMap
}

export interface TelematicsDataSummaryResponse {
    dataProvider: TelematicsProvider
    dateOfLastUpdate?: Date
    conditionBasedServiceInfoCount?: TelematicNumberSet
    mileage?: TelematicNumberSet
    nextServiceDate?: TelematicDateTimeSet
    nextServiceDistance?: TelematicNumberSet
    legalInspectionDate?: TelematicDateTimeSet
    nextBrakeFluidChangeDate?: TelematicDateTimeSet
    batteryVoltage?: TelematicNumberSet
}

export interface TelematicsDataSet {
    key: string
    value: string
    unit: string
    dateOfCreation?: Date
}

export interface GpsPosition {
    longitude?: TelematicNumberSet
    latitude?: TelematicNumberSet
    altitude?: TelematicNumberSet
    headingOrientation?: TelematicNumberSet
}

export interface ConditionBasedServiceInfo {
    date?: Date
    title: string
    description: string
    ok: boolean
}

export interface TelematicDateTimeSet extends TelematicSet {
    value: Date
}

export interface TelematicNumberSet extends TelematicSet {
    value: number
    decimalPlaces: number
}

export interface TelematicStringSet extends TelematicSet {
    value: string
}

export interface TelematicSet {
    labelTextMap: LabelMap
    unit: string
    timestamp?: Date
    type: SetType
}

export interface LabelMap {
    [key: string]: string
}
