import { Worktask, WorktaskInfo } from "./repositories"

export const mapWorktaskInfo = (data: any): WorktaskInfo => {
    return {
        ...data,
        createdDate: new Date(data.createdDate),
    }
}

export const mapWorktask = (data: any): Worktask => {
    return {
        ...data,
        createdDate: new Date(data.createdDate),
        modifiedDate: new Date(data.modifiedDate),
        statusModifiedDate: data.statusModifiedDate ? new Date(data.statusModifiedDate) : undefined,
        vehicleHistory: (data.vehicleHistory || []).map((historyVehicle: any) => ({
            ...historyVehicle,
            modifiedDate: data.modifiedDate ? new Date(data.modifiedDate) : undefined,
        })),
    }
}
