import { BundleComponent } from "@tm/morpheus"
import { SimpleSearchWidget } from "./component"
import { ComponentState, reduce } from "./business"

const SimpleSearchWidgetBundle: BundleComponent<ComponentState, typeof SimpleSearchWidget> = {
    name: "simple-search-widget",
    reduce,
    component: SimpleSearchWidget,
}

export { SimpleSearchWidgetBundle, SimpleSearchWidget }
