import { Box, Button, CellContentPosition, Icon, Separator, Table, TableCellData, TableColumnData, TableRowData, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { formatDate } from "@tm/utils"
import { summaryActions, useDrivemotiveStore } from "../../../data/state"
import { Booking } from "../../../data/models"
import { mapBookingStatus } from "../../../utils"

export default function CompactBookingsList() {
    const { translateText } = useLocalization()
    const bookings = useDrivemotiveStore((state) => state.summary.bookings)
    const selectedBooking = useDrivemotiveStore((state) => state.summary.selectedBooking)

    const tableColumns: TableColumnData[] = [{ alignContent: CellContentPosition.left }]

    const renderBookingDetails = (booking: Booking) => {
        return (
            <>
                {booking.first_name} {booking.last_name}
                <br />
                {mapBookingStatus(booking.status, translateText)?.label?.toUpperCase()} <Separator />
                {formatDate(new Date(booking.date_created), "dd.MM.yyyy")}
            </>
        )
    }

    const getCells = (booking: Booking) => {
        const cellData: TableCellData[] = [{ displayValue: renderBookingDetails(booking), id: "booking_details" }]

        return cellData
    }

    const displayData: TableRowData[] = bookings?.map((booking: Booking) => ({
        cells: getCells(booking),
        id: `${booking.id}`,
        active: booking.id === selectedBooking?.id,
        leftRowIndicatorColor: mapBookingStatus(booking.status, translateText)?.color,
        rowIndicatorWidth: 7,
        borderOnlyOnTheLeft: true,
    }))

    const onRowClick = (row: TableRowData) => {
        const selected = bookings.find((booking) => booking.id.toString() === row.id)
        if (selected) {
            summaryActions.setSelectedBooking(selected)
        }
    }

    return (
        <Box sx={{ width: "20em" }}>
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Typography variant="h2">{translateText(409)}</Typography>
                <Button onClick={() => summaryActions.setShowBookingDetails(false)} style={{ width: "2.5em", height: "2.5em" }} color="highlight">
                    <Icon name="arrow-right" />
                </Button>
            </Box>
            <Table
                columns={tableColumns}
                rows={displayData}
                headerBackground="transparent"
                fontSize={14}
                headerStyle="default"
                rowCap={1}
                overflowY="auto"
                rowStyle="uniform"
                onRowClick={onRowClick}
            />
        </Box>
    )
}
