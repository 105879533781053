import { AddRepairTimeListRequest, IncludedWorkRequest, VehicleType, AddCustomWorkListRequest, RepairTimeProvider } from "@tm/models"
import { mapCategoryOfWork } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest, RepairTimesNode } from "../../../data"

export default function handleAddRepairTimesToBasket(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const {
        workTask,
        localization: { translateText },
        addRepairTimeList,
        addCustomWorkList,
    } = this.props
    const { addRepairTimesToBasket } = data

    if (addRepairTimesToBasket && workTask) {
        const provider = addRepairTimesToBasket.repairTimeProvider
        const repairTimeDivision = addRepairTimesToBasket.repairTimeDivision ? addRepairTimesToBasket.repairTimeDivision : 100

        if (workTask.vehicle) {
            const request: AddRepairTimeListRequest = {
                workTaskId: workTask.id,
                vehicleId: workTask.vehicle && workTask.vehicle.id,
                provider,
                repairTimes: [],
                repairTimeDivision,
            }

            addRepairTimesToBasket.repairTimesItems.forEach((item) => {
                const { Includes, FollowUps } = item
                const included: Array<IncludedWorkRequest> = Includes?.map((inc) => ({
                    categoryOfWork: mapCategoryOfWork(inc.CategoryOfWork && inc.CategoryOfWork.Code),
                    description: inc.Description,
                    providerWorkId: inc.Id,
                    displayNumber: inc.WorkPositionNo, // TODO: The WS cannot currently save the display number for included works.
                    // TODO: The WS cannot currently calculate Maintenance times.
                    time: inc.IsMaintenanceWork ? inc.CalculatedTime : inc.Time,
                    isVisible: true,
                    isCalculable: true,
                    sortNo: 0,
                }))

                request.repairTimes.push({
                    providerWorkId: item.RepairTimesNode.Id,
                    displayNumber: item.RepairTimesNode.WorkPositionNo,
                    categoryOfWork: mapCategoryOfWork(item.RepairTimesNode.CategoryOfWork && item.RepairTimesNode.CategoryOfWork.Code),
                    description: addRepairTimesTypesText(translateText, item.RepairTimesNode) + item.RepairTimesNode.Description,
                    time:
                        provider !== RepairTimeProvider.TecRmiCar
                            ? item.RepairTimesNode.IsMaintenanceWork
                                ? item.RepairTimesNode.CalculatedTime
                                : item.RepairTimesNode.Time
                            : item.RepairTimesNode.Time,
                    linkageId: undefined,
                    note: undefined,
                    // TODO: The WS cannot currently calculate Maintenance times, except for TecRMI.
                    isMaintenanceWork: provider !== RepairTimeProvider.TecRmiCar ? item.RepairTimesNode.IsMaintenanceWork : false,
                    sortNo: 0,
                    oeReferenceNumber: item.RepairTimesNode.OeCode,
                    includedWorks: included || [],
                })

                FollowUps?.forEach((followUp) => {
                    request.repairTimes.push({
                        providerWorkId: followUp.Id,
                        displayNumber: followUp.WorkPositionNo,
                        categoryOfWork: mapCategoryOfWork(item.RepairTimesNode.CategoryOfWork && item.RepairTimesNode.CategoryOfWork.Code),
                        description: followUp.Description,
                        time:
                            provider !== RepairTimeProvider.TecRmiCar
                                ? followUp.IsMaintenanceWork
                                    ? followUp.CalculatedTime
                                    : followUp.Time
                                : followUp.Time,
                        linkageId: undefined,
                        note: undefined,
                        // TODO: The WS cannot currently calculate Maintenance times, except for TecRMI.
                        isMaintenanceWork: provider !== RepairTimeProvider.TecRmiCar ? followUp.IsMaintenanceWork : false,
                        includedWorks: [],
                        sortNo: 0,
                    })
                })
            })

            addRepairTimeList(request)
        }
        // TODO: NEXT doesn't fully support Trucks yet. The RT from TM Truck must be added as Custom works until Trucks are fully supported
        else if (addRepairTimesToBasket.vehicleType === VehicleType.CommercialVehicle && addRepairTimesToBasket.carType) {
            const request: AddCustomWorkListRequest = {
                workTaskId: workTask.id,

                // TODO: Vehicle Id should be given when NEXT's work task supports Trucks as current vehicle
                // vehicleId: workTask.vehicle && workTask.vehicle.id,
                repairTimeProvider: provider,
                customWorks: [],
                repairTimeDivision,
            }

            addRepairTimesToBasket.repairTimesItems.forEach((item) => {
                request.customWorks.push({
                    customWorkNumber: item.RepairTimesNode.WorkPositionNo ? item.RepairTimesNode.WorkPositionNo : item.RepairTimesNode.Id,
                    categoryOfWork: mapCategoryOfWork(item.RepairTimesNode.CategoryOfWork && item.RepairTimesNode.CategoryOfWork.Code),
                    description: addRepairTimesTypesText(translateText, item.RepairTimesNode) + item.RepairTimesNode.Description,

                    // TODO: The WS cannot currently calculate Maintenance times.
                    time: item.RepairTimesNode.IsMaintenanceWork ? item.RepairTimesNode.CalculatedTime : item.RepairTimesNode.Time,
                })

                // TODO: to avoid problems includes nor followups should be included for customWorks
                // if (Includes) {
                //     Includes.map(include => {
                //         request.customWorks.push({
                //             customWorkNumber: include.WorkPositionNo ? include.WorkPositionNo : include.Id,
                //             categoryOfWork: mapCategoryOfWork(item.RepairTimesNode.CategoryOfWork && item.RepairTimesNode.CategoryOfWork.Code),
                //             description: include.Description,
                //             //TODO: The WS cannot currently calculate Maintenance times.
                //             time: item.RepairTimesNode.IsMaintenanceWork ? item.RepairTimesNode.CalculatedTime : item.RepairTimesNode.Time,
                //         })
                //     })
                // }

                // if (FollowUps) {
                //     FollowUps.forEach(followUp => {
                //         request.customWorks.push({
                //             customWorkNumber: followUp.WorkPositionNo ? followUp.WorkPositionNo : followUp.Id,
                //             categoryOfWork: mapCategoryOfWork(item.RepairTimesNode.CategoryOfWork && item.RepairTimesNode.CategoryOfWork.Code),
                //             description: followUp.Description,
                //             //TODO: The WS cannot currently calculate Maintenance times.
                //             time: followUp.IsMaintenanceWork ? followUp.CalculatedTime : followUp.Time
                //         })
                //     })
                // }
            })

            addCustomWorkList(request, true)
        }
    }
}

function addRepairTimesTypesText(translateText: (key: string | number) => string, repairTimesNode: RepairTimesNode): string {
    if (!repairTimesNode || !repairTimesNode?.IsMaintenanceWork) {
        return ""
    }

    switch (repairTimesNode.RepairTimeType) {
        case 0: // Hauptinspektion
            return `${translateText(12748)} - `
        case 1: // Zusätzliche Wartungspositionen
            return `${translateText(12749)} - `
        default:
            return ""
    }
}
