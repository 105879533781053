import { memo, Suspense } from "react"
import { Box } from "@tm/components"
import NextToursComponent from "./components/NextTours"
import { useNextTours } from "../../data/hooks/useNextTours"

type Props = {
    detailsCisRoute?: string
    lightMode: boolean
}

function ToursComponent(props: Props) {
    const { tours, isLoading } = useNextTours()

    return (
        <Box display="flex" height="100%" alignSelf="center" margin="0 6px">
            <NextToursComponent loading={isLoading} tours={tours} detailsCisRoute={props.detailsCisRoute} lightMode={props.lightMode} />
        </Box>
    )
}

const Wrapper = memo((props: Props) => {
    return (
        <Suspense fallback={null}>
            <ToursComponent {...props} lightMode={props.lightMode ?? false} />
        </Suspense>
    )
})

export default Wrapper
