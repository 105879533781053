import * as React from 'react'
import { useStyle } from '@tm/context-distribution'
import { Button, Text, Tooltip } from '@tm/controls'

type Props = {
    onReset: () => void
}

const ResetButtonFromState: React.FC<Props> = ({ onReset }) => {

    return (
        <div className={style.stateResetWrapper} >
            <div className="selection-list__item">
                <Text onClick={onReset} size="m" modifiers="strong" className="selection-list__item__value is-clickable">Reset all selections</Text>
            </div>
            <div className="wrapper">
                <Tooltip key="tooltip_state" content="Data has been loaded based on the previous selections. Please reset if you want to start over again.">
                    <Button layout={["ghost"]} icon="info" />
                </Tooltip>
                <Button onClick={onReset} layout={['ghost']} size="s" icon="synchronize" />
            </div>
        </div>
    )
}

export default ResetButtonFromState

const style = useStyle({
    stateResetWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 1em 0 1em"
    }
})(ResetButtonFromState)