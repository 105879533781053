import { FC, useState } from "react"
import { styled, Box, Divider, MuiCustomModal } from "@tm/components"
import { Image, Checkbox } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { tyresWheelsActions, useFastServiceStore } from "../../../../data"
import { CollapsibleWrapper, DefectDescription } from "../.."
import { TyreWear } from "../../../../data/state/tyres-wheels"

type Props = {
    handleCloseModal: () => void
    collapsibleName: string
    tabType: string
    open: boolean
}

const TiresAndWheelsDefekt: FC<Props> = ({ handleCloseModal, collapsibleName, tabType, open }) => {
    const { translateText } = useLocalization()
    const tabs = useFastServiceStore((state) => state.tyresWheels.tabs)

    const selectedCollapsible = tabs.find((x) => x.name === tabType)?.collapsibleItems.find((y) => y.name === collapsibleName)

    const [selectedWear, setSelectedWear] = useState<TyreWear | undefined>(selectedCollapsible?.tyreWear)
    const [safetyStatus, setSafetyStatus] = useState<number | undefined>(undefined)
    const [description, setDescription] = useState<string | undefined>(undefined)

    const handleChange = (checkName: "left" | "center" | "right") => {
        setSelectedWear((prevState) => ({ ...selectedWear, [checkName]: prevState ? !prevState[checkName] : true }))
    }

    const renderTireWear = () => {
        if (collapsibleName !== "tyreDot") {
            return null
        }

        return (
            <CollapsibleWrapper textId={13091}>
                <Wrapper>
                    <div>
                        <Image url="/bundles/fast-service/images/tire-left.svg" />
                        <Checkbox size="l" checked={selectedWear?.left} label={translateText(13092)} onToggle={() => handleChange("left")} />
                    </div>
                    <div>
                        <Image url="/bundles/fast-service/images/tire-center.svg" />
                        <Checkbox size="l" checked={selectedWear?.center} label={translateText(13093)} onToggle={() => handleChange("center")} />
                    </div>
                    <div>
                        <Image url="/bundles/fast-service/images/tire-right.svg" />
                        <Checkbox size="l" checked={selectedWear?.right} label={translateText(13094)} onToggle={() => handleChange("right")} />
                    </div>
                </Wrapper>

                <Divider sx={{ marginTop: "2em" }} />
            </CollapsibleWrapper>
        )
    }

    const handleStatusSelection = (value: number) => {
        setSafetyStatus(value)
    }

    const handleSaveDescription = (value: string) => {
        setDescription(value)
    }

    function handleOkButton() {
        // Images are not used yet, needs to be implemented when needed, this is deprecated
        // if (images.length > 0) {
        //     images.forEach((image) => tyresWheelsActions.updateTyresImages(image.img, { collapsibleName, tabType }, image.replaceImage))
        // }

        if (safetyStatus) {
            tyresWheelsActions.updateSafetyStatus(safetyStatus, { collapsibleName, tabType })
        }

        if (description) {
            tyresWheelsActions.updateDescription(description, { collapsibleName, tabType })
        }

        if (selectedWear) {
            tyresWheelsActions.updateTyreWear(selectedWear, { collapsibleName, tabType })
        }

        handleCloseModal()
    }

    return (
        <MuiCustomModal open={open} onOutsideClick={handleCloseModal} handleOkButton={handleOkButton}>
            <ContentFlex>
                <DefectDescription
                    selectedData={selectedCollapsible}
                    onCommentSave={handleSaveDescription}
                    onSelect={handleStatusSelection}
                    path={`tyresAndWheels_${collapsibleName}`}
                    additionalContent={renderTireWear()}
                />
            </ContentFlex>
        </MuiCustomModal>
    )
}

export default TiresAndWheelsDefekt

const Wrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-around",
})

const ContentFlex = styled(Box)({
    padding: "2em",
    flex: 1,
})
