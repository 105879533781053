import { Paging } from "@tm/models"
import { callCarsService } from "."
import { ModelsResponse } from "../../hooks/models"
import { VrcInfo } from "../../model/vrc-lookup"

type ShowModlelsByVrcInfoRequest = Paging & {
    vrcInfo: VrcInfo
    useDatLookup: boolean
}

type ShowModlelsByVrcInfoResponse = ModelsResponse

export function showModlelsByVrcInfo(request: ShowModlelsByVrcInfoRequest) {
    return callCarsService<ShowModlelsByVrcInfoRequest, ShowModlelsByVrcInfoResponse>("showModelsByVrcInfo", request, "POST")
}
