import { Stack, Tooltip, useMediaQuery, useTheme } from "@tm/components"
import { concat } from "@tm/utils"
import { BasketPart } from "../../../models"
import { OverflowTypography } from "../../StyledComponents"

type Props = {
    part: BasketPart
}

export default function ArticleDescription({ part }: Props) {
    const { articleInformation, mainPartId } = part.partItem
    const { additionalDescription, description, productGroupName } = articleInformation
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    const isLinkedItem = !!mainPartId
    function renderContent() {
        return (
            <>
                {isLinkedItem && (
                    <OverflowTypography variant="body2" fontWeight="bold">
                        {description}
                    </OverflowTypography>
                )}
                <OverflowTypography variant="body2">{productGroupName}</OverflowTypography>
                <OverflowTypography variant="body2">
                    {isLinkedItem ? additionalDescription : concat(" ", description, additionalDescription)}
                </OverflowTypography>
            </>
        )
    }
    return isBigScreen ? (
        <Stack>{renderContent()}</Stack>
    ) : (
        <Tooltip title={renderContent()} variant="light">
            <Stack width="50px" whiteSpace="nowrap">
                {renderContent()}
            </Stack>
        </Tooltip>
    )
}
