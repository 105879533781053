import { useLocalization } from "@tm/localization"
import { Icon } from "@tm/controls"
import { TelematicsDataSummaryResponse } from "../../../data"

type Props = {
    summary: TelematicsDataSummaryResponse
}

export default function Slide2Component(props: Props) {
    const { date, language } = useLocalization()
    const { mileage, nextBrakeFluidChangeDate, legalInspectionDate, conditionBasedServiceInfoCount } = props.summary

    return (
        <div className="slider__content slide-2">
            <div className="def-list">
                {mileage && (
                    <div className="def-list__item">
                        <Icon name="raceflag" size="l" />
                        <div>
                            <div className="def-list__text">{`${mileage.value} ${mileage.unit}`}</div>
                            <div className="def-list__term">{mileage.labelTextMap[language.toLowerCase()]}</div>
                        </div>
                    </div>
                )}
                {conditionBasedServiceInfoCount && (
                    <div className="def-list__item">
                        <Icon name="service-info" size="l" />
                        <div>
                            <div className="def-list__text">{conditionBasedServiceInfoCount.value}</div>
                            <div className="def-list__term">{conditionBasedServiceInfoCount.labelTextMap[language.toLowerCase()]}</div>
                        </div>
                    </div>
                )}
                {legalInspectionDate && (
                    <div className="def-list__item">
                        <Icon name="service" size="l" />
                        <div>
                            <div className="def-list__text">{date(legalInspectionDate.value, "d")}</div>
                            <div className="def-list__term">{legalInspectionDate.labelTextMap[language.toLowerCase()]}</div>
                        </div>
                    </div>
                )}
                {nextBrakeFluidChangeDate && (
                    <div className="def-list__item">
                        <Icon name="oil-can" size="l" />
                        <div>
                            <div className="def-list__text">{date(nextBrakeFluidChangeDate.value, "d")}</div>
                            <div className="def-list__term">{nextBrakeFluidChangeDate.labelTextMap[language.toLowerCase()]}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}
