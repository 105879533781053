import { Button, Icon, LinkButton, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { useParams } from "react-router"
import { VoucherRouteProps } from "../../vouchers/component"
import { Voucher } from "../../../data/model"
import SendVoucherConfirmationButton from "../buttons/SendVoucherConfirmationButton"

type Props = {
    detailsRoute: string
    hasConnectedVouchers?: boolean
    itemsCount: number
    openedConnectedVouchers?: Array<string>
    selectedVoucherId?: string
    showConnectedVouchersButton?: boolean
    voucherId: string
    voucherTypeId: number
    showConfirmVoucherButton?: boolean
    voucher?: Voucher
    onExpandRowClick?(voucherId: string): void
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function VoucherOptionsCellComponent(props: Props) {
    const {
        detailsRoute,
        voucherId,
        itemsCount,
        selectedVoucherId,
        openedConnectedVouchers,
        voucherTypeId,
        showConnectedVouchersButton,
        hasConnectedVouchers,
        showConfirmVoucherButton,
        voucher,
    } = props

    const { translateText } = useLocalization()
    const matchParams = useParams<VoucherRouteProps>()

    let url = renderRoute(detailsRoute, { ...matchParams, voucherTypeId, id: voucherId })
    const isOpened = openedConnectedVouchers?.some((item) => item === voucherId)
    const iconName = isOpened ? "up" : "down"
    const buttonTitle = isOpened ? translateText(459) : translateText(460)

    if (selectedVoucherId && selectedVoucherId === voucherId) {
        url = renderRoute(detailsRoute, { ...matchParams, voucherTypeId, id: null })
    }

    return (
        <Stack spacing={0.5} direction="row" textAlign="center" justifyContent="center" alignItems="center">
            {showConnectedVouchersButton && (
                <Button
                    disabled={!hasConnectedVouchers}
                    startIcon={<Icon name={iconName} />}
                    title={buttonTitle}
                    onClick={props.onExpandRowClick ? () => props.onExpandRowClick?.(voucherId) : undefined}
                />
            )}
            {showConfirmVoucherButton && voucher && <SendVoucherConfirmationButton voucher={voucher} onOpenNewVoucher={props.onOpenNewVoucher} />}
            <LinkButton
                to={url}
                title={translateText(597)}
                disabled={!itemsCount}
                color={selectedVoucherId === voucherId ? "highlight" : undefined}
                startIcon={<Icon name="file-details" />}
            />
        </Stack>
    )
}
