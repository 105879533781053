import { ajax, getStoredAuthorization } from "@tm/utils"
import { QueryClient } from "react-query"
import { getBundleParams } from "../../../utils"
import { InsertHistoryRequest, InsertHistoryResponse } from "./models"

export function insertHistory(request: InsertHistoryRequest): Promise<InsertHistoryResponse> {
    const url = `${getBundleParams().historyServiceUrl}/InsertHistoryWithData`
    const authorization = getStoredAuthorization()
    const body = request

    const queryClient = new QueryClient()
    return queryClient.fetchQuery({
        queryKey: ["InsertFastServiceHistory", { request }],
        queryFn: () => ajax({ url, body, authorization, method: "POST" }),
    })
}

export function getInsertHistoryRequest(vehicleId?: string, vinNumber?: string, calculationId?: string, historyData?: string, version?: number) {
    if (vehicleId && vinNumber && calculationId && historyData) {
        const request: InsertHistoryRequest = {
            version,
            vinNumber,
            vehicleId,
            calculationId,
            historyDataJson: historyData,
        }

        return request
    }

    return null
}
