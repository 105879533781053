import { ActiveButtons } from "../../../models"
import { SelectedItems } from "../../models"
import { MaintenanceReviewSlice } from "./model"

export function sendItemsToMaintenanceReview(
    state: MaintenanceReviewSlice,
    path: string,
    data: Record<string, SelectedItems>
): Partial<MaintenanceReviewSlice> {
    const maintenanceReviewState = state.maintenanceReview
    const selWorks = maintenanceReviewState.selectedWorks.set(path, data)

    return {
        maintenanceReview: {
            items: {
                ...maintenanceReviewState.items,
                [path]: data,
            },
            itemsForPrint: {
                ...maintenanceReviewState.items,
                [path]: data,
            },
            activeFilterButton: "none",
            selectedWorks: selWorks,
        },
    }
}

export function filterData(state: MaintenanceReviewSlice, buttonType: ActiveButtons): Partial<MaintenanceReviewSlice> {
    const maintenanceReviewState = state.maintenanceReview

    let filteredWorks: typeof maintenanceReviewState.selectedWorks = new Map([])

    if (buttonType === "none") {
        filteredWorks = new Map(Object.entries(maintenanceReviewState.items))
    } else {
        Object.entries(maintenanceReviewState.items).forEach(([key, items]) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const foundItems = Object.entries(items).filter(([_, item]) => item.selectedStatusButton === buttonType)
            if (foundItems.length) {
                filteredWorks.set(key, Object.fromEntries(foundItems))
            }
        })
    }

    return {
        maintenanceReview: {
            items: {
                ...maintenanceReviewState.items,
            },
            activeFilterButton: buttonType,
            selectedWorks: filteredWorks,
            itemsForPrint: {
                ...maintenanceReviewState.itemsForPrint,
            },
        },
    }
}
