import { PropsWithChildren } from "react"
import { CollapsibleProps, Collapsible } from "@tm/controls"
import { margin, em } from "csx"
import { styled } from "@tm/components"

type Props = PropsWithChildren<CollapsibleProps>

export default function BorderedCollapsible({ className, ...rest }: Props) {
    return <StyledCollapsible {...rest} className={className} />
}

const StyledCollapsible = styled(Collapsible)({
    ".collapsible__header": {
        borderBottom: "1px solid rgb(226, 226, 226)",
        margin: margin(em(1), 0),

        ".collapsible__label": {
            fontWeight: "unset !important",
            fontSize: "16px",
        },
    },
})
