import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { isSameDay } from "@tm/utils"
import { Box, FormControlLabel, Icon, Checkbox, Typography, Tooltip } from "@tm/components"
import { Item, OrderOptions, WarehouseDispatchType } from "@tm/models"
import {
    BoxWithAlignItems,
    BoxWithColumnPaddingRight,
    BoxWithMarginBottom,
    IconWithMargin,
    RadioButtonSmallWithMargin,
    RadioGroupGrid,
} from "./StyledComponents"
import { ShipmentMode } from "../../../../data/model"
import { getBundleParams } from "../../../../utils"

type Props = {
    disableFields: boolean
    options: OrderOptions
    completeDeliveryTour?: Date
    onSetDeliveryDistribution(
        id: string,
        description: string,
        selectedOptionsItem?: Item,
        updateErpInformation?: boolean,
        updateOrderOptions?: boolean
    ): void
    onSetShipment(shipmentMode: ShipmentMode, selectedOptionsItem?: Item, updateErpInformation?: boolean, updateOrderOptions?: boolean): void
}

export default function ShipmentOptionsComponent(props: Props) {
    const { translateText, translate, date } = useLocalization()
    const {
        disableFields,
        options: { shipmentModes, deliveryDistribution, selectedOptionsItem },
        onSetShipment,
        onSetDeliveryDistribution,
        completeDeliveryTour,
    } = props
    const [selectedShipmentId, setSelectedShipmentId] = useState<string>()
    const [shipmentUpdated, setShipmentUpdated] = useState<boolean>(false)
    const [selectedDeliveryDistributionId, setSelectedDeliveryDistributionId] = useState<string>()
    const [hideDeliveryDistribution, setHideDeliveryDistribution] = useState<boolean>(false)

    useEffect(() => {
        if (shipmentModes?.shipmentModes.length) {
            const defaultShipment = shipmentModes.shipmentModes.find((shipment) => shipment.isSelected)
            const shipmentId = defaultShipment ? defaultShipment.id : shipmentModes.shipmentModes[0].id
            const shipmentDispatchTypeId = defaultShipment ? defaultShipment.dispatchType : shipmentModes.shipmentModes[0].dispatchType
            setSelectedShipmentId(shipmentId)
            // DeliveryDistribution can only be selected for shipmentId and shipmentDispatchTypeId === 1
            setHideDeliveryDistribution(shipmentDispatchTypeId !== undefined && shipmentDispatchTypeId !== WarehouseDispatchType.Delivery)
        }
        if (deliveryDistribution?.deliveryDistributionModes.length) {
            const defaultDistribution = deliveryDistribution.deliveryDistributionModes.find((distribution) => distribution.isSelected)
            const distributionId = defaultDistribution ? defaultDistribution.id : deliveryDistribution.deliveryDistributionModes[0].id
            setSelectedDeliveryDistributionId(distributionId)
        }
    }, [shipmentModes, deliveryDistribution])

    function handleShipmentChange(_: unknown, id: string) {
        if (shipmentModes?.shipmentModes.length) {
            setSelectedShipmentId(id)
            const firstShipmentMatch = shipmentModes.shipmentModes.find((shipmentMode) => shipmentMode.id === id)
            if (firstShipmentMatch) {
                setShipmentUpdated(true)
                onSetShipment(
                    firstShipmentMatch,
                    selectedOptionsItem,
                    shipmentModes.updateErpInformationOnChange,
                    shipmentModes.updateOrderOptionsOnChange
                )
            }
        }
    }

    function changeDeliveryDistributor(id: string) {
        if (deliveryDistribution?.deliveryDistributionModes.length) {
            setSelectedDeliveryDistributionId(id)
            const firstDistributionMatch = deliveryDistribution.deliveryDistributionModes.find(
                (deliveryDistribution) => deliveryDistribution.id === id
            )
            if (firstDistributionMatch) {
                onSetDeliveryDistribution(
                    id,
                    firstDistributionMatch.description,
                    selectedOptionsItem,
                    deliveryDistribution.updateErpInformationOnChange,
                    deliveryDistribution.updateOrderOptionsOnChange
                )
            }
        }
    }

    function handleDeliveryDistributionChange(_: unknown, id: string) {
        changeDeliveryDistributor(id)
    }

    function handleDeliveryDistributionCheckboxChange(_: unknown, checked: boolean) {
        const distModes = deliveryDistribution?.deliveryDistributionModes

        if (distModes) {
            changeDeliveryDistributor(checked ? distModes[0].id : distModes[1].id)
        }
    }

    function renderShipmentModes() {
        return (
            <RadioGroupGrid onChange={handleShipmentChange}>
                {shipmentModes?.shipmentModes.map((mode) => {
                    return (
                        <BoxWithMarginBottom key={mode.id}>
                            <FormControlLabel
                                value={mode.id}
                                control={<RadioButtonSmallWithMargin disabled={disableFields} checked={mode.id === selectedShipmentId} />}
                                label={<Typography variant="body2">{mode.description}</Typography>}
                            />
                        </BoxWithMarginBottom>
                    )
                })}
            </RadioGroupGrid>
        )
    }

    function renderTourDescription() {
        if (completeDeliveryTour) {
            const weekday = isSameDay(completeDeliveryTour, new Date()) ? translateText(142) : date(completeDeliveryTour, "dddd")
            return `${date(completeDeliveryTour, "g")} : ${weekday}`
        }
    }

    function renderDeliveryDistribution() {
        const distModes = deliveryDistribution?.deliveryDistributionModes
        if (!distModes || hideDeliveryDistribution) {
            return
        }

        /* The id of Complete delivery is always 1, for STG that ist the only option that matters 
          {distModes && (distModes.length === 2 && distModes.some(distMode => distMode.id === "1") ? */
        if (distModes.length === 2) {
            return (
                <Box mt="12px" ml="38px">
                    <FormControlLabel
                        label={distModes[0].description}
                        sx={{ mr: 1 }}
                        control={
                            <Checkbox
                                checked={selectedDeliveryDistributionId === distModes[0].id}
                                onChange={handleDeliveryDistributionCheckboxChange}
                                size="small"
                                disabled={disableFields}
                            />
                        }
                    />
                    <Tooltip title={translateText(1855)}>
                        <Icon name="info" />
                    </Tooltip>
                    {selectedDeliveryDistributionId === distModes[0].id && completeDeliveryTour && (
                        <Typography variant="body2" ml="2.3em">
                            {renderTourDescription()}
                        </Typography>
                    )}
                </Box>
            )
        }
        return (
            <Box>
                <RadioGroupGrid onChange={handleDeliveryDistributionChange}>
                    {distModes.map((mode) => {
                        return (
                            <BoxWithMarginBottom key={mode.id}>
                                <FormControlLabel
                                    value={mode.id}
                                    control={
                                        <RadioButtonSmallWithMargin disabled={disableFields} checked={mode.id === selectedDeliveryDistributionId} />
                                    }
                                    label={<Typography variant="body2">{mode.description}</Typography>}
                                />
                            </BoxWithMarginBottom>
                        )
                    })}
                </RadioGroupGrid>
            </Box>
        )
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="truck" />
                <Typography variant="h4">{translate(765)}</Typography>
            </BoxWithAlignItems>
            {renderShipmentModes()}
            {renderDeliveryDistribution()}
            {shipmentUpdated && !getBundleParams().deactivateFavoriteOrderOptions && (
                <Box pt={2} ml="38px">
                    <Typography variant="h4">{`${translateText(813)}: `}</Typography>
                    <Typography variant="body2">{translateText(13003)}</Typography>
                </Box>
            )}
        </BoxWithColumnPaddingRight>
    )
}
