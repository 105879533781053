import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, uniqueId, encodeUniqueId, createQueryString, TmaHelper } from "@tm/utils"
import { useReplaceUrlTags, useUser, useWorkTask, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Widget, Icon, Loader, Image, WidgetProps, WidgetSizes } from "@tm/controls"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { getCurrentWorkTaskId } from "@tm/models"
import { SxProps, Theme, styled } from "@tm/components"
import { useHistory, useLocation, useParams } from "react-router"
import { ComponentState, IActions, Actions } from "./business"
import { useOpenExternalCatalogPage } from "../../hooks/useOpenExternalCatalogPage"

function getUrlFromExternalModule(extModule: { parameter: string; value: string }) {
    const { parameter, value } = extModule
    if (!parameter || !value) {
        return
    }
    const modules = window.userContext?.externalModules
    if (!modules) {
        return
    }
    const module = modules.find((x) => x.parameter?.some((y) => y.key === parameter && y.value === value))
    const url = module?.replacedUrl
    if (url) {
        return url
    }
}

function getExternalSystemParameter(key: string, externalSystemId?: number) {
    if (!externalSystemId) {
        return
    }
    const externalModul = window.userContext?.externalModules?.find((module) => module.id === externalSystemId)
    if (!externalModul) {
        return
    }
    return externalModul[key] ?? externalModul.parameter?.find((param) => param.key === key)?.value
}

function createQualifiedClassName(vehicleIsRequired: boolean, className?: string, catalogLight?: string) {
    let qualifiedClassName = `bundle-misc widget-cover-only widget widget--w1 widget--h1`
    if (className) {
        qualifiedClassName += ` ${className}`
    }

    if (vehicleIsRequired) {
        qualifiedClassName += " is-disabled"
    }

    if (catalogLight === "active") {
        qualifiedClassName += " is-disabled"
    }
    return qualifiedClassName
}

function getCoverIcon(imageUrl?: string, imageWidth?: string, icon?: string, iconLong?: string) {
    if (imageUrl) {
        const imageProps = { url: imageUrl, style: imageWidth ? { width: imageWidth, height: "auto" } : undefined }
        return <Image {...imageProps} className="widget__cover-icon" />
    }

    let imageOrIcon = icon ? <Icon className="widget__cover-icon" name={icon} /> : null

    iconLong
        ? (imageOrIcon = (
              <>
                  {imageOrIcon}
                  <Icon className="widget__cover-icon-long" name={iconLong} />
              </>
          ))
        : imageOrIcon // are both, icon and icon long, really necessary??

    return imageOrIcon
}

type Props = {
    state: ComponentState
    actions: IActions
    size?: string
    title?: string
    subTitle?: string
    icon?: string
    iconLong?: string
    imageUrl?: string
    imageWidth?: string
    className?: string
    catalogLight?: string
    extModule?: {
        parameter: string
        value: string
    }
    target: {
        url?: string
        getFromExtModule?: boolean
        externalSystemId?: number
        urlGenerator?: boolean
        openNewWindow?: boolean
        openModal?: boolean
        openInWorkTask?: boolean
        icon?: string
    }
    highlight?: boolean
    vehicleIsRequired?: boolean
    id?: string
    useNewIFrameForExternalId?: boolean
    sx?: SxProps<Theme>
}

const translationRegex = /\{\{(.*?)\}\}/
const SxWidget = styled(Widget)({})

function WidgetCoverOnlyComponent(props: Props) {
    const history = useHistory()
    const matchParams = useParams<{ workTaskId: string }>()
    const location = useLocation()
    const { translateText, languageId } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { workTask } = useWorkTask() ?? {}
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const {
        target,
        state,
        actions,
        subTitle,
        className,
        size,
        highlight,
        icon,
        iconLong,
        imageUrl,
        imageWidth,
        vehicleIsRequired,
        catalogLight,
        title,
        id,
        extModule,
        useNewIFrameForExternalId,
    } = props
    const { openNewWindow, openModal, openInWorkTask, externalSystemId } = target
    const [activeUrl, setActiveUrl] = useState<string>()
    const [redirectTriggered, setRedirectTriggered] = useState<boolean>()
    const replacedTags = useReplaceUrlTags(activeUrl, { location, kbaNumbers: state.kbaNumbers, languageId })
    const openExternalCatalogPage = useOpenExternalCatalogPage()

    function getActiveUrl() {
        if (state.url) {
            if (target.urlGenerator) {
                if (state.generatedUrl) {
                    return state.generatedUrl
                }
            } else {
                return state.url
            }
        } else if (target.getFromExtModule && extModule) {
            const url = getUrlFromExternalModule(extModule)
            return url
        }
    }

    function proceedWithRedirect(url: string) {
        if (target.openNewWindow) {
            window.open(target.url)
        } else {
            history.push(
                renderRoute(url, {
                    workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()),
                })
            )
        }
    }

    useEffect(() => {
        if (replacedTags && redirectTriggered) {
            if (openNewWindow) {
                window.open(replacedTags)
            } else if (openModal) {
                Morpheus.showView("1", `/modal^/external/${createQueryString({ replacedTags })}`)
            } else if (openInWorkTask) {
                const wkId = getCurrentWorkTaskId()
                if (wkId) {
                    history.push(`/${encodeUniqueId(wkId)}/ext${createQueryString({ url: replacedTags })}`)
                }
            } else {
                history.push(`/external01${createQueryString({ url: replacedTags })}`)
            }
            setRedirectTriggered(false)
        }
    }, [redirectTriggered, replacedTags, openNewWindow, openModal, openInWorkTask])

    useEffect(() => {
        if (!target.url && state.url) {
            if (state.generatedUrl) {
                setActiveUrl(state.generatedUrl)
            }
            if (target.urlGenerator) {
                if (!state.generatedUrl && !state.urlLoading) {
                    actions.getGeneratedUrl()
                }
            } else {
                setActiveUrl(state.url)
            }
        }
    }, [target.url, state.url, state.generatedUrl])

    async function handleRedirect() {
        setRedirectTriggered(true)

        if (target.url) {
            proceedWithRedirect(target.url)
            TmaHelper.GeneralCountEvent.CallModule(target.url)
        }

        if (externalSystemId) {
            if (useNewIFrameForExternalId) {
                await openExternalCatalogPage(externalSystemId)
            } else {
                setActiveUrl(undefined)
                actions.getUrl(externalSystemId, telesalesCustomerNo, userContext)
            }

            return
        }

        const url = getActiveUrl()
        if (url) {
            setActiveUrl(url)
        }
    }

    let newtitle =
        title ?? getExternalSystemParameter("catalogDescription", externalSystemId) ?? getExternalSystemParameter("description", externalSystemId)
    if (newtitle) {
        newtitle = newtitle.replace(translationRegex, (s: any, num: any) => translateText(num))
    }

    const externalImageUrl = getExternalSystemParameter("URL_Logo", externalSystemId)
    const imageOrIcon = getCoverIcon(imageUrl || externalImageUrl, imageWidth, icon, iconLong)

    const catalogLightParameter = userContext?.parameter.catalogLight
    const qualifiedClassName = createQualifiedClassName(
        !!vehicleIsRequired && !!workTask?.vehicle,
        className,
        catalogLightParameter ? catalogLight : ""
    )

    const widgetProps: WidgetProps = {
        size: (size as WidgetSizes | undefined) || "1x1",
        active: false,
        highlight,
        className: qualifiedClassName,
        onClick: handleRedirect,
        cover: (
            // externalImageUrl ? <><Image onClick={handleRedirect} style={{ cursor: "pointer" }} url={externalImageUrl} /> <div className="widget__cover-text">{newtitle}</div></> :
            <Widget.Cover>
                {imageOrIcon}
                <div className="widget__cover-text">{newtitle}</div>
                {subTitle && <div className="text">{subTitle}</div>}
                {target.icon && <Icon className="target-icon" name={target.icon} />}
                {state.urlLoading && <Loader visible />}
            </Widget.Cover>
        ),
        id,
    }
    return <SxWidget sx={props.sx} {...widgetProps} />
}

export default connectComponent(Actions, WidgetCoverOnlyComponent)
