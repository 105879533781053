import { useRef, useState, useMemo, Fragment } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { Button, Headline, WarningPrompt, XorSwitch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { AttributeFilterModel, channel, ConfigParams, FittingPosition, RequestArticleListPayload } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { denyWriteVehicleRecords } from "../../data/repository"
import { useVehicleRecords } from "./business/useVehicleRecords"
import ProductGroupFilter from "./subcomponents/product-group-filter"

type Props = RouteComponentProps & {
    calculatorRoute?: string
}

function VehicleRecordsComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()

    const { workTask } = useWorkTask() || {}
    const promptRef = useRef<WarningPrompt>(null)

    const [selectedFittingSide, setSelectedFittingSide] = useState(FittingPosition.FrontAxle)
    const { records, selectAttributeFilter, deselectAttributeFilter, clearAllFilters } = useVehicleRecords(workTask?.vehicle?.id, selectedFittingSide)

    const denyWrite = useMemo(() => denyWriteVehicleRecords(), [])

    const modelId = workTask?.vehicle?.tecDocTypeId
    if (!modelId) {
        return null
    }

    const handleShowClearAllWarning = () => {
        promptRef.current?.show()
    }

    const handleClearAll = () => {
        clearAllFilters()
    }

    const handleFittingPositionChange = (position: "left" | "right") => {
        setSelectedFittingSide(position === "left" ? FittingPosition.FrontAxle : FittingPosition.RearAxle)
    }

    const setFittingPositionLeft = () => {
        setSelectedFittingSide(FittingPosition.FrontAxle)
    }

    const setFittingPositionRight = () => {
        setSelectedFittingSide(FittingPosition.RearAxle)
    }

    const handleRequestArticleList = (attributeFilter: AttributeFilterModel) => {
        const request: RequestArticleListPayload = {
            productGroups: {
                ids: [attributeFilter.productGroupId],
                attributes: [
                    {
                        id: attributeFilter.id,
                        name: attributeFilter.abbreviation,
                        query: `${attributeFilter.id}|${attributeFilter.value}`,
                        text: attributeFilter.text,
                        unit: attributeFilter.unit,
                        description: attributeFilter.description,
                    },
                ],
            },
            fittingPosition: attributeFilter.fittingSide,
        }
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }

    const handleRequestCalculation = (productGroupId: number) => {
        const { calculatorRoute, match } = props
        if (productGroupId && calculatorRoute) {
            Morpheus.showView("1", renderRoute(calculatorRoute, { ...match.params, productGroupIds: [productGroupId], origin: "128" }))
        }
    }

    const pgWithFittingSides = records.map((productGroup) => {
        return { ...productGroup, attributeFilters: productGroup.attributeFilters.filter((filter) => !!filter.fittingSide) }
    })

    const pgWithoutFittingSides = records.map((productGroup) => {
        return { ...productGroup, attributeFilters: productGroup.attributeFilters.filter((filter) => !filter.fittingSide) }
    })

    const frontAxleClassName = !(selectedFittingSide & FittingPosition.FrontAxle) ? "text--disabled" : ""
    const rearAxleClassName = !(selectedFittingSide & FittingPosition.RearAxle) ? "text--disabled" : ""

    // FastCalculator is only available if enabled in the config and the vehicle has data
    const onRequestCalculation = props.calculatorRoute ? handleRequestCalculation : undefined
    const canRequestCalculation = !!workTask?.vehicle?.dataAvailabilities?.fastCalculator.hasAny

    return (
        <div className="tk-vehicle-records">
            <div className="vehicle-records">
                <Headline className="vehicle-records__headline" size="xs">
                    {translate(1038)}
                </Headline>

                {!denyWrite && (
                    <div className="vehicle-records__clear-all">
                        <Button
                            icon="delete"
                            className="vehicle-records__clear-all-button"
                            onClick={handleShowClearAllWarning}
                            skin={deleteButtonColorError ? "danger" : undefined}
                        >
                            {translateText(1077)}
                        </Button>
                        <WarningPrompt
                            text={translateText(1075)}
                            cancelButtonText={translateText(584)}
                            confirmationButtonText={translateText(585)}
                            ref={promptRef}
                            onConfirm={handleClearAll}
                        />
                    </div>
                )}

                <div className="vehicle-records__fitting-side">
                    <div className={`text ${frontAxleClassName}`} onClick={setFittingPositionLeft}>
                        {translateText(353)}
                    </div>
                    <XorSwitch status={selectedFittingSide & FittingPosition.FrontAxle ? "left" : "right"} onChange={handleFittingPositionChange} />
                    <div className={`text ${rearAxleClassName}`} onClick={setFittingPositionRight}>
                        {translateText(354)}
                    </div>
                </div>

                <div className="vehicle-records__pg-fitting-side">
                    {pgWithFittingSides
                        .filter((pg) => !!pg.attributeFilters.length)
                        .map((productGroup) => (
                            <Fragment key={`pg-fittingside${productGroup.id}${productGroup.name}`}>
                                <ProductGroupFilter
                                    productGroupFilter={productGroup}
                                    selectedFittingSide={selectedFittingSide}
                                    modelId={modelId}
                                    denyWrite={denyWrite}
                                    onSelectAttribute={selectAttributeFilter}
                                    onDeselectAttribute={deselectAttributeFilter}
                                    onRequestArticleList={handleRequestArticleList}
                                    onRequestCalculation={onRequestCalculation}
                                    canRequestCalculation={canRequestCalculation}
                                />
                            </Fragment>
                        ))}
                </div>
                <div className="vehicle-records__pg-no-fitting-side">
                    {pgWithoutFittingSides
                        .filter((pg) => !!pg.attributeFilters.length)
                        .map((productGroup) => (
                            <Fragment key={`pg-no-fittingside${productGroup.id}${productGroup.name}`}>
                                <ProductGroupFilter
                                    productGroupFilter={productGroup}
                                    modelId={modelId}
                                    denyWrite={denyWrite}
                                    onSelectAttribute={selectAttributeFilter}
                                    onDeselectAttribute={deselectAttributeFilter}
                                    onRequestArticleList={handleRequestArticleList}
                                    onRequestCalculation={onRequestCalculation}
                                    canRequestCalculation={canRequestCalculation}
                                />
                            </Fragment>
                        ))}
                </div>
            </div>
        </div>
    )
}

export default withRouter(VehicleRecordsComponent)
