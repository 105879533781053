import { useMutation, UseMutateAsyncFunction } from "react-query"
import { Employee, FindEmployeeRequest, findEmployees } from "../repositories"

export const EMPLOYEES_FIND_REQUEST_KEY = "EMPLOYEES_FIND"

export const useFindEmployee = (): {
    findEmployee: UseMutateAsyncFunction<Employee[], unknown, FindEmployeeRequest>
    isLoading: boolean
} => {
    const mutation = useMutation(EMPLOYEES_FIND_REQUEST_KEY, findEmployees)

    return { findEmployee: mutation.mutateAsync, isLoading: mutation.isLoading }
}
