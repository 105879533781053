import { Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { ValueLabelStack } from "./ValueLabelStack"

type Props = {
    confirmedQuantityValue: number
    returnedQuantityValue?: number
    isBigScreen: boolean
    hideReturnQuantity?: boolean
}

export function ArticleQuantities({ confirmedQuantityValue, returnedQuantityValue, isBigScreen, hideReturnQuantity }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { hasMailRetoure } = userContext
    const showReturns = hasMailRetoure && !hideReturnQuantity

    return (
        <Stack direction={isBigScreen ? "row" : "column"} columnGap={2}>
            <ValueLabelStack label={showReturns ? translateText(782) : translateText(89)} value={confirmedQuantityValue} />
            {showReturns && <ValueLabelStack label={translateText(12663)} value={returnedQuantityValue || 0} />}
        </Stack>
    )
}
