import { Paper } from "@tm/components"
import { Toolbar } from "@tm/controls"
import { PropsWithChildren } from "react"
import { StyledBox } from "./StyledComponents"

type Props = PropsWithChildren<{}> & {
    title: string
}

export default function FieldComponent({ title, children }: Props) {
    return (
        <StyledBox>
            <Paper>
                <Toolbar title={title}>{children}</Toolbar>
            </Paper>
        </StyledBox>
    )
}
