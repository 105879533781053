import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function damageCalculationEurotaxFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const damageCalculationEurotaxModule = getModuleFromUserContext(userContext, UserModuleType.TMDamageCalculationEurotax)

    if (!damageCalculationEurotaxModule) {
        return undefined
    }

    return {
        "damage-calculation": {
            items: {
                "damage-calculation-eurotax": {
                    sort: 0,
                    name: "Eurotax",
                    active: true,
                    route: "/:workTaskId/damage-calculation/eurotax",
                    setAsActiveVehicleDataProviderOnSelect: {
                        damageCalculation: "eurotax",
                    },
                },
            },
        },
    }
}
