import { OrderOptions, SendOrderOptions } from "@tm/models"
import { SelectedOrderOptions } from "../model"

export function mapOrderOptionsToSelectedOrderOptions(options: OrderOptions, workTaskId?: string): SelectedOrderOptions {
    const billingMode = options.billingModes?.billingModes.find((mode) => mode.isSelected)
    const deliveryDistributionMode = options.deliveryDistribution?.deliveryDistributionModes.find(
        (deliveryDistribution) => deliveryDistribution.isSelected
    )
    const paymentOption = options.paymentOptions?.paymentOptions.find((payment) => payment.isSelected)
    const shipmentMode = options.shipmentModes?.shipmentModes.find((mode) => mode.isSelected)
    const tour = options.tours?.tours.find((tour) => tour.isSelected)
    const orderProcessingMode = options.orderProcessingOptions?.orderProcessingOptions.find((orderProcessing) => orderProcessing.isSelected)
    const backorderOption = options.backorderOptions?.backorderOptions.find((backorder) => backorder.isSelected)
    const billingAddress = options.billingAddresses?.addresses.find((address) => address.isSelected)
    const deliveryAddress = options.deliveryAddresses?.addresses.find((address) => address.isSelected)

    return {
        workTaskId,
        warehouseId: options.warehouseId,
        orderComment: options.orderComment,
        submitOrderItemComment: options.hasItemComment,
        orderCommentMaxLength: options.orderCommentMaxLength,
        customerOrderNumber: options.customerOrderNumber,
        hasCustomerOrderNumber: options.hasCustomerOrderNumber,
        customerOrderNumberMaxLength: options.customerOrderNumberMaxLength,
        hasOrderComment: options.hasOrderComment,
        hasItemComment: options.hasItemComment,
        itemCommentMaxLength: options.itemCommentMaxLength,
        selectedBillingAddress: billingAddress,
        selectedBillingOption: billingMode,
        selectedDeliveryAddress: deliveryAddress,
        selectedDeliveryDistributionMode: deliveryDistributionMode,
        selectedPaymentOption: paymentOption,
        selectedShipmentMode: shipmentMode,
        selectedTour: tour,
        selectedBackorderOption: backorderOption,
        deliveryDate: options.deliveryDate,
        wholesalerOrderConfirmation: false,
        selectedOrderProcessingOption: orderProcessingMode,
    }
}

export function mapOrderOptionsToSendOrderOptions(
    options: OrderOptions,
    wholesalerOrderConfirmation?: boolean,
    orderConfirmationEmail?: string
): SendOrderOptions {
    const billingMode = options.billingModes?.billingModes.find((mode) => mode.isSelected)
    const deliveryDistributionMode = options.deliveryDistribution?.deliveryDistributionModes.find(
        (deliveryDistribution) => deliveryDistribution.isSelected
    )
    const paymentOption = options.paymentOptions?.paymentOptions.find((payment) => payment.isSelected)
    const shipmentMode = options.shipmentModes?.shipmentModes.find((mode) => mode.isSelected)
    const tour = options.tours?.tours.find((tour) => tour.isSelected)
    const orderProcessingOption = options.orderProcessingOptions?.orderProcessingOptions.find((orderProcessing) => orderProcessing.isSelected)
    const backorderOption = options.backorderOptions?.backorderOptions.find((backorder) => backorder.isSelected)
    const billingAddress = options.billingAddresses?.addresses.find((address) => address.isSelected)
    const deliveryAddress = options.deliveryAddresses?.addresses.find((address) => address.isSelected)

    return {
        warehouseId: options.warehouseId,
        billingAddress,
        deliveryAddress,
        billingMode,
        paymentOption,
        shipmentMode,
        deliveryDistributionMode,
        tour,
        orderProcessingOption,
        backorderOption,
        deliveryDate: options.deliveryDate,
        customerOrderNumber: options.customerOrderNumber,
        orderComment: options.orderComment,
        hasCustomerOrderNumber: options.hasCustomerOrderNumber,
        customerOrderNumberMaxLength: options.customerOrderNumberMaxLength,
        hasOrderComment: options.hasOrderComment,
        orderCommentMaxLength: options.orderCommentMaxLength,
        hasItemComment: options.hasItemComment,
        itemCommentMaxLength: options.itemCommentMaxLength,
        wholesalerOrderConfirmation: options ? wholesalerOrderConfirmation : undefined,
        orderConfirmationEmail,
    }
}
