import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce } from "./business"

const IFrameWidget: BundleComponent<any, typeof component> = {
    name: "iframe-widget",
    component,
    reduce,
}

export default IFrameWidget
