import { CarModel, RegistrationNoType, ShowCarModelDetailsRequest, ShowCarModelDetailsResponse, VehicleShortInfo } from "@tm/models"

import { callCarsService } from "."
import { PagedRequest, PagedResponse } from ".."
import { CarBodyType, EngineCapacityFilter, EngineCodeFilter, FuelTypeFilter, ModelYearFilter } from "../../model/filters"

type SelectedModelFilters = {
    bodyTypeId?: number
    modelYear?: number
    fuelType?: string
    engineCode?: string
    engineCapacity?: number
}

type ModelFilters = {
    bodyTypes: Array<CarBodyType>
    modelYears: Array<ModelYearFilter>
    fuelTypes: Array<FuelTypeFilter>
    engineCodes: Array<EngineCodeFilter>
    engineCapacities: Array<EngineCapacityFilter>
}

export type ModelsResponse = PagedResponse & {
    models: Array<CarModel>
    customerVehicles: Array<VehicleShortInfo>
    filters: ModelFilters
}

type ShowModelsByArticleRequest = PagedRequest & {
    articleId: number
    productGroupId?: number
    manufacturerId?: number
    modelSeriesId?: number
}

export function showModelsByArticle(request: ShowModelsByArticleRequest) {
    return callCarsService<ShowModelsByArticleRequest, ModelsResponse>("ShowModelsByArticle", request)
}

type ShowModelsByPartListArticleRequest = PagedRequest & {
    partsListArticleId: number
    modelSeriesId: number
}

export function showModelsByPartListArticle(request: ShowModelsByPartListArticleRequest) {
    return callCarsService<ShowModelsByPartListArticleRequest, ModelsResponse>("ShowModelsByPartListArticle", request)
}

type ShowModelsByIdsRequest = PagedRequest & {
    modelIds: Array<number>
    selectedFilters?: SelectedModelFilters
}

export function showModelsByIds(request: ShowModelsByIdsRequest) {
    return callCarsService<ShowModelsByIdsRequest, ModelsResponse>("ShowModelsByIds", request)
}

type ShowModelsByModelSeriesRequest = PagedRequest & {
    modelSeriesId: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByModelSeries(request: ShowModelsByModelSeriesRequest) {
    return callCarsService<ShowModelsByModelSeriesRequest, ModelsResponse>("ShowModelsByModelSeries", request)
}

type ShowModelsByQueryRequest = PagedRequest & {
    query: string
    manufacturerIds?: Array<number>
    modelSeriesId?: number
    selectedFilters?: SelectedModelFilters
    selectedRegistrationNoType?: RegistrationNoType
    countryCode?: string
}

export function showModelsByQuery(request: ShowModelsByQueryRequest) {
    return callCarsService<ShowModelsByQueryRequest, ModelsResponse>("ShowModelsByQuery", request)
}

export type ShowModelsByRegistrationNoRequest = PagedRequest & {
    registrationNo: string
    registrationNoTypeId: RegistrationNoType
    manufacturerIds?: Array<number>
    modelSeriesId?: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByRegistrationNo(request: ShowModelsByRegistrationNoRequest) {
    return callCarsService<ShowModelsByRegistrationNoRequest, ModelsResponse>("ShowModelsByRegistrationNo", request)
}

export function showModelDetails(request: ShowCarModelDetailsRequest) {
    return callCarsService<ShowCarModelDetailsRequest, ShowCarModelDetailsResponse>("ShowModelDetails", request).then((response) => {
        /** @todo REMOVE (move to service): Set request regNo as response vin if not set and regNoType is DATVin */
        if (
            request.registrationNo &&
            response?.modelDetails?.registrationNoDetails &&
            !response.modelDetails.registrationNoDetails.vin &&
            request.registrationNoTypeId === RegistrationNoType.DatVin
        ) {
            response.modelDetails.registrationNoDetails.vin = request.registrationNo
        }

        return response
    })
}
