import { getStoredAuthorization, ajax } from "@tm/utils"
import { CisFindCustomerDto, CisQueryType, CisFindCustomersRequest, CisCustomerResponse, CisShowCustomerRequest } from "@tm/models"
import { getCisServiceUrl } from "../.."
import { mapCustomer } from "../../mapper"

function getServiceUrl() {
    return `${getCisServiceUrl()}/Customer`
}

export function showCustomer(body: CisShowCustomerRequest) {
    const url = `${getServiceUrl()}/ShowCustomer`
    const authorization = getStoredAuthorization()

    return ajax<{ customer?: CisCustomerResponse }>({ url, body, authorization }).then((response) => {
        if (!response) {
            return
        }
        const customer = mapCustomer(response.customer)
        if (!customer) {
            return
        }

        return {
            ...customer,
            requestedCustomerNo: body.customerNo, // In some cases (PV/Neimcke) is the requested customerNo not equals to the real customerNo, but we need to compare the requested one later.
        }
    })
}

export function loadCurrentCaller() {
    const url = `${getServiceUrl()}/GetCustomerByCurrentCaller`
    const authorization = getStoredAuthorization()

    return ajax<{ customers?: Array<CisFindCustomerDto> }>({ url, authorization }).then((data) => data?.customers || [])
}

export function findCustomers(body: CisFindCustomersRequest) {
    const url = `${getServiceUrl()}/FindCustomers`
    const authorization = getStoredAuthorization()

    return ajax<{ customers?: Array<CisFindCustomerDto> }>({ url, body, authorization }).then((data) => data?.customers || [])
}

export function getQueryTypes(): Promise<CisQueryType[]> {
    const url = `${getServiceUrl()}/GetQueryTypes`
    const authorization = getStoredAuthorization()

    return ajax<{ queryTypes?: Array<CisQueryType> }>({ url, authorization }).then((response) => response?.queryTypes || [])
}
