import { useState, useCallback, useMemo, useEffect } from "react"
import { Dropzone, ProgressBar, Icon, Typography, Alert, Stack, Box, Button, Dialog } from "@tm/components"
import { AllowedFileSelectionTypes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FileRejection } from "react-dropzone"
import { TmaHelper, getFileContent, showWarehouseDataMissingError, useDefaultErpSystem, useDefaultOrderWarehouse } from "@tm/utils"
import { useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { AddWholesalerPartListRequest, ChangeItemsResponse, ECounterType, TmaEModule, WholesalerPart } from "@tm/models"

const ALLOWED_FILE_TYPES: AllowedFileSelectionTypes = ["textTxt", "textCsv"]
const BASE64_MARKER = ";base64,"
const DEFAULT_PARTS_TO_IMPORT = 50
const IMPORT_PAGING_SIZE = 25
const MAX_PARTS_TO_IMPORT = 999 // MDM field allows 3 digits
const MAX_FILE_SIZE = 1000000

type Props = {
    disabled: boolean
    onAddWholesalerArticlesToBasket(
        request: AddWholesalerPartListRequest,
        skipResponseHandling?: boolean,
        skipReloadQuanities?: boolean
    ): Promise<ChangeItemsResponse | undefined>
}

export default function ImportFileButton({ disabled, onAddWholesalerArticlesToBasket }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const workTaskId = useWorkTask()?.workTaskId
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { erpSystemConfig } = useDefaultErpSystem()
    const { warehouseData, refetchWarehouseData } = useDefaultOrderWarehouse(
        { telesalesCustomerNo, distributorId: erpSystemConfig?.id },
        enableServiceCalls
    )

    const [showImportFileDialog, setShowImportFileDialog] = useState(false)
    const [importingParts, setImportingParts] = useState(false)
    const [importingSteps, setImportingSteps] = useState(0)
    const [activeImportingStep, setActiveImportingStep] = useState(0)
    const [file, setFile] = useState<{ data: string; name: string; mimeType: string }>()
    const [error, setError] = useState<string>()

    const maxPartsToImport = useMemo(() => {
        const filesToImport = userContext?.parameter.fileImportBasket
        return filesToImport && filesToImport > 1 && filesToImport <= MAX_PARTS_TO_IMPORT ? filesToImport : DEFAULT_PARTS_TO_IMPORT
    }, [userContext.parameter.fileImportBasket])

    const addWholesalerArticlesToBasket = useCallback(
        async (fullList: Array<WholesalerPart>) => {
            const tempList = [...fullList]
            setImportingSteps(Math.floor(tempList.length / IMPORT_PAGING_SIZE))
            let tempStep = activeImportingStep
            while (tempList.length && workTaskId) {
                const batch = tempList.splice(0, IMPORT_PAGING_SIZE)
                // eslint-disable-next-line no-await-in-loop
                await onAddWholesalerArticlesToBasket(
                    {
                        workTaskId,
                        wholesalerParts: batch,
                        usePercentageValues: true,
                        log: TmaHelper.AddCustomToBasket.GetUserInteractionLog("wholesalerPartList", TmaEModule.FILEIMPORT),
                    },
                    tempList.length !== 0,
                    true
                ).catch((er) => {
                    setError(er)
                })

                tempStep++
                setActiveImportingStep(tempStep)
            }

            setShowImportFileDialog(false)
            setImportingParts(false)
        },
        [workTaskId] // Adding activeImportingStep to the dependencies will generate an endless loop
    )

    function convertDataURIToDecodedString(dataURI: string) {
        const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length
        const base64 = dataURI.substring(base64Index)
        return window.atob(base64)
    }

    function handleImportfiledialogClose() {
        setError(undefined)
        setShowImportFileDialog(false)
        setImportingParts(false)
    }

    const handleDropReject = useCallback((fileRejections: FileRejection[]) => {
        setError(fileRejections[0].errors[0].message)
    }, [])

    const handleDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (!warehouseData || warehouseData.hasErrors) {
                showWarehouseDataMissingError(translateText)
                refetchWarehouseData()
                return
            }

            getFileContent(acceptedFiles[0]).then((fileContent) => {
                setFile({ data: fileContent, mimeType: acceptedFiles[0].type, name: acceptedFiles[0].name })
            })
        },
        [warehouseData, translateText, refetchWarehouseData]
    )

    function normalise(value: number) {
        return ((value - 0) * 100) / (importingSteps - 0)
    }

    useEffect(() => {
        if (file) {
            const decodedData = convertDataURIToDecodedString(file.data)
            if (decodedData) {
                let articles = decodedData.split("\n")
                articles = articles.filter((item) => item.trim() !== "")
                if (articles) {
                    if (articles.length <= maxPartsToImport) {
                        setImportingParts(true)
                        const wholesalerArticles: Array<WholesalerPart> = []
                        for (let i = 0; i < articles.length; i++) {
                            if (articles[i].trim() !== "") {
                                const wholesalerArticleNumber = articles[i].substring(0, articles[i].indexOf(";"))
                                const quantity = Number(articles[i].substring(articles[i].indexOf(";") + 1))
                                if (wholesalerArticleNumber && !Number.isNaN(quantity)) {
                                    if (!wholesalerArticles.some((article) => article.wholesalerArticleNumber === wholesalerArticleNumber)) {
                                        wholesalerArticles.push({
                                            wholesalerArticleNumber,
                                            quantityValue: quantity,
                                            distributorId: erpSystemConfig?.id,
                                            distributorName: erpSystemConfig?.description,
                                            warehouseId: warehouseData?.defaultWarehouse?.id,
                                            warehouseName: warehouseData?.defaultWarehouse?.name,
                                        })
                                    } else {
                                        wholesalerArticles.forEach((article) => {
                                            if (article.wholesalerArticleNumber === wholesalerArticleNumber) {
                                                article.quantityValue += quantity
                                            }
                                        })
                                    }
                                } else {
                                    setError(translateText(12494))
                                    setImportingParts(false)
                                    return
                                }
                            }
                        }

                        if (wholesalerArticles) {
                            addWholesalerArticlesToBasket(wholesalerArticles)
                        } else {
                            setError(translateText(1010))
                            setImportingParts(false)
                        }
                    } else {
                        setError(translateText(12521).replace("{0}", maxPartsToImport.toString()))
                    }
                } else {
                    setError(translateText(1010))
                }
            } else {
                setError(translateText(1010))
            }
        }
    }, [
        addWholesalerArticlesToBasket,
        erpSystemConfig?.description,
        erpSystemConfig?.id,
        file,
        maxPartsToImport,
        translateText,
        warehouseData?.defaultWarehouse?.id,
        warehouseData?.defaultWarehouse?.name,
    ])

    return (
        <>
            {(userContext?.parameter.fileImportBasket ?? 0) >= 1 && (
                <Button
                    onClick={() => {
                        setShowImportFileDialog(true)
                        TmaHelper.GeneralCountEvent.Call(ECounterType.ImportToBasket)
                    }}
                    startIcon={<Icon name="upload" />}
                    title={translateText(12482)}
                    disabled={disabled}
                />
            )}
            <Dialog open={showImportFileDialog} maxWidth="md" position="top">
                <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h3">{translateText(12485)}</Typography>
                        {!importingParts && (
                            <Button onClick={handleImportfiledialogClose} startIcon={<Icon name="close" />} disabled={importingParts} />
                        )}
                    </Stack>
                    {importingParts ? (
                        <ProgressBar value={normalise(activeImportingStep)} />
                    ) : (
                        <Dropzone
                            allowedFileTypes={ALLOWED_FILE_TYPES}
                            maxSize={MAX_FILE_SIZE}
                            maxFiles={1}
                            onDrop={handleDrop}
                            onDropRejected={handleDropReject}
                        >
                            {() => (
                                <Box
                                    height="318px"
                                    border="1px dashed #979797"
                                    minWidth="50rem"
                                    justifyContent="center"
                                    alignItems="center"
                                    display="flex"
                                >
                                    <Icon name="document" />
                                    <Typography>{translateText(13080)}</Typography>
                                </Box>
                            )}
                        </Dropzone>
                    )}
                    {error && <Alert severity="error" title={error} />}
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" spacing={0.5}>
                            <Icon name="info" color="highlight" />
                            <Typography>
                                {translateText(12487)
                                    .split("<br>")
                                    .map((x, index) => (
                                        <Typography key={index} variant={index === 0 ? "h4" : "body2"}>
                                            {x}
                                        </Typography>
                                    ))}
                            </Typography>
                        </Stack>
                        <Stack>
                            <Typography variant="h4">{translateText(12524)}</Typography>
                            <Typography variant="body2">{`- ${translateText(12523).replace("{0}", maxPartsToImport.toString())}`}</Typography>
                            <Typography variant="body2">{`- ${translateText(12525)}`}</Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Dialog>
        </>
    )
}
