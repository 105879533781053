import { Stack } from "@tm/components"
import { VehicleSearchField } from "./VehicleSearchField"
import { VehicleSearchfieldV1Props } from "./VehicleSearchFieldSwitch"
import SpecialLicensePlatesComponent from "../widget/components/special-license-plates/component"

export function VehicleSearchV1(props: VehicleSearchfieldV1Props) {
    return (
        <Stack>
            {!props.hideSpecialLicensePlateSearchField && (
                <SpecialLicensePlatesComponent
                    {...props?.specialLicensePlatesProps}
                    vehicleType={props.vehicleType}
                    className="vehicle-widget__special-license-plates-micro"
                />
            )}

            <VehicleSearchField {...props} />
        </Stack>
    )
}
