import { Box, Divider, Icon, SharePopover, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ECounterType, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { TmaHelper } from "@tm/utils"
import { SharingData } from "."
import { bundleChannel } from "../../../../business"
import { getBundleParams } from "../../../../utils"
import { CostEstimation } from "../../../../data/model"
import { VisibleOption } from "./VisibleOption"

type Props = {
    disabled: boolean
    customerEmail: string | undefined
    sharingData: SharingData
    costEstimation?: CostEstimation
    printVehicleImage: boolean
    useNoteTextByDefault: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    onChangeSharingData(sharingData: SharingData): void
    onSendCostEstimationButtonClick(): void
    onGenerateCostEstimationLinkButtonClick(): void
}

export function ShareButton(props: Props) {
    const {
        disabled,
        sharingData,
        customerEmail,
        showManufacturer,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        costEstimation,
        printVehicleImage,
        useNoteTextByDefault,
        onChangeSharingData,
        onGenerateCostEstimationLinkButtonClick,
        onSendCostEstimationButtonClick,
    } = props
    const { priceTypeMode, priceVatMode, customerGroupMode } = costEstimation?.costEstimationOptions || {}
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const unsubRefs = useRef<Array<() => void>>([])

    const message = useMemo(() => {
        if (!sharingData.link) {
            return
        }

        let text = `${translateText(13353)}: ${sharingData.link}`

        if (sharingData.pin) {
            text += `\n${translateText(13354)}: ${sharingData.pin}`
        }

        return text
    }, [sharingData, translateText])

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            unsubRefs.current.forEach((unsub) => unsub())
        }
    }, [])

    const handleRequestLink = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateSend)

        return new Promise<void>((resolve, reject) => {
            let errorTimeout = 0

            // TODO: This should be refactored somehow to not subscribe to the bundleChannel but `onGenerateCostEstimationLink` should return a Promise or likewise

            const unsub = bundleChannel().subscribe("COST_ESTIMATION_LINK_CREATED", ({ link, additionalData }) => {
                window.clearTimeout(errorTimeout)
                unsub()
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                unsub2()
                onChangeSharingData({ loading: false, link, pin: additionalData?.pin })
                resolve()
            })
            unsubRefs.current.push(unsub)

            const unsub2 = bundleChannel().subscribe("COST_ESTIMATION_LINK_CREATION_ERROR", ({ error }) => {
                window.clearTimeout(errorTimeout)
                unsub()
                unsub2()
                onChangeSharingData({ loading: false, error: error?.message ?? true })
                reject()
            })
            unsubRefs.current.push(unsub2)

            errorTimeout = window.setTimeout(() => {
                unsub()
                unsub2()
                onChangeSharingData({ loading: false, error: true })
                reject()
            }, 10000) // abort after 10 seconds

            onChangeSharingData({ loading: true })
            onGenerateCostEstimationLinkButtonClick()
        })
    }, [onGenerateCostEstimationLinkButtonClick, onChangeSharingData])

    function handleSendCostEstimationButtonClick() {
        if (!getBundleParams().enableAdvancedSharingOptions) {
            TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateSend)
        }
        onSendCostEstimationButtonClick()
    }

    if (!getBundleParams().enableAdvancedSharingOptions) {
        return (
            <>
                {renderMicro("notifications", "send-cost-estimation-button", {
                    disabled,
                    onClick: handleSendCostEstimationButtonClick,
                })}
            </>
        )
    }

    return (
        <SharePopover
            title={`${translateText(13355)}:`}
            link={sharingData.link}
            onRequestLink={handleRequestLink}
            isRequestingLink={sharingData.loading}
            errorMessage={sharingData.error === true ? translateText(13301) : sharingData.error}
            buttonProps={{
                disabled,
                variant: "outlined",
                onClick: !sharingData.link ? handleRequestLink : undefined,
            }}
            popoverProps={{
                anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                },
                transformOrigin: {
                    vertical: "top",
                    horizontal: "left",
                },
            }}
        >
            <SharePopover.ShareButtonWrapper>
                <SharePopover.WhatsAppButton message={message} />
                <SharePopover.EmailButton recipient={customerEmail} subject={translateText(13356)} body={message} />
                {renderMicro("notifications", "send-cost-estimation-button", {
                    asShareButton: true,
                    disabled,
                    onClick: handleSendCostEstimationButtonClick,
                })}
                <SharePopover.DownloadButton
                    disabled={!sharingData.link}
                    href={sharingData.link}
                    {...{ download: true }} // TODO: Handle this correct in the DownloadButton itself
                />
            </SharePopover.ShareButtonWrapper>
            <SharePopover.AdditionalPopoverContent>
                <Stack gap={1.5}>
                    <Stack direction="row" gap={1}>
                        <Icon name="settings" />
                        <Typography textTransform="uppercase" variant="body2">
                            {translateText(222)}
                        </Typography>
                        <Box width="100%" alignSelf="center">
                            <Divider variant="middle" />
                        </Box>
                    </Stack>
                    <Box display="grid" gridTemplateColumns="auto auto" ml={1}>
                        <Stack gridColumn="span  1" sx={{ ":empty": { display: "none" } }}>
                            {showSupplierArticleNumbers && <VisibleOption textId={87} />}
                            {showWholesalerArticleNumbers && <VisibleOption textId={91} />}
                            {showManufacturer && <VisibleOption textId={71} />}
                            {printVehicleImage && <VisibleOption textId={1632} />}
                        </Stack>
                        <Stack gridColumn="span  1">
                            <VisibleOption textId={priceVatMode ? 13744 : 13745} />
                            <VisibleOption textId={priceTypeMode ? 13748 : 13749} />
                            <VisibleOption textId={customerGroupMode ? 13746 : 13747} />
                            {useNoteTextByDefault && <VisibleOption textId={13760} />}
                        </Stack>
                    </Box>
                </Stack>
            </SharePopover.AdditionalPopoverContent>
        </SharePopover>
    )
}
