import { FC, useRef } from "react"
import { Badge, Demo } from "@tm/controls"
import { ActivateModuleRef, ModuleGroupId, ModuleId, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { getModuleOptionsAndPackages, renderRoute, useActivateModule, useGetActivatableModules } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { Box, Button, Icon, styled } from "@tm/components"
import { useHideDemoBanner } from "../../../hooks"
import { getMessage, getModuleTitle } from "../helpers"
import ActivateModule from "../../../micros/activate-module/component"

type Props = {
    activationRoute?: string
    moduleGroupId: ModuleGroupId | "inspectionData"
    moduleGroupIdAux: ModuleGroupId
    moduleId: ModuleId
    moduleName: string
    vehicleType: VehicleType
}

const DemoBanner: FC<Props> = ({ moduleGroupId, moduleGroupIdAux, moduleId, moduleName, vehicleType, activationRoute }) => {
    const { translateText, languageId } = useLocalization()

    const user = useUser()
    const activatableModules = useGetActivatableModules(user?.userContext)
    const { activatingModule } = useActivateModule()

    const activateRef = useRef<ActivateModuleRef>(null)
    const { handleHideBanner, hideBanner } = useHideDemoBanner(moduleGroupIdAux, moduleId)

    const mainModule = activatableModules.response?.moduleGroups
        ?.find((x) => x.id === moduleGroupIdAux)
        ?.modules?.find((x) => x.id.toLowerCase() === moduleId.toLocaleLowerCase() && x.vehicleType === vehicleType)

    if (!mainModule) {
        return null
    }

    const {
        defaultFullOptionModule,
        defaultDemoOptionModule,
        activeFullModulePackage,
        activeDemoModulePackage,
        activatableFullModulePackage,
        activatableDemoModulePackage,
        fullOptionModule,
        demoOptionModule,
        isFullPackageActive,
    } = getModuleOptionsAndPackages(mainModule)

    if (isFullPackageActive) {
        return null
    }

    if (
        activationRoute &&
        ["dat", "eurotax", "tecrmi", "haynespro"].includes(moduleId) &&
        !defaultFullOptionModule?.active &&
        !defaultDemoOptionModule?.active
    ) {
        const comparePageUrl = renderRoute(activationRoute, {})

        Morpheus.showView("1", comparePageUrl)

        return null
    }

    if (!demoOptionModule?.active && hideBanner) {
        return null
    }

    const renderDemoAlert = () => {
        if (!mainModule) {
            return
        }

        const moduleTitle = getModuleTitle(mainModule, moduleId, moduleName, moduleGroupIdAux, translateText)
        const message = getMessage(
            moduleTitle,
            mainModule?.name,
            defaultFullOptionModule,
            defaultDemoOptionModule,
            activeFullModulePackage,
            activeDemoModulePackage,
            activatableFullModulePackage,
            activatableDemoModulePackage,
            translateText
        )

        return (
            <StyledDemoAlert>
                <Icon color="primary" height="1.5em" name="information-light" sx={{ marginRight: "0.5em" }} />

                <Box flexGrow="1">{message}</Box>

                <Box display="flex" flex="0">
                    <StyledDemoButton
                        disabled={activatingModule || (demoOptionModule?.activatable ? demoOptionModule?.active : true)}
                        onClick={() =>
                            activateRef.current?.handleActivateModule(moduleGroupIdAux, mainModule, demoOptionModule, activatableDemoModulePackage)
                        }
                    >
                        {translateText(12567)}
                        <Demo displayMode="edge" />
                    </StyledDemoButton>
                    <StyledDemoButton
                        color="highlight"
                        variant="contained"
                        disabled={activatingModule || (fullOptionModule?.activatable ? fullOptionModule?.active : true)}
                        onClick={() =>
                            activateRef.current?.handleActivateModule(moduleGroupIdAux, mainModule, fullOptionModule, activatableFullModulePackage)
                        }
                    >
                        {translateText(12568)}
                    </StyledDemoButton>
                    <StyledMoreInfoBtn
                        target="_blank"
                        disabled={!mainModule.longDescriptionUrl}
                        href={mainModule.longDescriptionUrl?.replace("{0}", languageId)}
                    >
                        {translateText(12597)}
                    </StyledMoreInfoBtn>
                    {mainModule.explanationVideoUrl && (
                        <StyledMoreInfoBtn href={mainModule.explanationVideoUrl} target="_blank" startIcon={<Icon name="video" />}>
                            {translateText(12841)}
                        </StyledMoreInfoBtn>
                    )}
                </Box>

                {!demoOptionModule?.active && (
                    <Button
                        sx={{ alignSelf: "center", color: "#202020" }}
                        variant="text"
                        startIcon={<Icon name="close" />}
                        onClick={handleHideBanner}
                    />
                )}
            </StyledDemoAlert>
        )
    }

    return (
        <>
            {renderDemoAlert()}
            <Badge />
            <ActivateModule ref={activateRef} />
        </>
    )
}

const StyledDemoAlert = styled(Box)(({ theme }) => ({
    width: "100%",
    flex: "0 0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    alignSelf: "flex-start",
    background: theme.colors?.light ?? "#fff",
    border: `1px solid ${theme.colors?.primary ?? ""}`,
    marginBottom: 0,
    padding: "0.25em 0.8em 0.25em 0.9em",
    gap: "0.5em",
}))

const StyledDemoButton = styled(Button)({
    margin: "0 .25rem",
    "& svg": {
        position: "absolute",
        top: "0",
        right: "0",
        opacity: 1,
        margin: 0,
    },
})

const StyledMoreInfoBtn = styled(Button)({
    margin: "0 .25rem",
    display: "flex",
    justifyContent: "center",
})

export default DemoBanner
