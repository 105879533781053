import { useEffect, useRef, useState } from "react"
import { Autocomplete, AutocompleteRenderInputParams, DefaultTextField as TextField, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ExtendEFilterNames } from "../../../../../data/state/tyres-wheels"

type Props = {
    items: string[]
    name: string
    path: string
    selectedValue?: string
    onChange: (path: string, filter: string) => void
    focusInput?: boolean
    isLoading?: boolean
    disableDropDown?: boolean
    inputMode?: "text" | "none" | "decimal" | "tel" | "search" | "url" | "numeric"
}

export function DropDown(props: Props) {
    const { items, name, path, onChange, selectedValue, focusInput, isLoading, disableDropDown, inputMode } = props
    const [openDropDown, setOpenDropDown] = useState(false)
    const { translateText } = useLocalization()
    const inputRef = useRef<HTMLInputElement | null>(null)

    useEffect(() => {
        if (focusInput && !isLoading && items.length !== 1) {
            if (path === ExtendEFilterNames.width) {
                const timer = setTimeout(() => {
                    setOpenDropDown(focusInput)
                    if (inputRef.current) {
                        inputRef.current.focus()
                    }
                }, 300)
                return () => clearTimeout(timer)
            }

            setOpenDropDown(focusInput)
            if (inputRef.current) {
                inputRef.current.focus()
            }
        }
    }, [focusInput, isLoading, inputRef])

    const handleChange = (event: React.SyntheticEvent, value: string | null) => {
        if (!value) {
            return
        }

        onChange(path, value)
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const filteredItems = items.filter((item) => item.includes(event.target.value.toUpperCase()))
        if (filteredItems.length === 1) {
            onChange(path, filteredItems[0])
        }
    }

    const renderContent = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                label={name}
                InputLabelProps={{ shrink: true }}
                onChange={handleInputChange}
                inputRef={inputRef}
                inputProps={{
                    // https://github.com/mui/material-ui/issues/28687
                    ...params.inputProps,
                    inputMode: inputMode || "text",
                }}
                sx={{
                    ".MuiInputLabel-root.MuiInputLabel-formControl": {
                        fontSize: "18px",
                        transform: "translate(10px, 9px) scale(1)",
                    },
                    ".MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-shrink": {
                        fontSize: "18px",
                        transform: "translate(10px, 4px) scale(.6)",
                        color: "text.primary",
                    },
                    ".MuiOutlinedInput-notchedOutline legend span": {
                        display: "none",
                    },
                    ".MuiOutlinedInput-root.MuiInputBase-root": {
                        paddingY: "0",
                        lineHeight: 1,
                    },
                    ".MuiButtonBase-root": {
                        opacity: 1,
                        marginTop: "4px",
                    },
                    ".MuiButtonBase-root svg": {
                        width: "18px",
                        height: "18px",
                        minWidth: "18px",
                        minHeight: "18px",
                        opacity: 1,
                    },
                    ".MuiOutlinedInput-root.MuiInputBase-root #combo-box-dropdown": {
                        paddingY: "8px",
                        position: "relative",
                        top: "8px",
                    },
                    ".MuiOutlinedInput-notchedOutline": {
                        borderWidth: "1px !important",
                        borderColor: "#0000003b !important",
                    },
                }}
            />
        )
    }

    return (
        <Autocomplete
            id="combo-box-dropdown"
            options={items}
            renderInput={renderContent}
            value={selectedValue || null}
            onChange={handleChange}
            autoHighlight
            autoComplete
            freeSolo={items.length === 0 || path === ExtendEFilterNames.manufacturer || path === ExtendEFilterNames.tyreModel}
            autoSelect={items.length === 0 || path === ExtendEFilterNames.manufacturer || path === ExtendEFilterNames.tyreModel}
            selectOnFocus={openDropDown}
            open={openDropDown}
            onOpen={() => setOpenDropDown(true)}
            onClose={() => setOpenDropDown(false)}
            clearIcon={<Icon name="close" onClick={() => setOpenDropDown(true)} />}
            popupIcon={<Icon name="down" />}
            sx={{
                width: "100%",
            }}
            noOptionsText={translateText(325)}
            disabled={disableDropDown}
        />
    )
}
