import { Box, Button, styled } from "@tm/components"
import { VinField } from "@tm/controls"
import { useCallback, useMemo, useState } from "react"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { color } from "csx"
import { useGetVins } from "../../../helpers/hooks/useGetVins"
import { maintenancePlanActions, useFastServiceStore } from "../../../data"
import { initMaintenancePlan } from "../../maintenance-plan/business"
import { getLabourRate } from "../../../helpers/userSettingsContextMethods"

const VIN_LENGTH = 17
const VIN_LENGTH_SMALL = 7
const BMW_TECDOC_ID = 16

type Props = {
    buttonTextId: string
    historyCalcId: string
}

export default function VehicleVinField(props: Props) {
    const { buttonTextId, historyCalcId } = props
    const { translateText, languageId } = useLocalization()
    const userSettings = useUser()?.userSettings
    const vehicle = useFastServiceStore((store) => store.maintenancePlan.stateVehicle)
    const vehicleVin = useFastServiceStore((store) => store.maintenancePlan.vehicleFields.vin)
    const [vinFromHistory, setVinFromHistory] = useState<string>(vehicleVin ?? "")
    const vehicleVinSuggestions: string[] = useGetVins("", vehicle?.tecDocTypeId) ?? []
    const { attachToWorkTask, workTask } = useWorkTask() || {}

    const isVinValid = useMemo(() => {
        if (
            vinFromHistory?.length === VIN_LENGTH ||
            (vehicle?.tecDocManufacturerId === BMW_TECDOC_ID && vinFromHistory?.length === VIN_LENGTH_SMALL)
        ) {
            return true
        }

        return false
    }, [vinFromHistory])

    const handleGetVinSuggestions = useCallback(
        (query: string): Array<string> => {
            setVinFromHistory(query)
            return vehicleVinSuggestions?.length && query?.length
                ? vehicleVinSuggestions?.filter((vin) => vin.startsWith(query))
                : vehicleVinSuggestions
        },
        [vehicleVin, vehicleVinSuggestions]
    )

    function handleVinChange(vinValue: string) {
        setVinFromHistory(vinValue)
    }

    const handleSubmitVin = () => {
        if (isVinValid && vinFromHistory !== workTask?.vehicle?.vin) {
            vehicle && attachToWorkTask?.({ vehicle: { ...vehicle, vin: vinFromHistory } })
        }
        maintenancePlanActions.updateVehicleField("vin", vinFromHistory)
    }

    const handleInitMaintenancePlan = (calcId: string) => {
        handleSubmitVin()
        initMaintenancePlan(languageId, getLabourRate(userSettings), calcId)
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
            <StyledVinField className={`fieldSeparator ${isVinValid ? "borderredSuccess" : "borderredWarning"} col`}>
                <VinField
                    size="xl"
                    floatingLabel
                    label={translateText(101)}
                    value={vinFromHistory}
                    getSuggestionData={handleGetVinSuggestions}
                    path={["vin"]}
                    onChangeConfirm={handleVinChange}
                    onChange={handleVinChange}
                />
            </StyledVinField>
            <StyledButton disabled={!isVinValid} onClick={() => handleInitMaintenancePlan(historyCalcId)}>
                {translateText(buttonTextId)}
            </StyledButton>
        </Box>
    )
}

const StyledVinField = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    borderLeftWidth: "0.25em",
    borderLeftStyle: "solid",
    "&.fieldSeparator": {
        minWidth: "fit-content",
        marginRight: "0.5em",
        "& .suggest__box--xl": {
            fontSize: "1.25rem",
        },
    },

    "&.borderredWarning": {
        borderLeftColor: `${theme.colors && color(theme.colors.warning).mix(theme.colors.danger)}`,
    },
    "&.borderredSuccess": {
        borderLeftColor: `${theme.colors?.success}`,
    },

    "&.col": {
        margin: `${theme.margin?.s}`,
        "& .suggest": {
            flex: 1,
            justifyContent: "center",
            $nest: {
                "&.scrollbar__view": {
                    top: "20px",
                },
            },
        },
        "& .input": {
            flex: 1,
        },
    },
}))

const StyledButton = styled(Button)({
    height: "10%",
    marginLeft: "1em",
})
