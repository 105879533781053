import { ConfigParams } from "@tm/models"
import { useEffect, useMemo, useState } from "react"
import { UrlTagsProps, replaceUrlTags, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { existsTokenInCache, getExternalToken } from "../data/repositories/portalAuthority"

export function useReplaceExternalCatalogUrl(urlTagsProps: UrlTagsProps, moduleReplacedUrl?: string, requiresExternalToken?: boolean) {
    const { telesalesCustomerNo, enableServiceCalls, hasTelesales } = useTelesalesCustomerNumber()
    const [tokenLoading, setTokenLoading] = useState(false)
    const [tokenError, setTokenError] = useState(false)
    const workTaskContainer = useWorkTask()
    const userContainer = useUser()

    const [replacedUrl, tokenKey] = useMemo(() => {
        if (!requiresExternalToken || !moduleReplacedUrl) {
            return [moduleReplacedUrl]
        }
        const tokenMatch = /{#TOKEN:(.*?)}/.exec(moduleReplacedUrl || "")
        if (tokenMatch) {
            const tokenType = Morpheus.getParams<ConfigParams>().tokenHandler?.[tokenMatch[1]] || tokenMatch[1]
            if (hasTelesales) {
                if (!enableServiceCalls) {
                    return []
                }
                return [moduleReplacedUrl.replace(tokenMatch[0], `{#TOKEN:${tokenType}:${telesalesCustomerNo}}`), tokenType]
            }
            return [moduleReplacedUrl.replace(tokenMatch[0], `{#TOKEN:${tokenType}}`), tokenType]
        }
        return [moduleReplacedUrl]
    }, [requiresExternalToken, moduleReplacedUrl, hasTelesales, enableServiceCalls, telesalesCustomerNo])

    const tokenIsCached = useMemo(() => {
        return tokenKey && enableServiceCalls && !tokenLoading && existsTokenInCache(tokenKey, undefined, telesalesCustomerNo)
    }, [telesalesCustomerNo, tokenKey, tokenLoading, enableServiceCalls])

    const url = useMemo(() => {
        if (!replacedUrl || (tokenKey && (!tokenIsCached || tokenLoading || tokenError))) {
            return
        }
        return replaceUrlTags(replacedUrl, urlTagsProps, userContainer, workTaskContainer)
    }, [replacedUrl, tokenKey, tokenIsCached, tokenLoading, tokenError, userContainer, workTaskContainer, urlTagsProps])

    useEffect(() => {
        if (!tokenKey || !enableServiceCalls || tokenIsCached || tokenLoading || tokenError) {
            return
        }
        setTokenLoading(true)
        getExternalToken(tokenKey, { id: telesalesCustomerNo, telesalesCustomerNo })
            .catch(() => setTokenError(true))
            .finally(() => setTokenLoading(false))
    }, [tokenKey, tokenIsCached, telesalesCustomerNo, tokenLoading, tokenError, enableServiceCalls])

    return { url }
}
