import { percent } from "csx"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Loader, Scrollbar } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { bem, initSelector } from "@tm/utils"
import { Plate, Section } from "./_shared"
import { Actions } from "./business"
import { DOPState } from "./business/model"
import Articles from "./components/articles"
import Navigation from "./components/navigation"

const selector = initSelector(
    (s: DOPState) => s.model,
    (s) => s.loading
)

export default function Main() {
    const [model, loading] = useSelector(selector)
    const actions = useActions(Actions)
    const style = useMemo(() => getStyle(), [])

    useEffect(() => {
        actions.loadData()
    }, [])

    return (
        <div className={style.wrapper}>
            <Navigation />
            {/* <Plate /> */}
            <Scrollbar>
                <div className={bem(style.cover, loading && "loading")}>
                    {model.formState?.sections?.map((sec, idx) => (
                        <Section key={sec.name} aditionalContent={model.plate && !idx && <Plate plate={model.plate} />} section={sec} />
                    ))}
                </div>
                <Articles />
                {!model.formState?.sections && (
                    <div className="article-list__panel article-list__status">
                        <Loader />
                    </div>
                )}
            </Scrollbar>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            flexBasis: percent(100),
            alignItems: "stretch",
            display: "flex",
            flexDirection: "column",
            padding: theme.margin.m,
        },
        cover: {
            display: "flex",
            flexDirection: "column",
            $nest: {
                "&--loading": {
                    pointerEvents: "none",
                    opacity: theme.opacity.secondary,
                },
            },
        },
    })(Main)
}
