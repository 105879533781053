import { Image } from "@tm/controls"
import { concat } from "@tm/utils"
import { MouseEvent, useEffect, useMemo, useRef, useState } from "react"

import { Models } from "../../../data"
import { PartsListImageCoordinate } from "../../../data/model"

type Props = {
    image: Models.PartsListImage
    imageCoordinates?: Array<Models.PartsListImageCoordinate>
    selectedArticles: { internalId: number; imageCoordinates?: string }[]
    onArticleSelect?(coordinate: PartsListImageCoordinate): void
    onArticleHover?(coordinate?: PartsListImageCoordinate): void
}

export default function SensitiveGraphics(props: Props) {
    const imageRef = useRef<HTMLImageElement | null>(null)
    const [imgRatio, setImgRatio] = useState(1)

    const imgObserver = useMemo(
        () =>
            new ResizeObserver((entries) => {
                const img = entries[0].target as HTMLImageElement
                setImgRatio(img.clientWidth / img.naturalWidth)
            }),
        []
    )

    useEffect(() => {
        const img = imageRef.current
        img && imgObserver.observe(img)
        return () => {
            img && imgObserver.unobserve(img)
        }
    }, [imageRef, imgObserver])

    function handleAreaMouseEnter(coordinates: Array<Models.PartsListImageCoordinate>) {
        if (coordinates?.[0]) {
            props.onArticleHover?.(coordinates[0])
        }
    }

    function handleAreaMouseLeave() {
        props.onArticleHover?.(undefined)
    }

    function renderCoordinateGroups(imageCoordinates: Array<Models.PartsListImageCoordinate>) {
        const coordinateGroups = imageCoordinates.groupBy((x) => x.referencedArticle)

        return Object.keys(coordinateGroups).map((key, idx) => {
            const coordinates = coordinateGroups[key]

            if (!coordinates) {
                return
            }

            return (
                <g
                    key={idx}
                    className="sensitive-graphics__coordinate-group"
                    onMouseEnter={() => handleAreaMouseEnter(coordinates)}
                    onMouseLeave={() => handleAreaMouseLeave()}
                >
                    {coordinates.map(renderCoordinate)}
                </g>
            )
        })
    }

    function renderCoordinate(coordinate: Models.PartsListImageCoordinate, idx: number) {
        if (!coordinate?.referencedArticle) {
            return
        }

        const className = concat(
            " ",
            "sensitive-graphics__coordinate",
            props.selectedArticles.some(
                (selected) => selected.internalId === coordinate.referencedArticle && selected.imageCoordinates?.includes(`,${coordinate.id};`)
            ) && "sensitive-graphics__coordinate--selected"
        )

        function onClick(e: MouseEvent) {
            e.preventDefault()
            e.stopPropagation()
            props.onArticleSelect?.(coordinate)
        }

        if (coordinate.type == Models.PartsListImageCoordinateType.Rectangle && coordinate.y2 != undefined) {
            return (
                <rect
                    key={idx}
                    className={className}
                    x={coordinate.x1}
                    y={coordinate.y1}
                    width={coordinate.x2 - coordinate.x1}
                    height={coordinate.y2 - coordinate.y1}
                    onClick={onClick}
                />
            )
        }

        return <circle key={idx} className={className} cx={coordinate.x1} cy={coordinate.y1} r={coordinate.x2} onClick={onClick} />
    }

    if (!props.image) {
        return null
    }

    return (
        <div className="sensitive-graphics">
            <Image ref={imageRef} className="sensitive-graphics__image" url={props.image.imageUrl} />
            {props.imageCoordinates && (
                <svg className="sensitive-graphics__coordinates" style={{ zoom: imgRatio }}>
                    {renderCoordinateGroups(props.imageCoordinates)}
                </svg>
            )}
        </div>
    )
}
