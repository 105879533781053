import { Channel, MessageBus } from "@tm/hermes"
import { Customer } from "@tm/models"

export type BundleActionType =
    | { type: "CUSTOMER_DETAILS"; payload?: Customer }
    | { type: "CUSTOMER_DELETED"; payload: Customer }
    | { type: "VIEW"; payload: { view: "DETAILS" | "LIST"; id?: string } }

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel("BUNDLE")
}

export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = { APPLY_CHANGES: { vehicle?: string } }
