import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { TesterSlice, TesterState } from "./model"

function getInitialState(): TesterState {
    return {
        tester: {
            firstName: "",
            lastName: "",
            comment: "",
        },
    }
}

export const createTesterSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TesterSlice> = (set) => ({
    ...getInitialState(),
    testerActions: {
        setSignature: (signature: string) => set((state) => ({ tester: { ...state.tester, signature } }), false, "Set tester signature"),
        setFirstName: (firstName: string) =>
            set((state) => ({ tester: { ...state.tester, firstName } }), false, `Set tester firstName: ${firstName}`),
        setLastName: (lastName: string) => set((state) => ({ tester: { ...state.tester, lastName } }), false, `Set tester lastName: ${lastName}`),
        setComment: (comment: string) => set((state) => ({ tester: { ...state.tester, comment } }), false, `Set tester comment: ${comment}`),
        reset: () => set(getInitialState(), false, "Reset Tester Slice"),
    },
})
