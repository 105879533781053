import { PartInfo, ProductGroup, ArticleInfoType, OrderItem, CustomPart, VatRate, CustomArticleItem } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { ShowCentralOrderResponse, WorkTaskOrderGroups, OrderItemGroup, PartItem, ChangedIsIncludedPartItem, ChangedQuantityPartItem } from "../model"

function mapOeArticleProductGroup(data: any): ProductGroup | undefined {
    return {
        ...data,
        id: data || undefined,
        name: "-",
    }
}

function mapArticleInfo(data: any, articleInfoType: ArticleInfoType): PartInfo | undefined {
    if (!data) {
        return
    }

    let dataSupplier
    let dataSupplierArticleNumber
    let productGroup
    switch (articleInfoType) {
        case ArticleInfoType.Undefined:
            break
        case ArticleInfoType.CustomArticle:
            dataSupplierArticleNumber = data.articleNumber
            break
        case ArticleInfoType.TecdocArticle:
            dataSupplier = data.dataSupplier
            dataSupplierArticleNumber = data.dataSupplierArticleNumber
            productGroup = data.productGroup
            break
        case ArticleInfoType.OeArticle:
            dataSupplier = data.vehicleManufacturer
            dataSupplierArticleNumber = data.oeArticleNumber
            productGroup = mapOeArticleProductGroup(data.productGroupId)
            break
        default:
            break
    }
    return {
        ...data,
        dataSupplier,
        dataSupplierArticleNumber,
        productGroup,
    }
}

function mapOrderItem(data: any): OrderItem | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        articleInfo: mapArticleInfo(data.articleInfo, data.articleInfoType),
    }
}

function mapOrderItemGroup(data: any): OrderItemGroup | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderItems: (data.orderItems || []).map(mapOrderItem),
    }
}

function mapWorkTaskOrderGroups(data: any): WorkTaskOrderGroups | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderGroups: (data.orderGroups || []).map(mapOrderItemGroup),
    }
}

export function mapShowCentralOrder(data: any): ShowCentralOrderResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        workTaskOrderGroups: (data.workTaskOrderGroups || []).map(mapWorkTaskOrderGroups),
    }
}

export function createCustomPartObject(
    articleNumber: string | undefined,
    retailPrice: number | undefined,
    purchasePrice: number | undefined,
    description: string,
    quantityValue: number,
    rebate: number | undefined,
    surcharge: number | undefined,
    vatRate: undefined | VatRate,
    memo?: string
): CustomPart {
    return {
        articleNumber,
        retailPrice,
        purchasePrice,
        description,
        quantityValue,
        rebate,
        surcharge,
        vatRate,
        memo,
    }
}

export function mapCustomArticleItemToCustomPart(article: CustomArticleItem, quantity: number, memo: string | undefined) {
    return createCustomPartObject(
        article.articleNumber,
        article.retailPrice,
        article.purchasePrice,
        article.description,
        quantity,
        0,
        0,
        article.vatRate,
        memo
    )
}

export function mapChangeIsIncludedResponse(parts?: PartItem[], changedIsIncludedParts?: ChangedIsIncludedPartItem[]): PartItem[] | undefined {
    return parts?.map((part) => {
        const item = changedIsIncludedParts?.find((x) => x.id === part.id)
        if (item && part.costEstimationItem) {
            return {
                ...part,
                version: item.version,
                mainPartId: item.mainPartId,
                costEstimationItem: {
                    ...part.costEstimationItem,
                    isIncluded: item.isIncluded,
                },
                linkedItems: mapChangeIsIncludedResponse(part.linkedItems, item.linkedItems),
            }
        }
        return part
    })
}

export function mapChangeQuantityResponse(part: PartItem, changedQuantityPart: ChangedQuantityPartItem): PartItem | undefined {
    const item = changedQuantityPart.id === part.id ? changedQuantityPart : undefined
    if (item) {
        return {
            ...part,
            version: item.version,
            quantity: item.quantity,
            mainPartId: item.mainPartId,
            linkedItems: part.linkedItems
                ?.map((linkedItem) => {
                    let mappedLinkedItem: PartItem | undefined
                    item.linkedItems
                        ?.map((link) => {
                            if (link.id === linkedItem.id) {
                                mappedLinkedItem = mapChangeQuantityResponse(linkedItem, link)
                            }
                        })
                        .filter(notUndefinedOrNull)
                    return mappedLinkedItem
                })
                .filter(notUndefinedOrNull),
        }
    }
    return part
}
