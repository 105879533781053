import { useLocalization } from "@tm/localization"

export function useWhatsAppShare(onShareCompleted?: (success: boolean) => void) {
    const { language } = useLocalization()

    const send = (message: string, recipient?: string) => {
        if (!message) {
            onShareCompleted?.(false)
            return
        }

        const url = `https://api.whatsapp.com/send?${recipient ? `phone=${recipient}&` : ""}text=${encodeURIComponent(message)}&lang=${language}`

        window.open(url, "_blank", "noreferrer")
        onShareCompleted?.(true)
    }

    return {
        sendWhatsAppMessage: send,
    }
}
