import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetTheme(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { theme } = this.props

    if (data.getTheme) {
        if (source) {
            const { cms } = (window as any).__MORPHEUS_CONTEXT__.config.params
            source.postMessage({ theme, cms }, "*")
        }
    }
}
