import { MouseEvent, SyntheticEvent, useCallback, useMemo, useState } from "react"
import { Alert, Box, BoxProps, Button, ButtonProps, Icon, Loader, Snackbar, styled, Tooltip, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { VinField, VinFieldProps as TextFieldProps } from "@tm/controls"
import { useAvailableVehicleSearchOptions } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType, Vehicle } from "@tm/models"
import { searchVehiclesByRegNo } from "../../business"

type VehicleData = Pick<Vehicle, "plateId" | "vin" | "tecDocManufacturerId">

type Props = {
    vehicleData: VehicleData
    showEdit?: boolean
    autoAttach?: boolean
    textFieldProps?: TextFieldProps
    buttonWrapperProps?: BoxProps
    onVinChange?(vin: string, isValid?: boolean): void
    onVinChangeConfirm?(vin: string, isValid?: boolean): void
    getVinSuggestions?(query: string): Promise<Array<string>>
}

const StyledVinField = styled(VinField)({
    width: "100%",
})

// TODO: NEXT-22792 We should discuss how we handle small icons in a button and maybe move this to @tm/components
const MediumButtonWithLargeIcon = styled((props: ButtonProps) => <Button {...props} size="medium" />)({
    "&.MuiButton-sizeMedium": {
        padding: "1px",
        ".MuiButton-startIcon > svg": {
            width: "28px",
            height: "28px",
        },
    },
})

const PLACEHOLDER = "________________________"

type InfoMessage = {
    text: string
    severity?: "success" | "info" | "warning" | "error"
}

export const VinInputField = ({
    vehicleData,
    showEdit,
    autoAttach,
    textFieldProps,
    buttonWrapperProps,
    onVinChange,
    onVinChangeConfirm,
    getVinSuggestions,
}: Props) => {
    const { translateText } = useLocalization()
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()

    const [vinLoading, setVinLoading] = useState(false)
    const [infoMessage, setInfoMessage] = useState<InfoMessage>()

    const canRequestVin = useMemo(
        () =>
            !!availableVehicleSearches?.configuredVehicleLookups.find(
                (lookup) => lookup.lookupTypeId === RegistrationNoType.VrmLicensePlateNetherlandRDC
            ),
        [availableVehicleSearches]
    )

    const requestVinButtonTooltip = useMemo(() => {
        if (!vinLoading) {
            return vehicleData.plateId ? translateText(13303) : translateText(13361)
        }
    }, [vinLoading, vehicleData.plateId, translateText])

    const searchForVinByPlateId = useCallback(async () => {
        if (!vehicleData.plateId || !canRequestVin) {
            return
        }

        setVinLoading(true)

        try {
            const response = await searchVehiclesByRegNo(vehicleData.plateId, RegistrationNoType.VrmLicensePlateNetherlandRDC, true)

            if (response?.models.length) {
                const { vin } = response.models[0].registrationNoDetails ?? {}

                if (vin) {
                    setInfoMessage({ text: `${translateText(13360)} ${vin}`, severity: "success" })

                    onVinChangeConfirm?.(vin, true)

                    if (workTask?.vehicle && autoAttach) {
                        await attachToWorkTask?.({ vehicle: { ...workTask.vehicle, vin } })
                    }
                }
            } else {
                setInfoMessage({ text: translateText(13359), severity: "info" })
            }
        } catch {
            setInfoMessage({ text: translateText(787), severity: "error" })
        } finally {
            setVinLoading(false)
        }
    }, [attachToWorkTask, autoAttach, canRequestVin, onVinChangeConfirm, translateText, vehicleData, workTask?.vehicle])

    const handleVinChange = useCallback(
        (vin: string, _: unknown, isValid: boolean) => {
            onVinChange?.(vin, isValid)
        },
        [onVinChange]
    )

    const handleVinChangeConfirm = useCallback(
        (vin: string, _: unknown, isValid: boolean) => {
            onVinChangeConfirm?.(vin, isValid)
        },
        [onVinChangeConfirm]
    )

    const handleRequestVinButtonClick = useCallback(
        (event: MouseEvent) => {
            event.stopPropagation()
            searchForVinByPlateId()
        },
        [searchForVinByPlateId]
    )

    const handleSnackbarClick = useCallback((event: SyntheticEvent<unknown>) => {
        event.stopPropagation()
    }, [])

    const handleSnackbarClose = useCallback((_: unknown, reason?: string) => {
        // event.stopPropagation()

        if (reason === "clickaway") {
            return
        }

        setInfoMessage(undefined)
    }, [])

    return (
        <>
            <Box display="flex" flexDirection="row" alignItems="center">
                {showEdit ? (
                    <StyledVinField
                        {...textFieldProps}
                        className="vin-field"
                        coloredBorder
                        value={vehicleData.vin}
                        getSuggestionDataAsync={getVinSuggestions}
                        onChange={handleVinChange}
                        onChangeConfirm={handleVinChangeConfirm}
                        tecDocManufacturerId={vehicleData.tecDocManufacturerId}
                    />
                ) : (
                    <Typography variant="body3" mt="2px">
                        {vehicleData.vin || PLACEHOLDER}
                    </Typography>
                )}

                {!vehicleData.vin && canRequestVin && (
                    <Tooltip title={requestVinButtonTooltip ?? ""}>
                        <Box {...buttonWrapperProps} ml={0.5}>
                            {vinLoading ? (
                                <Loader size="small" />
                            ) : (
                                <MediumButtonWithLargeIcon
                                    startIcon={<Icon name="vin-counter" />}
                                    disabled={!vehicleData.plateId}
                                    onClick={handleRequestVinButtonClick}
                                />
                            )}
                        </Box>
                    </Tooltip>
                )}
            </Box>

            <Snackbar
                open={!!infoMessage}
                sx={{ paddingBottom: "30px" }}
                onClick={handleSnackbarClick}
                onClose={handleSnackbarClose}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert severity={infoMessage?.severity}>{infoMessage?.text}</Alert>
            </Snackbar>
        </>
    )
}
