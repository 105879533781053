import { FC } from "react"
import { Loader, Dropdown, Text, Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ArticleAttribute, OrderItem, RepairTimeProvider, RequestArticleDetailsPayload, SearchFilters } from "@tm/models"
import { decodeUniqueId, getRepairTimeProviders } from "@tm/utils"
import { match as reactRouterMatch } from "react-router-dom"
import { SearchType } from "../../../business"
import { IActions, ListState } from "../business"

import OrderItemComponent from "./OrderItem"
import { getSearchTypeDependentArticleTableProps, getTmaInfos } from "./utils"
import { RouteParams } from "../wrapper"
import { ArticleTable } from "./article-table"

type Props = {
    state: ListState
    actions: IActions
    partToReplaceId?: string
    partToReplaceData?: { orderItem: OrderItem; article?: Article }
    showAdditionalPrices?: boolean
    showDocumentsInline?: boolean
    showDocumentsInCompact?: boolean
    showReferenceLinksInCompact?: boolean
    openDocumentsAsModal?: boolean
    showErpPawnItems?: boolean
    onShowArticleFeedback?(article: Article): void
    onArticleAddToBasket?(article: Article): void
    getRepairTimesUrl?(article: Article, rtProviders: Array<RepairTimeProvider> | RepairTimeProvider): string | undefined
    onArticleAttributeSelect: (article: Article, attribute: ArticleAttribute) => void
    onRequestArticleDetails: (request: RequestArticleDetailsPayload) => void
    onRequestArticleDirectSearch?(query: string, searchFilter?: SearchFilters): void
    isCentralOrder: () => boolean
    match: reactRouterMatch<RouteParams>
    articleAlternativesRoute?: string
    handleRequestArticleAlternatives: (article: Article) => void
    handlePartToReplaceDropdownChange: (item: { value: "uni" | "direct"; text: string }) => void
}

export const PartToReplace: FC<Props> = (props) => {
    const {
        state,
        partToReplaceId,
        partToReplaceData,
        actions,
        isCentralOrder,
        match,
        articleAlternativesRoute,
        handleRequestArticleAlternatives,
        handlePartToReplaceDropdownChange,
    } = props
    const { translateText } = useLocalization()
    const { vehicleId, customerId, foundBySearchTerm } = getTmaInfos(state.usedFilters, state.searchType, state.customer)
    const { canFilterArticleAttributes, ignoreAttributeKey, selectedArticleAttributes, showMissingVehicleConnection, hasInlineDataSupplierFilter } =
        getSearchTypeDependentArticleTableProps(vehicleId, state.usedFilters, state.searchType)

    const { repairTimeProviders } = getRepairTimeProviders()

    if (partToReplaceId) {
        let content
        if (partToReplaceData) {
            if (partToReplaceData.article) {
                content = (
                    <ArticleTable
                        articles={[partToReplaceData.article]}
                        searchType={state.searchType}
                        selectedArticleIds={state.result.selected}
                        selectedArticleAttributes={selectedArticleAttributes}
                        ignoreAttributeKey={ignoreAttributeKey}
                        onArticleSelect={actions.selectPart}
                        onRequestArticleAlternatives={articleAlternativesRoute ? handleRequestArticleAlternatives : undefined}
                        compactView={!!state.compactView}
                        showArticleImages={!!state.showArticleImages}
                        canFilterArticleAttributes={canFilterArticleAttributes}
                        productGroupAsDescription
                        vehicleEngineCode={state.usedFilters.vehicle ? state.usedFilters.vehicle.engineCode : undefined}
                        workTaskId={match.params.workTaskId && decodeUniqueId(match.params.workTaskId)}
                        vehicleId={vehicleId}
                        customerId={customerId}
                        foundBySearchTerm={foundBySearchTerm}
                        showMissingVehicleConnection={showMissingVehicleConnection}
                        repairTimeAvailabilities={state.repairTimeAvailabilities}
                        hitInfos={state.result.hitInfo}
                        getArticleSearchHitInfo={actions.getArticleSearchHitInfo}
                        showActions={false}
                        previouslyOrderedArticles={state.result.previouslyOrderedArticles}
                        vehicleRecordsComparisons={state.result.vehicleRecordsComparisons}
                        activeDataSupplierFilter={state.usedFilters.supplierIds}
                        onDataSupplierFilterToggle={hasInlineDataSupplierFilter ? actions.toggleDataSupplierFilter : undefined}
                        hideCostEstimationButton={!!state.fastCalculator || isCentralOrder() || !repairTimeProviders.length}
                        advertisementCategoryId={state.usedFilters.offers?.advertisementCategory.id}
                        {...props}
                    />
                )
            } else {
                content = <OrderItemComponent article={partToReplaceData.orderItem} showArticleImage={!!state.showArticleImages} />
            }
        } else {
            content = (
                <div className="article-list__panel article-list__status">
                    <Loader />
                </div>
            )
        }

        const changeItems: Array<{ value: "uni" | "direct"; text: string }> = [{ value: "direct", text: translateText(840) }]

        let currentChangeItem = changeItems[0]

        if (partToReplaceData?.orderItem?.articleInfo?.description) {
            changeItems.unshift({ value: "uni", text: translateText(1009) })

            if (state.searchType !== SearchType.UNISEARCH) {
                currentChangeItem = changeItems[1]
            }
        }

        return (
            <div className="part-replacement">
                {state.searchType !== SearchType.ALTERNATIVES && changeItems.length > 1 && (
                    <div className="part-replacement__actions">
                        <div className="part-replacement__actions__change">
                            <Text>{translateText(1508)}</Text>
                            <Dropdown
                                value={currentChangeItem}
                                items={changeItems}
                                onChange={handlePartToReplaceDropdownChange}
                                itemView={(item) => <Text>{item.text}</Text>}
                            />
                        </div>
                    </div>
                )}
                <div className="part-replacement__header">
                    <div className="part-replacement__header__left">
                        <Icon name="info" size="xl" />
                        <div>
                            <Text modifiers="block">{translateText(1505)}</Text>
                        </div>
                    </div>
                </div>
                {content}
            </div>
        )
    }
    return null
}
