import { Box, Typography, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { formatDate, getCurrencyFromUserContext } from "@tm/utils"
import { ReactNode } from "react"
import { mapBookingStatus } from "../../../utils"
import { useDrivemotiveStore } from "../../../data/state"
import NoResultHint from "../../_shared/NoResult"

export default function DetailsHeader() {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const selectedBooking = useDrivemotiveStore((state) => state.summary.selectedBooking)

    if (!selectedBooking) {
        return <NoResultHint />
    }

    const renderInfo = (label: string, value: string | ReactNode | undefined) => {
        return (
            <StyledInfo>
                <StyledLabel size="xs" modifiers={["block", "sub"]}>
                    {label}
                </StyledLabel>
                {!value || typeof value == "string" ? <StyledValue>{value || "-"}</StyledValue> : value}
            </StyledInfo>
        )
    }

    return (
        <Box>
            <Typography variant="h2">
                {translateText(13861)} {formatDate(new Date(selectedBooking?.preferred_time || ""), "dd.MM.yyyy HH:mm")}
            </Typography>
            <StyledRowInfo>
                {renderInfo(translateText(1759), mapBookingStatus(selectedBooking.status, translateText)?.label?.toUpperCase())}
                {renderInfo(
                    translateText(13860),
                    selectedBooking.date_created && formatDate(new Date(selectedBooking.date_created), "dd.MM.yyyy HH:mm")
                )}
                {renderInfo(
                    translateText(13697),
                    selectedBooking.price_range_from && `${currency(selectedBooking.price_range_from, currencyString)}`
                )}
            </StyledRowInfo>
            <StyledRowInfo>
                {renderInfo(translateText(155), `${selectedBooking.first_name} ${selectedBooking.last_name}`)}
                {renderInfo(translateText(111), "-")} {/* Adress */}
                {renderInfo(`${translateText(112)}, ${translateText(113)}`, "-")} {/* General Adress */}
            </StyledRowInfo>
            <StyledRowInfo>
                {renderInfo(
                    translateText(13704),
                    selectedBooking.vehicle?.vehicle_type &&
                        `${selectedBooking.vehicle?.vehicle_type?.manufacturer} ${selectedBooking.vehicle?.vehicle_type?.model} ${selectedBooking.vehicle?.vehicle_type?.type}`
                )}
                {renderInfo(
                    translateText(124),
                    selectedBooking.vehicle?.first_registration && formatDate(new Date(selectedBooking.vehicle?.first_registration), "dd.MM.yyyy")
                )}
                {renderInfo(translateText(125), "-")} {/* Odometer reading */}
                {renderInfo(translateText(101), selectedBooking.vehicle?.vin)}
                {renderInfo(translateText(13698), selectedBooking.message)} {/* Customer message */}
            </StyledRowInfo>
        </Box>
    )
}

const StyledInfo = styled(Box)(({ theme }) => ({
    display: "inline-block",
    verticalAlign: "top",
    maxWidth: "15em",
    marginLeft: `calc(${theme.margin?.xl} * 3)`,
    marginTop: theme.margin?.l,
}))

const StyledLabel = styled(Typography)(({ theme }) => ({
    marginBottom: theme.margin?.s,
    textTransform: "uppercase",
    opacity: "0.54",
}))

const StyledValue = styled(Typography)({ opacity: 0.87 })

const StyledRowInfo = styled(Box)(({ theme }) => ({
    marginLeft: `calc(-${theme.margin?.xl} * 3)`,
}))
