import { Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { Button } from "@tm/controls"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["details-erp-info-graduated"]

function DetailsErpInfoGraduatedComponent({ data, foundBySearchTerm, onClick }: Props) {
    const { translateText } = useLocalization()
    const { loading, erpInfo } = useErpInfo(data, "details", undefined, undefined, foundBySearchTerm)

    if (loading || !erpInfo?.hasGraduatedPurchasePrice) {
        return null
    }

    return (
        <Button layout={["ghost"]} onClick={onClick} icon="staffelpreis">
            {translateText(705)}
        </Button>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="details-erp-info-graduated">
                    <Suspense fallback={null}>
                        <DetailsErpInfoGraduatedComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
