import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import Widget from "./component"

const WidgetBundle: BundleComponent<unknown, typeof Widget> = {
    name: "widget",
    reduce,
    component: Widget,
}

export { WidgetBundle, Widget }
