import { channel } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { BundleActions, BundleActionTypes } from "../../../business"
import { useContainer } from "../../../data/repositories"
import { MainState } from "../../main"
import { MainActionsType } from "../../main/business"
import { SettingsDatState } from "./model"

export * from "./model"

export type ComponentActionType = BundleActionTypes | { type: "SETTINGS_URL_LOADED"; payload?: string }

export const DEFAULT_STATE: SettingsDatState = {}

export function reduce(state = { ...DEFAULT_STATE }, action: ComponentActionType): SettingsDatState {
    switch (action.type) {
        case "SETTINGS_URL_LOADED": {
            return {
                ...state,
                externalUrl: action.payload,
            }
        }
        default: {
            return state
        }
    }
}

const getSettingsUrl =
    (translateText?: (key: string | number) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        if (!translateText) {
            return
        }

        useContainer()
            .action("prepareSettings")()
            .then((res) => {
                if (res.errorMessage) {
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(res.errorMessage), closeDelay: 3000, skin: "warning" })
                }

                dispatch({ type: "SETTINGS_URL_LOADED", payload: res.glassHtml })
            })
    }

const reloadIframe = (): AsyncAction<MainActionsType, MainState> => (dispatch, getState) => {
    const url = getState().settings.externalUrl
    dispatch({ type: "SETTINGS_URL_LOADED", payload: undefined })
    setTimeout(() => {
        dispatch({ type: "SETTINGS_URL_LOADED", payload: url })
    }, 10)
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    getSettingsUrl,
    reloadIframe,
    // updateContractId
}
