import { BundleComponent } from "@tm/morpheus"
import { Manager } from "./component"
import { withStoreProvider } from "../../data/helpers"

const ManagerBundle: BundleComponent = {
    name: "manager",
    component: withStoreProvider(Manager as any) as any,
}

export { ManagerBundle, Manager }
