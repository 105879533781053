import { ajax, getStoredAuthorization } from "@tm/utils"
import {
    DeleteCustomRepairTimesRequest,
    ShowCustomRepairTimeItemsRequest,
    ShowCustomRepairTimeItemsResponse,
    AddCustomRepairTimeItemsRequest,
    AddCustomRepairTimeItemsResponse,
    EditCustomRepairTimeItemRequest,
    EditCustomRepairTimeItemResponse,
} from "../../model/custom-items"
import { getBundleParams } from "../../../utils"

function getServiceUrl() {
    return getBundleParams().customRepairTimesServiceUrl
}

export function showCustomRepairTimeItems(body: ShowCustomRepairTimeItemsRequest) {
    const url = `${getServiceUrl()}/ShowCustomRepairTimeItems`
    const authorization = getStoredAuthorization()
    return ajax<ShowCustomRepairTimeItemsResponse>({ url, body, authorization }).then((response) => ({
        data: response?.customRepairTimeItems ?? [],
        pageIndex: body.pageIndex,
        endOfList: (response?.customRepairTimeItems?.length ?? 0) < body.pageSize,
    }))
}

export function addCustomRepairTimeItems(body: AddCustomRepairTimeItemsRequest) {
    const url = `${getServiceUrl()}/AddCustomRepairTimeItems`
    const authorization = getStoredAuthorization()
    return ajax<AddCustomRepairTimeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function editCustomRepairTimeItem(body: EditCustomRepairTimeItemRequest) {
    const url = `${getServiceUrl()}/EditCustomRepairTimeItem`
    const authorization = getStoredAuthorization()
    return ajax<EditCustomRepairTimeItemResponse>({ url, body, authorization, method: "POST" })
}

export function deleteCustomRepairTimes(body: DeleteCustomRepairTimesRequest) {
    return ajax<void>({ url: `${getServiceUrl()}/Delete`, body, authorization: getStoredAuthorization(), method: "POST" })
}
