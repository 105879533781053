import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { RouteComponentProps, withRouter } from "@tm/utils"
import * as React from "react"

import { getAvailableTruckModules, getAvailableVehicleTypes, getTruckTypeName, getVehicleTypeName } from "../../../helpers/modules"
import { getTruckModuleUrl } from "../../../helpers/routing"

type Props = RouteComponentProps<RouteParams> & {
    activeVehicleType: VehicleType
    setVehicleType(type: VehicleType): void
}

type RouteParams = {
    workTaskId?: string
}

function Navigation({ match, activeVehicleType, setVehicleType }: Props): React.ReactElement | null {
    const { translateText } = useLocalization()

    const availableVehicleTypes = getAvailableVehicleTypes()
    const availableTruckModules = getAvailableTruckModules()

    if (availableVehicleTypes.length + availableTruckModules.length <= 1) {
        return null
    }

    return (
        <div className="mb-2">
            {availableVehicleTypes.map((type) => (
                <Button key={type} layout={["holo"]} skin={activeVehicleType === type ? "highlight" : undefined} onClick={() => setVehicleType(type)}>
                    {getVehicleTypeName(type, translateText)}
                </Button>
            ))}
            {availableTruckModules.map((type) => (
                <Button key={type} layout={["holo"]} linkTo={getTruckModuleUrl(match.params, type)}>
                    {getTruckTypeName(type)}
                </Button>
            ))}
        </div>
    )
}

export default withRouter(Navigation)
