import { equals } from "@tm/utils"

export function addOrRemoveItem<T>(array: T[], object?: T, comparator?: (a: T, B: T) => boolean): T[] {
	if (!object)
		return array

	let comp = comparator ?? equals

	const isInArray = array.some(x => comp(x, object))

	if (isInArray)
		array = array.filter(x => !comp(x, object))
	else
		array.push(object)

	return array
}