import { concat } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { HistoryEntry } from "../../../data/models"
import { InitToolRequest } from "../../extern/business/model"
import { MainState } from "../../main"
import { mapToHistoryVehicle } from "./mappers"

export const createInitToolRequest = (moduleSubId: number, state: MainState, historyEntry?: HistoryEntry): InitToolRequest => {
    const {
        manager: { vehicle },
        summary: {
            vehicleModels: { selectedVehicleModel },
            customerFields,
            vehicleInfoFields,
        },
    } = state
    const customerName = concat(" ", customerFields?.firstName, customerFields?.lastName)

    let request: InitToolRequest = { moduleSubId, returnUrl: getBundleParams().redirectURL, customerName }

    if (historyEntry) {
        request = {
            ...request,
            historyId: historyEntry.historyId,
            hasCalcData: historyEntry.hasCalcData,
            vehicle: historyEntry.customerData?.vehicle,
        }
    }

    if (!historyEntry?.customerData && vehicle && selectedVehicleModel && vehicleInfoFields) {
        request = { ...request, vehicle: mapToHistoryVehicle(vehicle, selectedVehicleModel, vehicleInfoFields) }
    }

    return request
}
