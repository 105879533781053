import { ErpInformation } from "@tm/models"
import { clone } from "@tm/utils"
import { Part } from "../../../../data/models"
import { BasicErpInfo, UpdateTotalPricesRequest } from "../../../../data/repositories/updateTotalPrices"
import { CalculationState } from "../model"

function mapBasicErpInfo(erpInfo: ErpInformation): BasicErpInfo {
    const { dataSupplierArticleNumber, prices } = erpInfo

    return {
        dataSupplierArticleNumber,
        prices,
    }
}

export function createUpdateTotalsRequest(state: CalculationState, erpInfo?: ErpInformation[]): UpdateTotalPricesRequest {
    const alternatives: Part[] = []
    const items = clone(state.items)

    // TODO: alternative articles don't have support for quantity
    items?.forEach((x) => x.isSelected && !!x.selectedPart && alternatives.push({ ...x.selectedPart, quantityValue: x.oeArticle.quantityValue }))

    const selectedItems = items.filter((x) => x.isSelected && !x.selectedPart)

    return {
        oeArticles: selectedItems.map((x) => x.oeArticle),
        alternatives,
        works: state.works?.filter((x) => x.isSelected),
        basicErpInfo: erpInfo?.map(mapBasicErpInfo),
    }
}
