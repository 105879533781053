import { Box, Loader, SelectChangeEvent, VehicleSearch, VehicleSelected } from "@tm/components"
import { useCountryCodeToLicensePlate, useUser, useWorkTask } from "@tm/context-distribution"
import {
    BikeModel,
    CarManufacturer,
    CarModel,
    ECounterType,
    RegistrationNoType,
    TruckModel,
    Vehicle,
    VehicleLookupConfig,
    VehicleShortInfo,
    VehicleType,
} from "@tm/models"
import { encodeUniqueId, TmaHelper, useAvailableVehicleSearchOptions } from "@tm/utils"
import { useCallback, useMemo, useState } from "react"
import { useHistory, useParams } from "react-router"
import { useModelDetailsLoadable, useResetFilters, useSelectedVehicleLookup, useTopManufacturers } from "../../data/hooks"
import { getVehicleDetailsUrl, getVehicleIdentificationUrl, getVehicleIdentificationUrlWithLookup, getVehicleSearchUrl } from "../../helpers/routing"
import { handleUniqueVehicleFound } from "../../helpers/vrm"
import { getBundleParams } from "../../utils"
import { VehicleSearchField } from "../_shared/VehicleSearchField"
import { RouteParams } from "../../models"
import { useRegNoAndType } from "../details/hooks/useRegNoAndType"

export default function WidgetComponent() {
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const { userContext } = useUser()
    const { vehicle } = workTask ?? {}
    const [vehicleType, setVehicleType] = useState(VehicleType.PassengerCar)
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicle?.vehicleType ?? vehicleType)
    const matchParams = useParams<RouteParams>()
    const history = useHistory()
    const searchParams = new URLSearchParams(history.location.search)
    const initialVin = searchParams.get("vin") ?? undefined
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const resetFilters = useResetFilters(vehicleType)
    const { response: modelDetails } = useModelDetailsLoadable({
        vehicleType: vehicle?.vehicleType,
        modelId: vehicle?.tecDocTypeId,
        registrationNoTypeId: vehicle?.registrationTypeId,
        selectedLookupType: selectedVehicleLookup.lookupTypeId,
    })
    const byLookupTypeId = useCallback(
        (lookup: VehicleLookupConfig) => lookup.lookupTypeId === selectedVehicleLookup.lookupTypeId,
        [selectedVehicleLookup]
    )

    const alreadySelectedLookup = availableVehicleSearches?.configuredVehicleLookups.find(byLookupTypeId)
    const { regNoType, regNo } = useRegNoAndType(
        vehicleType,
        vehicle,
        searchParams.toString(),
        !alreadySelectedLookup ? availableVehicleSearches.defaultVehicleLookup : alreadySelectedLookup
    )

    const topManufacturers = useTopManufacturers({ vehicleType, selectedFilters: {} })?.topManufacturers ?? []

    const replaceButton = useMemo(() => {
        return {
            to: getVehicleIdentificationUrl(matchParams, { vehicleType }),
            handleClick: () => TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarChange),
        }
    }, [matchParams, vehicleType])

    const detailsButton = useMemo(() => {
        return {
            to: vehicle ? getVehicleDetailsUrl(matchParams, { vehicleType, vehicleId: encodeUniqueId(vehicle?.id) }) : "",
            handleClick: () => TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarDetails),
        }
    }, [matchParams, vehicle, vehicleType])

    const selectedSearchConfig = useMemo(() => {
        if (!!vehicle && !vehicle.countryCode) {
            return
        }

        if (availableVehicleSearches) {
            const { configuredVehicleLookups } = availableVehicleSearches
            const currentLookupConfig = configuredVehicleLookups.find(
                (lookup) => lookup.isSelectable && lookup.lookupTypeId === selectedVehicleLookup.lookupTypeId
            )
            return currentLookupConfig ?? availableVehicleSearches?.defaultVehicleLookup
        }
    }, [availableVehicleSearches, selectedVehicleLookup, vehicle])

    const handleSelectEngineCode = useCallback(
        (e: SelectChangeEvent<unknown>) => {
            if (!vehicle) {
                return
            }
            const emptyEngineCode = "--"
            const code = e.target.value as string
            attachToWorkTask?.({ vehicle: { ...vehicle, engineCode: code !== emptyEngineCode ? code : undefined } })
        },
        [vehicle]
    )

    function handleVehicleUpdate(vehicle: Vehicle) {
        return attachToWorkTask?.({ vehicle }) ?? Promise.resolve()
    }

    function handleSearchConfigSelect(vehicleSearchConfig: VehicleLookupConfig) {
        if (vehicle) {
            handleVehicleUpdate({
                ...vehicle,
                countryCode: vehicleSearchConfig?.countryCode,
                registrationTypeId: vehicleSearchConfig.lookupTypeId,
            })
        }
    }

    function searchConfigSelectionHandler(vehicleSearchConfig: VehicleLookupConfig) {
        if (vehicleSearchConfig) {
            handleSearchConfigSelect(vehicleSearchConfig)
        }
    }

    function handleTopManufacturerSelect(manufacturer: CarManufacturer) {
        TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarGraphicSearch)
        history.push(
            getVehicleIdentificationUrlWithLookup(matchParams, {
                vehicleType,
                manufacturerId: manufacturer.id,
                mainModelSeriesId: getBundleParams().hideMainModelSeries ? 0 : undefined,
            })
        )
    }

    function handleStartSearch(query: string, regNumberType: RegistrationNoType) {
        resetFilters() // Reset filters before opening search page
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSearchSymbol)

        history.push(
            getVehicleSearchUrl(matchParams, {
                vehicleType,
                query,
                registrationNoType: regNumberType,
            })
        )
    }

    function handleUniqueVehicle(model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string) {
        if (!attachToWorkTask) {
            return
        }

        handleUniqueVehicleFound(
            model,
            vehicleType,
            query,
            attachToWorkTask,
            undefined,
            selectedSearchConfig?.countryCode?.toLowerCase(),
            selectedVehicleLookup.lookupTypeId
        )
    }

    function handleAttachVehicleId(vehicleId: string, query: string) {
        if (!attachToWorkTask) {
            return
        }

        TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query })

        attachToWorkTask({ vehicle: vehicleId })
    }

    return (
        <>
            {!vehicle ? (
                <VehicleSearch
                    selectedSearchConfig={selectedSearchConfig}
                    onSelectSearchOption={searchConfigSelectionHandler}
                    useSelectedVehicleLookup={useSelectedVehicleLookup}
                    flagPath="/styles/base/images/flags/"
                    onSelectManufacturer={handleTopManufacturerSelect}
                    useTopManufacturers={() => ({ topManufacturers })}
                    hideManufacturerLogos={getBundleParams().hideManufacturerLogos}
                    to={getVehicleIdentificationUrl(matchParams, { vehicleType })}
                    vehicleType={vehicleType}
                    setVehicleType={setVehicleType}
                >
                    <VehicleSearchField
                        vehicleType={vehicleType}
                        initialValue={initialVin}
                        onStartSearch={handleStartSearch}
                        onUniqueVehicleFound={handleUniqueVehicle}
                        onAttachVehicleId={handleAttachVehicleId}
                        showVrcScanButton
                        size="small"
                        isOnIndustryDashboard
                    />
                </VehicleSearch>
            ) : (
                <>
                    {!modelDetails?.modelDetails ? (
                        <Box display="flex" margin="0.25em 0">
                            <Loader />
                        </Box>
                    ) : (
                        <VehicleSelected
                            modelDetails={modelDetails?.modelDetails}
                            useCountryCodeToLicensePlate={useCountryCodeToLicensePlate}
                            useRegNoAndType={() => ({ regNoType, regNo })}
                            replaceButton={replaceButton}
                            detailsButton={userContext.isAnonym ? undefined : detailsButton}
                            vehicle={vehicle}
                            handleSelectEngineCode={handleSelectEngineCode}
                        />
                    )}
                </>
            )}
        </>
    )
}
