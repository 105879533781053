import { Box, Skeleton } from "@tm/components"
import { SkeletonSelectionLoading } from "./SkeletonSelectionLoading"

export function SkeletonLoading() {
    return (
        <Box>
            <Skeleton height="18px" width="100px" />
            <Skeleton height="60px" width="80px" sx={{ transformOrigin: "0 10%" }} />
            <SkeletonSelectionLoading />
        </Box>
    )
}
