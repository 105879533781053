import { CompilationArticleInterface } from "../interfaces/compilation-article-interface"
import { ArticleAddRequestWM } from "../model"

export const mapArticleToAddRequest = (articles: CompilationArticleInterface["articles"]): ArticleAddRequestWM[] => {
    return articles.map((article) => ({
        quantity: article.quantity,
        description: article.description,
        wholesalerArticleNo: article.wholesalerArticleNo,
        note: article.note,
        customerArticleNumber: article.customerArticleNumber,
        supplierArticleNumber: article.supplierArticleNumber,
        supplierId: article.supplierId,
    }))
}
