import { useUser } from "@tm/context-distribution"
import { GetAdvertisementCategoriesResponse, RegisteredModels, UserModuleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

export function useAdvertisementCategories(enabled?: boolean) {
    const { userContext } = useUser() ?? {}

    return useQuery(
        "offers_advertisement-categories",
        () =>
            Container.getInstance<GetAdvertisementCategoriesResponse>(RegisteredModels.ERP_GetAdvertisementCategories)
                .subscribe()
                .load()
                .then((response) => response.advertisementCategories),
        {
            enabled: !!enabled && !!userContext?.modules?.some((x) => x.type === UserModuleType.OfferCarousel),
        }
    )
}
