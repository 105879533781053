import { AsyncAction } from "@tm/morpheus"
import { getErrQueryHistory } from "../../../data/helpers/queryHistory"
import { ErrorSearchState } from "./model"

export * from "./model"

export type ComponentActionType = { type: "ERROR_SEARCH_HISTORY_LOADED"; payload: Array<string> } | { type: "LOADING_HISTORY" }

const DEFAULT_STATE: ErrorSearchState = {
    errorQueryHistory: [],
    errorQueriesLoading: false,
}

export const reduce = (state = DEFAULT_STATE, action: ComponentActionType): ErrorSearchState => {
    switch (action.type) {
        case "LOADING_HISTORY": {
            return {
                ...state,
                errorQueriesLoading: true,
            }
        }
        case "ERROR_SEARCH_HISTORY_LOADED": {
            const queries = action.payload
            return {
                ...state,
                errorQueryHistory: queries,
                errorQueriesLoading: false,
            }
        }
        default:
            return state
    }
}

const loadQueryHistory = (): AsyncAction<ComponentActionType, ErrorSearchState> => {
    return (dispatch) => {
        dispatch({ type: "LOADING_HISTORY" })

        getErrQueryHistory().then((history) => {
            return dispatch({ type: "ERROR_SEARCH_HISTORY_LOADED", payload: history })
        })
    }
}

export const ErrorSearchActions = {
    loadQueryHistory,
}
