import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { CalculationDataRequest, CalculationDataResponse } from "../models/calculation"

export function getCalculationData(request: CalculationDataRequest) {
    const url = `${getServiceUrl()}/CalculateArticlesForOe`
    const authorization = getStoredAuthorization()
    const body: CalculationDataRequest = request
    return ajax<CalculationDataResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        if (!response) {
            throw new Error()
        }
        return response
    })
}

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
