import { Suspense } from "react"
import { IMicros } from "@tm/models"
import PricesComponent from "../../../_shared/prices"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["details-erp-info-prices"]

function DetailsErpInfoPricesComponent({ data, foundBySearchTerm, priceNamesInTooltip }: Props) {
    const { loading, erpInfo } = useErpInfo(data, "details", undefined, undefined, foundBySearchTerm)

    if (loading || !erpInfo?.prices?.length) {
        return null
    }

    return (
        <PricesComponent
            classPrefix="details-erp-info-prices"
            prices={erpInfo.prices}
            erpInfo={erpInfo}
            hasGraduatedPrices={false}
            hideGraduatedPricesIcon
            priceNamesInTooltip={priceNamesInTooltip}
            article={data}
            isReplacementPart={erpInfo?.isReplacementPart || false}
        />
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="details-erp-info-prices">
                    <Suspense fallback={null}>
                        <DetailsErpInfoPricesComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
