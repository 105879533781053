import Morpheus from "@tm/morpheus"

import { PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"

export default function handleCloseModal(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (!data.closeModal) {
        return
    }
    Morpheus.closeView("1")
}
