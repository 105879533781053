import { Stack } from "@tm/components"
import { PricesProps } from "."
import BigScreenPrice from "./BigScreenPrice"

export default function BigScreenPrices(props: PricesProps) {
    const {
        showPurchasePrice,
        theme,
        purchasePrice,
        showTotalPrices,
        purchasePriceLabel,
        showRetailPrice,
        retailPrice,
        retailPriceLabel,
        totalPurchasePrice,
        totalPurchasePriceLabel,
        totalRetailPrice,
        totalRetailPriceLabel,
        totalRecommendedPrice,
        totalRecommendedPriceLabel,
    } = props

    return (
        <Stack direction="row" spacing={4} justifyContent="flex-end">
            {/* unit price */}
            {showPurchasePrice && purchasePrice?.value && (
                <BigScreenPrice price={purchasePrice} priceLabel={purchasePriceLabel} primaryColor theme={theme} />
            )}
            {showRetailPrice && retailPrice?.value && <BigScreenPrice price={retailPrice} priceLabel={retailPriceLabel} theme={theme} />}

            {/* total purchase price */}
            {showTotalPrices && showPurchasePrice && totalPurchasePrice?.value && (
                <BigScreenPrice price={totalPurchasePrice} priceLabel={totalPurchasePriceLabel} primaryColor theme={theme} />
            )}

            {/* total retail price */}
            {showTotalPrices && totalRetailPrice?.value && (
                <BigScreenPrice price={totalRetailPrice} priceLabel={totalRetailPriceLabel} theme={theme} />
            )}
            {showTotalPrices && !totalRetailPrice?.value && totalRecommendedPrice?.value && (
                <BigScreenPrice price={totalRecommendedPrice} priceLabel={totalRecommendedPriceLabel} theme={theme} />
            )}
        </Stack>
    )
}
