import { UpdateRepairShopRequest } from "@tm/context-distribution"
import { PartslifeReturnUrlRequest } from "../models/partslife"
import { getGenderTitle } from "."

export function getReturnUrlRequest(
    repairShop: UpdateRepairShopRequest,
    companyId: string,
    countryCode: string,
    translateText: (key: string | number) => string
): PartslifeReturnUrlRequest {
    // Info the repairShop attributes used can not be undefined, the form restricts it, original model kept for more clarity and overhead reduction
    const contactPersonGenderOptions = getGenderTitle(translateText)

    const genderTitleString = contactPersonGenderOptions.find((cP) => cP.key === repairShop.contactPerson?.title ?? 0)?.value ?? ""

    return {
        companyId,
        companyName: repairShop.companyName ?? "",
        street: repairShop.postalAddress?.street ?? "",
        zip: repairShop.postalAddress?.zip ?? "",
        city: repairShop.postalAddress?.city ?? "",
        phone: repairShop.contactInfo?.phone ?? "",
        email: repairShop.contactInfo?.email ?? "",
        firstName: repairShop.contactPerson?.firstName ?? "",
        lastName: repairShop.contactPerson?.lastName ?? "",
        title: genderTitleString,
        countryCode,
    }
}
