import { useLocalization } from "@tm/localization"
import { Warehouse } from "@tm/models"
import SimpleTourTable, { SimpleTourTableData } from "../../../../_shared/SimpleTourTable"
import DetailsWarehouseTable from "./warehouse-table"

type Props = {
    warehouses?: Array<Warehouse>
    requestedQuantity: number | undefined
}

export default function Warehouses({ warehouses, requestedQuantity }: Props) {
    const { translateText } = useLocalization()

    if (!warehouses?.length) {
        return null
    }
    if (warehouses.length === 1 && !warehouses[0].showWarehouseInfo) {
        const data: Array<SimpleTourTableData> = []

        if (warehouses[0].standardDelivery?.length && warehouses[0].standardDelivery[0].tours) {
            data.push({ title: translateText(1146), tours: warehouses[0].standardDelivery[0].tours })
        }

        if (warehouses[0].collection?.length && warehouses[0].collection[0].tours) {
            data.push({ title: translateText(1147), tours: warehouses[0].collection[0].tours })
        }

        // if (warehouses[0].dayExpressDelivery && warehouses[0].dayExpressDelivery.length && warehouses[0].dayExpressDelivery[0].tours)
        //     data.push({ title: translateText(), tours: warehouses[0].dayExpressDelivery[0].tours })

        if (warehouses[0].nightExpressDelivery && warehouses[0].nightExpressDelivery.length && warehouses[0].nightExpressDelivery[0].tours) {
            data.push({ title: translateText(13441), tours: warehouses[0].nightExpressDelivery[0].tours })
        }

        return <SimpleTourTable data={data} transparentBackground />
    }

    return <DetailsWarehouseTable warehouses={warehouses} requestedQuantity={requestedQuantity} />
}
