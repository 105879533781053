import { Omit } from "@tm/utils"
import { ComponentType, createElement } from "react"
import { connect, Provider } from "react-redux"
import { Action, bindActionCreators, Dispatch, Store } from "redux"

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(component: ComponentType<Omit<P, keyof StoreModel>>): ComponentType<P> {
    return (props: P) => createElement(Provider, { store: props.store }, createElement(component, props))
}

type ActionType<V> = { actions?: V }
export function connector<P extends U & ActionType<V>, U, V, S>(
    component: ComponentType<P>,
    mapStToPr?: (store: S, props: P) => U,
    actions?: V
): ComponentType<Omit<P, keyof U | keyof ActionType<V>>> {
    const mapDisptachToProps = (dispatch: Dispatch<Action<V>>, props: P): P => {
        return {
            ...props,
            actions: actions && bindActionCreators(actions || {}, dispatch),
        }
    }

    const mapStateToProps = (store: S, props: P): P => {
        return {
            ...props,
            ...mapStToPr?.(store, props),
        }
    }

    return connect(mapStateToProps, mapDisptachToProps)(component as ComponentType<any>) as any
}
