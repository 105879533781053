import { channel, getCurrentWorkTaskId } from "@tm/models";
import { useEffect } from "react"
import { useNodesStore, useServicesStore } from "../../data/state";

export default function Manager() {
    const { resetNodesStore } = useNodesStore()
    const { resetServiceStore } = useServicesStore()

    useEffect(() => {
        const unsub = channel("WORKTASK", getCurrentWorkTaskId()).subscribe("MODULE/CLOSED", module => {
            if (module.includes("memo-tool")) {
                resetNodesStore()
                resetServiceStore()
            }
        })
        return () => {
            unsub()
        }
    }, [])

    return null
}