import { CarModelDetailsRequest, RegisteredModels, ShowCarModelDetailsRequest, VehicleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { mapBikeModelDetailsResponse, mapTruckModelDetailsResponse } from "./mapper"

import { showManufacturerDetails as showBikeManufacturerDetails } from "./repositories/bikes/showManufacturers"
import { showModelDetails as showBikeModelDetails } from "./repositories/bikes/showModels"
import { showModelSeriesDetails as showBikeModelSeriesDetails } from "./repositories/bikes/showModelSeries"
import { showKbaNumbers as showCarKbaNumbers } from "./repositories/cars/showKbaNumbers"
import { showManufacturerDetails as showCarManufacturerDetails } from "./repositories/cars/showManufacturers"
import { showModelDetails as showCarModelDetails } from "./repositories/cars/showModels"
import { showModelSeriesDetails as showCarModelSeriesDetails } from "./repositories/cars/showModelSeries"
import { showAvailabilities } from "./repositories/data-availabilities"
import { showConfig } from "./repositories/options"
import { PrintVehicleInfoRequest, printVehicleInformation } from "./repositories/print"
import { showManufacturerDetails as showTruckManufacturerDetails } from "./repositories/trucks/showManufacturers"
import { showModelDetails as showTruckModelDetails } from "./repositories/trucks/showModels"
import { showModelSeriesDetails as showTruckModelSeriesDetails } from "./repositories/trucks/showModelSeries"

export function registerActions() {
    Container.register({
        name: RegisteredModels.Vehicle_ModelDetails,
        modelActions: {
            load: (request: CarModelDetailsRequest, vehicleType?: VehicleType) => {
                switch (vehicleType) {
                    case VehicleType.Motorcycle:
                        return showBikeModelDetails(request).then(mapBikeModelDetailsResponse)
                    case VehicleType.CommercialVehicle:
                        return showTruckModelDetails(request).then(mapTruckModelDetailsResponse)
                    case VehicleType.PassengerCar:
                    default:
                        return showCarModelDetails(request)
                }
            },
        },
    })

    Container.register({
        name: RegisteredModels.Vehicle_KbaNumbers,
        modelActions: {
            load: (modelId: number) => showCarKbaNumbers({ modelId }).then((x) => x?.numbers ?? []),
        },
    })

    Container.register({
        name: RegisteredModels.Vehicle_ShowOptions,
        modelActions: {
            load: showConfig,
        },
    })

    Container.register({
        name: RegisteredModels.Vehicle_ManufacturerDetails,
        modelActions: {
            load: (request: { manufacturerId: number }, vehicleType?: VehicleType) => {
                switch (vehicleType) {
                    case VehicleType.Motorcycle:
                        return showBikeManufacturerDetails({ manufacturerId: request.manufacturerId }).then((x) => x?.manufacturerDetails)
                    case VehicleType.CommercialVehicle:
                        return showTruckManufacturerDetails({ manufacturerId: request.manufacturerId }).then((x) => x?.manufacturerDetails)
                    case VehicleType.PassengerCar:
                    default:
                        return showCarManufacturerDetails({ manufacturerId: request.manufacturerId }).then((x) => x?.manufacturerDetails)
                }
            },
        },
    })

    Container.register({
        name: RegisteredModels.Vehicle_ModelSeriesDetails,
        modelActions: {
            load: (request: { manufacturerId: number; modelSeriesId: number }, vehicleType?: VehicleType) => {
                switch (vehicleType) {
                    case VehicleType.Motorcycle:
                        return showBikeModelSeriesDetails({ manufacturerId: request.manufacturerId, modelSeriesId: request.modelSeriesId }).then(
                            (x) => x?.modelSeriesDetails
                        )
                    case VehicleType.CommercialVehicle:
                        return showTruckModelSeriesDetails({ manufacturerId: request.manufacturerId, modelSeriesId: request.modelSeriesId }).then(
                            (x) => x?.modelSeriesDetails
                        )
                    case VehicleType.PassengerCar:
                    default:
                        return showCarModelSeriesDetails({ manufacturerId: request.manufacturerId, modelSeriesId: request.modelSeriesId }).then(
                            (x) => x?.modelSeriesDetails
                        )
                }
            },
        },
    })

    Container.register({
        name: RegisteredModels.Print_VehicleDatInfo,
        modelActions: {
            load: printVehicleInformation,
        },
    })

    Container.register({
        name: RegisteredModels.Vehicle_DataAvailabilities,
        modelActions: {
            load: showAvailabilities,
        },
    })
}
