import { useShowCisOptions, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { CisVoucherType } from "@tm/models"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { ShowAllOrdersRequest } from "../model"
import * as Data from "../repositories/cis/vouchers"

function showAllOrders(customerNo: string | undefined) {
    const showAllOrdersRequest: ShowAllOrdersRequest = {
        pageIndex: 1,
        pageSize: 150,
        customerNo,
    }

    return Data.showAllOrders(showAllOrdersRequest)
}

const KEY = "cis_useAllOrdersRecent"
const VOUCHER_TYPE = CisVoucherType.AllOrders
export function useAllOrdersRecent() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { cisOptions } = useShowCisOptions()

    const voucherTypeEnabled = useMemo(() => {
        return !!cisOptions?.voucherTypes?.some((type) => type.typeId === VOUCHER_TYPE)
    }, [cisOptions])

    const enabled = voucherTypeEnabled && enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => {
            return showAllOrders(telesalesCustomerNo)
        },
        {
            enabled,
        }
    )

    return {
        allOrders: data?.orders,
        allOrdersLoading: isLoading,
    }
}
