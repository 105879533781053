import { MainService, RepairTimeProvider } from "@tm/models"
import { SearchTreeNode } from "./data"

export type BundleActionType =
    | {
          type: "SELECT_MAIN_SERVICE"
          payload: { provider: RepairTimeProvider; workTaskId: string; mainService: MainService; repairTimeDivision: number }
      }
    | { type: "CLEAR_MAIN_SERVICE" }
    | { type: "MODULE_OPENED"; payload: ModuleOpenedPayload }
    | { type: "ADD_BREADCRUMB"; payload: SearchTreeNode }

export type ModuleOpenedPayload = {
    icon: string
    title: string
}

function updateModuleURL(payload: ModuleOpenedPayload): BundleActionType {
    return { type: "MODULE_OPENED", payload }
}

export type IBundleActions = typeof BundleActions

export const BundleActions = {
    updateModuleURL,
}

export type RouteParams = {
    mainCategoryId?: string
    subCategoryId?: string
    repairTimeId?: string
    searchQuery?: string
    productGroupId?: string
    position?: string
    workTaskId: string
}
