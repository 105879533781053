import { useMemo } from "react"
import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ErpSystemConfig, IMicros, PriceType } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { TableRow, TableCell, Box, Typography, CellContentPosition } from "@tm/components"
import GraduatedPricesDropdown from "../graduated-prices-dropdown"
import SpecialIcons from "../special-icons"
import AvailabilityWrapperComponent from "../availability-wrapper"
import { useErpInfo } from "../../micros/_helpers/useErpInfo"
import { useArticleWithChangedTradeReference } from "../../data/hooks/useArticleWithChangedTradeReference"

type Props = {
    article: Article
    basketItemId?: string
    columnCount: number
    erpSystemConfig: ErpSystemConfig
}

export default function PartnerSystemRowComponent({ erpSystemConfig, article, basketItemId, columnCount }: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const { currency, date } = useLocalization()

    const { erpInfo, loading } = useErpInfo(article, "list", undefined, erpSystemConfig)
    const mergedArticle = useArticleWithChangedTradeReference(article, erpSystemConfig)

    const basketArticles = useMemo(() => (mergedArticle ? [mergedArticle] : []), [mergedArticle])

    function renderPrice(type: PriceType, isRebate?: boolean, showQuantityUnit?: boolean) {
        const price = erpInfo?.prices?.find((p) => p.type === type)

        if (!price) {
            return ""
        }

        if (showQuantityUnit) {
            return !!price.priceUnit && <Typography variant="label">{`${price.priceUnit} ${erpInfo?.quantity?.quantityUnit || ""}`}</Typography>
        }

        if (isRebate) {
            return <>{price.rebate || "-"}</>
        }

        return <Box> {currency(price.value, price.currencySymbol || "")} </Box>
    }

    if (!mergedArticle) {
        return <TableRow colCount={columnCount} />
    }

    return (
        <TableRow colCount={columnCount}>
            <TableCell colCount={columnCount}>{erpSystemConfig.description}</TableCell>
            <TableCell colCount={columnCount}>{erpSystemConfig.username}</TableCell>
            <TableCell colCount={columnCount}>{mergedArticle?.traderArticleNo || ""}</TableCell>
            <TableCell colCount={columnCount}>{erpInfo?.tour?.tourStart ? date(erpInfo.tour.tourStart, "d") : ""}</TableCell>
            <TableCell colCount={columnCount} alignContent={CellContentPosition.right}>
                {renderPrice(PriceType.Retail)}
            </TableCell>
            <TableCell colCount={columnCount} alignContent={CellContentPosition.right}>
                {renderPrice(PriceType.Retail, true)}
            </TableCell>
            <TableCell colCount={columnCount} alignContent={CellContentPosition.right}>
                {renderPrice(PriceType.Purchase)}
            </TableCell>
            <TableCell colCount={columnCount} alignContent={CellContentPosition.right}>
                {erpInfo?.graduatedPrices?.length ? (
                    <GraduatedPricesDropdown
                        layout="icon"
                        graduatedPrices={erpInfo.graduatedPrices}
                        article={article}
                        erpInfo={erpInfo}
                        overwriteErpSystemConfig={erpSystemConfig}
                    />
                ) : (
                    ""
                )}
            </TableCell>
            <TableCell colCount={columnCount} alignContent={CellContentPosition.right}>
                {renderPrice(PriceType.Retail, false, true)}
            </TableCell>
            <TableCell colCount={columnCount} alignContent={CellContentPosition.right}>
                {erpInfo?.quantity?.quantityPackingUnit || ""}
            </TableCell>
            <TableCell colCount={columnCount} alignContent={CellContentPosition.right}>
                <SpecialIcons icons={erpInfo?.specialIcons} />
            </TableCell>
            <TableCell colCount={columnCount}>
                {loading ? (
                    <Loader />
                ) : (
                    <AvailabilityWrapperComponent
                        hasReplacedArticles={!!(erpInfo?.articles && erpInfo.articles.length)}
                        availability={erpInfo?.availability}
                        tour={erpInfo?.tour}
                        warehouses={erpInfo?.warehouses}
                        requestedQuantity={erpInfo?.quantity?.requestedValue}
                        specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
                        useSpecialProcurement={erpInfo?.useSpecialProcurement}
                    />
                )}
            </TableCell>
            <TableCell colCount={columnCount} maxWidth={170} alignContent={CellContentPosition.right}>
                {basketItemId
                    ? renderMicro("basket", "change-distributor-button", { basketItemId, article: mergedArticle, erpSystemConfig })
                    : renderMicro("basket", "add-to-basket", { data: basketArticles, erpSystemConfig })}
            </TableCell>
        </TableRow>
    )
}
