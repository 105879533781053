import { useCallback, useMemo, useEffect } from "react"
import { atom, useRecoilState, useRecoilValue, useRecoilValueLoadable } from "recoil"
import { useWorkTask } from "@tm/context-distribution"
import { ajax } from "@tm/utils"
import { InitialRepairshopMetaData, MetaDataType } from ".."
import { useChatAuthorization, Message } from "."
import { getBundleParams } from "../../utils"

type IdStorage = {
    [chatId: string]:
        | {
              tmCustomerId?: string
              seatbeltCustomerId?: string
              vehicles: Array<{ tmVehicleId: string; seatbeltVehicleId: string }>
          }
        | undefined
}

const idStorageKey = "notifications_chat_id-storage"
const idStorageRecoilAtom = atom<IdStorage>({
    key: idStorageKey,
    default: {}, // TODO?: Use an async selector to initialize the atom instead of the useEffect in useInitalizeChatStorage
})

function getUpdatedIdStorageValueFromMetaData(prev: IdStorage[string] | undefined, metaData: InitialRepairshopMetaData) {
    let result = prev

    if (metaData.customer) {
        result = {
            tmCustomerId: metaData.customer.tmCustomerId,
            seatbeltCustomerId: metaData.customer.seatbeltCustomerId,
            vehicles: result?.vehicles || [],
        }
    }

    metaData.vehicles?.forEach((v) => {
        if (v.tmVehicleId && result) {
            result = {
                ...result,
                vehicles: [...result.vehicles, v],
            }
        }
    })

    return result
}

/**
 * Initalize the IdStorage by running getallcontainingmetadata request with filter "type":"initial"
 * which means to get all chat messages containing these string within the metadata
 */
export function useInitalizeChatStorage() {
    const authorization = useChatAuthorization()
    const [state, setState] = useRecoilState(idStorageRecoilAtom)

    useEffect(() => {
        const url = `${getBundleParams().chatApiUrl}/api/v1/user/chats/messages/getallcontainingmetadata/"type":"initial"`
        ajax({ url, method: "GET", authorization }).then((response: Array<Message>) => {
            if (!response.length) {
                return
            }

            const chatStorage: IdStorage = {}

            response.forEach((m) => {
                if (m.appMetaData) {
                    const metaData: MetaDataType = JSON.parse(m.appMetaData)
                    let result: IdStorage[string] = chatStorage[m.chatId] || state[m.chatId] || undefined

                    if (metaData.type === "initial" && metaData.action === "repairshopData") {
                        result = getUpdatedIdStorageValueFromMetaData(result, metaData)
                    }

                    chatStorage[m.chatId] = result
                }
            })
            setState(chatStorage)
        })
        // Only execute on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleReceiveMessageChatStorage = useCallback(
        (m: Message) => {
            if (m.appMetaData) {
                const metaData: MetaDataType = JSON.parse(m.appMetaData)
                if (metaData.type === "initial" && metaData.action === "repairshopData") {
                    setState((prev) => {
                        return {
                            ...prev,
                            [m.chatId]: getUpdatedIdStorageValueFromMetaData(prev[m.chatId], metaData),
                        }
                    })
                }
            }
        },
        [setState]
    )

    return { handleReceiveMessageChatStorage }
}

function getIdStorageByWorkTask(customerId: string | undefined, vehicleId: string | undefined, idStorage: IdStorage | undefined) {
    if (!customerId || !idStorage) {
        return
    }

    let chatId

    const keys = Object.keys(idStorage)
    for (let i = 0; i < keys.length; i++) {
        const chatStorage = idStorage[keys[i]]

        if (chatStorage?.tmCustomerId === customerId) {
            chatId = keys[i]

            if (!vehicleId) {
                // If the workTask doesn't have a vehicle we can just return the first chat id with matching customer
                return { chatId }
            }

            const vehicle = chatStorage.vehicles.find((v) => v.tmVehicleId === vehicleId)
            if (vehicle) {
                // We can only return here if the chat contains the correct vehicle.
                // Otherwise we have to continue searching, because other chats with the same customer might exist.
                return { chatId, vehicle }
            }
        }
    }

    if (chatId) {
        // If a chat was found for the customer, but not for the workTask vehicle, we return only the chatId here
        return { chatId }
    }
}

export function useIdStorageByWorkTask() {
    const { workTask } = useWorkTask() ?? {}
    const { customer, vehicle } = workTask ?? {}
    const idStorage = useRecoilValue(idStorageRecoilAtom)

    return useMemo(() => getIdStorageByWorkTask(customer?.id, vehicle?.id, idStorage), [idStorage, customer?.id, vehicle?.id])
}

export function useIdStorageByWorkTaskLoadable() {
    const { workTask } = useWorkTask() ?? {}
    const { customer, vehicle } = workTask ?? {}
    const idStorage = useRecoilValueLoadable(idStorageRecoilAtom).valueMaybe()

    return useMemo(() => getIdStorageByWorkTask(customer?.id, vehicle?.id, idStorage), [idStorage, customer?.id, vehicle?.id])
}
