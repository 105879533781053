import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Tag } from "@tm/controls"
import { SearchHistoryItem } from "../business"

type Props = {
    searchHistoryItems: SearchHistoryItem[]
    onHandleHistoryClick(item: SearchHistoryItem): void
}

export default function SearchHistory({ searchHistoryItems, onHandleHistoryClick }: Props) {
    const { translateText } = useLocalization()
    if (!searchHistoryItems) {
        return null
    }

    return (
        <Stack spacing={0.5}>
            <Typography variant="label" textTransform="uppercase" width="100%">
                {translateText(1765)}
            </Typography>
            <Stack direction="row" flexWrap="wrap">
                {searchHistoryItems.map((item) => (
                    <Tag key={`${item.queryTypeId}_${item.query}`} value={item.query} onClick={() => onHandleHistoryClick(item)} />
                ))}
            </Stack>
        </Stack>
    )
}
