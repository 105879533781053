import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { matchPath } from "react-router"
import { Popover, Badge } from "@tm/controls"
import { RouteComponentProps, registerOutsideClick, renderRoute, withRouter, TmaHelper } from "@tm/utils"
import { GlobalNavigationItem, Icon } from "@tm/components"
import { ECounterType } from "@tm/models"

import { Message, useLastMessages, useLastReadInfoDate, useOwnUserId, useSignalRMessage } from "../../../../data/hooks"
import { getBundleParams } from "../../../../utils"
import { PopoverContent } from "./popover-content"
import { getComponentStyles } from "./styles"

type Props = RouteComponentProps

function NotificationCenter(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const [showPopover, setShowPopover] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const ownUserId = useOwnUserId()
    const [hasNewMessage, setHasNewMessage] = useState(false)
    const { chats } = useLastMessages(1)
    const { lastReadInfoDate } = useLastReadInfoDate()

    const handler = useCallback(
        (message: Message) => {
            if (message.authorId !== ownUserId) {
                setHasNewMessage(true)
            }
        },
        [ownUserId]
    )

    useSignalRMessage(handler)

    useEffect(() => {
        if (wrapperRef.current && showPopover) {
            return registerOutsideClick(wrapperRef.current, () => setShowPopover(false))
        }
    }, [setShowPopover, showPopover])

    const isTargetRouteMatch = matchPath(props.location.pathname, { path: getBundleParams().chatDetailsRoute })
    const lastMessageDate = chats?.[0]?.lastMessage?.inserted
    const isNew = lastMessageDate && lastReadInfoDate && new Date(lastMessageDate) > lastReadInfoDate

    const targetRoute = renderRoute(getBundleParams().chatDetailsRoute, {})

    const handleIconClick = !isTargetRouteMatch
        ? () => {
              setShowPopover(!showPopover)
              setHasNewMessage(false)
              TmaHelper.GeneralCountEvent.Call(ECounterType.Chat)
          }
        : undefined

    const handleDblClick = !isTargetRouteMatch
        ? () => {
              setShowPopover(false)
              setHasNewMessage(false)
              props.history.push(targetRoute)
          }
        : undefined

    return (
        <div className={classNames.main} ref={wrapperRef}>
            <GlobalNavigationItem
                onIconClick={handleIconClick}
                onDoubleClick={handleDblClick}
                name="chat"
                componentType="icon"
                tooltip="Chat"
                active={!!isTargetRouteMatch || showPopover}
                noOffsetOnActive={!isTargetRouteMatch && showPopover}
                endAdornment={isNew || hasNewMessage ? <Badge skin="danger" value=" " className={classNames.newMessageBadge} /> : undefined}
            />

            {showPopover && (
                <Popover active alignArrow={["top", "right"]} className={classNames.popover}>
                    <PopoverContent lastReadInfoDate={lastReadInfoDate} onClose={() => setShowPopover(false)} />
                </Popover>
            )}
        </div>
    )
}

export default withRouter(NotificationCenter)
