import { useState, useEffect } from "react"
import Morpheus from "@tm/morpheus"
import { channel, SystemType, VehicleType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { createQueryString, withRouter, RouteComponentProps, parseQueryString } from "@tm/utils"
import { IFrame, Loader, Text } from "@tm/controls"
import { LoginRequest } from "../../data"
import { getBundleParams } from "../../utils"
import { getCatalogNumber, GetMvcPlatformId, getMvcSessionId } from "../../data/helpers/common"

type RouteProps = {
    workTaskId: string

    // The following props are only filled in the part repairtimes context
    supplierId?: string
    supplierName?: string
    supplierArticleNo?: string
    productGroupId?: string
    traderArticleNo?: string
    position?: string
    quantityValue?: string
    itemId?: string
    productGroupName?: string
    articleDescription?: string
    topicId?: string
}

type Props = RouteComponentProps<RouteProps> & {
    className?: string
    title: string
    id: string
    loginUrl: string
    loginRequest: LoginRequest
    withoutVehicle?: boolean
    observeViewportIntersection?: boolean
    useNativeIFrame?: boolean
    refreshOnUrlChanged?: boolean
}

function FrameComponent(props: Props) {
    const { languageId, translateText } = useLocalization()
    const { vehicle, id: workTaskId } = useWorkTask()?.workTask || {}
    const { userContext } = useUser() ?? {}

    const [loading, setLoading] = useState<boolean>(true)
    const [errorMessage, setErrorMessage] = useState<string>()
    const { loginRequest, id, withoutVehicle, loginUrl, match, className, useNativeIFrame } = props

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            channel("GLOBAL").subscribe("MVC/FEEDBACK_RESULT", ({ getFeedbackResult }) => {
                if (getFeedbackResult) {
                    Morpheus.closeView("1")
                } else {
                    setErrorMessage(translateText(1706))
                }
            })
        )
        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [])

    function getRequestParameter(): LoginRequest | null {
        if ((userContext?.system.systemType == SystemType.Next && !workTaskId) || !userContext || (!withoutVehicle && !vehicle?.tecDocTypeId)) {
            return null
        }

        const catNr = getCatalogNumber(userContext)

        let request: LoginRequest = {
            ...loginRequest,
            platformId: GetMvcPlatformId(userContext.system.systemType), // This is the id used by the MVC Application to identify NEXT
            sectionId: loginRequest?.sectionId,
            languageId, // MLE - 22.05.19: changed from "language" to "languageId"
            mdmSessionId: userContext?.id,
            h_session: getMvcSessionId(workTaskId),
            catNr,
            clientId: catNr,
            supplierNumber: match.params.supplierId || "",
            supplierName: match.params.supplierName || "",
            supplierArticleNumber: match.params.supplierArticleNo?.replace(/%252F/g, "/") || "",
            wholesalerArticleNumber: match.params.traderArticleNo || "",
            productGroupName: match.params.productGroupName || "",
        }

        if (id == "negative-sale") {
            request = {
                ...request,
                itemId: match.params.itemId,
                productGroupId: match.params.productGroupId,
                productGroupName: match.params.productGroupName,
                articleDescription: parseQueryString(location.search).articleDescription,
                quantityValue: match.params.quantityValue,
            }
        }

        if (vehicle) {
            request.kTypeId =
                vehicle.vehicleType === VehicleType.CommercialVehicle
                    ? vehicle.tecDocTypeId - getBundleParams().commercialVehicleOffset
                    : vehicle.tecDocTypeId
            request.engineCode = vehicle.engineCode
            request.typeArt = vehicle.vehicleType
            request.vinNumber = vehicle.vin
            request.regNo = vehicle.plateId
            request.registrationNo = vehicle.registrationNo
        }

        if (userContext?.account?.customerNo) {
            request.customerNo = userContext.account.customerNo
        }

        if (request.moduleMode === "rtgenarts") {
            request.genartId = match.params.productGroupId
        } else if (request.moduleMode === "TDGENARTS") {
            request.genartId = match.params.productGroupId
            request.topicIds = match.params.topicId
        }

        return request
    }

    function handleLoadingCallback() {
        setLoading(false)
    }

    const request = getRequestParameter()

    if (!request) {
        return null
    }

    const url = `${loginUrl}${createQueryString(request)}`
    const newClassName = `module frame ${className || ""}`

    if (errorMessage) {
        return (
            <Text modifiers="danger" size="l">
                {errorMessage}
            </Text>
        )
    }
    if (useNativeIFrame) {
        return <iframe src={url} className={newClassName} id={`iframe_${id}`} onLoad={handleLoadingCallback} />
    }
    if (id == "tecrmi-td-genarts") {
        return (
            <>
                <iframe
                    style={{ ...(loading && { display: "none" }) }}
                    src={url}
                    className={newClassName}
                    id={`iframe_${id}`}
                    onLoad={handleLoadingCallback}
                />
                {loading && (
                    <div className={newClassName}>
                        <Loader />
                    </div>
                )}
            </>
        )
    }
    return (
        <IFrame
            url={url}
            className={newClassName}
            contextDependent
            id={`iframe_${id}`}
            observeViewportIntersection={props.observeViewportIntersection}
            refreshOnUrlChanged={props.refreshOnUrlChanged}
            onLoad={handleLoadingCallback}
        >
            {loading ? <Loader /> : <></>}
        </IFrame>
    )
}

export default withRouter(FrameComponent)
