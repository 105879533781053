import { matchPath, useHistory, useParams } from "react-router-dom"
import { renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { GlobalNavigationItem } from "@tm/components"
import { useCallback, useMemo } from "react"
import { getBundleParams } from "../../utils"

export default function HeaderButton() {
    const { translateText } = useLocalization()
    const matchParams = useParams()
    const { drivemotiveRoute, headerButton } = getBundleParams()
    const workTaskId = useWorkTask()?.workTaskId
    const history = useHistory()
    const url = useMemo(
        () =>
            renderRoute(drivemotiveRoute, {
                ...matchParams,
                workTaskId: encodeUniqueId(workTaskId || uniqueId()),
            }),
        [drivemotiveRoute, workTaskId, matchParams]
    )
    const isActive = useMemo(
        () => !!matchPath(history.location.pathname + history.location.search, url),
        [history.location.pathname, history.location.search, url]
    )

    const handleClick = useCallback(() => {
        history.push(url)
    }, [url, history])

    if (!headerButton) {
        return null
    }
    return <GlobalNavigationItem name="drivemotive" componentType="icon" active={isActive} onIconClick={handleClick} tooltip={translateText(13689)} />
}
