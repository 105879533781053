import { AlertNotification, useTelesalesCustomerNumber } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { BaseConfig } from "@tm/models"

export default function TelesalesCustomerNotificationComponent() {
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { erpServiceUrl } = Morpheus.getParams<BaseConfig>()

    if (!erpServiceUrl || !telesalesCustomerNo) {
        return null
    }

    return <AlertNotification erpServiceUrl={erpServiceUrl} telesalesCustomerNo={telesalesCustomerNo} />
}
