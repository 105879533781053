import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import Morpheus, { useMicro } from "@tm/morpheus"
import { Article, ArticleInfoType, VoucherType, OePart, IMicros, WholesalerPart } from "@tm/models"
import { Box, Tooltip, Button, Icon, Stack, Checkbox, Badge } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useOpenArticleDetails } from "@tm/utils"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import { ExtendedOrderVoucherItem } from "../../../../../../data/model"
import {
    mapOrderedItemToArticle,
    mapOrderedItemToPartToOePart,
    mapOrderedItemToWholesalerPart,
    mapVoucherPartToArticle,
    mapVoucherPartToOePart,
    mapVoucherPartToWholesalerPart,
} from "../../../../../../data/mapper"
import { getAddReturnItemRequest } from "../../../../business"
import { useAddReturnItem } from "../../../../business/hooks/useReturnItems"
import { useOrderDetails } from "../../../../business/hooks/useOrderDetails"
import { getBundleParams } from "../../../../../../utils"

type Props = {
    voucherId?: string
    voucherType: VoucherType
    voucherPart?: VoucherPart
    orderedItem?: ExtendedOrderVoucherItem
    isSelected: boolean
    costEstimationVehicleId?: string
    isBigScreen: boolean
    currencyCode?: string
    onSelectPart(id: string): void
}

export default function PartActions(props: Props) {
    const { onSelectPart, orderedItem, voucherPart, voucherType, costEstimationVehicleId, voucherId, isSelected, isBigScreen, currencyCode } = props
    const { partsDetailRoute } = getBundleParams()

    const { translateText, date } = useLocalization()
    const {
        userContext: { hasMailRetoure },
    } = useUser() ?? {}
    const { renderMicro } = useMicro<IMicros>()
    const { orderDetails } = useOrderDetails(voucherType === VoucherType.Order ? voucherId : undefined)
    const { addReturnItem } = useAddReturnItem(voucherType === VoucherType.Order ? voucherId : undefined)

    const handleOpenArticleDetails = useOpenArticleDetails({
        productGroupId:
            voucherType === VoucherType.CostEstimation ? voucherPart?.articleInformation.productGroupId : orderedItem?.articleInfo.productGroup?.id,
        supplierId:
            voucherType === VoucherType.CostEstimation ? voucherPart?.articleInformation.supplierId : orderedItem?.articleInfo.dataSupplier?.id,
        supplierArticleNumber:
            voucherType === VoucherType.CostEstimation
                ? voucherPart?.articleInformation.articleNumber
                : orderedItem?.articleInfo.dataSupplierArticleNumber,
        partsDetailsUrl: partsDetailRoute,
        openModal: Morpheus.showView,
    })

    const getBasketArticles = useCallback(() => {
        if (
            voucherType === VoucherType.CostEstimation &&
            voucherPart?.articleInformation.supplierId &&
            voucherPart.articleInformation.productGroupId
        ) {
            return [mapVoucherPartToArticle(voucherPart)]
        }

        if (voucherType === VoucherType.Order && orderedItem?.articleInfo.dataSupplier && orderedItem.articleInfo.productGroup) {
            return [mapOrderedItemToArticle(orderedItem)]
        }

        return []
    }, [voucherType, voucherPart, orderedItem])

    const getWholesalerPart = useCallback(() => {
        if (voucherType === VoucherType.CostEstimation && voucherPart) {
            return mapVoucherPartToWholesalerPart(voucherPart)
        }

        if (voucherType === VoucherType.Order && orderedItem) {
            return mapOrderedItemToWholesalerPart(orderedItem)
        }
    }, [voucherType, voucherPart, orderedItem])

    const getOePart = useCallback(() => {
        if (voucherType === VoucherType.CostEstimation && voucherPart) {
            return mapVoucherPartToOePart(voucherPart, currencyCode)
        }

        if (voucherType === VoucherType.Order && orderedItem) {
            return mapOrderedItemToPartToOePart(orderedItem)
        }
    }, [voucherType, voucherPart, orderedItem])

    function getIsArticleOrderable(): boolean {
        if (
            voucherType === VoucherType.CostEstimation &&
            voucherPart &&
            voucherPart.articleInformation.articleInfoType !== ArticleInfoType.CustomArticle &&
            !voucherPart.mainPartItemId
        ) {
            return true
        }
        if (
            voucherType === VoucherType.Order &&
            orderedItem &&
            orderedItem.articleInfoType !== ArticleInfoType.CustomArticle &&
            !orderedItem.isLinkedItem
        ) {
            return true
        }
        return false
    }

    function getArticleInfoType(): ArticleInfoType {
        if (voucherType === VoucherType.CostEstimation && voucherPart) {
            return voucherPart.articleInformation.articleInfoType
        }
        if (voucherType === VoucherType.Order && orderedItem) {
            return orderedItem.articleInfoType
        }
        return ArticleInfoType.Undefined
    }

    const isArticleOrderable = getIsArticleOrderable()
    const articleInfoType = getArticleInfoType()

    function hasArticleDetails(): boolean {
        if (voucherType === VoucherType.CostEstimation && voucherPart && !voucherPart.mainPartItemId) {
            return !(
                voucherPart.articleInformation.articleInfoType === ArticleInfoType.OeArticle ||
                voucherPart.articleInformation.articleInfoType === ArticleInfoType.CustomArticle
            )
        }

        if (orderedItem) {
            return !(orderedItem.articleInfoType === ArticleInfoType.CustomArticle || orderedItem.articleInfoType === ArticleInfoType.OeArticle)
        }

        return false
    }

    function getVehicleId(): string | undefined {
        if (orderedItem && orderedItem.vehicle) {
            return orderedItem.vehicle.id
        }

        if (voucherPart && costEstimationVehicleId) {
            return costEstimationVehicleId
        }
    }

    function handlePartCheck() {
        if (voucherType === VoucherType.CostEstimation && voucherPart) {
            onSelectPart(voucherPart.partItemId)
        }

        if (orderedItem) {
            onSelectPart(orderedItem.id)
        }
    }

    function handleReturnItemClick() {
        if (orderedItem) {
            const request = getAddReturnItemRequest(
                orderedItem,
                VoucherType.Order,
                voucherId,
                orderDetails?.orderDate,
                orderDetails?.wholesalerOrderNumber
            )
            if (request) {
                addReturnItem(request)
            }
        }
    }

    function renderBasketMicros() {
        if (articleInfoType === ArticleInfoType.WholesalerArticle) {
            const wholesalerPart = getWholesalerPart()

            if (wholesalerPart) {
                return renderMicro("basket", "add-to-basket-wholesaler-part", {
                    part: wholesalerPart.part as WholesalerPart,
                    hideQuantityField: true,
                    disabled: !isArticleOrderable,
                    generatedArticleId: wholesalerPart.id,
                })
            }
        } else if (articleInfoType === ArticleInfoType.OeArticle) {
            return renderMicro("basket", "add-to-basket-oe-part", {
                part: getOePart() as OePart,
                hideQuantityField: true,
                disabled: !isArticleOrderable,
            })
        } else {
            return renderMicro("basket", "add-to-basket", {
                data: getBasketArticles() as Article[],
                hideQuantityField: true,
                disabled: !isArticleOrderable,
                articleInfoType,
                vehicleId: getVehicleId(),
            })
        }
    }

    function renderReturnButton() {
        let returnText = translateText(13369)
        const returnButton = (
            <Button
                disabled={!orderedItem?.canReturn}
                startIcon={<Icon name="return" />}
                title={translateText(13167)}
                variant="bordered"
                onClick={handleReturnItemClick}
            />
        )

        // If returnInfo is set, the item is allready returned
        if (orderedItem?.returnInfo?.returnNumber && !orderedItem?.canReturn) {
            returnText = translateText(13436)
        }
        return (
            <Box>
                {orderedItem?.pendingReturnQuantity && (
                    <Badge
                        size="small"
                        badgeContent={orderedItem.pendingReturnQuantity.value}
                        sx={{ position: "absolute", marginLeft: "30px", marginTop: "3px" }}
                    />
                )}
                {orderedItem?.returnableUntil && !orderedItem?.pendingReturnQuantity ? (
                    <Tooltip
                        title={orderedItem?.canReturn ? translateText(13370).replace("{0}", date(orderedItem.returnableUntil, "d")) : returnText}
                    >
                        <Box>{returnButton}</Box>
                    </Tooltip>
                ) : (
                    returnButton
                )}
            </Box>
        )
    }
    return (
        <Stack direction="row" spacing={0.5}>
            {renderBasketMicros()}
            {hasMailRetoure && voucherType === VoucherType.Order && renderReturnButton()}
            <Button
                onClick={() => handleOpenArticleDetails()}
                disabled={!hasArticleDetails() || articleInfoType !== ArticleInfoType.TecdocArticle}
                startIcon={!isBigScreen ? <Icon name="details" /> : undefined}
            >
                {isBigScreen && translateText(43)}
            </Button>
            <Checkbox
                // Currently the add-to-basket micro only supports a list of a single artcileInfoType
                disabled={articleInfoType !== ArticleInfoType.TecdocArticle}
                checked={isSelected}
                onChange={handlePartCheck}
            />
        </Stack>
    )
}
