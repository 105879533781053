import { RepairShops } from "@tm/data"
import { channel, UserContext, Vehicle } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { SearchTreeNode } from "../../../data"

const formatRepairtimes = (breadcrumbs: Array<SearchTreeNode>) => {
    if (!breadcrumbs) {
        return
    }

    const repairtimes = []
    for (let i = 0; i < breadcrumbs.length; i++) {
        repairtimes.push(`ID: ${breadcrumbs[i].id} | Name: ${breadcrumbs[i].name} | Nummer: ${breadcrumbs[i].repairTimesNo}`)
    }
    return repairtimes.join("\n")
}

const formatEmail = (breadcrumbs: Array<SearchTreeNode>, text: string, vehicleState?: Vehicle, userContextState?: UserContext) => {
    if (!vehicleState || !userContextState?.generalContact || !userContextState.account || !userContextState.principal || !text) {
        return
    }

    let vehicleMailBody = ["FAHRZEUGDATEN: \n"]
    let userContextBody = ["KUNDENDATEN: \n"]

    vehicleMailBody.push(
        `Model: ${vehicleState.modelSeries}`,
        `TecDoc: ${vehicleState.tecDocTypeId.toString()}`,
        vehicleState.vin ? `VIN: ${vehicleState.vin}` : "",
        vehicleState.engineCode ? `Motorcode: ${vehicleState.engineCode}` : "",
        vehicleState.initialRegistration ? `Erstzulassung: ${vehicleState.initialRegistration.toLocaleDateString()}` : "",
        "\n"
    )

    userContextBody.push(
        userContextState.generalContact.email ? `Mail: ${userContextState.generalContact.email}` : "",
        userContextState.generalContact.name ? `Name: ${userContextState.generalContact.name}` : "",
        userContextState.generalContact.company ? `Firma: ${userContextState.generalContact.company}` : "",
        userContextState.generalContact.phone ? `Telefon: ${userContextState.generalContact.phone}` : "",
        `Kundennummer: ${userContextState.account.customerNo}`,
        `KatalogID: ${userContextState.principal.traderId}`,
        "\n"
    )

    vehicleMailBody = vehicleMailBody.filter((item) => item !== "")
    userContextBody = userContextBody.filter((item) => item !== "")

    const formattedRepairTimes = formatRepairtimes(breadcrumbs)

    const formattedMailBody = `Zu dem Zeitpunkt gewählte Arbeitswerte: \n${formattedRepairTimes}\n${vehicleMailBody.join(
        "\n"
    )}\n${userContextBody.join("\n")}\nNACHRICHT: \n${text}`

    return formattedMailBody
}

export function handleSendMail(
    successText: string,
    errorText: string,
    breadcrumbs: Array<SearchTreeNode>,
    text: string,
    vehicleState?: Vehicle,
    userContextState?: UserContext
) {
    const mailBody = formatEmail(breadcrumbs, text, vehicleState, userContextState)
    const emailRecipient = getBundleParams().missingRepairTimesEmailRecipient

    if (mailBody && emailRecipient && userContextState?.account) {
        const request: RepairShops.SendEmailRequest = {
            emails: [emailRecipient],
            body: mailBody,
            subject: `Arbeitswertanfrage NEXT Kundennummer: ${userContextState.account.customerNo}`,
        }

        RepairShops.sendEmail(request)
            .then(() => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: successText,
                    skin: "success",
                    icon: "check",
                })
            })
            .catch(() => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: errorText,
                    skin: "warning",
                    icon: "warning",
                })
            })
    }
}
