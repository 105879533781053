import { useLocalization } from "@tm/localization"
import { CostEstimationOptions } from "@tm/models"
import { CostEstimationCalculation } from "../../../../data/model"
import Total from "../Total"

type Props = {
    costEstimationCalculation?: CostEstimationCalculation
    costEstimationOptions?: CostEstimationOptions
    currencyCode: string
}

export default function Totals({ costEstimationCalculation, currencyCode, costEstimationOptions }: Props) {
    const { translateText } = useLocalization()
    const { customerGroupMode, priceVatMode } = costEstimationOptions || {}
    const { totalsB2B, totalsB2C } = costEstimationCalculation || {}

    if (!costEstimationCalculation && !costEstimationOptions) {
        return null
    }

    if (customerGroupMode) {
        return priceVatMode ? (
            // B2B Gross
            <Total currencyCode={currencyCode} label={translateText(13668)} value={totalsB2B?.offerPrice} />
        ) : (
            // B2B Net
            <Total currencyCode={currencyCode} label={translateText(694)} value={totalsB2B?.offerPrice} />
        )
    }

    return priceVatMode ? (
        // B2C Gross
        <Total currencyCode={currencyCode} label={translateText(13668)} value={totalsB2C?.offerPriceVatIncluded} />
    ) : (
        // B2C Net
        <>
            <Total currencyCode={currencyCode} label={translateText(694)} value={totalsB2C?.offerPriceVatExcluded} />
            <Total currencyCode={currencyCode} label={translateText(13668)} value={totalsB2C?.offerPriceVatIncluded} />
        </>
    )
}
