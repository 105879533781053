import { CisCustomerResponse } from "@tm/models"

export function mapCustomer(data: any): CisCustomerResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        companyName: data.companyName,
        customerName: data.companyNameAddition1,
        account: data.account,
        addresses: data.addresses || [],
        shippingTypes: data.shippingTypes || [],
        notes: data.notes || [],
        salesOutlets: data.salesOutlets || [],
    }
}
