import { CheckHasMemoRequest, CheckHasMemoResponse } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getServiceUrl } from "../params"

export async function checkHasMemo(manufacturerName: string, tecDocTypeId: number, engineCode?: string): Promise<CheckHasMemoResponse> {
    const body: CheckHasMemoRequest = {
        manufacturerName,
        modelId: tecDocTypeId,
        engineCode
    }

    const url = `${getServiceUrl()}/CheckHasMemo`
    const authorization = getStoredAuthorization()

    return ajax<CheckHasMemoResponse>({ url, body, authorization, method: "GET" }).then(response => response!)
}