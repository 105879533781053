import { DefaultDialog as Dialog, DialogTitle } from "@tm/components"
import { useLocalization } from "@tm/localization"
import CustomerStart from "./customer-start"
import { WrapperProps, OpenWorkTaskDialogState } from "../index"

type Props = WrapperProps & {
    state: OpenWorkTaskDialogState
    onClose(): void
}

/**
 * Diagram:
 * https://confluence.dvse.de/display/PROM/Chat+API+and+Integration+in+Next#ChatAPIandIntegrationinNext-WorktaskcreationwithVehicle/CustomerSelection
 */
export function OpenWorkTaskDialog({ chatId, tmCustomerId, customerInfo, state, onClose }: Props) {
    const { translateText } = useLocalization()

    return (
        <Dialog open onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>{translateText(2014)}</DialogTitle>
            <CustomerStart chatId={chatId} tmCustomerId={tmCustomerId} customerInfo={customerInfo} openOptions={state} />
        </Dialog>
    )
}
