
import { BundleMicro } from "@tm/morpheus"
import component from "./component"

const addItemsRtToRdBasket: BundleMicro = {
    name: "rd-add-articles-rt-to-basket",
    component
}

export default addItemsRtToRdBasket
