import { getStoredAuthorization, ajax } from "@tm/utils"
import { UserContext } from "@tm/models"
import { getUrlTags } from "@tm/context-distribution"
import { getBundleParams } from "../../utils"

function getServiceUrl() {
    return `${getBundleParams().authorityServiceUrl}/Url`
}

export function getExternalCatalogUrl(
    externalSystemId: number,
    telesalesCustomerNo: string | undefined,
    userContext: UserContext,
    forceRequest?: boolean
): Promise<string> {
    const externalSystem = userContext?.externalModules?.find((ex) => ex.id == externalSystemId)

    // determine if any tag which is not replaced in the frontend is included in the url
    const urlTags = getUrlTags().map((tag) => `{#${tag}}`)
    const matches = externalSystem?.replacedUrl?.match(/{#.+?}/g)

    if (forceRequest || (matches != null && matches.filter((tag) => !urlTags?.some((m) => m === tag))?.length > 0)) {
        const url = `${getServiceUrl()}/GetExternalCatalogUrl`
        const authorization = getStoredAuthorization()
        const body = { externalSystemId, telesalesCustomerNo }

        return new Promise<string>((resolve, reject) =>
            ajax({ url, authorization, body, method: "GET" }).then((response) => resolve(response && response.url), reject)
        )
    }
    return Promise.resolve(externalSystem?.replacedUrl || "")
}

export type GetConceptPageUrlResponse = {
    url: string
    logo: string
    hasCredentials: boolean
}
export function getConceptPageUrl(): Promise<GetConceptPageUrlResponse> {
    const url = `${getServiceUrl()}/GetConceptPageUrl`
    const authorization = getStoredAuthorization()
    const body = {}

    return new Promise<GetConceptPageUrlResponse>((resolve, reject) =>
        ajax({ url, authorization, body, method: "GET" }).then((response) => resolve(response), reject)
    )
}
