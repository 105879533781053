import { useMemo, useCallback, useEffect, ReactNode } from "react"
import { useLocalization } from "@tm/localization"
import { Dictionary } from "@tm/morpheus"
import { TabControl, Dropdown } from "@tm/controls"
import { channel } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { encodeUniqueId, renderRoute, uniqueId, useMultiWidgetState } from "@tm/utils"
import { Box, Icon, Typography, useTheme } from "@tm/components"
import { useHistory, useParams } from "react-router"

export type ItemData = {
    //
    text: string
    key: string
    sort?: number
    path?: string
}

type TabControlLayout = "bordered" | "bordered-bottom" | "round-border-bottom"

type Props = {
    storeId?: string
    items: Dictionary<ItemData>
    showAsDropdown?: boolean
    hideItems?: Dictionary<boolean>
    layout?: TabControlLayout
    headline?: string
    selectedItem?: string
}

const vehiclePartsKey = "VEHICLE_PARTS"
const predictiveKey = "PARTS_INDICATOR"

function TabControlComponent(props: Props) {
    const { translateText, isTranslationId } = useLocalization()
    const { workTask, workTaskId } = useWorkTask() || {}
    const { items, layout, hideItems, showAsDropdown, storeId, headline } = props
    const history = useHistory()
    const matchParams = useParams()
    const theme = useTheme()

    const [selectedItemState, setSelectedItemState] = useMultiWidgetState({ storeId })

    const existingVehicleId = workTask?.vehicle?.id

    const options = useMemo(() => {
        if (!items) {
            return []
        }

        return Object.values(items)
            .filter((x) => !!x && !!x.text && !(hideItems && Object.keys(hideItems).includes(x.key)))

            .orderBy((x) => x.sort || 0)
    }, [items, hideItems])

    const initialItem = useMemo(() => {
        if (props.selectedItem) {
            const optionsKey = options.find((item) => item.key === props.selectedItem)?.key
            if (optionsKey) {
                return optionsKey
            }
        }
        return options[0].key
    }, [props.selectedItem, options])

    const isDisabled = useCallback(
        (key: string) => {
            return key === vehiclePartsKey && !workTask?.vehicle
        },
        [workTask?.vehicle]
    )

    const onSelectionChange = (key: string) => {
        const tempItem = options.find((item) => item.key === key)
        if (key === "EXTERN" || tempItem?.path) {
            // Open External moduul by magic!
            setSelectedItemState(initialItem)
            if (tempItem?.path) {
                const workTaskId = workTask?.id || uniqueId()
                const url = renderRoute(tempItem?.path, { ...matchParams, workTaskId: encodeUniqueId(workTaskId) })
                history.push(url)
            }
        } else {
            setSelectedItemState(key)
        }
    }

    useEffect(() => {
        // if the selectedItem is disabled open the initial item
        if (isDisabled(selectedItemState) && selectedItemState !== initialItem) {
            setSelectedItemState(initialItem)
        }
    }, [selectedItemState, isDisabled, initialItem])

    useEffect(() => {
        if (workTaskId) {
            if (existingVehicleId && options.some((option) => option.key === vehiclePartsKey)) {
                setSelectedItemState(vehiclePartsKey)
            } else {
                setSelectedItemState(initialItem)
            }
        }
        return channel("WORKTASK", workTaskId).subscribe("PARTS/SHOW_PARTS_INDICATOR", () => {
            if (options.some((item) => item.key === predictiveKey)) {
                setSelectedItemState(predictiveKey)
            }
        })
    }, [options, workTaskId, existingVehicleId])

    useEffect(() => {
        if (options) {
            setSelectedItemState(initialItem)
        }
        return channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (["UNI", "DIRECT"].includes(rez.key) && options.some((option) => option.key === rez.key)) {
                setSelectedItemState(rez.key)
            }
        })
    }, [options])

    function renderAsDropdown() {
        return (
            <Dropdown
                items={options}
                itemView={DropdownView}
                value={options.find((option) => option.key === selectedItemState)}
                onChange={(option) => onSelectionChange(option.key)}
            />
        )
    }

    function withHeadline(children: ReactNode) {
        return (
            <Box>
                <Typography variant="h1">Suche</Typography>
                {children}
            </Box>
        )
    }

    function renderTabControl() {
        return (
            <TabControl
                layout={layout}
                className="bnd-misc"
                selectedTabIdentifier={selectedItemState}
                onTabSelect={onSelectionChange}
                key={`${storeId}_multiControl`}
            >
                {options.map((item) => (
                    <TabControl.Tab
                        disabled={isDisabled(item.key)}
                        key={item.key}
                        identifier={item.key}
                        className={`tab--${item.key.toLowerCase()}`}
                        style={theme.radius?.default ? { borderRadius: theme.radius.default } : {}}
                    >
                        {isTranslationId(item.text) ? translateText(item.text) : item.text}{" "}
                        {item.key === "EXTERN" && <Icon sx={{ ml: 1 }} name="external-link" />}
                    </TabControl.Tab>
                ))}
            </TabControl>
        )
    }

    const content = showAsDropdown ? renderAsDropdown() : renderTabControl()

    return headline ? withHeadline(content) : content
}

export default TabControlComponent

function DropdownView(option: ItemData) {
    const { translateText, isTranslationId } = useLocalization()
    const text = isTranslationId(option.text) ? translateText(option.text) : option.text
    return <>{text}</>
}
