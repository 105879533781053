import { Stack } from "@tm/components"
import BigScreenPrice from "./BigScreenPrice"
import { PricesProps } from "."

export default function BigScreenPrices(props: PricesProps) {
    const {
        showPurchasePrice,
        theme,
        quantity,
        showTotalPrices,
        purchasePriceLabel,
        showRetailPrice,
        retailPriceLabel,
        totalPurchasePriceLabel,
        totalRetailPriceLabel,
        totalRecommendedPriceLabel,
        totalErpPurchasePrice,
        totalErpRecommendedPrice,
        totalErpRetailPrice,
        erpPurchasePrice,
        erpRetailPrice,
        calculatedPurchasePrice,
        calculatedRetailPrice,
        totalCalculatedPurchasePrice,
        totalCalculatedRetailPrice,
        totalCalculatedRecommendedPrice,
    } = props

    return (
        <Stack direction="row" spacing={4} justifyContent="flex-end">
            {/* unit price */}
            {showPurchasePrice && (calculatedPurchasePrice?.value || erpPurchasePrice?.value) && (
                <BigScreenPrice
                    calculatedPrice={calculatedPurchasePrice}
                    erpPrice={erpPurchasePrice}
                    priceLabel={purchasePriceLabel}
                    primaryColor
                    quantity={quantity}
                    theme={theme}
                    showPricePerUnitLabel
                />
            )}
            {showRetailPrice && (calculatedRetailPrice?.value || erpRetailPrice?.value) && (
                <BigScreenPrice
                    calculatedPrice={calculatedRetailPrice}
                    erpPrice={erpRetailPrice}
                    priceLabel={retailPriceLabel}
                    quantity={quantity}
                    theme={theme}
                    showPricePerUnitLabel
                />
            )}

            {/* total purchase price */}
            {showTotalPrices && showPurchasePrice && (totalCalculatedPurchasePrice?.value || totalErpPurchasePrice?.value) && (
                <BigScreenPrice
                    calculatedPrice={totalCalculatedPurchasePrice}
                    erpPrice={totalErpPurchasePrice}
                    priceLabel={totalPurchasePriceLabel}
                    primaryColor
                    quantity={quantity}
                    theme={theme}
                />
            )}

            {/* total retail price */}
            {showTotalPrices && (totalCalculatedRetailPrice?.value || totalErpRetailPrice?.value) && (
                <BigScreenPrice
                    calculatedPrice={totalCalculatedRetailPrice}
                    erpPrice={totalErpRetailPrice}
                    priceLabel={totalRetailPriceLabel}
                    quantity={quantity}
                    theme={theme}
                />
            )}
            {showTotalPrices && !totalCalculatedRetailPrice?.value && (totalCalculatedRecommendedPrice?.value || totalErpRecommendedPrice?.value) && (
                <BigScreenPrice
                    calculatedPrice={totalCalculatedRecommendedPrice}
                    erpPrice={totalErpRecommendedPrice}
                    priceLabel={totalRecommendedPriceLabel}
                    quantity={quantity}
                    theme={theme}
                />
            )}
        </Stack>
    )
}
