import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { Grid, Stack, Button, Icon } from "@tm/components"
import { ConfirmationDisplay, OrderDetails, Voucher } from "../../../../data/model"
import { printVoucherDetails } from "../../business/helpers"
import { getBundleParams } from "../../../../utils"
import DocumentButtons from "../../../_shared/buttons/DocumentButtons"
import { OrderDetailsField } from "../../../_shared/OrderDetailsField"
import SendVoucherConfirmationButton from "../../../_shared/buttons/SendVoucherConfirmationButton"

type Props = {
    voucher: Voucher
    orderDetails: OrderDetails
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function StandardVoucherDetailsHeaderComponent({ voucher, orderDetails, onOpenNewVoucher }: Props) {
    const { voucherDocuments, voucherItems } = orderDetails
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { date, currency, translateText } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    const showVoucherConfirmationButton = useMemo(() => {
        return (
            voucher.confirmationOptions?.canBeConfirmed &&
            (voucher.confirmationOptions.display === ConfirmationDisplay.ShowOnDetails ||
                voucher.confirmationOptions.display === ConfirmationDisplay.ShowOnListAndDetails)
        )
    }, [voucher.confirmationOptions])

    function handlePrintButtonClick() {
        if (voucher && voucher.voucherId) {
            const bundleParams = getBundleParams()
            printVoucherDetails(voucher, bundleParams.printing, translateText, telesalesCustomerNo)
        }
    }

    return (
        <Stack direction="column" alignItems="flex-end">
            <Stack spacing={0.5} direction="row" textAlign="center" justifyContent="center" alignItems="center">
                {showVoucherConfirmationButton && (
                    <SendVoucherConfirmationButton voucher={voucher} voucherItems={voucherItems} onOpenNewVoucher={onOpenNewVoucher} />
                )}
                {voucherDocuments?.length ? (
                    <DocumentButtons voucherDocuments={voucherDocuments} />
                ) : (
                    <Button startIcon={<Icon name="print" />} color="primary" onClick={handlePrintButtonClick} title={translateText(13164)} />
                )}
            </Stack>
            <Grid container rowSpacing={1} columnSpacing={2} my={1} columns={4}>
                {/* First Row */}
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.voucherId} label={translateText(470)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.voucherDate && date(voucher.voucherDate, "d")} label={translateText(471)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.deliveryType} label={translateText(40)} />
                </Grid>
                <Grid item xs={1}>
                    {!hidePurchasePrice && <OrderDetailsField value={currency(voucher.totalNet, voucher.currencyCode)} label={translateText(474)} />}
                </Grid>
                {/* Second Row */}
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.itemsCount} label={translateText(473)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.information} label={translateText(465)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.paymentInformation} label={translateText(440)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={currency(voucher.totalGross, voucher.currencyCode)} label={translateText(472)} />
                </Grid>
            </Grid>
        </Stack>
    )
}
