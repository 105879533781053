import { Box, Button, Dialog, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { getCurrentWorkTaskId, channel } from "@tm/models"
import { renderRoute } from "@tm/utils"
import { useHistory, useParams } from "react-router"

export default function UserCredentialsModal() {
    const { translateText } = useLocalization()
    const history = useHistory()
    const params = useParams()

    function handleModuleClose() {
        closeModule()
        history.push(renderRoute("/:workTaskId", { ...params }))
    }

    function redirectToUserSettings() {
        closeModule()
        // second part of the url is the scrollTo value
        history.push("/usersettings/credential-settings")
    }

    function closeModule() {
        const workTaskId = getCurrentWorkTaskId()
        if (workTaskId) {
            channel("WORKTASK", workTaskId).publish("MODULE/CLOSED", location.pathname + location.search)
        }
    }

    return (
        <Dialog onOutsideClick={() => handleModuleClose()} open position="top" skin="info">
            <Box p={3}>
                <Typography paddingBottom={3} variant="h2">
                    {translateText(1196)}
                </Typography>
                <Typography paddingBottom={2}>{translateText(13453)}</Typography>
                <Button onClick={() => redirectToUserSettings()} size="large" color="primary" value={translateText(13110)} />
            </Box>
        </Dialog>
    )
}
