import { useWorkTask } from "@tm/context-distribution"
import { Box, Typography, Loader, Alert } from "@tm/components"
import { useLocalization } from "@tm/localization"
import morpheus, { useMicro } from "@tm/morpheus"
import {
    CarModel,
    channel,
    IMicros,
    LookupConflictReason,
    RegisteredModels,
    RegistrationNoType,
    ShowCarModelDetailsResponse,
    Vehicle,
    VehicleType,
    ViewStateContainer,
} from "@tm/models"
import { Container } from "@tm/nexus"
import {
    createQueryString,
    equals,
    parseQueryString,
    renderRoute,
    TmaHelper,
    useCheckHasMemo,
    isLicensePlateType,
    isVinType,
    isValidVin,
} from "@tm/utils"
import { useEffect, useMemo, useState } from "react"

import VrmLookupDetailsComponent from "../_shared/VrmLookupDetailsComponent"
import { bundleChannel, checkAndMergeRegNoDetails, createVehicleFromModel, deleteImportedVehicleDetails } from "../../business"
import { DetailsError } from "../../data/hooks/modelDetailsWithoutCache"
import { OrderedPartByVehicle } from "../../data/model/vouchers"
import { VRMAAA_STATE_KEY, VRMAAA_STATE_TYPE } from "../../data/model/vrm-lookup"
import { handleDATRegistration, isSpecialRegNoType, UnknownVinTextId, VrmLookupErrorTextIds } from "../../helpers"
import { vehicleDetailsSubPageDatInfo, vehicleDetailsSubPageMemoInfo, vehicleDetailsSubPageMemoTechnicalNotes } from "../../helpers/routing"
import { getBundleParams } from "../../utils"
import { VehicleParams } from "../search/component"
import { DetailsProps } from "./component"
import { ConflictDialog } from "./components/ConflictDialog"
import ImageView from "./components/ImageView"
import Navigation from "./components/Navigation"
import PersonalData from "./components/PersonalData"
import Selections from "./components/Selections"
import TecDocDetails from "./components/TecDocDetails"
import { useSelectedVehicleLookup } from "../../data/hooks/useSelectedLookupConfig"
import { useRegNoAndType } from "./hooks/useRegNoAndType"

type Props = DetailsProps & {
    vehicleType: VehicleType
    vehicle?: Vehicle
    modelDetails?: ShowCarModelDetailsResponse
    error?: DetailsError
    isWorkTaskVehicle?: boolean
    isGsiVehicle?: boolean
    orderedParts?: OrderedPartByVehicle[]
    reload(force?: boolean, overwriteRegNo?: string, overwriteRegNoType?: RegistrationNoType): void
    clearVehicleConflict(): void
}

export default function DetailsComponent(props: Props) {
    const {
        modelDetails,
        availableVehicleSearches,
        match,
        location,
        actions,
        history,
        error,
        isWorkTaskVehicle,
        isGsiVehicle,
        orderedParts,
        vehicleType,
        tabs,
        showServiceBook,
        vehicle,
    } = props

    const { renderMicro } = useMicro<IMicros>()
    const { translateText, translate } = useLocalization()
    const { workTask, workTaskId, attachToWorkTask } = useWorkTask() ?? {}

    const [vrmAAALastLookupDate, setVrmAAALastLookupDate] = useState<Date>()
    const [currentVehicle, setCurrentVehicle] = useState(vehicle)

    const hasDAT = !!getBundleParams().enableDATVinSearch
    const hasAAA = !!getBundleParams().canRequestAAAVrmInfo
    const memoToolEnabled = !!getBundleParams().memoToolEnabled

    const { checkHasMemo } = useCheckHasMemo(memoToolEnabled, vehicle?.manufacturer, vehicle?.tecDocTypeId, vehicle?.engineCode)

    let { regNoType } = useRegNoAndType(vehicleType, currentVehicle, location.search, availableVehicleSearches?.defaultVehicleLookup)
    if (isSpecialRegNoType(regNoType)) {
        regNoType = availableVehicleSearches?.defaultVehicleLookup?.lookupTypeId
    }

    const {
        selectedVehicleLookup: { countryCode },
    } = useSelectedVehicleLookup(currentVehicle?.vehicleType ?? vehicleType)

    const datVinErrorTextId = useMemo(() => {
        const temp = new URLSearchParams(location.search).get("datVinErrorTextId")
        return (temp && parseInt(temp)) || undefined
    }, [location.search])

    useEffect(() => {
        setCurrentVehicle(() => (vehicle && !vehicle.countryCode ? { ...vehicle, countryCode } : vehicle))
    }, [vehicle])

    useEffect(() => {
        if (currentVehicle) {
            actions.setVehicleForPrint(currentVehicle)
        }
    }, [currentVehicle])

    useEffect(() => {
        if (modelDetails?.modelDetails) {
            actions.setModelImage(modelDetails?.modelDetails?.modelSeriesImage)
        }

        if (modelDetails?.modelDetails?.registrationNoDetails?.datECode?.length) {
            actions.setHasDatInfo(true)
        } else {
            actions.setHasDatInfo(false)
        }
    }, [modelDetails])

    useEffect(() => {
        if (vehicle?.customerId) {
            bundleChannel().publish("DETAILS_VEHICLE_CUSTOMERID_CHANGED", { customerId: vehicle.customerId })

            return () => bundleChannel().publish("DETAILS_VEHICLE_CUSTOMERID_CHANGED", {})
        }
    }, [vehicle?.customerId])

    function handleRequestVrmData(dataProvider: RegistrationNoType) {
        if (!currentVehicle) {
            return
        }

        props.clearVehicleConflict()

        switch (dataProvider) {
            case RegistrationNoType.VrmAAA:
                if (currentVehicle.registrationNo) {
                    props.reload(true, currentVehicle.registrationNo, dataProvider)

                    const lastUpdated = new Date()
                    const viewStateContainer: ViewStateContainer = Container.getInstance(RegisteredModels.ViewState)
                    const vrmAAAState: VRMAAA_STATE_TYPE = { lastUpdated: lastUpdated.toISOString() }
                    viewStateContainer.action("saveViewState")({ key: VRMAAA_STATE_KEY, value: vrmAAAState })
                    setVrmAAALastLookupDate(lastUpdated)
                }
                break
            case RegistrationNoType.DatVin:
                if (currentVehicle.vin) {
                    props.reload(true, currentVehicle.vin, dataProvider)

                    if (datVinErrorTextId) {
                        const queryParams = parseQueryString(location.search)
                        delete queryParams.datVinErrorTextId
                        history.replace(location.pathname + createQueryString(queryParams))
                    }
                }
                break
            default:
                break
        }
    }

    useEffect(() => {
        // TODO: currently would also open for other vrm lookup types then DAT
        if (error?.text === VrmLookupErrorTextIds.UserNotRegistered) {
            const { datRegistrationPath } = getBundleParams()
            if (datRegistrationPath) {
                // User is not already registered at DAT

                const datRegistrationRoute = renderRoute(datRegistrationPath, match.params)

                handleDATRegistration(datRegistrationRoute).then(() => handleRequestVrmData(RegistrationNoType.DatVin))
            }
        }
    }, [error])

    const shouldCheckAndMergeRegNoDetails = useMemo(() => {
        return new URLSearchParams(location.search).get(VehicleParams.CheckAndMergeRegNoDetails) === "true"
    }, [location.search])

    useEffect(() => {
        if (!currentVehicle) {
            return
        }

        return bundleChannel().subscribe("APPLY_BUTTON_CLICKED", () => {
            let promise: Promise<Vehicle | string> = Promise.resolve(currentVehicle)

            if (shouldCheckAndMergeRegNoDetails) {
                promise = new Promise((resolve) => {
                    const { registrationNo, registrationTypeId: registrationNoTypeId } = currentVehicle

                    const isPlate = isLicensePlateType(registrationNoTypeId, registrationNo)
                    const isVin = isVinType(registrationNoTypeId, registrationNo)

                    if (!registrationNo || !registrationNoTypeId || (!isPlate && !isVin)) {
                        resolve(currentVehicle)
                        return
                    }

                    const {
                        initialRegistration,
                        plateId = isPlate ? registrationNo : undefined,
                        vin = isVin ? registrationNo : undefined,
                    } = currentVehicle

                    checkAndMergeRegNoDetails({
                        modelId: currentVehicle.tecDocTypeId.toString(),
                        registrationNo,
                        registrationNoTypeId,
                        initialRegistration,
                        plateId,
                        vin,
                        countryCode,
                    }).then(
                        (vehicleId) => {
                            if (vehicleId) {
                                resolve(vehicleId)
                            } else {
                                resolve(currentVehicle)
                            }
                        },
                        () => resolve(currentVehicle)
                    )
                })
            }

            promise.then((vehicle) => {
                attachToWorkTask?.({ vehicle }).then(() => {
                    deleteImportedVehicleDetails()

                    if (!isWorkTaskVehicle) {
                        const routeToPush = renderRoute("/:workTaskId", match.params)

                        if (morpheus.anyOpenView()) {
                            morpheus.getHistory().push(routeToPush)
                        } else {
                            history.push(routeToPush)
                        }

                        TmaHelper.VehicleSelection.List.Search({
                            dataSourceId: isGsiVehicle || typeof vehicle === "string" ? RegistrationNoType.VehicleBase : vehicle.registrationTypeId,
                            query: new URLSearchParams(location.search).get(VehicleParams.SearchQuery) || undefined,
                        })
                    }
                })
            })
        })
    }, [currentVehicle, attachToWorkTask, isWorkTaskVehicle, history, match.params, shouldCheckAndMergeRegNoDetails])

    useEffect(() => {
        if (!equals(currentVehicle, workTask?.vehicle) && isValidVin(currentVehicle)) {
            bundleChannel().publish("SET_APPLY_BUTTON_STATUS", { disabled: false })
        }
        return () => bundleChannel().publish("SET_APPLY_BUTTON_STATUS", { disabled: true })
    }, [currentVehicle, workTask?.vehicle])

    useEffect(() => {
        if (tabs.orderedArticles && orderedParts) {
            actions.setHasOrderedParts(true)
        } else {
            actions.setHasOrderedParts(false)
        }
    }, [orderedParts, tabs])

    const modifiedTabs = useMemo(() => {
        const modifiedTabs = { ...tabs }

        if (hasDAT) {
            modifiedTabs[vehicleDetailsSubPageDatInfo] = { path: vehicleDetailsSubPageDatInfo, text: "{{1267}}", sort: 5 }
        }

        if (hasAAA) {
            modifiedTabs.vrmInfo = { path: "vrmInfo", text: "{{1397}}", sort: 6 }
        }

        if (
            modelDetails?.modelDetails?.registrationNoDetails?.additionalDetails?.length &&
            currentVehicle &&
            currentVehicle?.registrationTypeId != RegistrationNoType.DatVin
        ) {
            modifiedTabs.additionalDetails = { path: "additionalDetails", text: "{{1397}}", sort: 7 }
        }

        if (modifiedTabs.dmsVoucherHistory) {
            const dmsName = (window as any).__dmsInfo?.name ?? JSON.parse(localStorage.getItem("DMS_INFO") ?? "{}")?.name
            if (dmsName) {
                modifiedTabs.dmsVoucherHistory.text = `{{569}} {{13121}} ${dmsName}`
            }
        }
        if (memoToolEnabled) {
            modifiedTabs[vehicleDetailsSubPageMemoInfo] = {
                path: vehicleDetailsSubPageMemoInfo,
                text: "{{465}}",
                sort: 8,
                disabled: !checkHasMemo?.hasMemoInfo,
                icon: "atu-logo",
            }

            modifiedTabs[vehicleDetailsSubPageMemoTechnicalNotes] = {
                path: vehicleDetailsSubPageMemoTechnicalNotes,
                text: "{{13026}}",
                sort: 9,
                disabled: !checkHasMemo?.hasTechnicalNotes,
                icon: "atu-logo",
            }
        }

        return modifiedTabs
    }, [
        tabs,
        currentVehicle,
        modelDetails?.modelDetails?.registrationNoDetails?.additionalDetails?.length,
        currentVehicle?.registrationTypeId,
        hasAAA,
        hasDAT,
        checkHasMemo?.hasMemoInfo,
        checkHasMemo?.hasTechnicalNotes,
    ])

    function showPartsIndicator() {
        history.push(renderRoute("/:workTaskId", match.params))
        window.setTimeout(() => {
            channel("WORKTASK", workTaskId).publish("PARTS/SHOW_PARTS_INDICATOR", undefined)
        }, 25)
    }

    if (error?.type === "general") {
        return <Alert variant="filled" severity="error" title={typeof error.text === "number" ? translateText(error.text) : error.text} />
    }

    if (!currentVehicle || !modelDetails?.modelDetails || !modelDetails.manufacturer || !modelDetails.modelSeries) {
        return <Loader />
    }

    const renderVrmLookupDetails = (dataProvider: RegistrationNoType) => {
        let errorMessage
        let showRequestVinComponent = false
        if (error?.type === "registrationNoDetails") {
            errorMessage = typeof error.text === "number" ? translateText(error.text) : error.text
            showRequestVinComponent =
                dataProvider === RegistrationNoType.DatVin &&
                (error.text === UnknownVinTextId ||
                    error.text === VrmLookupErrorTextIds.NoResult ||
                    error.text === VrmLookupErrorTextIds.ManufacturerNotSupported)
        } else if (dataProvider === RegistrationNoType.DatVin && datVinErrorTextId) {
            errorMessage = translateText(datVinErrorTextId)
            showRequestVinComponent =
                datVinErrorTextId === UnknownVinTextId ||
                datVinErrorTextId === VrmLookupErrorTextIds.NoResult ||
                datVinErrorTextId === VrmLookupErrorTextIds.ManufacturerNotSupported
        }

        return (
            <VrmLookupDetailsComponent
                dataProvider={dataProvider}
                vehicle={currentVehicle}
                vrmData={modelDetails.modelDetails?.registrationNoDetails}
                lastLookupDate={dataProvider === RegistrationNoType.VrmAAA ? vrmAAALastLookupDate : undefined}
                onRequestVrmData={handleRequestVrmData}
                errorMessage={errorMessage}
                showRequestVinComponent={showRequestVinComponent}
            />
        )
    }

    const handleChangeVehicle = (model: CarModel, reuseVehicleData: boolean) => {
        switch (modelDetails?.conflictReason) {
            case LookupConflictReason.TecdocTypeNotMatch: {
                createVehicleFromModel({
                    model,
                    registrationNoTypeId: model.registrationNoTypeId || model.dataSourceId,
                    countryCode,
                    vehicleType: currentVehicle.vehicleType,
                    checkAndMergeRegNoDetails: shouldCheckAndMergeRegNoDetails,
                    /** @todo: remove and do somehow different asap */
                    requestOnlyRegNo:
                        model.dataSourceId === RegistrationNoType.DatVin ? model.registrationNoDetails?.vin ?? currentVehicle.vin : undefined,
                    ...(reuseVehicleData
                        ? {
                              plateId: currentVehicle.plateId,
                              vin: currentVehicle.vin,
                              initialRegistration: currentVehicle.initialRegistration,
                              engineCode: currentVehicle.engineCode,

                              mileAge: currentVehicle.mileAge,
                              mileageType: currentVehicle.mileType,

                              lastGeneralInspection: currentVehicle.lastGeneralInspection,
                              nextGeneralInspection: currentVehicle.nextGeneralInspection,
                              nextServiceDate: currentVehicle.nextServiceDate,

                              longlife: currentVehicle.longlife,
                              countryCode,

                              // registrationNo: vehicle.registrationNo,
                          }
                        : {}),
                }).then((vehicle) => {
                    attachToWorkTask?.({ vehicle })

                    if (!isWorkTaskVehicle) {
                        history.push(renderRoute("/:workTaskId", match.params))

                        TmaHelper.VehicleSelection.List.Search({
                            dataSourceId: typeof vehicle === "string" ? RegistrationNoType.VehicleBase : vehicle.registrationTypeId,
                            query: new URLSearchParams(location.search).get(VehicleParams.SearchQuery) || undefined,
                        })
                    }
                })

                break
            }
            default:
                break
        }

        props.clearVehicleConflict()
    }

    return (
        <Box m={2} className="tk-vehicle vehicle-details">
            {modelDetails.conflictReason && (
                <ConflictDialog
                    reason={modelDetails.conflictReason}
                    alternativeVehicles={modelDetails.alternativeModels}
                    vehicleType={vehicleType}
                    currentVehicle={vehicle}
                    onClose={props.clearVehicleConflict}
                    onSelectAlternativeVehicle={handleChangeVehicle}
                />
            )}
            <PersonalData
                vehicle={currentVehicle}
                modelDetails={modelDetails.modelDetails}
                regNoDetails={modelDetails.modelDetails?.registrationNoDetails}
                regNoType={regNoType}
                onChange={setCurrentVehicle}
                showServiceBook={showServiceBook}
                onShowPartsIndicator={showPartsIndicator}
            />
            <Typography variant="h2" mt={3}>
                {translate(321)}
            </Typography>
            <Selections modelDetails={modelDetails.modelDetails} modelSeries={modelDetails.modelSeries} manufacturer={modelDetails.manufacturer} />
            <div className="flex-wrapper">
                <ImageView modelDetails={modelDetails.modelDetails} vehicleType={currentVehicle.vehicleType} />

                <TecDocDetails
                    modelDetails={modelDetails.modelDetails}
                    registrationNumberType={regNoType}
                    vehicleType={parseInt(match.params.vehicleType)}
                    setManufacturerData={actions.setManufacturerData}
                />
            </div>
            <div className="navigation-wrapper" id="vehicle__details__vehicle-details-tabs">
                <Navigation orderedArticles={orderedParts} tabs={modifiedTabs} vehicle={currentVehicle} />
            </div>
            {match.params.vehicleDetailsSubPage === vehicleDetailsSubPageDatInfo && hasDAT && renderVrmLookupDetails(RegistrationNoType.DatVin)}
            {match.params.vehicleDetailsSubPage === "vrmInfo" && hasAAA && renderVrmLookupDetails(RegistrationNoType.VrmAAA)}
            {match.params.vehicleDetailsSubPage === "additionalDetails" && (
                <VrmLookupDetailsComponent
                    dataProvider={currentVehicle.registrationTypeId ?? RegistrationNoType.None}
                    vehicle={currentVehicle}
                    vrmData={modelDetails.modelDetails?.registrationNoDetails}
                />
            )}
            {match.params.vehicleDetailsSubPage === vehicleDetailsSubPageMemoInfo && checkHasMemo?.hasMemoInfo && (
                <div>
                    {renderMicro("memo-tool", "memo-info", {
                        isModal: false,
                        vehicleId: vehicle?.id,
                        manufacturer: vehicle?.manufacturer,
                    })}
                </div>
            )}
            {match.params.vehicleDetailsSubPage === vehicleDetailsSubPageMemoTechnicalNotes && checkHasMemo?.hasTechnicalNotes && (
                <div>
                    {renderMicro("memo-tool", "memo-technical-notes", {
                        isModal: false,
                        vehicleId: vehicle?.id,
                        modelId: vehicle?.tecDocTypeId,
                        engineCode: vehicle?.engineCode,
                    })}
                </div>
            )}
        </Box>
    )
}
