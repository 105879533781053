import { UserContext, UserModuleType } from "@tm/models"
import { createQueryString } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { useFastServiceStore } from "../../../data"
import { HaynesProModuleMode, HaynesProResetModel } from "../../../data/models"

export function getHaynesProServiceReset(userContext: UserContext, languageId: string) {
    const { haynesProLoginUrl } = getBundleParams()
    const { stateVehicle } = useFastServiceStore.getState().maintenancePlan

    if (!haynesProLoginUrl || !userContext || !stateVehicle) {
        return
    }

    const clientId = userContext?.modules?.find((x) => x && x.type === UserModuleType.Catalog)?.id

    const requestParams: HaynesProResetModel = {
        moduleMode: HaynesProModuleMode.ServiceReset,
        platformId: "4",
        languageId,
        mdmSessionId: userContext.id,
        h_session: "",
        catNr: clientId,
        clientId,
        kTypeId: stateVehicle.tecDocTypeId,
        engineCode: stateVehicle.engineCode ? encodeURIComponent(stateVehicle.engineCode) : "",
        typeArt: "1",
    }

    return `${haynesProLoginUrl}${createQueryString(requestParams)}`
}
