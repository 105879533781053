import getValue from "get-value"
import type { TMessageKeys } from "@tm/hermes"
import type { NextChannels } from "@tm/models"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import { IPlugin, ModuleConfig, PluginProps } from "@tm/morpheus"

function publish(message: Message) {
    channel("WORKTASK", getCurrentWorkTaskId()).publish(message.topic, message.content)
}

function Messenger(props: MessengerProps) {
    if (!props.module.props || !props.pluginProps.propertyPath) {
        return
    }
    const message = getValue(props.module.props, props.pluginProps.propertyPath)
    if (!message) {
        return
    }

    if (Array.isArray(message)) {
        message.forEach(publish)
        return
    }
    publish(message)
}

const plugin: IPlugin<ModuleConfig> = {
    name: "WorktaskMessageOnMount",
    type: "MODULE/MOUNT",
    plugin: Messenger,
}

export default plugin

type Message<C extends keyof NextChannels = any, M extends TMessageKeys<NextChannels, C> = any> = {
    topic: M
    content: NextChannels[C][M]
}

type MessengerProps = PluginProps<ModuleConfig, { propertyPath: string }>
