import { ChangeItemsResponse, ImportResponse, Item } from "@tm/models"
import { convertBase64ImageToByteArray, ajaxAuth } from "@tm/utils"
import { getBasketServiceUrl } from "../.."
import {
    ChangeCostEstimationResponse,
    ChangeCustomerGroupModeRequest,
    ChangeIsIncludedResponse,
    ChangePriceTypeModeRequest,
    ChangePriceVatModeRequest,
    ChangeRepairTimeCalculationRequest,
    ChangeUseNoteTextRequest,
    ImportVoucherRequest,
    SendCostEstimationRequest,
    SendCostEstimationResponse,
    ShowTotalNumbersByWorkTaskResponse,
} from "../../model"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/costEstimation`
}

// TODO: add in the request the vehicle id, so it is easier to identify if a vehicle indeed has changed for the same work task
export function attachVehicleImage(workTaskId: string, vehicleImageBase64: string) {
    const url = `${getServiceUrl()}/AttachVehicleImage`
    const body = { workTaskId, vehicleImage: convertBase64ImageToByteArray(vehicleImageBase64) }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function removeVehicleImage(workTaskId: string) {
    const url = `${getServiceUrl()}/RemoveVehicleImage`
    const body = { workTaskId }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function resetCostEstimation(workTaskId: string) {
    const url = `${getServiceUrl()}/ResetCostEstimation `
    const body = { workTaskId }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function showTotalNumbersByWorkTask(workTaskIdList: Array<string>): Promise<ShowTotalNumbersByWorkTaskResponse | undefined> {
    const url = `${getServiceUrl()}/ShowTotalNumbersByWorkTask`
    const body = { workTaskIdList }

    return ajaxAuth({ url, body, method: "POST" })
}

export function includeParts(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/IncludeParts`
    const body = { workTaskId, itemList }

    return ajaxAuth<ChangeIsIncludedResponse>({ url, body, method: "POST" })
}

export function excludeParts(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/ExcludeParts`
    const body = { workTaskId, itemList }

    return ajaxAuth<ChangeIsIncludedResponse>({ url, body, method: "POST" })
}

export function includeWorks(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/IncludeWorkList`
    const body = { workTaskId, itemList }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function excludeWorks(workTaskId: string, itemList: Array<Item>) {
    const url = `${getServiceUrl()}/ExcludeWorkList`
    const body = { workTaskId, itemList }

    return ajaxAuth<ChangeItemsResponse>({ url, body, method: "POST" })
}

export function importCostEstimationVoucher(body: ImportVoucherRequest) {
    // TODO configurable cost-estimation:  Rename to ImportVoucher when the original Create is no longer used
    const url = `${getServiceUrl()}/ImportVoucherV2`

    return ajaxAuth<ImportResponse>({ url, body, method: "POST" })
}

// Check if this must be included in useBasketState
export function sendCostEstimation(body: SendCostEstimationRequest) {
    const url = `${getServiceUrl()}/SendCostEstimation`

    return ajaxAuth<SendCostEstimationResponse>({ url, body, method: "POST" })
}

export function changeRepairTimeCalculation(body: ChangeRepairTimeCalculationRequest) {
    const url = `${getServiceUrl()}/ChangeRepairTimeCalculation`

    return ajaxAuth<ChangeCostEstimationResponse>({ url, body, method: "POST" })
}

export function changeCustomerGroupMode(body: ChangeCustomerGroupModeRequest) {
    const url = `${getServiceUrl()}/ChangeCustomerGroupMode`

    return ajaxAuth<ChangeCostEstimationResponse>({ url, body, method: "POST" })
}

export function changePriceTypeMode(body: ChangePriceTypeModeRequest) {
    const url = `${getServiceUrl()}/ChangePriceTypeMode`

    return ajaxAuth<ChangeCostEstimationResponse>({ url, body, method: "POST" })
}

export function changePriceVatMode(body: ChangePriceVatModeRequest) {
    const url = `${getServiceUrl()}/ChangePriceVatMode`

    return ajaxAuth<ChangeCostEstimationResponse>({ url, body, method: "POST" })
}

export function changeUseNoteText(body: ChangeUseNoteTextRequest) {
    const url = `${getServiceUrl()}/ChangeUseNoteText`

    return ajaxAuth<ChangeCostEstimationResponse>({ url, body, method: "POST" })
}
