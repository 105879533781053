import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import * as Data from "../repositories/cis/vouchers"
import { ShowOrderDetailsRequest } from "../model"

const KEY = "cis_useOrderDetails"
export function useOrderDetails(request: Omit<ShowOrderDetailsRequest, "customerNo"> | undefined) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const enabled = enableServiceCalls && !!request

    const { data, isLoading, error } = useQuery(
        [KEY, request, telesalesCustomerNo, enabled],
        () => {
            if (request) {
                return Data.showOrdersDetails({ ...request, customerNo: telesalesCustomerNo })
            }
        },
        { enabled }
    )

    return {
        orderDetails: data?.orderDetails,
        orderDetailsLoading: isLoading,
        orderDetailsError: data?.messageDescription || (error as string | undefined),
    }
}

export function useReloadOrderDetails() {
    const queryClient = useQueryClient()

    const invalidateShowOrderDetails = useCallback(() => {
        queryClient.invalidateQueries(KEY)
    }, [queryClient])

    return { invalidateShowOrderDetails }
}
