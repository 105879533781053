import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { Restriction } from "../../model"
import { TiresRestrictionsRequest } from "./model"

export function loadTireRestrictions(request: TiresRestrictionsRequest) {
    const url = `${getServiceUrl()}/GetTireRestrictions`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<Restriction[]>((resolve, reject) =>
        ajax({ url, body, authorization, method: "GET" }).then(
            response => resolve(response ? response.tireRestrictions : []),
            reject
        )
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}