import { Box, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { useEffect, useState } from "react"

type Props = {
    loadTeccom(quantity: number): void
    teccomLoading: boolean
    articleQuantity: number
}

export function TeccomAvailability(props: Props) {
    const { translateText } = useLocalization()
    const [quantity, setQuantity] = useState<number>(1)

    useEffect(() => {
        setQuantity(props.articleQuantity)
    }, [props.articleQuantity])

    useEffect(() => {
        const unsub = channel("WORKTASK").subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", ({ quantity, article }) => {
            setQuantity(quantity)
        })
        return unsub
    }, [])

    return (
        <Box py={3} display="flex">
            <Button color="primary" onClick={() => props.loadTeccom(quantity)} sx={{ marginLeft: "4px" }} disabled={props.teccomLoading}>
                {translateText(1080)}
            </Button>
        </Box>
    )
}
