import { useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Button, MenuItemWidget, Select, styled } from "@tm/components"
import { UseFormReturn } from "react-hook-form"
import { getCategories } from "../../helpers/upload"
import { CategoryDropDownProps, FileCategory } from "../../models"
import { UploadSchemaType } from "../../formSchemas/uploadSchema"
import { ErrorBoxWrapper } from "../shared"

export const StyledButtonsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: ".5em",
    margin: ".5em 0",
})

type Props = {
    formMethods: UseFormReturn<UploadSchemaType>
}

export function CategorySelection({ formMethods }: Props) {
    const { translateText } = useLocalization()
    const categories = useMemo(() => getCategories(translateText), [translateText])
    const [selectedCategory, setSelectedCategory] = useState(categories[0])

    const subCategories = useMemo(() => selectedCategory.subCategories, [selectedCategory])

    const {
        watch,
        setValue,
        clearErrors,
        formState: { errors },
    } = formMethods

    const selectedSubCategory = watch("category")

    function onSubCategorySelected(category: FileCategory | undefined) {
        clearErrors("category")
        setValue("category", category)
    }

    function onCategorySelected(index: number) {
        const category = categories[index]
        setSelectedCategory(category)
        onSubCategorySelected(undefined)
        if (category.id === 1) {
            onSubCategorySelected(FileCategory.Others)
        }
    }

    return (
        <Box ml={2}>
            <Select
                variant="outlined"
                value={selectedCategory.id}
                renderValue={(index) => categories[index as number].categoryName}
                onChange={(e) => onCategorySelected(e.target.value as number)}
            >
                {categories.map((category) => {
                    return (
                        <MenuItemWidget key={category.id.toString()} value={category.id}>
                            {category.categoryName}
                        </MenuItemWidget>
                    )
                })}
            </Select>

            <StyledButtonsWrapper>
                {subCategories?.length > 0 &&
                    subCategories.map((item) => (
                        <ErrorBoxWrapper key={item} isError={!!errors?.category}>
                            <Button color={item === selectedSubCategory ? "primary" : "inherit"} onClick={() => onSubCategorySelected(item)}>
                                {translateText(item)}
                            </Button>
                        </ErrorBoxWrapper>
                    ))}
            </StyledButtonsWrapper>
        </Box>
    )
}
