import { useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { TyreFilter } from "@tm/models"
import { em } from "csx"
import * as React from "react"

export type RangeProps = {
    items: TyreFilter[]
    selectedItempos: number
    onChange?(newValue: number): void
}

export const MinimizedRangeSlider: React.FC<RangeProps> = ({ items=[], selectedItempos, onChange }) => {

    const [sliderPos, setSliderPos] = React.useState(selectedItempos)
    const [curentValue, setCurentValue] = React.useState(items[selectedItempos])
    const [showTooltip, setShowTooltip] = React.useState(false)

    const FIRST_POS=0
    const MIDDLE_POS=Math.floor((items.length - 1) / 2)
    const LAST_POS=items.length-1


    let className = "range-slider"

    React.useEffect(() => {
        if (selectedItempos != sliderPos) {
            setSliderPos(selectedItempos)
        }
    }, [selectedItempos])

    const calculateSliderPosition = () => {
        const p = sliderPos / (items.length - 1) * 100
        return p - 7 - ((8 * p) / 100)
    }

    const onChangeHandler = (e: any) => {
        setSliderPos(e.target.value)
        setCurentValue(items[e.target.value])
    }

    const handleMouseUp = (e: any) => {
        if (onChange && selectedItempos != e.target.value) {
            onChange(e.target.value)
        }
    }

    const handleSelectFirst=()=>{
        onChange?.(FIRST_POS)
    }
    const handleSelectMiddle=()=>{
        onChange?.(MIDDLE_POS)
    }
    const handleSelectLast=()=>{
        onChange?.(LAST_POS)
    }


    return (
        <div>
            <div className={className + " customSlider "} onMouseEnter={() => { setShowTooltip(true) }} onMouseLeave={setShowTooltip.bind(undefined, false)} >
                {showTooltip &&
                    <span
                        style={{ left: (calculateSliderPosition() + "%") }}
                        className={style.popup + " range-slider__value"}>
                        {curentValue.value}
                    </span>
                }
                <input value={sliderPos} min={0} max={items.length - 1} type="range" onChange={onChangeHandler} onMouseUp={handleMouseUp} />
            </div>
            <div className={style.sliderButtons}>
                <Button
                    size="s"
                    key={0}
                    className={style.buttonNumbers}
                    onClick={handleSelectFirst}>
                    {items.first()?.value}
                </Button>
               {MIDDLE_POS>=1 && <Button
                    size="s"
                    key={1}
                    className={style.buttonNumbers}
                    onClick={handleSelectMiddle}>
                    {items[MIDDLE_POS]?.value}
                </Button>}
                { LAST_POS >=1 && <Button
                    size="s"
                    key={2}
                    className={style.buttonNumbers}
                    onClick={handleSelectLast}>
                    {items.last()?.value}
                </Button>}
            </div>
        </div>
    )

}

const style = useStyle({
    popup: {
        fontSize: em(.8),
        padding: "0.5em 1em 0.5em 1em",
        whiteSpace: "nowrap",
        top: "-140%"
    },
    sliderButtons: {
        marginTop: em(.5),
        display: "flex",
        justifyContent: "space-between"
    }, buttonNumbers: {
        backgroundColor: "#2196f3",
        color: "#fff"
    }
})(MinimizedRangeSlider)

export default MinimizedRangeSlider