import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, receive } from "./business"

const requestRepairTimes: BundleComponent = {
    name: "request-repair-times",
    component,
    reduce,
    receive,
}

export default requestRepairTimes
