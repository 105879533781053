import { Typography } from "@tm/components"
import { BasketErpInformationResponseItem } from "../../../../../../../data/model"
import { getBundleParams } from "../../../../../../../utils"

type Props = {
    erpInfoResponse?: BasketErpInformationResponseItem
}

export default function ErpMemos({ erpInfoResponse }: Props) {
    const { memoTypesToShowBelowPrices } = getBundleParams()

    if (!erpInfoResponse?.memos?.length || !memoTypesToShowBelowPrices?.length) {
        return null
    }

    // Neimcke  -  NEXT-17790
    return (
        <>
            {memoTypesToShowBelowPrices.flatMap((memoType) =>
                erpInfoResponse?.memos
                    ?.filter((memo) => memo.type === memoType)
                    .map((memo, idx) => (
                        <Typography key={`${memoType}_${idx}`} variant="label">
                            {memo.text}
                        </Typography>
                    ))
            )}
        </>
    )
}
