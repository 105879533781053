import { useEffect, useMemo, useRef } from "react"
import { Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box } from "@tm/components"
import NextStep from "../_shared/nextStep"
import { useIsFirstRender } from "../../helpers"
import { stepNavigationActions, useFastServiceStore } from "../../data"
import { SelectionKeys } from "../../data/state"
import SelectionCheck from "./components/SelectionCheck"
import WorksCheck from "./components/WorksCheck"
import VehicleCheck from "./components/VehicleCheck"

export default function MaintenancePlan() {
    const currentStepName = "maintenancePlan"
    const { translateText } = useLocalization()
    const scrollbarRef = useRef<any>(null)

    const selections = useFastServiceStore((state) => state.maintenancePlan.selections)
    const additionalWorks = useFastServiceStore((state) => state.maintenancePlan.additionalWorks)
    const followupWorks = useFastServiceStore((state) => state.maintenancePlan.followupWorks)

    const isFirstMount = useIsFirstRender()

    const completedStep = useMemo(
        () => !!selections?.length && selections.filter((x) => x.isSubmitted === true).length === selections.length,
        [selections]
    )

    useEffect(() => {
        !isFirstMount && stepNavigationActions.completeStep(currentStepName)

        if (completedStep) {
            stepNavigationActions.updateNextStep(currentStepName)
        }
        if (!completedStep) {
            stepNavigationActions.resetCompleteStep()
        }
    }, [completedStep])

    const onCheckOpened = (offsetTop: number) => {
        // eslint-disable-next-line no-underscore-dangle
        scrollbarRef?.current?.__refElement.scrollTop(offsetTop)
    }

    return (
        <Box display="flex" flexDirection="column" height="100%">
            <Scrollbar ref={scrollbarRef}>
                <VehicleCheck onCheckOpened={onCheckOpened} />

                {selections?.map((selection) => (
                    <SelectionCheck key={selection.id} selection={selection} onCheckOpened={onCheckOpened} />
                ))}

                {!!additionalWorks?.length && (
                    <WorksCheck
                        title={translateText(12636)}
                        icon="additional-work"
                        selectionKey={SelectionKeys.AdditionalWorks}
                        works={additionalWorks}
                        onCheckOpened={onCheckOpened}
                    />
                )}

                {!!followupWorks?.length && (
                    <WorksCheck
                        title={translateText(647)}
                        icon="follow-up-work"
                        selectionKey={SelectionKeys.FollowUps}
                        works={followupWorks}
                        onCheckOpened={onCheckOpened}
                    />
                )}
            </Scrollbar>

            <NextStep currentStepName="maintenancePlan" icon="arrows_down" buttonTextId={414} active={completedStep} />
        </Box>
    )
}
