import { useState, useEffect } from "react"
import { MoreMenu, MoreMenuItem } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CisVoucherType, OrderVoucherList, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { formatDate, renderRoute } from "@tm/utils"
import { useHistory } from "react-router"
import { Button } from "@tm/components"

type Order = {
    orderId: string
    orderSubId?: string
    orderNumber: string
    orderDate: Date
}

type Props = {
    ordersUrl: string // Could be cis or vouchers-orders
    generatedWorktaskId: string
    voucherTypeId?: CisVoucherType
    textId: number
}

export default function LastOrdersComponent({ generatedWorktaskId, ordersUrl, voucherTypeId, textId }: Props) {
    const { translateText } = useLocalization()
    const [lastOrderIds, setLastOrderIds] = useState<Array<Order>>()
    const history = useHistory()

    useEffect(() => {
        const request = {
            pageIndex: 1,
            pageSize: 5,
        }
        if (voucherTypeId === CisVoucherType.AllOrders) {
            Container.getInstance<any>(RegisteredModels.CIS_ShowAllOrders)
                .subscribe(request)
                .load()
                .then((response) => {
                    // TODO: when the paging is supported and working in the service, slice should be removed
                    const orders = response.orders.slice(0, request.pageSize).map((order: any) => ({
                        orderId: order.orderId,
                        orderNumber: order.orderId,
                        orderDate: order.orderDate,
                    }))
                    setLastOrderIds(orders)
                })
        } else if (voucherTypeId === CisVoucherType.OpenOrders) {
            Container.getInstance<any>(RegisteredModels.CIS_ShowOpenOrders)
                .subscribe(request)
                .load()
                .then((response) => {
                    const orders = response.openOrders.map((order: any) => ({
                        orderId: order.orderNumber,
                        orderNumber: `${order.orderNumber} - ${order.orderSubNumber}`,
                        orderDate: order.orderDate,
                    }))
                    setLastOrderIds(orders)
                })
        } else {
            Container.getInstance<Array<OrderVoucherList>>(RegisteredModels.Vouchers_ShowAllOrders)
                .subscribe(request)
                .load()
                .then((response) => {
                    const orders = response?.map((order) => ({
                        orderId: order.orderVoucherId,
                        orderNumber: order.voucherNumber,
                        orderDate: order.orderDate,
                    }))
                    setLastOrderIds(orders)
                })
        }
    }, [voucherTypeId])

    function handleMenuItemSelect(item: MoreMenuItem) {
        const openOrdersUrlParams = {
            workTaskId: generatedWorktaskId,
            id: item.id,
            subId: voucherTypeId === CisVoucherType.OpenOrders ? "0" : undefined, // SubId is 0, as requested by STG
        }

        const url = renderRoute(ordersUrl, openOrdersUrlParams)
        history.push(url)
    }

    function handleRedirectButtonClick() {
        const openOrdersUrlParams = {
            workTaskId: generatedWorktaskId,
        }

        const voucherUrl = ordersUrl.substr(0, ordersUrl.indexOf(":id"))
        const url = renderRoute(voucherUrl, openOrdersUrlParams)
        history.push(url)
    }

    function renderMoreMenu() {
        const menuItems: Array<MoreMenuItem> = []
        lastOrderIds?.forEach((order) => {
            menuItems.push({
                id: order.orderId,
                text: `${order.orderNumber} ${formatDate(order.orderDate, "d")} ${formatDate(order.orderDate, "t")}`,
            })
        })
        return <MoreMenu items={menuItems} onMenuItemSelect={handleMenuItemSelect} disabled={!lastOrderIds} />
    }

    return (
        <>
            <Button onClick={handleRedirectButtonClick}>{translateText(textId || 1998)}</Button>
            {renderMoreMenu()}
        </>
    )
}
