import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { PrintOptions } from "../../models"
import { PrintOptionsSlice, PrintOptionsState } from "./model"

const initPrintOptionsState: PrintOptions = {
    inspectionTimes: false,
    remarks: false,
    images: false,
    tyres: false,
    nextMaintenance: false,
    showDefects: false,
}

const getInitialState = (): PrintOptionsState => {
    return {
        printOptions: initPrintOptionsState,
    }
}

export const createPrintOptionsSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], PrintOptionsSlice> = (set) => ({
    ...getInitialState(),
    reset: () => set(getInitialState(), false, "Reset Print Options Slice"),
    setUpdatePrintOptions: (optionKey) => set((state: PrintOptionsSlice) => setPrintOptions(state, optionKey), false, "Set print option"),
    setReplacePrintOptions: (newOptions) => set(() => replacePrintOptions(newOptions), false, "Options Updated"),
})

// Not used - Might need later
const setPrintOptions = (state: PrintOptionsSlice, optionKey: keyof PrintOptions): Partial<PrintOptionsSlice> => {
    return {
        printOptions: {
            ...state.printOptions,
            [optionKey]: !state.printOptions[optionKey],
        },
    }
}

const replacePrintOptions = (printOptions: PrintOptions): Partial<PrintOptionsSlice> => {
    return {
        printOptions,
    }
}
