import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { Article, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Stack, Typography } from "@tm/components"
import ArticlesTable from "./ArticlesTable"

type Props = {
    articles: Article[]
    quantity: number
    onOpenArticlePartsList(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number): void
}

export default function SelectArticlesComponent({ articles, quantity, onOpenArticlePartsList }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const [selectedArticles, setSelectedArticles] = useState<Article[]>([])

    function selectArticle(article: Article, remove?: boolean) {
        if (remove) {
            setSelectedArticles(selectedArticles.filter((item) => item.id !== article.id))
        } else {
            setSelectedArticles(selectedArticles.concat(article))
        }
    }

    function mapArticleToListItem(article: Article): Article {
        return {
            ...article,
            quantity,
        }
    }

    return (
        <Stack flex={1} pt={1} mr={1}>
            <Stack direction="row" justifyContent="space-between">
                <Stack>
                    <Typography variant="h1">
                        {translateText(693)} - {translateText(1324)}
                    </Typography>
                    <Typography>{translateText(1325)}</Typography>
                </Stack>
                {renderMicro("basket", "add-to-basket", {
                    data: selectedArticles,
                    buttonText: translateText(133),
                    disabled: !selectedArticles.length,
                    searchType: 1 /** DIRECT search */,
                })}
            </Stack>
            {!!articles.length && (
                <ArticlesTable
                    articles={articles.map(mapArticleToListItem)}
                    selectArticle={selectArticle}
                    selectedArticles={selectedArticles}
                    onOpenArticlePartsList={onOpenArticlePartsList}
                />
            )}
        </Stack>
    )
}
