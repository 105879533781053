import { useWorkTask } from "@tm/context-distribution"
import { Widget, WidgetProps, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { SearchFilters } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { TmaHelper } from "@tm/utils"
import { useEffect } from "react"
import { SxProps, Theme, styled } from "@tm/components"
import { getBundleParams } from "../../utils"
import DirectSearchField from "../_shared/direct-search-field"
import { Actions, IActions, WidgetState } from "./business"

export type DirectSearchWidgetConfigProps = {
    height?: number
    size?: WidgetSizes
    className?: string
    collapsible?: boolean
    directSearchText?: string
    title?: string
    alignHeightToWidgets?: string[]
    autofocusTimeout?: number
    sx?: SxProps<Theme>
}

type DirectSearchWidgetProps = DirectSearchWidgetConfigProps & {
    state: WidgetState
    actions: IActions
}

const SxWidget = styled(Widget)({})

const DirectSearchWidgetComponent = (props: DirectSearchWidgetProps) => {
    const {
        actions: { loadQueryHistory, searchArticles },
    } = props
    const { workTask } = useWorkTask() || {}
    const { translate, translateText } = useLocalization()

    useEffect(() => {
        loadQueryHistory()
    }, [loadQueryHistory])

    const handleSearch = (query: string, searchFilters?: SearchFilters, extendedSearch?: boolean) => {
        if (workTask?.vehicle) {
            TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.AttachVehicle(workTask?.vehicle)
        }

        TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext(props.state.activeCategory, query, workTask?.id, true)
        searchArticles(query, searchFilters, extendedSearch)
    }

    const { collapsible, title, state, autofocusTimeout, size, sx } = props

    const propsWidget: WidgetProps = {
        size: size || "4x1",
        id: "parts__widget-directsearch-only",
        className: `tk-parts parts-widget directsearch`,
        height: 10.8, // remove this crappy crapcode and kill it with fire => webelekat specific
        collapsible,
        iconName: "catalog",
        title: `${title ? translate(title) : translate(993)}`,
    }

    return (
        <SxWidget sx={sx} {...propsWidget} active>
            <div className="upper">
                <div className="search-and-navigation">
                    <DirectSearchField
                        searchfilterResultCounts={[]}
                        placeholder={translateText(138)}
                        minLengthForSearch={getBundleParams().minimumSearchLength.directSearch}
                        onSearch={handleSearch}
                        activeCategory={state.activeCategory}
                        tooltip={translateText(1046)}
                        autofocusTimeout={autofocusTimeout}
                        autofocus
                    />
                </div>
            </div>
        </SxWidget>
    )
}

export const DirectSearchWidget = connectComponent(Actions, DirectSearchWidgetComponent)
