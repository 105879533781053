import { Loader, styled } from "@tm/components";
import { useWorkTask } from "@tm/context-distribution";
import { Button, Table } from "@tm/controls";
import { useLocalization } from "@tm/localization";
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils";
import { useEffect } from "react"
import { ServiceNode } from "../../../data/models";
import { getServiceNodes, getServiceNodesById } from "../../../data/repositories";
import { useNodesStore, useServicesStore } from "../../../data/state";

const { Cell, Column } = Table

type RouteProps = {
    workTaskId: string
    selectedNode?: string
    selectedSubNode?: string
}

type Props = RouteComponentProps<RouteProps> & {
    route: string
}

function NodesListThree(props: Props) {
    const { loadingNodes, nodes, selectedNode, selectedSubNode, setSelectedSubNode, setSelectedNode, setLoadingNodes, setNodes, resetNodesStore} = useNodesStore()
    const { loadingServices, setServices, setLoadingServices,resetServiceStore } = useServicesStore()
    const { translateText } = useLocalization()
    const { vehicle } = useWorkTask()?.workTask ?? {}

    useEffect(() => {
        if (!nodes?.length) {
            setLoadingNodes(true)
            getServiceNodes().then(x => setNodes(x))
        }

        return () => {
            resetNodesStore()
            resetServiceStore()
        }
    }, [vehicle?.id])

    useEffect(() => {
        const { selectedNode: selectedNodeFromUrl, selectedSubNode: selectedSubNodeFromUrl } = props.match.params

        if (!!selectedNodeFromUrl && !!nodes?.length) {
            let node = nodes?.find(x => x.id == Number(selectedNodeFromUrl))
            node && setSelectedNode(node)

            if (!!selectedSubNodeFromUrl && !!node?.subNodes?.length) {
                const subNode = node.subNodes.find(x => x.id === Number(selectedSubNodeFromUrl))
                subNode && setSelectedSubNode(subNode)
            }
        }
    }, [nodes, loadingServices])

    useEffect(() => {
        if (!!selectedSubNode) {
            setLoadingServices(true)
            getServiceNodesById({ NodeId: selectedSubNode.id }).then(x => setServices(x))
        }
    }, [selectedSubNode])

    const handleSelectNode = (row: ServiceNode) => {
        setSelectedNode(row)
        setSelectedSubNode(undefined)

        const routeParams = {
            workTaskId: props.match.params.workTaskId,
            selectedNode: row.id
        }

        const url = renderRoute(props.route, routeParams)
        props.history.push(url)
    }

    const handleSelectSubNode = (row: ServiceNode) => {
        setSelectedSubNode(row)

        const routeParams = {
            workTaskId: props.match.params.workTaskId,
            selectedNode: props.match.params.selectedNode,
            selectedSubNode: row.id
        }

        const url = renderRoute(props.route, routeParams)
        props.history.push(url)
    }

    const handleOnCloseNodeClick = (e: any) => {
        e.stopPropagation()
        setSelectedNode(undefined)
        setServices([])

        const url = renderRoute(props.route, { workTaskId: props.match.params.workTaskId })
        props.history.replace(url)
    }

    const getSelectedNodeClassName = () => {
        return (!!selectedNode) ? "is-selected" : ""
    }

    const getSelectedSubNodeClassName = (row: ServiceNode) => {
        return row.id === selectedSubNode?.id ? "is-selected" : ""
    }

    if (loadingNodes) {
        return <Loader />
    }

    return (<>
        <StyledTable
            data={!!selectedNode ? [selectedNode] : nodes}
            onClickRow={handleSelectNode}
            getRowClassName={getSelectedNodeClassName}
            columns={[
                <Column
                    className="fancy-list__block--number"
                    renderItemContent={(x: ServiceNode) => <Cell>{x.id}</Cell>}
                >
                    {translateText(130)}
                </Column>,

                <Column
                    className="fancy-list__block--description"
                    renderItemContent={(x: ServiceNode) => <Cell>{x.bez}</Cell>}
                >
                    {translateText(131)}
                </Column>,

                <Column
                    className="fancy-list__block--actions"
                    renderItemContent={(x: ServiceNode) => <Cell
                    >
                        <Button
                            style={{ visibility: !!selectedNode ? "visible" : "hidden" }}
                            scaleIcon
                            layout={["ghost"]}
                            icon="close"
                            onClick={handleOnCloseNodeClick} />
                    </Cell>} />,
            ]}
        />
        {selectedNode?.subNodes && <StyledTable
            data={selectedNode.subNodes}
            onClickRow={handleSelectSubNode}
            columns={[
                <Column
                    className="fancy-list__block--number"
                    renderItemContent={(x: ServiceNode) => <Cell>{x.id}</Cell>}
                >
                    {translateText(130)}
                </Column>,
                <Column
                    className="fancy-list__block--description"
                    renderItemContent={(x: ServiceNode) => <Cell>{x.bez}</Cell>}
                >
                    {translateText(131)}
                </Column>,
            ]}
            getRowClassName={getSelectedSubNodeClassName}
        />}
    </>
    )
}

const StyledTable = styled(Table)({
    ".fancy-list__block": {
        flex: 1,
        cursor: "pointer",
        "&--actions": {
            display: "flex",
            justifyContent: "flex-end"
        }
    }
})

export default withRouter(NodesListThree)