import { getStoredAuthorization, ajax } from "@tm/utils"
import { AddReturnItemRequest, Item, ShowReturnsTotalNumbersRequest, ShowReturnsTotalNumbersResponse } from "@tm/models"
import { getCisServiceUrl } from "../.."
import {
    ChangeReturnQuantityRequest,
    SubmitReturnOrderRequest,
    SubmitReturnOrderResponse,
    EditReturnInfoRequest,
    ChangeReturnReasonRequest,
    ShowReturnReasonsFromMdmResponse,
    SubmitReturnOrderMailRequest,
    ShowReturnItemResponse,
    ShowReturnItemsResponse,
} from "../../model"

function getServiceUrl() {
    return `${getCisServiceUrl()}/ReturnItems`
}

export function addReturnItem(body: AddReturnItemRequest) {
    const authorization = getStoredAuthorization()
    const url = `${getServiceUrl()}/AddReturnItem`

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function changeReturnQuantity(body: ChangeReturnQuantityRequest) {
    const url = `${getServiceUrl()}/ChangeReturnQuantity`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function changeReturnReason(body: ChangeReturnReasonRequest) {
    const url = `${getServiceUrl()}/ChangeReturnReason`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function editReturnInfo(body: EditReturnInfoRequest) {
    const url = `${getServiceUrl()}/EditReturnInfo`
    const authorization = getStoredAuthorization()

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function excludeFromReturn(item: Item) {
    const url = `${getServiceUrl()}/ExcludeFromReturn`
    const authorization = getStoredAuthorization()
    const body = { item }

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function excludeItemsFromReturn(itemList: Array<Item>) {
    const url = `${getServiceUrl()}/ExcludeItemsFromReturn`
    const authorization = getStoredAuthorization()
    const body = { itemList }

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function includeInReturn(item: Item) {
    const url = `${getServiceUrl()}/IncludeInReturn`
    const authorization = getStoredAuthorization()
    const body = { item }

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function includeItemsInReturn(itemList: Array<Item>) {
    const url = `${getServiceUrl()}/IncludeItemsInReturn`
    const authorization = getStoredAuthorization()
    const body = { itemList }

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function removeReturnItem(id: string) {
    const url = `${getServiceUrl()}/RemoveReturnItem`
    const authorization = getStoredAuthorization()
    const body = { id }

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function removeReturnItemList(idList: Array<string>) {
    const url = `${getServiceUrl()}/RemoveReturnItemList`
    const authorization = getStoredAuthorization()
    const body = { idList }

    return ajax<void>({ url, body, authorization, method: "POST" })
}

export async function submitReturnOrder(body: SubmitReturnOrderRequest) {
    const url = `${getServiceUrl()}/SubmitReturnOrder`
    const authorization = getStoredAuthorization()
    return ajax<SubmitReturnOrderResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true)
}

export async function showReturnItem(body: { id: string }) {
    const url = `${getServiceUrl()}/ShowReturnItem`
    const authorization = getStoredAuthorization()
    return ajax<ShowReturnItemResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showReturnItems(body?: { customerNo?: string }) {
    const url = `${getServiceUrl()}/ShowReturnItems`
    const authorization = getStoredAuthorization()
    return ajax<ShowReturnItemsResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showTotalNumbers(body: ShowReturnsTotalNumbersRequest) {
    const url = `${getServiceUrl()}/ShowTotalNumbers`
    const authorization = getStoredAuthorization()

    return ajax<ShowReturnsTotalNumbersResponse>({ url, body, authorization })
}

export async function showReturnReasonsFromMdm() {
    const url = `${getServiceUrl()}/ShowReturnReasonsFromMdm`
    const authorization = getStoredAuthorization()

    return ajax<ShowReturnReasonsFromMdmResponse>({ url, authorization })
}

export async function submitReturnOrderMail(body: SubmitReturnOrderMailRequest) {
    const url = `${getServiceUrl()}/SubmitReturnOrderMail`
    const authorization = getStoredAuthorization()
    return ajax<SubmitReturnOrderResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true)
}

export async function printReturnItems(body: any) {
    const url = `${getServiceUrl()}/PrintReturnItems`
    const authorization = getStoredAuthorization()

    return ajax<Array<number>>({
        url,
        body,
        authorization,
        responseType: "blob",
        method: "POST",
    })
}
