import { em, margin, percent, rem } from "csx"
import { useLocalization } from "@tm/localization"
import { Box, Typography, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { TotalPrice } from "../../../data/models/calculation"
import { useDrivemotiveStore } from "../../../data/state"

export default function Prices() {
    const { currency, translateText } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const { articlesPrice, repairTimesPrice, totalPrice } = useDrivemotiveStore((state) => state.calculation.totals)

    const hasInvalidArticles = false
    const hasInvalidRepairTimes = false
    const hasInvalidConsumabled = false

    const renderPanel = (calcPrice: TotalPrice, isTotal?: boolean, invalid?: boolean, text?: string) => {
        let label = text
        if (!isTotal) {
            label = `${calcPrice?.totalQuantity ?? 0} ${label}`
        }
        return (
            <StyledPricePanel>
                <StyledText size="s" modifiers={["sub"]}>
                    {label}
                </StyledText>
                <StyledPriceWrapper>
                    <StyledPriceValue isTotal={isTotal} invalid={invalid} size="xl" modifiers="strong">
                        {currency(calcPrice?.value ?? 0, currencyString)}
                    </StyledPriceValue>
                    {/* {isTotal && (
                        <StyledSmallText size="s">{`${translateText(694)} ${currency(calcPrice.value ?? 0, currencyString)}`}</StyledSmallText>
                    )} */}
                </StyledPriceWrapper>
            </StyledPricePanel>
        )
    }

    return (
        <StyledWrapper>
            {renderPanel(articlesPrice, false, hasInvalidArticles || hasInvalidConsumabled, translateText(90))}
            {repairTimesPrice && renderPanel(repairTimesPrice, false, hasInvalidRepairTimes, translateText(620))}
            {renderPanel(totalPrice, true, undefined, translateText(62))}
            {/* {renderPanel(totals.totalPriceVat, true, undefined, translateText(62))} */}
        </StyledWrapper>
    )
}

const StyledPricePanel = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: "0 1.5em",
    position: "relative",
})

const StyledText = styled(Typography)(({ theme }) => ({
    margin: theme?.margin?.m && margin(0, theme?.margin?.m, theme.margin?.m, theme.margin?.m),
    textTransform: "uppercase",
}))

const StyledPriceWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
})

const StyledSmallText = styled(Typography)({
    alignSelf: "center",
})

const StyledPriceValue = styled(Typography)(({ theme, isTotal, invalid }) => ({
    display: "flex",
    justifyContent: "center",
    fontSize: rem(1.5),
    margin: theme.margin?.m && margin(0, theme.margin?.m, theme.margin?.m, theme.margin?.m),
    ...(isTotal && {
        color: theme.colors?.highlight,
    }),
    ...(invalid && {
        backgroundColor: theme.colors?.danger,
    }),
    "&::before": {
        content: `""`,
        position: "absolute",
        left: 0,
        height: em(0.15),
        width: percent(100),
        bottom: 0,
        backgroundColor: "#c3c3c3",
        borderRadius: theme.box?.radius,
    },
}))

const StyledWrapper = styled(Box)({
    display: "flex",
    padding: "1.5em 8em 0 8em",
})
