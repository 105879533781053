import { useLocalization } from "@tm/localization"
import { Button, Icon, IconButton, Tooltip, useTheme } from "@tm/components"
import { ArticleInfoType } from "@tm/models"
import { BasketPart } from "../../../models"

type Props = {
    part: BasketPart
    onOpenArticleDetails?(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number, subPage?: string, scrollTo?: string): void
}

export default function PartAlternativesButton({ part, onOpenArticleDetails }: Props) {
    const { partItem, erpInfoResponse } = part
    const { articleInfoType, supplierId, productGroupId, articleNumber } = partItem.articleInformation
    const { translateText } = useLocalization()

    const theme = useTheme()
    const iconColor = theme?.overwrites?.components?.basketArticle?.alternateArticleButton?.iconColor
    const iconSize = theme?.overwrites?.components?.basketArticle?.alternateArticleButton?.size

    return erpInfoResponse?.hasAlternatives ? (
        <Tooltip title={translateText(710)} variant="light">
            <IconButton
                disabled={articleInfoType === ArticleInfoType.WholesalerArticle}
                onClick={() => {
                    onOpenArticleDetails?.(productGroupId, articleNumber, supplierId, "alternative-articles")
                }}
            >
                <Icon name="alternative-article" color={iconColor} size={iconSize} />
            </IconButton>
        </Tooltip>
    ) : (
        <></>
    )
}
