import { Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { Text } from "@tm/controls"
import { concat } from "@tm/utils"
import { IMicros } from "@tm/models"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-quantity-unit"]

function ErpInfoQuantityUnitComponent(props: Props) {
    const { translateText } = useLocalization()
    const { loading, erpInfo } = useErpInfo(props.data, "list")
    const { quantity } = erpInfo ?? {}

    if (loading || !quantity?.quantityUnit) {
        return null
    }

    return <Text size="s">{concat(" ", translateText(1114), quantity.quantityPackingUnit, quantity.quantityUnit)}</Text>
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info-quantity-unit">
                    <Suspense fallback={null}>
                        <ErpInfoQuantityUnitComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
