import { useState, useRef, useEffect, useCallback } from "react"
import { Button } from "@tm/controls"
import Shutter from "./shutter"
import { VrcLocalFile } from "../../../../component"
import { getComponentStyles } from "./styles"

type Props = {
    stream: MediaStream
    onClose(): void
    onImageUpload(image: VrcLocalFile): void
}

export default function Overlay({ stream, onClose, onImageUpload }: Props) {
    const classNames = getComponentStyles()

    const [dimensions, setDimensions] = useState<{ width: number; height: number }>()
    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        if (videoRef.current && videoRef.current.srcObject !== stream) {
            videoRef.current.srcObject = stream
        }

        return () => {
            const tracks = stream.getTracks()
            tracks.forEach((track) => {
                track.stop()
            })
        }
    }, [stream])

    const handleCanPlay = () => {
        if (videoRef.current) {
            // Restrict both width and height to 1920 here, so the image can be rotated later without losing pixels
            // setDimensions(calculateMediaSizeLimit(videoRef.current.videoWidth, videoRef.current.videoHeight, 1920, 1920))
            setDimensions({ width: videoRef.current.videoWidth, height: videoRef.current.videoHeight })
        }
    }

    const handleTakePhoto = useCallback(() => {
        if (videoRef.current && canvasRef.current) {
            const context = canvasRef.current.getContext("2d")
            if (context) {
                context.drawImage(videoRef.current, 0, 0, videoRef.current.width, videoRef.current.height)
                const data = canvasRef.current.toDataURL("image/jpg")
                onImageUpload({ data, isImage: true, rotation: 0 })
            }
        }
    }, [onImageUpload])

    return (
        <div className={classNames.main}>
            <div className={classNames.content}>
                <Button layout={["ghost"]} onClick={onClose} icon="cancel-x" className={classNames.closeButton} />
                {
                    // eslint-disable-next-line jsx-a11y/media-has-caption
                    <video
                        ref={videoRef}
                        onCanPlay={handleCanPlay}
                        height={dimensions?.height}
                        width={dimensions?.width}
                        autoPlay
                        playsInline
                        className={classNames.video}
                    />
                }
                <canvas ref={canvasRef} height={dimensions?.height} width={dimensions?.width} className={classNames.canvas} />
                <Shutter onTakePhoto={handleTakePhoto} />
            </div>
        </div>
    )
}
