import { Button, Icon } from "@tm/controls"
import { useState } from "react"

export type Rating = { name: string; value: number }
const ratingSmiley: Rating[] = [
    { name: "very-unhappy-smiley", value: 1 },
    { name: "unhappy-smiley", value: 2 },
    { name: "neutral-smiley", value: 3 },
    { name: "happy-smiley", value: 4 },
    { name: "very-happy-smiley", value: 5 },
]

type Props = {
    onChange: (selected: Rating) => void
}

export function Rating(props: Props) {
    const { onChange } = props
    const [selected, setSelected] = useState<string>("")

    const handleChangeRating = (value: Rating) => {
        setSelected(value.name)
        if (onChange) {
            onChange(value)
        }
    }

    return (
        <>
            {ratingSmiley.map((entry) => (
                <Button key={`key_${entry.name}`} layout={["ghost"]} onClick={() => handleChangeRating(entry)}>
                    <Icon className={`rating${entry.name === selected ? " selected" : ""}`} name={entry.name} />
                </Button>
            ))}
        </>
    )
}
