import { Box, Button, Dialog, Icon, Loader, Stack, Typography } from "@tm/components"
import { WorkTaskInfo, useReplaceUrlTags, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ECounterType, OrderingType } from "@tm/models"
import { TmaHelper, ajax, renderRoute, useExternalCatalogUrl } from "@tm/utils"
import { Suspense, useState } from "react"
import { useHistory, useParams } from "react-router"
import DmsExportButton from "../../../../../../dms/src/components/export-button/component"
import { ModuleHeaderSlot } from "../../../../../../parts/src/components/ListV2/components/ModuleHeader/ModuleHeaderSlot"
import { useCreateCostEstimationVoucher } from "../../../../data/hooks/useCreateCostEstimationVoucher"
import { EGsiContext, useGsiContext } from "../../../../data/hooks/useGsiContext"
import { ESendCostEstimationMode } from "../../../../data/model"
import { useWorkTaskBasketState } from "../../../../hooks/basketState/useWorkTaskBasketState"
import { useCostEstimationModuleParameter } from "../../../../hooks/useCostEstimationModuleParameter"
import { getBundleParams } from "../../../../utils"
import WorkTaskOrderButtonComponent from "../../../_shared/order-button/worktask"
import { TransferButtons } from "../../../_shared/transfer-buttons"
import { StackRow } from "../../../StyledComponents"
import { OfferState } from "../../business"
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"

type Props = {
    orderRoute: string
    orderButtonTextId: string
    orderButtonIcon: string
    orderRoutePreActionExternalSystemId?: number
    showDmsExportButton?: boolean
    state: OfferState
}

/**
 * Action Buttons for Basket Bundle
 * @memberof Basket
 * @param props
 * @returns Action button Component
 */
function ActionButtons(props: Props & { workTask: WorkTaskInfo }) {
    const { orderButtonTextId, orderButtonIcon, orderRoute, orderRoutePreActionExternalSystemId, workTask, showDmsExportButton, state } = props

    const { hideBasket } = getBundleParams()
    const history = useHistory()
    const params = useParams()
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId: orderRoutePreActionExternalSystemId, telesalesCustomerNo })
    const replacedExternalCatalogUrl = useReplaceUrlTags(externalCatalogUrl, {})
    const { costEstimation, workTaskBasketLoading, basket } = useWorkTaskBasketState(workTask.id)
    const { createCostEstimationVoucher, creatingCostEstimationVoucher } = useCreateCostEstimationVoucher(workTask, state)
    const { hasItems, costEstimationLoading } = costEstimation.state
    const workTaskGsiContext = useGsiContext(workTask.id)
    const { useSendOrder } = useCostEstimationModuleParameter()
    const { hasWorkTaskOrder } = useBasketModuleParameter()

    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)
    const [sendingPreAction, setSendingPreAction] = useState<boolean>(false)

    const disableButtons = costEstimationLoading || !hasItems

    function handleRemoveAllItems() {
        setShowDeleteDialog(false)
        costEstimation.actions.resetCostEstimation()
    }

    function handleRemoveAllItemsDialog() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateReset)
        setShowDeleteDialog(true)
    }

    function handleSave() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateSave)
        createCostEstimationVoucher()
    }

    function openOrderUrl() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateBackToBasket)

        const url = renderRoute(orderRoute, params)

        if (!orderRoutePreActionExternalSystemId) {
            history.push(url)
        } else if (replacedExternalCatalogUrl) {
            setSendingPreAction(true) // semicolon needed
            // Call to external system before redirecting to basket
            ajax({ url: replacedExternalCatalogUrl, method: "GET" }).then(
                () => {
                    history.push(url)
                },
                () => {
                    setSendingPreAction(false)
                }
            )
        }
    }

    return (
        <StackRow>
            {showDmsExportButton && (
                <ModuleHeaderSlot title="">
                    <DmsExportButton />
                </ModuleHeaderSlot>
            )}
            <ModuleHeaderSlot title="">
                <StackRow spacing={0.5}>
                    <Dialog open={showDeleteDialog} skin="warning" position="top" fullWidth>
                        <Stack spacing={2}>
                            <Typography>{translateText(989)}</Typography>
                            <Typography variant="body2">{translateText(990)}</Typography>
                        </Stack>
                        <Box pl={2} textAlign="center" display="initial">
                            <Button variant="contained" onClick={() => setShowDeleteDialog(false)}>
                                {translateText(584)}
                            </Button>
                            <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleRemoveAllItems}>
                                {translateText(585)}
                            </Button>
                        </Box>
                    </Dialog>
                    <Button disabled={disableButtons} onClick={handleRemoveAllItemsDialog}>
                        {translateText(48)}
                    </Button>
                    {creatingCostEstimationVoucher ? (
                        <Loader size="small" />
                    ) : (
                        <Button color="success" disabled={disableButtons} onClick={handleSave}>
                            {translateText(9)}
                        </Button>
                    )}
                    <TransferButtons workTaskId={workTask.id} disabled={disableButtons} mode={ESendCostEstimationMode.CostEstimation} />
                    {useSendOrder && workTaskGsiContext !== EGsiContext.BoschOs3 && (
                        <WorkTaskOrderButtonComponent
                            textId={485}
                            workTaskId={workTask.id}
                            orderGroups={basket.state.basketOrderGroups}
                            orderGroupsLoading={workTaskBasketLoading}
                        />
                    )}
                    {userContext?.parameter.orderingType !== OrderingType.CentralOrderingWithoutWorkTaskOrder && !hideBasket && hasWorkTaskOrder && (
                        <Button
                            color="highlight"
                            endIcon={<Icon name={orderButtonIcon || "orders"} />}
                            onClick={openOrderUrl}
                            disabled={disableButtons || sendingPreAction}
                        >
                            {translateText(orderButtonTextId || "50")}
                        </Button>
                    )}
                </StackRow>
            </ModuleHeaderSlot>
        </StackRow>
    )
}

export default function Wrapper(props: Props) {
    const workTask = useWorkTask()?.workTask
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <ActionButtons {...props} workTask={workTask} />
        </Suspense>
    )
}
