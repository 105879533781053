import { getStoredAuthorization, ajax, uniqueId } from "@tm/utils"
import { Container } from "@tm/nexus"
import { WorkTaskReadModel, RegisteredModels, CisCustomerContainer } from "@tm/models"
import { getBundleParams } from "../../utils"
import { mapWorktask, mapWorktaskInfo } from "../mapper"
import { WorktaskHistoryState } from "../../components/worktask-history/business"

export type HistoryVehicle = {
    description: string
    id: string
    modifiedDate: Date
    registrationNumber?: string
    plateId?: string
    vin?: string
    registrationTypeId?: number
}

export type WorktaskInfo = {
    id: string
    status: number
    partnerNo: string
    company: string
    createdDate: Date
    employee: string
    commision: string
    vehicle: string
    plateId: string
    cooperationMemberDisplayName: string
    cooperationMemberId: string
    orderNo: string
    partsCount: number
    customerId: string
    vehicleId: string
    refCustomerNo: string
    registrationNumber: string
    vin: string
    vehicleHistory: HistoryVehicle[]
}

export type Worktask = WorkTaskReadModel & {
    statusModifiedDate?: Date
}

export type Employee = {
    id: string
    name: string
}

export type FindEmployeeRequest = {
    cooperationMemberIds?: string[]
}

export type Sort = {
    field: string
    desc: boolean
}

export type FindWorktaskQuery = {
    page: number
    pageSize: number
    dateFilter?: WorktaskHistoryState["dateFilter"]
    query?: string
    employeeId?: string
    cooperationMemberId?: string
    status?: number
    sort?: Sort
}

type FindOutletResponse = { salesOutlets: Record<string, string>; defaultSalesOutlet: string }

export type Outlet = { id: string; name: string; default: boolean }

export function findWorktasks(query: FindWorktaskQuery): Promise<{ worktasks: WorktaskInfo[]; pageIndex: number }> {
    const slitDate = (date: Date): string => {
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
        return date.toISOString().split("T")[0]
    }

    return new Promise((resolve, reject) => {
        const url = `${getBundleParams().telesalesServiceUrl}/findworktasks`
        const authorization = getStoredAuthorization()

        const body: any = {
            query: query.query,
            userId: query.employeeId,
            status: query.status,
            pageIndex: query.page,
            pageSize: query.pageSize,
            ...(query.cooperationMemberId ? { cooperationMemberIds: [query.cooperationMemberId] } : {}),
            ...(query.dateFilter?.from ? { dateFrom: slitDate(query.dateFilter.from) } : {}),
            ...(query.dateFilter?.to ? { dateTo: slitDate(query.dateFilter.to) } : {}),
        }

        if (query.sort) {
            body.orderBy = query.sort.field
            body.orderByDesc = query.sort.desc
        }

        return ajax({
            url,
            body,
            authorization,
            method: "GET",
        }).then(
            (data) =>
                resolve({
                    worktasks: data?.workTasks ? data.workTasks.map(mapWorktaskInfo) : [],
                    pageIndex: data.pageIndex,
                }),
            reject
        )
    })
}

export function showWorktask(workTaskId: string): Promise<WorkTaskReadModel | undefined> {
    return new Promise((resolve, reject) => {
        const url = `${getBundleParams().telesalesServiceUrl}/showWorktask`
        const authorization = getStoredAuthorization()
        const body = { workTaskId }

        return ajax({
            url,
            body,
            authorization,
            method: "GET",
        }).then((data) => resolve(data ? mapWorktask(data.workTask) : undefined), reject)
    })
}

export function createWorktask(customerId: string) {
    return new Promise<WorkTaskReadModel>((resolve, reject) => {
        const url = `${getBundleParams().worktaskServiceUrl}/createWorktask`
        const authorization = getStoredAuthorization()

        const body = {
            workTaskId: uniqueId(),
            customerId,
        }

        const result: WorkTaskReadModel = {
            ...body,
            statusValue: 5,
            telesalesStatusValue: 0,
        }

        return ajax({ url, body, authorization, method: "POST" }).then(() => resolve(result), reject)
    })
}

export function findEmployees(query: FindEmployeeRequest): Promise<Employee[]> {
    return new Promise((resolve, reject) => {
        const url = `${getBundleParams().telesalesServiceUrl}/findEmployees`
        const authorization = getStoredAuthorization()
        const body = query

        return ajax({ url, body, authorization, method: "GET" }).then((data) => resolve(data?.employees || []), reject)
    })
}

export function searchPartner(query: string, queryTypeId: number) {
    const container = Container.getInstance(RegisteredModels.CIS_Customer) as CisCustomerContainer
    return container.action("findCustomers")({ query, queryTypeId })
}

const mapOutletToExpected = (outlets: FindOutletResponse): Outlet[] => {
    return Object.keys(outlets.salesOutlets).map((outletKey) => ({
        id: outletKey,
        name: outlets.salesOutlets[outletKey],
        default: outletKey === outlets.defaultSalesOutlet,
    }))
}

export const loadOutlet = (): Promise<Outlet[]> => {
    return new Promise((resolve, reject) => {
        const url = `${getBundleParams().telesalesServiceUrl}/FindSalesOutlet`
        const authorization = getStoredAuthorization()
        const body = {}

        return ajax<FindOutletResponse>({
            url,
            body,
            authorization,
            method: "GET",
        }).then((data) => {
            if (!data || !data.salesOutlets) {
                return resolve([])
            }
            return resolve(mapOutletToExpected(data))
        }, reject)
    })
}

export const completeWorkTask = (workTaskId: string): Promise<void> => {
    const url = `${getBundleParams().telesalesServiceUrl}/CompleteWorkTask?worktaskId=${workTaskId}`
    const authorization = getStoredAuthorization()

    return ajax({
        url,
        authorization,
        method: "POST",
    })
}

export const partialOrderWorkTask = (workTaskId: string): Promise<void> => {
    const url = `${getBundleParams().telesalesServiceUrl}/PartialOrderWorkTask?worktaskId=${workTaskId}`
    const authorization = getStoredAuthorization()

    return ajax({
        url,
        authorization,
        method: "POST",
    })
}

export const abortWorkTask = (workTaskId: string): Promise<void> => {
    const url = `${getBundleParams().telesalesServiceUrl}/AbortWorkTask?worktaskId=${workTaskId}`
    const authorization = getStoredAuthorization()

    return ajax({
        url,
        authorization,
        method: "POST",
    })
}
