import { useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { ExternalModule } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createQueryString, encodeUniqueId } from "@tm/utils"
import { useHistory } from "react-router"
import { getExternalCatalogUrl } from "../data/repositories/url"

enum TargetType {
    Modal = 0,
    Tab = 1,
    IFrame = 2,
}

export function useOpenExternalCatalogPage() {
    const user = useUser()
    const { userContext } = user
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const history = useHistory()
    const { workTaskId } = useWorkTask() ?? {}

    function getTargetType(externalSystem: ExternalModule) {
        const targetKey = externalSystem?.parameter?.find((x) => x.key.toLocaleLowerCase() === "target")?.value
        switch (targetKey) {
            case "1":
                return TargetType.Modal
            case "2":
                return TargetType.Tab
            // We choose IFrame also as default if no parameter is configured in the MDM.
            // This was a dev-decision. Feel free to make changes if a STORY asks you to do so.
            case "3":
            default:
                return TargetType.IFrame
        }
    }

    async function openTab(externalModuleId: number) {
        const targetUrl = await getExternalCatalogUrl(externalModuleId, telesalesCustomerNo, userContext)
        if (!targetUrl || !targetUrl.length) {
            console.error(`Failed open external catalog page. The external module with id ${externalModuleId} has no target URL defined in the MDM.`)
            return
        }
        window.open(targetUrl, "_blank")
    }

    function getModalUrl(externalModuleId: number | undefined) {
        if (workTaskId) {
            return `/${encodeUniqueId(workTaskId)}/^modal/externalId/${createQueryString({ id: externalModuleId })}`
        }
        return `/^modal/externalId/${createQueryString({ id: externalModuleId })}`
    }

    function getIFrameUrl(externalModuleId: number | undefined) {
        if (workTaskId) {
            return `/${encodeUniqueId(workTaskId)}/externalId/${createQueryString({ id: externalModuleId })}`
        }
        return `/external/${createQueryString({ id: externalModuleId })}`
    }

    // Please note, for modal and i-frame we want to use the i-frame-extid component.
    // For the modal and an i-frame with worktask context, the routes is /externalId.
    // For the i-frame without worktask context, the name is /external.
    async function openTarget(targetType: TargetType, externalModuleId: number) {
        switch (targetType) {
            case TargetType.Modal:
                Morpheus.showView("1", getModalUrl(externalModuleId))
                return
            case TargetType.Tab:
                await openTab(externalModuleId)
                return
            // We choose TargetType.IFrame as the default in getTargetType().
            // Therefore it is safe to also use this as the default behavior in this switch case.
            case TargetType.IFrame:
            default:
                history.push(getIFrameUrl(externalModuleId))
        }
    }

    async function openExternalCatalogPage(externalModuleId: number) {
        const externalModule = userContext.externalModules?.find((ext) => ext?.id === externalModuleId)
        if (!externalModule) {
            console.error(`Failed open external catalog page. No external module with the id ${externalModuleId} was found.`)
            return
        }

        const targetType = getTargetType(externalModule)

        openTarget(targetType, externalModuleId)
    }

    return openExternalCatalogPage
}
