import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce } from "./business"

const WidgetCoverOnly: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "widget-cover-only",
    component,
    reduce,
}

export default WidgetCoverOnly
