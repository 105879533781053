import { Box, BoxProps, Divider, styled, Typography } from "@tm/components"
import { ReactNode } from "react"

const StyledBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    gap: "0.5em",
})

type Props = BoxProps & {
    children: ReactNode
    title?: string
    hideDivider?: boolean
}

export function HeaderItem({ title, children, hideDivider, ...rest }: Props) {
    const getTitle = () => {
        if (title) {
            return <Typography variant="label">{title}</Typography>
        }

        return (
            <Typography variant="label" sx={{ visibility: "hidden" }}>
                &nbsp;
            </Typography>
        )
    }

    return (
        <StyledBox {...rest}>
            {!hideDivider && <Divider sx={{ height: "60%", borderRightWidth: "2px" }} flexItem orientation="vertical" />}
            <Box display="flex" alignItems="flex-start" flexDirection="column" gap="4px" marginTop="4px">
                {getTitle()}
                {children}
            </Box>
        </StyledBox>
    )
}
