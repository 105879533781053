import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { ConfigParams, Item, ReturnStatus } from "@tm/models"
import { Stack, Checkbox, Button, Loader, Switch, Icon, Tooltip, Box } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { ReturnItem } from "../../../data/model"
import RemoveItemsDialog from "../../_shared/RemoveItemsDialog"

type Props = {
    returnItem: ReturnItem
    isSelected: boolean
    isUpdating: boolean
    disablingReason: string
    onChangeReturnStatus(items: Array<Item>, includeInReturn: boolean): void
    onRemoveItems(id: Array<ReturnItem>): void
    onSelectItem(itemId: string): void
}

export default function PartActionsComponent(props: Props) {
    const { returnItem, isSelected, isUpdating, disablingReason, onChangeReturnStatus, onRemoveItems, onSelectItem } = props
    const { returnReasonId, isReturnInfoRequired, returnInfo, canReturn } = returnItem
    const { translateText } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()

    const [partWillBeRemoved, setPartWillBeRemoved] = useState(false)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const disableReturnStatus = !returnReasonId || (isReturnInfoRequired && !returnInfo) || isUpdating || !canReturn

    function handleChangeReturnStatus(): void {
        onChangeReturnStatus([{ id: returnItem.id, version: returnItem.version }], returnItem.returnStatus !== ReturnStatus.IncludedInReturn)
    }

    function handleRemovePartClick() {
        setPartWillBeRemoved(true)
        setShowDeleteDialog(true)
    }

    function closeDeleteDialog() {
        setPartWillBeRemoved(false)
        setShowDeleteDialog(false)
    }

    function deleteCurrentItem() {
        onRemoveItems([returnItem])
    }

    function handleItemCheck() {
        onSelectItem(returnItem.id)
    }

    return (
        <Stack direction="row" spacing={0.5}>
            <Tooltip title={disablingReason}>
                <Box>
                    <Switch
                        labelPlacement="end"
                        size="small"
                        disabled={disableReturnStatus}
                        label={translateText(1515)}
                        checked={returnItem.returnStatus === ReturnStatus.IncludedInReturn}
                        onChange={handleChangeReturnStatus}
                    />
                </Box>
            </Tooltip>
            <Button
                title={translateText(624)}
                disabled={isUpdating}
                onClick={handleRemovePartClick}
                startIcon={<Icon name={!partWillBeRemoved ? "delete" : undefined} />}
                color={!partWillBeRemoved || deleteButtonColorError ? "error" : undefined}
            >
                {partWillBeRemoved && <Loader size="extrasmall" />}
            </Button>
            <RemoveItemsDialog
                dialogTextId={833}
                showDeleteDialog={showDeleteDialog}
                onCloseRemoveDialog={closeDeleteDialog}
                onRemoveReturnItems={deleteCurrentItem}
            />
            <Checkbox checked={isSelected || false} onChange={handleItemCheck} disabled={isUpdating} />
        </Stack>
    )
}
