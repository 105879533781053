import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { UserModuleType } from "@tm/models"

export function useCostEstimationModuleParameter() {
    const { userContext } = useUser()
    return useMemo(() => {
        const costEstimationModule = userContext.modules?.find((x) => x.type === UserModuleType.CostEstimation)

        return {
            hasCostEstimation: !!costEstimationModule,
            customerGroup: costEstimationModule?.parameters?.find((x) => x.key === "CustomerGroup")?.value,
            priceModus: costEstimationModule?.parameters?.find((x) => x.key === "PriceModus")?.value,
            priceTypes: costEstimationModule?.parameters?.find((x) => x.key === "PriceTypes")?.value,
            useSendCostEstimation: costEstimationModule?.parameters?.find((x) => x.key === "UseSendCostEstimation")?.value === "1",
            useSendOrder: costEstimationModule?.parameters?.find((x) => x.key === "UseSendOrder")?.value === "1",
        }
    }, [userContext])
}
