import { CisAddress } from "@tm/models"
import { Stack, Typography } from "@tm/components"
import Field from "./Field"

type Props = {
    address: CisAddress
}

export default function AddressComponent({ address }: Props) {
    const { description, street, addressAddition, zip, city, country, phone } = address

    return (
        <Field title={description || ""}>
            <Stack>
                <Typography>
                    {street} {addressAddition}
                </Typography>
                <Typography>
                    {zip} {city}
                </Typography>
                <Typography>{country}</Typography>
                <Typography>{phone}</Typography>
            </Stack>
        </Field>
    )
}
