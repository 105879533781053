const STORAGEIDENTIFIER = "MVC-FEEDBACK"

function getLocalStorageKey(workTaskId: string): string {
    return `${workTaskId}_${STORAGEIDENTIFIER}`
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function saveToLocalStorage(workTaskId: string, data: any, expirationTimestamp?: number) {
    const key = getLocalStorageKey(workTaskId)

    const dataToSave = expirationTimestamp ? { ...data, expirationTimestamp } : data

    const serializedObj = JSON.stringify(dataToSave)
    localStorage.setItem(key, serializedObj)
}

export function getFromLocalStorage<T>(workTaskId: string): T | undefined {
    const key = getLocalStorageKey(workTaskId)
    const serializedObj = localStorage.getItem(key)

    if (serializedObj) {
        const parsedObj = JSON.parse(serializedObj)
        const { expirationTimestamp, ...obj } = parsedObj

        if (!expirationTimestamp || expirationTimestamp > Date.now()) {
            return obj as T
        }

        localStorage.removeItem(key)
    }

    return undefined
}

export function deleteFromLocalStorage(workTaskId: string) {
    const key = getLocalStorageKey(workTaskId)
    localStorage.removeItem(key)
}

export function getExpirationTimestampHours(hours: number): number {
    const expirationMilliseconds = hours * 60 * 60 * 1000
    return Date.now() + expirationMilliseconds
}

export function getExpirationTimestampMinutes(minutes: number): number {
    const expirationMilliseconds = minutes * 60 * 1000
    return Date.now() + expirationMilliseconds
}
