import { RegistrationNoDetails, VehicleType } from "@tm/models"

import { DictionaryItem } from "./Dictionary"

export const mapVrmDetailsToDictionary = (details: RegistrationNoDetails, translateText: (id: number) => string): Array<DictionaryItem> => {
    const vrmInfo: Array<DictionaryItem> = []

    // if(details.manufacturer && details.manufacturer !== "") {
    //     vrmInfo.push({ key: "manufacturer", value: details.manufacturer })
    // }

    // if(details.modelSeries && details.modelSeries !== "") {
    //     vrmInfo.push({ key: "modelSeries", value: details.modelSeries })
    // }

    // if(details.model && details.model !== "") {
    //     vrmInfo.push({ key: "model", value: details.model })
    // }

    if (details.chassisNumber && details.chassisNumber !== "") {
        vrmInfo.push({ key: translateText(13635), value: details.chassisNumber })
    }

    if (details.manufacturingDate && typeof details.manufacturingDate === "object") {
        vrmInfo.push({ key: translateText(13636), value: details.manufacturingDate.toLocaleDateString() })
    }

    // tecdoc data
    if (details.engineNumber && details.engineNumber !== "") {
        vrmInfo.push({ key: translateText(105), value: details.engineNumber })
    }

    if (details.engineModel && details.engineModel !== "") {
        vrmInfo.push({ key: translateText(13633), value: details.engineModel })
    }

    if (details.hasTurbo && details.hasTurbo !== "") {
        vrmInfo.push({ key: translateText(1399), value: details.hasTurbo })
    }

    if (details.fuelType && details.fuelType !== "") {
        vrmInfo.push({ key: translateText(299), value: details.fuelType })
    }

    if (details.engineCapacity && details.engineCapacity !== "") {
        vrmInfo.push({ key: translateText(128), value: details.engineCapacity })
    }

    if (details.enginePowerKw && details.enginePowerKw !== "" && details.enginePowerHp !== "") {
        vrmInfo.push({ key: translateText(20), value: `${details.enginePowerKw}/${details.enginePowerHp}` })
    }

    if (details.transmissionType && details.transmissionType !== "") {
        vrmInfo.push({ key: translateText(1366), value: details.transmissionType })
    }

    if (details.gearCount && details.gearCount !== "") {
        vrmInfo.push({ key: translateText(1398), value: details.gearCount })
    }

    if (details.bodyStyle && details.bodyStyle !== "") {
        vrmInfo.push({ key: translateText(1400), value: details.bodyStyle })
    }

    if (details.cylinderCount && details.cylinderCount !== "") {
        vrmInfo.push({ key: translateText(305), value: details.cylinderCount })
    }

    if (details.valveCount && details.valveCount !== "") {
        vrmInfo.push({ key: translateText(304), value: details.valveCount })
    }

    if (details.color && details.color !== "") {
        vrmInfo.push({ key: translateText(944), value: details.color })
    }

    // tecdoc data - tecdocid
    if (details.modelId && details.modelId !== "") {
        vrmInfo.push({ key: translateText(123), value: details.modelId })
    }

    if (details.typScheinNr && details.typScheinNr !== "") {
        vrmInfo.push({ key: translateText(12718), value: details.typScheinNr })
    }

    if (details.constructionYearFrom && typeof details.constructionYearFrom === "object" && typeof details.constructionYearTo === "object") {
        vrmInfo.push({
            key: translateText(127),
            value: `${details.constructionYearFrom.month}/${details.constructionYearFrom.year} - ${details.constructionYearTo.month}/${details.constructionYearTo.year}`,
        })
    }

    if (details.brakes && details.brakes !== "") {
        vrmInfo.push({ key: translateText(13637), value: details.brakes })
    }

    if (details.importCondition && details.importCondition !== "") {
        vrmInfo.push({ key: translateText(13638), value: details.importCondition })
    }

    if (details.countryOfOrigin && details.countryOfOrigin !== "") {
        vrmInfo.push({ key: translateText(13639), value: details.countryOfOrigin })
    }

    if (details.foundBySearchTerm && details.foundBySearchTerm !== "") {
        vrmInfo.push({ key: translateText(13640), value: details.foundBySearchTerm })
    }

    if (details.vehicleType) {
        vrmInfo.push({ key: translateText(610), value: VehicleType[details.vehicleType] })
    }

    if (details.vehicleTypeName && details.vehicleTypeName !== "") {
        vrmInfo.push({ key: translateText(13634), value: details.vehicleTypeName })
    }

    if (details.container && details.container !== "") {
        vrmInfo.push({ key: translateText(13641), value: details.container })
    }

    if (details.containerName && details.containerName !== "") {
        vrmInfo.push({ key: translateText(13642), value: details.containerName })
    }

    if (details.datECode && details.datECode !== "") {
        vrmInfo.push({ key: translateText(13643), value: details.datECode })
    }

    return vrmInfo
}
