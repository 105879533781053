import { useLocalization } from "@tm/localization"
import { Button, CellContentPosition, Icon, Table, TableCellData, TableColumnData, Typography, styled } from "@tm/components"
import { getCategoryOfWorkDescriptionTextId, useVatRateText } from "@tm/utils"
import { useState } from "react"
import { VoucherWork } from "@tm/data/vouchers/costEstimations"
import HourlyRate from "./HourlyRate"
import IncludedWorksTable from "./IncludedWorksTable"

type Props = {
    currencySymbol?: string
    currencyCode?: string
    works?: VoucherWork[]
    showRepairTimesInHours: boolean
}

const ColumnHeader = styled(Typography)({})
ColumnHeader.defaultProps = { variant: "body2" }

export default function WorksTable({ works, showRepairTimesInHours, currencyCode, currencySymbol }: Props) {
    const { translate, translateText, number, currency } = useLocalization()
    const { vatRateTypeDisplayShortText } = useVatRateText(translateText)
    const [expandedRows, setExpandedRow] = useState<string[]>([])

    function handleShowHideIncludes(workId: string) {
        if (expandedRows.includes(workId)) {
            setExpandedRow(expandedRows.filter((e) => e !== workId))
        } else {
            setExpandedRow([...expandedRows, workId])
        }
    }

    function getColumns() {
        const repairTimesTextId = showRepairTimesInHours ? 84 : 1550
        const columns: TableColumnData[] = [
            { header: "" }, // showIncludes
            { header: <ColumnHeader>{translate(58)}</ColumnHeader> }, // provicer work id
            { header: <ColumnHeader>{translate(25)}</ColumnHeader> }, //  description
            { header: <ColumnHeader>{translate(278)}</ColumnHeader> }, // Provider
            { header: <ColumnHeader>{translate(59)}</ColumnHeader> }, // Category
            { header: <ColumnHeader>{translate(63)}</ColumnHeader>, alignContent: CellContentPosition.right }, // Hourly rate
            { header: <ColumnHeader>{translate(repairTimesTextId)}</ColumnHeader>, alignContent: CellContentPosition.right }, // Net time
            {
                header: <ColumnHeader>{`${translate(repairTimesTextId)} ${translate(85)}`}</ColumnHeader>,
                alignContent: CellContentPosition.right,
            }, // Gross time
            { header: <ColumnHeader>{translate(54)}</ColumnHeader>, alignContent: CellContentPosition.right }, // Rebate
            { header: <ColumnHeader>{translate(706)}</ColumnHeader>, alignContent: CellContentPosition.right }, // vatRate
            { header: <ColumnHeader>{translate(57)}</ColumnHeader>, alignContent: CellContentPosition.right }, // workPriceCalculated
        ]

        return columns
    }

    function getModuleCellData(work: VoucherWork): TableCellData[] {
        const { calculation, includedWorks, providerWorkId, description, providerName, categoryShortCode } = work
        const { vatRate, repairTime, repairTimeCalculated, rebate, offerPrice } = calculation
        const cellData: TableCellData[] = [
            {
                displayValue: (
                    <Button
                        disabled={!includedWorks.length}
                        startIcon={<Icon name={expandedRows.includes(providerWorkId) ? "up" : "down"} />}
                        title={translateText(61)}
                        onClick={() => handleShowHideIncludes(providerWorkId)}
                        variant="text"
                    />
                ),
                id: "work_1",
            },
            { displayValue: providerWorkId, id: "work_2" },
            { displayValue: description, id: "work_3" },
            { displayValue: providerName, id: "work_4" },
            {
                displayValue: <div title={translateText(getCategoryOfWorkDescriptionTextId(categoryShortCode))}>{categoryShortCode}</div>,
                id: "work_5",
            },
            {
                displayValue: <HourlyRate voucherWork={work} currencyCode={currencyCode} currencySymbol={currencySymbol} />,
                id: "work_6",
            },
            { displayValue: repairTime ? number(repairTime) : "-", id: "work_7" },
            { displayValue: repairTimeCalculated ? number(repairTimeCalculated) : "-", id: "work_8" },
            { displayValue: !!rebate && `${rebate.round(0.01)}%`, id: "work_9" },
            { displayValue: vatRate && vatRateTypeDisplayShortText(vatRate), id: "work_10" },
            { displayValue: offerPrice && currency(offerPrice, currencySymbol ?? currencyCode ?? ""), id: "work_11" },
        ]

        return cellData
    }

    const displayData = works?.map((work, index) => ({
        cells: getModuleCellData(work),
        id: `${index}`,
        active: false,
        extendedContent: expandedRows.includes(work.providerWorkId) && <IncludedWorksTable includedWorks={work.includedWorks} />,
    }))

    return <Table columns={getColumns()} rows={displayData} headerBackground="transparent" rowCap={4} />
}
