import { channel } from "@tm/models"
import { PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"

export default function handleShowToastMessage(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (!data.showToastMessage) {
        return
    }

    const { skin, message } = data.showToastMessage

    if (!message) {
        return
    }

    channel("APP").publish("TOAST_MESSAGE/SHOW", { message, skin })
}
