import { ETyresCarType, ETyresSeason, TyreFilter } from "@tm/models"
import { getSelectedCriteria, getSelectedCriteriaForSummary } from "."
import { getBundleParams } from "../../../../utils"
import { GetArticleListByMatchCodeRequest } from "../../../../data/repositories"
import { MainState } from "../../../main"
import { Statics } from "../../../../data/statics"

export function createArticleListRequest(state: MainState, byMatchCode?: boolean, fromFilter?: boolean, summaryFilter?: boolean, searchFromCoCData?: boolean): GetArticleListByMatchCodeRequest | undefined {
    const { searchValue, lastSearch: { carType } } = state.list

    const selectedCriteria: TyreFilter[] = summaryFilter ? getSelectedCriteriaForSummary(state.summary) : getSelectedCriteria(state.list, byMatchCode)

    const selectedCarTypes = byMatchCode || !carType ? Statics.getCarTypes().map(x => +x.query) : carType
   
    const lastCharFromQuery = searchValue.charAt(searchValue.length - 1)
    const query = (
        searchFromCoCData && 
        state.summary.selectedFilters?.season?.value == ETyresSeason.winter && 
        lastCharFromQuery.match(/[a-z]/i)
    ) ? searchValue.replace(lastCharFromQuery, "") : searchValue
    
    if (!searchValue) {
        return
    }

    const { pageSize, traderReferenceId } = getBundleParams()

    return {
        pageIndex: 1,
        pageSize,
        query: query.trim(),
        searchLevel: 0,
        selectedCriteria,
        fromFilter,
        traderReferenceId,
        carTypes: selectedCarTypes as ETyresCarType[],
    }
}