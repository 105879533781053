import { Icon, Widget } from "@tm/controls"
import { SelectCalendarType, SelectCalendarTypeProps } from "./select-calendar-type"

export function Header(props: SelectCalendarTypeProps) {
    return (
        <Widget.Header>
            <div className="widget__title">
                <Icon name="calendar" className="widget__icon" />
                {/* <div className="text">Termine</div> */}
            </div>
            <SelectCalendarType {...props} />
        </Widget.Header>
    )
}
