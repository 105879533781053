import { BundleComponent } from "@tm/morpheus"

import { receive, reduce } from "./business"
import component from "./component"

const selections: BundleComponent<unknown, typeof component> = {
    name: "selections",
    component,
    reduce,
    receive,
}

export default selections
