import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, encodeUniqueId, getRepairTimeProvidersByNames, RouteComponentProps } from "@tm/utils"
import { Toolbar, Dropdown } from "@tm/controls"
import { RegisteredModels, RepairTimeProvider, HasRepairTimesRequest, RepairTimeProviderConfig, ActiveVehicleDataProviders } from "@tm/models"
import { Container } from "@tm/nexus"
import { useUser, useWorkTask } from "@tm/context-distribution"
import getVehicle from "../../data/helpers/getVehicle"
import { useHasRepairTimes } from "../../data/hooks/useHasRepairTimes"

type RepairTimeProviderConfigDisabledBool = Omit<RepairTimeProviderConfig, "disabled"> & {
    disabled: boolean
}

type RouteProps = {
    provider: string
    supplierId: string
    supplierArticleNo: string
    productGroupId: string
    position?: string
}

type Props = RouteComponentProps<RouteProps> & {
    items: { [key: string]: RepairTimeProviderConfig }
    title: string
}

function ProviderDropdownComponent(props: Props) {
    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser() || {}
    const { workTask } = useWorkTask() || {}
    const { match, items, title, history } = props
    const { productGroupId, position, provider } = match.params

    const vehicle = getVehicle(workTask?.vehicle, userContext?.system.systemType)
    const rtProviders = Object.keys(items).map((key) => items[key].id)
    const providers = getRepairTimeProvidersByNames(rtProviders)
    const request: HasRepairTimesRequest | undefined =
        vehicle && providers.length
            ? {
                  repairTimeProvider: providers,
                  modelId: vehicle.tecDocTypeId,
                  productGroupIds: [parseInt(productGroupId)],
                  vehicleType: vehicle.vehicleType,
                  fittingSideFilter: position ? parseInt(position) : undefined,
              }
            : undefined
    const { availableProviders } = useHasRepairTimes(request)

    function handleChange(item: RepairTimeProviderConfigDisabledBool) {
        if (item.disabled) {
            return
        }

        if (userSettings && workTask) {
            const key = `${encodeUniqueId(workTask.id)}__ACTIVE_VEHICLE_DATA_PROVIDERS`
            const subscription = Container.getInstance<{ key: string; value?: ActiveVehicleDataProviders }>(RegisteredModels.ViewState).subscribe(key)
            subscription.load().then((state) => {
                subscription.save?.({ key, value: { ...state.value, repairTimes: item.id } })
            })
        }

        const url = renderRoute(match.path, { ...match.params, provider: item.id })
        history.push(url)
    }

    const filteredItems = Object.keys(items)
        .map((key) => {
            let disabled = true

            if (availableProviders) {
                switch (items[key].id) {
                    case "awdoc":
                        disabled = availableProviders.indexOf(RepairTimeProvider.AwDocCar) === -1
                        break
                    case "autodata":
                        disabled = availableProviders.indexOf(RepairTimeProvider.Autodata) === -1
                        break
                    case "haynespro":
                        disabled = availableProviders.indexOf(RepairTimeProvider.HaynesProCar) === -1
                        break
                    case "tecrmi":
                        disabled = availableProviders.indexOf(RepairTimeProvider.TecRmiCar) === -1
                        break
                    default:
                        break
                }
            }

            return { ...items[key], disabled }
        })
        .filter((x) => !!x.id)
        .orderBy((x) => x.sort ?? Number.MAX_VALUE)

    const selected = filteredItems.find((item) => item.id === provider)

    if (selected?.disabled) {
        const fallback = filteredItems.find((item) => !item.disabled)
        if (fallback) {
            handleChange(fallback)
        }
    }

    return (
        <div className="tk-repair-times provider-dropdown">
            <Toolbar title={translateText(title)}>
                <Dropdown items={filteredItems} itemView={NavigationItem} onChange={handleChange} value={selected} />
            </Toolbar>
        </div>
    )
}

export default withRouter(ProviderDropdownComponent)

function NavigationItem(props: RepairTimeProviderConfigDisabledBool) {
    return <>{props.name || null}</>
}
