import { ArticleInfoType, PartInfo, PartProductGroup } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"

import { getBundleParams } from "../../utils"
import { OrderedPartByVehicle } from "../model/vouchers"

function getVouchersServiceUrl() {
    return getBundleParams().vouchersServiceUrl
}

export type ShowOrderedPartsByVehicleResponse = {
    vehcicleId: string
    orderedParts: Array<OrderedPartByVehicle>
}

export function showOrderedPartsByVehicle(vehicleId: string) {
    return ajax<ShowOrderedPartsByVehicleResponse>({
        url: `${getVouchersServiceUrl()}/Orders/ShowOrderedPartsByVehicle`,
        authorization: getStoredAuthorization(),
        body: { vehicleId },
    }).then(mapShowOrderedPartsByVehicleResponse)
}

function mapShowOrderedPartsByVehicleResponse(data: any): ShowOrderedPartsByVehicleResponse | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        orderedParts: (data.orderedParts || []).map(mapOrderedPartByVehicle),
    }
}

function mapOrderedPartByVehicle(data: any): OrderedPartByVehicle | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        articleInfo: mapArticleInfo(data.articleInfo, data.articleInfoType),
        orderDate: data.orderDate ? new Date(data.orderDate) : undefined,
    }
}

function mapArticleInfo(data: any, articleInfoType: ArticleInfoType): PartInfo | undefined {
    if (!data) {
        return
    }

    switch (articleInfoType) {
        case ArticleInfoType.CustomArticle:
            return {
                ...data,
                dataSupplierArticleNumber: data.articleNumber,
            }
        case ArticleInfoType.TecdocArticle:
            return {
                ...data,
                dataSupplier: data.dataSupplier,
                dataSupplierArticleNumber: data.dataSupplierArticleNumber,
                productGroup: data.productGroup,
            }
        case ArticleInfoType.OeArticle:
            return {
                ...data,
                dataSupplier: data.vehicleManufacturer,
                dataSupplierArticleNumber: data.oeArticleNumber,
                productGroup: mapOeArticleProductGroup(data.productGroupId),
            }
        default:
            break
    }

    return data
}

function mapOeArticleProductGroup(data: any): PartProductGroup | undefined {
    return {
        ...data,
        id: data || undefined,
        name: "-",
    }
}
