import { MileType, TelematicsProvider, Vehicle } from "@tm/models"
import { useMutation, useQuery, useQueryClient } from "react-query"
import * as Data from "../../../data"

export function useTelematicsData(provider: TelematicsProvider, vehicle: Vehicle | undefined) {
    const key = ["telematics_useTelematicsData", provider, vehicle?.vin]
    const { data, isLoading } = useQuery(
        key,
        () => {
            if (vehicle?.vin) {
                return Data.showTelematicsData(provider, vehicle.vin)
            }
        },
        {
            enabled: !!vehicle?.vin,
            onSuccess: (response) => {
                if (response?.mileage && vehicle && response.mileage.value !== vehicle.mileAge) {
                    Data.updateVehicle({
                        ...vehicle,
                        mileAge: response.mileage.value,
                        mileType: MileType.Kilometer,
                    })
                }
            },
        }
    )

    const queryClient = useQueryClient()
    const { mutate: updateData, isLoading: updatingData } = useMutation(
        async () => {
            if (vehicle?.vin) {
                return Data.updateTelematicsData(provider, vehicle.vin)
            }
        },
        {
            onSuccess: (response) => {
                queryClient.setQueryData(key, response)
            },
        }
    )

    return { telematicsData: data, telematicsDataLoading: isLoading, updateData, updatingData }
}
