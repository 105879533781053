import { Button, Card, PanelSection, RangeSlider, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import * as React from "react"
import { batch, useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Color } from "../../../data/model"
import { MainState } from "../../main"
import { Actions } from "../business"

type Props = {}

const selector = createSelector((s: MainState) => ({
  withSlider: s.wheelsList.configurator.show3D,
  colors: s.wheelsList.configurator.colors,
  show3D: s.wheelsList.configurator.show3D,
  configuratorHeight: s.wheelsList.configurator.height,
  configuratorTint: s.wheelsList.configurator.tint,
  selectedColor: s.wheelsList.configurator.selectedColor
}), x => x)

const RimSettings: React.FC<Props> = ({ }) => {
  const { colors, withSlider, show3D, configuratorHeight, configuratorTint, selectedColor } = useSelector(selector)
  const actions = useActions(Actions, "update2DConfigurator", "changeConfiguratorColor", "changeRimInfoAndSettingVisibility", "changeConfiguratorTint", "changeConfiguratorHeight")
  const [tintSliderValue, setTintSliderValue] = React.useState(configuratorTint / 10)
  const [heightSliderValue, setHeightSliderValue] = React.useState(-configuratorHeight)
  const { translateText } = useLocalization()

  const handleColorSelect = (color: string) => {
    if (show3D) {
      actions.changeConfiguratorColor(color)
    } else {
      batch(() => {
        actions.changeConfiguratorColor(color)
      })
    }
  }

  const handleButtonValue = (value: number) => {
    setHeightSliderValue(value)
    const newHeight = -value
    actions.changeConfiguratorHeight(newHeight)
  }

  const handleTintChange = (value: number) => {
    setTintSliderValue(value)
    const newTint = 10 * value
    actions.changeConfiguratorTint(newTint)
  }

  const renderColor = (color: Color, idx: number) => {
    return (
      <Card className={`is-clickable ${selectedColor == color.code && "is-active" || ""}`} key={idx} onClick={() => handleColorSelect(color.code)} >
        <div className="color_selection" style={{ backgroundColor: `#${color.code}` }}> </div>
        <div className="color_text" title={color.name}>{color.name}</div>
      </Card>
    )
  }

  const onClose = () => {
    actions.changeRimInfoAndSettingVisibility(false, false)
  }

  return (
    <PanelSection className="rim-settings" >
      <div className="rim-settings__header">
        <Text>{translateText(943)}</Text>
        <Button className="close-btn" onClick={onClose} layout={["ghost"]} icon="close" />
      </div>
      <div className="rim-settings__content">
        {withSlider &&
          <div className="rim-settings__sliders">
            <PanelSection className="slider">
              <Text>{translateText(995)}</Text>
              <RangeSlider max={12} min={0} value={heightSliderValue} onChange={handleButtonValue} />
              <div className="buttons">
                <Button layout={["ghost"]} onClick={handleButtonValue.bind(this, 0)}>0</Button>
                <Button layout={["ghost"]} onClick={handleButtonValue.bind(this, 4)}>4</Button>
                <Button layout={["ghost"]} onClick={handleButtonValue.bind(this, 8)}>8</Button>
                <Button layout={["ghost"]} onClick={handleButtonValue.bind(this, 12)}>12</Button>
              </div>
            </PanelSection>
            <PanelSection className="slider">
              <Text>{translateText(1597)}</Text>
              <RangeSlider max={10} min={0} value={tintSliderValue} onChange={handleTintChange} />
            </PanelSection>
          </div>}

        <div className="rim-settings__colors">
          <Scrollbar>
            {colors.map(renderColor)}
          </Scrollbar>
        </div>
      </div>
    </PanelSection>
  )
}

export default RimSettings