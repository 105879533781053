import { Box, Icon, Typography, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { DropdownField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { TitleType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { getCountryDescriptionByCountryCode } from "@tm/utils"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { summarySelector } from "../../../selectors"
import { MainActions } from "../../main/business"
import { validateFields } from "../business/helper"

export default function CustomerFields() {
    const user = useUser()
    const { translateText } = useLocalization()
    const { fields, isGermanOrAustrianVehicle } = useSelector(summarySelector)
    const modelState = validateFields(fields, translateText, isGermanOrAustrianVehicle)

    const actions = useActions(MainActions, "updateFieldValue", "initCustomer")

    useEffect(() => {
        user?.userContext && !fields.country?.length && actions.initCustomer(getCountryDescriptionByCountryCode(user?.userContext!, translateText))
    }, [fields.country])

    const titleItems: Record<string, string> = {
        0: " ",
        1: translateText(145),
        2: translateText(146),
        3: translateText(108),
    }

    const renderNameFields = () => {
        if (fields.titleType === TitleType.Company) {
            return (
                <Col>
                    <TextField
                        floatingLabel
                        label={translateText(1757)}
                        value={fields.companyName}
                        modelState={modelState}
                        path={["companyName"]}
                        onChangeConfirm={(value) => actions.updateFieldValue("companyName", value)}
                    />
                </Col>
            )
        }

        return (
            <>
                <Col>
                    <TextField
                        floatingLabel
                        label={translateText(119)}
                        value={fields.firstName}
                        modelState={modelState}
                        path={["firstName"]}
                        onChangeConfirm={(value) => actions.updateFieldValue("firstName", value)}
                    />
                </Col>
                <Col>
                    <TextField
                        floatingLabel
                        label={translateText(104)}
                        value={fields.lastName}
                        modelState={modelState}
                        path={["lastName"]}
                        onChangeConfirm={(value) => actions.updateFieldValue("lastName", value)}
                    />
                </Col>
            </>
        )
    }

    return (
        <Box>
            <Title>
                <Icon name="customer" size="2em" />
                <Typography fontSize="large" marginLeft={(theme) => theme.spacing(0.5)}>
                    {translateText(107)}
                </Typography>
            </Title>

            <Fields>
                <FieldsArea>
                    <Col sx={{ width: "7.5em" }}>
                        <DropdownField
                            floatingLabel
                            label={translateText(118)}
                            value={titleItems[fields.titleType || 0]}
                            items={titleItems}
                            onChange={(value) => actions.updateFieldValue("titleType", value)}
                            noCover
                            modelState={modelState}
                            path={["titleType"]}
                        />
                    </Col>

                    {renderNameFields()}
                </FieldsArea>

                <FieldsArea>
                    <Col>
                        <TextField
                            floatingLabel
                            label={translateText(12914)}
                            value={fields.street}
                            modelState={modelState}
                            path={["street"]}
                            onChange={(value) => actions.updateFieldValue("street", value)}
                        />
                    </Col>
                    <Col sx={{ width: "6em" }}>
                        <TextField
                            floatingLabel
                            label={translateText(3152)}
                            value={fields.streetNo}
                            modelState={modelState}
                            path={["streetNo"]}
                            onChangeConfirm={(value) => actions.updateFieldValue("streetNo", value)}
                        />
                    </Col>
                    <Col sx={{ width: "7.5em" }}>
                        <TextField
                            floatingLabel
                            label={translateText(112)}
                            value={fields.zip}
                            modelState={modelState}
                            path={["zip"]}
                            onChangeConfirm={(value) => actions.updateFieldValue("zip", value)}
                        />
                    </Col>
                    <Col>
                        <TextField
                            floatingLabel
                            label={translateText(113)}
                            value={fields.city}
                            modelState={modelState}
                            path={["city"]}
                            onChangeConfirm={(value) => actions.updateFieldValue("city", value)}
                        />
                    </Col>
                    <Col>
                        <TextField
                            floatingLabel
                            label={translateText(114)}
                            value={fields.country}
                            modelState={modelState}
                            path={["country"]}
                            onChangeConfirm={(value) => actions.updateFieldValue("country", value)}
                        />
                    </Col>
                </FieldsArea>
                <FieldsArea>
                    <Col>
                        <TextField
                            floatingLabel
                            label={translateText(109)}
                            value={fields.email}
                            modelState={modelState}
                            path={["email"]}
                            onChangeConfirm={(value) => actions.updateFieldValue("email", value)}
                        />
                    </Col>
                    <Col>
                        <TextField
                            floatingLabel
                            label={translateText(110)}
                            value={fields.phone}
                            modelState={modelState}
                            path={["phone"]}
                            onChangeConfirm={(value) => actions.updateFieldValue("phone", value)}
                        />
                    </Col>
                </FieldsArea>
            </Fields>
        </Box>
    )
}

const Title = styled(Box)(() => ({
    display: "flex",
    padding: ".5em",
    alignItems: "center",
}))

const Fields = styled(Box)(() => ({
    display: "inline-flex",
    flex: 1,
    margin: ".5em",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: ".5em",
}))

const FieldsArea = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
    flexBasis: "100%",
}))

const Col = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0.5, 0, 0.5, 1),
    width: "15em",
}))
