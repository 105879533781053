import { WorkTaskDetailsReadModel, WorkTaskReadModel } from "@tm/models"
import { mapCustomer, mapVehicle } from "@tm/utils"

export function mapWorkTaskReadModel(data: any): WorkTaskReadModel | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        createdDate: data.createdDate ? new Date(data.createdDate) : undefined,
        modifiedDate: data.modifiedDate ? new Date(data.modifiedDate) : undefined,
        statusModifiedDate: data.statusModifiedDate ? new Date(data.statusModifiedDate) : undefined,
    }
}

export function mapWorkTaskDetailsReadModel(data: any): WorkTaskDetailsReadModel | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        createdDate: data.createdDate ? new Date(data.createdDate) : undefined,
        modifiedDate: data.modifiedDate ? new Date(data.modifiedDate) : undefined,
        statusModifiedDate: data.statusModifiedDate ? new Date(data.statusModifiedDate) : undefined,
        vehicle: data.vehicle && mapVehicle({ ...data.vehicle, id: data.vehicle.vehicleId }),
        customer: data.customer && mapCustomer({ ...data.customer, id: data.customer.customerId }),
    }
}
