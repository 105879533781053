/* eslint-disable no-console */
import { VefilesBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"

let bundleParams: VefilesBundleParams

export const version = {
    name: "vefiles",
    version: "2.0.0",
}

function checkBundleParams(params: VefilesBundleParams) {
    if (!isDevtoolEnabled()) {
        return null
    }
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle ${version.name}`)
    }

    return bundleParams
}

export function initVefilesBundle(params: VefilesBundleParams) {
    bundleParams = params
    checkBundleParams(params)
}
