import { FC, useEffect, useState } from "react"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import { Box, Loader } from "@tm/components"
import { useFastServiceStore } from "../../data"
import Main from "./component"

type Props = RouteComponentProps<{ view: string }> & {
    route: string
}

const HistoryWrapper: FC<Props> = (props): JSX.Element => {
    const [hydrated, setHydrated] = useState(false)
    const [historyRestored, setHistoryRestored] = useState(false)
    const [hasWorktaskId, setHasWorktaskId] = useState(false)
    const worktask = useWorkTask()?.workTask

    useEffect(() => {
        if (!hasWorktaskId && worktask) {
            setHasWorktaskId(true)
        }
    }, [worktask, hasWorktaskId])

    useEffect(() => {
        return () => {
            setHistoryRestored(false)
            setHasWorktaskId(false)
            setHydrated(false)
        }
    }, [])

    useEffect(() => {
        if (hasWorktaskId) {
            useFastServiceStore.persist.rehydrate()
        }

        const unsubHydrate = useFastServiceStore.persist.onHydrate(() => setHydrated(false))
        const unsubFinishHydration = useFastServiceStore.persist.onFinishHydration(() => setHydrated(true))
        setHydrated(useFastServiceStore.persist.hasHydrated())

        return () => {
            unsubHydrate()
            unsubFinishHydration()
        }
    }, [hasWorktaskId])

    useEffect(() => {
        if (!hydrated || !hasWorktaskId) {
            return
        }

        restoreHistory()
    }, [hydrated, hasWorktaskId])

    function restoreHistory() {
        setHistoryRestored(true)
    }

    if (historyRestored) {
        return <Main {...props} />
    }

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loader />
        </Box>
    )
}

export default withRouter(HistoryWrapper)
