import { SessionStorageKeys, channel, getCurrentWorkTaskId } from "@tm/models"
import { getMvcComponentModuleMode } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSetMvcSession(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    if (!data?.setMvcSession?.mvcSession) {
        return
    }

    const {
        setMvcSession: { mvcSession, moduleMode },
    } = data

    sessionStorage.setItem(`${getCurrentWorkTaskId()}${getMvcComponentModuleMode(moduleMode)}${SessionStorageKeys.mvcSessionId}`, mvcSession)
}
