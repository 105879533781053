import { MileType, RegistrationNoType } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { CreateRequest, VoucherIncludedWork, VoucherPart, VoucherWork } from "@tm/data/vouchers/costEstimations"
import { CostEstimation, IncludedWorkItem, CostEstimationCalculation } from "../model"
import { OfferState } from "../../components/cost-estimation/business"
import { BasketPart, BasketWork } from "../../models"

function mapBasketPartsToVoucherParts(costEstimationParts?: BasketPart[]): VoucherPart[] {
    const voucherParts: VoucherPart[] = []
    costEstimationParts?.forEach((part) => {
        const { partItem, calculatedPart, article } = part
        const { articleInformation, costEstimationItem } = partItem
        if (costEstimationItem?.isIncluded) {
            voucherParts.push({
                partItemId: partItem.id,
                articleInformation: {
                    ...articleInformation,
                    description: article?.description ?? articleInformation.description,
                    thumbnailUrl: undefined,
                },
                quantityValue: partItem.quantity.value,
                calculation: {
                    offerPriceTotal: calculatedPart?.offerPriceTotal?.value,
                    rebate: calculatedPart?.rebate,
                    regularPriceWithSurcharge: calculatedPart?.regularPriceWithSurcharge?.value,
                    surcharge: calculatedPart?.surcharge,
                    vatRate: calculatedPart?.vatRate,
                },
                purchaseNetPrice: partItem.costEstimationItem?.purchaseNetPrice,
                retailNetPrice: partItem.costEstimationItem?.retailNetPrice,
                mainPartItemId: partItem.mainPartId,
                itemRole: partItem.itemRole,
                linkedItems: mapBasketPartsToVoucherParts(part.linkedItems),
            })
        }
    })

    return voucherParts
}

function mapIncludedWorkItemsToVoucherIncludedWorks(mainWorkId: string, includedWorks?: IncludedWorkItem[]): VoucherIncludedWork[] {
    const voucherIncludedWorks: VoucherIncludedWork[] = []
    includedWorks?.forEach((includedWork) => {
        voucherIncludedWorks.push({
            mainWorkId,
            providerWorkId: includedWork.providerWorkId,
            category: includedWork.category,
            categoryShortCode: includedWork.categoryShortCode,
            description: includedWork.description,
            isCalculable: includedWork.isCalculable,
            sortNo: includedWork.sortNo,
            timeInMinutes: includedWork.timeInMinutes,
            isVisible: includedWork.isVisible,
            isInternal: includedWork.isInternal,
        })
    })
    return voucherIncludedWorks
}

function mapBasketWorksToVoucherWorks(works?: BasketWork[]): VoucherWork[] {
    const voucherWorks: VoucherWork[] = []

    works?.forEach((work) => {
        const { workItem, states, calculatedWork, estimatedWork } = work
        if (work.workItem.isIncluded) {
            voucherWorks.push({
                workItemId: workItem.id,
                provider: workItem.provider,
                providerName: workItem.providerName,
                providerWorkId: workItem.providerWorkId,
                displayNumber: workItem.displayNumber,
                type: workItem.type,
                description: estimatedWork?.description ?? workItem.description,
                category: workItem.category,
                categoryShortCode: workItem.categoryShortCode,
                includedWorks: mapIncludedWorkItemsToVoucherIncludedWorks(workItem.id, workItem.includedWorks),
                timeInMinutes: workItem.timeInMinutes,
                hourlyRateNet: workItem.hourlyRate,
                fixedPriceNetValue: workItem.fixedPriceValue,
                calculation: {
                    repairTime: calculatedWork?.repairTime,
                    repairTimeCalculated: calculatedWork?.repairTimeCalculated,
                    regularPrice: calculatedWork?.regularPrice?.value,
                    offerPrice: calculatedWork?.offerPrice?.value,
                    isFixedPrice: calculatedWork?.isFixedPrice,
                    hourlyRateWithSurcharge: calculatedWork?.hourlyRateWithSurcharge?.value,
                    rebate: calculatedWork?.rebate,
                    surcharge: calculatedWork?.surcharge,
                    vatRate: calculatedWork?.vatRate,
                },
                printIncludedWorks: states.isExpanded || false,
                providerTimeInMinutes: workItem.providerTimeInMinutes,
                fittingSide: workItem.fittingSide,
                productGroupIds: workItem.productGroupIds,
                note: workItem.note,
                sortNo: workItem.sortNo,
            })
        }
    })

    return voucherWorks
}

export function mapCreateCostEstimationRequest(
    workTask: WorkTaskInfo,
    state: OfferState,
    repairTimeDivision: number,
    currencyCode: string,
    currencySymbol: string,
    registrationNoLabel?: string,
    costEstimation?: CostEstimation,
    costEstimationParts?: BasketPart[],
    works?: BasketWork[],
    calculatedCostEstimation?: CostEstimationCalculation,
    showSupplierArticleNumbers?: boolean,
    showManufacturer?: boolean,
    showWholesalerArticleNumbers?: boolean,
    showVehicleImage?: boolean
): CreateRequest {
    const { vehicle, customer, id, no } = workTask
    const { totalsB2B, totalsB2C } = calculatedCostEstimation || {}
    return {
        workTaskId: id,
        workTaskNumber: no,
        customer: customer
            ? {
                  id: customer.id,
                  city: customer.city ?? "",
                  title: customer.title,
                  academicTitle: customer.academicTitle,
                  titleType: customer.titleType,
                  companyName: customer.companyName ?? "",
                  country: customer.country ?? "",
                  customerNumber: customer.customerNo ?? "",
                  eMailAddress: customer.email ?? "",
                  firstName: customer.firstName ?? "",
                  lastName: customer.lastName ?? "",
                  phone: customer.phone ?? "",
                  street: customer.street ?? "",
                  zip: customer.zip ?? "",
              }
            : undefined,
        vehicle: vehicle
            ? {
                  displayName: `${vehicle.manufacturer} ${vehicle.modelSeries} ${vehicle.model}`,
                  id: vehicle.id,
                  initialRegistration: vehicle.initialRegistration,
                  mileType: vehicle.mileType ?? MileType.Kilometer,
                  mileage: vehicle.mileAge,
                  plateId: vehicle.plateId ?? "",
                  vin: vehicle.vin ?? "",
                  vehicleImage: costEstimation?.vehicleImage,
                  registrationNo: vehicle.registrationNo ?? "",
                  registrationNoLabel: registrationNoLabel ?? "",
                  registrationNoType: vehicle.registrationTypeId ?? RegistrationNoType.Kba,
              }
            : undefined,
        costEstimation: {
            costEstimationOptions: costEstimation?.costEstimationOptions,
            details: {
                workSchedule: {
                    employeeInCharge: state.offerDetails.clientAdvisor,
                    performingEmployee: state.offerDetails.mechanic,
                    expectedCompletionDate: state.offerDetails.dueDate,
                    referenceNumber: state.offerDetails.referenceNumber,
                },
                validityPeriod: {
                    fromDate: state.offerDetails.startDate ? state.offerDetails.startDate : new Date(),
                    toDate: state.offerDetails.endDate,
                },
                memo: state.note,
            },
            parts: mapBasketPartsToVoucherParts(costEstimationParts),
            works: mapBasketWorksToVoucherWorks(works),
            totalsB2C: {
                alreadyTaxedPartCosts: totalsB2C?.alreadyTaxedPartCosts,
                alreadyTaxedTotalCosts: totalsB2C?.alreadyTaxedTotalCosts,
                alreadyTaxedWorkCosts: totalsB2C?.alreadyTaxedWorkCosts,
                offerPriceVatExcluded: totalsB2C?.offerPriceVatExcluded,
                offerPriceVatIncluded: totalsB2C?.offerPriceVatIncluded,
                partCosts: totalsB2C?.partCosts,
                repairTimeTotal: totalsB2C?.repairTimeTotal,
                replacementVatAmount: totalsB2C?.replacementVatAmount,
                vatAmounts: totalsB2C?.vatAmounts,
                workCosts: totalsB2C?.workCosts,
            },
            totalsB2B: {
                alreadyTaxedPartCosts: totalsB2B?.alreadyTaxedPartCosts,
                alreadyTaxedTotalCosts: totalsB2B?.alreadyTaxedTotalCosts,
                alreadyTaxedWorkCosts: totalsB2B?.alreadyTaxedWorkCosts,
                offerPrice: totalsB2B?.offerPrice,
                partCosts: totalsB2B?.partCosts,
                repairTimeTotal: totalsB2B?.repairTimeTotal,
                replacementVatAmount: totalsB2B?.replacementVatAmount,
                workCosts: totalsB2B?.workCosts,
            },
            repairTimeDivision,
            currencyCode,
            currencySymbol,
        },
        printOptions: {
            showSupplierArticleNumbers: showSupplierArticleNumbers ?? true,
            showWholesalerArticleNumbers: showWholesalerArticleNumbers ?? true,
            showManufacturer: showManufacturer ?? true,
            showVehicleImage: showVehicleImage ?? true,
            showNoteText: costEstimation?.useNoteText ?? false,
            noteText: costEstimation?.noteText ?? "",
        },
    }
}
