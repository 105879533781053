import { useUser } from "@tm/context-distribution"
import { ArticleNumber, Stack, styled } from "@tm/components"
import { useOpenArticleDetails } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { getBundleParams } from "../../../../../utils"

const StyledStack = styled(Stack)({
    flex: "0 0 8em",
    minWidth: "100px",
    alignItems: "flex-start !important",
})

type Props = {
    productGroupId?: number
    supplierArticleNumber?: string
    supplierId?: number
    wholesalerArticleNumber?: string
}

export default function ArticleNumbersComponent({ supplierId, supplierArticleNumber, wholesalerArticleNumber, productGroupId }: Props) {
    const userContext = useUser()?.userContext
    const { partsDetailsModalUrl } = getBundleParams()
    const handleOpenArticleDetails = useOpenArticleDetails({
        productGroupId,
        supplierId,
        supplierArticleNumber,
        partsDetailsUrl: partsDetailsModalUrl,
        openModal: Morpheus.showView,
    })

    return (
        <StyledStack direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}>
            {wholesalerArticleNumber && !userContext?.parameter.hideDealerPartNumber && (
                <ArticleNumber
                    articleNumber={wholesalerArticleNumber}
                    articleNumberType="wholesaler"
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={handleOpenArticleDetails}
                />
            )}
            {supplierArticleNumber && (
                <ArticleNumber
                    articleNumber={supplierArticleNumber}
                    articleNumberType="supplier"
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    onClick={handleOpenArticleDetails}
                />
            )}
        </StyledStack>
    )
}
