import { GetShowCategorizedSearchQueryResponse, RequestArticleListPayload } from "@tm/models"

export function mapCategrizedSearchToArticleListRequest(
    categorizedSearch: GetShowCategorizedSearchQueryResponse,
    hasVehicle?: boolean
): RequestArticleListPayload | undefined {
    // first check if there are vehicle related product
    if (categorizedSearch.hasVehicleRelatedProductGroups) {
        if (hasVehicle) {
            return {
                synonym: {
                    query: `${categorizedSearch.splitedSearchQueryParts.supplierName} ${
                        categorizedSearch.splitedSearchQueryParts?.productGroupName
                    } ${categorizedSearch.splitedSearchQueryParts?.attributes?.join(" ")}`,
                },
                productGroups: {
                    ids: categorizedSearch.productGroups?.map((e) => e.productGroupId),
                },
            }
        }
        return {
            uniSearch: {
                query: `${categorizedSearch.splitedSearchQueryParts.supplierName} ${
                    categorizedSearch.splitedSearchQueryParts?.productGroupName
                } ${categorizedSearch.splitedSearchQueryParts?.attributes?.join(" ")}`,
            },
        }
    }
    // if not, check for universalPats
    if (categorizedSearch.hasUniversalProductGroups) {
        return {
            uniSearch: {
                query: `${categorizedSearch.splitedSearchQueryParts?.productGroupName} ${categorizedSearch.splitedSearchQueryParts?.attributes?.join(
                    " "
                )}`,
            },
        }
    }
    // check if the ki find a articleID, than make a direktSearch
    if (categorizedSearch.splitedSearchQueryParts.articleID) {
        return {
            direct: {
                query: categorizedSearch.splitedSearchQueryParts.articleID,
                productGroupIds: categorizedSearch.productGroups?.map((e) => e.productGroupId),
            },
        }
    }
    // if only productGroups in the result
    if (categorizedSearch.productGroups) {
        return {
            uniProductGroups: { ids: categorizedSearch.productGroups.map((e) => e.productGroupId) },
        }
    }
}
