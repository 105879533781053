import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { Button, LicensePlateField, Scrollbar, Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { DateUnit, renderRoute } from "@tm/utils"
import { History } from "history"
import * as React from "react"
import { ServiceEvent } from "../../../data"
import { formatDate } from "../../../helpers/date"
import { StartWidgetState } from "../business"

type Props = Required<Pick<StartWidgetState, "events" | "types">> & {
    type?: DateUnit
    history: History
    centerRoute: string
}

export function Events(props: Props) {
    const { translate, date } = useLocalization()
    const { workTask } = useWorkTask() || {}
    const vehicle = workTask?.vehicle
    const { types, events, type } = props
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)

    function getType(event: ServiceEvent) {
        const result = types.find((x) => x.id === event.eventTypeId)
        return result ? result.name : "Unknown"
    }

    function getName(event: ServiceEvent) {
        if (!event.firstName && !event.lastName) {
            const name = translate(1639, false) || ""
            return name.toString() /** Ohne Kundeninformation */
        }
        return [(event.firstName || "").substr(0, 1), event.lastName || ""].join(". ")
    }

    function getPlate(event: ServiceEvent) {
        if (!event.licensePlate) {
            return null
        }
        return <LicensePlateField shortCountryCode={plateCode} readonly value={event.licensePlate} />
    }

    function getRowClassName(event: ServiceEvent) {
        return `event event--${event.eventTypeId || 0}`
    }

    function getDateTime(event: ServiceEvent) {
        if (type === DateUnit.Day) {
            return (
                <>
                    <div className="date__start">{date(event.startDateTime, "t")}</div>
                    <div className="date__end">{date(event.endDateTime, "t")}</div>
                </>
            )
        }
        return date(event.startDateTime, "g")
    }

    function handleClickRow(event: ServiceEvent) {
        props.history.push(`/${event.onlineBookingId}`)
    }

    function handleClickDetails(event: ServiceEvent, e: Event) {
        e.stopPropagation()
        const centerUrl =
            props.centerRoute &&
            `${renderRoute(props.centerRoute, { workTaskId: event.onlineBookingId })}?url=${encodeURIComponent(
                `https://werkstattplanung.net/demo/topmotive/kic/public/#/${formatDate(event.startDateTime)}?caseId=${event.id}`
            )}`
        props.history.push(centerUrl)
    }

    function ScrollContainer(ownProps: { children: React.ReactElement }): React.ReactElement {
        return !type ? <Scrollbar>{ownProps.children}</Scrollbar> : ownProps.children
    }

    const className = `events ${type === DateUnit.Day ? "events--day" : ""}`

    return (
        <ScrollContainer>
            <Table
                className={className}
                getRowClassName={getRowClassName}
                data={events}
                onClickRow={handleClickRow}
                columns={[
                    <Table.Column key="date" renderItemContent={(event) => <div className="date">{getDateTime(event)}</div>} />,
                    <Table.Column
                        key="type"
                        renderItemContent={(event) => {
                            const eventType = getType(event)
                            return (
                                <div className="type" title={translate(eventType) as string}>
                                    {translate(eventType)}
                                </div>
                            )
                        }}
                    />,
                    <Table.Column
                        key="name"
                        renderItemContent={(event) => {
                            const name = getName(event)
                            return (
                                <div className="name" title={name as string}>
                                    {name}
                                </div>
                            )
                        }}
                    />,
                    <Table.Column key="plate" renderItemContent={(event) => <div className="plate">{getPlate(event)}</div>} />,
                    <Table.Column
                        key="actions"
                        renderItemContent={(event) => (
                            <div className="actions">
                                <Button layout={["ghost"]} icon="details" onClick={handleClickDetails.bind(null, event)} />
                            </div>
                        )}
                    />,
                ]}
            />
        </ScrollContainer>
    )
}
