import { Suspense } from "react"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Loader } from "@tm/components"
import { useArticleDetailsSharedArticle } from "../../data/hooks/useArticleDetailsSharedErpInfos"
import PartnerSystemsTable from "../../_shared/partner-systems"

function DetailsPartnersystems() {
    const article = useArticleDetailsSharedArticle()

    return <PartnerSystemsTable article={article} />
}

export default function Wrapper() {
    const { translateText } = useLocalization()

    return (
        <PanelSection className="tk-erp details-partnersystems" title={translateText(12416)} size="s">
            <Suspense fallback={<Loader />}>
                <DetailsPartnersystems />
            </Suspense>
        </PanelSection>
    )
}
