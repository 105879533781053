import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { ajax, Authorization, encodeBase64 } from "@tm/utils"
import { useQuery } from "react-query"
import { AdTileType } from "./model"

export function useAdTiles(serviceUrl: string) {
    const { userContext } = useUser()

    const authorization: Authorization | undefined = useMemo(() => {
        if (userContext) {
            const guid = userContext.system.projectGuid
            const traderId = userContext.principal?.traderId
            if (guid) {
                const customerNo = userContext.account?.customerNo
                return {
                    type: "Basic",
                    credentials: encodeBase64(`${guid.toLocaleUpperCase()}:${customerNo}:${traderId}`),
                }
            }
        }
    }, [userContext])

    const { data } = useQuery(
        ["trader-wm-ad-tiles", serviceUrl, authorization],
        () =>
            ajax({ url: serviceUrl, method: "GET", authorization }).then(
                (response) => JSON.parse(response.WMOBS.Data.jsonData["cdata-section"]) as Array<AdTileType>
            ),
        { enabled: !!authorization, staleTime: 5 * 60 * 1000 }
    )

    return { adTileItems: data || [] }
}
