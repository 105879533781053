import { eOEArtType } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { ReactText } from "react"
import { BundleActions, BundleActionTypes } from "../../../business"
import { GetCalculationDataRequest, useContainer } from "../../../data/repositories"
import { MainState } from "../../main"
import { MainActionsType } from "../../main/business"

export type ExternState = {
    document: string
    transferBtnEnabled?: boolean
    transferLoading?: boolean
    contractNotFound?: boolean
    successfullStart?: boolean
}

export type ComponentActionType = BundleActionTypes | { type: "EXTERN_INCOMPLETE"; payload: string } | { type: "CUSTOMER_BILLING_HANDLED" }

const DEFAULT_STATE: ExternState = {
    document: "",
}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): ExternState => {
    switch (action.type) {
        case "PREPARE_CALC_LOADED": {
            return {
                ...state,
                document: action.payload.baseInfo.glassHtml,
                transferBtnEnabled: false,
            }
        }
        case "RESET_MODULE": {
            return {
                ...state,
                transferBtnEnabled: false,
                transferLoading: false,
                contractNotFound: false,
                successfullStart: false,
            }
        }
        case "EXTERN_INCOMPLETE": {
            return {
                ...state,
                transferBtnEnabled: false,
                transferLoading: false,
                ...(!state.contractNotFound && { contractNotFound: action.payload?.includes("Server.contractNotFound") }),
                successfullStart: false,
            }
        }
        case "CALCULATION_DATA_LOADING": {
            return {
                ...state,
                transferLoading: true,
            }
        }
        case "CALCULATION_DATA_LOADED": {
            return {
                ...state,
                transferBtnEnabled: !action.payload.errorMessage,
                transferLoading: false,
                successfullStart: state.contractNotFound && (!!action.payload.works || !!action.payload.totals || !!action.payload.items),
            }
        }
        case "CUSTOMER_BILLING_HANDLED": {
            return {
                ...state,
                contractNotFound: false,
                successfullStart: false,
            }
        }
        default: {
            return state
        }
    }
}

const getCalculationData =
    (forceRequest?: boolean, translateText?: (key: ReactText) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const {
            summary: {
                fields: { processName },
            },
            extern,
        } = getState()

        const container = useContainer()

        if ((extern.transferBtnEnabled || extern.transferLoading) && !forceRequest) {
            return
        }

        const req: GetCalculationDataRequest = { contractNumber: processName }
        dispatch({ type: "CALCULATION_DATA_LOADING" })
        container
            .action("getCalculationData")(req)
            .then(
                (res) => {
                    const smallSparePart = res?.items?.find((x) => x.oeArticle.type === eOEArtType.SmallPartsAndConsumables)
                    if (smallSparePart && translateText) {
                        smallSparePart.oeArticle.description = `${translateText(1903)} (${smallSparePart.oeArticle.priceInPercent}%)`
                    }

                    dispatch({ type: "CALCULATION_DATA_LOADED", payload: res })
                    // !extern.transferBtnEnabled && dispatch(MainActions.changeStep("calculation"))
                },
                (error) => dispatch({ type: "EXTERN_INCOMPLETE", payload: error })
            )
    }

const handleCustomerBilling = (): AsyncAction<MainActionsType, MainState> => (dispatch, getState) => {
    const {
        extern: { successfullStart },
    } = getState()
    const container = useContainer()

    if (!successfullStart) {
        return
    }

    container
        .action("handleCustomerBilling")()
        .then(
            () => {
                dispatch({ type: "CUSTOMER_BILLING_HANDLED" })
            },
            () => dispatch({ type: "CUSTOMER_BILLING_HANDLED" })
        )
}

export type IActions = typeof Actions

export const Actions = { ...BundleActions, getCalculationData, handleCustomerBilling }
