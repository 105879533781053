import { Article } from "@tm/models"
import { useCallback, useMemo } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { getInternalIdFromArticleUniqueId } from "../../list/business/helpers"
import { SelectedArticlesState } from "../states"
import { getBundleParams } from "../../../utils"

export function useArticleSelection() {
    const selectedArticles = useRecoilValue(SelectedArticlesState)

    const isArticleSelected = useCallback((article: Article) => selectedArticles.some((x) => x.id === article.id), [selectedArticles])

    const articleSelectionActions = useArticleSelectionActions()

    const { maximumPartsToCompare } = getBundleParams()

    return useMemo(() => ({
        selectedArticles,
        isArticleSelected,
        maximumPartsToCompare: maximumPartsToCompare || 25,
        ...articleSelectionActions,
    }), [selectedArticles,
        isArticleSelected,
        maximumPartsToCompare,
        articleSelectionActions])
}

export function useArticleSelectionActions() {
    const setSelectedArticles = useSetRecoilState(SelectedArticlesState)

    const toggleSelectedArticle = useCallback(
        (article: Article) => {
            setSelectedArticles((prev) => (prev.some((x) => x.id === article.id) ? prev.filter((x) => x.id !== article.id) : [...prev, article]))
        },
        [setSelectedArticles]
    )

    const deselectArticle = useCallback(
        (articleUniqueId: string) => {
            const { internalId } = getInternalIdFromArticleUniqueId(articleUniqueId)

            if (Number.isNaN(internalId)) {
                return
            }

            setSelectedArticles((prev) => prev.filter((x) => x.internalId !== internalId))
        },
        [setSelectedArticles]
    )

    const deselectAll = useCallback(() => {
        setSelectedArticles([])
    }, [setSelectedArticles])

    return useMemo(() => ({
        toggleSelectedArticle,
        deselectArticle,
        deselectAll,
    }), [toggleSelectedArticle,
        deselectArticle,
        deselectAll,])
}
