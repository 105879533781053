import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, RouteComponentProps, uniqueId, withRouter } from "@tm/utils"
import { Icon, Tooltip } from "@tm/components"
import { getBundleParams } from "../../utils"
import { useVrcLookupModulesLoadable } from "../../data/hooks/vrc-lookup"

type Props = RouteComponentProps<{ workTaskId?: string }>

function VrcScanButton({ match, history }: Props) {
    const { translateText } = useLocalization()
    const modules = useVrcLookupModulesLoadable()

    if (!modules.length) {
        return null
    }

    const handleButtonClick = () => {
        history.push(
            renderRoute(getBundleParams().vrcLookupRoute, { ...match.params, workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()) })
        )
    }

    return (
        <Tooltip title={translateText(12617)} enterDelay={500}>
            <Icon sx={{ cursor: "pointer" }} name="scan" onClick={handleButtonClick} />
        </Tooltip>
    )
}

export default withRouter(VrcScanButton)
