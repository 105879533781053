import { Button, TextField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { Formular } from "../../modul/toolbar-form/component"
import { Email } from "../email"
import { Rating } from "../rating"

export type FeedbackProps = {
    preset: Formular
    onDecline: () => void
    onSubmit: (formValue: Formular) => void
}

export function SimpleFeedbackFormular(props: FeedbackProps) {
    const { preset, onDecline, onSubmit } = props
    const [formValue, setFormValue] = useState<Formular>(preset)
    const [isValid, setIsValid] = useState<boolean>(false)

    const { translateText } = useLocalization()

    const handleChangeRating = (value: Rating) => {
        setFormValue({ ...formValue, rating: value })
    }

    const handleOnNameChange = (value: string) => {
        setFormValue({ ...formValue, name: value })
    }

    const handleOnEmailChange = (value: string, valid: boolean) => {
        setIsValid(valid)
        setFormValue({ ...formValue, email: value })
    }

    const handleOnPhoneChange = (value: string) => {
        setFormValue({ ...formValue, phone: value })
    }

    const handleOnFeedbackTextChange = (value: string) => {
        setFormValue({ ...formValue, text: value })
    }

    return (
        <div className="content">
            <div className="headline">
                {translateText(1210)}
                <div className="actions">
                    <Button size="l" icon="close" onClick={onDecline} />
                </div>
            </div>
            <Text className="description">{translateText(1211)}</Text>
            <div className="">
                <div className="ratings">
                    <Rating onChange={handleChangeRating} />
                </div>
                <div className="feedback-box">
                    <TextField className="feedback-name" placeholder={translateText(155)} value={formValue.name} onChange={handleOnNameChange} />
                    <Email placeholder={translateText(109)} value={formValue.email} onHandleChange={handleOnEmailChange} />
                    <TextField className="feedback-phone" placeholder={translateText(110)} value={formValue.phone} onChange={handleOnPhoneChange} />
                    <TextField
                        className="feedback-text"
                        multiline
                        placeholder={translateText(1213)}
                        value={formValue.text}
                        onChange={handleOnFeedbackTextChange}
                    />
                    <Button
                        className="submit"
                        size="l"
                        skin="highlight"
                        onClick={() => onSubmit(formValue)}
                        disabled={!isValid || !formValue.rating || !formValue.name || !formValue.email}
                    >
                        {translateText(642)}
                    </Button>
                </div>
            </div>
        </div>
    )
}
