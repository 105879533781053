import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { Text, MessageInline, Demo } from "@tm/controls"
import { getCombinedModuleOptionsAndPackages, useActivateModule, useGetActivatableModules } from "@tm/utils"
import { ActivatableModule, ActivateModuleRef, IMicros, ModuleGroupId, UserContext } from "@tm/models"
import { Box, Button, Icon, Typography, styled } from "@tm/components"
import { useMicro } from "@tm/morpheus"
import UserSettingsArea from "../UserSettingsArea"
import ModuleAccordion from "./components/ModuleAccordion"

type Props = {
    demoActivationInstanstly?: boolean // In cases like WM where an external system is responsible for the activation. A refresh of the page is not enough.
    userContext: UserContext
    moduleCompareRoute: string | undefined
}

export default function ModulesComponent({ demoActivationInstanstly, moduleCompareRoute, userContext }: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText, translate } = useLocalization()
    const activatableModulesLoadable = useGetActivatableModules(userContext)
    const { activatingModule, activateModuleError } = useActivateModule()
    const activateRef = useRef<ActivateModuleRef>(null)

    function renderDemoButtons(moduleGroupId: ModuleGroupId, module: ActivatableModule) {
        const { fullModulePackage, demoModulePackage, fullOptionModule, demoOptionModule, isFullPackageActive } =
            getCombinedModuleOptionsAndPackages(module)

        if (isFullPackageActive) {
            return (
                <Box display="flex" gap="0.5em" width="100%" justifyContent="center">
                    <Icon name="check-filled" color="#c2e617" />
                    <Typography variant="body2">{translateText(12839)}</Typography>
                </Box>
            )
        }

        return (
            <>
                <StyledDemoButton
                    hidden={!demoOptionModule}
                    variant="contained"
                    disabled={activatingModule || (demoOptionModule?.activatable ? demoOptionModule.active : true)}
                    onClick={() => activateRef.current?.handleActivateModule(moduleGroupId, module, demoOptionModule, demoModulePackage)}
                >
                    {demoOptionModule?.active
                        ? `${translateText(12573).replace("{0}", demoOptionModule.amountOfDays?.toString() || "0")}`
                        : translateText(12567)}
                    <Demo displayMode="edge" />
                </StyledDemoButton>
                <StyledDemoButton
                    color="highlight"
                    variant="contained"
                    disabled={activatingModule || (fullOptionModule?.activatable ? fullOptionModule?.active : true)}
                    onClick={() => activateRef.current?.handleActivateModule(moduleGroupId, module, fullOptionModule, fullModulePackage)}
                >
                    {translateText(12568)}
                </StyledDemoButton>
            </>
        )
    }

    return (
        <UserSettingsArea
            id="user-settings__area__module"
            title={translateText(1832)}
            showLoader={activatableModulesLoadable.isLoading || activatingModule}
        >
            {renderMicro?.("misc", "activate-module", { ref: activateRef, demoActivationInstanstly })}
            {!activatableModulesLoadable.isLoading && (
                <div className="user-settings__area__module">
                    {activatableModulesLoadable.response && <Text size="xs">{`*${translate(12873)}`}</Text>}
                    {activateModuleError && <MessageInline message={translateText(12821)} skin="error" />}
                    {!activatableModulesLoadable.response && <MessageInline message={translateText(286)} skin="error" />}
                    {activatableModulesLoadable.response?.moduleGroups?.map((moduleGroup) => (
                        <ModuleAccordion
                            key={moduleGroup.id}
                            moduleGroup={moduleGroup}
                            moduleCompareRoute={moduleCompareRoute}
                            activatingModule={activatingModule}
                            renderDemoButtons={renderDemoButtons}
                        />
                    )) ?? null}
                </div>
            )}
        </UserSettingsArea>
    )
}

const StyledDemoButton = styled(Button)<{ hidden?: boolean }>(({ hidden }) => ({
    visibility: hidden ? "hidden" : "visible",
    minWidth: "13rem",
    margin: "0 .25rem",
    "& svg": {
        position: "absolute",
        top: "0",
        right: "0",
        opacity: 1,
        margin: 0,
    },
}))
