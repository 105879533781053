import { Loader } from "@tm/components"
import { getModuleFromUserContext, useUser } from "@tm/context-distribution"
import { BasketBundleParams, ConfigParams, ErpBundleParams, QuantitySuggestionMode, UserModuleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { PropsWithChildren, Suspense, useMemo } from "react"
import { createContext, useContextSelector } from "use-context-selector"
import { getBundleParams } from "../../../utils"
import { ArticleFeedbackConfiguration, useArticleFeedbackConfiguration } from "./useArticleFeedbackConfiguration"
import { ArticleListSortingConfiguration, useArticleListSortingConfiguration } from "./useArticleListSortingConfiguration"
import { OeInformationConfiguration, useOeInformationConfiguration } from "./useOeInformationConfiguration"

export const DEFAULT_PAGE_SIZE = 25

/** Contains all available configuration options for the article list functionality. Extend if necessary. */

export type ArticleListConfiguration = {
    // Article list view options
    filtersAtLeftSide: boolean
    showDataSupplierLogos: boolean
    highlightCostEstimationButton: boolean
    viewOptions: {
        purchasePrice: boolean | "extracted"
        compact: boolean | "extracted"
        images: boolean | "extracted"
        vehicleRecords: boolean | "extracted"
        quantitySuggestion: boolean | "extracted"
    }

    // Article list functionality
    hasWatchList: boolean
    feedbackConfiguration: ArticleFeedbackConfiguration

    // Page sizes
    pageSize: number
    erpPageSize: number

    // Article list sorting
    sorting: ArticleListSortingConfiguration

    hideExtendedDeliveryComponent?: boolean
    showWideTeccomButton?: boolean
    articleListTeccomMode: ErpBundleParams["articleListTeccomMode"]

    // Oe Article Information settings
    oeInformation: OeInformationConfiguration

    // Switch Position of EArtNr and HArtNr
    positionChangeEArtNrHArtNr?: boolean

    imsIcon?: string

    // Article Notes
    enableArticleNotes?: boolean

    // Recommended Articles
    enableRecommendedArticles?: boolean
}

/**
 * Shall not be used directly outside of this file.
 * Use export `useArticleListConfiguration` instead.
 */
const ArticleListConfigurationContext = createContext<ArticleListConfiguration | undefined>(undefined)

/** Provides all the necessary article list configuration. */
export function ArticleListConfigurationProvider(props: PropsWithChildren<unknown>) {
    const bundleParams = getBundleParams()
    const { userContext } = useUser()

    const { articleListPageSize, showDataSupplierLogos = false } = userContext.parameter
    const pageSize = typeof articleListPageSize === "number" ? articleListPageSize : DEFAULT_PAGE_SIZE

    const { highlightCostEstimationButton = false } = Morpheus.getParams<BasketBundleParams>("basket")
    const {
        /** @todo Check if we really need these two parameters anymore --> NEXT-26321 */
        // hidePurchasePriceIfReplacementArticlesAvailable,
        // priceOrder,
        hideExtendedDeliveryComponent,
        showWideTeccomButton,
        articleListTeccomMode,
    } = Morpheus.getParams<ErpBundleParams>("erp")

    const hasWatchList = userContext.modules?.some((x) => x.description === "WatchList") ?? false

    const erpSystem = useMemo(
        () => userContext.externalModules?.find((x) => typeof x.$type === "string" && x.$type.includes("ExternalSystemErpDto")),
        [userContext.externalModules]
    )

    const erpPageSizeValue = useMemo(() => {
        const value = parseInt(erpSystem?.parameter.find((x) => x.key === "ErpRequestArticleCount")?.value ?? "")
        return !Number.isNaN(value) ? value : undefined
    }, [erpSystem?.parameter])

    const quantitySuggestionMode = useMemo<QuantitySuggestionMode>(
        () => userContext.modules?.find((x) => x.type === UserModuleType.Catalog)?.quantitySuggestionMode ?? QuantitySuggestionMode.Deactivated,
        [userContext]
    )

    const enableArticleNotes = useMemo<boolean>(() => !!getModuleFromUserContext(userContext, UserModuleType.ArticleNotes), [userContext.modules])

    const enableRecommendedArticles = useMemo<boolean>(
        () => !!getModuleFromUserContext(userContext, UserModuleType.RecommendedArticles),
        [userContext.modules]
    )

    const viewOptions = useMemo(
        () => ({
            purchasePrice: bundleParams.listV2?.viewOptions?.purchasePrice ?? true,
            compact: bundleParams.listV2?.viewOptions?.compact ?? true,
            images: bundleParams.listV2?.viewOptions?.images ?? true,
            vehicleRecords: (bundleParams.listV2?.viewOptions?.vehicleRecords ?? true) && !!bundleParams.vehicleRecordsEnabled,
            quantitySuggestion: quantitySuggestionMode !== QuantitySuggestionMode.Deactivated,
        }),
        [bundleParams, quantitySuggestionMode]
    )

    const oeInformation = useOeInformationConfiguration()

    const positionChangeEArtNrHArtNr = userContext?.parameter.positionChangeEArtNrHArtNr

    const articleListConfiguration: ArticleListConfiguration = {
        filtersAtLeftSide: Morpheus.getParams<ConfigParams>().articleFiltersInLeft ?? false,
        showDataSupplierLogos,
        highlightCostEstimationButton,
        viewOptions,
        hasWatchList,
        feedbackConfiguration: useArticleFeedbackConfiguration(),
        pageSize,
        erpPageSize: erpPageSizeValue ?? pageSize,
        hideExtendedDeliveryComponent,
        showWideTeccomButton,
        articleListTeccomMode,
        sorting: useArticleListSortingConfiguration(),
        oeInformation,
        positionChangeEArtNrHArtNr,
        imsIcon: bundleParams.imsIcon,
        enableArticleNotes,
        enableRecommendedArticles,
    }

    return (
        <Suspense fallback={<Loader />}>
            <ArticleListConfigurationContext.Provider value={articleListConfiguration}>{props?.children}</ArticleListConfigurationContext.Provider>
        </Suspense>
    )
}

// /** Can be used to get all the necessary article list configuration. */
// export function useArticleListConfiguration() {
//     const context = useContext(ArticleListConfigurationContext)

//     if (!context) {
//         throw new Error(`Used ${useArticleListConfiguration.name} outside of ${ArticleListConfigurationProvider.name}`)
//     }

//     return context
// }

export * from "./useArticleFeedbackConfiguration"
export * from "./useArticleListSortingConfiguration"
export * from "./useOeInformationConfiguration"

export function useArticleListConfiguration<TContext extends ArticleListConfiguration>(): TContext
export function useArticleListConfiguration<TContext extends ArticleListConfiguration, TReturnType>(
    selector: (value: TContext) => TReturnType
): TReturnType
export function useArticleListConfiguration<TContext extends ArticleListConfiguration, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(ArticleListConfigurationContext, (context) => {
        const validatedContext = getValidatedContext(context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}

function getValidatedContext<TContext extends ArticleListConfiguration>(context: TContext | undefined): TContext {
    if (!context) {
        throw new Error(`Used ${useArticleListConfiguration.name} outside of ${ArticleListConfigurationProvider.name}`)
    }

    return context
}
