import * as React from "react"
import { Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { styled, Typography } from "@tm/components"

type Props = {
    titleID?: number
    descriptionID?: number
    icon?: string
}

const ErrorMessage: React.FC<Props> = ({ icon, titleID, descriptionID }) => {
    const { translateText } = useLocalization()
    return (
        <StyledDiv styleEnabled={!icon} className="article-list__panel article-list__no-result">
            {icon && <Icon className="icon--no-result" name={icon} size="xl" />}
            {titleID && <Typography variant="h2">{translateText(titleID)}</Typography>}
            {descriptionID && <Typography variant="body1">{translateText(descriptionID)}</Typography>}
        </StyledDiv>
    )
}

const StyledDiv = styled("div")<{ styleEnabled: boolean }>(
    ({ styleEnabled }) =>
        styleEnabled && {
            display: "flex",
            flex: 1,
            flexDirection: "column",
            margin: "1em 0",
            alignItems: "center",
            justifyContent: "center",
        }
)

export default ErrorMessage
