import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function temotFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const temotModule = getModuleFromUserContext(userContext, UserModuleType.GpiDataTemot)

    if (!temotModule) {
        return undefined
    }

    return {
        gti: {
            items: {
                temot: {
                    sort: 3,
                    name: "Temot",
                    active: true,
                    route: "/:workTaskId/gpi/temot",
                    disabledFunction: "GPI-TEMOT-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        gpi: "temot",
                    },
                },
            },
        },
    }
}
