import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import { initDamageCalculationGlassBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    // debug: true,
    components: [Main],
    init: initDamageCalculationGlassBundle,
}

export default bundle
