import * as React from "react"
import { Provider } from "react-redux"
import { Store } from "redux"

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(comp: React.FC<Omit<P, keyof StoreModel>>): React.FC<P> {
    const component: React.FC<P> = (props) => {
        const { store } = props
        return React.createElement(Provider, { store },
            React.createElement(comp, props))
    }
    return component
}