import { UserContext, UserModuleType } from "@tm/models"
import { createQueryString } from "@tm/utils"
import { useFastServiceStore } from "../../../../data"
import { getBundleParams } from "../../../../utils"
import { HaynesProModuleMode, HaynesProSmartlinkModel, SmartLink } from "../../../../data/models"

export function getHaynesProSmartLinkUrl(link: SmartLink, userContext: UserContext, languageId: string) {
    const { haynesProLoginUrl } = getBundleParams()

    const { stateVehicle } = useFastServiceStore.getState().maintenancePlan

    const clientId = userContext?.modules?.find((x) => x && x.type === UserModuleType.Catalog)?.id

    if (!haynesProLoginUrl || !userContext || !stateVehicle) {
        return
    }

    const requestParams: HaynesProSmartlinkModel = {
        moduleMode: HaynesProModuleMode.SmartLinks,
        platformId: "4",
        languageId,
        mdmSessionId: userContext.id,
        h_session: "",
        catNr: clientId,
        clientId,
        kTypeId: stateVehicle.tecDocTypeId,
        operation: link.operation,
        carTypeGroup: link.id2 || link.filter || "",
        carTypeId: link.id1,
        additionalTitle: link.text?.[0] || "",
        typeArt: "1",
    }

    return `${haynesProLoginUrl}${createQueryString(requestParams)}`
}
