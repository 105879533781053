import {
    Article,
    ArticleIdentifier,
    ArticlePrice,
    CatalogPart,
    DATOePart,
    DATWork,
    eOEArtType,
    ErpInformation,
    ErpPrice,
    OE,
    PriceType,
    RepairTimeRD,
    ServiceNote,
    UserContext,
    Vehicle,
} from "@tm/models"
import { createPriceDictionaryFromErpPrices, getCurrencyFromUserContext, getShortCountryCodeByUserContext } from "@tm/utils"
import { DATCatalogPart, ICalculationItem } from "../../../data/model"
import { BasicErpInfo } from "../../../data/repositories/updateTotalPrices/model"

export const mapIAMPartToArticle = (part: Article, erpArticle?: ErpInformation): Article => {
    return {
        ...part,
        prices:
            erpArticle?.prices?.map((x) =>
                mapErpPriceToArticlePrice(
                    x,
                    part.prices?.find((y) => x.type === y.priceType)
                )
            ) || part.prices,
    }
}

export const mapWorkToService = (work: DATWork): RepairTimeRD => {
    return {
        ...work,
        attributes: [],
        hasCalculatedServices: true,
        hasPreparationWorks: false,
        isCalculated: true,
        note: {} as unknown as ServiceNote,
        productGroupIds: work.productGroupIds! || [],
        repairTimeNo: work.providerWorkId,
        serviceType: "",
        sortId: 0,
        repairTime: work.time,
    }
}

const mapErpPriceToArticlePrice = (erpPrice: ErpPrice, articlePrice?: ArticlePrice): ArticlePrice => {
    return {
        countryCode: getShortCountryCodeByUserContext(),
        currencyCode: erpPrice.currencyCode_Iso_4217,
        deliveryStatus: articlePrice?.deliveryStatus || 0,
        deliveryStatusId: articlePrice?.deliveryStatusId || 0,
        description: erpPrice.description,
        discount: articlePrice?.discount || 0,
        discountGroupId: articlePrice?.discountGroupId || 0,
        isTraderPrice: articlePrice?.isTraderPrice || 0,
        priceType: erpPrice.type,
        priceUnit: erpPrice.priceUnit?.toString() || articlePrice?.priceUnit || "",
        priceUnitId: articlePrice?.priceUnitId || 0,
        quantityUnit: articlePrice?.quantityUnit || "",
        quantityUnitId: articlePrice?.quantityUnitId || 0,
        scaleQuantity: articlePrice?.scaleQuantity || 0,
        validFrom: articlePrice?.validFrom || new Date(),
        value: erpPrice.value || articlePrice?.value || 0,
        currencySymbol: erpPrice.currencySymbol || articlePrice?.currencySymbol,
        traderDescription: articlePrice?.traderDescription,
        traderPriceType: articlePrice?.traderPriceType,
        validTo: articlePrice?.validTo,
    }
}

export const mapBasicErpInfo = (erpInfo: ErpInformation): BasicErpInfo => {
    const { dataSupplierArticleNumber, prices } = erpInfo

    return {
        dataSupplierArticleNumber,
        prices,
    }
}

export const mapItemToCatalogPart = (item: ICalculationItem, erpArticle?: ErpInformation): CatalogPart => {
    return {
        dataSupplierArticleNumber: item.selectedPart!.supplierArticleNo,
        dataSupplierId: item.selectedPart!.supplier.id,
        dataSupplierName: item.selectedPart!.supplier.name,
        productGroupId: item.selectedPart!.productGroup.id,
        productGroupName: item.selectedPart!.productGroup.name,
        quantityValue: item.oeArticle.quantityValue,
        additionalDescription: item.selectedPart!.additionalDescription,
        description: item.selectedPart!.description,
        fittingSide: item.selectedPart!.fittingSide,
        garagePrice: item.selectedPart!.prices?.find((x) => x.priceType === PriceType.Garage)?.value,
        isReplacementPart: true,
        thumbnailUrl: item.selectedPart!.thumbnail,
        erpInformation: {
            prices: erpArticle?.prices ? createPriceDictionaryFromErpPrices(erpArticle?.prices) : {},
        },
        wholesalerArticleNumber: item.selectedPart!.traderArticleNo,
    }
}

export const mapItemToDatCatalogPart = (item: ICalculationItem): DATCatalogPart => {
    return {
        dataSupplierArticleNumber: item.selectedPart!.supplierArticleNo,
        dataSupplierId: item.selectedPart!.supplier.id,
        dataSupplierName: item.selectedPart!.supplier.name,
        productGroupId: item.selectedPart!.productGroup.id,
        productGroupName: item.selectedPart!.productGroup.name,
        quantityValue: item.oeArticle.quantityValue,
        articlePrices: item.selectedPart!.prices,
    }
}

export const mapDatOePartToOE = (oeArticle: DATOePart, currency: string, vehicle?: Vehicle): OE.OePart => {
    return {
        description: oeArticle.description,
        ...(oeArticle.type === eOEArtType.MaterialPosition && {
            manufacturerName: vehicle?.manufacturer,
        }),
        ...(oeArticle.type !== eOEArtType.MaterialPosition && {
            manufacturerName: "DAT",
            thumbnailUrl: "/styles/base/images/dat-logo.png",
        }),
        manufacturerId: vehicle?.tecDocManufacturerId,
        number: oeArticle.oeArticleNumber,
        prices: [
            {
                value: oeArticle.oePriceValue ?? 0,
                currency,
            },
        ],
        quantity: oeArticle.quantityValue,
    }
}

export const mapDatOePartToOePart = (oeArticle: DATOePart, userContext?: UserContext): OE.OePart => {
    return {
        id: oeArticle.datProcessId?.toString(),
        description: oeArticle.description,
        number: oeArticle.oeArticleNumber,
        additionalDescription: oeArticle.additionalDescription,
        quantity: oeArticle.quantityValue,
        manufacturerId: oeArticle.vehicleManufacturerId,
        manufacturerName: oeArticle.vehicleManufacturerName,
        thumbnailUrl: oeArticle.thumbnailUrl,
        productGroupId: oeArticle.productGroupId,
        ...(oeArticle.oePriceValue && {
            prices: [
                {
                    currency: getCurrencyFromUserContext(userContext),
                    value: oeArticle.oePriceValue,
                },
            ],
        }),
    }
}

export const mapArticleToArticleIdentifier = (selectedPart: Article, tecdocTypeNo?: number): ArticleIdentifier => {
    return {
        productGroupId: selectedPart.productGroup.id,
        supplierArticleNo: selectedPart.supplierArticleNo,
        supplierId: selectedPart.supplier.id,
        tecdocTypeNo,
    }
}
