import { Button, Loader, SubTitle } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { bindActionCreators } from "redux"
import { useCallback, useEffect, useMemo } from "react"
import ErrorSearch from "../../components/_shared/ErrorSearch"
import { addErrQueryToHistory } from "../../data/helpers/queryHistory"
import { FastDtcRouteParams } from "../../models"
import { ErrorSearchActions, ErrorSearchState } from "./business"

export default function ErrorSearchMicro() {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const actions = bindActionCreators(ErrorSearchActions, dispatch)
    const history = useHistory()
    const params = useParams<FastDtcRouteParams>()

    const queries = useSelector((s: ErrorSearchState) => s.errorQueryHistory)
    const loadingQueries = useSelector((s: ErrorSearchState) => s.errorQueriesLoading)

    useEffect(() => {
        actions.loadQueryHistory()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const startSearch = useCallback(
        (value: string) => {
            addErrQueryToHistory(value)
            // TODO put in uiconfig
            const route = renderRoute("/:workTaskId/fastdtc/search", { ...params })
            history.push({
                pathname: route,
                search: `errorCodes=${value}&fromWidget=1`,
            })
        },
        [history, params]
    )

    const renderButtons = useMemo(() => {
        return queries.map((query, idx) => (
            <Button
                onClick={() => startSearch(query)}
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
            >
                {query}
            </Button>
        ))
    }, [queries, startSearch])

    return (
        <>
            <ErrorSearch onSubmit={startSearch} />
            <div className="lower">
                <div className="wrapper" />
                {!!queries.length && (
                    <div className="history">
                        <SubTitle>{translateText(1073)}</SubTitle>
                        {renderButtons}
                    </div>
                )}
                {!queries.length && loadingQueries && <Loader className="history" />}
            </div>
        </>
    )
}
