import { SessionStorageKeys } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleInvalidSessionMvc(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { invalidSessionMvc } = data

    if (!invalidSessionMvc) {
        return
    }

    Object.keys(sessionStorage).forEach((key) => {
        if (key.indexOf(SessionStorageKeys.mvcSessionId) === -1) {
            return
        }

        if (sessionStorage.getItem(key) === invalidSessionMvc) {
            sessionStorage.setItem(key, `${SessionStorageKeys.invalidSessionMvc}${invalidSessionMvc}`)
        }
    })
}
