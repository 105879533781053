import { getBundleParams } from "../utils"

import * as CustomerRepository from "./repositories/customer"
import * as VehicleRepository from "./repositories/vehicle"
import * as AddressPickerRepository from "./repositories/address-picker"

export * from "./models"

export { CustomerRepository }
export { VehicleRepository }
export { AddressPickerRepository }

export function getGsiServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.gsiServiceUrl
}

export function getAddressPickerServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.addressPickerServiceUrl
}

export function getVehicleRecordsServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.vehicleRecordsServiceUrl
}
