export function removeBrackets(value?: string): string | undefined {
    if (!value) {
        return
    }

    const regex = /^(.*?)(?:\(.*?\))?$/
    const match = regex.exec(value)

    if (!match) {
        return value
    }

    return match[1]
}
