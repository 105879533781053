import { IFrame } from "@tm/controls"
import { channel } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { initSelector } from "@tm/utils"
import { batch, useSelector } from "react-redux"
import { Box, Loader, styled } from "@tm/components"
import { useEffect } from "react"
import { EreSelectionSteps } from "../../data/models"
import { MainActions, vehicleSelector } from "../main/business"

const selector = initSelector(
    vehicleSelector,
    (s) => s.extern.externalUrl,
    (s) => s.extern.historyId
)

export default function ExternComponent() {
    const [vehicle, externalUrl, historyId] = useSelector(selector)
    const actions = useActions(MainActions, "changeStep", "getArticles", "getHistory", "removeIframe")

    useEffect(() => {
        const subscription = channel("GLOBAL").subscribe("DMG_EUROTAX/EXTERNAL_TOOL_DONE", () => {
            batch(() => {
                actions.getArticles(historyId!, true)
                actions.changeStep(EreSelectionSteps.CALCULATION)
                actions.removeIframe(true)
            })
        })

        return subscription
    }, [vehicle, externalUrl, historyId])

    return (
        <StyledBox>
            {!externalUrl && <Loader />}
            {externalUrl && <StyledIFrame hide={!externalUrl} url={externalUrl} id="ere-external-url" />}
        </StyledBox>
    )
}

const StyledIFrame = styled(IFrame, {
    shouldForwardProp: (prop) => prop !== "hide",
})<{ hide?: boolean }>(({ hide }) => ({
    flex: 1,
    height: "100%",
    width: "100%",
    ...(hide && {
        display: "none",
    }),
}))

const StyledBox = styled(Box)({
    flex: "1",
    width: "100%",
    height: "100%",
    ".MuiBox-root": {
        height: "100%",
    },
})
