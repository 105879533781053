export enum eOEArtType {
    MaterialPosition = 0,
    AdditionalCostsPosition = 1,
    SmallSparePart = 2,
    MaterialFlatAmountLacquerWorks = 3,
    MaterialsForLacquerWorks = 4,
    ConstantCostsForLacquerWorks = 5,
    DiscountForLacquerWorks = 6,
    SmallPartsAndConsumables = 7,
}

export enum eOEArtSubType {
    ProcurementCosts = 99106,
    FreightCharges = 99105,
    CuttingWire = 99101,
    CleaningSupplies = 99039,
    EnvironmentalPackages = 44905,
    SurchargeOrDiscountPackage = 99126,
    RemoveGlassSplitter = 90608, // in hours
    MobileServiceCart = 99110,
    OtherItems = 99120,
    RentalCarCosts = 99113,
    SunProtectionFilm = 99102,
    TowingCosts = 99111,
    ShippingCosts = 99112,
    SurchargeOrDiscount = 99125, // in %
    DisposalCost = 99044,
}

export enum DatSelectionSteps {
    SUMARRY = "summary",
    EXTERN = "extern",
    CALCULATION = "calculation",
    DATSETTINGS = "dat-settings",
}
