import { Button, Dropdown } from '@tm/controls'
import * as React from 'react'
import { RimFilter } from '../../data/model'

type Props = {
    filterType: string
    items: RimFilter[]
    selectedValue?: RimFilter
    onChange: (path: string, filter: RimFilter) => void
    onReset: (path: string) => void
    withReset?: { showButton: boolean, isEnabled: boolean }
}

const DropDownWrapper: React.FC<Props> = ({ filterType, items, onReset, onChange, withReset, selectedValue }) => {

    const handleOnChange = (value: RimFilter) => {
        if (value.attributeId == -1)
            onReset(value.info)
        else
            onChange(value.attributeId.toString(), value)
    }

    const handleButtonReset = () => {
        onReset(filterType)
    }

    return (
        <>
            <Dropdown
                value={selectedValue || items.first()}
                items={items}
                onChange={handleOnChange}
                itemView={DropDownItemView}
                amountItemsToShow={10}
            />
            {withReset?.showButton && <Button layout={['ghost']} size="s" disabled={withReset.isEnabled} onClick={handleButtonReset} icon="synchronize" />}
        </>
    )
}

export default DropDownWrapper

const DropDownItemView: React.FC<RimFilter> = React.memo(({ attributeValue }) => {
    return (
        <div style={{ textAlign: 'center' }}>{attributeValue}</div>
    )
})