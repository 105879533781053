import { useLocalization } from "@tm/localization"
import { Box, Stack, Typography, BonusPoints } from "@tm/components"
import { GetBonusInformationResponse } from "@tm/models"
import { BasketPart } from "../../../../../../models"
import ErrorAlert from "../../../../ErrorAlert"
import { LoaderSmall } from "../../../../../StyledComponents"

type Props = {
    bonusInformation?: GetBonusInformationResponse
    bonusInformationLoading?: boolean
    bonusPointsWithErrors?: boolean
    bonusPointsLoading?: boolean
    part: BasketPart
    showBonusInformation: boolean
}

export default function PartBonusPoints(props: Props) {
    const { part, showBonusInformation, bonusInformationLoading, bonusInformation, bonusPointsWithErrors, bonusPointsLoading } = props
    const { partItem, bonusPoints } = part
    const { translateText, translate, number } = useLocalization()
    if (!showBonusInformation && !bonusPoints) {
        return null
    }

    if (bonusInformationLoading || bonusPointsLoading) {
        return <LoaderSmall />
    }

    // TODO: Move to PartTable's Footer with right label
    if (bonusPointsWithErrors) {
        return <ErrorAlert />
    }

    const bonusInformationPoints = bonusInformation?.items.find((b) => b.itemId === partItem.id)?.bonusPoints

    const bonusPointTotalsKey = parseInt(Object.keys(bonusPoints?.bonusPointTotals)[0])
    const bonusPointTotals = bonusPoints?.bonusPointTotals ? Object.values(bonusPoints.bonusPointTotals)[0] : undefined
    const bonusPointPerUnit = bonusPoints?.bonusPointsPerUnit ? Object.values(bonusPoints.bonusPointsPerUnit)[0] : undefined

    return (
        <Box textAlign="center">
            {showBonusInformation && (
                // NEXT-17229
                <Stack>
                    <Typography variant="label">{translate(1914)}</Typography>
                    {bonusInformationPoints ? number(bonusInformationPoints, 0) : "-"}
                </Stack>
            )}
            {bonusPointTotals && (
                <BonusPoints
                    bonusSystem={{
                        id: bonusPointTotalsKey,
                        imageUrl: "",
                        value: bonusPointTotals || 0,
                        name: `${bonusPointPerUnit?.toString()} ${translateText(13113)}`,
                    }}
                />
            )}
        </Box>
    )
}
