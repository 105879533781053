import { Box, BoxProps, Divider, Typography } from "@tm/components"
import { ReactNode } from "react"

type Props = {
    children?: ReactNode
    title?: string
    showToolbarDivider?: boolean
} & BoxProps

export default function Toolbar(props: Props) {
    const { children, showToolbarDivider, title, ...rest } = props
    return (
        <Box position="relative" display="flex" flexDirection="column" padding="0px 19px" {...rest}>
            {showToolbarDivider && (
                <Divider
                    flexItem
                    orientation="vertical"
                    sx={{ borderRightWidth: "3px", height: "32px", position: "absolute", top: "0", left: "0" }}
                />
            )}
            <Typography
                variant="label"
                fontSize="10px"
                textTransform="uppercase"
                sx={{ margin: "5px 0px", visibility: title ? "visible" : "hidden" }}
            >
                {title ?? "Hidden"}
            </Typography>
            <Box display="flex" gap="4px" flexDirection="row">
                {children}
            </Box>
        </Box>
    )
}
