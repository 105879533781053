import { Box, Button, Icon, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useState } from "react"
import { FileShareOption } from "./useFileShare"

export const ShareButtonWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== "isSelected" })<{ isSelected?: boolean }>(
    ({ isSelected, theme }) => ({
        ...(isSelected && {
            border: `${theme.colors?.primary} solid 1px`,
            borderRadius: theme.radius?.default,
        }),
    })
)

type ShareOptionsProps = {
    onShareOptionSelected: (shareOption: FileShareOption) => void
}

export default function ShareOptions({ onShareOptionSelected }: ShareOptionsProps) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const [selectedShareOption, setSelectedShareOption] = useState<FileShareOption | undefined>()

    function selectShareOption(option: FileShareOption) {
        setSelectedShareOption(option)
        onShareOptionSelected?.(option)
    }

    return (
        <Box display="flex" gap="1em">
            <ShareButtonWrapper isSelected={selectedShareOption === FileShareOption.Whatsapp}>
                <Button
                    variant="outlined"
                    size="extralarge"
                    startIcon={<Icon name="whatsapp" color="green" />}
                    data-action="share/whatsapp/share"
                    onClick={() => selectShareOption(FileShareOption.Whatsapp)}
                >
                    {translateText(12784)}
                </Button>
            </ShareButtonWrapper>

            <ShareButtonWrapper isSelected={selectedShareOption === FileShareOption.Email}>
                <Button
                    variant="outlined"
                    size="extralarge"
                    startIcon={<Icon name="envelope" />}
                    onClick={() => selectShareOption(FileShareOption.Email)}
                >
                    {translateText(109)}
                </Button>
            </ShareButtonWrapper>

            <ShareButtonWrapper isSelected={selectedShareOption === FileShareOption.Carteligence}>
                {renderMicro("notifications", "send-cost-estimation-button", {
                    asShareButton: true,
                    onClick: () => selectShareOption(FileShareOption.Carteligence),
                })}
            </ShareButtonWrapper>
        </Box>
    )
}
