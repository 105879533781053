import { clone } from "@tm/utils"
import { NavigationState } from "."
import { BundleActionTypes } from "../../../business"
import { EurotaxSelectionSteps } from "../../../data/enums"
export * from "./model"

export type ComponentActionType = BundleActionTypes 
    | { type: "CHANGE_STEP", payload: { selectedStep: EurotaxSelectionSteps } }


const DEFAULT_STATE: NavigationState = {
    active: EurotaxSelectionSteps.NONE,
    params: {}
}

export function reduce(state = clone(DEFAULT_STATE), action: ComponentActionType): NavigationState {
    switch (action.type) {
        case "CHANGE_STEP": {
            const { selectedStep } = action.payload
            let { params, active } = { ...state }
            if (active == EurotaxSelectionSteps.SUMMARY && selectedStep == EurotaxSelectionSteps.DETAILS)
                delete params[EurotaxSelectionSteps.SELECTION] //to disable this tab

            return {
                ...state,
                active: selectedStep,
                params: {
                    ...params,
                    [selectedStep]: true
                }

            }
        }
        case "RESET_LOCAL_STORE": {
            return DEFAULT_STATE
        }
    }
    return state
}


const changeStep = (selectedStep: EurotaxSelectionSteps): ComponentActionType => ({ type: "CHANGE_STEP", payload: { selectedStep } })

export type IActions = typeof Actions

export const Actions = {
    changeStep
}