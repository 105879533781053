import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { colorLightGray } from "../../styles"

export function getSharedChatInfoStyles() {
    const { margin } = getStyleTheme()
    const padding = `${margin.s} ${margin.l}`

    return stylesheet({
        infoHeader: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding,
            border: "1px solid #d3d3d3",
            background: colorLightGray,
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
