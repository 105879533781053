import { RegistrationNoType, Vehicle, VrmLookupTextIds } from "@tm/models"
import { BundleActionTypes } from "../business"
import { DEFAULT_STATE } from "../components/summary/business"
import { MainActionsType } from "../components/main/business"
import { getDATErrorMessageTextId } from "../components/summary/business/helper"
import { SummaryState } from "../components/summary/business/model"
import { DATVehicleVinSearchResponse } from "../data/repositories/datVehicleVinSearch/model"

export type SummaryActionTypes =
    | BundleActionTypes
    | { type: "UPDATE_FIELD"; payload: { path: string; value: any } }
    | { type: "TRIGGER_NEW_CALCULATION" }
    | { type: "INIT_NEW_CALCULATION_ERROR" }
    | { type: "USE_NEXT_HOURLY_RATES"; payload: boolean }
    | { type: "INIT_CUSTOMER"; payload: { country: string } }
    | { type: "CHECK_VIN"; payload: DATVehicleVinSearchResponse }
    | { type: "CHECK_VIN_LOADING"; payload: boolean }
    | { type: "VEHICLE_HAS_DAT_INFO"; payload: boolean }
    | { type: "VEHICLE_HAS_KBA"; payload: boolean }
    | { type: "SHOW_VEHICLE_MODELS_LOADING"; payload: boolean }

export const reduce = (state = { ...DEFAULT_STATE }, action: MainActionsType): SummaryState => {
    switch (action.type) {
        case "@@redux/INIT":
        case "RESTART_PROCESS": {
            return {
                ...DEFAULT_STATE,
                fields: state.fields,
                datVinSearchIsValid: state.datVinSearchIsValid,
                useCatalogHourlyRates: state.useCatalogHourlyRates,
                vinSearchInformalMessage: state.vinSearchInformalMessage,
            }
        }
        case "UPDATE_FIELD": {
            const { path, value } = action.payload

            return {
                ...state,
                fields: {
                    ...state.fields,
                    [path]: value,
                },
                ...(path === "vin" &&
                    !!value &&
                    value?.length < 17 && {
                        vinSearchInformalMessage: undefined,
                    }),
                errorMessage: undefined,
            }
        }
        case "TRIGGER_NEW_CALCULATION": {
            return {
                ...state,
                loading: true,
                startCalcError: false,
            }
        }
        case "INIT_NEW_CALCULATION": {
            return {
                ...state,
                errorMessage: action.payload.errorMessage,
                loading: false,
            }
        }
        case "INIT_NEW_CALCULATION_ERROR": {
            return {
                ...state,
                startCalcError: true,
                loading: false,
            }
        }
        case "VEHICLE_SET": {
            const { registrationNo, initialRegistration, plateId, vin, mileAge } = action.payload

            return {
                ...state,
                fields: {
                    ...state.fields,
                    initialRegistration,
                    vin,
                    registrationNo,
                    plateNumber: plateId,
                    mileage: mileAge,
                },
            }
        }
        case "CUSTOMER_SET": {
            const { title, titleType, firstName, lastName, zip, city, country, email, phone, companyName } = action.payload

            const street = action.payload?.street && /^[^\d]*/gi.exec(action.payload.street)?.[0]?.trim()
            const streetNo = action.payload?.street && /[\d+](.*)/gi.exec(action.payload.street)?.[0]?.trim()

            return {
                ...state,
                fields: {
                    ...state.fields,
                    title,
                    titleType,
                    firstName,
                    lastName,
                    street,
                    streetNo,
                    zip,
                    city,
                    country,
                    email,
                    phone,
                    companyName,
                },
            }
        }
        case "INIT_CUSTOMER": {
            const { country } = action.payload

            return {
                ...state,
                fields: {
                    ...state.fields,
                    country,
                },
            }
        }
        case "VEHICLE_DETAILS_LOADED": {
            const { modelDetails } = action.payload
            const minDate =
                modelDetails?.constructionYearFrom && new Date(modelDetails.constructionYearFrom.year, modelDetails.constructionYearFrom.month, 0)
            const maxDate =
                modelDetails?.constructionYearTo && new Date(modelDetails.constructionYearTo.year, modelDetails.constructionYearTo.month, 0)

            return {
                ...state,
                fields: {
                    ...state.fields,
                    registrationNos: modelDetails?.registrationNos || [],
                    minDate,
                    maxDate,
                },
            }
        }
        case "USE_NEXT_HOURLY_RATES": {
            return {
                ...state,
                useCatalogHourlyRates: action.payload,
            }
        }
        case "CHECK_VIN": {
            const { error, vehicles } = action.payload
            return {
                ...state,
                checkVinLoading: false,
                datVinSearchIsValid: vehicles?.length > 0 || false,
                ...(error?.errorType && {
                    vinSearchInformalMessage: getDATErrorMessageTextId(error),
                }),
                ...(vehicles?.length > 0 && {
                    vinSearchInformalMessage: VrmLookupTextIds.ResultSuccess,
                }),
                ...(vehicles?.length === 0 && {
                    vinSearchInformalMessage: VrmLookupTextIds.NoResultForVin,
                }),
            }
        }
        case "CHECK_VIN_LOADING": {
            return {
                ...state,
                checkVinLoading: action.payload,
            }
        }
        case "VEHICLE_HAS_DAT_INFO": {
            return {
                ...state,
                datVinSearchIsValid: action.payload,
                vinSearchInformalMessage: action.payload ? VrmLookupTextIds.ResultSuccess : VrmLookupTextIds.NoResultForVin,
                showVehicleModelDetailsLoading: false,
            }
        }
        case "VEHICLE_HAS_KBA": {
            return {
                ...state,
                isGermanOrAustrianVehicle: action.payload,
            }
        }
        case "SHOW_VEHICLE_MODELS_LOADING": {
            return {
                ...state,
                showVehicleModelDetailsLoading: action.payload,
            }
        }
        default:
            return state
    }
}
