import { Tooltip, Typography } from "@tm/components"
import { Memo, MemoType } from "@tm/models"
import { SmallIcon } from "../../../../../../StyledComponents"

type Props = {
    memo: Memo
    alignLabelEnd?: boolean
}

export default function PriceMemo({ memo, alignLabelEnd }: Props) {
    switch (memo.type) {
        case MemoType.Note:
            return (
                <Typography variant="label" display="block" textAlign={alignLabelEnd ? "end" : undefined}>
                    {memo.text}
                </Typography>
            )
        case MemoType.AdditionalDescription:
            return (
                <Tooltip title={memo.text}>
                    <SmallIcon name="info" />
                </Tooltip>
            )
        default:
            return null
    }
}
