import { useMemo } from "react"
import { CisVoucherType } from "@tm/models"
import { Box, Loader } from "@tm/components"
import { CompletedOrderListItem, OpenOrderListItem } from "../../../../../data/model"
import StgOrderDetailsHeader from "../voucher-details-heads/StgOrderDetailsHeader"
import { useOrderDetails } from "../../../../../data/hooks/useOrderDetails"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { ErrorMessage } from "../../../../_shared/ErrorMessage"
import { ColumnStack } from "../../../../_shared/StyledComponents"
import StgOrderItemsTable from "../voucher-items-tables/StgOderItemsTable"

type Props = {
    specialOrders?: Array<CompletedOrderListItem | OpenOrderListItem>
    selectedVoucherId: string
    selectedVoucherSubId: string
    voucherTypeId: CisVoucherType
    onPrintButtonClick(orderNumber: string, orderSubNumber: string, voucherId: string, warehouseId: string, voucherTypeId?: CisVoucherType): void
}

export default function StgOrderDetailsComponent(props: Props) {
    const { voucherTypeId, selectedVoucherId, specialOrders, selectedVoucherSubId, onPrintButtonClick } = props

    const selectedVoucher = useMemo(() => {
        return specialOrders?.find((order) => order.orderNumber === selectedVoucherId && order.orderSubNumber === selectedVoucherSubId)
    }, [selectedVoucherId, selectedVoucherSubId, specialOrders])

    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && {
            voucherTypeId,
            warehouseId: selectedVoucher.warehouseId,
            orderNumber: selectedVoucher.orderNumber,
            orderSubNumber: selectedVoucher.orderSubNumber,
        }
    )

    let content = <></>
    if (selectedVoucher) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (orderDetails && orderDetails.voucherItems?.length > 0) {
            content = (
                <ColumnStack>
                    <StgOrderDetailsHeader key="VoucherHead" orderDetails={orderDetails} onPrintButtonClick={onPrintButtonClick} />
                    <StgOrderItemsTable
                        key="VoucherItemsTable"
                        currencyCode={orderDetails.currencyCode}
                        voucherItems={orderDetails.voucherItems}
                        voucherTypeId={voucherTypeId}
                    />
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    }

    return <Box flex={1}>{content}</Box>
}
