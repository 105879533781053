import { Button, Icon, Box, styled, Badge, Loader } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Toolbar, WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ESubModules, RegistrationNoType, SystemType, UserModuleType, Vehicle } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { equals, getCostPerCalculation, getSystemTypeFromUserContext, uniqueId } from "@tm/utils"
import { useSelector } from "react-redux"
import { useRef } from "react"
import { getBundleParams } from "../../../utils"
import { managerSelector, summarySelector } from "../../../selectors"
import { MainActions } from "../../main/business"
import { validateFields } from "../../summary/business/helper"

export default function SummaryActions() {
    const user = useUser()
    const { translateText } = useLocalization()
    const workTask = useWorkTask()
    const warningRef = useRef<WarningPrompt>(null)
    const { fields, loading, isGermanOrAustrianVehicle } = useSelector(summarySelector)
    const { vehicle, customer, userIsRegistered } = useSelector(managerSelector)
    const modelState = validateFields(fields, translateText, isGermanOrAustrianVehicle)
    const invalid = Object.values(modelState).some((val) => val.length)

    const actions = useActions(MainActions, "startCalculation", "resetStore")

    const handleStartCalculation = (fromPrompt?: boolean) => {
        const { useAttachToWorktask } = getBundleParams()

        if (userIsRegistered && user?.userContext) {
            const calculationCost = getCostPerCalculation(UserModuleType.TMDamageCalculationDat, ESubModules.ShowSk_DATFIOnline, user?.userContext)

            if (calculationCost && !fromPrompt) {
                warningRef?.current?.show()
            } else {
                actions.startCalculation(translateText)

                if (getSystemTypeFromUserContext(user.userContext) === SystemType.Next && useAttachToWorktask) {
                    updateWorkTaskInfo()
                }
            }
        } else {
            actions.resetStore()
        }
    }

    const updateWorkTaskInfo = () => {
        if (!vehicle) {
            return
        }

        const { initialRegistration, vin, plateNumber: plateId, registrationNo, mileage: mileAge } = fields
        const { title, titleType, firstName, lastName, zip, city, country, email, phone, companyName } = fields

        const updatedVehicle: Vehicle = {
            ...vehicle,
            ...(initialRegistration !== vehicle.initialRegistration && { initialRegistration }),
            ...(vin !== vehicle.vin && { vin }),
            ...(plateId !== vehicle.plateId && { plateId }),
            ...(registrationNo !== vehicle.registrationNo && { registrationNo }),
            ...(mileAge !== vehicle.mileAge && { mileAge }),
        }
        const shouldUpdateVehicle = !equals(vehicle, updatedVehicle)

        const { street, streetNo } = fields
        let streetUpdated

        if (street?.length) {
            streetUpdated = street

            if (streetNo?.length) {
                streetUpdated += ` ${streetNo}`
            }
        }

        const updatedCustomer = {
            id: uniqueId(),
            ...customer,
            title,
            titleType,
            firstName,
            lastName,
            street: streetUpdated?.trim(),
            zip,
            city,
            country,
            email,
            phone,
            companyName,
        }

        const shouldUpdateCustomer = !equals(customer, updatedCustomer) && !!titleType && !!firstName && !!lastName

        if (shouldUpdateCustomer || shouldUpdateVehicle) {
            workTask?.attachToWorkTask({
                ...(shouldUpdateVehicle && { vehicle: { ...updatedVehicle, registrationTypeId: RegistrationNoType.DatVin } }),
                ...(shouldUpdateCustomer && { customer: updatedCustomer }),
            })
        }
    }

    return (
        <>
            <Box sx={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
                <StyledToolbar />

                <Toolbar title={translateText(12884)}>
                    <Badge badgeContent={loading ? <Loader size="small" /> : null}>
                        <Button
                            size="medium"
                            color={invalid ? undefined : "highlight"}
                            startIcon={<Icon name="damage-calculation" />}
                            disabled={invalid || loading}
                            onClick={() => handleStartCalculation(false)}
                        >
                            {translateText(1461)}
                        </Button>
                    </Badge>
                </Toolbar>
            </Box>

            <WarningPrompt
                ref={warningRef}
                confirmationButtonText={translateText(1628)}
                onConfirm={() => handleStartCalculation(true)}
                text={translateText(1627)}
            />
        </>
    )
}

const StyledToolbar = styled(Toolbar)(() => ({
    flex: 1,
    justifyContent: "flex-end",
    "&:before": {
        content: "unset",
    },
}))
