import { SavedCalculation } from "../repositories/fastcalculator-getSavedCalculations/model"

export enum CalculationHistoryStatus {
    None = 0,
    ReadyToStart = 1,
    InProgress = 2,
    ReadyForExam = 3,
    InExamination = 4,
    Finished = 5,
    FastCalcHistory = 6,
}

export type HistorySummary = {
    id: string
    version: number
    dateTime: string
    status: CalculationHistoryStatus
}

export type GenericHistoryEntry = HistorySummary | SavedCalculation
