import { Scrollbar } from "@tm/controls"
import { Box, useTheme } from "@tm/components"
import { useParams } from "react-router"
import { MatchParams } from "../../data/models"
import Calculation from "../calculation/component"
import Extern from "../extern/component"
import ModuleManager from "../moduleManager/component"
import Navigation from "../navigation/component"
import Summary from "../summary/component"
import Transactions from "../transactions/component"

type Props = {
    route: string
    detailsModalRoute: string
}

export default function Main({ route, detailsModalRoute }: Props) {
    const theme = useTheme()
    const matchParams = useParams<MatchParams>()

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <Navigation route={route} />
            <ModuleManager />
            <Box style={{ padding: theme.margin?.m, flex: "1", display: "flex" }}>
                <Scrollbar>
                    {matchParams.view === "summary" && <Summary />}
                    {matchParams.view === "extern" && <Extern />}
                    {matchParams.view === "calculation" && <Calculation detailsModalRoute={detailsModalRoute} />}
                    {matchParams.view === "transactions" && <Transactions />}
                </Scrollbar>
            </Box>
        </Box>
    )
}
