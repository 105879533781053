import { useRef } from "react"
import { createQueryString, encodeUniqueId, renderRoute, useExternalCatalogUrl } from "@tm/utils"
import { LinkButton, Button, Icon, Stack } from "@tm/components"
import { getLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { Dialog } from "@tm/controls"
import { WorkTaskStatus } from "@tm/models"
import { WorktaskInfo } from "../../../data/repositories"
import { useShowOrderVoucherTypeId } from "../../../../../cis/src/data/hooks/useShowOrderVoucherTypeId"
import { getBundleParams } from "../../../utils"

type RenderActionsProps = {
    allowOpenClosedWorktask?: boolean
    checkVoucherTypeId?: boolean
    externalSystemId?: number
    sentOrderRoute?: string
    sentOrderVoucherTypeId?: number
    workTaskInfo: WorktaskInfo
    handleClickCreateNewWorktask: (workTaskInfo: WorktaskInfo, vehicle?: string) => void
}

export default function WorkTaskActionsComponent({
    allowOpenClosedWorktask,
    checkVoucherTypeId,
    externalSystemId,
    sentOrderRoute,
    sentOrderVoucherTypeId,
    workTaskInfo,
    handleClickCreateNewWorktask,
}: RenderActionsProps) {
    const { externalCatalogUrl } = useExternalCatalogUrl({
        externalSystemId,
        telesalesCustomerNo: workTaskInfo.partnerNo,
    })
    const workTaskId = encodeUniqueId(workTaskInfo.id)
    const externalDialogRef = useRef<Dialog>(null)
    const { translateText } = getLocalization()
    const textId = workTaskInfo.status !== WorkTaskStatus.Completed ? "414" : "465"
    const { getVoucherInfo } = useShowOrderVoucherTypeId()
    const { hideOpenOrdersButton } = getBundleParams()

    async function openOrder(orderRoute: string, voucherTypeId: number, orderNo: string, orderSubNo?: string) {
        let url = renderRoute(orderRoute, {
            workTaskId,
            voucherTypeId,
            orderNo,
            orderSubNo,
        })

        url += createQueryString({ refCustomerNo: workTaskInfo.refCustomerNo, singleSearch: true })
        Morpheus.showView("1", url)
    }

    function handleOpenOrderButtonClick() {
        if (externalCatalogUrl) {
            externalDialogRef.current?.show()
        } else if (sentOrderRoute) {
            if (sentOrderVoucherTypeId) {
                openOrder(sentOrderRoute, sentOrderVoucherTypeId, workTaskInfo.orderNo)
            } else if (checkVoucherTypeId) {
                getVoucherInfo(workTaskInfo)?.then((response) => {
                    response && openOrder(sentOrderRoute, response.voucherType.typeId, response.orderNumber, response.orderSubNumber)
                })
            }
        }
    }

    return (
        <Stack direction="row">
            {workTaskInfo.status !== WorkTaskStatus.Completed || allowOpenClosedWorktask ? (
                <LinkButton to={`/${workTaskId}`}>{translateText(textId)}</LinkButton>
            ) : (
                <>
                    {!hideOpenOrdersButton && (
                        <Button onClick={handleOpenOrderButtonClick} disabled={!externalCatalogUrl && !workTaskInfo.orderNo}>
                            {translateText(1763)}
                        </Button>
                    )}
                    {externalCatalogUrl && (
                        <Dialog className="form-confirmation" ref={externalDialogRef} text={translateText(1084)} iconName="users" layout="stretch">
                            <iframe className="PDF-IFrame" src={externalCatalogUrl} />
                        </Dialog>
                    )}
                </>
            )}
            {workTaskInfo.customerId && (
                <Button
                    startIcon={<Icon name="add" />}
                    onClick={() => handleClickCreateNewWorktask(workTaskInfo)}
                    title={translateText(13259)}
                    style={{ marginLeft: 5 }}
                />
            )}
            {workTaskInfo.vehicle && (
                <Button
                    onClick={() => handleClickCreateNewWorktask(workTaskInfo, workTaskInfo.vehicleId)}
                    title={translateText(12442)}
                    style={{ marginLeft: 5, width: 32, height: 32 }}
                >
                    <Icon name="worktask-with-vehicle" width="24px" height="26px" />
                </Button>
            )}
        </Stack>
    )
}
