import { useMutation, useQueryClient } from "react-query"
import { BasketErpInformationResponseItem, GetErpInformationBasketResponse } from "../../../model"
import * as Data from "../../.."
import { KEY as ERP_INFO_KEY } from "../queries/useErpInfosBasketData"
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"
import { GetErpInformationBasketRequestPage } from "../../../../models"

export function useBasketErpMutations(workTaskId: string) {
    const queryClient = useQueryClient()
    const basketErpInfoIndex = useWorkTaskBasketStore((store) => store.getBasketErpInfoIndex(workTaskId))

    const { mutateAsync: getErpInformationForPart, isLoading: getErpInformationForPartLoading } = useMutation(
        ({ request }: { partId: string; request: GetErpInformationBasketRequestPage }) => Data.getBasketErpInformation(request),
        {
            onSuccess: (response, { partId, request }) => {
                const responseItem = response?.items?.find((item) => item.itemId === partId)

                if (!responseItem) {
                    return
                }

                queryClient.setQueryData<GetErpInformationBasketResponse | undefined>(
                    [ERP_INFO_KEY, workTaskId, request.distributorId, request.pageIndex, basketErpInfoIndex],
                    (prev) => {
                        if (!prev) {
                            return
                        }

                        return {
                            ...prev,
                            items: prev.items?.map<BasketErpInformationResponseItem>((item) => {
                                if (item.itemId !== partId) {
                                    return item
                                }

                                return responseItem
                            }),
                        }
                    }
                )
            },
        }
    )

    return {
        getErpInformationForPart,
        getErpInformationForPartLoading,
    }
}
