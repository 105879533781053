import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { useWorkTask } from "@tm/context-distribution"
import { SuggestionTextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { Box, styled } from "@tm/components"
import { MainActions, fieldsSelector, isGermanOrAustrianVehicleSelector } from "../../main/business"
import { RepairCoverages } from "../business"
import { validateFields } from "../business/helpers"

export default function MainFields() {
    const { translateText, languageId } = useLocalization()
    const { workTask } = useWorkTask() || {}
    const actions = useActions(MainActions, "updateFieldValue")
    const fields = useSelector(fieldsSelector)
    const { isGermanOrAustrianVehicle } = useSelector(isGermanOrAustrianVehicleSelector)

    const modelState = validateFields(fields, translateText, workTask?.vehicle, isGermanOrAustrianVehicle)
    const [selectedRepairCoverage, setSelectedRepairCoverage] = useState("")

    const repairCoveragesOptions = useMemo<Record<RepairCoverages, string>>(
        () => ({
            More: translateText(3160),
            Complete: translateText(3159),
            OneStoneChip: translateText(3149),
            TwoStoneChips: translateText(3150),
        }),
        [languageId]
    )

    const handleUpdateCoverageField = (value: string) => {
        const key = Object.keys(repairCoveragesOptions).find((x) => repairCoveragesOptions[x as RepairCoverages] === value)

        actions.updateFieldValue("repairCoverage", key ?? value)
    }

    useEffect(() => {
        fields.repairCoverage && setSelectedRepairCoverage(repairCoveragesOptions[fields.repairCoverage])
    }, [fields.repairCoverage, languageId])

    return (
        <Wrapper>
            <SuggestionTextField
                floatingLabel
                className="processName"
                value={fields.processName}
                label={translateText(1471)}
                modelState={modelState}
                onChange={(x) => actions.updateFieldValue("processName", x)}
                path={["processName"]}
            />
            <SuggestionTextField
                floatingLabel
                label={translateText(3141)}
                value={selectedRepairCoverage}
                getSuggestionData={() => Object.values(repairCoveragesOptions)}
                onChangeConfirm={handleUpdateCoverageField}
                modelState={modelState}
                path={["repairCoverage"]}
            />
        </Wrapper>
    )
}

const Wrapper = styled(Box)(({ theme }) => ({
    borderTop: "solid #e2e2e2 .1em",
    display: "flex",
    alignItems: "center",

    ".processName": {
        maxWidth: "20em",
        margin: theme.margin?.s,
    },
}))
