import { Box, Loader, Typography, MuiCustomModal } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { CustomArticle, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useGetCustomArticlesCategory } from "../../../helpers/hooks/useGetCustomArticlesCategory"
import { useShowCustomArticleItems } from "../../../helpers/hooks/useShowCustomArticleItems"

type Props = {
    open: boolean
    onClose(open: boolean): void
    genArtNr: number
    replaceConsumable: (consumable: CustomArticle) => void
    genArtLabel?: string
}

export default function ReplaceConsumable({ genArtLabel, open, onClose, genArtNr, replaceConsumable }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const { categoryTextId: genartTextId, isError: customCategoryError } = useGetCustomArticlesCategory(genArtNr, true)
    const request: Parameters<typeof useShowCustomArticleItems>[0] = {
        categoriesFilter: genartTextId,
    }
    const { data: { pages } = {}, isError, refetch: refetchCustomArticles } = useShowCustomArticleItems(request)
    const customArticles = useMemo(() => pages?.flatMap((x) => x.data) ?? [], [pages])

    const handleClose = () => {
        onClose(false)
    }

    function onConsumableReplace(selected: CustomArticle) {
        replaceConsumable(selected)
        handleClose()
        refetchCustomArticles()
    }

    function onConsumableSaved() {
        refetchCustomArticles()
    }

    let content = (
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyItems="center">
            <Loader />
        </Box>
    )

    if (isError || customCategoryError) {
        content = <Typography>{translateText(12775)}</Typography>
    }

    if (customArticles) {
        content = (
            <>
                {renderMicro("parts", "consumable-replace", {
                    title: genArtLabel ?? translateText(616),
                    category: genartTextId,
                    consumables: customArticles,
                    onConsumableReplace,
                    onConsumableSaved,
                })}
            </>
        )
    }

    if (open) {
        return (
            <MuiCustomModal open showCloseButtonOnTheLeft smallCloseButton onOutsideClick={handleClose}>
                {content}
            </MuiCustomModal>
        )
    }

    return null
}
