import { useMemo } from "react"
import { renderRoute } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { AttachmentButton, StatePill, styled } from "@tm/components"
import { getBundleParams } from "../../../../../../utils"
import { CostEstimateMetaData } from "../../../input/attachment"
import { Message, useChatMessagesReadonly, useCondensedChatInfos } from "../../../../../../data/hooks"
import { CostEstimateCancelTypeTextMap, MetaDataType } from "../../../../../../data"
import { openWorkTask } from "../../../../../../bundle-channel"

type Props = {
    metadata: CostEstimateMetaData
    message: Message
}

const FlexBox = styled("div")({
    display: "flex",
})

const ContentWrapper = styled("div")(({ theme }) => ({
    flexWrap: "wrap",
    "> *": {
        margin: theme.spacing(0.5),
    },
}))

export default function CostEstimate({ metadata, message }: Props) {
    const infos = useCondensedChatInfos(message.chatId)
    const { languageId } = useLocalization()

    const vehicle = useMemo(
        () => infos?.vehicles.find((v) => v.vehicleInfo.id === metadata.externalVehicleId),
        [infos?.vehicles, metadata.externalVehicleId]
    )

    let button
    if (vehicle && !metadata.externalOffer) {
        const handleClick = () => {
            const url = renderRoute(getBundleParams().costEstimationDetailsRouteWithoutWorkTaskId, { id: metadata.offerId })
            openWorkTask({ selectedChatVehicle: vehicle, subRoute: url })
        }

        button = (
            <FlexBox>
                <AttachmentButton fileType="Pdf" onClick={handleClick} iconName="voucher-kva" fileName={metadata.description || metadata.name} />
            </FlexBox>
        )
    } else if (message.attachment?.uri) {
        button = (
            <FlexBox>
                <AttachmentButton
                    fileType="Pdf"
                    href={message.attachment.uri}
                    iconName={languageId === "1" ? "voucher-kva" : "voucher-kva-international"}
                    fileName={metadata.description || metadata.name}
                />
            </FlexBox>
        )
    }

    return (
        <ContentWrapper>
            {button}
            <CostEstimateState message={message} metadata={metadata} />
        </ContentWrapper>
    )
}

export function CostEstimateState(props: Props) {
    const { translateText } = useLocalization()
    const response = useCostEstimateResponse(props.message.chatId, props.metadata.offerId)
    if (response) {
        if (response.metadata.action === "customerConfirmation") {
            return <StatePill text={translateText(3042)} state="accept" />
        }

        if (response.metadata.canceledType && response.message.text) {
            return (
                <StatePill
                    text={`${translateText(3043)}. ${translateText(CostEstimateCancelTypeTextMap[response.metadata.canceledType])}`}
                    state="rejected"
                />
            )
        }
        if (response.message.text) {
            return <StatePill text={`${translateText(3043)}. ${response.message.text}`} state="rejected" />
        }

        if (response.metadata.canceledType) {
            return (
                <StatePill
                    text={`${translateText(3043)}. ${translateText(CostEstimateCancelTypeTextMap[response.metadata.canceledType])}`}
                    state="rejected"
                />
            )
        }
    }
    return null
}

function useCostEstimateResponse(chatId: string, id: string) {
    const allMessages = useChatMessagesReadonly(chatId)
    return useMemo(() => {
        if (!allMessages) {
            return
        }

        let result
        for (let i = 0; i < allMessages.length; i++) {
            const message = allMessages[i]
            const metadata: MetaDataType | undefined = message.appMetaData && JSON.parse(message.appMetaData)
            if (metadata?.type === "costEstimateResponse" && metadata.offerId === id) {
                result = { message, metadata }
            }
        }
        return result
    }, [allMessages, id])
}
