import { HasRepairTimesRequest, HasRepairTimesResponse, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

export function useHasRepairTimes(request?: HasRepairTimesRequest) {
    const { data, isLoading } = useQuery(
        ["repair-times__has-repair-times", request],
        () => {
            if (request) {
                return Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes).subscribe(request).load()
            }
        },
        {
            enabled: !!request,
            staleTime: 30 * 60 * 1000, // 30 minutes
        }
    )

    let availableProviders
    if (data && request) {
        availableProviders = data.productGroups[request.productGroupIds[0]]
    }
    return { availableProviders, isLoading }
}
