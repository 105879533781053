import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleDatExternalTool(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { datExternalTool } = data

    if (datExternalTool?.otherInformation) {
        channel("GLOBAL").publish("DMG_DAT/EXTERNAL_TOOL_DONE", datExternalTool?.otherInformation)
    }
}
