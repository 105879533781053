import { Box, CellContentPosition, Icon, Table, TableCellData, TableColumnData, TableRowData } from "@tm/components"
import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import Accordion from "../../_shared/Accordion"
import { RequiredPart } from "../../../data/models"
import { useDrivemotiveStore } from "../../../data/state"
import NoResultHint from "../../_shared/NoResult"

type Props = {
    articles: RequiredPart[]
}

export default function SpareParts(props: Props) {
    const { translateText, currency } = useLocalization()
    const { userContext } = useUser()
    const currencyString = getCurrencyFromUserContext(userContext)
    const { articles } = props

    if (!articles) {
        return <NoResultHint />
    }

    const [isExpanded, setIsExpanded] = useState(articles?.length > 0)

    const handleClick = () => {
        setIsExpanded((prevState) => !prevState)
    }
    const tableColumns: TableColumnData[] = [
        { alignContent: CellContentPosition.left },
        {},
        { header: translateText(13699).toUpperCase() },
        { header: translateText(89).toUpperCase(), alignContent: CellContentPosition.right },
        { header: translateText(495).toUpperCase(), alignContent: CellContentPosition.right },
        { header: translateText(494).toUpperCase(), alignContent: CellContentPosition.right },
    ]

    const getCells = (part: RequiredPart) => {
        const name = (
            <>
                <Box sx={{ fontWeight: "bold" }}>{part.partName}</Box>
                {part.alternatives?.map((a, index) => {
                    if (part.alternatives?.length && index < part.alternatives?.length - 1) {
                        return (
                            <Box key={index}>
                                {translateText(1098)} <br />
                            </Box>
                        )
                    }
                })}
            </>
        )

        const description = part.alternatives?.map((alternative, index) => {
            return (
                <Box key={index}>
                    {alternative.description}
                    <br />
                </Box>
            )
        })

        const partNo = part.alternatives?.map((alternative, index) => {
            return (
                <Box key={index}>
                    {alternative.parts?.[0].partNo}
                    <br />
                </Box>
            )
        })

        const quantity = part.alternatives?.map((alternative, index) => {
            return (
                <Box key={index}>
                    {alternative.parts?.[0].amounts?.[0].amount}{" "}
                    {alternative.parts?.[0].amounts?.[0].unit ? alternative.parts?.[0].amounts?.[0].unit : "x"}
                    <br />
                </Box>
            )
        })

        const partPrice = part.alternatives?.map((alternative, index) => {
            return (
                <Box key={index}>
                    {alternative.parts?.[0].partPrice && currency(alternative.parts?.[0].partPrice, currencyString)}
                    <br />
                </Box>
            )
        })

        const cellData: TableCellData[] = [
            { displayValue: name, id: "partName" },
            { displayValue: description, id: "description" },
            { displayValue: partNo, id: "type" },
            { displayValue: quantity, id: "quantity" },
            { displayValue: partPrice, id: "partPriceNET" },
            { displayValue: partPrice, id: "partPriceGROSS" },
        ]

        return cellData
    }

    const displayData: TableRowData[] = articles.map((part: RequiredPart) => ({
        cells: getCells(part),
        id: `${part.partName}`,
        rowIndicatorWidth: 7,
    }))

    return (
        <Box sx={{ marginTop: "1em", display: "flex", flexDirection: "column", flex: "1", gap: "0.5em", backgroundColor: "#f5f5f5" }}>
            <Accordion icon={<Icon name="down" />} expandIcon={<Icon name="up" />} expanded={isExpanded} onClick={handleClick}>
                <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    {translateText(1667)}
                    {/* Will be needed later */}
                    {/* <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                        {translateText(1720)}
                        <Box sx={{ fontWeight: "bold", marginLeft: "1em" }}>{currency(totalPriceArticles.value, currencyString)}</Box>
                    </Box> */}
                </Box>
                {articles.length > 0 && (
                    <Table
                        columns={tableColumns}
                        rows={displayData}
                        headerBackground="transparent"
                        fontSize={14}
                        headerStyle="default"
                        rowCap={0}
                        overflowY="auto"
                        rowStyle="uniform"
                    />
                )}
            </Accordion>
        </Box>
    )
}
