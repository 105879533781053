import { useLocalization } from "@tm/localization"
import { useQuery } from "react-query"
import { IndustrialInformationRequest } from "../model"
import { getIndustrialInformation } from "../repositories"

export function useIndustrialInformation(request: IndustrialInformationRequest | undefined) {
    const { languageId } = useLocalization()
    const { data, isLoading, isIdle } = useQuery(
        ["parts__industrial-information", request, languageId],
        () => request && getIndustrialInformation(request),
        {
            enabled: !!request,
            staleTime: 30 * 60 * 1000, // 30 minutes
        }
    )

    return { data: data?.information, isLoading, isIdle }
}
