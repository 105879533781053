import { AsyncAction, PayloadAction } from "@tm/morpheus"
import { Customer } from "@tm/models"
import { BundleActionType } from "../../../business"

export type CustomerDetailsState = Record<string, never>

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: CustomerDetailsState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): CustomerDetailsState {
    switch (action.type) {
        default:
            break
    }

    return state
}

export function transmit(action: PayloadAction<ComponentActionType>) {
    switch (action.type) {
        case "VIEW":
        case "CUSTOMER_DETAILS": {
            return action
        }
        default:
            break
    }
}

function start(customerId?: string): AsyncAction<ComponentActionType, CustomerDetailsState> {
    return (dispatch) => {
        dispatch({ type: "VIEW", payload: { view: "DETAILS", id: customerId } })
    }
}

function loaded(customer: Customer): AsyncAction<ComponentActionType, CustomerDetailsState> {
    return (dispatch) => {
        dispatch({ type: "CUSTOMER_DETAILS", payload: customer })
    }
}

export type IActions = typeof Actions

export const Actions = {
    start,
    loaded,
}
