import { useWorkTask } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { BikeModel, CarModel, TruckModel, VehicleType } from "@tm/models"
import { parseQueryString, renderRoute, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { useEffect, Suspense } from "react"
import { Redirect } from "react-router-dom"
import { Box } from "@tm/components"

import Morpheus from "@tm/morpheus"
import ModelListComponent from "../_shared/ModelList"
import { NoDataWarningComponent } from "../_shared/no-data-warning"
import { bundleChannel, createVehicleFromModel, deleteImportedVehicleDetails } from "../../business"
import { useFilters, useModelsByModelSeries, useSelectedVehicleLookup, useSetAvailableFilters } from "../../data/hooks"
import {
    getValidatedVehicleIdentificationParams,
    getVehicleDetailsUrl,
    getVehicleIdentificationUrl,
    getVehicleIdentificationUrlWithLookup,
} from "../../helpers/routing"

type Props = RouteComponentProps<RouteParams>

type RouteParams = {
    vehicleType: string
    manufacturerId: string
    mainModelSeriesId: string
    modelSeriesId: string
    modelId?: string
}

function Wrapper(props: Props) {
    const { vehicleType, manufacturerId, modelSeriesId, modelId } = getValidatedVehicleIdentificationParams(props.match.params)

    if (!vehicleType) {
        return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType: VehicleType.PassengerCar })} />
    }

    if (!manufacturerId) {
        return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, manufacturerId: undefined })} />
    }

    if (!modelSeriesId) {
        return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, modelSeriesId: undefined })} />
    }

    return (
        <Box m={2} className="tk-vehicle vehicle-models">
            <Suspense fallback={<Loader />}>
                <ModelsComponent {...props} vehicleType={vehicleType} modelSeriesId={modelSeriesId} modelId={modelId} />
            </Suspense>
        </Box>
    )
}

function ModelsComponent(props: Props & { vehicleType: VehicleType; modelSeriesId: number; modelId: number | undefined }) {
    const { vehicleType, modelSeriesId, modelId } = props
    const { attachToWorkTask } = useWorkTask() ?? {}
    const [filters, setFilters] = useFilters(vehicleType)
    const setAvailableFilters = useSetAvailableFilters(vehicleType)
    const {
        selectedVehicleLookup: { lookupTypeId, countryCode },
    } = useSelectedVehicleLookup(vehicleType)
    const response = useModelsByModelSeries({
        vehicleType,
        modelSeriesId,
        selectedFilters: {
            bodyTypeId: filters.bodyTypeId,
            modelYear: filters.modelYear,
            fuelType: filters.fuelType,
            engineCode: filters.engineCode,
            engineCapacity: filters.engineCapacity,
            // sorting: filters.sorting, // Only pass the required filters to prevent unnecessary service call (due to recoil selector params changing)
        },
    })

    useEffect(() => {
        setAvailableFilters({
            bodyTypes: response?.filters?.bodyTypes ?? [],
            modelYears: response?.filters?.modelYears ?? [],
            fuelTypes: response?.filters?.fuelTypes ?? [],
            engineCodes: response?.filters?.engineCodes ?? [],
            engineCapacities: response?.filters?.engineCapacities ?? [],
            sorting: false,
        })
    }, [response, setAvailableFilters])

    const handleApply = (model: CarModel | BikeModel | TruckModel, plateId?: string, vin?: string, initialRegistration?: Date, mileAge?: number) => {
        createVehicleFromModel({
            model,
            engineCode: filters.engineCode,
            plateId,
            vin,
            initialRegistration,
            mileAge,
            vehicleType,
            countryCode,
            registrationNoTypeId: lookupTypeId,
        }).then((vehicle) => {
            TmaHelper.VehicleSelection.List.Graphical(model.dataSourceId)
            attachToWorkTask?.({ vehicle })
            deleteImportedVehicleDetails()

            const redirect = parseQueryString(location.search)?.redirect?.toString()
            if (redirect) {
                props.history.push(renderRoute(redirect, props.match.params))
            } else if (decodeURIComponent(location.search).substr(1, 3) === "(1)") {
                // When we are in a modal, just close the modal
                Morpheus.closeView("1")
            } else {
                props.history.push(renderRoute("/:workTaskId", props.match.params))
            }
        })
    }

    useEffect(() => {
        const unsubs: Array<Function> = []

        unsubs.push(
            bundleChannel().subscribe("APPLY_BUTTON_CLICKED", () => {
                if (modelId) {
                    const model = response?.models.find((x) => x.id === modelId)

                    if (model) {
                        handleApply(model)
                    }
                }
            })
        )

        if (modelId && response?.models.some((x) => x.id === modelId)) {
            bundleChannel().publish("SET_APPLY_BUTTON_STATUS", { disabled: false })
        }

        return () => {
            unsubs.forEach((x) => x())
            bundleChannel().publish("SET_APPLY_BUTTON_STATUS", { disabled: true })
        }
    }, [modelId, response?.models])

    const handleSelect = (model: CarModel) => {
        props.history.replace(getVehicleIdentificationUrlWithLookup(props.match.params, { vehicleType, modelId: model.id }))
    }

    const handleDetailsClick = (model: CarModel) => {
        props.history.push(
            getVehicleDetailsUrl(props.match.params, {
                vehicleType,
                vehicleId: model.id,
            })
        )
    }

    if (!response) {
        return <NoDataWarningComponent />
    }

    /** @todo Figure out how to automatically redirect but keep the ability to have the user click back */
    // if (response?.models.length === 1 && modelId != response.models[0].id) {
    //     return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, modelId: response.models[0].id })} />
    // }

    return (
        <ModelListComponent
            models={response.models}
            selectedModel={response.models.find((x) => x.id === modelId)}
            engineCodeFilter={filters.engineCode}
            onSelect={handleSelect}
            onApply={handleApply}
            onDetailsClick={handleDetailsClick}
            onSelectEngineCode={(engineCode) => setFilters((current) => ({ ...current, engineCode }))}
            vehicleType={vehicleType}
            showManufacturerThumbnail={false}
            showModelThumbnail={vehicleType === VehicleType.Motorcycle}
        />
    )
}

export default withRouter(Wrapper)
