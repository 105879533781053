import { percent } from "csx"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { VehicleImage } from "@tm/components"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Grid, Modal, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { initSelector } from "@tm/utils"
import { Actions } from "../../bussiness"
import { DocumentsState } from "../../bussiness/model"
import { Selections } from "../vehicleInfo"
import VehicleAcceptance from "../vehicleInfo/details"

type Props = {
    onVehicleAcceptance: (value: boolean) => void
}

const selector = initSelector((s: DocumentsState) => s.vehicle)

export default function VehicleAcceptanceModal({ onVehicleAcceptance }: Props) {
    const style = useMemo(() => getStyle(), [])
    const { translateText } = useLocalization()

    const actions = useActions(Actions, "setShowVehicleModal")
    const [vehicle] = useSelector(selector)

    if (!vehicle) {
        return null
    }

    return (
        <Modal onClose={() => actions.setShowVehicleModal(false)} containerClassName="open--foreground">
            <div className={style.modal}>
                <div className={style.header}>
                    <Button size="l" onClick={() => onVehicleAcceptance(true)}>
                        {translateText(585)}
                    </Button>
                    <Button size="l" onClick={() => onVehicleAcceptance(false)}>
                        {translateText(584)}
                    </Button>
                </div>
                <Text modifiers="strong">{translateText(862)}</Text>
                <Text>{translateText(12426)}</Text>
                <div className={style.content}>
                    <Selections />
                    <Grid container className={style.top}>
                        <Grid s={4}>
                            <VehicleImage
                                modelImage={vehicle.modelSeriesImage || ""}
                                modelSeriesImage={vehicle.modelSeriesThumbnail?.replace("kmodthumb", "kmod") || ""}
                                vehicleType={vehicle.vehicleType}
                                className={style.vehicleImage}
                            />
                        </Grid>
                        <Grid s={8}>
                            <VehicleAcceptance />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </Modal>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        modal: {
            position: "relative",
            display: "flex",
            flex: 1,
            flexDirection: "column",
            paddingLeft: theme.margin.m,
            paddingTop: theme.margin.m,
        },
        header: {
            position: "absolute",
            top: 0,
            right: 0,
            margin: theme.margin.m,
            zIndex: 22,
        },
        content: {
            marginTop: theme.margin.l,
        },
        top: {
            marginTop: theme.margin.xl,
        },
        img: {
            width: percent(100),
        },
        vehicleImage: {
            width: "100%",
        },
    })(VehicleAcceptanceModal)
}
