import { Component } from "react"
import { withLocalization, LocalizationProps } from "@tm/localization"
import { bindSpecialReactMethods } from "@tm/utils"
import { Text, Button } from "@tm/controls"
import { EMediaType } from "@tm/models"

export type KeyButtonItemProps = {
    description: string
    value: KeyButtonItemValue | Array<KeyButtonItemValue> | undefined
    bold?: boolean
}

export type KeyButtonItemValue = {
    mediaType: EMediaType
    content: string
    description: string
}

type Props = KeyButtonItemProps & LocalizationProps

class KeyButtonItem extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    renderValue(button: KeyButtonItemValue, idx?: number) {
        let icon: string | undefined

        switch (button.mediaType) {
            case EMediaType.Video:
                icon = "arrow-right"
                break
            case EMediaType.Audio:
                icon = "arrow-right"
                break
            case EMediaType.Pdf:
                icon = "file-pdf"
                break
            case EMediaType.Website:
                icon = "partslink24"
                break
            case EMediaType.YoutubeChannel:
                icon = "partslink24"
                break
            default:
                break
        }

        return (
            <Button key={idx} size="s" icon={icon} linkTo={button.content} target="_blank">
                {button.description}
            </Button>
        )
    }

    render() {
        const { description, value, bold } = this.props

        let content

        if (Array.isArray(value)) {
            content = value.map(this.renderValue)
        } else if (value) {
            content = this.renderValue(value)
        }

        return (
            <>
                <div className="key-value-list__key-cell">
                    <Text modifiers={bold ? "strong" : undefined}>{description}</Text>
                </div>
                <div className="key-value-list__value-cell">{content}</div>
            </>
        )
    }
}

export default withLocalization(KeyButtonItem)
