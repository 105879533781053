import { Genart, RepairTime, SpecificationsGenart } from "../../models"

export function mapCalculationData(data: CalculationData): FSCalculation {
    // const mappedData = data.calculationContext.works.filter(x => x.genArts.some(y => y.article))
    return {
        eTag: data.eTag,
        works: data.calculationContext.works,
    }
}

export type FSCalculation = {
    eTag: string
    works: CalculationContextWorks[]
}

export type CalculationData = {
    calculationContext: CalculationContext
    eTag: string
}

type CalculationContext = {
    type: number
    works: CalculationContextWorks[]
}

export type CalculationContextWorks = {
    consumables: SpecificationsGenart[]
    genArts: CalculationGenart[]
    isSelected: boolean
    label: string
    providerId: string
    repairTimes: RepairTime[]
    location: string
    locationId: string
}

type CalculationGenart = Genart & {
    article?: FSArticle
}

export type FSArticle = {
    attributes: FSArticleAttributes[]
    availIcon: string
    binKrit100: number
    hArtNr: string
    iArtNr: number
    id: string
    img: string
    oeId: string
    oeLabel: string
    oeNrs: string[]
    price: number
    quantity: number
    supplierArtNr: string
    supplierId: number
    supplierName: string
    totalPrice: number
    type: number
    vknAttributes: FSArticleAttributes[]
    vknId: string
    genArtNr: number
    isSelected: true
    label: string
}

type FSArticleAttributes = {
    description: string
    lfdNr: string
    nr: string
    rawValue: string
    shortDescription: string
    unit: string
    value: string
    existsInSlfa?: boolean
}
