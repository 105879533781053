import { useLocalization } from "@tm/localization"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { decodeUniqueId, initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useParams } from "react-router"
import { MainState } from "../main"
import { MainActions, vehicleSelector } from "../main/business"
import { isVehicleChanged } from "./business/helpers"

type Props = {
    workTaskId: string
}

const summarySelector = initSelector((s: MainState) => s.manager.userIsRegistered)

export default function ModuleManager() {
    const { translateText } = useLocalization()
    const vehicle = useSelector(vehicleSelector)
    const [userIsRegistered] = useSelector(summarySelector)
    const actions = useActions(MainActions, "setVehicle", "loadVehicleDetails", "setCustomer", "reset", "resetStore")
    const matchParams = useParams<Props>()

    useEffect(() => {
        const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(matchParams.workTaskId ?? "")
        const unsubCustomer = channel("WORKTASK", workTaskId).subscribe("CUSTOMER/LOADED", actions.setCustomer, true)
        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    actions.setVehicle(newVeh)
                    actions.loadVehicleDetails(newVeh, translateText)
                }
            },
            true
        )

        return () => {
            unsubCustomer?.()
            unsubVehicle?.()
        }
    }, [userIsRegistered])

    useEffect(() => {
        const unsub = channel("WORKTASK", getCurrentWorkTaskId()).subscribe("MODULE/CLOSED", (module) => {
            module.includes("glass") && actions.resetStore()
        })
        return unsub
    }, [])

    return null
}
