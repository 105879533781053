import { Typography } from "@tm/components"

type Props = {
    articleDescription: string
    genericArticle?: string
    supplierName?: string
}

export default function ArticleDescriptionCellComponent({ articleDescription, genericArticle, supplierName }: Props) {
    return (
        <>
            <Typography>{supplierName}</Typography>
            <Typography fontWeight={genericArticle ? undefined : "bold"}>{articleDescription}</Typography>
            <Typography fontWeight="bold">{genericArticle}</Typography>
        </>
    )
}
