import { ArticleInfo, EMediaType } from "@tm/models"
import { Button, ButtonProps } from "@tm/controls"

type ReferenceLinkProps = {
    link: ArticleInfo
    handleOpenInModal?(url: string): void
}

export function ReferenceLink(props: ReferenceLinkProps) {
    const { link, handleOpenInModal } = props

    const match = /^(https?|ftp)\:\/\/.*\/(?:(?:[^\/]+\.([^\/\.]+)$|[^\/]+$))/gi.exec(link.content)
    const protocol = (match?.[1] || "http").toLowerCase()
    const ext = (match?.[2] || "").toLowerCase()

    const buttonProps: ButtonProps = {
        linkTo: link.content,
        target: "_blank",
    }

    function changePropsToOpenModal() {
        if (!handleOpenInModal) {
            return
        }
        buttonProps.onClick = () => handleOpenInModal(link.content)
        delete buttonProps.linkTo
        delete buttonProps.target
    }

    switch (link.mediaType) {
        case EMediaType.Video:
            buttonProps.icon = "arrow-right"
            break
        case EMediaType.Audio:
            buttonProps.icon = "arrow-right"
            break
        case EMediaType.Pdf:
            buttonProps.icon = "file-pdf"
            if (protocol !== "ftp") {
                changePropsToOpenModal()
            }
            break
        case EMediaType.Website:
            buttonProps.icon = "partslink24"
            break
        case EMediaType.YoutubeChannel:
            buttonProps.icon = "partslink24"
            break
        default:
            break
    }

    if (protocol !== "ftp") {
        switch (ext) {
            case "jpg":
            case "jpeg":
            case "png":
            case "gif":
            case "pdf":
                changePropsToOpenModal()
                break
            default:
                break
        }
    }

    return (
        <Button {...buttonProps} size="s">
            {link.description || link.content}
        </Button>
    )
}
