// RFC 5322 - https://tools.ietf.org/html/rfc5322
export const isValid = (email: string) => {
    const reg =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(String(email))
}

export const getError = (value: string) => {
    if (value.length === 0) {
        return undefined
    }

    if (!value || !isValid(value)) {
        return true
    }

    return undefined
}
