import { channel, UserContext } from "@tm/models"
import { clone } from "@tm/utils"
import { ExternalDocument } from "../../../../data/model"

export const getRelatedExternalModules = (userContext?: UserContext, docs?: ExternalDocument[]) => {
    const cloneDocs = clone(docs)
    if (!userContext) {
        ;[userContext] = channel("GLOBAL")
            .last(1, "USER/CONTEXT_LOADED")
            .map((x) => x.content.context)
    }

    if (userContext.externalModules) {
        userContext.externalModules.forEach((item) => {
            if (cloneDocs && item.relatedExternalSystem) {
                const extDoc = cloneDocs.find((doc) => doc.externalSystemId == item.id)

                if (extDoc) {
                    extDoc.relatedExternalSystemId = item.relatedExternalSystem
                }
            }
        })
    }

    return cloneDocs ?? []
}
