import { useLocalization } from "@tm/localization"
import { StatusSwitch } from "@tm/controls"
import { Stack, Typography, Button, Icon, SelectorPaper } from "@tm/components"
import { ConfirmationDialogKeys } from "@tm/models"
import { VisibilityStatus } from "../../../business"
import { BasketPart } from "../../../../../models"
import DeleteButton from "../../../../_shared/DeleteButton"

type Props = {
    selectedParts: BasketPart[]
    allSelected: boolean
    loading: boolean
    onSelectAll(): void
    onUnselectAll(): void
    onRemoveParts(ids: string[]): void
    onIncludeExcludeParts(parts: BasketPart[]): void
}

export default function PartsSelection(props: Props) {
    const { loading, selectedParts, allSelected, onRemoveParts, onIncludeExcludeParts, onSelectAll, onUnselectAll } = props
    const { translateText } = useLocalization()

    function getPartsVisibilityStatus(): VisibilityStatus {
        if (selectedParts.every((part) => part.partItem.costEstimationItem?.isIncluded)) {
            return VisibilityStatus.allVisible
        }
        if (selectedParts.every((part) => !part.partItem.costEstimationItem?.isIncluded)) {
            return VisibilityStatus.noneVisible
        }
        return VisibilityStatus.someVisible
    }

    const items = selectedParts.length
    const articles = selectedParts.map((part) => part.partItem.quantity.value).reduce((sum, current) => sum + current)
    const visibilityStatus = getPartsVisibilityStatus()
    const selectedIds = selectedParts.map((part) => part.partItem.id)

    function renderUpdatePartVisibilityStatus() {
        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (visibilityStatus === VisibilityStatus.allVisible) {
            status = "checked"
        } else if (visibilityStatus === VisibilityStatus.someVisible) {
            status = "checked-not-equal"
        } else if (visibilityStatus === VisibilityStatus.noneVisible) {
            status = "unchecked"
        }

        return <StatusSwitch label={translateText(49)} alignLabel="left" status={status} onChange={() => onIncludeExcludeParts(selectedParts)} />
    }

    return (
        <SelectorPaper>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="label">
                    {translateText(701)}: {items} {translateText(479)}, {articles} {translateText(90)}
                </Typography>
                <Button onClick={() => (allSelected ? onUnselectAll() : onSelectAll())} size="small">
                    {translateText(allSelected ? 703 : 702)}
                </Button>
                {renderUpdatePartVisibilityStatus()}
                <DeleteButton
                    ids={selectedIds}
                    buttonTitleId={624}
                    disableButton={loading}
                    dialogTextId={834}
                    onConfirmRemove={(ids) => ids && onRemoveParts?.(ids)}
                    confirmationKey={ConfirmationDialogKeys.DeleteBasketItem}
                />
                <Button variant="text" startIcon={<Icon name="cancel-x" />} size="large" title={translateText(317)} onClick={() => onUnselectAll()} />
            </Stack>
        </SelectorPaper>
    )
}
