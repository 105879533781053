import { Article, ArticlePrice, ErpInformation, ErpPrice } from "@tm/models"
import { getShortCountryCodeByUserContext } from "@tm/utils"
import { CustomArticlePrice, Part } from "../models"

export function mapIAMPartToArticle(part: Part, erpArticle?: ErpInformation): Article {
    return {
        id: part.article!.id,
        internalId: part.article!.internalId,
        supplierArticleNo: part.article!.supplierArticleNo,
        accessoryDescription: part.article!.accessoryDescription,
        additionalDescription: part.article!.additionalDescription,
        traderArticleNo: part.article!.traderArticleNo,
        additionalReferenceArticleInformation: part.article!.additionalReferenceArticleInformation,
        articleModificationState: part.article!.articleModificationState,
        attributes: part.article!.attributes,
        description: part.article!.description,
        existImage: part.article!.existImage,
        existPdf: part.article!.existPdf,
        fittingSide: part.article!.fittingSide,
        imageCoordinates: part.article!.imageCoordinates,
        information: part.article!.information,
        initialQuantity: part.article!.initialQuantity,
        isAccessory: part.article!.isAccessory,
        isAccessoryListAvailable: part.article!.isAccessoryListAvailable,
        isExactMatch: part.article!.isExactMatch,
        isMandatoryCertificationRequired: part.article!.isMandatoryCertificationRequired,
        isPartsListAvailable: part.article!.isPartsListAvailable,
        isReplacementArticle: part.article!.isReplacementArticle,
        isTraderAddition: part.article!.isTraderAddition,
        matchingArticleNo: part.article!.matchingArticleNo,
        options: part.article!.options,
        priority: part.article!.priority,
        productGroup: part.article!.productGroup,
        quantity: part.article!.quantity,
        references: part.article!.references,
        requestErpInfo: part.article!.requestErpInfo,
        showAddToBasket: part.article!.showAddToBasket,
        showDbPrices: part.article!.showDbPrices,
        status: part.article!.status,
        supplier: part.article!.supplier,
        thumbnail: part.article!.thumbnail,
        traderDescription: part.article!.traderDescription,
        vehicleInformation: part.article!.vehicleInformation,
        vehicleLinkageId: part.article!.vehicleLinkageId,
        documents: part.article!.documents,
        lastUpdate: part.article!.lastUpdate,
        packaging: part.article!.packaging,
        prices:
            erpArticle?.prices?.map((x) =>
                mapErpPriceToArticlePrice(
                    x,
                    part.article!.prices?.find((y) => x.type === y.priceType)
                )
            ) || part.article!.prices,
        vehicleDescriptions: part.article!.vehicleDescriptions,
    }
}

function mapErpPriceToArticlePrice(erpPrice: ErpPrice, articlePrice?: CustomArticlePrice): ArticlePrice {
    return {
        countryCode: getShortCountryCodeByUserContext(),
        currencyCode: erpPrice.currencyCode_Iso_4217,
        deliveryStatus: articlePrice?.deliveryStatus || 0,
        deliveryStatusId: articlePrice?.deliveryStatusId || 0,
        description: erpPrice.description,
        discount: articlePrice?.discount || 0,
        discountGroupId: articlePrice?.discountGroupId || 0,
        isTraderPrice: articlePrice?.isTraderPrice || 0,
        priceType: erpPrice.type,
        priceUnit: erpPrice.priceUnit?.toString() || articlePrice?.priceUnit || "",
        priceUnitId: articlePrice?.priceUnitId || 0,
        quantityUnit: articlePrice?.quantityUnit || "",
        quantityUnitId: articlePrice?.quantityUnitId || 0,
        scaleQuantity: articlePrice?.scaleQuantity || 0,
        validFrom: articlePrice?.validFrom || new Date(),
        value: erpPrice.value || articlePrice?.value || 0,
        currencySymbol: erpPrice.currencySymbol || articlePrice?.currencySymbol,
        traderDescription: articlePrice?.traderDescription,
        traderPriceType: articlePrice?.traderPriceType,
        validTo: articlePrice?.validTo,
    }
}
