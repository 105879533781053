import { useExternalCatalogUrl as useGetExternalCatalogUrl } from "@tm/utils"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"

export function useExternalCatalogUrl(externalSystemId: number | undefined, cachingDuration?: number) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber() ?? undefined

    const { externalCatalogUrl: url, loadingExternalCatalogUrl: isLoading } = useGetExternalCatalogUrl(
        {
            externalSystemId,
            telesalesCustomerNo,
        },
        cachingDuration,
        () => enableServiceCalls
    )

    return {
        url,
        isLoading: isLoading || !enableServiceCalls,
    }
}
