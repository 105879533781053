import { useLocalization } from "@tm/localization"
import { Checkbox, Loader, Stack, TableCellData, TableColumnData, TableVariants, Typography } from "@tm/components"
import { ReactNode } from "react"
import { CalculatedCost, CalculatedCostDetails, CalculatedCostType } from "@tm/models"
import { StyledTable, RowStack, ColumnStack } from "../../_shared/StyledComponents"
import { NoResultHint } from "../../_shared/NoResultHint"

type Props = {
    calculatedCosts: Array<CalculatedCost> | undefined
    variant: TableVariants
    calculatedCostsLoading: boolean
}

export function CostOverviewTable(props: Props) {
    const { calculatedCosts, variant, calculatedCostsLoading } = props
    const { translateText, date } = useLocalization()

    function getCostTypeText(type: CalculatedCostType) {
        switch (type) {
            case CalculatedCostType.DatEre:
                return translateText(13541)
            case CalculatedCostType.DatFiOnline:
                return translateText(13552)
            case CalculatedCostType.DatGlas:
                return translateText(13540)
            case CalculatedCostType.DatPackage:
                return translateText(13539)
            case CalculatedCostType.Vin:
                return translateText(13538)
            case CalculatedCostType.Unknown:
                return translateText(171)
            default:
                return translateText(171)
        }
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(98) },
            { header: translateText(13526) },
            { header: translateText(13560) },
            { header: translateText(43) },
        ]
        return columns
    }

    function getCells(cost: CalculatedCost) {
        const cellData: TableCellData[] = [
            { displayValue: <Typography>{date(cost.creationDate, "g")}</Typography>, id: "0" },
            { displayValue: <Typography>{getCostTypeText(cost.calculatedCostType)}</Typography>, id: "1" },
            { displayValue: <Checkbox checked={cost.hasCausedCosts} />, id: "2" },
            { displayValue: renderDetails(cost.details, cost.calculatedCostType), id: "3" },
        ]
        return cellData
    }

    function renderCalculatedCostVinDetails(details: CalculatedCostDetails) {
        const content = (
            <ColumnStack>
                <Typography variant="body3">{translateText(13556)}</Typography>
                <Typography variant="body2">{details.vin}</Typography>
            </ColumnStack>
        )
        return content
    }

    function renderCalculatedCostEurotaxDetails(details: CalculatedCostDetails) {
        return (
            <Stack direction="row" spacing={3}>
                <ColumnStack>
                    <Typography variant="body3">{translateText(99)}</Typography>
                    <RowStack>
                        <Typography variant="body2">{details.plateId}</Typography>
                        <Typography variant="body2">
                            {translateText(13557)} {details.vin}
                        </Typography>
                    </RowStack>
                </ColumnStack>
                <ColumnStack>
                    <Typography variant="body3">{translateText(29)}</Typography>
                    <Typography variant="body2">{details.historyId}</Typography>
                </ColumnStack>
            </Stack>
        )
    }

    function renderDetails(details: CalculatedCostDetails | undefined, type: CalculatedCostType): ReactNode {
        if (details === undefined) {
            return <></>
        }
        switch (type) {
            case CalculatedCostType.Vin:
                return renderCalculatedCostVinDetails(details)
            case CalculatedCostType.DatEre:
                return renderCalculatedCostEurotaxDetails(details)
            default:
                return <></>
        }
    }

    const displayData = calculatedCosts?.map((cost, index) => ({
        cells: getCells(cost),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (calculatedCostsLoading) {
        return <Loader />
    }

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return (
        <StyledTable
            columns={getColumns()}
            rows={displayData}
            variant={variant}
            headerStyle={variant === "small" ? "bold" : "default"}
            headerBackground={variant === "small" ? "default" : "paper"}
        />
    )
}
