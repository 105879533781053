
export function getMatchParamFromActiveStep(activeStep: number) {
	switch (activeStep) {
		case 0:
			return "vehicle-selection"
		case 1:
			return "wheels-list"
		case 2:
			return "wheels-details"
		case 3:
			return "rdks-list"
		case 4:
			return "details"
		case 5:
			return "tyres-list"
		case 6:
			return "details"
		case 7:
			return "overview"
		default:
			return undefined
	}
}

export function getActiveStepFromMatchParam(selectedValue: string) {
	switch (selectedValue) {
		case "vehicle-selection":
			return 0
		case "wheels-list":
			return 1
		case "wheels-details":
			return 2
		case "rdks-list":
			return 3
		case "details":
			return 4
		case "tyres-list":
			return 5
		case "details":
			return 6
		case "overview":
			return 7
		default:
			return -1
	}
}
