import { useState, useRef } from "react"
import { Button } from "@tm/controls"
import { Tooltip } from "@tm/components"
import { ConstructionYearFilter } from "../../../data/model"

type Props = {
    items: Array<ConstructionYearFilter>
    activeItemId?: number
    onChange(id: number): void
}

export default function ConstructionYearFilterComponent(props: Props) {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const activeItem = props.activeItemId && props.items.find((x) => x.id === props.activeItemId)

    function handleChange(id: number) {
        props.onChange(id)
        hideTooltip()
    }

    function showTooltip() {
        setIsOpen(true)
    }

    function hideTooltip() {
        setIsOpen(false)
    }

    return (
        <div className="selection-list selection-list--s">
            <Tooltip
                open={isOpen}
                title={
                    <div>
                        {props.items.map((item) => {
                            return (
                                <div className="input__list-item filter--construction-year__item" key={item.id} onClick={() => handleChange(item.id)}>
                                    {item.name}
                                </div>
                            )
                        })}
                    </div>
                }
                onMouseEnter={showTooltip}
                onMouseLeave={hideTooltip}
                color="primary"
                variant="light"
                placement="top"
            >
                <Button className="filter--construction-year__button" icon={isOpen ? "up" : "down"}>
                    {activeItem && activeItem.name}
                </Button>
            </Tooltip>
        </div>
    )
}
