import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { Widget } from "@tm/controls"
import { useHistory, useParams } from "react-router"
import { getBundleParams } from "../../utils"

export default function WidgetComponent() {
    const { translateText } = useLocalization()
    const matchParams = useParams<{ workTaskId?: string }>()
    const history = useHistory()
    const { compilationsRoute } = getBundleParams()

    const handleWidgetClick = () => {
        if (matchParams.workTaskId) {
            const url = renderRoute(compilationsRoute, matchParams)
            history.push(url)
        }
    }

    return (
        <Widget
            iconName={getBundleParams().compilationsIcon === "wm" ? "wishlist" : "vote-star"}
            size="1x1"
            title={translateText(1240)}
            onClick={handleWidgetClick}
        />
    )
}
