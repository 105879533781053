import { useMemo } from "react"
import { VehicleImage } from "@tm/components"
import { getStyleTheme, useCountryCodeToLicensePlate, useStyle, useWorkTask } from "@tm/context-distribution"
import { Headline, LicensePlateField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { useFastServiceStore } from "../../data"
import { removeBrackets } from "../../helpers"

type Props = {
    showServiceText?: boolean
    headerText: string
    headerCustomer?: string
}

export default function VehicleAndCustomer({ showServiceText, headerText, headerCustomer }: Props) {
    const { vehicle, customer } = useWorkTask()?.workTask || {}
    const selectedService = useFastServiceStore((state) => state.maintenancePlan.selectedService)
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)

    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    if (!vehicle) {
        return null
    }

    const renderInterval = () => {
        if (!selectedService?.length) {
            return null
        }

        return (
            <div className={style.vehicleInfoText}>
                {selectedService.map((x, i) => (
                    <Text size="m" key={i} modifiers={["block"]}>
                        {x.label}
                    </Text>
                ))}
            </div>
        )
    }

    const renderSubTitle = () => {
        const texts = translateText(12971).split("{0}")
        return (
            <div className={style.paddingTop}>
                <Text size="m">{texts[0]}</Text>
                <Text modifiers="strong" size="m">
                    {headerCustomer}
                </Text>
                <Text size="m">{texts[1]}</Text>
            </div>
        )
    }

    const shortenedModelThumbnail = vehicle.modelThumbnail?.replace(/https?:/, "") || ""
    const shortenedModelSeriesThumbnail = vehicle.modelSeriesThumbnail?.replace(/https?:/, "") || ""

    const vehicleText = `${vehicle.manufacturer || ""} ${removeBrackets(vehicle.modelSeries || "")} ${removeBrackets(vehicle.model || "")}`
    const customerText = (customer && `${customer?.firstName || ""} ${customer?.lastName || ""}`) ?? translateText(12822)

    return (
        <>
            <Headline size="xs">{headerText}</Headline>
            {headerCustomer && renderSubTitle()}
            <div className={style.summary}>
                {!headerText && <div className={style.for}>{translateText(1481)}</div>}
                <div className={style.vehicleInfo}>
                    <div className={style.flexBasis}>
                        <VehicleImage
                            modelImage={shortenedModelThumbnail}
                            modelSeriesImage={shortenedModelSeriesThumbnail}
                            vehicleType={vehicle.vehicleType}
                            className={style.carImage}
                        />
                    </div>
                    <div className={style.details}>
                        <Text size="m">{customerText}</Text>
                        <div className={style.vehicleText}>
                            <Text size="m">{vehicleText}</Text>
                            <LicensePlateField
                                className={style.licensePlate}
                                readonly
                                size="m"
                                shortCountryCode={plateCode}
                                value={vehicle.plateId}
                            />
                        </div>
                    </div>
                </div>
                {showServiceText && (
                    <div className={style.serviceInfo}>
                        <Text size="m" modifiers="sub" className={classes(style.vehicleText, style.flexBasis)}>
                            {translateText(1204)}
                        </Text>
                        {renderInterval()}
                    </div>
                )}
            </div>
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        summary: {
            display: "flex",
            marginTop: "1.5em",
            flexDirection: "column",
        },
        for: {
            textTransform: "capitalize",
            opacity: theme.opacity.secondary,
        },
        textRight: {
            textAlign: "right",
        },
        details: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
        },
        carImage: {
            height: "3em",
        },
        vehicleText: {
            display: "flex",
            alignItems: "center",
        },
        licensePlate: {
            marginLeft: theme.margin.xl,
            $nest: {
                ".input__inner": {
                    padding: ".05rem",
                    fontSize: "1.1em",
                },
            },
        },
        vehicle: {
            flex: 0,
            marginTop: theme.margin.l,
            marginBottom: theme.margin.l,
        },
        service: {
            marginTop: "0.5em",
        },
        paddingTop: {
            paddingTop: theme.margin.xl,
        },
        vehicleInfoText: {
            display: "flex",
            flexDirection: "column",
            flex: 0.75,
            fontWeight: 600,
        },
        flexBasis: {
            flexBasis: "8.5em",
            justifyContent: "flex-end",
        },
        vehicleInfo: {
            display: "flex",
            gap: "1em",
        },
        serviceInfo: {
            display: "flex",
            gap: "1em",
            alignItems: "baseline",
        },
    })(VehicleAndCustomer)
}
