import { Box, styled } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { FC, memo, useState } from "react"
import CustomDetailsModal from "./customDetailsModal"

const PupUpButton: FC = memo(() => {
    const { translateText } = useLocalization()
    const { customer } = useWorkTask()?.workTask || {}

    const customerText = (customer && `${customer?.firstName || ""} ${customer?.lastName || ""}`) ?? translateText(12822)

    const [showModal, setShowModal] = useState(false)

    const onChangeOpen = () => {
        setShowModal(!showModal)
    }

    const handleCloseModal = () => {
        setShowModal(false)
    }

    return (
        <Box style={{ position: "relative", overflow: "hidden", paddingRight: ".5em" }}>
            <StyledButton onClick={onChangeOpen} layout={["holo"]} icon="service-information">
                {customerText}
            </StyledButton>
            <CustomDetailsModal open={showModal} onOutsideClick={handleCloseModal} />
        </Box>
    )
})

export default PupUpButton

const StyledButton = styled(Button)({
    height: "100%",
    marginRight: ".5em",
    maxWidth: "100%",
    "& .btn__content": {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
})
