import { uniqueId } from "@tm/utils"
import { RegisteredModels, ShowCarModelDetailsRequest, ShowCarModelDetailsResponse, Vehicle, VehicleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { VehicleInfo } from "../../../../../../../data"

export function setVehicleInfoData(vehicle: Vehicle, vehicleInfo: VehicleInfo, customerId: string): Vehicle {
    return {
        ...vehicle,
        plateId: vehicleInfo.plateId,
        vin: vehicleInfo.vin,
        initialRegistration: vehicleInfo.initialRegistration ? new Date(vehicleInfo.initialRegistration) : undefined,
        mileAge: vehicleInfo.mileAge ? parseInt(vehicleInfo.mileAge) : undefined,
        mileType: vehicleInfo.mileType,
        engineCode: vehicleInfo.engineCode,
        telematicsProviderId: vehicleInfo.telematicProviderId || undefined,
        telematicsRefId: vehicleInfo.telematicDeviceId || undefined,
        customerId,
    }
}

export async function createNewVehicle(vehicleInfo: VehicleInfo, customerId: string) {
    const vehicleType = VehicleType.PassengerCar // Todo?
    const request: ShowCarModelDetailsRequest = { modelId: vehicleInfo.tecDocTypeId }

    const { modelDetails, modelSeries, manufacturer } = await Container.getInstance<ShowCarModelDetailsResponse>(
        RegisteredModels.Vehicle_ModelDetails
    )
        .subscribe(request, vehicleType)
        .load()

    if (!modelDetails) {
        throw new Error(`No modelDetails found for modelId ${vehicleInfo.tecDocTypeId}`)
    }

    return setVehicleInfoData(
        {
            id: uniqueId(),
            model: modelDetails.description,
            tecDocTypeId: modelDetails.id,
            modelSeries: modelSeries?.description ?? "",
            tecDocModelId: modelSeries?.id ?? modelDetails.modelSeriesId,
            manufacturer: manufacturer?.description ?? "",
            tecDocManufacturerId: manufacturer?.id ?? modelDetails.manufacturerId,
            vehicleType,
            manufacturerThumbnail: manufacturer?.thumbnail ?? modelDetails.manufacturerThumbnail,
            modelSeriesThumbnail: modelSeries?.modelSeriesThumbnail ?? modelDetails.modelSeriesThumbnail,
            modelThumbnail: modelDetails.thumbnail,
        },
        vehicleInfo,
        customerId
    )
}
