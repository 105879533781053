import { matchPath, useHistory, useParams } from "react-router-dom"
import { renderRoute, encodeUniqueId, uniqueId, TmaHelper } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { ECounterType } from "@tm/models"
import { GlobalNavigationItem } from "@tm/components"
import { useCallback, useMemo } from "react"
import { getBundleParams, isWM } from "../../utils"

export default function HeaderButton() {
    const { translateText } = useLocalization()
    const matchParams = useParams()
    const { headerButton, compilationsRoute } = getBundleParams()
    const workTaskId = useWorkTask()?.workTaskId
    const history = useHistory()
    const url = useMemo(
        () =>
            renderRoute(compilationsRoute, {
                ...matchParams,
                workTaskId: encodeUniqueId(workTaskId || uniqueId()),
            }),
        [compilationsRoute, workTaskId, matchParams]
    )
    const isActive = useMemo(
        () => !!matchPath(history.location.pathname + history.location.search, url),
        [history.location.pathname, history.location.search, url]
    )

    const handleClick = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.MyAssortment)
        history.push(url)
    }, [url, history])

    if (!headerButton) {
        return null
    }

    return (
        <GlobalNavigationItem
            name={isWM() ? "own-lists" : "vote-star"}
            componentType="icon"
            active={isActive}
            onIconClick={handleClick}
            tooltip={translateText(1240)}
        />
    )
}
