import { BundleActionTypes } from "../business"
import { DEFAULT_STATE, ExternState } from "../components/extern/business"
import { MainActionsType } from "../components/main/business"
import { ExternalToolSteps, getExternalToolStepByStatus } from "../data/enums"
import { getExternalPage } from "../data/helpers"

export type ExternActionTypes =
    | BundleActionTypes
    | { type: "DAT_EXTERNAL_TOOL_DONE" }
    | { type: "LOAD_NEW_DOCUMENT"; payload: { externalData: string; contractId: string; page?: ExternalToolSteps } }
    | { type: "LOAD_NEW_DOCUMENT_ERROR" }
    | { type: "UPDATE_EXTERN_PAGE"; payload: ExternalToolSteps }

export const reduce = (state = { ...DEFAULT_STATE }, action: MainActionsType): ExternState => {
    switch (action.type) {
        case "@@redux/INIT":
        case "RESTART_PROCESS":
        case "DAT_EXTERNAL_TOOL_DONE":
        case "OPEN_HISTORY_CALCULATION": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "INIT_MODULE": {
            const { status } = action.payload
            return {
                ...state,
                ...(status && {
                    page: getExternalToolStepByStatus(status),
                }),
            }
        }
        case "INIT_NEW_CALCULATION": {
            const { sphinxHtml } = action.payload
            return {
                ...state,
                page: ExternalToolSteps.ContractOpening,
                externalContent: getExternalPage(ExternalToolSteps.ContractOpening, sphinxHtml),
            }
        }
        case "LOAD_NEW_DOCUMENT": {
            const { page, externalData } = action.payload
            return {
                ...DEFAULT_STATE,
                templateExternalUrl: externalData,
                externalContent: getExternalPage(page ?? state.page, externalData),
                ...(page && { page }),
            }
        }
        case "LOAD_NEW_DOCUMENT_ERROR": {
            return {
                ...state,
                getDocumentError: true,
            }
        }
        case "UPDATE_EXTERN_PAGE": {
            return {
                ...state,
                page: action.payload,
            }
        }
        default:
            return state
    }
}
