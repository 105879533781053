import { useGlobalOrderOptionsState } from "../../../../hooks/useGlobalOrderOptionsState"
import AdditionalInformationTextFields from "./AdditionalInformationTextFields"

export default function GlobalAdditionalInformationComponent() {
    const { globalOrderOptions, setGlobalOrderOptionsValue } = useGlobalOrderOptionsState()

    function handleCustomOrderNumberChange(value: string) {
        if (globalOrderOptions) {
            setGlobalOrderOptionsValue({ ...globalOrderOptions, customerOrderNumber: value || "" })
        }
    }

    function handleOrderCommentChange(value: string) {
        if (globalOrderOptions) {
            setGlobalOrderOptionsValue({ ...globalOrderOptions, orderComment: value || "" })
        }
    }

    return (
        <AdditionalInformationTextFields
            hasCustomerOrderNumber={globalOrderOptions?.hasCustomerOrderNumber || false}
            hasOrderComment={globalOrderOptions?.hasOrderComment || false}
            customerOrderNumberMaxLength={globalOrderOptions?.customerOrderNumberMaxLength}
            orderCommentMaxLength={globalOrderOptions?.orderCommentMaxLength}
            onChangeComment={handleOrderCommentChange}
            onChangeNumber={handleCustomOrderNumberChange}
            customOrderNumberValue={globalOrderOptions?.customerOrderNumber}
            customerOrderCommentValue={globalOrderOptions?.orderComment}
        />
    )
}
