import { EFilterNames, ETyresCarType, TyreFilter } from "@tm/models"
import { Statics } from "../statics"
import { Filters as SummaryFilters } from "../../components/summary/business"
import { Filters as WidgetFilters } from "../../hooks/widgetFilters/model"

const getTireSizeFromCarType = (carType: ETyresCarType | string, filters: SummaryFilters | WidgetFilters) => {
    switch (+carType) {
        case ETyresCarType.PKW:
            return mapSizeToTireFilter(filters, '205', '16', '55')
        case  ETyresCarType.LLKW:
            return mapSizeToTireFilter(filters, '215', '16', '65')
        case  ETyresCarType.LKW:
            return mapSizeToTireFilter(filters, '315', '22.5', '80')
        case  ETyresCarType.Motorrad:
            return mapSizeToTireFilter(filters, '120', '17', '70')
        case  ETyresCarType.Anhanger:
            return mapSizeToTireFilter(filters, '385', '22.5', '65')
        case  ETyresCarType.Industrie:
            return mapSizeToTireFilter(filters, '18', '8', '7')
        case  ETyresCarType.Agrar:
            return mapSizeToTireFilter(filters, '420', '30', '70')
        case  ETyresCarType.EM:
            return mapSizeToTireFilter(filters, '18', '33')
        default:
            return mapSizeToTireFilter(filters, '205', '16', '55')
    }
}

export type TireFilterSizes = {
    width: TyreFilter
    height?: TyreFilter
    inch: TyreFilter
}

const mapSizeToTireFilter = (filters: SummaryFilters | WidgetFilters, widthValue: string, inchValue: string, heightValue?: string): TireFilterSizes => {
    return {
        width: {
            query: widthValue,
            value: widthValue,
            group: EFilterNames.width,
            groupId: filters.width.first()?.groupId
        },
        inch: {
            query: inchValue,
            value: inchValue,
            group: EFilterNames.inch,
            groupId: filters.inch.first()?.groupId
        },
        ...(heightValue && {
            height: {
                query: heightValue,
                value: heightValue,
                group: EFilterNames.height,
                groupId: filters.height.first()?.groupId
            }
        })
    }
}

export const getAvailableTireSize = (carTypes: TyreFilter[], filters: SummaryFilters | WidgetFilters): TireFilterSizes | undefined => {
    const selectedCartypes = carTypes.map(x => x.bitValue).reduce((acc, curr) => acc! | curr!)
    const firstBit = selectedCartypes! & -selectedCartypes!
    const firstElement = Statics.getCarTypes().find(x => x.bitValue == firstBit)
    return firstElement && getTireSizeFromCarType(firstElement.query, filters) || undefined
}