import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(): NestedCSSProperties {
    const { margin, opacity } = getStyleTheme()

    return {
        opacity: opacity.primary,
        padding: `${margin.s} 0 ${margin.s} ${margin.m}`,
        $nest: {
            "&:before": { display: "none" },
            ".toolbar__title": {
                marginBottom: "0.2em",
            },
            ".toolbar__inner": {
                borderLeft: `1px solid rgba(0,0,0,0.3)`,
                padding: "0 0 0 0.6em",
            },
        },
    }
}
