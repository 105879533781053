import { useShowCisOptions, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { CisVoucherType } from "@tm/models"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { ShowCompletedOrdersRequest } from "../model"
import * as Data from "../repositories/cis/vouchers"

function showClosedOrders(customerNo: string | undefined) {
    const showCompletedOrdersRequest: ShowCompletedOrdersRequest = {
        pageIndex: 1,
        pageSize: 5,
        customerNo,
    }

    return Data.showCompletedOrders(showCompletedOrdersRequest)
}

const KEY = "cis_useClosedOrdersRecent"
const VOUCHER_TYPE = CisVoucherType.ClosedOrders

export function useClosedOrdersRecent() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { cisOptions } = useShowCisOptions()

    const voucherTypeEnabled = useMemo(() => {
        return !!cisOptions?.voucherTypes?.some((type) => type.typeId === VOUCHER_TYPE)
    }, [cisOptions])

    const enabled = voucherTypeEnabled && enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => {
            return showClosedOrders(telesalesCustomerNo)
        },
        {
            enabled,
        }
    )

    return {
        closedOrders: data?.completedOrders,
        closedOrdersLoading: isLoading,
    }
}
