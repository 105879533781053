import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetErpInfos(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { getErpInfos } = data

    if (source && getErpInfos?.items.length) {
        const { workTask, erpSystemConfig, telesalesCustomerNo } = this.props

        Promise.all(
            getErpInfos.items.map((item) =>
                this.Erp.action("getErpInfo")({
                    item: { ...item, vehicleId: workTask?.vehicle?.id },
                    distributorId: erpSystemConfig?.id,
                    telesalesCustomerNo,
                })
            )
        ).then((responses) => {
            if (source) {
                source.postMessage({ getErpInfosResponse: { items: responses } }, "*")
            }
        })
    }
}
