import { State } from "./model"
import { BundleActionType, BundleActions, reduceUniversalTagBaseState } from "../../../business"

export * from "./model"

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: State = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): State {
    return reduceUniversalTagBaseState(state, action)
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
}
