import { Box, Button } from "@tm/components"
import { Popover } from "@tm/controls"
import { OrderVoucherList } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { useState } from "react"
import VehicleInfo from "../VehicleInfo"

export type Props = {
    licensePlateOnTop?: boolean
    order: OrderVoucherList
    shortCountryCode: string
}

export default function OrderVehicleInfo({ order, licensePlateOnTop, shortCountryCode }: Props) {
    const { workTaskInfos, orderVoucherId } = order
    const workTasksWithVehicleInfo = workTaskInfos.filter((wt) => wt.vehicleInfo)
    const worktasks = workTasksWithVehicleInfo.length

    const [extraVehicles, setExtraVehicles] = useState<string>()
    const expandPopover = extraVehicles === orderVoucherId

    if (!worktasks || !workTasksWithVehicleInfo[0].vehicleInfo) {
        return <div />
    }

    function handleExtraVehiclesClick(orderId: string) {
        if (extraVehicles && extraVehicles === orderId) {
            setExtraVehicles(undefined)
        } else {
            setExtraVehicles(orderId)
        }
    }

    const vehicle = workTasksWithVehicleInfo[0].vehicleInfo
    return (
        <Box display="flex" alignItems="center" key={vehicle.id}>
            <VehicleInfo
                shortCountryCode={shortCountryCode}
                vehicleDescription={vehicle.displayName}
                licensePlateOnTop={licensePlateOnTop}
                showVehicleMilage={!!vehicle.mileAge && !!vehicle.mileType}
                vehiclePlateId={vehicle.plateId}
            />
            {worktasks > 1 && (
                <Box>
                    <Button variant="outlined" onClick={() => handleExtraVehiclesClick(order.orderVoucherId)}>
                        + {worktasks - 1}
                    </Button>
                    {workTaskInfos.length > 1 && (
                        <Popover
                            active={expandPopover}
                            alignArrow="center"
                            onElementRef={(ref: HTMLElement) => {
                                ref.style.transform = `translateX(-${ref.getBoundingClientRect().width / 2}px)`
                                ref.style.left = "50%"
                            }}
                        >
                            {workTaskInfos
                                .map((workTaskInfo) => workTaskInfo.vehicleInfo)
                                .filter(notUndefinedOrNull)
                                .slice(1)
                                .map((vehicleInfo) => (
                                    <Box key={vehicleInfo.id}>
                                        <VehicleInfo
                                            shortCountryCode={shortCountryCode}
                                            vehicleDescription={vehicleInfo.displayName}
                                            licensePlateOnTop={licensePlateOnTop}
                                            showVehicleMilage={!!vehicleInfo.mileAge && !!vehicleInfo.mileType}
                                            vehiclePlateId={vehicleInfo.plateId}
                                        />
                                    </Box>
                                ))}
                        </Popover>
                    )}
                </Box>
            )}
        </Box>
    )
}
