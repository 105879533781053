import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenExternalModule(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openExternalModule } = data

    if (openExternalModule && openExternalModule.url) {
        const url = `/external01?url=${encodeURIComponent(openExternalModule.url)}`
        this.props.history.push(url)

        const { info } = openExternalModule
        if (info) {
            channel("GLOBAL").publish("PAGE/OPENED", { ...info, url })
        }
    }
}
