import { ArticleListSortingMode } from "@tm/models"
import { equals } from "@tm/utils"
import { useCallback, useState } from "react"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { mapDistinctValues } from "../helpers"
import { ArticleGroupParams } from "../models"
// import { useReloadErp } from "./useErpInfos"

/** Contains information about the current sorting and some helper methods to change the sorting. */
export type ArticleListSortingMeta = {
    /** The current sortings activated for each product group. */
    productGroups: Record<number, ArticleListSortingMode | undefined>

    /** Clear sortings for all product groups. */
    clear: () => void

    /** Set a sorting for a list of product groups and optionally clear sortings for all other product groups. */
    setForProductGroups: (productGroupIds: number[], sorting: ArticleListSortingMode | undefined, clearOther?: boolean) => void

    /**
     * Activate the default sorting if necessary and enabled.
     * @param ignoreMaximum If true, the default sorting will be activated even when the article count is more than the maximum article count for sorting. But only for groups that are not incomplete.
     */
    activateDefault: (articleCount: number, pageCount: number, articleGroups: ArticleGroupParams[], ignoreMaximum?: boolean) => void
}

/** Can be used to get all the necessary states and actions for the article list sorting functionality. */
export function useArticleListSorting(): ArticleListSortingMeta {
    // const reloadErpData = useReloadErp()

    const { maximumArticleCount, defaultMode } = useArticleListConfiguration().sorting

    const [productGroups, setProductGroupSortings] = useState<ArticleListSortingMeta["productGroups"]>({})

    const clear = useCallback<ArticleListSortingMeta["clear"]>(() => {
        setProductGroupSortings({})
    }, [])

    const setForProductGroups = useCallback<ArticleListSortingMeta["setForProductGroups"]>((productGroupIds, sorting, clearOther) => {
        if (!productGroupIds.length && !clearOther) {
            return
        }

        setProductGroupSortings((prev) => {
            const newValue = clearOther ? {} : { ...prev }

            productGroupIds.forEach((id) => {
                newValue[id] = sorting
            })

            return equals(newValue, prev) ? prev : newValue
        })
    }, [])

    const activateDefault = useCallback<ArticleListSortingMeta["activateDefault"]>(
        (articleCount, pageCount, articleGroups, ignoreMaximum) => {
            // Don't do anything if the default sorting is not set.
            if (!defaultMode) {
                return
            }

            // Don't do anything if there are no articles or more than one page.
            if (articleCount === 0 || pageCount !== 1) {
                return
            }

            // Don't do anything if there are more articles than the maximum article count for sorting except if ignoreMaximum is true.
            if (!ignoreMaximum && articleCount > maximumArticleCount) {
                return
            }

            const groupsForSorting = articleGroups.filter((group) => !group.incomplete)
            if (!groupsForSorting.length) {
                return
            }

            // if (defaultArticleListSorting === "erpSortNumber") {
            //     reloadErpData(groupsForSorting.flatMap((group) => group.articles))
            // }

            setForProductGroups?.(
                mapDistinctValues(groupsForSorting, (group) => group.productGroup.id),
                defaultMode,
                true
            )
        },
        [maximumArticleCount, defaultMode, /* reloadErpData, */ setForProductGroups]
    )

    return {
        productGroups,
        clear,
        setForProductGroups,
        activateDefault,
    }
}
