import { IBundle } from "@tm/morpheus"
import StartWidget from "./components/start-widget"
import CreateEvent from "./components/create-event"
import ToolbarInfo from "./components/toolbar-info"
import AddWork from "./components/add-work"
import { initSchedulesBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [StartWidget, CreateEvent, ToolbarInfo, AddWork],
    init: initSchedulesBundle,
}

export default bundle
