export const VRMAAA_STATE_KEY = "__VRM-AAA-LOOKUP__"
export type VRMAAA_STATE_TYPE = {
    lastUpdated: string // The last lookup date as ISO date string
}

export type AdditionalInfo = {
    type: AdditionalInfoType
    value?: string
    values?: Array<AdditionalInfo>
}

export enum AdditionalInfoType {
    container = 20,
    containerName = 21,
    datECode = 22,
    kbaNumber = 23,
    vehicleTypeName = 27,

    description = 35,

    appraisalGroup = 54,
    equipmentClassification = 55,
    equipmentLabeling = 56,
    equipmentNumber = 57,
    equipmentStandardOrOptional = 58,
    manufacturerCode = 59,
    price = 60,

    code = 78,
    color = 79,
    equipment = 80,
    id = 81,

    // not fully implemented
}
