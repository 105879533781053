import { CarLookupError, TitleType, VrmLookupTextIds } from "@tm/models"
import { ModelState, validateField } from "@tm/utils"
import { ReactText } from "react"
import { PrepareCalculationDatRequest } from "../../../data/repositories/prepareCalculation/model"
import { DatFields, SummaryState } from "./model"

export const validateFields = (fields: DatFields, translateText: (key: ReactText) => string, isGermanOrAustrianVehicle?: boolean): ModelState => {
    return {
        processName: validateField(fields.processName).required(translateText(37)).messages,
        ...(isGermanOrAustrianVehicle && {
            registrationNo: validateField(fields.registrationNo).required(translateText(37)).messages,
        }),
        initialRegistration: validateField(fields.initialRegistration && fields.initialRegistration.toString()).min(8, translateText(289)).messages,
        plateNumber: validateField(fields.plateNumber).required(translateText(37)).messages,
        ...((!fields.vin || fields.vin.length < 17) && {
            vin: ((fields.vin?.length ?? 0) < 17 && [translateText(289).replace("#1#", "17")]) || [translateText(1881)],
        }),
    }
}

export const createDatCalculationRequest = (state: SummaryState): PrepareCalculationDatRequest => {
    const { fields, useCatalogHourlyRates, datVinSearchIsValid, isGermanOrAustrianVehicle } = state

    const titles = {
        [TitleType.Undefined]: undefined,
        [TitleType.Mister]: "titleMr",
        [TitleType.Miss]: "titleMrs",
        [TitleType.Company]: "titleCompany",
    }

    const request: PrepareCalculationDatRequest = {
        vehicleInfo: {
            registrationDate: fields.initialRegistration,
            ...(isGermanOrAustrianVehicle && {
                kbaNo: fields.registrationNo,
                kbaNrs: fields.registrationNos,
            }),
            certificateNumber: fields.registrationNo,
            vin: fields.vin,
            licenseNumber: fields.plateNumber,
            mileAge: fields.mileage,
        },
        customerInfo: {
            title: titles[fields.titleType!],
            firstName: fields.firstName,
            lastName: fields.lastName,
            street: fields.street,
            streetNo: fields.streetNo,
            country: fields.country,
            zip: fields.zip,
            city: fields.city,
            email: fields.email,
            phone: fields.phone,
            companyName: fields.companyName,
        },
        transactionDescription: fields.processName,
        useCatalogHourlyRates: useCatalogHourlyRates || false,
        getDatVehicleData: datVinSearchIsValid,
    }

    return request
}

export const getDATErrorMessageTextId = (error: CarLookupError): VrmLookupTextIds => {
    if (error.errorMessage) {
        if (error.errorMessage.includes("DAT User not registered")) {
            return VrmLookupTextIds.UserNotRegistered
        }
        if (
            error.errorMessage.includes("manufacturer not supported") ||
            /VIN requests for manufacturers.*are not permitted/.test(error.errorMessage)
        ) {
            return VrmLookupTextIds.ManufacturerNotSupported
        }
        if (
            error.errorMessage.includes("Fahrgestellnummer ist unbekannt") ||
            error.errorMessage.includes("no result") ||
            error.errorMessage.includes("unknown vin")
        ) {
            return VrmLookupTextIds.NoResultForVin
        }
        if (error.errorMessage.includes("No module calls left")) {
            return VrmLookupTextIds.ContingentLimitReached
        }
    }

    return VrmLookupTextIds.UnknownError
}
