import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const alldata: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "alldata",
    reduce,
    component,
}

export default alldata
