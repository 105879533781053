import { Typography } from "@tm/components"
import { FastServiceSelection } from "../../../data/models"
import { SelectionKeys } from "../../../data/state"
import Select from "./Select"

type Props = {
    workSelectionItem: FastServiceSelection
    handleSelection: (element: string[], selectionId: string, selectionKey: SelectionKeys) => void
    storeSelections: (id: string, selectionId: string) => void
    selectionsToSend?: { [key: string]: string[] }
    selectionKey: SelectionKeys
}

export default function WorkItem({ workSelectionItem, handleSelection, storeSelections, selectionsToSend, selectionKey }: Props) {
    return (
        <>
            <Typography variant="body1" sx={{ display: "inline-block", marginBottom: "0.5em" }}>
                {workSelectionItem.label}
            </Typography>
            <Select
                selectionKey={selectionKey}
                onSelect={handleSelection}
                items={workSelectionItem.items}
                multiSelect={workSelectionItem.multiSelect}
                selectionId={workSelectionItem.selectionId}
                updateMultiSelections={storeSelections}
                selectedIds={selectionsToSend?.[workSelectionItem.selectionId] || []}
            />
        </>
    )
}
