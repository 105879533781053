import { ajax, getStoredAuthorization } from "@tm/utils"
import { getAddressPickerServiceUrl } from ".."
import { Country, Region, Street, GetStreetsRequest, GetRegionsRequest } from "../models/address-picker"

export function getCountries(): Promise<Array<Country>> {
    const url = `${getAddressPickerServiceUrl()}/Countries`
    const authorization = getStoredAuthorization()

    return new Promise((resolve, reject) => ajax({ url, authorization }).then((response) => resolve(response.countries || []), reject))
}

export function getRegions(request: GetRegionsRequest): Promise<Array<Region>> {
    const url = `${getAddressPickerServiceUrl()}/Regions`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise((resolve, reject) => ajax({ url, authorization, body }).then((response) => resolve(response.regions || []), reject))
}

export function getStreets(request: GetStreetsRequest): Promise<Array<Street>> {
    const url = `${getAddressPickerServiceUrl()}/Streets`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise((resolve, reject) => ajax({ url, authorization, body }).then((response) => resolve(response.streets || []), reject))
}
