import { useWorkTask } from "@tm/context-distribution"
import { Dropdown } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { BikeModel, CarModel, TruckModel, VehicleLookupConfig, VehicleType } from "@tm/models"
import { concat, TmaHelper } from "@tm/utils"
import { Suspense } from "react"

import { createVehicleFromModel, deleteImportedVehicleDetails } from "../../../../business"
import { useModelsByModelSeries } from "../../../../data/hooks"
import { renderDateRange } from "../../../../helpers/construction-year"
import { useSelections } from "../../business/hooks"

type Props = {
    vehicleType: VehicleType
    selectedSearchConfig?: VehicleLookupConfig
}

export default function ModelSelection({ vehicleType, selectedSearchConfig }: Props) {
    const { selectedManufacturer, selectedModelSeries } = useSelections(vehicleType)

    return (
        <div className="row">
            <div className="number">{vehicleType === VehicleType.PassengerCar ? 4 : 3}</div>
            {!!selectedManufacturer && !!selectedModelSeries ? (
                <Suspense fallback={<DisabledDropdown />}>
                    <Content vehicleType={vehicleType} modelSeriesId={selectedModelSeries.id} selectedSearchConfig={selectedSearchConfig} />
                </Suspense>
            ) : (
                <DisabledDropdown />
            )}
        </div>
    )
}

function DisabledDropdown() {
    const { translateText } = useLocalization()
    const view = () => <div>{translateText(297)}</div>
    return <Dropdown items={[]} itemView={view} coverView={view} disabled />
}

function Content({ vehicleType, modelSeriesId, selectedSearchConfig }: Props & { modelSeriesId: number }) {
    const { translateText, date } = useLocalization()
    const { models } = useModelsByModelSeries({ vehicleType, modelSeriesId, selectedFilters: {} }) ?? {}
    const { attachToWorkTask } = useWorkTask() ?? {}
    const { removeState } = useSelections(vehicleType)

    const renderModelItem = (data?: CarModel | BikeModel | TruckModel) => {
        if (!data || !data.description) {
            return <div>{translateText(297)}</div>
        }

        const engine = data.engineCodes?.join(", ") ?? ""
        const powerKw = "enginePowerKw" in data ? `${data.enginePowerKw} kW` : undefined
        const powerHp = "enginePowerHp" in data ? `${data.enginePowerHp} PS` : undefined

        return (
            <div className="item">
                <div className="description">{data.description}</div>
                <div className="engine">{engine}</div>
                <div className="years">{renderDateRange(data, date)}</div>
                <div className="power">{concat(" / ", powerKw, powerHp)}</div>
            </div>
        )
    }

    const handleModelSelect = (model: CarModel | BikeModel | TruckModel) => {
        createVehicleFromModel({ model, vehicleType, countryCode: selectedSearchConfig?.countryCode }).then((vehicle) => {
            TmaHelper.VehicleSelection.List.Graphical(model.dataSourceId)
            removeState()
            attachToWorkTask?.({ vehicle })
            deleteImportedVehicleDetails()
        })
    }

    return (
        <Dropdown
            items={models || []}
            itemView={renderModelItem}
            coverView={() => <div>{translateText(297)}</div>}
            disabled={!models}
            onChange={handleModelSelect}
            amountItemsToShow={10}
            getSearchValue={(item) => item.description}
        />
    )
}
