import { getStoredAuthorization, ajax } from "@tm/utils"
import { PrepareCalculationRequest, PrepareCalculationResponse } from ".."
import { getBundleParams } from "../../../utils"

export * from "./model"

export function prepareCalculation(request: PrepareCalculationRequest) {
    const url = `${getServiceUrl()}/PrepareCalculation`
    const authorization = getStoredAuthorization()

    return new Promise<PrepareCalculationResponse>((resolve, reject) =>
        ajax({ url, body: request, method: "POST", authorization }).then((response: PrepareCalculationResponse) => {
            resolve(response)
        }, reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.glassServiceUrl
}
