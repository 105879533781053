import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit } from "./business"
import component from "./component"

const manager: BundleComponent = {
    name: "search",
    reduce,
    component,
    transmit,
}

export default manager
