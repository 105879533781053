import { useState } from "react"
import { Text, Scrollbar, Headline, Button } from "@tm/controls"
import { DateUnit } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { History } from "history"
import { StartWidgetState } from "../business"
import { Events } from "./events"

type Props = Required<Pick<StartWidgetState, "events" | "types">> & {
    date: Date
    history: History
    centerRoute: string
}

export function DayCalendar(props: Props) {
    const { events } = props
    const [day, setDay] = useState(props.date.startOf(DateUnit.Day))
    const workRange = [7, 19]
    const hours = [...Array(workRange[1] - workRange[0])].map((val: unknown, index: number) => workRange[0] + index)
    const { date } = useLocalization()

    function renderEvents(hour: number) {
        const dayEvents = events
            .filter((x) => x.startDateTime.isSame(day, DateUnit.Day) && x.startDateTime.getHours() === hour)
            .orderBy((x) => x.startDateTime)
        if (!dayEvents.length) {
            return
        }
        return <Events types={props.types} events={dayEvents} type={DateUnit.Day} history={props.history} centerRoute={props.centerRoute} />
    }

    function handleNextClick(direction: number) {
        setDay(day.add(direction, DateUnit.Day))
    }

    return (
        <div className="day-calendar">
            <Headline size="xs">{date(day, "D")}</Headline>
            <Button className="prev" icon="prev" layout={["holo", "circle"]} onClick={handleNextClick.bind(null, -1)} />
            <Button className="next" icon="next" layout={["holo", "circle"]} onClick={handleNextClick.bind(null, 1)} />
            <div className="day-calendar__content">
                <Scrollbar>
                    {hours.map((hour) => (
                        <div className="hour" key={hour}>
                            <Text className="hour__label">{hour}:00</Text>
                            <div className="hour__content">{renderEvents(hour)}</div>
                        </div>
                    ))}
                </Scrollbar>
            </div>
        </div>
    )
}
