/* eslint-disable jsx-a11y/media-has-caption */
import { important } from "csx"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { calculateMediaSizeLimit } from "@tm/utils"

type Props = {
    stream: MediaStream
    onClose(): void
    onImageUpload(image: string): void
}

export default function Overlay(props: Props) {
    const style = useMemo(() => getStyle(), [])
    const [dimensions, setDimensions] = useState<{ width: number; height: number }>()
    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)

    useEffect(() => {
        if (videoRef.current && videoRef.current.srcObject !== props.stream) {
            videoRef.current.srcObject = props.stream
        }

        return () => {
            const tracks = props.stream.getTracks()
            tracks.forEach((track) => {
                track.stop()
            })
        }
    }, [props.stream])

    const handleCanPlay = () => {
        if (videoRef.current) {
            // Restrict both width and height to 1920 here, so the image can be rotated later without losing pixels
            setDimensions(calculateMediaSizeLimit(videoRef.current.videoWidth, videoRef.current.videoHeight, 1920, 1920))
        }
    }

    const handleTakePhoto = useCallback(() => {
        if (videoRef.current && canvasRef.current) {
            const context = canvasRef.current.getContext("2d")

            if (context) {
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
                const data = canvasRef.current.toDataURL("image/png")
                props.onImageUpload(data)
            }
        }
    }, [props.onImageUpload])

    return (
        <div className={style.main}>
            <div className={style.content}>
                <Button layout={["ghost"]} onClick={props.onClose} icon="cancel-x" className={style.closeButton} />
                <video
                    ref={videoRef}
                    onCanPlay={handleCanPlay}
                    height={dimensions?.height}
                    width={dimensions?.width}
                    autoPlay
                    playsInline
                    className={style.video}
                />
                <canvas ref={canvasRef} height={dimensions?.height} width={dimensions?.width} className={style.canvas} />
                <div className={style.buttonWrapper}>
                    <Button layout={["circle"]} onClick={handleTakePhoto} icon="camera" size="xl" className={style.shutterButton} />
                </div>
            </div>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        main: {
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            width: "100%",
            height: "100%",
            zIndex: 1000,
            background: "rgba(0,0,0,.6)",
            pointerEvents: "none",
        },
        content: {
            pointerEvents: "all",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: "4rem",
        },
        canvas: {
            display: "none",
        },
        closeButton: {
            position: "absolute",
            top: theme.margin.m,
            right: theme.margin.m,
            $nest: {
                ".btn__icon": {
                    height: "2rem",
                    width: "2rem",
                    fill: important(theme.colors.light),
                    opacity: 1,
                },
            },
        },
        video: {
            maxWidth: "100%",
            maxHeight: "100%",
            marginBottom: theme.margin.xl,
            objectFit: "cover",
        },
        buttonWrapper: {
            display: "flex",
        },
        shutterButton: {
            $nest: {
                ".btn__icon": {
                    height: "2rem",
                    width: "2rem",
                },
                ".btn__content": {
                    height: "2rem",
                    width: "2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                },
            },
        },
    })(Overlay)
}
