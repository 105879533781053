import { useShowCisOptions, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { CisVoucherType } from "@tm/models"
import { useMemo } from "react"
import { useQuery } from "react-query"
import { ShowOpenOrdersRequest } from "../model"
import * as Data from "../repositories/cis/vouchers"

function showOpenOrders(customerNo: string | undefined) {
    const showOpenOrdersRequest: ShowOpenOrdersRequest = {
        pageIndex: 1,
        pageSize: 5,
        customerNo,
    }

    return Data.showOpenOrders(showOpenOrdersRequest)
}

const KEY = "cis_useOpenOrdersRecent"
const VOUCHER_TYPE = CisVoucherType.OpenOrders

export function useOpenOrdersRecent() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { cisOptions } = useShowCisOptions()

    const voucherTypeEnabled = useMemo(() => {
        return !!cisOptions?.voucherTypes?.some((type) => type.typeId === VOUCHER_TYPE)
    }, [cisOptions])

    const enabled = voucherTypeEnabled && enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => {
            return showOpenOrders(telesalesCustomerNo)
        },
        {
            enabled,
        }
    )

    return {
        openOrders: data?.openOrders,
        openOrdersLoading: isLoading,
    }
}
