import { ArticleIdentifier, IMicros, OE } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { equals } from "@tm/utils"
import { useSelector } from "react-redux"
import { Box } from "@tm/components"
import { ReplaceButton } from "@tm/controls"
import { toolkitPath } from "../../../utils"
import { ExtendedOePart, Part } from "../../../data/models"
import { managerSelector } from "../../main/business"

type Props = {
    alternativePart: Part
    oeArticle: ExtendedOePart
    onSelect(): void
    selectedPart?: Part
}

export default function AlternativeArticle({ alternativePart, oeArticle, selectedPart, onSelect }: Props) {
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const { renderMicro } = useMicro<IMicros>()
    const { vehicle } = useSelector(managerSelector)

    const renderBasketButton = () => (
        <Box className="tk-basket">
            <Box className="add-to-basket">
                <ReplaceButton onClick={onSelect} isWm={isWm} />
            </Box>
        </Box>
    )

    const renderOeArticle = () => {
        const oePart: OE.OePart = {
            description: oeArticle.description,
            ...(!oeArticle.isExtraArticle && {
                manufacturerName: vehicle?.manufacturer,
            }),
            ...(oeArticle.isExtraArticle && {
                manufacturerName: "SG",
            }),
            manufacturerId: vehicle?.tecDocManufacturerId,
            number: oeArticle.oeArticleNumber,
            prices: [
                {
                    value: oeArticle.oePriceValue ?? 0,
                    currency: "EUR",
                },
            ],
            ...(oeArticle.isExtraArticle && {
                thumbnailUrl: `${toolkitPath}/images/Saint-Gobain.png`,
            }),
        }

        return (
            <>
                {renderMicro!("parts", "oe-part-item", {
                    oePart,
                    renderBasketButton,
                    hiddenFakeActions: { costEstimation: true, selector: true, moreMenu: true, details: true },
                })}
            </>
        )
    }
    const renderPart = () => {
        const art: ArticleIdentifier = {
            productGroupId: alternativePart.article?.productGroup.id ?? alternativePart.productGroupId,
            supplierArticleNo: alternativePart.article?.supplierArticleNo ?? alternativePart.dataSupplierArticleNumber,
            supplierId: alternativePart.article?.supplier.id ?? alternativePart.dataSupplierId,
            tecdocTypeNo: vehicle?.tecDocTypeId,
        }

        return (
            <>
                {renderMicro!("parts", "part-item", {
                    partToLoad: art,
                    renderBuyActions: renderBasketButton,
                    showActions: true,
                } as any)}
            </>
        )
    }

    if (equals(selectedPart, alternativePart)) {
        return renderOeArticle()
    }

    return renderPart()
}
