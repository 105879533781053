import { z } from "zod"
import { FileCategory } from "../models"

const requiredNumberSchema = (translateText: (key: string | number) => string) => {
    return z.preprocess(
        (value) => (value === "" ? NaN : value),
        z.coerce
            .number({
                required_error: translateText(13831),
                invalid_type_error: translateText(13831),
            })
            .refine((val) => !Number.isNaN(val), {
                message: translateText(13831),
            })
    )
}

export function getUploadSchema(translateText: (key: string | number) => string) {
    return z
        .object({
            mileage: requiredNumberSchema(translateText),
            documentDate: z
                .date({ required_error: translateText(13831), invalid_type_error: translateText(13831) })
                .pipe(z.coerce.date({ required_error: translateText(13831), invalid_type_error: translateText(13831) })),
            invoiceTotal: requiredNumberSchema(translateText),
            category: z.nativeEnum(FileCategory),
            file: z.union([z.string(), z.instanceof(File), z.undefined()]).refine((val) => val !== undefined, {
                message: translateText(13831),
            }),
        })
        .transform((o) => ({ ...o, category: o.category, file: o.file }))
}

export type UploadSchemaType = {
    mileage: number
    documentDate: Date
    invoiceTotal: number | null
    file: string | File | undefined
    category: FileCategory | undefined
}
