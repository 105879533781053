import { getStyleTheme } from "@tm/context-distribution"
import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, font } = getStyleTheme()
    const themeStyles: NestedCSSProperties = {
        padding: "0.25em 0.4em",
        margin: 0,
        height: "4.5em",
        backgroundColor: "#fff",
        color: colors.dark,
        fontWeight: font.boldWeight,
        borderWidth: "1px",
        borderRightColor: colors.secondary,
        borderRadius: 0,
        $nest: {
            "&__close .icon": {
                fill: colors.dark,
            },
            "&--selected .icon, &:active .icon": {
                fill: `${colors.highlight} !important`,
            },
            "&:first-of-type::after": {
                content: "unset",
            },
            "&:before": {
                content: "''",
                width: "20px",
                height: "22px",
                background: "#FFFFFF",
                borderLeftColor: colors.secondary,
                borderLeft: "1px solid",
                transform: "translateX(8px) translateY(12px) rotateZ(45deg)",
                position: "absolute",
                bottom: "-2px",
                right: "-2px",
            },
            "&:after": {
                content: "''",
                width: "20px",
                height: "22px",
                background: "#fff",
                transform: "translate(10px, 10px) rotateZ(45deg)",
                position: "absolute",
                bottom: "-3px",
                left: "-20px",
                transition: "none",
            },
            "&--selected, &:active": {
                transform: "none !important",
                color: colors.highlight,
                backgroundColor: "#fff",
            },
            "&:hover:not(&--selected)": {
                backgroundColor: colors.secondary,
                borderLeftColor: colors.secondary,
                color: "#fff",
                $nest: {
                    "&:after": {
                        background: colors.secondary,
                    },
                },
            },
            "&:last-child": {
                borderRight: `1px solid ${colors.secondary}`,
            },
            "&__telesales__info": {
                fontSize: "1rem",
                position: "static",
            },
            "&__telesales__title": {
                fontSize: "0.6875rem",
                position: "static",
                order: 2,
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}

export function getMoreItemStyles(baseStyles: NestedCSSProperties): NestedCSSProperties {
    const moreItemStyles: NestedCSSProperties = {
        background: "white",
    }

    const mergedStyles = deepAssign(baseStyles, moreItemStyles)
    return mergedStyles
}

export function getOverflowMenuStyles(baseStyles: NestedCSSProperties): NestedCSSProperties {
    const { colors, animation } = getStyleTheme()

    const themeStyles: NestedCSSProperties = {
        height: "auto",
        $nest: {
            "&__main__inner:after": {
                content: "''",
                width: "20px",
                height: "22px",
                background: "#fff",
                transform: "translate(10px, 10px) rotateZ(45deg)",
                position: "absolute",
                bottom: "-3px",
                right: 0,
                transition: animation.transition,
            },
            "&__btn": {
                $nest: {
                    ".icon": {
                        fill: colors.dark,
                    },
                    "&:hover": {
                        $nest: {
                            ".icon": {
                                fill: colors.dark,
                            },
                        },
                    },
                    "&:focus": {
                        $nest: {
                            ".icon": {
                                fill: colors.dark,
                            },
                        },
                    },
                },
            },
        },
    }

    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
