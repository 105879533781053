import { TmaEModule, channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSearchTyre(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { searchTyre, sourceId } = data

    if (!searchTyre?.size) {
        return
    }

    let origin = TmaEModule.TM_TYRE_SEARCH

    switch (sourceId) {
        case "TM_MVC_TECRMI_DIRECT_SEARCH":
            origin = TmaEModule.TYRES_AUDACON
            break
        default:
            break
    }

    channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { ...searchTyre, origin })
}
