import {
    RepairTimeProvider,
    CustomWork,
    CustomPart,
    OE,
    ImportRepairEstimationRequest,
    RepairEstimationMainWork,
    CatalogPart,
    OePart,
    IncludedWorkRequest,
    RepairEstimationIncludedWork,
} from "@tm/models"
import { MainState } from "../../../main"
import { ExtendedOePart } from "../../../../data/models"
import { eOEArtSubType } from "../../../../data/enums"

function mapCustomPart(oePart: ExtendedOePart): CustomPart {
    return {
        articleNumber: oePart.oeArticleNumber,
        description: oePart.description,
        quantityValue: oePart.datProcessId !== eOEArtSubType.SurchargeOrDiscount ? oePart.quantityValue : 1,
        garagePrice: oePart.oePriceValue ?? undefined,
    }
}

function mapIncludedWork(includedWork: IncludedWorkRequest): RepairEstimationIncludedWork {
    return {
        categoryOfWork: includedWork.categoryOfWork,
        description: includedWork.description,
        providerWorkId: includedWork.providerWorkId,
        time: includedWork.time,
        sortNo: includedWork.sortNo,
    }
}

export function createCostEstimationRequest(state: MainState, workTaskId: string, memo?: string): ImportRepairEstimationRequest {
    const {
        calculation: { items, works },
        manager: { vehicle },
    } = state

    const selectedItems = items.filter((x) => x.isSelected)
    const repairTimes = works.filter((x) => x.isSelected && !x.isFixPrice)

    const parts: CatalogPart[] = []
    const oeParts: OePart[] = []
    const customParts: CustomPart[] = []
    const mainWorks: RepairEstimationMainWork[] = []
    const customWorks: CustomWork[] = []

    selectedItems?.forEach((item) => {
        if (item.selectedPart) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { warehouseId, ...rest } = item.selectedPart

            parts.push({
                ...rest,
                quantityValue: item.oeArticle.quantityValue,
                memo,
            })
        } else if (!item.oeArticle.oeArticleNumber) {
            customParts.push(mapCustomPart({ ...item.oeArticle, memo }))
        } else {
            oeParts.push({
                ...item.oeArticle,
                memo,
                oePriceValue: item.oeArticle.oePriceValue || undefined,
                oeArticleOrigin: {
                    module: OE.OeArticleModule.GlassRepairEstimation,
                    provider: OE.OeArticleProvider.DAT,
                },
            })
        }
    })

    repairTimes?.forEach((work) => {
        mainWorks.push({
            providerWorkId: work.providerWorkId,
            hourlyRate: work.hourlyRate,
            categoryOfWork: work.categoryOfWork,
            description: work.description,
            time: work.time,
            includedWorks: work.includedWorks?.map(mapIncludedWork),
            sortNo: work.sortNo,
        })
    })

    works
        ?.filter((x) => x.isFixPrice)
        .forEach((work) => {
            customWorks.push({
                customWorkNumber: work.providerWorkId,
                hourlyRate: work.hourlyRate,
                categoryOfWork: work.categoryOfWork,
                description: work.description,
                fixedPriceValue: work.total,
                time: work.calculatedTime,
            })
        })

    return {
        workTaskId,
        customParts,
        vehicleId: vehicle?.id ?? "",
        oeParts,
        works: mainWorks,
        customWorks,
        parts,
        repairTimesProvider: RepairTimeProvider.Dat,
    }
}
