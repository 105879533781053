import { ButtonKeyDefinition, concat } from '@tm/utils'
import { Dropdown, Button, TextField, ButtonLayout } from '@tm/controls'
import { classes, useStyle, } from '@tm/context-distribution'
import { useRef } from 'react'
import { TyreFilter } from "@tm/models"
import { IAllFilters } from './diameterFilters'

type Props = {
	filterName: IAllFilters
	isActive: boolean
	items: TyreFilter[]
	selectedValue?: TyreFilter
	defaultItem?: string
	withResetButton?: boolean
	disabled?: boolean
	onChange?(filterName: IAllFilters, value: TyreFilter | undefined): void
	hasInput?: boolean
	loading?: boolean
	label?: string
	layout?: ButtonLayout[]
	className?: string
	maxItemsToShow?: number
	submitOnTab?: boolean
	ref?: React.ForwardedRef<any>
	onDropdownClick?: () => void
	disableAutoSelect?: boolean
}

const CustomDropdown: React.FC<Props> = ({ filterName, onDropdownClick, isActive, label, items, defaultItem, selectedValue, withResetButton, className, disabled, hasInput, loading, layout, maxItemsToShow, submitOnTab, onChange, disableAutoSelect }) => {

	const timeoutRef = useRef<NodeJS.Timeout | null>(null)
	const inputRef = useRef<TextField | null>(null)
	const dropdownRef = useRef<Dropdown<TyreFilter> | null>(null)

	const [inputValue, setInputValue] = React.useState("")
	
	const dropDownItems = !inputValue ? [...items] : items.filter((x) => x.query.includes(inputValue))

	if (defaultItem) {
		const value = defaultItem.charAt(0).toUpperCase() + defaultItem.slice(1)
		dropDownItems.unshift({ query: "default", value, group: filterName }) // add default item into array
	}

	const foundItem = dropDownItems.find((item) => item.query == selectedValue?.query)
	const selectedItem = foundItem || (!disableAutoSelect && !!defaultItem && dropDownItems.length === 2 ? dropDownItems[1] : dropDownItems.first())

	React.useEffect(() => {

		if (!!timeoutRef.current) {
			clearTimeout(timeoutRef.current)
			timeoutRef.current = null
		}

		timeoutRef.current = setTimeout(() => {
			if (!dropdownRef?.current) {
				return
			}
			
			if (isActive && !dropdownRef.current.state.open) {
				dropdownRef.current.toggleDropdownMenu()
				return
			}
			
			if (!isActive && dropdownRef.current.state.open) {
				dropdownRef.current.toggleDropdownMenu()
			}
		}, 50)
	}, [isActive, loading])

	React.useEffect(() => {
		if (disableAutoSelect || !inputValue || !selectedItem) {
			return
		}

		const firstItem = dropDownItems?.filter(x => x.query !== "default")?.first()

		if (dropDownItems?.length == (defaultItem ? 2 : 1) && firstItem?.query == inputValue) {
			setInputValue("")
			onChange?.(filterName, firstItem!)
		}
	}, [inputValue])

	const handleDropdownOpen = () => setTimeout(() => inputRef?.current?.focus(), 100)

	const handleOnClose = () => {
		if (!!inputValue?.length) {
			setTimeout(() => setInputValue(""), 50)
		}

		if (!dropdownRef.current?.state.alreadyFocused) {
			dropdownRef.current?.setState({ alreadyFocused: true })
		}
	}

	const handleKeyPress = (e: React.KeyboardEvent) => {
		switch (e.key) {
			case ButtonKeyDefinition.Enter:
			case ButtonKeyDefinition.Tab: {
				e.preventDefault()
				e.stopPropagation()

				if (!dropdownRef?.current) {
					return
				}

				const { startShowIndex, preSelectedItemIndex } = dropdownRef?.current?.state
				
				let selectedListItem = dropDownItems[preSelectedItemIndex + startShowIndex]

				if (selectedListItem?.query == "default" && items.some(x => x.query == inputValue)) {
					selectedListItem = items.find(x => x.query == inputValue)!
				}
				onChange?.(filterName, selectedListItem)
				handleOnClose()
				
				return
			}
		}
	}


	const handleDropDownChange = (dropDownItem: TyreFilter | undefined) => {
		if (dropDownItem?.query == "default") {
			if (items.some(x => x.query == inputValue)) {
				dropDownItem = items.find(x => x.query == inputValue)!
			} else {
				dropDownItem = undefined
			}
		}
		onChange?.(filterName, dropDownItem)
		handleOnClose()
	}

	const handleInputConfirm = (value: string) => {
		const selectedItem = items.find(x => x.query == value)
		if (selectedItem) {
			onChange?.(filterName, selectedItem)
		}
		handleOnClose()
	}

	const handleOnTextInputChange = (value: string) => {
		setInputValue(value)
	}

	const renderDropDownInput = () => {
		return (
			<TextField
				onKeyDown={handleKeyPress}
				label={label}
				floatingLabel={true}
				value={inputValue}
				ref={inputRef}
				layout={["holo"]}
				autoFocus={true} // needed because it loses focus when we change the state
				preventConfirmOnBlur
				preventBubbling
				onChange={handleOnTextInputChange}
				onChangeConfirm={handleInputConfirm}
			/>
		)
	}

	return (
		<>
			<Dropdown<TyreFilter>
				className={classes(className, style.dropDownItem)}
				ref={dropdownRef}
				disabled={!items.length || disabled}
				items={loading ? undefined : dropDownItems}
				itemView={DropDownItemView}
				layout={layout}
				enableLoaderInDropdown
				onDropdownOpen={handleDropdownOpen}
				onPopOverClose={handleOnClose}
				onDropdownClose={handleOnClose}
				amountItemsToShow={maxItemsToShow || 6}
				value={selectedItem}
				onClick={onDropdownClick}
				onChange={handleDropDownChange}
				inputView={hasInput ? renderDropDownInput : undefined}
				submitOnTab={submitOnTab}
			/>
			{withResetButton && <Button
				layout={['ghost']}
				disabled={!selectedValue || !items.length || disabled}
				size="s"
				icon="synchronize"
				onClick={() => handleDropDownChange({ value: "", query: "default", group: filterName })} />}
		</>
	)

}

const DropDownItemView: React.FC<TyreFilter> = ({ value, info }) => {
	return <div style={{ textAlign: 'center' }}>{concat(" ", value, info)}</div>
}


const style = useStyle({
	dropDownItem: {
		maxWidth: "calc(100% - 2em)",
		$nest: {
			".btn__content": {
				overflow: "hidden"
			},
			".btn__content > div": {
				overflow: "hidden",
				textOverflow: "ellipsis"
			},
		},
	},
})(CustomDropdown)



export default CustomDropdown