import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { QueueStore } from "./model"

// Queue finished has the same function as queue cancelled, make sure to separate the functions if something else is needed
export const useQueueStore = create<QueueStore>()(
    devtools(
        (set) => ({
            state: {
                isQueued: false,
            },
            queueActions: {
                queueStart: (requestQueueId, checkStatusUrl) => set((state) => queueStart(state, requestQueueId, checkStatusUrl)),
                queueFinished: () => set((state) => queueFinished(state)),
                queueCancelled: () => set((state) => queueFinished(state)),
            },
        }),
        { name: "Fast Calculator Queue store", enabled: true }
    )
)

function queueStart(state: QueueStore, requestQueueId?: string, checkStatusUrl?: string): Partial<QueueStore> {
    return {
        state: {
            ...state.state,
            requestQueueId,
            checkStatusUrl,
            isQueued: true,
        },
    }
}

function queueFinished(state: QueueStore): Partial<QueueStore> {
    return {
        state: {
            ...state.state,
            isQueued: false,
            checkStatusUrl: "",
            requestQueueId: "",
        },
    }
}
