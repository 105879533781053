import { createSelector } from "reselect"
import { validate } from "../helpers"
import { DocumentsState } from "../model"

export const vehicleSelector = (s: DocumentsState) => s.vehicle
// export const customerSelector = createSelector((s: DocumentsState) => s.customer, combiner.self)
export const carModelSelector = (s: DocumentsState) => s.carModel

export const fieldsSelector = (s: DocumentsState) => s.model
export const documentsIdsSelector = createSelector(
    (s: DocumentsState) => s.model?.documents,
    (s) => s?.map((x) => x.documentTypeId) ?? []
)

export const modelStateSelector = createSelector(
    fieldsSelector,
    (s: DocumentsState) => s.model?.documents ?? [],
    (_: unknown, translateText: (key: string | number) => string) => translateText,
    vehicleSelector,
    carModelSelector,
    (s: DocumentsState) => s.vehicleDataIsMandatory,
    (fields, docs, translateText, vehicle, carModel, vehicleDataIsMandatory) =>
        validate(fields!, docs, translateText, vehicle, carModel, vehicleDataIsMandatory)
)
