import { useEffect, useState, useRef, Suspense, useMemo } from "react"
import { Overwrite } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { channel, ErpInformation, MemoType } from "@tm/models"
import { Box, Divider, Loader, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { StockInfoState, IActions, Actions } from "./business"
import { ErpSpecialProcurementText } from "./components/ErpSpecialProcurementText"
import { ErrorWrapper } from "../../_shared/teccom/Error"
import TeccomComponent from "../../_shared/teccom/TeccomComponent"
import { AdvancedDeliveryRequestTableData } from "../../_shared/teccom/AdvancedDeliveryRequestTable"

type Props = {
    state: Overwrite<StockInfoState, { erpInfo: ErpInformation }>
    actions: IActions
}

function SpecialProcurement({ state, actions }: Props) {
    const { erpInfo, loading, error } = state
    const telesalesCustomer = useTelesalesCustomerNumber()
    const { translateText } = useLocalization()
    const [articleDivision, setArticleDivision] = useState(erpInfo?.quantity?.division || 1)

    const teccomRef = useRef<HTMLDivElement>(null)
    const teccomTableData: AdvancedDeliveryRequestTableData = useMemo(() => {
        return {
            article: {
                requestedQuantity: erpInfo?.specialProcurementErpInformation?.requestedQuantity || erpInfo?.quantity?.requestedValue,
                availability: erpInfo?.specialProcurementErpInformation?.availability || erpInfo?.availability,
                warehouses: erpInfo?.warehouses,
                confirmedQuantity: erpInfo?.specialProcurementErpInformation?.confirmedQuantity || erpInfo?.quantity?.confirmedValue,
                expectedDelivery:
                    erpInfo?.specialProcurementErpInformation?.expectedDelivery &&
                    new Date(erpInfo?.specialProcurementErpInformation?.expectedDelivery),
                tourOrderAcceptanceTimeLimit:
                    erpInfo?.specialProcurementErpInformation?.tourOrderAcceptanceTimeLimit &&
                    new Date(erpInfo?.specialProcurementErpInformation?.tourOrderAcceptanceTimeLimit),

                // TODO: The Service mapping should be improved
                shortDescription: erpInfo?.specialProcurementErpInformation?.availability.shortDescription,
                additionalInformation: erpInfo?.specialProcurementErpInformation?.message,
                message: erpInfo?.additionalInformation,
            },
            shipments: erpInfo?.specialProcurementErpInformation?.orderOptions?.shipmentModes?.shipmentModes,
            refreshErp: erpInfo?.specialProcurementErpInformation?.orderOptions?.shipmentModes?.updateErpInformationOnChange,
        }
    }, [erpInfo])

    useEffect(() => {
        const unsub = channel("WORKTASK").subscribe("BASKET/ARTICLE_QUANTITY_CHANGED", ({ quantity: newQuantity }) => {
            if (erpInfo?.quantity?.division !== newQuantity) {
                actions.setErpLoading()
            }
        })
        return unsub
    }, [])

    // Reset loading, if quantity was change because of different division
    useEffect(() => {
        if (erpInfo && loading && erpInfo?.quantity?.division && erpInfo?.quantity?.division >= articleDivision) {
            setArticleDivision(erpInfo.quantity?.division)
            actions.setErpLoaded()
        }
    }, [erpInfo, loading])

    function loadTeccom(quantity: number) {
        actions.loadTeccom(quantity, telesalesCustomer.telesalesCustomerNo, undefined)
    }

    const getSpecialProcumentText = () => {
        return erpInfo?.memos?.find((e) => e.type === MemoType.HeaderInformation)?.text
    }

    return (
        <Box className="tk-erp stock-info" pb={1}>
            <ErpSpecialProcurementText text={getSpecialProcumentText()} />
            <Typography variant="h3">{translateText(1080)}</Typography>
            <Divider variant="fullWidth" />

            {error && <ErrorWrapper error={error} />}
            {loading ? (
                <Loader />
            ) : (
                <TeccomComponent
                    articleQuantity={erpInfo.quantity?.requestedValue || 1}
                    tableData={teccomTableData}
                    loadTeccom={loadTeccom}
                    wrapperRef={teccomRef}
                    loading={loading}
                    loaded
                    hideRequestButton
                />
            )}
        </Box>
    )
}

function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <SpecialProcurement {...props} />
        </Suspense>
    )
}

export default connectComponent(Actions, Wrapper)
