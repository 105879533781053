import { Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ErpPrice, MemoType } from "@tm/models"
import { InfoIcon } from "./StyledComponents"

type Props = {
    price: ErpPrice
}

export default function VkTooltip({ price }: Props) {
    const { translateText, currency } = useLocalization()

    const content =
        price.memos?.filter((x) => x.type === MemoType.AdditionalDescription).map((memo, idx) => <div key={`memo_${idx}`}>{memo.text}</div>) || []

    if (!price.taxIncluded && price.valueTaxIncluded) {
        if (content.length) {
            content.push(<br key="br" />)
        }

        content.push(
            <div key="vk-tooltip-main">
                {translateText(882)}: {currency(price.valueTaxIncluded, price.currencySymbol || "")}
            </div>
        )
    }

    if (!content.length) {
        return null
    }

    return (
        <Tooltip title={<>{content}</>}>
            <InfoIcon />
        </Tooltip>
    )
}
