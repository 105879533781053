import { MessageBus, Channel } from "@tm/hermes"
import { AlertColor, AlertMessageTypes } from "../data/models"

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }

    return mb.channel("BUNDLE")
}
export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = {
    ALERT_MESSAGE: { messageType?: AlertMessageTypes; message: string; severity?: AlertColor }
}
