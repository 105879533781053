import { useSelector } from "react-redux"
import { Box, Typography, VehicleImage, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { MainState } from "../../main"

export default function VehicleInfo() {
    const { translateText } = useLocalization()
    const vehicle = useSelector((s: MainState) => s.manager.vehicle)
    const theme = useTheme()

    if (!vehicle) {
        return null
    }

    return (
        <Box display="flex" flexDirection="column" margin={theme.margin?.m}>
            <Typography>
                {translateText(1462)} {translateText(1463)}
            </Typography>

            <Box display="flex" alignItems="center">
                <Typography>
                    {translateText(1464)} {translateText(1481)}
                </Typography>
                <Box display="flex" alignItems="center" width="4em" height="3em">
                    {vehicle.modelThumbnail && (
                        <VehicleImage
                            modelImage={vehicle.modelThumbnail}
                            modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                            vehicleType={vehicle.vehicleType}
                            style={{ width: "3em", margin: "0 .5em" }}
                        />
                    )}
                </Box>
                <Typography fontWeight={600}>
                    {vehicle.manufacturer} {vehicle.modelSeries}
                </Typography>
            </Box>
        </Box>
    )
}
