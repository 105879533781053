import { Icon, Headline, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import * as React from "react"

export const TiresError: React.FC<{}> = React.memo(() => {
    const { translateText } = useLocalization()
    return (
        <div className="article-list__panel article-list__status">
            <div className="article-list__no-result">
                <Icon name="no-results" size="xl" />
                <Headline>{translateText(809)}</Headline>
                <Text size="s" modifiers={["block"]} >{translateText(1088)}</Text>
            </div>
        </div>
    )
})