import { OE } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Box, Stack, Typography, styled } from "@tm/components"
import { OeNumber } from "./components/OeNumber"

type OePositionsProps = {
    positions: OE.OePosition[]
    onPositionSelect(position: OE.OePosition, replacement?: OE.OeNumber): void
    hideSelectedPosition?: boolean
    hideDescription?: boolean
    hideReplacements?: boolean
}

const OeReplacement = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",

    "&::before": {
        content: "'\\2192'",
        display: "block",
        margin: `0 ${theme.spacing()}`,
    },

    "&:first-child": {
        "&::before": {
            content: "'\\2BA1'",
        },
    },
}))

export function OePositions(props: OePositionsProps) {
    const { translate } = useLocalization()
    const { positions, onPositionSelect, hideSelectedPosition, hideDescription, hideReplacements } = props

    const selectedPosition = positions.find((x) => x.isSelected)

    if (!positions.length) {
        return null
    }

    const handleReplacementSelect = (replacement: OE.OeNumber) => selectedPosition && onPositionSelect(selectedPosition, replacement)

    return (
        <Box>
            {!hideSelectedPosition && (
                <Box>
                    <Typography variant="label">{translate(1814)}</Typography>
                    <Stack direction="row" gap={0.5}>
                        {positions.map((position, key) => (
                            <OeNumber
                                key={key}
                                oeNumber={position}
                                onSelect={onPositionSelect}
                                isSelected={position.isSelected}
                                hasSelectedReplacement={position.replacements?.some((x) => x.isSelected)}
                                hideDescription={hideDescription}
                            />
                        ))}
                    </Stack>
                </Box>
            )}
            {
                // !! needed as otherwise a length of zero can result in a "0" being rendered
                !hideReplacements && !!selectedPosition?.replacements?.length && (
                    <Box>
                        <Typography variant="label">{translate(1819)}</Typography>
                        <Stack direction="row" flexWrap="wrap">
                            {selectedPosition.replacements.map((replacement, key) => (
                                <OeReplacement key={key}>
                                    <OeNumber
                                        oeNumber={replacement}
                                        onSelect={handleReplacementSelect}
                                        hideDescription={hideDescription}
                                        isSelected={replacement.isSelected}
                                    />
                                </OeReplacement>
                            ))}
                        </Stack>
                    </Box>
                )
            }
        </Box>
    )
}
