import { useLocalization } from "@tm/localization"
import { PriceType } from "@tm/models"
import { Grid } from "@tm/components"
import { useMemo } from "react"
import { OrderCalculation } from "../../../../data/model"
import { HighlightContrastTypography, StyledBox } from "../../../StyledComponents"
import Total from "../Total"
import SummaryDetails from "../SummaryDetails"
import { useSummaryTheme } from "../../hooks/useSummaryTheme"

type Props = {
    calculatedOrder: OrderCalculation
    priceType: PriceType
}

export default function BasketTotalsTable({ calculatedOrder, priceType }: Props) {
    const { netTotalsInOrder, grossTotalsInOrder, numberOfItemsInOrder } = calculatedOrder
    const { translateText } = useLocalization()
    const { styledBoxBackgroundColor, highlightColor, styledBoxPadding } = useSummaryTheme()

    const netTotalPrice = useMemo(() => {
        if (!netTotalsInOrder) {
            return
        }
        const totalPricesAllParts = Object.values(netTotalsInOrder)
        let result = totalPricesAllParts.find((price) => price?.type === priceType)
        if (!result && priceType === PriceType.Retail) {
            result = totalPricesAllParts.find((price) => price?.type === PriceType.RecommendedRetail)
        }
        return result
    }, [netTotalsInOrder, priceType])

    const grossTotalPrice = useMemo(() => {
        if (!grossTotalsInOrder) {
            return
        }
        const totalPricesAllParts = Object.values(grossTotalsInOrder)
        let result = totalPricesAllParts.find((price) => price?.type === priceType)
        if (!result && priceType === PriceType.Retail) {
            result = totalPricesAllParts.find((price) => price?.type === PriceType.RecommendedRetail)
        }
        return result
    }, [grossTotalsInOrder, priceType])

    return (
        <>
            <StyledBox backgroundColor={styledBoxBackgroundColor} padding={styledBoxPadding}>
                <SummaryDetails
                    currencyCode={netTotalPrice?.currencyCode_Iso_4217}
                    partCosts={netTotalPrice?.value || 0}
                    partCount={numberOfItemsInOrder}
                />
            </StyledBox>
            <StyledBox backgroundColor={highlightColor} padding={styledBoxPadding}>
                <Grid container spacing={1} alignItems="center" sx={{ rowGap: 0.5 }}>
                    <Total currencyCode={netTotalPrice?.currencyCode_Iso_4217} label={translateText(694)} value={netTotalPrice?.value} />
                    <Total currencyCode={grossTotalPrice?.currencyCode_Iso_4217} label={translateText(13668)} value={grossTotalPrice?.value} />
                    <Grid item xs={6} />
                    <Grid item xs={6} textAlign="right">
                        <HighlightContrastTypography variant="label">*{translateText(802)}</HighlightContrastTypography>
                    </Grid>
                </Grid>
            </StyledBox>
        </>
    )
}
