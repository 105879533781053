import { useLocalization } from "@tm/localization"
import { Box, CellContentPosition, TableCellData, TableColumnData, TableVariants } from "@tm/components"
import { FindInvoicesResponse, InvoiceResponse } from "../../../data/model"
import ConnectedVouchersTable from "../../_shared/tables/ConnectedVouchersTable"
import { getBundleParams } from "../../../utils"
import { StyledTable } from "../../_shared/StyledComponents"
import { NoResultHint } from "../../_shared/NoResultHint"
import DateCell from "../../_shared/cells/DateCell"
import VoucherOptionsCell from "../../_shared/cells/VoucherOptionsCell"

type Props = {
    invoices: FindInvoicesResponse
    compact: boolean
    openedConnectedVouchers: Array<string>
    selectedInvoiceId?: string
    voucherTypeId: number
    variant: TableVariants
    onExpandRowClick?(invoiceId: string): void
    loadNextPage(): void
}

export default function InvoiceTableComponent(props: Props) {
    const { selectedInvoiceId, invoices, openedConnectedVouchers, variant, voucherTypeId, compact } = props
    const { translateText, currency } = useLocalization()
    const { invoiceDetailsRoute } = getBundleParams()

    function getRowColor(rowData: InvoiceResponse): string | undefined {
        if (!rowData || !rowData.state || !invoices) {
            return undefined
        }

        const { processingStates } = invoices
        const invoiceState = processingStates.find((state) => state.description === rowData.state)
        if (!invoiceState) {
            return undefined
        }

        return invoiceState.htmlColorCode
    }

    function renderConnectedVouchers(invoice: InvoiceResponse) {
        return (
            <Box flex={1}>
                <ConnectedVouchersTable key="connectedVouchers" parentVoucherId={invoice.invoiceId} vouchersParentTypeId={props.voucherTypeId} />
            </Box>
        )
    }

    function renderOptionsCell(invoice: InvoiceResponse) {
        return (
            <VoucherOptionsCell
                detailsRoute={invoiceDetailsRoute}
                openedConnectedVouchers={openedConnectedVouchers}
                selectedVoucherId={selectedInvoiceId}
                voucherId={invoice.invoiceId}
                itemsCount={invoice.itemsCount}
                hasConnectedVouchers={invoice.hasConnectedVouchers}
                voucherTypeId={voucherTypeId}
                showConnectedVouchersButton={!compact}
                onExpandRowClick={props.onExpandRowClick}
            />
        )
    }

    function getModuleColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(461) },
            { header: translateText(463) },
            { header: translateText(462) },
            { header: translateText(464) },
            { header: translateText(178) },
            { header: translateText(62), alignContent: CellContentPosition.right },
            { header: translateText(466), alignContent: CellContentPosition.right },
            { alignContent: CellContentPosition.right },
        ]

        return columns
    }

    function getCompactColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(461) },
            { header: translateText(463) },
            { header: translateText(62), alignContent: CellContentPosition.right },
            { alignContent: CellContentPosition.right },
        ]
        return columns
    }

    function getColumns() {
        if (compact) {
            return getCompactColumns()
        }

        return getModuleColumns()
    }

    function getModuleCellData(invoice: InvoiceResponse) {
        const cellData: TableCellData[] = [
            { displayValue: invoice.invoiceId, id: "order_0" },
            { displayValue: <DateCell value={invoice.dateOfInvoice} mode="onlyDate" />, id: "order_1" },
            { displayValue: <DateCell value={invoice.dueDate} mode="onlyDate" />, id: "order_2" },
            { displayValue: invoice.state, id: "order_3" },
            { displayValue: invoice.information, id: "order_4" },
            { displayValue: currency(invoice.amountInvoice || 0, invoice.currencyCode), id: "order_5" },
            { displayValue: currency(invoice.saldo || 0, invoice.currencyCode), id: "order_6" },
            { displayValue: renderOptionsCell(invoice), id: "voucher_7" },
        ]
        return cellData
    }

    function getCompactCellData(invoice: InvoiceResponse) {
        const cellData: TableCellData[] = [
            { displayValue: invoice.invoiceId, id: "order_0" },
            { displayValue: <DateCell value={invoice.dateOfInvoice} mode="onlyDate" />, id: "order_1" },
            { displayValue: currency(invoice.amountInvoice || 0, invoice.currencyCode), id: "order_5" },
            { displayValue: renderOptionsCell(invoice), id: "voucher_7" },
        ]
        return cellData
    }

    function getCells(invoice: InvoiceResponse) {
        let cells: TableCellData[] = []
        if (compact) {
            cells = getCompactCellData(invoice)
        } else {
            cells = getModuleCellData(invoice)
        }

        return cells
    }

    const displayData = invoices.invoices?.map((invoice, index) => ({
        cells: getCells(invoice),
        id: `${index}`,
        customRow: false,
        active: selectedInvoiceId === invoice.invoiceId,
        extendedContent:
            !compact && openedConnectedVouchers.some((item) => item === invoice.invoiceId) ? renderConnectedVouchers(invoice) : undefined,
        leftRowIndicatorColor: getRowColor(invoice),
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} variant={variant} onScrollBottom={props.loadNextPage} />
}
