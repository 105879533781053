import { VehicleImage, Box, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useSelector } from "react-redux"
import { dateToString } from "../../../data/helpers"
import { managerSelector } from "../../../selectors"
import HeaderContent from "./_shared/HeaderContent"

export default function Header() {
    const { translateText } = useLocalization()
    const { vehicle, customer } = useSelector(managerSelector)

    if (!vehicle) {
        return null
    }

    return (
        <StyledHeader>
            <HeaderContent
                title={`${translateText(1575)} ${translateText(1481)}`}
                content={[
                    vehicle.modelThumbnail && (
                        <Box display="flex" alignItems="center" width="4em" height="3em">
                            <VehicleImage
                                modelImage={vehicle.modelThumbnail}
                                modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                                vehicleType={vehicle.vehicleType}
                                style={{ width: "3em", margin: "0 .5em" }}
                            />
                        </Box>
                    ),
                    vehicle.manufacturer,
                    vehicle.modelSeries,
                ]}
            />
            {vehicle.engineCode && <HeaderContent title={`${translateText(105)}: `} content={vehicle.engineCode} />}
            {vehicle.initialRegistration && <HeaderContent title={`${translateText(124)}: `} content={dateToString(vehicle.initialRegistration)} />}
            {vehicle.vin && <HeaderContent title={`${translateText(101)}: `} content={vehicle.vin} />}
            {(customer?.firstName || customer?.lastName) && (
                <HeaderContent title={`${translateText(1930)}: `} content={`${customer.firstName ?? ""} ${customer.lastName ?? ""}`} />
            )}
        </StyledHeader>
    )
}

const StyledHeader = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    paddingTop: ".5em",
}))
