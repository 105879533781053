import { css, getStyleTheme, StyleProps, withStyle } from '@tm/context-distribution'
import { Checkbox, Text } from '@tm/controls'
import { classes } from '@tm/utils'
import { Component } from 'react';

type Props = StyleProps<typeof stylesheet> & {
    label: string
    info?: string
    selected?: boolean,
    onChange?: () => void
    disabled?: boolean
}

class SelectionCheckbox extends React.Component<Props> {
    render() {
        const { label, info, style, onChange, selected } = this.props

        return (
            <div className={classes(style.filterItem, "is-clickable selection-list__item--high-priority")}>
                <Checkbox
                    checked={selected}
                    size="s"
                    onToggle={onChange}
                />
                <div className={classes(style.textWrapper, "selection-list__item")} onClick={onChange}>
                    <Text modifiers={"strong"} size="s">{label}</Text>
                    {info && <Text size="xs">&nbsp;{info}</Text>}
                </div>
            </div>
        )
    }
}

function stylesheet() {
    const theme = getStyleTheme()
    return css({
        filterItem: {
            display: "flex",
            marginLeft: theme.margin.m
        },
        textWrapper: {
            marginLeft: theme.margin.m
        }
    })
}

export default withStyle(stylesheet, SelectionCheckbox) 
