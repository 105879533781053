import { AddKVAToRDBasketState } from "./model"
import { MainService } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"

export type ComponentActionType =
    | { type: 'CHANGE_POST_MESSAGE_STATUS', payload: boolean }
    | { type: 'GET_REPAIR_ITEMS_FROM_RD', payload: MainService[] }


const DEFAULT_STATE: AddKVAToRDBasketState = {
    repairTimeNos: [],
    blockPostMessage: false
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): AddKVAToRDBasketState {
    switch (action.type) {
        case "CHANGE_POST_MESSAGE_STATUS": {
            return {
                ...state,
                blockPostMessage: action.payload
            }
        }
        case "GET_REPAIR_ITEMS_FROM_RD": {
            return {
                ...state,
                repairTimeNos: action.payload
            }
        }

    }

    return state
}

function setBlockMessageStatus(value: boolean): ComponentActionType {
    return { type: "CHANGE_POST_MESSAGE_STATUS", payload: value }
}

function getRepairItems(items: MainService[]): AsyncAction<ComponentActionType, AddKVAToRDBasketState> {
    return (dispatch) => {
        console.log(items)

        dispatch({ type: "GET_REPAIR_ITEMS_FROM_RD", payload: items })
        //dispatch({ type: "ADD_ITEMS_TO_RD_BASKET_LOADED", payload: { itemID } })
    }
}

export interface IActions {
    setBlockMessageStatus(value: boolean): void
    getRepairItems(items: MainService[]): void
}

export const Actions: IActions = {
    setBlockMessageStatus,
    getRepairItems
}
