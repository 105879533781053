import { CarModelDetailsResponse, Customer, RegisteredModels, Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { BundleActions, BundleActionTypes } from "../../../business"
import { MainActionsType } from "../../main/business"

export type ModuleManagerState = {
    vehicle?: Vehicle
    customer?: Customer
    taskId?: string
}

export type ComponentActionType = BundleActionTypes

const DEFAULT_STATE: ModuleManagerState = {}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): ModuleManagerState => {
    switch (action.type) {
        case "RESET": {
            return {
                ...state,
                taskId: DEFAULT_STATE.taskId,
            }
        }
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "CUSTOMER_SET": {
            return {
                ...state,
                customer: action.payload,
            }
        }
        case "PREPARE_CALC_LOADED": {
            return {
                ...state,
                taskId: action.payload.taskId,
            }
        }
        default: {
            return state
        }
    }
}

const loadVehicleDetails = (vehicle: Vehicle): AsyncAction<ComponentActionType, ModuleManagerState> => {
    return (dispatch) => {
        Container.getInstance<CarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)
            .subscribe({ modelId: vehicle.tecDocTypeId })
            .load()
            .then((response) => {
                response && dispatch({ type: "DETAILS_LOADED", payload: response })
            })
    }
}

const setVehicle = (vehicle: Vehicle): ComponentActionType => ({ type: "VEHICLE_SET", payload: vehicle })

const setCustomer = (customer: Customer): ComponentActionType => ({ type: "CUSTOMER_SET", payload: customer })

const resetStore = (): ComponentActionType => ({ type: "RESET" })

export type IActions = typeof Actions

export const Actions = { ...BundleActions, setVehicle, loadVehicleDetails, setCustomer, resetStore }
