import { percent } from "csx"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Scrollbar } from "@tm/controls"
import { RouteComponentProps, initSelector, withRouter } from "@tm/utils"
import { MainState } from "."
import { NavigationSteps } from "../../data/models"
import { getBundleParams } from "../../utils"
import { SplashScreen, WrappedModule } from "../_shared"
import Details from "../details/component"
import ModuleManager from "../moduleManager/component"
import Navigation from "../navigation/component"
import Summary from "../summary/component"

type Props = RouteComponentProps<{ view: NavigationSteps }> & {
    route: string
    vehicleSelectionRoute: string
}

const selector = initSelector((s: MainState) => s.loading, s => s.summary.cars.loading, s => s.manager.progress, s => s.manager.vehicle)

function Main({ match, route }: Props) {
    const { disableStateSave } = getBundleParams()

    const workTaskTecDoc = useWorkTask()?.workTask?.vehicle?.tecDocTypeId
    const [loading, carsLoading, progress, vehicle] = useSelector(selector)
    const style = useMemo(() => getStyle(), [])

    //used only on vechicle change on the same worktask -> to prevent showwing in first render of the previus stored data )(channel(VEHILE_LOADED) is coming later)
    const [forceShowSplash, setforceShowSplash] = useState(vehicle && !disableStateSave && workTaskTecDoc != vehicle.tecDocTypeId)

    useEffect(() => {
        forceShowSplash && setTimeout(setforceShowSplash.bind(undefined, false), 800)
    }, [])

    const renderContent = () => {
        return <>
            <Navigation eurotaxRoute={route} />
            <div className={style.content}>
                <Scrollbar>
                    {
                        match.params.view == "details" &&
                        <WrappedModule>
                            <Details />
                        </WrappedModule>}
                    {
                        match.params.view == "summary" &&
                        <WrappedModule>
                            <Summary defaultRoute={route} />
                        </WrappedModule>
                    }
                </Scrollbar>
            </div>
        </>
    }

    const showSplashScreen = loading || carsLoading || forceShowSplash

    return (
        <div className={style.wrapper}>
            <ModuleManager />
            {showSplashScreen && <WrappedModule> <SplashScreen current={carsLoading && 5 || progress} max={5} /> </WrappedModule>}
            {!showSplashScreen && renderContent()}
        </div >
    )
}

export default withRouter(Main)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            width: percent(100),
            height: percent(100),
            display: "flex",
            flexDirection: "column"
        },
        content: {
            margin: theme.margin.m,
            flex: 1,
            display: "flex",
        }
    })(Main)
}
