import { useEffect, useState } from "react"
import * as Data from "../../../data"

export function useLoadExportInfos(workTaskId?: string, showExportVoucherButton?: boolean) {
    const [exportLabel, setExportLabel] = useState<string | undefined>(undefined)
    const [exportUri, setExportUri] = useState<string | undefined>(undefined)

    useEffect(() => {
        setExportLabel(undefined)
        setExportUri(undefined)
        if (workTaskId && showExportVoucherButton !== false) {
            Data.getExportVoucherInfos(workTaskId).then((response) => {
                setExportLabel(response.exportLabel)
                setExportUri(response.uri)
            })
        }
    }, [workTaskId, showExportVoucherButton])

    return { exportLabel, exportUri }
}
