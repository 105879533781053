import { useEffect, useCallback, useMemo, useState } from "react"
import { TextField } from "@tm/controls"
import { getError, isValid } from "./utils"

export interface EmailProps {
    onHandleChange(value: string, valid: boolean): void
    value?: string
    placeholder?: string
    className?: string
}

export function Email(props: EmailProps) {
    const { placeholder, onHandleChange, value: defaultValue = "", className } = props
    const [email, setEmail] = useState(defaultValue)
    const [displayError, setDisplayError] = useState<boolean>(false)
    const error = useMemo(() => getError(email), [email])

    const handleChange = useCallback(
        (value: string) => {
            setEmail(value)
            onHandleChange(value, isValid(value))
        },
        [onHandleChange]
    )

    useEffect(() => {
        handleChange(props.value || "")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFocus = useCallback(() => {
        if (!error) {
            setDisplayError(false)
        }
    }, [error])

    const handleBlur = useCallback(() => {
        setDisplayError(true)
    }, [])

    return (
        <TextField
            forceShowErrorBorder={displayError ? !!error : false}
            className={`feedback-email ${className}`}
            placeholder={placeholder}
            value={email}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
    )
}
