import { PropsWithChildren, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, styled } from "@tm/components"

type Props = {
    itemsPrice: string
    itemsCount: number
    title: string
}

export default function DcCollapsible({ children, itemsPrice, itemsCount, title }: PropsWithChildren<Props>) {
    const { translateText } = useLocalization()
    const [isOpen, setIsOpen] = useState(itemsCount > 0)

    const handleOpenAccordion = () => {
        setIsOpen(!isOpen)
    }

    const renderCollapsibleHeader = () => {
        return (
            <Box display="flex" gap="1em" marginRight="1em">
                <Typography fontWeight="bold">{`${itemsCount} ${translateText(479)}`}</Typography>
                <Typography fontWeight="bold">{itemsPrice}</Typography>
            </Box>
        )
    }

    return (
        <StyledAccordion expanded={isOpen} disableGutters elevation={0} onChange={handleOpenAccordion}>
            <AccordionSummary
                sx={{ borderBottom: "1px solid #e2e2e2", display: "flex", justifyContent: "space-between", alignItems: "center", ml: "1em" }}
            >
                <Typography fontWeight="600" flex="1">
                    {title}
                </Typography>
                {renderCollapsibleHeader()}
            </AccordionSummary>
            <AccordionDetails sx={{ marginBottom: "-1em" }}>{children}</AccordionDetails>
        </StyledAccordion>
    )
}

const StyledAccordion = styled(Accordion)({
    borderRadius: "3px",
    marginBottom: "1rem",
    boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    fontWeight: 500,
    "&:before": {
        backgroundColor: "transparent",
    },
})
