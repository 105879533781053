import { useQuery } from "react-query"
import { useFastServiceStore } from "../../data"
import { GetArticleAlternativeRequest } from "../../data/repositories/articleAlternatives/model"
import { getArticleAlternatives } from "../../data/repositories/articleAlternatives"

export function useAlternativeArticles(articleId?: string) {
    const { stateVehicle, calcState } = useFastServiceStore((state) => state.maintenancePlan)

    let request: GetArticleAlternativeRequest | undefined
    if (calcState?.calcId && calcState?.eTag && stateVehicle?.tecDocTypeId !== undefined && articleId) {
        request = {
            calcId: calcState.calcId,
            eTag: calcState.eTag,
            kTypeNr: stateVehicle.tecDocTypeId,
            articleId,
        }
    }

    const query = useQuery(["GetArticleAlternatives", request], () => getArticleAlternatives(request!), {
        enabled: !!request,
        staleTime: 10 * 60 * 1000, // 10 minutes
    })

    return {
        alternativesLoading: query.isLoading || query.isFetching || query.isRefetching,
        alternativesError: query.isError,
        alternatives: query.data,
    }
}
