import { DialogContent, CircularProgress, styled, MuiTableCell } from "@tm/components"

export const ModalLoader = () => (
    <DialogContent>
        <CircularProgress />
    </DialogContent>
)

export const StyledCell = styled(MuiTableCell)(() => ({
    fontSize: "1rem",
}))
