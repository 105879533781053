import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { lightGrayBorder } from "../../../styles"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    const carIconWidth = "2rem"

    return stylesheet({
        main: {
            marginBottom: margin.s,
            borderBottom: "2px solid #d3d3d3",
        },
        header: {
            display: "flex",
            alignItems: "center",
        },
        headerIcon: {
            flexBasis: carIconWidth,
            lineHeight: 0,
        },
        headerText: {
            flex: 1,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
        },
        licensePlateField: {
            marginLeft: carIconWidth,
            marginBottom: margin.xl,
            minWidth: "9em",
            $nest: { ".input__inner": { padding: ".2rem .4rem" } },
        },
        content: {
            marginLeft: carIconWidth,
            marginRight: margin.m,
        },
        status: {
            borderTop: lightGrayBorder,
            borderBottom: lightGrayBorder,
            padding: `${margin.l} 0`,
            margin: `${margin.l} 0`,
        },
        statusContent: {
            display: "flex",
            alignItems: "center",
            marginTop: margin.s,
        },
        statusIcon: {
            marginRight: margin.m,
        },
        separator: {
            borderTop: lightGrayBorder,
            margin: `${margin.l} 0`,
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
