import { Suspense } from "react"
import { Loader } from "@tm/controls"
import { IMicros } from "@tm/models"
import AvailabilityWrapperComponent from "../../../_shared/availability-wrapper"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"
import { StyledBox } from "../StyledComponents"

type Props = IMicros["erp"]["compilation-availability"]

function ErpInfoCompilationAvailabilityComponent(props: Props) {
    const { loading, erpInfo, error } = useErpInfo(props.data, "list")

    if (loading) {
        return <Loader />
    }

    return (
        <AvailabilityWrapperComponent
            onClick={props.onClick}
            hasReplacedArticles={!!erpInfo?.articles?.length}
            availability={erpInfo?.availability}
            tour={erpInfo?.tour}
            warehouses={erpInfo?.warehouses}
            error={error}
            requestedQuantity={erpInfo?.quantity?.requestedValue}
            specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
            useSpecialProcurement={erpInfo?.useSpecialProcurement}
        />
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <StyledBox className="avail">
                <Suspense fallback={<Loader />}>
                    <ErpInfoCompilationAvailabilityComponent {...props} />
                </Suspense>
            </StyledBox>
        </ErpWrapper>
    )
}
