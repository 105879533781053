import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()
    const padding = `${margin.s} ${margin.l}`

    return stylesheet({
        area: {
            background: "#fff",
            padding,
            paddingTop: margin.l,
        },
        row: {
            marginBottom: margin.l,
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
