import { useEffect, useMemo, useState } from "react"
import { MenuItemWidget, Select, SelectProps } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ECounterType, VoucherType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { useParams } from "react-router"
import { TmaHelper, mapVoucherTypeFromUrl } from "@tm/utils"
import { VoucherRouteParams } from "../../business"

export type VoucherTypeSelectItem = {
    voucherType: VoucherType
    value: string
}

type Props = {
    disabled?: boolean
    selectedVoucherType: VoucherType
    size?: "small" | "medium"
    variant?: "filled" | "outlined"
    color?: SelectProps["color"]
    onVoucherTypeChanged(item: VoucherTypeSelectItem): void
}

export default function VoucherTypeSelectorComponent({ disabled, selectedVoucherType, size, color, variant, onVoucherTypeChanged }: Props) {
    const { translateText } = useLocalization()
    const params = useParams<VoucherRouteParams>()

    const { showVoucherKVA, showVoucherOrder, showVoucherReturn } = useUser().userContext.parameter

    const items = useMemo(() => {
        const result: Array<VoucherTypeSelectItem> = []

        if (showVoucherKVA) {
            result.push({ voucherType: VoucherType.CostEstimation, value: translateText(82) })
        }

        if (showVoucherOrder) {
            result.push({ voucherType: VoucherType.Order, value: translateText(292) })
        }

        if (showVoucherReturn) {
            result.push({ voucherType: VoucherType.Return, value: translateText(13256) })
        }

        return result
    }, [showVoucherKVA, showVoucherOrder, showVoucherReturn, translateText])

    const [selectedItem, setSelectedItem] = useState<VoucherTypeSelectItem>(items.find((x) => x.voucherType === selectedVoucherType) || items[0])

    useEffect(() => {
        if (params.type) {
            const voucherType = mapVoucherTypeFromUrl(params.type)
            const item = items.find((item) => item.voucherType === voucherType)
            if (item && item.voucherType !== selectedItem.voucherType) {
                setSelectedItem(item)
            }
        }
    }, [params.type, items, selectedItem])

    const handleGetCounterType = (identifier: number) => {
        switch (identifier) {
            case VoucherType.CostEstimation:
                return ECounterType.VoucherCostEstimate
            case VoucherType.Order:
                return ECounterType.VoucherOrders
            default:
                return ECounterType.Undefined
        }
    }

    function handleChangeVoucherType(item: VoucherTypeSelectItem) {
        onVoucherTypeChanged(item)
        setSelectedItem(item)
        TmaHelper.GeneralCountEvent.Call(handleGetCounterType(item.voucherType))
    }

    if (!items.length || !selectedItem) {
        return null
    }

    return (
        <Select
            defaultValue={items[0]}
            value={selectedItem}
            onChange={(e) => handleChangeVoucherType(e.target.value as VoucherTypeSelectItem)}
            disabled={disabled}
            renderValue={(val) => (val as VoucherTypeSelectItem).value}
            size={size}
            variant={variant}
            color={color}
            fullWidth
        >
            {items.map((item) => {
                return (
                    <MenuItemWidget key={item.value} value={item}>
                        {item.value}
                    </MenuItemWidget>
                )
            })}
        </Select>
    )
}
