import { ChangeItemsResponse, ImportResponse, channel } from "@tm/models"
import { useWorkTaskBasketStore } from "./useWorkTaskBasketStore"
import { ChangeCostEstimationResponse, ChangePartPriceCalculationResponse } from "../../../model"

export function useBasketUpdateWorkflow(updatePartErpInfo: (partId: string, quantityValue?: number) => Promise<unknown>) {
    const [
        invalidateWorkTaskBasket,
        invalidateWorkEstimation,
        invalidateBasketErpInfo,
        invalidateBasketCalculation,
        invalidateBonusPointsCalculation,
        invalidateUpdateOrder,
    ] = useWorkTaskBasketStore((store) => [
        store.invalidateWorkTaskBasket,
        store.invalidateWorkEstimation,
        store.invalidateBasketErpInfo,
        store.invalidateBasketCalculation,
        store.invalidateBonusPointsCalculation,
        store.invalidateUpdateOrder,
    ])

    async function handleBasketUpdateWorkflow(
        workTaskId: string,
        response: ChangeItemsResponse | ChangeCostEstimationResponse | ChangePartPriceCalculationResponse | ImportResponse,
        quantityValue?: number
    ) {
        const { basketUpdateWorkflow } = response
        const changedItems = "changedItems" in response ? response.changedItems : undefined
        channel("WORKTASK", workTaskId).publish("BASKET/CLEAR_ORDER_SENT_CONTAINER", {})

        if (basketUpdateWorkflow.updateWorkTaskBasket) {
            invalidateWorkTaskBasket(workTaskId)
        }

        if (basketUpdateWorkflow.updateErpInfo) {
            invalidateBasketErpInfo(workTaskId)
        }

        if (basketUpdateWorkflow.updateItemErpInfo && changedItems?.length) {
            await Promise.allSettled(changedItems.map((item) => updatePartErpInfo(item.id, quantityValue)))
        }

        if (basketUpdateWorkflow.updateWorkEstimation) {
            invalidateWorkEstimation(workTaskId)
        }

        if (basketUpdateWorkflow.updateWorkTaskBasketCalculation) {
            invalidateBasketCalculation(workTaskId)
        }

        if (basketUpdateWorkflow.updateBonusPointCalculation) {
            invalidateBonusPointsCalculation(workTaskId)
        }

        if (basketUpdateWorkflow.updateOrder) {
            invalidateUpdateOrder(workTaskId)
        }
    }
    return handleBasketUpdateWorkflow
}
