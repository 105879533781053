import { useCallback, useEffect, useRef } from "react"
import { useLocalization } from "@tm/localization"
import { styled, Typography, Box, Button, TextField, Icon } from "@tm/components"

import { closeModalOrDrawer, setValue, useUnsubscribe } from "@tm/utils"
import { DateField } from "@tm/controls"
import { Article, channel, EReplaceButtonBundle } from "@tm/models"
import { tyresWheelsActions, useFastServiceStore, worksActions } from "../../../data"
import { CollapsibleData, ExtendETyreSeason, WheelsAndTyresTabNames } from "../../../data/state/tyres-wheels"
import { Genart, TyreAxles } from "../../../data/models"
import { CollapsibleWrapper, PartItem } from "../../_shared"
import GenArt from "./genArt"

type Props = {
    collapsibleName: string
    tabName: string
    info: CollapsibleData
    isExpanded: boolean
    genArtItem: Genart
    currentStepName: string
    handleCompleteCollapsible: (collapsibleName: string) => void
}

function CollapsibleContent({ tabName, info, collapsibleName, isExpanded, genArtItem, currentStepName, handleCompleteCollapsible }: Props) {
    const { translateText } = useLocalization()
    const subscriptionReplacePart = useUnsubscribe()
    const tyrePressureRef = useRef<HTMLInputElement>(null)
    const profileDepthRef = useRef<HTMLInputElement>(null)
    const dotRef = useRef<HTMLInputElement>(null)
    const selectedTyreSpecification = useFastServiceStore((state) => state.tyresWheels.selectedTyreSpecification)
    const tireSpecifications = useFastServiceStore((state) => state.maintenancePlan.tireSpecifications)

    useEffect(() => {
        if (collapsibleName === "tyrePressure" && isExpanded) {
            tyrePressureRef.current && tyrePressureRef.current.focus()
        } else if (collapsibleName === "tyreDot" && isExpanded) {
            profileDepthRef.current && profileDepthRef.current.focus()
        }
    }, [isExpanded])

    const handleCollapsibleData = (path: keyof CollapsibleData, filter: string | Date) => {
        const newCollapseInfo = setValue({ ...info }, [path as string], filter)
        tyresWheelsActions.updateCollapsibleData(tabName, collapsibleName, newCollapseInfo)
    }

    const handleEnter = (key: React.KeyboardEvent<HTMLDivElement>) => {
        if (key.code === "Enter") {
            let allowed = false

            if (collapsibleName === "tyreDot") {
                allowed = !!info.profileDepth && !!info.dot
            } else {
                allowed = !!Object.values(info).length
            }

            if (allowed) {
                handleCompleteCollapsible(collapsibleName)
            }
        }
    }

    const hadleChangeFocusOnEnter = (key: React.KeyboardEvent<HTMLDivElement>) => {
        if (key.code === "Enter" && !!info.profileDepth) {
            dotRef.current?.focus()
        }
    }

    const renderFrontAxlePressure = () => {
        if (selectedTyreSpecification?.frontAxle?.pressure) {
            return (
                <CenterDiv>
                    <Typography variant="h4">{translateText(13654)}</Typography>
                    <Typography variant="h4" style={{ fontWeight: "bold", paddingLeft: ".5em" }}>
                        {`${selectedTyreSpecification?.frontAxle?.pressure} ${translateText(13666)}`}
                    </Typography>
                </CenterDiv>
            )
        }
        return <></>
    }

    const renderRearAxlePressure = () => {
        if (selectedTyreSpecification?.rearAxle?.pressure) {
            return (
                <CenterDiv>
                    <Typography variant="h4">{translateText(13655)}</Typography>
                    <Typography variant="h4" style={{ fontWeight: "bold", paddingLeft: ".5em" }}>
                        {`${selectedTyreSpecification?.rearAxle?.pressure} ${translateText(13666)}`}
                    </Typography>
                </CenterDiv>
            )
        }
        return <></>
    }

    const renderPressure = () => {
        switch (tabName) {
            case WheelsAndTyresTabNames.FrontLeft:
                return renderFrontAxlePressure()
            case WheelsAndTyresTabNames.FrontRight:
                return renderFrontAxlePressure()
            case WheelsAndTyresTabNames.RearLeft:
                return renderRearAxlePressure()
            case WheelsAndTyresTabNames.RearRight:
                return renderRearAxlePressure()
            case WheelsAndTyresTabNames.SpareWheel:
                let tyreSpecification: TyreAxles | undefined

                tireSpecifications?.forEach((axleSpecifications) => {
                    tyreSpecification = axleSpecifications?.specifications?.find(
                        (specification) => (specification.season as number | undefined) === ExtendETyreSeason.spareTyre
                    )
                })

                if (tyreSpecification?.frontAxle?.pressure) {
                    return (
                        <CenterDiv>
                            <Typography variant="h4">{translateText(13656)}</Typography>
                            <Typography variant="h4">{`${selectedTyreSpecification?.frontAxle?.pressure} ${translateText(13666)}`}</Typography>
                        </CenterDiv>
                    )
                }
                return <></>
            default:
                return <></>
        }
    }

    const handleQuantityArticleChange = useCallback((articleId: string, quantity: number) => {
        worksActions.updateArticleQuantity(genArtItem.label, currentStepName, quantity, articleId)
    }, [])

    const handleOeArticleChange = useCallback((fsArticleId: string, quantity: number, price: number, designation: string, path: string) => {
        worksActions.updateOeArticle(path, currentStepName, quantity, price, designation, fsArticleId, "")
    }, [])

    const handleShowPartAlternatives = (genArt: Genart) => {
        subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce(
            "PARTS/REPLACE_PART",
            ({ part: replacePart, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.FastService) {
                    return true
                }

                worksActions.replaceArticle(genArt.label, currentStepName, (replacePart as Article).id, replacePart as Article, true)
                closeModalOrDrawer(isNewList)
            }
        )

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            uniProductGroups: {
                ids: [genArt.genArtNr],
            },
            replaceButtonMicro: EReplaceButtonBundle.FastService,
            forceReload: true,
            inModal: true,
        })
    }

    switch (collapsibleName) {
        case "general":
            return null
        case "tyrePressure":
            return (
                <StyledBox>
                    <TextField
                        variant="outlined"
                        placeholder={translateText(13081)}
                        onChange={(e) => handleCollapsibleData("tyrePressure", e.target.value)}
                        value={info.tyrePressure ?? ""}
                        inputRef={tyrePressureRef}
                        inputProps={{ inputMode: "numeric" }}
                        onKeyDown={handleEnter}
                    />
                    {renderPressure()}
                </StyledBox>
            )
        case "tyreDot":
            return (
                <StyledBox>
                    <TextField
                        variant="outlined"
                        placeholder={translateText(13082)}
                        value={info?.profileDepth ?? ""}
                        onChange={(e) => handleCollapsibleData("profileDepth", e.target.value)}
                        inputRef={profileDepthRef}
                        inputProps={{ inputMode: "numeric" }}
                        onKeyDown={hadleChangeFocusOnEnter}
                    />
                    <PaddingTextField
                        variant="outlined"
                        placeholder={translateText(13083)}
                        value={info?.dot ?? ""}
                        onChange={(e) => handleCollapsibleData("dot", e.target.value)}
                        inputProps={{ inputMode: "numeric" }}
                        inputRef={dotRef}
                        onKeyDown={handleEnter}
                    />
                </StyledBox>
            )
        case "rimCondition":
            return (
                <StyledBox>
                    <Typography variant="label">{translateText(13084)}:</Typography>
                    <PaddingButton
                        variant={info?.rimCondition === "aluminium" ? "outlined" : "contained"}
                        size="large"
                        onClick={() => {
                            handleCollapsibleData("rimCondition", "aluminium")
                        }}
                    >
                        {translateText(13085)}
                    </PaddingButton>
                    <PaddingButton
                        variant={info?.rimCondition === "steel" ? "outlined" : "contained"}
                        size="large"
                        onClick={() => {
                            handleCollapsibleData("rimCondition", "steel")
                        }}
                    >
                        {translateText(13086)}
                    </PaddingButton>
                </StyledBox>
            )
        case "brakeSystem":
            return (
                <DateField
                    size="xl"
                    useUtc
                    value={info.brakeSystem}
                    openToDate={info.brakeSystem || new Date()}
                    onChange={(e) => {
                        const dateObject = e instanceof Date ? e : new Date(`${e?.toString()}Z`)
                        handleCollapsibleData("brakeSystem", dateObject)
                    }}
                />
            )
        case "wholesaler":
            return (
                <CollapsibleWrapper icon={<Icon name="fast-calculator" color="primary" size="1.5em" />} textId={1667}>
                    {genArtItem?.article ? (
                        <PartItem
                            genArt={genArtItem}
                            article={genArtItem.article}
                            onQuantityArticleChange={handleQuantityArticleChange}
                            showPartAlternatives={handleShowPartAlternatives}
                            disableCheckToggle
                            enableOeReplace
                            enableReplaceWithoutFsArticle
                            selectedGenArts={[genArtItem]}
                        />
                    ) : (
                        <GenArt
                            selectedGenArt={genArtItem}
                            onOeArticleChange={handleOeArticleChange}
                            showPartAlternatives={handleShowPartAlternatives}
                        />
                    )}
                </CollapsibleWrapper>
            )
        default:
            return null
    }
}

export default CollapsibleContent

const CenterDiv = styled(Box)({
    display: "flex",
    flexDirection: "row",
    paddingLeft: "1.5em",
    paddingTop: "1em",
})

const PaddingButton = styled(Button)({
    marginLeft: ".5em",
})

const PaddingTextField = styled(TextField)({
    paddingLeft: ".5em",
})

const StyledBox = styled(Box)({
    display: "flex",
    alignItems: "center",
})
