import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { withStoreProvider } from "../../data/helpers"

const fastcalc: BundleComponent = {
    name: "fastcalc",
    component: withStoreProvider(component as any) as any,
}

export default fastcalc
