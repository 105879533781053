import { useLocalization } from "@tm/localization"
import { PriceType, CisVoucherType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { TableCellData, TableColumnData, CellContentPosition } from "@tm/components"
import { VoucherItem } from "../../../../../data/model"
import ArticleNumberCell from "../../../../_shared/cells/ArticleNumberCell"
import ArticleDescriptionCell from "../../../../_shared/cells/ArticleDescriptionCell"
import { StyledTable } from "../../../../_shared/StyledComponents"
import PriceCell from "../../../../_shared/cells/PriceCell"
import TaxesCell from "../../../../_shared/cells/TaxesCell"
import VoucherItemOptionsCell from "../../../../_shared/cells/VoucherItemOptionsCell"
import { NoResultHint } from "../../../../_shared/NoResultHint"

type Props = {
    currencyCode?: string
    voucherItems: Array<VoucherItem>
    voucherTypeId?: number
}

export default function StgOrderItemsTableComponent({ voucherTypeId, voucherItems, currencyCode }: Props) {
    const { translateText, currency } = useLocalization()
    const { userSettings } = useUser() ?? {}

    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(370) },
            { header: translateText(476) },
            { header: translateText(477) },
            { header: translateText(25) },
            { header: translateText(voucherTypeId === CisVoucherType.ClosedOrders ? 782 : 89), alignContent: CellContentPosition.center },
            { header: translateText(1396), alignContent: CellContentPosition.center },
            { header: translateText(783), alignContent: CellContentPosition.center },
            { header: translateText(56), alignContent: CellContentPosition.right },
        ]

        if (!hidePurchasePrice) {
            columns.push(
                { header: translateText(54), alignContent: CellContentPosition.right },
                { header: translateText(731), alignContent: CellContentPosition.right },
                { header: translateText(495), alignContent: CellContentPosition.right }
            )
        }

        if (voucherItems.some((item) => !!item.totalGross)) {
            columns.push({ header: translateText(494), alignContent: CellContentPosition.right })
        }
        columns.push({ header: translateText(478), alignContent: CellContentPosition.right }, {})

        return columns
    }

    function getCells(voucherItem: VoucherItem) {
        const cellData: TableCellData[] = [
            { displayValue: voucherItem.positionNumber, id: "item_0" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="wholesaler" />, id: "item_1" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="supplier" />, id: "item_2" },
            { displayValue: <ArticleDescriptionCell articleDescription={voucherItem.articleDescription} />, id: "item_3" },
            { displayValue: `${voucherItem.quantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_4" },
            { displayValue: `${voucherItem.deliveryQuantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_5" },
            { displayValue: `${voucherItem.additionalDeliveryQuantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_6" },
            { displayValue: <PriceCell priceType={PriceType.Retail} prices={voucherItem.prices} />, id: "item_7" },
        ]

        if (!hidePurchasePrice) {
            cellData.push(
                { displayValue: voucherItem.discount ? `${voucherItem.discount}%` : "", id: "item_8" },
                { displayValue: <PriceCell priceType={PriceType.Purchase} prices={voucherItem.prices} />, id: "item_9" },
                { displayValue: currency(voucherItem.totalNet || 0, currencyCode || voucherItem.currencyCode), id: "item_10" }
            )
        }

        if (voucherItems.some((item) => !!item.totalGross)) {
            cellData.push({
                displayValue: currency(voucherItem.totalGross || 0, currencyCode || voucherItem.currencyCode),
                id: "item_11",
            })
        }
        cellData.push(
            { displayValue: <TaxesCell taxes={voucherItem.taxes} />, id: "item_12" },
            {
                displayValue: (
                    <VoucherItemOptionsCell
                        item={voucherItem}
                        showNote={voucherTypeId === CisVoucherType.OpenOrders || voucherTypeId === CisVoucherType.ClosedOrders}
                    />
                ),
                id: "item_13",
            }
        )
        return cellData
    }

    const displayData = voucherItems.map((voucherItem, index) => ({
        cells: getCells(voucherItem),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} headerBackground="paper" />
}
