import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import deepAssign from "deep-assign"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, margin, opacity, pageNavigation } = getStyleTheme()
    const component = {
        back: "transparent",
        fore: color(pageNavigation?.itemColor || colors.light)
            .setAlpha(opacity.secondary)
            .toRgbString(),
        hover: {
            back: color(colors.light).setAlpha(0.15).toRgbString(),
            fore: color(pageNavigation?.itemHoverColor || colors.light)
                .setAlpha(opacity.primary)
                .toRgbString(),
        },
        active: {
            back: colors.primary,
            fore: colors.light,
        },
        more: {
            fore: colors.dark,
        },
        border: {
            color: "transparent",
        },
    }

    const themeStyles: NestedCSSProperties = {
        backgroundColor: component.back,
        color: component.fore,
        borderLeftColor: component.border.color,
        $nest: {
            "&__close": {
                display: "flex",
                marginLeft: ".3em",
                padding: 0,
                background: "none",
                $nest: {
                    ".icon": {
                        opacity: "1 !important",
                        height: "1.5em",
                        width: "1.5em",
                        fill: component.fore,
                    },
                    "&:hover, &:active": {
                        background: "none",
                    },
                    "&:hover .icon": {
                        fill: `${color(component.fore).setAlpha(0.54).toRgbString()} !important`,
                    },
                },
            },
            "&--selected, &:active": {
                backgroundColor: component.active.back,
                color: component.active.fore,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
                borderLeftColor: "transparent",
            },
            "&--selected": {
                transform: "translateY(0.65rem)",
                $nest: {
                    "&:hover .icon, &:hover .icon:hover": {
                        fill: `${color(component.active.fore).setAlpha(0.87).toRgbString()} !important`,
                    },
                },
            },
            "&--selected .icon, &:active .icon": {
                fill: component.active.fore,
            },
            "&--selected .plate, &:active .plate": {
                opacity: opacity.primary,
            },
            "&:not(&--selected)": {
                borderLeftColor: component.border.color,
            },
            "&:hover:not(&--selected)": {
                backgroundColor: component.hover.back,
                borderLeftColor: component.border.color,
                color: component.hover.fore,
                $nest: {
                    ".icon": {
                        fill: component.fore,
                    },
                    ".plate": {
                        opacity: opacity.primary,
                    },
                },
            },
            "&--more": {
                display: "flex",
                padding: margin.m,
                color: component.more.fore,
                opacity: opacity.secondary,
            },
            "&--more:hover:not(&--selected)": {
                color: component.more.fore,
                opacity: 1,
                background: component.hover.back,
                $nest: {
                    ".icon": {
                        fill: `${component.more.fore} !important`,
                    },
                },
            },
            "&--more &__close .icon": {
                fill: component.more.fore,
                opacity: 1,
            },
            "&--more &__content": {
                flex: 1,
            },
            "&:last-child": {
                borderRight: `2px solid ${component.border.color}`,
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
