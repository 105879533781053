import { useEffect, useRef } from "react"
import { useLocalization } from "@tm/localization"
import { getStorage } from "@tm/utils"
import { Button, WarningPrompt } from "@tm/controls"
import { channel, ConfigParams, IMicros } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { DeletionPrompt } from "./DeletionPrompt"
import { setWorkTaskCompleted } from "../../data"

type Props = IMicros["worktask"]["complete-work-task-button"]

export const LOCAL_STORAGE_KEY = "WORKTASK_DELETE_DIALOG_DEACTIVATED"
const MAX_LOCAL_STORAGE_ENTRY_AGE = 60 * 60 * 24 * 1000

export function CompleteWorkTaskButtonComponent({ workTaskId, onWorkTaskCompleted, onPromptToggle }: Props) {
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const { translateText } = useLocalization()

    const deletionPromptRef = useRef<WarningPrompt>(null)

    useEffect(() => {
        const localStorageEntry = getStorage("localStorage").getItem(LOCAL_STORAGE_KEY)
        /* delete localstorage entry after 24h if the user reload the page -> NEXT-15870 */
        if (localStorageEntry && +localStorageEntry < Date.now() - MAX_LOCAL_STORAGE_ENTRY_AGE) {
            getStorage("localStorage").removeItem(LOCAL_STORAGE_KEY)
        }
    }, [])

    async function deleteWorkTask(dontAskAgain: boolean) {
        if (dontAskAgain) {
            setLocalStorageKey()
        }
        await setWorkTaskCompleted(workTaskId)
        channel("GLOBAL").publish("WORKTASK/DELETED", { id: workTaskId })
        onWorkTaskCompleted?.()
        onPromptToggle?.(false)
        channel("WORKTASK", workTaskId).publish("WORKTASK/CLOSE", undefined)
    }

    function handleDeleteWorkTask(e: Event): void {
        e.stopPropagation()
        e.preventDefault()

        const dontAskAgain = getStorage("localStorage").getItem(LOCAL_STORAGE_KEY)

        if (!dontAskAgain) {
            deletionPromptRef.current?.show()
            onPromptToggle?.(true)
        } else {
            deleteWorkTask(false)
        }
    }

    function setLocalStorageKey() {
        getStorage("localStorage").setItem(LOCAL_STORAGE_KEY, `${Date.now()}`)
    }

    function handleDialogCancellation(dontAskAgain: boolean) {
        if (dontAskAgain) {
            setLocalStorageKey()
        }
        onPromptToggle?.(false)
    }

    return (
        <>
            <Button icon="delete" onClick={handleDeleteWorkTask} title={translateText(69)} skin={deleteButtonColorError ? "danger" : undefined} />
            <DeletionPrompt ref={deletionPromptRef} handleConfirmation={deleteWorkTask} handleCancellation={handleDialogCancellation} />
        </>
    )
}
