import * as React from "react"
import { Icon, Text, Availability } from "@tm/controls"
import { ErpPrice, PriceType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Tooltip } from "@tm/components"
import { getCurrencyFromUserContext } from "../../data/helpers"
import { ErpData } from "../../data/model"

type Props = {
    erpData: ErpData
}

const ArticleErp: React.FC<Props> = ({ erpData }) => {
    const userContext = useUser()?.userContext
    const { currency } = useLocalization()

    const renderPrice = (price: ErpPrice & { priceCode: number }, idex: number) => {
        if (!price) {
            return
        }
        const currencyString = getCurrencyFromUserContext(userContext)

        const modifiers: Array<"strong" | "highlight" | "strikethrough" | "danger"> = ["strong"]
        let size: "s" | "xs" | undefined

        if (price.priceCode === PriceType.Purchase) {
            modifiers.push("highlight")
            size = "s"
        }

        return (
            <div key={idex} className="price">
                <Text className="price" modifiers={modifiers} size={size}>
                    {currency(price.value ?? 0, currencyString)}
                </Text>
                <Tooltip title={price.description}>
                    <Icon name="info" className="price__icon" />
                </Tooltip>
            </div>
        )
    }

    return (
        <div className="tk-erp">
            <div className="erp-info">
                {!!erpData?.prices?.length && (
                    <div className="erp-info__prices">
                        <div className="price__group">{erpData.prices.map(renderPrice)}</div>
                    </div>
                )}

                {erpData?.availState && (
                    <div className="erp-info__availability">
                        <div className="erp-info__availability__wrapper">
                            <Availability
                                status={erpData.availState.availstate ?? 0}
                                imageUrl={erpData.availState.availIconUrl}
                                indicatorPosition="top"
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ArticleErp
