import { useUser, useWorkTask } from "@tm/context-distribution"
import { Headline, Icon, Text, Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { SystemType } from "@tm/models"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import * as React from "react"
import { useDispatch, useSelector } from "react-redux"
import { vehicleSelector } from "../../data/helpers"
import { MainState } from "../main"
import { Actions } from "./business"
import { ContactWheels } from "../_shared"
import { Loader } from "@tm/components"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps & {
    vehicleSelectionRoute: string
}

const LoadingScreen: React.FC<Props> = ({ vehicleSelectionRoute, match, history }) => {

    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const userContext = useUser()?.userContext

    const vehicle = useSelector(vehicleSelector)
    const workTaskVehicle = useWorkTask()?.workTask?.vehicle
    const latestVehicleUsed = useSelector((s: MainState) => s.loadingScreen.latestVehicleUsed)
    const isHostettler = getBundleParams()?.isHostettler

    const { error } = useSelector((s: MainState) => s.loadingScreen)

    React.useEffect(() => {
        if (vehicle && vehicle.id != latestVehicleUsed) {
            dispatch(Actions.loadTpmsArticle(vehicle))
        }
    }, [vehicle])

    React.useEffect(() => {
        if (vehicle && (userContext.system.systemType == SystemType.Redesign || vehicle.id == workTaskVehicle?.id)) {
            dispatch(Actions.loadCarPark(vehicle))
        }
    }, [vehicle?.id, workTaskVehicle?.id])

    const handleVehicleRedirect = () => {
        const vehicleSelectUrl = renderRoute(vehicleSelectionRoute, { ...match.params }) + `/${vehicle?.vehicleType}`
        history.push(vehicleSelectUrl)
    }

    if (error) {
        return (
            <div className="loading-screen">
                <Text>{translateText(323)}</Text>
                {userContext?.system.systemType == SystemType.Next &&
                    <Widget
                        iconName="vehiclesearch"
                        title={translateText(99)}
                        highlight indicator
                        className="vehicle-widget"
                        onClick={handleVehicleRedirect}
                    />
                }
                {isHostettler && <ContactWheels />}
            </div>
        )
    }

    return (
        <div className="loading-screen">
            <Icon name="wheel-and-car" />
            <Headline>{translateText(608)}</Headline>
            <Loader style={{ flex: 0 }} />
        </div>
    )
}

export default withRouter(LoadingScreen)