import { useLocalization } from "@tm/localization"
import { copyToClipboard } from "@tm/utils"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

export function useCopy() {
    const { translateText } = useLocalization()
    const [isLinkCopied, setIsLinkCopied] = useState(false)
    const linkCopiedTimeoutRef = useRef(0)

    const copyButtonText = useMemo(() => {
        if (isLinkCopied) {
            return translateText(13296)
        }

        return translateText(12785)
    }, [isLinkCopied, translateText])

    useEffect(() => {
        return () => {
            if (linkCopiedTimeoutRef.current) {
                window.clearTimeout(linkCopiedTimeoutRef.current)
            }
        }
    }, [])

    const handleCopyLink = useCallback(async (msg: string) => {
        if (!msg) {
            return
        }

        try {
            await copyToClipboard(msg)

            setIsLinkCopied(true)

            if (linkCopiedTimeoutRef.current) {
                window.clearTimeout(linkCopiedTimeoutRef.current)
            }

            linkCopiedTimeoutRef.current = window.setTimeout(() => setIsLinkCopied(false), 2000)
            // eslint-disable-next-line no-empty
        } catch {}
    }, [])

    return {
        copyToClipboard: handleCopyLink,
        isLinkCopied,
        buttonText: copyButtonText,
    }
}
