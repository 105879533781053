import { Suspense, useCallback, useState } from "react"
import { WorkTaskInfo, useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { Button } from "@tm/components"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import BasketClearDialog from "../ClearBasketDialog"

type Props = {
    workTask: WorkTaskInfo
}

function CloseOrAbortWorktask({ workTask }: Props) {
    const { translateText } = useLocalization()
    const { basket } = useWorkTaskBasketState(workTask.id)

    const [showClearBasketDialog, setShowBasketClearDialog] = useState(false)

    const clearBasket = useCallback(() => {
        setShowBasketClearDialog(false)

        const partIds: Array<string> = []
        basket.state.basketOrderGroups?.forEach((basketOrderGroup) => {
            basketOrderGroup.basketParts.forEach((part) => {
                if (part.partItem.orderItem?.isIncluded) {
                    partIds.push(part.partItem.id)
                }
            })
        })
        if (partIds.length) {
            basket.actions.removeParts(partIds)
        }
    }, [basket.actions, basket.state.basketOrderGroups])

    function handleExternalOrder() {
        clearBasket()
        channel("WORKTASK", workTask.id).publish("BASKET/EXTERNAL_ORDER_SENT", { isPartialOrder: false })
    }

    function handleAbortWorktask() {
        clearBasket()
        channel("WORKTASK", workTask.id).publish("WORKTASK/ABORT", {})
    }

    return (
        <Toolbar className="tk-basket order-button">
            <Button size="large" onClick={() => setShowBasketClearDialog(true)} disabled={basket.state.basketLoading}>
                {translateText(333)}
            </Button>
            <BasketClearDialog
                showClearBasketDialog={showClearBasketDialog}
                onCloseDialog={handleExternalOrder}
                onConfirmDialog={handleAbortWorktask}
            />
        </Toolbar>
    )
}

export default function Wrapper() {
    const { workTask } = useWorkTask() ?? {}

    return <Suspense fallback={null}>{workTask && <CloseOrAbortWorktask workTask={workTask} />}</Suspense>
}
