import { DamageCalculationDatBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"
import { initRepositories } from "./data/repositories"

export const version = {
    name: "dmg-dat",
    version: "2.0",
}

let bundleParams: DamageCalculationDatBundleParams

export function initDamageCalculationDatBundle(params: DamageCalculationDatBundleParams) {
    bundleParams = params

    checkBundleParams(params)
    initRepositories()
    return bundleParams
}

export function getBundleParams(): DamageCalculationDatBundleParams {
    if (!bundleParams) {
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}

const checkBundleParams = (params: DamageCalculationDatBundleParams) => {
    if (!isDevtoolEnabled()) {
        return
    }

    if (!params.vinPickerUrl) {
        console.warn(`${version.name}: The parameter 'vinPickerUrl' has to be set in the configuration`)
    }

    if (!params.gsiServiceUrl) {
        console.warn(`${version.name}: The parameter 'gsiServiceUrl' has to be set in the configuration`)
    }

    if (!params.datCalculationServiceUrl) {
        console.warn(`${version.name}: The parameter 'datCalculationServiceUrl' has to be set in the configuration`)
    }

    if (!params.datCustomerUrl) {
        console.warn(`${version.name}: The parameter 'datCustomerUrl' has to be set in the configuration`)
    }

    if (!params.datRegistrationPath) {
        console.warn(`${version.name}: The parameter 'datRegistrationPath' has to be set in the configuration`)
    }

    if (!params.businessUrl) {
        console.warn(`${version.name}: The parameter 'businessUrl' has to be set in the configuration`)
    }

    if (!params.vrmLookupServiceUrl) {
        console.warn(`${version.name}: The parameter 'vrmLookupServiceUrl' has to be set in the configuration`)
    }

    if (!params.carsServiceUrl) {
        console.warn(`${version.name}: The parameter 'carsServiceUrl' has to be set in the configuration`)
    }
}
