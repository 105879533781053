import { VoucherType } from "@tm/models"
import VoucherTypeSelector, { VoucherTypeSelectItem } from "../../../../_shared/VoucherTypeSelector"

type Props = {
    voucherType: VoucherType
    onVoucherTypeChanged(entry: VoucherTypeSelectItem): void
}

export default function Areas({ voucherType, onVoucherTypeChanged }: Props) {
    return <VoucherTypeSelector onVoucherTypeChanged={onVoucherTypeChanged} selectedVoucherType={voucherType} size="medium" variant="outlined" />
}
