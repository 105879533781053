import { memo, useEffect, useState } from "react"
import { Box, CellContentPosition, Table, TableProps, TableRowData, Skeleton, Select, MenuItem } from "@tm/components"
import { WorkTaskInfo } from "@tm/context-distribution"
import { NumberField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { AddCustomWorkListRequest, CostEstimationOptions, WorkCategory } from "@tm/models"
import { getCategoryOfWorkDescriptionTextId, useVatRateText } from "@tm/utils"
import { CustomRepairTimeItem, EditCustomRepairTimeItemRequest } from "../../../../../data/model/custom-items"
import { useEditCustomArticleItem } from "../../../../../data/hooks/custom-basket-items/useCustomRepairTimes"
import { CustomRepairTimesActions } from "./CustomRepairTimesActions"
import { EditorButtons } from "../../custom-articles/components/EditorButtons"
import { VatRateSelectionItem } from "../../../../../data/model"
import { VatRateSelector } from "../../../../VatRateSelector"
import { useWorkCategoryItems } from "../../../../../hooks/useWorkCategoryItems"

type Props = {
    currencySymbol?: string
    costEstimationOptions?: CostEstimationOptions
    vatRates?: VatRateSelectionItem[]
    hourlyRates: Record<WorkCategory, number>
    loading: boolean
    repairTimeDivision: number
    repairTimes: CustomRepairTimeItem[]
    selectedRepairTimeIds: string[]
    showRepairTimesInHours: boolean
    workTask: WorkTaskInfo
    onLoadMoreRepairTimes(): void
    onToggleRepairTime(repairTime: CustomRepairTimeItem): void
    onAddCustomWorks(request: AddCustomWorkListRequest): Promise<unknown>
}

export const CustomRepairTimesList = memo(
    ({
        workTask,
        repairTimes,
        repairTimeDivision,
        showRepairTimesInHours,
        vatRates,
        currencySymbol,
        hourlyRates,
        loading,
        selectedRepairTimeIds,
        costEstimationOptions,
        onAddCustomWorks,
        onLoadMoreRepairTimes,
        onToggleRepairTime,
    }: Props) => {
        const { translateText, currency, number } = useLocalization()
        const { vatRateTypeDisplayShortText } = useVatRateText(translateText)
        const rates = Object.values(hourlyRates)
        const workCategoryItems = useWorkCategoryItems(hourlyRates)

        const [editedRepairTime, setEditedRepairTime] = useState<CustomRepairTimeItem>()
        const { mutateAsync: saveCustomRepairTime } = useEditCustomArticleItem()

        useEffect(() => {
            if (editedRepairTime && vatRates) {
                const defaultVatRate = vatRates[0]

                if (!editedRepairTime.vatRate) {
                    setEditedRepairTime((prev) =>
                        prev
                            ? {
                                  ...prev,
                                  vatRate: {
                                      vatRate: defaultVatRate.vatValue,
                                      vatType: defaultVatRate.vatType,
                                  },
                              }
                            : prev
                    )
                }
            }
        }, [editedRepairTime, vatRates])

        const handleEditClick = (repairTime: CustomRepairTimeItem | undefined) => {
            setEditedRepairTime(repairTime)

            if (repairTime && selectedRepairTimeIds.includes(repairTime.id)) {
                onToggleRepairTime(repairTime)
            }
        }

        const handleSaveRepairTimeInformation = async (): Promise<void> => {
            if (editedRepairTime) {
                const request: EditCustomRepairTimeItemRequest = {
                    customRepairTimeToEdit: { ...editedRepairTime },
                    repairTimeDivision,
                    priceVatMode: costEstimationOptions?.priceVatMode,
                }
                await saveCustomRepairTime(request)
            }
        }

        const handleFixedPriceValueChange = (repairTime: CustomRepairTimeItem) => {
            setEditedRepairTime({ ...repairTime, workTime: undefined, customHourlyRate: undefined })
        }

        const handleHourlyRateOrWorkTimeChange = (repairTime: CustomRepairTimeItem) => {
            setEditedRepairTime({ ...repairTime, fixedPriceValue: undefined })
        }

        const handleWorkCategoryChange = (category: WorkCategory) => {
            const newHourlyRate = rates[category] ?? rates[WorkCategory.NotCategorized]
            setEditedRepairTime((prev) =>
                prev
                    ? {
                          ...prev,
                          category,
                          customHourlyRate: newHourlyRate,
                          fixedPriceValue: undefined,
                      }
                    : prev
            )
        }

        function handleSelectVatRateType(newVatRate: VatRateSelectionItem) {
            setEditedRepairTime((prev) =>
                prev
                    ? {
                          ...prev,
                          vatRate: {
                              vatRate: newVatRate.vatValue,
                              vatType: newVatRate.vatType,
                          },
                      }
                    : prev
            )
        }

        const renderNumber = (repairTime: CustomRepairTimeItem) =>
            repairTime.id === editedRepairTime?.id ? (
                <TextField model={editedRepairTime} path={["workId"]} onChange={setEditedRepairTime} />
            ) : (
                repairTime.workId
            )

        const renderDescription = (repairTime: CustomRepairTimeItem) =>
            repairTime.id === editedRepairTime?.id ? (
                <TextField model={editedRepairTime} path={["description"]} onChange={setEditedRepairTime} />
            ) : (
                repairTime.description
            )

        const renderCategory = (repairTime: CustomRepairTimeItem) =>
            repairTime.id === editedRepairTime?.id ? (
                <Select value={editedRepairTime.category} onChange={(e) => handleWorkCategoryChange(e.target.value as WorkCategory)} size="large">
                    {workCategoryItems.map((item, idx) => {
                        return (
                            <MenuItem key={idx} value={item.category}>
                                {translateText(item.textId)}
                            </MenuItem>
                        )
                    })}
                </Select>
            ) : (
                translateText(getCategoryOfWorkDescriptionTextId(repairTime.category))
            )

        const renderHourlyRate = (repairTime: CustomRepairTimeItem) =>
            repairTime.id === editedRepairTime?.id ? (
                <NumberField
                    model={editedRepairTime}
                    path={["customHourlyRate"]}
                    onChange={handleHourlyRateOrWorkTimeChange}
                    minimum={0.0}
                    maximum={999}
                    stepSize={0.01}
                    nullable
                />
            ) : (
                repairTime.customHourlyRate && currency(repairTime.customHourlyRate, currencySymbol || "")
            )

        const renderWorkTime = (repairTime: CustomRepairTimeItem) =>
            repairTime.id === editedRepairTime?.id ? (
                <NumberField
                    model={editedRepairTime}
                    path={["workTime"]}
                    onChange={handleHourlyRateOrWorkTimeChange}
                    minimum={0.0}
                    maximum={999}
                    stepSize={0.01}
                    nullable
                />
            ) : (
                repairTime.workTime && number(repairTime.workTime, 2)
            )

        const renderVat = (repairTime: CustomRepairTimeItem) =>
            repairTime.id === editedRepairTime?.id && vatRates ? (
                <VatRateSelector
                    vatType={repairTime.vatRate?.vatType}
                    vatRates={vatRates}
                    onChange={(newVatRate) => handleSelectVatRateType(newVatRate)}
                />
            ) : (
                !!repairTime.vatRate && vatRateTypeDisplayShortText(repairTime.vatRate)
            )

        const renderPrice = (repairTime: CustomRepairTimeItem) => {
            if (repairTime.id === editedRepairTime?.id) {
                return (
                    <NumberField
                        model={editedRepairTime}
                        path={["fixedPriceValue"]}
                        onChange={handleFixedPriceValueChange}
                        minimum={0.0}
                        maximum={99999}
                        stepSize={0.01}
                        nullable
                    />
                )
            }

            let price
            if (repairTime.fixedPriceValue) {
                price = repairTime.fixedPriceValue
            } else if (repairTime.workTime && repairTime.customHourlyRate) {
                price = (repairTime.workTime * repairTime.customHourlyRate) / repairTimeDivision
            }

            return !!price && currency(price, currencySymbol || "")
        }

        const renderActions = (repairTime: CustomRepairTimeItem) => {
            if (repairTime.id === editedRepairTime?.id) {
                return (
                    <EditorButtons
                        canSave={
                            (!!editedRepairTime.workId || !!editedRepairTime.description) &&
                            (!!editedRepairTime.fixedPriceValue || (!!editedRepairTime.customHourlyRate && !!editedRepairTime.workTime))
                        }
                        onSave={handleSaveRepairTimeInformation}
                        onAbort={() => handleEditClick(undefined)}
                    />
                )
            }

            return (
                <CustomRepairTimesActions
                    workTask={workTask}
                    repairTime={repairTime}
                    repairTimeDivision={repairTimeDivision}
                    onEditClick={handleEditClick}
                    isSelected={selectedRepairTimeIds.includes(repairTime.id)}
                    onToggleSelection={onToggleRepairTime}
                    onAddCustomWorks={onAddCustomWorks}
                    costEstimationOptions={costEstimationOptions}
                />
            )
        }

        const tableData: TableProps = {
            columns: [
                { header: translateText(58) }, // Number/id
                { header: translateText(25) }, // description
                { header: translateText(59) }, // Category
                { header: translateText(63), alignContent: CellContentPosition.right }, // Hourly Rate
                { header: translateText(showRepairTimesInHours ? 84 : 1550), alignContent: CellContentPosition.right }, // Work time
                { header: translateText(57), alignContent: CellContentPosition.right }, // Price
                { header: translateText(12807), alignContent: CellContentPosition.right }, // Vat
                { alignContent: CellContentPosition.right }, // Actions
            ],
            rows: repairTimes.map(
                (repairTime): TableRowData => ({
                    id: repairTime.id,
                    cells: [
                        { displayValue: renderNumber(repairTime), id: "1" },
                        { displayValue: renderDescription(repairTime), id: "2" },
                        { displayValue: renderCategory(repairTime), id: "3" },
                        { displayValue: renderHourlyRate(repairTime), id: "4" },
                        { displayValue: renderWorkTime(repairTime), id: "5" },
                        { displayValue: renderPrice(repairTime), id: "6" },
                        { displayValue: renderVat(repairTime), id: "7" },
                        { displayValue: renderActions(repairTime), id: "8" },
                    ],
                    active: repairTime.id === editedRepairTime?.id,
                })
            ),
        }

        return (
            <Box maxHeight={500}>
                <Table overflowY="auto" {...tableData} onScrollBottom={onLoadMoreRepairTimes} />
                {loading && <Skeleton height={54} width="100%" />}
            </Box>
        )
    }
)
