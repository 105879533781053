import { Toolbar, Switch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useDispatch, useSelector } from "react-redux"
import { MainState } from "../main"
import { BundleActions } from "../../business"
import { getBundleParams } from "../../utils"

const MountingSwitch = () => {
    const { translateText } = useLocalization()

    const dispatch = useDispatch()
    const isHostettler = getBundleParams()?.isHostettler

    const { mountingEnabled } = useSelector((s: MainState) => ({
        mountingEnabled: s.overview.mountingEnabled,
    }))

    const handleChange = (includeMount: boolean) => {
        dispatch(BundleActions.setMountingSwitchValue(includeMount))
    }

    const canShowSwitch = (): boolean => {
        return isHostettler || false
    }

    return (
        <>
            {canShowSwitch() && <Toolbar className="tk-parts switch mounting-switch" title={translateText(13056)}>
                <Switch
                    status={mountingEnabled}
                    onChange={handleChange}
                    alignLabel="left"
                />
            </Toolbar>}
        </>
    )
}

export default MountingSwitch