import { useUser } from "@tm/context-distribution"
import { UserModuleType, WorkCategory } from "@tm/models"
import { Dictionary } from "@tm/utils"

type WorkCategoryItemViewProps = {
    category: WorkCategory
    textId: number
    hourlyRate: number
}

export function useWorkCategoryItems(hourlyRates: Dictionary<number>) {
    const { userContext } = useUser() || {}

    const defaultHourlyRate = hourlyRates[WorkCategory.NotCategorized]
    const items: Array<WorkCategoryItemViewProps> = [
        { category: WorkCategory.WorkshopWork, textId: 248, hourlyRate: hourlyRates[WorkCategory.WorkshopWork] || defaultHourlyRate },
        { category: WorkCategory.BodyWork, textId: 249, hourlyRate: hourlyRates[WorkCategory.BodyWork] || defaultHourlyRate },
        { category: WorkCategory.AccessoryWork, textId: 250, hourlyRate: hourlyRates[WorkCategory.AccessoryWork] || defaultHourlyRate },
        { category: WorkCategory.PaintWork, textId: 251, hourlyRate: hourlyRates[WorkCategory.PaintWork] || defaultHourlyRate },
        { category: WorkCategory.ElectricalWork, textId: 252, hourlyRate: hourlyRates[WorkCategory.ElectricalWork] || defaultHourlyRate },
        { category: WorkCategory.ElectronicWork, textId: 253, hourlyRate: hourlyRates[WorkCategory.ElectronicWork] || defaultHourlyRate },
        { category: WorkCategory.Diagnose, textId: 12981, hourlyRate: hourlyRates[WorkCategory.Diagnose] || defaultHourlyRate },
    ]

    const ereModule = userContext?.modules?.find((x) => x.type === UserModuleType.TMDamageCalculationEurotax)
    if (ereModule) {
        items.push(
            { category: WorkCategory.SaddlerWork, textId: 929, hourlyRate: hourlyRates[WorkCategory.SaddlerWork] || defaultHourlyRate },
            { category: WorkCategory.SmartRepair, textId: 930, hourlyRate: hourlyRates[WorkCategory.SmartRepair] || defaultHourlyRate }
        )
    }

    return items
}
