import { WheelSelectionSteps } from "../enums"
import { NavigationItem, Color } from "../model"

export const tabs: NavigationItem[] = [
    { path: 'VehicleInfo', translationId: 931 },
    { path: 'SeriesTires', translationId: 726 },
    { path: 'Rdks', translationId: 932 }
]

export const mountingPadsTabs: NavigationItem[] = [
    { path: 'TiresRestrictions', translationId: 965 },
    { path: 'RimsRestrictions', translationId: 966 }
]

export const colorsList: Color[] = [
    {
        name: 'cavansitblau',
        code: '324766'
    },
    {
        name: 'nordlichtviolett',
        code: '5f4785'
    },
    {
        name: 'universumblau',
        code: '88a6d5'
    },
    {
        name: 'mountaingrau',
        code: '8a91a1'
    },
    {
        name: 'polarsilber',
        code: '414347'
    },
    {
        name: 'orientbraun',
        code: '383420'
    },
    {
        name: 'jupiterrot',
        code: 'd23344'
    },
    {
        name: 'zirrusweiss',
        code: 'ffffff'
    }
]

export const Steps: StepsArray[] = [
    { id: WheelSelectionSteps.CARSELECTION, current: "vehicle-selection", title: 862, tooltipTitle: 901 },
    { id: WheelSelectionSteps.WHEELSLIST, current: "wheels-list", title: 863, tooltipTitle: 902 },
    { id: WheelSelectionSteps.WHEELDETAILS, current: "wheels-info", title: 1130, tooltipTitle: 903 },
    { id: WheelSelectionSteps.RDKSLIST, current: "rdks-list", title: 865, tooltipTitle: 904 },
    { id: WheelSelectionSteps.RDKSDETAILS, current: "sensor-details", title: 866, tooltipTitle: 905 },
    { id: WheelSelectionSteps.TIRESLIST, current: "tyres-list", title: 867, tooltipTitle: 906 },
    { id: WheelSelectionSteps.TIREDETAILS, current: "tyres-details", title: 868, tooltipTitle: 907 },
    { id: WheelSelectionSteps.OVERVIEW, current: "overview", title: 409, tooltipTitle: 908 }
]

export type StepsArray = { id: WheelSelectionSteps, current: string, title: number, tooltipTitle: number }