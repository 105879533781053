import { Box, Stack, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    note: string
}

export default function PartNotes({ note }: Props) {
    const { translateText } = useLocalization()

    const NoteBox = styled(Box, { shouldForwardProp: (prop) => prop !== "height" })<{ height?: string }>(({ height, theme }) => ({
        border: `1px solid "#e2e2e1"`,
        borderRadius: theme.radius?.default ?? "3px",
        backgroundColor: theme.colors?.paperBackground ?? "#FAFAFA",
        padding: "2px 5px",
        height: height ?? "41px",
    }))

    return (
        <Stack direction="row" spacing={1} alignItems="center" flex={1}>
            <Typography variant="label">{translateText(39)}</Typography>
            <NoteBox display="flex" flexGrow={1} alignItems="center">
                <Typography>{note}</Typography>
            </NoteBox>
        </Stack>
    )
}
