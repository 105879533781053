import { CisElectronicAddress } from "@tm/models"
import { Typography } from "@tm/components"
import Field from "./Field"

type Props = {
    electronicAddress: CisElectronicAddress
}

export default function ElectronicAddressComponent({ electronicAddress }: Props) {
    const { description, value } = electronicAddress
    return (
        <Field title={description || ""}>
            <Typography>{value}</Typography>
        </Field>
    )
}
