import { isSameDay } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { ItemTour } from "@tm/models"
import { Stack, Table, TableCellData, TableColumnData } from "@tm/components"

export type SimpleTourTableData = {
    title: string
    tours: ItemTour[]
}

type Props = {
    data: SimpleTourTableData[]
    transparentBackground?: boolean
    whiteText?: boolean
    transparentHighlight?: boolean
}

export default function SimpleTourTable({ data, transparentBackground, whiteText, transparentHighlight }: Props) {
    const { date, translateText } = useLocalization()

    function getTourDescription(tour: ItemTour): string {
        if (tour.tourStart) {
            const weekday = isSameDay(tour.tourStart, new Date()) ? translateText(142) : date(tour.tourStart, "dddd")
            return `${date(tour.tourStart, "g")} : ${weekday}`
        }
        if (tour.tourOrderAcceptanceTimeLimit) {
            return `${translateText(1129)} ${date(tour.tourOrderAcceptanceTimeLimit, "g")}`
        }
        return tour.description || translateText(1143)
    }

    function getCells(tour: ItemTour): TableCellData[] {
        return [
            { displayValue: tour.deliveredQuantity || 0, id: "tour_1" },
            { displayValue: getTourDescription(tour), id: "tour_2" },
        ]
    }

    function getColumns(item: SimpleTourTableData): TableColumnData[] {
        return [{ header: translateText(89) }, { header: item.title }]
    }

    function renderTable(item: SimpleTourTableData, index: number) {
        if (!item.tours?.length) {
            return null
        }

        const displayData = item.tours.map((tour, index) => ({
            cells: getCells(tour),
            id: `${index}`,
            customRow: false,
            active: false,
        }))

        return (
            <Table
                key={index}
                variant="small"
                columns={getColumns(item)}
                rows={displayData}
                overflowY="auto"
                rowCap={0}
                headerBackground={transparentBackground ? "transparent" : "paper"}
                rowStyle="transparent"
                textContrast={whiteText ? "white" : "black"}
                transparentHighlight={transparentHighlight}
            />
        )
    }

    if (!data?.length) {
        return null
    }

    return (
        <Stack spacing={1}>
            {data.map((tourData, index) => {
                return renderTable(tourData, index)
            })}
        </Stack>
    )
}
