import { ReactElement, useState } from "react"
import { Box, Button, Icon, Typography, styled, Image, ReplaceButton } from "@tm/components"
import { useLocalization } from "@tm/localization"
import ArticleCell from "../../_shared/articleCell"
import QuantityDropdown from "../../_shared/QuantityDropdown"
import { ArticleForOe, NotFoundArticle } from "../../../data/models/calculation"
import { calculationActions } from "../../../data/state"

type Props = {
    item: NotFoundArticle
}

export default function NotFoundArticleComponent({ item }: Props): ReactElement {
    const { translateText } = useLocalization()
    const [isFavorite, setIsFavorite] = useState(false)

    const onClick = () => {}

    const onStarArticle = () => {
        setIsFavorite((prevState) => !prevState)
    }

    const handleChangeQuantity = (article: ArticleForOe | NotFoundArticle, quantity: number) => {
        calculationActions.changeQuantity(article, quantity)
    }

    const handleDeleteArticle = () => {
        calculationActions.deleteArticleFromCalculation(undefined, item)
    }

    const renderActions = () => {
        return (
            <ActionsWrapper>
                <QuantityDropdown article={item} onChangeQuantity={handleChangeQuantity} />
                <StyledButton onClick={handleDeleteArticle} startIcon={<Icon name="delete" />} />
                <ReplaceButton onClick={onClick} disabled />
                <StyledButton onClick={onStarArticle} startIcon={<Icon name={isFavorite ? "filled-star" : "vote-star"} />} variant="text" />
                <Button disabled>{translateText(43)}</Button>
                <Button disabled variant="text">
                    <StyledMoreMeu>
                        <span />
                        <span />
                        <span />
                    </StyledMoreMeu>
                </Button>
            </ActionsWrapper>
        )
    }

    return (
        <StyledBox className="article-list__item article-list__item--compact article-list__panel">
            <ArticleCell bemModifier="thumbnail">
                <Image className="image article__thumbnail " src="" type="article" />
            </ArticleCell>
            <ArticleCell bemModifier="description">
                <Typography ml="1em" fontWeight="bold" variant="body1">
                    {item.label || item.oeNumber || translateText(13716)}
                </Typography>
            </ArticleCell>
            <ArticleCell bemModifier="actions">{renderActions()}</ArticleCell>
        </StyledBox>
    )
}

const StyledMoreMeu = styled(Box)({
    span: {
        display: "inline-block",
        height: ".25em",
        width: ".25em",
        marginRight: ".25em",
        borderRadius: "50%",
        backgroundColor: "#202020",
    },
})

const ActionsWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
})

const StyledButton = styled(Button)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 .25em",
})

const StyledBox = styled(Box)({
    ".image": {
        marginLeft: "1em",
    },
})
