import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { CellContentPosition, TableCellData, TableColumnData } from "@tm/components"
import { OrderIdSource, OrderVoucherList, VoucherType } from "@tm/models"
import { NoResultHint } from "../../_shared/NoResultHint"
import { StyledTable } from "../../_shared/StyledComponents"
import VoucherDetailsButton from "../../_shared/VoucherDetailsButton"
import VehicleInfo from "../../_shared/orders/OrderVehicleInfo"
import CustomerInfo from "../../_shared/orders/CustomerInfo"
import { getBundleParams } from "../../../utils"
import ErpState from "../../_shared/orders/ErpState"

type Props = {
    orders: OrderVoucherList[]
    shortCountryCode: string
    showCustomerColumn?: boolean
}

export default function WidgetOrderList({ orders, shortCountryCode, showCustomerColumn }: Props) {
    const { translateText, date } = useLocalization()
    const { erpOrderState } = getBundleParams()

    const showCustomers = useMemo(() => {
        return showCustomerColumn && orders?.some((order) => !!order.workTaskInfos.some((wt) => wt.customerInfo))
    }, [orders, showCustomerColumn])

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(700) }, // orderNumbers
            { header: translateText(98) }, // orderDate
        ]

        if (showCustomers) {
            columns.push({ header: translateText(107) }) // CustomerInfo
        }

        columns.push(
            { header: translateText(147) } // VehicleInfo
        )
        if (erpOrderState) {
            columns.push({ header: translateText(212) }) // ErpState
        }
        columns.push(
            { alignContent: CellContentPosition.right } // VoucherDetailsButton
        )

        return columns
    }

    function getCellData(order: OrderVoucherList) {
        const data: TableCellData[] = [
            { displayValue: order.orderNumbers?.find((orderNumber) => orderNumber.type === OrderIdSource.Wholesaler)?.number, id: "1" },
            { displayValue: date(order.orderDate, "d"), id: "2" },
        ]
        if (showCustomers) {
            data.push({ displayValue: <CustomerInfo order={order} />, id: "3" })
        }

        data.push({ displayValue: <VehicleInfo order={order} licensePlateOnTop shortCountryCode={shortCountryCode} />, id: "5" })
        if (erpOrderState) {
            data.push({ displayValue: <ErpState orderVouchers={orders} order={order} />, id: "6" })
        }

        data.push({
            displayValue: <VoucherDetailsButton variant="small" voucherId={order.orderVoucherId} voucherType={VoucherType.Order} />,
            id: "7",
        })

        return data
    }

    const displayData = orders.map((order) => ({
        cells: getCellData(order),
        id: `${order.orderVoucherId}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable variant="small" sx={{ position: "absolute" }} columns={getColumns()} rows={displayData} rowCap={8} />
}
