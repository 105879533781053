import { QueryFunctionContext } from "react-query"
import { ArticlesResponse } from "../../../data/model/uni-parts"
import { ArticlePage, QueryKey } from "../hooks/useArticlesBase"

export async function fetchUniversalPartsArticles<T>(
    getArticles: (request: T) => Promise<ArticlesResponse>,
    { queryKey: [, , request], pageParam }: QueryFunctionContext<QueryKey<T>, number>
): Promise<ArticlePage> {
    if (!request) {
        return Promise.reject()
    }

    const articleResponse = await getArticles({
        ...request,
        pageIndex: pageParam ?? 0,
    })

    const hasNextPage = articleResponse.count !== undefined && articleResponse.count >= (articleResponse.pageSize ?? 0)

    return {
        data: articleResponse.data,
        nextPageIndex: hasNextPage ? (articleResponse.pageIndex ?? 1) + 1 : undefined,
    }
}
