import { useStyle } from '@tm/context-distribution'
import { Icon, Loader } from '@tm/controls'
import { bem } from '@tm/utils'
import { em, important, margin, percent, px, rem, scale, translate, viewHeight, viewWidth } from 'csx'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { MainState } from '../main'
import { getBundleParams } from '../../utils'

type Props = {
	insideDialog?: boolean
}
const selector = createSelector((s: MainState) => ({
	loading: s.wheelsList.configurator._3D.loading,
	selected3DCar: s.wheelsList.configurator._3D.selected3DCar,
	color: s.wheelsList.configurator.selectedColor || "000",
	height: s.wheelsList.configurator.height,
	tint: s.wheelsList.configurator.tint,

	selectedRim: s.wheelsList.base.selectedRimItem,
	available3DRims: s.wheelsList.configurator._3D.available3DRims,
	selectedSize: s.wheelsList.base.selectedSize
}), x => x)

let configurator: any

const PreyssConfigurator: React.FC<Props> = ({ insideDialog }) => {

	let startx: number, moving: boolean, startframe: number
	const { loading, selected3DCar, color, height, tint, selectedRim, available3DRims, selectedSize } = useSelector(selector)

	let { defaultTyres } = selected3DCar ?? {}
	defaultTyres = defaultTyres?.split("_")?.[0] + "_" + selectedSize

	const { imagesPath } = getBundleParams()

	const tyreFolderName = defaultTyres?.slice(0, 1) + '_' + defaultTyres?.slice(1)

	const wheelPos_front = selected3DCar?.wheelPos_front

	let observer: MutationObserver

	React.useEffect(() => {
		ref.current?.setAttribute("color", color || "000000")
		ref.current?.setAttribute("tint", tint.toString())
		ref.current?.setAttribute("height", height.toString())

	}, [color, tint, height])

	const getFrame = () => ~~(parseInt(ref.current?.getAttribute("angle") ?? "0") / 10)
	const setFrame = (angle: number) => ref.current?.setAttribute("angle", (angle * 10).toString())


	const handleTouchMouseDown = (ev: any) => {
		// ev.preventDefault()
		const pageX = ev.pageX || ev.touches[0].pageX
		startx = pageX
		moving = true
		startframe = getFrame()
	}

	const handleTouchMouseMove = (ev: any) => {
		// ev.preventDefault()
		if (!moving) {
			return
		}

		const pageX = ev.pageX || ev.touches[0].pageX
		const dX = Math.floor((pageX - startx) / 15)
		const frame = (startframe + 3600 + dX) % 36
		setFrame(frame)

	}

	const handleMouseWheel = (ev: any) => {
		const FRAME = getFrame()
		// ev.()
		if (ev.nativeEvent.wheelDelta > 0)
			if (FRAME < 35)
				setFrame(FRAME + 1)
			else
				setFrame(0)
		else
			if (FRAME > 0)
				setFrame(FRAME - 1)
			else
				setFrame(35)
	}

	const handleTouchMouseUp = () => {
		moving = false
	}

	const handleAngleChange = () => {
		const angle = ref.current?.getAttribute("angle")
	}

	React.useEffect(() => {
		if (!selectedSize || !selectedRim)
			return

		const preysRim = available3DRims.find(x => x.rimId == selectedRim?.sizeInfo.find(y => y.size == selectedSize)?.rimFrameId && x.inch == selectedSize)
		const rimName = (preysRim?.folder.slice(0, -2) ?? "") + selectedSize
		if (preysRim?.rimId) {
			ref.current?.setAttribute("rims", `${imagesPath}rims/${rimName}/${rimName}_${wheelPos_front}`,)

			ref.current?.setAttribute("tyres", `${imagesPath}tyres/${tyreFolderName}/${defaultTyres}_${selected3DCar?.wheelPos_front}`,)
		}

	}, [selectedRim, selectedSize])

	const init = () => {
		configurator = new window.ConfigurationRenderer(ref.current,
			{ // Width and height in pixels
				width: 1280,
				height: 720
			},
			{
				car: `${imagesPath}cars/` + selected3DCar?.imgid,
				rims: `${imagesPath}rims/${selected3DCar?.defaultRims}/${selected3DCar?.defaultRims}_${selected3DCar?.wheelPos_front}`,
				tyres: `${imagesPath}tyres/${tyreFolderName}/${defaultTyres}_${selected3DCar?.wheelPos_front}`,
				color: color ?? selected3DCar?.color,
				// plate: `/bundles/wheels/images/axle-image__bit${1}.png`,
				shadow: "on"
			}
		)
		// configurator.showLoadingAnimation=()=>{}
		// console.log(configurator)

		// observer = new MutationObserver(function (mutations) {
		// 	mutations.forEach(function (mutation) {
		// 		if (mutation.type == "attributes" && mutation.attributeName == "angle") {
		// 			debounce(handleAngleChange, 300)
		// 		}
		// 	})
		// })

		// observer.observe(ref.current!, {
		// 	attributes: true
		// })

		configurator.start()
	}

	const ref = React.useRef<HTMLDivElement>(null)
	React.useLayoutEffect(() => {
		ref.current && init()

		// return () => observer.disconnect()
	}, [])

	if (insideDialog)
		return (
			<div className={style.dialogWrapper}>
				{loading && <Loader />}
				<div ref={ref} className={style.dialogContent} id={"viewer"}
					onWheel={handleMouseWheel}
					onTouchStart={handleTouchMouseDown}
					onTouchEnd={handleTouchMouseUp}
					onTouchMove={handleTouchMouseMove}
					onMouseUp={handleTouchMouseUp}
					onMouseMove={handleTouchMouseMove}
					onMouseDown={handleTouchMouseDown}>
				</div>
			</div>
		)

	return (
		<div className={style.parent}>
			{loading && <Loader />}
			<Icon skin="primary" className={bem(style.icon, insideDialog && "dialog")} name="wheels_arrow-right" />
			<div className={bem(style.wrapper, insideDialog && "dialog")}>
				<div ref={ref} className={bem(style.content, insideDialog && "dialog")} id={"viewer"}
					onWheel={handleMouseWheel}
					onTouchStart={handleTouchMouseDown}
					onTouchEnd={handleTouchMouseUp}
					onTouchMove={handleTouchMouseMove}
					onMouseUp={handleTouchMouseUp}
					onMouseMove={handleTouchMouseMove}
					onMouseDown={handleTouchMouseDown}
				>
				</div>
			</div>
			<Icon skin="primary" className={bem(style.icon, insideDialog && "dialog")} name="wheels_arrow-left" />
		</div>
	)
}

const style = useStyle({
	parent: {
		display: "flex",
		justifyContent: "center",
		alignItems: "baseline",
		height: percent(100),
		flex: 1,
	},
	wrapper: {
		height: px(300),
		width: px(550),
		position: "relative",

	},
	dialogWrapper: {
		height: percent(100),
		width: percent(100),
		display: "flex",
		justifyContent: "center"
	},
	dialogContent: {
		width: viewWidth(70),
		height: viewHeight(85),
		$nest: {
			"#ConfigurationRendererViewer-viewer": {
				transition: important("none"),
				filter: important("none"),
				transform: scale(1.5),
				pointerEvents: 'none',
				left: percent(-10),
			},
			".clickMaskContainer": {
				display: "none"
			}
		}
	},
	content: {
		zIndex: 1,
		left: percent(40),
		top: percent(20),
		width: percent(170),
		height: percent(134),
		position: "absolute",
		transform: translate(percent(-45), percent(-50)),
		$nest: {
			//HIDE THE BLUR MASK
			"#ConfigurationRendererViewer-viewer": {
				transition: important("none"),
				filter: important("none"),
			},
			".clickMaskContainer": {
				display: "none"
			},
			".configurationRenderer-wrapper >div:first-child": {
				paddingBottom: important(percent(50.25))
			}
		}
	},
	icon: {
		margin: margin(0, em(2)),
		width: rem(13),
		height: rem(8),
		display: "block",
	}

})(PreyssConfigurator)

export default PreyssConfigurator
