import { stylesheet } from "typestyle"
// import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    // const { margin } = getStyleTheme()

    return stylesheet({
        detailsButton: {
            marginTop: "0.4rem",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
