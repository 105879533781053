import { Key, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Text } from "@tm/controls"
import { classes } from "@tm/utils"

type Props = {
    className?: string
    label: string | undefined
    percentage: number
    segments?: Array<number | Segment>
    defaultDescription?: string
}

type Segment = {
    endPercentage: number
    description?: string
    title?: string
}

// MLE 27.01.2021: CSS.registerProperty is currently only supported by Chrome (and WebKit Edge).
if (typeof (window.CSS as any).registerProperty == "function") {
    ;(window.CSS as any).registerProperty({
        name: "--counter-percentage",
        syntax: "<integer>",
        initialValue: 0,
        inherits: true,
    })
}

const size = 100
const center = size / 2
const strokeWidth = 20
const radius = center - strokeWidth / 2

const bottomBufferAngle = 40 // Compensate the space used for the label below the circle (first and last segments are extended by this angle)
const onePercentAngle = (360 - bottomBufferAngle * 2) / 100 // Angle per "one percent" (width) of segment

/** @todo: move to @tm/controls */
export function CircularProgressBar(props: Props) {
    const style = useMemo(() => getStyle(), [])

    function getAngles(startPercentage: number, endPercentage: number) {
        return {
            startAngle: startPercentage * onePercentAngle + 180 + bottomBufferAngle,
            endAngle: endPercentage * onePercentAngle + 180 + bottomBufferAngle,
        }
    }

    function renderCirclePath(startPercentage: number, endPercentage: number, className?: string, key?: Key) {
        const { startAngle, endAngle } = getAngles(startPercentage, endPercentage)

        const startPoint = polarToCartesian(center, center, radius, startAngle)
        const endPoint = polarToCartesian(center, center, radius, endAngle)
        const useLongArc = endAngle - startAngle <= 180 ? 0 : 1

        return (
            <path
                key={key}
                className={classes(style.circleSegment, className)}
                d={`M ${startPoint.x} ${startPoint.y} A ${radius} ${radius} 0 ${useLongArc} 1 ${endPoint.x} ${endPoint.y}`}
            />
        )
    }

    function renderSegmentSeparators() {
        const segments = getSegments(props.segments)

        // Only one segment (0 - 100) so no separators needed
        if (segments.length == 1 && segments[0].endPercentage === defaultSegments[0]) {
            return
        }

        return segments.map((x, idx) => renderCirclePath(x.endPercentage - 0.5, x.endPercentage + 0.5, style.circleSegmentSeparator, idx))
    }

    function renderDescription() {
        let currentDescription = props.defaultDescription

        getSegments(props.segments).forEach((x) => {
            if (x.endPercentage <= props.percentage) {
                currentDescription = x.description ?? undefined
            }
        })

        return (
            <div style={{ position: "relative" }}>
                <Text modifiers={["block", "strong"]} className={style.progressDescription}>
                    {currentDescription}
                </Text>
                <Text modifiers="block" className={style.progressPercentage}>
                    {/* {props.percentage} */}
                    <small>%</small>
                </Text>
            </div>
        )
    }

    function renderLabel() {
        if (!props.label) {
            return null
        }

        return (
            <div className={style.label}>
                <Text modifiers="strong">{props.label || "\u00A0"}</Text>
            </div>
        )
    }

    return (
        <div className={classes(style.wrapper, props.className)} style={{ "--counter-percentage": props.percentage } as any}>
            <svg viewBox={`0 0 ${size} ${size - 5}`} className={style.circle}>
                <circle className={style.circleBorder} cx={center} cy={center} r={radius} />
                <circle className={style.circleBackground} cx={center} cy={center} r={radius} />

                {renderCirclePath(0, 100)}
                {renderCirclePath(0, 100, style.circleSegmentActive)}
                {renderSegmentSeparators()}
            </svg>

            {renderDescription()}
            {renderLabel()}
        </div>
    )
}

const defaultSegments = [100]

CircularProgressBar.defaultProps = {
    segments: defaultSegments,
}

function getStyle() {
    const theme = getStyleTheme()

    const colors = {
        circleBorder: "#ededed",
        circleStroke: "white",
        circleFill: "#f8f8f8",
        labelBackground: "white",
        labelBorder: "#ededed",
        segment: "#f2f2f2",
        segmentActive: theme.overwrites?.toolkits?.vehicle?.circularProgressBarColor || theme.colors.highlight,
        description: theme.colors.highlight,
    }

    return useStyle({
        wrapper: {
            width: "10em",
            height: "9em",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        },
        circle: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            paddingBottom: "1px", // Fix circle slightly visible below label
        },
        circleBorder: {
            stroke: colors.circleBorder,
            strokeWidth,
            fill: "none",
        },
        circleBackground: {
            stroke: colors.circleStroke,
            strokeWidth: strokeWidth - 2,
            fill: colors.circleFill,
        },
        circleSegment: {
            stroke: colors.segment,
            fill: "none",
            strokeWidth: strokeWidth / 2,
        },
        circleSegmentActive: {
            stroke: colors.segmentActive,
            strokeDasharray: 196,
            strokeDashoffset: "calc(196px - (var(--counter-percentage) * 1.96px))", // "px" units are important for Firefox (but not for Chrome/WebKit Edge)
            transition: "stroke-dashoffset 2s ease-in-out",
        },
        circleSegmentSeparator: {
            stroke: colors.circleStroke,
            strokeWidth: strokeWidth / 2 + 1,
        },
        progressDescription: {
            maxWidth: "4em",
            margin: "0.25em auto 0",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontSize: "1.4em",
            color: colors.description,
            $nest: {
                "&:empty": {
                    display: "none",
                },
            },
        },
        progressPercentage: {
            fontSize: "2.2em",
            transition: "--counter-percentage 2s ease-in-out",
            counterReset: "counter-percentage var(--counter-percentage)",
            $nest: {
                "&::before": {
                    content: "counter(counter-percentage)",
                },
                small: {
                    fontSize: "0.75em",
                    marginLeft: "0.05em",
                },
            },
        },
        label: {
            position: "absolute",
            bottom: 0,
            width: "90%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            padding: "0.25em",
            backgroundColor: colors.labelBackground,
            border: `solid 0.15em ${colors.labelBorder}`,
            borderRadius: "0.25em",
            $nest: {
                ".text": {
                    fontSize: "1.2em",
                },
            },
        },
    })(CircularProgressBar)
}

function getSegments(segments: Array<number | Segment> = defaultSegments): Array<Segment> {
    return segments.map((x) => (typeof x == "number" ? { endPercentage: x } : x)).orderBy((x) => x.endPercentage)
}

function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    }
}
