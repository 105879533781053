import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { useEffect, Suspense } from "react"
import { setTabInfo } from "../../../business"
import { useManufacturerDetails } from "../../../data/hooks"
import { publishModuleInfo } from "../../../helpers"
import SelectionsCard from "./SelectionsCard"

import { getBundleParams } from "../../../utils"

type Props = {
    vehicleType: VehicleType
    manufacturerId: number | undefined
    backUrl: string
    skipModuleInfoPublishing?: boolean
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={<SelectionsCard isLoading isCurrent />}>
            <CardManufacturer {...props} />
        </Suspense>
    )
}

function CardManufacturer({ vehicleType, manufacturerId, backUrl, skipModuleInfoPublishing }: Props) {
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const manufacturer = useManufacturerDetails({ vehicleType, manufacturerId })

    useEffect(() => {
        if (!skipModuleInfoPublishing && workTaskId) {
            publishModuleInfo(workTaskId, "{{1601}}", manufacturer?.description || "{{71}}")
            /** @todo Remove when the module tabs also receive the channel message */
            manufacturer && setTabInfo(workTaskId, manufacturer.description)
        }
    }, [manufacturer?.description, workTaskId, skipModuleInfoPublishing])

    return (
        <SelectionsCard
            isCurrent
            image={!getBundleParams().hideManufacturerLogos ? manufacturer?.thumbnail : undefined}
            imageFallbackType="manufacturer"
            description={!manufacturer ? translateText(71) : undefined}
            content={manufacturer?.description}
            resetUrl={manufacturer ? backUrl : undefined}
        />
    )
}
