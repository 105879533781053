import { Button, ListItem, ListItemText, useTheme, Stack, styled } from "@tm/components"
import { Icon } from "@tm/controls"
import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { NotificationMessage } from "../../data/model/notificationMessage"
import { NotificationMessageType } from "../../data/model/notificationMessageType"
import { NotificationMessageStatus } from "../../data/model/notificationMessageStatus"

export interface INotificationListItemProps {
    item: NotificationMessage
    setNotificationMessageReadAndRemove(notificationMessageId: string): Promise<void>
}

export function NotificationListItem(props: INotificationListItemProps) {
    const theme = useTheme()
    const { translateText } = useLocalization()

    const NotificationStatusStack = styled(Stack)({
        flexDirection: "row",
        width: "100%",
        maxWidth: "100px",
        marginRight: "128px",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: "8px",
        paddingRight: "8px",
        borderRadius: "3px",
        border: "2px solid #e2e2e2",
    })

    const getColorByNotificationType = useCallback(
        (type: NotificationMessageType) => {
            let color = theme.colors?.dark
            switch (type) {
                case NotificationMessageType.Sindri:
                    color = "#FFE100"
                    break
                case NotificationMessageType.News:
                    color = "#BFED93"
                    break
                case NotificationMessageType.Chat:
                    color = "#93EDD7"
                    break
                default:
                    color = theme.colors?.dark
            }
            return color
        },
        [theme]
    )

    const getColorByIsRead = useCallback(
        (isRead: boolean) => {
            return isRead ? theme.palette.grey[200] : theme.palette.grey[50]
        },
        [theme]
    )

    return (
        <ListItem
            sx={{
                marginBottom: 1,
                borderLeft: 4,
                borderColor: getColorByNotificationType(props.item.notificationMessageType),
                background: getColorByIsRead(props.item.isRead),
            }}
            key={props.item.notificationMessageId}
            disableGutters
        >
            <ListItemText sx={{ marginLeft: 2 }} primary={props.item.title} secondary={props.item.customerName} />
            <NotificationStatusStack>
                <Icon size="xs" name="check" />
                <ListItemText sx={{ marginLeft: 0.5 }} primary={NotificationMessageStatus[props.item.notificationMessageStatus]} />
            </NotificationStatusStack>
            <Button sx={{ marginRight: 1 }} variant="contained">
                {translateText(2014)}
            </Button>
            <Button
                sx={{ marginRight: 2 }}
                variant="text"
                onClick={() => {
                    props.setNotificationMessageReadAndRemove(props.item.notificationMessageId)
                }}
            >
                <Icon size="xs" name="close" />
            </Button>
        </ListItem>
    )
}
