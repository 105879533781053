import { useState, useCallback, useEffect } from "react"
import { useUser } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box, Switch } from "@tm/components"
import { TmaHelper } from "@tm/utils"
import { ECounterType } from "@tm/models"

type Props = {
    isChecked: boolean
    disabled: boolean
    onToggle: (value: boolean) => void
}

const PurchasePriceSwitch = ({ isChecked, disabled, onToggle }: Props) => {
    const { translateText } = useLocalization()

    const handleToggle = (value: boolean) => {
        onToggle(value)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowInvoicePrice, !value)
    }

    return (
        <Box sx={{ position: "relative" }}>
            <Toolbar className="purchase-price-switch" title={translateText(1132)}>
                <Switch checked={isChecked} onChange={(e) => handleToggle(e.target.checked)} disabled={disabled} />
            </Toolbar>
        </Box>
    )
}

export default function PurchasePriceSwitchSettingsWrapper() {
    const user = useUser()
    const { userSettingsUpdating } = user
    const [isChecked, setChecked] = useState(false)

    useEffect(() => {
        setChecked(user.userSettings?.showPurchasePrice ?? false)
    }, [user.userSettings?.showPurchasePrice])

    const canShowSwitch = useCallback((): boolean => {
        const externalModules = user?.userContext?.externalModules
        let isInfoEnabled = false
        if (externalModules) {
            externalModules.forEach((externalModule) => {
                if (externalModule.isInfoEnabled) {
                    isInfoEnabled = true
                }
            })
        }

        const shouldRender = !(
            user.userSettings === undefined ||
            user?.userContext?.parameter.purchasePricesDisabled ||
            user.userSettings.showPurchasePrice === undefined
        )

        return shouldRender && isInfoEnabled
    }, [user?.userContext?.externalModules, user?.userSettings?.showPurchasePrice, user?.userContext?.parameter.purchasePricesDisabled])

    if (!canShowSwitch()) {
        return null
    }

    const handleToggle = (value: boolean) => {
        user.setUserSetting?.("SHOW_PURCHASE_PRICE", value)
    }

    return <PurchasePriceSwitch isChecked={isChecked} onToggle={handleToggle} disabled={userSettingsUpdating} />
}
