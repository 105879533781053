import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { RealTable, Button } from "@tm/controls"
import { channel } from "@tm/models"
import { SubUser, UserAdminConfig, PermissionContainer } from "../../../data/model"
import SubUserEditor from "./SubUserEditor"
import { deleteSubUser } from "../../../data"
import SubUserDelete from "./SubUserDelete"

type Props = {
    permissionContainer?: PermissionContainer
    subUsers: Array<SubUser>
    userAdminConfig: UserAdminConfig
    reloadSubUsers(): void
}

export default function SubUserTableComponent({ permissionContainer, subUsers, userAdminConfig, reloadSubUsers }: Props) {
    const { translate, translateText } = useLocalization()
    const [deleting, setDeleting] = useState<boolean>(false)
    const [editUser, setEditUser] = useState<SubUser>()

    function handleCloseEditor(reload: boolean) {
        setEditUser(undefined)

        if (reload) {
            reloadSubUsers()
        }
    }

    function handleDeleteUser(user: SubUser) {
        setDeleting(true)

        deleteSubUser(user)
            .then(() => {
                setDeleting(false)
                reloadSubUsers()
            })
            .catch(() => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", { message: `${translateText(787)}. ${translateText(1478)}`, skin: "danger" })
                setDeleting(false)
            })
    }

    function renderUsernameCell(user: SubUser) {
        return <RealTable.Cell>{user.username}</RealTable.Cell>
    }

    function renderNameCell(user: SubUser) {
        return <RealTable.Cell>{`${user.firstname || ""} ${user.lastname || ""}`}</RealTable.Cell>
    }

    function renderEMailCell(user: SubUser) {
        return <RealTable.Cell>{user.eMail}</RealTable.Cell>
    }

    function renderEditCell(user: SubUser) {
        return (
            <RealTable.Cell>
                <Button
                    size="s"
                    skin="primary"
                    icon="pencil"
                    title={translateText(1238)}
                    onClick={() => {
                        setEditUser(user)
                    }}
                    disabled={deleting}
                />
            </RealTable.Cell>
        )
    }

    function renderDeleteCell(user: SubUser) {
        return (
            <RealTable.Cell>
                <SubUserDelete subUser={user} onDelete={handleDeleteUser} disabled={deleting} />
            </RealTable.Cell>
        )
    }

    function getColumns() {
        return [
            <RealTable.Column key="username" className="username" renderItemContent={renderUsernameCell}>
                {translate(186)}
            </RealTable.Column>,
            <RealTable.Column key="name" className="name" renderItemContent={renderNameCell}>
                {translate(950)}
            </RealTable.Column>,
            <RealTable.Column key="email" className="email" renderItemContent={renderEMailCell}>
                {translate(109)}
            </RealTable.Column>,
            <RealTable.Column key="edit" className="edit" renderItemContent={renderEditCell} />,
            <RealTable.Column key="delete" className="delete" renderItemContent={renderDeleteCell} />,
        ]
    }

    return (
        <>
            <RealTable data={subUsers} columns={getColumns()} />
            {editUser && (
                <SubUserEditor
                    type="edit"
                    subUser={editUser}
                    headline={1238}
                    onclose={handleCloseEditor}
                    permissionContainer={permissionContainer}
                    userAdminConfig={userAdminConfig}
                />
            )}
        </>
    )
}
