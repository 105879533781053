import { Loader } from "@tm/controls"
import { CarModelSeries, VehicleType } from "@tm/models"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useEffect, useMemo, useCallback, Suspense } from "react"
import { Redirect, useHistory, useRouteMatch } from "react-router-dom"
import { Box } from "@tm/components"

import ModelSeriesListComponent from "../_shared/ModelSeriesList"
import { useFilters, useModelSeriesByManufacturer, useSetAvailableFilters } from "../../data/hooks"
import { getValidatedVehicleIdentificationParams, getVehicleIdentificationUrl } from "../../helpers/routing"
import { usePreferredSortOptionKey } from "../../data/hooks/useSortOptions"

type RouteParams = {
    vehicleType: string
    manufacturerId: string
    mainModelSeriesId: string
}

type ModelSeriesComponentProps = { vehicleType: VehicleType; manufacturerId: number; mainModelSeriesId: number }

function ModelSeriesComponent(props: ModelSeriesComponentProps) {
    const { mainModelSeriesId, manufacturerId, vehicleType } = props
    const history = useHistory()
    const { params } = useRouteMatch<RouteParams>()

    const [preferredSortOption, setPreferredSortOption] = usePreferredSortOptionKey()

    const [filters, setFilters] = useFilters(vehicleType)
    const setAvailableFilters = useSetAvailableFilters(vehicleType)
    const response = useModelSeriesByManufacturer({
        vehicleType,
        manufacturerId,
        mainModelSeriesId,
        selectedFilters: { bodyTypeId: filters.bodyTypeId, modelYear: filters.modelYear },
    })

    useEffect(() => {
        // Reset the filters on mount
        setFilters((prev) => ({ ...prev, fuelType: undefined, engineCode: undefined, engineCapacity: undefined }))
    }, [])

    useEffect(() => {
        setAvailableFilters({
            bodyTypes: response?.filters?.bodyTypes ?? [],
            modelYears: response?.filters?.modelYears ?? [],
            fuelTypes: [],
            engineCodes: [],
            engineCapacities: [],
            sorting: true,
        })
    }, [response, setAvailableFilters])

    const orderedModelSeries = useMemo(() => {
        if (!response?.modelSeries || preferredSortOption !== "alphabetical") {
            return response?.modelSeries ?? []
        }

        return response.modelSeries.orderBy((x) => x.description)
    }, [response?.modelSeries, preferredSortOption])

    const handleSelect = useCallback(
        (modelSeries: CarModelSeries) => {
            history.push(getVehicleIdentificationUrl(params, { vehicleType, modelSeriesId: modelSeries.id }))
        },
        [vehicleType, history, params]
    )

    /** @todo Figure out how to automatically redirect but keep the ability to have the user click back */
    // if (response?.modelSeries.length === 1) {
    //     return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, modelSeriesId: response.modelSeries[0].id })} />
    // }

    return <ModelSeriesListComponent vehicleType={vehicleType} modelSeries={orderedModelSeries} onSelect={handleSelect} isArticleReferences={false} />
}

export default function Wrapper() {
    const routeMatch = useRouteMatch<RouteParams>()
    const params = getValidatedVehicleIdentificationParams(routeMatch.params)
    const { vehicleType, manufacturerId, mainModelSeriesId } = params

    if (!manufacturerId) {
        return <Redirect to={getVehicleIdentificationUrl(params, { vehicleType })} />
    }
    if (mainModelSeriesId === undefined) {
        return <Redirect to={getVehicleIdentificationUrl(params, { vehicleType, manufacturerId })} />
    }

    return (
        <div className="tk-vehicle">
            <Box m={2}>
                <Suspense fallback={<Loader />}>
                    <ModelSeriesComponent vehicleType={vehicleType} manufacturerId={manufacturerId} mainModelSeriesId={mainModelSeriesId} />
                </Suspense>
            </Box>
        </div>
    )
}
