import { BundleActionType, BundleActions } from "../../../business"
import { LoginRequest } from "../../../data"
import { CustomVehicle, TruckState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "UPDATE_MODULE_PARAMS"; payload: LoginRequest }
    | { type: "SET_CUSTOM_VEHICLE"; payload: CustomVehicle }

const DEFAULT_STATE: TruckState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): TruckState {
    switch (action.type) {
        case "UPDATE_MODULE_PARAMS": {
            return {
                ...state,
                parameters: action.payload,
            }
        }
        case "SET_CUSTOM_VEHICLE": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
    }

    return state
}

const updateParameters = (parameters: LoginRequest): ComponentActionType => ({ type: "UPDATE_MODULE_PARAMS", payload: parameters })

const setCustomVehicle = (vehicle: CustomVehicle): ComponentActionType => ({ type: "SET_CUSTOM_VEHICLE", payload: vehicle })

export type IActions = typeof Actions
export const Actions = {
    ...BundleActions,
    updateParameters,
    setCustomVehicle,
}
