import { Dialog } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    ActivatableModule,
    ActivateModuleProps,
    ActivateModuleRef,
    ModuleGroupId,
    ModuleOption,
    ModuleOptionType,
    ModulePackage,
    SetModuleActivationRequest,
} from "@tm/models"
import { getModuleGroupTextId, useActivateModule } from "@tm/utils"
import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { Box, Checkbox, HTMLBox, Typography } from "@tm/components"
import { StyledDialog, StyledInstantDialog } from "./styledComponents"
import { getDialogText, getModulePrice, handleDemoActivation, handleFullActivation, removeMvcSessions } from "./helper"

const ActivateModule = forwardRef<ActivateModuleRef, ActivateModuleProps>(({ routes, demoActivationInstanstly, workTaskId }, ref) => {
    const { translateText, number: numberFormat } = useLocalization()
    const [activateDemoModuleRequest, setActivateDemoModuleRequest] = useState<SetModuleActivationRequest>()
    const [activateFullModuleRequest, setActivateFullModuleRequest] = useState<{
        moduleName: string
        request: SetModuleActivationRequest
        dialogText: string
        checked?: boolean
    }>()
    const [moduleRoute, setModuleRoute] = useState<string>()

    const { activateModule } = useActivateModule()

    const dialogRef = useRef<Dialog>(null)
    const instantDialogRef = useRef<Dialog>(null)
    const notifyPricesDialogRef = useRef<Dialog>(null)

    useImperativeHandle(ref, () => ({
        handleActivateModule,
    }))

    function handleActivateModule(
        moduleGroupId: ModuleGroupId,
        module: ActivatableModule,
        moduleOption?: ModuleOption,
        modulePackage?: ModulePackage
    ) {
        if (!moduleOption?.activatable) {
            return
        }

        if (routes) {
            const route = routes?.[module.id.toLowerCase()]
            setModuleRoute(route)
        }

        const request: SetModuleActivationRequest = {
            moduleGroupId,
            moduleId: module.id,
            externalModuleId: module.externalId,
            vehicleType: module.vehicleType,
            moduleOption,
            modulePackage,
        }

        switch (moduleOption.type) {
            case ModuleOptionType.Demo: {
                if (!demoActivationInstanstly) {
                    setActivateDemoModuleRequest(request)
                    dialogRef.current?.show()
                    return
                }

                activateModule(request).then(() => {
                    removeMvcSessions()
                    instantDialogRef.current?.show()
                })

                break
            }

            case ModuleOptionType.Full: {
                const moduleName = module.externalId ? module.name : `${getModuleGroupTextId(moduleGroupId, translateText)} ${module.id}`
                const modulePrice = getModulePrice(moduleOption)

                if (!modulePrice) {
                    handleFullActivation(activateModule, moduleName, request, translateText)
                    return
                }

                const dialogText = getDialogText(modulePrice, moduleName, translateText, numberFormat)
                setActivateFullModuleRequest({ moduleName, request, dialogText })

                notifyPricesDialogRef.current?.show()

                break
            }

            default:
                break
        }
    }

    function handleConfirmLeave() {
        if (!activateDemoModuleRequest) {
            return
        }

        handleDemoActivation(activateModule, activateDemoModuleRequest, workTaskId, moduleRoute, translateText)
    }

    function handleNotifyPricesConfirmLeave() {
        if (!activateFullModuleRequest) {
            return
        }

        handleFullActivation(activateModule, activateFullModuleRequest.moduleName, activateFullModuleRequest.request, translateText)
    }

    function renderNotifyPricesDialogContent() {
        return (
            <Box display="flex" flexDirection="column" rowGap="0.5em">
                <HTMLBox innerHTML={activateFullModuleRequest?.dialogText} />
                <Box display="flex" alignItems="center">
                    <Checkbox
                        checked={activateFullModuleRequest?.checked ?? false}
                        onClick={() => {
                            setActivateFullModuleRequest((prev) => (prev ? { ...prev, checked: !prev.checked } : undefined))
                        }}
                    />
                    <Typography fontSize="small">{translateText(3024)}</Typography>
                </Box>
            </Box>
        )
    }

    return (
        <>
            <StyledDialog
                skin="warning"
                ref={dialogRef}
                preText={translateText(12817)}
                text={translateText(12818)}
                confirmButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                iconName="warning-dark"
                onConfirm={handleConfirmLeave}
            />
            <Dialog
                skin="warning"
                ref={notifyPricesDialogRef}
                confirmButtonText={translateText(13794)}
                cancelButtonText={translateText(317)}
                iconName="warning-dark"
                onConfirm={handleNotifyPricesConfirmLeave}
                confirmButtonDisabled={!activateFullModuleRequest?.checked}
                hideCloseButton
                closeOnlyWithDialogInteraction
                text={renderNotifyPricesDialogContent()}
            />
            <StyledInstantDialog skin="warning" ref={instantDialogRef} text={translateText(12835)} iconName="warning-dark" />
        </>
    )
})

export default ActivateModule
