import { EurotaxOePart, EurotaxOePartGroup } from "@tm/models"
import { equals } from "@tm/utils"
import { SCOpenOeArticleRequest } from "../models"


export function getEurotaxSCArticles(part: EurotaxOePart, items: EurotaxOePartGroup[], manufacturer: string, callbackTranslate: (key: React.ReactText) => string, hideOePrice?: boolean) {
    let partsArray = selectedProductGroupParts(part, items).map(val => val.map((x) =>
        mapOePartSC({ ...x, ...equals(x, part) && { isSelected: true } }, manufacturer, callbackTranslate, hideOePrice))).reduce((acc, val) => acc.concat(val))

    return partsArray
}

function mapOePartSC(item: EurotaxOePart, manufacturer: string, callbackTranslate: (key: React.ReactText) => string, hideOePrice?: boolean): SCOpenOeArticleRequest {
    const partPosition = displayPartPosition(item.links, item.rechts, callbackTranslate)

    return {
        oeNumber: item.partNumber,
        description: item.mastercodeDescription,
        additionalDescription: partPosition,
        oeReplacements: item.replacements,
        currency: item.currency,
        price: hideOePrice ? undefined : item.price,
        isSelected: !!item.isSelected,
        isLeft: item.links,
        isRight: item.rechts,
        manufacturer: manufacturer,
    }
}

function selectedProductGroupParts(part: EurotaxOePart, items: EurotaxOePartGroup[]) {
    return items.map(item => item.parts.find(x => equals(x, part)) && item.parts).filter(Boolean) as EurotaxOePart[][]
}


function displayPartPosition(links: boolean, rechts: boolean, callbackTranslate: (key: React.ReactText) => string) {

    const buttonTitle = links && rechts ? callbackTranslate(1809) : links ? callbackTranslate(355) : rechts ? callbackTranslate(356) : ""

    return buttonTitle
}