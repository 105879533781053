import { channel, ErpInformation } from "@tm/models"

let erpBufferTimeout: number
const queue: ErpInformation[] = []

export function publishErpInfoLoaded(response: ErpInformation) {
    queue.push(response)
    clearTimeout(erpBufferTimeout)

    erpBufferTimeout = window.setTimeout(() => {
        channel("GLOBAL").publish("ERP/ERP_INFORMATION_LOADED", queue)
        queue.splice(0)
    }, 25)
}
