import { useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { Box, Typography, styled } from "@tm/components"
import CheckToken from "./CheckToken"
import { useFastServiceStore } from "../../../data"
import { initMaintenancePlan } from "../business"
import { getLabourRate } from "../../../helpers/userSettingsContextMethods"
import VehicleFields from "./VehicleFields"

type Props = {
    onCheckOpened?: (checkOffsetTop: number) => void
}

type HeaderDataItem = {
    name: string
    value: string
}

export default function VehicleCheck(props: Props) {
    const { onCheckOpened } = props
    const { translateText, languageId } = useLocalization()
    const userSettings = useUser()?.userSettings

    const selections = useFastServiceStore((state) => state.maintenancePlan.selections)
    const engineCode = useFastServiceStore((state) => state.maintenancePlan.vehicleFields.engineCode)
    const mileage = useFastServiceStore((state) => state.maintenancePlan.vehicleFields.mileage)
    const initialRegistration = useFastServiceStore((state) => state.maintenancePlan.vehicleFields.initialRegistration)
    const vin = useFastServiceStore((state) => state.maintenancePlan.vehicleFields.vin)
    const longLife = useFastServiceStore((state) => state.maintenancePlan.vehicleFields.longLife)

    useEffect(() => {
        if (!selections?.length && engineCode && mileage && initialRegistration && vin) {
            handleInitMaintenancePlan()
        }
    }, [])

    const handleInitMaintenancePlan = () => {
        initMaintenancePlan(languageId, getLabourRate(userSettings))
    }

    const renderVehicleFieldsHeader = () => {
        const data: HeaderDataItem[] = []

        if (engineCode) {
            data.push({ name: translateText(105), value: engineCode })
        }
        if (initialRegistration) {
            data.push({ name: translateText(124), value: initialRegistration.toLocaleDateString() })
        }
        if (mileage) {
            data.push({ name: translateText(125), value: mileage.toString() })
        }
        if (vin) {
            data.push({ name: translateText(101), value: vin })
        }
        if (longLife) {
            data.push({ name: translateText(12514), value: translateText(585) })
        }

        return (
            <Box display="flex" flexWrap="wrap">
                {data.map((elem, idx) => (
                    <StyledVehicleHeader key={idx}>
                        <Typography variant="h3">{`${elem.name}: `}</Typography>
                        <Typography variant="h3" fontWeight="600">
                            {elem.value}
                        </Typography>
                    </StyledVehicleHeader>
                ))}
            </Box>
        )
    }

    return (
        <CheckToken
            onCheckOpened={onCheckOpened}
            name={translateText(12634)}
            isCompleted={!!selections?.length}
            isFirst
            headerData={renderVehicleFieldsHeader()}
        >
            <VehicleFields handleSubmit={handleInitMaintenancePlan} />
        </CheckToken>
    )
}

const StyledVehicleHeader = styled(Box)({
    display: "flex",
    gap: "0.2em",
    marginRight: "0.5em",
    marginBottom: "0.5em",
    "&:last-child": {
        margin: 0,
    },
})
