import { Box, Skeleton } from "@tm/components"
import { Scrollbar } from "@tm/controls"
import * as React from "react"
import { YqNode } from "../../models"
import TreeItem from "./TreeItem"

type TreeNavigationProps = {
    nodes: YqNode[] | undefined
    loading: boolean
    onNodeSelect: (selectedNode: YqNode) => void
    selectedNode?: YqNode
    highlightOnStart?: boolean
}

const TreeNavigation: React.FC<TreeNavigationProps> = ({ nodes, loading, highlightOnStart, onNodeSelect, selectedNode }) => {
    const [openedArray, setOpenedArray] = React.useState<string[]>([])

    React.useEffect(() => {
        if (selectedNode && highlightOnStart) {
            let tempNode: YqNode | undefined = selectedNode
            while (tempNode) {
                const token = `${tempNode.code}${tempNode.name}`
                if (token) {
                    setOpenedArray((oldArray) => [...oldArray, token])
                }
                tempNode = tempNode.parent
            }
        }
    }, [selectedNode, highlightOnStart])

    function renderSkeletonLoading() {
        return (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.25em" }}>
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
                <Skeleton height={25} width={`${Math.floor(Math.random() * 80) + 20}%`} />
            </Box>
        )
    }

    return (
        <Box sx={{ minWidth: "300px", height: "100%", display: "flex", flexDirection: "column" }}>
            <Box sx={{ flex: "1" }}>
                <Scrollbar>
                    <Box pr="1em" sx={{ display: "flex", flexDirection: "column" }}>
                        {loading && renderSkeletonLoading()}
                        {!loading &&
                            nodes &&
                            nodes.map((node) => (
                                <TreeItem
                                    key={node.code ? node.code : node.name}
                                    node={node}
                                    onNodeSelect={onNodeSelect}
                                    selectedNode={selectedNode}
                                    openedList={openedArray}
                                />
                            ))}
                    </Box>
                </Scrollbar>
            </Box>
        </Box>
    )
}

export default TreeNavigation
