import { Alert, Button, Typography, Box, styled, Checkbox, Modal } from "@tm/components"

import { useLocalization } from "@tm/localization"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { renderRoute } from "@tm/utils"
import { useSelector } from "react-redux"
import { useHistory, useRouteMatch } from "react-router"
import { useCallback, useEffect, useState } from "react"
import { getBundleParams } from "../../utils"
import { managerSelector } from "../../selectors"
import { MainActions } from "../main/business"

export default function UserRegistration() {
    const history = useHistory()
    const match = useRouteMatch()
    const { translateText } = useLocalization()
    const { agbUrl, userIsRegistered } = useSelector(managerSelector)
    const [accepted, setAccepted] = useState(false)
    const [registrationProcessDone, setRegistrationProcessDone] = useState(userIsRegistered)
    const [open, setOpen] = useState(true)

    const actions = useActions(MainActions, "acceptConditions", "setCustomerRegistrationStatus")

    useEffect(() => {
        const unsubRegistrationResult = channel("GLOBAL").subscribe(
            "DMG_DAT/REGISTRATION_RESULT",
            ({ registrationDone }: { registrationDone?: boolean }) => {
                actions.setCustomerRegistrationStatus(!!registrationDone)
                setRegistrationProcessDone(!!registrationDone)

                if (registrationDone) {
                    Morpheus.closeView("1")
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12979), closeDelay: 3000, skin: "success" })
                } else {
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12980), closeDelay: 3000, skin: "danger" })
                }
            }
        )

        const unsubModalClose = channel("GLOBAL").subscribe("MODAL/CLOSED", handleModuleClose)

        return () => {
            unsubRegistrationResult?.()
            unsubModalClose?.()
        }
    }, [userIsRegistered])

    const handleModuleClose = useCallback(() => {
        setOpen(false)
        const workTaskId = getCurrentWorkTaskId()

        if (workTaskId) {
            channel("WORKTASK", workTaskId).publish("MODULE/CLOSED", location.pathname + location.search)
            history.push(renderRoute("/:workTaskId", { ...match.params }))
        }
    }, [location, registrationProcessDone])

    const handleConfirm = useCallback(() => {
        const { datRegistrationPath } = getBundleParams()
        const datRegistrationRoute = (datRegistrationPath && renderRoute(datRegistrationPath, match.params)) || ""

        Morpheus.showView("1", datRegistrationRoute)
        setOpen(false)

        // for testing purpose
        // channel("GLOBAL").publish("DMG_DAT/REGISTRATION_RESULT", { registrationDone: true })
    }, [match])

    const renderAlert = () => {
        return (
            <Alert
                title={translateText(827)}
                severity="error"
                variant="outlined"
                borderLeft
                action={
                    <Button disabled={!accepted} onClick={handleConfirm} color="success">
                        {translateText(13110)}
                    </Button>
                }
            >
                <Box>
                    <Typography variant="body2">{translateText(13108)}</Typography>
                    <Box display="flex">
                        <Checkbox checked={accepted} onClick={() => setAccepted((prevValue) => !prevValue)} />
                        <Typography sx={{ lineHeight: "2", marginRight: "1rem" }}>{translateText(1709)}</Typography>
                    </Box>
                </Box>
            </Alert>
        )
    }

    return (
        <Modal open={open} onOutsideClick={handleModuleClose}>
            <WrapperContent>
                {renderAlert()}
                <iframe title="dat-pdf-url" style={{ flex: 1 }} id="dat-pdf-url" src={agbUrl} />
            </WrapperContent>
        </Modal>
    )
}

const WrapperContent = styled(Box)(() => ({
    display: "flex",
    flexDirection: "column",
    height: "100%",
    flex: 1,
}))
