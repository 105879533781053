import { ShowCarModelDetailsRequest, ShowCarModelDetailsResponse } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"

export const showModelDetails = async (request: ShowCarModelDetailsRequest): Promise<ShowCarModelDetailsResponse> => {
    const url = `${getServiceUrl()}/ShowModelDetails`
    const authorization = getStoredAuthorization()

    return ajax<ShowCarModelDetailsResponse>({ url, body: request, method: "GET", authorization }).then((response) => {
        if (!response) {
            throw new Error()
        }

        return response
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.carsServiceUrl
}
