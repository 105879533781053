import { useLocalization } from "@tm/localization"
import { useRef } from "react"

import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { CarBodyType } from "../../../data/model/filters"
import Filter from "./_filter"

type Props = {
    items: Array<CarBodyType>
    clipped: boolean
    opened: boolean
    onToggleOpen(): void
    onToggleClip(): void
    onChangeFilter(item?: number): void
}

function BodyTypeFilter(props: Props) {
    const { translateText } = useLocalization()
    const filterRef = useRef<React.ElementRef<typeof Filter>>(null)

    const handleClick = (item: CarBodyType) => {
        props.onChangeFilter(item.isSelected ? undefined : item.id)
        !props.clipped && filterRef.current?.collapse()
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionBodyType, !item.isSelected)
    }

    const renderItem = (item: CarBodyType) => {
        const className = `bodytypes__item ${item.isAvailable ? "" : "bodytypes__item--disabled"} ${
            item.isSelected ? "bodytypes__item--selected" : ""
        }`
        return (
            <div className={className} title={item.description} key={item.id} onClick={() => handleClick(item)}>
                <img src={item.thumbnail} alt={item.description} />
            </div>
        )
    }

    const { items, opened } = props
    const selectedItem = items.find((x) => !!x.isSelected)

    return (
        <Filter
            {...props}
            name={translateText(303)}
            ref={filterRef}
            className="filters__bodytypes"
            disabled={!(items && items.length)}
            value={!opened && selectedItem ? selectedItem.description : undefined}
        >
            <div className="bodytypes">{items.map(renderItem)}</div>
        </Filter>
    )
}

export default BodyTypeFilter
