import { VehicleType } from "@tm/models"
import { useState } from "react"
import { atomFamily, useRecoilState } from "recoil"
import { getBundleParams } from "../../utils"

export enum FilterType {
    None = 0,
    BodyType = 1 << 0,
    ModelYear = 1 << 1,
    FuelType = 1 << 2,
    EngineCode = 1 << 3,
    EngineCapacity = 1 << 4,
    Sorting = 1 << 5,
}

const clippedFiltersRecoilAtom = atomFamily<FilterType, VehicleType>({
    key: "vehicle_filters_clipped",
    default:
        FilterType.BodyType | FilterType.EngineCode | FilterType.FuelType | FilterType.ModelYear | FilterType.EngineCapacity | FilterType.Sorting,
})

export function useClippedFilters(vehicleType: VehicleType) {
    const [clippedFilters, setClippedFilters] = useRecoilState(clippedFiltersRecoilAtom(vehicleType))

    const toggleFilterClip = (type: FilterType) => {
        setClippedFilters((current) => current ^ type)
    }

    const isFilterClipped = (filterType: FilterType): boolean => {
        return (clippedFilters & filterType) === filterType
    }

    return { toggleFilterClip, isFilterClipped }
}

export function useOpenedFilters(clipped: boolean) {
    const { showFuelFilterByDefault } = getBundleParams()
    const [openedFilters, setOpenedFilters] = useState(
        clipped
            ? FilterType.BodyType |
                  FilterType.ModelYear |
                  FilterType.EngineCapacity |
                  FilterType.Sorting |
                  (showFuelFilterByDefault ? FilterType.FuelType : FilterType.None)
            : 0
    )

    const toggleFilterOpen = (type: FilterType) => {
        setOpenedFilters((current) => current ^ type)
    }

    const isFilterOpened = (filterType: FilterType): boolean => {
        return (openedFilters & filterType) === filterType
    }

    return { toggleFilterOpen, isFilterOpened }
}
