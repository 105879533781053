import { memo, useMemo } from "react"
import { ArticleInfoType, IMicros } from "@tm/models"
import { mapOrderItemsToCompilationArticle } from "../../data/mapper"
import { isWM } from "../../utils"
import { MicroWrapper } from "../MicroWrapper"
import { AddToCompilation } from "../shared-components/component"

type Props = IMicros["compilations"]["add-to-compilation-basket"]

const AddToCompilationBasket = memo<Props>(({ disabled, articles }) => {
    const mappedArticles = useMemo(() => {
        return mapOrderItemsToCompilationArticle(articles)
    }, [articles])

    // we do not need this functionality for WM
    if (isWM()) {
        return null
    }

    // if only customArticles do not render the compilation icon - use KVA
    if (articles.findIndex((article) => article.articleInfoType !== ArticleInfoType.CustomArticle) === -1) {
        return null
    }

    return <AddToCompilation articles={mappedArticles} disabled={disabled} variant="small" />
})

export default function Wrapper(props: Props) {
    return (
        <MicroWrapper>
            <AddToCompilationBasket {...props} />
        </MicroWrapper>
    )
}
