import { RegistrationNoType } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { DATVehicleVinSearchResponse } from "./model"

export const datVehicleVinSearch = async (vin: string): Promise<DATVehicleVinSearchResponse> => {
    const url = `${getServiceUrl()}/ShowVehiclesByVin`
    const authorization = getStoredAuthorization()
    const body = {
        vin,
        lookupTypeId: RegistrationNoType.DatVin,
        forceUpdateRegistrationNoDetails: true,
    }

    return ajax<DATVehicleVinSearchResponse>({ url, body, method: "GET", authorization }).then((response) => {
        if (!response) {
            throw new Error("There was an error!")
        }
        return response
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.vrmLookupServiceUrl
}
