import { ExternalModule } from "@tm/models"
import "resize-observer-polyfill"

export function getIconName(item: ExternalModule) {
    return item.parameter.find((item) => item.key.toLowerCase() === "icon")?.value
}

export function getIconUrl(item: ExternalModule) {
    return item.parameter.find((item) => item.key.toLowerCase() === "url_logo")?.value
}

export function getIcon(item: ExternalModule) {
    return getIconName(item) || getIconUrl(item)
}

export function getRect(el: Element, type: "margin" | "bound"): DOMRectReadOnly {
    const htmlEl = el as HTMLElement
    let heightMargin = 0
    let widthMargin = 0
    if (type === "margin") {
        const styles = getComputedStyle(el)
        heightMargin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom)
        widthMargin = parseFloat(styles.marginLeft) + parseFloat(styles.marginRight)
    }
    const bounds = htmlEl.getBoundingClientRect()
    return {
        ...bounds,
        width: bounds.width + widthMargin,
        height: bounds.height + heightMargin,
    }
}

export class KeyValueLoader<T> {
    private data: { [key: string]: T | null } = {}

    private subscriber: ((data: { [key: string]: T }) => void)[] = []

    get values(): { [key: string]: T } {
        return Object.keys(this.data)
            .filter((x) => this.data[x] != null)
            .reduce((p, c) => ({ ...p, [c]: this.data[c] }), {})
    }

    existsKey(key: string) {
        return Object.keys(this.data).some((x) => x === key)
    }

    addKey(key: string) {
        this.data = { ...this.data, [key]: null }
    }

    publish(key: string, value: T) {
        this.data = { ...this.data, [key]: value }
        this.subscriber.forEach((x) => x(this.values))
    }

    subscribe(handler: (data: { [key: string]: T }) => void): () => void {
        if (this.subscriber.indexOf(handler) === -1) {
            this.subscriber.push(handler)
        }
        return () => {
            const index = this.subscriber.findIndex((x) => x === handler)
            if (index !== -1) {
                this.subscriber.splice(index, 1)
            }
        }
    }
}
