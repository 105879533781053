import { Box } from "@tm/components"
import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { useAvailableVehicleSearchOptions } from "@tm/utils"
import { ReactNode, Suspense, useEffect, useState } from "react"
import SelectionsCard from "./SelectionsCard"
import { VehicleLookupSelectionSwitch } from "../../_shared/VehicleLookupSelectionSwitch"

type Props = {
    vehicleType: VehicleType
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={<SelectionsCard isCurrent />}>
            <CardVehicleLookup {...props} />
        </Suspense>
    )
}

function CardVehicleLookup({ vehicleType }: Props) {
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const [selectedSearchConfig, setSelectedSearchConfig] = useState<VehicleLookupConfig | undefined>()
    const selectableVehicleLookups = availableVehicleSearches.configuredVehicleLookups.filter((lookup) => lookup.isSelectable)
    function defaultLookup(value: VehicleLookupConfig) {
        return value.isDefault
    }

    useEffect(() => {
        if (!selectedSearchConfig && selectableVehicleLookups) {
            setSelectedSearchConfig(selectableVehicleLookups.find(defaultLookup))
        }
    }, [selectedSearchConfig, selectableVehicleLookups, setSelectedSearchConfig])

    return selectableVehicleLookups.length > 1 ? (
        <Card>
            <VehicleLookupSelectionSwitch
                vehicleType={vehicleType}
                onSelectSearchOption={setSelectedSearchConfig}
                selectedSearchConfig={selectedSearchConfig}
                sx={{
                    alignSelf: "center",
                    height: "100%",
                    ".MuiInputBase-input": {
                        "&.MuiSelect-select": {
                            height: "100%",
                        },
                        "&:focus": {
                            backgroundColor: "transparent",
                        },
                        ".image": {
                            height: "32px",
                            alignSelf: "center",
                        },
                    },
                }}
                variant="standard"
            />
        </Card>
    ) : null
}

export function Card(props: { children?: ReactNode }) {
    return (
        <li className="card--current">
            <Box sx={{ height: "100%", display: "flex" }}>{props.children}</Box>
        </li>
    )
}
