import { getStoredAuthorization, ajax } from "@tm/utils"
import { SubUser, ShowSubUsersResponse } from "../model"
import { getSubUserServiceUrl } from ".."

export function showSubUsers(languageId: string) {
    const url = `${getSubUserServiceUrl()}/ShowSubUsers`
    const authorization = getStoredAuthorization()
    const body = { languageId }

    return ajax<ShowSubUsersResponse>({ url, authorization, body, method: "GET" })
}

export function createSubUser(user: SubUser): Promise<void> {
    const url = `${getSubUserServiceUrl()}/CreateSubUser`
    const authorization = getStoredAuthorization()
    const body = { user }

    return ajax({ url, authorization, body, method: "POST" })
}

export function setSubUser(user: SubUser): Promise<void> {
    const url = `${getSubUserServiceUrl()}/SetSubUser`
    const authorization = getStoredAuthorization()
    const body = { user }

    return ajax({ url, authorization, body, method: "POST" })
}

export function deleteSubUser(user: SubUser): Promise<void> {
    const url = `${getSubUserServiceUrl()}/SetSubUser`
    const authorization = getStoredAuthorization()
    const body = { user: { ...user, destroy: true } }

    return ajax({ url, authorization, body, method: "POST" })
}
