import { ErpInformationRequestItem } from "@tm/models"
import { BasketPart } from "../../models"

export function mapBasketPartsToErpInformationRequest(basketParts: BasketPart[]): ErpInformationRequestItem[] {
    const items: Array<ErpInformationRequestItem> = []
    basketParts.forEach((basketPart) => {
        const { orderItem, id, articleInformation, quantity } = basketPart.partItem
        const { wholesalerArticleNumber, articleNumber, supplierId, supplierName, productGroupId, productGroupName, description } = articleInformation
        if (orderItem?.isIncluded && supplierId && productGroupId && articleNumber && wholesalerArticleNumber) {
            items.push({
                itemId: id,
                wholesalerArticleNumber,
                dataSupplierArticleNumber: articleNumber,
                dataSupplierId: supplierId,
                dataSupplierName: supplierName,
                productGroupId,
                productGroupName,
                articleDescription: description,
                quantityValue: quantity.value,
            })
        }
    })
    return items
}
