import { useWorkTask } from "@tm/context-distribution"
import { useCallback } from "react"
import { AddWholesalerPartListRequest, AvailabilityStatus, PriceDictionary, WholesalerArticle, WholesalerPart } from "@tm/models"
import { useVehicle } from "../useVehicle"
import { useBasketParts } from "../../../../../../basket/src/hooks/basketState/useBasketParts"

export function useHandleAddWholesalerArticleToBasket(article: WholesalerArticle) {
    const { workTaskId, workTask } = useWorkTask() ?? {}
    const { customer } = workTask ?? {}
    const vehicle = useVehicle()
    const { addWholesalerPartList } = useBasketParts()

    const handleAddToBasket = useCallback(
        (quantity: number) => {
            if (!workTaskId) {
                return Promise.reject()
            }

            const wholesalerPart = mapWholesalerArticleToWholesalerPart(article, quantity)
            const request: AddWholesalerPartListRequest = {
                wholesalerParts: [wholesalerPart],
                workTaskId,
                vehicleId: vehicle?.id,
                customerId: customer?.id,
                usePercentageValues: true,
            }

            return addWholesalerPartList(request)
        },
        [workTaskId, article, vehicle?.id, customer?.id, addWholesalerPartList]
    )
    return handleAddToBasket
}

export function mapWholesalerArticleToWholesalerPart(article: WholesalerArticle, quantity?: number): WholesalerPart {
    return {
        quantityValue: quantity ?? article.quantity?.value ?? 1,
        wholesalerArticleNumber: article.wholesalerArticleNumber,
        description: article.additionalInformationExtended,
        availability: article.availability
            ? {
                  status: article.availability.type || AvailabilityStatus.Unknown,
                  description: article.availability.description ?? article.availability.shortDescription,
              }
            : undefined,
        erpInformation: article.prices
            ? {
                  prices: article.prices.reduce<PriceDictionary>(
                      (prev, current) => ({
                          ...prev,
                          [current.type]: {
                              currencyCode: current.currencyCode_Iso_4217,
                              currencySymbol: current.currencySymbol,
                              type: current.type,
                              value: current.value,
                              memos: current.memos,
                              rebate: current.rebate,
                              typeName: current.description,
                              unit: current.priceUnit,
                              vat: current.vat,
                              vatIncluded: current.taxIncluded,
                          },
                      }),
                      {}
                  ),
              }
            : undefined,
        itemRole: article.itemRole,
    }
}
