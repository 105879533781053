import { useState, useEffect, useCallback } from "react"
import { Article, DefaultArticleItemState, Note, NoteTypes, ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { useUpdateArticle } from "../../../../../compilations/src/data/hooks/useCompilations"
import { createUpdateRequest } from "../helpers"

export function useCompilationArticleNotes(
    showNotes: boolean,
    compilationId: string,
    article: Article,
    articleQuantity: number
): DefaultArticleItemState["notes"] {
    const [notes, setNotes] = useState<Note[]>([])
    const [visible, setVisible] = useState(false)

    const { updateArticle, updatingArticle } = useUpdateArticle()

    useEffect(() => {
        if (!showNotes || !article.additionalDescription?.length) {
            setNotes([])
            return
        }

        const note: Note = {
            noteId: article.id,
            text: article.additionalDescription,
            type: NoteTypes.ARTICLE,
        }
        setNotes([note])
    }, [article, showNotes, article.additionalDescription])

    const handleSave = useCallback(
        async (note: Note) => {
            updateArticle(createUpdateRequest(compilationId, article, articleQuantity, note.text))
        },
        [article, articleQuantity, compilationId, updateArticle]
    )

    const handleDelete = useCallback(async () => {
        updateArticle(createUpdateRequest(compilationId, article, articleQuantity, ""))
    }, [article, articleQuantity, compilationId, updateArticle])

    const toggle = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleNotes, !visible)
        setVisible((prev) => !prev)
    }, [visible])

    const hide = useCallback(() => {
        setVisible(false)
    }, [])

    return { notes, handleSave, handleDelete, isLoading: updatingArticle, visible, toggle, hide }
}
