import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { useOpenArticleDetailsClickHandle, isCentralOrder } from "@tm/utils"
import { useMemo } from "react"
import { useLocation } from "react-router"
import { getBundleParams } from "../utils"

export function useOpenBasketArticleDetails(workTaskId?: string) {
    const location = useLocation()
    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
    const { partsDetailsModalUrl, partsDetailsRedirectUrl } = getBundleParams()
    // The name of the module must be taken from the url
    const moduleName = useMemo(() => {
        if (articleDetailsInModal) {
            return
        }
        const [_, __, module] = location.pathname.split("/")
        return module
    }, [location, articleDetailsInModal])

    const handleOpenArticleDetails = useOpenArticleDetailsClickHandle({
        moduleName,
        partsDetailsUrl: isCentralOrder() || articleDetailsInModal ? partsDetailsModalUrl : partsDetailsRedirectUrl,
        workTaskId,
        openModal: isCentralOrder() || articleDetailsInModal ? Morpheus.showView : undefined,
    })

    return handleOpenArticleDetails
}
