import { getBundleParams } from "../../utils"
import { VehicleLookupSelection, VehicleLookupSelectionProps } from "./VehicleLookupSelection"
import { SearchSelection } from "./VehicleSearchFieldV2/SearchSelection"
import { useShowVehicleLookupConfig } from "../../data/hooks/useShowVehicleLookupConfig"

type Props = VehicleLookupSelectionProps

export function VehicleLookupSelectionSwitch(props: Props) {
    const { simpleVehicleSearch } = getBundleParams()

    const { data, isFetching } = useShowVehicleLookupConfig(simpleVehicleSearch)

    if (simpleVehicleSearch) {
        return <SearchSelection lookupConfigs={data?.lookupConfigs} loading={isFetching} />
    }

    return <VehicleLookupSelection {...props} />
}
