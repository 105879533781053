import { ShowCisOptionsRequest, ShowCisOptionsResponse } from "@tm/models"
import { getStoredAuthorization, ajax } from "@tm/utils"
import { getCisServiceUrl } from "../.."

function getServiceUrl() {
    return `${getCisServiceUrl()}/Options`
}

export async function showCisOptions(body: ShowCisOptionsRequest) {
    const url = `${getServiceUrl()}/ShowCisOptions`
    const authorization = getStoredAuthorization()

    return ajax<ShowCisOptionsResponse | undefined>({ url, body, authorization })
}
