import { useMemo } from "react"
import { AttachmentTypeAppointment } from ".."
import ICS from "../ics"

const DURATION_MINUTES = 30

export function useCalendarInfos(initialMetadata: AttachmentTypeAppointment, date: Date | string, endDate: Date | string) {
    const services = useMemo(() => {
        return (
            initialMetadata.serviceInfo?.repairServices?.map((s) => {
                const duration = s.duration || s.hours
                return {
                    id: s.id,
                    label: s.label || s.displayName,
                    duration: typeof duration === "string" ? parseFloat(duration.replace(",", ".")) : duration,
                }
            }) || []
        )
    }, [initialMetadata.serviceInfo?.repairServices])

    const calendarUrl = useMemo(() => {
        const calendar = new ICS()

        let subject = ""
        if (initialMetadata.customerInfo?.firstName) {
            subject += initialMetadata.customerInfo.firstName
        }
        if (initialMetadata.customerInfo?.lastName) {
            subject += (subject ? " " : "") + initialMetadata.customerInfo.lastName
        }
        if (initialMetadata.vehicleInfo?.plateId) {
            subject += (subject ? " - " : "") + initialMetadata.vehicleInfo.plateId
        }
        if (initialMetadata.vehicleInfo?.displayName) {
            subject += (subject ? " - " : "") + initialMetadata.vehicleInfo.displayName
        }

        let description = ""
        let duration = 0
        if (services.length) {
            description += "Angefragte Services:\\n\\n"
            services.forEach((s) => {
                description += `${s.label} - ${s.duration}h\\n\\n`
                duration += s.duration
            })
        }

        const appointmentDurationMinutes = (new Date(endDate).getTime() - new Date(date).getTime()) / 60000

        if (initialMetadata.note) {
            description += (description ? "\\n" : "") + initialMetadata.note
        }

        calendar.addEvent(subject, new Date(date), appointmentDurationMinutes || duration * 60 || DURATION_MINUTES, description)
        return calendar.getDataUrl()
    }, [initialMetadata, date, services, endDate])

    return { services, calendarUrl }
}
