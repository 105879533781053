import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { colorLightGray } from "../../../../../chats/styles"

export function getComponentStyles() {
    const { box } = getStyleTheme()

    return stylesheet({
        messageInfo: {
            background: colorLightGray,
            padding: "0.6rem",
            marginBottom: "0.2rem",
            marginRight: "0.2rem",
            borderRadius: box.radius,
            display: "flex",
            alignItems: "center",
        },
        wrapper: {
            flex: 1,
            overflow: "hidden",
            marginLeft: "1rem",
        },
        previewText: {
            marginTop: "0.4rem",
        },
        messageInfoDetailsButton: {
            marginTop: "0.4rem",
        },
    })
}
