import { getStyleTheme } from "@tm/context-distribution"
import { stylesheet } from "typestyle"
import { important } from "csx"

export function getComponentStyles() {
    const { colors, margin } = getStyleTheme()

    return stylesheet({
        main: {
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            background: "rgba(0,0,0,.6)",
            pointerEvents: "none",
        },
        content: {
            pointerEvents: "all",
            height: "100%",
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            padding: "4rem",
        },
        canvas: {
            display: "none",
        },
        closeButton: {
            position: "absolute",
            top: margin.m,
            right: margin.m,
            $nest: {
                ".btn__icon": {
                    height: "2rem",
                    width: "2rem",
                    fill: important(colors.light),
                    opacity: 1,
                },
            },
        },
        video: {
            maxWidth: "100%",
            maxHeight: "100%",
            marginBottom: margin.xl,
            objectFit: "cover",
        },
    })
}
