import { RepairTimeOptions } from "@tm/context-distribution"
import { RepairTimeProvider, Vehicle } from "@tm/models"
import { selectorFamily, useRecoilValueLoadable } from "recoil"

import { Models, Repositories } from "../../../data"

type AsyncResponse<T> = {
    loading: boolean
    response?: T
}

const topNodesSelector = selectorFamily<Models.Predictive.GetTopNodesResponse | undefined, Models.Predictive.GetTopNodesRequest | undefined>({
    key: "partsIndicator_topNodes",
    get: (request) => () => request && Repositories.Predictive.getTopNodes(request),
})

export function useTopNodes(
    vehicle: Vehicle | undefined,
    repairTimeProvider: RepairTimeProvider,
    repairTimeOptions: RepairTimeOptions | undefined
): AsyncResponse<Models.Predictive.GetTopNodesResponse | undefined> {
    const result = useRecoilValueLoadable(
        topNodesSelector(
            vehicle && repairTimeOptions
                ? {
                      modelId: vehicle.tecDocTypeId,
                      vehicleType: vehicle.vehicleType,
                      repairTimeProvider,
                      maxCount: 10,
                      manufacturerId: vehicle.tecDocManufacturerId,
                      defaultRepairTimeDivision: repairTimeOptions.division,
                      useManufacturerRepairTimeDivision: repairTimeOptions.useManufacturerRepairTimeDivision,
                  }
                : undefined
        )
    )

    switch (result.state) {
        case "loading":
            return { loading: true }
        case "hasValue":
            return { loading: false, response: result.contents }
        default:
            return { loading: false }
    }
}

const predictiveNodesSelector = selectorFamily<
    Models.Predictive.GetPredictiveNodesResponse | undefined,
    Models.Predictive.GetPredictiveNodesRequest | undefined
>({
    key: "partsIndicator_predictiveNodes",
    get: (request) => () => request && Repositories.Predictive.getPredictiveNodes(request),
})

export function usePredictiveNodes(
    vehicle: Vehicle | undefined,
    repairTimeProvider: RepairTimeProvider,
    repairTimeOptions: RepairTimeOptions | undefined
): AsyncResponse<Models.Predictive.GetPredictiveNodesResponse | undefined> {
    const result = useRecoilValueLoadable(
        predictiveNodesSelector(
            vehicle?.initialRegistration && vehicle.mileAge && repairTimeOptions
                ? {
                      modelId: vehicle.tecDocTypeId,
                      vehicleType: vehicle.vehicleType,
                      repairTimeProvider,
                      initialRegistration: vehicle.initialRegistration,
                      mileAge: vehicle.mileAge,
                      maxCount: 10,
                      manufacturerId: vehicle.tecDocManufacturerId,
                      defaultRepairTimeDivision: repairTimeOptions.division,
                      useManufacturerRepairTimeDivision: repairTimeOptions.useManufacturerRepairTimeDivision,
                  }
                : undefined
        )
    )

    switch (result.state) {
        case "loading":
            return { loading: true }
        case "hasValue":
            return { loading: false, response: result.contents }
        default:
            return { loading: false }
    }
}
