import { ERIKContentService } from "@tm/data"
import { useCallback, useState } from "react"
import { Box, Dialog, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { getFileCategory, stopPropagation } from "../../helpers"
import FileShare from "../fileShare/FileShare"
import PreviewDialogHeader from "./PreviewDialogHeader"
import { useFileDownload } from "../../hooks"
import { DialogContent } from "./DialogContent"

type Props = {
    file: ERIKContentService.FileMeta
    onNextFile: (direction: "next" | "prev") => void
    onClose: () => void
}

export function PreviewDialog({ file, onNextFile, onClose }: Props) {
    const { translateText } = useLocalization()
    const { downloadFile } = useFileDownload()

    const [showShare, setShowShare] = useState(false)

    const toggleShowShare = () => {
        setShowShare((prev) => {
            return !prev
        })
    }

    const onDownloadClick = useCallback(() => {
        downloadFile(file)
    }, [file])

    const onShareClick = useCallback(() => {
        toggleShowShare()
    }, [])

    const onFileShared = useCallback((success: boolean) => {
        if (success) {
            toggleShowShare()
        }
    }, [])

    return (
        <Dialog
            open={!!file}
            onOutsideClick={onClose}
            position="middle"
            PaperProps={{
                sx: {
                    maxWidth: 1200,
                    minWidth: 700,
                    minHeight: 600,
                    height: "calc(90vh - 32px)",
                    width: "calc(90vw - 32px)",
                    "& > .MuiBox-root": {
                        padding: 0,
                        height: "inherit",
                        "& > .MuiBox-root": {
                            height: "inherit",
                            "& > .MuiBox-root": {
                                height: "inherit",
                            },
                        },
                    },
                },
            }}
        >
            <Box sx={{ height: "100%", display: "grid", gridTemplateRows: "auto 1fr" }}>
                <PreviewDialogHeader
                    title={translateText(getFileCategory(file))}
                    onClose={onClose}
                    onDownloadClick={onDownloadClick}
                    onShareClick={onShareClick}
                />

                <Box sx={{ position: "relative" }}>
                    <DialogContent file={file} onNextFile={onNextFile} />
                    {showShare && (
                        <StyledShareContainer onClick={() => toggleShowShare()}>
                            <Box
                                onClick={stopPropagation()}
                                sx={{ background: "white", padding: "1em", borderRadius: "3px", animation: "fadeIn 0.1s ease-in-out" }}
                            >
                                <FileShare file={file} onShareCompleted={onFileShared} />
                            </Box>
                        </StyledShareContainer>
                    )}
                </Box>
            </Box>
        </Dialog>
    )
}

const StyledShareContainer = styled(Box)({
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(255,255,255,0.8)",
})
