import { Button, buttonClasses, styled } from "@tm/components"

export const GroupHeaderIconButton = styled(Button)(({ theme }) => {
    return {
        [`&.${buttonClasses.outlined}`]: {
            borderColor: "#202020",
            "&:hover": {
                color: theme.palette.common.black,
            },
        },
    }
})
