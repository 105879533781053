import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        tooltip: {
            $nest: {
                ".tooltip__content": {
                    padding: margin.m,
                },
            },
        },
        tooltipButton: {
            display: "block",
            $nest: {
                "&+.btn": {
                    marginLeft: 0,
                    marginTop: margin.s,
                },
            },
        },
        appLogo: {
            maxHeight: "3rem",
            maxWidth: "5rem",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
