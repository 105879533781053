import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleImportFastCalculation(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { workTask, importFastCalculation } = this.props
    const { importFastCalculation: request } = data

    if (request && workTask) {
        if (!request.workTaskId) {
            request.workTaskId = workTask.id
        }

        if (!request.vehicleId && workTask.vehicle) {
            request.vehicleId = workTask.vehicle.id
        }

        importFastCalculation(request)
    }
}
