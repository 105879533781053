import { Button, Icon } from "@tm/components"
import { getBundleParams } from "../../../utils"

type Props = {
    isLoading: boolean
    label: string
    size?: "large" | "medium"
    onClick(): void
}

export default function ActiveCustomer({ isLoading, label, onClick, size = "medium" }: Props) {
    if (!getBundleParams().activeCustomerSearchable) {
        return null
    }

    return (
        <Button startIcon={<Icon name="phone-speaker" />} color="primary" onClick={onClick} disabled={isLoading} size={size}>
            {label}
        </Button>
    )
}
