import { useUser } from "@tm/context-distribution"
import { ArticleListSortingMode, AvailabilityStatus, ExternalModule, PriceType } from "@tm/models"
import { useMemo } from "react"
import { getBundleParams } from "../../../utils"
import { DEFAULT_PAGE_SIZE } from "."
import { useDefaultErpSystem } from "../hooks/useDefaultErpSystem"

/** If new sorting modes should be implemented the mapping to a translation id must be added here. */
const SORTING_MODES: Required<ArticleListSortingConfiguration["availableModes"]> = {
    default: 1812,
    price: "{{53}} ({{13549}})",
    priceDescending: "{{53}} ({{13550}})",
    availability: 746,
    erpSortNumber: 13548,
}

/** Defines the order of the articles when availability sorting is active. */
const AVAILABILITY_SORTING_ORDER: ArticleListSortingConfiguration["availabilitySortingOrder"] = [
    AvailabilityStatus.Available,
    AvailabilityStatus.AvailableInCentralWarehouse,
    AvailabilityStatus.AvailableInAlternativeWarehouse,
    AvailabilityStatus.NightExpress,
    AvailabilityStatus.ManufacturerAvailable,
    AvailabilityStatus.PartiallyAvailable,
    AvailabilityStatus.PartiallyAvailableInCentralWarehouse,
    AvailabilityStatus.PartiallyAvailableInAlternativeWarehouse,
    AvailabilityStatus.NotAvailable,
    AvailabilityStatus.ManufacturerUnavailable,
    AvailabilityStatus.ManufacturerNotYetRequested,
    AvailabilityStatus.NotShown,
    AvailabilityStatus.NotYetDefined,
    AvailabilityStatus.NotYetRequested,
    AvailabilityStatus.NoLongerAvailable,
    AvailabilityStatus.Unknown,
]

/** Contains all available configuration options for the article list sorting functionality. */
export type ArticleListSortingConfiguration = {
    // Article list sorting
    /** Defines if article list sorting is enabled for the user. */
    enabled: boolean
    /** If article list contains more articles then configured here the sorting will be disabled. */
    maximumArticleCount: number
    /** If configured a default sorting will be activated after the first page of the article list is loaded. */
    defaultMode: ArticleListSortingMode | undefined
    /** Contains all enabled sorting modes. */
    availableModes: Partial<Record<ArticleListSortingMode, string | number>>
    /** Defines the price type that should be used for sorting. */
    sortingPriceType: PriceType
    /** Defines the order of the articles when availability sorting is active. */
    availabilitySortingOrder: AvailabilityStatus[]
}

/** Can be used to get the configuration for the article list sorting. */
export function useArticleListSortingConfiguration(): ArticleListSortingConfiguration {
    const { userContext, userSettings } = useUser()
    const defaultErpSystemConfig = useDefaultErpSystem()

    const { articleListPageSize } = userContext.parameter
    const pageSize = typeof articleListPageSize === "number" ? articleListPageSize : DEFAULT_PAGE_SIZE

    const erpSystem = useMemo(
        () => userContext.externalModules?.find((x) => defaultErpSystemConfig?.id === x.id),
        [userContext.externalModules, defaultErpSystemConfig]
    )

    const { availableModes, sortingPriceType, availabilitySortingOrder } = useMemo(() => getSortingConfiguration(erpSystem), [erpSystem])
    const enabled = Object.keys(availableModes).length > 1

    return {
        enabled,
        maximumArticleCount: pageSize /** @todo Does this have to be configured independend from the article list paging size? */,
        defaultMode: enabled ? userSettings?.articleListDefaultSorting ?? getBundleParams().defaultArticleListSorting : undefined,
        availableModes,
        sortingPriceType,
        availabilitySortingOrder,
    }
}

function getSortingConfiguration(erpSystem: ExternalModule | undefined): {
    availableModes: ArticleListSortingConfiguration["availableModes"]
    sortingPriceType: PriceType
    availabilitySortingOrder: AvailabilityStatus[]
} {
    const availableModes: ArticleListSortingConfiguration["availableModes"] = {
        default: SORTING_MODES.default,
    }
    let sortingPriceType = PriceType.Purchase

    if (erpSystem?.parameter) {
        const sortByPriceValue = parseInt(erpSystem.parameter.find((x) => x.key === "SortingByPriceType")?.value ?? "")
        if (!Number.isNaN(sortByPriceValue) && PriceType[sortByPriceValue]) {
            availableModes.price = SORTING_MODES.price
            availableModes.priceDescending = SORTING_MODES.priceDescending
            sortingPriceType = sortByPriceValue
        }

        const sortByAvailabilityValue = erpSystem.parameter.find((x) => x.key === "SortingByAvailability")?.value
        if (sortByAvailabilityValue === "true") {
            availableModes.availability = SORTING_MODES.availability
        }

        const sortByErpValue = erpSystem.parameter.find((x) => x.key === "SortingByErpSortNumber")?.value
        if (sortByErpValue === "true") {
            availableModes.erpSortNumber = SORTING_MODES.erpSortNumber
        }
    }

    return {
        availableModes,
        sortingPriceType,
        availabilitySortingOrder: AVAILABILITY_SORTING_ORDER,
    }
}
