import { Suspense, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react"
import { ImperativeFunctions, Loader, MessageContainer, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { bundleChannel } from "../../../../bundle-channel"
import { Message, useChatMessages, useForeignUsersInChat, useLastReadInfo, useOwnUserId } from "../../../../data/hooks"
import ImageViewer from "../image-viewer"
import MessageInput from "../input/input"
import MessageComponent from "../message"
import { getComponentStyles } from "./styles"

type Props = { chatId: string }

const NewMessageBR = styled("div")({
    width: "100%",
    margin: "2em 0",
    paddingBottom: "0.5em",
    textAlign: "center",
    borderBottom: "2px solid #dedede",
})

function Messages({ chatId }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()
    const { foreignUsers, isLoading: foreignUsersLoading } = useForeignUsersInChat(chatId)
    const { lastReadInfo, setLastReadInfo, lastReadInfoLoaded } = useLastReadInfo()
    const messages = useChatMessages(chatId)
    const [image, setImage] = useState<{ url: string; message: Message }>()
    const newMessageBRRef = useRef<HTMLDivElement>(null)
    const ref = useRef<ImperativeFunctions>(null)
    const initialScrollRef = useRef<boolean>(true)
    const ownUserId = useOwnUserId()
    const stashLastReadInfo = useRef<Date>()
    let newMessages = false

    useEffect(() => {
        if (ref.current && messages && initialScrollRef.current) {
            initialScrollRef.current = false
            setTimeout(() => {
                // jump initial to the Bottom, if you stay on the Screen, scroll smoooth
                ref.current?.scrollDown(false)
            }, 10)
        } else if (newMessageBRRef.current) {
            setTimeout(() => {
                newMessageBRRef.current?.scrollIntoView({
                    behavior: "auto",
                    block: "center",
                })
            }, 10)
        }
    }, [messages, ref, newMessageBRRef])

    useEffect(() => {
        bundleChannel("CHAT").subscribe("SHOW_IMAGE_VIEWER", ({ message }) => {
            if (message.attachment) {
                setImage({ url: message.attachment.uri, message })
            }
        })
    }, [])

    useEffect(() => {
        const lastMessage = messages?.last()
        if (setLastReadInfo && lastReadInfoLoaded && lastMessage) {
            const lastMessageDate = new Date(lastMessage.inserted)
            if (!lastReadInfo?.messageDate || lastMessageDate > new Date(lastReadInfo.messageDate)) {
                if (lastMessage.authorId === ownUserId) {
                    stashLastReadInfo.current = new Date(lastMessageDate)
                }
                setLastReadInfo({
                    messageId: lastMessage.id,
                    messageDate: lastMessageDate,
                })
            }
        }
    }, [messages, lastReadInfo, lastReadInfoLoaded, setLastReadInfo, ownUserId])

    useEffect(() => {
        if (!stashLastReadInfo.current && lastReadInfo?.messageDate) {
            stashLastReadInfo.current = new Date(lastReadInfo.messageDate)
        }
    }, [lastReadInfo])

    useLayoutEffect(() => {
        // Nach oben scrollen, nur wenn die letzte Nachricht neu ist und vom Owner
        const lastMessage = messages?.last()
        if (lastMessage) {
            const lastMessageDate = new Date(lastMessage.inserted)
            if (!lastReadInfo?.messageDate || (lastMessageDate > new Date(lastReadInfo.messageDate) && lastMessage.authorId === ownUserId)) {
                ref.current?.scrollDown(true)
            }
        }
    }, [lastReadInfo, messages, ownUserId])

    const isNewMessage = (message: Message) => {
        return stashLastReadInfo.current && !newMessages && new Date(message.inserted) > stashLastReadInfo?.current && message.authorId !== ownUserId
    }

    if (!messages) {
        return null
    } // TODO: show nice message

    if (foreignUsersLoading) {
        return <Loader />
    }

    return (
        <>
            <div className={classNames.usersArea}>
                {!!foreignUsers && (
                    <Typography className={classNames.usersAreaText} sx={{ fontWeight: 600 }}>
                        {foreignUsers.map((u) => u.displayName).join(", ")}
                    </Typography>
                )}
            </div>

            <MessageContainer ref={ref}>
                {messages.map((message) => {
                    if (isNewMessage(message)) {
                        newMessages = true
                        return (
                            <>
                                <NewMessageBR key="newMessages" ref={newMessageBRRef}>
                                    {translateText(13124)}
                                </NewMessageBR>
                                <MessageComponent key={message.id} message={message} />
                            </>
                        )
                    }
                    return <MessageComponent key={message.id} message={message} />
                })}
            </MessageContainer>

            <div className={classNames.messageInputWrapper}>
                <MessageInput chatId={chatId} />
            </div>

            {!!image && (
                <div className={classNames.imageViewerWrapper}>
                    <ImageViewer url={image.url} message={image.message} onClose={() => setImage(undefined)} />
                </div>
            )}
        </>
    )
}

export default function Wrapper(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])

    return (
        <div className={classNames.wrapper}>
            <Suspense fallback={<Loader />}>
                <Messages {...props} />
            </Suspense>
        </div>
    )
}
