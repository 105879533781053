import { useEffect, useRef } from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useDebounce = (callback: () => void, delay: number, dependencies: any[]) => {
    const timerRef = useRef<NodeJS.Timeout | null>(null)

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current)
        }

        timerRef.current = setTimeout(() => {
            callback()
        }, delay)

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [callback, delay, ...dependencies])
}

export default useDebounce
