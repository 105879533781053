import { percent } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Grid, GridProps, Text } from "@tm/controls"

type Props = GridProps & {
    title?: string
    mandatory?: boolean | string[]
}

export default function FieldItem({ mandatory, title, children, ...rest }: Props) {
    const style = useMemo(() => getStyle(), [])

    return (
        <Grid {...rest}>
            <div className={style.content}>
                <Text className={style.fieldTitle} modifiers="strong">
                    {title}
                    {(mandatory && <span className={style.mandatory}>*</span>) || ""}
                </Text>
                {children}
            </div>
        </Grid>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        content: {
            padding: theme.margin.m,
            display: "flex",
            height: percent(100),
            flexDirection: "column",
            $nest: {
                ".react-datepicker__input-container": {
                    width: percent(100),
                },
                ".radio": {
                    display: "flex",
                    marginTop: theme.margin.s,
                    $nest: {
                        label: {
                            flex: 1,
                        },
                    },
                },
                ".checkbox": {
                    marginTop: theme.margin.s,
                },
            },
        },
        fieldTitle: {
            marginBottom: theme.margin.s,
            // flex: 1,
        },
        mandatory: {
            color: theme.colors.danger,
        },
    })(FieldItem)
}
