import { FaultCode } from "./faultCode"
import { ServiceItem } from "./serviceItem"

export type VehicleScans = {
    detectedVin: string
    scanItems: ScanItem[]
    supportedScanTypes: ESupportedScanTypes
}

export type ScanItem = {
    alerts: object
    autoDetect: object
    startTime: string
    endTime: string
    faultCodes: FaultCode
    serviceItems: ServiceItem
    type: EScanItemType
}

export enum ESupportedScanTypes {
    InboundAndOutbound,
    InboundOnly,
}

export enum EScanItemType {
    Inbound,
    Outbound,
}
