import { useState } from "react"
import { Button, ConfirmationDialog, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { LoaderSmall } from "../StyledComponents"

type Props = {
    buttonTitleId: number
    /**
     * A confirmationKey must be send for showing the Checkbox. The confirmationKeys must be unique and the new ones cand be added into the ConfirmationDialogKeys enum from @tm/models
     */
    confirmationKey?: string
    dialogTextId: number
    disableButton?: boolean
    ids?: Array<string>
    showDialog?: boolean
    onConfirmRemove(ids?: Array<string>): void
}

export default function DeleteButton(props: Props) {
    const { dialogTextId, ids, buttonTitleId, confirmationKey, disableButton, showDialog, onConfirmRemove } = props
    const { translateText } = useLocalization()
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(showDialog || false)

    function handleConfirmClick() {
        onConfirmRemove(ids)
        setShowDeleteDialog(false)
    }

    return (
        <>
            <Button
                title={translateText(buttonTitleId)}
                onClick={() => setShowDeleteDialog(true)}
                startIcon={!showDeleteDialog ? <Icon name="delete" /> : undefined}
                disabled={disableButton}
                variant="text"
            >
                {showDeleteDialog && <LoaderSmall />}
            </Button>
            <ConfirmationDialog
                open={showDeleteDialog}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                onConfirm={() => handleConfirmClick()}
                onCancel={() => setShowDeleteDialog(false)}
                confirmationKey={confirmationKey}
            >
                {translateText(dialogTextId)}
            </ConfirmationDialog>
        </>
    )
}
