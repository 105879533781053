import { ListItem, styled } from "@tm/components"

export const StyledListItem = styled(ListItem, {
    shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
    backgroundColor: "#80808014",
    marginBottom: ".5em",
    color: theme.colors?.primary ?? "inherit",
    "& .MuiButtonBase-root.MuiListItemButton-root": {
        padding: "1em",
    },
    "& .MuiListItemText-root": {
        flex: 1,
    },
    "&:hover": {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    ...(isSelected && {
        border: `1px solid ${theme.palette.primary.main}`,
    }),
}))
