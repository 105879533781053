import { ActionDispatch, AsyncAction } from "@tm/morpheus"
import { equals } from "@tm/utils"
import { ModuleNavigationState, ModuleTab } from "./model"

export * from "./model"

export type ComponentActionType =
    | { type: "START_MODULE_OPENED"; payload: ModuleTab }
    | { type: "RESET_TABS" }
    | { type: "CLOSE_TAB"; payload: ModuleTab }
    | { type: "CHANGE_WORKTASK"; payload: string }
    | { type: "STATE_LOADING" }
    | { type: "STATE_LOADED"; payload?: ModuleNavigationState }

const DEFAULT_STATE: ModuleNavigationState = {
    initialized: false,
    loading: false,
    tabs: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ModuleNavigationState {
    switch (action.type) {
        case "START_MODULE_OPENED": {
            if (!action.payload) {
                return state
            }

            const tab: ModuleTab = {
                ...action.payload,
                url: `${location.pathname}${location.search}`,
            }

            return addTabToState(state, tab)
        }
        case "RESET_TABS": {
            return addTabToState(state)
        }
        case "CLOSE_TAB": {
            return removeTabFromState(state, action.payload)
        }
        case "STATE_LOADING": {
            return {
                ...state,
                initialized: true,
                loading: true,
            }
        }
        case "STATE_LOADED": {
            return action.payload || state // mergeTabs(state, action.payload ? action.payload.tabs : DEFAULT_STATE.tabs)
        }
    }
    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "START_MODULE_OPENED": {
            dispatch(action)
        }
    }
}

function start(): AsyncAction<ComponentActionType> {
    return (dispatch, getState) => {
        const state = getState() as ModuleNavigationState
        if (!state.loading) {
            dispatch({ type: "STATE_LOADING" })
            loadState().then((state) => dispatch({ type: "STATE_LOADED", payload: state }))
        }
    }
}

function resetSelection(): ComponentActionType {
    return {
        type: "RESET_TABS",
    }
}

function closeTab(tab: ModuleTab): ComponentActionType {
    return {
        type: "CLOSE_TAB",
        payload: tab,
    }
}

export type IActions = typeof Actions

export const Actions = {
    start,
    resetSelection,
    closeTab,
}

function addTabToState(state: ModuleNavigationState, tab?: ModuleTab) {
    const tabs = [...state.tabs.map((x) => ({ ...x, isSelected: false }))]
    if (!tab) {
        return {
            ...state,
            tabs,
            more: [],
            splitted: false,
        }
    }

    let pos = tabs.findIndex((x) => x.title === tab.title)

    if (pos < 0) {
        pos = tabs.length
        tabs.push(tab)
    } else if (!equals(tab, tabs[pos])) {
        tabs.splice(pos, 1, tab)
    }

    state = {
        ...state,
        tabs,
    }

    if (state.initialized) {
        saveState(state)
    }

    tabs[pos].isSelected = true
    return state
}

function removeTabFromState(state: ModuleNavigationState, tab: ModuleTab) {
    state = {
        ...state,
        tabs: state.tabs.filter((x) => x.url !== tab.url),
    }

    saveState(state)

    return state
}

const STATE_KEY = "__start__module-navigation__"

function loadState() {
    return new Promise<any>((resolve) => {
        const json = sessionStorage.getItem(STATE_KEY)
        resolve(json ? JSON.parse(json) : undefined)
    })
}

function saveState(state: ModuleNavigationState) {
    sessionStorage.setItem(STATE_KEY, JSON.stringify(state))
}
