import { VehicleLookupConfig, VehicleType } from "@tm/models"
import MainModelSeriesSelection from "./mainmodelseries"
import ManufacturerSelection from "./manufacturer"
import ModelSelection from "./model"
import ModelSeriesSelection from "./modelseries"

type Props = {
    vehicleType: VehicleType
    selectedSearchConfig?: VehicleLookupConfig
}

export default function SelectionComponent({ vehicleType, selectedSearchConfig }: Props) {
    return (
        <div className="selection-container">
            <ManufacturerSelection vehicleType={vehicleType} />
            <MainModelSeriesSelection vehicleType={vehicleType} />
            <ModelSeriesSelection vehicleType={vehicleType} />
            <ModelSelection vehicleType={vehicleType} selectedSearchConfig={selectedSearchConfig} />
        </div>
    )
}
