import { useMemo } from "react"
import { Customer, Vehicle } from "@tm/models"
import { concat, useIsUrlAvailableCached } from "@tm/utils"
import { PageNavigationTabInfo } from "../components/page-navigation/components/itemWithCar"

export function createPageNavigationTabInfo(hasModelThumbnail: boolean, customer?: Customer, vehicle?: Vehicle): PageNavigationTabInfo {
    return {
        carThumbnail: (hasModelThumbnail ? vehicle?.modelThumbnail : vehicle?.modelSeriesImage) || "",
        customerInfoText: concat(", ", customer?.customerNo, customer?.lastName),
        carInfoText: concat(" ", vehicle?.manufacturer, vehicle?.modelSeries, vehicle?.model),
    }
}

export function usePageNavigationTabInfo(customer?: Customer, vehicle?: Vehicle) {
    const { isAvailable } = useIsUrlAvailableCached(vehicle?.modelThumbnail)
    return useMemo<PageNavigationTabInfo>(() => createPageNavigationTabInfo(isAvailable, customer, vehicle), [isAvailable, vehicle, customer])
}
