import { useEffect, useState } from "react"
import { Icon, TabControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ProductGroupFiltersModel, RegisteredModels, ShowVehicleRecordsViewMode, Vehicle, VehicleRecordsContainer } from "@tm/models"
import { Container } from "@tm/nexus"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { styled } from "@tm/components"
import { OrderedPartByVehicle } from "../../../data/model/vouchers"
import { NavigationItem } from "../business"

type Props = RouteComponentProps<{ vehicleDetailsSubPage?: string }> & {
    tabs: { [key: string]: NavigationItem | false }
    orderedArticles?: OrderedPartByVehicle[]
    vehicle: Vehicle
}

const StyledIcon = styled(Icon)({
    width: "4em",
    marginRight: "0.4em",
    opacity: "1 !important",
})

function NavigationComponent(props: Props) {
    const { translateText } = useLocalization()
    const [vehicleRecords, setVehicleRecords] = useState<Array<ProductGroupFiltersModel>>()

    const filterTabs = ([tabIdentifier, item]: [string, NavigationItem | false]) => {
        if (!item) {
            return false
        }

        switch (tabIdentifier) {
            case "orderedArticles":
                return !!props.orderedArticles?.length
            case "characteristics":
                return vehicleRecords?.length
            case "customerData":
                return props.vehicle.customerId
            default:
                return true
        }
    }

    const tabs = Object.entries(props.tabs).filter(filterTabs)

    useEffect(() => {
        // If no subpage is already opened ...
        if (!props.match.params.vehicleDetailsSubPage) {
            // ... find the first one without an absolute path
            const defaultTab = tabs.find((x) => x[1] && !x[1].isAbsolutePath && !x[1].disabled)

            if (defaultTab) {
                navigateToTab(defaultTab[0], "replace")
            }
        }
    }, [props.match])

    useEffect(() => {
        const container: VehicleRecordsContainer = Container.getInstance(RegisteredModels.VehicleRecords)
        let firstResponse = true
        let timeoutId: number | undefined

        const subscription = container.subscribe({ vehicleId: props.vehicle.id, viewMode: ShowVehicleRecordsViewMode.ShowRecordedValuesOnly })
        const unsub = subscription.addListener("loaded", (response) => {
            setVehicleRecords(response.productGroupFilters)

            if (firstResponse && !response.productGroupFilters?.length) {
                timeoutId = window.setTimeout(() => {
                    subscription.load()
                }, 5000)
            }

            firstResponse = false
        })
        subscription.load()

        return () => {
            unsub()
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [props.vehicle.id])

    if (!tabs.length) {
        return null
    }

    function navigateToTab(tabIdentifier: string, openMode: "push" | "replace" = "push") {
        const tab = props.tabs[tabIdentifier]
        if (!tab) {
            return
        }

        let url
        if (tab.isAbsolutePath) {
            url = renderRoute(tab.path, props.match.params)
        } else {
            url = renderRoute(props.match.path, { ...props.match.params, vehicleDetailsSubPage: tabIdentifier })
        }
        url += location.search

        if (openMode === "replace") {
            props.history.replace(url)
        } else {
            props.history.push(url)
        }
    }

    return (
        <TabControl onTabSelect={navigateToTab} selectedTabIdentifier={props.match.params.vehicleDetailsSubPage || ""}>
            {tabs.map(([tabIdentifier, item]) =>
                item ? (
                    <TabControl.Tab disabled={item.disabled} identifier={tabIdentifier} key={tabIdentifier}>
                        {!!item.icon && <StyledIcon name={item.icon} />}
                        {translateText(item.text)}
                    </TabControl.Tab>
                ) : null
            )}
        </TabControl>
    )
}

export default withRouter(NavigationComponent)
