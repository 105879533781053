import { Badge, Button, Switch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import * as React from "react"
import { useIsUrlAvailable } from "@tm/utils"
import { VehiclePrintOptions } from "../../../data/repositories/print"
import { IActions, NavigationState } from "../business"

type Props = {
    handlePrintArticleDetails(imageUrl: string): void
    onPrintOptionsChanged: IActions["onPrintOptionsChanged"]
    isPrintLoading: boolean
    printOptions: VehiclePrintOptions
    navigationState: NavigationState
}

export const PrintLayer: React.FC<Props> = ({ handlePrintArticleDetails, onPrintOptionsChanged, isPrintLoading, printOptions, navigationState }) => {
    const { translateText } = useLocalization()

    const { isAvailable, isLoading } = useIsUrlAvailable(navigationState.vehicle?.modelThumbnail)

    const { printVehicleInfo, printManufacturerInfo, printOrderedParts, printDatInfo } = printOptions

    const printActive = printVehicleInfo === true || printManufacturerInfo === true || printOrderedParts === true || printDatInfo === true

    const handlePrint = () => {
        handlePrintArticleDetails(isAvailable ? navigationState.vehicle?.modelThumbnail! : navigationState.modelImage)
    }

    return (
        <>
            <Switch
                label={translateText(931)}
                status={printOptions.printVehicleInfo}
                alignLabel="left"
                onChange={(e) => onPrintOptionsChanged(e, "printVehicleInfo")}
            />
            <Switch
                label={translateText(321)}
                status={printOptions.printManufacturerInfo}
                alignLabel="left"
                onChange={(e) => onPrintOptionsChanged(e, "printManufacturerInfo")}
            />
            <Switch
                label={translateText(12837)}
                status={printOptions.printOrderedParts}
                disabled={!navigationState.hasOrderedParts}
                alignLabel="left"
                onChange={(e) => onPrintOptionsChanged(e, "printOrderedParts")}
            />
            <Switch
                label={translateText(1267)}
                status={printOptions.printDatInfo}
                disabled={!navigationState.hasDatInfo}
                alignLabel="left"
                onChange={(e) => onPrintOptionsChanged(e, "printDatInfo")}
            />
            <div className="print-vehicle-info-wrapper">
                {isPrintLoading && <Badge title="loading" skin="dark" loading />}
                <Button icon="print" onClick={handlePrint} disabled={!printActive && !isLoading} className="print-vehicle-info-btn">
                    {translateText(49)}
                </Button>
            </div>
        </>
    )
}
