import { InputGroup, SearchField, Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { initSelector, renderRoute } from "@tm/utils"
import { useState } from "react"
import { useSelector } from "react-redux"
import { useHistory, useParams } from "react-router"
import { Box, Button, Icon, Loader, Typography, styled } from "@tm/components"
import { dateToString } from "../../../data/helpers"
import { HistoryEntry } from "../../../data/models"
import { historySelector } from "../../main/business"
import { MatchParams } from "./models"

type Props = {
    handleOpenHistoryCalculation(historyEntry: HistoryEntry, openTool: boolean): void
}

const selector = initSelector(historySelector)

export default function LastCalculations({ handleOpenHistoryCalculation }: Props) {
    const { translateText, date: formatDate } = useLocalization()
    const [historyData] = useSelector(selector)
    const { historyEntries, historyEntriesLoading } = historyData
    const matchParams = useParams<MatchParams>()
    const history = useHistory()

    const [inputValue, setInputValue] = useState("")
    const [filterValue, setFilterValue] = useState("")

    const handleInputChange = (input: string) => {
        setInputValue(input)
    }

    const handleFilterValueChange = (value: string) => {
        setFilterValue(value)
    }

    const redirectToDashboard = () => {
        const url = renderRoute("/:workTaskId", { ...matchParams })
        history.push(url)
    }

    const visibleDta =
        historyEntries?.filter(
            (x) =>
                !filterValue ||
                x.customerData.vehicle?.description.toLocaleLowerCase().includes(filterValue.toLowerCase()) ||
                x.historyId.toString().includes(filterValue)
        ) || []

    return (
        <StyledBox className="last-calculations">
            <Box display="flex">
                <Typography>{translateText(1581)}</Typography>
                <Button sx={{ ml: "1em" }} startIcon={<Icon name="dashboard" />} className="dashboard" onClick={redirectToDashboard}>
                    {translateText(760)}
                </Button>
            </Box>

            <Box className="header">
                <Box className="title">
                    <Typography className="last-calculations-title" fontSize="1.5rem">
                        {translateText(1465)}
                    </Typography>
                </Box>
            </Box>

            <Box className="search-field">
                <Typography>{translateText(1582)}</Typography>
                <InputGroup>
                    <SearchField
                        value={inputValue}
                        showClear
                        size="l"
                        onChangeConfirm={handleFilterValueChange}
                        onChange={handleInputChange}
                        onChangeReset={() => handleFilterValueChange("")}
                        disabled={!historyEntries || historyEntries.length === 0}
                    />
                    <Button
                        startIcon={<Icon name="search" />}
                        size="large"
                        onClick={() => handleFilterValueChange(inputValue)}
                        disabled={!historyEntries || historyEntries.length === 0}
                    />
                </InputGroup>
            </Box>

            <Box className="calculations">
                {historyEntriesLoading && <Loader size="small" />}

                {!historyEntriesLoading && !!visibleDta?.length && (
                    <Table
                        data={visibleDta}
                        scrollable
                        columns={[
                            <Table.Column
                                key="history-id"
                                className="history-id number"
                                renderItemContent={(data: HistoryEntry) => <Table.Cell>{data.historyId}</Table.Cell>}
                            >
                                {translateText(1584)}
                            </Table.Column>,
                            <Table.Column
                                key="update-date"
                                className="update-date time"
                                renderItemContent={(data: HistoryEntry) => <Table.Cell>{dateToString(data.updateDateTime)}</Table.Cell>}
                            >
                                {translateText(98)}
                            </Table.Column>,
                            <Table.Column
                                key="customer-name"
                                className="customer-name"
                                renderItemContent={(data: HistoryEntry) => <Table.Cell>{data.customerData?.name}</Table.Cell>}
                            >
                                {translateText(107)}
                            </Table.Column>,
                            <Table.Column
                                key="description"
                                className=" description"
                                renderItemContent={(data: HistoryEntry) => <Table.Cell>{data.customerData?.vehicle?.description}</Table.Cell>}
                            >
                                {translateText(99)}
                            </Table.Column>,
                            <Table.Column
                                key="time"
                                className="time"
                                renderItemContent={(data: HistoryEntry) => (
                                    <Table.Cell>{formatDate(new Date(data.customerData?.vehicle?.dateFirstRegistered), "d")}</Table.Cell>
                                )}
                            >
                                {translateText(124)}
                            </Table.Column>,
                            <Table.Column
                                key="vin"
                                className="vin"
                                renderItemContent={(data: HistoryEntry) => <Table.Cell>{data.customerData?.vehicle?.vin}</Table.Cell>}
                            >
                                {translateText(101)}
                            </Table.Column>,
                            <Table.Column
                                key="km-status"
                                className="km-status"
                                renderItemContent={(data: HistoryEntry) => <Table.Cell>{data.customerData?.vehicle?.mileage}</Table.Cell>}
                            >
                                {translateText(12593)}
                            </Table.Column>,
                            <Table.Column
                                key="actions"
                                className="actions actions"
                                renderItemContent={(data: HistoryEntry) => (
                                    <Table.Cell>
                                        <Button onClick={() => handleOpenHistoryCalculation(data, true)}>{translateText(1580)}</Button>
                                        <Button disabled={!data.hasCalcData} onClick={() => handleOpenHistoryCalculation(data, false)}>
                                            {translateText(1579)}
                                        </Button>
                                    </Table.Cell>
                                )}
                            />,
                        ]}
                    />
                )}
                {!historyEntriesLoading && !visibleDta?.length && (
                    <Box className="article-list__panel article-list__status">
                        <Typography fontWeight="bold">{translateText(323)}</Typography>
                    </Box>
                )}
            </Box>
        </StyledBox>
    )
}

const StyledBox = styled(Box)({
    padding: "1em",
    height: "calc(100% - 1em)",

    ".search-field": {
        display: "flex",
        flex: "0",
        alignItems: "center",
        marginBottom: "1em",

        ".text": {
            fontSize: "1.3rem",
        },

        ".inputgroup": {
            boxShadow: "none",
            margin: "0 1em",
        },
    },

    ".fancy-list": {
        marginRight: "2em",
        "&__item": {
            "span:last-child": {
                justifyContent: "flex-end",
            },
        },
    },

    ".last-calculations-content": {
        flex: "1",
        margin: ".5em 2em",
        display: "flex",
        flexDirection: "column",
        height: "100%",
    },

    margin: "1em 0",
    flex: "1",
    display: "flex",
    flexDirection: "column",

    ".header": {
        flex: "0",
    },

    ".calculations": {
        flex: "1",
        marginRight: "-3rem",
    },

    ".calculations .scrollbar__view": {
        paddingRight: ".5em",
    },

    ".fancy-list .number": {
        display: "flex",
        flex: "0 0 8%",
        flexDirection: "column",
        paddingLeft: "1.5rem",
        minWidth: "7rem",
        marginRight: "0",
    },

    ".fancy-list .number>span:first-child": {
        paddingLeft: ".55em",
    },

    ".fancy-list .time": {
        flex: "0 0 7%",
        whiteSpace: "normal",
        minWidth: "5rem",
    },

    ".fancy-list .customer-name": {
        flex: "0 0 10%",
        minWidth: "6rem",
    },

    ".fancy-list .description": {
        flex: "0 0 18%",
        wordBreak: "break-all",
        minWidth: "12rem",
    },

    ".fancy-list .vin": {
        flex: "0 0 13%",
        minWidth: "10rem",
    },

    ".fancy-list .km-status": {
        flex: "0 0 6%",
        minWidth: "4rem",
    },

    ".fancy-list__block.actions.actions": {
        flex: "1 1 10%",
        justifyContent: "space-around",
        alignItems: "center",
        display: "flex",
        paddingRight: ".5em",
        flexWrap: "wrap",
        marginTop: "-0.25em",
        minWidth: "11rem",
        Button: {
            flex: "1 1 48%",
            marginTop: ".25em",
            marginLeft: ".25em",
        },
    },

    ".fancy-list__head .number": {
        paddingLeft: "0",
    },
})
