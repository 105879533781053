import { Stack } from "@tm/components"
import { BikeModel, CarModel, RegistrationNoType, TruckModel, VehicleLookupConfig, VehicleShortInfo } from "@tm/models"
import { VehicleSearchField } from "../VehicleSearchField"
import { VehicleSearchfieldV1Props } from "../VehicleSearchFieldSwitch"
import { LicensePlateSearchFieldV2 } from "./LicensePlateSearchFieldV2"
import { useSelectedSimpleLookupConfig } from "../../../data/hooks/useSelectedSimpleLookupConfig"

export function VehicleSearchV2(props: VehicleSearchfieldV1Props) {
    const { selectedConfig } = useSelectedSimpleLookupConfig()

    // TODO's
    // In the future, we want to handle the search logic in this component, or to enter it in hooks from here.
    // The search data should be saved and transferred at +1 result
    // There should only be one point from which a search addresses the backend

    const handleUniqueVehicleFound = (model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string) => {
        props.onUniqueVehicleFound?.(model, query) // support old Logic
    }

    const handleStartSearch = (query: string, regNoType?: RegistrationNoType, selectedVehicleLookupConfig?: VehicleLookupConfig) => {
        props.onStartSearch?.(query, regNoType, selectedVehicleLookupConfig) // support old logic
    }

    const handleAttachVehicleId = (vehicleId: string, query: string) => {
        props.onAttachVehicleId?.(vehicleId, query) // support old logic
    }

    const handleStartSearchLicensPlate = (query: string) => {
        props.onStartSearch?.(query) // support old logic
    }

    return (
        <Stack spacing={1}>
            {selectedConfig?.showAdditionalSearchField && !props?.hideSpecialLicensePlateSearchField && (
                <LicensePlateSearchFieldV2
                    quota={selectedConfig.quota}
                    licensePlateCode={selectedConfig.licensePlateCode}
                    onStartSearch={handleStartSearchLicensPlate}
                />
            )}
            <VehicleSearchField
                {...props}
                onAttachVehicleId={handleAttachVehicleId}
                onStartSearch={handleStartSearch}
                onUniqueVehicleFound={handleUniqueVehicleFound}
            />
        </Stack>
    )
}
