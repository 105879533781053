import { useLocalization } from "@tm/localization"
import { Headline } from "@tm/controls"
import { RouteComponentProps } from "@tm/utils"

type ComponentProps = RouteComponentProps & {
    text: string
    size?: "xl" | "l" | "m" | "s" | "xs"
}

const translationIdPattern = /\{\{(.*?)\}\}/g

export default function Component({ text, size }: ComponentProps) {
    const { translateText } = useLocalization()
    const finalText = text.replace(translationIdPattern, ($0, $1) => translateText($1))
    return <Headline size={size}>{finalText}</Headline>
}
