import { useUser } from "@tm/context-distribution"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { decodeUniqueId, equals } from "@tm/utils"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { useEffect } from "react"
import { asWegasWindow } from "../extern/component"
import { MainActions, managerSelector } from "../main/business"
import { isVehicleChanged } from "./business/helpers"
import { MatchParams } from "../../data/models"

export default function ModuleManager() {
    const { vehicle, customer } = useSelector(managerSelector)
    const actions = useActions(MainActions, "setVehicle", "loadVehicleDetails", "setCustomer", "reset", "resetStore")
    const user = useUser()
    const matchParams = useParams<MatchParams>()
    const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(matchParams.workTaskId ?? "")

    useEffect(() => {
        const unsubCustomer = channel("WORKTASK", workTaskId).subscribe(
            "CUSTOMER/LOADED",
            (newCustomer) => {
                if (customer && !equals(newCustomer, customer)) {
                    asWegasWindow?.close()
                }

                actions.setCustomer(newCustomer)
            },
            true
        )

        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    vehicle && asWegasWindow?.close()
                    actions.setVehicle(newVeh)
                    actions.loadVehicleDetails(newVeh)
                }
            },
            true
        )

        return () => {
            unsubCustomer?.()
            unsubVehicle?.()
        }
    }, [user?.userContext?.system.systemType])

    useEffect(() => {
        const unsub = channel("WORKTASK", workTaskId).subscribe("MODULE/CLOSED", (module) => {
            module.includes("aswegas") && actions.resetStore()
            asWegasWindow?.close()
        })
        return unsub
    }, [])

    return null
}
