import { percent } from "csx"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { MessageInline, NumberField, Table, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { clone, initSelector } from "@tm/utils"
import { Actions } from "../../bussiness"
import { hasError, hasFieldError } from "../../bussiness/helpers"
import { DocumentsState, OldBatteryType } from "../../bussiness/model"
import { fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { Field, StyledTable } from "../common"

const selector = initSelector(fieldsSelector)

export default function OldBatteriesTab() {
    const style = useMemo(() => getStyle(), [])
    const { translateText, number } = useLocalization()

    const actions = useActions(Actions, "updateField")
    const [model] = useSelector(selector)

    if (!model) {
        return null
    }

    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText))
    const { oldBatteryTypeList } = model.oldBatteriesInfo

    const handleQuantityChange = (idx: number, value: number) => {
        const item = oldBatteryTypeList[idx]

        item.quantity = value
        item.dangerPointsNr = value * item.value
        item.tons = value * item.tonsValue
        actions.updateField(["oldBatteriesInfo", "oldBatteryTypeList"], oldBatteryTypeList)
    }

    const handleDangerPoint = (idx: number, value: number) => {
        const item = oldBatteryTypeList[idx]

        item.dangerPointsNr = value
        actions.updateField(["oldBatteriesInfo", "oldBatteryTypeList"], oldBatteryTypeList)
    }

    const handleWeightTonst = (idx: number, value: number) => {
        const item = oldBatteryTypeList[idx]

        item.tons = value
        actions.updateField(["oldBatteriesInfo", "oldBatteryTypeList"], oldBatteryTypeList)
    }

    const renderQuantity = (item: OldBatteryType, idx: number) => {
        return (
            <Table.Cell>
                <NumberField value={item.quantity || 0} onChange={(_) => handleQuantityChange(idx, _)} />
            </Table.Cell>
        )
    }

    const renderADRNaming = (item: OldBatteryType) => {
        return (
            <Table.Cell>
                <div style={{ display: "inline" }}>
                    <Text>{`${translateText(12433)} `}</Text>
                    <Text modifiers="strong" size="l">
                        {item.id}
                    </Text>
                </div>
                <Text modifiers="block">UN 2794 Abfall Batterien (Akkumulatoren), nass, gefüllt mit Säure, 8, (E)</Text>
            </Table.Cell>
        )
    }

    const renderDangerPoint = (item: OldBatteryType, idx: number) => {
        return (
            <Table.Cell>
                <NumberField readonly value={item.dangerPointsNr || 0} onChange={(_) => handleDangerPoint(idx, _)} />
            </Table.Cell>
        )
    }

    const renderWeightTons = (item: OldBatteryType, idx: number) => {
        return (
            <Table.Cell>
                <NumberField readonly value={item.tons} onChange={(_) => handleWeightTonst(idx, _)} stepSize={0.001} />
            </Table.Cell>
        )
    }

    const renderFooter = () => {
        const sum = model.oldBatteriesInfo.oldBatteryTypeList.reduce((prev, cur) => prev + cur.dangerPointsNr, 0)
        const sumTons = number(
            model.oldBatteriesInfo.oldBatteryTypeList.reduce((prev, cur) => prev + cur.tons, 0),
            3
        )

        return (
            <div className={style.footer}>
                <Text className={style.footerTitle}>{translateText(1533)}</Text>
                <Text className={style.sumItem}>{sum}</Text>
                <Text className={style.sumItem}>{sumTons}</Text>
            </div>
        )
    }

    const batteryList = clone(model.oldBatteriesInfo.oldBatteryTypeList)

    return (
        <>
            <Field.Container>
                <Field.Item title={translateText(1513)} m={12}>
                    <TextField
                        showClear
                        maxLength={32}
                        value={model.customerInfo.customerNote}
                        onChange={(value) => actions.updateField(["customerInfo", "customerNote"], value)}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item title={translateText(1708)} m={12}>
                    <TextField
                        showClear
                        maxLength={10}
                        value={model.oldBatteriesInfo.producerNumber}
                        onChange={(value) => actions.updateField(["oldBatteriesInfo", "producerNumber"], value)}
                    />
                </Field.Item>
            </Field.Container>

            <StyledTable
                data={batteryList}
                className={hasFieldError(modelState["oldBatteriesInfo.oldBatteryTypeList"])}
                groupBy={() => ""}
                renderGroupFooter={renderFooter}
                columns={[
                    <Table.Column renderItemContent={renderQuantity}>{translateText(89)}</Table.Column>,
                    <Table.Column renderItemContent={renderADRNaming}>{translateText(1529)}</Table.Column>,
                    <Table.Column renderItemContent={renderDangerPoint}>{translateText(1530)}</Table.Column>,
                    <Table.Column renderItemContent={renderWeightTons}>{translateText(1707)}</Table.Column>,
                ]}
            />

            {hasError(modelState["oldBatteriesInfo.oldBatteryTypeList"]) && (
                <MessageInline message="" description={translateText(1663)} size="s" skin="warning" />
            )}
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        footer: { display: "flex", padding: theme.margin.m },
        footerTitle: { flex: 1 },
        sumItem: { flexBasis: percent(25) },
    })(OldBatteriesTab)
}
