import { useLocalization } from "@tm/localization"
import { StatusSwitch } from "@tm/controls"
import { Button, Icon, Stack, Typography, SelectorPaper } from "@tm/components"
import { ConfirmationDialogKeys } from "@tm/models"
import { VisibilityStatus } from "../../../business"
import { BasketWork } from "../../../../../models"
import { CustomerDefaults } from "../../../../../data/model"
import DeleteButton from "../../../../_shared/DeleteButton"

type Props = {
    allSelected: boolean
    loading: boolean
    resetButtonEnabled: boolean
    selectedWorks: BasketWork[]
    customerDefaults?: CustomerDefaults
    onRemoveWorks(ids: string[]): void
    onResetRepairTimes(items: BasketWork[], customerDefaults?: CustomerDefaults): void
    onSelectAll(): void
    onUnselectAll(): void
    onIncludeExcludeWorks(parts: BasketWork[]): void
}

export default function WorksSelection(props: Props) {
    const { loading, allSelected, selectedWorks, resetButtonEnabled, customerDefaults } = props
    const { translateText } = useLocalization()

    function getWorksVisibilityStatus(): VisibilityStatus {
        if (selectedWorks.every((work) => work.workItem.isIncluded)) {
            return VisibilityStatus.allVisible
        }
        if (selectedWorks.every((work) => !work.workItem.isIncluded)) {
            return VisibilityStatus.noneVisible
        }
        return VisibilityStatus.someVisible
    }

    const items = selectedWorks.length
    const visibilityStatus = getWorksVisibilityStatus()
    const selectedIds = selectedWorks.map((work) => work.workItem.id)

    function renderUpdateWorkVisibilityStatus() {
        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (visibilityStatus === VisibilityStatus.allVisible) {
            status = "checked"
        } else if (visibilityStatus === VisibilityStatus.someVisible) {
            status = "checked-not-equal"
        } else if (visibilityStatus === VisibilityStatus.noneVisible) {
            status = "unchecked"
        }

        return (
            <StatusSwitch label={translateText(49)} alignLabel="left" status={status} onChange={() => props.onIncludeExcludeWorks(selectedWorks)} />
        )
    }

    return (
        <SelectorPaper>
            <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="label">
                    {translateText(701)}: {items} {translateText(479)}
                </Typography>
                <Button onClick={() => (allSelected ? props.onUnselectAll() : props.onSelectAll())} size="small">
                    {translateText(allSelected ? 703 : 702)}
                </Button>
                {renderUpdateWorkVisibilityStatus()}
                <Button
                    title={translateText(48)}
                    onClick={() => props.onResetRepairTimes(selectedWorks, customerDefaults)}
                    startIcon={<Icon name="refresh" />}
                    disabled={!resetButtonEnabled}
                    size="small"
                    variant="text"
                />
                <DeleteButton
                    ids={selectedIds}
                    buttonTitleId={624}
                    dialogTextId={834}
                    disableButton={loading}
                    onConfirmRemove={(ids) => ids && props.onRemoveWorks?.(ids)}
                    confirmationKey={ConfirmationDialogKeys.DeleteBasketItem}
                />
                <Button
                    variant="text"
                    startIcon={<Icon name="cancel-x" />}
                    size="large"
                    title={translateText(317)}
                    onClick={() => props.onUnselectAll()}
                />
            </Stack>
        </SelectorPaper>
    )
}
