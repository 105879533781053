const srcList: string[] = []
export function loadScript(src: string) {
    if (!srcList.includes(src)) {
        const script = document.createElement("script")
        script.async = true
        script.defer = true
        script.src = src
        document.getElementsByTagName("head")[0].appendChild(script)
        srcList.push(...srcList, src)
    }
}
