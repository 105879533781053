import { ShowCarModelDetailsResponse, Vehicle } from "@tm/models"
import { FastServiceData, Fields, RecordWorks, Work } from "../models"
import { FastServiceResponse } from "./fastcalculator-getMaintenancePlan/model"
import { worksActions } from ".."
import { convertCustomWorks } from "../../helpers/convertCustomWorks"
import { getInitialCustomWorks } from "../../components/inspection-work/helpers/getInitialCustomWorks"

export function mapFastServiceResponse(data: FastServiceResponse): FastServiceData {
    const { selections, additionalWorkSelections, followupWorkSelections, type, works, results, tireSpecifications } = data.cockpitContext
    let recordWorks: RecordWorks | undefined

    if (works?.length) {
        recordWorks = mapWorksToRecord(works)
        worksActions.updateCustomWorks(convertCustomWorks(getInitialCustomWorks()))
    }

    if (results?.length) {
        results.forEach((result) => (result.provider = mapRepairTimeProvider(result.provider)))
    }

    return {
        calcId: data.calcId,
        cockpitContext: {
            selections,
            additionalWorkSelections,
            followupWorkSelections,
            type,
            recordWorks,
            results,
            tireSpecifications,
        },
        eTag: data.eTag,
    }
}

const mapRepairTimeProvider = (provider: number): number => {
    switch (provider) {
        case 1:
            return 5 // HaynesPro
        case 2:
            return 3 // Autodata
        case 3:
            return 4 // TecRmiCar
        default:
            return provider
    }
}

export function createGetMaintenancePlanRequest(
    vehicle: Vehicle,
    fields: Fields,
    carModel: ShowCarModelDetailsResponse,
    languageId?: string,
    defaultHourlyRate?: number,
    baseCalculationCalcId?: string
) {
    return {
        kTypNr: vehicle.tecDocTypeId,
        vehicleId: vehicle.id,
        initialData: {
            eTypArt: vehicle.vehicleType,
            monthYearDateFormat: "MM.yyyy",
            numberLocal: "",
            dateFormat: languageId === "1" ? "dd.MM.yyyy" : "MM.dd.yyyy",
            kTypNr: vehicle.tecDocTypeId,
            vin: fields.vin,
            nextVehicleId: vehicle.id,
            mileage: fields?.mileage ?? 0,
            isLonglife: fields?.longLife,
            engineCode: fields?.engineCode,
            labourRate: defaultHourlyRate ? defaultHourlyRate * 100 : undefined,
            cylinders: carModel?.modelDetails?.cylinderCount ?? 0,
            registrationDate: fields.initialRegistration,
            engineCodes: carModel?.modelDetails?.engineCodes ?? [],
            calculateIncludedAdditionalWorks: fields.calculateIncludedAdditionalWorks ?? false,
            baseCalculationCalcId: baseCalculationCalcId ?? "",
        },
    }
}

const mapWorksToRecord = (works: Work[]): RecordWorks => {
    return works.groupBy((x) => getCorrectPath(x.locationId))
}

export const getCorrectPath = (location: string) => {
    switch (location) {
        case "ENGINE_COMPARTMENT":
            return "Engine compartment"
        case "UNDER_THE_VEHICLE":
            return "Under the vehicle"
        case "EXTERIOR":
            return "Exterior"
        case "INTERIOR":
            return "Interior"
        case "GENERAL":
            return "General"
        case "CHECK_WORK":
            return "Check work"
        case "TEST_DRIVE":
            return "Test drive"
        case "TIRES_WHEELS":
            return "Tires wheels"
        default:
            return ""
    }
}
