import { useState } from "react"
import { Voucher, VoucherItem, SendVoucherConfirmationResponse } from "../model"
import * as Data from "../repositories/cis/vouchers"

export function useSendVoucherConfirmation() {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState<SendVoucherConfirmationResponse>()
    const [error, setError] = useState("")

    function onSendVoucherConfirmation(voucher: Voucher, voucherItems?: VoucherItem[]) {
        setIsLoading(true)
        setResponse(undefined)
        return Data.sendVoucherConfirmation({ voucher, voucherItems })
            .then((resp) => setResponse(resp))
            .catch((e) => setError(e))
            .finally(() => setIsLoading(false))
    }

    return {
        onSendVoucherConfirmation,
        voucherConfirmationResponse: response,
        voucherConfirmationLoading: isLoading,
        voucherConfirmationError: error,
    }
}
