import { Warehouse, Availability, AvailabilityStatus } from "@tm/models"
import { AvailabilityQuantity, Icon, Text } from "@tm/controls"
import { mapAvailabilityStatusToIconName } from "@tm/utils"
import { style } from "typestyle"

type Props = {
    availability?: Availability
    tourDate?: string
    tourName?: string
    warehouses?: Array<Warehouse>
    onClick?(): void
}

export const AvailabilityComponent = ({ availability, tourDate, tourName, warehouses, onClick }: Props) => {
    if (warehouses) {
        let quantity1 = 0
        let quantity2 = 0
        let quantity3 = 0
        let quantity4 = 0

        warehouses.forEach((w) => {
            w.quantities?.forEach((q) => {
                if (q.availability) {
                    switch (q.availability.type) {
                        case AvailabilityStatus.Available:
                            quantity1 += q.value || 0
                            break
                        case AvailabilityStatus.AvailableInAlternativeWarehouse:
                            quantity2 += q.value || 0
                            break
                        case AvailabilityStatus.PartiallyAvailable:
                            quantity3 += q.value || 0
                            break
                        case AvailabilityStatus.NotAvailable:
                            quantity4 += q.value || 0
                            break
                        default:
                            break
                    }
                }
            })
        })

        return (
            <AvailabilityQuantity
                quantities={[
                    { status: AvailabilityStatus.Available, quantity: quantity1 },
                    { status: AvailabilityStatus.AvailableInAlternativeWarehouse, quantity: quantity2 },
                    { status: AvailabilityStatus.PartiallyAvailable, quantity: quantity3 },
                    { status: AvailabilityStatus.NotAvailable, quantity: quantity4 },
                ]}
                tourDate={tourDate}
                tourName={tourName}
                onClick={onClick}
            />
        )
    }
    if (availability) {
        return (
            <div className={style({ display: "flex", alignItems: "center" })}>
                <Icon
                    size="xl"
                    name={mapAvailabilityStatusToIconName(availability.type)}
                    className={`${style({ opacity: 1 })} availability-quantity__icon--status-${availability.type}`}
                />
                <Text size="s" className={style({ marginLeft: "0.2rem" })}>
                    {tourName}
                </Text>
            </div>
        )
    }

    return null
}
