import { channel, ErpInformation, getCurrentWorkTaskId, ImportRepairEstimationRequest, ImportResponse, TmaEModule } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { equals } from "@tm/utils"
import { batch } from "react-redux"
import { BundleActions, BundleActionTypes } from "../../../business"
import { ICalculationItem, Part, UpdatedTotals, Work } from "../../../data/models"
import { getBundleParams } from "../../../utils"
import { useContainer } from "../../../data/repositories"
import { createCostEstimationRequest } from "../../../data/repositories/costEstimate/helpers"
import { GetCalculationDataResponse } from "../../../data/repositories/getCalculationData/model"
import { createUpdateTotalsRequest } from "../../../data/repositories/updateTotalPrices/helpers"
import { MainState } from "../../main"
import { MainActionsType } from "../../main/business"
import { CalculationState } from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "CALCULATION_DATA_LOADING" }
    | { type: "CALCULATION_DATA_LOADED"; payload: GetCalculationDataResponse }
    | { type: "CALCULATION_DATA_ERROR" }
    | { type: "REPLACE_ARTICLE"; payload: { oeArticle: ICalculationItem; part: Part } }
    | { type: "SELECT_ARTICLE"; payload: { oeArticle: ICalculationItem } }
    | { type: "SELECT_WORK"; payload: { work: Work } }
    | { type: "CHANGE_OE_ARTICLE_QUANTITY"; payload: { item: ICalculationItem; quantity: number } }
    | { type: "CHANGE_ALTERNATIVE_ARTICLE_QUANTITY"; payload: { item: ICalculationItem; quantity: number } }
    | { type: "CHANGE_COLLAPSABLE_STATE"; payload: { key: string; value: boolean } }
    | { type: "SET_ERP_ARTICLES"; payload: ErpInformation[] }
    | { type: "SET_UPDATED_TOTALS"; payload: UpdatedTotals }
    | { type: "SET_UPDATED_TOTALS_ERROR" }
    | { type: "IMPORT_LOADING"; payload: boolean }

export const DEFAULT_STATE: CalculationState = {
    items: [],
    works: [],
    collapsableItems: {},
    externToolUpdate: true,
    erpArticles: [],
    totals: {
        totalSparePartsPrice: 0,
        totalWorksPrice: 0,
        totalNetPrice: 0,
        totalGrossPrice: 0,
    },
}

export function reduce(state = { ...(DEFAULT_STATE as CalculationState) }, action: ComponentActionType): CalculationState {
    // TODO implement reducers when WS is done
    switch (action.type) {
        case "RESET": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "CALCULATION_DATA_LOADING": {
            return {
                ...DEFAULT_STATE,
                calculationLoading: true,
            }
        }
        case "CALCULATION_DATA_LOADED": {
            const { items, works, totals } = action.payload
            const { showTraderArticlePrio } = getBundleParams()

            if (showTraderArticlePrio) {
                items?.forEach((x) => {
                    if (x.showWMArticleFirst) {
                        // eslint-disable-next-line no-param-reassign
                        x.selectedPart = x.alternativeParts?.find((y) => y.wholesalerArticleNumber === x.oeArticle.articleTraderNumber)
                    }
                })
            }

            return {
                ...state,
                calculationLoading: false,
                calculationError: false,
                calculationLoaded: true,
                works: works ?? [],
                items: items ?? [],
                totals,
                initialSparePartsPrice: totals?.totalSparePartsPrice,
            }
        }
        case "CALCULATION_DATA_ERROR": {
            return {
                ...state,
                calculationLoading: false,
                calculationError: true,
                calculationLoaded: true,
            }
        }
        case "REPLACE_ARTICLE": {
            const { oeArticle, part } = action.payload
            const { items } = state

            return {
                ...state,
                items: items.map((x) => ({
                    ...x,
                    ...(equals(x, oeArticle) && {
                        selectedPart: (!equals(x.selectedPart, part) && part) || undefined,
                    }),
                })),
                updateTotalsInProgress: true,
            }
        }
        case "SELECT_ARTICLE": {
            const { oeArticle } = action.payload
            const { items } = state

            return {
                ...state,
                items: items.map((x) => ({
                    ...x,
                    ...(equals(x, oeArticle) && {
                        isSelected: !x.isSelected,
                    }),
                })),
                updateTotalsInProgress: true,
            }
        }
        case "SET_ERP_ARTICLES": {
            return {
                ...state,
                erpArticles: [...state.erpArticles, ...action.payload],
            }
        }
        case "SELECT_WORK": {
            const { work } = action.payload
            return {
                ...state,
                works: state.works.map((x) => ({ ...x, ...(equals(x, work) && { isSelected: !x.isSelected }) })),
                addToShoppingBasketDone: false,
                updateTotalsInProgress: true,
            }
        }
        case "CHANGE_OE_ARTICLE_QUANTITY": {
            const { item, quantity } = action.payload
            return {
                ...state,
                items: state.items.map((x) => ({ ...x, ...(equals(x, item) && { oeArticle: { ...x.oeArticle, quantityValue: quantity } }) })),
                addToShoppingBasketDone: false,
                updateTotalsInProgress: true,
            }
        }
        case "CHANGE_ALTERNATIVE_ARTICLE_QUANTITY": {
            const { item, quantity } = action.payload
            return {
                ...state,
                items: state.items.map((x) => ({
                    ...x,
                    ...(equals(x, item) && x.selectedPart && { selectedPart: { ...x.selectedPart, quantityValue: quantity } }),
                })),
                addToShoppingBasketDone: false,
                updateTotalsInProgress: true,
            }
        }
        case "CHANGE_COLLAPSABLE_STATE": {
            return {
                ...state,
                collapsableItems: {
                    ...state.collapsableItems,
                    [action.payload.key]: action.payload.value,
                },
            }
        }
        case "SET_UPDATED_TOTALS": {
            return {
                ...state,
                totals: action.payload,
                updateTotalsInProgress: false,
            }
        }
        case "SET_UPDATED_TOTALS_ERROR": {
            return {
                ...state,
                updateTotalsInProgress: false,
                updateTotalsFailed: true,
            }
        }
        case "IMPORT_LOADING": {
            return {
                ...state,
                importLoading: action.payload,
            }
        }
        default:
            return state
    }
}

const addToShoppingBasket =
    (
        importToCostEstimation: (importRequest: ImportRepairEstimationRequest, tmaModule: TmaEModule) => Promise<ImportResponse | undefined>,

        memo?: string
    ): AsyncAction<ComponentActionType, MainState> =>
    (dispatch, getState) => {
        dispatch({ type: "IMPORT_LOADING", payload: true })

        const workTaskId = getCurrentWorkTaskId()
        const state = getState()
        const {
            manager: { vehicle },
            calculation: { items, works },
        } = state

        const request = createCostEstimationRequest(workTaskId!, items, works, vehicle, memo)

        importToCostEstimation(request, TmaEModule.GLASS_SAINTGOBAIN).then(
            () => {
                dispatch({ type: "IMPORT_LOADING", payload: false })
            },
            (error) => {
                dispatch({ type: "IMPORT_LOADING", payload: false })
                channel("APP").publish("TOAST_MESSAGE/SHOW", { message: error, closeDelay: 3000, skin: "warning" })
            }
        )
    }

const updateTotals =
    (erpInfo?: ErpInformation[]): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const state = getState()
        const container = useContainer()

        const {
            calculation: { items, works },
        } = state

        const request = createUpdateTotalsRequest(items, works, erpInfo || state.calculation.erpArticles)

        container
            .action("updateTotalsPrices")(request)
            .then(
                (res) => dispatch({ type: "SET_UPDATED_TOTALS", payload: res }),
                () => dispatch({ type: "SET_UPDATED_TOTALS_ERROR" })
            )
    }

const changeAlternativeArticleQuantity =
    (item: ICalculationItem, quantity: number): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "CHANGE_ALTERNATIVE_ARTICLE_QUANTITY", payload: { item, quantity } })
            dispatch(updateTotals())
        })
    }

const changeOeArticleQuantity =
    (item: ICalculationItem, quantity: number): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "CHANGE_OE_ARTICLE_QUANTITY", payload: { item, quantity } })
            dispatch(updateTotals())
        })
    }

const replaceArticle = (oeArticle: ICalculationItem, part: Part): ComponentActionType => ({ type: "REPLACE_ARTICLE", payload: { oeArticle, part } })

const selectArticle =
    (oeArticle: ICalculationItem): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "SELECT_ARTICLE", payload: { oeArticle } })
            dispatch(updateTotals())
        })
    }

const selectWork =
    (work: Work): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "SELECT_WORK", payload: { work } })
            dispatch(updateTotals())
        })
    }

const changeCollapsiableState = (key: string, value: boolean): MainActionsType => ({ type: "CHANGE_COLLAPSABLE_STATE", payload: { key, value } })

const setErpArticles = (articles: ErpInformation[]): MainActionsType => ({ type: "SET_ERP_ARTICLES", payload: articles })

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectArticle,
    replaceArticle,
    selectWork,
    changeOeArticleQuantity,
    changeAlternativeArticleQuantity,
    changeCollapsiableState,
    setErpArticles,
    addToShoppingBasket,
    updateTotals,
}
