export function hasFieldError(modelState?: Array<string>) {
    if (modelState && modelState.length) {
        return "field-error"
    }
    return ""
}

export function hasError(modelState: Array<string>): boolean {
    return !!(modelState && modelState.length)
}
