import { Box, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    error?: { message?: string }
}
export function ErrorWrapper(props: Props) {
    const { translateText } = useLocalization()
    return (
        <Box display="flex" alignItems="center" py={2}>
            <Icon name="error-filled" color="error" height="25px" width="25px" />
            <Typography variant="h4" component="div" px={1}>
                {translateText(787)}
            </Typography>
            <Typography variant="body3">{props.error?.message}</Typography>
        </Box>
    )
}
