import { Quantity, Warehouse } from "@tm/models"
import { useMemo } from "react"

export type StockTuple = { warehouse: Warehouse; quantity: Quantity }

export function useStockTuple(warehouses?: Array<Warehouse>) {
    return useMemo(() => {
        const list: Array<StockTuple> = []
        warehouses?.forEach((w) => {
            w.quantities?.forEach((q) => list.push({ warehouse: w, quantity: q }))
        })
        return list
    }, [warehouses])
}
