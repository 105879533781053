import { ETyresSeason } from "@tm/models"
import { SeasonPrefixes, TyreModelsWithSeason } from "../data/state/tyres-wheels"

export function getSeasonFromTyreModel(tyreModel: string, tyreModelsWithSeason: TyreModelsWithSeason[]) {
    const findSeasonByModelName = tyreModelsWithSeason.find((model) => model.modelName === tyreModel)
    switch (findSeasonByModelName?.seasonPrefix) {
        case SeasonPrefixes.Summer:
            return ETyresSeason.summer
        case SeasonPrefixes.Winter:
            return ETyresSeason.winter
        case SeasonPrefixes.AllSeason:
            return ETyresSeason.allSeason
        default:
            break
    }
}
