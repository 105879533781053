import { Article } from "@tm/models"
import { TraderArticle } from "../business/helpers"
import { OfferItemCatalogPart } from "./OfferItemCatalogPart"
import { OfferItemTraderPart } from "./OfferItemTraderPart"

type Props = {
    workTaskId: string
    part: Article | TraderArticle
    advertisementCategoryId: string
    onRequestPartList(showBasket: boolean): void
}

export function OfferItem(props: Props) {
    const { part } = props

    if ("isTraderArticle" in part) {
        return <OfferItemTraderPart {...props} part={part} />
    }

    return <OfferItemCatalogPart {...props} part={part} />
}
