import { Customer, Vehicle } from "@tm/models"
import { BundleActions, BundleActionTypes } from "../../../business"
import { MainActionsType } from "../../main/business"

export type ComponentActionType = BundleActionTypes

export type ModuleManagerState = {
    vehicle?: Vehicle
    customer?: Customer
}
const DEFAULT_STATE: ModuleManagerState = {}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): ModuleManagerState => {
    switch (action.type) {
        case "VEHICLE_SET": {
            return { ...state, vehicle: action.payload }
        }
        case "CUSTOMER_SET": {
            return { ...state, customer: action.payload }
        }
        default: {
            return state
        }
    }
}

export const setVehicle = (veh: Vehicle): ComponentActionType => ({ type: "VEHICLE_SET", payload: veh })
export const setCustomer = (customer: Customer): ComponentActionType => ({ type: "CUSTOMER_SET", payload: customer })

export const Actions = { ...BundleActions, setVehicle, setCustomer }
