import { Stack, Typography } from "@tm/components"
import { VoucherWork } from "@tm/data/vouchers/costEstimations"
import { useLocalization } from "@tm/localization"

type Props = {
    voucherWork: VoucherWork
    currencySymbol?: string
    currencyCode?: string
}

export default function HourlyRate({ voucherWork, currencyCode, currencySymbol }: Props) {
    const { hourlyRateNet, fixedPriceNetValue } = voucherWork
    const { translate, currency } = useLocalization()

    return (
        <Stack justifyContent="flex-end">
            {fixedPriceNetValue ? (
                <>
                    <Typography variant="label">{translate(246)}</Typography>
                    {currency(fixedPriceNetValue, currencySymbol ?? currencyCode ?? "")}{" "}
                </>
            ) : (
                currency(hourlyRateNet || 0, currencySymbol ?? currencyCode ?? "")
            )}
        </Stack>
    )
}
