import { Box, Icon, LinkButton, Loader, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Widget } from "@tm/controls"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useParams } from "react-router"
import { getBundleParams } from "../../utils"

type Props = {
    hideLinks?: boolean
    iconName: string
    linkButtonIconName?: string
    linkIconName?: string
    linkTextId?: number | string
    titleTextId: number | string
    additionalInfoLoading?: boolean
    additionalInfo?: string
}

export default function WidgetHeaderTitleComponent({
    iconName,
    titleTextId,
    linkTextId,
    linkIconName,
    linkButtonIconName,
    hideLinks,
    additionalInfoLoading,
    additionalInfo,
}: Props) {
    const { translate, translateText } = useLocalization()
    const matchParams = useParams<{ workTaskId?: string }>()
    const detailsUrl = renderRoute(getBundleParams().summaryUrl, {
        ...matchParams,
        workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()),
    })

    return (
        <Widget.Header>
            <Widget.Header.Title>
                <Box display="flex" alignItems="center">
                    <Icon height="16px" width="16px" name={iconName} />
                    <Box ml={1} component="span">
                        <Widget.Header.Title.Text>
                            <Stack direction="row" spacing={1}>
                                {translate(titleTextId)}
                                {additionalInfoLoading ? <Loader size="extrasmall" /> : additionalInfo}
                            </Stack>
                        </Widget.Header.Title.Text>
                    </Box>
                </Box>
            </Widget.Header.Title>
            {(!!linkIconName || !!linkTextId || !!linkButtonIconName) && !hideLinks && (
                <Widget.Header.Title.Options>
                    <Box display="flex" alignItems="center">
                        {linkIconName && <Icon name={linkIconName} />}
                        {!!linkTextId && <LinkButton to={detailsUrl}>{translateText(linkTextId)}</LinkButton>}
                        {linkButtonIconName && (
                            <LinkButton to={detailsUrl} variant="text" sx={{ py: "0 !important" }}>
                                <Icon name={linkButtonIconName} height={15} width={15} />
                            </LinkButton>
                        )}
                    </Box>
                </Widget.Header.Title.Options>
            )}
        </Widget.Header>
    )
}
