import { useWorkTask, WorkTaskInfo } from "@tm/context-distribution"
import { Suspense } from "react"

import PartsList, { PartsListConfigProps } from "../_shared/parts-list/component"

type Props = PartsListConfigProps

function WorkTaskOrderComponent(props: Props & { workTask: WorkTaskInfo }) {
    return <PartsList {...props} workTask={props.workTask} hideAddToBasketButtonInArticleComparison />
}

export default function Wrapper(props: Props) {
    const { workTask } = useWorkTask() ?? {}

    return <Suspense fallback={null}>{workTask && <WorkTaskOrderComponent {...props} workTask={workTask} />}</Suspense>
}
