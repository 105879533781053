import { useLocalization } from "@tm/localization"
import { CisCustomerResponse } from "@tm/models"
import Field from "./Field"

type Props = {
    customer: CisCustomerResponse
}

export default function AccountDataComponent({ customer }: Props) {
    const { translateText } = useLocalization()
    const { account, nextTourInfo, customerNo, customerNumberToShow, customerGroup, cooperationGroup, information } = customer
    const { balanceDue, billingType } = account || {}

    return (
        <>
            {balanceDue && <Field title={translateText(3144)}>{balanceDue}</Field>}
            {billingType && <Field title={translateText(440)}>{billingType.description}</Field>}
            {nextTourInfo && <Field title={translateText(433)}>{nextTourInfo}</Field>}
            {(customerNumberToShow || customerNo) && <Field title={translateText(156)}>{customerNumberToShow || customerNo}</Field>}
            {customerGroup && <Field title={translateText(434)}>{customerGroup}</Field>}
            {cooperationGroup && <Field title={translateText(435)}>{cooperationGroup}</Field>}
            {information && <Field title={translateText(465)}>{information}</Field>}
            {account?.information && <Field title={translateText(465)}>{account.information}</Field>}
        </>
    )
}
