import { IBundle } from "@tm/morpheus"
import MainComponent from "./components/main"
import MemoInfoComponent from "./components/memoInfo"
import TechnicalNotesComponent from "./components/memoTechnicalNotes"
import VehicleMemoInfoDialog from "./components/vehicle-memo-info"
import MemoInfoMicro from "./micros/memo-info"
import MemoTechnicalInfoMicro from "./micros/memo-technical-info"
import { initMemoToolBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    components: [
        MainComponent,
        MemoInfoComponent,
        TechnicalNotesComponent,
        VehicleMemoInfoDialog
    ],
    micros: [
        MemoInfoMicro,
        MemoTechnicalInfoMicro
    ],
    init: initMemoToolBundle
}


export default bundle