import { Button, Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"

import { TelematicsDataResponse } from "../../../../data"

type Props = {
    data?: TelematicsDataResponse
    serviceCalculationUrl?: string
    canRequestCalculation?: boolean
}

export default function InspectionComponent(props: Props) {
    const { translate, date, language } = useLocalization()

    const { data, serviceCalculationUrl } = props
    if (!data) {
        return null
    }
    const { legalInspectionDate, nextServiceDate, nextServiceDistance } = data

    return (
        <div className="inspection">
            <div className="box">
                <div className="box__headline">{translate(574)}</div>
                <div className="box__content inspection">
                    <div className="list">
                        {nextServiceDate && (
                            <div className="list__cell">
                                <Icon className="list__cell__icon" name="inspection-date" size="xl" />
                                <Text modifiers="highlight">{date(nextServiceDate.value, "d")}</Text>
                                <Text size="s">{nextServiceDate.labelTextMap[language.toLowerCase()]}</Text>
                            </div>
                        )}
                        {nextServiceDistance && (
                            <div className="list__cell">
                                <Icon className="list__cell__icon" name="inspection-distance" size="xl" />
                                <Text modifiers="highlight">{nextServiceDistance.value.format(0)}</Text>
                                <Text size="s">{nextServiceDistance.labelTextMap[language.toLowerCase()]}</Text>
                            </div>
                        )}
                        {legalInspectionDate && (
                            <div className="list__cell">
                                <Icon className="list__cell__icon" name="service" size="xl" />
                                <Text modifiers="highlight">{date(legalInspectionDate.value, "d")}</Text>
                                <Text size="s">{legalInspectionDate.labelTextMap[language.toLowerCase()]}</Text>
                            </div>
                        )}
                    </div>
                    {!!serviceCalculationUrl && (
                        <Button icon="fastclick" layout={["holo"]} linkTo={serviceCalculationUrl} disabled={!props.canRequestCalculation}>
                            {translate(598)}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    )
}
