import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()

    const styles: NestedCSSProperties = {
        $nest: {
            "&__head": {
                marginBottom: margin.l,
                $nest: {
                    ">.subtitle": {
                        marginBottom: margin.m,
                    },
                    ">.no": {
                        marginRight: margin.m,
                    },
                    ">.addInfo": {
                        marginLeft: margin.m,
                    },
                },
            },
            "&__details": {
                display: "flex",
                margin: `${margin.m} 0`,
                $nest: {
                    ".toolbar": {
                        position: "relative",
                        padding: `${margin.m}`,
                        $nest: {
                            "&:before": {
                                margin: 0,
                                height: "100%",
                                content: '""',
                                borderLeft: "1px solid #c3c3c3",
                                top: 0,
                                position: "absolute",
                            },
                            "&__content": {
                                fontSize: "1rem",
                            },
                        },
                    },
                },
            },
            "&__small-Loader": {
                display: "inline-block",
                maxHeight: "15px",
                maxWidth: "15px",
            },
        },
    }

    return styles
}
