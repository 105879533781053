import { getStoredAuthorization, ajax, openBase64Pdf } from "@tm/utils"
import { PrintArticleDetailsRequest } from "../model"
import { getBundleParams } from "../../utils"

function getPrintArticleDetailsUrl(): string {
    return getBundleParams().printArticleDetailsServiceUrl
}

export function printArticleDetails(request: PrintArticleDetailsRequest, translateText: (id: number) => string) {
    const url = `${getPrintArticleDetailsUrl()}/PrintArticleDetails`
    const authorization = getStoredAuthorization()
    const body = { ...request }

    return new Promise<void>((resolve, reject) => {
        ajax({ url, body, authorization, method: "POST" }).then((data: string) => {
            openBase64Pdf(data, translateText)
            resolve()
        }, reject)
    })
}
