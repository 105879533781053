import { RequestArticleListPayload, FittingPosition, SearchFilters } from "@tm/models"
import * as Models from "../../../data/model"

export function mapShowPartsListByOeReferencesToRequestArticleList(payload: Models.SearchOeReferencesRequest): RequestArticleListPayload {
    const oeReferenceNos = new Set<string>()
    const productGroupIds = new Set<number>()
    let fittingPosition: FittingPosition | undefined

    payload.oeArticleLists.forEach((x) => {
        x.oeArticles.forEach((y) => {
            oeReferenceNos.add(y.oeNo)
            if (y.genericArticleIds) {
                y.genericArticleIds.forEach((z) => productGroupIds.add(z))
            }
            if (y.oeReplacements) {
                y.oeReplacements.sort((a, b) => (a.sortNo && b.sortNo ? a.sortNo - b.sortNo : 0)).forEach((z) => oeReferenceNos.add(z.oeNo))
            }
        })
    })

    if (payload.oeArticleLists.length === 1) {
        const { constructionSide, oeArticles } = payload.oeArticleLists[0]

        if (oeArticles.length === 1) {
            fittingPosition = oeArticles[0].fittingPosition
            if (fittingPosition) {
                switch (constructionSide) {
                    case "left":
                        fittingPosition |= FittingPosition.Left
                        break
                    case "right":
                        fittingPosition |= FittingPosition.Right
                        break
                    default:
                        break
                }
            }
        }
    }

    if (productGroupIds.size) {
        return {
            oeReferences: {
                nos: Array.from(oeReferenceNos),
                productGroupIds: Array.from(productGroupIds),
            },
            fittingPosition,
            inModal: true,
        }
    }

    // TODO: to change when the Shopping basket supports only Oenumbers
    return {
        direct: {
            query: Array.from(oeReferenceNos)[0],
        },
        fittingPosition,
        inModal: true,
    }
}

export function mapShowPartsListByTextToRequestArticleList(
    query: string,
    oeManufacturerIds?: Array<number>,
    searchFilter?: SearchFilters,
    productGroupIds?: number[]
): RequestArticleListPayload {
    // workaround to add both Renault = 93 and Renault Trucks = 694
    if (oeManufacturerIds?.includes(93)) {
        oeManufacturerIds.push(694)
    }

    return {
        direct: {
            query,
            oeManufacturerIds,
            oeInformationWithoutCar: !!oeManufacturerIds?.length,
            searchFilter,
            productGroupIds,
        },
        inModal: true,
    }
}
