import { RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { ImportVoucherRequest } from "../../../model"
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"
import { publishToChannel } from "../../../../helpers"

export function useImportVouchers(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()

    const { mutateAsync: importOrderVouchers } = useMutation(
        (importCostEstimationVoucherRequest: ImportVoucherRequest) => Data.importCostEstimationVoucher(importCostEstimationVoucherRequest),
        {
            onSuccess: async (response, request) => {
                if (response) {
                    await enableCostEstimation(request.workTaskId)
                    if (request.voucherParts?.length) {
                        publishToChannel(request.workTaskId)
                    }

                    handleBasketUpdateWorkflow(request.workTaskId, response)
                }
                // Save work task in the DB
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
            },
        }
    )

    const importVoucher = useCallback(
        (importOrderVoucherItemsRequest: ImportVoucherRequest) => {
            return importOrderVouchers(importOrderVoucherItemsRequest)
        },
        [importOrderVouchers]
    )

    return importVoucher
}
