import { useLocalization } from "@tm/localization"
import { Box, Grid, Stack } from "@tm/components"
import { VoucherDocument } from "../../../data/model"
import { InvoiceResponse } from "../../../data/model/invoice"
import DocumentButtons from "../../_shared/buttons/DocumentButtons"
import { OrderDetailsField } from "../../_shared/OrderDetailsField"

type Props = {
    invoice: InvoiceResponse
    voucherDocuments?: Array<VoucherDocument>
}

export default function InvoiceHeaderComponent({ invoice, voucherDocuments }: Props) {
    const { translateText, date, currency } = useLocalization()

    return (
        <Stack direction="column" alignItems="flex-end">
            {voucherDocuments?.length && (
                <Box>
                    <DocumentButtons voucherDocuments={voucherDocuments} />
                </Box>
            )}
            <Grid container rowSpacing={1} columnSpacing={2} my={1} columns={4}>
                <Grid item xs={1}>
                    <OrderDetailsField value={invoice.invoiceId} label={translateText(470)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={invoice.dateOfInvoice && date(invoice.dateOfInvoice, "d")} label={translateText(471)} />
                </Grid>

                <Grid item xs={1}>
                    <OrderDetailsField value={invoice.dueDate && date(invoice.dueDate, "d")} label={translateText(462)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={currency(invoice.amountInvoice, invoice.currencyCode)} label={translateText(62)} />
                </Grid>
                {/* Second Row */}
                <Grid item xs={1}>
                    <OrderDetailsField value={invoice.itemsCount} label={translateText(473)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={invoice.information} label={translateText(465)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={invoice.paymentTypeDescription} label={translateText(440)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={currency(invoice.saldo, invoice.currencyCode)} label={translateText(467)} />
                </Grid>
            </Grid>
        </Stack>
    )
}
