import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, RouteComponentProps } from "@tm/utils"
import { Button } from "@tm/controls"
import { MemoType } from "@tm/models"
import { getBundleParams } from "../../utils"
import { useArticleDetailsSharedErpInfos } from "../../data/hooks/useArticleDetailsSharedErpInfos"

type Props = RouteComponentProps & {
    stocksRoute: string
}

function NavigationComponent({ stocksRoute, match, location }: Props) {
    const { translateText } = useLocalization()
    const { erpInfo } = useArticleDetailsSharedErpInfos()

    if (!erpInfo) {
        return null
    }

    const ids: { [key: string]: string | undefined } = {}
    const { memoTooltipIcon } = getBundleParams()

    if (erpInfo.warehouses?.length) {
        ids.warehouses = translateText(412)
    }
    if (erpInfo.isTeccomRequestAvailable) {
        ids.teccom = translateText(1079)
    }
    if (erpInfo.prices?.length || erpInfo.graduatedPrices?.length) {
        ids.prices = translateText(140)
    }
    if (erpInfo.additionalInformation || erpInfo.additionalInformationExtended) {
        ids.infos = translateText(413)
    }
    if (erpInfo.hasObligatedArticles) {
        ids.obligated = translateText(873)
    }
    if (erpInfo.hasLinkedArticles) {
        ids.linked = translateText(874)
    }
    if (erpInfo.hasPawnArticles) {
        ids["pawn-articles"] = translateText(1995)
    }
    if (erpInfo.hasAccessoryArticles) {
        ids.accessory = translateText(349)
    }
    if (memoTooltipIcon && erpInfo.memos?.filter((m) => m.type === MemoType.Hyperlink || m.type === MemoType.AdditionalDescription).length) {
        ids["memo-tooltip-icon"] = translateText(180)
    }

    if (Object.keys(ids).length <= 1) {
        return null
    }

    return (
        <div className="tk-erp navigation">
            {Object.keys(ids).map((id) => {
                if (!ids[id]) {
                    return null
                }

                const url = renderRoute(match.path + stocksRoute, { ...match.params, scrollTo: id }) + (location.search || "")

                return (
                    <Button key={id} className="navigation__item" size="xl" layout={["ghost"]} linkTo={url}>
                        {ids[id]}
                    </Button>
                )
            })}
        </div>
    )
}

export default withRouter(NavigationComponent)
