import { TyresCarTypeAddon, ETyresCarType, ETyresSeason, EFilterNames, TyreFilter, FilterNames } from "@tm/models"
import { getBundleParams } from "../../utils"
import { CarTypeAddons } from "./addonMocks"

function getCars(): TyresCarTypeAddon[] {
    const { addOns } = getBundleParams()

    return Array.isArray(addOns)
        ? addOns.filter((x: any) => x.Id != ETyresCarType.SUV)
        : CarTypeAddons.filter(x => x.Default && x.Id != ETyresCarType.SUV)
}

function getCarTypes(): TyreFilter[] {
    return getCars().map((x, idx) => ({
        group: EFilterNames.carType,
        value: x.Description,
        query: x.Id.toString(),
        info: undefined,
        bitValue: 1 << idx,
    }))
}

const seasons: TyreFilter[] = Object.values(ETyresSeason)
    .filter(x => x != ETyresSeason.default)
    .map((season) => ({ query: season, value: season, group: EFilterNames.season }))

const multiSelectionFilters: Array<FilterNames> = [
    EFilterNames.manufacturer,
    EFilterNames.loadIndex,
    EFilterNames.speedIndex,
    EFilterNames.oeIdentifier,
    EFilterNames.extras,
    EFilterNames.season,
    EFilterNames.carType,
    // TODO check extra unused keys
    // "studed",
    // "studdable",
    // "silent",
]

function getDefaultCarType(): TyreFilter {
    return getCarTypes().find(x => x.query == ETyresCarType.PKW.toString())!
}

export const Statics = {
    getDefaultCarType,
    getCarTypes,
    seasons,
    multiSelectionFilters,
}
