import { Article } from "@tm/models"
import { useMemo } from "react"
import { usePartsModuleState } from "../../PartsModuleState"
import { TradeReferenceNumber } from "../useTradeReferences"

export function useArticleTradeReferences(article: Article) {
    const tradeReferences = usePartsModuleState((x) => x.tradeReferences)
    return useMemo(() => {
        const articleTradeReferences = tradeReferences.find(
            ([key]) => key.supplierId === article.supplier.id && key.supplierArticleNo === article.supplierArticleNo
        )?.[1]

        return (articleTradeReferences ?? []) as TradeReferenceNumber[]
    }, [article, tradeReferences])
}
