import { PanelSection } from "@tm/controls"
import { connectComponent } from "@tm/morpheus"
import { Actions, DocumentsState } from "./business"
import DocumentComponent from "./components/document"

type Props = {
    state: DocumentsState
    openDocumentsAsModal?: boolean
}

function DocumentsComponent({ state, openDocumentsAsModal }: Props) {
    return (
        <div className="article-details__overview">
            {!!state.detailsResponse?.documents?.length && (
                <PanelSection size="s">
                    <div className="article-details__downloads">
                        {state.detailsResponse.documents.map((document, idx) => (
                            <DocumentComponent key={idx} document={document} openDocumentsAsModal={openDocumentsAsModal} />
                        ))}
                    </div>
                </PanelSection>
            )}
        </div>
    )
}

export default connectComponent(Actions, DocumentsComponent)
