import { RepairTime } from "../../models"
import { FastServiceWorkTimes, WorkTypes } from "../../repositories/fastcalculator-getMaintenancePlan/model"
import { WorkTimes } from "./model"

export function mapWorkTimes(results: FastServiceWorkTimes[] | undefined): WorkTimes {
    const inspectionWorkTimes: RepairTime[] = []
    const additionalWorkTimes: RepairTime[] = []
    const followUpWorkTimes: RepairTime[] = []

    if (results) {
        // eslint-disable-next-line no-restricted-syntax
        for (const result of results) {
            const repairTime: RepairTime = {
                label: result.label,
                price: 1,
                quantity: result.value,
                repairTimeId: result.repairTimeId,
                provider: result.provider,
                included: result.included,
            }

            switch (result.workType) {
                case WorkTypes.additionalWork:
                    repairTime.iconType = "6"
                    additionalWorkTimes.push(repairTime)
                    break

                case WorkTypes.followUpWork:
                    repairTime.iconType = "5"
                    followUpWorkTimes.push(repairTime)
                    break

                case WorkTypes.service:
                default:
                    inspectionWorkTimes.push(repairTime)
                    break
            }
        }
    }

    return { inspectionWorkTimes, additionalWorkTimes, followUpWorkTimes }
}
