import { ETyresCarType, ETyresSeason, TyresSeason } from "@tm/models"

export const getTitleByGroup = (value: ETyresCarType | TyresSeason | string) => {

	switch (value) {
		case ETyresSeason.summer: return 800
		case ETyresSeason.allSeason: return 725
		case ETyresSeason.winter: return 801
	}

	switch (+value) {
		case ETyresCarType.Agrar: return 12451
		case ETyresCarType.Anhanger: return 12457
		case ETyresCarType.EM: return 12452
		case ETyresCarType.Industrie: return 12453
		case ETyresCarType.LKW: return 12458
		case ETyresCarType.LLKW: return 12459
		case ETyresCarType.Militar: return 12456
		case ETyresCarType.Motorrad: return 1447
		case ETyresCarType.PKW: return 12455
		case ETyresCarType.SUV: return 682
	}

	return ""
}
