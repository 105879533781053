import * as React from "react"
import { useLocalization } from "@tm/localization"
import { Tag, Icon, Text } from "@tm/controls"
import { useCisCustomer } from "@tm/context-distribution"
import { Checkbox, Dropzone, styled, Box, CardActionArea } from "@tm/components"
import { getFileContent } from "@tm/utils"
import ConceptLogo from "./ConceptLogo"
import { LogoCard, LogoImage } from "./components"

type Props = {
    logo?: string
    saveLogo(logo: string): void
    printLogo?: boolean
    handleChangePrintLogo(printLogo: boolean): void
}

const SelectedLogoCard = styled(LogoCard)(({ theme }) => ({
    border: `1px solid ${theme.colors?.primary}`,
}))

const StyledIcon = styled(Icon)({
    margin: "1rem",
    height: "2rem",
    width: "2rem",
})

const Logo = (props: Props) => {
    const [message, setMessage] = React.useState<string>()
    const { translateText } = useLocalization()
    const { cisCustomer } = useCisCustomer()

    const [printLogo, setPrintLogo] = React.useState<boolean>(props.printLogo ?? false)

    function handleTogglePrintLogo(e: React.ChangeEvent<HTMLInputElement>) {
        setPrintLogo(!printLogo)
        props.handleChangePrintLogo(!e?.target?.checked)
    }

    return (
        <div>
            <Box display="flex" flexWrap="wrap" gap={2}>
                {props.logo && (
                    <SelectedLogoCard>
                        <LogoImage url={props.logo.replace("image/*", "image/png")} /* IE11 & Edge Fix */ />
                    </SelectedLogoCard>
                )}
                {cisCustomer?.conceptCustomers?.map((concept) => (
                    <ConceptLogo key={concept.id} conceptCustomer={concept} onSelect={props.saveLogo} />
                ))}
                <Dropzone
                    allowedFileTypes={["gif", "jpg", "png"]}
                    maxSize={300000}
                    maxFiles={1}
                    multiple={false}
                    onDropAccepted={(acceptedFiles) => {
                        getFileContent(acceptedFiles[0]).then((fileContent) => {
                            props.saveLogo(fileContent)
                        })
                    }}
                    onDropRejected={(rejections) => {
                        if (rejections[0]?.errors[0]?.code === "file-invalid-type") {
                            setMessage(translateText(951))
                        } else if (rejections[0]?.errors[0]?.code === "file-too-large") {
                            setMessage(translateText(859))
                        }
                    }}
                    onFileDialogOpen={() => setMessage(undefined)}
                >
                    {({ isDragActive }) => (
                        <Box display="flex" alignItems="center" height="100%">
                            <LogoCard>
                                <CardActionArea>
                                    <StyledIcon name="plus" skin={isDragActive ? "primary" : undefined} />
                                </CardActionArea>
                            </LogoCard>
                        </Box>
                    )}
                </Dropzone>
            </Box>
            {!!message && <Tag value={message} skin="danger" icons={[{ name: "attention-light" }]} onCloseClick={() => setMessage(undefined)} />}
            <div>
                <Checkbox checked={!printLogo} onChange={handleTogglePrintLogo} />
                <Text>{translateText(13179)}</Text>
            </div>
            <Box mt={1}>
                <Text>{translateText(13134)}</Text>
            </Box>
        </div>
    )
}

export default Logo
