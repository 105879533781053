import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function autodataFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const autodataModule = getModuleFromUserContext(userContext, UserModuleType.Autodata)

    if (!autodataModule) {
        return undefined
    }

    return {
        repairtimes: {
            items: {
                autodata: {
                    sort: 1,
                    name: "Autodata",
                    active: true,
                    route: "/:workTaskId/repair-times/autodata/select",
                    disabledFunction: "REPAIRTIMES-AUTODATA-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        repairTimes: "autodata",
                    },
                },
            },
        },
        inspection: {
            items: {
                autodata: {
                    sort: 1,
                    name: "Autodata",
                    active: true,
                    route: "/:workTaskId/sd/autodata",
                    disabledFunction: "INSPECTIONDATA-AUTODATA-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        inspectionData: "autodata",
                    },
                },
            },
        },
        tech: {
            items: {
                autodata: {
                    sort: 1,
                    active: true,
                    name: "Autodata",
                    route: "/:workTaskId/td/autodata",
                    disabledFunction: "TECHDATA-AUTODATA-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        technicalData: "autodata",
                    },
                },
            },
        },
    }
}
