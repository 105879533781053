import { ajax, getStoredAuthorization, mapCustomer } from "@tm/utils"
import { Customer, FindCustomersRequest, FindCustomersResponse, RegisteredModels, Version } from "@tm/models"
import { Container } from "@tm/nexus"
import { getGsiServiceUrl } from ".."

function getServiceUrl() {
    return `${getGsiServiceUrl()}/Customers`
}

export function save(customer: Customer, overwriteRefCustomerNo?: boolean): Promise<Customer> {
    const url = `${getServiceUrl()}/SaveCustomer`
    const authorization = getStoredAuthorization()
    const body = { ...customer, overwriteRefCustomerNo }

    return new Promise<Customer>((resolve, reject) =>
        ajax({ url, authorization, body, method: "POST" }).then(
            // After the service returns the saved customer (with a new etag), this has to be changed to resolve the returned customer
            () => resolve(customer),
            reject
        )
    )
}

export function findCustomers(body: FindCustomersRequest) {
    const url = `${getServiceUrl()}/FindCustomers`
    const authorization = getStoredAuthorization()

    return ajax<FindCustomersResponse>({ url, authorization, body })
}

export function load(id: string): Promise<Customer> {
    const url = `${getServiceUrl()}/ShowCustomer`
    const authorization = getStoredAuthorization()
    const body = { id }

    return new Promise<Customer>((resolve, reject) => ajax({ url, authorization, body }).then((data) => resolve(mapCustomer(data)), reject))
}

export function findCustomer(request: { refId?: string; refCustomerNo?: string }): Promise<Customer> {
    const { refId, refCustomerNo } = request
    if (!refId && !refCustomerNo) {
        return Promise.reject(new Error("Missing parameter: [refId] or [refCustomerNo]"))
    }
    const url = `${getServiceUrl()}/ShowCustomer`
    const authorization = getStoredAuthorization()
    const body = { refId, refCustomerNo }

    return new Promise<Customer>((resolve, reject) => ajax({ url, authorization, body }).then((data) => resolve(mapCustomer(data)), reject))
}

export function detachVehicle(customerId: string, vehicleId: string, version: Version): Promise<void> {
    const url = `${getServiceUrl()}/DetachVehicle`
    const authorization = getStoredAuthorization()
    const body = { customerId, vehicleId, version }

    return new Promise<void>((resolve, reject) => ajax({ url, authorization, body, method: "POST" }).then(resolve, reject))
}

export function hideCustomer(id: string): Promise<void> {
    const url = `${getServiceUrl()}/HideCustomer`
    const authorization = getStoredAuthorization()
    const body = { id }

    return new Promise<void>((resolve, reject) => ajax({ url, authorization, body, method: "POST" }).then(resolve, reject))
}

export function registerActions() {
    Container.register({
        name: RegisteredModels.Customer,
        modelActions: {
            load,
            save,
        },
        containerActions: {
            loadCustomer: load,
            saveCustomer: save,
            findCustomer,
            findCustomers,
        },
    })
}
