import { useWorkTask } from "@tm/context-distribution"
import { SidebarWidget } from "@tm/controls"
import { LinkButton, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { BikeModel, CarManufacturer, CarModel, RegistrationNoType, TruckModel, TruckModuleType, VehicleShortInfo, VehicleType } from "@tm/models"
import { RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { useState } from "react"

import TopManufacturerCards from "../_shared/top-manufacturer-cards"
import { getBundleParams } from "../../utils"
import { useResetFilters } from "../../data/hooks"
import { isTruckModuleType } from "../../helpers/modules"
import { getTruckModuleUrl, getVehicleIdentificationUrl, getVehicleSearchUrl } from "../../helpers/routing"
import { handleUniqueVehicleFound } from "../../helpers/vrm"
import { TabNavigation, VehicleTabsDisplayStyle } from "../widget/components/tab-navigation"
import { VehicleSearchFieldSwitch } from "../_shared/VehicleSearchFieldSwitch"

type Props = RouteComponentProps<RouteParams> &
    ConfigurationProps & {
        className?: string
    }

type ConfigurationProps = {
    defaultVehicleType?: VehicleType
    vehicleTypeSelectionStyle?: VehicleTabsDisplayStyle
}

type RouteParams = {
    workTaskId?: string
    vehicleId?: string
    manufacturerId: any
    query?: string
}

function SidebarWidgetComponent(props: Props) {
    const { attachToWorkTask } = useWorkTask() ?? {}
    const localization = useLocalization()
    const [vehicleType, setVehicleType] = useState(props.defaultVehicleType ?? VehicleType.PassengerCar)
    const resetFilters = useResetFilters(vehicleType)

    const handleTopManufacturerSelect = (manufacturer: CarManufacturer) => {
        props.history.push(
            getVehicleIdentificationUrl(props.match.params, {
                vehicleType,
                manufacturerId: manufacturer.id,
                mainModelSeriesId: getBundleParams().hideMainModelSeries ? 0 : undefined,
            })
        )
    }

    const handleStartSearch = (query: string, registrationNoType?: RegistrationNoType) => {
        resetFilters() // Reset filters before opening search page
        props.history.push(getVehicleSearchUrl(props.match.params, { vehicleType, query, registrationNoType }))
    }

    const handleUniqueVehicle = (model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string) => {
        if (!attachToWorkTask) {
            return
        }
        handleUniqueVehicleFound(model, vehicleType, query, attachToWorkTask)
    }

    const handleAttachVehicleId = (vehicleId: string, query: string) => {
        if (!attachToWorkTask) {
            return
        }
        TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query })
        attachToWorkTask({ vehicle: vehicleId })
    }

    const handleTabSelect = (identifier: string) => {
        switch (identifier) {
            case VehicleType.PassengerCar.toString():
            case VehicleType.Motorcycle.toString():
            case VehicleType.CommercialVehicle.toString():
                const vehicleType = parseInt(identifier)
                if (!isNaN(vehicleType)) {
                    setVehicleType(vehicleType)
                }
                break
            default:
                if (isTruckModuleType(identifier)) {
                    props.history.push(getTruckModuleUrl(props.match.params, identifier))
                }
                break
        }
    }

    return (
        <SidebarWidget title={localization.translateText(99)} icon="vehiclesearch" className="sidebar-vehiclesearch">
            <TabNavigation
                currentVehicleType={vehicleType}
                moduleAvailabilities={getBundleParams().moduleAvailabilities ?? {}}
                displayStyle={props.vehicleTypeSelectionStyle}
                onTabSelect={handleTabSelect}
            />

            <VehicleSearchFieldSwitch
                {...props}
                vehicleType={vehicleType}
                onStartSearch={handleStartSearch}
                onUniqueVehicleFound={attachToWorkTask ? handleUniqueVehicle : undefined}
                onAttachVehicleId={handleAttachVehicleId}
            />

            <Typography variant="body3">{localization.translateText(1078)}</Typography>

            <div className="manufacturers">
                <TopManufacturerCards vehicleType={vehicleType} onSelect={handleTopManufacturerSelect} />
                <LinkButton className="manufacturers-more" to={getVehicleIdentificationUrl(props.match.params, { vehicleType })}>
                    ...
                </LinkButton>
            </div>
        </SidebarWidget>
    )
}

export default withRouter(SidebarWidgetComponent)
