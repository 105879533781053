import { getStoredAuthorization, ajax } from "@tm/utils"
import {
    getCompilationsServiceUrl,
    CompilationsFindResponseWM,
    CompilationShowAmountsResponse,
    ArticleUpdateRequest,
    CompilationUpdateRequest,
    CompilationCreateRequest,
    ArticlesDeleteRequest,
    CompilationsFindResponse,
    CompilationShowResponse,
    CompilationImportArticlesRequest,
} from ".."
import { mapArticleToAddRequest } from "../mapper/wm"
import { CompilationArticleInterface } from "../interfaces/compilation-article-interface"

export function addArticleWM(articles: CompilationArticleInterface): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/ArticlesAdd`
    const body = {
        compilationId: articles.compilationId,
        articles: mapArticleToAddRequest(articles.articles),
    }
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export function createCompilationWM(body: CompilationCreateRequest): Promise<string> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationCreate`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" }).then((response) => response.compilationId)
}

export function deleteArticlesWM(body: ArticlesDeleteRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/ArticlesDelete`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export function deleteCompilationsWM(compilationIds: Array<string>): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationsDelete`
    const authorization = getStoredAuthorization()
    const body = { compilationIds }

    return ajax({ url, body, authorization, method: "POST" })
}

export function importArticlesWM(body: CompilationImportArticlesRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/tbd`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export function findCompilationsWM(query?: string, pageIndex?: number, pageSize?: number): Promise<CompilationsFindResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationsFind`
    const authorization = getStoredAuthorization()
    const body = { query, pageIndex, pageSize }

    return ajax<CompilationsFindResponseWM>({ url, body, authorization })
}

export function showAmountsWM(compilationId?: string): Promise<CompilationShowAmountsResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationShowAmounts`
    const authorization = getStoredAuthorization()
    const body = { compilationId }

    return ajax<CompilationShowAmountsResponse>({ url, body, authorization })
}

export function showCompilationWM(compilationId: string) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationShow`
    const authorization = getStoredAuthorization()
    const body = { compilationId }

    return ajax<CompilationShowResponse>({ url, body, authorization })
}

export function updateArticleWM(body: ArticleUpdateRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/ArticleUpdate`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export function updateCompilationWM(body: CompilationUpdateRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/CompilationUpdate`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}
