import { Box, BoxProps, Button, Icon, TextField, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ButtonKeyDefinition } from "@tm/utils"
import { useState } from "react"

type Props = BoxProps & {
    handleSearch?: (query?: string) => void
}

export default function SearchInput(props: Props) {
    const { translateText } = useLocalization()
    const { handleSearch, ...rest } = props
    const [query, setQuery] = useState<string | undefined>()

    function onSearchClick() {
        handleSearch?.(query)
    }

    function handleSearchValueChanged(event: React.ChangeEvent<HTMLTextAreaElement>) {
        const {
            target: { value },
        } = event

        setQuery(value)
    }

    function handleSearchKeyDown(e: React.KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter && query) {
            onSearchClick()
        }
    }

    return (
        <Box display="flex" {...rest}>
            <StyledTextField
                placeholder={translateText(135)}
                variant="outlined"
                value={query}
                onChange={handleSearchValueChanged}
                onKeyDown={handleSearchKeyDown}
                sx={{ marginTop: "0px !important", height: "32px" }}
            />
            <Button sx={{ margin: "0", borderRadius: "0 3px 3px 0 !important", height: "32px" }} onClick={onSearchClick}>
                <Icon name="search" />
            </Button>
        </Box>
    )
}

const StyledTextField = styled(TextField)({
    "& .MuiInputBase-root": {
        borderRadius: "3px 0 0 3px !important",
        height: "100%",
    },
})
