import { getStyleTheme } from "@tm/context-distribution"
import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors } = getStyleTheme()
    const themeStyles: NestedCSSProperties = {
        backgroundColor: "transparent",
        color: colors.dark,
        borderLeftWidth: "1px",
        borderRightWidth: 0,
        borderRadius: 0,
        borderBottomWidth: "4px",
        height: "100%",
        margin: 0,
        transform: "translateZ(0) skewX(-15deg)",
        $nest: {
            "&:last-child": {
                borderRightWidth: "1px",
            },
            "&:hover:not(&--selected)": {
                backgroundColor: color("black").setAlpha(0.03).toRgbString(),
                color: colors.dark,
                $nest: {
                    ".icon": {
                        fill: colors.dark,
                    },
                },
            },
            "&__content": {
                transform: "translateZ(0) skewX(15deg)",
            },
            "&__info": {
                marginTop: "0.25rem",
            },
            "&__close": {
                transform: "translateZ(0) skewX(15deg)",
                $nest: {
                    ".icon": {
                        fill: colors.dark,
                    },
                    "&:hover .icon": {
                        fill: `${color(colors.dark).setAlpha(0.54).toRgbString()} !important`,
                    },
                },
            },
            "&--selected, &:active": {
                borderLeft: "1px solid #ddd",
                borderLeftColor: "#ddd",
            },
            "&--selected": {
                transform: "translateZ(0) skewX(-15deg)",
                backgroundColor: "transparent",
                borderBottomColor: colors.primary,
                color: colors.dark,
            },
            "&:active": {
                transform: "translateZ(0) skewX(-15deg)",
                backgroundColor: "transparent",
                borderBottomColor: colors.primary,
                color: colors.dark,
            },
            "&--selected .icon, &:active .icon": {
                fill: colors.dark,
            },
            "&__telesales__info": {
                fontSize: "1rem",
                position: "static",
            },
            "&__telesales__title": {
                fontSize: "0.6875rem",
                position: "static",
                order: 2,
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    mergedStyles.$nest && delete mergedStyles.$nest["&:first-of-type:not(&--selected)"]
    return mergedStyles
}

export function getOverflowMenuStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()
    const moreComponent = {
        back: "transparent",
        fore: color(colors.dark).setAlpha(opacity.secondary).toRgbString(),
        hover: {
            back: color(colors.dark).setAlpha(0.05).toRgbString(),
            fore: color(colors.dark).setAlpha(opacity.primary).toRgbString(),
        },
    }

    const themeStyles: NestedCSSProperties = {
        marginLeft: "-0.6em",
        $nest: {
            "&__main__content": {
                marginLeft: "0.6em",
            },
            "&__more": {
                marginLeft: "0.6em",
            },
            "&__btn": {
                transform: "skewX(-15deg)",
                height: "100%",
                background: "none",
                borderRadius: 0,
                $nest: {
                    ".icon": {
                        transform: "skewX(15deg)",
                        opacity: 1,
                        fill: `${moreComponent.fore} !important`,
                    },
                    "&:hover": {
                        background: moreComponent.hover.back,
                        $nest: {
                            ".icon": {
                                fill: `${moreComponent.hover.fore} !important`,
                            },
                        },
                    },
                    "&:focus": {
                        background: moreComponent.hover.back,
                        color: "inherit",
                    },
                },
            },
            "&__popover": {},
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
