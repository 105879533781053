import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Box, styled } from "@tm/components"
import { useEffect } from "react"
import { successfullStartSelector, documentSelector, MainActions } from "../main/business"
import { CustomIframe } from "../_shared"

export default function ExternComponent() {
    const document = useSelector(documentSelector)
    const successfullStart = useSelector(successfullStartSelector)
    const actions = useActions(MainActions, "getCalculationData", "handleCustomerBilling")
    let interval: NodeJS.Timeout | number

    useEffect(() => {
        actions.getCalculationData()
        interval = setInterval(actions.getCalculationData, 2000)
        return () => clearInterval(interval as number)
    })

    useEffect(() => {
        if (successfullStart) {
            actions.handleCustomerBilling()
        }
    }, [successfullStart])

    return <StyledWrapper>{document && <CustomIframe className="extern" id="dat-glass-external" srcDoc={document} />}</StyledWrapper>
}

const StyledWrapper = styled(Box)(() => ({
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    ".extern": {
        flex: 1,
    },
}))
