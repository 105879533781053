import { Memo } from "@tm/models"
import { PanelSection } from "@tm/controls"
import ServiceIconComponent from "./service-icon"
import { filterMemos } from "../../../business"
import { getBundleParams } from "../../../utils"
import { useLocalization } from "@tm/localization"

type Props = {
    memos?: Array<Memo>
}

export default function MemosComponent(props: Props) {
    // The memos will be displayed in a different component in this case
    if (getBundleParams().memoTooltipIcon) {
        return null
    }

    const { translateText } = useLocalization()
    const filteredMemos = filterMemos(props.memos)

    if (!filteredMemos || !filteredMemos.length) {
        return null
    }

    return (
        <PanelSection className="stock-info__memos" title={translateText(796)} size="s">
            {filteredMemos &&
                filteredMemos.map((memo, idx) => (
                    <div key={idx} title={memo.label} className="memoText">
                        <ServiceIconComponent icons={memo.icons} className="stock-info__memos__icon" />
                        {memo.text.trim()}
                    </div>
                ))}
        </PanelSection>
    )
}
