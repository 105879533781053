import { Stack, Switch } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useState, useEffect } from "react"

import UserSettingsArea from "../UserSettingsArea"
import { BasketMemoOptions } from "./BasketMemoOptions"
import { ItemSortingOptions } from "./ItemSortingOptions"
import { OrderConfirmationOptions } from "./OrderConfirmationOptions"
import { PaymentModeOptions, showPaymentModeOptions } from "./PaymentModeOptions"
import { PurchasePriceSummary } from "./PurchasePriceSummary"
import { ShipmentModeOptions, showShipmentModeOptions } from "./ShipmentModeOptions"

export interface ISaveableOption {
    isSaving: boolean
    onSave(isSaving: boolean): void
}

type Props = {
    externalSystemId?: number
}

export default function ShoppingBasketComponent({ externalSystemId }: Props) {
    const { translateText } = useLocalization()
    const { userSettings, changeOrderOptions } = useUser() ?? {}
    const { addWorkTaskInfoToOrderItemMemo, showResetBasketDialogAfterExport, wholesalerOrderConfirmation } =
        userSettings?.orderOptions.repairShopResponse ?? {}

    const [userSettingsSaving, setUserSettingsSaving] = useState<boolean>(false)

    useEffect(() => {
        setUserSettingsSaving(false)
    }, [showResetBasketDialogAfterExport])

    function handleShowDialogChange(state: boolean) {
        setUserSettingsSaving(true)
        changeOrderOptions?.({ showResetBasketDialogAfterExport: state })
    }

    return (
        <UserSettingsArea id="user-settings__area__basket" title={translateText(291)} showLoader={!userSettings || userSettingsSaving}>
            <Stack spacing={1.5}>
                <ItemSortingOptions isSaving={userSettingsSaving} onSave={setUserSettingsSaving} />
                <PurchasePriceSummary isSaving={userSettingsSaving} onSave={setUserSettingsSaving} />
                {addWorkTaskInfoToOrderItemMemo !== undefined && <BasketMemoOptions isSaving={userSettingsSaving} onSave={setUserSettingsSaving} />}
                {!!externalSystemId && (
                    <Switch
                        checked={showResetBasketDialogAfterExport}
                        onChange={(e) => handleShowDialogChange(e.target.checked)}
                        label={translateText(12561)}
                        disabled={userSettingsSaving}
                    />
                )}
                {wholesalerOrderConfirmation !== undefined && (
                    <OrderConfirmationOptions isSaving={userSettingsSaving} onSave={setUserSettingsSaving} />
                )}
                {showShipmentModeOptions(userSettings) && <ShipmentModeOptions isSaving={userSettingsSaving} onSave={setUserSettingsSaving} />}
                {showPaymentModeOptions(userSettings) && <PaymentModeOptions isSaving={userSettingsSaving} onSave={setUserSettingsSaving} />}
            </Stack>
        </UserSettingsArea>
    )
}
