import { ArticleIdentifier } from "@tm/models"
import { PartItem } from "../model"

function mapPartItemToArticleIdentifier(partItem: PartItem): ArticleIdentifier | undefined {
    const { supplierId, articleNumber, productGroupId } = partItem.articleInformation
    if (!!supplierId && !!productGroupId && articleNumber) {
        return {
            supplierId,
            supplierArticleNo: articleNumber,
            productGroupId,
        }
    }
}

export function mapPartItemsToArticleIdentifiers(partItems: PartItem[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    partItems.forEach((partItem) => {
        const part = mapPartItemToArticleIdentifier(partItem)
        if (part) {
            parts.push(part)
        }
    })
    return parts
}
