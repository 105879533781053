import * as React from "react"
import { useSelector } from "react-redux"
import { ApplyBtn } from "../../_shared/index"
import { MainState } from "../../main"

type Props = {
    onClick: () => void
    disableButton?: boolean
    withLoading?: boolean
}
const ApplyButtonComponent: React.FC<Props> = ({ onClick, disableButton, withLoading }) => {
    const { applyBtnLoading } = useSelector((s: MainState) => s.wheelsList.base)
    return (
        <ApplyBtn
            disabledApplyBtn={disableButton}
            showApplyBtn
            loadingApplyBtn={applyBtnLoading}
            onApply={onClick}
            showLoading={withLoading}
        />
    )
}

export default ApplyButtonComponent