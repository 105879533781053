import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { Card, Button, StatusSwitch, Text } from "@tm/controls"
import { ReturnItem } from "../../../data/model"
import RemoveItemsDialog from "../../_shared/RemoveItemsDialog"

type Props = {
    items: number
    articles: number
    allSelected: boolean
    equalReturnStatus: boolean
    selectedPartsIncludedInOrder: boolean
    selectedPartsBeingUpdated: boolean
    selectedParts: Array<ReturnItem>
    onSelectAll(): void
    onUnselectAll(): void
    onRemoveReturnItems(ids: Array<ReturnItem>): void
    onUpdateItemsReturnStatus(includeInReturn: boolean): void
}

export default function PartsSelectionComponent(props: Props) {
    const { translateText } = useLocalization()
    const { items, articles, allSelected, selectedParts, selectedPartsBeingUpdated, selectedPartsIncludedInOrder, equalReturnStatus } = props
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    function handlRemoveReturnItems() {
        props.onRemoveReturnItems(selectedParts)
    }

    function handleRemoveAll() {
        setShowDeleteDialog(true)
    }

    function handleOrderStatusSwitchChange() {
        props.onUpdateItemsReturnStatus(!selectedPartsIncludedInOrder)
    }

    function handleSelectButtonClick() {
        if (allSelected) {
            props.onUnselectAll()
        } else {
            props.onSelectAll()
        }
    }

    function handleCancelButtonClick() {
        props.onUnselectAll()
    }

    function closeDeleteDialog() {
        setShowDeleteDialog(false)
    }

    function renderSelectButton() {
        let buttonText = translateText(702)

        if (allSelected) {
            buttonText = translateText(703)
        }

        return (
            <Button onClick={handleSelectButtonClick} size="s">
                {buttonText}
            </Button>
        )
    }

    function renderUpdateReturnStatusSwitch() {
        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (selectedPartsIncludedInOrder && equalReturnStatus) {
            status = "checked"
        } else if (selectedPartsIncludedInOrder && !equalReturnStatus) {
            status = "checked-not-equal"
        } else if (!selectedPartsIncludedInOrder && equalReturnStatus) {
            status = "unchecked"
        } else if (!selectedPartsIncludedInOrder && !equalReturnStatus) {
            status = "unchecked-not-equal"
        }

        const notOrderableParts = selectedParts.some(
            (part) => !part.returnReasonId || (part.isReturnInfoRequired && !part.returnInfo) || !part.canReturn
        )

        return (
            <StatusSwitch
                label={translateText(1515)}
                alignLabel="left"
                status={status}
                disabled={selectedPartsBeingUpdated || notOrderableParts}
                onChange={handleOrderStatusSwitchChange}
            />
        )
    }

    return (
        <Card>
            <Text>
                <Text size="s" className="selection">
                    {translateText(701)}:
                </Text>
                <Text size="s" className="numbers">
                    {items} {translateText(479)},
                </Text>
                <Text size="s" className="numbers">
                    {articles} {translateText(90)}
                </Text>
            </Text>
            {renderSelectButton()}
            {renderUpdateReturnStatusSwitch()}
            <Button onClick={handleRemoveAll} size="s" icon="delete" layout={["ghost"]} />
            <RemoveItemsDialog
                dialogTextId={834}
                showDeleteDialog={showDeleteDialog}
                onCloseRemoveDialog={closeDeleteDialog}
                onRemoveReturnItems={handlRemoveReturnItems}
            />

            <Button skin="primary" className="cancel" icon="cancel-x" title={translateText(317)} onClick={handleCancelButtonClick} />
        </Card>
    )
}
