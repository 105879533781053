import { FC, createElement } from "react"
import { Provider } from "react-redux"
import { Store } from "redux"

type StoreModel = { store: Store }

export const withStoreProvider = <P extends StoreModel>(component: FC<Omit<P, keyof StoreModel>>): FC<P> => {
    const comp: FC<P> = (props) => {
        const { store } = props

        return createElement(Provider, { store }, createElement(component, props))
    }

    return comp
}
