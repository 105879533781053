import { EdsPart, OE } from "@tm/models"


export function getEdsRdArticles(selectedPart: EdsPart, parts: EdsPart[]): OE.OePartRD[] {
    const oeArticles: OE.OePartRD[] = []

    oeArticles.push(mapOePartRd(selectedPart, true))

    parts.forEach((part) => {
        if (part.areaCode === selectedPart.areaCode && part.partNumber !== selectedPart.partNumber) {
            oeArticles.push(mapOePartRd(part, false))
        }
    })

    return oeArticles
}

function mapOePartRd(item: EdsPart, isSelected: boolean): OE.OePartRD {
    return {
        oeNumber: item.partNumber,
        description: item.displayName,
        additionalDescription: "",
        oeReplacements: [],
        isSelected
    }
}
