import { FeedbackBundleParams } from "@tm/models"

let bundleParams: FeedbackBundleParams

export const version = {
    name: "feedback",
    version: "0.1.0",
}

export function getBundleParams(): FeedbackBundleParams {
    if (!bundleParams) {
        // eslint-disable-next-line no-throw-literal
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}

export function initFeedbackBundle(params: any) {
    bundleParams = params
    return bundleParams
}
