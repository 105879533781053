import { useMemo } from "react"
import { CisVoucherType } from "@tm/models"
import { Box, Loader } from "@tm/components"
import { FindInvoicesResponse } from "../../../data/model"
import InvoiceHeader from "./InvoiceDetailsHeader"
import VoucherItemsSummary from "../../_shared/VoucherItemsSummary"
import StandardVoucherItemsTable from "../../_shared/voucher-items-table/StandardVoucherItemsTable"
import TexkatVoucherItemsTable from "../../_shared/voucher-items-table/TexkatVoucherItemsTable"
import { CisDisplayMode } from "../../../business/model"
import { useOrderDetails } from "../../../data/hooks/useOrderDetails"
import { ColumnStack } from "../../_shared/StyledComponents"
import { NoResultHint } from "../../_shared/NoResultHint"
import { ErrorMessage } from "../../_shared/ErrorMessage"

type Props = {
    voucherTypeId: CisVoucherType
    cisDisplayMode?: CisDisplayMode
    endOfList: boolean
    invoices: FindInvoicesResponse
    selectedInvoiceId: string
}

export default function InvoiceDetailsComponent({ endOfList, selectedInvoiceId, invoices, cisDisplayMode, voucherTypeId }: Props) {
    const selectedVoucher = useMemo(() => {
        return invoices.invoices.find((voucher) => voucher.invoiceId === selectedInvoiceId)
    }, [invoices.invoices, selectedInvoiceId])
    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && { voucherId: selectedVoucher.invoiceId, voucherTypeId }
    )

    let content = <div />
    const selectedInvoce = invoices.invoices.find((invoice) => invoice.invoiceId === selectedInvoiceId)
    if (selectedInvoce) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (orderDetails?.voucherItems) {
            content = (
                <ColumnStack>
                    <InvoiceHeader key="InvoiceHead" invoice={selectedInvoce} voucherDocuments={orderDetails.voucherDocuments} />
                    {cisDisplayMode === "texkat" ? (
                        <TexkatVoucherItemsTable key="VoucherItemsTable" voucherItems={orderDetails.voucherItems} />
                    ) : (
                        <>
                            <StandardVoucherItemsTable key="VoucherItemsTable" voucherItems={orderDetails.voucherItems} />
                            <VoucherItemsSummary
                                key="VoucherItemsSummary"
                                voucherItems={orderDetails.voucherItems}
                                currencyCode={invoices.currencyCode}
                            />
                        </>
                    )}
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    } else if (endOfList) {
        content = <NoResultHint />
    }

    return <Box flex={1}>{content}</Box>
}
