import { ActionDispatch } from "@tm/morpheus"
import { ServiceDirection } from "@tm/models"
import { BundleActionType } from "../../../business"
import { AssociatedRepairTimesState } from "./model"
import { getInitialTab } from "./helper"

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: AssociatedRepairTimesState = {
    excludedRepairTimes: [],
    preRepairTimes: [],
    postRepairTimes: [],
    initialTab: "post",
    mainServiceSelected: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): AssociatedRepairTimesState {
    switch (action.type) {
        case "SELECT_MAIN_SERVICE": {
            const { preparationWorks } = action.payload.mainService

            return {
                ...state,
                excludedRepairTimes: preparationWorks
                    ? preparationWorks.filter((preparationWork) => preparationWork.direction === ServiceDirection.Exclude)
                    : [],
                preRepairTimes: preparationWorks
                    ? preparationWorks.filter((preparationWork) => preparationWork.direction === ServiceDirection.Pre)
                    : [],
                postRepairTimes: preparationWorks
                    ? preparationWorks.filter((preparationWork) => preparationWork.direction === ServiceDirection.Post)
                    : [],
                mainServiceSelected: true,
                initialTab: getInitialTab(state),
                repairTimeDivision: action.payload.repairTimeDivision,
            }
        }
        case "CLEAR_MAIN_SERVICE": {
            return {
                ...state,
                excludedRepairTimes: [],
                preRepairTimes: [],
                postRepairTimes: [],
                mainServiceSelected: false,
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "SELECT_MAIN_SERVICE":
        case "CLEAR_MAIN_SERVICE": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

export type IActions = typeof Actions

export const Actions = {}
