import { useEffect, FC, ReactElement } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { Button, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { BundleComponent } from "@tm/morpheus"

type Props = {
    text?: string
    title?: string
}

export const ShellExportButton: FC<Props> = (props) => {
    const { workTaskId } = useWorkTask() ?? {}
    const { translateText, isTranslationId } = useLocalization() ?? {}
    if (!workTaskId || typeof tmJSEvents === "undefined" || !tmJSEvents || !tmJSEvents.onExportShoppingBasket) {
        return null
    }

    useEffect(() => {
        return channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (rez.key === "dms-voucher") {
                tmJSEvents?.onExportShoppingBasket?.(workTaskId)
            }
        })
    })

    function getTranslatedText(text: string) {
        if (isTranslationId(text)) {
            return translateText(text)
        }

        return text
    }

    function renderWithTooltip(content: ReactElement) {
        if (!props.title) {
            return content
        }

        return <Tooltip title={getTranslatedText(props.title)}>{content}</Tooltip>
    }

    return renderWithTooltip(
        <Button onClick={() => tmJSEvents?.onExportShoppingBasket?.(workTaskId)}>
            {props.text ? getTranslatedText(props.text) : translateText(3008)}
        </Button>
    )
}

const shellExportButton: BundleComponent<void, typeof ShellExportButton> = {
    name: "shell-export-button",
    component: ShellExportButton,
}

export default shellExportButton
