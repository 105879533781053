import { useArticlesBySupplierArticleNumber, useArticlesByWholesalerArticleNumber } from "@tm/utils"
import { VoucherItem } from "../model"

export function useTecdocPartsInfoForVoucher(item: VoucherItem) {
    const { articles: articlesByWholesaler } = useArticlesByWholesalerArticleNumber(item.wholesalerArticleNumber)
    const { articles: articlesBySupplier } = useArticlesBySupplierArticleNumber(
        !item.wholesalerArticleNumber && !!item.supplierId && !!item.supplierArticleNumber
            ? { supplierId: +item.supplierId, supplierArticleNo: item.supplierArticleNumber }
            : undefined
    )

    return articlesByWholesaler || articlesBySupplier || []
}
