import { Box, Button, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { UseFormReturn } from "react-hook-form"
import { getUploadMethods } from "../../helpers/upload"
import { DragAndDrop } from "./DragAndDrop"
import { FastUpUpload } from "./FastUpUpload"
import { UploadSchemaType } from "../../formSchemas/uploadSchema"
import { ErrorBoxWrapper } from "../shared"
import { UploadPreview } from "./UploadPreview"

const StyledButtonsWrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: ".5em",
    margin: ".5em 0",
})

const StyledUploadWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    gap: "0.em",
    minHeight: "20em",
    marginLeft: "2px",
    marginBottom: "2px",
})

type Props = {
    formMethods: UseFormReturn<UploadSchemaType>
}

export function UploadArea({ formMethods }: Props) {
    const {
        clearErrors,
        setValue,
        setError,
        formState: { errors },
    } = formMethods
    const { translateText } = useLocalization()
    const uploadMethods = getUploadMethods(translateText)

    const [selectedUploadMethod, setUploadMethod] = useState<{ id: number; method: string }>(uploadMethods[0])
    const [selectedFile, setSelectedFile] = useState<File | string | undefined>(undefined)

    function onFileSelected(file: File | string) {
        clearErrors("file")
        setValue("file", file)
        setSelectedFile(file)
    }

    function onFileDelete() {
        setError("file", { message: "" })
        resetFileField()
    }

    function onSetUploadMethod(item: { id: number; method: string }) {
        setUploadMethod(item)
        resetFileField()
    }

    function resetFileField() {
        setValue("file", undefined)
        setSelectedFile(undefined)
    }

    const renderUploadMethod = () => {
        if (selectedFile) {
            return <UploadPreview file={selectedFile} onFileDelete={onFileDelete} />
        }

        switch (selectedUploadMethod.id) {
            case 1:
                return <DragAndDrop onFileUpload={onFileSelected} />
            case 2:
                return <FastUpUpload onFileUploaded={onFileSelected} />

            default:
                return <DragAndDrop onFileUpload={onFileSelected} />
        }
    }

    return (
        <StyledUploadWrapper>
            <StyledButtonsWrapper>
                {uploadMethods.map((item) => (
                    <Button
                        key={item.id}
                        onClick={() => onSetUploadMethod(item)}
                        color={item.method === selectedUploadMethod.method ? "primary" : "inherit"}
                    >
                        {item.method}
                    </Button>
                ))}
            </StyledButtonsWrapper>
            <ErrorBoxWrapper display="flex" flex="1" isError={!!errors?.file}>
                {renderUploadMethod()}
            </ErrorBoxWrapper>
        </StyledUploadWrapper>
    )
}
