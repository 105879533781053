// https://confluence.dvse.de/x/3KHVAg
export const documentTypeOfferTextId = 3019
export const documentTypes = [
    {
        name: 3060,
        categories: {
            Others: 3060,
        },
    },
    {
        name: 31,
        categories: {
            Offer: documentTypeOfferTextId,
            Order: 3026,
            Invoice: 656,
        },
    },
    {
        name: 1652,
        categories: {
            VehicleInspectionCertificate: 3010,
            EmissionsTestCertificate: 3011,
            GasSafetyInspection: 3035,
            AccidentPreventionRegulations: 3072,
        },
    },
    {
        name: 3077,
        categories: {
            MaintenanceProtocol: 3078,
            AcceptanceProtocol: 3023,
        },
    },
    {
        name: 3017,
        categories: {
            RegistrationOfVehicle: 3022,
            DeregistrationOfVehicle: 3014,
            CompletionCertificate: 3071,
            TakebackCertificate: 3056,
        },
    },
    {
        name: 3082,
        categories: {
            VehicleRegistration: 3087,
            VehicleRegistrationDocument: 3092,
            TaxAssessment: 3061,
        },
    },
    {
        name: 3074,
        categories: {
            Policies: 3052,
            InsuranceRepairAuthorisation: 3055,
            DamageReport: 3058,
            RecallReplacement: 3057,
        },
    },
    {
        name: 12842,
        categories: {
            AccidentalDamage: 12843,
            PaintDamage: 12844,
            Defects: 12845,
        },
    },
]
