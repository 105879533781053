import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { useIdStorageByWorkTaskLoadable, useSendChatMessageLoadable } from "../../../../notifications/src/data/hooks"

export function useCarteligenceShare(onShareCompleted?: (success: boolean) => void) {
    const { translateText } = useLocalization()
    const { chatId } = useIdStorageByWorkTaskLoadable() ?? {}
    const sendChatMessage = useSendChatMessageLoadable()

    const handleSendToCartelligence = (fileUrl: string) => {
        if (!chatId || !fileUrl) {
            return
        }

        sendChatMessage({
            chatId,
            message: translateText(13782),
            attachment: {
                metaData: {
                    type: "link",
                    url: fileUrl,
                },
            },
        })
            .then(() => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(13809),
                    icon: "check",
                    skin: "success",
                    closeDelay: 3000,
                })

                onShareCompleted?.(true)
            })
            .catch(() => {
                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: translateText(13810),
                    icon: "error",
                    skin: "warning",
                    closeDelay: 3000,
                })

                onShareCompleted?.(false)
            })
    }

    return {
        isLoading: false,
        sendToCarteligence: handleSendToCartelligence,
    }
}
