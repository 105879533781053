import { AsyncAction } from "@tm/morpheus"
import { getStoredAuthorization, ajax } from "@tm/utils"
import { State, AuthToken } from "./model"
import { getExternalToken } from "../../../data/repositories/portalAuthority"
import { BundleActions, BundleActionType } from "../../../business"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "TOKEN_LOADING" }
    | { type: "TOKEN_LOADED"; payload: AuthToken }
    | { type: "SET_URL"; payload: string }
    | { type: "SET_HIDE_CLOSE_BUTTON"; payload: boolean }

const DEFAULT_STATE: State = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): State {
    switch (action.type) {
        case "SET_HIDE_CLOSE_BUTTON": {
            return {
                ...state,
                hideCloseButton: action.payload,
            }
        }
        case "TOKEN_LOADING": {
            return {
                ...state,
                tokenLoading: true,
            }
        }
        case "TOKEN_LOADED": {
            return {
                ...state,
                tokenLoading: false,
                token: action.payload,
            }
        }
    }
    return state
}

function setHideCloseButton(hide: boolean): ComponentActionType {
    return { type: "SET_HIDE_CLOSE_BUTTON", payload: hide }
}

function getToken(type: string, key?: string): AsyncAction<ComponentActionType, State> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.tokenLoading) {
            return
        }

        dispatch({ type: "TOKEN_LOADING" })

        getExternalToken(type, { key }).then((token) => {
            dispatch({ type: "TOKEN_LOADED", payload: token })
        })
    }
}

function getTokenDeprecated(url: string, id: string): AsyncAction<ComponentActionType, State> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.tokenLoading || (state.token && state.token.expiration > new Date().getTime() / 1000)) {
            return
        }
        const itemKey = `IFRAME_WIDGET_TOKEN_${id}`
        const tokenItem = sessionStorage.getItem(itemKey)
        const token = tokenItem ? JSON.parse(tokenItem) : undefined
        if (token && token.expiration > new Date().getTime() / 1000) {
            dispatch({ type: "TOKEN_LOADED", payload: token })
            return
        }

        dispatch({ type: "TOKEN_LOADING" })
        const authorization = getStoredAuthorization()
        ajax({ url, authorization }).then((token) => {
            sessionStorage.setItem(itemKey, JSON.stringify(token))
            dispatch({ type: "TOKEN_LOADED", payload: token })
        })
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    setHideCloseButton,
    getToken,
    getTokenDeprecated,
}
