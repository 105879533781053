import { em } from "csx"
import { ReactNode } from "react"
import { useStyle } from "@tm/context-distribution"
import { Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { bem } from "../../data/helpers"

type Props = {
    title?: number | string
    description?: number | string
    icon?: string
    children?: ReactNode
}

export default function ErrorMessage({ icon, title, description, children }: Props) {
    const { translateText } = useLocalization()

    return (
        <div className={classes(style.error, "article-list__no-result")}>
            {icon && <Icon className="icon--no-result" name={icon} size="xl" />}

            {title && <div className="headline">{+title ? translateText(title) : title}</div>}
            {description && <div className="text text--s text--block">{+description ? translateText(description) : description}</div>}

            {children && <div className={bem(style.additionalContent)}>{children}</div>}
        </div>
    )
}

const style = useStyle({
    error: {
        display: "flex",
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
    },
    additionalContent: {
        margin: em(2),
    },
})(ErrorMessage)
