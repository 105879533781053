import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function damageCalculationGlassFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const damageCalculationGlassModule = getModuleFromUserContext(userContext, UserModuleType.TMDamageCalculationGlass)

    if (!damageCalculationGlassModule) {
        return undefined
    }

    return {
        "damage-calculation": {
            items: {
                "damage-calculation-glass": {
                    sort: 3,
                    name: "{{3164}}",
                    active: true,
                    route: "/:workTaskId/damage-calculation/glass",
                    disabledFunction: "HAS-NO-VEHICLE",
                    setAsActiveVehicleDataProviderOnSelect: {
                        damageCalculation: "datglass",
                    },
                },
            },
        },
    }
}
