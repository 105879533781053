import { useStyle } from "@tm/context-distribution"
import { Dialog } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { EventListenerManager } from "@tm/utils"
import { em, percent } from "csx"
import * as React from "react"
import { FilterType } from "../../data/enums"
import { generateDialogContent } from "../../data/helpers"

type Props = {
    filterId: FilterType | string
    onDialogCLose(): void
}

const InfoDialog: React.FC<Props> = ({ filterId, onDialogCLose }) => {

    const dialogRef = React.useRef<Dialog>(null)
    const { translateText } = useLocalization()
    let unregisterOutsideClick: () => void

    const [dialogOpen, setDialogOpen] = React.useState(false)

    React.useEffect(() => {
        openDialog()
        return () => {
            dialogRef.current?.hide()
        }
    }, [])

    React.useLayoutEffect(() => {
        if (dialogRef?.current?.innerRef.current) {
            unregisterOutsideClick = EventListenerManager.registerEventListener("outsideClick", dialogRef.current.innerRef.current, handleClose, true)
        }
        return () => {
            unregisterOutsideClick?.()
        }
    }, [dialogOpen])

    const openDialog = () => {
        dialogRef.current?.show()
        setDialogOpen(true)
    }

    const handleClose = () => {
        dialogRef.current?.hide()
        setDialogOpen(false)
        onDialogCLose()
    }

    const getDialogContent = () => {
        return generateDialogContent(filterId, translateText)
    }

    return (
        <Dialog
            className={style.dialog}
            ref={dialogRef}
            preText={getDialogContent()?.[1]}
            text={getDialogContent()?.[0]}
        />
    )
}

export default InfoDialog

const style = useStyle({
    dialog: {
        $nest: {
            ".dialog-prompt": {
                $nest: {
                    "&__text": {
                        whiteSpace: "pre-wrap"
                    },
                    "&__inner": {
                        width: percent(60)
                    },
                    "&__pre-text": {
                        fontSize: em(1.25),
                        fontWeight: "bold",
                        marginBottom: em(1)
                    },
                    "&__content": { alignItems: "start" },
                }
            }
        }
    }
})(InfoDialog)