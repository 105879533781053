import { Box, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Price } from "@tm/models"
import { BasePricesProps } from "."

type Props = BasePricesProps & {
    price: Price
    priceLabel: string
    primaryColor?: boolean
}

export default function BigScreenPrice(props: Props) {
    const { price, priceLabel, primaryColor, theme } = props
    const fontFamilyOverwrite = theme?.overwrites?.components?.basketPrices?.fontFamily || theme.typography.fontFamily

    const { currency } = useLocalization()

    return (
        <Stack minWidth="6.5em" spacing={0}>
            <Typography variant="label" alignSelf="flex-start" noWrap>
                {priceLabel}
            </Typography>
            <Box display="flex" justifyContent="flex-end">
                <Typography
                    color={primaryColor ? "primary" : undefined}
                    fontFamily={fontFamilyOverwrite || theme.typography.fontFamily}
                    fontWeight="bold"
                    noWrap
                >
                    {currency(price.value, price.currencySymbol || price.currencyCode)}
                </Typography>
            </Box>
        </Stack>
    )
}
