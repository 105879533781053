import * as React from "react"
import { Loader, Checkbox, Text } from "@tm/controls"
import { List, ListItem, ListItemButton, ListItemIcon, styled, TextField } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CollapsibleWrapper } from "."
import { RepairTime } from "../../data/models"
import { useFastServiceStore } from "../../data"

type Props = {
    items: RepairTime[]
    onSelect: (item: RepairTime) => void
    selectedRepairTimes?: RepairTime[]
}

const RepairTimes: React.FC<Props> = ({ items, onSelect, selectedRepairTimes }) => {
    const { translateText } = useLocalization()
    const loading = useFastServiceStore((state) => state.articlesLoading)
    const repairTimes = useFastServiceStore((state) => state.maintenancePlan.workTimes)

    const handleSelection = (item: RepairTime, preventSelect: boolean) => () => {
        if (preventSelect) {
            return
        }

        onSelect(item)
    }

    const renderItems = (item: RepairTime, idx: number) => {
        const includedInMaintenance = Object.values(repairTimes)
            .flat()
            .some((x) => x.label === item.label && x.repairTimeId === item.repairTimeId)

        const isSelected =
            (item.isMandatory && item.isSelected) ||
            item.isSelected ||
            selectedRepairTimes?.some((x) => x.label === item.label && x.repairTimeId === item.repairTimeId) ||
            false

        const isDisabled = (item.isMandatory && item.isSelected) || includedInMaintenance

        return (
            <ListItemComponent key={idx} disablePadding>
                <ListItemButton role={undefined} onClick={handleSelection(item, isDisabled)} dense>
                    <ListItemIcon>
                        <Checkbox disabled={isDisabled} size="l" checked={isSelected} />
                    </ListItemIcon>
                    <Text size="l" id={item.label}>
                        {loading ? <Loader /> : item.label}
                    </Text>
                    <TextField
                        style={{ marginLeft: "auto" }}
                        label={translateText(89)}
                        onChange={() => {}}
                        value={item.quantity?.toFixed(2) || 0}
                        size="medium"
                    />
                </ListItemButton>
            </ListItemComponent>
        )
    }

    return (
        <CollapsibleWrapper textId={83}>
            <List>{items.map(renderItems)}</List>
        </CollapsibleWrapper>
    )
}

export default RepairTimes

const ListItemComponent = styled(ListItem)(({ theme }) => ({
    border: "1px solid transparent",
    borderLeftWidth: "2px",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
    borderRadius: "3px",
    "&:hover": {
        borderColor: theme.palette.primary.main,
    },
}))
