import { getModuleFromUserContext, useUser, useWorkTask } from "@tm/context-distribution"
import { merge } from "lodash"
import { UserModuleType } from "@tm/models"
import { useWidgetModules } from "../../../hooks/useWidgetModules"
import { AllWidgetKeys, Overrides, WidgetProp } from "../models"
import { useMultiWidgetTabsModules } from "../../../hooks/useMultiWidgetTabsModules"
import { useOtherModules } from "../../../hooks/useOtherModules"
import { createCatalogWidgetTree } from "../../../helpers/createCatalogWidgetTree"

const defaultOverrides: Partial<Record<AllWidgetKeys, WidgetProp>> = {
    tools: { hidden: true },
}

export function useDashboardIndustry(overrides?: Overrides) {
    const workTask = useWorkTask()
    const user = useUser()
    const userContext = user?.userContext

    const finalOverrides = merge(defaultOverrides, overrides)

    const otherModules = useOtherModules("DASHBOARD", !!userContext, () => {
        const fastDtcModule = getModuleFromUserContext(userContext, UserModuleType.FastDTC)
        const gpiEurotaxModule = getModuleFromUserContext(userContext, UserModuleType.TMEurotax)
        const tyresModule = getModuleFromUserContext(userContext, UserModuleType.TMTires)
        const uniPartsPremiumModule = getModuleFromUserContext(userContext, UserModuleType.UnipartsPremium)
        const partsIndicatorModule = getModuleFromUserContext(userContext, UserModuleType.PartsIndicator)

        const catalogWidgetTree = createCatalogWidgetTree(
            {
                eurotaxSearch: !!gpiEurotaxModule,
                universalParts: !!uniPartsPremiumModule,
                fastDTC: !!fastDtcModule,
                tiresSearch: !!tyresModule,
                predictive: !!partsIndicatorModule,
                vehicleParts: true,
                directSearch: true,
            },
            finalOverrides
        )

        return {
            catalogWidgetTree,
        }
    })

    function getShown(widgetKey: AllWidgetKeys): boolean {
        return !finalOverrides?.[widgetKey]?.hidden
    }

    const checkOrder = (key: AllWidgetKeys): number | undefined => {
        return finalOverrides?.[key]?.sort
    }

    function getVal<T>(widgetKey: AllWidgetKeys, configKey: string, defaultVal?: T | undefined): T | undefined {
        return finalOverrides?.[widgetKey]?.custom?.[configKey] ?? defaultVal
    }

    return {
        workTaskId: workTask?.workTaskId,
        otherModules,
        finalOverrides,
        getVal,
        getShown,
        checkOrder,
    }
}
