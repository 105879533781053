import { useWorkTask } from "@tm/context-distribution"
import * as React from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { WheelSelectionSteps } from "../../data/enums"
import { RimDetailsArticle, TireSize } from "../../data/model"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { ApplyBtn } from "../_shared"
import { Actions } from "./business"
import { RimInformation, RimDetails } from "./components"
import { styled } from "@tm/components"

type WheelsDetailsComponentProps = {
    repairTimesRoute: string
}

const wheelsDetailsSelector = (s: MainState) => s.wheelsDetails

const WheelsDetails: React.FC<WheelsDetailsComponentProps> = ({ repairTimesRoute }) => {
    const dispatch = useDispatch()

    const { selectedSize, initialized } = useSelector(wheelsDetailsSelector)

    React.useEffect(() => {
        selectedSize && !initialized && dispatch(Actions.loadRimDetailsArticles())
    }, [])

    return (
        <StyledWrapper className="wheels-details">
            <ContinueButton />
            <RimDetails repairTimesRoute={repairTimesRoute} />
            <RimInformation />
        </StyledWrapper>
    )
}

const StyledWrapper = styled("div")(() => ({
    display: "flex",
    flex: 1
})) 

export default WheelsDetails

const ContinueButton: React.FC<{}> = ({ }) => {

    const dispatch = useDispatch()
    const selectedTireSize = useSelector<MainState, TireSize | undefined>(s => s.wheelsDetails.selectedTireSize)
    const selectedRimDetailsArticle = useSelector<MainState, RimDetailsArticle | undefined>(s => s.wheelsDetails.selectedRimDetailsArticle)
    const workTaskId = useWorkTask()?.workTaskId 

    const handleApplyBtnClick = () => {
        batch(() => {
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.RDKSLIST }, true))
            dispatch(Actions.sendRimArticlesToOverview(WheelSelectionSteps.RDKSLIST, workTaskId))
        })
    }
    return (
        <ApplyBtn
            disabledApplyBtn={!selectedRimDetailsArticle || !selectedTireSize}
            showApplyBtn
            onApply={handleApplyBtnClick} />
    )
}