import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Icon, Widget } from "@tm/controls"
import { carMunValidate } from "@tm/utils"
import Content from "./components/content"
import { Box, ClickAwayListener, Tooltip } from "@tm/components"
import { FC, useCallback, useState } from "react"

type Props = {
    detailsRoute: string
    hideDrivingStyleAnalysis?: boolean
}

const WidgetComponent: FC<Props> = () => {
    const worktaskVehicle = useWorkTask()?.workTask?.vehicle
    const isCarMunValidate = carMunValidate(worktaskVehicle?.telematicsRefId, worktaskVehicle?.vin)
    const [showTooltip, setShowTooltip] = useState(false)

    const handleCloseTooltip = useCallback(() => {
        setShowTooltip(false)
    }, [])

    const handleOpenTooltip = useCallback(() => {
        setShowTooltip(true)
    }, [])

    const renderWidgetCover = () => (
        <div className="widget__cover" onClick={handleOpenTooltip}>
            <Icon className="widget__cover-icon" name="telematics" />
            <div className="widget__cover-text">
                Carmunication
            </div>
        </div>
    )

    return (
        <>
            {isCarMunValidate &&
                <Widget
                    size="1x1"
                    title="Carmunication"
                    highlight cover={
                        <ClickAwayListener onClickAway={handleCloseTooltip}>
                            <Box flex={1}>
                                <Tooltip
                                    onClose={handleCloseTooltip}
                                    open={showTooltip}
                                    title={<Content />}
                                    placement="top"
                                    variant="light"
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    componentsProps={{
                                        tooltip: { sx: { maxWidth: "none" } }
                                    }}
                                >
                                    {renderWidgetCover()}
                                </Tooltip>

                            </Box>
                        </ClickAwayListener>
                    } />
            }
        </>
    )
}
export default WidgetComponent
