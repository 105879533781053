import { useLocalization } from "@tm/localization"
import { Article, ErpSystemConfigMode } from "@tm/models"
import { TableColumnData, Loader, Table, CellContentPosition } from "@tm/components"
import { useErpConfig } from "@tm/utils"
import Row from "./PartnerSystemRow"

type Props = {
    article: Article | undefined
    basketItemId?: string
}

export default function PartnerSystemsComponent({ article, basketItemId }: Props) {
    const { translateText } = useLocalization()
    const { erpSystemConfigs, mode } = useErpConfig()

    const columns: TableColumnData[] = [
        { header: translateText(839).toUpperCase() } /* Partner */,
        { header: translateText(232).toLocaleUpperCase() } /* Benutzer */,
        { header: translateText(376).toLocaleUpperCase() } /* Händlerartiklenummer */,
        { header: translateText(12739).toLocaleUpperCase() } /* Nächster Wareneingang */,
        { header: translateText(53).toLocaleUpperCase(), alignContent: CellContentPosition.right } /* Preis */,
        { header: "%", alignContent: CellContentPosition.right },
        { header: translateText(13387).toLocaleUpperCase(), alignContent: CellContentPosition.right } /* Nettopreis  */,
        {} /* Staffelpreis */,
        { header: translateText(13389).toLocaleUpperCase(), alignContent: CellContentPosition.right } /* Preis per */,
        { header: translateText(13388).toLocaleUpperCase(), alignContent: CellContentPosition.right } /* VPE */,
        {} /* Statusicons */,
        {} /* Verfügbarkeitsicon */,
        {} /* AddToBasket */,
    ]

    if (mode !== ErpSystemConfigMode.Partnersystems || !erpSystemConfigs?.length) {
        return null
    }
    if (!article) {
        return <Loader />
    }

    return (
        <Table columns={columns} overflowY="auto">
            {erpSystemConfigs?.map((erpSystemConfig, index) => (
                <Row key={index} columnCount={columns.length} erpSystemConfig={erpSystemConfig} article={article} basketItemId={basketItemId} />
            ))}
        </Table>
    )
}
