import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState, MouseEvent, useCallback } from "react"
import { Collapse, List, Stack, TextField, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useRecoilState } from "recoil"
import { ECounterType, ListFilter } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { AccordionDetailsSmall, AccordionHeadline, AccordionSimple, AccordionSummaryMore } from "./FiltersStyledComponents"
import { FilterItem } from "./FilterItem"
import { FilterTypeVisibilitiesState } from "../../states"
import { useListType } from "./useListType"
import IconWithTooltip from "../../../_shared/icon-with-tooltip/IconWithTooltip"

type FilterGroups = {
    display: [ListFilter, boolean][]
    more: [ListFilter, boolean][]
}

type FiltersAreaProps = {
    filtersType?: "productGroups" | "suppliers" | "availability" | "attributes"
    headline: string
    data: FilterGroups
    onChange: (filter: ListFilter, exclusive?: boolean) => void
    onResetFilters?: () => void
    hideFilterSearch?: boolean
    hideActions?: boolean
    hideAbbreviation?: boolean
    startCollapsed?: boolean
    className?: string
    expandWhenChildIsSelected?: boolean
}

export type FiltersAreaActions = {
    expandMore(): void
    collapseMore(): void
}

export const FiltersArea = memo(
    forwardRef<FiltersAreaActions, FiltersAreaProps>(function FiltersAreaComponent(props, ref) {
        const { translateText } = useLocalization()
        const {
            data,
            headline,
            onResetFilters,
            hideFilterSearch,
            hideActions,
            className,
            hideAbbreviation,
            startCollapsed,
            filtersType,
            expandWhenChildIsSelected,
        } = props

        const listType = useListType()

        const [showMore, setShowMore] = useState(false)
        const [showInput, setShowInput] = useState(false)
        const [groupFilter, setGroupFilter] = useState("")
        const textFieldRef = useRef<HTMLInputElement>()

        const [visibilities, setVisibilities] = useRecoilState(FilterTypeVisibilitiesState(listType))

        useImperativeHandle(
            ref,
            () => ({
                expandMore() {
                    setShowMore(true)
                },
                collapseMore() {
                    setShowMore(false)
                },
            }),
            []
        )

        useEffect(() => {
            if (textFieldRef.current && showInput) {
                textFieldRef.current.focus()
            }
        }, [showInput, textFieldRef])

        const handleResetFilters = (event: MouseEvent) => {
            event.stopPropagation()
            onResetFilters?.()
        }

        const handleOpenMoreWithTmaEvent = (state: boolean) => {
            switch (filtersType) {
                case "productGroups":
                    TmaHelper.GeneralCountEvent.Call(state ? ECounterType.ArticleListShowMoreGenArt : ECounterType.ArticleListShowLessGenArt)
                    break
                case "suppliers":
                    TmaHelper.GeneralCountEvent.Call(state ? ECounterType.ArticleListShowMoreSuppliers : ECounterType.ArticleListShowLessSuppliers)
                    break
                default:
                    break
            }
        }

        const handleFilterCategory = (event: MouseEvent) => {
            event.stopPropagation()
            setShowInput(!showInput)
        }

        const includesFilter = (filter: ListFilter, needle: string) => {
            return (
                filter.name?.toUpperCase().includes(needle.toLocaleUpperCase()) || filter.abbreviation?.toUpperCase().includes(needle.toUpperCase())
            )
        }

        const defaultExpanded = useMemo(() => {
            if (startCollapsed || !filtersType) {
                return false
            }
            return visibilities[filtersType] ?? true
        }, [filtersType, startCollapsed, visibilities])

        const [accordionExpanded, setAccordionExpanded] = useState(defaultExpanded)

        useEffect(() => {
            let expectedExpandedValue = defaultExpanded
            if (expandWhenChildIsSelected) {
                const numberOfExpandedChildren = [...data.display, data.more].filter(([, selected]) => selected).length
                if (numberOfExpandedChildren > 0) {
                    expectedExpandedValue = true
                }
            }
            if (expectedExpandedValue !== accordionExpanded) {
                setAccordionExpanded(expectedExpandedValue)
            }
        }, [data, expandWhenChildIsSelected])

        const handleChangeExpansion = useCallback(
            (_: unknown, expand: boolean) => {
                if (!filtersType) {
                    return
                }
                setVisibilities((prev) => {
                    const currentState = prev[filtersType] ?? true
                    if (expand !== currentState) {
                        return { ...prev, [filtersType]: expand }
                    }
                    return prev
                })
            },
            [setVisibilities, filtersType]
        )

        const handleSetShowMore = (state: boolean) => {
            handleOpenMoreWithTmaEvent(state)
            setShowMore(state)
        }

        const filteredData = useMemo(() => {
            if (groupFilter.length > 1) {
                return {
                    display: data.display.filter(([filter]) => {
                        return includesFilter(filter, groupFilter)
                    }),
                    more: data.more.filter(([filter]) => {
                        return includesFilter(filter, groupFilter)
                    }),
                }
            }
            return data
        }, [groupFilter, data])

        return (
            <AccordionSimple expanded={accordionExpanded} className={className} onChange={handleChangeExpansion}>
                <AccordionHeadline sx={{ display: "flex" }} onClick={() => setAccordionExpanded(!accordionExpanded)}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                        <Typography ml={1}>{headline}</Typography>
                        <Stack direction="row" spacing={1}>
                            {!(hideFilterSearch || hideActions) && <IconWithTooltip variant="search" onClick={handleFilterCategory} />}
                            {!hideActions && !!onResetFilters && <IconWithTooltip variant="remove-filter" onClick={handleResetFilters} />}
                        </Stack>
                    </Stack>
                </AccordionHeadline>
                <AccordionDetailsSmall>
                    <Collapse in={showInput} unmountOnExit>
                        <TextField
                            inputRef={textFieldRef}
                            size="small"
                            onChange={(e) => setGroupFilter(e.target.value)}
                            sx={{ margin: "4px 8px" }}
                            clearButton
                            variant="filled"
                            value={groupFilter}
                        />
                    </Collapse>
                    <List sx={{ p: 0 }}>
                        {filteredData.display.map(([filter, checked]) => (
                            <FilterItem
                                key={filter.id}
                                filter={filter}
                                checked={checked}
                                onChange={props.onChange}
                                hideAbbreviation={hideAbbreviation}
                            />
                        ))}
                    </List>
                    {filteredData.more.length > 0 && (
                        <AccordionSimple expanded={showMore} onChange={(_, state: boolean) => handleSetShowMore(state)}>
                            <AccordionSummaryMore expandIcon={() => undefined} id={`${headline}_more`}>
                                <Typography variant="label">{translateText(869)}</Typography>
                            </AccordionSummaryMore>
                            <AccordionDetailsSmall>
                                <List sx={{ p: 0 }}>
                                    {filteredData.more.map(([filter, checked]) => (
                                        <FilterItem
                                            key={filter.id}
                                            filter={filter}
                                            checked={checked}
                                            onChange={props.onChange}
                                            hideAbbreviation={hideAbbreviation}
                                        />
                                    ))}
                                </List>
                            </AccordionDetailsSmall>
                        </AccordionSimple>
                    )}
                </AccordionDetailsSmall>
            </AccordionSimple>
        )
    })
)
