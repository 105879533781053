import { RealTable, RealTableProps } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { MemoType, Warehouse } from "@tm/models"
import { useCallback, useMemo } from "react"
import { StockTuple, useStockTuple } from "../data/hooks"
import { getBundleParams } from "../utils"
import AvailabilityWrapperComponent from "./availability-wrapper"

const { Column, Cell } = RealTable

type Props = {
    warehouses?: Array<Warehouse>
    requestedQuantity: number | undefined
    isTooltip: boolean
    hasReplacedArticles?: boolean
}

const renderWarehouseCell = (item: StockTuple) => <Cell>{item.warehouse.name || item.warehouse.id}</Cell>

const renderQuantityDescriptionCell = (item: StockTuple) => <Cell>{item.quantity.description || ""}</Cell>

const renderQuantityValueCell = (item: StockTuple) => <Cell>{item.quantity.value}</Cell>

const renderDescriptionCell = (item: StockTuple) => <Cell>{item.warehouse.description || item.warehouse.shortDescription}</Cell>

const renderMemoCell = (item: StockTuple) => {
    return (
        <Cell>
            {item.warehouse.memos?.map((memo) => {
                if (memo.type === MemoType.Note) {
                    return memo.label || memo.text
                }
                return null
            })}
        </Cell>
    )
}

const renderTourNameCell = (item: StockTuple) => {
    const name = item.warehouse.tour?.id || item.warehouse.tour?.description
    if (!name) {
        return <Cell />
    }

    return <Cell>{name}</Cell>
}

const renderDeliveryTourNameCell = (item: StockTuple) => {
    const deliveries =
        item.warehouse.standardDelivery || item.warehouse.dayExpressDelivery || item.warehouse.nightExpressDelivery || item.warehouse.collection
    if (!deliveries?.length) {
        return <RealTable.Cell />
    }
    return (
        <Cell>
            {deliveries
                .find((delivery) => delivery.tours?.some((tour) => tour.id || tour.description))
                ?.tours?.map((delivery) => {
                    return delivery.id || delivery.description
                })}
        </Cell>
    )
}

export default function WarehousesTable(props: Props) {
    const { translate, date } = useLocalization()
    const list = useStockTuple(props.warehouses)

    const renderAvailabilityCell = useCallback(
        (item: StockTuple) => {
            return (
                <Cell>
                    <AvailabilityWrapperComponent
                        hasReplacedArticles={props.hasReplacedArticles}
                        availability={item.quantity.availability}
                        tour={item.quantity.tour}
                        requestedQuantity={props.requestedQuantity}
                        warehouseTableCurrentItem={item.warehouse}
                    />
                </Cell>
            )
        },
        [props.hasReplacedArticles, props.requestedQuantity]
    )

    const renderTourExpectedDeliveryCell = useCallback(
        (item: StockTuple) => {
            const expectedDelivery = item.warehouse.tour?.expectedDelivery

            if (!expectedDelivery) {
                return <Cell />
            }

            return <Cell>{date(expectedDelivery, getBundleParams().expectedDeliveryDateFormat)}</Cell>
        },
        [date]
    )

    const renderTourOrderAcceptanceCell = useCallback(
        (item: StockTuple) => {
            const orderAcceptance = item.warehouse.tour?.tourOrderAcceptanceTimeLimit
            return <Cell>{orderAcceptance && date(orderAcceptance, "g")}</Cell>
        },
        [date]
    )

    const renderWarehouseTourStart = useCallback(
        (item: StockTuple) => {
            const warehouseTourStart = item.warehouse.tour?.tourStart

            if (!warehouseTourStart) {
                return <Cell />
            }

            return <Cell>{date(warehouseTourStart, getBundleParams().expectedDeliveryDateFormat)}</Cell>
        },
        [date]
    )

    const renderQuantityExpectedDeliveryCell = useCallback(
        (item: StockTuple) => {
            let expectedDelivery = item.quantity[getBundleParams().quantityExpectedDeliveryProperty]

            if (!expectedDelivery) {
                return <Cell />
            }
            if (typeof expectedDelivery !== "string") {
                expectedDelivery = date(expectedDelivery, getBundleParams().expectedDeliveryDateFormat)
            }

            return <Cell>{expectedDelivery || ""}</Cell>
        },
        [date]
    )

    const columns = useMemo<RealTableProps<StockTuple>["columns"]>(() => {
        let showWarehouse = false
        let showAvailability = false
        let showMemo = false
        let showQuantityDescription = false
        let showQuantityExpectedDelivery = false
        let showQuantityValue = false
        let showDescription = false
        let showTourDeadline = false
        let showTourDelivery = false
        let showTourName = false
        let showWarehouseTourStart = false
        let showDeliveryTourName = false

        for (let i = 0; i < list.length; i++) {
            if (list[i].warehouse.name || list[i].warehouse.id) {
                showWarehouse = true
            }

            if (list[i].quantity.availability) {
                showAvailability = true
            }

            if (list[i].warehouse.tour?.tourOrderAcceptanceTimeLimit) {
                showTourDeadline = true
            }

            if (getBundleParams().hideAllZeroQuantityColumn ? list[i].quantity.value : list[i].quantity.value !== undefined) {
                showQuantityValue = true
            }

            if (list[i].quantity.description) {
                showQuantityDescription = true
            }

            if (list[i].warehouse.memos?.some((memo) => memo.type === MemoType.Note)) {
                showMemo = true
            }

            if ((list[i].warehouse.description || list[i].warehouse.shortDescription) && !list[i].warehouse.isManufacturerWarehouse) {
                showDescription = true
            }

            if (list[i].warehouse.tour?.expectedDelivery || list[i].warehouse.tour?.expectedDeliveryString) {
                showTourDelivery = true
            }

            if (list[i].warehouse.tour?.id || list[i].warehouse.tour?.description) {
                showTourName = true
            }

            if (list[i].warehouse.tour?.tourStart) {
                showWarehouseTourStart = true
            }

            const deliveries =
                list[i].warehouse.standardDelivery ||
                list[i].warehouse.dayExpressDelivery ||
                list[i].warehouse.nightExpressDelivery ||
                list[i].warehouse.collection
            if (deliveries?.find((delivery) => delivery.tours?.some((tour) => tour.id || tour.description))) {
                showDeliveryTourName = true
            }

            if (list[i].quantity.expectedDeliveryTime || list[i].quantity.expectedDeliveryTimeString) {
                showQuantityExpectedDelivery = true
            }

            if (
                showWarehouse &&
                showAvailability &&
                showTourDeadline &&
                showQuantityValue &&
                showTourDelivery &&
                showMemo &&
                showDescription &&
                showTourName &&
                showWarehouseTourStart &&
                showQuantityDescription &&
                showDeliveryTourName &&
                showQuantityExpectedDelivery
            ) {
                break
            }
        }

        return [
            showWarehouse && (
                <Column className="warehouseCell" renderItemContent={renderWarehouseCell}>
                    {translate(144)}
                </Column>
            ),
            showAvailability && (
                <Column className="availabilityCell" renderItemContent={renderAvailabilityCell}>
                    {!props.isTooltip && translate(412)}
                </Column>
            ),
            showQuantityValue && (
                <Column className="quantityCell" renderItemContent={renderQuantityValueCell}>
                    {translate(89)}
                </Column>
            ),
            showQuantityDescription && (
                <Column className="infoCell" renderItemContent={renderQuantityDescriptionCell}>
                    {translate(178)}
                </Column>
            ),
            showTourDeadline && (
                <Column className="deadlineCell" renderItemContent={renderTourOrderAcceptanceCell}>
                    {translate(1129)}
                </Column>
            ),
            showDescription && (
                <Column className="infoCell" renderItemContent={renderDescriptionCell}>
                    {translate(178)}
                </Column>
            ),
            showTourName && (
                <Column className="tourCell" renderItemContent={renderTourNameCell}>
                    {translate(766)}
                </Column>
            ),
            showTourDelivery && (
                <Column className="deliveryCell" renderItemContent={renderTourExpectedDeliveryCell}>
                    {translate(433)}
                </Column>
            ),
            showWarehouseTourStart && (
                <Column className="deliveryCell" renderItemContent={renderWarehouseTourStart}>
                    {translate(12739)}
                </Column>
            ),
            showDeliveryTourName && (
                <Column className="tourCell" renderItemContent={renderDeliveryTourNameCell}>
                    {translate(766)}
                </Column>
            ),
            showQuantityExpectedDelivery && (
                <Column className="deliveryCell" renderItemContent={renderQuantityExpectedDeliveryCell}>
                    {translate(709)}
                </Column>
            ),
            showMemo && (
                <Column className="memoCell" renderItemContent={renderMemoCell}>
                    {translate(14)}
                </Column>
            ),
        ]
    }, [
        list,
        props.isTooltip,
        renderAvailabilityCell,
        renderQuantityExpectedDeliveryCell,
        renderTourExpectedDeliveryCell,
        renderTourOrderAcceptanceCell,
        renderWarehouseTourStart,
        translate,
    ])

    if (!list?.length) {
        return null
    }

    return <RealTable data={list} columns={columns} />
}
