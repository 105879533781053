import { useEffect } from "react"
import { BundleComponent } from "@tm/morpheus"
import { useModuleNavigation, withWorkTaskConflict, WithWorkTaskConflictProps } from "@tm/context-distribution"

function Resolver(props: WithWorkTaskConflictProps) {
    const { workTaskConflict } = props

    const { resetTabs } = useModuleNavigation()

    useEffect(() => {
        if (workTaskConflict) {
            resetTabs()
            workTaskConflict?.onConfirm(false, true)
        }
    }, [workTaskConflict])

    return null
}

const ConflictResolver: BundleComponent = {
    name: "conflict-resolver",
    component: withWorkTaskConflict(Resolver),
}

export default ConflictResolver
