import { EMemoType, Memo_V1 } from "@tm/models"
import { DefaultItem, PDFItem } from "./mainItems"

type Props = {
    item: Memo_V1
}

function MainItemElement(props: Props) {
    const { item } = props

    switch (item.eType) {
        case EMemoType.PDF:
            return <PDFItem item={item} />
        default:
            return <DefaultItem item={item} />
    }
}

export default MainItemElement