import { VehicleType, ModuleAvailabilities, TruckModuleAvailabilities, TruckModuleType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../utils"

/**
 * Returns the available vehicle types (e.g. Car, Bike, Commercial)
 * @param moduleAvailabilities If not supplied the module availabilities will be read from `getBundleParams`
 */
export function getAvailableVehicleTypes(moduleAvailabilities?: ModuleAvailabilities): Array<VehicleType> {
    const available: Array<VehicleType> = []

    moduleAvailabilities = moduleAvailabilities ?? getBundleParams().moduleAvailabilities ?? {}

    if (moduleAvailabilities.hasCar) {
        available.push(VehicleType.PassengerCar)
    }
    if (moduleAvailabilities.hasBikes) {
        available.push(VehicleType.Motorcycle)
    }
    if (moduleAvailabilities.hasCommercialVehicles) {
        available.push(VehicleType.CommercialVehicle)
    }

    return available
}

/**
 * Returns the available truck module types (e.g. Truck Garage, MyTruck, MyTruck Plus, TM Truck)
 * @param truckModuleAvailabilities If not supplied the truck module availabilities will be read from `getBundleParams`
 */
export function getAvailableTruckModules(truckModuleAvailabilities?: TruckModuleAvailabilities): Array<TruckModuleType> {
    const available: Array<TruckModuleType> = []

    truckModuleAvailabilities = truckModuleAvailabilities ?? getBundleParams().moduleAvailabilities?.truckModules ?? {}

    if (truckModuleAvailabilities.truckGarage) {
        available.push("truckGarage")
    }
    if (truckModuleAvailabilities.myTruck) {
        available.push("myTruck")
    }
    if (truckModuleAvailabilities.myTruckPlus) {
        available.push("myTruckPlus")
    }
    if (truckModuleAvailabilities.tmTruck) {
        available.push("tmTruck")
    }
    if (truckModuleAvailabilities.tmTrailer) {
        available.push("tmTrailer")
    }

    return available
}

export type VehicleTypeTabIdentifier = VehicleType | TruckModuleType

export function isTruckModuleType(type: string | number): type is TruckModuleType {
    const allowed: Array<TruckModuleType> = ["truckGarage", "myTruck", "myTruckPlus", "tmTruck", "tmTrailer"]
    return allowed.includes(type as TruckModuleType)
}

/**
 * Get the tab name for the given vehicle type (@see `VehicleType`).
 */
export function getVehicleTypeName(identifier: VehicleType, translateFn: (id: number) => string): string | undefined {
    switch (identifier) {
        case VehicleType.PassengerCar:
            return translateFn(1234)
        case VehicleType.Motorcycle:
            return translateFn(730)
        case VehicleType.CommercialVehicle:
            return translateFn(1915)
    }
}

/**
 * Get the names for the given truck module type (@see `TruckModuleType`).
 */
export function getTruckTypeName(type: TruckModuleType): string | undefined {
    const { translateText } = useLocalization()
    switch (type) {
        case "truckGarage":
            return "Truck Garage"
        case "myTruck":
            return "MyTruck"
        case "myTruckPlus":
            return "MyTruck Plus"
        case "tmTruck":
            return translateText(13349)
        case "tmTrailer":
            return "TM Trailer"
    }
}
