import { useLocalization } from "@tm/localization"
import { CisShippingType } from "@tm/models"
import { Button, ButtonGroup } from "@tm/components"
import { StyledBox } from "./StyledComponents"

type Props = {
    shippingType: CisShippingType
}

export default function ShippingTypeComponent({ shippingType }: Props) {
    const { currency } = useLocalization()
    const { uniqueId, currencyCode, description, price, isDefault } = shippingType
    return (
        <StyledBox>
            <ButtonGroup key={uniqueId}>
                <Button color={isDefault ? "primary" : "inherit"} sx={{ cursor: "default" }}>
                    {description}
                </Button>
                <Button color="secondary" sx={{ cursor: "default" }}>
                    {currency(price, currencyCode)}
                </Button>
            </ButtonGroup>
        </StyledBox>
    )
}
