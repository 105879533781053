import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Box, Typography, styled } from "@tm/components"
import NextStep from "../_shared/nextStep"
import FastServiceHistory from "../history/component"

export default function Start() {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()

    return (
        <Box height="100%" display="grid" gridTemplateRows="1fr 1fr auto">
            <StyledWidgetContainer>
                <Box flex="1" height="100%" sx={{ "& .widget": { height: "100%" } }}>
                    {renderMicro!("vehicle", "vehicle-widget", {
                        className: "widget--car widget--w12",
                        hideCover: false,
                        hideFooterButtons: true,
                        alternativeView: true,
                        fullHeight: true,
                    })}
                </Box>
                <Box flex="1">
                    {renderMicro!("crm", "crm-widget", {
                        className: "widget--customer widget--w12",
                        listRoute: "/:workTaskId/customer/list",
                        detailsRoute: "/:workTaskId/customer/details/:customerId?",
                        hideCover: false,
                        hideFooterButtons: true,
                        alternativeView: true,
                        autoToggleEddit: true,
                        fullHeight: true,
                        hidePlaceholders: true,
                    })}
                </Box>
            </StyledWidgetContainer>
            <Box overflow="hidden" display="flex" flexDirection="column" marginLeft="1em">
                <Box>
                    <Typography variant="h2">{translateText(12587)}</Typography>
                </Box>
                <FastServiceHistory />
            </Box>

            <NextStep currentStepName="start" icon="pencil" buttonTextId={1483} active />
        </Box>
    )
}

const StyledWidgetContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
    "& .widget__header .widget__title": {
        fontSize: "1.25rem",
    },
})
