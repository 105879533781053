import { useBasketUpdateWorkflow } from "../../data/hooks/workTaskBasket/workflow/useBasketUpdateWorkflow"
import { useImportFastCalculation } from "../../data/hooks/workTaskBasket/imports/useImportFastCalculation"
import { useImportVoucherItems } from "../../data/hooks/workTaskBasket/imports/useImportVoucherItems"
import { useImportVouchers } from "../../data/hooks/workTaskBasket/imports/useImportVouchers"
import { useImportToCostEstimation } from "../../data/hooks/workTaskBasket/imports/useImportCostEstimation"

async function resolve() {}

export function useBasketImports() {
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const importVoucherItems = useImportVoucherItems(handleBasketUpdateWorkflow)
    const importVoucher = useImportVouchers(handleBasketUpdateWorkflow)
    const importFastCalculation = useImportFastCalculation(handleBasketUpdateWorkflow)
    const importToCostEstimation = useImportToCostEstimation(handleBasketUpdateWorkflow)
    return { importVoucherItems, importVoucher, importFastCalculation, importToCostEstimation }
}
