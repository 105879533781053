import { Article } from "@tm/models"
import { Stack } from "@tm/components"
import { TraderArticle } from "../business/helpers"
import { OfferItem } from "./OfferItem"

type Props = {
    workTaskId: string
    parts: Array<Article | TraderArticle>
    advertisementCategoryId: string
    onRequestPartList(showBasket: boolean): void
}

export function OfferItems({ workTaskId, parts, advertisementCategoryId, onRequestPartList }: Props) {
    return (
        <Stack direction="row" spacing={1} flex={1} mx={1} pt={0.5} pb={1}>
            {parts.map((x) => (
                <OfferItem
                    key={x.id}
                    workTaskId={workTaskId}
                    part={x}
                    advertisementCategoryId={advertisementCategoryId}
                    onRequestPartList={onRequestPartList}
                />
            ))}
        </Stack>
    )
}
