import { memo, useState, useEffect, useCallback } from "react"
import { Image } from "@tm/controls"
import { styled } from "@tm/components"

type Props = {
    supplierId: number
    supplierName: string
    active: boolean
}

const StyledImage = styled(Image)(() => ({
    maxHeight: "20px",
}))

const IndustrialInfoTab = memo((props: Props) => {
    const [error, setError] = useState(false)

    useEffect(() => {
        setError(false)
    }, [props.supplierId])

    const handleImageError = useCallback(() => {
        setError(true)
    }, [])

    if (error) {
        return <>{props.supplierName}</>
    }

    const url = `/styles/base/images/industrial-info-${props.supplierId}${props.active ? "-active" : ""}.png`
    return <StyledImage onError={handleImageError} url={url} />
})

export default IndustrialInfoTab
