import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const jfNetworkPv: BundleComponent = {
    name: "jf-network-pv",
    reduce,
    component,
}

export default jfNetworkPv
