import { Container, IModelContainer } from "@tm/nexus"
import { ShowCarModelDetailsRequest, ShowCarModelDetailsResponse } from "@tm/models"
import { checkCustomer } from "./checkCustomer"
import { getDATCalculationUrl, PrepareCalculationDatRequest, PrepareCalculationDatResponse } from "./prepareCalculation"
import { CheckCustomerResponse } from "./checkCustomer/model"
import { getVinSuggestions } from "./getVinSuggestions"
import { showModelDetails } from "./showModelDetails"
import { DATVehicleVinSearchResponse } from "./datVehicleVinSearch/model"
import { datVehicleVinSearch } from "./datVehicleVinSearch"
import { getDatDocument } from "./getDatDocument"
import { getDATCalculationData, GetDatCalculationDataRequest, GetDatCalculationDataResponse } from "./getCalculationData"
import { updateTotalsPrices, UpdateTotalPricesRequest, UpdateTotalPricesResponse } from "./updateTotalPrices"
import { CustomerBillingResponse, handleCustomerBilling } from "./handleCustomerBilling"

const DATKey = "dmg-calc-dat"

export const initRepositories = () => {
    Container.register({
        name: DATKey,
        containerActions: {
            checkCustomer,
            getDATCalculationUrl,
            getVinSuggestions,
            showModelDetails,
            datVehicleVinSearch,
            getDatDocument,
            getDATCalculationData,
            updateTotalsPrices,
            handleCustomerBilling,
        },
    })
}

type DATContainerActions =
    | { name: "checkCustomer"; action: () => Promise<CheckCustomerResponse> }
    | { name: "getDATCalculationUrl"; action: (calculationUrlRequest: PrepareCalculationDatRequest) => Promise<PrepareCalculationDatResponse> }
    | { name: "getVinSuggestions"; action: (query: string, modelId: number) => Promise<Array<string>> }
    | { name: "showModelDetails"; action: (request: ShowCarModelDetailsRequest) => Promise<ShowCarModelDetailsResponse> }
    | { name: "datVehicleVinSearch"; action: (vin: string) => Promise<DATVehicleVinSearchResponse> }
    | { name: "getDatDocument"; action: (contractId: string) => Promise<string> }
    | { name: "getDATCalculationData"; action: (calculationDataRequest: GetDatCalculationDataRequest) => Promise<GetDatCalculationDataResponse> }
    | { name: "updateTotalsPrices"; action: (calculationDataRequest: UpdateTotalPricesRequest) => Promise<UpdateTotalPricesResponse> }
    | { name: "handleCustomerBilling"; action: () => Promise<CustomerBillingResponse> }

type DATContainer = IModelContainer<void, DATContainerActions>

export const useContainer = () => Container.getInstance(DATKey) as DATContainer
