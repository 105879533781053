import { FittingPosition, Vehicle, VehicleType } from "@tm/models"
import { RDKSListState } from "./model"
import { getBundleParams } from "../../../utils"
import { SensorItemsRequest } from "../../../data/repositories/articles-loadSensorItems/model"

export function createSensorArticlesRequest(filters: RDKSListState["filters"], selectedFilters: RDKSListState["selectedFilters"], vehicle: Vehicle, extendedAssortment?: boolean, pageIndex?: number): SensorItemsRequest | undefined {

    let supplierIds = filters.manufacturer.map(x => x.id)
    if (selectedFilters.manufacturer?.length)
        supplierIds = selectedFilters.manufacturer?.map(x => x.id || 0)

    if (!supplierIds.length) return

    return {
        // TODO hardcoded productGroup
        productGroupIds: [2232],
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType || VehicleType.PassengerCar,
        supplierIds: supplierIds,
        extendedAssortment: extendedAssortment || selectedFilters.extendedAssortment,
        fittingSideFilter: FittingPosition.None,
        pageIndex: pageIndex ?? 1,
        pageSize: getBundleParams().tyresPageSize,
    }
}

export function createNextSensorArticlesRequest(state: RDKSListState, vehicle: Vehicle, extendedAssortment?: boolean): SensorItemsRequest | undefined {
    const { filters, selectedFilters, articles: { pageIndex } } = state

    let supplierIds = filters.manufacturer.map(x => x.id)
    if (selectedFilters.manufacturer?.length)
        supplierIds = selectedFilters.manufacturer?.map(x => x.id || 0)
    
    if (!supplierIds.length) return

    return {
        // TODO hardcoded productGroup
        productGroupIds: [2232],
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType || 1,
        supplierIds: supplierIds,
        extendedAssortment: extendedAssortment || selectedFilters.extendedAssortment,
        fittingSideFilter: FittingPosition.None,
        pageIndex: pageIndex + 1,
        pageSize: getBundleParams().tyresPageSize,
    }
}