import { useStyle, useUser } from '@tm/context-distribution'
import { Icon, Switch, Toolbar, Tooltip } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { useActions } from '@tm/morpheus'
import { RouteComponentProps, withRouter } from '@tm/utils'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { FiltersModal, ListAttributeFilters } from '.'
import { MainState } from '../../main'
import { MainActions } from '../../main/business'
import { PurchasePriceSwitch } from '../../_shared'
import MatchCodeSearch from '../../_shared/matchCodeSearch'
import { getBundleParams } from '../../../utils'
import { useMemo } from 'react'
import { Button } from '@tm/components'

type Props = RouteComponentProps & {
}

const TopFiltersComponent: React.FC<Props> = ({ }) => {
	const { translateText } = useLocalization()
	const user = useUser()
	const { useNewNavbar } = getBundleParams()
	const style = useMemo(() => getStyle(useNewNavbar), [])

	const actions = useActions(MainActions, "updateSearchValue", "loadTiresList", "changeStep", "resetAllAttributeFilters")
	const { searchValue, clippedFilters, atributeFilters } = useSelector((s: MainState) => ({ searchValue: s.list.searchValue, clippedFilters: s.list.clippedFilters, atributeFilters: s.list.selectedFilters }))

	const handleSearch = (searchValue?: string) => {
		searchValue && actions.updateSearchValue(searchValue)
		actions.loadTiresList(true)
		actions.changeStep("list", { size: searchValue, season: undefined, carType: undefined })
	}

	const handleAllFiltersReset = () => {
		actions.resetAllAttributeFilters()
		actions.loadTiresList(undefined, true)
	}

	const handleChangeCompactView = (compactView: boolean) => {

		// actions.setArticleListCompactView(compactView)

		if (user?.userSettings) {
			user.setUserSetting("ARTICLE_LIST_SETTINGS", {
				...user.userSettings.articleListSettings,
				viewOptions: {
					...user.userSettings.articleListSettings?.viewOptions,
					compactView
				}
			})
		}
	}

	const handleChangeShowArticleImages = (showArticleImages: boolean) => {
		if (user?.userSettings) {
			user.setUserSetting("ARTICLE_LIST_SETTINGS", {
				...user.userSettings.articleListSettings,
				viewOptions: {
					...user.userSettings.articleListSettings?.viewOptions,
					showArticleImages
				}
			})
		}
	}

	const renderTooltipContent = () => {
		return (
			<Tooltip className="tk-parts list-options__tooltip" content={translateText(1805)}>
				<Switch
					status={user?.userSettings?.articleListSettings?.viewOptions?.showArticleImages}
					onChange={handleChangeShowArticleImages}
					label={translateText(795)}
					alignLabel="left"
				/>
			</Tooltip>
		)
	}

	const isResetBtnEnabled = Object.values(atributeFilters).some(x => Array.isArray(x) ? x.length : x)

	const renderFilterModalButton = () => {
		if (useNewNavbar) {
			return (
				clippedFilters ?
					<FiltersModal>
						<ListAttributeFilters inModal />
					</FiltersModal> : null
			)
		}

		return (
			<FiltersModal disabled={!clippedFilters}>
				<ListAttributeFilters inModal />
			</FiltersModal>
		)
	}

	return (
		<div className={style.wrapper}>
			{useNewNavbar && <Toolbar />}
			<Toolbar title={translateText(1883)}>
				<MatchCodeSearch
					value={searchValue}
					onButtonClick={handleSearch}
				/>
			</Toolbar>
			<Toolbar className="tk-parts" title={translateText(361)}>
				<div className="list-options">
					<Tooltip
						content={renderTooltipContent()}
						event="click"
						style="primary"
						className="tk-parts list-options"
					>
						{useNewNavbar ? <Button variant="outlined" startIcon={<Icon name="settings" />} /> : <Button variant="outlined">{translateText(361)}</Button>}
					</Tooltip>

				</div>
			</Toolbar>
			<PurchasePriceSwitch />
			<Toolbar className={style.compactViewToolbar} title={translateText(3009)}>
				<Tooltip className="tk-parts" content={translateText(1804)}>
					<Switch
						status={user?.userSettings?.articleListSettings?.viewOptions?.compactView}
						onChange={handleChangeCompactView}
					/>
				</Tooltip>
			</Toolbar>
			<Toolbar className="tk-parts" title={translateText(209)}>
				{renderFilterModalButton()}
				<Button
					variant="outlined"
					disabled={!isResetBtnEnabled}
					onClick={handleAllFiltersReset}
					title={translateText(1664)}
					startIcon={<Icon name="remove-filter" />}
				/>
			</Toolbar>
		</div>
	)
}

function getStyle(useNewNavbar?: boolean) {
	return useStyle({
		wrapper: {
			flex: 1,
			justifyContent: !useNewNavbar ? "flex-end" : "unset",
			display: "flex",
			marginLeft: useNewNavbar ? "9.8%" : 0
		},
		compactViewToolbar: {
			$nest: {
                "&:before": {
                    borderLeft: "transparent !important"
                }
            }
		}
	})(TopFiltersComponent)
}

export default withRouter(TopFiltersComponent)