import { ActionDispatch } from "@tm/morpheus"
import { BundleActionType } from "../../../business"
import { SearchTreeNode } from "../../../data"

type ComponentActionType = BundleActionType

export type RequestState = {
    breadcrumbs: Array<SearchTreeNode>
}

const DEFAULT_STATE: RequestState = {
    breadcrumbs: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): RequestState {
    switch (action.type) {
        case "ADD_BREADCRUMB": {
            return {
                ...state,
                breadcrumbs: [...state.breadcrumbs, action.payload],
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "ADD_BREADCRUMB": {
            dispatch(action)
            break
        }
        default:
            break
    }
}
