import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, uniqueId, encodeUniqueId, RouteComponentProps } from "@tm/utils"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Widget, Button, Text, Image } from "@tm/controls"

type Props = RouteComponentProps<{ workTaskId?: string }> & ConfigurationProps & {}

type ConfigurationProps = {
    height?: number
    size?: string
    icon?: string
    title?: string
    text?: string
    image?: string
    imageLink?: string
    className?: string
    button?: {
        text?: string
        icon?: string
        iconRight?: boolean
        highlight?: boolean
        createUrl?: string
        url?: string
        params?: { [key: string]: any }
        openNewWindow?: boolean
    }
    highlight?: boolean
}

function WidgetOpenComponent(props: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { workTask } = useWorkTask() || {}

    const { history, button, match, imageLink, icon, className, highlight, text, title, height, size, image } = props

    const url = useMemo(() => {
        if (!button) {
            return undefined
        }

        const createUrl = eval(`[${button.createUrl}][0]`)

        if (!userContext || typeof createUrl != "function") {
            return undefined
        }

        return createUrl(userContext, workTask?.vehicle, workTask?.customer) || undefined
    }, [userContext, workTask?.vehicle, workTask?.customer])

    function renderFooter(): JSX.Element {
        const buttonLayout = []
        if (button?.iconRight) {
            buttonLayout.push("iconRight")
        }

        const btnText = button?.text || ""

        return (
            <Button
                icon={button?.icon}
                layout={buttonLayout as any}
                onClick={handleRedirect}
                skin={button?.highlight ? "highlight" : undefined}
                size="l"
            >
                {translateText(btnText)}
            </Button>
        )
    }

    function handleRedirect() {
        if (button?.openNewWindow || (button?.params && button?.params.target == "_blank")) {
            window.open(url || button.url)
        } else if (imageLink) {
            const redirectUrl = replaceWorkTaskId(imageLink)
            history.push(renderRoute(redirectUrl, { ...match.params }))
        } else {
            const targetUrl = !url && button?.url ? replaceWorkTaskId(button.url) : url
            targetUrl && history.push(renderRoute(targetUrl, { ...match.params, ...(button?.params ? button.params : {}) }))
        }
    }

    function replaceWorkTaskId(url: string) {
        return url.replace(/:workTaskId\??/, match.params.workTaskId || encodeUniqueId(uniqueId()))
    }

    let qualifiedClassName = "bundle-misc widget-cover-only widget"

    if (className) {
        qualifiedClassName = `${qualifiedClassName} ${className}`
    }

    const widgetProps = {
        height,
        size: (size as any) || "1x1",
        active: true,
        highlight,
        className: qualifiedClassName,
        title: title && translateText(title),
        iconName: icon,
        footer: button ? renderFooter() : undefined,
    }

    return (
        <Widget {...widgetProps}>
            {image && !imageLink ? <Image url={image} /> : null}
            {image && imageLink ? (
                <div onClick={handleRedirect}>
                    <img src={image} />
                </div>
            ) : null}
            {text ? <Text>{translateText(text)}</Text> : null}
        </Widget>
    )
}

export default withRouter(WidgetOpenComponent)
