import { useEffect } from "react"
import { useUser } from "@tm/context-distribution"
import { Box, LoadingContainer } from "@tm/components"
import { useMutation } from "react-query"
import LoginDialog from "../login/LoginDialog"
import Header from "../header/Header"
import { mainActions, useSindriStore } from "../../data/state"
import { Repositories } from "../../data"
import Maintenance from "../maintenance/Maintenance"
import JobTable from "../jobs/JobTable"

export default function Main() {
    const user = useUser()
    const sindriToken = useSindriStore((state) => state.main.sindriToken)
    const showLoginDialog = useSindriStore((state) => state.main.showLoginDialog)
    const hasSindriCredentialsInSettings = user.userSettings?.sindriSettings.APIKey && user.userSettings?.sindriSettings.APIKeyID

    const loginToSindri = useMutation({
        mutationFn: ({ APIKey, APIKeyID }: { APIKey: string; APIKeyID: string }) => Repositories.SindriLogin(APIKey, APIKeyID),
        onSuccess(data, variables) {
            mainActions.sindriLogin({ APIKey: variables.APIKey, APIKeyID: variables.APIKeyID }, data)
        },
        onError() {
            mainActions.setShowLoginDialog(true)
        },
    })

    useEffect(() => {
        if (sindriToken || !user.userSettings) {
            return
        }

        if (hasSindriCredentialsInSettings) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            loginToSindri.mutate({ APIKey: user.userSettings.sindriSettings.APIKey!, APIKeyID: user.userSettings.sindriSettings.APIKeyID! })
        } else {
            mainActions.setShowLoginDialog(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sindriToken, user.userSettings])

    const masterLoading = !user.userSettings || !sindriToken

    function onLoginDenied() {
        if (!hasSindriCredentialsInSettings) {
            console.log("User login denied by user")
            // TODO see what the designer thinks here, what to show.
        }
    }

    return (
        <LoadingContainer isLoading={masterLoading}>
            {showLoginDialog && <LoginDialog onLoginDenied={onLoginDenied} />}
            {!showLoginDialog && sindriToken && (
                <Box height="100%" padding="0.5em" display="flex" flexDirection="column" gap="10px">
                    <Header />
                    <JobTable />
                    <Maintenance />
                </Box>
            )}
        </LoadingContainer>
    )
}
