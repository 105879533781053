import { WholesalerArticleQuantities, WholesalerArticleQuantitiesRequest } from "@tm/models"
import { ajax, createBufferedRequestFunction, getStoredAuthorization, notUndefinedOrNull } from "@tm/utils"
import { getBasketServiceUrl } from "../../.."

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Order`
}

export const getWholesalerArticleQuantities = createBufferedRequestFunction({
    callService: getShowWholesalerArticleQuantities,
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return (
            serviceResponse.find((item) => item.article.wholesalerArticleNumber === request.article.wholesalerArticleNumber) || {
                article: request.article,
                isInOrder: false,
            }
        )
    },
})

export function getShowWholesalerArticleQuantities(requests: Array<WholesalerArticleQuantitiesRequest>) {
    const url = `${getServiceUrl()}/ShowQuantitiesByWholesalerArticle`
    const authorization = getStoredAuthorization()
    const body = {
        workTaskId: requests[requests.length - 1].workTaskId,
        articles: requests.map((r) => r.article),
    }

    return ajax<{ wholesalerArticleQuantities?: Array<WholesalerArticleQuantities> }>({ url, body, authorization, method: "POST" }).then(
        (data) => data?.wholesalerArticleQuantities?.filter(notUndefinedOrNull) || []
    )
}
