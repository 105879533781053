import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { GetVinSuggestionsResponse } from "./model"

export const getVinSuggestions = (query: string, modelId: number) => {
    const url = `${getServiceUrl()}/Vins`
    const authorization = getStoredAuthorization()
    const body = { query, modelId }

    return ajax<GetVinSuggestionsResponse>({ url, body, authorization })
        .then((data) => data?.vins ?? [])
        .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error)
        })
}

export const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.vinPickerUrl
}
