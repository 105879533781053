import { Box, Card, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { FC, useMemo } from "react"
import { PartV2Dto } from "../../../models"
import OverflowTypography from "../../_shared/OverflowTypography"

type AreaInfoBarProps = {
    selectedAreaCode: string | undefined
    selectedAreaInfo: PartV2Dto | undefined
}

const AreaInfoBar: FC<AreaInfoBarProps> = ({ selectedAreaCode, selectedAreaInfo }) => {
    const { translateText } = useLocalization()

    const quantity = useMemo(() => {
        const amount = selectedAreaInfo?.attributes?.find((attribute) => attribute.code === "amount")

        if (selectedAreaInfo && "qty" in selectedAreaInfo) {
            return selectedAreaInfo?.qty.value ? `${selectedAreaInfo?.qty.value} ${selectedAreaInfo?.qty.units}` : "-"
        }
        return amount?.values[0] ?? "-"
    }, [selectedAreaInfo])

    return (
        <Card sx={{ display: "flex", flexDirection: "row", gap: "0.5em", padding: "0.5em 1em", height: "9em" }}>
            <Box sx={{ minWidth: "2em" }}>
                <Typography sx={{ color: "primary.main" }}>{selectedAreaInfo?.areaCode ?? selectedAreaCode}</Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em", overflow: "hidden" }}>
                <InfoBarRowBox>
                    <Typography>{translateText(155)}</Typography>
                    <OverflowTypography
                        sx={{ fontWeight: "bold" }}
                        title={selectedAreaInfo?.displayName ?? "-"}
                        content={selectedAreaInfo?.displayName ?? "-"}
                    />
                </InfoBarRowBox>
                <InfoBarRowBox>
                    <Typography>{translateText(13411)}</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>{selectedAreaInfo?.partNumberFormatted ?? "-"}</Typography>
                </InfoBarRowBox>
                <InfoBarRowBox>
                    <Typography>{translateText(89)}</Typography>
                    <Typography sx={{ fontWeight: "bold" }}>{quantity}</Typography>
                </InfoBarRowBox>
            </Box>
        </Card>
    )
}

const InfoBarRowBox = styled(Box)({
    display: "flex",
    flexDirection: "row",
    gap: "0.5em",
})

export default AreaInfoBar
