import { useQuery } from "react-query"
import { VrcInfo } from "../model/vrc-lookup"
import { showModlelsByVrcInfo } from "../repositories/cars/showModlelsByVrcInfo"

const KEY = "vrc_showmodelsbyvrcinfo"

export function useShowModelsByVrcInfo(vrcInfo: VrcInfo, useDatLookup: boolean) {
    const { data, isLoading } = useQuery([KEY], () => showModlelsByVrcInfo({ vrcInfo, useDatLookup, pageIndex: 1, pageSize: 25 }))

    return { data, isLoading }
}
