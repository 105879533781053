import React from "react"
import { useLocalization } from "@tm/localization"
import { CellContentPosition, Table, TableCellData, TableColumnData } from "@tm/components"
import { IncludedWorkItem } from "../../../../../data/model"

type Props = {
    includedWorks: Array<IncludedWorkItem>
    showSupplierArticleNumbers: boolean
}

export default function IncludedWorksTableComponent({ includedWorks, showSupplierArticleNumbers }: Props) {
    const { translate } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = []
        if (showSupplierArticleNumbers) {
            columns.push({ header: translate(58) })
        }
        columns.push({ header: translate(61), alignContent: CellContentPosition.left, minWidth: 1500 })

        return columns
    }

    function getModuleCellData(includedWork: IncludedWorkItem): TableCellData[] {
        const cellData: TableCellData[] = []
        if (showSupplierArticleNumbers) {
            cellData.push({ displayValue: includedWork.providerWorkId, id: "includedWork_1" })
        }
        cellData.push({ displayValue: includedWork.description, id: "includedWork_2" })
        return cellData
    }

    const displayData = includedWorks?.map((includedWork, index) => ({
        cells: getModuleCellData(includedWork),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    return <Table columns={getColumns()} rows={displayData} overflowY="unset" headerBackground="transparent" rowCap={1} />
}
