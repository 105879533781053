import { useQuery } from "react-query"
import * as Data from "../../.."
import { UpdateOrderRequest } from "../../../model"
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"

const KEY = "basket_useUpdateOrder"

export function useUpdateOrderData(workTaskId: string, request: UpdateOrderRequest) {
    const updateOrderIndex = useWorkTaskBasketStore((store) => store.getUpdateOrderIndex(workTaskId))
    const QUERY_KEY = [KEY, workTaskId, updateOrderIndex]

    const { data, isLoading, isError } = useQuery(
        QUERY_KEY,
        () => {
            return Data.updateOrder(request)
        },
        { enabled: request.erpGroups.some((erpGroup) => !!erpGroup.items.length) && updateOrderIndex > 0 }
    )

    return {
        updatedOrderData: data,
        updateOrderDataLoading: isLoading,
        updateOrderDataWithError: isError,
    }
}
