import { Button, Icon, Box, styled, Loader, Typography, useTheme } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { Scrollbar } from "@tm/controls"
import { useEffect, useState } from "react"
import { DatSelectionSteps } from "../../data/enums"
import { managerSelector } from "../../selectors"
import { Wrapper } from "../../styles"
import Calculation from "../calculation/component"
import Extern from "../extern/component"
import ModuleManager from "../manager/component"
import Navigation from "../navigation/component"
import Settings from "../settings/component"
import Summary from "../summary/component"
import { UserRegistration } from "../_shared"
import { MainActions } from "./business"
import UserCredentialsModal from "../_shared/UserCredentialsModal"
import { MatchParams } from "../navigation/business/model"

type Props = {
    defaultRoute: string
    detailsTabRoute: string
}

export default function Main({ defaultRoute, detailsTabRoute }: Props) {
    const matchParams = useParams<MatchParams>()
    const workTaskId = useWorkTask()?.workTask?.id ?? "FAKE_WORKTASK"
    const { translateText } = useLocalization()
    const { checkCustomerLoading, checkCustomerError, userIsRegistered, showPDF, userMustEnterCredentials } = useSelector(managerSelector)
    const theme = useTheme()

    const actions = useActions(MainActions, "checkCustomer")

    let scrollTimeoutId: number | undefined
    const [showPricesInHeader, setShowPricesInHeader] = useState(false)
    const [showRegistrationModal, setShowRegistrationModal] = useState(false)

    useEffect(() => {
        setShowRegistrationModal(showPDF ?? false)
    }, [userIsRegistered])

    if (!workTaskId) {
        return null
    }

    const handleScroll = (e: any) => {
        window.clearTimeout(scrollTimeoutId)

        scrollTimeoutId = window.setTimeout(() => {
            setShowPricesInHeader(e.target.scrollTop >= 125)
        }, 100)
    }

    let content

    if (userIsRegistered) {
        content = (
            <Scrollbar onScroll={handleScroll}>
                {matchParams.view === DatSelectionSteps.SUMARRY && <Summary />}
                {matchParams.view === DatSelectionSteps.EXTERN && <Extern />}
                {matchParams.view === DatSelectionSteps.CALCULATION && <Calculation />}
                {matchParams.view === DatSelectionSteps.DATSETTINGS && <Settings />}
            </Scrollbar>
        )
    }

    if (checkCustomerLoading) {
        content = <Loader size="small" />
    }

    if (checkCustomerError) {
        content = (
            <Box display="flex" justifyContent="center" alignItems="center">
                <Typography fontWeight={700} padding={theme.spacing(0, 1)}>
                    {translateText(12591)}
                </Typography>
                <Button onClick={() => actions.checkCustomer(translateText)} color="error" size="large" startIcon={<Icon name="synchronize" />}>
                    {translateText(401)}
                </Button>
            </Box>
        )
    }

    return (
        <StyledMain setflex={matchParams.view !== DatSelectionSteps.DETAILS}>
            {userMustEnterCredentials && <UserCredentialsModal />}
            {showRegistrationModal && <UserRegistration />}
            <ModuleManager />
            <Navigation defaultRoute={defaultRoute} showPrices={showPricesInHeader} detailsTabRoute={detailsTabRoute} />

            {matchParams.view !== DatSelectionSteps.DETAILS && (
                <Wrapper sx={{ padding: theme.spacing(0.5, 1.5) }} centeredContent={checkCustomerLoading || checkCustomerError}>
                    {content}
                </Wrapper>
            )}
        </StyledMain>
    )
}

const StyledMain = styled(Box)<{ setflex?: boolean }>(({ setflex }) => ({
    display: "flex",
    flexDirection: "column",
    ...(setflex && {
        flex: 1,
        flexBasis: "100%",
    }),
}))
