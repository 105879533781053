import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, encodeUniqueId, parseQueryString, createQueryString, RouteComponentProps, TmaHelper } from "@tm/utils"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { Dialog, Box, Button as MuiButton, Typography } from "@tm/components"
import { Toolbar, Button, InputGroup, DefaultSearchField, Headline } from "@tm/controls"
import { ConfigParams, ECounterType } from "@tm/models"
import { ComponentState, IActions, Actions } from "./business"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps & {
    state: ComponentState
    actions: IActions
    listRoute: string
    detailsRoute: string
}

function CustomerDetailsComponent({ state, actions, listRoute, detailsRoute, match, location, history }: Props) {
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [searchActive, setSearchActive] = useState<boolean>(false)
    const { translateText } = useLocalization()
    const { query } = parseQueryString(location.search)
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const [searchQuery, setSearchQuery] = useState<string>(query?.toString() || "")

    const { view, selectedId, customerDetails } = state

    useEffect(() => {
        setSearchQuery(query?.toString() || "")
        setSearchActive(!!query?.toString())
    }, [query])

    function handleSearchChange(query: string) {
        setSearchQuery(query)
    }

    function handleSearch(query: string) {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerSearch)
        const customerSearchUrl = renderRoute(listRoute, match.params) + createQueryString({ query })
        history.push(customerSearchUrl)
    }

    function handleSearchButtonClick() {
        handleSearch(searchQuery)
    }

    const handleChangeReset = () => {
        if (searchActive) {
            handleSearch("")
        }
    }

    function handleOpenCloseDialog() {
        TmaHelper.GeneralCountEvent.Call(ECounterType.DeleteCustomer)
        setOpenDeleteDialog((prev) => !prev)
    }

    function deleteCustomer() {
        customerDetails && actions.deleteCustomer()
        setOpenDeleteDialog(false)
        history.push(renderRoute(listRoute, match.params))
    }

    const listUrl = renderRoute(listRoute, match.params)
    const detailsUrl = selectedId ? renderRoute(detailsRoute, { ...match.params, customerId: encodeUniqueId(selectedId) }) : undefined
    const newCustomerUrl = view !== "DETAILS" || !!selectedId ? renderRoute(detailsRoute, { ...match.params, customerId: undefined }) : undefined
    const { nextLight } = getBundleParams()

    return (
        <div className="tk-crm navigation">
            <Headline size="s">{translateText(107)}</Headline>
            {!nextLight && (
                <>
                    <Toolbar title={translateText(176)}>
                        <Button
                            linkTo={listUrl}
                            icon="menu"
                            skin={view === "LIST" ? "primary" : undefined}
                            className={view === "LIST" ? "is-active" : undefined}
                            onClick={() => TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerList)}
                        >
                            {translateText(6)}
                        </Button>
                        <Button
                            linkTo={view !== "DETAILS" ? detailsUrl : undefined}
                            disabled={!detailsUrl}
                            icon="details"
                            skin={view === "DETAILS" ? "primary" : undefined}
                            className={view === "DETAILS" ? "is-active" : undefined}
                            onClick={() => TmaHelper.GeneralCountEvent.Call(ECounterType.CustomerDetails)}
                        >
                            {translateText(43)}
                        </Button>
                    </Toolbar>
                    <Toolbar title={translateText(135)}>
                        <InputGroup>
                            <DefaultSearchField
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onChangeConfirm={handleSearch}
                                placeholder={translateText(184)}
                                tooltip={translateText(572)}
                                onChangeReset={handleChangeReset}
                                showClear
                            />
                            <Button icon="search" onClick={handleSearchButtonClick} />
                        </InputGroup>
                    </Toolbar>
                    <Toolbar title={translateText(361)}>
                        <Button
                            linkTo={newCustomerUrl}
                            disabled={!newCustomerUrl}
                            icon="plus"
                            layout={["iconRight"]}
                            onClick={() => TmaHelper.GeneralCountEvent.Call(ECounterType.AddCustomer)}
                        >
                            {translateText(115)}
                        </Button>
                        <Button
                            onClick={handleOpenCloseDialog}
                            disabled={!customerDetails}
                            icon="delete"
                            title={translateText(69)}
                            skin={deleteButtonColorError ? "danger" : undefined}
                        />
                    </Toolbar>
                    <Dialog open={openDeleteDialog} skin="warning" position="top">
                        <Typography component="span" pl={2}>
                            {translateText(148)}
                        </Typography>
                        <Box pl={2} textAlign="center" display="initial">
                            <MuiButton variant="contained" onClick={handleOpenCloseDialog}>
                                {translateText(584)}
                            </MuiButton>
                            <MuiButton variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={deleteCustomer}>
                                {translateText(585)}
                            </MuiButton>
                        </Box>
                    </Dialog>
                </>
            )}
        </div>
    )
}

export default connectComponent(Actions, withRouter(CustomerDetailsComponent))
