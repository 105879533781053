import { Box, Button, Icon, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useCallback, useEffect, useState } from "react"
import { useUser } from "@tm/context-distribution"
import { UserModuleType, SelectedCustomerGroupMode, SelectedPriceTypeMode, SelectedPriceVatMode } from "@tm/models"
import { SharingData } from "."
import { ShareButton } from "./ShareButton"
import { OptionsButton } from "./OptionsButton"
import { RowStack } from "../../../_shared/order-options/components/StyledComponents"
import { CostEstimation } from "../../../../data/model"

type Props = {
    disabled: boolean
    customerEmail: string | undefined
    costEstimation?: CostEstimation
    onToggleShowManufacturers(state: boolean): void
    onToggleShowArticleNumbers(state: boolean): void
    onToggleShowWholesalerArticleNumbers(state: boolean): void
    onTogglePrintVehicleImage(state: boolean): void
    onChangeRepairTimeCalculation(useRepairTimeCalculation: boolean): void
    onChangePriceTypeMode(priceTypeMode?: SelectedPriceTypeMode): void
    onChangePriceVatMode(priceVatMode?: SelectedPriceVatMode): void
    onChangeUseNoteText(useNoteText: boolean): void
    onChangeCustomerGroupMode(customerGroupMode?: SelectedCustomerGroupMode): void
    onPrintButtonClick(): void
    onSendCostEstimationButtonClick(): void
    onGenerateCostEstimationLinkButtonClick(): void
}

export function CostEstimationOptions(props: Props) {
    const { disabled, customerEmail, costEstimation } = props
    const { translateText } = useLocalization()
    const { userContext, userSettings } = useUser()
    const hasWheelsModule = userContext.modules?.some((x) => x.type === UserModuleType.TMWheels) ?? false

    const [sharingData, setSharingData] = useState<SharingData>({ loading: false })
    const [showSupplierArticleNumbers, setShowSupplierArticleNumbers] = useState(true)
    const [showWholesalerArticleNumbers, setShowWholesalerArticleNumbers] = useState(true)
    const [showManufacturer, setShowManufacturer] = useState(true)
    const [printVehicleImage, setPrintVehicleImage] = useState(hasWheelsModule)
    const [useNoteTextByDefault, setUseNoteTextByDefault] = useState(
        costEstimation?.useNoteText ?? userSettings?.costEstimationOptions?.useNoteTextByDefault ?? false
    )
    const [noteText, setNoteText] = useState(userSettings?.costEstimationOptions?.noteText ?? "")

    const useNoteTextDefault = useCallback(() => {
        if (!userSettings?.costEstimationOptions?.noteText || userSettings?.costEstimationOptions?.noteText.length === 0) {
            return false
        }

        // Make sure, that the value in the costEstimation is the same
        if (costEstimation?.useNoteText === undefined && userSettings?.costEstimationOptions?.useNoteTextByDefault) {
            handleToggleUseNoteTextByDefault(true)
        }

        return costEstimation?.useNoteText ?? userSettings?.costEstimationOptions?.useNoteTextByDefault ?? false
    }, [costEstimation?.useNoteText, userSettings?.costEstimationOptions?.noteText, userSettings?.costEstimationOptions?.useNoteTextByDefault])

    useEffect(() => {
        setUseNoteTextByDefault(useNoteTextDefault)
    }, [costEstimation?.useNoteText, userSettings?.costEstimationOptions?.useNoteTextByDefault])

    useEffect(() => {
        setNoteText(userSettings?.costEstimationOptions?.noteText ?? "")
    }, [userSettings?.costEstimationOptions?.noteText])

    const handleToggleShowSupplierArticleNumbers = useCallback(
        (showNumbers: boolean) => {
            setShowSupplierArticleNumbers(showNumbers)
            props.onToggleShowArticleNumbers(showNumbers)
            setSharingData({ loading: false })
        },
        [props]
    )
    const handleToggleShowWholesalerArticleNumbers = useCallback(
        (showNumbers: boolean) => {
            setShowWholesalerArticleNumbers(showNumbers)
            props.onToggleShowWholesalerArticleNumbers(showNumbers)
            setSharingData({ loading: false })
        },
        [props]
    )
    const handleToggleShowManufacturers = useCallback(
        (show: boolean) => {
            setShowManufacturer(show)
            props.onToggleShowManufacturers(show)
            setSharingData({ loading: false })
        },
        [props]
    )
    const handleTogglePrintVehicleImage = useCallback(
        (print: boolean) => {
            setPrintVehicleImage(print)
            props.onTogglePrintVehicleImage(print)
            setSharingData({ loading: false })
        },
        [props]
    )

    const handleToggleUseNoteTextByDefault = useCallback(
        (state: boolean) => {
            props.onChangeUseNoteText(state)
            setSharingData({ loading: false })
        },
        [props]
    )

    const handleChangeRepairTimeCalculation = useCallback(
        (useRepairTimeCalculation: boolean) => {
            props.onChangeRepairTimeCalculation(useRepairTimeCalculation)
            setSharingData({ loading: false })
        },
        [props]
    )

    const handleChangePriceTypeMode = useCallback(
        (priceTypeMode?: SelectedPriceTypeMode) => {
            props.onChangePriceTypeMode(priceTypeMode)
            setSharingData({ loading: false })
        },
        [props]
    )

    const handleChangePriceVatMode = useCallback(
        (priceVatMode?: SelectedPriceVatMode) => {
            props.onChangePriceVatMode(priceVatMode)
            setSharingData({ loading: false })
        },
        [props]
    )

    const handleChangeCustomerGroupMode = useCallback(
        (customerGroupMode?: SelectedCustomerGroupMode) => {
            props.onChangeCustomerGroupMode(customerGroupMode)
            setSharingData({ loading: false })
        },
        [props]
    )

    return (
        <RowStack alignItems="center" spacing={1}>
            <OptionsButton
                disabled={disabled}
                costEstimation={costEstimation}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                hasWheelsModule={hasWheelsModule}
                printVehicleImage={printVehicleImage}
                showManufacturer={showManufacturer}
                useNoteTextByDefault={useNoteTextByDefault}
                noteText={noteText}
                onToggleShowManufacturers={handleToggleShowManufacturers}
                onTogglePrintVehicleImage={handleTogglePrintVehicleImage}
                onToggleShowWholesalerArticleNumbers={handleToggleShowWholesalerArticleNumbers}
                onToggleShowArticleNumbers={handleToggleShowSupplierArticleNumbers}
                onChangeRepairTimeCalculation={handleChangeRepairTimeCalculation}
                onChangePriceTypeMode={handleChangePriceTypeMode}
                onChangePriceVatMode={handleChangePriceVatMode}
                onChangeCustomerGroupMode={handleChangeCustomerGroupMode}
                onToggleUseNoteTextByDefault={handleToggleUseNoteTextByDefault}
            />
            <Tooltip title={translateText(49)}>
                <Box>
                    <Button disabled={disabled} onClick={props.onPrintButtonClick} startIcon={<Icon name="print" />} variant="outlined" />
                </Box>
            </Tooltip>
            <ShareButton
                customerEmail={customerEmail}
                disabled={disabled}
                costEstimation={costEstimation}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                printVehicleImage={printVehicleImage}
                useNoteTextByDefault={useNoteTextByDefault}
                sharingData={sharingData}
                showManufacturer={showManufacturer}
                onChangeSharingData={(sharing) => setSharingData(sharing)}
                onGenerateCostEstimationLinkButtonClick={props.onGenerateCostEstimationLinkButtonClick}
                onSendCostEstimationButtonClick={props.onSendCostEstimationButtonClick}
            />
        </RowStack>
    )
}
