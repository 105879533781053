import { Button, Icon, Stack, Typography } from "@tm/components"

export type Filter = {
    id: number
    value: string
    abbreviation?: string
    unit?: string
}

type Props<TFilter> = {
    onRemoveFilter(filter: TFilter): void
    filters: TFilter[]
}

export function FilterButtons<TFilter extends Filter>(props: Props<TFilter>) {
    const { onRemoveFilter, filters } = props

    return (
        <Stack sx={{ m: "0 0 40px 0", flexWrap: "wrap", flexDirection: "row" }}>
            {filters.map((filter) => (
                <Button
                    color="highlight"
                    key={filter.id}
                    sx={{
                        m: "10px",
                        minWidth: "100px",
                        flexDirection: "row",
                        display: "flex",
                    }}
                    style={{ padding: 0 }}
                    onClick={() => onRemoveFilter(filter)}
                >
                    {filter.abbreviation ? (
                        <Stack flexDirection="column">
                            <Typography variant="body2" sx={{ flexDirection: "column", display: "flex", padding: "7px 10px 0", textAlign: "left" }}>
                                {filter.abbreviation}
                            </Typography>
                            <Typography variant="body2" sx={{ flexDirection: "column", display: "flex", padding: "0 10px 7px", textAlign: "left" }}>
                                {filter.value} {filter.unit && ` ${filter.unit}`}
                            </Typography>
                        </Stack>
                    ) : (
                        <Typography
                            variant="body2"
                            sx={{ flexDirection: "column", display: "flex", minWidth: "70px", overflow: "hidden", padding: "14px 10px" }}
                        >
                            {filter.value}
                        </Typography>
                    )}
                    <Icon name="close" sx={{ height: "40px", padding: "5px", width: "40px", borderLeft: "1px solid #666" }} />
                </Button>
            ))}
        </Stack>
    )
}
