import { Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { VoucherType } from "@tm/models"
import { bundleChannel } from "../../../../../bundle-channel"
import { useActionButtonStatus } from "../../../../../data/hooks/useActionButtonStatus"
import { RowStack } from "../../../../_shared/StyledComponents"

export default function CostEstimationActionButtons() {
    const { translateText } = useLocalization()
    const canBeOrdered = useActionButtonStatus(VoucherType.CostEstimation)

    const handleOrderParts = (includeRepairTimes: boolean) => {
        bundleChannel().publish("ADD_COST_ESTIMATION_VOUCHER_TO_BASKET", { includeRepairTimes })
    }

    return (
        <RowStack spacing={0.5}>
            <Button startIcon={<Icon name="pencil" />} disabled={!canBeOrdered} onClick={() => handleOrderParts(true)} title={translateText(917)}>
                {translateText(122)}
            </Button>
            <Button
                color="highlight"
                startIcon={<Icon name="cart" />}
                disabled={!canBeOrdered}
                onClick={() => handleOrderParts(false)}
                title={translateText(937)}
            >
                {translateText(50)}
            </Button>
        </RowStack>
    )
}
