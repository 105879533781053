import { em, percent } from "csx"
import { useEffect, useMemo } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { TmaHelper, bem, classes, getValue } from "@tm/utils"
import { SpecialCarTypes } from "../../../data/enums"
import { Statics } from "../../../data/statics"
import { CarTypeSeasonFilters, CustomDropdown, DiameterFilters } from "../../_shared"
import { MainState } from "../../main"
import { MainActions } from "../../main/business"
import { ISummaryFilters, createSizeQuery } from "../business"
import { EFilterNames, TyreFilter } from "@tm/models"

type Props = {
	className?: string
}

export default function FiltersComponent({ className }: Props) {
	const { translateText } = useLocalization()
	const dispatch = useDispatch()
    const { selectedFilters, filters } = useSelector((s: MainState) => s.summary)
    const style = useMemo(() => getStyle(), [])

	useEffect(() => {
		if (filters.height?.length === 1 && selectedFilters.width && !selectedFilters.height) {
			handleFiltersChange(EFilterNames.height, filters.height.first())
		} else if (filters.inch?.length === 1 && selectedFilters.height && !selectedFilters.inch) {
			handleFiltersChange(EFilterNames.inch, filters.inch.first())
		}
	}, [filters.height, filters.inch])

	const handleFiltersChange = (path: ISummaryFilters, filter: TyreFilter | undefined) => {
        batch(() => {
            dispatch(MainActions.resetSummaryFilter(path))
            if (filter) {
                dispatch(MainActions.updateSummaryFilters(path, filter))
            }
            dispatch(MainActions.loadSummaryFilters())
        })
	}

	const handleNormalSearchBtn = () => {
		const { carType, season } = selectedFilters

		const size = createSizeQuery(selectedFilters)

        const params = { size, season: season?.value, carType: carType?.map(x => x.query) }

        TmaHelper.UniParts.Search.TyresSelection(size)

		batch(() => {
			dispatch(MainActions.updateSearchValue(size))
			dispatch(MainActions.loadTiresList(false, false, true))
			dispatch(MainActions.changeStep("list", params))
			dispatch(MainActions.toggleTiresAccordion(false))
		})
	}

	const handleResetAll = () => {
		batch(() => {
			dispatch(MainActions.resetSummaryFilter("inch"))
			dispatch(MainActions.setTireSizes())
			dispatch(MainActions.loadSummaryFilters())
		})
	}

	const handleResetTireSizes = () => {
		batch(() => {
			dispatch(MainActions.setTireSizes())
			dispatch(MainActions.loadSummaryFilters())
		})
	}

	return (
		<div className={style.relative}>
			<div className={bem(classes(className, style.wrapper), filters.loading && "loading")}>
				<CarTypeSeasonFilters
					filterName={EFilterNames.carType}
					disabled={filters.loading}
					items={Statics.getCarTypes()}
					selectedItemValue={selectedFilters?.carType}
					onChange={handleFiltersChange}
				/>
				<CarTypeSeasonFilters
					filterName={EFilterNames.season}
					disabled={filters.loading || selectedFilters.carType.every(x => SpecialCarTypes.includes(+x.query))}
					items={Statics.seasons}
					selectedItemValue={selectedFilters?.season}
					onChange={handleFiltersChange}
				/>

				<div className={style.mainFilters }>
					<DiameterFilters
						widths={filters.width}
						heights={filters.height}
						inches={filters.inch}
						selectedHeight={selectedFilters.height}
						selectedInch={selectedFilters.inch}
						selectedWidth={selectedFilters.width}
						onChange={handleFiltersChange}
						loading={filters.loading}
						onResetAll={handleResetTireSizes}
					/>

					<div className={style.toolbar} >
						<CustomDropdown
							filterName={EFilterNames.loadIndex}
							submitOnTab
							className={style.dropdown}
							defaultItem={translateText(12403)}
							items={filters.loadIndex}
							onChange={handleFiltersChange}
							selectedValue={selectedFilters.loadIndex}
							withResetButton
							disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
							isActive={false}
						/>
					</div>

					<div className={style.toolbar} >
						<CustomDropdown
							filterName={EFilterNames.speedIndex}
							submitOnTab
							className={style.dropdown}
							defaultItem={translateText(719)}
							items={filters.speedIndex}
							onChange={handleFiltersChange}
							selectedValue={selectedFilters.speedIndex}
							withResetButton
							disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
							isActive={false}
						/>
					</div>

					<div className={style.toolbar} >
						<CustomDropdown
							filterName={EFilterNames.oeIdentifier}
							submitOnTab
							className={style.dropdown}
							defaultItem={translateText(720)}
							items={filters.oeIdentifier}
							onChange={handleFiltersChange}
							selectedValue={selectedFilters.oeIdentifier}
							withResetButton
							disableAutoSelect
							disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}
							isActive={false}
						/>
					</div>
				</div>
				<div className={style.footer}>
					<Button onClick={handleResetAll} icon="synchronize" />
					<Button skin="success" onClick={handleNormalSearchBtn} disabled={!selectedFilters.width || !selectedFilters.inch}>
						{translateText(135)}
					</Button>
				</div>
			</div>

			{filters.loading && <div className={`loader loader-spinner loader-spinner--visible " + ${style.loader}`} />}

		</div>
	)

}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            position: "relative",
            display: "flex",
            flexDirection: "column",
            padding: theme.margin.m,
            $nest:{
                "&--loading": {
                    opacity: theme.opacity.disabled,
                    pointerEvents: "none",
                    $nest: {
                        ".popover": {
                            position: "fixed",
                        },
                    },
                },
            },
        },
        mainFilters: {
            display: "flex",
            flexDirection: "column",
        },
        footer: {
            margin: theme.margin.xl,
            display: "flex",
            justifyContent: "flex-end",
        },
        toolbar: {
            marginBottom: theme.margin.m,
            display: "flex",
            // ".toolbar__title": {
            // 	width: em(8)
            // }
        },
        dropdown: {
            flex: 1,
            // width: em(16.6),
            // border: border({ color: "#d4dadf", style: "solid", width: px(1) }),
            // borderRadius: theme.box.radius,
            $nest: {
                ".btn": {
                    padding: theme.margin.m,
                    lineHeight: 1.3,
                },
                "&--small": {
                    width: em(5.5),
                },
            },
        },
        loader: {
            position: "absolute",
            top: percent(50),
            right: percent(50),
        },
        relative: {
            position: "relative"
        }
    })(FiltersComponent)
}
