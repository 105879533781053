import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function drivemotiveFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const drivemotiveModule = getModuleFromUserContext(userContext, UserModuleType.Drivemotive)

    if (!drivemotiveModule) {
        return undefined
    }

    return {
        drivemotive: {
            items: {
                drivemotive: {
                    sort: 0,
                    name: "Drivemotive",
                    active: true,
                    route: "/:workTaskId/drivemotive",
                },
            },
        },
    }
}
