import { useQuery } from "react-query"
import { ItemPosition } from "../helpers/models"

export function useOtherModules<T>(position: ItemPosition, enabled: boolean, getData: () => T): T {
    const { data } = useQuery([`${position}_otherModules`], () => getData(), {
        enabled,
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: false,
        suspense: true,
        refetchOnWindowFocus: false,
    })

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return data!
}
