import { DateField, LicensePlateField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { useState, useEffect } from "react"

import { Grid, Loader } from "@tm/components"
import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { ConstructionYear, MileType } from "@tm/models"
import { Actions, IActions } from "./business/actions"
import { SimplifiedVehicle, VehicleExtended, VehicleOverviewState } from "./business/model"

type Props = PartialProps & {
    state: VehicleOverviewState
    actions: IActions
}

type PartialProps = {
    readOnly?: boolean
    description?: string
    plateId?: string
    initialRegistration?: Date
    mileAge?: number
    vin?: string
    constructionYearFrom?: ConstructionYear
}

function VehicleOverviewComponent(props: Props) {
    const { translateText } = useLocalization()
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const [vehicleLoading, setVehicleLoading] = useState<boolean>(false)
    const { state, readOnly, description, plateId, initialRegistration, mileAge, vin, actions, constructionYearFrom } = props
    const { plateCode } = useCountryCodeToLicensePlate(workTask?.vehicle?.countryCode)
    useEffect(() => {
        setVehicleLoading(false)
    }, [workTask?.vehicle])

    function fixVehicleMileage(vehicle: VehicleExtended, path: Array<any>) {
        if (path.first() === "mileAge" && vehicle.mileAge) {
            const mileAge = parseInt(`${vehicle.mileAge}`.replace(/[\.,\D]/g, ""))
            vehicle.mileAge = !isNaN(mileAge) ? mileAge : undefined

            if (!vehicle.mileType) {
                vehicle.mileType = MileType.Kilometer
            }
        }
    }

    function handleVehicleChange(vehicle: VehicleExtended, path: Array<any>) {
        fixVehicleMileage(vehicle, path)

        if (!vehicleLoading) {
            setVehicleLoading(true)
            attachToWorkTask?.({
                vehicle: {
                    ...workTask?.vehicle,
                    ...vehicle,
                },
            })
        }
    }

    function handleInitialRegistrationChange(initialRegistration: Date) {
        if (!vehicleLoading && workTask?.vehicle && workTask.vehicle.initialRegistration?.getTime() !== initialRegistration?.getTime()) {
            setVehicleLoading(true)

            attachToWorkTask?.({
                vehicle: {
                    ...workTask?.vehicle,
                    initialRegistration,
                },
            })
        }
    }

    function handleVehicleDescriptionChange(vehicle: VehicleExtended) {
        actions.updateVehicleDescription(vehicle.id, vehicle.description ? vehicle.description : "")
    }

    function renderVehicleOverview() {
        const vehicle = workTask?.vehicle
        // In case the micro is loaded in read only mode
        const model: SimplifiedVehicle = {
            description: readOnly ? description : `${vehicle?.manufacturer || ""} ${vehicle?.modelSeries || ""} ${vehicle?.model || ""}`,
            plateId: readOnly ? plateId : vehicle?.plateId,
            initialRegistration: readOnly ? initialRegistration : vehicle?.initialRegistration,
            mileAge: readOnly ? mileAge : vehicle?.mileAge,
            vin: readOnly ? vin : vehicle?.vin,
        }

        return (
            <Grid container spacing={0.5} columns={9} flex={1} flexWrap="wrap">
                <Grid item sm={4.5}>
                    <TextField
                        maxLength={200}
                        label={translateText(25)}
                        floatingLabel
                        model={model}
                        path={["description"]}
                        layout={["holo"]}
                        disabled={readOnly}
                        onChangeConfirm={handleVehicleDescriptionChange}
                    />
                </Grid>
                <Grid item sm={1}>
                    <LicensePlateField
                        maxLength={15}
                        label={translateText(21)}
                        floatingLabel
                        model={model}
                        path={["plateId"]}
                        layout={["holo"]}
                        disabled={readOnly || vehicleLoading}
                        shortCountryCode={plateCode}
                        onChangeConfirm={handleVehicleChange}
                    />
                </Grid>
                <Grid item sm={1}>
                    <DateField
                        label={translateText(124)}
                        floatingLabel
                        value={model.initialRegistration}
                        maxDate={new Date()}
                        minDate={constructionYearFrom ? new Date(constructionYearFrom.year, constructionYearFrom.month, 1) : undefined}
                        layout={["holo"]}
                        disabled={readOnly || vehicleLoading}
                        useUtc
                        ignoreOnSelect
                        onChange={handleInitialRegistrationChange}
                    />
                </Grid>
                <Grid item sm={1}>
                    <TextField
                        maxLength={9}
                        pattern={/[\d.,]*/}
                        label={translateText(125)}
                        floatingLabel
                        model={model}
                        path={["mileAge"]}
                        layout={["holo"]}
                        disabled={readOnly || vehicleLoading}
                        onChangeConfirm={handleVehicleChange}
                    />
                </Grid>
                <Grid item sm={1.5}>
                    <TextField
                        maxLength={17}
                        label={translateText(101)}
                        floatingLabel
                        model={model}
                        path={["vin"]}
                        layout={["holo"]}
                        disabled={readOnly || vehicleLoading}
                        onChangeConfirm={handleVehicleChange}
                    />
                </Grid>
            </Grid>
        )
    }
    // className must remain so the LicensPlate is not affected
    return state.loading ? <Loader size="small" /> : <div className="tk-crm vehicle-overview">{renderVehicleOverview()}</div>
}

export default connectComponent(Actions, VehicleOverviewComponent)
