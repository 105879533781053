import { useLocalization } from "@tm/localization"
import { CisCustomerResponse } from "@tm/models"
import { Stack, Typography } from "@tm/components"
import DonutChart from "./DonutChart"

type Props = {
    customer: CisCustomerResponse
}

export default function CreditComponent({ customer }: Props) {
    const { translate, currency } = useLocalization()
    const { creditInfo, currencyCode } = customer.account ?? {}
    if (!currencyCode) {
        return null
    }

    // These three values should be rendered in a shared components and used in widget-credit, widget and summary
    const creditLimit = creditInfo ? parseFloat(creditInfo.limit) : 0
    const creditDrawn = creditInfo ? parseFloat(creditInfo.drawn) : 0

    const creditAvailable = creditLimit - creditDrawn
    const creditDrawnPercent = Math.floor((creditDrawn / creditLimit) * 100) || 0

    return (
        <Stack direction="row" justifyContent="space-around">
            <Stack>
                <Typography>{translate(431)}</Typography>
                <DonutChart size={100} strokewidth={16} value={creditDrawnPercent} />
            </Stack>
            <Stack spacing={1}>
                <Stack>
                    <Typography variant="label">{translate(421)?.toString().toUpperCase()}</Typography>
                    <Typography>{Number.isNaN(creditLimit) ? creditInfo && creditInfo.limit : currency(creditLimit, currencyCode)}</Typography>
                </Stack>
                <Stack>
                    <Typography variant="label">{translate(422)?.toString().toUpperCase()}</Typography>
                    <Typography color="primary" fontWeight="bold">
                        {Number.isNaN(creditDrawn) ? creditInfo && creditInfo.drawn : currency(creditDrawn, currencyCode)}
                    </Typography>
                </Stack>
                {!Number.isNaN(creditAvailable) && (
                    <Stack>
                        <Typography variant="label">{translate(423)?.toString().toUpperCase()}</Typography>
                        <Typography>{currency(creditAvailable, currencyCode)}</Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}
