import { IBundle } from "@tm/morpheus"
import { WidgetBundle, Widget } from "./components/widget"
import { WidgetStart, WidgetStartBundle } from "./components/widget-start"
import Navigation from "./components/navigation"
import Transitions from "./components/transitions"
import CustomerList from "./components/customer-list"
import CustomerDetails from "./components/customer-details"
import CustomerVehicles from "./components/customer-vehicles"
import VehicleInfo from "./micros/vehicle-info"
import VehicleOverview from "./micros/vehicle-overview"
import CustomerInfo from "./micros/customer-info"
import CrmWidget from "./micros/crm-widget"
import { initCrmBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [WidgetBundle, WidgetStartBundle, Navigation, Transitions, CustomerList, CustomerDetails, CustomerVehicles],
    micros: [VehicleInfo, VehicleOverview, CustomerInfo, CrmWidget],
    init: initCrmBundle,
}

export default bundle

// export available to be used in other bundles
export {
    //
    Widget,
    WidgetBundle,
    WidgetStart,
    WidgetStartBundle,
}
