import { RepairTimeProviderConfig } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { Suspense } from "react"
import { Loader } from "@tm/components"
import { Actions, IActions, LicensorData, UserSettingsState } from "./business"
import UserSettingsComponent from "./component"

type Params = {
    scrollTo?: string
}

export type Provider = {
    image: string
    url?: string
}

export type WrapperTypes = RouteComponentProps<Params> & {
    state: UserSettingsState
    actions: IActions
    providerImageBaseUrl?: string
    providers?: Array<Provider>
    licensorData?: LicensorData
    subUserManagement?: boolean
    hasDmsSettings?: boolean
    dmsName?: string
    showApiKeyForChat?: boolean
    hasDutchOrderPlates?: boolean
    externalSystemId?: number
    moduleRoute?: string
    demoActivationInstanstly?: boolean
    isWmTelesales?: boolean
    hasDatCredentialSettings?: boolean
    hasErpCredentialSettings?: boolean
}

const UserSettingsWrapper = (props: WrapperTypes) => {
    return (
        <Suspense fallback={<Loader />}>
            <UserSettingsComponent {...props} />
        </Suspense>
    )
}

export default connectComponent(Actions, withRouter(UserSettingsWrapper))
