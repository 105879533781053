import { WorkTaskInfo } from "@tm/context-distribution"
import { RegisteredModels, WholesalerArticleQuantities, WholesalerArticleQuantitiesRequest, WholesalerPart } from "@tm/models"
import { Container } from "@tm/nexus"
import { useState, useEffect } from "react"

export function useWholesalerBasketInfo(part: WholesalerPart, workTask?: WorkTaskInfo) {
    const [loading, setLoading] = useState(false)
    const [basketInfo, setBasketInfo] = useState<WholesalerArticleQuantities>()

    useEffect(() => {
        if (!workTask?.id) {
            return
        }

        setBasketInfo(undefined)

        const request: WholesalerArticleQuantitiesRequest = {
            workTaskId: workTask.id,
            article: {
                wholesalerArticleNumber: part.wholesalerArticleNumber,
            },
        }

        const subscription = Container.getInstance<WholesalerArticleQuantities>(RegisteredModels.Basket_WholesalerBasketInfo).subscribe(request)
        const unregisterLoadingInfo = subscription.addListener("loading", () => {
            setLoading(true)
        })
        const unregisterLoadedInfo = subscription.addListener(
            "loaded",
            (response) => {
                setLoading(false)
                setBasketInfo(response)
            },
            () => {
                setLoading(false)
                setBasketInfo(undefined)
            }
        )

        subscription.load()

        return () => {
            unregisterLoadingInfo()
            unregisterLoadedInfo()
        }
    }, [workTask?.id, part.wholesalerArticleNumber])

    return { basketInfo, basketInfoLoading: loading }
}
