import { Tab, Tabs, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CategoryType } from "@tm/models"
import { SyntheticEvent } from "react"
import { CatalogNavigationProps } from "."

export default function IndustryCatalogSelection(props: CatalogNavigationProps & { categoryType: { [key in CategoryType]: CategoryType } }) {
    const { disabledCategories, availableCategories, directSearchText, universalSearchText, categoryType } = props
    const { vehicleParts, directSearch, universalParts } = categoryType
    const { translate } = useLocalization()
    const theme = useTheme()

    const handleChangeCatageory = (_e: SyntheticEvent, category: CategoryType) => {
        props.onChangeCategory(category)
    }

    return (
        <Tabs
            value={props.activeCategory}
            onChange={handleChangeCatageory}
            TabIndicatorProps={{ style: { backgroundColor: theme.colors?.highlight } }}
        >
            {availableCategories.includes(directSearch) && (
                <Tab value={directSearch} label={directSearchText} disabled={disabledCategories.includes(directSearch)} />
            )}
            {availableCategories.includes(universalParts) && (
                <Tab value={universalParts} label={universalSearchText} disabled={disabledCategories.includes(universalParts)} />
            )}
            {availableCategories.includes(vehicleParts) && (
                <Tab value={vehicleParts} label={translate(449)} disabled={disabledCategories.includes(vehicleParts)} />
            )}
        </Tabs>
    )
}
