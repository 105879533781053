import { em, url } from "csx"
import { Text, Widget, Button } from "@tm/controls"
import { styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { EFilterNames, channel } from "@tm/models"
import { useMultiWidgetState, ModulePropsMultiWidget } from "@tm/utils"
import * as React from "react"
import { Statics } from "../../data/statics"
import { CarTypeSeasonFilters, DiameterFilters, MatchCodeSearchInput } from "../_shared"
import { SpecialCarTypes } from "../../data/enums"
import { useWidgetFilters } from "../../hooks/widgetFilters"

type Props = {
    tyresListTabRoute: string
    vehicleSelectionRoute: string
    searchValue: string
    showComponentKey?: string
    moduleProps?: ModulePropsMultiWidget
}

const TyresSearchWidget: React.FC = () => {

    const { translateText } = useLocalization()

    const {
        state: {
            loading,
            filters,
            selectedFilters,
            selectedFiltersDefaultVehicle
        },
        handlers: {
            handleFilterChange,
            handleResetAll,
            handleNormalSearch
        }
    } = useWidgetFilters()

    function handleMatchCodeSearch(size: string) {
        channel("GLOBAL").publish("TYRES/SEARCH_TYRE_SIZE", { size })
    }

    return (
        <Widget size="6x3" iconName="tire" title={translateText(240)} active={true} className="tyres-search-widget">
            <Content>
                <MatchCodeSearchInput
                    value={""}
                    withTitle
                    onButtonClick={handleMatchCodeSearch}
                />
                <Separator />
                <Text modifiers="sub">{translateText(1562)}</Text>
                <Separator />
                <CarTypeSeasonFilters
                    filterName="carType"
                    disabled={loading}
                    items={Statics.getCarTypes()}
                    selectedItemValue={selectedFiltersDefaultVehicle ?? selectedFilters?.carType}
                    onChange={handleFilterChange}
                />
                <Separator />
                <CarTypeSeasonFilters
                    filterName={EFilterNames.season}
                    disabled={loading || selectedFilters.carType.every(x => SpecialCarTypes.includes(+x.value))}
                    items={Statics.seasons}
                    selectedItemValue={selectedFilters.season}
                    onChange={handleFilterChange}
                />
                <Separator />
                <MainFilters loading={loading}>
                    <DiameterFilters
                        title={translateText(739)}
                        selectedHeight={selectedFilters.height}
                        selectedWidth={selectedFilters.width}
                        selectedInch={selectedFilters.inch}
                        widths={filters.width}
                        heights={filters.height}
                        inches={filters.inch}
                        onChange={handleFilterChange}
                        onResetAll={handleResetAll}
                        loading={loading}
                        maxItemsToShow={6}
                    />
                    <Separator />
                    <SearchButton
                        skin="highlight"
                        icon="search"
                        onClick={handleNormalSearch}
                        disabled={!selectedFilters.width || !selectedFilters.height || !selectedFilters.inch}>
                        {translateText(135)}
                    </SearchButton>
                </MainFilters>
            </Content>
        </Widget>
    )

}

const Content = styled("div")({
    paddingLeft: em(12),
    backgroundImage: url("/styles/base/images/bitmap-copy.png"),
    backgroundPositionY: em(2),
    backgroundRepeat: "no-repeat",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
})

const MainFilters = styled("div")<{ loading?: boolean }>(({ theme, loading }) => ({
    display: "flex",
    flexDirection: "column",
    ...loading && {
        position: "relative",
        opacity: theme.opacity?.disabled,
        pointerEvents: "none",
        ".popover": {
            position: "fixed"
        }
    }             
}))

const Separator = styled("hr")({
    border: 0,
    height: 0,
    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
    borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
    marginLeft: 0,
    marginRight: 0,
})

const SearchButton = styled(Button)({
    margin: "0 0 0 auto",
    display: "flex",
    justifyContent: "flex-end"
})

const TyresSearchWidgetWrapper: React.FC<Props> = ({ showComponentKey, moduleProps }) => {

    const [selectedMultiWidgetTab] = useMultiWidgetState(moduleProps)

    if (!showComponentKey || selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    return <TyresSearchWidget />
}

export default TyresSearchWidgetWrapper
