import { withRouter, RouteComponentProps, decodeUniqueId } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { ExternalModule, channel } from "@tm/models"
import IframeComponent from "./components/IframeComponent"

type ComponentProps = RouteComponentProps<{ workTaskId?: string }> & ConfigProps & {}

type ConfigProps = {
    display: "START" | "DASHBOARD" | "BASKET"
    className?: string
    itemClassName: string
    showCloseButton?: boolean
    hideWrapper?: boolean
    requiresExternalToken?: boolean
}

function ExternalCatalogsIframeComponent(props: ComponentProps) {
    const { userContext } = useUser() ?? {}
    const { match, history, itemClassName, display, className, showCloseButton, hideWrapper, requiresExternalToken } = props

    function checkDisplayBehavior(displayBehavior: string): boolean {
        const DISPLAY_BEHAVIORS = {
            START: "514",
            DASHBOARD: "515",
            START_AND_DASHBOARD: "516",
            BASKET: "519",
        }

        switch (display) {
            case "DASHBOARD":
                return displayBehavior === DISPLAY_BEHAVIORS.DASHBOARD || displayBehavior === DISPLAY_BEHAVIORS.START_AND_DASHBOARD

            case "START":
                return displayBehavior === DISPLAY_BEHAVIORS.START || displayBehavior === DISPLAY_BEHAVIORS.START_AND_DASHBOARD

            case "BASKET":
                return displayBehavior === DISPLAY_BEHAVIORS.BASKET

            default:
                return false
        }
    }

    function renderItem(item: ExternalModule) {
        const className = itemClassName ? ` ${itemClassName}` : ""
        return <IframeComponent item={item} className={className} requiresExternalToken={requiresExternalToken} />
    }

    function getClassName() {
        return `bundle-misc external-catalogs--iframe${className ? ` ${className}` : ""}`
    }

    function handleClose() {
        const {
            history: his,
            current: { search },
        } = window.portalLocations
        const {
            params: { workTaskId },
            url,
        } = match
        const fullUrl = `${url}${search}`
        workTaskId && channel("WORKTASK", decodeUniqueId(workTaskId)).publish("MODULE/CLOSED", fullUrl)

        history.push(his.length ? his[0].pathname + his[0].search || "" : "/")
    }

    if (!userContext || !userContext.externalModules || (requiresExternalToken && !userContext?.externalAuthenticationTokens)) {
        return null
    }

    const processDisplayBehavior = (y: any) => y.key === "DisplayBehavior" && checkDisplayBehavior(y.value)
    const processIframeCheck = (y: { key: string; value: any }) => y.key.toLowerCase() === "target" && y.value === "3"

    const catalogs = userContext.externalModules
        .filter((x) => x.parameter && x.parameter.some(processDisplayBehavior) && x.parameter.some(processIframeCheck))
        .orderBy((x) => {
            const item = x.parameter.find((y: any) => y.key === "SortNumber")
            return (item && item.value) || 0
        })

    function content() {
        return (
            <>
                {showCloseButton && <Button icon="close" className="close" onClick={handleClose} />}
                {catalogs.map(renderItem)}
            </>
        )
    }

    return !hideWrapper ? <div className={getClassName()}>{content()}</div> : content()
}

export default withRouter(ExternalCatalogsIframeComponent)
