import { BikeManufacturer } from "@tm/models"
import { callBikesService } from "."

export type ManufacturersResponse = {
    topManufacturers: Array<BikeManufacturer>
    primaryManufacturers: Array<BikeManufacturer>
    secondaryManufacturers: Array<BikeManufacturer>
}

type ShowTopManufacturersResponse = {
    topManufacturers: Array<BikeManufacturer>
}

export function showTopManufacturers() {
    return callBikesService<{}, ShowTopManufacturersResponse>("ShowTopManufacturers", {})
}

export function showAllManufacturers() {
    return callBikesService<{}, ManufacturersResponse>("ShowAllManufacturers", {})
}

type ShowManufacturersByQueryRequest = {
    query: string
}

export function showManufacturersByQuery(request: ShowManufacturersByQueryRequest) {
    return callBikesService<ShowManufacturersByQueryRequest, ManufacturersResponse>("ShowManufacturersByQuery", request)
}

type ShowManufacturersByArticleRequest = {
    articleId: number
}

export function showManufacturersByArticle(request: ShowManufacturersByArticleRequest) {
    return callBikesService<ShowManufacturersByArticleRequest, ManufacturersResponse>("ShowManufacturersByArticle", request)
}

type ShowManufacturersByEngineCodeRequest = {
    engineCode: string
}

export function showManufacturersByEngineCode(request: ShowManufacturersByEngineCodeRequest) {
    return callBikesService<ShowManufacturersByEngineCodeRequest, ManufacturersResponse>("ShowManufacturersByEngineCode", request)
}

type ShowManufacturersByPartsListArticleRequest = {
    partsListArticleId: number
}

export function showManufacturersByPartsListArticle(request: ShowManufacturersByPartsListArticleRequest) {
    return callBikesService<ShowManufacturersByPartsListArticleRequest, ManufacturersResponse>("ShowManufacturersByPartsListArticle", request)
}

type ShowManufacturerDetailsRequest = {
    manufacturerId: number
}

type ShowManufacturerDetailsResponse = {
    manufacturerDetails: BikeManufacturer
}

export function showManufacturerDetails(request: ShowManufacturerDetailsRequest) {
    return callBikesService<ShowManufacturerDetailsRequest, ShowManufacturerDetailsResponse>("ShowManufacturerDetails", request)
}
