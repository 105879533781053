import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Job } from "../../../models"
import JobStatus from "../../shared/JobStatus"
import PrintButton from "./PrintButton"
import { formatDate, getDaysUntilExpire } from "../../../data/helpers"
import JobDetails from "./JobDetails"

type Props = {
    job: Job
    expanded: boolean
    isEvenRow: boolean
    onRowClick: (jobId: string) => void
}

export default function JobListItem({ job, expanded, isEvenRow, onRowClick }: Props) {
    const { translateText } = useLocalization()

    return (
        <StyledAccordion
            key={job.uid}
            expanded={expanded}
            onChange={() => {
                onRowClick(job.uid)
            }}
            elevation={0}
            disableGutters
            TransitionProps={{ unmountOnExit: true }}
        >
            <StyledAccordionSummary isEven={isEvenRow} expanded={expanded}>
                <StyledListRow>
                    <Typography variant="body2">{formatDate(job.created)}</Typography>
                    <Typography variant="body2">{job.mileage}</Typography>
                    <Typography variant="body2" maxLines={1} maxWidth="200px">
                        {job.faultCodeItemCodes?.join(", ")}
                    </Typography>
                    <JobStatus text={job.status || ""} />
                    <Typography variant="body2">{translateText(13533).replace("{0}", getDaysUntilExpire(job.expiry))}</Typography>
                    <PrintButton disabled={!job.hasPdfReport} jobId={job.uid} />
                </StyledListRow>
            </StyledAccordionSummary>
            <AccordionDetails>
                <JobDetails jobId={job.uid} />
            </AccordionDetails>
        </StyledAccordion>
    )
}

const StyledListRow = styled(Box)({
    width: "100%",
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "minMax(200px, 1fr) minMax(200px, 1fr) minMax(200px, 1fr) minMax(200px, 1fr) minMax(200px, 1fr) minMax(100px, 0.5fr)",
})

const StyledAccordion = styled(Accordion)(({ expanded, theme }) => ({
    "&:before": {
        display: "none",
    },
    "&:hover": {
        border: `1px solid ${theme.palette.primary.light}`,
    },
    ...(expanded && {
        border: `1px solid ${theme.palette.primary.light}`,
    }),
}))

const StyledAccordionSummary = styled(AccordionSummary, {
    shouldForwardProp: (prop) => prop !== "isEven" && prop !== "expanded",
})<{ isEven?: boolean; expanded?: boolean }>(({ isEven, expanded, theme }) => ({
    minHeight: "38px",
    height: "36px",
    backgroundColor: "#f6f6f6",
    ...(isEven && {
        backgroundColor: "#fafafa",
    }),
    "& .MuiAccordionSummary-content": {
        margin: "0 !important",
    },
    "& .MuiAccordionSummary-expandIconWrapper": {
        width: "47px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    borderLeft: `3px solid #e2e2e2`,
    "&:hover": {
        borderColor: `${theme.palette.primary.main}`,
    },
    ...(expanded && {
        borderColor: `${theme.palette.primary.main}`,
    }),
}))
