import { useState, useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { formatDate, Dictionary, withRouter, RouteComponentProps } from "@tm/utils"
import { RealTable, TextField, Switch, Loader, Button, Headline } from "@tm/controls"

import CompilationActions from "./actions"
import CompilationCreator from "./creator"
import { useUpdateCompilation } from "../../../../../data/hooks/useCompilations"
import { Compilation } from "../../../../../data"
import { isWM } from "../../../../../utils"
import { CompilationDeleteNavigation } from "../../navigation/delete/delete"

type RouteProps = {
    compilationId?: string
}

type Props = {
    compilations: Array<Compilation>
    selectedCompilationIds: Array<string>
    compact: boolean
    showCreator: boolean
    onOpenCompilationDetails(id: string): void
    onSelectCompilation(id: string): void
    openCompilationList(): void
    onShowCreator(): void
} & RouteComponentProps<RouteProps>

const CompilationsList = (props: Props) => {
    const { translateText } = useLocalization()
    const { updateCompilation } = useUpdateCompilation()

    const [editingCompilations, setEditingCompilations] = useState<Dictionary<Compilation>>({})
    const [updatingCompilations, setUpdatingCompilations] = useState<Dictionary<boolean>>({})

    const onRowDoubleClick = (compilation: Compilation) => {
        props.onOpenCompilationDetails(compilation.id)
    }

    const handleShowHideEditMode = (compilation: Compilation) => {
        if (!editingCompilations[compilation.id]) {
            setEditingCompilations({
                ...editingCompilations,
                [compilation.id]: compilation,
            })
        } else {
            const temp = { ...editingCompilations }
            delete temp[compilation.id]
            setEditingCompilations(temp)
        }
    }

    const handleUpdateCompilation = (compilation: Compilation) => {
        const editedCompilation = editingCompilations[compilation.id]
        if (editedCompilation) {
            setUpdatingCompilations((prev) => ({ ...prev, [compilation.id]: true }))
            updateCompilation({
                compilationId: editedCompilation.id,
                name: editedCompilation.name,
                description: editedCompilation.description,
                isPublic: editedCompilation.isPublic,
            }).then(
                () => {
                    setUpdatingCompilations((prev) => ({ ...prev, [compilation.id]: false }))
                    handleShowHideEditMode(compilation)
                },
                () => {
                    setUpdatingCompilations((prev) => ({ ...prev, [compilation.id]: false }))
                }
            )
        }
    }

    const handleNameChange = (name: string | null, compilation: Compilation) => {
        setEditingCompilations({
            ...editingCompilations,
            [compilation.id]: {
                ...compilation,
                name: name || "",
            },
        })
    }

    const handleDescriptionChange = (description: string | null, compilation: Compilation) => {
        setEditingCompilations({
            ...editingCompilations,
            [compilation.id]: {
                ...compilation,
                description: description || "",
            },
        })
    }

    const handlePrivacyChange = (compilation: Compilation) => {
        setEditingCompilations({
            ...editingCompilations,
            [compilation.id]: {
                ...compilation,
                isPublic: !compilation.isPublic,
            },
        })
    }

    const renderNameCell = (compilation: Compilation) => {
        if (updatingCompilations[compilation.id]) {
            return (
                <RealTable.Cell>
                    <Loader />
                </RealTable.Cell>
            )
        }

        if (editingCompilations[compilation.id]) {
            return (
                <RealTable.Cell>
                    <TextField
                        maxLength={50}
                        label={translateText(155)}
                        floatingLabel
                        value={compilation.name}
                        layout={["holo"]}
                        onChange={(value) => {
                            handleNameChange(value, editingCompilations[compilation.id])
                        }}
                        showLength
                    />
                </RealTable.Cell>
            )
        }

        return <RealTable.Cell>{compilation.name}</RealTable.Cell>
    }

    const renderDescriptionCell = (compilation: Compilation) => {
        if (updatingCompilations[compilation.id]) {
            return (
                <RealTable.Cell>
                    <Loader />
                </RealTable.Cell>
            )
        }

        if (editingCompilations[compilation.id]) {
            return (
                <RealTable.Cell>
                    <TextField
                        maxLength={200}
                        label={translateText(617)}
                        floatingLabel
                        value={compilation.description}
                        layout={["holo"]}
                        onChange={(value) => {
                            handleDescriptionChange(value, editingCompilations[compilation.id])
                        }}
                        showLength
                    />
                </RealTable.Cell>
            )
        }

        return <RealTable.Cell>{compilation.description}</RealTable.Cell>
    }

    const renderPrivacyCell = (compilation: Compilation) => {
        if (updatingCompilations[compilation.id]) {
            return (
                <RealTable.Cell>
                    <Loader />
                </RealTable.Cell>
            )
        }

        if (editingCompilations[compilation.id]) {
            return (
                <RealTable.Cell>
                    <Switch
                        label={translateText(1254)}
                        status={compilation.isPublic}
                        alignLabel="left"
                        onChange={() => {
                            handlePrivacyChange(editingCompilations[compilation.id])
                        }}
                    />
                </RealTable.Cell>
            )
        }
        return <RealTable.Cell>{translateText(compilation.isPublic ? 1254 : 1253)}</RealTable.Cell>
    }

    const renderCreationDateCell = (compilation: Compilation) => {
        if (editingCompilations[compilation.id] || !compilation.createDate) {
            return <RealTable.Cell />
        }

        return <RealTable.Cell>{formatDate(new Date(compilation.createDate), "d")}</RealTable.Cell>
    }

    const renderModificationCell = (compilation: Compilation) => {
        if (editingCompilations[compilation.id] || !compilation.updateDate) {
            return <RealTable.Cell />
        }

        if (updatingCompilations[compilation.id]) {
            return (
                <RealTable.Cell>
                    <Loader />
                </RealTable.Cell>
            )
        }

        return <RealTable.Cell>{formatDate(new Date(compilation.updateDate), "d")}</RealTable.Cell>
    }

    const renderActionsCell = (compilation: Compilation) => {
        return (
            <RealTable.Cell>
                <CompilationActions
                    compilation={compilation}
                    isEditing={!!editingCompilations[compilation.id]}
                    isSelected={props.selectedCompilationIds?.includes(compilation.id)}
                    onShowHideEditMode={handleShowHideEditMode}
                    onUpdateCompilation={handleUpdateCompilation}
                    onOpenCompilationDetails={props.onOpenCompilationDetails}
                    onSelectCompilation={props.onSelectCompilation}
                />
            </RealTable.Cell>
        )
    }

    const renderCompactActionsCell = (compilation: Compilation) => {
        return (
            <RealTable.Cell>
                <CompilationDeleteNavigation
                    compilation={compilation}
                    openCompilations={() => {
                        /* do nothing */
                    }}
                />
                <Button size="s" icon="details" onClick={() => onRowDoubleClick(compilation)} />
            </RealTable.Cell>
        )
    }

    const handleClassRows = useCallback(
        (data: Compilation, index: number): string => {
            if (data.id === props.match.params.compilationId) {
                return "is-selected"
            }

            return ""
        },
        [props]
    )

    const handleOnClickRow = (compilation: Compilation) => {
        if (!isWM() && props.compact) {
            onRowDoubleClick(compilation)
        }
    }

    const columns = props.compact
        ? [
              <RealTable.Column className="compilation-name" renderItemContent={renderNameCell}>
                  {translateText(155).toUpperCase()}
              </RealTable.Column>,
              <RealTable.Column className="compilation-modification-date hide-mobile" renderItemContent={renderModificationCell}>
                  {translateText(1252).toUpperCase()}
              </RealTable.Column>,
              <RealTable.Column className="actions" renderItemContent={renderCompactActionsCell} />,
          ]
        : [
              <RealTable.Column className="compilation-name" renderItemContent={renderNameCell}>
                  {translateText(155).toUpperCase()}
              </RealTable.Column>,
              <RealTable.Column className="compilation-description" renderItemContent={renderDescriptionCell}>
                  {translateText(617).toUpperCase()}
              </RealTable.Column>,
              isWM() && (
                  <RealTable.Column className="compilation-privacy" renderItemContent={renderPrivacyCell}>
                      {translateText(1243).toUpperCase()}
                  </RealTable.Column>
              ),
              <RealTable.Column className="compilation-creation-date" renderItemContent={renderCreationDateCell}>
                  {translateText(258).toUpperCase()}
              </RealTable.Column>,
              <RealTable.Column className="compilation-modification-date" renderItemContent={renderModificationCell}>
                  {translateText(1252).toUpperCase()}
              </RealTable.Column>,
              <RealTable.Column className="actions" renderItemContent={renderActionsCell} />,
          ]

    return (
        <>
            {!isWM() && props.compact && (
                <div className="compilation__toolbar compilation__toolbar--flex">
                    <div className="compilation__toolbar compilation__toolbar--flex">
                        <Headline className="compilation__toolbar compilation__toolbar--paddingRight" size="xs">
                            {translateText(12791)}
                        </Headline>
                    </div>
                    <Button
                        className="compilation__toolbar compilation__toolbar--smallHeightIcon"
                        onClick={props.openCompilationList}
                        icon="arrow-right"
                        size="m"
                    />
                </div>
            )}
            {props.showCreator && props.compact && <CompilationCreator onClose={props.onShowCreator} short />}
            <RealTable
                data={props.compilations}
                columns={columns}
                onClickRow={handleOnClickRow}
                onDoubleClickRow={onRowDoubleClick}
                getRowClassName={handleClassRows}
            />
        </>
    )
}

export default withRouter(CompilationsList)
