import { ShowConfigResponse, Vehicle, VehicleType } from "@tm/models"

import { FC, useState } from "react"
import { showGsiVehicle } from "../../data/repositories/gsi"
import { DetailsProps } from "./component"
import { DetailsByVehicle } from "./DetailsByVehicle"

type Props = DetailsProps & {
    vehicleType: VehicleType
    vehicleId: string
    workTaskId?: string
    availableVehicleSearches?: ShowConfigResponse
}

export const DetailsByVehicleId: FC<Props> = (props) => {
    const [vehicle, setVehicle] = useState<Vehicle | undefined>(undefined)

    React.useEffect(() => {
        let isMounted = true

        showGsiVehicle(props.vehicleId).then((data) => {
            if (isMounted) {
                setVehicle(data)
            }
        })

        return () => {
            isMounted = false
        }
    }, [props.vehicleId])

    return <DetailsByVehicle {...props} vehicle={vehicle} />
}
