import { useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { WarningPrompt } from "@tm/controls"
import { useUser } from "@tm/context-distribution"
import { ConfigParams, PriceType } from "@tm/models"
import { TableCellData, Tooltip, Icon, TableVariants, CellContentPosition, Box, Loader, Button, TableColumnData } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { useDeleteBackorder } from "../../../data/hooks/useBackorders"
import { BackorderListItem } from "../../../data/model"
import { NoResultHint } from "../NoResultHint"
import { StyledTable } from "../StyledComponents"
import PriceCell from "../cells/PriceCell"
import DateCell from "../cells/DateCell"

type Props = {
    backorders: Array<BackorderListItem> | undefined
    backordersLoading: boolean
    variant: TableVariants
    onLoadNextPage?(): void
}
export default function BackordersTableComponent({ variant, backorders, backordersLoading, onLoadNextPage }: Props) {
    const { translateText, translate, currency } = useLocalization()
    const { userSettings } = useUser()
    const { deleteBackorder, isDeleting } = useDeleteBackorder()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()

    const [backorderToBeRemoved, setBackorderToBeRemoved] = useState<BackorderListItem | undefined>(undefined)

    const dialogRef = useRef<WarningPrompt>(null)

    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    function handleDeleteClick(backorder: BackorderListItem) {
        setBackorderToBeRemoved(backorder)
        dialogRef.current?.show()
    }

    function handleWarningClose() {
        setBackorderToBeRemoved(undefined)
    }

    function handleWarningConfirm() {
        if (backorderToBeRemoved) {
            deleteBackorder({ backorder: backorderToBeRemoved }).then(() => setBackorderToBeRemoved(undefined))
        }
    }

    function renderError(backorder: BackorderListItem) {
        if (backorder.errorCode) {
            return (
                <Tooltip title={translateText(13186).replace("{0}", backorder.errorCode)}>
                    <Icon name="error-filled" />
                </Tooltip>
            )
        }
        return <></>
    }

    function renderDeleteButton(backorder: BackorderListItem) {
        const disabled = isDeleting && backorder === backorderToBeRemoved
        return (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    onClick={() => handleDeleteClick(backorder)}
                    title={translateText(69)}
                    disabled={disabled}
                    startIcon={<Icon name="delete" />}
                    color={deleteButtonColorError ? "error" : undefined}
                />
            </Box>
        )
    }

    function geModuleColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(87) },
            { header: translateText(71) },
            { header: translateText(377) },
            { header: translateText(700) },
            { header: translateText(776) },
            { header: translateText(89), alignContent: CellContentPosition.center },
        ]
        if (!hidePurchasePrice) {
            columns.push(
                { header: translateText(55), alignContent: CellContentPosition.right },
                { header: `${translate(1197)}*`, alignContent: CellContentPosition.right }
            )
        }
        columns.push({ header: `${translate(1198)}*`, alignContent: CellContentPosition.right }, { alignContent: CellContentPosition.right })
        return columns
    }

    function getWidgetColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(13177) },
            { header: translateText(13203), minWidth: 50 },
            { header: translateText(776) },
            { header: translateText(89), minWidth: 50, alignContent: CellContentPosition.center },
            { header: `${translate(1197)}*`, alignContent: CellContentPosition.right },
        ]

        if (!hidePurchasePrice) {
            columns.push({ header: `${translate(1198)}*`, alignContent: CellContentPosition.right })
        }
        columns.push({ alignContent: CellContentPosition.right })
        return columns
    }

    function getColumns() {
        if (variant === "small") {
            return getWidgetColumns()
        }
        if (variant === "normal") {
            return geModuleColumns()
        }

        return []
    }

    function getModuleCellData(backorder: BackorderListItem) {
        let cellData: TableCellData[] = [
            { displayValue: backorder.supplierArticleNumber, id: "backorder_1" },
            { displayValue: backorder.supplierName, id: "backorder_2" },
            { displayValue: backorder.articleDescription, id: "backorder_3" },
            { displayValue: backorder.orderNumber, id: "backorder_4" },
            { displayValue: <DateCell value={backorder.backorderDate} mode="dateSlashTime" />, id: "backorder_5" },
            { displayValue: backorder.quantity, id: "backorder_6" },
        ]

        if (!hidePurchasePrice) {
            cellData.push(
                { displayValue: <PriceCell priceType={PriceType.Purchase} prices={backorder.prices} />, id: "backorder_7" },
                { displayValue: currency(backorder.purchaseTotalPrice || 0, backorder.currencyCode), id: "backorder_8" }
            )
        }

        cellData.push(
            { displayValue: currency(backorder.retailTotalPrice || 0, backorder.currencyCode), id: "backorder_9" },
            { displayValue: renderDeleteButton(backorder), id: "backorder_10" }
        )

        if (backorder.errorCode) {
            cellData = [{ displayValue: renderError(backorder), id: "backorder_0" }]
        }
        return cellData
    }

    function getWidgetCellData(backorder: BackorderListItem) {
        let cellData: TableCellData[] = [
            { displayValue: backorder.supplierArticleNumber, id: "backorder_1" },
            { displayValue: backorder.articleDescription, id: "backorder_3" },
            { displayValue: <DateCell value={backorder.backorderDate} mode="dateAboveTime" alignCenter />, id: "backorder_5" },
            { displayValue: backorder.quantity, id: "backorder_6" },
        ]

        if (!hidePurchasePrice) {
            cellData.push({
                displayValue: currency(backorder.purchaseTotalPrice || 0, backorder.currencyCode),
                id: "backorder_8",
            })
        }

        cellData.push(
            { displayValue: currency(backorder.retailTotalPrice || 0, backorder.currencyCode), id: "backorder_9" },
            { displayValue: renderDeleteButton(backorder), id: "backorder_10" }
        )

        if (backorder.errorCode) {
            cellData = [{ displayValue: renderError(backorder), id: "backorder_0" }]
        }
        return cellData
    }

    const displayData = backorders?.map((backorder, index) => ({
        cells: variant === "small" ? getWidgetCellData(backorder) : getModuleCellData(backorder),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (backordersLoading) {
        return <Loader />
    }
    if (!displayData?.length) {
        return <NoResultHint />
    }

    return (
        <>
            <StyledTable
                columns={getColumns()}
                rows={displayData}
                variant={variant}
                headerStyle={variant === "small" ? "bold" : "default"}
                onScrollBottom={onLoadNextPage}
                headerBackground={variant === "small" ? "default" : "paper"}
            />
            <WarningPrompt
                cancelButtonText={translateText(584)}
                confirmationButtonText={translateText(585)}
                doNotCloseOnConfirm
                ref={dialogRef}
                text={translateText(13215)}
                onClose={handleWarningClose}
                onConfirm={handleWarningConfirm}
            />
        </>
    )
}
