import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { LinkButton, Stack } from "@tm/components"
import PartnerSearch from "../shared/partner-search"

type Props = {
    detailsRoute: string
    height?: number
}

export default function PartnerSearchWidget(props: Props) {
    const { translate } = useLocalization()

    return (
        <Widget id="telesales__partner-search-widget" height={props.height} size="4x2" iconName="user" title={translate(839)} active>
            <Stack justifyContent="space-between" flex={1}>
                <PartnerSearch hideTitle />
                <LinkButton to={props.detailsRoute}>{translate(336)}</LinkButton>
            </Stack>
        </Widget>
    )
}
