import { List, Loader, Scrollbar, Text } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import * as React from 'react'
import { Restriction } from '../../../../data/model'

type Props = {
    loading?: boolean
    error?: boolean
    data: Restriction[]
}

const Restrictions: React.FC<Props> = ({ loading, error, data }) => {
    const { translateText } = useLocalization()
    const renderItem = (item: Restriction) => (
        <>
            <Text modifiers="strong">{item.restrictionId}</Text>
            <Text>{item.description}</Text>
        </>
    )

    if (loading)
        return <Loader />

    if (!data.length || error)
        return (
            <div className="article-list__panel article-list__status">
                <Text>{translateText(323)}</Text>
            </div>
        )

    return (
        <div className="restrictions article-details">
            <Scrollbar>
                <List className="key-value-list" view={renderItem} items={data} />
            </Scrollbar>
        </div>
    )
}

export default Restrictions