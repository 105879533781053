import { useLocalization } from "@tm/localization"
import { Warehouse, WarehouseDispatchType } from "@tm/models"
import SimpleTourTable, { SimpleTourTableData } from "../../../../_shared/SimpleTourTable"
import WarehouseQuantitiesTable from "./WarehouseQuantitiesTable"

type Props = {
    warehouses?: Array<Warehouse>
    warehouseDispatchType?: WarehouseDispatchType // Display only this dispacht type
    currentWarehouseId?: string
}

export default function Warehouses(props: Props) {
    const { translateText } = useLocalization()
    const { warehouses, warehouseDispatchType, currentWarehouseId } = props

    if (warehouses?.length === 1 && !warehouses[0].showWarehouseInfo) {
        const data: Array<SimpleTourTableData> = []

        if (
            (!warehouseDispatchType || warehouseDispatchType === WarehouseDispatchType.Delivery) &&
            warehouses[0].standardDelivery?.length &&
            warehouses[0].standardDelivery[0].tours
        ) {
            data.push({ title: translateText(1146), tours: warehouses[0].standardDelivery[0].tours })
        }

        if (
            (!warehouseDispatchType || warehouseDispatchType === WarehouseDispatchType.Collection) &&
            warehouses[0].collection?.length &&
            warehouses[0].collection[0].tours
        ) {
            data.push({ title: translateText(1147), tours: warehouses[0].collection[0].tours })
        }

        if (
            (!warehouseDispatchType || warehouseDispatchType === WarehouseDispatchType.NightExpressDelivery) &&
            warehouses[0].nightExpressDelivery?.length &&
            warehouses[0].nightExpressDelivery[0].tours
        ) {
            data.push({ title: translateText(13441), tours: warehouses[0].nightExpressDelivery[0].tours })
        }

        if (data.length) {
            return <SimpleTourTable data={data} transparentBackground />
        }
    }

    const currentWarehouses = warehouses?.filter(
        (warehouse) =>
            warehouse.showWarehouseInfo &&
            (warehouse.id === currentWarehouseId || warehouse.id === "LZ") &&
            warehouse.quantities?.every((quantity) => !!quantity.value)
    )

    if (currentWarehouses?.length) {
        return <WarehouseQuantitiesTable warehouses={currentWarehouses} transparentBackground />
    }

    return null
}
