import { Typography, Box, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useFilterStore } from "../../business/state"
import { useBackorders } from "../../data/hooks/useBackorders"
import BackordersTable from "../_shared/tables/BackordersTable"

export default function BackordersComponent() {
    const { translate } = useLocalization()
    const [filters] = useFilterStore((store) => [store.filters])
    // Backorders don't support any filters at the moment
    const { backorders, backordersLoading, backordersLoadNextPage, backordersNextPageLoading } = useBackorders(false, filters)

    function loadNextBackorderPage() {
        backordersLoadNextPage && backordersLoadNextPage()
    }

    return (
        <Box display="flex" flexDirection="column" width="100%" alignItems="flex-end">
            <Typography variant="label">{`*${translate(694)}`}</Typography>
            <Box display="flex" flexDirection="column" width="100%" alignSelf="center" height="calc(100vh - 16em)">
                <BackordersTable
                    variant="normal"
                    backorders={backorders!}
                    backordersLoading={backordersLoading}
                    onLoadNextPage={loadNextBackorderPage}
                />
                {backordersNextPageLoading && <Loader />}
            </Box>
        </Box>
    )
}
