import { useState, useEffect } from "react"
import { Box, Icon, LinkButton, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { channel, getCurrentWorkTaskId } from "@tm/models"
import { encodeUniqueId, renderRoute, TmaHelper, uniqueId } from "@tm/utils"
import { useHistory, useParams } from "react-router"
import { getBundleParams } from "../../../utils"
import { IActions } from "../../direct-search-widget/business"
import DirectSearchField from "../direct-search-field"

type Props = {
    directSearchRoute: string
    queryHistory: Array<string>
    showHints?: boolean
    actions?: IActions
}

export default function DirectSearchWidgetContent({ directSearchRoute, queryHistory, showHints, actions }: Props) {
    const { translateText, translate } = useLocalization()
    const matchParams = useParams<{ workTaskId?: string }>()
    const history = useHistory()
    const [currentShortcutKey, setCurrentShortcutKey] = useState("")

    // showQueryHistory for landingpage view of History. Set in Parts BundleParams in the UI-Config
    const { showQueryHistory, minimumSearchLength } = getBundleParams()

    useEffect(() => {
        actions?.loadQueryHistory()
        return channel("APP").subscribe("SHORTCUTS", (rez) => {
            if (rez.key === "DIRECT") {
                setCurrentShortcutKey(rez.key + Math.random())
            }
        })
    }, [])

    let params = matchParams
    if (!params.workTaskId) {
        params = { ...params, workTaskId: encodeUniqueId(uniqueId()) }
    }

    function handleDirectSearch(query: string) {
        const url = renderRoute(directSearchRoute, params)
        TmaHelper.Shared.ByArticleAndUniParts.SetSearchContext("directSearch", query, params.workTaskId || getCurrentWorkTaskId(), true)
        history.push(`${url}?query=${encodeURIComponent(query)}`)
    }

    const baseUrl = renderRoute(directSearchRoute, params)

    return (
        <>
            <DirectSearchField
                key={currentShortcutKey}
                placeholder={translateText(138)}
                minLengthForSearch={minimumSearchLength.directSearch}
                onSearch={handleDirectSearch}
                activeCategory="directSearch"
                hint={showHints ? translateText(1046) : undefined}
                searchfilterResultCounts={[]}
                autofocus
                autofocusTimeout={100}
            />

            {showHints && (
                <Stack direction="row" spacing={0.5} mt={1}>
                    <Icon name="search" width="20px" height="20px" />
                    <Typography variant="body2">{translateText(1046)}</Typography>
                </Stack>
            )}

            <div className="wrapper" />

            {showQueryHistory && !!queryHistory.length && (
                <Stack spacing={0.5} mt={1}>
                    <Typography variant="body3">{translate(1073)}</Typography>
                    <Stack direction="row" flexWrap="wrap" maxWidth="100%">
                        {queryHistory.map((query) => {
                            const url = `${baseUrl}?query=${query}`
                            return (
                                <LinkButton to={url} key={query} sx={{ mt: 0.5, mr: 0.5 }}>
                                    {query}
                                </LinkButton>
                            )
                        })}
                    </Stack>
                </Stack>
            )}
        </>
    )
}
