import { useLocalization } from "@tm/localization"
import { Widget } from "@tm/controls"
import { useCisCustomer } from "@tm/context-distribution"
import { Box, Divider, Loader, Stack } from "@tm/components"
import WarehouseInfoComponent from "../_shared/customer/WarehouseInfo"
import WarehouseOpeningTimesComponent from "../_shared/customer/WarehouseOpeningTimes"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"
import { RowStack, VerticalDivider } from "../_shared/StyledComponents"

type Props = {
    titleTextId: number
}

export default function WidgetWarehouseComponent({ titleTextId }: Props) {
    const { translate } = useLocalization()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()
    const defaultSalesOutlet = cisCustomer?.salesOutlets?.find((salesOutlet) => salesOutlet.isDefault)
    const alternativeSalesOutlet = cisCustomer?.salesOutlets?.find((salesOutlet) => !salesOutlet.isDefault && !salesOutlet.isTireWarehouse)

    return (
        <Widget
            size={alternativeSalesOutlet && defaultSalesOutlet ? "4x4" : "4x2"}
            iconName="catalog"
            title={translate(titleTextId)}
            header={<WidgetHeaderTitleComponent iconName="catalog" titleTextId={titleTextId} />}
            active
        >
            {cisCustomerLoading ? (
                <Loader />
            ) : (
                <Stack divider={<Divider />} justifyContent="center" spacing={1} height="100%">
                    {defaultSalesOutlet && (
                        <RowStack divider={<VerticalDivider />} flex={1}>
                            <Box flex={1}>
                                <WarehouseInfoComponent warehouse={defaultSalesOutlet} warehouseHeaderId={1089} />
                            </Box>
                            <Box flex={1}>
                                <WarehouseOpeningTimesComponent businnessTimesMode="businessTimes" warehouse={defaultSalesOutlet} showNotes />
                            </Box>
                        </RowStack>
                    )}
                    {alternativeSalesOutlet && (
                        <RowStack divider={<VerticalDivider />} flex={1}>
                            <Box flex={1}>
                                <WarehouseInfoComponent warehouse={alternativeSalesOutlet} warehouseHeaderId={1090} />
                            </Box>
                            <Box flex={1}>
                                <WarehouseOpeningTimesComponent businnessTimesMode="businessTimes" warehouse={alternativeSalesOutlet} showNotes />
                            </Box>
                        </RowStack>
                    )}
                </Stack>
            )}
        </Widget>
    )
}
