import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function fastServiceFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const fastServiceModule = getModuleFromUserContext(userContext, UserModuleType.FastService)

    if (!fastServiceModule) {
        return undefined
    }

    return {
        "fast-service": {
            items: {
                "fast-service": {
                    sort: 0,
                    name: "{{12686}}",
                    active: true,
                    route: "/:workTaskId/fast-service",
                    disabledFunction: "FASTSERVICE-DISABLED",
                },
            },
        },
    }
}
