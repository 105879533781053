import { ComponentActionType } from "./actions"
import { State } from "./model"
import { News } from "../../../data/models"

export * from "./actions"
export * from "./model"

const DEFAULT_STATE: State = {
    news: [],
    fullNewsLength: 0,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): State {
    switch (action.type) {
        case "UNREAD_NEWS_LOADED": {
            return {
                ...state,
                news: action.payload.sort(sortNews),
                fullNewsLength: action.payload.length,
            }
        }
        case "NEWS_READ": {
            return {
                ...state,
                news: state.news.filter((news) => news.newsId !== action.payload),
            }
        }
    }
    return state
}

function sortNews(newsA: News, newsB: News) {
    const sortNewsA = +newsA.category.substring(newsA.category.indexOf("sort-") + 5)
    const sortNewsB = +newsB.category.substring(newsB.category.indexOf("sort-") + 5)

    if (sortNewsA > sortNewsB || isNaN(sortNewsB)) {
        return 1
    }
    if (sortNewsA < sortNewsB || isNaN(sortNewsA)) {
        return -1
    }
    return 0
}
