import { Button, Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ReturnVoucherItem } from "../../../../../../../../../data/model"

type Props = {
    returnItem: ReturnVoucherItem
    onShowOrder(voucherId: string): void
}

export default function OrderNumber({ returnItem, onShowOrder }: Props) {
    const { translateText } = useLocalization()
    const orderNumber = returnItem.orderNo || returnItem.voucherId

    return (
        <Stack>
            <Typography whiteSpace="nowrap" variant="label">
                {translateText(470)}
            </Typography>
            <Button size="small" startIcon={<Icon name="partslink24" />} disabled={!orderNumber} onClick={() => onShowOrder(orderNumber)}>
                {translateText(50)}
            </Button>
        </Stack>
    )
}
