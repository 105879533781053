import { batch } from "react-redux"
import { Article, GetProductGroupTopicIdsRequest, GetProductGroupTopicIdsResponse, HasRepairTimesRequest, HasRepairTimesResponse, RegisteredModels, RepairTimeProvider, Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { Filter, equals, getProductGroupsIdsFromArticles, getRepairTimeProviders, getRepairTimeProvidersByNames, getValue } from "@tm/utils"
import { AvailabilityFilterType, BundleActionTypes, BundleActions } from "../../../business"
import { Repositories } from "../../../data"
import { WheelSelectionSteps } from "../../../data/enums"
import { addOrRemoveItem } from "../../../data/helpers"
import { getBundleParams } from "../../../utils"
import { MainState } from "../../main"
import { createNextSensorArticlesRequest, createSensorArticlesRequest } from "./helper"
import { ISensorFilters, RDKSListState } from "./model"

export * from "./model"

export type ComponentActionType = BundleActionTypes
    | { type: "RDKS_ARTICLES_LOADING" }
    | { type: "RDKS_NEXT_ARTICLES_LOADING", payload: boolean }
    | { type: "RDKS_ARTICLES_ERROR" }

    | { type: "RDKS_ARTICLES_LOADED", payload: Article[] }
    | { type: "RDKS_NEXT_ARTICLES_LOADED", payload: { articles: Article[], auto?: boolean } }
    | { type: "RDKS_NEXT_ARTICLES_ERROR" }

    | { type: "RDKS_ARTICLES_SELECT_ITEM", payload: Article }
    | { type: "UPDATE_FILTER", payload: { path: ISensorFilters, value: Filter } }
    | { type: "CHANGE_AVAILABILITY", payload: AvailabilityFilterType }
    | { type: "RESET_FILTER", payload: { path: ISensorFilters } }
    | { type: "SET_PRODUCTGROUP_TOPICIDS", payload: GetProductGroupTopicIdsResponse }
    | { type: "CHANGE_EXTENDEDASSORTMENT", payload: boolean }

    | { type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: { [key: number]: Array<RepairTimeProvider> } }

export const RDKS_LIST_DEFAULT_STATE: RDKSListState = {
    filters: { manufacturer: [] },
    articles: { autoNextCount: 0, data: [], pageIndex: 1, noMoreRdksArticles: false },
    selectedFilters: {
        availability: AvailabilityFilterType.None,
        extendedAssortment: false
    },
    erpInformations: [],
    productGroupTopicIds: {},
    repairTimeAvailabilities: []
}

export function reduce(state = { ...RDKS_LIST_DEFAULT_STATE }, action: ComponentActionType): RDKSListState {
    switch (action.type) {
        case "SEND_SENSOR_FILTERS_RESPONSE": {
            const { manufacturer } = action.payload
            return {
                ...state,
                filters: { manufacturer }
            }
        }
        case "SEND_SENSOR_ITEMS_RESPONSE": {
            return {
                ...state,
                articles: {
                    data: action.payload,
                    error: false,
                    loading: false,
                    autoNextCount: 0,
                    pageIndex: 1,
                    noMoreRdksArticles: false
                }
            }
        }
        case "SET_PRODUCTGROUP_TOPICIDS": {
            return {
                ...state,
                productGroupTopicIds: {
                    ...state.productGroupTopicIds,
                    ...action.payload
                }
            }
        }
        case "RDKS_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    data: [],
                    error: false,
                    loading: true,
                    autoNextCount: 0,
                    pageIndex: 1,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    noMoreRdksArticles: false
                }
            }
        }
        case "RDKS_ARTICLES_LOADED": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: action.payload,
                    error: false,
                    loading: false,
                    pageIndex: 1,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    noMoreRdksArticles: false
                }
            }
        }
        case "RDKS_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    error: true,
                    loading: false,
                    pageIndex: 1,
                    loadingNextItems: false
                }
            }
        }
        case "RDKS_ARTICLES_SELECT_ITEM": {
            return {
                ...state,
                selectedArticle: !equals(state.selectedArticle, action.payload) && action.payload || undefined,
            }
        }
        case "SET_ERP_INFORMATIONS": {
            let erpInformations = [...state.erpInformations]

            action.payload.forEach(erp => {
                const index = erpInformations.findIndex(x => x.itemId === erp.itemId)
                if (index >= 0)
                    erpInformations[index] = erp
                else
                    erpInformations.push(erp)
            })

            return {
                ...state,
                erpInformations,
                articles: {
                    ...state.articles,
                    loading: false,
                    loadingAutoItems: false,
                }
            }
        }
        case "CHANGE_AVAILABILITY": {
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    availability: action.payload
                }
            }
        }
        case "CHANGE_EXTENDEDASSORTMENT": {
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    extendedAssortment: action.payload
                }
            }
        }
        case "UPDATE_FILTER": {
            const { path, value } = action.payload
            const oldStoredValues = getValue(state.selectedFilters, [path])
            const newValues = addOrRemoveItem(oldStoredValues, value)
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: newValues
                }
            }
        }
        case "RESET_FILTER": {
            const { path } = action.payload
            return {
                ...state,
                selectedFilters: {
                    ...state.selectedFilters,
                    [path]: undefined
                }
            }
        }
        case "CHANGE_ARTICLE_QUANTITY": {
            const { article, quantity } = action.payload
            const sensors = state.articles.data.map(sensor => ({ ...sensor, ...(sensor.id == article.id || sensor.id == article.internalId) && { quantity: quantity || article.quantity } }))

            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: sensors
                }

            }
        }
        case "RDKS_NEXT_ARTICLES_ERROR": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    loadingNextItems: false,
                    loadingAutoItems: false,
                    noMoreRdksArticles: true
                },
            }
        }
        case "RDKS_NEXT_ARTICLES_LOADING": {
            return {
                ...state,
                articles: {
                    ...state.articles,
                    ...action.payload
                    && {
                        loadingAutoItems: true
                    }
                    || {
                        loadingNextItems: true,
                    },
                    autoNextCount: action.payload && (state.articles.autoNextCount + 1) || 0
                }
            }
        }
        case "RDKS_NEXT_ARTICLES_LOADED": {
            const { articles } = action.payload
            const newData = [...state.articles.data, ...articles]
            return {
                ...state,
                articles: {
                    ...state.articles,
                    data: newData,

                    loading: false,
                    loadingNextItems: false,

                    autoNextCount: state.articles.autoNextCount == 2 && undefined || state.articles.autoNextCount,
                    pageIndex: state.articles.pageIndex + 1
                }
            }
        }

        case "SET_PRODUCTGROUP_REPAIRTIMES": {
            return {
                ...state,
                repairTimeAvailabilities: {
                    ...state.repairTimeAvailabilities,
                    ...action.payload
                }
            }
        }
    }
    return state
}

function loadSensorsList(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const vehicle = getState().manager.vehicle
        const { selectedFilters, filters } = getState().rdksList

        if (!vehicle) return

        let isExtendedAssortment = selectedFilters.manufacturer?.some(x => !x.isTopPriority)
        if (!isExtendedAssortment) isExtendedAssortment == undefined
        const request = createSensorArticlesRequest(filters, selectedFilters, vehicle, isExtendedAssortment)

        if (!request)
            return

        batch(() => {
            dispatch({ type: "RDKS_ARTICLES_LOADING" })
            isExtendedAssortment && dispatch({ type: "CHANGE_EXTENDEDASSORTMENT", payload: isExtendedAssortment })
        })

        Repositories.loadSensorItems(request).then(
            response => {
                dispatch({ type: "RDKS_ARTICLES_LOADED", payload: response })
                dispatch(loadProductGroupRepairTimes(response))
            },
            () => dispatch({ type: "RDKS_ARTICLES_ERROR" })
        )
    }
}

function loadProductGroupRepairTimes(articles: Array<Article>): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { manager: { vehicle } } = getState()

        const productGroupIds = getProductGroupsIdsFromArticles(articles)
        const { repairTimeProviderIds } = getRepairTimeProviders()

        const providers = getRepairTimeProvidersByNames(repairTimeProviderIds)

        if (!vehicle || !providers.length || !productGroupIds.length)
            return

        const request: HasRepairTimesRequest = {
            repairTimeProvider: providers,
            modelId: vehicle.tecDocTypeId,
            productGroupIds,
            vehicleType: vehicle.vehicleType
        }

        Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes)
            .subscribe(request)
            .load()
            .then(response => {
                if (response)
                    dispatch({ type: "SET_PRODUCTGROUP_REPAIRTIMES", payload: response })
            })
    }
}

function toggleExtendedAssortmentFilter(extendedAssortment: boolean): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const vehicle = getState().manager.vehicle
        const { selectedFilters, filters } = getState().rdksList

        if (!vehicle) return

        const request = createSensorArticlesRequest(filters, selectedFilters, vehicle, extendedAssortment)
        if (!request)
            return

        Repositories.loadSensorItems(request).then(
            response => {
                batch(() => {
                    dispatch({ type: "RDKS_ARTICLES_LOADED", payload: response })
                    dispatch({ type: "CHANGE_EXTENDEDASSORTMENT", payload: extendedAssortment })
                })
            },
            () => {
                batch(() => {
                    dispatch({ type: "RDKS_ARTICLES_ERROR" })
                    dispatch({ type: "CHANGE_EXTENDEDASSORTMENT", payload: extendedAssortment })
                })
            }
        )
    }
}

function loadNextSensorsList(auto?: boolean): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const state = getState()
        const vehicle = getState().manager.vehicle

        if (!vehicle) return

        let isExtendedAssortment = state.rdksList.selectedFilters.manufacturer?.some(x => !x.isTopPriority)
        if (!isExtendedAssortment) isExtendedAssortment == undefined

        const request = createNextSensorArticlesRequest(state.rdksList, vehicle, isExtendedAssortment)

        if (!request || state.rdksList.articles.loadingNextItems || state.rdksList.articles.loadingAutoItems)
            return

        dispatch({ type: "RDKS_NEXT_ARTICLES_LOADING", payload: !!auto })

        Repositories.loadSensorItems(request).then(
            response => dispatch({ type: "RDKS_NEXT_ARTICLES_LOADED", payload: { articles: response, auto } }),
            () => dispatch({ type: "RDKS_NEXT_ARTICLES_ERROR" }))
    }
}

function getProductGroupTopicIds(vehicle: Vehicle, productGroupIds: Array<number>): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        if (!!getBundleParams().haynesProTdGenartsRoute) {
            const state = getState()
            productGroupIds = productGroupIds.filter(id => !state.rdksList.productGroupTopicIds.hasOwnProperty(id))

            if (!productGroupIds.length)
                return

            const request: GetProductGroupTopicIdsRequest = {
                modelId: vehicle.tecDocTypeId,
                vehicleType: vehicle.vehicleType,
                repairTimeProvider: [RepairTimeProvider.HaynesProCar],
                productGroupIds
            }

            Container.getInstance<GetProductGroupTopicIdsResponse>(RegisteredModels.RepairTimes_TechnicalData_GetProductGroupTopicIds)
                .subscribe(request).load().then(response => {
                    dispatch({ type: "SET_PRODUCTGROUP_TOPICIDS", payload: response })
                })
        }
    }
}

function sendSelectedSensorToDetails(article?: Article): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        dispatch({ type: "SEND_SENSOR_ARTICLE_TO_DETAILS", payload: article ?? getState().rdksList.selectedArticle! })
    }
}

function sendArticleToOverview(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { selectedArticle } = getState().rdksList
        if (!selectedArticle)
            return

        dispatch({ type: "SEND_SENSOR_TO_OVERVIEW", payload: selectedArticle })
    }
}

function saveTpmsTab(selectedArticle: Article): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch, getState) => {

        const { pageIndex } = getState().rdksList.articles
        const { selectedFilters } = getState().rdksList

        dispatch(BundleActions.saveData({
            tpmsTab: {
                article: {
                    internalId: selectedArticle.internalId, traderArticleNo: selectedArticle.traderArticleNo, quantity: selectedArticle.quantity
                },
                pageIndex,
                selectedFilters
            },
            activeStep: WheelSelectionSteps.TIRESLIST,
            highestStepReached: WheelSelectionSteps.TIRESLIST
        }))
    }
}

function selectRDKSArticle(article: Article): ComponentActionType {
    return { type: "RDKS_ARTICLES_SELECT_ITEM", payload: article }
}

function updateFilter(path: ISensorFilters, value: Filter): ComponentActionType {
    return { type: "UPDATE_FILTER", payload: { path, value } }
}

function resetFilter(path: ISensorFilters): ComponentActionType {
    return { type: "RESET_FILTER", payload: { path } }
}

function changeAvailabilityFilter(value: AvailabilityFilterType): ComponentActionType {
    return { type: "CHANGE_AVAILABILITY", payload: value }
}

function changeExtendedAssortmentFilter(value: boolean): ComponentActionType {
    return { type: "CHANGE_EXTENDEDASSORTMENT", payload: value }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectRDKSArticle,
    sendSelectedSensorToDetails,
    sendArticleToOverview,
    changeAvailabilityFilter,
    updateFilter,
    resetFilter,
    loadSensorsList,
    getProductGroupTopicIds,
    loadNextSensorsList,
    changeExtendedAssortmentFilter,
    toggleExtendedAssortmentFilter,
    saveTpmsTab
}
