import { ajax, getStoredAuthorization } from "@tm/utils"
import { getServiceUrl } from "../params"
import { GetMemoInfoRequest, GetMemoInfoResponse } from "@tm/models"

export function getMemoInfo(manufacturerName: string): Promise<GetMemoInfoResponse | undefined> {

    const body: GetMemoInfoRequest = {
        manufacturerName
    }

    const url = `${getServiceUrl()}/GetMemoInfo`
    const authorization = getStoredAuthorization()

    return ajax<GetMemoInfoResponse>({ url, body, authorization, method: "GET" }).then(response => response!)
}