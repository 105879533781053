import { MessageBus, Channel } from "@tm/hermes"

let mb: MessageBus<BundleChannels>

export function bundleChannel<T extends keyof BundleChannels>(type: T, id?: string): Channel<BundleChannels, T> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel(type, id)
}

interface BundleChannels {
    DETAILS: DetailsChannelType
}

type DetailsChannelType = { NAVIGATE: { subPage: string } }
