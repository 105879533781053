import { stylesheet } from "typestyle"

export function getComponentStyles() {
    return stylesheet({
        partsListWithImageWrapper: {
            flex: 1,
            minHeight: "18rem",
        },
        loadArticlesButtonWrapper: {
            textAlign: "center",
        },
        loadArticlesButton: {
            margin: "2em",
        },
    })
}
