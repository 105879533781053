import { MenuItem, Select, SelectChangeEvent, styled, Typography, Box } from "@tm/components"
import { Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModelSeriesBase, SortingFilterType, VehicleType } from "@tm/models"

import { getImageTypeByVehicleType } from "../../business"
import { usePreferredSortOptionKey } from "../../data/hooks/useSortOptions"
import { renderDateRange } from "../../helpers/construction-year"
import { NoDataWarningComponent } from "./no-data-warning"

type Props = {
    vehicleType: VehicleType
    modelSeries: Array<ModelSeriesBase>
    onSelect(modelSeries: ModelSeriesBase): void
    isArticleReferences: boolean
}

export default function ModelSeriesListComponent({ vehicleType, modelSeries, onSelect, isArticleReferences }: Props) {
    const { translateText, date } = useLocalization()
    const [preferredSortOption, setPreferredSortOption] = usePreferredSortOptionKey()

    const imageType = getImageTypeByVehicleType(vehicleType)

    const renderItem = (item: ModelSeriesBase) => (
        <li key={item.id} className="modelseries__item card">
            {
                // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                <figure onClick={() => onSelect(item)} className="card__inner">
                    <Image url={item.modelSeriesThumbnail} type={imageType} />
                    <figcaption>{item.description}</figcaption>
                    <div className="construction-dates">{renderDateRange(item, date)}</div>
                </figure>
            }
        </li>
    )

    const handleChange = (e: SelectChangeEvent<unknown>) => {
        setPreferredSortOption?.(e.target.value as SortingFilterType)
    }

    const InputContainer = styled("div")({
        display: "flex",
        flex: 1,
        justifyContent: "flex-end",
        alignItems: "center",
        paddingRight: "40px",
    })

    const articleReferencesSorting = (
        <InputContainer>
            <Typography variant="h3" mr="16px">
                {translateText(13270)}
            </Typography>
            <Select value={preferredSortOption} onChange={handleChange}>
                <MenuItem value="alphabetical">{translateText(3156)}</MenuItem>
                <MenuItem value="relevance">{translateText(3157)}</MenuItem>
            </Select>
        </InputContainer>
    )

    return (
        <div className="modelseries">
            <Box alignItems="center">
                <Typography variant="h2" mb={1}>
                    {translateText(73)}
                </Typography>
                {isArticleReferences && articleReferencesSorting}
            </Box>
            <ul className="modelseries__list">{modelSeries?.length ? modelSeries.map(renderItem) : <NoDataWarningComponent />}</ul>
        </div>
    )
}
