import { Box, Button, Tooltip } from "@tm/components"
import { ECounterType, ModuleTab } from "@tm/models"
import { getUIA, TmaHelper } from "@tm/utils"
import { forwardRef, useImperativeHandle, useRef } from "react"
import { ModuleNavigationProps, Refs } from "../component"
import { DashboardItem } from "./DashboardItem"
import { TabItem } from "./TabItem"

type Props = ModuleNavigationProps & {
    workTaskRoute?: string
    worktaskDashboardTitle?: string
    visibleTabs: ModuleTab[]
    moreTabs: ModuleTab[]
    showTooltip: boolean
    handleTooltipShow(): void
    handleTooltipHide(): void
    btnClassName: string
}

export const ModuleNavigationTabs = forwardRef<Refs, Props>((props: Props, ref) => {
    const wrapperElement = useRef<HTMLDivElement>(null)
    const navigationElement = useRef<HTMLDivElement>(null)
    const moreElement = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        wrapperElement,
        navigationElement,
        moreElement,
    }))

    const { visibleTabs, moreTabs, showTooltip, handleTooltipShow, handleTooltipHide, btnClassName } = props
    const isStartSelected = !visibleTabs.some((x) => x.isSelected)

    return (
        <Box className="worktask-modules__wrapper" ref={wrapperElement} {...getUIA("Modulenavigation")}>
            <DashboardItem
                {...props}
                clickEvent={() => {
                    TmaHelper.GeneralCountEvent.Call(ECounterType.StartPageWorktask)
                }}
                isSelected={isStartSelected}
            />
            <Box className="worktask-modules tab-control">
                <Box className="worktask-modules__inner" ref={navigationElement}>
                    {visibleTabs.map((item) => (
                        <TabItem key={item.url} item={item} style={props.style} onClose={handleTooltipHide} />
                    ))}
                </Box>
                <Box className="more__wrapper" ref={moreElement}>
                    <Tooltip
                        className="more__list"
                        onClose={handleTooltipHide}
                        onClickAway={handleTooltipHide}
                        open={showTooltip}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={
                            <Box>
                                {moreTabs.map((item) => (
                                    <TabItem key={item.url} item={item} isMoreItem style={props.style} onClose={handleTooltipHide} />
                                ))}
                            </Box>
                        }
                        variant="light"
                    >
                        <Box>
                            <Button className={btnClassName} onClick={handleTooltipShow}>
                                ...
                            </Button>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    )
})
