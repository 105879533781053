import { elementId } from "@tm/utils"
import { Icon, Text, Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"

type Props = {
    actionsWillBeDone: Array<string>
    loading: boolean
    intro?: string
}

const TRANSLATION_REGEX = /\{\{(.*?)\}\}/g

export default function DialogInfoComponent({ actionsWillBeDone, loading, intro }: Props) {
    const { translateText } = useLocalization()

    function renderInformation(info: string) {
        const translatedText = info.replace(TRANSLATION_REGEX, (s, num) => translateText(num))

        return (
            <div key={elementId("vehicle-change-info")} className="text-wrapper">
                <Text>{translatedText}</Text>
            </div>
        )
    }

    const infoText = translateText(178)
    return (
        <div className="worktask-change__information">
            <Text modifiers={["block", "sub"]} className="worktask-change__head">
                {actionsWillBeDone.length <= 0 ? infoText.substr(0, infoText.length - 2) : infoText}
            </Text>
            {intro && (
                <div className="intro">
                    <Icon className="icon--decent" name="info" />
                    <Text>{intro}</Text>
                </div>
            )}
            {loading ? <Loader /> : <div className="section">{actionsWillBeDone.map(renderInformation)}</div>}
        </div>
    )
}
