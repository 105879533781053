import { Dispatch } from "react"
import { Container } from "@tm/nexus"
import { CisCustomerContainer, CisFindCustomerDto, RegisteredModels } from "@tm/models"
import { Outlet } from "../../data/repositories"
import * as Data from "../../data/repositories"
import { subtractDaysFromDate } from "../../helpers"
import { resetTimeInGivenDate } from "./helper/time"

export const DEFAULT_FILTER_DATE_OFFSET = 30
export const MAX_WORKTASK_PAGE_SIZE = 50

export type WorktaskHistoryState = {
    dateFilter: { from: Date; to: Date }
    activePage: number
    endOfPages: boolean
    query?: string
    employee?: Data.Employee
    status?: number
    outlet?: Outlet
    sort?: Data.Sort
    worktasks?: Data.WorktaskInfo[]
    worktasksLoading?: boolean
    currentCallerLoading?: boolean
    currentCallers?: Array<CisFindCustomerDto>
}

export type ActionType =
    | {
          type: "WORKTASKS_LOADING"
          payload: {
              query?: string
              employee?: Data.Employee
              outlet?: Outlet
              status?: number
              sort?: Data.Sort
              dateFilter?: { from: Date; to: Date }
              activePage?: number
          }
      }
    | { type: "WORKTASKS_LOADED"; payload: Data.WorktaskInfo[]; endOfPaging: boolean; activePage: number }
    | { type: "CALLER_LOADED"; payload: Array<CisFindCustomerDto> }
    | { type: "CALLER_LOADING" }

export const initialState: WorktaskHistoryState = {
    activePage: 1,
    endOfPages: true,
    dateFilter: {
        from: subtractDaysFromDate(new Date(), 7),
        to: new Date(),
    },
}

export function reduce(state: WorktaskHistoryState, action: ActionType): WorktaskHistoryState {
    switch (action.type) {
        case "WORKTASKS_LOADING": {
            return {
                ...state,
                ...action.payload,
                dateFilter: {
                    from: resetTimeInGivenDate(action.payload.dateFilter?.from || state.dateFilter.from),
                    to: resetTimeInGivenDate(action.payload.dateFilter?.to || state.dateFilter.to),
                },
                worktasksLoading: true,
            }
        }
        case "WORKTASKS_LOADED": {
            let concatinatedWorktasks: Data.WorktaskInfo[] = [...(state.worktasks || [])]

            if (action.activePage > 1) {
                const ids = new Set(concatinatedWorktasks.map((d) => d.id))
                concatinatedWorktasks = [...concatinatedWorktasks, ...(action.payload || []).filter((d) => !ids.has(d.id))]
            } else {
                concatinatedWorktasks = action.payload
            }

            return {
                ...state,
                worktasksLoading: false,
                worktasks: concatinatedWorktasks,
                endOfPages: action.endOfPaging,
                activePage: action.activePage,
            }
        }
        case "CALLER_LOADING": {
            return {
                ...state,
                currentCallerLoading: !state.currentCallerLoading,
            }
        }
        case "CALLER_LOADED": {
            return {
                ...state,
                currentCallers: action.payload,
                currentCallerLoading: false,
            }
        }
        default:
            break
    }
    return state
}

export function searchCurrentActiveCaller(dispatch: Dispatch<ActionType>) {
    dispatch({ type: "CALLER_LOADING" })
    const container = Container.getInstance(RegisteredModels.CIS_Customer) as CisCustomerContainer
    container
        .action("loadCurrentCaller")()
        .then((response) => {
            dispatch({ type: "CALLER_LOADED", payload: response })
        })
        .catch(() => {
            dispatch({ type: "CALLER_LOADING" })
        })
}
