import { styled } from "@tm/components"

type Props = {
    value: number
    size: number
    strokewidth: number
}

const StyledSVG = styled("svg")({
    margin: "0.5rem auto 0",
    borderRadius: "50%",
    display: "block",

    ".donutchart__track": {
        fill: "transparent",
        stroke: "#EEEEEE",
        strokeWidth: 26,
    },

    ".donutchart__indicator": {
        fill: "transparent",
        stroke: "#0088FE",
        strokeWidth: 26,
        strokeDasharray: "0 10000",
        transition: "stroke-dasharray .3s ease",
    },
})

// https://codepen.io/zeroskillz/pen/mPmENy
export default function DonutChart(props: Props) {
    const halfsize = props.size * 0.5
    const radius = halfsize - props.strokewidth * 0.5
    const circumference = 2 * Math.PI * radius
    const strokeval = (props.value * circumference) / 100
    const dashval = `${strokeval} ${circumference}`

    const trackstyle = { strokeWidth: props.strokewidth }
    const indicatorstyle = { strokeWidth: props.strokewidth, strokeDasharray: dashval }
    const rotateval = `rotate(-90 ${halfsize},${halfsize})`

    return (
        <StyledSVG width={props.size} height={props.size}>
            <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={trackstyle} className="donutchart__track" />
            <circle r={radius} cx={halfsize} cy={halfsize} transform={rotateval} style={indicatorstyle} className="donutchart__indicator" />
            <text className="donutchart__text" x={halfsize} y={halfsize} dominantBaseline="middle" textAnchor="middle">
                <tspan className="donutchart__text__val">{props.value}</tspan>
                <tspan className="donutchart__text__percent">%</tspan>
            </text>
        </StyledSVG>
    )
}
