import { useStyle, useUser, useWorkTask } from "@tm/context-distribution"
import { IFrame } from "@tm/controls"
import { channel } from "@tm/models"
import { classes, decodeUniqueId, RouteComponentProps, useMessage, withRouter } from "@tm/utils"
import { useSelector } from "react-redux"
import { useLocalization } from "@tm/localization"
import { percent } from "csx"
import { useActions } from "@tm/morpheus"
import { useEffect, FC, useRef, useState } from "react"
import { Actions, SetTruckCustomerData, TruckState } from "./business"
import { WrapperFrame } from "../_shared/wrapperFrame"
import { LoginRequest } from "../../data"
import { buildBaseRequestParametersWithoutVehicle, compareAndSetRequestUrl, getCustomer, getTruckVehicle, getWorktaskId } from "../../data/helpers"
import { useTruckCustomData } from "../../data/hooks/useTruckCustomData"

type RouteProps = {
    workTaskId: string
}

type Props = RouteComponentProps<RouteProps> & {
    id: string
    loginUrl: string
    loginRequest: LoginRequest
    className?: string
}

const TruckFrame: FC<Props> = ({ id, loginRequest, loginUrl, match: { params }, className }) => {
    const { languageId } = useLocalization()
    const workTaskId = getWorktaskId() ?? decodeUniqueId(params.workTaskId ?? "")
    const { workTask: { customer = undefined } = {} } = useWorkTask() ?? {}
    const actions = useActions(Actions)
    const userContext = useUser()?.userContext

    const vehicle = useSelector((s: TruckState) => s.vehicle)
    const parameters = useSelector((s: TruckState) => s.parameters)

    const isDataLoaded = useTruckCustomData(workTaskId, vehicle, actions.setCustomVehicle)

    const [loading, setLoading] = useState(true)
    const [url, setUrl] = useState("")
    const mounted = useRef(false)
    const [, setResize] = useState<number | undefined>()

    const buildRequestParameters = () => {
        const request = buildBaseRequestParametersWithoutVehicle(loginRequest, languageId, workTaskId, userContext)

        if (vehicle) {
            request.manufacturer = vehicle.manufacturerId
            request.vinNumber = vehicle.vin
        }

        return request
    }

    const handleLoadingCallback = () => {
        mounted.current && setLoading(false)
    }

    useMessage((data: any, source: Window) => {
        if (!source) {
            return
        }

        if (data.invalidSessionMvc) {
            compareAndSetRequestUrl(
                buildRequestParameters,
                loginUrl,
                setLoading,
                setUrl,
                actions.updateParameters,
                parameters,
                url,
                data.invalidSessionMvc
            )
            return
        }

        if (!data.getTruckCustomerData) {
            return
        }

        const setTruckCustomerData: SetTruckCustomerData = {
            customer: getCustomer(customer, userContext?.hasTelesales),
            vehicle: getTruckVehicle(vehicle),
        }

        source.postMessage({ setTruckCustomerData }, "*")
    })

    useEffect(() => {
        mounted.current = true
        const unSub = channel("GLOBAL").subscribe("MVC/RESIZE", () => mounted.current && setResize(Math.random()))

        return () => {
            unSub?.()
            mounted.current = false
        }
    }, [])

    useEffect(() => {
        if (workTaskId && userContext?.id && isDataLoaded) {
            compareAndSetRequestUrl(buildRequestParameters, loginUrl, setLoading, setUrl, actions.updateParameters, parameters, url)
        }
    }, [workTaskId, userContext?.id, isDataLoaded, vehicle?.vin, vehicle?.manufacturerId])

    return (
        <WrapperFrame loading={loading}>
            {url && (
                <IFrame
                    url={url}
                    className={classes(style.wrapper, className, loading ? style.hide : "")}
                    contextDependent
                    id={`iframe_${id}`}
                    onLoad={handleLoadingCallback}
                    allow="camera *; clipboard-read; clipboard-write"
                    refreshOnUrlChanged
                />
            )}
        </WrapperFrame>
    )
}

const style = useStyle({
    hide: {
        display: "none",
    },
    wrapper: {
        flex: 1,
        height: percent(100),
        width: percent(100),
    },
})(TruckFrame)

export default withRouter(TruckFrame)
