import { Box, Typography, styled } from "@tm/components"
import Vehicle from "./Vehicle"

export default function Header() {
    return (
        <Box position="relative">
            <Typography variant="h2" marginBottom="0.5em">
                SINDRI
            </Typography>
            <Vehicle />
            <StyledImageBox />
        </Box>
    )
}

const StyledImageBox = styled(Box)({
    position: "absolute",
    right: "8px",
    top: "0",
    width: "167px",
    height: "51px",
    backgroundImage: `url("/styles/base/images/continental.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
})
