import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { getAlertColor } from "@tm/utils"
import { MessageType } from "@tm/models"
import { Alert, Stack } from "@tm/components"
import { useTransferCostEstimation } from "../../../data/hooks/useTransferCostEstimation"
import { ESendCostEstimationMode } from "../../../data/model"

type Props = {
    workTaskId: string
    telesalesCustomerNo?: string
    mode: ESendCostEstimationMode
    onClearCostEstimationSent?(): void
}

export default function TransferCompletedComponent({ workTaskId, telesalesCustomerNo, mode, onClearCostEstimationSent }: Props) {
    const { translateText } = useLocalization()
    const { response } = useTransferCostEstimation(workTaskId, telesalesCustomerNo, mode)

    const title = useMemo(() => {
        if (response?.isBasketEmpty) {
            return { textId: 13447, severity: getAlertColor(MessageType.Warning) }
        }

        if (response?.hasSucceeded) {
            return { textId: 13446, severity: getAlertColor(MessageType.Information) }
        }

        return { textId: 787, severity: getAlertColor(MessageType.TechnicalError) }
    }, [response])

    return (
        <Stack spacing={1} mb={2}>
            <Alert title={translateText(title.textId)} severity={title.severity} onClose={onClearCostEstimationSent} />
            {response?.message && (
                <Alert
                    title={response.message.texts.toString()}
                    severity={getAlertColor(response.message.type)}
                    iconColor={response.message.type === MessageType.Error ? "error" : undefined}
                />
            )}
        </Stack>
    )
}
