import { channel, ErpSystemConfig, OrderResponse } from "@tm/models"
import { useCallback, useEffect, useState } from "react"

export type InternalOrderResult = {
    result: OrderResponse
    erpSystemConfig: ErpSystemConfig
}

export type InternalOrderError = {
    technicalErrorText?: string
    erpSystemConfig: ErpSystemConfig
}

type State = {
    results: Array<InternalOrderResult>
    technicalErrors: Array<InternalOrderError>
}

export function useOrderResult(workTaskId: string) {
    const [state, setState] = useState<State>({ results: [], technicalErrors: [] })

    const clearOrderSent = useCallback(() => {
        setState({ results: [], technicalErrors: [] })
    }, [])

    useEffect(() => {
        const unsubscriptions = [
            channel("WORKTASK", workTaskId).subscribe("BASKET/CLEAR_ORDER_SENT_CONTAINER", clearOrderSent),
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENDING", clearOrderSent),
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENT", (result) => {
                setState((prev) => ({ ...prev, results: prev.results.concat(result) }))
            }),
            channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENT_ERROR", (technicalError) => {
                setState((prev) => ({ ...prev, technicalErrors: prev.technicalErrors.concat(technicalError) }))
            }),
        ]

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [workTaskId, clearOrderSent])

    return { ...state, clearOrderSent }
}
