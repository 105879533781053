import { useKeyValue } from "@tm/utils"
import { useMemo } from "react"

export enum EGsiContext {
    BoschOs3 = "1",
}

export function useGsiContext(workTaskId: string) {
    const [gsiContextString] = useKeyValue({ key: `${workTaskId}_GSI-CONTEXT`, ownedByRepairShop: true })

    const gsiContext = useMemo(() => {
        return gsiContextString ? (JSON.parse(gsiContextString) as { contextId: EGsiContext }) : undefined
    }, [gsiContextString])

    return gsiContext?.contextId
}
