import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, ConfigParams, RequestArticleDetailsPayload, SystemType } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { createQueryString, parseQueryString, renderRoute } from "@tm/utils"
import { useSelector } from "react-redux"
import { Box, Loader, styled, Typography } from "@tm/components"
import { useParams } from "react-router"
import { useCallback, useEffect } from "react"
import { getCurrencyFromUserContext } from "../../data/helpers"
import { ICalculationItem, MatchParams, Part } from "../../data/models"
import { calculationSelector, MainActions, navigationSelector } from "../main/business"
import { CalculationItem, Header, Totals, Works } from "./components"
import CustomAccordion from "./components/shared/customAccordion"

type Props = {
    detailsModalRoute: string
}

export default function Calculation({ detailsModalRoute }: Props) {
    const { translateText, currency } = useLocalization()
    const user = useUser()
    const matchParams = useParams<MatchParams>()
    const currencyString = getCurrencyFromUserContext(user?.userContext)

    const { collapsableItems, erpArticles, items, totals, works, calculationError, calculationLoading, calculationLoaded } =
        useSelector(calculationSelector)
    const { active: activeTab } = useSelector(navigationSelector)
    const actions = useActions(
        MainActions,
        "setErpArticles",
        "changeOeArticleQuantity",
        "getCalculationData",
        "changeCollapsiableState",
        "selectArticle",
        "replaceArticle",
        "selectWork",
        "updateTotals",
        "changeAlternativeArticleQuantity",
        "changeStep"
    )

    useEffect(() => {
        const unsub = channel("GLOBAL").subscribe("ERP/ERP_INFORMATION_LOADED", actions.setErpArticles)
        return unsub
    })

    const handleDetailsClick = (article: Part, request: RequestArticleDetailsPayload) => {
        const params = {
            productGroupId: article.productGroupId,
            supplierId: article.dataSupplierId,
            supplierArticleNo: article.dataSupplierArticleNumber,
            quantity: article.quantityValue || 1,
            partsDetailsSubPage: request.subPage || "overview",
            taskId: undefined,
        }

        const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()

        const queryParams = parseQueryString(location.search)
        queryParams.initialQuantity = params?.quantity

        if (articleDetailsInModal && user?.userContext?.system.systemType === SystemType.Next && detailsModalRoute) {
            Morpheus.showView("1", renderRoute(detailsModalRoute, { ...matchParams, ...params }) + createQueryString(queryParams))
        } else {
            actions.changeStep("details", params)
        }
    }

    const handleAlternativeClick = useCallback(
        (item: ICalculationItem) => {
            const isActive = collapsableItems[item.uniqueId]
            actions.changeCollapsiableState(item.uniqueId, !isActive)
        },
        [actions]
    )

    const changeQuantity = useCallback(
        (item: ICalculationItem) => (value: number) => {
            actions.changeOeArticleQuantity(item, value)
        },
        [actions]
    )

    const handleOePart = useCallback(
        (oeArticle: ICalculationItem) => (part: Part) => {
            actions.changeCollapsiableState(oeArticle.uniqueId, false)
            actions.replaceArticle(oeArticle, part)

            if (!oeArticle.selectedPart) {
                channel("GLOBAL").subscribeOnce("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
                    actions.updateTotals([...erpArticles, ...erpInfo])
                })
            } else {
                actions.updateTotals(erpArticles)
            }
        },
        [actions]
    )

    const renderArticles = (item: ICalculationItem, idx: number) => {
        return (
            <CalculationItem
                key={idx}
                item={item}
                onDetailsClick={handleDetailsClick}
                onOeArticleSelect={() => actions.selectArticle(item)}
                onOEQuantityChange={changeQuantity(item)}
                onAlternativeArticleQuantityChange={(value) => actions.changeAlternativeArticleQuantity(item, value)}
                onPartSelect={handleOePart(item)}
                onAlternativeClick={() => handleAlternativeClick(item)}
                isActive={collapsableItems[item.uniqueId]}
            />
        )
    }

    if (!!matchParams.taskId?.length && !calculationLoading && !calculationLoaded && activeTab === "calculation") {
        actions.getCalculationData(matchParams.taskId)

        return (
            <Box>
                <Loader size="small" />
            </Box>
        )
    }

    if (calculationLoading) {
        return (
            <Box>
                <Loader size="small" />
            </Box>
        )
    }

    if (calculationError) {
        return (
            <Box>
                <Typography>{translateText(1569)}</Typography>
            </Box>
        )
    }

    return (
        <Box style={{ padding: "1em", flex: "1" }}>
            <Header />
            <Totals />

            <CustomAccordion
                title={translateText(90)}
                itemsCount={items?.length ?? 0}
                itemsPrice={currency(totals.totalSparePartsPrice, currencyString)}
            >
                {!!items?.length && (
                    <Box className="tk-parts">
                        <Box className="list">
                            <StyledBox className="article-list calculation-items">{items.map(renderArticles)}</StyledBox>
                        </Box>
                    </Box>
                )}
            </CustomAccordion>

            <CustomAccordion title={translateText(83)} itemsCount={works?.length ?? 0} itemsPrice={currency(totals.totalWorksPrice, currencyString)}>
                {!!works?.length && <Works />}
            </CustomAccordion>
        </Box>
    )
}

const StyledBox = styled(Box)({
    ".article-list__item": {
        margin: "0 0 0.5rem -0.5rem",
    },
    ".oe-article-image": {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right center",
        backgroundSize: "100%",
        backgroundColor: "white",
    },
    ".tag__content": {
        borderColor: "orange",
    },
    ".check-button": {
        marginLeft: "2em",
    },
    ".supplier__name": {
        fontWeight: "500",
    },
    ".article__description": {
        fontWeight: "500",
    },
    ".price__group": {
        fontWeight: "500",
    },
    ".article__cell--additional-actions": {
        fontWeight: "500",
    },
    ".article__information-group": {
        fontWeight: "500",
    },
    ".article-list__item__buy .tk-erp": {
        fontWeight: "500",
    },
})
