import { Dropdown, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ManufacturerBase, VehicleType } from "@tm/models"
import { Suspense } from "react"

import { useAllManufacturers } from "../../../../data/hooks"
import { useSelections } from "../../business/hooks"

type Props = {
    vehicleType: VehicleType
}

export default function ManufacturerSelection(props: Props) {
    const { translateText } = useLocalization()

    return (
        <div className="row">
            <div className="number">1</div>
            <Suspense
                fallback={
                    <Dropdown
                        items={[]}
                        itemView={() => <div>{translateText(294)}</div>}
                        coverView={() => <div>{translateText(294)}</div>}
                        disabled
                    />
                }
            >
                <Content {...props} />
            </Suspense>
        </div>
    )
}

function Content({ vehicleType }: Props) {
    const { translateText } = useLocalization()
    const { primaryManufacturers, secondaryManufacturers } = useAllManufacturers({ vehicleType, selectedFilters: {} }) ?? {}
    const { selectedManufacturer, setManufacturer } = useSelections(vehicleType)

    const renderManufacturerItem = (data?: ManufacturerBase) => {
        if (!data?.description) {
            return <div>{translateText(294)}</div>
        }

        return (
            <div className="item">
                <div className="thumb">
                    <Image url={data.thumbnail} />
                </div>
                <div className="description">{data.description}</div>
            </div>
        )
    }

    return (
        <Dropdown
            items={(primaryManufacturers?.length ? primaryManufacturers : secondaryManufacturers) ?? []}
            itemView={renderManufacturerItem}
            coverView={() => <div>{translateText(294)}</div>}
            disabled={!primaryManufacturers?.length && !secondaryManufacturers?.length}
            onChange={setManufacturer}
            value={selectedManufacturer}
            amountItemsToShow={10}
            getSearchValue={(item) => item.description}
        />
    )
}
