import { AsyncAction } from "@tm/morpheus"
import { BundleActionType } from "../../business"
import { getQueryHistory } from "../_shared/helper/queryhistory"

export type ComponentState = {
    queryHistory: Array<string>
}

const DEFAULT_STATE: ComponentState = {
    queryHistory: [],
}

export function reduce(state = DEFAULT_STATE, action: BundleActionType): ComponentState {
    switch (action.type) {
        case "QUERY_HISTORY_LOADED": {
            return {
                ...state,
                queryHistory: action.payload,
            }
        }
        default:
            break
    }
    return state
}

function loadQueryHistory(): AsyncAction<BundleActionType> {
    return (dispatch) => {
        getQueryHistory().then((history) => dispatch({ type: "QUERY_HISTORY_LOADED", payload: history }))
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadQueryHistory,
}
