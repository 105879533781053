import { Box, TextField } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useRef } from "react"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { EmailSchemaType, getEmailSchema } from "../../formSchemas/emailSchema"
import { useDebounceFormSubmit } from "../../hooks/useDebounceFormSubmit"

type AddEmailEntryProps = {
    id: string
    onEmailModified: (id: string, email: string) => void
    startingEmail?: string
    disabled?: boolean
}

export default function EmailEntry({ id, onEmailModified, startingEmail, disabled }: AddEmailEntryProps) {
    const { translateText } = useLocalization()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const emailSchema = useMemo(() => getEmailSchema(translateText), [translateText])

    const formMethods = useForm<EmailSchemaType>({
        resolver: zodResolver(emailSchema),
        defaultValues: {
            email: startingEmail,
        },
        mode: "all",
    })

    const {
        handleSubmit,
        control,
        formState: { errors, isValid, isSubmitting },
        watch,
    } = formMethods

    useEffect(() => {
        if (!disabled && !startingEmail && inputRef.current) {
            inputRef.current.focus()
        }
    }, [disabled, startingEmail, inputRef])

    const onSubmit = (data: EmailSchemaType) => {
        onEmailModified(id, data.email)
    }

    useDebounceFormSubmit(watch("email"), isValid, isSubmitting, () => {
        handleSubmit(onSubmit)()
    })

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ display: "flex" }}>
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            type="email"
                            placeholder={translateText(109)}
                            inputRef={inputRef}
                            onChange={(e) => field.onChange(e.target.value)}
                            error={!!errors.email}
                            sx={{ width: "100%", marginTop: "0px" }}
                        />
                    )}
                />
            </Box>
        </form>
    )
}
