import { StateCreator } from "zustand"
import _ from "lodash"
import { FastServiceStore } from "../.."
import { TyresWheelsSlice, TyresWheelsState } from "./model"
import { tabItems } from "./staticData"
import {
    completeCollapsible,
    selectTab,
    setSelectedTireType,
    setTyresCritsError,
    setTyresCritsLoaded,
    setTyresCritsLoading,
    setErrorTyres as setTyresError,
    setTyresLoaded,
    setTyresLoading,
    setTyreSpecification,
    updateCollapsibleData,
    updateDescription,
    updateSafetyStatus,
    updateSelectedFilters,
    updateShowBonus,
    updateSpareWheel,
    updateTyreWear,
} from "./actions"

function getInitialState(): TyresWheelsState {
    const clonedTabItems = _.cloneDeep(tabItems)
    return {
        tyresWheels: {
            tabs: clonedTabItems,
            selectedTab: clonedTabItems[0],
            tiresData: {
                frontTires: [],
                rearTires: [],
                loading: false,
            },
            tecDocUsed: 0,
            tireBrands: { suppliers: [] },
            loadingCrits: false,
            spareWheel: undefined,
            selectedTire: undefined,
            tyresCrits: {},
        },
    }
}

export const createTyresWheelsSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TyresWheelsSlice> = (set) => ({
    ...getInitialState(),
    tyresWheelsActions: {
        selectTab: (tab) => set((state) => selectTab(state, tab), false, "Set wheels selected tab"),
        completeCollapsible: (tabName: string, tabType: string, collapsibleName: string) =>
            set((state) => completeCollapsible(state, tabName, tabType, collapsibleName), false, "Complete collapsible"),
        setTyresLoading: () => set((state) => setTyresLoading(state), false, "Set tyres loading"),
        setTyresError: () => set((state) => setTyresError(state), false, "Set tyres error"),
        setTyresLoaded: (data, tecDocUsed, tiresBrands) =>
            set((state) => setTyresLoaded(state, data, tecDocUsed, tiresBrands), false, "Set tyres data"),
        updateSelectedFilters: (tabName, tabType, selectedFilters) =>
            set((state) => updateSelectedFilters(state, tabName, tabType, selectedFilters), false, "Update selected filters"),
        setSelectedTireType: (tireType) => set((state) => setSelectedTireType(state, tireType), false, "Set selected tire type"),
        updateCollapsibleData: (tabName, collapsibleName, data) =>
            set((state) => updateCollapsibleData(state, tabName, collapsibleName, data), false, "Update collapsible data"),
        setTyresCritsLoading: () => set((state) => setTyresCritsLoading(state), false, "Set tyres crits loading"),
        setTyresCritsLoaded: (tyresCrits) => set((state) => setTyresCritsLoaded(state, tyresCrits), false, "Set tyres crits"),
        setTyresCritsError: () => set((state) => setTyresCritsError(state), false, "Tyre crits error"),
        setTyreSpecification: (tyreSpecification) => set((state) => setTyreSpecification(state, tyreSpecification), false, "Set tyre specification"),
        updateSpareWheel: (hasSpareWheel) => set((state) => updateSpareWheel(state, hasSpareWheel), false, "Update has spare wheel"),
        updateShowBonus: (showBonus) => set((state) => updateShowBonus(state, showBonus), false, "Update show bonus"),
        updateSafetyStatus: (safetyStatus, path) => set((state) => updateSafetyStatus(state, safetyStatus, path), false, "Update safety status"),
        updateDescription: (description, path) => set((state) => updateDescription(state, description, path), false, "Update description"),
        updateTyreWear: (tyreWear, path) => set((state) => updateTyreWear(state, tyreWear, path), false, "Update tyre wear"),
        reset: () => set(getInitialState(), false, "Reset Tyres Wheels Slice"),
    },
})
