import { FastCalculatorBundleParams } from "@tm/models"

export const version = {
    name: "fast-calculator-next",
    version: "2.0.0",
}

let bundleParams: FastCalculatorBundleParams

/** Initial bundle parms */
export function initFastCalculatorBundle(params: FastCalculatorBundleParams) {
    bundleParams = params
    return bundleParams
}
export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The toolkit params have to be set in order to use the toolkit "${version.name}"`)
    }

    return bundleParams
}
