import { getStoredAuthorization, ajax } from "@tm/utils"
import { getAuthorityServiceUrl } from ".."
import { SaveDatCredentialsRequest, SaveErpCredentialsRequest } from "../model/externalCredentials"

export function setErpSettings(request: SaveErpCredentialsRequest) {
    const url = `${getAuthorityServiceUrl()}/external/credentials/SaveErpCredentials`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<void>({ url, authorization, body, method: "POST" })
}

export function setDatSettings(request: SaveDatCredentialsRequest) {
    const url = `${getAuthorityServiceUrl()}/external/credentials/SaveDatCredentials`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<void>({ url, authorization, body, method: "POST" })
}
