import { Channel, MessageBus } from "@tm/hermes"

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel("BUNDLE")
}

export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = { ADD_ORDER_VOUCHER_TO_BASKET: { newWorkTask: boolean } } & {
    ADD_COST_ESTIMATION_VOUCHER_TO_BASKET: { includeRepairTimes: boolean }
}
