import { AsyncAction } from "@tm/morpheus"
import { UserContext, channel } from "@tm/models"
import { UniversalTagBasedState } from "../../business"
import * as Data from "../../data/repositories/url"

type ComponentActionType =
    | { type: "URLS_LOADING" }
    | { type: "URLS_LOADED"; payload: Data.GetConceptPageUrlResponse & { description: string } }
    | { type: "SET_ACTIVE" }
    | { type: "CONCEPT_PAGE_CREDENTIALS_CHANGED" }

export type ComponentState = UniversalTagBasedState & {
    active?: boolean
    loading?: boolean
    url?: string
    mediaManager?: string
    logo?: string
    description?: string
    hasCredentials?: boolean
}

const DEFAULT_STATE: ComponentState = {}

export function reduce(state: ComponentState = DEFAULT_STATE, action: ComponentActionType): ComponentState {
    switch (action.type) {
        case "URLS_LOADING":
            return {
                ...state,
                loading: true,
            }
        case "URLS_LOADED":
            return {
                ...state,
                loading: false,
                ...action.payload,
            }

        case "SET_ACTIVE":
            return {
                ...state,
                active: true,
            }
        case "CONCEPT_PAGE_CREDENTIALS_CHANGED":
            return {
                ...state,
                active: false,
            }
    }
    return state
}

export function subscribeChannels(): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch) => {
        return channel("GLOBAL").subscribe("CONCEPT_PAGE/CREDENTIALS_CHANGED", () => dispatch({ type: "CONCEPT_PAGE_CREDENTIALS_CHANGED" }))
    }
}

function loadConceptPageUrls(userContext: UserContext): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        if (getState().active) {
            return
        }
        const extModule =
            userContext &&
            userContext.externalModules &&
            userContext.externalModules.find(
                (extModule: any) =>
                    extModule.parameter && extModule.parameter.some((param: any) => param.key === "DocumentTypeID" && param.value === "ConceptPage")
            )

        if (
            extModule &&
            extModule.parameter &&
            extModule.parameter.some((param: any) => param.key === "DocumentTypeID" && param.value === "ConceptPage")
        ) {
            dispatch({ type: "SET_ACTIVE" })
            dispatch({ type: "URLS_LOADING" })
            Data.getConceptPageUrl().then((urls) => {
                dispatch({ type: "URLS_LOADED", payload: { ...urls, description: extModule!.description } })
            })
        }
    }
}

export type IActions = typeof Actions

export const Actions = {
    subscribeChannels,
    loadConceptPageUrls,
}
