import { memo } from "react"
import { styled } from "@tm/components"
import { Checkbox, Text } from "@tm/controls"

type Props = {
    label: string
    info?: string
    selected?: boolean
    type?: string
    onChange?: () => void
    disabled?: boolean
    textModifier?: string
    blockModifier?: boolean
    compactStyle?: boolean
}

const FilterItem = styled("div")<{compactStyle: boolean}>(({ theme, compactStyle }) => ({
    display: "flex",
    ...(!compactStyle && {
        marginLeft: theme.spacing(2)
    })
}))

const TextWrapper = styled("div")<{compactStyle: boolean}>(({ theme, compactStyle }) => ({
    ...(compactStyle ? {
        marginLeft: "0.5em",
        color: "#727272"
    } : {
        marginLeft: theme.spacing(2)
    })
}))

const StyledText = styled(Text)<{compactStyle: boolean}>(({ compactStyle }) => ({
    ...(compactStyle && {
        color: "#727272"
    })
}))

export default memo(function SelectionCheckbox({ label, info, onChange, selected, blockModifier, compactStyle }: Props) {
    return (
        <FilterItem className={"is-clickable selection-list__item"} compactStyle>
            <Checkbox
                checked={selected}
                size="s"
                onToggle={onChange}
            />
            <TextWrapper onClick={onChange} compactStyle>
                <StyledText className="selection-list__item__value" modifiers={blockModifier && "block" || "strong"} size="s" compactStyle>{label}</StyledText>
                {info && <Text size="xs">&nbsp;{info}</Text>}
            </TextWrapper>
        </FilterItem>
    )
})
