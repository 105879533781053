import { useLocalization } from "@tm/localization"
import { Image } from "@tm/controls"

export default function AnalysisComponent() {
    const { translate } = useLocalization()

    return (
        <div className="analysis">
            <div className="box">
                <div className="box__headline">{translate(575)}</div>
                <div className="box__content analysis">
                    <div className="rating">3/5</div>
                    <Image
                        className="chart"
                        url="https://media.carparts-cat.com/webdata1/ActionsData/OffersModuleData/Pictures/driving-analysis.svg"
                    />
                    <div className="description">{translate(582)}</div>
                </div>
            </div>
        </div>
    )
}
