import { AsyncAction, ActionDispatch } from "@tm/morpheus"
import * as Data from "../../data"
import { BundleActionType, bundleReduce, BundleState } from "../../business"
import { ServiceEvent } from "../../data"

type CostEstimationWork = {
    timeCalculated: number
    description: string
}

type CostEstimation = {
    calculatedWorks: Array<CostEstimationWork>
}

export type ComponentActionType =
    | BundleActionType
    | { type: "STORING" }
    | { type: "STORED" }
    | { type: "SET_WORKTASK_ID"; payload: string }
    | { type: "COST_ESTIMATION_DETAILS_LOADED"; payload: CostEstimation }

export type ComponentState = BundleState & {
    loading?: boolean
    workTaskId?: string
    event?: ServiceEvent
    costEstimation?: CostEstimation
    storing?: boolean
    stored?: boolean
}

const DEFAULT_STATE: ComponentState = {}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "EVENT_CREATED":
        case "EVENT_LOADED":
        case "COST_ESTIMATION_DETAILS_LOADED": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ComponentState {
    state = bundleReduce(state, action as BundleActionType) as ComponentState
    switch (action.type) {
        case "STORING": {
            return {
                ...state,
                storing: true,
                stored: false,
            }
        }
        case "STORED": {
            return {
                ...state,
                storing: false,
                stored: true,
            }
        }
        case "SET_WORKTASK_ID": {
            return {
                ...state,
                workTaskId: action.payload,
            }
        }
        case "EVENT_CREATED":
        case "EVENT_LOADED": {
            return {
                ...state,
                event: action.payload,
            }
        }
        case "COST_ESTIMATION_DETAILS_LOADED": {
            return {
                ...state,
                costEstimation: action.payload,
            }
        }
        default:
            break
    }
    return state
}

function saveWork(): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const { event, costEstimation } = getState()
        if (!event || !costEstimation || !costEstimation.calculatedWorks || !costEstimation.calculatedWorks.length) {
            return
        }
        const proms = costEstimation.calculatedWorks.map((work) =>
            Data.createWorkshopTask({
                startDateTime: new Date(event.startDateTime.getTime() + 60 * 60 * 1000),
                workshopServiceId: 23,
                eventId: event.id!,
                workLoad: work.timeCalculated ? work.timeCalculated : undefined,
                description: work.description,
            }).catch(() => {})
        )

        dispatch({ type: "STORING" })
        Promise.all(proms)
            .then(() => {
                dispatch({ type: "STORED" })
            })
            .catch(() => {
                dispatch({ type: "STORED" })
            })
    }
}

export type IActions = typeof Actions

export const Actions = {
    saveWork,
}
