import { SortingFilterType, VehicleType } from "@tm/models"
import { useCallback } from "react"
import { atomFamily, useRecoilState, useSetRecoilState } from "recoil"

import { getBundleParams } from "../../utils"
import { CarBodyType, EngineCapacityFilter, EngineCodeFilter, FuelTypeFilter, ModelYearFilter } from "../model/filters"

export type Filters = {
    bodyTypeId?: number
    modelYear?: number
    fuelType?: string
    engineCode?: string
    engineCapacity?: number
    sorting?: SortingFilterType
}

const filtersRecoilAtom = atomFamily<Filters, VehicleType>({
    key: "vehicle_filters",
    default: {},
})

export function useFilters(vehicleType: VehicleType) {
    return useRecoilState(filtersRecoilAtom(vehicleType))
}

export function useSetFilters(vehicleType: VehicleType) {
    return useSetRecoilState(filtersRecoilAtom(vehicleType))
}

export function useResetFilters(vehicleType: VehicleType) {
    const setFilters = useSetFilters(vehicleType)
    return useCallback(() => {
        setFilters((prev) => ({ sorting: prev.sorting }))
    }, [setFilters])
}

export function getSorting(sorting: SortingFilterType | undefined) {
    return sorting || getBundleParams().sortingFilterDefault || "relevance"
}

type AvailableFilters = {
    bodyTypes: Array<CarBodyType>
    modelYears: Array<ModelYearFilter>
    fuelTypes: Array<FuelTypeFilter>
    engineCodes: Array<EngineCodeFilter>
    engineCapacities: Array<EngineCapacityFilter>
    sorting: boolean
}

const availableFiltersRecoilAtom = atomFamily<AvailableFilters, VehicleType>({
    key: "vehicle_availablefilters",
    default: {
        bodyTypes: [],
        modelYears: [],
        fuelTypes: [],
        engineCodes: [],
        engineCapacities: [],
        sorting: false,
    },
})

export function useAvailableFilters(vehicleType: VehicleType) {
    return useRecoilState(availableFiltersRecoilAtom(vehicleType))
}

export function useSetAvailableFilters(vehicleType: VehicleType) {
    return useSetRecoilState(availableFiltersRecoilAtom(vehicleType))
}
