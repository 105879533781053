import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { TableCellData, Icon, TableVariants, TableColumnData, CellContentPosition, LinkButton, Alert, Tooltip, Box } from "@tm/components"
import { createQueryString, renderRoute } from "@tm/utils"
import { CisVoucherType } from "@tm/models"
import { useParams } from "react-router"
import { getBundleParams } from "../../../utils"
import { NoResultHint } from "../NoResultHint"
import { CompletedOrderListItem, OpenOrderListItem } from "../../../data/model"
import { StyledTable } from "../StyledComponents"
import DateCell from "../cells/DateCell"
import { VoucherRouteProps } from "../../vouchers/component"

type Props = {
    cisVoucherType: CisVoucherType
    orders: Array<CompletedOrderListItem | OpenOrderListItem>
    singleSearch?: boolean
    variant: TableVariants
    vouchersLoadedMessage?: string
}

export default function StgOrdersTableComponent({ orders, singleSearch, vouchersLoadedMessage, cisVoucherType, variant }: Props) {
    const { translateText, translate, currency } = useLocalization()
    const { userSettings } = useUser()
    const { cisVoucherUrl } = getBundleParams()
    const matchParams = useParams<VoucherRouteProps>()
    const selectedVoucherId = matchParams.id || undefined
    const selectedVoucherSubId = matchParams.subId || undefined
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false
    const compact = !!matchParams.id

    function renderOrderNumber(order: CompletedOrderListItem | OpenOrderListItem) {
        return <>{order.orderNumber + (order.orderSubNumber ? ` - ${order.orderSubNumber}` : "")}</>
    }

    function renderCompactOrderType(order: CompletedOrderListItem | OpenOrderListItem) {
        return (
            <Tooltip title={order.orderTypeDescription} placement="left-start">
                <Box>{order.orderType}</Box>
            </Tooltip>
        )
    }

    function renderDeliveryNote(order: CompletedOrderListItem) {
        return <>{order.deliveryNote}</>
    }

    function renderDeliveryNoteDate(order: CompletedOrderListItem) {
        return <DateCell value={order.deliveryNoteDate} mode="onlyDate" />
    }

    function renderInvoice(order: CompletedOrderListItem) {
        return <>{order.invoice}</>
    }

    function renderInvoiceDate(order: CompletedOrderListItem) {
        return <DateCell value={order.invoiceDate} mode="onlyDate" />
    }

    function renderDetailsButton(order: CompletedOrderListItem | OpenOrderListItem) {
        const id = order.orderNumber
        const subId = order.orderSubNumber
        const voucherTypeId = order.voucherType.typeId
        let url = renderRoute(cisVoucherUrl, {
            ...matchParams,
            subpage: "vouchers",
            voucherTypeId,
            id,
            subId,
        })
        if (singleSearch) {
            url += createQueryString({ singleSearch: true })
        }
        if (selectedVoucherId && selectedVoucherId === id && selectedVoucherSubId && selectedVoucherSubId === subId) {
            url = renderRoute(cisVoucherUrl, { ...matchParams, voucherTypeId, id: null, subId: null })
        }
        return <LinkButton to={url} title={translateText(597)} startIcon={<Icon name="file-details" />} />
    }

    function getModuleColumns() {
        const moduleHeader: TableColumnData[] = [
            { header: translateText(1089) },
            { header: translateText(700) },
            { header: translateText(776) },
            { header: translateText(777) },
            { header: translateText(40) },
            { header: translateText(440) },
            { header: translateText(1249) },
            { header: translateText(cisVoucherType === CisVoucherType.ClosedOrders ? 766 : 12728) },
        ]

        if (cisVoucherType === CisVoucherType.ClosedOrders) {
            moduleHeader.push(
                { header: translateText(778) },
                { header: translateText(1137) },
                { header: translateText(779) },
                { header: translateText(1138) }
            )
        }
        if (!hidePurchasePrice) {
            moduleHeader.push(
                { header: `${translate(495)}*`, alignContent: CellContentPosition.right },
                { header: `${translate(494)}*`, alignContent: CellContentPosition.right }
            )
        }

        moduleHeader.push({ alignContent: CellContentPosition.right })

        return moduleHeader
    }

    function getCompactColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(1089) },
            { header: translateText(700) },
            { header: translateText(776) },
            { header: translateText(777), alignContent: CellContentPosition.center },
            { alignContent: CellContentPosition.right },
        ]
        return columns
    }

    function getWidgetColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(700) },
            { header: translateText(98) },
            { header: translateText(777), alignContent: CellContentPosition.center },
            { alignContent: CellContentPosition.right },
        ]
        return columns
    }

    function getColumns() {
        if (compact) {
            return getCompactColumns()
        }
        if (variant === "small") {
            return getWidgetColumns()
        }
        if (variant === "normal") {
            return getModuleColumns()
        }

        return []
    }

    function getModuleCellData(order: CompletedOrderListItem | OpenOrderListItem) {
        const cellData: TableCellData[] = [
            { displayValue: order.warehouseId, id: "order_0" },
            { displayValue: renderOrderNumber(order), id: "order_1" },
            { displayValue: <DateCell value={order.orderDate} mode="dateDashTime" />, id: "order_2" },
            { displayValue: order.orderTypeDescription, id: "order_4" },
            { displayValue: order.deliveryType, id: "order_5" },
            { displayValue: order.paymentInformation, id: "order_6" },
            { displayValue: order.customerOrderNumber, id: "order_7" },
            { displayValue: order.tour, id: "order_8" },
        ]
        if (cisVoucherType === CisVoucherType.ClosedOrders) {
            cellData.push(
                { displayValue: renderDeliveryNote(order as CompletedOrderListItem), id: "order_9" },
                { displayValue: renderDeliveryNoteDate(order as CompletedOrderListItem), id: "order_10" },
                { displayValue: renderInvoice(order as CompletedOrderListItem), id: "order_11" },
                { displayValue: renderInvoiceDate(order as CompletedOrderListItem), id: "order_12" }
            )
        }
        if (!hidePurchasePrice) {
            cellData.push(
                { displayValue: currency(order.totalNet || 0, order.currencyCode), id: "order_13" },
                { displayValue: currency(order.totalGross || 0, order.currencyCode), id: "order_14" }
            )
        }

        cellData.push({ displayValue: renderDetailsButton(order), id: "order_15" })
        return cellData
    }

    function getCompactCellData(order: CompletedOrderListItem | OpenOrderListItem) {
        const cellData: TableCellData[] = [
            { displayValue: order.warehouseId, id: "order_0" },
            { displayValue: renderOrderNumber(order), id: "order_1" },
            { displayValue: <DateCell value={order.orderDate} mode="dateDashTime" />, id: "order_2" },
            { displayValue: renderCompactOrderType(order), id: "order_3" },
            { displayValue: renderDetailsButton(order), id: "order_15" },
        ]
        return cellData
    }

    function getWidgetCellData(order: CompletedOrderListItem | OpenOrderListItem) {
        const cellData: TableCellData[] = [
            { displayValue: renderOrderNumber(order), id: "order_1" },
            { displayValue: <DateCell value={order.orderDate} mode="onlyDate" />, id: "order_2" },
            { displayValue: renderCompactOrderType(order), id: "order_3" },
            { displayValue: renderDetailsButton(order), id: "order_15" },
        ]
        return cellData
    }

    function getCells(order: CompletedOrderListItem | OpenOrderListItem) {
        let cells: TableCellData[] = []
        if (compact) {
            cells = getCompactCellData(order)
        } else if (variant === "small") {
            cells = getWidgetCellData(order)
        } else if (variant === "normal") {
            cells = getModuleCellData(order)
        }

        return cells
    }

    const displayData = orders?.map((order, index) => ({
        cells: getCells(order),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === order.orderNumber && selectedVoucherSubId === order.orderSubNumber,
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return (
        <>
            {vouchersLoadedMessage && !compact && variant === "normal" && <Alert title={vouchersLoadedMessage} severity="warning" />}
            <StyledTable
                columns={getColumns()}
                rows={displayData}
                variant={variant}
                headerStyle={variant === "small" && !compact ? "bold" : "default"}
                headerBackground={variant === "small" && !compact ? "default" : "paper"}
            // onScrollBottom={onLoadNextPage} TODO: When STG supports real paging (with page Index, not only page size) this can be enabled.
            />
        </>
    )
}
