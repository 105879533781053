import { Customer } from "@tm/models"
import { CustomerInfo } from "../../../../../../../data"

export function setCustomerInfoData(customer: Customer, customerInfo: CustomerInfo): Customer {
    return {
        ...customer,
        firstName: customerInfo.firstName || customer.firstName,
        lastName: customerInfo.lastName || customer.lastName,
        street: customerInfo.street || customer.street,
        addressAddition: customerInfo.addressAddition || customer.addressAddition,
        city: customerInfo.city || customer.city,
        zip: customerInfo.zip || customer.zip,
        state: customerInfo.state || customer.state,
        country: customerInfo.country || customer.country,
        email: customerInfo.emailAddress || customer.email,
        phone: customerInfo.phone || customer.phone,
    }
}
