import { useMemo, useState } from "react"
import { Button, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { openWorkTask } from "../../../../../bundle-channel"
import { CustomerInfo as CustomerInfoData } from "../../../../../data"
import { MergedVehicle } from "../../../../../data/hooks"
import { getBundleParams } from "../../../../../utils"
import { getSharedChatInfoStyles } from "../_shared-styles"
import { getComponentStyles } from "./styles"

type Props = {
    customer: CustomerInfoData
    vehicles: Array<MergedVehicle>
}

export default function CustomerInfo({ customer, vehicles }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const sharedClassNames = useMemo(() => getSharedChatInfoStyles(), [])
    const { translateText } = useLocalization()
    const [compact, setCompact] = useState(false)

    return (
        <>
            <div className={sharedClassNames.infoHeader}>
                <Text size="l" modifiers="strong">
                    {translateText(107)}
                </Text>
                <Button icon={compact ? "down" : "up"} layout={["ghost"]} onClick={() => setCompact((prev) => !prev)} />
            </div>

            {!compact && (
                <div className={classNames.area}>
                    <div className={classNames.row}>
                        <Text modifiers={["block", "sub"]} size="s">
                            {translateText(155)}
                        </Text>
                        <Text modifiers="block">
                            {customer.firstName} {customer.lastName}
                        </Text>
                    </div>

                    <div className={classNames.row}>
                        <Text modifiers={["block", "sub"]} size="s">
                            {translateText(109)}
                        </Text>
                        <Text modifiers="block">{customer.emailAddress}</Text>
                    </div>

                    {!!customer.phone && (
                        <div className={classNames.row}>
                            <Text modifiers={["block", "sub"]} size="s">
                                {translateText(110)}
                            </Text>
                            <Text modifiers="block">{customer.phone}</Text>
                        </div>
                    )}

                    {!!vehicles[0] && (
                        <Button
                            size="s"
                            className={classNames.row}
                            onClick={() =>
                                openWorkTask({ selectedChatVehicle: vehicles[0], subRoute: getBundleParams().customerDetailsRouteWithoutWorkTaskId })
                            }
                        >
                            {translateText(43)}
                        </Button>
                    )}
                </div>
            )}
        </>
    )
}
