import { useMemo } from "react"
import { RegistrationNoType, Vehicle, VehicleLookupConfig, VehicleType } from "@tm/models"
import { isLicensePlateType, isVinType } from "@tm/utils"
import { VehicleParams } from "../../search/component"
import { useSelectedVehicleLookup } from "../../../data/hooks/useSelectedLookupConfig"

type RegNoAndType = {
    regNoType?: RegistrationNoType
    regNo?: string
}

function getRegNoAndTypeFromQuery(query: string): RegNoAndType {
    const searchParams = new URLSearchParams(query)
    const regNo = searchParams.get(VehicleParams.RegNo) || undefined
    const regNoTypeSearch = searchParams.get(VehicleParams.RegNoType) || undefined

    if (regNoTypeSearch !== undefined && /\d+/.test(regNoTypeSearch)) {
        return {
            regNoType: parseInt(regNoTypeSearch),
            regNo,
        }
    }

    return { regNo }
}

export function useRegNoAndType(
    vehicleType: VehicleType,
    vehicle: Vehicle | undefined,
    query: string,
    defaultLookup?: VehicleLookupConfig
): RegNoAndType {
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicle?.vehicleType ?? vehicleType)

    return useMemo(() => {
        const queryParams = getRegNoAndTypeFromQuery(query)

        let regNo = vehicle?.registrationNo
        let regNoType = vehicle?.registrationTypeId

        // If the vehicle has a registration number but no type treat it as KBA number
        if (regNo && regNoType === undefined) {
            regNoType = RegistrationNoType.Kba
        }

        // Check the url query parameter
        if (regNoType === undefined && queryParams.regNoType) {
            regNoType = queryParams.regNoType
        }

        if (regNoType === undefined && selectedVehicleLookup.countryCode !== undefined && selectedVehicleLookup.lookupTypeId !== undefined) {
            regNoType = selectedVehicleLookup.lookupTypeId
        }

        // When the vehicle wasn't found with a specific vehicle lookup search
        // use the default configured vehicle search
        if (regNoType === undefined && defaultLookup) {
            regNoType = defaultLookup.lookupTypeId
        }

        if (isVinType(regNoType, regNo)) {
            regNo = vehicle?.vin
        } else if (isLicensePlateType(regNoType, regNo)) {
            regNo = vehicle?.plateId
        }

        if (!regNo && queryParams.regNo) {
            regNo = queryParams.regNo
        }

        return { regNoType, regNo }
    }, [vehicle, query, defaultLookup, selectedVehicleLookup])
}
