export type Attachment = {
    items: AttachmentItem[]
}

export type AttachmentItem = {
    fileAlias: string
    fileName: string
    fileType: EAttachmentTye
    id: string
}

export enum EAttachmentTye {
    Images,
    Reports,
    Undefined,
}
