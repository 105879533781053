import { Component } from "react"
import { Icon, DropdownField } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { withRouter, RouteComponentProps } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { Article } from "@tm/models"
import { Actions, HistoryState } from "./business"

type RouteParams = {
    supplierArticleNo?: string
    supplierId?: string
    productgroupId?: string
}

type Props = RouteComponentProps<RouteParams> &
    LocalizationProps & {
        state: HistoryState
    }

type State = {
    articleNameHistory: string[]
    articleHistory: Article[]
    pathHistory: string[]
    isOrigin: boolean
}

class HistoryComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            articleNameHistory: [],
            articleHistory: [],
            pathHistory: [],
            isOrigin: true,
        }
    }

    componentDidUpdate() {
        if (!this.props.state.detailsResponse || !this.props.state.detailsResponse.article) {
            return
        }
        if (JSON.stringify(this.state.articleHistory.last()) !== JSON.stringify(this.props.state.detailsResponse.article)) {
            this.getArticleHistory()
        }
    }

    handleClick() {
        this.navigateTo(0)
    }

    navigateTo(index: number) {
        this.props.history.push(this.state.pathHistory[index])
    }

    getItems() {
        const items: Record<string, string> = {}
        for (let i = 0; i < this.state.articleNameHistory.length; i++) {
            items[i.toString()] = this.state.articleNameHistory[i]
        }
        return items
    }

    getArticleHistory() {
        const {
            state: { detailsResponse },
            history,
        } = this.props
        const { articleNameHistory, isOrigin } = this.state

        if (!detailsResponse || !detailsResponse.article) {
            return
        }

        const { article } = detailsResponse
        const articleName = `${article.supplier.name} | ${article.productGroup.name} | ${article.supplierArticleNo}`
        const path = history.location.pathname + history.location.search

        if (!articleNameHistory.includes(articleName)) {
            this.setState((prevState) => ({
                articleNameHistory: [...prevState.articleNameHistory, articleName],
                articleHistory: [...prevState.articleHistory, article],
                pathHistory: [...prevState.pathHistory, path],
                isOrigin: false,
            }))
        } else {
            const shouldSetOrigin = articleName === articleNameHistory[0]
            if (isOrigin !== shouldSetOrigin) {
                this.setState({ isOrigin: shouldSetOrigin })
            }
        }
    }

    handleChange(item: number) {
        this.navigateTo(item)
    }

    render() {
        const icon = <Icon name="history" />

        const items = this.getItems()

        if (this.state.articleNameHistory.length > 1) {
            return (
                <div className="tk-parts-history">
                    {!this.state.isOrigin && (
                        <div className="origin" onClick={this.handleClick.bind(this)}>
                            <div className="icon-wrapper">
                                <Icon name="arrow-left" />
                            </div>
                            <div>{`${this.props.localization.translate(1624)}: ${this.state.articleNameHistory[0]}`}</div>
                        </div>
                    )}
                    <DropdownField className="history" size="xl" noWrap label={icon} items={items} onChange={this.handleChange.bind(this)} />
                </div>
            )
        }

        return null
    }
}

export default connectComponent(Actions, withRouter(withLocalization(HistoryComponent)))
