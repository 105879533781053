import { ErpInformation, PriceType } from "@tm/models"
import { ICalculationItem, Work } from "../models"

export const sumItemsPrices = (items: ICalculationItem[], erpArticles?: ErpInformation[]) =>
    items
        .filter((x) => x.isSelected)
        .reduce((accumulator, item) => {
            let price = item.oeArticle.oePriceValue ?? 0

            if (item.selectedPart) {
                let associatedErpArticle

                if (erpArticles) {
                    associatedErpArticle = erpArticles.find((x) => x.dataSupplierArticleNumber === item.selectedPart!.supplierArticleNo)
                }

                if (associatedErpArticle) {
                    price = associatedErpArticle?.prices?.find((x) => x.type === PriceType.Retail)?.value ?? 0

                    if (!price || price === 0) {
                        price = associatedErpArticle?.prices?.find((x) => x.type === PriceType.Purchase)?.value ?? 0
                    }
                } else if (item.selectedPart) {
                    price = item.selectedPart.prices?.find((x) => x.priceType === PriceType.Retail)?.value ?? 0

                    if (!price) {
                        price = item.selectedPart.prices?.find((x) => x.priceType === PriceType.Purchase)?.value ?? 0
                    }
                }
            }
            return accumulator + price * (item.selectedPart?.quantity ?? item.oeArticle.quantityValue)
        }, 0)

export const sumWorksPrices = (works: Work[]): number =>
    works.reduce((accumulator: number, work: Work) => {
        if (work.isSelected) {
            return !work.total ? accumulator : accumulator + work.total
        }

        return accumulator
    }, 0)
