import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetHourlyRates(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { userSettings } = this.props
    const { getHourlyRates } = data

    if (source && getHourlyRates) {
        if (userSettings?.hourlyRates) {
            if (getHourlyRates.workCategoryId) {
                let hourlyRate = userSettings.hourlyRates.find((rate) => rate.category === getHourlyRates.workCategoryId)
                if (!hourlyRate) {
                    hourlyRate = userSettings.hourlyRates.find((rate) => rate.category == undefined)
                }
                source.postMessage({ hourlyRate }, "*")
            } else {
                source.postMessage({ hourlyRates: userSettings.hourlyRates }, "*")
            }
        } else {
            source.postMessage({ hourlyRatesNotInitialized: true }, "*")
        }
    }
}
