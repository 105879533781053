import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, receive } from "../_shared/article-references-business"

const articleReferencesManufacturers: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "article-references-manufacturers",
    reduce,
    component,
    receive,
}

export default articleReferencesManufacturers
