import { useStyle } from '@tm/context-distribution'
import { Button, InputGroup, SearchField, Text } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import * as React from 'react'
import { getCurrentSeason } from '../../data/helpers'
import { getBundleParams } from '../../utils'

type Props = {
	value: string
	onButtonClick(value: string): void
	withTitle?: boolean
}

const MatchCodeSearch: React.FC<Props> = ({ value, onButtonClick, withTitle }) => {
	const { translateText } = useLocalization()
	const [searchValue, setValue] = React.useState(value)
	React.useEffect(() => setValue(value), [value])
	const handleSubmit = (value: string) => value && onButtonClick(value)
	const { useNewNavbar } = getBundleParams()

	const getSeasonPrefix = React.useCallback(() => getCurrentSeason(true), [])
	
	return (
		<div className={style.matchCode}>
			{withTitle && <Text modifiers="sub">{translateText(1233)}</Text>}
			<InputGroup className={style.inputGroup}>
				<SearchField
					value={searchValue}
					showClear={true}
					placeholder={getSeasonPrefix() + "2055516 91H"}
					tooltip="2055516, 2055516 91H, S2055516"
					onChange={setValue}
					onChangeConfirm={handleSubmit}
					forceShowTooltipOnHover={true}
					className={useNewNavbar ? style.searchField : undefined}
				/>
				<Button
					onClick={() => handleSubmit(searchValue)}
					icon="search"
					disabled={!searchValue}
					className={style.searchButton}
				/>
			</InputGroup>
		</div>
	)
}
const style = useStyle({
	inputGroup: {
		height: "2em"
	},
	matchCode: {
		minWidth: "12em"
	},
	searchField: {
		width: "22.4em"
	},
	searchButton: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		width: "3.2em !important"
	}
})(MatchCodeSearch)

export default MatchCodeSearch
