import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { DataDocumentResponse } from "./model"

export const getDatDocument = async (contractId: string): Promise<string> => {
    const url = `${getServiceUrl()}/GetDatContent`
    const authorization = getStoredAuthorization()
    const body = {
        contractId,
    }

    return ajax<DataDocumentResponse>({ url, body, method: "POST", authorization }).then((response) => {
        if (!response?.content) {
            throw new Error()
        }

        return response?.content
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.datCalculationServiceUrl
}
