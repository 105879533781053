import { useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Button, WarningPrompt, Checkbox, Loader } from "@tm/controls"
import { Compilation } from "../../../../../data"
import { useDeleteCompilations } from "../../../../../data/hooks/useCompilations"

type Props = {
    compilation: Compilation
    isEditing: boolean
    isSelected: boolean
    onShowHideEditMode(compilation: Compilation): void
    onUpdateCompilation(compilation: Compilation): void
    onOpenCompilationDetails(id: string): void
    onSelectCompilation(id: string): void
}

export default function CompilationActions(props: Props) {
    const { translateText } = useLocalization()
    const dialogRef = useRef<WarningPrompt>(null)
    const { deleteCompilations } = useDeleteCompilations()
    const [compilationWillBeRemoved, setCompilationWillBeRemoved] = useState(false)

    const { isEditing, compilation } = props

    const handleRemoveCompilation = () => {
        setCompilationWillBeRemoved(true)
        dialogRef.current?.show()
    }

    const handleWarningClose = () => {
        setCompilationWillBeRemoved(false)
    }

    const handleWarningConfirm = () => {
        deleteCompilations([compilation.id])
    }

    const handleCompilationCheckboxToggle = () => {
        props.onSelectCompilation(compilation.id)
    }

    const handleDetailsClick = () => {
        props.onOpenCompilationDetails(compilation.id)
    }

    const handleConfirmButtonClick = () => {
        props.onUpdateCompilation(compilation)
    }

    const handleEditModeToggle = () => {
        props.onShowHideEditMode(compilation)
    }

    return (
        <div className="compilation-actions">
            {isEditing ? (
                <>
                    <Button title={translateText(70)} onClick={handleEditModeToggle} size="s" icon="close" className="custom-part__button" />
                    <Button
                        title={translateText(9)}
                        onClick={handleConfirmButtonClick}
                        size="s"
                        icon="check"
                        skin="success"
                        className="custom-part__button"
                    />
                </>
            ) : (
                <>
                    <Button onClick={handleDetailsClick} size="s">
                        {translateText(43)}
                    </Button>
                    <Button
                        className="edit-position__button"
                        title={translateText(122)}
                        onClick={handleEditModeToggle}
                        icon="edit_change"
                        size="s"
                        layout={["ghost"]}
                        disabled={!compilation.isOwn}
                    />
                    <Button
                        title={translateText(624)}
                        onClick={handleRemoveCompilation}
                        icon={!compilationWillBeRemoved ? "delete" : undefined}
                        size="s"
                        layout={["ghost"]}
                        disabled={!compilation.isOwn}
                    >
                        {compilationWillBeRemoved && <Loader visible delay={0} />}
                    </Button>
                    <WarningPrompt
                        text={translateText(1313)}
                        confirmationButtonText={translateText(585)}
                        cancelButtonText={translateText(584)}
                        ref={dialogRef}
                        onClose={handleWarningClose}
                        onConfirm={handleWarningConfirm}
                    />
                    <Checkbox disabled={!compilation.isOwn} checked={props.isSelected} onToggle={handleCompilationCheckboxToggle} />
                </>
            )}
        </div>
    )
}
