import { SubmitSelectionRequest } from "./model"

export function createSubmitSelectionRequest(calcId: string, eTag: string, items: string[], selectionId: string): SubmitSelectionRequest {
    return {
        calcId,
        eTag,
        selectionItemIds: items,
        selectionId,
    }
}
