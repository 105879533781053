import { Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { CustomPart, channel } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { useParams } from "react-router"
import { renderRoute } from "@tm/utils"
import { useBasketImports } from "../../../../../basket/src/hooks/basketState/useBasketImports"
import { useDrivemotiveStore } from "../../../data/state"
import { ArticleForOe } from "../../../data/models/calculation"
import { createImportCalculationRequest, mapCustomParts } from "../../../data/helpers/mappers"

export default function CalculationButton() {
    const { translateText } = useLocalization()
    const { importFastCalculation } = useBasketImports()
    const { workTask } = useWorkTask() || {}
    const works = useDrivemotiveStore((state) => state.calculation.calculationWorks)
    const hourlyRate = useDrivemotiveStore((state) => state.summary.hourRate)
    const vehicle = useDrivemotiveStore((state) => state.summary.selectedBooking?.vehicle)
    const oeProductGroups = useDrivemotiveStore((state) => state.calculation.calculationArticles)
    const importArticles: ArticleForOe[] = []
    const customParts: CustomPart[] = []
    const matchParams = useParams<{ workTaskId?: string; view?: string }>()

    const handleButtonClick = () => {
        if (workTask) {
            oeProductGroups?.forEach((oeProductGroup) => {
                oeProductGroup.articles.forEach((importArticle) => {
                    if (importArticle.article?.einspNr && importArticle.article?.einspNr > 0) {
                        importArticles.push(importArticle)
                    } else {
                        customParts.push(mapCustomParts(importArticle, oeProductGroup.label))
                    }
                })
            })

            const history = Morpheus.getHistory()
            const costEstimationRoute = "/:workTaskId/cost-estimation"
            const costEstimationUrl = renderRoute(costEstimationRoute, matchParams)
            const request = createImportCalculationRequest(
                workTask,
                importArticles,
                vehicle?.vehicle_type.type_id.toString(),
                works,
                hourlyRate,
                customParts
            )

            if (request) {
                importFastCalculation(request).then(
                    () => {
                        channel("APP").publish("TOAST_MESSAGE/SHOW", {
                            message: translateText(12998),
                            icon: "check",
                            skin: "success",
                            closeDelay: 3000,
                        })

                        history.push(costEstimationUrl)
                    },
                    () => {
                        channel("APP").publish("TOAST_MESSAGE/SHOW", {
                            message: translateText(12997),
                            icon: "error",
                            skin: "warning",
                            closeDelay: 3000,
                        })
                    }
                )
            }
        }
    }

    return (
        <Button color="highlight" size="extralarge" endIcon={<Icon name="voucher-add-kva" />} onClick={handleButtonClick}>
            {translateText(1607)}
        </Button>
    )
}
