import { ShowBikeModelDetailsResponse, ShowCarModelDetailsResponse, ShowTruckModelDetailsResponse, Vehicle } from "@tm/models"
import { ModelState, validateField } from "@tm/utils"
import { isDocumentRelated, isFieldAvailable } from "."
import { ExternalDocument } from "../../../../data/model"
import { DocumentRequest, EDocumentIds } from "../model"

export const validate = (
    docRequest: DocumentRequest,
    documents: ExternalDocument[],
    translateText: (_: number) => string,
    vehicle?: Vehicle,
    carModel?: ShowCarModelDetailsResponse & ShowBikeModelDetailsResponse & ShowTruckModelDetailsResponse,
    vehicleDataIsMandatory?: boolean
): ModelState => {
    let modelState = {}
    if (!docRequest) {
        return modelState
    }

    const documentNamesIds = documents.map((x) => x.documentTypeId)
    const seletedDocumentId = documentNamesIds.first()
    const modelStateAdresses = {
        // adresses
        "supplierInfo.postalAddress":
            documentNamesIds.includes(EDocumentIds.GVA) && validateField(docRequest.supplierInfo.postalAddress).min(1, translateText(289)).messages,
        "repairShopInfo.postalAddress":
            documentNamesIds.includes(EDocumentIds.GVA) && validateField(docRequest.repairShopInfo.postalAddress).min(1, translateText(289)).messages,
        "traderInfo.postalAddress":
            documentNamesIds.includes(EDocumentIds.GVA) && validateField(docRequest.traderInfo.postalAddress).min(1, translateText(289)).messages,
        "customerInfo.postalAddress":
            (documentNamesIds.includes(EDocumentIds.ADR) || documentNamesIds.includes(EDocumentIds.Returns)) &&
            validateField(docRequest.customerInfo.postalAddress).min(1, translateText(289)).messages,
    }

    const modelStateVehicle = {
        // vehicledata
        "receitInfo.number": validateField(docRequest.receitInfo.number).min(1, translateText(289)).messages,
        // "articleDetails.articleNr": validateField(docRequest.articleDetails.articleNr).min(1, translateText(289)).messages,
        // "receitInfo.date": validateField(docRequest.receitInfo.date).min(1, translateText(289)).messages
        ...(vehicle &&
            (vehicleDataIsMandatory == undefined || vehicleDataIsMandatory) && {
                "vehicleInfo.vinNr": validateField(docRequest.vehicleInfo.vinNr).min(17, translateText(289)).messages,
                "vehicleInfo.firstRegistrationYear": validateField(docRequest.vehicleInfo.firstRegistrationYear).min(1, translateText(289)).messages,
                "vehicleInfo.partsInstallationDate": validateField(docRequest.vehicleInfo.partsInstallationDate).min(1, translateText(289)).messages,
                "vehicleInfo.installationMileage": validateField(docRequest.vehicleInfo.installationMileage).min(1, translateText(289)).messages,
                "vehicleInfo.partsDeinstallationDate": validateField(docRequest.vehicleInfo.partsDeinstallationDate).min(1, translateText(289))
                    .messages,
                "vehicleInfo.deinstallationMileage": validateField(docRequest.vehicleInfo.deinstallationMileage).min(1, translateText(289)).messages,
                ...(carModel &&
                    carModel.modelDetails.engineCodes &&
                    carModel.modelDetails.engineCodes.length !== 0 && {
                        "vehicleInfo.motorCode": validateField(docRequest.vehicleInfo.motorCode).min(1, translateText(289)).messages,
                    }),
            }),
        ...(vehicleDataIsMandatory && {
            "vehicleInfo.vehicleType": validateField(docRequest.vehicleInfo.vehicleType).min(1, translateText(289)).messages,
            "vehicleInfo.kw": validateField(docRequest.vehicleInfo.kw).min(1, translateText(289)).messages,
            "vehicleInfo.hp": validateField(docRequest.vehicleInfo.hp).min(1, translateText(289)).messages,
            "vehicleInfo.keyNumber": validateField(docRequest.vehicleInfo.keyNumber).min(1, translateText(289)).messages,
            "vehicleInfo.ccm": validateField(docRequest.vehicleInfo.ccm).min(1, translateText(289)).messages,
            "vehicleInfo.fuelTypeId": validateField(docRequest.vehicleInfo.fuelTypeId).required(translateText(289)).messages,
            "vehicleInfo.transmissionType": validateField(docRequest.vehicleInfo.transmissionType).min(1, translateText(289)).messages,
        }),
    }

    const modelStateCompliant = {
        ...(isFieldAvailable("complainInfo.claimDescription") && {
            "complainInfo.claimDescription": validateField(docRequest.complainInfo.claimDescription).min(1, translateText(289)).max(590, "").messages,
        }),
        "complainInfo.errorOccurance": validateField(docRequest.complainInfo.errorOccurance).required(translateText(289)).messages,
        "complainInfo.isAdditionalCosts": validateField(docRequest.complainInfo.isAdditionalCosts).required(translateText(289)).messages,
    }

    const modelStateKosten = {
        // kostenauftellung
        "additionalCostsInfo.repairmentDay": validateField(docRequest.additionalCostsInfo.repairmentDay).required(translateText(289)).messages,
        "additionalCostsInfo.referenceNumber": validateField(docRequest.additionalCostsInfo.referenceNumber).required(translateText(289)).messages,
        "additionalCostsInfo.vehicleOwner": validateField(docRequest.additionalCostsInfo.vehicleOwner).required(translateText(289)).messages,
        "additionalCostsInfo.additionalParts": validateField(docRequest.additionalCostsInfo.additionalParts.length).required(translateText(289))
            .messages,
        ...(vehicle &&
            (vehicleDataIsMandatory == undefined || vehicleDataIsMandatory) && {
                "vehicleInfo.firstRegistrationYear": validateField(docRequest.vehicleInfo.firstRegistrationYear).min(1, translateText(289)).messages,
                "vehicleInfo.deinstallationMileage": validateField(docRequest.vehicleInfo.deinstallationMileage).min(1, translateText(289)).messages,
            }),
    }

    const modelStateBatterie = {
        // baterie
        "batteryInfo.deliveryNoteInformation.number": validateField(docRequest.batteryInfo.deliveryNoteInformation.number).min(1, translateText(289))
            .messages,
        "batteryInfo.deliveryNoteInformation.date": validateField(docRequest.batteryInfo.deliveryNoteInformation.date).min(1, translateText(289))
            .messages,
        "batteryInfo.siteOfUse": validateField(docRequest.batteryInfo.siteOfUse).required(translateText(289)).messages,
        "batteryInfo.batteryDetectedVoltage": validateField(docRequest.batteryInfo.batteryDetectedVoltage).required(translateText(289)).messages,
        "batteryInfo.batteryDetectedTime": validateField(docRequest.batteryInfo.batteryDetectedTime).required(translateText(289)).messages,
        "batteryInfo.generatorDetectedVoltageWithLoad": validateField(docRequest.batteryInfo.generatorDetectedVoltageWithLoad).required(
            translateText(289)
        ).messages,
        "batteryInfo.generatorDetectedVoltageWithoutLoad": validateField(docRequest.batteryInfo.generatorDetectedVoltageWithoutLoad).required(
            translateText(289)
        ).messages,
        "batteryInfo.completionAtAcknowledgement": validateField(docRequest.batteryInfo.completionAtAcknowledgement).required(translateText(289))
            .messages,
        "batteryInfo.completionAtRejection": validateField(docRequest.batteryInfo.completionAtRejection).required(translateText(289)).messages,
        "batteryInfo.durationOfDistance": validateField(docRequest.batteryInfo.durationOfDistance).required(translateText(289)).messages,
        "batteryInfo.batteryPlacement": validateField(docRequest.batteryInfo.batteryPlacement).required(translateText(289)).messages,
        "batteryInfo.purposeOfUse": validateField(
            docRequest.batteryInfo.purposeOfUseStart || docRequest.batteryInfo.purposeOfUseSupplyDrive
        ).required(translateText(289)).messages,
        "batteryInfo.enableDeinstallationCost": validateField(docRequest.batteryInfo.enableDeinstallationCost).required(translateText(289)).messages,
    }

    const modelStateReturns = {
        // waren
        "returnsInfo.returnType": validateField(docRequest.returnsInfo.returnType).required(translateText(289)).messages,
        ...((!docRequest.returnsInfo.returnType || docRequest.returnsInfo.returnType == 1) && {
            "returnsInfo.isProductResalable": validateField(docRequest.returnsInfo.isProductResalable).required(translateText(289)).messages,
        }),
        ...((!docRequest.returnsInfo.returnType || docRequest.returnsInfo.returnType == 1) && {
            "returnsInfo.completionAtReturn": validateField(docRequest.returnsInfo.completionAtReturn).required(translateText(289)).messages,
        }),
        "returnsInfo.additionalParts": validateField(docRequest.returnsInfo.additionalParts.length).required(translateText(289)).messages,
    }

    const modelStateOldBatteries = {
        "oldBatteriesInfo.oldBatteryTypeList": validateField(docRequest.oldBatteriesInfo.oldBatteryTypeList.some((x) => x.quantity)).required(
            translateText(1663)
        ).messages,
    }

    documents.forEach((doc) => {
        switch (doc.documentTypeId) {
            case EDocumentIds.GVA: {
                modelState = {
                    ...modelState,
                    ...modelStateAdresses,
                    ...modelStateVehicle,
                    ...modelStateCompliant,
                }
                break
            }
            case EDocumentIds.CostStatement: {
                const shouldHideCostStatementError =
                    isDocumentRelated(doc.externalSystemId, documents) &&
                    ((seletedDocumentId == EDocumentIds.GVA && modelStateCompliant["complainInfo.isAdditionalCosts"].length) ||
                        (seletedDocumentId == EDocumentIds.Batterie && modelStateBatterie["batteryInfo.enableDeinstallationCost"].length))
                if (shouldHideCostStatementError) {
                    break
                }
                modelState = {
                    ...modelState,
                    ...modelStateKosten,
                }
                break
            }
            case EDocumentIds.Batterie: {
                modelState = {
                    ...modelStateAdresses,
                    ...modelStateVehicle,
                    ...modelStateBatterie,
                }
                break
            }
            case EDocumentIds.Returns: {
                modelState = {
                    ...modelState,
                    ...modelStateAdresses,
                    ...modelStateReturns,
                }
                break
            }
            case EDocumentIds.ADR: {
                modelState = {
                    ...modelState,
                    ...modelStateAdresses,
                    ...modelStateOldBatteries,
                }
                break
            }
            default:
                break
        }
    })

    return modelState
}
