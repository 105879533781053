import { Box, Divider, Stack, Typography } from "@tm/components"
import { Warehouse } from "@tm/models"
import ImageWithBadge from "./image-with-badge"

type Props = {
    warehouses: Warehouse[]
    title?: string
}

export default function WarehouseAvailabilityImagesWithBadge({ warehouses, title }: Props) {
    const controls: JSX.Element[] = []

    warehouses.forEach((w) => {
        const icon = w.quantities?.[0]?.availability?.icon
        const imageUrl = icon?.useIconFromUrl ? icon.url : undefined

        if (imageUrl) {
            const quantity = w.quantities?.reduce((v, q) => v + (q.value || 0), 0) || 0

            controls.push(<ImageWithBadge quantities={quantity} imageUrl={imageUrl} />)
        }
    })

    return (
        <Stack className="warehouse-availability-images-with-badge">
            {title && (
                <Box>
                    <Typography variant="label">{title}</Typography>
                    <Divider light />
                </Box>
            )}
            <Stack mt={1} direction="row">
                {controls}
            </Stack>
        </Stack>
    )
}
