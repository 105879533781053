import { PayloadAction, AsyncAction, ActionDispatch } from "@tm/morpheus"
import { CustomerShortInfo, FindCustomersRequest } from "@tm/models"
import { CustomerListState } from "./model"
import { CustomerRepository } from "../../../data"
import { BundleActionType } from "../../../business"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "SEARCH_STARTED"; payload: FindCustomersRequest }
    | { type: "SEARCH_FAILED" }
    | { type: "SEARCH_SUCCEED"; payload: { options: FindCustomersRequest; list: Array<CustomerShortInfo> } }
    | { type: "PAGE_LOADED"; payload: { options: FindCustomersRequest; list: Array<CustomerShortInfo> } }
    | { type: "SELECT_CUSTOMER"; payload: CustomerShortInfo | undefined }

const DEFAULT_STATE: CustomerListState = {
    initialized: false,
    loading: false,
    endOfList: false,

    options: {
        query: undefined,
        pageIndex: 1,
        pageSize: 25,
        orderBy: "lastname",
        orderByDescending: false,
    },

    selected: undefined,
    list: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): CustomerListState {
    switch (action.type) {
        case "SEARCH_STARTED": {
            return {
                ...state,
                initialized: true,
                loading: true,
                options: action.payload,
            }
        }
        case "SEARCH_SUCCEED": {
            return {
                ...state,
                loading: false,
                options: action.payload.options,
                endOfList: (action.payload.options.pageSize || 20) > action.payload.list.length,
                list: action.payload.list,
            }
        }
        case "SEARCH_FAILED": {
            return {
                ...state,
                loading: false,
            }
        }
        case "PAGE_LOADED": {
            return {
                ...state,
                loading: false,
                options: action.payload.options,
                endOfList: (action.payload.options.pageSize || 20) > action.payload.list.length,
                list: state.list.concat(action.payload.list),
            }
        }
        case "SELECT_CUSTOMER": {
            return {
                ...state,
                selected: action.payload,
            }
        }
        case "CUSTOMER_DELETED": {
            const pos = state.list.findIndex((x) => x.id === action.payload.id)
            if (pos >= 0) {
                return {
                    ...state,
                    list: state.list.filter((x) => x.id !== action.payload.id),
                    selected: state.selected?.id === action.payload.id ? undefined : state.selected,
                }
            }
            break
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "CUSTOMER_DELETED": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

export function transmit(action: PayloadAction<ComponentActionType>) {
    switch (action.type) {
        case "VIEW": {
            return action
        }
        default:
            break
    }
}

let searchRequestCount = 0
function search(options: FindCustomersRequest): AsyncAction<ComponentActionType, CustomerListState> {
    return (dispatch, getState) => {
        const state = getState()
        const isPaging = state.options.pageIndex === (options.pageIndex || 0) - 1

        dispatch({ type: "SEARCH_STARTED", payload: options })

        const requestNumber = ++searchRequestCount
        return CustomerRepository.findCustomers(options).then(
            (result) => {
                if (searchRequestCount === requestNumber) {
                    if (isPaging) {
                        dispatch({
                            type: "PAGE_LOADED",
                            payload: {
                                options,
                                list: result?.customers || [],
                            },
                        })
                    } else {
                        dispatch({
                            type: "SEARCH_SUCCEED",
                            payload: {
                                options,
                                list: result?.customers || [],
                            },
                        })
                    }
                }
            },
            () => dispatch({ type: "SEARCH_FAILED" })
        )
    }
}

function start(query?: string): AsyncAction<ComponentActionType, CustomerListState> {
    return (dispatch, getState) => {
        const state = getState()
        dispatch({ type: "VIEW", payload: { view: "LIST", id: state.selected ? state.selected.id : undefined } })

        if (!state.initialized || state.options.query !== query) {
            dispatch(
                search({
                    ...state.options,
                    pageIndex: 1,
                    query,
                })
            )
        }
    }
}

function loadNextPage(): AsyncAction<ComponentActionType, CustomerListState> {
    return (dispatch, getState) => {
        const state = getState()
        const { loading, endOfList } = state

        if (loading || endOfList) {
            return
        }

        const options: FindCustomersRequest = {
            ...state.options,
            pageIndex: (state.options.pageIndex || 0) + 1,
        }

        dispatch(search(options))
    }
}

function sortList(orderBy: string, orderByDescending: boolean): AsyncAction<ComponentActionType, CustomerListState> {
    return (dispatch, getState) => {
        const state = getState()
        const options = {
            ...state.options,
            pageIndex: 1,
            orderBy,
            orderByDescending,
        }
        dispatch(search(options))
    }
}

function selectCustomer(customer?: CustomerShortInfo): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "SELECT_CUSTOMER", payload: customer })
        dispatch({ type: "VIEW", payload: { view: "LIST", id: customer ? customer.id : undefined } })
    }
}

function deleteCustomer(customer: CustomerShortInfo): AsyncAction<ComponentActionType, CustomerListState> {
    return (dispatch) => {
        CustomerRepository.hideCustomer(customer.id).then(() => dispatch({ type: "CUSTOMER_DELETED", payload: customer }))
    }
}

export type IActions = typeof Actions

export const Actions = {
    start,
    search,
    loadNextPage,
    sortList,
    selectCustomer,
    deleteCustomer,
}
