import { stylesheet, style } from "typestyle"

export const colorDarkGray = "#c3c3c3"

export const fileDropClassName = style({
    marginTop: "1rem",
    padding: "5em",
    height: "30vh",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
    background: "#f6f6f6",
})

export function getComponentStyles() {
    return stylesheet({
        inputForm: { display: "flex", flexDirection: "row", alignItems: "center" },
        inputFormActionWrapper: { display: "flex", flexDirection: "row", position: "absolute", right: "0", paddingRight: "1.5em" },
        inputFormSendButton: { padding: "0px", $nest: { ".icon": { height: "25px", width: "25px", opacity: "1" } } },
        documentInfoWrapper: {
            display: "flex",
            flexDirection: "column",
            borderTop: `solid 1px ${colorDarkGray}`,
            marginTop: "1em",
            paddingTop: "1em",
        },
        filePreviewWrapper: { display: "flex", alignItems: "center", flexDirection: "column" },
        filePreviewContainer: {
            border: `1px solid ${colorDarkGray}`,
            borderRadius: "3px",
            padding: "2em",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            boxShadow: "1px 1px 1px -1px black",
        },
    })
}

export const textOpacity = style({
    opacity: ".54",
})
