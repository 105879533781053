import { useCallback } from "react"
import { selectorFamily, useSetRecoilState, DefaultValue } from "recoil"
import { OrderResponseGroup } from "@tm/models"
import {
    getNextOrderGroupsAndCurrentlyUpdatingPartIds,
    useSharedWorkTaskOrderReloadListeners,
} from "../../data/hooks/shared/useSharedWorkTaskOrderReloadListeners"
import { CentralOrderWorkTaskState, centralOrderWorkTasksAtom } from "../../data/hooks/centralOrder/useCentralOrderWorkTasks"

const centralOrderStateByWorkTaskSelector = selectorFamily<CentralOrderWorkTaskState | undefined, string>({
    key: "basket_centralOrderStateByWorkTaskSelector",
    get:
        (workTaskId) =>
        ({ get }) =>
            get(centralOrderWorkTasksAtom).workTasks.find((w) => w.workTask.workTaskId === workTaskId),
    set:
        (workTaskId) =>
        ({ set }, newValue) => {
            if (!newValue || newValue instanceof DefaultValue) {
                return
            } // TODO?

            set(centralOrderWorkTasksAtom, (prevState) => {
                const foundState = prevState.workTasks.find((w) => w.workTask.workTaskId === workTaskId)
                if (foundState) {
                    return {
                        ...prevState,
                        workTasks: prevState.workTasks.map((w) => {
                            if (w.workTask.workTaskId === workTaskId) {
                                return newValue
                            }
                            return w
                        }),
                    }
                }

                console.warn("basket_centralOrderStateByWorkTaskSelector: tried to set state of an unknown workTask")
                return prevState
            })
        },
})

export function useCentralOrderStateByWorkTask(workTaskId: string) {
    const setState = useSetRecoilState(centralOrderStateByWorkTaskSelector(workTaskId))

    const toggleIncludedInOrder = useCallback(
        (value?: boolean) => {
            setState((prev) => prev && { ...prev, includedInOrder: value ?? !prev?.includedInOrder })
        },
        [setState]
    )

    const toggleSelected = useCallback(
        (value?: boolean) => {
            setState((prev) => prev && { ...prev, selected: value ?? !prev?.selected })
        },
        [setState]
    )

    const toggleDisabled = useCallback(
        (value?: boolean) => {
            setState((prev) => prev && { ...prev, disabled: value ?? !prev?.disabled })
        },
        [setState]
    )

    const setSucceededOrderGroups = useCallback(
        (succeededOrderGroups?: OrderResponseGroup[]) => {
            setState((prev) => prev && { ...prev, succeededOrderGroups })
        },
        [setState]
    )

    const setOrderGroupsAndCurrentlyUpdatingPartIds = useCallback(
        (getNext: getNextOrderGroupsAndCurrentlyUpdatingPartIds) => {
            setState(
                (prev) => prev && { ...prev, ...getNext({ orderGroups: prev.orderGroups, currentlyUpdatingPartIds: prev.currentlyUpdatingPartIds }) }
            )
        },
        [setState]
    )

    const setHasBasketErros = useCallback(
        (hasBasketErrors?: boolean) => {
            setState((prev) => prev && { ...prev, hasBasketErrors })
        },
        [setState]
    )

    useSharedWorkTaskOrderReloadListeners(workTaskId, setOrderGroupsAndCurrentlyUpdatingPartIds)

    return { toggleIncludedInOrder, toggleSelected, toggleDisabled, setSucceededOrderGroups, setHasBasketErros }
}
