import { ArticleAttributes } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { TyreArticle, TyreArticleAttributes } from "../../models"
import { GetArticleListByMatchCodeResponse, GetArticlesListMappedResponse } from "./model"

export function mapTireArticlesResponse(response: GetArticleListByMatchCodeResponse): GetArticlesListMappedResponse {
    return {
        ...response,
        uniArticles: response.uniArticles.length > 0 ? response.uniArticles.map((x) => mapTireArticleToArticle(x, response.defaultQuantity)) : [],
    }
}

export function mapTireArticleToArticle(item: TyreArticle, defaultQuantity: number): TyreArticle {
    return {
        ...item,
        quantity: defaultQuantity ?? 4,
        id: uniqueId(),
        internalId: +item.id,
        initialQuantity: 4,
        attributes: mapArticleAttributes(item),
        lastUpdate: item.lastUpdate ? new Date(item.lastUpdate) : undefined,
        vehicleInformation: item.vehicleInformation || []
    }
}

function mapArticleAttributes(data: any): TyreArticleAttributes[] {
    if (!data) {
        return []
    }

    const attributes: TyreArticleAttributes = {
        topAttributes: data.attributes || [],
        articleAttributes: data.articleAttributes || [],
        vehicleAttributes: data.vehicleAttributes || [],
        partsListAttributes: data.partsListAttributes || []
    }
    return [attributes]
}
