import { useMemo } from "react"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { ChatInfo } from "../../../../../../data/hooks"
import { getBundleParams } from "../../../../../../utils"
import NewMessageBadge from "../../../../../_shared/new-message-badge"
import PreviewText from "../../../../../_shared/preview-text"
import { getComponentStyles } from "./styles"

type Props = {
    chat: ChatInfo
    lastReadInfoDate: Date | undefined
    onClose(): void
}

export function MessageInfo({ chat, lastReadInfoDate, onClose }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()

    const isNew = chat.lastMessage?.inserted && lastReadInfoDate && new Date(chat.lastMessage.inserted) > lastReadInfoDate

    return (
        <div className={classNames.messageInfo}>
            <NewMessageBadge isNew={!!isNew} />

            <div className={classNames.wrapper}>
                <PreviewText chat={chat} size="m" className={classNames.previewText} bold={!!isNew} />
                <Button
                    layout={["holo"]}
                    className={classNames.messageInfoDetailsButton}
                    onClick={onClose}
                    linkTo={renderRoute(getBundleParams().chatDetailsRoute, { chatId: chat.chatId })}
                >
                    {translateText(3079)}
                </Button>
            </div>
        </div>
    )
}
