import { Box, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { HeaderItem } from "./HeaderItem"
import { Search } from "../search/Search"
import { UploadButton } from "../uploadButton/UploadButton"

export function Header() {
    const { translateText } = useLocalization()

    return (
        <Box display="flex" flexDirection="row" gap="1em">
            <HeaderItem hideDivider marginRight="1em">
                <Typography variant="h2">VeFiles</Typography>
            </HeaderItem>
            <HeaderItem title={translateText(135)}>
                <Search />
            </HeaderItem>
            <HeaderItem title={translateText(177)}>
                <UploadButton />
            </HeaderItem>
        </Box>
    )
}
