import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit } from "./business"
import component from "./wrapper"

const detailsHead: BundleComponent<unknown, typeof component> = {
    name: "details-head",
    reduce,
    component,
    transmit,
}

export default detailsHead
