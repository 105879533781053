import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import * as React from "react"
import { getRdSession } from "../../utils"
import { getEurotaxRDArticles } from "../../data/helpers"


type Props = IMicros["standalone"]["rd-eurotax-openOeArticleList"]


const OpenEurotaxOeArticleList: React.FC<Props> = ({ part, text, className, getGroups, btnRef, isActive, vehicle, hideOePrice }) => {
    const { translateText } = useLocalization()

    const handlePartSelect = () => {
        const items = getEurotaxRDArticles(part, getGroups?.() ?? [], translateText, hideOePrice)

        const data = {
            sessionId: getRdSession(),
            method: "openOEArticleList",
            sourceId: "TM_Eurotax",
            oeArticles: items,
            manufacturerName: vehicle?.manufacturer,
            title: translateText(628),
        }

        window.parent.postMessage(data, "*")
    }

    return (
        <Button
            ref={btnRef}
            fakeButton
            className={className}
            onClick={handlePartSelect}
            isActive={isActive}>
            {text}
        </Button>
    )
}


export default OpenEurotaxOeArticleList

