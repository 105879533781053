import { useCallback, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Skeleton, Text } from "@tm/controls"
import { useSelection } from "@tm/utils"
import CompilationCreator from "./components/creator"
import CompilationsList from "./components/list"
import CompilationsSelection from "./components/selection"
import { Compilation } from "../../../../data"
import { isWM } from "../../../../utils"
import { sortByCreatedAtDesc } from "../../../../utils/time"

type Props = {
    compilations: Array<Compilation> | undefined
    loading: boolean
    error: string | undefined
    showCreator: boolean
    compact: boolean
    onHideCreator(): void
    openCompilationDetails(id: string): void
    openCompilationList(): void
}

export default function CompilationsListComponent(props: Props) {
    const { translate } = useLocalization()

    const getAllIds = useCallback(() => props.compilations?.filter((x) => x.isOwn).map((x) => x.id) || [], [props.compilations])
    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllIds)

    useEffect(() => {
        unselectAll()
    }, [props.compilations])

    const { compilations, compact } = props

    const renderCompilationsSelection = () => {
        if (!compact && compilations?.length) {
            if (selectedIds.length > 0) {
                const ownCompilations = compilations.filter((compilation) => compilation.isOwn)
                const allSelected = ownCompilations.length === selectedIds.length

                return (
                    <div className="compilations-selection">
                        <CompilationsSelection
                            allSelected={allSelected}
                            selectedCompilationIds={selectedIds}
                            onSelectAll={selectAll}
                            onUnselectAll={unselectAll}
                        />
                    </div>
                )
            }
        }
    }

    const renderEmpty = () => {
        return (
            <Text size="l" className="empty-text">
                {translate(1284)}
            </Text>
        )
    }

    if (props.error) {
        return (
            <Text size="l" className="empty-text">
                {props.error}
            </Text>
        )
    }

    if (isWM() && compact) {
        return null
    }

    if (props.loading) {
        return (
            <div
                className={`tk-compilations compilations-list ${compact ? "compilations-list--compact" : "compilations-list--full-width"}`}
                style={{ paddingTop: "1rem" }}
            >
                <Skeleton variant="text" height="30px" />
                <Skeleton variant="text" height="63px" />
                <Skeleton variant="text" height="63px" />
                <Text />
            </div>
        )
    }

    return (
        <div className={`tk-compilations compilations-list ${compact ? "compilations-list--compact" : "compilations-list--full-width"}`}>
            {renderCompilationsSelection()}
            {props.showCreator && !compact && <CompilationCreator onClose={props.onHideCreator} />}
            {!compilations || compilations.length === 0 ? (
                renderEmpty()
            ) : (
                <CompilationsList
                    compilations={compilations.sort(sortByCreatedAtDesc)}
                    onOpenCompilationDetails={props.openCompilationDetails}
                    onSelectCompilation={toggleSelected}
                    selectedCompilationIds={selectedIds}
                    compact={compact}
                    openCompilationList={props.openCompilationList}
                    showCreator={props.showCreator}
                    onShowCreator={props.onHideCreator}
                />
            )}
        </div>
    )
}
