import { useState } from "react"
import { Box, Button, Icon, Stack, Tooltip, Typography, styled, tooltipClasses } from "@tm/components"
import { ArticleInfoType, IMicros } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useMicro } from "@tm/morpheus"
import { BasketPart } from "../../models"

type Props = {
    hideVehicleLogos?: boolean
    part: BasketPart
    replacedPartInfo?: string
    onOpenArticleSearch?(query: string): void
}

export default function PartTypeComponent({ part, hideVehicleLogos, replacedPartInfo, onOpenArticleSearch }: Props) {
    const { articleInformation, foundBySearchTerm, vehicleId } = part.partItem
    const { articleInfoType } = articleInformation
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const [openVehicleInfo, setOpenVehicleInfo] = useState<boolean>(false)

    function handleOpenArticleSearchClick() {
        if (foundBySearchTerm) {
            onOpenArticleSearch?.(foundBySearchTerm)
        }
    }

    function renderPartSearchLink() {
        return (
            <Stack spacing={1} p={0.5}>
                <Typography variant="label" textTransform="uppercase">
                    {translateText(241)}
                </Typography>
                <Typography variant="body2" fontWeight="bold">
                    {translateText(775)} "{foundBySearchTerm}"
                </Typography>
                <Typography variant="body2">{translateText(840)}</Typography>
                <Button onClick={handleOpenArticleSearchClick}>{translateText(389)}</Button>
            </Stack>
        )
    }

    if (articleInfoType === ArticleInfoType.OeArticle) {
        return (
            <Tooltip title={translateText(803)} variant="light">
                <Button startIcon={<Icon name="oe-reference" />} variant="text" />
            </Tooltip>
        )
    }
    if (articleInfoType === ArticleInfoType.CustomArticle) {
        return (
            <Tooltip title={translateText(757)} variant="light">
                <Button startIcon={<Icon name="individual-article" />} variant="text" />
            </Tooltip>
        )
    }
    if (articleInfoType === ArticleInfoType.TecdocArticle || articleInfoType === ArticleInfoType.WholesalerArticle) {
        if (replacedPartInfo) {
            return (
                <Tooltip
                    title={
                        <Box>
                            <Typography fontWeight="bold" variant="body2">
                                {translateText(1510)}:
                            </Typography>
                            <Typography variant="body2">{replacedPartInfo}</Typography>
                        </Box>
                    }
                    variant="light"
                >
                    <Button startIcon={<Icon name="replace-confirmed" />} variant="text" />
                </Tooltip>
            )
        }
        if (foundBySearchTerm) {
            return (
                <Tooltip title={renderPartSearchLink()} variant="light">
                    <Box padding="6px">
                        <Icon size="" name="articlesearch" />
                    </Box>
                </Tooltip>
            )
        }
        if (vehicleId) {
            return (
                <Tooltip
                    title={<>{renderMicro("crm", "vehicle-info", { vehicleId, hideLogos: hideVehicleLogos || false })}</>}
                    variant="light"
                    disableHoverListener
                    open={openVehicleInfo}
                    onClickAway={() => setOpenVehicleInfo(false)}
                >
                    <Button startIcon={<Icon name="vehicle-reference" />} variant="text" onClick={() => setOpenVehicleInfo(true)} />
                </Tooltip>
            )
        }
    }

    return <></>
}
