import { Component, PureComponent } from "react"
import { Button, ButtonLayout, Dropdown, TextField } from "@tm/controls"
import { bindSpecialReactMethods } from "@tm/utils"

type Props = {
	items: string[]
	selectedValue?: string
	defaultItem?: string
	withResetButton?: boolean
	disabled?: boolean
	onChange?(value: string): void
	hasInput?: boolean
	loading?: boolean
	label?: string
	layout?: ButtonLayout[]
	className?: string
	maxItemsToShow?: number
}

type State = {
	inputValue: string
}

export default class CustomDropdown extends Component<Props, State> {

	dropdownRef: Dropdown<DropDownItemType> | null
	inputRef: TextField | null
	constructor(props: Props) {
		super(props)
		bindSpecialReactMethods(this)
		this.state = { inputValue: "" }
		this.dropdownRef = null
		this.inputRef = null
	}

	componentDidMount() {
		this.forceUpdate() // to set initial refs
	}

	handleDropdownOpen() {
		setTimeout(() => this.inputRef && this.inputRef.focus(), 100)
	}

	handleDropDownChange(dropDownItem: DropDownItemType) {
		if (dropDownItem.index == -1)
			dropDownItem.value = ""
		this.props.onChange && this.props.onChange(dropDownItem.value)
	}

	handleInputConfirm(value: string) {
		const { items } = this.props
		const selectedItem = items.find(x => x == value)
		this.setState({ inputValue: "" })
		selectedItem && this.props.onChange && this.props.onChange(selectedItem)
	}

	handleBlurOnTextField() {
		if (this.dropdownRef && this.dropdownRef.state.open)
			this.dropdownRef.toggleDropdownMenu()

	}

	renderDropDownInput() {
		const { label } = this.props

		return (
			<TextField
				label={label}
				floatingLabel={true}
				value={this.state.inputValue}
				ref={(ref) => this.inputRef = ref}
				layout={["holo"]}
				preventConfirmOnBlur
				onBlur={this.handleBlurOnTextField}
				preventBubbling
				onChange={(value) => this.setState({ inputValue: value })}
				onChangeConfirm={this.handleInputConfirm}
			/>
		)
	}

	render() {
		const { items, defaultItem, className, selectedValue, withResetButton, disabled, hasInput, loading, layout, maxItemsToShow } = this.props
		const { inputValue } = this.state

		const visibleItems = items.filter((x) => !inputValue || x.includes(inputValue))
		const dropDownItems = mapStringArrayToDropdownItems(visibleItems)

		if (defaultItem)
			dropDownItems.unshift({ value: defaultItem, index: -1 }) // add default item into array

		const foundItem = dropDownItems.find((item) => item.value == selectedValue)
		const selectedItem = foundItem || dropDownItems.first()

		return (
			<>
				<Dropdown
					className={className}
					ref={(ref) => { this.dropdownRef = ref }}
					disabled={!items.length || disabled}
					items={loading ? [] : dropDownItems}
					itemView={DropDownItemView}
					layout={layout}
					onDropdownOpen={this.handleDropdownOpen}
					amountItemsToShow={maxItemsToShow || 10}
					value={selectedItem}
					onChange={this.handleDropDownChange}
					inputView={hasInput ? this.renderDropDownInput : undefined}
				/>
				{withResetButton && <Button
					layout={["ghost"]}
					disabled={!selectedValue || !items.length || disabled}
					size="s"
					icon="synchronize"
					onClick={this.handleDropDownChange.bind(this, { value: "", index: -1 })} />}
			</>
		)
	}
}

class DropDownItemView extends PureComponent<DropDownItemType> {
	render() {
		return <div style={{ textAlign: "center" }}>{this.props.value}</div>
	}
}

type DropDownItemType = {
	value: string
	index: number
}

function mapStringArrayToDropdownItems(items?: string[]): DropDownItemType[] {
	if (!items)
		return []
	return items.map((value, index) => ({ value, index }))
}
