import { useWorkTask } from "@tm/context-distribution"
import { ActiveVehicleDataProviders, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { encodeUniqueId } from "@tm/utils"
import { useEffect, useState } from "react"

export function useActiveVehicleDataProvider() {
    const [currentProviders, setCurrentProviders] = useState<ActiveVehicleDataProviders>()
    const { workTaskId } = useWorkTask() ?? {}

    useEffect(() => {
        if (!workTaskId) {
            return
        }
        const container = Container.getInstance(RegisteredModels.ViewState)
        const subscription = container.subscribe(`${encodeUniqueId(workTaskId)}__ACTIVE_VEHICLE_DATA_PROVIDERS`)

        const unregisterViewStateLoaded = subscription.addListener("loaded", (response) => {
            setCurrentProviders(response.value)
        })
        const unregisterViewStateUpdated = subscription.addListener("updated", (response) => {
            setCurrentProviders(response.value)
        })

        subscription.load()
        return () => {
            unregisterViewStateLoaded()
            unregisterViewStateUpdated()
        }
    }, [workTaskId])

    return currentProviders
}
