import { AsyncAction } from "@tm/morpheus"
import { ajax } from "@tm/utils"
import { UniversalTagBasedState, BundleActionType, reduceUniversalTagBaseState, BundleActions } from "../../business"

type ComponentActionType = BundleActionType | { type: "GENERATED_URL_LOADING" } | { type: "GENERATED_URL_LOADED"; payload: string }

export type ComponentState = UniversalTagBasedState & {
    generatedUrl?: string
}

const DEFAULT_STATE: ComponentState = {}

export function reduce(state: ComponentState = DEFAULT_STATE, action: ComponentActionType) {
    state = reduceUniversalTagBaseState(state, action as BundleActionType)
    switch (action.type) {
        case "GENERATED_URL_LOADED": {
            return {
                ...state,
                generatedUrl: action.payload,
                loading: false,
            }
        }
        case "GENERATED_URL_LOADING": {
            return {
                ...state,
                generatedUrl: undefined,
                loading: true,
            }
        }
    }
    return state
}

function getGeneratedUrl(): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const { url } = getState()
        if (!url) {
            return
        }
        dispatch({ type: "GENERATED_URL_LOADING" })
        ajax({ url }).then((generatedUrl) => {
            dispatch({ type: "GENERATED_URL_LOADED", payload: generatedUrl })
        })
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    getGeneratedUrl,
}
