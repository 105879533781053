import { useQuery } from "react-query"
import * as Data from "../../.."
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"
import { CalculateBonusPointsRequest } from "../../../model/CalculateBonusPoints"
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"

const KEY = "basket_useCalculateBonusPoints"

export function useCalculateBonusPointsData(workTaskId: string, request: CalculateBonusPointsRequest) {
    const bonusPointsCalculationIndex = useWorkTaskBasketStore((store) => store.getBonusPointsCalculationIndex(workTaskId))
    const QUERY_KEY = [KEY, workTaskId, bonusPointsCalculationIndex]
    const { hasWorkTaskOrder } = useBasketModuleParameter()

    const { data, isLoading, isError, isFetching } = useQuery(
        QUERY_KEY,
        () => {
            return Data.calculateBonusPoints(request)
        },
        {
            enabled: hasWorkTaskOrder && !!request.bonusPointItemsToCalculate?.length,
            staleTime: 10 * 60 * 1000, // 10 Minutes
        }
    )

    return {
        bonusPointsCalculation: data,
        bonusPointsLoading: isLoading || isFetching,
        bonusPointsWithErrors: isError,
    }
}
