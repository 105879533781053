import { YqVehicleDetails } from "../../../models"
import { useEdsStore } from "../store"

export function setYQVehicle(vehicle: YqVehicleDetails) {
    useEdsStore.setState((state) => ({
        vehicle: {
            ...state.vehicle,
            isLoading: false,
            error: undefined,
            data: {
                ...state.vehicle.data,
                yqVehicle: vehicle,
            },
        },
    }))
}

export function setVin(newValue: string) {
    useEdsStore.setState((state) => ({
        vehicle: {
            ...state.vehicle,
            data: {
                vin: newValue,
            },
        },
    }))
}

export function setLoading(loading: boolean) {
    useEdsStore.setState((state) => ({
        vehicle: {
            ...state.vehicle,
            isLoading: loading,
            error: undefined,
        },
    }))
}

export function setError(errorMessage: number | string | undefined) {
    useEdsStore.setState((state) => ({
        vehicle: {
            ...state.vehicle,
            isLoading: false,
            error: errorMessage,
        },
    }))
}
