import { Redirect } from "react-router"
import { withRouter, renderRoute, RouteComponentProps } from "@tm/utils"

type Props = RouteComponentProps & {
    route: string
}

function RedirectComponent(props: Props) {
    return <Redirect to={renderRoute(props.route, { ...props.match.params })} />
}

export default withRouter(RedirectComponent)
