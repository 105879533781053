import { ArticleIdentifier, IMicros, OE, RequestArticleDetailsPayload } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Box, NumberSelect } from "@tm/components"
import { ReactElement, ReactNode } from "react"
import { ReplaceButton } from "@tm/controls"
import { toolkitPath } from "../../../utils"
import { ExtendedOePart, Part } from "../../../data/models"
import { managerSelector } from "../../main/business"

type Props = {
    oeArticle: ExtendedOePart
    isActive: boolean
    showAlternativeBtn?: boolean
    selectedPart?: Part
    isSelected?: boolean
    onAlternativeArticleQuantityChange(quantity: number): void
    onOEQuantityChange?(quantity: number): void
    onDetailsClick(part: Part, request: RequestArticleDetailsPayload): void
    onSelect(): void
    onAlternativeClick(): void
}

export default function MainArticle({
    oeArticle,
    showAlternativeBtn,
    onAlternativeClick,
    onDetailsClick,
    onOEQuantityChange,
    onAlternativeArticleQuantityChange,
    onSelect,
    isSelected,
    selectedPart,
}: Props) {
    const { renderMicro } = useMicro<IMicros>()
    const { vehicle } = useSelector(managerSelector)
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    const renderOeActions = () => {
        return renderBasketActions(true)
    }

    const renderAlternativeActions = () => {
        return renderBasketActions(false)
    }

    const renderBasketActions = (isOePart: boolean) => {
        const handleOnChange = (value: number) => {
            if (isOePart && onOEQuantityChange) {
                return onOEQuantityChange(value)
            }

            return onAlternativeArticleQuantityChange(value)
        }

        return (
            <Box className="tk-basket">
                {oeArticle.isExtraArticle && (
                    <Box className="add-to-basket">
                        <Box sx={{ display: "flex", alignItems: "center", width: "11.5em" }} />
                    </Box>
                )}
                {!oeArticle.isExtraArticle && (
                    <Box display="flex" gap="3%" mb=".3em" marginRight={isWm ? "none" : "1em"}>
                        <NumberSelect minAmount={0} value={oeArticle.quantityValue} onValueChange={handleOnChange} />
                        <ReplaceButton disabled={!showAlternativeBtn} onClick={onAlternativeClick} isWm={isWm} />
                    </Box>
                )}
            </Box>
        )
    }

    const renderDefaultHeader = (): ReactNode => {
        const oePart: OE.OePart = {
            description: oeArticle.description,
            manufacturerId: vehicle?.tecDocManufacturerId,
            ...(!oeArticle.isExtraArticle && {
                manufacturerName: vehicle?.manufacturer,
            }),
            ...(oeArticle.isExtraArticle && {
                manufacturerName: "SG",
            }),
            number: oeArticle.oeArticleNumber,
            prices: [
                {
                    value: oeArticle.oePriceValue ?? 0,
                    currency: "EUR",
                },
            ],
            ...(oeArticle.isExtraArticle && {
                thumbnailUrl: `${toolkitPath}/images/Saint-Gobain.png`,
            }),
        }

        return renderMicro!("parts", "oe-part-item", {
            oePart,
            renderBasketButton: renderOeActions,
            hiddenFakeActions: { costEstimation: true, selector: true, moreMenu: true, details: true },
            onArticleSelect: onSelect,
            isSelected,
        })
    }

    const renderAlternativeHeader = (selectPart: Part): ReactNode => {
        const art: ArticleIdentifier = {
            productGroupId: selectPart.article?.productGroup.id ?? selectPart.productGroupId,
            supplierArticleNo: selectPart.article?.supplierArticleNo ?? selectPart.dataSupplierArticleNumber,
            supplierId: selectPart.article?.supplier.id ?? selectPart.dataSupplierId,
            tecdocTypeNo: vehicle?.tecDocTypeId,
        }

        const handleArticleDetails = (request: RequestArticleDetailsPayload) => {
            onDetailsClick(selectPart, request)
        }

        return renderMicro!("parts", "part-item", {
            partToLoad: art,
            renderBuyActions: renderAlternativeActions,
            onRequestArticleDetails: handleArticleDetails,
            // hiddenFakeActions: { costEstimation: true, selector: true, moreMenu: true, details: true },
            onArticleSelect: onSelect,
            isSelected,
            showActions: true,
        } as any)
    }

    if (selectedPart) {
        return renderAlternativeHeader(selectedPart) as ReactElement
    }

    return renderDefaultHeader() as ReactElement
}
