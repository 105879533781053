import { EFilterNames, TyreFilter } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { batch } from "react-redux"
import { BundleActions, BundleActionTypes } from "../../../business"
import { Repositories } from "../../../data"
import { RimCertification, WheelSelectionSteps } from "../../../data/enums"
import { mapRimItemForOverview } from "../../../data/helpers"
import { HSCombination, Restriction, RimDetailsArticle, TireSize } from "../../../data/model"
import { MainState } from "../../main"
import { createRimDetArticlesRequest, createRimRestrictionRequest, createTireRestrictionRequest, createTireSizesRequest } from "./helper"
import { WheelsDetailsState } from "./model"
import { saveConfiguratorImage } from "../../../data/repositories/wheels-saveConfiguratorImage"
import { getBundleParams } from "../../../utils"
import { Fixture } from "../../../data/repositories/wheels-loadRimFixtures/model"

export * from "./model"

export type ComponentActionType = BundleActionTypes
    | { type: "RIM_DETAILS_ARTICLES_LOADING" }
    | { type: "RIM_DETAILS_ARTICLES_LOADED", payload: { result: Record<string, RimDetailsArticle[]>, selectedRimItem: RimDetailsArticle | undefined } }
    | { type: "RIM_DETAILS_ARTICLES_ERROR" }

    | { type: "RIM_INFO_TIRE_RESTRICTIONS_LOADING" }
    | { type: "RIM_INFO_TIRE_RESTRICTIONS_LOADED", payload: Restriction[] }
    | { type: "RIM_INFO_TIRE_RESTRICTIONS_ERROR" }

    | { type: "SELECT_RIM_DETAILS_ARTICLE", payload: RimDetailsArticle }

    | { type: "SELECT_RIM_DETAILS_SIZE", payload: string }
    | { type: "SELECT_TIRE_SIZE", payload: TireSize }

    | { type: "CHANGE_IMAGE_DISPLAY" }
    | { type: "CHANGE_RIM_DET_ARTICLE_QUANTITY", payload: { rimDetailsArticle: RimDetailsArticle, quantity: number } }

    | { type: "RIM_DETAILS_AND_RESTRICTIONS", payload: { rimRestrictions: Restriction[], tireSizes: TireSize[], fixture?: Fixture, rimId?: number, sswHsCombinations?: Record<string, HSCombination[]> } }
    | { type: "RIM_DETAILS_AND_RESTRICTIONS_LOADING" }

export const WHEELS_DETAILS_DEFAULT_STATE: WheelsDetailsState = {
    initialized: false,
    rimDetailsArticlesResponse: {
        data: {}
    },
    rimInfoRimRestrictionsResponse: {
        data: []
    },
    rimInfoTireRestrictionsResponse: {
        data: []
    },
    rimInfoTireSizesResponse: {
        data: []
    },
    displayImage: true,
    selectedFilters: {}
}

export function reduce(state = WHEELS_DETAILS_DEFAULT_STATE, action: ComponentActionType): WheelsDetailsState {
    switch (action.type) {
        case "SEND_RIM_ARTICLE_TO_DETAILS": {
            return {
                ...state,
                rimItem: action.payload.rimItem,
                configuratorImage: action.payload.configuratorImage,
                selectedFilters: action.payload.selectedFilters,
                selectedSize: action.payload.size,
                selectedArticleFromState: undefined,
                initialized: false
            }
        }
        case "RIM_DETAILS_ARTICLES_LOADING": {
            return {
                ...state,
                rimDetailsArticlesResponse: {
                    data: WHEELS_DETAILS_DEFAULT_STATE.rimDetailsArticlesResponse.data,
                    loading: true
                },
                rimInfoTireSizesResponse: {
                    ...state.rimInfoTireSizesResponse,
                    loading: true
                },
                selectedRimDetailsArticle: WHEELS_DETAILS_DEFAULT_STATE.selectedRimDetailsArticle
            }
        }
        case "RIM_DETAILS_ARTICLES_LOADED": {
            const { result, selectedRimItem } = action.payload

            return {
                ...state,
                rimDetailsArticlesResponse: {
                    loading: false,
                    data: result
                },
                ...selectedRimItem && {
                    selectedRimDetailsArticle: selectedRimItem
                },
                initialized: true
            }
        }
        case "RIM_DETAILS_ARTICLES_ERROR": {
            return {
                ...state,
                rimDetailsArticlesResponse: {
                    loading: false,
                    data: WHEELS_DETAILS_DEFAULT_STATE.rimDetailsArticlesResponse.data,
                    error: true
                },
                rimInfoTireSizesResponse: {
                    ...state.rimInfoTireSizesResponse,
                    loading: false
                },
            }

        }
        case "RIM_INFO_TIRE_RESTRICTIONS_LOADING": {
            return {
                ...state,
                rimInfoTireRestrictionsResponse: {
                    loading: true,
                    data: WHEELS_DETAILS_DEFAULT_STATE.rimInfoTireRestrictionsResponse.data,
                }
            }
        }
        case "RIM_INFO_TIRE_RESTRICTIONS_LOADED": {
            return {
                ...state,
                rimInfoTireRestrictionsResponse: {
                    loading: false,
                    data: action.payload,
                },
            }
        }
        case "RIM_INFO_TIRE_RESTRICTIONS_ERROR": {
            return {
                ...state,
                rimInfoTireRestrictionsResponse: {
                    error: true,
                    loading: false,
                    data: WHEELS_DETAILS_DEFAULT_STATE.rimInfoTireRestrictionsResponse.data,
                },
            }
        }
        case "SELECT_RIM_DETAILS_SIZE": {
            return {
                ...state,
                selectedSize: action.payload,
                selectedArticleFromState: WHEELS_DETAILS_DEFAULT_STATE.selectedArticleFromState
            }
        }
        case "SELECT_RIM_DETAILS_ARTICLE": {
            return {
                ...state,
                selectedRimDetailsArticle: action.payload
            }
        }
        case "SELECT_TIRE_SIZE": {
            return {
                ...state,
                selectedTireSize: action.payload
            }
        }
        case "CHANGE_IMAGE_DISPLAY": {
            return {
                ...state,
                displayImage: !state.displayImage
            }
        }
        case "CHANGE_RIM_DET_ARTICLE_QUANTITY": {
            const { rimDetailsArticle, quantity } = action.payload
            let rimDetailsArticles = { ...state.rimDetailsArticlesResponse.data }

            Object.entries(rimDetailsArticles).forEach(([key, value]) =>
                rimDetailsArticles[key] = value.map(item => ({ ...item, quantity: item.idRim == rimDetailsArticle.idRim ? quantity : item.quantity })))

            return {
                ...state,
                rimDetailsArticlesResponse: {
                    ...state.rimDetailsArticlesResponse,
                    data: rimDetailsArticles
                }
            }
        }
        case "RIM_DETAILS_AND_RESTRICTIONS": {
            const { rimRestrictions, tireSizes, fixture, rimId, sswHsCombinations } = action.payload

            const selectedTireSize = tireSizes.find(x => x.tyreSize == state.savedTireSize) ?? tireSizes[0]
            let selectedRimDetailsArticle: RimDetailsArticle | undefined = state.selectedRimDetailsArticle
            const updatedData = Object.fromEntries(
                Object.entries(state.rimDetailsArticlesResponse.data).map(([key, value]) => [
                    key,
                    value.map(item => {
                        if (item.idRim === rimId) {
                            selectedRimDetailsArticle = { ...item, fixture: fixture }
                            return { ...item, fixture: fixture }
                        }
                        return item
                    })
                ])
            )

            return {
                ...state,
                ...state.rimItem && sswHsCombinations && {
                    rimItem: {
                        ...state.rimItem,
                        sswCombinations: sswHsCombinations
                    },
                },
                rimInfoTireSizesResponse: {
                    loading: false,
                    data: tireSizes
                },
                selectedTireSize: selectedTireSize,

                rimInfoRimRestrictionsResponse: {
                    loading: false,
                    data: rimRestrictions
                },
                rimDetailsArticlesResponse: {
                    ...state,
                    data: updatedData
                },
                ...rimId && {
                    selectedRimDetailsArticle: selectedRimDetailsArticle
                }
            }
        }

        case "RIM_DETAILS_AND_RESTRICTIONS_LOADING": {
            return {
                ...state,
                rimInfoTireSizesResponse: {
                    ...state.rimInfoTireSizesResponse,
                    loading: true
                },
                rimInfoRimRestrictionsResponse: {
                    ...state.rimInfoRimRestrictionsResponse,
                    loading: true
                }
            }
        }
    }
    return state
}

function selectRimDetailsSize(rimSize?: string): AsyncAction<ComponentActionType, MainState> {
    return (dispatch) => {
        batch(() => {
            dispatch({ type: "SELECT_RIM_DETAILS_SIZE", payload: rimSize || "" })
            dispatch(loadRimDetailsArticles())
        })
    }
}

function sendRimArticlesToOverview(activestep: number, workTaskId?: string): AsyncAction<BundleActionTypes, MainState> {
    return (dispatch, getState) => {

        const { selectedRimDetailsArticle, rimItem, selectedTireSize, rimInfoTireSizesResponse, configuratorImage } = getState().wheelsDetails
        const { _2D, _3D, height, tint, selectedColor } = getState().wheelsList.configurator
        const { isRDKSAvailable } = getState().navigation
        const { vehicle } = getState().manager
        let mappedSelectedRimArticlesForOverview = undefined
        let selectedRimArticle: RimDetailsArticle | undefined

        if (!selectedRimDetailsArticle || !rimItem || !selectedTireSize)
            return

        //here we need to send the selectedArticle with quantity updated
        Object.entries(getState().wheelsDetails.rimDetailsArticlesResponse.data).some(([_, value]) => {
            selectedRimArticle = value.find(x => x.artNr == selectedRimDetailsArticle.artNr &&
                selectedRimDetailsArticle?.idRim == x.idRim &&
                selectedRimDetailsArticle?.applicationId == x.applicationId
            )
            if (!!selectedRimArticle) return true
        })

        if (!selectedRimArticle)
            return

        mappedSelectedRimArticlesForOverview = mapRimItemForOverview(selectedRimArticle)

        const mapedSizes: TyreFilter[] = rimInfoTireSizesResponse.data.map(x => ({ query: x.tyreSize, value: x.tyreSize, group: EFilterNames.tyreSize }))
        const mapedSelectedTireSize: TyreFilter = selectedTireSize && { query: selectedTireSize.tyreSize, value: selectedTireSize.tyreSize, group: EFilterNames.tyreSize } || mapedSizes?.[0]

        dispatch({
            type: "SEND_RIM_ARTICLE_TO_OVERVIEW",
            payload: {
                rimItem: mappedSelectedRimArticlesForOverview,
                tireSizes: mapedSizes,
                selectedTireSize: mapedSelectedTireSize,
                configuratorImage
            }
        })

        if (configuratorImage && vehicle?.id && workTaskId) {
            saveConfiguratorImage(vehicle.id, workTaskId, configuratorImage)
        }

        dispatch(BundleActions.saveData({
            wheelsDetailsTab: {
                selectedArticle: {
                    artNr: selectedRimArticle.artNr,
                    eanCode: selectedRimArticle.eanCode,
                    rimSize: selectedRimArticle.rimSize,
                    quantity: selectedRimArticle.quantity
                },
                tireSizes: mapedSizes,
                selectedTireSize: mapedSelectedTireSize
            },
            activeStep: isRDKSAvailable ? activestep : WheelSelectionSteps.TIRESLIST,
            highestStepReached: activestep,
            configurator: {
                _3D: { height: height, tint: tint },
                selectedColor: selectedColor || "000000"
            }
        }))
    }
}

function loadRimDetailsArticles(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { rimItem, selectedFilters, selectedSize, selectedArticleFromState } = getState().wheelsDetails
        const { selectedCarParkId, selectedRimSize, isAdditionalManufacturerSelected } = getState().wheelsList.base
        const { selectedRegistrationNo } = getState().vehicleSelection
        const isHostettler = getBundleParams()?.isHostettler

        if (!selectedSize || !rimItem || !selectedCarParkId)
            return

        const HSArticlesId = rimItem.hostettlerCombinations?.[selectedSize]?.map(x => x.articleNumber).join(";")

        if (selectedArticleFromState) {
            dispatch(setWheelsDetailsArticle(selectedArticleFromState, HSArticlesId))
        }
        else {
            dispatch({ type: "RIM_DETAILS_ARTICLES_LOADING" })

            const request = createRimDetArticlesRequest(selectedCarParkId, selectedSize, rimItem.idRimDesign, selectedFilters, HSArticlesId, selectedRegistrationNo, rimItem?.isSSW, rimItem?.isConti, rimItem.rimErpInformations, isAdditionalManufacturerSelected)
            Repositories.loadRimDetailsArticles(request).then(
                (response) => {
                    const selectedRimArticle = Object.values(response)?.find(rim => rim[0].artNr == selectedRimSize?.dataSupplierArticleNumber)?.[0] || Object.values(response)?.[0]?.[0]
                    if (isHostettler && !rimItem?.isSSW && !rimItem?.isConti) {
                        Object.values(response).forEach(rimsGroup => rimsGroup.forEach(rim => {
                            const rimArticleFound = rimItem.hostettlerCombinations?.[selectedSize]?.find(article => article.articleNumber == rim.artNr)

                            if (rimArticleFound?.oe.length) {
                                rim.appTypeID = "OE"
                                rim.appType = RimCertification.OE
                            }
                            if (rimArticleFound?.vsa.length) {
                                rim.appTypeID = "ASA"
                                rim.appType = RimCertification.ASA
                            }
                        }))
                    }

                    batch(() => {
                        dispatch({ type: "RIM_DETAILS_ARTICLES_LOADED", payload: { result: response, selectedRimItem: selectedRimArticle } })
                        dispatch(setWheelsDetailsArticle(selectedRimArticle, HSArticlesId))
                    })
                },
                () => dispatch({ type: "RIM_DETAILS_ARTICLES_ERROR" })
            )
        }
    }
}

function setWheelsDetailsArticle(selectedRim: RimDetailsArticle, hsArticleId?: string): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { rimItem, selectedSize, selectedRimDetailsArticle } = getState().wheelsDetails
        const { selectedRegistrationNo } = getState().vehicleSelection
        let tireSizes: TireSize[] = []

        if (!rimItem || !selectedSize)
            return

        if (selectedRegistrationNo && hsArticleId && !rimItem?.isConti) {
            if (rimItem?.isSSW) {
                tireSizes = selectedRimDetailsArticle?.sizes || []
            } else {
                tireSizes = rimItem.hostettlerCombinations?.[selectedSize]?.find(x => x.articleNumber == selectedRim.artNr)?.reifen || []
            }
            dispatch({ type: "RIM_DETAILS_AND_RESTRICTIONS", payload: { rimRestrictions: [], tireSizes } })
        }
        else if (selectedRim) {
            dispatch(loadRimDetailsAndRestrictions())
        }
    }
}

function loadTiresRestrictions(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const carparkId = getState().wheelsList.base.selectedCarParkId
        const { selectedRegistrationNo } = getState().vehicleSelection
        const { rimItem } = getState().wheelsDetails

        if (!rimItem?.isConti && (!carparkId || (selectedRegistrationNo && !rimItem?.carParkId)))
            return

        const request = createTireRestrictionRequest(carparkId, getState().wheelsDetails)

        if (!request) return

        dispatch({ type: "RIM_INFO_TIRE_RESTRICTIONS_LOADING" })
        Repositories.loadTireRestrictions(request).then(
            response => dispatch({ type: "RIM_INFO_TIRE_RESTRICTIONS_LOADED", payload: response }),
            () => dispatch({ type: "RIM_INFO_TIRE_RESTRICTIONS_ERROR" })
        )
    }
}

function loadRimDetailsAndRestrictions(): AsyncAction<ComponentActionType, MainState> {
    return async (dispatch, getState) => {
        const carparkId = getState().wheelsList.base.selectedCarParkId
        const wheelsDetailsState = getState().wheelsDetails
        const { selectedRegistrationNo } = getState().vehicleSelection
        const { selectedSize, selectedRimDetailsArticle, rimItem } = wheelsDetailsState

        if (selectedSize && !rimItem?.isConti) {
            let tireSizes: TireSize[] = []
            if (rimItem?.isSSW && selectedRimDetailsArticle) {
                tireSizes = selectedRimDetailsArticle?.sizes || []
            } else {
                tireSizes = rimItem?.hostettlerCombinations?.[selectedSize]?.find(x => x.articleNumber == selectedRimDetailsArticle?.artNr)?.reifen || []
            }
            dispatch({ type: "RIM_DETAILS_AND_RESTRICTIONS", payload: { rimRestrictions: [], tireSizes } })
        }

        if (!rimItem?.isConti && (!carparkId || (selectedRegistrationNo && !rimItem?.carParkId)))
            return

        dispatch({ type: "RIM_DETAILS_AND_RESTRICTIONS_LOADING" })
        const rimRestrictionsRequest = createRimRestrictionRequest(carparkId, wheelsDetailsState.selectedRimDetailsArticle)
        const tireSizesRequest = createTireSizesRequest(carparkId, wheelsDetailsState)

        Promise.allSettled([
            Repositories.loadRimRestrictions(rimRestrictionsRequest),
            Repositories.loadTireSize(tireSizesRequest),
            Repositories.loadRimFixtures({
                appId: rimRestrictionsRequest.appId,
                carparkId: rimRestrictionsRequest.carparkId,
            })
        ]).then(results => {
            const rimRestrictionsResult = results[0]
            const tireSizesResult = results[1]
            const fixtureResult = results[2]
            
            let rimRestrictions: Restriction[] | undefined = []
            let tireSizes: TireSize[] | undefined = []
            let fixture: Fixture | undefined = undefined

            if (rimRestrictionsResult.status === "fulfilled") {
                rimRestrictions = rimRestrictionsResult.value
            }

            if (tireSizesResult.status === "fulfilled") {
                tireSizes = tireSizesResult.value
            }

            if (fixtureResult.status === "fulfilled") {
                fixture = fixtureResult.value
            }

            dispatch({
                type: "RIM_DETAILS_AND_RESTRICTIONS",
                payload: {
                    rimRestrictions,
                    tireSizes,
                    fixture,
                    rimId: rimRestrictionsRequest?.idRim
                }
            })
        })
    }
}

function selectRimDetailsArticle(item: RimDetailsArticle): ComponentActionType {
    return { type: "SELECT_RIM_DETAILS_ARTICLE", payload: item }
}

function changeRimDetailsArticleQuantity(rimDetailsArticle: RimDetailsArticle, quantity: number): ComponentActionType {
    return { type: "CHANGE_RIM_DET_ARTICLE_QUANTITY", payload: { rimDetailsArticle, quantity } }
}

function selectTireSize(tireSize: TireSize): ComponentActionType {
    return { type: "SELECT_TIRE_SIZE", payload: tireSize }
}

function changeImageDisplay(): ComponentActionType {
    return { type: "CHANGE_IMAGE_DISPLAY" }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    selectRimDetailsSize,
    changeRimDetailsArticleQuantity,
    sendRimArticlesToOverview,
    selectTireSize,
    changeImageDisplay,
    selectRimDetailsArticle,
    loadTiresRestrictions,
    loadRimDetailsAndRestrictions,
    loadRimDetailsArticles
}