import { CarLookupModel, RegistrationNoType } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"

import * as VrmLookup from "../../../data/repositories/vrm-lookup"
import { VrmLookupDetailsOnlyState } from "./model"

export * from "./model"

export type ComponentActionType = { type: "vehicle-data-loaded"; payload: CarLookupModel }

const DEFAULT_STATE: VrmLookupDetailsOnlyState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): VrmLookupDetailsOnlyState {
    switch (action.type) {
        case "vehicle-data-loaded": {
            return {
                ...state,
                vrmVehicle: action.payload,
            }
        }
        default:
            break
    }

    return state
}

function load(query: string, lookupTypeId: RegistrationNoType): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        switch (lookupTypeId) {
            case RegistrationNoType.VrmAAA: {
                VrmLookup.showVehiclesByRegistrationNo({ query, lookupTypeId }).then(({ carModels }) => {
                    const vrmVehicle = carModels.first()

                    if (vrmVehicle) {
                        dispatch({ type: "vehicle-data-loaded", payload: vrmVehicle })
                    }
                })
                break
            }

            case RegistrationNoType.DatVin: {
                VrmLookup.showVehiclesByVin({ query, lookupTypeId }).then(({ carModels }) => {
                    const vrmVehicle = carModels.first()

                    if (vrmVehicle) {
                        dispatch({ type: "vehicle-data-loaded", payload: vrmVehicle })
                    }
                })
                break
            }
            default:
                break
        }
    }
}

export type IActions = typeof Actions

export const Actions = {
    load,
}
