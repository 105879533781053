import { useMemo, useState } from "react"
import { Box, Icon, Loader, Button, ConfirmationDialog, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ConfirmationState, Voucher, VoucherItem } from "../../../data/model"
import { useSendVoucherConfirmation } from "../../../data/hooks/useSendVoucherConfirmation"

type Props = {
    voucher: Voucher
    voucherItems?: VoucherItem[]
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function SendVoucherConfirmationButton({ voucher, voucherItems, onOpenNewVoucher }: Props) {
    const { translateText } = useLocalization()
    const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false)
    const { onSendVoucherConfirmation, voucherConfirmationResponse, voucherConfirmationLoading, voucherConfirmationError } =
        useSendVoucherConfirmation()

    const [isLink, voucherTypeId, voucherId] = useMemo(() => {
        return [
            !!voucherConfirmationResponse?.voucherId && !!voucherConfirmationResponse.voucherTypeId,
            voucherConfirmationResponse?.voucherTypeId,
            voucherConfirmationResponse?.voucherId,
        ]
    }, [voucherConfirmationResponse])

    const [iconName, title, disabled] = useMemo(() => {
        let icon = "voucher-send"

        let description = voucherConfirmationResponse?.confirmation?.description ?? voucher.confirmation?.description
        const confirmationState = voucherConfirmationResponse?.confirmation?.confirmationState ?? voucher.confirmation?.confirmationState
        let clickable = false

        if (voucherConfirmationResponse) {
            if (voucherConfirmationError) {
                icon = "error-filled"
                description = translateText(13333)
                clickable = true
            } else if (isLink) {
                icon = "partslink24"
                description = translateText(13468)
                clickable = true
            } else if (confirmationState === ConfirmationState.Unconfirmed) {
                icon = "voucher-unconfirm"
                clickable = true
            } else if (confirmationState === ConfirmationState.Unknown) {
                icon = "file-question"
            } else if (confirmationState === ConfirmationState.Confirmed) {
                icon = "voucher-confirm"
            } else if (confirmationState === ConfirmationState.ConfirmedWithChanges) {
                icon = "voucher-confirmed-with-changes"
            } else if (confirmationState === ConfirmationState.Rejected) {
                icon = "voucher-rejected"
            } else if (confirmationState === ConfirmationState.Revoked) {
                icon = "voucher-withdrawn"
            }
        } else if (voucher.confirmationOptions?.canBeConfirmed) {
            clickable = true
            description = voucher.confirmationOptions.tooltip ?? translateText(13508)
        }

        return [icon, description, !clickable]
    }, [voucher.confirmation, voucherConfirmationError, voucherConfirmationResponse, voucher.confirmationOptions, isLink])

    function handleAcceptOffer() {
        setShowConfirmDialog(false)
        onSendVoucherConfirmation(voucher, voucherItems)
    }

    function handleConfirmOrSendOffer() {
        if (isLink && voucherTypeId && voucherId) {
            onOpenNewVoucher?.(voucherTypeId, voucherId)
        } else {
            setShowConfirmDialog(true)
        }
    }

    function handleDeclineOffer() {
        setShowConfirmDialog(false)
    }

    return (
        <>
            <ConfirmationDialog
                open={showConfirmDialog}
                onConfirm={handleAcceptOffer}
                onCancel={handleDeclineOffer}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
            >
                <Typography>{translateText(13785)}</Typography>
            </ConfirmationDialog>
            {voucherConfirmationLoading ? (
                <Box width="32px">
                    <Loader size="small" />
                </Box>
            ) : (
                <Button disabled={disabled} startIcon={<Icon name={iconName} />} title={title} onClick={handleConfirmOrSendOffer} />
            )}
        </>
    )
}
