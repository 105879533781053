import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { WorkCategory, WorkType, RepairTimeProvider, VatRate, CustomWork } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { Paper, Dialog, Typography, Box, Button } from "@tm/components"
import WorkFields from "./work-fields"
import { EditWorkMode } from "../../../business"
import { createCustomWork } from "../../../../../data/mapper"
import { addCustomRepairTimeItems } from "../../../../../data/repositories/custom-items/repair-times"
import { BasketWork } from "../../../../../models"
import { StackRow } from "../../../../StyledComponents"
import { AddCustomRepairTimeItemsRequest, CostEstimation, CustomerDefaults } from "../../../../../data/model"

type Props = {
    costEstimation?: CostEstimation
    fixedPriceValue?: number
    repairTimeDivision: number
    showRepairTimesInHours: boolean
    work: BasketWork
    workTask: WorkTaskInfo
    onCloseWorkEditor(workId: string): void
    onEditWork(
        work: BasketWork,
        repairTimeDivision: number,
        categoryOfWork: WorkCategory,
        description: string,
        editWorkMode: EditWorkMode,
        customerDefaults?: CustomerDefaults,
        workNumber?: string,
        time?: number,
        hourlyRate?: number,
        fixedPriceValue?: number,
        rebate?: number,
        surcharge?: number,
        vatRate?: VatRate
    ): void
    onReplaceWorkWithCustomWork(workToReplace: BasketWork, repairTimeDivision: number, customWork: CustomWork, vehicleId?: string): void
}

export default function WorkEditorComponent(props: Props) {
    const { work, workTask, showRepairTimesInHours, costEstimation, fixedPriceValue, repairTimeDivision } = props
    const { workItem, estimatedWork, calculatedWork } = work
    const { hourlyRates, customerDefaults, costEstimationOptions } = costEstimation ?? {}
    const { translateText } = useLocalization()
    const [actualWorkNumber, setWorkNumber] = useState<string>("")
    const [actualDescription, setDescription] = useState<string>("")
    const [actualTime, setTime] = useState<number | undefined>(workItem.timeInMinutes)
    const [actualHourlyRate, setHourlyRate] = useState<number | undefined>(
        workItem.hourlyRate || hourlyRates?.[WorkCategory.WorkshopWork] || hourlyRates?.[WorkCategory.NotCategorized]
    )
    const [actualFixedPriceValue, setFixedPriceValue] = useState<number | undefined>(fixedPriceValue)
    const [actualWorkCategory, setWorkCategory] = useState<WorkCategory>(WorkCategory.WorkshopWork)
    const [actualWorkVatRate, setWorkVatRate] = useState<VatRate | undefined>(calculatedWork?.vatRate)
    const [showEditDialog, setShowEditDialog] = useState(false)

    function handleEditWorkClick(
        _saveAsCustomRepairTime: boolean,
        workNumber: string,
        categoryOfWork: WorkCategory,
        description: string,
        time?: number,
        hourlyRate?: number,
        fixedPriceValue?: number,
        rebate?: number,
        surcharge?: number,
        vatRate?: VatRate,
        editWorkMode?: EditWorkMode
    ) {
        if (editWorkMode === EditWorkMode.editCustomWork || editWorkMode === EditWorkMode.editRepairTime) {
            props.onEditWork(
                work,
                repairTimeDivision,
                categoryOfWork,
                description,
                editWorkMode,
                customerDefaults,
                workNumber,
                time,
                hourlyRate,
                fixedPriceValue,
                rebate,
                surcharge,
                vatRate
            )
            props.onCloseWorkEditor(workItem.id)
            if (editWorkMode === EditWorkMode.editCustomWork && _saveAsCustomRepairTime) {
                const request: AddCustomRepairTimeItemsRequest = {
                    customRepairTimeItemsToAdd: [
                        {
                            workId: workNumber,
                            category: categoryOfWork,
                            description,
                            workTime: time,
                            customHourlyRate: hourlyRate,
                            fixedPriceValue,
                            vatRate,
                        },
                    ],
                    repairTimeDivision,
                    priceVatMode: costEstimationOptions?.priceVatMode,
                }
                addCustomRepairTimeItems(request)
            }
        } else {
            setWorkNumber(workNumber)
            setTime(time)
            setDescription(description)
            setWorkVatRate(vatRate)
            setFixedPriceValue(fixedPriceValue)
            setHourlyRate(hourlyRate)
            setWorkCategory(categoryOfWork)
            setShowEditDialog(true)
        }
    }

    function handleEditWarningConfirm() {
        props.onReplaceWorkWithCustomWork(
            work,
            repairTimeDivision,
            createCustomWork(
                actualWorkNumber,
                actualWorkCategory,
                actualDescription,
                actualTime,
                actualHourlyRate,
                actualFixedPriceValue,
                workItem.rebate,
                workItem.surcharge,
                actualWorkVatRate
            ),
            workTask.vehicle?.id
        )
        props.onCloseWorkEditor(workItem.id)
    }

    let title = workItem.providerName || ""
    let icon = "repairtimes"
    let iconTooltip = translateText(83)
    let showSaveAsCustomRepairTimeButton = false
    switch (workItem.type) {
        case WorkType.CustomWork: {
            title = translateText(920)
            icon = "individual-repairtimes"
            iconTooltip = translateText(920)
            showSaveAsCustomRepairTimeButton = true
            break
        }
        case WorkType.CustomMainWork: {
            icon = "repairtime-modified"
            iconTooltip = translateText(1473)
            break
        }
        default:
            break
    }
    return (
        <Paper>
            {hourlyRates && (
                <WorkFields
                    calculatedPrice={calculatedWork?.offerPrice?.value}
                    currencyCode={workItem.currencyCode}
                    description={estimatedWork?.description ?? workItem.description}
                    fixedPriceValue={calculatedWork?.regularPrice?.value}
                    hourlyRate={calculatedWork?.hourlyRate?.value ?? workItem.hourlyRate}
                    hourlyRates={hourlyRates}
                    icon={icon}
                    iconTooltip={iconTooltip}
                    isEditMode
                    isFixedPrice={calculatedWork?.isFixedPrice}
                    numberAndDescriptionMandatory={workItem.provider !== undefined && workItem.provider !== RepairTimeProvider.Eurotax}
                    price={calculatedWork?.regularPrice?.value}
                    rebate={calculatedWork?.rebate ?? workItem.rebate}
                    repairTimeDivision={repairTimeDivision}
                    showRepairTimesInHours={showRepairTimesInHours}
                    showSaveAsCustomRepairTimeButton={showSaveAsCustomRepairTimeButton}
                    surcharge={calculatedWork?.surcharge ?? workItem.surcharge}
                    time={calculatedWork?.repairTime ?? estimatedWork?.displayTime?.value}
                    title={title}
                    vatRates={workItem.vatRates}
                    workCategory={workItem.category}
                    workNumber={workItem.displayNumber || workItem.providerWorkId}
                    workType={workItem.type}
                    onClose={() => props.onCloseWorkEditor(workItem.id)}
                    onConfirm={handleEditWorkClick}
                />
            )}
            <Dialog open={showEditDialog} skin="warning" position="top" fullWidth>
                <StackRow spacing={2}>
                    <Typography>{translateText(933)}</Typography>
                    <Box textAlign="center" display="flex" flexWrap="nowrap">
                        <Button variant="contained" onClick={() => setShowEditDialog(false)}>
                            {translateText(584)}
                        </Button>
                        <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleEditWarningConfirm}>
                            {translateText(585)}
                        </Button>
                    </Box>
                </StackRow>
            </Dialog>
        </Paper>
    )
}
