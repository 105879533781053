import { RouteComponentProps, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { GlobalNavigationItem } from "@tm/components"
import { useCallback } from "react"

type Props = RouteComponentProps<void> & {
    url: string
    title?: string
    tooltip?: string
    icon: string
}

function HeaderButton({ url, history, title, icon, tooltip }: Props) {
    const { translateText } = useLocalization()

    const isActive = history.location.pathname === url
    const navigate = useCallback(() => {
        if (isActive) {
            history.goBack()
        } else {
            history.push(url)
        }
    }, [isActive, url])

    return (
        <GlobalNavigationItem
            tooltip={(tooltip && translateText(tooltip)) || translateText(1251)}
            active={isActive}
            onIconClick={navigate}
            componentType="icon"
            name={icon}
            subtitle={title ? (/^\d+/.test(title) ? translateText(title) : title) : undefined}
        />
    )
}

export default withRouter(HeaderButton)
