export type PrintOptions = {
    inspectionTimes: boolean
    remarks: boolean
    images: boolean
    tyres: boolean
    nextMaintenance: boolean
    showDefects: boolean
}

export enum DisplayOptionKeys {
    InspectionTimes = "inspectionTimes",
    Remarks = "remarks",
    Images = "images",
    Tyres = "tyres",
    NextMaintenance = "nextMaintenance",
    ShowDefects = "showDefects",
}
