import { MenuItemWidget, Select, SelectChangeEvent, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CausedCostsType } from "@tm/models"

type Props = {
    onCausedCostsFilterChanged(causeCostsFilter: CausedCostsType): void
    value: CausedCostsType
}

export default function CausedCostsSelect({ onCausedCostsFilterChanged, value }: Props) {
    const { translateText } = useLocalization()

    const selectItems = [CausedCostsType.All, CausedCostsType.NoCosts, CausedCostsType.OnlyCosts]

    function getFilterLocalization(filter: CausedCostsType): string {
        switch (filter) {
            case CausedCostsType.All:
                return translateText(1205)
            case CausedCostsType.NoCosts:
                return translateText(13573)
            case CausedCostsType.OnlyCosts:
                return translateText(13560)
            default:
                return ""
        }
    }

    function onSelectionChanged(selectionEvent: SelectChangeEvent<unknown>) {
        const filter = selectionEvent.target.value as CausedCostsType
        if (filter !== undefined) {
            onCausedCostsFilterChanged(filter)
        }
    }

    return (
        <Stack direction="column">
            <Typography variant="label">{translateText(13560)}</Typography>
            <Select
                color="primary"
                sx={{ minWidth: "150px" }}
                variant="filled"
                size="large"
                value={value}
                onChange={onSelectionChanged}
                disableUnderline
            >
                {selectItems.map((item) => {
                    return (
                        <MenuItemWidget key={item.toString()} value={item}>
                            {getFilterLocalization(item)}
                        </MenuItemWidget>
                    )
                })}
            </Select>
        </Stack>
    )
}
