import { useState, useEffect } from "react"
import { Button, Dropdown, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { AttributeFilterModel } from "@tm/models"

import * as Data from "../../../data/repository"
import AttributeFilterOptionView, { AttributeFilterOption } from "./attribute-filter-option"

export type AttributeFilterComponentProps = {
    attributeFilter: AttributeFilterModel
    modelId: number
    denyWrite: boolean
    onSelectAttribute: (changedFilter: AttributeFilterModel) => void
    onDeselectAttribute: (changedFilter: AttributeFilterModel) => void
    onRequestArticleList(attributeFilter: AttributeFilterModel): void
    onRequestCalculation?(productGroupId: number): void
    canRequestCalculation?: boolean
}

function AttributeFilterComponent(props: AttributeFilterComponentProps) {
    const { translateText, translate } = useLocalization()
    const [options, setOptions] = useState<Array<AttributeFilterModel>>()
    const { id, fittingSide, productGroupId, description, text, unit } = props.attributeFilter

    useEffect(() => {
        setOptions(undefined)
    }, [props.modelId, id, fittingSide, productGroupId])

    const handleAttributeSelection = (selectedAttributeFilter: { filter: AttributeFilterModel }) => {
        if (selectedAttributeFilter.filter) {
            props.onSelectAttribute(selectedAttributeFilter.filter)
        }
    }

    const handleDropDownOpen = () => {
        if (!options) {
            Data.loadFilterOptions({ attributeId: id, fittingSide, productGroupId, modelId: props.modelId }).then((response) => {
                setOptions(response?.filterOptions)
            })
        }
    }

    const handleRequestArticleList = () => {
        props.onRequestArticleList?.(props.attributeFilter)
    }

    const handleRequestCalculation = () => {
        if (props.canRequestCalculation) {
            props.onRequestCalculation?.(productGroupId)
        }
    }

    const renderActions = () => {
        if (!productGroupId) {
            return
        }

        return (
            <div className="product-groups__actions">
                {!props.denyWrite && (
                    <Button className="btn--apply" icon="check" onClick={handleRequestArticleList} title={translateText(1041)}>
                        {translate(1041)}
                    </Button>
                )}
                {props.onRequestCalculation && (
                    <Button
                        className="btn--calculate"
                        icon="fastclick"
                        layout={["holo"]}
                        disabled={!props.canRequestCalculation}
                        onClick={handleRequestCalculation}
                        title={translateText(1042)}
                    >
                        {translate(1042)}
                    </Button>
                )}
            </div>
        )
    }

    return (
        <div className="product-groups__filter" style={{ display: "flex", justifyContent: "space-between", flex: "1", alignItems: "center" }}>
            <Text size="m" className="product-groups__desciption">
                {description}
            </Text>
            <Dropdown
                enableLoaderInDropdown
                disabled={Data.denyWriteVehicleRecords()}
                value={text ? { filterText: text + (unit ? ` ${unit}` : "") } : { filterText: translateText(1039) }}
                items={options?.map((item): AttributeFilterOption => ({ filter: item }))}
                onChange={handleAttributeSelection}
                itemView={AttributeFilterOptionView}
                onDropdownOpen={handleDropDownOpen}
            />
            {renderActions()}
            <Text className="product-groups__record-info">&nbsp;</Text>
        </div>
    )
}

export default AttributeFilterComponent
