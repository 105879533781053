import { BundleComponent } from "@tm/morpheus"
import { StartTemplateFive } from "./StartTemplateFive"
import { withStoreProvider } from "../../shared/withStoreProvider"

const StartTemplateFiveBundle: BundleComponent = {
    name: "start-template-five",
    component: withStoreProvider(StartTemplateFive as any),
}

export { StartTemplateFiveBundle, StartTemplateFive }
