import { useShowCisOptions, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { CisVoucherType, Paging } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { useMemo } from "react"
import { useInfiniteQuery, QueryFunctionContext, QueryKey } from "react-query"
import { ShowAllOrdersRequest } from "../model"
import * as Data from "../repositories/cis/vouchers"
import { CisFilters } from "../../business/model"
import { useFilterStore } from "../../business/state"

type PageParam = Paging & { continuationPoint?: string }

function showAllOrders(pageParam: PageParam | undefined, pageSize: number, customerNo: string | undefined, requestSingleItem: boolean) {
    const showAllOrdersRequest: ShowAllOrdersRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        continuationPoint: pageParam?.continuationPoint,
        customerNo,
    }

    const { filters } = useFilterStore.getState()

    if (filters?.[CisVoucherType.AllOrders]) {
        const { dateFrom, dateTo, query } = filters?.[CisVoucherType.AllOrders]

        showAllOrdersRequest.dateFrom = !requestSingleItem ? dateFrom : undefined
        showAllOrdersRequest.dateTo = !requestSingleItem ? dateTo : undefined
        showAllOrdersRequest.query = query
    }

    return Data.showAllOrders(showAllOrdersRequest)
}

const KEY = "cis_useAllOrders"
const VOUCHER_TYPE = CisVoucherType.AllOrders
export function useAllOrders(useFilters: boolean, filters: CisFilters | undefined, singleSearch = false, pageSize = 150) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { cisOptions } = useShowCisOptions()

    const voucherTypeEnabled = useMemo(() => {
        return !!cisOptions?.voucherTypes?.some((type) => type.typeId === VOUCHER_TYPE)
    }, [cisOptions])

    const enabled = voucherTypeEnabled && enableServiceCalls && (!useFilters || !!filters)

    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
        [KEY, telesalesCustomerNo, pageSize, singleSearch, filters, enabled],
        ({ pageParam }: QueryFunctionContext<QueryKey, PageParam>) => {
            return showAllOrders(pageParam, pageSize, telesalesCustomerNo, singleSearch)
        },
        {
            enabled,
            getNextPageParam: (lastPage, allPages): PageParam | undefined => {
                if (lastPage?.continuationPoint && lastPage.continuationPoint !== allPages[allPages.length - 2]?.continuationPoint) {
                    return {
                        pageIndex: lastPage.pageIndex + 1,
                        pageSize: lastPage.pageSize,
                        continuationPoint: lastPage.continuationPoint,
                    }
                }
            },
        }
    )

    const allOrders = useMemo(() => {
        return data?.pages.flatMap((page) => page?.orders).filter(notUndefinedOrNull)
    }, [data?.pages])

    return {
        allOrders,
        allOrdersLoading: isLoading,
        allOrdersLoadNextPage: hasNextPage ? fetchNextPage : undefined,
        allOrdersNextPageLoading: isFetchingNextPage,
    }
}
