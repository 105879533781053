import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { VoucherDetailsField } from "./VoucherDetailsField"
import { ReturnRecipient } from "../../../../../../../../data/model"

type Props = {
    voucherNumber: string
    orderNumber?: string
    customOrderNumber?: string
    currencyCode?: string
    currencySymbol?: string
    orderDate: Date
    totalRetailPrice?: number
    totalPurchasePrice?: number
    returnRecipient?: ReturnRecipient
}

function getOrderRecipient(returnRecipient: ReturnRecipient) {
    return `${returnRecipient.name || ""},  ${returnRecipient.street || ""}, ${returnRecipient.zip || ""} ${returnRecipient.city || ""}, ${
        returnRecipient.country || ""
    }`
}

export default function GeneralFields(props: Props) {
    const {
        currencyCode,
        currencySymbol,
        customOrderNumber,
        orderDate,
        orderNumber,
        totalPurchasePrice,
        totalRetailPrice,
        voucherNumber,
        returnRecipient,
    } = props
    const { translateText, currency, date } = useLocalization()
    const { userSettings } = useUser() ?? {}

    const voucherDate = orderDate ? new Date(orderDate) : undefined

    return (
        <>
            {orderNumber && <VoucherDetailsField label={translateText(700)} value={orderNumber} />}
            {voucherNumber && <VoucherDetailsField label={translateText(470)} value={voucherNumber} />}
            {customOrderNumber && <VoucherDetailsField label={translateText(1249)} value={customOrderNumber} />}
            {voucherDate && <VoucherDetailsField label={translateText(98)} value={date(voucherDate, "g")} />}
            {userSettings?.showPurchasePrice && totalPurchasePrice && currencyCode && currencySymbol && (
                <VoucherDetailsField
                    label={translateText(12853)}
                    value={currency(totalPurchasePrice, currencyCode || currencySymbol)}
                    valueInPrimaryColor
                />
            )}
            {totalRetailPrice && currencyCode && currencySymbol && (
                <VoucherDetailsField label={translateText(12852)} value={currency(totalRetailPrice, currencyCode || currencySymbol)} />
            )}
            {returnRecipient && <VoucherDetailsField label={translateText(13249)} value={getOrderRecipient(returnRecipient)} />}
        </>
    )
}
