import { margin, padding, percent, rem } from "csx"
import { ReactNode, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"

export type DictionaryValue = string | number | Array<string> | Array<number>

export type DictionaryItem = {
    key: string
    value: DictionaryValue
    valueRenderer?: (value: DictionaryValue) => ReactNode
}

type Props = {
    showOnlyFilledValues?: boolean
    items: Array<DictionaryItem>
}

export default function Dictionary({ showOnlyFilledValues, items }: Props) {
    const style = useMemo(() => getStyle(), [])

    const renderItem = (item: DictionaryItem, idx: number) => {
        if (showOnlyFilledValues && item.value == null) {
            return
        }

        let content: ReactNode = item.value

        if (item.valueRenderer) {
            content = item.valueRenderer(item.value)
        } else if (Array.isArray(item.value)) {
            content = item.value.join(", ")
        }

        return (
            <div className="item" key={idx}>
                <div className="key">{item.key}</div>
                <div className="value">{content}</div>
            </div>
        )
    }

    return <div className={style.dictionary}>{items.map(renderItem)}</div>
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        dictionary: {
            display: "block",
            columns: 2,
            columnGap: 2,
            margin: margin(rem(-0.5), 0),
            $nest: {
                ".item": {
                    display: "flex",
                    flexBasis: percent(50),
                    padding: padding(rem(0.5), 0),
                },
                ".key": {
                    flexBasis: percent(50),
                },
                ".value": {
                    flexBasis: percent(50),
                    fontWeight: theme.font.boldWeight,
                },
            },
        },
    })(Dictionary)
}
