/*
 * Function to convert from UTF8 to Base64 solving the Unicode Problem
 * Requires: window.btoa and window.encodeURIComponent functions
 * More info: http://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * Samples:
 *      b64EncodeUnicode('✓ à la mode'); // "4pyTIMOgIGxhIG1vZGU="
 *      b64EncodeUnicode('\n'); // "Cg=="
 */
export function b64EncodeUnicode(str: string): string {
    if (window && "btoa" in window && "encodeURIComponent" in window) {
        return btoa(
            encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => {
                return String.fromCharCode(`0x${p1}` as any)
            })
        )
    }

    console.warn("b64EncodeUnicode requirements: window.btoa and window.encodeURIComponent functions")
    return ""
}
