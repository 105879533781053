import { Grid, Popover, Box, LinkButton, List, useTheme, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { NotificationMessage } from "../../data/model/notificationMessage"
import { NotificationListItem } from "./NotificationListItem"

export interface INotificationsListProps {
    unreadNotificationMessages: NotificationMessage[]
    setNotificationMessageReadAndRemove(notificationMessageId: string): Promise<void>
    closePopup(): void
    anchorEl: Element | null
}

export default function NotificationsList(props: INotificationsListProps) {
    const isOpen = props.anchorEl !== null
    const theme = useTheme()
    const { translateText } = useLocalization()

    const handleClose = () => {
        props.closePopup()
    }

    return (
        <Box>
            <Popover
                id={isOpen ? "simple-popover" : undefined}
                open={isOpen}
                anchorEl={props.anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{ maxHeight: 595, overflow: "visible" }}
            >
                <Box sx={{ padding: 2, background: theme.palette.grey[200] }}>
                    <Grid container spacing={1} direction="column">
                        {props.unreadNotificationMessages?.length > 0 ? (
                            <Grid item xs sx={{ overflow: "auto", minHeight: 435 }}>
                                <List dense sx={{ minWidth: 800 }}>
                                    {props.unreadNotificationMessages.map((item: NotificationMessage) => (
                                        <NotificationListItem
                                            key={item.notificationMessageId}
                                            item={item}
                                            setNotificationMessageReadAndRemove={props.setNotificationMessageReadAndRemove}
                                        />
                                    ))}
                                </List>
                            </Grid>
                        ) : (
                            <Grid item xs sx={{ overflow: "auto", minHeight: 100 }}>
                                <Typography sx={{ marginRight: 4, marginTop: 4, marginBottom: 4 }}>{translateText(13488)}</Typography>
                            </Grid>
                        )}
                        <Grid item xs="auto">
                            <LinkButton to="/notifications" onClick={handleClose}>
                                {translateText(13489)}
                            </LinkButton>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </Box>
    )
}
