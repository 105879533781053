import { forwardRef, memo, useCallback, useMemo, useState } from "react"
import { style } from "typestyle"
import { Checkbox, Text, WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"

type Props = {
    handleConfirmation(dontAskAgain: boolean): void
    handleCancellation(dontAskAgain: boolean): void
}

const checkBoxWrapperClassName = style({
    paddingLeft: "3.8rem",
})

export const DeletionPrompt = memo(
    forwardRef<WarningPrompt, Props>(({ handleConfirmation, handleCancellation }, ref) => {
        const [checkboxActive, setCheckboxActive] = useState<boolean>(false)
        const { translateText } = useLocalization()

        const handleCheckboxChange = useCallback(() => {
            setCheckboxActive((pref) => !pref)
        }, [])

        const textContent = useMemo(() => {
            return (
                <div>
                    <Text size="l" modifiers={["strong"]}>
                        {translateText(13292)}
                    </Text>
                    <br />
                    <Text size="m">{translateText(13294)}</Text>
                    <br />
                    <Text size="m">{translateText(13295)}</Text>
                </div>
            )
        }, [translateText])

        const handleOnConfirm = useCallback(() => {
            handleConfirmation(checkboxActive)
        }, [handleConfirmation, checkboxActive])

        const handleOnCancel = useCallback(() => {
            handleCancellation(checkboxActive)
        }, [handleCancellation, checkboxActive])

        return (
            <WarningPrompt
                text={textContent}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={ref}
                onConfirm={handleOnConfirm}
                onCancel={handleOnCancel}
            >
                <div className={checkBoxWrapperClassName}>
                    <Checkbox label={translateText(12857)} size="s" checked={checkboxActive} onToggle={handleCheckboxChange} />
                </div>
            </WarningPrompt>
        )
    })
)
