import { Button, TextField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { Formular } from "../../modul/toolbar-form/component"
import { callUserSnapForm, initUsersnap } from "../userSnapApi"
import { getBundleParams } from "../../utils"

declare global {
    interface Window {
        onUsersnapCXLoad: any
        UserSnap: any
    }
}

export type FeedbackProps = {
    preset: Formular
    onDecline: () => void
    onSubmit: (formValue: Formular) => void
}

export function UserSnapFeedbackFormular(props: FeedbackProps) {
    const { preset, onDecline } = props
    const [formValue, setFormValue] = useState<Formular>(preset)
    const { userSnapBugReportKey, userSnapSatisfactionReportKey, userSnapFeatureRequestkKey } = getBundleParams()

    function setFormValueValueUserSnap() {
        window.UserSnap.on("open", (event: any) => {
            event.api.setValue("custom", formValue)
        })
    }

    if (!window.onUsersnapCXLoad) {
        initUsersnap(formValue)
    } else if (window.UserSnap != null) {
        setFormValueValueUserSnap()
    }

    const { translateText } = useLocalization()

    const handleOnNameChange = (value: string) => {
        const currentValue = { ...formValue, name: value }
        setFormValue(currentValue)
        setFormValueValueUserSnap()
    }

    const handleOnPhoneChange = (value: string) => {
        const currentValue = { ...formValue, phone: value }
        setFormValue(currentValue)
        setFormValueValueUserSnap()
    }

    const onButtonClick = (userSnapKey: string) => {
        callUserSnapForm(userSnapKey)
        onDecline()
    }
    return (
        <div className="content">
            <div className="headline">
                {translateText(12985)}
                <div className="actions">
                    <Button size="l" icon="close" onClick={onDecline} />
                </div>
            </div>
            <Text className="description">{translateText(1211)}</Text>
            <div className="">
                <div className="feedback-box">
                    <TextField className="feedback-name" placeholder={translateText(155)} value={formValue.name} onChange={handleOnNameChange} />
                    <TextField className="feedback-phone" placeholder={translateText(110)} value={formValue.phone} onChange={handleOnPhoneChange} />
                    <div className="buttonBox">
                        {userSnapSatisfactionReportKey && (
                            <Button
                                className="submit"
                                size="m"
                                skin="highlight"
                                onClick={() => onButtonClick(userSnapSatisfactionReportKey)}
                                disabled={!formValue.name}
                            >
                                {translateText(1210)}
                            </Button>
                        )}
                        {userSnapBugReportKey && (
                            <Button
                                className="submit"
                                size="m"
                                skin="highlight"
                                onClick={() => onButtonClick(userSnapBugReportKey)}
                                disabled={!formValue.name}
                            >
                                {translateText(12983)}
                            </Button>
                        )}
                        {userSnapFeatureRequestkKey && (
                            <Button
                                className="submit"
                                size="m"
                                skin="highlight"
                                onClick={() => onButtonClick(userSnapFeatureRequestkKey)}
                                disabled={!formValue.name}
                            >
                                {translateText(12984)}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
