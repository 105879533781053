import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Quota } from "@tm/models"

export type Props = {
    quota?: Quota
    marginY?: string
}

export const QuotaText = ({ quota, marginY }: Props) => {
    const { translateText } = useLocalization()

    if (!quota) {
        return null
    }

    const used = `${quota?.used || "0"}`
    const limit = `${quota?.limit || "-"}`

    return (
        <Stack direction="row" spacing="4px" marginY={marginY}>
            <Typography variant="label">{translateText(quota.hasQuotaAvailable ? 13346 : 1524)}</Typography>
            <Typography variant="label" fontWeight="bold">
                {used}
            </Typography>
            <Typography variant="label"> / </Typography>
            <Typography variant="label" fontWeight="bold">
                {limit}
            </Typography>
        </Stack>
    )
}
