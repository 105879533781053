import { Badge, Box, Button, Icon, Loader, SxProps, Theme, Typography } from "@tm/components"

type Props = {
    disabled: boolean | undefined
    onClick(): void
    text: string
    icon?: string
    sx?: SxProps<Theme> | undefined
    showBadge?: boolean
    className?: string
    variant?: "text" | "outlined" | "contained"
    color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning" | "highlight" | undefined
}

export default function NavigationButton({ disabled, onClick, icon, sx, showBadge, text, className, variant, color }: Props) {
    return (
        <Box display="flex" sx={sx}>
            <Badge badgeContent={showBadge ? <Loader size="small" /> : null}>
                <Button className={className ?? ""} variant={variant || "text"} size="large" disabled={disabled} onClick={onClick} color={color}>
                    <Box display="flex" gap=".5em">
                        {icon && <Icon name={icon} />}
                        <Typography color="inherit">{text}</Typography>
                    </Box>
                </Button>
            </Badge>
        </Box>
    )
}
