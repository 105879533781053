import { createContext, useContextSelector } from "use-context-selector"
import {
    OePartsModuleState,
    DirectSearchModuleState,
    UniversalPartsModuleState,
    VehiclePartsModuleState,
    ProductGroupsModuleState,
    WholesalerArticleNumbersModuleState,
} from "./models"

type PartsModuleState =
    | VehiclePartsModuleState
    | DirectSearchModuleState
    | UniversalPartsModuleState
    | OePartsModuleState
    | ProductGroupsModuleState
    | WholesalerArticleNumbersModuleState

/**
 * Shall not be used directly outside of this file.
 * Use export `usePartsDataContext` instead.
 */
const PartsModuleStateContext = createContext<PartsModuleState | undefined>(undefined)

export const PartsModuleStateProvider = PartsModuleStateContext.Provider

export function usePartsModuleState<TContext extends PartsModuleState>(): TContext
export function usePartsModuleState<TContext extends PartsModuleState, TReturnType>(selector: (value: TContext) => TReturnType): TReturnType
export function usePartsModuleState<TContext extends PartsModuleState, TReturnType>(
    selector?: (value: TContext) => TReturnType
): TContext | TReturnType {
    return useContextSelector(PartsModuleStateContext, (context) => {
        const validatedContext = getValidatedContext(context)

        if (!selector) {
            return validatedContext
        }

        return selector(validatedContext as TContext)
    }) as TContext | TReturnType
}

function getValidatedContext<TContext extends PartsModuleState>(context: TContext | undefined): TContext {
    if (!context) {
        throw new Error(`Used ${usePartsModuleState.name} outside of ${PartsModuleStateProvider.name}`)
    }

    return context
}
