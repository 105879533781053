import type { ModuleTab } from "@tm/models"
import { ChangeDialogState } from "./model"
import { BundleActionType } from "../../../business"

export type ComponentActionType = BundleActionType | { type: "OPEN_TABS_LOADING" } | { type: "OPEN_TABS_LOADED"; payload: Array<ModuleTab> }

const DEFAULT_STATE: ChangeDialogState = {
    openTabsLoading: false,
    openTabs: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ChangeDialogState {
    switch (action.type) {
        case "OPEN_TABS_LOADING": {
            return {
                ...DEFAULT_STATE,
                openTabsLoading: true,
            }
        }
        case "OPEN_TABS_LOADED": {
            return {
                ...state,
                openTabsLoading: false,
                openTabs: action.payload,
            }
        }
    }
    return state
}
