import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"

export default function Hint(props: { hint?: string }) {
    const style = useMemo(() => getStyle(), [])
    const { translate } = useLocalization()
    const { hint } = props

    if (!hint) {
        return null
    }

    return (
        <div className={`search__hint ${style.searchHint}`}>
            <span className={style.bulbWrapper}>
                <Icon name="bulb" />
            </span>
            <div className="search__hint__text" style={{}}>
                <Text size="xs" modifiers={["block", "strong", "light"]}>
                    {translate(813)}
                </Text>
                <Text size="xs" modifiers={["block", "light"]}>
                    {hint}
                </Text>
            </div>
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        searchHint: {
            backgroundColor: theme.colors.primary,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            whiteSpace: "normal",
            padding: ".5em",
        },
        bulbWrapper: {
            fill: theme.colors.light,
        },
    })(Hint)
}
