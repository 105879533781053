import { useRef, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { ECounterType, SortingFilterType, VehicleType } from "@tm/models"
import { concat, TmaHelper } from "@tm/utils"
import Filter from "./_filter"
import { getSorting, useAvailableFilters, useFilters } from "../../../data/hooks"
import { usePreferredSortOptionKey } from "../../../data/hooks/useSortOptions"

type Props = {
    vehicleType: VehicleType
    clipped: boolean
    opened: boolean
    onToggleOpen(): void
    onToggleClip(): void
}

function SortingFilter(props: Props) {
    const { translateText } = useLocalization()
    const filterRef = useRef<React.ElementRef<typeof Filter>>(null)
    const [filters, setFilters] = useFilters(props.vehicleType)
    const [availableFilters] = useAvailableFilters(props.vehicleType)
    const [preferredSortOption, setPreferredSortOption] = usePreferredSortOptionKey()

    const currentSorting = getSorting(filters.sorting)

    useEffect(() => {
        if (preferredSortOption) {
            setFilters((curr) => ({ ...curr, sorting: preferredSortOption }))
        }
    }, [preferredSortOption, setFilters])

    const getText = (item: SortingFilterType) => {
        switch (item) {
            case "relevance":
                return translateText(3157)
            case "alphabetical":
                return translateText(3156)
            default:
        }
    }

    const handleClick = (item: SortingFilterType) => {
        setFilters((curr) => ({ ...curr, sorting: item }))
        setPreferredSortOption(item)
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSort)
    }

    const handleFilterClick = () => {
        if (!props.clipped) {
            filterRef.current?.collapse()
        }
    }

    const renderItem = (item: SortingFilterType) => {
        return (
            <div
                className={concat(" ", "filter__item", currentSorting === item && "filter__item--selected")}
                key={item}
                onClick={() => handleClick(item)}
            >
                {getText(item)}
            </div>
        )
    }

    return (
        <Filter
            {...props}
            onChangeFilter={handleFilterClick}
            name={translateText(3155)}
            ref={filterRef}
            className="filters__filter"
            disabled={!availableFilters.sorting}
            value={!props.opened ? getText(currentSorting) : undefined}
        >
            <div className="filter">
                {availableFilters.sorting && renderItem("alphabetical")}
                {availableFilters.sorting && renderItem("relevance")}
            </div>
        </Filter>
    )
}

export default SortingFilter
