import { useMemo } from "react"
import { Button, MessageToast, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { Message, useForeignUsersInChat } from "../../../../data/hooks"
import { getBundleParams } from "../../../../utils"
import { getComponentStyles } from "./styles"

type Props = {
    message: Message
    setMessage(message: Message | undefined): void
}

export function MessageUser({ message, setMessage }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()
    const { foreignUsers, isLoading } = useForeignUsersInChat(message.chatId)

    if (isLoading) {
        return null
    }

    const user = foreignUsers?.find((u) => u.id === message.authorId)

    return (
        <MessageToast
            message={user?.displayName || ""}
            description={
                <div className={classNames.content}>
                    <Text className={classNames.messageText}>{message.text}</Text>
                    <Button
                        onClick={() => setMessage(undefined)}
                        size="s"
                        layout={["holo"]}
                        linkTo={renderRoute(getBundleParams().chatDetailsRoute, { chatId: message.chatId })}
                    >
                        {translateText(3079)}
                    </Button>
                </div>
            }
            icon="chat"
            onButtonClick={() => setMessage(undefined)}
            className={classNames.main}
        />
    )
}
