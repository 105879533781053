import { useLocalization } from "@tm/localization"
import { useReplaceUrlTags, useUser } from "@tm/context-distribution"
import { useLocation } from "react-router"
import { useExternalCatalogUrl } from "../../hooks/useExternalCatalogUrl"

export function useWebkatUrl(externalSystemId: number) {
    const location = useLocation()
    const { languageId } = useLocalization()
    const { userSettings } = useUser()

    const externalCatalogUrlResult = useExternalCatalogUrl(externalSystemId, 0)
    let externalCatalogUrl = externalCatalogUrlResult.url

    // If "showPurchasePrice" is not set (or false) the "pricefilter" should not be sent at all - see NEXT-19519
    if (externalCatalogUrl && !userSettings?.showPurchasePrice) {
        externalCatalogUrl = externalCatalogUrl.replace("&pricefilter={#pricefilter}", "")
    }

    const url = useReplaceUrlTags(externalCatalogUrl, { location, languageId })

    return {
        url,
        isLoading: externalCatalogUrlResult.isLoading,
    }
}
