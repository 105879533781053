import { Fragment, Suspense, useMemo } from "react"
import { Icon, Loader, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { RouteComponentProps, withRouter } from "@tm/utils"
import ChatInfos from "./components/chat-infos"
import ChatList from "./components/chat-list"
import Messages from "./components/messages"
import { getComponentStyles } from "./styles"

type Props = RouteComponentProps<{ chatId?: string }>

function WidgetWithLoader({ match }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()

    return (
        <div className={classNames.main}>
            <div className={classNames.headlineWrapper}>
                <Typography variant="h2" className={classNames.headline}>
                    Chats
                </Typography>
            </div>

            <div className={classNames.contentWrapper}>
                <div className={classNames.chatlistWrapper}>
                    <Suspense fallback={<Loader />}>
                        <ChatList />
                    </Suspense>
                </div>

                <div className={classNames.messagesWrapper}>
                    {match.params.chatId ? (
                        <Fragment key={match.params.chatId}>
                            <Messages chatId={match.params.chatId} />
                            <ChatInfos chatId={match.params.chatId} />
                        </Fragment>
                    ) : (
                        <div className={classNames.noChatSelected}>
                            <Icon name="info" />
                            <Typography>{translateText(3102)}</Typography>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default withRouter(WidgetWithLoader)
