import { Box, styled } from "@tm/components"
import { border, px } from "csx"

type Props = {
    icon: React.ReactNode
    expandIcon: React.ReactNode
    expanded: boolean
    onClick?: (expanded: boolean) => void
    children: React.ReactNode
    underlinedHeader?: boolean
}

export default function Accordion({ children, icon, expandIcon, expanded, onClick, underlinedHeader }: Props) {
    const result = React.Children.toArray(children)

    const handleClick = () => {
        onClick?.(!expanded)
    }

    return (
        <Box>
            <UnderlinedBox onClick={handleClick} underlinedHeader={underlinedHeader}>
                <Box sx={{ display: "flex" }}>{expanded ? expandIcon : icon}</Box>
                {result[0]}
            </UnderlinedBox>
            {expanded && <Box sx={{ height: "100%", marginLeft: "3em" }}>{result[1]}</Box>}
        </Box>
    )
}

const UnderlinedBox = styled(Box, { shouldForwardProp: (prop) => prop !== "underlinedHeader" })<{ underlinedHeader?: boolean }>(
    ({ underlinedHeader }) => ({
        display: "flex",
        gap: "1em",
        alignItems: "center",
        padding: ".5em 1em",
        ...(underlinedHeader && { borderBottom: border({ color: "#e2e2e2", style: "solid", width: px(2) }) }),
    })
)
