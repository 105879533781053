import { Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { clone } from "@tm/utils"
import { BundleActions, BundleActionTypes } from "../../../business"
import { CarInfo, EreSelectionSteps, HistoryEntry } from "../../../data/models"
import { useContainer } from "../../../data/repositories"
import { createEurotaxGetCalculationDataRequest } from "../../calculation/business/helper"
import { MainState } from "../../main"
import { MainActions, MainActionsType } from "../../main/business"
import { createInitToolRequest } from "./helpers"
import { ICustomerFields, IVehicleInfoFields, SummaryState } from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | {
          type: "VEHICLE_MODELS_LOADED"
          payload: { vehicleModels: Array<CarInfo>; selectedTecDoc: number; minDate?: Date; maxDate?: Date; vehicle?: Vehicle }
      }
    | { type: "VEHICLE_MODELS_LOADING" }
    | { type: "VEHICLE_MODELS_ERROR" }
    | { type: "SET_VEHICLE_MODEL"; payload: CarInfo }
    | { type: "HISTORY_ENTRIES_LOADED"; payload: Array<HistoryEntry> }
    | { type: "HISTORY_ENTRIES_ERROR" }
    | { type: "HISTORY_ENTRIES_LOADING" }
    | { type: "UPDATE_SELECTED_HISTORY_ITEM"; payload: HistoryEntry }
    | { type: "UPDATE_INFO_FIELDS"; payload: IVehicleInfoFields }
    | { type: "UPDATE_CUSTOMER_FIELDS"; payload: ICustomerFields }

export const DEFAULT_STATE: SummaryState = {
    historyData: {},
    vehicleInfoFields: {
        selectedEquipmentPackage: "",
        mileage: 0,
        vin: "",
        plateNumber: "",
    },
    vehicleModels: {},
    customerFields: {},
}

export const reduce = (state = clone(DEFAULT_STATE), action: MainActionsType): SummaryState => {
    switch (action.type) {
        case "RESTART_PROCESS": {
            return {
                ...DEFAULT_STATE,
                vehicleInfoFields: state.vehicleInfoFields,
                historyData: {
                    ...DEFAULT_STATE.historyData,
                    historyEntries: state.historyData.historyEntries,
                    selectedHistoryEntry: state.historyData.selectedHistoryEntry,
                },
                customerFields: {
                    ...state.customerFields,
                },
                vehicleModels: {
                    ...DEFAULT_STATE.vehicleModels,
                    models: state.vehicleModels.models,
                    vehicleModelsLoaded: true,
                },
            }
        }
        case "SET_VEHICLE_MODEL": {
            return {
                ...state,
                vehicleModels: {
                    ...state.vehicleModels,
                    selectedVehicleModel: action.payload,
                },
            }
        }
        case "VEHICLE_MODELS_LOADED": {
            // const { vehicleModels } = action.payload
            // const { vehicleInfoFields: { initialRegistration } } = state

            // let temp

            // if (initialRegistration)
            // 	temp = filterModelsByInitialRegistration(initialRegistration, vehicleModels)

            return {
                ...state,
                vehicleModels: {
                    ...state.vehicleModels,
                    models: action.payload.vehicleModels,
                    // filteredModels: temp,
                    vehicleModelsLoading: false,
                    vehicleModelsError: false,
                    vehicleModelsLoaded: true,
                },
                vehicleInfoFields: {
                    ...state.vehicleInfoFields,
                    minDate: action.payload.minDate,
                    maxDate: action.payload.maxDate,
                },
            }
        }
        case "VEHICLE_MODELS_LOADING": {
            return {
                ...state,
                vehicleModels: {
                    ...DEFAULT_STATE.vehicleModels,
                    vehicleModelsLoading: true,
                },
            }
        }
        case "VEHICLE_MODELS_ERROR": {
            return {
                ...state,
                vehicleModels: {
                    ...DEFAULT_STATE.vehicleModels,
                    vehicleModelsLoading: false,
                    vehicleModelsError: true,
                },
            }
        }
        case "HISTORY_ENTRIES_LOADING": {
            return {
                ...state,
                historyData: {
                    ...DEFAULT_STATE.historyData,
                    historyEntriesLoading: true,
                },
            }
        }
        case "HISTORY_ENTRIES_LOADED": {
            return {
                ...state,
                historyData: {
                    ...DEFAULT_STATE.historyData,
                    historyEntries: action.payload,
                    historyEntriesLoading: false,
                    selectedHistoryEntry: action.payload?.first(),
                },
            }
        }
        case "HISTORY_ENTRIES_ERROR": {
            return {
                ...state,
                historyData: {
                    ...DEFAULT_STATE.historyData,
                    historyEntriesError: true,
                },
            }
        }
        case "UPDATE_SELECTED_HISTORY_ITEM": {
            return {
                ...state,
                historyData: {
                    ...state.historyData,
                    selectedHistoryEntry: action.payload,
                },
            }
        }
        case "UPDATE_INFO_FIELDS": {
            // const { selectedEquipmentPackage, initialRegistration } = action.payload
            // const { models } = state.vehicleModels
            // let temp = clone(models)

            // if(models && initialRegistration)
            // 	temp = filterModelsByInitialRegistration(initialRegistration, models)

            // if(selectedEquipmentPackage)
            // 	temp = temp?.filter((vehicleModel) => vehicleModel.equipment?.toUpperCase().includes(selectedEquipmentPackage.toUpperCase()))

            return {
                ...state,
                vehicleInfoFields: action.payload,
                // vehicleModels: {
                // 	...state.vehicleModels,
                // 	filteredModels: temp
                // }
            }
        }
        case "CUSTOMER_SET": {
            return {
                ...state,
                customerFields: {
                    ...state.customerFields,
                    firstName: action.payload.firstName,
                    lastName: action.payload.lastName,
                },
            }
        }
        case "VEHICLE_SET": {
            // eslint-disable-next-line no-param-reassign
            action.payload.mileAge = action.payload.mileAge && parseInt(action.payload.mileAge.toString())

            return {
                ...state,
                vehicleInfoFields: {
                    ...state.vehicleInfoFields,
                    initialRegistration: action.payload.initialRegistration,
                    mileage: action.payload.mileAge,
                    plateNumber: action.payload.plateId,
                    vin: action.payload.vin,
                },
            }
        }
        case "UPDATE_CUSTOMER_FIELDS": {
            return {
                ...state,
                customerFields: action.payload,
            }
        }
        default: {
            return state
        }
    }
}

const getVehicleModels = (): AsyncAction<ComponentActionType, MainState> => (dispatch, getState) => {
    const {
        manager: { vehicle },
    } = getState()
    dispatch({ type: "VEHICLE_MODELS_LOADING" })

    useContainer()
        .action("getVehicleModels")(vehicle!.tecDocTypeId)
        .then(
            (response) =>
                dispatch({
                    type: "VEHICLE_MODELS_LOADED",
                    payload: {
                        vehicleModels: response.carInfos,
                        selectedTecDoc: vehicle!.tecDocTypeId,
                        minDate: response.minDate,
                        maxDate: response.maxDate,
                    },
                }),
            () => dispatch({ type: "VEHICLE_MODELS_ERROR" })
        )
}

const initExtern =
    (moduleSubId: number, historyEntry?: HistoryEntry): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        dispatch(MainActions.changeStep(EreSelectionSteps.EXTERN))

        const state = getState()

        const request = createInitToolRequest(moduleSubId, state, historyEntry)
        dispatch(MainActions.initTool(request))
    }

const openPreviousCalculation =
    (historyEntry: HistoryEntry): AsyncAction<MainActionsType, MainState> =>
    async (dispatch, getState) => {
        const state = getState()
        const { historyId } = historyEntry
        dispatch({ type: "CALCULATION_DATA_LOADING" })

        const request = createEurotaxGetCalculationDataRequest(historyId, state)
        const response = await useContainer().action("getCalculationData")(request)

        dispatch({ type: "CALCULATION_DATA_LOADED", payload: response })
    }

const getHistory =
    (kType?: number, vehicleId?: string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        dispatch({ type: "HISTORY_ENTRIES_LOADING" })

        useContainer()
            .action("getHistory")({ kType, vehicleId })
            .then(
                (response) => dispatch({ type: "HISTORY_ENTRIES_LOADED", payload: response }),
                () => dispatch({ type: "HISTORY_ENTRIES_ERROR" })
            )
    }

const updateSelectedVehicle = (model: CarInfo): ComponentActionType => ({ type: "SET_VEHICLE_MODEL", payload: model })
const updateHistoryItem = (historyEntry: HistoryEntry): ComponentActionType => ({ type: "UPDATE_SELECTED_HISTORY_ITEM", payload: historyEntry })
const updateVehicleInfoFields = (vehicleInfoFields: IVehicleInfoFields): ComponentActionType => ({
    type: "UPDATE_INFO_FIELDS",
    payload: vehicleInfoFields,
})
const updateCustomerFields = (customerInfoFields: ICustomerFields): ComponentActionType => ({
    type: "UPDATE_CUSTOMER_FIELDS",
    payload: customerInfoFields,
})

export const Actions = {
    ...BundleActions,
    getVehicleModels,
    initExtern,
    openPreviousCalculation,
    getHistory,
    updateHistoryItem,
    updateVehicleInfoFields,
    updateSelectedVehicle,
    updateCustomerFields,
}
