import { FittingPosition } from "@tm/models"
import { ActionDispatch } from "@tm/morpheus"
import { clone } from "@tm/utils"

import { AvailabilityFilterType, BundleActionType, FilterType, SearchType } from "../../../business"
import { Models } from "../../../data"
import * as Helpers from "../../../helper"
import { FiltersState } from "./model"
import { CriterionFilter } from "../../../data/model/uni-parts"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "TOGGLE_FILTER_CLIP"; payload: FilterType }
    | { type: "TOGGLE_FILTER_OPEN"; payload: FilterType }
    | { type: "SET_CLIPPED_FILTERS"; payload: FilterType }
    | { type: "SET_OPENED_FILTERS"; payload: FilterType }

const DEFAULT_STATE: FiltersState = {
    searchType: SearchType.NONE,

    loadingFilters: FilterType.None,
    clippedFilters:
        FilterType.ExtendedAssortment |
        FilterType.Availability |
        FilterType.ProductGroup |
        FilterType.Supplier |
        FilterType.ArticleAttribute |
        FilterType.FittingPosition |
        FilterType.UniCriterion,
    openedFilters: FilterType.ProductGroup | FilterType.Supplier | FilterType.UniCriterion | FilterType.FittingPosition | FilterType.Availability,

    filters: {
        productGroups: [],
        suppliers: [],
        constructionYear: [],
        articleAttributes: [],
        showFittingPosition: false,
        showExtendedAssortment: false,
        uniCriteria: [],
    },

    selectedFilters: {
        productGroupIds: [],
        supplierIds: [],
        articleAttributes: [],
        fittingPosition: FittingPosition.None,
        extendedAssortment: false,
        uniCriteria: [],
        availability: AvailabilityFilterType.None,
    },
}

export function reduce(state = clone(DEFAULT_STATE), action: ComponentActionType): FiltersState {
    switch (action.type) {
        case "SET_SEARCHTYPE": {
            return {
                ...state,
                searchType: action.payload,
            }
        }
        case "FILTERS_LOADING": {
            const { productGroups, suppliers, articleAttributes, uniCritera, constructionYear } = action.payload

            state = { ...state }

            if (productGroups) {
                state.loadingFilters |= FilterType.ProductGroup
            }

            if (suppliers) {
                state.loadingFilters |= FilterType.Supplier
            }

            if (articleAttributes) {
                state.loadingFilters |= FilterType.ArticleAttribute
            }

            if (uniCritera) {
                state.loadingFilters |= FilterType.UniCriterion
            }

            if (constructionYear) {
                state.loadingFilters |= FilterType.ConstructionYear
            }

            return state
        }

        case "FILTERS_LOADED": {
            const {
                productGroups,
                suppliers,
                articleAttributes,
                showExtendedAssortment,
                uniCriteria,
                constructionYear,
                resetFilters,
                requestedFilters,
            } = action.payload
            const anyExtendedFilters = false
            state = { ...state }

            if (productGroups) {
                state.loadingFilters &= ~FilterType.ProductGroup
                state.filters = { ...state.filters, productGroups }
                anyExtendedFilters == anyExtendedFilters || productGroups.find((item) => item.priority != 1)
            }

            if (suppliers) {
                state.loadingFilters &= ~FilterType.Supplier
                state.filters = { ...state.filters, suppliers }
                anyExtendedFilters == anyExtendedFilters || suppliers.find((item) => item.priority != 1)
            }

            if (articleAttributes) {
                state.loadingFilters &= ~FilterType.ArticleAttribute
                state.filters = { ...state.filters, articleAttributes }
                anyExtendedFilters == anyExtendedFilters || articleAttributes.find((item) => item.priority != 1)
            }

            if (uniCriteria) {
                state.loadingFilters &= ~FilterType.UniCriterion
                state.filters = { ...state.filters, uniCriteria }
                anyExtendedFilters == anyExtendedFilters || uniCriteria.find((item) => item.priority != 1)
                // set all unifilters selected
                const selected = uniCriteria.filter((uni) => uni.containsSelected)
                if (selected?.length) {
                    const selectedCriteria: CriterionFilter[] = []
                    selected.forEach((sel) => {
                        sel.criterionFilters.forEach((crit) => {
                            if (crit.isSelected) {
                                crit.priority = 1
                                selectedCriteria.push(crit)
                            }
                        })
                    })
                    state.selectedFilters = { ...state.selectedFilters, uniCriteria: selectedCriteria }
                }
            }

            if (constructionYear) {
                state.loadingFilters &= ~FilterType.ConstructionYear
                state.filters = { ...state.filters, constructionYear }
            }

            if (showExtendedAssortment !== undefined) {
                state.filters = {
                    ...state.filters,
                    showExtendedAssortment: showExtendedAssortment || anyExtendedFilters,
                }
            }

            state.selectedFilters = {
                ...state.selectedFilters,
                availability: resetFilters ? AvailabilityFilterType.None : state.selectedFilters.availability,
            }

            state.filters = {
                ...state.filters,
                requestedFilters,
            }

            return state
        }

        case "FILTERS_ERROR": {
            return {
                ...state,
                loadingFilters: state.loadingFilters & ~FilterType.ProductGroup & ~FilterType.Supplier,
                filters: {
                    ...state.filters,
                    productGroups: [],
                    suppliers: [],
                },
            }
        }

        case "FILTERS_CHANGED": {
            const { productGroupIds, supplierIds, articleAttributes, fittingPosition, extendedAssortment, uniCriteria, availability } = action.payload
            const {
                searchType,
                filters: { productGroups },
            } = state

            state = { ...state }

            if (productGroupIds) {
                state.selectedFilters = { ...state.selectedFilters, productGroupIds }

                // NEXT-15997: check if all filters are set in searchType PRODUCTGROUPS, to show no selected filters
                if (searchType == SearchType.PRODUCTGROUPS) {
                    const allFiltersSet = Object.values(productGroups).every((productGroup) => productGroup.isSelected === true)
                    if (allFiltersSet) {
                        state.selectedFilters.productGroupIds = []
                    }
                }

                // activate fitting position filter if at least one active product group has fitting position options
                if (productGroupIds.length) {
                    state.filters = {
                        ...state.filters,
                        showFittingPosition: state.filters.productGroups.some((x) => productGroupIds.includes(x.id) && x.hasFittingSideFilters),
                    }
                } else {
                    state.filters = {
                        ...state.filters,
                        showFittingPosition: Helpers.getInitialProductGroupFilters(
                            state.filters.productGroups,
                            state.selectedFilters.extendedAssortment
                        ).some((x) => x.hasFittingSideFilters),
                    }
                }

                // // reset article attribute filters on product group change
                // state.filters.articleAttributes = []
                // state.selectedFilters.articleAttributes = []
            }

            if (supplierIds) {
                state.selectedFilters = { ...state.selectedFilters, supplierIds }
            }

            if (articleAttributes) {
                state.selectedFilters = { ...state.selectedFilters, articleAttributes }

                // open attribute filter when set
                if (articleAttributes.length) {
                    state.openedFilters |= FilterType.ArticleAttribute
                }
            }

            if (fittingPosition != undefined) {
                state.selectedFilters = { ...state.selectedFilters, fittingPosition }

                // open fitting position filter when set
                if (fittingPosition != FittingPosition.None) {
                    state.openedFilters |= FilterType.FittingPosition
                }
            }

            if (extendedAssortment != undefined) {
                state.selectedFilters = { ...state.selectedFilters, extendedAssortment }
            }

            if (availability != undefined) {
                state.selectedFilters = { ...state.selectedFilters, availability }
            }

            if (uniCriteria) {
                state.selectedFilters = { ...state.selectedFilters, uniCriteria }
            }

            if (action.payload.constructionYear === 0) {
                state.selectedFilters = { ...state.selectedFilters, constructionYear: undefined }
            } else if (action.payload.constructionYear) {
                state.selectedFilters = { ...state.selectedFilters, constructionYear: action.payload.constructionYear }
            }

            return state
        }

        case "TOGGLE_FILTER_CLIP": {
            return {
                ...state,
                clippedFilters: state.clippedFilters ^ action.payload,
            }
        }

        case "TOGGLE_FILTER_OPEN": {
            return {
                ...state,
                openedFilters: state.openedFilters ^ action.payload,
            }
        }

        case "SET_CLIPPED_FILTERS": {
            return {
                ...state,
                clippedFilters: action.payload,
            }
        }

        case "SET_OPENED_FILTERS": {
            return {
                ...state,
                openedFilters: action.payload,
            }
        }
        case "TOGGLE_FILTERS_OPEN": {
            return {
                ...state,
                openedFilters: action.payload
                    ? 0
                    : FilterType.ProductGroup |
                      FilterType.Supplier |
                      FilterType.ArticleAttribute |
                      FilterType.UniCriterion |
                      FilterType.FittingPosition |
                      FilterType.Availability,
            }
        }
        default:
    }

    return state
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "FILTERS_CHANGED":
            if (action.wasReceived) {
                return
            }
            return action
        default:
            break
    }
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "FILTERS_CHANGED":
            dispatch({ ...action, wasReceived: true })
            break
        case "SET_SEARCHTYPE":
        case "FILTERS_LOADING":
        case "FILTERS_LOADED":
        case "FILTERS_ERROR":
        case "TOGGLE_FILTERS_OPEN":
            dispatch(action)
            break
        default:
            break
    }
}

function changeExtendedAssortmentFilter(extendedAssortment: boolean): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { extendedAssortment } }
}

function changeAvailabilityFilter(availability: AvailabilityFilterType): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { availability } }
}

function changeProductGroupFilters(filters: Array<Models.ProductGroupFilter>, extendedAssortment?: boolean): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { productGroupIds: filters.map((x) => x.id), extendedAssortment } }
}

function changeSupplierFilters(filters: Array<Models.DataSupplierFilter>, extendedAssortment?: boolean): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { supplierIds: filters.map((x) => x.id), extendedAssortment } }
}

function changeArticleAttributeFilters(filters: Array<Models.ArticleAttributeFilter>): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { articleAttributes: filters.map((x) => x.query) } }
}

function changeFittingPositionFilter(fittingPosition: FittingPosition): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { fittingPosition } }
}

function changeUniCriteriaFilters(filters: Array<Models.UniParts.CriterionFilter>): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { uniCriteria: filters } }
}

function changeConstructionYearFilter(filter: number): ComponentActionType {
    return { type: "FILTERS_CHANGED", payload: { constructionYear: filter } }
}

function resetAllFilters(): ComponentActionType {
    return {
        type: "FILTERS_CHANGED",
        payload: {
            ...DEFAULT_STATE.selectedFilters,
            constructionYear: 0,
        },
    }
}

function toggleFilterClip(filterType: FilterType): ComponentActionType {
    return { type: "TOGGLE_FILTER_CLIP", payload: filterType }
}

function toggleFilterOpen(filterType: FilterType): ComponentActionType {
    return { type: "TOGGLE_FILTER_OPEN", payload: filterType }
}

function setClippedFilters(filterType: FilterType): ComponentActionType {
    return { type: "SET_CLIPPED_FILTERS", payload: filterType }
}

function setOpenedFilters(filterType: FilterType): ComponentActionType {
    return { type: "SET_OPENED_FILTERS", payload: filterType }
}

export type IActions = typeof Actions

export const Actions = {
    changeExtendedAssortmentFilter,
    changeAvailabilityFilter,
    changeProductGroupFilters,
    changeSupplierFilters,
    changeArticleAttributeFilters,
    changeFittingPositionFilter,
    changeUniCriteriaFilters,
    changeConstructionYearFilter,
    resetAllFilters,
    toggleFilterClip,
    toggleFilterOpen,
    setClippedFilters,
    setOpenedFilters,
}
