import { em, px } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { ArticleAttribute } from "@tm/models"
import { bem, classes } from "@tm/utils"
import { Tooltip } from "@tm/components"

type SelectableArticleAttribute = (ArticleAttribute & { selected?: boolean })

type Props = {
    items: SelectableArticleAttribute[]
    onSelect: (a: ArticleAttribute) => void
}

export default function TiresIcons({ items, onSelect }: Props) {
    const style = useMemo(() => getStyle(), [])

    const renderIcon = (attr: SelectableArticleAttribute, key: number) =>
        <Tooltip key={key} title={attr.description}>
            <Button size="s" className={classes(bem(style.buttonNumber, attr.value), attr.selected && "selected")} onClick={() =>
                onSelect(attr)}>{attr.value}</Button>
        </Tooltip>

    return (
        <div className={style.attrIcons}>
            {items.map(renderIcon)}
        </div >
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        attrIcons: {
            $nest: {
                ".selected": {
                    outline: px(1),
                    outlineColor: theme.colors.primary,
                    outlineStyle: "auto"
                }
            },
            minWidth: em(6)
        },
        icon: {
            marginRight: theme.margin.m
        },
        buttonNumber: {
            cursor: "pointer",
            width: px(25),
            height: px(25),
            marginLeft: "0.2em",
            color: theme.colors.light,
            textShadow: "0 0 3px #202020",
            backgroundColor: "#2196f3",
            $nest: {
                "&--A": { backgroundColor: "#2d7327", $nest: { "&:active, &:focus": { backgroundColor: "#2d7327" } } },
                "&--B": { backgroundColor: "#55d545", $nest: { "&:active, &:focus": { backgroundColor: "#55d545" } } },
                "&--C": { backgroundColor: "#b5e941", $nest: { "&:active, &:focus": { backgroundColor: "#b5e941" } } },
                "&--D": { backgroundColor: "#f6ed4e", $nest: { "&:active, &:focus": { backgroundColor: "#f6ed4e" } } },
                "&--E": { backgroundColor: "#f6a328", $nest: { "&:active, &:focus": { backgroundColor: "#f6a328" } } },
                "&--F": { backgroundColor: "#dd901f", $nest: { "&:active, &:focus": { backgroundColor: "#dd901f" } } },
                "&--G": { backgroundColor: "#e12816", $nest: { "&:active, &:focus": { backgroundColor: "#e12816" } } },
                "&:hover": {
                    textShadow: "none",
                },
                ".btn content": {
                    opacity: 1
                }
            }

        }
    })(TiresIcons)
}
