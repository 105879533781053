import { RadioButton, styled, Icon, RadioGroup, Box, Button, Stack, TextField } from "@tm/components"

export const RadioButtonSmallWithMargin = styled(RadioButton)({
    marginRight: ".5em",
})
RadioButtonSmallWithMargin.defaultProps = { size: "small" }

export const IconWithMargin = styled(Icon)({
    marginRight: "1rem",
    height: "1.25em",
    width: "1.25em",
})

export const RadioGroupGrid = styled(RadioGroup)<{ rowcap?: string; minwidth?: string }>(({ rowcap, minwidth }) => ({
    paddingTop: "4px",
    paddingLeft: "35px",
    display: "grid",
    gridTemplateColumns: `minmax(${minwidth || "50px"}, auto) 1fr`,
    columnGap: "26px",
    rowGap: rowcap || "4px",
}))

export const BoxWithMarginBottom = styled(Box)({
    display: "flex",
    marginBottom: "-.5rem",
})

export const BoxWithColumnPaddingRight = styled(Box)({
    display: "flex",
    flexDirection: "column",
    paddingRight: "1.5rem",
})

export const BoxWithAlignItems = styled(Box)({
    display: "flex",
    alignItems: "center",
})

export const ButtonWithIcon = styled(Button)({})
ButtonWithIcon.defaultProps = { size: "small", variant: "text" }

export const RowStack = styled(Stack)({
    alignItems: "baseline",
})
RowStack.defaultProps = { direction: "row", spacing: 0.5 }

export const ResponsivTextfield = styled(TextField)(({ theme }) => ({
    width: "40em",

    [theme.breakpoints.down("lg")]: {
        width: "200px",
    },
}))
