import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Loader } from "@tm/components"
import PriceTable from "../../_shared/price-table"
import { useArticleDetailsSharedErpInfos } from "../../data/hooks/useArticleDetailsSharedErpInfos"

export default function PricesComponent() {
    const { translate } = useLocalization()
    const { loading, erpInfo } = useArticleDetailsSharedErpInfos()

    if (loading) {
        return <Loader />
    }

    if (!erpInfo) {
        return null
    }

    return (
        <div className="tk-erp prices">
            <PriceTable prices={erpInfo.prices} graduatedPrices={erpInfo.graduatedPrices} isReplacementPart={erpInfo.isReplacementPart} />
            {erpInfo.isReplacementPart && (
                <PanelSection title={translate(413)} size="s">
                    <div>{translate(1578)}</div>
                </PanelSection>
            )}
        </div>
    )
}
