import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { Box, Button, Icon, Typography, styled } from "@tm/components"
import { viewTranslate } from "../../models"
import { changeStepWithHistory, useFastServiceStore } from "../../data"

type Props = {
    currentStepName: string
    buttonTextId: number
    active: boolean
    icon?: string
    onClick?: () => void
}

export default function NextStep({ currentStepName, buttonTextId, active, icon, onClick }: Props) {
    const { translateText } = useLocalization()

    const navigationSteps = useFastServiceStore((state) => state.stepNavigationState.steps)

    const nextStep = useMemo(
        () => navigationSteps[navigationSteps.findIndex((x) => x.stepName === currentStepName) + 1],
        [navigationSteps, currentStepName]
    )
    const nextTranslationId = useMemo(() => viewTranslate.get(nextStep.stepName), [nextStep.stepName])

    function navigate() {
        changeStepWithHistory(nextStep.stepName)
        onClick?.()
    }

    return (
        <NextStepWrapper hasTranslationId={!!nextTranslationId}>
            {nextTranslationId && (
                <HeadingWrapper>
                    <Typography
                        variant="h1"
                        textTransform="capitalize"
                        marginLeft="0.25rem"
                        opacity="0.8"
                        marginBottom="0.5rem"
                        fontSize="calc(10px + .2vw)"
                    >
                        {translateText(12929)}
                    </Typography>

                    <Box display="flex" alignItems="center" justifyContent="center" fontSize="calc(15px + .4vw)">
                        {nextStep?.icon && (
                            <Icon sx={{ marginLeft: ".25rem", marginRight: "1rem" }} width="1.25em" height="1.25em" name={nextStep.icon} />
                        )}
                        {translateText(nextTranslationId)}
                    </Box>
                </HeadingWrapper>
            )}

            <ButtonWrapper hasHeading={!!nextTranslationId}>
                <Button startIcon={<Icon name={icon} />} disabled={!active} size="large" color="highlight" onClick={navigate}>
                    {translateText(buttonTextId)}
                </Button>
            </ButtonWrapper>
        </NextStepWrapper>
    )
}

const NextStepWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "hasTranslationId",
})<{ hasTranslationId?: boolean }>(({ hasTranslationId }) => ({
    borderBottom: "1px solid",
    borderBottomColor: "#9a9a9a",
    margin: "2em 0 1.2em 0",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    fontSize: "1.5rem",
    lineHeight: "1.2em",
    fontWeight: "400",
    opacity: ".87",
    ...(hasTranslationId && {
        marginBottom: "2.5em",
    }),
}))

const ButtonWrapper = styled(Box, {
    shouldForwardProp: (prop) => prop !== "hasHeading",
})<{ hasHeading?: boolean }>(({ hasHeading }) => ({
    position: "absolute",
    top: "-20px",
    display: "inline-block",
    backgroundColor: "#efefef",
    ...(hasHeading && {
        top: "16px",
    }),
}))

const HeadingWrapper = styled(Box)({
    position: "absolute",
    backgroundColor: "#efefef",
    top: "calc(100% - 1.8em)",
    padding: "0 .5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
})
