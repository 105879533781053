import { AssociatedRepairTimesState } from "./model"

export function getInitialTab(state: AssociatedRepairTimesState): string {
    let initialTab

    if (state.postRepairTimes.length) {
        initialTab = "post"
    } else if (state.excludedRepairTimes.length) {
        initialTab = "excludes"
    } else if (state.preRepairTimes.length) {
        initialTab = "pre"
    } else {
        initialTab = "post"
    }

    return initialTab
}
