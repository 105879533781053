import { ArticleIdentifier, ArticleInfoType, Vehicle } from "@tm/models"

import { ComponentActionType } from "."
import { OrderedPartByVehicle } from "../../../data/model/vouchers"
import { DictionaryItemPair } from "../../_shared/Dictionary"

const ORDERED_ARTICLES_ID = "vehicle-ordered-articles"

function loadArticleList(orderedParts?: Array<OrderedPartByVehicle>, tecDocTypeId?: number): ComponentActionType {
    return {
        type: "LOAD_ARTICLE_LIST",
        payload: {
            articleIdentifier: orderedParts && tecDocTypeId ? mapOrderedPartsToArticleIdentifiers(orderedParts, tecDocTypeId) : [],
            dontResetStateOnUnload: true,
        },
        targetComponentId: ORDERED_ARTICLES_ID,
    }
}

export function mapOrderedPartsToArticleIdentifiers(
    parts: Array<OrderedPartByVehicle>,
    tecDocTypeNo: number
): Array<ArticleIdentifier & { date?: Date }> {
    const identifiers: Array<ArticleIdentifier & { date?: Date }> = []

    parts.forEach((part) => {
        if (
            part.articleInfoType === ArticleInfoType.TecdocArticle &&
            part.articleInfo.dataSupplier &&
            part.articleInfo.dataSupplierArticleNumber &&
            part.articleInfo.productGroup
        ) {
            identifiers.push({
                supplierId: part.articleInfo.dataSupplier.id,
                supplierArticleNo: part.articleInfo.dataSupplierArticleNumber,
                productGroupId: part.articleInfo.productGroup.id,
                tecdocTypeNo: tecDocTypeNo,
                date: part.orderDate,
            })
        }
    })

    return identifiers
}

function setHasDatInfo(hasDatInfo: boolean) {
    return { type: "HAS_DAT_INFO", payload: hasDatInfo }
}

function setVehicleForPrint(vehicle: Vehicle) {
    return { type: "SET_PRINT_VEHICLE", payload: vehicle }
}

function setManufacturerData(items: Array<DictionaryItemPair>) {
    return { type: "SET_MANUFACTURER_DATA", payload: items }
}

function setHasOrderedParts(hasOrderedParts: boolean) {
    return { type: "HAS_ORDERED_PARTS", payload: hasOrderedParts }
}

function setModelImage(imageUrl: string) {
    return { type: "SET_MODEL_IMAGE", payload: imageUrl }
}

export type IActions = typeof Actions

export const Actions = {
    loadArticleList,
    setHasDatInfo,
    setHasOrderedParts,
    setVehicleForPrint,
    setManufacturerData,
    setModelImage,
}
