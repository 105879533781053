import { useLocalization } from "@tm/localization"
import { OrderRecipient, VoucherType } from "@tm/models"
import { Typography, Stack } from "@tm/components"
import { OrderOptions, ReturnRecipient } from "../../../../../../../data/model"
import { RowStack } from "../../../../../../_shared/StyledComponents"
import GeneralFields from "./components/GeneralFields"
import OrderOptionsFields from "./components/OrderOptionsFields"
import ErpState from "./components/ErpState"

type Props = {
    type: VoucherType

    voucherNumber: string
    orderNumber?: string
    customOrderNumber?: string
    catalogOrderNumber?: string
    wholesalerOrderNumber?: string

    currencyCode?: string
    currencySymbol?: string

    orderDate: Date
    orderOptions?: OrderOptions
    orderRecipient?: OrderRecipient

    returnRecipient?: ReturnRecipient
    totalRetailPrice?: number
    totalPurchasePrice?: number
}

export default function VoucherDetailsHeader(props: Props) {
    const {
        catalogOrderNumber,
        currencyCode,
        currencySymbol,
        customOrderNumber,
        orderDate,
        orderNumber,
        orderOptions,
        returnRecipient,
        totalPurchasePrice,
        totalRetailPrice,
        type,
        voucherNumber,
        wholesalerOrderNumber,
        orderRecipient,
    } = props
    const { translateText } = useLocalization()

    let voucherType = translateText(82) // CostEstimation
    if (type === VoucherType.Order) {
        voucherType = translateText(292)
    } else if (type === VoucherType.Return) {
        voucherType = translateText(13256)
    }

    return (
        <Stack spacing={2}>
            <Typography variant="h3">{`${voucherType} ${orderRecipient?.distributorName || ""}`}</Typography>
            <RowStack columnGap={4} rowGap={1} flexWrap="wrap">
                <GeneralFields
                    orderDate={orderDate}
                    voucherNumber={voucherNumber}
                    currencyCode={currencyCode}
                    currencySymbol={currencySymbol}
                    customOrderNumber={customOrderNumber}
                    orderNumber={orderNumber}
                    totalPurchasePrice={totalPurchasePrice}
                    totalRetailPrice={totalRetailPrice}
                    returnRecipient={returnRecipient}
                />
                {orderOptions && <OrderOptionsFields orderOptions={orderOptions} />}
                <ErpState
                    catalogOrderNumber={catalogOrderNumber}
                    customOrderNumber={customOrderNumber}
                    wholesalerOrderNumber={wholesalerOrderNumber}
                />
            </RowStack>
        </Stack>
    )
}
