import { Box, Button, Dialog, Icon } from "@tm/components"
import { ReactNode, useCallback, useState } from "react"
import { UploadSection } from "../uploadSection/UploadSection"

type Props = {
    children?: ReactNode
}

export function UploadButton({ children }: Props) {
    const [isDialogVisible, setDialogVisible] = useState(false)

    const handleToggleDialog = useCallback(() => {
        setDialogVisible((prevState) => !prevState)
    }, [])

    const renderButton = () => {
        if (children) {
            return <Box onClick={handleToggleDialog}>{children}</Box>
        }

        return (
            <Button onClick={handleToggleDialog} sx={{ height: "32px", width: "32px" }}>
                <Icon name="upload" />
            </Button>
        )
    }

    return (
        <>
            {renderButton()}
            <Dialog maxWidth={false} open={isDialogVisible} onOutsideClick={handleToggleDialog} position="top">
                <UploadSection handleCloseViewOptions={handleToggleDialog} />
            </Dialog>
        </>
    )
}
