import { useCallback, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"
import { useUser } from "@tm/context-distribution"
import { Box, Icon, Button, buttonClasses, colors, styled, ListItem, Tooltip, useTheme, Switch, Popover, List } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ECounterType } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { usePartsViewOptions } from "../../ListV2/hooks"
import { useArticleListConfiguration } from "../../ListV2/ArticleListConfiguration"

const OptionsButton = styled(Button)({
    [`&.${buttonClasses.sizeMedium}`]: {
        padding: 7,
        borderColor: colors.grey[400],
    },
})

const OptionsItem = styled(ListItem)({
    padding: "0 0 0 16px",
})

type Option = {
    show: true | "extracted"
    label: string
    checked: boolean
    handler: () => void
    tooltip?: string
}

type Props = {
    elementId?: string
}

export function ArticleListOptions({ elementId }: Props) {
    if (!elementId) {
        return null
    }

    const element = document.getElementById(elementId)

    if (!element) {
        return null
    }

    return <ArticleListOptionsComponent element={element} />
}

function ArticleListOptionsComponent({ element }: { element: HTMLElement }) {
    const { translateText } = useLocalization()
    const buttonRef = useRef<HTMLButtonElement>(null)
    const [open, setOpen] = useState(false)
    const { partsViewSettings, setPartsViewSettings, showPurchasePrice, setShowPurchasePrice, isLoading } = usePartsViewOptions()
    const { userSettingsUpdating } = useUser()
    const { viewOptions } = useArticleListConfiguration()

    const theme = useTheme()
    const textColor = theme.overwrites?.components?.partListV2?.color

    const handleToggleCompactView = useCallback(() => {
        setOpen(false)
        TmaHelper.GeneralCountEvent.Call(partsViewSettings.compactView ? ECounterType.ArticleListShowDetailed : ECounterType.ArticleListShowCompact)

        setPartsViewSettings({
            ...partsViewSettings,
            compactView: !partsViewSettings.compactView,
        })
    }, [setPartsViewSettings, partsViewSettings])

    const handleToggleShowPurchasePrice = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowInvoicePrice, !showPurchasePrice)

        setShowPurchasePrice(!showPurchasePrice)
    }, [setShowPurchasePrice, showPurchasePrice])

    const handleToggleShowArticleImages = useCallback(() => {
        setOpen(false)
        TmaHelper.GeneralCountEvent.Call(
            partsViewSettings.showArticleImages ? ECounterType.ArticleListShowImages : ECounterType.ArticleListHideImages
        )

        setPartsViewSettings({
            ...partsViewSettings,
            showArticleImages: !partsViewSettings.showArticleImages,
        })
    }, [partsViewSettings, setPartsViewSettings])

    const list = useMemo<Option[]>(() => {
        const options: Option[] = []

        if (viewOptions.purchasePrice) {
            options.push({
                show: viewOptions.purchasePrice,
                label: translateText(1132),
                checked: !!showPurchasePrice,
                handler: handleToggleShowPurchasePrice,
            })
        }

        if (viewOptions.compact) {
            options.push({
                show: viewOptions.compact,
                label: translateText(3009),
                checked: !!partsViewSettings.compactView,
                handler: handleToggleCompactView,
                tooltip: translateText(1804),
            })
        }

        if (viewOptions.images) {
            options.push({
                show: viewOptions.images,
                label: translateText(795),
                checked: !!partsViewSettings.showArticleImages,
                handler: handleToggleShowArticleImages,
                tooltip: translateText(1805),
            })
        }

        return options
    }, [
        translateText,
        handleToggleCompactView,
        handleToggleShowArticleImages,
        handleToggleShowPurchasePrice,
        partsViewSettings,
        showPurchasePrice,
        viewOptions,
    ])

    const handleSetOpen = () => {
        setOpen(true)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListViewOptions)
    }

    function render() {
        return (
            <>
                <OptionsButton
                    sx={{ marginTop: "6px" }}
                    ref={buttonRef}
                    variant="outlined"
                    startIcon={<Icon name="settings" />}
                    onClick={handleSetOpen}
                />
                {list
                    .filter((x) => x.show === "extracted")
                    .map(({ label, checked, handler, tooltip }) => (
                        <Tooltip key={label} title={tooltip}>
                            <Switch
                                typographySx={{ color: textColor, fontSize: 9, textTransform: "uppercase" }}
                                disabled={isLoading || userSettingsUpdating}
                                checked={checked}
                                size="small"
                                label={label}
                                onChange={handler}
                                formControlLabelSx={{ flexDirection: "column-reverse", alignItems: "flex-start", marginLeft: 2, marginRight: 0 }}
                            />
                        </Tooltip>
                    ))}
                <Popover
                    anchorEl={buttonRef.current}
                    anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <List>
                        {list
                            .filter((x) => x.show === true)
                            .map(({ label, checked, handler, tooltip }) => (
                                <Tooltip key={label} title={tooltip}>
                                    <OptionsItem>
                                        <Switch disabled={isLoading} checked={checked} size="small" label={label} onChange={handler} />
                                    </OptionsItem>
                                </Tooltip>
                            ))}
                    </List>
                </Popover>
            </>
        )
    }

    return createPortal(render(), element)
}
