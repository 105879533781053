import { Box, Button, Icon } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useMemo } from "react"
import { useHistory } from "react-router"

type Props = {
    icon: string
    targetUrl: string
    justifyContent: "start" | "center" | "end"
    textId: string | number
}

function WidgetButton(props: Props) {
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const history = useHistory()

    const url = useMemo(() => {
        const id = encodeUniqueId(workTaskId || uniqueId())
        return renderRoute(props.targetUrl, { workTaskId: id })
    }, [workTaskId])

    const onClick = () => {
        history.push(url)
    }

    return (
        <Box width="100%" display="flex" justifyContent={props.justifyContent} mt={1}>
            <Button startIcon={props.icon && <Icon name={props.icon} />} onClick={onClick}>
                {translateText(props.textId)}
            </Button>
        </Box>
    )
}

export default WidgetButton
