import { useMemo } from "react"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { FastCalculator } from "@tm/data"
import { bem, saveVehicleToWorkTask } from "../../../data/helpers"
import { CalcInputComponent } from "../../_shared"
import SelectionDialog from "../../_shared/modals/SelectionDialog"
import { useFastCalculatorStore } from "../../../state"

export default function SummaryPreview() {
    const { carModel, widgetContext, selectedOverlayCalcState } = useFastCalculatorStore((state) => ({
        carModel: state.carModel,
        widgetContext: state.selectedCalcState?.context,
        selectedOverlayCalcState: state.selectedOverlayCalcState,
    }))

    const worktask = useWorkTask()
    const style = useMemo(() => getStyle(), [])

    const interactionDialog = selectedOverlayCalcState && FastCalculator.ECalcState.OverlayCalcStates & selectedOverlayCalcState?.type

    const onChangeCallBack = (data: string | Date | number | boolean) => {
        saveVehicleToWorkTask(data, worktask?.workTask?.vehicle, worktask?.attachToWorkTask)
    }

    return (
        <>
            {interactionDialog && <SelectionDialog calcState={selectedOverlayCalcState} />}
            <div className={style.inputs}>
                {widgetContext?.inputs?.map((input) => (
                    <CalcInputComponent
                        key={input.id}
                        item={input}
                        className={bem(style.input, input.style === FastCalculator.ECalcInputStyle.Slider && "slider")}
                        modelDetails={carModel?.modelDetails}
                        onChangeCallBack={onChangeCallBack}
                        showBorder={input.style !== FastCalculator.ECalcInputStyle.Slider}
                    />
                ))}
            </div>
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        inputs: {
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
        },
        input: {
            flex: "1 0 30%",
            marginRight: theme.margin.m,
            $nest: {
                "&--slider": {
                    marginTop: theme.margin.m,
                },
            },
            minWidth: "7em",
            marginBottom: theme.margin.m,
        },
    })(SummaryPreview)
}
