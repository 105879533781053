import { FastCalculator } from "@tm/data"

export function getCalcStateIcon(item: FastCalculator.ECalcStateButtonIcon | undefined) {
    switch (item) {
        case FastCalculator.ECalcStateButtonIcon.Bin:
            return "delete"
        case FastCalculator.ECalcStateButtonIcon.Replace:
            return "replace"
        case FastCalculator.ECalcStateButtonIcon.Add:
            return "plus"
        case FastCalculator.ECalcStateButtonIcon.NoFavorite:
            return "vote-star"
        case FastCalculator.ECalcStateButtonIcon.IsFavorite:
            return "filled-star"
        case FastCalculator.ECalcStateButtonIcon.TMSuggestion:
            return "partsindicator"
        case FastCalculator.ECalcStateButtonIcon.ArtList:
            return "replace"
        default:
            return "none"
    }
}
