import { Article, ErpSystemConfig, RegisteredModels, SupplierArticle } from "@tm/models"
import { Container } from "@tm/nexus"
import { notUndefinedOrNull } from "@tm/utils"
import { useMemo } from "react"
import { selectorFamily, useRecoilValue } from "recoil"

const singleSelector = selectorFamily<
    string | undefined,
    {
        article: SupplierArticle | undefined
        tradeReferenceId: number | undefined
    }
>({
    key: "erp_getMultiWholesalerArticleNosByTraderId",
    get:
        ({ article, tradeReferenceId }) =>
        () => {
            if (!article || !tradeReferenceId) {
                return undefined
            }

            return Container.getInstance<string | undefined>(RegisteredModels.Articles_GetMultiWholesalerArticleNosByTraderId)
                .subscribe(article, tradeReferenceId)
                .load()
                .catch(() => undefined)
        },
})

const multiSelector = selectorFamily<
    string[] | undefined,
    {
        article: SupplierArticle | undefined
        tradeReferenceIds: number[] | undefined
    }
>({
    key: "erp_getMultiWholesalerArticleNosByTraderIds",
    get:
        ({ article, tradeReferenceIds }) =>
        async () => {
            if (!article || !tradeReferenceIds?.length) {
                return undefined
            }

            const results = await Promise.allSettled(
                tradeReferenceIds.map((tradeReferenceId) =>
                    Container.getInstance<string | undefined>(RegisteredModels.Articles_GetMultiWholesalerArticleNosByTraderId)
                        .subscribe(article, tradeReferenceId)
                        .load()
                        .catch(() => undefined)
                )
            )

            return results.map((x) => (x.status === "fulfilled" ? x.value : undefined)).filter(notUndefinedOrNull)
        },
})

export function useArticleWithChangedTradeReference(article: Article | undefined, config: ErpSystemConfig): Article | undefined {
    const traderArticleNo = useRecoilValue(
        singleSelector({
            article: article ? { supplierId: article.supplier.id, supplierArticleNo: article.supplierArticleNo } : undefined,
            tradeReferenceId: config.tradeReferenceId,
        })
    )

    return useMemo(() => {
        if (!traderArticleNo || !article) {
            return undefined
        }

        return {
            ...article,
            traderArticleNo,
        }
    }, [article, traderArticleNo])
}

export function useIsArticleAvailableForMultipleDistributors(article: Article | undefined, configs: ErpSystemConfig[]): boolean {
    const traderArticleNumbers = useRecoilValue(
        multiSelector({
            article: article ? { supplierId: article.supplier.id, supplierArticleNo: article.supplierArticleNo } : undefined,
            tradeReferenceIds: configs.map((x) => x.tradeReferenceId).filter(notUndefinedOrNull),
        })
    )

    if (!traderArticleNumbers) {
        return false
    }

    return traderArticleNumbers.length > 1
}
