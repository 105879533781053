import { Box, Loader } from "@tm/components"
import { replaceUrlTags, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { getExternalCatalogUrl } from "../../data/repositories/url"

export function IframeModuleExternalId() {
    const localization = useLocalization()
    const user = useUser()
    const { userContext } = user
    const workTaskContent = useWorkTask()
    const location = useLocation()
    const history = useHistory()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const [targetUrl, setTargetUrl] = useState<string>()
    const [loading, setLoading] = useState(true)

    const searchParams = useMemo(() => {
        return new URLSearchParams(location.search)
    }, [location.search])

    const externalSystem = useMemo(() => {
        const exId = searchParams.get("id")
        const exIdAsNumber = Number(exId)
        return userContext.externalModules?.find((ext) => ext?.id === exIdAsNumber)
    }, [userContext, searchParams])

    // replace all tokens with next informations
    useEffect(() => {
        if (externalSystem?.id) {
            getExternalCatalogUrl(externalSystem.id, telesalesCustomerNo, userContext)
                .then((url) => {
                    const replacedTags = replaceUrlTags(url, { languageId: localization.languageId }, user, workTaskContent)
                    setTargetUrl(replacedTags)
                })
                .finally(() => {
                    setLoading(false)
                })
        }
    }, [externalSystem?.id, telesalesCustomerNo, userContext, localization, user, workTaskContent])

    useEffect(() => {
        // If the component is displayed in a modal, we do not want to add a new tab, and the useEffect must not be executed.
        if (!targetUrl || !workTaskContent?.workTaskId || location.pathname.includes("modal")) {
            return
        }

        // need a better way ticket for it is => https://jira.dvse.de/browse/NEXT-26093
        channel("WORKTASK", workTaskContent.workTaskId).publish("MODULE/OPENED", {
            isSelected: true,
            url: location.pathname + location.search,
            title: externalSystem?.description || localization.translateText(1832),
        })

        const id = encodeUniqueId(workTaskContent.workTaskId || uniqueId())
        const route = renderRoute(`/:workTaskId/externalId?id=${externalSystem?.id}`, { workTaskId: id })
        history.replace(route)
    }, [targetUrl, workTaskContent?.workTaskId, externalSystem, history, localization, location.pathname, location.search])

    return (
        <Box flex={1}>
            {loading && (
                <Box display="flex" height="100%" justifyContent="center">
                    <Loader />
                </Box>
            )}

            {!loading && targetUrl && (
                <iframe
                    width="100%"
                    height="100%"
                    src={targetUrl}
                    allowFullScreen
                    title={`iExId${externalSystem?.description}${externalSystem?.id}`}
                />
            )}
        </Box>
    )
}
