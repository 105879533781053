import { Box, styled, Typography, Button, Icon, Loader } from "@tm/components";
import { useWorkTask } from "@tm/context-distribution";
import { useLocalization } from "@tm/localization";
import { IMicros, TechnicalNote } from "@tm/models";
import Morpheus from "@tm/morpheus";
import { decodeUniqueId, encodeUniqueId, renderRoute, RouteComponentProps, withRouter } from "@tm/utils";
import { useCallback, useEffect, useMemo, useRef } from "react"
import { getBundleParams } from "../../utils";
import { useGetTechnicalMemoInfo } from "../../data/hooks/useGeTechnicalMemoInfo";

type Props = IMicros["memo-tool"]["memo-technical-notes"] & RouteComponentProps<{technicalNoteId: string}>

function MemoTechnicalInfoMicro(props: Props) {

    const { vehicleId, modelId, engineCode, isModal } = props

    const memoInfoRef = useRef<HTMLDivElement>(null)
    const { technicalNotes, isLoading, error } = useGetTechnicalMemoInfo(modelId, engineCode)
    const { workTask } = useWorkTask() ?? {}
    const selectedPdf = decodeUniqueId(props.match.params?.technicalNoteId ?? "")
    const { memoTechicalNotesModalUrl } = getBundleParams()
    const { translateText } = useLocalization()

    useEffect(() => {
        if (!isModal && !isLoading && !!technicalNotes) {
            memoInfoRef.current?.scrollIntoView()
        }
    }, [isLoading, technicalNotes, isModal])

    const handleOpenModal = useCallback((technicalNoteId: string) => {
        if (memoTechicalNotesModalUrl && workTask?.id) {
            const url = renderRoute(memoTechicalNotesModalUrl, {
                workTaskId: encodeUniqueId(workTask?.id),
                technicalNoteId: encodeUniqueId(technicalNoteId)
            })
            Morpheus.showView("1", url)
        }
    }, [memoTechicalNotesModalUrl, workTask?.id])

    if (!vehicleId) {
        return null
    }

    const renderContent = useMemo(() => {
        if (!isModal) {
            return <>
                {technicalNotes?.map((technicalNote) =>
                    <span key={technicalNote.id}>
                        {technicalNote.memoStr && <Typography>
                            {technicalNote.memoStr}
                        </Typography>}
                        {technicalNote.link && <StyledButton
                            startIcon={<Icon name="file-pdf" />}
                            onClick={(e) => handleOpenModal(technicalNote.id)}
                        >
                            {technicalNote.title}
                        </StyledButton>
                        }
                    </span>
                )}
            </>
        }

        if (selectedPdf || technicalNotes?.length == 1) {

            const technicalNote = selectedPdf ? technicalNotes?.find(x => selectedPdf === x.id) : technicalNotes?.first()
            if (!technicalNote?.link) {
                return null
            }
            return <Box sx={{ display: "flex", height: "100%" }}>
                <iframe
                    style={{ flex: 1 }}
                    className="pdf-url"
                    id="memo-pdf-url"
                    src={location.protocol + technicalNote.link}
                />
            </Box>

        }

        return technicalNotes?.map((technicalNote) =>
            <span key={technicalNote.id}>
                {technicalNote.memoStr && <Typography>
                    {technicalNote.memoStr}
                </Typography>}
                {(!isModal || technicalNotes?.length > 1) && !selectedPdf && technicalNote.link && <StyledButton
                    startIcon={<Icon name="file-pdf" />}
                    href={location.protocol + technicalNote.link}
                    target="_blank"
                >
                    {technicalNote.title}
                </StyledButton>
                }
            </span>
        ) || null
    }, [isModal, technicalNotes, selectedPdf])

    return (
        <StyledWrapperBox ref={memoInfoRef}>
            {isLoading && <Loader />}
            {!error && !isLoading && renderContent}
            {(error || !technicalNotes?.length) && !isLoading && <Typography variant="body2">{translateText(13216)}</Typography>}
        </StyledWrapperBox>
    )
}

const StyledWrapperBox = styled(Box)({
    margin: "1.5em 0",
    height: "90%"
})

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme?.margin?.m,
}))

export default withRouter(MemoTechnicalInfoMicro)