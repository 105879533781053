import { useLocalization } from "@tm/localization"
import { CisAccount } from "@tm/models"
import Field from "./Field"

type Props = {
    account?: CisAccount
}

export default function AccountBalanceComponent({ account }: Props) {
    const { translateText, currency } = useLocalization()
    const { creditInfo, currencyCode } = account ?? {}

    if (!currencyCode || !creditInfo) {
        return null
    }

    // These three values should be rendered in a shared components and used in widget-credit, widget and summary
    const creditLimit = parseFloat(creditInfo.limit)
    const creditDrawn = parseFloat(creditInfo.drawn)
    const creditAvailable = creditLimit - creditDrawn

    return (
        <>
            {!Number.isNaN(creditLimit) && <Field title={translateText(421)}>{currency(creditLimit, currencyCode)}</Field>}
            {!Number.isNaN(creditDrawn) && <Field title={translateText(422)}>{currency(creditDrawn, currencyCode)}</Field>}
            {!Number.isNaN(creditAvailable) && <Field title={translateText(423)}>{currency(creditAvailable, currencyCode)}</Field>}
        </>
    )
}
