import { WorkTaskInfo } from "@tm/context-distribution"
import { CreateRequest, create } from "@tm/data/vouchers/costEstimations"
import { useLocalization } from "@tm/localization"
import { RegisteredModels, RegistrationNoType, channel } from "@tm/models"
import { Container } from "@tm/nexus"
import { getVehicleRegistrationNoLabelTextIds, openStreamPdf } from "@tm/utils"
import { useCallback, useMemo } from "react"
import { atomFamily, useRecoilState } from "recoil"
import { PostCreateActionType, bundleChannel } from "../../business"
import { OfferState } from "../../components/cost-estimation/business"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import { mapCreateCostEstimationRequest } from "../mapper"
import { sendCostEstimation } from "../repositories/dms/iqt"

function showCostEstimationPdf(costEstimationId: string, translateText: ReturnType<typeof useLocalization>["translateText"]) {
    Container.getInstance<Array<number>>(RegisteredModels.Vouchers_ShowCostEstimationPdf)
        .subscribe(costEstimationId)
        .load()
        .then((costEstimationPdf) => {
            if (costEstimationPdf) {
                openStreamPdf(costEstimationPdf, translateText)
            }
        })
}

type State = {
    loading: boolean
    error: any
}

const createCostEstimationVoucherAtom = atomFamily<State, WorkTaskInfo>({
    key: "basket_createCostEstimationVoucherAtom",
    default: { loading: false, error: {} },
})

export function useCreateCostEstimationVoucher(workTask: WorkTaskInfo, offerState?: OfferState) {
    const { translateText } = useLocalization()
    const { workTaskBasketCalculation, costEstimation } = useWorkTaskBasketState(workTask.id)

    const [state, setState] = useRecoilState(createCostEstimationVoucherAtom(workTask))

    const registrationNoLabel = useMemo(() => {
        const itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(workTask.vehicle?.registrationTypeId || RegistrationNoType.Kba)
        return itemLabelTextIds?.label ? translateText(itemLabelTextIds.label) : undefined
    }, [workTask.vehicle])

    const request: CreateRequest | undefined = useMemo(() => {
        if (!offerState) {
            return
        }
        return mapCreateCostEstimationRequest(
            workTask,
            offerState,
            costEstimation.state.repairTimeDivision,
            costEstimation.state.currencyCode,
            costEstimation.state.currencySymbol,
            registrationNoLabel,
            costEstimation.state.costEstimation,
            costEstimation.state.costEstimationParts,
            costEstimation.state.works,
            workTaskBasketCalculation?.calculatedCostEstimation,
            costEstimation.state.showSupplierArticleNumbers,
            costEstimation.state.showManufacturers,
            costEstimation.state.showWholesalerArticleNumbers,
            costEstimation.state.showVehicleImage
        )
    }, [offerState, workTask, registrationNoLabel, costEstimation.state, workTaskBasketCalculation?.calculatedCostEstimation])

    const createCostEstimationVoucher = useCallback(
        (postCreateAction?: PostCreateActionType) => {
            if (!request) {
                return
            }
            setState((prev) => ({ ...prev, loading: true }))
            create(request)
                .then((response) => {
                    const { id } = response || {}
                    if (id) {
                        switch (postCreateAction) {
                            case PostCreateActionType.ShowPdf:
                                showCostEstimationPdf(id, translateText)
                                break
                            case PostCreateActionType.SendChatMessage:
                                channel("WORKTASK", workTask.id).publish("NOTIFICATIONS/SEND_COST_ESTIMATION_CHAT_MESSAGE", {
                                    costEstimationId: id,
                                })
                                break
                            case PostCreateActionType.PublishLink:
                                sendCostEstimation({ voucherId: id, workTaskId: workTask.id })
                                    .then((pdfUrl) => {
                                        if (pdfUrl) {
                                            bundleChannel().publish("COST_ESTIMATION_LINK_CREATED", { link: pdfUrl })
                                        } else {
                                            bundleChannel().publish("COST_ESTIMATION_LINK_CREATION_ERROR", {})
                                        }
                                    })
                                    .catch((error: Error) => bundleChannel().publish("COST_ESTIMATION_LINK_CREATION_ERROR", { error }))
                                break
                            default:
                                break
                        }
                    } else {
                        setState((prev) => ({ ...prev, error: "create cost estimation error" }))
                    }
                })

                .catch((e) => setState((prev) => ({ ...prev, error: e })))
                .finally(() => setState((prev) => ({ ...prev, loading: false })))
        },
        [request, workTask.id]
    )

    return { createCostEstimationVoucher, creatingCostEstimationVoucher: state.loading, errorCreatingCostEstimationVoucher: state.error }
}
