import { Box, Button, Icon, LinkTab, Stack, Tabs, Tooltip, Typography } from "@tm/components"
import { useModuleNavigation, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ECounterType, ModuleTab } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { forwardRef, SyntheticEvent, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { Refs } from "../component"
import { checkVehicleDependency, isDigit } from "../helpers"
import { TabIcon } from "./TabIcon"
import { TabItem } from "./TabItem"
import { StylingFromConfig } from "../../../data"

type Props = StylingFromConfig & {
    worktaskDashboardTitle?: string
    visibleTabs: ModuleTab[]
    componentUrl: string
    moreTabs: ModuleTab[]
    showTooltip: boolean
    handleTooltipShow(): void
    handleTooltipHide(): void
    btnClassName: string
}

export const IndustryTabs = forwardRef<Refs, Props>((props: Props, ref) => {
    const wrapperElement = useRef<HTMLDivElement>(null)
    const navigationElement = useRef<HTMLDivElement>(null)
    const moreElement = useRef<HTMLDivElement>(null)

    useImperativeHandle(ref, () => ({
        wrapperElement,
        navigationElement,
        moreElement,
    }))

    const { worktaskDashboardTitle, visibleTabs, componentUrl, moreTabs, showTooltip, handleTooltipShow, handleTooltipHide, btnClassName } = props
    const { workTask } = useWorkTask() ?? {}
    const { closeTab } = useModuleNavigation()
    const { translateText } = useLocalization()

    const selectedIndex = visibleTabs.findIndex((x) => x.isSelected)
    const [tabValue, setTabValue] = useState(selectedIndex === -1 ? 0 : selectedIndex + 1)

    useEffect(() => {
        setTabValue(selectedIndex === -1 ? 0 : selectedIndex + 1)
    }, [selectedIndex])

    const dashboardTitle = useMemo(() => {
        if (worktaskDashboardTitle) {
            return isDigit.test(worktaskDashboardTitle) ? translateText(worktaskDashboardTitle) : worktaskDashboardTitle
        }
        return translateText(671)
    }, [worktaskDashboardTitle])

    const handleChangeTabs = (_e: SyntheticEvent, newValue: number) => {
        setTabValue(newValue)
    }

    const handleCloseTab = useCallback(
        (ev: MouseEvent, url: string) => {
            ev.stopPropagation()
            ev.preventDefault()
            closeTab(url)
            handleTooltipHide()
            setTabValue(0)
        },
        [closeTab]
    )

    const renderLabel = (tabTitle: string, url: string, iconName?: string, isDefault?: boolean) => {
        return (
            <Stack flexDirection="row" alignItems="center">
                <TabIcon icon={iconName} />
                <Typography variant="h4">{tabTitle}</Typography>
                {url.length > 0 && !isDefault && (
                    <Box className="tab__close">
                        <Icon name="close" className="icon" onClick={(e) => handleCloseTab(e as unknown as MouseEvent, url)} size="16px" />
                    </Box>
                )}
            </Stack>
        )
    }

    return (
        <Box className="worktask-modules__wrapper" ref={wrapperElement}>
            <Box ref={navigationElement}>
                <Tabs value={tabValue} onChange={handleChangeTabs}>
                    <LinkTab
                        key={componentUrl}
                        component={Link}
                        value={0}
                        to={componentUrl}
                        label={renderLabel(dashboardTitle, "", "dashboard")}
                        onClick={() => {
                            TmaHelper.GeneralCountEvent.Call(ECounterType.StartPageWorktask)
                        }}
                    />
                    {visibleTabs.map((item, index) => (
                        <LinkTab
                            key={item.url}
                            component={Link}
                            value={index + 1}
                            to={item.url}
                            label={renderLabel(translateText(item.title), item.url, item.icon, item.isDefault)}
                            disabled={!checkVehicleDependency(workTask?.vehicle, item.vehicleDependency)}
                        />
                    ))}
                </Tabs>
            </Box>
            <Box className="more__wrapper" ref={moreElement}>
                <Tooltip
                    className="more__list"
                    onClose={handleTooltipHide}
                    onClickAway={handleTooltipHide}
                    open={showTooltip}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <Box>
                            {moreTabs.map((item) => (
                                <TabItem key={item.url} item={item} isMoreItem onClose={handleTooltipHide} style={props.style} />
                            ))}
                        </Box>
                    }
                    variant="light"
                >
                    <Box>
                        <Button className={btnClassName} onClick={handleTooltipShow}>
                            ...
                        </Button>
                    </Box>
                </Tooltip>
            </Box>
        </Box>
    )
})
