import { Box, Tooltip, Typography } from "@tm/components"
import { PartDataSupplier, VehicleManufacturer } from "@tm/models"
import { OverflowTypography } from "../../../../../../../../_shared/StyledComponents"

type PartDetailsProps = {
    dataSupplier?: PartDataSupplier
    vehicleManufacturer?: VehicleManufacturer
    manufacturerName: string | undefined
    isBigScreen: boolean
}

export default function ArticleManufacturer({ dataSupplier, vehicleManufacturer, manufacturerName, isBigScreen }: PartDetailsProps) {
    let manufacturer = ""
    if (dataSupplier) {
        manufacturer = dataSupplier.name
    } else if (vehicleManufacturer) {
        manufacturer = vehicleManufacturer.name
    } else if (manufacturerName) {
        manufacturer = manufacturerName
    }
    if (!manufacturer) {
        return null
    }

    return isBigScreen ? (
        <Typography>{manufacturer}</Typography>
    ) : (
        <Tooltip title={manufacturer} variant="light">
            <Box>
                <OverflowTypography width="50px" whiteSpace="nowrap">
                    {manufacturer}
                </OverflowTypography>
            </Box>
        </Tooltip>
    )
}
