import { WheelsConfiguratorState } from './model'
import { AsyncAction } from '@tm/morpheus'
import { Repositories, } from '../../../data'
import { ComponentActionType } from '.'
import { ImagePaths, RimsPosition, PreyssRim, PreyssCar, Color } from '../../../data/model'
import { createImagePathsRequest } from './helper'
import { MainState } from '../../main'

export const CONFIGURATOR_DEFAULT_STATE: WheelsConfiguratorState = {
    _2D: {
        images: {},
        rimSizes: {}
    },
    _3D: {
        available3DCars: [],
        available3DRims: []
    },
    colors: [],
    has3DConfig: false,
    has2DConfig: false,
    height: 0,
    tint: 0,
    display: false,
    renderHidden: false,
    show3D: false,
}

export type ConfiguratorComponentActionType =
    | { type: 'SWITCH_CONFIGURATOR_DISPLAY' }
    | { type: 'SWITCH_CONFIGURATOR_VERSION' }

    | { type: '2D_CONFIGURATOR_LOADING' }
    | { type: '2D_CONFIGURATOR_UPDATE', payload: { images: ImagePaths, rimPositions: RimsPosition } }
    | { type: '2D_CONFIGURATOR_ERROR' }
    | { type: '2D_CONFIGURATOR_ROTATE_IMAGE', payload: number }
    | { type: '2D_CONFIGURATOR_DEGREES_COLORS_LOADED', payload: { degrees: number[], colors: Color[] } }

    | { type: 'CONFIGURATOR_CHANGE_COLOR', payload?: string }
    | { type: 'CONFIGURATOR_CHANGE_HEIGHT', payload: number }
    | { type: 'CONFIGURATOR_CHANGE_TINT', payload: number }

    | { type: 'CONFIGURATOR_RENDER_HIDDEN', payload: boolean }

    | { type: '3D_CONFIGURATOR_LOADING', payload: boolean }
    | { type: '3D_CONFIGURATOR_PREYSS_DATA_LOADED', payload: { rims: PreyssRim[], cars: PreyssCar[], has2DConfig: boolean, has3DConfig: boolean, selectedCar?: PreyssCar } }
    | { type: "CHANGE_DIALOG_DISPLAY", payload: boolean }

export function reduce(state = { ...CONFIGURATOR_DEFAULT_STATE }, action: ComponentActionType): WheelsConfiguratorState {
    switch (action.type) {
        case "SWITCH_CONFIGURATOR_DISPLAY": {
            return {
                ...state,
                display: !state.display,
                renderHidden: false

            }
        }

        case "SWITCH_CONFIGURATOR_VERSION": {
            return {
                ...state,
                show3D: !state.show3D,
            }
        }

        case "2D_CONFIGURATOR_LOADING": {
            return {
                ...state,
                _2D: {
                    ...state._2D,
                    loading: true,
                    error: false
                }
            }
        }

        case "RIM_ARTICLES_LOADED": {
            return {
                ...state,
                ...!action.payload.rims.length
                && {
                    display: false
                }
                || {
                    display: state.has2DConfig || state.has3DConfig
                }
            }
        }

        case "2D_CONFIGURATOR_DEGREES_COLORS_LOADED": {
            const { colors } = action.payload
            return {
                ...state,
                _2D: {
                    ...state._2D,
                    loading: false
                },
                colors,
                selectedColor: state.selectedColor || colors?.[0]?.code
            }
        }

        case "2D_CONFIGURATOR_UPDATE": {
            const { images, rimPositions } = action.payload
            return {
                ...state,
                _2D: {
                    ...state._2D,
                    loading: false,
                    error: false,
                    images,
                    rimPositions
                }
            }
        }

        case "2D_CONFIGURATOR_ERROR": {
            return {
                ...state,
                _2D: {
                    ...state._2D,
                    loading: false,
                    error: true
                },
                has2DConfig: false,
                display: state.has3DConfig
            }
        }

        case "3D_CONFIGURATOR_LOADING": {
            return {
                ...state,
                _3D: {
                    ...state._3D,
                    loading: action.payload
                }
            }
        }

        case "3D_CONFIGURATOR_PREYSS_DATA_LOADED": {
            const { cars, rims, has2DConfig, has3DConfig, selectedCar } = action.payload

            return {
                ...state,
                has2DConfig,
                has3DConfig,
                display: has2DConfig || has3DConfig,
                renderHidden: false,
                _3D: {
                    ...state._3D,
                    available3DCars: cars,
                    available3DRims: rims,
                    selected3DCar: selectedCar
                },
            }
        }

        case "CONFIGURATOR_CHANGE_COLOR": {
            return {
                ...state,
                selectedColor: action.payload || '#ff0000'
            }
        }
        case "CONFIGURATOR_CHANGE_TINT": {
            return {
                ...state,
                tint: action.payload
            }
        }
        case
            "CONFIGURATOR_CHANGE_HEIGHT": {
                return {
                    ...state,
                    height: action.payload
                }
            }

        case "RIM_ARTICLES_ERROR": {
            return {
                ...state,
                has2DConfig: false,
                has3DConfig: false,
            }
        }

        case "CONFIGURATOR_RENDER_HIDDEN": {
            return {
                ...state,
                renderHidden: action.payload
            }
        }

        case "SELECTED_RIM_ARTICLE_LOADED": {
            const {sizes, selectedSize, defaultSizes, defaultRimSize} = action.payload
            if(defaultRimSize && defaultSizes){               
                return {
                    ...state,
                    _2D: {
                        ...state._2D,
                        rimSizes: {
                            ...state._2D.rimSizes,
                            [selectedSize]: sizes,
                            [defaultRimSize]: defaultSizes
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    _2D: {
                        ...state._2D,
                        rimSizes: {
                            ...state._2D.rimSizes,
                            [selectedSize]: sizes
                        }
                    }
                }
            }
        }

        case "CHANGE_DIALOG_DISPLAY": {
            return {
                ...state,
                showDialog: action.payload
            }
        }

        //TODO: also done on vehicle-selection, this check for has2DConfig can be made inside component
        case "SEND_SELECTED_CARD_PARK": {
            return {
                ...CONFIGURATOR_DEFAULT_STATE,
                has2DConfig: !!action.payload.imageId2D
            }
        }
    }
    return state
}

function changeConfiguratorDisplay(): ComponentActionType {
    return { type: "SWITCH_CONFIGURATOR_DISPLAY" }
}

export function switchConfiguratorVersion(): ComponentActionType {
    return { type: "SWITCH_CONFIGURATOR_VERSION" }
}

export function load2DConfiguratorData(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { imageId2D } = getState().wheelsList.base.carPark

        const request = imageId2D ? { idImage: parseInt(imageId2D) || 0 } : undefined
        if (!request) {
            dispatch({ type: "2D_CONFIGURATOR_ERROR" })
            return
        }

        dispatch({ type: "2D_CONFIGURATOR_LOADING" })
        Repositories.loadColors(request).then(
            response => {
                dispatch({ type: "2D_CONFIGURATOR_DEGREES_COLORS_LOADED", payload: response })
            },
            () => dispatch({ type: "2D_CONFIGURATOR_ERROR" })
        )
    }
}

export function load3DConfiguratorData(): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        const { imageId2D, imageId3D } = getState().wheelsList.base.carPark
        const request = { idImage: parseInt(imageId3D || "0"), is3Dgraphic: true }
        Promise.all([Repositories.load3DAvailableCars(), Repositories.load3DAvailableRims()]).then(
            ([cars, rims]) => {
                const { _2D } = getState().wheelsList.configurator
                const selectedCar = cars.find(x => !!imageId3D && imageId3D == x.imgid)
                const has2DConfig = !!imageId2D && !_2D.error
                const has3DConfig = !!(selectedCar && selectedCar.defaultRims && selectedCar.defaultTyres)

                dispatch({ type: "3D_CONFIGURATOR_PREYSS_DATA_LOADED", payload: { cars, rims, has2DConfig, has3DConfig, selectedCar } })
            }
        )
    }
}

function update2DConfigurator(images: ImagePaths, rimPositions: RimsPosition): ComponentActionType {
    return { type: "2D_CONFIGURATOR_UPDATE", payload: { images, rimPositions } }
}

function setConfigLoader(): ComponentActionType {
    return { type: "2D_CONFIGURATOR_LOADING" }
}

function changeConfiguratorColor(colorCode?: string): ComponentActionType {
    return { type: "CONFIGURATOR_CHANGE_COLOR", payload: colorCode }
}

const changeConfiguratorTint = (tint: number): ComponentActionType =>
    ({ type: "CONFIGURATOR_CHANGE_TINT", payload: tint })

const changeConfiguratorHeight = (height: number): ComponentActionType =>
    ({ type: "CONFIGURATOR_CHANGE_HEIGHT", payload: height })

function renderHidden(val: boolean): ComponentActionType {
    return { type: "CONFIGURATOR_RENDER_HIDDEN", payload: val }
}

const changeDialogDisplay = (val: boolean): ComponentActionType =>
    ({ type: "CHANGE_DIALOG_DISPLAY", payload: val })

export type IConfigActions = typeof ConfigActions

export const ConfigActions = {
    changeConfiguratorDisplay,
    switchConfiguratorVersion,
    changeConfiguratorColor,
    update2DConfigurator,
    renderHidden,
    changeDialogDisplay,
    changeConfiguratorTint,
    changeConfiguratorHeight,
    load2DConfiguratorData,
    load3DConfiguratorData,
    setConfigLoader
}