import { Box, styled } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { Scrollbar } from "@tm/controls"
import { useEffect } from "react"
import { useNodesStore, useServicesStore } from "../../data/state"
import Manager from "../manager"
import Navigation from "../navigation/component"
import NodesListThree from "./components/nodesListThree"
import ServicesList from "./components/servicesList"

type Props = {
    route: string
}

function MainComponent(props: Props) {
    const { resetNodesStore } = useNodesStore()
    const { resetServiceStore } = useServicesStore()
    const { vehicle } = useWorkTask()?.workTask ?? {}

    useEffect(() => {
        return () => {
            resetNodesStore()
            resetServiceStore()
        }
    }, [vehicle?.id])

    return (
        <ContainerBox>
            <Manager />
            <Navigation />
            <Wrapper>
                <Side sx={{ boxShadow: 2 }}>
                    <NodesListThree route={props.route} />
                </Side>
                <Side sx={{ boxShadow: 2 }} >
                    <ServicesList />
                </Side>
            </Wrapper>
        </ContainerBox>

    )
}

const ContainerBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    maxHeight: "calc(100vh - 144px)",
    overflow: "hidden"
})

const Side = styled(Scrollbar)(({ theme }) => ({
    margin: theme.margin?.s,
    flex: 1,
    padding: theme.margin?.l

}))

const Wrapper = styled(Box)({
    display: "flex",
    flexDirection: "row",
    flex: 1,
    overflow: "hidden"
})

export default MainComponent
