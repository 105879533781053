import * as React from "react"
import { useLocalization } from "@tm/localization"
import { Button, Badge } from "@tm/controls"
import { createPortal } from "react-dom"
import { CircularProgress } from "@tm/components"

type Props = {
    showApplyBtn?: boolean
    showSkipBtn?: boolean
    skipBtnText?: string
    disabledApplyBtn?: boolean
    loadingApplyBtn?: boolean
    showLoading?: boolean
    onApply?(): void
    onSkip?(): void
}

const ApplyButton: React.FC<Props> = ({ disabledApplyBtn, onApply, onSkip, showApplyBtn, loadingApplyBtn, showSkipBtn, skipBtnText, showLoading }) => {

    const { translateText } = useLocalization()

    const el = document.getElementById("wheels-navigation")

    const renderContent = () => {
        return (
            <div className="applyBtn--wrapper">
                {showLoading && disabledApplyBtn && <CircularProgress sx={{ marginRight: ".5em" }} size={25} />}
                {showApplyBtn && <Button
                    skin="success"
                    size="m"
                    className="btn--apply"
                    disabled={disabledApplyBtn}
                    onClick={onApply}>
                    {loadingApplyBtn && <Badge skin="dark" value={<CircularProgress size={10} />} />}
                    {translateText(22)}
                </Button>}
                {showSkipBtn && <Button
                    size="m"
                    onClick={onSkip}>
                    {skipBtnText || translateText(1116)}
                </Button>}
            </div>
        )
    }

    if (el)
        return createPortal(renderContent(), el)
    else
        return null
}

export default ApplyButton