import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, receive } from "./business"

const associatedRepairTimes: BundleComponent<unknown, typeof component> = {
    name: "associated-repair-times",
    reduce,
    component,
    receive,
}

export default associatedRepairTimes
