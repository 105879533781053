import { useLocalization } from "@tm/localization"
import { useEffect, useMemo, useState } from "react"
import { ArticleErpInfo, DefaultArticleItemState, ErpInformation, ExtendedDeliveryInfo } from "@tm/models"
import { mergeTeccomPrices, mergeWarehouses } from "../../../../../../erp/src/micros/_helpers/useErpInfo"
import { useTeccom } from "../../../../../../erp/src/micros/_helpers/useTeccom"
import { useDefaultErpSystem } from "../useDefaultErpSystem"

export function useArticleExtendedDeliveryInfo(isEnabled: boolean, articleErpInfo?: ArticleErpInfo): DefaultArticleItemState["extendedDeliveryInfo"] {
    const { translateText } = useLocalization()

    const erpSystemConfig = useDefaultErpSystem()
    const erpInfo = articleErpInfo?.state === "success" ? articleErpInfo.response : undefined
    const {
        teccom: teccomInfo,
        loadTeccom: load,
        teccomLoading: isLoading,
    } = useTeccom(erpInfo?.isTeccomRequestAvailable, articleErpInfo?.request, erpSystemConfig?.id)

    const [teccomErpRespone, setTeccomErpResponse] = useState<ErpInformation>()

    useEffect(() => {
        if (!teccomInfo || !erpInfo) {
            return
        }

        setTeccomErpResponse({
            ...erpInfo,
            availability: teccomInfo.specialProcurementErpInformation?.availability ?? erpInfo.availability,
            orderOptions: teccomInfo.specialProcurementErpInformation?.orderOptions ?? erpInfo.orderOptions,
            prices: mergeTeccomPrices(erpInfo.prices, teccomInfo.prices),
            warehouses: mergeWarehouses(translateText, erpInfo.warehouses, teccomInfo),
        })
    }, [teccomInfo, erpInfo, translateText])

    const extendedDeliveryInfo = useMemo<ExtendedDeliveryInfo>(
        () => ({ showExtendedDeliveryInfo: isEnabled, info: teccomErpRespone, load, isLoading }),
        [isEnabled, isLoading, load, teccomErpRespone]
    )

    return extendedDeliveryInfo
}
