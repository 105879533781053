import { RequestArticleDetailsPayload } from "@tm/models"
import { clone, equals } from "@tm/utils"
import { useMemo } from "react"
import { Box, styled } from "@tm/components"
import { ICalculationItem, Part } from "../../../data/models"
import { AlternativeArticle, MainArticle } from "."

type Props = {
    item: ICalculationItem
    onOeArticleSelect(): void
    onAlternativeArticleQuantityChange(quantity: number): void
    onOEQuantityChange(quantity: number): void
    onPartSelect(part: Part): void
    onDetailsClick(article: Part, request: RequestArticleDetailsPayload): void
    onAlternativeClick(item: ICalculationItem): void
    isActive: boolean
}

export default function CalculationItemComponent({
    item,
    onDetailsClick,
    isActive,
    onOeArticleSelect,
    onAlternativeArticleQuantityChange,
    onOEQuantityChange,
    onPartSelect,
    onAlternativeClick,
}: Props) {
    let alternatives = useMemo(() => clone(item.alternativeParts), [item.alternativeParts])
    const index = alternatives?.findIndex((x) => equals(x, item.selectedPart))

    if (item.selectedPart && index > 0) {
        alternatives = alternatives.filter((x) => !equals(x, item.selectedPart))
        alternatives.unshift(item.selectedPart)
    }

    return (
        <StyledBox>
            <MainArticle
                isActive={isActive}
                oeArticle={item.oeArticle}
                showAlternativeBtn={alternatives && !!alternatives.length}
                onAlternativeArticleQuantityChange={onAlternativeArticleQuantityChange}
                onOEQuantityChange={onOEQuantityChange}
                isSelected={item.isSelected}
                onSelect={onOeArticleSelect}
                onDetailsClick={onDetailsClick}
                onAlternativeClick={() => onAlternativeClick(item)}
                selectedPart={item.selectedPart}
            />

            {isActive && (
                <Box className="tk-parts">
                    <Box className="list">
                        <Box className="article-list alternative-part__list">
                            {alternatives.map((part, idx) => (
                                <AlternativeArticle
                                    key={idx}
                                    alternativePart={part}
                                    oeArticle={item.oeArticle}
                                    selectedPart={item.selectedPart}
                                    onSelect={() => onPartSelect(part)}
                                />
                            ))}
                        </Box>
                    </Box>
                </Box>
            )}
        </StyledBox>
    )
}

const StyledBox = styled(Box)(() => ({
    ".calculationItem": {
        marginBottom: "1rem",
        marginRight: "0",
    },
    ".alternatives": {
        padding: 0,
    },
    ".tag__content": {
        fontWeight: "500",
    },
}))
