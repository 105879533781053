import { MouseEvent } from "react"

type EventHandler = (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => void

export const stopPropagation = (handler?: EventHandler): EventHandler => {
    return (event: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        event.stopPropagation()
        handler?.(event)
    }
}
