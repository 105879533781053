import { Loader, styled, IconButton, Table, Stack, Typography, TextField } from "@tm/components"

export const LoaderSmall = styled(Loader)({
    marginTop: ".3rem",
})
LoaderSmall.defaultProps = { size: "extrasmall" }

export const StyledTable = styled(Table)(({ variant }) => ({
    top: variant === "small" ? "0" : undefined,
    bottom: variant === "small" ? "0" : undefined,
    overflowY: "auto",
}))

export const RowStack = styled(Stack)({})
RowStack.defaultProps = { direction: "row" }

export const LightableText = styled(Typography, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode?: boolean }>(({ lightMode }) => ({
    color: lightMode ? "#FFF" : undefined,
}))

export const TypographyTitle = styled(LightableText)({
    marginBottom: "1rem",
})
TypographyTitle.defaultProps = { variant: "body3", color: "primary" }

export const TextBody1Bold = styled(LightableText)({
    fontWeight: "bold",
})

export const ColumnStack = styled(Stack)({
    justifyContent: "space-between",
    height: "100%",
})
ColumnStack.defaultProps = { direction: "column" }

export const OrderDetailsLabel = styled(Typography)({
    fontSize: "10px",
    textTransform: "uppercase",
    opacity: 0.54,
})

export const OverflowTypography = styled(Typography)({
    overflow: "hidden",
    textOverflow: "ellipsis",
})

export const FullWidthTextField = styled(TextField)({})
FullWidthTextField.defaultProps = { fullWidth: true, size: "large" }

export const TotalStack = styled(Stack)({
    alignSelf: "flex-end",
    minWidth: "500px",
})
TotalStack.defaultProps = { px: 2, spacing: 0.5 }
