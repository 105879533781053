import { CustomerVehicleShortInfo, FindVehiclesRequest, FindVehiclesResponse, RegisteredModels, Vehicle } from "@tm/models"
import { Container } from "@tm/nexus"
import { ajax, createBufferedRequestFunction, getStoredAuthorization, mapVehicle, mapVehicleShortInfo, notUndefinedOrNull } from "@tm/utils"
import { getGsiServiceUrl } from ".."
import { mapCustomerVehicleShortInfo } from "../mapper"
import { updateVehicleRecords } from "./vehicle-records"

function getServiceUrl() {
    return `${getGsiServiceUrl()}/Vehicles`
}

function load(id: string): Promise<Vehicle> {
    return new Promise<Vehicle>((resolve, reject) =>
        ajax({
            url: `${getServiceUrl()}/ShowVehicle`,
            authorization: getStoredAuthorization(),
            body: { id },
        }).then((response) => {
            if (response) {
                resolve(mapVehicle(response))
            } else {
                reject()
            }
        }, reject)
    )
}

async function saveVehicle(vehicle: Vehicle): Promise<Vehicle> {
    await ajax({
        url: `${getServiceUrl()}/SaveVehicle`,
        authorization: getStoredAuthorization(),
        body: {
            ...vehicle,
            // returnNewVersion: true, // TODO: Service now could directly return the updated vehicle
            preventUpdateVehicleRecords: true, // Set this parameter so the GSI service handler skips calling "UpdateVehicleRecords" itself
        },
        method: "POST",
    })

    return vehicle
}

async function save(vehicle: Vehicle): Promise<Vehicle> {
    const savedVehicle = await saveVehicle(vehicle)

    // Previously updating the vehicle records was done in the GSI service
    // We had to remove it there (for now) because it was blocking the SaveVehicle handler from finishing earlier --> will be fixed in NEXT-23839 (then this can be removed here)
    // Therefor we moved this call to the frontend, but it should be removed here as soon as we support non-blockin service-to-service calls in the backend.
    setTimeout(() => updateVehicleRecords(savedVehicle.id))

    return savedVehicle
}

async function update(vehicle: Vehicle): Promise<Vehicle> {
    const { id, mileAge, vin, plateId, initialRegistration, engineCode, retailPurchasePrice, retailSalesPrice } = vehicle

    const storedVehicle = await load(id)

    return save({
        ...storedVehicle,
        mileAge,
        vin,
        plateId,
        initialRegistration,
        engineCode,
        retailPurchasePrice,
        retailSalesPrice,
    })
}

function attachCustomer(vehicleId: string, customerId: string): Promise<void> {
    return ajax({
        method: "POST",
        url: `${getServiceUrl()}/AttachVehicleToCustomer`,
        authorization: getStoredAuthorization(),
        body: { vehicleId, customerId },
    })
}

export async function showCustomerVehicles(customerId: string): Promise<CustomerVehicleShortInfo[]> {
    const response = await ajax<{ vehicles?: CustomerVehicleShortInfo[] }>({
        url: `${getServiceUrl()}/ShowCustomerVehicles`,
        authorization: getStoredAuthorization(),
        body: { customerId },
    })

    return response?.vehicles?.map(mapCustomerVehicleShortInfo) ?? []
}

async function showVehicles(requests: string[]): Promise<Vehicle[]> {
    const response = await ajax<{ vehicles?: Vehicle[] }>({
        url: `${getServiceUrl()}/ShowVehicles`,
        body: { vehicleIds: requests },
        authorization: getStoredAuthorization(),
        method: "POST",
    })

    return response?.vehicles?.map(mapVehicle).filter(notUndefinedOrNull) ?? []
}

export const getVehicleBuffered = createBufferedRequestFunction({
    callService: showVehicles,
    mapServiceResponseToResponse: (serviceResponse, request) => serviceResponse.find((v) => v.id === request),
})

export async function findVehicles(body: FindVehiclesRequest): Promise<FindVehiclesResponse | undefined> {
    const response = await ajax<FindVehiclesResponse>({
        url: `${getServiceUrl()}/FindVehicles`,
        authorization: getStoredAuthorization(),
        body,
    })

    if (!response) {
        return undefined
    }

    return {
        ...response,
        vehicles: response.vehicles?.map(mapVehicleShortInfo).filter(notUndefinedOrNull) ?? [],
    }
}

export function registerActions() {
    Container.register({
        name: RegisteredModels.Vehicle,
        modelActions: {
            load,
            save,
        },
        containerActions: {
            update,
            attachCustomer,
            saveVehicle: save,
            findVehicles,
            showCustomerVehicles,
        },
    })
}
