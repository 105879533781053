import { useQuery } from "react-query"
import { BookingRequest } from "../data/models"
import { getBookingResponse } from "../data/repositories"

export function useGetBookings() {
    const request: BookingRequest = {
        limit: 10,
    }

    const { data, isLoading } = useQuery([request.limit], () => getBookingResponse(request), {
        staleTime: 10 * 60 * 1000, // 10 Minutes
    })

    return { bookings: data, isLoading }
}
