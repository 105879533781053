import { ajax, formatVin, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { kba } from "../../helpers"
import { ShowVrcInformationRequest, ShowLookupModulesResponse, ShowVrcInformationResponse, VrcLookupModule } from "../model/vrc-lookup"
import { isVrcModuleUsingKbaNumbers } from "../../helpers/vrc"

function getServiceUrl() {
    return getBundleParams().vrcLookupServiceUrl
}

export function showLookupModulesResponse() {
    const url = `${getServiceUrl()}/ShowLookupModules`
    const authorization = getStoredAuthorization()

    return ajax<ShowLookupModulesResponse>({ url, body: {}, authorization, method: "GET" }).then((response) => response?.modules || [])
}

export function showVrcInformation(body: ShowVrcInformationRequest, module: VrcLookupModule): Promise<ShowVrcInformationResponse | undefined> {
    const url = `${getServiceUrl()}/ShowVrcInformation`
    const authorization = getStoredAuthorization()

    return ajax<ShowVrcInformationResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true).then((response) =>
        mapResponse(response, module)
    )
}

function mapResponse(response: ShowVrcInformationResponse | undefined, module: VrcLookupModule): ShowVrcInformationResponse | undefined {
    if (!response) {
        return response
    }

    return {
        ...response,
        info: {
            ...response.info,
            vin: response.info.vin && formatVin(response.info.vin),
            registrationNumber:
                response.info.registrationNumber && isVrcModuleUsingKbaNumbers(module)
                    ? kba(response.info.registrationNumber)
                    : response.info.registrationNumber,
        },
    }
}
