import { getStoredAuthorization, ajax, TmaHelper } from "@tm/utils"
import { FastCalculation, ImportResponse } from "@tm/models"
import { getBasketServiceUrl } from "../.."

function getServiceUrl() {
    return `${getBasketServiceUrl()}/fastCalculation`
}

export function importFastCalculation(body: FastCalculation.ImportFastCalculationRequest) {
    const url = `${getServiceUrl()}/ImportCalculation`
    const authorization = getStoredAuthorization()

    // eslint-disable-next-line no-param-reassign
    body.log = body.log ?? TmaHelper.AddCustomToBasket.GetUserInteractionLog("import")

    return ajax<ImportResponse>({ url, body, authorization, method: "POST" })
}
