import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { WarningPrompt } from "@tm/controls"
import { Box, Button, Checkbox, Icon, IconButton } from "@tm/components"
import { CostEstimationVoucher } from "../../../../../data/model"

type Props = {
    isSelected: boolean
    costEstimation: CostEstimationVoucher
    onDeleteCostEstimations(ids: Array<string>): void
    onOpenDetails(voucherId: string): void
    onSelectCostEstimation(costEstimationId: string): void
}

export default function CostEstimationActionsComponent(props: Props) {
    const { translateText } = useLocalization()
    const { costEstimation, onDeleteCostEstimations, onOpenDetails, onSelectCostEstimation } = props
    const dialogRef = useRef<WarningPrompt>(null)

    function handleCostEstimationCheck() {
        onSelectCostEstimation(costEstimation.id)
    }

    function handleDeleteVouchers() {
        dialogRef.current?.show()
    }

    function handleDeleteWarningConfirm() {
        onDeleteCostEstimations([costEstimation.id])
    }

    function handleDetailsClick() {
        onOpenDetails(costEstimation.id)
    }

    return (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Button sx={{ mr: 0.5, maxHeight: "30px" }} onClick={handleDetailsClick}>
                {translateText(43)}
            </Button>
            <IconButton sx={{ maxHeight: "30px" }} onClick={handleDeleteVouchers} title={translateText(69)} variant="fill">
                <Icon name="delete" height="15px" width="15px" />
            </IconButton>
            <WarningPrompt
                text={translateText(1752)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={dialogRef}
                key={costEstimation.id}
                onConfirm={handleDeleteWarningConfirm}
            />
            <Checkbox checked={props.isSelected} onChange={handleCostEstimationCheck} />
        </Box>
    )
}
