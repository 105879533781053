import { useCallback, useEffect, useMemo, useState, MouseEvent } from "react"
import { useLocalization } from "@tm/localization"
import { ECounterType, FittingPosition, VehicleType } from "@tm/models"
import { FittingPositionControl } from "@tm/controls"
import { Box, Stack, Typography } from "@tm/components"
import { TmaHelper } from "@tm/utils"
import { useRecoilState } from "recoil"
import { useVehicle } from "../../hooks/useVehicle"
import { AccordionDetailsSmall, AccordionHeadline, AccordionSimple } from "./FiltersStyledComponents"
import { FilterTypeVisibilitiesState } from "../../states"
import { useListType } from "./useListType"
import IconWithTooltip from "../../../_shared/icon-with-tooltip/IconWithTooltip"
import { usePartsModuleState } from "../../PartsModuleState"

type FittingPositionProps = {
    startCollapsed?: boolean
    className?: string
}

const filterVisibilitiesType = "fittingPosition"

export function FittingPositions(props: FittingPositionProps) {
    const listType = useListType()
    const { startCollapsed, className } = props
    const { vehicleType } = useVehicle() ?? {}
    const { translateText } = useLocalization()
    const { fittingPosition, setFittingPosition } = usePartsModuleState((x) => x.filters)

    const [fittingPositionInternal, setFittingPositionInternal] = useState(FittingPosition.None)
    const [visibilities, setVisibilities] = useRecoilState(FilterTypeVisibilitiesState(listType))

    useEffect(() => {
        setFittingPositionInternal(fittingPosition ?? FittingPosition.None)
    }, [fittingPosition])

    const handleChange = useMemo(
        () =>
            setFittingPosition
                ? (position: FittingPosition) => {
                      TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
                      TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListLocationFilter)

                      setFittingPositionInternal(position)
                      setFittingPosition(position)
                  }
                : undefined,
        [setFittingPosition, setFittingPositionInternal]
    )

    const handleReset = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation()
            handleChange?.(FittingPosition.None)
        },
        [handleChange]
    )

    const defaultExpanded = useMemo(() => {
        if (startCollapsed) {
            return false
        }
        return visibilities[filterVisibilitiesType] ?? true
    }, [visibilities, startCollapsed])

    const handleChangeExpansion = useCallback(
        (_: unknown, expand: boolean) => {
            setVisibilities((prev) => {
                const currentState = prev[filterVisibilitiesType] ?? true
                if (expand !== currentState) {
                    return { ...prev, [filterVisibilitiesType]: expand }
                }
                return prev
            })
        },
        [setVisibilities]
    )

    if (!handleChange) {
        return null
    }

    return (
        <AccordionSimple defaultExpanded={defaultExpanded} className={className} onChange={handleChangeExpansion}>
            <AccordionHeadline sx={{ display: "flex" }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
                    <Typography ml={1}>{translateText(352)}</Typography>
                    <Stack direction="row" spacing={1}>
                        <IconWithTooltip variant="remove-filter" onClick={handleReset} />
                    </Stack>
                </Stack>
            </AccordionHeadline>
            <AccordionDetailsSmall>
                <Box pl={0.5}>
                    <FittingPositionControl
                        onChange={handleChange}
                        selected={fittingPositionInternal}
                        isBikeAsVehicle={vehicleType === VehicleType.Motorcycle}
                    />
                </Box>
            </AccordionDetailsSmall>
        </AccordionSimple>
    )
}
