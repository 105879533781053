import { Box, Button, Icon, Typography, styled } from "@tm/components"
import { Demo } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ActivatableModule, ModuleOption, ModulePackage } from "@tm/models"
import { getCombinedModuleOptionsAndPackages, useActivateModule } from "@tm/utils"

type Props = {
    module: ActivatableModule
    handleActivateModule: (module: ActivatableModule, moduleOption?: ModuleOption, modulePackage?: ModulePackage) => void
}

export default function DemoButtons({ module, handleActivateModule }: Props) {
    const { translateText } = useLocalization()
    const { activatingModule } = useActivateModule()

    const { fullModulePackage, demoModulePackage, fullOptionModule, demoOptionModule, isFullPackageActive } =
        getCombinedModuleOptionsAndPackages(module)

    if (isFullPackageActive) {
        return (
            <Box display="flex" gap="0.5em" margin="0.5rem 0.5rem 0 0.5rem" justifyContent="center" padding="7px 11px">
                <Icon name="check-filled" color="#c2e617" />
                <Typography variant="body2">{translateText(12839)}</Typography>
            </Box>
        )
    }

    return (
        <>
            <StyledDemoButton
                variant={demoOptionModule?.active ? "contained" : undefined}
                disabled={activatingModule || (demoOptionModule?.activatable ? demoOptionModule.active : true)}
                onClick={() => handleActivateModule(module, demoOptionModule, demoModulePackage)}
            >
                {demoOptionModule?.active
                    ? `${translateText(12573).replace("{0}", demoOptionModule.amountOfDays?.toString() || "0")}`
                    : translateText(12567)}
                <Demo displayMode="edge" />
            </StyledDemoButton>
            <StyledDemoButton
                color="highlight"
                variant="contained"
                disabled={activatingModule || (fullOptionModule?.activatable ? fullOptionModule?.active : true)}
                onClick={() => handleActivateModule(module, fullOptionModule, fullModulePackage)}
            >
                {translateText(12568)}
            </StyledDemoButton>
        </>
    )
}

const StyledDemoButton = styled(Button)({
    margin: "0.5rem 0.5rem 0 0.5rem",
    "& svg": {
        position: "absolute",
        top: "0",
        right: "0",
        opacity: 1,
        margin: 0,
    },
})
