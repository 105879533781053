import { stylesheet, extend, media } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { breakpoints, margin, opacity } = getStyleTheme()

    return stylesheet({
        header: {
            justifyContent: "space-between",
        },
        headerButton: {
            alignSelf: "center",
        },
        top: {
            display: "flex",
            alignItems: "flex-end",
            flex: 1,

            margin: margin.xl,
            marginTop: margin.s,
            marginBottom: margin.s,

            ...extend(
                media(
                    { maxWidth: breakpoints.s },
                    {
                        flexDirection: "column",
                        alignItems: "center",
                    }
                )
            ),
        },
        resetWrapper: {
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            flex: 1,

            ...extend(
                media(
                    { maxWidth: breakpoints.s },
                    {
                        alignItems: "center",
                    }
                )
            ),
        },
        resetButton: {
            marginTop: margin.l,
            marginBottom: margin.l,
        },
        image: {
            maxHeight: "40vh",
            width: "60vw",
        },
        pdf: {
            height: "100%",
            width: "80%",
        },
        pdfContent: {
            flex: 1,
            display: "flex",
            flexFlow: "column",
        },
        pdfTop: {
            flex: 1,
        },
        pdfBottom: {
            flex: "0 0 35vh",
        },
        topSpacer: {
            flex: 1,
        },

        bottom: {
            marginTop: margin.xl,
        },
        formsWrapper: {
            ...extend(
                media(
                    { minWidth: breakpoints.s },
                    {
                        display: "flex",
                        alignItems: "flex-start",
                    }
                )
            ),
        },
        form: {
            margin: margin.xl,
            flex: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: margin.l,
            $nest: {
                "> div": {
                    flex: "40%",
                },
                ".checkbox": {
                    flex: "100%",
                },
                ".react-datepicker-wrapper, .react-datepicker__input-container": {
                    width: "100%",
                },
            },
        },
        formDisabled: {
            $nest: {
                ".input--textfield": {
                    opacity: opacity.disabled,
                },
            },
        },
        switchWrapper: {
            height: "40px",
            display: "flex",
        },
        switchContent: {
            fontSize: "1rem",
            display: "flex",
            alignItems: "center",
        },
        switchContentIcon: {
            height: "2em",
            width: "2em",
        },
        switchContentText: {
            display: "flex",
            flexDirection: "column",
            marginLeft: margin.m,
        },
    })
}
