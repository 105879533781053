import { useMemo } from "react"
import { ArticleErpInfo, ArticleNotification, MemoType } from "@tm/models"

export function useArticleNotifications(articleErpInfo?: ArticleErpInfo) {
    const erpInfo = articleErpInfo?.state === "success" ? articleErpInfo.response : undefined
    return useMemo<ArticleNotification[] | undefined>(() => {
        return erpInfo?.memos
            ?.filter((memo) => memo.type === MemoType.ArticleListInformation)
            .map((memo) => {
                return {
                    variant: "info",
                    message: memo.text,
                }
            })
    }, [erpInfo])
}
