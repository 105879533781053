import { FastService } from "@tm/data"
import { useMutation } from "react-query"
import { useCallback } from "react"

export function useGetUploadKey() {
    const { isLoading, mutateAsync } = useMutation(FastService.getUploadKey)
    const getUploadKey = useCallback(
        (request: FastService.GetUploadKeyRequest) => {
            return mutateAsync(request)
        },
        [mutateAsync]
    )
    return { isLoading, getUploadKey }
}
