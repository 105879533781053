import { styled, Typography } from "@tm/components"

export const GroupHeaderHeadline = styled(Typography)(({ theme }) => {
    const groupHeader = theme.overwrites?.components?.partlist?.groupHeader
    const color = groupHeader?.monochrom ? theme.palette.common.black : theme.palette.highlight.contrastText
    return {
        fontWeight: 600,
        color,
    }
})
