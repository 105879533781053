import { PropsWithChildren } from "react"
import { getBundleParams } from "../utils"

/**
 * Because some micros are being rendered by other bundles even if the compilations bundle itself is not enabled,
 * this component only renders its children if the compilations bundle is enabled.
 */
export function MicroWrapper({ children }: Required<PropsWithChildren<unknown>>): JSX.Element | null {
    // Wrapped in try catch because "getBundleParams" can throw an error when not initialized because the whole bundle is not enabled
    try {
        getBundleParams()
    } catch {
        return null
    }

    return <>{children}</>
}
