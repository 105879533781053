import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { uniqueId } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import FileSaver from "file-saver"
import { Toolbar, Button } from "@tm/controls"
import { getElekatString } from "../../data"

// Has to stay in this order
require("../../text-encoding/encoding-indexes.js")
// eslint-disable-next-line import/first, import/order, import/no-unresolved
import { TextEncoder } from "text-encoding"

export default function ElekatButtonComponent() {
    const { translateText } = useLocalization()
    const { workTask } = useWorkTask() || {}
    const [downloadingFile, setDownloadingFile] = useState<boolean>(false)

    function handleElekatButtonClick() {
        if (workTask) {
            setDownloadingFile(true)
            getElekatString(workTask.id).then(
                (file) => {
                    const uint8array = new TextEncoder("windows-1252", { NONSTANDARD_allowLegacyEncoding: true }).encode(file)

                    const blob = new Blob([uint8array], { type: "text/plain;charset=windows-1252" })
                    FileSaver.saveAs(blob, `ts${uniqueId()}.wdb`)
                    setDownloadingFile(false)
                },
                () => {
                    setDownloadingFile(false)
                }
            )
        }
    }
    return (
        <div className="tk-birner elekat-button">
            <Toolbar title={translateText(631)}>
                <Button icon="download" onClick={handleElekatButtonClick} disabled={downloadingFile}>
                    {translateText(1358)}
                </Button>
            </Toolbar>
        </div>
    )
}
