import { useLocalization } from "@tm/localization"
import { CellContentPosition, TableCellData, TableColumnData } from "@tm/components"
import { VoucherType } from "@tm/models"
import { CostEstimationVoucher } from "../../../data/model"
import VoucherDetailsButton from "../../_shared/VoucherDetailsButton"
import { NoResultHint } from "../../_shared/NoResultHint"
import { StyledTable } from "../../_shared/StyledComponents"
import VehicleInfo from "../../_shared/VehicleInfo"
import CustomerInfo from "../../_shared/cost-estimations/CustomerInfo"

type Props = {
    costEstimations: CostEstimationVoucher[]
    shortCountryCode: string
    showCustomerColumn?: boolean
}

export default function WidgetCostEstimationsList({ shortCountryCode, showCustomerColumn, costEstimations }: Props) {
    const { translateText, date } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(470) }, // voucherNumber
            { header: translateText(98) }, // voucherCreationDate
        ]

        if (showCustomerColumn) {
            columns.push({ header: translateText(107) }) // CustomerInfo
        }

        columns.push(
            { header: translateText(99) }, // VehicleInfo
            { alignContent: CellContentPosition.right } // VoucherDetailsButton
        )
        return columns
    }

    function getCells(costEstimation: CostEstimationVoucher) {
        const data: TableCellData[] = [
            { displayValue: costEstimation.voucherNumber, id: "1", maxWidth: 80 },
            { displayValue: date(costEstimation.voucherCreationDate, "d"), id: "2" },
        ]

        if (showCustomerColumn) {
            data.push({ displayValue: <CustomerInfo costEstimation={costEstimation} />, id: "3", maxWidth: 100 })
        }

        data.push(
            {
                displayValue: (
                    <VehicleInfo
                        vehicleDescription={costEstimation.vehicleDescription}
                        vehicleMileage={costEstimation.vehicleMileAge}
                        vehiclePlateId={costEstimation.vehiclePlateId}
                        shortCountryCode={shortCountryCode}
                        licensePlateOnTop
                    />
                ),
                id: "4",
            },
            {
                displayValue: (
                    <VoucherDetailsButton
                        variant="small"
                        voucherId={costEstimation.id}
                        voucherWorkTaskId={costEstimation.workTaskId}
                        voucherType={VoucherType.CostEstimation}
                    />
                ),
                id: "5",
            }
        )
        return data
    }

    const displayData = costEstimations.map((costEstimation) => ({
        cells: getCells(costEstimation),
        id: `${costEstimation.id}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable variant="small" sx={{ position: "absolute" }} columns={getColumns()} rows={displayData} rowCap={8} />
}
