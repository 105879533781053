import { useStyle } from "@tm/context-distribution"
import { Icon, Switch, Text } from "@tm/controls"
import * as React from "react"

type Props = {
    name: string
    label: string
    value?: boolean
    icon?: string
    disabled?: boolean
    handleChange(fieldType: string, value: any): void
}

const IncludedAdditionalWorks: React.FC<Props> = (props) => {
    const { name, label, value, icon, disabled, handleChange } = props

    const handle = () => {
        handleChange(name, !value ?? true)
    }

    return (
        <div className={style.wrapper}>
            {icon && <Icon name={icon} />}
            <Text size="xs">{label}</Text>
            <Switch onClick={handle} disabled={disabled} status={value} />
        </div>
    )
}

const style = useStyle({
    wrapper: {
        display: "flex",
        alignItems: "center",
        gap: "0.5em",
    },
})(IncludedAdditionalWorks)

export default IncludedAdditionalWorks
