import { useCallback, useState } from "react"
import { useLocalization } from "@tm/localization"
import { RouteComponentProps, renderRoute, withRouter } from "@tm/utils"
import { SuggestionSearchField, Button, Toolbar } from "@tm/controls"
import { connectComponent } from "@tm/morpheus"
import { IActions, Actions } from "./business"
import { ModuleOpenedPayload } from "../../business"
import { RepairTimesRouteParams } from "../../data"

type Props = RouteComponentProps<RepairTimesRouteParams> & {
    actions: IActions
    moduleOpenedPayload?: ModuleOpenedPayload
}

function DirectSearchComponent(props: Props) {
    const { translateText } = useLocalization()
    const [query, setQuery] = useState((props.match.params.type === "search" && props.match.params.searchQuery) || "")

    const navigateTo = useCallback(
        (type: string, searchQuery?: string) => {
            props.history.push(renderRoute(props.match.path, { ...props.match.params, type, searchQuery }))
            if (props.moduleOpenedPayload) {
                props.actions.updateModuleURL(props.moduleOpenedPayload)
            }
        },
        [props.moduleOpenedPayload, history, props.match.path, props.match.params, props.actions.updateModuleURL]
    )

    const handleChange = useCallback(
        (newQuery: string) => {
            setQuery(newQuery)
            if (!newQuery) {
                navigateTo("select")
            }
        },
        [setQuery, navigateTo]
    )

    const handleSearch = useCallback(
        (newQuery: string) => {
            setQuery(newQuery)
            if (newQuery.length > 1) {
                navigateTo("search", newQuery)
            }
        },
        [setQuery, navigateTo]
    )

    const handleSearchButtonClick = useCallback(() => {
        handleSearch(query)
    }, [handleSearch, query])

    return (
        <Toolbar className="repair-times__direct-search">
            <SuggestionSearchField
                value={query}
                className="input-field"
                onSuggestionSelect={handleSearch}
                onChangeConfirm={handleSearch}
                placeholder={translateText(135)}
                showClear
                onChange={handleChange}
                tooltip={translateText(244)}
                button={<Button onClick={handleSearchButtonClick}>{translateText(135)}</Button>}
            />
        </Toolbar>
    )
}

export default connectComponent(Actions, withRouter(DirectSearchComponent))
