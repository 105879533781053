import { matchPath, useHistory, useLocation, useParams } from "react-router-dom"
import { renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { GlobalNavigationItem } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useShowCisOptions, useWorkTask } from "@tm/context-distribution"
import { DisplayBehaviour } from "@tm/models"
import { getBundleParams } from "../../utils"

export default function CisButtonCopmonent() {
    const { translateText } = useLocalization()
    const { pathname, search } = useLocation()
    const matchParams = useParams()
    const { cisOptions } = useShowCisOptions()
    const workTaskId = useWorkTask()?.workTaskId
    const history = useHistory()

    if (cisOptions?.displayBehaviour !== DisplayBehaviour.Header && cisOptions?.displayBehaviour !== DisplayBehaviour.DashboardAndHeader) {
        return null
    }

    const url = renderRoute(getBundleParams().summaryUrl, { ...matchParams, workTaskId: encodeUniqueId(workTaskId || uniqueId()) })
    function onIconClick() {
        history.push(renderRoute(url, matchParams))
    }

    const isActive = matchPath(pathname + search, { path: getBundleParams().summaryUrl })

    return <GlobalNavigationItem active={!!isActive} onIconClick={onIconClick} name="users" componentType="icon" tooltip={translateText(491)} />
}
