import { Box, Icon, styled } from "@tm/components"
import * as React from "react"
import { YqNode } from "../../models"
import OverflowTypography from "./OverflowTypography"

type TreeItemProps = {
    node: YqNode
    onNodeSelect: (selectedNode: YqNode) => void
    selectedNode?: YqNode
    openedList?: string[]
}

const TreeItem: React.FC<TreeItemProps> = ({ node, onNodeSelect, selectedNode, openedList }) => {
    const { children, name, code } = node
    const [showChildren, setShowChildren] = React.useState(false)

    const isSelected = React.useMemo(() => (selectedNode?.code === code && selectedNode?.name === name) ?? false, [selectedNode, code, name])

    const isOpenedInList = React.useMemo(() => {
        const token = `${code}${name}`
        return !!openedList?.find((oL) => oL === token)
    }, [openedList, code, name])

    React.useEffect(() => {
        if (isOpenedInList) {
            setShowChildren(true)
        }
    }, [isOpenedInList])

    const renderChildren = () => {
        return (
            <Box sx={{ paddingLeft: "1.25em", display: "flex", flexDirection: "column" }}>
                {children?.map((subNodes: YqNode) => (
                    <TreeItem
                        key={subNodes.code ? subNodes.code : subNodes.name}
                        node={subNodes}
                        onNodeSelect={onNodeSelect}
                        selectedNode={selectedNode}
                        openedList={openedList}
                    />
                ))}
            </Box>
        )
    }

    const onClick = () => {
        onNodeSelect?.(node)
        setShowChildren((prev) => !prev)
    }

    return (
        <Box>
            <StyledBox
                id={code || name}
                sx={{ display: "grid", gridTemplateColumns: "5% 95%", gap: "0.5em", marginBottom: "0.5em" }}
                isSelected={isSelected}
                onClick={onClick}
            >
                {children?.length ? <Icon name={showChildren ? "up" : "down"} /> : <div />}
                <OverflowTypography variant="body2" sx={{ ...(isSelected && { color: "primary.main" }) }} title={name} content={name} />
            </StyledBox>
            {showChildren && renderChildren()}
        </Box>
    )
}

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isSelected",
})<{ isSelected?: boolean }>(({ theme, isSelected }) => ({
    cursor: "pointer",
    ...(isSelected && {
        color: theme.palette.primary.main,
    }),
}))

export default TreeItem
