import * as React from "react"
import { Checkbox, Icon, Loader, Table, Text } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { equals } from '@tm/utils'
import { TireSize } from '../../../../data/model'
import { useDispatch, useSelector } from 'react-redux'
import { WheelsDetailsState, Actions } from '../../business'
import { MainState } from "../../../main"

type Props = {}

const TireSizeComponent: React.FC<Props> = ({ }) => {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const selectedSize = useSelector<MainState, WheelsDetailsState["selectedTireSize"]>(s => s.wheelsDetails.selectedTireSize)
    const { data, loading } = useSelector<MainState, WheelsDetailsState["rimInfoTireSizesResponse"]>(s => s.wheelsDetails.rimInfoTireSizesResponse)

    const handleSetTireSize = (item: TireSize) => {
        dispatch(Actions.selectTireSize(item))
    }

    const renderTyreSize = (item: TireSize) => (
        <Table.Cell>
            <div>{item.tyreSize}</div>
        </Table.Cell>
    )

    const renderOperatingLicence = (item: TireSize) => (
        <Table.Cell>
            <Icon className="search-icon" name={item.operatingLicence && 'check-filled' || 'close'} size="l" />
        </Table.Cell>
    )

    const renderShowChain = (item: TireSize) => (
        <Table.Cell>
            <Icon className="search-icon" name={item.showChain && 'check-filled' || 'close'} size="l" />
        </Table.Cell>
    )

    const renderModelSize = (item: TireSize) => (
        <Table.Cell>
            <Icon name={item.modelSize && 'check-filled' || 'close'} size="l" />
        </Table.Cell>
    )

    const renderCheckedTireList = (item: TireSize) => (
        <Table.Cell>
            <div className={equals(selectedSize, item) ? "article-check" : ""} >
                <Checkbox checked={equals(selectedSize, item)} />
            </div>
        </Table.Cell>
    )

    const renderEce = (item: TireSize) => (
        <Table.Cell>
            <Icon name={item.ece && 'check-filled' || 'close'} size="l" />
        </Table.Cell>
    )

    if (loading)
        return <Loader />

    if (!data.length)
        return (
            <div className="article-list__panel article-list__status">
                <Text>{translateText(323)}</Text>
            </div>
        )

    return (
        <div className="tire-sizes-table">
            <Table
                scrollable
                getRowClassName={_ => "is-clickable"}
                onClickRow={handleSetTireSize}
                columns={[
                    <Table.Column renderItemContent={renderTyreSize}>
                        {translateText(968)}
                    </Table.Column>,

                    <Table.Column renderItemContent={renderOperatingLicence}>
                        {translateText(969)}
                    </Table.Column>,

                    <Table.Column renderItemContent={renderShowChain}>
                        {translateText(970)}
                    </Table.Column>,

                    <Table.Column renderItemContent={renderModelSize}>
                        {translateText(971)}
                    </Table.Column>,

                    <Table.Column renderItemContent={renderEce}>
                        ECE
                    </Table.Column>,

                    <Table.Column renderItemContent={renderCheckedTireList}>
                        {translateText(1350)}
                    </Table.Column>
                ]}
                data={data}
            />
        </div>
    )
}

export default TireSizeComponent