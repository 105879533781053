import { AvailabilityStatus } from "@tm/models"
import { AvailabilityFilterType } from "../../../../business"
import { RimItem } from "../../../../data/model"

export const filterRimList = (data: RimItem[], availability: AvailabilityFilterType, availabilityStatusIdsToShow?: AvailabilityStatus[], availabilityStatusIdsToShowSecondary?: AvailabilityStatus[]) => {
    const rims = [...data]
    return React.useMemo(() => {
        if (availability) {
            const availableRims = data.flatMap(rim => {
                const availabilities = rim.rimErpInformations?.filter(info => {
                    let visible = false
                        if (availability === AvailabilityFilterType.Primary && availabilityStatusIdsToShow?.length) {
                            const mod = info.availability
                            if (mod && mod.type && availabilityStatusIdsToShow.includes(mod.type))
                                visible = true
                        }
                        else if (availability === AvailabilityFilterType.Secondary && availabilityStatusIdsToShowSecondary?.length) {
                            const mod = info.availability
                            if (mod && mod.type && availabilityStatusIdsToShowSecondary.includes(mod.type))
                                visible = true
                        }
                        return visible
                })

                const availableDiameterSizes = rim.sizeInfo.filter(sizeInfo => {
                    return availabilities.flatMap(item => item.diameterSize).includes(sizeInfo.size.toString())
                })
                return {...rim, rimErpInformations: availabilities, availableDiameterSizes: availableDiameterSizes}
            })

            return availableRims.filter(item => item.rimErpInformations.length > 0)
        }
        return rims
    }, [rims, availability])
}
