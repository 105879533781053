import { Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { Button } from "@tm/controls"
import { IMicros } from "@tm/models"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-graduated"]

function ErpInfoGraduatedComponent(props: Props) {
    const { translateText } = useLocalization()
    const { loading, erpInfo } = useErpInfo(props.data, "list")

    if (loading || !erpInfo?.hasGraduatedPurchasePrice) {
        return null
    }

    return (
        <Button layout={["ghost"]} onClick={props.onClick} icon="staffelpreis">
            {translateText(705)}
        </Button>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info-graduated">
                    <Suspense fallback={null}>
                        <ErpInfoGraduatedComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
