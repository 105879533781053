import { Availability, ErpPriceDictionary, ItemTour } from "@tm/models"
import { Part, PartAvailability, PartPrices, PartTourInformation } from "../model/sap"
import { BasketPart } from "../../models"

function mapPriceDictionary(prices: ErpPriceDictionary | undefined): PartPrices | undefined {
    if (!prices) {
        return
    }

    const mappedPrices: PartPrices = {}

    Object.entries(prices).forEach(([key, price]) => {
        if (price) {
            mappedPrices[price.type] = {
                currencyCode: price.currencyCode_Iso_4217,
                currencySymbol: price.currencySymbol,
                rebate: price.rebate,
                type: price.type,
                typeName: price.description,
                unit: price.priceUnit,
                value: price.value,
                vat: price.vat,
            }
        }
    })

    return mappedPrices
}

function mapAvailability(availability: Availability | undefined): PartAvailability | undefined {
    if (!availability) {
        return
    }

    return {
        description: availability.description,
        shortDescription: availability.shortDescription,
        type: availability.type,
    }
}

function mapTour(tour: ItemTour | undefined): PartTourInformation | undefined {
    if (!tour) {
        return
    }

    return {
        deliveredQuantity: tour.deliveredQuantity,
        description: tour.description,
        expectedDelivery: tour.expectedDelivery,
        expectedDeliveryString: tour.expectedDeliveryString,
        id: tour.id,
        tourOrderAcceptanceTimeLimit: tour.tourOrderAcceptanceTimeLimit,
        tourStart: tour.tourStart,
        weekday: tour.weekday ?? [],
    }
}

export function mapBasketPartsToParts(basketParts?: BasketPart[]): Array<Part> | undefined {
    if (!basketParts?.length) {
        return
    }
    return basketParts.map((basketPart) => {
        const { partItem, erpInfoResponse, references } = basketPart
        const { articleInformation, quantity, orderItem } = partItem

        const part: Part = {
            wholesalerArticleNumber: articleInformation.wholesalerArticleNumber,
            dataSupplierId: articleInformation.supplierId,
            dataSupplierName: articleInformation.supplierName,
            dataSupplierArticleNumber: articleInformation.articleNumber,
            productGroupId: articleInformation.productGroupId,
            productGroupName: articleInformation.productGroupName,
            description: articleInformation.description,
            additionalDescription: articleInformation.additionalDescription,
            quantity: quantity?.value,
            quantityUnit: quantity.unit,
            quantityMinimum: erpInfoResponse?.quantity?.minQuantity,
            quantityDivision: erpInfoResponse?.quantity?.division || quantity.division,
            prices: mapPriceDictionary(erpInfoResponse?.prices),
            availability: mapAvailability(erpInfoResponse?.availability),
            tour: mapTour(
                erpInfoResponse?.tour ?? erpInfoResponse?.warehouses?.find((y) => y.isDefault)?.tour ?? erpInfoResponse?.warehouses?.first()?.tour
            ),
            memo: orderItem?.memo,

            // TODO: specialIcons are not yet supplied by the basket service for the erp item response
            // TODO: identify the correct specialIcon by comparing the description won't work, because it's language depended
            safetyDataSheetUrl: erpInfoResponse?.specialIcons?.find(
                (y) => y?.description === "Sicherheitsdatenblatt" || y?.description === "safety data sheet"
            )?.linkUrl,

            // TODO: Find a better solution: the reference numbers (really only one utility number) are currently only required for Birner, because they contain the BirnerMatchCode which should be exported through OCI
            referenceNumbers: references?.map((y) => ({ ...y, referenceNumber: y.referenceNo, referenceNo: undefined })),
        }

        return part
    })
}
