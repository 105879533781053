import { useMemo } from "react"
import { Icon } from "@tm/controls"
import { renderRoute, RouteComponentProps, withRouter, decodeUniqueId } from "@tm/utils"

type Props = RouteComponentProps<{ workTaskId?: string }> & {
    icon: string
    targetUrl: string
    className?: string
}

function WorkTaskTabIconComponent(props: Props) {
    const { history, match, targetUrl, className, icon } = props

    function needsWorkTaskId() {
        return !!(targetUrl.indexOf(":workTaskId") >= 0 && props.targetUrl.indexOf(":workTaskId?") < 0)
    }

    function hasValidWorkTaskId() {
        const {
            params: { workTaskId },
        } = match
        const decodedId = workTaskId && decodeUniqueId(workTaskId)
        return !!(decodedId && /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(decodedId))
    }

    function isDisabled() {
        return !!(needsWorkTaskId() && !hasValidWorkTaskId())
    }

    function getConcreteTargetUrl() {
        let route = needsWorkTaskId() && !hasValidWorkTaskId() ? targetUrl : renderRoute(targetUrl, match.params)

        if (!route.startsWith("/")) {
            route = `/${route}`
        }

        return route
    }

    const isSelected = useMemo(() => {
        return document.location.pathname === getConcreteTargetUrl() && !isDisabled()
    }, [document.location.pathname])

    function handleOnClick() {
        history.push(getConcreteTargetUrl())
    }

    const disableButton = isDisabled()

    return (
        <div onClick={!disableButton ? handleOnClick : undefined} className={`bnd-misc${className ? ` ${className}` : ""}`}>
            <div className={`tab tab--worktask${isSelected ? " is-selected" : ""}${disableButton ? " tab--disabled" : ""}`}>
                <div className="tab__content">
                    <Icon size="l" name={icon} />
                </div>
            </div>
        </div>
    )
}

export default withRouter(WorkTaskTabIconComponent)
