import { useLocalization } from "@tm/localization"
import { CisVoucherType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { Box, CellContentPosition, TableCellData, TableColumnData, TableVariants } from "@tm/components"
import { Voucher, ConfirmationDisplay } from "../../../../../data/model"
import { StyledTable } from "../../../../_shared/StyledComponents"
import DateCell from "../../../../_shared/cells/DateCell"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import VoucherOptionsCell from "../../../../_shared/cells/VoucherOptionsCell"
import ConnectedVouchersTable from "../../../../_shared/tables/ConnectedVouchersTable"
import { getBundleParams } from "../../../../../utils"

type Props = {
    openedConnectedVouchers: Array<string>
    selectedVoucherId?: string
    voucherTypeId: number
    vouchers: Array<Voucher>
    compact: boolean
    variant: TableVariants
    loadNextPage(): void
    onExpandRowClick?(voucherId: string): void
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function StandardVoucherTableComponent(props: Props) {
    const { vouchers, selectedVoucherId, openedConnectedVouchers, voucherTypeId, compact, variant } = props
    const { translateText, currency } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false
    const { cisVoucherUrl } = getBundleParams()

    function renderConnectedVouchers(voucher: Voucher) {
        return (
            <Box flex={1}>
                <ConnectedVouchersTable key="connectedVouchers" parentVoucherId={voucher.voucherId} vouchersParentTypeId={voucherTypeId} />
            </Box>
        )
    }

    function renderOptionsCell(voucher: Voucher) {
        const showVoucherConfirmationButton =
            !compact &&
            voucher.confirmationOptions?.canBeConfirmed &&
            (voucher.confirmationOptions.display === ConfirmationDisplay.ShowOnList ||
                voucher.confirmationOptions.display === ConfirmationDisplay.ShowOnListAndDetails)
        return (
            <VoucherOptionsCell
                detailsRoute={cisVoucherUrl}
                hasConnectedVouchers={voucher.hasConnectedVouchers}
                openedConnectedVouchers={openedConnectedVouchers}
                selectedVoucherId={selectedVoucherId}
                voucherId={voucher.voucherId}
                itemsCount={voucher.itemsCount}
                voucherTypeId={voucherTypeId}
                showConnectedVouchersButton={!compact}
                showConfirmVoucherButton={showVoucherConfirmationButton}
                voucher={voucher}
                onExpandRowClick={props.onExpandRowClick}
                onOpenNewVoucher={props.onOpenNewVoucher}
            />
        )
    }

    function getModuleColumns() {
        const columns: TableColumnData[] = [{ header: translateText(32) }, { header: translateText(471) }, { header: translateText(440) }]

        if (voucherTypeId !== CisVoucherType.OldParts) {
            columns.push({ header: translateText(40) })
        }

        columns.push(
            { header: translateText(465) },
            { header: translateText(479), alignContent: CellContentPosition.center },
            { header: translateText(494), alignContent: CellContentPosition.right }
        )

        if (!hidePurchasePrice) {
            columns.push({ header: translateText(495), alignContent: CellContentPosition.right })
        }

        columns.push({ alignContent: CellContentPosition.right })

        return columns
    }

    function getCompactColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(32) },
            { header: translateText(471) },
            { header: translateText(62) },
            { header: translateText(440) },
            { alignContent: CellContentPosition.right },
        ]
        return columns
    }

    function getColumns() {
        if (compact) {
            return getCompactColumns()
        }

        return getModuleColumns()
    }

    function getModuleCellData(voucher: Voucher) {
        const cellData: TableCellData[] = [
            { displayValue: voucher.voucherId, id: "order_0" },
            { displayValue: <DateCell value={voucher.voucherDate} mode="onlyDate" />, id: "order_1" },
            { displayValue: voucher.paymentInformation, id: "order_2" },
        ]

        if (voucherTypeId !== CisVoucherType.OldParts) {
            cellData.push({ displayValue: voucher.deliveryType, id: "order_3" })
        }

        cellData.push(
            { displayValue: voucher.information, id: "order_4" },
            { displayValue: voucher.itemsCount, id: "order_5" },
            { displayValue: currency(voucher.totalGross || 0, voucher.currencyCode), id: "order_6" }
        )

        if (!hidePurchasePrice) {
            cellData.push({ displayValue: currency(voucher.totalNet || 0, voucher.currencyCode), id: "order_7" })
        }

        cellData.push({ displayValue: renderOptionsCell(voucher), id: "voucher_8" })
        return cellData
    }

    function getCompactCellData(voucher: Voucher) {
        const cellData: TableCellData[] = [
            { displayValue: voucher.voucherId, id: "order_0" },
            { displayValue: <DateCell value={voucher.voucherDate} mode="onlyDate" />, id: "order_1" },
            { displayValue: currency(voucher.totalGross || 0, voucher.currencyCode), id: "order_3" },
            { displayValue: voucher.paymentInformation, id: "order_4" },
            { displayValue: renderOptionsCell(voucher), id: "voucher_5" },
        ]
        return cellData
    }

    function getCells(voucher: Voucher) {
        let cells: TableCellData[] = []
        if (compact) {
            cells = getCompactCellData(voucher)
        } else {
            cells = getModuleCellData(voucher)
        }

        return cells
    }

    const displayData = vouchers?.map((voucher, index) => ({
        cells: getCells(voucher),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === voucher.voucherId,
        extendedContent:
            !compact && openedConnectedVouchers.some((item) => item === voucher.voucherId) ? renderConnectedVouchers(voucher) : undefined,
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} variant={variant} onScrollBottom={props.loadNextPage} />
}
