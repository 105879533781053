import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { _2DRimSizesRequest, _2DRimSize } from "./model"

export function load2DRimSizes(request: _2DRimSizesRequest) {
    const url = `${getServiceUrl()}/GetRimSizes`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<_2DRimSize[]>((resolve, reject) => {
        ajax({ url, body, authorization, method: "GET" }).then(
            response => resolve(response.rimSizes),
            reject
        )
    })
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.vehicleServiceUrl
}