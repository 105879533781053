import { RegistrationNoType, ShowTruckModelDetailsRequest, ShowTruckModelDetailsResponse, TruckModel } from "@tm/models"

import { callTrucksService } from "."
import { PagedRequest, PagedResponse } from ".."
import { EngineCapacityFilter, EngineCodeFilter, FuelTypeFilter, ModelYearFilter } from "../../model/filters"

type SelectedModelFilters = {
    modelYear?: number
    engineCapacity?: number
}

type ModelFilters = {
    modelYears: Array<ModelYearFilter>
    engineCapacities: Array<EngineCapacityFilter>
    fuelTypes: Array<FuelTypeFilter>
    engineCodes: Array<EngineCodeFilter>
}

export type ModelsResponse = PagedResponse & {
    models: Array<TruckModel>
    filters: ModelFilters
}

type ShowModelsByArticleRequest = PagedRequest & {
    articleId: number
    modelSeriesId?: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByArticle(request: ShowModelsByArticleRequest) {
    return callTrucksService<ShowModelsByArticleRequest, ModelsResponse>("ShowModelsByArticle", request)
}

type ShowModelsByModelSeriesRequest = PagedRequest & {
    modelSeriesId: number
    selectedFilters?: SelectedModelFilters
}

export function showModelsByModelSeries(request: ShowModelsByModelSeriesRequest) {
    return callTrucksService<ShowModelsByModelSeriesRequest, ModelsResponse>("ShowModelsByModelSeries", request)
}

type ShowModelsByRegistrationNoRequest = PagedRequest & {
    registrationNo: string
    registrationNoTypeId: RegistrationNoType
    selectedFilters?: SelectedModelFilters
}

export function showModelsByRegistrationNo(request: ShowModelsByRegistrationNoRequest) {
    return callTrucksService<ShowModelsByRegistrationNoRequest, ModelsResponse>("ShowModelsByRegistrationNo", request)
}

export function showModelDetails(request: ShowTruckModelDetailsRequest) {
    return callTrucksService<ShowTruckModelDetailsRequest, ShowTruckModelDetailsResponse>("ShowModelDetails", request)
}
