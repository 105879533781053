import { TmaHelper } from "@tm/utils"
import { useCallback, useMemo, useState } from "react"
import { OeInformation } from "../../../../../data/model"
import { compareReplacementNumbers } from "../../../../list/components/utils"
import { useVehicle } from "../../../hooks/useVehicle"

export const useOeInfoSelection = (oeInformationList: OeInformation[], query?: string, oeManufacturerIds?: number[]) => {
    const [selectedManufacturerId, setSelectedManufacturerId] = useState<number>()
    const [selectedOeNumber, setSelectedOeNumber] = useState<string>()

    const vehicleManufacturerId = useVehicle()?.tecDocManufacturerId

    const onSelectManufacturer = useCallback(
        (manufacturerId) => () => {
            setSelectedManufacturerId(manufacturerId)
            TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        },
        [setSelectedManufacturerId]
    )

    const { selectedManufacturer, selectedOeArticle } = useMemo(() => {
        const oeInformation =
            oeInformationList.find((x) => x.manufacturer?.id === selectedManufacturerId) ??
            oeInformationList.find((x) => x.manufacturer?.id === vehicleManufacturerId) ??
            oeInformationList.find((x) => oeManufacturerIds?.includes(x.manufacturer?.id)) ??
            oeInformationList.first()

        const oeArticle =
            (!query ? undefined : oeInformation?.oeArticles?.find((x) => compareReplacementNumbers(x.number, query))) ??
            oeInformation?.oeArticles?.find((x) => x.number === selectedOeNumber) ??
            oeInformation?.oeArticles?.first()

        if (selectedManufacturerId !== oeInformation?.manufacturer?.id) {
            setSelectedManufacturerId(oeInformation?.manufacturer?.id)
        }

        if (selectedOeNumber !== oeArticle?.number) {
            setSelectedOeNumber(oeArticle?.number)
        }

        return {
            selectedManufacturer: oeInformation,
            selectedOeArticle: oeArticle,
        }
    }, [query, oeInformationList, selectedManufacturerId, vehicleManufacturerId, selectedOeNumber, oeManufacturerIds])

    return {
        selectedManufacturer,
        selectedOeArticle,
        onSelectManufacturer,
    }
}
