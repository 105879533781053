import { MessageBus, Channel } from "@tm/hermes"
import { TeccomErpInformation } from "@tm/models"

let mb: MessageBus<BundleChannels>

export function bundleChannel<T extends keyof BundleChannels>(type: T, id?: string): Channel<BundleChannels, T> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel(type, id)
}

export interface BundleChannels {
    TECCOM: TeccomChannelType
}

export type TeccomChannelType = { LOADED: { response: TeccomErpInformation } } & { ERROR: {} }
