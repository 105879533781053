import { useRef, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Text, Loader, TextField, Headline } from "@tm/controls"
import { ButtonKeyDefinition } from "@tm/utils"
import { Compilation } from "../../../../../data"
import { useUpdateCompilation } from "../../../../../data/hooks/useCompilations"

type Props = {
    compilation: Compilation
}

export default function CompilationInfo(props: Props) {
    const { translateText } = useLocalization()
    const descriptionRef = useRef<TextField>(null)
    const confirmationRef = useRef<Button>(null)

    const { updateCompilation, updatingCompilation } = useUpdateCompilation()

    const { compilation } = props

    const [name, setName] = useState(compilation.name)
    const [description, setDescription] = useState(compilation.description)
    const [isEditing, setIsEditing] = useState(false)

    useEffect(() => {
        if (compilation.name !== name || compilation.description !== description) {
            setDescription(compilation.description)
            setName(compilation.name)
        }
    }, [compilation])

    const handleEditModeToggle = () => {
        setIsEditing((prev) => !prev)
    }

    const handleNameChange = (name: string | null) => {
        setName(name || "")
    }

    const handleCompilationNameKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            descriptionRef.current?.focus()
        }
    }

    const handleDescriptionChange = (description: string | null) => {
        setDescription(description || "")
    }

    const handleDescriptionKeyDown = (e: any) => {
        if (e.key == ButtonKeyDefinition.Enter) {
            confirmationRef.current?.focus()
        }
    }

    const handleConfirmButtonClick = () => {
        updateCompilation({ compilationId: compilation.id, name, description, isPublic: compilation.isPublic })
        handleEditModeToggle()
    }

    const renderReadOnlyMode = () => {
        return (
            <>
                <div className="compilation-info__header">
                    <Headline size="xs">{compilation.name}</Headline>
                    <Button
                        className="edit-position__button"
                        title={translateText(122)}
                        onClick={handleEditModeToggle}
                        icon="edit_change"
                        disabled={!compilation.isOwn}
                    />
                </div>
                <Text className="compilation-info__description">
                    {compilation.description || <Text modifiers={["sub"]}>{translateText(12802)}</Text>}
                </Text>
            </>
        )
    }

    const renderEditMode = () => {
        return (
            <>
                <div className="compilation-info__header no-left-padding">
                    <TextField
                        maxLength={50}
                        onKeyDown={handleCompilationNameKeyDown}
                        label={translateText(155)}
                        floatingLabel
                        value={compilation.name}
                        layout={["holo"]}
                        size="xl"
                        onChange={handleNameChange}
                    />
                    <div className="compilation-info__edit__buttons">
                        <Button title={translateText(70)} onClick={handleEditModeToggle} icon="close" />
                        <Button
                            title={translateText(9)}
                            onClick={handleConfirmButtonClick}
                            icon="check"
                            skin="success"
                            ref={confirmationRef}
                            disabled={!compilation.name}
                        />
                    </div>
                </div>
                <TextField
                    className="compilation-info__description no-left-padding"
                    floatingLabel
                    label={translateText(617)}
                    layout={["holo"]}
                    maxLength={200}
                    ref={descriptionRef}
                    value={compilation.description}
                    onChange={handleDescriptionChange}
                    onKeyDown={handleDescriptionKeyDown}
                />
            </>
        )
    }

    return <div className="compilation-info">{updatingCompilation ? <Loader /> : isEditing ? renderEditMode() : renderReadOnlyMode()}</div>
}
