import { Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { SelectedPriceVatMode } from "@tm/models"
import Total from "./Total"
import { CostEstimationB2BTotals, CostEstimationB2CTotals } from "../../../../../data/model"
import { TotalsLoader } from "./TotalsLoader"

type Props = {
    priceVatMode?: SelectedPriceVatMode
    totals?: CostEstimationB2CTotals | CostEstimationB2BTotals
    totalsLoading: boolean
    currencyCode: string
}

export function PartsTotals({ totals, totalsLoading, currencyCode, priceVatMode }: Props) {
    const { translateText, currency } = useLocalization()

    if (totalsLoading) {
        return <TotalsLoader />
    }

    if (!totals) {
        return null
    }
    return (
        <Stack py={1} pr={1} display="flex" justifyContent="flex-end" spacing={0.5} width="400px">
            <Total label={translateText(1720)} value={currency(totals?.partCosts || 0, currencyCode)} valueBold />
            {!!totals?.alreadyTaxedPartCosts && priceVatMode !== SelectedPriceVatMode.Gross && (
                <Total label={translateText(12850)} value={currency(totals.alreadyTaxedPartCosts, currencyCode)} valueBold />
            )}
        </Stack>
    )
}
