import { ajax } from "@tm/utils"

import { getBundleParams } from "../../utils"

function getServiceBooksUrl() {
    return getBundleParams().serviceBooksUrl
}

type ServiceBook = {
    kher: number
    her: string
    url: string
}

export async function getServiceBook(manufacturerId: number) {
    const url = getServiceBooksUrl()
    if (!url) {
        return
    }

    const serviceBooks = await ajax<Array<ServiceBook>>({ url })
    const serviceBook = serviceBooks?.find((x) => x.kher === manufacturerId)

    return serviceBook?.url
}
