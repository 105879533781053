import { Icon, IconButton, Snackbar, Tooltip, TextField, styled } from "@tm/components"
import { useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Article } from "@tm/models"
import {
    barcodeToastState,
    createBasketMemo,
    encodeUniqueId,
    mapArticleToAddCatalogPartListRequest,
    renderRoute,
    RouteComponentProps,
    showWarehouseDataMissingError,
    uniqueId,
    useDefaultErpSystem,
    useDefaultOrderWarehouse,
    useWorkTaskTruckData,
    withRouter,
} from "@tm/utils"
import { FC, Suspense, useEffect, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import { useBasketParts } from "../../../../basket/src/hooks/basketState/useBasketParts"
import { getArticlesByBarcodeScanner } from "../../data/repositories"

export type Props = RouteComponentProps & {
    directSearchRoute: string
    disabledForRoutes: string[]
}

const CenterWrapper = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
})

const InfoSnackBar = styled(Snackbar)<{ colortype: "warning" | "error" | "success" | "info" }>(({ theme, colortype }) => ({
    bottom: "48px",
    [theme.breakpoints.up("sm")]: {
        bottom: "80px",
    },
    ".MuiSnackbarContent-root": {
        backgroundColor: theme.palette[colortype].main,
        color: theme.palette[colortype].contrastText,
    },
}))

const CustomIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    "&:disabled": {
        color: theme.palette.grey[600],
    },
}))

type InfoMessage = {
    message: string
    type: "warning" | "error" | "success" | "info"
    display: boolean
}

const doubleKeyDelta = 500
export const ToastBarcodeSearchComponent: FC<Props> = (props) => {
    const { directSearchRoute, disabledForRoutes } = props
    const [query, setQuery] = useState<string>("")
    const { workTask, workTaskId } = useWorkTask() ?? {}
    const { workTaskTruckData } = useWorkTaskTruckData(workTaskId)
    const { userSettings } = useUser() ?? {}
    const { orderOptions = {} } = userSettings ?? {}
    const { erpSystemConfig } = useDefaultErpSystem()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { warehouseData, refetchWarehouseData } = useDefaultOrderWarehouse(
        { telesalesCustomerNo, distributorId: erpSystemConfig?.id },
        enableServiceCalls
    )
    const { translateText } = useLocalization()
    const { addCatalogPartList } = useBasketParts()

    const [barcodeToast, setBarcodeToastState] = useRecoilState(barcodeToastState)
    const [infoMessage, setInfoMessage] = useState<InfoMessage>({ type: "info", message: "", display: false })

    const doubleKeyTimerRef = useRef<number | undefined>(undefined)
    const doubleKeyCounter = useRef(0)

    useEffect(() => {
        return () => clearTimeout(doubleKeyTimerRef.current)
    }, [])

    function checkIfRouteIsRestricted() {
        if (disabledForRoutes && disabledForRoutes.length > 0) {
            return disabledForRoutes?.some((route) => props.history.location.pathname.includes(renderRoute(route, { workTaskId })) ?? false)
        }
        return false
    }

    if (checkIfRouteIsRestricted()) {
        return null
    }

    const params = { ...props.match.params, workTaskId: !workTask?.id ? encodeUniqueId(uniqueId()) : encodeUniqueId(workTask?.id) }
    const baseUrl = renderRoute(directSearchRoute, params)

    const onInfoClose = () => {
        setInfoMessage((prev) => ({ ...prev, display: false }))
    }

    const infoNotification = (message: string, type: "info" | "warning" | "success" | "error" = "success") => {
        setInfoMessage({ message, type, display: true })
    }

    const clearAndClose = () => {
        setQuery("")
        setBarcodeToastState({ show: false })
    }

    const onSearch = () => {
        infoNotification(`${translateText(587)} "${query}"`)
        props.history.push(`${baseUrl}?query=${encodeURIComponent(query)}`)
        clearAndClose()
    }

    const onAddToBasket = async () => {
        if (!warehouseData || warehouseData.hasErrors) {
            showWarehouseDataMissingError(translateText)
            refetchWarehouseData()
            return
        }

        const articles = await getArticlesByBarcodeScanner(query)
        const articlesCount = articles && articles.length

        if (articlesCount) {
            if (articlesCount === 1) {
                addToBasket(articles[0], 1)
            } else {
                infoNotification(translateText(13041).replace("{0}", articlesCount.toString()), "warning")
            }
        } else {
            infoNotification(translateText(13586), "error")
        }

        clearAndClose()
    }

    const handleKeyDown = (key: string) => {
        if (key === "Enter" && !!query.length) {
            if (doubleKeyCounter.current === 0) {
                clearTimeout(doubleKeyTimerRef.current)
                doubleKeyTimerRef.current = window.setTimeout(async () => {
                    if (doubleKeyCounter.current === 1) {
                        onSearch()
                    } else {
                        await onAddToBasket()
                    }
                    doubleKeyCounter.current = 0
                }, doubleKeyDelta)
            }

            doubleKeyCounter.current++
        }
    }

    const addToBasket = (article: Article, quantity: number) => {
        const worktaskId: string | undefined = workTask?.id || uniqueId()

        const memo =
            orderOptions?.repairShopResponse?.addWorkTaskInfoToOrderItemMemo && orderOptions.basketMemoContext !== "perOrder"
                ? createBasketMemo({
                      config: {
                          sections: orderOptions.basketMemoSections ?? [],
                      },
                      vehicle: workTask?.vehicle,
                      customer: workTask?.customer,
                      truckData: workTaskTruckData,
                      workTask: workTask?.workTaskReadModel,
                  })
                : undefined

        addCatalogPartList(
            mapArticleToAddCatalogPartListRequest(
                [{ ...article, quantity }],
                worktaskId,
                workTask?.vehicle?.id,
                workTask?.customer?.id,
                undefined,
                memo,
                undefined,
                warehouseData?.defaultWarehouse,
                undefined,
                erpSystemConfig?.id,
                erpSystemConfig?.description
            )
        )
            .catch(() => {
                infoNotification(translateText(938), "error")
            })
            .finally(() => {
                if (!workTask?.id) {
                    infoNotification(translateText(13043), "success")
                    const baseUrl = renderRoute("/:workTaskId", { ...props.match.params, workTaskId: encodeUniqueId(worktaskId) })
                    props.history.push(baseUrl)
                }
            })

        setQuery("")
    }

    const inputSnackBarContent = (
        <CenterWrapper>
            <TextField
                size="small"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
                autoFocus
                onKeyDown={(e) => handleKeyDown(e.key)}
            />
            <Tooltip title={translateText(13044)} enterDelay={1000}>
                <div>
                    <CustomIconButton size="small" aria-label="basket" onClick={onSearch} disabled={!query.length}>
                        <Icon name="search" />
                    </CustomIconButton>
                </div>
            </Tooltip>
            <Tooltip title={translateText(13045)} enterDelay={1000}>
                <div>
                    <CustomIconButton size="small" aria-label="search" onClick={onAddToBasket} disabled={!query.length}>
                        <Icon name="basket" />
                    </CustomIconButton>
                </div>
            </Tooltip>
            <CustomIconButton size="small" aria-label="close" onClick={() => setBarcodeToastState({ show: false })}>
                <Icon name="close" />
            </CustomIconButton>
        </CenterWrapper>
    )

    const infoSnackbarContent = (
        <IconButton size="small" aria-label="close" color="inherit" onClick={() => setInfoMessage((prev) => ({ ...prev, display: false }))}>
            <Icon name="close" />
        </IconButton>
    )

    return (
        <>
            <InfoSnackBar
                open={infoMessage.display}
                message={infoMessage.message}
                action={infoSnackbarContent}
                autoHideDuration={2000}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                onClose={onInfoClose}
                colortype={infoMessage.type}
            />
            <Snackbar
                open={barcodeToast.show}
                message={`${translateText(12560)} :`}
                action={inputSnackBarContent}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            />
        </>
    )
}

function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <ToastBarcodeSearchComponent {...props} />
        </Suspense>
    )
}

export default withRouter(Wrapper)
