import { useCallback } from "react"
import { useIdStorageByWorkTaskLoadable } from "./useIdStorage"
import { useSendChatMessageLoadable } from "."

export function useCarteligenceLinkShare() {
    const { chatId } = useIdStorageByWorkTaskLoadable() ?? {}
    const sendChatMessage = useSendChatMessageLoadable()

    const handleSendToCartelligence = useCallback(async (message: string, url: string) => {
        if (!chatId || !url) {
            return false
        }

        await sendChatMessage({
            chatId,
            message,
            attachment: {
                metaData: {
                    type: "link",
                    url,
                },
            },
        }).catch(() => {
            return false
        })

        return true
    }, [])

    return {
        disabled: !chatId,
        sendToCarteligence: handleSendToCartelligence,
    }
}
