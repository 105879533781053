import { Paging } from "@tm/models"
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query"

type PagedResponse<T> = {
    data: T
    pageIndex: number
    endOfList: boolean
}

export function useCustomItems<TRequest, TData>(
    request: TRequest,
    { queryKey, dataFn }: { queryKey: string; dataFn(request: TRequest & Paging): Promise<PagedResponse<TData>> }
) {
    return useInfiniteQuery([queryKey, request], ({ pageParam = 1 }) => dataFn({ ...request, pageIndex: pageParam, pageSize: 10 }), {
        getNextPageParam: (lastPage) => {
            if (!lastPage.endOfList) {
                return lastPage.pageIndex + 1
            }
        },
    })
}

export function useDeleteCustomItem<TRequest, TResponse, TData>({
    queryKey,
    dataFn,
    filterFn,
}: {
    queryKey: string
    dataFn(request: TRequest): Promise<TResponse>
    filterFn: (response: TResponse, request: TRequest, prevData: TData) => TData
}) {
    const queryClient = useQueryClient()

    return useMutation<TResponse, unknown, TRequest>(dataFn, {
        onSuccess: (response, request) => {
            queryClient.setQueriesData<{ pages: Array<{ data: TData }> }>(queryKey, (prev) => {
                return {
                    ...prev,
                    pages:
                        prev?.pages.map((page) => ({
                            ...page,
                            data: filterFn(response, request, page.data),
                        })) ?? [],
                }
            })

            queryClient.invalidateQueries(queryKey)
        },
    })
}

export function useEditCustomItem<TRequest, TResponse, TData>({
    queryKey,
    dataFn,
    mutateFn,
}: {
    queryKey: string
    dataFn(request: TRequest): Promise<TResponse>
    mutateFn: (response: TResponse, request: TRequest, prevData: TData) => TData
}) {
    const queryClient = useQueryClient()

    return useMutation<TResponse, unknown, TRequest>(dataFn, {
        onSuccess: (response, request) => {
            queryClient.setQueriesData<{ pages: Array<{ data: TData }> }>(queryKey, (prev) => {
                return {
                    ...prev,
                    pages:
                        prev?.pages.map((page) => ({
                            ...page,
                            data: mutateFn(response, request, page.data),
                        })) ?? [],
                }
            })

            queryClient.invalidateQueries(queryKey)
        },
    })
}
