import { useMemo } from "react"
import { useLocation } from "react-router"
import { getCategoryTypeFromUrl } from "@tm/utils"
import { DirectSearch } from "./DirectSearch"
import { UniPartsSearch } from "./UniPartsSearch"
import { VehiclePartsSearch } from "./VehiclePartsSearch"

export function Search() {
    const { pathname } = useLocation()
    const categoryType = useMemo(() => getCategoryTypeFromUrl(pathname), [pathname])

    switch (categoryType) {
        case "directSearch":
            return <DirectSearch />
        case "vehicleParts":
            return <VehiclePartsSearch />
        case "universalParts":
            return <UniPartsSearch />
        default:
            return null
    }
}
