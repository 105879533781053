import { Button, InputGroup, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { style } from "typestyle"

type Props = {
    numberTextId: number
    onSearch: (query: string | undefined) => void
}

const className = style({
    $nest: {
        "&__text-field": {
            background: "#fff",
            width: "25rem",
        },
    },
})

export function CustomItemSearch({ numberTextId, onSearch }: Props) {
    const { translateText } = useLocalization()
    const [inputValue, setInputValue] = useState<string>()

    const handleChange = (query: string) => {
        setInputValue(query || undefined)

        if (!query) {
            onSearch(undefined)
        }
    }

    const handleSearch = () => {
        onSearch(inputValue)
    }

    return (
        <InputGroup size="l" className={className}>
            <TextField
                className={`${className}__text-field`}
                floatingLabel
                label={`${translateText(numberTextId)} ${translateText(1098)} ${translateText(25)}`}
                onChange={handleChange}
                onChangeConfirm={handleSearch}
                value={inputValue}
                preventConfirmOnBlur
                showClear
            />
            <Button size="l" scaleIcon icon="search" onClick={handleSearch} />
        </InputGroup>
    )
}
