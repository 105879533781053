import { Article, ErpInformation, ErpInformationRequestItem } from "@tm/models"
import { useCallback } from "react"
import { atom, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil"

type State = {
    loading: boolean
    erpInfo?: ErpInformation
    error?: string
    request?: ErpInformationRequestItem
}

const articleDetailsSharedArticle = atom<Article | undefined>({
    key: "erp_articleDetailsSharedArticle",
    default: undefined,
})

const articleDetailsSharedErpInfos = atom<State>({
    key: "erp_articleDetailsSharedErpInfos",
    default: {
        loading: true,
    },
})

export function useArticleDetailsSharedArticle() {
    return useRecoilValue(articleDetailsSharedArticle)
}

export function useArticleDetailsSharedErpInfos() {
    return useRecoilValue(articleDetailsSharedErpInfos)
}

export function useArticleDetailsSharedErpInfosActions() {
    const setState = useSetRecoilState(articleDetailsSharedErpInfos)
    const shareErpInfoDetailsArticle = useSetRecoilState(articleDetailsSharedArticle)
    const shareErpInfoDetailsReset = useResetRecoilState(articleDetailsSharedErpInfos)

    const shareErpInfoDetails = useCallback(
        (request: ErpInformationRequestItem, erpInfo: ErpInformation) => {
            setState({ loading: false, erpInfo, request })
        },
        [setState]
    )

    const shareErpInfoDetailsError = useCallback(
        (error: string) => {
            setState({ loading: false, error })
        },
        [setState]
    )

    return { shareErpInfoDetails, shareErpInfoDetailsError, shareErpInfoDetailsReset, shareErpInfoDetailsArticle }
}
