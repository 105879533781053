import { Fragment } from "react"

export function mapTooltipArrayToElement(tooltips: Array<string> | undefined) {
    if (!tooltips || !tooltips.length) {
        return
    }

    return (
        <>
            {tooltips.map((tip, idx) => (
                <Fragment key={idx}>
                    {tip}
                    {idx < tooltips.length - 1 ? <br /> : null}
                </Fragment>
            ))}
        </>
    )
}
