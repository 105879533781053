import { BundleMicro } from "@tm/morpheus"
import { withStoreProvider } from "../../components/details-formulars/bussiness/helpers"
import { reduce } from "../../components/details-formulars/bussiness/index"
import component from "../../components/details-formulars/component"

const externalSystemDocuments: BundleMicro = {
    name: "external-system-documents",
    reduce,
    component: withStoreProvider(component),
    resetOnWorktaskChange: true,
}

export default externalSystemDocuments
