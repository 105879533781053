import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../utils"

function getDmsServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.dmsServiceUrl
}

export function getElekatString(workTaskId: string) {
    const authorization = getStoredAuthorization()
    const url = `${getDmsServiceUrl()}/Elekat/GetElekatString`
    const body = { workTaskId }

    return new Promise<string>((resolve, reject) =>
        ajax({ url, body, authorization, method: "POST" }).then((data) => resolve(data && data.result), reject)
    )
}
