import { HistoryEntry } from "../../models"

export const mapHistoryEntries = (historyEntriy: HistoryEntry): HistoryEntry => {
    return {
        creationDateTime: historyEntriy.creationDateTime && new Date(historyEntriy.creationDateTime),
        customerData: historyEntriy.customerData,
        hasCalcData: historyEntriy.hasCalcData,
        historyId: historyEntriy.historyId,
        updateDateTime: historyEntriy.updateDateTime && new Date(historyEntriy.updateDateTime),
    }
}
