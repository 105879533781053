import { useEffect, useMemo } from "react"
import { useHistory, useLocation } from "react-router"
import { bindActionCreators } from "redux"
import { useDispatch, useSelector } from "react-redux"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { QuerySearchType, TmaHelper } from "@tm/utils"
import { Box, Icon, Loader, Stack, Typography, ScrollContainer } from "@tm/components"
import { TmaEModule, VehicleType } from "@tm/models"
import { Actions } from "./business"
import { MainState } from "./business/model"
import Navigation from "../navigation/component"
import ErrorCodeItem from "./components/errorCodeItem"

export default function MainComponent() {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()
    const actions = bindActionCreators(Actions, dispatch)
    const history = useHistory()
    const { search } = useLocation()

    const errorData = useSelector((s: MainState) => s.errorData)
    const isLoading = useSelector((s: MainState) => s.isLoading)
    const isError = useSelector((s: MainState) => s.isError)

    const workTask = useWorkTask()?.workTask
    const workTaskId = workTask?.id
    const vehicle = workTask?.vehicle

    const searchParams = new URLSearchParams(search)
    const errorCodes = searchParams.get("errorCodes")
    const uniqueErrorCodes = Array.from(new Set(errorCodes?.split(","))).join(",")
    const fromWidget: boolean = searchParams.get("fromWidget") === "1"

    const codeFirstElementWithData = useMemo(() => errorData?.find((error) => !error.noResult)?.code, [errorData])

    useEffect(() => {
        if (!workTaskId || !vehicle?.id || !uniqueErrorCodes) {
            return
        }

        const queriesFromUrl = uniqueErrorCodes?.split(",")
        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchContextV2(
            TmaEModule.FAST_DTC,
            uniqueErrorCodes ?? "dtcSearch",
            QuerySearchType.ErrorCode
        )

        if (fromWidget || !errorData.length || !!queriesFromUrl?.[0]) {
            actions.fetchErrorDatas(
                vehicle?.tecDocTypeId,
                vehicle?.tecDocManufacturerId,
                queriesFromUrl,
                vehicle?.vehicleType || VehicleType.All,
                vehicle?.initialRegistration || new Date(),
                vehicle?.mileAge || 0,
                10,
                translateText
            )

            actions.saveLastSearch(uniqueErrorCodes || "")

            history.push({ search: `errorCodes=${uniqueErrorCodes}` })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workTaskId, vehicle?.id, uniqueErrorCodes])

    const renderNoData = () => {
        return (
            <Stack justifyContent="center" height="100%" alignItems="center">
                <Icon width="6em" height="6em" name="no-results" />
                <Typography variant="h2">{translateText(809)}</Typography>
                <Typography variant="body2">{translateText(3119)}</Typography>
            </Stack>
        )
    }

    const renderError = () => {
        return (
            <Stack justifyContent="center" height="100%" alignItems="center" gap="1em">
                <Icon width="6em" height="6em" name="error-filled" />
                <Typography variant="h2">{translateText(787)}</Typography>
            </Stack>
        )
    }

    return (
        <Stack sx={(theme) => ({ margin: theme?.margin?.m })} flexBasis="100%">
            <Navigation />
            <Box flex={1} mt={1.5} mb={1} display="initial">
                {isError && !isLoading && renderError()}
                {!isLoading && !errorData.length && renderNoData()}
                {!!errorData.length && workTaskId && (
                    <ScrollContainer>
                        {errorData.map((x, idx) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <ErrorCodeItem
                                key={`${x.code}_${idx}`}
                                initiallyOpened={x.code === codeFirstElementWithData}
                                item={x}
                                workTaskId={workTaskId}
                            />
                        ))}
                    </ScrollContainer>
                )}
                {isLoading && <Loader />}
            </Box>
        </Stack>
    )
}
