import { getStoredAuthorization, ajax } from "@tm/utils"
import { getDutchOrderPlatesServiceUrl } from ".."
import { SetDutchOrdersMDMParamsRequest } from "../model"

export function setDutchOrdersMDMParams(request: SetDutchOrdersMDMParamsRequest) {
    const baseUrl = getDutchOrderPlatesServiceUrl()
    if (!baseUrl) {
        return Promise.resolve()
    }
    const url = `${baseUrl}/SetMDMParams`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<void>({ url, authorization, body, method: "POST" })
}
