import { BundleMicro } from "@tm/morpheus"
import { reduce, transmit } from "../../_helpers/details-business"
import component from "./component"

const erpInfoDetails: BundleMicro = {
    name: "details-erp-info-availability",
    reduce,
    component,
    transmit,
}

export default erpInfoDetails
