import { Suspense, useCallback, useMemo, useState } from "react"
import { useUser } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Customer } from "@tm/models"
import { getTitleTypeTextId } from "@tm/utils"
import { Button, Icon, Tooltip } from "@tm/components"
import { AppLink } from "../../../../data"
import { useChatInvitationLinks } from "../../../../data/hooks/useChatInvitationLinks"
import { getComponentStyles } from "./styles"

type Props = {
    customer: Customer
    email: string
}

function MailButton({ customer, email }: Props) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()
    const companyName = useUser()?.userSettings?.repairShop?.companyName
    const appLinks = useChatInvitationLinks()

    const showTooltip = useCallback(() => {
        setIsTooltipOpen(true)
    }, [])

    const hideTooltip = useCallback(() => {
        setIsTooltipOpen(false)
    }, [])

    if (!appLinks.length) {
        return null
    }

    const subject = translateText(3132)
    const title = customer.titleType && getTitleTypeTextId(customer.titleType)

    const getLink = (appLink: AppLink) => {
        const body = `${translateText(3129)
            .replace(" {0}", title ? ` ${translateText(title)}` : "")
            .replace(" {1}", customer.firstName ? ` ${customer.firstName}` : "")
            .replace(" {2}", customer.lastName ? ` ${customer.lastName}` : "")}

${translateText(3128).replace("{0}", companyName || "")}

${translateText(3127)}
${appLink.appLink}
${translateText(3130)}

${translateText(3131)}`

        return `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
    }

    if (appLinks.length === 1) {
        return (
            <a className="btn has-icon" href={getLink(appLinks[0])} target="_blank" rel="noopener noreferrer">
                <Icon name="chat" className="btn__icon" />
                <div className="btn__content">{translateText(3106)}</div>
            </a>
        )
    }

    return (
        <Tooltip
            open={isTooltipOpen}
            color="primary"
            variant="light"
            className={classNames.tooltip}
            onClickAway={hideTooltip}
            onClose={hideTooltip}
            disableFocusListener
            disableHoverListener
            title={
                <>
                    {appLinks?.map((appLink: AppLink) => (
                        <a
                            key={appLink.appId}
                            className={`btn ${classNames.tooltipButton}`}
                            href={getLink(appLink)}
                            target="_blank"
                            rel="noopener noreferrer"
                            title={appLink.appName || appLink.appIdentifier}
                        >
                            <div className="btn__content">
                                {appLink.appLogo ? (
                                    <img src={appLink.appLogo} className={classNames.appLogo} />
                                ) : (
                                    appLink.appName || appLink.appIdentifier
                                )}
                            </div>
                        </a>
                    ))}
                </>
            }
        >
            <Button onClick={showTooltip} startIcon={<Icon name="chat" />}>
                {translateText(3106)}
            </Button>
        </Tooltip>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={<Loader />}>
            <MailButton {...props} />
        </Suspense>
    )
}
