import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        //= =Theme values==================================
        marginBottomS: {
            marginBottom: margin.s,
        },
        marginBottomM: {
            marginBottom: margin.m,
        },
        marginBottomL: {
            marginBottom: margin.l,
        },
        marginBottomXl: {
            marginBottom: margin.xl,
        },
        //= =Typography==================================
        textCenter: {
            textAlign: "center",
        },
        //= =Flex==================================
        flex: {
            display: "flex",
        },
        flexCenter: {
            alignItems: "center",
            justifyContent: "center",
        },
        flexColumn: {
            display: "flex",
            flexDirection: "column",
        },
        flexOne: {
            flex: 1,
        },
        flexContainerWithGap: {
            display: "flex",
            gap: "1em",
        },
        //= =Custom==================================
        wrapper: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            position: "relative",
        },
        wrapperMargins: {
            paddingLeft: margin.xl,
            paddingRight: margin.l,
            paddingTop: margin.m,
        },
        wrapperMinHeight: {
            minHeight: "calc(100% - 8em)",
        },
        buttonPadding: {
            padding: ".4em 2em",
        },
        signButton: {
            padding: "0 2em",
        },
        infoText: {
            padding: "5% 20% 0 20%",
        },
        bigIcon: {
            width: "max-content",
            margin: "0.5em",
            minWidth: "12em",
            flex: 1,
        },
        modalContent: {
            display: "flex",
            padding: "3em",
            width: "55%",
            $nest: {
                ".modal__body": {
                    flex: 1,
                },
                ".modal__close": {
                    display: "none",
                },
            },
        },
        mediumScreenModal: {
            width: "70%",
        },
        smallScreenModalContent: {
            width: "85%",
        },
    })
}
