import { FC, createRef, useState, useEffect } from "react"
import { Button, MessageInline, Radio, Table, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus, { useActions } from "@tm/morpheus"
import { clone, getValue, initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { ConfigParams } from "@tm/models"
import { Actions } from "../../bussiness"
import { hasFieldError } from "../../bussiness/helpers"
import { AdditionalPart, DocumentsState, ECompletionType } from "../../bussiness/model"
import { fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { Field, StyledTable } from "../common"

type Props = {}

const selector = initSelector(fieldsSelector)

const ReturnsTab: FC<Props> = () => {
    const { translateText } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const ref = createRef<TextField>()

    const actions = useActions(Actions, "updateField")

    const [model] = useSelector(selector)
    const [quantity, setQuantity] = useState<number>()
    const [description, setDescription] = useState<string>()
    const [deliveryNoteNumber, setDeliveryNoteNumber] = useState<number>()
    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText))

    const initState = () => {
        setQuantity(+(model?.articleDetails?.quantity ?? 0))
        setDescription(`${model?.articleDetails.manufacturerPartsCode}/${model?.articleDetails.productGroupName}`)
        setDeliveryNoteNumber(undefined)
    }

    useEffect(() => {
        const shouldInitState = model?.articleDetails && !model?.returnsInfo.additionalParts.length
        if (shouldInitState) {
            initState()
        }
    }, [])

    useEffect(() => {
        if (model?.returnsInfo.additionalChange) {
            initState()
        }
    }, [model?.returnsInfo.additionalChange])

    if (!model) {
        return null
    }

    const { additionalParts } = model.returnsInfo

    const handleCheckboxChange = (path: Array<string>, value: any, defaultValue?: any) => {
        if (getValue(model, path) === value) {
            value = defaultValue
        }

        if (path.includes("completionAtReturn")) {
            if (value === ECompletionType.CreditNoteNr) {
                ref.current?.focus()
            } else {
                actions.updateField(["returnsInfo", "creditNoteNumber"], "")
            }
        }

        actions.updateField(path, value)
    }

    const handleQuantityChange = (newValue: string) => {
        const stringValue = newValue || ""
        let value = parseFloat(stringValue.replace ? stringValue.replace(",", ".") : stringValue)
        if (isNaN(value)) {
            value = 0
        }
        setQuantity(value)
    }

    const handleAddNewAditionalPart = () => {
        const { additionalParts } = model.returnsInfo
        const newItem: AdditionalPart = {
            description: description ?? "",
            netUnitPrice: 0,
            quantity: quantity ?? 0,
            deliveryNoteNumber: deliveryNoteNumber?.toString() ?? "",
        }
        actions.updateField(["returnsInfo", "additionalChange"], false)
        actions.updateField(["returnsInfo", "additionalParts"], [...additionalParts, newItem])

        setDescription(undefined)
        setQuantity(undefined)
        setDeliveryNoteNumber(undefined)
    }

    const handleDeleteAditionalPart = (item: AdditionalPart) => {
        const newAdditionalParts = additionalParts.filter((x) => x.description != item.description)
        actions.updateField(["returnsInfo", "additionalParts"], newAdditionalParts)
    }

    const renderQuantity = (item: AdditionalPart, idx: number) => {
        const isLastElement = additionalParts.length == idx
        return (
            <Table.Cell>
                {!isLastElement && item.quantity.toLocaleString()}
                {isLastElement && <TextField pattern={/\d{0,5}(?:[\.,]\d{0,2})?/} value={quantity} onChange={handleQuantityChange} />}
            </Table.Cell>
        )
    }

    const renderAditionalPartName = (item: AdditionalPart, idx: number) => {
        const isLastElement = additionalParts.length == idx

        return (
            <Table.Cell>
                {!isLastElement && item.description}
                {isLastElement && <TextField value={description} onChange={setDescription} showClear />}
            </Table.Cell>
        )
    }

    const renderDeliveryNoteNumber = (item: AdditionalPart, idx: number) => {
        const isLastElement = additionalParts.length == idx

        return (
            <Table.Cell>
                {!isLastElement && item.deliveryNoteNumber}
                {isLastElement && <TextField value={deliveryNoteNumber} onChange={setDeliveryNoteNumber} showClear />}
            </Table.Cell>
        )
    }

    const renderDeleteButton = (item: AdditionalPart, idx: number) => {
        const isLastElement = additionalParts.length == idx

        return (
            <Table.Cell>
                {!isLastElement && (
                    <Button
                        onClick={handleDeleteAditionalPart.bind(this, item)}
                        title={translateText(117)}
                        icon="delete"
                        skin={deleteButtonColorError ? "danger" : undefined}
                    />
                )}
                {isLastElement && (
                    <Button disabled={!description || !quantity || !deliveryNoteNumber} onClick={handleAddNewAditionalPart} icon="check" />
                )}
            </Table.Cell>
        )
    }

    const newActivities = clone(model.returnsInfo.additionalParts)
    if (newActivities.length < 12) {
        newActivities.push({ description: "", netUnitPrice: 0, quantity: 0, deliveryNoteNumber: "" })
    }

    return (
        <>
            <Field.Container>
                <Field.Item m={12} title={translateText(1513)}>
                    <TextField
                        showClear
                        maxLength={32}
                        value={model.customerInfo.customerNote}
                        onChange={actions.updateField.bind(this, ["customerInfo", "customerNote"])}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={6} title={translateText(1514)}>
                    <TextField
                        showClear
                        multiline
                        value={model.returnsInfo.goodsReturnReason}
                        onChange={actions.updateField.bind(this, ["returnsInfo", "goodsReturnReason"])}
                    />
                </Field.Item>
                <Field.Item m={6} title={translateText(1534)} mandatory>
                    <Radio
                        size="s"
                        label={translateText(1515)}
                        checked={model.returnsInfo.returnType == 1}
                        onCheck={handleCheckboxChange.bind(this, ["returnsInfo", "returnType"], "1", "")}
                    />
                    <Radio
                        size="s"
                        label={translateText(1516)}
                        checked={model.returnsInfo.returnType == 2}
                        onCheck={handleCheckboxChange.bind(this, ["returnsInfo", "returnType"], "2", "")}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={6} title={translateText(1520)} mandatory={!model.returnsInfo.returnType || model.returnsInfo.returnType == 1}>
                    <Radio
                        size="s"
                        label={translateText(585)}
                        checked={model.returnsInfo.isProductResalable == 1}
                        onCheck={handleCheckboxChange.bind(this, ["returnsInfo", "isProductResalable"], "1", "")}
                    />
                    <Radio
                        size="s"
                        label={translateText(584)}
                        checked={model.returnsInfo.isProductResalable == 2}
                        onCheck={handleCheckboxChange.bind(this, ["returnsInfo", "isProductResalable"], "2", "")}
                    />
                </Field.Item>
                <Field.Item m={6} title={translateText(1521)} mandatory={!model.returnsInfo.returnType || model.returnsInfo.returnType == 1}>
                    <Radio
                        size="s"
                        label={translateText(1518)}
                        checked={model.returnsInfo.completionAtReturn == ECompletionType.FreeReplacement}
                        onCheck={handleCheckboxChange.bind(this, ["returnsInfo", "completionAtReturn"], ECompletionType.FreeReplacement, "")}
                    />
                    <Radio
                        size="s"
                        label={translateText(1378)}
                        checked={model.returnsInfo.completionAtReturn == ECompletionType.CreditNote}
                        onCheck={handleCheckboxChange.bind(this, ["returnsInfo", "completionAtReturn"], ECompletionType.CreditNote, "")}
                    />
                    <Radio
                        size="s"
                        label={translateText(1519)}
                        checked={model.returnsInfo.completionAtReturn == ECompletionType.CreditNoteNr}
                        onCheck={handleCheckboxChange.bind(this, ["returnsInfo", "completionAtReturn"], ECompletionType.CreditNoteNr, "")}
                    />
                    <TextField
                        showClear
                        disabled={model.returnsInfo.completionAtReturn != ECompletionType.CreditNoteNr}
                        ref={ref}
                        value={model.returnsInfo.creditNoteNumber}
                        onChange={actions.updateField.bind(this, ["returnsInfo", "creditNoteNumber"])}
                    />
                </Field.Item>
            </Field.Container>

            <StyledTable
                className={hasFieldError(modelState["returnsInfo.additionalParts"])}
                data={newActivities}
                columns={[
                    <Table.Column renderItemContent={renderQuantity}>{translateText(89)}</Table.Column>,
                    <Table.Column renderItemContent={renderAditionalPartName}>{`${translateText(87)} / ${translateText(25)}`}</Table.Column>,
                    <Table.Column renderItemContent={renderDeliveryNoteNumber}>{translateText(1522)}</Table.Column>,
                    <Table.Column renderItemContent={renderDeleteButton} />,
                ]}
            />
            {newActivities.length > 11 && <MessageInline skin="warning" message={translateText(1795)} />}
        </>
    )
}

export default ReturnsTab
