import { useLocalization } from "@tm/localization"
import { CellContentPosition, TableCellData, TableColumnData } from "@tm/components"
import { VoucherItem } from "../../../../../data/model"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { StyledTable } from "../../../../_shared/StyledComponents"
import VoucherItemOptionsCell from "../../../../_shared/cells/VoucherItemOptionsCell"
import DateCell from "../../../../_shared/cells/DateCell"
import ArticleDescriptionCell from "../../../../_shared/cells/ArticleDescriptionCell"
import ArticleNumberCell from "../../../../_shared/cells/ArticleNumberCell"

type Props = {
    voucherItems: Array<VoucherItem>
}

export default function StgReturnVoucherItemsTableComponent({ voucherItems }: Props) {
    const { translateText } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(370) },
            { header: translateText(12662) },
            { header: translateText(12661) },
            { header: translateText(470) },
            { header: translateText(471) },
            { header: translateText(476) },
            { header: translateText(477) },
            { header: translateText(25) },
            { header: translateText(782), alignContent: CellContentPosition.center },
            { header: translateText(12663), alignContent: CellContentPosition.center },
            {},
        ]
        return columns
    }

    function getCells(voucherItem: VoucherItem) {
        const cellData: TableCellData[] = [
            { displayValue: voucherItem.returnPositionNumber, id: "item_0" },
            { displayValue: voucherItem.orderNo, id: "item_1" },
            { displayValue: voucherItem.positionNumber, id: "item_2" },
            { displayValue: voucherItem.documentNumber, id: "item_3" },
            { displayValue: <DateCell value={voucherItem.orderDate} mode="onlyDate" />, id: "item_4" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="wholesaler" />, id: "item_5" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="supplier" />, id: "item_6" },
            { displayValue: <ArticleDescriptionCell articleDescription={voucherItem.articleDescription} />, id: "item_7" },
            { displayValue: `${voucherItem.quantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_8" },
            { displayValue: `${voucherItem.returnQuantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_9" },
            { displayValue: <VoucherItemOptionsCell item={voucherItem} showNote hideAddToBasket />, id: "item_10" },
        ]
        return cellData
    }

    const displayData = voucherItems.map((voucherItem, index) => ({
        cells: getCells(voucherItem),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} headerBackground="paper" />
}
