import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { DateUnit } from "@tm/utils"
import { TableCellData, TableColumnData, TableRowData } from "@tm/components"
import { VoucherType } from "@tm/models"
import { useRecoilValue } from "recoil"
import { compareDesc } from "date-fns"
import { useParams } from "react-router"
import VehicleInfo from "../../../../_shared/VehicleInfo"
import { CostEstimationVoucher } from "../../../../../data/model"
import { VoucherRouteParams } from "../../../../../business"
import { useOpenDetails } from "../../../../../hooks/useOpenDetails"
import { voucherListFilterAtom } from "../../../../../data/states/listFilters"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import { StyledTable } from "../../../../_shared/StyledComponents"

type Props = {
    costEstimations: Array<CostEstimationVoucher>
    shortCountryCode: string
}

export default function CompactCostEstimationsList(props: Props) {
    const { costEstimations, shortCountryCode } = props
    const { translateText, date } = useLocalization()
    const params = useParams<VoucherRouteParams>()
    const selectedVoucherId = params.id && decodeURIComponent(params.id)

    const { openDetails } = useOpenDetails()

    const listFilters = useRecoilValue(voucherListFilterAtom(params.workTaskId))

    const filteredData = useMemo(() => {
        return costEstimations
            .filter(
                (x) =>
                    (x.voucherCreationDate.isAfter(listFilters.startDate, DateUnit.Day) ||
                        x.voucherCreationDate.isSame(listFilters.startDate, DateUnit.Day)) &&
                    (x.voucherCreationDate.isBefore(listFilters.endDate, DateUnit.Day) ||
                        x.voucherCreationDate.isSame(listFilters.endDate, DateUnit.Day))
            )
            .sort((a, b) => compareDesc(a.voucherCreationDate, b.voucherCreationDate))
    }, [listFilters, costEstimations])

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(470) }, // voucherNumber
            { header: translateText(98) }, // voucherCreationDate
            { header: translateText(99) }, // VehicleInfo
        ]
        return columns
    }

    function getCells(costEstimation: CostEstimationVoucher) {
        const data: TableCellData[] = [
            { displayValue: costEstimation.voucherNumber, id: "1", maxWidth: 80 },
            { displayValue: date(costEstimation.voucherCreationDate, "d"), id: "2" },
        ]

        data.push({
            displayValue: (
                <VehicleInfo
                    vehicleDescription={costEstimation.vehicleDescription}
                    vehicleMileage={costEstimation.vehicleMileAge}
                    vehiclePlateId={costEstimation.vehiclePlateId}
                    shortCountryCode={shortCountryCode}
                    licensePlateOnTop
                />
            ),
            id: "3",
        })
        return data
    }

    function onRowClick(row: TableRowData) {
        openDetails(row.id, VoucherType.CostEstimation)
    }

    const displayData = filteredData.map((costEstimation) => ({
        cells: getCells(costEstimation),
        id: `${costEstimation.id}`,
        customRow: false,
        active: selectedVoucherId === costEstimation.id,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable variant="small" columns={getColumns()} rows={displayData} rowCap={4} onRowClick={onRowClick} />
}
