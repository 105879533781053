import { Fragment, MouseEvent, memo, useCallback, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Vehicle, VehicleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { classes } from "@tm/utils"

import { CircularProgressBar } from "./circular-progress-bar"

type Props = {
    vehicle: Vehicle
    hasEngineCodes: boolean
    onClick?(e: MouseEvent<HTMLDivElement>): void
}

type SegmentType = {
    [key in keyof Pick<Vehicle, "initialRegistration" | "mileAge" | "vin" | "plateId" | "engineCode">]: {
        translation: number
    }
}

const ELEMENTS_FOR_SEGMENTATION: SegmentType = {
    initialRegistration: {
        translation: 124,
    },
    mileAge: {
        translation: 125,
    },
    vin: {
        translation: 101,
    },
    plateId: {
        translation: 21,
    },
    engineCode: {
        translation: 105,
    },
}

export const VehicleDataProgress = memo<Props>(({ vehicle, hasEngineCodes, onClick }) => {
    if (!Morpheus.getParams("parts").enablePartsIndicator) {
        return null
    }

    const style = useMemo(() => getStyle(), [])
    const { translateText } = useLocalization()
    const isSupportedVehicleType = vehicle.vehicleType === VehicleType.PassengerCar

    const availableSegments = useMemo(
        () =>
            Object.keys(ELEMENTS_FOR_SEGMENTATION).reduce((acc: string[], segment) => {
                if (segment === "engineCode" && !hasEngineCodes) {
                    return acc
                }
                return [...acc, segment]
            }, []),
        [hasEngineCodes]
    )

    const filledSegmentSteps = useMemo(
        () =>
            availableSegments.reduce((acc: string[], segment) => {
                if (segment in vehicle && !!vehicle[segment as keyof Vehicle]) {
                    acc.push(segment)
                }

                return acc
            }, []),
        [vehicle, availableSegments]
    )

    const renderChecklistItem = useCallback((label: string, checked: boolean) => {
        return (
            <li className={classes(style.checklistItem, "vehicle-data-progress__checklist__item")}>
                {checked ? CHECK : CIRCLE}
                {label}
            </li>
        )
    }, [])

    const renderChecklist = useCallback(() => {
        return (
            <ul className={classes(style.checklist, "vehicle-data-progress__checklist")}>
                {availableSegments.map((segment) => (
                    <Fragment key={`${segment}`}>
                        {
                            // @ts-ignore
                            renderChecklistItem(translateText(ELEMENTS_FOR_SEGMENTATION[segment].translation), !!vehicle[segment as keyof Vehicle])
                        }
                    </Fragment>
                ))}
            </ul>
        )
    }, [availableSegments, vehicle])

    return (
        <div
            className={classes(style.wrapper, "vehicle-data-progress", isSupportedVehicleType && onClick && "is-clickable")}
            onClick={isSupportedVehicleType ? onClick : undefined}
            title={isSupportedVehicleType && onClick ? translateText(12656).replace("{0}", translateText(1630)) : undefined}
        >
            <CircularProgressBar
                className={classes(style.circle, "vehicle-data-progress__circle")}
                label={isSupportedVehicleType ? translateText(1630) : undefined}
                percentage={(100 / availableSegments.length) * filledSegmentSteps.length}
            />
            {renderChecklist()}
        </div>
    )
})

function getStyle() {
    const theme = getStyleTheme()

    const colors = {
        checklistBackground: "white",
        checklistBorder: "#ededed",
    }

    return useStyle({
        wrapper: {
            position: "relative",
            padding: "1em 0 1em 5em",
        },
        circle: {
            position: "absolute",
            top: 0,
            left: 0,
            filter: "drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25))",
        },
        checklist: {
            padding: "0.5em 0.75em 0.5em 6em",
            border: `solid 1px ${colors.checklistBorder}`,
            backgroundColor: colors.checklistBackground,
            boxShadow: "0 0 8px 0 rgba(0, 0, 0, 0.25)",
            borderRadius: theme.box.radius,
            whiteSpace: "nowrap",
        },
        checklistItem: {
            display: "flex",
            alignItems: "center",
            $nest: {
                svg: {
                    width: "1.2em",
                    height: "1.2em",
                    marginRight: "0.5em",
                },
            },
        },
    })(VehicleDataProgress)
}

const CIRCLE = (
    <svg viewBox="0 0 17 17">
        <path
            fill="#AAA"
            d="M8.5 13.37c-2.69 0-4.87-2.18-4.87-4.87S5.81 3.63 8.5 3.63s4.87 2.18 4.87 4.87-2.18 4.87-4.87 4.87m0-11.245c-3.52 0-6.375 2.854-6.375 6.375 0 3.52 2.854 6.375 6.375 6.375 3.52 0 6.375-2.855 6.375-6.375S12.021 2.125 8.5 2.125"
        />
    </svg>
)

const CHECK = (
    <svg viewBox="0 0 20 20">
        <path
            fill="#C2E617"
            d="M7.139 16.292c-.33 0-.647-.13-.883-.36L2.88 12.63c-.499-.488-.508-1.287-.02-1.785.487-.5 1.287-.508 1.785-.02l2.438 2.384L15.3 4.124c.469-.517 1.266-.557 1.783-.09.517.468.557 1.266.09 1.783l-9.1 10.06c-.23.253-.554.404-.897.414h-.038"
        />
    </svg>
)
