import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { TyresCritsResponse, TyresCritsRequest } from "."
export * from "./model"

export async function loadTyresCrits(request: TyresCritsRequest) {
    const url = `${getServiceUrl()}/TyresCrits`
    const authorization = getStoredAuthorization()

    const response = await ajax<TyresCritsResponse>({ method: "POST", url, authorization, body: request })

    if (!response) {
        throw new Error("empty result")
    }

    return response
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.tyresCritsServiceUrl
}

