import { FC, memo, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { keyframes, style } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { Button, DateField, Dropdown, NumberField, Table, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ConfigParams } from "@tm/models"
import Morpheus, { useActions } from "@tm/morpheus"
import { clone, initSelector } from "@tm/utils"
import { Box, styled } from "@tm/components"
import { getBundleParams } from "../../../../utils"
import { Actions } from "../../bussiness"
import { dateToString, hasFieldError, isFieldAvailable, stringToDate } from "../../bussiness/helpers"
import { AdditionalPart, DocumentsState, QuantityUnit } from "../../bussiness/model"
import { fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { Field, StyledTable } from "../common"

type DropdownItem = {
    idx: number
    value: string
    label: string
}

const selector = initSelector(fieldsSelector)

export default function CostStatementTab() {
    const highlightAnimation = useMemo(() => getHighlightAnimation(), [])
    const { translateText, currency } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const [description, setDescription] = useState<string>()
    const [quantity, setQuantity] = useState<number>()
    const [unit, setUnit] = useState<QuantityUnit>()
    const [netUnitPrice, setNetUnitPrice] = useState<number>()
    const [glow, setGlow] = useState<number>(-1)
    const [model] = useSelector(selector)
    const maxActivities = 4

    const QuantityUnitArray = [
        { idx: 0, value: "AW", label: translateText(13678) },
        { idx: 1, value: "G", label: translateText(13679) },
        { idx: 2, value: "KG", label: translateText(13680) },
        { idx: 3, value: "LTR", label: translateText(13681) },
        { idx: 4, value: "MIN", label: translateText(13682) },
        { idx: 5, value: "MTR", label: translateText(13683) },
        { idx: 6, value: "STD", label: translateText(13684) },
        { idx: 7, value: "STK", label: translateText(13685) },
    ]

    if (!model) {
        return null
    }

    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText))
    const { additionalParts } = model.additionalCostsInfo

    const actions = useActions(Actions, "updateField")

    const handleQuantityChange = (newValue: string) => {
        const stringValue = newValue || ""
        let value = parseFloat(stringValue.replace ? stringValue.replace(",", ".") : stringValue)

        if (isNaN(value)) {
            value = 0
        }

        setQuantity(value)
    }
    const handleQuantityUnitChange = (newValue: DropdownItem) => {
        setUnit(newValue.value as QuantityUnit)
    }
    const handleNetUnitPriceChange = (newValue: string) => {
        const stringValue = newValue || ""
        let value = parseFloat(stringValue.replace ? stringValue.replace(",", ".") : stringValue)

        if (isNaN(value)) {
            value = 0
        }

        setNetUnitPrice(value)
    }

    const handleAddNewAditionalPart = () => {
        const newItem: AdditionalPart = {
            description: description ?? "",
            netUnitPrice: netUnitPrice ?? 0,
            quantity: quantity ?? 0,
            unit: unit || "AW",
            deliveryNoteNumber: "",
        }
        let shouldAddNewItem = true
        const newAdditionalParts = clone(additionalParts)
        newAdditionalParts.forEach((itm, idx) => {
            if (itm.description === description && itm.netUnitPrice === netUnitPrice && itm.unit === unit) {
                newAdditionalParts[idx].quantity += quantity ?? 0
                shouldAddNewItem = false
                setGlow(idx)
            }
        })
        if (shouldAddNewItem) {
            if (maxActivities > additionalParts.length) {
                actions.updateField(["additionalCostsInfo", "additionalParts"], [...additionalParts, newItem])
            }
        } else {
            actions.updateField(["additionalCostsInfo", "additionalParts"], [...newAdditionalParts])
        }

        setDescription(undefined)
        setNetUnitPrice(undefined)
        setUnit("AW")
        setQuantity(undefined)
    }

    const handleDeleteAditionalPart = (idx: number) => {
        const newAdditionalParts = clone(additionalParts)
        newAdditionalParts.splice(idx, 1)
        actions.updateField(["additionalCostsInfo", "additionalParts"], [...newAdditionalParts])
    }

    const renderMaxLimitWarning = () => {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return <StyledWarningBox>{translateText(13700)}</StyledWarningBox>
    }

    const renderAditionalPartName = (item: AdditionalPart, idx: number) => {
        let isLastElement = additionalParts.length === idx
        if (maxActivities) {
            isLastElement = isLastElement && additionalParts.length <= maxActivities
        }

        const descriptionCharLimit = getBundleParams().gvaCostStatementDescriptionLimit

        return (
            <Table.Cell>
                {!isLastElement && item.description}
                {isLastElement && <TextField maxLength={descriptionCharLimit} value={description} onChange={setDescription} showClear />}
            </Table.Cell>
        )
    }
    const renderQuantity = (item: AdditionalPart, idx: number) => {
        let isLastElement = additionalParts.length === idx
        if (maxActivities) {
            isLastElement = isLastElement && additionalParts.length <= maxActivities
        }
        const doHighlight = glow === idx
        return (
            <Table.Cell>
                {!isLastElement && (
                    <div key={item.quantity} className={doHighlight ? highlightAnimation : ""}>
                        {item.quantity.toLocaleString()}
                    </div>
                )}
                {isLastElement && (
                    <NumberField
                        className="input--m"
                        nullable
                        onChange={handleQuantityChange}
                        value={quantity}
                        maximum={99999}
                        minimum={0.0}
                        stepSize={0.01}
                    />
                )}
            </Table.Cell>
        )
    }

    const DropdownItemView: FC<DropdownItem> = memo(({ idx, label }) => {
        return <div key={idx}>{label}</div>
    })

    const renderQuantityUnit = (item: AdditionalPart, idx: number) => {
        const isLastElement = additionalParts.length === idx
        return (
            <Table.Cell>
                {!isLastElement && item.unit}
                {isLastElement && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Dropdown
                            items={QuantityUnitArray}
                            value={QuantityUnitArray.first()}
                            onChange={handleQuantityUnitChange}
                            itemView={DropdownItemView}
                        />
                    </div>
                )}
            </Table.Cell>
        )
    }

    const renderNetUnitPrice = (item: AdditionalPart, idx: number) => {
        let isLastElement = additionalParts.length === idx
        if (maxActivities) {
            isLastElement = isLastElement && additionalParts.length <= maxActivities
        }

        return (
            <Table.Cell>
                {!isLastElement && currency(item.netUnitPrice, "EUR")}

                {isLastElement && (
                    <NumberField
                        className="input--m"
                        onChange={handleNetUnitPriceChange}
                        value={netUnitPrice}
                        nullable
                        maximum={99999}
                        minimum={0.0}
                        stepSize={0.01}
                    />
                )}

                {/* { isLastElement && <TextField
                    placeholder="EUR"
                    formatter={value => value || ""}
                    value={this.state.netUnitPrice}
                    pattern={/\d{0,5}(?:[\.,]\d{0,2})?/}
                    onChange={this.handleNetUnitPriceChange} />} */}
            </Table.Cell>
        )
    }
    const renderNetTotalPrince = (item: AdditionalPart, idx: number) => {
        let isLastElement = additionalParts.length === idx
        if (maxActivities) {
            isLastElement = isLastElement && additionalParts.length <= maxActivities
        }
        const doHighlight = glow === idx
        return (
            <Table.Cell>
                {!isLastElement && item.netUnitPrice && item.quantity && (
                    <div key={item.quantity} className={doHighlight ? highlightAnimation : ""}>
                        {currency(item.netUnitPrice * item.quantity, "EUR")}
                    </div>
                )}
                {isLastElement && !!netUnitPrice && !!quantity && currency(netUnitPrice * quantity, "EUR")}
            </Table.Cell>
        )
    }

    const renderDeleteButton = (item: AdditionalPart, idx: number) => {
        let isLastElement = additionalParts.length === idx
        if (maxActivities) {
            isLastElement = isLastElement && additionalParts.length <= maxActivities
        }

        return (
            <Table.Cell>
                {!isLastElement && (
                    <Button onClick={() => handleDeleteAditionalPart(idx)} icon="delete" skin={deleteButtonColorError ? "danger" : undefined} />
                )}
                {isLastElement && <Button disabled={!description || !quantity || !netUnitPrice} onClick={handleAddNewAditionalPart} icon="check" />}
            </Table.Cell>
        )
    }

    const newActivities = clone(model.additionalCostsInfo.additionalParts)

    newActivities.push({ description: "", netUnitPrice: 0, quantity: 0, unit: "AW", deliveryNoteNumber: "" })

    return (
        <>
            <Field.Container>
                <Field.Item m={12} title={translateText(1666)} mandatory>
                    <DateField
                        value={stringToDate(model.additionalCostsInfo.repairmentDay)}
                        className={hasFieldError(modelState["additionalCostsInfo.repairmentDay"])}
                        onChange={(date: Date) => actions.updateField(["additionalCostsInfo", "repairmentDay"], dateToString(date))}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={6} title={translateText(1408)} mandatory>
                    <TextField
                        multiline
                        showClear
                        maxLength={32}
                        value={model.additionalCostsInfo.referenceNumber}
                        className={hasFieldError(modelState["additionalCostsInfo.referenceNumber"])}
                        onChange={(value) => actions.updateField(["additionalCostsInfo", "referenceNumber"], value)}
                    />
                </Field.Item>
                <Field.Item m={6} title={translateText(1409)} mandatory>
                    <TextField
                        multiline
                        showClear
                        value={model.additionalCostsInfo.vehicleOwner}
                        className={hasFieldError(modelState["additionalCostsInfo.vehicleOwner"])}
                        onChange={(value) => actions.updateField(["additionalCostsInfo", "vehicleOwner"], value)}
                    />
                </Field.Item>
            </Field.Container>

            {isFieldAvailable("complainInfo.claimDescription") && (
                <Field.Container>
                    <Field.Item m={12} title={translateText(1475)} mandatory={modelState["complainInfo.claimDescription"]}>
                        <TextField
                            multiline
                            showClear
                            value={model.complainInfo.claimDescription}
                            className={hasFieldError(modelState["complainInfo.claimDescription"])}
                            onChange={(value) => actions.updateField(["complainInfo", "claimDescription"], value)}
                        />
                    </Field.Item>
                </Field.Container>
            )}

            <Field.Container>
                <Field.Item m={6} title={translateText(1367)} mandatory={modelState["vehicleInfo.firstRegistrationYear"]}>
                    <DateField
                        value={stringToDate(model.vehicleInfo.firstRegistrationYear)}
                        className={hasFieldError(modelState["vehicleInfo.firstRegistrationYear"])}
                        onChange={(date: Date) => actions.updateField(["vehicleInfo", "firstRegistrationYear"], dateToString(date))}
                    />
                </Field.Item>
                <Field.Item m={6} title={translateText(1370)} mandatory={modelState["vehicleInfo.deinstallationMileage"]}>
                    <TextField
                        showClear
                        value={model.vehicleInfo.deinstallationMileage}
                        className={hasFieldError(modelState["vehicleInfo.deinstallationMileage"])}
                        onChange={(value) => actions.updateField(["vehicleInfo", "deinstallationMileage"], value)}
                        pattern={/[\d.]*/}
                    />
                </Field.Item>
            </Field.Container>

            <StyledTable
                className={hasFieldError(modelState["additionalCostsInfo.additionalParts"])}
                data={newActivities}
                columns={[
                    <Table.Column renderItemContent={renderAditionalPartName}>{translateText(1410)}</Table.Column>,
                    <Table.Column renderItemContent={renderQuantity}>{translateText(1411)}</Table.Column>,
                    <Table.Column renderItemContent={renderQuantityUnit}>{translateText(12419)}</Table.Column>,
                    <Table.Column renderItemContent={renderNetUnitPrice}>{translateText(1412)}</Table.Column>,
                    <Table.Column renderItemContent={renderNetTotalPrince}>{translateText(1413)}</Table.Column>,
                    <Table.Column renderItemContent={renderDeleteButton} />,
                ]}
            />
            {maxActivities <= additionalParts.length && renderMaxLimitWarning()}
        </>
    )
}

function getHighlightAnimation() {
    const theme = getStyleTheme()

    return style({
        animationName: keyframes({
            "0%": { color: theme.colors.danger },
            "100%": { color: "black" },
        }),
        animationDuration: "2s",
    })
}

const StyledWarningBox = styled(Box)({
    width: "100%",
    padding: "1em 0.5em",
    textAlign: "center",
    color: "red",
    marginTop: "0.2em",
    border: "1px solid red",
    borderRadius: "3px",
})
