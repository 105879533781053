import { Link } from "react-router-dom"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { uniqueId, encodeUniqueId } from "@tm/utils"

type Props = LocalizationProps

function Component({ localization }: Props) {
    const workTaskId = encodeUniqueId(uniqueId())
    const url = `${workTaskId}/blaetterkataloge`
    const { translateText } = localization

    return (
        <Link className="catalogsFrame widget" to={url}>
            <div className="catalogsContent">
                <div className="text_catalogs">{translateText(12709).toUpperCase()}</div>
            </div>
        </Link>
    )
}

export default withLocalization(Component)
