import { useUser } from "@tm/context-distribution"
import { IFrame, Loader, MessageInline } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ActivatableModule, ActivateModuleRef, ModuleGroupId, ModuleOption, ModuleOptionType, ModulePackage } from "@tm/models"
import { useGetActivatableModules } from "@tm/utils"
import { Box, Divider, Typography, styled } from "@tm/components"
import { useCallback, useRef } from "react"
import Module from "./Module"
import ActivateModule from "../../../micros/activate-module/component"

export type RouteProps = {
    workTaskId: string
    module: string
    feedbackUrl: string
}

type Props = {
    params: RouteProps
    moduleGroupId: ModuleGroupId
    routes: Record<string, string>
    demoActivationInstanstly?: boolean
}

const formUrlRegex = new RegExp(/formUrl=([\s\S]*)/g)

export default function Modules({ params, moduleGroupId, routes, demoActivationInstanstly }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const activatableModulesLoadable = useGetActivatableModules(userContext)
    const activateRef = useRef<ActivateModuleRef>(null)

    const handleActivateModule = useCallback(
        (module: ActivatableModule, moduleOption?: ModuleOption, modulePackage?: ModulePackage) => {
            activateRef.current?.handleActivateModule(moduleGroupId, module, moduleOption, modulePackage)
        },
        [moduleGroupId]
    )

    function renderExternalIframe() {
        if (!params.feedbackUrl) {
            return null
        }

        // the url contains formUrl param which is the url for feedback, but here, after decoding, a value like ' -> ' can be found, which will break the url
        let feedbackUrl = decodeURIComponent(params.feedbackUrl)

        let formUrl = feedbackUrl.match(formUrlRegex)?.[0]

        if (!formUrl) {
            return null
        }

        formUrl = formUrl.replace("formUrl=", "")
        feedbackUrl = feedbackUrl.replace(formUrl, encodeURIComponent(formUrl))

        return (
            <StyledIframe>
                <IFrame url={feedbackUrl} id="iframe_autodataFeedback" refreshOnUrlChanged contextDependent />
            </StyledIframe>
        )
    }

    if (activatableModulesLoadable.isLoading) {
        return <Loader />
    }

    if (params.module) {
        const activatableModule = activatableModulesLoadable.response?.moduleGroups
            ?.find((x) => x.id == "technicalData")
            ?.modules?.find((x) => x.id == params.module)

        const moduleIsFullActive = activatableModule?.moduleOptions?.find((x) => x.type == ModuleOptionType.Full)?.active

        return (
            <MvcFeedback>
                {renderExternalIframe()}
                {activatableModule && !moduleIsFullActive && (
                    <>
                        <Divider sx={{ marginRight: "1.5em", border: "1px solid darkgrey" }} />

                        <ActivateModule
                            ref={activateRef}
                            demoActivationInstanstly={demoActivationInstanstly}
                            routes={routes}
                            workTaskId={params.workTaskId}
                        />

                        <Box key={module.id} marginTop="1.5rem" display="flex" flexDirection="column" alignItems="center">
                            <Typography fontSize="18px" mb="0.5em">
                                {translateText(13653)}
                            </Typography>
                            <Module module={activatableModule} handleActivateModule={handleActivateModule} />
                            <Typography mt="1.5em" variant="body3">
                                *{translateText(12873)}
                            </Typography>
                        </Box>
                    </>
                )}
            </MvcFeedback>
        )
    }

    if (!activatableModulesLoadable.response) {
        return (
            <Box flexDirection="column" flex="1" marginTop="2em">
                <MessageInline message={translateText(286)} skin="error" />
            </Box>
        )
    }

    const moduleGroup = activatableModulesLoadable.response.moduleGroups.find((m) => m.id === moduleGroupId)

    return (
        <>
            <ActivateModule ref={activateRef} demoActivationInstanstly={demoActivationInstanstly} routes={routes} workTaskId={params.workTaskId} />

            {moduleGroup?.modules?.map((module) => <Module key={module.id} module={module} handleActivateModule={handleActivateModule} />) ?? null}
        </>
    )
}

const StyledIframe = styled(Box)(() => ({
    flex: 1,
    height: "inherit",
    maxWidth: "550px",
    " > div": {
        flex: 1,
        height: "100%",
        width: "100%",
    },
}))

const MvcFeedback = styled(Box)(() => ({
    height: "100%",
    display: "flex",
    flex: 1,
    flexDirection: "row",
}))
