import { memo, useCallback, useEffect, useRef, useState } from "react"
import { channel } from "@tm/models"
import { encodeUniqueId, withRouter, getStorage, RouteComponentProps } from "@tm/utils"

import { getBundleParams } from "../../utils"
import { DeletionPrompt } from "./components/DeletionPrompt"
import { useBasketModuleParameter } from "../../hooks/useBasketModuleParameter"

export const LOCAL_STORAGE_KEY = "WORKTASK_CLOSE_DIALOG_DEACTIVATED"
const MAX_LOCAL_STORAGE_ENTRY_AGE = 60 * 60 * 24 * 1000

const ActiveOrderModal = memo<RouteComponentProps>(({ history }) => {
    const [event, setEvent] = useState<boolean>(false)
    const localStorage = getStorage("localStorage").getItem(LOCAL_STORAGE_KEY)
    const [workTaskId, setWorkTaskId] = useState<string>()

    const { hasWorkTaskOrder } = useBasketModuleParameter()

    const callbackRef = useRef(() => {
        /* do nothing */
    })

    const { interceptWorkTaskDeletionRequest, nextLight } = getBundleParams()

    useEffect(() => {
        /* delete localstorage entry after 24h if the user reload the page -> NEXT-15870 */
        if (localStorage && +localStorage < Date.now() - MAX_LOCAL_STORAGE_ENTRY_AGE) {
            getStorage("localStorage").removeItem(LOCAL_STORAGE_KEY)
        }
    }, [localStorage])

    useEffect(() => {
        const globalChannel = channel("GLOBAL")

        return globalChannel.subscribe("INTERCEPTABLE/WORKTASK_DELETE", ({ callback, workTaskId }) => {
            const localStorage = getStorage("localStorage").getItem(LOCAL_STORAGE_KEY)

            if (nextLight || !hasWorkTaskOrder || !interceptWorkTaskDeletionRequest || localStorage) {
                callback()
            } else {
                setEvent(true)
                setWorkTaskId(workTaskId)
                callbackRef.current = callback
            }
        })
    }, [interceptWorkTaskDeletionRequest, callbackRef, nextLight])

    const setLocalStorageKey = useCallback(() => {
        getStorage("localStorage").setItem(LOCAL_STORAGE_KEY, `${Date.now()}`)
    }, [])

    const handleOnCancellation = useCallback(
        (dontAskAgain: boolean) => {
            if (dontAskAgain) {
                setLocalStorageKey()
            }
            setWorkTaskId(undefined)
            callbackRef.current && callbackRef.current()
        },
        [setLocalStorageKey]
    )

    const handleOnConfirm = useCallback(
        (dontAskAgain: boolean): void => {
            if (!workTaskId) {
                return
            }
            if (dontAskAgain) {
                setLocalStorageKey()
            }
            setWorkTaskId(undefined)
            history.push(`/${encodeUniqueId(workTaskId)}/basket`)
        },
        [history, workTaskId, setLocalStorageKey]
    )

    if (!event || !workTaskId || localStorage) {
        return null
    }

    return <DeletionPrompt workTaskId={workTaskId} handleConfirmation={handleOnConfirm} handleCancellation={handleOnCancellation} />
})

export default withRouter(ActiveOrderModal)
