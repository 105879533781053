import { useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { withWorkTask, WithWorkTaskProps, WithUserContextProps, withUserContext } from "@tm/context-distribution"
import { IFrame, Loader } from "@tm/controls"
import { JfNetworkPvState, IActions, Actions } from "./business"

type Props = WithWorkTaskProps &
    WithUserContextProps & {
        state: JfNetworkPvState
        actions: IActions
        url: string
    }

function JfNetworkPvComponent(props: Props) {
    const { userContext, workTask } = props
    const { tecDocTypeId: kTypNr, registrationNo: kbaNumber } = workTask?.vehicle || {}
    const customerNo = userContext && userContext.account && userContext.account.customerNo

    useEffect(() => {
        if (customerNo) {
            props.actions.getUrl(props.url, customerNo, kTypNr, kbaNumber)
        }
    }, [kTypNr, customerNo, kbaNumber])

    const { url } = props.state

    if (!url) {
        return null
    }

    return (
        <IFrame url={url} className="module frame jfn-pv" contextDependent id="iframe_jfn-pv">
            <Loader />
        </IFrame>
    )
}

export default connectComponent(Actions, withWorkTask(withUserContext(JfNetworkPvComponent)))
