import { useMemo, useEffect } from "react"
import { RegisteredModels, GetBonusInformationResponse, GetBonusInformationRequest } from "@tm/models"
import { Container } from "@tm/nexus"
import { atomFamily, useRecoilState, useResetRecoilState } from "recoil"
import { mapBasketPartsToErpInformationRequest } from "../mapper"
import { BasketOrderGroup } from "../../models"

export type BonusInformationState = {
    loading: boolean
    groups: {
        distributorId?: number
        response: GetBonusInformationResponse
    }[]
}

const bonusInformationAtom = atomFamily<BonusInformationState, string>({
    key: "basket_bonusInformationAtom",
    default: { loading: false, groups: [] },
})

export function useBonusInformation(
    workTaskId: string,
    showBonusInformation?: boolean,
    telesalesCustomerNo?: string,
    basketOrderGroups?: BasketOrderGroup[]
) {
    const [state, setState] = useRecoilState(bonusInformationAtom(workTaskId))
    const reset = useResetRecoilState(bonusInformationAtom(workTaskId))

    const requests = useMemo(() => {
        if (!showBonusInformation || !basketOrderGroups?.length) {
            return
        }

        const result: { [distributorId: string]: GetBonusInformationRequest | undefined } = {}

        basketOrderGroups.forEach((group) => {
            const resultKey = group.orderGroup.distributorId || ""
            const items = mapBasketPartsToErpInformationRequest(group.basketParts)
            if (!items.length) {
                return
            }

            if (!result[resultKey]) {
                result[resultKey] = {
                    telesalesCustomerNo,
                    distributorId: group.orderGroup.distributorId,
                    items,
                }
            } else {
                result[resultKey]!.items.push(...items)
            }
        })

        return result
    }, [showBonusInformation, telesalesCustomerNo, basketOrderGroups])

    useEffect(() => {
        if (!requests) {
            return
        }

        let cancelled = false

        setState({ loading: true, groups: [] })

        const promises = Object.values(requests).map((request) => {
            if (!request) {
                return
            }
            return Container.getInstance<GetBonusInformationResponse>(RegisteredModels.ERP_GetBonusInformation)
                .subscribe(request)
                .load()
                .then((response) => {
                    if (cancelled || !response) {
                        return
                    }
                    setState((prev) => ({ ...prev, groups: prev.groups.concat({ distributorId: request.distributorId, response }) }))
                })
        })

        Promise.all(promises).finally(() => {
            if (cancelled) {
                return
            }
            setState((prev) => ({ ...prev, loading: false }))
        })

        return () => {
            cancelled = true
            reset()
        }
    }, [JSON.stringify(requests)])

    return state
}
