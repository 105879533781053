import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"
import * as Models from "../model"

function getOeServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.oeResearchServiceUrl
}

function getOensServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.oensServiceUrl
}

export function getOeReplacements(query: string): Promise<Array<Models.OeReplacementManufacturer>> {
    const url = `${getOeServiceUrl()}/FindOeReplacements`
    const authorization = getStoredAuthorization()
    const body = { query }

    return ajax({ url, body, authorization }).then((data) => (Array.isArray(data?.manufacturers) ? data.manufacturers : undefined))
}

export function getOensArticles(query: string): Promise<Array<Models.OensArticle>> {
    const url = `${getOensServiceUrl()}/FindArticles`
    const authorization = getStoredAuthorization()
    const body = { query }

    return new Promise<Array<Models.OensArticle>>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve(data?.articles ?? []), reject)
    )
}

export function getOensManufacturers(): Promise<Array<Models.OeManufacturer>> {
    const url = `${getOensServiceUrl()}/GetManufacturers`
    const authorization = getStoredAuthorization()

    return new Promise<Array<Models.OeManufacturer>>((resolve, reject) =>
        ajax({ url, authorization }).then((data) => resolve(data?.manufacturers ?? []), reject)
    )
}
