import { createElement, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ErePosition, MmtGroup } from "../../../data/models"
import { EurotaxPanel, GraphicViewer } from "../../_shared"

type Props = {
    item: MmtGroup
    selectedMmtGroup?: MmtGroup
    multiselection?: boolean
    index?: number,
    disabledZoom?: boolean
    withoutPanel?: boolean
    onSelect: (position: ErePosition) => void
}

export default function Graphical({ item, index, multiselection, selectedMmtGroup, withoutPanel, disabledZoom, onSelect }: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    const handleGraphicClick = (value: string) => {
        const erePosition = item.positions.find(x => x.name == value)
        erePosition && onSelect(erePosition)
    }

    const title = multiselection && `${item.doorsNo} ${translateText(1787)}` || ""

    const wrapper = (cc: any) => {
        if (withoutPanel) {
            return (<div className={style.content}>{cc}</div>)
        }

        return <EurotaxPanel className={style.panel} title={title} >{cc}</EurotaxPanel>
    }

    return wrapper(
        <GraphicViewer
            svgImage={item.svg}
            index={index}
            renderClickTooltip={createElement(Loader as any, { hideXBtn: true, removeOutsideClick: true })}
            // multipeItemsOnView
            onClick={handleGraphicClick.bind(item)}
            withColors={true}
            zoomEnabled={!disabledZoom}
            selected={selectedMmtGroup?.doorsNo == item.doorsNo && selectedMmtGroup?.selectedErePosition?.name || undefined}
            items={item?.positions.filter(x => x.parentName).map(x => ({ id: x.name, tooltip: x.description, isVisible: true }))}
        />
    );
}


function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        content: {
            // height: percent(100),
            padding: theme.margin.m,
            display: "flex",
            flex: 1,
        },
        panel: {
            $nest: {
                "&:not(:last-child)": {
                    marginRight: theme.margin.s,
                }
            }
        }
    })(Graphical)
}
