import { Divider, styled, TextField, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { FC, ReactNode, memo } from "react"
import { CollapsibleWrapper, SecutiryStatus, UploadPic } from "."
import { useTextFieldSubmit } from "../../hooks/useTextFieldSubmit"

type Props = {
    path: string
    additionalContent?: ReactNode
    onCommentSave(comm: string): void
    onSelect?: (value: number) => void
    selectedData?: { safetyStatus?: number; description?: string }
}

const TypographyTitle = styled(Typography)({
    padding: ".5em .5em .5em 0",
})

const DefectDescription: FC<Props> = memo(({ path, additionalContent, onCommentSave, onSelect, selectedData }) => {
    const { translateText } = useLocalization()
    const { inputValue: comment, inputActions: commentInputActions } = useTextFieldSubmit<string>(selectedData?.description ?? "", onCommentSave)

    return (
        <div>
            <TypographyTitle variant="h4">{translateText(12767)}</TypographyTitle>
            <CollapsibleWrapper textId={617}>
                <TypographyTitle variant="body2">{translateText(12751)}</TypographyTitle>

                <TextField
                    fullWidth
                    sx={{ paddingTop: "9px !important" }}
                    size="extralarge"
                    placeholder={translateText(12752)}
                    value={comment}
                    {...commentInputActions}
                />
                <Divider light sx={{ marginTop: "1em" }} />
            </CollapsibleWrapper>

            {additionalContent && additionalContent}

            <CollapsibleWrapper textId={12753}>
                <TypographyTitle variant="body2">{translateText(12754)}</TypographyTitle>
                <UploadPic path={path} />
            </CollapsibleWrapper>
            {path.startsWith("tyresAndWheels") && <SecutiryStatus onSelect={onSelect || (() => {})} value={selectedData?.safetyStatus || 0} />}
        </div>
    )
})

export default DefectDescription
