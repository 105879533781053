import { useUser } from "@tm/context-distribution"

export function useIsUniBestsellerActive() {
    const user = useUser()

    const module = user.userContext.modules?.find((m) => m.description === "UnipartsPremium")
    if (module) {
        const mod = module.parameters?.find((para) => para.key === "DisplayBehavior")
        // "NEXT - Direkteinstieg-Produkt anzeigen" (ID 193001)
        // "NEXT - Direkteinstieg-Produkt nicht anzeigen" (ID 193002)
        if (mod?.value === "193001") {
            return true
        }
    }
    return false
}
