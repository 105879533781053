import { countdownStyle } from "../style"
import { CountdownComponent, CountdownComponentProps } from "./CountdownComponent"

export type CountDownWrapperProps = CountdownComponentProps

export function CountdownWrapper(props: CountDownWrapperProps) {
    return (
        <div className={countdownStyle.wrapper}>
            <CountdownComponent dueDate={props.dueDate} />
        </div>
    )
}

export interface CountdownAnchorWrapperProps extends CountdownComponentProps {
    url: string
    target?: string
}

export function CountdownAnchorWrapper(props: CountdownAnchorWrapperProps) {
    let { target, url } = props

    if (target === "external") {
        url = `/external01?url=${url}`
        target = undefined
    }

    return (
        <a href={url} target={target} className={countdownStyle.wrapper} rel="noopener noreferrer">
            <CountdownComponent dueDate={props.dueDate} />
        </a>
    )
}
