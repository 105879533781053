import { useMemo } from "react"
import { Button, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { useLastMessages } from "../../../../../data/hooks"
import { getBundleParams } from "../../../../../utils"
import { MessageInfo } from "./message-info"
import { getComponentStyles } from "./styles"

type Props = {
    lastReadInfoDate: Date | undefined
    onClose(): void
}

export function PopoverContent({ onClose, lastReadInfoDate }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText } = useLocalization()
    const { chats, loading } = useLastMessages(10)

    if (loading) {
        return <Loader />
    }

    if (!chats?.length) {
        return <Text modifiers="block">{translateText(504)}</Text>
    }

    return (
        <>
            <div className={classNames.detailsButton}>
                <Button onClick={onClose} linkTo={renderRoute(getBundleParams().chatDetailsRoute, {})}>
                    {translateText(409)}
                </Button>
            </div>
            <div className={classNames.chatsWrapper}>
                {chats.map((c) => (
                    <MessageInfo key={c.chatId} chat={c} lastReadInfoDate={lastReadInfoDate} onClose={onClose} />
                ))}
            </div>
        </>
    )
}
