import { useCallback, useEffect } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useCountryCodeToLicensePlate, useSelectedVehicleLookup, useUser, useWorkTask } from "@tm/context-distribution"
import { DateField, LicensePlateField, SuggestionTextField, TextField, VinField, VinFieldProps } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ESubModules, RegistrationNoType, UserModuleType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { getCostPerCalculation, getVehicleRegistrationNoLabelTextIds, initSelector } from "@tm/utils"
import { Box, Icon, Loader, Typography, styled, useTheme } from "@tm/components"
import { useGetVins } from "../../../data/hooks/useGetVins"
import { getBundleParams } from "../../../utils"
import { MainActions, fieldsSelector, vehicleSelector } from "../../main/business"
import { validateFields } from "../business/helpers"

export const vehicleFieldsSelector = createSelector(fieldsSelector, (fields) => {
    const { keyNumber, initialRegistration, mileage, plateNumber, vin, keyNumbers, minDate, processName } = fields
    return { keyNumber, initialRegistration, mileage, plateNumber, vin, keyNumbers, minDate, processName }
})

const tecdocselector = createSelector(vehicleSelector, (vehicle) => vehicle?.tecDocTypeId)

const selector = initSelector(
    vehicleFieldsSelector,
    tecdocselector,
    (s) => s.summary.vinSearchInformalMessage,
    (s) => s.summary.datVinSearchIsValid,
    (s) => s.summary.checkVinLoading,
    (s) => s.summary.showVehicleModelDetailsLoading,
    (s) => s.summary.isGermanOrAustrianVehicle
)

export default function VehicleInfoFields() {
    const { enableDATVinSearch, useAttachToWorktask } = getBundleParams()
    const localization = useLocalization()
    const user = useUser()
    const { translateText } = localization
    const { workTask, attachToWorkTask } = useWorkTask() ?? {}
    const vehicle = workTask?.vehicle
    const actions = useActions(MainActions, "updateFieldValue", "validateVin", "loadVehicleDetails", "initVehicle")
    const [
        fields,
        tecDoc,
        vinSearchInformalMessage,
        datVinSearchIsValid,
        checkVinLoading,
        showVehicleModelDetailsLoading,
        isGermanOrAustrianVehicle,
    ] = useSelector(selector)
    const modelState = validateFields(fields, translateText, vehicle, isGermanOrAustrianVehicle)
    const { selectedVehicleLookup } = useSelectedVehicleLookup()
    const vehicleVinSuggestions: string[] = useGetVins("", tecDoc) ?? []
    const theme = useTheme()
    const { plateCode: shortCountryCode, countryCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)

    useEffect(() => {
        actions.initVehicle(countryCode)
    }, [vehicle])

    const handleGetVinSuggestions = useCallback(
        (query: string): Array<string> => {
            return vehicleVinSuggestions?.length && query?.length
                ? vehicleVinSuggestions?.filter((vin) => vin.startsWith(query))
                : vehicleVinSuggestions
        },
        [vehicle, fields.vin, vehicleVinSuggestions]
    )

    const handleValidateVin = () => {
        actions.validateVin(attachToWorkTask, translateText)
    }

    const renderKeyNumberField = () => {
        if (!isGermanOrAustrianVehicle) {
            return
        }

        let itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(
            (vehicle?.registrationTypeId === RegistrationNoType.TopmotiveVin ? RegistrationNoType.Kba : vehicle?.registrationTypeId) ||
                RegistrationNoType.Kba
        )

        if (!itemLabelTextIds?.label) {
            itemLabelTextIds = getVehicleRegistrationNoLabelTextIds(selectedVehicleLookup?.lookupTypeId)
        }
        if (!itemLabelTextIds?.label) {
            return
        }

        return (
            <Box className="col key-number">
                <SuggestionTextField
                    floatingLabel
                    label={translateText(itemLabelTextIds?.label)}
                    value={fields.keyNumber}
                    getSuggestionData={() => fields.keyNumbers}
                    onChangeConfirm={(value) => actions.updateFieldValue("keyNumber", value)}
                    path={["keyNumber"]}
                    modelState={modelState}
                />
            </Box>
        )
    }

    const calculationCost = getCostPerCalculation(UserModuleType.TruckGarage, ESubModules.DamageCalculationGlass, user?.userContext)
    const vinFieldProps: VinFieldProps = {
        floatingLabel: true,
        label: translateText(101),
        coloredBorder: true,
        value: fields.vin,
        onChangeConfirm: (value) => actions.updateFieldValue("vin", value),
        onChange: (value) => actions.updateFieldValue("vin", value),
        getSuggestionData: handleGetVinSuggestions,
        path: ["vin"],
        modelState,
        ...(useAttachToWorktask &&
            enableDATVinSearch && {
                datVinSearchIsValid,
                vinSearchRequired: true,
                vinSearchInformalMessage,
                datPricePerRequest: calculationCost?.toString(),
                checkVinLoading,
                translateText,
                validateVin: handleValidateVin,
            }),
    }

    return (
        <Box borderTop="solid #e2e2e2 0.1em">
            <Box display="flex" padding={theme.margin?.m} alignItems="center" gap=".5em">
                <Icon name="car-front" size="2em" color="black" />
                <Typography fontSize="large">{translateText(99)}</Typography>
            </Box>
            {showVehicleModelDetailsLoading ? (
                <Box display="inline-flex" margin=".5em" alignItems="center" gap="1em">
                    <Loader size="small" />
                    <Typography>{translateText(12583)}</Typography>
                </Box>
            ) : (
                <>
                    <StyledBox>
                        {renderKeyNumberField()}
                        <Box className="col initial-registration">
                            <DateField
                                useUtc
                                floatingLabel
                                label={translateText(124)}
                                value={fields.initialRegistration}
                                minDate={fields.minDate}
                                openToDate={fields.initialRegistration || fields.minDate}
                                onChange={(value) => actions.updateFieldValue("initialRegistration", value)}
                                path={["initialRegistration"]}
                                modelState={modelState}
                            />
                        </Box>
                        <Box className="col vin">
                            <VinField className="vinField" {...vinFieldProps} />
                        </Box>
                        <Box className="col plateId">
                            <LicensePlateField
                                floatingLabel
                                className="fixedHeight"
                                label={translateText(21)}
                                value={fields.plateNumber}
                                shortCountryCode={shortCountryCode}
                                onChange={(value) => actions.updateFieldValue("plateNumber", value)}
                                maxLength={17}
                                path={["plateNumber"]}
                                modelState={modelState}
                                showErrorBorder
                            />
                        </Box>
                        <Box className="col">
                            <TextField
                                floatingLabel
                                label={translateText(125)}
                                path={["mileage"]}
                                modelState={modelState}
                                value={fields.mileage || undefined}
                                onChange={(value) => actions.updateFieldValue("mileage", value)}
                                pattern={/[\d.]*/}
                                formatter={(value) => value.replace(/[^\d]/, "").replace(",", "").replace(".", "")}
                            />
                        </Box>
                    </StyledBox>
                    {!isGermanOrAustrianVehicle && (
                        <Typography fontWeight={600} padding=".5em">
                            {translateText(13286)}
                        </Typography>
                    )}
                </>
            )}
        </Box>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    marginTop: "0.5rem",
    ".col": {
        padding: "0.5rem 0 0.5rem 0.5rem",

        "&.vin": {
            flex: "0 0 20%",
        },

        "&.plateId": {
            flex: "0 0 13%",

            ".plate-id": {
                width: "100%",
            },
        },
    },
    ".fixedHeight": {
        ".input__inner": {
            height: "2.5rem",
            ".input__field": {
                fontSize: theme.font?.textSize.m,
                lineHeight: "1.2",
            },
        },
    },
    ".vinField": {
        ".btn": {
            ".icon": {
                width: "2.5rem",
                height: "2.5rem",
            },
        },
    },
    ".btn__content": {
        textAlign: "left",
        ".text": {
            marginTop: "0.25em",
            color: "unset",
        },
    },
}))
