import { ArticleIdentifier, IMicros, OE } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { equals } from "@tm/utils"
import { useSelector } from "react-redux"
import { ReplaceButton } from "@tm/controls"
import { ExtendedOePart, Part } from "../../../data/models"
import { vehicleSelector } from "../../main/business"

type Props = {
    alternativePart: Part
    oeArticle: ExtendedOePart
    selectedPart?: Part
    onSelect(): void
}

export default function PartItem({ alternativePart, oeArticle, onSelect, selectedPart }: Props) {
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const { renderMicro } = useMicro<IMicros>()
    const vehicle = useSelector(vehicleSelector)

    const renderBasketButton = () => (
        <div className="tk-basket">
            <div className="add-to-basket">
                <ReplaceButton onClick={onSelect} isWm={isWm} />
            </div>
        </div>
    )

    const renderOeArticle = () => {
        const oePart: OE.OePart = {
            description: oeArticle.description,
            ...(!oeArticle.isExtraArticle && {
                manufacturerName: vehicle?.manufacturer,
            }),
            ...(oeArticle.isExtraArticle && {
                manufacturerName: "DAT",
                thumbnailUrl: "/styles/base/images/dat-logo.png",
            }),
            manufacturerId: vehicle?.tecDocManufacturerId,
            number: oeArticle.oeArticleNumber,
            prices: [
                {
                    value: oeArticle.oePriceValue ?? 0,
                    currency: "EUR",
                },
            ],
        }

        return (
            <>
                {renderMicro!("parts", "oe-part-item", {
                    oePart,
                    renderBasketButton,
                    hiddenFakeActions: { costEstimation: true, moreMenu: true, details: true },
                })}
            </>
        )
    }

    const renderPart = () => {
        const art: ArticleIdentifier = {
            productGroupId: alternativePart.article?.productGroup.id ?? alternativePart.productGroupId,
            supplierArticleNo: alternativePart.article?.supplierArticleNo ?? alternativePart.dataSupplierArticleNumber,
            supplierId: alternativePart.article?.supplier.id ?? alternativePart.dataSupplierId,
            tecdocTypeNo: vehicle?.tecDocTypeId,
        }

        return (
            <>
                {renderMicro!("parts", "part-item", {
                    partToLoad: art,
                    renderBuyActions: renderBasketButton,
                    showActions: true,
                } as any)}
            </>
        )
    }

    if (equals(selectedPart, alternativePart)) {
        return renderOeArticle()
    }

    return renderPart()
}
