import { Box, Button, Icon, styled, Typography } from "@tm/components"
import { SuggestionSearchField, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getServiceNodesById } from "../../data/repositories"
import { useServicesStore } from "../../data/state"

function NavigationComponent() {
    const { translateText } = useLocalization()

    const { searchValue, setSearchValue, setLoadingServices, setServices } = useServicesStore()

    const handleSearch = () => {
        setLoadingServices(true)
        getServiceNodesById({ Search: searchValue }).then(x => { !!x ? setServices(x) : setLoadingServices(false) })
    }

    return (
        <StyledNavigation>
            <StyledHeadline variant="h4" className="module-title">
                {translateText(83)}
            </StyledHeadline>
            <Toolbar className="repair-times__direct-search">
                <SuggestionSearchField
                    value={searchValue}
                    className="input-field"
                    onChangeConfirm={handleSearch}
                    placeholder={translateText(135)}
                    onChange={setSearchValue}
                    button={<Button onClick={handleSearch}>{translateText(135)}</Button>}
                />
            </Toolbar>
            <StyledIcon name="atu-logo-filled" />
        </StyledNavigation>
    )
}

const StyledHeadline = styled(Typography)(({ theme }) => ({
    marginRight: theme.margin?.xl
}))

const StyledIcon = styled(Icon)({
    marginLeft: "auto",
    width: "8rem",
    height: "2rem"
})

const StyledNavigation = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.margin?.m,
    overflow: "hidden",
    flex: 0.05
}))

export default NavigationComponent