import { SortingFilterType } from "@tm/models"
import { useKeyValue } from "@tm/utils"
import { useCallback, useMemo } from "react"

export function usePreferredSortOptionKey(): [SortingFilterType, (sort: SortingFilterType) => void] {
    const [value, setValue] = useKeyValue({
        key: "VEHICLE_SORTING_FILTER_PREFERRED_OPTION",
        ownedByRepairShop: false,
    })

    const sorting = useMemo<SortingFilterType>(() => {
        if (value === "alphabetical") {
            return "alphabetical"
        }

        return "relevance"
    }, [value])

    const setSorting = useCallback(
        (newSorting: SortingFilterType) => {
            setValue?.(newSorting)
        },
        [setValue]
    )

    return [sorting, setSorting]
}
