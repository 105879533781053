import { ErpContainer, ErpInformation, ErpInformationRequestItem, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useEffect, useState } from "react"
import { uniqueId, useDefaultErpSystem } from "@tm/utils"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { IArticle } from "../models"

export function useErp(article: IArticle) {
    const { erpSystemConfig } = useDefaultErpSystem()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const [erp, setErp] = useState<ErpInformation>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!article?.traderArticleNumber) {
            return
        }

        setLoading(true)

        const Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)

        Erp.action("getErpInfo")({
            item: {
                itemId: uniqueId(),
                wholesalerArticleNumber: article?.traderArticleNumber || "",
                quantityValue: article?.quantity,
            } as ErpInformationRequestItem,
            distributorId: erpSystemConfig?.id,
            telesalesCustomerNo,
        }).then((x) => {
            setErp(x)
            setLoading(false)
        })
    }, [article.traderArticleNumber, erpSystemConfig?.id, telesalesCustomerNo])

    return { erp, loading }
}
