import { channel, Vehicle } from "@tm/models"
import { useState, useEffect } from "react"
import { isVehicleChanged, loadCarModelDetails } from "../helpers/common"

export const useDATVehicle = (workTaskId?: string, perviousVehicle?: Vehicle, calBack?: (veh: Vehicle, eCode?: string) => void) => {
    const [vehicle, setVehicle] = useState<Vehicle | undefined>(perviousVehicle)
    const [datECode, setDatECode] = useState<string | undefined>()

    useEffect(() => {
        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVeh) => {
                if (isVehicleChanged(newVeh, vehicle)) {
                    newVeh &&
                        loadCarModelDetails(newVeh).then((details) => {
                            const datECode = details?.modelDetails?.registrationNoDetails?.datECode
                            setDatECode(datECode)
                            setVehicle(newVeh)
                            calBack?.(newVeh, datECode)
                        })
                }
            },
            true
        )
        return unsubVehicle
    }, [perviousVehicle])

    return vehicle
}
