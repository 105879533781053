import { memo } from "react"

import { Note as ArticleNoteInterface } from "@tm/models"
import { Note } from "./component"

type Props = {
    saveInProgress: boolean
    onChange(value?: string): void
    note?: ArticleNoteInterface
}

export const ArticleNote = memo<Props>(({ saveInProgress, onChange, note }) => {
    return <Note note={note?.text} onChange={onChange} isLoading={saveInProgress} />
})
