import { VoucherType, AddReturnItemRequest } from "@tm/models"
import { ExtendedOrderVoucherItem } from "../../../data/model"

export enum TotalType {
    all = 1,
    parts = 2,
    works = 3,
}

export enum TotalNumberType {
    parts = 1,
    works = 2,
}

export function getAddReturnItemRequest(
    voucherItem: ExtendedOrderVoucherItem,
    voucherTypeId: VoucherType,
    voucherId: string | undefined,
    orderDate?: Date,
    catlogOrderNumber?: string
) {
    if (voucherItem.articleInfo.dataSupplier && voucherItem.articleInfo.productGroup) {
        const addReturnItemRequest: AddReturnItemRequest = {
            dataSupplierArticleNumber: voucherItem.articleInfo.dataSupplierArticleNumber,
            dataSupplierId: voucherItem.articleInfo.dataSupplier.id,
            dataSupplierName: voucherItem.articleInfo.dataSupplier.name,
            description: voucherItem.articleInfo.description,
            // invoice:
            orderNo: catlogOrderNumber,
            orderDate: orderDate ?? new Date(),
            productGroupId: voucherItem.articleInfo.productGroup.id,
            productGroupName: voucherItem.articleInfo.productGroup.name,
            returnQuantity: voucherItem.allowedReturnQuantity ? voucherItem.allowedReturnQuantity.value : voucherItem.confirmedQuantity.value,
            thumbnailUrl: voucherItem.articleInfo.thumbnailUrl,
            voucherId,
            voucherItemId: voucherItem.id,
            voucherTypeId,
            wholesalerArticleNumber: voucherItem.articleInfo.wholesalerArticleNumber,
        }

        return addReturnItemRequest
    }
}
