import { VoucherType } from "@tm/models"
import { ModuleHeaderSlot } from "../../../../../../../parts/src/components/ListV2/components/ModuleHeader/ModuleHeaderSlot"
import CostEstimationActionButtons from "./CostEstimationActionButtons"
import OrderActionButtons from "./OrderActionButtons"

type Props = {
    voucherType: VoucherType
}

export default function ActionButtons({ voucherType }: Props) {
    let content
    if (voucherType === VoucherType.CostEstimation) {
        content = <CostEstimationActionButtons />
    } else if (voucherType === VoucherType.Order) {
        content = <OrderActionButtons />
    }

    return <ModuleHeaderSlot title="">{content}</ModuleHeaderSlot>
}
