import { StateCreator } from "zustand"
import { FastCalculator } from "@tm/data"
import { ECalcOrigin } from "@tm/models"
import { MainSlice } from "./model"
import { FastCalculatorStore } from "../store"

const initialState: MainSlice = {
    loading: true,
    error: false,
    skipReload: false,
    calcOrigin: ECalcOrigin.Next,
    unsavedServices: [],
    selectedServices: [],
    additionalData: {
        loading: false,
        articles: [],
        orderHistory: [],
    },
    fastCalculatorData: {
        activeCalcState: FastCalculator.ECalcState.None,
        availableCalcStates: FastCalculator.ECalcState.None,
        calcStates: [],
    },
}

export const createMainSlice: StateCreator<FastCalculatorStore, [["zustand/devtools", never]], [], MainSlice> = (set) => ({
    ...initialState,
})
