import { Button, Icon, Loader } from "@tm/components"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ETransferMode } from "@tm/models"
import { useErpConfig } from "@tm/utils"
import { useMemo } from "react"
import { EGsiContext, useGsiContext } from "../../../data/hooks/useGsiContext"
import { useTransferCostEstimation } from "../../../data/hooks/useTransferCostEstimation"
import { ESendCostEstimationMode } from "../../../data/model"
import { useCostEstimationModuleParameter } from "../../../hooks/useCostEstimationModuleParameter"

type Props = {
    workTaskId: string
    disabled?: boolean
    mode: ESendCostEstimationMode
}
export function TransferButtons({ workTaskId, disabled, mode }: Props) {
    const { translateText, languageId } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { useSendCostEstimation } = useCostEstimationModuleParameter()
    const { erpSystemConfigs } = useErpConfig()
    const workTaskIsBoschOs3Context = useGsiContext(workTaskId) === EGsiContext.BoschOs3
    const { sendCostEstimation, loading } = useTransferCostEstimation(workTaskId, telesalesCustomerNo, mode)

    const erpSystemsWithSendCostEstimation = useMemo(() => {
        if (!useSendCostEstimation || !erpSystemConfigs) {
            return []
        }

        return erpSystemConfigs.filter((x) => {
            // Only regard erp systems that are configured for sending cost estimation.
            if (!x.useForWorkTaskSendCostEstimation) {
                return false
            }

            switch (mode) {
                case ESendCostEstimationMode.Basket:
                    // If work task is in BoschOs3 context, show only BoschOs3 transfer button otherwise show none.
                    return workTaskIsBoschOs3Context && x.transferMode === ETransferMode.BasketTransferBoschOs3
                case ESendCostEstimationMode.CostEstimation:
                    // If work task is in BoschOs3 context, show only BoschOs3 transfer button otherwise show all except BoschOs3.
                    return workTaskIsBoschOs3Context
                        ? x.transferMode === ETransferMode.BasketTransferBoschOs3
                        : x.transferMode !== ETransferMode.BasketTransferBoschOs3
                default:
                    return false
            }
        })
    }, [erpSystemConfigs, useSendCostEstimation, workTaskIsBoschOs3Context, mode])

    return (
        <>
            {loading && <Loader size="small" />}
            {!loading &&
                erpSystemsWithSendCostEstimation.map((erpSystem) => (
                    <Button
                        key={erpSystem.id}
                        color="highlight"
                        endIcon={<Icon name={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} />}
                        onClick={() => sendCostEstimation(erpSystem.id)}
                        disabled={disabled}
                    >
                        {translateText(1209)} {erpSystem.description}
                    </Button>
                ))}
        </>
    )
}
