import * as React from "react"
import { DefaultModal as Modal, Fade, styled, Box, Backdrop } from "@tm/components"
import { Icon } from "@tm/controls"

type Props = {
    content: React.ReactNode
    open: boolean
    onHide(hide: boolean): void
}

const StyledModalContent = styled(Box)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
})

const StyledCloseButton = styled(Box)({
    position: "absolute",
    top: "20%",
    left: "75%",
    height: "3em",
    width: "3em",
    cursor: "pointer",
    ".icon": {
        width: "100%",
        height: "100%",
        fill: "#fff",
        opacity: "0.7",
        "&:hover": {
            opacity: 1,
        },
    },
})

const EnlargeImage: React.FC<Props> = ({ content, open, onHide }) => {
    const handleClose = () => onHide(false)

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Box>
                <Fade in={open}>
                    <StyledModalContent>{content}</StyledModalContent>
                </Fade>
                <StyledCloseButton onClick={handleClose}>
                    <Icon size="xl" name="close" />
                </StyledCloseButton>
            </Box>
        </Modal>
    )
}

export default EnlargeImage
