import { FittingPosition, ShowVehicleRecordsRequest, ShowVehicleRecordsViewMode, TyreFilter, VehicleRecordSingleEventRequest } from "@tm/models"
import { Filters, ISummaryFilters } from "../../../summary/business"

export function createShowVehicleRecordsRequest(vehicleId: string, productGroupIds: number[]): ShowVehicleRecordsRequest | undefined {
    return {
        vehicleId,
        productGroupIds: productGroupIds.join(","),
        fittingSide: FittingPosition.None,
        viewMode: ShowVehicleRecordsViewMode.ShowRecordedValuesOnly
    }
}
export function createConfirmVehicleRecordsRequest(vehicleId: string, filter: TyreFilter, productGroupId: number, filters: Filters): VehicleRecordSingleEventRequest | undefined {    
    const filterId = filter.groupId ?? filters[filter.group as ISummaryFilters]?.first()?.groupId
    
    if(!filterId) {
        return
    }

    return {
        vehicleId,
        attribute: {
            id: filterId,
            value: filter.query,
            productGroupId,
            fittingSide: FittingPosition.None
        }
    }
}

