import { Dispatch } from "redux"
import { ArticleDetailsResponse } from "../../data/model/parts"

export type SelectionsState = {
    referencedVehicleCount?: number
    referencedBikesCount?: number
    referencedTrucksCount?: number
}

export type ComponentActionType = { type: "DETAILSHEAD_LOADED"; payload: ArticleDetailsResponse }

const DEFAULT_STATE: SelectionsState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): SelectionsState {
    switch (action.type) {
        case "DETAILSHEAD_LOADED": {
            return {
                ...state,
                referencedVehicleCount: action.payload.referencedVehicleCount,
                referencedBikesCount: action.payload.referencedBikesCount,
                referencedTrucksCount: action.payload.referencedTrucksCount,
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: Dispatch<ComponentActionType>) {
    switch (action.type) {
        case "DETAILSHEAD_LOADED": {
            dispatch(action)
            break
        }
        default:
            break
    }
}
