import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const vrmLookupDetailsOnly: BundleComponent = {
    name: "vrm-lookup-details-only",
    reduce,
    component,
}

export default vrmLookupDetailsOnly
