import { ExtendEFilterNames } from "../data/state/tyres-wheels"
import { TyresCritsResponse } from "../data/repositories"
import { splitSeasonFromTyreModel } from "./splitSeasonFromTyreModel"

export function enableFreeInputMode(crits: Partial<TyresCritsResponse>, path: string, filter: string) {
    switch (path) {
        case ExtendEFilterNames.width:
            return crits.width?.some((crit) => crit.value === filter)
        case ExtendEFilterNames.height:
            return crits.height?.some((crit) => crit.value === filter)
        case ExtendEFilterNames.inch:
            return crits.inch?.some((crit) => crit.value === filter)
        case ExtendEFilterNames.loadIndex:
            return crits.loadIndex?.some((crit) => crit.value === filter)
        case ExtendEFilterNames.speedIndex:
            return crits.speedIndex?.some((crit) => crit.value === filter)
        case ExtendEFilterNames.vehicleLabel:
            return true
        case ExtendEFilterNames.size:
            return true
        case ExtendEFilterNames.manufacturer:
            return crits.tyreManufacturer?.some((crit) => crit.value === filter)
        case ExtendEFilterNames.tyreModel:
            if (!crits.tyreModel) {
                return false
            }

            const tyreModelValues = splitSeasonFromTyreModel(crits.tyreModel)
            return tyreModelValues.some((value) => value.modelName === filter)
        case ExtendEFilterNames.season:
            return true
        default:
            return false
    }
}
