import { useQuery } from "react-query"
import { ECalcOrigin } from "@tm/models"
import { getCalculationData } from "../data/repositories/calculation"
import { CalculationDataRequest, InitialCalcData, TopProductGroup } from "../data/models/calculation"

export function useGetCalculationData(initialCalcData: InitialCalcData, productGroups: TopProductGroup[]) {
    const request: CalculationDataRequest = {
        origin: ECalcOrigin.Drivemotive,
        initialCalcData,
        productGroups,
    }

    const { data, isLoading } = useQuery([request.initialCalcData, request.productGroups], () => getCalculationData(request), {
        staleTime: 10 * 60 * 1000, // 10 Minutes
    })
    return { oeProductGroups: data?.productGroups, isLoading }
}
