import { useUser } from "@tm/context-distribution"
import { Card, DateField, Headline, Icon, Loader, Text, TextField } from "@tm/controls"
import { RepairShops } from "@tm/data"
import { useLocalization } from "@tm/localization"
import { ModelState, validateField } from "@tm/utils"
import { useState, useEffect } from "react"

import { Box, Button, Stack } from "@tm/components"
import { getBundleParams } from "../../utils"
import { MissingDataModel, VehicleData } from "./business/model"

type Props = {
    vehicleData?: VehicleData
    query: string
    onClose: () => void
}

export default function RequestVinComponent({ vehicleData, query, onClose }: Props) {
    const { translateText } = useLocalization()
    const { userSettings, userContext } = useUser() || {}

    const [missingDataModel, setMissingDataModel] = useState<MissingDataModel>({ vehicleData })
    const [loading, setLoading] = useState<boolean>(false)
    const [errorText, setErrorText] = useState<string>("")

    useEffect(() => {
        setModelData()
    }, [userSettings])

    function setModelData() {
        setMissingDataModel({
            customerData: {
                email: userSettings?.repairShop?.contactInfo?.email,
                name: `${userSettings?.repairShop?.contactPerson?.title} ${userSettings?.repairShop?.contactPerson?.firstName} ${userSettings?.repairShop?.contactPerson?.lastName}`.trim(),
                phone: userSettings?.repairShop?.contactInfo?.phone,
                customerNumber: userContext?.account?.customerNo,
                userName: userContext?.account?.username,
                traderId: userContext?.principal?.traderId,
            },
            vehicleData: {
                vin: query,
            },
        })
    }

    function handleChangeDataModel(model: MissingDataModel) {
        setMissingDataModel(model)
    }

    function handleInitialRegistrationChange(model: MissingDataModel, initialRegistration: Date) {
        setMissingDataModel({
            ...model,
            vehicleData: {
                ...model.vehicleData,
                initialRegistration,
            },
        })
    }

    function onAbort() {
        onClose()
    }

    function onComfirm() {
        const { vehicleData, customerData } = missingDataModel

        if (!vehicleData || !customerData) {
            return
        }

        const mailSubject = getEmailSubject()
        const mailBody = formatEmailBody()
        const emailRecipient = getBundleParams().missingVinDataEmailRecipient

        if (mailBody && emailRecipient) {
            setLoading(true)

            const request: RepairShops.SendEmailRequest = {
                emails: [emailRecipient],
                body: mailBody,
                subject: mailSubject,
            }

            RepairShops.sendEmail(request)
                .then(() => {
                    setLoading(false)
                    setErrorText(translateText(1705))
                })
                .catch(() => {
                    setLoading(false)
                    setErrorText(translateText(1706))
                })
        }
    }

    function getEmailSubject() {
        const { customerData } = missingDataModel
        let subject = `Fehlende Fahrzeugreferenz VIN Abfrage - ${new Date().toLocaleDateString()}`
        if (customerData) {
            subject += ` ${customerData.userName}` ? `${translateText(186)}: ${customerData.userName}` : ""
        }

        return subject
    }

    function formatEmailBody() {
        const { vehicleData, customerData, note } = missingDataModel

        if (!vehicleData || !customerData) {
            return
        }

        let mailBody = ["\n"]

        mailBody.push(
            `${translateText(99)}: ${vehicleData.modelSeries}`,
            vehicleData.tecDocTypeId && vehicleData.tecDocTypeId !== 0 ? `TecDoc: ${vehicleData.tecDocTypeId}` : "",
            vehicleData.registrationNo1 || vehicleData.registrationNo2
                ? `${translateText(880)}: ${[vehicleData.registrationNo1, vehicleData.registrationNo2].filter((x) => x).join("/")}`
                : "",
            vehicleData.vin ? `VIN: ${vehicleData.vin}` : "",
            vehicleData.engineCode ? `${translateText(105)}: ${vehicleData.engineCode}` : "",
            vehicleData.initialRegistration ? `${translateText(124)}: ${vehicleData.initialRegistration.toString()}` : "",
            "\n",
            customerData.traderId ? `${translateText(1385)}: ${customerData.traderId}` : "",
            customerData.customerNumber ? `${translateText(156)}: ${customerData.customerNumber}` : "",
            customerData.email ? `${translateText(109)}: ${customerData.email}` : "",
            customerData.name ? `${translateText(155)}: ${customerData.name}` : "",
            customerData.phone ? `${translateText(110)}: ${customerData.phone}` : "",
            "\n",
            note ? `${translateText(1700)}: ${note}` : ""
        )

        mailBody = mailBody.filter((item) => item !== "")
        return mailBody.join("\n")
    }

    function validateMissingDataModel(): ModelState {
        const { vehicleData, customerData } = missingDataModel

        return {
            "vehicleData.modelSeries": validateField(vehicleData?.modelSeries).min(3, translateText(289)).messages,
            "vehicleData.vin": validateField(vehicleData?.vin ? vehicleData.vin.toString() : "").min(17, translateText(289)).messages,
            "customerData.email": validateField(customerData?.email).email(translateText(290)).min(1, translateText(289)).messages,
            "customerData.phone": validateField(customerData?.phone).min(3, translateText(289)).messages,
            "customerData.name": validateField(customerData?.name).min(3, translateText(289)).messages,
        }
    }

    let valid = true
    const modelState = validateMissingDataModel()

    Object.keys(modelState).forEach((key) => {
        const value = modelState[key]
        if (value && value.length) {
            valid = false
        }
    })
    return (
        <div className="tk-vehicle__vin-request">
            <Headline className="tk-vehicle__vin-request__title">{translateText(1704)}</Headline>
            <div className="request-text-wrapper">
                <Text size="s">{translateText(1727)}</Text>
                <Icon name="dat-vin-data" size="xl" />
            </div>
            <Box maxHeight="65vh" sx={{ overflowY: "scroll" }}>
                <Card>
                    <Headline size="xs">{translateText(236)}</Headline>
                    <ul className="list">
                        <li className="list-item model">
                            <TextField
                                label={`${translateText(99)} (*)`}
                                floatingLabel
                                maxLength={150}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["vehicleData", "modelSeries"]}
                                onChange={handleChangeDataModel}
                            />
                        </li>
                        <li className="list-item manufacturer">
                            <TextField
                                label={translateText(71)}
                                floatingLabel
                                maxLength={50}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["vehicleData", "manufacturer"]}
                                onChange={handleChangeDataModel}
                            />
                        </li>
                        <li className="list-item">
                            <TextField
                                label={`${translateText(880)} (2.1)`}
                                floatingLabel
                                maxLength={15}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["vehicleData", "registrationNo1"]}
                            />
                        </li>
                        <li className="list-item">
                            <TextField
                                label={`${translateText(880)} (2.2)`}
                                floatingLabel
                                maxLength={15}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["vehicleData", "registrationNo2"]}
                            />
                        </li>
                        <li className="list-item">
                            <TextField
                                label={`${translateText(101)} (*)`}
                                floatingLabel
                                maxLength={17}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["vehicleData", "vin"]}
                                onChange={handleChangeDataModel}
                            />
                        </li>
                        <li className="list-item">
                            <TextField
                                label={translateText(105)}
                                floatingLabel
                                maxLength={10}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["vehicleData", "engineCode"]}
                                onChange={handleChangeDataModel}
                            />
                        </li>
                        <li className="list-item">
                            <DateField
                                label={translateText(124)}
                                floatingLabel
                                maxDate={new Date()}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["vehicleData", "initialRegistration"]}
                                useUtc
                                value={missingDataModel.vehicleData?.initialRegistration}
                                onChange={(d: Date) => handleInitialRegistrationChange(missingDataModel, d)}
                            />
                        </li>
                    </ul>
                </Card>
                <Card>
                    <Headline size="xs">{translateText(1703)}</Headline>
                    <ul className="list">
                        <li className="list-item">
                            <TextField
                                label={`${translateText(109)} (*)`}
                                floatingLabel
                                maxLength={150}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["customerData", "email"]}
                                onChange={handleChangeDataModel}
                            />
                        </li>
                        <li className="list-item">
                            <TextField
                                label={`${translateText(155)} (*)`}
                                floatingLabel
                                maxLength={150}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["customerData", "name"]}
                                onChange={handleChangeDataModel}
                            />
                        </li>
                        <li className="list-item">
                            <TextField
                                label={`${translateText(110)} (*)`}
                                floatingLabel
                                maxLength={20}
                                model={missingDataModel}
                                modelState={modelState}
                                path={["customerData", "phone"]}
                                onChange={handleChangeDataModel}
                            />
                        </li>
                    </ul>
                </Card>
                <Card>
                    <Headline size="xs">{translateText(1700)}</Headline>
                    <TextField
                        className="note"
                        model={missingDataModel}
                        modelState={modelState}
                        path={["note"]}
                        multiline
                        size="xl"
                        type="text"
                        onChange={handleChangeDataModel}
                    />
                </Card>
            </Box>
            <Stack direction="row" justifyContent="end" spacing={1} marginTop={1}>
                <Button disabled={loading} onClick={onAbort}>
                    {translateText(70)}
                </Button>
                <Button disabled={!valid || loading} onClick={onComfirm}>
                    {translateText(642)}
                </Button>
                <Stack>
                    <Loader visible={loading} />
                    <Text>{errorText}</Text>
                </Stack>
            </Stack>
        </div>
    )
}
