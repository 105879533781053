import { ArticleItem } from "@tm/components"
import { Article } from "@tm/models"
import { DefaultArticleItemStateProvider } from "./DefaultArticleItemStateProvider"

type DefaultArticleItemProps = {
    article: Article
}

export function DefaultArticleItem({ article }: DefaultArticleItemProps) {
    return (
        <DefaultArticleItemStateProvider article={article}>
            <ArticleItem variant="default" />
        </DefaultArticleItemStateProvider>
    )
}
