import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const addingTools: BundleComponent<unknown, typeof component> = {
    name: "adding-tools",
    reduce,
    component,
}

export default addingTools
