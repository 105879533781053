import { useMessage } from "@tm/utils"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useMicro } from "@tm/morpheus"
import { CustomArticle, IMicros } from "@tm/models"
import { Box, Loader, MuiCustomModal } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useGetCustomArticlesCategory } from "../../../data/hooks/useCustomArticlesCategories"
import { CustomArticleGenartList, CustomMvcArticle } from "../../../model"
import { useShowCustomArticleItems } from "../../../data/hooks/useShowCustomArticleItems"

export default function ReplaceArticleModal() {
    const [messageWindow, setMessageWindow] = useState<Window | undefined>(undefined)
    const [genartNr, setGenartNr] = useState<string>("")
    const [openModal, setOpenModal] = useState(false)
    const [apiCallEnabled, setApiCallEnabled] = useState(false)
    const [enableMvcMessageSend, setEnableMvcMessageSend] = useState(false)
    const [lubricantName, setLubricantName] = useState("")
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()

    const {
        categoryTextId: genartTextId,
        data: categoryTextIds,
        isError: customCategoryError,
    } = useGetCustomArticlesCategory(genartNr, apiCallEnabled)
    const request: Parameters<typeof useShowCustomArticleItems>[0] = {
        categoriesFilter: genartTextId,
    }

    const { data: { pages } = {}, isError, isLoading, isFetched, refetch: refetchCustomArticles } = useShowCustomArticleItems(request)
    const customArticles = useMemo(() => pages?.flatMap((x) => x.data) ?? [], [pages])

    const sendCustomArticleToMvc = useCallback(
        (customArticleList: CustomArticleGenartList[] | undefined) => {
            if (messageWindow) {
                messageWindow.postMessage({ customArticles: customArticleList }, "*")
                setEnableMvcMessageSend(false)
            }
        },
        [messageWindow]
    )

    function onOutsideClick() {
        setOpenModal(false)
    }

    function onConsumableSaved() {
        refetchCustomArticles()
    }

    function onConsumableReplace(selectedConsumable: CustomArticle) {
        const customMvcArticle = { ...selectedConsumable, lubricantName } as CustomMvcArticle
        sendCustomArticleToMvc([{ genart: +genartNr, customArticle: customMvcArticle }])
        onOutsideClick()
    }

    function getDefaultOrFirstArticle(articles: CustomArticle[] | undefined) {
        return articles?.find((customArticle) => customArticle.isDefaultForCategory) ?? articles?.[0]
    }

    function mapArticlesToList(articles: CustomArticle[] | undefined) {
        const articleListGroupArray: CustomArticleGenartList[] = []

        const grouppedArticles = articles?.reduce((acc, curr) => {
            const genartList = categoryTextIds?.find((x) => x.textId === curr.category)?.genArt
            genartList?.forEach((genart) => {
                if (!genart) {
                    return
                }

                const customArticleEntry = acc.get(genart)
                if (customArticleEntry) {
                    acc.set(genart, [...customArticleEntry, curr])
                } else {
                    acc.set(genart, [curr])
                }
            })
            return acc
        }, new Map<number, CustomArticle[]>())

        grouppedArticles?.forEach((groupArticle, genArt) => {
            articleListGroupArray.push({ genart: genArt, customArticle: getDefaultOrFirstArticle(groupArticle) })
        })

        return articleListGroupArray
    }

    useEffect(() => {
        if (isLoading || (!isFetched && isLoading === false) || !enableMvcMessageSend) {
            return
        }

        if (isError || customCategoryError) {
            sendCustomArticleToMvc(undefined)
            return
        }

        if (customArticles.length > 0 && categoryTextIds) {
            sendCustomArticleToMvc(mapArticlesToList(customArticles))
        }
        // This dep array should remain like this!!
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customArticles, isLoading, isError, isFetched, sendCustomArticleToMvc, customCategoryError, enableMvcMessageSend, categoryTextIds])

    useMessage((data, source) => {
        if (data.getCustomArticle) {
            const genartNrRequest = data.getCustomArticle.genartNr
            const openModalRequest = data.getCustomArticle.openModal
            setLubricantName(data.getCustomArticle.lubricantName)
            setEnableMvcMessageSend(!openModalRequest)
            setMessageWindow(source)
            setGenartNr(genartNrRequest)
            setOpenModal(openModalRequest)
            setApiCallEnabled(true)
        }
    })

    let content = (
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyItems="center">
            <Loader />
        </Box>
    )

    if (customArticles) {
        content = (
            <>
                {renderMicro("parts", "consumable-replace", {
                    title: translateText(genartTextId ?? 616),
                    category: genartTextId,
                    consumables: customArticles,
                    onConsumableReplace,
                    onConsumableSaved,
                })}
            </>
        )
    }

    if (openModal) {
        return (
            <MuiCustomModal open onOutsideClick={onOutsideClick} smallCloseButton showCloseButtonOnTheLeft>
                {content}
            </MuiCustomModal>
        )
    }

    return null
}
