import { useEffect, useState } from "react"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Article, ErpContainer, ErpInformation, RegisteredModels, WholesalerPart } from "@tm/models"
import { Container } from "@tm/nexus"
import { createErpInformationRequestItem, useDefaultErpSystem } from "@tm/utils"

export function useErpInfo(wholesalerPart: WholesalerPart | undefined) {
    const { erpSystemConfig } = useDefaultErpSystem()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const [state, setState] = useState<{ loading: boolean; erpInfo?: ErpInformation }>({ loading: false })

    useEffect(() => {
        if (!wholesalerPart?.wholesalerArticleNumber) {
            return
        }

        setState({ loading: true })

        const Erp: ErpContainer = Container.getInstance(RegisteredModels.ERP)

        Erp.action("getErpInfo")({
            item: createErpInformationRequestItem({
                traderArticleNo: wholesalerPart.wholesalerArticleNumber,
                supplier: {},
                productGroup: {},
                requestErpInfo: true,
            } as Article),
            distributorId: erpSystemConfig?.id,
            telesalesCustomerNo,
        }).then(
            (response) => {
                setState({ loading: false, erpInfo: response })
            },
            () => {
                setState({ loading: false })
            }
        )
    }, [wholesalerPart?.wholesalerArticleNumber, erpSystemConfig?.id, telesalesCustomerNo])

    return state
}
