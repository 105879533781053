import { EFuelTypes, FuelType, ShowBikeModelDetailsResponse, ShowCarModelDetailsResponse, ShowTruckModelDetailsResponse, Vehicle } from "@tm/models"

import { ArticleDetailsResponse, ExternalDocument, SupplierInfo } from "../../../data/model"

export const ECompletionType = {
    CreditNote: 1,
    SubsequentDelivery: 2,
    FreeReplacement: 3,
    ComponentBackToCustomer: 4,
    Scrapping: 5,
    InformCustomer: 6,
    CreditNoteNr: 7,
}

export type DocumentsState = {
    articleDetailsResponse?: ArticleDetailsResponse
    vehicle?: Vehicle
    carModel?: ShowCarModelDetailsResponse & ShowBikeModelDetailsResponse & ShowTruckModelDetailsResponse
    supplierInfo?: SupplierInfo
    model?: DocumentRequest
    showModal: boolean
    loading: boolean
    selectedTab?: AvailableTabs
    tabs: Tab[]
    articleQuantity?: number
    showVehicleModal?: boolean
    mainDocument?: ExternalDocument
    vehicleDataIsMandatory?: boolean
    fuelTypes: Array<FuelType>
}

export type DocumentRequest = {
    articleDetails: ArticleRequestForDocuments
    vehicleInfo: VehicleRequestForDocuments
    supplierInfo: SupplierRequestForDocuments
    repairShopInfo: RepairShopRequestForDocuments
    traderInfo: TraderRequestForDocuments
    customerInfo: CustomerRequestForDocuments

    additionalCostsInfo: {
        referenceNumber: string
        vehicleOwner: string
        additionalParts: AdditionalPart[]
        repairmentDay: string
    }

    batteryInfo: {
        consumers: Consumers
        hasStartStopTech?: number
        deliveryNoteInformation: ReceitInfo
        siteOfUse: number
        otherSiteOfUseDescription: string
        purposeOfUseStart: number
        purposeOfUseSupplyDrive: number
        durationOfDistance: number
        serviceMeasurement: ServiceMeasurement
        generalMeasurement: ServiceMeasurement
        batteryPlacement: number
        batteryDetectedVoltage: string
        batteryDetectedTime: string
        generatorDetectedVoltageWithLoad: string
        generatorDetectedVoltageWithoutLoad: string
        completionAtAcknowledgement: number
        completionAtRejection: number
        claimDescription: string
        enableDeinstallationCost: boolean
        deinstallationCost: string
    }

    oldBatteriesInfo: {
        oldBatteryTypeList: OldBatteryType[]
        producerNumber: string
    }

    returnsInfo: {
        returnType: number // goodsReturn: 1, oldPartReturn: 2
        goodsReturnReason: string
        isProductResalable: number
        additionalParts: AdditionalPart[]
        completionAtReturn: number
        creditNoteNumber: string // gutrschrift NR
        additionalChange?: boolean
    }

    complainInfo: ComplainInfoForDocuments
    receitInfo: ReceitInfo
    includeCustomerNr: boolean
    customerReference: string
    currentDate: string
    notificationOfDefects: string
    documents: ExternalDocument[]
    isTelesales: boolean
    telesalesCustomerNumber?: string
}

export type VehicleRequestForDocuments = {
    vehicleType?: string
    vinNr?: string
    fuelType?: string
    fuelTypeId?: EFuelTypes
    transmissionType?: string
    motorCode?: string
    motorCodes?: string[]
    ccm?: string
    kw?: string
    hp?: string
    keyNumber?: string
    keyNumbers?: string[]
    firstRegistrationYear?: string
    partsInstallationDate?: string
    installationMileage?: string
    partsDeinstallationDate?: string
    deinstallationMileage?: string
}

export type ArticleRequestForDocuments = {
    description: string
    manufacturerPartsCode: string
    dealerPartNumber: string
    deliveryDate: string
    quantity: string
    productGroupName: string
}

export type RepairShopRequestForDocuments = {
    administrator: string
    repairshopNr: string
    firstName: string
    lastName: string
    postalAddress: string
    companyName: string
    country: string
    state: string
    street: string
    city: string
    zip: string
}

export type TraderRequestForDocuments = {
    administrator: string
    postalAddress: string
    companyName: string
    street: string
    city: string
    zip: string
    country: string
    traderNr: string
    phone: string
    email: string
}

export type CustomerRequestForDocuments = {
    customerNo: string
    customerName: string
    customerNote: string
    email: string
    phone: string
    postalAddress: string
    companyName: string
    country: string
    state: string
    street: string
    city: string
    zip: string
}

export type SupplierRequestForDocuments = {
    postalAddress: string
    companyName: string
    country: string
    state: string
    street: string
    city: string
    zip: string
    administrator: string
}

export type LicensorData = {
    name?: string
    streetAndNumber?: string
    postalCodeAndCity?: string
    supportNo?: string
    telNo?: string
    faxNo?: string
    email?: string
}

type ServiceMeasurement = {
    dateOfMeasurement: string
    measuredValueInVolt: string
    measuredValueInEN: string
}

type Consumers = {
    airConditioning: string
    seatHeating: string
    auxiliaryHeating: string
    other: string
    otherConsumerDescription: string
}

export type ComplainInfoForDocuments = {
    claimDescription: string
    errorOccurance: number
    errorOccuranceDescription: string
    isAdditionalCosts: number
    additionalCostsDecription: string
    additionalCosts: number
    completionAtAcknowledgement: number
    completionAtRejection: number
}

export type ReceitInfo = {
    number: string
    date: string
}

export type AdditionalPart = {
    description: string
    quantity: number
    unit?: QuantityUnit
    netUnitPrice: number
    deliveryNoteNumber: string
}
export type QuantityUnit = "AW" | "LTR" | "STK" | "STD" | "MIN" | "G" | "KG" | "MTR"

export type AvailableTabs = "Adresses" | "Vehicle" | "Compliamt" | "CostStatement" | "Batterie" | "Return" | "OldBatteries"

export type Tab = {
    id: AvailableTabs
    name: string
    enabledIf?: string
    hintTitle?: string
    hintDescription?: string
}

export enum EDocumentIds {
    GVA = 1,
    ADR = 2,
    CostStatement = 3,
    Returns = 4,
    Batterie = 5,
    GVAGuide = 6,
}

export enum OtherCosumers {
    Other = 0,
    AirConditioning = 1,
    SeatHeating = 2,
    AuxiliaryHeating = 3,
}

export enum SiteOfUses {
    PassengerCar = 1,
    CommercialVehicle = 2,
    Engine = 4,
    Motorcycle = 8,
    Axle = 16,
    Universal = 32,
    DriversCab = 64,
    GearBox = 128,
    All = 256,
    LightCommercialVehicle = 512,
    Caravan = 1024,
}

export type OldBatteryType = {
    id: string
    quantity: number
    dangerPointsNr: number
    value: number
    tons: number
    tonsValue: number
}
