import { CisVoucherType } from "@tm/models"
import { Box } from "@tm/components"
import { useParams } from "react-router"
import { AllOrderListItem } from "../../../../data/model"
import { RowStackWithFlex, VerticalDivider } from "../../../_shared/StyledComponents"
import { CALCULATED_HEIGHT, COMPACT_WIDTH, FULL_WIDTH, VoucherRouteProps } from "../../component"
import PvOrdersTable from "../../../_shared/tables/PvOrdersTable"
import PvOrderDetails from "./PvOrderDetails"

type Props = {
    voucherTypeId: number
    vouchers?: Array<AllOrderListItem>
    onLoadNextPage(): void
}

export default function PvVouchersComponent(props: Props) {
    const { voucherTypeId, vouchers } = props
    const matchParams = useParams<VoucherRouteProps>()
    switch (voucherTypeId) {
        case CisVoucherType.AllOrders:
            return (
                <RowStackWithFlex divider={<VerticalDivider />}>
                    <Box width={matchParams.id ? COMPACT_WIDTH : FULL_WIDTH} height={CALCULATED_HEIGHT}>
                        <PvOrdersTable
                            orders={vouchers as Array<AllOrderListItem>}
                            selectedVoucherId={matchParams.id || undefined}
                            variant={matchParams.id ? "small" : "normal"}
                            onLoadNextPage={props.onLoadNextPage}
                        />
                    </Box>
                    {!!matchParams.id && (
                        <PvOrderDetails
                            orders={vouchers as Array<AllOrderListItem>}
                            selectedVoucherId={matchParams.id}
                            voucherTypeId={voucherTypeId}
                        />
                    )}
                </RowStackWithFlex>
            )
        default:
            return <></>
    }
}
