import { useQuery } from "react-query"
import { channel } from "@tm/models"
import { Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useHistory } from "react-router"
import { getExportVoucherInfos } from "../../data"
import { getBundleParams } from "../../utils"

type ExportVoucherButtonProps = {
    exportTarget?: string
}

export default function ExportVoucherButtonComponent({ exportTarget }: ExportVoucherButtonProps) {
    const { translate } = useLocalization()
    const { closeWorkTaskOnTransferToDms } = getBundleParams()
    const workTaskId = window.__NEXT_WORKTASKID__!
    const { data } = useQuery(`EXPORT-VOUCHER-STATE_${workTaskId}`, () => getExportVoucherInfos(workTaskId), { cacheTime: Infinity })
    const history = useHistory()

    function handleClick() {
        if (closeWorkTaskOnTransferToDms) {
            channel("GLOBAL").publish("INTERCEPTABLE/WORKTASK_DELETE", {
                workTaskId,
                callback: () => {
                    closeTab()
                },
            })
        }

        window.location.href = data!.uri!
    }

    function closeTab() {
        channel("WORKTASK", workTaskId).publish("WORKTASK/CLOSE", undefined)
        history.push("/")
    }

    if (!data?.uri) {
        return null
    }

    return (
        <Button onClick={handleClick}>
            {translate(1209)} {data.exportLabel || exportTarget || translate(1875)}
        </Button>
    )
}
