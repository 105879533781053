import { useState } from "react"
import { channel, ErpSystemConfig, Item, OrderResponse } from "@tm/models"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useDefaultErpSystem } from "@tm/utils"
import * as Data from "../../../../data"
import { SendWorkTaskOrderRequest } from "../../../../data/model"
import { bundleChannel } from "../../../../business"
import { createSendWorkTaskOrderRequest, mapDistributorGroups, openOrderSentUrl } from "./helper"
import { BasketOrderGroup } from "../../../../models"
import { useSetAdditionalOrderOption } from "../../../../hooks/useAdditionalOrderInformation"
import { useBasketMemo } from "../../../../hooks/useBasketMemo"
import { useInvalidateWorkTaskBasketQueries } from "../../../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

export function useSendWorkTaskOrder(workTaskId: string, telesalesCustomerNo: string | undefined, basketOrderGroups?: BasketOrderGroup[]) {
    const {
        erpConfig: { erpSystemConfigs },
        erpSystemConfig: defaultErpSystemConfig,
    } = useDefaultErpSystem()
    const { userSettings } = useUser() ?? {}
    const setAdditionalOrderOption = useSetAdditionalOrderOption()
    const workTask = useWorkTask()?.workTask
    const basketMemo = useBasketMemo(workTask)
    const { invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()

    const [sendingOrders, setSendingOrders] = useState(false)

    function getRequests(extSys: ErpSystemConfig | undefined, distributorId: number | undefined) {
        const wholesalerOrderConfirmation = userSettings?.orderOptions.repairShopResponse?.wholesalerOrderConfirmation || false
        const confirmationEmail = userSettings?.orderOptions.repairShopResponse?.orderConfirmationEmail
        const requests: Array<{ request: SendWorkTaskOrderRequest; erpSystemConfig: ErpSystemConfig; isPartialOrder: boolean }> = []
        const partItemVersions: Item[] = []
        basketOrderGroups?.forEach((orderOroup) => {
            orderOroup.basketParts.forEach((part) =>
                partItemVersions.push({
                    id: part.partItem.id,
                    version: part.partItem.version,
                })
            )
        })

        const basketOrderGroupsIncluded = basketOrderGroups?.filter((group) => group.hasPartsIncludedInOrder)
        let orderGroupsByDistributor = basketOrderGroupsIncluded?.groupBy(
            (group) => group.orderGroup.distributorId || defaultErpSystemConfig?.id || ""
        )

        if (!!distributorId && orderGroupsByDistributor) {
            orderGroupsByDistributor = { [distributorId]: orderGroupsByDistributor[distributorId] }
        }

        if (orderGroupsByDistributor) {
            Object.values(orderGroupsByDistributor).forEach((distriGroups) => {
                const { orderGroup, completeDeliveryTourStart } = distriGroups[0]
                const config =
                    extSys ?? (orderGroup.distributorId ? erpSystemConfigs?.find((c) => c.id === orderGroup.distributorId) : defaultErpSystemConfig)
                if (config) {
                    const { bonusPointTotals, orderOptions, itemErpInformation } = mapDistributorGroups(distriGroups)

                    requests.push({
                        request: createSendWorkTaskOrderRequest(
                            workTaskId,
                            orderOptions.length ? orderOptions : undefined,
                            itemErpInformation.length ? itemErpInformation : undefined,
                            partItemVersions,
                            wholesalerOrderConfirmation,
                            telesalesCustomerNo,
                            config.id,
                            orderGroup.distributorId,
                            confirmationEmail,
                            completeDeliveryTourStart,
                            bonusPointTotals !== 0 ? bonusPointTotals : undefined,
                            userSettings?.itemSorting
                        ),
                        erpSystemConfig: config,
                        isPartialOrder: distriGroups.some((group) => group.hasPartsExcludedFromOrder),
                    })
                }
            })
        }
        return requests
    }

    async function handleResponse(
        request: SendWorkTaskOrderRequest,
        result: OrderResponse,
        erpSystemConfig: ErpSystemConfig,
        isPartialOrder: boolean
    ) {
        channel("WORKTASK", result.workTaskId).publish("BASKET/ORDER_SENT", { result, erpSystemConfig, isPartialOrder })
        invalidatePartsRequests(result.workTaskId)

        if (result.allHaveSucceeded) {
            openOrderSentUrl(result.workTaskId)

            if (request.orderOptions) {
                setAdditionalOrderOption((prev) =>
                    prev.map((option) => {
                        if (option.distributorId === request.distributorId && option.workTaskId === workTaskId) {
                            return {
                                isDirty: undefined,
                                customerOrderComment: basketMemo.order,
                                customerOrderNumber: "",
                            }
                        }
                        return option
                    })
                )
            } else if (request.warehouseOrderOptions) {
                request.warehouseOrderOptions.forEach((warehouseOption) => {
                    setAdditionalOrderOption((prev) =>
                        prev.map((option) => {
                            if (option.warehouseId === warehouseOption.warehouseId && option.workTaskId === workTaskId) {
                                return {
                                    isDirty: undefined,
                                    customerOrderComment: basketMemo.order,
                                    customerOrderNumber: "",
                                }
                            }
                            return option
                        })
                    )
                })
            }
        }
    }

    function sendWorkTaskOrders(extSys: ErpSystemConfig | undefined, distributorId: number | undefined) {
        const requests = getRequests(extSys, distributorId)

        if (!requests.length) {
            return
        }
        // TODO Basket V2: replace these messages with setSendingOrders
        channel("WORKTASK", workTaskId).publish("BASKET/ORDER_SENDING", {})
        bundleChannel().publish("ANY_ORDERS_SENDING", {})

        setSendingOrders(true)
        Promise.all(
            requests.map(({ request, erpSystemConfig, isPartialOrder }) =>
                Data.sendWorkTaskOrder(request).then(
                    async (result) => {
                        if (result) {
                            handleResponse(request, result, erpSystemConfig, isPartialOrder)
                        }
                    },
                    (error) => {
                        channel("WORKTASK", workTaskId).publish("BASKET/ORDER_SENT_ERROR", { technicalErrorText: error.message, erpSystemConfig })
                        invalidatePartsRequests(workTaskId)
                    }
                )
            )
        ).finally(() => {
            setSendingOrders(false)
            // TODO: replace these messages with setSendingOrders
            bundleChannel().publish("ALL_ORDERS_SENT", {})
        })
    }

    return { sendWorkTaskOrders, sendingOrders }
}
