import { styled, Icon, Stack } from "@tm/components"

export const RowStack = styled(Stack)({
    alignItems: "center",
})
RowStack.defaultProps = { direction: "row", spacing: 0.5 }

export const InfoIcon = styled(Icon)({
    width: "18px",
    height: "18px",
})
InfoIcon.defaultProps = { name: "info" }
