import { useRef, useState, useLayoutEffect, useEffect, useMemo } from "react"

type BoxSize = [number, number]

export function useElementSize(): [React.RefObject<HTMLElement>, BoxSize] {
    const ref = useRef<HTMLElement>(null)
    const [size, setSize] = useState<BoxSize>([0, 0])

    const observer = useMemo(
        () =>
            new ResizeObserver((entries: ResizeObserverEntry[]) => {
                const entry = entries.length === 1 ? entries[0] : null
                if (!entry) {
                    return
                }
                setSize([entry.target.clientWidth, entry.target.clientHeight])
            }),
        []
    )

    useEffect(() => {
        return () => observer.disconnect()
    }, [observer])

    useLayoutEffect(() => {
        if (ref.current) {
            observer.observe(ref.current)
        }
        return () => {
            if (ref.current) {
                observer.unobserve(ref.current)
            }
        }
    }, [observer])

    return [ref, size]
}
