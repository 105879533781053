import { BundleMicro } from "@tm/morpheus"
import component from "../../components/widget/component"
import { reduce } from "../../components/widget/business"

const crmWidget: BundleMicro = {
    name: "crm-widget",
    component,
    reduce,
}

export default crmWidget
