import { useLocalization } from "@tm/localization"
import { createQueryString, renderRoute } from "@tm/utils"
import { CisVoucherType, OrderByArticleListItem } from "@tm/models"
import { CellContentPosition, TableCellData, Icon, LinkButton, TableColumnData } from "@tm/components"
import { useParams } from "react-router"
import { NoResultHint } from "../../../_shared/NoResultHint"
import { getBundleParams } from "../../../../utils"
import { StyledTable } from "../../../_shared/StyledComponents"
import DateCell from "../../../_shared/cells/DateCell"
import { VoucherRouteProps } from "../../component"

type Props = {
    orders: Array<OrderByArticleListItem>
}

export default function StgOrdersByArticleTableComponent({ orders }: Props) {
    const { translateText } = useLocalization()
    const { cisVoucherUrl } = getBundleParams()
    const matchParams = useParams<VoucherRouteProps>()
    const selectedVoucherId = matchParams.id ? decodeURIComponent(matchParams.id) : undefined
    const selectedVoucherSubId = matchParams.subId

    function renderOrderNumber(order: OrderByArticleListItem) {
        return <>{order.orderNumber + (order.orderSubNumber ? ` - ${order.orderSubNumber}` : "")}</>
    }

    function renderDetailsButton(order: OrderByArticleListItem) {
        let url = renderRoute(cisVoucherUrl, {
            ...matchParams,
            voucherTypeId: order.positionStatusVoucherTypeId,
            id: order.orderNumber,
            subId: order.orderSubNumber ? order.orderSubNumber : 0,
        })
        url += createQueryString({ singleSearch: true })
        if (order.positionStatusVoucherTypeId === CisVoucherType.Undefined) {
            return <></>
        }

        return <LinkButton to={url} title={translateText(597)} startIcon={<Icon name="file-details" />} />
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(87) },
            { header: translateText(25) },
            { header: translateText(700) },
            { header: translateText(776) },
            { header: translateText(782), alignContent: CellContentPosition.center },
            { header: translateText(784) },
            { alignContent: CellContentPosition.right },
        ]

        return columns
    }

    function getCells(order: OrderByArticleListItem) {
        const cellData: TableCellData[] = [
            { displayValue: order.wholesalerArticleNumber, id: "order_0" },
            { displayValue: order.itemDescription, id: "order_1" },
            { displayValue: renderOrderNumber(order), id: "order_2" },
            { displayValue: <DateCell value={order.orderDate} mode="dateDashTime" />, id: "order_3" },
            { displayValue: order.orderQuantity, id: "order_4" },
            { displayValue: order.positionStatus, id: "order_5" },
            { displayValue: renderDetailsButton(order), id: "order_6" },
        ]
        return cellData
    }

    const displayData = orders?.map((order, index) => ({
        cells: getCells(order),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === order.orderNumber && selectedVoucherSubId === order.orderSubNumber,
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} headerBackground="paper" />
}
