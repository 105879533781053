import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers"
import { reduce } from "./business"
import component from "./component"

const DAT: BundleComponent = {
    name: "dat-frame",
    reduce,
    component: withStoreProvider(component as any),
}

export default DAT
