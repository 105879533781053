import { BundleActionTypes } from "../business"
import { MainActionsType } from "../components/main/business"
import { DEFAULT_STATE } from "../components/manager/business"
import { ModuleManagerState } from "../components/manager/business/model"

export type ManagerActionTypes =
    | BundleActionTypes
    | { type: "CHECK_CUSTOMER_LOADING" }
    | { type: "CHECK_CUSTOMER"; payload: { userIsRegistered: boolean; agbUrl?: string; userMustEnterCredentials: boolean } }
    | { type: "CHECK_CUSTOMER_ERROR" }
    | { type: "ACCEPT_CONDITIONS" }
    | { type: "SET_CUSTOMER_REGISTRATION_STATUS"; payload: boolean }

export const reduce = (state = { ...DEFAULT_STATE }, action: MainActionsType): ModuleManagerState => {
    switch (action.type) {
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
            }
        }
        case "CUSTOMER_SET": {
            return {
                ...state,
                customer: action.payload,
            }
        }
        case "CHECK_CUSTOMER_LOADING": {
            return {
                ...DEFAULT_STATE,
                checkCustomerLoading: true,
                vehicle: state.vehicle,
                customer: state.customer,
            }
        }
        case "CHECK_CUSTOMER": {
            const { agbUrl, userIsRegistered, userMustEnterCredentials } = action.payload

            return {
                ...state,
                checkCustomerLoading: false,
                userIsRegistered,
                agbUrl,
                showPDF: !userIsRegistered && !userMustEnterCredentials,
                userMustEnterCredentials,
            }
        }
        case "CHECK_CUSTOMER_ERROR": {
            return {
                ...DEFAULT_STATE,
                vehicle: state.vehicle,
                customer: state.customer,
                checkCustomerError: true,
            }
        }
        case "INIT_NEW_CALCULATION":
        case "LOAD_NEW_DOCUMENT": {
            return {
                ...state,
                contractId: action.payload.contractId,
            }
        }
        case "SET_CUSTOMER_REGISTRATION_STATUS": {
            return {
                ...state,
                userIsRegistered: action.payload,
                showPDF: !action.payload,
                checkCustomerError: undefined,
            }
        }
        case "INIT_MODULE": {
            const { contractId } = action.payload

            return {
                ...state,
                contractId,
            }
        }
        default:
            return state
    }
}
