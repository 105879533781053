import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./wrapper"

const userSettings: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "user-settings",
    reduce,
    component,
}

export default userSettings
