import { Button, Modal } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { UserModuleType } from "@tm/models"
import { useMemo, useState } from "react"

export function MarginTool() {
    const { translate, translateText, languageId } = useLocalization()
    const { userContext } = useUser()

    const [modalOpen, setModalOpen] = useState(false)

    const marginToolUrl = useMemo(() => {
        const module = userContext.modules?.find((x) => x.type == UserModuleType.MarginTool)
        const url = module?.parameters?.find((x) => x.key.toLowerCase() === "url")?.value
        return url?.replace("{#language}", languageId)
    }, [userContext, languageId])

    if (!marginToolUrl) {
        return null
    }

    return (
        <>
            <Button onClick={() => setModalOpen(true)}>{translate(13463)}</Button>
            <Modal open={modalOpen} onOutsideClick={() => setModalOpen(false)}>
                <iframe src={marginToolUrl} width="100%" height="100%" title={translateText(13463)} />
            </Modal>
        </>
    )
}
