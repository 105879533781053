import { useEffect } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { channel, RegisteredModels, CarModelDetailsResponse, VehicleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { loadImportedVehicleDetails, deleteImportedVehicleDetails } from "../../business"
import { mapModelDetailsToVehicle } from "../../data/mapper"

export default function VehicleManager() {
    const workTask = useWorkTask()

    useEffect(() => {
        if (workTask?.workTaskId) {
            loadImportedVehicleDetails(workTask?.workTaskId)
        }
    }, [workTask?.workTaskId])

    useEffect(() => {
        if (!workTask) {
            return
        }

        const unsub = channel("WORKTASK").subscribe("VEHICLE/SET_VEHICLE_TECDOC_NUMBER", (request) => {
            if (workTask.workTask?.vehicle?.tecDocTypeId !== request.tecDocNumber) {
                const container = Container.getInstance<CarModelDetailsResponse>(RegisteredModels.Vehicle_ModelDetails)

                container
                    .subscribe({ modelId: request.tecDocNumber }, request.vehicleType)
                    .load()
                    .then((response) => {
                        if (response.modelDetails) {
                            workTask.attachToWorkTask({
                                vehicle: mapModelDetailsToVehicle(response, response.modelDetails, request.vehicleType || VehicleType.PassengerCar),
                            })
                            deleteImportedVehicleDetails()
                        }
                    })
            }
        })

        return unsub
    }, [workTask?.workTask?.vehicle?.tecDocTypeId, workTask?.attachToWorkTask])

    return null
}
