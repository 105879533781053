import { PreyssCar } from "../../model"



export function mapStatics2CarsJsonToPreyssCars(response: any[]): PreyssCar[] {
    return response.map(x => ({
        ...x.car,
        imgid: x.imgid,
    }))

}