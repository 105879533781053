import { createQueryString, getStoredAuthorization } from "@tm/utils"
import { useWorkTask, useUser, UserSettings } from "@tm/context-distribution"
import { IFrame } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { WorkCategory } from "@tm/models"

type Props = {
    url: string
}

export default function FastCheckComponent({ url }: Props) {
    const { languageId } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const { userSettings } = useUser() ?? {}

    function getLabourRate() {
        let hourlyRate

        if (userSettings?.hourlyRates) {
            hourlyRate = userSettings.hourlyRates.find((rate) => rate.category === WorkCategory.WorkshopWork)

            if (!hourlyRate) {
                hourlyRate = userSettings.hourlyRates.find((rate) => !rate.category)
            }
        }

        return hourlyRate?.hourlyRate
    }

    function getUrl() {
        const authorization = getStoredAuthorization()
        if (!authorization) {
            return
        }

        const params: any = {
            token: authorization.credentials,
            lang: languageId,
        }

        if (workTask) {
            const { id, vehicle, customer } = workTask
            params.workTaskId = id

            if (vehicle) {
                params.vehicleId = vehicle.id
            }

            if (customer) {
                params.customerId = customer.id
            }
        }

        params.hourlyRate = getLabourRate()

        return url + createQueryString(params)
    }

    const newUrl = getUrl()
    if (!newUrl) {
        return null
    }

    return (
        <div className="bundle-misc iframe-module">
            <IFrame id="iframe_fast-check" className="iframe_fast-check" url={newUrl} allowFullscreen allow="fullscreen; camera" contextDependent />
        </div>
    )
}
