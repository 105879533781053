import { useUser, useWorkTask } from "@tm/context-distribution"
import { Toolbar, WarningPrompt } from "@tm/controls"
import { Icon, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Customer, ESubModules, UserModuleType, Vehicle } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { equals, getCostPerCalculation, initSelector, uniqueId } from "@tm/utils"
import { useRef } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { HistoryEntry } from "../../../data/models"
import { MainState } from "../../main"
import {
    customerFieldsSelector,
    customerSelector,
    MainActions,
    modelStateSelector,
    vehicleFieldsSelector,
    vehicleModelsSelector,
    vehicleSelector,
} from "../../main/business"

const invalidSelector = createSelector(modelStateSelector, (modelState: any) => Object.values(modelState).some((val: any) => val?.length))

const vehicleModelState = createSelector(modelStateSelector, (modelState) => {
    const { initialRegistration, mileage, plateNumber, vin } = modelState as any
    return { initialRegistration, mileage, plateNumber, vin }
})
const selector = initSelector(
    vehicleSelector,
    customerSelector,
    vehicleModelsSelector,
    customerFieldsSelector,
    vehicleFieldsSelector,
    (s) => s.extern.newCalculationLoading
)

export default function RenderButton() {
    const { translateText } = useLocalization()
    const workTask = useWorkTask()

    const modelState = useSelector((s: MainState) => vehicleModelState(s, translateText))
    const actions = useActions(MainActions)

    const [vehicle, customer, vehicleModels, customerInfoFields, vehicleInfoFields, newCalculationLoading] = useSelector(selector)
    const { models, selectedVehicleModel } = vehicleModels

    const showBtn = vehicle && models?.length
    const isInvalid = useSelector((s: MainState) => invalidSelector(s, translateText))

    const warningRef = useRef<WarningPrompt>(null)
    const user = useUser()

    const handleNewCalculation = () => {
        const invalid = Object.values(modelState).some((val) => val.length)

        if (!user?.userContext || !user?.userContext.modules || invalid) {
            return
        }

        const calculationCost = getCostPerCalculation(UserModuleType.TMDamageCalculationEurotax, ESubModules.ShowEREClick, user?.userContext)

        if (calculationCost) {
            warningRef?.current?.show()
        } else {
            handleOpenExternalTool()
        }
    }

    const handleOpenExternalTool = (historyEntry?: HistoryEntry) => {
        const invalid = !historyEntry && Object.values(modelState).some((val) => val.length)

        if (!user?.userContext || !user?.userContext.modules || invalid) {
            return
        }
        const module = user!.userContext.modules.find((x) => x.type === 31)

        if (!module) {
            return
        }

        if (!historyEntry) {
            updateWorkTaskInfo()
        }

        actions.initExtern(module.moduleSubId, historyEntry)
    }
    const updateWorkTaskInfo = () => {
        if (!vehicle) {
            return
        }

        const { initialRegistration, vin, plateNumber, mileage } = vehicleInfoFields
        const { firstName, lastName } = customerInfoFields

        const updatedVehicle: Vehicle = {
            ...vehicle,
            ...(initialRegistration !== vehicle.initialRegistration && { initialRegistration }),
            ...(vin !== vehicle.vin && { vin }),
            ...(plateNumber !== vehicle.plateId && { plateId: plateNumber }),
            ...(mileage !== vehicle.mileAge && { mileAge: mileage }),
        }
        const shouldUpdateVehicle = !equals(vehicle, updatedVehicle)

        const fieldsHaveValueNewCustomer = !customer && (!!firstName?.length || !!lastName?.length)
        const fieldsAreModified = customer && (customer.firstName !== firstName || customer.lastName !== lastName)
        const shouldUpdateCustomer: boolean = !!fieldsHaveValueNewCustomer || !!fieldsAreModified

        const updatedCustomer: Customer = {
            id: uniqueId(),
            ...customer,
            firstName,
            lastName,
        }

        if (shouldUpdateCustomer || shouldUpdateVehicle) {
            workTask?.attachToWorkTask({
                ...(shouldUpdateVehicle && { vehicle: updatedVehicle }),
                ...(shouldUpdateCustomer && { customer: updatedCustomer }),
            })
        }
    }

    return (
        <>
            {showBtn && (
                <Toolbar className="take-damage" title={translateText(12884)}>
                    <Button
                        color="highlight"
                        startIcon={<Icon name="damage-calculation" />}
                        disabled={!selectedVehicleModel || isInvalid || newCalculationLoading}
                        onClick={() => handleNewCalculation()}
                    >
                        {translateText(1461)}
                    </Button>
                </Toolbar>
            )}
            <WarningPrompt
                ref={warningRef}
                confirmationButtonText={translateText(1628)}
                onConfirm={() => handleOpenExternalTool()}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onCancel={() => {}}
                text={translateText(1627)}
            />
        </>
    )
}
