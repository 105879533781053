import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Icon, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { DisplayOptionKeys, PrintOptions } from "../../../data/models"
import { getTextFromPrintOption } from "../../../helpers"

type Props = {
    printOptions: PrintOptions
}

export function PrintOptionsPreview({ printOptions }: Props) {
    const { translateText } = useLocalization()

    const { inspectionTimes, remarks, images, tyres, nextMaintenance, showDefects } = printOptions

    if (inspectionTimes || remarks || images || tyres || nextMaintenance || showDefects) {
        return (
            <Box>
                <StyledAccordion defaultExpanded>
                    <StyledAccordionSummary expandIcon={<Icon name="down" />}>
                        <StyledBox>
                            <Typography variant="body1" fontWeight="medium">
                                {translateText(222)}
                            </Typography>
                            <Divider light sx={{ transform: "translateY(-50%)" }} flexItem />
                        </StyledBox>
                    </StyledAccordionSummary>
                    <AccordionDetails>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {inspectionTimes && (
                                <StyledGridItem item xs={6}>
                                    <Icon name="visible" />
                                    <StyledText>{getTextFromPrintOption(DisplayOptionKeys.InspectionTimes, translateText)}</StyledText>
                                </StyledGridItem>
                            )}
                            {remarks && (
                                <StyledGridItem item xs={6}>
                                    <Icon name="visible" />
                                    <StyledText>{getTextFromPrintOption(DisplayOptionKeys.Remarks, translateText)}</StyledText>
                                </StyledGridItem>
                            )}
                            {images && (
                                <StyledGridItem item xs={6}>
                                    <Icon name="visible" />
                                    <StyledText>{getTextFromPrintOption(DisplayOptionKeys.Images, translateText)}</StyledText>
                                </StyledGridItem>
                            )}
                            {tyres && (
                                <StyledGridItem item xs={6}>
                                    <Icon name="visible" />
                                    <StyledText>{getTextFromPrintOption(DisplayOptionKeys.Tyres, translateText)}</StyledText>
                                </StyledGridItem>
                            )}
                            {nextMaintenance && (
                                <StyledGridItem item xs={6}>
                                    <Icon name="visible" />
                                    <StyledText>{getTextFromPrintOption(DisplayOptionKeys.NextMaintenance, translateText)}</StyledText>
                                </StyledGridItem>
                            )}
                            {showDefects && (
                                <StyledGridItem item xs={6}>
                                    <Icon name="visible" />
                                    <StyledText>{getTextFromPrintOption(DisplayOptionKeys.ShowDefects, translateText)}</StyledText>
                                </StyledGridItem>
                            )}
                        </Grid>
                    </AccordionDetails>
                </StyledAccordion>

                <Divider light />
            </Box>
        )
    }

    return null
}

const StyledAccordion = styled(Accordion)({
    boxShadow: "unset",
    "&::before": {
        backgroundColor: "unset",
    },
})

const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
    "& .MuiAccordionSummary-content.Mui-expanded": {
        marginLeft: theme.spacing(1),
    },
}))

const StyledGridItem = styled(Grid)({
    display: "flex",
    alignItems: "center",
    gap: "0.5em",
})

const StyledBox = styled(Box)({
    width: "100%",
    display: "grid",
    gridTemplateColumns: "auto 1fr",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5em",
})

const StyledText = styled(Typography)({
    variant: "body2",
    fontWeight: "regular",
})
