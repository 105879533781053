import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { OrderOptions } from "@tm/models"
import { Box, FormControlLabel, RadioGroup, styled, Typography } from "@tm/components"
import { SelectedOrderOptions } from "../../../../../../data/model"
import { IconWithMargin, RadioButtonSmallWithMargin } from "../../../../order-options/components/StyledComponents"

const TypographyOption = styled(Typography)({
    marginRight: "2rem",
    marginLeft: ".5rem",
    fontWeight: "bold",
})
TypographyOption.defaultProps = { variant: "body1" }

const BoxOption = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginRight: "10rem",
})

const RadioGroupOption = styled(RadioGroup)({
    display: "flex",
    flexDirection: "row",
})

type Props = {
    workTaskId: string
    orderOptions: OrderOptions
    selectedOrderOptions?: SelectedOrderOptions
    onChangeOrderOptions(orderOptions: SelectedOrderOptions): void
    loading: boolean
}

export default function PartsOrderOptionsComponent({ orderOptions, workTaskId, selectedOrderOptions, onChangeOrderOptions, loading }: Props) {
    const { translateText, currency } = useLocalization()
    const [selectedShipmentId, setSelectedShipmentId] = useState<string | undefined>(selectedOrderOptions?.selectedShipmentMode?.id)
    const [selectedBillingAddressId, setSelectedBillingAddressId] = useState<string | undefined>(selectedOrderOptions?.selectedBillingAddress?.id)
    const { shipmentModes, billingAddresses } = orderOptions

    function handleShipmentChange(_: unknown, id: string) {
        setSelectedShipmentId(id)
        if (shipmentModes?.shipmentModes.length) {
            const firstShipmentMatch = shipmentModes.shipmentModes.find((shipmentMode) => shipmentMode.id === id)
            const selectedBillingAddress = billingAddresses?.addresses.find((address) => address.isSelected)

            if (firstShipmentMatch && workTaskId) {
                onChangeOrderOptions({
                    workTaskId,
                    selectedBillingAddress,
                    selectedShipmentMode: firstShipmentMatch,
                    wholesalerOrderConfirmation: false,
                })
            }
        }
    }

    function handleBillingAddressChange(_: unknown, id: string) {
        setSelectedBillingAddressId(id)

        if (billingAddresses?.addresses.length) {
            const firstBillingAddressMatch = billingAddresses.addresses.find((address) => address.id === id)
            const selectedShipmentMode = shipmentModes?.shipmentModes.find((shipmentMode) => shipmentMode.isSelected)

            if (firstBillingAddressMatch && workTaskId) {
                onChangeOrderOptions({
                    workTaskId,
                    selectedBillingAddress: firstBillingAddressMatch,
                    selectedShipmentMode,
                    wholesalerOrderConfirmation: false,
                })
            }
        }
    }

    function renderShipmentModes() {
        return (
            <BoxOption>
                {billingAddresses?.isNotVisible === false && <IconWithMargin name="truck" />}
                <TypographyOption>{translateText(765)}</TypographyOption>
                <RadioGroupOption onChange={handleShipmentChange}>
                    {shipmentModes?.shipmentModes.map((shipmentMode) => {
                        const checked = selectedOrderOptions ? selectedShipmentId === shipmentMode.id : shipmentMode.isSelected

                        return (
                            <Box key={shipmentMode.id} display="flex" alignItems="center" pr="24px">
                                <FormControlLabel
                                    sx={{ marginRight: "5px" }}
                                    value={shipmentMode.id}
                                    control={<RadioButtonSmallWithMargin checked={checked || false} disabled={loading} />}
                                    label={shipmentMode.description}
                                />
                                {shipmentMode.costs && (
                                    <Typography variant="body2">
                                        {`${translateText(3138)} ${currency(shipmentMode.costs.value, shipmentMode.costs.currencyCode)}`}
                                    </Typography>
                                )}
                            </Box>
                        )
                    })}
                </RadioGroupOption>
            </BoxOption>
        )
    }

    function renderBillingAddress() {
        return (
            <BoxOption>
                {shipmentModes?.isNotVisible === false && <IconWithMargin name="order-options" />}
                <TypographyOption>{translateText(12584)}</TypographyOption>
                <RadioGroupOption onChange={handleBillingAddressChange}>
                    {billingAddresses?.addresses.map((billingAddress) => {
                        const checked = selectedOrderOptions ? selectedBillingAddressId === billingAddress.id : billingAddress.isSelected

                        return (
                            <Box key={billingAddress.id} display="flex" alignItems="center">
                                <FormControlLabel
                                    value={billingAddress.id}
                                    control={<RadioButtonSmallWithMargin checked={checked || false} />}
                                    label={billingAddress.description}
                                />
                            </Box>
                        )
                    })}
                </RadioGroupOption>
            </BoxOption>
        )
    }

    return (
        <Box display="flex" flexDirection="column">
            {shipmentModes && !shipmentModes.isNotVisible && renderShipmentModes()}
            {billingAddresses && !billingAddresses.isNotVisible && renderBillingAddress()}
        </Box>
    )
}
