import { CarLookupError, CarModel, RegistrationNoDetails, RegistrationNoType, VehicleShortInfo, VehicleType } from "@tm/models"
import { ajax, getStoredAuthorization, mapVehicleShortInfo } from "@tm/utils"

import { getBundleParams } from "../../utils"
import { AdditionalInfo } from "../model/vrm-lookup"

function getVrmLookupServiceUrl() {
    return getBundleParams().vrmLookupServiceUrl
}

function notEmpty<T>(value: T | null | undefined): value is T {
    return !!value
}

export type SetSelectedVehicleRequest = {
    query: string
    lookupTypeId: RegistrationNoType
    tecDocTypeId: number
}

export function setSelectedVehicle(request: SetSelectedVehicleRequest): Promise<void> {
    return ajax({
        method: "POST",
        url: `${getVrmLookupServiceUrl()}/SetSelectedVehicle`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}

export type VrmLookupRequest = {
    query: string
    lookupTypeId: RegistrationNoType
    forceUpdateRegistrationNoDetails?: boolean
    vehicleType?: VehicleType
}

export type VrmLookupResponse = {
    carModels: Array<CarModel>
    truckModels: Array<CarModel>
    customerVehicles: Array<VehicleShortInfo>
    // error: CarLookupError // will be handled by rejecting the promise
}

export function showVehiclesByVin(_request: VrmLookupRequest): Promise<VrmLookupResponse> {
    type Request = {
        vin: string
        lookupTypeId: RegistrationNoType
        forceUpdateRegistrationNoDetails?: boolean
    }

    type Response = {
        vehicles?: Array<CarModel>
        customerVehicles?: Array<VehicleShortInfo>
        error?: CarLookupError
    }

    const request: Request = {
        vin: _request.query,
        lookupTypeId: _request.lookupTypeId,
        forceUpdateRegistrationNoDetails: _request.forceUpdateRegistrationNoDetails,
    }

    return ajax<Response>({
        url: `${getVrmLookupServiceUrl()}/ShowVehiclesByVin`,
        authorization: getStoredAuthorization(),
        body: request,
    }).then((data) => {
        if (data?.error) {
            throw data.error
        }

        return {
            carModels: data?.vehicles?.map(mapVrmLookupVehicle).filter(notEmpty) ?? [],
            truckModels: [],
            customerVehicles: data?.customerVehicles?.map(mapVehicleShortInfo).filter(notEmpty) ?? [],
        }
    })
}

export function showVehiclesByRegistrationNo(_request: VrmLookupRequest): Promise<VrmLookupResponse> {
    type Request = {
        registrationNo: string
        lookupTypeId: RegistrationNoType
        forceUpdateRegistrationNoDetails?: boolean
        vehicleType?: VehicleType
    }

    type Response = {
        models?: Array<CarModel>
        truckModels?: Array<CarModel>
        customerVehicles?: Array<VehicleShortInfo>
        error?: CarLookupError
    }

    const request: Request = {
        registrationNo: _request.query,
        lookupTypeId: _request.lookupTypeId,
        forceUpdateRegistrationNoDetails: _request.forceUpdateRegistrationNoDetails,
        vehicleType: _request.vehicleType,
    }

    return ajax<Response>({
        url: `${getVrmLookupServiceUrl()}/ShowVehiclesByRegistrationNo`,
        authorization: getStoredAuthorization(),
        body: request,
    }).then((data) => {
        if (data?.error) {
            throw data.error
        }

        return {
            carModels: data?.models?.map(mapVrmLookupVehicle).filter(notEmpty) ?? [],
            truckModels: data?.truckModels?.map(mapVrmLookupVehicle).filter(notEmpty) ?? [],
            customerVehicles: data?.customerVehicles?.map(mapVehicleShortInfo).filter(notEmpty) ?? [],
        }
    })
}

function mapVrmLookupVehicle(data: any): CarModel | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        engineCodes: data.engineCodes ?? [],
        registrationNoDetails: mapVrmLookupRegNoDetails(data.registrationNoDetails),
    }
}

function mapVrmLookupRegNoDetails(data: any): RegistrationNoDetails | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        additionalInfos: data.additionalInfos?.map(mapVrmLookupAdditionalInfo).filter(notEmpty) ?? [],
        colors: data.colors ?? [],
        engineCodes: data.engineCodes ?? [],
        equipments: data.equipments ?? [],
        manufacturingDate: data.manufacturingDate ? new Date(data.manufacturingDate) : undefined,
        initialRegistration: data.initialRegistration ? new Date(data.initialRegistration) : undefined,
    }
}

function mapVrmLookupAdditionalInfo(data: any): AdditionalInfo | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        values: data.values?.map(mapVrmLookupAdditionalInfo).filter(notEmpty) ?? [],
    }
}
