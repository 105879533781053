import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { RegisteredModels, ShowOrderStatusRequest, ShowOrderStatusResponse } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

export function useVoucherErpState(request: Omit<ShowOrderStatusRequest, "telesalesCustomerNo">) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const { data, isLoading } = useQuery(
        ["vouchers__ERP__Status", request],
        () =>
            Container.getInstance<ShowOrderStatusResponse>(RegisteredModels.ERP_OrderStatus)
                .subscribe({ ...request, telesalesCustomerNo })
                .load(),
        {
            enabled: enableServiceCalls && !!request.orderIdentifier.length,
            staleTime: 30 * 1000, // 30 seconds
        }
    )

    return { erpStates: data, erpStateLoading: isLoading }
}
