import { isDocumentRelated } from "."
import { ExternalDocument } from "../../../../data/model"
import { AvailableTabs, EDocumentIds, Tab } from "../model"

export const generateAvailableTabs = (documents: ExternalDocument[], translateText: (key: number) => string): Tab[] => {
    const selectedDocument = documents.first()

    const tabsMap = new Map<AvailableTabs, Tab>()

    const costStatementDocExtID = documents.find((x) => x.documentTypeId === EDocumentIds.CostStatement)?.externalSystemId
    const hasRelateGVACost = selectedDocument?.documentTypeId === EDocumentIds.GVA && isDocumentRelated(costStatementDocExtID, documents)

    const hasRelatedBaterrieCost = selectedDocument?.documentTypeId === EDocumentIds.Batterie && isDocumentRelated(costStatementDocExtID, documents)

    documents.forEach((doc) => {
        switch (doc.documentTypeId) {
            case EDocumentIds.GVA: {
                tabsMap.set("Adresses", { name: translateText(1454), id: "Adresses" })
                tabsMap.set("Vehicle", { name: translateText(236), id: "Vehicle" })
                tabsMap.set("Compliamt", {
                    name: translateText(1362),
                    id: "Compliamt",
                    ...(hasRelateGVACost && { hintTitle: translateText(12423), hintDescription: translateText(12422) }),
                })
                break
            }
            case EDocumentIds.ADR: {
                tabsMap.set("Adresses", { name: translateText(1454), id: "Adresses" })
                tabsMap.set("OldBatteries", { name: translateText(1528), id: "OldBatteries" })
                break
            }
            case EDocumentIds.CostStatement: {
                tabsMap.set("CostStatement", {
                    name: translateText(1455),
                    id: "CostStatement",
                    hintTitle: translateText(12423),
                    hintDescription: translateText(13471),
                    ...(hasRelateGVACost && { enabledIf: "complainInfo.isAdditionalCosts" }),
                    ...(hasRelatedBaterrieCost && { enabledIf: "batteryInfo.enableDeinstallationCost" }),
                })
                break
            }

            case EDocumentIds.Batterie: {
                tabsMap.set("Adresses", { name: translateText(1454), id: "Adresses" })
                tabsMap.set("Vehicle", { name: translateText(236), id: "Vehicle" })
                tabsMap.set("Batterie", {
                    name: translateText(1456),
                    id: "Batterie",
                    ...(hasRelatedBaterrieCost && {
                        hintTitle: translateText(12423),
                        hintDescription: translateText(12428),
                    }),
                })
                break
            }
            case EDocumentIds.Returns: {
                tabsMap.set("Adresses", { name: translateText(1454), id: "Adresses" })
                tabsMap.set("Return", { name: translateText(1485), id: "Return" })
                break
            }
            default:
                break
        }
    })

    return Array.from(tabsMap.values())
}
