import { CategoryNode, GroupNode, YqNode } from "../../../models"
import { useEdsStore } from "../store"

export function setLoading(loading: boolean) {
    useEdsStore.setState((state) => ({
        treeNavigation: {
            isLoading: loading,
            isError: false,
        },
    }))
}

export function setError(error: boolean) {
    useEdsStore.setState((state) => ({
        treeNavigation: {
            isLoading: false,
            isError: error,
        },
    }))
}

function setNodeParents(nodes: GroupNode | CategoryNode) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { children, ...parent } = nodes
    nodes?.children?.forEach((childNode) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { children: _, parent: __, ...node } = childNode
        // eslint-disable-next-line no-param-reassign
        childNode.parent = { ...parent, children: [node] }
        setNodeParents(childNode)
    })
}

export function setTreeNavigation(nodes: GroupNode | CategoryNode | undefined) {
    if (nodes) {
        setNodeParents(nodes)
    }

    useEdsStore.setState((state) => ({
        treeNavigation: {
            data: nodes,
            isLoading: false,
            isError: false,
        },
    }))
}

export function setSelectedNode(selectedNode: YqNode | undefined) {
    useEdsStore.setState((state) => ({
        treeNavigation: {
            ...state.treeNavigation,
            selectedNode,
        },
    }))
}
