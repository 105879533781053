import { ajax, getStoredAuthorization, TmaHelper } from "@tm/utils"

import Morpheus from "@tm/morpheus"
import { ConfigParams } from "@tm/models"
import { getBundleParams } from "../../utils"

export type AutosuggestFunction = (query: string) => Promise<Array<Hit>>

export type Hit = {
    productGroup: string
    productGroupSynonym?: string
    dataSupplier?: string
    highlightProductGroup?: string
    highlightProductGroupSynonym?: string
    highlightDataSupplier?: string
}

function getServiceUrl(type: "vehicle" | "universal"): string {
    const { autosugestServiceUrl } = Morpheus.getParams<ConfigParams>()
    const { vehiclePartsAutosuggestWithSuppliers } = getBundleParams()

    if (type === "vehicle") {
        if (vehiclePartsAutosuggestWithSuppliers) {
            return `${autosugestServiceUrl}/ProductGroupsSuppliers`
        }

        return `${autosugestServiceUrl}/ProductGroups`
    }

    return `${autosugestServiceUrl}/UniversalProductGroups`
}

export function getProductGroupSuggestions(query: string, tecdocTypeId?: number) {
    return ajax<{ hits: Array<Hit | string> }>({
        url: getServiceUrl("vehicle"),
        authorization: getStoredAuthorization(),
        body: {
            query,
            tecdocTypeId,
            resultSize: 50,
        },
    }).then((data) => {
        const hits: Array<Hit> = (data?.hits ?? []).map((hit) => (typeof hit === "string" ? { productGroup: hit } : hit)) // Map response from old endpoints to new format
        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.AutoCompleteResults(hits.map((x) => x.productGroup))
        return hits
    })
}

export function getUniProductGroupSuggestions(query: string) {
    return ajax<{ hits: Array<Hit | string> }>({
        url: getServiceUrl("universal"),
        authorization: getStoredAuthorization(),
        body: {
            query,
            resultSize: 50,
        },
    }).then((data) => {
        const hits: Array<Hit> = (data?.hits ?? []).map((hit) => (typeof hit === "string" ? { productGroup: hit } : hit)) // Map response from old endpoints to new format
        TmaHelper.UniParts.Search.AutoCompleteResults(hits.map((x) => x.productGroup))
        return hits
    })
}
