import { useLocalization } from "@tm/localization"
import { CisVoucherType } from "@tm/models"
import { CellContentPosition, TableCellData, TableColumnData, TableVariants } from "@tm/components"
import { getWmVoucherId } from "../../../../../business/helper"
import { Voucher } from "../../../../../data/model"
import DateCell from "../../../../_shared/cells/DateCell"
import VoucherOptionsCell from "../../../../_shared/cells/VoucherOptionsCell"
import { StyledTable } from "../../../../_shared/StyledComponents"
import { getBundleParams } from "../../../../../utils"

type Props = {
    compact: boolean
    selectedVoucherId?: string
    voucherTypeId: number
    vouchers: Array<Voucher>
    variant: TableVariants
    loadNextPage(): void
}

export default function WmStandardVouchersTableComponent(props: Props) {
    const { translateText } = useLocalization()
    const { selectedVoucherId, vouchers, voucherTypeId, variant, compact } = props
    const { cisVoucherUrl } = getBundleParams()

    function getColumns() {
        const descriptionColumnTextId = voucherTypeId === CisVoucherType.DeliveryNote ? 440 : 212
        const columns: TableColumnData[] = [
            { header: translateText(32) },
            { header: translateText(471) },
            { header: translateText(descriptionColumnTextId) },
            { header: translateText(40) },
        ]
        // if more validations are required for Returns a new Component must be created for that type
        if (!compact && voucherTypeId !== CisVoucherType.Returns) {
            columns.push({ header: translateText(465) })
        }
        columns.push({ alignContent: CellContentPosition.right })

        return columns
    }

    function getCells(voucher: Voucher) {
        const cellData: TableCellData[] = [
            { displayValue: getWmVoucherId(voucher.voucherId), id: "voucher_0" },
            {
                displayValue: <DateCell value={voucher.voucherDate} mode={compact ? "dateAboveTime" : "dateDashTime"} showHoursText />,
                id: "voucher_1",
            },
            { displayValue: voucher.voucherDescription, id: "voucher_2" },
            { displayValue: voucher.deliveryType, id: "voucher_3" },
        ]
        // if more validations are required for Returns a new Component must be created for that type
        if (!compact && voucherTypeId !== CisVoucherType.Returns) {
            cellData.push({ displayValue: voucher.information, id: "voucher_4" })
        }

        cellData.push({
            displayValue: (
                <VoucherOptionsCell
                    detailsRoute={cisVoucherUrl}
                    selectedVoucherId={selectedVoucherId}
                    voucherId={voucher.voucherId}
                    itemsCount={voucher.itemsCount}
                    voucherTypeId={voucherTypeId}
                />
            ),
            id: "voucher_5",
        })

        return cellData
    }

    const displayData = vouchers?.map((voucher, index) => ({
        cells: getCells(voucher),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === voucher.voucherId,
    }))

    return <StyledTable columns={getColumns()} rows={displayData} variant={variant} onScrollBottom={props.loadNextPage} />
}
