export enum ExternalToolSteps {
    ContractOpening = "contractOpening",
    Model = "model",
    Equipment = "equipment",
    Calculation = "calculationResultPage",
}

export const getStatusByExternalTool = (external: ExternalToolSteps) => {
    switch (external) {
        case ExternalToolSteps.ContractOpening:
            return "2"
        case ExternalToolSteps.Model:
            return "2-1"
        case ExternalToolSteps.Equipment:
            return "2-2"
        case ExternalToolSteps.Calculation:
            return "2-3"
        default:
            return "2"
    }
}

export const getExternalToolStepByStatus = (status: string) => {
    switch (status) {
        case "2":
            return ExternalToolSteps.ContractOpening
        case "2-1":
            return ExternalToolSteps.Model
        case "2-2":
            return ExternalToolSteps.Equipment
        case "2-3":
        case "3":
        case "4":
            return ExternalToolSteps.Calculation
        default:
            return ExternalToolSteps.ContractOpening
    }
}
