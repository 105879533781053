import { Stack, styled } from "@tm/components"

export const GroupHeader = styled(Stack)(({ theme }) => {
    const groupHeader = theme.overwrites?.components?.partlist?.groupHeader
    const backgroundColor = groupHeader?.monochrom ? theme.palette.fallback.highlightLight : theme.palette.highlight.light

    return {
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: groupHeader?.backgroundColor || backgroundColor,
        justifyContent: "space-between",
        padding: "4px 12px",
        minHeight: "40px",
        borderRadius: theme.radius?.default ?? 3,
        ".MuiTypography-root": {
            fontSize: 18,
            fontWeight: 600,
        },
    }
})
