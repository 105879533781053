import { useLocalization } from "@tm/localization"
import { Text, Icon, Button } from "@tm/controls"
import { TelematicsDataResponse } from "../../../../data"

type Props = {
    displayName: string
    data?: TelematicsDataResponse
    dataLoading: boolean
    updateData(): void
}

export default function UpdateViewBrandComponent(props: Props) {
    const { translateText, translate, date } = useLocalization()
    const { data, dataLoading, updateData, displayName } = props

    return (
        <div>
            <div className="update-view">
                {dataLoading ? (
                    <div className="update-view__info">{translate(977)}</div>
                ) : (
                    <div className="update-view__info">
                        <div className="update-view__last-update">
                            {data && data.dateOfLastUpdate ? `(${translateText(496).replace("?", date(data.dateOfLastUpdate, "g"))})` : " "}
                        </div>
                    </div>
                )}
                <Button size="xl" skin="primary" onClick={updateData} disabled={dataLoading}>
                    {translate(496)}
                </Button>
                <div className="update-view__footnote">
                    {translate(489)}
                    <Icon name="info" size="s" />
                </div>
            </div>
            <div className="brand">
                <Icon className="brand__logo" name="telematics" size="xl" />
                <Text className="brand__description" size="xl">
                    {displayName}
                    <Text className="brand__version" size="s">
                        Version 1.0.1
                    </Text>
                </Text>
            </div>
        </div>
    )
}
