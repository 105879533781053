import { Button, Icon, buttonClasses, styled } from "@tm/components"
import { useMemo } from "react"

type Props = {
    id: number
    thumbnailUrl: string
    selected: boolean
    onClick: (id: number) => void
}

const SelectionButton = styled(Button)<{ selected: boolean }>(({ selected, theme }) => ({
    [`&.${buttonClasses.outlined}`]: {
        borderColor: theme.palette.grey[400],
        ...(selected && {
            backgroundColor: theme.palette.primary.light,
            svg: {
                color: theme.palette.primary.contrastText,
            },
            "&:hover": {
                svg: {
                    fill: theme.palette.primary.contrastText,
                },
            },
        }),
    },
}))

/**
 * Special Iconbutton, boarded with Selected state
 * @param props
 * @returns
 */
export function UniBestsellerButton(props: Props) {
    const iconName = useMemo(() => {
        const iconNameSplits = props.thumbnailUrl?.match("[0-9]*_[0-9]*")
        return iconNameSplits?.[0] ?? props?.thumbnailUrl
    }, [props.thumbnailUrl])

    return (
        <SelectionButton
            size="large"
            selected={props.selected}
            variant="outlined"
            startIcon={<Icon name={iconName} width="24px" height="24px" sx={{ opacity: 1 }} />}
            onClick={() => props.onClick(props.id)}
        />
    )
}
