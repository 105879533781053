import { useLocalization } from "@tm/localization"
import { Box, styled } from "@tm/components"
import { renderRoute, uniqueId, encodeUniqueId } from "@tm/utils"
import { useHistory, useRouteMatch } from "react-router"

type LinkWidgetProps = {
    url: string
    textId: number
    image: string
    target: {
        url?: string
    }
}

const StyledLinkBox = styled(Box)({
    flex: "0 0 50%",
    cursor: "pointer",
    paddingRight: "8px",
    paddingTop: "10px",
})

const StyledContentBox = styled(Box)({
    width: "100%",
    backgroundColor: "#7898B1",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    paddingTop: "180px",
})

const StyledTextBox = styled(Box)({
    backgroundColor: "rgba(51, 51, 51, 0.8)",
    width: "100%",
    bottom: 0,
    textAlign: "center",
    verticalAlign: "middle",
    lineHeight: "40px",
    height: "40px",
    marginBottom: "20px",
    color: "white",
    fontSize: "14px",
    display: "inline-block",
})

export default function LinkWidgetComponent({ url, textId, image, target }: LinkWidgetProps) {
    const { translateText } = useLocalization()
    const backgroundImage = `url(${image})`
    const history = useHistory()
    const match = useRouteMatch<{ workTaskId?: string }>()

    function openWindowInNewTab() {
        if (target?.url) {
            history.push(
                renderRoute(target.url, {
                    ...match.params,
                    workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()),
                })
            )
        } else {
            window.open(url, "_blank")
        }
    }

    return (
        <StyledLinkBox onClick={openWindowInNewTab}>
            <StyledContentBox sx={{ backgroundImage }}>
                <StyledTextBox>{translateText(textId).toUpperCase()}</StyledTextBox>
            </StyledContentBox>
        </StyledLinkBox>
    )
}
