import { Dialog, Headline, Icon, LicensePlateField, PanelSection, Text } from "@tm/controls"
import { styled } from "@tm/components"

export const StyledDialog = styled(Dialog)({
    ".dialog-prompt__inner": {
        display: "flex",
        flexDirection: "column",
        height: "auto",
        maxHeight: "95%",
    },
    ".dialog-prompt__additional-content": {
        flex: 1,
        overflow: "auto",
    },
    ".dialog-prompt__close": {
        visibility: "hidden",
    },
})

export const Step = styled("div")<{ disabled: boolean }>(({ theme, disabled }) => ({
    marginBottom: "2em",
    ...(disabled
        ? {
              pointerEvents: "none",
              opacity: theme.opacity?.disabled,
          }
        : {}),
}))

export const StepDescription = styled(Text)({
    display: "block",
    marginBottom: "0.5em",
})

export const AlternativeVehiclesStep = styled(Step)({
    maxHeight: "50vh",
    display: "flex",
    flexDirection: "column",
})

export const AlternativeVehiclesStepTableWrapper = styled("div")({
    overflowY: "auto",
    paddingRight: "0.25em",
})

export const AlternativeVehiclesStepThumbnail = styled("div")({
    display: "flex",
    alignItems: "center",
    marginTop: "0.5em",
    ".image": {
        height: "2em",
    },
    ".error-image": {
        width: "2em",
        height: "2em",
        marginRight: "1em",
    },
})

export const SwitchWrapperStep = styled(Step)({
    ".switch": {
        display: "flex",
        $nest: {
            ".text": {
                fontSize: "0.875rem",
                lineHeight: 1.2,
            },
        },
    },
})

export const ArrowIcon = styled(Icon)({
    width: "5em",
    height: "5em",
})

export const VehicleCardsStep = styled(Step)({
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
})

export const VehicleCard = styled(PanelSection)({
    width: "30%",
})

export const VehicleCardHeader = styled("div")({
    textAlign: "center",
})

export const VehicleCardHeadline = styled(Headline)({
    marginBottom: "1em",
})

export const VehicleCardThumbnails = styled("div")({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "0.5em",
    ".image": {
        height: "2em",
    },
    ".error-image": {
        width: "2em",
        height: "2em",
        marginRight: "1em",
    },
})

export const VehicleCardDetails = styled("div")({
    display: "table",
    width: "100%",
    marginTop: "1em",
})

export const VehicleCardDetail = styled("div")({
    display: "table-row",
    "&:first-child > div": {
        borderTop: "solid 1px #ccc",
    },
    "> div": {
        display: "table-cell",
        width: "50%",
        height: "2em",
        padding: "0.25em 0.5em",
        verticalAlign: "middle",
        border: "solid 1px #ccc",
        borderTop: "none",
    },
    "> div:first-child": {
        borderRight: "none",
    },
    ".switch": {
        pointerEvents: "none",
    },
})

export const StyledLicensePlateField = styled(LicensePlateField)({
    ".input__inner": {
        padding: "0 5px",
    },
})
