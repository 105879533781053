import { Vehicle } from "@tm/models"

function removeBrackets(value?: string): string | undefined {
    if (!value) {
        return
    }
    const regex = /^(.*?)(?:\(.*?\))?$/
    const match = regex.exec(value)
    if (!match) {
        return value
    }
    return match[1]
}

export function getFullVehicleName(vehicle: Vehicle) {
    const tokens = [vehicle.manufacturer, removeBrackets(vehicle.modelSeries), removeBrackets(vehicle.model)]
    return tokens.join(" ")
}
