
import { ImagePathsResponse } from "./model"

export function mapImagePathResponse(response: any): ImagePathsResponse | undefined {

    if (response && response.imagePaths && response.imagePaths.vehicleImages) {
        const imagePaths = response.imagePaths
        const chassisImage = imagePaths.vehicleImages.findFirst((x: any) => x?.type == "1")
        const shadowImage = imagePaths.vehicleImages.findFirst((x: any) => x?.type == "2")
        const bodyImage = imagePaths.vehicleImages.findFirst((x: any) => x?.type == "3")
        const brakeImage = imagePaths.vehicleImages.findFirst((x: any) => x?.type == "4")

        const naturalWidth = chassisImage && chassisImage.imageWidth || bodyImage && bodyImage.imageWidth || 0
        const naturalHeight = chassisImage && chassisImage.imageHeight ||  bodyImage && bodyImage.imageHeight || 0

        const rimsPosition = imagePaths.rimsPosition

        return {
            images: {
                bodyImage64: bodyImage && bodyImage.image64,
                brakeImage64: brakeImage && brakeImage.image64,
                chassisImage64: chassisImage && chassisImage.image64,
                shadowImage64: shadowImage && shadowImage.image64,
                isNewImage: response.isNewImage || false
            },
            rimsPosition: {
                ...rimsPosition,
                naturalWidth,
                naturalHeight
            },
        }
    }

    return undefined
}
