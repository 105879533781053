import { forwardRef, PropsWithChildren, useCallback, useImperativeHandle, useRef, useState } from "react"
import { Note } from "./component"

type Payload = {
    onChange?(value: string): void
}

export type ArticleNoteRefProps = {
    show(payload: Payload): void
}

export const ControllableArticleNote = forwardRef<ArticleNoteRefProps, PropsWithChildren<unknown>>((_, ref) => {
    const [visible, setVisible] = useState<boolean>(false)
    const payloadRef = useRef<Payload>({})

    useImperativeHandle(
        ref,
        () => ({
            show: (payload: Payload) => {
                payloadRef.current = payload
                setVisible((prev) => !prev)
            },
        }),
        []
    )

    const handleOnBlur = useCallback(
        (note: string) => {
            if (note) {
                payloadRef.current.onChange && payloadRef.current.onChange(note)
            }
        },
        [payloadRef.current.onChange]
    )

    if (!visible) {
        return null
    }

    return <Note onBlur={handleOnBlur} />
})
