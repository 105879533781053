import { classes } from "@tm/utils"
import { useMemo } from "react"
import { getComponentStyles } from "./styles"

type Props = {
    isNew: boolean
}

export default function NewMessageBadge({ isNew }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    return <div className={classes(classNames.main, isNew && classNames.new)} />
}
