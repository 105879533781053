import { useMemo } from "react"
import { notUndefinedOrNull } from "@tm/utils"
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from "react-query"
import { JobListQueryParams, SortTypes, ErrorReason } from "../../models"
import { Repositories } from ".."

export function useGetJobList(vehicleVin?: string, errorSearchQuery?: string) {
    const pageSize = 2
    const queryParams: JobListQueryParams = {
        pageNumber: 1,
        pageSize,
        sortType: SortTypes.creationDate,
        // vin: vehicleVin,
        errorSearchQuery,
    }

    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, isError } = useInfiniteQuery(
        ["sindri_query", "jobsList", pageSize, errorSearchQuery, vehicleVin],
        ({ pageParam }: QueryFunctionContext<QueryKey, JobListQueryParams>) => {
            return Repositories.getJobList(pageParam ?? queryParams)
        },
        {
            enabled: !!vehicleVin,
            getNextPageParam: (lastPage, pages): JobListQueryParams | undefined => {
                if (!lastPage) {
                    return
                }

                if (lastPage.page_number * pageSize >= lastPage.total_records) {
                    return
                }

                return {
                    ...queryParams,
                    pageNumber: pages.length + 1,
                }
            },
        }
    )

    const jobs = useMemo(() => data?.pages.flatMap((page) => page?.jobs).filter(notUndefinedOrNull), [data?.pages])
    const errorReason = data?.pages[0]?.error?.reason

    const hasError = isError || errorReason

    return {
        jobs,
        isLoading,
        isNextPageLoading: isFetchingNextPage,
        loadNextPage: hasNextPage && !hasError ? fetchNextPage : undefined,
        nextPageLoading: isFetchingNextPage,
        isError: hasError,
        isSindriError: errorReason === ErrorReason.SindriError,
        errorReason,
    }
}
