import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { ERepairTimeDivision, RepairTimeOptions, useUser } from "@tm/context-distribution"
import { Loader, Text, Radio, Dropdown } from "@tm/controls"
import { ReactQueryKeys, RepairTimeProviderConfig } from "@tm/models"
import { useQueryClient } from "react-query"
import { Switch } from "@tm/components"
import { getRepairTimeProviders } from "@tm/utils"
import { useInvalidateWorkTaskBasketQueries } from "../../../../../basket/src/data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

function NavigationItem(props: RepairTimeProviderConfig) {
    return <>{props.name || null}</>
}

const KEY = ReactQueryKeys.basket_summary
export default function RepairTimesComponent() {
    const { translateText } = useLocalization()
    const { userSettings, setUserSetting, changeRepairTimeOptions } = useUser() ?? {}
    const [savingOptions, setSavingOptions] = useState<boolean>(false)
    const queryClient = useQueryClient()
    const { invalidateWorksRequests } = useInvalidateWorkTaskBasketQueries()

    if (!userSettings) {
        return <Loader />
    }

    const repairTimeOptions: RepairTimeOptions = userSettings.repairTimeOptions || {
        division: ERepairTimeDivision.Hours,
        useManufacturerRepairTimeDivision: false,
        useRepairTimeCalculation: false,
        id: "",
    }

    function handleProviderChange(item: RepairTimeProviderConfig) {
        if (userSettings && setUserSetting) {
            const providers = { ...userSettings.activeVehicleDataProviders }
            providers.repairTimes = item.id
            setUserSetting("ACTIVE_VEHICLE_DATA_PROVIDERS", providers)
        }
    }

    function postUpdateActions() {
        queryClient.resetQueries(KEY)
        setSavingOptions(false)
        invalidateWorksRequests()
    }

    function handleManufacturerDivisionChange(state: boolean) {
        if (userSettings && changeRepairTimeOptions) {
            setSavingOptions(true)
            changeRepairTimeOptions({ ...repairTimeOptions, useManufacturerRepairTimeDivision: state }).then(() => {
                postUpdateActions()
            })
        }
    }

    function handleDivisionChange(value: ERepairTimeDivision) {
        if (userSettings && changeRepairTimeOptions) {
            setSavingOptions(true)
            changeRepairTimeOptions({ ...repairTimeOptions, division: value }).then(() => {
                postUpdateActions()
            })
        }
    }

    function handleUseRepairTimeCalculationChange(state: boolean) {
        if (userSettings && changeRepairTimeOptions) {
            setSavingOptions(true)
            changeRepairTimeOptions({ ...repairTimeOptions, useRepairTimeCalculation: state }).then(() => {
                postUpdateActions()
            })
        }
    }

    if (userSettings) {
        const { repairTimeProviders } = getRepairTimeProviders()
        const items = repairTimeProviders.orderBy((x) => (x.sort != null ? x.sort : Number.MAX_VALUE))

        const { useManufacturerRepairTimeDivision, division, useRepairTimeCalculation } = repairTimeOptions
        return (
            <div className="user-settings__repair-times">
                <div className="user-settings__repair-times__default-rt-selection">
                    {!items.length && <Text>{translateText(1753)}</Text>}
                    {items.length > 1 && (
                        <>
                            <Text>{translateText(1603)}:</Text>
                            <Dropdown
                                items={items}
                                itemView={NavigationItem}
                                onChange={handleProviderChange}
                                value={
                                    (!!userSettings.activeVehicleDataProviders.repairTimes &&
                                        items.find((item) => item.id === userSettings.activeVehicleDataProviders.repairTimes)) ||
                                    items[0]
                                }
                            />
                        </>
                    )}
                </div>
                <Switch
                    checked={useRepairTimeCalculation}
                    onChange={(e) => handleUseRepairTimeCalculationChange(e.target.checked)}
                    label={translateText(3161)}
                    disabled={savingOptions}
                />
                <Text size="xl" modifiers={["block"]} className="user-settings__repair-times__unit-headline">
                    {translateText(1552)}
                </Text>
                <Switch
                    checked={useManufacturerRepairTimeDivision}
                    onChange={(e) => handleManufacturerDivisionChange(e.target.checked)}
                    label={translateText(1557)}
                    disabled={savingOptions}
                />
                <div className="user-settings__repair-times__division-radios">
                    <Radio
                        id="user-settings__repair-times--hours"
                        disabled={useManufacturerRepairTimeDivision || savingOptions}
                        size="s"
                        label={translateText(1564)}
                        onCheck={handleDivisionChange}
                        value={ERepairTimeDivision.Hours}
                        checked={division === ERepairTimeDivision.Hours}
                    />
                    <Radio
                        id="user-settings__repair-times--10"
                        disabled={useManufacturerRepairTimeDivision || savingOptions}
                        size="s"
                        label={translateText(1554)}
                        onCheck={handleDivisionChange}
                        value={ERepairTimeDivision.Division10}
                        checked={division === ERepairTimeDivision.Division10}
                    />
                    <Radio
                        id="user-settings__repair-times--12"
                        disabled={useManufacturerRepairTimeDivision || savingOptions}
                        size="s"
                        label={translateText(1555)}
                        onCheck={handleDivisionChange}
                        value={ERepairTimeDivision.Division12}
                        checked={division === ERepairTimeDivision.Division12}
                    />
                    <Radio
                        id="user-settings__repair-times--100"
                        disabled={useManufacturerRepairTimeDivision || savingOptions}
                        size="s"
                        label={translateText(1556)}
                        onCheck={handleDivisionChange}
                        value={ERepairTimeDivision.Division100}
                        checked={division === ERepairTimeDivision.Division100}
                    />
                </div>
            </div>
        )
    }

    return <Loader />
}
