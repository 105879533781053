import * as React from 'react'
import { Text, Tooltip } from '@tm/controls'

type Props = {
    children: React.ReactNode
    label: string
}

const OverflowTooltip : React.FC<Props> = ({children, label}) =>  {
    const [isOverflowed, setIsOverflow] = React.useState(false)
    const containerRef = React.useRef<HTMLDivElement>()

    const updateOverflowStatus = () => {
      if (containerRef.current){
        const element = containerRef.current        
        setIsOverflow(element.offsetHeight < element.scrollHeight ||element.offsetWidth < element.scrollWidth)
      }
    }

    React.useEffect(() => {
      if(containerRef.current)
        updateOverflowStatus()
    }, [containerRef.current])


    React.useEffect(() => {
      window.addEventListener("resize", updateOverflowStatus);
      return () => {
        window.removeEventListener("resize", updateOverflowStatus);
      };
    }, [])

    return (
        <Tooltip style={'primary'} position="right" content={<Text>{label}</Text>} disabled={!isOverflowed}>
            <div ref={(elem : HTMLDivElement)=>(containerRef.current = elem)} >
              {children}
            </div>
        </Tooltip>
    )
}

export default OverflowTooltip