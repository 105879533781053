import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce } from "./business"

const ImageWidget: BundleComponent = {
    name: "image-widget",
    component,
    reduce,
}

export default ImageWidget
