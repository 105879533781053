import { Box, Typography, styled } from "@tm/components"
import TextLink from "./text-link"

export type TriProp = {
    name: string
    to: string
    closeMenu: () => void
}

export const MenuText = styled(Typography)(({ theme }) => ({
    opacity: 1,
    ":disabled": {
        opacity: theme.opacity?.disabled,
        cursor: "default",
    },
    a: {
        textDecoration: "none",
    },
}))

const TriangleText = styled(MenuText)(({ theme }) => ({
    padding: "0.2em 0.6em",
    ":hover": {
        "> a:not(:disabled)": {
            "> div": {
                "&:first-child": {
                    color: theme.colors?.dark,
                },
                "&:last-child": {
                    opacity: theme.opacity?.primary,
                },
            },
        },
    },
}))

const TriangledSymbol = styled(Box)(({ theme }) => ({
    fontSize: "8px",
    display: "inline-block",
    lineHeight: theme.font?.lineHeight,
    verticalAlign: "text-top",
    paddingRight: ".5rem",
    color: theme.colors?.primary,
}))

export function TriangledLabel(props: TriProp) {
    return (
        <TextLink to={props.to} closeMenu={props.closeMenu} component={TriangleText}>
            <TriangledSymbol>&#9654;</TriangledSymbol>
            <Box>{props.name}</Box>
        </TextLink>
    )
}
