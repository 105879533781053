import { Suspense, useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { Alert, Box } from "@tm/components"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

type Props = {
    workTaskId: string
}

function NotificationsComponent({ workTaskId }: Props) {
    const { translateText } = useLocalization()
    const { workEstimation } = useWorkTaskBasketState(workTaskId)
    const [showNotification, setShowNotification] = useState(false)

    function handleAlertButtonClick() {
        setShowNotification(false)
    }

    useEffect(() => {
        setShowNotification(workEstimation?.showMultipleManufacturerDivisionsConflict ?? false)
    }, [workEstimation?.showMultipleManufacturerDivisionsConflict])

    if (!showNotification) {
        return null
    }

    return (
        <Box flex={1}>
            <Alert title={translateText(1560)} severity="warning" onClose={handleAlertButtonClick}>
                {translateText(1561)}
            </Alert>
        </Box>
    )
}

export default function Wrapper() {
    const { workTask } = useWorkTask() ?? {}
    if (!workTask) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <NotificationsComponent workTaskId={workTask.id} />
        </Suspense>
    )
}
