import { useRef } from "react"
import { useLocalization } from "@tm/localization"
import { Button, WarningPrompt } from "@tm/controls"
import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { SubUser } from "../../../data/model"

type Props = {
    subUser: SubUser
    disabled: boolean
    onDelete(subUser: SubUser): void
}

export default function SubUserDeleteComponent({ subUser, disabled, onDelete }: Props) {
    const { translateText } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    const dialogRef = useRef<WarningPrompt>(null)

    function openWarning() {
        dialogRef.current?.show()
    }

    function handleWarningConfirm() {
        onDelete(subUser)
    }

    return (
        <>
            <Button
                size="s"
                skin={deleteButtonColorError ? "danger" : undefined}
                icon="delete"
                title={translateText(1239)}
                onClick={openWarning}
                disabled={disabled}
            />
            <WarningPrompt
                text={translateText(1258)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={dialogRef}
                onConfirm={handleWarningConfirm}
            />
        </>
    )
}
