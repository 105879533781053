import { AvailabilityStatus, Warehouse } from "@tm/models"

export const isManufacturerAvailable = (warehouse: Warehouse) => {
    const manufacturerAvailable = warehouse.quantities?.find(
        (quantity) => quantity.availability?.type === AvailabilityStatus.ManufacturerAvailable
    )?.value

    // NEXT-24548 - Icon "7" should always be displayed if availability type is 13 for Coparts
    if (
        warehouse.quantities?.find((quantity) => quantity?.availability?.type === AvailabilityStatus.ManufacturerAvailable && quantity?.value === 0)
    ) {
        return true
    }

    return !!manufacturerAvailable && manufacturerAvailable > 0
}
