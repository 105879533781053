import { PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"
import handleAddCustomPartsToBasket from "./addCustomPartsToBasket"
import handleAddCustomRepairTimesToBasket from "./addCustomRepairTimesToBasket"
import handleAddFillQuantityToBasket from "./addFillQuantityToBasket"
import handleAddOePartsToBasket from "./addOePartsToBasket"
import handleAddPartsToBasket from "./addPartsToBasket"
import handleAddRepairTimesToBasket from "./addRepairTimesToBasket"
import handleAddWholesalerPartsToBasket from "./addWholesalerPartsToBasket"
import handleAttachToWorkTask from "./attachToWorkTask"
import handleCloseModal from "./closeModal"
import handleDATRegistration from "./openDATRegistration"
import handleDatExternalTool from "./handleDatExternalTool"
import handleEreContinueToCalculation from "./ereContinueToCalculation"
import handleEurotaxDataOe from "./eurotaxDataOe"
import handleGetActivatableModules from "./getActivatableModules"
import handleGetCheckHasMemo from "./getCheckHasMemo"
import handleGetCustomerData from "./getCustomerData"
import handleGetDemoModules from "./getDemoModules"
import handleGetErpInfos from "./getErpInfos"
import handleGetFeedbackResult from "./getFeedbackResult"
import handleGetHourlyRates from "./getHourlyRates"
import handleGetRepairShop from "./getRepairShop"
import handleGetRepairShopData from "./getRepairShopData"
import handleGetRepairTimesDivision from "./getRepairTimesDivision"
import handleGetTheme from "./getTheme"
import handleImportFastCalculation from "./importFastCalculation"
import handleInvalidSessionMvc from "./invalidSessionMvc"
import handleInvokeDirectSearch from "./invokeDirectSearch"
import handleInvokeUniversalSearch from "./invokeUniversalSearch"
import handleLogout from "./logout"
import handleOpenArticleList from "./openArticleList"
import handleOpenArticleListByGenarts from "./openArticleListByGenarts"
import handleOpenArticleListByWholesalerNumbers from "./openArticleListByWholesalerNumbers"
import handleOpenExternalModule from "./openExternalModule"
import handleOpenExternalModuleInWorkTask from "./openExternalModuleInWorkTask"
import handleOpenInternalModule from "./openInternalModule"
import handleOpenMemoInfo from "./openMemoInfo"
import handleOpenMemoTechicalNotes from "./openMemoTechnicalNotes"
import handleOpenOeAftermarket from "./openOeAftermarket"
import handleOpenOffersModule from "./openOffersModule"
import handleOpenPartsDetails from "./openPartsDetails"
import handleOpenPartsList from "./openPartsList"
import handleOpenPromoPartsList from "./openPromoPartsList"
import handleOpenRapidCalculationList from "./openRapidCalculationList"
import handleOpenStartPage from "./openStartPage"
import handleOpenWorkTask from "./openWorkTask"
import handleSearchTyre from "./searchTyre"
import handleSearchWheel from "./searchWheel"
import handleSendFastCheckOverview from "./sendFastCheckOverview"
import handleSendTruckDataToNext from "./sendTruckDataToNext"
import handleSetEngineCode from "./setEngineCode"
import handleSetLongLifeStatus from "./setLongLifeStatus"
import handleSetMvcSession from "./setMvcSession"
import handleSetVehicleProperties from "./setVehicleProperties"
import handleSetVehicleTecDocNumber from "./setVehicleTecDocNumber"
import handleShowPartsByOeReferences from "./showPartsByOeReferences"
import handleShowToastMessage from "./showToastMessage"
import handleAddRapidCalculatedCostsRequest from "./addRapidCalculatedCostsRequest"

export type PostMessageEvent = MessageEvent<PostMessageRequest>

export type PostMessageHandler = (this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) => void

export const handlers: PostMessageHandler[] = [
    handleAddCustomPartsToBasket,
    handleAddCustomRepairTimesToBasket,
    handleAddFillQuantityToBasket,
    handleAddOePartsToBasket,
    handleAddPartsToBasket,
    handleAddRepairTimesToBasket,
    handleAddWholesalerPartsToBasket,
    handleAttachToWorkTask,
    handleCloseModal,
    handleDATRegistration,
    handleDatExternalTool,
    handleEreContinueToCalculation,
    handleEurotaxDataOe,
    handleGetActivatableModules,
    handleGetCheckHasMemo,
    handleGetCustomerData,
    handleGetDemoModules,
    handleGetErpInfos,
    handleGetFeedbackResult,
    handleGetHourlyRates,
    handleGetRepairShop,
    handleGetRepairShopData,
    handleGetRepairTimesDivision,
    handleGetTheme,
    handleImportFastCalculation,
    handleInvalidSessionMvc,
    handleInvokeDirectSearch,
    handleInvokeUniversalSearch,
    handleLogout,
    handleOpenArticleList,
    handleOpenArticleListByGenarts,
    handleOpenArticleListByWholesalerNumbers,
    handleOpenExternalModule,
    handleOpenExternalModuleInWorkTask,
    handleOpenInternalModule,
    handleOpenMemoInfo,
    handleOpenMemoTechicalNotes,
    handleOpenOeAftermarket,
    handleOpenOffersModule,
    handleOpenPartsDetails,
    handleOpenPartsList,
    handleOpenPromoPartsList,
    handleOpenRapidCalculationList,
    handleOpenStartPage,
    handleOpenWorkTask,
    handleSearchTyre,
    handleSearchWheel,
    handleSendFastCheckOverview,
    handleSendTruckDataToNext,
    handleSetEngineCode,
    handleSetLongLifeStatus,
    handleSetMvcSession,
    handleSetVehicleProperties,
    handleSetVehicleTecDocNumber,
    handleShowPartsByOeReferences,
    handleShowToastMessage,
    handleAddRapidCalculatedCostsRequest,
]
