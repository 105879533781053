import { BasketBundleParams } from "@tm/models"
import { registerActions } from "../data"

export const version = {
    name: "basket",
    version: "1.0",
}

let bundleParams: BasketBundleParams

/**
 * @memberof Basket
 * @param params Config for Basket Bundle (UICONFIG)
 */
export function initBasketBundle(params: BasketBundleParams) {
    bundleParams = params

    registerActions()

    return bundleParams
}

export function getBundleParams(): BasketBundleParams {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
