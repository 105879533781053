import { ajax, getStoredAuthorization } from "@tm/utils"
import { HistoryListRequest } from ".."
import { getBundleParams } from "../../../utils"
import { HistoryEntry } from "../../models"
import { mapHistoryEntries } from "./mapper"

export * from "./model"

export const getHistory = (body: HistoryListRequest): Promise<HistoryEntry[]> => {
    const url = `${getServiceUrl()}/GetHistoryList`
    const authorization = getStoredAuthorization()

    return new Promise<HistoryEntry[]>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => resolve(response.historyList.map(mapHistoryEntries)), reject)
    )
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.ereCalculationServiceUrl
}
