import Morpheus, { useActions } from "@tm/morpheus"
import { closeModalOrDrawer, initSelector, useUnsubscribe } from "@tm/utils"
import { useSelector } from "react-redux"
import { Box, styled } from "@tm/components"
import { useCallback } from "react"
import { Article, EReplaceButtonBundle, channel } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { CalculationItem } from "."
import { EreSelectionSteps, ICalculationItem } from "../../../data/models"
import { itemsSelector, MainActions } from "../../main/business"
import { createRequestArticleListPayload } from "../business/helper"

type StyledBox = {
    isWm: boolean
}

const selector = initSelector(itemsSelector)

export default function ArticleListComponent() {
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"
    const { userContext } = useUser()
    const subscriptionReplacePart = useUnsubscribe()

    const actions = useActions(
        MainActions,
        "changeStep",
        "addToShoppingBasket",
        "selectPart",
        "changeOeArticleQuantity",
        "selectOeArticle",
        "setErpArticles",
        "selectOePart",
        "changeArticleQuantity"
    )
    const [items] = useSelector(selector)

    const handleDetailsClick = (part: Article) => {
        const params = { productGroupId: part.productGroup.id, supplierId: part.supplier.id, supplierArticleNo: part.supplierArticleNo }

        actions.changeStep(EreSelectionSteps.DETAILS, params)
    }

    const handleAlternativeClick = useCallback(
        (item: ICalculationItem) => {
            subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce("PARTS/REPLACE_PART", ({ part, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.DMGEurotax) {
                    return false
                }
                if (part) {
                    closeModalOrDrawer(isNewList)
                    if ("number" in part) {
                        return actions.selectOePart(item)
                    }

                    return actions.selectPart(item, part)
                }
            })

            channel("WORKTASK").publish("PARTS/REQUEST_LIST", createRequestArticleListPayload(item))
        },
        [userContext, actions]
    )

    const handleChangeQuantity = useCallback(
        (item: ICalculationItem) => (value: number) => {
            if (!item.selectedPart) {
                return actions.changeOeArticleQuantity(item, value)
            }

            return actions.changeArticleQuantity(item, value)
        },
        [actions]
    )

    const renderOEArticle = (item: ICalculationItem, idx: number) => {
        return (
            <CalculationItem
                key={idx}
                item={item}
                onDetailsClick={handleDetailsClick}
                onOeArticleSelect={() => actions.selectOeArticle(item)}
                onQuantityChange={handleChangeQuantity(item)}
                onAlternativeClick={() => handleAlternativeClick(item)}
            />
        )
    }

    return (
        <StyledBox isWm={isWm} className="tk-parts">
            <Box className="list">
                <Box className="article-list calculation-items">{items?.map(renderOEArticle)}</Box>
            </Box>
        </StyledBox>
    )
}

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isWm",
})<StyledBox>(({ isWm }) => ({
    ".article-list__item": {
        margin: "0 0 0.5rem -0.5rem",
    },
    ".oe-article-image": {
        backgroundRepeat: "no-repeat",
        backgroundPosition: "right center",
        backgroundSize: "100%",
        backgroundColor: "white",
    },
    ".tag__content": {
        borderColor: "orange",
        fontWeight: "500",
    },
    ".check-button": {
        marginLeft: "2em",
    },
    ".supplier__name": {
        fontWeight: "500",
    },
    ".article__description": {
        fontWeight: "500",
    },
    ".additional-actions": {
        fontWeight: "500",
    },
    ".article__information-group": {
        fontWeight: "500",
    },
    ".erp-info__availability__wrapper": {
        fontWeight: "500",
    },
    ".price__name": {
        fontWeight: "500",
    },
    ".article__cell--additional-actions": {
        fontWeight: "500",
    },
    ".MuiButtonBase-root": {
        fontWeight: "500",
    },
    ".article__reference-group__description": {
        fontWeight: "500",
    },
    ".tk-erp": {
        fontWeight: "500",
    },
    ".tk-parts .alternative-part__list .article__cell--actions": {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridTemplateRows: "auto auto",
        marginRight: "2em",
        ".normal-replace": {
            marginLeft: "auto",
        },
    },
    ".tk-parts .alternative-part__list .article__cell--actions .btn-compilations": {
        gridRow: "2",
        gridColumn: "1",
        order: "2",
        maxWidth: "2.5em",
    },

    ".tk-parts .alternative-part__list .article__cell--actions .more-menu-wrapper": {
        gridRow: "2",
        gridColumn: "1",
        order: "1",
        marginLeft: "auto",
        marginRight: "1em",
        maxWidth: "2.5em",
    },
    ".article-list .article__cell--actions>:not(:last-child)": {
        marginLeft: "-1em",
    },

    ".article__cell--actions": {
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "198px",

        ".tk-basket": {
            margin: ".1725em",
            marginLeft: "0",
            marginRight: ".25em",
        },
        ".checkbox": {
            marginLeft: "auto",
        },
    },

    "&.calculation-item": {
        marginBottom: "1rem",

        ".article-list__item--oe": {
            "&.article__cell--additional-actions": {
                display: "none",
            },
        },

        ".alternative-part__list": {
            padding: 0,
            ".article-list__item": {
                margin: "0 0 0.5rem 3rem",
            },
        },

        ".oe-article-image": {
            backgroundRepeat: "no-repeat",
            backgroundPosition: "right center",
            backgroundSize: "100%",
            backgroundColor: "white",
        },

        ".oeArticle-attribute .tag__content": {
            borderColor: "orange",
        },

        ".check-button": {
            marginLeft: "2em",
        },

        ".article__cell--icon": {
            "& .btn__content": {
                "& .image": {
                    maxHeight: "1.2em",
                },
            },
        },

        ".article__information-group": {
            "& .icon-wrapper": {
                "& .image": {
                    maxHeight: "1.2em",
                },
            },
        },
    },

    ".article-list__item.article-list__panel.article-list__item--oe.article-list__panel": {
        ".article__cell--actions": {
            flexWrap: "nowrap",

            ".tk-basket": {
                margin: "0.1725em",
                marginLeft: 0,
                marginRight: "0.25em",
            },

            ".checkbox": {
                marginLeft: "auto",
            },
        },
    },

    ".article-list.alternative-part__list .additional-actions": {
        "&:last-child": {
            marginRight: "1.5em",
        },
    },

    ".tk-erp.erp-info__extended-delivery": {
        marginRight: "1em",
    },

    ...(!isWm
        ? {
            ".bdl-compilations.add-to-compilation": {
                padding: "0 2em",
            },
        }
        : {}),
}))
