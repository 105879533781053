import { useMemo, useRef } from "react"
import { generateHash } from "@tm/utils"
import { IFrame } from "@tm/controls"
import { Loader, Stack, styled } from "@tm/components"
import { useParams } from "react-router"

import { useWebkatUrl } from "./useWebkatUrl"
import { useCachedWebkatUrl } from "./useCachedWebkatUrl"

type Props = {
    externalSystemId: number
}

const WebkatFrame = styled(IFrame)({
    width: "100%",
    height: "100%",
})

export default function LkqWebkat({ externalSystemId }: Props) {
    const { workTaskId } = useParams<{ workTaskId?: string }>()

    const { url: loadedUrl, isLoading } = useWebkatUrl(externalSystemId)
    const cachedUrl = useCachedWebkatUrl(loadedUrl, workTaskId)

    // The frame should use the cached url in favor of the loaded url.
    // But because the cached url can change during runtime (while navigating inside the frame)
    // and the frame url should not change then (because this would cause the frame to reload)
    // it is set to a ref here which is then used in the memo.
    const cachedUrlRef = useRef(cachedUrl)
    cachedUrlRef.current = cachedUrl
    const frameUrl = useMemo(() => cachedUrlRef.current ?? loadedUrl, [loadedUrl])

    if (isLoading) {
        return (
            <Stack style={{ width: "100%", height: "100%" }} alignItems="center" justifyContent="center">
                <Loader />
            </Stack>
        )
    }

    if (!frameUrl) {
        return null
    }

    return <WebkatFrame id={`iframe-${generateHash(`${workTaskId}#${externalSystemId}`)}`} url={frameUrl} allowFullscreen refreshOnLoad />
}
