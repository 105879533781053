import { useMemo } from "react"
import { Box, Loader } from "@tm/components"
import { CisVoucherType } from "@tm/models"
import { ColumnStack } from "../../../../_shared/StyledComponents"
import { useOrderDetails } from "../../../../../data/hooks/useOrderDetails"
import { ErrorMessage } from "../../../../_shared/ErrorMessage"
import StgReturnVoucherDetailsHeader from "../voucher-details-heads/StgReturnVoucherDetailsHeader"
import { Voucher } from "../../../../../data/model"
import StgReturnVoucherItemsTable from "../voucher-items-tables/StgReturnVoucherItemsTable"
import { NoResultHint } from "../../../../_shared/NoResultHint"

type Props = {
    selectedVoucherId: string
    vouchers: Array<Voucher>
    onPrintButtonClick(orderNumber?: string, orderSubNumber?: string, voucherId?: string, warehouseId?: string, voucherTypeId?: CisVoucherType): void
}

export default function StgReturnVoucherDetailsComponent({ selectedVoucherId, vouchers, onPrintButtonClick }: Props) {
    const selectedVoucher = useMemo(() => {
        return vouchers.find((voucher) => voucher.voucherId === selectedVoucherId)
    }, [vouchers, selectedVoucherId])
    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && { voucherId: selectedVoucher.voucherId, voucherTypeId: CisVoucherType.Returns }
    )

    let content = <></>
    if (selectedVoucher) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (orderDetails?.voucherItems?.length) {
            content = (
                <ColumnStack>
                    <StgReturnVoucherDetailsHeader key="VoucherHead" orderDetails={orderDetails} onPrintButtonClick={onPrintButtonClick} />
                    <StgReturnVoucherItemsTable key="VoucherItemsTable" voucherItems={orderDetails.voucherItems} />
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    }

    return <Box flex={1}>{content}</Box>
}
