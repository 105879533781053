import { css, getStyleTheme, StyleProps, withStyle } from '@tm/context-distribution'
import { Loader, PanelSection, Scrollbar } from '@tm/controls'
import { LocalizationProps, withLocalization } from '@tm/localization'
import { bindSpecialReactMethods } from '@tm/utils'
import { Component } from 'react';
import { bem, connector } from '../../../../data/helpers'
import { Criteria } from '../../../../data/models'
import { MainState } from '../../../main'
import { MonoselectFilters, MultiselectFilters } from '../../../_shared'
import { Actions, DetailsState, IActions } from '../../business'

type Props = LocalizationProps & StyleProps<typeof stylesheet> & StoreProps & {
    actions: IActions
}

type StoreProps = {
    selectedCriterias: DetailsState["selectedCriterias"]
    selectedVKNImage: DetailsState["selectedVKNImage"]
    criterias: DetailsState["criterias"]
}

class BaseCriterias extends Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleFilterChange(value: Criteria, isCheckbox?: boolean) {
        const { actions } = this.props

        actions.updateBaseCriterias(value, isCheckbox)
        actions.loadImagePositions()
    }

    handleReset(value: string) {
        const { actions } = this.props
        actions.resetBaseCriteriaGroup(value)
        actions.loadImagePositions()
    }

    renderMultiselectFilters(items: Criteria[], selectedCriterias: Criteria[]) {
        return (
            <MultiselectFilters
                criterias={items}
                selectedCriterias={selectedCriterias}
                onChange={this.handleFilterChange}
                onReset={this.handleReset}
            />
        )
    }

    renderMonoselectFilters(items: Criteria[], selectedCriterias: Criteria[]) {
        return (
            <MonoselectFilters
                criterias={items}
                selectedCriterias={selectedCriterias}
                onChange={this.handleFilterChange}
                onReset={this.handleReset}
            />
        )
    }

    render() {
        const { selectedCriterias, style, criterias: { base, loading } } = this.props
        const hasCriterias = !!base.length
        const groupedCriterias = base.groupBy(x => x.compatGroup)

        if (loading && !base.length)
            return (
                <div className={bem(style.filters, "loading")}  >
                    <Loader />
                </div>
            )

        return (
            <div className={bem(style.filters, loading && "covered")}  >
                <Scrollbar>
                    {hasCriterias && groupedCriterias &&
                        Object.entries(groupedCriterias).map(([key, items], idx) =>
                            <PanelSection className={style.panelSection} key={idx}>
                                {key.length > 0 && this.renderMonoselectFilters(items, selectedCriterias)}
                                {key.length == 0 && this.renderMultiselectFilters(items, selectedCriterias)}
                            </PanelSection>)
                    }
                </Scrollbar>
            </div >
        )
    }
}

function mapStateToProps({ details: state }: MainState): StoreProps {
    return {
        selectedCriterias: state.selectedCriterias,
        selectedVKNImage: state.selectedVKNImage,
        criterias: state.criterias,
    }
}

function stylesheet() {
    const theme = getStyleTheme()
    return css({
        filters: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            // marginRight: theme.margin.m,
            $nest: {
                "&--loading": {
                    justifyContent: "center",
                    alignItems: "center"
                },
                "&--covered": {
                    pointerEvents: "none",
                    opacity: theme.opacity.disabled
                }
            }
        },
        panelSection: {
            padding: theme.margin.s
        }
    })
}

export default connector((withLocalization(withStyle(stylesheet, BaseCriterias))), mapStateToProps, Actions)