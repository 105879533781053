import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { UpdateTotalPricesRawResponse, UpdateTotalPricesRequest } from "./model"

export * from "./model"

export function updateTotalsPrices(calculationDataRequest: UpdateTotalPricesRequest) {
    const url = `${getServiceUrl()}/UpdateTotalPrices`
    const authorization = getStoredAuthorization()
    const body = calculationDataRequest

    return ajax<UpdateTotalPricesRawResponse>({ url, body, method: "POST", authorization }).then((response) => {
        if (!response) {
            throw new Error()
        }
        return response?.updatedTotals
    })
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.businessUrl
}
