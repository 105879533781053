import { Box, Dialog, Stack, Typography, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { StackRow } from "../../../StyledComponents"

type Props = {
    showAdditionalCostsDialog: boolean
    onCloseDialog(): void
    onConfirmDialog(): void
}

export default function AdditionalCostsDialog({ showAdditionalCostsDialog, onConfirmDialog, onCloseDialog }: Props) {
    const { translateText } = useLocalization()

    return (
        <Dialog open={showAdditionalCostsDialog} position="top" skin="warning">
            <Box display="flex">
                <Stack spacing={1}>
                    <Typography variant="h2">{translateText(13111)}</Typography>
                    <Typography>{translateText(13112)}</Typography>
                </Stack>
                <Box display="flex" alignItems="flex-start">
                    <StackRow spacing={0.5}>
                        <Button size="large" onClick={onCloseDialog}>
                            {translateText(584)}
                        </Button>
                        <Button size="large" color="success" onClick={onConfirmDialog}>
                            {translateText(585)}
                        </Button>
                    </StackRow>
                </Box>
            </Box>
        </Dialog>
    )
}
