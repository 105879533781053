import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    const buttonWrapperWidth = "25rem"

    return stylesheet({
        toolbar: {
            marginLeft: margin.xl,
            flex: "1",
        },
        buttonWrapper: {
            width: buttonWrapperWidth,
            alignSelf: "center",
        },
        infoWrapper: {
            width: buttonWrapperWidth,
            alignItems: "center",
        },
        infoContainer: {
            display: "flex",
            flexDirection: "row",
            paddingBottom: "0.7rem",
        },
        infoIcon: {
            flex: "0 0 auto",
            marginRight: margin.m,
        },
        subHeader: {
            display: "flex",
            justifyContent: "space-between",
        },
        wrapper: {
            marginTop: margin.xl,
            textAlign: "center",
        },
        image: {
            height: "60vh",
            maxWidth: "100%",
        },
        pdf: {
            height: "60vh",
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
        },
    })
}
