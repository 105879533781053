import { memo, useEffect, useState } from "react"
import { Button, FileDropField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"

import { getComponentStyles } from "./styles"
import { VrcLocalFile } from "../../../component"

type Props = {
    onFileUpload(file: VrcLocalFile): void
}

export const FileUpload = memo<Props>((props) => {
    const classNames = getComponentStyles()

    const { translateText } = useLocalization()
    const [clipboardError, setClipboardError] = useState<boolean>(false)

    const handleLoad = (fileData: string) => {
        fileData && props.onFileUpload({ data: fileData, isImage: fileData.startsWith("data:image/"), rotation: 0 })
    }

    useEffect(() => {
        if (clipboardError) {
            const timer = setTimeout(() => {
                setClipboardError(false)
            }, 5000)

            return () => clearTimeout(timer)
        }
    }, [clipboardError])

    return (
        <div className={classNames.container}>
            <FileDropField
                allowPaste
                className={classNames.fileDropField}
                allowedFileTypes={["jpg", "png", "pdf"]}
                maxFileSize={100000}
                onLoad={handleLoad}
            >
                <div className={classNames.uploadContainer}>
                    <Button className={classNames.button}>{translateText(1163)}</Button>
                    <Text>{translateText(12789)}</Text>
                </div>
            </FileDropField>
        </div>
    )
})
