import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { withStoreProvider } from "../../data/helpers"

const widget: BundleComponent = {
    name: "widget",
    component: withStoreProvider(component as any),
}

export default widget
