import { GetCarExtendedInfoRequest } from "../../../../data/repositories"
import { MainState } from "../../../main"

export function createCarExtendedRequest(state: MainState): GetCarExtendedInfoRequest | undefined {
    const { summary: { selectedMmtGroup }, details: { selectedValues }, manager: { vehicle } } = state

    if (vehicle && selectedMmtGroup?.mmtList && selectedMmtGroup.selectedErePosition) {
        return {
            ...selectedValues,
            certificateOk: true,
            countryOk: true,
            kTypOk: true,
            kTypNr: vehicle.tecDocTypeId,
            erePosition: selectedMmtGroup?.selectedErePosition,
            mmtList: selectedMmtGroup.mmtList,
            ...(!!selectedValues.initialReg && { initialRegString: selectedValues.initialReg.toDateString() })
        }
    }
}