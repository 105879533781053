import { ActionDispatch } from "@tm/morpheus"
import { HistoryState } from "./model"
import { BundleActionType } from "../../../business"

export * from "./model"

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: HistoryState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): HistoryState {
    switch (action.type) {
        case "DETAILSHEAD_RESET": {
            return {
                ...state,
                detailsResponse: undefined,
            }
        }
        case "DETAILSHEAD_LOADED": {
            return {
                ...state,
                detailsResponse: action.payload,
            }
        }
        default:
            break
    }

    return state
}

export type IActions = typeof Actions

export const Actions = {}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>, _getState: () => HistoryState) {
    switch (action.type) {
        case "DETAILSHEAD_LOADED":
        case "DETAILSHEAD_RESET":
            dispatch(action)
            break
        default:
            break
    }
}
