import { channel } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { IDOPModel } from "../../models"
import { getBundleParams } from "../../params"

export function orderPlates(req: IDOPModel) {
    const url = `${getServiceUrl()}/OrderPlates`
    const authorization = getStoredAuthorization()
    const body = { dataIn: req }

    return ajax({ url, body, method: "POST", authorization }).then((data: { dataOut: IDOPModel }) => {
        if (data.dataOut?.errorMessage) {
            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: data.dataOut.errorMessage, skin: "danger" })
            throw data.dataOut.errorMessage
        } else if (data.dataOut) {
            return data.dataOut
        } else {
            throw new Error("Error")
        }
    })
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.serviceUrl
}
