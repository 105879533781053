import { Box, Button, Icon, styled } from "@tm/components"
import { Card } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ActivatableModule, ModuleOption, ModulePackage } from "@tm/models"
import DemoButtons from "./DemoButtons"

type Props = {
    module: ActivatableModule
    handleActivateModule: (module: ActivatableModule, moduleOption?: ModuleOption, modulePackage?: ModulePackage) => void
}

export default function Module({ module, handleActivateModule }: Props) {
    const { translate, languageId } = useLocalization()
    const versionActive = module.moduleOptions.some((option) => option.active)

    return (
        <Box key={module.id} marginTop="1rem" display="flex" flexDirection="column" width="fit-content">
            <Card>
                <Icon
                    height="1.75em"
                    width="1.75em"
                    sx={{ position: "absolute", right: 0, margin: ".5rem .5rem 0 0" }}
                    name={versionActive ? "unlock" : "lock"}
                />
                <Box display="flex" flexDirection="column" marginBottom="1rem">
                    <object aria-label="" style={{ width: "302px", height: "469px" }} data={module.shortDescriptionUrl?.replace("{0}", languageId)}>
                        ...
                    </object>
                    <Box display="flex" alignItems="center" flexDirection="column" flexWrap="wrap" rowGap="0.5em">
                        <StyledMoreInfoBtn
                            sx={{ visibility: module.explanationVideoUrl ? "visible" : "hidden" }}
                            href={module.explanationVideoUrl}
                            target="_blank"
                            startIcon={<Icon name="video" />}
                        >
                            {translate(12841)}
                        </StyledMoreInfoBtn>
                        <StyledMoreInfoBtn
                            disabled={!module.longDescriptionUrl}
                            href={module.longDescriptionUrl?.replace("{0}", languageId)}
                            target="_blank"
                        >
                            {translate(12597)}
                        </StyledMoreInfoBtn>
                    </Box>
                </Box>
            </Card>
            <DemoButtons module={module} handleActivateModule={handleActivateModule} />
        </Box>
    )
}

const StyledMoreInfoBtn = styled(Button)({
    marginLeft: "0.5rem",
    height: "2em",
    display: "flex",
    justifyContent: "center",
})
