import { memo, useState, useCallback } from "react"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { TmaHelper, encodeUniqueId, uniqueId } from "@tm/utils"
import { ECounterType } from "@tm/models"
import { getBundleParams } from "../../utils"

type Props = {
    skin?: "warning" | "danger" | "success" | "highlight" | "primary"
}

const getUrl = () => `/${encodeUniqueId(uniqueId())}`

const CreateButtonComponent = memo<Props>(({ skin }) => {
    const { translateText } = useLocalization()

    // Component has to be re-rendered after opening the url, to create a new url. So we just put the url in the state.
    const [url, setUrl] = useState<string>(getUrl)
    const handleClick = useCallback(() => {
        setUrl(getUrl())
        TmaHelper.GeneralCountEvent.Call(ECounterType.NewWorktask)
    }, [])

    const { hideWorktaskCreateButton } = getBundleParams()
    if (hideWorktaskCreateButton) {
        return null
    }

    return (
        <Button
            skin={skin || "highlight"}
            className="worktask-create-button"
            title={translateText(357)}
            linkTo={url}
            onClick={handleClick}
            icon="add"
        />
    )
})

export default CreateButtonComponent
