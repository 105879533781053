import { OrderItem, Item } from "@tm/models"
import { MessageBus, Channel } from "@tm/hermes"

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel("BUNDLE")
}

export enum PostCreateActionType {
    ShowPdf,
    SendChatMessage,
    PublishLink,
}

export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = {
    WORKTASK_BASKET_PARTS_CHANGING: { workTaskId: string; partIds: Array<string> }
} & { WORKTASK_BASKET_PART_CHANGED: { workTaskId: string; partId: string } } & { ENABLE_COST_ESTIMATION: {} } & { ANY_ORDERS_SENDING: {} } & {
    ALL_ORDERS_SENT: {}
} & {
    COST_ESTIMATION_LINK_CREATED: { link: string; additionalData?: { pin?: string } }
} & {
    COST_ESTIMATION_LINK_CREATION_ERROR: { error?: Error }
}

export function mapOrderItemToItem(orderItem: OrderItem): Item {
    return { id: orderItem.id, version: orderItem.version }
}
