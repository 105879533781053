import { Badge, Box, Loader, Switch, SxProps, Toolbar } from "@tm/components"
import { useUser, useErpInfoEnabled } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { TmaHelper, UIAHtmlAttribut } from "@tm/utils"
import { ECounterType } from "@tm/models"

type Props = {
    showLabelOnTop?: boolean
    labelTextId?: string | number
    loaderSx?: SxProps
    toolbarSx?: SxProps
    uIAHtmlAttribut?: UIAHtmlAttribut
}

export default function PurchasePriceSwitch(props: Props) {
    const { showLabelOnTop, labelTextId, loaderSx, toolbarSx, uIAHtmlAttribut } = props
    const { translateText } = useLocalization()
    const { userSettings, userContext, setUserSetting, userSettingsUpdating } = useUser() ?? {}
    const [loading, setLoading] = useState(false)
    const isErpInfoEnabled = useErpInfoEnabled()

    useEffect(() => {
        setLoading(false)
    }, [userSettings?.showPurchasePrice])

    function handleSwitchPurchasePrice(showPurchasePrice: boolean) {
        setLoading(true)
        setUserSetting?.("SHOW_PURCHASE_PRICE", showPurchasePrice)
        TmaHelper.GeneralCountEvent.Call(ECounterType.BasketShowInvoicePrice, showPurchasePrice)
    }

    if (userContext.parameter.purchasePricesDisabled || !isErpInfoEnabled) {
        return null
    }

    return (
        <Box className="toolbar" sx={toolbarSx}>
            <Box className="toolbar_inner">
                {showLabelOnTop && (
                    <Box className="toolbar_title subtitle subtitle--xs" sx={{ marginRight: 1 }}>
                        {translateText(labelTextId || 1132)}
                    </Box>
                )}
                <Toolbar sx={{ minHeight: 0, marginTop: 0.2 }} disableGutters variant="dense">
                    <Box sx={{ minHeight: 40 }}>
                        {!loading && (
                            <Switch
                                formControlLabelSx={{ marginRight: 0 }}
                                checked={userSettings?.showPurchasePrice ?? false}
                                onChange={(e) => handleSwitchPurchasePrice(e.target.checked)}
                                label={!showLabelOnTop ? translateText(labelTextId || 1132) : undefined}
                                disabled={userSettingsUpdating}
                                {...uIAHtmlAttribut}
                            />
                        )}
                        {loading && <Badge badgeContent={<Loader size="small" />} sx={loaderSx || { top: 15, left: 24, right: 1 }} />}
                    </Box>
                </Toolbar>
            </Box>
        </Box>
    )
}
