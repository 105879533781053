import { IBundle } from "@tm/morpheus"
import UserInfo from "./components/user-info"
import UserSettings from "./components/user-settings"
import UserSettingsButton from "./components/user-settings-button"
import UserSettingsMenu from "./components/usersettings-menu"
import { initSettingsBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [UserInfo, UserSettings, UserSettingsButton, UserSettingsMenu],
    init: initSettingsBundle,
}

export default bundle
