import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { notUndefinedOrNull, useErpConfig } from "@tm/utils"
import { Article, ArticleErpInfo, ErpInformation, ErpInformationResponse, ErpSystemConfig, GetErpInfosRequest } from "@tm/models"
import { chunk as createChunks, uniqBy } from "lodash"
import { useEffect, useMemo } from "react"
import { getErpInfos } from "../../../../../../erp/src/data"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { DirectSearchStartParams, ErpInfosData, ErpSystemLoadingGroup, StartParams } from "../../models"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ArticleTradeReferences } from "../useTradeReferences"
import { useVehicle } from "../useVehicle"
import { ArticleErpInfoFactory, createGetErpInfosRequest } from "./factories"

type ErrorResponses = Omit<ErpInformationResponse, "items"> & { request?: GetErpInfosRequest }

type UseErpInfosProps = {
    articles: Article[]
    erpInfos: ErpInfosData["erpInfos"]
    setErpInfos: ErpInfosData["setErpInfos"]
    isEnabled: boolean
    startParams?: StartParams
    tradeReferences?: ArticleTradeReferences[]
}

export function useErpInfos({ articles, isEnabled, startParams, tradeReferences, erpInfos, setErpInfos }: UseErpInfosProps): ErpInfosData {
    const searchQuery: string | undefined = (startParams as DirectSearchStartParams)?.query
    const { erpPageSize } = useArticleListConfiguration()
    const vehicle = useVehicle()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const defaultErpSystem = useDefaultErpSystem()
    const erpConfig = useErpConfig()
    const { erpSystemConfigs, useOrderByDistributor } = erpConfig

    useEffect(
        function prepareRequests() {
            if (!isEnabled || !defaultErpSystem || !articles.length) {
                return
            }

            setErpInfos((prev) => {
                let mapped = prev

                let itemsToLoad: ArticleErpInfo[] = []

                if (defaultErpSystem.tradeReferenceId && tradeReferences?.length) {
                    // uniqBy is needed because we might have duplicate articles with the same internal id
                    const articlesToLoad = articles.filter(
                        (x) =>
                            x.requestErpInfo &&
                            tradeReferences.some(
                                ([referenceKey, referenceNumbers]) =>
                                    x.supplierArticleNo === referenceKey.supplierArticleNo &&
                                    referenceNumbers?.some((refNumber) => refNumber.erpSystemId === defaultErpSystem.id)
                            )
                    )
                    itemsToLoad = uniqBy(
                        tradeReferences
                            .filter(([, referenceNumbers]) => referenceNumbers?.some((refNumber) => refNumber.erpSystemId === defaultErpSystem.id))
                            .map((reference) => createArticleErpInfo(articlesToLoad, reference, defaultErpSystem))
                            .filter(notUndefinedOrNull)
                            .filter((item) => !mapped.some((prevItem) => item.key.id === prevItem.key.id)),
                        (x) => x.key.id
                    )
                } else {
                    itemsToLoad = uniqBy(
                        articles
                            .filter((x) => x.requestErpInfo)
                            .map<ArticleErpInfo>((article) => ArticleErpInfoFactory.createQueued(article, defaultErpSystem))
                            .filter((item) => !mapped.some((prevItem) => item.key.id === prevItem.key.id)),
                        (x) => x.key.id
                    )
                }

                if (itemsToLoad.length) {
                    mapped = [...mapped, ...itemsToLoad]
                }
                return mapped
            })
        },
        [isEnabled, defaultErpSystem, articles, setErpInfos, tradeReferences]
    )

    // creates the erp request for the alternative erp system, when MRE is active

    // add extra erp request for the alternative erp system, when MRE is active. MRE is active when useOrderByDistributor is true.
    useEffect(
        function addAdditionalRequests() {
            if (!useOrderByDistributor || !tradeReferences?.length || !erpSystemConfigs || erpSystemConfigs.length < 2) {
                return
            }

            const alternativeErpSystem = erpSystemConfigs[1]

            const articleErpInfosToLoad = tradeReferences
                .filter(([, tradeReferenceNumbers]) => tradeReferenceNumbers?.some((refNumber) => refNumber.erpSystemId === alternativeErpSystem.id))
                .map((references) => createArticleErpInfo(articles, references, alternativeErpSystem))
                .filter(notUndefinedOrNull)

            if (articleErpInfosToLoad.length) {
                setErpInfos((prev) => {
                    let newList = prev
                    articleErpInfosToLoad.forEach((erpToLoad) => {
                        if (!newList.some(({ key }) => key.id === erpToLoad.key.id)) {
                            newList = [...newList, erpToLoad]
                        }
                    })
                    return newList
                })
            }
        },
        [tradeReferences, useOrderByDistributor, articles, erpSystemConfigs, setErpInfos]
    )

    useEffect(
        function loadErpInformation() {
            async function fetchErpInformation() {
                if (!defaultErpSystem) {
                    return
                }

                const itemsToLoad = erpInfos.filter((x) => x.state === "queued")

                if (!itemsToLoad.length) {
                    return
                }

                setErpInfos((prev) => {
                    let changed = false

                    const mapped = prev.map<ArticleErpInfo>((prevItem) => {
                        const itemToLoad = itemsToLoad.find((x) => x.key.id === prevItem.key.id)

                        if (!itemToLoad) {
                            return prevItem
                        }

                        changed = true

                        return ArticleErpInfoFactory.createLoading(prevItem)
                    })

                    return changed ? mapped : prev
                })

                const erpSystemChunks = createErpSystemChunks(itemsToLoad, erpPageSize, erpSystemConfigs)

                const responses = await Promise.allSettled(
                    erpSystemChunks.map(({ chunk, erpSystemId }) =>
                        getErpInfos(createGetErpInfosRequest(chunk, erpSystemId, searchQuery, telesalesCustomerNo, vehicle))
                    )
                )

                const errorResponses: ErrorResponses[] = []
                const responseItems: ErpInformation[] = []

                responses.forEach((response) => {
                    if (response.status === "rejected") {
                        errorResponses.push({
                            hasErrors: true,
                            errorText: typeof response.reason === "string" ? response.reason : undefined,
                            request: response.reason.data,
                        })
                    } else if (response.value.hasErrors || !response.value.items?.length) {
                        errorResponses.push({ ...response.value, request: response.value.request })
                    } else {
                        responseItems.push(...response.value.items)
                    }
                })

                if (!responseItems.length && !errorResponses.length) {
                    return
                }

                setErpInfos((prev) => {
                    let changed = false
                    const mapped: ArticleErpInfo[] = [...prev]

                    responseItems.forEach((item) => {
                        const index = mapped.findIndex((prevItem) => prevItem.request.itemId === item.itemId)

                        if (index === -1) {
                            return
                        }

                        changed = true

                        mapped[index] = ArticleErpInfoFactory.createFromResponse(mapped[index], item)

                        // // Store the erp response under the division quantity to prevent a second request
                        const { division = 0, requestedValue = 0 } = item.quantity ?? {}
                        if (division > 1 && requestedValue !== requestedValue.ceil(division)) {
                            mapped.push(ArticleErpInfoFactory.createWithDifferentQuantity(mapped[index], division))
                        }
                    })

                    errorResponses.forEach((errorResponse) => {
                        errorResponse.request?.items.forEach((requestItem) => {
                            const index = mapped.findIndex((prevItem) => prevItem.request.itemId === requestItem.itemId)

                            if (index === -1) {
                                return
                            }

                            changed = true

                            mapped[index] = ArticleErpInfoFactory.createFromError(mapped[index], errorResponse)
                        })
                    })

                    return changed ? mapped : prev
                })
            }
            fetchErpInformation()
        },
        [defaultErpSystem, erpInfos, searchQuery, telesalesCustomerNo, vehicle?.id, setErpInfos, erpPageSize, erpSystemConfigs, vehicle]
    )

    useEffect(
        function resetErpInfos() {
            setErpInfos([])
            return () => setErpInfos([])
        },
        [startParams, setErpInfos]
    )

    return useMemo(
        () => ({
            isReady: !erpSystemConfigs?.length || (!!articles.length && !!erpInfos.some((x) => x.state === "success" || x.state === "error")),
            erpInfos,
            setErpInfos,
            erpConfig,
        }),
        [articles, erpInfos, erpSystemConfigs, erpConfig]
    )
}

function createArticleErpInfo(articles: Article[], references: ArticleTradeReferences, erpSystem: ErpSystemConfig): ArticleErpInfo | undefined {
    const [supplierArticleWithId, tradeReferenceNumbers] = references
    const article = articles.find(
        (x) => x.supplier.id === supplierArticleWithId.supplierId && x.supplierArticleNo === supplierArticleWithId.supplierArticleNo
    )
    const traderReferenceNumber = tradeReferenceNumbers?.find((x) => x.erpSystemId === erpSystem.id)
    if (traderReferenceNumber && article) {
        const articleErpInfo = ArticleErpInfoFactory.createQueued(article, erpSystem, traderReferenceNumber.traderArticleNumber)
        return articleErpInfo
    }
}

// Group by erpSystemId and create chunks for every group using the articleCount which is defined for the related erpSystem
function createErpSystemChunks(itemsToLoad: ArticleErpInfo[], defaultPageSize: number, erpSystemConfigs?: ErpSystemConfig[]) {
    return itemsToLoad
        .reduce<ErpSystemLoadingGroup[]>((list, item) => {
            let group = list.find((x) => x.erpSystemId === item.key.erpSystemId)
            if (!group) {
                group = {
                    erpSystemId: item.key.erpSystemId,
                    chunkSize: erpSystemConfigs?.find((config) => config.id === item.key.erpSystemId)?.erpRequestArticleCount ?? defaultPageSize,
                    items: [],
                }
                list.push(group)
            }
            group.items.push(item.request)
            return list
        }, [])
        .flatMap(({ chunkSize, erpSystemId, items }) =>
            createChunks(items, chunkSize).map((chunk) => ({
                erpSystemId,
                chunk,
            }))
        )
}
