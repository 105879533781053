import { useLocalization } from "@tm/localization"
import { Button, Text } from "@tm/controls"
import { History } from "history"
import { StartWidgetState } from "../business"
import { Events } from "./events"

type Props = Required<Pick<StartWidgetState, "events" | "types">> & {
    createUrl: string
    history: History
    centerRoute: string
}
export function EventList(props: Props) {
    const { translate } = useLocalization()
    const filteredEvents = props.events.filter((x) => x.startDateTime.getTime() > Date.now() - 60 * 60 * 1000)

    if (!filteredEvents.length) {
        return (
            <div className="empty-list">
                <Text modifiers={["block"]}>
                    {translate(1636) /** Keine Termine gefunden. */}
                    <br />
                    {translate(1637) /** Wollen Sie einen neuen Termin anlegen? */}
                </Text>
                <Button skin="highlight" linkTo={props.createUrl}>
                    {translate(1638) /** Wollen Sie einen neuen Termin anlegen? */}
                </Button>
            </div>
        )
    }

    return (
        <div className="event-list">
            <Events {...props} events={filteredEvents} history={props.history} centerRoute={props.centerRoute} />
        </div>
    )
}
