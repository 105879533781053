export const openPartslifeInNewWindow = (url: string, contentRef?: React.RefObject<HTMLDivElement>) => {
    const navigationDom = document?.querySelector(".master")

    if (!contentRef?.current || !navigationDom) {
        return
    }

    const options = `height=${contentRef.current.offsetHeight - navigationDom.clientHeight},width=${
        contentRef.current.offsetWidth
    },location=0,resizable=1,scrollbars=1,left=${window.screenLeft + 15},top=${window.screenTop + 260},status=yes`

    window.open(url, "_blank", options)
}
