import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetFeedbackResult(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { getFeedbackResult } = data

    if (getFeedbackResult !== null && getFeedbackResult !== undefined) {
        channel("GLOBAL").publish("MVC/FEEDBACK_RESULT", { getFeedbackResult })
    }
}
