import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { Box } from "@tm/components"
import { TopManufacturer } from "../_shared/TopManufacturer"
import { VehicleSearchFieldSwitch } from "../_shared/VehicleSearchFieldSwitch"

type Props = {
    handleStartSearch: (query: string) => void
    specializedSearchConfig?: VehicleLookupConfig
}

function CommercialCard(props: Props) {
    const { specializedSearchConfig } = props

    return (
        <Box display="flex" flexDirection="column" width="100%">
            <VehicleSearchFieldSwitch
                {...props}
                size="small"
                onStartSearch={props.handleStartSearch}
                vehicleType={VehicleType.CommercialVehicle}
                specialLicensePlatesProps={{ registrationNoType: specializedSearchConfig?.lookupTypeId }}
            />
            <TopManufacturer vehicleType={VehicleType.CommercialVehicle} />
        </Box>
    )
}

export default CommercialCard
