import { em } from "csx"
import { useSelector } from "react-redux"
import { useStyle } from "@tm/context-distribution"
import { DateField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { classes, initSelector } from "@tm/utils"
import { Actions } from "../../bussiness"
import { dateToString, hasFieldError, stringToDate } from "../../bussiness/helpers"
import { DocumentsState, EDocumentIds } from "../../bussiness/model"
import { documentsIdsSelector, fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { Field } from "../common"

const selector = initSelector(fieldsSelector, documentsIdsSelector)

export default function DocumentAdressesTab() {
    const { translateText } = useLocalization()

    const [model, documentNamesIds] = useSelector(selector)
    const actions = useActions(Actions, "updateField")

    if (!model) {
        return null
    }

    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText))

    const handleDateFieldChange = (path: Array<string>, date: Date) => {
        actions.updateField(path, dateToString(date))
    }

    return (
        <>
            {documentNamesIds.includes(EDocumentIds.GVA) && (
                <Field.Container>
                    <Field.Item m={6} title={translateText(1361)} mandatory>
                        <TextField
                            multiline
                            showClear
                            value={model.supplierInfo.postalAddress}
                            className={classes(style.adressInput, hasFieldError(modelState["supplierInfo.postalAddress"]))}
                            onChange={(value) => actions.updateField(["supplierInfo", "postalAddress"], value)}
                        />
                    </Field.Item>

                    <Field.Item m={6} title={translateText(93)}>
                        <TextField
                            showClear
                            multiline
                            className={style.adressInput}
                            value={model.supplierInfo.administrator}
                            onChange={(value) => actions.updateField(["supplierInfo", "administrator"], value)}
                        />
                    </Field.Item>
                </Field.Container>
            )}

            {documentNamesIds.includes(EDocumentIds.Returns) && (
                <Field.Container>
                    <Field.Item m={12} title={translateText(1386)} mandatory>
                        <TextField
                            multiline
                            showClear
                            value={model.customerInfo.postalAddress}
                            className={classes(style.adressInput, hasFieldError(modelState["customerInfo.postalAddress"]))}
                            onChange={(value) => actions.updateField(["customerInfo", "postalAddress"], value)}
                        />
                    </Field.Item>
                </Field.Container>
            )}

            {(documentNamesIds.includes(EDocumentIds.GVA) || documentNamesIds.includes(EDocumentIds.Batterie)) && (
                <Field.Container>
                    <Field.Item m={(documentNamesIds.includes(EDocumentIds.GVA) && 6) || 12} title={translateText(1386)} mandatory>
                        <TextField
                            multiline
                            showClear
                            value={model.repairShopInfo.postalAddress}
                            className={classes(style.adressInput, hasFieldError(modelState["repairShopInfo.postalAddress"]))}
                            onChange={(value) => actions.updateField(["repairShopInfo", "postalAddress"], value)}
                        />
                    </Field.Item>

                    {documentNamesIds.includes(EDocumentIds.GVA) && (
                        <Field.Item m={6} title={translateText(93)}>
                            <TextField
                                multiline
                                className={style.adressInput}
                                showClear
                                value={model.repairShopInfo.administrator}
                                onChange={(value) => actions.updateField(["repairShopInfo", "administrator"], value)}
                            />
                        </Field.Item>
                    )}
                </Field.Container>
            )}

            {documentNamesIds.includes(EDocumentIds.GVA) && (
                <Field.Container>
                    <Field.Item m={6} title={translateText(1385)} mandatory>
                        <TextField
                            multiline
                            showClear
                            value={model.traderInfo.postalAddress}
                            className={classes(style.adressInput, hasFieldError(modelState["traderInfo.postalAddress"]))}
                            onChange={(value) => actions.updateField(["traderInfo", "postalAddress"], value)}
                        />
                    </Field.Item>

                    <Field.Item m={6} title={translateText(93)}>
                        <TextField
                            showClear
                            multiline
                            className={style.adressInput}
                            value={model.traderInfo.administrator}
                            onChange={(value) => actions.updateField(["traderInfo", "administrator"], value)}
                        />
                    </Field.Item>
                </Field.Container>
            )}

            {documentNamesIds.includes(EDocumentIds.ADR) && (
                <Field.Container>
                    <Field.Item m={12} title={translateText(107)} mandatory>
                        <TextField
                            multiline
                            maxLength={50}
                            showClear
                            value={model.customerInfo.postalAddress}
                            className={classes(style.adressInput, hasFieldError(modelState["customerInfo.postalAddress"]))}
                            onChange={(value) => actions.updateField(["customerInfo", "postalAddress"], value)}
                        />
                    </Field.Item>
                </Field.Container>
            )}

            <Field.Container>
                <Field.Item m={6} title={translateText(98)}>
                    <DateField value={stringToDate(model.currentDate)} onChange={(value) => handleDateFieldChange(["currentDate"], value)} />
                </Field.Item>
            </Field.Container>
        </>
    )
}

const style = useStyle({
    adressInput: {
        $nest: {
            textarea: {
                height: em(5),
            },
        },
    },
})(DocumentAdressesTab)
