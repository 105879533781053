import { styled } from "@tm/components"
import { Dialog } from "@tm/controls"

export const StyledInstantDialog = styled(Dialog)(() => ({
    ".dialog-prompt__text": {
        fontSize: "1rem",
        lineHeight: 1.3,
    },
}))

export const StyledDialog = styled(Dialog)(() => ({
    ".dialog-prompt__pre-text": {
        fontSize: "1.2rem",
        lineHeight: 1.3,
        marginBottom: "1rem",
    },
    ".dialog-prompt__text": {
        fontSize: "1rem",
        lineHeight: 1.3,
        marginBottom: "1rem",
    },
    ".dialog-prompt__close": {
        display: "none",
    },
    ".dialog-prompt__buttons": {
        $nest: {
            "button:first-child": {
                order: 2,
            },
            ".btn + .btn": {
                marginLeft: 0,
                marginRight: ".2em",
            },
            ".icon": {
                display: "none",
            },
        },
    },
}))
