import { channel } from "@tm/models"
import { useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import { showTotalNumbers } from ".."

const KEY = "vouchers__useTotalNumbers"
export function useTotalNumbers(workTaskId?: string) {
    const queryClient = useQueryClient()
    const { data, isLoading } = useQuery([KEY, workTaskId], () => (workTaskId ? showTotalNumbers(workTaskId) : undefined), {
        enabled: !!workTaskId,
        staleTime: 10 * 1000, // 10 seconds
    })

    useEffect(() => {
        return channel("WORKTASK", workTaskId).subscribe("BASKET/ORDER_SENT", () => {
            queryClient.resetQueries([KEY, workTaskId])
        })
    }, [workTaskId, queryClient])

    return { totalNumbers: data, totalsLoading: isLoading }
}
