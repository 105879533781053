import { styled, Box } from "@tm/components"

export const StyledBox = styled(Box)({
    "& .tooltip__content": {
        whiteSpace: "nowrap",
    },
    "& .erp-info__availability__wrapper": {
        display: "flex",
        alignItems: "center",
    },
    "& .loader": {
        margin: "0 0.25em",
    },
})
