import { useEffect } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { ImportOrderItemDto, VoucherType } from "@tm/models"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { ShowDetailsResponse as ShowCostEstimationDetailsResponse } from "@tm/data/vouchers/costEstimations"
import { bundleChannel } from "../../../../bundle-channel"
import { ExtendedOrderVoucherItem, ShowOrderDetailsResponse } from "../../../../data/model"
import { getBundleParams } from "../../../../utils"
import { useSetActionButtonStatus } from "../../../../data/hooks/useActionButtonStatus"
import { useBasketImports } from "../../../../../../basket/src/hooks/basketState/useBasketImports"
import { mapVoucherPartInPartItem, mapVoucherWorkInWorkItem } from "../../../../data/mapper"

function mapToImportOrderItemDto(item: ExtendedOrderVoucherItem): ImportOrderItemDto {
    return {
        articleInfo: item.articleInfo,
        quantity: item.confirmedQuantity,
        memo: item.memo,
        articleInfoType: item.articleInfoType,
        foundBySearchTerm: item.foundBySearchTerm,
        fittingSide: item.fittingSide,
        itemRole: item.itemRole,
        linkedItems: item.linkedItems?.map(mapToImportOrderItemDto),
    }
}

export function useOrderActionButtonsConnection(orderDetails: ShowOrderDetailsResponse | undefined, historyPush: (url: string) => void) {
    const { workTaskId, createWorkTask } = useWorkTask() || {}
    const { importVoucherItems } = useBasketImports()
    useEffect(() => {
        if (orderDetails?.orderItemsByWorkTask && createWorkTask) {
            return bundleChannel().subscribe("ADD_ORDER_VOUCHER_TO_BASKET", async ({ newWorkTask }) => {
                let newWorkTaskId = workTaskId

                if (newWorkTask) {
                    newWorkTaskId = uniqueId()
                    await createWorkTask({ workTaskId: newWorkTaskId, subPage: getBundleParams().basketRouteWithoutWorkTaskId })
                } else if (newWorkTaskId) {
                    historyPush(renderRoute(getBundleParams().basketRoute, { workTaskId: encodeUniqueId(newWorkTaskId) }))
                } else {
                    return
                }

                const orderItems: Array<ImportOrderItemDto> = []

                orderDetails.orderItemsByWorkTask.forEach((x) => {
                    x.orderVoucherItems.forEach((item) => {
                        orderItems.push(mapToImportOrderItemDto(item))
                    })
                })

                if (orderItems.length) {
                    importVoucherItems({ workTaskId: newWorkTaskId, orderItems })
                }
            })
        }
    }, [orderDetails, workTaskId, createWorkTask, historyPush, importVoucherItems])

    const setOrderActionButtonStatus = useSetActionButtonStatus(VoucherType.Order)
    const hasParts = !!orderDetails?.totals.numberOfParts
    useEffect(() => {
        setOrderActionButtonStatus(hasParts)
    }, [hasParts, setOrderActionButtonStatus])
}

export function useCostEstimationActionButtonsConnection(costEstimationDetails: ShowCostEstimationDetailsResponse | undefined) {
    const { createWorkTask } = useWorkTask() || {}
    const { importVoucher } = useBasketImports()

    useEffect(() => {
        if (costEstimationDetails && createWorkTask) {
            return bundleChannel().subscribe("ADD_COST_ESTIMATION_VOUCHER_TO_BASKET", async ({ includeRepairTimes }) => {
                const workTaskId = uniqueId()
                const vehicleId = costEstimationDetails.vehicle?.id
                const customerId = costEstimationDetails.customer?.id
                await createWorkTask({
                    workTaskId,
                    vehicle: vehicleId,
                    customer: customerId,
                    subPage: includeRepairTimes
                        ? getBundleParams().costEstimateRouteWithoutWorkTaskId
                        : getBundleParams().basketRouteWithoutWorkTaskId,
                })

                if (costEstimationDetails.costEstimation?.parts?.length || costEstimationDetails.costEstimation?.works?.length) {
                    importVoucher({
                        workTaskId,
                        vehicleId,
                        customerId,
                        voucherParts: costEstimationDetails.costEstimation?.parts?.map(mapVoucherPartInPartItem),
                        voucherWorks: includeRepairTimes ? costEstimationDetails.costEstimation?.works?.map(mapVoucherWorkInWorkItem) : undefined,
                        currencyCode: costEstimationDetails.costEstimation.currencyCode,
                        currencySymbol: costEstimationDetails.costEstimation.currencySymbol,
                        vehicleImage: costEstimationDetails.vehicle?.vehicleImage,
                    })
                }
            })
        }
    }, [costEstimationDetails, createWorkTask, importVoucher])

    const setCostEstimationActionButtonStatus = useSetActionButtonStatus(VoucherType.CostEstimation)
    const hasParts = !!costEstimationDetails?.costEstimation?.parts?.length
    useEffect(() => {
        setCostEstimationActionButtonStatus(hasParts)
    }, [hasParts, setCostEstimationActionButtonStatus])
}
