import { ETyresSeason, TyresSeason } from "@tm/models"
import { getBundleParams } from "../../utils"

export const getCurrentSeason = (shortForm?: boolean) => {
	const { defaultSeason } = getBundleParams()

	const date = new Date()
	let season: TyresSeason = ETyresSeason.winter
	const currentMonth = date.getMonth() + 1

	if (currentMonth >= 3 && currentMonth < 9) {
		season = ETyresSeason.summer
	}

	season = defaultSeason || season

	if (shortForm) {
		return season.charAt(0).toUpperCase()
	}

	return season
}

