import { Box, Tooltip } from "@tm/components"
import { renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { Icon, Text } from "@tm/controls"
import { useHistory, useLocation, useParams } from "react-router"
import { getBundleParams } from "../../../utils"

export type NextTourVariation = "tour-with-date" | "my-account" | "wm-telesales" | undefined

type Props = {
    text: string | undefined
    disableClick?: boolean
    variation: NextTourVariation
}

export default function NextTourDisplayComponent({ text, variation, disableClick }: Props) {
    const { translateText } = useLocalization()
    const matchParams = useParams<{ workTaskId?: string }>()
    const history = useHistory()
    const location = useLocation()
    const workTaskId = useWorkTask()?.workTaskId

    if (!text) {
        return null
    }
    const cisSummaryUrl = getBundleParams().summaryUrl

    const handleClick = () => {
        history.push(renderRoute(cisSummaryUrl, { ...matchParams, workTaskId: encodeUniqueId(workTaskId || uniqueId()) }))
    }

    const isSelected = matchParams.workTaskId && location.pathname === renderRoute(cisSummaryUrl, matchParams)

    let tooltipContent
    if (variation === "my-account") {
        tooltipContent = translateText(1084)
    } else if (variation === "wm-telesales") {
        tooltipContent = `${translateText(12977)} ${text}`
        text = text.substring(text.length - 5)
    } else {
        tooltipContent = disableClick ? "" : translateText(491)
    }

    let content
    if (variation === "tour-with-date") {
        content = (
            <div
                className={`next-tour--wm${isSelected ? " is-selected" : ""}${disableClick ? " not-clickable" : ""}`}
                onClick={disableClick ? undefined : handleClick}
            >
                <div className="next-tour--wm__title">
                    <Icon name="truck-simple" size="l" />
                    <Text modifiers="light">{translateText(433)}</Text>
                </div>
                <Text modifiers={["light", "strong"]}>{text}</Text>
            </div>
        )
    } else {
        content = (
            <div
                className={`next-tour${isSelected ? " is-selected" : ""}${disableClick ? " not-clickable" : ""}`}
                onClick={disableClick ? undefined : handleClick}
            >
                <Text modifiers="light" className="time-box">
                    {text}
                </Text>
                <div className="next-tour__icon-container">
                    <Icon name="next-tour" size="xl" />
                </div>
            </div>
        )
    }

    return (
        <Box className="bundle-cis" alignSelf="center">
            <Tooltip title={tooltipContent}>{content}</Tooltip>
        </Box>
    )
}
