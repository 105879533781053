import { Dropdown } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CarMainModelSeries, VehicleType } from "@tm/models"
import { Suspense } from "react"

import { useMainModelSeriesByManufacturer } from "../../../../data/hooks"
import { useSelections } from "../../business/hooks"

type Props = {
    vehicleType: VehicleType
}

export default function MainModelSeriesSelection({ vehicleType }: Props) {
    if (vehicleType !== VehicleType.PassengerCar) {
        return null
    }

    const { selectedManufacturer } = useSelections(vehicleType)

    return (
        <div className="row">
            <div className="number">2</div>
            {selectedManufacturer ? (
                <Suspense fallback={<DisabledDropdown />}>
                    <Content vehicleType={vehicleType} manufacturerId={selectedManufacturer.id} />
                </Suspense>
            ) : (
                <DisabledDropdown />
            )}
        </div>
    )
}

function DisabledDropdown() {
    const { translateText } = useLocalization()
    const view = () => <div>{translateText(295)}</div>
    return <Dropdown items={[]} itemView={view} coverView={view} disabled />
}

const EMPTY_ARRAY: Array<CarMainModelSeries> = []

function Content({ vehicleType, manufacturerId }: Props & { manufacturerId: number }) {
    const { translateText } = useLocalization()
    const { mainModelSeries = EMPTY_ARRAY } = useMainModelSeriesByManufacturer({ manufacturerId, selectedFilters: {} }) ?? {}
    const { selectedMainModelSeries, setMainModelSeries } = useSelections(vehicleType)

    const renderMainModelSeriesItem = (data?: CarMainModelSeries) => {
        if (!data || !data.description) {
            return <div>{translateText(295)}</div>
        }

        return (
            <div className="item">
                <div className="description">{data.description}</div>
            </div>
        )
    }

    /** @todo Check if ordering is done correct by service */
    const orderedMainModelSeries = (mainModelSeries || []).orderBy((x) => x.sortId)

    return (
        <Dropdown
            items={orderedMainModelSeries}
            itemView={renderMainModelSeriesItem}
            coverView={() => <div>{translateText(295)}</div>}
            disabled={!mainModelSeries}
            onChange={setMainModelSeries}
            value={selectedMainModelSeries}
            amountItemsToShow={10}
            getSearchValue={(item) => item.description}
        />
    )
}
