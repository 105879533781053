import { TitleType } from "@tm/models"
import { ReactText } from "react"
import { getSplittedRegistrationNo } from "../../helpers"
import { SummaryFields } from "../../models"
import { PrepareCalculationRequest } from "./model"

export const createPrepareCalculationRequest = (fields: SummaryFields, translateText: (key: ReactText) => string): PrepareCalculationRequest => {
    const {
        processName,
        repairCoverage,
        keyNumber,
        vin,
        plateNumber,
        mileage,
        initialRegistration,
        titleType,
        firstName,
        lastName,
        street,
        zip,
        city,
    } = fields

    const titles = {
        [TitleType.Undefined]: undefined,
        [TitleType.Mister]: translateText(145),
        [TitleType.Miss]: translateText(146),
        [TitleType.Company]: translateText(108),
    }

    const { hsn, tsn, vsn } = getSplittedRegistrationNo(keyNumber)

    return {
        taskId: processName,
        eTaskId: parseInt(repairCoverage!),
        vehicleInfo: {
            hsn,
            tsn,
            vsn,
            vin: vin!,
            numberPlate: plateNumber!,
            mileage: mileage!,
            registrationDate: initialRegistration!,
        },
        customerInfo: {
            title: titles[titleType!]!,
            firstName: firstName!,
            lastName: lastName!,
            street: street!,
            zipCode: zip!,
            city: city!,
        },
    }
}
