import { useCallback } from "react"
import { matchPath } from "react-router"
import { RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Text } from "@tm/controls"
import { GlobalNavigationItem } from "@tm/components"
import { ECounterType } from "@tm/models"

type Props = RouteComponentProps<void> & {
    url: string
    title?: string
    tooltip?: string
}

function CrmButton({ url, history, title, tooltip }: Props) {
    const { translateText } = useLocalization()

    const isActive = matchPath(history.location.pathname, url)
    const handleClick = useCallback(() => {
        isActive ? history.goBack() : history.push(url)
        TmaHelper.GeneralCountEvent.Call(ECounterType.WorkshopProfiler)
    }, [isActive, url])

    return (
        <>
            <GlobalNavigationItem
                componentType="icon"
                name="crm-button"
                active={!!isActive}
                tooltip={tooltip || translateText(1251)}
                onIconClick={handleClick}
            />
            {!!title && (
                <Text size="m" className="startpage-button__title">
                    {/^\d+/.test(title) ? translateText(title) : title}
                </Text>
            )}
        </>
    )
}

export default withRouter(CrmButton)
