import { useCallback, useMemo } from "react"
import { ArticleListSortingMode, AttributeFilterModel } from "@tm/models"
import { Box, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleGroup } from "../components/ArticleGroup"
import { NextPageLoader } from "../components/NextPageLoader"
import { NoSearchResult } from "../components/NoSearchResult"
import { useArticleSelection } from "../hooks/useArticleSelection"
import { ArticleSelection } from "../components/ArticleSelection"
import { usePartsViewOptions } from "../hooks/usePartsViewOptions"
import { useVehiclePartsModuleState } from "./VehiclePartsModuleState"
import { getBundleParams } from "../../../utils"
import { usePartsModuleState } from "../PartsModuleState"
import { useArticleListActions } from "../ArticleListActions"

export function VehiclePartsList() {
    const { translateText } = useLocalization()

    const noResult = usePartsModuleState((x) => x.params.noResult)
    const isFiltersLoading = usePartsModuleState((x) => x.params.isFiltersLoading)
    const attributes = usePartsModuleState((x) => x.params.attributes)
    const setAttributes = usePartsModuleState((x) => x.params.setAttributes)
    const options = usePartsModuleState((x) => x.options)
    const sortingProductGroups = usePartsModuleState((x) => x.params.sorting.productGroups)
    const articleGroups = usePartsModuleState((x) => x.articles.articleGroups)
    const isLoading = usePartsModuleState((x) => x.articles.isLoading)
    const isLoaded = usePartsModuleState((x) => x.articles.isLoaded)
    const isFetchingNextPage = usePartsModuleState((x) => x.articles.isFetchingNextPage)
    const hasNextPage = usePartsModuleState((x) => x.articles.hasNextPage)
    const loadNextPage = usePartsModuleState((x) => x.articles.loadNextPage)
    const isStalling = usePartsModuleState((x) => x.articles.isStalling)
    const isFrontendFiltered = usePartsModuleState((x) => x.articles.isFrontendFiltered)

    const activateSortingForProductGroups = useVehiclePartsModuleState((x) => x.params.sorting.setForProductGroups)
    const productGroupTopicIds = useVehiclePartsModuleState((x) => x.productGroupTopicIds)

    const { toggleQuickFilter } = useArticleListActions()

    const {
        partsViewSettings: { showVehicleRecordsFilters },
    } = usePartsViewOptions()

    const calculatorRoute = getBundleParams().fastCalculatorModalRoute
    const usedAttributeFilters = useMemo(() => attributes.map((x) => x.query ?? `${x.id}|${x.key ?? ""}`), [attributes])

    const handleResetAttributeFilters = useCallback(() => {
        if (usedAttributeFilters.length) {
            setAttributes([])
        }
    }, [usedAttributeFilters, setAttributes])

    const handleChangeSorting = useCallback(
        (productGroupId: number, value: ArticleListSortingMode | undefined) => {
            activateSortingForProductGroups([productGroupId], value)
        },
        [activateSortingForProductGroups]
    )

    const { selectedArticles } = useArticleSelection()
    let distance = ""
    if (selectedArticles.length > 0) {
        distance = "-50px"
    }

    const showPageLoader = ((hasNextPage || isLoading || isFiltersLoading) && !isFrontendFiltered) || isStalling || isFetchingNextPage

    if (noResult) {
        return <NoSearchResult searchType="vehicle" />
    }

    return (
        <Box position="relative" top={distance}>
            <ArticleSelection showArticleComparision hideBasketButton={options.hideAddToBasketButton} />

            {isLoaded &&
                !isStalling &&
                articleGroups.map((group) => (
                    <ArticleGroup
                        key={group.productGroup.id}
                        group={group}
                        currentSorting={sortingProductGroups[group.productGroup.id]}
                        onChangeSorting={handleChangeSorting}
                        calculatorRoute={calculatorRoute}
                        showFilterOptions={showVehicleRecordsFilters}
                        usedAttributeFilters={usedAttributeFilters}
                        onToggleAttributeFilter={toggleQuickFilter}
                        onResetAttributeFilters={handleResetAttributeFilters}
                        productGroupTopicIds={productGroupTopicIds}
                    />
                ))}

            {showPageLoader && (
                <NextPageLoader
                    loadNextPage={loadNextPage}
                    canLoadNextArticles={hasNextPage}
                    skeletonArticleCount={articleGroups.length ? 1 : 5}
                    skeletonCategory
                />
            )}

            {hasNextPage && isFrontendFiltered && (
                <Box display="flex" justifyContent="center">
                    <Button color="highlight" onClick={loadNextPage}>
                        {translateText(12430)}
                    </Button>
                </Box>
            )}
        </Box>
    )
}
