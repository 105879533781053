import { clone, equals } from "@tm/utils"
import { Box } from "@tm/components"
import { ICalculationItem, Part } from "../../../data/models"
import { OeArticleItem, PartItem } from "../../_shared"

type Props = {
    item: ICalculationItem
    isActive: boolean
    onOeArticleSelect(): void
    onOeArticleQuantity(quantity: number): void
    onPartSelect(part: Part): void
    onDetailsClick?(article: Part): void
    onAlternativeClick(item: ICalculationItem): void
}

export default function CalculationItemComponent({
    item,
    onDetailsClick,
    isActive,
    onOeArticleSelect,
    onOeArticleQuantity,
    onPartSelect,
    onAlternativeClick,
}: Props) {
    let alternatives = clone(item.alternativeParts)
    const index = alternatives?.findIndex((x) => equals(x, item.selectedPart))

    if (item.selectedPart && index > 0) {
        alternatives = alternatives.filter((x) => !equals(x, item.selectedPart))
        alternatives.unshift(item.selectedPart)
    }

    return (
        <Box className="calculation-item">
            <OeArticleItem
                isActive={isActive}
                oeArticle={item.oeArticle}
                showAlternativeBtn={alternatives && !!alternatives.length}
                onQuantityChange={onOeArticleQuantity}
                isSelected={item.isSelected}
                onSelect={onOeArticleSelect}
                onDetailsClick={(part) => onDetailsClick?.(part)}
                onAlternativeClick={() => onAlternativeClick(item)}
                selectedPart={item.selectedPart}
            />

            {isActive && (
                <div className="tk-parts">
                    <div className="list">
                        <div className="article-list alternative-part__list">
                            {alternatives.map((part) => (
                                <PartItem
                                    key={item.uniqueId}
                                    alternativePart={part}
                                    oeArticle={item.oeArticle}
                                    selectedPart={item.selectedPart}
                                    onSelect={() => onPartSelect(part)}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </Box>
    )
}
