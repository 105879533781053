import { ajaxAuth } from "@tm/utils"
import { OrderVouchersShowSupplierArticleByVehicleRequest } from "@tm/models"
import {
    ShowTotalNumbersResponse,
    ShowOrdersResponse,
    ShowOrderDetailsResponse,
    ShowAllRequest,
    ShowByCustomerRequest,
    ShowByVehiclesRequest,
    ShowOrderDetailsRequest,
    ShowByArticleRequest,
} from "../../model"
import { getBundleParams } from "../../../utils"
import { mapShowOrderDetailsResponse, mapShowOrdersResponse } from "../../mapper"

function getServiceUrl(): string {
    return `${getBundleParams().vouchersServiceUrl}/Orders`
}

export function showAllOrders(body: ShowAllRequest) {
    const url = `${getServiceUrl()}/ShowAll`
    return ajaxAuth<ShowOrdersResponse>({ url, body }).then((response) => mapShowOrdersResponse(response)?.orders)
}
export function showOrdersByCustomer(body: ShowByCustomerRequest) {
    const url = `${getServiceUrl()}/ShowByCustomer`
    return ajaxAuth<ShowOrdersResponse>({ url, body }).then((response) => mapShowOrdersResponse(response)?.orders)
}

export function showOrdersByVehicles(body: ShowByVehiclesRequest) {
    const url = `${getServiceUrl()}/ShowByVehicles`
    return ajaxAuth<ShowOrdersResponse>({ url, body, method: "POST" }).then((response) => mapShowOrdersResponse(response)?.orders)
}

export function showOrdersByArticle(body: ShowByArticleRequest) {
    const url = `${getServiceUrl()}/ShowByArticle`
    return ajaxAuth<ShowOrdersResponse>({ url, body }).then((response) => mapShowOrdersResponse(response)?.orders)
}

export function showOrderDetails(body: ShowOrderDetailsRequest) {
    const url = `${getServiceUrl()}/ShowDetails`
    return ajaxAuth<ShowOrderDetailsResponse>({ url, body }).then((response) => mapShowOrderDetailsResponse(response))
}

export function showTotalNumbers(workTaskId: string): Promise<ShowTotalNumbersResponse | undefined> {
    const url = `${getServiceUrl()}/ShowTotalNumbers`
    const body = { workTaskId }
    return ajaxAuth({ url, body })
}

export function showSupplierArticleByVehicle(body: OrderVouchersShowSupplierArticleByVehicleRequest) {
    const url = `${getServiceUrl()}/ShowSupplierArticleByVehicle`
    return ajaxAuth({ url, body })
}

export function showOrderPdf(voucherId: string): Promise<Array<number> | undefined> {
    const url = `${getServiceUrl()}/ShowDetailsPdf`
    const body = { voucherId }
    return ajaxAuth({ url, body, responseType: "blob" })
}
