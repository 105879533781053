import { useLocalization } from "@tm/localization"
import { TmaHelper } from "@tm/utils"
import { useRef } from "react"

import { ECounterType } from "@tm/models"
import { ModelYearFilter } from "../../../data/model/filters"
import Filter from "./_filter"

type Props = {
    items: Array<ModelYearFilter>
    clipped: boolean
    opened: boolean
    onToggleOpen(): void
    onToggleClip(): void
    onChangeFilter(item?: number): void
}

function ModelYearsFilter(props: Props) {
    const { translateText } = useLocalization()
    const filterRef = useRef<React.ElementRef<typeof Filter>>(null)

    const handleClick = (item: ModelYearFilter) => {
        props.onChangeFilter(item.isSelected ? undefined : item.year)
        TmaHelper.VehicleSelection.Search.ModelYearFilter(item.year)
        !props.clipped && filterRef.current?.collapse()
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionConstructionYear, !item.isSelected)
    }

    const renderItem = (item: ModelYearFilter) => {
        const className = `modelyears__item ${item.isSelected ? "modelyears__item--selected" : ""}`
        return (
            <div className={className} key={item.year} onClick={() => handleClick(item)}>
                {item.year}
            </div>
        )
    }

    const { items, opened } = props
    const selectedItem = items.find((x) => !!x.isSelected)

    return (
        <Filter
            {...props}
            name={translateText(127)}
            ref={filterRef}
            className="filters__modelyears"
            disabled={!(items && items.length)}
            value={!opened && selectedItem ? selectedItem.year.toString() : undefined}
        >
            <div className="modelyears">{items.map(renderItem)}</div>
        </Filter>
    )
}

export default ModelYearsFilter
