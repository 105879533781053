import { useCallback } from "react"
import { useRouteMatch } from "react-router"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { useWorkTask } from "@tm/context-distribution"
import { encodeUniqueId, getCategoryTypeFromUrl, renderRoute, uniqueId } from "@tm/utils"
import { Article, LogEntry, RegisteredModels, RequestArticleDetailsPayload } from "@tm/models"
import { SearchType } from "../../../../business"

import { getArticleDetailsRoute } from "../../../manager/business"
import { usePartsRoutes } from "../usePartsRoutes"

export function useHandleClickDetails(article: Article, quantity: number, searchType?: SearchType) {
    const routeMatch = useRouteMatch()
    const partsRoutes = usePartsRoutes()
    const { workTaskId } = useWorkTask() ?? {}

    return useCallback(
        (subPage?: string, scrollTo?: string) => {
            // show directly partslist by opening pseudo articles details
            if (!subPage && !article.showAddToBasket && article.isPartsListAvailable) {
                subPage = "partslist"
            }
            const request: RequestArticleDetailsPayload = {
                article: {
                    productGroupId: article.productGroup.id,
                    supplierArticleNo: article.supplierArticleNo,
                    supplierId: article.supplier.id,
                    traderArticleNo: article.traderArticleNo,
                },
                inModal: true,
                initialQuantity: quantity,
                vehicleLinkageId: article.vehicleLinkageId,
                subPage,
                scrollTo,
            }

            if (routeMatch.url.indexOf("/part-alternatives/") !== -1) {
                request.categoryType = "partAlternatives"
            } else {
                request.categoryType = getCategoryTypeFromUrl(routeMatch.url)
            }

            if (searchType === SearchType.ALTERNATIVES) {
                if (request.article) {
                    try {
                        const logEntry: LogEntry = {
                            type: "Alternative article search - article details opened",
                            request: request.article,
                        }

                        Container.getInstance<LogEntry>(RegisteredModels.Logging).callAction("write", logEntry)
                    } catch {}
                }
            }

            const route = getArticleDetailsRoute(request, true, partsRoutes)
            if (route) {
                const url = `${renderRoute(route?.route, { workTaskId: encodeUniqueId(workTaskId ?? uniqueId()) })}?${route.queryString}`
                Morpheus.showView("1", url)
            }
        },
        [
            article.productGroup.id,
            article.supplierArticleNo,
            article.supplier.id,
            article.traderArticleNo,
            article.vehicleLinkageId,
            article.isPartsListAvailable,
            article.showAddToBasket,
            quantity,
            routeMatch.url,
            searchType,
            partsRoutes,
            workTaskId,
        ]
    )
}
