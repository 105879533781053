import { FileMeta } from "@tm/data/erikContentService/models"

export function getMileageNumber(file: FileMeta): number {
    if (!file || !file.meta || typeof file.meta.Mileage !== "string") {
        return 0
    }

    const mileage = parseInt(file.meta.Mileage.trim())

    return Number.isNaN(mileage) ? 0 : mileage
}
