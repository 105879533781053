import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { ErpInformationRequestItem, TeccomErpInformation } from "@tm/models"
import { useCallback, useEffect, useState } from "react"
import { bundleChannel } from "../../bundle-channel"
import { getErpInfosTeccom } from "../../data"

export type TeccomState = {
    teccomLoading: boolean
    teccom: TeccomErpInformation | undefined
}
const initialTeccomState: TeccomState = { teccomLoading: false, teccom: undefined }

export function useTeccom(
    isTeccomRequestAvailable: boolean | undefined,
    request: ErpInformationRequestItem | undefined,
    distributorId: number | undefined
) {
    const [{ teccom, teccomLoading }, setTeccomState] = useState(initialTeccomState)
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const requestJson = request ? JSON.stringify(request) : null // Generate JSON as dependency for the hooks and as id for hermes

    useEffect(() => {
        if (requestJson) {
            // set loading to false, when the request changes. Otherwise the loader will always be shown.
            setTeccomState(initialTeccomState)
        }
    }, [requestJson])

    useEffect(() => {
        if (!isTeccomRequestAvailable || !requestJson) {
            return
        }

        // Subscribe to teccom response from hermes
        const unsubscribe = bundleChannel("TECCOM", requestJson).subscribe("LOADED", (data) => {
            setTeccomState({ teccomLoading: false, teccom: data.response })
        })

        const unsubscribeError = bundleChannel("TECCOM", requestJson).subscribe("ERROR", () => {
            setTeccomState(initialTeccomState)
        })

        return () => {
            unsubscribe()
            unsubscribeError()
        }
    }, [requestJson, isTeccomRequestAvailable])

    const loadTeccom = useCallback(() => {
        if (!isTeccomRequestAvailable || !requestJson || !request) {
            return
        }

        setTeccomState({ teccomLoading: true, teccom: undefined })
        getErpInfosTeccom(request, telesalesCustomerNo, distributorId).then(
            (response) => {
                // Send teccom response with hermes
                bundleChannel("TECCOM", requestJson).publish("LOADED", { response })
            },
            () => {
                bundleChannel("TECCOM", requestJson).publish("ERROR", {})
            }
        )
    }, [request, requestJson, telesalesCustomerNo, distributorId, isTeccomRequestAvailable])

    return { teccom, teccomLoading, loadTeccom: isTeccomRequestAvailable ? loadTeccom : undefined }
}
