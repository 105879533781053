import { useState } from "react"
import { useUser } from "@tm/context-distribution"
import { Table } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { getCurrencyFromUserContext } from "@tm/utils"
import { Button, Checkbox, Icon, Typography } from "@tm/components"
import { eOEArtSubType } from "../../../data/enums"
import { Work } from "../../../data/models"
import { MainActions } from "../../main/business"
import { IncludedWorks } from "."

type Props = {
    works: Work[]
}

export default function WorkList({ works }: Props) {
    const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({})
    const { translateText, currency, number } = useLocalization()

    const user = useUser()
    const currencyString = getCurrencyFromUserContext(user?.userContext)

    const actions = useActions(MainActions, "selectWork")

    const handleCollapse = (work: Work) =>
        setIsExpanded((state) => ({ ...state, [work.providerWorkId + work.description]: !state[work.providerWorkId + work.description] }))

    const renderIncludes = (work: Work) => (
        <Table.Cell>
            <Button
                startIcon={<Icon name={isExpanded[work.providerWorkId + work.description] ? "up" : "down"} />}
                disabled={!work.includedWorks?.length}
                onClick={() => handleCollapse(work)}
            />
        </Table.Cell>
    )

    const renderProviderId = (work: Work) => {
        const isAdditionalWorkCost = work.providerWorkId === eOEArtSubType.RemoveGlassSplitter.toString()

        return (
            <Table.Cell>
                {!isAdditionalWorkCost &&
                    work.providerWorkId
                        ?.split(/(?=[+])/)
                        .map((providerWorkId: any) => <Typography key={providerWorkId}>{providerWorkId}</Typography>)}

                {isAdditionalWorkCost && <Typography key={work.providerWorkId}>{work.providerWorkId}</Typography>}
            </Table.Cell>
        )
    }

    const renderDescription = (work: Work) => (
        <Table.Cell>
            <Typography>{work.description} </Typography>
        </Table.Cell>
    )

    const renderHourlyRate = (work: Work) => (
        <Table.Cell>
            <Typography alignContent="center">{currency(work.hourlyRate, currencyString)} </Typography>
        </Table.Cell>
    )

    const renderRtUnitValue = (work: Work) => (
        <Table.Cell>
            <Typography>{number(work.rtUnit, 2)}</Typography>
        </Table.Cell>
    )

    const renderRtValue = (work: Work) => (
        <Table.Cell>
            <Typography>{number(work.time, 2)}</Typography>
        </Table.Cell>
    )

    const renderTotal = (work: Work) => (
        <Table.Cell>
            <Typography>{currency(work.total, currencyString)}</Typography>
        </Table.Cell>
    )

    const renderCheckbox = (work: Work) => (
        <Table.Cell>
            <Checkbox checked={work.isSelected} onClick={() => actions.selectWork(work)} />
        </Table.Cell>
    )

    const renderIncludedWorks = (work: Work) => (
        <Table.Cell>
            {work.includedWorks && (
                <IncludedWorks
                    includedWorks={work.includedWorks}
                    showSupplierArticleNumbers // TODO: this.props.showSupplierArticleNumbers
                />
            )}
        </Table.Cell>
    )

    const rtValueTitle = user?.userSettings?.repairTimeOptions?.division === 1 ? translateText(84) : translateText(621)

    const columns = [
        <Table.Column key="includes" className="includes" renderItemContent={renderIncludes} />,
        <Table.Column key="number" className="number" renderItemContent={renderProviderId}>
            {translateText(58)}
        </Table.Column>,
        <Table.Column key="description" className="description" renderItemContent={renderDescription}>
            {translateText(617)}
        </Table.Column>,
        <Table.Column key="hourly-rate price" className="hourly-rate price" renderItemContent={renderHourlyRate}>
            {translateText(622)}
        </Table.Column>,
        user?.userSettings?.repairTimeOptions?.division !== 1 && (
            <Table.Column className="rt-value time" renderItemContent={renderRtUnitValue}>
                {translateText(1723)}
            </Table.Column>
        ),
        <Table.Column key="rt-value time" className="rt-value time" renderItemContent={renderRtValue}>
            {rtValueTitle}
        </Table.Column>,
        <Table.Column key="rt-value time" className="price sum" renderItemContent={renderTotal}>
            {translateText(57)}
        </Table.Column>,
        <Table.Column key="rt-value time" className="actions" renderItemContent={renderCheckbox} />,
        <Table.Column key="expandableRow" className="expandableRow" renderItemContent={renderIncludedWorks} />,
    ]

    return (
        <Table
            data={works}
            className="works-list"
            columns={columns.filter(Boolean) as any}
            getRowClassName={(work) => (isExpanded[work.providerWorkId + work.description] ? "is-expanded" : "")}
        />
    )
}
