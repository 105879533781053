import {
    Article,
    ArticleAttribute,
    OE,
    OeArticleDto,
    OeArticleQuantitiesRequest,
    SupplierArticleQuantitiesRequest,
    ListFilter,
    WholesalerArticleQuantitiesRequest,
    WholesalerPart,
    WholesalerArticleDto,
    channel,
} from "@tm/models"
import { isEqual } from "lodash"
import { ForwardedRef, useEffect, useRef } from "react"

import { ArticleGroupParams } from "./models"
import { DataSupplierFilter, ProductGroupFilter } from "../../data/model"
import { CriterionFilter } from "../../data/model/uni-parts"

export function getArticleGroups(articles: Article[], articlesIncomplete: boolean) {
    const groups: ArticleGroupParams[] = []

    articles.forEach((article) => {
        const lastGroup = groups.last()

        if (lastGroup && lastGroup.productGroup.id === article.productGroup.id) {
            lastGroup.articles.push(article)
        } else {
            groups.push({
                productGroup: article.productGroup,
                articles: [article],
                incomplete: false, // Assume the group is complete
            })
        }
    })

    // Only the last group can be incomplete
    if (articlesIncomplete && groups.length) {
        groups[groups.length - 1].incomplete = true
    }

    return groups
}

export function getArticleGroupsWithExactMatches(articles: Article[], articlesIncomplete: boolean) {
    const exactMatch: Article[] = []
    const other: Article[] = []

    articles.forEach((article) => {
        if (article.isExactMatch) {
            exactMatch.push(article)
        } else {
            other.push(article)
        }
    })

    return {
        exactMatch: getArticleGroups(exactMatch, articlesIncomplete),
        other: getArticleGroups(other, articlesIncomplete),
    }
}

export function mapDistinctValues<T, K = keyof T>(items: T[], selector: (item: T) => K) {
    return items.reduce<K[]>((prev, curr) => {
        const val = selector(curr)

        if (prev.some((x) => x === val)) {
            return prev
        }

        return [...prev, val]
    }, [])
}

/**
 * Checks if all items in source are found in object. It perfoms a deep comparison. The order of the items doesn't matter.
 * @param object
 * @param source
 * @returns
 */
export function isPartOf(object: unknown[], source: unknown[]) {
    for (let s = 0; s < source.length; s++) {
        let found = false

        for (let o = 0; o < object.length; o++) {
            if (isEqual(source[s], object[o])) {
                found = true
                break
            }
        }

        if (!found) {
            return false
        }
    }

    return true
}

export function objectValuesEqual(obj1: any, obj2: any) {
    if (!obj1 || !obj2) {
        return false
    }

    const keys = Object.keys(obj1)

    for (let i = 0; i < keys.length; i++) {
        const key = keys[i]

        if (typeof obj1[key] === "object" && !objectValuesEqual(obj1[key], obj2[key])) {
            return false
        }

        if (obj1[key] !== obj2[key]) {
            return false
        }
    }

    return true
}

export function useForwardedRef<T>(ref: ForwardedRef<T>, initialValue: T | null = null) {
    const targetRef = useRef<T>(initialValue)

    useEffect(() => {
        if (!ref) {
            return
        }

        if (typeof ref === "function") {
            ref(targetRef.current)
        } else {
            ref.current = targetRef.current
        }
    }, [ref])

    return targetRef
}

export function mapProductGroupFilter(filter: ProductGroupFilter): ListFilter {
    const key = `${filter.id}`
    return {
        ...filter,
        showOnTop: filter.showOnTop || filter.hasTopPrioritySuppliers,
        key,
    }
}

export function mapDataSupplierFilterAndShowOnTop(filter: DataSupplierFilter): ListFilter {
    const key = `${filter.id}`
    return {
        ...filter,
        showOnTop: filter.showOnTop || filter.isTopPriority,
        key,
    }
}

export function mapDataSupplierFilter(filter: DataSupplierFilter): ListFilter {
    const key = `${filter.id}`
    return {
        ...filter,
        key,
    }
}

export function mapArticleAttribute(filter: ArticleAttribute): ListFilter {
    const query = `${filter.id}|${filter.key || filter.value}`
    return {
        ...filter,
        query,
        priority: 1,
    }
}

export function mapCriterionFilter(filter: CriterionFilter): ListFilter {
    const query = `${filter.id}|${filter.valueKey || filter.value}`
    return {
        ...filter,
        query,
        priority: 1,
    }
}

export function mapListFilterToQuery(filter: ListFilter): string {
    // TODO: we need to clean up the filter structure in the backend
    return `${filter.id}|${filter.valueKey || filter.key || filter.value}`
}

export function mapSupplierArticleQuantitiesRequest(article: Article, workTaskId: string): SupplierArticleQuantitiesRequest {
    return {
        article: {
            dataSupplierArticleNumber: article.supplierArticleNo,
            dataSupplierId: article.supplier.id,
            productGroupId: article.productGroup.id,
        },
        workTaskId,
    }
}

export function mapOeArticleDtoFromOePart(part: OE.OePart): OeArticleDto {
    return {
        oeArticleNumber: part.number,
        productGroupId: part.productGroupId,
        vehicleManufacturerId: part.manufacturerId,
    }
}

export function mapWholesaleDtoFromWholesalerPart(part: WholesalerPart): WholesalerArticleDto {
    return {
        wholesalerArticleNumber: part.wholesalerArticleNumber,
    }
}

export function isSameOeArticle(a: OeArticleDto, b: OeArticleDto) {
    // There are cases where productGroupId is sometimes undefined, this does not work with Lodash isEqual
    return a.oeArticleNumber === b.oeArticleNumber && a.productGroupId === b.productGroupId && a.vehicleManufacturerId === b.vehicleManufacturerId
}

export function isSameWholesalerArticle(a: WholesalerArticleDto, b: WholesalerArticleDto) {
    return a.wholesalerArticleNumber === b.wholesalerArticleNumber
}

export function mapOeArticleQuantitiesRequest(article: OE.OePart, workTaskId: string): OeArticleQuantitiesRequest {
    return {
        article: mapOeArticleDtoFromOePart(article),
        workTaskId,
    }
}

export function mapWholesalerArticleQuantitiesRequest(article: WholesalerPart, workTaskId: string): WholesalerArticleQuantitiesRequest {
    return {
        article: mapWholesaleDtoFromWholesalerPart(article),
        workTaskId,
    }
}

export function mapWholesalerPartToArticle(part: WholesalerPart): Article {
    const internalId = (part.description + part.wholesalerArticleNumber).hashCode()
    return {
        id: part.itemId,
        internalId,
        supplier: {} as any,
        productGroup: {} as any,
        traderArticleNo: part.wholesalerArticleNumber,
        description: part.description ?? "",
        quantity: part.quantityValue,
        requestErpInfo: true,
    } as Article
}

export function publishModuleInfo(view: string, info: string) {
    const unsub = channel("GLOBAL").subscribe(
        "WORKTASK/ID_CHANGED",
        (data) => {
            if (data.id) {
                channel("WORKTASK", data.id).publish("MODULE/CHANGED", { id: "parts", name: "{{99}}", view, info })
                unsub()
            }
        },
        true
    )
}
