import { Box, Grid, Typography, styled } from "@tm/components"
import { PropsWithChildren } from "react"

type ModuleHeaderSlotProps = PropsWithChildren<{ title?: string }>

function ModuleHeaderSlotComponent(props: ModuleHeaderSlotProps) {
    const { title, children } = props

    if (!title) {
        return (
            <Grid display="grid">
                <Box>{children}</Box>
            </Grid>
        )
    }

    return (
        <Grid display="grid" gridTemplateRows="12px 1fr">
            <Typography variant="label" fontSize={9} textTransform="uppercase" height={12}>
                {title}
            </Typography>
            <Box>{children}</Box>
        </Grid>
    )
}

export const ModuleHeaderSlot = styled(ModuleHeaderSlotComponent)<{ isTabs?: boolean }>(({ isTabs }) => ({
    position: "relative",
    paddingTop: isTabs ? 0 : 8,
    paddingBottom: 4,
    paddingLeft: 16,
    paddingRight: 16,
    height: "100%",
    "&:not(:nth-child(2)):before": {
        position: "absolute",
        top: 0,
        left: 0,
        content: "''",
        borderLeft: "2px solid #c3c3c3",
        marginTop: "-.5rem",
        height: "75%",
    },
}))
