import { CarModelDetailsResponse, Customer, Vehicle } from "@tm/models"
import { GetCalculationDataResponse } from "./data/repositories"

export type BundleActionTypes =
    | { type: "VEHICLE_SET"; payload: Vehicle }
    | { type: "CUSTOMER_SET"; payload: Customer }
    | { type: "DETAILS_LOADED"; payload: { data: CarModelDetailsResponse; vehicle?: Vehicle } }
    | { type: "CALCULATION_DATA_LOADED"; payload: GetCalculationDataResponse }
    | { type: "@@redux/INIT" }

export type IBundleActions = {}

export const BundleActions: IBundleActions = {
    // init,
}
