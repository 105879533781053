import { ajax, getStoredAuthorization } from "@tm/utils"
import { QueryClient } from "react-query"
import { getBundleParams } from "../../../utils"
import { FastServiceStoreState } from "../.."
import { reviver } from "../../sessionStorage/helper"

const getFastServiceHistoryDetails = (id: string): Promise<string> => {
    const url = `${getBundleParams().historyServiceUrl}/GetHistoryData`
    const authorization = getStoredAuthorization()
    const body = { id }

    return new Promise<string>((resolve, reject) => ajax({ url, body, authorization }).then((data) => resolve(data?.result || []), reject))
}

export async function getHistoryDetails(historyEntryId: string): Promise<FastServiceStoreState> {
    const queryClient = new QueryClient()

    return queryClient
        .fetchQuery({
            queryKey: ["GetHistoryDetails", { historyEntryId }],
            queryFn: () => getFastServiceHistoryDetails(historyEntryId),
        })
        .then((res) => JSON.parse(res, reviver))
}
