import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function tmTyresFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const tmTyresModule = getModuleFromUserContext(userContext, UserModuleType.TMTires)

    if (!tmTyresModule) {
        return undefined
    }

    return {
        tyres: {
            items: {
                "tm-tyres": {
                    sort: 0,
                    name: "TM Tyres",
                    route: "/:workTaskId/tyres/summary",
                    active: true,
                    setAsActiveVehicleDataProviderOnSelect: {
                        tyres: "tmtires",
                    },
                },
            },
        },
    }
}
