import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"
import { mapShowPartsListByOeReferencesToRequestArticleList } from "../business"

export default function handleEurotaxDataOe(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (data.eurotaxDataOe) {
        const request = mapShowPartsListByOeReferencesToRequestArticleList(data.eurotaxDataOe)
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}
