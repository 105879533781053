import { ServiceEvent, EventType, TimeSlot, WorkshopTask } from "./model"
import { formatDate, formatTime } from "../helpers/date"

function snakeToCamel(obj: Record<string, unknown>): any {
    return Object.keys(obj).reduce(
        (prev, curr) => ({
            ...prev,
            [curr
                .split("_")
                .map((x, idx) => (!idx ? x : x.substr(0, 1).toUpperCase() + x.substr(1)))
                .join("")]: obj[curr],
        }),
        {}
    )
}

export function camelToSnake(obj: Record<string, unknown>): any {
    return Object.keys(obj).reduce(
        (prev, curr) => ({
            ...prev,
            [curr.replace(/[A-Z]/g, (x) => `_${x.toLowerCase()}`)]:
                obj[curr] instanceof Date ? `${formatDate(obj[curr] as Date)} ${formatTime(obj[curr] as Date)}` : obj[curr],
        }),
        {}
    )
}

export function mapServiceEvent(data: any): ServiceEvent {
    const pre = snakeToCamel(data)
    return {
        ...pre,
        startDateTime: new Date(Date.parse(pre.startDateTime)),
        endDateTime: new Date(Date.parse(pre.endDateTime)),
        completionDateTime: pre.completionDateTime ? new Date(Date.parse(pre.completionDateTime)) : undefined,
    }
}

export function mapWorkshopTask(data: any): WorkshopTask {
    const pre = snakeToCamel(data)
    return {
        ...pre,
        startDateTime: new Date(Date.parse(pre.startDateTime)),
    }
}

function mapNamesToTranslationIds(name: string): string {
    const translationDict: { [key: string]: any } = {
        Dialogannahme: 1646,
        Onlinebuchung: 1647,
        "Annahme ohne Dialogannahme": 1648,
        Unfallaufnahme: 1649,
        Reklamation: 1362,
        "Kunde ohne Termin": 1650,
        Räderwechsel: 1651,
        "HU/AU": 1652,
        Neukunde: 1653,
    }

    return translationDict[name]
}

export function mapEventType(data: any): EventType {
    return {
        ...snakeToCamel(data),
        name: mapNamesToTranslationIds(data.name),
    }
}

export function mapTimeSlot(data: any): TimeSlot {
    const pre = snakeToCamel(data)
    return {
        ...pre,
        startDate: new Date(Date.parse(pre.startDate)),
        endDate: new Date(Date.parse(pre.endDate)),
    }
}
