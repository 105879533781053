import { IBundle } from "@tm/morpheus"
import PostMessageController from "./components/post-message-controller"
import { initApiBundle, version } from "./utils"

const toolkit: IBundle = {
    ...version,
    components: [PostMessageController],
    init: initApiBundle,
}

export default toolkit
