import { Box, Button, Icon, EasyPopover } from "@tm/components"
import { ERIKContentService } from "@tm/data"
import { useFileDownload } from "../../hooks"
import { stopPropagation } from "../../helpers"
import FileShare from "../fileShare/FileShare"

type FileItemActionsProps = {
    file: ERIKContentService.FileMeta
}

export function FileItemActions({ file }: FileItemActionsProps) {
    const { downloadFile } = useFileDownload()

    return (
        <Box display="flex" gap="1em">
            <Button startIcon={<Icon name="download" />} onClick={stopPropagation(() => downloadFile(file))} />
            <EasyPopover anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
                <EasyPopover.ActionButton>
                    <Button startIcon={<Icon name="share" />} onClick={stopPropagation((e) => {})} />
                </EasyPopover.ActionButton>
                <EasyPopover.Content sx={{ padding: "1em" }}>
                    <FileShare file={file} />
                </EasyPopover.Content>
            </EasyPopover>
            {/* This is not needed for V1 */}
            {/* <Button startIcon={<Icon name="delete" />} onClick={stopPropagation(() => deleteFile(file))} /> */}
        </Box>
    )
}
