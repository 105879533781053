import { Repositories, tyresWheelsActions, useFastServiceStore } from "../../../data"
import { SelectedFilters } from "../../../data/state/tyres-wheels"
import { createTiresCritsRequest } from "../../../data/state/tyres-wheels/helper"

export function loadTiresData() {
    const {
        maintenancePlan: { stateVehicle },
    } = useFastServiceStore.getState()
    if (!stateVehicle) {
        return
    }
    tyresWheelsActions.setTyresLoading()

    Promise.all([Repositories.loadDriveRightTires(stateVehicle.tecDocTypeId), Repositories.getTiresBrands()]).then(
        ([tires, brands]) => {
            tyresWheelsActions.setTyresLoaded(tires, stateVehicle.tecDocTypeId, brands)
        },
        () => tyresWheelsActions.setTyresError()
    )
}

export function loadTiresCrits(actualFilters?: SelectedFilters) {
    const {
        tyresWheels: {
            selectedTab: { selectedFilters },
        },
        maintenancePlan: { stateVehicle },
    } = useFastServiceStore.getState()

    const request = createTiresCritsRequest(selectedFilters, actualFilters, stateVehicle?.vehicleType)

    tyresWheelsActions.setTyresCritsLoading()

    Repositories.loadTyresCrits(request).then(
        (response) => tyresWheelsActions.setTyresCritsLoaded(response),
        () => tyresWheelsActions.setTyresCritsError()
    )
}
