import { KeyboardEvent, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Modal } from "@tm/controls"
import { MenuItemWidget, SearchButton, Select, Stack, TextField, Typography } from "@tm/components"
import { Container } from "@tm/nexus"
import { RegisteredModels, Article, SearchFilters } from "@tm/models"
import { ButtonKeyDefinition } from "@tm/utils"
import { CisFilters, OrderItemSearchTypeId } from "../../../business/model"
import SelectArticlesComponent from "./select-articles"

type SearchTypeSelectItem = {
    id: OrderItemSearchTypeId
    description: string
}

type Props = {
    filters: CisFilters
    setFilters(filters: CisFilters): void
}

export default function OrderItemSearchComponent({ filters, setFilters }: Props) {
    const { translateText } = useLocalization()
    const [cisFilters, setCisFilters] = useState<CisFilters>(filters)
    const [articlesToSelect, setArticlesToSelect] = useState<Array<Article>>()

    const searchTypeItems: Array<SearchTypeSelectItem> = [
        {
            id: OrderItemSearchTypeId.Manufacturer,
            description: translateText(71),
        },
        {
            id: OrderItemSearchTypeId.Wholesaler,
            description: translateText(1385),
        },
        {
            id: OrderItemSearchTypeId.EuropeanArticleNumber,
            description: translateText(12560),
        },
    ]

    useEffect(() => {
        setCisFilters(filters)
        if (filters.queryTypeId !== OrderItemSearchTypeId.Wholesaler && filters.query) {
            searchArticle()
        }
    }, [filters])

    function handleChangeItemId(itemId: string) {
        setCisFilters((prev) => ({ ...prev, itemId }))
    }

    function searchArticle() {
        const request = {
            query: cisFilters.itemId,
            searchFilter:
                cisFilters.queryTypeId === OrderItemSearchTypeId.Manufacturer
                    ? SearchFilters.SupplierArticleNoAndTradeReference
                    : SearchFilters.EuropeanArticleNumber,
        }
        Container.getInstance<Array<Article>>(RegisteredModels.Articles)
            .subscribe(request)
            .load()
            .then((articles) => {
                if (articles.length === 1) {
                    const cisFilter: CisFilters = {
                        ...cisFilters,
                        itemId: articles[0].traderArticleNo,
                        queryTypeId: OrderItemSearchTypeId.Wholesaler,
                    }
                    setCisFilters(cisFilter)
                    setFilters(cisFilter)
                } else if (articles.length > 1) {
                    setArticlesToSelect(articles)
                } else {
                    setFilters(cisFilters)
                }
            })
    }

    function handleSearch() {
        if (!cisFilters.queryTypeId) {
            setFilters(cisFilters)
        } else {
            searchArticle()
        }
    }

    function handleSelectArticle(article: Article) {
        const cisFilter: CisFilters = {
            ...cisFilters,
            itemId: article.traderArticleNo,
            queryTypeId: OrderItemSearchTypeId.Wholesaler,
        }

        setArticlesToSelect(undefined)
        setCisFilters(cisFilter)
        setFilters(cisFilter)
    }

    function handleModalClose() {
        setArticlesToSelect(undefined)
    }

    function handleArticleTypeStateChange(articleType: SearchTypeSelectItem) {
        setCisFilters({
            ...cisFilters,
            queryTypeId: articleType.id,
            queryTypeDescription: articleType.description,
        })
    }

    function handleSearchKeyDown(e: KeyboardEvent<HTMLInputElement>) {
        if (e.key === ButtonKeyDefinition.Enter) {
            handleSearch()
        }
    }

    function renderArticleSelection() {
        if (articlesToSelect) {
            return (
                <Modal className="direct-buy__article-details" onClose={handleModalClose}>
                    <SelectArticlesComponent articles={articlesToSelect} selectArticle={handleSelectArticle} />
                </Modal>
            )
        }
    }

    const selectedArticleType = searchTypeItems.find((articleType) => articleType.id === cisFilters.queryTypeId) || searchTypeItems[0]
    return (
        <>
            {renderArticleSelection()}
            <Stack direction="row" spacing={1}>
                <Stack direction="column">
                    <Typography variant="label">{translateText(1837)}</Typography>
                    <Select
                        value={selectedArticleType}
                        size="large"
                        onChange={(e) => handleArticleTypeStateChange(e.target.value as SearchTypeSelectItem)}
                        renderValue={(val) => (val as SearchTypeSelectItem).description}
                        variant="filled"
                        sx={{ width: "10rem" }}
                    >
                        {searchTypeItems.map((item) => {
                            return (
                                <MenuItemWidget key={item.description} value={item}>
                                    {item.description}
                                </MenuItemWidget>
                            )
                        })}
                    </Select>
                </Stack>

                <Stack direction="column">
                    <Typography variant="label">{translateText(135)}</Typography>
                    <Stack direction="row">
                        <TextField
                            onChange={(e) => handleChangeItemId(e.target.value)}
                            onBlur={handleSearch}
                            onKeyDown={handleSearchKeyDown}
                            label={translateText(87)}
                            maxRows={1}
                            value={cisFilters.itemId}
                            inputProps={{ maxLength: 50 }}
                            size="large"
                            sx={{ width: "25em" }}
                        />
                        <SearchButton color="primary" onClick={handleSearch} />
                    </Stack>
                </Stack>
            </Stack>
        </>
    )
}
