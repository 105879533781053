import { ListItem, styled } from "@tm/components"

export const ListItemComponent = styled(ListItem)(({ theme }) => ({
    border: "1px solid transparent",
    borderLeftWidth: "2px",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
    borderRadius: "3px",
    "&:hover": {
        borderColor: theme.palette.primary.main,
    },
    flexWrap: "wrap",
}))
