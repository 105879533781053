import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenWorkTask(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { attachToWorkTask } = data

    if (attachToWorkTask && (attachToWorkTask.vehicleId || attachToWorkTask.customerId)) {
        this.props.attachToWorkTask({
            vehicle: attachToWorkTask.vehicleId && typeof attachToWorkTask.vehicleId === "string" ? attachToWorkTask.vehicleId : undefined,
            customer: attachToWorkTask.customerId && typeof attachToWorkTask.customerId === "string" ? attachToWorkTask.customerId : undefined,
        })
    }
}
