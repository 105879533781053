import { Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { ConfigParams, IMicros } from "@tm/models"
import { Button } from "@tm/controls"
import Morpheus from "@tm/morpheus"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-alternatives"]

function ErpInfoAlternativesComponent(props: Props) {
    const { translateText } = useLocalization()
    const { loading, erpInfo } = useErpInfo(props.data, "list")

    const show = props.isWholesalerPart
        ? erpInfo?.hasAlternatives
        : Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp || erpInfo?.hasAlternatives

    if (loading || !show) {
        return null
    }

    return (
        <Button
            onClick={props.onClick}
            icon="alternative-article"
            disabled={props.isWholesalerPart}
            layout={props.showOnlyIcon ? ["ghost"] : undefined}
            size={props.showOnlyIcon ? "l" : "m"}
        >
            {!props.showOnlyIcon ? translateText(710) : undefined}
        </Button>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info-alternatives">
                    <Suspense fallback={null}>
                        <ErpInfoAlternativesComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
