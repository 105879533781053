import { channel } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { BundleActions, BundleActionTypes } from "../../../business"
import { useContainer } from "../../../data/repositories"
import { GetCalculationDataRequest } from "../../../data/repositories/getCalculationData/model"
import { MainState } from "../../main"
import { MainActions, MainActionsType } from "../../main/business"
import { ExternState } from "./model"

export type ComponentActionType = BundleActionTypes | { type: "EXTERN_INCOMPLETE" }

const DEFAULT_STATE: ExternState = {
    getArticlesFailed: false,
    getArticlesInProgress: false,
}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): ExternState => {
    switch (action.type) {
        case "RESET": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "PREPARE_CALC_LOADED": {
            return {
                ...state,
                url: action.payload.taskUrl,
            }
        }
        case "CALCULATION_DATA_LOADING": {
            return {
                ...state,
                getArticlesInProgress: true,
            }
        }
        case "CALCULATION_DATA_LOADED": {
            return {
                ...state,
                getArticlesInProgress: false,
            }
        }
        case "EXTERN_INCOMPLETE": {
            return {
                ...state,
                getArticlesInProgress: false,
                getArticlesFailed: true,
            }
        }
        default: {
            return state
        }
    }
}

const getCalculationData =
    (taskId?: string): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const container = useContainer()
        const { manager } = getState()

        if (!manager.taskId && !taskId) {
            return
        }

        const req: GetCalculationDataRequest = { taskId: taskId ?? manager.taskId ?? "" }
        dispatch({ type: "CALCULATION_DATA_LOADING" })

        container
            .action("getCalculationData")(req)
            .then(
                (res) => {
                    dispatch({ type: "CALCULATION_DATA_LOADED", payload: res })
                    dispatch(MainActions.changeStep("calculation", { taskId }))
                },
                (error) => {
                    dispatch({ type: "EXTERN_INCOMPLETE" })
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: error, closeDelay: 3000, skin: "warning" })
                }
            )
            .then(() => {
                const { calculation } = getState()

                if (calculation.items?.some((x) => x.selectedPart)) {
                    channel("GLOBAL").subscribeOnce("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
                        dispatch(MainActions.updateTotals([...calculation.erpArticles, ...erpInfo]))
                    })
                }
            })
    }

const setDataFromHistory = (taskId: string, taskUrl: string): MainActionsType => ({ type: "PREPARE_CALC_LOADED", payload: { taskId, taskUrl } })

export type IActions = typeof Actions

export const Actions = { ...BundleActions, getCalculationData, setDataFromHistory }
