export function getTypeTextId(type: string) {
    switch (type) {
        case "TechnicalBulletin":
            return 12886
        case "TechnicalDocument":
            return 12887
        case "FittingInstruction":
            return 12885
        default:
            return undefined
    }
}
