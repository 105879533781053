export type AlertColor = "success" | "info" | "warning" | "error"

export type AlertMessage = {
    message: string
    severity?: AlertColor
}

export enum AlertMessageTypes {
    "HISTORY",
}
