import { color } from "csx"
import { Children, PropsWithChildren, ReactNode, useMemo } from "react"
import { Divider } from "@tm/components"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"

type Props = {
    title: ReactNode
    initiallyClosed: boolean
    isSelected?: boolean
}

export default function CustomPanel({ title, initiallyClosed, isSelected, children }: PropsWithChildren<Props>) {
    const style = useMemo(() => getStyle(), [])

    return (
        <PanelSection
            className={classes(style.panelWrapper, (isSelected && style.selected) || "")}
            collapsible
            title={title}
            initiallyClosed={initiallyClosed}
        >
            {!!Children.toArray(children).length && <Divider />}
            {children}
        </PanelSection>
    )
}

function getStyle() {
    // TODO: REMOVE DISPLAY NONE
    const theme = getStyleTheme()

    return useStyle({
        panelWrapper: {
            padding: ".2em 1em",
            border: "1px solid transparent",
            borderRadius: "3px",
            transition: theme.animation.transition,
            $nest: {
                ".panel__collapseicon": {
                    display: "none",
                },
                ".panel__content": {
                    paddingLeft: theme.margin.l,
                },
                "&:hover": {
                    borderColor: color(theme.colors.primary).lighten("20%").toString(),
                },
            },
        },
        selected: {
            borderColor: color(theme.colors.primary).lighten("20%").toString(),
        },
    })(CustomPanel)
}
