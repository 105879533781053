import { useLocalization } from "@tm/localization"
import { Table, Text } from "@tm/controls"
import { PriceType, ErpPrice } from "@tm/models"
import { useHidePriceTypes, useUser } from "@tm/context-distribution"
import GraduatedPricesComponent from "./graduated-prices"
import { getPriceDescription } from "../business"

type PriceTableProps = {
    prices?: Array<ErpPrice>
    graduatedPrices?: Array<ErpPrice>
    isReplacementPart: boolean
}

export default function PriceTable(props: PriceTableProps) {
    const { translateText, translate, currency } = useLocalization()
    const userSettings = useUser()?.userSettings
    const { isPriceTypeHidden } = useHidePriceTypes()
    const renderTypeCell = (item: ErpPrice) => {
        return <Table.Cell>{item.description || getPriceDescription(translateText, item.type, props.isReplacementPart)}</Table.Cell>
    }

    const renderPriceCell = (item: ErpPrice) => {
        let content = null
        if (item.type === PriceType.GraduatedPurchase) {
            if (props.graduatedPrices) {
                content = <GraduatedPricesComponent prices={props.graduatedPrices} />
            }
        } else {
            const modifiers: Array<"strong" | "highlight" | "strikethrough" | "danger"> = []
            if (item.type === PriceType.Purchase) {
                modifiers.push("highlight")
            } else if (item.type === PriceType.Strikethrough) {
                modifiers.push("strikethrough", "danger")
            }

            content = <Text modifiers={modifiers}>{currency(item.value, item.currencySymbol)}</Text>
        }

        return <Table.Cell className={item.type === PriceType.GraduatedPurchase ? "priceCell--graduated" : ""}>{content}</Table.Cell>
    }

    const renderVatCell = (item: ErpPrice) => {
        return <Table.Cell className={item.type === PriceType.GraduatedPurchase ? "vatCell--graduated" : ""}>{item.vat}%</Table.Cell>
    }

    const renderRebateCell = (item: ErpPrice) => {
        return (
            <Table.Cell className={item.type === PriceType.GraduatedPurchase ? "rebateCell--graduated" : ""}>
                {item.rebatePercent ? `${item.rebatePercent.round(0.01)}%` : ""}
            </Table.Cell>
        )
    }

    const { prices, graduatedPrices } = props

    if (!prices?.length && !graduatedPrices?.length) {
        return null
    }

    let standardPrices = prices ? [...prices] : []

    if (userSettings && !userSettings.showPurchasePrice) {
        standardPrices = standardPrices.filter((price) => !isPriceTypeHidden(price.type))
    }

    if (graduatedPrices?.[0]) {
        standardPrices.push({
            value: 0,
            vat: 0,
            currencySymbol: "",
            currencyCode_Iso_4217: "",
            type: PriceType.GraduatedPurchase,
            description: graduatedPrices[0].description,
            shortDescription: graduatedPrices[0].shortDescription,
        })
    }

    const columns = [
        <Table.Column className="typeCell" renderItemContent={renderTypeCell} />,
        <Table.Column className="priceCell" renderItemContent={renderPriceCell}>
            {translate(53)}
        </Table.Column>,
    ]

    if (standardPrices.some((p) => p.rebate)) {
        columns.push(
            <Table.Column className="rebateCell" renderItemContent={renderRebateCell}>
                {translate(54)}
            </Table.Column>
        )
    }

    columns.push(
        <Table.Column className="vatCell" renderItemContent={renderVatCell}>
            {translate(706)}
        </Table.Column>
    )

    return (
        <div className="price-table">
            <Table data={standardPrices} columns={columns} />
        </div>
    )
}
