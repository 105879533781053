import { RegisteredModels, FastCalculation } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"
import { publishToChannel } from "../../../../helpers"

export function useImportFastCalculation(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()

    const { mutateAsync } = useMutation(
        (importFastCalculationRequest: FastCalculation.ImportFastCalculationRequest) => Data.importFastCalculation(importFastCalculationRequest),
        {
            onSuccess: async (response, request) => {
                if (response) {
                    await enableCostEstimation(request.workTaskId)
                    if (request.parts?.length || request.customParts?.length || request.oeParts?.length) {
                        publishToChannel(request.workTaskId)
                    }
                    handleBasketUpdateWorkflow(request.workTaskId, response)
                }
                // Save work task in the DB
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
            },
        }
    )

    const importFastCalculation = useCallback(
        (importFastCalculationRequest: FastCalculation.ImportFastCalculationRequest) => {
            return mutateAsync(importFastCalculationRequest)
        },
        [mutateAsync]
    )

    return importFastCalculation
}
