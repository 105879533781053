import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Alert, Button, Icon, Stack, TextField, Typography, AlertProps } from "@tm/components"
import { DatSettings as DatSettingsModel } from "@tm/context-distribution"
import { setDatSettings } from "../../../data"
import { SaveDatCredentialsRequest } from "../../../data/model"

type Props = {
    datSettings: DatSettingsModel
}
type InfoMessage = {
    message: string
    type: AlertProps["severity"]
}

export default function DatCredentialSettings(props: Props) {
    const [isSaving, setIsSaving] = useState(false)
    const [customerNumber, setCustomerNumber] = useState("")
    const [userName, setUserName] = useState("")
    const [customerPassword, setCustomerPassword] = useState("")
    const [infoMessage, setInfoMessage] = useState<InfoMessage>({ message: "", type: "success" })
    const [disabledCustomer, setDisabledCustomer] = useState(true)
    const [customerNumberError, setCustomerNumberError] = useState(false)
    const [userNameError, setUserNameError] = useState(false)
    const [customerPasswordError, setCustomerPasswordError] = useState(false)
    const passwordPlaceholder = "**********"

    const { translateText } = useLocalization()

    useEffect(() => {
        if (props.datSettings.userName) {
            setUserName(props.datSettings.userName)
        }
        if (props.datSettings.customerNumber) {
            setCustomerNumber(props.datSettings.customerNumber)
        }
        if (props.datSettings.isPasswordSet === true) {
            setCustomerPassword(passwordPlaceholder)
        }
    }, [props.datSettings])

    function setCustomerActive() {
        setDisabledCustomer(!disabledCustomer)
        setCustomerPassword("")
    }
    async function saveCustomerData() {
        if (!customerNumber || !userName || !customerPassword) {
            if (!customerNumber) {
                setCustomerNumberError(!customerNumber)
            }
            if (!userName) {
                setUserNameError(!userName)
            }
            if (!customerPassword) {
                setCustomerPasswordError(!customerPassword)
            }
            return
        }
        setDisabledCustomer(!disabledCustomer)
        setIsSaving(true)

        const data: SaveDatCredentialsRequest = {
            customerNo: customerNumber,
            username: userName,
            password: customerPassword,
        }

        try {
            await setDatSettings(data)
            setInfoMessage({
                message: translateText(13451),
                type: "success",
            })
        } catch (error) {
            setInfoMessage({
                message: translateText(13452),
                type: "error",
            })
        } finally {
            setIsSaving(false)
            setCustomerNumberError(false)
            setUserNameError(false)
            setCustomerPasswordError(false)
        }
    }

    return (
        <Stack spacing={2}>
            <Typography variant="body2">{translateText(13450)}</Typography>
            {infoMessage.message !== "" && <Alert severity={infoMessage.type}>{infoMessage.message}</Alert>}
            <Stack direction="row" spacing={1} width="70%">
                <TextField
                    fullWidth
                    sx={{ margin: "0" }}
                    value={customerNumber}
                    onChange={(e) => setCustomerNumber(e.target.value)}
                    label={translateText(102)}
                    disabled={disabledCustomer || isSaving}
                    error={customerNumberError}
                    helperText={translateText(12741)}
                />
                <TextField
                    fullWidth
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    label={translateText(186)}
                    disabled={disabledCustomer || isSaving}
                    error={userNameError}
                    helperText={translateText(13449)}
                />
                <TextField
                    fullWidth
                    value={customerPassword}
                    onChange={(e) => setCustomerPassword(e.target.value)}
                    label={translateText(187)}
                    type="password"
                    disabled={disabledCustomer || isSaving}
                    error={customerPasswordError}
                    helperText={translateText(13448)}
                />
                <Button
                    onClick={() => setCustomerActive()}
                    startIcon={<Icon name="edit_change" />}
                    size="medium"
                    disabled={!disabledCustomer || isSaving}
                />
                <Button
                    onClick={() => saveCustomerData()}
                    color="success"
                    startIcon={<Icon name="check" />}
                    size="medium"
                    disabled={disabledCustomer || isSaving}
                />
            </Stack>
        </Stack>
    )
}
