import { Alert, Button, Paper, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { HTMLAttributes, forwardRef } from "react"
import { VinSearchButtons } from "./VinSearchButtons"
import { VehicleSearchError } from "./index"

export type Props = HTMLAttributes<HTMLElement> & {
    query: string
    onDATSearchButtonClick: () => void
    onEdsSearchButtonClick: () => void
    onClickRequestVin: () => void
    error?: VehicleSearchError
    disableEdsSearch?: boolean
    vehicleType: VehicleType
}

export const VehicleSearchPaper = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const { children, query, onDATSearchButtonClick, onEdsSearchButtonClick, onClickRequestVin, error, disableEdsSearch, vehicleType } = props
    const { translateText } = useLocalization()

    return (
        <Paper ref={ref} sx={(theme) => ({ p: 0, border: `1px solid ${theme.palette.primary.main}`, borderTop: "unset" })}>
            <VinSearchButtons
                query={query}
                onDATSearchButtonClick={onDATSearchButtonClick}
                onEdsSearchButtonClick={onEdsSearchButtonClick}
                disableEdsSearch={disableEdsSearch}
                vehicleType={vehicleType}
            />

            <Stack justifyContent="center" px={1} spacing={1}>
                {error && (
                    <Alert variant="filled" severity="error" sx={{ whiteSpace: "initial" }}>
                        {error.message}
                    </Alert>
                )}
                {error?.type === "searchError" && (
                    <Button
                        onMouseDown={(e) => {
                            e.stopPropagation()
                            onClickRequestVin()
                        }}
                    >
                        {translateText(1704)}
                    </Button>
                )}
            </Stack>
            {children}
        </Paper>
    )
})
