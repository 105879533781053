import { useUser } from "@tm/context-distribution"
import { Text, Icon } from "@tm/controls"
import { style } from "typestyle"
import { getLocalization } from "@tm/localization"
import { getComponentStyles } from "./styles"

type Props = {
    showGeneralContactInfo: boolean
    showUserName: boolean
}

function UserInfoComponent({ showGeneralContactInfo, showUserName }: Props) {
    const className = style(getComponentStyles())
    const { userContext } = useUser()
    const { translate } = getLocalization()

    if (!userContext) {
        return null
    }

    return (
        <div className={className}>
            {showGeneralContactInfo && <Icon name="user" className={`${className}__icon`} />}
            <div className={`${className}__content`}>
                {showGeneralContactInfo && (
                    <>
                        <Text modifiers={["block"]}>{userContext.generalContact?.name}</Text>
                        <Text size="xs" modifiers={["block"]}>
                            {translate(1780)} {userContext.generalContact?.personnelNumber || "-"}
                        </Text>
                    </>
                )}
                {showUserName && <Text modifiers={["block"]}> {userContext.account?.username || "-"}</Text>}
            </div>
        </div>
    )
}

export default UserInfoComponent
