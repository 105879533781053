import { CisBundleParams } from "@tm/models"
import { registerActions } from "./data"
import { PdfRequest } from "./data/model"
import { loadPdf, pdfExists } from "./data/repositories/cis/orderConfirmation"

export const version = {
    name: "cis",
    version: "1.0",
}

let bundleParams: CisBundleParams

export function initCisBundle(params: CisBundleParams) {
    bundleParams = params
    registerActions()
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

let existPdfInterval = 0
let intervalCount = 0
export function loadOrderConfirmationPdf(request: PdfRequest): [Promise<Array<number>>, () => void] {
    const promise = new Promise<Array<number>>((resolve, reject) => {
        window.clearInterval(existPdfInterval)

        existPdfInterval = window.setInterval(() => {
            intervalCount++
            if (intervalCount > 8) {
                window.clearInterval(existPdfInterval)
                reject()
                return
            }

            pdfExists(request)
                .then((response) => {
                    if (response?.existsPdf) {
                        window.clearInterval(existPdfInterval)
                        loadPdf(request).then((response) => {
                            if (!response) {
                                reject()
                            } else {
                                resolve(response)
                            }
                        }, reject)
                    }
                })
                .catch(() => window.clearInterval(existPdfInterval))
        }, 2000)
    })

    return [promise, () => window.clearInterval(existPdfInterval)]
}
