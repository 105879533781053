import { useLocalization } from "@tm/localization"
import { CarModelDetails, CarModelSeries, CarManufacturer } from "@tm/models"

type Props = {
    modelDetails: CarModelDetails
    modelSeries: CarModelSeries
    manufacturer: CarManufacturer
}

function SelectionsComponent({ modelDetails, modelSeries, manufacturer }: Props) {
    const { translateText } = useLocalization()

    return (
        <div className="selections">
            <div className="col">
                <div className="panel">
                    <div className="subtitle">{translateText(71)}</div>
                    <div className="selections__headline">{manufacturer.description}</div>
                </div>
            </div>
            <div className="col">
                <div className="panel">
                    <div className="subtitle">{translateText(73)}</div>
                    <div className="selections__headline">{modelSeries.description}</div>
                </div>
            </div>
            <div className="col">
                <div className="panel">
                    <div className="subtitle">{translateText(74)}</div>
                    <div className="selections__headline">{modelDetails.description}</div>
                </div>
            </div>
        </div>
    )
}

export default SelectionsComponent
