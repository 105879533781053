import { useWorkTask } from "@tm/context-distribution"
import { useMemo } from "react"
import { StartParams } from "../models"

export function useFilterStorageKey(startParams: StartParams) {
    const { workTaskId } = useWorkTask() ?? {}

    return useMemo(() => {
        switch (startParams.type) {
            case "uninode":
                return `${workTaskId}-${startParams.type}-${startParams.treeId}-${startParams.nodeId}`
            case "node":
                return `${workTaskId}-${startParams.type}-${startParams.treeId}-${startParams.nodeId}`
            case "productgroups":
                return `${workTaskId}-${startParams.type}-${startParams.treeId}-${startParams.nodeId}-${startParams.productGroupIds}`
            case "direct":
                return `${workTaskId}-${startParams.type}-${startParams.searchFilter}-${startParams.query}`
            case "unisearch":
                return `${workTaskId}-${startParams.type}-${startParams.query}`
            case "synonym":
                return `${workTaskId}-${startParams.type}-${startParams.query}`
            default:
                return `${workTaskId}-unknown`
        }
    }, [startParams, workTaskId])
}
