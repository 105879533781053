import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { PropsWithChildren, useState } from "react"

type Props = {
    itemsPrice: string
    itemsCount: number
    title: string
}

export default function CustomAccordion({ itemsPrice, itemsCount, title, children }: PropsWithChildren<Props>) {
    const { translateText } = useLocalization()
    const [isOpen, setIsOpen] = useState(itemsCount > 0)

    const handleOpenAccordion = () => {
        setIsOpen((prevValue) => !prevValue)
    }

    const renderCollapsibleHeader = () => {
        return (
            <Box display="flex" gap="1em" mr="1em">
                <Typography fontWeight="600" mr="1em" className="articles-count">
                    {`${itemsCount} ${translateText(479)}`}
                </Typography>
                <Typography fontWeight="600" className="total-price">
                    {itemsPrice}
                </Typography>
            </Box>
        )
    }

    return (
        <StyledAccordion expanded={isOpen} disableGutters elevation={0} onChange={handleOpenAccordion}>
            <StyledAccordionSummary>
                <Typography fontSize="medium" fontWeight="600" flex="1">
                    {title}
                </Typography>
                {renderCollapsibleHeader()}
            </StyledAccordionSummary>
            <AccordionDetails sx={{ marginBottom: "-1em" }}>{children}</AccordionDetails>
        </StyledAccordion>
    )
}

const StyledAccordion = styled(Accordion)({
    backgroundColor: "#efefef",
    borderRadius: "3px",
    marginBottom: "1rem",
    boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.13)",
    fontWeight: "bold",
    "&:before": {
        backgroundColor: "transparent",
    },
})

const StyledAccordionSummary = styled(AccordionSummary)({
    borderBottom: "1px solid #e2e2e2",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    ml: "1em",
})
