import { Box, Image } from "@tm/components"
import { Collapsible } from "@tm/controls"
import { useLocalization } from "@tm/localization"

type Props = {
    base64Image: string
}

export function VehicleImage({ base64Image }: Props) {
    const { translateText } = useLocalization()
    return (
        <Collapsible name={translateText(1633)} initiallyOpened skin="dark">
            <Box display="flex" justifyContent="center">
                <Image src={`data:image;base64,${base64Image}`} />
            </Box>
        </Collapsible>
    )
}
