import { StateCreator } from "zustand"
import { EdsStore } from ".."
import { NavigationSteps, TreeOptions } from "../../../models"
import { MainSlice } from "./model"

export const initialState: MainSlice = {
    main: {
        treeOption: TreeOptions.Category,
        view: NavigationSteps.Vehicle,
        initializedFromUrl: false,
        worktaskId: "",
    },
    vehicle: {
        isLoading: false,
        error: undefined,
        isLocked: false,
        data: {
            vin: "",
        },
    },
    treeNavigation: {
        isLoading: false,
        isError: false,
    },
    graphic: {
        loading: false,
        error: false,
    },
}

export const createMainSlice: StateCreator<EdsStore, [["zustand/devtools", never]], [], MainSlice> = (set) => ({
    ...initialState,
})
