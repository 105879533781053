import { UserContext, UserModuleType } from "@tm/models"
import { sanitize } from "@tm/utils"

export function getSanitizedPrivacyPolicyUrl(translateText: (key: string | number) => string, userContext?: UserContext) {
    const privacyPolicyLink = userContext?.modules?.find((x) => x.type === UserModuleType.Partslife)?.dsgvoUrl ?? "#"
    const notSafePrivacyPolicy = translateText(13582).replace("{0}", `<a href="${privacyPolicyLink}" target="_blank">${translateText(13632)}</a>`)
    return sanitize(notSafePrivacyPolicy, { ADD_ATTR: ["target"] })
}

export function getGenderTitle(translateText: (key: string | number) => string) {
    return [
        {
            key: 1,
            value: translateText(145),
        },
        {
            key: 2,
            value: translateText(146),
        },
    ]
}
