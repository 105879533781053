import { IBundle } from "@tm/morpheus"
import Startup from "./components/startup"

import AddArticlesToRdBasket from "./micros/add-items-to-rd-basket"
import AddRtToRdBasket from "./micros/add-rt-to-rd-basket"
import AddArticlesToScBasket from "./micros/add-items-to-sc-basket"
import AddRtToScBasket from "./micros/add-rt-to-sc-basket"
import OpenRtModal from "./micros/open-kva-modal"
import AddArticlesToRdBasketDmg from "./micros/add-items-rt-to-rd-basket"
import SCEurotaxOpenOeArticleList from "./micros/eurotax-sc-openOeArticleList"
import RDEurotaxOpenOeArticleList from "./micros/eurotax-rd-openOeArticleList"
import openEdsOeArticleList from "./micros/eds-rd-openOeArticleList"
import { initStandaloneBundle, version } from "./utils"
import { DVSE } from "./data/models/smartClientDVSEObj"

declare global {
    interface Window { DVSE: DVSE }
}

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    micros: [
        //rd
        AddArticlesToRdBasket,
        AddRtToRdBasket,
        AddArticlesToRdBasketDmg,
        RDEurotaxOpenOeArticleList,
        openEdsOeArticleList,

        //sc
        AddArticlesToScBasket,
        AddRtToScBasket,
        SCEurotaxOpenOeArticleList,

        //general
        OpenRtModal,
    ],
    components: [
        Startup
    ],
    init: initStandaloneBundle
}


export default bundle
