import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, FormControlLabel, Typography } from "@tm/components"
import { Item, OrderOptions } from "@tm/models"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin, RadioButtonSmallWithMargin, RadioGroupGrid } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetPayment(id: string, description: string, selectedOptionsItem?: Item, updateErpInformation?: boolean, updateOrderOptions?: boolean): void
}
export default function PaymentOptionsComponent({ options: { paymentOptions, selectedOptionsItem }, onSetPayment, disableFields }: Props) {
    const { translate } = useLocalization()
    const [selectedPaymentId, setSelectedPaymentId] = useState<string>()

    useEffect(() => {
        if (paymentOptions?.paymentOptions.length) {
            const defaultPayment = paymentOptions.paymentOptions.find((payment) => !!payment.isSelected)
            const paymentId = defaultPayment ? defaultPayment.id : paymentOptions.paymentOptions[0].id
            setSelectedPaymentId(paymentId)
        }
    }, [paymentOptions])

    function handlePaymentModeChange(_: unknown, id: string) {
        if (paymentOptions?.paymentOptions.length) {
            setSelectedPaymentId(id)
            const firstPaymentMatch = paymentOptions.paymentOptions.find((paymentOption) => paymentOption.id === id)
            if (firstPaymentMatch) {
                onSetPayment(
                    id,
                    firstPaymentMatch.description,
                    selectedOptionsItem,
                    paymentOptions.updateErpInformationOnChange,
                    paymentOptions.updateOrderOptionsOnChange
                )
            }
        }
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="payment" />
                <Typography variant="h4"> {translate(440)}</Typography>
            </BoxWithAlignItems>
            <RadioGroupGrid onChange={handlePaymentModeChange}>
                {paymentOptions?.paymentOptions.map((mode) => {
                    return (
                        <Box key={mode.id} display="flex">
                            <FormControlLabel
                                value={mode.id}
                                control={<RadioButtonSmallWithMargin disabled={disableFields} checked={mode.id === selectedPaymentId} />}
                                label={<Typography variant="body2">{mode.description}</Typography>}
                            />
                        </Box>
                    )
                })}
            </RadioGroupGrid>
        </BoxWithColumnPaddingRight>
    )
}
