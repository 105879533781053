export type ServiceItem = {
    lastReadTime: string
    serviceItemGroups: ServiceItemGroup[]
}

export type ServiceItemGroup = {
    displayOrder: string
    groupItems: GroupItem[]
    id: string
    name: string
    type: string
}

export type GroupItem = {
    description: string
    id: string
    max: string
    min: string
    plausible: string
    sytem: string
    type: string
    units: string
    value: string
}

export enum EServiceItemGroupType {
    CategoryBattery = "Category_Battery",
    CategoryService = "Category_Service",
    CategoryOdometer = "Category_Odometer",
}

export enum EServiceGroupItemType {
    BatteryVoltage = "BatteryVoltage",
    VehicleCheckServiceDueDate = "VehicleCheck_ServiceDueDate",
    VehicleCheckDistanceUntilService = "VehicleCheck_DistanceUntilService",
    EngineOilDistanceUntilService = "EngineOil_DistanceUntilService",
    Odometer = "Odometer",
}
