import { SummaryState } from "./model"

export const DEFAULT_STATE: SummaryState = {
    fields: {
        registrationNos: [],
        processName: "",
    },
    useCatalogHourlyRates: true,
    checkVinLoading: false,
    isGermanOrAustrianVehicle: true,
}
