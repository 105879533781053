import { uniqueId } from "@tm/utils"
import { EDatRepairworkType, ICalculationItem, Work, UpdatedTotals } from "../../models"
import { GetCalculationDataResponse } from "./model"

export function mapDatCalculationData({ items, works, ...rest }: GetCalculationDataResponse): GetCalculationDataResponse {
    return {
        ...rest,
        items: items?.map(mapOeArticle),
        works: works?.map(mapWork),
        totals: mapTotals(rest.totals),
        initialSparePartsPrice: rest.initialSparePartsPrice,
    }
}

function mapTotals(totals: UpdatedTotals): UpdatedTotals {
    return {
        totalSparePartsPrice: totals.totalSparePartsPrice ?? 0,
        additionalCosts: totals.additionalCosts ?? 0,
        totalWorksPrice: totals.totalWorksPrice ?? 0,
        totalAdditionalWorksCostsPrice: totals.totalAdditionalWorksCostsPrice ?? 0,
        totalNetPrice: totals.totalNetPrice ?? 0,
        totalGrossPrice: totals.totalGrossPrice ?? 0,
        surchargeOrDiscountUpdated: totals.surchargeOrDiscountUpdated ?? 0,
        updatedOeArticles: totals.updatedOeArticles,
    }
}

function mapOeArticle(item: any): ICalculationItem {
    return {
        alternativeParts: item.parts,
        oeArticle: { ...item.oeArticle, type: item.oeArticle.type ?? 0 },
        isSelected: item.isSelected,
        uniqueId: uniqueId(),
    }
}

function mapWork(work: any): Work {
    return {
        ...work,
        type: work.type || EDatRepairworkType.LabourWork,
    }
}
