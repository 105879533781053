import { useUser, useShowNewArticleList } from "@tm/context-distribution"
import { ArticleAttributes, channel, ConfigParams, SystemType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute, TmaHelper, uniqueId } from "@tm/utils"
import { useCallback, useEffect } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { Repositories } from "../../data"
import { GetOeAttributesResponse } from "../../data/model"
import { getBundleParams } from "../../utils"
import * as Helpers from "../list/business/helpers"
import { getArticleDetailsRoute, getArticleListRoute, Route } from "./business"

type ManagerProps = {
    handleDetailsRequest?: boolean
}

type RouteParams = {
    workTaskId?: string
}

function ManagerComponent({ handleDetailsRequest }: ManagerProps) {
    const { params } = useRouteMatch<RouteParams>()
    const history = useHistory()
    const { userContext } = useUser() || {}
    const { partsRoutes } = getBundleParams()
    const location = useLocation()
    const showNewArticleList = useShowNewArticleList()

    const openRoute = useCallback(
        (routeToOpen: Route) => {
            let matchParams = params
            // To avoid spending too much time fixing this problem for V1, we decided to just check
            // for the string "undefined" before generating a new uniqueID.
            // We did not find the source where the "undefined" was set previously.
            // https://jira.dvse.de/browse/NEXT-28069
            if (!params.workTaskId || params.workTaskId === "undefined") {
                matchParams = { ...params, workTaskId: encodeUniqueId(uniqueId()) }
            }

            let url = renderRoute(routeToOpen.route, matchParams)
            if (routeToOpen.queryString) {
                url += `?${routeToOpen.queryString}`
            }

            if (routeToOpen.inModal) {
                if (routeToOpen.callNewModal) {
                    const searchParams = new URLSearchParams(location.search)
                    searchParams.set("mp", routeToOpen.route)
                    if (routeToOpen.queryString) {
                        searchParams.set("ms", routeToOpen.queryString)
                    }
                    searchParams.delete(`(1)`) // close Morpheus modal // Morpheus.closeView("1") doesn't work
                    history.push({ pathname: location.pathname, search: searchParams.toString() })
                } else {
                    Morpheus.showView("1", url)
                }
            } else {
                history.push(url)
            }
        },
        [history, location, params]
    )

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            channel("WORKTASK").subscribe("PARTS/REQUEST_LIST", async (request) => {
                if (!!request.oePositions?.length && request.tecDocTypeId) {
                    const promises: Promise<GetOeAttributesResponse | ArticleAttributes[] | undefined>[] = []

                    request.oePositions?.forEach((oePosition) => {
                        oePosition.parts?.forEach((part) => {
                            if (!part.attributes && !!part.number) {
                                const req = Helpers.createGetOeAttributesRequest(request.tecDocTypeId!, part.number, [])
                                promises.push(Repositories.getOeAttributes(req).then((res) => (part.attributes = res?.articleAttributes)))
                            }
                        })
                    })

                    await Promise.allSettled(promises)
                }

                // -> usually request comes from external api post message => set search context
                if (request.inModal && !request.skipTma) {
                    if (!!request.uniSearch && request.uniSearch.query?.length) {
                        TmaHelper.UniParts.Search.SubmitExternal(request.uniSearch?.query)
                    } else if (request?.productGroups?.ids) {
                        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SubmitExternal(request.productGroups.ids.join(","))
                    } else {
                        TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SubmitExternal(
                            request?.direct?.query || request?.synonym?.query || request?.general?.query
                        )
                    }
                }

                const listRoute = getArticleListRoute(request, partsRoutes, showNewArticleList)
                if (listRoute) {
                    openRoute(listRoute)
                }
            })
        )
        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [location, openRoute, partsRoutes])

    useEffect(() => {
        const unsubscriptions: Array<() => void> = []
        if (handleDetailsRequest !== false) {
            unsubscriptions.push(
                channel("WORKTASK").subscribe("PARTS/REQUEST_ARTICLE_DETAILS", (request) => {
                    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
                    const detailsRoute = getArticleDetailsRoute(request, !!articleDetailsInModal, partsRoutes)
                    if (detailsRoute) {
                        openRoute(detailsRoute)
                    }
                })
            )
        }

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [handleDetailsRequest, openRoute, partsRoutes])

    useEffect(() => {
        // if parts manager is used in another system (RD/SC we have to prevent these console.errors)
        if (userContext?.system.systemType != SystemType.Next) {
            return
        }

        if (!partsRoutes) {
            console.error(`Parts Manager: 'routes' was not passed as property in the app config`)
            return
        }

        if (!partsRoutes.vehicleParts || !partsRoutes.vehicleParts.list || !partsRoutes.vehicleParts.details) {
            console.error(`Parts Manager: 'routes.vehicleParts' was not passed as property in the app config or contained no list and details route`)
            return
        }

        if (!partsRoutes.universalParts || !partsRoutes.universalParts.list || !partsRoutes.universalParts.details) {
            console.error(
                `Parts Manager: 'routes.universalParts' was not passed as property in the app config or contained no list and details route`
            )
            return
        }

        if (!partsRoutes.directSearch || !partsRoutes.directSearch.list || !partsRoutes.directSearch.details) {
            console.error(`Parts Manager: 'routes.directSearch' was not passed as property in the app config or contained no list and details route`)
        }
    }, [userContext, partsRoutes])

    return null
}

export default ManagerComponent
