import { AsyncAction } from "@tm/morpheus"
import { Vehicle, Customer, getWorkTaskId, channel } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { ManagerState, WorkTaskFunctions } from "./models"
import { ComponentActionType } from "."

function init(): AsyncAction<ComponentActionType, ManagerState> {
    return (dispatch) => {
        // subscribe to worktask channel and get the loaded vehicle and customer
        const unsubs: Function[] = []
        let hasUnsubscribed = false
        getWorkTaskId((workTaskId) => {
            if (hasUnsubscribed) {
                return
            }
            const wtChannel = channel("WORKTASK", workTaskId)
            unsubs.push(wtChannel.subscribe("VEHICLE/EDITED", (vehicle) => wtChannel.publish("VEHICLE/LOADED", vehicle), true))
        })
        return function unsubscribeChannels() {
            unsubs.forEach((unsub) => unsub())
            unsubs.splice(0)
            hasUnsubscribed = true
        }
    }
}

function sendWorkTask(workTask?: WorkTaskInfo): AsyncAction<ComponentActionType, ManagerState> {
    return (dispatch) => {
        dispatch({ type: "WORKTASK_LOADED", payload: workTask })
        dispatch(sendCustomer(workTask && workTask.customer))
        dispatch(sendVehicle(workTask && workTask.vehicle))
    }
}

function sendCustomer(customer?: Customer): ComponentActionType {
    return { type: "CUSTOMER_SET", payload: customer }
}

function sendVehicle(vehicle?: Vehicle): ComponentActionType {
    return { type: "VEHICLE_SET", payload: vehicle }
}

function setWorkTaskFunctions(functions: WorkTaskFunctions): ComponentActionType {
    return { type: "SET_WORK_TASK_FUNCTIONS", payload: functions }
}

export type IActions = typeof Actions

export const Actions = {
    init,
    sendWorkTask,
    sendCustomer,
    sendVehicle,
    setWorkTaskFunctions,
}
