export enum ECalcArticle {
    None = 0,
    Article = 1,
    OeArticle = 2,
    AlternativeArticle = 3,
    OeAlternativeArticle = 4,
    ConsumableArticle = 5,
}

export enum ECalcLink {
    None = 0,
    ArticleDetail = 1,
    UniSearch = 2,
    DirectSearch = 3,
    OESearch = 4,
    ArticleList = 5,
    FastTD = 6,
    MaintenancePlanPrint = 7,
}

export enum ECalcLinkTarget {
    None = 0,
    /// <summary>
    /// Opens in a Popup eg. Colorbox in Redesign
    /// </summary>
    Popup = 1,
    /// <summary>
    /// Opens in the root
    /// The Redesign could run in nested IFrames, this will redirect highest redesign
    /// </summary>
    Root = 2,
    /// <summary>
    /// Redirects the current context, eg. IFrame or the App is running in.
    /// </summary>
    Current = 3,
    /// <summary>
    /// Opens a new Tab
    /// </summary>
    NewTab = 4,
}

export enum ECalcPrice {
    None = 0,
    VatExcluded = 1,
    VatIncluded = 2,
}

export enum ECalcResult {
    None = 0,
    Articles = 1,
    Consumables = 2,
    RepairTimes = 3,
    Total = 4,
}

export enum ESearchArea {
    /// <summary>
    /// Manufacturer article Numbers
    /// </summary>
    EArtnr = 1,
    /// <summary>
    /// OE? References
    /// </summary>
    // eslint-disable-next-line camelcase
    OE_Referenzen = 2,
    /// <summary>
    /// ?
    /// </summary>
    Gebrauchsnummern = 4,
    /// <summary>
    /// Replacement numbers
    /// </summary>
    Ersatznummern = 8,

    /// <summary>
    /// Standardwert für alle oben gelisteten
    /// </summary>
    Hersteller = 15,

    EAN = 16,
    Handelsreferenz = 96,

    /// <summary>
    /// nur für anwendungs / debugzwecke
    /// </summary>
    ALL = 2047,
}
