import { Box } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { LightableText } from "../StyledComponents"

type Props = {
    phone?: string
    lightMode?: boolean
}

export default function PhoneComponent({ phone, lightMode }: Props) {
    const { translate } = useLocalization()

    return (
        <Box display="flex">
            <LightableText variant="body2" lightMode={lightMode}>
                {translate(1107)}
            </LightableText>
            <LightableText variant="body2" lightMode={lightMode} fontWeight="bold" ml={0.5}>
                {phone}
            </LightableText>
        </Box>
    )
}
