import { ERIKContentService } from "@tm/data"

export function getIcon(fileKind: ERIKContentService.FileKind) {
    switch (fileKind) {
        case ERIKContentService.FileKind.Document:
            return "document"
        case ERIKContentService.FileKind.Image:
            return "pictures"
        default:
            return "document"
    }
}
