import { Channel, MessageBus } from "@tm/hermes"
import { DMS } from "@tm/models"

const mb = new MessageBus<BundleChannels>()
mb.channel("CONNECTION").configure({ countPerType: 1 })
mb.channel("SETTINGS").configure({ countPerType: 1 })
;(window as any).__DMS_MESSAGEBUS__ = mb

export function connectionChannel(): Channel<BundleChannels, "CONNECTION"> {
    return mb.channel("CONNECTION")
}

export function settingsChannel(): Channel<BundleChannels, "SETTINGS"> {
    return mb.channel("SETTINGS")
}

export function messageChannel(): Channel<BundleChannels, "MESSAGE"> {
    return mb.channel("MESSAGE")
}

export interface BundleChannels {
    CONNECTION: ConnectionChannelType
    SETTINGS: SettingsChannelType
    MESSAGE: MessageChannelType
}

export type ConnectionChannelType = { REQUEST_CONNECTION_STATUS: { forceReload?: boolean } } & {
    CONNECTION_STATUS_RECEIVED: DMS.ShowConnectionStatusResponse
}

export type SettingsChannelType = { LOAD_SETTINGS: {} } & { SETTINGS_LOADED: DMS.ShowSettingsResponse }

export type MessageChannelType = { SEND_DMS_REQUEST: DMS.DmsRequest } & { DMS_RESPONSE_RECEIVED: DMS.DmsResponse }
