import { Box, Image } from "@tm/components"
import { Warehouse } from "@tm/models"
import { isManufacturerAvailable } from "../utils"

type Props = {
    warehouseTableCurrentItem: Warehouse
}

export const WarehouseTableCurrentItemImage = (props: Props) => {
    let iconUrl = "/styles/coparts/images/availability_5.png"
    if (props.warehouseTableCurrentItem.isDefault) {
        iconUrl = "/styles/coparts/images/availability_2.png"
    } else if (isManufacturerAvailable(props.warehouseTableCurrentItem)) {
        iconUrl = "/styles/coparts/images/availability_7.png"
    }

    return (
        <Box>
            <Image src={iconUrl} className="availability__standalone-image" />
        </Box>
    )
}
