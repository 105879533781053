import { Box, Icon, Typography } from "@tm/components"

type Props = {
    text: string
}

export default function JobStatus({ text }: Props) {
    return (
        <Box display="flex" height="24px">
            <Box display="flex" alignItems="center" padding="4px" borderRadius="2px 0px 0px 2px" sx={{ backgroundColor: "#e2e2e2" }}>
                <Icon width="1em" height="1em" name="check" />
            </Box>
            <Typography variant="body2" border="2px solid #e2e2e2" padding="0 2px" borderRadius="0px 2px 2px 0px">
                {text}
            </Typography>
        </Box>
    )
}
