import { PropsWithChildren } from "react"
import { Text } from "@tm/controls"
import { styled } from "@tm/components"

export function AutosuggestHit({ children }: PropsWithChildren<{}>) {
    return <Text modifiers="strong">{children}</Text>
}

const CustomStrong = styled("strong")(({ theme }) => ({
    color: theme.palette.highlight.main,
}))

export function AutosuggestHitMui({ children }: PropsWithChildren<{}>) {
    return <CustomStrong>{children}</CustomStrong>
}
