import { Box, Checkbox } from "@tm/components"
import { GetMemoVKN_Out_V2 } from "@tm/models"
import { useState } from "react"
import { MainItemElement } from "../../shared"

type VehicleMemoType = "manufacturer" | "model" | "tecdoc" | "engine-code"

type Props = {
    section: VehicleMemoType
    data: GetMemoVKN_Out_V2
    isConfirmed?: boolean
    handleConfirmProp?(section: string): void
}

function VehicleMemoInfo(props: Props) {
    const { data: { mainItems }, section, isConfirmed, handleConfirmProp } = props
    const [confirmed, setConfirmed] = useState(isConfirmed)

    if (!mainItems)
        return null

    const handleConfirm = () => {
        setConfirmed(prev => !prev)
        handleConfirmProp?.(section)
    }

    return <>
        <Box id={section}>
            {mainItems.map((item, idx) => <MainItemElement item={item} key={idx} />)}
        </Box>

        {!isConfirmed && <Box sx={{ mb: 2, marginLeft: "auto" }}>
            <Checkbox onClick={handleConfirm} sx={{ mt: 1, mr: 1 }} />
        </Box>}
    </>
}

export default VehicleMemoInfo