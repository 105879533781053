import { TruckManufacturer } from "@tm/models"
import { callTrucksService } from "."

export type ManufacturersResponse = {
    topManufacturers: Array<TruckManufacturer>
    manufacturers: Array<TruckManufacturer>
}

type ShowTopManufacturersResponse = {
    topManufacturers: Array<TruckManufacturer>
}

type ShowAllManufacturersRequest = {
    priority?: number
}

export function showTopManufacturers() {
    return callTrucksService<{}, ShowTopManufacturersResponse>("ShowTopManufacturers", {})
}

export function showAllManufacturers(priority?: number) {
    return callTrucksService<ShowAllManufacturersRequest, ManufacturersResponse>("ShowAllManufacturers", { priority })
}

type ShowManufacturersByArticleRequest = {
    articleId: number
}

export function showManufacturersByArticle(request: ShowManufacturersByArticleRequest) {
    return callTrucksService<ShowManufacturersByArticleRequest, ManufacturersResponse>("ShowManufacturersByArticle", request)
}

type ShowManufacturersByEngineCodeRequest = {
    engineCode: string
}

export function showManufacturersByEngineCode(request: ShowManufacturersByEngineCodeRequest) {
    return callTrucksService<ShowManufacturersByEngineCodeRequest, ManufacturersResponse>("ShowManufacturersByEngineCode", request)
}

type ShowManufacturerDetailsRequest = {
    manufacturerId: number
}

type ShowManufacturerDetailsResponse = {
    manufacturerDetails: TruckManufacturer
}

export function showManufacturerDetails(request: ShowManufacturerDetailsRequest) {
    return callTrucksService<ShowManufacturerDetailsRequest, ShowManufacturerDetailsResponse>("ShowManufacturerDetails", request)
}
