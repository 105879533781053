import { Box, BoxProps, Button, Checkbox, ListItemIcon, Loader, TextField, Typography } from "@tm/components"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { Genart } from "../../../data/models"
import { useFastServiceStore } from "../../../data"
import { ListItemComponent } from "../../_shared/genArts/shared/ListItemComponent"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"

type Props = {
    selectedGenArt: Genart
    onOeArticleChange: (fsArticleId: string, quantity: number, price: number, designation: string, path: string) => void
    showPartAlternatives: (genArt: Genart, article?: FSArticle, oeArticle?: boolean) => void
} & BoxProps

export default function GenArt({ selectedGenArt, onOeArticleChange, showPartAlternatives }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])
    const [designation, setDesignation] = useState<string>("")
    const [quantity, setQuantity] = useState<number>(0)
    const [price, setPrice] = useState<string>("0")
    const [checked, setChecked] = useState<boolean>(false)
    const loading = useFastServiceStore((state) => state.articlesLoading)

    const handleSetDesignation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (designation !== event.target.value) {
            setDesignation(event.target.value)
        }
    }

    const handleSetPrice = (event: ChangeEvent<HTMLInputElement>) => {
        const inputPrice = event.target.value
        const regex = /^[0-9]*([.|,][0-9]{0,2})?$/

        if (regex.test(inputPrice) || inputPrice === "0" || inputPrice === "") {
            setPrice(inputPrice)
        }
    }

    const handleSetQuantity = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (quantity !== +event.target.value) {
            setQuantity(+event.target.value)
        }
    }

    const handleOeChange = (item: Genart, userQuantity: number, userPrice: number, userDesignation: string) => {
        onOeArticleChange(item.genArtNr.toString(), userQuantity, userPrice, userDesignation, item.label)
    }

    useEffect(() => {
        setDesignation(selectedGenArt.label)
        setPrice("0")
        setQuantity(1)
    }, [])

    useEffect(() => {
        if (!selectedGenArt) {
            return
        }
        handleOeChange(selectedGenArt, quantity, +price.replace(/,/g, "."), designation)
    }, [designation, quantity, price])

    const requestAlternatives = () => {
        showPartAlternatives(selectedGenArt)
    }

    return (
        <ListItemComponent disablePadding>
            <Box padding="16px" width="100%">
                <Box display="flex" alignItems="center">
                    <ListItemIcon>
                        <Checkbox size="medium" onChange={() => setChecked(!checked)} checked={checked} />
                    </ListItemIcon>
                    <Typography variant="h4" id={selectedGenArt.label}>
                        {loading ? <Loader size="small" /> : selectedGenArt.label}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="0.5em">
                    <Box display="flex" flexDirection="row" gap="1em" justifyContent="flex-start">
                        <TextField
                            label={translateText(13467)}
                            onChange={handleSetDesignation}
                            value={designation}
                            size="extralarge"
                            sx={{ width: "20em" }}
                            required
                        />
                        <Button color="highlight" onClick={requestAlternatives} disabled={!checked}>
                            {translateText(13723)}
                        </Button>
                    </Box>

                    <TextField label={currencyCode} onChange={handleSetPrice} value={price} size="extralarge" />
                    <TextField label={translateText(89)} type="number" onChange={handleSetQuantity} size="extralarge" value={quantity} />
                </Box>
            </Box>
        </ListItemComponent>
    )
}
