import { BikeModelSeries } from "@tm/models"
import { callBikesService } from "."
import { ModelYearFilter } from "../../model/filters"

type SelectedModelSeriesFilters = {
    modelYear?: number
}

type ModelSeriesFilters = {
    modelYears: Array<ModelYearFilter>
}

export type ModelSeriesResponse = {
    modelSeries: Array<BikeModelSeries>
    filters: ModelSeriesFilters
}

type ShowModelSeriesByArticleRequest = {
    manufacturerId: number
    articleId: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByArticle(request: ShowModelSeriesByArticleRequest) {
    return callBikesService<ShowModelSeriesByArticleRequest, ModelSeriesResponse>("ShowModelSeriesByArticle", request)
}

type ShowModelSeriesByPartListArticleRequest = {
    manufacturerId: number
    partsListArticleId: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByPartListArticle(request: ShowModelSeriesByPartListArticleRequest) {
    return callBikesService<ShowModelSeriesByPartListArticleRequest, ModelSeriesResponse>("ShowModelSeriesByPartListArticle", request)
}

type ShowModelSeriesByEngineCodeRequest = {
    manufacturerId: number
    engineCode: string
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByEngineCode(request: ShowModelSeriesByEngineCodeRequest) {
    return callBikesService<ShowModelSeriesByEngineCodeRequest, ModelSeriesResponse>("ShowModelSeriesByEngineCode", request)
}

type ShowModelSeriesByManufacturerRequest = {
    manufacturerId: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByManufacturer(request: ShowModelSeriesByManufacturerRequest) {
    return callBikesService<ShowModelSeriesByManufacturerRequest, ModelSeriesResponse>("ShowModelSeriesByManufacturer", request)
}

type ShowModelSeriesDetailsRequest = {
    manufacturerId: number
    modelSeriesId: number
}

type ShowModelSeriesDetailsResponse = {
    modelSeriesDetails: BikeModelSeries
}

export function showModelSeriesDetails(request: ShowModelSeriesDetailsRequest) {
    return callBikesService<ShowModelSeriesDetailsRequest, ShowModelSeriesDetailsResponse>("ShowModelSeriesDetails", request)
}
