import { CategoryDropDownProps, FileCategory } from "../models"

const fileTypeMapping = {
    jpg: { accept: [".jpg", ".jpeg"], mime: ["image/jpeg"] },
    png: { accept: [".png"], mime: ["image/png"] },
    pdf: { accept: [".pdf"], mime: ["application/pdf"] },
}

export type AllowedFileSelectionTypes = Array<keyof typeof fileTypeMapping>

export const getMimeTypes = (allowedFileTypes: AllowedFileSelectionTypes) => {
    const mime: Array<string> = []
    allowedFileTypes.forEach((type) => mime.push(...fileTypeMapping[type].mime))
    return mime
}

export const getAcceptAttribute = (allowedFileTypes: AllowedFileSelectionTypes) => {
    const accept: Array<string> = []
    allowedFileTypes.forEach((type) => accept.push(...fileTypeMapping[type].accept))
    return accept.join(", ")
}

export const getCategories = (translateText: (key: string | number) => string) => {
    const categories: Array<CategoryDropDownProps> = [
        { id: 0, categoryName: translateText(FileCategory.Others), subCategories: [] },
        {
            id: 1,
            categoryName: translateText(31),
            subCategories: [FileCategory.CostEstimate, FileCategory.Order, FileCategory.Invoice],
        },
        {
            id: 2,
            categoryName: translateText(1652),
            subCategories: [
                FileCategory.VehicleInspectionCertificate,
                FileCategory.EmissionsTestCertificate,
                FileCategory.GasSafetyInspection,
                FileCategory.AccidentPreventionRegulations,
            ],
        },
        {
            id: 3,
            categoryName: translateText(3077),
            subCategories: [FileCategory.MaintenanceProtocol, FileCategory.AcceptanceProtocol],
        },
        {
            id: 4,
            categoryName: translateText(3017),
            subCategories: [
                FileCategory.RegistrationOfVehicle,
                FileCategory.DeregistrationOfVehicle,
                FileCategory.CompletionCertificate,
                FileCategory.TakebackCertificate,
            ],
        },
        {
            id: 5,
            categoryName: translateText(3082),
            subCategories: [FileCategory.VehicleRegistration, FileCategory.VehicleRegistrationDocument, FileCategory.TaxAssessment],
        },
        {
            id: 6,
            categoryName: translateText(3074),
            subCategories: [
                FileCategory.Policies,
                FileCategory.InsuranceRepairAuthorisation,
                FileCategory.DamageReport,
                FileCategory.RecallReplacement,
            ],
        },
        {
            id: 7,
            categoryName: translateText(12842),
            subCategories: [FileCategory.AccidentalDamage, FileCategory.PaintDamage, FileCategory.Defects],
        },
        {
            id: 8,
            categoryName: translateText(1022),
            subCategories: [FileCategory.WheelCertificates, FileCategory.DamageReport],
        },
    ]
    return categories
}

export const getUploadMethods = (translateText: (key: string | number) => string) => {
    const uploadMethods = [
        { id: 1, method: translateText(13773) },
        { id: 2, method: translateText(12679) },
    ]
    return uploadMethods
}
