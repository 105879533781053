import { BikeModel, CarModel, RegistrationNoType, TruckModel, VehicleShortInfo, VehicleType } from "@tm/models"
import { modelDetailsSelector } from "@tm/utils"
import { selectorFamily, useRecoilValue } from "recoil"

import { RequestWithVehicleType, useLoadable } from "."
import { CarBodyType, EngineCapacityFilter, EngineCodeFilter, FuelTypeFilter, ModelYearFilter } from "../model/filters"
import { PagedRequest, PagedResponse } from "../repositories"
import * as Bikes from "../repositories/bikes/showModels"
import * as Cars from "../repositories/cars/showModels"
import * as Trucks from "../repositories/trucks/showModels"

export type ModelsResponse =
    | undefined
    | (PagedResponse & {
          models: Array<CarModel | BikeModel | TruckModel>
          customerVehicles: Array<VehicleShortInfo>
          filters: ModelFilters
      })

type SelectedModelFilters = {
    /** Only used for `VehicleType.PassengerCar` */
    bodyTypeId?: number
    modelYear?: number
    fuelType?: string
    engineCode?: string
    engineCapacity?: number
}

type ModelFilters = {
    /** Only used for `VehicleType.PassengerCar` */
    bodyTypes: Array<CarBodyType>
    modelYears: Array<ModelYearFilter>
    fuelTypes: Array<FuelTypeFilter>
    engineCodes: Array<EngineCodeFilter>
    engineCapacities?: Array<EngineCapacityFilter>
}

type ModelsByModelSeriesRequest = RequestWithVehicleType &
    PagedRequest & {
        modelSeriesId: number
        selectedFilters: SelectedModelFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
    }

const modelsByModelSeries = selectorFamily<ModelsResponse, ModelsByModelSeriesRequest>({
    key: "vehicle_modelsByModelSeries",
    get:
        ({ vehicleType, modelSeriesId, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showModelsByModelSeries({ modelSeriesId, selectedFilters })
                case VehicleType.Motorcycle:
                    return Bikes.showModelsByModelSeries({ modelSeriesId, selectedFilters }).then(mapBikeModelsResponse)
                case VehicleType.CommercialVehicle:
                    return Trucks.showModelsByModelSeries({ modelSeriesId, selectedFilters }).then(mapTruckModelsResponse)
            }
        },
})

export function useModelsByModelSeries(request: ModelsByModelSeriesRequest) {
    return useRecoilValue(modelsByModelSeries(request))
}

type ModelsByArticleRequest = RequestWithVehicleType &
    PagedRequest & {
        articleId: number
        productGroupId?: number
        manufacturerId?: number
        modelSeriesId?: number
        selectedFilters: SelectedModelFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
    }

const modelsByArticle = selectorFamily<ModelsResponse, ModelsByArticleRequest>({
    key: "vehicle_modelsByArticle",
    get:
        ({ vehicleType, modelSeriesId, articleId, selectedFilters }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showModelsByArticle({ modelSeriesId, articleId })
                case VehicleType.Motorcycle:
                    return Bikes.showModelsByArticle({ modelSeriesId, articleId, selectedFilters }).then(mapBikeModelsResponse)
                case VehicleType.CommercialVehicle:
                    return Trucks.showModelsByArticle({ modelSeriesId, articleId }).then(mapTruckModelsResponse)
            }
        },
})

export function useModelsByArticle(request: ModelsByArticleRequest) {
    return useRecoilValue(modelsByArticle(request))
}

type ModelsByQueryRequest = RequestWithVehicleType &
    PagedRequest & {
        query?: string
        manufacturerIds?: Array<number>
        modelSeriesId?: number
        selectedFilters: SelectedModelFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
        selectedRegistrationNoType?: RegistrationNoType
        countryCode?: string
    }

const modelsByQuery = selectorFamily<ModelsResponse, ModelsByQueryRequest>({
    key: "vehicle_modelsByQuery",
    get:
        ({ vehicleType, query, manufacturerIds, selectedFilters, selectedRegistrationNoType, countryCode }) =>
        () => {
            if (!query) {
                return
            }

            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    return Cars.showModelsByQuery({ query, manufacturerIds, selectedFilters, selectedRegistrationNoType, countryCode }).then(
                        filterCustomerVehicles(vehicleType)
                    )
                case VehicleType.Motorcycle:
                    return Bikes.showModelsByQuery({ query, manufacturerIds, selectedFilters })
                        .then(mapBikeModelsResponse)
                        .then(filterCustomerVehicles(vehicleType))
                case VehicleType.CommercialVehicle:
                    /** @todo: query search for trucks is currently not possible - nkw id search is used instead */
                    return Trucks.showModelsByRegistrationNo({
                        registrationNo: query,
                        registrationNoTypeId: RegistrationNoType.NkwId,
                        selectedFilters,
                    })
                        .then(mapTruckModelsResponse)
                        .then(filterCustomerVehicles(vehicleType))
                default:
            }
        },
})

export function useModelsByQuery(request: ModelsByQueryRequest) {
    return useRecoilValue(modelsByQuery(request))
}

export function useModelsByQueryLoadable(request: ModelsByQueryRequest) {
    return useLoadable(modelsByQuery(request))
}

type ModelDetailsRequest = Partial<RequestWithVehicleType> & {
    modelId?: number
    selectedLookupType?: RegistrationNoType
    registrationNoTypeId?: RegistrationNoType
}

export function useModelDetailsLoadable(request: ModelDetailsRequest) {
    return useLoadable(modelDetailsSelector(request))
}

/** @todo: remove vehicleType filtering as soon as the service is respecting the supplied vehicleType for the customerVehicles */
function filterCustomerVehicles(vehicleType: VehicleType): (response: ModelsResponse) => ModelsResponse {
    return (response: ModelsResponse) => {
        if (response?.customerVehicles) {
            response.customerVehicles = response.customerVehicles.filter((x) => x.vehicleType === vehicleType)
        }

        return response
    }
}

function mapBikeModelsResponse(response: Bikes.ModelsResponse | undefined): ModelsResponse {
    return {
        models: response?.models ?? [],
        customerVehicles: [],
        filters: {
            bodyTypes: [],
            modelYears: response?.filters?.modelYears ?? [],
            fuelTypes: [],
            engineCodes: [],
            engineCapacities: response?.filters?.engineCapacities ?? [],
        },
    }
}

export const mapTruckModelsResponse = (response: Trucks.ModelsResponse | undefined): ModelsResponse => {
    return {
        models: response?.models ?? [],
        customerVehicles: [],
        filters: {
            bodyTypes: [],
            modelYears: response?.filters?.modelYears ?? [],
            fuelTypes: response?.filters?.fuelTypes ?? [],
            engineCodes: response?.filters?.engineCodes ?? [],
            engineCapacities: response?.filters?.engineCapacities ?? [],
        },
    }
}
