import { useLocalization } from "@tm/localization"
import { Price, PriceDictionary, PriceType } from "@tm/models"
import { Theme } from "@tm/components"
import { useMemo } from "react"
import SmallScreenPrices from "./SmallScreenPrices"
import BigScreenPrices from "./BigScreenPrices"

type Props = {
    prices: PriceDictionary
    cumulatedPrices: PriceDictionary
    showPurchasePrice?: boolean
    showRetailPrice?: boolean
    showTotalPrices: boolean
    isBigScreen: boolean
    theme: Theme
}

export type BasePricesProps = {
    theme: Theme
}

export type PricesProps = BasePricesProps & {
    purchasePrice?: Price
    purchasePriceLabel: string
    retailPrice?: Price
    retailPriceLabel: string
    recommendedPrice?: Price
    recommendedPriceLabel: string
    showPurchasePrice?: boolean
    showRetailPrice?: boolean
    showTotalPrices: boolean
    totalPurchasePrice?: Price
    totalPurchasePriceLabel: string
    totalRecommendedPrice?: Price
    totalRecommendedPriceLabel: string
    totalRetailPrice?: Price
    totalRetailPriceLabel: string
}

function findPrice(prices: (Price | undefined)[] | undefined, type: PriceType) {
    return prices?.find((price) => price?.type === type)
}

export default function PartPrices({ prices, cumulatedPrices, showPurchasePrice, showRetailPrice, showTotalPrices, isBigScreen, theme }: Props) {
    const { translateText } = useLocalization()
    const unitPrices = prices ? Object.values(prices) : undefined
    const totalPrices = cumulatedPrices ? Object.values(cumulatedPrices) : undefined
    const { purchasePrice, purchasePriceLabel, retailPrice, retailPriceLabel, recommendedPrice, recommendedPriceLabel } = useMemo(() => {
        return {
            purchasePrice: findPrice(unitPrices, PriceType.Purchase),
            purchasePriceLabel: translateText(55),
            retailPrice: findPrice(unitPrices, PriceType.Retail),
            retailPriceLabel: translateText(1620),
            recommendedPrice: findPrice(unitPrices, PriceType.RecommendedRetail),
            recommendedPriceLabel: translateText(1144),
        }
    }, [unitPrices])

    const {
        totalPurchasePrice,
        totalPurchasePriceLabel,
        totalRetailPrice,
        totalRetailPriceLabel,
        totalRecommendedPrice,
        totalRecommendedPriceLabel,
    } = useMemo(() => {
        return {
            totalPurchasePrice: findPrice(totalPrices, PriceType.Purchase),
            totalPurchasePriceLabel: translateText(1197),
            totalRetailPrice: findPrice(totalPrices, PriceType.Retail),
            totalRetailPriceLabel: translateText(1198),
            totalRecommendedPrice: findPrice(totalPrices, PriceType.RecommendedRetail),
            totalRecommendedPriceLabel: translateText(13644),
        }
    }, [totalPrices])

    if (!unitPrices && !totalPrices) {
        return null
    }

    const pricesProps: PricesProps = {
        purchasePrice,
        purchasePriceLabel,
        recommendedPrice,
        recommendedPriceLabel,
        retailPrice,
        retailPriceLabel,
        showPurchasePrice,
        showRetailPrice,
        showTotalPrices,
        theme,
        totalPurchasePrice,
        totalPurchasePriceLabel,
        totalRecommendedPrice,
        totalRecommendedPriceLabel,
        totalRetailPrice,
        totalRetailPriceLabel,
    }

    if (isBigScreen) {
        return <BigScreenPrices {...pricesProps} />
    }

    return <SmallScreenPrices {...pricesProps} />
}
