
import { styled, Box, Icon, IconButton, Typography } from '@tm/components'
import { useLocalization } from '@tm/localization'
import { channel } from '@tm/models'
import { copyToClipboard } from '@tm/utils'

const ContactWheels = () => {
    const { translateText } = useLocalization()
    const [title, contact, phone, email] = translateText(13117).split("/ ")

    const handleCopy = (text: string) => {
        copyToClipboard(text)
        channel("APP").publish("TOAST_MESSAGE/SHOW", {
            message: text + " " + translateText(1920),
            icon: "info",
            skin: "success",
            closeDelay: 1250
        })
    }

    const handleEmail = () => {
        window.location.href = "mailto:" + email
    }

    return (<Box sx={{ justifyContent: "center", alignItems: "center" }}>
        <br />
        <InfoText variant="h4" color="primary">{title}</InfoText>
        <br />
        <InfoText variant="h4" >{contact}</InfoText>
        <FlexBox onClick={() => handleCopy(phone)}>
            <CopyText
                variant="h4" >
                {phone}
            </CopyText>
            <CopyIcon><Icon name='copy' /></CopyIcon>
        </FlexBox>
        <FlexBox>
            <CopyText
                onClick={handleEmail}
                variant="h4" >
                {email}
            </CopyText>
            <CopyIcon onClick={() => handleCopy(email)}> <Icon name='copy' /></CopyIcon>
        </FlexBox>
    </Box>)
}

const FlexBox = styled(Box)(({ }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

const InfoText = styled(Typography)(({ theme }) => ({
    color: theme.colors?.highlight,
    fontWeight: "600",
    textAlign: "center"
}))

const CopyIcon = styled(IconButton)(({ theme }) => ({
    color: theme.colors?.highlight,
    fontSize: theme.font?.textSize.m
    
}))

const CopyText = styled(InfoText)(({ theme }) => ({
    cursor: "pointer",
    "&:hover": {
        textDecoration: "underline"
    },

}))

export default ContactWheels