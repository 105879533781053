import { BundleActionTypes } from "../business"
import { MainActionsType } from "../components/main/business"
import { DEFAULT_STATE, SettingsState } from "../components/settings/business"

export type SettingsActionTypes = BundleActionTypes | { type: "UPDATE_SETTINGS_PAGE"; payload: { sphinxHtml: string; errorMessage?: string } }

export const reduce = (state = { ...DEFAULT_STATE }, action: MainActionsType): SettingsState => {
    switch (action.type) {
        case "RESTART_PROCESS":
        case "OPEN_HISTORY_CALCULATION": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "UPDATE_SETTINGS_PAGE": {
            const { sphinxHtml, errorMessage } = action.payload

            return {
                ...state,
                externalContent: sphinxHtml,
                errorMessage,
            }
        }
        default:
            return state
    }
}
