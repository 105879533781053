import { AuthorityContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { ajax } from "@tm/utils"
import { getBundleParams } from "../../utils"

export type TransferInfoResponse = {
    transferId: string
    storageUri: string
    uploadUri: string
    expirationDateUtc: string
}

export async function getTransferInfo() {
    const token = await getAuthenticationToken()
    const url = `${getBundleParams().fastUpApiBaseUrl}/api/v2/files/gettransferinfo`
    return ajax<TransferInfoResponse>({ url, method: "GET", headers: { Authorization: token } })
}

type FileType = "image" | "pdf"

export type UploadRequest = {
    transferId: string
    files: Array<Blob>
    fileType: FileType
}

async function getAuthenticationToken() {
    const container = Container.getInstance(RegisteredModels.Authority) as AuthorityContainer
    const response = await container.action("getExternalToken")("FastUp")
    return `${response.schema} ${response.token}`
}

export async function upload(request: UploadRequest) {
    const token = await getAuthenticationToken()
    const body = new FormData()
    body.set("TransferId", request.transferId)
    request.files.forEach((file, idx) => body.append("Files", file, `upload-file${idx > 0 ? idx : ""}.${request.fileType === "pdf" ? "pdf" : "png"}`))

    const url = `${getBundleParams().fastUpApiBaseUrl}/api/v2/files/upload`

    const response = await fetch(url, { method: "POST", body, headers: [["Authorization", token]] }) // had to use fetch here because I could not make ajax/axios work with a multipart/form-data request
    const json = await response.json()

    // Check if response is valid
    if (typeof json == "object" && typeof json.transferId == "string" && Array.isArray(json.storageUris)) {
        return json as GetStorageUrisResponse
    }
}

export type GetStorageUrisResponse = {
    transferId: string
    storageUris: Array<string>
}

export async function getStorageUris(transferId: string) {
    const token = await getAuthenticationToken()
    const url = `${getBundleParams().fastUpApiBaseUrl}/api/v2/files/getstorageuris/${transferId}`
    return ajax<GetStorageUrisResponse>({ url, method: "GET", headers: { Authorization: token } })
}
