import { AsyncAction } from "@tm/morpheus"
import { DateUnit } from "@tm/utils"
import * as Data from "../../data"
import { BundleActionType } from "../../business"
import { ServiceEvent, EventType } from "../../data"

export type ComponentActionType = BundleActionType | { type: "LOADING" } | { type: "EVENTS_LOADED"; payload: Array<ServiceEvent> }

export type CalendarType = "MONTH" | "DAY" | "LIST"

export type StartWidgetState = {
    types: Array<EventType>
    events: Array<ServiceEvent>
    typesLoading: boolean
    eventsLoading: boolean
    loading: boolean
}

const DEFAULT_STATE: StartWidgetState = {
    loading: false,
    eventsLoading: false,
    typesLoading: false,
    events: [],
    types: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): StartWidgetState {
    switch (action.type) {
        case "LOADING": {
            return {
                ...state,
                loading: true,
                typesLoading: true,
                eventsLoading: true,
            }
        }
        case "TYPES_LOADED": {
            return {
                ...state,
                types: action.payload,
                loading: state.eventsLoading,
                typesLoading: false,
            }
        }
        case "EVENTS_LOADED": {
            return {
                ...state,
                events: action.payload.filter((x) => !!x.onlineBookingId).orderBy((x) => x.startDateTime),
                loading: state.typesLoading,
                eventsLoading: false,
            }
        }
        default:
            break
    }

    return state
}

function loadServiceEvents(/* dateRange: [Date, Date] */): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "LOADING" })
        const date = new Date().set(1, DateUnit.Day)

        Data.getEventTypes()
            .then((types) => dispatch({ type: "TYPES_LOADED", payload: types }))
            .catch(() => {
                dispatch({ type: "TYPES_LOADED", payload: [] })
            })

        Data.getServiceEvents([date, date.add(1, DateUnit.Month)])
            .then((list) => dispatch({ type: "EVENTS_LOADED", payload: list }))
            .catch(() => {
                dispatch({ type: "EVENTS_LOADED", payload: [] })
            })
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadServiceEvents,
}
