import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function damageCalculationAsWegasFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const damageCalculationAsWegasModule = getModuleFromUserContext(userContext, UserModuleType.TMDamageCalculationAsWegas)

    if (!damageCalculationAsWegasModule) {
        return undefined
    }

    return {
        "damage-calculation": {
            items: {
                "damage-calculation-aswegas": {
                    sort: 2,
                    name: "{{12527}}",
                    active: true,
                    route: "/:workTaskId/damage-calculation/aswegas",
                    disabledFunction: "HAS-NO-VEHICLE",
                },
            },
        },
    }
}
