import { buttonClasses, Icon, LinkButton as MuiLinkButton, styled } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useMemo } from "react"
import { useParams } from "react-router"
import { getBundleParams } from "../../utils"

const LinkButton = styled(MuiLinkButton)(({ theme }) => ({
    width: "160px",
    height: "100%",
    [`&.${buttonClasses.contained}`]: {
        color: theme.colors?.light,
        backgroundColor: theme.colors?.highlight,
        fontSize: "18px",
        "&:hover": {
            backgroundColor: theme.colors?.["highlight-hover"],
        },
    },
}))

export default function WatchListButton() {
    const workTaskId = useWorkTask()?.workTaskId
    const { compilationsRoute } = getBundleParams()
    const matchParams = useParams()
    const { translateText } = useLocalization()

    const url = useMemo(() => {
        if (!compilationsRoute) {
            return ""
        }
        return renderRoute(compilationsRoute, {
            ...matchParams,
            workTaskId: encodeUniqueId(workTaskId || uniqueId()),
        })
    }, [compilationsRoute, workTaskId, matchParams])

    return (
        <LinkButton to={url} startIcon={<Icon name="filled-star" style={{ width: "24px", height: "24px", marginRight: "15px" }} />}>
            {translateText(13789)}
        </LinkButton>
    )
}
