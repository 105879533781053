
import { ETyresCarType } from "@tm/models"
import { createBaseQuery } from "../../../../data/helpers"
import { AttributeFiltersRequest } from "../../../../data/repositories"
import { MainState } from "../../../main"
import { Statics } from "../../../../data/statics"

export function createAttributeFiltersRequest(state: MainState): AttributeFiltersRequest | undefined {
    const { searchValue, lastSearch: { byMatchcode, carType } } = state.list

    const { width, height, inch } = state.summary.selectedFilters
    const matchCodeSize = byMatchcode ? searchValue : createBaseQuery(width?.value, height?.value, inch?.value) ?? searchValue

    return {
        query: matchCodeSize.trim(),
        searchLevel: 2,
        carTypes: carType ?? Statics.getCarTypes().map(x => +x.value as ETyresCarType),
        width: width?.value ?? "", 
        height: height?.value ?? "", 
        inch: inch?.value ?? ""
    }
}

