import { ExternalDocument } from "../../../../data/model"

export function generateExternalSystemNameList(externalId: number, externalDocuments: Array<ExternalDocument>, nameList: ExternalDocument[]) {
    if (externalDocuments.length > 0 && externalId) {
        const extDoc = externalDocuments.find((item) => item.externalSystemId === externalId)

        if (extDoc) {
            nameList.push(extDoc)
            generateExternalSystemNameList(extDoc.relatedExternalSystemId, externalDocuments, nameList)
        }
    }
    return nameList
}
