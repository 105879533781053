import * as React from "react"
import { useLocalization } from "@tm/localization"
import { PanelSection, RealTable } from "@tm/controls"
import { Memo, MemoType } from "@tm/models"
import { AdditionalInformationExtendedComponent } from "./additional-information-extended"

type Props = {
    additionalInformation?: string
    additionalInformationExtended?: string
    memos?: Array<Memo>
    isReplacementPart: boolean
    wrapperRef: React.RefObject<HTMLDivElement> | undefined
}

export default function AdditionalInfosComponent({
    additionalInformation,
    additionalInformationExtended,
    memos,
    isReplacementPart,
    wrapperRef,
}: Props) {
    const { translateText, translate } = useLocalization()

    function renderMemoKey(item: Memo) {
        let key = item.label || ""

        if (item.type === MemoType.Assortment) {
            key = translateText(1571)
        }

        return <RealTable.Cell>{key}</RealTable.Cell>
    }

    function renderMemoValue(item: Memo) {
        return <RealTable.Cell>{item.text || ""}</RealTable.Cell>
    }

    function getMemoColumns() {
        return [
            <RealTable.Column className="key" renderItemContent={renderMemoKey} />,
            <RealTable.Column className="value" renderItemContent={renderMemoValue} />,
        ]
    }

    let filteredMemos: Array<Memo> = []
    if (memos && memos.length) {
        filteredMemos = memos.filter((memo) => memo?.text?.trim() && memo.type === MemoType.Assortment)
    }

    if (!additionalInformation && !additionalInformationExtended && !filteredMemos.length) {
        return null
    }

    return (
        <div ref={wrapperRef}>
            <PanelSection className="stock-info__additional-infos" title={translate(413)} size="s">
                {additionalInformation && <div>{additionalInformation}</div>}
                <AdditionalInformationExtendedComponent information={additionalInformationExtended} />
                {!!filteredMemos.length && (
                    <div className="stock-info__additional-infos__memo-table">
                        <RealTable data={filteredMemos} columns={getMemoColumns()} />
                    </div>
                )}
                {isReplacementPart && <div>{translate(1578)}</div>}
            </PanelSection>
        </div>
    )
}
