import { createContext, useContext } from "react"
import { ArticleListActions } from "./models"

/**
 * Shall not be used directly outside of this file.
 * Use export `useArticleListActions` instead.
 */
const ArticleListActionsContext = createContext<ArticleListActions | undefined>(undefined)

export const ArticleListActionsProvider = ArticleListActionsContext.Provider

export function useArticleListActions() {
    const context = useContext(ArticleListActionsContext)

    if (!context) {
        throw new Error(`Used ${useArticleListActions.name} outside of ${ArticleListActionsProvider.name}`)
    }

    return context
}
