import { useLocalization } from "@tm/localization"
import { TableCellData, TableVariants, CellContentPosition, TableColumnData } from "@tm/components"
import { NoResultHint } from "../NoResultHint"
import { ComplaintListItem } from "../../../data/model"
import { StyledTable } from "../StyledComponents"
import { getWmVoucherId } from "../../../business/helper"

type Props = {
    complaints: Array<ComplaintListItem>
    variant: TableVariants
}

export default function ComplaintsTableComponent({ variant, complaints }: Props) {
    const { translateText } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(97) },
            { header: translateText(87) },
            { header: translateText(25) },
            { header: translateText(89), alignContent: CellContentPosition.center },
            { header: translateText(212) },
        ]

        return columns
    }

    function getCells(complaint: ComplaintListItem) {
        const cellData: TableCellData[] = [
            { displayValue: complaint.orderNumber || getWmVoucherId(complaint.voucherId), id: "order_0" },
            { displayValue: complaint.wholesalerArticleNumber, id: "order_1" },
            { displayValue: complaint.itemDescription, id: "order_2" },
            { displayValue: complaint.itemsCount, id: "order_3" },
            { displayValue: complaint.positionStatus, id: "order_4" },
        ]
        return cellData
    }

    const displayData = complaints?.map((complaint, index) => ({
        cells: getCells(complaint),
        id: `${index}`,
        customRow: false,
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return (
        <StyledTable
            columns={getColumns()}
            rows={displayData}
            variant={variant}
            headerStyle={variant === "small" ? "bold" : "default"}
            headerBackground={variant === "small" ? "default" : "paper"}
        />
    )
}
