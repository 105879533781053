import { getStyleTheme } from "@tm/context-distribution"
import { style } from "typestyle"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return style({
        $nest: {
            "&__badge": {
                top: margin.s,
            },
        },
    })
}
