import { ErpInformation } from "@tm/models"
import { BasicErpInfo, UpdateTotalPricesRequest } from "."
import { ICalculationItem, Part, Work } from "../../models"

function mapBasicErpInfo(erpInfo: ErpInformation): BasicErpInfo {
    const { dataSupplierArticleNumber, prices } = erpInfo

    return {
        dataSupplierArticleNumber,
        prices,
    }
}

export function createUpdateTotalsRequest(items: ICalculationItem[], works: Work[], erpInfo?: ErpInformation[]): UpdateTotalPricesRequest {
    const alternatives: Part[] = []

    // TODO: alternative articles don't have support for quantity
    items?.forEach((x) => x.isSelected && !!x.selectedPart && alternatives.push({ ...x.selectedPart, quantityValue: x.oeArticle.quantityValue }))

    return {
        oeArticles: items?.filter((x) => x.isSelected && !x.selectedPart).map((x) => x.oeArticle),
        alternatives,
        works: works?.filter((x) => x.isSelected),
        basicErpInfo: erpInfo?.map(mapBasicErpInfo),
    }
}
