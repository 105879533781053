import { useUser } from "@tm/context-distribution"
import { useParams } from "react-router"
import { BasketBundleParams, ECounterType, PriceType } from "@tm/models"
import { Box, Icon, LinkButton } from "@tm/components"
import { TmaHelper, renderRoute } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { useLocalization } from "@tm/localization"
import { ReactNode, useMemo, useState } from "react"
import { OrderCalculation } from "../../../../data/model"
import BasketTotalsTable from "./BasketTotalsTable"
import { LoaderSmall, StyledBox, SummaryPopover } from "../../../StyledComponents"
import { getBundleParams } from "../../../../utils"
import { SummaryRouteProps } from "../../component"
import ErrorButton from "../../../_shared/ErrorButton"
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"
import { useSummaryTheme } from "../../hooks/useSummaryTheme"

type Props = {
    workTaskId: string
    calculatedOrder?: OrderCalculation
    partsBasketRoute: string
    hasErrors: boolean
    isLoading: boolean
    additionalErrorTextId?: number
}

export function BasketTotals({ isLoading, hasErrors, partsBasketRoute, calculatedOrder, additionalErrorTextId, workTaskId }: Props) {
    const { hideBasket } = getBundleParams()
    const { userSettings } = useUser() ?? {}
    const { currency } = useLocalization()
    const matchParams = useParams<SummaryRouteProps>()
    const { hasWorkTaskOrder } = useBasketModuleParameter()
    const {
        linkButtonFontWeight,
        linkButtonFontFamily,
        linkButtonTextColor: textColor,
        contrastColor,
        linkButtonSize,
        linkButtonVariant,
    } = useSummaryTheme()

    const [partsTotalsPopoverEl, setPartsTotalsPopoverEl] = useState<HTMLDivElement>()
    const showPartsRetailPrice =
        !userSettings?.showPurchasePrice ||
        !(userSettings?.showPurchasePriceInSummary ?? !Morpheus.getParams<BasketBundleParams>("basket").showRetailPriceInSummaryByDefault) // 1 of 2: If the usersetting is not set fall back to the config param because that is the behaviour it was before this change (NEXT-20908). MLE: Added this because it caused NEXT-22836

    const totalPartsPrice = useMemo(() => {
        // ErpTotalsInOrder needs to be used here. This might change if in the future UiConfig changes will make it customizable.
        if (!calculatedOrder?.erpTotalsInOrder) {
            return null
        }
        const calculatedPrices = Object.values(calculatedOrder?.erpTotalsInOrder)
        const purchasePrice = calculatedPrices.find((price) => price?.type === PriceType.Purchase)
        const retailPrice =
            calculatedPrices.find((price) => price?.type === PriceType.Retail) ??
            calculatedPrices.find((price) => price?.type === PriceType.RecommendedRetail)

        if (showPartsRetailPrice || purchasePrice === undefined) {
            return retailPrice
        }

        return purchasePrice
    }, [calculatedOrder?.erpTotalsInOrder, showPartsRetailPrice])

    const priceType = showPartsRetailPrice ? PriceType.Retail : PriceType.Purchase

    function getPartsBasketUrl(): string {
        return renderRoute(partsBasketRoute, { ...matchParams })
    }

    let basketButtonContent: ReactNode = currency(0, totalPartsPrice?.currencyCode_Iso_4217 ?? "-")

    if (hasErrors) {
        basketButtonContent = <ErrorButton basketWithErrors workTaskIds={[workTaskId]} additionalErrorTextId={additionalErrorTextId} />
    } else if (isLoading) {
        basketButtonContent = <LoaderSmall />
    } else {
        basketButtonContent = `${currency(totalPartsPrice?.value ?? 0, totalPartsPrice?.currencyCode_Iso_4217 ?? "-")}*`
    }

    let linkButtonTextColor = ""
    if (showPartsRetailPrice || totalPartsPrice === undefined) {
        linkButtonTextColor = ""
    } else if (textColor) {
        linkButtonTextColor = textColor
    }
    linkButtonTextColor += " !important"

    if (!hasWorkTaskOrder || hideBasket) {
        return null
    }

    return (
        <Box paddingRight={1} alignItems="center">
            <div
                onMouseEnter={(event) => {
                    setPartsTotalsPopoverEl(event.currentTarget)
                }}
                onMouseLeave={() => {
                    setPartsTotalsPopoverEl(undefined)
                }}
            >
                <LinkButton
                    sx={{
                        borderRadius: 0,
                        border: 0,
                        fontWeight: showPartsRetailPrice || totalPartsPrice === undefined ? linkButtonFontWeight : 600,
                        fontFamily: linkButtonFontFamily,
                        color: linkButtonTextColor,
                    }}
                    variant={linkButtonVariant}
                    startIcon={<Icon name="cart-summary" color={contrastColor} />}
                    to={getPartsBasketUrl()}
                    onClick={() => {
                        TmaHelper.GeneralCountEvent.Call(ECounterType.TopBarBasket)
                    }}
                    size={linkButtonSize || "extralarge"}
                >
                    {basketButtonContent}
                </LinkButton>
                {calculatedOrder && !hasErrors && (
                    <SummaryPopover open={!!partsTotalsPopoverEl} anchorEl={partsTotalsPopoverEl}>
                        <StyledBox>
                            <BasketTotalsTable calculatedOrder={calculatedOrder} priceType={priceType} />
                        </StyledBox>
                    </SummaryPopover>
                )}
            </div>
        </Box>
    )
}
