import { useMemo, Suspense } from "react"
import { useArticleByArticleNumberWithOptionalVehicle, withRouter } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { PanelSection } from "@tm/controls"
import { Loader } from "@tm/components"
import PartnerSystemsTable from "../../_shared/partner-systems"

const BasketPartnerChange = withRouter(({ location }) => {
    const { basketItemId, supplierId, supplierArticleNo, productGroupId } = useMemo(() => {
        const queryParams = new URLSearchParams(location.search)
        const supplierIdString = queryParams.get("supplierId")
        const productGroupIdString = queryParams.get("productGroupId")
        return {
            basketItemId: queryParams.get("basketItemId") || undefined,
            supplierId: supplierIdString ? parseInt(supplierIdString) : undefined,
            supplierArticleNo: queryParams.get("supplierArticleNo"),
            productGroupId: productGroupIdString ? parseInt(productGroupIdString) : undefined,
        }
    }, [location.search])

    const request = useMemo(() => {
        if (supplierId && supplierArticleNo && productGroupId) {
            return { supplierId, supplierArticleNo, productGroupId }
        }
    }, [supplierId, supplierArticleNo, productGroupId])

    const article = useArticleByArticleNumberWithOptionalVehicle(request)

    if (!basketItemId || !article) {
        return null
    }

    return <PartnerSystemsTable article={article} basketItemId={basketItemId} />
})

export default function Wrapper() {
    const { translateText } = useLocalization()

    return (
        <PanelSection className="tk-erp basket-partner-change" title={translateText(12416)} size="s">
            <Suspense fallback={<Loader />}>
                <BasketPartnerChange />
            </Suspense>
        </PanelSection>
    )
}
