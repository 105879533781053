import { useLocalization } from "@tm/localization"

export default function WmStartInfoPanelComponent() {
    const { translate } = useLocalization()

    return (
        <div className="wminfo_bgrpnl">
            <div className="wminfo_outer">
                <div className="wminfo_inner">
                    <div className="wminfo_headline">{translate(13194)}</div>
                    <div className="wminfo_text">
                        {translate(13195)}
                        <br />
                        {translate(13200)}
                    </div>
                </div>
            </div>
        </div>
    )
}
