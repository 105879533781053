import { IMicros } from "@tm/models"
import { useEffect } from "react"
import AvailabilityWrapperComponent from "../../../_shared/availability-wrapper"
import Warehouses from "./components/warehouses"
import SpecialIcons from "../../../_shared/special-icons"
import MemoTooltipIcon from "../../../_shared/memo-tooltip-icon"
import ExtendedDeliveryComponent from "../../../_shared/extended-delivery"
import { getBundleParams } from "../../../utils"
import { useTeccom } from "../../_helpers/useTeccom"
import { RowStack } from "../../../_shared/prices/components/StyledComponents"

type Props = IMicros["erp"]["erp-info-basket"]

export default function ErpInfoBasketComponent({
    requestItem,
    availability,
    isTeccomRequestAvailable,
    warehouses,
    tour,
    onClick,
    warehouseDispatchType,
    distributorId,
    specialIcons,
    memos,
    useSpecialProcurement,
    selectedShipmentMode,
    onTeccomInfoLoaded,
}: Props) {
    const { hideExtendedDeliveryComponent } = getBundleParams()
    const { teccom, loadTeccom, teccomLoading } = useTeccom(isTeccomRequestAvailable, requestItem, distributorId)

    useEffect(() => {
        if (teccom) {
            onTeccomInfoLoaded?.(requestItem.itemId, teccom)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teccom, requestItem.itemId]) // onTeccomInfoLoaded as dependency genereates an infinite loop

    return (
        <RowStack>
            {specialIcons && <SpecialIcons icons={specialIcons} />}
            {memos && <MemoTooltipIcon memos={memos} />}
            <Warehouses warehouses={warehouses} warehouseDispatchType={warehouseDispatchType} currentWarehouseId={requestItem.warehouseId} />
            <AvailabilityWrapperComponent
                onClick={onClick}
                availability={availability}
                tour={tour}
                hasReplacedArticles={false}
                warehouses={warehouses}
                requestedQuantity={requestItem.quantityValue || 1}
                specialProcurementInformation={teccom?.specialProcurementErpInformation}
                useSpecialProcurement={useSpecialProcurement}
                selectedShipmentMode={selectedShipmentMode}
            />
            {!hideExtendedDeliveryComponent && (
                <ExtendedDeliveryComponent
                    extendedDeliveryRequested={!!teccom}
                    loadExtendedDelivery={loadTeccom}
                    extendedDeliveryLoading={teccomLoading}
                />
            )}
        </RowStack>
    )
}
