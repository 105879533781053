import { Box } from "@tm/components"
import { useSelector } from "react-redux"
import { Article, EReplaceButtonBundle, OE, channel } from "@tm/models"
import { clone, closeModalOrDrawer, useUnsubscribe } from "@tm/utils"
import { useActions } from "@tm/morpheus"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useCallback } from "react"
import { createRequestArticleListPayload } from "../../business/helper"
import { ArticleItem } from "./components"
import { calculationSelector } from "../../../../selectors"
import { ICalculationItem } from "../../../../data/model"
import { MainActions } from "../../../main/business"

export default function ArticleList() {
    const { userContext } = useUser()
    const { translateText } = useLocalization()
    const subscriptionReplacePart = useUnsubscribe()

    const { items } = useSelector(calculationSelector)
    const { erpArticles } = useSelector(calculationSelector)

    const actions = useActions(MainActions, "updateTotals", "replaceArticle", "setErpArticles")

    const requestAlternatives = useCallback(
        (item: ICalculationItem) => {
            subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce("PARTS/REPLACE_PART", ({ part, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.DMGDat) {
                    return false
                }

                if (!part) {
                    return
                }

                closeModalOrDrawer(isNewList)

                actions.replaceArticle(item, part)

                let filteredErpArticles = clone(erpArticles)

                if ((part as OE.OePart).number) {
                    if (item.selectedPart) {
                        filteredErpArticles = filteredErpArticles
                            .filter((x) => x.dataSupplierArticleNumber !== item.selectedPart!.supplierArticleNo)
                            .distinct()
                    }

                    actions.updateTotals(translateText, filteredErpArticles)
                } else {
                    channel("GLOBAL").subscribeOnce("ERP/ERP_INFORMATION_LOADED", (erpInfo) => {
                        const newErpArticle = erpInfo.filter((x) => x.dataSupplierArticleNumber === (part as Article).supplierArticleNo)

                        if (item.isSelected) {
                            actions.updateTotals(translateText, [...erpArticles, ...newErpArticle].distinct())
                        } else {
                            actions.setErpArticles([...erpArticles, ...newErpArticle])
                        }
                    })
                }
            })

            channel("WORKTASK").publish("PARTS/REQUEST_LIST", createRequestArticleListPayload(item, userContext))
        },
        [userContext, actions, erpArticles, translateText]
    )

    return (
        <Box className="tk-parts">
            <Box className="list">
                <Box padding={(theme) => theme.spacing(0.5, 1)} className="article-list calculation-items">
                    {items?.map((item: ICalculationItem) => (
                        <ArticleItem key={item.uniqueId} item={item} requestAlternatives={requestAlternatives} />
                    ))}
                </Box>
            </Box>
        </Box>
    )
}
