import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function dutchOrderPlatesFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const dutchOrderPlatesModule = getModuleFromUserContext(userContext, UserModuleType.DutchOrderPlates)

    if (!dutchOrderPlatesModule) {
        return undefined
    }

    return {
        "dutch-order-plates": {
            items: {
                "dutch-order-plates": {
                    sort: 0,
                    name: "DOP",
                    active: true,
                    route: "/:workTaskId/dop",
                },
            },
        },
    }
}
