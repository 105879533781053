import { useLocalization } from "@tm/localization"
import { TableCellData, TableColumnData, TableVariants } from "@tm/components"
import { ConfirmationDisplay, Voucher } from "../../../../../data/model"
import DateCell from "../../../../_shared/cells/DateCell"
import { StyledTable } from "../../../../_shared/StyledComponents"
import VoucherOptionsCell from "../../../../_shared/cells/VoucherOptionsCell"
import { getBundleParams } from "../../../../../utils"

type Props = {
    selectedVoucherId?: string
    voucherTypeId: number
    vouchers: Array<Voucher>
    variant: TableVariants
    compact: boolean
    loadNextPage(): void
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function PickupInvoicesTableComponent(props: Props) {
    const { selectedVoucherId, vouchers, voucherTypeId, variant, compact, loadNextPage, onOpenNewVoucher } = props
    const { translateText } = useLocalization()
    const { cisVoucherUrl } = getBundleParams()

    function getColumns() {
        const columns: TableColumnData[] = [{ header: translateText(32) }, { header: translateText(433) }, { header: translateText(1323) }, {}]
        return columns
    }

    function getCells(voucher: Voucher) {
        const showVoucherConfirmationButton =
            !compact &&
            voucher.confirmationOptions?.canBeConfirmed &&
            (voucher.confirmationOptions.display === ConfirmationDisplay.ShowOnList ||
                voucher.confirmationOptions.display === ConfirmationDisplay.ShowOnListAndDetails)

        const cellData: TableCellData[] = [
            { displayValue: voucher.voucherId, id: "voucher_0" },
            {
                displayValue: <DateCell value={voucher.voucherDate} mode="dateDashTime" showHoursText />,
                id: "voucher_1",
            },
            { displayValue: voucher.information, id: "voucher_2" },
            {
                displayValue: (
                    <VoucherOptionsCell
                        detailsRoute={cisVoucherUrl}
                        selectedVoucherId={selectedVoucherId}
                        voucherId={voucher.voucherId}
                        itemsCount={voucher.itemsCount}
                        showConfirmVoucherButton={showVoucherConfirmationButton}
                        voucher={voucher}
                        voucherTypeId={voucherTypeId}
                        onOpenNewVoucher={onOpenNewVoucher}
                    />
                ),
                id: "voucher_3",
            },
        ]

        return cellData
    }

    const displayData = vouchers?.map((voucher, index) => ({
        cells: getCells(voucher),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === voucher.voucherId,
    }))

    return <StyledTable columns={getColumns()} rows={displayData} variant={variant} onScrollBottom={loadNextPage} />
}
