import { WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ErpInformation } from "@tm/models"
import { concat } from "@tm/utils"
import { forwardRef, useMemo } from "react"

type Props = {
    erpInfos: ErpInformation[]
    onConfirm(): void
    onCancel?(): void
}

export const ApprovalWarningPrompt = forwardRef<WarningPrompt, Props>(({ erpInfos, onConfirm, onCancel }, ref) => {
    const { translateText } = useLocalization()

    const warningText = useMemo(() => {
        const notOrderableArticleNumbers = erpInfos
            .filter((erpData) => erpData.itemOrderableInformation)
            .map((erpData) => erpData.wholesalerArticleNumber)
            .distinct()

        if (notOrderableArticleNumbers.length) {
            return translateText(1783).replace("{0}", concat(", ", ...notOrderableArticleNumbers))
        }

        return translateText(1785)
    }, [erpInfos, translateText])

    return (
        <WarningPrompt
            text={warningText}
            confirmationButtonText={translateText(585)}
            cancelButtonText={translateText(584)}
            ref={ref}
            onConfirm={onConfirm}
            onCancel={onCancel}
        />
    )
})
