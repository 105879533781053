import { isDevtoolEnabled, parseQueryString } from "@tm/utils"

type BundleParams = {
    eurotaxVehicleSelectionUrl?: string
    eurotaxMastercodeSelectionUrl?: string
    eurotaxGraphicSelectionUrl?: string
    eurotaxImagesUrl?: string
    eurotaxPartsUrl?: string

    disableStateSave?: boolean

}

const queryParams = parseQueryString(window.location.search)
const RDsessionId = queryParams.RDsessionId as string

let bundleParams: BundleParams

export const version = {
    name: "eurotax",
    version: "0.1.0",
}

export function initEurotaxBundle(params: BundleParams) {
    bundleParams = params
    // bundleParams.disableStateSave = true
    checkBundleParams(params)
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw `The toolkit params have to be set in order to use the bundle "${version.name}"`
    }
    return bundleParams
}

export function getRdSession() {
    return RDsessionId
}

function checkBundleParams(params: BundleParams) {
    if(!isDevtoolEnabled()){
        return
    }

    if (!params.eurotaxVehicleSelectionUrl) {
        console.warn(`${version.name}: The parameter 'eurotaxVehicleSelectionUrl' has to be set in the configuration`)
    }

    if (!params.eurotaxMastercodeSelectionUrl) {
        console.warn(`${version.name}: The parameter 'eurotaxMastercodeSelectionUrl' has to be set in the configuration`)
    }

    if (!params.eurotaxGraphicSelectionUrl) {
        console.warn(`${version.name}: The parameter 'eurotaxGraphicSelectionUrl' has to be set in the configuration`)
    }

    if (!params.eurotaxImagesUrl) {
        console.warn(`${version.name}: The parameter 'eurotaxImagesUrl' has to be set in the configuration`)
    }
}
