import { useMemo, useRef } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Text, Card, WarningPrompt, Dropdown } from "@tm/controls"
import { Article, ArticleInfoType, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Compilation, CompilationShowViewModelItem } from "../../../../../data"
import { mapCompilationShowViewModelItemToArticle } from "../../../../../data/mapper"
import { useArticleMove, useCompilations, useDeleteArticles } from "../../../../../data/hooks/useCompilations"
import { isWM } from "../../../../../utils"

type Props = {
    compilationId: string
    allSelected: boolean
    selectedArticles: Array<CompilationShowViewModelItem>
    onSelectAll(): void
    onUnselectAll(): void
    selectionMode?: "MOVE" | "DEFAULT"
}

export default function PartsSelectionComponent({
    compilationId,
    allSelected,
    selectedArticles,
    onSelectAll,
    onUnselectAll,
    selectionMode = "DEFAULT",
}: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const { deleteArticles } = useDeleteArticles()
    const { compilations } = useCompilations()
    const { moveArticle } = useArticleMove()

    const deleteConfirmationDialogRef = useRef<WarningPrompt>(null)

    const basketArticles = useMemo(() => selectedArticles.map(mapCompilationShowViewModelItemToArticle) as Article[], [selectedArticles])

    function handleSelectButtonClick() {
        if (allSelected) {
            onUnselectAll()
        } else {
            onSelectAll()
        }
    }

    function handleCancelButtonClick() {
        onUnselectAll()
    }

    function handleWarningConfirm() {
        deleteArticles({ compilationId, articleIds: selectedArticles.map((article) => article.id) })
    }

    function handleRemoveAll() {
        deleteConfirmationDialogRef.current?.show()
    }

    function renderSelectButton() {
        return (
            <Button onClick={handleSelectButtonClick} size="s">
                {translateText(allSelected ? 703 : 702)}
            </Button>
        )
    }

    function handleMoveDropdownChange({ id }: Compilation) {
        if (id !== compilationId) {
            moveArticle({
                articleIds: selectedArticles.map((x) => x.id),
                sourceCompilationId: compilationId,
                targetCompilationId: id,
            })
        }
    }

    function renderMoveButton() {
        if (isWM()) {
            return null
        }

        if (!compilations || compilations.length === 0) {
            return null
        }

        return (
            <div className="move-dropdown">
                <Dropdown
                    items={compilations}
                    value={compilations.find((x) => x.id === compilationId)}
                    itemView={(compilation: Compilation) => <>{compilation.name}</>}
                    onChange={handleMoveDropdownChange}
                    layout={["iconRight"]}
                    alignArrow="right"
                    isActive={selectionMode === "MOVE"}
                    size="s"
                />
            </div>
        )
    }

    function renderAddToBasket() {
        return renderMicro("basket", "add-to-basket", {
            data: basketArticles,
            articleInfoType: isWM() ? ArticleInfoType.WholesalerArticle : undefined,
            hideQuantityField: true,
        })
    }

    return (
        <Card className="card-parts-selection">
            <Text>
                <Text size="s" className="selection">
                    {translateText(701).toUpperCase()}:
                </Text>
                {isWM() && (
                    <Text size="s" className="numbers">
                        {selectedArticles.length} {translateText(479)}
                    </Text>
                )}
                <Text size="s" className="numbers">
                    {selectedArticles.map((x) => x.quantity.value).reduce((sum, cur) => sum + cur)} {translateText(90)}
                </Text>
            </Text>
            <div className={`dynamic-actions ${selectionMode === "MOVE" ? "switch" : ""}`}>
                {renderSelectButton()}
                {renderMoveButton()}
            </div>
            <Button className="delete-button" onClick={handleRemoveAll} size="s" icon="delete" layout={["dropshadow"]} />
            <WarningPrompt
                text={translateText(834)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={deleteConfirmationDialogRef}
                onConfirm={handleWarningConfirm}
            />
            {renderAddToBasket()}
            <Button className="cancel" icon="cancel-x" size="l" title={translateText(317)} onClick={handleCancelButtonClick} />
        </Card>
    )
}
