import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { PartslifeReturnUrlRequest, PartslifeReturnUrlResponse } from "../models/partslife"

export async function getReturnUrl(request: PartslifeReturnUrlRequest): Promise<PartslifeReturnUrlResponse | undefined> {
    const url = `${getBaseUrl()}/GetPartslifeRedirectUrl`
    const authorization = getStoredAuthorization()
    const body = request
    return ajax<PartslifeReturnUrlResponse>({ url, body, authorization, method: "POST" })
}

function getBaseUrl(): string {
    return getBundleParams().partslifeServiceUrl
}
