import { VoucherIncludedWork, VoucherPart, VoucherWork } from "@tm/data/vouchers/costEstimations"
import { OrderVoucherList } from "@tm/models"
import {
    VoucherPart as BasketVocherPart,
    VoucherWork as BasketVoucherWork,
    VoucherIncludedWork as BasketVoucherIncludedWork,
} from "../../../../basket/src/data/model/cost-estimation"
import {
    ExtendedOrderVoucherItem,
    OrderOptions,
    OrderVoucherItemsByWorkTask,
    ReturnInfo,
    ShowOrderDetailsResponse,
    ShowOrdersResponse,
} from "../model"

export function mapVoucherPartInPartItem(part: VoucherPart): BasketVocherPart {
    return {
        articleInformation: part.articleInformation,
        quantityValue: part.quantityValue,
        rebate: part.calculation.rebate,
        surcharge: part.calculation.surcharge,
        vatRate: part.calculation.vatRate,
        itemRole: part.itemRole,
        purchaseNetPrice: part.purchaseNetPrice,
        retailNetPrice: part.retailNetPrice,
        linkedItems: part.linkedItems?.map(mapVoucherPartInPartItem),
    }
}

export function mapVoucherWorkInWorkItem(work: VoucherWork): BasketVoucherWork {
    return {
        provider: work.provider,
        type: work.type,
        providerWorkId: work.providerWorkId,
        category: work.category,
        description: work.description,
        timeInMinutes: work.timeInMinutes,
        hourlyRateNet: work.hourlyRateNet,
        fixedPriceNetValue: work.fixedPriceNetValue,
        rebate: work.calculation.rebate,
        surcharge: work.calculation.surcharge,
        vatRate: work.calculation.vatRate,
        includedWorks: work.includedWorks.map(mapIncludedWorks),
        displayNumber: work.displayNumber,
        isFixedPrice: work.calculation.isFixedPrice,
        fittingSide: work.fittingSide,
        note: work.note,
        productGroupIds: work.productGroupIds,
        providerTimeInMinutes: work.providerTimeInMinutes,
        sortNo: work.sortNo,
    }
}

export function mapIncludedWorks(includedWork: VoucherIncludedWork): BasketVoucherIncludedWork {
    return {
        providerWorkId: includedWork.providerWorkId,
        category: includedWork.category,
        description: includedWork.description,
        timeInMinutes: includedWork.timeInMinutes,
        isCalculable: includedWork.isCalculable,
        isInternal: includedWork.isInternal,
        sortNo: includedWork.sortNo,
    }
}

/** ****************************************************************************************************************************************
 * The orderNumber shall not be mapped, sometimes a date time Stamp is stored as orderId https://jira.dvse.de/browse/NEXT-24102, https://jira.dvse.de/browse/NEXT-29143 *
 **************************************************************************************************************************************** */
function mapOrderVoucherList(data: any): OrderVoucherList | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderDate: data.orderDate ? new Date(data.orderDate) : undefined,
    }
}

export function mapShowOrdersResponse(data: any): ShowOrdersResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orders: (data.orders || []).map(mapOrderVoucherList),
    }
}

function mapReturnInfo(data: any): ReturnInfo | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        returnDate: data.returnDate ? new Date(data.returnDate) : undefined,
    }
}

function mapOrderVoucherItems(data: any): ExtendedOrderVoucherItem | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        returnableUntil: data.returnableUntil ? new Date(data.returnableUntil) : undefined,
        returnInfo: mapReturnInfo(data.returnInfo),
        vehicle: {
            ...data.vehicle,
            initialRegistration: data.vehicle?.initialRegistration ? new Date(data.vehicle?.initialRegistration) : undefined,
        },
    }
}

function mapOrderItemsByWorkTask(data: any): OrderVoucherItemsByWorkTask | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        workTaskInfo: {
            ...data.workTaskInfo,
            vehicleInfo: {
                ...data.workTaskInfo.vehicleInfo,
                initialRegistration: data.workTaskInfo.vehicleInfo?.initialRegistration
                    ? new Date(data.workTaskInfo.vehicleInfo.initialRegistration)
                    : undefined,
            },
        },
        orderVoucherItems: (data.orderVoucherItems || []).map(mapOrderVoucherItems),
    }
}

function mapOrderOptions(data: any): OrderOptions | undefined {
    if (!data) {
        return
    }

    return {
        ...data.orderOptions,
        deliveryDate: data.deliveryDate ? new Date(data.deliveryDate) : undefined,
        tour: {
            ...data.tour,
            tourStart: data.tour?.tourStart ? new Date(data.tour.tourStart) : undefined,
        },
    }
}

export function mapShowOrderDetailsResponse(data: any): ShowOrderDetailsResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        orderDate: data.orderDate ? new Date(data.orderDate) : undefined,
        orderItemsByWorkTask: (data.orderItemsByWorkTask || []).map(mapOrderItemsByWorkTask),
        orderOptions: mapOrderOptions(data.orderOptions),
    }
}
