import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { Button, Icon, InputGroup, LicensePlateField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import {
    CarLookupError,
    CarLookupModel,
    ECarLookupErrorType,
    RegisteredModels,
    RegistrationNoType,
    VehicleType,
    ViewStateContainer,
} from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { RouteComponentProps, encodeUniqueId, uniqueId, withRouter } from "@tm/utils"
import { useState, useRef, useEffect } from "react"

import { VRMAAA_STATE_KEY, VRMAAA_STATE_TYPE } from "../../../../../data/model/vrm-lookup"
import { showVehiclesByRegistrationNo } from "../../../../../data/repositories/vrm-lookup"
import { handleUniqueVehicleFound } from "../../../../../helpers/vrm"
import { ConfigProps } from "../component"

type RouteParams = {
    workTaskId: string
    query?: string
}

type Props = ConfigProps &
    RouteComponentProps<RouteParams> & {
        /** Is also called when the conflict dialog is opened. Keep that in mind when using this function. */
        onVehicleAttached?(): void
    }

/** @todo combine with default search field somehow? */
function VrmAAALicensePlateComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const { attachToWorkTask } = useWorkTask() || {}
    const context = useWorkTask()

    const [query, setQuery] = useState<string>()
    const [previousQuery, setPreviousQuery] = useState<string>()
    const [showError, setShowError] = useState<boolean>(false)
    const [attachShowErrorTo, setAttachShowErrorTo] = useState<HTMLElement>()
    const [contingentReached, setContingentReached] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()
    const [lookupInProgress, setLookupInProgress] = useState<boolean>(false)
    const { match, history, className, size } = props
    const { plateCode } = useCountryCodeToLicensePlate(context?.workTask?.vehicle?.countryCode)
    const licensePlateRef = useRef<HTMLElement>()

    useEffect(() => {
        const { query } = props.match.params
        if (!previousQuery && query) {
            setPreviousQuery(query)
        }

        if (!attachShowErrorTo && licensePlateRef.current) {
            const { outerContainerRef } = licensePlateRef.current as any
            if (outerContainerRef && outerContainerRef.parentElement) {
                setAttachShowErrorTo(outerContainerRef.parentElement)
            }
        }
    }, [attachShowErrorTo, previousQuery, licensePlateRef.current])

    function handleChange(query: string, path: Array<string>) {
        setQuery(query)

        if (query === "" || !query || (errorMessage !== undefined && query.replace(/\W/g, "").length >= 4)) {
            setErrorMessage(undefined)
        }

        if (errorMessage === undefined && query && query.replace(/\W/g, "").length < 4) {
            setErrorMessage(translateText(289).replace("#1#", "4"))
        }
    }

    function handleSearch() {
        if (previousQuery && previousQuery === query) {
            return
        }

        setPreviousQuery(query)
        setErrorMessage(undefined)

        if (!query || query.replace(/\W/g, "").length < 4) {
            return
        }

        if (!lookupInProgress) {
            setLookupInProgress(true)

            showVehiclesByRegistrationNo({ query, lookupTypeId: RegistrationNoType.VrmAAA })
                .then(({ carModels }) => {
                    if (carModels.length === 1) {
                        const model = carModels[0]

                        if (model.id) {
                            handleVehicleFound(model, query)
                        } else if (model.id === undefined && model.registrationNoDetails) {
                            Morpheus.showView("1", `/modal/^vrm/details/${RegistrationNoType.VrmAAA}/${query || ""}`)
                        }
                    } else if (carModels.length === 0) {
                        handleError({ errorMessage: translateText(1405), errorType: ECarLookupErrorType.Technical })
                    } else {
                        handleError({ errorMessage: "Multiple cars found", errorType: ECarLookupErrorType.Technical })
                    }
                }, handleError)
                .finally(handleFinished)
        }
    }

    function handleVehicleFound(model: CarLookupModel, query: string) {
        if (!props.match.params.workTaskId) {
            if (match.url.indexOf("modal") >= 0) {
                Morpheus.closeView("1", `/${encodeUniqueId(uniqueId())}`)
            } else {
                history.push(`${encodeUniqueId(uniqueId())}`)
            }
        }

        if (model && attachToWorkTask) {
            /** @todo: fix hardcoded vehicle type */
            handleUniqueVehicleFound(model, VehicleType.PassengerCar, query, attachToWorkTask).then(props.onVehicleAttached)

            const vrmAAAState: VRMAAA_STATE_TYPE = { lastUpdated: new Date().toISOString() }
            const viewStateContainer: ViewStateContainer = Container.getInstance(RegisteredModels.ViewState)
            viewStateContainer.action("saveViewState")({ key: VRMAAA_STATE_KEY, value: vrmAAAState })
        }
    }

    function handleError(error: CarLookupError) {
        switch (error.errorType) {
            case ECarLookupErrorType.NoPrivilege: {
                setContingentReached(true)
                break
            }
            case ECarLookupErrorType.Technical: {
                if (error.errorMessage) {
                    setErrorMessage(error.errorMessage)
                } else {
                    setErrorMessage(translateText(1393))
                }
                break
            }
            default:
                break
        }
    }

    function handleFinished() {
        setLookupInProgress(false)
    }

    const modelState: { [key: string]: Array<string> } = {}

    if (contingentReached || errorMessage) {
        if (errorMessage) {
            modelState.query = [errorMessage]
        } else {
            modelState.query = [translateText(1389)]
        }
    }

    return (
        <div className={`special-license-plates special-license-plates--aaa ${className ? ` ${className}` : ""}`}>
            <div className="special-license-plates__container">
                <div className="special-license-plates__description">
                    <Text size="s">{translate(1401)}:</Text>
                </div>
                <InputGroup
                    onFocus={() => setShowError(true)}
                    onMouseOver={() => setShowError(true)}
                    onMouseOut={() => setShowError(false)}
                    onBlur={() => setShowError(false)}
                >
                    <LicensePlateField
                        onRef={(el: HTMLElement) => {
                            licensePlateRef.current = el
                        }}
                        model={{ query }}
                        shortCountryCode={plateCode}
                        showCountryCode
                        maxLength={17}
                        onChange={handleChange}
                        onChangeConfirm={handleSearch}
                        size={size || "xl"}
                        path={["query"]}
                        modelState={modelState}
                        forceShowError={showError}
                        attachShowErrorTo={attachShowErrorTo}
                    />
                    <Button icon="search" skin="highlight" onClick={handleSearch} />
                </InputGroup>
                <div className="special-license-plates__subtitle">
                    <Icon className="background-icon" name="car-front" />
                    <Text className="subtext" size="xs">
                        {translateText(1020)}
                    </Text>
                </div>
            </div>
        </div>
    )
}

export default withRouter(VrmAAALicensePlateComponent)
