import { styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { Widget } from "@tm/controls"

type Props = {
    /** This url is being used as background image of this component when the MDM parameter is not set. */
    imageUrl?: string
}

const BackgroundImage = styled("div", { shouldForwardProp: (prop) => prop !== "src" })<{ src: string }>(({ src }) => ({
    position: "absolute",
    inset: 0,
    background: `url(${src}) center / cover no-repeat`,
}))

/**
 * This component is used to display a banner without any further interaction.
 * It used the MDM parameter "urlLogoMoodPicture" as background image if set otherwise the supplied property `imageUrl` is used.
 */
export default function Banner({ imageUrl }: Props) {
    const { userContext } = useUser()
    const { urlLogoMoodPicture = imageUrl } = userContext.parameter

    if (!urlLogoMoodPicture) {
        return null
    }

    return (
        <Widget active>
            <BackgroundImage src={urlLogoMoodPicture} />
        </Widget>
    )
}
