import { Collapsible, Toolbar } from "@tm/controls"
import { Typography, Box, Loader, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { clone, initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { filterModelsByInitialRegistration } from "../../../../data/helpers"
import { MainState } from "../../../main"
import { MainActions, vehicleFieldsSelector, vehicleModelsSelector } from "../../../main/business"
import VehicleList from "./vehicleList"

type Props = {
    onStartCalculation(): void
}
const mainSelector = createSelector(
    vehicleFieldsSelector,
    (s: MainState) => s.summary.vehicleModels.models,
    (fields, models) => {
        let filteredModels = clone(models)

        if (filteredModels && fields.initialRegistration) {
            filteredModels = filterModelsByInitialRegistration(fields.initialRegistration, filteredModels)
        }

        filteredModels = filteredModels?.filter(
            (vehicleModel) =>
                !fields.selectedEquipmentPackage ||
                (vehicleModel.equipment && vehicleModel.equipment.toUpperCase().includes(fields.selectedEquipmentPackage.toUpperCase()))
        )

        const exitModels = models?.filter((x) => !filteredModels?.find((y) => x.natCode === y.natCode))
        return { filteredModels, exitModels }
    }
)
const selector = initSelector(mainSelector, vehicleModelsSelector, (s) => s.extern.newCalculationLoading)

export default function VehicleSelection({ onStartCalculation }: Props) {
    const { translateText } = useLocalization()

    const [{ exitModels, filteredModels }, { vehicleModelsLoading, vehicleModelsError }, newCalculationLoading] = useSelector(selector)
    const actions = useActions(MainActions, "updateSelectedVehicle")

    if (vehicleModelsLoading) {
        return (
            <StyledBox>
                <Loader />
            </StyledBox>
        )
    }

    if (vehicleModelsError) {
        return (
            <StyledBox>
                <Toolbar title={translateText(862)} className="vehicle-selection">
                    <Box>
                        <Typography>{translateText(1570)}</Typography>
                    </Box>
                </Toolbar>
            </StyledBox>
        )
    }

    if (!filteredModels?.length) {
        return (
            <StyledBox>
                <Toolbar title={translateText(862)} className="vehicle-selection">
                    <Box>
                        <Typography>{translateText(1517)}</Typography>
                    </Box>
                </Toolbar>
            </StyledBox>
        )
    }

    return (
        <StyledBox>
            <Typography variant="h2" mb={1}>
                {translateText(862)}
            </Typography>
            <StyledBox>
                <VehicleList models={filteredModels} onClickRow={actions.updateSelectedVehicle} onDoubleClickRow={onStartCalculation} />
            </StyledBox>

            {exitModels && exitModels.length > 0 && (
                <Collapsible
                    name={translateText(869)}
                    disabled={false}
                    active={false}
                    initiallyOpened={false}
                    asDropDown={false}
                    closeDropdownOnOutsideClick={false}
                >
                    <VehicleList
                        selectionDisabled={newCalculationLoading}
                        models={exitModels}
                        onClickRow={actions.updateSelectedVehicle}
                        onDoubleClickRow={onStartCalculation}
                    />
                </Collapsible>
            )}
        </StyledBox>
    )
}

const StyledBox = styled(Box)({
    display: "block",

    "&.toolbar": {
        display: "block",

        ".toolbar__inner": {
            display: "block",

            ".subtitle": {
                fontWeight: 400,
                fontSize: "1.5em",
                lineHeight: 1.4,
                opacity: 1,
                textTransform: "capitalize",

                "&:after": {
                    content: '"*"',
                    color: "#f44336",
                },
            },

            ".toolbar__content": {
                height: "100%",
                display: "block",

                ".fancy-list__body": {
                    maxHeight: "14em",
                    minHeight: "3em",
                },
            },
        },
    },

    ".collapsible__content": {
        paddingLeft: "2em",
        paddingRight: "0.5em",

        ".fancy-list__body": {
            maxHeight: "14em",
            minHeight: "3em",
        },
    },

    ".fancy-list": {
        flex: 1,

        "&__body": {
            overflowY: "auto",
        },

        "&__item": {
            cursor: "pointer",

            "& span:first-child": {
                whiteSpace: "normal",
            },
        },

        "&__block": {
            flex: 1,
        },
    },
})
