import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../shared/withStoreProvider"
import { DashboardIndustry } from "./DashboardIndustry"

const DashboardIndustryBundle: BundleComponent = {
    name: "dashboard-industry",
    component: withStoreProvider(DashboardIndustry as any),
}

export { DashboardIndustryBundle, DashboardIndustry }
