import { EFilterNames, FilterNames, TyreFilter } from '@tm/models'
import { DRTire } from '../../../data/models'

export type SummaryState = {
	driveRightTires: {
		frontTires: DRTire[]
		rearTires: DRTire[]
		loading?: boolean
		error?: boolean
		lastTecDocSearched?: number
	},
	ignoreVehRecors?: boolean
	filters: Filters
	lastFiltersSearch?: string
	selectedFilters: SelectedFilters
    toggleTiresAccordion: boolean 
}

export type ISummarySingleFilters = Extract<FilterNames, "season" | "width" | "height" | "inch" | "loadIndex" | "oeIdentifier" | "speedIndex">
export type ISummaryMultiFilters = Extract<FilterNames, "carType">

export const SummaryMultiFilters: Array<FilterNames> = [
	EFilterNames.carType
]

export type ISummaryFilters = ISummarySingleFilters | ISummaryMultiFilters

export type IVehicleRecordFilter = Extract<ISummaryFilters, "width" | "height" | "inch">

export type Filters = { [key in ISummaryFilters]: TyreFilter[] } & { loading?: boolean, initialized: boolean, carTypeAndSeason?: TyreFilter[] }

export type SelectedFilters = { [key in ISummarySingleFilters]?: TyreFilter }
	& { [key in ISummaryMultiFilters]: TyreFilter[] }
	& { untouched?: boolean }
