import { useMutation } from "react-query"
import { useCallback } from "react"
import { ERIKContentService } from "@tm/data"

export function useCompleteFileUpload() {
    const { data, isLoading, mutateAsync } = useMutation(ERIKContentService.completeFileUpload)

    const completeFileUpload = useCallback(
        (baseUrl: string, uploadRequestId: string, uploadKey: string) => {
            const request: ERIKContentService.CompleteFileUploadRequest = {
                baseUrl,
                uploadRequestId,
                uploadKey,
            }

            return mutateAsync(request)
        },
        [mutateAsync]
    )

    return { data, isLoading, completeFileUpload }
}
