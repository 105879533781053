import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers"
import { reduce } from "./business"
import component from "./component"

const managerComponent: BundleComponent = {
    name: "main",
    component: withStoreProvider(component as any),
    reduce,
}

export type MainState = ReturnType<typeof reduce>

export default managerComponent
