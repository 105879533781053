import { PropsWithChildren } from "react"
import { Fade, Paper, Popper, styled } from "@tm/components"

const PopperPaper = styled(Paper)(({ theme }) => ({
    padding: 0,
    border: `1px solid ${theme.overwrites?.components?.textfield?.border?.color || "#e2e2e1"}`,
    borderRadius: theme.radius?.default || "3px",
    backgroundColor: theme.overwrites?.components?.textfield?.backgroundColor || "white",
    width: "min-content",
    minWidth: "500px",
}))

export function Suggestions(props: PropsWithChildren<{ anchor: HTMLElement | null; isOpen: boolean; className?: string }>) {
    const { anchor, isOpen, children, className } = props
    return (
        <Popper open={isOpen} anchorEl={anchor} placement="bottom-start" transition>
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <PopperPaper elevation={8} sx={{ minWidth: anchor?.offsetWidth ?? "auto" }} className={className}>
                        {children}
                    </PopperPaper>
                </Fade>
            )}
        </Popper>
    )
}
