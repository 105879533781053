import { Button, Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { style } from "typestyle"

const thankContainer = style({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    $nest: {
        button: {
            position: "absolute",
            right: 0,
            top: 0,
            transform: "translate(-1em,1em)",
        },
        svg: {
            height: "5em",
            width: "5em",
            marginBottom: "1em",
        },
    },
})

type Props = {
    onClick: () => void
}

export function Thanks(props: Props) {
    const { onClick } = props
    const { translateText } = useLocalization()

    return (
        <div className={thankContainer}>
            <Button icon="close" layout={["ghost"]} onClick={onClick} />
            <Icon name="thanks" />
            {translateText(1214)}
        </div>
    )
}
