import { Box, Loader, MuiCustomModal, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { CustomArticle, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useShowCustomArticleItems } from "../../../data/helpers/useShowCustomArticleItems"
import { useGetCustomArticlesCategory } from "../../../data/helpers/useGetCustomArticlesCategory"
import { CalcConsumable } from "../../../data/model"

type Props = {
    open: boolean
    onClose(open: number | null): void
    consumable: CalcConsumable
    replaceConsumable: (customArticle: CustomArticle, consumable: CalcConsumable) => void
}

export default function ReplaceConsumableModal({ open, onClose, consumable, replaceConsumable }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const { categoryTextId: genartTextId, isError: customCategoryError } = useGetCustomArticlesCategory(consumable.genArtNr, true)
    const request: Parameters<typeof useShowCustomArticleItems>[0] = {
        categoriesFilter: genartTextId,
    }
    const { data: { pages } = {}, isError, refetch: refetchCustomArticles } = useShowCustomArticleItems(request)
    const customArticles = useMemo(() => pages?.flatMap((x) => x.data) ?? [], [pages])

    const handleClose = () => {
        onClose(null)
    }

    function onConsumableReplace(selected: CustomArticle) {
        replaceConsumable(selected, consumable)
        handleClose()
        refetchCustomArticles()
    }

    function onConsumableSaved() {
        refetchCustomArticles()
    }

    let content = (
        <Box width="100%" height="100%" display="flex" alignItems="center" justifyItems="center">
            <Loader />
        </Box>
    )

    if (isError || customCategoryError) {
        content = <Typography>{translateText(12775)}</Typography>
    }

    if (customArticles) {
        content = (
            <>
                {renderMicro("parts", "consumable-replace", {
                    title: consumable.genArtLabel ?? translateText(616),
                    category: genartTextId,
                    consumables: customArticles,
                    onConsumableReplace,
                    onConsumableSaved,
                })}
            </>
        )
    }

    if (open) {
        return (
            <MuiCustomModal open onOutsideClick={handleClose} smallCloseButton showCloseButtonOnTheLeft>
                {content}
            </MuiCustomModal>
        )
    }

    return null
}
