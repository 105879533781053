import { useMemo } from "react"
import { Icon, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Divider, Slider } from "@tm/components"
import { important } from "csx"
import { CollapsibleWrapper } from "."
import { getTranslationFromSafetyStatus } from "../../helpers"

type Props = {
    onSelect(value: number): void
    value?: number
}

const marks = [
    {
        value: 0,
        label: "warning-dark",
        fillColor: "red",
    },
    {
        value: 1,
        label: "warning-yellow",
        fillColor: "red",
    },
    {
        value: 2,
        label: "not",
        fillColor: "red",
    },
]

export default function SecurityStatus({ onSelect, value }: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    const valueLabelFormat = (safetyStatusValue: number) => {
        return getTranslationFromSafetyStatus(safetyStatusValue, translateText)
    }

    const renderMarkLabels = (element: any) => {
        return (
            <div style={element.style} aria-hidden data-index={element["data-index"]} className={style.markLabel}>
                {element["data-index"] === 0 && <Icon name="info-filled" size="m" />}

                {element["data-index"] === 1 && <Image className={style.iconImage} url="/styles/base/images/warning-triangle.svg" />}

                {element["data-index"] === 2 && <Image className={style.iconImage} url="/styles/base/images/no-entry-sign.svg" />}

                {valueLabelFormat(element["data-index"])}
            </div>
        )
    }

    const handleChange = (ev: Event, value: number | number[], active: number) => {
        if (typeof value === "number") {
            onSelect(value)
        }
    }

    return (
        <CollapsibleWrapper textId={12756}>
            <div className={style.sliderWrapper}>
                <Slider
                    aria-label="Restricted values"
                    onChange={handleChange}
                    defaultValue={value}
                    components={{ MarkLabel: renderMarkLabels }}
                    step={null}
                    valueLabelDisplay="auto"
                    marks={marks}
                    valueLabelFormat={valueLabelFormat}
                    min={0}
                    max={2}
                    classes={{ root: style.root, mark: style.mark, markActive: style.markActive }}
                    sx={(theme) => ({
                        color: theme.palette.primary.main,
                        "& .MuiSlider-thumb": {
                            width: "30px",
                            height: "30px",
                        },
                        ".MuiSlider-track": {
                            display: "none",
                        },
                        ".MuiSlider-markActive": {
                            color: "#c3c3c3 !important",
                        },
                    })}
                />
            </div>
            <Divider />
        </CollapsibleWrapper>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        markActive: {
            color: important(theme.colors.primary),
        },
        markLabel: {
            lineHeight: theme.font.lineHeight,
            position: "absolute",
            top: "50px",
            transform: "translateX(-50%)",
            color: theme.colors.secondary,
            display: "flex",
            alignItems: "center",
            gap: theme.margin.xs,
            whiteSpace: "nowrap",
        },
        icon: {
            $nest: {
                "path:nth-child(1)": {
                    fill: "yellow",
                },
                "path:nth-child(2)": {
                    fill: "#000",
                },
            },
        },
        root: {
            margin: theme.margin.xl,
            marginBottom: important("5em"),
            flex: 1,
            maxWidth: "40em",
        },
        sliderWrapper: {
            display: "flex",
            justifyContent: "center",
        },
        mark: {
            height: important("30px"),
            width: important("30px"),
            borderRadius: important("50%"),
            color: "#c3c3c3",
            backgroundColor: important("currentColor"),
            transform: important("translate(-15px, -50%)"),
            opacity: important(1),
        },
        iconImage: {
            height: "1.25em",
            width: "1.25em",
        },
    })(SecurityStatus)
}
