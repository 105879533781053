import { DRTire, Tire } from "../../../../data/models"

export function mapTyres(frontTires: DRTire[], rearTires: DRTire[]): Tire[] {
	const tyres = frontTires.map((frontTire: DRTire, index: number) => {
		return {
			loadIndexFront: frontTire.loadIndex,
			rimSizeFront: frontTire.rimSize,
			speedIndexFront: frontTire.speedindex,
			sizeFront: frontTire.size,
			rimOffsetFront: frontTire.offsetValue,
			seasonSummerFront: frontTire.isSummer,
			seasonWinterFront: frontTire.isWinter,
		
			loadIndexRear: rearTires[index].loadIndex,
			rimSizeRear: rearTires[index].rimSize,
			speedIndexRear: rearTires[index].speedindex,
			sizeRear: rearTires[index].size,
			rimOffsetRear: rearTires[index].offsetValue,
			seasonSummerRear: rearTires[index].isSummer,
			seasonWinterRear: rearTires[index].isWinter
		}
	})
    return tyres
}