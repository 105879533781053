import { CrmBundleParams } from "@tm/models"
import { registerActions as registerCustomerActions } from "./data/repositories/customer"
import { registerActions as registerVehicleActions } from "./data/repositories/vehicle"

export const version = {
    name: "crm",
    version: "1.0.0",
}

let bundleParams: CrmBundleParams

export function getBundleParams(): CrmBundleParams {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function initCrmBundle(params: any) {
    bundleParams = params
    registerCustomerActions()
    registerVehicleActions()
    return bundleParams
}
