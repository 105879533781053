import { useEffect, useState } from "react"

import { Box, styled } from "@tm/components"
import { Button, Text, Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { RouteComponentProps, useAvailableVehicleSearchOptions, useMultiWidgetState, withRouter } from "@tm/utils"
import { getVehicleIdentificationUrlWithLookup } from "../../helpers/routing"
import Navigation from "./components/navigation"
import Search from "./components/search"
import SelectionComponent from "./components/selection"
import SpecialLicensePlatesComponent from "../widget/components/special-license-plates/component"
import { VehicleLookupSelection } from "../_shared/VehicleLookupSelection"
import CarSearchCard from "./CarSearchCard"
import { VehicleLookupSelectionSwitch } from "../_shared/VehicleLookupSelectionSwitch"
import { VehicleSearchfieldV1Props } from "../_shared/VehicleSearchFieldSwitch"

type Props = RouteComponentProps<{ workTaskId?: string }> & {
    storeId?: string
    showComponentKey?: string
    hideVehicleTypeSwitch?: boolean
    showSpecialLicensePlateMicro?: boolean
    specialLicenseProps?: Record<string, any>
    placeholderPassengerCar?: string
    contentOnly?: boolean
    onlySearchInputs?: boolean
}

const StyledVehicleLookupSelection = styled(VehicleLookupSelectionSwitch)({
    alignSelf: "flex-start",
    "& .MuiSelect-outlined": {
        padding: "0px 1em 0px 2em !important",
    },
})

function SearchTreeWidgetComponent(props: Props) {
    const [selectedMultiWidgetTab] = useMultiWidgetState({ storeId: props?.storeId })

    const { translateText } = useLocalization()
    const [vehicleType, setVehicleType] = useState(VehicleType.PassengerCar)
    const [showCurrentVehicleContent, setShowCurrentVehicleContent] = useState(true)
    const [selectedSearchConfig, setSelectedSearchConfig] = useState<VehicleLookupConfig>()
    const [specializedSearchConfig, setSpecializedSearchConfig] = useState<VehicleLookupConfig>()

    // this one could be implemented inside the vehiclelookupselection
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const allConfiguredVehicleLookups = availableVehicleSearches?.configuredVehicleLookups
    function defaultLookup(value: VehicleLookupConfig) {
        return value.isDefault
    }

    useEffect(() => {
        if (!selectedSearchConfig && allConfiguredVehicleLookups) {
            const defaultVehicleLookup = allConfiguredVehicleLookups.find(defaultLookup)
            defaultVehicleLookup && setSelectedSearchConfig(defaultVehicleLookup)
        }
    }, [availableVehicleSearches])

    useEffect(() => {
        if (availableVehicleSearches) {
            const { configuredVehicleLookups } = availableVehicleSearches // if there is an selectedSearchConfig, availableVehicleSearches is maybe set
            if (availableVehicleSearches?.configuredVehicleLookups && selectedSearchConfig) {
                const vrmLookupConfig = configuredVehicleLookups.find(
                    (lookup) => !lookup.isUsedInDefaultSearch && selectedSearchConfig.countryCode === lookup.countryCode
                )
                setSpecializedSearchConfig(vrmLookupConfig)
            }
        }
    }, [selectedSearchConfig])

    if (props.showComponentKey && selectedMultiWidgetTab !== props.showComponentKey) {
        return null
    }

    function handleVehicleAttached() {
        if (!showCurrentVehicleContent) {
            setShowCurrentVehicleContent(true)
        }
    }

    const className = `tk-vehicle search-tree-widget ${props.showSpecialLicensePlateMicro ? "special-license-plate-micro" : ""}`

    if (props.contentOnly) {
        return (
            <CarSearchCard
                allConfiguredVehicleLookups={allConfiguredVehicleLookups}
                handleVehicleAttached={handleVehicleAttached}
                selectedSearchConfig={selectedSearchConfig}
                setSelectedSearchConfig={setSelectedSearchConfig}
                setVehicleType={setVehicleType}
                vehicleType={vehicleType}
                specializedSearchConfig={specializedSearchConfig}
                hideVehicleTypeSwitch={props.hideVehicleTypeSwitch}
                placeholderPassengerCar={props.placeholderPassengerCar}
                showSpecialLicensePlateMicro={props.showSpecialLicensePlateMicro}
                specialLicenseProps={props.specialLicenseProps}
            />
        )
    }

    return (
        <Widget active className={className}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    ".lnt-widgets__content &": {
                        position: "absolute",
                        top: "-50px",
                        right: "-5em",
                    },
                }}
            >
                {!props.hideVehicleTypeSwitch && <Navigation activeVehicleType={vehicleType} setVehicleType={setVehicleType} />}
                {allConfiguredVehicleLookups && (
                    <StyledVehicleLookupSelection
                        vehicleType={vehicleType}
                        className="vehicle_search-tree-widget_lookup-selection"
                        onSelectSearchOption={setSelectedSearchConfig}
                        selectedSearchConfig={selectedSearchConfig}
                    />
                )}
            </Box>

            <SearchContainer
                vehicleType={vehicleType}
                placeholderPassengerCar={props.placeholderPassengerCar}
                selectedSearchConfig={selectedSearchConfig}
                specialLicensePlatesProps={{
                    ...props.specialLicenseProps,
                    onVehicleAttached: handleVehicleAttached,
                    showSpecialLicensePlateMicro: props.showSpecialLicensePlateMicro,
                }}
            />

            <div className="line" />

            <GraphicalVehicleSearchContainer vehicleIdentifierUrl={getVehicleIdentificationUrlWithLookup(props.match.params, { vehicleType })} />

            <div className="line">
                <Text>{translateText(1098)}</Text>
            </div>

            <SelectionComponent key={vehicleType} vehicleType={vehicleType} selectedSearchConfig={selectedSearchConfig} />
        </Widget>
    )
}

export default withRouter(SearchTreeWidgetComponent)

function GraphicalVehicleSearchContainer(props: { vehicleIdentifierUrl: string }) {
    const { translateText } = useLocalization()
    return (
        <div className="button-container">
            <Text className="label">{translateText(1095)}</Text>

            <Button icon="vehiclesearch" className="vehiclesearch" linkTo={props.vehicleIdentifierUrl}>
                {translateText(1096)}
                <br />
                <small>{translateText(1097)}</small>
            </Button>
        </div>
    )
}
type SearchContainerProps = Omit<VehicleSearchfieldV1Props, "onStartSearch"> & { selectedSearchConfig?: VehicleLookupConfig }
function SearchContainer(props: SearchContainerProps) {
    return (
        <div className="search-container">
            <Search {...props} />
        </div>
    )
}
