import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { merge } from "lodash"
import { ModuleItemFactory } from "../models"

export function tecrmiFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const tecRmiModule = getModuleFromUserContext(userContext, UserModuleType.TechDataTecRmi)
    const tecRmiTruckModule = getModuleFromUserContext(userContext, UserModuleType.TecRMITruck)

    if (!tecRmiModule && !tecRmiTruckModule) {
        return {}
    }

    const hasRepairTimes = tecRmiTruckModule || tecRmiModule?.uiConfigKeys?.includes("awdata")

    let payload: ModuleItemFactory = {
        gti: {
            items: {
                tecrmi: {
                    sort: 2,
                    name: "TecRMI",
                    active: true,
                    route: "/:workTaskId/gpi/tecrmi",
                    disabledFunction: "GPI-TECRMI-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        gpi: "tecrmi",
                    },
                },
            },
        },
        tyres: {
            items: {
                tecrmi: {
                    sort: 1,
                    name: "TecRMI",
                    active: true,
                    route: "/:workTaskId/tecrmi-tyres",
                    disabledFunction: "TYRES-TECRMI-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        tyres: "tecrmi",
                    },
                },
            },
        },
        inspection: {
            items: {
                tecrmi: {
                    sort: 2,
                    name: "TecRMI",
                    route: "/:workTaskId/sd/tecrmi",
                    active: true,
                    disabledFunction: "INSPECTIONDATA-TECRMI-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        inspectionData: "tecrmi",
                    },
                },
            },
        },
        tech: {
            items: {
                tecrmi: {
                    sort: 2,
                    name: "TecRMI",
                    route: "/:workTaskId/td/tecrmi",
                    active: true,
                    disabledFunction: "TECHDATA-TECRMI-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        technicalData: "tecrmi",
                    },
                },
            },
        },
    }

    if (hasRepairTimes) {
        payload = merge(payload, {
            repairtimes: {
                items: {
                    tecrmi: {
                        sort: 3,
                        name: "TecRMI",
                        route: "/:workTaskId/repair-times/tecrmi",
                        active: true,
                        disabledFunction: "REPAIRTIMES-TECRMI-DISABLED",
                        setAsActiveVehicleDataProviderOnSelect: {
                            repairTimes: "tecrmi",
                        },
                    },
                },
            },
        })
    }

    return payload
}
