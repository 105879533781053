import { selectorFamily, useRecoilValueLoadable } from "recoil"
import { getBundleParams } from "../../utils"
import { getSupplierLogo } from "../repositories/supplier-logos"

type Request = {
    supplierId: number
}

const supplierLogoSelector = selectorFamily<string | undefined, Request>({
    key: "parts_supplierLogoSelector",
    get:
        ({ supplierId }) =>
        () => {
            if (!getBundleParams().showSupplierLogos) {
                return
            }
            return getSupplierLogo(supplierId).catch(() => undefined)
        },
})

export function useSupplierLogo(request: Request): { supplierLogoLoading: boolean; supplierLogo?: string } {
    const result = useRecoilValueLoadable(supplierLogoSelector(request))

    if (result.state === "hasValue") {
        return { supplierLogoLoading: false, supplierLogo: result.contents }
    }
    if (result.state === "loading") {
        return { supplierLogoLoading: true }
    }
    return { supplierLogoLoading: false }
}
