import { useQuery } from "react-query"
import { getMemoTechnicalInfo } from "../repositories"

const KEY = "memotool_useGetTechnicalMemoInfo"
export function useGetTechnicalMemoInfo(modelId?: number, engineCode?: string) {
    const queryEnabled = !!modelId && !!engineCode

    const { data, isLoading, error } = useQuery(
        [KEY, modelId, engineCode],
        () => getMemoTechnicalInfo({ modelId: modelId!, engineCode: engineCode! }),
        { staleTime: 30 * 60 * 1000, cacheTime: 30 * 60 * 1000, enabled: queryEnabled, retry: 2 }
    )

    return { technicalNotes: data, isLoading, error }
}