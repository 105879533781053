import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { DriveRightTiresRespone } from "./"
import { mapDriveRightTiresResponse } from "./mapper"
export * from "./model"

export function loadDriveRightTires(kTypNr: number) {
    const url = `${getServiceUrl()}/DriveRightTyres`
    const authorization = getStoredAuthorization()
    const body = { kTypNr }

    return new Promise<DriveRightTiresRespone>((resolve, reject) =>
        ajax({ url, body, authorization }).then(
            response => {
                if (response && response.tyres)
                    resolve(mapDriveRightTiresResponse(response))
                else
                    reject()
            },
            reject
        ))
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.tyresServiceUrl
}