import { Fragment } from "react"
import { CustomArticle } from "@tm/models"
import { CollapsibleWrapper } from ".."
import { SpecificationsGenart, Work } from "../../../data/models"
import Lubricants from "./Lubricants"
import ConsumableArticleComponent from "./ConsumableArticle"

type Props = {
    items: SpecificationsGenart[]
    workItem: Work
    onSelectLubricant: (item: string) => void
    selectedLubricant: string | undefined
    onConsumableReplace: (consumableId: string, newConsumableArticle: CustomArticle) => void
}

export default function Consumables({ items, workItem, onSelectLubricant, selectedLubricant, onConsumableReplace }: Props) {
    return (
        <CollapsibleWrapper textId={526}>
            {items.map((item, idx) => {
                return (
                    <Fragment key={idx}>
                        <Lubricants item={item} selectedLubricant={selectedLubricant} onSelectLubricant={onSelectLubricant} workItem={workItem} />
                        {item.isMandatory && <ConsumableArticleComponent item={item} onConsumableReplace={onConsumableReplace} />}
                    </Fragment>
                )
            })}
        </CollapsibleWrapper>
    )
}
