import { Customer } from "@tm/models"
import { OpenWorkTaskDialogState } from ".."
import { useVehicleById } from "../hooks/useVehicleSearch"
import ConflictStart from "./conflict"
import { CustomerInfo } from "../../../../../../data"
import { ModalLoader } from "./_shared"
import { VehicleSearch } from "./vehicle-search"

export type Props = {
    chatId: string
    openOptions: OpenWorkTaskDialogState
    customer: Customer | undefined
    tmCustomerId: string | undefined
    customerInfo: CustomerInfo
}

export default function VehicleStart({ chatId, openOptions, customer, tmCustomerId, customerInfo }: Props) {
    const { selectedChatVehicle } = openOptions
    const { vehicleById, vehicleByIdLoading } = useVehicleById(selectedChatVehicle.tmVehicleId)

    if (vehicleByIdLoading) {
        return <ModalLoader />
    }
    if (vehicleById) {
        return (
            <ConflictStart
                chatId={chatId}
                customer={customer}
                vehicle={vehicleById}
                tmCustomerId={tmCustomerId}
                customerInfo={customerInfo}
                openOptions={openOptions}
            />
        )
    }

    return <VehicleSearch chatId={chatId} openOptions={openOptions} customer={customer} tmCustomerId={tmCustomerId} customerInfo={customerInfo} />
}
