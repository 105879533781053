import { useLocalization } from "@tm/localization"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { renderRoute, encodeUniqueId, getTitleTypeTextId } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { Button, Card, Typography, CardContent, Grid, Image, Stack, styled, TextField, Icon } from "@tm/components"
import { useParams } from "react-router"
import { StackRow } from "../../StyledComponents"

const FullWidthTextField = styled(TextField)({})
FullWidthTextField.defaultProps = { fullWidth: true, size: "large" }

type Props = {
    customerListRoute: string
    customerDetailsRoute: string
}

export default function CustomerOverview({ customerListRoute, customerDetailsRoute }: Props) {
    const { translateText } = useLocalization()
    const matchParams = useParams()
    const customer = useWorkTask()?.workTask?.customer
    const userSettings = useUser()?.userSettings
    const { companyName, contactInfo, postalAddress, printLogo, logo } = userSettings?.repairShop ?? {}
    let repairShopLogo = printLogo ? logo : undefined

    if (repairShopLogo) {
        repairShopLogo = repairShopLogo.replace("image/*", "image/png") /* IE11 & Edge Fix */
    }

    const titleType = customer && customer.titleType && getTitleTypeTextId(customer.titleType)

    const handleOpenCustomerList = () => {
        let url
        if (customer) {
            const customerId = encodeUniqueId(customer.id)
            url = renderRoute(customerDetailsRoute, { ...matchParams, customerId })
        } else {
            url = renderRoute(customerListRoute, { ...matchParams })
        }

        Morpheus.showView("1", url)
    }

    return (
        <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Card sx={{ maxWidth: "50%" }}>
                <CardContent>
                    <StackRow justifyContent="space-between" mb={1}>
                        <Typography variant="h4">{translateText(107)}</Typography>
                        <Button startIcon={customer ? <Icon name="edit_change" /> : <Icon name="menu" />} onClick={handleOpenCustomerList} />
                    </StackRow>
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(102)} value={customer?.customerNo} />
                        </Grid>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(118)} value={titleType && translateText(titleType)} />
                        </Grid>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(76)} value={customer?.academicTitle} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(119)} value={customer?.firstName} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(104)} value={customer?.lastName} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(108)} value={customer?.companyName} />
                        </Grid>
                        <Grid item xs={3}>
                            <FullWidthTextField label={translateText(111)} value={customer?.street} />
                        </Grid>
                        <Grid item xs={2}>
                            <FullWidthTextField label={translateText(112)} value={customer?.zip} />
                        </Grid>
                        <Grid item xs={4}>
                            <FullWidthTextField label={translateText(845)} value={customer?.city} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Stack direction="row" spacing={2}>
                {repairShopLogo && <Image src={repairShopLogo} style={{ padding: "1rem", maxWidth: "15rem", maxHeight: "6rem" }} />}
                {userSettings && userSettings.repairShop && (
                    <Stack>
                        <Typography variant="h2">{companyName}</Typography>
                        <Typography variant="label">{postalAddress && postalAddress.street}</Typography>
                        <Typography variant="label">{postalAddress && `${postalAddress.zip}, ${postalAddress.city}`}</Typography>
                        <Typography variant="label">{contactInfo && contactInfo.phone}</Typography>
                        <Typography variant="label">{contactInfo && contactInfo.email}</Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}
