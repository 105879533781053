
import { useModuleNavigation } from "@tm/context-distribution"
import { useEffect } from "react"

export const WrappedModule: React.FC<any> = ({ children }) => {
    const { showTab } = useModuleNavigation()

    useEffect(() => {
        showTab({
            icon: "group",
            title: "Eurotax - {{571}}",
        })
    }, [showTab])

    return children
}
