import { LinkDto, ResponseError } from "./shared"

export type VinSearchRequest = {
    vin: string
}

export type VinSearchResponse = {
    data: {
        vehicles: YqVehicleDetails[]
    }
    error?: ResponseError
    manufacturerId: number
}

export enum VehicleAttributeCodes {
    date = "date",
    manufactured = "manufactured",
    prodrange = "prodrange",
    market = "market",
    engine = "engine",
    engineInfo = "engine_info",
    transmission = "transmission",
    framecolor = "framecolor",
    trimcolor = "trimcolor",
}

export type YqVehicleDetails = {
    navigationLinks: NavigationLink[]
    type: string
    brand: string
    model: string
    attributes: VehicleAttribute[]
    sysProperties: SysProperty[]
    links: LinkDto[]
    token: string
    manufacturerId: number
}

export type YqVehicle = {
    yqVehicle?: YqVehicleDetails
    vin: string
}

export type NavigationLink = LinkDto & {
    code: string
}

export type VehicleAttribute = {
    code: VehicleAttributeCodes
    label: string
    values: string[]
    type: string
}

export type SysProperty = {
    code: string
    value: string
}
