import { LiveItems, EServiceGroupItemType, EServiceItemGroupType, GroupItem, ServiceItemGroup, ScanItem } from "../../models"

export function getLiveItems(scanItems: ScanItem[] | undefined): LiveItems | undefined {
    if (!scanItems || scanItems.length <= 0) {
        return undefined
    }

    const scanItem = scanItems[0]

    const odometer = getItem(
        getGroupItems(scanItem.serviceItems?.serviceItemGroups, EServiceItemGroupType.CategoryOdometer),
        EServiceGroupItemType.Odometer
    )
    const battery = getItem(
        getGroupItems(scanItem.serviceItems?.serviceItemGroups, EServiceItemGroupType.CategoryBattery),
        EServiceGroupItemType.BatteryVoltage
    )

    const serviceGroup = getGroupItems(scanItem.serviceItems?.serviceItemGroups, EServiceItemGroupType.CategoryService)

    const serviceDueDate = getItem(serviceGroup, EServiceGroupItemType.VehicleCheckDistanceUntilService)
    const distanceUntilService = getItem(serviceGroup, EServiceGroupItemType.EngineOilDistanceUntilService)
    const checkServiceDueDate = getItem(serviceGroup, EServiceGroupItemType.VehicleCheckServiceDueDate)

    return {
        odometer,
        batteryVoltage: battery,
        distanceUntilService: serviceDueDate,
        oilDistanceUntilService: distanceUntilService,
        checkServiceDueDate,
    }
}

function getGroupItems(serviceItemGroup: ServiceItemGroup[] | undefined, groupType: EServiceItemGroupType) {
    return serviceItemGroup?.find((group) => group.type === groupType)?.groupItems
}

function getItem(groupItemList: GroupItem[] | undefined, type: EServiceGroupItemType) {
    return groupItemList?.find((groupItem) => groupItem.type === type)
}
