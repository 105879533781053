import { getStyleTheme } from "@tm/context-distribution"
import { stylesheet } from "typestyle"

export function getComponentStyles() {
    const { colors } = getStyleTheme()
    return stylesheet({
        main: {
            border: "1px solid #aaaaaa",
            height: ".8rem",
            width: ".8rem",
            flex: "0 0 auto",
            borderRadius: "100%",
        },
        new: {
            borderColor: colors.primary,
            background: colors.primary,
        },
    })
}
