import { Article, ErpInformation, ErpSystemConfig } from "@tm/models"
import { Suspense } from "react"
import { useErpInfo } from "../../../../_helpers/useErpInfo"
import { useArticleWithChangedTradeReference } from "../../../../../data/hooks/useArticleWithChangedTradeReference"

type Props = {
    config: ErpSystemConfig
    article: Article
    fallback: JSX.Element | null
    children: (props: { erpInfo?: ErpInformation; error?: string }) => JSX.Element | null
}

function ErpWrapper(props: Props) {
    const { loading, erpInfo, error } = useErpInfo(props.article, "list", undefined, props.config)

    if (loading) {
        return props.fallback
    }

    return props.children({ erpInfo, error })
}

function PartnersystemWithTradeReference(props: Props) {
    const mergedArticle = useArticleWithChangedTradeReference(props.article, props.config)

    if (!mergedArticle) {
        return null
    }

    return <ErpWrapper {...props} article={mergedArticle} />
}

export default function PartnerSystemHelper(props: Props) {
    return (
        <Suspense fallback={props.fallback}>
            <PartnersystemWithTradeReference {...props} />
        </Suspense>
    )
}
