import { Vehicle } from "@tm/models"
import { History } from "history"
import { renderRoute } from "@tm/utils"
import { getBundleParams } from "./utils"
import { ReplaceArticle } from "./data/model"

export function hasFastCalculatorData(vehicle?: Vehicle): boolean {
    if (!vehicle) {
        return false
    }
    return !!vehicle?.dataAvailabilities?.fastCalculator.hasAny
}

export function getPoolingInterval(): number {
    const pollingInterval = +getBundleParams()?.pollingInterval

    return (Number.isInteger(pollingInterval) && pollingInterval > 0 ? pollingInterval : 5) * 1000
}

export function navigateWithReplaceParam(history: History<unknown>, replaceArticle: ReplaceArticle, addParam: boolean) {
    const search = new URLSearchParams(replaceArticle.search)
    if (addParam) {
        search.append("isReplaceArticle", "true")
    } else {
        search.delete("isReplaceArticle")
    }

    const pathname = renderRoute(replaceArticle.match.path, { ...replaceArticle.match.params }).replace("/modal/", "/modal^/")

    if (!replaceArticle.inModal) {
        history.replace({ pathname, search: search.toString() })
        return
    }

    const params = new URLSearchParams(history.location.search)
    const modalUrl = `${pathname}?${search.toString()}`
    params.set(`(1)`, modalUrl)
    const url = `${history.location.pathname}?${params.toString()}`
    history.replace(url)
}
