import { ArticleIdentifier, ArticleInfoType } from "@tm/models"
import { useArticlesByArticleNumbersWithOptionalVehicle, useSelection } from "@tm/utils"
import { useCallback, useEffect, useMemo } from "react"
import { useQuery } from "react-query"
import { useLocalization } from "@tm/localization"
import { ShowDetailsResponse, VoucherPart, showDetails } from "@tm/data/vouchers/costEstimations"
import { mapVoucherPartToArticleIdentifier } from "../../../../data/mapper"

export function useCostEstimationDetails(voucherId: string | undefined) {
    const { language } = useLocalization()
    const { data, isLoading: costEstimationDetailsLoading } = useQuery(
        ["vouchers__useCostEstimationDetails", voucherId],
        () => (voucherId ? showDetails({ id: voucherId }) : undefined),
        {
            enabled: !!voucherId,
            staleTime: 60 * 1000, // 1 minute
        }
    )

    const getAllPartIds = useCallback(() => {
        return (
            data?.costEstimation?.parts
                ?.filter((item) => item.articleInformation.articleInfoType === ArticleInfoType.TecdocArticle)
                .map((item) => item.partItemId) || []
        )
    }, [data?.costEstimation?.parts])

    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getAllPartIds)

    useEffect(() => {
        unselectAll()
    }, [unselectAll, voucherId])

    const articleIdentifiers = useMemo((): Array<ArticleIdentifier> | undefined => {
        const filteredParts = data?.costEstimation?.parts?.filter(
            (part) =>
                part.articleInformation.articleInfoType === ArticleInfoType.TecdocArticle ||
                part.articleInformation.articleInfoType === ArticleInfoType.WholesalerArticle
        )
        return filteredParts && mapVoucherPartToArticleIdentifier(filteredParts)
    }, [data?.costEstimation?.parts])

    const { articles } = useArticlesByArticleNumbersWithOptionalVehicle(articleIdentifiers, language)
    const costEstimationDetails = useMemo<ShowDetailsResponse | undefined>(() => {
        if (data && articles?.length) {
            return {
                ...data,
                costEstimation: data.costEstimation
                    ? {
                          ...data.costEstimation,
                          parts: data?.costEstimation?.parts?.map<VoucherPart>((part) => {
                              const match = articles.find(
                                  (article) =>
                                      part.articleInformation.supplierId === article.supplier.id &&
                                      part.articleInformation.articleNumber === article.supplierArticleNo &&
                                      part.articleInformation.productGroupId === article.productGroup.id
                              )

                              // if returned directly typecheck won't work
                              const mappedPart: VoucherPart = match
                                  ? {
                                        ...part,
                                        articleInformation: {
                                            ...part.articleInformation,
                                            thumbnailUrl: match.thumbnail,
                                            productGroupName: match.productGroup.name,
                                            description: match.description,
                                        },
                                    }
                                  : part
                              return mappedPart
                          }),
                      }
                    : undefined,
            }
        }
        return data
    }, [data, JSON.stringify(articles)])

    return { costEstimationDetails, costEstimationDetailsLoading, selectedIds, toggleSelected, selectAll, unselectAll }
}
