import { useUser } from "@tm/context-distribution"
import { Button, Checkbox, Table, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { UserModuleType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Typography, styled } from "@tm/components"
import { useState } from "react"
import { Work } from "../../../data/models"
import { MainActions, worksSelector } from "../../main/business"
import { IncludedWorks } from "."

export default function WorkList() {
    const [isExpanded, setIsExpanded] = useState<Record<string, boolean>>({})
    const { translateText, currency, number } = useLocalization()

    const user = useUser()
    const currencyString = user?.userContext?.modules?.find((x) => x.type === UserModuleType.Catalog)?.currencyCode ?? "EUR"
    const repairTimeDivision = user?.userSettings?.repairTimeOptions?.division

    const actions = useActions(MainActions, "selectWork")
    const works = useSelector(worksSelector)

    const handleCollapse = (work: Work) =>
        setIsExpanded((state) => ({ ...state, [work.providerWorkId + work.description]: !state[work.providerWorkId + work.description] }))

    const renderIncludes = (work: Work) => (
        <Table.Cell>
            <Button
                icon={isExpanded[work.providerWorkId + work.description] ? "up" : "down"}
                size="s"
                disabled={!work.includedWorks?.length}
                layout={["ghost"]}
                onClick={() => handleCollapse(work)}
            />
        </Table.Cell>
    )

    const renderProviderId = (work: Work) => (
        <Table.Cell>
            {work.providerWorkId.split(/(?=[+])/).map((providerWorkId: any, index: any) => (
                <Text key={index}>{providerWorkId}</Text>
            ))}
        </Table.Cell>
    )

    const renderDescription = (work: Work) => (
        <Table.Cell>
            <Typography>{work.description} </Typography>
        </Table.Cell>
    )

    const renderHourlyRate = (work: Work) => (
        <Table.Cell>
            <Typography>{currency(work.hourlyRate, currencyString)} </Typography>
        </Table.Cell>
    )

    const renderRtUnitValue = (work: Work) => (
        <Table.Cell>
            <Typography>{repairTimeDivision && number(work.hourlyRate / repairTimeDivision, 2)}</Typography>
        </Table.Cell>
    )

    const renderRtValue = (work: Work) => (
        <Table.Cell>
            <Typography>{number(work.time, 2)}</Typography>
        </Table.Cell>
    )

    const renderTotal = (work: Work) => (
        <Table.Cell>
            <Typography>{currency(work.total, currencyString)}</Typography>
        </Table.Cell>
    )

    const renderCheckbox = (work: Work) => (
        <Table.Cell>
            <Checkbox checked={work.isSelected} onToggle={() => actions.selectWork(work)} />
        </Table.Cell>
    )

    const renderIncludedWorks = (work: Work) => (
        <Table.Cell>
            {work.includedWorks && (
                <IncludedWorks
                    includedWorks={work.includedWorks}
                    showSupplierArticleNumbers // TODO: this.props.showSupplierArticleNumbers
                />
            )}
        </Table.Cell>
    )

    const rtValueTitle = repairTimeDivision === 1 ? translateText(84) : translateText(621)

    const columns = [
        <Table.Column key="includes" className="includes" renderItemContent={renderIncludes} />,
        <Table.Column key="number" className="number" renderItemContent={renderProviderId}>
            {translateText(58)}
        </Table.Column>,
        <Table.Column key="description" className="description" renderItemContent={renderDescription}>
            {translateText(617)}
        </Table.Column>,
        <Table.Column key="hourly-rate" className="hourly-rate price" renderItemContent={renderHourlyRate}>
            {translateText(622)}
        </Table.Column>,
        repairTimeDivision !== 1 && (
            <Table.Column className="rt-value time" renderItemContent={renderRtUnitValue}>
                {translateText(1723)}
            </Table.Column>
        ),
        <Table.Column key="rt-value" className="rt-value time" renderItemContent={renderRtValue}>
            {rtValueTitle}
        </Table.Column>,
        // <Table.Column className="price time" renderItemContent={this.renderCalculatedTime}>{rtCalculatedTimeTitle}</Table.Column>,
        <Table.Column key="price" className="price sum" renderItemContent={renderTotal}>
            {translateText(57)}
        </Table.Column>,
        <Table.Column key="actions" className="actions" renderItemContent={renderCheckbox} />,
        <Table.Column key="expandableRow" className="expandableRow" renderItemContent={renderIncludedWorks} />,
    ]

    return (
        <StyledTable
            data={works}
            columns={columns.filter(Boolean) as any}
            getRowClassName={(work: Work) => (isExpanded[work.providerWorkId + work.description] ? "is-expanded" : "")}
        />
    )
}

const StyledTable = styled(Table)({
    ".is-expanded": {
        flexWrap: "wrap",
        ".expandableRow": {
            display: "block",
            ".number, .description": {
                fontWeight: "500",
            },
        },
    },

    ".expandableRow": {
        flex: "100%",
        display: "none",
    },

    ".includes": {
        flex: 0,
        flexBasis: "1.6rem",
    },

    ".provider, .category": {
        flex: 0,
        flexBasis: "5.2rem",
        justifyContent: "flex-start",
    },

    ".price": {
        flex: 0,
        flexBasis: "5.7rem",
        textAlign: "right",
        $nest: {
            "&.sum": {
                display: "flex",
                flex: 0,
                flexBasis: "7.5rem",
                paddingLeft: "1.5em",
                justifyContent: "flex-end",
            },
        },
    },

    ".time": {
        flex: 0,
        flexBasis: "5.3rem",
        textAlign: "right",
        whiteSpace: "normal",
    },

    ".actions": {
        flex: 0,
        flexBasis: "1.5rem",
    },

    ".type, .note": {
        flex: 0,
        flexBasis: "1.2rem",
    },

    ".number": {
        fontWeight: "500",
        flex: 0,
        flexBasis: "9rem",
        display: "flex",
        flexDirection: "column",
    },

    ".description": {
        flex: 1,
    },

    ".fancy-list__head": {
        fontWeight: "500",
    },
})
