import { ArticleAttribute } from "@tm/models"
import { KeyValueItemValue, KeyValueItemProps } from "../model"

function mapAttributeToKeyValueItemValue(attribute: ArticleAttribute, originalText: string, optimizedText: string): KeyValueItemValue {
    let tooltips: Array<string> | undefined
    if (attribute.alternatives && attribute.alternatives.length) {
        tooltips = [`${attribute.isOptimized ? originalText : optimizedText}: `]

        attribute.alternatives.forEach((tooltip) => {
            const value = `${tooltip.value} ${tooltip.unit || ""}`.trim()
            tooltips!.push(`${tooltip.abbreviation}: ${value}`)
        })
    }

    return {
        text: `${attribute.value} ${attribute.unit || ""}`.trim(),
        modificationState: attribute.modificationState,
        highlight: attribute.highlight,
        asTag: true,
        tooltips,
        attributeAction: attribute.action,
    }
}

function mapAttributeToKeyValueItem(
    attribute: ArticleAttribute,
    bold: boolean,
    originalText: string,
    optimizedText: string,
    hoveredAttribute?: string
): KeyValueItemProps {
    return {
        description: attribute.description,
        value: [mapAttributeToKeyValueItemValue(attribute, originalText, optimizedText)],
        bold,
        highlight: hoveredAttribute == attribute.description,
    }
}

export function mapAttributesToKeyValueItem(
    attributes: Array<ArticleAttribute>,
    bold: boolean,
    originalText: string,
    optimizedText: string,
    hoveredAttribute?: string
): Array<KeyValueItemProps> {
    const mappedAttributes: Array<KeyValueItemProps> = []

    if (attributes) {
        let keyValue: KeyValueItemProps | undefined

        attributes.forEach((attribute) => {
            if (!keyValue) {
                keyValue = mapAttributeToKeyValueItem(attribute, bold, originalText, optimizedText, hoveredAttribute)
            } else if (keyValue) {
                if (attribute.isDuplicatedAttribute && Array.isArray(keyValue.value)) {
                    keyValue.value.push(mapAttributeToKeyValueItemValue(attribute, originalText, optimizedText))
                } else {
                    mappedAttributes.push(keyValue)
                    keyValue = mapAttributeToKeyValueItem(attribute, bold, originalText, optimizedText, hoveredAttribute)
                }
            }
        })

        if (keyValue) {
            mappedAttributes.push(keyValue)
        }
    }

    return mappedAttributes
}
