import { useState, useEffect, useMemo } from "react"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { channel, OrderWarehouseInfo } from "@tm/models"
import { useDefaultOrderWarehouse } from "@tm/utils"

type WarehouseData = {
    warehouse: OrderWarehouseInfo | undefined
    hasErrors: boolean
    errorText?: string
    refetchWarehouseData(): Promise<void>
}

export function useWarehouse(articleId?: string, distributorId?: number): WarehouseData {
    const { telesalesCustomerNo, enableServiceCalls, hasTelesales } = useTelesalesCustomerNumber()

    // Hotfix for this https://jira.dvse.de/browse/NEXT-26920
    // maybe the problem can be solved in another way, but at the moment it was the best way without breaking anything
    const enableCalls = enableServiceCalls || hasTelesales

    const { warehouseData, refetchWarehouseData } = useDefaultOrderWarehouse({ telesalesCustomerNo, distributorId }, enableCalls)

    const [warehouse, setWarehouse] = useState<OrderWarehouseInfo>()

    useEffect(() => {
        return articleId
            ? channel("WORKTASK").subscribe("ARTICLE/ARTICLE_WAREHOUSE_CHANGED", ({ articleId: channelArticleId, warehouse }) => {
                  if (articleId === channelArticleId) {
                      setWarehouse(warehouse)
                  }
              })
            : undefined
    }, [articleId])

    return useMemo(() => {
        if (warehouse) {
            return {
                warehouse,
                hasErrors: false,
                refetchWarehouseData: Promise.resolve,
            }
        }
        return {
            warehouse: warehouseData?.defaultWarehouse,
            hasErrors: !warehouseData ? true : warehouseData.hasErrors ?? false,
            errorText: warehouseData?.errorText,
            refetchWarehouseData,
        }
    }, [refetchWarehouseData, warehouseData, warehouse])
}
