import { useMutation } from "react-query"
import { Repositories } from "../data"
import { mainActions } from "../data/state"
import { DrivemotiveLoginRequest } from "../data/models"

type Props = {
    onLoginSuccess: () => void
}

export function useGetLoginSuccess({ onLoginSuccess }: Props) {
    return useMutation({
        mutationFn: (request: DrivemotiveLoginRequest) => Repositories.getDrivemotiveLoginResponse(request),
        onSuccess() {
            onLoginSuccess()
        },
        onError() {
            mainActions.setShowLoginDialog(true)
        },
    })
}
