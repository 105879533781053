import { ETyresCarType, TyresSeason } from "@tm/models"
import { MainState } from "../../../main"
import { TyresCritsRequest } from "../../../../data/repositories"
import { Statics } from "../../../../data/statics"

export function createTiresCritsRequest(state: MainState, forAttrFilters?: boolean): TyresCritsRequest {
    const { summary: { selectedFilters } } = state

    const crits: TyresCritsRequest = {
        carTypes: [],
        width: selectedFilters.width?.value ?? "",
        height: selectedFilters.height?.value ??  "",
        inch: selectedFilters.inch?.value ?? "",
        ...(!forAttrFilters && {
            ...(selectedFilters.loadIndex && {
                loadIndex: selectedFilters.loadIndex.value
            }),
            ...(selectedFilters.speedIndex && {
                speedIndex: selectedFilters.speedIndex.value
            }),
            ...(selectedFilters.oeIdentifier && {
                oeIdentifier: selectedFilters.oeIdentifier.value
            })
        })
    }

    if (forAttrFilters || !selectedFilters.carType?.length) {
        crits.carTypes = Statics.getCarTypes().map(x => +x.query as ETyresCarType)
    } else {
        crits.carTypes = selectedFilters.carType.map(x => +x.query as ETyresCarType)
        crits.season = selectedFilters.season?.value as unknown as TyresSeason
    }

    return crits
}
