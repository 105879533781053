import { useState } from "react"
import { Box, Table, TableCellData, RadioButton, TableColumnData } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ShipmentMode } from "@tm/models"
import { DetailOrderOptionState } from "@tm/utils"

type Props = {
    shipments?: ShipmentMode[]
    handleShippmentsSelect: (orderOption: ShipmentMode | undefined) => void
    selectedOrderOption: DetailOrderOptionState
}

export default function ShipmentSelectionTable(props: Props) {
    const { translateText, currency } = useLocalization()

    const columns: TableColumnData[] = [{ header: translateText(438) }, { header: translateText(3138) }, {}]

    const [selectedShipmentId, setSelectedShippmentId] = useState<string>("")

    const shipmentscellData = (shipmentModes: ShipmentMode): TableCellData[] => {
        return [
            { displayValue: shipmentModes.description, id: "Shippment_1" },
            {
                displayValue: shipmentModes.costs?.currencySymbol
                    ? currency(shipmentModes?.costs?.value || 0, shipmentModes.costs?.currencySymbol)
                    : "-",
                id: "Shippment_2",
            },
            {
                displayValue: (
                    <Box display="flex" justifyContent="end">
                        <RadioButton
                            onChange={() => props.handleShippmentsSelect(shipmentModes)}
                            checked={props.selectedOrderOption?.orderOption?.id === shipmentModes.id}
                        />
                    </Box>
                ),
                id: "Shippment_3",
            },
        ]
    }

    const shipmentDate = props.shipments
        ? props.shipments.map((v: ShipmentMode) => {
              return {
                  cells: shipmentscellData(v),
                  id: `${v.id}`,
                  active: selectedShipmentId === v.id,
              }
          })
        : []

    return <Table variant="small" rowCap={2} columns={columns} rows={shipmentDate} />
}
