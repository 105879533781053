import { styled, Typography, Table, Box, Divider, Stack, TextField } from "@tm/components"

export const LightableText = styled(Typography, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode?: boolean }>(({ lightMode }) => ({
    color: lightMode ? "#FFF" : undefined,
}))

export const TypographyTitle = styled(LightableText)({
    marginBottom: "1rem",
})
TypographyTitle.defaultProps = { variant: "body3", color: "primary" }

export const TextBody1Bold = styled(LightableText)({
    fontWeight: "bold",
})
TextBody1Bold.defaultProps = { variant: "body1" }

export const StyledTable = styled(Table)({
    overflowY: "auto",
})

export const BoxRowFullWithSpaceBetween = styled(Box)({
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
})

export const BoxRowContentEnd = styled(Box)({
    display: "flex",
    flexDirection: "row",
    alignContent: "end",
})

export const VerticalDivider = styled(Divider)({})
VerticalDivider.defaultProps = { orientation: "vertical", light: false }

export const RowStack = styled(Stack)({})
RowStack.defaultProps = { direction: "row", spacing: 1 }

export const ColumnStack = styled(Stack)({
    justifyContent: "space-between",
    height: "100%",
})
ColumnStack.defaultProps = { direction: "column" }

export const RowStackWithFlex = styled(RowStack)({
    flex: 1,
})

export const RowStackWithPadding = styled(RowStack)({
    flex: 1,
    padding: "0 0.5rem",
})

export const OrderDetailsBox = styled(Box, { shouldForwardProp: (prop) => prop !== "height" })<{ height?: string }>(({ height, theme }) => ({
    border: `1px solid ${theme.overwrites?.components?.textfield?.border?.color || "#e2e2e1"}`,
    padding: "2px 5px",
    borderRadius: theme.radius?.default ?? "3px",
    backgroundColor: theme.colors?.paperBackground ?? "#FAFAFA",
    height: height ?? "41px",
}))

export const OrderDetailsLabel = styled(Typography)({
    fontSize: "10px",
    textTransform: "uppercase",
    opacity: 0.54,
})
