import { useState, useEffect } from "react"
import { OrderedPartByVehicle } from "../../../data/model/vouchers"
import { showOrderedPartsByVehicle } from "../../../data/repositories/vouchers"

export function useOrderedPartsByVehicle(vehicleId?: string) {
    const [orderedParts, setOrderedParts] = useState<Array<OrderedPartByVehicle>>()
    useEffect(() => {
        if (orderedParts) {
            setOrderedParts(undefined)
        }

        if (!vehicleId) {
            return
        }

        let cancelled = false
        showOrderedPartsByVehicle(vehicleId).then((response) => {
            if (cancelled) {
                return
            }

            if (response && response.orderedParts && response.orderedParts.length) {
                setOrderedParts(response.orderedParts)
            }
        })

        return () => {
            cancelled = true
        }
    }, [])

    return orderedParts
}
