import { ConfigParams, OE, OeArticleItemState, channel } from "@tm/models"
import { PropsWithChildren, useCallback, useMemo, useState } from "react"
import { ArticleItemStateProvider } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { useLocalization } from "@tm/localization"
import { useOeBasketQuantities } from "../../hooks/useBasketQuantities"
import { useHandleAddOeToBasket } from "../../hooks/useArticleItem/useHandleAddOeToBasket"
import { useHandleRemoveFromBasket } from "../../hooks/useArticleItem/useHandleRemoveFromBasket"
import { isSameOeArticle, mapOeArticleDtoFromOePart } from "../../helpers"
import { useOePartErpInfos } from "../../hooks/useArticleItem/useOeArticleErpInfos"
import AvailabilityComponent from "../../../../../../erp/src/_shared/availability-wrapper"
import { useOeArticleOptions } from "../../hooks/useArticleItem/useArticleOptions"
import { useOePartsModuleState } from "../../OeParts/OePartsModuleState"
import { usePartsModuleState } from "../../PartsModuleState"
import { useLoadOePartErpInfos } from "../../hooks/useErpInfos/useLoadOePartErpInfos"
import { mapArticleAttributes } from "../../hooks/useArticleItem/mapper"

type OeArticleItemStateProviderProps = PropsWithChildren<{
    article: OE.OePart
}>

export function OeArticleItemStateProvider({ article, children }: OeArticleItemStateProviderProps) {
    const { translateText } = useLocalization()
    const [quantity, setQuantity] = useState<number>(article?.quantity || 1)
    const articleList = useMemo(() => [article], [article])
    const options = useOeArticleOptions(article)
    const replaceButtonBundle = useOePartsModuleState((x) => x.params.startParams.replaceButtonBundle)
    const erpInfosData = usePartsModuleState((x) => x.erpInfosData)
    const loadOePartErpInfos = useLoadOePartErpInfos(erpInfosData)

    const handleReplaceArticle = useCallback(() => {
        if (!replaceButtonBundle) {
            return
        }
        channel("GLOBAL").publish("PARTS/REPLACE_PART", { part: article, isNewList: true, bundle: replaceButtonBundle })
    }, [article, replaceButtonBundle])

    const { basketQuantities, updateBasketQuantities } = useOeBasketQuantities(articleList, true)

    const handleAddToBasketInternal = useHandleAddOeToBasket(article)
    const handleRemoveFromBasketInternal = useHandleRemoveFromBasket()

    const basketQuantity = useMemo(() => {
        const oeArticleDto = mapOeArticleDtoFromOePart(article)
        return basketQuantities.find((q) => isSameOeArticle(q.article, oeArticleDto))
    }, [basketQuantities, article])

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setQuantity(value)
            if (loadErpInfo) {
                loadOePartErpInfos([{ oePart: article, quantity: value }])
            }
        },
        [article, loadOePartErpInfos]
    )
    const handleAddToBasket = useCallback(
        async (internalQuantity?: number) => {
            await handleAddToBasketInternal(internalQuantity ?? quantity)
            updateBasketQuantities([article])
        },
        [handleAddToBasketInternal, quantity, article, updateBasketQuantities]
    )

    const handleRemoveFromBasket = useCallback(async () => {
        if (basketQuantity?.articleQuantities?.allPartItemIds) {
            await handleRemoveFromBasketInternal(basketQuantity?.articleQuantities?.allPartItemIds)
            updateBasketQuantities([article])
        }
    }, [basketQuantity, handleRemoveFromBasketInternal, updateBasketQuantities, article])

    const articleErpInfos = useOePartErpInfos(article, quantity || 1, erpInfosData)
    const { enableAddingButtonsAfterErp } = Morpheus.getParams<ConfigParams>()
    const isLoadingErpInfos = articleErpInfos?.default?.state === "loading" || articleErpInfos?.alternative?.state === "loading"
    const addButtonsDisabled = !!enableAddingButtonsAfterErp && isLoadingErpInfos
    const attributes = useMemo(() => mapArticleAttributes(translateText, article, true), [article])

    const state: OeArticleItemState = {
        type: "OE",
        article,
        attributes,
        isVehicleDependent: false,
        options,
        quantity,
        originalQuantity: quantity,
        basketQuantity,
        oeArticleNo: article.number,
        wholesalerArticleNumber: article.traderNumber ?? "",
        thumbnailUrl: "/styles/base/images/oe-part-logo.svg",
        manufacturerThumbnail: article.thumbnailUrl,
        showReplaceButton: !!replaceButtonBundle,
        articleErpInfos,
        addToCostEstimationButtonDisabled: addButtonsDisabled,
        addToBasketButtonDisabled: addButtonsDisabled,
        handleChangeQuantity,
        handleAddToBasket,
        handleRemoveFromBasket,
        AvailabilityComponent,
        handleReplaceArticle,
    }

    return <ArticleItemStateProvider value={state}>{children}</ArticleItemStateProvider>
}
