import { GetMainServicesRequest, GetMainServicesResponse, ProductGroup, RepairTimeProvider, VehicleType } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"

import { getBundleParams } from "../../utils"

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.repairTimesServiceUrl
}

export function getMainServices(request: GetMainServicesRequest) {
    return ajax<GetMainServicesResponse>({
        url: `${getServiceUrl()}/MainServices`,
        body: request,
        authorization: getStoredAuthorization(),
    })
}

export type GetProductGroupsRequest = {
    repairTimeProvider: RepairTimeProvider
    serviceId: string
    modelId: number
    vehicleType: VehicleType
}

export function getProductGroups(request: GetProductGroupsRequest): Promise<Array<ProductGroup>> {
    return ajax<{ productGroups: Array<ProductGroup> }>({
        url: `${getServiceUrl()}/ProductGroups`,
        body: request,
        authorization: getStoredAuthorization(),
    }).then((response) => response?.productGroups ?? [])
}
