import { em, rem } from "csx"
import { useMemo, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Dialog, Image, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { SizeInfo } from "../../../data/model"
import { MainState } from "../../main"
import { Actions } from "../business"
import ArticleAtributes from "./articleAtributes"

export default function RimDetailsHeader() {
    const dispatch = useDispatch()
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])
    const dialogRef = useRef<Dialog>(null)

    const { rimItem, selectedSize, displayImage } = useSelector((s: MainState) => ({
        rimItem: s.wheelsDetails.rimItem,
        selectedSize: s.wheelsDetails.selectedSize,
        displayImage: s.wheelsDetails.displayImage
    }))

    const handleImageDisplay = () => {
        dispatch(Actions.changeImageDisplay())
    }

    const renderRimSize = (item: SizeInfo, idx: number) => {

        const handleRimSizeSelect = () => {
            dispatch(Actions.selectRimDetailsSize(item.size))
        }

        return (
            <Button
                size="l"
                key={idx}
                isActive={selectedSize == item.size}
                layout={["holo", "bordered"]}
                skin={selectedSize == item.size && "primary" || undefined}
                onClick={handleRimSizeSelect}>
                {item.size}
            </Button>
        )
    }

    return (
        <div className={style.wrapper}>
            <Dialog className="wheels-image-zoom" ref={dialogRef}>
                <Image url={rimItem?.perspectivePicture!} />
            </Dialog>
            {displayImage && <Image url={rimItem?.perspectivePicture!} className={style.mainImage} />}
            <div className={displayImage && style.buttonsWrapper || "row-buttons"}>
                {rimItem?.sizeInfo.map(renderRimSize)}
            </div>

            <div className={style.actions}>
                <Button className={style.changeDisplayBtn} onClick={handleImageDisplay} >
                    <Image className={style.changeDisplayBtnImg} url={rimItem?.perspectivePicture!} />
                    <Text >{displayImage ? translateText(973) : translateText(972)}</Text>
                </Button>
                <Button icon="no-results" onClick={() => dialogRef.current?.show()} />
            </div>
            {rimItem && <ArticleAtributes rimItem={rimItem} />}
        </div>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            display: "flex",
            position: "relative",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: rem(2),
            marginBottom: theme.margin.m,
            borderBottom: theme.colors.secondary,
            $nest: {
                ".error-image": {
                    height: "unset"
                }
            }
        },
        mainImage: {
            width: em(15),
            height: em(15)
        },
        actions: {
            position: "absolute",
            top: 0,
            left: 0,
            display: "flex"
        },
        changeDisplayBtn: {
            $nest: {
                ".btn__content": {
                    display: "flex",
                    alignItems: "center"
                }
            }
        },
        changeDisplayBtnImg: {
            height: rem(1.2),
            width: rem(1.2),
            display: "block",
            marginRight: theme.margin.m
        },
        buttonsWrapper: {
            display: "flex",
            flexDirection: "column",
            $nest: {
                ".btn": {
                    marginBottom: ".2em",
                    position: "relative",
                    $nest: {
                        "&:first-child": {
                            marginLeft: ".2em"
                        }
                    }
                }
            }
        }
    })(RimDetailsHeader)
}
