import { FastCalculator } from "@tm/data"
import { ModalCalcState, SummaryContext } from "../model"

export function getSelectedServicesFromWS(summaryContext: SummaryContext): FastCalculator.CalcSelectionItem[] {
    let selectedServices: FastCalculator.CalcSelectionItem[] = []
    const servicesRegisteredInWS = summaryContext?.categories?.selections

    if (servicesRegisteredInWS) {
        // eslint-disable-next-line no-restricted-syntax
        for (const category of servicesRegisteredInWS) {
            const foundServices = category.items.filter((s) => s.isSelected)
            if (foundServices) {
                selectedServices = selectedServices.concat(foundServices)
            }
        }
    }

    return selectedServices
}

export function findServiceStatusOnServer(
    calcState: FastCalculator.CalcState | undefined,
    isOverlayCalcState: boolean | undefined,
    newItemId: string
) {
    if (calcState == undefined) {
        return false
    }

    if (isOverlayCalcState) {
        const overLayCalcState = calcState as ModalCalcState

        if (overLayCalcState?.type == FastCalculator.ECalcState.AdditionalWork) {
            return checkWorkExistsOnServer(overLayCalcState?.context?.additionalWork, newItemId)
        }

        if (overLayCalcState?.type == FastCalculator.ECalcState.FollowupWork) {
            return checkWorkExistsOnServer(overLayCalcState?.context?.followupWork, newItemId)
        }
    }

    const categoryState = calcState.context as SummaryContext
    const categorySelections = categoryState?.categories?.selections

    return checkServiceExistsOnServer(categorySelections, newItemId)
}

function checkServiceExistsOnServer(servicesRegisteredInWS: FastCalculator.CalcSelection[] | undefined, newItemId: string) {
    if (servicesRegisteredInWS === undefined) {
        return false
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const category of servicesRegisteredInWS) {
        if (category.items.find((s) => s.isSelected && s.id === newItemId)) {
            return true
        }
    }

    return false
}

function checkWorkExistsOnServer(
    items: Array<FastCalculator.CalcInput & { items: FastCalculator.CalcSelectionItem[] }> | undefined,
    newItemId: string
) {
    if (items === undefined) {
        return false
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const works of items) {
        if (works.items.find((work) => work.isSelected && work.id === newItemId)) {
            return true
        }
    }

    return false
}
