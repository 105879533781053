import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { channel, TelematicsProvider } from "@tm/models"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"

import { mapShowPartsListByProductGroupsToRequestArticleList } from "../../data/mapper"
import { useTelematicsData } from "./business/useTelematicsData"
import AnalysisComponent from "./components/analysis/component"
import ChosenVehicleComponent from "./components/chosen-vehicle/component"
import GpsComponent from "./components/gps/component"
import InspectionComponent from "./components/inspection/component"
import MaintenanceComponent from "./components/maintenance/component"
import MiscComponent from "./components/misc/component"
import ProvidersComponent from "./components/providers/component"
import UpdateViewBrandComponent from "./components/update-view-brand/component"
import VehicleStatusComponent from "./components/vehicle-status/component"

type Props = RouteComponentProps & {
    moduleName?: string
    hideDrivingStyleAnalysis?: boolean
    hideGpsInfo?: boolean
    fastCalculatorModalRoute?: string
    fastCalculatorRoute?: string
    providerLogos?: Array<string>
}

const provider = TelematicsProvider.BmwCarData
function DetailsComponent(props: Props) {
    const { translateText, isTranslationId } = useLocalization()
    const { workTask } = useWorkTask() || {}
    const { telematicsData, telematicsDataLoading, updateData, updatingData } = useTelematicsData(provider, workTask?.vehicle)

    let telematicsDisplayName = props.moduleName || "Telematics"
    if (isTranslationId(telematicsDisplayName)) {
        telematicsDisplayName = translateText(telematicsDisplayName)
    }

    const getBatteryCalculationRoute = () => {
        if (props.fastCalculatorModalRoute) {
            return decodeURIComponent(renderRoute(props.fastCalculatorModalRoute, { ...props.match.params, productGroupId: 1 }))
        }
    }

    const getFastCalculationRoute = () => {
        if (props.fastCalculatorRoute) {
            return renderRoute(props.fastCalculatorRoute, props.match.params)
        }
    }

    const handleShowPartsByProductGroups = (productGroupIds: Array<number>) => {
        if (productGroupIds?.length) {
            const request = mapShowPartsListByProductGroupsToRequestArticleList(productGroupIds)
            channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
        }
    }

    // FastCalculator is only available if enabled in the config and the vehicle has data
    const batteryCalculationUrl = getBatteryCalculationRoute()
    const fastCalculationUrl = getFastCalculationRoute()
    const canRequestCalculation = !!workTask?.vehicle?.dataAvailabilities?.fastCalculator.hasAny

    return (
        <div className="tk-telematics">
            <div className="details">
                <div className="row">
                    <div className="col">
                        <div className="col__left">
                            <InspectionComponent
                                data={telematicsData}
                                serviceCalculationUrl={fastCalculationUrl}
                                canRequestCalculation={canRequestCalculation}
                            />
                            <MiscComponent
                                data={telematicsData}
                                batteryCalculationUrl={batteryCalculationUrl}
                                canRequestCalculation={canRequestCalculation}
                            />
                            {props.hideDrivingStyleAnalysis !== true && <AnalysisComponent />}
                        </div>
                        {props.hideGpsInfo !== true && (
                            <div className="col__center">
                                <GpsComponent data={telematicsData} />
                            </div>
                        )}
                        <div className="col__right">
                            <ChosenVehicleComponent vehicle={workTask?.vehicle} />
                            <UpdateViewBrandComponent
                                data={telematicsData}
                                dataLoading={telematicsDataLoading || updatingData}
                                updateData={updateData}
                                displayName={telematicsDisplayName}
                            />
                        </div>
                        <ProvidersComponent providerLogos={props.providerLogos} />
                    </div>
                </div>
                <div className="row">
                    <MaintenanceComponent data={telematicsData} showPartsByProductGroups={handleShowPartsByProductGroups} />
                </div>
                <div className="row">
                    <VehicleStatusComponent data={telematicsData} />
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetailsComponent)
