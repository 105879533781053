import { useState } from "react"
import { Article, ArticleAttributes as ArticleAttributesModel, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { Stack, Typography, Checkbox, Button, Icon, Table, TableCellData, CellContentPosition, TableColumnData, Box, Image } from "@tm/components"
import { useLocalization } from "@tm/localization"
import ArticleAttributes from "./select-article-attributes"
import ArticleNumbers from "../../_shared/ArticleNumbers"
import { useOpenBasketArticleDetails } from "../../../hooks/useOpenBasketArticleDetails"

type Props = {
    articles: Array<Article>
    selectedArticles: Array<Article>
    selectArticle: (article: Article, remove: boolean) => void
    onOpenArticlePartsList(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number): void
}

export default function ArticleListItemView({ articles, selectedArticles, selectArticle, onOpenArticlePartsList }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()
    const [showAllAttributes, setShowAllAttributes] = useState<boolean>(true)

    const handleOpenArticleDetails = useOpenBasketArticleDetails()

    function handleAttributeMoreToggle() {
        setShowAllAttributes(!showAllAttributes)
    }

    function handleDetailsButtonPartsList(article: Article) {
        const { productGroup, supplierArticleNo, supplier } = article
        onOpenArticlePartsList(productGroup.id, supplierArticleNo, supplier.id)
    }

    function filterAttributes(attr: ArticleAttributesModel): ArticleAttributesModel {
        return {
            topAttributes: attr.topAttributes,
            articleAttributes: [],
            partsListAttributes: [],
            vehicleAttributes: [],
        }
    }

    function renderDescription(article: Article) {
        const description = article.description ? article.description : ""
        const additionalDescription = article.additionalDescription ? article.additionalDescription : null
        return (
            <div className="description">
                <Typography variant="body3">{article.productGroup && article.productGroup.name ? article.productGroup.name : null}</Typography>
                <Typography variant="body3">{description + (additionalDescription ? ` ${additionalDescription}` : "")}</Typography>
            </div>
        )
    }

    function renderAttributes(article: Article) {
        let { attributes } = article
        if (!showAllAttributes) {
            attributes = attributes.map(filterAttributes)
        }

        return <ArticleAttributes attributes={attributes} isCompact={!showAllAttributes} toggleMoreAttributes={handleAttributeMoreToggle} />
    }

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: "" },
            { header: "" },
            { header: "" },
            { header: "" },
            { header: "" },
            { header: "", alignContent: CellContentPosition.right },
        ]
        return columns
    }

    function getModuleCellData(article: Article): TableCellData[] {
        const { thumbnail, productGroup, attributes, supplier, supplierArticleNo, traderArticleNo, showAddToBasket, isPartsListAvailable } = article
        const selected = selectedArticles.some((art) => art.id === article.id)
        const cellData: TableCellData[] = [
            {
                displayValue: (
                    <Box display="flex" alignContent="center">
                        <Button
                            variant="text"
                            startIcon={<Icon name={showAllAttributes ? "up" : "down"} />}
                            onClick={handleAttributeMoreToggle}
                            disabled={!attributes.length}
                        />
                        <Box height="4.5em" width="4.5em" lineHeight="4.5em">
                            <Image
                                width="100%"
                                height="100%"
                                src={thumbnail ?? ""}
                                type="article"
                                style={{ objectFit: "contain", objectPosition: "center" }}
                            />
                        </Box>
                    </Box>
                ),

                id: "part_1",
            },
            { displayValue: <Typography>{supplier?.name}</Typography>, id: "part_2" },
            {
                displayValue: (
                    <ArticleNumbers
                        productGroupId={productGroup.id}
                        supplierArticleNumber={supplierArticleNo}
                        supplierId={supplier?.id}
                        wholesalerArticleNumber={traderArticleNo}
                    />
                ),
                id: "part_3",
            },
            { displayValue: renderDescription(article), id: "part_4" },
            {
                displayValue: renderMicro("erp", "erp-info", {
                    data: article,
                    onClick: () => handleOpenArticleDetails(productGroup?.id, supplierArticleNo, supplier?.id),
                }),
                id: "part_5",
            },
            {
                displayValue: (
                    <Stack direction="row">
                        {isPartsListAvailable && (
                            <Button
                                startIcon={<Icon name="partslist" />}
                                variant="bordered"
                                color="highlight"
                                onClick={() => handleDetailsButtonPartsList(article)}
                                title={translateText(1525)}
                            />
                        )}
                        <Checkbox checked={selected || false} onChange={() => selectArticle(article, selected)} disabled={!showAddToBasket} />
                    </Stack>
                ),
                id: "part_6",
            },
        ]
        return cellData
    }

    const displayData = articles?.map((article, index) => ({
        cells: getModuleCellData(article),
        id: `${index}`,
        customRow: false,
        active: false,
        extendedContent: renderAttributes(article),
    }))

    return <Table columns={getColumns()} rows={displayData} overflowY="unset" />
}
