import { useUser } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModuleGroupId } from "@tm/models"
import { useActivateModule, useGetActivatableModules } from "@tm/utils"
import { Box, Typography } from "@tm/components"
import { useParams } from "react-router"
import Modules, { RouteProps } from "./components/Modules"

type Props = {
    id: ModuleGroupId
    textId: number
    routes: Record<string, string>
    demoActivationInstanstly?: boolean // In cases like WM where an external system is responsible for the activation. A refresh of the page is not enough.
}
export default function ModuleGroup({ id: moduleGroupId, textId, routes, demoActivationInstanstly }: Props) {
    const params = useParams<RouteProps>()
    const { translate } = useLocalization()
    const { userContext } = useUser() ?? {}
    const activatableModulesLoadable = useGetActivatableModules(userContext)
    const { activatingModule } = useActivateModule()

    return (
        <Box padding="1em" width="100%" height="100%" display="flex" flexDirection="column">
            <Box display="flex" flexDirection="row" flex="0">
                <Typography variant="h1" marginRight="0.5em">
                    {translate(textId)}
                </Typography>
                {activatingModule && <Loader />}
            </Box>
            <Box display="flex" flex="1">
                <Modules params={params} moduleGroupId={moduleGroupId} demoActivationInstanstly={demoActivationInstanstly} routes={routes} />
            </Box>
            {activatableModulesLoadable.response && (!params.feedbackUrl || !params.module) && (
                <Box flex="0" margin="1em">
                    <Typography variant="body3">{`*${translate(12873)}`}</Typography>
                </Box>
            )}
        </Box>
    )
}
