import { AsyncAction } from "@tm/morpheus"
import { LoadingScreenState } from "./model"
import { BundleActionTypes, BundleActions } from "../../../business"
import { Repositories } from "../../../data"
import { createSensorArticlesRequest, createSensorArticleFiltersRequest } from "./helper"
import { MainState } from "../../main"
import { Vehicle } from "@tm/models"
import { WheelSelectionSteps } from "../../../data/enums"
import { getBundleParams } from "../../../utils"

export type ComponentActionType = BundleActionTypes
    | { type: "CAR_PARK_ERROR" }
    | { type: "CAR_PARK_LOADING" }
    | { type: "MODEL_DETAILS_LOADING" }
    | { type: "MODEL_DETAILS_ERROR" }
    | { type: "SET_SHOW_VEHICLE_SELECTION" }

export const LOADING_SCREEN_DEFAULT_STATE: LoadingScreenState = {
    carParkHeaders: [],
    carParkItems: [],
    showVehicleSelection: false
}

export function reduce(state = { ...LOADING_SCREEN_DEFAULT_STATE }, action: ComponentActionType): LoadingScreenState {
    switch (action.type) {
        case "CAR_PARK_LOADING": {
            return {
                ...state,
                loading: true,
                error: false
            }
        }
        case "MODEL_DETAILS_LOADING": {
            return {
                ...state,
                loading: true,
                error: false
            }
        }
        case "CAR_PARK_ERROR": {
            return {
                ...state,
                error: true,
                loading: false,
                carParkHeaders: [],
                carParkItems: [],
                hasMultipleCarParks: undefined,
                latestVehicleUsed: undefined
            }
        }
        case "MODEL_DETAILS_ERROR": {
            return {
                ...state,
                error: true,
                loading: false
            }
        }
        case "CAR_PARK_LOADED": {
            const { carParks, headers, hasMultipleCarParks, latestVehicleUsed } = action.payload
            return {
                ...state,
                loading: false,
                error: false,
                carParkItems: carParks,
                carParkHeaders: headers,
                hasMultipleCarParks,
                latestVehicleUsed,
                showVehicleSelection: hasMultipleCarParks || state.showVehicleSelection
            }
        }
        case "SET_SHOW_VEHICLE_SELECTION": {
            return {
                ...state,
                showVehicleSelection: true
            }
        }
    }
    return state
}

function loadCarPark(vehicle?: Vehicle): AsyncAction<ComponentActionType, MainState> {
    return (dispatch, getState) => {
        if (!vehicle) {
            return
        }

        const { initFromHistory, selectedCarParkId } = getState()?.wheelsList.base
        
        if (initFromHistory && selectedCarParkId) {
            return
        }

        const isHostettler = getBundleParams()?.isHostettler

        dispatch({ type: "CAR_PARK_LOADING" })

        Repositories.loadCarPark(vehicle.tecDocTypeId, vehicle.vehicleType).then(
            response => {
                dispatch({ type: "CAR_PARK_LOADED", payload: { ...response, latestVehicleUsed: vehicle.id } })

                const skipVehicleSelection = isHostettler ? (!response.hasMultipleCarParks && vehicle.registrationNo) : !response.hasMultipleCarParks

                if (!response.hasMultipleCarParks) {
                    dispatch({ type: "SEND_SELECTED_CARD_PARK", payload: response.carParks[0] })
                }

                dispatch({ type: "CHANGE_STEP", payload: skipVehicleSelection ? { step: WheelSelectionSteps.WHEELSLIST, enablePreviousStep: true } : { step: WheelSelectionSteps.CARSELECTION } })
            },
            () => dispatch({ type: "CAR_PARK_ERROR" })
        )
    }
}

function loadTpmsArticle(vehicle: Vehicle | undefined): AsyncAction<ComponentActionType, MainState> {
    return (dispatch) => {
        const sensorFiltersRequest = createSensorArticleFiltersRequest(vehicle)

        if (!sensorFiltersRequest)
            return

        Repositories.loadSensorFilters(sensorFiltersRequest).then(sensorFiltersResponse => {
            dispatch({ type: "SEND_SENSOR_FILTERS_RESPONSE", payload: sensorFiltersResponse })
            const sensorItemsRequest = createSensorArticlesRequest(vehicle, sensorFiltersResponse)
            
            if (!sensorItemsRequest) return
            
            return Repositories.loadSensorItems(sensorItemsRequest).then(sensorItemsResponse => {
                dispatch({ type: "SEND_SENSOR_ITEMS_RESPONSE", payload: sensorItemsResponse })
            })
        })
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    loadCarPark,
    loadTpmsArticle
}