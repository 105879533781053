import { useUser, useWorkTask } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { CarModel, VehicleType } from "@tm/models"
import Morpheus, { connectComponent } from "@tm/morpheus"
import { concat, parseQueryString, renderRoute, RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { useCallback, Suspense } from "react"

import { ArticleReferencesState } from "../_shared/article-references-business"
import ModelListComponent from "../_shared/ModelList"
import { createVehicleFromModel, deleteImportedVehicleDetails } from "../../business"
import { useModelsByArticle } from "../../data/hooks"
import { getValidatedVehicleIdentificationParams, getVehicleDetailsUrl } from "../../helpers/routing"

type RouteParams = {
    vehicleType: string
    manufacturerId: any
    mainModelSeriesId: any
    modelSeriesId: any
    modelId?: any
}

type Props = RouteComponentProps<RouteParams> & {
    state: ArticleReferencesState
}

function Wrapper(props: Props) {
    if (!props.state.internalArticleId) {
        return null
    }

    return (
        <div className="tk-vehicle vehicle-models">
            <Suspense fallback={<Loader />}>
                <ArticleReferencesTypesComponent {...props} />
            </Suspense>
        </div>
    )
}

function ArticleReferencesTypesComponent(props: Props) {
    const {
        state: { internalArticleId },
        history,
        location,
        match,
    } = props

    const { vehicleType, manufacturerId, modelSeriesId } = getValidatedVehicleIdentificationParams(props.match.params)

    if (!vehicleType || !manufacturerId || !modelSeriesId || !internalArticleId) {
        return null
    }

    const { models } = useModelsByArticle({ vehicleType, manufacturerId, modelSeriesId, articleId: internalArticleId, selectedFilters: {} }) ?? {}
    const { attachToWorkTask, workTask } = useWorkTask() ?? {}
    const { userContext } = useUser()

    const handleSelect = useCallback(
        (model: CarModel) => {
            history.replace(concat(" ", renderRoute(match.path, { ...match.params, modelId: model.id }), location.search))
        },
        [history, match, location]
    )

    const handleApply = useCallback(
        (model: CarModel, plateId?: string, vin?: string, initialRegistration?: Date, mileAge?: number) => {
            createVehicleFromModel({ model, plateId, vin, initialRegistration, mileAge, vehicleType }).then((vehicle) => {
                TmaHelper.VehicleSelection.List.Graphical(model.dataSourceId)
                attachToWorkTask?.({ vehicle })
                deleteImportedVehicleDetails()

                // In Telesales it's possible to add multiple vehicles to a single worktask, therefore the
                // Conflict Dialog which initiates a redirect to the dashboard didn't trigger.
                // Therefore for Telesales catalogs we had to add another redirect to the dashboard.
                // https://jira.dvse.de/browse/NEXT-26742
                if (workTask?.vehicle && userContext.hasTelesales) {
                    Morpheus.closeView("1", renderRoute("/:workTaskId", match.params))
                }
            })
        },
        [attachToWorkTask, vehicleType, workTask?.vehicle, userContext.hasTelesales]
    )

    const handleDetailsClick = useCallback(
        (item: CarModel) => {
            history.push(getVehicleDetailsUrl(match.params, { vehicleType, vehicleId: item.id }))
        },
        [history, match.params, vehicleType]
    )

    if (!models) {
        return null
    }

    return (
        <ModelListComponent
            models={models}
            selectedModel={models.find((x) => x.id === match.params.modelId)}
            onSelect={handleSelect}
            onApply={handleApply}
            onDetailsClick={handleDetailsClick}
            vehicleType={vehicleType}
            showManufacturerThumbnail={false}
            showModelThumbnail={vehicleType === VehicleType.Motorcycle}
        />
    )
}

export default connectComponent({}, withRouter(Wrapper))
