import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import deepAssign from "deep-assign"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()

    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&:not(&--selected)": {
                borderLeftColor: color(colors.primary).setAlpha(opacity.secondary).toRgbString(),
                $nest: {
                    "&:hover": {
                        borderLeftColor: color(colors.primary).setAlpha(opacity.primary).toRgbString(),
                    },
                },
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
