import { CellContentPosition, Checkbox, Table, TableRowData, Typography, LoadingContainer } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { useGetJobDetails } from "../../../data/hooks"
import { FaultCodeItem } from "../../../models"

type Props = {
    jobId: string
}

export default function JobDetails({ jobId }: Props) {
    const { translateText } = useLocalization()

    const { faultCodeGroups, isLoading } = useGetJobDetails(jobId)

    const tableColumns = [
        {
            header: translateText(1065),
        },
        {
            header: translateText(617),
        },
        {
            header: translateText(13555),
        },
        {
            header: translateText(74),
        },
        {
            header: translateText(1759),
        },
        {
            alignContent: CellContentPosition.right,
        },
    ]

    const rows = useMemo(
        () =>
            faultCodeGroups
                ?.map((faultCodeGroup) => {
                    return faultCodeGroup.faultCodeItems?.reduce((list: TableRowData[], faultCode: FaultCodeItem) => {
                        list?.push({
                            id: faultCode.id,
                            cells: [
                                { displayValue: faultCode.faultId, id: "0" },
                                { displayValue: faultCode.faultInfo, id: "1" },
                                { displayValue: faultCodeGroup.name, id: "2" },
                                { displayValue: faultCode.faultType, id: "3" },
                                { displayValue: faultCode.faultStatus, id: "4" },
                                {
                                    displayValue: <Checkbox />,
                                    id: "5",
                                },
                            ],
                        })

                        return list
                    }, [])
                })
                ?.flatMap((o) => o),
        [faultCodeGroups]
    )

    return (
        <LoadingContainer isLoading={isLoading} padding="1em">
            <Typography variant="h4">{translateText(1065)}</Typography>
            <Table columns={tableColumns} rows={rows} headerStyle="default" variant="small" />
        </LoadingContainer>
    )
}
