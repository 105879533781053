import { RepairShop, UpdateRepairShopRequest, useUser } from "@tm/context-distribution"
import { Box, Button, Checkbox, FormControl, Typography, styled, Image } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { z } from "zod"
import { Controller, useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { useEffect, useMemo } from "react"
import { TextFormItem } from "../shared/TextFormItem"
import { ComboBoxFormItem } from "../shared/ComboBoxFormItem"
import { getGenderTitle, getSanitizedPrivacyPolicyUrl } from "../../lib/helpers"
import { getRepairShopSchema } from "../../lib/schemas/repairShop"

type Props = {
    repairShop?: RepairShop
    openPartslife: (data: UpdateRepairShopRequest, fieldsChanged: boolean) => void
}

export default function CustomerDetailsForm({ repairShop, openPartslife }: Props) {
    const { translateText } = useLocalization()
    const user = useUser()

    const sanitizedPrivacyPolicyHTML = useMemo(() => {
        return getSanitizedPrivacyPolicyUrl(translateText, user.userContext)
    }, [translateText, user?.userContext])

    const contactPersonGenderOptions = getGenderTitle(translateText)
    const repairShopDetailsSchema = getRepairShopSchema(translateText)

    const {
        control,
        handleSubmit,
        formState: { isSubmitting, isDirty, isValid, dirtyFields },
        trigger,
    } = useForm<z.infer<typeof repairShopDetailsSchema>>({
        resolver: zodResolver(repairShopDetailsSchema),
        mode: "all",
        criteriaMode: "all",
        defaultValues: {
            companyName: repairShop?.companyName ?? "",
            street: repairShop?.postalAddress?.street ?? "",
            zip: repairShop?.postalAddress?.zip ?? "",
            city: repairShop?.postalAddress?.city ?? "",
            phone: repairShop?.contactInfo?.phone ?? "",
            email: repairShop?.contactInfo?.email ?? "",
            firstName: repairShop?.contactPerson?.firstName ?? "",
            lastName: repairShop?.contactPerson?.lastName ?? "",
            title: Number(repairShop?.contactPerson?.title) ?? 0,
            privacyPolicyAccepted: false,
        },
    })

    useEffect(() => {
        trigger?.()
    }, [])

    function onSubmit(data: z.infer<typeof repairShopDetailsSchema>) {
        if (isDirty) {
            const request: UpdateRepairShopRequest = {
                companyName: data.companyName,
                postalAddress: {
                    ...repairShop?.postalAddress,
                    street: data.street,
                    zip: data.zip,
                    city: data.city,
                },
                contactInfo: {
                    ...repairShop?.contactInfo,
                    phone: data.phone,
                    email: data.email,
                },
                contactPerson: {
                    ...repairShop?.contactPerson,
                    title: data.title,
                    firstName: data.firstName,
                    lastName: data.lastName,
                },
            }
            const fieldsChanged = Object.keys(dirtyFields).length > 1
            openPartslife?.(request, fieldsChanged)
        }
    }

    return (
        <Box display="flex" flex="1" flexDirection="column" gap="1em">
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignContent="center">
                <Typography variant="h2" fontWeight="600">
                    {translateText(13584)}
                </Typography>
                <Button disabled={!isValid || isSubmitting} type="submit" form="customerInfoForm" color="primary" size="large">
                    {translateText(13583)}
                </Button>
            </Box>
            <Box>
                <StyledTypography variant="h2">{translateText(13651)}</StyledTypography>
                <StyledImage src="/styles/base/images/pool-klein.jpg" />
            </Box>
            <Typography variant="body1">{translateText(13581)}</Typography>
            <form id="customerInfoForm" noValidate onSubmit={handleSubmit(onSubmit)}>
                <Box display="flex" flexDirection="column" gap="1em" maxWidth="70%">
                    {/* Company */}
                    <Controller
                        control={control}
                        name="companyName"
                        render={({ field, fieldState }) => <TextFormItem label={translateText(108)} fieldState={fieldState} {...field} />}
                    />
                    <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="1em">
                        {/* Street, No. */}
                        <Controller
                            control={control}
                            name="street"
                            render={({ field, fieldState }) => <TextFormItem label={translateText(111)} fieldState={fieldState} {...field} />}
                        />

                        {/* Postal Code */}
                        <Controller
                            control={control}
                            name="zip"
                            render={({ field, fieldState }) => <TextFormItem label={translateText(112)} fieldState={fieldState} {...field} />}
                        />
                        {/* City */}
                        <Controller
                            control={control}
                            name="city"
                            render={({ field, fieldState }) => <TextFormItem label={translateText(113)} fieldState={fieldState} {...field} />}
                        />
                    </Box>

                    <Box display="grid" gridTemplateColumns="1fr 1fr" gap="1em">
                        {/* Phone */}
                        <Controller
                            control={control}
                            name="phone"
                            render={({ field, fieldState }) => <TextFormItem label={translateText(110)} fieldState={fieldState} {...field} />}
                        />
                        {/* Email */}
                        <Controller
                            control={control}
                            name="email"
                            render={({ field, fieldState }) => <TextFormItem label={translateText(109)} fieldState={fieldState} {...field} />}
                        />
                    </Box>

                    <Box display="grid" gridTemplateColumns="auto 1fr 1fr" gap="1em">
                        {/* Title */}
                        <Controller
                            control={control}
                            name="title"
                            render={({ field, fieldState }) => (
                                <ComboBoxFormItem
                                    options={contactPersonGenderOptions}
                                    label={translateText(118)}
                                    fieldState={fieldState}
                                    {...field}
                                />
                            )}
                        />
                        {/* FirstName */}
                        <Controller
                            control={control}
                            name="firstName"
                            render={({ field, fieldState }) => <TextFormItem label={translateText(119)} fieldState={fieldState} {...field} />}
                        />
                        {/* LastName */}
                        <Controller
                            control={control}
                            name="lastName"
                            render={({ field, fieldState }) => <TextFormItem label={translateText(104)} fieldState={fieldState} {...field} />}
                        />
                    </Box>

                    <Controller
                        control={control}
                        name="privacyPolicyAccepted"
                        render={({ field, fieldState }) => (
                            <FormControl sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                                <Checkbox
                                    aria-required
                                    required
                                    inputRef={field.ref}
                                    name={field.name}
                                    value={field.value}
                                    onChange={field.onChange}
                                    size="medium"
                                />
                                <StyledPrivacyPolicy
                                    isError={Boolean(fieldState.error)}
                                    dangerouslySetInnerHTML={{ __html: `${sanitizedPrivacyPolicyHTML}` }}
                                />
                            </FormControl>
                        )}
                    />
                </Box>
            </form>
        </Box>
    )
}

const StyledPrivacyPolicy = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isError",
})<{ isError?: boolean }>(({ isError, theme }) => ({
    ...(isError && {
        color: theme?.colors?.danger,
    }),
    a: {
        ...(isError && {
            color: theme?.colors?.danger,
        }),
        fontWeight: "600",
    },
}))

const StyledImage = styled(Image)({
    maxHeight: "5em",
    maxWidth: "5em",
    marginRight: "0.5em",
    verticalAlign: "middle",
    borderRadius: "6px",
})

const StyledTypography = styled(Typography)({
    display: "inline-block",
    fontWeight: "600",
    marginRight: "1em",
    verticalAlign: "middle",
})
