import { em, px } from "csx"
import { ReactNode, useMemo } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Checkbox, Text, TextField, Toolbar } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { bem, clone, initSelector } from "@tm/utils"
import { EFieldType, ESelectionType, ETextType, IFieldsItem, IValuesItem } from "../../../data/models"
import { Actions } from "../business"
import { DOPState } from "../business/model"
import CustomRadio from "./customRadio"
import Svg from "./svg/svg"

type Props = {
    item: IFieldsItem
}

const selector = initSelector((s: DOPState) => s.model.formState?.validationErrors)

export default function Field({ item }: Props) {
    const [validationss] = useSelector(selector)
    const actions = useActions(Actions)
    const style = useMemo(() => getStyle(), [])

    const handleTextField = (str: string) => {
        const a = clone(item)
        a.value = str
        if (item.maxLength && str.length >= item.maxLength) {
            a.value = str.slice(0, item.maxLength)
        }
        actions.updateField(item.id, a)
        actions.loadData()
    }

    const handleClick = (iVal: IValuesItem) => {
        const a = clone(item)
        a.values = a.values.map((x) => ({ ...x, isSelected: x.id === iVal.id && !x.isSelected }))
        actions.updateField(item.id, a)
        actions.loadData()
    }

    const error =
        validationss?.find((x) => x.relatedFields.includes(item.id))?.message ||
        (item.type === EFieldType.TextField && item.isRequired && !item.value) ||
        (item.type === EFieldType.SelectionField && !item.values.some((x) => x.isSelected))

    const renderTextField = () => {
        if (item.textType === ETextType.Text) {
            return <Text>{item.value}</Text>
        }

        return (
            <TextField
                readonly={item.isReadOnly}
                pattern={(item.pattern && RegExp(item.pattern)) || undefined}
                placeholder={item.placeholder}
                maxLength={item.maxLength}
                modelState={{ x: typeof error == "string" ? [error] : [] }}
                path={["x"]}
                // className={error && "field-error"}
                onChangeConfirm={handleTextField}
                // multiline={item.textType == ETextType.TextBox}
                value={item.value}
            />
        )
    }

    const renderButtons = () => {
        return item.values.map((va) => (
            <Button
                key={va.id}
                layout={["ghost"]}
                isActive={va.isSelected}
                skin={(va.isSelected && "primary") || undefined}
                fakeButton
                onClick={handleClick.bind(undefined, va)}
            >
                <div className={style.button}>
                    <Svg url={va.imageUrl || ""} selected={va.isSelected} />
                    {va.text}
                </div>
            </Button>
        ))
    }

    const renderCheckBox = () => {
        return item.values.map((va) => <Checkbox key={va.id} checked={va.isSelected} onToggle={handleClick.bind(undefined, va)} label={va.text} />)
    }

    const renderRadio = () => {
        return item.values.map((va) => (
            <CustomRadio key={va.id} checked={!!va.isSelected} onCheck={handleClick.bind(undefined, va)} label={va.text} />
        ))
    }

    const wrapContent = (child: ReactNode, asColumn?: boolean) => (
        <div className={style.wrapper}>
            {/* {item.label && <Text>{item.label}</Text>} */}
            <Toolbar className={bem(style.content, error && "error", asColumn && "collumn")} modifiers={["border-left"]} title={item.label}>
                {/* {typeof error == "string" && <Text size="s">{error}</Text>} */}
                {child}
            </Toolbar>
        </div>
    )

    if (item.type === EFieldType.TextField) {
        return wrapContent(renderTextField())
    }

    switch (item.selectionType) {
        case ESelectionType.ButtonGroup:
            return wrapContent(renderButtons())
        case ESelectionType.ComboBox:
            return wrapContent(renderCheckBox())
        case ESelectionType.Radio:
            return wrapContent(renderRadio(), true)
        default:
            return null
    }
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            flex: 1,
            display: "flex",
            alignItems: "center",
        },
        content: {
            $nest: {
                ".toolbar__inner": {
                    borderWidth: px(2),
                    // padding: 0
                },
                ".toolbar__title": {
                    paddingBottom: 0,
                },
                "&--error .toolbar__inner": {
                    borderColor: theme.colors.danger,
                },
                "&--collumn .toolbar__content": {
                    flexDirection: "column",
                    alignItems: "start",
                },
            },
        },
        button: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
        },
        buttonImage: {
            width: em(2.5),
            height: em(2.5),
        },
    })(Field)
}
