import { Box, Loader, styled } from "@tm/components"

export const SAD_CAR = (
    <svg width="96" height="96" viewBox="0 0 31 20">
        <path
            fill="#7C7C7C"
            d="M26.094 14.956h-1.395a.903.903 0 0 1-.904-.903v-2.089h1.91c.505.504.79.786 1.295 1.291v.798c0 .499-.406.903-.906.903zM3.926 8.973l1.916-3.819 1.084-2.163h16.146l1.085 2.163 1.915 3.819H3.926zm16.417 6.43s-3.491-1.41-5.487-1.41c-1.833 0-5.214 1.41-5.214 1.41a.647.647 0 0 1-.647-.646v-1.7c0-.357.29-.646.647-.646 0 0 2.543-.964 5.357-.964 2.892 0 5.344.964 5.344.964.357 0 .647.29.647.645v1.701c0 .356-.29.645-.647.645zm-15.044-.447H3.905A.905.905 0 0 1 3 14.053v-.798l1.294-1.29h1.912v2.088c0 .499-.406.903-.906.903zM26.842 3.819h-.002L24.927 0H5.073L3.157 3.819 0 10.116v7.83h3a2.997 2.997 0 0 0 3 2.991c1.654 0 3-1.34 3-2.99h11.999a2.998 2.998 0 0 0 3 2.99c1.654 0 3-1.34 3-2.99H30v-7.83l-3.158-6.298z"
        />
    </svg>
)

export const CARTELLIGENCE_LOGO = (
    <svg viewBox="0 0 345 136">
        <path
            fill="#004a92"
            d="M20.49 54.3c-.8.8-.8 2 0 2.7s2 .8 2.7 0c.8-.8.8-2 0-2.7-.7-.7-1.9-.7-2.7 0zm17.1-25.2c-.8.8-.8 2 0 2.7.8.8 2 .8 2.7 0 .8-.8.8-2 0-2.7-.7-.7-1.9-.7-2.7 0zm7.1 15.7c-.8.8-.8 2 0 2.7.8.8 2 .8 2.7 0 .8-.8.8-2 0-2.7-.7-.8-1.9-.8-2.7 0z"
        />
        <path
            fill="#004a92"
            d="M89.19 28.1v-5.4c0-4.5 1.5-7.3 4.6-8.3 1-.3 2.1-.5 3.4-.5h56.2V0h-113c-14.6 0-19.5 8.4-23.3 21.6-.7-.7-1.3-1.4-1.7-2.1-.6-1.2-.6-2.6-1.1-4.1-1.6-5.5-13.3-1.7-14.2.5-1.2 2.7 10.2.6 14.2 7.9-5.7.1-10.4 4.8-10.4 10.5v30.8c0 1.2.7 2.2 1.8 2.6v2.2a4 4 0 003.9 3.9h18a4 4 0 003.9-3.9v-1.2h5.8c4.9 0 13.5-3.7 21.6-3.7h94.5V51.1h-56.1c-3 0-5-.9-6.3-2.5l-.5-.7a11 11 0 01-1.2-5.5V28.1h-.1zM70.69 4.8h8.8a13.7 13.7 0 00-3.3 4.8h-10.5l-4.8-4.8h9.8zm-50.6 17.7c3.3-11.5 7.1-17.7 20.3-17.7h13.7l9.6 9.6h11.1c-.4 2-.6 4.3-.6 6.8v2.1h-9.7l-9.8-9.6h-6.9l-.9-1.2a5 5 0 00-7.2 0 5 5 0 000 7.2 5 5 0 008.1-1.2h4.9l4.8 4.8h-37.6l.2-.8zm22.4 20.1a5 5 0 018.1 1.2h8.4v-6.1h15.1v6.2c0 1.7.1 3.2.3 4.7h-23.8l-.9 1.2a5 5 0 01-7.2 0 5 5 0 010-7.2zm-7.1-8.5a5 5 0 010-7.2 5 5 0 018.1 1.2h30.6v4.8h-30.6l-.9 1.2a5.3 5.3 0 01-7.2 0zm9.3-16.6c-.8.8-2 .8-2.7 0-.8-.8-.8-2 0-2.7.8-.8 2-.8 2.7 0 .8.7.8 1.9 0 2.7zm-34.2 14.7c8.7-.5 22.1 7.3 22.8 14.2-4.8 1.4-25.1-.3-22.8-14.2zm15.8 25.9l-.9 1.2a5 5 0 01-7.2 0 5 5 0 010-7.2 5 5 0 018.1 1.2h49c.6 1.8 1.3 3.4 2.3 4.8h-51.3zM227.79 0h-63.7v13.8h60.4c5.5 0 8.1 2.9 8.1 8.8v4.1h-55.1c-11 0-15.1 5.2-15.1 14.7v8.9c0 9.5 4 14.7 15.1 14.7h70V21.1c.1-14.1-6.2-21.1-19.7-21.1zm4.8 52.4h-49.6c-4 0-5.6-2-5.6-5.9v-3.2c0-3.6 1.5-5.7 5.6-5.7h49.6v14.8zm110.5 8.4V48.5c0-10-6.1-12.1-10.2-12.5 3.9-.6 9.2-2.2 9.2-13v-8.3c0-9.5-4-14.7-15.1-14.7h-69.7v65h15.1V42.8h48.8c4.1 0 6.8 1.6 6.8 5.9v12.1c0 2.3.6 3.5 1.6 4.2h15.1c-.9-.8-1.6-1.9-1.6-4.2zm-15.9-35.6c0 4.2-2.5 5.7-6.2 5.7h-48.5V12.6h49.1c4 0 5.6 1.9 5.6 5.7v6.9z"
        />
        <path
            fill="#706f6f"
            d="M88.89 134.5h-4.9V88.4h-9.8v-4.8h24.5v4.8h-9.8v46.1zm34.1 0h-19.5V83.7h19.5v4.8h-14.5v17.1h13.7v4.8h-13.7v19.4h14.5v4.7zm26.2 0h-18.8V83.7h4.9v46.1h13.9v4.7zm24.4 0h-18.8V83.7h4.9v46.1h13.9v4.7zm10.5 0h-4.9V83.7h4.9v50.8zm25.6.7a15 15 0 01-13.2-7.1c-3-4.6-4.6-11-4.6-19.1a34 34 0 014.7-19.1c3.2-4.6 7.8-6.9 13.6-6.9a20 20 0 0111 3.3l.6.4-2.4 4.3-.7-.5c-3-1.9-5.8-2.8-8.5-2.8-4.3 0-7.5 1.7-9.8 5.3-2.3 3.6-3.4 9-3.4 16.1 0 6.9 1.2 12.2 3.5 16 2.3 3.7 5.4 5.4 9.5 5.4a22 22 0 007.2-1V113h-7.4v-4.8h12.4v24.7l-.5.2a37 37 0 01-12 2.1zm40-.7h-19.5V83.7h19.5v4.8h-14.5v17.1h13.7v4.8h-13.7v19.4h14.5v4.7zm35.1 0h-5.6l-17.3-40.9.1 4.8v36.1h-4.8V83.7h5.6l.2.5 17 40.1-.1-5.3V83.8h4.8v50.7h.1zm24.2.7c-3.3 0-6.2-1.1-8.7-3.2a20.6 20.6 0 01-5.7-9.1c-1.3-3.9-2-8.6-2-13.9 0-8 1.5-14.3 4.3-18.9 2.9-4.7 7-7.1 12.1-7.1 3.1 0 5.9.7 8.3 2.2l.6.4-2.3 4.3-.7-.5a11 11 0 00-6-1.7c-3.4 0-6.1 1.8-8.1 5.5a33.1 33.1 0 00-3.2 15.9c0 4.4.5 8.3 1.5 11.6a18 18 0 004.1 7.4 8.2 8.2 0 006 2.5c2.4 0 4.5-.4 6.2-1.3l1.2-.6v4.8l-.4.2c-1.7.9-4.2 1.5-7.2 1.5zm34.2-.7h-19.5V83.7h19.5v4.8h-14.5v17.1h13.7v4.8h-13.7v19.4h14.5v4.7z"
        />
    </svg>
)

export const ButtonContainer = styled(Box)(() => ({
    display: "flex",

    "& > div": {
        marginRight: "0.25em",
    },
}))

export const DataProviderBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "flex-end",
    marginLeft: "1em",

    "&:only-child": {
        marginLeft: "auto",
    },
}))

export const DataProviderLogo = styled("a")(() => ({
    marginLeft: "0.5em",
    opacity: 0.5,
    transition: "opacity 0.25s ease-in-out",
    cursor: "pointer",
    "&:hover": {
        opacity: 1,
    },
    svg: {
        display: "block",
        width: "auto",
        height: "2em",
    },
}))

export const FooterBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "auto",
    paddingTop: "0.5em",
}))

export const FooterLoader = styled(Loader)(() => ({
    marginLeft: "auto",
}))

export const NoDataBox = styled(Box)(() => ({
    textAlign: "center",
    margin: "4.75em 0",
    width: "100%",
    svg: {
        display: "block",
        margin: "0 auto",
    },
}))

export const NodesBox = styled(Box)(() => ({
    flex: 1,
    display: "flex",
    alignItems: "stretch",
    width: "100%",
    overflow: "hidden auto",
    marginTop: "0.5em",
}))

export const NodeGroupBox = styled(Box)(() => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    $nest: {
        "&:empty": {
            display: "none",
        },
        ".collapsible": {
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            $nest: {
                "&--opened": {
                    flex: 1,
                },
                "&__header": {
                    $nest: {
                        ".icon": {
                            fontSize: "1.5em",
                        },
                        ".collapsible__label": {
                            fontSize: "1.1em",
                        },
                    },
                },
                "&__content": {
                    overflow: "hidden auto",
                },
            },
        },
    },
}))

export const OverlayLoader = styled(Loader)(() => ({
    position: "absolute",
    top: "50%",
    left: "50%",
    height: "4em",
    width: "4em",
    marginLeft: "-2em",
    marginTop: "-2em",
}))

export const RepairTimesBox = styled(Box)(() => ({
    padding: "0.25em 0.6em",
}))

export const WrapperBox = styled(Box)(() => ({
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
}))
