import { useWorkTask } from "@tm/context-distribution"
import { Box, Icon, Modal, styled } from "@tm/components"
import { usePartnerInfoAction } from "../../helpers"

type PartnerInfoComponentProps = {
    limitNameLength: boolean
    externalSystemId?: number
}

const StyledWrapperBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    opacity: theme.opacity?.primary,
    padding: "0 0.5rem",
    color: theme.overwrites?.components?.worktaskModuleNavigation?.foregroundColor,
}))

const companyNameBoxStyles = {
    maxWidth: "13rem",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
}

export default function PartnerInfoComponent({ limitNameLength, externalSystemId }: PartnerInfoComponentProps) {
    const customer = useWorkTask()?.workTask?.customer
    const { displayBehavior, externalCatalogUrl, modalOpen, inModal, handleButtonClick, handleModalClose } = usePartnerInfoAction(externalSystemId)

    if (!customer) {
        return null
    }

    return (
        <>
            <StyledWrapperBox onClick={displayBehavior ? handleButtonClick : undefined} sx={displayBehavior ? { cursor: "pointer" } : undefined}>
                <Icon name="customer" />
                <Box title={customer.companyName} sx={limitNameLength ? { companyNameBoxStyles } : undefined}>
                    {customer.companyName}
                </Box>
            </StyledWrapperBox>
            {inModal && (
                <Modal open={modalOpen} onOutsideClick={handleModalClose}>
                    <iframe title="external-catalogs" src={externalCatalogUrl} height="890px" width="100%" />
                </Modal>
            )}
        </>
    )
}
