import {
    Icon,
    IconButton,
    RadioButton,
    Tooltip,
    Box,
    FormControlLabel,
    ListSubheader,
    MenuItem,
    RadioGroup,
    styled,
    Typography,
    Select,
    Switch,
    Theme,
    Stack,
} from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { BasketBundleParams, BasketMemoContext, BasketMemoSection, VehicleType, WorkTaskStatus } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createBasketMemo, CreateBasketMemoOptions, equals, getUIA } from "@tm/utils"
import { memo, useState, useEffect, useMemo } from "react"

import { ISaveableOption } from "."
import { SaveActions } from "./SaveActions"

const InlineRadioGroup = styled(RadioGroup)({
    display: "flex",
    flexDirection: "row",
})

const InlineBox = styled(Box)(({ theme }) => ({
    display: "flex",
    "& + &": {
        marginLeft: theme.spacing(1),
    },
}))

const PositionLabel = styled("label")(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.25em",
    width: "2em",
    border: "solid 1px #e5e5e4",
    borderRight: 0,
    borderRadius: theme.radius?.default,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    backgroundColor: "#f7f7f7",
}))

const StyledSelectInput = styled(Select)({
    ".MuiInputBase-input": {
        marginBottom: 0,
    },
})

const StyledSelectInputWithLabel = styled(StyledSelectInput)({
    ".MuiInputBase-input": {
        borderRadius: 0,
    },
})

const RemoveSectionButtonWrapper = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    border: "solid 1px #e5e5e4",
    borderLeft: 0,
    borderRadius: theme.radius?.default,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    backgroundColor: "#f7f7f7",
    ".MuiButtonBase-root.MuiIconButton-root": {
        backgroundColor: "transparent",
    },
}))

const Italic = styled("em")({
    fontStyle: "italic",
})

const InlineItalic = styled(Italic)(({ theme }) => ({
    display: "inline-block",
    marginLeft: theme.spacing(0.5),
}))

const SmallListSubheader = styled(ListSubheader)(({ theme }) => ({
    lineHeight: "24px",
    backgroundColor: theme.palette.divider,
}))

type BasketMemoSectionEntry = {
    value: BasketMemoSection
    label: string
    group?: string
}

type BasketMemoDefaults = {
    enabled: boolean
    context: BasketMemoContext
    sections: Array<BasketMemoSection>
    onlyOnVehicleParts: boolean
}

export const BasketMemoDefaults: BasketMemoDefaults = {
    enabled: false,
    context: "perPosition",
    sections: [],
    onlyOnVehicleParts: false,
}

const BasketMemoExampleData: CreateBasketMemoOptions = {
    vehicle: {
        id: "",
        manufacturer: "VW",
        modelSeries: "Golf IV",
        model: "1.6",
        tecDocManufacturerId: 1,
        tecDocModelId: 1,
        tecDocTypeId: 1,
        vehicleType: VehicleType.PassengerCar,
        plateId: "OD TM 123",
    },
    customer: {
        id: "",
        firstName: "Max",
        lastName: "Mustermann",
        companyName: "Musterfirma GmbH",
    },
    workTask: {
        telesalesStatusValue: WorkTaskStatus.Undefined,
        statusValue: WorkTaskStatus.Undefined,
        workTaskId: "",
        voucherNo: "12345",
    },
}

type Props = ISaveableOption

export const BasketMemoOptions = memo(({ isSaving, onSave }: Props) => {
    const { hasExtendedBasketMemoSettings } = Morpheus.getParams<BasketBundleParams>("basket")
    const { translate, translateText } = useLocalization()
    const { userSettings, setUserSetting, changeOrderOptions } = useUser() ?? {}
    const { orderOptions = {} } = userSettings ?? {}

    const [basketMemoEnabled, setBasketMemoEnabled] = useState<boolean>(
        orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ?? BasketMemoDefaults.enabled
    )
    const [basketMemoOnlyOnVehicleParts, setBasketMemoOnlyOnVehicleParts] = useState<boolean>(
        orderOptions.repairShopResponse?.workTaskInfoOnlyForVehicleParts ?? BasketMemoDefaults.onlyOnVehicleParts
    )
    const [basketMemoContext, setBasketMemoContext] = useState<BasketMemoContext>(orderOptions.basketMemoContext ?? BasketMemoDefaults.context)
    const [basketMemoSections, setBasketMemoSections] = useState<Array<BasketMemoSection>>(
        orderOptions.basketMemoSections ?? BasketMemoDefaults.sections
    )

    useEffect(() => {
        onSave(false)
        setBasketMemoEnabled(orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo ?? BasketMemoDefaults.enabled)
    }, [orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo])

    useEffect(() => {
        onSave(false)
        setBasketMemoOnlyOnVehicleParts(orderOptions.repairShopResponse?.workTaskInfoOnlyForVehicleParts ?? BasketMemoDefaults.onlyOnVehicleParts)
    }, [orderOptions.repairShopResponse?.workTaskInfoOnlyForVehicleParts])

    useEffect(() => {
        onSave(false)
        setBasketMemoContext(orderOptions.basketMemoContext ?? BasketMemoDefaults.context)
    }, [orderOptions.basketMemoContext])

    useEffect(() => {
        onSave(false)
        setBasketMemoSections(orderOptions.basketMemoSections ?? BasketMemoDefaults.sections)
    }, [orderOptions.basketMemoSections])

    const basketMemoSectionsChanged = useMemo<boolean>(() => {
        return !equals(basketMemoSections, orderOptions.basketMemoSections ?? BasketMemoDefaults.sections)
    }, [basketMemoSections, orderOptions.basketMemoSections])

    /** Contains all possible placeholders that can be used for the basket memo */
    const PossibleBasketMemoPlaceholders = useMemo<Array<BasketMemoSectionEntry>>(
        () => [
            { value: "vehicleLicensePlateWithVehicleDescriptionFallback", label: translateText(21) /* group: translateText(99) */ },
            { value: "customerFullName", label: translateText(1930) /* group: translateText(107) */ },
            { value: "voucherNumber", label: translateText(470) /* group: translateText(30) */ },
            // Other possible placeholders - but currently not required
            // { value: "vehicleLicensePlate", label: translateText(21), group: translateText(99) },
            // { value: "customerFirstName", label: translateText(119), group: translateText(107) },
            // { value: "customerLastName", label: translateText(104), group: translateText(107) },
            // { value: "customerCompanyName", label: translateText(108), group: translateText(107) },
        ],
        [translateText]
    )

    /** Contains all not already used placeholders */
    const PossibleNewBasketMemoPlaceholders = useMemo<Array<BasketMemoSectionEntry>>(() => {
        return PossibleBasketMemoPlaceholders.filter((section) => !basketMemoSections.includes(section.value))
    }, [PossibleBasketMemoPlaceholders, basketMemoSections])

    const demoBasketMemo = useMemo<string | undefined>(() => {
        return createBasketMemo({
            ...BasketMemoExampleData,
            config: {
                sections: basketMemoSections ?? [],
            },
        })
    }, [basketMemoSections])

    function handleSetBasketMemoEnabled(state: boolean) {
        onSave(true)
        setBasketMemoEnabled(state)
        changeOrderOptions?.({ addWorkTaskInfoToOrderItemMemo: state })
    }

    function handleSetOnlyVehicleMemoEnabled(state: boolean) {
        onSave(true)
        setBasketMemoOnlyOnVehicleParts(state)
        changeOrderOptions?.({ workTaskInfoOnlyForVehicleParts: state })
    }

    function handleChangeMemoContext(_: unknown, value: string) {
        onSave(true)

        setBasketMemoContext(value as BasketMemoContext)

        setUserSetting("BASKET_MEMO_OPTIONS", {
            context: value as BasketMemoContext,
            sections: basketMemoSections,
        })
    }

    function handleChangeMemoSection(sectionIndex: number, value: BasketMemoSection) {
        const sections = [...basketMemoSections]

        const existingIndex = sections.indexOf(value)
        if (existingIndex !== -1) {
            sections[existingIndex] = sections[sectionIndex]
        }

        setBasketMemoSections([...sections.slice(0, sectionIndex), value, ...sections.slice(sectionIndex + 1)])
    }

    function handleRemoveMemoSection(sectionIndex: number) {
        setBasketMemoSections((prev) => [...prev.slice(0, sectionIndex), ...prev.slice(sectionIndex + 1)])
    }

    function handleAddNewMemoSection(value: BasketMemoSection) {
        setBasketMemoSections([...basketMemoSections, value])
    }

    function handleSaveBasketMemoSections() {
        onSave(true)

        setUserSetting("BASKET_MEMO_OPTIONS", {
            context: basketMemoContext,
            sections: basketMemoSections,
        })
    }

    function handleResetBasketMemoSections() {
        setBasketMemoSections(orderOptions.basketMemoSections ?? BasketMemoDefaults.sections)
    }

    function renderMenuItems(sections: Array<BasketMemoSectionEntry>) {
        return Object.entries(sections.groupBy((x) => x.group ?? ""))
            .map(([group, entries]) => {
                const elements = entries.map((entry) => (
                    <MenuItem key={entry.value} value={entry.value}>
                        {entry.label}
                    </MenuItem>
                ))

                if (group) {
                    elements.unshift(<SmallListSubheader>{group}</SmallListSubheader>)
                }

                return elements
            })
            .flat()
    }

    return (
        <Stack spacing={1}>
            <Switch
                checked={basketMemoEnabled}
                onChange={(e) => handleSetBasketMemoEnabled(e.target.checked)}
                label={translateText(hasExtendedBasketMemoSettings ? 13055 : 1256)}
                disabled={isSaving}
                {...getUIA("SettingsBasketAutomaticPlateNumber")}
            />
            <Box pl={6}>
                <Switch
                    checked={basketMemoOnlyOnVehicleParts}
                    onChange={(e) => handleSetOnlyVehicleMemoEnabled(e.target.checked)}
                    label={translateText(13143)}
                    disabled={!basketMemoEnabled || isSaving}
                    {...getUIA("SettingsBasketAutomaticPlateNumberOnlyForRelated")}
                />
            </Box>

            {basketMemoEnabled && hasExtendedBasketMemoSettings && (
                <Stack spacing={1} pl={6}>
                    <InlineRadioGroup value={basketMemoContext} onChange={handleChangeMemoContext}>
                        <FormControlLabel value="perPosition" label={translateText(13051)} control={<RadioButton />} disabled={isSaving} />
                        <FormControlLabel value="perOrder" label={translateText(1248)} control={<RadioButton />} disabled={isSaving} />
                    </InlineRadioGroup>

                    <Box pl={3}>
                        <Typography>
                            {basketMemoContext === "perPosition" ? translate(13052) : translate(13053)}
                            <InlineItalic>({translateText(763).toLowerCase()})</InlineItalic>:
                        </Typography>

                        <Stack direction="row" spacing={1} alignItems="center">
                            {basketMemoSections.map((section, index) => (
                                <InlineBox key={index}>
                                    <PositionLabel id={`basket-memo-section-select-${index}`}>{index + 1}</PositionLabel>
                                    <StyledSelectInputWithLabel
                                        labelId={`basket-memo-section-select-${index}`}
                                        value={section}
                                        onChange={(e) => handleChangeMemoSection(index, e.target.value as BasketMemoSection)}
                                        disabled={isSaving}
                                    >
                                        {renderMenuItems(PossibleBasketMemoPlaceholders)}
                                    </StyledSelectInputWithLabel>
                                    <RemoveSectionButtonWrapper>
                                        <Tooltip title={translateText(624).toLowerCase()}>
                                            <Box>
                                                <IconButton onClick={() => handleRemoveMemoSection(index)} disabled={isSaving}>
                                                    <Icon name="close" />
                                                </IconButton>
                                            </Box>
                                        </Tooltip>
                                    </RemoveSectionButtonWrapper>
                                </InlineBox>
                            ))}
                            {!!PossibleNewBasketMemoPlaceholders.length && (
                                <InlineBox>
                                    <StyledSelectInput
                                        value=""
                                        displayEmpty
                                        onChange={(e) => handleAddNewMemoSection(e.target.value as BasketMemoSection)}
                                        disabled={isSaving}
                                    >
                                        <MenuItem value="">
                                            <Italic>{translate(13054)}</Italic>
                                        </MenuItem>
                                        {renderMenuItems(PossibleNewBasketMemoPlaceholders)}
                                    </StyledSelectInput>
                                </InlineBox>
                            )}
                            {basketMemoSectionsChanged && (
                                <SaveActions
                                    onReset={handleResetBasketMemoSections}
                                    onSave={handleSaveBasketMemoSections}
                                    resetDisabled={isSaving}
                                    saveDisabled={isSaving}
                                />
                            )}
                        </Stack>

                        {demoBasketMemo && (
                            <Typography>
                                {translate(12881)}: {demoBasketMemo}
                            </Typography>
                        )}

                        <Typography variant="label" color="grey.500">
                            {translate(12423)}: {translate(13058)}
                        </Typography>
                    </Box>
                </Stack>
            )}
        </Stack>
    )
})
