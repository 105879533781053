import { Suspense } from "react"
import Morpheus from "@tm/morpheus"
import { Loader } from "@tm/controls"
import { ConfigParams, ErpSystemConfigMode, IMicros } from "@tm/models"
import { Box } from "@tm/components"

import PricesComponent from "../../../_shared/prices"
import AvailabilityComponent from "../../../_shared/availability"
import ExtendedDeliveryComponent from "../../../_shared/extended-delivery"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { getBundleParams } from "../../../utils"

import { publishErpInfoLoaded } from "../../_helpers/publishErpInfoLoaded"
import Partnersystem from "./components/partnersystem/availability"
import Icons from "./components/icons"
import PartnerSystemHelper from "./components/partnersystem/helper"
import { AdditionalInformation } from "../../../_shared/additional-information"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info"]

function ErpInfoComponent(props: Props) {
    const {
        data,
        hidePriceMemos,
        hideAlternativesIcon,
        hideSpecialIconsFromPrices,
        searchQuery,
        onAlternativesClick,
        onTeccomClick,
        onClick,
        onGraduatedPricesClick,
        onPartnersystemClick,
        onReplacementArticlesClick,
        onSpecialIconClick,
        onStgSafetyDataSheetClick,
    } = props

    const { disableDangerouslyDirtyHtmlTooltip, articleListTeccomMode, erpInfoMicroPricesAfterAvailability, showPartnersystemIconsOnArticleList } =
        getBundleParams()

    const { loading, erpInfo, error, loadTeccom, teccomLoading, erpConfig, teccomResponse } = useErpInfo(
        data,
        "list",
        {
            onLoad: (_, response) => {
                publishErpInfoLoaded(response)
            },
        },
        undefined,
        searchQuery
    )

    const additionalHTMLInformation = !disableDangerouslyDirtyHtmlTooltip ? erpInfo?.additionalInformationExtended : undefined

    if (loading) {
        return <Loader />
    }

    let teccomFunction
    if (loadTeccom) {
        // If loadTeccom is undefined, teccom is not available for the article
        switch (articleListTeccomMode) {
            case "redirect":
                teccomFunction = () => {
                    if (onTeccomClick) {
                        onTeccomClick(erpInfo?.useSpecialProcurement)
                    }
                }
                break
            case "load":
            default:
                teccomFunction = loadTeccom
                break
        }
    }

    let pricesComp
    if (erpInfo?.prices?.length) {
        pricesComp = (
            <PricesComponent
                classPrefix="erp-info"
                prices={erpInfo.prices}
                erpInfo={erpInfo}
                hasGraduatedPrices={erpInfo?.hasGraduatedPurchasePrice || false}
                graduatedPrices={erpInfo?.graduatedPrices}
                onGraduatedPricesClick={onGraduatedPricesClick}
                article={data}
                quantity={erpInfo?.quantity}
                hideMemos={hidePriceMemos}
                isReplacementPart={erpInfo?.isReplacementPart || false}
                hideSpecialIcons={hideSpecialIconsFromPrices}
            />
        )
    } else {
        pricesComp = (
            <div className="erp-info__prices erp-info__prices--disabled">
                <div className="price__group" />
            </div>
        )
    }

    return (
        <>
            {showPartnersystemIconsOnArticleList &&
            erpConfig.mode === ErpSystemConfigMode.Partnersystems &&
            erpConfig.erpSystemConfigs &&
            erpConfig.erpSystemConfigs.length > 1 ? (
                <PartnerSystemHelper config={erpConfig.erpSystemConfigs[1]} article={data} fallback={null}>
                    {({ erpInfo: partnersystemErpInfo }) => (
                        <Icons
                            erpInfo={erpInfo}
                            specialProcurementErpInformation={erpInfo?.specialProcurementErpInformation}
                            onStgSafetyDataSheetClick={onStgSafetyDataSheetClick}
                            onSpecialIconClick={onSpecialIconClick}
                            additionalSpecialIcons={partnersystemErpInfo?.specialIcons}
                            additionalMemos={partnersystemErpInfo?.memos}
                        />
                    )}
                </PartnerSystemHelper>
            ) : (
                <Icons
                    erpInfo={erpInfo}
                    specialProcurementErpInformation={erpInfo?.specialProcurementErpInformation}
                    onStgSafetyDataSheetClick={onStgSafetyDataSheetClick}
                    onSpecialIconClick={onSpecialIconClick}
                />
            )}
            {!erpInfoMicroPricesAfterAvailability && pricesComp}
            {additionalHTMLInformation && (
                <Box display="flex" alignItems="center" pr={1}>
                    <AdditionalInformation information={additionalHTMLInformation} />
                </Box>
            )}
            <AvailabilityComponent
                classPrefix="erp-info"
                availability={erpInfo?.availability}
                hasReplacedArticles={!!(erpInfo?.articles && erpInfo.articles.length)}
                tour={erpInfo?.tour}
                warehouses={erpInfo?.warehouses}
                error={error}
                hasAlternatives={Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp || erpInfo?.hasAlternatives || false}
                hasReplacementArticles={erpInfo?.hasReplacementArticles || false}
                onClick={onClick}
                onAlternativesClick={onAlternativesClick}
                onReplacementArticlesClick={onReplacementArticlesClick}
                requestedQuantity={erpInfo?.quantity?.requestedValue}
                specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
                hideAlternativesIcon={hideAlternativesIcon}
                useSpecialProcurement={erpInfo?.useSpecialProcurement}
                partnerSystem={
                    erpConfig.mode === ErpSystemConfigMode.Partnersystems && erpConfig.erpSystemConfigs && erpConfig.erpSystemConfigs.length > 1 ? (
                        <Box mr={0.5}>
                            <Partnersystem
                                config={erpConfig.erpSystemConfigs[1]}
                                article={data}
                                onClick={onPartnersystemClick}
                                onSpecialIconClick={onSpecialIconClick}
                            />
                        </Box>
                    ) : undefined
                }
            />
            {erpInfoMicroPricesAfterAvailability && pricesComp}
            {erpInfo?.useSpecialProcurement ? (
                <Box width="43px" className="button_placeholder" />
            ) : (
                <ExtendedDeliveryComponent
                    extendedDeliveryRequested={!!teccomResponse}
                    loadExtendedDelivery={teccomFunction}
                    extendedDeliveryLoading={teccomLoading}
                />
            )}
        </>
    )
}

function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info">
                    <Suspense fallback={<Loader />}>
                        <ErpInfoComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}

export default Wrapper
