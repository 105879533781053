import { useQuery } from "react-query"
import { getStorageUris, getTransferInfo } from "../repositories/fast-up"

export function useTransferInfo() {
    const { data, isLoading, isError, refetch, isRefetching } = useQuery(["vehicle__fast-up__getTransferInfo"], getTransferInfo, {
        staleTime: 0,
        cacheTime: 0,
    })

    return { transferInfo: data, isLoading, isError, refetch, isRefetching }
}

export function useStorageUris(transferId: string | undefined) {
    const { data, isLoading } = useQuery(
        ["vehicle__fast-up__getStorageUris", transferId],
        () => (transferId ? getStorageUris(transferId) : undefined),
        {
            enabled: !!transferId,
            refetchInterval: 10000, // 10s
        }
    )

    return { storageUris: data?.storageUris, isLoading }
}
