import { useMemo } from "react"
import { useParams } from "react-router"
import { Box, Button, Icon, Tooltip, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Toolbar } from "@tm/controls"
import { getComponentStyles } from "../../styles"
import { useShowCompilationPdf } from "../../../../data/hooks/useCompilations"
import CompilationDetailsComponent from "../details"

type RouteProps = {
    compilationId?: string
    workTaskId: string
}

type Props = {
    compilationId?: string
}

export default function SingleCompilationsComponent(props: Props) {
    const { compilationId } = props
    const classNames = useMemo(() => getComponentStyles(), [])
    const matchParams = useParams<RouteProps>()
    const { translateText } = useLocalization()
    const { showCompilationPdf, loadingCompilationPdf } = useShowCompilationPdf(compilationId)

    return (
        <div className={classNames.main}>
            <div className="tk-compilations navigation">
                <Box mt={3} ml={2} mr="230px">
                    <Typography variant="h1">{translateText(13789)} </Typography>
                </Box>
                <Toolbar title={translateText(177)}>
                    <Tooltip title={translateText(49)}>
                        <Box>
                            <Button
                                disabled={loadingCompilationPdf}
                                onClick={showCompilationPdf}
                                startIcon={<Icon name="print" />}
                                variant="outlined"
                            />
                        </Box>
                    </Tooltip>
                </Toolbar>
            </div>

            <div className={classNames.content}>
                {compilationId ? (
                    <CompilationDetailsComponent compilationId={compilationId} encodedWorkTaskId={matchParams.workTaskId} singleCompilationList />
                ) : (
                    <Typography variant="body1" className="empty-text">
                        {translateText(1284)}
                    </Typography>
                )}
            </div>
        </div>
    )
}
