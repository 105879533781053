import { channel } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { useLocalization } from "@tm/localization"
import { useEffect } from "react"
import { Box, styled } from "@tm/components"
import { MainActions } from "../main/business"
import { MainState } from "../main"

export const documentSelector = createSelector(
    (s: MainState) => s.settings.externalUrl,
    (x) => x
)

export default function SettingsComponent() {
    const { translateText } = useLocalization()
    const actions = useActions(MainActions, "changeStep", "getSettingsUrl")
    const externalUrl = useSelector(documentSelector)

    useEffect(() => {
        if (!externalUrl) {
            actions.getSettingsUrl(translateText)
        }
    }, [])

    useEffect(() => {
        const subscription = channel("GLOBAL").subscribe("DMG_DAT/EXTERNAL_TOOL_DONE", (msg) => {
            const data = msg as any

            if (data.type === "relocate") {
                const getContractIdRegex = new RegExp("_(.*?)~")
                const contractId = getContractIdRegex.exec(data.message)?.[1]

                if (!contractId) {
                    return
                }

                actions.changeStep("calculation", { contractId })

                // document.getElementById("dat-external-settings")?.remove()
                // document.getElementById("dat-external")?.remove()
            }
        })

        return subscription
    })

    return (
        <StyledWrapper>
            {externalUrl && <iframe title="dat-glass-settings" className="extern" id="dat-glass-settings" srcDoc={externalUrl} />}
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)(() => ({
    flex: 1,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",

    ".extern": {
        flex: 1,
    },
}))
