import { Grid } from "@tm/controls"
import { PropsWithChildren } from "react"
import FieldItem from "./fieldItem"

type StaticComponents = {
    Item: typeof FieldItem
    Container: typeof FieldContainer
}

function FieldContainer({ children }: PropsWithChildren<Record<string, unknown>>) {
    return <Grid container>{children}</Grid>
}

const Field: StaticComponents = {
    Item: FieldItem,
    Container: FieldContainer,
}

export default Field
