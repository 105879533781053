import { ajax } from "@tm/utils"
import { useCallback, useEffect, useMemo } from "react"
import { atom, useRecoilState } from "recoil"
import { Message, useChatAuthorization, useForeignUsersInChatMulti, useSignalRMessage } from "."
import { getBundleParams } from "../../utils"

export type ChatInfo = {
    chatId: string
    users: Array<{
        displayName: string
        userId: string
    }>
    /** Frontend only */
    lastMessage?: Message
}

const chatListRecoilAtom = atom<Array<ChatInfo> | undefined>({
    key: "notifications_chat_list",
    default: undefined,
})

export function useChatList() {
    const authorization = useChatAuthorization()
    const [list, setList] = useRecoilState(chatListRecoilAtom)

    const chatsIdsWithoutUsers = useMemo(() => {
        const ids: Array<string> = []
        list?.forEach((chat) => {
            if (!chat.users.length) {
                ids.push(chat.chatId)
            }
        })
        return ids
    }, [list])

    const manuallyLoadedChatUsers = useForeignUsersInChatMulti(chatsIdsWithoutUsers)

    useEffect(() => {
        if (!authorization) {
            return
        }

        let cancelled = false
        const url = `${getBundleParams().chatApiUrl}/api/v1/user/chats/get`
        ajax({ url, method: "GET", authorization }).then((response: Array<ChatInfo>) => {
            if (cancelled || !response.length) {
                return
            }

            Promise.all(
                response.map((chat) => {
                    const getLastMessageUrl = `${getBundleParams().chatApiUrl}/api/v1/user/chats/messages/getlast/${chat.chatId}/${1}`
                    return ajax({ url: getLastMessageUrl, method: "GET", authorization }) as Promise<Array<Message>>
                })
            ).then(
                (lastMessages) => {
                    if (cancelled) {
                        return
                    }

                    lastMessages.forEach((messages, index) => {
                        response[index].lastMessage = messages?.[0] // Mutate object in response. Shouldn't be a problem at this point.
                    })
                    setList(response)
                },
                () => {
                    setList(response)
                }
            )
        })

        return () => {
            cancelled = true
        }
    }, [authorization, setList])

    const messageHandler = useCallback(
        (message: Message) => {
            setList((currentList) => {
                if (!currentList) {
                    return [
                        {
                            chatId: message.chatId,
                            users: [],
                            lastMessage: message,
                        },
                    ]
                }

                const index = currentList.findIndex((c) => c.chatId === message.chatId)

                if (index === -1) {
                    return [
                        ...currentList,
                        {
                            chatId: message.chatId,
                            users: [],
                            lastMessage: message,
                        },
                    ]
                }
                const result = [...currentList]
                result[index] = { ...result[index], lastMessage: message }
                return result
            })
        },
        [setList]
    )

    useSignalRMessage(messageHandler)

    return useMemo(() => {
        return list
            ?.map<ChatInfo>((chat) => {
                const manuallyLoadedUsers = manuallyLoadedChatUsers[chat.chatId]
                if (!chat.users.length && manuallyLoadedUsers?.length) {
                    return { ...chat, users: manuallyLoadedUsers.map((user) => ({ ...user, userId: user.id })) }
                }
                return chat
            })
            .sort((a, b) => {
                if (!a.lastMessage && !b.lastMessage) {
                    return 0
                }
                if (!a.lastMessage) {
                    return 1
                }
                if (!b.lastMessage) {
                    return -1
                }
                return new Date(a.lastMessage.inserted) >= new Date(b.lastMessage.inserted) ? -1 : 1
            })
    }, [list, manuallyLoadedChatUsers])
}
