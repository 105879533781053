import { Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { SelectedPriceVatMode } from "@tm/models"
import { B2BTotals, B2CTotals } from "@tm/data/vouchers/costEstimations"
import Total from "./Total"

type Props = {
    priceVatMode?: SelectedPriceVatMode
    totals?: B2CTotals | B2BTotals
    currencyCode: string
}

export function PartsTotals({ totals, currencyCode, priceVatMode }: Props) {
    const { translateText, currency } = useLocalization()

    if (!totals) {
        return null
    }
    return (
        <Stack py={1} pr={1} display="flex" justifyContent="flex-end" spacing={0.5} width="400px">
            <Total label={translateText(1720)} value={currency(totals?.partCosts || 0, currencyCode)} valueBold />
            {!!totals?.alreadyTaxedPartCosts && priceVatMode !== SelectedPriceVatMode.Gross && (
                <Total label={translateText(12850)} value={currency(totals.alreadyTaxedPartCosts, currencyCode)} valueBold />
            )}
        </Stack>
    )
}
