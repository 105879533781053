import { Button, Badge, Icon } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { Article, IMicros } from "@tm/models"
import { Component } from "react"
import { SmartClientHelper } from "../sc-helper"
import { AddToSCBasketState } from "./business/model"
import { Actions, IActions } from "./business"
import { bindSpecialReactMethods } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"

type Props = LocalizationProps & IMicros["standalone"]["sc-add-articles-to-basket"] & {
    state: AddToSCBasketState
    actions: IActions
}

class AddToSCBasketComponent extends Component<Props> {

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleClick() {
        const { items, actions, sourceId } = this.props
        SmartClientHelper.addArticlesToBasket(items, actions.addItemToScBasket.bind(items), sourceId)
    }

    render() {
        const { items, localization: { translateText }, state: { states }, buttonText } = this.props

        const loadedSuccesfully = states && items[0] && states[items[0].id] && states[items[0].id].loadedSuccesfully

        return (
            <div className="tk-standalone rd-add-articles-to-basket" >
                {loadedSuccesfully &&
                    <Badge skin="dark" value={<Icon name="check" />} />
                }
                <Button
                    title={translateText(937)}
                    className="add-to-basket__button"
                    layout={["bordered"]}
                    skin="highlight"
                    onClick={this.handleClick}
                    icon={"cart"}
                    disabled={false}
                >
                    {buttonText && <span className="btn__text">{buttonText}</span>}
                </Button>
            </div>
        )
    }
}
export default connectComponent(Actions, withLocalization(AddToSCBasketComponent))