import { em } from "csx"
import { ReactElement, useCallback, useState } from "react"
import { Box, Button, Icon, styled, ReplaceButton } from "@tm/components"
import { Article, IMicros, PriceType, RequestArticleDetailsPayload, channel } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { ArticleForOe, NotFoundArticle } from "../../../data/models/calculation"
import QuantityDropdown from "../../_shared/QuantityDropdown"
import TotalPrice from "./totalPrice"
import { mapDrivemotiveArticleToArticle, mapErpPrices } from "../../../data/helpers/mappers"
import { calculationActions } from "../../../data/state"

type Props = {
    item: ArticleForOe
}

export default function ArticleItem({ item }: Props): ReactElement {
    const { renderMicro } = useMicro<IMicros>()
    const [switchStates, setSwitchStates] = useState<{
        isStared: boolean
        opened: boolean
    }>({
        isStared: false,
        opened: false,
    })

    const handleToggleOpened = () => {
        setSwitchStates((prevState) => ({
            ...prevState,
            opened: !prevState.opened,
        }))
    }

    const onStarArticle = () => {
        setSwitchStates((prevState) => ({
            ...prevState,
            isStared: !prevState.isStared,
        }))
    }

    const renderOeNrs = () => {
        if (!item.article?.oeNrs) {
            return
        }

        return (
            <StyledOeGroups>
                <StyledOeGroup>
                    {item.article.oeNrs.map((oeNr, index) => (
                        <StyledTag key={index} variant="text">
                            {oeNr.oeNr} | {oeNr.herBez}
                        </StyledTag>
                    ))}
                </StyledOeGroup>
            </StyledOeGroups>
        )
    }

    const handleDeleteArticle = () => {
        calculationActions.deleteArticleFromCalculation(item, undefined)
    }

    const handleChangeQuantity = (article: ArticleForOe | NotFoundArticle, quantity: number) => {
        calculationActions.changeQuantity(article, quantity)
    }

    const renderActions = () => {
        let totalPrice = 0
        if (item.article.artPreise) {
            const retailPrice = item.article.artPreise.find((price) => price.preisArt == PriceType.RecommendedRetail)
            if (retailPrice) {
                totalPrice = retailPrice?.preis * item.quantity
            }
        }

        return (
            <ActionsWrapper>
                {totalPrice > 0 && <TotalPrice totalPrice={totalPrice} />}
                <QuantityDropdown article={item} onChangeQuantity={handleChangeQuantity} />
                <StyledButton onClick={handleDeleteArticle} startIcon={<Icon name="delete" />} />
                <ReplaceButton onClick={() => {}} disabled />
                <StyledButton
                    onClick={onStarArticle}
                    startIcon={<Icon name={switchStates.isStared ? "filled-star" : "vote-star"} />}
                    variant="text"
                />
            </ActionsWrapper>
        )
    }

    const handleRequestArticleDetails = useCallback((request: RequestArticleDetailsPayload) => {
        request.categoryType = "partAlternatives"
        request.inModal = true
        request.article = {
            productGroupId: item.article.genArtNr,
            supplierId: item.article.einspNr,
            supplierArticleNo: item.article.eArtNr,
        }
        channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
    }, [])

    const renderCustomErp = () => {
        if (item.article.artPreise?.length) {
            return renderMicro!("erp", "display-only-prices", {
                data: mapDrivemotiveArticleToArticle(item) as Article,
                prices: mapErpPrices(item.article),
                priceNamesInTooltip: true,
            })
        }
    }

    const renderArticle = () => {
        return renderMicro!("parts", "part-item", {
            part: mapDrivemotiveArticleToArticle(item) as Article,
            className: Morpheus.getParams("parts")?.templates?.articleItem?.bundle,
            shouldLoadVehicleRecords: false,
            isCompact: !switchStates.opened,
            showActions: true,
            showArticleImage: true,
            onRequestArticleDetails: handleRequestArticleDetails,
            onToggleCollapse: handleToggleOpened,
            renderCustomErpInformation: renderCustomErp,
            renderBuyActions: renderActions,
            canFilterArticleAttributes: false,
        })
    }

    return (
        <>
            {renderOeNrs()}
            {renderArticle()}
        </>
    )
}

const StyledTag = styled(Button)({
    span: {
        border: "1px solid #b4b4b4",
        padding: ".3em",
    },
})

const ActionsWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
})

const StyledOeGroups = styled(Box)({
    display: "flex",
    flexWrap: "wrap",
    marginBottom: em(0.25),
    marginRight: em(-0.5),
})

const StyledOeGroup = styled(Box)({
    position: "relative",
    marginBottom: em(0.25),
    marginRight: em(0.5),
})

const StyledButton = styled(Button)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "0 .25em",
})
