import { AsyncAction } from "@tm/morpheus"
import { MainState } from "../components/main"
import { MainActionsType } from "../components/main/business"
import { ExternalToolSteps } from "../data/enums"
import { useContainer } from "../data/repositories"

const finishCalculationProcess = (): MainActionsType => ({ type: "DAT_EXTERNAL_TOOL_DONE" })

const updateExternPage = (page: ExternalToolSteps): MainActionsType => ({ type: "UPDATE_EXTERN_PAGE", payload: page })

const loadDocumentByContactId =
    (contractId: string, page?: ExternalToolSteps): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        if (!contractId) {
            return
        }

        useContainer()
            .action("getDatDocument")(contractId)
            .then(
                (response) => {
                    dispatch({ type: "LOAD_NEW_DOCUMENT", payload: { page, externalData: response, contractId } })
                },
                () => dispatch({ type: "LOAD_NEW_DOCUMENT_ERROR" })
            )
    }

export const ExternActions = {
    loadDocumentByContactId,
    finishCalculationProcess,
    updateExternPage,
}
