import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { ImagePathsResponse, ImagePathsRequest } from "./model"
import { mapImagePathResponse } from "./mapper"

export function load2DImages(request: ImagePathsRequest) {
    const url = `${getServiceUrl()}/GetImagePaths`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<ImagePathsResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then(
            (response) => {
                const imagePaths = mapImagePathResponse(response)
                imagePaths ? resolve(imagePaths) : reject()
            },
            reject
        )
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.vehicleServiceUrl
}