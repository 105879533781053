import { useAddCatalogPartList } from "../../data/hooks/workTaskBasket/parts/useAddCatalogPartList "
import { useAddCustomPartList } from "../../data/hooks/workTaskBasket/parts/useAddCustomPartList"
import { useAddOePartList } from "../../data/hooks/workTaskBasket/parts/useAddOePartList"
import { useAddWholesalerPartList } from "../../data/hooks/workTaskBasket/parts/useAddWholesalerPartList"
import { useBasketUpdateWorkflow } from "../../data/hooks/workTaskBasket/workflow/useBasketUpdateWorkflow"
import { useRemovePartList } from "../../data/hooks/workTaskBasket/parts/useRemovePartList"
import { useInvalidateWorkTaskBasketQueries } from "../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

async function resolve() {}

export function useBasketParts() {
    const { invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const addCustomPartList = useAddCustomPartList(handleBasketUpdateWorkflow)
    const addCatalogPartList = useAddCatalogPartList(handleBasketUpdateWorkflow)
    const addOePartList = useAddOePartList(handleBasketUpdateWorkflow)
    const addWholesalerPartList = useAddWholesalerPartList(handleBasketUpdateWorkflow)
    const removePartList = useRemovePartList((workTaskId, response) => {
        invalidatePartsRequests(workTaskId)
        return handleBasketUpdateWorkflow(workTaskId, response)
    })

    return { addCustomPartList, addCatalogPartList, addOePartList, addWholesalerPartList, removePartList }
}
