import { ajax, getStoredAuthorization } from "@tm/utils"
import { useQuery } from "react-query"
import { ECalcOrigin } from "@tm/models"
import { getBundleParams } from "../../utils"
import { GenericHistoryEntry, HistorySummary } from "../../data/models"
import { GetSavedCalculationsRequest } from "../../data/repositories/fastcalculator-getSavedCalculations/model"
import { getSavedCalculations } from "../../data/repositories/fastcalculator-getSavedCalculations"

function getFastCalculatorHistory(vehicleId: string | undefined, tecDocTypeId: number | undefined) {
    if (vehicleId && tecDocTypeId) {
        const request: GetSavedCalculationsRequest = {
            kTypNr: tecDocTypeId!,
            vehicleId: vehicleId!,
            origin: ECalcOrigin.FastService,
            doNotShowOwnCalculations: true,
        }

        return getSavedCalculations(request)
    }

    return []
}

const getFastServiceHistorySummary = (vin: string | undefined, vehicleId: string | undefined): Promise<Array<HistorySummary>> => {
    if (vin && vehicleId) {
        const url = `${getBundleParams().historyServiceUrl}/GetHistoryInfoList`
        const authorization = getStoredAuthorization()
        const body = { VINNumber: vin, vehicleId }

        return new Promise<Array<HistorySummary>>((resolve, reject) =>
            ajax({ url, body, authorization }).then((data) => resolve(data?.result || []), reject)
        )
    }

    return Promise.resolve([])
}

function getHistory(vin: string | undefined, vehicleId: string | undefined, tecDocTypeId: number | undefined) {
    return Promise.all([getFastServiceHistorySummary(vin, vehicleId), getFastCalculatorHistory(vehicleId, tecDocTypeId)])
}

export function useFastServiceHistorySummary(vin: string | undefined, vehicleId: string | undefined, tecDocTypeId: number | undefined) {
    const query = useQuery(["fastServiceHistorySummary", { vin, vehicleId, tecDocTypeId }], () => getHistory(vin, vehicleId, tecDocTypeId))

    let historyList: Array<GenericHistoryEntry> = []
    if (query.data) {
        const [fastService, fastCalculator] = query.data
        historyList = [...fastService, ...fastCalculator]

        historyList.sort((a, b) => {
            return new Date(b.dateTime)?.getTime() - new Date(a.dateTime)?.getTime()
        })
    }

    return {
        historyList,
        isLoading: query.isLoading,
    }
}
