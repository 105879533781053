import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { Suspense } from "react"

import { getImageTypeByVehicleType } from "../../../business"
import { useModelSeriesDetails } from "../../../data/hooks"
import SelectionsCard from "./SelectionsCard"

type Props = {
    vehicleType: VehicleType
    manufacturerId: number | undefined
    modelSeriesId: number | undefined
    backUrl: string
    isCurrent: boolean
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={<SelectionsCard isLoading isCurrent={props.isCurrent} />}>
            <CardModelSeries {...props} />
        </Suspense>
    )
}

function CardModelSeries({ vehicleType, manufacturerId, modelSeriesId, isCurrent, backUrl }: Props) {
    const { translateText } = useLocalization()
    const modelSeries = useModelSeriesDetails({ vehicleType, manufacturerId, modelSeriesId })

    return (
        <SelectionsCard
            isCurrent={isCurrent}
            image={modelSeries?.modelSeriesThumbnail}
            imageFallbackType={getImageTypeByVehicleType(vehicleType)}
            description={!modelSeries ? translateText(73) : undefined}
            content={modelSeries?.description}
            resetUrl={modelSeries ? backUrl : undefined}
        />
    )
}
