import { Alert, Icon, Box, FormControlLabel, Radio, RadioGroup, styled, Typography } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { TextField, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ESubModules, SystemType, UserModuleType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { getCostPerCalculation, getCurrencyFromUserContext, getSystemTypeFromUserContext } from "@tm/utils"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { managerSelector, summarySelector } from "../../selectors"
import { Wrapper } from "../../styles/_shared"
import { MainActions } from "../main/business"
import { validateFields } from "./business/helper"
import { CustomerFields, Header, VehicleFields } from "./components"

export default function SummaryComponent() {
    const { translateText, currency } = useLocalization()
    const user = useUser()
    const { fields, useCatalogHourlyRates, isGermanOrAustrianVehicle } = useSelector(summarySelector)
    const { vehicle } = useSelector(managerSelector)
    const { processName } = fields
    const calculationCost = getCostPerCalculation(UserModuleType.TMDamageCalculationDat, ESubModules.ShowSk_DATFIOnline, user?.userContext)
    const currencyCode = getCurrencyFromUserContext(user?.userContext)
    const modelState = validateFields(fields, translateText, isGermanOrAustrianVehicle)
    const costAlertText = translateText(12408) + currency(calculationCost ?? 0, currencyCode)

    const actions = useActions(
        MainActions,
        "startCalculation",
        "changeStep",
        "loadVehicleDetails",
        "initCustomer",
        "checkVehicleDatInfo",
        "handleUseNextHourlyRates",
        "updateFieldValue",
        "validateVin",
        "acceptConditions"
    )

    useEffect(() => {
        !!vehicle && actions.loadVehicleDetails(vehicle)
    }, [vehicle])

    const renderHourlySelection = () => {
        if (getSystemTypeFromUserContext(user?.userContext) === SystemType.Next) {
            return (
                <FieldsArea>
                    <Typography fontSize="1em">{translateText(1900)}:</Typography>
                    <RadioGroup sx={{ p: 1, pl: 4 }}>
                        <FormControlLabel
                            control={
                                <Radio
                                    sx={{ p: 0 }}
                                    size="small"
                                    checked={useCatalogHourlyRates}
                                    onChange={() => actions.handleUseNextHourlyRates(true)}
                                />
                            }
                            label={translateText(993)}
                        />
                        <FormControlLabel
                            control={
                                <Radio
                                    sx={{ p: 0 }}
                                    size="small"
                                    checked={!useCatalogHourlyRates}
                                    onChange={() => actions.handleUseNextHourlyRates(false)}
                                />
                            }
                            label={`DAT-${translateText(1901)}`}
                        />
                    </RadioGroup>
                </FieldsArea>
            )
        }
    }

    const renderTransaction = () => (
        <Box>
            <Title>
                <Icon name="damage-calculation" height="3em" />
                <Typography fontSize="large">{translateText(1462)}</Typography>
            </Title>

            <Fields>
                <FieldsArea>
                    <TextField
                        floatingLabel
                        label={translateText(1471)}
                        value={processName}
                        onChange={(value) => actions.updateFieldValue("processName", value)}
                        autoFocus={!!processName?.length}
                        path={["processName"]}
                        modelState={modelState}
                    />
                </FieldsArea>

                {renderHourlySelection()}
            </Fields>
        </Box>
    )

    const renderCostAlert = () => (
        <StyledCostAlert>
            <Toolbar>
                <Alert color="warning" icon={<Icon name="info-filled" />} title={costAlertText} />
            </Toolbar>
        </StyledCostAlert>
    )

    if (!vehicle) {
        return null
    }

    return (
        <Wrapper>
            {!!calculationCost && renderCostAlert()}

            <Header />

            <Box>
                {renderTransaction()}

                <Section>
                    <VehicleFields />
                    <CustomerFields />
                </Section>
            </Box>
        </Wrapper>
    )
}

const Section = styled(Box)(({ theme }) => ({
    margin: theme.spacing(1, 0),
}))

const StyledCostAlert = styled(Box)(() => ({
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    position: "absolute",
    right: 0,
    top: ".5em",
}))

const Title = styled(Box)(() => ({
    display: "flex",
    gap: ".5em",
    padding: ".5em",
    alignItems: "center",
}))

const Fields = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    flex: 1,
    margin: theme.spacing(0.5, 0.5, 0.5, 2),
    alignItems: "center",
    flexWrap: "wrap",
    flexDirection: "column",
}))

const FieldsArea = styled(Box)(({ theme }) => ({
    display: "flex",
    flex: 1,
    flexBasis: "100%",
    flexDirection: "column",
    margin: theme.spacing(0.5, 0),
    alignSelf: "flex-start",
}))
