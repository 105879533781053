import { border, px } from "csx"
import { useMemo } from "react"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Table, TableProps } from "@tm/controls"

type Props<T> = TableProps<T> & {}

export default function StyledTable<T>(rest: Props<T>) {
    const style = useMemo(() => getStyle(), [])

    const props: TableProps<T> = { ...rest, className: classes(style.table, rest.className) }
    return <Table {...props} />
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        table: {
            $nest: {
                "&.fancy-list": {
                    $nest: {
                        ".fancy-list__block": {
                            flex: 1,
                        },
                    },
                },
                "&.field-error": {
                    $nest: {
                        ".fancy-list__item:first-of-type": {
                            border: border({
                                width: px(1),
                                style: "solid",
                                color: theme.colors.danger,
                            }),
                        },
                    },
                },
            },
        },
    })(StyledTable)
}
