import { useQueryClient } from "react-query"
import { useWorkTaskBasketStore } from "./useWorkTaskBasketStore"
import { ORDER_OPTIONS_KEY } from "../../useOrderOptions"

export function useInvalidateWorkTaskBasketQueries() {
    const queryClient = useQueryClient()
    const [
        invalidateWorkTaskBasket,
        invalidateWorkEstimation,
        invalidateBasketErpInfo,
        invalidateBasketCalculation,
        invalidateBonusPointsCalculation,
    ] = useWorkTaskBasketStore((store) => [
        store.invalidateWorkTaskBasket,
        store.invalidateWorkEstimation,
        store.invalidateBasketErpInfo,
        store.invalidateBasketCalculation,
        store.invalidateBonusPointsCalculation,
    ])

    // From the settings page when a setting within the Repair times sections is made, all current queries must be inbalidated
    function invalidateWorksRequests(workTaskId?: string) {
        invalidateWorkTaskBasket(workTaskId)
        invalidateWorkEstimation(workTaskId)
        invalidateBasketCalculation(workTaskId)
    }

    function invalidatePartsRequests(workTaskId?: string) {
        invalidateWorkTaskBasket(workTaskId)
        queryClient.invalidateQueries([ORDER_OPTIONS_KEY, workTaskId])
        invalidateBasketErpInfo(workTaskId)
        invalidateBasketCalculation(workTaskId)
        invalidateBonusPointsCalculation(workTaskId)
    }

    function invalidateAllWorkTaskBasketRequests(workTaskId: string) {
        invalidateWorksRequests(workTaskId)
        invalidatePartsRequests(workTaskId)
    }

    return { invalidateWorksRequests, invalidatePartsRequests, invalidateAllWorkTaskBasketRequests }
}
