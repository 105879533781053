import { ErrorType } from "../models/error"
import { ErrorState, ErrorStore } from "./model"

export function setError(state: ErrorStore, error: ErrorType): Partial<ErrorStore> {
    return {
        ...state,
        isError: error.isError,
        errorMessage: error.message,
    }
}

export function clearError(initialState: ErrorState): Partial<ErrorStore> {
    return initialState
}
