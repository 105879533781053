import { BasketBundleParams, SettingsBundleParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { BasketMemoDefaults } from "./components/user-settings/components/shopping-basket/BasketMemoOptions"

export const version = {
    name: "settings",
    version: "1.0",
}

let bundleParams: SettingsBundleParams

export function initSettingsBundle(params: any) {
    bundleParams = params

    // TODO: find a better way to access params from other bundles
    window.setTimeout(() => {
        BasketMemoDefaults.onlyOnVehicleParts = Morpheus.getParams<BasketBundleParams>("basket").workTaskInfoOnlyForVehicleParts ?? false
    }, 0)

    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
