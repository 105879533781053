import { Box, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ManufacturerBase, VehicleType } from "@tm/models"
import { useHistory, useRouteMatch } from "react-router"
import TopManufacturerCards from "./top-manufacturer-cards"
import { getVehicleIdentificationUrl, getVehicleIdentificationUrlWithLookup } from "../../helpers/routing"

type Props = {
    vehicleType: VehicleType
}

export function TopManufacturer(props: Props) {
    const { vehicleType } = props
    const { translate, translateText } = useLocalization()
    const match = useRouteMatch()
    const history = useHistory()

    function handleTopManufacturerSelect(manufacturer: ManufacturerBase) {
        history.push(
            getVehicleIdentificationUrlWithLookup(match.params, {
                vehicleType,
                manufacturerId: manufacturer.id,
            })
        )
    }

    return (
        <Box display="flex" mt={2} flexDirection="column">
            <div className="fav-manufactureres-subtitle subtitle subtitle--xs">{translateText(1078)}</div>
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <TopManufacturerCards vehicleType={vehicleType} onSelect={handleTopManufacturerSelect} imageHeight="50px" />
                <Box p="0.5em">
                    <Button sx={{ height: "55px" }} href={getVehicleIdentificationUrl(match.params, { vehicleType })}>
                        {translate(869)}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
