import { WorkCategory, CustomWork, VatRate } from "@tm/models"
import { CustomRepairTimeItem } from "../model/custom-items"

export function createCustomWork(
    customWorkNumber: string,
    categoryOfWork: WorkCategory,
    description: string,
    time: number | undefined,
    hourlyRate: number | undefined,
    fixedPriceValue: number | undefined,
    rebate: number | undefined,
    surcharge: number | undefined,
    vatRate: VatRate | undefined
): CustomWork {
    return {
        customWorkNumber,
        categoryOfWork,
        description,
        time,
        hourlyRate,
        fixedPriceValue,
        rebate,
        surcharge,
        vatRate,
    }
}

export function mapCustomRepairTimeItemToCustomWork(repairTime: CustomRepairTimeItem) {
    const { workId, category, description, workTime, customHourlyRate, fixedPriceValue, vatRate } = repairTime
    return createCustomWork(workId, category, description, workTime, customHourlyRate, fixedPriceValue, 0, 0, vatRate)
}
