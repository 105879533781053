import { useQuery } from "react-query"
import { BookingDetailsRequest } from "../data/models"
import { getBookingDetailsResponse } from "../data/repositories"

export function useGetBookingDetails(bookingId: number) {
    const request: BookingDetailsRequest = {
        bookingId,
    }

    const { data, isLoading } = useQuery([request.bookingId], () => getBookingDetailsResponse(request), {
        staleTime: 10 * 60 * 1000, // 10 Minutes
    })
    return { bookingDetails: data, isLoading }
}
