import { Button } from "@tm/controls"
import { useState, useEffect } from "react"
import { classes } from "@tm/utils"
import { getComponentStyles } from "./styles"
import { bundleChannel } from "../../../../../../../business"

type Props = {
    onTakePhoto(): void
}

export default function Shutter({ onTakePhoto }: Props) {
    const classNames = getComponentStyles()

    const [timerMethod, setTimerMethod] = useState<0 | 3 | 10>(0)
    const [shutterTimer, setShutterTimer] = useState(0)

    useEffect(() => {
        setShutterTimer(0)
        let interval: undefined | ReturnType<typeof setInterval>

        const unsub1 = bundleChannel().subscribe("VRC_SCAN_SHUTTER_TIMER_START", () => {
            setShutterTimer(timerMethod)

            interval = setInterval(() => {
                setShutterTimer((prev) => {
                    if (prev <= 1) {
                        interval && clearInterval(interval)
                        onTakePhoto()
                        return 0
                    }
                    return prev - 1
                })
            }, 1000)
        })

        const unsub2 = bundleChannel().subscribe("VRC_SCAN_SHUTTER_TIMER_STOP", () => {
            interval && clearInterval(interval)
            setShutterTimer(0)
        })

        return () => {
            interval && clearInterval(interval)
            unsub1()
            unsub2()
        }
    }, [timerMethod, onTakePhoto])

    const handleClick = () => {
        if (timerMethod === 0) {
            onTakePhoto()
        } else if (shutterTimer === 0) {
            bundleChannel().publish("VRC_SCAN_SHUTTER_TIMER_START", {})
        } else {
            bundleChannel().publish("VRC_SCAN_SHUTTER_TIMER_STOP", {})
        }
    }

    return (
        <div className={classNames.buttonWrapper}>
            <Button
                layout={["circle"]}
                onClick={handleClick}
                icon={shutterTimer || timerMethod ? undefined : "camera"}
                size="xl"
                className={classNames.shutterButton}
            >
                {shutterTimer || timerMethod || undefined}
            </Button>

            <Button
                layout={["ghost"]}
                icon="self-timer-0"
                onClick={() => setTimerMethod(0)}
                className={classes(classNames.timerButton, timerMethod === 0 && classNames.timerButtonActive)}
            />

            <Button
                layout={["ghost"]}
                icon="self-timer-3"
                onClick={() => setTimerMethod(3)}
                className={classes(classNames.timerButton, timerMethod === 3 && classNames.timerButtonActive)}
            />

            <Button
                layout={["ghost"]}
                icon="self-timer-10"
                onClick={() => setTimerMethod(10)}
                className={classes(classNames.timerButton, timerMethod === 10 && classNames.timerButtonActive)}
            />
        </div>
    )
}
