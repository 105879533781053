import { Box, Button } from "@tm/components"
import { Popover } from "@tm/controls"
import { OrderVoucherList, VoucherCustomerInfo } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { useState } from "react"

export type Props = {
    order: OrderVoucherList
}

export default function CustomerInfo({ order }: Props) {
    const { workTaskInfos, orderVoucherId } = order
    const workTasksWithCustomerInfo = workTaskInfos.filter((wt) => wt.customerInfo)
    const worktasks = workTasksWithCustomerInfo.length

    const [extraCustomers, setExtraCustomers] = useState<string>()
    const expandPopover = extraCustomers === orderVoucherId

    if (!worktasks || !workTasksWithCustomerInfo[0].customerInfo) {
        return <div />
    }

    function handleExtraCustomersClick(orderId: string) {
        if (extraCustomers && extraCustomers === orderId) {
            setExtraCustomers(undefined)
        } else {
            setExtraCustomers(orderId)
        }
    }

    function renderCustomerInfo(customer: VoucherCustomerInfo) {
        return (
            <>
                {customer.customerNumber ? <>{customer.customerNumber}</> : undefined}
                {customer.displayName ? <>{customer.displayName}</> : undefined}
            </>
        )
    }

    const customer = workTasksWithCustomerInfo[0].customerInfo
    return (
        <Box key={customer.id}>
            {renderCustomerInfo(customer)}
            {worktasks > 1 && (
                <Box>
                    <Button variant="outlined" onClick={() => handleExtraCustomersClick(order.orderVoucherId)}>
                        + {worktasks - 1}
                    </Button>
                    {workTaskInfos.length > 1 && (
                        <Popover
                            active={expandPopover}
                            alignArrow="center"
                            onElementRef={(ref: HTMLElement) => {
                                ref.style.transform = `translateX(-${ref.getBoundingClientRect().width / 2}px)`
                                ref.style.left = "50%"
                            }}
                        >
                            {workTaskInfos
                                .map((x) => x.customerInfo)
                                .filter(notUndefinedOrNull)
                                .slice(1)
                                .map((x) => (
                                    <Box key={x.id}>{renderCustomerInfo(x)}</Box>
                                ))}
                        </Popover>
                    )}
                </Box>
            )}
        </Box>
    )
}
