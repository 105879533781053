import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../utils"
import { AppLink, ChatToken } from "./model"

export * from "./model"

export function getChatToken() {
    const url = `${getBundleParams().authorityServiceUrl}/GetChatToken`
    const authorization = getStoredAuthorization()

    return ajax<{ token: ChatToken; apiKey: string }>({ url, body: {}, authorization, method: "GET" })
}

export function getChatInvitationLinks() {
    const url = `${getBundleParams().authorityServiceUrl}/GetChatInvitationLinks`
    const authorization = getStoredAuthorization()

    return ajax<{ appLinks: Array<AppLink> }>({ url, body: {}, authorization, method: "GET" }).then(
        (response) => response?.appLinks || [],
        () => []
    )
}
