import { useMemo, useState } from "react"
import { useMutation } from "react-query"
import { useUser } from "@tm/context-distribution"
import { Box, Button, Dialog, Icon, IconButton, TextField, Typography, LoadingContainer } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CredentialInputKeys } from "../../models"
import { InputStatusCheck } from "../shared/InputStatusCheck"
import { Repositories } from "../../data"
import { mainActions } from "../../data/state"
import { getBundleParams } from "../../utils"

type Props = {
    onLoginSuccess?: () => void
    onLoginDenied?: () => void
}

export default function LoginDialog(props: Props) {
    const { onLoginSuccess, onLoginDenied } = props
    const user = useUser()
    const { translateText } = useLocalization()
    const [open, setOpen] = useState(true)
    const [credentials, setCredentials] = useState<{ [key in CredentialInputKeys]: string }>({ APIKey: "", APIKeyID: "" })

    const loginToSindri = useMutation({
        mutationFn: ({ APIKey, APIKeyID }: { APIKey: string; APIKeyID: string }) => Repositories.SindriLogin(APIKey, APIKeyID),
        onSuccess(data, variables) {
            mainActions.sindriLogin({ APIKey: variables.APIKey, APIKeyID: variables.APIKeyID }, data)
            user.setUserSetting("SINDRI_SETTINGS", { APIKeyID: variables.APIKeyID, APIKey: variables.APIKey })
            onLoginSuccess?.()
        },
    })

    const loginDisabled = useMemo(
        () => credentials.APIKey.trim().length === 0 || credentials.APIKeyID.trim().length === 0 || loginToSindri.isLoading,
        [credentials, loginToSindri]
    )

    const isLoading = !user.userContext || loginToSindri.isLoading

    function closeDialog() {
        setOpen(false)
        onLoginDenied?.()
    }

    async function handleLogin() {
        if (loginDisabled) {
            return
        }

        loginToSindri.mutate({ APIKey: credentials.APIKey, APIKeyID: credentials.APIKeyID })
    }

    function handleInputChange(key: CredentialInputKeys, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {
            target: { value },
        } = event

        setCredentials((prev) => ({
            ...prev,
            [key]: value,
        }))
    }

    function handleOpenPrivacyPolicy() {
        const { urlPrivacyPolicy } = getBundleParams()
        if (!urlPrivacyPolicy) {
            return
        }

        window.open(urlPrivacyPolicy, "_blank")
    }

    return (
        <Dialog open={open} onOutsideClick={closeDialog} fullWidth maxWidth="sm" position="top">
            <LoadingContainer isLoading={isLoading}>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="11px">
                    <Typography variant="h2">{translateText(13517)}</Typography>
                    <Box display="flex" alignItems="center" gap="13px">
                        <IconButton onClick={closeDialog}>
                            <Icon name="close" />
                        </IconButton>
                        <Button color="success" onClick={handleLogin} disabled={loginDisabled}>
                            <Typography variant="h4">{translateText(13110)}</Typography>
                        </Button>
                    </Box>
                </Box>
                <Box>
                    <Typography variant="body2">{translateText(13518)}</Typography>
                    <Box display="flex" flexDirection="column" gap="18px" marginTop="13px" width="70%">
                        <TextField
                            fullWidth
                            size="large"
                            label={translateText(13519)}
                            value={credentials.APIKey}
                            onChange={(event) => handleInputChange(CredentialInputKeys.APIKey, event)}
                        />

                        <TextField
                            fullWidth
                            size="large"
                            label={translateText(13520)}
                            value={credentials.APIKeyID}
                            onChange={(event) => handleInputChange(CredentialInputKeys.APIKeyID, event)}
                        />
                        {loginToSindri.isError && (
                            <Typography variant="body3" color="error">
                                {translateText(13522)}
                            </Typography>
                        )}
                        <Box alignItems="center" display="flex">
                            <Typography variant="body3">
                                {translateText(13521)}
                                <IconButton sx={{ width: "16px", height: "16px" }} onClick={handleOpenPrivacyPolicy}>
                                    <Icon
                                        height="16px"
                                        width="16px"
                                        sx={{ minHeight: "16px", minWidth: "16px", padding: "1px" }}
                                        name="linked-item"
                                    />
                                </IconButton>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </LoadingContainer>
        </Dialog>
    )
}
