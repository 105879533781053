import { Container, IModelContainer } from "@tm/nexus"
import { IDOPModel } from "../models"

import { orderPlates } from "./orderPlates"

export const DopKey = "dutch-order-plates"

export const initRepositories = () => {
    Container.register({
        name: DopKey,
        containerActions: {
            orderPlates,
        },
    })
}

export const useContainer = () => Container.getInstance(DopKey) as DopContainer

type DopContainerActions = { name: "orderPlates"; action: (req: IDOPModel) => Promise<IDOPModel> }

export type DopContainer = IModelContainer<void, DopContainerActions>
