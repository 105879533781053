import { encodeUniqueId } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenWorkTask(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openWorkTask } = data

    // rethink this
    if (openWorkTask && openWorkTask.workTaskId) {
        this.props.history.push(`/${encodeUniqueId(openWorkTask.workTaskId)}`)
    }
}
