import { ListResponse, ajax, getStoredAuthorization, createBufferedRequestFunction } from "@tm/utils"
import {
    GetMainServicesRequest,
    HasRepairTimesResponse,
    HasRepairTimesRequest,
    ShowRepairTimesByIdsRequest,
    ShowRepairTimesByIdsResponse,
    ProductGroup,
    GetMainServicesResponse,
    ShowManufacturerDivisionRequest,
    ShowManufacturerDivisionResponse,
    RepairTimeProvider,
    VehicleType,
    FittingPosition,
} from "@tm/models"
import { getRepairTimesServiceUrl, LoadProductGroupsRequest, SearchTreeNode, SearchTreeNodeLoadOptions } from ".."

export function loadSearchTreeNodes(body: SearchTreeNodeLoadOptions): Promise<ListResponse<SearchTreeNode>> {
    const url = `${getRepairTimesServiceUrl()}/SearchTreeNodes`
    const authorization = getStoredAuthorization()

    return new Promise((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => {
            const result: ListResponse<SearchTreeNode> = {
                requestNumber: body.requestNumber,
                pageIndex: body.pageIndex,
                pageSize: body.pageSize,
                data: data?.searchTreeNodes || [],
            }

            resolve(result)
        }, reject)
    )
}

export function loadMainServices(body: GetMainServicesRequest) {
    const url = `${getRepairTimesServiceUrl()}/MainServices`
    const authorization = getStoredAuthorization()

    return ajax<GetMainServicesResponse>({ url, body, authorization })
}

export function hasRepairTimes(request: HasRepairTimesRequest) {
    const url = `${getRepairTimesServiceUrl()}/HasRepairTimes`
    const authorization = getStoredAuthorization()
    const body: HasRepairTimesRequest = {
        ...request,
        productGroupIds: request.productGroupIds.sort((a, b) => a - b),
        repairTimeProvider: request.repairTimeProvider.sort((a, b) => a - b),
    }

    return ajax<HasRepairTimesResponse>({ url, body, authorization, method: "GET" })
}

type HasRepairTimesBufferedRequest = {
    repairTimeProvider: Array<RepairTimeProvider>
    modelId: number
    vehicleType: VehicleType
    productGroupId: number
    fittingSideFilter?: FittingPosition | undefined
}

export const hasRepairTimesBuffered = createBufferedRequestFunction({
    callService: (requests: Array<HasRepairTimesBufferedRequest>) => {
        let request: HasRepairTimesRequest | undefined

        requests.forEach((x) => {
            if (!request) {
                request = {
                    ...x,
                    productGroupIds: [x.productGroupId],
                    repairTimeProvider: [...x.repairTimeProvider],
                }
            } else {
                if (!request.productGroupIds.includes(x.productGroupId)) {
                    request.productGroupIds.push(x.productGroupId)
                }

                x.repairTimeProvider.forEach((provider) => {
                    if (!request?.repairTimeProvider.includes(provider)) {
                        request?.repairTimeProvider.push(provider)
                    }
                })
            }
        })

        return request ? hasRepairTimes(request) : Promise.reject()
    },
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return serviceResponse?.productGroups[request.productGroupId]
    },
})

export function getProductGroups(body: LoadProductGroupsRequest): Promise<Array<ProductGroup>> {
    const url = `${getRepairTimesServiceUrl()}/ProductGroups`
    const authorization = getStoredAuthorization()

    return new Promise((resolve, reject) => ajax({ url, body, authorization }).then((data) => resolve(data.productGroups || []), reject))
}

export function showRepairTimesByIds(body: ShowRepairTimesByIdsRequest) {
    const url = `${getRepairTimesServiceUrl()}/ShowRepairTimesByIds`
    const authorization = getStoredAuthorization()

    return ajax<ShowRepairTimesByIdsResponse>({ url, body, authorization, method: "POST" })
}

export function showManufacturerDivision(body: ShowManufacturerDivisionRequest) {
    const url = `${getRepairTimesServiceUrl()}/ShowManufacturerDivision`
    const authorization = getStoredAuthorization()

    return ajax<ShowManufacturerDivisionResponse>({ url, body, authorization })
}
