import { getModuleFromUserContext, getParameterFromModule, getParametersFromModule, useUser } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { useMemo } from "react"
import { getBundleParams } from "../../../utils"

export type OeInformationConfiguration = {
    enableOeInformation: boolean
    isVehicleDependentSearch?: boolean
    showReplacementChain?: boolean
    hideManufacturerLogo?: boolean
    showOePrice?: boolean
    transferToShoppingCart?: boolean
    displayVehicleOeCriteria?: boolean
}

export function createOeInformationConfiguration(userContext: UserContext): OeInformationConfiguration {
    const oeInformationModule = getModuleFromUserContext(userContext, UserModuleType.OeInformation)

    if (!oeInformationModule) {
        return {
            enableOeInformation: getBundleParams()?.enableOeInformation ?? false,
        }
    }

    const configs = getParametersFromModule(oeInformationModule, {
        showReplacementChain: ["TMReplacementChain", "boolean"],
        isVehicleDependentSearch: ["VehicleDependentSearch", "boolean"],
        hideManufacturerLogo: ["HideManufacturerLogo", "boolean"],
        displayVehicleOeCriteria: ["DisplayVehicleOeCriteria", "boolean"],
        showOePrice: ["ShowOEPrice", "boolean"],
        transferToShoppingCart: ["TransferToShoppingCart", "boolean"],
    })

    return {
        ...configs,
        showReplacementChain: configs.showReplacementChain || getParameterFromModule(oeInformationModule, "ReplacementChain", "boolean"),
        enableOeInformation: true,
    }
}

export function useOeInformationConfiguration() {
    const { userContext } = useUser()

    const oeInformation = useMemo(() => createOeInformationConfiguration(userContext), [userContext?.modules])

    return oeInformation
}
