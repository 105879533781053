import { useState } from "react"
import { NumberSelect } from "@tm/components"
import { ArticleForOe, NotFoundArticle } from "../../data/models/calculation"

type Props = {
    article: ArticleForOe | NotFoundArticle
    onChangeQuantity: (item: ArticleForOe | NotFoundArticle, quantity: number) => void
}

export default function QuantityDropdown({ article, onChangeQuantity }: Props) {
    const [selectedQuantity, setSelectedQuantity] = useState<number>()

    const handleChange = (value: number) => {
        if (value !== article.quantity) {
            setSelectedQuantity(value)
            onChangeQuantity(article, value)
        }
    }

    return (
        <NumberSelect
            value={article.quantity || selectedQuantity || 1}
            maxAmount={9999}
            minAmount={1}
            onValueChange={(amountItem) => {
                ~~amountItem !== ~~article.quantity && handleChange(amountItem)
            }}
        />
    )
}
