import { Fragment, useState } from "react"
import { Box, Divider, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { FastCalculator } from "@tm/data"
import { CalculationContext } from "../../../data/model"
import { BorderedCollapsible } from "../../_shared"
import HeaderActionBox from "./HeaderActionBox"
import { useFastCalculatorStore, useStoreContext } from "../../../state"
import { handleInputSubmit } from "../../../business"

export default function Header() {
    const instance = useStoreContext()
    const selectedCalcState = useFastCalculatorStore((state) => state.selectedCalcState)

    const {
        definedVehicleTypeLabel,
        definedMaintenanceSystemsLabel,
        definedMaintenanceIntervalsLabel,
        changeMaintenanceIntervalInput,
        changeMaintenanceSystemInput,
        changeVehicleTypeInput,
        selectedServices,
    } = selectedCalcState?.context as CalculationContext
    const { translateText } = useLocalization()
    const [opened, setOpened] = useState(false)

    if (!definedVehicleTypeLabel) {
        return null
    }

    function renderHeaderAppendix() {
        if (opened) {
            return null
        }

        return (
            <Box display="flex" alignItems="center" sx={{ userSelect: "none" }}>
                <Typography variant="body3" fontWeight="600">
                    {definedVehicleTypeLabel}
                </Typography>
                <LeftBorderedTypography variant="body3" fontWeight="600">
                    {definedMaintenanceSystemsLabel}
                </LeftBorderedTypography>
                <LeftBorderedTypography variant="body3" fontWeight="600">
                    {definedMaintenanceIntervalsLabel}
                </LeftBorderedTypography>
                <LeftBorderedTypography variant="body3" fontWeight="600">
                    {translateText(12539)}
                </LeftBorderedTypography>
            </Box>
        )
    }

    function handleButtonClick(input: FastCalculator.CalcInput) {
        handleInputSubmit(instance, input)
    }

    return (
        <StyledCollapsible name={translateText(13476)} renderHeaderAppendix={renderHeaderAppendix} onChangeOpen={setOpened}>
            <Box padding="0 16px">
                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr" gap="2em">
                    {changeVehicleTypeInput && (
                        <HeaderActionBox
                            title={translateText(99)}
                            buttonIcon="edit_change"
                            onButtonClick={() => handleButtonClick(changeVehicleTypeInput)}
                        >
                            <Typography margin="auto 0" paddingRight="2em" variant="body3" fontWeight="600">
                                {definedVehicleTypeLabel}
                            </Typography>
                        </HeaderActionBox>
                    )}
                    {changeMaintenanceSystemInput && (
                        <HeaderActionBox
                            title={translateText(689)}
                            buttonIcon="edit_change"
                            onButtonClick={() => handleButtonClick(changeMaintenanceSystemInput)}
                        >
                            <Typography variant="body3" fontWeight="600" margin="auto 0">
                                {definedMaintenanceSystemsLabel}
                            </Typography>
                        </HeaderActionBox>
                    )}
                    {changeMaintenanceIntervalInput && (
                        <HeaderActionBox
                            title={translateText(612)}
                            buttonIcon="edit_change"
                            onButtonClick={() => handleButtonClick(changeMaintenanceIntervalInput)}
                        >
                            <Typography variant="body3" fontWeight="600" margin="auto 0">
                                {definedMaintenanceIntervalsLabel}
                            </Typography>
                        </HeaderActionBox>
                    )}
                </Box>
                <Box padding="9px">
                    <Typography paddingBottom="9px" display="block" textTransform="uppercase" variant="label">
                        {translateText(12539)}
                    </Typography>
                    <Box display="flex" gap="0.5em">
                        {selectedServices?.map((selectedService, index) => (
                            <Fragment key={index}>
                                {index % 2 === 1 && <Divider sx={{ borderWidth: "1px" }} orientation="vertical" flexItem />}
                                <Typography variant="h4">{selectedService}</Typography>
                            </Fragment>
                        ))}
                    </Box>
                </Box>
            </Box>
        </StyledCollapsible>
    )
}

const LeftBorderedTypography = styled(Typography)({
    borderLeft: "2px solid #c3c3c3",
    paddingLeft: "0.5em",
    marginLeft: "0.5em",
})

const StyledCollapsible = styled(BorderedCollapsible)({
    ".collapsible__caption": {
        flex: "unset !important",
    },
})
