import * as React from "react"
import { bindSpecialReactMethods } from "@tm/utils"
import { Collapsible, CollapsibleProps, Loader, Button } from "@tm/controls"
import { LocalizationProps } from "@tm/localization"
import { Tooltip } from "@tm/components"
import { getBundleParams } from "../../../utils"

export type Props = CollapsibleProps &
    LocalizationProps & {
        loading?: boolean
        onToggleClip?(): void
        onReset?(): void
        headerAppendix?: React.ReactNode
    }

export default class extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleReset(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation()
        this.props.onReset?.()
    }

    handleToggleClip(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation()
        this.props.onToggleClip?.()
    }

    renderHeaderAppendix() {
        const {
            localization: { translateText },
        } = this.props

        const { hideFilterPaperClip } = getBundleParams()

        return (
            <>
                <Loader visible={this.props.loading || false} />
                {this.props.headerAppendix}
                {this.props.onReset && (
                    <Tooltip title={translateText(48)}>
                        <Button layout={["ghost"]} className="multi-btn__icon" icon="remove-filter" onClick={this.handleReset} />
                    </Tooltip>
                )}
                {this.props.onToggleClip && !hideFilterPaperClip && (
                    <Tooltip title={translateText(1899)}>
                        <Button layout={["ghost"]} className="multi-btn__icon filter-clip-icon" icon="paperclip" onClick={this.handleToggleClip} />
                    </Tooltip>
                )}
            </>
        )
    }

    render() {
        let className = "filter "
        className += this.props.className || ""

        return (
            <Collapsible
                {...this.props}
                initiallyOpened={this.props.initiallyOpened && !this.props.disabled}
                className={className}
                renderHeaderAppendix={this.renderHeaderAppendix}
            >
                {this.props.children}
            </Collapsible>
        )
    }
}
