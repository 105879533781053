import { CustomerInfo } from "../../../../../../data"
import { useCustomerById } from "../hooks/useCustomerSearch"
import { OpenWorkTaskDialogState } from ".."
import VehicleStart from "./vehicle-start"
import { ModalLoader } from "./_shared"
import { CustomerSearch } from "./customer-search"

export type Props = {
    chatId: string
    tmCustomerId: string | undefined
    customerInfo: CustomerInfo
    openOptions: OpenWorkTaskDialogState
}

export default function CustomerStart({ chatId, tmCustomerId, customerInfo, openOptions }: Props) {
    const { customerById, customerByIdLoading } = useCustomerById(tmCustomerId)

    if (customerByIdLoading) {
        return <ModalLoader />
    }
    if (customerById) {
        return (
            <VehicleStart chatId={chatId} openOptions={openOptions} customer={customerById} tmCustomerId={tmCustomerId} customerInfo={customerInfo} />
        )
    }

    return <CustomerSearch chatId={chatId} tmCustomerId={tmCustomerId} customerInfo={customerInfo} openOptions={openOptions} />
}
