import { CarLookupError, VrmLookupTextIds } from "@tm/models"

export function getDATErrorMessageTextId(error: CarLookupError): VrmLookupTextIds {
    // parse exception from V4 or external service
    if (error.errorMessage) {
        if (error.errorMessage.includes("DAT User not registered")) {
            return VrmLookupTextIds.UserNotRegistered
        }
        if (
            error.errorMessage.includes("manufacturer not supported") ||
            /VIN requests for manufacturers.*are not permitted/.test(error.errorMessage)
        ) {
            return VrmLookupTextIds.ManufacturerNotSupported
        }
        if (
            error.errorMessage.includes("Fahrgestellnummer ist unbekannt") ||
            error.errorMessage.includes("no result") ||
            error.errorMessage.includes("unknown vin")
        ) {
            return VrmLookupTextIds.NoResultForVin
        }
        if (error.errorMessage.includes("No module calls left")) {
            return VrmLookupTextIds.ContingentLimitReached
        }
    }

    return VrmLookupTextIds.UnknownError
}
