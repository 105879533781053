import {styled, Box, Icon, Loader, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useCheckHasMemo } from "@tm/utils"
import { Suspense } from "react"

function TechnicalNotesComponent() {
    const { renderMicro } = useMicro<IMicros>()
    const { translateText } = useLocalization()

    const vehicle = useWorkTask()?.workTask?.vehicle

    const { isMemoLoading } = useCheckHasMemo(true, vehicle?.manufacturer, vehicle?.tecDocTypeId, vehicle?.engineCode)

    if (isMemoLoading || !vehicle) {
        return <Loader />
    }

    return (
        <Box flex={1} m={"1em 0.5em"}>
            <Typography variant="h2">
                <StyledHeaderIcon name="atu-logo-filled" />
                {translateText(13026)}
            </Typography>

            {vehicle?.tecDocTypeId && vehicle?.engineCode && renderMicro("memo-tool", "memo-technical-notes", {
                isModal: true,
                vehicleId: vehicle?.id,
                modelId: vehicle?.tecDocTypeId,
                engineCode: vehicle?.engineCode,
            })}
        </Box>
    )
}

function TechnicalNotesWrapper() {
    return (
        <Suspense fallback={<Loader />}>
            <TechnicalNotesComponent />
        </Suspense>
    );
}
export const StyledHeaderIcon = styled(Icon)({
    width: "4em",
    marginRight: "0.4em",
    verticalAlign: "middle",
    opacity: 1
})

export default TechnicalNotesWrapper
