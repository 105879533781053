import { VoucherType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Collapsible } from "@tm/controls"
import { Loader, Card, Typography, Box, Stack } from "@tm/components"
import VoucherHeader from "../_shared/VoucherDetailsHeader"
import { useReturnDetails } from "../../../../business/hooks/useReturnDetails"
import ReturnsPartsTable from "./components/PartsTable/Index"

type Props = {
    voucherId: string | undefined
    onShowOrder(voucherId: string): void
}

export default function ReturnDetails({ voucherId, onShowOrder }: Props) {
    const { translateText } = useLocalization()
    const { returnDetails, returnDetailsLoading } = useReturnDetails(voucherId)

    if (returnDetailsLoading) {
        return <Loader />
    }

    if (!returnDetails) {
        return <Typography>{translateText(323)}</Typography>
    }

    const { items, voucherNumber, returnDate, returnRecipient, customerReturnNumber } = returnDetails

    return (
        <Stack spacing={1}>
            <VoucherHeader
                type={VoucherType.Return}
                orderDate={returnDate}
                returnRecipient={returnRecipient}
                voucherNumber={voucherNumber}
                customOrderNumber={customerReturnNumber}
            />
            <Card>
                <Collapsible name={translateText(1667)} initiallyOpened skin="dark">
                    <Box px={2} pb={1}>
                        <ReturnsPartsTable returnItems={items} onShowOrder={onShowOrder} isBigScreen />
                    </Box>
                </Collapsible>
            </Card>
        </Stack>
    )
}
