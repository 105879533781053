import { ReactElement, useEffect, useState } from "react"
import { matchPath } from "react-router"
import { style } from "typestyle"
import { useWorkTask } from "@tm/context-distribution"
import { Button, Dropdown, Headline, Popover, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { concat, RouteComponentProps, withRouter, useAvailableVehicleSearchOptions } from "@tm/utils"

import { getBundleParams } from "../../utils"
import { getAvailableVehicleTypes, getVehicleTypeName } from "../../helpers/modules"
import { getValidatedVehicleIdentificationParams, getVehicleIdentificationUrl } from "../../helpers/routing"
import { Actions, IActions, NavigationState } from "./business"
import { createPrintVehicleDetailsRequest, getVehiclePdfDocument } from "../../data/repositories/print"
import { PrintLayer } from "./components/print-layer"
import { useRegNoAndType } from "../details/hooks/useRegNoAndType"

type Props = RouteComponentProps<RouteParams> & {
    state: NavigationState
    actions: IActions
}

type RouteParams = {
    workTaskId: string
    vehicleType: string
    view: string
}

function NavigationComponent(props: Props) {
    const { translateText } = useLocalization()

    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()

    const { vehicleType } = getValidatedVehicleIdentificationParams(props.match.params)
    if (!vehicleType) {
        return null
    }

    const [identificationUrl, setIdentificationUrl] = useState(
        getVehicleIdentificationUrl({ workTaskId: props.match.params.workTaskId }, { vehicleType })
    )
    const [detailsUrl, setDetailsUrl] = useState<string>()

    const { workTask } = useWorkTask() ?? {}
    const localization = useLocalization()

    const [isPrintLoading, setIsPrintLoading] = useState(false)

    const [printingOptionsExpanded, setPrintingOptionsExpanded] = useState<boolean>(false)
    const { regNo, regNoType } = useRegNoAndType(
        vehicleType,
        workTask?.vehicle,
        props.location.search,
        availableVehicleSearches?.defaultVehicleLookup
    )

    useEffect(() => {
        if (matchPath(props.location.pathname, { path: getBundleParams().vehiclesRoute })) {
            setIdentificationUrl(props.location.pathname)
        } else if (matchPath(props.location.pathname, { path: getBundleParams().vehicleDetailsUrl })) {
            setDetailsUrl(props.location.pathname)
        }
    }, [props.location.pathname])

    const handleVehicleTypeChange = ({ vehicleType: newVehicleType }: { vehicleType: VehicleType }) => {
        if (newVehicleType !== vehicleType) {
            if (detailsUrl) {
                setDetailsUrl(undefined)
            }

            props.history.push(
                getVehicleIdentificationUrl(
                    { workTaskId: props.match.params.workTaskId },
                    {
                        vehicleType: newVehicleType,
                        manufacturerId: undefined,
                        mainModelSeriesId: undefined,
                        modelSeriesId: undefined,
                    }
                )
            )
        }
    }

    const availableVehicleTypes = getAvailableVehicleTypes()

    const identificationActive = props.match.params.view === "select"
    const detailsActive = props.match.params.view === "details"

    const handlePrintArticleDetails = (imageUrl: string) => {
        if (props.state.vehicle) {
            setIsPrintLoading(true)
            const request = createPrintVehicleDetailsRequest(
                props.state.printOptions,
                localization.language,
                props.state.vehicle,
                imageUrl,
                props.state.manufacturerData,
                regNo,
                regNoType
            )

            if (request) {
                getVehiclePdfDocument(request)
                    .then(() => {
                        setIsPrintLoading(false)
                    })
                    .catch((ex) => {
                        // eslint-disable-next-line no-console
                        console.log("print error: ", ex)
                        setIsPrintLoading(false)
                    })
            } else {
                setIsPrintLoading(false)
            }
        }
    }

    function handlePrintingOptionsClick() {
        setPrintingOptionsExpanded(!printingOptionsExpanded)
    }

    function renderPrintToolbarArea() {
        return (
            <Toolbar title={translateText(49)}>
                <div style={{ position: "relative" }}>
                    <Button onClick={handlePrintingOptionsClick} disabled={!detailsUrl}>
                        {translateText(49)}
                    </Button>
                    <Popover
                        active={printingOptionsExpanded}
                        className="printing-options"
                        onOutsideInteraction={() => setPrintingOptionsExpanded(false)}
                    >
                        <PrintLayer
                            handlePrintArticleDetails={handlePrintArticleDetails}
                            onPrintOptionsChanged={props.actions.onPrintOptionsChanged}
                            isPrintLoading={isPrintLoading}
                            navigationState={props.state}
                            printOptions={props.state.printOptions}
                        />
                    </Popover>
                </div>
            </Toolbar>
        )
    }

    if (getBundleParams().nextLight) {
        return (
            <div className="tk-vehicle navigation">
                <div className={style({ display: "flex", alignItems: "center" })}>
                    <Headline size="s" className={style({ paddingRight: "1rem !important" })}>
                        {translateText(99)}
                    </Headline>
                </div>
                {renderPrintToolbarArea()}
            </div>
        )
    }

    return (
        <div className="tk-vehicle navigation">
            <div className={style({ display: "flex", alignItems: "center" })}>
                <Headline size="s" className={style({ paddingRight: "1rem !important" })}>
                    {translateText(99)}
                </Headline>
                {availableVehicleTypes.length > 1 && (
                    <Dropdown
                        className={style({
                            $nest: {
                                ".btn__content": { marginRight: "0.5rem" },
                            },
                        })}
                        items={availableVehicleTypes.map((x) => ({
                            vehicleType: x,
                        }))}
                        value={{ vehicleType }}
                        itemView={DropdownItemView}
                        layout={["ghost", "iconRight"]}
                        size="xl"
                        onChange={handleVehicleTypeChange}
                    />
                )}
            </div>
            <Toolbar title={translateText(176)}>
                <Button
                    icon="menu"
                    linkTo={identificationUrl}
                    skin={identificationActive ? "primary" : undefined}
                    className={identificationActive ? "is-active" : undefined}
                >
                    {translateText(68)}
                </Button>
                <Button
                    icon="details"
                    disabled={!detailsUrl}
                    linkTo={detailsUrl}
                    skin={detailsActive ? "primary" : undefined}
                    className={concat(" ", !detailsUrl && "btn--disabled", detailsActive && "is-active")}
                >
                    {translateText(43)}
                </Button>
            </Toolbar>
            {renderPrintToolbarArea()}
        </div>
    )
}

type ItemProps = {
    vehicleType: VehicleType
}

function DropdownItemView(props: ItemProps): ReactElement | null {
    const { translateText } = useLocalization()
    return <>{getVehicleTypeName(props.vehicleType, translateText)}</>
}

export default connectComponent(Actions, withRouter(NavigationComponent))
