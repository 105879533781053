import { ArticleNumber } from "@tm/components"
import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { createQueryString, renderRoute, useOpenArticleDetails } from "@tm/utils"
import { useHistory, useParams } from "react-router"
import { useUser } from "@tm/context-distribution"
import { getBundleParams } from "../../utils"
import { CisArticleType } from "../../business/model"

type Props = {
    articleType: CisArticleType
    supplierArticleNumber?: string
    productGroupId?: number
    supplierId?: number
    wholesalerArticleNumber?: string
    onOpenDetails?(): void
}

export default function ArticleNumberComponent(props: Props) {
    const { supplierArticleNumber, productGroupId, supplierId, wholesalerArticleNumber, articleType, onOpenDetails } = props
    const { partsDetailsUrl, articleSearchUrl } = getBundleParams()
    const matchParams = useParams()
    const history = useHistory()
    const userContext = useUser()?.userContext
    const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()

    let disabled = true
    if (supplierArticleNumber || (productGroupId && supplierId && supplierArticleNumber)) {
        disabled = false
    }

    const handleOpenArticleDetails = useOpenArticleDetails({
        productGroupId,
        supplierArticleNumber,
        supplierId,
        partsDetailsUrl,
        openModal: articleDetailsInModal || onOpenDetails ? Morpheus.showView : undefined,
    })

    function handleArticleNumberClick(query?: string) {
        if (onOpenDetails) {
            onOpenDetails()
            handleOpenArticleDetails()
        } else if (articleSearchUrl && query) {
            const url = renderRoute(articleSearchUrl, matchParams) + createQueryString({ query })
            history.push(url)
        }
    }

    if (articleType === "wholesaler" && wholesalerArticleNumber) {
        return (
            <ArticleNumber
                articleNumber={wholesalerArticleNumber}
                articleNumberType="wholesaler"
                disabled={disabled}
                omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                onClick={() => handleArticleNumberClick(wholesalerArticleNumber)}
            />
        )
    }
    if (articleType === "supplier" && supplierArticleNumber) {
        return (
            <ArticleNumber
                articleNumber={supplierArticleNumber}
                articleNumberType="supplier"
                disabled={disabled}
                omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                onClick={() => handleArticleNumberClick(supplierArticleNumber)}
            />
        )
    }
    return <></>
}
