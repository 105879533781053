import { useLocalization } from "@tm/localization"
import { Warehouse, DispatchType, DispatchConditions, DispatchCosts } from "@tm/models"
import { Text } from "@tm/controls"
import SimpleTourTable from "../../../../_shared/SimpleTourTable"

type Props = {
    warehouse: Warehouse
}

export default function WarehouseDetails({ warehouse }: Props) {
    const { translateText, currency } = useLocalization()

    const renderCosts = (costs?: DispatchCosts) => {
        if (!costs) {
            return null
        }

        return <div>{`${translateText(1292)}: ${currency(costs.value, costs.currencySymbol || "")}`}</div>
    }

    const renderConditions = (conditions?: DispatchConditions, costs?: DispatchCosts) => {
        if (!conditions) {
            return null
        }

        return (
            <div>
                {conditions.basicWeightKg && `${translateText(1289)}: ${conditions.basicWeightKg}`}
                <br />
                {conditions.pricePerAdditionalKg &&
                    `${translateText(1290)}: ${currency(conditions.pricePerAdditionalKg, costs ? costs.currencySymbol || "" : "")}`}
                <br />
                {conditions.carriageLimit && `${translateText(1291)}: ${conditions.carriageLimit}`}
            </div>
        )
    }

    const renderDispatchTypes = (title: string, types?: Array<DispatchType>) => {
        if (!types) {
            return null
        }

        return types.map((type, id) => {
            return (
                <div className="warehouse-details__item" key={id}>
                    <Text size="l" modifiers="light">
                        {title}
                    </Text>
                    {renderCosts(type.costs)}
                    <br />
                    {renderConditions(type.dispatchConditions, type.costs)}
                    {type.tours && (
                        <>
                            <br />
                            <SimpleTourTable
                                data={[{ title: translateText(789), tours: type.tours }]}
                                transparentBackground
                                whiteText
                                transparentHighlight
                            />
                        </>
                    )}
                </div>
            )
        })
    }

    return (
        <div className="warehouse-details">
            {renderDispatchTypes(translateText(765), warehouse.standardDelivery)}
            {renderDispatchTypes(translateText(1294), warehouse.collection)}
            {renderDispatchTypes(translateText(1293), warehouse.dayExpressDelivery)}
            {renderDispatchTypes(translateText(1295), warehouse.nightExpressDelivery)}
        </div>
    )
}
