import { memo, useCallback, useState } from "react"
import { Container } from "@tm/nexus"
import { Image, Loader } from "@tm/controls"
import { Article, ArticleImage, RegisteredModels } from "@tm/models"
import { Tooltip, ImageViewer, Skeleton, tooltipClasses, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    articleInfo?: Article
}

const StyledTooltip = styled(Tooltip)(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "60vw",
    },
}))

const ClickableImage = styled(Image)(() => ({
    cursor: "pointer",
}))

export const PartListItemImage = memo<Props>(({ articleInfo }) => {
    const { translate } = useLocalization()
    const [imagesLoading, setImagesLoading] = useState(false)
    const [articleImages, setArticleImages] = useState<ArticleImage[]>([])
    const [open, setOpen] = useState<boolean>(false)

    const handleLoadImages = useCallback(() => {
        setImagesLoading(true)

        Container.getInstance<ArticleImage[]>(RegisteredModels.Articles_GetArticleImageByInternalArticleId)
            .subscribe(articleInfo!.internalId)
            .load()
            .then(
                (response) => {
                    setArticleImages(response)
                    setImagesLoading(false)
                },
                () => setImagesLoading(false)
            )
            .finally(() => {
                setTimeout(() => {
                    window.dispatchEvent(new Event("resize"))
                }, 150)
            })
    }, [articleInfo?.internalId])

    const handleTooltipOpen = useCallback(() => {
        handleLoadImages()
        setOpen(true)
    }, [handleLoadImages])

    const handleTooltipClose = useCallback(() => {
        setOpen(false)
    }, [])

    const renderImages = useCallback(() => {
        if (imagesLoading) {
            return <Loader />
        }

        if (!articleImages.length) {
            return <div className="article__no-images-message">{translate(375)}</div>
        }

        return <ImageViewer images={articleImages} onClose={handleTooltipClose} isLoading={false} />
    }, [imagesLoading, articleImages])

    if (!articleInfo) {
        return (
            <div className="thumbnail no-separator">
                <Skeleton variant="text" height="63px" width="63px" />
            </div>
        )
    }

    return (
        <StyledTooltip
            color="primary"
            variant="light"
            open={open}
            placement="right"
            title={renderImages()}
            disableHoverListener
            onClick={handleTooltipOpen}
            onClickAway={handleTooltipClose}
        >
            <div className="thumbnail no-separator">
                <ClickableImage url={articleInfo.thumbnail || ""} type="article" />
            </div>
        </StyledTooltip>
    )
})
