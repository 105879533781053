import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { NavigationSlice, NavigationState } from "./model"

function getInitialState(): NavigationState {
    return {
        navigation: {
            active: "start",
        },
    }
}

export const createNavigationSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], NavigationSlice> = (set) => ({
    ...getInitialState(),
    navigationActions: {
        changeStep: (selectedStep: string) => set((state) => changeStep(state, selectedStep), false, `Navigation change step: ${selectedStep}`),
    },
    reset: () => set(getInitialState(), false, "Reset Navigation Slice"),
})

function changeStep(state: NavigationSlice, selectedStep: string): Partial<NavigationSlice> {
    return {
        navigation: {
            ...state.navigation,
            active: selectedStep,
        },
    }
}
