import { ReactElement, useEffect, useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Dropdown, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Overlay } from "../.."

type Props = {
    onImageUpload(image: string): void
    disabled?: boolean
}

const LOCAL_STORAGE_KEY = "VRC_LOOKUP_SELECTED_CAMERA"

type SimpleDevice = {
    deviceId?: string
    label: string
}

export default function Camera(props: Props) {
    const style = useMemo(() => getStyle(), [])
    const { translateText } = useLocalization()
    const [devices, setDevices] = useState<Array<SimpleDevice>>()
    const [currentDeviceId, setCurrentDeviceId] = useState<string | undefined>(() => localStorage.getItem(LOCAL_STORAGE_KEY) || undefined)
    const [stream, setStream] = useState<MediaStream>()

    useEffect(() => {
        navigator.mediaDevices?.enumerateDevices().then((availableDevices) => {
            setDevices(
                availableDevices
                    .filter((device) => device.kind === "videoinput")
                    .map((device, index) => ({
                        deviceId: device.deviceId || undefined,
                        label: device.label || `Camera ${index + 1}`,
                    }))
            )
        })
    }, [])

    if (!navigator.mediaDevices || !navigator.mediaDevices?.enumerateDevices || !navigator.mediaDevices.getUserMedia) {
        return <Text>{translateText(12622)}</Text>
    }

    if (!devices) {
        return <Loader />
    }

    if (!devices.length) {
        return <Text>{translateText(12623)}</Text>
    }

    const currentDevice = devices.find((device) => device.deviceId === currentDeviceId) || devices[0]

    const handleChangeDevice = (device: SimpleDevice) => {
        setCurrentDeviceId(device.deviceId)
        if (device.deviceId) {
            localStorage.setItem(LOCAL_STORAGE_KEY, device.deviceId)
        }
    }

    const handleStartClick = () => {
        navigator.mediaDevices
            .getUserMedia({
                audio: false,
                video: { deviceId: { exact: currentDevice.deviceId } },
            })
            .then(setStream)
    }

    const handleOnClose = () => {
        setStream(undefined)
    }

    const handleImageUpload = (image: string) => {
        props.onImageUpload(image)
        setStream(undefined)
    }

    return (
        <div>
            <Text modifiers="block">{translateText(12624)}</Text>
            <Dropdown className={style.dropDown} items={devices} itemView={DropdownItemView} value={currentDevice} onChange={handleChangeDevice} />
            <Button skin="highlight" disabled={props.disabled} className={style.button} onClick={handleStartClick}>
                {translateText(12625)}
            </Button>

            {stream && <Overlay stream={stream} onClose={handleOnClose} onImageUpload={handleImageUpload} />}
        </div>
    )
}

function DropdownItemView(props: SimpleDevice): ReactElement | null {
    return <>{props.label}</>
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        dropDown: {
            marginTop: theme.margin.m,
        },
        button: {
            display: "block",
            marginTop: "2rem",
            padding: ".8rem 1rem",
        },
    })(Camera)
}
