import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const part: BundleComponent = {
    name: "part",
    reduce,
    component,
}

export default part
