import { Button, ReplaceButton, Tag } from "@tm/controls"
import { FastCalculator } from "@tm/data"
import { getButtonSkin } from "../../../data/helpers"
import { getCalcStateIcon } from "../../../data/helpers/getCalcInputIcon"
import { handleInputSubmit } from "../../../business"
import { useStoreContext } from "../../../state"

type Props = {
    className?: string
    item: FastCalculator.CalcInput
}

export default function CalcInputButton(props: Props) {
    const instance = useStoreContext()
    const { className, item } = props

    if (!item) {
        return null
    }

    const icon = getCalcStateIcon(item.icon)

    const handleChange = (event?: MouseEvent) => {
        event?.stopPropagation()
        handleInputSubmit(instance, item)
    }

    function handleSwitchOeGroup() {
        handleInputSubmit(instance, item)
    }

    if (item.type === FastCalculator.ECalcInputType.SwitchOeGroup) {
        return (
            <Tag
                onClick={handleSwitchOeGroup}
                label={item.description}
                value={item.label}
                layout="holo"
                skin={item.isDisabled ? "primary" : undefined}
            />
        )
    }

    if (icon === "replace") {
        return <ReplaceButton className="price" onClick={handleChange} isWm={false} disabled={item.isDisabled} />
    }

    return (
        <Button className={className} disabled={item.isDisabled} onClick={handleChange} skin={getButtonSkin(item.btnStyle)} icon={icon}>
            {item.label}
        </Button>
    )
}
