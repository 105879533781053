import { Info } from "../model"

export const getInfosByPrio = (infos: Info[]) => {
    const prioHigh: Info[] = []
    const prioLow: Info[] = []
    if (infos.find((x) => x.priority === 1)) {
        infos.forEach((x) => {
            if (x.priority === 1) {
                prioHigh.push(x)
            } else {
                prioLow.push(x)
            }
        })
    } else {
        infos.forEach((x) => {
            if (x.priority === 2 || x.priority === 3) {
                prioHigh.push(x)
            } else {
                prioLow.push(x)
            }
        })
    }

    return [prioHigh, prioLow]
}
