export const version = {
    name: "schedules",
    version: "1.0.0",
}

let bundleParams: {
    serviceUrl: string
    authorityServiceUrl: string
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function initSchedulesBundle(params: any) {
    bundleParams = params
    return bundleParams
}
