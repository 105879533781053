import { useWorkTask } from "@tm/context-distribution"
import { SuggestionTextField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { TitleType } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Box, Icon, Typography, styled, useTheme } from "@tm/components"
import { MainActions, fieldsSelector, isGermanOrAustrianVehicleSelector } from "../../main/business"
import { validateFields } from "../business/helpers"

export const customerFieldsSelector = createSelector(
    fieldsSelector,
    ({ titleType, firstName, lastName, companyName, street, streetNo, zip, city, country, email, phone, processName, keyNumbers }) => ({
        titleType,
        firstName,
        lastName,
        companyName,
        street,
        streetNo,
        zip,
        city,
        country,
        email,
        phone,
        processName,
        keyNumbers,
    })
)

export default function CustomerInfoFields() {
    const { translateText, languageId } = useLocalization()
    const { workTask } = useWorkTask() || {}

    const actions = useActions(MainActions, "updateFieldValue", "initCustomer")
    const fields = useSelector(customerFieldsSelector)
    const { isGermanOrAustrianVehicle } = useSelector(isGermanOrAustrianVehicleSelector)
    const theme = useTheme()

    const modelState = validateFields(fields, translateText, workTask?.vehicle, isGermanOrAustrianVehicle)

    useEffect(() => {
        !fields.country && actions.initCustomer(translateText(3147))
    }, [languageId])

    const titleItems: Record<string, string> = {
        1: translateText(145),
        2: translateText(146),
        3: translateText(108),
    }

    const handleUpdateCustomerTitle = (value: string) => {
        const key = Object.keys(titleItems).find((x) => titleItems[x] === value)

        value && actions.updateFieldValue("titleType", key ?? value)
    }

    return (
        <Box borderTop="solid #e2e2e2 .1em">
            <Box display="flex" padding={theme.margin?.m} alignItems="center">
                <Icon name="customer" size="2em" color="black" />
                <Typography fontSize="large">{translateText(107)}</Typography>
            </Box>

            <Box display="flex" flexWrap="wrap" flex="1" margin={`${theme.margin?.m} 0 0 ${theme.margin?.m}`}>
                <Box display="flex" flexBasis="100%">
                    <StyledBox className="title">
                        <SuggestionTextField
                            floatingLabel
                            label={translateText(118)}
                            value={fields.titleType && titleItems[fields.titleType]}
                            getSuggestionData={() => Object.values(titleItems)}
                            onChangeConfirm={handleUpdateCustomerTitle}
                            modelState={modelState}
                            path={["titleType"]}
                        />
                    </StyledBox>

                    {fields.titleType !== TitleType.Company && (
                        <StyledBox>
                            <TextField
                                floatingLabel
                                label={translateText(119)}
                                value={fields.firstName}
                                path={["firstName"]}
                                modelState={modelState}
                                onChangeConfirm={(value) => actions.updateFieldValue("firstName", value)}
                            />
                        </StyledBox>
                    )}

                    {fields.titleType !== TitleType.Company && (
                        <StyledBox>
                            <TextField
                                floatingLabel
                                label={translateText(104)}
                                path={["lastName"]}
                                modelState={modelState}
                                value={fields.lastName}
                                onChangeConfirm={(value) => actions.updateFieldValue("lastName", value)}
                            />
                        </StyledBox>
                    )}

                    {fields.titleType === TitleType.Company && (
                        <StyledBox>
                            <TextField
                                floatingLabel
                                label={translateText(1757)}
                                path={["companyName"]}
                                modelState={modelState}
                                value={fields.companyName}
                                onChangeConfirm={(value) => actions.updateFieldValue("companyName", value)}
                            />
                        </StyledBox>
                    )}
                </Box>
                <Box display="flex" flexBasis="100%">
                    <StyledBox>
                        <TextField
                            floatingLabel
                            label={translateText(3151)}
                            path={["street"]}
                            value={fields.street}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("street", value)}
                        />
                    </StyledBox>
                    <StyledBox className="street-no">
                        <TextField
                            floatingLabel
                            label={translateText(3152)}
                            path={["streetNo"]}
                            value={fields.streetNo}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("streetNo", value)}
                        />
                    </StyledBox>
                    <StyledBox className="zip">
                        <TextField
                            floatingLabel
                            label={translateText(112)}
                            path={["zip"]}
                            value={fields.zip}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("zip", value)}
                        />
                    </StyledBox>
                    <StyledBox>
                        <TextField
                            floatingLabel
                            label={translateText(113)}
                            path={["city"]}
                            value={fields.city}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("city", value)}
                        />
                    </StyledBox>
                    <StyledBox>
                        <TextField
                            floatingLabel
                            label={translateText(114)}
                            path={["country"]}
                            value={fields.country}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("country", value)}
                        />
                    </StyledBox>
                </Box>
            </Box>
        </Box>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    margin: `${theme.margin?.m} ${theme.margin?.s}`,
    flexBasis: "20%",
    "&.title": {
        flexBasis: "4%",
    },
    "&.zip": {
        flexBasis: "10%",
    },
    "&.street-no": {
        flexBasis: "6%",
    },
}))
