import { Loader, Table, Text } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { getValue } from '@tm/utils'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { vehicleSelector } from '../../../../data/helpers'
import { MainState } from '../../../main'
import { AdditionalVehicleInfo } from '../../../../data/model'
import { vehicleInfoDetailsMap } from '../../../../data/enums'

type Props = {}

const selector = (s: MainState) => ({ additionalVehicleInfosResult: s.wheelsList.base.vehicleInfosResult })

const RimVehicleInformation: React.FC<Props> = ({ }) => {
	const { translateText } = useLocalization()
	const vehicle = useSelector(vehicleSelector)
	const { additionalVehicleInfosResult: { data, error, loading } } = useSelector(selector)
	const { selectedRegistrationNo } = useSelector((s: MainState) => s.vehicleSelection)
	const renderTitle = (item: { path: string; translateID: number }) => <Table.Cell><Text>{translateText(item.translateID)}</Text></Table.Cell>
	const renderValue = (item: { path: string; translateID: number }) => <Table.Cell><Text>{vehicle && getValue(vehicle, [item.path])}</Text></Table.Cell>
	const renderAditionalName = (item: AdditionalVehicleInfo) => <Table.Cell><Text>{translateText(vehicleInfoDetailsMap[item.attributeId])}</Text></Table.Cell>
	const renderAditionalValue = (item: AdditionalVehicleInfo) => <Table.Cell><Text>{item.attributeValue}</Text></Table.Cell>

	const vehicleInfoRows: { path: string, translateID: number }[] = React.useMemo(() => [
		...(selectedRegistrationNo && [{ path: "registrationNo", translateID: 1730 }] || []),
		{ path: "manufacturer", translateID: 71 },
		{ path: "engineCode", translateID: 105 },
		{ path: "model", translateID: 74 },
		{ path: "modelSeries", translateID: 73 },
		{ path: "vin", translateID: 101 }
	], [selectedRegistrationNo])

	if (loading)
		return <div className="article-list__panel article-list__status"><Loader /></div >

	if (error || !data.length || !vehicle)
		return <div className="article-list__panel article-list__status"><Text size="m"> {translateText(163)}</Text></div>

	return (
		<div className="vehicle__informations">
			<Table
				scrollable
				data={vehicleInfoRows}
				columns={[
					<Table.Column className="size" renderItemContent={renderTitle}></Table.Column>,
					<Table.Column className="rim" renderItemContent={renderValue}></Table.Column>,
				]}
			/>
			<Table
				scrollable
				data={data}
				columns={[
					<Table.Column className="size" renderItemContent={renderAditionalName}></Table.Column>,
					<Table.Column className="rim" renderItemContent={renderAditionalValue}></Table.Column>,
				]}
			/>
		</div>
	)
}

export default RimVehicleInformation