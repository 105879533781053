import { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form"
import { TextField } from "@tm/components"
import { ChangeEvent, ForwardedRef, forwardRef } from "react"
import FormItem from "./FormItem"

type Props = {
    fieldState: ControllerFieldState
    label: string
}

export const TextFormItem = forwardRef(function TextFormItem<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ label, fieldState, name, value, onChange, onBlur }: Props & ControllerRenderProps<TFieldValues, TName>, ref: ForwardedRef<HTMLDivElement>) {
    const capitalizeFirstLetter = (str: string) => {
        if (!str) {
            return str
        }
        return str.charAt(0).toUpperCase() + str.slice(1)
    }

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const newValue = name === "email" ? e.target.value : capitalizeFirstLetter(e.target.value)
        onChange(newValue)
    }

    return (
        <FormItem fieldState={fieldState}>
            <TextField
                ref={ref}
                name={name}
                label={label}
                required
                value={value}
                onChange={handleChange}
                onBlur={onBlur}
                error={Boolean(fieldState.error)}
                variant="filled"
                size="extralarge"
            />
        </FormItem>
    )
})
