import { GetCarExtendedInfoResponse, CarExtendedInfoResponseRAW } from "."

export function mapCarExtendedInfoResponse(response: CarExtendedInfoResponseRAW): GetCarExtendedInfoResponse {
    const { carCompleteInfo: {landCertificates, equipmentLine, carMMTs, sufficientSelection, make, model, type, transmissionType} } = response
    const initialRegistrationDate = response.carCompleteInfo.initialRegistrationDate && new Date(response.carCompleteInfo.initialRegistrationDate) || undefined
    const registrationDate = response.carCompleteInfo.registrationDate && new Date(response.carCompleteInfo.registrationDate) || undefined
    const constructionPeriods = response.carCompleteInfo.constructionPeriods?.map(x => ({dateFrom: new Date(x.dateFrom), dateTo: new Date(x.dateTo)})) ?? []

    return {
        registrationDate,
        constructionPeriods,
        landCertificates,
        equipmentLine,
        carMMTs,
        sufficientSelection,
        make,
        model,
        type,
        initialRegistrationDate,
        transmissionType,
    }
}