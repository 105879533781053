import { Article, OE, ArticleQuantities, ArticleQuantitiesRequest, channel, WholesalerPart, WholesalerArticle } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { useCallback, useEffect } from "react"
import { RecoilState, useRecoilState } from "recoil"
import { isEqual, sortBy } from "lodash"
import {
    isSameOeArticle,
    isSameWholesalerArticle,
    mapOeArticleQuantitiesRequest,
    mapSupplierArticleQuantitiesRequest,
    mapWholesalerArticleQuantitiesRequest,
} from "../helpers"
import { ArticleQuantitiesState, OeArticleQuantitiesState, WholesalerArticleQuantitiesState } from "../states"
import {
    getShowQuantitiesByOeArticle,
    getShowQuantitiesBySupplierArticle,
    getShowWholesalerArticleQuantities,
} from "../../../../../basket/src/data/repositories/basket/article-quantities"
import { mapWholesalerArticleToWholesalerPart } from "./useArticleItem/useHandleAddWholesalerPartToBasket"

function useGenericBasketQuantities<TArticle, TArticleDto>(
    articles: TArticle[],
    isEnabled: boolean,
    initialState: (workTaskId: string | undefined) => RecoilState<ArticleQuantities<TArticleDto>[]>,
    mapQuantitiesRequest: (article: TArticle, workTaskId: string) => ArticleQuantitiesRequest<TArticleDto>,
    getShowQuantities: (request: ArticleQuantitiesRequest<TArticleDto>[]) => Promise<ArticleQuantities<TArticleDto>[]>,
    isSameArticle: (a: TArticleDto, b: TArticleDto) => boolean,
    extraKey?: string
) {
    const { workTaskId } = useWorkTask() ?? {}
    const key = workTaskId + (extraKey ? `_${extraKey}` : "")
    const [basketQuantities, setBasketQuantities] = useRecoilState<ArticleQuantities<TArticleDto>[]>(initialState(key))

    // remove items on Unmount
    useEffect(() => () => setBasketQuantities([]), [setBasketQuantities])

    const loadQuantities = useCallback(
        async (requests: ArticleQuantitiesRequest<TArticleDto>[]) => {
            if (requests.length) {
                const response = await getShowQuantities(requests)
                setBasketQuantities((state) => {
                    const newList = [
                        ...state.filter((x) => !requests.some((item) => isSameArticle(item.article, x.article))),
                        ...response.filter((x) => !!x.articleQuantities),
                    ]
                    if (!isEqual(sortBy(newList), sortBy(state))) {
                        return newList
                    }
                    return state
                })
            }
        },
        [setBasketQuantities]
    )

    useEffect(() => {
        if (!workTaskId || !isEnabled) {
            return
        }
        const requests = articles
            .map((article) => mapQuantitiesRequest(article, workTaskId))
            .filter((req) => !basketQuantities.some((q) => isSameArticle(q.article, req.article)))

        loadQuantities(requests)
    }, [articles, workTaskId, isEnabled])

    const updateBasketQuantities = useCallback(
        async (articles: TArticle[]) => {
            if (!workTaskId) {
                return
            }
            const requests = articles.map((article) => mapQuantitiesRequest(article, workTaskId))

            loadQuantities(requests)
        },
        [loadQuantities, workTaskId]
    )

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        const unsubscriptions: Array<() => void> = []
        unsubscriptions.push(
            channel("WORKTASK", workTaskId).subscribe("BASKET/ARTICLE_ADDED", () => {
                updateBasketQuantities(articles)
            })
        )

        return () => {
            unsubscriptions.forEach((unsub) => unsub())
        }
    }, [articles, updateBasketQuantities, workTaskId])
    return { basketQuantities, updateBasketQuantities }
}

export function useBasketQuantities(articles: Article[], isEnabled: boolean, extraKey?: string) {
    return useGenericBasketQuantities(
        articles,
        isEnabled,
        ArticleQuantitiesState,
        mapSupplierArticleQuantitiesRequest,
        getShowQuantitiesBySupplierArticle,
        isEqual,
        extraKey
    )
}

export function useOeBasketQuantities(articles: OE.OePart[], isEnabled: boolean, extraKey?: string) {
    return useGenericBasketQuantities(
        articles,
        isEnabled,
        OeArticleQuantitiesState,
        mapOeArticleQuantitiesRequest,
        getShowQuantitiesByOeArticle,
        isSameOeArticle,
        extraKey
    )
}

export function useWholesalerPartBasketQuantities(articles: WholesalerPart[], isEnabled: boolean, extraKey?: string) {
    return useGenericBasketQuantities(
        articles,
        isEnabled,
        WholesalerArticleQuantitiesState,
        mapWholesalerArticleQuantitiesRequest,
        getShowWholesalerArticleQuantities,
        isSameWholesalerArticle,
        extraKey
    )
}

export function useWholesalerArticleBasketQuantities(articles: WholesalerArticle[], isEnabled: boolean, extraKey?: string) {
    const parts = articles.map(mapWholesalerArticleToWholesalerPart)
    const { basketQuantities, updateBasketQuantities: updateWholesalerBasketQuantities } = useWholesalerPartBasketQuantities(
        parts,
        isEnabled,
        extraKey
    )
    return {
        basketQuantities,
        updateBasketQuantities: (wholesalerArticles: WholesalerArticle[]) =>
            updateWholesalerBasketQuantities(wholesalerArticles.map(mapWholesalerArticleToWholesalerPart)),
    }
}
