import { useState } from "react"
import DatePicker from "react-datepicker"
import { DateUnit } from "@tm/utils"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { StartWidgetState } from "../business"

type Props = Required<Pick<StartWidgetState, "events" | "types">> & {
    date: Date
    onSelectDay(date: Date): void
}

export function MonthCalendar(props: Props) {
    const { events, types } = props
    const [date, setDate] = useState(props.date)
    const today = new Date()
    const { translate } = useLocalization()

    function handleChange(date: Date) {
        props.onSelectDay(date)
    }

    function handleNextClick(direction: number) {
        setDate(date.add(direction, DateUnit.Month))
    }

    function getType(id: string) {
        const type = types.find((x) => x.id.toString() === id)
        const noNameFound = translate(171, false) || ""
        return type ? type.name : noNameFound.toString()
    }

    function renderDay(dayOfMonth: number) {
        const eventTypes = Object.keys(
            events.filter((x) => x.startDateTime.isSame(date.set(dayOfMonth, DateUnit.Day), DateUnit.Day)).groupBy((x) => x.eventTypeId || 0)
        )
        return (
            <>
                {dayOfMonth}
                <div className="events">
                    {eventTypes.map((key) => (
                        <div key={key} className={`event event--${key}`} title={getType(key)} />
                    ))}
                </div>
            </>
        )
    }

    return (
        <div className="month-calendar">
            <Button className="prev" icon="prev" layout={["holo", "circle"]} onClick={handleNextClick.bind(null, -1)} />
            <Button className="next" icon="next" layout={["holo", "circle"]} onClick={handleNextClick.bind(null, 1)} />
            <DatePicker
                className="hallo"
                renderDayContents={renderDay}
                highlightDates={[today]}
                inline
                selected={date}
                onChange={handleChange}
                onMonthChange={handleChange}
                calendarClassName="inline"
            />
        </div>
    )
}
