import { Button, SeparatorList, Tag, TagIcon, TagLayout, TagSkin } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ArticleAttribute, AttributeComparisonModel, CompareWithVehicleRecordsAttributeVisualization, ModificationState } from "@tm/models"
import { useMemo } from "react"
import { MultipleValueAttribute } from "./attribute-group"
import { mapTooltipArrayToElement } from "../attribute-tooltip"

type Props = {
    attributes: Array<ArticleAttribute>
    canFilterArticleAttributes: boolean
    className: string
    ignoreAttributeKey?: boolean
    isCompact?: boolean
    productGroupId: number
    selected: Array<string>
    showMore?: boolean
    vehicleEngineCode?: string
    vehicleRecordsComparisons: Array<AttributeComparisonModel>
    onSelect?(attribute: ArticleAttribute): void
    toggleMoreAttributes?(): void
}

export default function ArticleAttributesComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const {
        attributes,
        canFilterArticleAttributes,
        className,
        ignoreAttributeKey,
        isCompact,
        productGroupId,
        selected,
        showMore,
        vehicleEngineCode,
        vehicleRecordsComparisons,
    } = props

    function isAttributeSelected(attribute: MultipleValueAttribute, attributeValue: ArticleAttribute) {
        return selected.some((x) => {
            const [id, key] = x.split("|")
            return (
                attribute.id.toString() === id &&
                (!ignoreAttributeKey && attributeValue.key ? attributeValue.key === key : attributeValue.value === key)
            )
        })
    }

    let items = useMemo(() => {
        return mapAttributes(attributes)
    }, [attributes])

    if (!attributes.length) {
        return <></>
    }

    if (showMore) {
        items = [...items, { id: -1 } as any]
    }

    function renderAttributeValue(attribute: MultipleValueAttribute, attributeValue: ArticleAttribute) {
        const isSelected = isAttributeSelected(attribute, attributeValue)

        const icons: Array<TagIcon> = []

        let attributeClassName = "article__attribute"

        switch (attributeValue.modificationState) {
            case ModificationState.Added:
                attributeClassName += " article__attribute--added"
                icons.push({ name: "plus", tooltip: translateText(883) })
                break
            case ModificationState.Removed:
                attributeClassName += " article__attribute--removed"
                icons.push({ name: "minus", tooltip: translateText(884) })
                break
            default:
        }

        let layout: TagLayout | undefined
        let skin: TagSkin | undefined
        const tooltips = []

        if (!isSelected) {
            layout = "ghost"
        } else {
            skin = "primary"
        }

        let { highlight } = attributeValue

        // If the attribute is engine code and the current engine code matches the value of the attribute ...
        if (attribute.id === 33 && vehicleEngineCode && vehicleEngineCode === attributeValue.value) {
            highlight = true // .. highlight the attribute
            tooltips.push(translateText(893))
        } else if (
            !highlight &&
            vehicleRecordsComparisons.some(
                (x) =>
                    attribute.id === x.attribute.id &&
                    attributeValue.key === x.attribute.value &&
                    productGroupId === x.attribute.productGroupId &&
                    (x.visualization === CompareWithVehicleRecordsAttributeVisualization.Suggested ||
                        x.visualization === CompareWithVehicleRecordsAttributeVisualization.Confirmed)
            )
        ) {
            highlight = true
        }

        if (attributeValue.alternatives && attributeValue.alternatives.length) {
            tooltips.push(`${translateText(attributeValue.isOptimized ? 1642 : 1643)}:`)

            attributeValue.alternatives.forEach((tooltip) => {
                const value = `${tooltip.value} ${tooltip.unit || ""}`.trim()
                tooltips.push(`${tooltip.abbreviation}: ${value}`)
            })
        }

        const tooltip = mapTooltipArrayToElement(tooltips)

        if (highlight) {
            layout = !isSelected ? "holo" : undefined
            skin = "highlight"
        }

        const value = `${attributeValue.value} ${attributeValue.unit || ""}`.trim()

        let onClick
        const shouldFilter = !attributeValue.action // undefined or 0 (AttributeAction.Filter)
        if (attributeValue.value && props.onSelect && (!shouldFilter || canFilterArticleAttributes)) {
            onClick = () => {
                props.onSelect?.(attributeValue)
            }
        }

        return (
            <Tag
                key={`${attribute.id}:${value}:${attributeValue.isOptimized ?? ""}`}
                className={attributeClassName}
                layout={layout}
                skin={skin}
                value={value}
                onClick={onClick}
                icons={icons}
                tooltip={tooltip}
            />
        )
    }

    function renderAttribute(attribute: MultipleValueAttribute) {
        if (attribute.id === -1) {
            if (!props.toggleMoreAttributes) {
                return <></>
            }

            return (
                <Button className="more-btn" onClick={props.toggleMoreAttributes} layout={["ghost"]} size="xs">
                    {translate(isCompact ? 44 : 1208)}
                </Button>
            )
        }

        const key = `${attribute.id}:${attribute.abbreviation}`
        if (!attribute.values.filter((x) => !!x.value).length && attribute.values.some((x) => x.highlight)) {
            return <Tag key={key} className="article__attribute" layout="holo" skin="highlight" label={attribute.abbreviation} />
        }

        return (
            <>
                <Tag key={key} className="article__attribute" layout="ghost" label={attribute.abbreviation} />
                {attribute.values.map((x) => renderAttributeValue(attribute, x))}
            </>
        )
    }

    return <SeparatorList className={`article__attributes ${className}`} items={items} renderItem={renderAttribute} />
}

function mapAttributes(attributes: Array<ArticleAttribute>): Array<MultipleValueAttribute> {
    const mapped: Array<MultipleValueAttribute> = []

    attributes.forEach((attribute) => {
        let found = mapped.find(
            (y) =>
                y.id === attribute.id && // check if the attribute is already existing
                !!attribute.abbreviation // don't combine attributes without an abbrevation/label
        )

        if (!found) {
            found = {
                abbreviation: attribute.abbreviation,
                description: attribute.description,
                id: attribute.id,
                key: attribute.key,
                isBlockSeperator: attribute.isBlockSeperator,
                isDuplicatedAttribute: attribute.isDuplicatedAttribute,
                sortNo: attribute.sortNo,
                text: attribute.text,
                values: [attribute],
            }

            mapped.push(found)
        } else {
            found.values.push(attribute)
        }
    })

    return mapped
}
