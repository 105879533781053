import { useState, useRef } from "react"
import { useLocalization } from "@tm/localization"
import { registerOutsideClick } from "@tm/utils"
import { Popover, Icon, Widget, WidgetSizes, Dropdown } from "@tm/controls"
import { ExternalModule } from "@tm/models"
import { replaceUrlTags, useTelesalesCustomerNumber, useUser, useWorkTask } from "@tm/context-distribution"
import { useHistory, useLocation } from "react-router"
import { SxProps, Theme, styled } from "@tm/components"
import { checkDisplayBehavior, DisplayType, callItemUrl } from "./utils"
import WidgetItem from "./components/WidgetItem"
import DropdownItem from "./components/DropdownItem"
import ListItem from "./components/ListItem"
import { getExternalCatalogUrl } from "../../data/repositories/url"

export type ExternalCatalogComponentProps = ConfigProps & {}

type ConfigProps = {
    display: DisplayType
    showGroupedItemsOnly?: boolean
    showOnlyGroup?: number
    listClassName?: string
    itemClassName: string
    size?: WidgetSizes
    height?: number
    hideWhenComponentChanged?: boolean
    title?: string
    description?: string
    buttonValue?: string
    icon?: string
    dropdown?: boolean
    hideWrapper?: boolean
    hideCover?: boolean
    showArrow?: boolean
    variableHeight?: boolean
    sx?: SxProps<Theme>
}

const SxWidget = styled(Widget)({})

export default function ExternalCatalogComponent(props: ExternalCatalogComponentProps) {
    const { translateText } = useLocalization()
    const location = useLocation()
    const history = useHistory()
    const { languageId } = useLocalization()
    const userContainer = useUser()
    const workTaskContainer = useWorkTask()
    const { workTaskId } = workTaskContainer ?? {}
    const { userContext } = userContainer
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const {
        display,
        listClassName,
        itemClassName,
        size,
        height,
        title,
        description,
        icon,
        dropdown,
        buttonValue,
        showGroupedItemsOnly,
        showOnlyGroup,
        hideWrapper,
        variableHeight,
        sx,
    } = props
    const [open, setOpen] = useState<boolean>(false)
    const popoverRef = useRef<HTMLElement | null>(null)

    async function handleClick(item: ExternalModule) {
        setOpen(false)
        const externalCatalogUrl = await getExternalCatalogUrl(item.id, telesalesCustomerNo, userContext, true)
        const url = replaceUrlTags(externalCatalogUrl, { location, languageId }, userContainer, workTaskContainer)
        return callItemUrl(history, item, url, workTaskId)
    }

    function handlePopoverRef(el: HTMLElement) {
        popoverRef.current = el
    }

    function handleClickButton() {
        setOpen(!open)
        if (popoverRef.current) {
            const unregisterOutsideClick = registerOutsideClick(popoverRef.current, () => {
                unregisterOutsideClick && unregisterOutsideClick()
                setOpen(false)
            })
        }
    }

    function renderCover() {
        return <div>{buttonValue ? translateText(buttonValue) : ""}</div>
    }

    function renderGroup(items: ExternalModule[], idx: number) {
        const tabClassName = "tab tab--worktask"

        if (display === "HEADER") {
            return (
                <div className="global-navigation btn-extern" key={idx}>
                    <div className={tabClassName} onClick={handleClickButton}>
                        <div className="tab__content">
                            <Icon size="l" className="user-info" name={icon || "user"} />
                        </div>
                    </div>
                    <Popover onElementRef={handlePopoverRef} active={open} alignArrow="center" className="user-settings__popover">
                        {items.map((item, itemIdx) => (
                            <ListItem key={itemIdx} item={item} className={itemClassName} onClick={handleClick} />
                        ))}
                    </Popover>
                </div>
            )
        }
        return (
            <SxWidget
                className={`group bundle-misc external-catalogs ${dropdown ? "dropdown" : ""}`}
                key={idx}
                size={size || "4x2"}
                height={height}
                variableHeight={variableHeight}
                active
                title={title ? translateText(title) : translateText(1509)}
                iconName={icon || "linked-item"}
                sx={sx}
            >
                <div className={`item-list ${listClassName || ""}`}>
                    {description ? <p className="mb-3">{translateText(description)}</p> : ""}
                    {dropdown ? (
                        <Dropdown
                            items={items}
                            coverView={renderCover}
                            skin="highlight"
                            layout={["iconRight"]}
                            itemView={(item) => <DropdownItem {...props} item={item} onClick={handleClick} />}
                        />
                    ) : (
                        items.map((item, itemIdx) => <ListItem key={itemIdx} item={item} className={itemClassName} onClick={handleClick} />)
                    )}
                </div>
            </SxWidget>
        )
    }

    if (!userContext || !userContext.externalModules) {
        return null
    }

    const processDisplayBehavior = (y: { key: string; value: string }) => y.key === "DisplayBehavior" && checkDisplayBehavior(display, y.value)

    const checkDisplayTarget = (module: ExternalModule) => {
        const param = module.parameter?.find((p) => p.key.toLowerCase() === "target")

        if (param) {
            return param.value !== "3"
        }

        return true
    }

    const filteredExternalModules = userContext.externalModules.filter(checkDisplayTarget)

    const catalogs = filteredExternalModules
        .filter((x) => {
            if (showGroupedItemsOnly) {
                return x.parameter?.some(processDisplayBehavior) && x.parameter.find((y) => y.key === "ArrangementInGroups")
            }
            if (showOnlyGroup) {
                return (
                    x.parameter?.some(processDisplayBehavior) &&
                    x.parameter.find((y) => y.key === "ArrangementInGroups" && y.value === showOnlyGroup.toString())
                )
            }
            return x.parameter?.some(processDisplayBehavior)
        })
        .orderBy((x) => {
            const item = x.parameter?.find((y) => y.key === "SortNumber")
            return (item && item.value) || 0
        })
        .groupBy((x) => {
            const param = x.parameter?.find((y) => y.key === "ArrangementInGroups")
            return param ? param.value : "0"
        })

    const groupIds = Object.keys(catalogs)

    if ((display === "MODULES" && groupIds.length) || (hideWrapper && groupIds.length)) {
        return (
            <>
                {catalogs[groupIds[0]].map((item, idx) => (
                    <WidgetItem {...props} item={item} onClick={handleClick} key={idx} />
                ))}
            </>
        )
    }
    return groupIds.length === 1 && groupIds[0] === "0" ? (
        <div className={`bundle-misc external-catalogs${display === "BASKET" ? "-basket" : ""}`}>
            {catalogs[groupIds[0]].map((item, idx) => (
                <WidgetItem {...props} item={item} onClick={handleClick} key={idx} />
            ))}
        </div>
    ) : (
        <>{groupIds.map((x, idx) => renderGroup(catalogs[x], idx))}</>
    )
}
