import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { GetUrlForPageResponse } from "./model"

export function getUrlForPage(taskId?: string) {
    const url = `${getServiceUrl()}/GetUrlForPage`
    const authorization = getStoredAuthorization()

    return ajax<GetUrlForPageResponse>({ url, body: { taskId }, method: "GET", authorization }).then((response) => {
        if (response?.taskUrl) {
            return response.taskUrl
        }

        throw new Error("Task URL is not available")
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.asWegasServiceUrl
}
