import { Box, Loader, styled } from "@tm/components"
import { useEffect, useMemo } from "react"
import Prices from "./components/Prices"
import ArticleList from "./components/ArticleList"
import { useGetCalculationData } from "../../hooks/useGetCalculationData"
import { calculationActions, useDrivemotiveStore } from "../../data/state"
import CalculationWorksList from "./components/CalculationWorksList"
import { InitialCalcData, TopProductGroup } from "../../data/models/calculation"
import CalculationButton from "./components/CalculationButton"
import NoResultHint from "../_shared/NoResult"

export default function Calculation() {
    const summaryArticles = useDrivemotiveStore((state) => state.summary.selectedArticleAndWork?.summaryArticles)
    const vehicle = useDrivemotiveStore((state) => state.summary.selectedBooking?.vehicle)

    if (!summaryArticles) {
        return <NoResultHint />
    }

    const initialData: InitialCalcData = useMemo(() => {
        return { KTypNr: vehicle?.vehicle_type?.tecdoc_type_id ?? 0 }
    }, [vehicle])

    const productGroups: TopProductGroup[] = useMemo(() => {
        return summaryArticles.map((article) => {
            const oeNumbers: string[] = []
            article.alternatives?.forEach((alternative) =>
                alternative.parts?.forEach((alternativePart) => {
                    if (alternativePart.partNo) {
                        oeNumbers.push(alternativePart.partNo)
                    }
                })
            )
            return {
                genArtNr: article.genArtNo,
                oeNrs: oeNumbers,
            }
        })
    }, [summaryArticles])

    const { oeProductGroups, isLoading } = useGetCalculationData(initialData, productGroups)

    useEffect(() => {
        if (oeProductGroups && oeProductGroups.length > 0) {
            calculationActions.setCalculationArticles(oeProductGroups)
        } else {
            calculationActions.setCalculationArticles([])
        }
    }, [oeProductGroups, isLoading])

    return (
        <Box>
            <ActionButton>
                <CalculationButton />
            </ActionButton>
            <Box>
                {isLoading ? (
                    <StyledLoader />
                ) : (
                    <Box>
                        <Prices />
                        <ArticleList />
                        <CalculationWorksList />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

const ActionButton = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    flex: "1 1 0%",
    gap: "8px",
    marginTop: "-3em",
})

const StyledLoader = styled(Loader)({
    position: "absolute",
    top: "50%",
    left: "50%",
    zIndex: 1,
})
