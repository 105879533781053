import { CarMainModelSeries } from "@tm/models"
import { selectorFamily, useRecoilValue } from "recoil"
import { CarBodyType, ModelYearFilter } from "../model/filters"

import * as Cars from "../repositories/cars/showMainModelSeries"

export type MainModelSeriesResponse =
    | undefined
    | {
          mainModelSeries: Array<CarMainModelSeries>
          filters: MainModelSeriesFilters
      }

type SelectedMainModelSeriesFilters = {
    bodyTypeId?: number
    modelYear?: number
}

type MainModelSeriesFilters = {
    bodyTypes: Array<CarBodyType>
    modelYears: Array<ModelYearFilter>
}

type MainModelSeriesByManufacturerRequest = {
    manufacturerId: number
    selectedFilters: SelectedMainModelSeriesFilters // Should not be optional because "undefined" and "{}" will create a different recoil selector
}

const mainModelSeriesByManufacturer = selectorFamily<MainModelSeriesResponse, MainModelSeriesByManufacturerRequest>({
    key: "vehicle_mainModelSeriesByManufacturer",
    get:
        ({ manufacturerId, selectedFilters }) =>
        () => {
            return Cars.showMainModelSeriesWithModelSeries({ manufacturerId, selectedFilters })
        },
})

export function useMainModelSeriesByManufacturer(request: MainModelSeriesByManufacturerRequest) {
    return useRecoilValue(mainModelSeriesByManufacturer(request))
}

type MainModelSeriesDetailsRequest = {
    manufacturerId?: number
    mainModelSeriesId?: number | undefined
}

type MainModelSeriesDetailsResponse =
    | undefined
    | {
          mainModelSeriesDetails: CarMainModelSeries
      }

const mainModelSeriesDetails = selectorFamily<MainModelSeriesDetailsResponse, MainModelSeriesDetailsRequest>({
    key: "vehicle_mainModelSeriesDetails",
    get:
        ({ manufacturerId, mainModelSeriesId }) =>
        async () => {
            if (!manufacturerId || mainModelSeriesId === undefined) {
                return
            }
            if (mainModelSeriesId === 0) {
                // The Service will return everything that has no mainModelSeries when we send undefined. On 0 it will try to find the corresponding mainModelSeries that is not existing
                mainModelSeriesId = undefined
            }
            return Cars.showMainModelSeriesDetails({ manufacturerId, mainModelSeriesId })
        },
})

export function useMainModelSeriesDetails(request: MainModelSeriesDetailsRequest) {
    return useRecoilValue(mainModelSeriesDetails(request))
}
