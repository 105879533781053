import { AsyncAction } from "@tm/morpheus"
import { clone, equals, getValue } from "@tm/utils"
import { Repositories } from "../../../data"
import { BundleActionTypes, BundleActions } from "../../../data/business"
import { addOrRemoveItem, getAvailableTireSize, removeFields } from "../../../data/helpers"
import { DRTire } from "../../../data/models"
import { TyresCritsRequest, TyresCritsResponse } from "../../../data/repositories"
import { Statics } from "../../../data/statics"
import { MainState } from "../../main"
import { MainActionsType } from "../../main/business"
import { createTiresCritsRequest } from "./helpers"
import { ISummaryFilters, SelectedFilters, SummaryMultiFilters, SummaryState } from "./model"
import { EFilterNames, TyreFilter } from "@tm/models"

export * from "./model"
export * from "./helpers"

export type ComponentActionType = BundleActionTypes
	| { type: "DRIVE_RIGHT_TIRES_LOADING" }
	| { type: "DRIVE_RIGHT_TIRES_LOADED", payload: { frontTires: DRTire[], rearTires: DRTire[], usedTecDoc?: number } }
	| { type: "DRIVE_RIGHT_TIRES_ERROR" }

	| { type: "SUMMARY_FILTERS_LOADING", }
	| { type: "SUMMARY_FILTERS_LOADED", payload: { response: TyresCritsResponse, request: TyresCritsRequest } }
	| { type: "SUMMARY_FILTERS_ERROR" }

	| { type: "UPDATE_SUMMARY_FILTERS", payload: { path: ISummaryFilters, value: TyreFilter } }
	| { type: "RESET_SUMMARY_FILTER", payload: { path: ISummaryFilters, selectedFilters: SelectedFilters } }
	| { type: "SET_TIRE_SIZES" }
	| { type: "TOGGLE_TIRES_ACCORDION", payload?: boolean | undefined }

export const DEFAULT_STATE: SummaryState = {
	driveRightTires: {
		frontTires: [],
		rearTires: []
	},
	filters: {
		initialized: false,
		width: [],
		height: [],
		inch: [],
		speedIndex: [],
		loadIndex: [],
		oeIdentifier: [],
		carType: [],
		season: Statics?.seasons,
		carTypeAndSeason: undefined // keep undefined once initialized!
	},
	selectedFilters: {
		carType: [],
		season: undefined,
		untouched: true
	},
	toggleTiresAccordion: false
}

export function reduce(state = { ...DEFAULT_STATE }, action: MainActionsType): SummaryState {
	switch (action.type) {
		case "SUMMARY_FILTERS_LOADING": {
			return {
				...state,
				filters: {
					...state.filters,
					loading: true
				}
			}
		}
		case "SUMMARY_FILTERS_LOADED": {
			const { request, response } = action.payload
			const selectedFilters = clone(state.selectedFilters)
			if (selectedFilters.width && !response.width.find(x => x.value == selectedFilters.width!.value))
				delete selectedFilters.width
			if (selectedFilters.height && !response.height.find(x => x.value == selectedFilters.height!.value))
				delete selectedFilters.height
			if (selectedFilters.inch && !response.inch.find(x => x.value == selectedFilters.inch!.value))
				delete selectedFilters.inch
			if (selectedFilters.speedIndex && !response.speedIndex.find(x => x.value == selectedFilters.speedIndex!.value))
				delete selectedFilters.speedIndex
			if (selectedFilters.loadIndex && !response.loadIndex.find(x => x.value == selectedFilters.loadIndex!.value))
				delete selectedFilters.loadIndex

			return {
				...state,
				filters: {
					...state.filters,
					...response,
					loading: false,
					initialized: true
				},
				lastFiltersSearch: JSON.stringify(request),
				selectedFilters
			}
		}

		case "SUMMARY_FILTERS_ERROR": {
			return {
				...state,
				filters: {
					...state.filters,
					loading: false,
					initialized: true
				},
			}
		}
		
		case "DRIVE_RIGHT_TIRES_LOADING": {
			return {
				...state,
				driveRightTires: {
					...state.driveRightTires,
					loading: true
				}
			}
		}
		case "DRIVE_RIGHT_TIRES_LOADED": {
			const { frontTires, rearTires, usedTecDoc } = action.payload
			return {
				...state,
				driveRightTires: {
					...state.driveRightTires,
					loading: false,
					frontTires,
					rearTires,
					lastTecDocSearched: usedTecDoc
				}
			}
		}
		case "DRIVE_RIGHT_TIRES_ERROR": {
			return {
				...state,
				driveRightTires: {
					...state.driveRightTires,
					loading: false,
					error: true
				}
			}
		}
		case "UPDATE_SUMMARY_FILTERS": {
			const { value, path } = action.payload

			const oldStoredValues: TyreFilter | TyreFilter[] | undefined = getValue(state.selectedFilters, [path])
			let newValues = equals(value, oldStoredValues) ? oldStoredValues : value

			if (SummaryMultiFilters.includes(path)) //if is multiSelection
				newValues = addOrRemoveItem(oldStoredValues as TyreFilter[], value)
			else if (path === EFilterNames.season) //it's needed for season, not sure if it's needed for all singleSelection
				newValues = newValues || oldStoredValues

			let carTypeSizes
			if (path == EFilterNames.carType) {
				newValues = state.selectedFilters.untouched ? [value] : newValues
				//take all car types and then select the first one and select the sizes based on that
				carTypeSizes = getAvailableTireSize(newValues as TyreFilter[], state.filters)
			}

			return {
				...state,
				selectedFilters: {
					...state.selectedFilters,
					[path]: newValues,
					untouched: state.selectedFilters.untouched && path == EFilterNames.carType ? false : state.selectedFilters.untouched,
					...carTypeSizes && {
						width: carTypeSizes.width,
						inch: carTypeSizes.inch,
						height: carTypeSizes.height ? carTypeSizes.height : undefined
					}
				}
			}
		}
		case "RESET_SUMMARY_FILTER": {
			const { path, selectedFilters } = action.payload
			return {
				...state,
				selectedFilters: {
					...selectedFilters,
					[path]: undefined
				}
			}
		}
		case "TYRES_ARTICLES_LOADED": {
			const { usedCriteria } = action.payload
			const { width, height, inch } = usedCriteria

			return {
				...state,
				selectedFilters: {
					...state.selectedFilters,
					...(width && { width }),
					...(height && { height }),
					...(inch && { inch }),
					// ...(loadIndex?.length && { loadIndex }),
					// ...(speedIndex?.length && { speedIndex }),
				}
			}
		}
		case "INIT": {
			const allCarTypes = Statics.getCarTypes()
			const carType: TyreFilter[] = []
			action.payload.carType?.forEach(y => {
				const newVal = allCarTypes.find(x => x.query == y)
				if (newVal) {
					carType.push(newVal)
				}
			})
			const season = Statics.seasons.find(x => x.value == action.payload.season)

			return {
				...state,
				selectedFilters: {
					...state.selectedFilters,
					...carType.length && season && {
						season,
						carType
					},
					untouched: !carType.length
				}

			}
		}
		case "TYRES_ARTICLES_LOADING": {
			return {
				...state,
				ignoreVehRecors: true
			}
		}
		case "SAVE_VEHICLE_RECORDS": {
			if (state.ignoreVehRecors) return state
			return {
				...state,

				selectedFilters: {
					...state.selectedFilters,
					...action.payload
				}
			}
		}
		case "SET_TIRE_SIZES": {
			const { width, inch, height } = state.selectedFilters.carType?.length > 0 && getAvailableTireSize(state.selectedFilters.carType, state.filters) || {}

			if (!width && !inch && !height) {
				return state
			}

			return {
				...state,
				selectedFilters: {
					...state.selectedFilters,
					width,
					inch,
					height
				}
			}
		}
		case "TOGGLE_TIRES_ACCORDION": {
			return {
				...state,
				toggleTiresAccordion: typeof action.payload === "boolean" ? action.payload : !state.toggleTiresAccordion
			}
		}
	}
	return state
}

function loadDriveRightTyres(): AsyncAction<ComponentActionType, MainState> {
	return (dispatch, getState) => {
		const { summary: { driveRightTires: { lastTecDocSearched } }, manager: { vehicle } } = getState()
		if (vehicle && vehicle.tecDocTypeId != lastTecDocSearched) {
			dispatch({ type: "DRIVE_RIGHT_TIRES_LOADING" })
			Repositories.loadDriveRightTires(vehicle.tecDocTypeId).then(
				response => dispatch({ type: "DRIVE_RIGHT_TIRES_LOADED", payload: { ...response, usedTecDoc: vehicle.tecDocTypeId } }),
				() => dispatch({ type: "DRIVE_RIGHT_TIRES_ERROR" })
			)
		}
	}
}

function loadSummaryFilters(): AsyncAction<MainActionsType, MainState> {
	return (dispatch, getState) => {
		const request = createTiresCritsRequest(getState())
		if (!request || JSON.stringify(request) == getState().summary.lastFiltersSearch)
			return

		dispatch({ type: "SUMMARY_FILTERS_LOADING" })
		
		Repositories.loadTyresCrits(request).then(
			(response) => dispatch({ type: "SUMMARY_FILTERS_LOADED", payload: { response, request } }),
			() => dispatch({ type: "SUMMARY_FILTERS_ERROR" })
		)
	}
}

const updateSummaryFilters = (path: ISummaryFilters, filter: TyreFilter): ComponentActionType =>
	({ type: "UPDATE_SUMMARY_FILTERS", payload: { path, value: filter } })

function resetSummaryFilter(path: ISummaryFilters): AsyncAction<MainActionsType, MainState> {
	return (dispatch, getState) => {

		let { summary: { selectedFilters } } = getState()

		switch (path) {
			case EFilterNames.carType:
			case EFilterNames.season:
				selectedFilters = removeFields(selectedFilters, EFilterNames.loadIndex, EFilterNames.speedIndex, EFilterNames.oeIdentifier)
				break
			case EFilterNames.width:
				selectedFilters = removeFields(selectedFilters, EFilterNames.height, EFilterNames.inch, EFilterNames.loadIndex, EFilterNames.speedIndex, EFilterNames.oeIdentifier)
				break
			case EFilterNames.height:
				selectedFilters = removeFields(selectedFilters, EFilterNames.inch, EFilterNames.loadIndex, EFilterNames.speedIndex, EFilterNames.oeIdentifier)
				break
		}

		dispatch({ type: "RESET_SUMMARY_FILTER", payload: { path, selectedFilters } })
	}
}

const toggleTiresAccordion = (state?: boolean | undefined): ComponentActionType => ({ type: "TOGGLE_TIRES_ACCORDION", payload: state })

const setTireSizes = (): ComponentActionType => ({ type: "SET_TIRE_SIZES" })

export type IActions = typeof Actions

export const Actions = {
	...BundleActions,
	loadDriveRightTyres,
	loadSummaryFilters,
	updateSummaryFilters,
	resetSummaryFilter,
	setTireSizes,
	toggleTiresAccordion
}
