import { TabControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import * as React from "react"
import Restrictions from "./restrictions"
import { useDispatch, useSelector } from "react-redux"
import { mountingPadsTabs } from "../../../../data/helpers"
import { MainState } from "../../../main"
import { Actions } from "../../business"

type Props = {}

const Mountingpads: React.FC<Props> = ({ }) => {
    const { translateText } = useLocalization()
    const dispatch = useDispatch()

    const [selectedTab, setSelectedTab] = React.useState("TiresRestrictions")

    const { rimInfoTireRestrictionsResponse, rimInfoRimRestrictionsResponse } = useSelector((s: MainState) => ({
        rimInfoTireRestrictionsResponse: s.wheelsDetails.rimInfoTireRestrictionsResponse,
        rimInfoRimRestrictionsResponse: s.wheelsDetails.rimInfoRimRestrictionsResponse
    }))

    React.useEffect(() => {
        !rimInfoTireRestrictionsResponse.data.length && dispatch(Actions.loadTiresRestrictions())
    }, [])

    return (
        <div className="mounting-pads">
            <TabControl className="mounting-pads-tabs" selectedTabIdentifier={selectedTab} onTabSelect={setSelectedTab}>
                {mountingPadsTabs.map((item, idx) => <TabControl.Tab key={idx} identifier={item.path}>
                    {translateText(item.translationId)}
                </TabControl.Tab>)}
            </TabControl>
            {selectedTab == "TiresRestrictions" && <Restrictions data={rimInfoTireRestrictionsResponse.data} loading={rimInfoTireRestrictionsResponse.loading} />}
            {selectedTab == "RimsRestrictions" && <Restrictions data={rimInfoRimRestrictionsResponse.data} loading={rimInfoRimRestrictionsResponse.loading} />}
        </div>
    )
}


export default Mountingpads