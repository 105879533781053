/* eslint-disable no-console */

import { PartslifeBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"

let bundleParams: PartslifeBundleParams

export const version = {
    name: "partslife",
    version: "2.0.0",
}

function checkBundleParams(params: PartslifeBundleParams) {
    if (!isDevtoolEnabled()) {
        return
    }

    if (!params?.moduleModalUrl) {
        console.warn("moduleModalUrl must be set in order to use the module")
    }
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle ${version.name}`)
    }

    return bundleParams
}

export function initPartslifeBundle(params: PartslifeBundleParams) {
    bundleParams = params
    checkBundleParams(params)
}
