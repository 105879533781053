import { SelectionListItem, SelectionListItemGroup } from "@tm/controls"
import { ProductGroupFilter, DataSupplierFilter, ArticleAttributeFilter, AttributeFilter, Filter } from "../../../data/model"
import { CriterionFilter, CriterionFilterGroup } from "../../../data/model/uni-parts"

function mapFilter(filter: Filter): SelectionListItem {
    return {
        name: filter.name || "-",
        sortNumber: filter.priority,
        query: filter.id.toString(),
        resultCount: filter.articleCount,
    }
}

function mapAttributeFilter(attribute: AttributeFilter): SelectionListItem {
    return {
        description: attribute.description,
        groupName: attribute.group,
        name: attribute.name,
        sortNumber: attribute.priority,
        query: attribute.query,
        resultCount: attribute.articleCount,
    }
}

export function mapProductGroupFilter(filter: ProductGroupFilter, showOnlyPriorityResultCounts: boolean): SelectionListItem {
    const item = mapFilter(filter)

    if (filter.showOnTop && filter.isTopPriority) {
        if (filter.hasTopPrioritySuppliers) {
            item.priority = "high"
        } else {
            item.priority = "high-normal"
        }
    } else if (filter.hasTopPrioritySuppliers) {
        item.priority = "normal-high"
    } else {
        item.priority = "normal"
    }

    if (showOnlyPriorityResultCounts && filter.showOnTop) {
        item.resultCount = filter.topPriorityArticleCount
    }

    return item
}

export function mapDataSupplierFilter(filter: DataSupplierFilter, showOnlyPriorityResultCounts: boolean): SelectionListItem {
    const item = mapFilter(filter)
    item.sortNumber = undefined

    if (filter.showOnTop) {
        if (filter.isTopPriority) {
            item.priority = "high"
        } else {
            item.priority = "high-normal"
        }
    } else if (filter.isTopPriority) {
        item.priority = "normal-high"
    } else {
        item.priority = "normal"
    }

    if (showOnlyPriorityResultCounts && filter.showOnTop) {
        item.resultCount = filter.topPriorityArticleCount
    }

    return item
}

export function mapArticleAttributeFilter(filter: ArticleAttributeFilter): SelectionListItem {
    const item = mapAttributeFilter(filter)
    // prevent description from being rendered at article filter list
    item.description = ""

    if (filter.text) {
        if (item.name) {
            item.name += ": "
        } else {
            item.name = ""
        }

        item.name += `${filter.text} ${filter.unit || ""}`.trim()
    }

    item.priority = "high"
    return item
}

export function mapUniCriterionFilter(filter: CriterionFilter): SelectionListItem {
    const item = mapAttributeFilter(filter)
    item.description = undefined

    if (filter.priority === 1) {
        item.priority = "high"
    } else {
        item.priority = "normal"
    }

    return item
}

export function mapUniCriterionFilterGroup(filterGroup: CriterionFilterGroup): SelectionListItemGroup {
    const group: SelectionListItemGroup = {
        name: filterGroup.description,
        items: filterGroup.criterionFilters.map(mapUniCriterionFilter),
    }

    if (filterGroup.priority === 1) {
        group.priority = "high"
    } else {
        group.priority = "low"
    }

    return group
}

export function updateFilterItemsPriority(items: Array<SelectionListItem>): Array<SelectionListItem> {
    if (items.some((x) => x.priority === "high" || x.priority === "high-normal")) {
        return items
    }

    let secondCheck = true

    // if there are no high(-normal) priority filters, change the normal-high priority to high priority
    let mappedItems: Array<SelectionListItem> = items.map((x) => {
        if (x.priority === "normal-high") {
            secondCheck = false
            return { ...x, priority: "high" }
        }

        return x
    })

    // if there are no high(-normal) priority filters, change the normal priority to high-normal priority
    if (secondCheck) {
        mappedItems = mappedItems.map((x) => {
            if (x.priority === "normal") {
                return { ...x, priority: "high-normal" }
            }

            return x
        })
    }

    return mappedItems
}
