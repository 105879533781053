import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { Badge, Box, LinkTab, Loader, styled, Tabs } from "@tm/components"
import { CisCustomerVoucherType, UserModuleType } from "@tm/models"
import { useCisReturnItemsTotalNumbers, useShowCisOptions, useUser } from "@tm/context-distribution"
import { Link, useParams } from "react-router-dom"
import { TabVoucherType } from "./model"

type Props = {
    subpageRoute: string
    hasInvoices?: boolean
    rightTabs?: Array<TabVoucherType>
}

type ComponentRouteProps = {
    subpage?: string
    voucherTypeId?: string
}

const translationRegex = /\{\{(.*?)\}\}/g

const StyledTabs = styled(Tabs)({
    "& .MuiTab-root": {
        margin: "7px 4px 4px 8px",
    },

    "& .MuiTabs-indicator": {
        bottom: "4px",
    },
})

function getPageContext(matchParams: ComponentRouteProps): string | undefined {
    if (matchParams.subpage === "vouchers") {
        return matchParams.voucherTypeId
    }
    return matchParams.subpage
}

export default function NavigationComponent({ subpageRoute, hasInvoices, rightTabs }: Props) {
    const { translateText } = useLocalization()
    const { cisOptions, cisOptionsLoading } = useShowCisOptions()
    const matchParams = useParams<ComponentRouteProps>()
    const [value, setValue] = useState(getPageContext(matchParams))
    const { totalNumbers, totalNumbersLoading } = useCisReturnItemsTotalNumbers()
    const { userContext } = useUser()

    const cisModule = userContext.modules?.find((x) => x.type === UserModuleType.CustomerInformation)
    const hasModuleCosts = cisModule?.moduleCosts

    useEffect(() => {
        setValue(getPageContext(matchParams))
    }, [matchParams])

    function renderTab(tab: TabVoucherType, voucherTab: boolean) {
        let url: string

        if (!tab || !tab.typeId) {
            return null
        }

        if (!voucherTab) {
            url = renderRoute(subpageRoute, { ...matchParams, subpage: tab.typeId, voucherTypeId: null })
        } else {
            url = renderRoute(subpageRoute, { ...matchParams, subpage: "vouchers", voucherTypeId: tab.typeId })
        }

        return <LinkTab component={Link} value={tab.typeId} key={tab.typeId} label={tab.description} to={url} disableRipple />
    }

    /* wrab tabs component to avoid stretches, the active indicator will leave his position NEXT-23527 */
    return (
        <Box>
            {value && (
                <StyledTabs value={value} variant="scrollable">
                    {renderTab(
                        {
                            typeId: "summary",
                            description: translateText(409),
                        },
                        false
                    )}
                    {cisOptions?.returnReasons &&
                        renderTab(
                            {
                                typeId: "returns",
                                description: translateText(1515),
                            },
                            false
                        )}
                    {cisOptions?.returnReasons && (
                        <Badge
                            size="small"
                            badgeContent={totalNumbersLoading ? <Loader size="extrasmall" /> : totalNumbers?.numberOfPositions}
                            sx={{ top: "12px" }}
                        />
                    )}
                    {hasInvoices &&
                        renderTab(
                            {
                                typeId: "invoices",
                                description: translateText(656),
                            },
                            false
                        )}
                    {cisOptionsLoading ? (
                        <Loader size="small" />
                    ) : (
                        cisOptions?.voucherTypes?.map((tab: CisCustomerVoucherType) =>
                            renderTab(
                                {
                                    typeId: String(tab.typeId),
                                    description: tab.description,
                                },
                                true
                            )
                        )
                    )}
                    {rightTabs &&
                        rightTabs.map((tab: TabVoucherType) =>
                            renderTab(
                                {
                                    typeId: tab.typeId,
                                    description: tab.description.replace(translationRegex, (s, num) => translateText(num)),
                                },
                                false
                            )
                        )}
                    {hasModuleCosts &&
                        renderTab(
                            {
                                typeId: "cost-overview",
                                description: translateText(13571),
                            },
                            false
                        )}
                </StyledTabs>
            )}
        </Box>
    )
}
