import { useCallback, useState } from "react"
import { useLocalization } from "@tm/localization"
import { AddCustomWorkListRequest, ECounterType, VatRate, WorkCategory } from "@tm/models"
import { Icon, Tooltip, Button, Stack, LinkButton, Paper } from "@tm/components"
import { TmaHelper } from "@tm/utils"
import WorkFields from "./work-fields"
import { addCustomRepairTimeItems } from "../../../../../data/repositories/custom-items/repair-times"
import { createCustomWork } from "../../../../../data/mapper"
import { AddCustomRepairTimeItemsRequest, CostEstimation } from "../../../../../data/model"

type Props = {
    showRepairTimesInHours: boolean
    costEstimation?: CostEstimation
    repairTimeDivision: number
    repairTimesUrl: string | undefined
    vehicleId?: string
    workTaskId: string
    currencyCode: string
    onAddCustomWork(request: AddCustomWorkListRequest): void
}

export default function AddWork(props: Props) {
    const { workTaskId, repairTimesUrl, vehicleId, showRepairTimesInHours, costEstimation, repairTimeDivision, currencyCode, onAddCustomWork } = props
    const { hourlyRates, defaultWorkVatRates, customerDefaults, costEstimationOptions } = costEstimation ?? {}

    const { translateText } = useLocalization()
    const [openTooltip, setOpenTooltip] = useState<boolean>(false)
    const [customWorkEditorOpen, setCustomWorkEditorOpen] = useState<boolean>(false)

    function toggleCustomWorkEditorOpen() {
        setCustomWorkEditorOpen((prev) => !prev)
        setOpenTooltip(false)
    }

    function handleAddCustomWorkClick(
        saveAsCustomRepairTime: boolean,
        workNumber: string,
        categoryOfWork: WorkCategory,
        description: string,
        time?: number,
        alternativeHourlyRate?: number,
        fixedPriceValue?: number,
        rebate?: number,
        surcharge?: number,
        vatRate?: VatRate
    ) {
        onAddCustomWork({
            workTaskId,
            repairTimeDivision,
            customWorks: [
                createCustomWork(workNumber, categoryOfWork, description, time, alternativeHourlyRate, fixedPriceValue, rebate, surcharge, vatRate),
            ],
            vehicleId,
            usePercentageValues: true,
            costEstimationOptions,
        })
        setCustomWorkEditorOpen(false)

        if (saveAsCustomRepairTime) {
            const request: AddCustomRepairTimeItemsRequest = {
                customRepairTimeItemsToAdd: [
                    {
                        workId: workNumber,
                        category: categoryOfWork,
                        description,
                        workTime: time,
                        customHourlyRate: alternativeHourlyRate,
                        fixedPriceValue,
                        vatRate,
                    },
                ],
                repairTimeDivision,
                priceVatMode: costEstimationOptions?.priceVatMode,
            }
            addCustomRepairTimeItems(request)
        }
    }

    const handleOpenTooltip = useCallback(() => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.CostEstimateAddLabourTime)
        setOpenTooltip(!openTooltip)
    }, [openTooltip])

    if (customWorkEditorOpen && hourlyRates) {
        return (
            <Paper sx={{ width: "100%" }}>
                <WorkFields
                    currencyCode={currencyCode}
                    vatRates={defaultWorkVatRates}
                    hourlyRates={hourlyRates}
                    icon="individual-repairtimes"
                    iconTooltip={translateText(920)}
                    isEditMode={false}
                    rebate={customerDefaults?.worksRebate}
                    repairTimeDivision={repairTimeDivision}
                    showRepairTimesInHours={showRepairTimesInHours}
                    showSaveAsCustomRepairTimeButton
                    surcharge={customerDefaults?.worksSurcharge}
                    title={translateText(920)}
                    onClose={toggleCustomWorkEditorOpen}
                    onConfirm={handleAddCustomWorkClick}
                />
            </Paper>
        )
    }

    return (
        <Tooltip
            title={
                <Stack spacing={0.5}>
                    <Button startIcon={<Icon name="individual-repairtimes" />} variant="outlined" onClick={toggleCustomWorkEditorOpen}>
                        {translateText(920)}
                    </Button>
                    {repairTimesUrl && (
                        <LinkButton startIcon={<Icon name="repairtimes" />} variant="outlined" to={repairTimesUrl} disabled={!repairTimesUrl}>
                            {translateText(83)}
                        </LinkButton>
                    )}
                </Stack>
            }
            variant="light"
            disableHoverListener
            open={openTooltip}
            onClickAway={() => setOpenTooltip(false)}
        >
            <Button
                // remove title from button if used inside from Tooltip to prevent double title use MUI errors
                // correct way is to use a Popper and a Tooltiop outside around the Button
                aria-label={translateText(1507)}
                onClick={handleOpenTooltip}
                startIcon={<Icon name="plus" />}
                color="highlight"
                variant="contained"
            />
        </Tooltip>
    )
}
