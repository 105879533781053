import { Box, styled } from "@tm/components"
import { Icon, Demo } from "@tm/controls"
import { ActiveVehicleDataProviders } from "@tm/models"
import { Overwrite } from "@tm/utils"
import { memo } from "react"

export type NavigationItemStatus = "demo" | "none" | "locked"

export type Props = Overwrite<
    NavigationItemConfigProps,
    {
        disabled: boolean
        disabledTooltip: string
        status: NavigationItemStatus
    }
>

export type NavigationItemConfigProps = {
    sort?: number
    name?: string
    route?: string
    /** No data available */
    disabled?: string | boolean
    disabledFunction?: string
    disabledTooltip?: string
    params?: { [key: string]: any }
    className?: string
    /**  Enabled in MDM (full / demo version) */
    active?: boolean
    /** Can be activatable thru the demo activation  */
    activatable?: boolean
    setAsActiveVehicleDataProviderOnSelect?: ActiveVehicleDataProviders
}

const DemoBadge = styled(Demo)({
    position: "absolute",
    right: 0,
    top: 0,
})

const LockedIcon = styled(Icon)({
    position: "absolute",
    right: 0,
    transform: "translate(-4px, 3px)",
})

const TextContent = styled("div")(({ theme }) => ({
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textAlign: (theme.overwrites?.components?.dropdown?.menu?.justifyContent as "left" | "right" | "center") || "left",
}))

const SpezialIcon = (prop: Props) => {
    const { status } = prop
    switch (status) {
        case "demo":
            return <DemoBadge displayMode="edge" />
        case "locked":
            return <LockedIcon name="lock" size="s" />
        default:
            return null
    }
}
export const ItemContent = memo<Props>((props) => {
    return (
        <Box sx={{ width: "100%" }}>
            <TextContent>
                {props.name} <SpezialIcon {...props} />
            </TextContent>
        </Box>
    )
})
