import { Icon, IconButton, styled, Tooltip } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { UserModuleType } from "@tm/models"
import { useState } from "react"
import { useDisabledFunction } from "../../../../offers/src/hooks"
import { AiWidget } from "./AiWidget"
import { CatalogWidget, WidgetComponentConfigProps } from "./CatalogWidget"
import { DirectSearchWidget, DirectSearchWidgetConfigProps } from "./DirectSearchWidget"

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    "&.MuiButtonBase-root:hover": {
        color: theme.colors?.["highlight-hover"],
    },
    padding: 0,
}))

export type WidgetProps =
    | ({ singleWidget: true; disabledFunction?: string | undefined; isOnIndustryDashboard?: boolean } & DirectSearchWidgetConfigProps)
    | ({
          singleWidget?: false | undefined
          disabledFunction?: string | undefined
          isOnIndustryDashboard?: boolean
      } & WidgetComponentConfigProps)

export function Widget(props: WidgetProps) {
    const { disabledFunction, singleWidget, ...rest } = props

    const { userContext } = useUser()
    const hasAiModule = userContext.modules?.find((m) => m.type === UserModuleType.AiSearch)

    const [hasAiWidget, setHasAiWidget] = useState(false)

    const { translateText } = useLocalization()

    const handleClick = () => {
        setHasAiWidget((prevState) => !prevState)
    }

    const aiSwitch = hasAiModule && (
        <Tooltip title={translateText(hasAiWidget ? 135 : 13835)}>
            <StyledIconButton onClick={handleClick}>
                <Icon name={hasAiWidget ? "search" : "ki-search"} width={hasAiWidget ? "25px" : "45px"} height={hasAiWidget ? "25px" : "39px"} />
            </StyledIconButton>
        </Tooltip>
    )

    const { isDisabled } = useDisabledFunction({ disabledFunction })

    if (isDisabled) {
        return null
    }

    if (hasAiWidget) {
        return <AiWidget {...props} aiSwitch={aiSwitch} isOnIndustryDashboard={props.isOnIndustryDashboard} />
    }

    if (singleWidget) {
        return <DirectSearchWidget {...rest} />
    }

    return <CatalogWidget {...props} aiSwitch={aiSwitch} isOnIndustryDashboard={props.isOnIndustryDashboard} />
}
