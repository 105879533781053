import { notUndefinedOrNull } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSetLongLifeStatus(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { setLongLifeStatus } = data
    const vehicle = this.props.workTask?.vehicle

    if (vehicle && notUndefinedOrNull(setLongLifeStatus) && vehicle.longlife !== setLongLifeStatus) {
        this.props.attachToWorkTask({ vehicle: { ...vehicle, longlife: setLongLifeStatus } })
    }
}
