import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { isDevtoolEnabled } from "@tm/utils"
import { createMainSlice, MainSlice } from "./main"

export type SindriStore = MainSlice

export const useSindriStore = create<SindriStore>()(
    devtools(
        (...a) => ({
            ...createMainSlice(...a),
        }),
        { name: "Sindri Store", enabled: isDevtoolEnabled() }
    )
)

export const { resetStore, mainActions } = useSindriStore.getState()
