import { BundleComponent } from "@tm/morpheus"
import TabControl from "./component"
import type { ItemData } from "./component"

const TabControlBundle: BundleComponent = {
    name: "tab-control",
    component: TabControl,
}

export { TabControlBundle, TabControl, ItemData }
