import { useLocalization } from "@tm/localization"
import { renderRoute, encodeUniqueId, uniqueId, TmaHelper } from "@tm/utils"
import { Widget } from "@tm/controls"
import { Icon } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { getBundleParams } from "../../utils"

type ConfigProps = {
    className?: string
    targetIcon?: string
}

type Props = ConfigProps & {
    openPromoPartsList?: boolean
}

function StartpageButtonComponent(props: Props) {
    const history = useHistory()
    const matchParams = useParams<{ workTaskId: string }>()
    const { className, targetIcon } = props
    const { translateText } = useLocalization()
    const { detailsUrl } = getBundleParams()

    function handleClick() {
        if (props.openPromoPartsList) {
            window.top?.postMessage({ openPromoPartsList: true }, "*")
        } else {
            const url = renderRoute(detailsUrl, { workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()) })
            history.push(url)
            TmaHelper.GeneralCountEvent.CallModule(url)
        }
    }

    return (
        <Widget
            id="offers__startpage-button"
            size="1x1"
            active={false}
            className={`tk-offers startpage-button ${className || ""}`}
            onClick={handleClick}
            cover={
                <Widget.Cover>
                    <Icon className="widget__cover-icon" name="sales" />
                    <div className="widget__cover-text">{translateText(1276)}</div>
                    {targetIcon && <Icon name={targetIcon} className="target-icon" />}
                </Widget.Cover>
            }
        />
    )
}

export default StartpageButtonComponent
