import { CarMainModelSeries } from "@tm/models"
import { callCarsService } from "."
import { CarBodyType, ModelYearFilter } from "../../model/filters"

type SelectedMainModelSeriesFilters = {
    bodyTypeId?: number
    modelYear?: number
}

type MainModelSeriesFilters = {
    bodyTypes: Array<CarBodyType>
    modelYears: Array<ModelYearFilter>
}

export type ShowMainModelSeriesWithModelSeriesRequest = {
    manufacturerId: number
    selectedFilters?: SelectedMainModelSeriesFilters
}

export type ShowMainModelSeriesWithModelSeriesResponse = {
    mainModelSeries: Array<CarMainModelSeries>
    filters: MainModelSeriesFilters
}

export function showMainModelSeriesWithModelSeries(request: ShowMainModelSeriesWithModelSeriesRequest) {
    return callCarsService<ShowMainModelSeriesWithModelSeriesRequest, ShowMainModelSeriesWithModelSeriesResponse>(
        "ShowMainModelSeriesWithModelSeries",
        request
    )
}

export type ShowMainModelSeriesDetailsRequest = {
    manufacturerId: number
    mainModelSeriesId: number | undefined
}

export type ShowMainModelSeriesDetailsResponse = {
    mainModelSeriesDetails: CarMainModelSeries
}

export function showMainModelSeriesDetails(request: ShowMainModelSeriesDetailsRequest) {
    return callCarsService<ShowMainModelSeriesDetailsRequest, ShowMainModelSeriesDetailsResponse>("ShowMainModelSeriesDetails", request)
}
