import { CisContact } from "@tm/models"
import { Stack, Typography } from "@tm/components"
import { renderElectronicAddresses } from "../helpers"

type Props = {
    contact: CisContact
}

export default function SalesOutletPersonComponent({ contact }: Props) {
    let { jobDescription } = contact
    if (contact.id === "1" && !jobDescription) {
        jobDescription = "Verkaufshausleiter"
    }

    function decideIfRenderElectronicAddresses() {
        if (contact.electronicAddresses && contact.electronicAddresses.length === 0 && jobDescription === "Aussendienstmitarbeiter") {
            return renderElectronicAddresses(contact.electronicAddresses.filter((x) => x.type === 3 && x.value?.trim()))
        }
    }

    return (
        <Stack spacing={1.5}>
            {jobDescription && <Typography variant="h3">{jobDescription}</Typography>}
            <Typography>
                {contact.firstName} {contact.lastName}
            </Typography>
            {decideIfRenderElectronicAddresses()}
        </Stack>
    )
}
