import { ActionDispatch, AsyncAction } from "@tm/morpheus"
import { TeccomErpInformation } from "@tm/models"
import { StockInfoState } from "./model"
import { BundleActionType } from "../../../business"
import * as Data from "../../../data"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "TECCOM_LOADING" }
    | { type: "TECCOM_LOADED"; payload: TeccomErpInformation | undefined }
    | { type: "TECCOM_ERROR"; payload: { message?: string } }

const DEFAULT_STATE: StockInfoState = {
    loading: true,
    loaded: false,
    teccomLoading: false,
    teccomLoaded: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): StockInfoState {
    switch (action.type) {
        case "ERP_INFO_DETAILS_LOADED": {
            return {
                ...state,
                loading: false,
                loaded: true,
                erpInfo: action.payload.response,
                request: action.payload.request,
                ...(action.payload.response?.specialProcurementErpInformation && {
                    teccomLoaded: true,
                    teccomErpInfo: {
                        itemId: action.payload.response.itemId,
                        specialProcurementErpInformation: action.payload.response.specialProcurementErpInformation,
                        quantity: action.payload.response.quantity,
                    },
                }),
            }
        }
        case "ERP_INFO_DETAILS_ERROR": {
            return {
                ...state,
                loading: false,
                loaded: true,
                error: action.payload,
            }
        }
        case "ERP_INFO_DETAILS_RESET": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "TECCOM_LOADING": {
            return {
                ...state,
                teccomLoading: true,
                teccomLoaded: false,
            }
        }
        case "TECCOM_LOADED": {
            return {
                ...state,
                teccomLoading: false,
                teccomLoaded: true,
                teccomErpInfo: action.payload,
            }
        }
        case "TECCOM_ERROR": {
            return {
                ...state,
                teccomLoading: false,
                teccomLoaded: true,
                teccomError: action.payload,
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "ERP_INFO_DETAILS_LOADED":
        case "ERP_INFO_DETAILS_ERROR":
        case "ERP_INFO_DETAILS_RESET": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

function loadTeccom(
    quantityValue: number,
    telesalesCustomerNo: string | undefined,
    distributorId: number | undefined
): AsyncAction<ComponentActionType, StockInfoState> {
    return (dispatch, getState) => {
        const state = getState()
        if (state.loading || !state.loaded || state.teccomLoading || !state.request) {
            return
        }

        dispatch({ type: "TECCOM_LOADING" })

        const request = { ...state.request, quantityValue }

        Data.getErpInfosTeccom(request, telesalesCustomerNo, distributorId)
            .then((response) => {
                dispatch({ type: "TECCOM_LOADED", payload: response })
            })
            .catch((error) => dispatch({ type: "TECCOM_ERROR", payload: error }))
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadTeccom,
}
