import { WorkTaskInfo } from "@tm/context-distribution"
import { forwardRef, useCallback, useEffect, useImperativeHandle } from "react"
import { WorkTask } from "../../../../data/model"
import { useAdditionalOrderInformation } from "../../../../hooks/useAdditionalOrderInformation"
import { useBasketMemo } from "../../../../hooks/useBasketMemo"
import AdditionalInformationTextFields from "./AdditionalInformationTextFields"

type Props = {
    hasCustomerOrderNumber: boolean
    hasOrderComment: boolean
    workTask: WorkTask | WorkTaskInfo
    customerOrderNumberMaxLength?: number
    orderCommentMaxLength?: number
    warehouseId?: string
    distributorId?: number
}

export type AdditionalInformationRef = {
    save(saveHandler: (customOrderNumber?: string, customOrderComment?: string) => Promise<void>): Promise<void>
}

export const AdditionalInformation = forwardRef<AdditionalInformationRef, Props>(
    ({ customerOrderNumberMaxLength, workTask, orderCommentMaxLength, hasCustomerOrderNumber, hasOrderComment, distributorId, warehouseId }, ref) => {
        const workTaskId = "workTaskId" in workTask ? workTask.workTaskId : workTask.id
        const [additionalInformation, setAdditionalInformation] = useAdditionalOrderInformation(workTaskId, { distributorId, warehouseId })
        const basketMemo = useBasketMemo(workTask)

        useEffect(() => {
            // Store automatically generated basket order memo on component mount if there isn't already an order comment set

            if (!additionalInformation?.customerOrderComment) {
                setAdditionalInformation({
                    ...additionalInformation,
                    isDirty: true,
                    customerOrderComment: basketMemo.order,
                })
            }
        }, [basketMemo.order])

        useImperativeHandle(
            ref,
            () => ({
                save: async (saveHandler): Promise<void> => {
                    if (additionalInformation?.isDirty) {
                        await saveHandler(additionalInformation?.customerOrderNumber, additionalInformation?.customerOrderComment)
                    }
                },
            }),
            [additionalInformation]
        )

        const onChangeNumber = useCallback(
            (value: string) => {
                setAdditionalInformation({
                    ...additionalInformation,
                    isDirty: true,
                    customerOrderNumber: value,
                })
            },
            [additionalInformation]
        )

        const onChangeComment = useCallback(
            (value: string) => {
                setAdditionalInformation({
                    ...additionalInformation,
                    isDirty: true,
                    customerOrderComment: value,
                })
            },
            [additionalInformation]
        )

        return (
            <AdditionalInformationTextFields
                hasCustomerOrderNumber={hasCustomerOrderNumber}
                hasOrderComment={hasOrderComment}
                customerOrderNumberMaxLength={customerOrderNumberMaxLength}
                orderCommentMaxLength={orderCommentMaxLength}
                onChangeComment={onChangeComment}
                onChangeNumber={onChangeNumber}
                customOrderNumberValue={additionalInformation?.customerOrderNumber}
                customerOrderCommentValue={additionalInformation?.customerOrderComment}
            />
        )
    }
)
