import { ChangeEvent, memo, useCallback, useEffect, useState } from "react"
import { Button, Skeleton } from "@tm/controls"
import { Box, TextField } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../../utils"

type Props = {
    note?: string
    isLoading?: boolean
    onBlur?(note: string): void
    onDelete?(): void
    onSave?(note: string): void
    onChange?(note: string): void
}

export const Note = memo<Props>(({ note, isLoading, onBlur, onDelete, onChange, onSave }) => {
    const [value, setValue] = useState<string>()
    const { translateText } = useLocalization()
    const { enableArticleNotes } = getBundleParams()

    useEffect(() => {
        if ((note && !value) || !note) {
            setValue(note || "")
        }
    }, [note])

    const handleOnInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        onChange && onChange(event.target.value)
        setValue(event.target.value)
    }

    const handleOnBlur = useCallback(() => {
        if (onBlur) {
            onBlur(value || "")
        }
    }, [onBlur, value])

    const handleOnDelete = useCallback(async () => {
        if (!value || !note) {
            return
        }

        onDelete && onDelete()
    }, [onDelete, value, note])

    const handleOnSave = useCallback(async () => {
        if (!value || !onSave) {
            return
        }

        onSave(value)
    }, [onSave, value])

    if (!enableArticleNotes) {
        return null
    }

    return (
        <Box pt={1} display="flex">
            <Box flex={onSave && onDelete ? 9 : 10}>
                {isLoading ? (
                    <Skeleton variant="text" height="40px" />
                ) : (
                    <TextField
                        id="notes"
                        value={value}
                        fullWidth
                        label={translateText(14).toUpperCase()}
                        onBlur={handleOnBlur}
                        onChange={handleOnInputChange}
                        clearButton
                        inputCounter
                        inputProps={{ maxLength: 500 }}
                        aria-describedby="component-helper-text"
                    />
                )}
            </Box>
            {!!(onSave || onDelete) && (
                <Box pl={1} margin="auto 0" textAlign="right">
                    {!!onDelete && <Button title={translateText(69)} onClick={handleOnDelete} size="s" icon="delete" disabled={isLoading || !note} />}
                    {!!onSave && (
                        <Button
                            title={translateText(9)}
                            onClick={handleOnSave}
                            size="s"
                            icon="check"
                            skin="success"
                            disabled={isLoading || !value || value === note}
                        />
                    )}
                </Box>
            )}
        </Box>
    )
})
