import { FastCalculator } from "@tm/data"
import { ButtonLayout } from "../model"

export function getButtonIcon(item: FastCalculator.CalcStateButton) {
    switch (item.type) {
        case FastCalculator.ECalcButtonState.Reset:
            return "synchronize"
        case FastCalculator.ECalcButtonState.AdditionalWork:
            return "additional-work"
        case FastCalculator.ECalcButtonState.FollowupWork:
            return "follow-up-work"
        case FastCalculator.ECalcButtonState.Close:
            return "close"
        case FastCalculator.ECalcButtonState.CreateQuote:
            return "voucher-add-kva"
        case FastCalculator.ECalcButtonState.StartCalculation:
            return "arrow-right"
        default:
            return undefined
    }
}

export function getButtonLabel(item: FastCalculator.CalcStateButton) {
    if (item.type === FastCalculator.ECalcButtonState.Close) {
        return ""
    }

    return item.label
}

export function getButtonSkin(buttonStyle: FastCalculator.ECalcButtonStyle, item?: FastCalculator.CalcStateButton) {
    // TODO This is hotfix only
    if (item?.type === FastCalculator.ECalcButtonState.Close) {
        return undefined
    }

    // TODO moveto helpers
    switch (buttonStyle) {
        case FastCalculator.ECalcButtonStyle.Danger:
            return "danger"
        case FastCalculator.ECalcButtonStyle.Info:
            return "highlight"
        case FastCalculator.ECalcButtonStyle.Primary:
            return "primary"
        case FastCalculator.ECalcButtonStyle.Success:
            return "success"
        case FastCalculator.ECalcButtonStyle.Warning:
            return "highlight"
        case FastCalculator.ECalcButtonStyle.Default:
        default:
            return undefined
    }
}

export function getButtonLayout(item: FastCalculator.ECalcButtonStyle, isTab?: boolean): ButtonLayout[] {
    if (isTab && item === FastCalculator.ECalcButtonStyle.Danger) {
        return ["ghost"]
    }

    switch (item) {
        case FastCalculator.ECalcButtonStyle.Danger:
        case FastCalculator.ECalcButtonStyle.Success:
        case FastCalculator.ECalcButtonStyle.Primary:
        case FastCalculator.ECalcButtonStyle.Info:
        case FastCalculator.ECalcButtonStyle.Warning:
        case FastCalculator.ECalcButtonStyle.Default:
            return ["dropshadow"]
        case FastCalculator.ECalcButtonStyle.Outlined:
            return ["holo"]
        default:
            return ["ghost"]
    }
}
