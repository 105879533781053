import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        dropDown: {
            marginTop: margin.m,
        },
        button: {
            display: "block",
            marginTop: "2rem",
            padding: ".8rem 1rem",
        },
    })
}
