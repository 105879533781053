import { stylesheet } from "typestyle"

export function getComponentStyles() {
    return stylesheet({
        main: {
            position: "relative",
        },
        newMessageBadge: {
            top: 0,
        },
        popover: {
            right: 7,
            $nest: {
                ".popover__inner": {
                    padding: "1rem",
                },
                "&.is-active": {
                    // transform: `translateY(${theme.spacing(1.15)})`,
                    transform: `translateY(9px)`,
                },
            },
        },
    })
}
