import { AsyncAction } from "@tm/morpheus"
import { ComponentActionType } from "."
import { VehicleOverviewState } from "./model"

export function updateVehicleDescription(vehicleId: string, description: string): AsyncAction<ComponentActionType> {
    return (dispatch, getState) => {
        dispatch({ type: "VEHICLE_OVERVIEW_DESCRIPTION_UPDATED", payload: { vehicleId, vehicleDescription: description } })

        const state = getState() as VehicleOverviewState
        if (state.vehicles) {
            dispatch({ type: "VEHICLE_OVERVIEW_LOADED", payload: state.vehicles })
        }
    }
}

export type IActions = typeof Actions

export const Actions = {
    updateVehicleDescription,
}
