import { useDrivemotiveStore } from "../../data/state"
import BookingsList from "./components/BookingsList"
import BookingsDetails from "./components/BookingsDetails"

export default function Summary() {
    const showBookingsDetails = useDrivemotiveStore((state) => state.summary.showBookingDetails)

    if (showBookingsDetails) {
        return <BookingsDetails />
    }

    return <BookingsList />
}
