import { ajax, getStoredAuthorization } from "@tm/utils"
import { DMS } from "@tm/models"
import { getServiceUrl } from "."

export type ImportVehicleRequest = {
    workTaskId: string
    vehicle: DMS.Vehicle
}

export function importVehicle(request: ImportVehicleRequest): Promise<void> {
    return ajax({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportVehicle`,
        authorization: getStoredAuthorization(),
        body: request,
    })
}
