import * as React from "react"
import { useLocalization } from "@tm/localization"
import { renderRoute, encodeUniqueId, uniqueId, RouteComponentProps, withRouter } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { DropdownField, Icon, Widget } from "@tm/controls"
import { useWorkTask } from "@tm/context-distribution"
import { WidgetState, IActions, Actions, Provider } from "./business"

type Props = RouteComponentProps<RouteProps> & {
    state: WidgetState
    actions: IActions
    className?: string
    iconName: string
    textId: number
    providers?: Array<Provider>
    isTecDocTypeIdRequired: boolean
}

type RouteProps = {
    workTaskId?: string
}

function WidgetComponent(props: Props) {
    const { translate } = useLocalization()
    const vehicle = useWorkTask()?.workTask?.vehicle
    const [active, setActive] = React.useState<boolean>(false)
    const { providers, isTecDocTypeIdRequired, iconName, textId, className, actions, history, match, state } = props

    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
        if (!active) {
            setActive(true)
        } else {
            const nativeEvent = e.nativeEvent as any
            if (nativeEvent && nativeEvent.path) {
                if (!nativeEvent.path.some((x: HTMLElement) => x.classList && x.classList.contains("input--dropdown"))) {
                    navigateTo(state.selected)
                }
            } else if (nativeEvent.deepPath) {
                if (
                    !nativeEvent.deepPath().some((x: EventTarget) => {
                        const element = x as HTMLElement
                        return element && element.classList && element.classList.contains("input--dropdown")
                    })
                ) {
                    navigateTo(state.selected)
                }
            }
        }
    }

    function handleChangeProvider(item: number) {
        actions.setSelectedProvider(item)
        navigateTo(item)
    }

    function navigateTo(item: number) {
        if (providers && providers.length) {
            history.push(getUrl(providers[item].route))
        }
    }

    function getUrl(route: string): string {
        return renderRoute(route, { ...match.params, workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()) })
    }

    function renderInner() {
        let providerElement
        if (providers) {
            if (providers.length > 1) {
                const items: { [key: number]: string } = {}
                providers.forEach((item, idx) => (items[idx] = item.name))
                providerElement = <DropdownField items={items} value={state.selected} onChange={handleChangeProvider} />
            } else if (providers.length === 1) {
                providerElement = <div className="widget-mvc__provider-text">{providers[0].name}</div>
            }
        }

        return (
            <div className="widget-mvc__cover">
                <Icon name={iconName} className="widget-mvc__icon" />
                <h2 className="widget-mvc__headline">{translate(textId)}</h2>
                {providerElement}
            </div>
        )
    }

    let enabled = false
    if ((isTecDocTypeIdRequired && !!vehicle?.tecDocTypeId) || !isTecDocTypeIdRequired) {
        enabled = true
    }

    const realClassName = `widget-mvc ${className || ""} ${enabled && providers && providers.length ? "" : "disabled"}`

    return (
        <Widget highlight active={active} size="1x1" title={translate(textId)} iconName={iconName} className={realClassName} onClick={handleClick}>
            {renderInner()}
        </Widget>
    )
}

export default connectComponent(Actions, withRouter(WidgetComponent))
