import { useSelector } from "react-redux"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { DateField, LicensePlateField, SuggestionTextField, TextField, VinField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { Box, Icon, Typography, styled, useTheme } from "@tm/components"
import { validateFields } from "../../../data/helpers"
import { useContainer } from "../../../data/repositories"
import { MainActions, managerSelector, summarySelector } from "../../main/business"

export default function VehicleInfoFields() {
    const localization = useLocalization()
    const { translateText } = localization
    const actions = useActions(MainActions, "updateFieldValue")
    const theme = useTheme()

    const { fields } = useSelector(summarySelector)
    const { vehicle } = useSelector(managerSelector)
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)
    const modelState = validateFields(fields, translateText)

    const handleGetVinSuggestions = (val: string) => {
        const container = useContainer()
        return container.action("getVinSuggestions")(val, vehicle?.tecDocTypeId)
    }

    return (
        <Box borderTop=".1em solid #e2e2e2">
            <Box display="flex" padding={theme.margin?.m}>
                <Icon name="car-front" size="2em" />
                <Typography style={{ marginLeft: theme.margin?.m }} size="xl">
                    {translateText(99)}
                </Typography>
            </Box>

            <Box display="flex" flexWrap="wrap" marginTop="0.5rem" gap=".6em">
                <StyledBox className="key-number">
                    <SuggestionTextField
                        floatingLabel
                        label={translateText(880)}
                        value={fields.keyNumber}
                        getSuggestionData={() => fields.keyNumbers}
                        onChangeConfirm={(value) => actions.updateFieldValue("keyNumber", value)}
                        path={["keyNumber"]}
                        modelState={modelState}
                    />
                </StyledBox>
                <StyledBox className="col initial-registration">
                    <DateField
                        useUtc
                        floatingLabel
                        label={translateText(124)}
                        value={fields.initialRegistration}
                        minDate={fields.minDate}
                        openToDate={fields.initialRegistration || fields.minDate}
                        onChange={(value) => actions.updateFieldValue("initialRegistration", value)}
                        path={["initialRegistration"]}
                        modelState={modelState}
                    />
                </StyledBox>
                <StyledBox className="col vin">
                    <VinField
                        floatingLabel
                        label={translateText(101)}
                        coloredBorder
                        value={fields.vin}
                        onChangeConfirm={(value) => actions.updateFieldValue("vin", value)}
                        onChange={(value) => actions.updateFieldValue("vin", value)}
                        getSuggestionDataAsync={handleGetVinSuggestions}
                        path={["vin"]}
                        modelState={modelState}
                    />
                </StyledBox>
                <StyledBox padding="0.5rem 0 0.5rem 0.5rem" className="plate-number col plateId">
                    <LicensePlateField
                        floatingLabel
                        className="fixHeight"
                        label={translateText(21)}
                        value={fields.plateNumber}
                        shortCountryCode={plateCode}
                        onChange={(value) => actions.updateFieldValue("plateNumber", value)}
                        maxLength={17}
                        path={["plateNumber"]}
                        modelState={modelState}
                        showErrorBorder
                    />
                </StyledBox>
                <StyledBox className="col mileAge">
                    <TextField
                        floatingLabel
                        label={translateText(125)}
                        path={["mileage"]}
                        modelState={modelState}
                        value={fields.mileage || undefined}
                        onChange={(value) => actions.updateFieldValue("mileage", value)}
                        pattern={/[\d.]*/}
                        formatter={(value) => value.replace(/[^\d]/, "").replace(",", "").replace(".", "")}
                    />
                </StyledBox>
            </Box>
        </Box>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    padding: "0.5rem 0 0.5rem 0.5rem",
    "&.vin": {
        flex: "0 0 20%",
    },
    "&.plate-number": {
        flex: "0 0 13%",
        ".plate-id": {
            width: "100%",
        },
    },
    fixHeight: {
        $nest: {
            ".input__inner": {
                height: "2.5rem",
                $nest: {
                    ".input__field": {
                        fontSize: theme.font?.textSize.m,
                        lineHeight: "1.2",
                    },
                },
            },
        },
    },
    "&.key-number": {
        ".suggest__box:first-child": {
            marginLeft: "-.5em",
        },
    },
}))
