import { useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType, Vehicle } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { combiner, equals, initSelector, uniqueId } from "@tm/utils"
import { useState, useEffect, useCallback } from "react"
import { useSelector } from "react-redux"
import { Badge, Button, Icon, Loader } from "@tm/components"
import { getBundleParams } from "../../../utils"
import { MainState } from "../../main"
import { customerSelector, fieldsSelector, MainActions, vehicleSelector } from "../../main/business"
import { validateFields } from "../../summary/business/helpers"
import { customerFieldsSelector } from "../../summary/components/customerFields"
import { vehicleFieldsSelector } from "../../summary/components/vehicleFields"

const selector = initSelector(
    fieldsSelector,
    vehicleSelector,
    customerSelector,
    (s) => s.manager.userIsRegistered,
    vehicleFieldsSelector,
    customerFieldsSelector,
    (s) => s.summary.isGermanOrAustrianVehicle,
    combiner.array
)

export default function SummaryActions() {
    const { translateText } = useLocalization()
    const workTask = useWorkTask()

    const loadingApplyBtn = useSelector((s: MainState) => s.manager.checkCustomerLoading || s.summary.prepareCalculationLoading)

    const actions = useActions(MainActions, "updateFieldValue", "startCalculation", "checkCustomer", "changeModalDisplay")
    const [fields, vehicle, customer, userIsRegistered, vehFields, customerFields, isGermanOrAustrianVehicle] = useSelector(selector)
    const modelState = validateFields(fields, translateText, workTask?.workTask?.vehicle, isGermanOrAustrianVehicle)
    const [isInvalid, setIsInvalid] = useState<boolean>(true)

    useEffect(() => {
        setIsInvalid(Object.values(modelState).some((val) => val.length))
    }, [fields])

    const attachToWorkTask = useCallback(() => {
        const updatedVehicle: Vehicle = {
            ...vehicle!,
            ...(vehFields.initialRegistration !== vehicle?.initialRegistration && { initialRegistration: vehFields.initialRegistration }),
            ...(isGermanOrAustrianVehicle && vehFields.keyNumber !== vehicle?.registrationNo && { registrationNo: vehFields.keyNumber }),
            ...(vehFields.mileage !== vehicle?.mileAge && { mileAge: vehFields.mileage }),
            ...(vehFields.vin !== vehicle?.vin && { vin: vehFields.vin }),
            ...(vehFields.plateNumber !== vehicle?.plateId && { plateId: vehFields.plateNumber }),
            registrationTypeId: RegistrationNoType.DatVin,
        }
        const shouldUpdateVehicle = !!vehicle && !equals(vehicle, updatedVehicle)

        const customerFieldsStreet = `${customerFields.street} ${customerFields.streetNo}`

        const updatedCustomer = {
            id: uniqueId(),
            ...customer,
            ...(customerFields.titleType !== customer?.titleType && { titleType: customerFields.titleType }),
            ...(customerFields.firstName !== customer?.firstName && { firstName: customerFields.firstName }),
            ...(customerFields.lastName !== customer?.lastName && { lastName: customerFields.lastName }),
            ...(customerFieldsStreet !== customer?.street && { street: customerFieldsStreet }),
            ...(customerFields.zip !== customer?.zip && { zip: customerFields.zip }),
            ...(customerFields.city !== customer?.city && { city: customerFields.city }),
            ...(customerFields.country !== customer?.country && { country: customerFields.country }),
        }
        const shouldUpdateCustomer =
            !equals(customer, updatedCustomer) && !!customerFields.titleType && !!customerFields.firstName && !!customerFields.lastName

        if (shouldUpdateVehicle || shouldUpdateCustomer) {
            workTask?.attachToWorkTask({
                ...(shouldUpdateVehicle && { vehicle: updatedVehicle }),
                ...(shouldUpdateCustomer && { customer: updatedCustomer }),
            })
        }
    }, [vehFields, customerFields])

    const startNewCalculation = () => {
        getBundleParams().useAttachToWorktask && attachToWorkTask()

        if (!userIsRegistered) {
            actions.changeModalDisplay(true)
            return
        }

        actions.startCalculation(translateText)
    }

    const renderStartCalculationButton = () => {
        return (
            <Badge badgeContent={loadingApplyBtn ? <Loader size="extrasmall" /> : null}>
                <Button
                    color="highlight"
                    startIcon={<Icon name="damage-calculation" />}
                    disabled={isInvalid || loadingApplyBtn}
                    onClick={startNewCalculation}
                >
                    {translateText(1461)}
                </Button>
            </Badge>
        )
    }

    return <Toolbar title={translateText(12884)}>{renderStartCalculationButton()}</Toolbar>
}
