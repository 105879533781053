import { VeFiles } from "@tm/data"
import { useMutation } from "react-query"
import { useCallback } from "react"

export function useGetUploadKey() {
    const { isLoading, mutateAsync } = useMutation(VeFiles.getUploadKey)

    const getUploadKey = useCallback(
        (maxFileCount, vehicleId, category, mileage, date, invoiceTotal) => {
            const request: VeFiles.GetUploadKeyRequest = {
                maxFileCount,
                application: "VeFiles",
                vehicleId,
                category,
                mileage,
                date,
                invoiceTotal,
            }

            return mutateAsync(request)
        },
        [mutateAsync]
    )

    return { isLoading, getUploadKey }
}
