import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, receive } from "../_shared/article-references-business"

const articleReferencesTypes: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "article-references-types",
    reduce,
    component,
    receive,
}

export default articleReferencesTypes
