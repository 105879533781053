import { AsyncAction } from "@tm/morpheus"
import { CustomerShortInfo } from "@tm/models"
import { CustomerRepository } from "../../../data"

export type ComponentActionType = { type: "SUGGESTIONS_LOADED"; payload: CustomerShortInfo[] } | { type: "SUGGESTIONS_LOADED_ERROR" }

export type WidgetState = { requestedSuggestions: Array<CustomerShortInfo> }

const DEFAULT_STATE: WidgetState = {
    requestedSuggestions: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): WidgetState {
    switch (action.type) {
        case "SUGGESTIONS_LOADED": {
            return {
                ...state,
                requestedSuggestions: action.payload,
            }
        }
        case "SUGGESTIONS_LOADED_ERROR": {
            return {
                ...state,
                requestedSuggestions: [],
            }
        }
        default:
            break
    }
    return state
}

function loadSuggestions(query: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        const searchOptions = {
            query: query.trim(),
            pageIndex: 1,
            pageSize: 10,
        }

        CustomerRepository.findCustomers(searchOptions)
            .then((result) => dispatch({ type: "SUGGESTIONS_LOADED", payload: result?.customers || [] }))
            .catch(() => dispatch({ type: "SUGGESTIONS_LOADED_ERROR" }))
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadSuggestions,
}
