import { useLocalization } from "@tm/localization"
import { Box, Divider, Stack, Typography } from "@tm/components"
import { VoucherItem } from "../../data/model"

type Props = {
    voucherItems: Array<VoucherItem>
    currencyCode: string
    hideTotal?: boolean
}

export default function VoucherItemsSummaryComponent({ voucherItems, currencyCode, hideTotal }: Props) {
    const { currency, translateText } = useLocalization()

    let totalNetto = 0
    let total = 0

    voucherItems?.forEach((item) => {
        if (item) {
            if (item.totalNet) {
                totalNetto += item.totalNet
            }
            if (item.totalGross) {
                total += item.totalGross
            }
        }
    })

    return (
        <Stack direction="column" spacing={0.5}>
            <Divider />
            <Stack direction="row" justifyContent="space-between">
                <Typography>{translateText(57)}</Typography>
                <Stack direction="row" spacing={8}>
                    {currency(totalNetto, currencyCode)}
                    {!hideTotal && <Box ml={8}>{currency(total, currencyCode)}</Box>}
                </Stack>
            </Stack>
            <Divider />
            <Divider />
        </Stack>
    )
}
