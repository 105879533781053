import {
    Article,
    FuelType,
    RegisteredModels,
    ShowBikeModelDetailsResponse,
    ShowCarModelDetailsResponse,
    ShowTruckModelDetailsResponse,
    UserContext,
    Vehicle,
    VehicleType,
} from "@tm/models"
import { ActionDispatch, AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { clone, setValue } from "@tm/utils"
import { batch } from "react-redux"

import { BundleActions, BundleActionType } from "../../../business"
import { Models, Repositories } from "../../../data"
import { ArticleDetailsResponse, ExternalDocument } from "../../../data/model"
import { dateToString, getRelatedExternalModules, mapArticleDetailsForDocumentsRequest, mapVehicleInfoForDocumentsRequest } from "./helpers"
import { AvailableTabs, DocumentRequest, DocumentsState, Tab } from "./model"

export type ComponentActionType =
    | BundleActionType
    | {
          type: "OPEN_DOCUMENT_DIALOG"
          payload: { request: DocumentRequest; tabs: Tab[]; documents: ExternalDocument[]; keepVehicleInfo?: boolean }
      }
    | { type: "CLOSE_DOCUMENT_DIALOG" }
    | { type: "UPDATE_FIELD"; payload: { path: string[]; value: any } }
    | { type: "LOADING_DOCUMENT"; payload: boolean }
    | { type: "DETAILSHEAD_LOADED"; payload: Models.ArticleDetailsResponse }
    | {
          type: "DETAILS_LOADED"
          payload: ShowCarModelDetailsResponse & ShowBikeModelDetailsResponse & ShowTruckModelDetailsResponse
      }
    | { type: "SUPPLIER_INFO_LOADED"; payload: Models.SupplierInfo }
    | { type: "EXTERNAL_SYSTEM_DOCUMENTS_LOADED"; payload: ExternalDocument[] }
    | { type: "SET_SELECTED_TAB"; payload?: AvailableTabs }
    | { type: "GET_RELATED_DOCUMENTS"; payload?: UserContext }
    | { type: "CHANGE_ARTICLE_QUANTITY_RETRANSMITTED_BY_DETAILS"; payload: { article: Article; quantity: number } }
    | { type: "SHOW_VEHICLE_MODAL"; payload: boolean }
    | { type: "SET_SELECTED_DOCUMENT"; payload: ExternalDocument }
    | { type: "VEHICLE_FUEL_TYPES_LOADED"; payload: Array<FuelType> }

const DEFAULT_STATE: DocumentsState = {
    showModal: false,
    loading: false,
    tabs: [],
    fuelTypes: [],
}

export function reduce(state = clone(DEFAULT_STATE), action: ComponentActionType): DocumentsState {
    switch (action.type) {
        case "OPEN_DOCUMENT_DIALOG": {
            const { documents, tabs, request: model, keepVehicleInfo } = action.payload
            const { carModel, vehicle } = state

            return {
                ...state,
                showModal: true,

                model: {
                    ...model,
                    documents,
                    ...(!keepVehicleInfo && {
                        vehicleInfo: {},
                    }),
                    ...(keepVehicleInfo &&
                        carModel &&
                        vehicle && {
                            vehicleInfo: mapVehicleInfoForDocumentsRequest(state.carModel, state.vehicle),
                        }),
                },
                tabs,
                selectedTab: tabs?.[0]?.id,
                vehicleDataIsMandatory: keepVehicleInfo,
            }
        }
        case "CLOSE_DOCUMENT_DIALOG": {
            return {
                ...state,
                showModal: false,
            }
        }
        case "UPDATE_FIELD": {
            const { path, value } = action.payload
            let obj = clone(state.model)
            if (obj) {
                obj = setValue(obj, path, value)
            }

            return {
                ...state,
                model: obj,
            }
        }
        case "LOADING_DOCUMENT": {
            return {
                ...state,
                loading: action.payload,
            }
        }
        case "DETAILSHEAD_LOADED": {
            return {
                ...state,
                articleDetailsResponse: action.payload,
                ...(state.model && {
                    model: {
                        ...state.model,
                        ...(state.model.returnsInfo && {
                            returnsInfo: {
                                ...state.model.returnsInfo,
                                additionalParts: [],
                            },
                        }),
                        articleDetails: mapArticleDetailsForDocumentsRequest(action.payload),
                    },
                }),
            }
        }
        case "EXTERNAL_SYSTEM_DOCUMENTS_LOADED": {
            return {
                ...state,
                articleDetailsResponse: {
                    ...state.articleDetailsResponse,
                    externalDocuments: action.payload,
                } as DocumentsState["articleDetailsResponse"],
            }
        }
        case "VEHICLE_SET": {
            return {
                ...state,
                vehicle: action.payload,
                ...(state.model && {
                    model: {
                        ...state.model,
                        vehicleInfo: {
                            ...state.model.vehicleInfo,
                            firstRegistrationYear: dateToString(action.payload?.initialRegistration),
                            deinstallationMileage: action.payload?.mileAge?.toString() ?? "",
                        },
                    },
                }),
            }
        }
        case "DETAILS_LOADED": {
            const fuelTypeId = state.fuelTypes.find((x) => x.value.toLowerCase() == action.payload.modelDetails.fuelType?.toLowerCase())?.id || 0
            return {
                ...state,
                carModel: {
                    ...action.payload,
                    modelDetails: {
                        ...action.payload.modelDetails,
                        fuelTypeId,
                    },
                },
                ...(state.model && {
                    model: {
                        ...state.model,
                        vehicleInfo: {
                            ...mapVehicleInfoForDocumentsRequest(action.payload, state.vehicle),
                            fuelTypeId,
                        },
                    },
                }),
            }
        }
        case "SUPPLIER_INFO_LOADED": {
            return {
                ...state,
                supplierInfo: action.payload,
            }
        }
        case "SET_SELECTED_TAB": {
            return {
                ...state,
                selectedTab: action.payload,
            }
        }
        case "GET_RELATED_DOCUMENTS": {
            const docs = getRelatedExternalModules(action.payload, state.articleDetailsResponse?.externalDocuments)
            return {
                ...state,
                articleDetailsResponse: {
                    ...state.articleDetailsResponse,
                    externalDocuments: docs,
                } as ArticleDetailsResponse,
            }
        }
        case "CHANGE_ARTICLE_QUANTITY_RETRANSMITTED_BY_DETAILS": {
            const quatity = action.payload.quantity

            if (quatity > 0) {
                return {
                    ...state,
                    articleDetailsResponse: {
                        ...state.articleDetailsResponse,
                        quantity: quatity,
                    },
                    ...(state.model && {
                        model: {
                            ...state.model,
                            articleDetails: {
                                ...state.model.articleDetails,
                                quantity: quatity,
                            },
                        },
                    }),
                    articleQuantity: quatity,
                } as DocumentsState
            }
            return state
        }
        case "SET_SELECTED_DOCUMENT": {
            return {
                ...state,
                mainDocument: action.payload,
            }
        }
        case "SHOW_VEHICLE_MODAL": {
            return {
                ...state,
                showVehicleModal: action.payload,
                vehicleDataIsMandatory: undefined,
            }
        }
        // case "CUSTOMER_SET": {
        // 	return { ...state, customer: action.payload }
        // }
        case "VEHICLE_FUEL_TYPES_LOADED": {
            return {
                ...state,
                fuelTypes: action.payload,
            }
        }
        default:
            break
    }
    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType, DocumentsState>) {
    switch (action.type) {
        case "DETAILSHEAD_LOADED": {
            if (action.payload) {
                batch(() => {
                    dispatch(action)
                    dispatch(loadSupplierInfoById(action.payload.article && action.payload.article.supplier.id))
                })
            }
            break
        }
        case "CHANGE_ARTICLE_QUANTITY_RETRANSMITTED_BY_DETAILS": {
            if (action.payload) {
                dispatch(action)
            }

            break
        }
        default:
            break
    }
}

const loadSupplierInfoById =
    (id?: number): AsyncAction<ComponentActionType> =>
    async (dispatch) => {
        if (!id) {
            return
        }
        const supplierInfo = await Repositories.getSupplierInformationById(id)
        dispatch({ type: "SUPPLIER_INFO_LOADED", payload: supplierInfo })
    }

const getAllVehicleFuelTypes =
    (fallbackItems: Array<FuelType>): AsyncAction<ComponentActionType> =>
    async (dispatch) => {
        await Repositories.getAllVehicleFuelTypes().then(
            (fuelTypes) => {
                dispatch({ type: "VEHICLE_FUEL_TYPES_LOADED", payload: fuelTypes })
            },
            () => dispatch({ type: "VEHICLE_FUEL_TYPES_LOADED", payload: fallbackItems })
        )
    }

const loadCarModelDetails =
    (tecDocTypeId: number, vehicleType: VehicleType): AsyncAction<ComponentActionType, DocumentsState> =>
    async (dispatch, getState) => {
        const { carModel } = getState()

        if (!carModel || carModel?.modelDetails?.id !== tecDocTypeId) {
            dispatch({
                type: "DETAILS_LOADED",
                payload: await Container.getInstance<ShowCarModelDetailsResponse & ShowBikeModelDetailsResponse & ShowTruckModelDetailsResponse>(
                    RegisteredModels.Vehicle_ModelDetails
                )
                    .subscribe({ modelId: tecDocTypeId }, vehicleType)
                    .load(),
            })
        }
    }

const setVehicle = (vehicle: Vehicle): ComponentActionType => ({ type: "VEHICLE_SET", payload: vehicle })

const openExternalDocument = (request: DocumentRequest, tabs: Tab[], docs: ExternalDocument[], keepVehicleInfo?: boolean): ComponentActionType => ({
    type: "OPEN_DOCUMENT_DIALOG",
    payload: { request, tabs, documents: docs, keepVehicleInfo },
})

const closeExternalDocument = (): ComponentActionType => ({ type: "CLOSE_DOCUMENT_DIALOG" })

const updateField = (path: string[], value: any): ComponentActionType => ({
    type: "UPDATE_FIELD",
    payload: { path, value },
})

const setSelectedTab = (tab?: AvailableTabs): ComponentActionType => ({ type: "SET_SELECTED_TAB", payload: tab })

const getRelatedDocuments = (userContext?: UserContext): ComponentActionType => ({
    type: "GET_RELATED_DOCUMENTS",
    payload: userContext,
})

const getExternalSystemDocuments = (): AsyncAction<ComponentActionType> => async (dispatch) => {
    const externalSystemDocuments = await Repositories.getExternalSystemDocuments()
    batch(() => {
        dispatch({ type: "EXTERNAL_SYSTEM_DOCUMENTS_LOADED", payload: externalSystemDocuments })
        dispatch(getRelatedDocuments())
    })
}

const setShowVehicleModal = (value: boolean): ComponentActionType => ({ type: "SHOW_VEHICLE_MODAL", payload: value })

const setSelectedDocument = (document: ExternalDocument): ComponentActionType => ({
    type: "SET_SELECTED_DOCUMENT",
    payload: document,
})

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    closeExternalDocument,
    getExternalSystemDocuments,
    openExternalDocument,
    updateField,
    loadCarModelDetails,
    setSelectedTab,
    getRelatedDocuments,
    setVehicle,
    setShowVehicleModal,
    setSelectedDocument,
    getAllVehicleFuelTypes,
}
