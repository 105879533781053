import { Box, Loader, Stack } from "@tm/components"
import { Suspense, useEffect } from "react"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"
import { WholesalerArticleNumberModuleStateProvider, useWholesalerArticleNumbersModuleState } from "./WholesalerArticleNumberModuleState"
import { WholesalerArticleNumberList } from "./WholesalerArticleNumberList"

function WholesalerArticleNumberComponent() {
    const start = useWholesalerArticleNumbersModuleState((x) => x.start)

    useEffect(start, [start])

    return <WholesalerArticleNumberList />
}

export default function WholesalerArticleNumbers() {
    return (
        <Suspense
            fallback={
                <Box alignContent="center">
                    <Loader />
                </Box>
            }
        >
            <WholesalerArticleNumberModuleStateProvider>
                <Stack overflow="hidden">
                    <ModalModuleHeader />
                    <WholesalerArticleNumberComponent />
                </Stack>
            </WholesalerArticleNumberModuleStateProvider>
        </Suspense>
    )
}
