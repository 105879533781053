import { CalculatedCostType, CausedCostsType, CisQueryType, OrderByArticleListItem } from "@tm/models"
import {
    FindInvoicesResponse,
    SearchOptions,
    Voucher,
    OpenOrderListItem,
    CompletedOrderListItem,
    ComplaintListItem,
    AllOrderListItem,
    FindVoucherRequest,
} from "../data/model"

export type InvoiceStateModelProps = {
    invoicesLoading: boolean
    invoicesLoaded: boolean
    invoicesError: boolean
    invoices?: FindInvoicesResponse
    invoicesSearchOptions: SearchOptions
    invoicesPageLoading: boolean
    invoicesPageEnd: boolean
}

export type VouchersStateModelProps = {
    vouchersLoading: boolean
    vouchersLoaded: boolean
    vouchersError: boolean
    vouchers?: Array<CisVoucher>
    orders?: Array<AllOrderListItem>
    continuationPoint?: string
    vouchersSearchOptions: SearchOptions
    vouchersPageLoading: boolean
    vouchersPageEnd: boolean
    vouchersLoadedMessage?: string
}

export type CisVoucher = Voucher | CompletedOrderListItem | OpenOrderListItem | OrderByArticleListItem | AllOrderListItem | ComplaintListItem

export type FiltersStateModelProps = {
    queryTypes?: Array<CisQueryType>
    findVouchersRequest?: FindVoucherRequest
}

export type RequestArticleDetailsPayload = {
    productGroupId: number
    supplierId: number
    supplierArticleNo: string
    vehicleLinkageId?: number
    subPage?: string
}

export type CisFilters = {
    causedCostsType?: CausedCostsType
    calculatedCostType?: CalculatedCostType
    dateFrom?: Date
    dateTo?: Date
    deliveryNote?: string
    deliveryNoteDate?: Date
    documentType?: string
    invoice?: string
    invoiceDate?: Date
    itemId?: string
    orderDate?: Date
    orderNumber?: string
    orderType?: string
    orderStatus?: string
    processingStateId?: number
    query?: string
    queryTypeId?: number
    queryTypeDescription?: string
    showSingleDateFilter?: boolean
}

export type CisDisplayMode = "pv" | "stg" | "texkat" | "wm"

export enum OrderItemSearchTypeId {
    Wholesaler = 0,
    Manufacturer = 1,
    EuropeanArticleNumber = 2,
}

export type CisArticleType = "wholesaler" | "supplier"
