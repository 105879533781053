import { Fragment, MouseEvent, ReactNode, useState } from "react"
import { styled } from "@tm/components"
import { Button, Collapsible, Loader, SearchField } from "@tm/controls"
import { InfoDialog } from "."
import { FilterType, isInfoAvailable } from "../../data/helpers"

type Props = {
    title: string
    loading?: boolean
    children: ReactNode
    disabled?: boolean
    searchValue?: string
    active?: boolean
    searchEnabled?: boolean
    resetBtnDisabled?: boolean
    onSearchChange?(value: string): void
    onSearchVisibility?(): void
    onReset?(): void
    onCollapsibleChange?: (value: boolean) => void
    filterId: FilterType
}

const StyledCollapsible = styled(Collapsible)<{ disabled: boolean }>(({ theme, disabled }) => ({
    visibility: "visible",
    ...(disabled ? {
        cursor: "default",
        pointerEvents: "none",
        opacity: theme.opacity?.disabled,
        ".collapsible__content": {
            display: "none"
        }
    } : {}),
}))

const StyledLoader = styled(Loader)({
    width: "1em",
    height: "1em",
})

export default function Filter({ filterId, title, active, disabled, loading, onCollapsibleChange, onReset, onSearchChange, onSearchVisibility, resetBtnDisabled, searchEnabled, searchValue, children }: Props) {
    const [displayDialog, setDisplayDialog] = useState(false)

    const handleToggleSearch = (ev: MouseEvent<HTMLElement>) => {
        onSearchVisibility?.()
        ev.stopPropagation()
    }

    const handleInputChange = (inputValue: string) => {
        onSearchChange?.(inputValue)
    }

    const handleXButton = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onReset?.()
    }

    const onInfoClick = (e: MouseEvent) => {
        e.stopPropagation()
        setDisplayDialog(true)
    }

    const handleDialogCLose = () => {
        setDisplayDialog(false)
    }

    const renderHeader = () => {
        return (
            <Fragment>
                {isInfoAvailable(filterId) && <Button layout={["ghost"]} onClick={onInfoClick} icon="info" />}
                {onSearchChange && <Button layout={["ghost"]} icon="search" onClick={handleToggleSearch} />}
                {onReset && <Button layout={["ghost"]} disabled={resetBtnDisabled && !disabled} onClick={handleXButton} icon="synchronize" />}
                {loading && <StyledLoader />}
            </Fragment>
        )
    }

    return (
        <StyledCollapsible disabled={disabled ?? false} name={title} renderHeaderAppendix={renderHeader}
            initiallyOpened={active} onChangeOpen={onCollapsibleChange}>
            {searchEnabled && <SearchField
                value={searchValue}
                showClear
                placeholder="Search..."
                onChange={handleInputChange} />}
            {children}
            {displayDialog && <InfoDialog filterId={filterId} onDialogCLose={handleDialogCLose} />}
        </StyledCollapsible>
    )
}
