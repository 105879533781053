import { Box, Button, Icon, Typography, styled } from "@tm/components"
import { PropsWithChildren } from "react"

type Props = {
    title: string
    onButtonClick: () => void
    buttonIcon: string
}

export default function HeaderActionBox({ title, buttonIcon, onButtonClick, children }: PropsWithChildren<Props>) {
    return (
        <BorderedBox>
            <Typography textTransform="uppercase" variant="label">
                {title}
            </Typography>
            {children}
            <Button sx={{ position: "absolute", right: "9px", top: "9px", padding: "4px !important" }} onClick={onButtonClick}>
                <Icon name={buttonIcon} width="14px" height="14px" />
            </Button>
        </BorderedBox>
    )
}

const BorderedBox = styled(Box)({
    border: "2px solid #c3c3c3",
    borderRadius: "0.5em",
    minHeight: "64px",
    padding: "9px",
    display: "flex",
    flexDirection: "column",
    position: "relative",
})
