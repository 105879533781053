import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()
    const padding = `${margin.s} ${margin.l}`

    return stylesheet({
        main: {
            flex: 1,
            marginLeft: "2rem",
            height: "100%",
        },
        customerWrapper: {
            marginBottom: margin.m,
        },
        content: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
        },
        infoArea: {
            background: "#fff",
            padding,
            flex: 1,
        },
    })
}
