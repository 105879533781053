import {
    Article,
    ErpInformation,
    ErpInformationRequestItem,
    ErpInformationResponse,
    ErpSystemConfig,
    Vehicle,
    ArticleErpInfo,
    ArticleErpKey,
    ComparableArticleErpKey,
} from "@tm/models"
import { erpInformationResponeMapper } from "./erpInformationMapper"

export const ArticleErpInfoFactory = {
    /** Creates a unique request key for this article. */
    createKey: (key: ArticleErpKey): ComparableArticleErpKey => {
        // The internal article id should be unique in combination with the quantity
        const internalId = typeof key.internalId === "string" ? key.internalId.hashCode() : key.internalId
        const hexId = `${key.erpSystemId.toString(16)}${internalId.toString(16)}${key.quantity.toString(16)}`.padStart(30, "0")

        // Create a valid UUID from the hex id
        return {
            internalId: key.internalId,
            quantity: key.quantity,
            erpSystemId: key.erpSystemId,
            id: `${hexId.substring(0, 8)}-${hexId.substring(8, 12)}-4${hexId.substring(12, 15)}-8${hexId.substring(15, 18)}-${hexId.substring(18)}`,
        }
    },
    createKeyFromArticle: (article: Article, erpSystemId: number): ComparableArticleErpKey => {
        return ArticleErpInfoFactory.createKey({
            internalId: article.internalId,
            quantity: article.quantity,
            erpSystemId,
        })
    },
    createFromError: (erpInfo: ArticleErpInfo, errorResponse: Omit<ErpInformationResponse, "items">): ArticleErpInfo => {
        return {
            state: "error",
            key: erpInfo.key,
            request: erpInfo.request,
            errorText: errorResponse.errorText ?? "An error occured while loading the ERP data.",
            erpSystem: erpInfo.erpSystem,
        }
    },
    createLoading: (erpInfo: ArticleErpInfo): ArticleErpInfo => {
        return {
            key: erpInfo.key,
            request: erpInfo.request,
            state: "loading",
            erpSystem: erpInfo.erpSystem,
        }
    },
    createFromResponse: (erpInfo: ArticleErpInfo, erpInfoResponse: ErpInformation): ArticleErpInfo => {
        return {
            state: "success",
            key: erpInfo.key,
            request: erpInfo.request,
            response: erpInformationResponeMapper(erpInfoResponse),
            sortNumber: erpInfoResponse.sortNumber,
            erpSystem: erpInfo.erpSystem,
        }
    },
    createFromTeccomResponse: (article: Article, erpInfoResponse: ErpInformation, erpSystem: ErpSystemConfig, sortNumber: number): ArticleErpInfo => {
        const key = ArticleErpInfoFactory.createKeyFromArticle(article, erpSystem.id)
        return {
            key,
            state: "success",
            request: createErpInformationRequestItem(key.id, article),
            response: erpInformationResponeMapper(erpInfoResponse),
            erpSystem,
            sortNumber,
        }
    },
    createQueued: (article: Article, erpSystem: ErpSystemConfig, traderArticleNumber?: string): ArticleErpInfo => {
        const key = ArticleErpInfoFactory.createKeyFromArticle(article, erpSystem.id)

        return {
            state: "queued",
            key,
            request: createErpInformationRequestItem(key.id, article, traderArticleNumber),
            erpSystem,
        }
    },
    createWithDifferentQuantity: (erpInfo: ArticleErpInfo, quantity: number): ArticleErpInfo => {
        const itemKey = ArticleErpInfoFactory.createKey({
            ...erpInfo.key,
            quantity,
        })

        return {
            ...erpInfo,
            request: {
                ...erpInfo.request,
                itemId: itemKey.id,
            },
            key: itemKey,
        }
    },
}

function createErpInformationRequestItem(itemId: string, article: Article, traderArticleNumber?: string): ErpInformationRequestItem {
    return {
        itemId,
        wholesalerArticleNumber: traderArticleNumber ?? article.traderArticleNo,
        dataSupplierArticleNumber: article.supplierArticleNo,
        dataSupplierId: article.supplier.id,
        dataSupplierName: article.supplier.name,
        productGroupId: article.productGroup.id,
        productGroupName: article.productGroup.name,
        articleDescription: article.description,
        quantityValue: article.quantity,
        vehicleId: undefined,
        warehouseId: undefined,
        selectedShipmentModeId: undefined,
        foundBySearchTerm: undefined,
        oeArticleOrigin: article.oeArticleOrigin,
    }
}

export function createGetErpInfosRequest(
    items: ErpInformationRequestItem[],
    erpSystemId: number,
    searchQuery?: string,
    telesalesCustomerNo?: string,
    vehicle?: Vehicle
) {
    return {
        items: items.map<ErpInformationRequestItem>((item) => ({
            ...item,
            vehicleId: vehicle?.id,
            foundBySearchTerm: searchQuery,
        })),
        distributorId: erpSystemId,
        foundBySearchTerm: searchQuery,
        telesalesCustomerNo,
    }
}
