import { getStoredAuthorization, ajax } from "@tm/utils"
import { GetCalculatedCostTypesResponse, GetCalculatedCostsRequest, GetCalculatedCostsResponse } from "@tm/models"
import { getBundleParams } from "../../../utils"

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.repairShopsServiceUrl
}

export function getCalculatedCosts(request: GetCalculatedCostsRequest) {
    const url = `${getServiceUrl()}/GetCalculatedCosts`
    const authorization = getStoredAuthorization()
    return ajax<GetCalculatedCostsResponse>({ url, body: request, authorization }, undefined, undefined, true)
}

export function getCalculatedCostTypes() {
    const url = `${getServiceUrl()}/GetCalculatedCostTypes`
    const authorization = getStoredAuthorization()
    return ajax<GetCalculatedCostTypesResponse>({ url, body: {}, authorization }, undefined, undefined, true)
}
