import { Collapsible, List } from "@tm/controls"
import { AttributeFilterModel, FittingPosition, ProductGroupFiltersModel } from "@tm/models"

import AttributeFilterComponent, { AttributeFilterComponentProps } from "./attribute-filter"

type Props = {
    productGroupFilter: ProductGroupFiltersModel
    selectedFittingSide?: FittingPosition
    modelId: number
    denyWrite: boolean
    onSelectAttribute: (changedFilter: AttributeFilterModel) => void
    onDeselectAttribute: (attributeToDeselect: AttributeFilterModel) => void
    onRequestArticleList(attributeFilter: AttributeFilterModel): void
    onRequestCalculation?(productGroupId: number): void
    canRequestCalculation?: boolean
}

function ProductGroupFilterComponent(props: Props) {
    const handleSelectAttribute = (changedFilter: AttributeFilterModel) => {
        props.onSelectAttribute({ ...changedFilter, fittingSide: props.selectedFittingSide || FittingPosition.None })
    }

    const appendClassNameToRecord = (attributeFilter: AttributeFilterModel): AttributeFilterComponentProps => {
        const { onDeselectAttribute, modelId, onRequestArticleList, onRequestCalculation, canRequestCalculation, denyWrite } = props
        return {
            attributeFilter,
            onSelectAttribute: handleSelectAttribute,
            onDeselectAttribute,
            modelId,
            denyWrite,
            onRequestArticleList,
            onRequestCalculation,
            canRequestCalculation,
        }
    }

    const groupedByFittingSide = props.productGroupFilter.attributeFilters
        .filter((filterOptions) => {
            if (props.selectedFittingSide) {
                return filterOptions.fittingSide & props.selectedFittingSide
            }
            return !filterOptions.fittingSide || filterOptions.fittingSide & FittingPosition.None
        })
        .map(appendClassNameToRecord)

    return (
        <div className="product-groups">
            <div style={props.selectedFittingSide ? { marginLeft: "2em" } : undefined}>
                <Collapsible initiallyOpened name={props.productGroupFilter.name}>
                    <List className="product-groups" items={groupedByFittingSide} view={AttributeFilterComponent} />
                </Collapsible>
            </div>
        </div>
    )
}

export default ProductGroupFilterComponent
