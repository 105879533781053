import { Suspense } from "react"
import { IMicros } from "@tm/models"
import SpecialIcons from "../../../_shared/special-icons"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-special-icons"]

function ErpInfoSpecialIconsComponent(props: Props) {
    const { loading, erpInfo } = useErpInfo(props.data, "list")

    if (loading || !erpInfo?.specialIcons) {
        return null
    }

    return <SpecialIcons size="s" icons={erpInfo.specialIcons} />
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info-special-icons">
                    <Suspense fallback={null}>
                        <ErpInfoSpecialIconsComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
