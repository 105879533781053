import { em, important, percent } from "csx"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Badge, Button, Headline, Loader, Modal, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Vehicle } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { ModelState, equals, initSelector } from "@tm/utils"
import { getPdfDocument } from "../../../../data/repositories/digital-forms"
import { Actions } from "../../bussiness"
import { UncountableFields } from "../../bussiness/contants"
import { countError, stringToDate } from "../../bussiness/helpers"
import { DocumentsState, Tab } from "../../bussiness/model"
import { fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { AdressesTab, BatterieTab, CompliantTab, CostStatementTab, OldBatteriesTab, ReturnsTab, VehicleTab } from "../tabs"
import { ExternalDocument } from "../../../../data/model"

const selector = initSelector(
    fieldsSelector,
    (s) => s.mainDocument,
    (s) => s.tabs,
    (s) => s.selectedTab,
    (s) => s.vehicleDataIsMandatory
)

export default function DocumentsModal() {
    const { translateText } = useLocalization()
    const workTask = useWorkTask()
    const [loading, setLoading] = useState(false)
    const style = useMemo(() => getStyle(), [])

    const actions = useActions(Actions, "updateField", "closeExternalDocument", "setSelectedTab")

    const [model, selectedDocument, tabs, selectedTab, vehicleDataIsMandatory] = useSelector(selector)
    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText))

    if (!model) {
        return null
    }

    const saveVehicleData = () => {
        const hasVehicleTab = tabs?.find((x) => x.id == "Vehicle")

        if (!workTask?.workTask || !hasVehicleTab || !vehicleDataIsMandatory) {
            return
        }

        const {
            workTask: { vehicle },
            attachToWorkTask,
        } = workTask

        const newVehicle = {
            ...vehicle,
            engineCode: model.vehicleInfo.motorCode,
            vin: model.vehicleInfo.vinNr,
            registrationNo: model.vehicleInfo.keyNumber,
            initialRegistration: stringToDate(model.vehicleInfo.firstRegistrationYear),
        }

        const isDirty = !equals(vehicle, newVehicle)

        isDirty && attachToWorkTask({ vehicle: newVehicle as Vehicle })
    }

    const handlePrintClick = () => {
        saveVehicleData()

        setLoading(true)

        getPdfDocument(model, modelState, tabs, translateText).then(() => {
            setLoading(false)
            actions.updateField(["returnsInfo", "additionalParts"], [])
            actions.updateField(["returnsInfo", "additionalChange"], true)
        })
    }

    const renderTab = (modelState: ModelState, tab: Tab, idx: number) => {
        const errorCount = useMemo(() => countError(modelState, tab), [modelState, tab])
        const disabled = !!modelState[tab.enabledIf!]?.length
        // const errorCount = countError(modelState, tab)
        return (
            <Button
                disabled={disabled}
                key={idx}
                size="l"
                className={style.itemBtn}
                skin="primary"
                isActive={tab.id == selectedTab}
                onClick={() => actions.setSelectedTab(tab.id)}
            >
                {!!errorCount && !disabled && <Badge skin="danger" value={errorCount} />}
                {tab.name}
            </Button>
        )
    }

    const renderTabHint = () => {
        const tab = tabs.find((x) => x.id == selectedTab)
        if (!tab?.hintTitle) {
            return null
        }
        return (
            <div className={style.hints}>
                <Text size="l" modifiers="strong">{`${tab.hintTitle}:`}</Text>
                <Text size="l" modifiers="strong">
                    {tab.hintDescription}
                </Text>
            </div>
        )
    }

    if (!tabs.length) {
        return (
            <Modal onClose={actions.closeExternalDocument}>
                <Headline size="xs" className="headline--danger">
                    {translateText(787)}
                </Headline>
            </Modal>
        )
    }

    const invalid = Object.keys(modelState)
        .filter((x) => !UncountableFields.includes(x))
        .some((key) => modelState[key]?.length)

    const documentTitle = (selectedDocument?: ExternalDocument) => {
        switch (selectedDocument?.documentTypeId) {
            case 1:
                return translateText(13676)
            case 3:
                return translateText(13677)
            default:
                return selectedDocument?.name
        }
    }

    return (
        <Modal onClose={actions.closeExternalDocument} containerClassName="open--foreground">
            <div className={style.modal}>
                <div className={style.header}>
                    <Button size="l" onClick={actions.closeExternalDocument}>
                        {translateText(317)}
                    </Button>
                    <Button size="l" disabled={invalid} onClick={handlePrintClick}>
                        {translateText(49)}
                    </Button>
                    <Loader visible={loading} />
                </div>
                <Text size="l" modifiers="strong">
                    {documentTitle(selectedDocument)}
                </Text>
                <div>
                    <Text modifiers="strong">{translateText(1021)}</Text>
                </div>
                <div>
                    <Text>{translateText(1392)}</Text>
                </div>
                {renderTabHint()}
                <div className={style.content}>
                    <div className={style.tabs}>{tabs.map((tab, idx) => renderTab(modelState, tab, idx))}</div>
                    <div className={style.fields}>
                        <Scrollbar>
                            {selectedTab == "Adresses" && <AdressesTab />}
                            {selectedTab == "Vehicle" && <VehicleTab />}
                            {selectedTab == "Compliamt" && <CompliantTab />}
                            {selectedTab == "CostStatement" && <CostStatementTab />}
                            {selectedTab == "Batterie" && <BatterieTab />}
                            {selectedTab == "Return" && <ReturnsTab />}
                            {selectedTab == "OldBatteries" && <OldBatteriesTab />}
                        </Scrollbar>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        modal: {
            position: "relative",
            display: "flex",
            flex: 1,
            flexDirection: "column",
        },
        header: {
            position: "absolute",
            top: 0,
            right: 0,
            margin: theme.margin.m,
        },
        content: {
            marginTop: theme.margin.m,
            height: percent(90),
            display: "flex",
        },
        tabs: {
            marginRight: theme.margin.l,
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "stretch",
        },
        itemBtn: {
            position: "relative",
            margin: important(theme.margin.m),
        },
        fields: {
            flex: 6,
            display: "flex",
            flexDirection: "column",
            $nest: {
                ".scrollbar__view": {
                    paddingRight: em(1),
                },
            },
        },
        hints: {
            marginTop: em(0.5),
            display: "flex",
            flexDirection: "column",
        },
    })(DocumentsModal)
}
