import { ajax, getStoredAuthorization } from "@tm/utils"
import { SendFeedbackRequest } from "./model"
import { getBundleParams } from "../utils"

function getServiceUrl(): string {
    return getBundleParams()?.serviceUrl || ""
}

export function sendFeedback(body: SendFeedbackRequest): Promise<void> {
    const url = `${getServiceUrl()}/SendFeedback`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}
