import { OE, RequestArticleListPayload, SearchFilters } from "@tm/models"
import { Models } from "../../../../data"
import { OeInformation, OeInformationNumber } from "../../../../data/model"

export function createVehicleDependentSearchRequest(positions: OE.OePosition[], hideOePrice: boolean | undefined) {
    const request: RequestArticleListPayload = {
        oePositions: positions,
        inModal: true,
        hideOePrice,
        forceReload: true,
    }

    return request
}

export function createManufacturerDependentSearchRequest(
    selectedOeNumber: string,
    positions: OE.OePosition[],
    manufacturerId: number,
    hideOePrice: boolean | undefined,
    oeArticles: Models.OeInformationNumber[] | undefined
) {
    const productGroupIds = (oeArticles ?? [])
        .flatMap((x) => x.parts)
        .map((x) => x.productGroupId ?? 0)
        .filter((x) => x > 0)
        .distinct()

    const request: RequestArticleListPayload = {
        oePositions: positions,
        direct: {
            query: selectedOeNumber,
            oeManufacturerIds: [manufacturerId],
            oeInformationWithoutCar: true,
            searchFilter: SearchFilters.OeReference,
            productGroupIds,
        },
        extendedAssortment: false,
        inModal: true,
        hideOePrice,
        forceReload: true,
    }

    return request
}

export function createOePositions(
    selectedOeNumber: string | undefined,
    selectedManufacturer: OeInformation | undefined,
    selectedOeArticle: OeInformationNumber | undefined
) {
    if (!selectedOeNumber) {
        return []
    }

    const positions = [
        {
            ...selectedOeArticle,
            isSelected: true,
            replacements: [...(selectedManufacturer?.oeArticles?.filter((x) => x.number !== selectedOeNumber) ?? [])],
        } as OE.OePosition,
    ]

    return positions
}
