import { useWorkTask } from "@tm/context-distribution"
import { connectComponent } from "@tm/morpheus"
import { useEffect } from "react"
import { removeContextDependentIFrames } from "../../helper/remove-context-dependent-iframes"
import { Actions, IActions } from "./business"
import { ManagerState } from "./business/models"

type Props = {
    state: ManagerState
    actions: IActions
}

function ManagerComponent(props: Props) {
    const { workTaskLoading, workTask, newWorkTaskLoading, attachToWorkTask, openWorkTask, createWorkTask } = useWorkTask() ?? {}
    const { state, actions } = props

    useEffect(() => {
        removeContextDependentIFrames()
        return actions.init() as () => void
    }, [])

    useEffect(() => {
        actions.sendWorkTask(workTask)
    }, [workTask])

    useEffect(() => {
        if (!state.attachToWorkTask) {
            actions.setWorkTaskFunctions({ attachToWorkTask, openWorkTask, createWorkTask })
        }
    }, [state.attachToWorkTask])

    const style = { top: `${getWorkTaskTop()}px` }
    if (workTaskLoading && newWorkTaskLoading) {
        return <div className="worktask-loader loading" style={style} />
    }

    return null
}

export default connectComponent(Actions, ManagerComponent)

function getWorkTaskTop() {
    const workTaskContainer = document && document.querySelector(".module.worktask")
    if (!workTaskContainer) {
        return 0
    }
    return workTaskContainer.getBoundingClientRect().top
}
