import { useCallback, useMemo } from "react"
import { QueryFunctionContext, useQuery } from "react-query"
import { useRecoilState } from "recoil"
import { getSupplierLogos } from "../../../data/repositories/supplier-logos"
import { SupplierLogosData } from "../models"
import { SupplierLogosState } from "../states"

const QUERY_KEY = "useSupplierLogos"
type QueryKey = [typeof QUERY_KEY, number[]]

/**
 * Get the urls of the supplier logos for the given supplier ids.
 * Is using a cache internally to only load each logo url once.
 * Return value is reference stable and can be used as any dependency for other hooks.
 */
export function useSupplierLogos(supplierIds: number[], isEnabled: boolean): SupplierLogosData {
    const [supplierLogos, setSupplierLogos] = useRecoilState(SupplierLogosState)

    const request = useMemo(() => {
        return supplierIds.filter((x) => !supplierLogos[x])
    }, [supplierIds, supplierLogos])

    const updateCache = useCallback((loadedUrls: Record<string, string>) => {
        setSupplierLogos((prev) => {
            let newRecord = prev
            Object.entries(loadedUrls).forEach(([idString, url]) => {
                const id = parseInt(idString)

                if (!Number.isNaN(id)) {
                    newRecord = {
                        ...newRecord,
                        [id]: url ?? "",
                    }
                }
            })

            return newRecord
        })
    }, [])

    const fetchSupplierLogos = useCallback(
        async ({ queryKey: [, ids] }: QueryFunctionContext<QueryKey>) => {
            const idsToLoad = ids.filter((id) => !supplierLogos[id])

            if (idsToLoad.length) {
                updateCache(await getSupplierLogos(idsToLoad))
            }
        },
        [supplierLogos, updateCache]
    )

    const query = useQuery({
        enabled: isEnabled && !!request.length,
        queryKey: [QUERY_KEY, request],
        queryFn: fetchSupplierLogos,
        keepPreviousData: true,
    })

    return useMemo(
        () => ({
            isLoading: query.isLoading,
            supplierLogos,
        }),
        [query.isLoading, supplierLogos]
    )
}
