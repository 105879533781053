import { Article, IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { getBundleParams } from "../../../utils"
import { useWatchlist } from "../../ListV2/hooks/useArticleItem/useWatchlist"

export default function AddToCompilations({ article }: { article: Article }) {
    const { renderMicro } = useMicro<IMicros>()

    const createWatchlist = useWatchlist(article).createOrAddWatchlist

    const compilationMicro = renderMicro("compilations", "add-to-compilation", {
        article,
        createOrAddSingleWatchlist: getBundleParams().highlightWatchlistButton ? createWatchlist : undefined,
    })

    if (compilationMicro) {
        return <div className="btn-compilations">{compilationMicro}</div>
    }
    return null
}
