import { Box, Button, Icon, Typography } from "@tm/components"

type Props = {
    title: string
    onClose: () => void
    onDownloadClick: () => void
    onShareClick: () => void
}

export default function PreviewDialogHeader({ title, onClose, onShareClick, onDownloadClick }: Props) {
    return (
        <Box sx={{ backgroundColor: "#e0e0e0", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            <Box display="inline-flex" alignItems="center">
                <Icon name="file" width="30px" height="30px" />
                <Typography variant="h2">{title}</Typography>
            </Box>
            <Box display="inline-flex">
                <Button variant="contained" onClick={onShareClick}>
                    <Icon name="share" sx={{ height: "24px", width: "24px" }} />
                </Button>
                <Button variant="contained" onClick={onDownloadClick}>
                    <Icon name="download" height="24px" width="24px" />
                </Button>
                <Button variant="contained" onClick={onClose}>
                    <Icon name="close" height="24px" width="24px" />
                </Button>
            </Box>
        </Box>
    )
}
