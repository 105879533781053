import { channel } from "@tm/models"
import { uniqueId, encodeUniqueId, createQueryString } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenExternalModuleInWorkTask(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openExternalModuleInWorkTask } = data

    if (openExternalModuleInWorkTask && openExternalModuleInWorkTask.url) {
        const worktaskId = openExternalModuleInWorkTask.openInSelectedWorkTask ? window.__NEXT_WORKTASKID__ : uniqueId()
        if (!worktaskId) {
            return
        }

        const url = `/${encodeUniqueId(worktaskId)}/ext${createQueryString({ url: openExternalModuleInWorkTask.url })}`

        const unregister = this.props.history.listen(() => {
            unregister()
            const { module, worktask } = openExternalModuleInWorkTask
            if (module && module.name) {
                channel("WORKTASK", worktaskId).publish("MODULE/OPENED", { icon: module.icon, title: module.name, url, isSelected: true })
            }

            if (worktask && worktask.title) {
                const payload: any = { view: worktask?.title, info: worktask?.subtitle }
                channel("WORKTASK", worktaskId).publish("MODULE/CHANGED", payload)
            }
        })
        this.props.history.push(url)
    }
}
