import {
    withChangeUserSetting,
    WithChangeUserSettingProps,
    withUserContext,
    WithUserContextProps,
    withWorkTask,
    WithWorkTaskProps,
    getModuleFromUserContext,
} from "@tm/context-distribution"
import { SelectionList, SelectionListItem, Toolbar } from "@tm/controls"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { ECounterType, FittingPosition, UserModuleType, VehicleType } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { bindSpecialReactMethods, TmaHelper } from "@tm/utils"
import { createPortal } from "react-dom"
import { Button, Icon } from "@tm/components"
import { AvailabilityFilterType, FilterType, SearchType } from "../../business"
import { CriterionFilter } from "../../data/model/uni-parts"
import { adaptFilterGroupHeights } from "../../helper"
import { getBundleParams } from "../../utils"
import { Actions, FiltersState, IActions } from "./business"
import {
    mapArticleAttributeFilter,
    mapDataSupplierFilter,
    mapProductGroupFilter,
    mapUniCriterionFilter,
    mapUniCriterionFilterGroup,
    updateFilterItemsPriority,
} from "./business/helpers"
import { AvailabilityFilter } from "./components/AvailabilityFilter"
import ConstructionYearFilterComponent from "./components/construction-year-filter"
import ExtendedAssortmentFilter from "./components/ExtendedAssortmentFilter"
import Filter from "./components/filter"
import FilterModal from "./components/filter-modal"
import FittingPositionFilter from "./components/fitting-position-filter"
import SelectionListFilter from "./components/selection-list-filter"

type Props = WithChangeUserSettingProps &
    WithUserContextProps &
    LocalizationProps &
    WithWorkTaskProps & {
        state: FiltersState
        actions: IActions

        showAllFiltersAsClipped?: boolean
        clippedFiltersClassName?: string
    }

type SelectionListRefs = {
    productGroup: React.RefObject<SelectionList>
    dataSupplier: React.RefObject<SelectionList>
}

type State = {
    showClippedFilters: boolean
    filterSearchFieldIsToggled: boolean
}

class FiltersComponent extends React.Component<Props, State> {
    private selectionListRefs: SelectionListRefs = {
        productGroup: React.createRef(),
        dataSupplier: React.createRef(),
    }

    private mountingPointElement: Element | null

    private productGroupState: boolean

    private supplierState: boolean

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
        this.state = {
            filterSearchFieldIsToggled: true,
            showClippedFilters: props.showAllFiltersAsClipped
                ? props.userSettings?.autoExpandFilters ?? !props.showAllFiltersAsClipped
                : !props.showAllFiltersAsClipped,
        }
        this.toggleShowClippedFilters = this.toggleShowClippedFilters.bind(this)
    }

    UNSAFE_componentWillMount() {
        this.initialize(this.props)
    }

    UNSAFE_componentWillReceiveProps(nextProps: Props) {
        if (
            (!this.props.userSettings && nextProps.userSettings) ||
            (this.props.userSettings &&
                !this.props.userSettings.articleListSettings &&
                nextProps.userSettings &&
                nextProps.userSettings.articleListSettings)
        ) {
            this.initialize(nextProps)
        }

        if (this.props.showAllFiltersAsClipped) {
            if (
                (this.props.userSettings?.autoExpandFilters != nextProps.userSettings?.autoExpandFilters ||
                    nextProps.userSettings?.autoExpandFilters === undefined) &&
                this.state.showClippedFilters != nextProps.userSettings?.autoExpandFilters
            ) {
                this.setState({ showClippedFilters: nextProps.userSettings?.autoExpandFilters ?? true })
            }
        }
    }

    componentDidMount() {
        // There is a problem that the mounting point element maybe not yet exists.
        // If this is the case force the component to render again but only once after mount
        if (!this.mountingPointElement || !document.body.contains(this.mountingPointElement)) {
            this.forceUpdate()
        }
        this.openFilterIfDefault()
    }

    openFilterIfDefault() {
        if (this.props.state.selectedFilters.extendedAssortment) {
            this.openFilterIfClosed(FilterType.Availability)
            this.openFilterIfClosed(FilterType.ProductGroup)
            this.openFilterIfClosed(FilterType.Supplier)
        }
    }

    openFilterIfClosed(filter: FilterType) {
        if (!this.isFilterOpened(filter)) {
            this.props.actions.toggleFilterOpen(filter)
        }
    }

    handleFilterSearchFieldToggle() {
        this.setState((state) => ({ filterSearchFieldIsToggled: !state.filterSearchFieldIsToggled }))
    }

    initialize(props: Props) {
        const { userSettings, setUserSetting, actions, state } = props

        if (userSettings) {
            if (userSettings.articleListSettings && userSettings.articleListSettings.filterStates) {
                if (userSettings.articleListSettings.filterStates.clippedFilters != state.clippedFilters) {
                    actions.setClippedFilters(userSettings.articleListSettings.filterStates.clippedFilters)
                }
            } else if (getBundleParams().showAllFiltersBehindButton) {
                this.props.actions.setClippedFilters(FilterType.None)
            } else {
                setUserSetting("ARTICLE_LIST_SETTINGS", {
                    ...userSettings.articleListSettings,
                    filterStates: {
                        clippedFilters: state.clippedFilters,
                    },
                })
            }
        }
    }

    isFilterVisible(filterType: FilterType): boolean {
        const { userContext } = this.props
        const { filters } = this.props.state

        // check extra conditions for special filterTypes
        switch (filterType) {
            case FilterType.ExtendedAssortment:
                // only show the extended assortment filter of the service says so
                return filters.showExtendedAssortment

            case FilterType.Availability:
                const catalogModule = getModuleFromUserContext(userContext, UserModuleType.Catalog)
                if (!catalogModule) {
                    return false
                }

                const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = catalogModule
                return !!availabilityStatusIdsToShow?.length || !!availabilityStatusIdsToShowSecondary?.length

            case FilterType.ArticleAttribute:
                // article attribute filter for direct search or uni article list or oe positions as direct search is not available
                return (
                    !this.isDirectSearch() && !this.isUniArticleList() && (!this.isOePositions() || !getBundleParams().oePositionSearchAsDirectSearch)
                )

            case FilterType.FittingPosition:
                return filters.showFittingPosition

            case FilterType.UniCriterion:
                // uni criterion filter is only available for uni article list
                return this.isUniArticleList()

            case FilterType.ConstructionYear:
                return !!filters.constructionYear.length

            default:
                return true
        }
    }

    isFilterLoading(filterType: FilterType): boolean {
        return (this.props.state.loadingFilters & filterType) === filterType
    }

    isFilterClipped(filterType: FilterType): boolean {
        return (this.props.state.clippedFilters & filterType) === filterType
    }

    isFilterOpened(filterType: FilterType): boolean {
        return (this.props.state.openedFilters & filterType) === filterType
    }

    isDirectSearch() {
        return this.props.state.searchType === SearchType.DIRECT
    }

    isOePositions() {
        return this.props.state.searchType === SearchType.OE_POSITIONS
    }

    isUniArticleList() {
        const { searchType } = this.props.state
        return searchType === SearchType.UNINODE || searchType === SearchType.UNISEARCH || searchType === SearchType.UNIPRODUCTGROUPS
    }

    isAnyFilterSelected() {
        const { selectedFilters } = this.props.state

        return (
            !!selectedFilters.productGroupIds.length ||
            !!selectedFilters.supplierIds.length ||
            !!selectedFilters.articleAttributes.length ||
            !!selectedFilters.uniCriteria.length ||
            !!selectedFilters.constructionYear ||
            // || selectedFilters.extendedAssortment
            selectedFilters.availability ||
            selectedFilters.fittingPosition != FittingPosition.None
        )
    }

    updateClippedFiltersSetting(clippedFilter: FilterType) {
        const { setUserSetting, userSettings, state } = this.props

        if (userSettings && userSettings.articleListSettings) {
            setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...userSettings.articleListSettings,
                filterStates: {
                    ...userSettings.articleListSettings.filterStates,
                    clippedFilters: state.clippedFilters ^ clippedFilter,
                },
            })
        }
    }

    toggleShowClippedFilters() {
        this.setState((prevState) => ({ showClippedFilters: !prevState.showClippedFilters }))
        this.props.setUserSetting("AUTO_EXPAND_FILTERS", !this.state.showClippedFilters)
    }

    getRenderedFilters(): { modal: Array<React.ReactNode>; clipped: Array<React.ReactNode> } {
        const { showAllFiltersAsClipped } = this.props

        const modal: Array<React.ReactNode> = []
        const clipped: Array<React.ReactNode> = []

        const addFilter = (filterType: FilterType, renderedFilter: React.ReactNode) => {
            if (!renderedFilter) {
                return
            }
            if (showAllFiltersAsClipped || this.isFilterClipped(filterType)) {
                clipped.push(renderedFilter)
            } else {
                modal.push(renderedFilter)
            }
        }

        addFilter(FilterType.ExtendedAssortment, this.renderExtendedAssortmentFilter())
        if (this.isFilterVisible(FilterType.Availability)) {
            addFilter(
                FilterType.Availability,
                <AvailabilityFilter
                    isLoading={this.isFilterLoading(FilterType.Availability)}
                    selected={this.props.state.selectedFilters.availability}
                    initiallyOpened={!this.isFilterClipped(FilterType.Availability) || this.isFilterOpened(FilterType.Availability)}
                    onChange={this.handleToggleAvailabilityFilter}
                    onChangeOpen={this.handleChangeOpenAvailabilityFilter}
                    onToggleClip={this.handleToggleClipAvailabilityFilter}
                    showAllFiltersAsClipped={showAllFiltersAsClipped}
                    key="availability-filter"
                />
            )
        }
        addFilter(FilterType.ProductGroup, this.renderProductGroupFilter())
        addFilter(FilterType.Supplier, this.renderSupplierFilter())
        addFilter(FilterType.ArticleAttribute, this.renderArticleAttributeFilter())
        addFilter(FilterType.ConstructionYear, this.renderConstructionYearFilter())
        addFilter(FilterType.FittingPosition, this.renderFittingPositionFilter())
        addFilter(FilterType.UniCriterion, this.renderUniCriterionFilter())

        return { modal, clipped }
    }

    handleToggleExtendedAssortmentFilter() {
        const extendedAssortment = this.getChangedExtendedAssortmentFilter(!this.props.state.selectedFilters.extendedAssortment)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowAllItems, extendedAssortment)

        if (extendedAssortment !== undefined) {
            this.props.actions.changeExtendedAssortmentFilter(extendedAssortment)
        }
    }

    handleToggleAvailabilityFilter(type: AvailabilityFilterType) {
        this.props.actions.changeAvailabilityFilter(this.props.state.selectedFilters.availability === type ? AvailabilityFilterType.None : type)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowOnStockOnlyItems, !this.props.state.selectedFilters.availability)
    }

    getChangedExtendedAssortmentFilter(extendedAssortment: boolean): boolean | undefined {
        const { filters, selectedFilters } = this.props.state
        if (selectedFilters.extendedAssortment === extendedAssortment) {
            return
        }

        const notPriorityProductGroupSelected = filters.productGroups
            .filter((x) => selectedFilters.productGroupIds.includes(x.id))
            .some((x) => !x.hasTopPrioritySuppliers)
        const notPriorityDataSupplierSelected = filters.suppliers.filter((x) => selectedFilters.supplierIds.includes(x.id)).some((x) => !x.showOnTop)

        const onlyNotPriorityDataSupplier = filters.suppliers.every((x) => !x.showOnTop)

        // Can't deselect extended assortment if any not priority product group or data supplier is selected or there are only not priority data supplier
        if (!extendedAssortment && (notPriorityProductGroupSelected || notPriorityDataSupplierSelected || onlyNotPriorityDataSupplier)) {
            return
        }

        const { productGroup, dataSupplier } = this.selectionListRefs

        if (extendedAssortment) {
            productGroup.current?.showOthers(true)
            dataSupplier.current?.showOthers(true)
        } else {
            productGroup.current?.hideOthers(true)
            dataSupplier.current?.hideOthers(true)
        }

        return extendedAssortment
    }

    handleToggleClipExtendedAssortmentFilter() {
        this.props.actions.toggleFilterClip(FilterType.ExtendedAssortment)
        this.updateClippedFiltersSetting(FilterType.ExtendedAssortment)
    }

    handleToggleClipAvailabilityFilter() {
        this.props.actions.toggleFilterClip(FilterType.Availability)
        this.updateClippedFiltersSetting(FilterType.Availability)
    }

    handleChangeProductGroupFilter(items: Array<SelectionListItem>) {
        const { state, actions } = this.props
        const filters = state.filters.productGroups.filter((x) => items.some((y) => x.id.toString() == y.query))

        let extendedAssortment

        if (filters.some((x) => !x.hasTopPrioritySuppliers)) {
            extendedAssortment = this.getChangedExtendedAssortmentFilter(true)
        }

        actions.changeProductGroupFilters(filters, extendedAssortment)
    }

    handleResetProductGroupFilter() {
        this.props.actions.changeProductGroupFilters([])
    }

    handleToggleClipProductGroupFilter() {
        this.props.actions.toggleFilterClip(FilterType.ProductGroup)
        this.updateClippedFiltersSetting(FilterType.ProductGroup)
    }

    handleChangeOpenProductGroupFilter(open: boolean) {
        if (!this.isFilterClipped(FilterType.ProductGroup) || open == this.isFilterOpened(FilterType.ProductGroup)) {
            return
        }
        this.props.actions.toggleFilterOpen(FilterType.ProductGroup)
    }

    handleChangeOpenAvailabilityFilter(open: boolean) {
        if (!this.isFilterClipped(FilterType.Availability) || open == this.isFilterOpened(FilterType.Availability)) {
            return
        }
        this.props.actions.toggleFilterOpen(FilterType.Availability)
    }

    handleChangeSupplierFilter(items: Array<SelectionListItem>) {
        const { state, actions } = this.props
        const filters = state.filters.suppliers.filter((x) => items.some((y) => x.id.toString() == y.query))

        let extendedAssortment

        if (filters.some((x) => !x.showOnTop)) {
            extendedAssortment = this.getChangedExtendedAssortmentFilter(true)
        }

        actions.changeSupplierFilters(filters, extendedAssortment)
    }

    handleResetSupplierFilter() {
        this.props.actions.changeSupplierFilters([])
    }

    handleToggleClipSupplierFilter() {
        this.props.actions.toggleFilterClip(FilterType.Supplier)
        this.updateClippedFiltersSetting(FilterType.Supplier)
    }

    handleChangeOpenSupplierFilter(open: boolean) {
        if (!this.isFilterClipped(FilterType.Supplier) || open == this.isFilterOpened(FilterType.Supplier)) {
            return
        }
        this.props.actions.toggleFilterOpen(FilterType.Supplier)
    }

    handleChangeArticleAttributeFilter(items: Array<SelectionListItem>) {
        const { state, actions } = this.props
        actions.changeArticleAttributeFilters(state.filters.articleAttributes.filter((x) => items.some((y) => x.query == y.query)))
    }

    handleResetArticleAttributeFilter() {
        this.props.actions.changeArticleAttributeFilters([])
    }

    handleToggleClipArticleAttributeFilter() {
        this.props.actions.toggleFilterClip(FilterType.ArticleAttribute)
        this.updateClippedFiltersSetting(FilterType.ArticleAttribute)
    }

    handleChangeOpenArticleAttributeFilter(open: boolean) {
        if (!this.isFilterClipped(FilterType.ArticleAttribute) || open == this.isFilterOpened(FilterType.ArticleAttribute)) {
            return
        }
        this.props.actions.toggleFilterOpen(FilterType.ArticleAttribute)
    }

    handleChangeFittingPositionFilter(fittingPosition: FittingPosition) {
        this.props.actions.changeFittingPositionFilter(fittingPosition)
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListLocationFilter)
    }

    handleResetFittingPositionFilter() {
        this.props.actions.changeFittingPositionFilter(FittingPosition.None)
    }

    handleToggleClipFittingPositionFilter() {
        this.props.actions.toggleFilterClip(FilterType.FittingPosition)
        this.updateClippedFiltersSetting(FilterType.FittingPosition)
    }

    handleChangeOpenFittingPositionFilter(open: boolean) {
        if (!this.isFilterClipped(FilterType.FittingPosition) || open === this.isFilterOpened(FilterType.FittingPosition)) {
            return
        }
        this.props.actions.toggleFilterOpen(FilterType.FittingPosition)
    }

    handleChangeUniCriterionFilter(items: Array<SelectionListItem>) {
        const { state, actions } = this.props
        const filters = ([] as Array<CriterionFilter>).concat
            .apply(
                [],
                state.filters.uniCriteria.map((x) => x.criterionFilters)
            )
            .filter((x: CriterionFilter) => items.some((y) => x.group === y.groupName && x.name == y.name))
        actions.changeUniCriteriaFilters(filters)
    }

    handleResetUniCriterionFilter() {
        this.props.actions.changeUniCriteriaFilters([])
    }

    handleToggleClipUniCriterionFilter() {
        this.props.actions.toggleFilterClip(FilterType.UniCriterion)
        this.updateClippedFiltersSetting(FilterType.UniCriterion)
    }

    handleChangeOpenUniCriterionFilter(open: boolean) {
        if (!this.isFilterClipped(FilterType.UniCriterion) || open === this.isFilterOpened(FilterType.UniCriterion)) {
            return
        }
        this.props.actions.toggleFilterOpen(FilterType.UniCriterion)
    }

    handleResetAllFilters() {
        this.props.actions.resetAllFilters()
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListReleaseAllFilters)

        const { productGroup, dataSupplier } = this.selectionListRefs
        productGroup.current?.hideOthers(true)
        dataSupplier.current?.hideOthers(true)
    }

    handleShowOthers = (state: boolean, on: ECounterType, off: ECounterType) => {
        adaptFilterGroupHeights()
        TmaHelper.GeneralCountEvent.Call(state ? off : on)
    }

    handleProductGroupFilterChange(query: string) {
        if (query && this.props.state.filters.productGroups.some((x) => !x.hasTopPrioritySuppliers)) {
            this.selectionListRefs.productGroup.current?.showOthers(true)
        }
    }

    renderExtendedAssortmentFilter() {
        if (!this.isFilterVisible(FilterType.ExtendedAssortment)) {
            return
        }
        return (
            <ExtendedAssortmentFilter
                selected={this.props.state.selectedFilters.extendedAssortment}
                onToggle={this.handleToggleExtendedAssortmentFilter}
                onToggleClipped={this.handleToggleClipExtendedAssortmentFilter}
                clipped={this.props.showAllFiltersAsClipped}
                key="extended-assortment-filter"
            />
        )
    }

    renderConstructionYearFilter() {
        if (!this.isFilterVisible(FilterType.ConstructionYear)) {
            return
        }

        return (
            <Filter
                localization={this.props.localization}
                name={this.props.localization.translateText(1480)}
                className="filter--construction-year"
                onChangeOpen={this.handleChangeOpenAvailabilityFilter}
                onToggleClip={this.props.showAllFiltersAsClipped ? undefined : this.handleToggleClipAvailabilityFilter}
                initiallyOpened={!this.isFilterClipped(FilterType.ConstructionYear) || this.isFilterOpened(FilterType.ConstructionYear)}
                loading={this.isFilterLoading(FilterType.ConstructionYear)}
                onReset={this.props.state.selectedFilters.constructionYear ? () => this.props.actions.changeConstructionYearFilter(0) : undefined}
                key="construction-year-filter"
            >
                <ConstructionYearFilterComponent
                    items={this.props.state.filters.constructionYear}
                    activeItemId={this.props.state.selectedFilters.constructionYear}
                    onChange={this.props.actions.changeConstructionYearFilter}
                />
            </Filter>
        )
    }

    renderProductGroupFilter() {
        const { filters, selectedFilters } = this.props.state
        const { translateText } = this.props.localization

        if (!this.isFilterVisible(FilterType.ProductGroup)) {
            return
        }

        let items = updateFilterItemsPriority(
            filters.productGroups.map((x) => mapProductGroupFilter(x, !selectedFilters.extendedAssortment && filters.showExtendedAssortment))
        )

        if (getBundleParams().productGroupFilterOrdering === "alphabetical") {
            items = items
                .orderBy((x) => x.name)
                .map((x, idx) => ({
                    ...x,
                    sortNumber: idx + 1, // SelectionList will sort items by the sortNumber
                }))
        }

        const showOthersEvent = () => {
            this.handleShowOthers(this.productGroupState, ECounterType.ArticleListShowMoreGenArt, ECounterType.ArticleListShowLessGenArt)
            this.productGroupState = !this.productGroupState
        }

        const selected = filters.productGroups
            .filter((x) => selectedFilters.productGroupIds.includes(x.id))
            .map((x) => mapProductGroupFilter(x, !selectedFilters.extendedAssortment && filters.showExtendedAssortment))

        return (
            <SelectionListFilter
                extendedAssortment={selectedFilters.extendedAssortment}
                selectionListRef={this.selectionListRefs.productGroup}
                disabled={!filters.productGroups.length}
                active={!!selectedFilters.productGroupIds.length}
                initiallyOpened={!this.isFilterClipped(FilterType.ProductGroup) || this.isFilterOpened(FilterType.ProductGroup)}
                loading={this.isFilterLoading(FilterType.ProductGroup)}
                name={translateText(88)}
                groups={[{ priority: "high", items }]}
                onChange={this.handleChangeProductGroupFilter}
                onReset={this.handleResetProductGroupFilter}
                selected={selected}
                onToggleClip={this.props.showAllFiltersAsClipped ? undefined : this.handleToggleClipProductGroupFilter}
                onChangeOpen={this.handleChangeOpenProductGroupFilter}
                onFilterChange={this.handleProductGroupFilterChange}
                localization={this.props.localization}
                key="selection-list-filter-88"
                uniqueKey={ECounterType.ArticleListFilterSelectProductGroup}
                onToggleOthers={adaptFilterGroupHeights}
                onToggleCollapseGroup={adaptFilterGroupHeights}
                onToggleShowOthers={showOthersEvent}
                onToggleSearchField={this.handleFilterSearchFieldToggle}
            />
        )
    }

    renderSupplierFilter() {
        const { filters, selectedFilters } = this.props.state

        if (!this.isFilterVisible(FilterType.Supplier)) {
            return
        }

        const items = updateFilterItemsPriority(
            filters.suppliers.map((x) => mapDataSupplierFilter(x, !selectedFilters.extendedAssortment && filters.showExtendedAssortment))
        )

        const showOthersEvent = () => {
            this.handleShowOthers(this.supplierState, ECounterType.ArticleListShowMoreSuppliers, ECounterType.ArticleListShowLessSuppliers)
            this.supplierState = !this.supplierState
        }

        return (
            <SelectionListFilter
                extendedAssortment={selectedFilters.extendedAssortment}
                selectionListRef={this.selectionListRefs.dataSupplier}
                disabled={!filters.suppliers.length}
                active={!!selectedFilters.supplierIds.length}
                initiallyOpened={!this.isFilterClipped(FilterType.Supplier) || this.isFilterOpened(FilterType.Supplier)}
                loading={this.isFilterLoading(FilterType.Supplier)}
                name={this.props.localization.translateText(71)}
                groups={[{ priority: "high", items }]}
                onChange={this.handleChangeSupplierFilter}
                onReset={this.handleResetSupplierFilter}
                selected={filters.suppliers
                    .filter((x) => selectedFilters.supplierIds.includes(x.id))
                    .map((x) => mapDataSupplierFilter(x, !selectedFilters.extendedAssortment && filters.showExtendedAssortment))}
                onToggleClip={this.props.showAllFiltersAsClipped ? undefined : this.handleToggleClipSupplierFilter}
                onChangeOpen={this.handleChangeOpenSupplierFilter}
                localization={this.props.localization}
                key="selection-list-filter-71"
                uniqueKey={ECounterType.ArticleListFilterSelectSupplier}
                onToggleOthers={adaptFilterGroupHeights}
                onToggleCollapseGroup={adaptFilterGroupHeights}
                onToggleShowOthers={showOthersEvent}
                onToggleSearchField={this.handleFilterSearchFieldToggle}
            />
        )
    }

    renderArticleAttributeFilter() {
        const { filters, selectedFilters } = this.props.state

        if (!this.isFilterVisible(FilterType.ArticleAttribute)) {
            return
        }

        return (
            <SelectionListFilter
                extendedAssortment={selectedFilters.extendedAssortment}
                disabled={!filters.articleAttributes.length}
                active={!!selectedFilters.articleAttributes.length}
                initiallyOpened={!this.isFilterClipped(FilterType.ArticleAttribute) || this.isFilterOpened(FilterType.ArticleAttribute)}
                loading={this.isFilterLoading(FilterType.ArticleAttribute)}
                name={this.props.localization.translateText(750)}
                groups={[{ priority: "high", items: filters.articleAttributes.map(mapArticleAttributeFilter) }]}
                onChange={this.handleChangeArticleAttributeFilter}
                onReset={this.handleResetArticleAttributeFilter}
                selected={filters.articleAttributes.filter((x) => selectedFilters.articleAttributes.includes(x.query)).map(mapArticleAttributeFilter)}
                onToggleClip={this.props.showAllFiltersAsClipped ? undefined : this.handleToggleClipArticleAttributeFilter}
                onChangeOpen={this.handleChangeOpenArticleAttributeFilter}
                localization={this.props.localization}
                key="selection-list-filter-750"
                onToggleOthers={adaptFilterGroupHeights}
                onToggleCollapseGroup={adaptFilterGroupHeights}
                onToggleShowOthers={adaptFilterGroupHeights}
                onToggleSearchField={this.handleFilterSearchFieldToggle}
            />
        )
    }

    renderFittingPositionFilter() {
        const { selectedFilters } = this.props.state

        if (!this.isFilterVisible(FilterType.FittingPosition)) {
            return
        }

        return (
            <FittingPositionFilter
                localization={this.props.localization}
                disabled={false}
                active={!!selectedFilters.fittingPosition}
                initiallyOpened={!this.isFilterClipped(FilterType.FittingPosition) || this.isFilterOpened(FilterType.FittingPosition)}
                name={this.props.localization.translateText(352)}
                onChange={this.handleChangeFittingPositionFilter}
                onReset={this.handleResetFittingPositionFilter}
                selected={selectedFilters.fittingPosition}
                onToggleClip={this.props.showAllFiltersAsClipped ? undefined : this.handleToggleClipFittingPositionFilter}
                onChangeOpen={this.handleChangeOpenFittingPositionFilter}
                key="selection-list-flter-352"
                isBikeAsVehicle={this.props.workTask?.vehicle?.vehicleType === VehicleType.Motorcycle}
            />
        )
    }

    renderUniCriterionFilter() {
        const { filters, selectedFilters } = this.props.state
        const { translateText } = this.props.localization

        if (!this.isFilterVisible(FilterType.UniCriterion)) {
            return
        }

        return (
            <SelectionListFilter
                extendedAssortment={selectedFilters.extendedAssortment}
                disabled={!filters.uniCriteria.length}
                active={!!selectedFilters.uniCriteria.length}
                initiallyOpened={!this.isFilterClipped(FilterType.UniCriterion) || this.isFilterOpened(FilterType.UniCriterion)}
                loading={this.isFilterLoading(FilterType.UniCriterion)}
                name={translateText(750)}
                groups={filters.uniCriteria.map(mapUniCriterionFilterGroup)}
                onChange={this.handleChangeUniCriterionFilter}
                onReset={this.handleResetUniCriterionFilter}
                selected={selectedFilters.uniCriteria.map(mapUniCriterionFilter)}
                onToggleClip={this.props.showAllFiltersAsClipped ? undefined : this.handleToggleClipUniCriterionFilter}
                onChangeOpen={this.handleChangeOpenUniCriterionFilter}
                localization={this.props.localization}
                canCollapseGroups
                showOthersText={translateText(876)}
                hideOthersText={translateText(877)}
                key="selection-list-filter-750+876+877"
                onToggleOthers={adaptFilterGroupHeights}
                onToggleCollapseGroup={adaptFilterGroupHeights}
                onToggleShowOthers={adaptFilterGroupHeights}
                onToggleSearchField={this.handleFilterSearchFieldToggle}
            />
        )
    }

    render() {
        const {
            clippedFiltersClassName,
            showAllFiltersAsClipped,
            localization: { translateText },
        } = this.props
        const { showClippedFilters } = this.state
        const filters = this.getRenderedFilters()
        const isAnyFilterSelected = this.isAnyFilterSelected()

        const resetFilterButton = (
            <Button
                variant="outlined"
                onClick={this.handleResetAllFilters}
                title={translateText(1664)}
                startIcon={<Icon name="remove-filter" />}
                color={isAnyFilterSelected ? "highlight" : undefined}
            />
        )

        let modal = (
            <Toolbar className="tk-parts" title={translateText(209)}>
                {resetFilterButton}
            </Toolbar>
        )

        if (filters.modal.length) {
            modal = (
                <Toolbar className="tk-parts" title={translateText(209)}>
                    <FilterModal resetFilterButton={resetFilterButton} searchbarToggle={this.state.filterSearchFieldIsToggled}>
                        <div className="filters">{filters.modal}</div>
                    </FilterModal>
                    {resetFilterButton}
                </Toolbar>
            )
        } else if (showAllFiltersAsClipped) {
            modal = (
                <Toolbar className="tk-parts" title={translateText(209)}>
                    <Button
                        startIcon={<Icon name={showClippedFilters ? "minus" : "plus"} />}
                        variant="outlined"
                        onClick={this.toggleShowClippedFilters}
                    >
                        {translateText(209)}
                    </Button>
                </Toolbar>
            )
        }

        this.mountingPointElement = document.querySelector(
            `#article-list-filters .filters${clippedFiltersClassName ? `.${clippedFiltersClassName}` : ""}`
        )

        return (
            <>
                {modal}
                {this.mountingPointElement && createPortal(showClippedFilters ? filters.clipped : null, this.mountingPointElement)}
            </>
        )
    }
}

export default connectComponent(Actions, withWorkTask(withLocalization(withChangeUserSetting(withUserContext(FiltersComponent)))))
