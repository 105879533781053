import { Suspense } from "react"
import { Loader, Button } from "@tm/controls"
import { IMicros } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Box, styled } from "@tm/components"
import AvailabilityWrapperComponent from "../../../_shared/availability-wrapper"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { publishErpInfoLoaded } from "../../_helpers/publishErpInfoLoaded"
import { getBundleParams } from "../../../utils"
import ExtendedDeliveryComponent from "../../../_shared/extended-delivery"
import { ErpWrapper } from "../../../_shared/ErpWrapper"
import { StyledBox } from "../StyledComponents"

type Props = IMicros["erp"]["erp-info-availability"]

const ExtendedDeliveryWrapper = styled(Box)({
    display: "flex",
    justifyContent: "center",
    marginTop: "0.2rem",
    "& .btn": {
        width: "100%",
    },
})

function ErpInfoAvailabilityComponent({ data, onClick, onTeccomClick }: Props) {
    const { translateText } = useLocalization()
    const { hideExtendedDeliveryComponent, showWideTeccomButton, articleListTeccomMode } = getBundleParams()

    const { loading, erpInfo, error, loadTeccom, teccomLoading } = useErpInfo(
        data,
        "list",
        {
            onLoad: (_, response) => {
                publishErpInfoLoaded(response)
            },
        },
        undefined
    )

    if (loading) {
        return <Loader />
    }

    let teccomFunction
    if (loadTeccom) {
        // If loadTeccom is undefined, teccom is not available for the article
        switch (articleListTeccomMode) {
            case "redirect":
                teccomFunction = () => {
                    if (onTeccomClick) {
                        onTeccomClick(erpInfo?.useSpecialProcurement)
                    }
                }
                break
            case "load":
            default:
                teccomFunction = loadTeccom
                break
        }
    }

    let extendedDeliveryComponent
    if (teccomFunction && !hideExtendedDeliveryComponent) {
        if (showWideTeccomButton) {
            extendedDeliveryComponent = (
                <ExtendedDeliveryWrapper>
                    {teccomLoading ? (
                        <Loader />
                    ) : (
                        <Button icon="store-request" onClick={teccomFunction}>
                            {erpInfo?.specialProcurementErpInformation ? translateText(276) : translateText(3113)}
                        </Button>
                    )}
                </ExtendedDeliveryWrapper>
            )
        } else {
            extendedDeliveryComponent = (
                <ExtendedDeliveryComponent
                    extendedDeliveryRequested={!!erpInfo?.specialProcurementErpInformation}
                    loadExtendedDelivery={teccomFunction}
                    extendedDeliveryLoading={teccomLoading}
                />
            )
        }
    }

    return (
        <>
            <AvailabilityWrapperComponent
                hasReplacedArticles={!!(erpInfo?.articles && erpInfo.articles.length)}
                onClick={erpInfo?.specialProcurementErpInformation ? onTeccomClick : onClick}
                availability={erpInfo?.availability}
                tour={erpInfo?.tour}
                warehouses={erpInfo?.warehouses}
                error={error}
                requestedQuantity={erpInfo?.quantity?.requestedValue}
                specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
                useSpecialProcurement={erpInfo?.useSpecialProcurement}
            />
            {extendedDeliveryComponent}
        </>
    )
}

const FlexBox = styled(StyledBox)<{ wide: boolean | undefined }>(({ wide }) => {
    if (!wide) {
        return {
            display: "flex",
            alignItems: "center",
        }
    }
})

function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <FlexBox className="erp-info-availability" wide={getBundleParams().showWideTeccomButton}>
                    <Suspense fallback={<Loader />}>
                        <ErpInfoAvailabilityComponent {...props} />
                    </Suspense>
                </FlexBox>
            </div>
        </ErpWrapper>
    )
}

export default Wrapper
