import { CellContentPosition, Icon, LoadingContainer, Table, TableCellData, TableColumnData, TableRowData } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ERIKContentService } from "@tm/data"
import { formatDate } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import { useState } from "react"
import { useFileList } from "../../hooks/useFileList"
import { useVefilesSearch } from "../../hooks/useVefilesSearch"
import { getFileCategory, getIcon } from "../../helpers"
import { FileItemActions } from "./FileItemActions"
import { NoResult } from "../noResult/NoResult"
import { PreviewDialog } from "../previewDialog/PreviewDialog"
import { getMileageNumber } from "../../helpers/getMileageNumber"

export function FileList() {
    const { searchQuery } = useVefilesSearch()
    const { vehicle } = useWorkTask()?.workTask || {}
    const { fileList, isLoading, fetchNextPage } = useFileList(vehicle?.id, searchQuery)
    const { translateText, number } = useLocalization()

    const [selectedFile, setSelectedFile] = useState<ERIKContentService.FileMeta>()

    function onRowClick(row: TableRowData) {
        const selected = fileList?.find((file) => file.id.toString() === row.id)
        setSelectedFile(selected)
    }

    function handleNextFile(direction: "next" | "prev") {
        if (!selectedFile || !fileList?.length) {
            return
        }

        let selectedIndex = fileList.findIndex((file) => file.id.toString() === selectedFile.id)

        switch (direction) {
            case "next":
                selectedIndex++
                if (selectedIndex >= fileList.length) {
                    selectedIndex = 0
                }

                break

            case "prev":
                selectedIndex--
                if (selectedIndex < 0) {
                    selectedIndex = fileList.length - 1
                }
                break

            default:
                break
        }

        setSelectedFile(fileList[selectedIndex])
    }

    const tableColumns: TableColumnData[] = [
        {
            alignContent: CellContentPosition.left,
            gridSize: "auto",
        },
        {
            header: translateText(155).toLocaleUpperCase(),
            gridSize: "2fr",
        },
        {
            header: translateText(158).toLocaleUpperCase(),
            gridSize: "1fr",
        },
        {
            header: translateText(125).toLocaleUpperCase(),
            gridSize: "1fr",
        },
        {
            header: translateText(98).toLocaleUpperCase(),
            gridSize: "1fr",
        },
        {
            header: translateText(712).toLocaleUpperCase(),
            gridSize: "1fr",
        },
        {
            alignContent: CellContentPosition.right,
            gridSize: "3fr",
        },
    ]

    const getCells = (file: ERIKContentService.FileMeta) => {
        const cellData: TableCellData[] = [
            { displayValue: <Icon name={getIcon(file.kind)} />, id: "icon" },
            { displayValue: file.displayName, id: "name" },
            { displayValue: translateText(getFileCategory(file)), id: "category" },
            {
                displayValue: number(getMileageNumber(file), 0),
                id: "vehicleMileage",
            },
            { displayValue: formatDate(new Date(file.createdAt), "dd.MM.yyyy, hh:mm"), id: "date_created" },
            { displayValue: `${Math.floor(file.fileSize / 1024)} KB`, id: "fileSize" },
            {
                displayValue: <FileItemActions file={file} />,
                id: "details",
            },
        ]

        return cellData
    }

    const displayData: TableRowData[] =
        fileList?.map((file: ERIKContentService.FileMeta) => ({
            cells: getCells(file),
            id: `${file.id}`,
            customRow: false,
            active: file.id === selectedFile?.id,
            rowIndicatorWidth: 7,
            borderOnlyOnTheLeft: true,
        })) ?? []

    const getContent = () => {
        if (isLoading) {
            return null
        }

        if (!fileList?.length) {
            return <NoResult />
        }

        return (
            <>
                <Table
                    columns={tableColumns}
                    rows={displayData}
                    headerBackground="#efefef"
                    fontSize={14}
                    headerStyle="default"
                    rowCap={1}
                    overflowY="auto"
                    rowStyle="uniform"
                    onRowClick={onRowClick}
                    onScrollBottom={fetchNextPage}
                />
                {selectedFile && <PreviewDialog file={selectedFile} onNextFile={handleNextFile} onClose={() => setSelectedFile(undefined)} />}
            </>
        )
    }

    return (
        <LoadingContainer isLoading={isLoading} display="flex" flexDirection="column" gap="0.5em" flex="1" overflow="auto">
            {getContent()}
        </LoadingContainer>
    )
}
