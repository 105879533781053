import { useEffect, useState } from "react"
import { Article, RegisteredModels, SupplierArticleQuantitiesRequest, SupplierArticleQuantities } from "@tm/models"
import { Container } from "@tm/nexus"

export function useSupplierArticleQuantities(workTaskId: string | undefined, article: Article, disabled: boolean | undefined) {
    const [supplierArticleQuantities, setSupplierArticleQuantities] = useState<SupplierArticleQuantities>()

    const request: SupplierArticleQuantitiesRequest | undefined =
        workTaskId && !disabled && article.supplierArticleNo && article.supplier.id > 0 && article.productGroup.id > 0
            ? {
                  workTaskId,
                  article: {
                      dataSupplierArticleNumber: article.supplierArticleNo,
                      dataSupplierId: article.supplier.id,
                      productGroupId: article.productGroup.id,
                  },
              }
            : undefined

    useEffect(() => {
        setSupplierArticleQuantities(undefined)
        let unsub: (() => void) | undefined

        if (!request) {
            return
        }

        // Check if required anymore - Timeout to fix NEXT-12659. If the articlelist ever gets refactored, this can probably be removed
        const timeout = setTimeout(() => {
            // TODO: create and use container action
            const subscription = Container.getInstance<SupplierArticleQuantities>(RegisteredModels.Basket_BasketInfo).subscribe(request)

            unsub = subscription.addListener("loaded", (response) => {
                setSupplierArticleQuantities(response)
            })

            subscription.load()
        }, 0)

        return () => {
            clearTimeout(timeout)
            unsub?.()
        }
    }, [JSON.stringify(request)])

    return supplierArticleQuantities
}
