import { useLocalization } from "@tm/localization"
import { Box, CellContentPosition, TableCellData, TableColumnData, TableVariants } from "@tm/components"
import { StyledTable } from "../../../../_shared/StyledComponents"
import { Voucher } from "../../../../../data/model"
import DateCell from "../../../../_shared/cells/DateCell"
import VoucherOptionsCell from "../../../../_shared/cells/VoucherOptionsCell"
import { getWmVoucherId } from "../../../../../business/helper"
import ConnectedVouchersTable from "../../../../_shared/tables/ConnectedVouchersTable"
import { getBundleParams } from "../../../../../utils"

type Props = {
    compact: boolean
    openedConnectedVouchers: Array<string>
    selectedVoucherId?: string
    voucherTypeId: number
    vouchers: Array<Voucher>
    variant: TableVariants
    loadNextPage(): void
    onExpandRowClick?(voucherId: string): void
}

export default function WmOldPartsTableComponent(props: Props) {
    const { translateText } = useLocalization()
    const { selectedVoucherId, vouchers, voucherTypeId, openedConnectedVouchers, variant, compact } = props
    const { cisVoucherUrl } = getBundleParams()

    function renderConnectedVouchers(voucher: Voucher) {
        return (
            <Box flex={1}>
                <ConnectedVouchersTable
                    key="connectedVouchers"
                    parentVoucherId={voucher.voucherId}
                    vouchersParentTypeId={voucherTypeId}
                    showPaymentInfo
                    showTotal
                />
            </Box>
        )
    }

    function getColumns() {
        const columns: TableColumnData[] = [{ header: translateText(32) }, { header: translateText(471) }, { header: translateText(212) }]
        if (!compact) {
            columns.push({ header: translateText(465) })
        }
        columns.push({ alignContent: CellContentPosition.right })

        return columns
    }

    function getCells(voucher: Voucher) {
        const cellData: TableCellData[] = [
            { displayValue: getWmVoucherId(voucher.voucherId), id: "voucher_0" },
            {
                displayValue: <DateCell value={voucher.voucherDate} mode={compact ? "dateAboveTime" : "dateDashTime"} showHoursText />,
                id: "voucher_1",
            },
            { displayValue: voucher.voucherDescription, id: "voucher_2" },
        ]
        if (!compact) {
            cellData.push({ displayValue: voucher.information, id: "voucher_3" })
        }
        cellData.push({
            displayValue: (
                <VoucherOptionsCell
                    detailsRoute={cisVoucherUrl}
                    openedConnectedVouchers={openedConnectedVouchers}
                    selectedVoucherId={selectedVoucherId}
                    itemsCount={voucher.itemsCount}
                    hasConnectedVouchers={voucher.hasConnectedVouchers}
                    voucherId={voucher.voucherId}
                    voucherTypeId={voucherTypeId}
                    showConnectedVouchersButton={!compact}
                    onExpandRowClick={props.onExpandRowClick}
                />
            ),
            id: "voucher_4",
        })

        return cellData
    }

    const displayData = vouchers?.map((voucher, index) => ({
        cells: getCells(voucher),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === voucher.voucherId,
        extendedContent:
            !compact && openedConnectedVouchers.some((item) => item === voucher.voucherId) ? renderConnectedVouchers(voucher) : undefined,
    }))

    return <StyledTable columns={getColumns()} rows={displayData} variant={variant} onScrollBottom={props.loadNextPage} />
}
