import { Box, Switch } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { BasketBundleParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { memo, useState } from "react"

import { getUIA } from "@tm/utils"
import { ISaveableOption } from "."

type Props = ISaveableOption

export const PurchasePriceSummary = memo(({ isSaving }: Props) => {
    const { translateText } = useLocalization()
    const { userSettings, setUserSetting } = useUser() ?? {}

    const [showPurchasePriceInSummary, setShowPurchasePriceInSummary] = useState(
        userSettings?.showPurchasePriceInSummary ?? !Morpheus.getParams<BasketBundleParams>("basket").showRetailPriceInSummaryByDefault // 2 of 2: If the usersetting is not set fall back to the config param because that is the behaviour it was before this change (NEXT-20908). MLE: Added this because it caused NEXT-22836
    )

    function handleChangeShowPurchasePrice(state: boolean) {
        setShowPurchasePriceInSummary(state)
        setUserSetting("SHOW_PURCHASE_PRICE_IN_SUMMARY", state)
    }

    return (
        <Box>
            <Switch
                checked={showPurchasePriceInSummary}
                onChange={(e) => handleChangeShowPurchasePrice(e.target.checked)}
                label={translateText(13310)}
                disabled={!userSettings?.showPurchasePrice || isSaving}
                {...getUIA("SettingsBasketPurchasePriceSummary")}
            />
        </Box>
    )
})
