import { selector, useRecoilValue } from "recoil"
import { getChatInvitationLinks } from ".."

const chatInvitationLinksRecoilSelector = selector({
    key: "notifications_chat_InvitationLinks",
    get: () => {
        return getChatInvitationLinks()
    },
})

export function useChatInvitationLinks() {
    return useRecoilValue(chatInvitationLinksRecoilSelector)
}
