import { Box, Icon, LicensePlate, Stack, Tooltip, Typography, styled } from "@tm/components"
import { concat, convertAutosuggestHit, plateCountryCodeMapper } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { LicensePlateComponentInitiator } from "@tm/models"
import { VehicleSuggestions } from "./useVehicleSearchAutocomplete"
import { AutosuggestHitMui } from "../AutosuggestHit"

const HighlightWrapper = styled("li")<{ gridTemplateColumns: string }>(({ theme, gridTemplateColumns }) => ({
    cursor: "pointer",
    boxSizing: "border-box",
    paddingTop: 1,
    paddingBottom: 1,
    paddingRight: 0.5,
    border: "1px solid transparent",
    whiteSpace: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-word",
    display: "grid !important",
    gridTemplateColumns,

    "&.MuiAutocomplete-option": {
        paddingTop: 2.5,
        paddingBottom: 2.5,
    },
    ":hover": {
        border: `1px solid ${theme.palette.primary.main}`,
        borderLeft: "1px solid transparent",
    },
}))

type Props = {
    liProps: React.HTMLAttributes<HTMLLIElement>
    option: VehicleSuggestions
    plateCode: string
    showPlateColumn: boolean
}

export function VehicleSearchOption(props: Props) {
    const { option, liProps, plateCode, showPlateColumn } = props
    const { translateText } = useLocalization()
    switch (option.type) {
        case "CUSTOMER":
            const startVal = showPlateColumn ? 1 : 0
            return (
                <HighlightWrapper {...liProps} gridTemplateColumns={showPlateColumn ? "minmax(120px, 1fr) 3fr min-content" : "3fr min-content"}>
                    {option.vehicle?.plateId && (
                        <Box sx={{ gridColumnStart: startVal }}>
                            <LicensePlate
                                value={option.vehicle.plateId}
                                countryCode={plateCountryCodeMapper(option.vehicle?.countryCode || plateCode)}
                                licensePlateComponentInitiator={LicensePlateComponentInitiator.VehicleSearchOption}
                            />
                        </Box>
                    )}

                    <Stack width="100%" sx={{ gridColumnStart: startVal + 1 }}>
                        <Typography>{concat(" ", option.vehicle?.manufacturer, option.vehicle?.modelSeries, option.vehicle?.model)}</Typography>

                        {option.vehicle?.vin && <Typography variant="label">{option.vehicle.vin}</Typography>}
                    </Stack>

                    <Box sx={{ gridColumnStart: startVal + 2 }}>
                        <Tooltip title={translateText(1041)}>
                            <Icon name="paperclip" />
                        </Tooltip>
                    </Box>
                </HighlightWrapper>
            )
        case "REGNO":
            return (
                <HighlightWrapper {...liProps} gridTemplateColumns="minmax(120px, 1fr) 3fr">
                    <Box sx={{ gridColumnStart: 1 }}>
                        {option.carModel?.plateId && (
                            <LicensePlate
                                value={option.carModel.plateId}
                                countryCode={plateCountryCodeMapper(option.carModel?.countryCode || plateCode)}
                                licensePlateComponentInitiator={LicensePlateComponentInitiator.VehicleSearchOption}
                            />
                        )}
                    </Box>
                    <Box sx={{ gridColumnStart: 2 }}>
                        <Typography width="100%">{option.carModel?.fullDescription || option.carModel?.description}</Typography>
                    </Box>
                </HighlightWrapper>
            )

        default:
            return (
                <HighlightWrapper {...liProps} gridTemplateColumns="1fr">
                    <Typography component="span">
                        {convertAutosuggestHit(option.suggestionHighlight ?? option.suggestions, AutosuggestHitMui)}
                    </Typography>
                </HighlightWrapper>
            )
    }
}
