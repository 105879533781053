import { useRef, CSSProperties, useEffect, useState } from "react"
import { ExternalWidgetConfig, ExternalWidgetsKeyValueLoader } from "../../plugins/ExternalWidgets"
import ExternalWidget from "./components/externalWidget"

function getTargetConfigs(conatinerId: string) {
    const [target, slot] = conatinerId.split(":")
    return Object.values(ExternalWidgetsKeyValueLoader.values).reduce(
        (p, c) => [...p, ...c.filter((x) => x.target === target && x.slot === slot)],
        []
    )
}

type ComponentProps = {
    containerId: string
    styles?: CSSProperties
}

function ExternalWidgetsContainer(props: ComponentProps) {
    const { containerId, styles } = props
    const [widgetConfigs, setWidgetConfigs] = useState<ExternalWidgetConfig[]>(getTargetConfigs(containerId))
    const containerRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        return ExternalWidgetsKeyValueLoader.subscribe(() => {
            setWidgetConfigs(getTargetConfigs(containerId))
        })
    }, [])

    if (!widgetConfigs.length) {
        return null
    }

    return (
        <div className="bundle-misc external-widgets-container" style={styles} ref={containerRef}>
            {widgetConfigs.map((cfg, idx) => (
                <ExternalWidget key={idx} {...cfg} />
            ))}
        </div>
    )
}

export default ExternalWidgetsContainer
