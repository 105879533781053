import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetActivatableModulesResponse, SetModuleActivationRequest, SetModuleActivationResponse } from "@tm/models"
import { getBundleParams } from "../../utils"

export function getActivatableModules(): Promise<GetActivatableModulesResponse | undefined> {
    const url = `${getBundleParams().authorityServiceUrl}/ModulStore/GetActivatableModules`
    const authorization = getStoredAuthorization()
    const body = {}

    return ajax({ url, authorization, body })
}

export function setModuleActivation(request: SetModuleActivationRequest): Promise<SetModuleActivationResponse | undefined> {
    const url = `${getBundleParams().authorityServiceUrl}/ModulStore/SetModuleActivation`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax({ url, authorization, body, method: "POST" })
}
