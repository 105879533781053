import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        container: {
            height: "100%",
            width: "100%",
        },
        fileDropField: {
            justifyContent: "center",
            height: "100%",
            width: "100%",
        },
        clipboard: {
            paddingTop: ".8rem",
            display: "flex",
            flexDirection: "column",
        },
        clipboardText: {
            paddingBottom: ".3rem",
        },
        clipboardButtonContainer: {
            display: "flex",
            alignItems: "center",
            marginBottom: margin.xl,
            $nest: {
                svg: {
                    marginLeft: margin.l,
                    marginRight: margin.s,
                },
            },
        },
        uploadContainer: {
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            justifyContent: "center",
            $nest: {
                ":nth-child(2)": {
                    marginTop: margin.m,
                },
            },
        },
        button: {
            padding: ".8rem 1rem",
        },
    })
}
