import { CostEstimationOptions } from "@tm/models"
import { CostEstimationCalculation } from "../../../../../../data/model"
import { TotalsLoader } from "../TotalsLoader"
import { B2BGross } from "./components/B2BGross"
import { B2BNet } from "./components/B2BNet"
import { B2CGross } from "./components/B2CGross"
import { B2CNet } from "./components/B2CNet"

type Props = {
    costEstimationCalculation?: CostEstimationCalculation
    costEstimationOptions?: CostEstimationOptions
    currencyCode: string
    totalsLoading: boolean
}

export function AllTotals({ costEstimationCalculation, totalsLoading, currencyCode, costEstimationOptions }: Props) {
    const { customerGroupMode, priceVatMode } = costEstimationOptions || {}

    if (totalsLoading) {
        return <TotalsLoader />
    }

    if (!costEstimationCalculation) {
        return null
    }

    if (customerGroupMode) {
        return priceVatMode ? (
            <B2BGross currencyCode={currencyCode} totals={costEstimationCalculation.totalsB2B} />
        ) : (
            <B2BNet currencyCode={currencyCode} totals={costEstimationCalculation.totalsB2B} />
        )
    }

    return priceVatMode ? (
        <B2CGross currencyCode={currencyCode} totals={costEstimationCalculation.totalsB2C} />
    ) : (
        <B2CNet currencyCode={currencyCode} totals={costEstimationCalculation.totalsB2C} />
    )
}
