import { CisCustomerResponse } from "@tm/models"
import { CustomerRequestForDocuments } from "../model"

export function mapCustomerForDocumentsRequest(customer?: CisCustomerResponse): CustomerRequestForDocuments {
    if (customer) {
        let customerAdress = ""
        const address = customer.addresses?.first()

        if (customer.companyName) {
            customerAdress += `${customer.companyName}\n`
        }
        if (address && address.street) {
            customerAdress += `${address.street}\n`
        }
        if (address && address.zip) {
            customerAdress += `${address.zip} `
        }
        if (address && address.city) {
            customerAdress += address.city
        }
        return {
            customerNo: customer.customerNo || "",
            customerName: "",
            customerNote: "",
            companyName: customer.companyName || "",
            postalAddress: customerAdress,
            email: "",
            phone: (address && address.phone) || "",
            city: (address && address.city) || "",
            country: (address && address.country) || "",
            zip: (address && address.zip) || "",
            street: (address && address.street) || "",
            state: "",
        }
    }
    return {
        customerNo: "",
        customerName: "",
        customerNote: "",
        companyName: "",
        postalAddress: "",
        email: "",
        phone: "",
        city: "",
        country: "",
        zip: "",
        street: "",
        state: "",
    }
}
