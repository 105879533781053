import { Widget } from "@tm/controls"
import { RouteComponentProps, useAvailableVehicleSearchOptions, useMultiWidgetState, withRouter } from "@tm/utils"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { VehicleLookupConfig, VehicleType } from "@tm/models"
import { useEffect, useState } from "react"
import { getVehicleSearchUrl } from "../../helpers/routing"
import { useResetFilters } from "../../data/hooks"
import BikeCardComponent from "./BikeCard"
import { VehicleSearchFieldSwitch } from "../_shared/VehicleSearchFieldSwitch"

type Props = RouteComponentProps<{ workTaskId?: string }> &
    LocalizationProps & {
        showComponentKey?: string
        storeId?: string
        contentOnly: string
    }

function BikeWidgetComponent(props: Props) {
    const resetFilters = useResetFilters(VehicleType.Motorcycle)
    const [selectedMultiWidgetTab] = useMultiWidgetState({ storeId: props.storeId })
    const [selectedSearchConfig, setSelectedSearchConfig] = useState<VehicleLookupConfig>()
    const [specializedSearchConfig, setSpecializedSearchConfig] = useState<VehicleLookupConfig>()
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const allConfiguredVehicleLookups = availableVehicleSearches?.configuredVehicleLookups

    useEffect(() => {
        if (!selectedSearchConfig && allConfiguredVehicleLookups) {
            const defaultVehicleLookup = allConfiguredVehicleLookups.find((x) => x.isDefault)
            defaultVehicleLookup && setSelectedSearchConfig(defaultVehicleLookup)
        }
    }, [availableVehicleSearches])

    useEffect(() => {
        if (availableVehicleSearches) {
            const { configuredVehicleLookups } = availableVehicleSearches // if there is an selectedSearchConfig, availableVehicleSearches is maybe set
            if (availableVehicleSearches?.configuredVehicleLookups && selectedSearchConfig) {
                const vrmLookupConfig = configuredVehicleLookups.find(
                    (lookup) => !lookup.isUsedInDefaultSearch && selectedSearchConfig.countryCode === lookup.countryCode
                )
                setSpecializedSearchConfig(vrmLookupConfig)
            }
        }
    }, [selectedSearchConfig])

    if (props.showComponentKey && selectedMultiWidgetTab !== props.showComponentKey) {
        return null
    }

    const handleStartSearch = (query: string) => {
        resetFilters() // Reset filters before opening search page
        // TODO:  ADD selectedVehicleLookup ?
        props.history.push(getVehicleSearchUrl(props.match.params, { vehicleType: VehicleType.Motorcycle, query }))
    }

    if (props.contentOnly) {
        return <BikeCardComponent handleStartSearch={handleStartSearch} specializedSearchConfig={specializedSearchConfig} />
    }

    return (
        <Widget active className="tk-vehicle bike-widget-start" size="4x4">
            <div className="search-container">
                <VehicleSearchFieldSwitch {...props} onStartSearch={handleStartSearch} vehicleType={VehicleType.Motorcycle} size="small" />
            </div>
        </Widget>
    )
}

export default withLocalization(withRouter(BikeWidgetComponent))
