import { useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { channel, ConfigParams } from "@tm/models"
import { useCisCustomer, useReloadCisCustomer } from "@tm/context-distribution"
import Morpheus from "@tm/morpheus"
import { getNextTourFromTourArray } from "../../business/helper"
import NextTourDisplay, { NextTourVariation } from "./components/display"
import { useCisShowTours } from "../../data/hooks/useCisShowTours"

type Props = {
    disableClick?: boolean
    showTours: boolean
    variation: NextTourVariation
}

function NextTourFromShowTours({ variation, disableClick }: Omit<Props, "showTours">) {
    const { date } = useLocalization()
    const { tours } = useCisShowTours()

    const nextTour = getNextTourFromTourArray(tours)

    useEffect(() => {
        channel("GLOBAL").publish("ERP/CLEAR_CACHE", {})
    }, [nextTour])

    const text = nextTour?.orderAcceptanceUntil && date(nextTour.orderAcceptanceUntil, "t")

    return <NextTourDisplay text={text} variation={variation} disableClick={disableClick} />
}

const SAFETY_MARGIN = 20 * 1000 // 20 seconds

function NextTourFromCustomer({ variation, disableClick }: Omit<Props, "showTours">) {
    const { cisCustomer } = useCisCustomer()
    const { invalidateCisCustomer } = useReloadCisCustomer()
    const text = cisCustomer?.nextTourInfo

    useEffect(() => {
        if (!text) {
            return
        }

        let ms = 0

        // Try to parse as date
        const timestamp = Date.parse(text)
        if (timestamp) {
            ms = timestamp - new Date().getTime()
        } else {
            // if that doesn't work, try to get the time part with a regex
            const match = /.?(\d{2}):(\d{2}).?/.exec(text) // for example "12:00"
            if (match) {
                const date = new Date()
                date.setHours(parseInt(match[1]) || 0, parseInt(match[2]) || 0, 0)

                ms = date.getTime() - new Date().getTime()
            }
        }

        if (ms <= SAFETY_MARGIN) {
            // Dont reload, if the time difference is negative or less then 20 seconds, so we dont ddos ourselves
            return
        }

        const { nextToursTimeoutRandomSecondsMultiplier } = Morpheus.getParams<ConfigParams>()

        // value between 0 and 40 seconds
        const randomMs = Math.floor(Math.random() * (nextToursTimeoutRandomSecondsMultiplier ?? 40) * 1000)

        const timeout = setTimeout(() => {
            invalidateCisCustomer()
            channel("GLOBAL").publish("ERP/CLEAR_CACHE", {})
        }, ms + SAFETY_MARGIN + randomMs)

        return () => clearTimeout(timeout)
    }, [invalidateCisCustomer, text])

    return <NextTourDisplay text={text} variation={variation} disableClick={disableClick} />
}

export default function NextTourComponent({ showTours, variation, disableClick }: Props) {
    return showTours ? (
        <NextTourFromShowTours variation={variation} disableClick={disableClick} />
    ) : (
        <NextTourFromCustomer variation={variation} disableClick={disableClick} />
    )
}
