import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Text, Image, Loader, LicensePlateField } from "@tm/controls"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { Vehicle } from "@tm/models"
import { VehicleImage } from "@tm/components"
import { VehicleRepository } from "../../data"

type Props = {
    hideLogos: boolean
    vehicleId: string | undefined
}

export default function VehicleInfoComponent({ vehicleId, hideLogos }: Props) {
    const { translateText } = useLocalization()
    const [{ loading, vehicle }, setState] = useState<{ loading: boolean; vehicle?: Vehicle }>({ loading: true })
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)

    useEffect(() => {
        if (!vehicleId) {
            return
        }
        setState({ loading: true })

        let cancelled = false
        VehicleRepository.getVehicleBuffered(vehicleId).then(
            (vehicle) => {
                if (!cancelled) {
                    setState({ loading: false, vehicle })
                }
            },
            () => {
                if (!cancelled) {
                    setState({ loading: false })
                }
            }
        )

        return () => {
            cancelled = true
        }
    }, [vehicleId])

    if (loading) {
        return <Loader />
    }

    if (vehicle) {
        const manufacturerThumbnail = vehicle.manufacturerThumbnail?.replace(/https?:/, "") // removing http: and https:
        const modelSeriesThumbnail = vehicle.modelSeriesThumbnail?.replace(/https?:/, "") || ""
        const modelThumbnail = vehicle.modelThumbnail?.replace(/https?:/, "") || ""

        return (
            <div className="tk-crm vehicle-info">
                <Text size="s" modifiers="strong">
                    {vehicle.manufacturer}
                </Text>
                <Text size="xs">
                    {vehicle.modelSeries} {vehicle.model}
                </Text>
                {vehicle.plateId && <LicensePlateField readonly shortCountryCode={plateCode} value={vehicle.plateId} size="s" />}
                {!hideLogos && <Image url={manufacturerThumbnail || ""} />}
                <VehicleImage modelImage={modelThumbnail} modelSeriesImage={modelSeriesThumbnail} vehicleType={vehicle.vehicleType} />
            </div>
        )
    }

    return <Text>{translateText(100)}</Text>
}
