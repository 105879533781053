import { classes, useStyle } from '@tm/context-distribution'
import { useLocalization } from '@tm/localization'
import { channel } from '@tm/models'
import { em, percent } from 'csx'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WheelSelectionSteps } from '../../data/enums'
import { MainState } from '../main'
import { MainActions } from '../main/business'
import ApplyButton from "../_shared/applyBtn"
import { Actions } from './business'
import SensorFilters from "./components/sensorFilters"
import SensorListTable from "./components/sensorList"
import { useActions } from '@tm/morpheus'

type Props = {
	articleAlternativesRoute: string
	repairTimesRoute: string
	detailsModalRoute?: string
}

export default function RdksList({ articleAlternativesRoute, repairTimesRoute, detailsModalRoute }: Props) {

	const dispatch = useDispatch()
	const { translateText } = useLocalization()

	const selectedArticle = useSelector((s: MainState) => s.rdksList.selectedArticle)
	const actions = useActions(Actions, "loadSensorsList")

	React.useEffect(() => {
		const unsubscribeErpModifiers = channel("GLOBAL").subscribe("ERP/ERP_INFORMATION_LOADED", erpInfo => {
			dispatch(Actions.setErpInformations(erpInfo))
		})
		actions.loadSensorsList()

		return () => {
			unsubscribeErpModifiers?.()
		}
	}, [])

	const handleApplyButton = () => {
		dispatch(Actions.sendArticleToOverview())
		dispatch(MainActions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, true))
		selectedArticle && dispatch(MainActions.saveTpmsTab(selectedArticle))
	}

	const handleSkipTab = () => {
		dispatch(MainActions.changeStep({ step: WheelSelectionSteps.TIRESLIST }, false))
	}

	return (
		<div className={classes(style.wrapper, "rdks-list")} >
			<ApplyButton
				showApplyBtn
				disabledApplyBtn={!selectedArticle}
				onApply={handleApplyButton}
				showSkipBtn
				skipBtnText={translateText(1117)}
				onSkip={handleSkipTab}
			/>
			<SensorFilters
				className={style.filters}
			/>
			<SensorListTable
				className={style.list}
				repairTimesRoute={repairTimesRoute}
				articleAlternativesRoute={articleAlternativesRoute}
				detailsModalRoute={detailsModalRoute}
			/>
		</div>
	)
}

const style = useStyle({
	wrapper: {
		width: percent(100),
		display: "flex"
	},
	filters: {
		flexBasis: em(22),
	},
	list: {
		flex: 1
	}
})(RdksList)