import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { UserModuleType } from "@tm/models"
import { initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { Box, Loader, Typography, styled } from "@tm/components"
import { sumItemsPrices, sumWorksPrices } from "../../data/helpers"
import { erpArticlesSelector, itemsSelector, worksSelector } from "../main/business"
import CustomAccordion from "../_shared/CustomAccordion"
import { ArticleList, Header, Totals, WorkList } from "./components"
import DiscountWarning from "./components/discountWarning"

const selector = initSelector(
    itemsSelector,
    worksSelector,
    erpArticlesSelector,
    (s) => s.calculation.calculationLoading,
    (s) => s.calculation.calculationError,
    (s) => s.calculation.isDiscount
)

export default function CalculationComponent() {
    const user = useUser()
    const currencyString = user?.userContext?.modules?.find((x) => x.type === UserModuleType.Catalog)?.currencyCode ?? "EUR"
    const { translateText, currency } = useLocalization()

    const [items, works, erpArticles, calculationLoading, calculationError, isDiscount] = useSelector(selector)

    if (calculationLoading) {
        return (
            <StyledBox>
                <Loader />
            </StyledBox>
        )
    }

    if (calculationError) {
        return (
            <StyledBox>
                <Typography>{translateText(1569)}</Typography>
            </StyledBox>
        )
    }

    // move business logic on ws
    const totalItems = (items && sumItemsPrices(items, erpArticles)) || 0
    const totalWorks = (works && sumWorksPrices(works)) || 0

    return (
        <StyledDamageCalculationEurotax>
            <DiscountWarning open={isDiscount} />

            <Header />

            <Totals />

            <CustomAccordion title={translateText(90)} totalItems={items?.length ?? 0} price={currency(totalItems, currencyString)}>
                <ArticleList />
            </CustomAccordion>

            <CustomAccordion title={translateText(83)} totalItems={works?.length ?? 0} price={currency(totalWorks, currencyString)}>
                <WorkList />
            </CustomAccordion>
        </StyledDamageCalculationEurotax>
    )
}

const StyledDamageCalculationEurotax = styled(Box)({
    ".calculation-content": {
        padding: "1rem",
        flex: 1,
    },
})

const StyledBox = styled(Box)({
    padding: "1rem",
    flex: "1",
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
    height: "100%",
})
