import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        panel: {
            marginBottom: 0,
            flexDirection: "column",
            display: "flex",
            height: "30rem",
            width: "100%",
            maxWidth: "35rem",
        },
        panelHeader: {
            display: "flex",
            marginBottom: margin.xl,
        },
        panelHeaderIcon: {
            marginRight: margin.m,
        },
        panelContent: {
            flex: "1 1 auto",
            display: "flex",
        },
    })
}
