import { RegistrationNoType, VehicleType } from "@tm/models"
import { selectorFamily, useRecoilValueLoadable } from "recoil"

import { searchVehiclesByRegNo, searchVehiclesByVin } from "../../business"
import { ModelsResponse } from "../../data/hooks"

type RequestByVinOrRegNo = {
    vehicleType: VehicleType
    query: string
    type: "vin" | "regNo"
    lookupTypeId: RegistrationNoType
    forceUpdate?: boolean
}

const vehiclesByVinOrRegNoRecoilSelector = selectorFamily<ModelsResponse, RequestByVinOrRegNo>({
    key: "vehicle_search_byvinorregno",
    get:
        ({ vehicleType, query, type, lookupTypeId, forceUpdate }) =>
        () => {
            switch (vehicleType) {
                case VehicleType.PassengerCar:
                    if (type === "vin") {
                        return searchVehiclesByVin(query, lookupTypeId, forceUpdate)
                    }

                    return searchVehiclesByRegNo(query, lookupTypeId, forceUpdate, vehicleType)

                case VehicleType.CommercialVehicle:
                    if (type === "regNo") {
                        return searchVehiclesByRegNo(query, lookupTypeId, forceUpdate, vehicleType)
                    }
                    break
                default:
            }
        },
})

export function useVehiclesByVinOrRegNo(request: RequestByVinOrRegNo) {
    const selector = vehiclesByVinOrRegNoRecoilSelector(request)
    const loadable = useRecoilValueLoadable(selector)

    if (loadable.state === "hasValue") {
        return loadable.contents
    }
}
