import { Channel, MessageBus } from "@tm/hermes"
import { CarModelDetailsResponse, Customer, Vehicle } from "@tm/models"
import { EreSelectionSteps } from "./data/models"
import { GetCalculationDataResponse } from "./data/repositories"

export type BundleActionTypes =
    | { type: "VEHICLE_SET"; payload: Vehicle }
    | { type: "CUSTOMER_SET"; payload: Customer }
    | { type: "RESTART_PROCESS" }
    | { type: "CHANGE_STEP"; payload: { selectedStep: EreSelectionSteps; params?: any; fromHistory?: boolean } }
    | { type: "CALCULATION_DATA_LOADED"; payload: GetCalculationDataResponse }
    | { type: "DETAILS_LOADED"; payload: CarModelDetailsResponse }
    | { type: "UPDATE_EXTERNAL_DATA"; payload: { url: string; sessionId: string; historyId: number } }
    | { type: "LOADING"; payload: boolean }

export function changeStep(selectedStep: EreSelectionSteps, params?: any, fromHistory?: boolean): BundleActionTypes {
    return { type: "CHANGE_STEP", payload: { selectedStep, params, fromHistory } }
}

export function setVehicle(veh: Vehicle): BundleActionTypes {
    return { type: "VEHICLE_SET", payload: veh }
}

export function setCustomer(customer: Customer): BundleActionTypes {
    return { type: "CUSTOMER_SET", payload: customer }
}

export function loading(value: boolean): BundleActionTypes {
    return {
        type: "LOADING",
        payload: value,
    }
}

let mb: MessageBus<BundleChannels>

export const bundleChannel = (): Channel<BundleChannels, "BUNDLE"> => {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }

    return mb.channel("BUNDLE")
}
export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = { SET_REPAIR_TIME_DIVISION: number }

// eslint-disable-next-line @typescript-eslint/ban-types
export type IBundleActions = {}
export const BundleActions: IBundleActions = {}
