import { useQuery } from "react-query"
import { getNotificationMessages } from "../getNotificationMessages"

export const POLLING_QUERY_KEY = "notificationPolling"

export function useNotificationPolling() {
    const { data } = useQuery({
        queryKey: [POLLING_QUERY_KEY],
        queryFn: async () => {
            const notifications = await getNotificationMessages()
            return notifications
        },
        refetchInterval: 10000,
    })

    const numberOfUnreadMessages = data === undefined ? 0 : data.filter((x) => !x.isRead).length

    return { polledNotificationMessages: data, numberOfUnreadMessages }
}
