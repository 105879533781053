/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Icon, Paper, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleAttribute, ArticleListType, ListFilter } from "@tm/models"
import { usePartsModuleState } from "../../PartsModuleState"
import { Filter, FilterButtons } from "./FilterButtons"
import { SearchSwap } from "./SearchSwap"

type Props = {
    searchType: ArticleListType
}

export function NoSearchResult(props: Props) {
    const { translateText } = useLocalization()
    const { searchType } = props
    const filters = usePartsModuleState((x) => x.filters)

    const productGroupFiltered = filters.productGroupFilters
        .filter((x) => x[1] === true && x[0].name)
        .map<Filter>((y) => ({ id: y[0].id, value: y[0].name! }))
    const dataSupplierFiltered = filters.dataSupplierFilters
        .filter((x) => x[1] === true && x[0].name)
        .map<Filter>((y) => ({ id: y[0].id, value: y[0].name! }))
    const attributeFiltered = filters.attributeFilters
        .filter((x) => x[1] === true && x[0].value)
        .map<Filter & ArticleAttribute>((y) => y[0] as Filter & ArticleAttribute)

    return (
        <Paper sx={{ my: 2 }}>
            <Stack flexDirection="column" alignContent="center" alignItems="center">
                <Icon name="no-results" width={80} height={80} />
                <Stack mt={1} alignItems="center">
                    {searchType !== "oe" ? (
                        <>
                            <Typography variant="h2">{translateText(809)}</Typography>
                            <Typography variant="body2" sx={{ marginBottom: "25px" }}>
                                {translateText(810)}
                            </Typography>
                        </>
                    ) : (
                        <Typography variant="h4">{translateText(1821)}</Typography>
                    )}
                </Stack>
                {productGroupFiltered.length >= 1 && (
                    <FilterButtons filters={productGroupFiltered} onRemoveFilter={(filter) => filters.toggleProductGroup(filter.id)} />
                )}
                {dataSupplierFiltered.length >= 1 && (
                    <FilterButtons filters={dataSupplierFiltered} onRemoveFilter={(filter) => filters.toggleSupplier(filter.id)} />
                )}
                {attributeFiltered.length >= 1 && (
                    <FilterButtons filters={attributeFiltered} onRemoveFilter={(filter) => filters.toggleAttribute?.(filter as ListFilter)} />
                )}
                {searchType !== "oe" && <SearchSwap searchType={searchType} />}
            </Stack>
        </Paper>
    )
}
