import { CompilationArticleInterface } from "../interfaces/compilation-article-interface"
import { ArticleAddRequest } from "../model"

export const mapArticleToAddRequest = (articles: CompilationArticleInterface["articles"]): ArticleAddRequest[] => {
    return articles.map((article) => ({
        description: article.description,
        note: article.note,
        quantity: article.quantity,
        wholesalerArticleNo: article.traderArticleNo || article.wholesalerArticleNo,
        supplierArticleNumber: article.supplierArticleNumber,
        supplierId: article.supplierId,
        supplierName: article.supplierName,
        productGroupId: article.productGroupId,
        productGroupName: article.productGroupName,
    }))
}
