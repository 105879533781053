import { Article, ArticleInfoType } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { max } from "lodash"
import { useCallback, useMemo, useState } from "react"
import { getMatchingBonusPoints, getMatchingCatalogArticle, mapBasketPartItem, mapOrderOptionsToSelectedOrderOptions } from "../../data/mapper"
import { CalculateWorkTaskBasketResponse, ShowWorkTaskBasketResponse, UpdateOrderResponse } from "../../data/model"
import {
    createArrayToggle,
    getMatchingErpInfo,
    getMatchingErpInfoGroup,
    getMatchingOrderOptionsGroup,
    getMatchingUpdatedErpGroupResponse,
} from "../../helpers"
import { BasketErpInfo, BasketErpInfoGroup, BasketOrderGroup, OrderOptionsGroup } from "../../models"
import { CalculateBonusPointsResponse } from "../../data/model/CalculateBonusPoints"

export function useBasket(
    articles: Article[],
    workTaskBasket?: ShowWorkTaskBasketResponse,
    erpInfoGroups?: BasketErpInfoGroup[],
    erpInformation?: BasketErpInfo[],
    workTaskBasketCalculation?: CalculateWorkTaskBasketResponse,
    beingUpdatedPartIds?: string[],
    orderOptionsBeingUpdatedPartIds?: string[],
    orderOptionGroups?: OrderOptionsGroup[],
    erpInfoBeingLoadedPartIds?: string[],
    bonusPointsCalculation?: CalculateBonusPointsResponse,
    updatedOrderData?: UpdateOrderResponse
) {
    const [selectedBasketPartIds, setSelectedBasketPartIds] = useState<string[]>([])
    const [collapsedOrderOptionsBasketPartIds, setCollapsedOrderOptionsBasketPartIds] = useState<string[]>([])
    const [expandedNoteBasketPartIds, setExpandedNoteBasketPartIds] = useState<string[]>([])

    const toggleBasketPartSelect = useMemo(() => createArrayToggle(setSelectedBasketPartIds), [])
    const toggleBasketPartOrderOptionsExpand = useMemo(() => createArrayToggle(setCollapsedOrderOptionsBasketPartIds), [])
    const toggleBasketPartNoteExpand = useMemo(() => createArrayToggle(setExpandedNoteBasketPartIds), [])

    const basketOrderGroups = useMemo<BasketOrderGroup[] | undefined>(() => {
        return workTaskBasket?.orderGroups?.map<BasketOrderGroup>((orderGroup) => {
            const parts = orderGroup.partIds
                .map((id) => {
                    return workTaskBasket?.parts?.find((part) => part.id === id)
                })
                .filter(notUndefinedOrNull)

            const orderOptionsGroup = getMatchingOrderOptionsGroup(orderGroup, orderOptionGroups)
            const orderOptionsLoading = orderOptionsGroup?.isLoading || orderOptionsGroup?.isFetching
            const orderOptions = orderOptionsGroup?.orderOptions
            const selectedOrderOptions = orderOptions ? mapOrderOptionsToSelectedOrderOptions(orderOptions, workTaskBasket.workTaskId) : undefined
            const erpInfoGroupRequest = getMatchingErpInfoGroup(orderGroup, erpInfoGroups)
            const erpInfoGroupResponses = getMatchingErpInfo(orderGroup, erpInformation)
            const updatedOrderResponse = getMatchingUpdatedErpGroupResponse(orderGroup, updatedOrderData?.erpGroups)

            const basketParts = parts.map((partItem) => {
                const article = getMatchingCatalogArticle(partItem, articles)
                const bonusPoints = getMatchingBonusPoints(partItem, bonusPointsCalculation?.calculatedBonusPointItems)
                return mapBasketPartItem(
                    workTaskBasket.workTaskId,
                    partItem,
                    selectedBasketPartIds,
                    beingUpdatedPartIds,
                    undefined,
                    collapsedOrderOptionsBasketPartIds,
                    expandedNoteBasketPartIds,
                    orderOptionsBeingUpdatedPartIds,
                    erpInfoBeingLoadedPartIds,
                    article,
                    erpInfoGroupRequest ? [erpInfoGroupRequest] : undefined,
                    erpInfoGroupResponses,
                    workTaskBasketCalculation?.calculatedOrder?.calculatedItems,
                    undefined,
                    undefined,
                    bonusPoints
                )
            })

            const hasOeParts = basketParts.some((part) => part.partItem.articleInformation.articleInfoType === ArticleInfoType.OeArticle)
            const partsIncludedInOrder = basketParts.filter((part) => part.partItem.orderItem?.isIncluded)
            const hasPartsExcludedFromOrder = basketParts.some((part) => !part.partItem.orderItem?.isIncluded)
            const hasIncludedPartsOrderable = partsIncludedInOrder.some((part) => part.erpInfoResponse?.isOrderable)
            const hasPartsBeingUpdated = beingUpdatedPartIds?.some((partId) => basketParts.some((part) => partId === part.partItem.id))
            const hasPartsLoadingErpInfo = erpInfoBeingLoadedPartIds?.some((partId) => basketParts.some((part) => partId === part.partItem.id))

            const bonusPointTotalsInOrder = bonusPointsCalculation?.bonusPointTotalsInOrder
            const completeDeliveryTourStart = updatedOrderResponse
                ? updatedOrderResponse.completeDeliveryTourStart
                : max(erpInfoGroupResponses?.map((erp) => erp.erpInfos?.completeDeliveryTourStart))
            const showAdditionalCostsConfirmation = updatedOrderResponse
                ? updatedOrderResponse.showAdditionalCostsConfirmation
                : erpInfoGroupResponses?.some((erp) => erp.erpInfos?.showAdditionalCostsConfirmation)

            return {
                basketParts,
                bonusPointTotalsInOrder,
                completeDeliveryTourStart,
                showAdditionalCostsConfirmation,
                hasOeParts,
                hasPartsBeingUpdated,
                hasPartsExcludedFromOrder,
                hasPartsIncludedInOrder: !!partsIncludedInOrder.length,
                hasPartsLoadingErpInfo,
                hasIncludedPartsOrderable,
                orderGroup,
                orderOptionsGroup,
                orderOptionsLoading,
                selectedOrderOptions,
            }
        })
    }, [
        workTaskBasket?.orderGroups,
        workTaskBasket?.workTaskId,
        workTaskBasket?.parts,
        orderOptionGroups,
        erpInfoGroups,
        erpInformation,
        updatedOrderData?.erpGroups,
        beingUpdatedPartIds,
        erpInfoBeingLoadedPartIds,
        bonusPointsCalculation?.bonusPointTotalsInOrder,
        bonusPointsCalculation?.calculatedBonusPointItems,
        articles,
        selectedBasketPartIds,
        collapsedOrderOptionsBasketPartIds,
        expandedNoteBasketPartIds,
        orderOptionsBeingUpdatedPartIds,
        workTaskBasketCalculation?.calculatedOrder?.calculatedItems,
    ])?.filter((basketOrderGroup) => !!basketOrderGroup.basketParts?.length)

    const selectAllBasketParts = useCallback((basketOrderGroup: BasketOrderGroup) => {
        const partIds = basketOrderGroup.basketParts?.map((basketPart) => basketPart?.partItem.id)
        if (partIds) {
            setSelectedBasketPartIds((prev) => prev.concat(partIds))
        }
    }, [])

    const unselectAllBasketParts = useCallback((basketOrderGroup: BasketOrderGroup) => {
        const partIds = basketOrderGroup.basketParts?.map((basketPart) => basketPart?.partItem.id)
        if (partIds) {
            setSelectedBasketPartIds((prev) => prev.filter((partId) => !partIds.includes(partId)))
        }
    }, [])

    return {
        basket: {
            state: {
                basketOrderGroups,
                hasItems: workTaskBasket?.orderGroups?.some((orderGroup) => orderGroup.partIds.length),
                showAdditionalCostsConfirmationAllErpGroups: updatedOrderData
                    ? updatedOrderData.showAdditionalCostsConfirmationAllErpGroups
                    : erpInformation?.some((erpInfo) => erpInfo.erpInfos?.showAdditionalCostsConfirmation),
            },
            actions: {
                toggleBasketPartSelect,
                toggleBasketPartOrderOptionsExpand,
                toggleBasketPartNoteExpand,
                unselectAllBasketParts,
                selectAllBasketParts,
            },
        },
    }
}
