import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { JobListQueryParams, JobListResponse, JobPDFResponse } from "../../models"
import { getSindriBearerToken } from "../helpers/login"
import { JobDetailsResponse } from "../../models/jobDetails"

export async function getJobList(queryParams: JobListQueryParams): Promise<JobListResponse | undefined> {
    const url = `${getBaseUrl()}/GetJobs`
    const authorization = getStoredAuthorization()
    const body = { queryParams, token: await getSindriBearerToken() }
    return ajax<JobListResponse>({ url, body, authorization, method: "POST" })
}

export async function getJobPDF(jobId: string): Promise<JobPDFResponse | undefined> {
    const url = `${getBaseUrl()}/GetPDF`
    const authorization = getStoredAuthorization()
    const body = { jobId, token: await getSindriBearerToken() }
    return ajax<JobPDFResponse>({ url, body, authorization, method: "POST" })
}

export async function getJobDetails(jobId: string): Promise<JobDetailsResponse | undefined> {
    const url = `${getBaseUrl()}/GetJobDetails`
    const authorization = getStoredAuthorization()
    const body = { jobId, token: await getSindriBearerToken() }
    return ajax<JobDetailsResponse>({ url, body, authorization, method: "POST" })
}

function getBaseUrl(): string {
    return getBundleParams().sindriServiceUrl
}
