import { useQuery, QueryClient } from "react-query"
import { useUser } from "@tm/context-distribution"
import { useEffect } from "react"
import * as Data from "../../.."
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"

export const KEY = "basket_useWorkTaskBasket_showWorkTaskBasket"
const staleTime = 10 * 60 * 1000 // 10 Minutes

export function useWorkTaskBasketData(workTaskId: string, useCostEstimation: boolean, enabled: boolean) {
    const { userSettings } = useUser()
    const workTaskBasketIndex = useWorkTaskBasketStore((store) => store.getWorkTaskBasketIndex(workTaskId))
    const workTaskBasketKeys = useWorkTaskBasketStore.getState().workTaskBasketKeys[workTaskId]
    const QUERY_KEY = [KEY, workTaskId, userSettings?.itemSorting, useCostEstimation, workTaskBasketIndex]

    // The getCachedWorkTaskBasketData needs these Keys to avoid duplicated calls
    useEffect(() => {
        if (userSettings?.itemSorting !== workTaskBasketKeys?.orderByDateAscending || useCostEstimation !== workTaskBasketKeys?.useCostEstimation) {
            useWorkTaskBasketStore
                .getState()
                .setWorkTaskBasketKeys(workTaskId, { orderByDateAscending: userSettings?.itemSorting, useCostEstimation })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [workTaskId, useCostEstimation, userSettings?.itemSorting])

    const {
        data,
        isLoading: workTaskBasketLoading,
        isError: workTaskBasketWithError,
    } = useQuery(QUERY_KEY, () => Data.showWorkTaskBasket({ workTaskId, orderByDateAscending: userSettings?.itemSorting, useCostEstimation }), {
        enabled: !!userSettings && enabled,
        staleTime,
    })

    return {
        workTaskBasket: data,
        workTaskBasketLoading,
        workTaskBasketQueryKey: QUERY_KEY,
        workTaskBasketWithError,
    }
}

export function getCachedWorkTaskBasketData(queryClient: QueryClient, workTaskId: string, enabled: boolean) {
    if (!enabled) {
        return Promise.resolve(undefined)
    }

    const workTaskBasketIndex = useWorkTaskBasketStore.getState().getWorkTaskBasketIndex(workTaskId)
    const workTaskBasketKeys = useWorkTaskBasketStore.getState().workTaskBasketKeys[workTaskId]

    const orderByDateAscending = workTaskBasketKeys?.orderByDateAscending
    const useCostEstimation = workTaskBasketKeys?.useCostEstimation ?? false
    const QUERY_KEY = [KEY, workTaskId, orderByDateAscending, useCostEstimation, workTaskBasketIndex]

    return queryClient.fetchQuery(QUERY_KEY, () => Data.showWorkTaskBasket({ workTaskId, orderByDateAscending, useCostEstimation }), {
        staleTime,
    })
}
