import { channel, RequestArticleListPayload } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"
import { shouldUseNewModal } from "./helpers/newPartslist"

export default function handleInvokeUniversalSearch(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (data.invokeUniversalSearch && data.invokeUniversalSearch.searchText) {
        const request: RequestArticleListPayload = {
            uniSearch: {
                query: data.invokeUniversalSearch.searchText,
            },
            inModal: true,
            useNewModal: shouldUseNewModal(data.sourceId),
        }

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}
