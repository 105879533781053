import { ErpInformation, PriceType, ErpPrice } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Text } from "@tm/controls"
import { ErpPriceAdditionalInfo, Stack } from "@tm/components"

type Props = {
    erpInfo: ErpInformation
    quantity: number
    types: Array<PriceType>
    multiply: boolean
    className?: string
}

export default function PriceRenderer(props: Props) {
    const { translateText, currency } = useLocalization()

    const { prices, quantity } = props.erpInfo

    let content
    if (prices?.length) {
        for (let i = 0; i < prices.length; i++) {
            const price: ErpPrice | undefined = prices.find((p) => !!p && p.type === props.types[i])
            const depositPrice: ErpPrice | undefined = prices.find((p) => !!p && p.type === PriceType.Deposit)

            if (price) {
                let { value } = price
                let description = ""

                if (props.multiply) {
                    value = price.value * (props.quantity || 1)
                }

                switch (price.type) {
                    case PriceType.Purchase:
                        description = props.multiply ? translateText(1197) : translateText(55)
                        break
                    case PriceType.Retail:
                        description = translateText(1198)
                        break
                    case PriceType.RecommendedRetail:
                        description = translateText(1144)
                        break
                    default:
                        break
                }

                content = (
                    <>
                        <Text modifiers="sub" size="xs" className="price__type">
                            {description}
                        </Text>
                        <Stack direction="row" spacing={0.8} alignItems="center">
                            <Text className="price__value" modifiers="highlight">
                                {currency(value, price.currencySymbol || price.currencyCode_Iso_4217 || "")}
                            </Text>
                            {!props.multiply &&
                                !!price.priceUnit &&
                                price.type === PriceType.Purchase &&
                                props.quantity > 1 &&
                                (!!quantity?.quantityUnit || price.priceUnit > 1) && (
                                    <Text modifiers="sub" size="xs" className="price__unit">
                                        {translateText(1312)} {price.priceUnit} {quantity?.quantityUnit || ""}
                                    </Text>
                                )}
                            {!props.multiply && !!depositPrice && price.type === PriceType.Purchase && (
                                <Text modifiers="sub" size="xs" className="price__unit">
                                    {`${translateText(1099)} `}{" "}
                                    {currency(depositPrice.value, depositPrice.currencySymbol || depositPrice.currencyCode_Iso_4217 || "")}
                                </Text>
                            )}
                            <ErpPriceAdditionalInfo price={price} />
                        </Stack>
                    </>
                )
            }
        }
    }

    return <div className={`price ${props.className || ""}`}>{content}</div>
}
