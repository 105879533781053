import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetServicesByNodeIdRequest, ServiceType } from "../models"
import { getServiceUrl } from "../params"
export async function getServiceNodesById(body: GetServicesByNodeIdRequest): Promise<Array<ServiceType[]> | undefined> {
    const url = `${getServiceUrl()}/GetServicesByNodeId`
    const authorization = getStoredAuthorization()

    return await ajax<any>({ url, body, authorization, method: "GET" })
        .then(response => response?.services)
}
