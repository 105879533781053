import { Paging, ShowCustomArticleItemsRequest, ShowCustomArticleItemsResponse } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { useInfiniteQuery } from "react-query"
import { getBundleParams } from "../../utils"

type PagedResponse<T> = {
    data: T
    pageIndex: number
    endOfList: boolean
}

const QUERY_KEY = "fastService__custom-articles"

export function showCustomArticleItems(body: ShowCustomArticleItemsRequest) {
    const url = `${getBundleParams().customArticlesServiceUrl}/ShowCustomArticleItems`
    const authorization = getStoredAuthorization()
    return ajax<ShowCustomArticleItemsResponse>({ url, body, authorization }).then((response) => ({
        data: response?.customArticleItems ?? [],
        pageIndex: body.pageIndex,
        endOfList: (response?.customArticleItems?.length ?? 0) < body.pageSize,
    }))
}

export function useShowCustomArticleItems(request: Omit<ShowCustomArticleItemsRequest, "pageIndex" | "pageSize">) {
    return useCustomItems(request, { queryKey: QUERY_KEY, dataFn: showCustomArticleItems })
}

export function useCustomItems<TRequest, TData>(
    request: TRequest,
    { queryKey, dataFn }: { queryKey: string; dataFn(request: TRequest & Paging): Promise<PagedResponse<TData>> }
) {
    return useInfiniteQuery([queryKey, request], ({ pageParam = 1 }) => dataFn({ ...request, pageIndex: pageParam, pageSize: 10 }), {
        getNextPageParam: (lastPage) => {
            if (!lastPage.endOfList) {
                return lastPage.pageIndex + 1
            }
        },
    })
}
