import { useCallback } from "react"
import { TmaEModule } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { uniqueId } from "@tm/utils"
import { useBasketParts } from "../../../../../../basket/src/hooks/basketState/useBasketParts"

export function useHandleRemoveFromBasket() {
    const { workTaskId } = useWorkTask() ?? {}
    const { removePartList } = useBasketParts()
    const handleRemoveFromBasket = useCallback(
        (partIds: Array<string>) => {
            if (!workTaskId) {
                return
            }
            return removePartList({ workTaskId, partIds, isExternalCall: true, log: { eventId: uniqueId(), origin: TmaEModule.ARTICLE_LIST } })
        },
        [removePartList, workTaskId]
    )
    return handleRemoveFromBasket
}
