import { Button, EasyPopover, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { stopPropagation } from "../../../helpers"
import { FastServiceFileShare } from "./FastServiceFileShare"

export function SharePopover() {
    const { translateText } = useLocalization()

    return (
        <EasyPopover anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ horizontal: "right", vertical: "top" }}>
            <EasyPopover.ActionButton>
                <Button size="extralarge" variant="contained" startIcon={<Icon name="share" />} onClick={stopPropagation((e) => {})}>
                    {translateText(12787)}
                </Button>
            </EasyPopover.ActionButton>
            <EasyPopover.Content sx={{ padding: "1em" }}>
                <FastServiceFileShare />
            </EasyPopover.Content>
        </EasyPopover>
    )
}
