import { MessageBus, Channel } from "@tm/hermes"
import { OpenWorkTaskDialogState } from "./components/chats/components/chat-infos/open-worktask-dialog"
import { DocumentCategoryType } from "./components/chats/components/input/attachment/document-form"
import { Message } from "./data/hooks"

let mb: MessageBus<BundleChannels>

export function bundleChannel<T extends keyof BundleChannels>(type: T, id?: string): Channel<BundleChannels, T> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }
    return mb.channel(type, id)
}

export interface BundleChannels {
    CHAT: ChatChannelType
}

export type ChatChannelType = { OPEN_DOCUMENT_FORM: { category?: DocumentCategoryType; externalVehicleId?: string } } & {
    SHOW_IMAGE_VIEWER: { message: Message }
} & { OPEN_WORKTASK: OpenWorkTaskDialogState }

export function openWorkTask(data: OpenWorkTaskDialogState) {
    bundleChannel("CHAT").publish("OPEN_WORKTASK", data)
}
