import { useMemo } from "react"
import { VehicleType } from "@tm/models"
import { Dropdown } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { withRouter, RouteComponentProps, renderRoute } from "@tm/utils"
import { style } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { SelectionsState } from "../business"

type Props = RouteComponentProps<{ vehicleType: string }> & {
    state: SelectionsState
}

function VehicleTypeSwitch(props: Props) {
    const theme = useMemo(() => getStyleTheme(), [])
    const vehicleType: VehicleType = parseInt(props.match.params.vehicleType)
    const vehicleTypes: Array<{ vehicleType: VehicleType }> = []
    if (props.state.referencedVehicleCount) {
        vehicleTypes.push({ vehicleType: VehicleType.PassengerCar })
    }
    if (props.state.referencedBikesCount) {
        vehicleTypes.push({ vehicleType: VehicleType.Motorcycle })
    }
    if (props.state.referencedTrucksCount) {
        vehicleTypes.push({ vehicleType: VehicleType.CommercialVehicle })
    }

    if (vehicleTypes.length <= 1) {
        return null
    }

    const handleVehicleTypeChange = ({ vehicleType: newType }: { vehicleType: VehicleType }) => {
        if (newType !== vehicleType) {
            props.history.push(
                renderRoute(props.match.path, {
                    ...props.match.params,
                    vehicleType: newType,
                    manufacturerId: undefined,
                    mainModelSeriesId: undefined,
                    modelSeriesId: undefined,
                    modelId: undefined,
                }) + (props.location.search || "")
            )
        }
    }

    return (
        <Dropdown
            className={style({
                $nest: {
                    ".btn": {
                        height: "100%",
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        minWidth: "8rem",
                        background: theme.colors.primary,
                    },
                    ".btn__content": { marginRight: "0.5rem", opacity: 1 },
                    ".btn__icon": { opacity: 1 },
                },
            })}
            items={vehicleTypes}
            value={{ vehicleType }}
            itemView={DropDownItemView}
            layout={["iconRight"]}
            size="l"
            skin="primary"
            onChange={handleVehicleTypeChange}
        />
    )
}

function DropDownItemView({ vehicleType }: { vehicleType: VehicleType }) {
    const { translateText } = useLocalization()
    switch (vehicleType) {
        case VehicleType.PassengerCar:
            return <>{translateText(1234)}</>
        case VehicleType.Motorcycle:
            return <>{translateText(730)}</>
        case VehicleType.CommercialVehicle:
            return <>{translateText(1915)}</>
    }
    return null
}

export default withRouter(VehicleTypeSwitch)
