import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            flex: 1,
            padding: `${margin.m} ${margin.xl}`,
            display: "flex",
            flexDirection: "column",
        },
        header: {
            minHeight: "3rem",
            marginBottom: margin.xl,
            display: "flex",
        },
        headline: {
            alignSelf: "center",
        },
        content: {
            flex: 1,
        },
    })
}
