import { useEffect, useRef } from "react"
import { useLocalization } from "@tm/localization"
import { WarningPrompt } from "@tm/controls"
import { Typography } from "@tm/components"

type Props = {
    open?: boolean
}

export default function DiscountWarning({ open }: Props) {
    const { translateText } = useLocalization()
    const warningRef = useRef<WarningPrompt>(null)

    useEffect(() => {
        if (open) {
            warningRef?.current?.show()
        }
    }, [])

    return (
        <WarningPrompt
            ref={warningRef}
            confirmationButtonText={translateText(1628)}
            text={<Typography maxWidth="22em">{translateText(13850)}</Typography>}
            hideCloseButton
        />
    )
}
