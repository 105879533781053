import { SyntheticEvent } from "react"
import { useUser } from "@tm/context-distribution"
import { ArticleNumber, Button, Icon, Stack, styled } from "@tm/components"
import { copyToClipboard } from "@tm/utils"
import { channel } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../utils"

export const StyledStack = styled(Stack)({
    flex: "0 0 13.6em",
    minWidth: "13.6em",
})

type Props = {
    oeArticleNumber?: string
    hideCopyButton?: boolean
    supplierArticleNumber?: string
    wholesalerArticleNumber?: string
    size?: "medium" | "large"
    onOpenArticleDetails?(): void
}

export default function ArticleNumbersComponent(props: Props) {
    const { supplierArticleNumber, wholesalerArticleNumber, onOpenArticleDetails, oeArticleNumber, hideCopyButton, size } = props
    const { showCopyNumberButton } = getBundleParams()
    const { translateText } = useLocalization()

    const userContext = useUser()?.userContext

    const renderCopyButton = (type: "trader" | "supplier") => {
        if (!showCopyNumberButton) {
            return
        }

        let number: string | undefined

        switch (type) {
            case "trader":
                if (showCopyNumberButton === true || showCopyNumberButton.includes("article-list--trader")) {
                    number = wholesalerArticleNumber
                }
                break
            case "supplier":
                if (showCopyNumberButton === true || showCopyNumberButton.includes("article-list--supplier")) {
                    number = supplierArticleNumber
                }
                break
            default:
                break
        }

        if (!number) {
            return
        }

        return (
            <Button
                startIcon={<Icon name="copy" />}
                variant="text"
                onClick={(e: SyntheticEvent) => {
                    e.stopPropagation()
                    copyToClipboard(number!)
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
                }}
            />
        )
    }

    if (oeArticleNumber) {
        return <ArticleNumber articleNumber={oeArticleNumber} articleNumberType="oe" />
    }
    if (wholesalerArticleNumber && !userContext?.parameter.hideDealerPartNumber) {
        return (
            <Stack direction="row" spacing={0.5}>
                <ArticleNumber
                    articleNumber={wholesalerArticleNumber}
                    articleNumberType="wholesaler"
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    size={size}
                    onClick={onOpenArticleDetails}
                />
                {!hideCopyButton && renderCopyButton("trader")}
            </Stack>
        )
    }
    if (supplierArticleNumber) {
        return (
            <Stack direction="row" spacing={0.5}>
                <ArticleNumber
                    articleNumber={supplierArticleNumber}
                    articleNumberType="supplier"
                    omitDefaultStyles={userContext?.parameter.positionChangeEArtNrHArtNr}
                    size={size}
                    onClick={onOpenArticleDetails}
                />
                {!hideCopyButton && renderCopyButton("supplier")}
            </Stack>
        )
    }
    return <></>
}
