import { Article } from "@tm/models"
import Main from "./components/main"
import GoToOverviewButton from "./micros/goto-overview"
import { MessageBus, Channel } from "@tm/hermes"
import { IBundle } from "@tm/morpheus"
import { initWheelsBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        Main
    ],
    micros: [
        GoToOverviewButton
    ],

    init: initWheelsBundle
}


export default bundle

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }

    return mb.channel("BUNDLE")
}

export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType =
    & { "SEND_TIRE_ARTICLE_TO_OVERVIEW": { article: Article } }
    & { "SEND_RDKS_ARTICLE_TO_OVERVIEW": { article: Article } }