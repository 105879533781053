import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Box, Button, Loader, Typography, styled } from "@tm/components"
import { dateToString } from "../../../data/helpers"
import { HistoryEntry } from "../../../data/models"
import { historySelector, MainActions } from "../../main/business"
import CustomDropdown from "../../_shared/CustomDropdown"

type Props = {
    onOpenHistoryCalculation(historyEntry: HistoryEntry, openTool: boolean): void
}

const mapHistoryEntries = (historyEntry?: HistoryEntry) =>
    (historyEntry && `${historyEntry.historyId} | ${dateToString(historyEntry.updateDateTime)}`) || ""

export default function HistorySelection({ onOpenHistoryCalculation }: Props) {
    const { translateText } = useLocalization()
    const history = useSelector(historySelector)
    const actions = useActions(MainActions, "updateHistoryItem")

    const { historyEntries, selectedHistoryEntry, historyEntriesLoading } = history

    if (historyEntriesLoading) {
        return (
            <StyledHistoryBox>
                <Loader />
            </StyledHistoryBox>
        )
    }

    if (!historyEntries?.length) {
        return null
    }

    const handleSelectHistoryItem = (historyEntryString: string) => {
        const selectedEntry = historyEntries?.find((x) => historyEntryString === mapHistoryEntries(x))

        selectedEntry && actions.updateHistoryItem(selectedEntry)
    }

    const historyItems = historyEntries?.map((x) => mapHistoryEntries(x))

    return (
        <StyledHistoryBox>
            <Typography variant="body2">{translateText(1599)}</Typography>
            <Box className="options">
                <CustomDropdown items={historyItems} selectedValue={historyItems?.[0]} maxItemsToShow={7} onChange={handleSelectHistoryItem} />

                {selectedHistoryEntry && (
                    <>
                        <Button onClick={() => onOpenHistoryCalculation(selectedHistoryEntry, true)}>{translateText(1580)}</Button>
                        <Button
                            sx={{ ml: ".2em" }}
                            onClick={() => onOpenHistoryCalculation(selectedHistoryEntry, false)}
                            disabled={!selectedHistoryEntry.hasCalcData}
                        >
                            {translateText(1579)}
                        </Button>
                    </>
                )}
            </Box>
        </StyledHistoryBox>
    )
}

const StyledHistoryBox = styled(Box)({
    display: "flex",
    flex: "1",
    padding: "1em 0",
    alignItems: "center",

    ".options": {
        display: "inline-flex",
        ".dropdown": {
            margin: "0 0.5em",
            width: "15em",
        },
    },
})
