import { useQuery } from "react-query"
import * as Data from "../.."
import { CalculateCentralOrderRequest } from "../../model"

const KEY = "basket_useCalculateCentralOrderData"

export function useCalculateCentralOrderData(request: CalculateCentralOrderRequest, workTaskOrdersCalculated: boolean) {
    const QUERY_KEY = [KEY, request]

    const { data, isLoading, isError } = useQuery(
        QUERY_KEY,
        () => {
            return Data.calculateCentralOrder(request)
        },
        {
            enabled: request.calculatedWorkTaskOrders.some((order) => !!order.numberOfItemsInOrder) && workTaskOrdersCalculated,
            staleTime: 10 * 60 * 1000, // 10 Minutes
        }
    )

    return {
        orderCalculation: data,
        orderCalculationLoading: isLoading,
        orderCalculationWithError: isError,
    }
}
