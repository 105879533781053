import { useState } from "react"
import { DataSupplier } from "@tm/models"
import { Tag, TagLayout, TagSkin, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../../utils"
import { useSupplierLogo } from "../../../data/hooks/useSupplierLogo"

type Props = {
    supplier: DataSupplier
    isDataSupplierFilterActive?: boolean
    onDataSupplierClick?(dataSupplierId: number): void
}

export default function SupplierComponent({ supplier, isDataSupplierFilterActive, onDataSupplierClick }: Props) {
    const { translateText } = useLocalization()
    const { supplierLogo } = useSupplierLogo({ supplierId: supplier.id })
    const [error, setError] = useState(false)

    const handleImageError = () => {
        setError(true)
    }

    let layout: TagLayout | undefined
    let skin: TagSkin | undefined

    if (!isDataSupplierFilterActive || !onDataSupplierClick) {
        layout = "ghost"
    } else {
        skin = "primary"
    }

    let value: any = supplier.name
    if (!error && getBundleParams().showSupplierLogos && supplierLogo) {
        value = <Image onError={handleImageError} url={supplierLogo} className="article__supplier-logo" />
    }

    return (
        <Tag
            className="supplier__name"
            layout={layout}
            skin={skin}
            size="m"
            value={{ value }}
            onClick={onDataSupplierClick ? () => onDataSupplierClick(supplier.id) : undefined}
            tooltip={onDataSupplierClick ? `${translateText(1048)}: ${supplier.name}` : supplier.name}
        />
    )
}
