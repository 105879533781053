import { getStoredAuthorization, ajax, ListResponse, TmaHelper } from "@tm/utils"
import {
    Version,
    WorkTaskReadModel,
    ShowCostEstimationDetailsRequest,
    ShowCostEstimationDetailsResponse,
    ChangeCostEstimationDetailsRequest,
    WorkTaskDetailsReadModel,
    AttachCustomerAndVehicleRequest,
    AttachVehicleRequest,
    AttachCustomerRequest,
    DetachCustomerRequest,
    CreateWorkTaskRequest,
    FindLatestWorkTaskByVehicleOrCustomerRequest,
    FindLatestWorkTaskByVehicleOrCustomerResponse,
} from "@tm/models"
import { getWorktaskServiceUrl, FindWorkTasksRequest } from ".."
import { mapWorkTaskDetailsReadModel, mapWorkTaskReadModel } from "../mapper"

export function createWorktask(request: CreateWorkTaskRequest & { returnNewVersion?: boolean }) {
    const url = `${getWorktaskServiceUrl()}/CreateWorkTask`
    const authorization = getStoredAuthorization()
    const vehicleSelectionLog = TmaHelper.VehicleSelection.GetVehicleSelectionLog()

    const body = { ...request, vehicleSelectionLog }

    return ajax({ url, body, authorization, method: "POST" }).then((response) => mapWorkTaskDetailsReadModel(response?.workTask))
}

export function attachCustomerAndVehicle(request: AttachCustomerAndVehicleRequest & { returnNewVersion?: boolean }) {
    const url = `${getWorktaskServiceUrl()}/AttachCustomerAndVehicle`
    const authorization = getStoredAuthorization()

    const vehicleSelectionLog = TmaHelper.VehicleSelection.GetVehicleSelectionLog()
    const body = { ...request, vehicleSelectionLog }

    return ajax({ url, body, authorization, method: "POST" }).then((response) => mapWorkTaskDetailsReadModel(response?.workTask))
}

export function attachVehicle(request: AttachVehicleRequest & { returnNewVersion?: boolean }) {
    const url = `${getWorktaskServiceUrl()}/AttachVehicle`
    const authorization = getStoredAuthorization()

    const vehicleSelectionLog = TmaHelper.VehicleSelection.GetVehicleSelectionLog()
    const body = { ...request, vehicleSelectionLog }

    return ajax({ url, body, authorization, method: "POST" }).then((response) => mapWorkTaskDetailsReadModel(response?.workTask))
}

export function attachCustomer(request: AttachCustomerRequest & { returnNewVersion?: boolean }) {
    const url = `${getWorktaskServiceUrl()}/AttachCustomer`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax({ url, body, authorization, method: "POST" }).then((response) => mapWorkTaskDetailsReadModel(response?.workTask))
}

export function detachCustomer(request: DetachCustomerRequest & { returnNewVersion?: boolean }) {
    const url = `${getWorktaskServiceUrl()}/DetachCustomer`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax({ url, body, authorization, method: "POST" }).then((response) => mapWorkTaskDetailsReadModel(response?.workTask))
}

export function detachVehicle(workTaskId: string, workTaskVersion?: Version): Promise<void> {
    const url = `${getWorktaskServiceUrl()}/DetachVehicle`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, version: workTaskVersion }

    return ajax({ url, body, authorization, method: "POST" })
}

export function setWorkTaskCompleted(workTaskId: string): Promise<void> {
    const url = `${getWorktaskServiceUrl()}/SetWorkTaskCompleted`
    const authorization = getStoredAuthorization()
    const body = { workTaskId }

    return ajax({ url, body, authorization, method: "POST" })
}

export function saveSelected(workTaskId: string): Promise<void> {
    const url = `${getWorktaskServiceUrl()}/SaveSelectedWorkTaskInfo`
    const authorization = getStoredAuthorization()
    const body = { workTaskId }

    return ajax({ url, body, authorization, method: "POST" })
}

export function findWorktasks(request: FindWorkTasksRequest): Promise<ListResponse<WorkTaskReadModel>> {
    const url = `${getWorktaskServiceUrl()}/FindWorkTasks`
    const authorization = getStoredAuthorization()
    const body = {
        ...request,
        pageIndex: request.pageIndex || 1,
        pageSize: request.pageSize || 20,
    }
    return new Promise<ListResponse<WorkTaskReadModel>>((resolve, reject) =>
        ajax({ url, body, authorization }).then(
            (response) =>
                resolve({
                    data: (response.workTasks || []).map(mapWorkTaskReadModel),
                    pageSize: response.pageSize,
                    pageIndex: response.pageIndex,
                    requestNumber: 0,
                }),
            reject
        )
    )
}

export function showWorktaskDetailed(workTaskId: string): Promise<WorkTaskDetailsReadModel | undefined> {
    const url = `${getWorktaskServiceUrl()}/ShowWorkTaskDetailed`
    const authorization = getStoredAuthorization()
    const body = { workTaskId }

    return ajax({ url, body, authorization }).then((response) => mapWorkTaskDetailsReadModel(response?.workTask))
}

export function showCostEstimationDetails(body: ShowCostEstimationDetailsRequest): Promise<ShowCostEstimationDetailsResponse> {
    const url = `${getWorktaskServiceUrl()}/ShowCostEstimationDetails`
    const authorization = getStoredAuthorization()

    return ajax<any>({ url, body, authorization })
}

// in order to compile instead of void the promise is of the same type as the request: ChangeCostEstimationDetailsRequest
export function changeCostEstimationDetails(body: ChangeCostEstimationDetailsRequest) {
    const url = `${getWorktaskServiceUrl()}/ChangeCostEstimationDetails`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export function findLatestWorkTaskByVehicleOrCustomer(body: FindLatestWorkTaskByVehicleOrCustomerRequest) {
    const url = `${getWorktaskServiceUrl()}/FindLatestWorkTaskByVehicleOrCustomer`
    const authorization = getStoredAuthorization()

    return ajax<FindLatestWorkTaskByVehicleOrCustomerResponse>({ url, body, authorization })
}
