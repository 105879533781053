import { DamageCalculationAswegasBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"
import { initRepositories } from "./data/repositories"

export const version = {
    name: "dmg-aswegas",
    version: "2.0",
}

let bundleParams: DamageCalculationAswegasBundleParams

export function initDamageCalculationAswegasBundle(params: DamageCalculationAswegasBundleParams) {
    bundleParams = params
    checkBundleParams(params)
    initRepositories()
    return bundleParams
}

const checkBundleParams = ({ asWegasServiceUrl }: DamageCalculationAswegasBundleParams) => {
    if (!isDevtoolEnabled()) {
        return
    }

    if (!asWegasServiceUrl) {
        // eslint-disable-next-line no-console
        console.warn("AsWegas service url is not passed")
    }
}

export const toolkitPath = "/bundles/dmg-aswegas"

export function getBundleParams(): DamageCalculationAswegasBundleParams {
    if (!bundleParams) {
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}
