import { create } from "zustand"

type CachedWorTaskBasketKeys = {
    orderByDateAscending?: boolean
    useCostEstimation: boolean
}

type WorkTaskBasketState = {
    workTaskBasketIndex: Record<string, number>
    workEstimationIndex: Record<string, number>
    basketErpInfoIndex: Record<string, number>
    basketCalculationIndex: Record<string, number>
    bonusPointsCalculationIndex: Record<string, number>
    updateOrderIndex: Record<string, number>
    workTaskBasketKeys: Record<string, CachedWorTaskBasketKeys | undefined>

    getWorkTaskBasketIndex: (workTaskId: string) => number
    getWorkEstimationIndex: (workTaskId: string) => number
    getBasketErpInfoIndex: (workTaskId: string) => number
    getBasketCalculationIndex: (workTaskId: string) => number
    getBonusPointsCalculationIndex: (workTaskId: string) => number
    getUpdateOrderIndex: (workTaskId: string) => number

    setWorkTaskBasketKeys: (workTaskId: string, basketKeys: CachedWorTaskBasketKeys) => void

    invalidateWorkTaskBasket: (workTaskId?: string) => void
    invalidateWorkEstimation: (workTaskId?: string) => void
    invalidateBasketErpInfo: (workTaskId?: string) => void
    invalidateBasketCalculation: (workTaskId?: string) => void
    invalidateBonusPointsCalculation: (workTaskId?: string) => void
    invalidateUpdateOrder: (workTaskId?: string) => void
}

type KeysOfType<T, U> = {
    [P in keyof T]: T[P] extends U ? P : never
}[keyof T]

function getIndex<S extends Record<string, any>>(
    set: (partial: S | Partial<S> | ((state: S) => S | Partial<S>), replace?: boolean | undefined) => void,
    indexName: KeysOfType<S, Record<string, number>>
) {
    return (workTaskId: string) => {
        let result = 0
        set((prev) => {
            const prevIndex = prev[indexName][workTaskId]
            if (prevIndex === undefined) {
                return {
                    ...prev,
                    [indexName]: { ...prev[indexName], [workTaskId]: result },
                }
            }
            result = prevIndex
            return prev
        })
        return result
    }
}

function increaseIndexes(indexRecord: Record<string, number>): Record<string, number> {
    return Object.entries(indexRecord).reduce((previous, [key, value]) => {
        return { ...previous, [key]: value + 1 }
    }, {})
}

function invalidateIndex<S extends Record<string, any>>(
    set: (partial: S | Partial<S> | ((state: S) => S | Partial<S>), replace?: boolean | undefined) => void,
    indexName: KeysOfType<S, Record<string, number>>
) {
    return (workTaskId?: string) => {
        if (workTaskId) {
            set((prev) => ({
                ...prev,
                [indexName]: { ...prev[indexName], [workTaskId]: (prev[indexName][workTaskId] ?? 0) + 1 },
            }))
        } else {
            set((prev) => ({
                ...prev,
                [indexName]: increaseIndexes(prev[indexName]),
            }))
        }
    }
}

function setWorkTaskBasketKeys(state: WorkTaskBasketState, worktaskId: string, basketKeys: CachedWorTaskBasketKeys): Partial<WorkTaskBasketState> {
    return {
        workTaskBasketKeys: {
            ...state.setWorkTaskBasketKeys,
            [worktaskId]: basketKeys,
        },
    }
}

const useWorkTaskBasketStore = create<WorkTaskBasketState>((set) => ({
    workTaskBasketIndex: {},
    workEstimationIndex: {},
    basketErpInfoIndex: {},
    basketCalculationIndex: {},
    bonusPointsCalculationIndex: {},
    updateOrderIndex: {},
    workTaskBasketKeys: {},
    getWorkTaskBasketIndex: getIndex(set, "workTaskBasketIndex"),
    getWorkEstimationIndex: getIndex(set, "workEstimationIndex"),
    getBasketErpInfoIndex: getIndex(set, "basketErpInfoIndex"),
    getBasketCalculationIndex: getIndex(set, "basketCalculationIndex"),
    getBonusPointsCalculationIndex: getIndex(set, "bonusPointsCalculationIndex"),
    getUpdateOrderIndex: getIndex(set, "updateOrderIndex"),
    invalidateWorkTaskBasket: invalidateIndex(set, "workTaskBasketIndex"),
    invalidateWorkEstimation: invalidateIndex(set, "workEstimationIndex"),
    invalidateBasketErpInfo: invalidateIndex(set, "basketErpInfoIndex"),
    invalidateBasketCalculation: invalidateIndex(set, "basketCalculationIndex"),
    invalidateBonusPointsCalculation: invalidateIndex(set, "bonusPointsCalculationIndex"),
    invalidateUpdateOrder: invalidateIndex(set, "updateOrderIndex"),
    setWorkTaskBasketKeys: (worktaskId, basketKeys) => set((state) => setWorkTaskBasketKeys(state, worktaskId, basketKeys)),
}))

export { useWorkTaskBasketStore }
