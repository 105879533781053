import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { UpdateTotalPricesAjaxResponse, UpdateTotalPricesRequest, UpdateTotalPricesResponse } from "./model"

export * from "./model"

export const updateTotalsPrices = async (calculationDataRequest: UpdateTotalPricesRequest): Promise<UpdateTotalPricesResponse> => {
    const url = `${getServiceUrl()}/UpdateTotalPrices`
    const authorization = getStoredAuthorization()
    const body = calculationDataRequest

    return ajax<UpdateTotalPricesAjaxResponse>({ url, body, method: "POST", authorization }).then((response) => {
        if (!response) {
            throw new Error()
        }

        return response.updatedTotals
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.businessUrl
}
