import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { useWorkTask } from "@tm/context-distribution"
import { IFrame, Loader } from "@tm/controls"
import { Alert, Snackbar } from "@tm/components"
import { AlldataState, IActions, Actions } from "./business"

type Props = RouteComponentProps<void> & {
    state: AlldataState
    actions: IActions

    checkRegistrationServiceUrl: string
    authenticationServiceUrl: string

    registrationUrl: string
    ktypUrl: string
}

type StatusMessage = {
    message: string
    type: "error" | "info"
}

function AlldataComponent(props: Props) {
    const [showInfo, setShowInfo] = useState<StatusMessage | undefined>(undefined)
    const { languageId, translateText } = useLocalization()
    const { workTask } = useWorkTask() ?? {}
    const {
        registrationUrl,
        ktypUrl,
        authenticationServiceUrl,
        checkRegistrationServiceUrl,
        state: { token, isRegistered, authenticationErrorUrl, authenticationError, authenticationErrorMessage },
        actions,
    } = props

    useEffect(() => {
        actions.getToken(checkRegistrationServiceUrl, authenticationServiceUrl)
    }, [checkRegistrationServiceUrl, authenticationServiceUrl])

    useEffect(() => {
        if (!isRegistered && !authenticationError) {
            setShowInfo({ type: "info", message: translateText(12591) })
        } else if (authenticationError) {
            setShowInfo({ type: "error", message: authenticationErrorMessage || translateText(787) })
        } else {
            setShowInfo(undefined)
        }
    }, [authenticationError, isRegistered])

    function getAlldataLocale() {
        switch (languageId) {
            case "1":
                return "de_DE"
            case "6":
                return "fr_FR"
            case "7":
                return "it_IT"
            case "8":
                return "es_ES"
            case "4":
            default:
                return "en_GB"
        }
    }

    let url
    if (workTask && workTask.vehicle) {
        if (authenticationErrorUrl !== undefined) {
            url = authenticationErrorUrl
        } else if (token) {
            if (isRegistered) {
                url = ktypUrl
                url = url.replace(":tecdocid", workTask.vehicle.tecDocTypeId.toString())
                url = url.replace(":locale", getAlldataLocale())
            } else {
                url = registrationUrl + token
            }
        } else {
            url = registrationUrl
        }
    }

    if (!url) {
        return null
    }

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: "bottom", horizontal: "right" }} open={showInfo !== undefined}>
                <Alert onClose={() => setShowInfo(undefined)} severity={showInfo?.type} sx={{ maxWidth: "20vw" }}>
                    {showInfo?.message}
                </Alert>
            </Snackbar>
            <IFrame url={url} className="module frame alldata" contextDependent id="iframe_alldata">
                <Loader />
            </IFrame>
        </>
    )
}
export default connectComponent(Actions, withRouter(AlldataComponent))
