import { useLocalization } from "@tm/localization"
import { MergedVehicle } from "../../../../../../data/hooks"
import { getDateFromMetadata } from "../appointment-info"
import { bundleChannel } from "../../../../../../bundle-channel"
import { addDaysToDate, getDateWithoutTime } from "../../../../../_shared/helper"
import WideButton from "../../wide-button"

type Props = {
    vehicle: MergedVehicle
}

export default function VehicleInvoiceInfo({ vehicle: { invoice, appointment, vehicleInfo } }: Props) {
    const { translateText, date } = useLocalization()

    const appointmentDate = appointment && new Date(getDateFromMetadata(appointment))
    const messageDate = invoice && new Date(invoice.inserted)

    if (
        !invoice?.attachment?.uri ||
        !messageDate ||
        !appointmentDate ||
        getDateWithoutTime(messageDate) < addDaysToDate(appointmentDate, -14) ||
        getDateWithoutTime(messageDate) > addDaysToDate(appointmentDate, 28)
    ) {
        const handleClick = () => {
            bundleChannel("CHAT").publish("OPEN_DOCUMENT_FORM", { category: "Invoice", externalVehicleId: vehicleInfo.id })
        }

        return <WideButton text={translateText(3054)} onClick={handleClick} icon="voucher-kva-international" />
    }

    return <WideButton text={`${translateText(3045)} (${date(messageDate, "d")})`} icon="voucher-kva-international" linkTo={invoice.attachment.uri} />
}
