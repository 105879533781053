import { Box, BoxProps, Icon, Typography } from "@tm/components"

type Props = BoxProps & {
    icon: string
    header: string
    unevenIconMargin?: boolean
    body?: string
}

export default function MaintenanceItem(props: Props) {
    const { icon, header, body, children, unevenIconMargin, ...rest } = props
    return (
        <Box display="flex" flexDirection="column" gap="0.5em" alignItems="center" height="100%" width="200px" {...rest}>
            <Box display="flex" flexDirection="column" gap="0.5em" alignItems="center" height="100%">
                <Icon width={32} height={32} name={icon} sx={{ marginLeft: unevenIconMargin ? "10px" : "0px" }} />
                <Typography variant="h4" color="primary" textAlign="center" maxLines={1}>
                    {header ?? ""}
                </Typography>
                <Typography variant="h4" textAlign="center" maxLines={2}>
                    {body ?? ""}
                </Typography>
            </Box>

            <Box height="32px" width="100%" justifyContent="center" display="flex">
                {children}
            </Box>
        </Box>
    )
}
