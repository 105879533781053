import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers/storeConenctor"
import { reduce } from "./business"
import component from "./main"

const Main: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "main",
    component: withStoreProvider(component) as any,
    reduce,
}

export default Main
