import { useLocalization } from "@tm/localization"
import { withRouter, renderRoute, RouteComponentProps } from "@tm/utils"
import { Widget } from "@tm/controls"
import { CisVoucherType } from "@tm/models"
import { useShowCisOptions } from "@tm/context-distribution"
import { Box, Divider, LinkButton, Loader, Stack, Typography } from "@tm/components"
import { Voucher } from "../../data/model"
import { CisVoucher } from "../../business/model"
import { useReturnsRecent } from "../../data/hooks/useReturnsRecent"
import { useComplaintsRecent } from "../../data/hooks/useComplaintsRecent"
import { TypographyTitle } from "../_shared/StyledComponents"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"
import ComplaintsTable from "../_shared/tables/ComplaintsTable"
import ReturnsTable from "../_shared/tables/ReturnsTable"

type Props = RouteComponentProps<RouteProps> & {
    subpageRoute: string
    titleTextId?: string
}
type RouteProps = {
    subpage?: string
    voucherTypeId?: string
    id?: string
}

function WidgetReturnsComponent(props: Props) {
    const { translate } = useLocalization()
    const { cisOptions } = useShowCisOptions()
    const { returns, returnsLoading } = useReturnsRecent()
    const { complaints, complaintsLoading } = useComplaintsRecent()
    const { subpageRoute, titleTextId, match } = props

    function renderVoucherTable(cisVoucherType: CisVoucherType, vouchers: CisVoucher[] | undefined) {
        if (cisVoucherType === CisVoucherType.Returns) {
            return <ReturnsTable vouchers={vouchers as Array<Voucher>} variant="small" singleSearch />
        }
        if (cisVoucherType === CisVoucherType.Complaints && complaints) {
            return <ComplaintsTable complaints={complaints} variant="small" />
        }
    }

    function renderVouchers(cisVoucherType: CisVoucherType) {
        const voucherType = cisOptions?.voucherTypes?.find((type) => type.typeId === cisVoucherType)

        if (voucherType) {
            const url = renderRoute(subpageRoute, {
                ...match.params,
                subpage: "vouchers",
                voucherTypeId: voucherType.typeId,
            })

            const vouchersLoading = cisVoucherType === CisVoucherType.Returns ? returnsLoading : complaintsLoading
            const vouchers = cisVoucherType === CisVoucherType.Returns ? returns : complaints

            return (
                <Box width="100%">
                    <TypographyTitle>{voucherType.description}</TypographyTitle>
                    {vouchersLoading ? (
                        <Box height="11.5em" display="flex" alignContent="center">
                            <Loader />
                        </Box>
                    ) : (
                        <Box height="11.5em">{renderVoucherTable(cisVoucherType, vouchers)} </Box>
                    )}
                    <Box display="flex" justifyContent="flex-end" sx={{ transform: "translateY(.5rem)" }}>
                        <LinkButton to={url} disabled={vouchersLoading}>
                            {translate(43)}
                        </LinkButton>
                    </Box>
                </Box>
            )
        }
        return <Typography>COMING SOON</Typography>
    }

    return (
        <Widget size="4x4" iconName="voucher" header={<WidgetHeaderTitleComponent iconName="voucher" titleTextId={titleTextId || 1119} />} active>
            {!cisOptions?.voucherTypes ? (
                <Loader />
            ) : (
                <Stack divider={<Divider />} justifyContent="center" spacing={1} height="100%">
                    <Box flex={1}>{renderVouchers(CisVoucherType.Returns)}</Box>
                    <Box flex={1}>{renderVouchers(CisVoucherType.Complaints)}</Box>
                </Stack>
            )}
        </Widget>
    )
}

export default withRouter(WidgetReturnsComponent)
