import { Box } from "@tm/components"
import { Article } from "@tm/models"
import { ICalculationItem } from "../../../data/models"
import { OeArticleItem } from "."

type Props = {
    item: ICalculationItem
    onOeArticleSelect(): void
    onQuantityChange(quantity: number): void
    onDetailsClick(article: Article): void
    onAlternativeClick(item: ICalculationItem): void
}

export default function CalculationItem({ item, onOeArticleSelect, onQuantityChange, onDetailsClick, onAlternativeClick }: Props) {
    return (
        <Box margin-bottom="1rem">
            <OeArticleItem
                item={item}
                showAlternativeBtn={item.alternativeParts && !!item.alternativeParts.length}
                onQuantityChange={onQuantityChange}
                onSelect={onOeArticleSelect}
                onDetailsClick={onDetailsClick}
                onAlternativeClick={() => onAlternativeClick(item)}
            />
        </Box>
    )
}
