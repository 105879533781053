import { useEffect } from "react"
import { useSelector } from "react-redux"
import { SuggestionTextField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { Box, Icon, Typography, styled, useTheme } from "@tm/components"
import { validateFields } from "../../../data/helpers"
import { MainActions, summarySelector } from "../../main/business"

export default function CustomerInfoFields() {
    const { translateText, languageId } = useLocalization()
    const { fields } = useSelector(summarySelector)
    const modelState = validateFields(fields, translateText)
    const actions = useActions(MainActions, "updateFieldValue", "initCustomer")
    const theme = useTheme()

    useEffect(() => {
        !fields.country && actions.initCustomer(translateText(3147))
    }, [languageId])

    const titleItems: Record<string, string> = {
        1: translateText(145),
        2: translateText(146),
        3: translateText(108),
    }

    const handleUpdateCustomerTitle = (value: string) => {
        const key = Object.keys(titleItems).find((x) => titleItems[x] === value)

        value && actions.updateFieldValue("titleType", key ?? value)
    }

    return (
        <Box borderTop=".1em solid #e2e2e2">
            <Box display="flex" padding={theme.margin?.m}>
                <Icon name="customer" size="2em" />
                <Typography size="xl">{translateText(107)}</Typography>
            </Box>

            <StyledBox className="customerInfo">
                <Box className="customerName">
                    <Box className="title">
                        <SuggestionTextField
                            floatingLabel
                            label={translateText(118)}
                            value={fields.titleType && titleItems[fields.titleType]}
                            getSuggestionData={() => Object.values(titleItems)}
                            onChangeConfirm={handleUpdateCustomerTitle}
                            modelState={modelState}
                            path={["titleType"]}
                        />
                    </Box>

                    <Box className="name">
                        <TextField
                            floatingLabel
                            label={translateText(119)}
                            value={fields.firstName}
                            path={["firstName"]}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("firstName", value)}
                        />
                    </Box>

                    <Box className="name">
                        <TextField
                            floatingLabel
                            label={translateText(104)}
                            path={["lastName"]}
                            modelState={modelState}
                            value={fields.lastName}
                            onChangeConfirm={(value) => actions.updateFieldValue("lastName", value)}
                        />
                    </Box>
                </Box>

                <Box className="locationArea">
                    <Box className="street">
                        <TextField
                            floatingLabel
                            label={translateText(3151)}
                            path={["street"]}
                            value={fields.street}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("street", value)}
                        />
                    </Box>
                    <Box className="zip">
                        <TextField
                            floatingLabel
                            label={translateText(112)}
                            path={["zip"]}
                            value={fields.zip}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("zip", value)}
                        />
                    </Box>
                    <Box className="city">
                        <TextField
                            floatingLabel
                            label={translateText(113)}
                            path={["city"]}
                            value={fields.city}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("city", value)}
                        />
                    </Box>
                    <Box className="country">
                        <TextField
                            floatingLabel
                            label={translateText(114)}
                            path={["country"]}
                            value={fields.country}
                            modelState={modelState}
                            onChangeConfirm={(value) => actions.updateFieldValue("country", value)}
                        />
                    </Box>
                </Box>
            </StyledBox>
        </Box>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    ".customerInfo": {
        display: "flex",
        flexWrap: "wrap",
        flex: 1,
        marginTop: theme.margin?.m,
        marginLeft: theme.margin?.m,
    },
    ".customerName": {
        display: "flex",
        flexBasis: "100%",
    },
    ".name, .street, .city, .country": {
        margin: theme.margin?.m,
        flexBasis: "20%",
    },
    ".title": {
        flexBasis: "4%",
        margin: theme.margin?.m,
    },
    ".suggest__box": {
        paddingLeft: "0.25em",
        paddingRight: "0.25em",
    },
    ".locationArea": {
        display: "flex",
        flexBasis: "100%",
    },
    ".zip": {
        flexBasis: "10%",
        margin: theme.margin?.m,
    },
}))
