import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { style } from "typestyle"
import { Checkbox, Text, WarningPrompt } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useWorkTaskBasketState } from "../../../hooks/basketState/useWorkTaskBasketState"

type Props = {
    workTaskId: string
    handleConfirmation(dontAskAgain: boolean): void
    handleCancellation(dontAskAgain: boolean): void
}

const checkBoxWrapperClassname = style({
    paddingLeft: "3.8rem",
})

export const DeletionPrompt = memo<Props>(({ handleConfirmation, handleCancellation, workTaskId }) => {
    const { translateText } = useLocalization()
    const { workTaskBasketLoading, basket } = useWorkTaskBasketState(workTaskId)
    const dialogRef = useRef<WarningPrompt>(null)
    const [checkboxActive, setCheckboxActive] = useState<boolean>(false)

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        if (!workTaskBasketLoading && basket.state.hasItems) {
            dialogRef.current?.show()
            return
        }

        if (!workTaskBasketLoading) {
            handleCancellation(false)
        }
    }, [basket.state.hasItems, workTaskBasketLoading, handleCancellation, workTaskId, dialogRef])

    const handleCheckboxChange = useCallback(() => {
        setCheckboxActive((pref) => !pref)
    }, [])

    const handleOnConfirm = useCallback(() => {
        handleConfirmation(checkboxActive)
    }, [handleConfirmation, checkboxActive])

    const handleOnCancel = useCallback(() => {
        handleCancellation(checkboxActive)
    }, [handleCancellation, checkboxActive])

    const textContent = useMemo(() => {
        return (
            <div>
                <Text size="l" modifiers={["strong"]}>
                    {translateText(12855)}
                </Text>
                <br />
                <Text size="m">{translateText(12856)}</Text>
                <br />
                <Text size="m">{translateText(12872)}</Text>
            </div>
        )
    }, [translateText])

    return (
        <WarningPrompt
            text={textContent}
            confirmationButtonText={translateText(585)}
            cancelButtonText={translateText(584)}
            ref={dialogRef}
            onConfirm={handleOnConfirm}
            onCancel={handleOnCancel}
        >
            <div className={checkBoxWrapperClassname}>
                <Checkbox label={translateText(12857)} size="s" checked={checkboxActive} onToggle={handleCheckboxChange} />
            </div>
        </WarningPrompt>
    )
})
