import { ajax, getStoredAuthorization } from "@tm/utils"

import { getBundleParams } from "../../utils"
import { Predictive } from "../model"

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.predictiveServiceUrl
}

export function getTopNodes(request: Predictive.GetTopNodesRequest) {
    return ajax<Predictive.GetTopNodesResponse>({
        url: `${getServiceUrl()}/GetTopNodes`,
        body: request,
        authorization: getStoredAuthorization(),
    })
}

export function getPredictiveNodes(request: Predictive.GetPredictiveNodesRequest) {
    return ajax<Predictive.GetPredictiveNodesResponse>({
        url: `${getServiceUrl()}/GetPredictiveNodes`,
        body: request,
        authorization: getStoredAuthorization(),
    })
}
