import { ErpInformationRequestItem, ErpInformation, ArticleDetailsErpTabs } from "@tm/models"
import { BundleActionType } from "../../../business"
import { LoadArticleListActionType, showArticleLists } from "./showArticleList"

type ErpInfoDetailsState = {}

type ComponentActionType = BundleActionType | LoadArticleListActionType | { type: "SHOW_ARTICLE_DETAILS_ERP_TABS"; payload: ArticleDetailsErpTabs }

const DEFAULT_STATE: ErpInfoDetailsState = {}

export function reduce(state = DEFAULT_STATE): ErpInfoDetailsState {
    return state
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "ERP_INFO_DETAILS_LOADED":
        case "ERP_INFO_DETAILS_ERROR":
        case "ERP_INFO_DETAILS_RESET":
        case "LOAD_ARTICLE_LIST":
        case "SHOW_ARTICLE_DETAILS_ERP_TABS": {
            return action
        }
        default:
            break
    }
}

export type IErpInfoDetailsActions = typeof ErpInfoDetailsActions

function shareErpInfoDetails(request: ErpInformationRequestItem, response: ErpInformation | undefined): ComponentActionType {
    return { type: "ERP_INFO_DETAILS_LOADED", payload: { request, response } }
}

function shareErpInfoDetailsError(error: string): ComponentActionType {
    return { type: "ERP_INFO_DETAILS_ERROR", payload: { message: error } }
}

function reset(): ComponentActionType {
    return { type: "ERP_INFO_DETAILS_RESET" }
}

function showArticleDetailsErpTabs(tabs: ArticleDetailsErpTabs): ComponentActionType {
    return { type: "SHOW_ARTICLE_DETAILS_ERP_TABS", payload: tabs }
}

export const ErpInfoDetailsActions = {
    shareErpInfoDetails,
    shareErpInfoDetailsError,
    reset,
    showArticleLists,
    showArticleDetailsErpTabs,
}
