import { useCallback, useEffect, useMemo, useState } from "react"
import { WorkTaskInfo } from "@tm/context-distribution"
import { Alert, Box, Loader } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { notUndefinedOrNull, useSelection } from "@tm/utils"
import { CustomArticle } from "@tm/models"
import { mapCustomArticleItemToCustomPart } from "../../../../data/mapper"
import { useBasketMemo } from "../../../../hooks/useBasketMemo"
import { CustomItemSearch } from "../CustomItemSearch"
import { CustomItemSelection } from "../CustomItemSelection"
import { useDeleteCustomArticles, useShowCustomArticleItems } from "../../../../data/hooks/custom-basket-items/useCustomArticles"
import { CustomArticlesList } from "./components/CustomArticlesList"
import { CustomItemHeader } from "../CustomItemHeader"
import { VatRateSelectionItem } from "../../../../data/model"
import { useWorkTaskBasketState } from "../../../../hooks/basketState/useWorkTaskBasketState"

type Props = {
    workTask: WorkTaskInfo
    currencySymbol: string | undefined
    customPartVatRates?: VatRateSelectionItem[]
}

export function CustomArticles({ workTask, currencySymbol, customPartVatRates }: Props) {
    const { translateText } = useLocalization()
    const { basket, costEstimation } = useWorkTaskBasketState(workTask.id)
    const { state } = costEstimation
    const { actions } = basket
    const { costEstimationOptions } = state.costEstimation ?? {}

    const [quantities, setQuantities] = useState<Map<string, number>>(new Map())

    const [request, setRequest] = useState<Parameters<typeof useShowCustomArticleItems>[0]>({
        priceVatMode: costEstimationOptions?.priceVatMode,
    })
    const { data: { pages } = {}, isFetchingNextPage, isLoading, isError, fetchNextPage, remove: resetCache } = useShowCustomArticleItems(request)
    const customArticles = useMemo(() => pages?.flatMap((x) => x.data) ?? [], [pages])

    const { mutateAsync: deleteArticle } = useDeleteCustomArticles()

    const basketMemo = useBasketMemo(workTask)

    const getArticleIds = useCallback(() => customArticles.map((x) => x.id).filter((x) => x), [customArticles])
    const { selectedIds, toggleSelected, selectAll, unselectAll } = useSelection(getArticleIds)

    // Reset query cache on unmount so on next component mount not all pages will be fetched again at once
    useEffect(() => {
        return () => {
            resetCache()
        }
    }, [resetCache])

    useEffect(() => {
        setRequest((prev) => ({
            ...prev,
            priceVatMode: costEstimationOptions?.priceVatMode,
        }))
    }, [costEstimationOptions?.priceVatMode])

    const handleChangeQuantity = useCallback((article: CustomArticle, quantity: number) => {
        setQuantities((prev) => new Map(prev).set(article.id, quantity))
    }, [])

    const handleToggleArticle = useCallback(
        (article: CustomArticle) => {
            toggleSelected(article.id)
        },
        [toggleSelected]
    )

    const handleDeleteSelected = useCallback(
        async (ids: Array<string>) => {
            const filteredIds = ids.filter((id) => customArticles.some((a) => a.id === id))

            if (filteredIds.length) {
                await deleteArticle(filteredIds)
                unselectAll()
            }
        },
        [customArticles, unselectAll, deleteArticle]
    )

    const handleAddToBasket = useCallback(
        async (ids: Array<string>) => {
            const customParts = ids
                .map((id) => {
                    const article = customArticles.find((a) => a.id === id)
                    return article ? mapCustomArticleItemToCustomPart(article, quantities.get(article.id) || 1, basketMemo.position) : undefined
                })
                .filter(notUndefinedOrNull)

            if (customParts.length) {
                await actions.addCustomParts({
                    workTaskId: workTask.id,
                    customParts,
                    vehicleId: workTask.vehicle?.id,
                    customerId: workTask.customer?.id,
                    usePercentageValues: true,
                    costEstimationOptions,
                })
            }
        },
        [customArticles, quantities, basketMemo.position, actions, workTask.id, workTask.vehicle?.id, workTask.customer?.id, costEstimationOptions]
    )

    const handleSearch = useCallback((query: string | undefined) => {
        setRequest((prev) => ({
            ...prev,
            query,
        }))
    }, [])

    let content
    let headerAttachment

    if (isLoading && !isFetchingNextPage) {
        content = <Loader />
    } else if (isError) {
        content = <Alert size="small" severity="error" title={translateText(163)} />
    } else if (customArticles.length) {
        content = (
            <CustomArticlesList
                workTask={workTask}
                articles={customArticles}
                currencySymbol={currencySymbol}
                quantities={quantities}
                loading={isLoading}
                selectedArticleIds={selectedIds}
                customPartVatRates={customPartVatRates}
                costEstimationOptions={costEstimationOptions}
                onQuantityChange={handleChangeQuantity}
                onLoadMoreArticles={fetchNextPage}
                onToggleArticle={handleToggleArticle}
                onAddCustomParts={actions.addCustomParts}
            />
        )

        headerAttachment = (
            <CustomItemSelection
                selectedIds={selectedIds}
                allSelected={customArticles.every((x) => selectedIds.includes(x.id))}
                selectAll={selectAll}
                unselectAll={unselectAll}
                deleteSelected={handleDeleteSelected}
                onAddToBasket={handleAddToBasket}
                itemsType="article"
            />
        )
    } else {
        content = <Alert size="small" severity="info" title={translateText(13291)} />
    }

    return (
        <Box p={1} pt={0}>
            <CustomItemHeader>
                <CustomItemSearch numberTextId={87} onSearch={handleSearch} />
                {headerAttachment}
            </CustomItemHeader>
            {content}
        </Box>
    )
}
