import { Suspense, useMemo } from "react"
import { Button, Icon, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Message } from "../../../../data/hooks"
import { DisplayName } from "./DisplayName"
import { getComponentStyles } from "./styles"

type Props = {
    url: string
    message: Message
    onClose(): void
}

export default function ImageViewer({ url, message, onClose }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { date } = useLocalization()

    return (
        <div className={classNames.main}>
            <div className={classNames.header}>
                <div className={classNames.headerLeft}>
                    <Suspense fallback={<Loader />}>
                        <DisplayName userId={message.authorId} />
                    </Suspense>

                    <Text modifiers={["sub", "block"]} size="xs" className={classNames.headerDate}>
                        {date(new Date(message.inserted), "g")}
                    </Text>
                </div>

                <a className={classNames.downloadButton} href={url} target="_blank" rel="noopener noreferrer">
                    <Icon name="download" />
                </a>
                <Button icon="cancel-x" layout={["ghost"]} onClick={onClose} />
            </div>

            <div className={classNames.imageWrapper}>
                <img src={url} className={classNames.image} />
            </div>
        </div>
    )
}
