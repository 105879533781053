import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetCalculationDataRequest, GetCalculationDataResponse } from ".."
import { getBundleParams } from "../../../utils"
import { mapEreCalculationData } from "./mapper"
import { IResponse } from "./rawModel"

export * from "./model"

export const getCalculationData = (calculationDataRequest: GetCalculationDataRequest) => {
    const url = `${getServiceUrl()}/GetCalculationData`
    const authorization = getStoredAuthorization()
    const body = calculationDataRequest

    return new Promise<GetCalculationDataResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then(
            (response: IResponse) => resolve(mapEreCalculationData(response.calculationData)),
            reject
        )
    )
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.ereCalculationServiceUrl
}
