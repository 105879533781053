export function getStorageKey() {
    // eslint-disable-next-line no-underscore-dangle
    return window.__NEXT_WORKTASKID__
}

type ReplacedMap = {
    type: "Map"
    value: [string, unknown][]
}

export const replacer = (key: string, value: unknown): ReplacedMap | unknown => {
    if (value instanceof Map) {
        return {
            type: "Map",
            value: Array.from(value.entries()),
        }
    }
    return value
}

export const reviver = (key: string, value: ReplacedMap | unknown) => {
    if (isReplacedMap(value)) {
        return new Map(value.value)
    }

    if (typeof value === "string" && /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/.test(value)) {
        return new Date(value)
    }

    return value
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isReplacedMap = (value: any): value is ReplacedMap => {
    if (value && value.type === "Map") {
        return true
    }

    return false
}
