import { Article, DATUpdatedTotals, DATWork, ErpInformation, OE } from "@tm/models"
import { clone, equals } from "@tm/utils"
import { BundleActionTypes } from "../business"
import { DEFAULT_STATE } from "../components/calculation/business"
import { CalculationState } from "../components/calculation/business/model"
import { MainActionsType } from "../components/main/business"
import { ICalculationItem } from "../data/model"
import { GetDatCalculationDataResponse } from "../data/repositories/getCalculationData"

export type CalculationActionTypes =
    | BundleActionTypes
    | { type: "CALCULATION_DATA_LOADING" }
    | { type: "CALCULATION_DATA_LOADED"; payload: GetDatCalculationDataResponse }
    | { type: "CALCULATION_DATA_ERROR" }
    | { type: "ADD_TO_SHOPPING_BASKET_LOADING" }
    | { type: "ADD_TO_SHOPPING_BASKET_SUCCESS" }
    | { type: "ADD_TO_SHOPPING_BASKET_ERROR" }
    | { type: "REPLACE_ARTICLE"; payload: { oeArticle: ICalculationItem; part: Article | OE.OePart } }
    | { type: "SELECT_ARTICLE"; payload: { oeArticle: ICalculationItem } }
    | { type: "SELECT_WORK"; payload: { work: DATWork } }
    | { type: "CHANGE_OE_ARTICLE_QUANTITY"; payload: { item: ICalculationItem; quantity: number } }
    | { type: "SET_ERP_ARTICLES"; payload: ErpInformation[] }
    | { type: "SET_UPDATED_TOTALS_START" }
    | { type: "SET_UPDATED_TOTALS"; payload: DATUpdatedTotals }
    | { type: "SET_UPDATED_TOTALS_ERROR" }

export const reduce = (state = { ...DEFAULT_STATE }, action: MainActionsType): CalculationState => {
    switch (action.type) {
        case "@@redux/INIT":
        case "RESTART_PROCESS":
        case "OPEN_HISTORY_CALCULATION":
        case "DAT_EXTERNAL_TOOL_DONE": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "CALCULATION_DATA_LOADING": {
            return {
                ...DEFAULT_STATE,
                calculationLoading: true,
            }
        }
        case "CALCULATION_DATA_ERROR": {
            return {
                ...state,
                calculationLoading: false,
                calculationError: true,
            }
        }
        case "CALCULATION_DATA_LOADED": {
            const { items, works, smallPartsPercentage, discounts, totals, initialSparePartsPrice } = action.payload

            return {
                ...state,
                calculationLoading: false,
                calculationError: false,
                calculationLoaded: true,
                works,
                items,
                smallPartsPercentage,
                discounts,
                initialSparePartsPrice,
                totals,
            }
        }
        case "ADD_TO_SHOPPING_BASKET_LOADING": {
            return {
                ...state,
                importLoading: true,
            }
        }
        case "ADD_TO_SHOPPING_BASKET_SUCCESS": {
            return {
                ...state,
                importLoading: false,
            }
        }
        case "ADD_TO_SHOPPING_BASKET_ERROR": {
            return {
                ...state,
                importLoading: false,
            }
        }
        case "REPLACE_ARTICLE": {
            const { oeArticle, part } = action.payload
            let items = clone(state.items)

            if ((part as OE.OePart).number) {
                items = items.map((x) => ({
                    ...x,
                    ...(x.oeArticle.oeArticleNumber === (part as OE.OePart).number && {
                        selectedPart: undefined,
                    }),
                }))
            } else {
                items = items.map((x) => ({
                    ...x,
                    ...(x.oeArticle.oeArticleNumber === oeArticle.oeArticle.oeArticleNumber && {
                        selectedPart: part as Article,
                    }),
                }))
            }

            return {
                ...state,
                items,
            }
        }
        case "SELECT_ARTICLE": {
            const { oeArticle } = action.payload
            const { items } = state

            return {
                ...state,
                items: items.map((x) => ({ ...x, ...(equals(x, oeArticle) && { isSelected: !x.isSelected }) })),
                updateTotalsInProgress: true,
            }
        }
        case "SELECT_WORK": {
            const { work } = action.payload
            return {
                ...state,
                works: state.works.map((x) => ({ ...x, ...(equals(x, work) && { isSelected: !x.isSelected }) })),
                updateTotalsInProgress: true,
            }
        }
        case "CHANGE_OE_ARTICLE_QUANTITY": {
            const { item, quantity } = action.payload
            const { items } = state

            const updatedItems: ICalculationItem[] = items.map((x) => ({
                ...x,
                ...(equals(x, item) && { oeArticle: { ...x.oeArticle, quantityValue: quantity } }),
            }))

            return {
                ...state,
                items: updatedItems,
                updateTotalsInProgress: true,
            }
        }
        case "SET_ERP_ARTICLES": {
            return {
                ...state,
                erpArticles: action.payload,
            }
        }
        case "SET_UPDATED_TOTALS_START": {
            return {
                ...state,
                updateTotalsInProgress: true,
            }
        }
        case "SET_UPDATED_TOTALS": {
            const { updatedOeArticles } = action.payload
            const items = clone(state.items)

            if (updatedOeArticles?.length) {
                updatedOeArticles.forEach((updatedArticle) => {
                    const match = items?.find(
                        (x) =>
                            x.oeArticle.oeArticleNumber === updatedArticle.oeArticleNumber &&
                            x.oeArticle.type === updatedArticle.type &&
                            x.oeArticle.datProcessId === updatedArticle.datProcessId
                    )

                    if (match) {
                        match.oeArticle = updatedArticle
                    }
                })
            }

            return {
                ...state,
                items,
                totals: action.payload,
                updateTotalsInProgress: false,
            }
        }
        case "SET_UPDATED_TOTALS_ERROR": {
            return {
                ...state,
                updateTotalsInProgress: false,
                updateTotalsFailed: true,
            }
        }
        default:
            return state
    }
}
