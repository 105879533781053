import { ReactNode, useMemo } from "react"
import { Checkbox, DateField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType } from "@tm/models"
import { classes, formatVin, getVehicleRegistrationNoLabelTextIds, ModelState } from "@tm/utils"
import { ShowVrcInformationResponse } from "../../../../../data/model/vrc-lookup"
import { kba } from "../../../../../helpers"
import { getComponentStyles } from "../styles"

type Props = {
    enabled: boolean
    setEnabled(): void
    vrcLookup: ShowVrcInformationResponse
    setVrcLookup(vrmLookup: ShowVrcInformationResponse): void
    saving: boolean
    requestVehicles: boolean
    modelState: ModelState | undefined

    showNoResultError: boolean
    setShowNoResultError(value: boolean): void

    regNoType: RegistrationNoType | undefined
    vrcModuleIsUsingKbaNumbers: boolean

    appendix?: ReactNode
}

const VIN_LENGTH = 17

export function VehicleForm(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const {
        enabled,
        setEnabled,
        vrcLookup,
        setVrcLookup,
        saving,
        requestVehicles,
        modelState,
        showNoResultError,
        setShowNoResultError,
        regNoType,
        vrcModuleIsUsingKbaNumbers,
        appendix,
    } = props
    const { translateText } = useLocalization()

    const regNoTypeLabel = getVehicleRegistrationNoLabelTextIds(regNoType)?.label
    const vehicleDisabled = saving || requestVehicles || !enabled

    function setModelAndResetError(model: ShowVrcInformationResponse) {
        setVrcLookup(model)

        if (showNoResultError) {
            setShowNoResultError(false)
        }
    }

    return (
        <div className={classes(classNames.form, !enabled && classNames.formDisabled)}>
            <Checkbox label={translateText(99)} size="l" checked={enabled} disabled={saving || requestVehicles} onToggle={setEnabled} />

            <div>
                <DateField
                    floatingLabel
                    label={translateText(124)}
                    value={vrcLookup.info.registrationDate}
                    model={vrcLookup}
                    path={["info", "registrationDate"]}
                    onChange={(registrationDate: Date) => setVrcLookup({ ...vrcLookup, info: { ...vrcLookup.info, registrationDate } })}
                    disabled={vehicleDisabled}
                />
            </div>
            <TextField
                floatingLabel
                label={regNoTypeLabel ? translateText(regNoTypeLabel) : undefined}
                model={vrcLookup}
                path={["info", "registrationNumber"]}
                onChange={setModelAndResetError}
                disabled={vehicleDisabled}
                formatter={vrcModuleIsUsingKbaNumbers ? kba : undefined}
                modelState={modelState}
                forceShowErrorBorder={showNoResultError}
            />
            <TextField
                floatingLabel
                label={translateText(101)}
                model={vrcLookup}
                path={["info", "vin"]}
                onChange={setModelAndResetError}
                disabled={vehicleDisabled}
                formatter={formatVin}
                maxLength={VIN_LENGTH}
                showLength
                modelState={modelState}
                forceShowErrorBorder={showNoResultError}
            />
            <TextField
                floatingLabel
                label={translateText(21)}
                model={vrcLookup}
                path={["info", "numberPlate"]}
                onChange={setModelAndResetError}
                disabled={vehicleDisabled}
                modelState={modelState}
                forceShowErrorBorder={showNoResultError}
            />

            {appendix}
        </div>
    )
}
