import { Tooltip, Button, Icon, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { memo } from "react"

type Props = {
    onReset(): void
    onSave(): void
    saveDisabled?: boolean
    resetDisabled?: boolean
}

export const SaveActions = memo(({ onReset, onSave, saveDisabled, resetDisabled }: Props) => {
    const { translateText } = useLocalization()

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Tooltip title={translateText(48).toLowerCase()}>
                <Button onClick={onReset} disabled={resetDisabled} startIcon={<Icon name="synchronize" />} />
            </Tooltip>
            <Tooltip title={translateText(9).toLowerCase()}>
                <Button onClick={onSave} disabled={saveDisabled} color="success" startIcon={<Icon name="check" />} />
            </Tooltip>
        </Stack>
    )
})
