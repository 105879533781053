import { Article } from "@tm/models"
import { useGetDetailedErpInfo } from "@tm/utils"

export function useErpInfoMulti(
    articles: Article[],
    type: "list" | "details" | undefined,
    distributorId?: number,
    telesalesCustomerNo?: string,
    vehicleId?: string,
    searchQuery?: string
) {
    const { isLoading, data: erpInfos = [] } = useGetDetailedErpInfo({
        articles,
        type,
        distributorId,
        telesalesCustomerNo,
        vehicleId,
        searchQuery,
    })

    return { erpInfos, erpInfoLoaded: !isLoading }
}
