import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { AdditionalVehicleInfo } from "../../model"

export function loadAdditionalVehicleInfos(carparkId: number) {
    const url = `${getServiceUrl()}/GetVehicleInfos`
    const authorization = getStoredAuthorization()
    const body = { carparkId }

    return new Promise<AdditionalVehicleInfo[]>((resolve, reject) =>
        ajax({ url, body, authorization, method: "GET" }).then(
            response => { resolve(response.vehicleInfos) },
            reject
        )
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.vehicleServiceUrl
}