import { Box, Icon, IconButton, Image, styled } from "@tm/components"
import { useEffect, useMemo, useState } from "react"

const StyledDeleteButton = styled(IconButton)({
    position: "absolute",
    top: "5px",
    right: "5px",
})

export const StyledUploadPreview = styled(Box)(({ theme }) => ({
    padding: "2em",
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.grey[200],
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "3px",
    transition: "0.2s",
    alignItems: "center",
}))

type Props = {
    file: File | string
    onFileDelete?: () => void
}

export function UploadPreview({ file, onFileDelete }: Props) {
    const [preview, setPreview] = useState<string>("")

    const isPdf = useMemo(() => {
        if (!file) {
            return false
        }

        if (file instanceof File) {
            return file.type === "application/pdf"
        }

        return file.toLowerCase().endsWith(".pdf")
    }, [file])

    useEffect(() => {
        if (file instanceof File) {
            const previewUrl = URL.createObjectURL(file)
            setPreview(previewUrl)

            return () => {
                URL.revokeObjectURL(previewUrl)
            }
        }
        setPreview(file)
    }, [file, setPreview])

    function deleteImage() {
        onFileDelete?.()
    }

    return (
        <Box display="flex" flex="1" alignItems="center" justifyContent="center">
            <StyledUploadPreview height="300px" position="relative">
                {isPdf ? (
                    <Box width="300px" height="200px" flex="1" alignSelf="center" display="flex" overflow="hidden">
                        <iframe style={{ overflow: "hidden !important" }} id="vefiles-upload-pdf-preview" src={preview} />
                    </Box>
                ) : (
                    <Image src={preview} type="car" style={{ maxWidth: "100%", maxHeight: "100%" }} />
                )}
                <StyledDeleteButton size="small" onClick={deleteImage}>
                    <Icon name="cancel-x" />
                </StyledDeleteButton>
            </StyledUploadPreview>
        </Box>
    )
}
