import { ErpInformation, Warehouse, TeccomErpInformation, Advertisement, GetAdvertisementResponse } from "@tm/models"
import { mapDispatchType, mapPrices, mapQuantity, mapTour, uniqueId } from "@tm/utils"

export function mapWarehouse(data: any): Warehouse | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        quantities: data.quantities && data.quantities.map(mapQuantity),
        tour: mapTour(data.tour),
        dayExpressDelivery: data.dayExpressDelivery && data.dayExpressDelivery.map(mapDispatchType),
        nightExpressDelivery: data.nightExpressDelivery && data.nightExpressDelivery.map(mapDispatchType),
        standardDelivery: data.standardDelivery && data.standardDelivery.map(mapDispatchType),
        collection: data.collection && data.collection.map(mapDispatchType),
        prices: mapPrices(data.prices) || [], // map the price dictionary into an array and filter empty items
    }
}

export function mapErpInformation(data: any): ErpInformation | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        prices: mapPrices(data.prices) || [], // map the price dictionary into an array and filter empty items
        quantity: mapQuantity(data.quantity),
        tour: mapTour(data.tour),
        warehouses: data.warehouses && data.warehouses.map(mapWarehouse),
        additionalDeliveryTours: data.additionalDeliveryTours && data.additionalDeliveryTours.map(mapTour),
        additionalPickupTours: data.additionalPickupTours && data.additionalPickupTours.map(mapTour),
        teccomResponse: data.teccomResponse && mapErpInformation(data.teccomResponse),
        articles: data.articles && data.articles.map(mapErpInformation),
    }
}

export function mapTeccomErpInformation(data: any): TeccomErpInformation | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        prices: mapPrices(data.prices) || [], // map the price dictionary into an array and filter empty items
        warehouse: mapWarehouse(data.warehouse),
    }
}

function mapAdvertisement(data: any): Advertisement | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        itemId: uniqueId(),
        validFrom: data.validFrom ? new Date(data.validFrom) : undefined,
        validTo: data.validTo ? new Date(data.validTo) : undefined,
    }
}

export function mapGetAdvertisementResponse(data: any): GetAdvertisementResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        advertisements: data.advertisements && data.advertisements.map(mapAdvertisement),
    }
}
