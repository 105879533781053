import { memo } from "react"
import { Text, Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Memo, MemoType } from "@tm/models"
import { Tooltip } from "@tm/components"
import { getBundleParams } from "../../utils"
import { MemoTooltipIconTable } from "./table"

type Props = {
    memos?: Array<Memo>
}

const MemoTooltipIcon = memo((props: Props) => {
    const { translateText } = useLocalization()

    const iconName = getBundleParams().memoTooltipIcon
    if (!iconName) {
        return null
    }

    const memos = props.memos?.filter((m) => m.type === MemoType.Hyperlink || m.type === MemoType.AdditionalDescription)
    if (!memos?.length) {
        return null
    }

    return (
        <div className="memo-tooltip-icon">
            <Tooltip
                variant="light"
                noWidthLimit
                title={
                    <div className="memo-tooltip-icon__tooltip">
                        <Text>{translateText(180)}</Text>
                        <MemoTooltipIconTable memos={memos} />
                    </div>
                }
                color="primary"
            >
                <Icon name={iconName} skin="highlight" />
            </Tooltip>
        </div>
    )
})

export default MemoTooltipIcon
