import { MenuItem, SelectTextField, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ChangeEvent, ForwardedRef, forwardRef, memo } from "react"
import { ArticleListSortingConfiguration } from "../ListV2/ArticleListConfiguration"

type SortingModes = ArticleListSortingConfiguration["availableModes"]
type SortingMode = keyof SortingModes

const SelectField = styled(SelectTextField<SortingMode>)({
    marginTop: 0,
    ".MuiSelect-select": {
        lineHeight: "18px",
    },
})

type Props = {
    sortingModes: SortingModes
    value: SortingMode
    onChange(value: SortingMode): void
    disabled?: boolean
    /** When this is set to `true` if `"default"` sorting mode is selected the dropdown will display "Default" instead of "Sorting" label. */
    displayDefaultText?: boolean
}

export const SortSelection = memo(
    forwardRef(({ sortingModes, value, onChange, disabled, displayDefaultText, ...rest }: Props, ref: ForwardedRef<HTMLDivElement>) => {
        const { translateText } = useLocalization()

        function handleChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
            onChange(event.target.value as SortingMode)
        }

        function renderValue(mode: SortingMode, text: string | number) {
            if (mode === "default") {
                return <Typography sx={{ fontStyle: "italic" }}>{translateText(text)}</Typography>
            }

            return translateText(text)
        }

        return (
            <SelectField
                // ...rest and ref are required here to make this component work as a child of the MUI Tooltip
                {...rest}
                ref={ref}
                select
                margin="none"
                value={value}
                onChange={handleChange}
                disabled={!!disabled}
                size="small"
                SelectProps={{
                    renderValue: (selected) => {
                        if (disabled || (selected === "default" && !displayDefaultText)) {
                            return translateText(3155)
                        }

                        const item = sortingModes[selected]

                        if (item) {
                            return translateText(item)
                        }
                    },
                }}
            >
                {Object.entries(sortingModes).map(([item, text]) => (
                    <MenuItem key={item} value={item}>
                        {renderValue(item as SortingMode, text)}
                    </MenuItem>
                ))}
            </SelectField>
        )
    })
)
