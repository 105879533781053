export type HaynesProBaseModel = {
    moduleMode: HaynesProModuleMode
    platformId: string
    languageId: string
    mdmSessionId: string | undefined
    h_session: string
    catNr: string
    clientId: string
    kTypeId: number
    typeArt: string
}

export type HaynesProResetModel =
    | HaynesProBaseModel
    | {
          engineCode: string
      }

export type HaynesProSmartlinkModel =
    | HaynesProBaseModel
    | {
          operation: string
          carTypeGroup: string
          carTypeId: number
          additionalTitle: string
      }

export enum HaynesProModuleMode {
    SmartLinks = "smartLinks",
    ServiceReset = "servicereset",
}
