import { FastCalculator } from "@tm/data"
import { MaintenanceIntervalContext, MaintenanceSystemContext, ModalCalcState, VehicleTypeContext } from "../model"

export function getCalcSelection(calcState: FastCalculator.CalcState) {
    switch (calcState?.type) {
        case FastCalculator.ECalcState.MaintenanceSystem:
            return (calcState?.context as MaintenanceSystemContext).maintenanceSystem

        case FastCalculator.ECalcState.Interval:
            return (calcState?.context as MaintenanceIntervalContext).interval

        case FastCalculator.ECalcState.VehicleType:
            return (calcState?.context as VehicleTypeContext).vehicleType

        default:
            return undefined
    }
}

export function getWorks(calcState: FastCalculator.CalcState) {
    switch (calcState?.type) {
        case FastCalculator.ECalcState.AdditionalWork:
            return (calcState as ModalCalcState)?.context?.additionalWork

        case FastCalculator.ECalcState.FollowupWork:
            return (calcState as ModalCalcState)?.context?.followupWork

        default:
            return undefined
    }
}
