import { useLocalization } from "@tm/localization"
import { withRouter, uniqueId, encodeUniqueId, renderRoute, useMultiWidgetState, ModulePropsMultiWidget, RouteComponentProps } from "@tm/utils"
import { Widget, Button } from "@tm/controls"
import { useWorkTask } from "@tm/context-distribution"

type Props = RouteComponentProps<void> & {
    searchRoute: string
    showComponentKey?: string
    moduleProps?: ModulePropsMultiWidget
}

const width = 205
const height = 55
const diameter = 16
const now = new Date()
const season = now.getMonth() > 8 || now.getMonth() < 3 ? "WIN" : "SOM"

function TyresWidgetComponent(props: Props) {
    const { searchRoute, showComponentKey } = props
    const { translate } = useLocalization()
    const { workTask } = useWorkTask() || {}
    const [selectedMultiWidgetTab] = useMultiWidgetState(props.moduleProps)

    if (showComponentKey && selectedMultiWidgetTab !== showComponentKey) {
        return null
    }

    const params = {
        width,
        height,
        diameter,
        season,
        workTaskId: encodeUniqueId(workTask ? workTask.id : uniqueId()),
    }
    const url = renderRoute(searchRoute, params)

    return (
        <Widget className="bnd-wm tyres-widget" active>
            <div className="button-container">
                <Button size="l" linkTo={url}>
                    {translate(1112)}
                </Button>
            </div>
        </Widget>
    )
}

export default withRouter(TyresWidgetComponent)
