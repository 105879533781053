import { Stack } from "@tm/components"
import { AiSearch } from "../_shared/ai-search/AiSearch"

export function AiWidget() {
    return (
        <Stack alignItems="center" spacing={1} overflow="hidden" width="100%" paddingBottom="50px">
            <AiSearch searchFieldWidth="425px" />
        </Stack>
    )
}
