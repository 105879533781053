import { useLocalization } from "@tm/localization"
import { TableCellData, Icon, TableVariants, LinkButton, CellContentPosition, TableColumnData } from "@tm/components"
import { createQueryString, renderRoute } from "@tm/utils"
import { useParams } from "react-router"
import { getBundleParams } from "../../../utils"
import { NoResultHint } from "../NoResultHint"
import { Voucher } from "../../../data/model"
import { StyledTable } from "../StyledComponents"
import DateCell from "../cells/DateCell"
import { VoucherRouteProps } from "../../vouchers/component"

type Props = {
    vouchers: Array<Voucher>
    singleSearch?: boolean
    variant: TableVariants
    onLoadNextPage?(): void
}

export default function ReturnsTableComponent({ variant, vouchers, singleSearch, onLoadNextPage }: Props) {
    const { translateText } = useLocalization()
    const { cisVoucherUrl } = getBundleParams()
    const matchParams = useParams<VoucherRouteProps>()
    const selectedVoucherId = matchParams.id ? decodeURIComponent(matchParams.id) : undefined
    const compact = !!matchParams.id

    function renderDetailsButton(voucher: Voucher) {
        const id = voucher.orderNumber
        const voucherTypeId = voucher.voucherType.typeId
        let url = renderRoute(cisVoucherUrl, {
            ...matchParams,
            subpage: "vouchers",
            voucherTypeId,
            id: encodeURI(id),
        })
        if (singleSearch) {
            url += createQueryString({ singleSearch: true })
        }
        if (selectedVoucherId && selectedVoucherId === id) {
            url = renderRoute(cisVoucherUrl, { ...matchParams, voucherTypeId, id: null, subId: null })
        }
        return (
            <LinkButton to={url} title={translateText(597)} sx={{ minWidth: "15px", marginLeft: "4px" }} startIcon={<Icon name="file-details" />} />
        )
    }

    function getModuleColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(1089) },
            { header: translateText(98) },
            { header: translateText(335) },
            { header: translateText(700) },
            { header: translateText(479), alignContent: CellContentPosition.center },
            {},
        ]
        return columns
    }

    function getCompactColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(1089) },
            { header: translateText(98) },
            { header: translateText(700) },
            { header: translateText(479), alignContent: CellContentPosition.center },
            {},
        ]
        return columns
    }

    function getColumns() {
        if (compact) {
            return getCompactColumns()
        }

        return getModuleColumns()
    }

    function getModuleCellData(voucher: Voucher) {
        const cellData: TableCellData[] = [
            { displayValue: voucher.warehouseId, id: "voucher_0" },
            { displayValue: <DateCell value={voucher.voucherDate} mode="onlyDate" />, id: "voucher_1" },
            { displayValue: <DateCell value={voucher.voucherDate} mode="onlyTime" />, id: "voucher_2" },
            { displayValue: voucher.orderNumber, id: "voucher_3" },
            { displayValue: voucher.itemsCount, id: "voucher_4" },
            { displayValue: renderDetailsButton(voucher), id: "voucher_5" },
        ]
        return cellData
    }

    function getCompactCellData(voucher: Voucher) {
        const cellData: TableCellData[] = [
            { displayValue: voucher.warehouseId, id: "voucher_0" },
            { displayValue: <DateCell value={voucher.voucherDate} mode="onlyDate" />, id: "voucher_1" },
            { displayValue: voucher.orderNumber, id: "voucher_3" },
            { displayValue: voucher.itemsCount, id: "voucher_4" },
            { displayValue: renderDetailsButton(voucher), id: "voucher_5" },
        ]
        return cellData
    }

    function getCells(voucher: Voucher) {
        let cells: TableCellData[] = []
        if (compact) {
            cells = getCompactCellData(voucher)
        } else {
            cells = getModuleCellData(voucher)
        }

        return cells
    }

    const displayData = vouchers?.map((voucher, index) => ({
        cells: getCells(voucher),
        id: `${index}`,
        customRow: false,
        active: selectedVoucherId === voucher.orderNumber,
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return (
        <StyledTable
            columns={getColumns()}
            rows={displayData}
            variant={variant}
            headerStyle={variant === "small" && !compact ? "bold" : "default"}
            onScrollBottom={onLoadNextPage}
            headerBackground={variant === "small" && !compact ? "default" : "paper"}
        />
    )
}
