import { MouseEventHandler, useState } from "react"
import { Box, Image, Stack, Typography, styled } from "@tm/components"

type Props = {
    displayText: string
    onClick: MouseEventHandler<HTMLDivElement>
    imgSrc: string
    hoverText?: string
}

const TileWrapper = styled(Box)({
    margin: "5px",
    boxShadow: "#ccc 0 0 1px 1px",
    backgroundColor: "white",
    display: "inline-block",
    lineHeight: "8px",
    cursor: "pointer",
    position: "relative",
    minWidth: "0px",
})

const Tile = styled(Box)({
    backgroundColor: "white",
    minWidth: "150px",
    minHeight: "40px",
    display: "inline-block",
    position: "relative",
    maxHeight: "300px",
})

const DisplayText = styled(Typography)({
    position: "absolute",
    bottom: "15px",
    textAlign: "center",
    width: "100%",
    color: "white",
    backgroundColor: "rgba(51,51,51,.5)",
    lineHeight: "3em",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textTransform: "uppercase",
})

const HoverTextWrapper = styled(Stack)({
    position: "absolute",
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(51,51,51,.9)",
    color: "white",
    top: 0,
    left: 0,
    padding: "1%",
    overflowY: "auto",
    opacity: "0",
})

const HoverText = styled(Typography)({
    color: "white",
    padding: "2%",
    overflowY: "hidden",
    overflow: "auto",
    lineHeight: "1.2em",
})

export default function ProductAreaTileComponent({ displayText, onClick, imgSrc, hoverText }: Props) {
    const [descriptionActive, setDescriptionActive] = useState<boolean>(false)

    function getDescriptionAnimation() {
        if (descriptionActive) {
            return { animation: "fadeIn .5s", opacity: 1 }
        }

        return { animation: "fadeOut .5s", opacity: 0 }
    }

    function fadeInDescription() {
        if (hoverText) {
            setDescriptionActive(true)
        }
    }

    function fadeOutDescription() {
        if (hoverText) {
            setDescriptionActive(false)
        }
    }

    return (
        <TileWrapper onMouseOver={fadeInDescription} onFocus={fadeInDescription} onMouseOut={fadeOutDescription} onBlur={fadeOutDescription}>
            <Tile onClick={onClick}>
                <Image src={imgSrc} height="170px" width="300px" />
                <DisplayText variant="body2">{displayText}</DisplayText>

                <HoverTextWrapper sx={getDescriptionAnimation()}>
                    <HoverText variant="body3">{hoverText}</HoverText>
                </HoverTextWrapper>
            </Tile>
        </TileWrapper>
    )
}
