import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { CisVoucherType } from "@tm/models"
import { TableCellData, Loader, Icon, LinkButton, CellContentPosition, TableColumnData } from "@tm/components"
import { useParams } from "react-router"
import { Voucher } from "../../../data/model"
import { useConnectedVouchers } from "../../../data/hooks/useConnectedVouchers"
import { getBundleParams } from "../../../utils"
import { NoResultHint } from "../NoResultHint"
import { StyledTable } from "../StyledComponents"
import DateCell from "../cells/DateCell"
import { VoucherRouteProps } from "../../vouchers/component"

type Props = {
    parentVoucherId: string
    showPaymentInfo?: boolean
    showTotal?: boolean
    vouchersParentTypeId: number
}

export default function ConnectedVouchersTableComponent({ parentVoucherId, vouchersParentTypeId, showPaymentInfo, showTotal }: Props) {
    const { translateText, currency } = useLocalization()
    const { connectedVouchers, connectedVouchersLoading } = useConnectedVouchers(parentVoucherId, vouchersParentTypeId)
    const matchParams = useParams<VoucherRouteProps>()

    const { cisVoucherUrl, invoiceDetailsRoute } = getBundleParams()

    function renderDetailsButton(voucher: Voucher) {
        let url
        const voucherType = voucher.voucherType.typeId
        // TODO: check if instead of passing CisVoucherType.Offers the current voucherTypeId could be used
        if (voucherType === CisVoucherType.Offers) {
            url = renderRoute(invoiceDetailsRoute, { ...matchParams, id: voucher.voucherId })
        } else {
            url = renderRoute(cisVoucherUrl, { ...matchParams, voucherTypeId: voucherType, id: voucher.voucherId })
        }

        return <LinkButton disabled={!(voucher.itemsCount > 0)} startIcon={<Icon name="linked-item" />} title={translateText(597)} to={url} />
    }

    function getColumns() {
        const columns: TableColumnData[] = [{ header: translateText(470) }, { header: translateText(471) }, { header: translateText(74) }]
        if (showPaymentInfo) {
            columns.push({ header: translateText(501) })
        }

        columns.push({ header: translateText(40) }, { header: translateText(178) })

        if (showTotal) {
            columns.push({ header: translateText(472), alignContent: CellContentPosition.right })
        }
        columns.push({ alignContent: CellContentPosition.right })

        return columns
    }

    function getCells(voucher: Voucher) {
        const cellData: TableCellData[] = [
            { displayValue: voucher.voucherId, id: "order_0" },
            { displayValue: <DateCell value={voucher.voucherDate} mode="onlyDate" />, id: "order_1" },
            { displayValue: voucher.voucherType.description, id: "order_2" },
        ]
        if (showPaymentInfo) {
            cellData.push({ displayValue: voucher.paymentInformation, id: "order_3" })
        }
        cellData.push({ displayValue: voucher.deliveryType, id: "order_4" }, { displayValue: voucher.information, id: "order_5" })
        if (showTotal) {
            cellData.push({ displayValue: currency(voucher.totalGross || 0, voucher.currencyCode), id: "item_8" })
        }
        cellData.push({ displayValue: renderDetailsButton(voucher), id: "order_9" })
        return cellData
    }

    const displayData = connectedVouchers?.map((voucher, index) => ({
        cells: getCells(voucher),
        id: `${index}`,
        customRow: false,
    }))

    if (connectedVouchersLoading) {
        return <Loader />
    }

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} />
}
