import { BundleActions, BundleActionTypes } from "../../../data/business"
import { DecodedMatchParams, NavigationSteps } from "../../../data/models"
import { MainActionsType } from "../../main/business"
import { NavigationState } from "./model"

export * from "./model"

export type ComponentActionType = BundleActionTypes
    | { type: "INIT", payload: DecodedMatchParams }


const DEFAULT_STATE: NavigationState = {
    params: {}
}

export function reduce(state = DEFAULT_STATE, action: MainActionsType): NavigationState {
    switch (action.type) {
        case "CHANGE_STEP": {
            const { params, selectedStep } = action.payload
            return {
                ...state,
                active: selectedStep,

                params: {
                    ...state.params,
                    ...params
                }

            }
        }
        case "INIT": {
            const { view, ...rest } = action.payload
            return {
                ...state,
                initialized: true,
                active: view,
                params: {
                    ...DEFAULT_STATE.params,
                    ...rest

                }
            }
        }
    }
    return state
}


const initNav = (matchParams: DecodedMatchParams): ComponentActionType =>
    ({ type: "INIT", payload: matchParams })

const changeStep = (selectedStep: NavigationSteps, params?: any): ComponentActionType =>
    ({ type: "CHANGE_STEP", payload: { selectedStep, params } })

export const Actions = {
    ...BundleActions,
    initNav,
    changeStep
}