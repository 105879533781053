import { RequestArticleListPayload, Vehicle } from "@tm/models"
import { DictionaryItemPair } from "../../_shared/Dictionary"

type DetailsState = Record<string, never>

export type NavigationItem = {
    path: string
    /** Has to be `true`, when `path` isn't only a subpage of the vehicle details but another route (e.g. route to basket) */
    isAbsolutePath?: boolean
    text: string
    sort: number
    icon?: string
    disabled?: boolean
}

export type ComponentActionType =
    | { type: "LOAD_ARTICLE_LIST"; payload: RequestArticleListPayload; targetComponentId: string }
    | { type: "HAS_DAT_INFO"; payload: boolean }
    | { type: "SET_PRINT_VEHICLE"; payload: Vehicle }
    | { type: "SET_MODEL_IMAGE"; payload: string }
    | { type: "HAS_ORDERED_PARTS"; payload: boolean }
    | { type: "GET_PRINTABLE_ITEMS"; payload: boolean }
    | { type: "SET_MANUFACTURER_DATA"; payload: Array<DictionaryItemPair> }

const DEFAULT_STATE: DetailsState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): DetailsState {
    return state
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "LOAD_ARTICLE_LIST": {
            return action
        }

        case "HAS_DAT_INFO": {
            return action
        }

        case "HAS_ORDERED_PARTS": {
            return action
        }

        case "SET_PRINT_VEHICLE": {
            return action
        }

        case "SET_MANUFACTURER_DATA": {
            return action
        }

        case "SET_MODEL_IMAGE": {
            return action
        }

        default:
            break
    }
}
