import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenOffersModule(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { history } = this.props
    const { openOffersModule } = data

    if (openOffersModule) {
        const workTaskId = this.getCurrentOrNewWorkTaskId()
        history.push(`/${workTaskId}/offers`)
    }
}
