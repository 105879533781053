import { Checkbox, DateField, Radio, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { getValue, initSelector } from "@tm/utils"
import * as React from "react"
import { useSelector } from "react-redux"
import { Typography, makeStyles } from "@tm/components"
import { Actions } from "../../bussiness"
import { dateToString, hasFieldError, isFieldAvailable, stringToDate } from "../../bussiness/helpers"
import { DocumentsState, SiteOfUses } from "../../bussiness/model"
import { fieldsSelector, modelStateSelector } from "../../bussiness/selectors"
import { Field } from "../common"

type Props = {}
const selector = initSelector(fieldsSelector)

const BatterieTab: React.FC<Props> = () => {
    const { translateText } = useLocalization()
    const actions = useActions(Actions, "updateField")
    const style = useStyle()

    const [model] = useSelector(selector)
    const modelState = useSelector((s: DocumentsState) => modelStateSelector(s, translateText))
    if (!model) {
        return null
    }

    const handleCheckboxChange = (path: Array<string>, value: any, defaultValue?: any) => {
        if (getValue(model, path) == value) {
            value = defaultValue
        }

        actions.updateField(path, value)
    }

    const handleDateFieldChange = (path: Array<string>, date: Date) => {
        actions.updateField(path, dateToString(date))
    }

    return (
        <>
            <Field.Container>
                <Field.Item m={6} title={translateText(1415)}>
                    <Checkbox
                        label={translateText(1442)}
                        checked={model.batteryInfo.consumers.airConditioning == "1"}
                        onToggle={handleCheckboxChange.bind(this, ["batteryInfo", "consumers", "airConditioning"], "1", "")}
                    />
                    <Checkbox
                        label={translateText(1443)}
                        checked={model.batteryInfo.consumers.seatHeating == "1"}
                        onToggle={handleCheckboxChange.bind(this, ["batteryInfo", "consumers", "seatHeating"], "1", "")}
                    />
                    <Checkbox
                        label={translateText(1444)}
                        checked={model.batteryInfo.consumers.auxiliaryHeating == "1"}
                        onToggle={handleCheckboxChange.bind(this, ["batteryInfo", "consumers", "auxiliaryHeating"], "1", "")}
                    />
                    <Checkbox
                        label={translateText(1376)}
                        checked={model.batteryInfo.consumers.other == "1"}
                        onToggle={handleCheckboxChange.bind(this, ["batteryInfo", "consumers", "other"], "1", "")}
                    />

                    {model.batteryInfo.consumers.other == "1" && (
                        <TextField
                            multiline
                            showClear
                            value={model.batteryInfo.consumers.otherConsumerDescription}
                            onChange={actions.updateField.bind(this, ["batteryInfo", "consumers", "otherConsumerDescription"])}
                        />
                    )}
                </Field.Item>
                <Field.Item m={6} title={translateText(1416)}>
                    <Radio
                        label={translateText(585)}
                        size="s"
                        checked={model.batteryInfo.hasStartStopTech == 1}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "hasStartStopTech"], 1, undefined)}
                    />
                    <Radio
                        label={translateText(584)}
                        size="s"
                        checked={model.batteryInfo.hasStartStopTech == 0}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "hasStartStopTech"], 0, undefined)}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={6} title={translateText(1417)} mandatory>
                    <TextField
                        showClear
                        value={model.batteryInfo.deliveryNoteInformation.number}
                        className={hasFieldError(modelState["batteryInfo.deliveryNoteInformation.number"])}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "deliveryNoteInformation", "number"])}
                    />
                </Field.Item>
                <Field.Item m={6} title={translateText(1418)} mandatory>
                    <DateField
                        className={hasFieldError(modelState["batteryInfo.deliveryNoteInformation.date"])}
                        value={stringToDate(model.batteryInfo.deliveryNoteInformation.date)}
                        onChange={handleDateFieldChange.bind(this, ["batteryInfo", "deliveryNoteInformation", "date"])}
                    />
                </Field.Item>
            </Field.Container>
            <Field.Container>
                <Field.Item m={3} title={translateText(1419)} mandatory>
                    <Radio
                        size="s"
                        label={translateText(1234)}
                        checked={model.batteryInfo.siteOfUse == SiteOfUses.PassengerCar}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "siteOfUse"], SiteOfUses.PassengerCar, 0)}
                    />
                    <Radio
                        label={translateText(1446)}
                        size="s"
                        checked={model.batteryInfo.siteOfUse == SiteOfUses.LightCommercialVehicle}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "siteOfUse"], SiteOfUses.LightCommercialVehicle, 0)}
                    />
                    <Radio
                        label={translateText(1447)}
                        size="s"
                        checked={model.batteryInfo.siteOfUse == SiteOfUses.Motorcycle}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "siteOfUse"], SiteOfUses.Motorcycle, 0)}
                    />
                    <Radio
                        label={translateText(1448)}
                        size="s"
                        checked={model.batteryInfo.siteOfUse == SiteOfUses.Caravan}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "siteOfUse"], SiteOfUses.Caravan, 0)}
                    />
                    <Radio
                        label={translateText(1376)}
                        size="s"
                        checked={model.batteryInfo.siteOfUse == SiteOfUses.Universal}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "siteOfUse"], SiteOfUses.Universal, 0)}
                    />
                    {model.batteryInfo.siteOfUse == SiteOfUses.Universal && (
                        <TextField
                            multiline
                            showClear
                            value={model.batteryInfo.otherSiteOfUseDescription}
                            onChange={actions.updateField.bind(this, ["batteryInfo", "otherSiteOfUseDescription"])}
                        />
                    )}
                </Field.Item>
                <Field.Item m={3} title={translateText(1420)} mandatory>
                    <Checkbox
                        label={translateText(671)}
                        checked={model.batteryInfo.purposeOfUseStart == 1}
                        onToggle={handleCheckboxChange.bind(this, ["batteryInfo", "purposeOfUseStart"], 1, 0)}
                    />
                    <Checkbox
                        label={translateText(1449)}
                        checked={model.batteryInfo.purposeOfUseSupplyDrive == 1}
                        onToggle={handleCheckboxChange.bind(this, ["batteryInfo", "purposeOfUseSupplyDrive"], 1, 0)}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1421)} mandatory>
                    <Radio
                        size="s"
                        label={translateText(1450)}
                        checked={model.batteryInfo.durationOfDistance == 1}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "durationOfDistance"], 1, 0)}
                    />

                    <Radio
                        size="s"
                        label={translateText(1451)}
                        checked={model.batteryInfo.durationOfDistance == 2}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "durationOfDistance"], 2, 0)}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1422)} mandatory>
                    <Radio
                        size="s"
                        label={translateText(1452)}
                        checked={model.batteryInfo.batteryPlacement == 1}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "batteryPlacement"], 1, 0)}
                    />
                    <Radio
                        size="s"
                        label={translateText(1453)}
                        checked={model.batteryInfo.batteryPlacement == 2}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "batteryPlacement"], 2, 0)}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={4} title={translateText(1423)}>
                    <DateField
                        value={stringToDate(model.batteryInfo.generalMeasurement.dateOfMeasurement)}
                        onChange={handleDateFieldChange.bind(this, ["batteryInfo", "generalMeasurement", "dateOfMeasurement"])}
                    />
                </Field.Item>
                <Field.Item m={4} title={translateText(1425)}>
                    <TextField
                        showClear
                        type="number"
                        value={model.batteryInfo.generalMeasurement.measuredValueInEN}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "generalMeasurement", "measuredValueInEN"])}
                    />
                </Field.Item>
                <Field.Item m={4} title={translateText(1424)}>
                    <TextField
                        showClear
                        type="number"
                        value={model.batteryInfo.generalMeasurement.measuredValueInVolt}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "generalMeasurement", "measuredValueInVolt"])}
                    />
                </Field.Item>
            </Field.Container>

            <Typography className={style.header}>{translateText(13472)}</Typography>
            <Field.Container>
                <Field.Item m={3} title={translateText(1426)} mandatory>
                    <TextField
                        value={model.batteryInfo.batteryDetectedVoltage}
                        className={hasFieldError(modelState["batteryInfo.batteryDetectedVoltage"])}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "batteryDetectedVoltage"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1427)} mandatory>
                    <TextField
                        value={model.batteryInfo.batteryDetectedTime}
                        className={hasFieldError(modelState["batteryInfo.batteryDetectedTime"])}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "batteryDetectedTime"])}
                    />
                </Field.Item>
            </Field.Container>

            <Typography className={style.header}>{translateText(13473)}</Typography>
            <Field.Container>
                <Field.Item m={3} title={translateText(1428)} mandatory>
                    <TextField
                        className={hasFieldError(modelState["batteryInfo.generatorDetectedVoltageWithLoad"])}
                        value={model.batteryInfo.generatorDetectedVoltageWithLoad}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "generatorDetectedVoltageWithLoad"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1429)} mandatory>
                    <TextField
                        formatter={(value) => value || ""}
                        value={model.batteryInfo.generatorDetectedVoltageWithoutLoad}
                        className={hasFieldError(modelState["batteryInfo.generatorDetectedVoltageWithoutLoad"])}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "generatorDetectedVoltageWithoutLoad"])}
                    />
                </Field.Item>
            </Field.Container>

            <Field.Container>
                <Field.Item m={3} title={translateText(1474)}>
                    <TextField
                        showClear
                        value={model.batteryInfo.claimDescription}
                        onChange={actions.updateField.bind(this, ["batteryInfo", "claimDescription"])}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1391)}>
                    <Checkbox
                        label={translateText(12429)}
                        checked={model.batteryInfo.enableDeinstallationCost}
                        onToggle={handleCheckboxChange.bind(this, ["batteryInfo", "enableDeinstallationCost"], true, false)}
                    />
                </Field.Item>

                {model.batteryInfo.enableDeinstallationCost && isFieldAvailable("batteryInfo.deinstallationCost") && (
                    <Field.Item m={6} title={translateText(12427)}>
                        <TextField
                            showClear
                            value={model.batteryInfo.deinstallationCost}
                            onChange={actions.updateField.bind(this, ["batteryInfo", "deinstallationCost"])}
                        />
                    </Field.Item>
                )}

                <Field.Item m={3} title={translateText(1431)} mandatory>
                    <Radio
                        size="s"
                        label={translateText(1378)}
                        checked={model.batteryInfo.completionAtAcknowledgement == 1}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "completionAtAcknowledgement"], 1, 0)}
                    />
                    <Radio
                        size="s"
                        label={translateText(1381)}
                        checked={model.batteryInfo.completionAtAcknowledgement == 3}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "completionAtAcknowledgement"], 3, 0)}
                    />
                </Field.Item>
                <Field.Item m={3} title={translateText(1430)} mandatory>
                    <Radio
                        size="s"
                        label={translateText(1457)}
                        checked={model.batteryInfo.completionAtRejection == 6}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "completionAtRejection"], 6, 0)}
                    />
                    <Radio
                        size="s"
                        label={translateText(1536)}
                        checked={model.batteryInfo.completionAtRejection == 5}
                        onCheck={handleCheckboxChange.bind(this, ["batteryInfo", "completionAtRejection"], 5, 0)}
                    />
                </Field.Item>
            </Field.Container>
        </>
    )
}

const useStyle = makeStyles({
    header: {
        textTransform: "capitalize",
        fontSize: "1.2em",
        paddingLeft: "8px",
        fontWeight: "600",
        opacity: "1",
    },
})

export default BatterieTab
