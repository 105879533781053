import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function awDocFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const awDocModule = getModuleFromUserContext(userContext, UserModuleType.AWDataAWDoc)

    if (!awDocModule) {
        return undefined
    }

    return {
        repairtimes: {
            items: {
                awdata: {
                    sort: 0,
                    name: "AwDoc",
                    active: true,
                    route: "/:workTaskId/repair-times/awdoc/select",
                    disabledFunction: "REPAIRTIMES-AWDOC-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        repairTimes: "awdoc",
                    },
                },
            },
        },
    }
}
