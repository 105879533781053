import { useCallback } from "react"

const SCROLL_DELAY = 150

export const useScrollIntoView = (): { scrollWithDelay(anchor: Element, block?: ScrollLogicalPosition): void } => {
    const scrollWithDelay = useCallback((anchor: Element, block: ScrollLogicalPosition = "start"): void => {
        setTimeout(() => {
            try {
                anchor.scrollIntoView({
                    block,
                    behavior: "smooth",
                })
            } catch (e) {
                anchor.scrollIntoView(false)
            }
        }, SCROLL_DELAY)
    }, [])

    return { scrollWithDelay }
}
