import { useLocalization } from "@tm/localization"
import { Icon } from "@tm/controls"
import { TelematicsDataSummaryResponse } from "../../../data"

type Props = {
    summary: TelematicsDataSummaryResponse
}

export default function Slide3Component(props: Props) {
    const { date, language } = useLocalization()
    const { legalInspectionDate, nextServiceDate, nextServiceDistance } = props.summary

    return (
        <div className="slider__content slide-1">
            <div className="def-list">
                {legalInspectionDate && (
                    <div className="def-list__item">
                        <Icon name="service" size="xl" />
                        <div className="def-list__term">{legalInspectionDate.labelTextMap[language.toLowerCase()]}</div>
                        <div className="def-list__text">{date(legalInspectionDate.value, "d")}</div>
                    </div>
                )}
                {nextServiceDate && (
                    <div className="def-list__item">
                        <Icon name="inspection-date" size="xl" />
                        <div className="def-list__term">{nextServiceDate.labelTextMap[language.toLowerCase()]}</div>
                        <div className="def-list__text">{date(nextServiceDate.value, "d")}</div>
                    </div>
                )}
                {nextServiceDistance && (
                    <div className="def-list__item">
                        <Icon name="inspection-distance" size="xl" />
                        <div className="def-list__term">{nextServiceDistance.labelTextMap[language.toLowerCase()]}</div>
                        <div className="def-list__text">{`${nextServiceDistance.value.format(nextServiceDistance.decimalPlaces)} ${
                            nextServiceDistance.unit
                        }`}</div>
                    </div>
                )}
            </div>
        </div>
    )
}
