import { combineReducers } from "redux"
import { Actions as managerActions, ComponentActionType as ManagerComponentActionType, reduce as managerReducer } from "../../moduleManager/business"
import { Actions as detailsActions, ComponentActionType as DetailsComponentActionType, reduce as detailsReducer } from "../../details/business"

export const reduce = combineReducers({
    manager: managerReducer,
    details: detailsReducer
})

export type IMainActions = typeof MainActions

export const MainActions = {
    ...managerActions,
    ...detailsActions
}

export * from "./selectors"

export type MainActionsType = ManagerComponentActionType | DetailsComponentActionType