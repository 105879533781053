import { Tooltip, Icon, IconProps, TooltipProps } from "@tm/components"
import { useLocalization } from "@tm/localization"

type IconWithTooltipProps = {
    variant: "search" | "remove-filter"
    onClick?: React.MouseEventHandler<SVGSVGElement>
    iconProps?: IconProps
    tooltipProps?: TooltipProps
}

export default function IconWithTooltip(props: IconWithTooltipProps) {
    const { variant, onClick, iconProps, tooltipProps } = props
    const { translateText } = useLocalization()

    if (variant === "search") {
        return (
            <Tooltip title={translateText(13858) /* Filter Suche */} {...tooltipProps}>
                <Icon name={variant} onClick={onClick} {...iconProps} />
            </Tooltip>
        )
    }

    return (
        <Tooltip title={translateText(13857) /* Filter zurücksetzen */} {...tooltipProps}>
            <Icon name={variant} onClick={onClick} {...iconProps} />
        </Tooltip>
    )
}
