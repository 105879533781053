import { useState, Fragment } from "react"
import { useLocalization } from "@tm/localization"
import { Dictionary } from "@tm/morpheus"
import { Loader, Text, Button, Card, Headline } from "@tm/controls"
import PartsListItem from "./parts-list-item"
import PartCreator from "./part-creator"
import { CompilationShowViewModelItem } from "../../../../../data"
import { isWM } from "../../../../../utils"

type Props = {
    encodedWorkTaskId?: string
    compilationId: string
    loadingPositions: boolean
    parts: Array<CompilationShowViewModelItem>
    positionCount: number
    selectedPartIds: Array<string>
    onSelectPart(partId: string, moveMode?: boolean): void
    singleCompilationList?: boolean
}

export default function PartsList(props: Props) {
    const { translateText, translate } = useLocalization()
    const [editingParts, setEditingParts] = useState<Dictionary<CompilationShowViewModelItem>>({})
    const [showPartCreator, setShowPartCreator] = useState(false)

    const handleShowHideEditMode = (part: CompilationShowViewModelItem) => {
        if (!editingParts[part.id]) {
            setEditingParts({
                ...editingParts,
                [part.id]: part,
            })
        } else {
            const temp = { ...editingParts }
            delete temp[part.id]
            setEditingParts(temp)
        }
    }

    const handleAddPartClick = () => {
        setShowPartCreator(true)
    }

    const handleCloseEditor = () => {
        setShowPartCreator(false)
    }

    const renderPartItem = (part: CompilationShowViewModelItem) => {
        const { onSelectPart } = props

        return (
            <PartsListItem
                partItem={part}
                compilationId={props.compilationId}
                isEditing={!!editingParts[part.id]}
                isSelected={props.selectedPartIds.includes(part.id)}
                onShowHideEditMode={handleShowHideEditMode}
                onSelectPart={onSelectPart}
                singleCompilationList={props.singleCompilationList}
            />
        )
    }

    const renderPositionCount = () => {
        if (props.loadingPositions) {
            return <Loader />
        }

        return (
            <>
                <Text size="s" className="numbers" modifiers={["strong"]}>
                    {props.positionCount}
                </Text>
                <Text size="s" className="numbers">
                    {translate(479)}
                </Text>
            </>
        )
    }

    const renderList = () => (
        <div className="list part-list__table">
            {props.parts.map((part: CompilationShowViewModelItem, index) => (
                <Fragment key={index}>{renderPartItem(part)}</Fragment>
            ))}
        </div>
    )

    const renderEmpty = () => (
        <Card className="empty-list">
            <Text>{translateText(217)}</Text>
            <div className="empty-list__dashboard-button">
                <Button icon="dashboard" linkTo={`/${props.encodedWorkTaskId}`}>
                    {translateText(760)}
                </Button>
            </div>
        </Card>
    )

    const renderPartCreator = () => <PartCreator onClose={handleCloseEditor} compilationId={props.compilationId} />

    const showCreator = showPartCreator || (isWM() && !props.parts.length)

    return (
        <div className="part-list">
            <div className="part-list__header">
                {isWM() && (
                    <>
                        <div className="part-list__header__left">
                            <Headline size="xs">{translate(90)}</Headline>
                            <Button
                                className="adding-tools__button"
                                disabled={showCreator}
                                icon="plus"
                                layout={["circle"]}
                                size="s"
                                skin="highlight"
                                onClick={handleAddPartClick}
                            />
                        </div>
                        <div>{renderPositionCount()}</div>
                    </>
                )}
            </div>
            {showCreator && renderPartCreator()}
            {!props.parts.length ? renderEmpty() : renderList()}
        </div>
    )
}
