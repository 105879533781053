import { useLocalization } from "@tm/localization"
import { Article, EArticleModificationState } from "@tm/models"

export function useDisableReason(article: Article) {
    const { translateText } = useLocalization()
    const { articleModificationState, referencedArticleModification } = article
    if (articleModificationState === EArticleModificationState.ArticleDisabled) {
        return translateText(887)
    }
    if (articleModificationState === EArticleModificationState.VehicleLinkageDisabled) {
        return translateText(890)
    }
    if (referencedArticleModification?.isRemovedReferencedArticle) {
        return translateText(13050)
    }
}
