import { useState, useEffect, Fragment, useMemo } from "react"
import { style } from "typestyle"

import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { ConfigParams, ECounterType, getCurrentWorkTaskId, UserModuleType } from "@tm/models"
import { Scrollbar, Button, Text, Loader, Image, Headline, ScrollMenu } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModelState, renderRoute, getUIA, TmaHelper } from "@tm/utils"
import {
    ErpSettings,
    UpdateRepairShopRequest,
    useUser,
    useDatCredentials,
    useErpCredentials,
    getModuleFromUserContext,
} from "@tm/context-distribution"

import { Stack } from "@tm/components"

import Cms from "./components/Cms"
import ConceptPage from "./components/ConceptPage"
import DmsSettingsComponent from "./components/DmsSettings"
import HourlyRatesComponent from "./components/HourlyRates"
import { Location } from "./components/location"
import Modules from "./components/modules"
import RdwCompanyInfo from "./components/RdwCompanyInfo"
import DatCredentialSettings from "./components/DatCredentialSettings"
import ErpCredentialSettings from "./components/ErpCredentialSettings"
import RepairTimes from "./components/RepairTimes"
import ShoppingBasket from "./components/shopping-basket"
import SubUserCreation from "./components/SubUserCreation"
import SubUserTable from "./components/SubUserTable"
import UserSettingsArea from "./components/UserSettingsArea"
import { getBundleParams } from "../../utils"
import { WmMediathek } from "./components/WmMediathek"
import { useTelesalesVideoUrlParam } from "../../helpers"
import Chat from "./components/Chat"
import Logo from "./components/logo"
import { MarginTool } from "./components/MarginTool"
import { SupportCard } from "./components/SupportCard"
import { DialogSettings } from "./components/DialogSetting"
import { useArticleListSortingConfiguration } from "../../../../parts/src/components/ListV2/ArticleListConfiguration"
import { Provider, WrapperTypes } from "./wrapper"
import { ArticleListSettings } from "./components/ArticleListSettings"
import PurchasePriceSwitch from "./components/PurchasePriceSwitch"
import { CostEstimation } from "./components/CostEstimation"
import { useBasketModuleParameter } from "../../../../basket/src/hooks/useBasketModuleParameter"
import { useCostEstimationModuleParameter } from "../../../../basket/src/hooks/useCostEstimationModuleParameter"
import DrivemotiveLogin from "./components/DrivemotiveLogin"

const linkClassName = style({
    textDecoration: "none",
})

function UserSettingsComponent(props: WrapperTypes) {
    const { translateText, translate, changeLocale, languageId, isTranslationId, availableLanguages } = useLocalization()
    const { hasWorkTaskOrder } = useBasketModuleParameter()
    const { hasCostEstimation } = useCostEstimationModuleParameter()

    const { userSettings, userContext, updateRepairShop, changeLogo, changePrintLogo } = useUser() ?? {}
    const {
        subUserManagement,
        actions,
        licensorData,
        match,
        history,
        hasDmsSettings,
        state,
        providerImageBaseUrl,
        providers,
        showApiKeyForChat,
        hasDutchOrderPlates,
        externalSystemId,
        moduleRoute,
        demoActivationInstanstly,
        isWmTelesales,
    } = props
    const { params, path } = match
    const [modelState, setModelState] = useState<ModelState>()
    const telesalesVideoUrl = useTelesalesVideoUrlParam()
    const { addionalHotkeys, hiddenAreas = [] } = getBundleParams()
    const datSettings = useDatCredentials()
    const erpSettings = useErpCredentials()

    const isFastScroll = new URLSearchParams(window.location.search).get("fastScroll") == "1"

    const {
        enabled: articleListSortingEnabled,
        defaultMode: articleListSortingDefaultMode,
        availableModes: availableArticleListSortingModes,
    } = useArticleListSortingConfiguration()
    const displayArticleListSettings = articleListSortingEnabled && articleListSortingDefaultMode

    const hasDatCredentialSettings = datSettings?.showDatSettings
    const hasErpCredentialSettings = erpSettings?.showErpSettings
    const hasDrivemotiveSettings = useMemo<boolean>(() => !!getModuleFromUserContext(userContext, UserModuleType.Drivemotive), [userContext.modules])

    useEffect(() => {
        if (userSettings) {
            handleScrollToFromUrl()

            if (subUserManagement === true) {
                actions.showSubUsers(languageId)
            }

            actions.checkConceptPage(userContext)
        }
    }, [subUserManagement, userSettings])

    const getDmsName = (): string => {
        const isDMSConnected = (window as any)?.__DMS_MESSAGEBUS__?.channel("CONNECTION").last(1, "CONNECTION_STATUS_RECEIVED")[0]
            ?.content?.isConnected
        if (!isDMSConnected) {
            return "DMS"
        }
        return (window as any).__dmsInfo?.name ?? props.dmsName ?? JSON.parse(localStorage.getItem("DMS_INFO") ?? "{}")?.name ?? translateText(1875)
    }

    function handleScrollToFromUrl() {
        if (params.scrollTo && userContext && userSettings) {
            const scrollbarView = document.querySelector(".user-settings__settings .scrollbar__view")
            const element = document.getElementById(`user-settings__area__${params.scrollTo}`)

            if (element && scrollbarView) {
                if (scrollbarView.scrollTo) {
                    scrollbarView.scrollTo(0, element.offsetTop)
                } else {
                    scrollbarView.scrollTop = element.offsetTop
                }
            }

            setTimeout(
                () => {
                    history.replace(renderRoute(path, { ...params, scrollTo: undefined }))
                },
                isFastScroll ? 2000 : 0
            )
        }
    }

    function handleLogoutClick() {
        Container.getInstance("Portal").callAction("logout")
    }

    function handleLanguageClick(locale: string) {
        changeLocale(locale)
    }

    function renderUser() {
        let userInfo
        if (userContext) {
            if (userContext.generalContact) {
                userInfo = (
                    <>
                        <Text modifiers="block">{!!userContext.account && userContext.account.username}</Text>
                        <Text modifiers="block">{userContext.generalContact.name || userContext.generalContact.company}</Text>
                        <Text modifiers="block">
                            <a className={linkClassName} href={`mailto:${userContext.generalContact.email}`}>
                                {userContext.generalContact.email}
                            </a>
                        </Text>
                    </>
                )
            } else if (userContext.account) {
                userInfo = <Text modifiers="block">{userContext.account.username}</Text>
            }
        } else {
            userInfo = <Loader />
        }

        return (
            <UserSettingsArea icon="user" title={translateText(232)}>
                {userInfo}
                <div>
                    <Button layout={["holo"]} onClick={handleLogoutClick}>
                        {translateText(367)}
                    </Button>
                </div>
            </UserSettingsArea>
        )
    }

    function openInNewWindow(url: string | undefined) {
        if (url) {
            window.open(url, "_blank")
        }
    }

    function renderLicensor() {
        const { externalScripts } = Morpheus.getParams<ConfigParams>()
        const hasOneTrust = !!externalScripts?.oneTrust

        const mdmTraderAddress = userContext?.parameter.traderAddress
        if (licensorData || mdmTraderAddress) {
            return (
                <UserSettingsArea classPostfix="licensor" icon="operator" title={translateText(1152)}>
                    <div className="licensor__information" key={licensorData?.name}>
                        {mdmTraderAddress &&
                            mdmTraderAddress.split("\n").map((x) => {
                                return (
                                    <Fragment key={x}>
                                        {x}
                                        <br />
                                    </Fragment>
                                )
                            })}
                        {!mdmTraderAddress && licensorData && licensorData.name && (
                            <>
                                <Text>{isTranslationId(licensorData.name) ? translateText(licensorData.name) : licensorData.name}</Text>
                                <br />
                            </>
                        )}
                        {!mdmTraderAddress && licensorData && licensorData.streetAndNumber && (
                            <>
                                <Text>
                                    {isTranslationId(licensorData.streetAndNumber)
                                        ? translateText(licensorData.streetAndNumber)
                                        : licensorData.streetAndNumber}
                                </Text>
                                <br />
                            </>
                        )}
                        {!mdmTraderAddress && licensorData && licensorData.postalCodeAndCity && (
                            <>
                                <Text>
                                    {isTranslationId(licensorData.postalCodeAndCity)
                                        ? translateText(licensorData.postalCodeAndCity)
                                        : licensorData.postalCodeAndCity}
                                </Text>
                                <br />
                            </>
                        )}
                        {!mdmTraderAddress && licensorData && licensorData.telNo && (
                            <>
                                <Text>{isTranslationId(licensorData.telNo) ? translateText(licensorData.telNo) : `Tel.: ${licensorData.telNo}`}</Text>
                                <br />
                            </>
                        )}
                        {!mdmTraderAddress && licensorData && licensorData.faxNo && (
                            <>
                                <Text>{isTranslationId(licensorData.faxNo) ? translateText(licensorData.faxNo) : `Fax.: ${licensorData.faxNo}`}</Text>
                                <br />
                            </>
                        )}
                        {!mdmTraderAddress && licensorData && licensorData.email && (
                            <>
                                <Text>
                                    {isTranslationId(licensorData.email) ? translateText(licensorData.email) : `E-Mail.: ${licensorData.email}`}
                                </Text>
                                <br />
                            </>
                        )}
                    </div>
                    {licensorData && licensorData.forms && (
                        <span>
                            {Object.keys(licensorData.forms).map((name) => {
                                let url = licensorData.forms![name]
                                if (url.indexOf("{{") > -1) {
                                    url = translateText(url)
                                }

                                return (
                                    <Button key={name} layout={["holo"]} linkTo={url} target="_blank">
                                        {translateText(name)}
                                    </Button>
                                )
                            })}
                        </span>
                    )}
                    {hasOneTrust && (
                        <Button layout={["holo"]} id="ot-sdk-btn" className="ot-sdk-show-settings">
                            {translateText(13367)}
                        </Button>
                    )}
                </UserSettingsArea>
            )
        }
        return null
    }

    function renderOperator() {
        return (
            <UserSettingsArea icon="operator" title={translateText(824)}>
                <Text modifiers="block">{translateText(825)}:</Text>
                <Text modifiers="block">{translate(826)}</Text>
                <div>
                    <Button layout={["holo"]} linkTo={translateText(828)} target="_blank">
                        {translateText(827)}
                    </Button>
                    <Button layout={["holo"]} linkTo={translateText(830)} target="_blank">
                        {translateText(829)}
                    </Button>
                    <Button layout={["holo"]} linkTo={translateText(832)} target="_blank">
                        {translateText(831)}
                    </Button>
                </div>
            </UserSettingsArea>
        )
    }

    function renderChatSettings() {
        if (showApiKeyForChat) {
            return (
                <UserSettingsArea icon="message" title={translateText(3028)}>
                    <Chat />
                </UserSettingsArea>
            )
        }
    }

    function renderProvider(provider: Provider | undefined, baseUrl: string, key?: number) {
        if (!provider) {
            return null
        }

        return (
            <Image
                key={key}
                onClick={() => {
                    openInNewWindow(provider.url)
                }}
                className="user-settings__provider"
                url={baseUrl + provider.image}
            />
        )
    }

    function renderProviders() {
        if (providerImageBaseUrl && providers && providers.length) {
            return (
                <UserSettingsArea icon="partner" title={translateText(839)}>
                    {providers.map((provider, id) => renderProvider(provider, providerImageBaseUrl, id))}
                </UserSettingsArea>
            )
        }
    }

    function renderMenu() {
        const ids: { [key: string]: string } = {}

        ids["user-settings__area__language"] = translateText(139)

        if (!hiddenAreas.includes("location")) {
            ids["user-settings__area__location"] = translateText(28)
        }

        if (
            (!userSettings ||
                !userContext.parameter.purchasePricesDisabled ||
                userContext.modules?.some((x) => x.type === UserModuleType.MarginTool)) &&
            !hiddenAreas.includes("prices")
        ) {
            ids["user-settings__area__prices"] = translateText(140)
        }

        if (!hiddenAreas.includes("basket") && hasWorkTaskOrder) {
            ids["user-settings__area__basket"] = translateText(291)
        }

        if (!hiddenAreas.includes("cost-estimation") && hasCostEstimation) {
            ids["user-settings__area__cost-estimation"] = translateText(82)
        }

        if (!hiddenAreas.includes("repair-times")) {
            ids["user-settings__area__repair-times"] = translateText(83)
        }

        ids["user-settings__area__hourly-rates"] = translateText(911)

        if (displayArticleListSettings) {
            ids["user-settings__area__article-list"] = translateText(152)
        }

        if (!hiddenAreas.includes("logos")) {
            ids["user-settings__area__logo"] = translateText(856)
        }

        if (subUserManagement === true) {
            ids["user-settings__area__sub-user-management"] = translateText(1237)
        }

        state.conceptPage && (ids["user-settings__concept-page"] = translateText(1602))

        if (hasDmsSettings) {
            ids["user-settings__area__dms"] = getDmsName()
        }

        if (hasDrivemotiveSettings) {
            ids["user-settings__area__drivemotive"] = translateText(13689)
        }

        if (hasDutchOrderPlates) {
            ids["user-settings__area__rdw-company-info"] = "RDW Company Info"
        }

        if (isWmTelesales && telesalesVideoUrl) {
            ids["user-settings__area__mediathek"] = translateText(13010)
        }

        if (moduleRoute) {
            ids["user-settings__area__module"] = translateText(1832)
        }

        if (hasDatCredentialSettings || hasErpCredentialSettings) {
            ids["user-settings__area__credential-settings"] = translateText(13417)
        }

        if (!hiddenAreas.includes("dialog-settings")) {
            ids["user-settings__area__dialog-settings"] = translateText(13629)
        }

        if (!hiddenAreas.includes("help")) {
            ids["user-settings__area__help"] = translateText(235)
        }

        return <ScrollMenu ids={ids} scrollbarViewQuerySelector=".user-settings__settings .scrollbar__view" />
    }

    function renderLanguageButton(languageIdToRender: string) {
        return (
            <Button
                key={languageIdToRender}
                layout={["ghost"]}
                id={`settings_lang_${languageIdToRender}`}
                onClick={() => {
                    handleLanguageClick(languageIdToRender)
                }}
                disabled={languageId === languageIdToRender}
            >
                {availableLanguages[languageIdToRender]}
            </Button>
        )
    }

    function renderLanguage() {
        return (
            <UserSettingsArea id="user-settings__area__language" title={translateText(139)}>
                {Object.keys(availableLanguages || {}).map(renderLanguageButton)}
            </UserSettingsArea>
        )
    }

    const renderShortcutText = (item: string | number): string => (typeof item == "string" ? item : translateText(item))

    function renderHelpPage() {
        const dmsText = translateText(12922).replace("DMS", getDmsName())
        const textes = [13840, 13756, 12574, dmsText, 12576, 12577, 12578, 12579, 12580, 12581, 12582, 13171].concat(addionalHotkeys || [])

        if (hiddenAreas.includes("help")) {
            return null
        }
        if (!hasWorkTaskOrder) {
            textes.remove((i) => i === 12580)
            textes.remove((i) => i === 12582)
        }
        if (!hasCostEstimation) {
            textes.remove((i) => i === 12581)
        }

        if (!(!getCurrentWorkTaskId() || typeof tmJSEvents === "undefined" || !tmJSEvents || !tmJSEvents.onExportShoppingBasket)) {
            textes.push(13171)
        }

        return (
            <UserSettingsArea id="user-settings__area__help" title={translateText(235)}>
                <Text className="inner_title" size="xl">
                    {translateText(12601)}
                </Text>
                <div className="content">
                    {textes.map((o) => (
                        <div key={o} className="shortcut">
                            <Text size="l">{renderShortcutText(o)}</Text>
                        </div>
                    ))}
                </div>
            </UserSettingsArea>
        )
    }

    function renderModulePage() {
        if (!userSettings || !userContext) {
            return <UserSettingsArea id="user-settings__area__module" title={translateText(1832)} showLoader />
        }

        if (!userContext?.modules?.some((x) => x.type === UserModuleType.DemoActivation)) {
            return null
        }

        return <Modules userContext={userContext} demoActivationInstanstly={demoActivationInstanstly} moduleCompareRoute={moduleRoute} />
    }

    function handleSaveRepairShop(data: UpdateRepairShopRequest) {
        updateRepairShop?.(data).catch((error) => {
            if (error) {
                setModelState(error.modelState)
            }
        })
    }

    function resetLocationModelState() {
        setModelState(undefined)
    }

    function renderLocation() {
        if (hiddenAreas.includes("location")) {
            return
        }

        return (
            <UserSettingsArea id="user-settings__area__location" title={translateText(28)}>
                {userSettings ? (
                    <Location
                        companyName={userSettings.repairShop && userSettings.repairShop.companyName}
                        postalAddress={userSettings.repairShop && userSettings.repairShop.postalAddress}
                        contactPerson={userSettings.repairShop && userSettings.repairShop.contactPerson}
                        contactInfo={userSettings.repairShop && userSettings.repairShop.contactInfo}
                        location={userSettings.repairShop && userSettings.repairShop.location}
                        taxNumber={userSettings.repairShop && userSettings.repairShop.taxNumber}
                        addressSuggestions={state.addressSuggestions}
                        addressSuggestionsLoading={state.addressSuggestionsLoading}
                        modelState={modelState}
                        updateRepairShop={handleSaveRepairShop}
                        loadAddressSuggestions={actions.loadAddressSuggestions}
                        resetAddressSuggestions={actions.resetAddressSuggestions}
                        resetModelState={() => resetLocationModelState()}
                    />
                ) : (
                    <Loader />
                )}
            </UserSettingsArea>
        )
    }

    function renderPrices() {
        if (hiddenAreas.includes("prices")) {
            return null
        }

        let content: JSX.Element | Array<JSX.Element>

        if (!userSettings) {
            content = <Loader />
        } else {
            content = []

            if (!userContext?.parameter.purchasePricesDisabled) {
                content.push(
                    <PurchasePriceSwitch
                        key="ek-price-switch"
                        loaderSx={{ left: 14, marginTop: 2, marginBottom: 2.2 }}
                        labelTextId={816}
                        uIAHtmlAttribut={getUIA("SettingsPricesPurchasePrice")}
                    />
                )
            }

            if (userContext.modules?.some((x) => x.type === UserModuleType.MarginTool)) {
                content.push(<MarginTool />)
            }

            if (!content.length) {
                return null
            }
        }

        return (
            <UserSettingsArea id="user-settings__area__prices" title={translateText(140)}>
                <Stack direction="column" spacing={1} alignItems="flex-start">
                    {content}
                </Stack>
            </UserSettingsArea>
        )
    }

    function renderCostEstimation() {
        if (hiddenAreas.includes("cost-estimation") || !hasCostEstimation) {
            return null
        }

        return <CostEstimation />
    }

    function renderShoppingBasket() {
        if (hiddenAreas.includes("basket") || !hasWorkTaskOrder) {
            return null
        }
        return <ShoppingBasket externalSystemId={externalSystemId} />
    }

    function renderRepairTimes() {
        if (hiddenAreas.includes("repair-times")) {
            return null
        }
        return (
            <UserSettingsArea id="user-settings__area__repair-times" title={translateText(83)}>
                <RepairTimes />
            </UserSettingsArea>
        )
    }

    function renderHourlyRates() {
        return (
            <UserSettingsArea id="user-settings__area__hourly-rates" title={translateText(911)}>
                {userSettings ? (
                    <HourlyRatesComponent
                        hourlyRates={userSettings.hourlyRates}
                        currencyCode={userSettings.hourlyRatesCurrencyCode}
                        enabledModules={userContext && userContext.modules}
                    />
                ) : (
                    <Loader />
                )}
            </UserSettingsArea>
        )
    }

    function renderArticleList() {
        if (!displayArticleListSettings) {
            return null
        }

        return (
            <UserSettingsArea id="user-settings__area__article-list" title={translateText(152)}>
                {userSettings ? (
                    <ArticleListSettings
                        defaultSortingMode={articleListSortingDefaultMode}
                        availableSortingModes={availableArticleListSortingModes}
                        userSettings={userSettings}
                    />
                ) : (
                    <Loader />
                )}
            </UserSettingsArea>
        )
    }

    function handleSaveLogo(logo: string) {
        changeLogo?.(logo)
    }

    function handleChangePrintLogo(printLogo: boolean) {
        changePrintLogo(printLogo)
    }

    function renderLogo() {
        if (hiddenAreas.includes("logos")) {
            return null
        }
        return (
            <UserSettingsArea id="user-settings__area__logo" title={translateText(856)}>
                {userSettings ? (
                    <Logo
                        logo={userSettings.repairShop && userSettings.repairShop.logo}
                        saveLogo={handleSaveLogo}
                        printLogo={userSettings.repairShop?.printLogo}
                        handleChangePrintLogo={handleChangePrintLogo}
                    />
                ) : (
                    <Loader />
                )}
            </UserSettingsArea>
        )
    }

    function renderDialogSettings() {
        if (hiddenAreas.includes("dialog-settings")) {
            return null
        }
        return (
            <UserSettingsArea id="user-settings__area__dialog-settings" title={translateText(13629)}>
                <DialogSettings />
            </UserSettingsArea>
        )
    }

    function renderSubUserManagement() {
        if (subUserManagement !== true) {
            return null
        }

        let table
        let button
        if (state.subUsersLoading) {
            table = <Loader />
        } else {
            button = (
                <SubUserCreation
                    reloadSubUsers={() => actions.showSubUsers(languageId)}
                    userAdminConfig={state.userAdminConfig}
                    permissionContainer={state.subUsersPermissionContainer}
                />
            )
            if (state.subUsers && state.subUsers.length) {
                table = (
                    <SubUserTable
                        subUsers={state.subUsers}
                        reloadSubUsers={() => actions.showSubUsers(languageId)}
                        permissionContainer={state.subUsersPermissionContainer}
                        userAdminConfig={state.userAdminConfig}
                    />
                )
            } else {
                table = (
                    <div>
                        <br />
                        {translateText(1245)}
                    </div>
                )
            }
        }

        return (
            <UserSettingsArea id="user-settings__area__sub-user-management" title={translateText(1237)}>
                <div className="user-settings__sub-user-management">
                    {button}
                    {table}
                </div>
            </UserSettingsArea>
        )
    }

    function renderConceptPage() {
        if (!state.conceptPage) {
            return null
        }

        return (
            <ConceptPage
                onSave={(credentials) => {
                    actions.saveConceptPageCredentials(credentials)
                }}
                hasCredentials={!!state.conceptPageHasCredentials}
                onChangeAnyway={actions.changeAnyway}
                isLoading={!!state.conceptPageLoading}
            />
        )
    }

    function renderDmsSettings() {
        if (!hasDmsSettings) {
            return
        }

        const dmsName = getDmsName()

        return (
            <UserSettingsArea id="user-settings__area__dms" title={dmsName}>
                {!userSettings ? <Loader /> : <DmsSettingsComponent dmsName={dmsName} settings={userSettings.dmsSettings} />}
            </UserSettingsArea>
        )
    }

    function renderRDWCompanyInfo() {
        if (!hasDutchOrderPlates) {
            return
        }

        return (
            <RdwCompanyInfo
                handleSetMDMParams={actions.setDutchOrdersMDMParams}
                loaded={state.rdwCompanyInfoLoaded}
                infoLoading={state.rdwCompanyInfoLoading}
                companyInfoError={state.rdwCompanyInfoError}
            />
        )
    }

    function renderCredentialSettings() {
        if (!hasDatCredentialSettings && !hasErpCredentialSettings) {
            return
        }

        return (
            <UserSettingsArea id="user-settings__area__credential-settings" title={translateText(13417)}>
                <Stack spacing={2}>
                    {hasErpCredentialSettings &&
                        Object.keys(erpSettings?.erpModules).map((key) => (
                            <ErpCredentialSettings erpSettings={erpSettings?.erpModules[key as keyof ErpSettings]} key={key} />
                        ))}
                    {hasDatCredentialSettings && <DatCredentialSettings datSettings={datSettings} />}
                </Stack>
            </UserSettingsArea>
        )
    }

    function renderDrivemotiveSettings() {
        if (!hasDrivemotiveSettings) {
            return
        }

        return (
            <UserSettingsArea id="user-settings__area__drivemotive" title={translateText(13689)}>
                {!userSettings ? <Loader /> : <DrivemotiveLogin settings={userSettings.drivemotiveSettings} />}
            </UserSettingsArea>
        )
    }

    const renderWmTelesalesMediathek = useMemo(() => {
        if (telesalesVideoUrl) {
            return <WmMediathek videoUrls={[{ description: translateText(13009), url: telesalesVideoUrl }]} />
        }
    }, [telesalesVideoUrl, translateText])

    const scrollClassName = style({ scrollBehavior: isFastScroll ? "auto" : "smooth" })

    return (
        <div className="tk-settings user-settings">
            <div className="user-settings__headline">
                <Headline>{translate(842)}</Headline>
                <Button
                    icon="close"
                    layout={["ghost"]}
                    size="xl"
                    onClick={() => {
                        history.goBack()
                        TmaHelper.GeneralCountEvent.Call(ECounterType.SettingsClose)
                    }}
                />
            </div>

            <div className="user-settings__content">
                <div className="user-settings__infos">
                    <Scrollbar className={scrollClassName}>
                        {renderUser()}
                        <SupportCard licensorData={licensorData} />
                        {renderLicensor()}
                        {renderOperator()}
                        {renderProviders()}
                        {renderChatSettings()}
                        <Cms />
                    </Scrollbar>
                </div>
                <div className="user-settings__settings">
                    {renderMenu()}
                    <Scrollbar className={scrollClassName}>
                        {renderLanguage()}
                        {renderLocation()}
                        {renderPrices()}
                        {renderShoppingBasket()}
                        {renderCostEstimation()}
                        {renderRepairTimes()}
                        {renderHourlyRates()}
                        {renderArticleList()}
                        {renderLogo()}
                        {renderSubUserManagement()}
                        {renderConceptPage()}
                        {renderDmsSettings()}
                        {renderDrivemotiveSettings()}
                        {renderRDWCompanyInfo()}
                        {isWmTelesales && renderWmTelesalesMediathek}
                        {renderModulePage()}
                        {renderCredentialSettings()}
                        {renderDialogSettings()}
                        {renderHelpPage()}
                    </Scrollbar>
                </div>
            </div>
        </div>
    )
}

export default UserSettingsComponent
