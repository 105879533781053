import { em } from "csx"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { Box, Typography, styled } from "@tm/components"

type Props = {
    totalPrice: number
}

export default function TotalPrice(props: Props) {
    const { totalPrice } = props
    const { userContext } = useUser() ?? {}
    const { currency, translateText } = useLocalization()
    const currencyString = getCurrencyFromUserContext(userContext)

    return (
        <TotalPriceWrapper>
            <Box>{translateText(2007)}</Box>
            <StyledPrice>{currency(totalPrice ?? 0, currencyString)}</StyledPrice>
        </TotalPriceWrapper>
    )
}

const TotalPriceWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    paddingLeft: em(0.5),
    paddingRight: em(1.5),
    fontSize: theme.font?.subtitleSize.m,
}))

const StyledPrice = styled(Typography)(({ theme }) => ({
    flex: 1,
    fontWeight: theme.font?.boldWeight,
    color: theme.colors?.primary,
}))
