import { Fragment, useCallback, useEffect, useState } from "react"
import { Article, channel, CustomArticle, EReplaceButtonBundle, RequestArticleListPayload } from "@tm/models"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Box, Button, Divider, Icon, Image, LoadingContainer, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { closeModalOrDrawer, useUnsubscribe } from "@tm/utils"
import { CollapsibleWrapper, CustomPanel, DefectDescription, GenArts, HaynesProServiceModal, RepairTimes, SecutiryStatus, UploadPic } from ".."
import { Genart, RepairTime, Work, SelectedItems, SpecificationsGenart, SmartLink, WorkId } from "../../../data/models"
import Consumables from "../consumables/Consumables"
import { ActiveButtons } from "../../../models"
import TitleHeader from "./titleHeader"
import { useFastServiceStore } from "../../../data"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"
import { mapCalcArticleToArticleIdentifier } from "../../../helpers"
import { getHaynesProSmartLinkUrl } from "./helpers/haynesProHelper"
import { useAlternativeArticles } from "../../../helpers/hooks/useAlternativeArticles"

type Props = {
    item: Work
    onSelectGenArt: (item: Genart, path: string) => void
    onSelectRepairTime: (item: RepairTime, path: string) => void
    onSelectLubricant: (item: string, path: string) => void
    onSaveLubricant: (item: string, path: string) => void
    onStatusButtonClick: (buttonId: ActiveButtons, path: string) => void
    selectedItems: Record<string, SelectedItems>
    onSaveComment: (comment: string, path: string) => void
    onSafetyStatus: (buttonType: number, path: string) => void
    onQuantityChange: (articleId: string, quantity: number, path: string) => void
    onOeArticleChange: (fsArticleId: string, quantity: number, price: number, designation: string, path: string, oeNumber: string) => void
    onArticleReplace: (articleId: string, newArticle: Article, path: string, oeReplacement?: boolean) => void
    onConsumableReplace: (consumableId: string, newConsumableArticle: CustomArticle) => void
    activePanel?: boolean
}

export default function Panels(props: Props) {
    const {
        item,
        onQuantityChange,
        onOeArticleChange,
        onArticleReplace,
        onSelectGenArt,
        onSelectRepairTime,
        onSelectLubricant,
        onSaveLubricant,
        selectedItems,
        onStatusButtonClick,
        onSaveComment,
        onSafetyStatus,
        activePanel,
        onConsumableReplace,
    } = props

    const subscriptionReplacePart = useUnsubscribe()

    const [showDefect, setShowDefect] = useState<boolean>(false)
    const workTask = useWorkTask()?.workTask
    const { userContext } = useUser() ?? {}
    const { languageId, translateText } = useLocalization()
    const recordWorks = useFastServiceStore((state) => state.worksState.works)
    const [alternativeRequestData, setAlternativeRequestData] = useState<{ genArt: Genart; fsArticle: FSArticle; oeReplace?: boolean } | undefined>(
        undefined
    )

    const { alternatives, alternativesLoading } = useAlternativeArticles(alternativeRequestData?.fsArticle.id)

    const [open, setOpen] = useState(false)
    const [url, setUrl] = useState("")
    const [hasPictureState, setHasPictureState] = useState(false)

    const isFirstAidKitFunction = item.providerId === WorkId.FirstAidKit

    useEffect(() => {
        if (alternativesLoading) {
            return
        }

        if (alternatives && alternativeRequestData) {
            const vehicle = workTask?.vehicle
            const { fsArticle, genArt } = alternativeRequestData!

            requestAlternatives(
                {
                    productGroups: {
                        ids: [genArt.genArtNr],
                    },
                    fastCalculator: {
                        isFastService: true,
                        alternatives: alternatives?.map((x) => mapCalcArticleToArticleIdentifier(x, genArt.genArtNr, vehicle?.tecDocTypeId)),
                    },
                    fittingPosition: fsArticle.binKrit100,
                },
                genArt,
                fsArticle
            )

            setAlternativeRequestData(undefined)
        }
    }, [alternatives, alternativesLoading, alternativeRequestData])

    const handleStatusSelection = useCallback((value: number) => {
        useFastServiceStore.getState().setUpdateStatusGenart(value, item.label)
        onSafetyStatus(value, item.label)
    }, [])

    const handleGenArtSelection = useCallback(
        (selectedItem: Genart) => {
            onSelectGenArt(selectedItem, item.label)
            if (!selectedItem.isSelected && showDefect) {
                useFastServiceStore.getState().setUpdateEndPageDefects(selectedItem, item.label)
            }
        },
        [showDefect]
    )

    const handleRepairTimeSelection = useCallback((selectedItem: RepairTime) => {
        onSelectRepairTime(selectedItem, item.label)
    }, [])

    const handleSelectLubricant = useCallback((selectedItem: string) => {
        onSelectLubricant(selectedItem, item.label)
        onSaveLubricant(selectedItem, item.label)

        Object.values(recordWorks)?.forEach((recordWork) => {
            recordWork.forEach((work) => {
                if (work !== item) {
                    work.consumables?.forEach((consumable) => {
                        consumable.specifications?.some(
                            (specification) => `${specification.label?.trim()} ${specification.quality?.trim()}` === selectedItem.trim()
                        ) && onSaveLubricant(selectedItem, work.label)
                    })
                }
            })
        })
    }, [])

    const handleStatusButtons = useCallback(
        (buttonType: ActiveButtons) => {
            if (buttonType === "defectButton" || showDefect) {
                setShowDefect(!showDefect)
            }
            onStatusButtonClick(buttonType, item.label)
        },
        [showDefect]
    )

    const onCommentSave = useCallback((comment: string) => {
        onSaveComment(comment, item.label)
    }, [])

    const handleQuantityChange = useCallback((articleId: string, quantity: number) => {
        onQuantityChange(articleId, quantity, item.label)
    }, [])

    const handleOeArticleChange = useCallback((fsArticleId: string, quantity: number, price: number, designation: string, oeNumber: string) => {
        onOeArticleChange(fsArticleId, quantity, price, designation, item.label, oeNumber)
    }, [])

    const handleShowPartAlternatives = (genArt: Genart, fsArticle?: FSArticle, oeReplace?: boolean) => {
        if (!fsArticle) {
            return
        }

        if (oeReplace) {
            requestOEAlternatives(genArt, fsArticle, oeReplace)
        } else {
            setAlternativeRequestData({ genArt, fsArticle, oeReplace })
        }
    }

    const requestOEAlternatives = (genArt: Genart, fsArticle: FSArticle, oeReplace?: boolean) => {
        subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce(
            "PARTS/REPLACE_PART",
            ({ part: replacePart, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.FastService) {
                    return false
                }

                onArticleReplace(fsArticle.id, replacePart as Article, item.label, oeReplace)

                closeModalOrDrawer(isNewList)
            }
        )

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            uniProductGroups: {
                ids: [genArt.genArtNr],
            },
            replaceButtonMicro: EReplaceButtonBundle.FastService,
            forceReload: true,
            inModal: true,
        })
    }

    const requestAlternatives = (request: RequestArticleListPayload, genArt: Genart, part: FSArticle, oeReplace?: boolean) => {
        subscriptionReplacePart.current = channel("GLOBAL").clearAndSubscribeOnce(
            "PARTS/REPLACE_PART",
            ({ part: replacePart, isNewList, bundle }) => {
                if (bundle !== EReplaceButtonBundle.FastService) {
                    return false
                }

                onArticleReplace(part.id, replacePart as Article, item.label, oeReplace)

                closeModalOrDrawer(isNewList)
            }
        )

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", {
            ...request,
            fastCalculator: request.fastCalculator ?? {},
            replaceButtonMicro: EReplaceButtonBundle.FastService,
            forceReload: true,
            inModal: true,
        })
    }

    const handleOpenHaynesProModal = (link: SmartLink) => {
        const smartLinkUrl = getHaynesProSmartLinkUrl(link, userContext, languageId)
        if (!smartLinkUrl) {
            return
        }

        setUrl(smartLinkUrl)
        setOpen(true)
    }

    const handleHplTextBuild = (texts: string[]) => {
        let hpText = ""
        texts.forEach((text) => {
            if (text) {
                hpText = hpText.concat(" ", text)
            }
        })
        return hpText
    }

    const renderRepairTimes = (data: RepairTime[]) => {
        return <RepairTimes items={data} onSelect={handleRepairTimeSelection} selectedRepairTimes={selectedItems[item.label]?.selectedRepairTimes} />
    }

    const renderGenArts = (data: Work) => {
        if (data.genArts.filter((x) => x.isSelected)?.length === 0 && !showDefect) {
            return null
        }

        return (
            <GenArts
                isDefect={showDefect}
                work={data}
                onSelect={handleGenArtSelection}
                selectedGenArts={selectedItems[item.label]?.selectedGenArts}
                onQuantityArticleChange={handleQuantityChange}
                onOeArticleChange={handleOeArticleChange}
                showPartAlternatives={handleShowPartAlternatives}
            />
        )
    }

    const renderConsumables = (data: SpecificationsGenart[]) => {
        return (
            <Consumables
                items={data}
                workItem={item}
                onSelectLubricant={handleSelectLubricant}
                selectedLubricant={selectedItems[item.label]?.selectedLubricant}
                onConsumableReplace={onConsumableReplace}
            />
        )
    }

    const renderCheckInformation = (work: Work) => {
        if (!work.infoTexts?.length && !work.haynesProSmartLinks?.length) {
            return null
        }

        const haynesProSmartLinksFiltered = work.haynesProSmartLinks?.filter((hpl) => hpl.operation !== undefined && hpl?.label)

        return (
            <>
                <CollapsibleWrapper textId={12771}>
                    <Box
                        sx={{
                            display: "flex",
                            marginLeft: "2em",
                            gap: "0.5em",
                            "& .hplImage": { justifyContent: "center" },
                        }}
                    >
                        {!!work.infoTexts?.length && <Icon sx={{ marginTop: "0.1em" }} name="info" />}
                        <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5em", width: "100%" }}>
                            {work.haynesProSmartLinks?.map((hpl, index) => {
                                return (
                                    <Fragment key={index}>
                                        {hpl?.text && <Typography>{handleHplTextBuild(hpl?.text)}</Typography>}
                                        {hpl?.operation === "IMAGE" && (
                                            <Box className="hplImage" sx={{ display: "flex" }}>
                                                <Image src={hpl?.id2} style={{ maxWidth: "50em", maxHeight: "50em" }} />
                                            </Box>
                                        )}
                                    </Fragment>
                                )
                            })}
                            <Box sx={{ display: "flex", gap: "0.5em" }}>
                                {haynesProSmartLinksFiltered?.map((links) => {
                                    return (
                                        <Button key={links.id1} title={links.label} onClick={() => handleOpenHaynesProModal(links)}>
                                            <Typography>{links.label}</Typography>
                                        </Button>
                                    )
                                })}
                            </Box>
                        </Box>
                    </Box>
                </CollapsibleWrapper>
                <Divider sx={{ margin: "1em 0" }} />
            </>
        )
    }

    const renderPanelTitle = (title: string, workType: number) => {
        const disableStatus = isFirstAidKitFunction && !hasPictureState

        return (
            <TitleHeader
                title={title}
                onStatusButtonClick={handleStatusButtons}
                selectedStatusButton={disableStatus ? "none" : selectedItems[item.label]?.selectedStatusButton || "none"}
                workType={workType}
                disableStatusButtonClick={disableStatus}
            />
        )
    }

    const hasPicture = (value: boolean) => {
        setHasPictureState(value)
    }

    const renderDepositOfMandatoryImage = (path: string) => {
        return (
            <Box marginTop="1em">
                <Typography>{translateText(12768)}</Typography>
                <UploadPic path={path} onPictureUploaded={hasPicture} />
            </Box>
        )
    }

    return (
        <CustomPanel title={renderPanelTitle(item.label, item.workType)} initiallyClosed={!activePanel} isSelected={activePanel}>
            <LoadingContainer isLoading={alternativesLoading}>
                {(showDefect || selectedItems[item.label]?.selectedStatusButton === "defectButton") && (
                    <DefectDescription onCommentSave={onCommentSave} path={item.label} />
                )}

                {renderCheckInformation(item)}
                {!!item.genArts.length && renderGenArts(item)}
                {!!item.repairTimes.length && renderRepairTimes(item.repairTimes)}
                {!!item.consumables.length && renderConsumables(item.consumables)}

                {showDefect && <SecutiryStatus onSelect={handleStatusSelection} />}
                {open && <HaynesProServiceModal open={open} url={url} onClose={setOpen} />}

                {isFirstAidKitFunction && renderDepositOfMandatoryImage(item.label)}
            </LoadingContainer>
        </CustomPanel>
    )
}
