import { useEffect, useRef, useState, KeyboardEvent, useCallback, ChangeEventHandler, useMemo, ChangeEvent } from "react"
import { equals, registerOutsideClick } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Customer } from "@tm/models"
import { Button, Typography, Tab, Tabs, Stack, Box, TextField } from "@tm/components"

type EditorComponentProps = {
    customer: Customer
    onChange(customer: Customer): void
    onAbort(): void
    onOutsideClick(): void
    nav: string
    hideDiscount?: boolean
    alternativeView?: boolean
    tinyWidget?: boolean
}

export default function EditorComponent(props: EditorComponentProps) {
    const { translateText, translate } = useLocalization()
    const [customer, setCustomer] = useState<Customer>(props.customer)
    const [activeNavIdentifier, setActiveNavIdentifier] = useState<string>(props.nav)
    const isDirty = useMemo(() => !equals(customer, props.customer), [customer, props.customer])
    const { hideDiscount, onChange, onAbort, onOutsideClick, alternativeView, tinyWidget } = props
    const element = useRef<HTMLDivElement>(null)

    useEffect(() => {
        function handleClickOutside() {
            if (isDirty) {
                onChange(customer)
            } else {
                onOutsideClick()
            }
        }
        if (element.current) {
            return registerOutsideClick(element.current, handleClickOutside)
        }
    }, [element, customer, isDirty, onChange, onOutsideClick])

    function handleNavSelect(_: unknown, nextNavIdentifier: string) {
        setActiveNavIdentifier(nextNavIdentifier)
    }

    function handleKeyPress(e: KeyboardEvent, nextNavIdentifier: string) {
        switch (e.key) {
            case "Enter":
            case "Tab":
                setActiveNavIdentifier(nextNavIdentifier)
                break
            default:
        }
    }

    function renderTextField(value: string | undefined, onChangeHandler: ChangeEventHandler<HTMLInputElement>, nextNavIdentifier: string) {
        return (
            <TextField
                value={value}
                fullWidth
                onChange={onChangeHandler}
                autoFocus
                onKeyPress={(e: KeyboardEvent) => handleKeyPress(e, nextNavIdentifier)}
            />
        )
    }

    function renderNumberField(value: number | undefined, onChangeHandler: ChangeEventHandler<HTMLInputElement>, nextNavIdentifier: string) {
        return (
            <TextField
                inputProps={{
                    max: 100,
                    min: 0,
                    type: "number",
                    step: "0.1",
                }}
                value={value}
                fullWidth
                onChange={onChangeHandler}
                autoFocus
                onKeyPress={(e: KeyboardEvent) => handleKeyPress(e, nextNavIdentifier)}
            />
        )
    }

    function renderActivePageContent() {
        const validatePhone = (value: string) => {
            return value.replace(/[a-zA-Z_]/g, "")
        }

        const validateNumber = (value: string) => {
            const regex = /^-+/g // No leading "-" allowed

            const result = value ? +value.replace(regex, "") : undefined

            return result
        }

        const renderFirstName = () => {
            return renderTextField(
                customer.firstName,
                (event) => {
                    setCustomer((prev) => ({ ...prev, firstName: event.target.value }))
                },
                "lastName"
            )
        }

        const renderPhone = () => {
            return renderTextField(
                customer.phone,
                (event) => {
                    setCustomer((prev) => ({ ...prev, phone: validatePhone(event.target.value) }))
                },
                alternativeView ? "firstName" : "email"
            )
        }

        switch (activeNavIdentifier) {
            case "phone": {
                return renderPhone()
            }

            case "email": {
                return renderTextField(
                    customer.email,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, email: event.target.value }))
                    },
                    "companyName"
                )
            }

            case "companyName": {
                return renderTextField(
                    customer.companyName,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, companyName: event.target.value }))
                    },
                    "mobile"
                )
            }

            case "mobile": {
                return renderTextField(
                    customer.mobile,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, mobile: validatePhone(event.target.value) }))
                    },
                    "sparePartsDiscount"
                )
            }

            case "sparePartsDiscount": {
                return renderNumberField(
                    customer.sparePartsDiscount,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, sparePartsDiscount: validateNumber(event.target.value) }))
                    },
                    "repairTimesDiscount"
                )
            }

            case "repairTimesDiscount": {
                return renderNumberField(
                    customer.repairTimesDiscount,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, repairTimesDiscount: validateNumber(event.target.value) }))
                    },
                    "phone"
                )
            }

            case "firstName": {
                return renderFirstName()
            }

            case "lastName": {
                return renderTextField(
                    customer.lastName,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, lastName: event.target.value }))
                    },
                    "street"
                )
            }

            case "street": {
                return renderTextField(
                    customer.street,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, street: event.target.value }))
                    },
                    "zip"
                )
            }

            case "zip": {
                return renderTextField(
                    customer.zip,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, zip: validatePhone(event.target.value) }))
                    },
                    "city"
                )
            }

            case "city": {
                return renderTextField(
                    customer.city,
                    (event) => {
                        setCustomer((prev) => ({ ...prev, city: event.target.value }))
                    },
                    "phone"
                )
            }

            default: {
                return alternativeView ? renderFirstName() : renderPhone()
            }
        }
    }

    function renderTabButtons() {
        if (alternativeView) {
            return (
                <Tabs value={activeNavIdentifier} onChange={handleNavSelect}>
                    <Tab value="firstName" label={translateText(119)} />
                    <Tab value="lastName" label={translateText(104)} />
                    <Tab value="street" label={translateText(111)} />
                    <Tab value="zip" label={translateText(112)} />
                    <Tab value="city" label={translateText(113)} />
                    <Tab value="phone" label={translateText(110)} />
                </Tabs>
            )
        }
        if (tinyWidget) {
            return (
                <Tabs size="small" value={activeNavIdentifier} onChange={handleNavSelect}>
                    <Tab value="phone" label={translateText(110)} />
                    <Tab value="email" label={translateText(109)} />
                </Tabs>
            )
        }

        if (hideDiscount) {
            return (
                <Tabs value={activeNavIdentifier} onChange={handleNavSelect}>
                    <Tab value="phone" label={translateText(110)} />
                    <Tab value="email" label={translateText(109)} />
                    <Tab value="companyName" label={translateText(108)} />
                    <Tab value="mobile" label={translateText(1308)} />
                </Tabs>
            )
        }

        return (
            <Tabs value={activeNavIdentifier} onChange={handleNavSelect}>
                <Tab value="phone" label={translateText(110)} />
                <Tab value="email" label={translateText(109)} />
                <Tab value="companyName" label={translateText(108)} />
                <Tab value="mobile" label={translateText(1308)} />
                <Tab value="sparePartsDiscount" label={translateText(554)} />
                <Tab value="repairTimesDiscount" label={translateText(1081)} />
            </Tabs>
        )
    }

    function renderEditorHead() {
        return (
            <Stack alignItems="baseline" justifyContent="space-between" direction="row" spacing={1}>
                <Typography variant={tinyWidget ? "h4" : "h3"}>{translate(178)}</Typography>
                <Stack direction={tinyWidget ? "column" : "row"} spacing={0.5}>
                    <Button onClick={onAbort}>{translate(317)}</Button>
                    <Button color="success" disabled={!isDirty} onClick={() => onChange(customer)}>
                        {translate(9)}
                    </Button>
                </Stack>
            </Stack>
        )
    }

    return (
        <Stack spacing={1}>
            {renderEditorHead()}
            <Box>
                {renderTabButtons()}
                {renderActivePageContent()}
            </Box>
            <Typography variant="body3">{translateText(1082)}</Typography>
        </Stack>
    )
}
