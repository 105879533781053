import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ArticleReference, RequestArticleDetailsPayload, SearchFilters, channel } from "@tm/models"
import { getBundleParams } from "../../../../utils"
import { openPartDetailsInModal, openPartsSearch } from "../../../../utils/modalHelper"
import { ReferenceNumber } from "./ReferenceNumber"

type Props = {
    references: ArticleReference[]
    searchFilter?: SearchFilters
    productGroupId: number
    supplierId: number
    onReferenceClicked?(): void
    shouldOpenDetails?: boolean
}

export function ReferenceNumbers({ references, searchFilter, productGroupId, supplierId, onReferenceClicked, shouldOpenDetails }: Props) {
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const params = useParams()

    const ref = useRef<HTMLDivElement>(null)
    const [minWidth, setMinWidth] = useState<number>()
    useEffect(() => {
        if (!ref.current?.children?.length) {
            return
        }

        // The minimum width is calculated by determining the maximum value of "clientWidth" of all children elements
        setMinWidth(Array.from(ref.current.children).reduce((prev, cur) => Math.max(prev, cur?.clientWidth ?? 0), 0))
    }, [])

    function handleReferenceSearch(reference: ArticleReference) {
        onReferenceClicked?.()

        const { partsRoutes } = getBundleParams()

        if (shouldOpenDetails) {
            const request: RequestArticleDetailsPayload = {
                article: {
                    productGroupId: reference.productGroup?.id ?? productGroupId,
                    supplierId,
                    supplierArticleNo: reference.referenceNo,
                },
            }

            if (workTaskId) {
                channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", request)
            } else {
                openPartDetailsInModal(request, params, partsRoutes)
            }
        } else {
            openPartsSearch(
                {
                    forceReload: true,
                    direct: {
                        query: reference.referenceNo,
                        searchFilter: searchFilter ? searchFilter | SearchFilters.TradeReference : undefined,
                    },
                },
                params,
                partsRoutes
            )
        }
    }

    return (
        <div ref={ref}>
            {references.map((reference) => (
                <ReferenceNumber
                    key={reference.referenceNo}
                    reference={reference}
                    tooltip={translateText(shouldOpenDetails ? 43 : 389)}
                    minWidth={minWidth}
                    onClick={handleReferenceSearch}
                />
            ))}
        </div>
    )
}
