import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import deepAssign from "deep-assign"
import color from "tinycolor2"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { opacity } = getStyleTheme()

    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&--selected": {
                transform: "translateY(1.2rem)",
                backgroundColor: "#fff",
            },
            "&:not(&--selected)": {
                $nest: {
                    "&:hover": {
                        borderLeftColor: color("#fcc41b").setAlpha(opacity.primary).toRgbString(),
                    },
                },
            },
        },
    }

    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}

export function getOverflowMenuStyles(baseStyles: NestedCSSProperties) {
    const themeStyles: NestedCSSProperties = {
        height: "calc(4em + 10px)",
        marginBottom: "-10px",
        $nest: {
            "&__main__inner": {
                height: "4em",
            },
        },
    }

    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
