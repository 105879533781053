import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Paging } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { useMemo } from "react"
import { QueryFunctionContext, QueryKey, useInfiniteQuery, useMutation, useQueryClient } from "react-query"
import { CisFilters } from "../../business/model"
import { ShowBackordersRequest, ShowBackordersResponse } from "../model"
import * as Data from "../repositories/cis/backorders"
import { useReloadBackordersCounts } from "./useBackordersCount"

type PageParam = Paging & { continuationPoint?: string }

function showBackorders(filters: CisFilters | undefined, pageParam: PageParam | undefined, pageSize: number, customerNo: string | undefined) {
    const showBackordersRequest: ShowBackordersRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        continuationPoint: pageParam?.continuationPoint,
        customerNo,
    }

    if (filters) {
        const { dateFrom, dateTo, query } = filters

        showBackordersRequest.dateFrom = dateFrom
        showBackordersRequest.dateTo = dateTo
        showBackordersRequest.query = query
    }

    return Data.showBackorders(showBackordersRequest)
}

const KEY = "cis_useBackorders"
export function useBackorders(useFilters: boolean, filters: CisFilters | undefined, pageSize = 150) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const enabled = enableServiceCalls && (!useFilters || !!filters)

    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage } = useInfiniteQuery(
        [KEY, telesalesCustomerNo, pageSize, filters, enabled],
        ({ pageParam }: QueryFunctionContext<QueryKey, PageParam>) => {
            return showBackorders(filters, pageParam, pageSize, telesalesCustomerNo)
        },
        {
            enabled,
            getNextPageParam: (lastPage, allPages): PageParam | undefined => {
                if (lastPage?.continuationPoint && lastPage.continuationPoint !== allPages[allPages.length - 2]?.continuationPoint) {
                    return {
                        pageIndex: lastPage.pageIndex + 1,
                        pageSize: lastPage.pageSize,
                        continuationPoint: lastPage.continuationPoint,
                    }
                }
            },
        }
    )

    const backorders = useMemo(() => {
        return data?.pages.flatMap((page) => page?.backorders).filter(notUndefinedOrNull)
    }, [data?.pages])

    return {
        backorders,
        backordersLoading: isLoading,
        backordersLoadNextPage: hasNextPage ? fetchNextPage : undefined,
        backordersNextPageLoading: isFetchingNextPage,
    }
}

export function useDeleteBackorder() {
    const queryClient = useQueryClient()
    const { resetBackorderCounts } = useReloadBackordersCounts()
    const mutation = useMutation(Data.deleteBackorder, {
        onSuccess: (_, request) => {
            queryClient.setQueriesData<{ pages: Array<ShowBackordersResponse> | undefined }>(KEY, (prev) => {
                resetBackorderCounts()
                return {
                    ...prev,
                    pages: prev?.pages?.map((page) => ({
                        ...page,
                        backorders: page.backorders.filter((b) => b !== request.backorder),
                    })),
                }
            })
        },
    })

    return { deleteBackorder: mutation.mutateAsync, isDeleting: mutation.isLoading }
}
