import { getStoredAuthorization, ajax } from "@tm/utils"
import { getCisServiceUrl } from "../.."
import { FindInvoicesRequest, FindInvoicesResponse } from "../../model"

function getServiceUrl() {
    return `${getCisServiceUrl()}/Invoices`
}

export async function findInvoices(body: FindInvoicesRequest) {
    const url = `${getServiceUrl()}/FindInvoices`
    const authorization = getStoredAuthorization()
    return ajax<FindInvoicesResponse>({ url, body, authorization }, undefined, undefined, true)
}
