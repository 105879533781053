import { Box, BoxProps, styled } from "@tm/components"

type WrapperProps = BoxProps & {
    centeredContent?: boolean
}

const Wrapper = styled(Box)<WrapperProps>(({ centeredContent }) => ({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    ...(centeredContent && {
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    }),
}))

export default Wrapper
