import { RegisteredModels, ViewState } from "@tm/models"
import { Container } from "@tm/nexus"

type QueryHistoryViewState = ViewState<undefined | { history: Array<string> }>
const HISTORYLIMIT = 9

export function getQueryHistory(): Promise<Array<string>> {
    return Container.getInstance<QueryHistoryViewState>(RegisteredModels.ViewState)
        .subscribe("SEARCH_HISTORY")
        .load()
        .then(
            (response) => response.value?.history?.slice(0, HISTORYLIMIT) || [],
            () => []
        )
}

export function addQueryToHistory(query: string) {
    query = query.trim()
    getQueryHistory().then((history) => {
        const idx = history.findIndex((x) => x.toLowerCase() === query.toLowerCase())

        if (idx === 0) {
            return
        }
        let newHistory = [...history]
        if (idx !== -1) {
            newHistory.splice(idx, 1)
        }
        newHistory.unshift(query)
        newHistory = newHistory.slice(0, HISTORYLIMIT)

        const collection = Container.getInstance<QueryHistoryViewState>(RegisteredModels.ViewState)
        const subscription = collection.subscribe("SEARCH_HISTORY")
        subscription.save && subscription.save({ key: "SEARCH_HISTORY", value: { history: newHistory } })
    })
}

export function removeQueryFromHistory(query: string, index: number): Promise<string[]> {
    return new Promise((resolve) => {
        query = query.trim()
        getQueryHistory().then((history) => {
            const newHistory = history.filter((queryItem, qIndex) => {
                return queryItem.toLowerCase() !== query.toLowerCase() && index !== qIndex
            })

            const collection = Container.getInstance<QueryHistoryViewState>(RegisteredModels.ViewState)
            const subscription = collection.subscribe("SEARCH_HISTORY")
            subscription.save && subscription.save({ key: "SEARCH_HISTORY", value: { history: newHistory } })

            resolve(newHistory)
        })
    })
}
