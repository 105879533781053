/* eslint-disable react/prefer-stateless-function */
import * as React from "react"
import { Provider } from "react-redux"
import { Store } from "redux"

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(component: React.ComponentType<Omit<P, keyof StoreModel>>): React.ComponentType<P> {
    return class extends React.Component<P> {
        render() {
            const { store } = this.props
            return React.createElement(Provider, { store }, React.createElement(component, this.props))
        }
    }
}
