import { ArticleInfoType } from "@tm/models"
import { useArticlesByArticleNumbersWithOptionalVehicle } from "@tm/utils"
import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { mapPartItemsToArticleIdentifiers } from "../../../mapper/ArticleIdentifier"
import { PartItem } from "../../../model"

export function useCatalogArticles(partItems: PartItem[], enabled?: boolean) {
    const { language } = useLocalization()
    const request = useMemo(() => {
        const catalogParts = partItems.filter((partItem) => partItem.articleInformation.articleInfoType === ArticleInfoType.TecdocArticle)
        return mapPartItemsToArticleIdentifiers(catalogParts)
    }, [partItems])

    const { articles } = useArticlesByArticleNumbersWithOptionalVehicle(enabled ? request : undefined, language)

    return articles ?? []
}
