import { LoginResponse, SindriCredentials } from "../../../models"
import { MainSlice } from "./model"

export function setSindriToken(state: MainSlice, sindriToken: string): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            sindriToken,
        },
    }
}

export function setShowLoginDialog(state: MainSlice, showLoginDialog: boolean): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            showLoginDialog,
        },
    }
}

export function setSindriCredentials(state: MainSlice, sindriCredentials: SindriCredentials): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            sindriCredentials,
        },
    }
}

export function sindriLogin(state: MainSlice, sindriCredentials: SindriCredentials, loginResponse: LoginResponse): Partial<MainSlice> {
    const expirationTime = new Date().getTime() + loginResponse.expires_in * 1000
    return {
        main: {
            ...state.main,
            sindriCredentials,
            sindriToken: loginResponse.access_token,
            tokenExpirationTime: expirationTime,
            showLoginDialog: false,
        },
    }
}

export function setSelectedJob(state: MainSlice, jobId: string): Partial<MainSlice> {
    return {
        main: {
            ...state.main,
            selectedJob: jobId,
        },
    }
}
