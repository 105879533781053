let bundleParams: {
    dmsServiceUrl: string
}

export const version = {
    name: "birner",
    version: "0.1.0",
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function initTraderBirnerBundle(params: any) {
    bundleParams = params
    return bundleParams
}
