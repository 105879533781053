import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { withRouter, RouteComponentProps, renderRoute, Dictionary } from "@tm/utils"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { Dropdown, SubTitle } from "@tm/controls"

type Props = RouteComponentProps & {
    title?: string
    icon?: string
    disabled?: string | boolean
    className?: string
    items: Dictionary<NavigationItemProps>
}

type NavigationItemProps = {
    sort?: number
    name?: string
    route: string
    disabled?: string | boolean
    disabledTooltip?: string
    params: { [key: string]: any }
    className?: string
}

const translationRegex = /\{\{(.*?)\}\}/

function ToolbarNavigationDropdownComponent(props: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { workTask } = useWorkTask() ?? {}

    const options = getOptions()
    const [selectedItem, setSelectedItem] = useState<NavigationItemProps | undefined>(options.find((x) => !x.disabled) || options.first())

    const { items, history, match } = props
    let { title, disabled } = props

    function handleChangeItem(item: NavigationItemProps) {
        if (item.disabled) {
            return
        }

        setSelectedItem(item)
        navigateTo(item)
    }

    function navigateTo(item: NavigationItemProps) {
        const route = getUrl(item)
        route && history.push(route)
    }

    function getDisabled(disabled: string | boolean | undefined): boolean {
        if (!disabled) {
            return false
        }

        let disabledFunction: Function

        try {
            disabledFunction = eval(`[${disabled}][0]`)
        } catch {
            return false
        }

        if (typeof disabledFunction != "function") {
            return false
        }

        return disabledFunction(userContext, workTask?.vehicle, workTask?.customer)
    }

    function getUrl(item: NavigationItemProps): string | undefined {
        const { params } = match
        let createUrl: Function

        try {
            createUrl = eval(`[${item.route}][0]`)
        } catch {
            return renderRoute(item.route, params)
        }

        if (typeof createUrl != "function") {
            return renderRoute(item.route, params)
        }

        const route = createUrl(userContext, workTask?.vehicle, workTask?.customer)

        return renderRoute(route, params)
    }

    function getOptions() {
        if (!items) {
            return []
        }

        return Object.keys(items)
            .map((x) => {
                return {
                    ...items[x],
                    disabled: getDisabled(items[x].disabled),
                    disabledTooltip: translateText(216),
                }
            })
            .orderBy((x) => x.sort || 0)
            .filter((x) => !!x)
    }

    disabled = getDisabled(disabled) || !options.length || options.every((x) => x.disabled)

    let className = "bundle-misc toolbar toolbar-navigation-dropdown "
    if (props.className) {
        className += props.className
    }

    if (title) {
        title = title.replace(translationRegex, (s, num) => translateText(num))
    }

    return (
        <div className={className}>
            <div className="toolbar__inner">
                {title && <SubTitle size="xs">{title}</SubTitle>}
                <div title={disabled ? translateText(216) : undefined}>
                    <Dropdown items={options} itemView={NavigationItem} onChange={handleChangeItem} value={selectedItem} disabled={disabled} />
                </div>
            </div>
        </div>
    )
}

export default withRouter(ToolbarNavigationDropdownComponent)

function NavigationItem(props: NavigationItemProps) {
    return <div title={props.disabled ? props.disabledTooltip : undefined}>{props.name}</div>
}
