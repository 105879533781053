import { Box, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    textId?: number
}
export function NoResultHint({ textId }: Props) {
    const { translate } = useLocalization()

    return (
        <Box mt={2} display="flex" justifyContent="center">
            <Typography> {textId ? translate(textId) : translate(279)}</Typography>
        </Box>
    )
}
