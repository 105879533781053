import { Box, Dialog, Stack, Typography, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    showNotUpToDateDialog: boolean
    dialogText: string
    onConfirmDialog(): void
}

export default function NotUpToDateDialog({ showNotUpToDateDialog, dialogText, onConfirmDialog }: Props) {
    const { translateText } = useLocalization()

    return (
        <Dialog open={showNotUpToDateDialog} position="top" skin="warning">
            <Box display="flex">
                <Stack spacing={1}>
                    <Typography variant="h2">{translateText(13579)}</Typography>
                    <Typography>{dialogText}</Typography>
                </Stack>
                <Box display="flex" alignItems="flex-start">
                    <Button size="large" color="success" onClick={onConfirmDialog}>
                        {translateText(316)}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    )
}
