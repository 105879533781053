import { Vehicle } from "@tm/models"
import { equals } from "@tm/utils"

export const isVehicleChanged = (newVeh: Vehicle, oldVeh?: Vehicle) => {
    if (!oldVeh) {
        return true
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { dataAvailabilities: _, ...newVehWithoutAvailabilites } = newVeh
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { dataAvailabilities: __, ...oldVehWithoutAvailabilites } = oldVeh
    return !equals(newVehWithoutAvailabilites, oldVehWithoutAvailabilites)
}
