import { useLocalization } from "@tm/localization"
import { GetMainServicesRequest } from "@tm/models"
import { useQuery } from "react-query"
import { loadMainServices } from "../repositories/repair-times"

export function useMainServices(request: GetMainServicesRequest | undefined) {
    const { languageId } = useLocalization()
    const { data, isLoading, isIdle } = useQuery(["repair-times__main-services", request, languageId], () => request && loadMainServices(request), {
        enabled: !!request,
        staleTime: 30 * 60 * 1000, // 30 minutes
    })

    return { mainServices: data?.mainServices, repairTimeDivision: data?.repairTimeDivision, isLoading, isIdle }
}
