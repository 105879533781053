import { IBundle } from "@tm/morpheus"
import PartnerWidget from "./components/partner-widget"
import PartnerInfoComponent from "./components/partner-info"
import PartnerNotes from "./components/partner-notes"
import StatusInfo from "./components/status-info"
import WorktaskHistory from "./components/worktask-history"
import ConflictResolver from "./components/conflict-resolver"
import PartnerSearchWidget from "./components/partner-search-widget"
import Manager from "./components/manager"
import CreateButton from "./components/create-button"
import { initTelesalesBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        PartnerWidget,
        WorktaskHistory,
        ConflictResolver,
        PartnerSearchWidget,
        PartnerInfoComponent,
        PartnerNotes,
        Manager,
        CreateButton,
        StatusInfo,
    ],
    init: initTelesalesBundle,
}

export default bundle
