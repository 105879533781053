import { useReducer, useState, useEffect, useCallback, useMemo, SyntheticEvent } from "react"
import { Toolbar, SearchField } from "@tm/controls"
import { getLocalization } from "@tm/localization"
import { encodeUniqueId, useLoadWorkTaskNote, uniqueId, parseQueryString } from "@tm/utils"
import {
    Box,
    AutocompleteField as ComponentsSearchField,
    TextField,
    StandardTextFieldProps,
    styled,
    filledInputClasses,
    AutocompleteValue,
    Typography,
    Stack,
    Loader,
    Select,
    MenuItem,
    SelectChangeEvent,
    selectClasses,
    getContrastText,
} from "@tm/components"
import { useHistory, useLocation } from "react-router"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { reduce, initialState, searchCurrentActiveCaller, WorktaskHistoryState, MAX_WORKTASK_PAGE_SIZE, DEFAULT_FILTER_DATE_OFFSET } from "./business"
import { WorktaskInfo, Sort, Employee, Outlet } from "../../data/repositories"
import { findParameterInTelesalesModule, getStatusText, subtractDaysFromDate } from "../../helpers"
import { useFindWorktask } from "../../data/hooks/useShowWorktask"
import { useFindEmployee } from "../../data/hooks/useEmployees"
import { useFindSalesOutlet } from "../../data/hooks/useFindSalesOutlet"
import ActiveCustomer from "../shared/active-customer"
import { DateFilter } from "./components/filter/date"
import { useScrollIntoView } from "../../hooks/useScrollIntoView"
import WorkTasksTable from "./components/WorkTasksTable"
import { VideoInfo } from "./components/VideoInfo"
import PartnerSearch from "../shared/partner-search"

type DropdownItem = { key?: number; value: string }
const renderStatusDropdownItem = (item: DropdownItem) => <div>{item.value}</div>

type Props = {
    allowOpenClosedWorktask?: boolean
    checkVoucherTypeId?: boolean
    externalSystemId?: number
    sentOrderRoute?: string
    sentOrderVoucherTypeId?: number
    showVideoInfo?: boolean
    showPartnerSearch?: boolean
    partnerSearchInTwoColumns?: boolean
    partnerSearchAlignedLeft?: boolean
}

const WORKTASK_TABLE_NAME = "telesales-worktask-history"

const StyledSearchField = styled(ComponentsSearchField)(() => ({
    width: "250px",

    [`.${filledInputClasses.root}`]: {
        height: "45px",
    },
})) as typeof ComponentsSearchField

const StyledSelect = styled(Select)(({ theme, color }) => {
    const backgroundColor = theme.overwrites?.components?.button?.primary?.backgroundColor ?? theme.palette.grey[300]
    const paletteMainColor = color && theme.palette[color]?.main

    return {
        [`.${filledInputClasses.input}`]: {
            ":focus": {
                backgroundColor,
            },
        },
        [`.${selectClasses.iconOpen}`]: {
            color: getContrastText(paletteMainColor ?? backgroundColor),
        },
    }
})

export default function WorktaskHistory(props: Props) {
    const {
        allowOpenClosedWorktask,
        checkVoucherTypeId,
        externalSystemId,
        sentOrderRoute,
        sentOrderVoucherTypeId,
        showVideoInfo,
        showPartnerSearch,
        partnerSearchInTwoColumns,
        partnerSearchAlignedLeft,
    } = props
    const history = useHistory()
    const [
        {
            worktasks,
            worktasksLoading,
            query,
            employee,
            status,
            outlet,
            currentCallers,
            sort,
            currentCallerLoading,
            dateFilter,
            activePage,
            endOfPages,
        },
        dispatch,
    ] = useReducer(reduce, initialState)
    const { scrollWithDelay } = useScrollIntoView()
    const { findWorktask, isLoading, fetched } = useFindWorktask()
    const { findEmployee } = useFindEmployee()
    const { loadOutlet } = useFindSalesOutlet()
    const { translateText } = getLocalization()
    const workTaskIds = useMemo(() => worktasks?.map((item) => item.id) ?? [], [worktasks])
    const { workTaskNote } = useLoadWorkTaskNote(workTaskIds)
    const { userContext } = useUser() ?? {}
    const [options, setOptions] = useState<Employee[]>([])
    const [outlets, setOutlets] = useState<Outlet[]>([])
    const userHasCooperationId = useMemo(() => !!userContext?.principal?.cooperationId, [userContext])
    const { createWorkTask } = useWorkTask() || {}
    const location = useLocation()
    const urlSearchQuery = parseQueryString(location?.search)?.query?.toString()
    // ExternalSystem with Type 3 = ExternalCatalog and DisplayBehvaior 530 = BestellHistorie
    const externalSystem = userContext.externalModules?.find(
        (e) =>
            e.parameter?.find((p) => p.key === "ExternalSystemType" && p.value === "3") &&
            e.parameter?.find((p) => p.key === "DisplayBehavior" && p.value === "530")
    )

    const scrollToTop = useCallback(() => {
        try {
            const tableElement = document.querySelector(`.${WORKTASK_TABLE_NAME}_body`)
            tableElement && scrollWithDelay(tableElement)
        } catch (_) {
            /* do nothing */
        }
    }, [scrollWithDelay])

    const searchForWorktasks = useCallback(
        (
            query?: string,
            employee?: Employee,
            outlet?: Outlet,
            status?: number,
            dates?: WorktaskHistoryState["dateFilter"],
            sort?: Sort,
            page = 1
        ) => {
            if (isLoading) {
                return false
            }
            scrollToTop()
            dispatch({
                type: "WORKTASKS_LOADING",
                payload: {
                    query: query || urlSearchQuery,
                    employee: outlet?.id || !userHasCooperationId ? employee : undefined,
                    outlet,
                    status,
                    sort,
                    dateFilter: dates,
                    activePage: page,
                },
            })

            findWorktask({
                query: query || urlSearchQuery,
                employeeId: outlet?.id || !userHasCooperationId ? employee?.id : undefined,
                cooperationMemberId: outlet?.id,
                status,
                sort,
                dateFilter: dates,
                page,
                pageSize: MAX_WORKTASK_PAGE_SIZE,
            }).then((response) => {
                dispatch({
                    type: "WORKTASKS_LOADED",
                    payload: response.worktasks,
                    activePage: response.pageIndex,
                    endOfPaging: response.worktasks.length !== MAX_WORKTASK_PAGE_SIZE,
                })
            })
        },
        [dispatch, findWorktask, dateFilter, isLoading, userHasCooperationId, worktasks, scrollToTop]
    )

    const loadInitialWorktaskList = async () => {
        const dateFilterPreset = +(findParameterInTelesalesModule("DateFilterPreset", userContext) || DEFAULT_FILTER_DATE_OFFSET)
        let outlet: Outlet | undefined
        const employee: Employee | undefined = undefined

        try {
            if (userHasCooperationId && !urlSearchQuery) {
                outlet = await loadOutlet().then((response) => response.find((r) => r.default) || undefined)
            }
        } finally {
            searchForWorktasks(undefined, employee, outlet, undefined, { from: subtractDaysFromDate(new Date(), dateFilterPreset), to: new Date() })
        }
    }

    useEffect(() => {
        loadInitialWorktaskList()
    }, [])

    useEffect(() => {
        if (currentCallers?.[0]) {
            searchForWorktasks(currentCallers[0].customerNumberToShow || currentCallers[0].partnerNo, undefined, undefined, undefined, dateFilter)
        }
    }, [currentCallers, dateFilter])

    const handleActiveCustomerClick = useCallback(() => searchCurrentActiveCaller(dispatch), [])

    useEffect(() => {
        findEmployee(outlet?.id ? { cooperationMemberIds: [outlet.id] } : {}).then((list) =>
            setOptions([{ name: translateText(1205), id: "" }].concat(list))
        )
    }, [findEmployee, outlet])

    useEffect(() => {
        loadOutlet().then((list) => setOutlets([{ id: "", name: translateText(1205), default: true }].concat(list)))
    }, [loadOutlet])

    async function handleClickCreateNewWorktask(workTask: WorktaskInfo, vehicle?: string) {
        const workTaskId = uniqueId()
        await createWorkTask?.({
            workTaskId,
            customer: workTask.customerId,
            vehicle,
        })
        history.push(`/${encodeUniqueId(workTaskId)}`)
    }

    const states: DropdownItem[] = [
        { value: translateText(1205) },
        { key: 1, value: getStatusText(1) },
        { key: 2, value: getStatusText(2) },
        { key: 4, value: getStatusText(4) },
    ]

    function handleStartSearch(query: string) {
        searchForWorktasks(query, undefined, undefined, undefined, dateFilter)
    }

    function handleResetSearch() {
        searchForWorktasks(undefined, undefined, outlet, status, dateFilter)
    }

    function handleChangeEmployee(employee?: Employee) {
        searchForWorktasks(query, employee, outlet, status, dateFilter)
    }

    const handleOutletChange = useCallback(
        (outlet: Outlet) => {
            searchForWorktasks(query, undefined, outlet, status, dateFilter)
        },
        [query, employee, status, dateFilter, searchForWorktasks]
    )

    function handleChangeStatus(item: DropdownItem) {
        searchForWorktasks(query, employee, outlet, item.key, dateFilter)
    }

    const handleSelectChange = (e: SelectChangeEvent<unknown>) => {
        const dropDownItem = states.find((state) => state?.value === e.target.value) as DropdownItem
        handleChangeStatus(dropDownItem)
    }

    const handleDateFilterChange = useCallback(
        (from: Date, to: Date) => {
            searchForWorktasks(query, employee, outlet, status, { from, to }, sort)
        },
        [query, employee, outlet, status, sort, searchForWorktasks]
    )

    const handleLoadMore = () => {
        if (fetched && worktasks?.length && !endOfPages && !worktasksLoading) {
            searchForWorktasks(query, employee, outlet, status, dateFilter, sort, activePage + 1)
        }
    }

    const modifiers: Array<"no-border"> = ["no-border"]

    return (
        <Stack flex={1} gap={4} position="relative">
            {showVideoInfo && (
                <Box position="absolute">
                    <VideoInfo />
                </Box>
            )}
            {showPartnerSearch && (
                <Box display="grid" gridTemplateColumns={partnerSearchAlignedLeft ? undefined : "33.33% 1fr"} gap={2}>
                    <Box sx={{ gridColumnStart: partnerSearchAlignedLeft ? 1 : 2 }} ml={partnerSearchAlignedLeft ? 1 : 0}>
                        <PartnerSearch searchHistoryInNewColumn={partnerSearchInTwoColumns} />
                    </Box>
                </Box>
            )}
            <Stack spacing={2} m={1} overflow="hidden">
                <Stack spacing={2} direction="row" width="25%" alignItems="center">
                    <Typography variant="h2">{translateText(369)}</Typography>
                    {worktasksLoading && <Loader size="extrasmall" />}
                </Stack>
                <Stack direction="row" flexWrap="wrap">
                    <Toolbar title={translateText(135)} modifiers={modifiers}>
                        <SearchField
                            value={query || urlSearchQuery}
                            autoComplete="off"
                            showSearch
                            showClear={!!query || !!urlSearchQuery}
                            size="xl"
                            placeholder={translateText(1758)}
                            onChangeConfirm={handleStartSearch}
                            onChangeReset={handleResetSearch}
                        />
                        {!urlSearchQuery && (
                            <ActiveCustomer
                                onClick={handleActiveCustomerClick}
                                label={translateText(12811)}
                                isLoading={!!currentCallerLoading}
                                size="large"
                            />
                        )}
                    </Toolbar>
                    {userHasCooperationId && (
                        <Toolbar title={translateText(28)} modifiers={modifiers}>
                            <StyledSearchField<Outlet | string, false, true, false>
                                value={outlet?.name ?? translateText(1205)}
                                onChange={(e: SyntheticEvent, value: AutocompleteValue<Outlet | string, false, true, false>) => {
                                    if (typeof value !== "string") {
                                        handleOutletChange(value)
                                    }
                                }}
                                getOptionLabel={(option: Outlet | string) => (typeof option === "string" ? option : option.name)}
                                options={outlets}
                                renderInput={(params: StandardTextFieldProps) => (
                                    <TextField {...params} placeholder={translateText(1205)} size="extralarge" />
                                )}
                            />
                        </Toolbar>
                    )}
                    <Toolbar title={translateText(341)} modifiers={modifiers}>
                        <StyledSearchField<Employee | string, false, true, false>
                            disabled={!outlet && userHasCooperationId}
                            value={employee?.name ?? translateText(1205)}
                            onChange={(e: SyntheticEvent, value: AutocompleteValue<Employee | string, false, true, false>) => {
                                if (typeof value !== "string") {
                                    handleChangeEmployee(value)
                                }
                            }}
                            getOptionLabel={(option: Employee | string) => (typeof option === "string" ? option : option.name)}
                            options={options}
                            renderInput={(params: StandardTextFieldProps) => (
                                <TextField {...params} placeholder={translateText(1205)} size="extralarge" />
                            )}
                        />
                    </Toolbar>
                    <Toolbar title={translateText(1759)} modifiers={modifiers}>
                        <StyledSelect onChange={handleSelectChange} defaultValue={states[0].value} iconOnTheRight>
                            {states.map((state) => (
                                <MenuItem key={state.value} value={state.value}>
                                    {state.value}
                                </MenuItem>
                            ))}
                        </StyledSelect>
                    </Toolbar>
                    <Toolbar title={translateText(12993)} modifiers={modifiers}>
                        <DateFilter onChangeDate={handleDateFilterChange} fromDate={dateFilter?.from} toDate={dateFilter?.to} />
                    </Toolbar>
                </Stack>
                <WorkTasksTable
                    allowOpenClosedWorktask={allowOpenClosedWorktask}
                    checkVoucherTypeId={checkVoucherTypeId}
                    externalSystemId={externalSystemId || externalSystem?.id}
                    sentOrderRoute={sentOrderRoute}
                    sentOrderVoucherTypeId={sentOrderVoucherTypeId}
                    userContext={userContext}
                    workTasks={worktasks}
                    workTaskNotes={workTaskNote}
                    onCreateNewWorkTask={handleClickCreateNewWorktask}
                    onScrollBottom={handleLoadMore}
                    onSearchForWorkTasks={(newSort) => searchForWorktasks(query, employee, outlet, status, dateFilter, newSort)}
                />
            </Stack>
            <Box margin="0 auto">{!!worktasks?.length && worktasksLoading && <Loader size="small" />}</Box>
        </Stack>
    )
}
