import { useCallback, useEffect, useMemo, useState } from "react"
import { Badge, Box, Button, Icon, Tip, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { Toolbar } from "@tm/controls"
import { useHistory, useParams } from "react-router"
import Morpheus from "@tm/morpheus"
import { ECalcOrigin } from "@tm/models"
import { changeStepWithHistory, useFastServiceStore } from "../../data"
import { useRepairTimes } from "../../helpers/hooks/useRepairTimes"
import { MatchParams, viewTranslate } from "../../models"
import { PupUpButton } from "../_shared"
import { FullScreenType } from "../module-manager/business/model"
import FastCalcSelection from "../_shared/modal/fastCalcSelection"
import { getBundleParams } from "../../utils"
import { TopProductGroup } from "../../data/models"
import { mapProductGroupIds } from "../../helpers"
import { isTouchDevice } from "../../helpers/isTouchDevice"

declare let document: Document & FullScreenType

type Props = {
    route: string
    onFullScreenChange: () => void
}

export default function NavigationComponent({ route, onFullScreenChange }: Props) {
    const active = useFastServiceStore((state) => state.navigation.active)
    const isFullScreen = useFastServiceStore((state) => state.isFullScreen)
    const { translateText } = useLocalization()
    const { totalRepairTimesFormatted, repairTimesIncludesString } = useRepairTimes()
    const history = useHistory()
    const matchParams = useParams<MatchParams>()
    const { view } = matchParams
    const touchDevice = isTouchDevice()
    const selectedItems = useFastServiceStore((state) => state.worksState.selectedItems)
    const [showFastCalcModal, setShowFastCalcModal] = useState(false)
    const defectGenarts = useFastServiceStore((state) => state.defectGenarts.groupBy((x) => x.id))

    const recordedDefects: number = useMemo(
        () =>
            Object.values(selectedItems)
                .flatMap((item) => Object.values(item))
                .filter((a) => a.selectedStatusButton === "defectButton")?.length,
        [selectedItems]
    )

    const hideSaveButton = useMemo(
        () =>
            ["start", "maintenancePlan", "technician", "maintenanceComplete", "tester", "maintenanceReview", "testComplete", "end"].some(
                (tabName) => tabName === view
            ),
        [view]
    )

    useEffect(() => {
        if (active && active !== matchParams.view) {
            const rrparams = { ...matchParams, view: active }
            const url = renderRoute(route, rrparams)

            history.push(url)
        } else if (!active && active !== matchParams.view) {
            changeStepWithHistory("start")
        }
    }, [active])

    const handleReset = () => {
        useFastServiceStore.getState().reInitializeStore()
    }

    const handleSave = () => {
        view && changeStepWithHistory(view, true)
    }

    const handleRequestCalculation = useCallback((ids: number[], topProductGroups: TopProductGroup[]) => {
        if (!getBundleParams().calculatorRoute) {
            return
        }

        const route = renderRoute(getBundleParams().calculatorRoute!, {
            ...matchParams,
            productGroupIds: mapProductGroupIds(topProductGroups),
            origin: ECalcOrigin.FastService,
        })

        setShowFastCalcModal(false)

        Morpheus.showView("1", route)
    }, [])

    const checkTab = useMemo(() => ["maintenanceReview", "inspection"].some((tabName) => tabName === view), [view])

    const showCalculateDefectsButton = checkTab && Object.values(defectGenarts).length > 0

    return (
        <>
            <FastCalcSelection open={showFastCalcModal} onOutsideClick={() => setShowFastCalcModal(false)} onCalculation={handleRequestCalculation} />
            <ModuleToolar>
                {!touchDevice && <FastServiceLogo className="logo-fast-service" />}
                <StyledHeader>{view && translateText(viewTranslate.get(view)!.toString())} </StyledHeader>

                <PupUpButton />

                <StyledToolbar title={translateText(361)}>
                    <Button startIcon={<Icon name="refresh" />} onClick={handleReset}>
                        {touchDevice ? "" : translateText(48)}
                    </Button>

                    <Button
                        disabled={!(document.fullscreenEnabled || document.webkitFullscreenEnabled)}
                        startIcon={<Icon name={`${isFullScreen ? "fullscreen-exit" : "fullscreen"}`} />}
                        onClick={onFullScreenChange}
                    >
                        {touchDevice ? "" : translateText(12604)}
                    </Button>
                </StyledToolbar>
                {totalRepairTimesFormatted && (
                    <Typography
                        sx={{
                            marginLeft: "auto",
                            fontWeight: 600,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <TypographyContent>
                            {`${translateText(12677)} ${totalRepairTimesFormatted} ${repairTimesIncludesString}`}
                            {recordedDefects > 0 && (
                                <>
                                    {`${translateText(13693)}`}
                                    <SumOfDefects>
                                        {translateText(13692)}
                                        <StyledBadge badgeContent={<Box style={{ color: "white" }}>{recordedDefects}</Box>} />
                                    </SumOfDefects>
                                </>
                            )}
                        </TypographyContent>
                    </Typography>
                )}
                {(showCalculateDefectsButton || !hideSaveButton) && (
                    <StyledSaveButtonWrapper>
                        {showCalculateDefectsButton && (
                            <Button
                                color="highlight"
                                sx={{ height: "max-content", alignSelf: "center", marginRight: "1em" }}
                                onClick={() => setShowFastCalcModal(true)}
                            >
                                {translateText(13034)}
                            </Button>
                        )}
                        {!hideSaveButton && (
                            <>
                                <Tip text={translateText(13502)} enableTipOnTouchDevice={touchDevice} />
                                <Button color="success" sx={{ height: "max-content", alignSelf: "center", marginLeft: "1em" }} onClick={handleSave}>
                                    {translateText(9)}
                                </Button>
                            </>
                        )}
                    </StyledSaveButtonWrapper>
                )}
            </ModuleToolar>
        </>
    )
}

const TypographyContent = styled(Box)({
    display: "flex",
    flexDirection: "column",
})

const SumOfDefects = styled(Box)({
    display: "flex",
    flexDirection: "row",
    marginTop: ".5em",
})

const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
        padding: ".25rem",
        background: `${theme.colors?.dark}`,
        borderRadius: "1rem",
        border: "1px solid",
    },
    marginLeft: ".7em",
    top: "1px",
}))

const ModuleToolar = styled(Box)({
    background: "transparent",
    padding: "0 1.5rem",
    marginTop: ".5em",
    marginBottom: ".5rem",
    display: "flex",
    position: "relative",
    zIndex: "2",
    flex: "0.075",
})

const StyledHeader = styled(Box)({
    fontSize: "1.25rem",
    lineHeight: "1.2em",
    fontWeight: "400",
    opacity: ".87",
    display: "flex",
    alignItems: "center",
    padding: "0 2rem 0 0",
})

const FastServiceLogo = styled(Box)({
    height: "3.3em",
    width: "7em",
    marginRight: "1em",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
})

const StyledToolbar = styled(Toolbar)({
    "& .toolbar__content": {
        flexWrap: "wrap",
    },
})

const StyledSaveButtonWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "auto",
    marginRight: "1em",
})
