import { TyreSpecification } from "../data/models"
import { SelectedFilters } from "../data/state/tyres-wheels"
import { findAxleSpecification } from "./findAxleSpecification"

export function extractSpecificationValues(selectedSpecification: TyreSpecification, selectedFilters: SelectedFilters, sizeData: string) {
    const axleSpecification = findAxleSpecification(selectedSpecification, sizeData)
    const sizeParts = sizeData?.split(" ")
    const dimensions = sizeParts[0]?.split("/")
    const inchIndex = sizeParts?.findIndex((part) => part?.includes("R"))
    let newSelectedFilters: SelectedFilters = {
        ...selectedFilters,
        width: dimensions[0],
        height: dimensions[1],
        inch: sizeParts[inchIndex]?.replace("R", ""),
        size: sizeData,
        sizeFromSpecs: sizeData,
        axleSpecs: axleSpecification,
    }
    let findLoadIndex: string[] | null = null
    let findSpeedIndex: string[] | null = null

    for (let i = inchIndex + 1; i < sizeParts.length; i++) {
        const loadSpeedIndexMatch = sizeParts[2]?.match(".*\\d$")
        if (loadSpeedIndexMatch) {
            break
        }

        const loadMatch = sizeParts[i]?.match(/^\d+/)
        if (loadMatch) {
            findLoadIndex = loadMatch
            const speedMatch = sizeParts[i]?.match(/[A-Z]+/)
            if (speedMatch) {
                findSpeedIndex = speedMatch
            }
            break
        }
    }

    if (findLoadIndex) {
        newSelectedFilters = { ...newSelectedFilters, loadIndex: findLoadIndex[0] }
    }

    if (findSpeedIndex) {
        newSelectedFilters = { ...newSelectedFilters, speedIndex: findSpeedIndex[0] }
    }

    return newSelectedFilters
}
