import { memo, useState, useMemo, useRef, useEffect, useCallback } from "react"
import { useShowCisOptions, useUser } from "@tm/context-distribution"
import { Dropdown } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Icon, IconButton, NumberSelect, Tooltip, Box, Stack, Image, Typography, Button, Loader } from "@tm/components"
import { Item } from "@tm/models"
import { ReturnItem } from "../../../data/model"
import PartsNote from "./parts-note"
import PartActions from "./part-actions"
import { CisDisplayMode } from "../../../business/model"
import { useChangeReturnItemQuantity, useChangeReturnReason, useEditReturnInfo } from "../../../data/hooks/useReturnItems"
import { useReturnReasonsFromMdm } from "../../../data/hooks/useReturnReasonsFromMdm"
import ArticleNumber from "../../_shared/ArticleNumber"

type DropdownInputViewProps = {
    id?: number
    description?: string
    isReturnInfoRequired?: boolean
}

const DropdownInputView = memo<DropdownInputViewProps>((props) => <>{props.description}</>)

type Props = {
    returnItem: ReturnItem
    isSelected: boolean
    isUpdating: boolean
    ordersUrl?: string
    cisDisplayMode?: CisDisplayMode
    fromVouchersModule?: boolean
    onOpenDetails?(): void
    onRemoveItems(ids: Array<ReturnItem>): void
    onSelectItem(itemId: string): void
    onShowOrder(voucherTypeId: number, orderId: string, voucherId?: string, subId?: string): void
    onChangeReturnStatus(items: Array<Item>, includeInReturn: boolean): void
    onResetSubmitOrder(): void
}

export default function PartsListItemComponent(props: Props) {
    const {
        returnItem,
        ordersUrl,
        cisDisplayMode,
        isSelected,
        isUpdating,
        fromVouchersModule,
        onChangeReturnStatus,
        onResetSubmitOrder,
        onOpenDetails,
    } = props
    const { id, version, returnQuantity, maxReturnQuantity, canReturn, returnableUntil, returnReasonId } = returnItem

    const { translateText, translate, date } = useLocalization()
    const { userContext } = useUser()
    const { cisOptions, cisOptionsLoading } = useShowCisOptions()
    const { returnReasonsFromMdm, returnReasonsFromMdmLoading } = useReturnReasonsFromMdm(fromVouchersModule)
    const { changeReturnItemQuantity, isReturnQuantityChanging } = useChangeReturnItemQuantity()
    const { editReturnInfo, isChangingReturnInfo } = useEditReturnInfo()
    const { changeReturnReason, isChangingReturnReason } = useChangeReturnReason()
    const [noteExpanded, setNoteExpanded] = useState<boolean>(false)
    const [isReturnInfoRequired, setIsReturnInfoRequired] = useState<boolean>(false)
    const changeQuantityTimeout = useRef<number>()
    const disabled = isUpdating || isChangingReturnInfo || isChangingReturnReason || isReturnQuantityChanging

    const disablingReason = useMemo(() => {
        return !canReturn && returnableUntil ? translateText(13370).replace("{0}", date(returnableUntil, "d")) : ""
    }, [canReturn, returnableUntil, date, translateText])

    const returnReasons = useMemo(() => {
        if (fromVouchersModule) {
            return returnReasonsFromMdm?.returnReasons
        }
        return cisOptions?.returnReasons
    }, [cisOptions?.returnReasons, returnReasonsFromMdm?.returnReasons, fromVouchersModule])

    useEffect(() => {
        if (returnReasons) {
            const commentRequired = returnReasons.filter(
                (returnReason) => returnReason.id === returnItem.returnReasonId && returnReason.isReturnInfoRequired
            )
            if (commentRequired?.length && !returnItem.returnInfo) {
                setNoteExpanded(true)
                setIsReturnInfoRequired(true)
            } else {
                setNoteExpanded(false)
                setIsReturnInfoRequired(false)
            }
        }
    }, [returnReasons, returnItem])

    const openDetails = useCallback(() => {
        onOpenDetails?.()
    }, [onOpenDetails])

    function handleShowOrderClick() {
        // SubId is 0, as requested by STG
        props.onShowOrder(returnItem.voucherTypeId, returnItem.orderNo, returnItem.voucherId, returnItem.orderNo ? "0" : undefined)
    }

    function handleChangeQuantity(item: Item, quantityValue: number) {
        if (quantityValue !== returnQuantity) {
            if (changeQuantityTimeout) {
                window.clearTimeout(changeQuantityTimeout.current)
            }

            changeQuantityTimeout.current = window.setTimeout(() => {
                changeReturnItemQuantity({ item, quantityValue }).then(onResetSubmitOrder)
            }, 250)
        }
    }

    function handleEditNote(note: string) {
        editReturnInfo({ item: { id, version }, returnInfo: note }).then(() => {
            onResetSubmitOrder()
            onChangeReturnStatus([returnItem], !!note)
        })
        setNoteExpanded(false)
    }

    function handleSelectNote() {
        setNoteExpanded(!noteExpanded)
    }

    function handleChangeReturnReason(item: Item, returnReason: DropdownInputViewProps) {
        changeReturnReason({
            item,
            returnReasonId: returnReason.id,
            returnReasonDescription: returnReason.description,
            isReturnInfoRequired: returnReason.isReturnInfoRequired ? returnReason.isReturnInfoRequired : false,
        }).then(props.onResetSubmitOrder)

        if (isReturnInfoRequired) {
            setIsReturnInfoRequired(false)
        }
    }

    function renderThumbnail() {
        const { thumbnailUrl } = returnItem
        let imageUrl = ""
        if (thumbnailUrl) {
            imageUrl = thumbnailUrl
        }

        return (
            <div className="thumbnail no-separator">
                <Image src={imageUrl} type="article" />
            </div>
        )
    }

    function renderNumbers() {
        return (
            <Stack
                sx={{ flex: "0 1 16em", minWidth: "100px", alignItems: "flex-start !important" }}
                direction={userContext?.parameter.positionChangeEArtNrHArtNr ? "column-reverse" : "column"}
            >
                {!userContext?.parameter.hideDealerPartNumber && (
                    <ArticleNumber
                        productGroupId={returnItem.productGroupId}
                        supplierArticleNumber={returnItem.dataSupplierArticleNumber}
                        supplierId={returnItem.dataSupplierId}
                        wholesalerArticleNumber={returnItem.wholesalerArticleNumber}
                        articleType="wholesaler"
                        onOpenDetails={openDetails}
                    />
                )}
                <ArticleNumber
                    productGroupId={returnItem.productGroupId}
                    supplierArticleNumber={returnItem.dataSupplierArticleNumber}
                    supplierId={returnItem.dataSupplierId}
                    wholesalerArticleNumber={returnItem.wholesalerArticleNumber}
                    articleType="supplier"
                    onOpenDetails={openDetails}
                />
            </Stack>
        )
    }

    function renderDescription() {
        return (
            <div className="description">
                <Typography variant="body2">{returnItem.productGroupName}</Typography>
                <Typography variant="body2">{returnItem.description}</Typography>
            </div>
        )
    }

    function renderOrderDate() {
        return (
            <>
                <Typography variant="body3">{translate(776)}</Typography>
                <Typography>{returnItem.orderDate && date(returnItem.orderDate, "d")}</Typography>
            </>
        )
    }

    function renderOrderNumber() {
        let orderNumber = returnItem.orderNo || undefined
        if (cisDisplayMode === "wm" && orderNumber && orderNumber.indexOf("-") > -1) {
            orderNumber = orderNumber.substring(0, orderNumber.indexOf("-"))
        }
        if (fromVouchersModule && !orderNumber && returnItem.voucherId) {
            orderNumber = returnItem.voucherId
        }

        return (
            <>
                <Typography variant="body3">{translate(cisDisplayMode !== "wm" ? 700 : 778)}</Typography>
                <Button
                    startIcon={<Icon name="partslink24" />}
                    size="small"
                    disabled={!ordersUrl || disabled || !orderNumber}
                    onClick={handleShowOrderClick}
                >
                    {orderNumber}
                </Button>
            </>
        )
    }

    function renderDeliveryNumber() {
        return (
            <>
                <Typography variant="body3">{translate(778)}</Typography>
                <Typography>{returnItem.deliveryNote}</Typography>
            </>
        )
    }

    function renderInvoiceNumber() {
        return (
            <>
                <Typography variant="body3">{translate(779)}</Typography>
                <Typography>{returnItem.invoice}</Typography>
            </>
        )
    }

    function renderQuantity() {
        const item: Item = { id, version }
        return (
            <Tooltip title={disablingReason}>
                <Box>
                    <NumberSelect
                        value={returnQuantity}
                        classNameWrapper="amount-field add-to-basket__quantity"
                        onValueChange={(amount) => handleChangeQuantity(item, amount)}
                        minAmount={1}
                        maxAmount={maxReturnQuantity}
                        disabled={disabled || !!disablingReason}
                    />
                </Box>
            </Tooltip>
        )
    }

    function renderReturnReason() {
        if (cisOptionsLoading || returnReasonsFromMdmLoading) {
            return <Loader size="extrasmall" />
        }

        if (!returnReasons?.length) {
            return null
        }

        const item: Item = { id, version }

        const reasons: Array<DropdownInputViewProps> = [{ description: translateText(1514) }]
        reasons.push(
            ...returnReasons.map((returnReason) => ({
                id: returnReason.id,
                description: returnReason.description,
                isReturnInfoRequired: returnReason.isReturnInfoRequired,
            }))
        )

        let selectedReason = reasons[0]
        if (returnReasonId) {
            const match = reasons.find((reason) => reason.id === returnReasonId)
            if (match) {
                selectedReason = match
            }
        }

        return (
            <Tooltip title={disablingReason}>
                <Box>
                    <Dropdown
                        items={reasons}
                        value={selectedReason}
                        itemView={DropdownInputView}
                        disabled={!reasons.length || disabled || !!disablingReason}
                        onChange={(returnReason) => handleChangeReturnReason(item, returnReason)}
                    />
                </Box>
            </Tooltip>
        )
    }

    function renderNote() {
        const { returnInfo } = returnItem
        // TODO: The tooltip shows an empty arrow when the title is empty
        return (
            <Tooltip title={returnInfo || disablingReason} variant="dark">
                <Box>
                    <IconButton onClick={handleSelectNote} disabled={disabled || !!disablingReason}>
                        <Icon name={returnInfo ? "message" : "add-notes"} width={20} height={20} color={returnInfo ? "primary" : undefined} />
                    </IconButton>
                </Box>
            </Tooltip>
        )
    }

    function renderActions() {
        return (
            <PartActions
                returnItem={returnItem}
                isSelected={isSelected}
                isUpdating={isUpdating}
                disablingReason={disablingReason}
                onChangeReturnStatus={props.onChangeReturnStatus}
                onRemoveItems={props.onRemoveItems}
                onSelectItem={props.onSelectItem}
            />
        )
    }

    function renderPartsNote() {
        if (!noteExpanded) {
            return
        }

        return (
            <PartsNote
                isReturnInfoRequired={returnItem.isReturnInfoRequired}
                note={returnItem.returnInfo}
                returnInfoMaxLength={returnItem.returnInfoMaxLength}
                isUpdating={disabled}
                onEditNote={handleEditNote}
            />
        )
    }

    return (
        <div className="list__item part-list__item">
            <div className="part-list__properties">
                {renderThumbnail()}
                <div className="manufacturer">
                    <Typography>{returnItem.dataSupplierName}</Typography>
                </div>
                {renderNumbers()}
                {renderDescription()}
                <div className="order-date">{renderOrderDate()}</div>
                <div className="order-number">{renderOrderNumber()}</div>
                {cisDisplayMode !== "wm" && (
                    <>
                        <div className="delivery-number">{renderDeliveryNumber()}</div>
                        <div className="invoice-number">{renderInvoiceNumber()}</div>
                    </>
                )}
                <div className="quantity">{renderQuantity()}</div>
                <div className="return-reason">{renderReturnReason()}</div>
                <div className="icon-column">{renderNote()}</div>
                <div className="actions no-separator">{renderActions()}</div>
            </div>
            {renderPartsNote()}
        </div>
    )
}
