import { renderRoute } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSearchWheel(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { history, match } = this.props
    const { searchWheel } = data

    if (searchWheel) {
        // TODO: since wheels bundle does not support at the moment an action that will open the new url, the history push must be done here.
        // Create an action (similar to REQUEST_ARTICLE_LIST from parts) so ap can send one like SHOW_UNIVERSAL_PARTS_BY_SEARCH for SHOW_TYRES.
        if (searchWheel && searchWheel.searchText) {
            const tyresSearchRoute = "/:workTaskId/tyres/list/isCar/0/:query?"
            history.push(
                renderRoute(tyresSearchRoute, {
                    ...match.params,
                    query: encodeURIComponent(searchWheel.searchText),
                })
            )
        }
    }
}
