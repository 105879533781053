import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import { initDamageCalculationEurotaxBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    // debug: true,
    components: [Main],

    init: initDamageCalculationEurotaxBundle,
}

export default bundle
