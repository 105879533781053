import { atom, useRecoilValue, useSetRecoilState } from "recoil"

type ApplyButtonState = {
    dataChanged: boolean
    valid: boolean
}

const applyButtonStateAtom = atom<ApplyButtonState>({
    key: "crm__applyButtonState",
    default: {
        dataChanged: false,
        valid: false,
    },
})

export function useApplyButtonState() {
    return useRecoilValue(applyButtonStateAtom)
}

export function useSetApplyButtonState() {
    return useSetRecoilState(applyButtonStateAtom)
}
