import { useRef } from "react"
import { UpdateRepairShopRequest, useUser } from "@tm/context-distribution"
import { Box, LoadingContainer } from "@tm/components"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import CustomerDetailsForm from "./CustomerDetailsForm"
import { useGetReturnUrl } from "../../hooks/useGetReturnUrl"
import { useUpdateRepairShopContact } from "../../hooks/useUpdateRepairShopContact"
import { getReturnUrlRequest } from "../../lib/helpers/returnUrl"
import ErrorNotification from "../shared/ErrorNotification"
import { openPartslifeInNewWindow } from "../../lib/helpers/openPartslifeInNewWindow"

export default function Main() {
    const contentRef = useRef<HTMLDivElement>(null)
    const { userSettings, setUserSetting } = useUser() ?? {}
    const { translateText, language } = useLocalization()

    const getReturnUrlMutation = useGetReturnUrl({
        translateText,
        onSuccess(returnUrl) {
            openPartslifeInNewWindow(returnUrl, contentRef)
            setUserSetting?.("PARTSLIFE_SETTINGS", { ...userSettings?.partsLifeSettings, hasPartsLifeActive: true })
            Morpheus.closeView("1")
        },
    })

    const { isLoading, updateContactDetails } = useUpdateRepairShopContact({
        translateText,
        onSuccess(request) {
            getPartslifeReturnUrl(request)
            setUserSetting?.("PARTSLIFE_SETTINGS", { ...userSettings?.partsLifeSettings, hasPartsLifeActive: true })
        },
    })

    function onOpenPartslife(request: UpdateRepairShopRequest, fieldsChanged: boolean) {
        if (fieldsChanged) {
            updateContactDetails(request)
        } else {
            getPartslifeReturnUrl(request)
        }
    }

    function getPartslifeReturnUrl(shopDetails: UpdateRepairShopRequest) {
        const request = getReturnUrlRequest(shopDetails, userSettings?.repairShop?.id ?? "", language, translateText)
        getReturnUrlMutation.mutate(request)
    }

    return (
        <Box display="flex" flex="1" ref={contentRef}>
            <LoadingContainer isLoading={userSettings === undefined || getReturnUrlMutation.isLoading || isLoading}>
                {userSettings && <CustomerDetailsForm repairShop={userSettings.repairShop} openPartslife={onOpenPartslife} />}
            </LoadingContainer>
        </Box>
    )
}
