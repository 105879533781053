import { useLocalization } from "@tm/localization"
import { PriceType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { CellContentPosition, TableCellData, TableColumnData } from "@tm/components"
import { VoucherItem } from "../../../data/model"
import VoucherItemOptionsCell from "../cells/VoucherItemOptionsCell"
import { StyledTable } from "../StyledComponents"
import { NoResultHint } from "../NoResultHint"
import ArticleDescriptionCell from "../cells/ArticleDescriptionCell"
import PriceCell from "../cells/PriceCell"
import DateCell from "../cells/DateCell"
import TaxesCell from "../cells/TaxesCell"
import VehicleInfoCell from "../cells/VehicleInfoCell"
import ArticleNumberCell from "../cells/ArticleNumberCell"

type Props = {
    currencyCode?: string
    voucherItems: Array<VoucherItem>
}

export default function TexkatVoucherItemsTableComponent({ voucherItems, currencyCode }: Props) {
    const { translateText, currency } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const hidePurchasePrice = userSettings ? !userSettings.showPurchasePrice : false

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(463) },
            { header: translateText(476) },
            { header: translateText(477) },
            { header: translateText(377) },
            { header: translateText(99), alignContent: CellContentPosition.center },
            { header: translateText(478), alignContent: CellContentPosition.center },
        ]

        if (!hidePurchasePrice) {
            columns.push({ header: translateText(1625), alignContent: CellContentPosition.right })
        }

        columns.push({ header: translateText(89), alignContent: CellContentPosition.center })

        if (!hidePurchasePrice) {
            columns.push({ header: translateText(495), alignContent: CellContentPosition.right })
        }
        columns.push({ header: translateText(494), alignContent: CellContentPosition.right }, {})

        return columns
    }

    function getCells(voucherItem: VoucherItem) {
        const cellData: TableCellData[] = [
            { displayValue: <DateCell value={voucherItem.orderDate} mode="onlyDate" />, id: "item_1" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="wholesaler" />, id: "item_2" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="supplier" />, id: "item_3" },
            { displayValue: <ArticleDescriptionCell articleDescription={voucherItem.articleDescription} />, id: "item_4" },
            { displayValue: <VehicleInfoCell voucherItem={voucherItem} />, id: "item_5" },
            { displayValue: <TaxesCell taxes={voucherItem.taxes} />, id: "item_6" },
        ]

        if (!hidePurchasePrice) {
            cellData.push({ displayValue: <PriceCell priceType={PriceType.Purchase} prices={voucherItem.prices} />, id: "item_7" })
        }

        cellData.push({ displayValue: `${voucherItem.quantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_8" })

        if (!hidePurchasePrice) {
            cellData.push({
                displayValue: currency(voucherItem.totalNet || 0, currencyCode || voucherItem.currencyCode),
                id: "item_9",
            })
        }

        cellData.push(
            { displayValue: currency(voucherItem.totalGross || 0, currencyCode || voucherItem.currencyCode), id: "item_10" },
            {
                displayValue: <VoucherItemOptionsCell item={voucherItem} />,
                id: "item_11",
            }
        )
        return cellData
    }

    const displayData = voucherItems.map((voucherItem, index) => ({
        cells: getCells(voucherItem),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} />
}
