import { useCallback, MouseEvent, useMemo } from "react"
import { Link } from "react-router-dom"
import { ModuleTab } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useWorkTask, useModuleNavigation } from "@tm/context-distribution"
import { classes, getUIA } from "@tm/utils"
import { Box, Icon, styled } from "@tm/components"
import { Demo } from "@tm/controls"
import { checkVehicleDependency, getStyleFromConfig, useCheckDemoEnabled } from "../helpers"
import { TabIcon } from "./TabIcon"
import { ModuleNavigationProps } from "../component"

type TabItemProps = {
    item: ModuleTab
    isMoreItem?: boolean
    style: ModuleNavigationProps["style"]
    onClose(): void
}

const TabLink = styled(Link)<{ disabled: boolean; selected: boolean }>(({ theme, disabled, selected }) => ({
    ...(disabled
        ? {
              pointerEvents: "none",
              opacity: 0.5,
          }
        : {}),
    ...(selected
        ? {
              color: theme.palette.highlight.main,
          }
        : {}),
}))

export function TabItem({ item, isMoreItem, style, onClose }: TabItemProps) {
    const { workTask } = useWorkTask() ?? {}
    const { closeTab } = useModuleNavigation()

    const { translateText } = useLocalization()
    const className = classes(
        isMoreItem ? "more__item" : classes("tab navigation__item", item.isSelected && "is-selected"),
        getStyleFromConfig("tab", style, isMoreItem ? undefined : { layout: "module" })
    )

    const title = translateText(item.title)

    const isDemoEnabled = useCheckDemoEnabled(item)
    const isEnabled = checkVehicleDependency(workTask?.vehicle, item.vehicleDependency)

    const handleCloseTab = useCallback(
        (ev: MouseEvent) => {
            ev.stopPropagation()
            ev.preventDefault()
            closeTab(item.url)
            onClose()
        },
        [onClose, closeTab, item.url]
    )

    const uia = useMemo(
        () => (isMoreItem ? getUIA("ModulenavigationButtonMore") : getUIA("ModulenavigationButton", item.url)),
        [isMoreItem, item.url]
    )

    return (
        <TabLink disabled={!isEnabled} selected={!!item.isSelected} className={className} to={item.url} {...uia}>
            <TabIcon icon={item.icon} />
            <div className="tab__content">
                <div className="tab__title">{title}</div>
            </div>
            {isDemoEnabled && <Demo displayMode="edge" className="demo-icon" />}
            {!item.isDefault && (
                <Box className="tab__close">
                    <Icon name="close" className="icon" onClick={handleCloseTab} size="16px" />
                </Box>
            )}
        </TabLink>
    )
}
