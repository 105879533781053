import { Box, NumberSelect, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { Article, ArticleIdentifier, eOEArtType, IMicros, OE } from "@tm/models"
import Morpheus, { useActions, useMicro } from "@tm/morpheus"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useSelector } from "react-redux"
import { ReplaceButton } from "@tm/controls"
import { DatSelectionSteps, getStatusByExternalTool } from "../../../../../data/enums"
import { ICalculationItem } from "../../../../../data/model"
import { externSelector, managerSelector } from "../../../../../selectors"
import { MainActions } from "../../../../main/business"
import { mapArticleToArticleIdentifier, mapDatOePartToOE } from "../../../business/mappers"

type Props = {
    item: ICalculationItem
    requestAlternatives: (item: ICalculationItem) => void
}

export function ArticleItem({ item, requestAlternatives }: Props) {
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    const user = useUser()
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const { vehicle, contractId } = useSelector(managerSelector)
    const { page } = useSelector(externSelector)

    const { oeArticle, selectedPart, isSelected } = item

    const actions = useActions(MainActions, "selectArticle", "changeOeArticleQuantity", "changeStep")

    const handleDetailsClick = (article: Article) => {
        const params = {
            contractId,
            status: getStatusByExternalTool(page),
            productGroupId: article.productGroup.id?.toString(),
            supplierId: article.supplier.id?.toString(),
            supplierArticleNo: article.supplierArticleNo,
            partsDetailsSubPage: "overview",
        }

        actions.changeStep(DatSelectionSteps.DETAILS, params)
    }

    const onArticleSelect = (partId: string) => {
        actions.selectArticle(translateText, item)
    }

    const onQuantityChange = (quantity: number) => {
        actions.changeOeArticleQuantity(translateText, item, quantity)
    }

    const onReplace = () => {
        requestAlternatives(item)
    }

    const renderReplaceButton = () => {
        let content = null

        if (oeArticle.type === eOEArtType.MaterialPosition) {
            content = (
                <Box display="flex" gap="3%">
                    <NumberSelect minAmount={0} value={oeArticle.quantityValue} onValueChange={onQuantityChange} />
                    <ReplaceButton disabled={!item.hasAlternatives} onClick={onReplace} isWm={isWm} />
                </Box>
            )
        } else {
            content = <StyledAdditionalCostActions />
        }

        return <Box margin={`.1725em ${!isWm ? "auto" : ".1725em"} .1725em 0`}>{content}</Box>
    }

    const renderOePart = () => {
        const oePart: OE.OePart = mapDatOePartToOE(oeArticle, getCurrencyFromUserContext(user?.userContext), vehicle)

        const enableSelector =
            oeArticle.type !== eOEArtType.SmallPartsAndConsumables ||
            (oeArticle.type === eOEArtType.SmallPartsAndConsumables && !!oeArticle.oePriceValue && oeArticle.oePriceValue > 0)

        return (
            <Box marginTop=".5em" sx={{ ".checkbox": { marginLeft: !isWm ? "1em" : "none" } }}>
                {renderMicro("parts", "oe-part-item", {
                    oePart,
                    renderBasketButton: renderReplaceButton,
                    hiddenFakeActions: {
                        costEstimation: true,
                        selector: enableSelector,
                        moreMenu: true,
                        details: true,
                    },
                    onArticleSelect: enableSelector ? onArticleSelect : undefined,
                    isSelected,
                })}
            </Box>
        )
    }

    const renderPart = (selectedArticlePart: Article) => {
        const partToLoad: ArticleIdentifier = mapArticleToArticleIdentifier(selectedArticlePart, vehicle?.tecDocTypeId)

        return (
            <Box marginTop=".5em">
                {renderMicro("parts", "part-item", {
                    partToLoad,
                    renderBuyActions: renderReplaceButton,
                    onRequestArticleDetails: () => handleDetailsClick(selectedArticlePart),
                    onArticleSelect,
                    isSelected,
                    showActions: true,
                    showArticleImage: true,
                    canFilterArticleAttributes: false,
                } as any)}
            </Box>
        )
    }

    if (selectedPart) {
        return renderPart(selectedPart)
    }

    return renderOePart()
}

const StyledAdditionalCostActions = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    width: "11.5em",
}))
