import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, Headline, Icon, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { classes } from "@tm/utils"
import { em } from "csx"
import * as React from "react"
import { useSelector } from "react-redux"
import { getBatteryVoltageIconName, getBatteryVoltageIconSkin, VOLTAGE_THRESHOLD_WARNING } from "../../../data/helpers"
import { MainActions, managerDataSelector } from "../../main/business"
import { Cell } from "../../shared"
import { Tooltip } from "@tm/components"

type Props = {
    handleClick(): void
}

const SecondPart: React.FC<Props> = ({ handleClick }) => {
    const { translateText, date } = useLocalization()
    const worktaskVehicle = useWorkTask()?.workTask?.vehicle
    const { statusData, statusDataLoaded, errorStatus } = useSelector(managerDataSelector) || {}

    const { odometer, telematicsDeviceVoltage, odometerUnit, batteryUnit, serviceRequiredTime } = statusData || {}
    const actions = useActions(MainActions, "getStatusData")

    React.useEffect(() => {
        //!statusDataLoaded && worktaskVehicle?.vin && actions.getStatusData(worktaskVehicle.vin)
    }, [])

    if (errorStatus)
        return (
            <div className={style.center}>
                <div className="article-list__panel article-list__status">
                    <div className="article-list__no-result">
                        <Icon name="attention-dark" size="xs" />
                        <Headline>{translateText(163)}</Headline>
                    </div>
                </div>
            </div>
        )
    else if (!statusDataLoaded && !errorStatus)
        return <div className={style.center}><Loader /></div>

    return (
        <>
            <div className={style.options}>
                <Button onClick={handleClick}>{translateText(458)}</Button>
            </div>

            <div className={classes(style.summary, style.center)}>
                {odometer && <Cell>
                    <Icon name="cylinder-raceflag" size="xl" />
                    <Text>{translateText(1491)}</Text>
                    <Text size="xl" modifiers={["highlight", "strong"]}>{`${odometer} ${odometerUnit}`}</Text>
                </Cell>}
                {serviceRequiredTime && <Cell>
                    <Icon name="inspection-date" size="xl" />
                    <Text>{translateText(12463)}</Text>
                    <Text size="xl" modifiers={["highlight", "strong"]}>{date(new Date(serviceRequiredTime), "d")}</Text>
                </Cell>}
                {telematicsDeviceVoltage && <Cell>
                    <Tooltip color="primary" variant="light" placement="top" title={telematicsDeviceVoltage < VOLTAGE_THRESHOLD_WARNING ? translateText(934) : ""}>
                        <div>
                            <Icon name="battery" className="battery-icon" size="xl" />
                            <Icon
                                size="xl"
                                className={style.batteryStatusIcon}
                                name={getBatteryVoltageIconName(telematicsDeviceVoltage)}
                                skin={getBatteryVoltageIconSkin(telematicsDeviceVoltage)} />
                        </div>
                    </Tooltip>
                    <Text>{translateText(12464)}</Text>
                    <Text size="xl" modifiers={["highlight", "strong"]}>{`${telematicsDeviceVoltage.format(2)} ${batteryUnit}`}</Text>
                </Cell>}
            </div>
        </>
    )
}

const style = useStyle({
    center: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1
    },
    summary: {},
    options: {
        position: "absolute",
        right: 0,
        top: 0
    },
    batteryStatusIcon: {
        marginLeft: em(.5)
    }
})(SecondPart)

export default SecondPart