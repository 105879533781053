import { Alert, Snackbar } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { errorStoreActions, useErrorStore } from "../../lib/state"

type Props = {
    children: React.ReactNode
}

export default function ErrorNotification({ children }: Props) {
    const { translateText } = useLocalization()

    const errorMessage = useErrorStore((state) => state.errorMessage)
    const isError = useErrorStore((state) => state.isError)

    return (
        <>
            {children}
            <Snackbar
                open={isError}
                autoHideDuration={3000}
                onClose={() => errorStoreActions.clearError()}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert
                    icon={false}
                    severity="error"
                    size="small"
                    title={errorMessage ?? translateText(787)}
                    onClose={() => errorStoreActions.clearError()}
                    sx={{ alignItems: "center" }}
                />
            </Snackbar>
        </>
    )
}
