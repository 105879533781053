import { FittingPosition, Vehicle } from "@tm/models"
import { FiltersSensorsRequest, FiltersSensorsResponse } from "../../../data/repositories/articles-loadSensorFilters/model"
import { SensorItemsRequest } from "../../../data/repositories/articles-loadSensorItems/model"
import { getBundleParams } from "../../../utils"

export function createSensorArticleFiltersRequest(vehicle: Vehicle | undefined): FiltersSensorsRequest | undefined {

    if (!vehicle)
        return

    return {
        productGroupId: [2232],
        extendedAssortment: false,
        fittingSideFilter: FittingPosition.None,
        treeId: getBundleParams().universalTreeId,
        nodeId: 2100242,
        modelId: vehicle.tecDocTypeId,
        vehicleType: 1
    }
}

export function createSensorArticlesRequest(vehicle: Vehicle | undefined, response: FiltersSensorsResponse): SensorItemsRequest | undefined {

    if (!vehicle)
        return

    const supplierIds = response.manufacturer.map(x => x.id || 0)

    if (!supplierIds.length) return

    return {
        productGroupIds: [2232],
        modelId: vehicle.tecDocTypeId,
        vehicleType: vehicle.vehicleType || 1,
        supplierIds,
        extendedAssortment: false,
        fittingSideFilter: FittingPosition.None,
        pageIndex: 1,
        pageSize: getBundleParams().tyresPageSize,
    }
}