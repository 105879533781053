import { AddOePartListRequest } from "@tm/models"

import { PostMessageRequest } from "../../../data"
import { getBasketPositionMemo } from "../business/helpers"
import { PostMessageControllerComponent } from "../component"

export default function handleAddOePartsToBasket(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { addOePartsToBasket } = data
    const { workTask, userSettings, workTaskTruckData, addOePartList } = this.props

    if (addOePartsToBasket && addOePartsToBasket.length > 0 && workTask) {
        const memo = getBasketPositionMemo(userSettings?.orderOptions, workTask, workTaskTruckData)

        const request: AddOePartListRequest = {
            workTaskId: workTask.id,
            customerId: workTask.customer && workTask.customer.id,
            vehicleId: workTask.vehicle && workTask.vehicle.id,
            oeParts: addOePartsToBasket.map((oePart) => ({
                oeArticleNumber: oePart.oeNumber,
                description: oePart.description,
                additionalDescription: oePart.information,
                productGroupId: undefined,
                quantityValue: oePart.quantity,
                oePriceValue: oePart.price && oePart.price > 0 ? oePart.price : undefined,
                currencyCode: "EUR", // Currently the Data Service only supports EUR
                vehicleManufacturerId: oePart.manufacturerId,
                vehicleManufacturerName: oePart.manufacturer,
                memo,
                wholesalerArticleNumber: oePart.wholesalerArticleNumber,
            })),
        }

        addOePartList(request).then(
            () => {
                if (source) {
                    source.postMessage({ name: "addOePartsToBasket", success: true, parts: request.oeParts }, "*")
                }
            },
            () => {
                if (source) {
                    source.postMessage({ name: "addOePartsToBasket", success: false, parts: request.oeParts }, "*")
                }
            }
        )
    }
}
