import * as React from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { DateField, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { DatePicker } from "@tm/components"
import { getComponentStyles } from "../../_shared/styles"
import { useFastServiceStore } from "../../../data"

const IntervalDate: React.FC = () => {
    const classNames = getComponentStyles()
    const { translateText, language } = useLocalization()
    const kmValue = useFastServiceStore((state) => state.inspectionKmValue)
    const date = useFastServiceStore((state) => state.inspectionDate)
    const saveInspectionKm = useFastServiceStore((state) => state.setSaveInspectionKm)
    const saveInspectionDate = useFastServiceStore((state) => state.setSaveInspectionDate)

    const handleDateChange = (value: Date | undefined) => {
        if (value) {
            saveInspectionDate(value)
        }
    }

    const handleKmValue = (value: string) => {
        saveInspectionKm(value)
    }

    return (
        <div className={style.wrapper}>
            <DateField size="xl" useUtc value={date} minDate={new Date()} openToDate={date || new Date()} onChange={handleDateChange} />
            <TextField
                className={classes(classNames.marginBottomS, style.marginLeft)}
                size="xl"
                placeholder={translateText(125)}
                value={kmValue}
                onChange={handleKmValue}
                maxLength={9}
                formatter={(value) => value.replace(/[\D]/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                inputMode="numeric"
            />
        </div>
    )
}

export default IntervalDate

const style = useStyle({
    wrapper: {
        display: "flex",
        marginTop: "1em",
    },
    marginLeft: {
        marginLeft: "3em",
    },
})(IntervalDate)
