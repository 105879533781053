import { NotificationsBundleParams } from "@tm/models"

export const version = {
    name: "notifications",
    version: "2.0",
}

let bundleParams: NotificationsBundleParams

export function initNotificationsBundle(params: NotificationsBundleParams) {
    bundleParams = params
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
