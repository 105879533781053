import { useState, Suspense, useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { Button, Icon, Loader } from "@tm/components"
import Morpheus from "@tm/morpheus"
import { useWorkTask } from "@tm/context-distribution"

import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

type Props = IMicros["basket"]["change-distributor-button"]

function ChangeDistributorButton(props: Props & { workTaskId: string }) {
    const { basketItemId, article, erpSystemConfig, workTaskId } = props
    const { translateText } = useLocalization()
    const { basket } = useWorkTaskBasketState(workTaskId)
    const [changingDistributor, setChangingDistributor] = useState(false)

    const handleClick = useCallback(() => {
        setChangingDistributor(true)
        basket.actions
            .changeItemDistributor({
                itemId: basketItemId,
                wholesalerArticleNumber: article.traderArticleNo,
                distributorId: erpSystemConfig.id,
                distributorName: erpSystemConfig.description,
            })
            .then(() => {
                Morpheus.closeView("1")
            })
            .finally(() => {
                setChangingDistributor(false)
            })
    }, [basketItemId, article.traderArticleNo, erpSystemConfig, basket.actions])

    return (
        <>
            {changingDistributor ? (
                <Loader size="extrasmall" />
            ) : (
                <Button
                    title={translateText(937)}
                    color="highlight"
                    onClick={handleClick}
                    startIcon={<Icon name="replace-partner" />}
                    disabled={changingDistributor}
                />
            )}
        </>
    )
}

export default function Wrapper(props: Props) {
    const { workTaskId } = useWorkTask() ?? {}
    if (!workTaskId) {
        return null
    }

    return (
        <Suspense fallback={null}>
            <ChangeDistributorButton {...props} workTaskId={workTaskId} />
        </Suspense>
    )
}
