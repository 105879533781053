import { Box, Button, Dialog, Icon, IconButton, Typography } from "@tm/components"
import { VoucherType } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { openStreamPdf } from "@tm/utils"
import { useState } from "react"
import { RowStack } from "../../../../_shared/StyledComponents"
import * as Data from "../../../../../data"
import ReturnsButton from "./ReturnsButton"
import { ModuleHeaderSlot } from "../../../../../../../parts/src/components/ListV2/components/ModuleHeader/ModuleHeaderSlot"

type Props = {
    voucherType: VoucherType
    hasMailRetoure: boolean
    voucherId?: string
    onRedirectToVoucherList(): void
}

export default function VoucherDetailsOptions({ voucherType, hasMailRetoure, voucherId, onRedirectToVoucherList }: Props) {
    const { translateText } = useLocalization()
    const [showDeleteDialog, setShowDeleteDialog] = useState<boolean>(false)

    function handleDeleteCostEstimation() {
        if (!voucherId) {
            return
        }

        Data.deleteCostEstimations([voucherId]).then(() => {
            onRedirectToVoucherList()
            setShowDeleteDialog(false)
        })
    }

    function showCostEstimationPdf(costEstimationId: string) {
        Data.showCostEstimationPdf(costEstimationId).then((costEstimationPdf) => {
            if (costEstimationPdf) {
                openStreamPdf(costEstimationPdf, translateText)
            }
        })
    }

    function showOrderPdf(orderId: string) {
        Data.showOrderPdf(orderId).then((orderPdf) => {
            if (orderPdf) {
                openStreamPdf(orderPdf, translateText)
            }
        })
    }

    function handlePrintButtonClick() {
        if (voucherType === VoucherType.CostEstimation && voucherId) {
            showCostEstimationPdf(voucherId)
        } else if (voucherType === VoucherType.Order && voucherId) {
            showOrderPdf(voucherId)
        }
    }

    return (
        <ModuleHeaderSlot title={translateText(177)}>
            <RowStack spacing={1}>
                {voucherType === VoucherType.CostEstimation && (
                    <>
                        <Button onClick={() => setShowDeleteDialog(true)} title={translateText(69)} size="small" variant="outlined">
                            <Icon name="delete" />
                        </Button>
                        <Dialog open={showDeleteDialog} skin="warning" position="top" fullWidth>
                            <RowStack spacing={2}>
                                <Typography>{translateText(1752)}</Typography>
                                <Box textAlign="center" display="flex" flexWrap="nowrap" height={45}>
                                    <Button variant="contained" onClick={() => setShowDeleteDialog(false)}>
                                        {translateText(584)}
                                    </Button>
                                    <Button variant="contained" sx={{ marginLeft: 1 }} color="success" onClick={handleDeleteCostEstimation}>
                                        {translateText(585)}
                                    </Button>
                                </Box>
                            </RowStack>
                        </Dialog>
                    </>
                )}
                {voucherType === VoucherType.Order && hasMailRetoure && <ReturnsButton />}
                {voucherType !== VoucherType.Return && (
                    <Button disabled={!voucherId} onClick={handlePrintButtonClick} title={translateText(49)} size="small" variant="outlined">
                        <Icon name="print" />
                    </Button>
                )}
            </RowStack>
        </ModuleHeaderSlot>
    )
}
