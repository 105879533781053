import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { useQuery } from "react-query"
import { findConnectedVouchers } from "../repositories/cis/vouchers"

const KEY = "cis_useConnectedVouchers"
export function useConnectedVouchers(parentVoucherId: string, vouchersParentTypeId: number) {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const enabled = enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, parentVoucherId, vouchersParentTypeId, telesalesCustomerNo, enabled],
        () => findConnectedVouchers({ parentVoucherId, vouchersParentTypeId, customerNo: telesalesCustomerNo }),
        {
            enabled,
            staleTime: 5 * 60 * 1000, // 5 minutes
        }
    )

    return { connectedVouchers: data?.vouchers, connectedVouchersLoading: isLoading }
}
