import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function wheelsFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const wheelsModule = getModuleFromUserContext(userContext, UserModuleType.TMWheels)

    if (!wheelsModule) {
        return undefined
    }

    return {
        wheels: {
            items: {
                wheels: {
                    sort: 0,
                    name: "{{608}}",
                    active: true,
                    route: "/:workTaskId/wheels",
                },
            },
        },
    }
}
