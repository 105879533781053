import { AsyncAction } from "@tm/morpheus"
import { CategorySelectionTreeSelectionState, CategoryType } from "./model"
import * as Global from "../../../data"
import { BundleActionType, BundleActions } from "../../../business"
import { SearchTreeNode, SearchTreeNodeLoadOptions } from "../../../data"

export * from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "LOADING_MAIN_CATEGORIES"; payload: { loading: boolean } }
    | { type: "MAIN_CATEGORIES_LOADED"; payload: { categories: Array<SearchTreeNode>; loading: boolean } }
    | { type: "RESET_BREADCRUMB"; payload: CategoryType }

const DEFAULT_STATE: CategorySelectionTreeSelectionState = {
    loading: false,
    categories: [],
    breadcrumbs: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): CategorySelectionTreeSelectionState {
    switch (action.type) {
        case "LOADING_MAIN_CATEGORIES": {
            return {
                ...state,
                ...action.payload,
                categories: [],
            }
        }
        case "MAIN_CATEGORIES_LOADED": {
            return {
                ...state,
                ...action.payload,
            }
        }
        case "ADD_BREADCRUMB": {
            return {
                ...state,
                breadcrumbs: state.breadcrumbs.concat([action.payload]),
            }
        }
        case "RESET_BREADCRUMB": {
            if (action.payload === CategoryType.main) {
                return { ...state, breadcrumbs: [] }
            }
            return { ...state, breadcrumbs: state.breadcrumbs.slice(0, 1) }
        }
        default:
            break
    }

    return state
}

export function transmit(action: ComponentActionType): ComponentActionType | undefined {
    switch (action.type) {
        case "MODULE_OPENED":
        case "ADD_BREADCRUMB":
            return action
        default:
            break
    }
}

function loadCategories(options: SearchTreeNodeLoadOptions): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "LOADING_MAIN_CATEGORIES", payload: { loading: true } })

        Global.loadSearchTreeNodes(options).then((response) => {
            dispatch({
                type: "MAIN_CATEGORIES_LOADED",
                payload: {
                    categories: response.data,
                    loading: false,
                },
            })
        })
    }
}

function selectBreadcrumb(category: SearchTreeNode): ComponentActionType {
    return { type: "ADD_BREADCRUMB", payload: category }
}

function resetBreadcrumb(categoryType: CategoryType): ComponentActionType {
    return { type: "RESET_BREADCRUMB", payload: categoryType }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    loadCategories,
    selectBreadcrumb,
    resetBreadcrumb,
}
