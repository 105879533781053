import { AddCustomWorkListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"

export function useAddCustomWorkList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()

    const { mutateAsync: addCustomWorkList } = useMutation(
        (request: { addCustomWorkListRequest: AddCustomWorkListRequest }) => Data.addCustomWorkList(request.addCustomWorkListRequest),
        {
            onSuccess: async (response, request) => {
                if (response) {
                    await enableCostEstimation(request.addCustomWorkListRequest.workTaskId)
                    handleBasketUpdateWorkflow(request.addCustomWorkListRequest.workTaskId, response)

                    // Save work task in the DB
                    Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                }
            },
        }
    )

    const addCustomWorks = useCallback(
        (request: AddCustomWorkListRequest) => {
            return addCustomWorkList({ addCustomWorkListRequest: request })
        },
        [addCustomWorkList]
    )

    return addCustomWorks
}
