import { memo, useEffect, useMemo, useState } from "react"
import { DatePicker, Tooltip } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { addDaysToDate, findParameterInTelesalesModule } from "../../../../../helpers"
import { DEFAULT_FILTER_DATE_OFFSET, initialState } from "../../../business"

type Props = {
    fromDate?: Date
    toDate?: Date
    onChangeDate(from: Date, to: Date): void
}

const TOOLTIP_TIMEOUT = 5000

export const DateFilter = memo<Props>(({ onChangeDate, fromDate, toDate }) => {
    const { translateText, language } = useLocalization()
    const { userContext } = useUser() ?? {}
    const maxTelesalesDateFilterRangeInDays = +(findParameterInTelesalesModule("DateFilterPreset", userContext) || DEFAULT_FILTER_DATE_OFFSET)
    const [openErrorTooltip, setOpenErrorTooltip] = useState<boolean>(false)

    useEffect(() => {
        let timer: NodeJS.Timeout

        if (openErrorTooltip) {
            timer = setTimeout(() => {
                setOpenErrorTooltip(false)
            }, TOOLTIP_TIMEOUT)
        }
        return () => {
            if (timer) {
                clearTimeout(timer)
            }
        }
    }, [openErrorTooltip])

    const calculateDaysBetween = (start: Date, end: Date): number => {
        return (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)
    }

    const updateDates = (from: Date, to: Date) => {
        onChangeDate(from, to)
    }

    const handleOnDateChange = (newDate: Date | null, identifier: "FROM" | "TO") => {
        if (newDate) {
            if (identifier === "FROM") {
                const daysBetween = calculateDaysBetween(newDate, toDate || initialState.dateFilter.to)

                if (daysBetween > maxTelesalesDateFilterRangeInDays || daysBetween < 0) {
                    setOpenErrorTooltip(true)
                    updateDates(newDate, addDaysToDate(newDate, maxTelesalesDateFilterRangeInDays))
                } else {
                    updateDates(newDate, toDate || initialState.dateFilter.to)
                }
            } else {
                updateDates(fromDate || initialState.dateFilter.from, getNewToDate(newDate))
            }
        }
    }

    const maxToDate = useMemo(() => {
        if ((fromDate || initialState.dateFilter.from).getTime() < new Date().getTime()) {
            return addDaysToDate(fromDate || initialState.dateFilter.from, maxTelesalesDateFilterRangeInDays)
        }

        return new Date()
    }, [fromDate, maxTelesalesDateFilterRangeInDays])

    const getNewToDate = (newToDate: Date) => {
        if (newToDate && fromDate && newToDate.getTime() >= fromDate?.getTime()) {
            if (newToDate?.getTime() <= maxToDate.getTime()) {
                return newToDate
            }
            if (newToDate?.getTime() > maxToDate.getTime()) {
                return maxToDate
            }
        }
        return maxToDate
    }

    return (
        <>
            <DatePicker
                localisation={{ locale: language, region: null }}
                onChange={() => {
                    /* do nothing */
                }}
                ignoreInvalidInputs
                onAccept={(date) => handleOnDateChange(date, "FROM")}
                value={fromDate || null}
                label={translateText(344)}
                maxDate={new Date()}
                size="extralarge"
            />
            <Tooltip title={translateText(13123)} open={openErrorTooltip}>
                <div>
                    <DatePicker
                        localisation={{ locale: language, region: null }}
                        onChange={() => {
                            /* do nothing */
                        }}
                        onAccept={(date) => handleOnDateChange(date, "TO")}
                        value={getNewToDate(toDate || initialState.dateFilter.to)}
                        InputProps={{ error: openErrorTooltip }}
                        label={translateText(95)}
                        minDate={fromDate}
                        maxDate={maxToDate}
                        size="extralarge"
                    />
                </div>
            </Tooltip>
        </>
    )
})
