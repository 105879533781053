import { useLocalization } from "@tm/localization"
import { LinkButton } from "@tm/components"
import { renderRoute, encodeUniqueId, uniqueId } from "@tm/utils"
import { useParams } from "react-router"
import { getBundleParams } from "../../../utils"

export default function WidgetCreditFooterComponent() {
    const { translate } = useLocalization()
    const matchParams = useParams<{ workTaskId?: string }>()
    const detailsUrl = renderRoute(getBundleParams().summaryUrl, {
        ...matchParams,
        workTaskId: matchParams.workTaskId || encodeUniqueId(uniqueId()),
    })

    return <LinkButton to={detailsUrl}>{translate(43)}</LinkButton>
}
