import { Stack, Typography } from "@tm/components"
import { OrderDetailsLabel } from "../../../../../../../_shared/StyledComponents"

export type VoucherDetailsFieldProps = {
    label: string
    value?: string | number
    valueInPrimaryColor?: boolean
}

export function VoucherDetailsField({ label, value, valueInPrimaryColor }: VoucherDetailsFieldProps) {
    return (
        <Stack direction="column">
            <OrderDetailsLabel>{label}</OrderDetailsLabel>
            <Typography variant="body2" color={valueInPrimaryColor ? "primary" : undefined}>
                {value}
            </Typography>
        </Stack>
    )
}
