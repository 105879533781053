import * as React from "react"

export type ArticleCellBgColor = "transparent" | "lighter" | "light"

type ArticleCellProps = {
    header?: React.ReactNode
    bgColor?: ArticleCellBgColor
    highlight?: boolean
    border?: "left"
    bemModifier?: string
    className?: string
    children?: React.ReactNode
}

const ArticleCell: React.SFC<ArticleCellProps> = props => {
    let className = "article__cell "
    className += props.bemModifier ? `article__cell--${props.bemModifier} ` : ""
    className += props.highlight ? "article__cell--highlight " : ""

    switch (props.bgColor) {
        case "lighter":
            className += "article__cell--lighter-bg "
            break
        case "light":
            className += "article__cell--light-bg "
            break
    }

    switch (props.border) {
        case "left":
            className += "article__cell--bordered-left "
            break
    }

    className += props.className || ""

    return (
        <div className={className}>
            {
                !!props.header &&
                <div className="article__cell__header text text--x-small">
                    {props.header}
                </div>
            }
            {props.children}
        </div>
    )
}

ArticleCell.defaultProps = {
    bgColor: "transparent"
}

export default ArticleCell
