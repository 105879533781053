import { styled, Loader } from "@tm/components"
import { channel } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { usePrevious } from "@tm/utils"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { ExternalToolMessage } from "../../data/model"
import { settingsSelector } from "../../selectors"
import { Wrapper } from "../../styles"
import { MainActions } from "../main/business"
import { CustomIFrame } from "../_shared"

export default function SettingsComponent() {
    const { externalContent } = useSelector(settingsSelector)
    const prevContent = usePrevious(externalContent)

    const actions = useActions(MainActions, "getUrl", "openHistoryCalculation", "changeStep")

    useEffect(() => {
        if (!externalContent) {
            actions.getUrl()
        }
    }, [])

    useEffect(() => {
        const subscription = channel("GLOBAL").subscribe("DMG_DAT/EXTERNAL_TOOL_DONE", (msg) => {
            const data = msg as ExternalToolMessage

            if (data.type === "relocate") {
                const getContractIdRegex = new RegExp("_(.*?)~")
                const newContractId = getContractIdRegex.exec(data.message)?.[1]

                if (!newContractId) {
                    return
                }

                actions.openHistoryCalculation(newContractId)
            }
        })

        return subscription
    }, [])

    return (
        <Wrapper sx={{ width: "100%", height: "100%" }} centeredContent={!externalContent}>
            {externalContent ? (
                <StyledCustomIframe id="dat-external-settings" srcDoc={externalContent} reloadIframe={prevContent !== externalContent} />
            ) : (
                <Loader size="small" />
            )}
        </Wrapper>
    )
}

const StyledCustomIframe = styled(CustomIFrame)(() => ({
    display: "flex",
    flex: 1,
    width: "100%",
    height: "100%",
}))
