import { ajax, getStoredAuthorization } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { RepairTimesBundleParams } from "@tm/models"
import { ShowWorkEstimationRequest, ShowWorkEstimationResponse } from "../model/WorkEstimation"

function getServiceUrl() {
    return Morpheus.getParams<RepairTimesBundleParams>("repair-times").serviceUrl
}

export function showWorkEstimation(body: ShowWorkEstimationRequest) {
    const url = `${getServiceUrl()}/ShowWorkEstimation`
    const authorization = getStoredAuthorization()
    return ajax<ShowWorkEstimationResponse>({ url, body, authorization, method: "POST" }, undefined, undefined, true)
}
