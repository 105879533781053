import { UserSettings } from "@tm/context-distribution"
import { WorkCategory, UserContext, UserModuleType } from "@tm/models"

export function getLabourRate(userSettings?: UserSettings): number | undefined {
    let hourlyRate

    if (userSettings?.hourlyRates) {
        hourlyRate = userSettings.hourlyRates.find((rate) => rate.category == WorkCategory.WorkshopWork)

        if (!hourlyRate) {
            hourlyRate = userSettings.hourlyRates.find((rate) => rate.category == undefined)
        }
    }

    return hourlyRate?.hourlyRate
}

export function getCurrencyFromUserContext(userContext?: UserContext): string {
    return userContext?.modules?.find((x) => x.type == UserModuleType.Catalog)?.currencyCode ?? "EUR"
}
