import { Icon, LinkButton } from "@tm/components"
import { VehicleType } from "@tm/models"
import { renderRoute } from "@tm/utils"
import { useParams } from "react-router"
import { VoucherItem } from "../../../data/model"
import { getBundleParams } from "../../../utils"
import { VoucherRouteProps } from "../../vouchers/component"

type Props = {
    voucherItem: VoucherItem
}

export default function VehicleinfoCellComponent({ voucherItem }: Props) {
    const matchParams = useParams<VoucherRouteProps>()
    if (!voucherItem.vehicleInfo && !voucherItem.kTypeId) {
        return <></>
    }

    const url = renderRoute(getBundleParams().vehicleDetailsUrl, {
        ...matchParams,
        vehicleType: voucherItem.vehicleTypeId || VehicleType.PassengerCar,
        vehicleId: voucherItem.kTypeId,
    })
    return (
        <LinkButton disabled={!url} to={url} variant="bordered" startIcon={<Icon name={voucherItem.vehicleInfo ? "" : "vehicle-reference"} />}>
            {voucherItem.vehicleInfo}
        </LinkButton>
    )
}
