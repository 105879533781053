import { parseQueryString } from "@tm/utils"

const queryParams = parseQueryString(window.location.search)
const RDsessionId = queryParams.RDsessionId as string
const tecDoc = parseInt(queryParams.ktype as string)

type BundleParams = {

}

let bundleParams: BundleParams


export const version = {
    name: "standalone",
    version: "0.1.0",
}

export function initStandaloneBundle(params: BundleParams) {
    bundleParams = params
    return bundleParams
}


export function getBundleParams() {
    if (!bundleParams) {
        throw `The toolkit params have to be set in order to use the bundle "${version.name}"`
    }
    return bundleParams
}


export function getRdSession() {
    return RDsessionId
}

export function getKtypeNr() {
    return tecDoc
}

if (!window.DVSE && localStorage.getItem("ENV") == "development") {
    window.DVSE = {
        addArticlesToBasket: console.debug.bind(undefined, "addArticlesToBasket not found"),
        addRepairTimesToBasket: console.debug.bind(undefined, "addRepairTimesToBasket not found"),
        RT_TYPE_GARAGE_WORK: "",
        openOEArticleList: console.debug.bind(undefined, "openOEArticleList not found"),
    }
}

