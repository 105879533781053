export const getStepLocationName = (stepName: string): string => {
    switch (stepName) {
        case "engineCompartment":
            return "Engine compartment"
        case "underVehicle":
            return "Under the vehicle"
        case "interior":
            return "Interior"
        case "exterior":
            return "Exterior"
        case "general":
            return "General"
        case "checkWork":
            return "Check work"
        case "testDrive":
            return "Test drive"
        case "tiresWheels":
            return "Tires wheels"
        default:
            return ""
    }
}

export const getStepNameByLocation = (stepName: string): string => {
    switch (stepName) {
        case "Engine compartment":
            return "engineCompartment"
        case "Under the vehicle":
            return "underVehicle"
        case "Interior":
            return "interior"
        case "Exterior":
            return "exterior"
        case "General":
            return "general"
        case "Check work":
            return "checkWork"
        case "Test drive":
            return "testDrive"
        case "Tires wheels":
            return "tiresWheels"
        default:
            return ""
    }
}

export const getTranslationByLocation = (stepName: string): string => {
    switch (stepName) {
        case "Engine compartment":
            return "12665"
        case "Under the vehicle":
            return "12668"
        case "Interior":
            return "12666"
        case "Exterior":
            return "12667"
        case "General":
            return "380"
        case "Check work":
            return "12674"
        case "Test drive":
            return "12673"
        case "Tires wheels":
            return "13062"
        default:
            return ""
    }
}

export const getTranslationByKey = (stepName: string): string => {
    switch (stepName) {
        case "12665":
            return "Engine compartment"
        case "12668":
            return "Under the vehicle"
        case "12666":
            return "Interior"
        case "12667":
            return "Exterior"
        case "380":
            return "General"
        case "12674":
            return "Check work"
        case "12673":
            return "Test drive"
        case "13062":
            return "Tires wheels"
        default:
            return ""
    }
}
