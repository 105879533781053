import { Article, ArticleAttributes } from "@tm/models"
import { CriterionFilters, OeInformation, ProductGroupFilter } from "."

export type GetPartsListResponse = {
    articles: Array<Article>
    imageCoordinates?: Array<PartsListImageCoordinate>
    partsListImage?: PartsListImage
}
export type PartsListImage = {
    description: string
    name: string
    imageUrl: string
    imageMapCoordinates: string
}
export type PartsListImageCoordinate = {
    id: number
    x1: number
    x2: number
    y1: number
    y2: number
    type: PartsListImageCoordinateType
    referencedArticle: number
}

export enum PartsListImageCoordinateType {
    Circle = 0,
    Rectangle = 1,
}

export type GetOeAttributesRequest = {
    kType: number
    oeNumbers: Array<string>
    productGroupIds?: number[]
}
export type GetOeAttributesResponse = {
    articleAttributes?: Array<ArticleAttributes>
    fittingPosition?: number
}

export type GetOeInformationResponse =
    | {
          oeInformation: OeInformation[]
      }
    | undefined

export type OeInformationRequest = {
    oeNumber: string
    modelId?: number
}

export type GetBestsellerSearchTreeRequest = {
    nodeId?: number
}

export type GetBestsellerCriteriaRequest = {
    selectedProductGroups: ProductGroupFilter[]
    selectedCriteria?: CriterionFilters[]
}
