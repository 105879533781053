import { Customer } from "@tm/models"
import { Tooltip, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Icon } from "@tm/controls"

type MemoProps = {
    customer: Customer
    className?: string
}

function Memo(props: MemoProps) {
    const { customer, className } = props
    const { translate } = useLocalization()

    if (!customer.memo) {
        return null
    }

    return (
        <Tooltip placement="top" title={customer.memo}>
            <div className={className}>
                <Icon name="notes" />
                {translate(39)}
            </div>
        </Tooltip>
    )
}

export default styled(Memo)(({ theme }) => ({
    alignSelf: "center",
    padding: theme.spacing(1),
    ".icon": {
        verticalAlign: "text-bottom",
        marginRight: theme.spacing(0.5),
    },
}))
