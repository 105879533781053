import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { WidgetCoverModules } from "../models"

export function fastCheckWidgetCoverFactory(userContext: UserContext): WidgetCoverModules | undefined {
    const fastCheckModule = getModuleFromUserContext(userContext, UserModuleType.FastCheck)

    if (!fastCheckModule) {
        return undefined
    }

    return {
        fastcheck: {
            sort: 21,
            moduleKey: "fastcheck",
            component: "widget-cover-only",
            id: "fast-check__widget",
            target: {
                url: "/:workTaskId/fastcheck",
            },
            title: "FastCheck",
            icon: "fast-check",
        },
    }
}
