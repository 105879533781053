import { useTelesalesCustomerNumber, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { createQueryString } from "@tm/utils"
import { getBundleParams } from "../utils"

export type ConfigQueryParams = { [key: string]: string | number | boolean }

export function useOffersUrl(configParams: ConfigQueryParams) {
    const baseUrl = getBundleParams().actionsBaseUrl
    const { userContext, userSettings } = useUser()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()
    const { languageId } = useLocalization()

    if (!baseUrl || !userContext || !enableServiceCalls || !userSettings) {
        return
    }

    const params = {
        ...configParams,
        mdmSessionId: userContext.id,
        lid: languageId,
        selectedCustomerNo: telesalesCustomerNo,
        ShowPrice: userSettings.showPurchasePrice,
    }

    return baseUrl + createQueryString(params)
}
