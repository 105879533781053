import { em, margin, percent } from "csx"
import { useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RouteComponentProps } from "react-router"
import { withRouter } from "react-router-dom"
import { Accordion, AccordionDetails, AccordionSummary, Icon, Typography } from "@tm/components"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { PanelSection, Scrollbar, SubTitle } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { bem } from "@tm/utils"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import SummaryFilters from "../summary/components/filters"
import { ListAttributeFilters, ListComponent } from "./components"
import { getBundleParams } from "../../utils"

type Props = RouteComponentProps<RouteProps> & {
	repairTimesRoute: string
	detailsModalRoute?: string
}

type RouteProps = {
	size?: string
	season?: string
	carType?: string
}

function TyresListComponent({ repairTimesRoute, detailsModalRoute }: Props) {
	const { translateText } = useLocalization()
	const dispatch = useDispatch()
    const toggleTiresAccordionState = useSelector((state: MainState) => state.summary.toggleTiresAccordion)
	const { isFiltersOnRightSide } = getBundleParams()
	const style = useMemo(() => getStyle(isFiltersOnRightSide), [])

	const renderList = () =>
		<ListComponent
			key="filters"
			detailsModalRoute={detailsModalRoute}
			className={style.list}
			repairTimesRoute={repairTimesRoute}
		/>

	const renderFilters = () =>
		<PanelSection key="list" className={style.filters}>
			<Scrollbar>
				<Accordion disableGutters expanded={toggleTiresAccordionState}>
					<AccordionSummary sx={{
						flexDirection: "row",
						mr: "5px",
						".MuiAccordionSummary-expandIconWrapper .icon": {
							opacity: 1
						},
						".MuiAccordionSummary-content": {
							alignItems: "center"
						}
					}} 
					onClick={() => dispatch(MainActions.toggleTiresAccordion())}>
						<Icon name="tyre-search" sx={{mr: "5px", opacity: 1}} />
						<Typography sx={{fontSize: "inherit", fontWeight: 600, opacity: 1}}>{translateText(3162)}</Typography>
					</AccordionSummary>
					<AccordionDetails sx={{p:0}}>
						<SummaryFilters />
					</AccordionDetails>
				</Accordion>
				<SubTitle className={bem(style.sub, "bordered")} >{translateText(3163)}</SubTitle>
				<ListAttributeFilters />
			</Scrollbar>
		</PanelSection>

	return (
		<div className={style.wrapper}>
			{[renderFilters(), renderList()]}
		</div>
	)
}

export default withRouter(TyresListComponent)

function getStyle(isFiltersOnRightSide?: boolean) {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            width: percent(100),
            display: "flex",
			flexDirection: isFiltersOnRightSide ? "row-reverse" : "row"
        },
        sub: {
            marginLeft: theme.margin.m,
            $nest: {
                "&--bordered": {
                    paddingTop: theme.margin.m
                }
            }

        },
        filters: {
            flexBasis: em(22),
            padding: theme.margin.m,
            margin: margin(theme.margin.m, theme.margin.s),
            display: "flex",
            flexDirection: "column",
            $nest: {
                ".panel__content": {
                    padding: 0,
                    flex: 1,
                },
            }

        },
        list: {
            flex: 1
        }
    })(TyresListComponent)
}
