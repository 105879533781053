import { AsyncAction } from "@tm/morpheus"
import { clone } from "@tm/utils"
import { batch } from "react-redux"
import { BundleActions, BundleActionTypes } from "../../../business"
import { useContainer } from "../../../data/repositories"
import { GetCalculationDataRequest } from "../../../data/repositories/getCalculationData/model"
import { MainState } from "../../main"
import { MainActions, MainActionsType } from "../../main/business"
import { ExternState, InitToolRequest } from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "SET_EXTERN_PROPS"; payload: { externalUrl: string; externalSessionId: string; historyId: number } }
    | { type: "RELOAD_IFRAME"; payload: boolean }
    | { type: "NEW_CALCULATION_LOADING"; payload: boolean }

export const DEFAULT_STATE: ExternState = {
    externalDataLoaded: false,
}

export const reduce = (state = clone(DEFAULT_STATE), action: MainActionsType): ExternState => {
    switch (action.type) {
        case "RESTART_PROCESS": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "NEW_CALCULATION_LOADING": {
            return {
                ...state,
                newCalculationLoading: action.payload,
            }
        }
        case "CALCULATION_DATA_LOADED": {
            return {
                ...state,
                externalUrl: action.payload.continuationEreUrl,
                externalSessionId: action.payload.continuationEreSessionId,
                newCalculationLoading: false,
            }
        }
        case "SET_EXTERN_PROPS": {
            return {
                ...DEFAULT_STATE,
                ...action.payload,
                newCalculationLoading: false,
            }
        }
        case "RELOAD_IFRAME": {
            return {
                ...state,
                resetIframe: action.payload,
            }
        }
        default: {
            return state
        }
    }
}

const initTool =
    (request: InitToolRequest): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        dispatch({ type: "NEW_CALCULATION_LOADING", payload: true })

        const { vehicle } = getState().manager
        const container = useContainer()

        dispatch(MainActions.removeIframe(true))

        if (request.hasCalcData) {
            const requestCalculationData: GetCalculationDataRequest = {
                contReturnUrl: request.returnUrl!,
                historyId: request.historyId,
                vehicleManufacturerId: vehicle?.tecDocManufacturerId,
                vehicleManufacturerName: vehicle?.manufacturer,
            }

            container
                .action("getCalculationData")(requestCalculationData)
                .then(
                    (response) =>
                        batch(() => {
                            dispatch({
                                type: "SET_EXTERN_PROPS",
                                payload: {
                                    externalUrl: response.continuationEreUrl,
                                    externalSessionId: response.continuationEreSessionId,
                                    historyId: request.historyId!,
                                },
                            })
                            dispatch({ type: "CALCULATION_DATA_LOADED", payload: response })
                        }),
                    () => dispatch({ type: "NEW_CALCULATION_LOADING", payload: false })
                )
        } else {
            container
                .action("getCalculationUrl")(request)
                .then(
                    (response) =>
                        dispatch({
                            type: "SET_EXTERN_PROPS",
                            payload: { externalUrl: response.ereUrl, externalSessionId: response.ereSessionId, historyId: response.historyId },
                        }),
                    () => dispatch({ type: "NEW_CALCULATION_LOADING", payload: false })
                )
        }
    }

const removeIframe = (value: boolean): ComponentActionType => ({ type: "RELOAD_IFRAME", payload: value })

export const Actions = {
    ...BundleActions,
    initTool,
    removeIframe,
}
