import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { FiltersSensorsRequest, FiltersSensorsResponse, FiltersResponse } from "./model"

export function loadSensorFilters(request: FiltersSensorsRequest): Promise<FiltersSensorsResponse> {
    const url = `${getServiceUrl()}/Filters/ProductGroups`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<FiltersResponse>({ method: "GET", url, body, authorization }).then(
        response => {
            if (response?.dataSupplierFilters && response?.dataSupplierFilters.length)
                return { manufacturer: response?.dataSupplierFilters }
            else
                return { manufacturer: [] }
        }
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.articlesServiceUrl
}
