import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { VinSuggestionsResponse } from "./model"

export const getVinSuggestions = async (query: string, modelId: number): Promise<Array<string>> => {
    const url = `${getServiceUrl()}/Vins`
    const authorization = getStoredAuthorization()
    const body = { query, modelId }

    return ajax<VinSuggestionsResponse>({ url, body, authorization }).then((data) => {
        if (!data) {
            throw new Error()
        }

        return data.vins || []
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.vinPickerUrl
}
