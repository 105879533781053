import { ActionDispatch } from "@tm/morpheus"

import { ArticleDetailsResponse } from "../../data/model/parts"

export type ArticleReferencesState = {
    internalArticleId?: number
}

export type ComponentActionType = { type: "DETAILSHEAD_LOADED"; payload: ArticleDetailsResponse }

const DEFAULT_STATE: ArticleReferencesState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ArticleReferencesState {
    switch (action.type) {
        case "DETAILSHEAD_LOADED": {
            return {
                ...state,
                internalArticleId: action.payload.article?.internalId,
            }
        }
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "DETAILSHEAD_LOADED": {
            dispatch(action)
            break
        }
    }
}
