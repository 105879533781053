import { useState, useRef, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { MessageToast } from "@tm/controls"

type Props = {
    skin: "success" | "danger" | "warning" | "primary" | undefined
    textId: number
}

function ConfirmationMessageComponent(props: Props) {
    const { translateText } = useLocalization()
    const [showMessageId, setShowMessageId] = useState<number | undefined>(props.textId)
    const closeTimeOutRef = useRef<number>()

    function hide() {
        setShowMessageId(undefined)
    }

    useEffect(() => {
        if (showMessageId) {
            closeTimeOutRef.current = window.setTimeout(hide, 4 * 1000)
        } else if (closeTimeOutRef.current) {
            window.clearTimeout(closeTimeOutRef.current)
        }
        return () => {
            if (closeTimeOutRef.current) {
                window.clearTimeout(closeTimeOutRef.current)
            }
        }
    }, [showMessageId])

    if (!showMessageId) {
        return null
    }

    return <MessageToast skin={props.skin} message={translateText(showMessageId)} onButtonClick={hide} />
}

export default ConfirmationMessageComponent
