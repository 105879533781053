import { RequestArticleListPayload } from "@tm/models"
import { Models } from "../../../data"
import { DetailsRequest } from "../../../data/model"

export function createListRequests(request: DetailsRequest, response: Models.ArticleDetailsResponse, foundBySearchTerm?: string) {
    const result: [string, RequestArticleListPayload][] = []

    if (!response.article) {
        return result
    }

    if (response.article.isPartsListAvailable === true) {
        result.push([
            "details-partslist",
            {
                partsList: {
                    articleId: response.article.internalId,
                    modelId: request.modelId,
                    vehicleType: request.vehicleType,
                    vehicleLinkageId: request.vehicleLinkageId,
                    engineCode: request.engineCode,
                },
                foundBySearchTerm,
                dontResetStateOnUnload: true,
            },
        ])
    }

    if (response.article.isAccessoryListAvailable === true) {
        result.push([
            "details-accessories",
            {
                accessoryList: {
                    articleId: response.article.internalId,
                    modelId: request.modelId,
                    vehicleType: request.vehicleType,
                    engineCode: request.engineCode,
                },
                foundBySearchTerm,
                dontResetStateOnUnload: true,
            },
        ])
    }

    if (response.partsListMainArticles && response.partsListMainArticles.length) {
        result.push([
            "details-mainpartslist",
            {
                articles: response.partsListMainArticles,
                foundBySearchTerm,
                dontResetStateOnUnload: true,
            },
        ])
    }

    if (response.accessoryListMainArticles && response.accessoryListMainArticles.length) {
        result.push([
            "details-mainaccessories",
            {
                articles: response.accessoryListMainArticles,
                foundBySearchTerm,
                dontResetStateOnUnload: true,
            },
        ])
    }
    return result
}
