import { TelematicsProvider, Vehicle } from "@tm/models"
import { useQuery } from "react-query"
import * as Data from "../../../data"

export function useClearanceStatus(provider: TelematicsProvider, vehicle: Vehicle | undefined) {
    const { data } = useQuery(
        ["telematics_useClearanceStatus", provider, vehicle?.vin],
        () => {
            if (vehicle?.vin) {
                return Data.showClearanceStatus(provider, vehicle.vin)
            }
        },
        {
            enabled: !!vehicle?.vin,
        }
    )

    return { clearanceStatus: data }
}
