import { Suspense, useEffect, useMemo } from "react"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { channel } from "@tm/models"
import { bem, useModelDetails } from "@tm/utils"
import { maintenancePlanActions, useFastServiceStore } from "../../data"
import { isOtherVehicle, isVehicleChanged } from "../../helpers/isVehicleChanged"

export default function ModuleManager() {
    const style = useMemo(() => getStyle(), [])

    return (
        <Suspense
            fallback={
                <div className={bem(style.wrapper, "loading")}>
                    <Loader className={style.loader} />
                </div>
            }
        >
            <Component />
        </Suspense>
    )
}

function Component() {
    const stateVehicle = useFastServiceStore((state) => state.maintenancePlan.stateVehicle)
    const { vehicle, id: workTaskId } = useWorkTask()?.workTask || {}
    const modelDetails = useModelDetails({ vehicleType: vehicle?.vehicleType, modelId: vehicle?.tecDocTypeId })
    const resetStore = useFastServiceStore((state) => state.resetStore)
    const setWorktaskId = useFastServiceStore((state) => state.setWorktaskId)
    const savedWorktaskId = useFastServiceStore((state) => state.worktaskId)

    useEffect(() => {
        saveVehicle()
    }, [vehicle, modelDetails])

    useEffect(() => {
        if (!workTaskId) {
            return
        }

        if (!savedWorktaskId) {
            setWorktaskId(workTaskId)
        }

        if (savedWorktaskId && workTaskId !== savedWorktaskId) {
            resetStore()
            saveVehicle()
            setWorktaskId(workTaskId)
        }
    }, [workTaskId, savedWorktaskId])

    function saveVehicle() {
        if (modelDetails && vehicle && isVehicleChanged(vehicle, stateVehicle)) {
            const shouldReset = isOtherVehicle(vehicle, stateVehicle)
            maintenancePlanActions.setVehicle(vehicle, modelDetails, shouldReset)
        }
    }

    useEffect(() => {
        const unsub = channel("WORKTASK", workTaskId).subscribe("MODULE/CLOSED", (module) => {
            if (module.includes("fast-service")) {
                resetStore()
            }
        })
        return () => {
            unsub()
        }
    }, [])

    return null
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            $nest: {
                "&--loading": {
                    pointerEvents: "none",
                    $nest: {
                        ">:not(.loader)": {
                            opacity: theme.opacity.disabled,
                        },
                    },
                },
            },
        },
        loader: {
            width: "2em",
            height: "2em",
            position: "absolute",
            top: "20em",
            left: "50%",
            zIndex: 1,
        },
    })(ModuleManager)
}
