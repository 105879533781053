import { ajax, Authorization } from "@tm/utils"
import { useQuery, useQueries, UseQueryResult } from "react-query"
import { useChatAuthorization } from "."
import { getBundleParams } from "../../utils"

type Response = Array<{ displayName: string; id: string }>

function load(authorization: Authorization, chatId: string) {
    const url = `${getBundleParams().chatApiUrl}/api/v1/user/chats/getforeignuserswithdisplaynames/${chatId}`
    return ajax<Response>({ url, method: "GET", authorization })
}

export function useForeignUsersInChat(chatId: string) {
    const authorization = useChatAuthorization()

    const { data, isLoading, isIdle } = useQuery(
        ["notifications_chat_foreignusersinchat", chatId],
        () => authorization && load(authorization, chatId),
        {
            enabled: !!authorization,
            staleTime: Infinity,
        }
    )

    return { foreignUsers: data, isLoading: isLoading || isIdle }
}

export function useForeignUsersInChatMulti(chatIds: Array<string>) {
    const authorization = useChatAuthorization()
    const results = useQueries(
        chatIds.map((chatId) => ({
            queryKey: ["notifications_chat_foreignusersinchat", chatId],
            queryFn: () => authorization && load(authorization, chatId),
            enabled: !!authorization,
            staleTime: Infinity,
        }))
    ) as Array<UseQueryResult<Response | undefined>>

    const dictionary: Record<string, Response | undefined> = {}
    chatIds.forEach((chatId, index) => {
        dictionary[chatId] = results[index]?.data
    })
    return dictionary
}
