import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { FastServiceData } from "../../models"
import { mapFastServiceResponse } from "../generalMapper"
import { GetMaintenancePlanRequest } from "./model"

export function getMaintenancePlan(data: GetMaintenancePlanRequest): Promise<FastServiceData> {
    const url = `${getServiceUrl()}/fastcalculator/FastServiceGetMaintenancePlan`
    const authorization = getStoredAuthorization()
    const body = data

    return ajax({ url, body, authorization, method: "POST" }).then((res) => {
        if (res && res.cockpitContext) {
            return mapFastServiceResponse(res)
        }
        throw new Error()
    })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
