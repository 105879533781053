import { channel, RequestArticleListPayload } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenRapidCalculationList(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (data.openRapidCalculationList) {
        const oeReferenceNos = new Set<string>()
        data.openRapidCalculationList.oeArticleLists.forEach((x) => {
            oeReferenceNos.add(x.oeNo)
        })

        // TODO: to change when the Shopping basket supports only Oenumbers
        const request: RequestArticleListPayload = {
            direct: {
                query: Array.from(oeReferenceNos)[0],
            },
            inModal: true,
        }
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}
