import { Badge, Icon, Stack } from "@tm/components"
import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { Button, Image, LicensePlateField, Loader, Table, Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { RouteComponentProps, encodeUniqueId, renderRoute, uniqueId, withRouter } from "@tm/utils"
import { useEffect } from "react"
import { getWorkTaskLabellingFromListItem } from "../../helpers"
import { Actions, IActions } from "./business"
import { RecentListState, WorkTaskListItem } from "./business/model"

type Props = RouteComponentProps<{ workTaskId?: string }> & {
    state: RecentListState
    actions: IActions
    className?: string
    customerListRoute: string
}

function RecentListComponent(props: Props) {
    const context = useWorkTask()
    const localization = useLocalization()
    const { translate, date: renderDate } = localization
    const { plateCode } = useCountryCodeToLicensePlate(context?.workTask?.vehicle?.countryCode)

    useEffect(() => {
        props.actions.load()
    }, [])

    const renderDateAndPlateCell = (workTask: WorkTaskListItem) => {
        const { createdDate, modifiedDate, vehiclePlateId } = workTask
        const date = modifiedDate || createdDate
        return (
            <Table.Cell>
                <div className="time">
                    {renderDate(date, "T")}
                    <br />
                    {renderDate(date, "d")}
                </div>
                <div className="plate">
                    {vehiclePlateId && <LicensePlateField shortCountryCode={plateCode} readonly size="xs" value={vehiclePlateId} />}
                </div>
            </Table.Cell>
        )
    }

    const renderTotalNumbersCell = (workTask: WorkTaskListItem) => {
        const { partPositionsTotal, workPositionsTotal } = workTask
        return (
            <Table.Cell>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Icon name="article" />
                    <Badge color="primary" size="small" badgeContent={partPositionsTotal || "0"} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1.5}>
                    <Icon name="repairtimes" />
                    <Badge color="primary" size="small" badgeContent={workPositionsTotal || "0"} />
                </Stack>
            </Table.Cell>
        )
    }

    const renderVehicleImageCell = (workTask: WorkTaskListItem) => {
        return (
            <Table.Cell>
                <Image url={workTask.vehicleThumbnail!} className="vehicle-thumbnail" />
            </Table.Cell>
        )
    }

    const renderCustomerAndVehicleCell = (workTask: WorkTaskListItem) => {
        const labelling = getWorkTaskLabellingFromListItem(workTask, localization)
        return (
            <Table.Cell>
                <div className="inner">
                    <div className="customer">{labelling.title}</div>
                    {!!labelling.info && <div className="vehicle">{labelling.info}</div>}
                </div>
            </Table.Cell>
        )
    }

    const getRowLink = (workTask: WorkTaskListItem) => {
        return `/${encodeUniqueId(workTask.workTaskId)}`
    }

    const renderWidgetHeader = () => {
        const { customerListRoute, match } = props

        const url = renderRoute(customerListRoute, { ...match.params, workTaskId: match.params.workTaskId || encodeUniqueId(uniqueId()) })

        return (
            <Widget.Header key="widget__header">
                <Widget.Header.Title>
                    <Icon name="menu" size="s" className="widget__icon" />
                    <Widget.Header.Title.Text>{translate(336)}</Widget.Header.Title.Text>
                </Widget.Header.Title>
                <Widget.Header.Title.Options>
                    <Button linkTo={url}>{translate(175)}</Button>
                </Widget.Header.Title.Options>
            </Widget.Header>
        )
    }

    const className = `tk-worktask recent-list ${props.className || ""}`

    const columns = [
        <Table.Column className="date-plate" renderItemContent={renderDateAndPlateCell} />,
        <Table.Column className="total-numbers" renderItemContent={renderTotalNumbersCell} />,
        <Table.Column className="vehicle-image" renderItemContent={renderVehicleImageCell} />,
        <Table.Column className="customer-vehicle" renderItemContent={renderCustomerAndVehicleCell} />,
    ]

    return (
        <Widget className={className} active size="4x4" iconName="menu" title={translate(336)} header={renderWidgetHeader()}>
            <div className="list">
                <Loader visible={props.state.loading} />
                <Table scrollable data={props.state.workTasks} columns={columns} getRowClassName={() => "card"} getRowLink={getRowLink} />
            </div>
        </Widget>
    )
}

export default connectComponent(Actions, withRouter(RecentListComponent))
