import { CostEstimationOptions, AddCustomArticleItemsRequest, SelectedPriceTypeMode, VatRate } from "@tm/models"
import { Paper } from "@tm/components"
import { addCustomArticleItems } from "../../../../../data/repositories/custom-items/articles"
import PartFields from "./part-fields"
import { BasketPart } from "../../../../../models"

type Props = {
    part: BasketPart
    costEstimationOptions?: CostEstimationOptions
    currencyCode: string
    hidePurchasePrice: boolean
    showManufacturer: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    onClosePartEditor?(id: string): void
    onEditPart?(
        part: BasketPart,
        rebate?: number,
        surcharge?: number,
        regularPrice?: number,
        customPartNumber?: string,
        customPartDescription?: string,
        vatRate?: VatRate
    ): void
}

export default function PartEditor(props: Props) {
    const {
        part,
        hidePurchasePrice,
        showManufacturer,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        currencyCode,
        costEstimationOptions,
    } = props
    const { priceTypeMode, priceVatMode } = costEstimationOptions ?? {}

    function handleEditPrice(
        saveAsCustomArticle: boolean,
        rebate?: number,
        surcharge?: number,
        regularPrice?: number,
        customPartNumber?: string,
        customPartDescription?: string,
        category?: number,
        vatRate?: VatRate
    ) {
        props.onEditPart?.(part, rebate, surcharge, regularPrice, customPartNumber, customPartDescription, vatRate)
        props.onClosePartEditor?.(part.partItem.id)
        if (saveAsCustomArticle && customPartDescription) {
            const request: AddCustomArticleItemsRequest = {
                customArticleItemsToAdd: [
                    {
                        articleNumber: customPartNumber,
                        description: customPartDescription,
                        vatRate,
                        category,
                        purchasePrice: priceTypeMode === SelectedPriceTypeMode.Purchase ? regularPrice : undefined,
                        retailPrice: !priceTypeMode ? regularPrice : undefined,
                        isDefaultForCategory: false,
                    },
                ],
                priceVatMode,
            }
            addCustomArticleItems(request)
        }
    }

    return (
        <Paper>
            <PartFields
                defaultCurrencyCode={currencyCode}
                part={part}
                hidePurchasePrice={hidePurchasePrice}
                isEditMode
                showManufacturer={showManufacturer}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                onClose={() => props.onClosePartEditor?.(part.partItem.id)}
                onEditPart={handleEditPrice}
            />
        </Paper>
    )
}
