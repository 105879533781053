import { Box, Collapse, Divider, Grid, Icon, IconButton, LinearProgress, Stack, Tooltip, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { TmaHelper } from "@tm/utils"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useRecoilState } from "recoil"
import { ECounterType, ListFilter } from "@tm/models"
import { useAvailabilityStatus } from "@tm/context-distribution"
import { useArticleListConfiguration } from "../../ArticleListConfiguration"
import { usePartsModuleState } from "../../PartsModuleState"
import { FilterTypeVisibilitiesState } from "../../states"
import { ExtendedAssortmentFilter } from "./ExtendedAssortmentFilter"
import { FiltersArea, FiltersAreaActions } from "./FiltersArea"
import { FiltersSkeleton } from "./FiltersSkeleton"
import { FittingPositions } from "./FittingPositions"
import { GroupedAttributeFiltersArea } from "./GroupedAttributeFiltersArea"
import { useListType } from "./useListType"

type Props = {
    className?: string
}

const filterVisibilitiesType = "container"

export const Filters = memo<Props>(({ className }) => {
    const { filtersAtLeftSide } = useArticleListConfiguration()

    const {
        isLoading,
        isRefetching,
        productGroupFilters,
        dataSupplierFilters,
        attributeFilters,
        groupedAttributeFilters,
        showOnlyAvailable,
        showOnlyAvailableSecondary,
        showExtendedAssortmentFilter,
        showFittingPositionsFilter,
        extendedAssortment,
        toggleProductGroup,
        toggleSupplier,
        toggleAvailability,
        toggleAvailabilitySecondary,
        toggleExtendedAssortment,
        toggleAttribute,
        resetProductGroups,
        resetSuppliers,
        resetAttributes,
        resetAvailability,
        resetAll,
    } = usePartsModuleState((x) => x.filters)

    const productGroupFiltersRef = useRef<FiltersAreaActions>(null)
    const supplierFiltersRef = useRef<FiltersAreaActions>(null)

    const listType = useListType()
    const { translateText } = useLocalization()
    const [visibilities, setVisibilities] = useRecoilState(FilterTypeVisibilitiesState(listType))
    const filtersVisible = useMemo(() => visibilities[filterVisibilitiesType] ?? true, [visibilities])
    const [visibilityButtonMinimized, setVisibilityButtonMinimized] = useState(!filtersVisible)
    const [isStartPhase, setIsStartPhase] = useState(true)
    const { availabilityStatusIdsToShow, availabilityStatusIdsToShowSecondary } = useAvailabilityStatus()

    useEffect(() => {
        setTimeout(() => setIsStartPhase(false), 100)
    }, [])

    const productGroups = useMemo(() => {
        return {
            display: productGroupFilters.filter(([filter]) => filter.showOnTop),
            more: productGroupFilters.filter(([filter]) => !filter.showOnTop),
        }
    }, [productGroupFilters])

    const suppliers = useMemo(() => {
        return {
            display: dataSupplierFilters.filter(([filter]) => filter.showOnTop),
            more: dataSupplierFilters.filter(([filter]) => !filter.showOnTop),
        }
    }, [dataSupplierFilters])

    const attributes = useMemo(() => {
        return {
            display: attributeFilters,
            more: [],
        }
    }, [attributeFilters])

    const availability: [ListFilter, boolean][] = useMemo(() => {
        const filter: [ListFilter, boolean][] = []

        if (availabilityStatusIdsToShow?.length) {
            filter.push([
                {
                    id: 0,
                    key: "stock",
                    name: translateText(1623),
                },
                showOnlyAvailable,
            ])
        }

        if (availabilityStatusIdsToShowSecondary?.length) {
            filter.push([
                {
                    id: 1,
                    key: "stock",
                    name: translateText(12860),
                },
                showOnlyAvailableSecondary,
            ])
        }

        return filter
    }, [showOnlyAvailable, showOnlyAvailableSecondary, translateText])

    const handleChangeExtendedAssortmentFilter = useCallback(
        (enabled: boolean) => {
            if (enabled) {
                productGroupFiltersRef.current?.expandMore()
                supplierFiltersRef.current?.expandMore()
            } else {
                productGroupFiltersRef.current?.collapseMore()
                supplierFiltersRef.current?.collapseMore()
            }

            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowAllItems, enabled)
            TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
            toggleExtendedAssortment()
        },
        [toggleExtendedAssortment]
    )

    const handleChangeProductGroup = useCallback(
        (filter: ListFilter, exclusive?: boolean) => {
            TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
            toggleProductGroup(filter.id, exclusive)
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListFilterSelectProductGroup, !filter.isSelected)
        },
        [toggleProductGroup]
    )

    const handleChangeSupplier = useCallback(
        (filter: ListFilter, exclusive?: boolean) => {
            TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
            toggleSupplier(filter.id, exclusive)
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListFilterSelectSupplier, !filter.isSelected)
        },
        [toggleSupplier]
    )

    const handleChangeAttribute = useCallback(
        (filter: ListFilter, exclusive?: boolean) => {
            if (!toggleAttribute) {
                return
            }
            TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
            toggleAttribute(filter, exclusive)
        },
        [toggleAttribute]
    )

    const handleToggleFilterVisibility = useCallback(() => {
        if (filtersVisible) {
            setVisibilityButtonMinimized(true)
        }
        filtersVisible
            ? TmaHelper.GeneralCountEvent.Call(ECounterType.SidePaneCollapse)
            : TmaHelper.GeneralCountEvent.Call(ECounterType.SidePaneExpand)

        setVisibilities({ ...visibilities, [filterVisibilitiesType]: !filtersVisible })
    }, [filtersVisible, visibilities, setVisibilities, setVisibilityButtonMinimized])

    const handleCollapseExit = useCallback(() => {
        if (filtersVisible) {
            setTimeout(() => {
                setVisibilityButtonMinimized(false)
            }, 500)
        }
    }, [filtersVisible, setVisibilityButtonMinimized])

    const handleResetFilters = useCallback(() => {
        TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
        resetAttributes?.()
    }, [resetAttributes])

    const handleAvailability = useCallback(
        (filter: ListFilter) => {
            TmaHelper.Shared.ByArticleAndUniParts.IncreaseStepNumber()
            switch (filter.id) {
                case 0: {
                    if (filtersVisible) {
                        toggleAvailability()
                        TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListShowOnStockOnlyItems, !showOnlyAvailable)
                    }
                    break
                }
                case 1: {
                    if (filtersVisible) {
                        toggleAvailabilitySecondary()
                    }
                    break
                }
                default:
                    break
            }
        },
        [toggleAvailability, toggleAvailabilitySecondary]
    )

    useEffect(() => {
        if (extendedAssortment.forced) {
            productGroupFiltersRef.current?.expandMore()
            supplierFiltersRef.current?.expandMore()
        } else if (!extendedAssortment.enabled) {
            productGroupFiltersRef.current?.collapseMore()
            supplierFiltersRef.current?.collapseMore()
        }
    }, [extendedAssortment.forced, extendedAssortment.enabled])

    function getCollapseIcon(): string {
        if (filtersAtLeftSide) {
            return filtersVisible ? "arrow-left" : "arrow-right"
        }

        return filtersVisible ? "arrow-right" : "arrow-left"
    }

    const showAvailabilityFilter = () => {
        return !!availabilityStatusIdsToShow?.length || !!availabilityStatusIdsToShowSecondary?.length
    }

    return (
        <Stack className={className} overflow="hidden">
            <Stack flex="0 0 auto" direction="row" alignItems="center" alignSelf="flex-start" sx={{ width: "100%" }}>
                <Tooltip title={translateText(visibilityButtonMinimized ? 13483 : 13484)} enterDelay={1000}>
                    <IconButton onClick={handleToggleFilterVisibility}>
                        <Icon size="16px" name={getCollapseIcon()} />
                    </IconButton>
                </Tooltip>
                <Box display="flex" justifyContent="space-between" width="100%">
                    {!visibilityButtonMinimized && <Typography>{translateText(209)}</Typography>}
                    {!visibilityButtonMinimized && (
                        <Tooltip title={translateText(13857)} enterDelay={1000}>
                            <Icon sx={{ mr: "1.2em", cursor: "pointer" }} name="remove-filter" onClick={resetAll} />
                        </Tooltip>
                    )}
                </Box>
            </Stack>
            {isRefetching ? <LinearProgress color="primary" sx={{ height: 3 }} /> : <Divider sx={{ borderColor: "#ccc" }} />}

            <Collapse in={filtersVisible} orientation="horizontal" sx={{ overflow: "hidden", flex: 1 }} addEndListener={handleCollapseExit}>
                {(isLoading || isStartPhase) && filtersVisible ? (
                    <Box width={290} key="loading">
                        <FiltersSkeleton />
                    </Box>
                ) : (
                    <Grid item xs sx={{ overflowY: "scroll" }} width={290} height="100%">
                        <Box pr={0.5} pt={1} position="relative">
                            {showExtendedAssortmentFilter && (
                                <ExtendedAssortmentFilter
                                    state={extendedAssortment.enabled || extendedAssortment.forced}
                                    disabled={extendedAssortment.forced}
                                    onChange={handleChangeExtendedAssortmentFilter}
                                />
                            )}
                            {showAvailabilityFilter() && (
                                <FiltersArea
                                    filtersType="availability"
                                    data={{ display: availability, more: [] }}
                                    headline={translateText(746)}
                                    onChange={handleAvailability}
                                    onResetFilters={resetAvailability}
                                    hideFilterSearch
                                />
                            )}
                            {!!productGroupFilters.length && (
                                <FiltersArea
                                    filtersType="productGroups"
                                    data={productGroups}
                                    headline={translateText(755)}
                                    onChange={handleChangeProductGroup}
                                    onResetFilters={resetProductGroups}
                                    ref={productGroupFiltersRef}
                                />
                            )}
                            {!!dataSupplierFilters.length && (
                                <FiltersArea
                                    filtersType="suppliers"
                                    data={suppliers}
                                    headline={translateText(71)}
                                    onChange={handleChangeSupplier}
                                    onResetFilters={resetSuppliers}
                                    ref={supplierFiltersRef}
                                />
                            )}
                            {!!attributeFilters.length && (
                                <FiltersArea
                                    filtersType="attributes"
                                    data={attributes}
                                    headline={translateText(750)}
                                    onChange={handleChangeAttribute}
                                    onResetFilters={resetAttributes}
                                />
                            )}
                            {!!groupedAttributeFilters.length && (
                                <GroupedAttributeFiltersArea
                                    data={groupedAttributeFilters}
                                    headline={translateText(750)}
                                    onChange={handleChangeAttribute}
                                    onResetFilters={handleResetFilters}
                                />
                            )}

                            {showFittingPositionsFilter && <FittingPositions />}
                        </Box>
                    </Grid>
                )}
            </Collapse>
        </Stack>
    )
})
