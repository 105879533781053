import { useCallback } from "react"
import { ReplaceButton as ReplaceButtonControl } from "@tm/controls"
import { channel, EReplaceButtonBundle, ReplaceButtonPayload } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { styled } from "@tm/components"

type Props = ReplaceButtonPayload & {
    disabled?: boolean
}

const StyledReplaceButton = styled(ReplaceButtonControl, { shouldForwardProp: (props) => props !== "isFastCalculator" })<{
    isFastCalculator?: boolean
}>(({ isFastCalculator }) => ({
    ...(isFastCalculator && {
        "&&": {
            minWidth: "6.25em",
            marginRight: "0.5em",
        },
    }),
}))

export function ReplaceButton({ part, bundle, disabled }: Props) {
    const isFastCalculator = bundle === EReplaceButtonBundle.FastCalculatorNext
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    const handleOnClick = useCallback(() => {
        channel("GLOBAL").publish("PARTS/REPLACE_PART", { part, isNewList: false, bundle })
    }, [part, bundle])

    return (
        <StyledReplaceButton
            className="price"
            isWm={isWm && !isFastCalculator}
            onClick={handleOnClick}
            disabled={disabled}
            isFastCalculator={isFastCalculator}
        />
    )
}
