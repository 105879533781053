import type { ModuleNavigationState } from "@tm/models"
import type { AsyncAction } from "@tm/morpheus"
import { encodeUniqueId } from "@tm/utils"
import { ComponentActionType } from "."
import { loadState } from "../../../data"

export type IActions = typeof Actions

export const Actions = {
    loadOpenTabs,
}

function loadOpenTabs(workTaskId: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "OPEN_TABS_LOADING" })

        loadState<ModuleNavigationState>(`${encodeUniqueId(workTaskId)}__module-navigation__`).then((state) => {
            dispatch({ type: "OPEN_TABS_LOADED", payload: state && state.value.tabs })
        })
    }
}
