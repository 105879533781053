import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const startWidget: BundleComponent = {
    name: "start-widget",
    reduce,
    component,
}

export default startWidget
