import { channel, SearchFilters } from "@tm/models"
import { concat } from "@tm/utils"

import { PostMessageRequest } from "../../../data"
import { mapShowPartsListByTextToRequestArticleList } from "../business"
import { PostMessageControllerComponent } from "../component"
import { shouldUseNewModal } from "./helpers/newPartslist"

export default function handleOpenOeAftermarket(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { oeNumber, altOeNumber, manufacturerIds, manufacturerId } = data.openOeAftermarket ?? {}

    if (oeNumber) {
        const request = mapShowPartsListByTextToRequestArticleList(
            concat(";", oeNumber, altOeNumber),
            manufacturerIds ?? (manufacturerId ? [manufacturerId] : undefined),
            this.props.oeAftermarketSearchFilter || SearchFilters.OeReference
        )

        request.useNewModal = shouldUseNewModal(data.sourceId)
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}
