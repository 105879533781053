import { DATWork, Discount, DiscountType, EDatRepairworkType, eOEArtType } from "@tm/models"
import { mapCategoryOfWork, uniqueId } from "@tm/utils"
import { ICalculationItem } from "../../model"
import { GetDatCalculationDataResponse } from "./model"

export const mapDatCalculationData = (calculationData: GetDatCalculationDataResponse): GetDatCalculationDataResponse => {
    return {
        iid: calculationData.iid,
        items: calculationData.items?.map(mapOeArticle),
        works: calculationData.works?.map(mapWork),
        vin: calculationData.vin,
        smallPartsPercentage: calculationData.smallPartsPercentage,
        isValidVIN: calculationData.isValidVIN,
        discounts: calculationData.discounts?.map(mapDiscounts),
        eCode: calculationData.eCode,
        kba: calculationData.kba,
        totals: calculationData.totals,
        initialSparePartsPrice: calculationData.initialSparePartsPrice,
    }
}

const mapOeArticle = (item: any): ICalculationItem => {
    const isDatItem = !!item.oeArticle.type // set Oe part as DAT if type != 0 or undefined

    return {
        alternativeParts: item.parts,
        oeArticle: {
            ...item.oeArticle,
            type: item.oeArticle.type || eOEArtType.MaterialPosition,
            ...(isDatItem && { vehicleManufacturerName: "DAT", thumbnailUrl: "/styles/base/images/dat-logo.png" }),
            priceInPercent: item.oeArticle.priceInPercent,
        },
        isSelected: item.isSelected,
        uniqueId: uniqueId(),
        hasAlternatives: item.hasAlternatives,
    }
}

const mapWork = (work: any): DATWork => {
    return {
        ...work,
        type: work.type || EDatRepairworkType.PaintWork,
        categoryOfWork: mapCategoryOfWork(work.datCategoryOfWork),
    }
}

const mapDiscounts = (discount: Discount): Discount => {
    return {
        isPercentage: discount.isPercentage,
        type: discount.type || DiscountType.Total,
        value: discount.value,
    }
}
