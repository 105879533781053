import { AddCustomWorkListRequest, AddRepairTimeListRequest, ChangeItemsResponse, RepairTimeProvider } from "@tm/models"
import { ajax, getStoredAuthorization, TmaHelper } from "@tm/utils"

import { getBasketServiceUrl } from "../.."
import {
    BasketHasRepairTime,
    ChangeWorkItemListResponse,
    ChangeWorkItemResponse,
    EditCustomWorkRequest,
    EditRepairTimeCalculationRequest,
    ReplaceByCustomWorkRequest,
    ResetRepairTimeListRequest,
} from "../../model"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Works`
}

export function addRepairTimeList(request: AddRepairTimeListRequest) {
    const url = `${getServiceUrl()}/AddRepairTimeList`
    const authorization = getStoredAuthorization()

    request.log = TmaHelper.AddRepairTimeList.GetUserInteractionLog(request.provider)

    return ajax<ChangeItemsResponse>({ url, body: request, authorization, method: "POST" })
}

export function addCustomWorkList(body: AddCustomWorkListRequest) {
    const url = `${getServiceUrl()}/AddCustomWorkList`
    const authorization = getStoredAuthorization()

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

// TODO: Delete when no longer used from the registered models
export function removeWork(id: string): Promise<void> {
    const url = `${getServiceUrl()}/RemoveWork`
    const authorization = getStoredAuthorization()
    const body = { id }

    return ajax({ url, body, authorization, method: "POST" })
}

export function editCustomWork(body: EditCustomWorkRequest) {
    const authorization = getStoredAuthorization()
    const url = `${getServiceUrl()}/EditCustomWork`

    return ajax<ChangeWorkItemResponse>({ url, body, authorization, method: "POST" })
}

export function editRepairTimeCalculation(body: EditRepairTimeCalculationRequest) {
    const authorization = getStoredAuthorization()
    const url = `${getServiceUrl()}/EditRepairTimeCalculation`

    return ajax<ChangeWorkItemResponse>({ url, body, authorization, method: "POST" })
}

export function resetRepairTimeList(body: ResetRepairTimeListRequest) {
    const url = `${getServiceUrl()}/ResetRepairTimeList`
    const authorization = getStoredAuthorization()

    return ajax<ChangeWorkItemListResponse>({ url, body, authorization, method: "POST" })
}

export function removeWorkList(workTaskId: string, idList: string[]) {
    const url = `${getServiceUrl()}/RemoveWorkList`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, idList }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function replaceByCustomWork(body: ReplaceByCustomWorkRequest) {
    const url = `${getServiceUrl()}/ReplaceByCustomWork`
    const authorization = getStoredAuthorization()

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

type NexusHasRepairTimesRequest = {
    workTaskId: string
    repairTimesProvider: RepairTimeProvider
    repairTimeProviderWorkId: string
    resolve: (items: BasketHasRepairTime) => void
    reject: (error: string) => void
}

const nexusQueue: NexusHasRepairTimesRequest[] = []
let nexusBufferTimeout: number

const bufferTimespanMs = 25 // defines the timespan (in ms) in which the requests will be buffered
const maxQueueLength: number | undefined = undefined // defines the maximum number of items in a single service call

// TODO: Delete when no longer used from the registered models
/**
 * @memberof Basket
 * Get basket part information with buffering so multiple nearly simultaneous requests would only result in one single service call
 * @param {boolean} sendImmediately Optional: if provided the request will be send immediately without buffering
 */
export function hasRepairTimesNexus(
    request: { workTaskId: string; repairTimesProvider: RepairTimeProvider; repairTimeProviderWorkId: string },
    sendImmediately?: boolean
): Promise<BasketHasRepairTime> {
    return new Promise<BasketHasRepairTime>((resolve, reject) => {
        const nexusRequest: NexusHasRepairTimesRequest = { ...request, resolve, reject }

        if (sendImmediately) {
            requestHasRepairTimesNexus(nexusRequest)
            return
        }

        // if there is already an identical request pending do nothing
        if (
            nexusQueue.some(
                (x) =>
                    x.workTaskId === nexusRequest.workTaskId &&
                    x.repairTimesProvider === nexusRequest.repairTimesProvider &&
                    x.repairTimeProviderWorkId === nexusRequest.repairTimeProviderWorkId
            )
        ) {
            return
        }

        nexusQueue.push(nexusRequest)

        clearTimeout(nexusBufferTimeout)

        if (maxQueueLength && nexusQueue.length >= maxQueueLength) {
            requestHasRepairTimesNexus()
            return
        }

        nexusBufferTimeout = window.setTimeout(() => requestHasRepairTimesNexus(), bufferTimespanMs)
    })
}

function requestHasRepairTimesNexus(request?: NexusHasRepairTimesRequest) {
    const url = `${getServiceUrl()}/HasRepairTimes`
    const authorization = getStoredAuthorization()

    // If supplied the given request will be used instead of the queue
    const requestItems = request ? [request] : nexusQueue.splice(0, nexusQueue.length) // splice to remove request items from queue...

    if (!requestItems.length) {
        return
    }

    function sendRequest(workTaskId: string, providerId: number, requestItems: NexusHasRepairTimesRequest[]) {
        const body = {
            workTaskId,
            repairTimesProvider: providerId,
            repairTimeProviderWorkIds: requestItems.map((x) => x.repairTimeProviderWorkId),
        }

        ajax({ url, body, authorization, method: "POST" }).then(
            (data) => {
                let items: BasketHasRepairTime[] = data?.hasRepairTimeList ?? []
                items = items.filter((x) => !!x)

                requestItems.forEach((requestItem) => {
                    const responseItem = items.find(
                        (x) =>
                            requestItem.workTaskId === body.workTaskId &&
                            requestItem.repairTimesProvider === body.repairTimesProvider &&
                            requestItem.repairTimeProviderWorkId === x.repairTimeProviderWorkId
                    )

                    if (responseItem) {
                        requestItem.resolve(responseItem)
                    } else {
                        requestItem.reject("No corresponding item in response")
                    }
                })
            },
            (error) => requestItems.forEach((item) => item.reject(error))
        )
    }

    // Group requests by workTaskId and then by repairTimesProvider
    // and send out a single requests for each combination
    /** @todo Service should be refactored to support workTaskId and repairTimesProvider for each item */
    Object.entries(requestItems.groupBy((x) => x.workTaskId)).forEach(([workTaskId, requests]) => {
        Object.entries(requests.groupBy((x) => x.repairTimesProvider)).forEach(([provider, requests]) => {
            sendRequest(workTaskId, parseInt(provider), requests)
        })
    })
}
