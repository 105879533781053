import { Article } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { useCallback, useState } from "react"
import { useVehicle } from "../useVehicle"

export function useHandleClickImage(article: Article) {
    const vehicle = useVehicle()
    const [showImageViewer, setShowImageViewer] = useState(false)

    const handleClickImage = useCallback(() => {
        setShowImageViewer(true)
        TmaHelper.Article.Parts.ImageClick.Click(article, vehicle?.id)
    }, [vehicle?.id, article])

    const handleCloseImage = useCallback(() => {
        setShowImageViewer(false)
    }, [])

    return { showImageViewer, handleClickImage, handleCloseImage }
}
