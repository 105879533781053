import { useMutation } from "react-query"
import { DrivemotiveLoginRequest } from "../model"
import * as Data from ".."

type Props = {
    onLoginSuccess: () => void
}

export function useGetLoginSuccess({ onLoginSuccess }: Props) {
    return useMutation({
        mutationFn: (request: DrivemotiveLoginRequest) => Data.getDrivemotiveLoginResponse(request),
        onSuccess() {
            onLoginSuccess()
        },
    })
}
