import { useLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { DetailsReferencesState, Actions } from "./business"
import ReferencesComponent from "../_shared/references"

type Props = {
    state: DetailsReferencesState
}

function DetailsReferencesComponent({ state }: Props) {
    const { translate } = useLocalization()

    if (!state.detailsResponse || !state.detailsResponse.article || !state.detailsResponse.article.references) {
        return <div className="headline headline--s">{translate(391)}</div>
    }

    return <ReferencesComponent className="article-details__references" article={state.detailsResponse.article} />
}

export default connectComponent(Actions, DetailsReferencesComponent)
