import { UserContext } from "@tm/models"
import { merge } from "lodash"
import { createWidgetModuleGroups } from "./createWidgetModuleGroups"
import { createWidgetCoverModules } from "./createWidgetCoverModules"
import { AllModulesKeys, ItemPosition, ModuleKeys, WidgetBaseProp, WidgetModules } from "./models"

export function createWidgetModules(
    userContext: UserContext,
    position: ItemPosition,
    overrides?: Partial<Record<AllModulesKeys, WidgetBaseProp<ModuleKeys>>>
): WidgetModules {
    function getSubSort(widgetKey: AllModulesKeys, itemKey: ModuleKeys): number | undefined {
        return overrides?.[widgetKey]?.subModules?.[itemKey]?.sort
    }

    function getSort(widgetKey: AllModulesKeys): number | undefined {
        return overrides?.[widgetKey]?.sort
    }

    function getSubHidden(widgetKey: AllModulesKeys, itemKey: ModuleKeys): boolean | undefined {
        return overrides?.[widgetKey]?.subModules?.[itemKey]?.hidden
    }

    function getHidden(widgetKey: AllModulesKeys): boolean | undefined {
        return overrides?.[widgetKey]?.hidden
    }

    const widgetModuleGroups = createWidgetModuleGroups(userContext, position)

    Object.keys(widgetModuleGroups).forEach((widgetModuleKey) => {
        const widget = widgetModuleGroups[widgetModuleKey]

        if (!Object.keys(widget?.items)?.length) {
            delete widgetModuleGroups[widgetModuleKey]
            return
        }

        if (!overrides) {
            return
        }

        if (getHidden(widgetModuleKey)) {
            delete widgetModuleGroups[widgetModuleKey]
            return
        }

        widget.sort = getSort(widgetModuleKey) ?? widget.sort

        Object.keys(widget.items).forEach((itemKey) => {
            if (getSubHidden(widgetModuleKey, itemKey)) {
                delete widget.items[itemKey]
                return
            }

            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            widget!.items![itemKey]!.sort = getSubSort(widgetModuleKey, itemKey) ?? widget!.items![itemKey]!.sort
        })

        if (!Object.keys(widget.items)?.length) {
            delete widgetModuleGroups[widgetModuleKey]
        }
    })

    const widgetCoverModules = createWidgetCoverModules(userContext, position)

    if (overrides) {
        Object.keys(widgetCoverModules).forEach((widgetModuleKey) => {
            if (getHidden(widgetModuleKey)) {
                delete widgetCoverModules[widgetModuleKey]
                return
            }

            widgetCoverModules[widgetModuleKey]!.sort = getSort(widgetModuleKey) ?? widgetCoverModules[widgetModuleKey]!.sort
        })
    }

    const widgetModules: WidgetModules = merge(widgetModuleGroups, widgetCoverModules)

    return widgetModules
}
