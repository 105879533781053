import { em, percent } from "csx"
import { PropsWithChildren, useMemo } from "react"
import tinycolor from "tinycolor2"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Collapsible, Icon } from "@tm/controls"
import { bem } from "@tm/utils"

type Props = {
    title: string
    checked?: boolean
}

export default function DcCollapsible({ title, children, checked }: PropsWithChildren<Props>) {
    const style = useMemo(() => getStyle(), [])

    const renderCollapsibleHeader = () => {
        if (checked === undefined) {
            return null
        }

        return (
            <div className={bem(style.header, checked && "active")}>
                <Icon name="check" />
            </div>
        )
    }

    return (
        <Collapsible name={title} initiallyOpened renderHeaderAppendix={renderCollapsibleHeader} className={style.colap}>
            {children}
        </Collapsible>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        colap: {
            background: "#fff",
            // border: "1px solid transparent",
            borderRadius: theme.box.radius,
            marginBottom: theme.margin.m,
            boxShadow: " 0 1px 5px rgb(0 0 0 / 20%)",
            $nest: {
                ".collapsible__header": {
                    height: em(3),
                    borderBottom: "1px solid #e2e2e2",
                },
            },
        },
        header: {
            marginRight: theme.margin.m,
            padding: theme.margin.s,
            borderRadius: percent(50),
            backgroundColor: "#e2e2e2",
            display: "flex",
            opacity: theme.opacity.secondary,
            justifyContent: "center",
            alignItems: "center",
            $nest: {
                "&--active": {
                    backgroundColor: theme.colors.success,
                    opacity: theme.opacity.primary,
                    color: tinycolor(theme.colors.success).isDark() ? theme.colors.light : theme.colors.dark,
                },
            },
        },
    })(DcCollapsible)
}
