import { WorkTask } from "@tm/context-distribution"
import { channel, Customer, TitleType, Vehicle, VehicleType } from "@tm/models"
import { parseQueryString, uniqueId } from "@tm/utils"
import { useState, useRef, useEffect } from "react"
import { isCustomVehicleChanged } from "../helpers/common"
import { CustomVehicle } from "../../components/truck/business"

export const useTruckCustomData = (workTaskId?: string, perviousVehicle?: CustomVehicle, calBack?: (veh: CustomVehicle) => void) => {
    const [customVehicle, setCustomVehicle] = useState<CustomVehicle | undefined>(perviousVehicle)
    const [loaded, setLoaded] = useState<boolean>(false)
    const isFromQuery = useRef<boolean>(false)

    useEffect(() => {
        const queryParams: Record<string, any> = parseQueryString(location.search) ?? {}

        const newCustomVehicle: CustomVehicle = {
            vehicleType: VehicleType.CommercialVehicle,
            vin: queryParams.vin,
            manufacturerId: queryParams.manufacturerId,
            plateId: queryParams.plateId,
            color: queryParams.color,
            initialRegistration: queryParams.initialRegistration,
            vehicleNr: queryParams.vehicleNr,
        }

        const weight = parseInt(queryParams.weight)
        if (!isNaN(weight) && weight > 0) {
            newCustomVehicle.weight = weight
        }

        if (!newCustomVehicle.vin || !newCustomVehicle.manufacturerId) {
            isFromQuery.current = false
        } else {
            isFromQuery.current = true
            setLoaded(true)

            if (isCustomVehicleChanged(newCustomVehicle, customVehicle)) {
                setCustomVehicle(newCustomVehicle)
                calBack?.(newCustomVehicle)
            }
        }

        const unsubVehicle = channel("WORKTASK", workTaskId).subscribe(
            "VEHICLE/LOADED",
            (newVehicle) => {
                if (isFromQuery.current) {
                    return
                }

                if (newVehicle.vehicleType !== VehicleType.CommercialVehicle || !newVehicle.vin) {
                    setLoaded(true)
                    return
                }

                const newCustomVehicle: CustomVehicle = {
                    vin: newVehicle.vin,
                    manufacturerId: newVehicle.tecDocManufacturerId,
                    vehicleType: newVehicle.vehicleType,
                    plateId: newVehicle.plateId,
                    initialRegistration: newVehicle.initialRegistration,
                }

                if (isCustomVehicleChanged(newCustomVehicle, customVehicle)) {
                    setCustomVehicle(newCustomVehicle)
                    calBack?.(newCustomVehicle)
                }

                setLoaded(true)
            },
            true
        )

        const unsubWorkTask = channel("WORKTASK", workTaskId).subscribe(
            "WORKTASK/LOADED",
            (workTask) => {
                if (!isFromQuery.current && !workTask.vehicle) {
                    setLoaded(true)
                }
            },
            true
        )

        return () => {
            unsubVehicle?.()
            unsubWorkTask?.()
        }
    }, [perviousVehicle])

    return loaded
}
