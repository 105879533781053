import { useQuery } from "react-query"
import { Container } from "@tm/nexus"
import { CisCustomerContainer, RegisteredModels } from "@tm/models"
import { CisQueryType } from "@tm/models/src/cis"

const REQUEST_KEY = "QUERYTYPES_LOAD"

export const useLoadQueryTypes = (): { queryTypes: CisQueryType[] | undefined; isLoading: boolean } => {
    const { data, isLoading } = useQuery(
        REQUEST_KEY,
        () => {
            const container = Container.getInstance(RegisteredModels.CIS_Customer) as CisCustomerContainer
            return container.action("getQueryTypes")()
        },
        { staleTime: 5 * 60 * 1000 } // 5 minutes
    )

    return { queryTypes: data, isLoading }
}
