import { Channel, MessageBus } from "@tm/hermes"
import { getCurrentWorkTaskId } from "@tm/models"
import { ErePosition, MmtGroup } from "./models"

let mb: MessageBus<BundleChannels>


export function bundleChannel(): Channel<BundleChannels, "EUROTAX"> {
    if (!mb)
        mb = (window as any).__NEXT_MESSAGEBUS__

    return mb.channel("EUROTAX", getCurrentWorkTaskId())
}

export interface BundleChannels {
    EUROTAX: BundleChannelType
}

export type BundleChannelType =
    & { 'INIT_EUROTAX_FROM_WIDGET': { erePosition: ErePosition, selectedMmtGroup: MmtGroup, groups: MmtGroup[] } }

