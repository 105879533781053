import { Customer, Vehicle, MileType, TitleType } from "@tm/models"
import { UserSettings } from "@tm/context-distribution"
import { PostMessageControllerComponent } from "../component"
import { SetCustomerDataResponse, PostMessageRequest } from "../../../data"

export default function handleGetCustomerData(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { workTask, userSettings, hasTelesales } = this.props

    if (!source || !data?.getCustomerData) {
        return
    }

    const description = workTask?.workTaskReadModel?.voucherType?.description ?? ""
    const voucherNo = workTask?.workTaskReadModel?.voucherNo ?? ""
    const order = (description || voucherNo) ? `${description} ${voucherNo}` : undefined

    const setCustomerData: SetCustomerDataResponse = {
        customer: getCustomer(workTask?.customer, hasTelesales),
        vehicle: getVehicle(workTask?.vehicle),
        address: userSettings ? getAddress(userSettings) : undefined,
        orderData: order ? {
            orderNumber: order 
        } : undefined
    }
    source.postMessage({ setCustomerData }, "*")
}

function getVehicle(vehicle?: Vehicle) {
    return vehicle
        ? {
              licensePlate: vehicle.plateId,
              milageInKm: vehicle.mileAge ? vehicle.mileAge * (vehicle.mileType === MileType.Mile ? 0.621371 : 1) : undefined,
              vin: vehicle.vin,
              initialRegistration: vehicle.initialRegistration,
              nextGeneralInspection: vehicle.nextGeneralInspection,
              lastGeneralInspection: vehicle.lastGeneralInspection,
              nextServiceDate: vehicle.nextServiceDate,
              kba: vehicle.registrationNo,
          }
        : undefined
}

function getCustomerName(customer: Customer, hasTelesales?: boolean): string {
    let name = ""

    if (customer.titleType === TitleType.Company || hasTelesales) {
        name = customer.companyName ?? ""
    }

    if (!name) {
        name = [customer.firstName, customer.lastName].filter((x) => !!x).join(" ")
    }

    return name
}

function getCustomer(customer?: Customer, hasTelesales?: boolean) {
    return customer
        ? {
              name: getCustomerName(customer, hasTelesales),
              street: customer.street,
              zip: customer.zip,
              city: customer.city,
              customerId: hasTelesales ? customer.refCustomerNo : undefined,
          }
        : { name: "" }
}

function getAddress(userSettings: UserSettings) {
    const { repairShop } = userSettings
    if (!repairShop) {
        return
    }
    return {
        city: repairShop.postalAddress ? repairShop.postalAddress.city : undefined,
        companyName: repairShop.companyName,
        street1: repairShop.postalAddress ? repairShop.postalAddress.street : undefined,
        zip: repairShop.postalAddress ? repairShop.postalAddress.zip : undefined,
        email: repairShop.contactInfo ? repairShop.contactInfo.email : undefined,
        telephone: repairShop.contactInfo ? repairShop.contactInfo.phone : undefined,
    }
}
