import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, receive } from "./bussiness"
import { withStoreProvider } from "./bussiness/helpers"

const documents: BundleComponent = {
    name: "details-formulars",
    reduce,
    component: withStoreProvider(component),
    receive,
}

export default documents
