import { FC, ReactElement } from "react"

type Props = {
    data: { requestErpInfo: boolean }
    children: ReactElement
}

export const ErpWrapper: FC<Props> = ({ data, children }) => {
    if (!data?.requestErpInfo) {
        return null
    }

    return children
}
