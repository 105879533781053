
import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetPartsRequest, GetPartsResponse } from "."
import { getBundleParams } from "../../../utils"
export * from "./model"

export function getParts(request: GetPartsRequest) {
    const url = `${getServiceUrl()}/GetParts`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<GetPartsResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then(
            response => resolve(response),
            reject
        ))
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.eurotaxPartsUrl
}
