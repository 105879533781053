import { Article, CisTour, AddReturnItemRequest, CalculatedCostType, CausedCostsType } from "@tm/models"
import { VoucherItem } from "../data/model"
import { CisFilters } from "./model"

export function getNextTourFromTourArray(tours?: Array<CisTour>) {
    if (tours && tours.length) {
        const currentDate = new Date()
        let bestDateIndex = 0
        let bestDiff = -new Date(0, 0, 0).valueOf()

        tours.forEach((tour: CisTour, index: number) => {
            if (tour && tour.orderAcceptanceUntil) {
                const currentDiff = tour.orderAcceptanceUntil.valueOf() - currentDate.valueOf()

                if (currentDiff > 0 && currentDiff < bestDiff) {
                    bestDateIndex = index
                    bestDiff = currentDiff
                }
            }
        })

        return tours[bestDateIndex]
    }
}

export function getDateAsTimestamp(date?: Date) {
    if (!date) {
        return undefined
    }
    return date.valueOf()
}
const todayNoTime = new Date().setUTCHours(0, 0, 0, 0)
export const today = new Date(todayNoTime)
export const oneMonthAgo = new Date(todayNoTime)
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1)

// NEXT-25679: the customer wants the filter to be "today" until "today - 30 days", no matter
// what the CIS filter in the MDM says.
export function getInitializedCostOverviewFilters() {
    return getInitializedCisFilters(undefined, undefined, 30)
}

export function getInitializedCisFilters(queryTypeId?: number, dateToDaysPreset?: number, dateFromDaysPreset?: number): CisFilters {
    let dateTo = today
    let dateFrom = oneMonthAgo
    if (dateToDaysPreset !== undefined) {
        dateTo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - dateToDaysPreset, 0, 0, 0, 0)
    }
    if (dateFromDaysPreset !== undefined) {
        if (dateFromDaysPreset === 30) {
            const aMonthAgo = new Date(dateTo)
            aMonthAgo.setMonth(aMonthAgo.getMonth() - 1)
            dateFrom = aMonthAgo
        } else {
            dateFrom = new Date(dateTo.getFullYear(), dateTo.getMonth(), dateTo.getDate() - dateFromDaysPreset, 0, 0, 0, 0)
        }
    }

    return {
        dateTo,
        dateFrom,
        query: undefined,
        queryTypeId,
        calculatedCostType: CalculatedCostType.Unknown,
        causedCostsType: CausedCostsType.All,
    }
}

export function getAddReturnItemRequest(voucherItem: VoucherItem, article?: Article, showAlternativeMode?: boolean) {
    const addReturnItemRequest: AddReturnItemRequest = {
        dataSupplierArticleNumber: article?.supplierArticleNo,
        dataSupplierId: article?.supplier?.id,
        dataSupplierName: article?.supplier.name,
        deliveryNote: voucherItem.deliveryNote,
        description: article?.description || voucherItem.articleDescription,
        invoice: voucherItem.invoice,
        orderDate: voucherItem.orderDate ? voucherItem.orderDate : new Date(),
        orderNo: showAlternativeMode ? voucherItem.voucherId : voucherItem.orderNo,
        positionNumber: voucherItem.positionNumber,
        productGroupId: article?.productGroup?.id,
        productGroupName: article?.productGroup?.name,
        returnQuantity: voucherItem.returnQuantity ? voucherItem.returnQuantity : voucherItem.quantity,
        thumbnailUrl: article?.thumbnail,
        voucherId: voucherItem.voucherId,
        voucherItemId: voucherItem.voucherItemId,
        voucherTypeId: voucherItem.voucherTypeId,
        wholesalerArticleNumber: voucherItem.wholesalerArticleNumber,
    }

    return addReturnItemRequest
}

export function getWmVoucherId(voucherId: string) {
    let wmVoucherId = voucherId
    if (voucherId.indexOf("-") > -1) {
        wmVoucherId = voucherId.substring(0, voucherId.indexOf("-"))
    }
    return wmVoucherId
}
