import { Box, MenuItem, SelectTextField, Typography, styled, useTheme } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useFastServiceStore } from "../../../data"
import { SpecificationsGenart, Work } from "../../../data/models"

type Props = {
    item: SpecificationsGenart
    workItem: Work
    onSelectLubricant: (item: string) => void
    selectedLubricant: string | undefined
}

export default function Lubricants({ item, workItem, onSelectLubricant, selectedLubricant }: Props) {
    const muiTheme = useTheme()
    const { translateText } = useLocalization()

    const savedItemLubricants = useFastServiceStore((state) => state.worksState.savedItemLubricants)

    const selectItems = item.specifications?.map((x) => `${x.label || ""} ${x.quality || ""} ${x.viscosity || ""}`)

    const itemLubricant = Object.values(savedItemLubricants)?.find((savedItemLubricant) => savedItemLubricant[workItem.label]?.selectedLubricant)

    if (!selectItems) {
        return null
    }

    return (
        <SpecificationWrapper>
            <Typography variant="body1" fontWeight="600">
                {translateText(12729)}
            </Typography>
            {selectItems.length === 1 ? (
                selectItems?.map((item, idx) => (
                    <Typography key={idx} size="m" sx={{ marginLeft: muiTheme.margin?.m ?? ".5em" }}>
                        {item}
                    </Typography>
                ))
            ) : (
                <SelectTextField
                    variant="outlined"
                    onChange={() => {}}
                    value={(itemLubricant && itemLubricant[workItem.label]?.selectedLubricant) ?? selectedLubricant ?? selectItems.first()}
                    size="medium"
                >
                    {selectItems?.map((item, index) => (
                        <MenuItem key={index} value={item} onClick={() => onSelectLubricant(item)}>
                            {item}
                        </MenuItem>
                    ))}
                </SelectTextField>
            )}
        </SpecificationWrapper>
    )
}

const SpecificationWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    "& fieldset": {
        border: "none",
    },
})
