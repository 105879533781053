import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers"
import { reduce } from "../main/business"
import component from "./component"

const widget: BundleComponent = {
    name: "widget",
    reduce: reduce,
    component: withStoreProvider(component as any),
}

export default widget
