import { useEffect } from "react"
import { bundleChannel } from "../../../business"
import { OrderItemGroup } from "../../model"
import * as Data from "../.."

type SetOrderGroupsAndCurrentlyUpdatingPartIdsDTO = {
    orderGroups: Array<OrderItemGroup> | undefined
    currentlyUpdatingPartIds: Set<string>
}
export type getNextOrderGroupsAndCurrentlyUpdatingPartIds = (
    state: SetOrderGroupsAndCurrentlyUpdatingPartIdsDTO
) => SetOrderGroupsAndCurrentlyUpdatingPartIdsDTO

export function useSharedWorkTaskOrderReloadListeners(
    workTaskId: string,
    setOrderGroupsAndCurrentlyUpdatingPartIds: (getNext: getNextOrderGroupsAndCurrentlyUpdatingPartIds) => void
) {
    useEffect(() => {
        return bundleChannel().subscribe("WORKTASK_BASKET_PARTS_CHANGING", ({ workTaskId: id, partIds }) => {
            if (workTaskId === id) {
                setOrderGroupsAndCurrentlyUpdatingPartIds((prev) => {
                    const currentlyUpdatingPartIds = new Set(prev.currentlyUpdatingPartIds)
                    partIds.forEach((partId) => currentlyUpdatingPartIds.add(partId))
                    return { ...prev, currentlyUpdatingPartIds }
                })
            }
        })
    }, [setOrderGroupsAndCurrentlyUpdatingPartIds, workTaskId])

    useEffect(() => {
        return bundleChannel().subscribe("WORKTASK_BASKET_PART_CHANGED", ({ workTaskId: id, partId }) => {
            if (workTaskId === id) {
                Data.showItem(partId).then((orderItem) => {
                    setOrderGroupsAndCurrentlyUpdatingPartIds((prev) => {
                        const currentlyUpdatingPartIds = new Set(prev.currentlyUpdatingPartIds)
                        currentlyUpdatingPartIds.delete(orderItem.id)
                        let orderGroups
                        if (prev.orderGroups) {
                            orderGroups = prev.orderGroups.map((g) => {
                                if (g.orderItems.find((i) => i.id === orderItem.id)) {
                                    return {
                                        ...g,
                                        orderItems: g.orderItems.map((i) => {
                                            if (i.id === orderItem.id) {
                                                return orderItem
                                            }
                                            return i
                                        }),
                                    }
                                }
                                return g
                            })
                        }
                        return { currentlyUpdatingPartIds, orderGroups }
                    })
                })
            }
        })
    }, [workTaskId, setOrderGroupsAndCurrentlyUpdatingPartIds])
}
