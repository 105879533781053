import { TitleType } from "@tm/models"
import { ModelState, validateField } from "@tm/utils"
import { ReactText } from "react"
import { SummaryFields } from "../models"

export const validateFields = (fields: SummaryFields, translateText: (key: ReactText) => string): ModelState => {
    return {
        processName: validateField(fields.processName).required(translateText(37)).messages,
        ...((!fields.repairCoverage || !["0", "1"].includes(fields.repairCoverage.toString())) && {
            repairCoverage: [translateText(37)],
        }),
        mileage: validateField(fields.mileage).required(translateText(37)).messages,
        keyNumber: validateField(fields.keyNumber?.trim()).required(translateText(37)).messages,
        initialRegistration: validateField(fields.initialRegistration && fields.initialRegistration.toString()).min(8, translateText(289)).messages,
        plateNumber: validateField(fields.plateNumber?.trim()).required(translateText(37)).messages,
        vin: validateField(fields.vin).min(17, translateText(289)).messages,
        titleType: validateField(fields.titleType).required(translateText(37)).messages,
        ...(fields.titleType !== TitleType.Company && {
            firstName: validateField(fields.firstName?.trim()).required(translateText(37)).messages,
            lastName: validateField(fields.lastName?.trim()).required(translateText(37)).messages,
        }),
        ...(fields.titleType === TitleType.Company && {
            companyName: validateField(fields.companyName).required(translateText(37)).messages,
        }),
        street: validateField(fields.street?.trim()).required(translateText(37)).messages,
        zip: validateField(fields.zip?.trim()).required(translateText(37)).messages,
        city: validateField(fields.city?.trim()).required(translateText(37)).messages,
        country: validateField(fields.country?.trim()).required(translateText(37)).messages,
    }
}
