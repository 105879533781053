import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, receive } from "../_shared/article-references-business"

const articleReferencesModelSeries: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "article-references-model-series",
    reduce,
    component,
    receive,
}

export default articleReferencesModelSeries
