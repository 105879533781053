import { getRdSession, getKtypeNr } from "../utils"
import { Article, MainService, OePart } from "@tm/models"
import { AddArticlesToRdBaket, AddRepairTimesToRdBasket } from "../data/models"
import { mapOePartRD } from "./add-items-rt-to-rd-basket/business/mappers"

export class RedesignHelper {
    static addItemsToRDBasket(items: Article[], callback: (items: Article[]) => void, sourceId?: string) {

        const basketItems: AddArticlesToRdBaket["basketItems"] = items.map(article => ({
            quantity: article.quantity ?? 1,
            articleInformation: {
                articleNumber: article.supplierArticleNo,
                supplierId: article.supplier.id,
                genArtNumber: article.productGroup.id,
                traderArticleNo : article.traderArticleNo
            }
        }))
        const data: AddArticlesToRdBaket = {
            sessionId: getRdSession(),
            ktypeNr: getKtypeNr(),
            tmaModuleIdentifier: "wheels",
            basketItems,
            method: "addArticle",
            sourceId
        }

        this.sendPostMessage(data)

        function receiveBasketMessage(event: MessageEvent) {
            event.data?.addArticle == "success" && callback(items)

            window.removeEventListener("message", receiveBasketMessage)
        }

        window.addEventListener("message", receiveBasketMessage)
    }

    static addOeItemsToRDBasket(items: OePart[], sourceId?: string) {
        const data = {
            sessionId: getRdSession(),
            method: "addOEArticle",
            sourceId,
            oeArticles: items.map(mapOePartRD)
        }

        this.sendPostMessage(data)

        function receiveBasketMessage(event: MessageEvent) {
            event.data?.addArticle == "success" && console.log("Oe articles added to Basket!")

            window.removeEventListener("message", receiveBasketMessage)
        }

        window.addEventListener("message", receiveBasketMessage)
    }

    static addItemsToKVA(request: AddRepairTimesToRdBasket[], sourceId?: string) {
        const data = {
            sourceId,
            items: request,
            sessionId: getRdSession(),
            method: "addRepairTime"
        }

        this.sendPostMessage(data)

        function receiveKvaMessage(event: MessageEvent) {
            event.data?.addRepairTime == "success" && console.log("Repair times added to Basket!")

            window.removeEventListener("message", receiveKvaMessage)
        }

        window.addEventListener("message", receiveKvaMessage)
    }

    static getAllRepairTimes(callback: (items: MainService[]) => void, sourceId?: string) {
        const ktypeId = getKtypeNr()

        const data = {
            sessionId: getRdSession(),
            method: "getRepairTimes",
            sourceId,
        }

        this.sendPostMessage(data)

        function receiveRepairTimes(event: MessageEvent) {
            if (event.data && Array.isArray(event.data)) {
                try {
                    let repairTimes = event.data.filter(x => x.KTypeId == ktypeId).map(x => x.RepairTimes)
                    repairTimes = [].concat.apply([], repairTimes)
                    const repairTimeArray = repairTimes.map(x => Object.entries(x).reduce((prev, [key, value]) => ({ ...prev, [key.charAt(0).toLowerCase() + key.slice(1)]: value }), {}))

                    callback(repairTimeArray as MainService[])
                }
                catch {
                    console.warn("Could not get repair times!")
                }
            }

            window.removeEventListener("message", receiveRepairTimes)
        }
        window.addEventListener("message", receiveRepairTimes)
    }

    static deleteRepairTimes(request: AddRepairTimesToRdBasket[], callback: (items: MainService[]) => void, sourceId?: string) {

        const data = {
            sessionId: getRdSession(),
            method: "deleteRepairTime",
            sourceId,
            items: request
        }
        
        this.sendPostMessage(data)

        const checkDeleteRepairTimes = (event: MessageEvent) => {
            event.data?.deleteRepairTime == "success" && this.getAllRepairTimes(callback)

            window.removeEventListener("message", checkDeleteRepairTimes)
        }

        window.addEventListener("message", checkDeleteRepairTimes)
    }

    static sendPostMessage(data: Object) {
        window.parent.postMessage(data, "*")
    }
}