import { Box, LinkButton, styled } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { CategoryType, channel, ECounterType } from "@tm/models"
import { encodeUniqueId, renderRoute, TmaHelper, uniqueId } from "@tm/utils"
import { useEffect, useRef } from "react"
import CatalogSelection from "./CatalogSelection"
import IndustryCatalogSelection from "./IndustryCatalogSelection"

export type NavigationLink = { title: string; route: string }

export type CatalogNavigationProps = {
    activeCategory: CategoryType
    onChangeCategory(category: CategoryType): void
    disabledCategories: Array<CategoryType>
    availableCategories: Array<CategoryType>

    directSearchText: string
    universalSearchText: string
    tiresSearchText: string

    className?: string
    tabType?: "worktask" | "flat" | "scoped"

    showPartsIndicatorIcon?: boolean
    navigationLinks?: NavigationLink[]

    hasMuiTabs?: boolean
}

// Use variables to have type check
const categoryType: Partial<{ [key in CategoryType]: CategoryType }> = {
    vehicleParts: "vehicleParts" as CategoryType,
    universalParts: "universalParts" as CategoryType,
    directSearch: "directSearch" as CategoryType,
    tiresSearch: "tiresSearch" as CategoryType,
    predictive: "predictive" as CategoryType,
    eurotaxSearch: "eurotaxSearch" as CategoryType,
    dtcSearch: "dtcSearch" as CategoryType,
}

const NavigationContainer = styled("div")(({ theme }) => {
    return {
        display: "flex",
        columnGap: 3,
        alignItems: "stretch",
        ".btn": {
            overflow: "hidden",
            borderWidth: 2,
            padding: "8px 8px 6px",
            backgroundColor: theme.overwrites?.components?.button?.highlight?.hover?.backgroundColor ?? theme.colors?.["highlight-hover"],
            "&:hover": {
                backgroundColor: theme.colors?.highlight,
            },
            ">.btn__content": {
                overflow: "hidden",
                textOverflow: "ellipsis",
            },
        },
    }
})

export default function Navigation(props: CatalogNavigationProps) {
    const { workTaskId } = useWorkTask() ?? {}
    const { navigationLinks = [] } = props
    const renderCount = useRef(0)

    useEffect(() => {
        return channel("WORKTASK", workTaskId).subscribe("PARTS/SHOW_PARTS_INDICATOR", () => {
            categoryType.predictive && props.onChangeCategory(categoryType.predictive)
        })
    }, [workTaskId])

    useEffect(() => {
        if (props?.activeCategory && renderCount.current >= 2) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            sendTmaGeneralCountEvent(props.activeCategory)
        } else {
            renderCount.current += 1
        }
    }, [props.activeCategory])

    const handleGetTmaEvent = (identifier: string) => {
        switch (identifier) {
            case "vehicleParts":
                return ECounterType.PassengerCarPartsCatalog
            case "universalParts":
                return ECounterType.UniParts
            case "directSearch":
                return ECounterType.ArticleDirectSearch
            case "predictive":
                return ECounterType.PartsIndicator
            case "eurotaxSearch":
                return ECounterType.PassengerCarGpi
            case "dtcSearch":
                return ECounterType.FastDTC
            default:
                return ECounterType.Undefined
        }
    }

    const sendTmaGeneralCountEvent = (category: CategoryType) => {
        const identifier = handleGetTmaEvent(category)
        if (identifier !== ECounterType.Undefined) {
            TmaHelper.GeneralCountEvent.Call(identifier)
        }
    }

    return (
        <Box className={`tk-parts ${!props.hasMuiTabs ? "category-selection" : ""} ${props.className || ""}`}>
            <NavigationContainer>
                {!props.hasMuiTabs ? (
                    <CatalogSelection {...props} categoryType={categoryType as { [key in CategoryType]: CategoryType }} />
                ) : (
                    <IndustryCatalogSelection {...props} categoryType={categoryType as { [key in CategoryType]: CategoryType }} />
                )}
                {navigationLinks.map((link) => (
                    <LinkButton key={link.title} to={renderRoute(link.route, { workTaskId: encodeUniqueId(workTaskId ?? uniqueId()) })}>
                        {link.title}
                    </LinkButton>
                ))}
            </NavigationContainer>
        </Box>
    )
}
