import { useEffect } from "react"
import { withRouter } from "@tm/utils"
import { getParameterFromUserContextModule, useUser, useWorkTask } from "@tm/context-distribution"
import { Box, Loader } from "@tm/components"
import { channel, SystemType, UserModuleType } from "@tm/models"
import Morpheus from "@tm/morpheus"
import Main from "./Main"
import { resetStore, useEdsStore } from "../../data/state"

const HistoryWrapper: React.FC = () => {
    const [hydrated, setHydrated] = React.useState(false)
    const [historyRestored, setHistoryRestored] = React.useState(false)
    const [hasWorktaskId, setHasWorktaskId] = React.useState(false)
    const workTaskId = useWorkTask()?.workTaskId
    const { userContext } = useUser()
    const systemType = userContext?.system.systemType

    const enableEdsStateSave = getParameterFromUserContextModule(userContext, UserModuleType.Eds, "ReRequestCurrentState", "boolean")

    useEffect(() => {
        Morpheus.closeView("1")
    }, [])

    // THIS NEEDS TO BE IN A USEEFFECT, DON'T ADD IT TO A MEMO
    useEffect(() => {
        if (!hasWorktaskId && workTaskId) {
            setHasWorktaskId(true)
        }
    }, [workTaskId, hasWorktaskId])

    useEffect(() => {
        if (hasWorktaskId) {
            const unsub = channel("WORKTASK", workTaskId).subscribe("MODULE/CLOSED", (module) => {
                if (module.includes("eds")) {
                    resetStore()
                }
            })
            return () => {
                if (!enableEdsStateSave) {
                    resetStore()
                }

                unsub()
            }
        }
        return () => {
            if (!enableEdsStateSave) {
                resetStore()
            }
        }
    }, [enableEdsStateSave, workTaskId, hasWorktaskId])

    useEffect(() => {
        if (hasWorktaskId) {
            useEdsStore.persist.rehydrate()
        }

        const unsubHydrate = useEdsStore.persist.onHydrate(() => setHydrated(false))
        const unsubFinishHydration = useEdsStore.persist.onFinishHydration(() => setHydrated(true))
        setHydrated(useEdsStore.persist.hasHydrated())

        return () => {
            unsubHydrate()
            unsubFinishHydration()
        }
    }, [hasWorktaskId])

    // THIS NEEDS TO BE IN A USEEFFECT, DON'T ADD IT TO A MEMO
    useEffect(() => {
        if (systemType && systemType !== SystemType.Next) {
            setHistoryRestored(true)
        }

        if (!hydrated || !hasWorktaskId) {
            return
        }

        setHistoryRestored(true)
    }, [hydrated, hasWorktaskId, systemType])

    if (historyRestored) {
        return <Main />
    }

    return (
        <Box sx={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loader />
        </Box>
    )
}

export default withRouter(HistoryWrapper)
