import { Button, Box, Typography, useTheme, Loader } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useSelector } from "react-redux"
import { useParams } from "react-router"
import { useEffect } from "react"
import { calculationSelector } from "../../selectors"
import { Wrapper } from "../../styles"
import { MainActions } from "../main/business"
import { Totals } from "../_shared"
import { ArticleList, Header, WorksList } from "./components"
import { MatchParams } from "../navigation/business/model"
import CustomAccordion from "./components/_shared/CustomAccordion"

export default function Calculation() {
    const matchParams = useParams<MatchParams>()
    const theme = useTheme()
    const { translateText, currency } = useLocalization()
    const user = useUser()
    const { calculationLoaded, calculationLoading, calculationError, items, works, totals } = useSelector(calculationSelector)
    const currencyString = getCurrencyFromUserContext(user?.userContext)

    const actions = useActions(MainActions, "getArticles", "addToShoppingBasket")

    useEffect(() => {
        if (!calculationLoaded && matchParams.contractId) {
            actions.getArticles(matchParams.contractId, translateText)
        }
    }, [calculationLoaded])

    if (calculationLoading) {
        return (
            <Wrapper centeredContent>
                <Loader size="small" />
            </Wrapper>
        )
    }

    if (calculationError) {
        return (
            <Wrapper centeredContent>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <Typography fontWeight={700} padding={theme.spacing(0, 1)}>
                        {translateText(1569)}
                    </Typography>
                    <Button
                        onClick={() => matchParams.contractId && actions.getArticles(matchParams.contractId, translateText)}
                        color="error"
                        size="large"
                    >
                        {translateText(401)}
                    </Button>
                </Box>
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            <Header />
            <Totals />
            <CustomAccordion
                title={translateText(90)}
                itemsCount={items?.length ?? 0}
                itemsPrice={currency(totals.totalSparePartsPrice, currencyString)}
            >
                <ArticleList />
            </CustomAccordion>
            <CustomAccordion title={translateText(83)} itemsCount={works?.length ?? 0} itemsPrice={currency(totals.totalWorksPrice, currencyString)}>
                <WorksList />
            </CustomAccordion>
        </Wrapper>
    )
}
