import { BundleComponent } from "@tm/morpheus"
import { WidgetState, reduce, transmit } from "./business"
import { Widget } from "./component"
import type { WidgetProps } from "./component"
import type { TreeConfigKeys } from "./CatalogWidget"
import type { TreeConfig } from "./business"

const WidgetBundle: BundleComponent<WidgetState, typeof Widget> = {
    name: "widget",
    reduce,
    component: Widget,
    transmit,
}

export { WidgetBundle, Widget, WidgetProps, TreeConfigKeys, TreeConfig }
