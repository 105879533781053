export type MetaDataType =
    | { type: "link"; url: string; name?: string }
    | CostEstimateResponseMetaData
    | AttachmentTypeAppointment
    | AttachmentTypeAppointmentResponse
    | { type: "initial"; action: "customerData" | "vehicleData"; customerInfo?: CustomerInfo; vehicleInfo?: VehicleInfo }
    | InitialRepairshopMetaData

export type InitialRepairshopMetaData = {
    type: "initial"
    action: "repairshopData"
    customer?: { tmCustomerId: string; seatbeltCustomerId: string }
    vehicles: Array<{ tmVehicleId: string; seatbeltVehicleId: string }>
}

export type AttachmentTypeAppointment = {
    type: "appointment"
    appointmentId: string
    action: "customerRequest" | "repairshopRequest"
    preferedDate: Date | string
    endDate: Date | string
    note?: string
    promotion?: string
    customerInfo?: CustomerInfo
    vehicleInfo?: VehicleInfo
    serviceInfo?: {
        fastCalculatorSessionId?: string
        repairServices?: Array<{
            id: string
            label: string
            displayName: string
            duration: string | number
            hours: number
        }>
        genArts?: Array<{
            genArtLabel: string
            genArtNr: number
        }>
        consumables?: Array<{
            genArtLabel: string
            genArtNr: number
        }>
    }
}

/** https://confluence.dvse.de/x/RYDDAw */
export const RepairshopAppointmentRejectionReasonsTextMap = {
    missingParts: 12744,
    canceledOfOtherDependencies: 12745,
    notSupportedVehicle: 12746,
    noCapacity: 12747,
    otherReason: 12815,
}

/** https://confluence.dvse.de/x/RYDDAw */
export const CostEstimateCancelTypeTextMap = {
    offerPriceTooHigh: 12894,
    offerTooLate: 12895,
    offerUnclear: 12896,
    offerNotAsAgreed: 12897,
}

export type RepairshopAppointmentRejectionReasons = keyof typeof RepairshopAppointmentRejectionReasonsTextMap
export type CostEstimateCancelType = keyof typeof CostEstimateCancelTypeTextMap

export type AttachmentTypeAppointmentResponse =
    | { type: "appointmentResponse"; appointmentId: string; action: "repairshopConfirmation"; confirmedDate: string | Date }
    | { type: "appointmentResponse"; appointmentId: string; action: "repairshopRejection"; canceledType?: RepairshopAppointmentRejectionReasons }
    | { type: "appointmentResponse"; appointmentId: string; action: "repairshopProposal"; proposedDate: string | Date; endDate: string | Date }
    | { type: "appointmentResponse"; appointmentId: string; action: "customerConfirmation"; confirmedDate: string | Date }
    | {
          type: "appointmentResponse"
          appointmentId: string
          action: "customerRejection"
          canceledType?: "spontaneouslyPrevented" | "problemSolved" | "foundBetterOffer" | "dateTooFarInFuture" | "appointmentConfirmedTooLate"
      }
    | { type: "appointmentResponse"; appointmentId: string; action: "customerProposal"; proposedDate: string | Date; endDate: string | Date }

export type CostEstimateResponseMetaData =
    | { type: "costEstimateResponse"; offerId: string; action: "customerConfirmation" }
    | { type: "costEstimateResponse"; offerId: string; action: "customerRejection"; canceledType?: CostEstimateCancelType }

export type CustomerInfo = {
    id: string
    firstName?: string
    lastName?: string
    street?: string
    addressAddition?: string
    city?: string
    zip?: string
    state?: string
    country?: string
    emailAddress?: string
    phone?: string
}

export type VehicleInfo = {
    id: string
    displayName?: string
    countryCode?: string
    tecDocTypeId: number
    tecDocManufacturerId: number
    tecDocModelId: number
    plateId?: string
    vin?: string
    initialRegistration?: string
    mileAge?: string
    mileType?: number
    engineCode?: string
    telematicProviderName?: string
    telematicProviderId?: number
    telematicDeviceId?: string
}
