import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, FormControlLabel, Typography } from "@tm/components"
import { Item, OrderOptions } from "@tm/models"
import { IconWithMargin, RadioButtonSmallWithMargin, RadioGroupGrid, BoxWithColumnPaddingRight, BoxWithAlignItems } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSelectBackorder(id: string, description: string, selectedOptionsItem?: Item, updateErpInformation?: boolean, updateOrderOptions?: boolean): void
}

export default function BackorderOptionsComponent(props: Props) {
    const { translate } = useLocalization()
    const {
        options: { backorderOptions, selectedOptionsItem },
        onSelectBackorder,
        disableFields,
    } = props

    const [selectedBackorderId, setSelectedBackorderId] = useState<string>()

    useEffect(() => {
        if (backorderOptions?.backorderOptions.length) {
            const defaultBackorder = backorderOptions.backorderOptions.find((backorder) => backorder.isSelected)
            const backorderId = defaultBackorder ? defaultBackorder.id : backorderOptions.backorderOptions[0].id
            setSelectedBackorderId(backorderId)
        }
    }, [backorderOptions])

    function handleBackorderOptionChange(_: unknown, id: string) {
        if (backorderOptions?.backorderOptions.length) {
            setSelectedBackorderId(id)
            const firstBackorderMatch = backorderOptions.backorderOptions.find((backorder) => backorder.id === id)
            if (firstBackorderMatch) {
                onSelectBackorder(
                    id,
                    firstBackorderMatch.description,
                    selectedOptionsItem,
                    backorderOptions.updateErpInformationOnChange,
                    backorderOptions.updateOrderOptionsOnChange
                )
            }
        }
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="back-order" />
                <Typography variant="h4">{backorderOptions?.description || translate(1143)}</Typography>
            </BoxWithAlignItems>
            <RadioGroupGrid onChange={handleBackorderOptionChange}>
                {backorderOptions?.backorderOptions.map((mode) => {
                    return (
                        <Box key={mode.id} display="flex">
                            <FormControlLabel
                                value={mode.id}
                                control={<RadioButtonSmallWithMargin disabled={disableFields} checked={mode.id === selectedBackorderId} />}
                                label={<Typography variant="body2">{mode.description}</Typography>}
                            />
                        </Box>
                    )
                })}
            </RadioGroupGrid>
        </BoxWithColumnPaddingRight>
    )
}
