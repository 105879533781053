import { atomFamily, useRecoilState } from "recoil"
import { useCallback, useEffect, useState } from "react"
import * as Data from ".."
import { ESendCostEstimationMode, SendCostEstimationRequest, SendCostEstimationResponse } from "../model"
import { useInvalidateWorkTaskBasketQueries } from "./workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

const sendCostEstimationState = atomFamily<SendCostEstimationResponse | undefined, ESendCostEstimationMode>({
    key: "basket_sendCostEstimation",
    default: undefined,
})

export function useTransferCostEstimation(workTaskId: string, telesalesCustomerNo: string | undefined, mode: ESendCostEstimationMode) {
    const [response, setResponse] = useRecoilState(sendCostEstimationState(mode))
    const { invalidateAllWorkTaskBasketRequests } = useInvalidateWorkTaskBasketQueries()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        return () => {
            setResponse(undefined)
        }
    }, [])

    const resetState = useCallback(() => {
        setResponse(undefined)
    }, [setResponse])

    const sendCostEstimation = useCallback(
        (distributorId: number) => {
            const request: SendCostEstimationRequest = {
                workTaskId,
                distributorId,
                telesalesCustomerNo,
            }

            resetState()
            setLoading(true)
            Data.sendCostEstimation(request)
                .then((result) => {
                    setResponse(result)
                    if (result?.hasSucceeded && !result.isBasketEmpty) {
                        invalidateAllWorkTaskBasketRequests(workTaskId)
                    }
                })
                .finally(() => setLoading(false))
        },
        [invalidateAllWorkTaskBasketRequests, resetState, telesalesCustomerNo, workTaskId]
    )

    return { sendCostEstimation, response, loading, resetState }
}
