import { useQuery } from "react-query"
import { HasRepairTimesRequest, HasRepairTimesResponse, RegisteredModels, VehicleType } from "@tm/models"
import { Container } from "@tm/nexus"
import { getRepairTimeProvidersByNames, getRepairTimeProviders } from "@tm/utils"
import { useCostEstimationModuleParameter } from "../../../../hooks/useCostEstimationModuleParameter"

export const KEY = "basket_useHasRepairTimes"

// TODO: a similar hook exists already in the repai-times bundle, that should be used everywhere
export function useHasRepairTimesData(productGroupIds?: number[], modelId?: number) {
    const { repairTimeProviderIds } = getRepairTimeProviders()
    const { hasCostEstimation } = useCostEstimationModuleParameter()

    const providers = repairTimeProviderIds && getRepairTimeProvidersByNames(repairTimeProviderIds)

    const request: HasRepairTimesRequest = {
        modelId: modelId ?? 0,
        vehicleType: VehicleType.PassengerCar,
        // Sort the ids so the query key will be identical, even when the order of the ids is different - order of the items doesn't matter for this endpoint
        productGroupIds: productGroupIds?.sort((a, b) => a - b) ?? [],
        repairTimeProvider: providers?.sort((a, b) => a - b) ?? [],
    }
    const { data, isLoading, isError } = useQuery(
        [KEY, request],
        () => {
            return Container.getInstance<HasRepairTimesResponse>(RegisteredModels.RepairTimes_HasRepairTimes).subscribe(request).load()
        },
        {
            enabled: hasCostEstimation && !!productGroupIds?.length && !!providers?.length && !!modelId,
        }
    )

    return { availableRepairTimeProviders: data?.productGroups, hasRepairTimesLoading: isLoading, hasRepairTimesWithError: isError }
}
