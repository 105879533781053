import { channel, getCurrentWorkTaskId } from "@tm/models"
import { useActions } from "@tm/morpheus"
import { decodeUniqueId, initSelector, RouteComponentProps, withRouter } from "@tm/utils"
import * as React from "react"
import { useSelector } from "react-redux"
import { getBundleParams } from "../../utils"
import { bundleChannel } from "../../data/channel"
import { EurotaxSelectionSteps } from "../../data/enums"
import { useVehicle } from "../../data/hooks"
import { MainActions, MainState } from "../main"
import { batch } from "react-redux"

const selector = initSelector(
    (s: MainState) => s.manager.vehicle,
    s => s.loading,
    s => s.details.selectedCriterias,
    s => s.details.selectedValues,
    s => s.navigation,
    s => s.summary.selectedMmtGroup,
    s => s.details.selectedVKNImage,
    s => s.details.extendedCarInfo,
    s => s.summary.cars.usedTecDoc
)

const ModuleManager: React.FC<RouteComponentProps<{ workTaskId: string }>> = ({ match: { params } }) => {
    const [vehicle, loading, selectedCriterias, selectedValues, navigation,
        selectedMmtGroup, selectedVKNImage, extendedCarInfo, usedTecDoc] = useSelector(selector)
    const vehTid = useSelector((x: MainState) => x.manager.vehicle?.tecDocTypeId)
    const actions = useActions(MainActions)
    const workTaskId = getCurrentWorkTaskId() ?? decodeUniqueId(params.workTaskId ?? "") ?? "FAKE_WORKTASK"

    const [widgetSearch] = bundleChannel().last(1, "INIT_EUROTAX_FROM_WIDGET").map(x => x.content)

    const handleWidgetSearch = () => {
        batch(() => {
            actions.initWidgetSearch(widgetSearch)
            actions.loadVKNImages()
            actions.changeStep(EurotaxSelectionSteps.DETAILS)
        })
        bundleChannel().clear("INIT_EUROTAX_FROM_WIDGET")
    }

    React.useEffect(() => {
        if (vehicle && widgetSearch)
            handleWidgetSearch()
    }, [])


    useVehicle(workTaskId, vehicle, newVeh => {
        const isFirstUpdate = !vehicle
        actions.setVehicle(newVeh, isFirstUpdate, !!widgetSearch)

        if (vehTid != newVeh.tecDocTypeId) {

            if (widgetSearch) {
                handleWidgetSearch()
                return
            }

            if (getBundleParams().disableStateSave)
                actions.loadEurotaxCars()

            actions.loadSavedData(workTaskId, newVeh)
        }

    })

    React.useEffect(() => {
        if (!loading)
            actions.saveData(workTaskId)
    }, [selectedCriterias, selectedValues, navigation, selectedMmtGroup, selectedVKNImage, extendedCarInfo])


    return null
}

export default withRouter(ModuleManager)