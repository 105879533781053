import { VehicleType } from "@tm/models"
import { useAvailableVehicleSearchOptions } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useCallback, useMemo } from "react"
import { useSelectedVehicleLookup } from "../../../data/hooks"
import {
    getBikeSearchPlaceholder,
    getBikeSearchTooltip,
    getCommercialSearchPlaceholder,
    getCommercialSearchTooltip,
    getVehicleSearchPlaceholder,
    getVehicleSearchTooltip,
    searchType,
} from "../../../helpers"
import { useSelectedSimpleLookupConfig } from "../../../data/hooks/useSelectedSimpleLookupConfig"
import { getBundleParams } from "../../../utils"

export function useVehicleSearch(vehicleType: VehicleType, placeholderPassengerCar?: string) {
    const { vehicleSearchesUsedInDefaultSearch } = useAvailableVehicleSearchOptions()
    const { selectedVehicleLookup } = useSelectedVehicleLookup(vehicleType)
    const { translateText } = useLocalization()
    const { simpleVehicleSearch } = getBundleParams()

    const { selectedConfig: selectedSimpleLookupConfig } = useSelectedSimpleLookupConfig()

    // If we have a selected lookup thats used in default search, it will determine the text.
    // Otherwise, we create the text based on all activated lookups used in default search.
    const regNoTypes = useMemo(() => {
        if (simpleVehicleSearch) {
            if (selectedSimpleLookupConfig !== undefined && !selectedSimpleLookupConfig.showAdditionalSearchField) {
                return [selectedSimpleLookupConfig.lookupId]
            }
        } else {
            // selectedVehicleLookup does only know its ID and country code, so we need to find the matching
            // lookup config in the available lookup configs that are not used in default search.
            const selectedVehicleLookupConfig = vehicleSearchesUsedInDefaultSearch.find((x) => x.lookupTypeId === selectedVehicleLookup?.lookupTypeId)

            if (selectedVehicleLookupConfig !== undefined) {
                return [selectedVehicleLookupConfig.lookupTypeId]
            }
        }
        return vehicleSearchesUsedInDefaultSearch.map((x) => x.lookupTypeId)
    }, [selectedVehicleLookup, vehicleSearchesUsedInDefaultSearch, simpleVehicleSearch, selectedSimpleLookupConfig])

    const searchPlaceholder = useMemo(() => {
        switch (vehicleType) {
            case VehicleType.PassengerCar:
                const translationRegex = /\{\{(.*?)\}\}/
                const placeholder = placeholderPassengerCar?.replace(translationRegex, (s, num) => translateText(num))
                return placeholderPassengerCar ? placeholder : getVehicleSearchPlaceholder(regNoTypes, translateText)
            case VehicleType.Motorcycle:
                return getBikeSearchPlaceholder(regNoTypes, translateText)
            case VehicleType.CommercialVehicle:
                return getCommercialSearchPlaceholder(regNoTypes, translateText)
            default:
                return undefined
        }
    }, [vehicleType, placeholderPassengerCar, regNoTypes, translateText])

    const tooltipMessage = useMemo(() => {
        switch (vehicleType) {
            case VehicleType.PassengerCar:
                return getVehicleSearchTooltip(regNoTypes, translateText, searchType.VEHICLE_TYPE)
            case VehicleType.Motorcycle:
                return getBikeSearchTooltip(regNoTypes, translateText)
            case VehicleType.CommercialVehicle:
                return getCommercialSearchTooltip(regNoTypes, translateText)
            default:
                return undefined
        }
    }, [regNoTypes, vehicleType, translateText])

    const getGroupHeaderName = useCallback(
        (headerKey: string) => {
            switch (headerKey) {
                case "CUSTOMER":
                    return translateText(12504)
                case "VEHICLE":
                    return translateText(13733)
                case "REGNO":
                    return translateText(13734)
                default:
                    return undefined
            }
        },
        [translateText]
    )

    return { getGroupHeaderName, tooltipMessage, searchPlaceholder }
}
