import { TreeNode, SearchTreeProductGroup, GetSearchTreeResponse } from "@tm/models"

import * as VehicleRecords from "./vehicle-records"

export * from "./list"
export * from "./details"
export * from "./key-value"
export { VehicleRecords }

export function mapGetSearchTreeResponse(data: any): GetSearchTreeResponse {
    if (!data) {
        data = {}
    }

    return {
        ...data,
        nodes: (data.nodes || []).map(mapTreeNode).filter((x: TreeNode | undefined) => !!x),
        topProductGroups: (data.topProductGroups || []).filter((x: SearchTreeProductGroup | undefined) => !!x),
    }
}

export function mapTreeNode(data: any): TreeNode | undefined {
    if (!data) {
        return
    }

    return {
        ...data,
        childNodes: data.childNodes ? data.childNodes.map(mapTreeNode).filter((x: TreeNode | undefined) => !!x) : undefined,
    }
}
