import {
    ShowCustomRepairTimeItemsRequest,
    AddCustomRepairTimeItemsRequest,
    AddCustomRepairTimeItemsResponse,
    CustomRepairTimeItem,
    EditCustomRepairTimeItemRequest,
    EditCustomRepairTimeItemResponse,
} from "../../model"
import * as Data from "../.."
import { useCustomItems, useDeleteCustomItem, useEditCustomItem } from "./useCustomItems"

const QUERY_KEY = "basket__custom-repairTimes"

export function useShowCustomRepairTimeItems(request: Omit<ShowCustomRepairTimeItemsRequest, "pageIndex" | "pageSize">) {
    return useCustomItems(request, { queryKey: QUERY_KEY, dataFn: Data.showCustomRepairTimeItems })
}

export function useDeleteCustomRepairTimes() {
    return useDeleteCustomItem<string[], void, CustomRepairTimeItem[]>({
        queryKey: QUERY_KEY,
        dataFn: (ids) => Data.deleteCustomRepairTimes({ ids }),
        filterFn: (_, ids, prevData) => prevData.filter((x) => !ids.includes(x.id)),
    })
}

export function useAddCustomRepairTimeItems() {
    return useEditCustomItem<AddCustomRepairTimeItemsRequest, AddCustomRepairTimeItemsResponse | undefined, CustomRepairTimeItem[]>({
        queryKey: QUERY_KEY,
        dataFn: Data.addCustomRepairTimeItems,
        mutateFn: (response, _, prevData) => [...prevData, ...(response?.addedCustomRepairTimeItems ?? [])],
    })
}

export function useEditCustomArticleItem() {
    return useEditCustomItem<EditCustomRepairTimeItemRequest, EditCustomRepairTimeItemResponse | undefined, CustomRepairTimeItem[]>({
        queryKey: QUERY_KEY,
        dataFn: Data.editCustomRepairTimeItem,
        mutateFn: (response, customRepairTime, prevData) =>
            prevData.map((x) => (x.id === response?.editedCustomRepairTime.id ? { ...x, ...customRepairTime } : x)),
    })
}
