import { MessageBus, Channel } from "@tm/hermes"
import { CarModelDetailsResponse } from "@tm/models"

let mb: MessageBus<BundleChannels>

export function bundleChannel(): Channel<BundleChannels, "BUNDLE"> {
    if (!mb) {
        mb = new MessageBus<BundleChannels>()
    }

    return mb.channel("BUNDLE")
}
export interface BundleChannels {
    BUNDLE: BundleChannelType
}

export type BundleChannelType = { SHARE_MODEL_DETAILS: CarModelDetailsResponse }

export const getLastModelDetails = () => {
    return bundleChannel().last(1, "SHARE_MODEL_DETAILS")?.last()?.content
}
