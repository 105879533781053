import { FittingPositionControl, FittingPositionControlProps } from "@tm/controls"
import Filter, { Props as FilterProps } from "./filter"

type Props = FilterProps & FittingPositionControlProps

export default function FittingPositionFilter(props: Props) {
    let className = "filter--fitting-position "
    className += props.className || ""

    return (
        <Filter {...props} className={className}>
            <div className="fitting-position-wrapper">
                <FittingPositionControl {...props} />
            </div>
        </Filter>
    )
}
