import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, FormControlLabel, Typography } from "@tm/components"
import { Item, OrderOptions } from "@tm/models"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin, RadioButtonSmallWithMargin, RadioGroupGrid } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetOrderProcessing(
        id: string,
        description: string,
        selectedOptionsItem?: Item,
        updateErpInformation?: boolean,
        updateOrderOptions?: boolean
    ): void
}

export default function OrderProcessingOptionsComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const {
        options: { orderProcessingOptions, selectedOptionsItem },
        onSetOrderProcessing,
        disableFields,
    } = props

    const [selectedOrderProcessingId, setSelectedOrderProcessingId] = useState<string>()

    useEffect(() => {
        if (orderProcessingOptions?.orderProcessingOptions.length) {
            const defaultOrderProcessing = orderProcessingOptions.orderProcessingOptions.find((orderProcessing) => orderProcessing.isSelected)
            const orderProcessingId = defaultOrderProcessing ? defaultOrderProcessing.id : orderProcessingOptions.orderProcessingOptions[0].id
            setSelectedOrderProcessingId(orderProcessingId)
        }
    }, [orderProcessingOptions])

    function getDescription(id: string, description?: string) {
        return description || translateText(id === "1" ? 2003 : 2004)
    }

    function handleOrderProcessingModeChange(_: unknown, id: string) {
        if (orderProcessingOptions?.orderProcessingOptions.length) {
            setSelectedOrderProcessingId(id)
            const firstOrderProcessingMatch = orderProcessingOptions.orderProcessingOptions.find((op) => op.id === id)
            if (firstOrderProcessingMatch) {
                onSetOrderProcessing(
                    id,
                    getDescription(id, firstOrderProcessingMatch.description),
                    selectedOptionsItem,
                    orderProcessingOptions.updateErpInformationOnChange,
                    orderProcessingOptions.updateOrderOptionsOnChange
                )
            }
        }
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="orderprocessing" />
                <Typography variant="h4"> {translate(1199)}</Typography>
            </BoxWithAlignItems>
            <RadioGroupGrid onChange={handleOrderProcessingModeChange}>
                {orderProcessingOptions?.orderProcessingOptions.map((mode) => {
                    return (
                        <Box key={mode.id} display="flex">
                            <FormControlLabel
                                value={mode.id}
                                control={<RadioButtonSmallWithMargin disabled={disableFields} checked={mode.id === selectedOrderProcessingId} />}
                                label={<Typography variant="body2">{getDescription(mode.id, mode.description)}</Typography>}
                            />
                        </Box>
                    )
                })}
            </RadioGroupGrid>
        </BoxWithColumnPaddingRight>
    )
}
