import { ArticleInfoType } from "@tm/models"
import { Stack, ArticleThumbnailImage } from "@tm/components"

type Props = {
    articleInfoType: ArticleInfoType
    mainPartItemId?: string
    thumbnailUrl: string | undefined
}

export default function ArticleThumbnail({ articleInfoType, mainPartItemId, thumbnailUrl }: Props) {
    const isLinkedItem = !!mainPartItemId

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <ArticleThumbnailImage isLinkedItem={isLinkedItem} thumbnailUrl={thumbnailUrl} articleInfoType={articleInfoType} />
        </Stack>
    )
}
