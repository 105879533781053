import { Article, GetAdvertisementRequest, GetAdvertisementResponse, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"
import { getArticlesFromResponse, mapAdvertisementToSupplierArticle } from "./helpers"

export function useAdvertisementParts(advertisementId: string | undefined, pageIndex: number, pageSize: number) {
    const { data: advertisementItems = [], isLoading: advertisementItemsLoading } = useQuery(
        ["offers_advertisement-items", advertisementId],
        () =>
            Container.getInstance<GetAdvertisementResponse>(RegisteredModels.ERP_GetAdvertisement)
                .subscribe({ advertisementId } as GetAdvertisementRequest)
                .load()
                .then((response) => response.advertisements),
        {
            enabled: !!advertisementId,
            staleTime: 1000 * 60 * 60, // keep data for 1 hour
        }
    )

    const startIndex = pageIndex * pageSize
    const endIndex = startIndex + pageSize
    const itemsOfCurrentPage = advertisementItems.slice(startIndex, endIndex)
    const totalPages = Math.ceil(advertisementItems.length / pageSize)

    const wholesalerArticleNumbers = itemsOfCurrentPage.map((x) => x.wholesalerArticleNumber).filter((x) => x)

    const { data: wholesalerParts = [], isLoading: wholesalerPartsLoading } = useQuery(
        ["offers_advertisement-wholesaler", wholesalerArticleNumbers],
        () =>
            Container.getInstance<Record<string, Array<Article>>>(RegisteredModels.Articles_ByWholesalerArticleNumbers)
                .subscribe(wholesalerArticleNumbers)
                .load(),
        {
            enabled: !!wholesalerArticleNumbers.length,
            staleTime: 1000 * 60 * 60 * 24, // keep data for 1 day
            select: getArticlesFromResponse,
        }
    )

    const supplierArticles = mapAdvertisementToSupplierArticle(itemsOfCurrentPage)

    const { data: supplierParts = [], isLoading: supplierPartsLoading } = useQuery(
        ["offers_advertisement-supplier-parts", supplierArticles],
        () =>
            Container.getInstance<Record<string, Array<Article>>>(RegisteredModels.Articles_BySupplierArticleNumbers)
                .subscribe(supplierArticles)
                .load(),
        {
            enabled: !!supplierArticles.length,
            staleTime: 1000 * 60 * 60 * 24, // keep data for 1 day
            select: getArticlesFromResponse,
        }
    )

    return {
        advertisementItems,
        parts: [...wholesalerParts, ...supplierParts],
        isLoading: advertisementItemsLoading || wholesalerPartsLoading || supplierPartsLoading,
        totalPages,
    }
}
