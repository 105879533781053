import { loadScript } from "./useScript"
import { getBundleParams } from "../../utils"

declare global {
    interface Window {
        UserSnap: any
    }
}

export function callUserSnapForm(formKey: string) {
    if (window.UserSnap) {
        window.UserSnap.show(formKey, { button: null }).then((widgetApi: any) => widgetApi.open())
    }
}

export function initUsersnap(formValue: any) {
    const { userSnapToken } = getBundleParams()

    if (!window.UserSnap || !window.onUsersnapCXLoad) {
        window.onUsersnapCXLoad = (api: any) => {
            api.init({
                collectGeoLocation: "none",
                custom: { ...formValue },
                user: {
                    email: formValue.email,
                },
            })
            window.UserSnap = api
            // workaround for a bug that causes a foreign widget to be initialised witha  feedback button
            api.hide("ffc348b9-0bac-4689-a97e-3332cbce90d7")
        }
        loadScript(`https://widget.usersnap.com/global/load/${userSnapToken}?onload=onUsersnapCXLoad`)
    }
}
