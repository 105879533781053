import { color, em, padding } from "csx"
import { useMemo } from "react"
import { getStyleTheme, useStyle, useUser } from "@tm/context-distribution"
import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { getCurrencyFromUserContext } from "../../data/helpers/userSettingsContextMethods"

type Props = {
    totalPrice: number
    displayOnTop?: boolean
}

export default function TotalPrice(props: Props) {
    const { totalPrice, displayOnTop } = props
    const { userContext } = useUser() ?? {}
    const { currency, translateText } = useLocalization()
    const currencyString = getCurrencyFromUserContext(userContext)
    const style = useMemo(() => getStyle(), [])

    if (displayOnTop) {
        return (
            <div className={style.wrapTotalPrice}>
                <Text size="m" className={style.totalPriceText}>
                    {translateText(2007)}
                </Text>
                <Text size="l" className={classes("price", style.totalPriceSum)}>
                    {currency(totalPrice ?? 0, currencyString)}
                </Text>
            </div>
        )
    }
    return <Text className={classes("price", style.totalPrice)}>{currency(totalPrice ?? 0, currencyString)}</Text>
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        totalPrice: {
            flex: 1,
            padding: padding(0, em(0.5)),
        },
        wrapTotalPrice: {
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: em(0.5),
        },
        totalPriceText: {
            borderBottom: "1px solid",
            borderColor: color(theme.colors.secondary).fade("50%").toString(),
            padding: padding(0, em(0.5), 0, em(1)),
        },
        totalPriceSum: {
            borderBottom: "1px solid",
            borderColor: color(theme.colors.secondary).fade("50%").toString(),
        },
    })(TotalPrice)
}
