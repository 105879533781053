import { OrderIdSource, OrderVoucherList } from "@tm/models"
import { useVoucherErpState } from "../../../data/hooks/useVoucherErpState"
import StatusFlag from "../StatusFlag"

type Props = {
    orderVouchers: OrderVoucherList[]
    order: OrderVoucherList
}

export default function ErpState({ orderVouchers, order }: Props) {
    const { erpStates, erpStateLoading } = useVoucherErpState({
        orderIdentifier: orderVouchers.map((x) => ({
            catalogOrderId: x.orderNumbers?.find((y) => y.type === undefined || y.type === OrderIdSource.CatalogSystem)?.number,
            wholesalerOrderId: x.orderNumbers?.find((y) => y.type === OrderIdSource.Wholesaler)?.number,
            customOrderId: x.orderNumbers?.find((y) => y.type === OrderIdSource.User)?.number,
        })),
    })

    const erpState = erpStates?.orderStatus.find((x) => {
        const catalogOrderId = order.orderNumbers?.find((y) => y.type === undefined || y.type === OrderIdSource.CatalogSystem)?.number
        const wholesalerOrderId = order.orderNumbers?.find((y) => y.type === OrderIdSource.Wholesaler)?.number
        const customOrderId = order.orderNumbers?.find((y) => y.type === OrderIdSource.User)?.number

        return (
            (catalogOrderId && x.orderIdentifier?.catalogOrderId === catalogOrderId) ||
            (wholesalerOrderId && x.orderIdentifier?.wholesalerOrderId === wholesalerOrderId) ||
            (customOrderId && x.orderIdentifier?.customOrderId === customOrderId)
        )
    })?.orderStatusMessage

    return <StatusFlag status={erpState} isLoading={erpStateLoading} />
}
