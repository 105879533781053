import { Box, Button, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    onRetry(): void
}

export function RetryError({ onRetry }: Props) {
    const { translate } = useLocalization()

    return (
        <Box display="flex" alignItems="center">
            <Typography variant="h4" mr={1}>
                {translate(787)}
            </Typography>
            <Button onClick={onRetry} startIcon={<Icon name="refresh" />}>
                {translate(13210)}
            </Button>
        </Box>
    )
}
