import { AddCustomPartListRequest } from "@tm/models"

import { PostMessageRequest } from "../../../data"
import { getBasketPositionMemo, getCatalogPartMemo } from "../business/helpers"
import { PostMessageControllerComponent } from "../component"

export default function handleAddCustomPartsToBasket(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { addCustomPartsToBasket } = data
    const { workTask, userSettings, workTaskTruckData, addCustomPartList } = this.props

    if (addCustomPartsToBasket?.length > 0 && workTask) {
        const memo = getBasketPositionMemo(userSettings?.orderOptions, workTask, workTaskTruckData)

        const request: AddCustomPartListRequest = {
            workTaskId: workTask.id,
            customerId: workTask.customer && workTask.customer.id,
            vehicleId: workTask.vehicle && workTask.vehicle.id,
            customParts: addCustomPartsToBasket.map((customPart) => ({
                ...customPart,
                memo: getCatalogPartMemo(customPart.memo, memo),
            })),
        }

        addCustomPartList(request, true).then(
            () => {
                if (source) {
                    source.postMessage({ name: "addCustomPartsToBasket", success: true, parts: request.customParts }, "*")
                }
            },
            () => {
                if (source) {
                    source.postMessage({ name: "addCustomPartsToBasket", success: false, parts: request.customParts }, "*")
                }
            }
        )
    }
}
