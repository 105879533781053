import { useEffect, useMemo, useState } from "react"
import { createErpInformationRequestItem, useDefaultErpSystem, useGetDetailedErpInfo, mapTeccomERPtoWarehouse } from "@tm/utils"
import { Article, ErpInformationRequestItem, ErpInformation, TeccomErpInformation, ErpPrice, ErpSystemConfig, Warehouse } from "@tm/models"
import { useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useTeccom } from "./useTeccom"

type ErpCallbacks = {
    onReset?(): void
    onLoad?(request: ErpInformationRequestItem, response: ErpInformation): void
    onError?(error: unknown): void
}

export function useErpInfo(
    article: Article | undefined,
    type: "list" | "details",
    callbacks: ErpCallbacks = {},
    overwriteErpSystemConfig?: ErpSystemConfig,
    searchQuery?: string
) {
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const [error, setError] = useState<string>()
    const { workTask } = useWorkTask() ?? {}
    const { id: vehicleId } = workTask?.vehicle ?? {}
    const { erpSystemConfig, erpConfig } = useDefaultErpSystem(overwriteErpSystemConfig)
    const { translateText } = useLocalization()

    const { data, isLoading } = useGetDetailedErpInfo(
        {
            articles: article ? [article] : [],
            type,
            vehicleId,
            distributorId: erpSystemConfig?.id,
            telesalesCustomerNo,
            searchQuery,
        },
        {
            onError: (err: string | null) => {
                callbacks.onError?.(err)
                setError(err || undefined)
            },
        }
    )

    const erpInfo = data?.[0]

    useEffect(() => {
        if (erpInfo && article) {
            callbacks.onLoad?.(createErpInformationRequestItem(article, vehicleId), erpInfo)
        }
    }, [erpInfo]) // callbacks.onLoad creates a new instance each render cycle. Do NOT add it to the dependency

    const { teccom, loadTeccom, teccomLoading } = useTeccomWithArticle(erpInfo?.isTeccomRequestAvailable, article, erpSystemConfig?.id, vehicleId)

    const mergedErpInfo = useMemo(() => {
        if (teccom && erpInfo) {
            return mergeTeccom(erpInfo, teccom, translateText)
        }

        return erpInfo
    }, [teccom, erpInfo])

    return { loading: isLoading, erpInfo: mergedErpInfo, error, loadTeccom, teccomLoading, erpConfig, teccomResponse: teccom }
}

function useTeccomWithArticle(
    isTeccomRequestAvailable: boolean | undefined,
    article: Article | undefined,
    distributorId: number | undefined,
    vehicleId?: string
) {
    const erpRequest = article ? createErpInformationRequestItem(article, vehicleId) : undefined
    return useTeccom(isTeccomRequestAvailable, erpRequest, distributorId)
}

export function mergeWarehouses(
    translateText: (key: string | number) => string,
    erpWarehouses?: Warehouse[],
    teccom?: TeccomErpInformation
): Warehouse[] | undefined {
    let warehouses
    if (erpWarehouses) {
        warehouses = [...erpWarehouses]
    }

    if (teccom) {
        const alternativeLabel = translateText(13440)
        const teccomWarehouse = mapTeccomERPtoWarehouse(teccom, alternativeLabel)

        if (warehouses) {
            const warehouseIndex = warehouses.findIndex((w) => w.isManufacturerWarehouse)
            if (warehouseIndex !== -1) {
                warehouses[warehouseIndex] = teccomWarehouse
            } else {
                warehouses.push(teccomWarehouse)
            }
        } else {
            warehouses = [teccomWarehouse]
        }
    }

    return warehouses
}

/**
 * @todo Discuss if the teccom information should be even merged with the ERP response for the new article list.
 * If so the teccom response should include everything from the original ERP request and the teccom information should be already merged.
 */
function mergeTeccom(erpInfo: ErpInformation, teccom: TeccomErpInformation, translateText: (key: string | number) => string): ErpInformation {
    return {
        ...erpInfo,
        // first look at the teccom response, then at the possible chached entry on the ErpInfo object
        availability: teccom.specialProcurementErpInformation?.availability || erpInfo.specialProcurementErpInformation?.availability || {},
        warehouses: mergeWarehouses(translateText, erpInfo.warehouses, teccom),
        specialProcurementErpInformation: teccom.specialProcurementErpInformation || erpInfo.specialProcurementErpInformation,
        prices: mergeTeccomPrices(erpInfo.prices, teccom.prices),
    }
}

export function mergeTeccomPrices(erpPrices?: Array<ErpPrice>, teccomPrices?: Array<ErpPrice>) {
    if (!teccomPrices?.length) {
        return erpPrices
    }

    if (!erpPrices?.length) {
        return teccomPrices
    }

    const mergedPrices = [...erpPrices]

    teccomPrices.forEach((teccomPrice) => {
        const index = mergedPrices.findIndex((x) => x.type === teccomPrice.type)

        if (index !== -1) {
            mergedPrices[index] = {
                ...teccomPrice,
                description: mergedPrices[index].description, // keep the original description as the one from the teccom response is different,
            }
        }
    })

    return mergedPrices
}
