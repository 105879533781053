import { useRef, useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Text, TextField, Button, WarningPrompt } from "@tm/controls"

type Props = {
    note: string
    itemCommentMaxLength?: number
    onSaveNote(note: string): void
}

export default function PartsNote(props: Props) {
    const { translateText } = useLocalization()

    const dialogRef = useRef<WarningPrompt>(null)
    const confirmationRef = useRef<Button>(null)

    const [note, setNote] = useState(props.note)

    useEffect(() => {
        setNote(props.note)
    }, [props.note])

    const handleNoteChange = (note: string) => {
        setNote(note)
    }

    const handleNoteChangeConfirm = (text: any) => {
        if (text != undefined && note) {
            confirmationRef.current?.focus()
        }
    }

    const handleSaveNote = () => {
        if (note) {
            props.onSaveNote(note)
        }
    }

    const handleRemoveNote = () => {
        dialogRef.current?.show()
    }

    const handleWarningConfirm = () => {
        props.onSaveNote("")
    }

    return (
        <div className="parts-note">
            <Text size="s">{translateText(39)}</Text>
            <TextField
                value={note}
                onChange={handleNoteChange}
                onChangeConfirm={handleNoteChangeConfirm}
                showLength
                maxLength={props.itemCommentMaxLength || 200}
            />
            <Button disabled={!note || note == props.note} onClick={handleSaveNote} ref={confirmationRef}>
                {translateText(9)}
            </Button>
            <Button title={translateText(624)} onClick={handleRemoveNote} icon="delete" disabled={!note} layout={["ghost"]} />
            <WarningPrompt
                text={translateText(838)}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                ref={dialogRef}
                onConfirm={handleWarningConfirm}
            />
        </div>
    )
}
