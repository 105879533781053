import { useState, useRef } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Typography, IconButton, Icon } from "@tm/components"
import { ModelState, validateField } from "@tm/utils"
import { Button, TextField } from "@tm/controls"
import RemoveItemsDialog from "../../_shared/RemoveItemsDialog"

type Props = {
    note: string
    returnInfoMaxLength?: number
    isReturnInfoRequired: boolean
    isUpdating: boolean
    onEditNote(note: string): void
}

export default function PartsNoteComponent(props: Props) {
    const { translateText } = useLocalization()

    const { returnInfoMaxLength, isReturnInfoRequired, isUpdating } = props
    const [note, setNote] = useState<string>(props.note)
    const [showDeleteDialog, setShowDeleteDialog] = useState(false)

    const saveButtonRef = useRef<Button>(null)

    function handleNoteChange(value: string) {
        setNote(value)
    }

    function handleNoteChangeConfirm(text: string) {
        if (text !== undefined) {
            if (note) {
                saveButtonRef.current?.focus()
            }
        }
    }

    function handleSaveNote() {
        if (!note) {
            return
        }
        props.onEditNote(note)
    }

    function handleRemoveNoteClick() {
        setShowDeleteDialog(true)
    }

    function deleteCurrentNote() {
        props.onEditNote("")
    }

    function closeDeleteDialog() {
        setShowDeleteDialog(false)
    }

    const modelState: ModelState = {
        note: validateField(note).min(0, translateText(522)).messages,
    }

    return (
        <Box display="flex" alignItems="center" mt={1} padding={0.3}>
            <Typography variant="body1" mr={1}>
                {translateText(39)}
            </Typography>
            <TextField
                className="parts-note"
                path={["note"]}
                modelState={isReturnInfoRequired ? modelState : undefined}
                value={note}
                onChange={handleNoteChange}
                onChangeConfirm={handleNoteChangeConfirm}
                showLength
                maxLength={returnInfoMaxLength || 200}
                disabled={isUpdating}
            />
            <Button disabled={!note || note === props.note || isUpdating} onClick={handleSaveNote} ref={saveButtonRef}>
                {translateText(9)}
            </Button>
            <IconButton sx={{ maxHeight: "30px", ml: 1 }} onClick={handleRemoveNoteClick} title={translateText(624)} disabled={!note || isUpdating}>
                <Icon name="delete" height="15px" width="15px" />
            </IconButton>
            <RemoveItemsDialog
                dialogTextId={838}
                showDeleteDialog={showDeleteDialog}
                onCloseRemoveDialog={closeDeleteDialog}
                onRemoveReturnItems={deleteCurrentNote}
            />
        </Box>
    )
}
