import { CostEstimation } from "@tm/data/vouchers/costEstimations"
import { B2BGross } from "./components/B2BGross"
import { B2BNet } from "./components/B2BNet"
import { B2CGross } from "./components/B2CGross"
import { B2CNet } from "./components/B2CNet"

type Props = {
    costEstimation?: CostEstimation
}

export function AllTotals({ costEstimation }: Props) {
    const { costEstimationOptions, totalsB2B, totalsB2C, currencyCode } = costEstimation || {}
    const { customerGroupMode, priceVatMode } = costEstimationOptions || {}
    const code = currencyCode ?? ""

    if (customerGroupMode) {
        return priceVatMode ? <B2BGross currencyCode={code} totals={totalsB2B} /> : <B2BNet currencyCode={code} totals={totalsB2B} />
    }

    return priceVatMode ? <B2CGross currencyCode={code} totals={totalsB2C} /> : <B2CNet currencyCode={code} totals={totalsB2C} />
}
