import { formatDate } from "@tm/utils"
import { CalculationHistoryStatus } from "../data/models"

export function getHistoryStatusFromEnum(status: CalculationHistoryStatus): { icon: string; textId: string; buttonTextId: string } {
    switch (status) {
        case CalculationHistoryStatus.FastCalcHistory:
        case CalculationHistoryStatus.ReadyToStart:
            return {
                icon: "maintenance-plan",
                textId: "12605",
                buttonTextId: "12606",
            }
        case CalculationHistoryStatus.InProgress:
            return {
                icon: "maintenance-plan",
                textId: "13442",
                buttonTextId: "13443",
            }
        case CalculationHistoryStatus.ReadyForExam:
            return {
                icon: "inspection",
                textId: "13444",
                buttonTextId: "12610",
            }
        case CalculationHistoryStatus.InExamination:
            return {
                icon: "inspection",
                textId: "12611",
                buttonTextId: "13494",
            }
        case CalculationHistoryStatus.Finished:
            return {
                icon: "checked-filled",
                textId: "12612",
                buttonTextId: "13445",
            }
        default:
            return {
                icon: "maintenance-plan",
                textId: "12605",
                buttonTextId: "12606",
            }
    }
}

export function getFormattedDate(date: Date): { dayTextId: string; date: string; time: string } | undefined {
    if (!date) {
        return undefined
    }
    const weekDay = date.getDay()
    let dayTextId = "533" // Default Sunday
    switch (weekDay) {
        case 0:
            dayTextId = "533" // Sunday
            break
        case 1:
            dayTextId = "527" // Monday
            break
        case 2:
            dayTextId = "528"
            break
        case 3:
            dayTextId = "529"
            break
        case 4:
            dayTextId = "530"
            break
        case 5:
            dayTextId = "531"
            break
        case 6:
            dayTextId = "532" // Saturday
            break

        default: // Sunday
            dayTextId = "533"
            break
    }

    return {
        dayTextId,
        date: formatDate(date, "d"),
        time: `${date.getHours().pad(2)}:${date.getMinutes().pad(2)}`,
    }
}
