import { Box, Grid, styled } from "@tm/components"
import { PropsWithChildren, forwardRef, useImperativeHandle, useRef } from "react"
import { useArticleListConfiguration } from "../ArticleListConfiguration"
import { Filters } from "./Filters"

export type ArticleListWrapperRefType = {
    scrollToTop: () => void
}

type ArticleListWrapperProps = { className?: string }

const ArticleFilters = styled(Filters)(({ theme }) => ({
    paddingLeft: theme.spacing(1),
    backgroundColor: theme.overwrites?.components?.partListV2?.backgroundColor,
}))

export const ArticleListWrapper = forwardRef<ArticleListWrapperRefType, PropsWithChildren<ArticleListWrapperProps>>(
    ({ className, children }, ref) => {
        const { filtersAtLeftSide } = useArticleListConfiguration()

        const scrollerRef = useRef<HTMLDivElement>(null)

        useImperativeHandle(
            ref,
            () => ({
                scrollToTop() {
                    scrollerRef.current?.scrollTo({ top: 0 })
                },
            }),
            []
        )

        return (
            <Grid display="grid" gridTemplateColumns={filtersAtLeftSide ? "auto 1fr" : "1fr auto"} overflow="hidden" pl={1} className={className}>
                {filtersAtLeftSide && <ArticleFilters />}
                <Box ref={scrollerRef} sx={{ overflowY: "scroll" }} paddingX={1}>
                    {children}
                </Box>
                {!filtersAtLeftSide && <ArticleFilters />}
            </Grid>
        )
    }
)
