import { Box, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ErrorReason } from "../../../models"

type Props = {
    errorReason?: ErrorReason
    isSindriError?: boolean
}

export default function JobListError({ errorReason, isSindriError }: Props) {
    const { translateText } = useLocalization()

    if (errorReason === ErrorReason.NotFound) {
        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                }}
            >
                <Icon name="no-results" width="15rem" height="4rem" />
                <Typography variant="h2">{translateText(163)}</Typography>
            </Box>
        )
    }

    return (
        <Box sx={{ flex: "1", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Typography variant="h1" sx={{ textAlign: "center", overflowWrap: "break-word", maxWidth: "50%" }}>
                {isSindriError ? translateText(13588) : translateText(787)}
            </Typography>
        </Box>
    )
}
