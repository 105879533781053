import * as React from "react"
import { style } from "typestyle"
import { NestedCSSProperties } from "typestyle/lib/types"

import { concat } from "@tm/utils"
import { getStyleTheme } from "@tm/context-distribution"

function getComponentStyles(): NestedCSSProperties {
    const { margin } = getStyleTheme()

    return {
        display: "flex",
        alignItems: "center",
        margin: `${margin.l} 0`,
        $nest: {
            "&__line": {
                flex: 1,
                height: "1px",
                backgroundColor: "#acacac",
                $nest: {
                    "&:not(:first-child)": {
                        marginLeft: margin.l,
                    },
                    "&:not(:last-child)": {
                        marginRight: margin.l,
                    },
                },
            },
        },
    }
}

type Props = React.HTMLAttributes<HTMLDivElement> & {
    left?: React.ReactNode
    right?: React.ReactNode
}

export const HorizontalRule: React.FC<Props> = (props) => {
    const { left, right, ...rest } = props

    const className = style(getComponentStyles())

    return (
        <div {...rest} className={concat(" ", props.className, className)}>
            {left}
            <div className={`${className}__line`} />
            {right}
        </div>
    )
}
