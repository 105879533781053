import { ConfigParams, RegistrationNoType, VehicleType } from "@tm/models"

import Morpheus from "@tm/morpheus"
import { callService } from "."

type Request = {
    query: string
    vehicleLookupId?: number
    resultSize?: number
}

export type Hit = {
    text: string
    highlight?: string
}

function getEndpointUrl(vehicleType: VehicleType | undefined): string | undefined {
    const { autosugestServiceUrl } = Morpheus.getParams<ConfigParams>()

    switch (vehicleType) {
        case VehicleType.PassengerCar:
            return `${autosugestServiceUrl}/CarModels`
        case VehicleType.Motorcycle:
            if (autosugestServiceUrl.toUpperCase().includes("V7")) {
                return `${autosugestServiceUrl}/BikeModels`
            }
            return undefined
        default:
            return undefined
    }
}

export async function loadVehicleSuggestions(query: string, vehicleLookupId?: RegistrationNoType, vehicleType?: VehicleType): Promise<Hit[]> {
    const url = getEndpointUrl(vehicleType)

    if (!url) {
        return []
    }

    const data = await callService<Request, { hits: Array<Hit | string> }>(url, {
        query,
        vehicleLookupId,
        resultSize: 50,
    })

    return (data?.hits ?? []).map((hit) => (typeof hit === "string" ? { text: hit } : hit)) // Map response from old endpoints to new format
}
