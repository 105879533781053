import { Tooltip } from "@tm/components"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, ErpInformation, ErpPrice, ErpSystemConfig } from "@tm/models"
import GraduatedPricesDropdownContent from "./content"

type Props = {
    layout: "icon" | "text"

    graduatedPrices: Array<ErpPrice>
    article: Article
    erpInfo?: ErpInformation
    overwriteErpSystemConfig?: ErpSystemConfig
}

export default function GraduatedPricesDropdown({ layout, graduatedPrices, article, erpInfo, overwriteErpSystemConfig }: Props) {
    const { translateText } = useLocalization()

    return (
        <Tooltip
            variant="light"
            color="primary"
            title={
                <GraduatedPricesDropdownContent
                    graduatedPrices={graduatedPrices}
                    article={article}
                    erpInfo={erpInfo}
                    overwriteErpSystemConfig={overwriteErpSystemConfig}
                />
            }
        >
            {/* Don't delete the div. It's necessary so mui can add the event listeners. */}
            <div>
                {layout === "icon" ? (
                    <Button icon="staffelpreis" layout={["ghost"]} size="l" />
                ) : (
                    <Button className="price__graduated__tooltip__button" icon="down" size="xs" layout={["ghost"]}>
                        {translateText(705)}
                    </Button>
                )}
            </div>
        </Tooltip>
    )
}
