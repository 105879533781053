import { useLocalization } from "@tm/localization"
import { renderRoute } from "@tm/utils"
import { getBundleParams } from "../../../../../../utils"
import { MergedVehicle } from "../../../../../../data/hooks"
import { getDateFromMetadata } from "../appointment-info"
import { addDaysToDate, getDateWithoutTime } from "../../../../../_shared/helper"
import WideButton from "../../wide-button"
import { bundleChannel, openWorkTask } from "../../../../../../bundle-channel"

type Props = {
    vehicle: MergedVehicle
}

const imageMimeTypes = ["image/jpeg", "image/png", "image/gif"]

export default function VehicleCostEstimateInfo({ vehicle }: Props) {
    const { translateText, date: formatDate, languageId } = useLocalization()
    const { costEstimate, appointment } = vehicle

    const appointmentDate = appointment && new Date(getDateFromMetadata(appointment))

    if (
        !costEstimate ||
        !appointmentDate ||
        getDateWithoutTime(costEstimate.initialMessage.inserted) > addDaysToDate(appointmentDate, 28) ||
        getDateWithoutTime(costEstimate.initialMessage.inserted) < addDaysToDate(appointmentDate, -28)
    ) {
        const handleClick = () => {
            openWorkTask({ selectedChatVehicle: vehicle, subRoute: getBundleParams().costEstimateRouteWithoutWorkTaskId })
        }

        return <WideButton text={translateText(1607)} icon={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} onClick={handleClick} />
    }

    let handleClick
    let linkTo
    if (!costEstimate.initial.externalOffer) {
        handleClick = () => {
            const url = renderRoute(getBundleParams().costEstimationDetailsRouteWithoutWorkTaskId, { id: costEstimate.initial.offerId })
            openWorkTask({ selectedChatVehicle: vehicle, subRoute: url })
        }
    } else if (costEstimate.initialMessage.attachment?.mimeType === "application/pdf") {
        linkTo = costEstimate.initialMessage.attachment.uri
    } else if (imageMimeTypes.includes(costEstimate.initialMessage.attachment?.mimeType || "")) {
        handleClick = () => {
            bundleChannel("CHAT").publish("SHOW_IMAGE_VIEWER", { message: costEstimate.initialMessage })
        }
    }

    const text = `${translateText(3047)} (${formatDate(new Date(costEstimate.initialMessage.inserted), "d")})`

    return <WideButton text={text} icon={languageId === "1" ? "voucher-kva" : "voucher-kva-international"} onClick={handleClick} linkTo={linkTo} />
}
