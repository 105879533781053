import { ERIKContentService, FastService } from "@tm/data"
import { useCallback } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useGetUploadKey } from "./useGetUploadKey"
import { useCompleteFileUpload } from "./useCompleteFileUpload"

function getBaseUrl(url: string) {
    return url?.split("/upload")?.[0] ?? ""
}

export type UploadFileProps = {
    file: File
    category?: string
    stepName?: string
    vehicleId?: string
    vin?: string
    mileage?: string
    workshopUser?: string
}

/**
 * @description Use this hook for single file upload.\
 * Only small files are supported for now\
 * Category and additional labels need to be added from getUploadKey hook
 */
export function useUploadFile() {
    const queryClient = useQueryClient()
    const { isLoading: uploadKeyLoading, getUploadKey } = useGetUploadKey()
    const { isLoading: fileUploadLoading, mutateAsync: uploadFileMutation } = useMutation(ERIKContentService.uploadSingleImage)
    const { data: uploadedFileData, isLoading: completeFileUploadLoading, completeFileUpload } = useCompleteFileUpload()

    const uploadFile = useCallback(
        async ({ file, category, stepName, vehicleId, vin, mileage, workshopUser }: UploadFileProps) => {
            const uploadKeyRequest: FastService.GetUploadKeyRequest = {
                maxFileCount: 1,
                application: "FastService",
                category,
                area: stepName,
                vehicleId,
                vin,
                mileage,
                workshopUser,
            }

            const uploadDetails = await getUploadKey(uploadKeyRequest)

            if (uploadDetails) {
                const baseUrl = getBaseUrl(uploadDetails.uploadUrl)

                const request: ERIKContentService.UploadSingleImageRequest = {
                    baseUrl,
                    fileIndex: 0,
                    partId: uploadDetails.parts[0].id,
                    uploadRequestId: uploadDetails.id,
                    uploadKey: uploadDetails.uploadKey,
                    file,
                }

                try {
                    const uploaded = await uploadFileMutation(request)

                    if (uploaded) {
                        return completeFileUpload(baseUrl, uploadDetails.id, uploadDetails.uploadKey)
                    }
                } catch (error) {
                    // TODO Error handling when messages are provided
                }
            }
        },
        [uploadFileMutation, getUploadKey, completeFileUpload, queryClient]
    )

    return { uploadedFileData, isLoading: uploadKeyLoading || fileUploadLoading || completeFileUploadLoading, uploadFile }
}
