import { useStyle } from "@tm/context-distribution"
import { border, em, px } from "csx"
import { Typography } from "@tm/components"
import { mapGenartToNotFoundArticle } from "../../../data/helpers"
import { CalcGenArt, CalculationContext } from "../../../data/model"
import { ArticleItem, BorderedCollapsible, NoArticleFound } from "../../_shared"
import { useFastCalculatorStore } from "../../../state"

type Props = {
    hideAlternatives?: boolean
    showProductGroupAlternatives(genArt: CalcGenArt): void
}

export default function ArticleList(props: Props) {
    const selectedCalcState = useFastCalculatorStore((state) => state.selectedCalcState)

    const articles = (selectedCalcState?.context as CalculationContext | undefined)?.articles

    const renderGenArtItem = (genArt: CalcGenArt) => {
        return (
            <div key={genArt.id} className={style.genArtItem}>
                <Typography variant="h4" className={style.genArtLabel}>
                    {genArt.genArtLabel}
                </Typography>
                <div className="tk-parts">
                    <div className="list">
                        <div className="article-list">
                            {genArt.articles ? (
                                genArt.articles.map((article, idx) => (
                                    <ArticleItem {...props} key={article.id + idx} genArt={genArt} item={article} />
                                ))
                            ) : (
                                <NoArticleFound
                                    article={mapGenartToNotFoundArticle(genArt)}
                                    genArt={genArt}
                                    showProductGroupAlternatives={props.showProductGroupAlternatives}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (!articles?.genArts) {
        return null
    }

    return (
        <BorderedCollapsible name={articles?.label ?? ""} initiallyOpened>
            {articles.genArts.map(renderGenArtItem)}
        </BorderedCollapsible>
    )
}

const style = useStyle({
    genArtLabel: {
        padding: em(0.5),
        borderBottom: border({ color: "#e2e2e2", style: "solid", width: px(2) }),
    },
    genArtItem: {
        marginBottom: em(0.5),
        $nest: {
            ".article__cell--edit-price": {
                marginRight: ".25em",
            },
            ".article__cell--actions": {
                minWidth: "33em",
            },
            ".article__cell--actions .more-menu": {
                marginLeft: "auto",
            },
        },
    },
})(ArticleList)
