import { List, ListItem, ListItemButton, listItemButtonClasses, ListItemText, styled } from "@tm/components"
import { AttributeFilterModel } from "@tm/models"
import FilterItem from "../FilterItem"

const DefaultList = styled(List)(({ theme }) => ({
    [`.${listItemButtonClasses.root}`]: {
        padding: `${theme.spacing()} ${theme.spacing(2)}`,
    },
}))

type DefaultSelectOptionsProps = {
    options: AttributeFilterModel[]
    onSelect(filter: AttributeFilterModel): void
}

export default function DefaultSelectOptions(props: DefaultSelectOptionsProps) {
    const { options, onSelect } = props

    return (
        <DefaultList>
            {options.map((item) => (
                <ListItem key={item.value} disablePadding>
                    <ListItemButton onClick={() => onSelect(item)}>
                        <FilterItem filter={item} />
                    </ListItemButton>
                </ListItem>
            ))}
        </DefaultList>
    )
}
