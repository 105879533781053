import { FastUp } from "@tm/data"
import { useQuery } from "react-query"
import { getBundleParams } from "../../utils"
import { QueryKeys } from ".."

export function useFastUpStorageUris(transferId: string | undefined, enableFetch: boolean) {
    const baseUrl = getBundleParams().fastUpApiBaseUrl

    const { data, isLoading } = useQuery(
        [QueryKeys.FastUpStorageUri, transferId, enableFetch, baseUrl],
        () => (transferId ? FastUp.getStorageUris(baseUrl, transferId) : undefined),
        {
            enabled: !!transferId && enableFetch,
            refetchInterval: 10000, // 10s
        }
    )

    return { storageUris: data?.storageUris, isLoading }
}
