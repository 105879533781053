import { CatalogPart, CustomPart, PartsContainer, RegisteredModels, SupplierArticle, WholesalerPart } from "@tm/models"
import { Container } from "@tm/nexus"
import { encodeUniqueId, Optional, renderRoute, showWarehouseDataMissingError, uniqueId } from "@tm/utils"

import { getBundleParams } from "../../../utils"
import { PostMessageRequest } from "../../../data"
import { getBasketPositionMemo, getCatalogPartMemo } from "../business/helpers"
import { PostMessageControllerComponent } from "../component"

export default function handleAddPartsToBasket(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { addPartsToBasket } = data

    if (!addPartsToBasket || !Array.isArray(addPartsToBasket) || !addPartsToBasket.length) {
        return
    }

    const {
        workTask,
        userSettings,
        workTaskTruckData,
        erpSystemConfig,
        warehouseData,
        refetchWarehouseData,
        localization,
        addCatalogPartList,
        addWholesalerPartList,
        addCustomPartList,
    } = this.props

    if (!warehouseData || warehouseData.hasErrors) {
        showWarehouseDataMissingError(localization.translateText)
        refetchWarehouseData()
        return
    }

    const memo = getBasketPositionMemo(userSettings?.orderOptions, workTask, workTaskTruckData)

    const catalogParts: Array<Optional<CatalogPart, "dataSupplierName" | "productGroupId" | "productGroupName">> = []
    const customParts: Array<CustomPart> = []
    const wholesalerParts: Array<WholesalerPart> = []

    const catalog = sessionStorage.getItem("catalog")
    const isNeimcke = !!catalog && catalog.toLowerCase().indexOf("neimcke") >= 0

    addPartsToBasket.forEach((part) => {
        const partMemo = getCatalogPartMemo(part.orderMemo, memo)

        if (part.wholesalerArticleNumber) {
            // if an wholesaler article number is supplied, try to get the corresponding catalog article

            // trim whitespace (should be done by external frame?!)
            // eslint-disable-next-line no-param-reassign
            part.wholesalerArticleNumber = part.wholesalerArticleNumber.trim()

            wholesalerParts.push({
                wholesalerArticleNumber: part.wholesalerArticleNumber,
                description: part.description,
                additionalDescription: undefined,
                manufacturerName: part.dataSupplierName,
                productGroupName: undefined,
                quantityValue: part.quantity,
                memo: partMemo,
                warehouseId: warehouseData.defaultWarehouse?.id,
                warehouseName: warehouseData.defaultWarehouse?.name,
                advertisementCategoryId: part.offerNumber,
                distributorId: erpSystemConfig?.id,
                distributorName: erpSystemConfig?.description,
            })
        } else if (part.dataSupplierArticleNumber) {
            // trim whitespace (should be done by external frame?!)
            part.dataSupplierArticleNumber = part.dataSupplierArticleNumber.trim()

            if (isNeimcke) {
                // fallback/default values for Neimcke. This should not be done by us!
                part.dataSupplierId = part.dataSupplierId || 2115
                part.dataSupplierName = part.dataSupplierName || "Neimcke"
                part.productGroupId = part.productGroupId || 17692
                part.productGroupName = part.productGroupName || "Neimcke-Artikel"
            } else if (!part.dataSupplierId) {
                customParts.push({
                    articleNumber: part.dataSupplierArticleNumber,
                    description: part.description || this.props.localization.translateText(757),
                    quantityValue: part.quantity,
                    advertisementCategoryId: part.offerNumber,
                    memo: partMemo,
                })
                return
            }

            catalogParts.push({
                additionalDescription: "",
                dataSupplierArticleNumber: part.dataSupplierArticleNumber,
                dataSupplierId: part.dataSupplierId,
                dataSupplierName: part.dataSupplierName,
                description: part.description || "",
                productGroupId: part.productGroupId,
                productGroupName: part.productGroupName,
                memo: partMemo,
                quantityValue: part.quantity,
                thumbnailUrl: part.thumbnailUrl || "",
                warehouseId: warehouseData.defaultWarehouse?.id,
                warehouseName: warehouseData.defaultWarehouse?.name,
                wholesalerArticleNumber: part.wholesalerArticleNumber,
                advertisementCategoryId: part.offerNumber,
                distributorId: erpSystemConfig?.id,
                distributorName: erpSystemConfig?.description,
            })
        } else {
            customParts.push({
                articleNumber: this.props.localization.translateText(757),
                description: part.description,
                quantityValue: part.quantity,
                advertisementCategoryId: part.offerNumber,
                memo: partMemo,
            })
        }
    })

    const workTaskId = workTask?.id || uniqueId()
    const baseRequest = {
        workTaskId,
        customerId: workTask?.customer?.id,
        vehicleId: workTask?.vehicle?.id,
    }

    if (!workTask?.id) {
        let url = `/${encodeUniqueId(workTaskId)}`

        const { offersDetailsUrl } = getBundleParams()
        if (data.sourceId === "TM_ACTIONS_APP" && offersDetailsUrl) {
            url = renderRoute(offersDetailsUrl, { workTaskId: encodeUniqueId(workTaskId) })
        }

        this.props.history.push(url)
    }

    if (catalogParts.length) {
        const supplierArticles: Array<SupplierArticle> = catalogParts.map((x) => ({
            supplierArticleNo: x.dataSupplierArticleNumber,
            supplierId: x.dataSupplierId,
        }))

        const container = Container.getInstance(RegisteredModels.Articles) as PartsContainer
        container
            .action("getArticlesBySupplierArticleNos")(supplierArticles)
            .then((articlesDictionary) => {
                Object.values(articlesDictionary)
                    .filter((x) => x.length)
                    .forEach((articles) => {
                        const article = articles[0]

                        const requestPart = catalogParts.find(
                            (x) => x.dataSupplierArticleNumber === article.supplierArticleNo && x.dataSupplierId === article.supplier.id
                        )
                        if (!requestPart) {
                            return
                        }

                        // Remove manual part before adding the found one
                        catalogParts.remove((x) => x.dataSupplierArticleNumber === article.supplierArticleNo)

                        catalogParts.push({
                            additionalDescription: article.additionalDescription,
                            dataSupplierArticleNumber: article.supplierArticleNo,
                            dataSupplierId: article.supplier.id,
                            dataSupplierName: article.supplier.name,
                            description: article.description,
                            productGroupId: article.productGroup.id,
                            productGroupName: article.productGroup.name,
                            memo: getCatalogPartMemo(requestPart.memo, memo),
                            quantityValue: requestPart.quantityValue,
                            thumbnailUrl: article.thumbnail,
                            warehouseId: warehouseData.defaultWarehouse?.id,
                            warehouseName: warehouseData.defaultWarehouse?.name,
                            wholesalerArticleNumber: article.traderArticleNo,
                            advertisementCategoryId: requestPart.advertisementCategoryId,
                            distributorId: erpSystemConfig?.id,
                            distributorName: erpSystemConfig?.description,
                        })
                    })

                const mappedCatalogParts: Array<CatalogPart> = []
                const mappedCustomParts: Array<CustomPart> = []

                // Parts that didn't have the necessary infos and have not been found via supplierId & supplierArticleNumber have to be added as custom parts

                catalogParts.forEach((catalogPart) => {
                    if (catalogPart.dataSupplierName && catalogPart.productGroupId && catalogPart.productGroupName) {
                        mappedCatalogParts.push(catalogPart as CatalogPart)
                    } else {
                        mappedCustomParts.push({
                            articleNumber: catalogPart.dataSupplierArticleNumber,
                            description: catalogPart.description || this.props.localization.translateText(757),
                            quantityValue: catalogPart.quantityValue,
                            advertisementCategoryId: catalogPart.advertisementCategoryId,
                            memo: catalogPart.memo,
                        })
                    }
                })

                if (mappedCatalogParts.length) {
                    addCatalogPartList({ ...baseRequest, catalogParts: mappedCatalogParts })
                }

                if (mappedCustomParts.length) {
                    addCustomPartList({ ...baseRequest, customParts: mappedCustomParts }, true)
                }
            })
    }

    if (customParts.length) {
        addCustomPartList({ ...baseRequest, customParts }, true)
    }

    if (wholesalerParts.length) {
        addWholesalerPartList({ ...baseRequest, wholesalerParts })
    }
}
