import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetRepairShop(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { userSettings } = this.props

    if (data.getRepairShop) {
        const setRepairShop = {
            logo: userSettings && userSettings.repairShop && userSettings.repairShop.logo,
        }

        if (source) {
            source.postMessage({ setRepairShop }, "*")
        }
    }
}
