import { margin } from "csx"
import { useSelector } from "react-redux"
import { Icon, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box, Typography, useTheme } from "@tm/components"
import { toolkitPath } from "../../../utils"
import { summarySelector } from "../../main/business"

export default function Header() {
    const { prepareCalculationError } = useSelector(summarySelector)
    const { translateText } = useLocalization()
    const theme = useTheme()

    return (
        <>
            <Box padding={margin(theme.margin?.m ?? ".5em")} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                    <Typography className="summary-title" variant="h3">
                        {translateText(12529)}
                    </Typography>

                    <Box flexDirection="row" marginLeft={theme.margin?.m} display="flex" flex="1" height="3em">
                        <Image url={`${toolkitPath}/images/Saint-Gobain.png`} />
                    </Box>

                    {prepareCalculationError && (
                        <Box marginLeft={theme.margin?.m} display="flex" alignItems="center">
                            <Icon name="info" skin="danger" />
                            <Typography style={{ marginLeft: theme.margin?.m }} className="message">
                                {translateText(787)}. {translateText(401)}
                            </Typography>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    )
}
