import { IBundle } from "@tm/morpheus"
import Details from "./components/details"
import Widget from "./components/widget"
import { initTelematicsBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [Details, Widget],
    init: initTelematicsBundle,
}

export default bundle
