import { ActionDispatch } from "@tm/morpheus"
import { ArticleDetailsErpTabs } from "@tm/models"
import { DetailsNavigationState } from "./model"
import { BundleActionType } from "../../../business"

export * from "./model"

export type ComponentActionType = BundleActionType | { type: "SHOW_ARTICLE_DETAILS_ERP_TABS"; payload: ArticleDetailsErpTabs }

const DEFAULT_STATE: DetailsNavigationState = {
    showAlternativeArticlesTab: false,
    showReplacementArticlesTab: false,
    showSafetyDataSheetsTab: false,
    showPawnArticlesTab: false,
    showSpecialProcurement: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): DetailsNavigationState {
    switch (action.type) {
        case "DETAILSHEAD_RESET": {
            return {
                ...state,
                detailsResponse: undefined,
            }
        }
        case "DETAILSHEAD_LOADED": {
            return {
                ...state,
                detailsResponse: action.payload,
            }
        }
        case "SHOW_ARTICLE_DETAILS_ERP_TABS": {
            return {
                ...state,
                showAlternativeArticlesTab: action.payload.alternativeArticles,
                showReplacementArticlesTab: action.payload.replacementArticles,
                showSafetyDataSheetsTab: action.payload.safetyDataSheets,
                showPawnArticlesTab: action.payload.pawnArticles,
                showSpecialProcurement: action.payload.useSpecialProcurement,
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "DETAILSHEAD_LOADED":
        case "DETAILSHEAD_RESET":
        case "SHOW_ARTICLE_DETAILS_ERP_TABS": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

export type IActions = typeof Actions

export const Actions = {}
