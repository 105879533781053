import { useState, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Alert, ConfirmationDialog, Stack, Typography } from "@tm/components"
import { useHistory } from "react-router"
import { ConfirmationDialogKeys } from "@tm/models"
import { GlobalOrderOptionsComponent } from "../_shared/order-options/global"
import WorkTasks from "./components/WorkTasks"
import { PartsListConfigProps } from "../_shared/parts-list/component"
import { WorkTaskOrderButtonConfigProps } from "../_shared/order-button/worktask"
import { useGlobalOrderOptionsState } from "../../hooks/useGlobalOrderOptionsState"
import { useCentralOrderWorkTasks } from "../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import { useInvalidateWorkTaskBasketQueries } from "../../data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"
import CentralOrderNavigation, { CentralOrderNavigationConfigProps } from "./components/navigation"

type Props = {
    partListProps: PartsListConfigProps
    workTaskOrderButtonProps: WorkTaskOrderButtonConfigProps
    centralOrderNavigationProps: CentralOrderNavigationConfigProps
}

let originPush: any

/**
 * CentralOrderComponent, all handling for the central Order
 * @memberof Basket
 * @param props
 * @returns
 */
export default function CentralOrderComponent(props: Props) {
    const { translateText } = useLocalization()
    const history = useHistory()
    const [pushArgs, setPushArgs] = useState<any>()
    const { globalOrderOptionsEnabled, centralOrderParameters } = useGlobalOrderOptionsState()
    const { workTasks, includedWorkTasks } = useCentralOrderWorkTasks().state
    const [open, setOpen] = useState(false)
    const { invalidatePartsRequests } = useInvalidateWorkTaskBasketQueries()

    function handleConfirmLeave() {
        workTasks.forEach((workTaskState) => invalidatePartsRequests(workTaskState.workTask.workTaskId))
        originPush && pushArgs && originPush(pushArgs[0], pushArgs[1])
        setOpen(false)
    }

    function handleCancelLeave() {
        setPushArgs(undefined)
        setOpen(false)
    }

    function historyPush(...args: any[]) {
        if (args[0].indexOf("/order?") === -1) {
            // details url begins with "?""
            setPushArgs(args)
            setOpen(true)
        } else {
            originPush && originPush(args[0], args[1])
        }
    }

    function revertOriginPush() {
        if (originPush) {
            history.push = originPush
        }
    }

    useEffect(() => {
        if (!originPush) {
            originPush = history.push
        }
        history.push = historyPush
        return revertOriginPush
    }, [])

    return (
        <Stack spacing={1} flex={1} marginX={2}>
            <CentralOrderNavigation {...props.centralOrderNavigationProps} />
            <Stack direction="row" justifyContent={globalOrderOptionsEnabled ? "space-between" : "flex-end"}>
                {globalOrderOptionsEnabled && <GlobalOrderOptionsComponent />}
                <Stack spacing={1} alignItems="flex-end">
                    <Typography variant="body2">
                        {translateText(13132).replace("{0}", includedWorkTasks.length.toString()).replace("{1}", workTasks.length.toString())}
                    </Typography>
                    {centralOrderParameters.useOrderCollection && (
                        <Alert
                            severity="info"
                            title={translateText(13131).replace("{0}", centralOrderParameters.orderPagingSize.toString())}
                            size="small"
                        />
                    )}
                </Stack>
            </Stack>
            {/* TODO: props.ordersUrl cannot be used for WorkTasks' ordersUrl since some catalogs like WM and default don't retunr the same order id as in vouchers */}
            <WorkTasks workTaskOrderButtonProps={props.workTaskOrderButtonProps} partListProps={props.partListProps} />
            <ConfirmationDialog
                open={open}
                confirmationButtonText={translateText(585)}
                cancelButtonText={translateText(584)}
                onConfirm={handleConfirmLeave}
                onCancel={handleCancelLeave}
                confirmationKey={ConfirmationDialogKeys.LeaveCentralOrder}
                PaperProps={{ sx: { maxWidth: "820px" } }}
            >
                <Typography fontSize="1.2rem" marginBottom="1rem">
                    {translateText(2005)}
                </Typography>
                <Typography fontSize="1rem">{translateText(2006)}</Typography>
            </ConfirmationDialog>
        </Stack>
    )
}
