import { MainState } from "../../components/main"

export const mapStateForSaving = ({ details, manager, navigation, summary }: MainState) => {
    const { selectedMmtGroup } = summary
    const { vehicle } = manager
    const { savedCriterias, criterias, extendedCarInfo, mmtList, savedSelectedValues, selectedCriterias,
        selectedValues, vknImages, selectedImagePosition, selectedVKNImage, selectionAvailable } = details

    const { loading: _, ...criteriasToSave } = criterias
    return {
        navigation,
        selectedMmtGroup: selectedMmtGroup?.svgEre,
        selectedErePositon: selectedMmtGroup?.selectedErePosition,

        savedCriterias: savedCriterias,
        savedSelectedValues: savedSelectedValues,
        selectedCriterias: selectedCriterias,
        selectedImagePosition: selectedImagePosition?.imageName,
        selectedVKNImage: selectedVKNImage?.imageName,
        vehicle: vehicle?.tecDocTypeId,
        sufficentSelection: vknImages.sufficientSelection,
        extendedSufficent: extendedCarInfo.sufficientSelection,

        criterias: criteriasToSave,

        selectionAvailable: selectionAvailable,
        mmtList: mmtList,
        savedValues: selectedValues


    }
}