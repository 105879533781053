import { useCallback } from "react"
import { useKeyValue } from "@tm/utils"
import { getBundleParams } from "../../utils"

export function useShowOnlyUserVouchers() {
    const { userVouchersEnabled = false } = getBundleParams()
    const [value, setValue, state] = useKeyValue({
        key: "VOUCHERS_SHOW_ONLY_USER_VOUCHERS",
        ownedByRepairShop: false,
    })

    const showOnlyUserVouchersLoaded = state === "hasValue" || state === "hasError"

    const showOnlyUserVouchers = showOnlyUserVouchersLoaded && value ? value === "true" : userVouchersEnabled

    const setShowOnlyUserVouchers = useCallback(
        (newValue: boolean) => {
            setValue?.(newValue.toString())
        },
        [setValue]
    )

    return { showOnlyUserVouchers, setShowOnlyUserVouchers, showOnlyUserVouchersLoaded }
}
