import { useMemo, useCallback, useState } from "react"
import { isEqual } from "lodash"
import Morpheus from "@tm/morpheus"
import {
    SupplierArticleDto,
    ErpInformation,
    SupplierArticleQuantities,
    Article,
    ActiveVehicleDataProviders,
    RepairTimeProvider,
    ConfigParams,
} from "@tm/models"
import {
    useHandleAddToBasket,
    useHandleRemoveFromBasket,
    useHandleAddToCostEstimation,
    useLoadErpInfos,
    useArticleErpInfos,
} from "../../ListV2/hooks"

import { ErpInfosData } from "../../ListV2/models"

export function useArticleBasketActions(
    article: Article,
    externalCatalogUrl: string | undefined,
    basketQuantities: SupplierArticleQuantities[],
    activeVehicleDataProviders: ActiveVehicleDataProviders | undefined,
    repairTimeProviders: RepairTimeProvider[] | undefined,
    erpInfosData: ErpInfosData,
    updateBasketQuantities: (articles: Article[]) => void,
    loadNextRecommendedArticle?: (article: Article) => Promise<void>
) {
    const [articleQuantity, setArticleQuantity] = useState(article.quantity)
    const [originalQuantity, setOriginalQuantity] = useState(article.quantity)

    const loadErpInfos = useLoadErpInfos(erpInfosData)
    const articleErpInfos = useArticleErpInfos(article, articleQuantity, erpInfosData)
    const defaultArticleErpInfoResponse = articleErpInfos.default?.state === "success" ? articleErpInfos.default.response : undefined

    const addToBasket = useHandleAddToBasket(article)
    const removeFromBasket = useHandleRemoveFromBasket()
    const addToKva = useHandleAddToCostEstimation(article, addToBasket, activeVehicleDataProviders, externalCatalogUrl, repairTimeProviders)

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    dataSupplierArticleNumber: article.supplierArticleNo,
                    dataSupplierId: article.supplier.id,
                    productGroupId: article.productGroup.id,
                } as SupplierArticleDto)
            ),
        [basketQuantities, article]
    )

    const postAddToBasket = useCallback(async () => {
        updateBasketQuantities([article])
    }, [article, updateBasketQuantities])

    const handleAddToBasket = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            loadNextRecommendedArticle?.(article)

            await addToBasket(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
        },
        [article, addToBasket, articleQuantity, defaultArticleErpInfoResponse, loadNextRecommendedArticle, postAddToBasket]
    )

    const handleAddToCostEstimation = useCallback(
        async (quantity?: number, erpInformation?: ErpInformation, erpSystemId?: number) => {
            await addToKva(quantity ?? articleQuantity, erpInformation ?? defaultArticleErpInfoResponse, erpSystemId)
            postAddToBasket()
        },
        [addToKva, articleQuantity, defaultArticleErpInfoResponse, postAddToBasket]
    )

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback(
        (value: number, loadErpInfo = true) => {
            setArticleQuantity(value)

            if (loadErpInfo) {
                setOriginalQuantity(value)

                if (articleErpInfos.default) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.default.erpSystem })
                }
                if (articleErpInfos.alternative) {
                    loadErpInfos({ article, quantity: value, erpSystem: articleErpInfos.alternative.erpSystem })
                }
            }
        },
        [article, loadErpInfos, articleErpInfos]
    )

    const { enableAddingButtonsAfterErp } = Morpheus.getParams<ConfigParams>()
    const isLoadingErpInfos = articleErpInfos.default?.state === "loading" || articleErpInfos.alternative?.state === "loading"
    const addButtonsDisabled = !!enableAddingButtonsAfterErp && isLoadingErpInfos

    return {
        originalQuantity,
        articleQuantity,
        basketQuantity,
        articleErpInfos,
        addButtonsDisabled,
        handleAddToBasket,
        handleAddToCostEstimation,
        handleRemoveFromBasket,
        handleChangeQuantity,
    }
}
