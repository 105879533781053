import { Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { channel } from "@tm/models"
import { Toolbar } from "@tm/controls"
import { ShellExportButton } from "../../navigation/components/ShellExportButton"
import { useLoadExportInfos } from "./useLoadExportInfos"
import { ModuleHeaderSlot } from "../../../../../parts/src/components/ListV2/components/ModuleHeader/ModuleHeaderSlot"

type Props = {
    exportTarget?: string
    showExportVoucherButton?: boolean
    showToolbar?: boolean
}
export default function ExportSlot({ exportTarget, showExportVoucherButton, showToolbar }: Props) {
    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const { exportLabel, exportUri } = useLoadExportInfos(workTaskId, showExportVoucherButton)

    const buttons = []

    if (exportUri && workTaskId) {
        buttons.push(
            <Button
                onClick={() => {
                    channel("WORKTASK", workTaskId).publish("WORKTASK/CLOSE", undefined)
                    window.location.href = exportUri!
                }}
                key="uri-export"
            >
                {exportLabel || exportTarget || translateText(1875)}
            </Button>
        )
    }

    const shellExportButtonRenders = !!ShellExportButton({})
    if (shellExportButtonRenders) {
        buttons.push(<ShellExportButton key="shell-export" />)
    }

    if (!buttons.length) {
        return null
    }

    return showToolbar ? (
        <Toolbar title={translateText(1209)}>{buttons}</Toolbar>
    ) : (
        <ModuleHeaderSlot title={translateText(1209)}>{buttons}</ModuleHeaderSlot>
    )
}
