import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetNotificationMessagesResponse } from "./model/getNotificationMessagesResponse"
import { getBundleParams } from "../utils"
import { NotificationMessage } from "./model/notificationMessage"

export async function getNotificationMessages() {
    const authorization = getStoredAuthorization()
    const bundleParams = getBundleParams()
    if (!bundleParams || !bundleParams.dataManagementServiceUrl) {
        console.log("Failed to determine url for dataManagementService from bundle parameters.")
        return []
    }

    const url = `${bundleParams.dataManagementServiceUrl}/GetNotifications`
    const body = {}
    const response = await ajax({ method: "GET", url, body, authorization })
    return mapGetNotificationMessageResponse(response)
}

function mapGetNotificationMessageResponse(response: GetNotificationMessagesResponse): NotificationMessage[] {
    return response.notifications
}
