import { useEffect } from "react"
import { Alert, Box, CellContentPosition, Table, TableCellData, TableColumnData } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Availability, AvailabilityStatus, ShipmentMode, Warehouse } from "@tm/models"
import { selectedOrderOptionState } from "@tm/utils"
import { useRecoilState } from "recoil"
import AvailabilityWrapperComponent from "../availability-wrapper"
import { ExpectedDelivery } from "./ExpectedDelivery"
import { OrderAcceptance } from "./OrderAcceptance"
import ShipmentSelectionTable from "./ShipmentSelectionTable"

export type AdvancedDeliveryRequestTableData = {
    article: {
        requestedQuantity?: number
        shortDescription?: string
        availability?: Availability
        warehouses?: Array<Warehouse>
        confirmedQuantity?: number
        additionalInformation?: string
        message?: string
        tourOrderAcceptanceTimeLimit?: Date
        expectedDelivery?: Date
    }
    shipments?: ShipmentMode[]
    refreshErp?: boolean
}

export type Props = {
    tableData: AdvancedDeliveryRequestTableData
    hideShipmentOptions?: boolean
}

export function AdvancedDeliveryRequestTable({ tableData, hideShipmentOptions }: Props) {
    const { translateText } = useLocalization()
    const [selectedOrderOption, setSelectedOrderoption] = useRecoilState(selectedOrderOptionState)
    const hideShipmentTable = hideShipmentOptions || !tableData.shipments?.length

    useEffect(() => {
        if (tableData.shipments) {
            const selected = tableData.shipments.find((shipment) => shipment.isSelected === true)
            setSelectedOrderoption({ orderOption: selected, refreshErp: false })
        }
    }, [tableData.shipments, setSelectedOrderoption])

    const handleShippmentsSelect = (orderOption: ShipmentMode | undefined) => {
        setSelectedOrderoption({ orderOption, refreshErp: tableData.refreshErp })
    }

    const columns: TableColumnData[] = [
        { header: translateText(667) },
        { header: translateText(1079) },
        { header: translateText(746) },
        { header: translateText(13281) },
    ]
    if (tableData.article.expectedDelivery) {
        columns.push({ header: translateText(433) })
    }
    if (tableData.article.tourOrderAcceptanceTimeLimit) {
        columns.push({ header: translateText(1129) })
    }
    columns.push({ header: translateText(321) })

    const mapSpecialProcurementToWareHouseCurrentItem = (confirmedQuantity?: number) => {
        const warehouse: Warehouse = {
            description: "",
            id: "LZ",
            isManufacturerWarehouse: true,
            name: "",
            shortDescription: "",
            showWarehouseInfo: false,
            isDefault: false,
        }

        if (confirmedQuantity && confirmedQuantity > 0) {
            warehouse.quantities = [
                {
                    availability: { type: AvailabilityStatus.ManufacturerAvailable },
                    requestedValue: 0,
                    confirmedValue: confirmedQuantity,
                    value: confirmedQuantity,
                },
            ]
        }
        return warehouse
    }

    const cellData = () => {
        const cells: TableCellData[] = [
            { displayValue: tableData.article?.requestedQuantity, id: "1" },
            { displayValue: tableData.article?.shortDescription, id: "2" },
            {
                displayValue: (
                    <AvailabilityWrapperComponent
                        availability={tableData.article?.availability}
                        requestedQuantity={tableData.article?.requestedQuantity}
                        warehouseTableCurrentItem={mapSpecialProcurementToWareHouseCurrentItem(tableData.article?.confirmedQuantity)}
                        warehouses={tableData.article?.warehouses}
                        selectedShipmentMode={selectedOrderOption?.orderOption}
                    />
                ),
                id: "3",
                alignContent: CellContentPosition.center,
            },
            { displayValue: tableData.article?.confirmedQuantity || 0, id: "4" },
        ]

        if (tableData.article.expectedDelivery) {
            cells.push({
                displayValue: <ExpectedDelivery expectedDelivery={tableData.article?.expectedDelivery} />,
                id: "5",
                alignContent: CellContentPosition.center,
            })
        }
        if (tableData.article.tourOrderAcceptanceTimeLimit) {
            cells.push({
                displayValue: <OrderAcceptance orderAcceptance={tableData.article?.tourOrderAcceptanceTimeLimit} />,
                id: "6",
                alignContent: CellContentPosition.center,
            })
        }

        cells.push({ displayValue: tableData.article?.additionalInformation, id: "7" })
        return cells
    }

    const data = [
        {
            cells: cellData(),
            id: `${1}`,
            active: false,
        },
    ]

    return (
        <Box pt={2}>
            {tableData.article.message && (
                <Alert severity="warning" variant="filled" title={tableData.article.message} sx={{ marginBottom: "16px" }} />
            )}
            <Table columns={columns} rows={data} overflowY="auto" />
            {!hideShipmentTable && (
                <ShipmentSelectionTable
                    handleShippmentsSelect={handleShippmentsSelect}
                    selectedOrderOption={selectedOrderOption}
                    shipments={tableData.shipments}
                />
            )}
        </Box>
    )
}
