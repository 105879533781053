import { Box, BoxProps, styled } from "@tm/components"
import { ReactNode } from "react"

type WorkCardProps = BoxProps & {
    children: ReactNode
}

export default function WorkCard({ children }: WorkCardProps) {
    return <Styledbox>{children}</Styledbox>
}

const Styledbox = styled(Box)({
    background: "#fff",
    marginBottom: "1em",
    width: "100%",
    border: "1px solid transparent",
    borderRadius: "3px",
    padding: "0.7em 1em",
    transition: "all .2s ease",
    position: "relative",
    boxShadow: "0 1px 5px rgba(0, 0, 0, .2)",
    "&:hover": {
        borderColor: "rgb(130, 196, 248)",
        boxShadow: "0 1px 5px rgba(0, 0, 0, .2)",
    },
})
