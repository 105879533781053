import { Dropdown, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ModelSeriesBase, VehicleType } from "@tm/models"
import { Suspense } from "react"

import { useModelSeriesByManufacturer } from "../../../../data/hooks"
import { renderDateRange } from "../../../../helpers/construction-year"
import { useSelections } from "../../business/hooks"

type Props = {
    vehicleType: VehicleType
}

export default function ModelSeriesSelection({ vehicleType }: Props) {
    const { selectedManufacturer, selectedMainModelSeries } = useSelections(vehicleType)

    return (
        <div className="row">
            <div className="number">{vehicleType === VehicleType.PassengerCar ? 3 : 2}</div>
            {!!selectedManufacturer && (vehicleType !== VehicleType.PassengerCar || !!selectedMainModelSeries) ? (
                <Suspense fallback={<DisabledDropdown />}>
                    <Content vehicleType={vehicleType} manufacturerId={selectedManufacturer.id} mainModelSeriesId={selectedMainModelSeries?.id} />
                </Suspense>
            ) : (
                <DisabledDropdown />
            )}
        </div>
    )
}

function DisabledDropdown() {
    const { translateText } = useLocalization()
    const view = () => <div>{translateText(296)}</div>
    return <Dropdown items={[]} itemView={view} coverView={view} disabled />
}

function Content({ vehicleType, manufacturerId, mainModelSeriesId }: Props & { manufacturerId: number; mainModelSeriesId?: number }) {
    const { translateText, date } = useLocalization()
    const { modelSeries } = useModelSeriesByManufacturer({ vehicleType, manufacturerId, mainModelSeriesId, selectedFilters: {} }) ?? {}
    const { selectedModelSeries, setModelSeries } = useSelections(vehicleType)

    const renderModelSeriesItem = (data?: ModelSeriesBase) => {
        if (!data || !data.description) {
            return <div>{translateText(296)}</div>
        }

        return (
            <div className="item">
                <div className="thumb">
                    <Image url={data.modelSeriesThumbnail} />
                </div>
                <div className="description">{data.description}</div>
                <div className="date-range">{renderDateRange(data, date)}</div>
            </div>
        )
    }

    return (
        <Dropdown
            items={modelSeries || []}
            itemView={renderModelSeriesItem}
            coverView={() => <div>{translateText(296)}</div>}
            disabled={!modelSeries}
            onChange={setModelSeries}
            value={selectedModelSeries}
            amountItemsToShow={10}
            getSearchValue={(item) => item.description}
        />
    )
}
