import { useState, useMemo, useEffect } from "react"
import { MenuItemWidget, Select, SelectProps } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useCountryCodeToLicensePlate, useWorkTask } from "@tm/context-distribution"
import { plateCountryCodeMapper } from "@tm/utils"
import { useRecoilState } from "recoil"
import { useParams } from "react-router"
import { useCustomerVehicles } from "../../data/hooks/useCustomerVehicles"
import { LoaderSmall } from "./StyledComponents"
import VehicleSelectorView from "./VehicleSelectorView"
import { voucherListFilterAtom } from "../../data/states/listFilters"
import { VoucherRouteParams } from "../../business"

export type VehicleSelectItem = {
    vehicleId?: string
    vehicleDescription: string
    vehiclePlate?: string
    shortCountryCode: string
}

type Props = {
    active: boolean
    disabled?: boolean
    size?: "small" | "medium"
    fullWidth?: boolean
    autoWidth?: boolean
    variant?: "filled" | "outlined"
    color?: SelectProps["color"]
}

export default function VehicleSelector({ active, disabled, size, color, fullWidth, autoWidth, variant }: Props) {
    const { workTask } = useWorkTask() ?? {}
    const params = useParams<VoucherRouteParams>()
    const { translateText } = useLocalization()
    const { customerVehicles, customerVehiclesLoading } = useCustomerVehicles(workTask?.customer?.id, active)
    const { plateCode: defaultCountryCode } = useCountryCodeToLicensePlate()
    const [listFilters, setListFilter] = useRecoilState(voucherListFilterAtom(params.workTaskId))

    const textId = workTask && workTask.customer ? 1271 : 1272

    const [selectedItem, setSelectedItem] = useState<VehicleSelectItem>()
    const items: Array<VehicleSelectItem> = useMemo(() => {
        return customerVehicles?.length
            ? [
                  { vehicleId: undefined, vehicleDescription: translateText(textId), shortCountryCode: defaultCountryCode },
                  ...customerVehicles.map((vehicle) => {
                      const shortCountryCode = plateCountryCodeMapper(vehicle.countryCode || defaultCountryCode)
                      return {
                          vehicleId: vehicle.id,
                          vehiclePlate: vehicle.plateId,
                          vehicleDescription: `${vehicle.manufacturer} ${vehicle.modelSeries} ${vehicle.model}`,
                          shortCountryCode,
                      }
                  }),
              ]
            : []
    }, [customerVehicles, textId, defaultCountryCode])

    useEffect(() => {
        if (items.length) {
            setSelectedItem(items.find((x) => x.vehicleId === listFilters.selectedVehicleId) || items[0])
        }
    }, [items, listFilters.selectedVehicleId])

    useEffect(() => {
        if (!listFilters.selectedVehicleId && !!workTask?.vehicle?.id) {
            setListFilter((prev) => ({
                ...prev,
                selectedVehicleId: workTask?.vehicle?.id,
            }))
        }
    }, [workTask?.vehicle?.id, listFilters.selectedVehicleId])

    if (customerVehiclesLoading) {
        return <LoaderSmall />
    }

    if (!items?.length || !selectedItem) {
        return null
    }

    function handleChangeVehicle(item: VehicleSelectItem) {
        setSelectedItem(item)
        setListFilter((prev) => ({
            ...prev,
            selectedVehicleId: item.vehicleId,
        }))
    }

    return (
        <Select
            value={selectedItem}
            onChange={(e) => handleChangeVehicle(e.target.value as VehicleSelectItem)}
            disabled={disabled}
            renderValue={(val) => {
                return <VehicleSelectorView vehicleSelectItem={val as VehicleSelectItem} />
            }}
            size={size}
            fullWidth={fullWidth}
            autoWidth={autoWidth}
            variant={variant}
            color={color}
        >
            {items.map((item, index) => {
                return (
                    <MenuItemWidget key={item.vehicleId || index.toString()} value={item}>
                        <VehicleSelectorView vehicleSelectItem={item} />
                    </MenuItemWidget>
                )
            })}
        </Select>
    )
}
