import { SupplierArticle } from "@tm/models"
import { ajax, getStoredAuthorization, uniqueId } from "@tm/utils"
import { getBundleParams } from "../../utils"

function getServiceUrl(): string {
    return getBundleParams().partsServiceUrl
}

export type SupplierArticleWithId = SupplierArticle & {
    id: string
}

type GetMultiWholesalerArticleNosByTraderIdRequest = {
    traderReferenceIds: Array<number>
    articles: Array<SupplierArticleWithId>
}

type GetMultiWholesalerArticleNosByTraderIdResponse = {
    wholesalerArticleNosDict: { [itemGuid: string]: { [tradeReferenceId: string]: string } }
}

type BufferedRequest = {
    article: SupplierArticle
    tradeReferenceId: number
    resolve: (item: string | undefined) => void
    reject: (error?: unknown) => void
}

const queue: Array<BufferedRequest> = []
let bufferTimeout: number

export function getMultiWholesalerArticleNosByTraderId(article: SupplierArticle, tradeReferenceId: number) {
    return new Promise<string | undefined>((resolve, reject) => {
        queue.push({ article, tradeReferenceId, resolve, reject })

        clearTimeout(bufferTimeout)
        bufferTimeout = window.setTimeout(requestService, 25)
    })
}

function requestService() {
    const traderReferenceIds: Array<number> = []
    const articles: Array<SupplierArticleWithId> = []

    const requestItems = queue
        .splice(0, queue.length) // splice to remove request items from queue...
        .map((x) => {
            if (!traderReferenceIds.includes(x.tradeReferenceId)) {
                traderReferenceIds.push(x.tradeReferenceId)
            }

            let articleId
            const match = articles.find((a) => a.supplierId === x.article.supplierId && a.supplierArticleNo === x.article.supplierArticleNo)
            if (match) {
                articleId = match.id
            } else {
                articleId = uniqueId()
                articles.push({ ...x.article, id: articleId })
            }

            return { ...x, articleId }
        })

    loadMultiWholesalerArticleNosByTraderId(articles, traderReferenceIds).then(
        (response) => {
            requestItems.forEach((requestItem) => {
                const responseItem = response?.wholesalerArticleNosDict?.[requestItem.articleId]?.[requestItem.tradeReferenceId]
                requestItem.resolve(responseItem)
            })
        },
        () => {
            requestItems.forEach((item) => {
                item.resolve(undefined)
            })
        }
    )
}

export async function loadMultiWholesalerArticleNosByTraderId(articles: SupplierArticleWithId[], traderReferenceIds: number[]) {
    const url = `${getServiceUrl()}/GetMultiWholesalerArticleNosByTraderId`
    const authorization = getStoredAuthorization()
    const body: GetMultiWholesalerArticleNosByTraderIdRequest = { traderReferenceIds, articles }

    try {
        const response = await ajax<GetMultiWholesalerArticleNosByTraderIdResponse>({ url, body, authorization, method: "POST" })
        if (!response) {
            return Promise.reject()
        }
        return response
    } catch {
        return Promise.reject()
    }
}
