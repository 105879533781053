import { ConstructionYear } from "@tm/models"

export function constructionYearToString(data?: ConstructionYear) {
    if (!data) {
        return ""
    }
    return `${data.month}/${data.year}`
}

export function constructionYearToDate(data: ConstructionYear): Date | undefined {
    const { year, month } = data

    if (Number.isNaN(year) || Number.isNaN(month)) {
        return
    }

    return new Date(year, month - 1)
}

export function renderDateRange(
    item: { constructionYearFrom?: ConstructionYear; constructionYearTo?: ConstructionYear },
    formatDate: (date: Date, format: string, utc?: boolean) => string
) {
    let content = "-"

    if (item.constructionYearFrom) {
        const constructionYearFrom = new Date(item.constructionYearFrom.year, item.constructionYearFrom.month - 1) // javascript's Date months start with 0
        content = `${formatDate(constructionYearFrom, "y")} - `
    }

    if (item.constructionYearTo) {
        const constructionYearTo = new Date(item.constructionYearTo.year, item.constructionYearTo.month - 1) // javascript's Date months start with 0
        content = content || " - "
        content += formatDate(constructionYearTo, "y")
    }

    return content
}
