import { AsyncAction } from "@tm/morpheus"
import { WorkTaskDetailsReadModel } from "@tm/models"
import { WorkTaskInfoState } from "./model"
import * as Data from "../../../data"

export type ComponentActionType =
    | { type: "WORKTASK_INFO_LOADING"; payload: string }
    | { type: "WORKTASK_INFO_LOADED"; payload?: WorkTaskDetailsReadModel }

const DEFAULT_STATE: WorkTaskInfoState = {
    loading: false,
    workTasks: {},
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): WorkTaskInfoState {
    switch (action.type) {
        case "WORKTASK_INFO_LOADING": {
            return {
                ...state,
                loading: true,
            }
        }
        case "WORKTASK_INFO_LOADED": {
            const workTasks = { ...state.workTasks }
            if (action.payload) {
                workTasks[action.payload.workTaskId] = action.payload
            }
            return {
                ...state,
                loading: false,
                workTasks,
            }
        }
    }
    return state
}

function loadWorkTaskInfo(worktaskId: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "WORKTASK_INFO_LOADING", payload: worktaskId })

        Data.showWorktaskDetailed(worktaskId).then((worktaskInfo) => dispatch({ type: "WORKTASK_INFO_LOADED", payload: worktaskInfo }))
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadWorkTaskInfo,
}
