import { CategoryType } from "@tm/models"
import { clone } from "@tm/utils"

import { BundleActionType } from "../../../business"
import { getTreeIdFromCategoryType } from "../../../helper"
import { ComponentActionType } from "./actions"
import { WidgetState } from "./model"

export * from "./model"
export type { IActions } from "./actions"
export { Actions } from "./actions"

const DEFAULT_STATE: WidgetState = {
    activeCategory: "directSearch",
    activeCategoryManuallySelected: false,
    trees: {},
    queryHistory: [],
}

export function reduce(state = clone(DEFAULT_STATE) as WidgetState, action: ComponentActionType): WidgetState {
    switch (action.type) {
        case "SET_TAB_INFO": {
            if (!state.trees[state.activeCategory]) {
                return state
            }
            return {
                ...state,
                trees: {
                    ...state.trees,
                    [state.activeCategory]: {
                        ...state.trees[state.activeCategory],
                        breadcrumbs: [],
                    },
                },
            }
        }
        case "INITIALIZE_TREES": {
            const result = { ...state }
            const { vehicle } = action.payload

            Object.keys(action.payload.trees).forEach((key) => {
                if (key === "predictive") {
                    return
                }

                const tree = action.payload.trees[key]

                const treeId = getTreeIdFromCategoryType(key as CategoryType, vehicle?.vehicleType)

                if (treeId === undefined) {
                    console.debug(`😱 Parts widget: ${key} tree is not available because the tree id is not configured as a bundle parameter.`)
                    return
                }

                result.trees[key] = {
                    treeId,
                    loading: false,
                    groups: [],
                    breadcrumbs: [],
                    showTip: tree.showTip === undefined ? true : tree.showTip,
                }
            })

            return result
        }
        case "SET_ACTIVE_CATEGORY": {
            return {
                ...state,
                activeCategory: action.payload.category,
                activeCategoryManuallySelected: action.payload.manuallySelected,
            }
        }
        case "SEARCH_TREE_LOADING": {
            return {
                ...state,
                trees: {
                    ...state.trees,
                    [action.payload]: {
                        ...state.trees[action.payload],
                        loading: true,
                    },
                },
            }
        }
        case "SEARCH_TREE_LOADED": {
            const { category, nodes, topProductGroups, parentNode } = action.payload

            const activeTree = state.trees[category]
            activeTree.loading = false

            if (parentNode) {
                // This also updates the groups array, since its the same object reference
                if (!parentNode.childNodes) {
                    parentNode.childNodes = nodes
                }
                if (!parentNode.topProductGroups) {
                    parentNode.topProductGroups = topProductGroups
                }
            }
            // Only do this for the first level (no parentNode)
            else {
                // Nodes from the first level are groups
                activeTree.groups = nodes.map((x) => ({
                    ...x,
                    isGroup: true,
                }))
            }

            return {
                ...state,
                trees: {
                    ...state.trees,
                    [category]: activeTree,
                },
            }
        }
        case "CHANGE_BREADCRUMBS": {
            const activeTree = state.trees[state.activeCategory]

            let breadcrumbs = action.payload
            let lastBreadcrumb = breadcrumbs.last()

            if (lastBreadcrumb) {
                // If the last breadcrumb is no group and has no child nodes
                // it should not appear as breadcrumb
                if (!lastBreadcrumb.isGroup && !lastBreadcrumb.hasChildNodes) {
                    breadcrumbs = breadcrumbs.slice(0, breadcrumbs.length - 1)
                } else {
                    // Only keep last breadcrumb if it really is the last one from the tree
                    lastBreadcrumb = undefined
                }
            }

            activeTree.breadcrumbs = breadcrumbs
            activeTree.selectedNode = lastBreadcrumb

            return {
                ...state,
                trees: {
                    ...state.trees,
                    [state.activeCategory]: activeTree,
                },
            }
        }
        case "QUERY_HISTORY_LOADED": {
            return {
                ...state,
                queryHistory: action.payload,
            }
        }
        default:
            return state
    }
}

export function transmit(action: ComponentActionType): BundleActionType | undefined {
    switch (action.type) {
        case "SET_TAB_INFO":
        case "SET_SEARCHTREE_DATA":
            return action
        default:
    }
}
