import { IBundle } from "@tm/morpheus"
import Widget from "./components/widget"
import StartpageButton from "./components/startpage-button"
import { WidgetFrameBundle, WidgetFrame } from "./components/widget-frame"
import Frame from "./components/frame"
import { initOffersBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [Widget, StartpageButton, WidgetFrameBundle, Frame],
    init: initOffersBundle,
}

export default bundle

export {
    //
    WidgetFrameBundle,
    WidgetFrame,
}
