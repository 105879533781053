import { Article, ECounterType, ErpSystemConfig } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { useCallback } from "react"
import { useHandleClickDetails } from "./useHandleClickDetails"
import { ErpInfosData } from "../../models"

export function useHandleClickAvailability(article: Article, articleQuantity: number, erpInfosData: ErpInfosData) {
    const handleClickDetails = useHandleClickDetails(article, articleQuantity)

    return useCallback(
        (erpSystem?: ErpSystemConfig, useSpecialProcurement?: boolean) => {
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListArticleDetailsViaAvailability)

            if (!!erpInfosData.erpConfig?.useOrderByDistributor && erpSystem?.id && !erpSystem?.isDefaultGetErpInfoSystem) {
                handleClickDetails("partnersystems")
            } else if (useSpecialProcurement) {
                handleClickDetails("special-procurement", "#load-teccom")
            } else {
                handleClickDetails("stocks")
            }
        },
        [articleQuantity]
    )
}
