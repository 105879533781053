import { Box, Icon } from "@tm/components"
import { ExternalCatalogComponentProps } from "../component"

type ItemCoverComponentProps = Pick<ExternalCatalogComponentProps, "hideCover"> & {
    title: string
    logo?: string
    icon?: string
}

export default function ItemCoverComponent(props: ItemCoverComponentProps) {
    const { logo, title, hideCover, icon } = props

    if (icon) {
        return (
            <Box display="flex" flexDirection="column" height="100%" width="100%" justifyContent="center" alignItems="center">
                <Icon name={icon} height="38px" width="38px" sx={{ marginBottom: "6px" }} />
                {title}
            </Box>
        )
    }
    if (logo && !hideCover) {
        return <Box sx={{ backgroundImage: `url(${logo})` }} className="item__logo" />
    }
    if (logo && hideCover) {
        return (
            <>
                <img src={logo} />
                <Box sx={{ padding: "8px", flex: "1", alignSelf: "center", fontSize: "16px", overFlow: "hidden", textOverflow: "ellipsis" }}>
                    {title}
                </Box>
            </>
        )
    }

    return <Box sx={{ padding: "8px", flex: "1", alignSelf: "center", fontSize: "16px", overFlow: "hidden", textOverflow: "ellipsis" }}>{title}</Box>
}
