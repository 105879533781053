import { memo, useMemo } from "react"
import { connectComponent } from "@tm/morpheus"
import { Headline, Icon, Loader, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Button, styled, Box, List, ListItem, ListItemText, Paper, IconButton, listItemTextClasses } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { DetailsReferencesState, Actions } from "./business"
import { useIndustrialInformation } from "../../data/hooks/useIndustrialInformation"
import { IndustrialInformationRequest } from "../../data/model"
import { getTypeTextId } from "./business/helper"

type Props = {
    state: DetailsReferencesState
}

const StyledBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
}))

const StyledPaper = styled(Paper)(({ theme }) => ({
    margin: theme.margin?.xl,
    padding: theme.margin?.xl,
}))

const StyledListItemText = styled(ListItemText)(({ theme }) => ({
    marginRight: theme.margin?.xl,
    [`& .${listItemTextClasses.secondary}`]: {
        whiteSpace: "pre-line",
    },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    marginRight: theme.margin?.m,
}))

const DetailsIndustrialInfo = memo(({ state }: Props) => {
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const request: IndustrialInformationRequest | undefined = state.detailsResponse?.article && {
        supplierArticleNo: state.detailsResponse.article.supplierArticleNo,
        supplierId: state.detailsResponse.article.supplier.id,
    }
    const { data, isIdle, isLoading } = useIndustrialInformation(request)

    const groupedData = useMemo(() => {
        const grouped = data?.groupBy((info) => info.type)
        return grouped && Object.entries(grouped)
    }, [data])

    if (isIdle || isLoading) {
        return (
            <StyledBox>
                <Loader />
            </StyledBox>
        )
    }

    if (!groupedData?.length) {
        return (
            <StyledBox>
                <Text>{translateText(286)}</Text>
            </StyledBox>
        )
    }

    return (
        <Box width="100%">
            {groupedData.map(([type, infos]) => {
                const textId = getTypeTextId(type)
                return (
                    <StyledPaper key={type}>
                        <Headline>{textId ? translateText(textId) : type}</Headline>
                        <List>
                            {infos.map((info, index) => (
                                <ListItem key={`${info.url}_${info.title}`} divider={index + 1 < infos.length}>
                                    <StyledListItemText
                                        primary={info.title}
                                        secondary={info.text
                                            ?.trim()
                                            .replace(/&nbsp;/g, " ")
                                            .replace(/&lt;/g, "<")
                                            .replace(/&gt;/g, ">")}
                                    />
                                    <Box flex="0 0 auto">
                                        {info.documents?.map((doc) => (
                                            <StyledIconButton
                                                key={doc}
                                                // @ts-ignore Not sure why this is a problem. If I use IconButton directly it works, with styled it doesn't.
                                                href={doc}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <Icon name={doc.endsWith(".pdf") ? "file-pdf" : "download"} size="s" />
                                            </StyledIconButton>
                                        ))}
                                        {info.url && !userContext.parameter.hideDetailsButtonInDetailView ? (
                                            <Button href={info.url} target="_blank" rel="noopener noreferrer">
                                                {translateText(43)}
                                            </Button>
                                        ) : null}
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                    </StyledPaper>
                )
            })}
        </Box>
    )
})

export default connectComponent(Actions, DetailsIndustrialInfo)
