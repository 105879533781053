import { useState, useEffect } from "react"
import { useMicro } from "@tm/morpheus"
import { IMicros } from "@tm/models"
import { bundleChannel } from "../../business"

type Props = {
    customerDetailsRoute: string
}

export default function CustomerInfoComponent(props: Props) {
    const [customerId, setCustomerId] = useState<string>()
    const { renderMicro } = useMicro<IMicros>()

    useEffect(() => {
        return bundleChannel().subscribe(
            "DETAILS_VEHICLE_CUSTOMERID_CHANGED",
            (data) => {
                setCustomerId(data.customerId)
            },
            true
        )
    }, [])

    if (!customerId) {
        return null
    }

    return <>{renderMicro("crm", "customer-info", { customerDetailsRoute: props.customerDetailsRoute, customerId })}</>
}
