import { OeArticleQuantities, OeArticleQuantitiesRequest } from "@tm/models"
import { ajax, createBufferedRequestFunction, getStoredAuthorization, notUndefinedOrNull } from "@tm/utils"
import { getBasketServiceUrl } from "../../.."

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Order`
}

export const getOeArticleQuantities = createBufferedRequestFunction({
    callService: getShowQuantitiesByOeArticle,
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return (
            serviceResponse.find(
                (item) =>
                    item.article.oeArticleNumber === request.article.oeArticleNumber &&
                    item.article.productGroupId === request.article.productGroupId &&
                    item.article.vehicleManufacturerId === request.article.vehicleManufacturerId
            ) || {
                article: request.article,
                isInOrder: false,
            }
        )
    },
})

export function getShowQuantitiesByOeArticle(requests: Array<OeArticleQuantitiesRequest>) {
    const url = `${getServiceUrl()}/ShowQuantitiesByOeArticle`
    const authorization = getStoredAuthorization()
    const body = {
        workTaskId: requests[requests.length - 1].workTaskId,
        articles: requests.map((r) => r.article),
    }

    return ajax<{ oeArticleQuantities?: Array<OeArticleQuantities> }>({ url, body, authorization, method: "POST" }).then(
        (data) => data?.oeArticleQuantities?.filter(notUndefinedOrNull) || []
    )
}
