import { BundleTemplate, TemplateRenderer } from "@tm/morpheus"

interface IArticleGroupTemplate extends TemplateRenderer {
    renderDescription(showVehicleRecords: boolean): JSX.Element
    renderContent(): JSX.Element
    getQualifiedClassName(): string
}

export const ArticleGroupTemplate: BundleTemplate<IArticleGroupTemplate> = {
    name: "article-group",
    targetComponent: {
        bundle: "parts",
        component: "article-group",
    },
    render(r: IArticleGroupTemplate) {
        const className = r.getQualifiedClassName()
        return (
            <div className={className}>
                {r.renderDescription(true)}
                {r.renderContent()}
            </div>
        )
    },
}
