import { Box, Divider, HTMLBox, Typography } from "@tm/components"
import { ErpIcon, Warehouse } from "@tm/models"
import { style } from "typestyle"
import SpecialIcons from "./special-icons"
import WarehousesTable from "./warehouses-table"

type Props = {
    title?: string
    warehouses?: Array<Warehouse>
    availability?: string
    requestedQuantity: number
    hasReplacedArticles?: boolean
    partnerSystemSpecialIcons?: Array<ErpIcon>
}

const className = style({ cursor: "pointer" })

export default function WarehouseTableTooltip(props: Props) {
    const { availability, title, partnerSystemSpecialIcons, warehouses, requestedQuantity, hasReplacedArticles } = props
    return (
        <Box className="warehouse-table-tooltip">
            {title && (
                <Box>
                    <Typography variant="label">{title}</Typography>
                    <Divider light />
                </Box>
            )}
            <Box mt={1} display="flex" justifyContent="space-between">
                <HTMLBox innerHTML={availability} />
                {!!partnerSystemSpecialIcons?.length && <SpecialIcons icons={partnerSystemSpecialIcons} className={className} />}
            </Box>
            {warehouses && (
                <WarehousesTable warehouses={warehouses} requestedQuantity={requestedQuantity} hasReplacedArticles={hasReplacedArticles} isTooltip />
            )}
        </Box>
    )
}
