import { useCallback, useState } from "react"
import { withRouter, renderRoute, RouteComponentProps } from "@tm/utils"
import { Widget, Icon, Loader, Button, Radio } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { TelematicsProvider } from "@tm/models"
import { Tooltip } from "@tm/components"
import Slide1 from "./components/slide1"
import Slide2 from "./components/slide2"
import Slide3 from "./components/slide3"
import Slide4 from "./components/slide4"
import { ClearanceState } from "../../data"
import { useClearanceStatus } from "./business/useClearanceStatus"
import { useTelematicsDataSummary } from "./business/useTelematicsDataSummary"

type Props = RouteComponentProps & {
    detailsRoute: string
    hideDrivingStyleAnalysis?: boolean
    moduleName?: string
}

const provider = TelematicsProvider.BmwCarData
function WidgetComponent(props: Props) {
    const { translateText, isTranslationId, translate } = useLocalization()
    const [selectedSlideOption, setSelectedSlideOption] = useState<number>()
    const { workTask } = useWorkTask() || {}

    const { clearanceStatus } = useClearanceStatus(provider, workTask?.vehicle)
    const { summary, summaryLoading, loadSummary } = useTelematicsDataSummary(provider, workTask?.vehicle)

    let telematicsDisplayName = props.moduleName || "Telematics"
    if (isTranslationId(telematicsDisplayName)) {
        telematicsDisplayName = translateText(telematicsDisplayName)
    }

    const handleLoadSummaryButtonClick = () => {
        if (workTask?.vehicle?.vin) {
            loadSummary()
        }
    }

    const renderUpdateView = () => {
        const statusClassName = `update-view__status ${summaryLoading ? "is-active" : ""}`

        return (
            <div className="widget-telematics__content">
                <div className="widget-telematics__popup-header">
                    <Icon name="telematics" />
                    <div className="headline--s">{telematicsDisplayName}</div>
                </div>
                <div className="update-view">
                    <div className={statusClassName}>
                        <Icon name="car" />
                        <Loader visible={summaryLoading} type="connection" />
                        <Icon name="telematics" />
                    </div>
                    {summaryLoading ? (
                        <div className="update-view__info">{translate(977)}</div>
                    ) : (
                        <div className="update-view__info">{translate(978)}</div>
                    )}
                    <Button skin="primary" onClick={handleLoadSummaryButtonClick} disabled={summaryLoading}>
                        {translate(979)}
                    </Button>
                    <div className="update-view__footnote">
                        {translateText(489).replace("%0", "1 EUR")}
                        <Icon name="info" size="xs" />
                    </div>
                </div>
            </div>
        )
    }

    const renderSlidesView = () => {
        if (!summary) {
            return
        }

        const { mileage, nextBrakeFluidChangeDate, batteryVoltage, legalInspectionDate, nextServiceDate, nextServiceDistance } = summary
        const slides: Array<number> = []
        if (mileage || nextBrakeFluidChangeDate || batteryVoltage) {
            slides.push(1)
        }
        if (legalInspectionDate || nextServiceDate || nextServiceDistance) {
            slides.push(3)
        }
        if (props.hideDrivingStyleAnalysis !== true) {
            slides.push(4)
        }

        let currentSlide

        switch (selectedSlideOption || slides[0]) {
            case 1:
                currentSlide = <Slide1 summary={summary} />
                break
            case 2:
                currentSlide = <Slide2 summary={summary} />
                break
            case 3:
                currentSlide = <Slide3 summary={summary} />
                break
            case 4:
                currentSlide = <Slide4 />
                break
            default:
                break
        }

        return (
            <div className="widget-telematics__content">
                <div className="widget-telematics__options">
                    <Button linkTo={renderRoute(props.detailsRoute, props.match.params)}>{translate(458)}</Button>
                </div>
                <div className="widget-telematics__popup-header">
                    <Icon name="telematics" />
                    <h2 className="headline--s">{telematicsDisplayName}</h2>
                </div>
                <div className="slides-view">{currentSlide}</div>
                <ul className="widget-telematics__footer">
                    {slides.map((index) => (
                        <Radio
                            key={index}
                            value={index}
                            checked={selectedSlideOption ? selectedSlideOption === index : slides[0] === index}
                            onCheck={setSelectedSlideOption}
                        />
                    ))}
                </ul>
            </div>
        )
    }

    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const toggleTooltip = useCallback(() => setIsTooltipOpen((prev) => !prev), [])
    const closeTooltip = useCallback(() => setIsTooltipOpen(false), [])

    let className = "widget-telematics"
    if (!clearanceStatus || clearanceStatus.clearanceState !== ClearanceState.Granted) {
        className += " disabled"
        return <Widget size="1x1" className={className} highlight iconName="telematics" title={telematicsDisplayName} />
    }

    return (
        <Widget
            size="1x1"
            className={className}
            highlight
            cover={
                <Tooltip
                    open={isTooltipOpen}
                    title={summary ? renderSlidesView() : renderUpdateView()}
                    onClickAway={closeTooltip}
                    variant="light"
                    color="primary"
                    placement="top"
                >
                    <div className="widget__cover" onClick={toggleTooltip}>
                        <Icon className="widget__cover-icon" name="telematics" />
                        <div className="widget__cover-text">{telematicsDisplayName}</div>
                    </div>
                </Tooltip>
            }
        />
    )
}

function Wrapper(props: Props) {
    const { workTask } = useWorkTask() || {}

    if (!workTask?.vehicle || workTask.vehicle.tecDocManufacturerId !== 16) {
        return null
    }

    return <WidgetComponent {...props} />
}

export default withRouter(Wrapper)
