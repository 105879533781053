import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { channel, ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { useDefaultErpSystem } from "@tm/utils"
import { useEffect } from "react"
import { useQuery, useQueryClient } from "react-query"
import { getNextTours } from ".."

const DEFAULT_INTERVAL = 5 * 60 * 1000 // 5 minutes
const SAFETY_MARGIN = 20 * 1000 // 20 seconds

const KEY = "erp_useNextTours"

export function useNextTours() {
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { erpSystemConfig } = useDefaultErpSystem()

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, erpSystemConfig?.id],
        () => getNextTours({ telesalesCustomerNo, distributorId: erpSystemConfig?.id }),
        { staleTime: Infinity }
    )

    const queryClient = useQueryClient()
    useEffect(() => {
        if (!data?.length) {
            // Performance optimization. If we don't get tours with the first request, we will probably never get any.
            return
        }

        const firstTourTimeLimit = data?.[0]?.tourOrderAcceptanceTimeLimit
        if (firstTourTimeLimit) {
            const ms = firstTourTimeLimit.getTime() - new Date().getTime()

            if (ms > SAFETY_MARGIN) {
                const { nextToursTimeoutRandomSecondsMultiplier } = Morpheus.getParams<ConfigParams>()

                // value between 0 and 40 seconds
                const randomMs = Math.floor(Math.random() * (nextToursTimeoutRandomSecondsMultiplier ?? 40) * 1000)

                // so we dont ddos ourselves, if the time difference is negative or less then 20 seconds, we use the default interval again
                const timeout = setTimeout(() => {
                    queryClient.resetQueries([KEY, telesalesCustomerNo, erpSystemConfig?.id])
                    channel("GLOBAL").publish("ERP/CLEAR_CACHE", {})
                }, ms + SAFETY_MARGIN + randomMs) // in case the user's clock is a little bit wrong, we wait another 20 seconds before loading

                return () => clearTimeout(timeout)
            }
        }

        const interval = setInterval(() => {
            if (document.visibilityState === "visible") {
                queryClient.resetQueries([KEY, telesalesCustomerNo, erpSystemConfig?.id])
            }
        }, DEFAULT_INTERVAL)
        return () => clearInterval(interval)
    }, [data, telesalesCustomerNo, erpSystemConfig?.id, queryClient])

    return { tours: data, isLoading }
}
