import { z } from "zod"

export function getRepairShopSchema(translateText: (key: string | number) => string) {
    return z.object({
        companyName: z.string().min(1, translateText(1)),
        street: z.string().min(1, translateText(1)),
        zip: z.string().min(1, translateText(1)),
        city: z.string().min(1, translateText(1)),
        phone: z.string().min(1, translateText(1)),
        email: z.string().min(1, translateText(1)),
        firstName: z.string().min(1, translateText(1)),
        lastName: z.string().min(1, translateText(1)),
        title: z.number().gt(0, translateText(1)),
        privacyPolicyAccepted: z.literal<boolean>(true, { errorMap: () => ({ message: "Accept" }) }),
    })
}
