import { SearchButton, TextField, styled, FormGroup, filledInputClasses, alpha, colors, Box, Image, Stack, Icon, Loader } from "@tm/components"
import { Quota } from "@tm/models"
import { useState } from "react"
import { QuotaText } from "../QuotaText"
import { LicensPlateEuroCode } from "./LicensPlateEuroCode"

const InlineSearchButton = styled(SearchButton)(({ theme }) => ({
    borderRadius: `${theme.radius?.default || "2px"} !important`,
    borderTopLeftRadius: "0 !important",
    borderBottomLeftRadius: "0 !important",
}))

const LicensePlateSearchInput = styled(TextField)(({ theme }) => {
    const backgroundColor = theme.overwrites?.components?.textfield?.backgroundColor || colors.grey[100]
    return {
        minWidth: "500px",
        marginTop: "0",
        [`.${filledInputClasses.root}`]: {
            padding: 0,
            opacity: 1,
            color: "black",

            border: `1px solid ${theme.overwrites?.components?.textfield?.border?.color || "#e2e2e1"}`,
            borderRadius: theme.radius?.default || "3px",
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            backgroundColor,
            transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
            "&:before": {
                content: "none",
            },
            "&:after": {
                content: "none",
            },
            "&:hover": {
                backgroundColor,
            },
            [`&.${filledInputClasses.focused}`]: {
                backgroundColor,
                borderColor: theme.palette.primary.main,
                boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            },
        },
    }
})

type Props = {
    quota?: Quota
    licensePlateCode?: string
    onStartSearch(query: string): void
    loading?: boolean
}

export function LicensePlateSearchFieldV2(props: Props) {
    const { quota, licensePlateCode, onStartSearch, loading } = props
    const [query, setQuery] = useState("")

    const startSearch = () => {
        if (onStartSearch) {
            onStartSearch(query)
        }
    }

    const InputProps = {
        endAdornment: (
            <>
                {loading && <Loader size="extrasmall" />}
                {query.length > 0 && (
                    <Icon
                        sx={{ cursor: "pointer" }}
                        name="close"
                        onClick={() => {
                            setQuery("")
                        }}
                    />
                )}
            </>
        ),
    }

    return (
        <Stack>
            <FormGroup row sx={{ flexWrap: "noWrap" }}>
                <LicensPlateEuroCode licensePlateCode={licensePlateCode} />
                <LicensePlateSearchInput
                    fullWidth
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    InputProps={InputProps}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            startSearch()
                        }
                    }}
                />
                <InlineSearchButton onClick={startSearch} />
            </FormGroup>
            <QuotaText quota={quota} />
        </Stack>
    )
}
