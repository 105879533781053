import { BundleComponent } from "@tm/morpheus"
import { WidgetNavigationDropdown } from "./component"
import type { NavigationItemConfigProps } from "./components/navigationItem"

const WidgetNavigationDropdownBundle: BundleComponent<never, typeof WidgetNavigationDropdown> = {
    name: "widget-navigation-dropdown",
    component: WidgetNavigationDropdown,
}

export { WidgetNavigationDropdownBundle, WidgetNavigationDropdown, NavigationItemConfigProps }
