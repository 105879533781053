import { Container } from "@tm/nexus"
import { RegisteredModels, ViewState, ViewStateContainer } from "@tm/models"

let _instance: ViewStateContainer

function getInstance() {
    return _instance || (_instance = Container.getInstance(RegisteredModels.ViewState))
}

export function saveState(state: ViewState, merge: boolean) {
    getInstance().action("saveViewState")(state, merge)
}

export function loadState<T = any>(key: string): Promise<ViewState<T>> {
    return getInstance().action("loadViewState")(key)
}
