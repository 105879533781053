import { TruckModelSeries } from "@tm/models"
import { callTrucksService } from "."
import { ModelYearFilter } from "../../model/filters"

type SelectedModelSeriesFilters = {
    modelYear?: number
}

type ModelSeriesFilters = {
    modelYears: Array<ModelYearFilter>
}

export type ModelSeriesResponse = {
    modelSeries: Array<TruckModelSeries>
    filters: ModelSeriesFilters
}

type ShowModelSeriesByArticleRequest = {
    manufacturerId: number
    articleId: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByArticle(request: ShowModelSeriesByArticleRequest) {
    return callTrucksService<ShowModelSeriesByArticleRequest, ModelSeriesResponse>("ShowModelSeriesByArticle", request)
}

type ShowModelSeriesByEngineCodeRequest = {
    manufacturerId: number
    engineCode: string
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByEngineCode(request: ShowModelSeriesByEngineCodeRequest) {
    return callTrucksService<ShowModelSeriesByEngineCodeRequest, ModelSeriesResponse>("ShowModelSeriesByEngineCode", request)
}

type ShowModelSeriesByManufacturerRequest = {
    manufacturerId: number
    selectedFilters?: SelectedModelSeriesFilters
}

export function showModelSeriesByManufacturer(request: ShowModelSeriesByManufacturerRequest) {
    return callTrucksService<ShowModelSeriesByManufacturerRequest, ModelSeriesResponse>("ShowModelSeriesByManufacturer", request)
}
type ShowModelSeriesDetailsRequest = {
    manufacturerId: number
    modelSeriesId: number
    engineCode?: string
}

type ShowModelSeriesDetailsResponse = {
    modelSeriesDetails: TruckModelSeries
}

export function showModelSeriesDetails(request: ShowModelSeriesDetailsRequest) {
    return callTrucksService<ShowModelSeriesDetailsRequest, ShowModelSeriesDetailsResponse>("ShowModelSeriesDetails", request)
}
