import { mainActions, useSindriStore } from "../state"
import { Repositories } from ".."
import { SindriCredentials } from "../../models"

export async function getSindriBearerToken() {
    const { sindriToken, sindriCredentials, tokenExpirationTime } = useSindriStore.getState().main

    if (sindriToken && sindriCredentials && tokenExpirationTime) {
        if (new Date().getTime() > tokenExpirationTime) {
            const newToken = await sindriLogin(sindriCredentials)
            return newToken
        }

        return sindriToken
    }

    mainActions.setSindriToken("")
    mainActions.setShowLoginDialog(true)
    throw new Error("Unauthorized")
}

async function sindriLogin(sindriCredentials: SindriCredentials) {
    try {
        const data = await Repositories.SindriLogin(sindriCredentials.APIKey, sindriCredentials.APIKeyID)
        const token = `${data.token_type} ${data.access_token}`
        mainActions.setSindriToken(token)
        return token
    } catch (error) {
        mainActions.setSindriToken("")
        mainActions.setShowLoginDialog(true)
        throw new Error("Unauthorized")
    }
}
