import { Stack, Typography, Icon, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { formatDate } from "@tm/utils"
import { ReturnInfo } from "../../../../../../../../../data/model"

type Props = {
    returnInfo: ReturnInfo
    handleOpenReturnVoucherClick(): void
}
export default function ReturnInformationComponent({ returnInfo, handleOpenReturnVoucherClick }: Props) {
    const { translateText } = useLocalization()
    if (!returnInfo) {
        return null
    }

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <Icon name="check-filled" size="16" />
            <Typography>
                {translateText(13262)} {formatDate(returnInfo.returnDate, "d")}
            </Typography>
            <Typography>{translateText(699)}</Typography>
            <Typography>{returnInfo.returnDate.toLocaleTimeString()}</Typography>
            <Typography>
                {translateText(470)}: {returnInfo.returnNumber}
            </Typography>
            <Button
                onClick={handleOpenReturnVoucherClick}
                target="_blank"
                size="small"
                disabled={!returnInfo.returnVoucherId}
                title={translateText(13268)}
            >
                {translateText(2008)}
            </Button>
        </Stack>
    )
}
