import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"
import { mapShowPartsListByOeReferencesToRequestArticleList } from "../business"

export default function handleShowPartsByOeReferences(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (data.showPartsByOeReferences) {
        const request = mapShowPartsListByOeReferencesToRequestArticleList(data.showPartsByOeReferences)
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}
