import { Box, Button, Divider, LoadingContainer } from "@tm/components"
import { useLocalization } from "@tm/localization"
import Section from "../shared/Section"
import MaintenanceItem from "../shared/MaintenanceItem"
import { useSindriStore } from "../../data/state"
import { useGetJobDetails } from "../../data/hooks"

export default function Maintenance() {
    const { translateText } = useLocalization()
    const selectedJob = useSindriStore((state) => state.main.selectedJob)
    const { liveItems, isLoading } = useGetJobDetails(selectedJob)

    return (
        <Section titleTextID="13530" sx={{ minHeight: "210px" }}>
            <LoadingContainer isLoading={isLoading}>
                <Box display="flex" justifyContent="space-around" height="100%" width="100%" gap="1em">
                    <MaintenanceItem
                        icon="inspection-date"
                        header={`${liveItems?.checkServiceDueDate?.value ?? "-"} ${liveItems?.checkServiceDueDate?.units ?? ""}`}
                        body={translateText(574)}
                        unevenIconMargin
                    >
                        <Button>Fast Service</Button>
                    </MaintenanceItem>
                    <Divider flexItem orientation="vertical" />
                    <MaintenanceItem icon="inspection-date" header="-" body={translateText(896)} unevenIconMargin />
                    <Divider flexItem orientation="vertical" />
                    <MaintenanceItem
                        icon="inspection-distance"
                        header={`${liveItems?.distanceUntilService?.value ?? "-"} ${liveItems?.distanceUntilService?.units ?? ""}`}
                        body={translateText(13531)}
                        unevenIconMargin
                    >
                        <Button>Fast Calculator</Button>
                    </MaintenanceItem>
                    <Divider flexItem orientation="vertical" />
                    <MaintenanceItem
                        icon="inspection-date"
                        header={`${liveItems?.odometer?.value ?? "-"} ${liveItems?.odometer?.units ?? ""}`}
                        body={translateText(125)}
                        unevenIconMargin
                    />
                    <Divider flexItem orientation="vertical" />
                    <MaintenanceItem
                        icon="oil-can"
                        header={`${liveItems?.oilDistanceUntilService?.value ?? "-"} ${liveItems?.oilDistanceUntilService?.units ?? ""}`}
                        body={translateText(12495)}
                    />
                    <Divider flexItem orientation="vertical" />
                    <MaintenanceItem
                        icon="battery"
                        header={`${liveItems?.batteryVoltage?.value ?? "-"} ${liveItems?.batteryVoltage?.units ?? ""}`}
                        body={translateText(12464)}
                    >
                        <Button>{translateText(13532)}</Button>
                    </MaintenanceItem>
                </Box>
            </LoadingContainer>
        </Section>
    )
}
