import { VehicleType } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { concat, RouteComponentProps, withRouter } from "@tm/utils"
import { Box } from "@tm/components"

import { useMemo } from "react"
import { getValidatedVehicleIdentificationParams, getVehicleIdentificationUrl } from "../../helpers/routing"
import { SelectionsState } from "./business"
import CardMainModelSeries from "./components/CardMainModelSeries"
import CardManufacturer from "./components/CardManufacturer"
import CardModel from "./components/CardModel"
import CardModelSeries from "./components/CardModelSeries"
import CardVehicleLookup from "./components/CardVehicleLookup"
import VehicleTypeSwitch from "./components/vehicle-type-switch"
import { getBundleParams } from "../../utils"

type RouteParams = {
    vehicleType: string
    manufacturerId?: string
    mainModelSeriesId?: string
    modelSeriesId?: string
    modelId?: string
}

type Props = RouteComponentProps<RouteParams> & {
    state: SelectionsState
    articleReferencesMode?: boolean
}

function SelectionsComponent(props: Props) {
    const { vehicleType, manufacturerId, mainModelSeriesId, modelSeriesId, modelId } = getValidatedVehicleIdentificationParams(props.match.params)

    const showMainModelSeriesCard = useMemo(() => {
        if (getBundleParams().hideMainModelSeries) {
            return false
        }
        return vehicleType === VehicleType.PassengerCar && !props.articleReferencesMode
    }, [vehicleType, props.articleReferencesMode])

    if (!vehicleType) {
        return null
    }

    const manufacturerUrl = concat(
        "",
        getVehicleIdentificationUrl(
            props.match.params,
            { vehicleType, manufacturerId: undefined, mainModelSeriesId: undefined, modelSeriesId: undefined, modelId: undefined },
            props.match.path
        ),
        props.location.search
    )
    const mainModelSeriesUrl = concat(
        "",
        getVehicleIdentificationUrl(
            props.match.params,
            { vehicleType, mainModelSeriesId: undefined, modelSeriesId: undefined, modelId: undefined },
            props.match.path
        ),
        props.location.search
    )
    const modelSeriesUrl = concat(
        "",
        getVehicleIdentificationUrl(props.match.params, { vehicleType, modelSeriesId: undefined, modelId: undefined }, props.match.path),
        props.location.search
    )
    const modelUrl = concat(
        "",
        getVehicleIdentificationUrl(props.match.params, { vehicleType, modelId: undefined }, props.match.path),
        props.location.search
    )

    return (
        <Box m={2} className="tk-vehicle">
            <ul className="tk-vehicle selections">
                {!!props.articleReferencesMode && <VehicleTypeSwitch state={props.state} />}
                <CardVehicleLookup vehicleType={vehicleType} />
                <CardManufacturer
                    vehicleType={vehicleType}
                    manufacturerId={manufacturerId}
                    backUrl={manufacturerUrl}
                    skipModuleInfoPublishing={props.articleReferencesMode}
                />
                {showMainModelSeriesCard && (
                    <CardMainModelSeries
                        vehicleType={vehicleType}
                        manufacturerId={manufacturerId}
                        mainModelSeriesId={mainModelSeriesId}
                        backUrl={mainModelSeriesUrl}
                        isCurrent={!!manufacturerId}
                    />
                )}
                <CardModelSeries
                    vehicleType={vehicleType}
                    manufacturerId={manufacturerId}
                    modelSeriesId={modelSeriesId}
                    backUrl={modelSeriesUrl}
                    isCurrent={showMainModelSeriesCard ? !!mainModelSeriesId : !!manufacturerId}
                />
                <CardModel vehicleType={vehicleType} modelId={modelId} backUrl={modelUrl} isCurrent={!!modelSeriesId} />
            </ul>
        </Box>
    )
}

export default connectComponent({}, withRouter(SelectionsComponent))
