import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetRepairTimesDivision(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { userSettings, workTask } = this.props

    if (source && data.getRepairTimesDivision && userSettings?.repairTimeOptions && workTask) {
        if (userSettings.repairTimeOptions.useManufacturerRepairTimeDivision && workTask.vehicle) {
            const subscription = this.RepairTimesDivision.subscribe({
                repairTimeProvider: data.getRepairTimesDivision.repairTimeProvider,
                manufacturerId: workTask.vehicle.tecDocManufacturerId,
            })
            subscription.load().then((response) => source.postMessage({ repairTimesDivision: response.repairTimeDivision }, "*"))
        } else {
            source.postMessage({ repairTimesDivision: userSettings.repairTimeOptions.division }, "*")
        }
    }
}
