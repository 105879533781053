import { Box, Icon, Tooltip, styled } from "@tm/components"
import { FC } from "react"
import { useLocalization } from "@tm/localization"

export const PartsIndicator: FC = () => {
    const { translateText } = useLocalization()

    return (
        <StyledBox>
            <Tooltip title={translateText(13592)}>
                <Icon name="partsindicator" />
            </Tooltip>
        </StyledBox>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    border: `2px solid ${theme.colors?.highlight}`,
    borderRadius: theme.radius?.default,
    padding: "0",
    marginLeft: "1em",
    display: "flex",
    cursor: "pointer",
    "& svg": {
        backgroundColor: theme.colors?.highlight,
    },
}))
