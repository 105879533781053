import { UserSettings } from "@tm/context-distribution"

export function getContactPersonName(userSettings?: UserSettings) {
    const contactPerson = userSettings?.repairShop?.contactPerson

    let contactPersonName
    if (contactPerson && (contactPerson.firstName || contactPerson.lastName)) {
        contactPersonName = contactPerson.firstName || ""
        if (contactPersonName && contactPerson.lastName) {
            contactPersonName += " "
        }
        contactPersonName += contactPerson.lastName || ""
    }

    return contactPersonName
}
