import { getStoredAuthorization, ajax } from "@tm/utils"
import { getCisServiceUrl } from "../.."
import { ExistsPdfResponse, PdfRequest } from "../../model"

function getServiceUrl() {
    return `${getCisServiceUrl()}/OrderConfirmations`
}

export function pdfExists(request: PdfRequest) {
    const url = `${getServiceUrl()}/ExistsPdf`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<ExistsPdfResponse>({ url, body, authorization })
}

export function loadPdf(request: PdfRequest) {
    const url = `${getServiceUrl()}/ShowPdf`
    const authorization = getStoredAuthorization()
    const body = request

    return ajax<Array<number>>({ url, body, authorization, responseType: "blob" })
}
