import { Box, styled } from "@tm/components"
import { FileList } from "../fileList/FileList"
import { Header } from "../shared"

const StyledWrapper = styled(Box)({
    display: "flex",
    flexDirection: "column",
    paddingLeft: "1em",
    width: "100%",
    height: "100%",
})

export function Main() {
    return (
        <StyledWrapper>
            <Box height="100%" padding="0.5em" display="flex" flexDirection="column" gap="10px">
                <Header />
                <FileList />
            </Box>
        </StyledWrapper>
    )
}
