import { OverviewState } from "./model"
import { BundleActionTypes, BundleActions } from "../../../business"
import { Article } from "@tm/models"
import { mapOverviewArticle } from "../../../data/helpers"

export * from "./model"

export type ComponentActionType = BundleActionTypes
    | { type: "UPDATE_RIMS_ITEMS", payload: Article | undefined }
    | { type: "UPDATE_SENSOR_ITEMS", payload: Article | undefined }
    | { type: "UPDATE_TIRES_ITEMS", payload: Article | undefined }
    | { type: "SET_MOUNTING_ITEM", payload: Article | undefined}
    | { type: "SET_OVERLAY_LOADING", payload: boolean}

export const OVERVIEW_DEFAULT_STATE: OverviewState = {
    mountingEnabled: true,
    loading: false
}

export function reduce(state = OVERVIEW_DEFAULT_STATE, action: ComponentActionType): OverviewState {
    switch (action.type) {
        case "SEND_RIM_ARTICLE_TO_OVERVIEW": {
            const { configuratorImage } = action.payload
            return {
                ...state,
                rimItem: mapOverviewArticle(action.payload.rimItem),
                configuratorImage
            }
        }
        case "SEND_SENSOR_TO_OVERVIEW": {
            return {
                ...state,
                sensorItem: mapOverviewArticle(action.payload)
            }
        }
        case "SEND_TIRE_TO_OVERVIEW": {
            return {
                ...state,
                tireItem: mapOverviewArticle(action.payload)
            }
        }
        case "UPDATE_RIMS_ITEMS": {
            return {
                ...state,
                rimItem: action.payload
            }
        }
        case "UPDATE_SENSOR_ITEMS": {
            return {
                ...state,
                sensorItem: action.payload
            }
        }
        case "UPDATE_TIRES_ITEMS": {
            return {
                ...state,
                tireItem: action.payload
            }
        }
        case "SET_MOUNTING_ITEM": {
            return {
                ...state,
                mountingItem: action.payload
            }
        }
        case "MOUNTING_SWITCH_SET": {
            return { 
                ...state,
                mountingEnabled: action.payload
            }
        }
        case "SET_OVERLAY_LOADING": {
            return {
                ...state,
                loading: action.payload
            }
        }
    }
    return state

}

function updateRimsItems(item?: Article): ComponentActionType {
    return {
        type: "UPDATE_RIMS_ITEMS", payload: item
    }
}

function updateTiresItems(item?: Article): ComponentActionType {
    return {
        type: "UPDATE_TIRES_ITEMS", payload: item
    }
}

function updateSensorItems(item?: Article): ComponentActionType {
    return {
        type: "UPDATE_SENSOR_ITEMS", payload: item
    }
}

function setMountingItem(item?: Article): ComponentActionType {
    return {
        type: "SET_MOUNTING_ITEM", payload: item,
    }
}

function setOverviewLoading(loading: boolean): ComponentActionType {
    return {
        type: "SET_OVERLAY_LOADING", payload: loading,
    }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    updateRimsItems,
    updateSensorItems,
    updateTiresItems,
    setMountingItem,
    setOverviewLoading
}