/* eslint-disable no-console */
import { SindriBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"

let bundleParams: SindriBundleParams

export const version = {
    name: "sindri",
    version: "2.0.0",
}

function checkBundleParams(params: SindriBundleParams) {
    if (!isDevtoolEnabled()) {
        return
    }

    if (!params?.sindriAuthUrl) {
        console.warn("sindriAuthUrl must be set in order to use the module")
    }
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle ${version.name}`)
    }

    return bundleParams
}

export function initSindriBundle(params: SindriBundleParams) {
    bundleParams = params
    checkBundleParams(params)
}
