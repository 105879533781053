import { RealTable, Image, Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { BikeModel, CarModel, TruckModel, VehicleType } from "@tm/models"
import { VehicleImage } from "@tm/components"
import { getBundleParams } from "../../../utils"
import { renderDateRange } from "../../../helpers/construction-year"
import { getComponentStyles } from "./styles"

const { Cell, Column } = RealTable

type Props<T> = {
    models: Array<T>
    vehicleType: VehicleType.PassengerCar

    showManufacturerThumbnail: boolean
    showModelThumbnail: boolean

    onApply(model: T): void
}

const classNames = getComponentStyles()

export default function ModelListSimple<T extends CarModel | BikeModel | TruckModel>(props: Props<T>) {
    const { translateText, date } = useLocalization()
    const { models, vehicleType, showManufacturerThumbnail, showModelThumbnail, onApply } = props

    if (!models.length) {
        return null
    }

    const renderModelCell = (item: T) => {
        const manufacturerThumbnail =
            showManufacturerThumbnail && !getBundleParams().hideManufacturerLogos && item.manufacturerThumbnail?.replace(/https?:/, "")

        return (
            <Cell>
                <strong>{item.fullDescription || item.description}</strong>
                {(!!manufacturerThumbnail || showModelThumbnail) && (
                    <div className={classNames.thumbnails}>
                        {!!manufacturerThumbnail && <Image url={manufacturerThumbnail} type="manufacturer" />}
                        {showModelThumbnail && (
                            <VehicleImage modelImage={item.thumbnail} modelSeriesImage={item.modelSeriesThumbnail} vehicleType={vehicleType} />
                        )}
                    </div>
                )}
            </Cell>
        )
    }
    const renderEngineCodeCell = (item: T) => <Cell>{item.engineCodes?.length ? item.engineCodes.join(", ") : "-"}</Cell>
    const renderConstructionYearCell = (item: T) => <Cell>{renderDateRange(item, date)}</Cell>
    const renderPowerCell = (item: CarModel | BikeModel) => (
        <Cell>
            {`${item.enginePowerKw ? `${item.enginePowerKw} ${translateText(329)}` : "-"} / ${
                item.enginePowerHp ? `${item.enginePowerHp} ${translateText(330)}` : "-"
            }`}
        </Cell>
    )
    const renderCapacityCell = (item: CarModel | BikeModel) => (
        <Cell>{item.engineCapacityCcm ? `${item.engineCapacityCcm} ${translateText(331)}` : "-"}</Cell>
    )
    const renderCylinderCountCell = (item: CarModel | BikeModel) => <Cell>{item.cylinderCount || "-"}</Cell>
    const renderFuelTypeCell = (item: CarModel | BikeModel) => <Cell>{item.fuelType || "-"}</Cell>
    const renderActionsCell = (item: T) => (
        <Cell>
            <Button size="s" title={translateText(22)} onClick={() => onApply(item)}>
                {translateText(22)}
            </Button>
        </Cell>
    )

    const columns = [
        <Column renderItemContent={renderModelCell}>{translateText(74)}</Column>,
        <Column renderItemContent={renderEngineCodeCell}>{translateText(105)}</Column>,
        <Column renderItemContent={renderConstructionYearCell}>{translateText(127)}</Column>,
        <Column renderItemContent={renderPowerCell}>{translateText(94)}</Column>,
        <Column renderItemContent={renderCapacityCell}>{translateText(128)}</Column>,
        <Column renderItemContent={renderCylinderCountCell}>{translateText(305)}</Column>,
        <Column renderItemContent={renderFuelTypeCell}>{translateText(299)}</Column>,
        <Column renderItemContent={renderActionsCell} className={classNames.actionsColumn} />,
    ]

    return <RealTable data={models} columns={columns} onDoubleClickRow={onApply} />
}
