import { ArticleItem, styled, Stack, Typography } from "@tm/components"

import { useLocalization } from "@tm/localization"
import { OE, SearchFilters, getCurrentWorkTaskId } from "@tm/models"
import { useCallback } from "react"
import { useHistory } from "react-router"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import { getBundleParams } from "../../../../utils"
import { ArticleGroupHeader } from "../../../list/components/ArticleGroupHeader/ArticleGroupHeader"
import { OeArticleItemStateProvider } from "./OeArticleItemStateProvider"
import { ReplacementChain } from "../ReplacementChain"
import { usePartsRoutes } from "../../hooks/usePartsRoutes"
import { OeReplacementData, OensArticleData } from "../../models"

const OeWrapper = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.highlight.light,
}))

type ComponentsProps = {
    oeArticles: OE.OePart[]
    oeReplacement: OeReplacementData
}

function OeArticleComponent({ oeArticles, oeReplacement }: ComponentsProps) {
    const { translateText } = useLocalization()
    const routes = usePartsRoutes()
    const history = useHistory()

    const onSelectReplacement = useCallback(
        (oeNumber: string) => {
            if (!oeNumber) {
                return
            }

            const baseUrl = renderRoute(routes.directSearch?.list || "", { workTaskId: encodeUniqueId(getCurrentWorkTaskId() ?? "") })
            const searchUrl = `${baseUrl}?query=${encodeURIComponent(oeNumber)}&searchFilter=${SearchFilters.OeReference}`

            history.push(searchUrl)
        },
        [history, routes.directSearch?.list]
    )

    return (
        <OeWrapper>
            <ArticleGroupHeader title={translateText(803)} />
            {oeArticles?.map((oe) => {
                return (
                    <Stack key={oe.id} width="100%">
                        <Stack direction="row" marginBottom="0.75em" paddingX="8px">
                            <Typography variant="h2" marginTop="8px">
                                {translateText(13168)}
                            </Typography>
                            <ReplacementChain
                                selectedOeNumber={oe.number}
                                oeNumbers={oeReplacement.replacementData?.find((r) => r.id === oe.manufacturerId)?.replacementNumbers}
                                onSelectReplacement={onSelectReplacement}
                            />
                        </Stack>

                        <OeArticleItemStateProvider article={oe}>
                            <ArticleItem variant="OE" />
                        </OeArticleItemStateProvider>
                    </Stack>
                )
            })}
        </OeWrapper>
    )
}

type OeArticleProps = {
    oensArticlesData: OensArticleData
    oeReplacement: OeReplacementData
    query?: string
}

export function OeArticle(props: OeArticleProps) {
    const { oensArticlesData, oeReplacement } = props
    if (!getBundleParams().enableOENS) {
        return null
    }

    const { isLoading, oeParts } = oensArticlesData

    if (isLoading || !oeParts?.length) {
        return null
    }

    return <OeArticleComponent oeArticles={oeParts} oeReplacement={oeReplacement} />
}
