import { useEffect, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Stack, Typography, TextField } from "@tm/components"
import { MemoLengthWarning } from "../../../../MemoLengthWarning"
import DeleteButton from "../../../../DeleteButton"

type Props = {
    partId: string
    note?: string
    itemCommentMaxLength?: number
    disableControls?: boolean
    onSaveNote(partId: string, note: string): void
    onRemoveNote(partId: string): void
}

export default function PartsNoteComponent({ partId, note, itemCommentMaxLength, disableControls, onRemoveNote, onSaveNote }: Props) {
    const { translateText } = useLocalization()
    const [memo, setMemo] = useState(note)

    useEffect(() => {
        setMemo(note)
    }, [note])

    const saveButtonRef = useRef<HTMLButtonElement>(null)

    function handleNoteChange(newNote: string) {
        setMemo(newNote)
    }

    function handleKeyUp(key: string) {
        if (!!memo && key === "Enter") {
            saveButtonRef.current?.focus()
        }
    }

    function handleSaveNote() {
        if (memo) {
            onSaveNote(partId, memo)
        }
    }

    const maxLength = itemCommentMaxLength ?? 200

    return (
        <Stack direction="row" spacing={1} flex={1} alignItems="center">
            <Typography variant="body2" fontWeight="bold">
                {translateText(39)}
            </Typography>
            <TextField
                clearButton
                inputCounter
                InputProps={{
                    endAdornment: <MemoLengthWarning currentLength={memo?.length || 0} maxLength={maxLength} />,
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                    maxLength,
                }}
                value={memo}
                fullWidth
                onChange={(e) => handleNoteChange(e.target.value)}
                onKeyUp={(e) => handleKeyUp(e.key)}
            />
            <Button disabled={!memo || memo === note || disableControls} onClick={handleSaveNote} ref={saveButtonRef}>
                {translateText(9)}
            </Button>
            <DeleteButton
                ids={[partId]}
                buttonTitleId={624}
                disableButton={!memo || disableControls}
                dialogTextId={838}
                onConfirmRemove={(ids) => ids && onRemoveNote(ids[0])}
            />
        </Stack>
    )
}
