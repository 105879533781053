import { ajax, createBufferedRequestFunction, getStoredAuthorization, notUndefinedOrNull } from "@tm/utils"
import {
    ArticlesDeleteRequest,
    ArticleUpdateRequest,
    Compilation,
    CompilationCreateRequest,
    CompilationsFindResponse,
    CompilationShowAmountsResponse,
    CompilationShowResponse,
    CompilationUpdateRequest,
    getCompilationsServiceUrl,
    CompilationsFindResponseEmbedded,
    ArticleMoveRequest,
    CompilationsContainsRequest,
    CompilationsContainsResponse,
    CompilationImportArticlesRequest,
} from ".."
import { mapArticleToAddRequest } from "../mapper/embedded"
import { CompilationArticleInterface } from "../interfaces/compilation-article-interface"

export function addArticleEmbedded(articles: CompilationArticleInterface): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticlesAdd`
    const body = {
        compilationId: articles.compilationId,
        articles: mapArticleToAddRequest(articles.articles),
    }

    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export function findCompilationsEmbedded(query?: string, pageIndex?: number, pageSize?: number): Promise<CompilationsFindResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationsFind`
    const authorization = getStoredAuthorization()
    const body = { query, pageIndex, pageSize }

    return ajax<CompilationsFindResponseEmbedded>({ url, body, authorization }).then(
        (response) =>
            response && {
                ...response,
                compilations: response.compilations.map((compilation): Compilation => {
                    return {
                        ...compilation,
                        isOwn: true,
                        isPublic: true,
                        createDate: compilation.createDate,
                        updateDate: compilation.updateDate,
                    }
                }),
            }
    )
}

export function createCompilationEmbedded(body: CompilationCreateRequest): Promise<string> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationCreate`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" }).then((response) => response.compilationId)
}

export async function showCompilationEmbedded(compilationId: string) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationShow`
    const authorization = getStoredAuthorization()
    const body = { compilationId }

    return ajax<CompilationShowResponse>({ url, body, authorization })
}

export async function updateArticleEmbedded(body: ArticleUpdateRequest) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticleUpdate`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export async function deleteArticlesEmbedded(body: ArticlesDeleteRequest) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticlesDelete`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export async function updateCompilationEmbedded(body: CompilationUpdateRequest) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationUpdate`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export async function deleteCompilationsEmbedded(compilationIds: string[]): Promise<any> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationsDelete`
    const authorization = getStoredAuthorization()
    const body = { compilationIds }

    return ajax({ url, body, authorization, method: "POST" })
}

export async function importArticlesEmbedded(body: CompilationImportArticlesRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/tbd`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export async function showAmountsEmbedded(compilationId?: string): Promise<CompilationShowAmountsResponse | undefined> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationShowAmounts`
    const authorization = getStoredAuthorization()
    const body = { compilationId }

    return ajax<CompilationShowAmountsResponse>({ url, body, authorization })
}

export async function moveArticleEmbedded(body: ArticleMoveRequest): Promise<void> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/ArticleMove`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}

export const getContainingArticlesBuffered = createBufferedRequestFunction<
    CompilationsContainsRequest[],
    CompilationsContainsResponse[],
    CompilationsContainsResponse[]
>({
    callService: (requests) => showContainingArticles(requests.reduce((prev, cur) => [...prev, ...cur], [])),
    mapServiceResponseToResponse: (serviceResponse, request) => {
        const includedInLists = serviceResponse.filter((compilationItem) =>
            request.some(
                (y) =>
                    compilationItem.supplierArticleNumber === y.supplierArticleNumber &&
                    compilationItem.supplierId === y.supplierId &&
                    compilationItem.productGroupId === y.productGroupId
            )
        )

        return includedInLists.length ? includedInLists : []
    },
})

export function showContainingArticles(articles: CompilationsContainsRequest[]): Promise<CompilationsContainsResponse[]> {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationsContain`
    const authorization = getStoredAuthorization()

    return ajax<{ compilationItemIds: CompilationsContainsResponse[] }>({
        url,
        body: { articleNumbers: articles },
        authorization,
        method: "POST",
    }).then((data) => data?.compilationItemIds?.filter(notUndefinedOrNull) || [])
}

export function showCompilationEmbeddedPdf(compilationId: string) {
    const url = `${getCompilationsServiceUrl()}/ArticleCompilations/embedded/CompilationShowPdf`
    const authorization = getStoredAuthorization()
    const body = { compilationId }

    return ajax<Array<number>>({ url, body, authorization, responseType: "blob" })
}
