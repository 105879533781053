import { IBundle } from "@tm/morpheus"
import VehicleRecords from "./components/vehicle-records"
import { initVehicleRecordsBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [VehicleRecords],
    init: initVehicleRecordsBundle,
}

export default bundle
