import { useQuery } from "react-query"
import { FastCalculator } from "@tm/data"
import { queueActions, useQueueStore } from "../state"
import { getPoolingInterval } from "../helpers"

export function useQueueStatus(onQueueFinished?: () => void) {
    const { isQueued, checkStatusUrl } = useQueueStore((state) => state.state)

    const { isLoading } = useQuery(
        ["FAST_CALCULATOR_CHECK_QUEUE_CALCULATIONS", checkStatusUrl, isQueued],
        () => (checkStatusUrl ? FastCalculator.checkQueuedCalculations(checkStatusUrl) : undefined),
        {
            enabled: !!checkStatusUrl && isQueued,
            refetchInterval: getPoolingInterval(),
            onSuccess: (data) => {
                if (data && data.done) {
                    queueActions.queueFinished()

                    onQueueFinished?.()
                }
            },
        }
    )

    return { isLoading, isQueued }
}
