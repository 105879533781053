import { ImportOrderVoucherItemsRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"

export function useImportVoucherItems(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { mutateAsync: importOrderVoucherItems } = useMutation(
        (importOrderVoucherItemsRequest: ImportOrderVoucherItemsRequest) => Data.importOrderVoucherItems(importOrderVoucherItemsRequest),
        {
            onSuccess: (response, request) => {
                if (response) {
                    handleBasketUpdateWorkflow(request.workTaskId, response)
                }
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
            },
        }
    )

    const importVoucherItems = useCallback(
        (importOrderVoucherItemsRequest: ImportOrderVoucherItemsRequest) => {
            return importOrderVoucherItems(importOrderVoucherItemsRequest)
        },
        [importOrderVoucherItems]
    )

    return importVoucherItems
}
