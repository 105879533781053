import { Article, ArticleIdentifier, WholesalerPart, OePart } from "@tm/models"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import { ExtendedOrderVoucherItem, ReturnVoucherItem } from "../model"

export function mapVoucherPartToArticleIdentifier(partItems: VoucherPart[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    partItems.forEach((partItem) => {
        const { articleNumber, supplierId, productGroupId } = partItem.articleInformation
        if (supplierId && articleNumber && productGroupId) {
            parts.push({
                supplierId,
                supplierArticleNo: articleNumber,
                productGroupId,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapOrderCatalogPartToArticleIdentifier(partItems: ExtendedOrderVoucherItem[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    partItems.forEach((partItem) => {
        if (partItem.articleInfo.dataSupplier && partItem.articleInfo.dataSupplierArticleNumber && partItem.articleInfo.productGroup?.id) {
            parts.push({
                supplierId: partItem.articleInfo.dataSupplier.id,
                supplierArticleNo: partItem.articleInfo.dataSupplierArticleNumber,
                productGroupId: partItem.articleInfo.productGroup.id,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapReturnVoucherItemToArticleIdentifier(partItems: ReturnVoucherItem[]): ArticleIdentifier[] {
    const parts: ArticleIdentifier[] = []
    partItems.forEach((partItem) => {
        if (partItem.dataSupplier && partItem.dataSupplierArticleNumber && partItem.productGroup?.id) {
            parts.push({
                supplierId: partItem.dataSupplier.id,
                supplierArticleNo: partItem.dataSupplierArticleNumber,
                productGroupId: partItem.productGroup.id,
                tecdocTypeNo: undefined,
            })
        }
    })
    return parts
}

export function mapVoucherPartToArticle(voucherPart: VoucherPart): Partial<Article> {
    return {
        id: voucherPart.partItemId,
        quantity: voucherPart.quantityValue,
        description: voucherPart.articleInformation.description,
        supplier: {
            id: voucherPart.articleInformation.supplierId ?? -1,
            name: voucherPart.articleInformation.supplierName ?? "",
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: voucherPart.articleInformation.articleNumber,
        traderArticleNo: voucherPart.articleInformation.wholesalerArticleNumber,
        productGroup: {
            id: voucherPart.articleInformation.productGroupId ?? -1,
            name: voucherPart.articleInformation.productGroupName ?? "",
            sortId: -1,
            thumbnail: "",
        },
        additionalDescription: voucherPart.articleInformation.additionalDescription,
        initialQuantity: voucherPart.quantityValue,
        showAddToBasket: true,
        requestErpInfo: !voucherPart.mainPartItemId,
    }
}

export function mapVoucherPartToWholesalerPart(voucherPart: VoucherPart): { part: Partial<WholesalerPart>; id: string } {
    const part: Partial<WholesalerPart> = {
        quantityValue: voucherPart.quantityValue,
        description: voucherPart.articleInformation.description,
        wholesalerArticleNumber: voucherPart.articleInformation.wholesalerArticleNumber,
        additionalDescription: voucherPart.articleInformation.additionalDescription,
        manufacturerName: voucherPart.articleInformation.supplierName,
        productGroupName: voucherPart.articleInformation.productGroupName,
    }
    return { part, id: voucherPart.partItemId }
}

export function mapVoucherPartToOePart(voucherPart: VoucherPart, currencyCode?: string): Partial<OePart> {
    return {
        oeArticleNumber: voucherPart.articleInformation.articleNumber,
        description: voucherPart.articleInformation.description,
        additionalDescription: voucherPart.articleInformation.additionalDescription,
        vehicleManufacturerId: voucherPart.articleInformation.supplierId,
        vehicleManufacturerName: voucherPart.articleInformation.supplierName,
        quantityValue: voucherPart.quantityValue,
        productGroupId: voucherPart.articleInformation.productGroupId,
        currencyCode,
        oePriceValue: voucherPart.calculation.offerPriceTotal,
        wholesalerArticleNumber: voucherPart.articleInformation.wholesalerArticleNumber,
    }
}

export function mapOrderedItemToArticle(orderedItem: ExtendedOrderVoucherItem): Partial<Article> {
    return {
        id: orderedItem.id,
        quantity: orderedItem.confirmedQuantity.value,
        description: orderedItem.articleInfo.description,
        supplier: {
            id: orderedItem.articleInfo.dataSupplier ? orderedItem.articleInfo.dataSupplier.id : -1,
            name: orderedItem.articleInfo.dataSupplier ? orderedItem.articleInfo.dataSupplier.name : "",
            abbreviation: "",
            isTopPriority: false,
        },
        supplierArticleNo: orderedItem.articleInfo.dataSupplierArticleNumber,
        traderArticleNo: orderedItem.articleInfo.wholesalerArticleNumber,
        productGroup: {
            id: orderedItem.articleInfo.productGroup ? orderedItem.articleInfo.productGroup.id : -1,
            name: orderedItem.articleInfo.productGroup ? orderedItem.articleInfo.productGroup.name : "",
            sortId: -1,
            thumbnail: "",
        },
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        thumbnail: orderedItem.articleInfo.thumbnailUrl,
        initialQuantity: orderedItem.confirmedQuantity.value,
        showAddToBasket: true,
        requestErpInfo: !orderedItem.isLinkedItem,
    }
}

export function mapOrderedItemToWholesalerPart(orderedItem: ExtendedOrderVoucherItem) {
    const part: Partial<WholesalerPart> = {
        quantityValue: orderedItem.confirmedQuantity.value,
        description: orderedItem.articleInfo.description,
        wholesalerArticleNumber: orderedItem.articleInfo.wholesalerArticleNumber,
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        manufacturerName: orderedItem.articleInfo.manufacturerName,
        productGroupName: orderedItem.articleInfo.productGroupName,
    }
    return { part, id: orderedItem.id }
}

export function mapOrderedItemToPartToOePart(orderedItem: ExtendedOrderVoucherItem): Partial<OePart> {
    return {
        description: orderedItem.articleInfo.description,
        additionalDescription: orderedItem.articleInfo.additionalDescription,
        oeArticleNumber: orderedItem.articleInfo.oeArticleNumber,
        vehicleManufacturerName: orderedItem.articleInfo.vehicleManufacturer?.name,
        vehicleManufacturerId: orderedItem.articleInfo.vehicleManufacturer?.id,
        quantityValue: orderedItem.confirmedQuantity.value,
        productGroupId: orderedItem.articleInfo.productGroupId,
        oePriceValue: orderedItem.articleInfo.oePrice?.amount,
        currencyCode: orderedItem.articleInfo.oePrice?.currencyCode,
        wholesalerArticleNumber: orderedItem.articleInfo.wholesalerArticleNumber,
    }
}
