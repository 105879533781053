import { Box, Typography } from "@tm/components"
import {
    Article,
    ArticleAttribute,
    AttributeFilterModel,
    ErpInformation,
    GetProductGroupTopicIdsResponse,
    ProductGroupFiltersModel,
    RepairTimeProvider,
    RequestArticleDetailsPayload,
    SearchFilters,
} from "@tm/models"
import { decodeUniqueId, getRepairTimeProviders } from "@tm/utils"
import * as React from "react"
import { IActions, ListState } from "../business"

import { ArticleGroupHeader } from "./ArticleGroupHeader/ArticleGroupHeader"
import { getSearchTypeDependentArticleTableProps, getTmaInfos } from "./utils"
import { RouteParams } from "../wrapper"
import { ArticleTable } from "./article-table"

export type CustomGroupHeaderInfo = {
    productGroupId?: number
    filterOptions?: ProductGroupFiltersModel
    productGroupTopicIds?: GetProductGroupTopicIdsResponse
}

type Props = {
    articles: Array<Article>
    state: ListState
    actions: IActions
    handleSelectProductGroupAttributeFilter: (option: AttributeFilterModel, previousProductGroupFilter?: AttributeFilterModel) => void
    handleDeselectProductGroupAttributeFilter: (productGroupFilter: AttributeFilterModel) => void
    handleDeselectAllProductGroupFilter: (productGroupFilters: Array<AttributeFilterModel>) => void
    calculatorRoute?: string
    handleArticleAttributeSelect: (article: Article, attribute: ArticleAttribute) => void
    handleRequestArticleDetails: (request: RequestArticleDetailsPayload) => void
    handleRequestArticleDirectSearch: (query: string, searchFilter?: SearchFilters, inModal?: boolean) => void
    articleAlternativesRoute?: string
    handleRequestArticleAlternatives: (article: Article) => void
    handleAddArticleToBasket: (article: Article) => void
    getRepairTimesUrl: (article: Article, rtProviders: RepairTimeProvider | Array<RepairTimeProvider>) => string | undefined
    showActions: boolean
    handleShowArticleFeedback: (article: Article) => void
    showAdditionalPrices?: boolean
    showErpPawnItems?: boolean
    showDocumentsInline?: boolean
    openDocumentsAsModal?: boolean
    showReferenceLinksInCompact?: boolean
    showDocumentsInCompact?: boolean
    matchParams: RouteParams
    isCentralOrder: () => boolean
    partToReplaceId?: string

    sectionHeadline?: string
    groupParts?: boolean
    customGroupHeaderInfo?: CustomGroupHeaderInfo
    isLoading?: boolean
    defaultErpData?: ErpInformation[]
}

export const ContentGroup: React.FC<Props> = (props) => {
    const {
        articles,
        state,
        handleSelectProductGroupAttributeFilter,
        handleDeselectProductGroupAttributeFilter,
        handleDeselectAllProductGroupFilter,
        calculatorRoute,
        actions,
        handleArticleAttributeSelect,
        handleRequestArticleDetails,
        handleRequestArticleDirectSearch,
        articleAlternativesRoute,
        handleRequestArticleAlternatives,
        handleAddArticleToBasket,
        handleShowArticleFeedback,
        matchParams,
        isCentralOrder,
        customGroupHeaderInfo,
    } = props
    const { vehicleId, customerId, foundBySearchTerm } = getTmaInfos(state.usedFilters, state.searchType, state.customer)
    const { canFilterArticleAttributes, ignoreAttributeKey, selectedArticleAttributes, showMissingVehicleConnection, hasInlineDataSupplierFilter } =
        getSearchTypeDependentArticleTableProps(vehicleId, state.usedFilters, state.searchType)

    const groups: Array<{ id: number; name: string; index: number; articles: Array<Article> }> = []
    const { repairTimeProviders } = getRepairTimeProviders()

    const renderArticleTable = (tableArticles: Article[]) => {
        return (
            <Box mb="12px">
                <ArticleTable
                    {...props}
                    defaultErpData={props.defaultErpData}
                    articles={tableArticles}
                    searchType={state.searchType}
                    selectedArticleIds={state.result.selected}
                    selectedArticleAttributes={selectedArticleAttributes}
                    ignoreAttributeKey={ignoreAttributeKey}
                    onArticleSelect={actions.selectPart}
                    onArticleAttributeSelect={handleArticleAttributeSelect}
                    onRequestArticleDetails={handleRequestArticleDetails}
                    onRequestArticleDirectSearch={handleRequestArticleDirectSearch}
                    onRequestArticleAlternatives={articleAlternativesRoute ? handleRequestArticleAlternatives : undefined}
                    compactView={!!state.compactView}
                    showArticleImages={!!state.showArticleImages}
                    canFilterArticleAttributes={canFilterArticleAttributes}
                    productGroupAsDescription={false}
                    vehicleEngineCode={state.usedFilters.vehicle ? state.usedFilters.vehicle.engineCode : undefined}
                    workTaskId={matchParams.workTaskId && decodeUniqueId(matchParams.workTaskId)}
                    vehicleId={vehicleId}
                    customerId={customerId}
                    foundBySearchTerm={foundBySearchTerm}
                    showMissingVehicleConnection={showMissingVehicleConnection}
                    repairTimeAvailabilities={state.repairTimeAvailabilities}
                    onArticleAddToBasket={handleAddArticleToBasket}
                    hitInfos={state.result.hitInfo}
                    getArticleSearchHitInfo={actions.getArticleSearchHitInfo}
                    previouslyOrderedArticles={state.result.previouslyOrderedArticles}
                    vehicleRecordsComparisons={state.result.vehicleRecordsComparisons}
                    onShowArticleFeedback={handleShowArticleFeedback}
                    activeDataSupplierFilter={state.usedFilters.supplierIds}
                    onDataSupplierFilterToggle={hasInlineDataSupplierFilter ? actions.toggleDataSupplierFilter : undefined}
                    replaceButtonBundle={state.initialFilters?.replaceButtonBundle}
                    hideCostEstimationButton={!!state.fastCalculator || isCentralOrder() || !repairTimeProviders.length}
                    advertisementCategoryId={state.usedFilters.offers?.advertisementCategory.id}
                />
            </Box>
        )
    }

    if (!props.groupParts && (props.sectionHeadline === "" || !props.sectionHeadline)) {
        return renderArticleTable(articles)
    }

    articles.forEach((x) => {
        const previousGroup = groups.last()
        const existingGroup = groups.filter((y) => y.id === x.productGroup.id).last()

        if (previousGroup && previousGroup.id === x.productGroup.id) {
            previousGroup.articles.push(x)
            return
        }

        groups.push({
            id: x.productGroup.id,
            name: x.productGroup.name || "-",
            index: existingGroup ? existingGroup.index + 1 : 0,
            articles: [x],
        })
    })

    return (
        <Box>
            {props.sectionHeadline && (
                <Box className="SearchResultsText">
                    <Typography>{props.sectionHeadline}</Typography>
                </Box>
            )}
            {groups.map((group, idx) => {
                if (!group.articles.length) {
                    return
                }

                const id = `${group.id}-${group.index}`

                //   All articles of this group are loaded if
                //   there are no more articles (endOfList) or
                //   the group is not the last group (all articles of group have to be loaded)
                const groupsFullyLoaded = state.result.page.endOfList || idx !== groups.length - 1

                const showArticleCount = !state.usedFilters.availability && groupsFullyLoaded

                return (
                    <React.Fragment key={id}>
                        <ArticleGroupHeader
                            productGroupTopicIds={customGroupHeaderInfo?.productGroupTopicIds || state.result.productGroupTopicIds}
                            calculatorRoute={calculatorRoute}
                            productGroupId={customGroupHeaderInfo?.productGroupId || group.id}
                            title={group.name}
                            groupArticlesCount={showArticleCount ? group.articles.length : undefined}
                            filterAreaProps={{
                                filterOptions:
                                    customGroupHeaderInfo?.productGroupId || canFilterArticleAttributes ? state.vehicleRecords[group.id] : undefined,
                                usedAttributeFilters: state.usedFilters.articleAttributes,
                                onSelectProductGroupFilter: handleSelectProductGroupAttributeFilter,
                                onDeselectProductGroupFilter: handleDeselectProductGroupAttributeFilter,
                                onDeselectAllProductGroupFilter: handleDeselectAllProductGroupFilter,
                            }}
                        />
                        <Box mb="12px">
                            <ArticleTable
                                {...props}
                                defaultErpData={props.defaultErpData}
                                articles={group.articles}
                                searchType={state.searchType}
                                selectedArticleIds={state.result.selected}
                                selectedArticleAttributes={selectedArticleAttributes}
                                ignoreAttributeKey={ignoreAttributeKey}
                                onArticleSelect={actions.selectPart}
                                onArticleAttributeSelect={handleArticleAttributeSelect}
                                onRequestArticleDetails={handleRequestArticleDetails}
                                onRequestArticleDirectSearch={handleRequestArticleDirectSearch}
                                onRequestArticleAlternatives={articleAlternativesRoute ? handleRequestArticleAlternatives : undefined}
                                compactView={!!state.compactView}
                                showArticleImages={!!state.showArticleImages}
                                canFilterArticleAttributes={canFilterArticleAttributes}
                                productGroupAsDescription={false}
                                vehicleEngineCode={state.usedFilters.vehicle ? state.usedFilters.vehicle.engineCode : undefined}
                                workTaskId={matchParams.workTaskId && decodeUniqueId(matchParams.workTaskId)}
                                vehicleId={vehicleId}
                                customerId={customerId}
                                foundBySearchTerm={foundBySearchTerm}
                                showMissingVehicleConnection={showMissingVehicleConnection}
                                repairTimeAvailabilities={state.repairTimeAvailabilities}
                                onArticleAddToBasket={handleAddArticleToBasket}
                                hitInfos={state.result.hitInfo}
                                getArticleSearchHitInfo={actions.getArticleSearchHitInfo}
                                previouslyOrderedArticles={state.result.previouslyOrderedArticles}
                                vehicleRecordsComparisons={state.result.vehicleRecordsComparisons}
                                onShowArticleFeedback={handleShowArticleFeedback}
                                activeDataSupplierFilter={state.usedFilters.supplierIds}
                                onDataSupplierFilterToggle={hasInlineDataSupplierFilter ? actions.toggleDataSupplierFilter : undefined}
                                replaceButtonBundle={state.initialFilters?.replaceButtonBundle}
                                hideCostEstimationButton={!!state.fastCalculator || isCentralOrder() || !repairTimeProviders.length}
                                advertisementCategoryId={state.usedFilters.offers?.advertisementCategory.id}
                            />
                        </Box>
                    </React.Fragment>
                )
            })}
        </Box>
    )
}
