import { useCallback } from "react"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { MenuItem } from "@tm/components"
import { SubLinkMenuItem } from "./SubLinkMenuItem"
import { LinkMenuItem as LinkMenuItemComponent } from "./LinkMenuItem"
import { SubMenuItem } from "./SubMenuItem"

export type MenuItem = LinkMenuItem | ExternalModuleMenuItem | ModuleMenuItem

export type BaseMenuItem = {
    label: string
}

type LinkMenuItem = BaseMenuItem & {
    path: string
}

type ExternalModuleMenuItem = BaseMenuItem & {
    externalModuleConfiguration?: {
        typeId: number
        documentTypeId?: number
    }
}

type ModuleMenuItem = LinkMenuItem & {
    moduleId: number
}

type Props = {
    item: MenuItem
    isSubMenuItem?: boolean
    onClick?(): void
}

export function isModuleMenuItem(item: Partial<MenuItem>): item is ModuleMenuItem {
    return "moduleId" in item && !!item?.moduleId
}

export function isLinkMenuItem(item: Partial<MenuItem>): item is LinkMenuItem {
    return "path" in item && !!item.path
}
export function isExternalModuleMenuItem(item: MenuItem): item is ExternalModuleMenuItem {
    return "externalModuleConfiguration" in item && !!item.externalModuleConfiguration
}
export function AdditionalMenuItemComponent({ item, isSubMenuItem, onClick }: Props) {
    const { translate } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const { userContext } = useUser()

    const getUrlFromExternalModule = useCallback(
        (typeId: number, documentTypeId?: number) => {
            return userContext.externalModules?.find(
                (x) => x.type === typeId && (documentTypeId === undefined || x.documentTypeId === documentTypeId)
            )?.url
        },
        [userContext]
    )

    const getUrlFromPath = useCallback(
        (path: string) => {
            const id = workTaskId || encodeUniqueId(uniqueId())
            return /^(?:https?:)\/\//.test(path) ? path : renderRoute(path, { workTaskId: id })
        },
        [workTaskId]
    )

    const getText = (text: string | number) => {
        if (!isNaN(Number(text)) || (text as string).includes("{")) {
            return translate(text)
        }
        return text
    }

    let url: string | undefined

    if (isModuleMenuItem(item)) {
        const hasMenuModule = userContext.modules?.find((module) => module.type === item.moduleId)
        if (!hasMenuModule) {
            return null
        }
        url = getUrlFromPath(item.path)
    } else if (isLinkMenuItem(item)) {
        url = getUrlFromPath(item.path)
    } else if (item.externalModuleConfiguration) {
        url = getUrlFromExternalModule(item.externalModuleConfiguration.typeId, item.externalModuleConfiguration.documentTypeId)
    }

    if (!url) {
        const Component = isSubMenuItem ? SubMenuItem : MenuItem

        return (
            <Component component="li" sx={{ pointerEvents: "none" }}>
                {getText(item.label)}
            </Component>
        )
    }

    const Component = isSubMenuItem ? SubLinkMenuItem : LinkMenuItemComponent
    return (
        <Component to={url} onClick={onClick}>
            {getText(item.label)}
        </Component>
    )
}
