import { Skeleton, Stack } from "@tm/components"

export function FiltersSkeleton() {
    return (
        <>
            <Stack spacing={1} mt={2} pr={2}>
                <Skeleton variant="rounded" height={22} />
                <Stack pl={3}>
                    <Skeleton variant="rounded" height={20} />
                </Stack>
            </Stack>
            <Stack spacing={1} mt={2} pr={2}>
                <Skeleton variant="rounded" height={22} />
                <Stack pl={3}>
                    <Skeleton variant="rounded" height={120} />
                </Stack>
            </Stack>
            <Stack spacing={1} mt={2} pr={2}>
                <Skeleton variant="rounded" height={22} />
                <Stack pl={3}>
                    <Skeleton variant="rounded" height={160} />
                </Stack>
            </Stack>
        </>
    )
}
