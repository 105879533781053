import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../data/helpers"
import component from "./component"

const DATRegistration: BundleComponent = {
    name: "dat-registration-frame",
    component: withStoreProvider(component as any),
}

export default DATRegistration
