import { getStyleTheme } from "@tm/context-distribution"
import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity } = getStyleTheme()
    const themeStyles: NestedCSSProperties = {
        color: colors.light,
        borderLeft: "1px solid",
        borderLeftColor: colors.light,
        borderRadius: 0,
        height: "100%",
        margin: 0,
        $nest: {
            "&:hover": {
                backgroundColor: "#E51636",
            },
            "&:hover:not(&--selected)": {
                backgroundColor: "transparent",
                borderLeftColor: "#FFF",
                color: colors.light,
                $nest: {
                    ".icon": {
                        fill: colors.light,
                    },
                },
            },
            ".plate": {
                opacity: opacity.primary,
            },
            "&:hover .plate, &:hover:not(&--selected) .plate": {
                opacity: "1",
            },
            "&__close": {
                marginLeft: "1.5em",
                marginRight: ".25em",
                $nest: {
                    ".icon": {
                        fill: colors.light,
                        height: "1.2em",
                        width: "1.2em",
                    },
                    "&:hover, &:active": {
                        background: "none",
                    },
                    "&:hover .icon": {
                        fill: `${colors.light} !important`,
                    },
                },
            },
            "&--selected": {
                transform: "none",
                backgroundColor: "#E51636",
                color: colors.light,
            },
            "&--selected, &:active": {
                borderLeft: `1px solid ${colors.light}`,
            },
            "&:not(&--selected)": {
                borderLeftColor: colors.light,
            },
            "&--selected .icon, &:active .icon": {
                fill: colors.light,
            },
            "&:last-child": {
                borderRightWidth: "1px",
                borderRightColor: colors.light,
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    mergedStyles.$nest && delete mergedStyles.$nest["&:first-of-type:not(&--selected)"]
    return mergedStyles
}

export function getPlateIdStyle(baseStyles: NestedCSSProperties) {
    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&__fx": {
                backgroundColor: "#4aa0e8",
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
