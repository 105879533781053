import { FindVehiclesRequest, RegisteredModels, VehicleContainer } from "@tm/models"
import { Container } from "@tm/nexus"
import { ajax, getStoredAuthorization } from "@tm/utils"

import { getBundleParams } from "../../utils"
import { CheckVehicleRequest, CheckVehicleResponse, MergeRegistrationNoDetailsRequest } from "../model/gsi"

function getGsiServiceUrl(): string {
    return getBundleParams().gsiServiceUrl
}

export function showGsiVehicle(id: string) {
    const container: VehicleContainer = Container.getInstance(RegisteredModels.Vehicle)
    return container.subscribe(id).load()
}

export function findGsiVehicles(body: FindVehiclesRequest) {
    const container: VehicleContainer = Container.getInstance(RegisteredModels.Vehicle)
    return container.action("findVehicles")(body)
}

export function checkGsiVehicle(body: CheckVehicleRequest) {
    return ajax<CheckVehicleResponse>({
        url: `${getGsiServiceUrl()}/Vehicles/CheckVehicle`,
        authorization: getStoredAuthorization(),
        body,
    })
}

export function mergeRegistrationNoDetails(body: MergeRegistrationNoDetailsRequest) {
    return ajax({
        method: "POST",
        url: `${getGsiServiceUrl()}/Vehicles/MergeRegistrationNoDetails`,
        authorization: getStoredAuthorization(),
        body,
    })
}
