import { Container } from "@tm/nexus"
import { AttributeFilterModel, FittingPosition, RegisteredModels, VehicleRecordsContainer } from "@tm/models"
import { useQuery } from "react-query"
import { useContext, useMemo } from "react"
import { ALL_AXLES, getModuleVehicleRecordMode } from "../../../../../helper"
import { RecordedFilterContext } from "../ArticleGroupFilters"

export function useSelectOptions(filter: AttributeFilterModel, modelId: number, startLoading: boolean) {
    const recordedFilters = useContext(RecordedFilterContext)

    const fittingSide = useMemo(() => {
        if (getModuleVehicleRecordMode()?.hideAxleSwitch) {
            if (filter.fittingSide) {
                return ALL_AXLES
            }
            return FittingPosition.None
        }
        return filter.fittingSide ?? FittingPosition.None
    }, [filter.fittingSide])

    const { isLoading, data } = useQuery(
        ["AttributeFilterSelectOptions", filter, modelId],
        () => loadSelectOptions(filter, modelId, fittingSide, recordedFilters),
        {
            enabled: startLoading,
        }
    )

    return { isOptionsLoading: isLoading, options: data }
}

async function loadSelectOptions(
    filter: AttributeFilterModel,
    modelId: number,
    fittingSide: number,
    recordedFilters: AttributeFilterModel[]
): Promise<Array<AttributeFilterModel>> {
    const { id, productGroupId } = filter

    const container = Container.getInstance(RegisteredModels.VehicleRecords) as VehicleRecordsContainer
    const loadFilterOptions = container.action("loadFilterOptions")
    const response = await loadFilterOptions({ attributeId: id, fittingSide, productGroupId, modelId })

    return response.filterOptions.map((f) => ({
        ...f,
        registrationType:
            f.registrationType ?? recordedFilters.find((rf) => rf.value === f.value && rf.fittingSide === f.fittingSide)?.registrationType,
    }))
}
