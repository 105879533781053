import { memo } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Icon, Loader } from "@tm/components"

type Props = {
    extendedDeliveryRequested?: boolean
    extendedDeliveryLoading: boolean
    loadExtendedDelivery: (() => void) | undefined
}

// Extended delivery also known as TecCom or Special Order
const ExtendedDelivery = memo((props: Props) => {
    const { loadExtendedDelivery, extendedDeliveryLoading, extendedDeliveryRequested } = props
    const { translateText } = useLocalization()

    return (
        <div className={`tk-erp erp-info__extended-delivery ${!loadExtendedDelivery ? "not-available" : ""}`}>
            {loadExtendedDelivery &&
                (extendedDeliveryLoading ? (
                    <Loader size="small" />
                ) : (
                    <Button
                        startIcon={<Icon name={extendedDeliveryRequested ? "extended-delivery-request-confirmed" : "extended-delivery-request"} />}
                        title={translateText(1080) + (extendedDeliveryRequested ? ` (${translateText(276)})` : "")}
                        onClick={loadExtendedDelivery}
                    />
                ))}
        </div>
    )
})

export default ExtendedDelivery
