import { ModelState, validateField } from "@tm/utils"
import { ReactText } from "react"
import { IVehicleInfoFields } from "../../components/summary/business/model"

export const validateVehicleFields = (fields: IVehicleInfoFields, translateText: (key: ReactText) => string): ModelState => {
    return {
        initialRegistration: validateField(fields.initialRegistration && fields.initialRegistration.toString()).min(8, translateText(289)).messages,
        vin: validateField(fields.vin).min(17, translateText(289)).messages,
        plateNumber: validateField(fields.plateNumber).required(translateText(37)).messages,
        mileage: validateField(fields.mileage).required(translateText(37)).messages,
        ...((!fields.vin || fields.vin.length < 17 || fields.vin.match(/(\d|[a-zA-Z])\1{9,}/)) && {
            vin: ((fields.vin?.length ?? 0) < 17 && [translateText(289).replace("#1#", "17")]) || [translateText(1881)],
        }),
    }
}
