import { css, StyleProps, withStyle } from '@tm/context-distribution'
import { Card, Loader } from '@tm/controls'
import { bindSpecialReactMethods, classes } from '@tm/utils'
import { em, percent } from 'csx'
import { Component } from 'react';
import { bem, connector } from '../../../../data/helpers'
import { VKNImage } from '../../../../data/models'
import { MainState } from '../../../main'
import { ErrorMessage, GraphicViewer } from '../../../_shared'
import { Actions, DetailsState, IActions } from '../../business'

type Props = StyleProps<typeof stylesheet> & StoreProps & {
    actions: IActions
}

type StoreProps = {
    vknImages: DetailsState["vknImages"]
    selectedCriterias: DetailsState["selectedCriterias"]
}

class VKNAlternativePicker extends Component<Props> {

    constructor(props: Props) {
        super(props)
        bindSpecialReactMethods(this)
    }

    handleVknImageSelect(item: VKNImage) {
        const { actions } = this.props

        actions.updateSelectedVKNImage(item)
        actions.loadImagePositions()
    }

    renderImage(item: VKNImage, idx: number) {
        const { style, selectedCriterias } = this.props
        const disabled = !!selectedCriterias.length && !selectedCriterias.find(x => x.code == item.criteria?.code)
        return (
            <Card className={style.card} onClick={item.isVisible && !disabled && this.handleVknImageSelect.bind(this, item) || undefined} key={idx}>
                <GraphicViewer
                    disabled={!item.isVisible || disabled}
                    className={style.graphicViewer}
                    svgImage={item.svg}
                    withoutHover
                    withoutTooltip
                />
            </Card>
        )
    }

    render() {
        const { vknImages: { items, loading }, style } = this.props
        const alternativesVkn = items.filter(x => x.isStartImage)

        if (loading)
            return (
                <div className={bem(style.list, "loading")}>
                    <Loader />
                </div >
            )

        if (!alternativesVkn.length && !loading) {
            return (
                <div className={classes(style.list)}>
                    <ErrorMessage title={1831} icon="no-results" />
                </div >
            )
        }

        return (
            <div className={classes(style.list)}>
                {alternativesVkn.map(this.renderImage)}
            </div >
        )
    }
}

function mapStateToProps({ details: state }: MainState): StoreProps {
    return {
        vknImages: state.vknImages,
        selectedCriterias: state.selectedCriterias
    }

}

function stylesheet() {
    return css({
        list: {
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
            $nest: {
                "&--loading": {
                    justifyContent: "center",
                    alignItems: "center"
                }
            }
        },
        card: {
            flexBasis: percent(50),
        },
        graphicViewer: {
            height: em(20),
        }
    })
}

export default connector(withStyle(stylesheet, VKNAlternativePicker), mapStateToProps, Actions)
