import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit, receive } from "./business"
import component from "./wrapper"

const list: BundleComponent<unknown, typeof component> = {
    name: "list",
    component,
    reduce,
    transmit,
    receive,
}

export default list
