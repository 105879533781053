import { useWorkTask } from "@tm/context-distribution"
import { useCallback } from "react"
import { OE } from "@tm/models"
import { mapArticleToAddOePartListRequest } from "@tm/utils"
import { useVehicle } from "../useVehicle"
import { useBasketParts } from "../../../../../../basket/src/hooks/basketState/useBasketParts"

export function useHandleAddOeToBasket(part: OE.OePart | undefined) {
    const { workTaskId, workTask } = useWorkTask() ?? {}
    const { customer } = workTask ?? {}
    const vehicle = useVehicle()

    const { addOePartList } = useBasketParts()

    const handleAddToBasket = useCallback(
        (quantity: number) => {
            if (!workTaskId || !part) {
                return Promise.reject()
            }

            const addToBasketArticle: OE.OePart[] = [
                {
                    ...part,
                    quantity,
                    ...(part.manufacturerId == null && { manufacturerId: workTask?.vehicle?.tecDocManufacturerId }),
                },
            ]
            const request = mapArticleToAddOePartListRequest(addToBasketArticle, workTaskId, vehicle?.id, customer?.id)
            return addOePartList(request)
        },
        [workTaskId, part, vehicle?.id, customer?.id, addOePartList]
    )
    return handleAddToBasket
}
