import { Container } from "@tm/nexus"
import { RegisteredModels } from "@tm/models"
import { showCostEstimationPdf } from "./repositories/vouchers/cost-estimation-voucher"
import { showSupplierArticleByVehicle, showAllOrders, showOrderPdf } from "./repositories/vouchers/order-voucher"

export * from "./repositories/vouchers/cost-estimation-voucher"
export * from "./repositories/vouchers/order-voucher"
export * from "./repositories/vouchers/return-voucher"

export function registerActions() {
    Container.register({
        name: RegisteredModels.Vouchers_ShowCostEstimationPdf,
        modelActions: {
            load: showCostEstimationPdf,
        },
    })
    Container.register({
        name: RegisteredModels.Vouchers_ShowSupplierArticleByVehicle,
        modelActions: {
            load: showSupplierArticleByVehicle,
        },
    })
    Container.register({
        name: RegisteredModels.Vouchers_ShowAllOrders,
        modelActions: {
            load: showAllOrders,
        },
    })
    Container.register({
        name: RegisteredModels.Vouchers_ShowOrderPdf,
        modelActions: {
            load: showOrderPdf,
        },
    })
}
