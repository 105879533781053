import { Button, Icon, Stack, Tooltip, Typography, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useMemo } from "react"
import { SectionStack } from "./OeInformation"

const OeReplacementBox = styled("div")({
    display: "flex",
    alignItems: "center",
    margin: "0 4px",
})

const StyledButton = styled(Button)({
    "&&": {
        borderColor: "#b4b4b4",
    },
})

type Props = {
    selectedOeNumber: string | undefined
    oeNumbers: string[] | undefined
    onSelectReplacement: (oeNumber: string) => void
}

export function ReplacementChain({ selectedOeNumber, oeNumbers, onSelectReplacement }: Props) {
    const { translateText } = useLocalization()

    const replacements = useMemo(() => oeNumbers?.filter((x) => x !== selectedOeNumber), [oeNumbers, selectedOeNumber])

    if (!replacements?.length) {
        return null
    }

    return (
        <SectionStack spacing="4px">
            <Typography textTransform="uppercase" variant="label">
                {translateText(3004)}
            </Typography>
            <Stack direction="row" flexWrap="wrap">
                {replacements.map((replacement, idx) => (
                    <OeReplacementBox key={replacement}>
                        <Icon name={idx === 0 ? "arrow-long-down-right" : "arrow-long-right"} sx={{ mr: "16px" }} color="#494949" />
                        <Tooltip title={translateText(1813).replace("{0}", translateText(758))}>
                            <StyledButton variant="outlined" onClick={() => onSelectReplacement(replacement)}>
                                <Typography variant="body2">{replacement}</Typography>
                            </StyledButton>
                        </Tooltip>
                    </OeReplacementBox>
                ))}
            </Stack>
        </SectionStack>
    )
}
