import { equals } from "@tm/utils"
import { VehicleType } from "@tm/models"
import { TyresCritsRequest } from "../../repositories/tyresCrits"
import { SelectedFilters, TyresWheelsState } from "./model"
import { mapVehicleTypeToTyresCarType } from "../../../helpers"

export const isSameTires = (state: TyresWheelsState, selectedFilters: SelectedFilters): boolean => {
    // compare front and rear and see if they are the same
    // if they are not then it means that the rear tires are wider than front
    const selectedItemIndex = state.tyresWheels.tiresData.frontTires.findIndex(
        (x) => x.size === selectedFilters.size && x.loadIndex === selectedFilters.loadIndex
    )
    return equals(state.tyresWheels.tiresData.frontTires[selectedItemIndex], state.tyresWheels.tiresData.rearTires[selectedItemIndex])
}

export const createTiresCritsRequest = (
    selectedFilters: SelectedFilters,
    actualFilters: SelectedFilters | undefined,
    vehicleType: VehicleType | undefined
) => {
    const carType = mapVehicleTypeToTyresCarType(vehicleType)

    const request: TyresCritsRequest = {
        carTypes: [carType],
        width: actualFilters?.width ?? selectedFilters?.width,
        height: actualFilters?.height ?? selectedFilters?.height,
        inch: actualFilters?.inch ?? selectedFilters?.inch,
        loadIndex: actualFilters?.loadIndex ?? selectedFilters?.loadIndex,
        speedIndex: actualFilters?.speedIndex ?? selectedFilters?.speedIndex,
        supplierCode: actualFilters?.manufacturerId ?? selectedFilters?.manufacturerId,
    }

    return request
}
