import { Box, makeStyles, styled } from "@tm/components"
import * as React from "react"

type Props = {
    icon: React.ReactNode
    expandIcon: React.ReactNode
    expanded: boolean
    fullHeight?: boolean
    keepContent?: boolean
    onClick?: (expanded: boolean) => void
}

const Accordion: React.FC<Props> = (props) => {
    const { children, icon, expandIcon, expanded, onClick, fullHeight, keepContent } = props

    const style = useStyle()

    const handleClick = () => {
        onClick?.(!expanded)
    }

    const result = React.Children.toArray(children)
    return (
        <StyledBox fullHeight={fullHeight}>
            <Box className={style.line} sx={{ display: "flex", gap: "1em", alignItems: "center" }} onClick={handleClick}>
                <Box sx={{ display: "flex" }}>{expanded ? expandIcon : icon}</Box>
                {result[0]}
            </Box>
            {keepContent ? (
                <>
                    <Box sx={{ padding: "8px 16px 16px", height: "100%", ...(!expanded && { display: "none" }) }}>{result[1]}</Box>
                </>
            ) : (
                <>{expanded && <Box sx={{ padding: "8px 16px 16px", height: "100%" }}>{result[1]}</Box>}</>
            )}
        </StyledBox>
    )
}

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "fullHeight",
})<{ fullHeight?: boolean }>(({ fullHeight }) => ({
    ...(fullHeight && {
        display: "flex",
        flexDirection: "column",
        height: "100%",
    }),
}))

const useStyle = makeStyles({
    line: {
        borderBottom: "1px solid",
        borderBottomColor: "#9a9a9a",
        paddingBottom: "0.5em",
    },
})

export default Accordion
