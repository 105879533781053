import { Grid } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CostEstimationOptions } from "@tm/models"
import { CostEstimationCalculation } from "../../../../data/model"
import { HighlightContrastTypography, StyledBox } from "../../../StyledComponents"
import SummaryDetails from "../SummaryDetails"
import Totals from "./Totals"
import { useSummaryTheme } from "../../hooks/useSummaryTheme"

type Props = {
    costEstimationCalculation?: CostEstimationCalculation
    costEstimationOptions?: CostEstimationOptions
    currencyCode: string
    showRepairTimesInHours: boolean
}

export default function CostEstimationTotalsTable({ costEstimationCalculation, currencyCode, showRepairTimesInHours, costEstimationOptions }: Props) {
    const { priceVatMode } = costEstimationOptions || {}
    const { translateText } = useLocalization()
    const { styledBoxBackgroundColor, highlightColor, styledBoxPadding } = useSummaryTheme()

    const { totalsB2B, totalsB2C } = costEstimationCalculation || {}
    const partPositionsInTotal = totalsB2B?.partPositionsInTotal ?? totalsB2C?.partPositionsInTotal ?? 0
    const partCosts = totalsB2B?.partCosts ?? totalsB2C?.partCosts ?? 0
    const repairTimeTotal = totalsB2B?.repairTimeTotal ?? totalsB2C?.repairTimeTotal
    const workCosts = totalsB2B?.workCosts ?? totalsB2C?.workCosts ?? 0

    return (
        <>
            <StyledBox backgroundColor={styledBoxBackgroundColor} padding={styledBoxPadding}>
                <SummaryDetails
                    currencyCode={currencyCode}
                    showRepairTimesInHours={showRepairTimesInHours}
                    showWorks
                    partCosts={partCosts}
                    partCount={partPositionsInTotal}
                    workCosts={workCosts}
                    repairTimeTotal={repairTimeTotal}
                    showGrossLabel={!!priceVatMode}
                />
            </StyledBox>
            <StyledBox backgroundColor={highlightColor} padding={styledBoxPadding}>
                <Grid container spacing={1} alignItems="center" sx={{ rowGap: 0.5 }}>
                    <Totals
                        costEstimationCalculation={costEstimationCalculation}
                        currencyCode={currencyCode}
                        costEstimationOptions={costEstimationOptions}
                    />
                    <Grid item xs={6} />
                    <Grid item xs={6} textAlign="right">
                        <HighlightContrastTypography variant="label">*{translateText(802)}</HighlightContrastTypography>
                    </Grid>
                </Grid>
            </StyledBox>
        </>
    )
}
