import { useRef, useState, useEffect } from "react"
import { CardActionArea } from "@tm/components"
import { CisConceptCustomer } from "@tm/models"
import { getBase64FromImage } from "@tm/utils"
import { LogoCard, LogoImage } from "./components"

type Props = {
    conceptCustomer: CisConceptCustomer
    onSelect(logo: string): void
}

export default function ConceptLogo(props: Props) {
    const imageRef = useRef<HTMLImageElement>(null)
    const [error, setError] = useState(false)

    useEffect(() => {
        setError(false)
    }, [props.conceptCustomer.iconUrl])

    if (!props.conceptCustomer.iconUrl || error) {
        return null
    }

    const handleClick = () => {
        if (imageRef.current) {
            getBase64FromImage(imageRef.current).then((data) => {
                props.onSelect(data)
            })
        }
    }

    const handleError = () => {
        setError(true)
    }

    return (
        <LogoCard>
            <CardActionArea onClick={handleClick}>
                <LogoImage crossOrigin="anonymous" url={props.conceptCustomer.iconUrl} onError={handleError} ref={imageRef} />
            </CardActionArea>
        </LogoCard>
    )
}
