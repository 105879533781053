import { Loader } from "@tm/controls"
import { CarManufacturer, VehicleType } from "@tm/models"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useEffect, useCallback, Suspense } from "react"
import { Redirect } from "react-router-dom"

import ManufacturerListComponent from "../_shared/ManufacturerList"
import { NoDataWarningComponent } from "../_shared/no-data-warning"
import { useAllManufacturers, useFilters, useSetAvailableFilters } from "../../data/hooks"
import { getValidatedVehicleIdentificationParams, getVehicleIdentificationUrl } from "../../helpers/routing"

type Props = RouteComponentProps<RouteParams>

type RouteParams = {
    vehicleType: string
}

function Wrapper(props: Props) {
    return (
        <div className="tk-vehicle vehicle-manufacturers">
            <Suspense fallback={<Loader />}>
                <ManufacturersComponent {...props} />
            </Suspense>
        </div>
    )
}

function ManufacturersComponent(props: Props) {
    const { vehicleType } = getValidatedVehicleIdentificationParams(props.match.params)

    if (!vehicleType) {
        return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType: VehicleType.PassengerCar })} />
    }

    const [filters, setFilters] = useFilters(vehicleType)
    const setAvailableFilters = useSetAvailableFilters(vehicleType)
    const response = useAllManufacturers({
        vehicleType,
        selectedFilters: {
            bodyTypeId: filters.bodyTypeId,
            // sorting: filters.sorting, // Only pass the required filters to prevent unnecessary service call (due to recoil selector params changing)
        },
    })

    useEffect(() => {
        // Reset the filters on mount
        setFilters((prev) => ({ sorting: prev.sorting }))
    }, [])

    useEffect(() => {
        setAvailableFilters({
            bodyTypes: response?.filters?.bodyTypes ?? [],
            modelYears: [],
            fuelTypes: [],
            engineCodes: [],
            engineCapacities: [],
            sorting: false,
        })
    }, [response, setAvailableFilters])

    const handleSelect = useCallback(
        (manufacturer: CarManufacturer) => {
            props.history.push(getVehicleIdentificationUrl(props.match.params, { vehicleType, manufacturerId: manufacturer.id }))
        },
        [props.history, props.match.params]
    )

    if (!response) {
        return <NoDataWarningComponent />
    }

    /** @todo Figure out how to automatically redirect but keep the ability to have the user click back */
    // if (response.manufacturers.length == 1) {
    //     return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, manufacturerId: response.manufacturers[0].id })} />
    // }

    return (
        <ManufacturerListComponent
            primaryManufacturers={response.primaryManufacturers}
            secondaryManufacturers={response.secondaryManufacturers}
            topManufacturers={response.topManufacturers}
            onSelect={handleSelect}
        />
    )
}

export default withRouter(Wrapper)
