import { useLocalization } from "@tm/localization"
import { Widget, WidgetSizes } from "@tm/controls"
import { useCisCustomer } from "@tm/context-distribution"
import { Stack, Typography, Alert, styled, Theme, SxProps } from "@tm/components"
import WidgetCreditFooter from "./components/WidgetCreditFooter"
import { VerticalDivider } from "../_shared/StyledComponents"

type Props = {
    size?: WidgetSizes
    sx?: SxProps<Theme>
}

const SxWidget = styled(Widget)({})

export default function WidgetCreditComponent({ size, sx }: Props) {
    const { translate, translateText, currency } = useLocalization()
    const { cisCustomer } = useCisCustomer()

    if (!cisCustomer) {
        return null
    }

    if (!cisCustomer.account) {
        return (
            <Widget size={size || "4x2"} active title={translate(491)} iconName="users" footer={<WidgetCreditFooter />}>
                <Alert severity="warning" title={translateText(323)} />
            </Widget>
        )
    }

    // These three values should be rendered in a shared components and used in widget-credit, widget and summary
    const { creditInfo, currencyCode, information } = cisCustomer.account
    const creditLimit = creditInfo ? parseFloat(creditInfo.limit) : 0
    const creditDrawn = creditInfo ? parseFloat(creditInfo.drawn) : 0

    const creditAvailable = creditLimit - creditDrawn

    return (
        <SxWidget sx={sx} size={size || "4x2"} active title={translate(491)} iconName="users" footer={<WidgetCreditFooter />}>
            <Stack spacing={2}>
                <Stack direction="row" divider={<VerticalDivider />} justifyContent="space-between">
                    <Stack>
                        <Typography variant="label">{translate(421)?.toString().toUpperCase()}</Typography>
                        <Typography>{Number.isNaN(creditLimit) ? creditInfo && creditInfo.limit : currency(creditLimit, currencyCode)}</Typography>
                    </Stack>
                    <Stack>
                        <Typography variant="label">{translate(422)?.toString().toUpperCase()}</Typography>
                        <Typography color="primary" fontWeight="bold">
                            {Number.isNaN(creditDrawn) ? creditInfo && creditInfo.drawn : currency(creditDrawn, currencyCode)}
                        </Typography>
                    </Stack>
                    {!Number.isNaN(creditAvailable) && (
                        <Stack>
                            <Typography variant="label">{translate(423)?.toString().toUpperCase()}</Typography>
                            <Typography>{currency(creditAvailable, currencyCode)}</Typography>
                        </Stack>
                    )}
                </Stack>
                <Stack>
                    <Typography variant="label">{translate(465)?.toString().toUpperCase()}</Typography>
                    <Typography fontWeight="bold">{information || ""}</Typography>
                </Stack>
            </Stack>
        </SxWidget>
    )
}
