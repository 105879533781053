import { RegisteredModels } from "@tm/models"
import { Container, IModelContainer } from "@tm/nexus"
import { ajax, getStoredAuthorization } from "@tm/utils"

import { getBundleParams } from "../../utils"
import * as Appointments from "./appointments"
import * as Customers from "./customers"
import * as Vehicles from "./vehicles"
import * as Vouchers from "./vouchers"

type DmsContainerActions =
    | { name: "importAppointment"; action: (externalSystemId: number) => Promise<string> }
    | { name: "importCustomer"; action: (request: Customers.ImportCustomerRequest) => Promise<void> }
    | { name: "importVehicle"; action: (request: Vehicles.ImportVehicleRequest) => Promise<void> }
    | { name: "importVoucherHistory"; action: (request: Vouchers.ImportVoucherHistoryRequest) => Promise<void> }
    | { name: "importVoucher"; action: (request: Vouchers.ImportVoucherRequest) => Promise<Vouchers.ImportVoucherResponse> }
    | { name: "exportVoucher"; action: (request: Vouchers.ExportVoucherRequest) => Promise<Vouchers.ExportVoucherResponse> }
    | { name: "extendVoucher"; action: (request: Vouchers.ExtendVoucherRequest) => Promise<Vouchers.ExtendVoucherResponse> }

export type DmsContainer = IModelContainer<void, DmsContainerActions>

type GetClientInstallationScriptResponse = {
    filename: string
    installScript: string
}

export function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.dmsServiceUrl
}

function getClientInstallationScript(certificateDownloadUrl: string) {
    return ajax<GetClientInstallationScriptResponse>({
        method: "POST",
        url: `${getServiceUrl()}/connect/GetClientInstallationScript`,
        authorization: getStoredAuthorization(),
        body: { certificateDownloadUrl },
    })
}

export function registerContainer() {
    Container.register({
        name: RegisteredModels.ImportExport,
        containerActions: {
            importAppointment: Appointments.importAppointment,
            importCustomer: Customers.importCustomer,
            importVehicle: Vehicles.importVehicle,
            importVoucherHistory: Vouchers.importVoucherHistory,
            importVoucher: Vouchers.importVoucher,
            exportVoucher: Vouchers.exportVoucher,
            extendVoucher: Vouchers.extendVoucher,
        },
    })

    Container.register({
        name: "DMS",
        containerActions: {
            getClientInstallationScript,
        },
    })
}
