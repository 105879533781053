import { useLocalization } from "@tm/localization"
import { TableCellData, CellContentPosition, TableColumnData } from "@tm/components"
import { VoucherType } from "@tm/models"
import { ReturnVoucherList } from "../../../data/model"
import { NoResultHint } from "../../_shared/NoResultHint"
import { StyledTable } from "../../_shared/StyledComponents"
import VoucherDetailsButton from "../../_shared/VoucherDetailsButton"

type Props = {
    returns: ReturnVoucherList[]
}

export default function WidgetReturnsList({ returns }: Props) {
    const { translateText, number, date } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(470) }, // voucherNumber
            { header: translateText(98) }, // returnDate
            { header: `${translateText(89)}`, alignContent: CellContentPosition.center }, // numberOfParts
            {}, // Details
        ]

        return columns
    }

    function getCells(returnOrder: ReturnVoucherList): TableCellData[] {
        return [
            { displayValue: returnOrder.voucherNumber, id: "1" },
            { displayValue: date(returnOrder.returnDate, "d"), id: "2" },
            { displayValue: returnOrder.totals?.numberOfParts ? number(returnOrder.totals.numberOfParts, 0) : "-", id: "3" },
            {
                displayValue: <VoucherDetailsButton variant="small" voucherId={returnOrder.returnVoucherId} voucherType={VoucherType.Return} />,
                id: "4",
            },
        ]
    }

    const displayData = returns.map((returnOrder) => ({
        cells: getCells(returnOrder),
        id: `${returnOrder.returnVoucherId}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable variant="small" sx={{ position: "absolute" }} columns={getColumns()} rows={displayData} rowCap={8} />
}
