import { Vehicle } from "@tm/models"

export type BundleActionType = { type: "VEHICLE_SET"; payload: { vehicle: Vehicle; datECode?: string } } | { type: "CLEAR_MODULE_URL" }

function setVehicle(vehicle: Vehicle, datECode?: string | undefined): BundleActionType {
    return {
        type: "VEHICLE_SET",
        payload: { vehicle, datECode },
    }
}
function clearURL(): BundleActionType {
    return {
        type: "CLEAR_MODULE_URL",
    }
}

export type IBundleActions = typeof BundleActions
export const BundleActions = {
    setVehicle,
    clearURL,
}
