import { Box, DatePicker, FormControlLabel, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Item, ItemTour, OrderOptions } from "@tm/models"
import { useCallback, useEffect, useState } from "react"
import { useDateFilter } from "../hooks/useDateFilter"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin, RadioButtonSmallWithMargin, RadioGroupGrid } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetTour(selectedTour: ItemTour, selectedOptionsItem?: Item, updateErpInformation?: boolean, updateOrderOptions?: boolean): void
    onUpdateTourFilterDate(tourFilterDate?: Date, selectedOptionsItem?: Item, updateOrderOptionsOnTourDateChange?: boolean): void
}

const today = new Date()
today.setUTCHours(0, 0, 0, 0) // so dates selected in the datepicker can be properly compared

export default function TourDateOptionsComponent(props: Props) {
    const { translateText, translate, language } = useLocalization()
    const {
        options: { tours, selectedOptionsItem },
        disableFields,
        onSetTour,
        onUpdateTourFilterDate,
    } = props
    const { tourFilterDate, showTourDatePicker, updateOrderOptionsOnChange, updateErpInformationOnChange, updateOrderOptionsOnTourDateChange } =
        tours || {}

    const [selectedTourId, setSelectedTourId] = useState<string>()

    const updateTourFilterDate = useCallback(
        (date: Date | null) => onUpdateTourFilterDate(date ?? undefined, selectedOptionsItem, updateOrderOptionsOnTourDateChange),
        [onUpdateTourFilterDate, selectedOptionsItem, updateOrderOptionsOnTourDateChange]
    )

    const { selectedDate, handleChange } = useDateFilter(tourFilterDate ?? null, updateTourFilterDate, today)

    useEffect(() => {
        if (tours?.tours.length) {
            setSelectedTourId(tours.tours.find((tour) => tour.isSelected)?.id)
        }
    }, [tours, tourFilterDate])

    const handleTourChange = useCallback(
        (_: unknown, id: string) => {
            if (tours?.tours.length) {
                setSelectedTourId(id)

                const firstTourMatch = tours.tours.find((tourOrder) => tourOrder.id === id)
                if (firstTourMatch) {
                    onSetTour({ ...firstTourMatch }, selectedOptionsItem, updateErpInformationOnChange, updateOrderOptionsOnChange)
                }
            }
        },
        [onSetTour, selectedOptionsItem, tours?.tours, updateErpInformationOnChange, updateOrderOptionsOnChange]
    )

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="delivery-time" />
                <Typography variant="h4">{translate(96)}</Typography>
            </BoxWithAlignItems>
            <BoxWithColumnPaddingRight pl="35px" pt="4px">
                {showTourDatePicker !== undefined && (
                    <Box mb={0.5}>
                        <DatePicker
                            localisation={{ locale: language, region: null }}
                            onChange={handleChange}
                            label={translateText(98)}
                            value={selectedDate}
                            disabled={disableFields || showTourDatePicker === false}
                            minDate={today}
                        />
                    </Box>
                )}
                {tours?.tours.length ? (
                    <RadioGroupGrid onChange={handleTourChange}>
                        {tours.tours.map((tour) => (
                            <Box key={tour.id} display="flex">
                                <FormControlLabel
                                    value={tour.id}
                                    control={<RadioButtonSmallWithMargin disabled={disableFields} checked={tour.id === selectedTourId} />}
                                    label={<Typography variant="body2">{tour.description}</Typography>}
                                />
                            </Box>
                        ))}
                    </RadioGroupGrid>
                ) : (
                    <Typography variant="body2">{translateText(13856)}</Typography>
                )}
            </BoxWithColumnPaddingRight>
        </BoxWithColumnPaddingRight>
    )
}
