import { LicensePlate } from "@tm/components"
import { ComponentTemplate } from "../business"

export function getTemplateElement(template: ComponentTemplate, children?: string) {
    switch (template.name.toUpperCase()) {
        case "PLATEID":
            return <LicensePlate {...template.props} value={children} />
        default:
            return children
    }
}
