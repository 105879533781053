import { useLocalization } from "@tm/localization"
import { CarMainModelSeries, VehicleType } from "@tm/models"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useEffect, useMemo, Suspense } from "react"
import { Link, Redirect } from "react-router-dom"
import { Box, Typography, Loader } from "@tm/components"

import { getSorting, useFilters, useMainModelSeriesByManufacturer, useSetAvailableFilters } from "../../data/hooks"
import { getValidatedVehicleIdentificationParams, getVehicleIdentificationUrl } from "../../helpers/routing"

type Props = RouteComponentProps<RouteParams>

type RouteParams = {
    vehicleType: string
    manufacturerId: string
}

function Wrapper(props: Props) {
    return (
        <Box m={2} className="tk-vehicle">
            <div className="mainmodelseries">
                <Suspense fallback={<Loader />}>
                    <MainModelSeriesComponent {...props} />
                </Suspense>
            </div>
        </Box>
    )
}

function MainModelSeriesComponent(props: Props) {
    const { vehicleType, manufacturerId } = getValidatedVehicleIdentificationParams(props.match.params)

    if (!vehicleType) {
        return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType: VehicleType.PassengerCar })} />
    }

    if (!manufacturerId) {
        return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, manufacturerId: undefined })} />
    }

    if (vehicleType !== VehicleType.PassengerCar) {
        return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, mainModelSeriesId: 0 })} />
    }

    const { translateText } = useLocalization()
    const [filters, setFilters] = useFilters(vehicleType)
    const setAvailableFilters = useSetAvailableFilters(vehicleType)
    const response = useMainModelSeriesByManufacturer({
        manufacturerId,
        selectedFilters: { bodyTypeId: filters.bodyTypeId, modelYear: filters.modelYear },
    })

    useEffect(() => {
        // Reset the filters on mount
        setFilters((prev) => ({ ...prev, fuelType: undefined, engineCode: undefined, engineCapacity: undefined }))
    }, [])

    useEffect(() => {
        setAvailableFilters({
            bodyTypes: response?.filters?.bodyTypes ?? [],
            modelYears: response?.filters?.modelYears ?? [],
            fuelTypes: [],
            engineCodes: [],
            engineCapacities: [],
            sorting: true,
        })
    }, [response, setAvailableFilters])

    const orderedMainModelSeries = useMemo(() => {
        if (!response?.mainModelSeries || getSorting(filters.sorting) !== "alphabetical") {
            return response?.mainModelSeries || []
        }

        return response.mainModelSeries.orderBy((x) => x.description)
    }, [response?.mainModelSeries, filters.sorting])

    const renderItem = (item: CarMainModelSeries) => {
        return (
            <li key={item.id} className="card mainmodelseries__item">
                <Link className="card__inner" to={getVehicleIdentificationUrl(props.match.params, { vehicleType, mainModelSeriesId: item.id || -1 })}>
                    {item.description}
                </Link>
            </li>
        )
    }

    /** @todo Figure out how to automatically redirect but keep the ability to have the user click back */
    // if (response?.mainModelSeries.length === 1) {
    //     return <Redirect to={getVehicleIdentificationUrl(props.match.params, { vehicleType, mainModelSeriesId: response.mainModelSeries[0].id })} />
    // }

    return (
        <>
            <div className="mainmodelseries__header">
                <Typography variant="h2" mb={1}>
                    {translateText(72)}
                </Typography>
            </div>
            <ul className="mainmodelseries__list">{orderedMainModelSeries.map(renderItem)}</ul>
        </>
    )
}

export default withRouter(Wrapper)
