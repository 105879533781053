import { RegistrationNoType, ShowCarModelDetailsRequest, TelematicsProvider, Vehicle } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getFormattedLocalDate } from "@tm/controls"
import { getBundleParams } from "../../utils"
import { DictionaryItem, DictionaryItemPair } from "../../components/_shared/Dictionary"

function getPrintServiceUrl() {
    return getBundleParams().printServiceUrl
}

export type PrintVehicleInfoResponse = {
    documentStream: string
}

export type VehicleInfo = {
    plateId?: string
    initialRegistration?: string
    mileAge?: number
    engineCode?: string
    vin?: string
    lastGeneralInspection?: string
    nextGeneralInspection?: string
    nextServiceDate?: string
    telematicsProvider?: string
    telematicsRefId?: string
    registrationNr?: string
}

export type ManufacturerInfo = {
    items?: Array<DictionaryItem>
    manufacturer?: string
    model?: string
    type?: string
    thumbnail?: string
}

export type VehiclePrintOptions = {
    printVehicleInfo: boolean
    printManufacturerInfo: boolean
    printOrderedParts: boolean
    printDatInfo: boolean
}

type ModelDetailsReq = Omit<ShowCarModelDetailsRequest, "modelId"> & {
    modelId?: number | undefined
}

export type PrintVehicleInfoRequest = {
    printOptions: VehiclePrintOptions
    currentDate: string
    vehicle?: VehicleInfo
    manufacturerInfo?: ManufacturerInfo
    modelDetailsRequest?: ModelDetailsReq
    vehicleId?: string
}

declare let window: Window & { navigator: Navigator & { msSaveOrOpenBlob: (file: Blob, name: string) => void } }

function openDocumentPdf(pdf: string) {
    const byteArray = Uint8Array.from(
        atob(pdf)
            .split("")
            .map((char) => char.charCodeAt(0))
    )
    const file = new Blob([byteArray], { type: "application/pdf" })

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, "Document.pdf")
    } else {
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL, "_blank")
    }
}

export function createPrintVehicleDetailsRequest(
    printOptions: VehiclePrintOptions,
    language: string,
    vehicle: Vehicle,
    modelImage: string,
    manufacturerData: Array<DictionaryItemPair>,
    regNo?: string,
    regNoType?: RegistrationNoType
): PrintVehicleInfoRequest | undefined {
    const date: Date = new Date()

    if (vehicle == null) {
        return
    }

    const manufacturerItems = manufacturerData.map((item) => {
        if (Array.isArray(item.value)) {
            return { key: item.key, value: item.value.join(", ") }
        }

        return item
    })

    return {
        printOptions,
        currentDate: getFormattedLocalDate(language, date) ?? "",
        modelDetailsRequest: {
            ...(printOptions.printDatInfo && {
                modelId: vehicle.tecDocTypeId,
                registrationNo: regNo,
                registrationNoTypeId: regNoType,
                modelIdentifier: {
                    plateId: vehicle?.plateId,
                    vin: vehicle?.vin,
                    initialRegistration: vehicle?.initialRegistration,
                },
                forceUpdateRegistrationNoDetails: false,
            }),
        },
        vehicle: {
            ...(printOptions.printVehicleInfo && {
                plateId: vehicle.plateId,
                initialRegistration: vehicle.initialRegistration ? getFormattedLocalDate(language, vehicle.initialRegistration!) : undefined,
                mileAge: vehicle.mileAge,
                engineCode: vehicle.engineCode,
                registrationNr: vehicle.registrationNo,
                vin: vehicle.vin,
                registrationId: vehicle.registrationTypeId,
                lastGeneralInspection: vehicle.lastGeneralInspection ? getFormattedLocalDate(language, vehicle.lastGeneralInspection!) : undefined,
                nextGeneralInspection: vehicle.nextGeneralInspection ? getFormattedLocalDate(language, vehicle.nextGeneralInspection!) : undefined,
                nextServiceDate: vehicle.nextServiceDate ? getFormattedLocalDate(language, vehicle.nextServiceDate!) : undefined,
                telematicsProvider: vehicle.telematicsProviderId ? TelematicsProvider[vehicle.telematicsProviderId] : undefined,
                telematicsRefId: vehicle.telematicsRefId,
            }),
        },
        manufacturerInfo: {
            ...(printOptions.printManufacturerInfo && {
                items: manufacturerItems,
                manufacturer: vehicle.manufacturer,
                model: vehicle.modelSeries,
                type: vehicle.model,
                thumbnail: modelImage,
            }),
        },
        vehicleId: printOptions.printOrderedParts ? vehicle.id : undefined,
    }
}

export async function getVehiclePdfDocument(request: PrintVehicleInfoRequest): Promise<PrintVehicleInfoResponse | undefined> {
    const data = await printVehicleInformation(request)
    if (!data) {
        return
    }

    openDocumentPdf(data.documentStream)

    return data
}

export function printVehicleInformation(request: PrintVehicleInfoRequest) {
    const url = `${getPrintServiceUrl()}/PrintVehicleInfo`
    const authorization = getStoredAuthorization()
    const body = { ...request }

    return ajax<PrintVehicleInfoResponse>({ url, body, authorization, method: "POST" })
}
