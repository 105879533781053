import { Text } from "@tm/controls"
import { useUserDisplayName } from "../../../../data/hooks"

export function DisplayName({ userId }: { userId: string }) {
    const displayName = useUserDisplayName(userId)

    if (displayName) {
        return (
            <Text size="l" modifiers={["block", "strong"]}>
                {displayName}
            </Text>
        )
    }

    return null
}
