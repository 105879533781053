import { CisQueryType } from "@tm/models"
import { create } from "zustand"
import { FindVoucherRequest } from "../data/model"
import { CisFilters } from "./model"

interface FilterState {
    filters?: Record<string, CisFilters>
    queryTypes?: Array<CisQueryType>
    loading?: boolean
    findVouchersRequest?: FindVoucherRequest
    hasData: boolean

    setLoading: (loading: boolean) => void
    setFilters: (voucherTypeId: string, voucherFilters: CisFilters) => void
    setFindVouchersRequest: (findVouchersRequest: FindVoucherRequest) => void
    setHasData: (hasData: boolean) => void
}

const useFilterStore = create<FilterState>((set) => ({
    hasData: false,
    setLoading: (loading) => set({ loading }),
    setFilters: (voucherTypeId, voucherFilters) => set((prevState) => ({ filters: { ...prevState.filters, [voucherTypeId]: voucherFilters } })),
    setFindVouchersRequest: (findVouchersRequest) => set({ findVouchersRequest }),
    setHasData: (hasData) => set({ hasData }),
}))

export { useFilterStore }
