import { useLocalization } from "@tm/localization"
import { Button, Image } from "@tm/controls"
import Morpheus from "@tm/morpheus"
import { DocumentAction, VoucherDocument } from "../../../data/model"

type Props = {
    voucherDocuments: Array<VoucherDocument>
}

export default function DocumentButtonsComponent({ voucherDocuments }: Props) {
    const { translateText } = useLocalization()

    function handleOpenDocumentButtonClick(documentURL: string) {
        const url = `/modal^/external?url=${encodeURIComponent(documentURL)}`
        Morpheus.showView("1", url)
    }

    if (!voucherDocuments.length) {
        return null
    }

    return (
        <>
            {voucherDocuments.map((voucherDocument) => {
                return (
                    <Button
                        icon={!voucherDocument.iconURL ? "print" : undefined}
                        layout={[voucherDocument.iconURL ? "holo" : "none"]}
                        skin="primary"
                        linkTo={voucherDocument?.actionType === DocumentAction.NewTab ? voucherDocument.documentURL : undefined}
                        onClick={
                            voucherDocument?.actionType !== DocumentAction.NewTab
                                ? () => {
                                      handleOpenDocumentButtonClick(voucherDocument.documentURL)
                                  }
                                : undefined
                        }
                        title={voucherDocument.description || translateText(13164)}
                        target="_blank"
                        key={voucherDocument.description}
                        style={{ padding: 0 }}
                    >
                        {voucherDocument.iconURL && (
                            <Image url={voucherDocument.iconURL} className="voucher-document-icon" style={{ width: "20px" }} />
                        )}
                    </Button>
                )
            })}
        </>
    )
}
