import * as React from "react"
import { Headline, Icon } from "@tm/controls"
import { Paper, Box } from "@tm/components"
import { getComponentStyles } from "./styles"

type Props = {
    icon: string
    headline: string
}

export const UploadPanel: React.FC<Props> = ({ icon, headline, children }) => {
    const classNames = getComponentStyles()

    return (
        <Paper sx={{ p: 3 }} elevation={2} className={classNames.panel}>
            <Box className={classNames.panelHeader}>
                <Icon name={icon} className={classNames.panelHeaderIcon} size="l" />
                <Headline size="xs">{headline}</Headline>
            </Box>
            <Box className={classNames.panelContent}>{children}</Box>
        </Paper>
    )
}
