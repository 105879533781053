import { useMemo, useState } from "react"
import { uniq } from "lodash"
import Morpheus from "@tm/morpheus"
import { getModuleFromUserContext, useUser, useWorkTaskId } from "@tm/context-distribution"
import { Article, ArticleErpInfo, ErpBundleParams, UserModuleType } from "@tm/models"
import { useActiveVehicleDataProviders } from "@tm/utils"
import { CompilationShowViewModelItem } from "../../../../../compilations/src/data"
import { useArticles } from "../../../../../compilations/src/data/hooks/useArticles"
import {
    useErpInfos,
    useProductGroupRepairTimes,
    useHasLoadingEnded,
    useListOptions,
    useBasketQuantities,
    usePreviouslyOrderedArticles,
} from "../../ListV2/hooks"
import { CompilationListOptions } from "../models"
import { ArticleGroupParams } from "../../ListV2/models"

export function useCompilationDetailsArticleList(compilationId: string, parts: CompilationShowViewModelItem[]) {
    const cacheKey = `compilationDetails_${compilationId}`
    const workTaskId = useWorkTaskId()

    const { userContext } = useUser()

    const { hideExtendedDeliveryComponent } = Morpheus.getParams<ErpBundleParams>("erp")
    const showNotes = useMemo<boolean>(() => !!getModuleFromUserContext(userContext, UserModuleType.ArticleNotes), [userContext.modules])

    const options = useListOptions()
    const listOptions: CompilationListOptions = useMemo(
        () => ({
            ...options,
            hasWatchList: false,
            showNotes,
        }),
        [options, showNotes]
    )

    const [erpInfos, setErpInfos] = useState<ArticleErpInfo[]>([])

    const { articles, isLoading } = useArticles(parts)

    const hasArticlesLoaded = !!articles && !isLoading

    const validArticles = useMemo(
        () => (hasArticlesLoaded ? articles?.filter((article) => article.productGroup.id !== -1 && article.internalId !== -1) ?? [] : []),
        [articles, hasArticlesLoaded]
    )

    const productGroupIds = useMemo(() => uniq(validArticles?.map((x) => x.productGroup.id) ?? []), [validArticles])

    const activeVehicleDataProviders = useActiveVehicleDataProviders(workTaskId)

    const erpInfosData = useErpInfos({
        articles: validArticles,
        isEnabled: hasArticlesLoaded,
        erpInfos,
        setErpInfos,
    })

    const { basketQuantities, updateBasketQuantities } = useBasketQuantities(validArticles, erpInfosData.isReady, cacheKey)

    const productGroupRepairTimes = useProductGroupRepairTimes(productGroupIds, erpInfosData.isReady)

    const previouslyOrderedArticles = usePreviouslyOrderedArticles(articles)

    const hasLoadingEnded = useHasLoadingEnded(isLoading, productGroupRepairTimes.isLoading, activeVehicleDataProviders.isLoading)

    const groupedArticles = useMemo(() => groupArticles(articles), [articles])

    return {
        isLoading,
        groupedArticles,
        hideExtendedDeliveryComponent,
        productGroupRepairTimes: hasLoadingEnded ? productGroupRepairTimes : undefined,
        activeVehicleDataProviders: hasLoadingEnded ? activeVehicleDataProviders.activeProviders : undefined,
        erpInfosData,
        previouslyOrderedArticles,
        listOptions,
        basketQuantities,
        updateBasketQuantities,
    }
}

function groupArticles(articles: Article[] | undefined) {
    if (!articles?.length) {
        return []
    }

    const groupMap: Record<string, ArticleGroupParams> = {}

    articles.forEach((article) => {
        const group = groupMap[article.productGroup.id]
        if (group) {
            group.articles.push(article)
        } else {
            groupMap[article.productGroup.id] = {
                productGroup: article.productGroup,
                articles: [article],
                incomplete: false,
            }
        }
    })

    return Object.values(groupMap)
}
