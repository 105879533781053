import { GetProductGroupTopicIdsRequest, GetProductGroupTopicIdsResponse } from "@tm/models"
import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../utils"

function getTechnicalDataUrl(): string {
    return getBundleParams().technicalDataServiceUrl
}

export function getProductGroupTopicIds(request: GetProductGroupTopicIdsRequest): Promise<GetProductGroupTopicIdsResponse> {
    const url = `${getTechnicalDataUrl()}/GetProductGroupTopicIds`
    const authorization = getStoredAuthorization()
    const body: GetProductGroupTopicIdsRequest = {
        ...request,
        productGroupIds: request.productGroupIds.sort((a, b) => a - b),
        repairTimeProvider: request.repairTimeProvider.sort((a, b) => a - b),
    }

    return ajax({ url, body, authorization, method: "GET" }).then((data) => data?.productGroups)
}
