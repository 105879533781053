import { Loader } from "@tm/controls"
import { CarModelSeries } from "@tm/models"
import { connectComponent } from "@tm/morpheus"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { useMemo, Suspense, useEffect } from "react"
import { ArticleReferencesState } from "../_shared/article-references-business"
import ModelSeriesListComponent from "../_shared/ModelSeriesList"
import { useModelSeriesByArticle } from "../../data/hooks"
import { getValidatedVehicleIdentificationParams } from "../../helpers/routing"
import { usePreferredSortOptionKey } from "../../data/hooks/useSortOptions"

type Props = RouteComponentProps<RouteParams> & {
    state: ArticleReferencesState
    routes: {
        types: string
    }
}

type RouteParams = {
    vehicleType: string
    manufacturerId: string
}

function Wrapper(props: Props) {
    if (!props.state.internalArticleId) {
        return null
    }

    return (
        <div className="tk-vehicle">
            <div className="modelseries">
                <Suspense fallback={<Loader />}>
                    <ArticleReferencesModelSeriesComponent {...props} articleId={props.state.internalArticleId} />
                </Suspense>
            </div>
        </div>
    )
}

function ArticleReferencesModelSeriesComponent(props: Props & { articleId: number }) {
    const { vehicleType, manufacturerId } = getValidatedVehicleIdentificationParams(props.match.params)
    const [preferredSortOption, setPreferredSortOption] = usePreferredSortOptionKey()

    if (!vehicleType || !manufacturerId) {
        return null
    }

    const { modelSeries } = useModelSeriesByArticle({ vehicleType, manufacturerId, articleId: props.articleId, selectedFilters: {} }) ?? {}

    const response = useModelSeriesByArticle({
        vehicleType,
        manufacturerId,
        articleId: props.articleId,
        selectedFilters: {},
    })

    useEffect(() => {
        if (modelSeries?.length == 1) {
            const url = renderRoute(props.routes.types, { ...props.match.params, modelSeriesId: modelSeries[0].id }) + (props.location.search || "")
            props.history.replace(url)
        }
    }, [modelSeries])

    const orderedModelSeries = useMemo(() => {
        if (!response?.modelSeries || preferredSortOption !== "alphabetical") {
            return response?.modelSeries.orderBy((x) => x.priority)
        }

        return response.modelSeries.orderBy((x) => x.description.toUpperCase())
    }, [response?.modelSeries, preferredSortOption])

    if (!orderedModelSeries) {
        return null
    }

    const handleSelect = (modelSeries: CarModelSeries) => {
        const url = renderRoute(props.routes.types, { ...props.match.params, modelSeriesId: modelSeries.id }) + (props.location.search || "")
        props.history.push(url)
    }

    return <ModelSeriesListComponent modelSeries={orderedModelSeries} onSelect={handleSelect} vehicleType={vehicleType} isArticleReferences />
}

export default connectComponent({}, withRouter(Wrapper))
