import { useWorkTask } from "@tm/context-distribution"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { renderRoute } from "@tm/utils"
import { useIdStorageByWorkTask } from "../../data/hooks"
import { getBundleParams } from "../../utils"
import { MicroWrapper } from "../MicroWrapper"
import MailButton from "./components/mail-button"

type Props = IMicros["notifications"]["crm-button"]

function CrmButton(props: Props) {
    const { chatDetailsRoute } = getBundleParams()

    const { translateText } = useLocalization()
    const chatStorage = useIdStorageByWorkTask()
    const customer = useWorkTask()?.workTask?.customer

    if (chatStorage) {
        return (
            <Button icon="chat" linkTo={renderRoute(chatDetailsRoute, { chatId: chatStorage.chatId })}>
                {translateText(996)}
            </Button>
        )
    }

    if (customer?.email) {
        return <MailButton customer={customer} email={customer.email} />
    }

    return null
}

export default function Wrapper(props: Props) {
    return (
        <MicroWrapper>
            <CrmButton {...props} />
        </MicroWrapper>
    )
}
