import { AsyncAction } from "@tm/morpheus"
import { batch } from "react-redux"
import { ReactText } from "react"
import { BundleActions, BundleActionTypes } from "../../../business"
import { getSplittedRegistrationNo } from "../../../data/helpers"
import { MainState } from "../../main"
import { MainActionsType, MainActions } from "../../main/business"
import { createPrepareCalculationRequest, useContainer } from "../../../data/repositories"

import { SummaryState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "UPDATE_FIELD"; payload: { path: string; value: any } }
    | { type: "PREPARE_CALC_LOADING" }
    | { type: "PREPARE_CALC_ERROR" }
    | { type: "INIT_CUSTOMER"; payload: { country: string } }

const DEFAULT_STATE: SummaryState = {
    fields: {
        keyNumbers: [],
        processName: "",
    },
}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): SummaryState => {
    switch (action.type) {
        case "RESET": {
            return {
                ...DEFAULT_STATE,
                fields: state.fields,
            }
        }
        case "UPDATE_FIELD": {
            const { path, value } = action.payload
            return {
                ...state,
                fields: {
                    ...state.fields,
                    [path]: value,
                },
            }
        }
        case "INIT_CUSTOMER": {
            const { country } = action.payload

            return {
                ...state,
                fields: {
                    ...state.fields,
                    country,
                },
            }
        }
        case "PREPARE_CALC_LOADING": {
            return {
                ...state,
                prepareCalculationLoading: true,
                prepareCalculationError: false,
            }
        }
        case "PREPARE_CALC_ERROR": {
            return {
                ...state,
                prepareCalculationError: true,
                prepareCalculationLoading: false,
            }
        }
        case "PREPARE_CALC_LOADED": {
            return {
                ...state,
                prepareCalculationError: false,
                prepareCalculationLoading: false,
            }
        }
        case "VEHICLE_SET": {
            let { registrationNo } = action.payload
            const { initialRegistration, plateId, vin, mileAge } = action.payload
            if (registrationNo) {
                const { hsn, tsn } = getSplittedRegistrationNo(registrationNo)

                registrationNo = [hsn, tsn].join(" ")
            }

            return {
                ...state,
                fields: {
                    ...state.fields,
                    initialRegistration,
                    vin,
                    keyNumber: registrationNo,
                    plateNumber: plateId,
                    mileage: mileAge,
                },
            }
        }
        case "CUSTOMER_SET": {
            const { title, titleType, firstName, lastName, zip, city, country, email, street, phone, companyName } = action.payload

            return {
                ...state,
                fields: {
                    ...state.fields,
                    title,
                    titleType,
                    firstName,
                    lastName,
                    street,
                    zip,
                    city,
                    ...(country && { country }),
                    email,
                    phone,
                    companyName,
                },
            }
        }
        case "DETAILS_LOADED": {
            const { modelDetails } = action.payload
            const minDate =
                modelDetails?.constructionYearFrom && new Date(modelDetails.constructionYearFrom.year, modelDetails.constructionYearFrom.month, 0)
            const maxDate =
                modelDetails?.constructionYearTo && new Date(modelDetails.constructionYearTo.year, modelDetails.constructionYearTo.month, 0)

            return {
                ...state,
                fields: {
                    ...state.fields,
                    keyNumbers: modelDetails?.registrationNos || [],
                    minDate,
                    maxDate,
                },
            }
        }
        default:
            return state
    }
}

const initCustomer = (country: string): MainActionsType => ({ type: "INIT_CUSTOMER", payload: { country } })

const updateFieldValue = (path: string, value: any): MainActionsType => ({ type: "UPDATE_FIELD", payload: { path, value } })

const startCalculation =
    (translateText: (key: ReactText) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        const state = getState()
        const { fields } = state.summary

        if (!fields.keyNumber) {
            return
        }

        const req = createPrepareCalculationRequest(fields, translateText)
        if (!req) {
            return
        }

        const container = useContainer()
        dispatch({ type: "PREPARE_CALC_LOADING" })
        container
            .action("prepareCalculation")(req)
            .then(
                (res) => {
                    batch(() => {
                        dispatch({ type: "PREPARE_CALC_LOADED", payload: res.baseInfo })
                        dispatch(MainActions.changeStep("extern", { taskId: res.baseInfo.taskId }))
                    })
                },
                () => dispatch({ type: "PREPARE_CALC_ERROR" })
            )
    }

export const Actions = { ...BundleActions, updateFieldValue, startCalculation, initCustomer }
