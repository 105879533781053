import { useSelector } from "react-redux"
import { useLocalization } from "@tm/localization"
import { Box, Icon, Image, Typography, useTheme } from "@tm/components"
import { MainState } from "../../main"

export default function Header() {
    const { translateText } = useLocalization()
    const theme = useTheme()

    const prepareCalculationError = useSelector((s: MainState) => s.summary.prepareCalculationError)

    return (
        <Box padding={theme.margin?.m} display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap={theme.margin?.m}>
                <Typography fontSize="large">{translateText(1458)}</Typography>

                <Box maxWidth="4em">
                    <Image width="80px" src="/styles/base/images/dat-logo.png" />
                </Box>

                {prepareCalculationError && (
                    <Box display="flex" alignItems="center" gap={theme.margin?.m}>
                        <Icon name="info" color="red" />
                        <Typography color="red">
                            {translateText(787)}. {translateText(401)}
                        </Typography>
                    </Box>
                )}
            </Box>
        </Box>
    )
}
