import { useCallback, useEffect, useMemo, useState } from "react"
import { useStyle } from "@tm/context-distribution"
import { SelectionList, SelectionListItem, Tag } from "@tm/controls"
import { LocalizationProps } from "@tm/localization"
import { SearchFilters } from "@tm/models"
import { TmaHelper } from "@tm/utils"
import { Box, Button, Typography } from "@tm/components"
import { SearchFilter } from "../../../../data/model"

type SearchFilterProps = {
    isExtendedSearch: boolean
    showApplyButton: boolean
    selectedSearchFilters?: SearchFilters
    searchfilterResultCounts: SearchFilter[]
    onSelection: (selectedFilter: SearchFilters) => void
    onSearchFilterChanged?(selected: Array<SelectionListItem>): void
    toggleExtendedSearch: (extendedSearch: boolean) => void
} & LocalizationProps

export const SearchFiltersComponent = (props: SearchFilterProps) => {
    const {
        isExtendedSearch,
        showApplyButton,
        selectedSearchFilters,
        searchfilterResultCounts,
        onSelection,
        onSearchFilterChanged,
        toggleExtendedSearch,
        localization,
    } = props
    const [isApplyButtonEnabled, setIsApplyButtonEnabled] = useState(false)

    const { translate, translateText } = localization
    const [activeSearchFilter, setActiveSearchFilter] = useState(selectedSearchFilters || SearchFilters.All)

    const searchFilterItems = useMemo<SelectionListItem[]>(
        () => getSearchFilters(translateText, searchfilterResultCounts),
        [searchfilterResultCounts]
    )

    useEffect(() => {
        if (isExtendedSearch) {
            setActiveSearchFilter(SearchFilters.All)
        }
    }, [isExtendedSearch, selectedSearchFilters])

    const handleApplySearchFilter = () => {
        setIsApplyButtonEnabled(false)
        const selectedFilterItems = searchFilterItems.filter((x) => (activeSearchFilter & parseInt(x.query)) === parseInt(x.query))

        const newFilter = getFilter(selectedFilterItems)

        onSearchFilterChanged?.(selectedFilterItems)
        onSelection?.(newFilter)
    }

    const handleSearchFilterChange = (selectedFilterItems: Array<SelectionListItem>) => {
        const newFilter = getFilter(selectedFilterItems)
        if (!showApplyButton && onSelection) {
            onSelection(newFilter)
        }
        setActiveSearchFilter(newFilter)
        setIsApplyButtonEnabled(true)
    }

    const isSearchFilterActive = useCallback(
        (searchFilter: SearchFilters) => {
            return (activeSearchFilter & searchFilter) === searchFilter
        },
        [activeSearchFilter]
    )

    useEffect(() => {
        handleTmaData(searchFilterItems, isSearchFilterActive)
    }, [searchFilterItems, isSearchFilterActive])

    function getFilter(selectedFilterItems: SelectionListItem[]) {
        let filter: SearchFilters = 0 as SearchFilters

        selectedFilterItems.forEach((x) => {
            const value = parseInt(x.query)
            filter |= value
        })

        // Set all search filters if all deselected or all selected
        if (isFilterSearchAll(filter)) {
            filter = SearchFilters.All
        }
        toggleExtendedSearch(false)

        return filter
    }

    function isFilterSearchAll(filter: SearchFilters) {
        // @ts-ignore
        return filter === 0 || filter === 127 || filter === 1920 || filter === 2047
    }

    return (
        <Box className="parts__search-filters">
            <Typography size="s">
                {translate(1014)}
                <Tag
                    className={style.tag}
                    layout="ghost"
                    onClick={() => {
                        if (activeSearchFilter !== SearchFilters.All) {
                            setActiveSearchFilter(SearchFilters.All)
                            onSelection && onSelection(SearchFilters.All)
                            onSearchFilterChanged && onSearchFilterChanged(searchFilterItems)
                        }
                    }}
                    value={translateText(12683)}
                />
                :
            </Typography>
            <SelectionList
                size="m"
                groups={[{ priority: "high", items: searchFilterItems }]}
                selected={searchFilterItems.filter((x) => isSearchFilterActive(parseInt(x.query)))}
                onChange={handleSearchFilterChange}
                localization={localization}
            />
            {showApplyButton && (
                <Button onClick={handleApplySearchFilter} disabled={!isApplyButtonEnabled} size="small">
                    {translateText(22)}
                </Button>
            )}
        </Box>
    )
}

function getSearchFilters(translateText: (value: number) => string, searchfilterResultCounts: SearchFilter[]): Array<SelectionListItem> {
    const getFilterResultCount = (searchFilter: SearchFilters): number => {
        return searchfilterResultCounts.filter((x) => x.filterType & searchFilter).reduce((prev, cur) => prev + cur.count, 0)
    }

    const mapSearchFilter = (value: SearchFilters, label: string): SelectionListItem => {
        return {
            query: value.toString(),
            name: label,
            resultCount: getFilterResultCount(value),
        }
    }

    return [
        mapSearchFilter(SearchFilters.OeReference, translateText(185)),
        mapSearchFilter(SearchFilters.UtilityNo, translateText(392)),
        mapSearchFilter(SearchFilters.ReplacementNo, translateText(393)),
        mapSearchFilter(SearchFilters.SupplierArticleNo, translateText(92)),
        mapSearchFilter(SearchFilters.TradeReference, translateText(91)),
        mapSearchFilter(SearchFilters.EuropeanArticleNumber, translateText(387)),
    ]
}

async function handleTmaData(items: Array<SelectionListItem>, isSearchFilterActive: (searchFilter: SearchFilters) => boolean) {
    TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.SetSearchFilters(
        items.map((item) => ({
            id: parseFloat(item.query),
            isSelected: isSearchFilterActive(parseFloat(item.query)),
            articleCount: item.resultCount || 0,
        }))
    )
}

const style = useStyle({
    tag: {
        fontWeight: "normal",
        margin: 0,
        verticalAlign: "baseline",
    },
})(SearchFiltersComponent)
