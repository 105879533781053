import { RegistrationNoType } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { DATVehicleVinSearchResponse } from "./model"

export function datVehicleVinSearch(vin: string): Promise<DATVehicleVinSearchResponse> {
    const url = `${getServiceUrl()}/ShowVehiclesByVin`
    const authorization = getStoredAuthorization()
    const body = {
        vin,
        lookupTypeId: RegistrationNoType.DatVin,
        forceUpdateRegistrationNoDetails: true,
    }

    return new Promise<DATVehicleVinSearchResponse>((resolve, reject) =>
        ajax({ url, body, method: "GET", authorization }).then((response) => resolve(response), reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.vrmLookupServiceUrl
}
