import { Box } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CisSalesOutlet } from "@tm/models"
import { TextBody1Bold, TypographyTitle, LightableText } from "../StyledComponents"
import EmailComponent from "./Email"
import PhoneComponent from "./Phone"

type Props = {
    warehouse: CisSalesOutlet
    warehouseHeaderId: number
    lightMode?: boolean
}

export default function WarehouseInfoComponent({ warehouse, warehouseHeaderId, lightMode }: Props) {
    const { translate } = useLocalization()

    return (
        <Box display="flex" flexDirection="column">
            <TypographyTitle lightMode={lightMode}>{translate(warehouseHeaderId)}</TypographyTitle>
            <Box>
                <TextBody1Bold lightMode={lightMode}>{warehouse.name || warehouse.description}</TextBody1Bold>
                <LightableText variant="body2" lightMode={lightMode}>
                    {warehouse.address && warehouse.address.street}
                </LightableText>
                <LightableText variant="body2" lightMode={lightMode}>
                    {warehouse.address && `${warehouse.address.zip} ${warehouse.address.city}`}
                </LightableText>
                <PhoneComponent phone={warehouse.address?.phone} lightMode={lightMode} />
                {warehouse.electronicAddresses && warehouse.electronicAddresses.length > 0 && (
                    <EmailComponent email={warehouse.electronicAddresses[0].value} lightMode={lightMode} />
                )}
            </Box>
        </Box>
    )
}
