import { useDeleteNote, useLoadNotes, useSaveNote } from "@tm/utils"
import { Note, NoteTypes } from "@tm/models"
import { useCallback } from "react"

type NoteFunctions = {
    articleNotes: Note[]
    saveNote(noteId: string, articleNote?: string): Promise<void>
    saveNoteInProgress: boolean
}

export const useArticleNotes = (articleIds: string[]): NoteFunctions => {
    const { articleNotes } = useLoadNotes(articleIds)
    const { saveNote: save, saveNoteInProgress } = useSaveNote()
    const { deleteNote, deleteNoteInProgress } = useDeleteNote()

    const filterForRequestedNote = useCallback(
        (noteId): Note | undefined => {
            return articleNotes.find((note) => note.noteId === noteId)
        },
        [articleNotes]
    )

    const handleSaveNote = async (noteId: string, articleNote?: string): Promise<void> => {
        const noteToEdit = filterForRequestedNote(noteId)

        if (!articleNote && noteToEdit?.text) {
            await deleteNote(noteId)
        }

        if (articleNote && articleNote !== noteToEdit?.text) {
            await save({
                noteId,
                type: NoteTypes.CUSTOM_ARTICLE,
                text: articleNote,
                version: noteToEdit?.version,
            })
        }
    }

    return {
        articleNotes,
        saveNoteInProgress: saveNoteInProgress || deleteNoteInProgress,
        saveNote: handleSaveNote,
    }
}
