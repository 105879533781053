import { EurotaxOePart, EurotaxOePartGroup, OE } from "@tm/models"
import { equals } from "@tm/utils"


export function getEurotaxRDArticles(part: EurotaxOePart, items: EurotaxOePartGroup[], callbackTranslate: (key: React.ReactText) => string, hideOePrice : boolean | undefined) {
    let partsArray = selectedProductGroupParts(part, items).map(val => val.map((x) =>
        mapOePartRD({ ...x, ...equals(x, part) && { isSelected: true } }, callbackTranslate, hideOePrice))).reduce((acc, val) => acc.concat(val))

    return partsArray

}

function mapOePartRD(item: EurotaxOePart, callbackTranslate: (key: React.ReactText) => string, hideOePrice : boolean | undefined): OE.OePartRD {
    const partPosition = displayPartPosition(item.links, item.rechts, callbackTranslate)

    return {
        oeNumber: item.partNumber,
        description: item.mastercodeDescription,
        additionalDescription: partPosition,
        oeReplacements: item.replacements,
        currency: item.currency,
        price: hideOePrice ? undefined : item.price,
        isSelected: !!item.isSelected,
    }
}

function selectedProductGroupParts(part: EurotaxOePart, items: EurotaxOePartGroup[]) {
    return items.map(item => item.parts.find(x => equals(x, part)) && item.parts).filter(Boolean) as EurotaxOePart[][]
}


function displayPartPosition(links: boolean, rechts: boolean, callbackTranslate: (key: React.ReactText) => string) {

    const buttonTitle = links && rechts ? callbackTranslate(1809) : links ? callbackTranslate(355) : rechts ? callbackTranslate(356) : ""

    return buttonTitle
}