import Morpheus, { AsyncAction } from "@tm/morpheus"
import * as Data from "../../data"
import { TimeSlot, EventType, ServiceEvent } from "../../data"
import { BundleActionType, BundleState, bundleReduce, IBundleActions, BundleActions } from "../../business"
import { getVehicleType } from "../../helpers/vehicle"

export type ComponentActionType =
    | BundleActionType
    | { type: "SET_WORKTASK_ID"; payload: string }
    | { type: "SLOTS_LOADING" }
    | { type: "SLOTS_LOADED"; payload: Array<TimeSlot> }

export type ComponentState = BundleState & {
    workTaskId?: string
    loading: boolean
    slots: Array<TimeSlot>
    types: Array<EventType>
    slotsLoading: boolean
    typesLoading: boolean
}

const DEFAULT_STATE: ComponentState = {
    loading: false,
    slotsLoading: false,
    typesLoading: false,
    slots: [],
    types: [],
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ComponentState {
    state = bundleReduce(state, action as BundleActionType) as ComponentState
    switch (action.type) {
        case "SET_WORKTASK_ID": {
            return {
                ...state,
                workTaskId: action.payload,
            }
        }
        case "SLOTS_LOADING": {
            return {
                ...state,
                loading: true,
                slotsLoading: true,
                slots: [],
            }
        }
        case "TYPES_LOADING": {
            return {
                ...state,
                loading: true,
                typesLoading: true,
            }
        }
        case "SLOTS_LOADED": {
            return {
                ...state,
                loading: state.typesLoading,
                slots: action.payload,
                slotsLoading: false,
            }
        }
        case "TYPES_LOADED": {
            return {
                ...state,
                loading: state.slotsLoading,
                types: action.payload.orderBy((x) => x.order),
                typesLoading: false,
            }
        }
        default:
            break
    }
    return state
}

export function transmit(action: ComponentActionType) {
    switch (action.type) {
        case "EVENT_CREATED": {
            return action
        }
        default:
            break
    }
}

function setWorkTaskId(workTaskId: string): ComponentActionType {
    return {
        type: "SET_WORKTASK_ID",
        payload: workTaskId,
    }
}

function loadFreeSlots(date: Date, duration: number): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "SLOTS_LOADING" })
        Data.getFreeSlots(date, date, duration)
            .then((timeslots) => dispatch({ type: "SLOTS_LOADED", payload: timeslots }))
            .catch(() => {
                dispatch({ type: "SLOTS_LOADED", payload: [] })
            })
    }
}

function loadEventTypes(): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        dispatch({ type: "TYPES_LOADING" })
        Data.getEventTypes()
            .then((types) => dispatch({ type: "TYPES_LOADED", payload: types }))
            .catch(() => {
                dispatch({ type: "SLOTS_LOADED", payload: [] })
            })
    }
}

function createEvent(typeId: number, date: Date, duration: number): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const { workTaskId, customer, vehicle } = getState()
        const endDate = date.clone()
        endDate.setHours(date.getHours())
        endDate.setMinutes(date.getMinutes() + duration)

        let event: ServiceEvent = {
            eventTypeId: typeId,
            startDateTime: date,
            endDateTime: endDate,
            resourceId: 1,
            onlineBookingId: workTaskId,
        }

        if (customer) {
            event = {
                ...event,
                customerNumber: customer.customerNo,
                firstName: customer.firstName,
                lastName: customer.lastName,
                company: customer.companyName,
                phoneNumber: customer.phone,
                street: customer.street,
                zipCode: customer.zip,
                city: customer.city,
                email: customer.email,
            }
        }

        if (vehicle) {
            event = {
                ...event,
                vehicleIdentificationNumber: vehicle.vin,
                firstRegistrationDate: vehicle.initialRegistration,
                licensePlate: vehicle.plateId,
                vehicleType: getVehicleType(vehicle),
                // vehicleTypeId: vehicle.tecDocTypeId,  // entspricht nicht der Tecdoc type id :(
                mileage: vehicle.mileAge,
                generalInspectionDate: vehicle.nextGeneralInspection,
            }
        }

        Data.createServiceEvent(event)
            .then((result) => {
                Morpheus.closeView("1")
                dispatch({ type: "EVENT_CREATED", payload: result })
            })
            .catch(() => {
                Morpheus.closeView("1")
            })
    }
}

export type IActions = IBundleActions & {
    setWorkTaskId(...args: Parameters<typeof setWorkTaskId>): void
    loadEventTypes(...args: Parameters<typeof loadEventTypes>): void
    loadFreeSlots(...args: Parameters<typeof loadFreeSlots>): void
    createEvent(...args: Parameters<typeof createEvent>): void
}

export const Actions = {
    ...BundleActions,
    setWorkTaskId,
    loadEventTypes,
    loadFreeSlots,
    createEvent,
}
