import { DMS } from "@tm/models"
import { concat } from "@tm/utils"

export function getCustomerNameAndCompany(customer: DMS.Customer): string {
    let name = concat(" ", customer.firstName, customer.lastName)

    if (name && customer.companyName) {
        name = concat(" / ", name, customer.companyName)
    } else if (customer.companyName) {
        name = customer.companyName
    }

    return name
}

export function getVehicleName(vehicle: DMS.Vehicle): string {
    return vehicle.vehicleType.description ?? concat(" ", vehicle.manufacturer, vehicle.model, vehicle.type)
}
