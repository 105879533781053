import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { classes } from "@tm/utils"
import { em, margin, percent, rem } from "csx"
import * as React from "react"
import { useSelector } from "react-redux"
import { useVehicleCheck } from "../../data/hooks"
import { MainActions, managerDataSelector } from "../main/business"
import { GPS, MaintenanceItems, Misc, NextMaintenance, Providers, TirePressure, VehicleDetails } from "./components"

const DetailsComponent: React.FC<{}> = () => {
    const { statusData, statusDataLoading, statusDataLoaded, vinUsed } = useSelector(managerDataSelector) || {}
    const worktaskVehicle = useWorkTask()?.workTask?.vehicle
    const actions = useActions(MainActions, "getStatusData")

    const shouldLoadData = useVehicleCheck(statusDataLoaded, vinUsed)

    React.useEffect(() => {
        if (shouldLoadData && worktaskVehicle)
            worktaskVehicle.vin && actions.getStatusData(worktaskVehicle.vin)
    }, [worktaskVehicle])

    if (statusDataLoading)
        return <Loader className={style.baseLoader} />

    if (!statusData)
        return null

    return (
        <div className={style.tkCarmunication}>
            <div className={style.details}>
                <div className={style.col}>
                    <div className={classes(style.left, style.mainSection)}>
                        <NextMaintenance />
                        <Misc />
                        <TirePressure />
                    </div>

                    <div className={classes(style.center, style.mainSection)}>
                        <GPS />
                    </div>

                    <div className={classes(style.right, style.mainSection)}>
                        <VehicleDetails />
                    </div>

                    <Providers />
                </div>
                
                <MaintenanceItems />
            </div>
        </div>
    )
}

const style = useStyle({
    baseLoader: {
        position: "absolute",
        top: percent(60),
        left: percent(50),
        zIndex: 2,
        $nest: {
            ".loader": {
                marginLeft: percent(-50),
                marginTop: percent(-50),
                fontSize: em(2)
            }
        }
    },
    tkCarmunication: {
        flex: 1
    },
    details: {
        $nest: {
            ".title": {
                padding: rem(1),
                borderBottom: "1px solid rgba(33,150,243,.32)"
            }
        }
    },
    col: {
        display: "flex"
    },
    left: {
        flex: 2,
        justifyContent: "space-between"
    },
    center: {
        margin: margin(0, rem(1)),
        flex: 2
    },
    right: {
        padding: em(1),
        borderLeft: ".4375em solid #dfe4e7",
    },
    mainSection: {
        display: "flex",
        flex: 1,
        flexDirection: "column"
    }
})(DetailsComponent)

export default DetailsComponent