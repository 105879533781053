import { useLocalization } from "@tm/localization"
import { VoucherType } from "@tm/models"
import { Typography } from "@tm/components"
import { TotalNumberType } from "../../../../business"
import { RowStack } from "../../../../../_shared/StyledComponents"

type Props = {
    voucherType: VoucherType
    type: TotalNumberType
    totalNumber?: number
}

export default function TotalNumbers({ voucherType, type, totalNumber }: Props) {
    const { translateText } = useLocalization()

    if (!totalNumber) {
        return null
    }

    const partsTitle = voucherType === VoucherType.Order ? translateText(90) : translateText(1667)
    const title = type === TotalNumberType.parts ? partsTitle : translateText(1668)

    return (
        <RowStack spacing={0.5}>
            <Typography variant="label" fontWeight="bold">
                {`${title} `}
                {totalNumber || 0}
            </Typography>
            <Typography variant="label">{translateText(479)}</Typography>
        </RowStack>
    )
}
