import { RequestArticleListPayload, ShowPartsListRequest, channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenPartsList(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (data.openPartsList) {
        this.checkAndCreateWorktask()
        const request = mapShowPartsListToRequestArticleList(data.openPartsList)
        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}

function mapShowPartsListToRequestArticleList(payload: ShowPartsListRequest): RequestArticleListPayload {
    if (payload.productGroupIds && !payload.query && !payload.supplierArticleNo) {
        return {
            productGroups: {
                ids: payload.productGroupIds,
                supplierIds: payload.supplierIds,
            },
            inModal: true,
        }
    }
    return {
        general: payload,
        inModal: true,
    }
}
