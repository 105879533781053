import { useLocalization } from "@tm/localization"
import { Toolbar } from "@tm/controls"
import { Box } from "@tm/components"
import StatusFlag from "../../../../../../../_shared/StatusFlag"
import { useVoucherErpState } from "../../../../../../../../data/hooks/useVoucherErpState"
import { getBundleParams } from "../../../../../../../../utils"

type Props = {
    customOrderNumber?: string
    catalogOrderNumber?: string
    wholesalerOrderNumber?: string
}

export default function ErpState({ catalogOrderNumber, customOrderNumber, wholesalerOrderNumber }: Props) {
    const { translateText } = useLocalization()

    const { erpStates, erpStateLoading } = useVoucherErpState({
        orderIdentifier:
            getBundleParams().erpOrderState && (catalogOrderNumber || wholesalerOrderNumber || customOrderNumber)
                ? [
                      {
                          catalogOrderId: catalogOrderNumber,
                          wholesalerOrderId: wholesalerOrderNumber,
                          customOrderId: customOrderNumber,
                      },
                  ]
                : [],
    })

    const status = erpStates?.orderStatus.first()?.orderStatusMessage

    if (!status && !erpStateLoading) {
        return null
    }

    return (
        <Box display="flex">
            <Toolbar modifiers={["no-border"]} title={translateText(212)}>
                <StatusFlag status={status} isLoading={erpStateLoading} />
            </Toolbar>
        </Box>
    )
}
