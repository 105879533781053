import { RegisteredModels, VehicleContainer } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"

export function useCustomerVehicles(customerId?: string, enabled = true) {
    const { data, isLoading } = useQuery(
        ["vouchers__useCustomerVehicles", customerId],
        () =>
            customerId ? (Container.getInstance(RegisteredModels.Vehicle) as VehicleContainer).action("showCustomerVehicles")(customerId) : undefined,
        {
            enabled: enabled && !!customerId,
            staleTime: 30 * 1000, // 30 seconds
        }
    )

    return { customerVehicles: data, customerVehiclesLoading: isLoading }
}
