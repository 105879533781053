import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { MaintenanceReviewSlice, MaintenanceReviewState } from "./model"
import { filterData, sendItemsToMaintenanceReview } from "./actions"

function getInitialState(): MaintenanceReviewState {
    return {
        maintenanceReview: {
            items: {},
            activeFilterButton: "none",
            selectedWorks: new Map([]),
            itemsForPrint: {},
        },
    }
}

export const createMaintenanceReviewSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], MaintenanceReviewSlice> = (set) => ({
    ...getInitialState(),
    maintenanceReviewActions: {
        filterData: (buttonType) => set((state) => filterData(state, buttonType), false, "Maintenance review filter data"),
        sendItemsToMaintenanceReview: (path, data) =>
            set((state) => sendItemsToMaintenanceReview(state, path, data), false, "Send items to maintenance review"),
        reset: () => set(getInitialState(), false, "Reset Maintenance Review Slice"),
    },
})
