import { useQuery } from "react-query"
import { getCustomArticlesCategories } from "../repositories/custom-items/articles"

export function useCustomArticlesCategories() {
    const { data, isLoading } = useQuery(["catalog____CustomArticlesCategories"], () => getCustomArticlesCategories(), {
        staleTime: 30 * 60 * 1000, // 30 minutes
    })

    return { categories: data, categoriesLoading: isLoading }
}
