
import { ajax, getStoredAuthorization } from "@tm/utils"
import { EreLinkImagesRawResponse, EreLinkImagesRequest, EreLinkImagesResponse } from "."
import { getBundleParams } from "../../../utils"
import { mapEreLinkImagesResponse } from "./mapper"
export * from "./model"

export function getEreLinkImages(request?: EreLinkImagesRequest) {
    const url = `${getServiceUrl()}/GetEreLinkImages`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<EreLinkImagesResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then(
            (response: EreLinkImagesRawResponse) => {
                if (response.vknImages?.sufficientSelection)
                    resolve(mapEreLinkImagesResponse(response))
                else reject()
            },
            reject
        ))
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.eurotaxGraphicSelectionUrl
}
