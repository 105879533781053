import { useLocalization } from "@tm/localization"
import { useState, useEffect } from "react"
import { Collapsible } from "@tm/controls"
import { useKeyValue } from "@tm/utils"
import { Alert, Paper, Icon, Loader, Button, Typography, Stack, Box, styled } from "@tm/components"
import { WorkTaskOrderButtonConfigProps } from "../../_shared/order-button/worktask"
import PartsList, { PartsListConfigProps } from "../../_shared/parts-list/component"
import { useCentralOrderWorkTasks } from "../../../data/hooks/centralOrder/useCentralOrderWorkTasks"
import WorkTaskInfo from "./WorkTaskInfo"
import WorkTasksSelection from "./WorkTasksSelection"
import { LoaderSmall } from "../../StyledComponents"
import Totals from "./Totals"

type Props = {
    partListProps: PartsListConfigProps
    workTaskOrderButtonProps: WorkTaskOrderButtonConfigProps
}
const WrapperStack = styled(Stack)({
    flex: 1,
    overflow: "hidden",
})
const StyledCollapsible = styled(Collapsible)({
    ".collapsible__caption": {
        flex: "0 0 auto !important",
    },
})

const today = new Date()
const oneWeekAgo = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)
export default function WorkTasksComponent({ partListProps, workTaskOrderButtonProps }: Props) {
    const { translateText, date, language } = useLocalization()
    const [showDateFilterDialog, setShowDateFilterDialog] = useState<boolean>(false)
    const {
        state: {
            loading,
            hasMorePages,
            loadingNextPage,
            error,
            workTasks,
            dateTo,
            dateFrom,
            orderCalculation,
            orderCalculationLoading,
            includedWorkTaskIds,
            includedWorkTasksWithBasketErrors,
        },
        loadNextPage,
        toggleExpanded,
        hideWorkTask,
    } = useCentralOrderWorkTasks()

    const [centralOrderingShowOnlyUserWorkTasks, _, centralOrderingShowOnlyUserWorkTasksState] = useKeyValue({
        key: "CENTRAL_ORDERING_SHOW_ONLY_USER_WORKTASKS",
        ownedByRepairShop: false,
    })

    const isLoading = loading || centralOrderingShowOnlyUserWorkTasksState === "loading"
    useEffect(() => {
        setShowDateFilterDialog(!dateFrom || dateFrom < oneWeekAgo)
    }, [dateTo, dateFrom])

    function handleCloseClick() {
        setShowDateFilterDialog(false)
    }

    function renderWorkTasks() {
        return workTasks.map((workTask) => {
            function renderHeaderAppendix() {
                return <WorkTaskInfo {...workTaskOrderButtonProps} workTaskState={workTask} onHideWorkTask={hideWorkTask} />
            }

            const orderGroupSucceeded = workTask.succeededOrderGroups?.find((orderGroup) => orderGroup.orderDate)
            return (
                <Paper key={workTask.workTask.workTaskId}>
                    {orderGroupSucceeded?.orderDate && (
                        <Stack direction="row" spacing={2} alignItems="center">
                            <Icon name="check-filled" />
                            <Typography>
                                {translateText(774)} {orderGroupSucceeded.orderDate.toLocaleDateString()} {translateText(699)}{" "}
                                {orderGroupSucceeded.orderDate.toLocaleTimeString()}.
                            </Typography>
                        </Stack>
                    )}
                    {!orderGroupSucceeded && workTask.workTask.modifiedDate && <Typography>{date(workTask.workTask.modifiedDate, "g")}</Typography>}
                    <StyledCollapsible
                        renderHeaderAppendix={renderHeaderAppendix}
                        closeDropdownOnOutsideClick
                        initiallyOpened={workTask.expanded}
                        onChangeOpen={() => toggleExpanded(workTask.workTask.workTaskId)}
                    >
                        <div className="part-list">
                            <PartsList {...partListProps} workTask={workTask.workTask} hideAddToBasketButtonInArticleComparison />
                        </div>
                    </StyledCollapsible>
                </Paper>
            )
        })
    }

    return (
        <WrapperStack spacing={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h2" mb={1}>
                    {translateText(centralOrderingShowOnlyUserWorkTasks === "true" ? 13125 : 1142) + (workTasks ? ` (${workTasks?.length})` : "")}
                </Typography>
                <Box pr="364px">
                    <Totals
                        numberOfItemsInOrder={orderCalculation?.numberOfItemsInOrder}
                        numberOfPositionsInOrder={orderCalculation?.numberOfPositionsInOrder}
                        loading={orderCalculationLoading ?? false}
                        erpTotalsInOrder={orderCalculation?.erpTotalsInOrder}
                        workTaskIds={includedWorkTaskIds}
                        hasErrors={includedWorkTasksWithBasketErrors ?? false}
                    />
                </Box>
            </Stack>
            {showDateFilterDialog && !!workTasks.length && <Alert severity="info" title={translateText(3121)} onClose={handleCloseClick} />}
            {isLoading && <Loader />}
            {isLoading === false && !workTasks.length ? (
                <Paper>
                    <Stack spacing={1} flex={1} alignItems="center">
                        <Icon name="no-results" />
                        <Typography variant="h4">
                            {translateText(3120)
                                .replace("{0}", dateFrom?.toLocaleDateString(language) || "-")
                                .replace("{1}", dateTo?.toLocaleDateString(language) || "-")}
                        </Typography>
                    </Stack>
                </Paper>
            ) : (
                <Box flex={1} sx={{ overflowY: "scroll" }}>
                    <Box zIndex={5} position="absolute" right="25px">
                        <WorkTasksSelection />
                    </Box>
                    <Stack spacing={2}>{renderWorkTasks()}</Stack>
                    <Stack spacing={2} textAlign="center">
                        {error && <Alert severity="error" title={error} />}
                        {hasMorePages && (
                            <Box mt={2}>
                                <Button startIcon={<Icon name="down" />} onClick={loadNextPage} disabled={loadingNextPage}>
                                    {loadingNextPage && <LoaderSmall />}
                                    {translateText(2013)}
                                </Button>
                            </Box>
                        )}
                    </Stack>
                </Box>
            )}
        </WrapperStack>
    )
}
