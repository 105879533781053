import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { DrivemotiveLoginRequest, DrivemotiveLoginResponse } from "../model"

export function getDrivemotiveLoginResponse(request: DrivemotiveLoginRequest): Promise<DrivemotiveLoginResponse | undefined> {
    const url = `${getBundleParams().drivemotiveServiceUrl}/login`
    const authorization = getStoredAuthorization()

    return ajax<{ result: DrivemotiveLoginResponse }>({ url, body: request, authorization, method: "POST" }).then((response) => {
        if (!response) {
            throw new Error()
        }
        return response.result
    })
}
