import { VehicleRecordsBundleParams } from "@tm/models"
import { registerActions } from "./data/repository"

export const version = {
    name: "vehicle-records",
    version: "1.0.0",
}

let bundleParams: VehicleRecordsBundleParams

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function initVehicleRecordsBundle(params: VehicleRecordsBundleParams) {
    bundleParams = params
    registerActions()
    return bundleParams
}
