import { FormControl, FormHelperText } from "@tm/components"
import { ControllerFieldState } from "react-hook-form"

type Props = {
    children: React.ReactNode
    fieldState: ControllerFieldState
}

export default function FormItem({ children, fieldState }: Props) {
    const { error } = fieldState
    return (
        <FormControl>
            {children}
            <FormHelperText sx={{ color: "error.main" }}>{error?.message ?? ""}</FormHelperText>
        </FormControl>
    )
}
