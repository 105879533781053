import { withRouter, RouteComponentProps, TmaHelper } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { GlobalNavigationItem, styled } from "@tm/components"
import { matchPath, useLocation } from "react-router"
import { ECounterType } from "@tm/models"

type Props = RouteComponentProps<void> & {
    url: string
}

const GlobalNavigationUserSettings = styled(GlobalNavigationItem)(({ theme }) => ({
    "&.global-navigation-flat": {
        marginRight: "1.5rem",
        borderRight: `1px solid ${theme.palette.grey.A400}60`,
        "&.active": {
            background: `${theme.palette.grey[100]}`,
        },
    },
}))

const UserSettingsButton = (props: Props) => {
    const { translateText } = useLocalization()
    const { pathname, search } = useLocation()
    const { url } = props

    const isActive = matchPath(pathname + search, url)

    const handleClick = () => {
        if (isActive) {
            props.history.goBack()
        } else {
            props.history.push(url)
            TmaHelper.GeneralCountEvent.Call(ECounterType.SettingsOpen)
        }
    }

    return (
        <GlobalNavigationUserSettings
            active={!!isActive}
            name="user-settings"
            componentType="icon"
            className="user-settings"
            onIconClick={handleClick}
            tooltip={translateText(141)}
        />
    )
}

export default withRouter(UserSettingsButton)
