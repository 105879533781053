import { TyresCarTypeAddon, ETyresCarType } from "@tm/models"

export const CarTypeAddons: TyresCarTypeAddon[] = [
    { Id: ETyresCarType.PKW, Description: "PKW", ShortCode: "233001", Default: true },
    { Id: ETyresCarType.SUV, Description: "SUV", ShortCode: "233002", Default: true },
    { Id: ETyresCarType.LLKW, Description: "LLKW", ShortCode: "233003", Default: true },
    { Id: ETyresCarType.LKW, Description: "LKW", ShortCode: "233004", Default: false },
    { Id: ETyresCarType.Motorrad, Description: "Motorrad", ShortCode: "233005", Default: false },
    { Id: ETyresCarType.Anhanger, Description: "Anhänger", ShortCode: "233006", Default: false },
    { Id: ETyresCarType.Agrar, Description: "Agrar", ShortCode: "233007", Default: false },
    { Id: ETyresCarType.EM, Description: "EM", ShortCode: "233008", Default: false },
    { Id: ETyresCarType.Industrie, Description: "Industrie", ShortCode: "233009", Default: false },
    { Id: ETyresCarType.Militar, Description: "Militär", ShortCode: "2330010", Default: false },
]

