import { FittingGroupRequest, FittingGroupResponse } from "@tm/models"
import { getStoredAuthorization, ajax, createBufferedRequestFunction } from "@tm/utils"
import { getBasketServiceUrl } from "../.."

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Works`
}

function callService(requests: Array<{ workTaskId: string; request: FittingGroupRequest }>) {
    const url = `${getServiceUrl()}/HasRepairTimesForProductGroup`
    const authorization = getStoredAuthorization()
    const body = {
        workTaskId: requests[requests.length - 1].workTaskId,
        fittingGroups: requests.map((r) => r.request),
    }

    return ajax<{ fittingGroups?: Array<FittingGroupResponse> }>({ url, body, authorization, method: "POST" }).then(
        (data) => data?.fittingGroups || []
    )
}

export const getHasRepairTimesForProductGroup = createBufferedRequestFunction({
    callService,
    mapServiceResponseToResponse: (serviceResponse, request) => {
        return (
            serviceResponse.find(
                (item) => item.productGroupId === request.request.productGroupId && item.fittingSide === request.request.fittingSide
            ) || {
                productGroupId: request.request.productGroupId,
                fittingSide: request.request.fittingSide,
                hasWorks: false,
            }
        )
    },
})
