import { clone } from "@tm/utils"
import { BundleActions, BundleActionTypes } from "../../../business"
import { DetailsState } from "./model"

export type ComponentActionType = BundleActionTypes

const DEFAULT_STATE: DetailsState = { }

export const reduce = (state = clone(DEFAULT_STATE), action: ComponentActionType): DetailsState => {
    switch (action.type) { }

    return state
}

export type IActions = typeof Actions

export const Actions = { ...BundleActions }
