import { getStoredAuthorization, ajax, clone, ModelState, openBase64Pdf } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { isDocumentRelated } from "../../components/details-formulars/bussiness/helpers"
import { DocumentRequest, EDocumentIds, Tab } from "../../components/details-formulars/bussiness/model"

function getDigitalFormsUrl(): string {
    return getBundleParams().digitalFormsServiceUrl
}

export function getPdfDocument(request: DocumentRequest, modelState: ModelState, tabs: Tab[], translateText: (id: number) => string) {
    const url = `${getDigitalFormsUrl()}/ShowExternalPdf`
    const authorization = getStoredAuthorization()

    const body = clone(request)
    const selectedDocument = body.documents?.first()

    const costStatementDocExtID = body.documents.find((x) => x.documentTypeId == EDocumentIds.CostStatement)?.externalSystemId

    const hasRelateGVACost = selectedDocument?.documentTypeId == EDocumentIds.GVA && isDocumentRelated(costStatementDocExtID, body.documents)
    const hasRelatedBaterrieCost =
        selectedDocument?.documentTypeId == EDocumentIds.Batterie && isDocumentRelated(costStatementDocExtID, body.documents)

    if (selectedDocument) {
        body.documents = body.documents.filter((x) => {
            if (x.documentTypeId == EDocumentIds.CostStatement && (hasRelateGVACost || hasRelatedBaterrieCost)) {
                const tab = tabs.find((x) => x.id == "CostStatement")

                if (modelState[tab?.enabledIf!]?.length) {
                    return false
                }
            }

            return true
        })
    }

    return new Promise<void>((resolve, reject) => {
        ajax({ url, body, authorization, method: "POST" }).then((data: string) => {
            openBase64Pdf(data, translateText)
            resolve()
        }, reject)
    })
}
