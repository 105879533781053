import { Button, PanelSection, TabControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import * as React from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { RimDriveRightTyres, RimSensors, RimVehicleInformation } from "."
import { tabs } from "../../../data/helpers/mocks"
import { NavigationItem } from "../../../data/model"
import { MainState } from "../../main"
import { Actions } from "../business"

type Props = {}

const selector = createSelector((s: MainState) => ({
    showRimInfo: s.wheelsList.base.showRimInfo
}), x => x)

const RimInfo: React.FC<Props> = ({ }) => {
    const { translateText } = useLocalization()
    const { showRimInfo } = useSelector(selector)
    const actions = useActions(Actions, "changeRimInfoAndSettingVisibility")
    const [selectedTab, setSelectedTab] = React.useState("VehicleInfo")

    const renderNavigationItem = (item: NavigationItem, idx: number) => {
        return (
            <TabControl.Tab identifier={item.path} key={idx}>
                {translateText(item.translationId)}
            </TabControl.Tab>
        )
    }

    const onClose = () => {
        actions.changeRimInfoAndSettingVisibility(false, false)
    }

    if (!showRimInfo)
        return null

    return (
        <PanelSection className="rim_info">
            <div className="rim-info__header">
                <TabControl selectedTabIdentifier={selectedTab} onTabSelect={setSelectedTab}>
                    {tabs.map(renderNavigationItem)}
                </TabControl>
                <Button onClick={onClose} layout={["ghost"]} icon="close" />
            </div>
            <div className="rim-info__content">
                {selectedTab == 'VehicleInfo' && <RimVehicleInformation />}
                {selectedTab == 'SeriesTires' && <RimDriveRightTyres />}
                {selectedTab == 'Rdks' && <RimSensors />}
            </div>
        </PanelSection>
    )
}

export default RimInfo