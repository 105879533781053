import { AddCustomPartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { useEnableCostEstimation } from "../../../../hooks/basketState/useEnableCostEstimation"
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"
import { useCreateBasketPartPostMessage } from "../../../../hooks/useCreatePartPostMessage"
import { useHandlePostSendOrderAction } from "../../../../components/_shared/order-button/hooks/useHandlePostSendOrderAction"

export function useAddCustomPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const { enableCostEstimation } = useEnableCostEstimation()
    const basketModule = useBasketModuleParameter()
    const { getMessageByCustomPart } = useCreateBasketPartPostMessage()
    const handlePostSendOrderAction = useHandlePostSendOrderAction()

    const { mutateAsync: addCustomPartList } = useMutation(
        (request: { addCustomPartListRequest: AddCustomPartListRequest }) => Data.addCustomPartList(request.addCustomPartListRequest),
        {
            onSuccess: async (response, request) => {
                if (response) {
                    await enableCostEstimation(request.addCustomPartListRequest.workTaskId)

                    handleBasketUpdateWorkflow(request.addCustomPartListRequest.workTaskId, response)
                }
                // Save work task in the DB
                Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
            },
        }
    )

    const addCustomParts = useCallback(
        (request: AddCustomPartListRequest) => {
            if (basketModule.sendArticlesPostMessage) {
                const message = getMessageByCustomPart(request.customParts, request.workTaskId)
                handlePostSendOrderAction(request.workTaskId, message)
            }
            if (basketModule?.addToNextBasket !== false) {
                return addCustomPartList({ addCustomPartListRequest: request })
            }
            return Promise.reject(new Error("no function"))
        },
        [basketModule?.addToNextBasket, basketModule?.sendArticlesPostMessage, getMessageByCustomPart]
    )

    return addCustomParts
}
