import { Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { Text } from "@tm/controls"
import { concat } from "@tm/utils"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["details-erp-info-quantity-unit"]

function DetailsErpInfoQuantityUnitComponent({ data, foundBySearchTerm }: Props) {
    const { translateText } = useLocalization()
    const { loading, erpInfo: { quantity } = {} } = useErpInfo(data, "details", undefined, undefined, foundBySearchTerm)

    if (loading || !quantity?.quantityUnit) {
        return null
    }

    return <Text size="s">{concat(" ", translateText(1114), quantity.quantityPackingUnit, quantity.quantityUnit)}</Text>
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="details-erp-info-quantity-unit">
                    <Suspense fallback={null}>
                        <DetailsErpInfoQuantityUnitComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
