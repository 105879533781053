import { Box, Typography } from "@tm/components"
import { ReactNode } from "react"

type Props = {
    title?: string
    content?: string | Array<ReactNode>
}

export default function HeaderContent({ title, content }: Props) {
    return (
        <Box display="flex" alignItems="center" margin={(theme) => theme.spacing(0, 0.5)}>
            {title && <Typography fontWeight={600}>{title}</Typography>}
            {content && Array.isArray(content) ? content.map((x) => <Typography>{x}</Typography>) : <Typography>{content}</Typography>}
        </Box>
    )
}
