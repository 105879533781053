import { clone } from "@tm/utils"
import { BundleActions, BundleActionTypes } from "../../../business"
import { EreSelectionSteps } from "../../../data/models"
import { MainActionsType } from "../../main/business"
import { NavigationState } from "./model"

export type ComponentActionType = BundleActionTypes

const DEFAULT_STATE: NavigationState = {
    active: EreSelectionSteps.SUMMARY,
    params: {},
}

export const reduce = (state = clone(DEFAULT_STATE), action: MainActionsType): NavigationState => {
    switch (action.type) {
        case "CHANGE_STEP": {
            const { selectedStep, params, fromHistory } = action.payload
            return {
                ...state,
                active: selectedStep,
                params: {
                    ...state.params,
                    [selectedStep]: {
                        ...state.params[selectedStep],
                        ...params,
                        selected: true,
                    },
                    ...(selectedStep === EreSelectionSteps.CALCULATION && {
                        [EreSelectionSteps.EXTERN]: { selected: true },
                    }),
                    ...(fromHistory &&
                        selectedStep === EreSelectionSteps.EXTERN && {
                            [EreSelectionSteps.CALCULATION]: { selected: true },
                        }),
                },
            }
        }
        case "RESTART_PROCESS":
            return {
                ...state,
                params: {},
            }
        default: {
            return state
        }
    }
}

export const reset = (): BundleActionTypes => ({ type: "RESTART_PROCESS" })
export const changeStep = (selectedStep: EreSelectionSteps, params?: any, fromHistory?: boolean): BundleActionTypes => ({
    type: "CHANGE_STEP",
    payload: { selectedStep, params, fromHistory },
})

export const Actions = {
    ...BundleActions,
    reset,
    changeStep,
}
