import { CisCustomerResponse } from "@tm/models"
import { LicensorData, TraderRequestForDocuments } from "../model"

export function mapTraderForDocumentsRequest(
    licensorData?: LicensorData,
    custommer?: CisCustomerResponse,
    addressGVS?: string
): TraderRequestForDocuments {
    const defaultSalesOutletIndex = custommer?.salesOutlets?.findIndex((salesOutlet) => salesOutlet.isDefault) || 0
    const sales = custommer?.salesOutlets?.length ? custommer.salesOutlets[defaultSalesOutletIndex] : undefined

    if (licensorData) {
        let traderAdress = ""

        if (addressGVS && addressGVS.length) {
            traderAdress = addressGVS
        } else {
            if (licensorData.name) {
                traderAdress += `${licensorData.name}\n`
            }
            if (licensorData.streetAndNumber) {
                traderAdress += `${licensorData.streetAndNumber}\n`
            }
            if (licensorData.postalCodeAndCity) {
                traderAdress += licensorData.postalCodeAndCity
            }
        }

        return {
            city: licensorData.postalCodeAndCity || "",
            companyName: licensorData.name || "",
            street: licensorData.streetAndNumber || "",
            country: "",
            zip: "",
            postalAddress: traderAdress,
            administrator: "",
            traderNr: licensorData.supportNo || "",
            phone: licensorData.faxNo || licensorData.telNo || "",
            email: "",
        }
    }
    const postalAddress = sales ? `${sales?.name}\n${sales?.address?.street}\n${sales?.address?.zip} ${sales?.address?.city}` : ""
    return {
        city: sales?.address?.city || "",
        companyName: sales?.name || "",
        country: "",
        street: sales?.address?.street || "",
        zip: sales?.address?.zip || "",
        postalAddress: addressGVS || postalAddress || "",
        phone: sales?.address?.phone || "",
        email: (sales?.electronicAddresses && sales.electronicAddresses.length > 0 && sales.electronicAddresses[defaultSalesOutletIndex].value) || "",
        administrator: "",
        traderNr: "",
    }
}
