import { stylesheet, extend, media } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { breakpoints, margin } = getStyleTheme()

    return stylesheet({
        wrapper: {
            marginTop: margin.xl,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: margin.xl,

            ...extend(
                media(
                    { maxWidth: breakpoints.m },
                    {
                        flexDirection: "column",
                    }
                )
            ),
        },
    })
}
