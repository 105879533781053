import { getBundleParams } from "../../../utils"
import { getStoredAuthorization, ajax } from "@tm/utils"
import { TireSizesRequest } from "./model"
import { TireSize } from "../../model"


export function loadTireSize(request: TireSizesRequest) {
    const url = `${getServiceUrl()}/GetTireSizes`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<TireSize[]>((resolve, reject) =>
        ajax({ url, body, authorization }).then(
            response => resolve(response?.tireSizes ?? []),
            reject
        )
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}