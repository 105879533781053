import { Genart, RepairTime, SpecificationsGenart } from "."
import { CustomWork } from "../../models"

export type Work = {
    id: string
    genArts: Genart[]
    consumables: SpecificationsGenart[]
    label: string
    isSelected: boolean
    repairTimes: RepairTime[]
    location: string
    providerId: string
    locationId: string
    workType: number
    infoTexts: string[]
    haynesProSmartLinks: SmartLink[]
}

export type SmartLink = {
    filter: string
    id1: number
    id2: string
    operation: string
    text: string[]
    label: string
}

export type RecordWorks = Record<string, Work[]>

export type RecordCustomWorks = Record<string, CustomWork[]>

export enum WorkId {
    FirstAidKit = "1045"
}
