import { useMemo } from "react"
import { AttachmentButton, Button, MessageBubble, MessageMetaData, styled } from "@tm/components"
import { Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { concat } from "@tm/utils"
import { bundleChannel } from "../../../../bundle-channel"
import { MetaDataType } from "../../../../data"
import { documentTypeOfferTextId, documentTypes } from "../../../../data/documentTypes"
import { Message, useOwnUserId } from "../../../../data/hooks"
import { FileAttachmentMetaData } from "../input/attachment"
import Appointment, { AppointmentResponse } from "./attachment/appointment"
import CostEstimate, { CostEstimateState } from "./attachment/cost-estimate"
import { getComponentStyles } from "./styles"

type Props = {
    message: Message
}

const ContentWrapper = styled("div")({
    display: "flex",
})

export default function MessageComponent({ message }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const ownUserId = useOwnUserId()
    const { translateText, date } = useLocalization()
    const isOwnMessage = ownUserId === message.authorId

    const { text } = message
    // Attachment Message
    if (message.attachment) {
        const metadata: FileAttachmentMetaData | undefined = message.appMetaData && JSON.parse(message.appMetaData)
        let attachment
        switch (message.attachment.mimeType) {
            case "image/jpeg":
            case "image/png":
            case "image/gif":
                attachment = (
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                    <img
                        src={message.attachment.uri}
                        className={classNames.attachmentImage}
                        onClick={() => bundleChannel("CHAT").publish("SHOW_IMAGE_VIEWER", { message })}
                    />
                )
                break
            case "application/pdf":
                attachment = (
                    <>
                        {metadata?.type === "costEstimate" && !metadata.externalOffer ? (
                            <CostEstimate metadata={metadata} message={message} />
                        ) : (
                            <ContentWrapper>
                                <AttachmentButton
                                    fileType="Pdf"
                                    href={message.attachment.uri}
                                    iconName="file-pdf"
                                    fileName={metadata?.name || "Open PDF"}
                                />
                            </ContentWrapper>
                        )}
                    </>
                )
                break
            case "video/mp4":
            case "video/webm":
            case "video/ogg":
                attachment = (
                    <video src={message.attachment.uri} controls muted className={classNames.attachmentVideoAudio}>
                        Video format is not supported by your browser
                    </video>
                )
                break
            case "audio/mpeg":
            case "audio/ogg":
            case "audio/wav":
                attachment = (
                    <audio src={message.attachment.uri} muted controls className={classNames.attachmentVideoAudio}>
                        Audio format is not supported by your browser
                    </audio>
                )
                break
            default:
                break
        }

        let categoryText
        if (metadata?.type === "attachment") {
            const categoryTextId = metadata.category && documentTypes.find((i) => i.categories[metadata.category!])?.categories[metadata.category]
            categoryText = categoryTextId ? translateText(categoryTextId) : metadata.category
        } else if (metadata?.type === "costEstimate") {
            categoryText = translateText(documentTypeOfferTextId)
        }

        const sublineMileageText = metadata?.mileage && `${translateText(125)} ${metadata?.mileage?.toLocaleString()}`
        const subline = concat("|", sublineMileageText)

        return (
            <MessageBubble dateTime={date(new Date(message.inserted), "g")} subline={subline} headline={categoryText} attachment={attachment}>
                {metadata?.type === "costEstimate" && metadata.externalOffer && <CostEstimateState message={message} metadata={metadata} />}
                {text}
            </MessageBubble>
        )
    }
    if (message.appMetaData) {
        const metadata: MetaDataType = JSON.parse(message.appMetaData)

        switch (metadata.type) {
            case "appointment":
                return (
                    <Appointment
                        appointmentId={metadata.appointmentId}
                        date={metadata.preferedDate}
                        endDate={metadata.endDate}
                        initialMetadata={metadata}
                        message={message}
                        isOwnMessage={isOwnMessage}
                    />
                )
            case "appointmentResponse":
                if (metadata.action === "customerProposal" || metadata.action === "repairshopProposal") {
                    return (
                        <AppointmentResponse
                            appointmentId={metadata.appointmentId}
                            date={metadata.proposedDate}
                            endDate={metadata.endDate}
                            message={message}
                            isOwnMessage={isOwnMessage}
                        />
                    )
                }
                break
            case "link":
                return (
                    <MessageBubble dateTime={date(new Date(message.inserted), "g")}>
                        {text}
                        <div>
                            <Button color="inherit" href={metadata.url} startIcon={<Icon name="linked-item" />} target="_blank">
                                {" "}
                                {metadata.name || "External Link"}
                            </Button>
                        </div>
                    </MessageBubble>
                )
            case "initial":
                if (metadata.action === "customerData") {
                    const data = [
                        { label: translateText(155), value: `${metadata?.customerInfo?.firstName} ${metadata?.customerInfo?.lastName}` },
                        { label: translateText(109), value: metadata?.customerInfo?.emailAddress },
                    ]
                    return (
                        <MessageMetaData
                            dateTime={date(new Date(message.inserted), "g")}
                            left={!isOwnMessage}
                            headline={translateText(3107)}
                            dataType="Customer"
                            data={data}
                        />
                    )
                }
                if (metadata.action === "vehicleData") {
                    const data = [{ label: translateText(155), value: metadata?.vehicleInfo?.displayName }]
                    if (metadata.vehicleInfo?.initialRegistration) {
                        data.push({ label: translateText(124), value: date(new Date(metadata?.vehicleInfo?.initialRegistration), "d") })
                    }
                    if (metadata?.vehicleInfo?.mileAge) {
                        data.push({ label: translateText(125), value: metadata?.vehicleInfo.mileAge })
                    }
                    if (metadata?.vehicleInfo?.plateId) {
                        data.push({ label: translateText(21), value: metadata?.vehicleInfo.plateId })
                    }
                    return (
                        <MessageMetaData
                            dateTime={date(new Date(message.inserted), "g")}
                            left={!isOwnMessage}
                            headline={translateText(3108)}
                            dataType="Vehicle"
                            data={data}
                        />
                    )
                }
                break
            default:
                break
        }
    }

    return (
        <MessageBubble left={!isOwnMessage} dateTime={date(new Date(message.inserted), "g")}>
            {text}
        </MessageBubble>
    )
}
