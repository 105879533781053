import { callService } from ".."
import { getBundleParams } from "../../../utils"

function getCarsServiceUrl() {
    return getBundleParams().carsServiceUrl
}

export function callCarsService<TReq, TRes>(url: string, request: TReq, method?: "GET" | "POST"): Promise<TRes | undefined> {
    return callService<TReq, TRes>(`${getCarsServiceUrl()}/${url}`, request, method)
}
