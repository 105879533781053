import { RequestArticleDetailsPayload, channel } from "@tm/models"
import { OpenPartsErpInfoRequest, PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"

export default function handleOpenPartsDetails(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openPartsDetails, openArticleByPartNumber, openPartsErpInfo } = data

    if (openPartsDetails || openArticleByPartNumber || openPartsErpInfo) {
        this.checkAndCreateWorktask()

        if (openArticleByPartNumber) {
            console.warn(
                "PostMessage API: openArticleByPartNumber is deprecated. Please use openPartsDetails instead. The data format can remain the same."
            )
        }

        const rawRequest = openPartsDetails || openArticleByPartNumber || openPartsErpInfo

        let mappedRequest: RequestArticleDetailsPayload

        if (rawRequest.supplierId && rawRequest.productGroupId && rawRequest.supplierArticleNo) {
            mappedRequest = {
                article: {
                    productGroupId: rawRequest.productGroupId,
                    supplierId: rawRequest.supplierId,
                    supplierArticleNo: rawRequest.supplierArticleNo,
                },
            }
        } else if (rawRequest.traderArticleNo) {
            mappedRequest = {
                article: {
                    traderArticleNo: rawRequest.traderArticleNo,
                },
            }
        } else {
            const requestName = openPartsDetails ? "openPartsDetails" : openArticleByPartNumber ? "openArticleByPartNumber" : "openPartsErpInfo"
            console.warn(`PostMessage API: ${requestName}: missing required information. Request: `, rawRequest)
            return
        }

        mappedRequest.inModal = rawRequest.inModal

        // If the request was openPartsErpInfo set the erp info subpage
        if (openPartsErpInfo) {
            mappedRequest.subPage = "stocks"
            mappedRequest.initialQuantity = (rawRequest as OpenPartsErpInfoRequest).initialQuantity
        }

        channel("WORKTASK").publish("PARTS/REQUEST_ARTICLE_DETAILS", mappedRequest)
    }
}
