import { useCallback, useMemo, useState } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { RegistrationNoType } from "@tm/models"
import { Icon } from "@tm/controls"
import { encodeUniqueId } from "@tm/utils"
import { LinkButton, styled, alpha, buttonClasses } from "@tm/components"
import { useLocalization } from "@tm/localization"
import RequestDialog from "./request-dialog"
import { getBundleParams } from "../../utils"

type DatVinRequestProps = {
    className?: string
}

function DatVinRequestComponent(props: DatVinRequestProps) {
    const { className } = props
    const { workTask } = useWorkTask()!
    const [dialogOpen, setDialogOpen] = useState(false)
    const { translate } = useLocalization()

    const handleClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleCloseDialog = useCallback(() => {
        setDialogOpen(false)
    }, [])

    const detailsDatUrl = useMemo(() => {
        if (!workTask) {
            return ""
        }
        const { vehicle } = workTask
        if (!vehicle) {
            return ""
        }
        return `/${encodeUniqueId(workTask.id)}/vehicles/details/${vehicle.vehicleType}/${encodeUniqueId(vehicle.id)}/datInfo`
    }, [workTask])

    if (!workTask?.vehicle || !getBundleParams().enableDATVinSearch) {
        return null
    }

    if (workTask.vehicle.registrationTypeId === RegistrationNoType.DatVin) {
        return (
            <div className={className}>
                <LinkButton to={detailsDatUrl} startIcon={<Icon name="dat-vin-data" />}>
                    {translate(13100)}
                </LinkButton>
            </div>
        )
    }

    return (
        <>
            <div className={`${className} link`} onClick={handleClick}>
                <div className="hint">
                    <Icon name="bulb" /> {translate(813)}:
                </div>
                <div className="text">
                    {translate(13095)} <Icon name="dat-vin-data" />
                </div>
            </div>
            <RequestDialog open={dialogOpen} onClose={handleCloseDialog} />
        </>
    )
}

const DatVinRequest = styled(DatVinRequestComponent)(({ theme }) => ({
    borderLeft: `1px solid #ccc`,
    padding: ".5em 1em",
    alignSelf: "stretch",
    [`.${buttonClasses.startIcon}`]: {
        marginLeft: 0,
    },
    "&.link": {
        cursor: "pointer",
        "&:hover": {
            background: alpha(theme.colors?.dark || "#202020", 0.1),
        },
    },
    ".icon": {
        fill: "currentColor",
        verticalAlign: "text-bottom",
    },
    ".hint": {
        color: theme.colors?.highlight,
        fontWeight: "bold",
        marginBottom: ".5em",
    },
}))

export default DatVinRequest
