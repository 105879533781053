import { useState, useEffect } from "react"
import { Button, MessageToast } from "@tm/controls"
import { copyToClipboard } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"

export default function Chat() {
    const { translateText } = useLocalization()
    const [success, setSuccess] = useState<boolean>()
    const [apiKey, setApiKey] = useState<string>()

    useEffect(() => {
        return channel("GLOBAL").subscribe(
            "NOTIFICATIONS/CHAT_TOKEN_LOADED",
            (token) => {
                setApiKey(token.apiKey)
            },
            true
        )
    }, [])

    useEffect(() => {
        if (success !== undefined) {
            setTimeout(() => {
                setSuccess(undefined)
            }, 3000)
        }
    }, [success])

    if (!apiKey) {
        return null
    }

    return (
        <>
            <Button layout={["holo"]} onClick={() => copyToClipboard(apiKey).then(() => setSuccess(true))}>
                {translateText(3137)}
            </Button>
            {success === true && (
                <MessageToast
                    skin="success"
                    message="In die Zwischenablage kopiert"
                    icon="success-dark"
                    onButtonClick={() => setSuccess(undefined)}
                />
            )}
        </>
    )
}
