import { CarManufacturer } from "@tm/models"
import { callCarsService } from "."
import { CarBodyType } from "../../model/filters"

type ManufacturersResponse = {
    topManufacturers: Array<CarManufacturer>
    primaryManufacturers: Array<CarManufacturer>
    secondaryManufacturers: Array<CarManufacturer>
    filters: ManufacturerFilters
}

type ManufacturerFilters = {
    bodyTypes: Array<CarBodyType>
}

type SelectedManufacturerFilters = {
    bodyTypeId?: number
}

type ShowTopManufacturersRequest = {
    selectedFilters?: SelectedManufacturerFilters
}

type ShowTopManufacturersResponse = {
    topManufacturers: Array<CarManufacturer>
}

export function showTopManufacturers(request: ShowTopManufacturersRequest) {
    return callCarsService<ShowTopManufacturersRequest, ShowTopManufacturersResponse>("ShowTopManufacturers", request)
}

type ShowAllManufacturersRequest = {
    selectedFilters?: SelectedManufacturerFilters
}

export function showAllManufacturers(request: ShowAllManufacturersRequest) {
    return callCarsService<ShowAllManufacturersRequest, ManufacturersResponse>("ShowAllManufacturers", request)
}

type ShowManufacturersByQueryRequest = {
    query: string
    selectedFilters?: SelectedManufacturerFilters
}

export function showManufacturersByQuery(request: ShowManufacturersByQueryRequest) {
    return callCarsService<ShowManufacturersByQueryRequest, ManufacturersResponse>("ShowManufacturersByQuery", request)
}

type ShowManufacturersByArticleRequest = {
    articleId: number
    selectedFilters?: SelectedManufacturerFilters
}

export function showManufacturersByArticle(request: ShowManufacturersByArticleRequest) {
    return callCarsService<ShowManufacturersByArticleRequest, ManufacturersResponse>("ShowManufacturersByArticle", request)
}

type ShowManufacturerDetailsRequest = {
    manufacturerId: number
}

type ShowManufacturerDetailsResponse = {
    manufacturerDetails: CarManufacturer
}

export function showManufacturerDetails(request: ShowManufacturerDetailsRequest) {
    return callCarsService<ShowManufacturerDetailsRequest, ShowManufacturerDetailsResponse>("ShowManufacturerDetails", request)
}
