import { useLocalization } from "@tm/localization"
import { MergedVehicle } from "../../../../../../data/hooks"
import { getDateFromMetadata } from "../appointment-info"
import { getBundleParams } from "../../../../../../utils"
import { addDaysToDate, getDateWithoutTime } from "../../../../../_shared/helper"
import WideButton from "../../wide-button"
import { openWorkTask } from "../../../../../../bundle-channel"

type Props = {
    vehicle: MergedVehicle
}

export default function VehicleFastcheckInfo({ vehicle }: Props) {
    const { translateText, date } = useLocalization()
    const route = getBundleParams().fastCheckRouteWithoutWorkTaskId
    const { fastCheck, appointment } = vehicle

    if (!route) {
        return null
    }

    const appointmentDate = appointment && getDateWithoutTime(getDateFromMetadata(appointment))
    const status =
        appointmentDate &&
        fastCheck &&
        getDateWithoutTime(fastCheck.inserted) >= addDaysToDate(appointmentDate, -1) &&
        getDateWithoutTime(fastCheck.inserted) <= addDaysToDate(appointmentDate, 28)

    if (!fastCheck?.attachment?.uri || !status) {
        const handleClick = () => {
            openWorkTask({ selectedChatVehicle: vehicle, subRoute: route })
        }

        return <WideButton text={translateText(12734)} onClick={handleClick} icon="fast-check" />
    }

    return (
        <WideButton
            text={`${translateText(12733)} (${date(new Date(fastCheck.inserted), "d")})`}
            icon="fast-check"
            linkTo={fastCheck.attachment.uri}
        />
    )
}
