import { IBundle } from "@tm/morpheus"
import CategorySelection from "./components/category-selection"
import RepairTimesSelection from "./components/repair-times-selection"
import DirectSearch from "./components/direct-search"
import Header from "./components/header"
import AssociatedRepairTimes from "./components/associated-repair-times"
import ProviderDropdown from "./components/provider-dropdown"
import RequestRepairTimes from "./components/request-repair-times"
import { initRepairTimesBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [CategorySelection, RepairTimesSelection, DirectSearch, Header, AssociatedRepairTimes, ProviderDropdown, RequestRepairTimes],
    init: initRepairTimesBundle,
}

export default bundle
