import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { mapDatCalculationData } from "./mappers"
import { GetCalculationDataRawResponse, GetCalculationDataRequest } from "./model"

export function getCalculationData(calculationDataRequest: GetCalculationDataRequest) {
    const url = `${getServiceUrl()}/GetCalculationData`
    const authorization = getStoredAuthorization()
    const body = calculationDataRequest

    return ajax<GetCalculationDataRawResponse>({ url, body, method: "POST", authorization }).then((response) => {
        if (!response?.calculationData?.errorMessage && response?.calculationData) {
            return mapDatCalculationData(response.calculationData)
        }
        throw new Error(response?.calculationData?.errorMessage)
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.asWegasServiceUrl
}
