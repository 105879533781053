import { ArticleQuantities, ConfigParams, ListOptions, WholesalerArticle, WholesalerArticleDto, WholesalerArticleItemState } from "@tm/models"
import { ArticleItem, ArticleItemStateProvider } from "@tm/components"
import { useCallback, useMemo, useState } from "react"
import { isEqual } from "lodash"
import Morpheus from "@tm/morpheus"
import { useHandleRemoveFromBasket } from "../ListV2/hooks/useArticleItem/useHandleRemoveFromBasket"
import AvailabilityComponent from "../../../../erp/src/_shared/availability-wrapper"
import { useHandleAddWholesalerArticleToBasket } from "../ListV2/hooks/useArticleItem/useHandleAddWholesalerPartToBasket"

type WholesalerArticleItemProps = {
    article: WholesalerArticle
    basketQuantities: ArticleQuantities<WholesalerArticleDto>[]
    updateBasketQuantities(articles: WholesalerArticle[]): void
    options: ListOptions
}

export function WholesalerArticleItem({ article, basketQuantities, updateBasketQuantities, options }: WholesalerArticleItemProps) {
    const [articleQuantity, setArticleQuantity] = useState(article.quantity?.value ?? 1)
    const [originalQuantity] = useState(article.quantity?.value ?? 1)
    const addToBasket = useHandleAddWholesalerArticleToBasket(article)
    const removeFromBasket = useHandleRemoveFromBasket()

    const basketQuantity = useMemo(
        () =>
            basketQuantities.find((q) =>
                isEqual(q.article, {
                    wholesalerArticleNumber: article.wholesalerArticleNumber,
                } as WholesalerArticleDto)
            ),
        [basketQuantities, article]
    )

    const postAddToBasket = useCallback(async () => {
        updateBasketQuantities([article])
    }, [article, updateBasketQuantities])

    const handleAddToBasket = useCallback(
        async (quantity?: number) => {
            await addToBasket(quantity ?? articleQuantity ?? 1)
            postAddToBasket()
        },
        [addToBasket, articleQuantity, postAddToBasket]
    )

    const handleRemoveFromBasket = useCallback(
        async (itemIds?: string[]) => {
            const ids = itemIds ?? basketQuantity?.articleQuantities?.allPartItemIds
            if (ids?.length) {
                await removeFromBasket(ids)
                updateBasketQuantities([article])
            }
        },
        [basketQuantity, removeFromBasket, updateBasketQuantities, article]
    )

    const handleChangeQuantity = useCallback((value: number) => {
        setArticleQuantity(value)
    }, [])

    const articleState = useMemo<WholesalerArticleItemState>(
        () => ({
            article,
            options,
            quantity: articleQuantity ?? 1,
            type: "WholesalerArticle",
            basketQuantity,
            originalQuantity,
            isVehicleDependent: false,

            addToBasketButtonDisabled: false,
            addToCostEstimationButtonDisabled: false,

            handleAddToBasket,
            handleChangeQuantity,
            handleRemoveFromBasket,

            AvailabilityComponent,
        }),
        [article, options, articleQuantity, basketQuantity, originalQuantity, handleAddToBasket, handleChangeQuantity, handleRemoveFromBasket]
    )

    return (
        <ArticleItemStateProvider value={articleState}>
            <ArticleItem variant="WholesalerArticle" />
        </ArticleItemStateProvider>
    )
}
