import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit } from "./business"
import component from "./component"

const MainCategorySelection: BundleComponent = {
    name: "category-selection",
    reduce,
    component,
    transmit,
}

export default MainCategorySelection
