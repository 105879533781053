export function getTimestamp(dateStr: string): number {
    return new Date(dateStr).getTime()
}

interface DateObj {
    createDate: string
}

export function sortByCreatedAtDesc(dateA: DateObj, dateB: DateObj): number {
    return getTimestamp(dateB.createDate) - getTimestamp(dateA.createDate)
}
