let bundleParams: {
    serviceUrl: string
}

export const version = {
    name: "wm",
    version: "0.1.0",
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function initTraderWmBundle(params: any) {
    bundleParams = params
    return bundleParams
}
