import { PropsWithChildren } from "react"
import { Collapsible, Card } from "@tm/controls"
import { Box } from "@tm/components"
import { StyledStack } from "./StyledComponents"

type Props = PropsWithChildren<{}> & {
    name: string
}

export default function AreaComponent({ name, children }: Props) {
    return (
        // Card from controls used beacuse the border primary on hover is missing from components
        <Card>
            <Collapsible name={name} initiallyOpened>
                {/* Box required beacuse the control's card has no padding, when the Card from Components is used this should be removed. */}
                <Box px={1}>
                    <StyledStack>{children}</StyledStack>
                </Box>
            </Collapsible>
        </Card>
    )
}
