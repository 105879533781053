import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { UpdateTotalPricesRequest, UpdateTotalPricesResponse } from "./model"

export * from "./model"

export function updateTotalsPrices(calculationDataRequest: UpdateTotalPricesRequest) {
    const url = `${getServiceUrl()}/UpdateTotalPrices`
    const authorization = getStoredAuthorization()
    const body = calculationDataRequest

    return new Promise<UpdateTotalPricesResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => resolve(response.updatedTotals), reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.businessUrl
}
