import { Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { classes, RouteComponentProps, withRouter } from "@tm/utils"

import { useAvailableFilters, useSetFilters } from "../../data/hooks"
import { FilterType, useClippedFilters, useOpenedFilters } from "./business"
import BodyTypes from "./components/bodytypes"
import EngineCapacities from "./components/enginecapacities"
import EngineCodes from "./components/enginecodes"
import FuelTypes from "./components/fueltypes"
import ModelYears from "./components/modelyears"
import SortingFilter from "./components/sorting"

type Props = RouteComponentProps<{ view: string; vehicleType: string }> & {
    clipped?: boolean
    showSorting?: boolean
}

function FilterComponent(props: Props) {
    if (props.match.params.view === "details") {
        return null
    }

    const clipped = props.clipped || false
    const vehicleType: VehicleType = parseInt(props.match.params.vehicleType)
    const { toggleFilterClip, isFilterClipped } = useClippedFilters(vehicleType)
    const { toggleFilterOpen, isFilterOpened } = useOpenedFilters(clipped)
    const [availableFilters] = useAvailableFilters(vehicleType)
    const setFilters = useSetFilters(vehicleType)
    const { translateText } = useLocalization()

    const renderSorting = () => {
        const filterType = FilterType.Sorting
        const isClipped = isFilterClipped(filterType)

        if (clipped !== isClipped) {
            return
        }

        return (
            <SortingFilter
                vehicleType={vehicleType}
                clipped={isClipped}
                opened={isFilterOpened(filterType)}
                onToggleClip={() => toggleFilterClip(filterType)}
                onToggleOpen={() => toggleFilterOpen(filterType)}
            />
        )
    }

    const renderBodyTypes = () => {
        const filterType = FilterType.BodyType
        const isClipped = isFilterClipped(filterType)

        if (clipped !== isClipped) {
            return
        }

        return (
            <BodyTypes
                items={availableFilters.bodyTypes}
                clipped={isClipped}
                opened={isFilterOpened(filterType)}
                onChangeFilter={(bodyTypeId) => setFilters((curr) => ({ ...curr, bodyTypeId }))}
                onToggleClip={() => toggleFilterClip(filterType)}
                onToggleOpen={() => toggleFilterOpen(filterType)}
            />
        )
    }

    const renderModelYears = () => {
        const filterType = FilterType.ModelYear
        const isClipped = isFilterClipped(filterType)

        if (clipped !== isClipped) {
            return
        }

        return (
            <ModelYears
                items={availableFilters.modelYears}
                clipped={isClipped}
                opened={isFilterOpened(filterType)}
                onChangeFilter={(modelYear) => setFilters((curr) => ({ ...curr, modelYear }))}
                onToggleClip={() => toggleFilterClip(filterType)}
                onToggleOpen={() => toggleFilterOpen(filterType)}
            />
        )
    }

    const renderFuelTypes = () => {
        const filterType = FilterType.FuelType
        const isClipped = isFilterClipped(filterType)

        if (clipped !== isClipped) {
            return
        }

        return (
            <FuelTypes
                items={availableFilters.fuelTypes}
                clipped={isClipped}
                opened={isFilterOpened(filterType)}
                onChangeFilter={(fuelType) => setFilters((curr) => ({ ...curr, fuelType }))}
                onToggleClip={() => toggleFilterClip(filterType)}
                onToggleOpen={() => toggleFilterOpen(filterType)}
            />
        )
    }

    const renderEngineCodes = () => {
        const filterType = FilterType.EngineCode
        const isClipped = isFilterClipped(filterType)

        if (clipped !== isClipped) {
            return
        }

        return (
            <EngineCodes
                items={availableFilters.engineCodes}
                clipped={isClipped}
                opened={isFilterOpened(filterType)}
                onChangeFilter={(engineCode) => setFilters((curr) => ({ ...curr, engineCode }))}
                onToggleClip={() => toggleFilterClip(filterType)}
                onToggleOpen={() => toggleFilterOpen(filterType)}
            />
        )
    }

    const renderEngineCapacity = () => {
        const filterType = FilterType.EngineCapacity
        const isClipped = isFilterClipped(filterType)

        if (clipped !== isClipped) {
            return
        }

        return (
            <EngineCapacities
                items={availableFilters.engineCapacities}
                clipped={isClipped}
                opened={isFilterOpened(filterType)}
                onChangeFilter={(engineCapacity) => setFilters((curr) => ({ ...curr, engineCapacity }))}
                onToggleClip={() => toggleFilterClip(filterType)}
                onToggleOpen={() => toggleFilterOpen(filterType)}
            />
        )
    }

    if (
        clipped !== isFilterClipped(FilterType.BodyType) &&
        clipped !== isFilterClipped(FilterType.ModelYear) &&
        clipped !== isFilterClipped(FilterType.FuelType) &&
        clipped !== isFilterClipped(FilterType.EngineCapacity) &&
        clipped !== isFilterClipped(FilterType.EngineCode) &&
        clipped !== isFilterClipped(FilterType.Sorting)
    ) {
        return null
    }

    return (
        <Toolbar
            title={!clipped ? translateText(209) : undefined}
            modifiers={clipped ? ["no-border"] : []}
            className={classes("tk-vehicle", "filters", clipped && "filters--clipped")}
        >
            {renderSorting()}
            {renderBodyTypes()}
            {renderModelYears()}
            {renderFuelTypes()}
            {renderEngineCodes()}
            {renderEngineCapacity()}
        </Toolbar>
    )
}

export default withRouter(FilterComponent)
