import { useLocalization } from "@tm/localization"
import { useFastServiceStore } from "../../data"

export const useRepairTimes = () => {
    const repairTimes = useFastServiceStore((state) => state.maintenancePlan.workTimes)
    const { translateText } = useLocalization()

    const { inspectionWorkTimes, additionalWorkTimes, followUpWorkTimes } = repairTimes
    const totalRepairTimes = [...inspectionWorkTimes, ...additionalWorkTimes, ...followUpWorkTimes]

    let totalRepairTimesValue = 0
    let repairTimesIncludesString = ""

    if (totalRepairTimes?.length) {
        totalRepairTimesValue = totalRepairTimes.reduce((sum, repairTime) => sum + (repairTime?.quantity || 0), 0)
    }

    const totalRepairTimesFormatted = totalRepairTimesValue ? totalRepairTimesValue.toFixed(2) : null

    if (additionalWorkTimes?.length || followUpWorkTimes?.length) {
        repairTimesIncludesString += translateText(13390)
    }

    if (additionalWorkTimes?.length) {
        repairTimesIncludesString += ` ${translateText(644)}`
    }

    if (additionalWorkTimes?.length && followUpWorkTimes?.length) {
        repairTimesIncludesString += ","
    }

    if (followUpWorkTimes?.length) {
        repairTimesIncludesString += ` ${translateText(797)}`
    }

    return { totalRepairTimesFormatted, repairTimesIncludesString }
}
