import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { PanelSection } from "@tm/controls"
import { ArticleReference, ArticleReferenceType, SearchFilters, Article } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { concat } from "@tm/utils"
import { ReferenceNumbers } from "./components/ReferenceNumbers"

type Props = {
    article: Article
    className?: string
    onReferenceClicked?(): void
}

export default function ReferencesComponent({ article, className, onReferenceClicked }: Props) {
    const { translate } = useLocalization()
    const manufacturerId = useWorkTask()?.workTask?.vehicle?.tecDocManufacturerId

    const oeNumbers = useMemo(() => {
        return article.references
            .filter((x) => x.referenceType === ArticleReferenceType.OeReferenceNo)
            .orderBy((x) => x.manufacturer ?? "")
            .sort((a, b) => {
                if (!manufacturerId) {
                    if (a.manufacturerId === manufacturerId && b.manufacturerId !== manufacturerId) {
                        return -1
                    }

                    if (b.manufacturerId === manufacturerId && a.manufacturerId !== manufacturerId) {
                        return 1
                    }
                }

                return 0
            })
            .groupBy((x) => x.manufacturer ?? "")
    }, [article.references, manufacturerId])

    const { replacedByArticleNumbers, replacementForArticleNumbers, utilityNumbers, otherRefGroups } = useMemo(() => {
        const replacedBy: ArticleReference[] = []
        const replacementFor: ArticleReference[] = []
        const utility: ArticleReference[] = []
        const other: ArticleReference[] = []

        article.references.forEach((x) => {
            switch (x.referenceType) {
                case ArticleReferenceType.ReplacedByArticleNo:
                    replacedBy.push(x)
                    break
                case ArticleReferenceType.ReplacementArticleNo:
                    replacementFor.push(x)
                    break
                case ArticleReferenceType.UtilityNo:
                    utility.push(x)
                    break
                default:
                    if (x.referenceType !== ArticleReferenceType.None && x.referenceType != ArticleReferenceType.OeReferenceNo) {
                        other.push(x)
                    }
                    break
            }
        })

        return {
            replacedByArticleNumbers: replacedBy,
            replacementForArticleNumbers: replacementFor,
            utilityNumbers: utility,
            otherRefGroups: other.groupBy((x) => x.description),
        }
    }, [article.references])

    function renderOeNumberGroup(references: ArticleReference[], key: number) {
        if (!references.length) {
            return
        }

        return (
            <PanelSection key={references[0].manufacturer || key} title={concat(" ", references[0].manufacturer, references[0].description)} size="s">
                <ReferenceNumbers
                    references={references}
                    searchFilter={SearchFilters.OeReference}
                    productGroupId={article.productGroup.id}
                    supplierId={article.supplier.id}
                    onReferenceClicked={onReferenceClicked}
                />
            </PanelSection>
        )
    }

    if (!article.references) {
        return <div className="headline headline--s">{translate(391)}</div>
    }

    return (
        <div className={`article-references ${className || ""}`}>
            {!!utilityNumbers.length && (
                <PanelSection title={utilityNumbers[0].description} size="s">
                    <ReferenceNumbers
                        references={utilityNumbers}
                        searchFilter={SearchFilters.UtilityNo | SearchFilters.OeReference}
                        productGroupId={article.productGroup.id}
                        supplierId={article.supplier.id}
                        onReferenceClicked={onReferenceClicked}
                    />
                </PanelSection>
            )}
            {(!!replacementForArticleNumbers.length || !!replacedByArticleNumbers.length) && (
                <PanelSection title={translate(393)} size="s">
                    {!!replacementForArticleNumbers.length && (
                        <div>
                            <div className="headline headline--xs">{replacementForArticleNumbers[0].description}:</div>
                            <ReferenceNumbers
                                references={replacementForArticleNumbers}
                                shouldOpenDetails
                                productGroupId={article.productGroup.id}
                                supplierId={article.supplier.id}
                                onReferenceClicked={onReferenceClicked}
                            />
                        </div>
                    )}
                    {!!replacedByArticleNumbers.length && (
                        <div>
                            <div className="headline headline--xs">{replacedByArticleNumbers[0].description}:</div>
                            <ReferenceNumbers
                                references={replacedByArticleNumbers}
                                shouldOpenDetails
                                productGroupId={article.productGroup.id}
                                supplierId={article.supplier.id}
                                onReferenceClicked={onReferenceClicked}
                            />
                        </div>
                    )}
                </PanelSection>
            )}

            {Object.values(oeNumbers).map(renderOeNumberGroup)}

            {Object.entries(otherRefGroups).map(([referenceDescription, references]) => (
                <PanelSection title={referenceDescription} size="s" key={referenceDescription}>
                    <ReferenceNumbers
                        references={references}
                        productGroupId={article.productGroup.id}
                        supplierId={article.supplier.id}
                        onReferenceClicked={onReferenceClicked}
                    />
                </PanelSection>
            ))}
        </div>
    )
}
