import { Articles } from "@tm/data"
import * as Models from "../model"

export function mapCriterionFilterGroup(data: any): Models.UniParts.CriterionFilterGroup | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        criterionFilters: data.criterionFilters || [],
    }
}

export function mapPartsListResponse(data: any): Models.GetPartsListResponse | undefined {
    if (!data) {
        return
    }
    return {
        ...data,
        articles: data.articles ? data.articles.map(Articles.mapArticle) : [],
        imageCoordinates: data.imageCoordinates || [],
    }
}

export function mapGetRelatedArticleResponse(data: any): Models.GetRelatedArticlesResponse | undefined {
    if (!data) {
        return
    }
    return {
        crossSellingArticles: data && data.crossSellingArticles ? data.crossSellingArticles.map(Articles.mapArticle) : [],
        universalArticles: data && data.universalArticles ? data.universalArticles.map(Articles.mapArticle) : [],
        recommendedArticles: data && data.recommendedArticles ? data.recommendedArticles.map(Articles.mapArticle) : [],
        toolKits: data && data.toolKits ? data.toolKits.map(Articles.mapArticle) : [],
    }
}
