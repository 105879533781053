import { CSSProperties } from "react"
import { BundleTemplate, TemplateRenderer } from "@tm/morpheus"
import { IWholesalerItemTemplate } from "@tm/models"

const Style: { [key: string]: CSSProperties } = {
    ArticleListItemContent: {
        flex: "1",
    },
    ArticleNumbersAndSupplierContainer: {
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
    },
    ArticleErpInformation: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: "4em",
    },
    ArticleErpInformationLeft: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "0.25em",
    },
    ArticleErpInformationRight: {
        flex: "1",
        textAlign: "right",
    },
    ArticleBuyActions: {
        display: "flex",
        flexDirection: "column",
        marginTop: "2em",
        position: "relative",
    },
    ArticleSaleIcon: {
        position: "absolute",
        right: "0",
        top: "0", // match ArticleBuyActions marginTop (but negative)
        height: "2rem", // match ArticleBuyActions marginTop
    },
    ArticleSelectorAndCostEstimationContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "0.25em",
    },
}

export const WholesalerItemTemplate: BundleTemplate<IWholesalerItemTemplate & TemplateRenderer> = {
    name: "wholesaler-item",
    targetComponent: {
        bundle: "parts",
        component: "wholesaler-item",
    },
    render(r: IWholesalerItemTemplate) {
        const className = r.getQualifiedClassName()

        const content = (
            <div className={className}>
                <div className="thumbnail_supplier-image">{r.renderThumbnail()}</div>
                <div style={Style.ArticleListItemContent}>
                    <div style={Style.ArticleNumbersAndSupplierContainer}>
                        {r.renderNumbers()}
                        <div style={Style.ArticleSaleIcon}>{r.renderSale()}</div>
                    </div>

                    {r.renderDescription()}
                    {r.renderAdditionalActionsAsRowWithoutMore(true)}
                </div>
                <div className="article-list__item__buy">
                    <div style={Style.ArticleErpInformation}>
                        <div style={Style.ArticleErpInformationLeft}>{r.renderAvailability()}</div>
                        <div style={Style.ArticleErpInformationRight}>
                            {r.renderPrices(false)}
                            {r.renderQuantityUnit()}
                        </div>
                    </div>
                    <div style={Style.ArticleBuyActions}>
                        {r.renderBuyActions(true)}
                        <div className="additional-actions" style={Style.ArticleSelectorAndCostEstimationContainer}>
                            {r.renderAddToCompilations?.()}
                            {r.renderCostEstimationButton()}
                        </div>
                    </div>
                </div>
                <div className="article-list__item__selector">{r.renderSelector()}</div>
            </div>
        )

        return content
    },
}
