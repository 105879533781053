import { useLocalization } from "@tm/localization"
import { StatusSwitch } from "@tm/controls"
import { Button, Icon, Paper, Stack, Typography } from "@tm/components"
import { useCentralOrderWorkTasks } from "../../../data/hooks/centralOrder/useCentralOrderWorkTasks"

export default function WorkTasksSelectionComponent() {
    const { translateText } = useLocalization()
    const {
        state: { selectedWorkTaskIds, includedWorkTaskIds, allWorkTasksSelected, expandedWorkTaskIds, selectedDisabledWorkTaskIds },
        selectAll,
        setWorkTaskStatusByList,
    } = useCentralOrderWorkTasks()
    if (!selectedWorkTaskIds.length) {
        return null
    }

    function handleOrderStatusSwitchChange() {
        const includeInOrder = !selectedWorkTaskIds.some((id) => includedWorkTaskIds.includes(id))
        setWorkTaskStatusByList(selectedWorkTaskIds, "includedInOrder", includeInOrder)
    }

    function handleCompcatViewSwitchChange() {
        const expand = !selectedWorkTaskIds.some((id) => expandedWorkTaskIds.includes(id))
        setWorkTaskStatusByList(selectedWorkTaskIds, "expanded", expand)
    }

    function handleSelectButtonClick() {
        selectAll(!allWorkTasksSelected)
    }

    function handleCancelButtonClick() {
        selectAll(false)
    }

    function renderSelectButton() {
        let buttonText = translateText(702)

        if (allWorkTasksSelected) {
            buttonText = translateText(703)
        }

        return <Button onClick={handleSelectButtonClick}>{buttonText}</Button>
    }

    function renderUpdateOrderStatusSwitch() {
        const workTasksIncludedInOrder = selectedWorkTaskIds.some((id) => includedWorkTaskIds.includes(id))
        const equalStatus = !(
            selectedWorkTaskIds.some((id) => includedWorkTaskIds.includes(id)) && selectedWorkTaskIds.some((id) => !includedWorkTaskIds.includes(id))
        )

        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (workTasksIncludedInOrder && equalStatus) {
            status = "checked"
        } else if (workTasksIncludedInOrder && !equalStatus) {
            status = "checked-not-equal"
        } else if (!workTasksIncludedInOrder && equalStatus) {
            status = "unchecked"
        } else if (!workTasksIncludedInOrder && !equalStatus) {
            status = "unchecked-not-equal"
        }

        return (
            <StatusSwitch
                label={translateText(772)}
                alignLabel="left"
                status={status}
                disabled={selectedDisabledWorkTaskIds.length > 0}
                onChange={handleOrderStatusSwitchChange}
            />
        )
    }

    function renderCompactModeSwitch() {
        let workTasksExpanded = false
        let equalStatus = true
        if (expandedWorkTaskIds.length) {
            workTasksExpanded = true
            selectedWorkTaskIds.forEach((id) => {
                if (!expandedWorkTaskIds.includes(id)) {
                    equalStatus = false
                }
            })
        }

        let status: "checked" | "checked-not-equal" | "unchecked" | "unchecked-not-equal" = "unchecked"
        if (workTasksExpanded && equalStatus) {
            status = "unchecked"
        } else if (workTasksExpanded && !equalStatus) {
            status = "unchecked-not-equal"
        } else if (!workTasksExpanded && equalStatus) {
            status = "checked"
        } else if (!workTasksExpanded && !equalStatus) {
            status = "checked-not-equal"
        }

        return <StatusSwitch label={translateText(3009)} alignLabel="left" status={status} onChange={handleCompcatViewSwitchChange} />
    }

    return (
        <Paper elevation={3} sx={{ padding: "0 0 0 1rem" }}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Typography variant="label">{translateText(701).toUpperCase()}: </Typography>
                <Typography variant="label">
                    {selectedWorkTaskIds.length} {translateText(1142)}
                </Typography>
                {renderSelectButton()}
                {renderCompactModeSwitch()}
                {renderUpdateOrderStatusSwitch()}
                <Button
                    size="large"
                    color="primary"
                    startIcon={<Icon name="cancel-x" />}
                    title={translateText(317)}
                    onClick={handleCancelButtonClick}
                />
            </Stack>
        </Paper>
    )
}
