import { MileType, TelematicsProvider, Vehicle } from "@tm/models"
import { useQuery } from "react-query"
import * as Data from "../../../data"

export function useTelematicsDataSummary(provider: TelematicsProvider, vehicle: Vehicle | undefined) {
    const { data, isLoading, refetch } = useQuery(
        ["telematics_useTelematicsDataSummary", provider, vehicle?.vin],
        () => {
            if (vehicle?.vin) {
                return Data.showTelematicsDataSummary(provider, vehicle.vin)
            }
        },
        {
            // This query should be executed manually, so we disable automatic fetching
            enabled: false,
            onSuccess: (response) => {
                if (response?.mileage && vehicle && response.mileage.value !== vehicle.mileAge) {
                    Data.updateVehicle({
                        ...vehicle,
                        mileAge: response.mileage.value,
                        mileType: MileType.Kilometer,
                    })
                }
            },
        }
    )

    return { summary: data, summaryLoading: isLoading, loadSummary: refetch }
}
