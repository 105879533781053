import * as React from "react"
import { NestedCSSProperties } from "typestyle/lib/types"
import { getStyleTheme } from "@tm/context-distribution"
import { Loader, RealTable, RealTableColumnProps, Text } from "@tm/controls"
import { concat } from "@tm/utils"
import { HorizontalRule } from "../../../_shared/horizontal-rule"

export function getComponentStyles(compact?: boolean): NestedCSSProperties {
    const { margin, font, opacity } = getStyleTheme()

    return {
        flex: 1,
        display: "flex",
        flexDirection: "column",
        $nest: {
            "&__hr": {
                minHeight: "32px" /** @todo improve this */,
            },
            "&__loader": {
                marginLeft: margin.l,
            },
            "&__deselect-button": {
                $nest: {
                    ".btn__icon": {
                        padding: margin.s,
                        opacity: opacity.secondary,
                    },
                },
            },
            "&__table-wrapper": {
                flex: 1,
                margin: `0 -${margin.s}`, // Offset padding of child
                position: "relative",
                overflowY: "auto",
            },
            "&__table": {
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                padding: `0 ${margin.s}`,
                borderSpacing: "0 2px" /** @todo margin.xxs */,
                $nest: {
                    ".real-table__head": {
                        fontSize: font.textSize.m,
                    },
                    ".real-table__cell": {
                        padding: !compact ? `${margin.s} calc(${margin.xl} * 2)` : `${margin.s} ${margin.m}`,
                        $nest: {
                            "&:first-child": {
                                paddingLeft: !compact ? margin.xl : margin.m,
                                borderLeftWidth: "5px",
                            },
                            "&:last-child": {
                                paddingRight: !compact ? margin.xl : margin.m,
                            },
                        },
                    },
                },
            },
            "&__header": {
                textTransform: "uppercase",
            },
            "&__no-items-message": {
                marginTop: margin.xl,
                textAlign: "center",
            },
            "&__item": {},
            "&__item--clickable": {
                cursor: "pointer",
            },
            "&__column": {
                minWidth: !compact ? "8em" : undefined,
                wordBreak: "break-word",
                $nest: {
                    "&--align-right": {
                        textAlign: "right",
                    },
                    "&--nowrap": {
                        whiteSpace: "nowrap",
                    },
                },
            },
            "&__cell": {
                position: "relative",
                $nest: {
                    "&:not(:last-child)::after": {
                        content: '""',
                        position: "absolute",
                        top: "-1px",
                        right: "0",
                        width: "2px",
                        height: "15px",
                        opacity: opacity.disabled,
                        backgroundColor: "#979797" /** @todo color? */,
                    },
                },
            },
            "&__actions": {
                display: "inline-flex",
                alignItems: "center",
                minHeight: "2em", // rows without action buttons should not be so slim
            },
        },
    }
}

type Props<T> = {
    className: string
    items: Array<T>
    columns: Array<React.ReactElement<RealTableColumnProps>>
    onSelect?(item: T): void
    loading?: boolean
    label?: string
    rightHorizontalRuleContent?: React.ReactNode
    noItemsMessage?: string
    getRowClassName?(item: T, rowIndex: number): string | undefined
    onLoadNextItems?(): void
}

export function Selection<T>(props: Props<T>) {
    const { items, loading, className, onLoadNextItems } = props

    const scrollTimeout = React.useRef<number>()
    const handleScroll = React.useCallback(
        (e: React.UIEvent) => {
            const {
                currentTarget: { scrollHeight, scrollTop, clientHeight },
            } = e

            window.clearTimeout(scrollTimeout.current)
            scrollTimeout.current = window.setTimeout(() => {
                if (loading) {
                    return
                }

                if (scrollHeight - scrollTop - clientHeight < 50) {
                    onLoadNextItems?.()
                }
            }, 250)
        },
        [loading, onLoadNextItems]
    )

    const getRowClassName = (item: T, rowIndex: number) => {
        return concat(
            " ",
            `${className}__item`,
            props.onSelect && `${className}__item--clickable`,
            rowIndex % 2 === 0 ? "real-table__row--light" : undefined,
            props.getRowClassName?.(item, rowIndex)
        )
    }

    const renderContent = () => {
        if (items.length) {
            return (
                <div className={`${className}__table-wrapper`} onScroll={onLoadNextItems ? handleScroll : undefined}>
                    <RealTable
                        data={items}
                        columns={props.columns}
                        onClickRow={props.onSelect}
                        className={`${className}__table`}
                        getRowClassName={getRowClassName}
                        // scrollable
                    />
                </div>
            )
        }
        if (!loading && props.noItemsMessage) {
            return <Text className={`${className}__no-items-message`}>{props.noItemsMessage}</Text>
        }
    }

    return (
        <div className={className}>
            <HorizontalRule
                className={`${className}__hr`}
                left={
                    <>
                        {props.label && <Text size="l">{props.label}</Text>}
                        <Loader visible={loading} className={`${className}__loader`} />
                    </>
                }
                right={props.rightHorizontalRuleContent}
            />

            {renderContent()}
        </div>
    )
}
