import { Box } from "@tm/components"
import { CisVoucherType } from "@tm/models"
import { useParams } from "react-router"
import { Voucher } from "../../../../data/model"
import { RowStackWithPadding, VerticalDivider } from "../../../_shared/StyledComponents"
import { CALCULATED_HEIGHT, COMPACT_WIDTH, FULL_WIDTH, VoucherRouteProps } from "../../component"
import StandardVouchersTable from "./voucher-tables/StandardVouchersTable"
import StandardVoucherDetails from "./StandardVoucherDetails"
import { CisDisplayMode } from "../../../../business/model"
import PickupInvoicesTable from "./voucher-tables/PickupInvoicesTable"

type Props = {
    cisDisplayMode?: CisDisplayMode
    endOfList: boolean
    openedConnectedVouchers: Array<string>
    voucherTypeId: number
    vouchers?: Array<Voucher>
    loadNextPage(): void
    onExpandRowClick?(voucherId: string): void
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function StandardVouchersComponent(props: Props) {
    const { voucherTypeId, vouchers, cisDisplayMode, openedConnectedVouchers, endOfList, onOpenNewVoucher, loadNextPage, onExpandRowClick } = props
    const matchParams = useParams<VoucherRouteProps>()
    const selectedVoucherId = matchParams.id ? decodeURIComponent(matchParams.id) : undefined
    let table = <></>
    switch (voucherTypeId) {
        case CisVoucherType.PickupInvoices:
            table = (
                <PickupInvoicesTable
                    compact={!!matchParams.id}
                    vouchers={vouchers as Array<Voucher>}
                    voucherTypeId={voucherTypeId}
                    selectedVoucherId={selectedVoucherId}
                    variant={matchParams.id ? "small" : "normal"}
                    loadNextPage={loadNextPage}
                    onOpenNewVoucher={onOpenNewVoucher}
                />
            )
            break
        default:
            table = (
                <StandardVouchersTable
                    compact={!!matchParams.id}
                    openedConnectedVouchers={openedConnectedVouchers}
                    selectedVoucherId={selectedVoucherId}
                    variant={matchParams.id ? "small" : "normal"}
                    voucherTypeId={voucherTypeId}
                    vouchers={vouchers as Array<Voucher>}
                    onExpandRowClick={onExpandRowClick}
                    loadNextPage={loadNextPage}
                    onOpenNewVoucher={onOpenNewVoucher}
                />
            )
            break
    }

    return (
        <RowStackWithPadding divider={<VerticalDivider />}>
            <Box width={matchParams.id ? COMPACT_WIDTH : FULL_WIDTH} height={CALCULATED_HEIGHT}>
                {table}
            </Box>
            {!!matchParams.id && (
                <StandardVoucherDetails
                    endOfList={endOfList}
                    selectedVoucherId={decodeURIComponent(matchParams.id)}
                    voucherTypeId={voucherTypeId}
                    vouchers={vouchers as Array<Voucher>}
                    cisDisplayMode={cisDisplayMode}
                    onOpenNewVoucher={onOpenNewVoucher}
                />
            )}
        </RowStackWithPadding>
    )
}
