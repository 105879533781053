import { GetArticlesRequest } from "@tm/models"
import { QueryFunctionContext } from "react-query"
import * as PartsRepository from "../../../data/repositories/parts"
import { ArticlePage, QueryKey } from "../hooks/useArticlesBase"

export async function fetchDirectSearchOrVehiclePartsArticles({
    queryKey: [, , request],
    pageParam,
}: QueryFunctionContext<QueryKey<GetArticlesRequest>, number>): Promise<ArticlePage> {
    if (!request) {
        return Promise.reject()
    }

    const articleResponse = await PartsRepository.getArticlesWithPageInfo({
        ...request,
        pageIndex: pageParam ?? 0,
    })

    const hasNextPage = articleResponse.articleListCount !== undefined && articleResponse.articleListCount >= articleResponse.pageSize

    return {
        data: articleResponse.articles,
        nextPageIndex: hasNextPage ? articleResponse.pageIndex + 1 : undefined,
    }
}
