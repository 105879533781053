import { Alert, Icon, Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { isVin } from "@tm/utils"
import { VehicleType } from "@tm/models"
import { DatVinSearchButton } from "./DatVinSearchButton"
import { EdsVinSearchButton } from "./EdsVinSearchButton"
import { getBundleParams } from "../../../utils"

export type VinSearchButtonProps = {
    query: string
    onDATSearchButtonClick: () => void
    onEdsSearchButtonClick: () => void
    disableEdsSearch?: boolean
    vehicleType: VehicleType
}

export function VinSearchButtons(props: VinSearchButtonProps) {
    const { translateText, translate } = useLocalization()
    const { enableDATVinSearch, enableEdsVinSearch } = getBundleParams()
    const { query, onDATSearchButtonClick, onEdsSearchButtonClick, disableEdsSearch, vehicleType } = props

    if (!enableDATVinSearch && (!enableEdsVinSearch || disableEdsSearch)) {
        return null
    }
    const useVinDropdown = vehicleType !== VehicleType.CommercialVehicle && vehicleType !== VehicleType.Motorcycle

    // Currently only show search button when a valid VIN is entered
    if (!isVin(query) || !useVinDropdown) {
        return null
    }
    const blacklistLetters = ["I", "Q", "O"]
    const isCorrectVin = !blacklistLetters.some((v) => query.toUpperCase().includes(v))

    return (
        <Stack spacing={1} p={1}>
            <Stack direction="row" alignItems="center" spacing={1}>
                <Icon name="info" />
                <Typography variant="label">{translate(1540)}</Typography>
            </Stack>

            {isCorrectVin ? (
                <Stack spacing={2}>
                    {enableDATVinSearch && <DatVinSearchButton onClick={onDATSearchButtonClick} />}
                    {enableEdsVinSearch && !disableEdsSearch && <EdsVinSearchButton onClick={onEdsSearchButtonClick} />}
                </Stack>
            ) : (
                <Alert icon={false} variant="filled" severity="error" sx={{ whiteSpace: "initial" }}>{`${translateText(
                    13706
                )} ${blacklistLetters.join(", ")}`}</Alert>
            )}
        </Stack>
    )
}
