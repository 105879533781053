import { channel, ExternalModule, getWorkTaskId, ModuleTab } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { History } from "history"
import { createQueryString, encodeUniqueId, uniqueId } from "@tm/utils"
import { getIconName } from "../../helpers"

export function openInWorktask(item: ExternalModule) {
    const openWorkTaskParam = item.parameter.find((item) => item.key.toLowerCase() === "openinworktask" || item.key.toLowerCase() === "455")
    if (openWorkTaskParam?.value === "1" || openWorkTaskParam?.value === "true") {
        return true
    }
    return false
}

export function createQualifiedUrl(item: ExternalModule, url: string, workTaskId?: string) {
    if (workTaskId || openInWorktask(item)) {
        return `/${encodeUniqueId(workTaskId ?? uniqueId())}/ext/${createQueryString({ url })}`
    }
    return `/external01${createQueryString({ url })}`
}

export function openModuleTab(url: string, item: ExternalModule) {
    getWorkTaskId((id) => {
        if (id) {
            channel("WORKTASK", id).publish("MODULE/OPENED", {
                isSelected: true,
                title: item.catalogDescription || item.description,
                url,
                icon: getIconName(item),
            } as ModuleTab)
        }
    })
}

export function checkDisplayBehavior(display: DisplayType, displayBehavior: string): boolean {
    const DISPLAY_BEHAVIORS = {
        START: "514",
        DASHBOARD: "515",
        START_AND_DASHBOARD: "516",
        BASKET: "519",
        DASHBOARD_AND_HEADER: "525",
        SETTINGS: "526",
        MODULES: "523",
    }

    switch (display) {
        case "DASHBOARD":
            return (
                displayBehavior === DISPLAY_BEHAVIORS.DASHBOARD ||
                displayBehavior === DISPLAY_BEHAVIORS.START_AND_DASHBOARD ||
                displayBehavior === DISPLAY_BEHAVIORS.DASHBOARD_AND_HEADER
            )

        case "START":
            return displayBehavior === DISPLAY_BEHAVIORS.START || displayBehavior === DISPLAY_BEHAVIORS.START_AND_DASHBOARD

        case "BASKET":
            return displayBehavior === DISPLAY_BEHAVIORS.BASKET

        case "HEADER":
            return displayBehavior === DISPLAY_BEHAVIORS.DASHBOARD_AND_HEADER

        case "MODULES":
            return displayBehavior === DISPLAY_BEHAVIORS.MODULES

        default:
            return false
    }
}

export async function callItemUrl(history: History, item: ExternalModule, url?: string, workTaskId?: string) {
    if (url) {
        const target = item.parameter.find((kvp) => kvp.key.toLowerCase() === "target")

        switch (target?.value) {
            case "1": {
                Morpheus.showView("1", `/modal^/external/${createQueryString({ url })}`)
                break
            }
            case "2": {
                window.open(url, "_blank")
                break
            }
            default: {
                const qualifiedUrl = createQualifiedUrl(item, url, workTaskId)
                history.push(qualifiedUrl)
                openModuleTab(qualifiedUrl, item)
                break
            }
        }
    }
}

export type DisplayType = "START" | "DASHBOARD" | "BASKET" | "HEADER" | "MODULES"
