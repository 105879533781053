import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce, receive } from "./business"

const history: BundleComponent<unknown, typeof component> = {
    name: "history",
    reduce,
    receive,
    component,
}

export default history
