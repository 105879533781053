import { VehicleBundleParams } from "@tm/models"
import { registerActions } from "./data"

export const version = {
    name: "vehicle",
    version: "0.1.0",
}

let bundleParams: VehicleBundleParams

export function initVehicleBundle(params: any) {
    bundleParams = params
    registerActions()
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}
