import { Container, IModelContainer } from "@tm/nexus"
import { getStatusData } from "."
import { StatusData, StatusIdType } from "../model"

export * from "./getStatusData"

export const CarMunicationKey = "carmunication"

export const initRepositories = () => {
    Container.register({
        name: CarMunicationKey,
        containerActions: {
            getStatusData
        }
    })
}

export const useContainer = () => Container.getInstance(CarMunicationKey) as CarMunicationContainer

type CarMunicationActions =
    | { name: "getStatusData", action: (vin: string, idType: StatusIdType) => Promise<StatusData> }

export type CarMunicationContainer = IModelContainer<void, CarMunicationActions>