import { useState, useEffect } from "react"
import { equals, getCategoryOfWorkDescriptionTextId, setValue } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { HourlyRate, useUser } from "@tm/context-distribution"
import { NumberField } from "@tm/controls"
import { WorkCategory, UserModule, ReactQueryKeys } from "@tm/models"
import { useQueryClient } from "react-query"
import { Button, Checkbox, FormControlLabel, Stack, Table, TableRowData, Typography } from "@tm/components"
import { HourlyRatesDictionary } from "../business"
import { useInvalidateWorkTaskBasketQueries } from "../../../../../basket/src/data/hooks/workTaskBasket/workflow/useInvalidateWorkTaskBasketQueries"

type Props = {
    hourlyRates?: Array<HourlyRate>
    currencyCode: string
    enabledModules?: Array<UserModule>
}

const MAX_POSSIBLE_NUMBER = 99999.99
const KEY = ReactQueryKeys.basket_summary

export default function HourlyRates({ hourlyRates, enabledModules, currencyCode }: Props) {
    const { translateText } = useLocalization()
    const { changeHourlyRates } = useUser() ?? {}
    const queryClient = useQueryClient()
    const { invalidateWorksRequests } = useInvalidateWorkTaskBasketQueries()

    const [useDefault, setUseDefault] = useState<boolean>(true)
    const [defaultValue, setDefaultValue] = useState<number>(0)
    const [rates, setRates] = useState<HourlyRatesDictionary>({})
    const [dirty, setDirty] = useState<boolean>()
    const [allCategoriesAreValid, setAllCategoriesAreValid] = useState<boolean>()
    const [oneCategoryValueIsValid, setOneCategoryValueIsValid] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState(false)
    const ereModuleEnabled = enabledModules?.find((x) => x.type === 31)

    function getHourlyRateDictionaryFromHourlyRateArray(): HourlyRatesDictionary {
        const dict: HourlyRatesDictionary = {}
        hourlyRates?.forEach((rate) => {
            if (rate.category) {
                dict[rate.category.toString()] = rate.hourlyRate
            } else {
                dict.defaultValue = rate.hourlyRate
            }
        })

        return dict
    }

    useEffect(() => {
        invalidateWorksRequests()
    }, [rates])

    useEffect(() => {
        if (hourlyRates) {
            setRates(getHourlyRateDictionaryFromHourlyRateArray())
            const defaultRate = hourlyRates.find((rate) => rate.category === undefined)
            if (defaultRate) {
                setDefaultValue(defaultRate.hourlyRate)
            }
            setUseDefault(hourlyRates.length === 1)
        } else {
            setRates({})
            setDefaultValue(0)
            setUseDefault(true)
        }
    }, [hourlyRates])

    useEffect(() => {
        setDirty(!equals(rates, hourlyRates ? getHourlyRateDictionaryFromHourlyRateArray() : {}))

        let validCategories = 0
        Object.keys(rates).forEach((key: string) => {
            const value = rates[key]
            if (value > 0) {
                setOneCategoryValueIsValid(true)
                validCategories++
            }
        })

        setAllCategoriesAreValid(Object.keys(rates).length === validCategories)
    }, [rates])

    function handleChange(newValue: string, id: string) {
        // Get the changed value from the changed model
        const stringValue = newValue || ""
        // Parse string to float (replace , with .)
        let value = parseFloat(stringValue.replace ? stringValue.replace(",", ".") : stringValue)

        // If the value is invalid, restore the old value
        if (isNaN(value)) {
            value = 0
        }

        const model = { ...rates }
        // Update the parsed float value
        setValue(model, [id], value)
        setRates(model)
    }

    function handleToggleUseDefaultValue() {
        setUseDefault(!useDefault)
        const hourlyRates = {
            [WorkCategory.ElectronicWork.toString()]: defaultValue,
            [WorkCategory.ElectricalWork.toString()]: defaultValue,
            [WorkCategory.BodyWork.toString()]: defaultValue,
            [WorkCategory.PaintWork.toString()]: defaultValue,
            [WorkCategory.WorkshopWork.toString()]: defaultValue,
            [WorkCategory.AccessoryWork.toString()]: defaultValue,
            [WorkCategory.Diagnose.toString()]: defaultValue,
        }

        if (ereModuleEnabled) {
            hourlyRates[WorkCategory.SaddlerWork.toString()] = defaultValue
            hourlyRates[WorkCategory.SmartRepair.toString()] = defaultValue
        }

        setRates(hourlyRates)
    }

    function handleDefaultChange(newValue: string) {
        // Get the changed value from the changed model
        const stringValue = newValue || ""
        // Parse string to float (replace , with .)
        let value = parseFloat(stringValue.replace ? stringValue.replace(",", ".") : stringValue)

        // If the value is invalid, restore the old value
        if (isNaN(value)) {
            value = 0
        }

        setRates((prevRates) => {
            return { ...prevRates, defaultValue: value }
        })
        setDefaultValue(value)
    }

    function handleSave() {
        setIsSaving(true)
        const arr: Array<HourlyRate> = []

        if (defaultValue > 0) {
            arr.push({ category: undefined, hourlyRate: defaultValue })
        }

        if (!useDefault) {
            Object.keys(rates).forEach((key: string) => {
                const value = rates[key]
                if (value > 0) {
                    arr.push({ category: parseInt(key), hourlyRate: value })
                }
            })
        }

        changeHourlyRates?.(arr, currencyCode)
            .then(() => {
                queryClient.resetQueries(KEY)
            })
            .finally(() => {
                setIsSaving(false)
            })
    }

    function handleReset() {
        if (hourlyRates) {
            setRates(getHourlyRateDictionaryFromHourlyRateArray())
            let defaultValue = true
            hourlyRates.forEach((rate) => {
                if (!rate.category) {
                    setDefaultValue(rate.hourlyRate)
                } else {
                    defaultValue = false
                }
            })
            setUseDefault(defaultValue)
        } else {
            setRates({})
            setDefaultValue(0)
            setUseDefault(true)
        }
    }

    function renderDescription(workCategory: WorkCategory) {
        return <Typography>{translateText(getCategoryOfWorkDescriptionTextId(workCategory))}</Typography>
    }

    function renderValue(id: string) {
        return (
            <NumberField
                disabled={isSaving || useDefault}
                onChange={(value) => {
                    handleChange(value, id)
                }}
                value={useDefault ? defaultValue : rates[id]}
                label={currencyCode}
                maximum={MAX_POSSIBLE_NUMBER}
                minimum={0.0}
                stepSize={0.01}
                placeholder="0"
            />
        )
    }

    const rowData: TableRowData[] = [
        {
            id: "ElectronicWork",
            cells: [
                { displayValue: renderDescription(WorkCategory.ElectronicWork), id: "description" },
                { displayValue: renderValue(WorkCategory.ElectronicWork.toString()), id: "value" },
            ],
        },
        {
            id: "ElectricalWork",
            cells: [
                { displayValue: renderDescription(WorkCategory.ElectricalWork), id: "description" },
                { displayValue: renderValue(WorkCategory.ElectricalWork.toString()), id: "value" },
            ],
        },
        {
            id: "BodyWork",
            cells: [
                { displayValue: renderDescription(WorkCategory.BodyWork), id: "description" },
                { displayValue: renderValue(WorkCategory.BodyWork.toString()), id: "value" },
            ],
        },
        {
            id: "WorkshopWork",
            cells: [
                { displayValue: renderDescription(WorkCategory.WorkshopWork), id: "description" },
                { displayValue: renderValue(WorkCategory.WorkshopWork.toString()), id: "value" },
            ],
        },
        {
            id: "AccessoryWork",
            cells: [
                { displayValue: renderDescription(WorkCategory.AccessoryWork), id: "description" },
                { displayValue: renderValue(WorkCategory.AccessoryWork.toString()), id: "value" },
            ],
        },
        {
            id: "Diagnose",
            cells: [
                { displayValue: renderDescription(WorkCategory.Diagnose), id: "description" },
                { displayValue: renderValue(WorkCategory.Diagnose.toString()), id: "value" },
            ],
        },
    ]
    if (ereModuleEnabled) {
        rowData.push(
            {
                id: "SaddlerWork",
                cells: [
                    { displayValue: renderDescription(WorkCategory.SaddlerWork), id: "description" },
                    { displayValue: renderValue(WorkCategory.SaddlerWork.toString()), id: "value" },
                ],
            },
            {
                id: "SmartRepair",
                cells: [
                    { displayValue: renderDescription(WorkCategory.SmartRepair), id: "description" },
                    { displayValue: renderValue(WorkCategory.SmartRepair.toString()), id: "value" },
                ],
            }
        )
    }

    return (
        <Stack gap={1}>
            <Stack direction="row" spacing={0.5}>
                <Button
                    onClick={handleSave}
                    disabled={
                        isSaving || !dirty || (useDefault ? defaultValue == 0 : defaultValue == 0 ? !allCategoriesAreValid : !oneCategoryValueIsValid)
                    }
                    color="success"
                >
                    {translateText(9)}
                </Button>
                <Button onClick={handleReset} disabled={isSaving || !dirty}>
                    {translateText(48)}
                </Button>
            </Stack>
            <Stack direction="row" spacing={2}>
                <FormControlLabel
                    label={`${translateText(861)}:`}
                    sx={{ mr: 1 }}
                    control={<Checkbox checked={useDefault} onChange={handleToggleUseDefaultValue} disabled={isSaving} />}
                />
                <NumberField
                    onChange={(value) => {
                        handleDefaultChange(value)
                    }}
                    value={defaultValue}
                    label={currencyCode}
                    maximum={MAX_POSSIBLE_NUMBER}
                    minimum={0}
                    stepSize={0.01}
                    placeholder="0"
                    disabled={isSaving}
                />
            </Stack>
            <Table
                columns={[
                    { header: <Typography variant="body2"> {translateText(860)}</Typography> },
                    { header: <Typography variant="body2"> {translateText(622)}</Typography> },
                ]}
                rows={rowData}
                headerBackground="transparent"
            />
        </Stack>
    )
}
