import { ajax, Request } from "@tm/utils"
import { ServiceEvent, EventType, TimeSlot, WorkshopTask } from "./model"
import { getBundleParams } from "../utils"
import { mapServiceEvent, mapEventType, mapTimeSlot, camelToSnake, mapWorkshopTask } from "./mapper"
import { formatDate } from "../helpers/date"
import { createRequest } from "./request-helper"

export * from "./model"

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.serviceUrl
}

export async function getServiceEvents(range: [Date, Date]): Promise<Array<ServiceEvent>> {
    const url = `${getServiceUrl()}/v1-beta/service_events`
    const body = { "filter[inRange]": `${formatDate(range[0])},${formatDate(range[1])}` }
    const result = await ajax(await createRequest(url, "GET", body))

    return result ? result.data.map(mapServiceEvent) : undefined
}

export async function getServiceEvent(workTaskId: string): Promise<ServiceEvent | undefined> {
    const url = `${getServiceUrl()}/v1-beta/service_events?filter[online_booking_id]=${workTaskId}`

    const request: Request<unknown> | null = await createRequest(url, "GET", undefined)

    if (!request) {
        return
    }

    const result = await ajax(request)

    if (!result || !result.data.length) {
        return
    }

    return mapServiceEvent(result.data[0])
}

export async function getEventTypes(): Promise<Array<EventType>> {
    const url = `${getServiceUrl()}/v1-beta/event_types`
    const body = {}
    const request: Request<unknown> | null = await createRequest(url, "GET", body)

    if (!request) {
        return []
    }

    const result = await ajax(request)

    return result ? result.data.map(mapEventType) : []
}

export async function createServiceEvent(event: ServiceEvent): Promise<ServiceEvent> {
    const url = `${getServiceUrl()}/v1-beta/service_events`
    const body = camelToSnake(event)
    const result = await ajax(await createRequest(url, "POST", body))

    return mapServiceEvent(result)
}

export async function updateServiceEvent(event: ServiceEvent): Promise<ServiceEvent> {
    const url = `${getServiceUrl()}/v1-beta/service_events/${event.id}`
    const body = camelToSnake(event)
    const result = await ajax(await createRequest(url, "PATCH", body))

    return mapServiceEvent(result)
}

export async function createWorkshopTask(task: WorkshopTask): Promise<WorkshopTask> {
    const url = `${getServiceUrl()}/v1-beta/workshop_tasks`
    const body = camelToSnake(task)
    const result = await ajax(await createRequest(url, "POST", body))

    return mapWorkshopTask(result)
}

export async function getFreeSlots(start: Date, end: Date, duration: number): Promise<Array<TimeSlot>> {
    const url = `${getServiceUrl()}/v1-beta/free_slots`
    const body = { start_date: formatDate(start), end_date: formatDate(end), duration }
    const result = await ajax(await createRequest(url, "GET", body))

    const list = Object.keys(result)
        .reduce((prev, key) => prev.concat(result[key]), [])
        .map(mapTimeSlot)
    const reducedList = list.reduce((prev, curr) => {
        const last = prev.last()
        if (!last) {
            return [curr]
        }
        if (last.startDate < curr.startDate && last.endDate >= curr.startDate) {
            last.endDate = curr.endDate
            return [...prev]
        }
        return [...prev, curr]
    }, [] as TimeSlot[])
    return reducedList
}
