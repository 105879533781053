import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { mapDatCalculationData } from "./mapper"
import { GetDatCalculationDataAjaxResponse, GetDatCalculationDataRequest, GetDatCalculationDataResponse } from "./model"

export * from "./model"

export const getDATCalculationData = async (calculationDataRequest: GetDatCalculationDataRequest): Promise<GetDatCalculationDataResponse> => {
    const url = `${getServiceUrl()}/GetCalculationData`
    const authorization = getStoredAuthorization()
    const body = calculationDataRequest

    return ajax<GetDatCalculationDataAjaxResponse>({ url, body, method: "POST", authorization }).then((response) => {
        if (!response) {
            throw new Error()
        }

        return mapDatCalculationData(response.calculationData)
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.datCalculationServiceUrl
}
