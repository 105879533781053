import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Box, DateTimePicker, Dialog, Icon, Button as MuiButton, Typography, dialogClasses, styled } from "@tm/components"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"

import { getComponentStyles } from "./styles"

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "secondDatePickerAvailable",
})<{
    secondDatePickerAvailable: boolean
}>(({ secondDatePickerAvailable }) => ({
    display: "flex",
    justifyContent: secondDatePickerAvailable ? "space-between" : "left",
    textAlign: "center",
    flexDirection: "row",
}))

const StyledMuiButton = styled(MuiButton)(({ theme }) => ({
    maxHeight: theme.spacing(2.5),
    alignSelf: "center",
    marginLeft: theme.spacing(2.5),
}))

type Props = {
    onSave(date: Date, endDate: Date): void
    onClose(): void
    startDate: Date
    totalWorkingHours?: number
}

const StyledDialog = styled(Dialog)(() => ({
    [`.${dialogClasses.paperScrollPaper}`]: {
        vertiaclAlign: "top",
    },
}))

export const AppointmentForm = memo<Props>(({ startDate, totalWorkingHours, onClose, onSave }) => {
    const classNames = useMemo(() => getComponentStyles(), [])
    const [open, setOpen] = useState(true)
    const [date, setDate] = useState<{ start: Date; end: Date }>({
        start: startDate,
        end: startDate,
    })
    const [endDateDisabled, setEndDateDisabled] = useState<boolean>(true)

    const { translateText, language } = useLocalization()

    const addHoursToDate = useCallback(
        (date: Date) => {
            if (!totalWorkingHours) {
                return date
            }

            return new Date(new Date(date).getTime() + 3600000 * totalWorkingHours)
        },
        [totalWorkingHours]
    )

    useEffect(() => {
        setDate({
            start: date.start,
            end: addHoursToDate(date.start),
        })
    }, [date.start, addHoursToDate])

    const handleSubmit = () => {
        onSave(date.start, date.end)
        setOpen(false)
    }

    const handleOnCloseModal = () => {
        setOpen(false)
        onClose()
    }

    const onToggle = useCallback(() => {
        setEndDateDisabled(!endDateDisabled)
    }, [])

    const handleOnDateChange = useCallback(
        (date: Date | null, identifier: "START" | "END") => {
            if (!date) {
                setDate({ start: startDate, end: startDate })
            } else {
                setDate((prev) => ({
                    start: identifier === "START" ? date : prev.start,
                    end: identifier === "END" ? date : prev.end,
                }))
            }
        },
        [startDate]
    )

    const ctaDisabled = useMemo((): boolean => {
        return !endDateDisabled && date.start.getTime() > date.end.getTime()
    }, [date, endDateDisabled])

    return (
        <StyledDialog maxWidth="sm" fullWidth open={open}>
            <Box display="flex" justifyContent="space-between">
                <Box>
                    <Typography variant="h2">{translateText(13028)}</Typography>
                </Box>
                <Box>
                    <Button size="l" layout={["ghost"]} icon="close" onClick={handleOnCloseModal} />
                    <Button size="l" skin="success" onClick={handleSubmit} disabled={ctaDisabled}>
                        {translateText(642)}
                    </Button>
                </Box>
            </Box>
            <Box pt={2}>
                <StyledBox secondDatePickerAvailable={!endDateDisabled}>
                    <DateTimePicker
                        localisation={{ locale: language, region: null }}
                        className={classNames.formField}
                        onChange={(date) => handleOnDateChange(date, "START")}
                        label={translateText(98).toUpperCase()}
                        value={date.start}
                        minDateTime={startDate}
                        disablePast
                        minutesStep={5}
                        hideTabs={false}
                    />
                    {endDateDisabled ? (
                        <StyledMuiButton size="small" startIcon={<Icon name="add" width="14px" height="14px" />} onClick={onToggle}>
                            {translateText(13061)}
                        </StyledMuiButton>
                    ) : (
                        <>
                            <Box my="auto">
                                <Typography variant="h4">{translateText(95)}</Typography>
                            </Box>
                            <DateTimePicker
                                localisation={{ locale: language, region: null }}
                                className={classNames.formField}
                                onChange={(date) => handleOnDateChange(date, "END")}
                                value={date.end}
                                label={translateText(13005).toUpperCase()}
                                minDateTime={date.start}
                                disablePast
                                minutesStep={5}
                            />
                        </>
                    )}
                </StyledBox>
            </Box>
        </StyledDialog>
    )
})
