import { Stack, Typography } from "@tm/components"
import { OrderDetailsBox, OrderDetailsLabel } from "./StyledComponents"

type Props = {
    label: string
    value?: string | number
}

export function OrderDetailsField({ label, value }: Props) {
    return (
        <OrderDetailsBox>
            <Stack direction="column">
                <OrderDetailsLabel>{label}</OrderDetailsLabel>
                <Typography variant="body2">{value}</Typography>
            </Stack>
        </OrderDetailsBox>
    )
}
