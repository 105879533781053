import { Box, Divider, Loader } from "@tm/components"
import { useEffect, useMemo, useState } from "react"
import { SizeSpecifications } from "./sizeSpecifications"
import { TireSpecifications } from "./tireSpecifications"
import { AdditionalSpecifications } from "./additionalSpecifications"
import { SeasonSpecifications } from "./seasonSpecifications"
import { useFastServiceStore } from "../../../../../data"
import { SelectedFilters, TireType } from "../../../../../data/state/tyres-wheels"
import { loadTiresCrits, loadTiresData } from "../../../../tyres-wheels/business"

type Props = {
    isFront: boolean
    selectedTabName: string
    tabType: string
    selectedFilters: SelectedFilters
    setSelectedTire: (tire?: TireType) => void
    setSelectedFiltersTemp: (filters?: SelectedFilters) => void
}

export function GeneralDataFields(props: Props) {
    const { isFront, selectedTabName, tabType, setSelectedTire, setSelectedFiltersTemp, selectedFilters } = props
    const { width, height, inch, loadIndex, speedIndex, vehicleLabel } = selectedFilters
    const {
        tiresData: { frontTires, rearTires, loading: loadingTyresData },
        tecDocUsed,
        loadingCrits,
        tyresCrits,
    } = useFastServiceStore((state) => state.tyresWheels)
    const tireSpecifications = useFastServiceStore((state) => state.maintenancePlan.tireSpecifications)
    const stateVehicle = useFastServiceStore((state) => state.maintenancePlan.stateVehicle)
    const [freeInputMode, setFreeInputMode] = useState<boolean>(!tyresCrits)
    const [tyreSpecValues, setTyreSpecValues] = useState<string[]>([])

    const tires = useMemo(() => (isFront ? frontTires : rearTires), [frontTires, rearTires, isFront])
    const previousFieldsCompleted = useMemo(() => {
        if (!(width && height && inch && loadIndex && speedIndex)) {
            return false
        }

        if (tireSpecifications?.length === 1) {
            return true
        }

        if (tireSpecifications && tireSpecifications?.length > 0 && !vehicleLabel) {
            return false
        }

        return true
    }, [selectedFilters])

    useEffect(() => {
        if (tecDocUsed !== stateVehicle?.tecDocTypeId && !frontTires.length) {
            loadTiresData()
            loadTiresCrits(selectedFilters)
        }
    }, [])

    return (
        <Box>
            {loadingCrits && <Loader sx={{ position: "absolute", top: "50%", left: "50%" }} />}
            <Box sx={loadingCrits ? { opacity: "0.54", pointerEvents: "none" } : {}}>
                <Box>
                    <SizeSpecifications
                        selectedFilters={selectedFilters}
                        setSelectedFiltersTemp={setSelectedFiltersTemp}
                        tires={tires}
                        tabType={tabType}
                        setSelectedTire={setSelectedTire}
                        tyresCrits={tyresCrits}
                        freeInputMode={freeInputMode}
                        setFreeInputMode={setFreeInputMode}
                        loading={loadingCrits}
                        loadingTyresData={loadingTyresData}
                    />

                    <Divider light sx={{ margin: "1.5em 0" }} />

                    <TireSpecifications
                        selectedFilters={selectedFilters}
                        setSelectedFiltersTemp={setSelectedFiltersTemp}
                        tyresCrits={tyresCrits}
                        selectedTabName={selectedTabName}
                        freeInputMode={freeInputMode}
                        setFreeInputMode={setFreeInputMode}
                        loading={loadingCrits}
                        setTyreSpecValues={setTyreSpecValues}
                    />
                </Box>

                <Divider light sx={{ margin: "1.5em 0" }} />

                <Box display="flex" flexDirection="column">
                    <AdditionalSpecifications
                        selectedFilters={selectedFilters}
                        setSelectedFiltersTemp={setSelectedFiltersTemp}
                        tyresCrits={tyresCrits}
                        freeInputMode={freeInputMode}
                        setFreeInputMode={setFreeInputMode}
                        loading={loadingCrits}
                        previousFieldsCompleted={previousFieldsCompleted}
                        tyreSpecValues={tyreSpecValues}
                    />

                    <SeasonSpecifications
                        selectedFilters={selectedFilters}
                        setSelectedFiltersTemp={setSelectedFiltersTemp}
                        tyresCrits={tyresCrits}
                        freeInputMode={freeInputMode}
                        previousFieldsCompleted={previousFieldsCompleted}
                    />
                </Box>
            </Box>
        </Box>
    )
}
