import { combineReducers } from "redux"
import { reduce as managerReducer } from "../../moduleManager"
import { reduce as vehicleSelectionReducer, Actions as vehicleSelectionActions, ComponentActionType as VehicleSelectionComponentActionType } from "../../vehicle-selection/business"
import { reduce as loadingScreenReducer, Actions as loadingScreenActions, ComponentActionType as LoadingScreenComponentActionType } from "../../loading-screen/business"
import { reduce as navigationReducer, Actions as navigationActions, ComponentActionType as NavigationComponentActionType } from "../../navigation/business"
import { reduce as wheelsListReducer, Actions as wheelsListActions, ComponentActionType as WheelsListComponentActionType } from "../../wheels-list/business"
import { reduce as wheelsDetailsReducer, Actions as wheelsDetailsActions, ComponentActionType as WheelsDetailsComponentActionType } from "../../wheels-details/business"
import { reduce as rdksListReducer, Actions as rdksListActions, ComponentActionType as RdksListComponentActionType } from "../../rdks-list/business"
import { reduce as tyresListReducer, Actions as tyresListActions, ComponentActionType as TyresListComponentActionType } from "../../tyres-list/business"
import { reduce as overviewReducer, Actions as overviewActions, ComponentActionType as OverviewComponentActionType } from "../../overview/business"
import { MainState } from ".."

export const appReduce = combineReducers({
    manager: managerReducer,
    vehicleSelection: vehicleSelectionReducer,
    loadingScreen: loadingScreenReducer,
    navigation: navigationReducer,
    wheelsList: wheelsListReducer,
    wheelsDetails: wheelsDetailsReducer,
    rdksList: rdksListReducer,
    tyresList: tyresListReducer,
    overview: overviewReducer
})

export const reduce = (state: MainState, action: MainActionsType) => {
    if (action.type == "KEY_VALUES_LOADED") {
        const newState = { ...state, ...action.payload }
        return {
            ...newState
        }
    }
    return appReduce(state, action)
}

export type IMainActions = typeof MainActions

export const MainActions = {
    ...vehicleSelectionActions,
    ...loadingScreenActions,
    ...navigationActions,
    ...wheelsListActions,
    ...wheelsDetailsActions,
    ...rdksListActions,
    ...tyresListActions,
    ...overviewActions
}

export type MainActionsType = VehicleSelectionComponentActionType | LoadingScreenComponentActionType | NavigationComponentActionType | WheelsListComponentActionType | WheelsDetailsComponentActionType | RdksListComponentActionType | TyresListComponentActionType | OverviewComponentActionType