import { useMemo, useState } from "react"
import { Button, TextField, Typography, LoadingContainer, Stack } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { DrivemotiveSettings, useUser } from "@tm/context-distribution"
import { useGetLoginSuccess } from "../../../data/hooks/useGetLoginSuccess"
import { CredentialInputKeys, DrivemotiveLoginRequest } from "../../../data/model"

type Props = {
    settings: DrivemotiveSettings
}
export default function DrivemotiveLogin(props: Props) {
    const {
        settings: { Email, Password },
    } = props
    const user = useUser()
    const { translateText } = useLocalization()
    const [credentials, setCredentials] = useState<{ [key in CredentialInputKeys]: string | undefined }>({ email: undefined, password: undefined })
    const isLoggedIn = useMemo(() => !!Email?.length && !!Password?.length, [Email, Password])

    const loginToDrivemotive = useGetLoginSuccess({
        onLoginSuccess() {
            user.setUserSetting("DRIVEMOTIVE_SETTINGS", { Email: credentials.email, Password: credentials.password })
            handleReset()
        },
    })

    const loginDisabled = useMemo(
        () => !credentials.email || !credentials.password || loginToDrivemotive.isLoading,
        [credentials, loginToDrivemotive.isLoading]
    )
    const isLoading = useMemo(() => !user.userContext || loginToDrivemotive.isLoading, [user.userContext, loginToDrivemotive.isLoading])
    const isDirty = useMemo(() => credentials.email !== undefined || credentials.password !== undefined, [credentials])

    function handleReset() {
        setCredentials({ email: undefined, password: undefined })
    }

    function handleLogin() {
        if (loginDisabled || !credentials.email || !credentials.password) {
            return
        }

        const request: DrivemotiveLoginRequest = { email: credentials.email, password: credentials.password }
        loginToDrivemotive.mutate(request)
    }

    function handleLogout() {
        user.setUserSetting("DRIVEMOTIVE_SETTINGS", { Email: undefined, Password: undefined })
        handleReset()
    }

    function handleInputChange(key: CredentialInputKeys, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const {
            target: { value },
        } = event

        setCredentials((prev) => ({
            ...prev,
            [key]: value,
        }))
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <Stack gap={1}>
                {!isLoggedIn && <Typography variant="body2">{translateText(13686)}</Typography>}
                <Stack direction="row" spacing={0.5}>
                    {isLoggedIn ? (
                        <Button color="success" onClick={handleLogout}>
                            {translateText(367)}
                        </Button>
                    ) : (
                        <Button color="success" onClick={handleLogin} disabled={!isDirty || loginDisabled}>
                            {translateText(13110)}
                        </Button>
                    )}
                    <Button onClick={handleReset} disabled={!isDirty || isLoading}>
                        {translateText(48)}
                    </Button>
                </Stack>
                <Stack direction="row" spacing={0.5} className="user-settings__area__row">
                    <TextField
                        fullWidth
                        size="large"
                        label={translateText(109)}
                        value={credentials.email ?? Email}
                        onChange={(event) => handleInputChange(CredentialInputKeys.Email, event)}
                    />

                    <TextField
                        fullWidth
                        size="large"
                        label={translateText(187)}
                        value={credentials.password ?? Password}
                        onChange={(event) => handleInputChange(CredentialInputKeys.Password, event)}
                        type="password"
                    />
                </Stack>
                {loginToDrivemotive.isError && (
                    <Typography variant="body3" color="error">
                        {translateText(12632)}
                    </Typography>
                )}
            </Stack>
        </LoadingContainer>
    )
}
