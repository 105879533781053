import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { RimItemsRequest } from "./model"
import { RimItem } from "../../model"
import { mapRimItems } from "./mapper"

export function loadRimItems(request: RimItemsRequest) {
    const url = `${getServiceUrl()}/GetRimsOverview`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise<RimItem[]>((resolve, reject) =>
        ajax({ url, body, authorization, method: "GET" }).then(
            response => {
                if (response?.rimsOverview)
                    resolve(mapRimItems(response.rimsOverview))
                else
                    reject()
            },
            reject
        )
    )
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.rimsServiceUrl
}