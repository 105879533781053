import { IBundle } from "@tm/morpheus"
import ElekatButton from "./components/elekat-button"
import { initTraderBirnerBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [ElekatButton],
    init: initTraderBirnerBundle,
}

export default bundle
