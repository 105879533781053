import { Article, ConfigParams, ErpInformation, ErpInformationRequestItem } from "@tm/models"
import { useCallback, useEffect } from "react"
import Morpheus from "@tm/morpheus"
import { useSetRecoilState } from "recoil"
import { selectedOrderOptionState } from "@tm/utils"
import { IErpInfoDetailsActions } from "."
import { useArticleDetailsSharedErpInfosActions } from "../../../data/hooks/useArticleDetailsSharedErpInfos"
import { publishErpInfoLoaded } from "../publishErpInfoLoaded"
import { useErpInfo } from "../useErpInfo"
import { useShowArticleLists } from "./showArticleList"

export type ErpInfoDetailsProps = {
    actions: IErpInfoDetailsActions

    data: Article
    foundBySearchTerm?: string
    foundByVehicleId?: string
}

export function useErpInfoDetails({ actions, data, foundBySearchTerm, foundByVehicleId }: ErpInfoDetailsProps) {
    const { shareErpInfoDetails, shareErpInfoDetailsError, shareErpInfoDetailsReset, shareErpInfoDetailsArticle } =
        useArticleDetailsSharedErpInfosActions()
    const setSelectedOrderOption = useSetRecoilState(selectedOrderOptionState)
    const showArticleLists = useShowArticleLists()

    useEffect(() => {
        shareErpInfoDetailsArticle(data)
    }, [shareErpInfoDetailsArticle, data])

    const resetRedux = useCallback(() => {
        actions.reset()
        shareErpInfoDetailsReset()
        actions.showArticleLists(undefined)
        actions.showArticleDetailsErpTabs({
            alternativeArticles: false,
            replacementArticles: false,
            safetyDataSheets: false,
            pawnArticles: false,
            useSpecialProcurement: false,
        })
    }, [])

    useEffect(() => {
        resetRedux()
    }, [])

    const handleError = useCallback((error: any) => {
        actions.shareErpInfoDetailsError(error)
        shareErpInfoDetailsError(error)
    }, [])

    const handleErpLoad = (request: ErpInformationRequestItem, response: ErpInformation) => {
        actions.shareErpInfoDetails(request, response)
        shareErpInfoDetails(request, response)

        publishErpInfoLoaded(response)

        if (response) {
            actions.showArticleLists(response.articles, foundBySearchTerm, foundByVehicleId, data)
            showArticleLists(response.articles, foundBySearchTerm, foundByVehicleId, data)
            if (!response.useSpecialProcurement) {
                setSelectedOrderOption({ orderOption: undefined, refreshErp: undefined })
            }
            actions.showArticleDetailsErpTabs({
                alternativeArticles: Morpheus.getParams<ConfigParams>().combineAlternativeArticlesFromDbAndErp || response.hasAlternatives || false,
                replacementArticles: response.hasReplacementArticles || false,
                safetyDataSheets: response.showSafetyDataSheets || false,
                pawnArticles: response.hasPawnArticles || false,
                useSpecialProcurement: response.useSpecialProcurement || false,
            })
        }
    }

    return useErpInfo(
        data,
        "details",
        {
            onReset: resetRedux,
            onLoad: handleErpLoad,
            onError: handleError,
        },
        undefined,
        foundBySearchTerm
    )
}
