import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { colorLightGray } from "../../styles"

const headerHeight = "4rem"

export function getComponentStyles() {
    const { box } = getStyleTheme()

    return stylesheet({
        main: {
            height: "100%",
        },
        header: {
            padding: "0 1.2rem",
            background: colorLightGray,
            borderTopLeftRadius: box.radius,
            borderTopRightRadius: box.radius,
            display: "flex",
            alignItems: "center",
            height: headerHeight,
        },
        headerLeft: {
            flex: 1,
        },
        headerDate: {
            marginTop: "0.2rem",
        },
        downloadButton: {
            padding: "0.4em",
            marginRight: "1rem",
        },
        imageWrapper: {
            height: `calc(100% - ${headerHeight})`,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottomLeftRadius: box.radius,
            borderBottomRightRadius: box.radius,
            border: `2px solid ${colorLightGray}`,
            background: "#fff",
        },
        image: {
            maxWidth: "90%",
            maxHeight: "90%",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
