import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { useCallback } from "react"
import { useQuery, useQueryClient } from "react-query"
import * as Data from "../repositories/cis/backorders"

const KEY = "cis_useBackorderCounts"
export function useBackordersCounts() {
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const enabled = enableServiceCalls

    const { data, isLoading } = useQuery(
        [KEY, telesalesCustomerNo, enabled],
        () => {
            return Data.showBackordersCount({ customerNo: telesalesCustomerNo })
        },
        { enabled }
    )

    return { backordersCount: data?.count, backordersCountLoading: isLoading }
}

export function useReloadBackordersCounts() {
    const queryClient = useQueryClient()

    const resetBackorderCounts = useCallback(() => {
        queryClient.resetQueries(KEY)
    }, [queryClient])

    return { resetBackorderCounts }
}
