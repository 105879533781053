import { Box, Button, Icon, NotesButton, Stack } from "@tm/components"
import { Badge } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros, NoteTypes } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useMemo } from "react"
import { getAddReturnItemRequest } from "../../../business/helper"
import { useAddReturnItem } from "../../../data/hooks/useReturnItems"
import { useTecdocPartsInfoForVoucher } from "../../../data/hooks/useTecdocPartsInfo"
import { VoucherItem } from "../../../data/model"

type Props = {
    isWm?: boolean
    item: VoucherItem
    hideAddToBasket?: boolean
    showAddToCompilations?: boolean
    showNote?: boolean
}

export default function VoucherItemOptionsCellComponent({ item, showAddToCompilations, hideAddToBasket, showNote, isWm }: Props) {
    const { translateText } = useLocalization()
    const { renderMicro } = useMicro<IMicros>()

    const [article] = useTecdocPartsInfoForVoucher(item)
    const { addReturnItem } = useAddReturnItem()

    const disabled = !article

    const returnButtonDisabled = useMemo(() => {
        if (!item.canReturn || item.pendingReturnQuantity) {
            return true
        }

        if (!item.returnQuantity) {
            return item.quantity ? item.quantity <= 0 : false
        }

        return item.returnQuantity <= 0
    }, [item])

    const basketArticles = useMemo(() => (article ? [article] : []), [article])

    function handleReturnItemClick() {
        const request = getAddReturnItemRequest(item, article, !!isWm)
        if (request) {
            addReturnItem(request)
        }
    }

    return (
        <Stack alignItems="center" justifyContent="left" spacing={0.5} direction="row">
            {showAddToCompilations && (
                <Box>
                    {renderMicro("compilations", "add-to-compilation", {
                        disabled,
                        article,
                        variant: "small",
                    })}
                </Box>
            )}
            {!hideAddToBasket && (
                <Box>
                    {renderMicro("basket", "add-to-basket", {
                        disabled,
                        data: basketArticles,
                        hideQuantityField: true,
                        variant: "small",
                    })}
                </Box>
            )}
            {!item.hideReturns && (
                <Box position="relative">
                    {item.pendingReturnQuantity && (
                        <Box zIndex={1} position="relative">
                            <Badge skin="dark" value={item.pendingReturnQuantity} />
                        </Box>
                    )}
                    <Button
                        disabled={returnButtonDisabled}
                        color={!item.canReturn || !!item.pendingReturnQuantity ? undefined : "highlight"}
                        title={translateText(13167)}
                        variant={!item.canReturn ? undefined : "bordered"}
                        onClick={handleReturnItemClick}
                        startIcon={<Icon name="return" />}
                    />
                </Box>
            )}
            {showNote && item.information && (
                <Box>
                    <NotesButton
                        articleNotes={[
                            {
                                type: NoteTypes.ARTICLE,
                                message: item.information,
                                title: translateText(12874),
                            },
                        ]}
                        iconOnly
                        hasNote={!!item.information}
                    />
                </Box>
            )}
        </Stack>
    )
}
