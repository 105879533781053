import { VehicleType } from "@tm/models"
import { useState, useEffect } from "react"
import { RecoilValue, useRecoilValueLoadable } from "recoil"

export * from "./filters"
export * from "./manufacturers"
export * from "./mainmodelseries"
export * from "./modelseries"
export * from "./models"
export * from "./useImportedVehicleData"
export * from "./useSelectedLookupConfig"

export type RequestWithVehicleType = {
    vehicleType: VehicleType
}

export function useLoadable<TRes>(recoilValue: RecoilValue<TRes>) {
    type Response<T> = {
        loading: boolean
        response?: T
    }

    const [response, setResponse] = useState<Response<TRes>>({ loading: true })

    const loadable = useRecoilValueLoadable(recoilValue)

    useEffect(() => {
        switch (loadable.state) {
            case "loading":
                setResponse((prev) => ({ ...prev, loading: false }))
                break
            case "hasValue":
                setResponse({ loading: false, response: loadable.contents })
                break
            case "hasError":
                setResponse({ loading: false })
                break
        }
    }, [loadable])

    return response
}
