import { useEffect, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, FormControlLabel, Typography } from "@tm/components"
import { Item, OrderOptions } from "@tm/models"
import { BillingMode } from "../../../../data/model"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin, RadioButtonSmallWithMargin, RadioGroupGrid } from "./StyledComponents"

type Props = {
    disableFields: boolean
    options: OrderOptions
    onSetBillingMode(billingMode: BillingMode, selectedOptionsItem?: Item, updateErpInformation?: boolean, updateOrderOptions?: boolean): void
}

export default function BillingOptionsComponent({ options: { billingModes, selectedOptionsItem }, onSetBillingMode, disableFields }: Props) {
    const { translate } = useLocalization()
    const [selectedBillingModeId, setSelectedBillingModeId] = useState<string>()

    useEffect(() => {
        if (billingModes?.billingModes.length) {
            const defaultBillingMode = billingModes.billingModes.find((billingMode) => billingMode.isSelected)
            const billingModeId = defaultBillingMode ? defaultBillingMode.id : billingModes.billingModes[0].id
            setSelectedBillingModeId(billingModeId)
        }
    }, [billingModes])

    function handleBillingModeChange(_: unknown, id: string) {
        if (billingModes?.billingModes.length) {
            setSelectedBillingModeId(id)
            const firstBillingModeMatch = billingModes.billingModes.find((mode) => mode.id === id)
            if (firstBillingModeMatch) {
                onSetBillingMode(
                    firstBillingModeMatch,
                    selectedOptionsItem,
                    billingModes.updateErpInformationOnChange,
                    billingModes.updateOrderOptionsOnChange
                )
            }
        }
    }

    return (
        <BoxWithColumnPaddingRight>
            <BoxWithAlignItems>
                <IconWithMargin name="billing-modes" />
                <Typography variant="h4"> {translate(1830)}</Typography>
            </BoxWithAlignItems>
            <RadioGroupGrid onChange={handleBillingModeChange}>
                {billingModes?.billingModes.map((mode) => {
                    return (
                        <Box key={mode.id} display="flex">
                            <FormControlLabel
                                value={mode.id}
                                control={<RadioButtonSmallWithMargin disabled={disableFields} checked={mode.id === selectedBillingModeId} />}
                                label={<Typography variant="body2">{mode.description}</Typography>}
                            />
                        </Box>
                    )
                })}
            </RadioGroupGrid>
        </BoxWithColumnPaddingRight>
    )
}
