import { channel } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSendFastCheckOverview(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { sendFastCheckOverview } = data

    if (sendFastCheckOverview) {
        channel("WORKTASK").publish("NOTIFICATIONS/SEND_FAST_CHECK_CHAT_MESSAGE", { pdfBlob: sendFastCheckOverview })
    }
}
