import { useStyle } from '@tm/context-distribution'
import { Icon, Loader, SubTitle, Table, Text } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import { em } from 'csx'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { Tire } from '../../../../data/model'
import { MainState } from '../../../main'
import { mapTyres } from '../../business/helpers'

type Props = {}

const selector = createSelector((s: MainState) => ({
	driveRightTyres: s.wheelsList.base.driveRightTyres,
}), x => x)

const RimDriveRightTyres: React.FC<Props> = ({ }) => {

	const { translateText } = useLocalization()
	const { driveRightTyres: { frontTires, rearTires, error, loading } } = useSelector(selector)
	const tyres = mapTyres(frontTires, rearTires)

	if (loading)
		return <div className="article-list__panel article-list__status"><Loader /></div>

	if (error || frontTires.length == 0 && rearTires.length == 0)
		return <div className="article-list__panel article-list__status"><Text> {translateText(163)}</Text></div>

	return (
		<div className="drive-right-tyres">
			<div className={style.header}>
				<SubTitle>{translateText(353)}</SubTitle>
				<SubTitle>{translateText(354)}</SubTitle>
			</div>
			<Table
				scrollable
				data={tyres}
				columns={[
					<Table.Column className="size" renderItemContent={(item: Tire) => <Table.Cell><Text>{item.sizeFront} {item.loadIndexFront}{item.speedIndexFront}</Text></Table.Cell>}>{translateText(968)}</Table.Column>,
					<Table.Column className="rim" renderItemContent={(item: Tire) => <Table.Cell><Text>{item.rimSizeFront}</Text></Table.Cell>}>{translateText(1029)}</Table.Column>,
					<Table.Column className="offset" renderItemContent={(item: Tire) => <Table.Cell><Text>{item.rimOffsetFront}</Text></Table.Cell>}>{translateText(1028)}</Table.Column>,
					<Table.Column className="season" renderItemContent={(item: Tire) => <Table.Cell>
						<Icon className={item.seasonSummerFront ? "search-icon" : "search-icon disabled"} name="sun" size="l" />
						<Icon className={item.seasonWinterFront ? "search-icon" : "search-icon disabled"} name="snow" size="l" />
					</Table.Cell>}>{translateText(1235)}</Table.Column>,
					<Table.Column className="size" renderItemContent={(item: Tire) => <Table.Cell><Text>{item.sizeRear} {item.loadIndexRear}{item.speedIndexRear}</Text></Table.Cell>}>{translateText(968)}</Table.Column>,
					<Table.Column className="rim" renderItemContent={(item: Tire) => <Table.Cell><Text>{item.rimSizeRear}</Text></Table.Cell>}>{translateText(1029)}</Table.Column>,
					<Table.Column className="offset" renderItemContent={(item: Tire) => <Table.Cell><Text>{item.rimOffsetRear}</Text></Table.Cell>}>{translateText(1028)}</Table.Column>,
					<Table.Column className="season" renderItemContent={(item: Tire) => <Table.Cell>
						<Icon className={item.seasonSummerRear ? "search-icon" : "search-icon disabled"} name="sun" size="l" />
						<Icon className={item.seasonWinterRear ? "search-icon" : "search-icon disabled"} name="snow" size="l" />
					</Table.Cell>}>{translateText(1235)}</Table.Column>
				]}
			/>
		</div>
	)
}

const style = useStyle({
    header: {
        display: "grid", 
		gridTemplateColumns: "1fr 1fr",
		marginRight: em(1.5)
    }
})(RimDriveRightTyres)

export default RimDriveRightTyres