import { CSSProperties } from "react"
import { IArticleItemTemplate } from "@tm/models"
import { BundleTemplate, TemplateRenderer } from "@tm/morpheus"

const Style: { [key: string]: CSSProperties } = {
    ArticleListItemContent: {
        flex: "1",
    },
    ArticleNumbersAndSupplierContainer: {
        display: "flex",
        flexWrap: "wrap",
        position: "relative",
    },
    ArticleErpInformation: {
        display: "flex",
        justifyContent: "space-between",
        minHeight: "4em",
    },
    ArticleErpInformationLeft: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginRight: "0.25em",
    },
    ArticleErpInformationRight: {
        flex: "1",
        textAlign: "right",
    },
    ArticleBuyActions: {
        display: "flex",
        flexDirection: "column",
        marginTop: "2em",
        position: "relative",
    },
    ArticleSaleIcon: {
        position: "absolute",
        right: "0",
        top: "0", // match ArticleBuyActions marginTop (but negative)
        height: "2rem", // match ArticleBuyActions marginTop
    },
    ArticleSelectorAndCostEstimationContainer: {
        display: "flex",
        alignItems: "center",
        marginTop: "0.25em",
    },
}

export const ArticleItemTemplate: BundleTemplate<IArticleItemTemplate & TemplateRenderer> = {
    name: "article-item",
    targetComponent: {
        bundle: "parts",
        component: "article-item",
    },
    render(r: IArticleItemTemplate) {
        const className = r.getQualifiedClassName()

        const { showActions = false } = (r as any).props ?? {}

        const content = (
            <>
                <div className={className}>
                    <div className="thumbnail_supplier-image">
                        {r.renderThumbnailWrapper(false)}
                        {r.renderSupplier()}
                        <div className="article__cell article__cell--bonus-systems">{r.renderBonusSystems()}</div>
                    </div>
                    <div style={Style.ArticleListItemContent}>
                        <div style={Style.ArticleNumbersAndSupplierContainer}>
                            {r.renderNumbers(false, true)}
                            <div style={Style.ArticleSaleIcon}>{r.renderSale()}</div>
                        </div>

                        {r.renderDescription()}
                        {r.renderAttributesCell()}
                        {r.renderReferencesCell()}
                        {r.renderInformationCell()}
                        {r.renderAdditionalActionsAsRowWithoutMore(true)}
                    </div>

                    <div className="article-list__item__buy">
                        <div style={Style.ArticleErpInformation}>
                            <div style={Style.ArticleErpInformationLeft}>
                                {r.renderAvailability()}
                                {r.renderSpecialIcons()}
                                {r.renderGraduatedPriceInfo()}
                            </div>

                            <div style={Style.ArticleErpInformationRight}>
                                {r.renderPrices(false)}
                                {r.renderQuantityUnit()}
                            </div>
                        </div>

                        {showActions && (
                            <div style={Style.ArticleBuyActions}>
                                {r.renderBuyActions(true)}
                                <div className="additional-actions" style={Style.ArticleSelectorAndCostEstimationContainer}>
                                    {r.renderAddToCompilations?.()}
                                    {r.renderCostEstimationButton(true)}
                                </div>
                            </div>
                        )}
                    </div>
                    {showActions && <div className="article-list__item__selector">{r.renderSelector()}</div>}
                    {r.renderMessages()}
                </div>
                {r.renderArticleNotes ? r.renderArticleNotes() : null}
            </>
        )

        const modificationState = r.renderModificationState()

        if (modificationState) {
            return (
                <>
                    {modificationState}
                    {content}
                </>
            )
        }

        return content
    },
}
