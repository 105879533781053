import { Text, MessageInline, Card, Headline, Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import { SubmitReturnOrderResponse } from "../../../data/model"
import VoucherButton from "./VoucherButton"

type Props = {
    fromVouchersModule?: boolean
    response: SubmitReturnOrderResponse
    onClearOrderSent(): void
    onOpenVoucherUrl(url: string): void
}

export default function OrderCompletedComponent(props: Props) {
    const { response, fromVouchersModule } = props
    const { returnOrderDate, returnOrderNumber, orderValidationErrorText, itemValidationErrorTexts, allReturnOrdersHaveSucceeded, returnVoucherId } =
        response

    const { translateText } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    function handleCloseClick() {
        props.onClearOrderSent()
    }

    function renderSuccess() {
        return (
            <Card className="order-success">
                <div className="order-success__details">
                    <Headline>
                        {translateText(1515)} | {translateText(700)} {returnOrderNumber}
                    </Headline>
                    {workTaskId && (
                        <VoucherButton
                            workTaskId={workTaskId}
                            voucherType={fromVouchersModule ? "voucher" : "cis"}
                            returnOrderNumber={returnOrderNumber}
                            returnVoucherId={returnVoucherId}
                            onOpenVoucherUrl={props.onOpenVoucherUrl}
                        />
                    )}
                </div>
                {returnOrderDate && (
                    <div className="order-success__date">
                        <Text>
                            {translateText(1661)} {returnOrderDate.toLocaleDateString()} {translateText(699)} {returnOrderDate.toLocaleTimeString()}.
                        </Text>
                    </div>
                )}
            </Card>
        )
    }

    function renderError() {
        return (
            <div className="order-completed__message order-completed__text">
                {itemValidationErrorTexts &&
                    itemValidationErrorTexts.map((itemError, idx) => {
                        return <MessageInline key={idx} message={itemError} skin="error" />
                    })}
            </div>
        )
    }

    const message = allReturnOrdersHaveSucceeded ? translateText(1214) : translateText(787)
    let description = orderValidationErrorText // This property is intended for messages, not only for errors, from the customer's web service
    if (!allReturnOrdersHaveSucceeded && !orderValidationErrorText) {
        description = `${translateText(1662)}. `
    }

    return (
        <div className="order-completed__message">
            <MessageInline
                message={message}
                skin={allReturnOrdersHaveSucceeded ? "success" : "error"}
                description={description}
                onButtonClick={handleCloseClick}
            />
            {allReturnOrdersHaveSucceeded ? renderSuccess() : renderError()}
        </div>
    )
}
