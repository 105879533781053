import { useStyle } from '@tm/context-distribution'
import { Loader } from '@tm/controls'
import { useActions } from '@tm/morpheus'
import { initSelector, RouteComponentProps, withRouter } from '@tm/utils'
import * as React from 'react'
import { useSelector } from "react-redux"
import { bem } from '../../../../data/helpers'
import { MainActions, MainState } from '../../../main'
import { ErrorMessage, GraphicViewer } from '../../../_shared'
import PartsSelection from "./partsSelection"

type Props = RouteComponentProps & {

}
const selector = initSelector(
    (s: MainState) => s.details.selectedVKNImage,
    s => s.details.vknImages.error,
    s => s.details.parts,
)

const VehicleParts: React.FC<Props> = ({ }) => {
    const [selectedVKNImage, error, parts] = useSelector(selector)
    const actions = useActions(MainActions)


    function handlePositionSelect(posCode: string) {
        const position = selectedVKNImage?.positions?.find(x => x.posCode == posCode)

        if (position) {
            actions.selectImagePosition(position)
            actions.getParts()
        }
    }

    if (error || selectedVKNImage?.error)
        return (
            <div className={style.graphical}>
                <ErrorMessage title={787} icon="no-results" />
            </div>
        )

    if (selectedVKNImage?.svgLoading)
        return (
            <div className={bem(style.graphical, "loading")}><Loader /></div>
        )

    return (
        <div className={style.graphical}>
            {selectedVKNImage && <GraphicViewer
                zoomEnabled
                loading={selectedVKNImage.positionsLoading}
                className={style.selectedImage}
                items={selectedVKNImage?.positions?.map(x => ({ id: x.posCode, tooltip: x.description, color: x.materialAbbr, isVisible: x.isVisible }))}
                svgImage={selectedVKNImage.svg}
                onClick={handlePositionSelect}
                isVknImage
                renderClickTooltip={<PartsSelection hideXBtn={parts.loading} />}
            />}
        </div>
    )

}
const style = useStyle({
    graphical: {
        paddingTop: 0,
        flex: "3",
        display: "flex",
        flexDirection: "column",
        $nest: {
            ".panel__content": {
                flex: 1,
                display: "flex"
            },
            "&--loading": {
                justifyContent: "center",
                display: "flex",
                flex: 1,
                alignItems: "center"
            },
        }
    },

    selectedImage: {
        display: "flex",
        flex: 1,

    },
})(VehicleParts)

export default withRouter(VehicleParts)