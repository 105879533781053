import { Box, LinkButton } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Image } from "@tm/controls"
import { CisNote, CisSalesOutlet } from "@tm/models"
import { LightableText, TypographyTitle } from "../StyledComponents"

type Props = {
    warehouse: CisSalesOutlet
    businnessTimesMode: "businessTimes" | "shopHoursToday"
    lightMode?: boolean
    showNotes?: boolean
}

export default function WarehouseOpeningTimesComponent({ warehouse, businnessTimesMode, lightMode, showNotes }: Props) {
    const { translate } = useLocalization()

    function renderBusinessTimes() {
        const { businessTimes } = warehouse
        if (!businessTimes) {
            return null
        }

        return businessTimes.map((businessTime) => {
            return (
                <Box key={businessTime.weekdayAbbreviation} display="grid" gridTemplateColumns="1fr 1fr" mb="2px">
                    <LightableText variant="label" lightMode={lightMode}>
                        {businessTime.weekdayAbbreviation}
                    </LightableText>
                    <LightableText variant="label" lightMode={lightMode}>
                        {businessTime.openingTime + (businessTime.closingTime ? ` - ${businessTime.closingTime}` : "")}
                    </LightableText>
                </Box>
            )
        })
    }

    function renderShopHoursToday() {
        const { shopHoursToday, businessTimeDescriptions } = warehouse
        if (!shopHoursToday && !businessTimeDescriptions) {
            return null
        }

        return (
            <>
                {shopHoursToday && (
                    <LightableText variant="label" lightMode={lightMode}>
                        {shopHoursToday}
                    </LightableText>
                )}
                {businessTimeDescriptions.map((businessTime) => {
                    return (
                        <Box key={businessTime.days} display="grid" gridTemplateColumns="1fr 1fr" mb="2px">
                            <LightableText variant="label" lightMode={lightMode}>
                                {businessTime.days}
                            </LightableText>
                            <LightableText variant="label" lightMode={lightMode}>
                                {businessTime.times}
                            </LightableText>
                        </Box>
                    )
                })}
            </>
        )
    }

    function renderNote(note: CisNote, id: number) {
        const { imageUrl, text, url, urlText } = note
        return (
            <Box key={id} mt={1}>
                <LightableText fontSize="11px" color="primary" fontWeight="bold" lightMode={lightMode}>
                    {text}
                </LightableText>
                {url && urlText && (
                    <LinkButton size="small" target="_blank" to={url}>
                        <LightableText fontSize="11px" lightMode={lightMode}>
                            {urlText}
                        </LightableText>
                    </LinkButton>
                )}
                <Box>{imageUrl && <Image url={imageUrl} width="250px" />}</Box>
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column">
            <TypographyTitle sx={{ marginBottom: "0.5rem" }} lightMode={lightMode} color="primary">
                {translate(1091)}
            </TypographyTitle>
            {businnessTimesMode === "businessTimes" && <>{renderBusinessTimes()}</>}
            {businnessTimesMode === "shopHoursToday" && <>{renderShopHoursToday()}</>}
            {showNotes && warehouse?.notes?.map(renderNote)}
        </Box>
    )
}
