import { Article } from "@tm/models"
import { uniqueId } from "@tm/utils"

export function mapOverviewArticle(article: Article): Article | undefined {
    return {
        ...article,
        id: uniqueId(),
        quantity: article.quantity || 4,
        requestErpInfo: true,
        showAddToBasket: true,
        internalId: article.internalId,
        information: article.information || [],
        vehicleInformation: article.vehicleInformation || [],
        references: article.references ?? []
    }
}