import { useState, useEffect } from "react"
import { getServiceBook } from "../../data/repositories"

export function useServiceBookUrl(tecDocManufacturerId?: number) {
    const [serviceBookUrl, setServiceBookUrl] = useState<string>()

    useEffect(() => {
        if (!tecDocManufacturerId) {
            setServiceBookUrl(undefined)
        } else {
            getServiceBook(tecDocManufacturerId).then(setServiceBookUrl)
        }
    }, [tecDocManufacturerId])

    return serviceBookUrl
}
