import { stylesheet } from "typestyle"
// import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    // const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            $nest: {
                ".dialog-prompt__inner": { minWidth: "40rem" },
                ".react-datepicker-wrapper": { width: "100%" },
                ".react-datepicker__input-container": { width: "100%" },
            },
        },
        formField: {
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
