import { useLocalization } from "@tm/localization"
import { CisVoucherType } from "@tm/models"
import { Grid, Stack, Box, Button, Icon } from "@tm/components"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Voucher } from "../../../../data/model"
import { getWmVoucherId } from "../../../../business/helper"
import { getBundleParams } from "../../../../utils"
import { printVoucherDetails } from "../../business/helpers"
import { OrderDetailsField } from "../../../_shared/OrderDetailsField"

type Props = {
    voucher: Voucher
    voucherTypeId?: number
}

export default function WmVoucherDetailsHeaderComponent({ voucher, voucherTypeId }: Props) {
    const { date, translateText } = useLocalization()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const descriptionColumnTextId = voucherTypeId === CisVoucherType.DeliveryNote ? 440 : 212
    function handlePrintButtonClick() {
        if (voucher && voucher.voucherId) {
            const bundleParams = getBundleParams()
            printVoucherDetails(voucher, bundleParams.printing, translateText, telesalesCustomerNo)
        }
    }

    return (
        <Stack direction="column" alignItems="flex-end">
            <Box>
                <Button startIcon={<Icon name="print" />} color="primary" onClick={handlePrintButtonClick} title={translateText(13164)} />
            </Box>
            <Grid container rowSpacing={1} columnSpacing={2} my={1} columns={4}>
                {/* First Row */}
                <Grid item xs={1}>
                    <OrderDetailsField value={getWmVoucherId(voucher.voucherId)} label={translateText(470)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField
                        value={
                            voucher.voucherDate && `${date(voucher.voucherDate, "d")}  -  ${date(voucher.voucherDate, "t")} ${translateText(12808)}`
                        }
                        label={`${translateText(471)}  -  ${translateText(335)}`}
                    />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.deliveryType} label={translateText(40)} />
                </Grid>
                <Grid item xs={1} />
                {/* Second Row */}
                <Grid item xs={1} />
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.information} label={translateText(465)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={voucher.voucherDescription} label={translateText(descriptionColumnTextId)} />
                </Grid>
            </Grid>
        </Stack>
    )
}
