import { NoteMessage, NotesButton } from "@tm/components"
import { ButtonLayout } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Article, NoteTypes, Vehicle } from "@tm/models"
import { useLoadNotes } from "@tm/utils"
import { memo, useMemo } from "react"
import { mapArticleAndVehicleToNoteIdentifierList } from "./helper/mapper"

type Props = {
    handleOnNoteClick(): void
    article: Article
    alwaysVisible?: boolean
    layout?: ButtonLayout[]
    vehicle?: Vehicle
    changeIcons?: boolean
    size?: "small" | "medium" | "large"
}

export const NoteActionButton = memo<Props>(({ handleOnNoteClick, article, layout, alwaysVisible, vehicle, changeIcons, size }) => {
    const noteIdsToLoad = mapArticleAndVehicleToNoteIdentifierList(article, vehicle)
    const { articleNotes } = useLoadNotes(noteIdsToLoad)
    const { translate, translateText } = useLocalization()

    const iconOnly = layout?.includes("ghost")
    const currentArticleHasNote = useMemo((): boolean => articleNotes.length > 0, [articleNotes])
    if (!currentArticleHasNote && !alwaysVisible) {
        return null
    }

    const mappedNotes = articleNotes.map((articleNote) => {
        const notes: NoteMessage = {
            type: articleNote.type,
            message: articleNote.text,
            title: articleNote.type === NoteTypes.ARTICLE ? translateText(12874) : translateText(39),
        }
        return notes
    })

    return (
        <NotesButton articleNotes={mappedNotes} size={size} iconOnly={iconOnly} onClick={handleOnNoteClick} hasNote={currentArticleHasNote}>
            {translate(14)}
        </NotesButton>
    )
})
