import { ActionDispatch } from "@tm/morpheus"
import { BundleActionType } from "../../../business"
import { DetailsReferencesState } from "./model"

export * from "./model"

export type ComponentActionType = BundleActionType

export const DEFAULT_STATE: DetailsReferencesState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): DetailsReferencesState {
    switch (action.type) {
        case "DETAILSHEAD_RESET": {
            return {
                ...state,
                detailsResponse: undefined,
            }
        }
        case "DETAILSHEAD_LOADED": {
            return {
                ...state,
                detailsResponse: action.payload,
            }
        }
        default:
            break
    }

    return state
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "DETAILSHEAD_LOADED":
        case "DETAILSHEAD_RESET":
            dispatch(action)
            break
        default:
            break
    }
}

export type IActions = typeof Actions
export const Actions = {}
