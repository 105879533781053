import { useLocalization } from "@tm/localization"
import { Text } from "@tm/controls"

type TextComponentProps = {
    text: string
    size?: "xl" | "l" | "m" | "s" | "xs"
}

const translationIdPattern = /\{\{(.*?)\}\}/g

function TextComponent(props: TextComponentProps) {
    const { translate } = useLocalization()
    const text = props.text.replace(translationIdPattern, ($0, $1) => translate($1) as string)
    return <Text size={props.size}>{text}</Text>
}

export default TextComponent
