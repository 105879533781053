import { cssRaw } from "typestyle"
import { useOffersUrl, ConfigQueryParams } from "../shared"

type Props = {
    params: ConfigQueryParams
}

function Component(props: Props) {
    const url = useOffersUrl(props.params)
    if (!url) {
        return null
    }

    return (
        <div className="tk-offers frame">
            <iframe title="tk-offers frame" width="100%" height="100%" src={url} />
        </div>
    )
}

export default Component

cssRaw(`
    .tk-offers.frame {
        width: 100%;
        position: relative;
        overflow-y: hidden;
        flex: 1;
    }
`)
