import { Box, GlobalNavigationItem, Tooltip } from "@tm/components"
import { Icon } from "@tm/controls"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useCallback, useState } from "react"
import PopoverMenu from "./components/menu"

type Props = RouteComponentProps<void> & {
    url: string
}

function UsersettingsMenuComponent({ url, match, history }: Props) {
    const [showMenu, setShowMenu] = useState<boolean>(false)

    function handleButtonClick() {
        if (match.url === url) {
            history.goBack()
        } else {
            setShowMenu(true)
        }
    }

    const handleTooltipHide = useCallback(() => {
        setShowMenu(false)
    }, [setShowMenu])

    return (
        <Box>
            <Tooltip
                arrow
                placement="bottom"
                onClose={handleTooltipHide}
                onClickAway={handleTooltipHide}
                open={showMenu}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                    <Box>
                        <PopoverMenu handleClose={handleTooltipHide} />
                    </Box>
                }
                variant="light"
                color="primary"
            >
                <GlobalNavigationItem componentType="icon" name="user" onIconClick={handleButtonClick} active={match.url === url} />
            </Tooltip>
        </Box>
    )
}

export default withRouter(UsersettingsMenuComponent)
