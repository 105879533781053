import { WorkTask } from "@tm/context-distribution"
import { BikeModel, CarModel, RegistrationNoType, TruckModel, Vehicle, VehicleShortInfo, VehicleType } from "@tm/models"
import { encodeUniqueId, renderRoute, TmaHelper } from "@tm/utils"

import { createVehicleFromModel, deleteImportedVehicleDetails } from "../business"
import { getBundleParams } from "../utils"
import { vehicleDetailsSubPageDatInfo } from "./routing"

/** @returns A Promise */
export async function handleUniqueVehicleFound(
    model: CarModel | BikeModel | TruckModel | VehicleShortInfo,
    vehicleType: VehicleType,
    searchQuery: string,
    attachToWorkTask: WorkTask["attachToWorkTask"],
    checkAndMergeRegNoDetails?: boolean,
    countryCode?: string,
    selectedLookupType?: RegistrationNoType,
    forceAttachVehicle?: boolean
): Promise<void> {
    let vehicle: Vehicle | string
    let dataSourceId: number | undefined

    if (typeof model.id == "string") {
        model = model as VehicleShortInfo
        dataSourceId = RegistrationNoType.VehicleBase
        vehicle = model.id
    } else {
        model = model as CarModel | BikeModel | TruckModel
        dataSourceId = model.dataSourceId
        vehicle = await createVehicleFromModel({
            model,
            searchQuery,
            registrationNoTypeId: model.dataSourceId,
            vehicleType,
            checkAndMergeRegNoDetails,
            countryCode,
            selectedLookupType,
        })
    }

    TmaHelper.VehicleSelection.Search.FromWidget({ query: searchQuery, dataSourceId })
    deleteImportedVehicleDetails()

    let subPage
    if (dataSourceId === RegistrationNoType.DatVin) {
        subPage = renderRoute(getBundleParams().vehicleDetailsUrlWithoutWorkTaskId, {
            vehicleType,
            vehicleId: encodeUniqueId(typeof vehicle === "string" ? vehicle : vehicle.id),
            vehicleDetailsSubPage: vehicleDetailsSubPageDatInfo,
        })
    }

    return attachToWorkTask({ vehicle, subPage }, forceAttachVehicle)
}
