import { useLocalization } from "@tm/localization"
import { CellContentPosition, TableCellData, TableColumnData } from "@tm/components"
import { VoucherItem } from "../../../../../data/model"
import { StyledTable } from "../../../../_shared/StyledComponents"
import { NoResultHint } from "../../../../_shared/NoResultHint"
import ArticleDescriptionCell from "../../../../_shared/cells/ArticleDescriptionCell"
import VoucherItemOptionsCell from "../../../../_shared/cells/VoucherItemOptionsCell"
import ArticleNumberCell from "../../../../_shared/cells/ArticleNumberCell"

type Props = {
    voucherItems: Array<VoucherItem>
}

export default function WmBackordersItemsTableComponent({ voucherItems }: Props) {
    const { translateText } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [
            { header: translateText(1310) },
            { header: translateText(477) },
            { header: translateText(377) },
            { header: translateText(782), alignContent: CellContentPosition.center },
            { header: translateText(1658), alignContent: CellContentPosition.center },
            {},
        ]
        return columns
    }

    function getCells(voucherItem: VoucherItem) {
        const cellData: TableCellData[] = [
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="wholesaler" />, id: "item_1" },
            { displayValue: <ArticleNumberCell voucherItem={voucherItem} articleType="supplier" />, id: "item_2" },
            {
                displayValue: <ArticleDescriptionCell supplierName={voucherItem.supplierName} articleDescription={voucherItem.articleDescription} />,
                id: "item_3",
            },
            { displayValue: `${voucherItem.quantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_7" },
            { displayValue: `${voucherItem.backorderQuantity || 0} ${voucherItem.quantityUnit || ""}`, id: "item_9" },
            { displayValue: <VoucherItemOptionsCell item={voucherItem} showAddToCompilations isWm />, id: "item_10" },
        ]
        return cellData
    }

    const displayData = voucherItems.map((voucherItem, index) => ({
        cells: getCells(voucherItem),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (!displayData.length) {
        return <NoResultHint />
    }

    return <StyledTable columns={getColumns()} rows={displayData} />
}
