import { Availability, AvailabilityStatus, ItemTour } from "@tm/models"
import { Typography, styled } from "@tm/components"

function getBackground(status: AvailabilityStatus): string | undefined {
    switch (status) {
        case AvailabilityStatus.Available:
            return "#02ad02" // green
        case AvailabilityStatus.PartiallyAvailable:
            return "#ffef35" // yellow
        case AvailabilityStatus.AvailableInAlternativeWarehouse:
            return "linear-gradient(115deg, #55dd55 0%, #fff333 100%)" // gradient from green to yellow
        default:
    }
}

const TourWithBackground = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "status",
})<{
    status: AvailabilityStatus
}>(({ theme, status }) => ({
    width: "100px",
    display: "block",
    textAlign: "center",
    padding: theme.spacing(0, 0.5),
    fontWeight: 600,
    background: getBackground(status),
    lineHeight: "initial",
}))

type Props = {
    availability: Availability | undefined
    tour: ItemTour | string | undefined
}

export function AvailabilityComponent({ availability, tour }: Props) {
    const availabilityStatus = availability?.type

    if (!availabilityStatus) {
        return null
    }

    const tourDescription = typeof tour === "string" ? tour : tour?.description

    return (
        <TourWithBackground variant="body3" status={availabilityStatus}>
            {tourDescription}
        </TourWithBackground>
    )
}
