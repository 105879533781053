import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&--selected, &:active": {
                backgroundColor: "white",
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
