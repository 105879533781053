import { RefObject, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Loader } from "@tm/components"
import { TeccomAvailability } from "./TeccomAvailability"
import { AdvancedDeliveryRequestTable, AdvancedDeliveryRequestTableData } from "./AdvancedDeliveryRequestTable"

type Props = {
    articleQuantity: number
    wrapperRef: RefObject<HTMLDivElement> | undefined
    loadTeccom(quantity: number): void
    hideShipmentOptions?: boolean
    loading: boolean
    loaded: boolean
    hideRequestButton?: boolean
    tableData: AdvancedDeliveryRequestTableData
}

export default function TeccomComponent(props: Props) {
    const { tableData, articleQuantity, wrapperRef, hideRequestButton, loading, loaded, hideShipmentOptions, loadTeccom } = props
    const { translateText } = useLocalization()

    // Set the quantity in the teccom amount field,
    // to the quantity selected in the article details page again, if it changes
    const quantity = useMemo(() => {
        return articleQuantity
    }, [articleQuantity])

    return (
        <div ref={wrapperRef}>
            <Box className="stock-info__teccom" title={translateText(1079)}>
                {!hideRequestButton && (
                    <Box>
                        <TeccomAvailability loadTeccom={loadTeccom} teccomLoading={loading} articleQuantity={quantity} />
                    </Box>
                )}
                {loading ? (
                    <Loader />
                ) : (
                    <>{loaded && <AdvancedDeliveryRequestTable tableData={tableData} hideShipmentOptions={hideShipmentOptions} />}</>
                )}
            </Box>
        </div>
    )
}
