import { useLocalization } from "@tm/localization"
import { useReplaceUrlTags, useWorkTask } from "@tm/context-distribution"
import { useLocation } from "react-router"
import { useEffect, useState } from "react"
import { Container } from "@tm/nexus"
import { RegisteredModels } from "@tm/models"
import { useExternalCatalogUrl } from "../../hooks/useExternalCatalogUrl"

export function useIFrameUniversalTagbasedUrl(externalSystemId: number | undefined) {
    const location = useLocation()
    const { languageId } = useLocalization()
    const { workTask } = useWorkTask() ?? {}

    const { url: externalCatalogUrl, isLoading } = useExternalCatalogUrl(externalSystemId)

    const [modelDetailsLoading, setModelDetailsLoading] = useState(true)
    const [kbaNumbers, setKbaNumbers] = useState<string[]>()

    // Load KBA numbers
    useEffect(() => {
        const { vehicle } = workTask ?? {}

        if (!vehicle?.tecDocTypeId) {
            setModelDetailsLoading(false)
            return
        }

        // Only if "registrationTypeId" is "undefined" or "0" the "registrationNo" is a KBA number (NEXT-24557)
        if (!vehicle.registrationTypeId && vehicle.registrationNo) {
            setKbaNumbers([vehicle.registrationNo])
            setModelDetailsLoading(false)
        } else {
            Container.getInstance<string[]>(RegisteredModels.Vehicle_KbaNumbers)
                .subscribe(vehicle.tecDocTypeId)
                .load()
                .then((numbers) => {
                    setKbaNumbers(numbers)
                    setModelDetailsLoading(false)
                })
        }
    }, [workTask])

    const url = useReplaceUrlTags(externalCatalogUrl, { location, kbaNumbers, languageId })

    return {
        url,
        isLoading: isLoading || modelDetailsLoading,
    }
}
