import { useLocalization } from "@tm/localization"
import { Table, TableColumnData, TableCellData, Icon, Tooltip, CellContentPosition, BasketAmountField } from "@tm/components"
import { WarehouseDispatchType, GetBonusInformationResponse, OrderOptions, ArticleInfoType, TeccomErpInformation } from "@tm/models"
import ArticleThumbnail from "../../PartsTableCells/ArticleThumbnail"
import { BasketErpIndicator, BasketPart } from "../../../../models"
import ArticleNumbers from "../../ArticleNumbers"
import ArticleDescription from "../../PartsTableCells/ArticleDescription"
import PartActions from "./part-list-item/components/PartActions"
import PartErpInfo from "../../PartErpInfo"
import PartFooter from "./part-list-item/components/PartFooter"
import PartPrices from "./part-list-item/components/partPrices"
import PartAlternativesButton from "../../PartsTableCells/PartAlternativesButton"
import { SelectedOrderOptions } from "../../../../data/model"
import ArticleSupplier from "../../PartsTableCells/ArticleSupplier"
import PartOrderStatus from "./part-list-item/components/PartOrderStatus"
import PartBonusPoints from "./part-list-item/components/PartBonusPoints"

type Props = {
    bonusInformation?: GetBonusInformationResponse | undefined
    bonusInformationLoading?: boolean
    bonusPointsWithErrors?: boolean
    bonusPointsLoading?: boolean
    calculationLoading?: boolean
    orderSending?: boolean
    basketErpIndicator: BasketErpIndicator
    hideVehicleLogos?: boolean
    orderOptions?: OrderOptions
    parts: Array<BasketPart>
    showPurchasePrice?: boolean
    warehouseDispatchType?: WarehouseDispatchType | undefined
    workTaskId: string
    onChangePartOrderOptions?(part: BasketPart, orderOptions: SelectedOrderOptions): void
    onChangeQuantity?(part: BasketPart, quantityValue: number): void
    onExpandNote?(id: string): void
    onExpandOrderOptions?(id: string): void
    onIncludeExcludePart?(part: BasketPart): void
    onOpenAlternativeArticlesToReplacePart?(part: BasketPart): void
    onOpenArticleDetails?(productGroupId?: number, supplierArticleNumber?: string, supplierId?: number, subPage?: string, scrollTo?: string): void
    onOpenArticleSearch?(query: string): void
    onOpenDirectSearchToReplacePart?(part: BasketPart): void
    onOpenUniversalSearchToReplacePart?(part: BasketPart): void
    onTeccomInfoLoaded?(partId: string, teccom: TeccomErpInformation): void
    onRemoveNote?(part: BasketPart): void
    onRemoveParts?(partIds: string[]): void
    onSaveNote?(part: BasketPart, note: string, isNewNote: boolean): void
    onSelectPart?(id: string): void
}

export default function PartsTableComponent(props: Props) {
    const {
        parts,
        workTaskId,
        showPurchasePrice,
        warehouseDispatchType,
        bonusInformation,
        bonusInformationLoading,
        basketErpIndicator,
        hideVehicleLogos,
        orderOptions,
        calculationLoading,
        orderSending,
        bonusPointsWithErrors,
        bonusPointsLoading,
    } = props
    const { hasOrderOptions, isLoading: erpInfoLoading, showAlternatives, showBonusInformation, showGraduatedPrices } = basketErpIndicator
    const { translateText } = useLocalization()

    function getColumns() {
        const columns: TableColumnData[] = [{ header: "" }, { header: "" }, { header: "" }, { header: "" }]
        if (showGraduatedPrices) {
            columns.push({ header: "" })
        }
        if (showAlternatives) {
            columns.push({ header: "" })
        }
        columns.push(
            { header: "", alignContent: CellContentPosition.right },
            { header: "" },
            { header: "" },
            { header: "", alignContent: CellContentPosition.right },
            { header: "", alignContent: CellContentPosition.center }, // When the article is not orderable, only an icon is shown
            { header: "", alignContent: CellContentPosition.right }
        )
        return columns
    }

    function getModuleCellData(part: BasketPart): TableCellData[] {
        const { partItem, erpInfoResponse, states } = part
        const { mainPartId, articleInformation, quantity } = partItem
        const { articleInfoType, articleNumber, supplierId, productGroupId, wholesalerArticleNumber } = articleInformation
        const isLinkedItem = !!mainPartId
        const cellData: TableCellData[] = [
            { displayValue: <ArticleThumbnail part={part} />, id: "part_1" },
            { displayValue: <ArticleSupplier part={part} />, id: "part_2" },
            {
                displayValue: (
                    <ArticleNumbers
                        articleNumber={articleInfoType === ArticleInfoType.CustomArticle ? articleNumber : undefined}
                        isLinkedItem={isLinkedItem}
                        oeArticleNumber={articleInfoType === ArticleInfoType.OeArticle ? articleNumber : undefined}
                        productGroupId={productGroupId}
                        supplierArticleNumber={articleInfoType === ArticleInfoType.TecdocArticle ? articleNumber : undefined}
                        supplierId={supplierId}
                        wholesalerArticleNumber={wholesalerArticleNumber}
                        workTaskId={workTaskId}
                    />
                ),
                id: "part_3",
            },
            { displayValue: <ArticleDescription part={part} />, id: "part_4" },
        ]
        if (showGraduatedPrices) {
            cellData.push({
                displayValue: erpInfoResponse?.hasGraduatedPurchasePrice ? (
                    <Tooltip title={translateText(705)}>
                        <Icon name="staffelpreis" />
                    </Tooltip>
                ) : undefined,
                id: "part_5",
            })
        }
        if (showAlternatives) {
            cellData.push({
                displayValue: <PartAlternativesButton part={part} onOpenArticleDetails={props.onOpenArticleDetails} />,
                id: "part_6",
            })
        }

        cellData.push(
            {
                displayValue: (
                    <PartErpInfo
                        part={part}
                        warehouseDispatchType={warehouseDispatchType}
                        erpInfoLoading={states.isErpInfoBeingLoaded}
                        onOpenArticleDetails={props.onOpenArticleDetails}
                        onTeccomInfoLoaded={props.onTeccomInfoLoaded}
                    />
                ),
                id: "part_7",
            },
            {
                displayValue: (
                    <BasketAmountField
                        division={erpInfoResponse?.quantity?.division || quantity.division || 1}
                        handleChangeQuantity={(quantityValue, loadErpInfo) => loadErpInfo && props.onChangeQuantity?.(part, quantityValue)}
                        originalQuantity={quantity.value}
                        disabled={erpInfoLoading || isLinkedItem || states.isErpInfoBeingLoaded || orderSending}
                        loadErpInfos={erpInfoResponse?.quantity?.division !== quantity.division}
                    />
                ),
                id: "part_8",
            },
            {
                displayValue: (
                    <PartBonusPoints
                        part={part}
                        showBonusInformation={showBonusInformation}
                        bonusInformation={bonusInformation}
                        bonusPointsWithErrors={bonusPointsWithErrors}
                        bonusInformationLoading={bonusInformationLoading}
                        bonusPointsLoading={bonusPointsLoading}
                    />
                ),
                id: "part_9",
            },
            {
                displayValue: (
                    <PartPrices
                        part={part}
                        loading={states.isErpInfoBeingLoaded || calculationLoading}
                        showPurchasePrice={showPurchasePrice}
                        showTotalPrices
                    />
                ),
                id: "part_10",
            },
            {
                displayValue: (
                    <PartOrderStatus
                        part={part}
                        infosLoading={calculationLoading || erpInfoLoading || orderSending}
                        onIncludeExcludePart={props.onIncludeExcludePart}
                    />
                ),
                id: "part_11",
            },
            {
                displayValue: (
                    <PartActions
                        workTaskId={workTaskId}
                        disableControls={
                            erpInfoLoading ||
                            calculationLoading ||
                            isLinkedItem ||
                            states.isBeingUpdated ||
                            states.isErpInfoBeingLoaded ||
                            orderSending
                        }
                        part={part}
                        showOrderOptions={hasOrderOptions}
                        showItemNote={orderOptions?.hasItemComment}
                        hideVehicleLogos={hideVehicleLogos}
                        onExpandNote={props.onExpandNote}
                        onExpandOrderOptions={props.onExpandOrderOptions}
                        onOpenAlternativeArticlesToReplacePart={props.onOpenAlternativeArticlesToReplacePart}
                        onOpenArticleDetails={props.onOpenArticleDetails}
                        onOpenArticleSearch={props.onOpenArticleSearch}
                        onOpenDirectSearchToReplacePart={props.onOpenDirectSearchToReplacePart}
                        onOpenUniversalSearchToReplacePart={props.onOpenUniversalSearchToReplacePart}
                        onRemoveParts={props.onRemoveParts}
                        onSelectPart={props.onSelectPart}
                    />
                ),
                id: "part_12",
            }
        )
        return cellData
    }

    const displayData = parts?.map((part, index) => ({
        cells: getModuleCellData(part),
        id: `${index}`,
        customRow: false,
        active: false,
        extendedContent: (
            <PartFooter
                calculationLoading={calculationLoading}
                part={part}
                workTaskId={workTaskId}
                basketErpIndicator={basketErpIndicator}
                showPurchasePrice={showPurchasePrice}
                orderSending={orderSending}
                itemCommentMaxLength={orderOptions?.itemCommentMaxLength}
                onChangeOrderOptions={(basketPart, basketOrderOptions) => props.onChangePartOrderOptions?.(basketPart, basketOrderOptions)}
                onRemoveNote={(basketPart) => props.onRemoveNote?.(basketPart)}
                onSaveNote={(basketPart, note, isNewNote) => props.onSaveNote?.(basketPart, note, isNewNote)}
            />
        ),
    }))

    // TODO: the new list (not implemented yet) should be used here insted of a table
    return <Table columns={getColumns()} rows={displayData} overflowY="unset" headerBackground="transparent" />
}
