import { stylesheet } from "typestyle"
// import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    // const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            $nest: {
                ".message-toast__text-wrapper": {
                    overflow: "hidden",
                },
            },
        },
        content: {
            display: "flex",
            alignItems: "center",
        },
        messageText: {
            flex: 1,
            overflow: "hidden",
            whiteSpace: "nowrap",
            marginRight: "0.5rem",
            textOverflow: "ellipsis",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
