import { BundleComponent } from "@tm/morpheus"
import { withStoreProvider } from "../../shared/withStoreProvider"
import { DashboardTemplateSix } from "./DashboardTemplateSix"

const DashboardTemplateSixBundle: BundleComponent = {
    name: "dashboard-template-six",
    component: withStoreProvider(DashboardTemplateSix as any),
}

export { DashboardTemplateSixBundle, DashboardTemplateSix }
