import { PropsWithChildren, ReactNode, useCallback, useEffect, useState } from "react"
import { styled } from "@tm/components"
import { getStyleTheme } from "@tm/context-distribution"
import { Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel, getWorkTaskId } from "@tm/models"
import { useHeighAdjustment } from "@tm/utils"

const translationIdPattern = /\{\{(.*?)\}\}/g

export type MultiWidgetProps = PropsWithChildren<{
    className?: string
    title?: string
    icon?: string
    showOnVehicle?: boolean // If true, only show the module when a vehicle is selected
    heightObservationName?: string // set this to allow the observation of the height of this module, to adjust other component height
    setModuleVisibility?: (visible: boolean) => void
    head?: ReactNode
}>

export function MultiWidget(props: MultiWidgetProps) {
    const { className, showOnVehicle, setModuleVisibility } = props
    const [visible, setVisible] = useState(!showOnVehicle)

    const handleRef = useCallback((ref: HTMLDivElement) => {
        if (ref && props?.heightObservationName) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useHeighAdjustment().allowHeightObservation(ref, props.heightObservationName)
        }
    }, [])

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/ban-types
        let unsubs: Function[]
        getWorkTaskId((id?: string) => {
            if (id) {
                unsubs = [
                    channel("WORKTASK", id).subscribe(
                        "VEHICLE/LOADED",
                        () => {
                            setVisible(true)
                            setModuleVisibility && setModuleVisibility(true)
                        },
                        true
                    ),
                ]
            }
        })
        return () => unsubs?.forEach((unsub) => unsub())
    }, [])

    if (!visible) {
        return null
    }

    return (
        <div className={`${className} widget`} ref={handleRef}>
            <div className="widget__card">
                <div className="widget__inner">
                    <Title {...props} />
                    <Head>{props.head}</Head>
                    <Content {...props} />
                </div>
            </div>
        </div>
    )
}

function Title(props: Pick<MultiWidgetProps, "title" | "icon">) {
    const { translate } = useLocalization()
    const { title, icon } = props || {}

    if (!title) {
        return null
    }

    const text = title.replace(translationIdPattern, ($0, $1) => translate($1) as string)

    return (
        <TitleComponent className="multiwidget__head widget__header">
            <div className="widget__title">
                {icon && <Icon name={icon} className="widget__icon" />}
                <div className="text">{text}</div>
            </div>
        </TitleComponent>
    )
}

const TitleComponent = styled("div")(() => {
    const { margin, opacity } = getStyleTheme()
    return {
        fontSize: "1.5em",
        lineHeight: 1.3,
        marginBottom: margin.s,
        opacity: opacity.primary,
    }
})

function Head(props: PropsWithChildren<{}>) {
    const { children } = props
    if (!children) {
        return null
    }

    return <HeadComponent className="multiwidget__head">{children}</HeadComponent>
}

const HeadComponent = styled("div")({})

function Content(props: Pick<MultiWidgetProps, "children">) {
    const { children } = props

    if (!children) {
        return null
    }

    return <ContentComponent className="multiwidget__body widget__content">{children}</ContentComponent>
}

const ContentComponent = styled("div")(() => {
    return {
        display: "flex",
        position: "relative",
        ".widget__card": {
            $nest: {
                ".widget__cover-icon": {
                    flex: 1,
                },
                ".widget__cover-text": {
                    minHeight: "2.4em",
                    padding: "0 .2em",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                },
            },
        },
        ".widget__footer": {
            bottom: 0,
        },
    }
})
