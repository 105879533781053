import { AuthorityContainer, MiscBundleParams, RegisteredModels, UrlResolverContainer } from "@tm/models"
import { Container } from "@tm/nexus"
import { getActivatableModules, setModuleActivation } from "./data/repositories/moduleStore"
import { getExternalToken } from "./data/repositories/portalAuthority"
import { getConceptPageUrl, getExternalCatalogUrl } from "./data/repositories/url"

export const version = {
    name: "misc",
    version: "1.0",
}

let bundleParams: MiscBundleParams

export function initMiscBundle(params: MiscBundleParams) {
    bundleParams = params
    Container.register<AuthorityContainer>({
        name: RegisteredModels.Authority,
        containerActions: {
            getExternalToken,
            setModuleActivation,
            getActivatableModules,
        },
    })
    // This registered model dosen't have the prefix Authority because there should be a service exclusive for that instad of the authority
    Container.register<UrlResolverContainer>({
        name: RegisteredModels.UrlResolver,
        containerActions: {
            getExternalCatalogUrl,
            getConceptPageUrl,
        },
    })
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
