import { Box, Icon, Loader, styled, Typography } from "@tm/components"
import { Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useEffect } from "react"
import { graphicActions, useEdsStore } from "../../../data/state"
import { NavigationLinksEnum, TreeOptions, UnitShortV2Dto } from "../../../models"
import { getGroupGraphic, getUnitsGraphic } from "../business"
import GraphicSelectionCard from "./GraphicSelectionCard"

export default function GraphicSelection() {
    const { translateText } = useLocalization()

    const selectedNode = useEdsStore((state) => state.treeNavigation.selectedNode)
    const treeOption = useEdsStore((state) => state.main.treeOption)
    const groupGraphic = useEdsStore((state) => state.graphic.group)
    const unitGraphic = useEdsStore((state) => state.graphic.unit)
    const loading = useEdsStore((state) => state.graphic.loading)
    const error = useEdsStore((state) => state.graphic.error)
    const vin = useEdsStore((state) => state.vehicle.data.vin)

    useEffect(() => {
        if (selectedNode && !selectedNode.children && selectedNode.links) {
            graphicActions.setLoading(true)
            const getGroupParts = selectedNode.links.filter((l) => l.action === NavigationLinksEnum.GetGroupParts)?.first()
            if (getGroupParts) {
                getGroupGraphic(getGroupParts.token, vin)
            } else {
                const getUnits = selectedNode.links.filter((l) => l.action === NavigationLinksEnum.GetUnits)?.first()
                getUnits && getUnitsGraphic(getUnits.token, vin)
            }
        } else {
            graphicActions.setGroup(undefined)
            graphicActions.setUnit(undefined)
        }
    }, [selectedNode])

    function onCardClick(unit: UnitShortV2Dto) {
        graphicActions.setSelectedUnit(unit)
    }

    if (loading) {
        return (
            <Box sx={{ height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Loader sx={{ zIndex: "1" }} />
            </Box>
        )
    }

    if (error) {
        return (
            <Box sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Icon name="no-results" width="15rem" height="4rem" />
                <Typography variant="h2">{translateText(13414)}</Typography>
            </Box>
        )
    }

    if (treeOption === TreeOptions.Category && groupGraphic?.categories) {
        const duplicateName = new Set()
        const objects = { isDuplicated: false, name: "" }
        groupGraphic.categories.forEach((c) =>
            c.units.forEach((unit) => {
                if (duplicateName.has(unit.unit.name)) {
                    objects.isDuplicated = true
                    objects.name = unit.unit.name
                }
                duplicateName.add(unit.unit.name)
                return null
            })
        )

        return (
            <Scrollbar>
                <StyledBox onContextMenu={(e) => e.preventDefault()}>
                    {groupGraphic.categories.map((c) =>
                        c.units.map((unit) => {
                            if (unit.unit.name === objects.name && objects.isDuplicated) {
                                return <GraphicSelectionCard key={unit.unit.token} unit={unit.unit} onCardClick={onCardClick} hasTooltip />
                            }

                            return <GraphicSelectionCard key={unit.unit.token} unit={unit.unit} onCardClick={onCardClick} />
                        })
                    )}
                </StyledBox>
            </Scrollbar>
        )
    }

    if (treeOption === TreeOptions.Oe && unitGraphic?.units) {
        return (
            <Scrollbar>
                <StyledBox onContextMenu={(e) => e.preventDefault()}>
                    {unitGraphic.units.map((unit) => (
                        <GraphicSelectionCard key={unit.token} unit={unit} onCardClick={onCardClick} />
                    ))}
                </StyledBox>
            </Scrollbar>
        )
    }

    return <Box />
}

const StyledBox = styled(Box)({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, 300px)",
    gridTemplateRows: "repeat(auto-fill, max-content)",
    gridAutoFlow: "dense",
    gap: "0.5em",
    padding: "0.25em",
})
