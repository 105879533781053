import { useHasCis, useTelesalesCustomerNumber } from "@tm/context-distribution"
import { QueryFunctionContext, useQuery } from "react-query"
import { showTours } from "../repositories/cis/tours"

const QUERY_KEY = "cis_useCisShowTours"

type QueryKey = [typeof QUERY_KEY, string | undefined]

/**
 * Returns the tours for the current CIS customer.
 * @param enabled Set this to `false` to disable the request. Default: `true`.
 */
export function useCisShowTours(enabled = true) {
    const hasCis = useHasCis()
    const { telesalesCustomerNo, enableServiceCalls } = useTelesalesCustomerNumber()

    const { data, isLoading } = useQuery([QUERY_KEY, telesalesCustomerNo], fetchTours, {
        enabled: hasCis && enabled && enableServiceCalls,
    })

    return {
        tours: data?.tours,
        toursLoading: isLoading,
    }
}

function fetchTours({ queryKey: [, customerNo] }: QueryFunctionContext<QueryKey>) {
    return showTours({ customerNo })
}
