import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Icon, Typography, styled } from "@tm/components"
import { FastCalculator } from "@tm/data"
import { CalcInputCheck, CalcStateButton, Prices } from "../_shared"
import { getCalcStateIcon } from "../../data/helpers"
import Toolbar from "./toolbar"
import { useFastCalculatorStore } from "../../state"

type Props = {
    selectedCalcState?: FastCalculator.CalcState
    inModal?: boolean
    showPrices?: boolean
    disableTabs?: boolean
}

export default function NavigationComponent({ selectedCalcState, inModal, showPrices, disableTabs }: Props) {
    const { translateText } = useLocalization()
    const { calcStates, serviceWorksCalcStates } = useFastCalculatorStore((state) => state.fastCalculatorData)

    const navigationCalcStates = useMemo(() => calcStates?.filter((cS) => !(FastCalculator.ECalcState.OverlayCalcStates & cS.type)), [calcStates])

    const renderTabs = (calcState: FastCalculator.CalcState, idx: number) => {
        let content = (
            <CalcStateButton key={idx} item={calcState.tab} isActive={calcState.type === selectedCalcState?.type} isDisabled={disableTabs} />
        )

        if (calcState.tab?.slider !== undefined) {
            const { slider } = calcState.tab
            const icon = getCalcStateIcon(slider.icon)

            content = (
                <Box key={idx} display="flex" alignItems="center" gap="0.5em">
                    <CalcStateButton item={calcState.tab} isActive={calcState.type === selectedCalcState?.type} isDisabled={disableTabs} />
                    <Box display="flex" alignItems="center" gap="0.5em">
                        {icon && <Icon name={icon} />}
                        {slider.title && (
                            <Typography width={110} sx={{ width: "110px", wordWrap: "break-word", whiteSpace: "normal" }} variant="label">
                                {slider.title}
                            </Typography>
                        )}
                        <CalcInputCheck disableTitleLabel item={slider} />
                    </Box>
                </Box>
            )
        }

        return content
    }

    return (
        <Box display="flex" flexWrap="wrap" padding="0px 16px" height="fit-content" borderBottom="2px solid #e2e2e2">
            <Box
                sx={{ backgroundRepeat: "no-repeat", backgroundPosition: "center", width: "10em", marginTop: "6px" }}
                className="fast-calculator-next_logo"
            />

            <Toolbar>{navigationCalcStates?.map(renderTabs)}</Toolbar>

            {!!serviceWorksCalcStates?.length && (
                <Toolbar title={translateText(12541)} showToolbarDivider>
                    {[...serviceWorksCalcStates].reverse().map(renderTabs)}
                </Toolbar>
            )}

            {selectedCalcState?.buttonGroups?.map((buttonGroup, idx) => {
                const isCalculationButtonGroup = buttonGroup?.buttons?.some(
                    (x) => x.type === FastCalculator.ECalcButtonState.StartCalculation || x.type === FastCalculator.ECalcButtonState.CreateQuote
                )

                const content = (
                    <>
                        {buttonGroup?.buttons.map((button) => (
                            <CalcStateButton
                                item={button}
                                key={button.id}
                                inModal={inModal}
                                isTab
                                isDisabled={isCalculationButtonGroup ? !!disableTabs : false}
                            />
                        ))}
                    </>
                )

                if (isCalculationButtonGroup) {
                    return (
                        <Box key={idx} display="flex" justifyContent="flex-end" flex="1" gap="8px">
                            <Box
                                display="flex"
                                height="100%"
                                whiteSpace="nowrap"
                                alignItems="center"
                                style={{ display: !showPrices ? "none" : undefined }}
                            >
                                <Prices isHeader />
                            </Box>

                            <CalculationButtonGroup display="flex" alignItems="center">
                                {content}
                            </CalculationButtonGroup>
                        </Box>
                    )
                }
                return (
                    <Toolbar key={idx} title={buttonGroup?.label} showToolbarDivider>
                        {content}
                    </Toolbar>
                )
            })}
        </Box>
    )
}

const CalculationButtonGroup = styled(Box)({
    ".btn": {
        flexDirection: "row-reverse",
        gap: "12px",
        padding: "13px !important",
        fontSize: "16px",
    },
})
