import { BundleComponent } from "@tm/morpheus"
import { TyresSearchWidgetCompact } from "./component"

const TyresSearchWidgetCompactBundle: BundleComponent = {
    name: "tyres-search-widget-compact",
    component: TyresSearchWidgetCompact
}

export { TyresSearchWidgetCompactBundle, TyresSearchWidgetCompact }

