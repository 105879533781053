import Morpheus from "@tm/morpheus"
import { ajax } from "@tm/utils"

// TODO: add this in utils or something, this code is from vehicle bundle
function getServiceBooksUrl() {
    return Morpheus.getParams("vehicle").serviceBooksUrl
}

type ServiceBook = {
    kher: number
    her: string
    url: string
}

export async function getServiceBook(manufacturerId: number) {
    const url = getServiceBooksUrl()
    if (!url) {
        return
    }

    const serviceBooks = await ajax<Array<ServiceBook>>({ url })
    const serviceBook = serviceBooks?.find((x) => x.kher === manufacturerId)

    return serviceBook?.url
}
