import { Dialog, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RegistrationNoType } from "@tm/models"
import { useAvailableVehicleSearchOptions } from "@tm/utils"
import { useRef, useEffect } from "react"
import { VrcInfo } from "../../../../../data/model/vrc-lookup"

type Props = {
    vrcInfo: VrcInfo
    noResult: boolean
    datVinRequestActive: boolean
    regNoType?: RegistrationNoType
    plateType?: RegistrationNoType
    datVinErrorTextId: number | null
    onClose(noResult?: boolean): void
}

export default function NoResultDialog(props: Props) {
    const { translateText } = useLocalization()
    const dialogRef = useRef<Dialog>(null)

    useEffect(() => {
        dialogRef.current?.show()
    }, [])

    // we are not able to detect which request was failed (kba, vin, plate) because we are getting the infos from one endpoint.
    // so this part has to be reworked, daniel will implement a default error object which should make this obsolete and waaay easier :)
    const { noResult, datVinRequestActive, regNoType, plateType, vrcInfo, onClose } = props
    const { availableVehicleSearches } = useAvailableVehicleSearchOptions()
    const regNoPossible = !!(vrcInfo.registrationNumber && regNoType) && regNoType >= 0
    const platePossible = !!(vrcInfo.numberPlate && plateType)
    const vinLookupAvailable = availableVehicleSearches?.configuredVehicleLookups?.some(
        (type) => type.lookupTypeId === RegistrationNoType.TopmotiveVin
    )
    const tmVinPossible = !!vrcInfo.vin && vinLookupAvailable

    const datVinEnabled = datVinRequestActive
    const noDatVinResult = datVinRequestActive && noResult
    const datVinErrorTextId = null

    const regNoEnabled = regNoPossible
    const noRegNoResult = !regNoPossible || noResult
    const plateEnabled = platePossible
    const noPlateResult = !platePossible || noResult
    const tmVinEnabled = tmVinPossible && noResult
    const noTmVinResult = noResult

    return (
        <Dialog
            ref={dialogRef}
            onClose={() => onClose(noResult)}
            text={noResult && translateText(325)}
            skin={noResult ? "danger" : undefined}
            iconName={noResult ? "not" : undefined}
        >
            <>
                {datVinEnabled && noDatVinResult && (
                    <>
                        <Text modifiers="block">{translateText(datVinErrorTextId || 12589)}</Text>
                        <br />
                    </>
                )}

                {regNoEnabled && noRegNoResult && (
                    <>
                        <Text modifiers="block">{translateText(props.regNoType === RegistrationNoType.Kba ? 12631 : 13126)}</Text>
                        <br />
                    </>
                )}

                {plateEnabled && noPlateResult && (
                    <>
                        <Text modifiers="block">{translateText(13127)}</Text>
                        <br />
                    </>
                )}

                {tmVinEnabled && noTmVinResult && (
                    <>
                        <Text modifiers="block">{translateText(1405)}</Text>
                        <br />
                    </>
                )}

                {noResult && <Text modifiers="block">{translateText(12632)}</Text>}
            </>
        </Dialog>
    )
}
