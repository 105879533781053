import { styled, Card } from "@tm/components"
import { Image } from "@tm/controls"

export const LogoCard = styled(Card)({
    display: "flex",
    alignItems: "center",
})

export const LogoImage = styled(Image)(({ theme }) => ({
    margin: theme.spacing(2),
    maxWidth: "15rem",
    maxHeight: "6rem",
}))
