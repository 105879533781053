import { getStyleTheme } from "@tm/context-distribution"
import { NestedCSSProperties } from "typestyle/lib/types"
import deepAssign from "deep-assign"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors } = getStyleTheme()
    const themeStyles: NestedCSSProperties = {
        color: colors.dark,
        borderLeftColor: colors.highlight,
        $nest: {
            "&:hover:not(&--selected)": {
                // backgroundColor: color("black").setAlpha(0.03).toRgbString(),
                borderLeftColor: colors.highlight,
                color: colors.dark,
                $nest: {
                    ".icon": {
                        fill: colors.dark,
                    },
                },
            },
            "&__close": {
                $nest: {
                    ".icon": {
                        fill: colors.dark,
                    },
                },
            },
            "&--selected": {
                borderLeftColor: "transparent",
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}

export function getOverflowMenuStyles(baseStyles: NestedCSSProperties) {
    const { colors } = getStyleTheme()

    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&__btn": {
                $nest: {
                    ".icon": {
                        fill: `${colors.dark} !important`,
                    },
                    "&:hover": {
                        $nest: {
                            ".icon": {
                                fill: `${colors.dark} !important`,
                            },
                        },
                    },
                },
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    return mergedStyles
}
