export const setField = (obj: Record<string, any>, id: string, val: any) => {
    let find: any
    Object.keys(obj).forEach((key) => {
        if (Array.isArray(obj[key])) {
            if (!find) {
                const idx = obj[key].findIndex((x: any) => x.id === id)
                if (idx !== -1) {
                    // setValue(find, [], val)
                    obj[key][idx] = val
                }
            }
        }

        if (typeof obj[key] == "object") {
            if (!find) {
                find = setField(obj[key], id, val)
            }
        }
    })
    return find
}
