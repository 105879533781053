import { ModelState } from "@tm/utils"
import { isFieldAvailable } from "."
import { Tab } from "../model"

export const countError = (modelState: ModelState, tab: Tab) => {
    let errorCount = 0
    switch (tab.id) {
        case "Adresses": {
            modelState["supplierInfo.postalAddress"]?.length && errorCount++
            modelState["repairShopInfo.postalAddress"]?.length && errorCount++
            modelState["traderInfo.postalAddress"]?.length && errorCount++
            modelState["customerInfo.postalAddress"]?.length && errorCount++
            break
        }
        case "Vehicle": {
            modelState["receitInfo.number"]?.length && errorCount++
            // modelState["articleDetails.articleNr"] && modelState["articleDetails.articleNr"]?.length && errorCount++
            // modelState["receitInfo.date"] && modelState["receitInfo.date"]?.length && errorCount++
            modelState["vehicleInfo.firstRegistrationYear"]?.length && errorCount++
            modelState["vehicleInfo.partsInstallationDate"]?.length && errorCount++
            modelState["vehicleInfo.installationMileage"]?.length && errorCount++
            modelState["vehicleInfo.partsDeinstallationDate"]?.length && errorCount++
            modelState["vehicleInfo.deinstallationMileage"]?.length && errorCount++
            modelState["vehicleInfo.vinNr"]?.length && errorCount++
            modelState["vehicleInfo.vehicleType"]?.length && errorCount++
            modelState["vehicleInfo.kw"]?.length && errorCount++
            modelState["vehicleInfo.hp"]?.length && errorCount++
            modelState["vehicleInfo.motorCode"]?.length && errorCount++
            modelState["vehicleInfo.keyNumber"]?.length && errorCount++
            modelState["vehicleInfo.ccm"]?.length && errorCount++
            modelState["vehicleInfo.fuelTypeId"]?.length && errorCount++
            modelState["vehicleInfo.transmissionType"]?.length && errorCount++
            break
        }
        case "Compliamt": {
            modelState["complainInfo.claimDescription"]?.length && errorCount++
            modelState["complainInfo.errorOccurance"]?.length && errorCount++
            break
        }
        case "CostStatement": {
            // if (tab.enabledIf && !!modelState[tab.enabledIf]?.length) break
            isFieldAvailable("complainInfo.claimDescription") && modelState["complainInfo.claimDescription"]?.length && errorCount++
            modelState["additionalCostsInfo.repairmentDay"]?.length && errorCount++
            modelState["additionalCostsInfo.referenceNumber"]?.length && errorCount++
            modelState["additionalCostsInfo.vehicleOwner"]?.length && errorCount++
            modelState["additionalCostsInfo.additionalParts"]?.length && errorCount++
            modelState["vehicleInfo.firstRegistrationYear"]?.length && errorCount++
            modelState["vehicleInfo.deinstallationMileage"]?.length && errorCount++
            break
        }
        case "Batterie": {
            modelState["batteryInfo.deliveryNoteInformation.number"]?.length && errorCount++
            modelState["batteryInfo.deliveryNoteInformation.date"]?.length && errorCount++
            modelState["batteryInfo.siteOfUse"]?.length && errorCount++
            modelState["batteryInfo.batteryDetectedVoltage"]?.length && errorCount++
            modelState["batteryInfo.batteryDetectedTime"]?.length && errorCount++
            modelState["batteryInfo.generatorDetectedVoltageWithLoad"]?.length && errorCount++
            modelState["batteryInfo.generatorDetectedVoltageWithoutLoad"]?.length && errorCount++
            modelState["batteryInfo.completionAtAcknowledgement"]?.length && errorCount++
            modelState["batteryInfo.completionAtRejection"]?.length && errorCount++
            modelState["batteryInfo.durationOfDistance"]?.length && errorCount++
            modelState["batteryInfo.batteryPlacement"]?.length && errorCount++
            modelState["batteryInfo.purposeOfUse"]?.length && errorCount++
            break
        }
        case "Return": {
            modelState["returnsInfo.returnType"]?.length && errorCount++
            modelState["returnsInfo.isProductResalable"]?.length && errorCount++
            modelState["returnsInfo.completionAtReturn"]?.length && errorCount++
            modelState["returnsInfo.additionalParts"]?.length && errorCount++
            break
        }
        case "OldBatteries": {
            modelState["oldBatteriesInfo.oldBatteryTypeList"]?.length && errorCount++
            break
        }
        default:
            break
    }
    return errorCount
}
