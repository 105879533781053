import { Loader } from "@tm/controls"
import { Article, ErpIcon, ErpSystemConfig } from "@tm/models"
import { useCallback } from "react"
import { style } from "typestyle"
import AvailabilityWrapperComponent from "../../../../../_shared/availability-wrapper"
import { getBundleParams } from "../../../../../utils"
import { parseUrlToGetFileNameWithoutPrefix } from "../../../../../utils/url-parser"
import PartnerSystemHelper from "./helper"

type Props = {
    config: ErpSystemConfig
    article: Article
    onClick?(): void
    onSpecialIconClick?(iconName: string): void
}

const className = style({ alignSelf: "center", marginLeft: "0.5rem" })

export default function PartnersystemAvailability({ config, article, onClick, onSpecialIconClick }: Props) {
    const mapSpecialIcons = useCallback(
        (icons?: Array<ErpIcon>): Array<ErpIcon & { onClick?(): void }> | undefined => {
            let specialIcons: Array<ErpIcon & { onClick?(): void }> | undefined

            if (icons && !getBundleParams().showPartnersystemIconsOnArticleList) {
                specialIcons = icons.map((icon) => {
                    if (onSpecialIconClick) {
                        try {
                            return {
                                ...icon,
                                onClick: () => onSpecialIconClick(parseUrlToGetFileNameWithoutPrefix(icon?.url || "")),
                            }
                        } catch (_) {
                            return icon
                        }
                    }

                    return icon
                })
            }

            return specialIcons
        },
        [onSpecialIconClick]
    )

    return (
        <PartnerSystemHelper config={config} article={article} fallback={<Loader className={className} />}>
            {({ erpInfo, error }) => (
                <AvailabilityWrapperComponent
                    hasReplacedArticles={!!(erpInfo?.articles && erpInfo.articles.length)}
                    className={className}
                    availability={erpInfo?.availability}
                    tour={erpInfo?.tour}
                    warehouses={erpInfo?.warehouses}
                    error={error}
                    requestedQuantity={erpInfo?.quantity?.requestedValue}
                    specialProcurementInformation={erpInfo?.specialProcurementErpInformation}
                    partnerSystemSpecialIcons={mapSpecialIcons(erpInfo?.specialIcons)}
                    onClick={onClick}
                    useSpecialProcurement={erpInfo?.useSpecialProcurement}
                />
            )}
        </PartnerSystemHelper>
    )
}
