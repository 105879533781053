import { MainState } from "../../../main"

export function createSizeQuery(selectedFilters: MainState["summary"]["selectedFilters"]) {
    const { width, height, inch, loadIndex, speedIndex } = selectedFilters
    let size = ''
    if (width && inch)
        size = width.value + (height?.value ? '/' : "") + (height?.value || "") + 'R' + inch.value + " "

    // if (loadIndex)
    //     size += loadIndex.value
    // if (speedIndex)
    //     size += speedIndex.value

    return size.trim()
}