import { ExternalToolSteps } from "../enums"
import { ExternalPageLoadedMessage } from "../model"

export const getPageByDatMessage = (message: ExternalPageLoadedMessage) => {
    switch (message.message) {
        case "contractOpeningPage":
            return ExternalToolSteps.ContractOpening
        case "equipment selection":
            return ExternalToolSteps.Equipment
        case "model selection":
            return ExternalToolSteps.Model
        default:
    }
}
