import { Stack } from "@tm/components"
import { notUndefinedOrNull } from "@tm/utils"
import { VoucherPart } from "@tm/data/vouchers/costEstimations"
import { CostEstimationOptions } from "@tm/models"
import PartsTable from ".."

type Props = {
    costEstimationOptions?: CostEstimationOptions
    part: VoucherPart
    isBigScreen: boolean
    currencyCode: string
    currencySymbol: string
    onSelectPart(id: string): void
}

export default function PartFooter({ part, isBigScreen, currencyCode, currencySymbol, costEstimationOptions, onSelectPart }: Props) {
    if (!part.linkedItems?.length) {
        return null
    }

    return (
        <Stack p={0.5} spacing={1.5} flex={1}>
            <PartsTable
                parts={
                    part.linkedItems
                        ?.filter((item) => !!item.mainPartItemId)
                        ?.map<VoucherPart>((item) => {
                            return { ...item, isLinkedItem: true, thumbnail: "", id: part.mainPartItemId || "" }
                        })
                        .filter(notUndefinedOrNull) || []
                }
                costEstimationOptions={costEstimationOptions}
                selectedIds={[]}
                currencyCode={currencyCode}
                currencySymbol={currencySymbol}
                isBigScreen={isBigScreen}
                onSelectPart={onSelectPart}
            />
        </Stack>
    )
}
