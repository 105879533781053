import { useMemo } from "react"
import { Box, Loader } from "@tm/components"
import { Voucher } from "../../../../data/model"
import StandardVoucherDetailsHeader from "./StandardVoucherDetailsHeader"
import VoucherItemsSummary from "../../../_shared/VoucherItemsSummary"
import StandardVoucherItemsTable from "../../../_shared/voucher-items-table/StandardVoucherItemsTable"
import TexkatVoucherItemsTable from "../../../_shared/voucher-items-table/TexkatVoucherItemsTable"
import { CisDisplayMode } from "../../../../business/model"
import { useOrderDetails } from "../../../../data/hooks/useOrderDetails"
import { NoResultHint } from "../../../_shared/NoResultHint"
import { ErrorMessage } from "../../../_shared/ErrorMessage"
import { ColumnStack } from "../../../_shared/StyledComponents"

type Props = {
    endOfList: boolean
    selectedVoucherId: string
    voucherTypeId?: number
    vouchers: Array<Voucher>
    cisDisplayMode?: CisDisplayMode
    onOpenNewVoucher?(voucherTypeId: number, voucherId: string): void
}

export default function StandardVoucherDetailsComponent(props: Props) {
    const { endOfList, selectedVoucherId, voucherTypeId, vouchers, cisDisplayMode, onOpenNewVoucher } = props
    const selectedVoucher = useMemo(() => {
        return vouchers.find((voucher) => voucher.voucherId === selectedVoucherId)
    }, [vouchers, selectedVoucherId])

    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && { voucherId: selectedVoucher.voucherId, voucherTypeId }
    )

    let content = <></>
    if (selectedVoucher) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (orderDetails?.voucherItems?.length) {
            content = (
                <ColumnStack>
                    <StandardVoucherDetailsHeader
                        key="VoucherHead"
                        voucher={selectedVoucher}
                        orderDetails={orderDetails}
                        onOpenNewVoucher={onOpenNewVoucher}
                    />
                    {cisDisplayMode === "texkat" ? (
                        <TexkatVoucherItemsTable key="VoucherItemsTable" voucherItems={orderDetails.voucherItems} />
                    ) : (
                        <>
                            <StandardVoucherItemsTable
                                key="VoucherItemsTable"
                                voucherItems={orderDetails.voucherItems}
                                voucherTypeId={selectedVoucher.voucherType.typeId}
                            />
                            <VoucherItemsSummary
                                key="VoucherItemsSummary"
                                voucherItems={orderDetails.voucherItems}
                                currencyCode={selectedVoucher.currencyCode}
                                hideTotal={false}
                            />
                        </>
                    )}
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    } else if (endOfList) {
        content = <NoResultHint />
    }

    return <Box flex={1}>{content}</Box>
}
