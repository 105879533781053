export const getSplittedRegistrationNo = (registrationNo = "") => {
    let regex = new RegExp(/[^\s]+|\s(.*)/g)
    let matches = registrationNo.match(regex)

    const hsn = matches?.[0] || ""
    let tsn = matches?.[1]?.trim() || ""
    let vsn

    if (tsn?.length > 3) {
        regex = new RegExp(/(^.{0,3})+|(.*)/g)
        matches = tsn.match(regex)

        tsn = matches?.[0] ?? ""
        vsn = matches?.[1] ?? ""
    }

    return { hsn, tsn, vsn }
}
