import { Accordion, AccordionDetails, AccordionSummary, Box, ListItem, Typography, styled } from "@tm/components"

export const AccordionHeadline = styled(AccordionSummary)({
    minHeight: "unset",
    ".MuiAccordionSummary-content": {
        margin: "4px 0",
        ".Mui-expanded": {
            margin: 0,
        },
    },
    ".MuiTypography-root": {
        fontSize: 12,
        fontWeight: 600,
    },
    svg: {
        minHeight: 16,
        minWidth: 16,
        width: 16,
        height: 16,
    },
    "svg:not(style)+svg:not(style)": {
        marginLeft: 4,
    },
})

export const AccordionSummaryMore = styled(AccordionHeadline)({
    ".MuiTypography-root": {
        fontWeight: "normal",
    },
})

export const AccordionSimple = styled(Accordion)({
    backgroundColor: "transparent",
    boxShadow: "unset",
    ":before": {
        display: "none",
    },
    ".MuiAccordionDetails-root .MuiAccordionSummary-root": {
        paddingLeft: 4,
    },
})
AccordionSimple.defaultProps = { disableGutters: true, TransitionProps: { mountOnEnter: true, unmountOnExit: true } }

export const ListItemMaxWidth = styled(ListItem)({
    padding: "1px 0",
    fontSize: 12,
    alignItems: "flex-end",
})

export const AccordionDetailsSmall = styled(AccordionDetails)({
    padding: "0 0 8px 20px",
    ".MuiAccordionDetails-root": {
        paddingLeft: 0,
    },
})

export const BorderdBox = styled(Box)({
    borderRadius: "2px",
    borderTop: "1px solid #c3c3c3",
    borderLeft: "1px solid #c3c3c3",
    borderBottom: "1px solid #c3c3c3",
    padding: "4px",
})

export const ClickableText = styled(Typography)<{ highPriority?: boolean }>(({ theme, highPriority }) => ({
    cursor: "pointer",
    color: theme.overwrites?.components?.partListV2?.color,
    "&:hover": {
        color: theme.palette.primary.main,
    },
    ...(highPriority && {
        fontWeight: "600",
    }),
}))
ClickableText.defaultProps = { variant: "body3" }
