import { useEffect, useMemo, useRef, useState } from "react"
import { Button, Dialog, Loader, Popover } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { registerOutsideClick } from "@tm/utils"
import { Message, useOwnUserId, useSendChatMessage } from "../../../data/hooks"
import WideButton from "../../chats/components/chat-infos/wide-button"
import { AttachmentType } from "../../chats/components/input/attachment"
import { AppointmentForm } from "../../chats/components/input/attachment/appointment-form"
import { AppointmentRejectionForm } from "./rejection-form"
import { getComponentStyles } from "./styles"

type Props = {
    appointmentId: string
    date: Date | string
    message: Message
    totalWorkingHours?: number
    asPopover?: boolean
}

export default function EditAppointmentButtons({ appointmentId, message, totalWorkingHours, date, asPopover }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const { translateText, date: formatDate } = useLocalization()
    const ownUserId = useOwnUserId()
    const rejectedDialogRef = useRef<Dialog>(null)
    const send = useSendChatMessage()
    const [sending, setSending] = useState(false)
    const [showAppointmentForm, setShowAppointmentForm] = useState(false)
    const [showPopover, setShowPopover] = useState(false)
    const wrapperRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (wrapperRef.current && showPopover) {
            return registerOutsideClick(wrapperRef.current, () => setShowPopover(false))
        }
    }, [wrapperRef.current, setShowPopover, showPopover])

    if (ownUserId === message.authorId) {
        return null
    }

    const sendMessage = (text: string, attachment: AttachmentType) => {
        setSending(true)

        send({ chatId: message.chatId, message: text, attachment }).then(
            () => {
                // setSending(false)
            },
            () => {
                setSending(false)
            }
        )
    }

    const buttonClassName = asPopover ? classNames.buttonInPopover : undefined
    let buttons = sending ? (
        <Loader />
    ) : (
        <>
            <Button
                skin="highlight"
                onClick={() =>
                    sendMessage(translateText(3064), {
                        metaData: {
                            type: "appointmentResponse",
                            appointmentId,
                            action: "repairshopConfirmation",
                            confirmedDate: date,
                        },
                    })
                }
            >
                {translateText(3024)}
            </Button>
            <Button
                className={buttonClassName}
                onClick={() => {
                    rejectedDialogRef.current?.show()
                    if (showPopover) {
                        setShowPopover(false)
                    }
                }}
            >
                {translateText(3013)}
            </Button>
            <Button
                className={buttonClassName}
                onClick={() => {
                    setShowAppointmentForm(true)
                    if (showPopover) {
                        setShowPopover(false)
                    }
                }}
            >
                {translateText(13028)}
            </Button>
        </>
    )

    if (asPopover) {
        buttons = (
            <>
                <Popover active={showPopover} alignArrow={["bottom", "right"]} className={classNames.popover}>
                    {buttons}
                </Popover>
                <WideButton
                    text={`${formatDate(new Date(date), "g")} (${translateText(122)})`}
                    icon="calendar"
                    onClick={() => {
                        setShowPopover(!showPopover)
                    }}
                />
            </>
        )
    }

    return (
        <div className={classNames.main} ref={wrapperRef}>
            {buttons}
            {showAppointmentForm && (
                <AppointmentForm
                    startDate={new Date(date)}
                    totalWorkingHours={totalWorkingHours}
                    onSave={(startDate, endDate) => {
                        sendMessage(translateText(3070), {
                            metaData: {
                                type: "appointmentResponse",
                                appointmentId,
                                action: "repairshopProposal",
                                proposedDate: startDate,
                                endDate,
                            },
                        })
                        setShowAppointmentForm(false)
                    }}
                    onClose={() => setShowAppointmentForm(false)}
                />
            )}
            <AppointmentRejectionForm
                onConfirm={(canceledType, text) => {
                    sendMessage(text, {
                        metaData: {
                            type: "appointmentResponse",
                            appointmentId,
                            action: "repairshopRejection",
                            canceledType,
                        },
                    })
                }}
                onClose={() => rejectedDialogRef.current?.hide()}
                ref={rejectedDialogRef}
            />
        </div>
    )
}
