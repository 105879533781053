import { FC, Ref, useEffect } from "react"
import { Box, Button, Icon, Loader, Stack, Typography } from "@tm/components"
import { UserSettings, UserSettingsKeys, useUser } from "@tm/context-distribution"
import { Headline } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CategoryType, FittingPosition, SearchFilters } from "@tm/models"
import { TmaHelper, getCategoryTypeFromUrl } from "@tm/utils"
import { useRecoilState } from "recoil"
import { SearchType } from "../../../business"
import { useUniSearchStatus } from "../../../helper"
import { IActions, ListState } from "../business"
import { NoResult } from "./no-result"
import { getBundleParams } from "../../../utils"
import { SelectedCriteriasState } from "../../_shared/uni-search-top-products/state/SelectedCriteriasState"

export type Props = {
    loading: boolean
    noResult: boolean
    showLoadArticlesButton?: boolean
    url: string
    handleRequestArticleDirectSearch: (query: string, searchFilter?: SearchFilters, inModal?: boolean, urlCallback?: (url: string) => void) => void
    handleRequestUniversalArticleSearch: (query: string, resetQuery?: boolean, urlCallback?: (url: string) => void) => void
    userSettings?: UserSettings
    setUserSetting(key: UserSettingsKeys, value: any): Promise<UserSettings>
    state: ListState
    actions: IActions
    loadingElementRef?: Ref<HTMLDivElement>
}

const StatusMessage: FC<Props> = (props) => {
    const { translateText, translate } = useLocalization()

    const {
        state,
        actions,
        userSettings,
        setUserSetting,
        loading,
        noResult,
        url,
        showLoadArticlesButton,
        handleRequestArticleDirectSearch,
        handleRequestUniversalArticleSearch,
    } = props

    const { searchType, initialFilters, error, usedFilters, result, oeResult } = state
    const { query } = usedFilters
    const { userContext } = useUser()
    const uniSearchIsActive = useUniSearchStatus()
    const [selectedCriteria, setSelectedCriterias] = useRecoilState(SelectedCriteriasState)
    const { uniPartsIsExternalSystem } = getBundleParams()

    function handleSwapSearch(type: Extract<CategoryType, "directSearch" | "universalParts">, query: string, resetQuery?: boolean) {
        switch (type) {
            case "directSearch":
                return handleRequestArticleDirectSearch(query, undefined, undefined, (targetUrl) => {
                    TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.Assistent(targetUrl, query)
                })
            case "universalParts":
                return handleRequestUniversalArticleSearch(query, resetQuery, (targetUrl) => {
                    TmaHelper.UniParts.Search.Assistent(targetUrl, query)
                })
            default:
                break
        }
    }

    // we need to reset the initial Criterias, after the Articles are loaded
    // the state is paresd as a prop at the "wrapper.tsx" component
    // and wee need a functional component, so this is a good place
    // NEXT-28684
    useEffect(() => {
        if (!loading && selectedCriteria?.length) {
            setSelectedCriterias([])
        }
    }, [loading, selectedCriteria, setSelectedCriterias])

    useEffect(() => {
        // https://jira.dvse.de/browse/NEXT-20966
        // After getting no result from direct search, go immediately to the uniparts search
        const categoryType = getCategoryTypeFromUrl(url)
        if (
            categoryType === "directSearch" &&
            !!query &&
            !loading &&
            noResult &&
            userContext.modules?.some(
                (module) =>
                    module.description === "UnipartsPremium" &&
                    module.parameters?.some((param) => param.key === "WithoutHitsAutoFurtherSearch" && param.value === "1")
            )
        ) {
            handleSwapSearch("universalParts", query.text, true)
        }
    }, [url, query, loading, noResult, userContext])

    const handleToggleEngineCodeFilter = () => {
        if (userSettings && userSettings.articleListSettings) {
            setUserSetting("ARTICLE_LIST_SETTINGS", {
                ...userSettings.articleListSettings,
                useEngineCodeFilter: !userSettings.articleListSettings.useEngineCodeFilter,
            })
        }
    }

    // Neimcke uses an external system for their UniParts. In the past, there was a 400 Error overwriting the "no result" screen.
    // Neimcke needs the "no result" screen of our UniParts search for the Button that leads to their external system.
    // In the future the external system search should be triggered instantly instead of our UniParts search.
    // https://jira.dvse.de/browse/NEXT-24973

    if (error && !uniPartsIsExternalSystem) {
        let { message } = error
        switch (!error.message && error.name) {
            case "NO_DATA": {
                message = translateText(1329)
                break
            }
            case "INVALID_REQUEST": {
                message = translateText(809)
                break
            }
            default:
        }

        return (
            <Headline size="xs" className="headline--danger">
                {message}
            </Headline>
        )
    }

    if (loading) {
        return <Loader />
    }

    function getSearchTypeText(translationNumber: number) {
        return (
            <Stack alignItems="center" p="1em" spacing={1}>
                <Typography variant="label">{translate(translationNumber)}</Typography>
            </Stack>
        )
    }

    if (noResult) {
        const categoryType = getCategoryTypeFromUrl(url)
        let alternatives

        if (query) {
            const renderDirectSearchBtn = () => (
                <Button onClick={() => handleSwapSearch("directSearch", query.text)} sx={{ margin: "0 4px" }}>
                    {translate(87)}
                </Button>
            )

            const renderUniversalPartsBtn = () => (
                <Button onClick={() => handleSwapSearch("universalParts", query.text)} sx={{ margin: "0 4px" }}>
                    {translate(1009)}
                </Button>
            )

            const renderAlternativesText = () => {
                if (uniSearchIsActive) {
                    if (categoryType === "directSearch") {
                        return translate(1074)
                    }
                    return translate(1007)
                }

                if (categoryType === "vehicleParts") {
                    return translate(1007)
                }
            }

            alternatives = (
                <Headline size="xs">
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        {renderAlternativesText()}
                        {(categoryType === "vehicleParts" || categoryType === "universalParts") && renderDirectSearchBtn()}
                        {uniSearchIsActive && categoryType === "directSearch" && renderUniversalPartsBtn()}
                        {uniSearchIsActive && (categoryType === "directSearch" || categoryType === "universalParts") && "?"}
                        {uniSearchIsActive && categoryType === "vehicleParts" && (
                            <>
                                {translate(1008)}
                                {renderUniversalPartsBtn()}?
                            </>
                        )}
                    </Stack>
                </Headline>
            )
        }

        if (userSettings?.articleListSettings?.useEngineCodeFilter && initialFilters?.engineCode) {
            alternatives = (
                <Headline size="xs">
                    {`${translateText(12869)} `}
                    <Button variant="outlined" startIcon="flame" title={translateText(13103)} onClick={handleToggleEngineCodeFilter}>
                        {translateText(12870)}
                    </Button>
                    {` ${translateText(12871)}`}
                </Headline>
            )
        }

        const getErrorText = (filtersInactive: boolean, text?: string) => {
            if ((text && !filtersInactive) || !filtersInactive) {
                return translate(810)
            }
            return translate(1011)
        }

        const noFilters =
            !!usedFilters.articleAttributes.length ||
            !!usedFilters.oeReferenceNos.length ||
            !!usedFilters.productGroupIds.length ||
            !!usedFilters.supplierIds.length ||
            !!usedFilters.constructionYear ||
            !!usedFilters.availability ||
            usedFilters.fittingPosition !== FittingPosition.None

        return (
            <Stack alignItems="center" p="1em" spacing={1}>
                <Icon name="no-results" size={showLoadArticlesButton ? "16px" : "128px"} />
                <Headline size={showLoadArticlesButton ? "xs" : "m"}>{usedFilters.query ? translate(809) : translate(1010)}</Headline>
                <Typography variant="label">{getErrorText(noFilters, usedFilters.query?.text)}</Typography>
                {noFilters && <NoResult actions={actions} state={state} />}
                {alternatives && <br />}
                {alternatives}
            </Stack>
        )
    }
    // If search type is oe positions and and oe article but no related iam articles were found show messageJ
    if (searchType === SearchType.OE_POSITIONS && oeResult?.parts.length && !result.parts.length) {
        return getSearchTypeText(1821)
    }
    if (searchType === SearchType.TRADERARTICLENOS && !result.wholesalerParts?.length && !result.parts.length) {
        return getSearchTypeText(1010)
    }

    return null
}

export const Status: FC<Props> = (props) => {
    return (
        <Box ref={props.loadingElementRef} id="articleListLoader" className="article-list__panel article-list__status">
            <StatusMessage {...props} />
        </Box>
    )
}
