import { Box, Stack, styled, SxProps, Theme, Typography } from "@tm/components"

// Function to deliver the correct background color
function getBackgroundColor(theme: Theme, lightMode: boolean): string {
    return lightMode ? theme.palette.common.white : theme.palette.common.black
}

// Function to deliver the correct foreground color
function getForegroundColor(theme: Theme, lightMode: boolean): string {
    return theme.palette.getContrastText(getBackgroundColor(theme, lightMode))
}

// Function to properly deliver the right color overwrites
function getOverwrites(theme: Theme, colorOverwrites: "background" | "border" | "foreground" | "headline") {
    switch (colorOverwrites) {
        case "background":
            return theme?.overwrites?.components?.erpTours?.backgroundColor
        case "border":
            return theme?.overwrites?.components?.erpTours?.borderColor
        case "foreground":
            return theme?.overwrites?.components?.erpTours?.foregroundColor
        case "headline":
            return theme?.overwrites?.components?.erpTours?.headline?.color
        default:
    }
}

// Styled Components for Tour.tsx
// Container around the Tour ID's
export const TourIdContainer = styled(Box, { shouldForwardProp: (prop) => prop !== "sx" && prop !== "lightMode" })<{
    sx?: SxProps
    lightMode: boolean
}>(({ lightMode, theme }) => {
    return {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: getOverwrites(theme, "background") ?? getBackgroundColor(theme, lightMode),
        borderRadius: "3px",
        margin: "0 2px",
        padding: "1px 3px",
    }
})

// Typography for the Tour ID's (e.g. "T1")
export const TourIdTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== "isNextDay" && prop !== "lightMode" })<{
    isNextDay: boolean
    lightMode: boolean
}>(({ isNextDay, lightMode, theme }) => ({
    fontWeight: 700,
    color:
        getOverwrites(theme, "foreground") ??
        (isNextDay ? theme.overwrites?.components?.erpTours?.warningColor ?? theme.palette.warning.main : getForegroundColor(theme, lightMode)),
}))

// Styled Components for NextTours.tsx
// Stack which wraps the Tours
export const ToursContainerStack = styled(Stack, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode: boolean }>(
    ({ lightMode, theme }) => {
        return {
            flexDirection: "row",
            border: `1px solid ${getOverwrites(theme, "border") || getOverwrites(theme, "background") || getBackgroundColor(theme, lightMode)}`,
            borderRadius: "3px",
            maxHeight: "50px",
            opacity: "0.87",
        }
    }
)

// Container for the first date on the left side of the component (e.g. 09:00)
export const FirstDateContainer = styled(Stack, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode: boolean }>(
    ({ lightMode, theme }) => {
        return {
            backgroundColor: getOverwrites(theme, "background") ?? getBackgroundColor(theme, lightMode),
            flexDirection: "row",
            padding: "2px",
        }
    }
)

// Triangle on the right side
export const FirstDateTriangle = styled(Box, { shouldForwardProp: (prop) => prop !== "lightMode" })<{ lightMode: boolean }>(
    ({ lightMode, theme }) => {
        return {
            backgroundColor: getOverwrites(theme, "background") ?? getBackgroundColor(theme, lightMode),
            clipPath: "polygon(0 0, 0% 100%, 100% 50%)",
            height: "49px",
            width: "23px",

            /* Fix for strange Line on the left side of the triangle:
            https://stackoverflow.com/questions/51542919/clip-path-on-chrome-leaves-a-strange-line-on-the-edge */
            transform: "skewY(0.001deg)",
        }
    }
)

// Typography for displaying the first day on the left side (e.g. "Mo" or "Monday")
export const FirstDateDay = styled(Typography, { shouldForwardProp: (prop) => prop !== "lightMode" })(({ lightMode, theme }) => {
    return {
        color: getOverwrites(theme, "foreground") ?? getForegroundColor(theme, lightMode),
        fontSize: "10px",
        marginRight: "2px",
        alignSelf: "flex-end",
    }
})
FirstDateDay.defaultProps = { fontWeight: "bold" }

// Typography for displaying the Tour dates by hours (e.g. 12:00)
export const TourDateTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== "lightMode" })(({ lightMode, theme }) => {
    return {
        // we use the background color as text color, since this text is displayed directly on the header
        color: getOverwrites(theme, "foreground") ?? getBackgroundColor(theme, lightMode),
        margin: "0 2px",
    }
})
TourDateTypography.defaultProps = { fontWeight: "bold", variant: "body3" }

// Typography for the Tour Headline (e.g. "Bestellschluss")
export const TourHeadlineTypography = styled(Typography, { shouldForwardProp: (prop) => prop !== "lightMode" })(({ lightMode, theme }) => {
    return {
        fontSize: theme?.overwrites?.components?.erpTours?.headline?.fontSize || "10px",
        fontWeight: theme?.overwrites?.components?.erpTours?.headline?.fontWeight || "400",
        color: getOverwrites(theme, "headline") ?? getBackgroundColor(theme, lightMode),
    }
})
