import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { concat } from "@tm/utils"

import { Box } from "@tm/components"
import { mapBasketPartsToParts } from "../../data/mapper/sap"
import { ExportConfiguration } from "../../data/model/sap"
import { exportCXMLBasket, exportOCIBasket } from "../../data/repositories/dms/sap"
import { ButtonWithLoader } from "./ButtonWithLoader"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

type Props = {
    workTaskId: string
    exportConfiguration: ExportConfiguration
}

export function SAPExportButton(props: Props) {
    const { workTaskId, exportConfiguration } = props

    const { translate, translateText } = useLocalization()
    const { workTaskBasketLoading, basket, erp } = useWorkTaskBasketState(workTaskId)
    const { basketOrderGroups, beingUpdatedPartIds } = basket.state

    const formWrapper = useRef<HTMLDivElement>(null)
    const [formHtml, setFormHtml] = useState<string>()
    const [exporting, setExporting] = useState(false)

    const disabled = useMemo(() => {
        return (
            !exportConfiguration ||
            workTaskBasketLoading ||
            !basketOrderGroups ||
            !basketOrderGroups.some((orderGroup) => orderGroup.basketParts.some((part) => part.partItem.orderItem?.isIncluded)) ||
            !!beingUpdatedPartIds?.length ||
            erp.basketErpInfosIndicator.isLoading ||
            !erp.basketErpInformation ||
            exporting
        )
    }, [basketOrderGroups, erp, exportConfiguration, exporting, workTaskBasketLoading, beingUpdatedPartIds?.length])

    const includedBasketParts = useMemo(() => {
        return basketOrderGroups
            ?.map((orderGroup) => orderGroup.basketParts)
            .reduce((prev, cur) => [...prev, ...cur], [])
            .filter((part) => part.partItem.orderItem?.isIncluded)
    }, [basketOrderGroups])

    const handleExport = useCallback(async () => {
        if (disabled || !basketOrderGroups || !erp.basketErpInformation) {
            return
        }

        setExporting(true)

        let promise

        if ("formMappingId" in exportConfiguration) {
            promise = exportOCIBasket({
                mappingId: exportConfiguration.formMappingId,
                httpContentCharset: exportConfiguration.formPageHttpContentCharset,
                formActionUrl: exportConfiguration.formActionUrl,
                formTarget: exportConfiguration.formReturnTarget,
                keepFormActionUrlParameters: exportConfiguration.keepFormActionUrlParameters,
                additionalFormParameters: exportConfiguration.additionalParameters,
                parts: mapBasketPartsToParts(includedBasketParts),
            })
        } else {
            promise = exportCXMLBasket({
                recipientAribaNetworkId: exportConfiguration.recipientAribaNetworkId,
                senderAribaNetworkId: exportConfiguration.senderAribaNetworkId,
                buyerCookie: exportConfiguration.buyerCookie,
                formActionUrl: exportConfiguration.formActionUrl,
                workTaskId,
                parts: mapBasketPartsToParts(includedBasketParts),
            })
        }

        promise
            .then((responseHtml) => {
                if (!responseHtml) {
                    return
                }

                const doc = document.createElement("html")
                doc.innerHTML = responseHtml

                const form = doc.getElementsByTagName("form")[0]

                if (!form) {
                    return
                }

                setFormHtml(form.outerHTML)
            })
            .catch((error) => {
                setExporting(false)

                channel("APP").publish("TOAST_MESSAGE/SHOW", {
                    message: concat(": ", translateText(1923), error),
                    skin: "danger",
                })
            })
    }, [disabled, basketOrderGroups, erp.basketErpInformation, exportConfiguration, translateText, workTaskId, includedBasketParts])

    useEffect(() => {
        const form = formWrapper.current?.firstElementChild as HTMLFormElement

        if (form?.nodeName?.toLowerCase() === "form") {
            form.submit()
            /** @todo improve to create a order voucher - for now let's just clear the basket */
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            clearBasket()
        }

        setFormHtml(undefined)
        setExporting(false)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formHtml])

    const clearBasket = useCallback(() => {
        // Do not clear the basket after cXML export
        if (!basketOrderGroups || "recipientAribaNetworkId" in exportConfiguration) {
            return
        }

        const partIds = includedBasketParts?.map((part) => part.partItem.id)

        if (partIds?.length) {
            basket.actions.removeParts(partIds)
        }
    }, [basket.actions, basketOrderGroups, includedBasketParts, exportConfiguration])

    return (
        <>
            <ButtonWithLoader
                skin="highlight"
                icon={exporting ? undefined : "return"}
                onClick={handleExport}
                disabled={disabled}
                loading={exporting}
                size="l"
            >
                {translate("formMappingId" in exportConfiguration ? 12543 : 13265)}
            </ButtonWithLoader>
            {/** @todo improve the dangerouslySetInnerHTML somehow */}
            {/* eslint-disable-next-line react/no-danger */}
            {formHtml && <Box ref={formWrapper} dangerouslySetInnerHTML={{ __html: formHtml }} sx={{ display: "none" }} />}
        </>
    )
}
