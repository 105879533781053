import { ChangeEvent, useCallback, useEffect, useMemo } from "react"
import { classes, useStyle } from "@tm/context-distribution"
import { Headline, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box, Icon, TextField, styled } from "@tm/components"
import { stepNavigationActions, technicianActions, useFastServiceStore } from "../../data"
import { VehicleAndCustomer } from "../_shared"
import NextStep from "../_shared/nextStep"
import { getComponentStyles } from "../_shared/styles"

export default function Technician() {
    const { translateText } = useLocalization()
    const classNames = getComponentStyles()
    const currentStepName = "technician"

    const firstName = useFastServiceStore((state) => state.technician.firstName)
    const lastName = useFastServiceStore((state) => state.technician.lastName)

    const nextStepActive = useMemo(() => !!firstName && !!lastName, [firstName, lastName])

    useEffect(() => {
        if (nextStepActive) {
            stepNavigationActions.completeStep(currentStepName)
            stepNavigationActions.updateNextStep(currentStepName)
        }
    }, [nextStepActive])

    const handleFormat = useCallback((value: string) => {
        return value.charAt(0).toUpperCase() + value.slice(1)
    }, [])

    const saveFirstName = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        technicianActions.setFirstName(handleFormat(event.target.value))
    }, [])

    const saveLastName = useCallback((event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        technicianActions.setLastName(handleFormat(event.target.value))
    }, [])

    return (
        <Scrollbar className={classNames.flexColumn}>
            <Box sx={{ padding: "0 1em" }} className={classNames.wrapper}>
                <VehicleAndCustomer headerText={translateText(689)} showServiceText />
                <div className={classes(classNames.flexColumn, classNames.flexCenter, classNames.flexOne)}>
                    <Icon sx={{ flex: "1", margin: "0.5em", width: "100%" }} name={currentStepName} />
                    <Box>
                        <Headline className={classes(classNames.textCenter, classNames.marginBottomL)} size="l">
                            {" "}
                            {translateText(12646)}
                        </Headline>
                        <div className={classes(classNames.marginBottomL, classNames.textCenter)}>{translateText(12647)}</div>
                    </Box>
                    <InputWrapper>
                        <TextField
                            size="extralarge"
                            value={firstName}
                            label={translateText(119)}
                            placeholder={translateText(119)}
                            onChange={saveFirstName}
                        />
                        <TextField
                            size="extralarge"
                            value={lastName}
                            label={translateText(104)}
                            placeholder={translateText(104)}
                            onChange={saveLastName}
                        />
                    </InputWrapper>
                </div>
            </Box>
            <NextStep currentStepName={currentStepName} buttonTextId={3113} icon="arrows_down" active={nextStepActive} />
        </Scrollbar>
    )
}

const InputWrapper = styled(Box)({
    marginTop: "2em",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    width: "50%",
    flex: "1",
    gap: "1em",
})
