import { DmsBundleParams } from "@tm/models"
import { registerContainer } from "../business/import"

export const version = {
    name: "dms",
    version: "1.0.0",
}

let bundleParams: DmsBundleParams

export function getBundleParams(): DmsBundleParams {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

export function initDmsBundle(params: any) {
    bundleParams = params
    registerContainer()
    return bundleParams
}
