import { Component } from "react"
import { bindSpecialReactMethods } from "@tm/utils"
import { LocalizationProps, withLocalization } from "@tm/localization"
import { ArticleAttributes } from "@tm/models"
import { PanelSection, List, Icon } from "@tm/controls"
import KeyValueItem from "./KeyValueItem"
import { KeyValueItemProps } from "../../../data/model"
import { mapAttributesToKeyValueItem } from "../../../data/mapper"

type AttributesProps = LocalizationProps & {
    attributes?: Array<ArticleAttributes>
}

class Attributes extends Component<AttributesProps> {
    constructor(props: AttributesProps) {
        super(props)
        bindSpecialReactMethods(this)
    }

    renderAttributes(attributes: ArticleAttributes) {
        const originalText = this.props.localization.translateText(1642)
        const optimizedText = this.props.localization.translateText(1643)
        const topAttributes: Array<KeyValueItemProps> = mapAttributesToKeyValueItem(attributes.topAttributes, true, originalText, optimizedText)
        const vehicleAttributes: Array<KeyValueItemProps> = mapAttributesToKeyValueItem(
            attributes.vehicleAttributes,
            false,
            originalText,
            optimizedText
        )
        const articleAttributes: Array<KeyValueItemProps> = mapAttributesToKeyValueItem(
            attributes.articleAttributes,
            false,
            originalText,
            optimizedText
        )

        return (
            <List
                view={KeyValueItem}
                items={[...topAttributes, ...vehicleAttributes, ...articleAttributes]}
                className="key-value-list key-value-list--attributes"
            />
        )
    }

    render() {
        const {
            attributes,
            localization: { translate },
        } = this.props

        if (!attributes || !attributes.length) {
            return null
        }

        let content

        if (attributes.length === 1) {
            content = (
                <PanelSection title={translate(397)} size="s">
                    {this.renderAttributes(attributes[0])}
                </PanelSection>
            )
        } else {
            content = attributes.map((value, id) => (
                <PanelSection
                    size="s"
                    key={id}
                    className="article-details__attributes"
                    title={
                        <>
                            {id + 1}.&nbsp;
                            <Icon name="car" />
                            &nbsp;
                            {translate(397)}
                        </>
                    }
                >
                    {this.renderAttributes(value)}
                </PanelSection>
            ))
        }

        return <>{content}</>
    }
}

export default withLocalization(Attributes)
