import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleSetEngineCode(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { workTask } = this.props
    const { setEngineCode } = data

    if (setEngineCode?.engineCode && workTask?.vehicle) {
        this.props.attachToWorkTask({ vehicle: { ...workTask.vehicle, engineCode: setEngineCode.engineCode } })
    }
}
