import { LinkTab, MenuItemWidget, Select, Tabs, Typography, colors, selectClasses, styled } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CategoryType, ConfigParams, ECounterType, RegisteredModels, ViewState } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { TmaHelper, getCategoryTypeFromUrl, renderRoute } from "@tm/utils"
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { Link } from "react-router-dom"
import { SectionType } from "../../../../../business"
import { useUniSearchStatus } from "../../../../../helper"
import { SectionUrl } from "../../../../navigation/business"
import { usePartsRoutes } from "../../../hooks/usePartsRoutes"
import { useVehicle } from "../../../hooks/useVehicle"

type NavigationItem = {
    categoryType: CategoryType
    text: ReactNode
    disabled?: boolean
}

const AreaSelect = styled(Select)({
    [`.${selectClasses.select}`]: {
        padding: "6px 16px 6px 28px !important",
    },
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: colors.grey[400],
    },
})

type NavigationProps = {
    areasAsDropdown?: boolean
    areasAsTabs?: boolean
    alwaysListRoute?: boolean
    hideAreas?: boolean
    hidePageButtons?: boolean
}

export function Navigation(props: NavigationProps) {
    const { alwaysListRoute, areasAsDropdown, areasAsTabs, hideAreas, hidePageButtons } = props
    const { translate } = useLocalization()
    const vehicle = useVehicle()
    const { workTaskId } = useWorkTask() ?? {}
    const { userContext } = useUser()
    const uniSearchIsActive = useUniSearchStatus()
    const location = useLocation()
    const history = useHistory()
    const routeMatch = useRouteMatch<{ section: string; view: string }>()
    const activeCategoryType = getCategoryTypeFromUrl(location.pathname)
    const [urls, setUrls] = useState<Record<string, Record<string, SectionUrl>>>({})
    const routes = usePartsRoutes()

    useEffect(() => {
        if (!hideAreas && (!routes || !routes.vehicleParts || !routes.universalParts || !routes.directSearch)) {
            // eslint-disable-next-line no-console
            console.error(`Parts-Navigation: 'routes' was not passed as property in the app config or doesn't contain valid routes`)
        }

        Container.getInstance(RegisteredModels.ViewState).action("loadViewState")(`${workTaskId}__ACTIVE_URLS`).then(setUrlFromViewState)
    }, [])

    const setUrlFromViewState = useCallback((response: ViewState) => {
        const responseUrls: Record<string, Record<string, SectionUrl>> = response?.value ?? {}
        const { view } = routeMatch.params

        if (view) {
            if (!responseUrls[activeCategoryType]) {
                responseUrls[activeCategoryType] = {}
            }

            // Die aktuelle URL sollte die gespeicherte überschreiben
            responseUrls[activeCategoryType][view] = {
                isActive: true,
                url: `${location.pathname}${location.search || ""}`,
            }
        }
        setUrls(responseUrls)
    }, [])

    useEffect(() => {
        const { view } = routeMatch.params
        const sectionUrl = urls[activeCategoryType]?.[view]

        if (!sectionUrl) {
            return
        }

        const currentUrl = `${location.pathname}${location.search}`
        if (sectionUrl.url !== currentUrl) {
            setUrls({
                ...urls,
                [activeCategoryType]: {
                    ...urls[activeCategoryType],
                    [view]: {
                        ...sectionUrl,
                        url: currentUrl,
                    },
                },
            })
        }
    }, [routeMatch.params, urls])

    useEffect(() => {
        if (urls && workTaskId) {
            Container.getInstance(RegisteredModels.ViewState).action("saveViewState")({ key: `${workTaskId}__ACTIVE_URLS`, value: urls })
        }
    }, [urls, workTaskId])

    const navigationItems = useMemo(() => {
        const result: NavigationItem[] = [
            { categoryType: "vehicleParts", text: translate(449), disabled: !vehicle || userContext?.parameter?.catalogLight },
            { categoryType: "directSearch", text: translate(389) },
        ]

        if (uniSearchIsActive) {
            result.push({ categoryType: "universalParts", text: translate(1009) })
        }

        return result
    }, [uniSearchIsActive, userContext?.parameter?.catalogLight, vehicle])

    const getCategoryUrl = useCallback(
        (categoryType: CategoryType, fallbackSectionType: SectionType): string => {
            const { articleDetailsInModal } = Morpheus.getParams<ConfigParams>()
            const categoryUrls = urls[categoryType]

            if (categoryUrls) {
                let activeSectionUrl: string | undefined

                Object.keys(categoryUrls)
                    .map((key) => {
                        return { type: key, url: categoryUrls[key] }
                    })
                    .forEach((x) => {
                        if (alwaysListRoute || articleDetailsInModal) {
                            if (x.type === "list") {
                                activeSectionUrl = x.url.url
                            }
                            return
                        }
                        if (x.url.isActive) {
                            activeSectionUrl = x.url.url
                        }
                    })

                if (activeSectionUrl) {
                    return activeSectionUrl
                }
            }
            return renderRoute(routes[categoryType][fallbackSectionType], routeMatch.params)
        },
        [alwaysListRoute, routeMatch.params, routes, urls]
    )

    const handleChange = useCallback(
        (categoryType: CategoryType) => {
            const url = urls[categoryType]?.list?.url ?? getCategoryUrl(categoryType, "list")
            TmaHelper.Shared.ByArticleAndUniParts.CategoryChanged(categoryType)
            TmaHelper.GeneralCountEvent.Call(ECounterType.ArticleListAreaSwitch)

            history.push(url)
        },
        [history, urls, activeCategoryType, routeMatch]
    )

    if (areasAsTabs) {
        return (
            <Tabs
                value={navigationItems.find((x) => x.categoryType === activeCategoryType)?.categoryType}
                onChange={(_e, v) => handleChange(v as CategoryType)}
            >
                {navigationItems.map((x) => (
                    <LinkTab component={Link} key={x.categoryType} value={x.categoryType} label={x.text as string} disabled={x.disabled} />
                ))}
            </Tabs>
        )
    }

    return (
        <AreaSelect
            variant="outlined"
            name="test"
            value={navigationItems.find((x) => x.categoryType === activeCategoryType)?.categoryType}
            onChange={(e) => handleChange(e.target.value as CategoryType)}
        >
            {navigationItems.map((x) => (
                <MenuItemWidget key={x.categoryType} value={x.categoryType} disabled={x.disabled}>
                    {x.text}
                </MenuItemWidget>
            ))}
        </AreaSelect>
    )
}
