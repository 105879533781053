import { Badge, Box, Modal, Icon, Button } from "@tm/components"
import { useCisReturnItemsTotalNumbers } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { createQueryString, renderRoute } from "@tm/utils"
import { useMemo } from "react"
import { useHistory, useLocation, useParams } from "react-router"
import { getBundleParams } from "../../../../../utils"

// TODO: this is a temporary workaround. Should be removed when the Monolith is implemented. The reference to components of other bundles won't be a problem with the monolith.
import ReturnsComponent from "../../../../../../../cis/src/components/returns/component"

type RouteParams = {
    workTaskId?: string
    type?: string
    id?: string
}

export default function ReturnsButton() {
    const { translateText } = useLocalization()
    const { totalNumbers } = useCisReturnItemsTotalNumbers()
    const history = useHistory()
    const matchParams = useParams<RouteParams>()
    const location = useLocation()
    const { vouchersDetailsRoute } = getBundleParams()

    const showReturns = useMemo(() => {
        const queryParams = new URLSearchParams(location.search)
        return !!queryParams.get("showReturns")
    }, [location.search])

    function openReturns() {
        const ordersUrlParams = {
            workTaskId: matchParams.workTaskId,
            type: matchParams.type,
            id: matchParams.id,
        }

        let url = renderRoute(vouchersDetailsRoute, ordersUrlParams)
        url += createQueryString({ showReturns: true })
        history.push(url)
    }

    function closeReturns() {
        const queryParams = new URLSearchParams(location.search)
        queryParams.delete("showReturns")

        let url = renderRoute(vouchersDetailsRoute, matchParams)
        url += queryParams
        history.push(url)
    }

    return (
        <Box>
            <Box zIndex={1} position="relative">
                <Badge
                    size="small"
                    badgeContent={totalNumbers?.numberOfPositions}
                    sx={{ position: "absolute", marginLeft: "20px", marginTop: "3px" }}
                />
            </Box>
            <Button title={translateText(1515)} onClick={openReturns}>
                <Icon name="return" />
            </Button>
            <Modal open={showReturns} onOutsideClick={closeReturns}>
                <ReturnsComponent fromVouchersModule onOpenPartDetails={closeReturns} />
            </Modal>
        </Box>
    )
}
