import { Availability, AvailabilityStatus, ShipmentMode, Warehouse } from "@tm/models"
import { useLocalization } from "@tm/localization"

type Props = {
    availability: Availability
    warehouses?: Array<Warehouse>
    warehouseTableCurrentItem?: Warehouse
    selectedShipmentMode?: ShipmentMode
}

export function AvailabilityTooltip({ availability, warehouses, warehouseTableCurrentItem, selectedShipmentMode }: Props) {
    const { date, currency, translateText } = useLocalization()
    const text = availability?.shortDescription || availability?.description || ""

    switch (availability.type) {
        case AvailabilityStatus.NightExpress:
            let wh = warehouses?.find((w) => w.nightExpressDelivery)
            if (!wh && warehouseTableCurrentItem?.nightExpressDelivery) {
                wh = warehouseTableCurrentItem
            }

            if (wh) {
                const timeLimit = wh.nightExpressDelivery?.[0]?.tours?.[0]?.tourOrderAcceptanceTimeLimit
                const costs = wh.nightExpressDelivery?.[0]?.costs

                return (
                    <>
                        <div>{translateText(1288)}</div>
                        {timeLimit && (
                            <div>
                                {translateText(1129)} {date(timeLimit, "t")}
                            </div>
                        )}
                        <div>
                            {translateText(3138)}: {costs?.value ? currency(costs.value, costs.currencySymbol) : translateText(13460)}
                        </div>
                    </>
                )
            }
            break
        case AvailabilityStatus.ManufacturerAvailable:
            let teccomWh = warehouses?.find((w) => w.isManufacturerWarehouse)
            if (!teccomWh && warehouseTableCurrentItem?.isManufacturerWarehouse) {
                teccomWh = warehouseTableCurrentItem
            }

            return (
                <>
                    <div>{translateText(13461)}</div>
                    <div>
                        {selectedShipmentMode?.costs?.value
                            ? `${translateText(3138)}: ${currency(selectedShipmentMode?.costs.value, selectedShipmentMode?.costs.currencySymbol)}`
                            : translateText(13138)}
                    </div>
                </>
            )
        default:
            return (
                <div>
                    {text
                        .replace("VH_Verfuegbar", translateText(13403))
                        .replace("Verfuegbar_Nicht_VH", translateText(13404))
                        .replace("Teillieferung", translateText(13405))
                        .replace("Rueckstand_Sonderbeschaffung", translateText(13406))}
                </div>
            )
            break
    }

    return <>{availability.description || availability.shortDescription}</>
}
