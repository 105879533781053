/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, LinkButton, Stack, Typography, styled } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, uniqueId } from "@tm/utils"
import { ReactNode, useState } from "react"
import { b64EncodeUnicode } from "./helper"
import Newsletter from "./components/Newsletter"

const FooterHeader = styled(Typography)(() => ({
    marginBottom: "0.25em",
}))
FooterHeader.defaultProps = { variant: "h2" }

const FooterColumn = styled(Stack)(() => ({
    margin: "1em 2em",
}))

const StyledLink = styled(LinkButton)(() => ({
    textDecoration: "none",
    "&:hover": { textDecoration: "underline" },
    ":only-child": { padding: 0 },
}))

const FooterLink = (props: { children: ReactNode; to: string; onClick?: React.MouseEventHandler<HTMLButtonElement> }) => {
    return (
        <StyledLink to={props.to} onClick={props.onClick} variant="text" size="small" rel="noopener noreferrer">
            {props.children}
        </StyledLink>
    )
}

export default function StartFooterComponent() {
    const { userContext } = useUser() || {}
    const { translate } = useLocalization()

    const [dialogActive, setDialogActive] = useState(false)

    let baseUrl = ""
    let portalQuery = ""
    let imprint = ""
    let className = "logoPanel_wm"
    let privacy = ""
    let termsAndConditions = ""
    let email = ""
    let phoneNumber = ""

    const { env } = Morpheus.getParams<ConfigParams>()
    const portalDomain = env === "dev" || env === "test" ? "https://test.wmkat.de" : "https://wmkat.de"

    if (userContext && userContext.externalAuthenticationTokens.WMPortalToken !== undefined) {
        const [trader, custNo, username, pwd] = userContext.externalAuthenticationTokens.WMPortalToken.split("¬")
        baseUrl = `/external01?url=${escape(`${portalDomain}/Anmelden.aspx?btoaUTF8=`)}`
        portalQuery = `kdnr=${(trader === "trost" ? "T" : "") + custNo}&benutzer=${username}&passwort=${pwd}`
    }

    if (userContext?.system.traderDescription === "WM-NL") {
        className = "logoPanel_wm wm-nl"
    } else if (userContext?.system.traderDescription === "WM-Austria") {
        className = "logoPanel_wm wm-at"
    } else if (userContext?.system.traderDescription === "WMKAT+ DK") {
        className = "logoPanel_wm wm-dk"
    }

    /* imprint */
    if (userContext?.system.traderDescription === "WM-Austria") {
        imprint = `${portalDomain}/IndexImpressum/NextAt`
    } else {
        imprint = `${portalDomain}/IndexImpressum/Next`
    }

    /* privacy */

    if (userContext?.system.traderDescription === "WM-Austria") {
        privacy = `${portalDomain}/IndexDatenschutz/NextAt`
    }
    if (userContext?.system.traderDescription === "WMKAT+ DK") {
        privacy = "https://media.carparts-cat.com/webdata6/rdimages/571/1a_Informationen_Internet_dk_V8-da.pdf "
    } else {
        privacy = `${portalDomain}/IndexDatenschutz/Next`
    }

    /* terms and conditions */
    if (userContext.parameter?.urlAGB) {
        termsAndConditions = userContext.parameter.urlAGB
    } else {
        if (userContext?.system.traderDescription === "WM-Austria") {
            termsAndConditions = "https://pdf.wmkat.de/AGB%20AT_2020.pdf"
        }
        if (userContext?.system.traderDescription === "WMKAT+ DK") {
            termsAndConditions = "https://media.carparts-cat.com/webdata6/rdimages/571/Salgs-og_leveringsbetingelser_WM_Autodele.pdf"
        } else {
            termsAndConditions = "https://pdf.wmkat.de/AGB_WM_SE_2017_06.pdf"
        }
    }

    /* email */
    if (userContext?.system.traderDescription === "WMKAT+ DK") {
        email = "info@wm-autodele.dk"
    } else {
        email = "wmkat@wm.de"
    }

    /* phoneNumber */
    if (userContext?.system.traderDescription === "WMKAT+ DK") {
        phoneNumber = "+45 76 36 35 00"
    } else {
        phoneNumber = "+49 (0)541 9989 - 14530"
    }

    const handleNewsletterClick = () => {
        setDialogActive((prev) => !prev)
    }

    return (
        <Box className="bnd-wm" width="100%">
            <hr className="topBorder" />
            <Stack direction="row" display="flex" justifyContent="center">
                <FooterColumn>
                    <div className={className} />
                </FooterColumn>
                {userContext?.system.traderDescription !== "WM-Austria" && (
                    <FooterColumn>
                        <FooterHeader>{translate(355)}</FooterHeader>
                        <Stack ml="0.5em" spacing="-0.25em">
                            <FooterLink to="/external01?url=https://blog.wm.de">{translate(13198)}</FooterLink>
                            {userContext?.system.traderDescription === "WM SE" && (
                                <FooterLink to="/" onClick={handleNewsletterClick}>
                                    {translate(13199)}
                                </FooterLink>
                            )}
                            <Newsletter dialogActive={dialogActive} closeDialog={handleNewsletterClick} />
                            <FooterLink to={baseUrl + b64EncodeUnicode(`${portalQuery}&42=997`)}>{translate(1297)}</FooterLink>
                            <FooterLink to={baseUrl + b64EncodeUnicode(`${portalQuery}&42=998`)}>{translate(1204)}</FooterLink>
                            <FooterLink to={`${encodeUniqueId(uniqueId())}/blaetterkataloge`}>{translate(12709)}</FooterLink>
                        </Stack>
                    </FooterColumn>
                )}
                <FooterColumn>
                    <FooterHeader>{translate(13196)}</FooterHeader>
                    <Stack ml="0.5em" spacing="-0.25em">
                        <FooterLink to={`/external01?url=${imprint}`}>{translate(1236)}</FooterLink>
                        <FooterLink to={`/external01?url=${termsAndConditions}`}>{translate(831)}</FooterLink>
                        <FooterLink to={`/external01?url=${privacy}`}>{translate(417)}</FooterLink>
                    </Stack>
                </FooterColumn>
                <FooterColumn>
                    <FooterHeader>{translate(13197)}</FooterHeader>
                    <Stack ml="0.5em">
                        <Typography variant="label">
                            <img
                                className="wmContactsIcon"
                                alt="email"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4yMfEgaZUAAAEMSURBVChTbdFLK6ZhHAbwx2FhFBtWmOwmG3ZKydLKF1A2ko3NKJNSfAZlMTUbho1EWcw3sHNcOKxsaLKRSGLhNPldj1e9U6761XM/3af//y6q8oUf7PHAM39Zpof/8pUTnvjNMEN855BHRinTQH7e0l8ZD9BOZ8Ufsll50iT/GKGDU165IZus0kYW/KLY4Zxa1sn9uxkkG82THLGdj3vWyIJM3iBpIQtmytH7xrv5SDeWaCITFkh6yXiCOq5YoThjixouOeYbud7HlaZ4IU0pfpJTMmmMFJeip9kni+4Yp0ze4JoUlRa2kpYm9XTRWI6q0scFKToNmGOWRQ74NM3kZfNImZSObJKiKymKNzhMRQw2NkcoAAAAAElFTkSuQmCC"
                            />
                            {email}
                        </Typography>
                        <Typography variant="label">
                            <img
                                className="wmContactsIcon"
                                alt="phone"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAABGdBTUEAALGPC/xhBQAAAAlwSFlzAAAOwgAADsIBFShKgAAAABl0RVh0U29mdHdhcmUAcGFpbnQubmV0IDQuMC4yMfEgaZUAAACiSURBVChTjdIxDgFBFIfxF1GIEoXSKehVEi6wEoXGBah1onEAbqDQqVzBqlQKlQPoFBrx/TcziexuZnzJr5iX97oxVwsn7LJXpCpSfJwxgg3hl+WMYHP8HqwRbAK/vNEgVhtv6GCpwT9toYMnOhrEauABHV3RhKpgin72ytXDCzq6IcHevWWFQgP4ozIjFOrijvzyBfoRpdUwwwFHLFA3M/sCoQguV7/LK7oAAAAASUVORK5CYII="
                            />
                            {phoneNumber}
                        </Typography>
                        {userContext?.system.traderDescription !== "WMKAT+ DK" && (
                            <Typography variant="label">
                                <img
                                    className="wmContactsIcon"
                                    alt="printer"
                                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAYAAABWdVznAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjAuMjHxIGmVAAAA8ElEQVQoU4WSMWrDMBSGY0vavPYUHQ1ZSvDQM2jNmuYALYFSGjJkyBBo6Q1iaAafoFMhs+nYLUvIlNKlSz2E5HsiKkI1ZPiQ9P73IfHsjrVWCUmSfEATUKZpeu9zjxOKotA07GgYaK0LwRhzSe0XJpIL/wQauz481TZw8FIsfMVCnucmy7ILspnsncChhjXsYcqzbiJG1OUW6alF+IHxqXiORgSZSFvYhhPkhjlMoqcMAx7IX2ArwjdUFG9Zn+E9YkHWZ13Bpwhv4AR4ZF9GzAPh1Y2VTQUzxnqllLoOodZDuBPh7ztweILwt2hjaa1VR6+ZbCEjAW+AAAAAAElFTkSuQmCC"
                                />
                                +49&nbsp;(0)541&nbsp;9989&nbsp;-&nbsp;14534
                            </Typography>
                        )}
                    </Stack>
                </FooterColumn>
            </Stack>
            <br />
        </Box>
    )
}
