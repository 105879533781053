import { NavigationState } from "./model"
import { BundleActionTypes, BundleActions } from "../../../business"
import { WheelSelectionSteps } from "../../../data/enums"
import { Article } from "@tm/models"

export type ComponentActionType = BundleActionTypes

const DEFAULT_STATE: NavigationState = {
	activeStep: WheelSelectionSteps.NONE,
	selectedTabs: {}
}

export function reduce(state = { ...DEFAULT_STATE }, action: ComponentActionType): NavigationState {
	switch (action.type) {
		case "CHANGE_STEP": {
			let { step, params, disableNextSteps, quantity, enablePreviousStep } = action.payload
			let { selectedTabs } = { ...state }

			if (step) {
				if ((step == WheelSelectionSteps.RDKSLIST || step == WheelSelectionSteps.RDKSDETAILS) && !state.isRDKSAvailable)
					step = WheelSelectionSteps.TIRESLIST
			}

			if (disableNextSteps) {
				Object.keys(selectedTabs).forEach((tab, index) => {
					if (index > step)
						selectedTabs[tab].wasSelected = false
				})
			}

			if (enablePreviousStep) {
				for (let i = 0; i < step; i++) {
					selectedTabs[i] = { ...selectedTabs[i], wasSelected: true }
				}
			}

			return {
				...state,
				activeStep: step,
				selectedTabs: {
					...selectedTabs,
					[step]: {
						wasSelected: true,
						quantity: quantity ?? selectedTabs?.[step]?.quantity,
						params: {
							...selectedTabs?.[step]?.params,
							...params
						}
					}
				}
			}
		}
		case "SEND_SENSOR_ITEMS_RESPONSE": {
			return {
				...state,
				isRDKSAvailable: !!action.payload.length
			}
		}
		case "CHANGE_ARTICLE_QUANTITY": {
			const quantity = action.payload.quantity
			return {
				...state,
				selectedTabs: {
					...state.selectedTabs,
					[state.activeStep]: {
						...state.selectedTabs[state.activeStep],
						quantity: quantity
					}
				}
			}
		}
	}

	return state
}

function sendTireToOverview(article: Article): ComponentActionType {
	return {
		type: "SEND_TIRE_TO_OVERVIEW", payload: article
	}
}

function sendSensorToOverview(article: Article): ComponentActionType {
	return {
		type: "SEND_SENSOR_TO_OVERVIEW", payload: article
	}
}

export type IActions = typeof Actions

export const Actions = {
	...BundleActions,
	sendTireToOverview,
	sendSensorToOverview
}