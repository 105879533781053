import { Button } from "@tm/controls"
import { OE } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { TmaHelper } from "@tm/utils"
import { FC, useCallback, useMemo } from "react"
import { Tooltip } from "@tm/components"

type Props = {
    oeNumber: OE.OeNumber
    onSelect(oeNumber: OE.OeNumber): void
    hasSelectedReplacement?: boolean
    hideDescription?: boolean
}

const OeNumber: FC<Props> = (props) => {
    const { translateText } = useLocalization()
    const { oeNumber, onSelect, hasSelectedReplacement, hideDescription } = props
    const { number, description, additionalDescription, isSelected } = oeNumber

    const value = description && !hideDescription ? [description, number] : [number]

    useMemo(() => {
        isSelected && TmaHelper.ArticleListFiltered.ArticleListFiltered.List.OePositionChanged(number)
    }, [oeNumber])

    const handleClick = useCallback(() => (!isSelected || hasSelectedReplacement) && onSelect(oeNumber), [isSelected, oeNumber, onSelect])

    const button = (
        <Button
            className="oe-positions__number"
            isActive={isSelected || hasSelectedReplacement}
            layout={!isSelected || hasSelectedReplacement ? ["holo"] : undefined}
            onClick={handleClick}
        >
            {value.join(" | ")}
            {additionalDescription && !hideDescription && (
                <>
                    {" "}
                    | <strong>{additionalDescription}</strong>
                </>
            )}
        </Button>
    )

    if (!isSelected || hasSelectedReplacement) {
        return <Tooltip title={translateText(1813).replace("{0}", translateText(758))}>{button}</Tooltip>
    }

    return button
}

export default OeNumber
