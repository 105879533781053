import { WholesalerPart } from "@tm/models"
import { IArticle } from "../models"

export const mapArticleToWholesalerPart = (translate: (key: string | number) => string, art: IArticle): WholesalerPart =>
    art && {
        quantityValue: art.quantity,
        wholesalerArticleNumber: art.traderArticleNumber,
        memo: `${translate(21)}: ${art.plateNumber}; ${art.description}`,
        // description: art.plateNumber
        // memo: art.description,
        // additionalDescription: art.description
    }
