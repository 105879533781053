import { AsyncAction } from "@tm/morpheus"
import { AlldataState } from "./model"
import * as Data from "../../../data"

export * from "./model"

export type ComponentActionType =
    | { type: "TOKEN_LOADED"; payload: { token: string; isRegistered: boolean } }
    | { type: "AUTHENTICATION_ERROR"; payload: { url?: string; message?: string } }

const DEFAULT_STATE: AlldataState = {
    isRegistered: false,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): AlldataState {
    switch (action.type) {
        case "TOKEN_LOADED": {
            return {
                ...state,
                token: action.payload.token,
                isRegistered: action.payload.isRegistered,
                authenticationErrorUrl: undefined,
                authenticationError: false,
            }
        }
        case "AUTHENTICATION_ERROR": {
            return {
                ...state,
                token: undefined,
                isRegistered: false,
                authenticationErrorUrl: action.payload.url,
                authenticationErrorMessage: action.payload.message,
                authenticationError: true,
            }
        }
    }

    return state
}

export interface IActions {
    getToken(checkRegistrationServiceUrl: string, authenticationServiceUrl: string): void
}

function getToken(checkRegistrationServiceUrl: string, authenticationServiceUrl: string): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        Data.getAllDataSession()
            .then((token) => {
                Data.checkAlldataRegistration(checkRegistrationServiceUrl, token).then((isRegistered) => {
                    if (isRegistered === true) {
                        Data.authenticateAlldata(authenticationServiceUrl, token).then((response) => {
                            if (response) {
                                if (response.errorCode) {
                                    let errorUrl = ""
                                    if (response.links && response.links.length) {
                                        errorUrl = response.links[0].href
                                    }

                                    dispatch({ type: "AUTHENTICATION_ERROR", payload: { url: errorUrl, message: response.errorMessage } })
                                } else {
                                    dispatch({ type: "TOKEN_LOADED", payload: { token, isRegistered: true } })
                                }
                            }
                        })
                    } else {
                        dispatch({ type: "TOKEN_LOADED", payload: { token, isRegistered: false } })
                    }
                })
            })
            .catch((error) => {
                dispatch({ type: "AUTHENTICATION_ERROR", payload: { url: undefined, message: error.message } })
            })
    }
}

export const Actions: IActions = {
    getToken,
}
