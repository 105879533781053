import { useCallback, Fragment } from "react"
import { useLocalization } from "@tm/localization"
import { Text, Button, TextModifiers } from "@tm/controls"
import { PriceType, Article, ErpPrice, Quantity, ErpInformation, MemoType } from "@tm/models"
import { useUser, useHidePriceTypes } from "@tm/context-distribution"
import { concat } from "@tm/utils"
import { ErpPriceAdditionalInfo, Icon, Tooltip } from "@tm/components"
import { getBundleParams } from "../../utils"
import { getPriceDescription } from "../../business"
import GraduatedPricesDropdown from "../graduated-prices-dropdown"
import EkTooltip from "./components/ek-tooltip"
import { getPrice } from "./helper"
import Priceunit from "./components/priceunit"
import VkTooltip from "./components/vk-tooltip"
import { ErpIconWrapper } from "./components/ErpIconWrapper"
import { PromotionalIcon } from "./components/promotional-icon"
import { InfoIcon, RowStack } from "./components/StyledComponents"

type PricesProps = {
    classPrefix: string
    priceNamesInTooltip?: boolean
    article: Article

    prices: Array<ErpPrice>
    erpInfo?: ErpInformation
    hasGraduatedPrices: boolean
    hideGraduatedPricesIcon?: boolean
    hideRetailPrice?: boolean
    hideMemos?: boolean
    hideSpecialIcons?: boolean
    graduatedPrices?: Array<ErpPrice>
    onGraduatedPricesClick?(): void

    quantity?: Quantity
    isReplacementPart: boolean

    showAdditionalPrices?: boolean
    foundBySearchTerm?: string
}

export default function PricesComponent(props: PricesProps) {
    const {
        article,
        classPrefix,
        erpInfo,
        graduatedPrices,
        hasGraduatedPrices,
        hideGraduatedPricesIcon,
        hideMemos,
        hideRetailPrice,
        hideSpecialIcons,
        isReplacementPart,
        onGraduatedPricesClick,
        priceNamesInTooltip,
        prices,
        quantity,
        showAdditionalPrices,
    } = props
    const { translateText, currency } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const { priceOrder, hidePurchasePriceIfReplacementArticlesAvailable, showVkAndRebateInEkPriceTooltip } = getBundleParams()
    const { isPriceTypeHidden } = useHidePriceTypes()

    const hidePurchasePrice = useCallback(
        (price: ErpPrice | undefined) => {
            return !!(
                hidePurchasePriceIfReplacementArticlesAvailable &&
                price &&
                erpInfo?.hasReplacementArticles &&
                price.type === PriceType.Purchase
            )
        },
        [erpInfo?.hasReplacementArticles, hidePurchasePriceIfReplacementArticlesAvailable]
    )

    if (!prices?.length) {
        return null
    }

    function renderSingleColumnPrice(price: ErpPrice | undefined) {
        if (!price || hidePurchasePrice(price)) {
            return null
        }

        const priceStyles = getBundleParams().oneColumnPriceStyles
        const { modifiers, size, descriptionInText } = { ...priceStyles.default, ...priceStyles[price.type] }

        let text = currency(price.value, price.currencySymbol || "")
        let showPriceName = true
        const description = price.description || getPriceDescription(translateText, price.type, isReplacementPart)

        if (isPriceTypeHidden(price.type)) {
            showPriceName = false
            text = ""
        } else if (descriptionInText) {
            text = `${price.description || (typeof descriptionInText === "number" ? translateText(descriptionInText) : "")} ${text}`
            showPriceName = false
        }

        let tooltip
        if (isPriceTypeHidden(price.type)) {
            tooltip = <EkTooltip prices={prices} showVkAndRebate={showVkAndRebateInEkPriceTooltip} />
        } else if (description === " ") {
            tooltip = <div className="icon price__icon icon--m" />
        } else if (showPriceName && priceNamesInTooltip !== false) {
            let tooltipContent: JSX.Element | string | undefined = description
            if (!price.taxIncluded && price.valueTaxIncluded) {
                tooltipContent = `${description}\n\n${translateText(12851)}: ${currency(price.valueTaxIncluded, price.currencySymbol || "")}`
            }

            tooltip = (
                <Tooltip title={tooltipContent}>
                    <InfoIcon />
                </Tooltip>
            )
        }

        if (!showVkAndRebateInEkPriceTooltip) {
            tooltip = <ErpPriceAdditionalInfo price={price} additionalInfoIconSize={18} />
        }

        return (
            <div className="price" key={price.type}>
                <RowStack>
                    {showPriceName && priceNamesInTooltip === false && (
                        <Text className="price__name" size={size}>
                            {description}
                        </Text>
                    )}
                    {!!text && (
                        <Text className="price__value" size={size} modifiers={modifiers}>
                            {text}
                        </Text>
                    )}
                    {tooltip}
                </RowStack>
                {getBundleParams().showPurchasePriceRebate &&
                    price.type === PriceType.Purchase &&
                    !!price.rebate &&
                    !!userSettings?.showPurchasePrice && (
                        <Text className="price__rebate" size="xs">
                            {translateText(54)}: {price.rebate}%
                        </Text>
                    )}
            </div>
        )
    }

    function renderTwoColumnPrice(price: ErpPrice | undefined) {
        if (!price || hidePurchasePrice(price)) {
            return null
        }

        const { twoColumnPriceUseDescriptionFromService: useServicePrice, twoColumnPriceStyles: priceStyles } = getBundleParams()

        let { modifiers, size, descriptionInText } = { ...priceStyles.default, ...priceStyles[price.type] }
        let className = "price price--two-column"
        const descriptionModifiers: Array<TextModifiers> = []
        let text = currency(price.value, price.currencySymbol || "")
        let description
        let priceunit

        if (isPriceTypeHidden(price.type)) {
            text = ""
            className += " price--info-only"
        } else if (price.type === PriceType.Purchase) {
            description = useServicePrice ? price.description : translateText(55)
            if (price.priceUnit && price.priceUnit > 1) {
                priceunit = (
                    <Text modifiers={["sub"]} size="xs" className="price__unit">
                        {translateText(1312)} {price.priceUnit}
                    </Text>
                )
            }

            if (getBundleParams().highlightEkDescription) {
                descriptionModifiers.push("highlight")
            }
        } else if (price.type === PriceType.Retail) {
            description = useServicePrice ? price.description : translateText(1620)
        } else if (price.type === PriceType.RecommendedRetail) {
            description = useServicePrice ? price.description : translateText(1144)
        } else if (price.type === PriceType.Replacement && isReplacementPart) {
            modifiers = ["sub"]
            text = `${translateText(1614)} ${text}`
            size = "xs"
        } else if (price.type === PriceType.Replacement) {
            description = useServicePrice ? price.description : translateText(1538)
        } else if (descriptionInText) {
            text = `${descriptionInText === true ? price.description : translateText(descriptionInText)} ${text}`
        }
        let tooltip
        if (price.type === PriceType.Purchase) {
            tooltip = <EkTooltip prices={prices} memos={price.memos} showVkAndRebate={showVkAndRebateInEkPriceTooltip} />
        } else if (price.type === PriceType.Retail) {
            tooltip = <VkTooltip price={price} />
        } else if (price.memos && !hideMemos) {
            tooltip = price.memos
                .filter((x) => x.type === MemoType.AdditionalDescription)
                .map((memo, idx) => (
                    <Tooltip title={memo.text} key={`hint_${idx}`}>
                        <InfoIcon />
                    </Tooltip>
                ))
        }

        if (!showVkAndRebateInEkPriceTooltip) {
            tooltip = <ErpPriceAdditionalInfo price={price} additionalInfoIconSize={18} />
        }

        return (
            <Fragment key={price.type}>
                <div className={className}>
                    <Text size="s" modifiers={descriptionModifiers}>
                        {description}
                    </Text>
                    <RowStack>
                        <Text className="price__value" size={size} modifiers={modifiers}>
                            {text}
                        </Text>
                        {tooltip}
                    </RowStack>
                    {priceunit}
                </div>
                {price.memos &&
                    !hideMemos &&
                    price.memos
                        .filter((x) => x.type === MemoType.Note)
                        .map((memo, idx) => (
                            <Text size="xs" className="memo" key={`memo_${idx}`} modifiers={["block", "sub"]}>
                                {memo.text}
                            </Text>
                        ))}
            </Fragment>
        )
    }

    function renderGraduatedPricesDropdown() {
        if (getBundleParams().graduatedPricesLayout !== "dropdown" || !graduatedPrices?.length) {
            return null
        }

        return (
            <div className="price" key="graduated-price-dropdown">
                <GraduatedPricesDropdown layout="text" graduatedPrices={graduatedPrices} article={article} erpInfo={erpInfo} />
            </div>
        )
    }

    function renderPriceByString(str: string | number, renderPrice: (price: ErpPrice | undefined) => JSX.Element | null) {
        if (typeof str === "string") {
            switch (str.toUpperCase()) {
                case "GRADUATED_PRICES":
                    return renderGraduatedPricesDropdown()
                case "VK_OR_UVP":
                    if (!hideRetailPrice) {
                        return renderPrice(getPrice(prices, PriceType.Retail) || getPrice(prices, PriceType.RecommendedRetail))
                    }
                    return null
                case "EK_PRICEUNIT":
                    return <Priceunit key={str} price={getPrice(prices, PriceType.Purchase)} quantity={quantity} />
                case "MEMO_TYPE_5":
                    return erpInfo?.memos
                        ?.filter((memo) => memo.type === MemoType.AdditionalDescription)
                        .map((memo, idx) => (
                            <Text key={idx} size="xs">
                                {memo.text}
                            </Text>
                        ))
                default:
                    break
            }
        }

        const num = typeof str === "string" ? parseInt(str) : str
        const price = getPrice(prices, num)
        return renderPrice(price)
    }

    function renderPrices() {
        if (!priceOrder.length) {
            return null
        }

        if (priceOrder.length > 1) {
            return (
                <div className="price__group__main">
                    <div className="price__group__wrapper">
                        {priceOrder.map((arr, idx) => {
                            return (
                                <div className="price__group price__group--two-column" key={`price__group-${idx}`}>
                                    {arr.map((str) => renderPriceByString(str, renderTwoColumnPrice))}
                                </div>
                            )
                        })}
                    </div>
                    {renderPriceOrderFullWidth()}
                </div>
            )
        }

        return <div className="price__group">{priceOrder[0].map((str) => renderPriceByString(str, renderSingleColumnPrice))}</div>
    }

    function renderPriceOrderFullWidth() {
        const { priceOrderFullWidth } = getBundleParams()

        if (!priceOrderFullWidth?.length) {
            return null
        }

        const content = priceOrderFullWidth.map((str) => renderPriceByString(str, renderTwoColumnPrice)).filter((x) => !!x)

        if (!content.length) {
            return null
        }

        return <div className="price__group price__group--two-column">{content}</div>
    }

    function renderGraduatedPricesIcon() {
        const { graduatedPricesLayout } = getBundleParams()

        let button
        if (hasGraduatedPrices && graduatedPricesLayout === "icon") {
            button = <Button onClick={onGraduatedPricesClick} icon="staffelpreis" layout={["ghost"]} size="l" />
        } else if (graduatedPrices?.length && graduatedPricesLayout === "icon-dropdown") {
            button = <GraduatedPricesDropdown layout="icon" graduatedPrices={graduatedPrices} article={article} erpInfo={erpInfo} />
        }

        if (!button && !showAdditionalPrices) {
            return null
        }

        const tooltipContent = (
            <table>
                <tbody>
                    {erpInfo?.prices?.map((price) => (
                        <tr key={price.type}>
                            <td>{price.description} </td>
                            <td>{currency(price.value, price.currencySymbol || "")}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )

        return (
            <div className={`${classPrefix}__staggered`}>
                {button}
                {showAdditionalPrices && erpInfo?.prices?.length && (
                    <Tooltip title={tooltipContent}>
                        <Icon name="additional-prices" size="25px" />
                    </Tooltip>
                )}
            </div>
        )
    }

    return (
        <div className={concat(" ", `${classPrefix}__prices`, priceOrder.length > 1 && `${classPrefix}__prices--two-column`)}>
            <>
                {(!hideSpecialIcons && erpInfo?.isPromotional && (
                    <ErpIconWrapper>
                        <PromotionalIcon value={erpInfo.promoText} />
                        {hideGraduatedPricesIcon !== true && renderGraduatedPricesIcon()}
                    </ErpIconWrapper>
                )) ||
                    (hideGraduatedPricesIcon !== true && renderGraduatedPricesIcon())}
                {renderPrices()}
            </>
        </div>
    )
}
