import { useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Loader, Toolbar, Text } from "@tm/controls"
import { connectComponent } from "@tm/morpheus"
import { IMicros } from "@tm/models"
import { IActions, Actions } from "./business"
import { WorkTaskInfoState } from "./business/model"

type Props = IMicros["worktask"]["worktask-info"] & {
    state: WorkTaskInfoState
    actions: IActions
}

function WorkTaskInfoComponent(props: Props) {
    const { state, hideToolbar, actions, worktaskId } = props
    const { loading, workTasks } = state
    const { translateText } = useLocalization()

    useEffect(() => {
        if (worktaskId) {
            actions.loadWorkTaskInfo(worktaskId)
        }
    }, [worktaskId])

    function renderWorktaskInfoToolbar() {
        const workTask = workTasks[worktaskId]
        if (workTask) {
            return (
                <Toolbar modifiers={["no-border"]} title={translateText(29)}>
                    {renderWorktaskInfo()}
                </Toolbar>
            )
        }

        return null
    }

    function renderWorktaskInfo() {
        const workTask = workTasks[worktaskId]
        if (workTask) {
            return (
                <>
                    <Text size="l">{workTask.workTaskNo},</Text>
                    <Text modifiers={["strong"]} size="l">
                        {workTask.vehicle ? `${workTask.vehicle.manufacturer} ${workTask.vehicle.modelSeries} ${workTask.vehicle.model}` : "-"}
                    </Text>
                    <Text size="l">{workTask.customer ? `${workTask.customer.firstName} ${workTask.customer.lastName}` : ""}</Text>
                </>
            )
        }

        return null
    }

    if (loading) {
        return <Loader />
    }

    return <div className="worktask-info">{hideToolbar ? renderWorktaskInfo() : renderWorktaskInfoToolbar()}</div>
}

export default connectComponent(Actions, WorkTaskInfoComponent)
