import { em, padding, percent } from "csx"
import { MouseEvent, useCallback, useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Icon, PanelSection, SubTitle, Table, Text, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ETyresSeason, TyresSeason } from "@tm/models"
import { Tire } from "../../data/models"
import { TmaHelper } from "@tm/utils"
import { Tooltip } from "@tm/components"

type Props = {
    items: Tire[]
    onTireSelect(item: Tire, isFrontTires?: boolean, season?: TyresSeason): void
}

type State = {
    sortedList: Tire[]
    sortAsc: boolean,
    listIsSorted: boolean,
    propertySorted: string,
    isFrontTires: boolean
}

export default function DriveRightTiresTable({ items, onTireSelect }: Props) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    const [state, setState] = useState<State>({
        sortedList: [],
        sortAsc: false,
        listIsSorted: false,
        propertySorted: "",
        isFrontTires: false
    })

    const handleSelection = (ev: MouseEvent<HTMLElement>, item: Tire, isFrontTires: boolean, season?: TyresSeason) => {
        ev.stopPropagation()
        onTireSelect(item, isFrontTires, season)
    }

    const renderTableHeadItem = (text: string, propertyName: string, isFrontTires: boolean) => {
        return (
            <div className="sortable is-clickable" onClick={() => sortElements(propertyName, isFrontTires)}>
                <Text size="s">{text}</Text>
                {propertyName == state.propertySorted && state.isFrontTires == isFrontTires && <Icon name={state.sortAsc ? "up" : "down"} className="icon--tiny" />}
            </div>
        )
    }

    const renderTireSize = useCallback((item: Tire, isFront: boolean) => {
        switch (true) {
            case item[isFront ? "seasonSummerFront" : "seasonSummerRear"] === true && item[isFront ? "seasonWinterFront" : "seasonWinterRear"] === true:
                item[isFront ? "seasonFront" : "seasonRear"] = ETyresSeason.allSeason
                break
            case item[isFront ? "seasonSummerFront" : "seasonSummerRear"] === true:
                item[isFront ? "seasonFront" : "seasonRear"] = ETyresSeason.summer
                break
            case item[isFront ? "seasonWinterFront" : "seasonWinterRear"] === true:
                item[isFront ? "seasonFront" : "seasonRear"] = ETyresSeason.winter
                break
        }
        return renderSize(item, isFront)
    }, [])

    const renderSize = (item: Tire, isFront: boolean) => {
        return (
            <Table.Cell>
                <Text onClick={(ev) => handleSelection(ev, item, isFront)}>{item[isFront ? "sizeFront" : "sizeRear"]} {item[isFront ? "loadIndexFront" : "loadIndexRear"]}{item[isFront ? "speedIndexFront" : "speedIndexRear"]}</Text>
            </Table.Cell>
        )
    }

    const sortElements = (propertyName: string, isFrontTires: boolean) => {
        const sortByAsc = state.sortAsc ? 1 : -1
        let sortedArray: Tire[] = []

        if (propertyName == "size")
            sortedArray = items.sort((a, b) => a[isFrontTires ? "sizeFront" : "sizeRear"] > b[isFrontTires ? "sizeFront" : "sizeRear"] ? -sortByAsc : sortByAsc)

        if (propertyName == "rimSize")
            sortedArray = items.sort((a, b) => a[isFrontTires ? "rimSizeFront" : "rimSizeRear"] > b[isFrontTires ? "rimSizeFront" : "rimSizeRear"] ? -sortByAsc : sortByAsc)

        if (propertyName == "offset")
            sortedArray = items.sort((a, b) => a[isFrontTires ? "rimOffsetFront" : "rimOffsetRear"] > b[isFrontTires ? "rimOffsetFront" : "rimOffsetRear"] ? -sortByAsc : sortByAsc)

        setState((prevState) => ({
            sortedList: sortedArray,
            sortAsc: !prevState.sortAsc,
            listIsSorted: true,
            propertySorted: propertyName,
            isFrontTires: isFrontTires
        }))
    }

    return (
        <PanelSection className={style.wrapper}>
            <div className={style.header}>
                <SubTitle className={style.subtitle}>{translateText(353)}</SubTitle>
                <SubTitle className={style.subtitle}>{translateText(354)}</SubTitle>
            </div>
            <Table
                scrollable
                data={state.listIsSorted ? state.sortedList : items}
                onClickRow={onTireSelect}
                getRowClassName={() => "link is-clickable"}
                columns={[
                    <Table.Column className="size" renderItemContent={(item) => renderTireSize(item, true)}>{renderTableHeadItem(translateText(968), "size", true)}</Table.Column>,
                    <Table.Column className="rim" renderItemContent={(item: Tire) => <Table.Cell><Text onClick={(ev) => handleSelection(ev, item, true)}>{item.rimSizeFront}</Text></Table.Cell>}>{renderTableHeadItem(translateText(1029), "rimSize", true)}</Table.Column>,
                    <Table.Column className="offset" renderItemContent={(item: Tire) => <Table.Cell><Text onClick={(ev) => handleSelection(ev, item, true)}>{item.rimOffsetFront}</Text></Table.Cell>}>{renderTableHeadItem(translateText(1028), "offset", true)}</Table.Column>,
                    <Table.Column className="season" renderItemContent={(item: Tire) =>
                        <Table.Cell>
                            <Toolbar modifiers={["title-before", "no-border"]}>
                                <Tooltip title={translateText(800)}>
                                    <Button className="tyre-search__frame" icon="sun" disabled={!item.seasonSummerFront} onClick={(ev) => { handleSelection(ev, item, true, ETyresSeason.summer) }} />
                                </Tooltip>
                                <Tooltip title={translateText(801)}>
                                    <Button className="tyre-search__frame" icon="snow" disabled={!item.seasonWinterFront} onClick={(ev) => { handleSelection(ev, item, true, ETyresSeason.winter) }} />
                                </Tooltip>
                            </Toolbar>
                        </Table.Cell>}>{translateText(1235)}
                    </Table.Column>,
                    <Table.Column className="size" renderItemContent={(item) => renderTireSize(item, false)}>{renderTableHeadItem(translateText(968), "size", false)}</Table.Column>,
                    <Table.Column className="rim" renderItemContent={(item: Tire) => <Table.Cell><Text onClick={(ev) => handleSelection(ev, item, false)}>{item.rimSizeRear}</Text></Table.Cell>}>{renderTableHeadItem(translateText(1029), "rimSize", false)}</Table.Column>,
                    <Table.Column className="offset" renderItemContent={(item: Tire) => <Table.Cell><Text onClick={(ev) => handleSelection(ev, item, false)}>{item.rimOffsetRear}</Text></Table.Cell>}>{renderTableHeadItem(translateText(1028), "offset", false)}</Table.Column>,
                    <Table.Column className="season" renderItemContent={(item: Tire) =>
                        <Table.Cell>
                            <Toolbar modifiers={["title-before", "no-border"]}>
                                <Tooltip title={translateText(800)}>
                                    <Button className="tyre-search__frame" icon="sun" disabled={!item.seasonSummerRear} onClick={(ev) => { handleSelection(ev, item, false, ETyresSeason.summer) }} />
                                </Tooltip>
                                <Tooltip title={translateText(801)}>
                                    <Button className="tyre-search__frame" icon="snow" disabled={!item.seasonWinterRear} onClick={(ev) => { handleSelection(ev, item, false, ETyresSeason.winter) }} />
                                </Tooltip>
                            </Toolbar>
                        </Table.Cell>}>{translateText(1235)}
                    </Table.Column>
                ]}
            />
        </PanelSection>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            flex: 1,
            $nest: {
                ".panel__content": {
                    padding: padding(theme.margin.m, 0),
                    height: percent(100),
                    $nest: {
                        ".fancy-list": {
                            $nest: {
                                "&__head": {
                                    paddingRight: theme.margin?.l || em(0.75),
                                    paddingLeft: theme.margin?.l || em(0.75),
                                    $nest: {
                                        ".sortable": {
                                            display: "flex",
                                            alignItems: "center",
                                            $nest: {
                                                ".icon": {
                                                    marginTop: em(.1)
                                                }
                                            }
                                        },
                                        ".sortable .season": {
                                            marginLeft: em(1.5)
                                        }
                                    }
                                },
                                ".fancy-list__block": {
                                    display: "flex",
                                    flexDirection: "column",
                                    flex: "1 1 100%",
                                    marginLeft: theme.margin.m,
                                    $nest: {
                                        ".size": {
                                            flex: "2 1 200%"
                                        },
                                        ".toolbar__inner": {
                                            padding: 0
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        header: {
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            marginRight: em(1)
        },
        subtitle: {
            color: "#000000"
        }
    })(DriveRightTiresTable)
}
