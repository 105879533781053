import { useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { connectComponent } from "@tm/morpheus"
import { RegistrationNoType } from "@tm/models"
import { Headline } from "@tm/controls"
import VrmLookupDetailsComponent from "../_shared/VrmLookupDetailsComponent"
import { VrmLookupDetailsOnlyState, IActions, Actions } from "./business"

type RouteProps = {
    query: string
    registrationNoType: string
}

type Props = RouteComponentProps<RouteProps> & {
    state: VrmLookupDetailsOnlyState
    actions: IActions
}

function VrmLookupDetailsOnlyComponent(props: Props) {
    const { translate } = useLocalization()
    const { match, actions, state } = props
    const { query, registrationNoType } = match.params
    const { vrmVehicle } = state

    useEffect(() => {
        if (/\d+/.test(registrationNoType) && query) {
            const regTypNo = parseFloat(registrationNoType)
            regTypNo && actions.load(query, regTypNo)
        }

        window.addEventListener("locationchange", () => {
            const searchParts = decodeURIComponent(document.location.search).split("/")

            if (searchParts.length <= 3) {
                return
            }

            const query = searchParts[searchParts.length - 1]
            const registrationNoType = searchParts[searchParts.length - 2]

            if (query && registrationNoType && /\d+/.test(registrationNoType)) {
                const regTypNo = parseFloat(registrationNoType)
                regTypNo && actions.load(query, regTypNo)
            }
        })

        return () => {
            window.removeEventListener("locationchange", () => {})
        }
    }, [])

    function handleVrmDataRequest(provider: RegistrationNoType) {
        console.log(provider)
    }

    return (
        <div className="tk-vehicle vrm-lookup-details-wrapper">
            <Headline>{`${translate(1565)}`}</Headline>
            {vrmVehicle && (
                <VrmLookupDetailsComponent
                    classNames={{
                        panel: "panel--no-dropshadow",
                        wrapper: "vrm-lookup-details--only",
                    }}
                    vrmData={vrmVehicle.registrationNoDetails}
                    dataProvider={RegistrationNoType.VrmAAA}
                    onRequestVrmData={handleVrmDataRequest}
                />
            )}
        </div>
    )
}

export default connectComponent(Actions, withRouter(VrmLookupDetailsOnlyComponent))
