import { getStoredAuthorization, ajax } from "@tm/utils"
import { ShowOrdersByArticleRequest, ShowOrdersByArticleResponse } from "@tm/models"
import {
    FindConnectedVouchersRequest,
    FindVoucherRequest,
    ShowOpenOrdersRequest,
    ShowCompletedOrdersRequest,
    ShowComplaintsRequest,
    ShowOpenOrdersResponse,
    ShowCompletedOrdersResponse,
    ShowComplaintsResponse,
    ShowOrderDetailsRequest,
    FindVouchersResponse,
    ShowOrderDetailsResponse,
    ShowOrderDetailsPdfRequest,
    ShowAllOrdersRequest,
    ShowAllOrdersResponse,
    PrintVoucherDetailsRequest,
    PrintVoucherListRequest,
    SendVoucherConfirmationRequest,
    SendVoucherConfirmationResponse,
    ShowOrderVoucherTypeIdRequest,
    ShowOrderVoucherTypeIdResponse,
} from "../../model"
import { getCisServiceUrl } from "../.."

function getServiceUrl() {
    return `${getCisServiceUrl()}/Vouchers`
}

export async function findConnectedVouchers(body: FindConnectedVouchersRequest) {
    const url = `${getServiceUrl()}/FindConnectedVouchers`
    const authorization = getStoredAuthorization()
    return ajax<FindVouchersResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function findVouchers(body: FindVoucherRequest) {
    const url = `${getServiceUrl()}/FindVouchers`
    const authorization = getStoredAuthorization()
    return ajax<FindVouchersResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showOpenOrders(body: ShowOpenOrdersRequest) {
    const url = `${getServiceUrl()}/ShowOpenOrders`
    const authorization = getStoredAuthorization()
    return ajax<ShowOpenOrdersResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showCompletedOrders(body: ShowCompletedOrdersRequest) {
    const url = `${getServiceUrl()}/ShowCompletedOrders`
    const authorization = getStoredAuthorization()
    return ajax<ShowCompletedOrdersResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showAllOrders(body: ShowAllOrdersRequest) {
    const url = `${getServiceUrl()}/ShowAllOrders`
    const authorization = getStoredAuthorization()
    return ajax<ShowAllOrdersResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showComplaints(body: ShowComplaintsRequest) {
    const url = `${getServiceUrl()}/ShowComplaints`
    const authorization = getStoredAuthorization()
    return ajax<ShowComplaintsResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showOrdersByArticle(body: ShowOrdersByArticleRequest) {
    const url = `${getServiceUrl()}/ShowOrdersByArticle`
    const authorization = getStoredAuthorization()
    return ajax<ShowOrdersByArticleResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showOrdersDetails(body: ShowOrderDetailsRequest) {
    const url = `${getServiceUrl()}/ShowOrderDetails`
    const authorization = getStoredAuthorization()
    return ajax<ShowOrderDetailsResponse>({ url, body, authorization }, undefined, undefined, true)
}

export async function showOrderDetailsPdf(body: ShowOrderDetailsPdfRequest) {
    const url = `${getServiceUrl()}/ShowOrderDetailsPdf`
    const authorization = getStoredAuthorization()

    return ajax<Array<number>>({ url, body, authorization, responseType: "blob", method: "POST" })
}

export async function printVoucherDetails(body: PrintVoucherDetailsRequest) {
    const url = `${getServiceUrl()}/PrintVoucherDetails`
    const authorization = getStoredAuthorization()

    return ajax<Array<number>>({ url, body, authorization, responseType: "blob", method: "POST" })
}

export async function printVoucherList(body: PrintVoucherListRequest) {
    const url = `${getServiceUrl()}/PrintVoucherList`
    const authorization = getStoredAuthorization()

    return ajax<Array<number>>({
        url,
        body,
        authorization,
        responseType: "blob",
        method: "POST",
    })
}

export async function sendVoucherConfirmation(body: SendVoucherConfirmationRequest) {
    const url = `${getServiceUrl()}/SendVoucherConfirmation`
    const authorization = getStoredAuthorization()

    return ajax<SendVoucherConfirmationResponse>({ url, body, authorization, method: "POST" })
}

export async function showOrderVoucherTypeId(body: ShowOrderVoucherTypeIdRequest) {
    const url = `${getServiceUrl()}/ShowOrderVoucherTypeId`
    const authorization = getStoredAuthorization()
    return ajax<ShowOrderVoucherTypeIdResponse>({ url, body, authorization }, undefined, undefined, true)
}
