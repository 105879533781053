import { Box, Icon, Typography, VehicleImage, styled, LoadingContainer } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"

export default function Vehicle() {
    const vehicle = useWorkTask()?.workTask?.vehicle
    const { translateText } = useLocalization()

    const renderVehicleImage = () => {
        if (vehicle?.modelThumbnail) {
            return (
                <StyledVehicleImage
                    modelImage={vehicle.modelThumbnail}
                    modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                    vehicleType={vehicle.vehicleType}
                />
            )
        }

        return <Icon sx={{ width: "4em", marginLeft: "0.5em", verticalAlign: "midle" }} name="undefined-car" />
    }

    return (
        <LoadingContainer isLoading={!vehicle}>
            <Box display="flex" gap="0.5em" alignItems="center">
                {renderVehicleImage()}

                <Box>
                    <Typography variant="body3" fontWeight="600">
                        {vehicle?.manufacturer} {vehicle?.modelSeries} {vehicle?.model}
                    </Typography>
                    <Box>
                        <Typography variant="body3">{translateText(105)}</Typography>
                        <Typography variant="body3" fontWeight="600" marginLeft="0.5em">
                            {vehicle?.engineCode ?? "-"}
                        </Typography>

                        <Typography variant="body3" marginLeft="0.5em">
                            {translateText(124)}
                        </Typography>
                        <Typography variant="body3" fontWeight="600" marginLeft="0.5em">
                            {vehicle?.initialRegistration?.toLocaleDateString() ?? "-"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </LoadingContainer>
    )
}

const StyledVehicleImage = styled(VehicleImage)({
    height: "2em",
})
