import { Box, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    selectedSize: string
    wheelSize?: string
    wheelOffset?: string
}

export function ExtraInformation(props: Props) {
    const { selectedSize, wheelSize, wheelOffset } = props
    const { translateText } = useLocalization()

    return (
        <Box marginLeft="1em" flexBasis="25%" minWidth="fit-content">
            <FlexDiv>
                {`${translateText(967)}:`}
                <Box paddingLeft="0.75em" fontWeight="bold">
                    {selectedSize}
                </Box>
            </FlexDiv>
            <Typography />
            <FlexDiv>
                {`${translateText(1029)}:`}
                <Box paddingLeft="0.75em" fontWeight="bold">
                    {wheelSize}
                </Box>
            </FlexDiv>
            {wheelOffset && (
                <FlexDiv>
                    {`${translateText(1135)}:`}
                    <Box paddingLeft="0.75em" fontWeight="bold">
                        {wheelOffset}
                    </Box>
                </FlexDiv>
            )}
        </Box>
    )
}

const FlexDiv = styled(Box)({
    display: "flex",
})
