import { useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { CisVoucherType } from "@tm/models"
import { Box, Loader, Stack, Typography, Icon, Button } from "@tm/components"
import { Collapsible } from "@tm/controls"
import { renderRoute } from "@tm/utils"
import { useParams } from "react-router"
import { WorkTaskInfo } from "@tm/context-distribution"
import PartsTable from "./PartsTable"
import { BasketPart } from "../../../../../models"
import { CustomArticles } from "../../../../_shared/custom-basket-items/custom-articles"
import PartsSelection from "./parts-selection"
import AddPart from "./AddPart"
import { PartsTotals } from "../totals/PartsTotals"
import { useWorkTaskBasketState } from "../../../../../hooks/basketState/useWorkTaskBasketState"

type Props = {
    externalSystemId?: number
    cisVoucherUrl?: string
    creatingCostEstimation: boolean
    partsDirectListRoute: string
    partsVehicleListRoute: string
    showAllPrices: boolean
    showPurchasePrice?: boolean
    voucherTypeId?: CisVoucherType
    workTask: WorkTaskInfo
    onOpenArticleSearch(query: string): void
    onOpenPartRepairTimes(part: BasketPart): void
}

export default function PartsList(props: Props) {
    const {
        creatingCostEstimation,
        showAllPrices,
        voucherTypeId,
        cisVoucherUrl,
        workTask,
        showPurchasePrice,
        partsDirectListRoute,
        partsVehicleListRoute,
        externalSystemId,
    } = props

    const { translateText } = useLocalization()
    const matchParams = useParams<{ workTaskId?: string }>()
    const {
        costEstimation: costEstimationState,
        basket: basketState,
        erp,
        workTaskBasketCalculationLoading,
        workTaskBasketLoading,
        workTaskBasketCalculation,
    } = useWorkTaskBasketState(workTask.id)

    const { toggleIncludeBasketParts, addCustomParts } = basketState.actions
    const { calculatedCostEstimation } = workTaskBasketCalculation || {}

    const {
        costEstimationParts,
        costEstimation,
        currencyCode,
        currencySymbol,
        showSupplierArticleNumbers,
        showManufacturers,
        showWholesalerArticleNumbers,
    } = costEstimationState.state
    const {
        changePartQuantity,
        editPart,
        removeParts,
        selectAllCostEstimationParts,
        toggleCostEstimationPartSelect,
        toggleIncludeCostEstimationParts,
        togglePartEditorOpen,
        unselectAllCostEstimationParts,
    } = costEstimationState.actions

    const [customArticlesOpen, setCustomArticlesOpen] = useState(false)

    const [selectedCostEstimaionParts = [], selectedLinkedItems = []] = useMemo(() => {
        return [
            costEstimationParts?.filter((item) => item.states.isSelected),
            costEstimationParts?.flatMap((item) => item.linkedItems ?? []).filter((linkedItem) => linkedItem?.states.isSelected),
        ]
    }, [costEstimationParts])

    const [selectedParts, areAllPartsSelected] = useMemo(() => {
        const linkedItems = costEstimationParts?.flatMap((item) => item.linkedItems ?? [])
        const items = (costEstimationParts?.length ?? 0) + (linkedItems?.length ?? 0)
        const selectedItems = (selectedCostEstimaionParts.length ?? 0) + (selectedLinkedItems.length ?? 0)
        return [selectedCostEstimaionParts.concat(selectedLinkedItems), items === selectedItems]
    }, [costEstimationParts, selectedCostEstimaionParts, selectedLinkedItems])

    function renderCustomArticlesButton() {
        return (
            <Button
                startIcon={customArticlesOpen ? <Icon name="up" /> : <Icon name="down" />}
                variant="text"
                onClick={(e) => {
                    e?.stopPropagation?.()
                    setCustomArticlesOpen((prev) => !prev)
                }}
                size="small"
            >
                {translateText(850)}
            </Button>
        )
    }

    function renderPartsSelection() {
        if (selectedParts && selectedParts?.length > 0) {
            return (
                <PartsSelection
                    selectedParts={selectedParts}
                    allSelected={areAllPartsSelected}
                    loading={workTaskBasketLoading || workTaskBasketCalculationLoading || !!selectedLinkedItems?.length}
                    onSelectAll={selectAllCostEstimationParts}
                    onUnselectAll={unselectAllCostEstimationParts}
                    onIncludeExcludeParts={toggleIncludeCostEstimationParts}
                    onRemoveParts={removeParts}
                />
            )
        }
    }

    function renderPartsAddingTools() {
        const partsVehicleUrl = renderRoute(partsVehicleListRoute, { ...matchParams })
        const partsDirectUrl = renderRoute(partsDirectListRoute, { ...matchParams })
        return (
            <Box display="flex" justifyContent="center" p="0 16px 4px 16px">
                <AddPart
                    workTask={workTask}
                    costEstimation={costEstimation}
                    vehicleId={workTask.vehicle?.id}
                    customerId={workTask.customer?.id}
                    partsVehicleListUrl={partsVehicleUrl}
                    partsDirectListUrl={partsDirectUrl}
                    currencyCode={currencyCode}
                    onAddCustomPart={addCustomParts}
                />
            </Box>
        )
    }

    function renderTable() {
        return costEstimationParts?.length ? (
            <PartsTable
                externalSystemId={externalSystemId}
                currencyCode={currencyCode}
                calculationLoading={workTaskBasketCalculationLoading}
                costEstimationOptions={costEstimation?.costEstimationOptions}
                cisVoucherUrl={cisVoucherUrl}
                creatingCostEstimation={creatingCostEstimation}
                basketErpIndicator={erp.basketErpInfosIndicator}
                parts={costEstimationParts}
                showAllPrices={showAllPrices}
                showManufacturer={showManufacturers}
                showPurchasePrice={showPurchasePrice}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                voucherTypeId={voucherTypeId}
                onChangeQuantity={changePartQuantity}
                onEditPart={editPart}
                onIncludeExcludePart={(part) => toggleIncludeCostEstimationParts([part])}
                onOpenArticleSearch={props.onOpenArticleSearch}
                onOpenClosePartEditor={(id) => togglePartEditorOpen([id])}
                onRemoveParts={removeParts}
                onSelectPart={(id) => toggleCostEstimationPartSelect([id])}
                onIncludeExcludeBasketPart={(part) => toggleIncludeBasketParts([part])}
                onOpenPartRepairTimes={props.onOpenPartRepairTimes}
            />
        ) : (
            <Stack alignItems="center" spacing={2}>
                <Icon name="cart" width="30px" height="30px" />
                <Typography>{translateText(918)}</Typography>
            </Stack>
        )
    }
    return (
        <Collapsible
            name={translateText(1667)}
            initiallyOpened
            skin="dark"
            renderHeaderAppendix={renderCustomArticlesButton}
            className="tk-basket cost-estimation__collabsible--small-caption"
        >
            {customArticlesOpen && (
                <CustomArticles workTask={workTask} currencySymbol={currencySymbol} customPartVatRates={costEstimation?.defaultPartVatRates} />
            )}
            <Box position="relative">
                {renderPartsSelection()}
                <Box mb={1}>{workTaskBasketLoading ? <Loader size="small" /> : renderTable()}</Box>
            </Box>
            {renderPartsAddingTools()}
            <Box py={1} pr={1} display="flex" justifyContent="flex-end">
                <PartsTotals
                    priceVatMode={costEstimation?.costEstimationOptions.priceVatMode}
                    totalsLoading={workTaskBasketCalculationLoading}
                    currencyCode={currencyCode}
                    totals={calculatedCostEstimation?.totalsB2B ?? calculatedCostEstimation?.totalsB2C}
                />
            </Box>
        </Collapsible>
    )
}
