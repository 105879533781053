import { Box, RadioButton, FormControlLabel, RadioGroup, styled, Typography } from "@tm/components"
import { UserSettings, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { memo, useEffect } from "react"

import { getUIA } from "@tm/utils"
import { ISaveableOption } from "."
import { getBundleParams } from "../../../../utils"

const InlineRadioGroup = styled(RadioGroup)({
    display: "flex",
    flexDirection: "row",
})

export function showShipmentModeOptions(userSettings: UserSettings | undefined) {
    return !getBundleParams().deactivateFavoriteOrderOptions && (userSettings?.orderOptions.repairShopResponse?.shipmentModes?.length || 0) > 1
}

type Props = ISaveableOption

export const ShipmentModeOptions = memo(({ isSaving, onSave }: Props) => {
    const { translateText } = useLocalization()

    const { userSettings, changeOrderOptions } = useUser() || {}
    const { shipmentModes } = userSettings?.orderOptions.repairShopResponse || {}

    useEffect(() => {
        onSave(false)
    }, [shipmentModes])

    function handleChange(_: unknown, value: string) {
        onSave(true)
        changeOrderOptions?.({ favoriteShipmentModeId: value })
    }

    if (!shipmentModes || !showShipmentModeOptions(userSettings)) {
        return null
    }

    return (
        <Box>
            <Typography variant="h4">{translateText(13001)}</Typography>
            <InlineRadioGroup value={shipmentModes.find((x) => x.isSelected)?.id} onChange={handleChange}>
                {shipmentModes.map((mode) => (
                    <FormControlLabel
                        key={mode.id}
                        value={mode.id}
                        label={mode.description}
                        control={<RadioButton />}
                        disabled={isSaving}
                        {...getUIA("SettingsBasketOrderShipmentmode", mode.id)}
                    />
                ))}
            </InlineRadioGroup>
        </Box>
    )
})
