import { Loader, TableCellData, TableColumnData, TableVariants } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CisTour } from "@tm/models"
import { useMemo } from "react"
import { NoResultHint } from "../NoResultHint"
import { StyledTable } from "../StyledComponents"
import DateCell from "../cells/DateCell"

type Props = {
    loading: boolean
    tours: CisTour[] | undefined
    variant: TableVariants
}
export default function ToursTableComponent({ variant, loading, tours }: Props) {
    const { translateText } = useLocalization()

    const showTourDescription = useMemo(() => {
        return tours?.some((tour) => !!tour.tourDescription)
    }, [tours])

    const showOrderAcceptanceUntil = useMemo(() => {
        return tours?.some((tour) => !!tour.orderAcceptanceUntil)
    }, [tours])

    const showTourStartTime = useMemo(() => {
        return tours?.some((tour) => !!tour.tourStartTime)
    }, [tours])

    const showStatus = useMemo(() => {
        return tours?.some((tour) => !!tour.status)
    }, [tours])

    function getColumns() {
        const columns: TableColumnData[] = [{ header: translateText(variant === "small" ? 766 : 790) }]
        if (showTourDescription) {
            columns.push({ header: translateText(791) })
        }
        if (showTourStartTime) {
            columns.push({ header: translateText(1128) })
        }
        if (showOrderAcceptanceUntil) {
            columns.push({ header: translateText(1129), minWidth: variant === "small" ? 80 : undefined })
        }
        if (showStatus) {
            columns.push({ header: translateText(212), minWidth: variant === "small" ? 20 : undefined })
        }
        return columns
    }

    function getModuleCellData(tour: CisTour) {
        const cellData: TableCellData[] = [{ displayValue: tour.tourNumber, id: "tour_1" }]
        if (showTourDescription) {
            cellData.push({ displayValue: tour.tourDescription, id: "tour_2" })
        }
        if (showTourStartTime) {
            cellData.push({ displayValue: <DateCell value={tour.tourStartTime} mode="onlyTime" />, id: "tour_3" })
        }
        if (showOrderAcceptanceUntil) {
            cellData.push({ displayValue: <DateCell value={tour.orderAcceptanceUntil} mode="onlyTime" />, id: "tour_4" })
        }
        if (showStatus) {
            cellData.push({ displayValue: tour.status, id: "tour_5" })
        }

        return cellData
    }

    if (loading) {
        return <Loader />
    }

    const displayData = tours?.map((tour, index) => ({
        cells: getModuleCellData(tour),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    if (!displayData?.length) {
        return <NoResultHint />
    }

    return (
        <StyledTable
            columns={getColumns()}
            rows={displayData}
            variant={variant}
            headerStyle={variant === "small" ? "bold" : "default"}
            headerBackground={variant === "small" ? "default" : "paper"}
        />
    )
}
