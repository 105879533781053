import { CisVoucherType, OrderByArticleListItem } from "@tm/models"
import { Box } from "@tm/components"
import { useParams } from "react-router"
import StgOrdersTable from "../../../_shared/tables/StgOrdersTable"
import StgOrdersByArticleTable from "./StgOrdersByArticleTable"
import { ComplaintListItem, CompletedOrderListItem, OpenOrderListItem, Voucher } from "../../../../data/model"
import { CisVoucher } from "../../../../business/model"
import ComplaintsTable from "../../../_shared/tables/ComplaintsTable"
import { RowStackWithFlex, VerticalDivider } from "../../../_shared/StyledComponents"
import ReturnsTable from "../../../_shared/tables/ReturnsTable"
import StgReturnVoucherDetails from "./voucher-details/StgReturnVoucherDetails"
import StgOrderDetails from "./voucher-details/StgOrderDetails"
import { CALCULATED_HEIGHT, COMPACT_WIDTH, FULL_WIDTH, VoucherRouteProps } from "../../component"

type Props = {
    voucherTypeId: number
    vouchers?: Array<CisVoucher>
    vouchersLoadedMessage?: string
    onPrintButtonClick(orderNumber?: string, orderSubNumber?: string, voucherId?: string, warehouseId?: string, voucherTypeId?: CisVoucherType): void
    onLoadNextPage(): void
}

export default function StgVouchersComponent(props: Props) {
    const { voucherTypeId, vouchers, vouchersLoadedMessage } = props
    const matchParams = useParams<VoucherRouteProps>()

    switch (voucherTypeId) {
        case CisVoucherType.ArticleSearchInOrders:
            return (
                <RowStackWithFlex>
                    <StgOrdersByArticleTable orders={vouchers as Array<OrderByArticleListItem>} />
                </RowStackWithFlex>
            )
        case CisVoucherType.OpenOrders:
        case CisVoucherType.ClosedOrders:
            return (
                <RowStackWithFlex divider={<VerticalDivider />}>
                    <Box width={matchParams.id ? COMPACT_WIDTH : FULL_WIDTH} height={CALCULATED_HEIGHT}>
                        <StgOrdersTable
                            orders={vouchers as Array<OpenOrderListItem | CompletedOrderListItem>}
                            variant={matchParams.id ? "small" : "normal"}
                            cisVoucherType={voucherTypeId}
                            vouchersLoadedMessage={vouchersLoadedMessage}
                        />
                    </Box>
                    {!!matchParams.id && !!matchParams.subId && (
                        <StgOrderDetails
                            specialOrders={vouchers as Array<CompletedOrderListItem | OpenOrderListItem>}
                            selectedVoucherId={decodeURIComponent(matchParams.id)}
                            selectedVoucherSubId={matchParams.subId}
                            voucherTypeId={voucherTypeId}
                            onPrintButtonClick={props.onPrintButtonClick}
                        />
                    )}
                </RowStackWithFlex>
            )
        case CisVoucherType.Complaints:
            return (
                <RowStackWithFlex>
                    <ComplaintsTable complaints={vouchers as Array<ComplaintListItem>} variant="normal" />
                </RowStackWithFlex>
            )
        case CisVoucherType.Returns:
            return (
                <RowStackWithFlex divider={<VerticalDivider />}>
                    <Box width={matchParams.id ? COMPACT_WIDTH : FULL_WIDTH} height={CALCULATED_HEIGHT}>
                        <ReturnsTable
                            vouchers={vouchers as Array<Voucher>}
                            variant={matchParams.id ? "small" : "normal"}
                            onLoadNextPage={props.onLoadNextPage}
                        />
                    </Box>
                    {!!matchParams.id && (
                        <StgReturnVoucherDetails
                            vouchers={vouchers as Array<Voucher>}
                            onPrintButtonClick={props.onPrintButtonClick}
                            selectedVoucherId={decodeURIComponent(matchParams.id)}
                        />
                    )}
                </RowStackWithFlex>
            )

        default:
            return <></>
    }
}
