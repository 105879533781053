import { styled, Icon, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { memo } from "react"

type Props = {
    currentLength: number
    maxLength: number
}

const WrappingTooltip = styled(Tooltip)({
    whiteSpace: "pre-wrap",
})

export const MemoLengthWarning = memo(({ currentLength, maxLength }: Props) => {
    const { translateText } = useLocalization()
    if (currentLength <= maxLength) {
        return null
    }

    return (
        <WrappingTooltip title={translateText(13057).replace("{0}", maxLength.toString())} variant="dark">
            <Icon className="icon" name="attention-dark" />
        </WrappingTooltip>
    )
})
