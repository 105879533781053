import { PriceType } from "@tm/models"
import { Stack, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { CisPrice } from "../../../data/model"

type Props = {
    priceType: PriceType
    prices?: Array<CisPrice>
    quantityUnit?: string
}

export default function PriceCellComponent({ priceType, prices, quantityUnit }: Props) {
    const { translateText, currency } = useLocalization()

    if (!prices) {
        return <></>
    }

    const price = prices.find((p) => p.priceType === priceType)

    if (!price || !price.value || !prices) {
        return <></>
    }
    return (
        <Stack>
            {currency(price.value || 0, price.currencyCode)}
            {quantityUnit && <Typography variant="label" alignSelf="end">{`${translateText(1312)} ${quantityUnit}`}</Typography>}
        </Stack>
    )
}
