import { LocalizationProps, useLocalization, withLocalization } from "@tm/localization"
import { usePureArticleLists, useUser, useWorkTask, WithUserContextProps, WithUserSettingsProps, WithWorkTaskProps } from "@tm/context-distribution"
import { ChangeItemsResponse, Article, channel, IMicros } from "@tm/models"
import { connectComponent, WithMicroProps } from "@tm/morpheus"
import { openDetailsState, selectedOrderOptionState, RouteComponentProps, withRouter } from "@tm/utils"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { useHistory, useLocation, useRouteMatch } from "react-router"
import { useEffect } from "react"
import { BaseDetailsRouteProps } from "../../business"
import { DetailsHeadState } from "./business"
import { DetailsHeadComponent } from "./component"
import { Actions, IActions } from "./business/actions"
import { createListRequests } from "./business/createListRequests"

type DetailsHeadConfigProps = {
    state: DetailsHeadState
    actions: IActions
    showAdditionalPrices?: boolean
    showArticleWarehouseSelector?: boolean
    infoAvailabilityOverviewData?: Array<{ status: number; text: string }>
}

export type DetailsHeadProps = WithMicroProps<IMicros> &
    LocalizationProps &
    RouteComponentProps<BaseDetailsRouteProps> &
    WithUserContextProps &
    WithUserSettingsProps &
    WithWorkTaskProps &
    DetailsHeadConfigProps

const DetailsHeadComponentWrapper = (props: DetailsHeadConfigProps) => {
    const setOpenDetailsState = useSetRecoilState(openDetailsState)
    const selectedOrderOption = useRecoilValue(selectedOrderOptionState)
    const history = useHistory()
    const location = useLocation()
    const routeMatch = useRouteMatch<BaseDetailsRouteProps>()
    const localization = useLocalization()
    const user = useUser()
    const workTask = useWorkTask()!
    const { setRequest } = usePureArticleLists()

    const { detailsRequest, detailsResponse, foundBySearchTerm } = props.state

    useEffect(
        function handleDetailsLoaded() {
            if (detailsResponse && detailsRequest) {
                createListRequests(detailsRequest, detailsResponse, foundBySearchTerm).forEach(([targetId, request]) => {
                    setRequest(targetId, request)
                })
            }
        },
        [detailsResponse]
    )

    const onFinished = (data: ChangeItemsResponse) => {
        channel("GLOBAL").publish("ERP/TECCOM_ARTICLE_ADDED", data)
    }

    const updateArticle = (article?: Article) => {
        setOpenDetailsState({ article })
    }

    return (
        <DetailsHeadComponent
            {...props}
            {...user}
            {...workTask}
            history={history}
            localization={localization}
            location={location}
            match={routeMatch}
            updateArticle={updateArticle}
            selectedOrderOption={selectedOrderOption}
            onFinished={onFinished}
        />
    )
}

export default connectComponent(Actions, DetailsHeadComponentWrapper)
