import { BundleComponent } from "@tm/morpheus"

import component from "./component"
import { receive, reduce } from "../details-references/business"

const articleNotes: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "article-notes",
    reduce,
    component,
    receive,
}

export default articleNotes
