import { useEffect, useMemo, useState } from "react"
import { Button, Headline, Icon, Text, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VrcLookupModule } from "../../../../data/model/vrc-lookup"
import { VrcLocalFile, VrcUploadedFile } from "../../component"
import SharedLayout from "../_shared-layout"
import FilePreview from "../file-preview"
import { getComponentStyles } from "./styles"

type Props = {
    file: VrcLocalFile | VrcUploadedFile
    module: VrcLookupModule
    resetFile(): void
    updateFile(file: VrcLocalFile | VrcUploadedFile): void
    onConfirm(file: VrcLocalFile | VrcUploadedFile): void
}

export default function Confirm(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])

    const { translateText } = useLocalization()
    const [confirmDisabled, setConfirmDisabled] = useState(false)
    const isChargeable = !!props.module.cost
    const [imageRotation, setImageRotation] = useState<number>(0)

    useEffect(() => {
        setConfirmDisabled(false)
    }, [props.file])

    const handleButtonClick = () => {
        setConfirmDisabled(true)

        props.onConfirm(props.file)
    }

    const rotateImage = () => {
        const newRotation = imageRotation !== 270 ? imageRotation + 90 : 0
        setImageRotation(newRotation)
        props.updateFile({ ...props.file, rotation: newRotation })
    }

    return (
        <SharedLayout
            headerChildren={
                <>
                    <Toolbar title={translateText(361)} className={classNames.toolbar}>
                        <Button icon="refresh" onClick={props.resetFile} size="s">
                            {translateText(12628)}
                        </Button>
                        {props.file.isImage && (
                            <Button icon="rotate" onClick={rotateImage} size="s">
                                {translateText(12687)}
                            </Button>
                        )}
                    </Toolbar>
                    <div className={classNames.buttonWrapper}>
                        <Button skin="highlight" size="l" onClick={handleButtonClick} disabled={confirmDisabled}>
                            {translateText(isChargeable ? 12642 : 13368)}
                        </Button>
                    </div>
                </>
            }
        >
            <div className={classNames.subHeader}>
                <div>
                    <Headline size="xs">{translateText(12640)}</Headline>
                    <Text>{translateText(12641)}</Text>
                </div>

                <div className={classNames.infoWrapper}>
                    {isChargeable && (
                        <div className={classNames.infoContainer}>
                            <Icon name="info" size="l" className={classNames.infoIcon} />
                            <div>
                                <Text>{translateText(12616)}: </Text>
                                <Text modifiers="strong">{props.module.cost}</Text>
                            </div>
                        </div>
                    )}
                    <div className={classNames.infoContainer}>
                        <Icon name="info" size="l" className={classNames.infoIcon} />
                        <div>
                            <Text>{translateText(12772)}</Text>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <FilePreview file={props.file} className={props.file.isImage ? classNames.image : classNames.pdf} rotation={imageRotation} />
            </div>
        </SharedLayout>
    )
}
