import { BundleActions, BundleActionTypes } from "../../../business"
import { NavigationSteps } from "../../../data/models"
import { MainActionsType } from "../../main/business"
import { NavigationState } from "./model"

export * from "./model"

export type ComponentActionType = BundleActionTypes

const DEFAULT_STATE: NavigationState = {
    params: {},
    init: false,
}

export function reduce(state = DEFAULT_STATE, action: MainActionsType): NavigationState {
    switch (action.type) {
        case "RESET": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "CHANGE_STEP": {
            const { params, selectedStep } = action.payload
            return {
                ...state,
                active: selectedStep,
                init: true,
                params: {
                    ...state.params,
                    [selectedStep]: {
                        ...state.params[selectedStep],
                        ...params,
                    },
                },
                ...(selectedStep === "extern" && {
                    externVisited: true,
                }),
                ...(selectedStep === "calculation" && {
                    calcVisited: true,
                }),
            }
        }
        case "PREPARE_CALC_LOADING": {
            return {
                ...state,
                calcVisited: false,
            }
        }
        default: {
            return state
        }
    }
}

const reset = (): ComponentActionType => ({ type: "RESET" })

const changeStep = (selectedStep: NavigationSteps, params?: Record<string, any>): ComponentActionType => ({
    type: "CHANGE_STEP",
    payload: { selectedStep, params },
})

export const Actions = {
    ...BundleActions,
    changeStep,
    reset,
}
