import { MainActionsType } from "../components/main/business"
import { MatchParams } from "../components/navigation/business/model"
import { DatSelectionSteps } from "../data/enums"

const initModule = (matchParams: MatchParams): MainActionsType => ({ type: "INIT_MODULE", payload: { ...matchParams } })

const changeStep = (selectedStep: DatSelectionSteps, params?: MatchParams, disableNext?: boolean): MainActionsType => ({
    type: "CHANGE_STEP",
    payload: { selectedStep, params, disableNext },
})

const generalReset = (): MainActionsType => ({ type: "RESTART_PROCESS" })

export const NavigationActions = {
    initModule,
    changeStep,
    generalReset,
}
