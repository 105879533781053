import { channel } from "@tm/models"
import Morpheus, { IPlugin, ModuleConfig } from "@tm/morpheus"
import { Location } from "history"

const plugin: IPlugin<ModuleConfig> = {
    name: "ForceNewWorktask",
    type: "MODULE/MOUNT",
    plugin: ForceNewWorktask,
}

export default plugin

let unregister: any

function ForceNewWorktask() {
    if (unregister) {
        return
    }

    const history = Morpheus.getHistory()

    function listener(location: Location) {
        const hasFragment = location.pathname.length > 1
        if (!hasFragment) {
            const pageNav = window.__NEXT_PAGE_NAVIGATION__ || []
            if (pageNav.length) {
                setTimeout(() => history.replace(pageNav[0]), 0)
            }
            setTimeout(() => channel("GLOBAL").publish("WORKTASK/OPEN_NEW", { openExisting: false }), 0)
        }
    }

    unregister = history.listen(listener)
    listener(history.location)
}
