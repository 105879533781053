import { Article, ArticlePrice, CatalogPart, ErpPrice, IncludedWorkRequest, OePart, PriceType, RepairTime } from "@tm/models"

export type ICalculationItem = {
    oeArticle: ExtendedOePart
    alternativeParts: Part[]
    selectedPart?: Part
    isSelected?: boolean
    uniqueId: string
    showWMArticleFirst?: boolean
}

export type Part = CatalogPart & {
    article?: CustomArticle
}

export type CustomArticle = Omit<Article, "prices"> & {
    prices?: Array<CustomArticlePrice>
}

// TODO quick type fix (move to @tm/models)
export type ExtendedOePart = OePart & {
    type?: number
    datProcessId?: number
    isExtraArticle?: boolean
    articleTraderNumber?: string
}

export type CustomArticlePrice = ArticlePrice & {
    description: string
    priceType: PriceType
    value: number
    currencyCode: string
    currencySymbol: string
    priceUnitId: number
    discount: number
    discountGroupId: number
}

export type ErpPrices = {
    dataSupplierArticleNumber: string
    prices: ErpPrice[]
}

export type UpdatedTotals = {
    totalSparePartsPrice: number
    totalWorksPrice: number
    totalNetPrice: number
    totalGrossPrice: number
}

export type Work = RepairTime & {
    hourlyRate: number
    value: number
    calculatedTime: number
    includedWorks?: Array<IncludedWorkRequest>
    isSelected: boolean
    isExpanded: boolean
    total: number
    datCategoryOfWork: string
    type: EDatRepairworkType
    rtUnit: number
    time: number
}

export enum EDatRepairworkType {
    PaintWork = 0,
    LabourWork = 1,
}
