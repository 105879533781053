import { Box, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { FC, useMemo } from "react"
import { mainActions, useEdsStore } from "../../data/state"
import { NavigationSteps, VehicleAttributeCodes } from "../../models"
import GraphicSearch from "../graphicSearch/GraphicSearch"
import Accordion from "../_shared/Accordion"
import Vehicle from "../vehicle/Vehicle"

const Content: FC = () => {
    const { translateText } = useLocalization()

    const activeView = useEdsStore((state) => state.main.view)
    const vehicle = useEdsStore((state) => state.vehicle.data)
    const treeNavigation = useEdsStore((state) => state.treeNavigation.data)

    const handleChange = (view: NavigationSteps) => {
        mainActions.changeStep(view)
    }

    const renderVehicleDetails = useMemo(() => {
        const { yqVehicle, vin } = vehicle
        const yqArrtributes = yqVehicle?.attributes
        const vehicleAttributes = []

        vehicleAttributes.push(yqVehicle?.brand)
        vehicleAttributes.push(yqVehicle?.model)
        vehicleAttributes.push(yqArrtributes?.find((a) => a.code === VehicleAttributeCodes.engine)?.values.first())
        vehicleAttributes.push(yqArrtributes?.find((a) => a.code === VehicleAttributeCodes.engineInfo)?.values.first())
        vehicleAttributes.push(vin)
        vehicleAttributes.push(yqArrtributes?.find((a) => a.code === VehicleAttributeCodes.date)?.values.first())
        vehicleAttributes.push(yqArrtributes?.find((a) => a.code === VehicleAttributeCodes.framecolor)?.values.first())
        vehicleAttributes.push(yqArrtributes?.find((a) => a.code === VehicleAttributeCodes.transmission)?.values.first())
        vehicleAttributes.push(yqArrtributes?.find((a) => a.code === VehicleAttributeCodes.prodrange)?.values.first())

        return (
            <>
                <Typography variant="h4" sx={{ width: "10%" }}>
                    {translateText(13395)}
                </Typography>
                {yqVehicle && (
                    <Box sx={{ display: "flex", gap: "2em" }}>
                        {vehicleAttributes.map((attribute, index) => {
                            if (attribute) {
                                return <Typography key={index}>{attribute}</Typography>
                            }
                            return null
                        })}
                    </Box>
                )}
            </>
        )
    }, [translateText, vehicle])

    return (
        <Box sx={{ marginTop: "1em", display: "flex", flexDirection: "column", flex: "1", gap: "0.5em" }}>
            <Accordion
                icon={<Icon name="up" />}
                expandIcon={<Icon name="down" />}
                expanded={activeView === NavigationSteps.Vehicle}
                onClick={() => handleChange(NavigationSteps.Vehicle)}
            >
                <Box sx={{ display: "flex", width: "100%" }}>{renderVehicleDetails}</Box>
                <Vehicle />
            </Accordion>

            <Accordion
                keepContent={!!treeNavigation}
                fullHeight
                icon={<Icon name="up" />}
                expandIcon={<Icon name="down" />}
                expanded={activeView === NavigationSteps.GraphicSearch}
                onClick={() => handleChange(NavigationSteps.GraphicSearch)}
            >
                <Typography variant="h4" sx={{ width: "10%" }}>
                    {translateText(13396)}
                </Typography>
                <GraphicSearch />
            </Accordion>
        </Box>
    )
}

export default Content
