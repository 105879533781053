import { Suspense } from "react"
import { Loader, Widget } from "@tm/controls"
import { style } from "typestyle"
import { useLocalization } from "@tm/localization"
import { Content } from "./components/Content"

type Props = { marginTop?: string }

export default function WidgetWithLoader(props: Props) {
    const { translateText } = useLocalization()
    return (
        <Widget
            iconName="chat"
            size="4x2"
            active
            title={translateText(996)}
            className={style({
                $nest: {
                    ".widget__header": { marginBottom: "0.2rem" },
                    ".widget__content": { overflowY: "hidden", alignItems: "flex-start" },
                },
                marginTop: props.marginTop,
            })}
        >
            <Suspense fallback={<Loader />}>
                <Content />
            </Suspense>
        </Widget>
    )
}
