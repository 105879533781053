import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function carmunicationFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const carmunicationModule = getModuleFromUserContext(userContext, UserModuleType.Carmunication)

    if (!carmunicationModule) {
        return undefined
    }

    return {
        carmunication: {
            items: {
                carmunication: {
                    sort: 0,
                    name: "Carmunication",
                    active: true,
                    route: "/:workTaskId/carmunication",
                },
            },
        },
    }
}
