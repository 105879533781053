import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Typography } from "@tm/components"
import { AllowedFileSelectionTypes, getAcceptAttribute, getMimeTypes } from "../../helpers/upload"
import { StyledLabel, StyledUploadField } from "./shared/DragAndDropComponents"

type Props = {
    onFileUpload: (file: File) => void
}

export function DragAndDrop({ onFileUpload }: Props) {
    const ref = React.useRef<HTMLInputElement>(null)
    const { translateText } = useLocalization()
    const allowedFileTypes: AllowedFileSelectionTypes = useMemo(() => ["jpg", "png", "pdf"], [])

    const onChangeFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) {
            onFileUpload(file)
        }
    }

    const handlePaste = useCallback(
        (e: ClipboardEvent): void => {
            const clipboardItems: DataTransferItemList | undefined = e.clipboardData?.items

            if (!clipboardItems) {
                return
            }

            const items = [].slice.call(clipboardItems).filter(({ type }: DataTransferItem) => getMimeTypes(allowedFileTypes).includes(type))

            if (items.length === 0) {
                return
            }

            const item: DataTransferItem = items[0]
            const file = item.getAsFile()
            if (file) {
                onFileUpload(file)
            }
        },
        [allowedFileTypes]
    )

    useEffect(() => {
        document.addEventListener("paste", handlePaste, true)

        return () => {
            document.removeEventListener("paste", handlePaste, true)
        }
    }, [handlePaste])

    useEffect(() => {
        const uploadField = document.getElementById("uploadField")
        uploadField?.addEventListener("dragover", (e) => {
            e.preventDefault()
        })
        uploadField?.addEventListener("drop", (e) => {
            e.preventDefault()
            const file = e.dataTransfer?.files?.[0]
            if (file) {
                onFileUpload(file)
            }
        })
    }, [onFileUpload])

    return (
        <StyledUploadField id="uploadField">
            <input
                id="inputBox"
                name="inputBox"
                ref={ref}
                type="file"
                accept={getAcceptAttribute(allowedFileTypes)}
                onChange={onChangeFile}
                style={{ display: "none" }}
            />
            <StyledLabel htmlFor="inputBox">{translateText(1163)}</StyledLabel>
            <Typography>{translateText(12789)}</Typography>
        </StyledUploadField>
    )
}
