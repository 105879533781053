import { getStyleTheme } from "@tm/context-distribution"
import { style } from "typestyle"

export function getComponentStyles() {
    const { colors, box } = getStyleTheme()

    return style({
        padding: "0.25em 0.75em",
        marginBottom: 0,
        border: `solid 1px ${colors.primary}`,
        borderRadius: box.radius,
        $nest: {
            "> div": {
                display: "flex",
                alignItems: "center",
            },
            "&__status": {
                flex: "0 0 auto",
                margin: "-0.5em 0",
                opacity: 1,
            },
            "&__icon": {
                flex: "0 0 auto",
                marginLeft: "1em",
                marginRight: "0.5em",
            },
            "&__tooltip": {
                $nest: {
                    ".tooltip__wrapper": {
                        width: "12em",
                        textAlign: "center",
                        whiteSpace: "pre-wrap",
                    },
                },
            },
            "&__button": {
                marginLeft: "auto",
            },
        },
    })
}
