import { useMemo } from "react"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { ErpPriceDictionary, PriceType } from "@tm/models"
import { Typography } from "@tm/components"
import { LoaderSmall, StackRow } from "../../StyledComponents"
import ErrorButton from "../../_shared/ErrorButton"

type Props = {
    loading: boolean
    numberOfPositionsInOrder?: number
    numberOfItemsInOrder?: number
    erpTotalsInOrder?: ErpPriceDictionary
    workTaskIds: string[]
    hasErrors: boolean
}

export default function Totals({ loading, numberOfItemsInOrder, numberOfPositionsInOrder, erpTotalsInOrder, workTaskIds, hasErrors }: Props) {
    const { translate, currency } = useLocalization()
    const { userSettings } = useUser() || {}
    const showPurchasePrice = userSettings?.showPurchasePrice ?? true

    const totalPrice = useMemo(() => {
        const calculatedPrices = erpTotalsInOrder ? Object.values(erpTotalsInOrder) : []
        const purchasePrice = calculatedPrices.find((price) => price?.type === PriceType.Purchase)
        const retailPrice = calculatedPrices.find((price) => price?.type === PriceType.Retail)
        let price = purchasePrice ? currency(purchasePrice.value, purchasePrice.currencyCode_Iso_4217 || purchasePrice.currencySymbol) : "-"
        if (!showPurchasePrice) {
            price = retailPrice ? currency(retailPrice.value, retailPrice.currencyCode_Iso_4217 || retailPrice.currencySymbol) : "-"
        }

        return price
    }, [currency, erpTotalsInOrder, showPurchasePrice])

    return (
        <StackRow spacing={4}>
            <Typography variant="body2">
                {numberOfPositionsInOrder ?? ""} {translate(479)}, {numberOfItemsInOrder ?? ""} {translate(90)}
            </Typography>
            <StackRow gap={1}>
                {(loading || loading) && <LoaderSmall />}
                {!loading && (
                    <>
                        <Typography color={showPurchasePrice ? "primary" : undefined} fontWeight="bold">
                            {totalPrice}
                        </Typography>
                        <Typography variant="label">{erpTotalsInOrder?.purchase?.taxIncluded ? translate(13668) : translate(694)}</Typography>
                        {hasErrors && <ErrorButton basketWithErrors workTaskIds={workTaskIds} additionalErrorTextId={13663} />}
                    </>
                )}
            </StackRow>
        </StackRow>
    )
}
