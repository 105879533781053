import { Box, Icon, Stack, Typography, styled } from "@tm/components"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { DateField, LicensePlateField, SuggestionTextField, TextField, VinField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { filterModelsByInitialRegistration } from "../../../data/helpers"
import { useContainer } from "../../../data/repositories"
import { MainState } from "../../main"
import { MainActions, modelStateSelector, vehicleFieldsSelector, vehicleModelsSelector, vehicleSelector } from "../../main/business"

const vehicleModelState = createSelector(modelStateSelector, (modelState) => {
    const { initialRegistration, mileage, plateNumber, vin } = modelState as any
    return { initialRegistration, mileage, plateNumber, vin }
})

const selector = initSelector(vehicleSelector, vehicleFieldsSelector, vehicleModelsSelector)

export default function VehicleFields() {
    const { translateText } = useLocalization()
    const modelState = useSelector((s: MainState) => vehicleModelState(s, translateText))
    const actions = useActions(MainActions, "updateVehicleInfoFields")

    const [vehicle, vehicleInfoFields, vehicleModels] = useSelector(selector)
    const { models } = vehicleModels

    let filteredVehicle = models

    if (vehicleInfoFields.initialRegistration && models) {
        filteredVehicle = filterModelsByInitialRegistration(vehicleInfoFields.initialRegistration, models)
    }

    const showEquipmentFilter = filteredVehicle?.some((x) => x.equipment)

    const handleChangeInitialRegistration = (initialRegistration: Date) =>
        actions.updateVehicleInfoFields({ ...vehicleInfoFields, initialRegistration })
    const handleVinFieldChange = (vin: string) => actions.updateVehicleInfoFields({ ...vehicleInfoFields, vin })
    const handlePlateNumberChange = (plateNumber: string) => actions.updateVehicleInfoFields({ ...vehicleInfoFields, plateNumber })

    const handleMileageChange = (mileage: string) => {
        // eslint-disable-next-line no-param-reassign
        mileage = mileage.replace(",", "").replace(".", "")

        actions.updateVehicleInfoFields({ ...vehicleInfoFields, mileage: (mileage && parseInt(mileage)) || undefined })
    }

    const handleGetVinSuggestions = (query: string): Promise<Array<string>> =>
        useContainer().action("getVinSuggestions")(query, vehicle!.tecDocTypeId)

    const handleGetEquipmentPackageSuggestions = (): Array<string> =>
        models
            ?.map((model) => model.equipment)
            .distinct()
            .filter((model) => !!model) ?? []
    const handleEquipmentPackageOnConfirm = (equipmentPackage: string) =>
        actions.updateVehicleInfoFields({ ...vehicleInfoFields, selectedEquipmentPackage: equipmentPackage })
    const { plateCode } = useCountryCodeToLicensePlate(vehicle?.countryCode)
    return (
        <Box>
            <Box sx={{ alignItems: "center", mb: 1, display: "flex" }} className="field">
                <Icon name="car-front" className="field__icon" width="28px" height="28px" />
                <Typography sx={{ fontSize: "1.5rem", margin: "0 0.5em" }}>{translateText(99)}</Typography>
            </Box>
            <Stack direction="row" spacing={1} sx={{ mb: 2 }}>
                <StyledBox className={vehicleInfoFields.initialRegistration ? "initial-registration" : "initial-registration-error"}>
                    <DateField
                        floatingLabel
                        label={translateText(124)}
                        value={vehicleInfoFields.initialRegistration}
                        minDate={vehicleInfoFields.minDate}
                        maxDate={vehicleInfoFields.maxDate}
                        openToDate={vehicleInfoFields.initialRegistration || vehicleInfoFields.minDate}
                        onChange={handleChangeInitialRegistration}
                        path={["initialRegistration"]}
                        modelState={modelState}
                    />
                </StyledBox>
                <VinField
                    floatingLabel
                    label={translateText(101)}
                    coloredBorder
                    value={vehicleInfoFields.vin}
                    onChangeConfirm={handleVinFieldChange}
                    onChange={handleVinFieldChange}
                    getSuggestionDataAsync={handleGetVinSuggestions}
                    path={["vin"]}
                    modelState={modelState}
                />
                <LicensePlateField
                    floatingLabel
                    label={translateText(21)}
                    value={vehicleInfoFields.plateNumber}
                    shortCountryCode={plateCode}
                    onChangeConfirm={handlePlateNumberChange}
                    maxLength={17}
                    path={["plateNumber"]}
                    modelState={modelState}
                    showErrorBorder
                />
                <TextField
                    floatingLabel
                    label={translateText(125)}
                    value={vehicleInfoFields.mileage}
                    onChangeConfirm={handleMileageChange}
                    pattern={/[\d.]*/}
                    formatter={(value) => value.replace(/[^\d]/, "").replace(",", "").replace(".", "")}
                    path={["mileage"]}
                    modelState={modelState}
                />
                {showEquipmentFilter && (
                    <Box>
                        <SuggestionTextField
                            floatingLabel
                            label={translateText(1484)}
                            value={vehicleInfoFields.selectedEquipmentPackage}
                            getSuggestionData={handleGetEquipmentPackageSuggestions}
                            onChangeConfirm={handleEquipmentPackageOnConfirm}
                        />
                    </Box>
                )}
            </Stack>
        </Box>
    )
}

const StyledBox = styled(Box)({
    ".initial-registration": {
        position: "relative",
        ".react-datepicker-wrapper": {
            display: "block",
            ".react-datepicker__input-container": {
                display: "block",
            },
        },
    },

    ".initial-registration-error": {
        padding: "0.5em",
        fontSize: "1.5rem",
        position: "relative",
        ".react-datepicker-wrapper": {
            display: "block",
            ".react-datepicker__input-container": {
                display: "block",
            },
        },
    },
})
