import { BundleMicro } from "@tm/morpheus"
import { reduce, } from "./business"
import component from "./component"

const addRtToRDBasket: BundleMicro = {
    name: "rd-add-rt-to-basket",
    reduce,
    component,
}

export default addRtToRDBasket
