import { useState } from "react"

export function useActivation(): [boolean, (value: boolean) => void] {
    const activation = sessionStorage.getItem("SCHEDULE_ACTIVATED") === "true"
    const [isActivated, setIsActivated] = useState(activation)

    function setActivation(value: boolean) {
        sessionStorage.setItem("SCHEDULE_ACTIVATED", String(value))
        setIsActivated(value)
    }

    return [isActivated, setActivation]
}
