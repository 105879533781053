import { Key, ReactNode } from "react"

export enum ConflictStepStatus {
    Disabled = 1,
    Current = 2,
    Done = 3,
}

export type ConflictStep = {
    status: ConflictStepStatus
    render: (key: Key, status: ConflictStepStatus) => ReactNode
}

export type ConflictFlow = {
    reason: string
    steps: Array<ConflictStep>
}

export type VehicleSimple = {
    description: string
    manufacturerThumbnail?: string
    modelSeriesThumbnail?: string
    plateId?: string
    vin?: string
    registrationNo?: string
    initialRegistration?: Date
    engineCode?: string
    mileAge?: number
    lastGeneralInspection?: Date
    nextGeneralInspection?: Date
    nextServiceDate?: Date
    longlife?: boolean
    countryCode?: string
}
