import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { PrepareCalculationRequest, PrepareCalculationResponse } from "./model"

export * from "./helpers"

export const prepareCalculation = (request: PrepareCalculationRequest) => {
    const url = `${getServiceUrl()}/PrepareCalculation`
    const authorization = getStoredAuthorization()

    return ajax<PrepareCalculationResponse>({ url, body: request, method: "POST", authorization }).then((response) => {
        if (!response) {
            throw new Error()
        }
        return response
    })
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.asWegasServiceUrl
}
