import { Article } from "@tm/models"
import { CompilationsContainsResponse } from "../../../compilations/src/data"

function getTimestamp(dateStr: string): number {
    return new Date(dateStr).getTime()
}

type DateObj = {
    createDate: string
}

export function sortByCreatedAtDesc(dateA: DateObj, dateB: DateObj): number {
    return getTimestamp(dateB.createDate) - getTimestamp(dateA.createDate)
}

export function isArticleInWatchlist(article: Article, containedArticle?: CompilationsContainsResponse): boolean {
    return (
        (containedArticle &&
            containedArticle.productGroupId === article.productGroup.id &&
            containedArticle.supplierId === article.supplier.id &&
            containedArticle.supplierArticleNumber === article.supplierArticleNo) ??
        false
    )
}
