import { AttributeFilterModel, EFilterNames, FilterNames, ShowVehicleRecordsResponse, TyreFilter } from "@tm/models"
import { VehRecords } from "../../components/moduleManager/business"
import { Filters } from "../../components/summary/business"

export function mapVehicleRecordsResponse(response: ShowVehicleRecordsResponse, filters: Filters) {
    const newVehRecords = response.productGroupFilters?.first()?.attributeFilters ?? []

    const widthGroupId = filters.width.first()?.groupId
    const heightGroupId = filters.height.first()?.groupId
    const inchGroupId = filters.inch.first()?.groupId

    const widthRecords = newVehRecords.find(x => x.id == widthGroupId)
    const heightRecords = newVehRecords.find(x => x.id == heightGroupId)
    const inchRecords = newVehRecords.find(x => x.id == inchGroupId)

    const vehRecords: VehRecords  = {
        width: mapAttributeFilterModelToTireFilter(EFilterNames.width, widthRecords),
        height: mapAttributeFilterModelToTireFilter(EFilterNames.height, heightRecords),
        inch: mapAttributeFilterModelToTireFilter(EFilterNames.inch, inchRecords),
        // speedIndex: mapAttributeFilterModeltoTireFilter(vehRecords.find(x => x.id == FilterIDS.SpeedIndex)),
        // loadIndex: mapAttributeFilterModeltoTireFilter(vehRecords.find(x => x.id == FilterIDS.LoadIndex)),
        // oeIdentifier: mapAttributeFilterModeltoTireFilter(vehRecords.find(x => x.id == FilterIDS.OeIdentifier)),
    }

    return vehRecords
}

function mapAttributeFilterModelToTireFilter(group: FilterNames, attr?: AttributeFilterModel): TyreFilter | undefined {
    if (!attr) {
        return undefined
    }

    return {
        group,
        groupId: attr.id,
        value: attr.value,
        query: attr.value
    }
}