import { useLocalization } from "@tm/localization"
import { Widget, WidgetSizes } from "@tm/controls"
import { Icon, Stack, Typography, useTheme, Loader } from "@tm/components"
import { useCisCustomer } from "@tm/context-distribution"
import PhoneComponent from "../_shared/customer/Phone"

type Props = {
    isOpen?: boolean
    title?: string
    size?: WidgetSizes
}

const translationRegex = /\{\{(.*?)\}\}/

export default function WidgetNextSupplier({ isOpen = true, size, title }: Props) {
    const { translateText } = useLocalization()
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()

    const theme = useTheme()
    const widgetTitleColor = theme.palette.highlight.main

    const openingTimes = cisCustomer?.salesOutlets?.[0]?.businessTimeDescriptions
    const phonenumber = cisCustomer?.salesOutlets?.[0]?.address?.phone
    let location = cisCustomer?.salesOutlets?.[0]?.name || cisCustomer?.salesOutlets?.[0]?.description
    // TODO: For WM fair purposes hot fix, must be fixed on WM side.
    if (!location) {
        location = cisCustomer?.salesOutlets?.[0]?.address?.city
    }

    function renderOpeningTimes() {
        if (!openingTimes) {
            return null
        }

        if (typeof openingTimes === "string") {
            return openingTimes
        }

        return openingTimes.map((openingTime) => (
            <Typography variant="label" key={`saleshouse-w${openingTime.days}`}>
                <Stack spacing={1.5} direction="row" alignItems="center" justifyContent="space-between">
                    <div>{openingTime.days}</div>
                    <div>{openingTime.times}</div>
                </Stack>
            </Typography>
        ))
    }

    let qualifiedTitle = translateText(1106)
    if (title) {
        qualifiedTitle = title.replace(translationRegex, (s, num) => translateText(num))
    }

    if (!cisCustomer) {
        return null
    }

    let widgetContent
    if (location) {
        widgetContent = (
            <Stack direction="row" spacing={1} justifyContent="space-around" alignItems="center" whiteSpace="nowrap" mt="0.5rem">
                <Icon name="phone-speaker" size="3.5rem" />
                <Stack spacing={1}>
                    <Typography variant="h3" fontWeight="600">
                        {location}
                    </Typography>
                    <PhoneComponent phone={phonenumber} />
                </Stack>
                <Stack spacing={1.5}>{renderOpeningTimes()}</Stack>
            </Stack>
        )
    } else {
        widgetContent = (
            <Typography variant="h4" fontWeight="bold">
                {translateText(13673)}
            </Typography>
        )
    }

    return (
        <Widget
            active={isOpen}
            size={size || "4x2"}
            title={
                <Typography variant="h3" color={widgetTitleColor}>
                    {qualifiedTitle}
                </Typography>
            }
        >
            {cisCustomerLoading ? <Loader /> : widgetContent}
        </Widget>
    )
}
