import { useMemo } from "react"
import { Button, Icon, Loader } from "@tm/controls"
import { getComponentStyles } from "./styles"

type Props = {
    text: string
    loading?: boolean
    linkTo?: string
    disabled?: boolean
    icon?: string
    onClick?(): void
}

export default function WideButton({ text, icon, loading, linkTo, disabled, onClick }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])

    if (loading) {
        return <Loader />
    }

    if (linkTo) {
        return (
            <a className={`btn btn--holo has-icon ${classNames.button}`} href={linkTo} target="_blank" rel="noopener noreferrer">
                {icon && <Icon name={icon} className="btn__icon" />}
                <div className="btn__content">{text}</div>
            </a>
        )
    }

    return (
        <div>
            <Button layout={["holo"]} onClick={onClick} icon={icon} title={text} className={classNames.button} disabled={disabled}>
                {text}
            </Button>
        </div>
    )
}
