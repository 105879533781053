import { getStoredAuthorization, ajax } from "@tm/utils"
import { getAddressPickerServiceUrl } from ".."
import { GetStreetsRequest, Street } from "../model"

export function getStreets(request: GetStreetsRequest): Promise<Array<Street>> {
    const url = `${getAddressPickerServiceUrl()}/Streets`
    const authorization = getStoredAuthorization()
    const body = request

    return new Promise((resolve, reject) => ajax({ url, authorization, body }).then((response) => resolve(response.streets || []), reject))
}
