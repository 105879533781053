import { create } from "zustand"
import { createJSONStorage, devtools, persist } from "zustand/middleware"
import { isDevtoolEnabled } from "@tm/utils"
import { createMainSlice, initialState, MainSlice } from "./main"
import { zustandSessionStorage } from "./sessionStorage"
import { replacer, reviver } from "./sessionStorage/helper"

export type EdsStore = MainSlice

export const useEdsStore = create<EdsStore>()(
    devtools(
        persist(
            (...a) => ({
                ...createMainSlice(...a),
            }),
            {
                name: "EDSStore",
                skipHydration: true,
                storage: createJSONStorage(() => zustandSessionStorage, { replacer, reviver }),
                version: 0,
            }
        ),
        { name: "EDS Store", enabled: isDevtoolEnabled() }
    )
)

export function resetStore() {
    useEdsStore.setState(() => ({
        ...initialState,
    }))

    useEdsStore.persist.clearStorage()
}
