export type APICallBase = {
    token: string
}

export enum SortTypes {
    creationDate = "creation_date",
    lastUploaded = "last_uploaded",
    vin = "vin",
    registration = "registration",
    technicianUid = "technician_uid",
    externalJobId = "external_job_id",
}
