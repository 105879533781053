import { getStoredAuthorization, ajax } from "@tm/utils"
import { BaseInfo, PrepareCalculationResponse } from ".."
import { getBundleParams } from "../../../utils"

export function prepareSettings() {
    const url = `${getServiceUrl()}/PrepareSettings`
    const authorization = getStoredAuthorization()

    return new Promise<BaseInfo>((resolve, reject) =>
        ajax({ url, body: {}, method: "POST", authorization }).then((response: PrepareCalculationResponse) => resolve(response?.baseInfo), reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.glassServiceUrl
}
