import { useState, MouseEvent, useMemo, useEffect, useCallback, CSSProperties, useRef } from "react"
import { Box, Popover, Typography, Button, styled, Icon, ButtonProps, buttonClasses, lighten } from "@tm/components"
import { AttributeFilterModel, PartsBundleParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import FilterSelectOptions from "./FilterSelectOptions"
import { useSelectOptions } from "./useSelectOptions"
import { getModuleVehicleRecordMode, useGetFittingSideText } from "../../../../../helper"
import FilterItem from "./FilterItem"

type SelectButtonProps = {
    selected: boolean
    suggested: boolean
}

const SelectButton = styled(Button, { shouldForwardProp: (prop: string) => !["selected", "suggested"].includes(prop) })<SelectButtonProps>(
    ({ theme, selected, suggested }) => {
        const { getContrastText } = theme.palette

        const backgroundColorContained: string = selected ? theme.palette.highlight.main : lighten(theme.palette.highlight.light, 0.4)

        const outlinedStyle: CSSProperties = {}
        if (selected) {
            outlinedStyle.borderColor = theme.palette.highlight.main
        } else if (suggested) {
            outlinedStyle.borderColor = lighten(theme.palette.highlight.light, 0.4)
        }
        return {
            letterSpacing: 0,
            marginRight: theme.spacing(0.5),
            height: "38px",
            svg: {
                marginRight: 0,
            },
            ...(selected ? { borderColor: theme.palette.highlight.main } : {}),
            [`&.${buttonClasses.contained}`]: {
                backgroundColor: backgroundColorContained,
                color: getContrastText(backgroundColorContained),
            },
            [`&.${buttonClasses.outlined}`]: outlinedStyle,
            [`&.${buttonClasses.sizeMedium}`]: {
                padding: "2px 5px",
            },
        }
    }
)

const SelectView = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    textAlign: "left",
    marginRight: theme.spacing(0.5),
}))

type SelectLabelProps = { small: boolean }
const SelectLabel = styled(Typography, { shouldForwardProp: (propName) => propName !== "small" })<SelectLabelProps>(({ small, theme }) => ({
    fontFamily: theme.font?.fontFamily?.condensed ? theme.font.fontFamily.condensed : theme.font.fontFamily.secondary,
    lineHeight: 1.2,
    ...(!small ? { marginLeft: 5 } : {}),
    color: "unset",
}))

const StyledButton = styled(Button)(() => ({
    color: "unset !important",
}))

type AttributeFilterDropdownProps = {
    attributeFilter: AttributeFilterModel
    selectedAttributes?: string[]
    prefilledAttributes?: string[]
    modelId: number
    onSelectFilter?: (attribute: AttributeFilterModel, previousProductGroupFilter?: AttributeFilterModel) => void
    onDeselectFilter?: (attribute: AttributeFilterModel) => void
}

enum AttributeMatchResult {
    ShowReset = "showReset",
    ShowArrowWithPrefill = "showArrowWithPrefill",
    ShowArrow = "showArrow",
}

function hasMatchingId(attributeFilter: AttributeFilterModel, prefilledAttributes: string[] | [], selectedAttributes: string[] | []) {
    const objectId = attributeFilter.id.toString()
    if (selectedAttributes.some((item) => item.startsWith(`${objectId}|`))) {
        return AttributeMatchResult.ShowReset
    }
    if (prefilledAttributes.some((item) => item.startsWith(`${objectId}|`))) {
        return AttributeMatchResult.ShowArrowWithPrefill
    }
    return AttributeMatchResult.ShowArrow
}

function getQueryForFilter(filter: AttributeFilterModel) {
    // Usually, an attribute filter query is in the format id|key.
    // Since AttributeFilterModel does not have a key property and stores the same information in the value property,
    // the resulting query is in the format id|value.
    return `${filter.id}|${filter.value}`
}

export default function AttributeFilterDropdown(props: AttributeFilterDropdownProps) {
    const { attributeFilter, selectedAttributes, modelId, onSelectFilter, onDeselectFilter, prefilledAttributes } = props
    const [selectedFilter, setSelectedFilter] = useState<AttributeFilterModel | undefined>()
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null)
    const anchorWidthRef = useRef<number>(0)
    const [startOptionsLoading, setStartOptionsLoading] = useState(false)
    const { isOptionsLoading, options } = useSelectOptions(attributeFilter, modelId, startOptionsLoading)
    const suggestedFilter = useMemo(() => (attributeFilter.value ? attributeFilter : undefined), [attributeFilter])
    const getFittingSideText = useGetFittingSideText()
    const checkMatchingId = hasMatchingId(attributeFilter, prefilledAttributes ?? [], selectedAttributes ?? [])
    const { listV2 } = Morpheus.getParams<PartsBundleParams>("parts")

    const handleClose = useCallback(() => {
        setAnchor(null)
    }, [])

    useEffect(() => {
        setStartOptionsLoading(!!anchor)

        if (anchor) {
            anchorWidthRef.current = anchor.clientWidth
        }
    }, [anchor])

    useEffect(() => {
        if (!selectedAttributes?.length && !prefilledAttributes?.length) {
            setSelectedFilter(undefined)
            return
        }
        if (selectedAttributes && suggestedFilter && selectedAttributes?.includes(getQueryForFilter(suggestedFilter))) {
            setSelectedFilter(suggestedFilter)
            return
        }
        if (!options) {
            setStartOptionsLoading(true)
            return
        }

        let filter = selectedAttributes && options.find((f) => selectedAttributes.includes(getQueryForFilter(f)))
        if (!filter && prefilledAttributes && listV2) {
            filter = options.find((f) => prefilledAttributes.includes(getQueryForFilter(f)))
        }

        setSelectedFilter(filter)
    }, [selectedAttributes, options, suggestedFilter, prefilledAttributes])

    const handleClickDropdown = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            if (anchor) {
                handleClose()
                return
            }
            if (selectedFilter) {
                setSelectedFilter(undefined)
                selectedFilter && onDeselectFilter?.(selectedFilter)
                return
            }
            if (!suggestedFilter) {
                setAnchor(event.currentTarget)
                return
            }
            setSelectedFilter(suggestedFilter)
            onSelectFilter?.(suggestedFilter)
        },
        [anchor, selectedFilter, suggestedFilter, handleClose, onSelectFilter, onDeselectFilter]
    )

    const handleClickDeselectIcon = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            setSelectedFilter(undefined)
            selectedFilter && onDeselectFilter?.(selectedFilter)
        },
        [selectedFilter, onDeselectFilter]
    )

    const handleClickToggleIcon = useCallback(
        (event: MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation()
            if (anchor) {
                handleClose()
                return
            }
            setAnchor(event.currentTarget.parentElement as HTMLButtonElement)
        },
        [anchor, handleClose]
    )

    const handleSelectFilter = useCallback(
        (filter: AttributeFilterModel) => {
            setSelectedFilter(filter)
            onSelectFilter?.(filter, selectedFilter)
            handleClose()
        },
        [selectedFilter, onSelectFilter, handleClose]
    )

    const currentFilter = selectedFilter || suggestedFilter

    const vehicleRecordMode = useMemo(() => getModuleVehicleRecordMode(), [])

    const label = useMemo(() => {
        if (
            vehicleRecordMode &&
            currentFilter &&
            currentFilter.fittingSide &&
            vehicleRecordMode.hideAxleSwitch &&
            vehicleRecordMode.mode === "DatVinOnly"
        ) {
            const bothFittingSides = (options?.filter((x) => !!x.fittingSide) || []).length > 1
            const fittingSideText = bothFittingSides ? "" : `- ${getFittingSideText(currentFilter.fittingSide)}`
            return `${currentFilter.description}${fittingSideText}`
        }
        return attributeFilter.description
    }, [currentFilter, attributeFilter, getFittingSideText, options, vehicleRecordMode])

    // In the selflearnig mode, the attributfilter should be highlighted, when it has a value coming from vehiclerecords
    const selectButtonHighlightProps: Pick<ButtonProps, "variant" | "color"> = useMemo(() => {
        if (
            (vehicleRecordMode?.mode === "SelfLearning" && !!attributeFilter.value) ||
            checkMatchingId === AttributeMatchResult.ShowArrowWithPrefill
        ) {
            return {
                variant: "contained",
                color: "highlight",
            }
        }
        return {
            variant: "outlined",
        }
    }, [attributeFilter.value])

    return (
        <>
            <SelectButton
                {...selectButtonHighlightProps}
                onClick={handleClickDropdown}
                disableRipple
                suggested={!!currentFilter}
                selected={!!selectedFilter}
            >
                <SelectView>
                    <SelectLabel small={!!currentFilter} variant={currentFilter ? "body3" : "body2"}>
                        {label}
                    </SelectLabel>
                    <FilterItem filter={currentFilter} isDisplay />
                </SelectView>
                {checkMatchingId === AttributeMatchResult.ShowReset ? (
                    <StyledButton variant="text" onClick={handleClickDeselectIcon} style={{ padding: 0 }}>
                        <Icon name="close" />
                    </StyledButton>
                ) : (
                    <StyledButton variant="text" onClick={handleClickToggleIcon} style={{ padding: 0 }}>
                        <Icon name={anchor ? "up" : "down"} />
                    </StyledButton>
                )}
            </SelectButton>
            <Popover
                open={!!anchor}
                anchorEl={anchor}
                onClose={handleClose}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                PaperProps={{
                    style: {
                        minWidth: anchorWidthRef.current,
                        maxHeight: 350,
                    },
                }}
            >
                <FilterSelectOptions options={options} isLoading={isOptionsLoading} onSelect={handleSelectFilter} />
            </Popover>
        </>
    )
}
