import * as React from "react"
import { Actions } from "./business"
import { AmountItem, Scrollbar } from "@tm/controls"
import OverviewItem from "./components/overviewItem"
import VehiclePanel from "./components/vehicle-panel"
import { WheelSelectionSteps } from "../../data/enums"
import { Article, RegisteredModels, SearchFilters } from "@tm/models"
import { batch, useDispatch, useSelector } from "react-redux"
import { MainState } from "../main"
import { vehicleSelector } from "../../data/helpers"
import { MainActions } from "../main/business"
import { Container } from "@tm/nexus"
import { getBundleParams } from "../../utils"

type OverviewComponentProps = {
    repairTimesRoute: string
    detailsModalRoute: string
}
const Overview: React.FC<OverviewComponentProps> = ({ repairTimesRoute, detailsModalRoute }) => {
    const dispatch = useDispatch()
    const vehicle = useSelector(vehicleSelector)
    const isHostettler = getBundleParams()?.isHostettler
    const { rimItem, tireItem, sensorItem, configuratorImage, mountingEnabled, mountingItem, loading } = useSelector((s: MainState) => ({
        rimItem: s.overview.rimItem,
        tireItem: s.overview.tireItem,
        sensorItem: s.overview.sensorItem,
        configuratorImage: s.overview.configuratorImage,
        mountingEnabled: s.overview.mountingEnabled,
        mountingItem: s.overview.mountingItem,
        loading: s.overview.loading
    }))

    const handleRimDelete = React.useCallback((item: Article) => {
        if (rimItem?.id != item.id && rimItem) {
            console.error('can not delete rim item')
            return
        }
        if (!rimItem) return

        batch(() => {
            //TODO: RESET HISTORY NAV
            dispatch(Actions.updateRimsItems(undefined))
            dispatch(MainActions.changeStep({ step: WheelSelectionSteps.WHEELSLIST, disableNextSteps: true }, false))
        })
    }, [])

    const handleTireDelete = React.useCallback((item: Article) => {
        if (tireItem?.internalId != item.internalId) {
            console.error('can not delete tire item')
            return
        }
        if (!tireItem) return

        dispatch(Actions.updateTiresItems(undefined))
    }, [])

    const handleSensorDelete = React.useCallback((item: Article) => {
        if (sensorItem?.internalId != item.internalId) {
            console.error('can not delete sensor item')
            return
        }
        if (!sensorItem) return

        dispatch(Actions.updateSensorItems(undefined))
    }, [])

    const handleRimQuanityChange = React.useCallback((item: Article, amountItem: AmountItem) => {
        if (!rimItem) return

        const rim = { ...rimItem, ...(item.internalId == rimItem.internalId) && { quantity: amountItem.value } }
        dispatch(Actions.updateRimsItems(rim))
    }, [])

    const handleTireQuantityChange = React.useCallback((item: Article, amountItem: AmountItem) => {
        if (!tireItem) return

        const tire = { ...tireItem, ...(item.internalId == tireItem.internalId) && { quantity: amountItem.value } }
        dispatch(Actions.updateTiresItems(tire))
    }, [])

    const handleSensorQuantityChange = React.useCallback((item: Article, amountItem: AmountItem) => {
        if (!sensorItem) return

        const sensor = { ...sensorItem, ...(item.internalId == sensorItem.internalId) && { quantity: amountItem.value } }
        dispatch(Actions.updateSensorItems(sensor))
    }, [])

    function searchMountItem(query: string) {
        const request = { query, searchFilter: SearchFilters.All }

        if (mountingItem) {
            return
        }

        dispatch(Actions.setOverviewLoading(true))

        Container.getInstance<Array<Article>>(RegisteredModels.Articles)
            .subscribe(request)
            .load()
            .then(articles => {
                if (articles && articles.length >= 1) {
                    dispatch(Actions.setMountingItem({ ...articles[0], quantity: 4 }))
                }

                dispatch(Actions.setOverviewLoading(false))
            })
    }

    React.useEffect(() => {
        if (isHostettler && mountingEnabled) {
            if (rimItem && tireItem) {
                searchMountItem("E995-KOMPLETTRAD")
            }
        }
    }, [rimItem, tireItem, isHostettler, mountingEnabled])

    return (
        <div className="wheels__overview__page">
            <Scrollbar>
                <VehiclePanel
                    items={[rimItem, sensorItem, tireItem, (mountingEnabled ? mountingItem : undefined)].filter(x => !!x) as Article[]}
                    configuratorImage={configuratorImage}
                    repairTimesRoute={repairTimesRoute}
                    vehicle={vehicle}
                    loading={loading}
                />
                <div className="tk-parts">
                    <div className="article-list">
                        {rimItem && <OverviewItem
                            repairTimesRoute={repairTimesRoute}
                            key={rimItem.internalId}
                            item={rimItem}
                            onDeleteClick={handleRimDelete}
                            onQuantityChange={handleRimQuanityChange}
                            detailsModalRoute={detailsModalRoute} />
                        }
                    </div>

                    <div className="article-list">
                        {sensorItem &&
                            <OverviewItem
                                key={sensorItem.internalId}
                                item={sensorItem}
                                repairTimesRoute={repairTimesRoute}
                                onDeleteClick={handleSensorDelete}
                                onQuantityChange={handleSensorQuantityChange}
                                detailsModalRoute={detailsModalRoute} />}
                    </div>

                    <div className="article-list">
                        {tireItem &&
                            <OverviewItem
                                key={tireItem.internalId}
                                item={tireItem}
                                repairTimesRoute={repairTimesRoute}
                                onDeleteClick={handleTireDelete}
                                onQuantityChange={handleTireQuantityChange}
                                detailsModalRoute={detailsModalRoute} />}
                    </div>
                </div>
            </Scrollbar>
        </div>
    )
}

export default React.memo(Overview)