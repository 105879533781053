import { useMemo } from "react"
import { useCisCustomer } from "@tm/context-distribution"
import { CountdownAnchorWrapper, CountdownWrapper } from "./helpers/CountdownWrapper"

type Props = {
    allowedConceptIds?: string[]
    linkTo?: string
    target?: string
    dueDate?: {
        day: number
        month: number
        year: number
    }
}

function EventCountdown(props: Props) {
    const concreteDate = useMemo(() => {
        return props.dueDate ? new Date(props.dueDate.year, props.dueDate.month, props.dueDate.day) : undefined
    }, [props.dueDate])

    const { cisCustomer } = useCisCustomer(!!concreteDate && !!props.allowedConceptIds?.length)

    const enabled = useMemo(() => {
        if (!concreteDate) {
            return false
        }
        if (!props.allowedConceptIds) {
            return true
        }
        return !!cisCustomer?.conceptCustomers?.some((conceptCustomer) => props.allowedConceptIds?.includes(conceptCustomer.id))
    }, [concreteDate, props.allowedConceptIds, cisCustomer?.conceptCustomers])

    if (!enabled || !concreteDate) {
        return null
    }

    return props.linkTo ? (
        <CountdownAnchorWrapper url={props.linkTo} target={props.target} dueDate={concreteDate} />
    ) : (
        <CountdownWrapper dueDate={concreteDate} />
    )
}

export default EventCountdown
