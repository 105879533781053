import { em } from "csx"
import { useMemo } from "react"
import { Divider, MuiCustomModal } from "@tm/components"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Icon, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { CollapsibleWrapper, MultiAppendix, VehicleAndCustomer } from "."
import { useFastServiceStore } from "../../data"
import { FastServiceSelection, Item } from "../../data/models"
import { useRepairTimes } from "../../helpers/hooks/useRepairTimes"

type Props = {
    open: boolean
    onOutsideClick: () => void
}

export default function CustomDetailsModal({ open, onOutsideClick }: Props) {
    const style = useMemo(() => getStyle(), [])
    const selections = useFastServiceStore((state) => state.maintenancePlan.selections)
    const followupWorks = useFastServiceStore((state) => state.maintenancePlan.followupWorks)
    const additionalWorks = useFastServiceStore((state) => state.maintenancePlan.additionalWorks)
    const { totalRepairTimesFormatted, repairTimesIncludesString } = useRepairTimes()
    const filteredFollowUp = useMemo(() => followupWorks?.filter((x) => x.items.some((y) => y.isSelected)), [followupWorks])
    const filteredAdditionalWorks = useMemo(() => additionalWorks?.filter((x) => x.items.some((y) => y.isSelected)), [additionalWorks])

    const { translateText } = useLocalization()

    const renderCollapsibles = (headingTextId: number, data?: FastServiceSelection[]) => {
        return (
            <div className={style.collapseWrapper}>
                <CollapsibleWrapper textId={headingTextId} isDisabled={!data?.length}>
                    {!!data?.length && <MultiAppendix items={data} />}
                </CollapsibleWrapper>
                <Divider light />
            </div>
        )
    }

    // TODO: move to one place, renderInterval is doubled
    const renderInterval = (item?: Item[]) => {
        if (!item?.length) {
            return null
        }

        return (
            <div className={style.boldText}>
                {item.map((x, i) => (
                    <Text size="m" key={i} modifiers={["block"]}>
                        {x.label}
                    </Text>
                ))}
            </div>
        )
    }

    return (
        <MuiCustomModal open={open} onOutsideClick={onOutsideClick}>
            <VehicleAndCustomer headerText={translateText(12541)} />

            <div className={style.collapseWrapper}>
                <CollapsibleWrapper textId={13222}>
                    <div className={style.twoRowGrid}>
                        <Text size="m" modifiers="sub" className={style.vehicleText}>
                            {translateText(1782)}:
                        </Text>
                        <Text size="m" className={style.boldText}>
                            {selections?.find((x) => x.id === "haynesProCarType")?.items.find((x) => x.isSelected)?.label || "-"}
                        </Text>

                        <Text size="m" modifiers="sub" className={style.vehicleText}>
                            {translateText(12635)}:
                        </Text>
                        <Text size="m" className={style.boldText}>
                            {selections?.find((x) => x.id === "haynesProMaintenanceSystem")?.items.find((x) => x.isSelected)?.label || "-"}
                        </Text>

                        <Text size="m" modifiers="sub" className={style.vehicleText}>
                            {translateText(12538)}:
                        </Text>
                        {renderInterval(selections?.find((x) => x.id === "haynesProMaintenancePeriod")?.items.filter((x) => x.isSelected))}
                    </div>
                </CollapsibleWrapper>
            </div>

            <Divider light />

            {renderCollapsibles(644, filteredAdditionalWorks)}
            {renderCollapsibles(797, filteredFollowUp)}
            <div className={classes(style.twoRowGrid, style.repairTimeWrapper)}>
                <div className={style.repairTimeHeader}>
                    <Icon name="time" />
                    <Text size="m" modifiers={["strong"]}>
                        {translateText(12677)}
                    </Text>
                </div>
                {totalRepairTimesFormatted && (
                    <Text size="m" className={style.boldText}>
                        {`${totalRepairTimesFormatted} ${repairTimesIncludesString}`}
                    </Text>
                )}
            </div>

            <Divider light />
        </MuiCustomModal>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        buttonWrapper: {
            display: "flex",
            alignItems: "center",
            paddingLeft: theme.margin.s,
            position: "absolute",
            top: "50%",
            right: "0",
        },
        twoRowGrid: {
            display: "grid",
            gridTemplateColumns: "20% 1fr",
            rowGap: em(0.5),
            columnGap: em(0.5),
            marginBottom: theme.margin.l,
        },
        repairTimeHeader: {
            display: "flex",
        },
        repairTimeWrapper: {
            paddingTop: theme.margin.l,
        },
        vehicleText: {
            display: "flex",
            flexBasis: "10vw",
            minWidth: "10em",
        },
        boldText: {
            display: "flex",
            flexDirection: "column",
            flex: 1,
            fontWeight: 600,
        },
        collapseWrapper: {
            $nest: {
                ".collapsible__content": {
                    paddingBottom: ".5em",
                    paddingLeft: "1em",
                },
                ".text.text--xl": {
                    fontSize: "1rem",
                },
            },
        },
    })(CustomDetailsModal)
}
