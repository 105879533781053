import { FilterType } from "."

export const isInfoAvailable = (filterId: FilterType | string): boolean => {

    switch (filterId) {
        case FilterType.SpeedIndex:
        case FilterType.LoadIndex:
        case FilterType.OeIdentifier:
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
            return true
    }

    return false
}

export const generateDialogContent = (filterId: FilterType | string, translateText: (key: string | number) => string): string[] | undefined => {

    switch (filterId) {
        case FilterType.SpeedIndex:
            return [translateText(12650), translateText(719)]
        case FilterType.LoadIndex:
            return [translateText(12651), translateText(12403)]
        case FilterType.OeIdentifier:
            return [translateText(12649), translateText(720)]
        case "1": //Runflat
            return [translateText(12688), translateText(716)]
        case "2": //Self-sealing
            return [translateText(12689), translateText(12721)]
        case "3": //Reinforced (XL/RF)
            return [translateText(12693), translateText(12694)]
        case "4": //Noise optimized
            return [translateText(12700), translateText(12701)]
        case "5": //Rim protection
            return [translateText(12691), translateText(12692)]
        case "6": //Commercial (C tyres)
            return [translateText(12695), translateText(12696)]
        case "7": //Studded included
            return [translateText(12697), translateText(12532)]
        case "8": //Studdable
            return [translateText(12698), translateText(12699)]
    }

    return undefined
}