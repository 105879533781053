import { Box, Divider, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
/**
 * @param props {text?: string} - use it to display plain text
 * @returns text with padding
 */
export function ErpSpecialProcurementText(props: { text?: string }) {
    const { translateText } = useLocalization()
    const { text } = props
    if (!text) {
        return null
    }

    return (
        <Box pb={2}>
            <Typography variant="h3">{translateText(796)}</Typography>
            <Divider variant="fullWidth" />
            <Box pt={1} width="80%">
                <Typography variant="body2">{text}</Typography>
            </Box>
        </Box>
    )
}
