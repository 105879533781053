import { SearchTreeNode } from "../../../data"

export type CategorySelectionTreeSelectionState = {
    loading: boolean
    categories: Array<SearchTreeNode>
    breadcrumbs: Array<SearchTreeNode>
}

export enum CategoryType {
    "main" = 0,
    "sub" = 1,
}
