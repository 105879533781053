import { useCisCustomer } from "@tm/context-distribution"
import { useEffect } from "react"

export default function ConceptStyle() {
    const { cisCustomer } = useCisCustomer()

    useEffect(() => {
        if (!cisCustomer?.conceptCustomers) {
            return
        }
        const currentConceptCustomerDescription = sessionStorage.getItem("conceptCustomer")

        const changedConceptCustomer = cisCustomer.conceptCustomers.find(
            (data) => data.id === "580" && !!data.description && data.description !== currentConceptCustomerDescription
        )

        if (changedConceptCustomer?.description) {
            sessionStorage.setItem("conceptCustomer", changedConceptCustomer.description)
            window.location.reload()
        }
    }, [cisCustomer?.conceptCustomers])

    return null
}
