import { stylesheet } from "typestyle"

export function getComponentStyles() {
    return stylesheet({
        main: {
            display: "flex",
            alignItems: "center",
        },
        link: {
            lineHeight: 0,
        },
        // NEXT-23945 try to reduce margin to fit all special icons in one line.. :/
        iconWrapper: {
            display: "flex",
            marginLeft: "6px",
            $nest: { "&:last-child": { marginRight: "6px" } },
        },
        iconWrapperS: {
            display: "flex",
            marginLeft: "0.25em",
            $nest: { "&:last-child": { marginRight: "0.25em" } },
        },
    })
}
