import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function edsFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const edsModule = getModuleFromUserContext(userContext, UserModuleType.Eds)

    if (!edsModule) {
        return undefined
    }

    return {
        eds: {
            items: {
                eds: {
                    sort: 0,
                    name: "EDS",
                    active: true,
                    route: "/:workTaskId/eds",
                },
            },
        },
    }
}
