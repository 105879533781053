import { ajax, getStoredAuthorization } from "@tm/utils"
import { PrepareCalculationEurotaxRequest, PrepareCalculationEurotaxResponse } from ".."
import { getBundleParams } from "../../../utils"

export * from "./model"

export const getCalculationUrl = (calculationUrlRequest: PrepareCalculationEurotaxRequest) => {
    const url = `${getServiceUrl()}/PrepareCalculation`
    const authorization = getStoredAuthorization()
    const body = calculationUrlRequest

    return new Promise<PrepareCalculationEurotaxResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => resolve(response.baseInfo), reject)
    )
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.ereCalculationServiceUrl
}
