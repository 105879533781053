import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../utils"

type Props = {
    expectedDelivery?: Date
}

export function ExpectedDelivery(props: Props) {
    const { date } = useLocalization()
    const { expectedDelivery } = props
    if (!expectedDelivery) {
        return null
    }
    return <>{date(expectedDelivery, getBundleParams().expectedDeliveryDateFormat)}</>
}
