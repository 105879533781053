import { VeFiles } from "@tm/data"
import { useMutation } from "react-query"
import { useCallback } from "react"

export function useGetSharedAccessKey() {
    const { isLoading, mutateAsync } = useMutation(VeFiles.getSharedAccessKey)

    const getSharedAccessKey = useCallback(
        (ids: string[]) => {
            const request: VeFiles.GetSharedAccessKeyRequest = {
                ids,
            }

            return mutateAsync(request)
        },
        [mutateAsync]
    )

    return { isLoading, getSharedAccessKey }
}
