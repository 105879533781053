import { IBundle } from "@tm/morpheus"
import Frame from "./components/frame"
import FeedbackFrame from "./components/feedback"
import Widget from "./components/widget"
import Alldata from "./components/alldata"
import JfNetworkPv from "./components/jf-network-pv"
import Autodata from "./components/autodata"
import HaynesPro from "./components/haynespro"
import TecRMI from "./components/tecRMI"
import DAT from "./components/dat"
import Truck from "./components/truck"
import HaynesproTdGenarts from "./components/haynespro-td-genarts"
import TecRmiTdGenarts from "./components/tecrmi-td-genarts"
import DATRegistration from "./components/dat-registration"
import Temot from "./components/temot"
import { initMvcBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    stylesUrl: "~/styles.css",
    components: [
        Alldata,
        Autodata,
        DAT,
        DATRegistration,
        FeedbackFrame,
        Frame,
        HaynesPro,
        HaynesproTdGenarts,
        JfNetworkPv,
        TecRMI,
        TecRmiTdGenarts,
        Temot,
        Truck,
        Widget,
    ],
    init: initMvcBundle,
}

export default bundle
