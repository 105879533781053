import {
    Button,
    DialogActions,
    DialogContent,
    DialogContentText,
    MuiTable as Table,
    MuiTableBody as TableBody,
    MuiTableHead as TableHead,
    MuiTableRow as TableRow,
} from "@tm/components"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { LicensePlateField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VehicleShortInfo } from "@tm/models"
import { concat, plateCountryCodeMapper } from "@tm/utils"
import { useState } from "react"
import { useVehicleById, useVehiclesByQuery } from "../hooks/useVehicleSearch"
import { ModalLoader, StyledCell } from "./_shared"
import ConflictStart from "./conflict"
import { Props } from "./vehicle-start"

const CREATE_NEW = "new"
export function VehicleSearch({ chatId, openOptions, customer, tmCustomerId, customerInfo }: Props) {
    const { translateText, date } = useLocalization()
    const { selectedChatVehicle } = openOptions
    const { vehiclesByQuery, vehiclesByQueryLoading } = useVehiclesByQuery(selectedChatVehicle, true)
    const [selectedVehicleShortInfo, setSelectedVehicleShortInfo] = useState<VehicleShortInfo | typeof CREATE_NEW>()
    const { vehicleById: selectedVehicle, vehicleByIdLoading: selectedVehicleLoading } = useVehicleById(
        selectedVehicleShortInfo === CREATE_NEW ? undefined : selectedVehicleShortInfo?.id
    ) // This could be loaded parallel to the next step
    const { plateCode: defaultPlateCode } = useCountryCodeToLicensePlate()

    if (vehiclesByQueryLoading || selectedVehicleLoading) {
        return <ModalLoader />
    }
    if (vehiclesByQuery?.length && !selectedVehicleShortInfo) {
        return (
            <>
                <DialogContent>
                    <DialogContentText>{translateText(12988)}.</DialogContentText>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledCell>{translateText(610)}</StyledCell>
                                <StyledCell>{translateText(21)}</StyledCell>
                                <StyledCell>{translateText(101)}</StyledCell>
                                <StyledCell>{translateText(124)}</StyledCell>
                                <StyledCell>{translateText(107)}</StyledCell>
                                <StyledCell>{translateText(102)}</StyledCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {vehiclesByQuery.map((vehicle) => (
                                <TableRow
                                    key={vehicle.id}
                                    sx={{ cursor: "pointer", "&:last-child td, &:last-child th": { border: 0 } }}
                                    onClick={() => {
                                        setSelectedVehicleShortInfo(vehicle)
                                    }}
                                >
                                    <StyledCell>{concat(" ", vehicle.manufacturer, vehicle.modelSeries, vehicle.model)}</StyledCell>
                                    <StyledCell>
                                        {vehicle.plateId && (
                                            <LicensePlateField
                                                readonly
                                                size="s"
                                                shortCountryCode={plateCountryCodeMapper(vehicle.countryCode || defaultPlateCode)}
                                                value={vehicle.plateId}
                                            />
                                        )}
                                    </StyledCell>
                                    <StyledCell>{vehicle.vin}</StyledCell>
                                    <StyledCell>{vehicle.initialRegistration && date(vehicle.initialRegistration, "d")}</StyledCell>
                                    <StyledCell>{concat(" ", vehicle.firstName, vehicle.lastName) || vehicle.companyName}</StyledCell>
                                    <StyledCell>{vehicle.customerNo}</StyledCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setSelectedVehicleShortInfo("new")
                        }}
                    >
                        {translateText(12501)}
                    </Button>
                </DialogActions>
            </>
        )
    }

    return (
        <ConflictStart
            chatId={chatId}
            customer={customer}
            vehicle={selectedVehicle}
            tmCustomerId={tmCustomerId}
            customerInfo={customerInfo}
            openOptions={openOptions}
        />
    )
}
