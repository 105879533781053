import { ShowCostEstimationDetailsResponse } from "@tm/models"

export function mapCostEstimationDetail(data: any): ShowCostEstimationDetailsResponse {
    return {
        ...data,
        startDate: data.startDate ? new Date(data.startDate) : undefined,
        dueDate: data.dueDate ? new Date(data.dueDate) : undefined,
        endDate: data.endDate ? new Date(data.endDate) : undefined,
    }
}
