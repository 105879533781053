import { memo, useCallback } from "react"
import { Button } from "@tm/controls"
import { Table, TableCellData, CellContentPosition } from "@tm/components"
import { useLocalization } from "@tm/localization"

import UserSettingsArea from "./UserSettingsArea"

type VideoItem = {
    description: string
    url: string
}

type Props = {
    videoUrls: VideoItem[]
}

export const WmMediathek = memo<Props>(({ videoUrls }) => {
    const { translateText } = useLocalization()

    const renderButtonsCell = useCallback(
        (url: string) => {
            return (
                <Button className="more-info" linkTo={url} target="_blank" icon="video">
                    {translateText(12841)}
                </Button>
            )
        },
        [translateText]
    )

    const cellData = useCallback(
        (item: VideoItem): TableCellData[] => {
            return [
                { displayValue: item.description, id: "1" },
                {
                    displayValue: renderButtonsCell(item.url),
                    id: "2",
                    alignContent: CellContentPosition.right,
                },
            ]
        },
        [renderButtonsCell]
    )

    const generateRows = useCallback(() => {
        return videoUrls.map((item, index) => ({
            cells: cellData(item),
            id: String(index),
        }))
    }, [videoUrls, cellData])

    return (
        <UserSettingsArea id="user-settings__area__mediathek" title={translateText(13010)}>
            <Table columns={[{}, {}]} rows={generateRows()} />
        </UserSettingsArea>
    )
})
