import { Button, Icon, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleInfoType } from "@tm/models"

type Props = {
    articleInfoType: ArticleInfoType
}

export default function PartType({ articleInfoType }: Props) {
    const { translateText } = useLocalization()

    if (articleInfoType === ArticleInfoType.OeArticle) {
        return (
            <Tooltip title={translateText(803)} variant="light">
                <Button startIcon={<Icon name="oe-reference" />} variant="text" />
            </Tooltip>
        )
    }

    if (articleInfoType === ArticleInfoType.CustomArticle) {
        return (
            <Tooltip title={translateText(757)} variant="light">
                <Button startIcon={<Icon name="individual-article" />} variant="text" />
            </Tooltip>
        )
    }

    return null
}
