import { FC, useEffect, useMemo, useState } from "react"
import { VrcLocalFile, VrcUploadedFile } from "../../component"
import { base64ToObjectUrl, getMimeType } from "../../helper"

type Props = {
    className?: string
    rotation?: number
    file: VrcLocalFile | VrcUploadedFile
}

// TODO: Größe von PDF-Dateien festlegen
const FilePreview: FC<Props> = (props) => {
    const data = props.file.isImage || !("url" in props.file) ? props.file.data : props.file.url
    const defaultImg = !data && "url" in props.file ? props.file.url : undefined

    const [img, setImg] = useState(props.file.isImage ? defaultImg : undefined)

    const iframeSrc = useMemo(() => {
        if (data?.startsWith("http")) {
            return data
        }
        if (data) {
            return base64ToObjectUrl(data)
        }
    }, [data])

    useEffect(() => {
        if (props.file.isImage) {
            const mimeType = data && getMimeType(data)

            let modifiedData = data ?? ("url" in props.file ? props.file.url : null)

            if (!modifiedData) {
                return
            } // default error img?

            const oimg = new Image()
            oimg.crossOrigin = "Anonymous"
            oimg.src = modifiedData
            const { rotation } = props.file
            oimg.onload = () => {
                const c = document.createElement("canvas")
                const { naturalWidth: width, naturalHeight: height } = oimg
                const swapDimensions = rotation === 90 || rotation === 270

                c.width = swapDimensions ? height : width
                c.height = swapDimensions ? width : height

                const context = c.getContext("2d")
                if (context) {
                    const x = c.width / 2
                    const y = c.height / 2

                    const angleInRadians = (props.file.rotation * Math.PI) / 180

                    context.translate(x, y)
                    context.rotate(angleInRadians)
                    context.drawImage(oimg, -width / 2, -height / 2, width, height)

                    modifiedData = c.toDataURL(mimeType)
                    setImg(modifiedData)
                }
            }
        }
    }, [data, props.file, props.file.rotation])

    return (
        <div className={props.className} style={props.file.isImage ? { overflow: "auto" } : undefined}>
            {props.file.isImage ? (
                <img
                    src={img}
                    alt="the scanned vehicle registration document"
                    style={{ maxHeight: "50vh", maxWidth: "100%", display: "block", margin: "auto" }}
                />
            ) : (
                <iframe src={iframeSrc} style={{ height: "100%", width: "80vw" }} />
            )}
        </div>
    )
}

export default FilePreview
