import { CustomerContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"
import { CustomerInfo } from "../../../../../../data"

export function useCustomerById(tmCustomerId: string | undefined) {
    const { data, isLoading } = useQuery(
        ["Toolkits.Notifications_useCustomerById", tmCustomerId],
        () => (Container.getInstance(RegisteredModels.Customer) as CustomerContainer).subscribe(tmCustomerId).load(),
        {
            enabled: !!tmCustomerId,
            cacheTime: 0,
        }
    )

    return { customerById: data, customerByIdLoading: isLoading }
}

export function useCustomersByQuery(customerInfo: CustomerInfo, enabled: boolean) {
    const query = customerInfo.emailAddress
    const { data, isLoading } = useQuery(
        ["Toolkits.Notifications_useCustomerByQuery", query],
        () => (query ? (Container.getInstance(RegisteredModels.Customer) as CustomerContainer).action("findCustomers")({ query }) : undefined),
        {
            enabled: !!query && enabled,
            select: (data) => data?.customers,
            cacheTime: 0,
        }
    )

    return { customersByQuery: data, customersByQueryLoading: isLoading }
}
