import { Component } from "react"

import { elementId, bindSpecialReactMethods } from "@tm/utils"
import { Icon } from "@tm/controls"

export type XorSwitchPositions = "left" | "right"
type XorSwitchProps = {
    status?: XorSwitchPositions
    disabled?: boolean
    onChange?(state: XorSwitchPositions): void
}

type SwitchState = {
    position: XorSwitchPositions
}

export default class XorSwitch extends Component<XorSwitchProps, SwitchState> {
    private elementId = elementId(`switch`)

    constructor(props: XorSwitchProps) {
        super(props)
        this.state = {
            position: props.status || "left",
        }

        bindSpecialReactMethods(this)
    }

    UNSAFE_componentWillReceiveProps(nextProps: XorSwitchProps) {
        if (this.props.status !== nextProps.status) {
            this.setState({ position: nextProps.status || "left" })
        }
    }

    handleChange() {
        this.setState(
            (state) => {
                return {
                    position: state.position === "left" ? "right" : "left",
                }
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.position)
                }
            }
        )
    }

    render() {
        const { disabled } = this.props
        const { position } = this.state

        let className = `switch switch-xor`

        if (disabled) {
            className += ` switch--disabled`
        }

        className += ` switch-xor--${position}`

        return (
            <label htmlFor={this.elementId} className={className}>
                <input
                    type="checkbox"
                    id={this.elementId}
                    value={undefined}
                    className="input"
                    disabled={!!disabled}
                    checked={position === "right"}
                    onChange={this.handleChange}
                />
                <span className="switch__content">
                    <div className="switch__icon">
                        <Icon skin="primary" size="xs" name={position === "right" ? "arrow-right" : "arrow-left"} />
                    </div>
                </span>
            </label>
        )
    }
}
