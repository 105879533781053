import { useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Button, Dialog, Icon, Typography } from "@tm/components"
import { encodeUniqueId, mapVoucherTypeForUrl, renderRoute } from "@tm/utils"
import { CisVoucherType, VoucherType } from "@tm/models"
import { getBundleParams } from "../../../utils"

export type ReturnVoucherType = "cis" | "voucher"
type Props = {
    returnOrderNumber?: string
    returnVoucherId?: string
    voucherType: ReturnVoucherType
    workTaskId: string
    onOpenVoucherUrl(url: string): void
}

export default function VoucherButtonComponent(props: Props) {
    const { translateText } = useLocalization()
    const { voucherType, returnOrderNumber, returnVoucherId, workTaskId } = props
    const [showExternalVoucher, setShowExternalVoucher] = useState(false)
    const { cisVoucherUrl, orderVoucherUrl } = getBundleParams()

    function handleOpenVoucherClick() {
        if (orderVoucherUrl) {
            const openUrlParams = {
                workTaskId: encodeUniqueId(workTaskId),
                type: mapVoucherTypeForUrl(VoucherType.Return),
                id: returnVoucherId,
            }

            const url = renderRoute(orderVoucherUrl, openUrlParams)
            props.onOpenVoucherUrl(url)
        } else if (cisVoucherUrl) {
            const openUrlParams = {
                workTaskId: encodeUniqueId(workTaskId),
                voucherTypeId: CisVoucherType.Returns,
                id: returnOrderNumber,
                subId: "0", // SubId is 0, as requested by STG
            }

            const url = renderRoute(cisVoucherUrl, openUrlParams)
            props.onOpenVoucherUrl(url)
        }
    }

    return (
        <>
            <Button disabled={voucherType === "cis" ? !returnOrderNumber : !returnVoucherId} onClick={handleOpenVoucherClick}>
                {translateText(1163)}
            </Button>
            <Dialog fullWidth maxWidth="md" open={showExternalVoucher} position="top">
                <Box>
                    <Box display="flex" justifyContent="space-between" align-items="center">
                        <Box>
                            <Icon name="voucher" height="1.5em" width="1.5em" />
                            <Typography variant="h4" component="span" pl={2}>
                                {translateText(292)}
                            </Typography>
                        </Box>
                        <Button
                            startIcon={<Icon name="close" height="1.5em" width="1.5em" />}
                            variant="text"
                            onClick={() => setShowExternalVoucher(false)}
                        />
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}
