import { Suspense } from "react"
import { IMicros, PriceType } from "@tm/models"
import { useUser } from "@tm/context-distribution"
import { useErpInfo } from "../../_helpers/useErpInfo"
import PriceRenderer from "./components/price-renderer"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["compilation-prices"]

function ErpInfoCompilationPricesComponent(props: Props) {
    const { loading, erpInfo } = useErpInfo(props.data, "list")

    if (loading || !erpInfo) {
        return null
    }

    return (
        <>
            <PriceRenderer
                key="Purchase"
                erpInfo={erpInfo}
                quantity={props.data.quantity}
                types={[PriceType.Purchase]}
                multiply={false}
                className="no-separator"
            />
            {!props.hideTotalPrice && (
                <PriceRenderer key="PurchaseTotal" erpInfo={erpInfo} quantity={props.data.quantity} types={[PriceType.Purchase]} multiply />
            )}
        </>
    )
}

export default function Wrapper(props: Props) {
    const { userSettings } = useUser()
    const { showPurchasePrice = true } = userSettings ?? {}

    if (!showPurchasePrice) {
        return null
    }

    return (
        <ErpWrapper {...props}>
            <Suspense fallback={null}>
                <ErpInfoCompilationPricesComponent {...props} />
            </Suspense>
        </ErpWrapper>
    )
}
