import { Vehicle } from "@tm/models"

export const saveVehicleToWorkTask = (
    data: Date | string | number | boolean,
    workTaskVehicle: Vehicle | undefined,
    attachToWorkTask?: ((data: { vehicle?: string | Vehicle | undefined }) => Promise<void>) | undefined
) => {
    if (workTaskVehicle) {
        const updatedVehicle: Vehicle = { ...workTaskVehicle }
        switch (typeof data) {
            case "string":
                updatedVehicle.engineCode = data
                break
            case "object":
                updatedVehicle.initialRegistration = data
                break
            case "number":
                updatedVehicle.mileAge = data
                break
            case "boolean":
                updatedVehicle.longlife = data
                break
        }
        attachToWorkTask?.({ vehicle: updatedVehicle })
    }
}

export const saveInputsToWorkTask = (
    initialRegistration: Date,
    engineCode: string,
    mileage: number,
    longLife: boolean,
    workTaskVehicle: Vehicle | undefined,
    attachToWorkTask?: ((data: { vehicle?: string | Vehicle | undefined }) => Promise<void>) | undefined
) => {
    if (workTaskVehicle) {
        const updatedVehicle: Vehicle = {
            ...workTaskVehicle,
            initialRegistration,
            engineCode,
            mileAge: mileage,
            longlife: longLife,
        }
        attachToWorkTask?.({ vehicle: updatedVehicle })
    }
}

export const isInitialRegistrationChanged = (initialRegistrationFromWorkTask: Date, initialRegistrationFromContext: Date) => {
    const d1 = new Date(initialRegistrationFromWorkTask)
    const d2 = new Date(initialRegistrationFromContext)
    let initialRegistrationChanged = false

    if (d1.getTime() == d2.getTime()) {
        initialRegistrationChanged = false
    } else if (d1.getTime() != d2.getTime()) {
        initialRegistrationChanged = true
    }

    return initialRegistrationChanged
}
