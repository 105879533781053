import { useLocalization } from "@tm/localization"
import { Image, Text } from "@tm/controls"
import { Vehicle } from "@tm/models"

type Props = {
    vehicle?: Vehicle
}

export default function ChosenVehicleComponent(props: Props) {
    const { translate } = useLocalization()
    const { vehicle } = props

    return (
        <div className="vehicle">
            <div className="vehicle__title">{translate(453)}</div>
            <div className="vehicle__thumbnail">
                <Image
                    className="vehicle__thumbnail__manufacturer"
                    url={vehicle && vehicle.manufacturerThumbnail ? vehicle.manufacturerThumbnail : ""}
                />
                <Image className="vehicle__thumbnail__model" url={vehicle && vehicle.modelThumbnail ? vehicle.modelThumbnail : ""} />
            </div>
            <div className="headline--l">{vehicle ? vehicle.manufacturer : ""}</div>
            <div className="vehicle__model">
                {vehicle ? vehicle.modelSeries : ""}, {vehicle ? vehicle.model : ""}
            </div>
            <div className="headline--xs">{vehicle ? vehicle.vin : ""}</div>
            <Text>{translate(101)}</Text>
        </div>
    )
}
