import { Article, ArticlePrice, ErpInformation, ErpPrice } from "@tm/models"
import { getShortCountryCodeByUserContext } from "@tm/utils"
import { CustomArticlePrice, Part } from "../models"

export function mapIAMPartToArticle(part: Part, erpArticle?: ErpInformation): Article {
    return {
        ...part.article!,
        prices:
            erpArticle?.prices?.map((x) =>
                mapErpPriceToArticlePrice(
                    x,
                    part.article!.prices?.find((y) => x.type === y.priceType)
                )
            ) || part.article!.prices,
    }
}

function mapErpPriceToArticlePrice(erpPrice: ErpPrice, articlePrice?: CustomArticlePrice): ArticlePrice {
    return {
        countryCode: getShortCountryCodeByUserContext(),
        currencyCode: erpPrice.currencyCode_Iso_4217,
        deliveryStatus: articlePrice?.deliveryStatus || 0,
        deliveryStatusId: articlePrice?.deliveryStatusId || 0,
        description: erpPrice.description,
        discount: articlePrice?.discount || 0,
        discountGroupId: articlePrice?.discountGroupId || 0,
        isTraderPrice: articlePrice?.isTraderPrice || 0,
        priceType: erpPrice.type,
        priceUnit: erpPrice.priceUnit?.toString() || articlePrice?.priceUnit || "",
        priceUnitId: articlePrice?.priceUnitId || 0,
        quantityUnit: articlePrice?.quantityUnit || "",
        quantityUnitId: articlePrice?.quantityUnitId || 0,
        scaleQuantity: articlePrice?.scaleQuantity || 0,
        validFrom: articlePrice?.validFrom || new Date(),
        value: erpPrice.value || articlePrice?.value || 0,
        currencySymbol: erpPrice.currencySymbol || articlePrice?.currencySymbol,
        traderDescription: articlePrice?.traderDescription,
        traderPriceType: articlePrice?.traderPriceType,
        validTo: articlePrice?.validTo,
    }
}
