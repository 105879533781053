import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function eurotaxFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const eurotaxModule = getModuleFromUserContext(userContext, UserModuleType.TMEurotax)

    if (!eurotaxModule) {
        return undefined
    }

    return {
        gti: {
            items: {
                eurotax: {
                    sort: 1,
                    name: "Eurotax",
                    route: "/:workTaskId/eurotax/summary",
                    active: true,
                    disabledFunction: "GPI-EUROTAX-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        gpi: "eurotax",
                    },
                },
            },
        },
    }
}
