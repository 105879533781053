import { Article } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { AddItemsRtToRdBasketState } from "./model"


export type ComponentActionType =
    | { type: "ADD_IAM_ARTICLES_TO_RD_BASKET_LOADED", payload: { itemID: string } }

const DEFAULT_STATE: AddItemsRtToRdBasketState = {
    states: {}
}

export function reduce(state = { ...DEFAULT_STATE }, action: ComponentActionType): AddItemsRtToRdBasketState {
    switch (action.type) {
        case "ADD_IAM_ARTICLES_TO_RD_BASKET_LOADED": {
            const { itemID } = action.payload

            console.log("IAM added OOOK")
            return {
                ...state,
                states: {
                    ...state.states,
                    [itemID]: { loadedSuccesfully: true }
                }
            }
        }
    }

    return state
}

function addIAMArticlesToRDBasket(items: Article[]): AsyncAction<ComponentActionType, AddItemsRtToRdBasketState> {
    return (dispatch) => {
        const itemID = items[0].id

        dispatch({ type: "ADD_IAM_ARTICLES_TO_RD_BASKET_LOADED", payload: { itemID } })
    }
}

export interface IActions {
    addIAMArticlesToRDBasket(items: Article[]): void
}

export const Actions: IActions = {
    addIAMArticlesToRDBasket
}
