import { Skeleton, StatePill, StatePillProps, Tooltip } from "@tm/components"
import { OrderStatusMessage, StatusMessageType } from "@tm/models"

type Props = {
    isLoading?: boolean
    status?: OrderStatusMessage
    compact?: boolean
}

const mapState = (status?: StatusMessageType): StatePillProps["state"] | undefined => {
    switch (status) {
        case StatusMessageType.Information:
            return "open"
        case StatusMessageType.OrderAccepted:
            return "accept"
        case StatusMessageType.OrderDeclined:
        case StatusMessageType.Error:
            return "rejected"
        default:
            return undefined
    }
}

export default function StatusFlag(props: Props) {
    if (props.isLoading) {
        return <Skeleton variant="rectangular" width={props.compact ? "26px" : "130px"} height="26px" />
    }

    if (props.status) {
        const state = mapState(props.status.type)

        if (state) {
            if (props.compact) {
                return (
                    <Tooltip title={<>{props.status.text}</>}>
                        <div>
                            <StatePill state={state} />
                        </div>
                    </Tooltip>
                )
            }

            return <StatePill state={state} text={props.status.text} />
        }
    }

    return null
}
