import { encodeUniqueId, mapVoucherTypeForUrl, renderRoute, uniqueId } from "@tm/utils"
import { VoucherType } from "@tm/models"
import { useMemo } from "react"
import { useHistory, useParams } from "react-router"
import { getBundleParams } from "../utils"
import { VoucherRouteParams } from "../business"

export function useOpenDetails(voucherWorkTaskId?: string) {
    const params = useParams<VoucherRouteParams>()

    const history = useHistory()

    const workTaskId = useMemo(() => {
        if (params.workTaskId) {
            return params.workTaskId
        }
        if (voucherWorkTaskId) {
            return encodeUniqueId(voucherWorkTaskId)
        }

        return encodeUniqueId(uniqueId())
    }, [params.workTaskId, voucherWorkTaskId])

    function getDetailsUrl(voucherId: string, voucherType: VoucherType) {
        const detailsUrl = renderRoute(getBundleParams().vouchersDetailsRoute, {
            ...params,
            workTaskId,
            type: mapVoucherTypeForUrl(voucherType),
            id: voucherId,
        })

        return detailsUrl
    }
    function getListUrl(voucherType: VoucherType) {
        const listUrl = renderRoute(getBundleParams().vouchersListRoute, {
            ...params,
            workTaskId,
            type: mapVoucherTypeForUrl(voucherType),
        })

        return listUrl
    }

    function openDetails(voucherId: string, voucherType: VoucherType) {
        let targetUrl = getDetailsUrl(voucherId, voucherType)

        targetUrl = encodeURI(targetUrl)

        if (encodeURI(`${location.pathname}`) !== targetUrl) {
            history.push(targetUrl)
        }
    }

    function openList(voucherType: VoucherType) {
        let targetUrl = getListUrl(voucherType)

        targetUrl = encodeURI(targetUrl)

        if (encodeURI(`${location.pathname}`) !== targetUrl) {
            history.push(targetUrl)
        }
    }

    return { openDetails, openList }
}
