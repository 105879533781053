import { getComponentFromBundle } from "@tm/utils"
import { useMemo } from "react"
import { WidgetNavigationDropdown } from "../../../../widget-navigation-dropdown"
import WidgetNavigationCoverOnlyBundle from "../../../../widget-cover-only"
import { dashboardTemplateSixChannel } from "../channel"
import { AllWidgetKeys, Overrides } from "../models"
import { WidgetModule } from "../../../helpers/models"
import { Empty } from "../../../components/Empty"

type Props = WidgetModule & {
    overrides?: Overrides
}

export function WidgetNavigationVariant(props: Props) {
    const { component, id, moduleKey, icon, title, overrides } = props

    const WidgetNavigationCoverOnly = useMemo(
        () => (component === "widget-cover-only" ? getComponentFromBundle(WidgetNavigationCoverOnlyBundle, dashboardTemplateSixChannel) : Empty),
        [component]
    )

    const checkOrder = (key: AllWidgetKeys): number | undefined => {
        return props.overrides?.[key]?.sort
    }

    function getVal<T>(widgetKey: AllWidgetKeys, configKey: string, defaultVal?: T | undefined): T | undefined {
        return overrides?.[widgetKey]?.custom?.[configKey] ?? defaultVal
    }

    switch (component) {
        case "widget-navigation-dropdown":
            if (!Object.keys(props.items)?.length) {
                return null
            }

            const { items, disabledIcon, disabledMsgId, disabledFunction, moduleRoute } = props

            return (
                <WidgetNavigationDropdown
                    key={moduleKey ?? id}
                    id={id}
                    icon={icon}
                    title={title}
                    disabledIcon={disabledIcon}
                    disabledMsgId={disabledMsgId}
                    disabledFunction={disabledFunction}
                    items={items}
                    moduleRoute={moduleRoute}
                    sx={{
                        width: "9em",
                        height: "8.5em",
                        order: checkOrder(moduleKey),
                    }}
                    className={getVal<string>(moduleKey, "className")}
                    highlight={getVal<boolean>(moduleKey, "highlight")}
                />
            )

        case "widget-cover-only":
            return (
                <WidgetNavigationCoverOnly
                    sx={{
                        width: "9em",
                        height: "8.5em",
                        order: checkOrder(moduleKey),
                    }}
                    key={moduleKey ?? id}
                    id={id}
                    target={props.target}
                    title={title}
                    icon={icon}
                    highlight={getVal<boolean>(moduleKey, "highlight")}
                />
            )

        default:
            return null
    }
}
