import { EReplaceButtonBundle, channel } from "@tm/models"
import { useCallback, useEffect } from "react"
import { useHistory } from "react-router"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { replaceArticleCancel, replaceArticleFinish } from "../moduleManager/business"
import { fastCalculatorStore, StoreInstanceEnum } from "../../state"

export function Manager() {
    const history = useHistory()
    const { languageId } = useLocalization()
    const { userSettings } = useUser() ?? {}

    const handleReplaceCallback = useCallback(
        ({ part: replacePart, bundle, cancelAction }) => {
            if (bundle !== EReplaceButtonBundle.FastCalculatorNext) {
                return false
            }

            let instance = StoreInstanceEnum.normal
            const { replaceArticle } = fastCalculatorStore(instance).getState()
            if (!replaceArticle || replaceArticle?.inModal) {
                // If we have a replace article and is in a modal then we need to switch instance
                // If we don't have a replace article in the normal module instance, we should use the modal instance
                instance = StoreInstanceEnum.modal
            }

            if (cancelAction) {
                replaceArticleCancel({ instance, history, languageId, userSettings })
                return
            }

            replaceArticleFinish({ instance, replacePart, history })
        },
        [history]
    )

    useEffect(() => {
        const unsub = channel("GLOBAL").clearAndSubscribe("PARTS/REPLACE_PART", handleReplaceCallback)

        return unsub
    }, [handleReplaceCallback])

    return null
}
