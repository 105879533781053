import { useEffect, useState } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { Toolbar } from "@tm/controls"
import { style } from "typestyle"
import { channel, WorkTaskStatus } from "@tm/models"
import { getLocalization } from "@tm/localization"
import { RouteComponentProps, withRouter } from "@tm/utils"
import { useHistory } from "react-router"
import { getComponentStyles } from "./styles"
import { completeWorkTask, Worktask, partialOrderWorkTask, abortWorkTask } from "../../data/repositories"
import { getStatusText } from "../../helpers"
import { useShowWorktask } from "../../data/hooks/useShowWorktask"

export default function StatusInfoComponent() {
    const className = style(getComponentStyles())
    const [telesalesWorktask, setTelesalesWorktask] = useState<Worktask>()
    const { translateText } = getLocalization()
    const { showWorktask } = useShowWorktask()
    const { workTaskId, injectVehicleHistory } = useWorkTask() || {}
    const history = useHistory()

    useEffect(() => {
        const unsubs: Array<() => void> = []
        if (workTaskId) {
            let cancelled = false

            showWorktask(workTaskId).then((workTask) => {
                if (workTask?.vehicleHistory && injectVehicleHistory) {
                    injectVehicleHistory(workTask.vehicleHistory)
                }
                if (!cancelled) {
                    setTelesalesWorktask(workTask)
                }
            })

            const workTaskChannel = channel("WORKTASK", workTaskId)

            workTaskChannel.subscribeOnce("BASKET/ARTICLE_ADDED", () => {
                if (!cancelled) {
                    showWorktask(workTaskId).then((workTask) => {
                        if (workTask?.vehicleHistory && injectVehicleHistory) {
                            injectVehicleHistory(workTask.vehicleHistory)
                        }
                        if (!cancelled) {
                            setTelesalesWorktask(workTask)
                            if (workTask?.statusValue === WorkTaskStatus.Released) {
                                channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1774), icon: "info", closeDelay: 3000 })
                            }
                        }
                    })
                }
            })

            const worktaskClosedCallback = (worktaskId: string) => {
                showWorktask(worktaskId).then((workTask) => {
                    if (workTask?.vehicleHistory && injectVehicleHistory) {
                        injectVehicleHistory(workTask.vehicleHistory)
                    }
                    if (!cancelled) {
                        setTelesalesWorktask(workTask)
                        if (workTask?.statusValue === WorkTaskStatus.Completed) {
                            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1776), icon: "info", closeDelay: 3000 })
                            workTaskChannel.publish("WORKTASK/CLOSE", undefined)
                        }
                    }
                })
            }

            const worktaskPartiallyOrderedCallback = (worktaskId: string) => {
                showWorktask(worktaskId).then((workTask) => {
                    if (workTask?.vehicleHistory && injectVehicleHistory) {
                        injectVehicleHistory(workTask.vehicleHistory)
                    }
                    if (!cancelled) {
                        setTelesalesWorktask(workTask)
                        if (workTask?.statusValue === WorkTaskStatus.OnHold) {
                            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(13246), icon: "info", closeDelay: 3000 })
                        }
                    }
                })
            }

            const worktaskAbortedCallback = (worktaskId: string) => {
                showWorktask(worktaskId).then((workTask) => {
                    if (workTask?.vehicleHistory && injectVehicleHistory) {
                        injectVehicleHistory(workTask.vehicleHistory)
                    }
                    if (!cancelled) {
                        setTelesalesWorktask(workTask)
                        if (workTask?.statusValue === WorkTaskStatus.Canceled) {
                            channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(13245), icon: "info", closeDelay: 3000 })
                        }
                    }
                })
            }

            workTaskChannel.subscribeOnce("BASKET/EXTERNAL_ORDER_SENT", (message) => {
                if (!cancelled) {
                    if (message.isPartialOrder) {
                        partialOrderWorkTask(workTaskId).then(() => {
                            worktaskPartiallyOrderedCallback(workTaskId)
                        })
                    } else {
                        completeWorkTask(workTaskId).then(() => {
                            worktaskClosedCallback(workTaskId)
                        })
                    }
                }
            })

            unsubs.push(
                workTaskChannel.subscribe("BASKET/ORDER_SENT", (message) => {
                    if (!cancelled && message.result.allHaveSucceeded) {
                        if (message.isPartialOrder) {
                            partialOrderWorkTask(workTaskId).then(() => {
                                worktaskPartiallyOrderedCallback(workTaskId)
                            })
                        } else {
                            completeWorkTask(workTaskId).then(() => {
                                worktaskClosedCallback(workTaskId)
                            })
                        }
                    }
                })
            )

            workTaskChannel.subscribeOnce("WORKTASK/ABORT", () => {
                if (!cancelled) {
                    abortWorkTask(workTaskId).then(() => {
                        worktaskAbortedCallback(workTaskId)
                    })
                }
            })

            return () => {
                cancelled = true
                unsubs.forEach((unsub) => unsub())
            }
        }
    }, [workTaskId, showWorktask])

    useEffect(() => {
        if (workTaskId) {
            const workTaskChannel = channel("WORKTASK", workTaskId)
            const unsubs: Array<() => void> = []

            unsubs.push(
                workTaskChannel.subscribe("VEHICLE/LOADED", () => {
                    showWorktask(workTaskId).then((workTask) => {
                        if (workTask?.vehicleHistory && injectVehicleHistory) {
                            injectVehicleHistory(workTask.vehicleHistory)
                        }
                    })
                })
            )

            unsubs.push(
                workTaskChannel.subscribe("VEHICLE/UPDATED", () => {
                    showWorktask(workTaskId).then((workTask) => {
                        if (workTask?.vehicleHistory && injectVehicleHistory) {
                            injectVehicleHistory(workTask.vehicleHistory)
                        }
                    })
                })
            )

            unsubs.push(
                workTaskChannel.subscribe("BASKET/ARTICLE_ADDED", () => {
                    showWorktask(workTaskId).then(setTelesalesWorktask)
                })
            )

            unsubs.push(
                workTaskChannel.subscribe("BASKET/ORDER_SENT", () => {
                    showWorktask(workTaskId).then(setTelesalesWorktask)
                })
            )

            return () => unsubs.forEach((unsub) => unsub())
        }
    }, [workTaskId, showWorktask])

    return (
        <Toolbar className={className} title={translateText(212)}>
            {telesalesWorktask ? getStatusText(telesalesWorktask.statusValue) : "-"}
        </Toolbar>
    )
}
