import { em, percent } from "csx"
import { useEffect } from "react"
import { batch, useDispatch, useSelector } from "react-redux"
import { classes, useStyle } from "@tm/context-distribution"
import { channel } from "@tm/models"
import { WheelSelectionSteps } from "../../data/enums"
import ApplyButton from "../_shared/applyBtn"
import { MainState } from "../main"
import { MainActions } from "../main/business"
import { Actions } from "./business"
import TiresListComponent from "./components/tiresArticleList"
import { TiresFilters } from "./components/tiresFilters"

type Props = {
	repairTimesRoute: string
	detailsModalRoute?: string
}

export default function TyresList({ repairTimesRoute, detailsModalRoute }: Props) {
	const dispatch = useDispatch()
	const { selectedItem, initialized } = useSelector((s: MainState) => ({ selectedItem: s.tyresList.selectedItem, initialized: s.tyresList.initialized }))
	const selectedTireSize = useSelector((s: MainState) => s.tyresList.selectedFilters.tyreSize?.value)

	useEffect(() => {
		selectedTireSize && !initialized && dispatch(Actions.loadTiresList())
	}, [selectedTireSize])

	useEffect(() => {
		const unsubscribeErpModifiers = channel("GLOBAL").subscribe("ERP/ERP_INFORMATION_LOADED", erpInfo => {
			dispatch(Actions.setErpInformations(erpInfo))
		})

		return () => {
			unsubscribeErpModifiers?.()
		}
	}, [])

	const handleApplyButton = () => {
		batch(() => {
			dispatch(Actions.sendTireToOverview())
			dispatch(MainActions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, true))
			selectedItem && dispatch(MainActions.saveTyresListTab(selectedItem))
		})
	}

	const handleSkipTab = () => {
		dispatch(MainActions.changeStep({ step: WheelSelectionSteps.OVERVIEW }, false))
	}

	const renderList = () => {
		return <TiresListComponent
			className={style.list}
			repairTimesRoute={repairTimesRoute}
			detailsModalRoute={detailsModalRoute}
		/>
	}

	const renderFilters = () => {
		return <TiresFilters className={style.filters} />
	}

	return (
		<div className={classes(style.wrapper, "tyres-list")}>
			<ApplyButton //render with Portal
				showApplyBtn
				disabledApplyBtn={!selectedItem}
				onApply={handleApplyButton}
				showSkipBtn
				onSkip={handleSkipTab}
			/>
			{renderFilters()}
			{renderList()}
		</div>
	)
}

const style = useStyle({
	wrapper: {
		width: percent(100),
		display: "flex"
	},
	filters: {
		flexBasis: em(22),
	},
	list: {
		flex: 1
	}
})(TyresList)
