import { NavigationSteps, TreeOptions } from "../../../models"
import { useEdsStore } from "../store"

export function setWorktaskId(worktaskId: string) {
    useEdsStore.setState((state) => ({
        main: {
            ...state.main,
            worktaskId,
        },
    }))
}

export function setSearchOption(option: TreeOptions) {
    useEdsStore.setState((state) => ({
        main: {
            ...state.main,
            treeOption: option,
        },
    }))
}

export function toggleSearchOption() {
    useEdsStore.setState((state) => {
        const { treeOption } = state.main

        return {
            main: {
                ...state.main,
                treeOption: treeOption === TreeOptions.Category ? TreeOptions.Oe : TreeOptions.Category,
            },
        }
    })
}

export function initializeFromUrl(vin: string, lockVehicle?: boolean) {
    useEdsStore.setState((state) => ({
        main: {
            ...state.main,
            initializedFromUrl: true,
        },
        vehicle: {
            isLoading: false,
            error: undefined,
            isLocked: lockVehicle,
            data: { vin },
        },
    }))
}

export function changeStep(newStep: NavigationSteps) {
    useEdsStore.setState((state) => ({
        main: {
            ...state.main,
            view: newStep,
        },
    }))
}
