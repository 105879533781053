export function getMimeTypeFromExtension(extension: string) {
    switch (extension) {
        case "jpg":
        case "jpeg":
            return "image/jpeg"
        case "png":
            return "image/png"
        case "pdf":
            return "application/pdf"

        default:
            return "image/jpeg"
    }
}

export function extractFilenameAndExtension(url: string): { filename: string; mimeType: string } | null {
    try {
        const parsedUrl = new URL(url)
        const { pathname } = parsedUrl
        const parts = pathname.split("/").filter((part) => part.length > 0)
        const lastPart = parts[parts.length - 1]
        const [filename, extension] = lastPart.split(".")

        if (filename && extension) {
            return { filename, mimeType: getMimeTypeFromExtension(extension) }
        }

        return null
    } catch (error) {
        console.error("Invalid URL:", error)
        return null
    }
}
