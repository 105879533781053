import { Article, FittingPosition, AttributeModel, ArticleAttributes, VehicleRecordArticleAddedToBasketEventRequest } from "@tm/models"

export function mapVehicleRecordArticleAddedToBasketEventRequest(vehicleId: string, article: Article): VehicleRecordArticleAddedToBasketEventRequest {
    return {
        vehicleId,
        attributes: mergeAttributes(article.attributes, article.fittingSide || 0, article.productGroup.id, false),
        supplierId: article.supplier.id,
        supplierArticleNo: article.supplierArticleNo,
        wholesalerArticleNo: article.traderArticleNo,
    }
}

function mergeAttributes(attributes: Array<ArticleAttributes>, fittingSide: FittingPosition, productGroupId: number, topAttributesOnly: boolean) {
    const merged: Array<AttributeModel> = []

    attributes.forEach((articleAttributes) => {
        const gatheredAttributes = topAttributesOnly
            ? articleAttributes.topAttributes
            : [
                  ...articleAttributes.articleAttributes,
                  ...articleAttributes.partsListAttributes,
                  ...articleAttributes.topAttributes,
                  ...articleAttributes.vehicleAttributes,
              ]

        gatheredAttributes.forEach((articleAttribute) => {
            if (articleAttribute.key && !merged.some((x) => x.id === articleAttribute.id && x.value === articleAttribute.key)) {
                merged.push({ id: articleAttribute.id, value: articleAttribute.key, fittingSide, productGroupId })
            }
        })
    })

    return merged
}
