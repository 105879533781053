import { useUser } from "@tm/context-distribution"
import { PanelSection } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { UserModuleType } from "@tm/models"
import { initSelector } from "@tm/utils"
import { useSelector } from "react-redux"
import { Box, Typography, styled } from "@tm/components"
import { sumItemsPrices, sumWorksPrices } from "../../../data/helpers"
import { erpArticlesSelector, itemsSelector, worksSelector } from "../../main/business"

const globalSelector = initSelector(itemsSelector, worksSelector, erpArticlesSelector, (s) => s.calculation.initialSparePartsPrice)

export default function TotalsComponent() {
    const user = useUser()
    const currencyString = user?.userContext?.modules?.find((x) => x.type === UserModuleType.Catalog)?.currencyCode ?? "EUR"
    const vat = user?.userContext?.modules?.find((x) => x.type === UserModuleType.Catalog)?.vat ?? 0
    const { translateText, currency } = useLocalization()

    const [items, works, erpArticles, initialSparePartsPrice] = useSelector(globalSelector)

    const totalItems = (items && sumItemsPrices(items, erpArticles)) || 0
    const totalWorks = (works && sumWorksPrices(works)) || 0
    const total = totalWorks + totalItems
    const totalWithVat = (vat / 100) * total + total

    const showInitialSparePrice = items && items.filter((x) => !!x.selectedPart).length > 0 && initialSparePartsPrice

    return (
        <StyledBox>
            <PanelSection className="parts" title={`${items?.length || 0} ${translateText(90)}`}>
                <Box className="price">
                    <Typography className="value">{currency(totalItems, currencyString)}</Typography>
                    {showInitialSparePrice && (
                        <Typography className="value initial-price">
                            ({`${translateText(12388)}: ${currency(initialSparePartsPrice!, currencyString)}`})
                        </Typography>
                    )}
                </Box>
            </PanelSection>
            <PanelSection className="repair-times" title={`${works?.length || 0} ${translateText(83)}`}>
                <Box className="price">
                    <Typography className="value">{currency(totalWorks, currencyString)}</Typography>
                </Box>
            </PanelSection>
            <PanelSection className="total" title={translateText(62)}>
                <Box className="price">
                    <Typography className="value colored">{currency(total, currencyString)}</Typography>
                </Box>
            </PanelSection>
            <PanelSection className="total vat" title={translateText(263)}>
                <Box className="price">
                    <Typography className="value colored">{currency(totalWithVat, currencyString)}</Typography>
                </Box>
            </PanelSection>
        </StyledBox>
    )
}

const StyledBox = styled(Box)(({ theme }) => ({
    alignContent: "center",
    height: "100px",
    display: "inline-flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "1.8em",

    ".panel": {
        "&.total": {
            ".panel": {
                "&__content": {
                    ".price": {
                        ".value": {
                            fontSize: "1.5rem",
                            fontWeight: "600",
                        },
                    },
                },
            },
        },

        backgroundColor: "transparent",
        boxShadow: "none",

        ".panel": {
            width: "32%",

            "&__title": {
                fontSize: "1.4rem",
                border: "none",
                fontWeight: "200",
            },

            "&__content": {
                height: "100%",
                borderBottom: ".4em solid #c3c3c3",

                ".price": {
                    height: "100%",
                    display: "flex",
                    alignItems: "flex-end",
                    paddingBottom: "3em",
                    position: "relative",
                    flexWrap: "wrap",

                    ".value": {
                        fontSize: "1.5rem",
                        fontWeight: "600",
                        textIndent: "1rem",

                        "&.colored": {
                            color: theme.colors?.highlight,
                            fontSize: "1.8rem",
                            fontWeight: "600",
                        },

                        "&.initial-price": {
                            flex: "0 0 100%",
                            fontSize: "1rem",
                        },
                    },

                    "&:before": {
                        position: "absolute",
                        left: "0",
                        bottom: "2.3em",
                        height: "0.4em",
                        width: "100%",
                        top: "3em",
                        backgroundColor: "#c3c3c3",
                    },
                },
            },
        },
    },

    "&__items, &__works, &__total": {
        height: "100%",
        display: "flex",
        flex: "0 0 30%",
        margin: "0 2em",
        flexWrap: "wrap",

        ".title, .price": {
            height: "60px",
            flex: "0 0 100%",

            "span.value": {
                backgroundColor: "gray",
            },
        },

        ".price": {
            fontSize: "1.4rem",
            flex: "0 0 100%",
            display: "inline",
            flexWrap: "wrap",

            ".value, .additional-info": {
                display: "flex",
            },
        },
    },

    "&__total": {
        ".price": {
            ".additional-info": {
                fontSize: "0.7rem",
                padding: "0.5em 0",
            },
        },
    },

    ".panel .panel__content": {
        height: "110%",
    },
}))
