import { Switch, Stack, Typography, Box } from "@tm/components"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { memo, useState } from "react"

import { getUIA } from "@tm/utils"
import { ISaveableOption } from "."

type Props = ISaveableOption

export const ItemSortingOptions = memo(({ isSaving }: Props) => {
    const { translateText } = useLocalization()

    const { userSettings, setUserSetting } = useUser() || {}
    const [itemSortingEnabled, setItemSortingEnabled] = useState(userSettings?.itemSorting || false)

    function handleChangeItemSorting(state: boolean) {
        setItemSortingEnabled(state)
        setUserSetting("BASKET_ITEM_SORTING", state)
    }

    return (
        <Stack spacing={0}>
            <Switch
                checked={itemSortingEnabled}
                onChange={(e) => handleChangeItemSorting(e.target.checked)}
                label={translateText(13157)}
                disabled={isSaving}
                {...getUIA("SettingsBasketArticleSorting")}
            />
            <Box pl={6}>
                <Typography color="grey.500" variant="label">
                    {translateText(13158)}
                </Typography>
            </Box>
        </Stack>
    )
})
