import { Article, CarModelDetailsResponse, ECalcOrigin, OrderVoucherSupplierItem, Vehicle } from "@tm/models"
import { FastCalculator } from "@tm/data"
import { queueActions, StoreInstance } from ".."
import { ReplaceArticle, Services, SummaryContext } from "../../data/model"
import { useQueueStore } from "../queue"
import { handleChecboxToggles } from "../../components/moduleManager/business"
import { getSelectedServicesFromWS } from "../../data/helpers"
import { fastCalculatorStore } from "../store"

export function fastCalculatorLoaded(instance: StoreInstance, data: FastCalculator.MappedFastCalculatorData) {
    const {
        selectedCalcState,
        fastCalculatorData,
        selectedDataCalcState,
        selectedDialogCalcState,
        selectedOverlayCalcState,
        alternativeCalcArticles,
    } = data

    const state = fastCalculatorStore(instance).getState()

    const initialRegistration = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.RegDate)?.value as string
    const engineCode = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.EngineCode)?.value as string
    const mileage = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.Mileage)?.value as number | undefined
    const longLife = selectedCalcState?.context?.inputs?.find((x: any) => x.type == FastCalculator.ECalcInputType.Longlife)?.value

    const selectedServicesFromWS = getSelectedServicesFromWS(selectedCalcState?.context as SummaryContext)
    const unsavedDeselections = state.unsavedServices.filter((s) => s.isSelected === true)
    const unsavedSelections = state.unsavedServices.filter((s) => s.isSelected === false)
    const serviceList: FastCalculator.CalcSelectionItem[] = []

    selectedServicesFromWS.forEach((service) => {
        const contains = unsavedDeselections.filter((s) => s.id === service.id)
        if (contains.length === 0) {
            serviceList.push(service)
        }
    })

    queueActions.queueCancelled()

    fastCalculatorStore(instance).setState((state) => ({
        loading: false,
        selectedCalcState,
        selectedDataCalcState,
        selectedDialogCalcState,
        fastCalculatorData,
        selectedOverlayCalcState,
        alternativeCalcArticles,
        error: false,
        engineCode,
        initialRegistration: (initialRegistration && new Date(initialRegistration)) || undefined,
        mileage,
        longLife,
        selectedServices: serviceList.length > 0 ? serviceList.concat(unsavedSelections) : unsavedSelections,
    }))
}

export function setDetailsLoaded(instance: StoreInstance, modelDetails: CarModelDetailsResponse, vehicleId: string) {
    fastCalculatorStore(instance).setState((state) => ({
        carModel: modelDetails,
        vehicleIdUsed: vehicleId,
    }))
}

export function setConfigProps(instance: StoreInstance, costEstimationUrl: string, inModal?: boolean) {
    fastCalculatorStore(instance).setState((state) => ({
        costEstimationUrl,
        inModal,
    }))
}

export function setFastCalculatorLoading(instance: StoreInstance) {
    fastCalculatorStore(instance).setState((state) => ({
        loading: true,
    }))
}

export function setFastCalculatorError(instance: StoreInstance) {
    fastCalculatorStore(instance).setState((state) => ({
        error: true,
        loading: false,
    }))
}

export function setVehicle(instance: StoreInstance, vehicle: Vehicle) {
    fastCalculatorStore(instance).setState((state) => ({
        vehicle,
    }))
}

export function closeSelectionDialog(instance: StoreInstance) {
    fastCalculatorStore(instance).setState((state) => ({
        selectedOverlayCalcState: undefined,
    }))
}

export function resetAdditionalData(instance: StoreInstance) {
    fastCalculatorStore(instance).setState((state) => ({
        additionalData: {
            loading: false,
            articles: [],
            orderHistory: [],
        },
    }))
}

export function onArticlesLoading(instance: StoreInstance) {
    fastCalculatorStore(instance).setState((state) => ({
        additionalData: {
            ...state.additionalData,
            loading: true,
        },
    }))
}

export function onArticlesEmptyResponse(instance: StoreInstance) {
    fastCalculatorStore(instance).setState((state) => ({
        additionalData: {
            ...state.additionalData,
            loading: false,
        },
    }))
}

export function onArticlesLoaded(instance: StoreInstance, articles: Array<Article>) {
    fastCalculatorStore(instance).setState((state) => ({
        additionalData: {
            ...state.additionalData,
            loading: false,
            articles: [...state.additionalData.articles, ...articles],
        },
    }))
}

export function orderHistoryLoaded(instance: StoreInstance, orderHistory: Array<OrderVoucherSupplierItem>, appendData: boolean) {
    fastCalculatorStore(instance).setState((state) => ({
        additionalData: {
            ...state.additionalData,
            orderHistory: !appendData ? orderHistory : state.additionalData.orderHistory.concat(orderHistory),
        },
    }))
}

export function addSelectedService(instance: StoreInstance, item: FastCalculator.CalcSelectionItem, existsInWS?: boolean) {
    const state = fastCalculatorStore(instance).getState()
    const validService: Services = {
        selectedServices: [...state.selectedServices],
        unsavedServices: [...state.unsavedServices],
    }
    const newService = item

    const calcType = state.selectedCalcState?.type
    if (calcType === FastCalculator.ECalcState.FastCockpitNext || calcType === FastCalculator.ECalcState.CalculationNext) {
        handleChecboxToggles(validService, newService, existsInWS)
    }

    fastCalculatorStore(instance).setState((state) => ({
        unsavedServices: validService.unsavedServices,
        selectedServices: validService.selectedServices,
    }))
}

export function resetSelectedServices(instance: StoreInstance) {
    useQueueStore.getState().queueActions.queueCancelled()

    fastCalculatorStore(instance).setState((state) => ({
        selectedServices: [],
        unsavedServices: [],
    }))
}

export function setCalcOrigin(instance: StoreInstance, calcOrigin: ECalcOrigin) {
    fastCalculatorStore(instance).setState((state) => ({
        calcOrigin,
    }))
}

export function setSelectedCalcSelection(instance: StoreInstance, selectedCalcSelection: FastCalculator.CalcSelection) {
    fastCalculatorStore(instance).setState((state) => ({
        selectedCalcSelection,
    }))
}

export function startArticleReplace(instance: StoreInstance, replaceArticle: ReplaceArticle) {
    fastCalculatorStore(instance).setState((state) => ({
        replaceArticle,
    }))
}

export function resetArticleReplace(instance: StoreInstance) {
    fastCalculatorStore(instance).setState((state) => ({
        replaceArticle: undefined,
    }))
}

export function setSkipReload(instance: StoreInstance, skipReload: boolean) {
    fastCalculatorStore(instance).setState((state) => ({
        skipReload,
    }))
}
