import { useLocalization } from "@tm/localization"
import { Image, Icon } from "@tm/controls"

type Props = {
    providerLogos?: Array<string>
}

export default function ProvidersComponent(props: Props) {
    const { translate } = useLocalization()
    const { providerLogos } = props

    if (!providerLogos?.length) {
        return null
    }

    return (
        <div className="providers col__right">
            <div className="header-wrapper">
                <Icon name="partner" />
                <div className="headline">{translate(839)}</div>
            </div>
            <div className="providers__logo-wrapper">
                {providerLogos
                    .filter((x) => !!x)
                    .map((logoPath: string) => (
                        <Image key={logoPath} className="provider-logo" url={logoPath} />
                    ))}
            </div>
        </div>
    )
}
