import { useQuery } from "react-query"
import { getVinSuggestions } from "../repositories/getVinSuggestions"

const getVinSuggestionsKey = "getVinSuggestions"
export function useGetVins(query: string, modelId?: number) {
    const { data } = useQuery([getVinSuggestionsKey, query, modelId], () => getVinSuggestions(query, modelId!), {
        enabled: !!modelId,
        staleTime: Infinity,
        cacheTime: Infinity,
    })

    return data
}
