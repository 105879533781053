import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce } from "./business"

const WidgetConceptPage: BundleComponent = {
    name: "widget-concept-page",
    component,
    reduce,
}

export default WidgetConceptPage
