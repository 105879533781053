import { ETyresSeason, VehicleType } from "@tm/models"
import { getSelectedCriteria } from "."
import { TyresListState } from "../model"
import { GetArticleListByMatchCodeRequest } from "../../../../data/repositories/wheels-loadTiresArticles/model"
import { getBundleParams } from "../../../../utils"
import { VehicleSelectionState } from "../../../vehicle-selection/business/model"
import { mapVehicleTypeToTyresCarType } from "../../../../data/helpers"

export function createArticleListRequest(tiresListState: TyresListState, pageIndex: number = 1, fromFilter?: boolean, vehicleSelectionState?: VehicleSelectionState, vehicleType?: VehicleType): GetArticleListByMatchCodeRequest | undefined {
    const { selectedFilters } = tiresListState
    const selectedCriteria = getSelectedCriteria(tiresListState)

    let searchValue = selectedFilters.tyreSize?.value.replace(/ /g, "") || ""

    if (searchValue) {
        if (vehicleSelectionState?.selectedCarPark?.loadIndex){
            searchValue += " " + vehicleSelectionState.selectedCarPark.loadIndex
        }
        
        if (vehicleSelectionState?.selectedCarPark?.speedIndex){
            searchValue += vehicleSelectionState.selectedCarPark.speedIndex
        }
    }
    const lastCharFromQuery = searchValue.charAt(searchValue.length - 1)
    const query = (
        tiresListState.selectedFilters?.season?.value == ETyresSeason.winter &&
        lastCharFromQuery.match(/[a-z]/i)
    ) ? searchValue.substring(0, searchValue.length - 1) : searchValue

    if (!selectedFilters.tyreSize) {
        return
    }

    const { tyresPageSize, traderReferenceId } = getBundleParams()

    const carType = mapVehicleTypeToTyresCarType(vehicleType)

    return {
        pageIndex,
        pageSize: tyresPageSize,
        query,
        searchLevel: 0,
        selectedCriteria,
        fromFilter,
        traderReferenceId,
        carTypes: [carType],
    }
}