import { Article } from "@tm/models"
import { useQuery } from "react-query"
import { useMemo, useState } from "react"
import { Repositories } from "../../../data"

const ARTICLE_IMAGES_QUERY = "ARTICLE_IMAGES_QUERY"

export function useArticleImages(article: Article) {
    const [enabled, setEnabled] = useState(false)

    const { data, isLoading } = useQuery({
        queryKey: [ARTICLE_IMAGES_QUERY, article.internalId],
        queryFn: () => Repositories.getArticleImages(article.internalId, article.vehicleLinkageId),
        enabled: !!article && enabled,
    })

    return useMemo(
        () => ({
            data,
            isLoading,
            setEnabled,
        }),
        [data, isLoading, setEnabled]
    )
}
