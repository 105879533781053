import { em } from "csx"
import { Suspense, useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Grid, Icon, Loader, Text, Toolbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { useMicro } from "@tm/morpheus"
import { useErp } from "../../../data/hooks/useErp"
import { IArticle } from "../../../data/models"

type Props = { item: IArticle }

function ArticleItem({ item }: Props) {
    const { erp, loading } = useErp(item)
    const { renderMicro } = useMicro<IMicros>()
    const { currency, translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    return (
        <Grid container className={style.root}>
            <Grid m={6} s={12} container>
                <Grid xs={1} className={style.thumbnail}>
                    <Icon size="xl" name="supplier" />
                </Grid>

                <Grid xs={2} className={style.cell}>
                    {/* <Toolbar modifiers={["border-left"]}> */}
                    <Text className={style.trader} modifiers={["strong", "highlight"]}>
                        {item.traderArticleNumber}
                    </Text>
                    {/* </Toolbar> */}
                </Grid>
                <Grid xs={6} className={style.cell}>
                    {/* <Toolbar modifiers={["border-left"]}> */}
                    <Text modifiers="sub">{item.description}</Text>
                    {/* </Toolbar> */}
                </Grid>
                <Grid xs={3} className={style.cell}>
                    <Toolbar title={translateText(89)} modifiers={["border-left"]}>
                        <Text className={style.trader} modifiers="strong">
                            {item.quantity}
                        </Text>
                    </Toolbar>
                </Grid>
            </Grid>

            <Grid m={6} s={12} container>
                {!loading && erp && (
                    <>
                        <Grid m={6} l={3} xs={3} className={style.cell}>
                            {erp.prices?.[0] && (
                                <Toolbar title={erp.prices?.[0].description} modifiers={["border-left"]}>
                                    <Text className={style.trader} modifiers="strong">
                                        {currency(erp.prices?.[0]?.value!, erp.prices?.[0].currencySymbol!)}
                                    </Text>
                                </Toolbar>
                            )}
                        </Grid>

                        <Grid m={6} l={3} xs={3} className={style.cell}>
                            {erp.prices?.[1] && (
                                <Toolbar title={erp.prices?.[1].description} modifiers={["border-left"]}>
                                    <Text className={style.trader} modifiers="strong">
                                        {currency(erp.prices?.[1]?.value!, erp.prices?.[0].currencySymbol!)}
                                    </Text>
                                </Toolbar>
                            )}
                        </Grid>
                        <Grid m={6} l={3} xs={3} className={style.cell}>
                            {erp.prices?.[0] && erp.prices?.[1] && (
                                <Toolbar title={translateText(1197)} modifiers={["border-left"]}>
                                    <Text className={style.trader} modifiers="strong">
                                        {currency((erp.prices?.[1]?.value ?? 0) * item.quantity!, erp.prices?.[0].currencySymbol!)}
                                    </Text>
                                </Toolbar>
                            )}
                        </Grid>
                        <Grid m={6} l={3} xs={3} className={style.cell}>
                            <Toolbar modifiers={["border-left"]}>
                                {!loading &&
                                    erp &&
                                    renderMicro("erp", "display-only-availability", {
                                        availability: erp?.availability!,
                                    })}
                            </Toolbar>
                        </Grid>
                    </>
                )}

                {loading && <Loader />}
            </Grid>
        </Grid>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <ArticleItem {...props} />
        </Suspense>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        root: {
            padding: theme.margin.m,
            display: "flex",
            alignItems: "center",
            flex: 1,
            boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
            $nest: {
                ".toolbar__title": {
                    paddingBottom: 0,
                },
            },
        },
        cell: {
            position: "relative",
            alignItems: "center",
            display: "flex",
            paddingBottom: theme.margin.s,
        },
        thumbnail: {
            width: em(3.5),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: em(3.5),
            boxShadow: "0 1px 5px rgb(0 0 0 / 20%)",
        },
        trader: {
            marginLeft: theme.margin.l,
        },
    })(ArticleItem)
}
