import { useLocalization } from "@tm/localization"
import { Text } from "@tm/controls"
import { AttributeFilterModel } from "@tm/models"

export type AttributeFilterOption = {
    filter?: AttributeFilterModel
    filterText?: string
}

function AttributeFilterOptionView(props: AttributeFilterOption) {
    const { translateText } = useLocalization()
    const { text, unit } = props.filter || { text: undefined, unit: undefined }

    return props.filterText ? (
        <>{props.filterText}</>
    ) : (
        <Text className="product-groups__filter__option" modifiers="block">
            {text ? text + (unit ? ` ${unit}` : "") : translateText(1039)}
        </Text>
    )
}

export default AttributeFilterOptionView
