import { Box, Divider, MuiCustomModal, styled, Switch } from "@tm/components"
import { Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useEffect, useState } from "react"
import { VehicleAndCustomer } from "."
import { useFastServiceStore } from "../../data"
import { DisplayOptionKeys, PrintOptions } from "../../data/models"
import { getTextFromPrintOption } from "../../helpers"

type Props = {
    open: boolean
    onOutsideClick: () => void
}

export default function CustomDisplayOptionsModal({ open, onOutsideClick }: Props) {
    const { translateText } = useLocalization()
    const printOptionsFromState = useFastServiceStore((state) => state.printOptions)
    const [printOptions, setPrintOptions] = useState<PrintOptions>(printOptionsFromState)

    useEffect(() => {
        setPrintOptions(printOptionsFromState)
    }, [printOptionsFromState])

    const handleCheck = (key: keyof PrintOptions) => {
        setPrintOptions((prevOptions) => ({
            ...prevOptions,
            [key]: !prevOptions[key],
        }))
    }

    const handleClickOutside = () => {
        onOutsideClick()
        setPrintOptions(printOptionsFromState)
    }

    const handleSaveState = () => {
        useFastServiceStore.getState().setReplacePrintOptions(printOptions)
        useFastServiceStore.getState().setPrintLoaded("", Date.now())
        onOutsideClick()
    }

    return (
        <MuiCustomModal open={open} handleOkButton={handleSaveState} onOutsideClick={handleClickOutside}>
            <VehicleAndCustomer headerText={translateText(844)} showServiceText />
            <Divider sx={{ margin: "1.5em 0" }} light />
            <StyledText size="m">{translateText(13825)}</StyledText>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.InspectionTimes)}
                    checked={printOptions?.inspectionTimes}
                    label={getTextFromPrintOption(DisplayOptionKeys.InspectionTimes, translateText)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.Remarks)}
                    checked={printOptions.remarks}
                    label={getTextFromPrintOption(DisplayOptionKeys.Remarks, translateText)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.Images)}
                    checked={printOptions?.images}
                    label={getTextFromPrintOption(DisplayOptionKeys.Images, translateText)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.Tyres)}
                    checked={printOptions?.tyres}
                    label={getTextFromPrintOption(DisplayOptionKeys.Tyres, translateText)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.NextMaintenance)}
                    checked={printOptions?.nextMaintenance}
                    label={getTextFromPrintOption(DisplayOptionKeys.NextMaintenance, translateText)}
                />
            </StyledCheckRow>
            <StyledCheckRow>
                <Switch
                    onChange={() => handleCheck(DisplayOptionKeys.ShowDefects)}
                    checked={printOptions?.showDefects}
                    label={getTextFromPrintOption(DisplayOptionKeys.ShowDefects, translateText)}
                />
            </StyledCheckRow>
        </MuiCustomModal>
    )
}

const StyledCheckRow = styled(Box)(({ theme }) => ({
    display: "flex",
    marginBottom: theme.margin?.l ?? ".75em",
}))

const StyledText = styled(Text)(({ theme }) => ({
    display: "block",
    paddingBottom: theme.margin?.xl ?? "1em",
}))
