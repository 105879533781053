import { IMicros } from "@tm/models"
import PricesComponent from "../../../_shared/prices"

type Props = IMicros["erp"]["display-only-prices"]

export default function ErpInfoDisplayOnlyPricesComponent({ data, prices, priceNamesInTooltip }: Props) {
    return (
        <div className="tk-erp">
            <div className="erp-info-prices">
                {!!prices.length && (
                    <PricesComponent
                        classPrefix="erp-info-prices"
                        article={data}
                        prices={prices}
                        hasGraduatedPrices={false}
                        isReplacementPart={false}
                        hideGraduatedPricesIcon
                        priceNamesInTooltip={priceNamesInTooltip}
                    />
                )}
            </div>
        </div>
    )
}
