import { useAddCustomWorkList } from "../../data/hooks/workTaskBasket/works/useAddCustomWorkList"
import { useAddRepairTimeList } from "../../data/hooks/workTaskBasket/works/useAddRepairTimeList"
import { useBasketUpdateWorkflow } from "../../data/hooks/workTaskBasket/workflow/useBasketUpdateWorkflow"

async function resolve() {}

export function useBasketWorks() {
    const handleBasketUpdateWorkflow = useBasketUpdateWorkflow(resolve)

    const addCustomWorkList = useAddCustomWorkList(handleBasketUpdateWorkflow)
    const addRepairTimeList = useAddRepairTimeList(handleBasketUpdateWorkflow)
    return { addCustomWorkList, addRepairTimeList }
}
