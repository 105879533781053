import { CisArticleType } from "../../../business/model"
import { VoucherItem } from "../../../data/model"
import ArticleNumber from "../ArticleNumber"

type Props = {
    voucherItem: VoucherItem
    articleType: CisArticleType
}
export default function ArticleNumberCellComponent({ voucherItem, articleType }: Props) {
    return (
        <ArticleNumber
            productGroupId={voucherItem.genericArticleId ? parseInt(voucherItem.genericArticleId) : undefined}
            supplierArticleNumber={voucherItem.supplierArticleNumber}
            supplierId={voucherItem.supplierId ? parseInt(voucherItem.supplierId) : undefined}
            wholesalerArticleNumber={voucherItem.wholesalerArticleNumber}
            articleType={articleType}
        />
    )
}
