import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from "react"
import { useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CisCustomerState, CisFindCustomerDto, CisQueryType, Customer, CustomerContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { encodeUniqueId, uniqueId } from "@tm/utils"
import { useHistory } from "react-router"
import { Box, Loader, Stack, LinkButton, Typography, Icon, Popover, SelectItem, PartnerSearchField, InlineSelect } from "@tm/components"
import { getCollectiveCustomerParam } from "../../../helpers"
import {
    INITIAL_STATE,
    loadViewState,
    reduce,
    resetPartner,
    searchCurrentActivePartner,
    SearchHistoryItem,
    searchPartner,
    setDefaultQueryType,
} from "./business"
import ActiveCustomer from "../active-customer"
import { PartnerList } from "./components/list"
import NoResults from "./components/NoResults"
import { useCreateWorktask } from "../../../data/hooks/useShowWorktask"
import { useLoadQueryTypes } from "../../../data/hooks/useQueries"
import { getBundleParams } from "../../../utils"
import SearchHistory from "./components/SearchHistory"

type Props = {
    searchHistoryInNewColumn?: boolean //  used only by work task history
    hideTitle?: boolean
}

export default function PartnerSearch({ searchHistoryInNewColumn, hideTitle }: Props) {
    const history = useHistory()
    const [state, dispatch] = useReducer(reduce, INITIAL_STATE)
    const { searchHistory, partnerList, partnerLoading, query, defaultQueryTypeId } = state
    const { queryTypes, isLoading: queryTypesLoading } = useLoadQueryTypes()
    const { translateText } = useLocalization()
    const { isLoading, createWorktask } = useCreateWorktask()
    const { userContext } = useUser()
    const { hideCollectivePartnerButton } = getBundleParams()
    const [queryType, setQueryType] = useState<CisQueryType | undefined>()
    const [actualQuery, setActualQuery] = useState(query ?? "")
    const inputRef = useRef<HTMLDivElement>(null)
    const [showPopover, setShowPopover] = useState(false)

    useEffect(() => {
        loadViewState(dispatch)
    }, [])

    const hasCollectiveCustomer = useMemo(() => {
        return !!getCollectiveCustomerParam(userContext)
    }, [userContext])

    useEffect(() => {
        setShowPopover(!!inputRef.current && !!partnerList && !!actualQuery)
    }, [actualQuery, partnerList, inputRef.current])

    useEffect(() => {
        if (queryTypes && queryTypes.length) {
            let queryType = queryTypes[0]

            if (defaultQueryTypeId) {
                queryType = queryTypes.find((x) => x.id === defaultQueryTypeId) || queryType
            }

            setQueryType(queryType)
        }
    }, [queryTypes, defaultQueryTypeId])

    useEffect(() => {
        if (partnerList?.length === 1) {
            if (partnerList[0].state !== CisCustomerState.TSMBlocked) {
                handleSelectPartner(partnerList[0])
            }
        }
    }, [partnerList])

    useEffect(() => {
        if (!actualQuery) {
            resetPartner(dispatch)
        }
    }, [actualQuery])

    useEffect(() => {
        if (query) {
            setActualQuery(query)
        }
    }, [query])

    function handleSearchStart() {
        if (actualQuery && queryType) {
            searchPartner(dispatch, state, actualQuery, queryType.id)
        } else {
            resetPartner(dispatch)
        }
    }

    function handleHistoryClick(item: SearchHistoryItem) {
        setQueryType(queryTypes?.find((x) => x.id == item.queryTypeId))
        searchPartner(dispatch, state, item.query, item.queryTypeId)
    }

    function handleQueryTypeChange(queryType: CisQueryType) {
        setDefaultQueryType(dispatch, state, queryType.id)
        setQueryType(queryType)
        resetPartner(dispatch)
    }

    function handleClosePopover() {
        setActualQuery("")
        resetPartner(dispatch)
        setShowPopover(false)
    }

    const prepareWorktaskToBeCreated = (customer: Customer) => {
        if (isLoading) {
            return
        }

        createWorktask(customer.id).then((worktask) => {
            const url = `/${encodeUniqueId(worktask.workTaskId)}`
            history.push(url)
        })
    }

    function handleSelectPartner(partner: CisFindCustomerDto) {
        const container = Container.getInstance(RegisteredModels.Customer) as CustomerContainer

        container
            .action("findCustomer")({ refCustomerNo: partner.partnerNo })
            .then(
                (customer) => {
                    // save customer in case there's a difference in the next db
                    container
                        .action("saveCustomer")(
                            {
                                ...customer,
                                companyName: partner.company,
                                refCustomerNo: partner.partnerNo,
                                displayCustomerNo: partner.customerNumberToShow,
                                city: partner.city,
                                street: partner.street,
                                zip: partner.zip,
                                firstName: partner.contactFirstName,
                                lastName: partner.contactLastName,
                                phone: partner.phone,
                            },
                            true
                        )
                        .then(prepareWorktaskToBeCreated)
                },
                () => {
                    container
                        .action("saveCustomer")(
                            {
                                id: uniqueId(),
                                companyName: partner.company,
                                refCustomerNo: partner.partnerNo,
                                displayCustomerNo: partner.customerNumberToShow,
                                city: partner.city,
                                street: partner.street,
                                zip: partner.zip,
                                firstName: partner.contactFirstName,
                                lastName: partner.contactLastName,
                                phone: partner.phone,
                            },
                            true
                        )
                        .then(prepareWorktaskToBeCreated)
                }
            )
    }

    const handleActiveCustomerClick = useCallback(() => searchCurrentActivePartner(dispatch), [state])
    return (
        <Box paddingTop={2}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-start" pb={1}>
                {!hideTitle && <Typography variant="h2">{translateText(1766)}</Typography>}
            </Stack>
            <Box display={searchHistoryInNewColumn ? "grid" : "column"} gridTemplateColumns="1fr 1fr" gap={4}>
                <Stack gap={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                        <PartnerSearchField
                            onChange={setActualQuery}
                            autoComplete="off"
                            isLoading={partnerLoading}
                            ref={inputRef}
                            onStartSearch={handleSearchStart}
                            value={actualQuery}
                        >
                            <>
                                {queryTypesLoading && <Loader size="extrasmall" />}
                                {queryType && (
                                    <InlineSelect
                                        color="primary"
                                        variant="filled"
                                        onChange={(e) => handleQueryTypeChange(e.target.value as CisQueryType)}
                                        renderValue={(val) => (val as CisQueryType).description}
                                        value={queryType}
                                    >
                                        {queryTypes?.map((item, index) => (
                                            <SelectItem key={item.description} value={item}>
                                                <Typography variant="body2">{item.description}</Typography>
                                            </SelectItem>
                                        )) || []}
                                    </InlineSelect>
                                )}
                            </>
                        </PartnerSearchField>
                        <Popover
                            open={showPopover}
                            anchorEl={inputRef.current}
                            anchorPosition={{ left: 0, top: 0 }}
                            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                            PaperProps={{ sx: { maxHeight: "30vh" } }}
                            onClose={handleClosePopover}
                        >
                            {partnerList?.length ? (
                                <PartnerList items={partnerList} onSelectItem={handleSelectPartner} />
                            ) : (
                                <NoResults label={state.searchType === "CURRENT_CALLER" ? translateText(12822) : translateText(12446)} />
                            )}
                        </Popover>
                        <ActiveCustomer onClick={handleActiveCustomerClick} label={translateText(12811)} isLoading={state.partnerLoading} />
                    </Stack>
                    {!searchHistoryInNewColumn && <SearchHistory searchHistoryItems={searchHistory} onHandleHistoryClick={handleHistoryClick} />}
                    {hasCollectiveCustomer && !hideCollectivePartnerButton && (
                        <Box>
                            <LinkButton startIcon={<Icon name="plus" />} to={`/${encodeUniqueId(uniqueId())}`}>
                                {translateText(1764)}
                            </LinkButton>
                        </Box>
                    )}
                </Stack>
                <Box>
                    {searchHistoryInNewColumn && <SearchHistory searchHistoryItems={searchHistory} onHandleHistoryClick={handleHistoryClick} />}
                </Box>
            </Box>
        </Box>
    )
}
