import { RequestArticleListPayload } from "@tm/models"
import { Repositories } from "../../../data"

export async function loadPartsList(request: RequestArticleListPayload) {
    const { partsList } = request

    if (partsList) {
        return Repositories.getPartsList(partsList)
    }

    return { articles: [] }
}
