
import { ajax } from "@tm/utils"
import { mapStatics2CarsJsonToPreyssCars } from "./mapper"
import { PreyssCar } from "../../model"
import { getBundleParams } from "../../../utils"

export function load3DAvailableCars() {
	const url = `${getBundleParams().imagesPath}cars.json`
	return new Promise<PreyssCar[]>((resolve, reject) =>
		ajax({ url }).then(
			response => resolve(mapStatics2CarsJsonToPreyssCars(response)),
			reject
		)
	)
}