import { getFormattedLocalDate } from "@tm/controls"
import { PdfOrderDetailOptions } from "@tm/models"
import { openStreamPdf } from "@tm/utils"
import * as Data from "../../../data/index"
import { PrintReturnItemsRequest } from "../../../data/model"

export function printReturnItemsList(
    printOptions: PdfOrderDetailOptions,
    language: string,
    translateText: (id: number) => string,
    handleLoading: (loading: boolean) => void,
    customerNo?: string
) {
    handleLoading(true)
    const date: Date = new Date()

    const request: PrintReturnItemsRequest = {
        printOptions,
        customerNo,
        currentDate: getFormattedLocalDate(language, date) ?? "",
    }

    Data.printReturnItems(request)
        .then((response) => {
            if (response) {
                openStreamPdf(response, translateText)
            }
        })
        .finally(() => {
            handleLoading(false)
        })
}
