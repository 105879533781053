import { ajax, getStoredAuthorization } from "@tm/utils"
import { VinSearchRequest, VinSearchResponse } from "../../models"
import { getBundleParams } from "../../utils"

export function vinSearch(vin: string): Promise<VinSearchResponse | undefined> {
    const url = `${getBaseUrl()}/VinSearch`
    const authorization = getStoredAuthorization()
    const body: VinSearchRequest = { vin }
    return ajax<VinSearchResponse>({ url, body, authorization, method: "POST" })
}

function getBaseUrl(): string {
    return getBundleParams().edsServiceUrl
}
