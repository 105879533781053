import { useState, useEffect } from "react"
import { ModelState, validateField } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { TextField, Text, Radio } from "@tm/controls"
import { useUser, useCisCustomer } from "@tm/context-distribution"
import { Box, Divider, Grid, Typography, Button, Loader, Icon } from "@tm/components"
import { RequestMissingArticlesRequest, RequestMissingArticlesResponse, requestMissingArticles, TypeOfContact } from "../../data"
import { useArticleDetailsSharedErpInfos } from "../../data/hooks/useArticleDetailsSharedErpInfos"
import { getContactPersonName } from "./helpers/getContactPersonName"

type Props = {
    warehouseNumberRequired?: boolean
    showResponseMessageDescription?: boolean
}

type State = {
    formData: FormFields

    sending: boolean
    response?: RequestMissingArticlesResponse
    errorText?: string
    serviceModelState?: ModelState
}

type FormFields = Omit<
    RequestMissingArticlesRequest,
    "warehouseNumber" | "items" | "customerNumberToShow" | "wareHouseEMailAddress" | "companyName" | "companyNameAddition1" | "companyNameAddition2"
>

export default function MissingArticlesRequestComponent(props: Props) {
    const { translateText } = useLocalization()
    const { userSettings } = useUser() || {}
    const { request: erpRequest } = useArticleDetailsSharedErpInfos()
    const { cisCustomer } = useCisCustomer()

    const contactInfo = userSettings?.repairShop?.contactInfo
    const contactPersonName = getContactPersonName(userSettings)

    const [state, setState] = useState<State>({
        formData: {
            contactPersonName,
            contactPersonPhoneNumber: contactInfo?.phone || "",
            contactPersonEMailAddress: contactInfo?.email || "",
            typeOfContact: TypeOfContact.Phone,
        },
        sending: false,
    })

    useEffect(() => {
        if (contactPersonName) {
            setState((prev) => ({ ...prev, formData: { ...prev.formData, contactPersonName } }))
        }
    }, [contactPersonName])

    useEffect(() => {
        if (contactInfo) {
            setState((prev) => ({
                ...prev,
                formData: {
                    ...prev.formData,
                    contactPersonPhoneNumber: contactInfo.phone || prev.formData.contactPersonPhoneNumber,
                    contactPersonEMailAddress: contactInfo.email || prev.formData.contactPersonEMailAddress,
                },
            }))
        }
    }, [contactInfo])

    const send = () => {
        if (!erpRequest) {
            return
        }

        const { salesOutlets, customerNumberToShow, companyName, companyNameAddition1, companyNameAddition2 } = cisCustomer || {}
        const warehouseNumber = salesOutlets?.[0]?.id
        const wareHouseEMailAddress = salesOutlets?.find((outlet) => outlet.isDefault)?.electronicAddresses?.[0]?.value

        const request: RequestMissingArticlesRequest = {
            ...state.formData,
            warehouseNumber,
            items: [erpRequest],
            customerNumberToShow,
            wareHouseEMailAddress,
            companyName,
            companyNameAddition1,
            companyNameAddition2,
        }

        setState((prev) => ({ ...prev, sending: true, errorText: undefined, serviceModelState: undefined }))

        requestMissingArticles(request).then(
            (response) => {
                setState((prev) => ({ ...prev, response, sending: false }))
            },
            (error) => {
                if (error) {
                    if (typeof error === "string") {
                        setState((prev) => ({ ...prev, errorText: error, sending: false }))
                    } else {
                        setState((prev) => ({ ...prev, serviceModelState: error.modelState, sending: false }))
                    }
                }
            }
        )
    }

    const handleChange = (model: FormFields) => {
        setState((prev) => ({ ...prev, formData: model, serviceModelState: undefined }))
    }

    const handleTypeChange = (typeOfContact: TypeOfContact) => {
        setState((prev) => ({ ...prev, formData: { ...prev.formData, typeOfContact }, serviceModelState: undefined }))
    }

    const validate = (formData: FormFields): ModelState => {
        const modelstate: ModelState = {
            contactPersonPhoneNumber: validateField(formData.contactPersonPhoneNumber).min(5, translateText(289)).messages,
            contactPersonEMailAddress: validateField(formData.contactPersonEMailAddress).email(translateText(290)).min(1, translateText(289))
                .messages,
        }
        return modelstate
    }

    const renderCisSalesOutlet = () => {
        if (!cisCustomer?.salesOutlets?.length) {
            return null
        }

        const outlet = cisCustomer?.salesOutlets[0]

        let email
        if (outlet?.electronicAddresses?.length) {
            email = outlet.electronicAddresses[0].value
        }

        return (
            <Box>
                <Typography variant="h3" color="common.black">
                    {translateText(1710)} {outlet?.name}
                </Typography>
                <Divider variant="fullWidth" />

                <Typography variant="body2" component="p" color="common.black" pt={1}>
                    {outlet?.address.street}
                </Typography>
                <Typography variant="body2" component="p" color="common.black">
                    {outlet?.address.zip} {outlet?.address.city}
                </Typography>

                <Typography variant="body2" component="p" color="common.black" pt={1}>
                    {translateText(110)}: {outlet?.address.phone}
                </Typography>

                <Typography variant="body2" component="p" fontWeight="bold" color="common.black" py={1}>
                    {email}
                </Typography>
                <Divider variant="fullWidth" />
            </Box>
        )
    }

    const renderRepairShopInfos = () => {
        const repairShop = userSettings?.repairShop
        const customerNo = cisCustomer?.customerNumberToShow || cisCustomer?.customerNo

        if (!repairShop) {
            return null
        }

        return (
            <Box py={2}>
                <Typography fontWeight="bold" variant="body2">
                    {customerNo ? `${customerNo} ` : ""}
                </Typography>
                <Typography fontWeight="bold" variant="body2">
                    {repairShop.companyName || ""}
                </Typography>
                <br />
                {repairShop.postalAddress && (
                    <Typography component="p" variant="body3" color="primary">
                        {repairShop.postalAddress.street || ""} {repairShop.postalAddress.zip || ""} {repairShop.postalAddress.city || ""}
                    </Typography>
                )}
            </Box>
        )
    }

    const Response = () => {
        if (!state.response) {
            return null
        }

        if (state.response.messageDescription && props.showResponseMessageDescription) {
            return (
                <div className="missing-articles-request__response">
                    <Typography variant="h3">{translateText(1715)}</Typography>
                    <Typography>{state.response.messageDescription}</Typography>
                </div>
            )
        }

        const salesOutlet = cisCustomer?.salesOutlets?.[0]?.name || ""
        const requestReciver = translateText(1716).replace("{0}", state.response.emailAddress || salesOutlet)

        return (
            <div className="missing-articles-request__response">
                <Box display="flex" alignItems="center" py="8px">
                    <Icon name="check-filled" color="success" height="25px" width="25px" sx={{ paddingRight: "8px" }} />
                    <Typography variant="h3" component="div">
                        {translateText(1715)}
                    </Typography>
                </Box>

                <Typography>{requestReciver}</Typography>
                <Typography>{translateText(1717)}</Typography>
            </div>
        )
    }

    const modelState = state.serviceModelState || validate(state.formData)

    const warehouseNumber = cisCustomer?.salesOutlets?.[0]?.id

    let valid = !!erpRequest && (!props.warehouseNumberRequired || !!warehouseNumber)
    Object.keys(modelState).forEach((key) => {
        const value = modelState[key]
        if (value && value.length) {
            valid = false
        }
    })

    if (!cisCustomer?.salesOutlets?.[0]?.address) {
        return <Loader />
    }

    return (
        <Box className="tk-erp missing-articles-request" pt={2}>
            <Box className="missing-articles-request__section">{renderCisSalesOutlet()}</Box>
            {state.response ? (
                <Response />
            ) : (
                <Box>
                    <Box className="missing-articles-request__section">
                        <Typography variant="h4" pt={1}>
                            {translateText(1712)}
                        </Typography>

                        {renderRepairShopInfos()}

                        <Grid container spacing={2} direction="column" maxWidth="50%">
                            <Grid item xs={4}>
                                <TextField
                                    model={state.formData}
                                    path={["contactPersonPhoneNumber"]}
                                    onChange={handleChange}
                                    modelState={modelState}
                                    label={`${translateText(110)} *`}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    model={state.formData}
                                    path={["contactPersonEMailAddress"]}
                                    onChange={handleChange}
                                    modelState={modelState}
                                    label={`${translateText(109)} *`}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    model={state.formData}
                                    path={["contactPersonName"]}
                                    onChange={handleChange}
                                    modelState={modelState}
                                    label={translateText(950)}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    model={state.formData}
                                    path={["additionalInformation"]}
                                    onChange={handleChange}
                                    modelState={modelState}
                                    label={translateText(39)}
                                    multiline
                                />
                            </Grid>
                            <Grid item display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                                <Box display="flex" flexDirection="column" justifyContent="space-between" height="50px">
                                    <Radio
                                        size="s"
                                        label={translateText(1713)}
                                        onCheck={handleTypeChange}
                                        value={TypeOfContact.Phone}
                                        checked={state.formData.typeOfContact === TypeOfContact.Phone}
                                    />
                                    <Radio
                                        size="s"
                                        label={translateText(1714)}
                                        onCheck={handleTypeChange}
                                        value={TypeOfContact.Email}
                                        checked={state.formData.typeOfContact === TypeOfContact.Email}
                                    />
                                </Box>
                                <Box>
                                    <Button
                                        onClick={send}
                                        color="primary"
                                        startIcon={<Icon name="paperplane" />}
                                        disabled={!valid || state.sending || !!state.response}
                                    >
                                        {translateText(1711)}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            )}

            <Text modifiers="danger">{state.errorText}</Text>
        </Box>
    )
}
