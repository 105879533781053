import { useMemo } from "react"
import { CisVoucherType } from "@tm/models"
import { Box, Loader } from "@tm/components"
import PvOrderItemsTable from "./PvOrderItemsTable"
import PvOrderDetailsHeader from "./PvOrderDetailsHeader"
import { AllOrderListItem } from "../../../../data/model"
import { useOrderDetails } from "../../../../data/hooks/useOrderDetails"
import { NoResultHint } from "../../../_shared/NoResultHint"
import { ErrorMessage } from "../../../_shared/ErrorMessage"
import { ColumnStack } from "../../../_shared/StyledComponents"

type Props = {
    orders: Array<AllOrderListItem>
    selectedVoucherId: string
    voucherTypeId: CisVoucherType
}

export default function PvOrderDetailsComponent({ selectedVoucherId, voucherTypeId, orders }: Props) {
    const selectedVoucher = useMemo(() => {
        return orders.find((order) => order.orderId === selectedVoucherId)
    }, [selectedVoucherId, orders])

    const { orderDetails, orderDetailsLoading, orderDetailsError } = useOrderDetails(
        selectedVoucher && {
            voucherTypeId,
            orderNumber: selectedVoucher.orderId,
        }
    )

    let content = <></>
    if (selectedVoucher) {
        if (orderDetailsLoading) {
            content = <Loader />
        } else if (orderDetailsError) {
            content = <ErrorMessage />
        } else if (orderDetails?.voucherItems?.length) {
            content = (
                <ColumnStack>
                    <PvOrderDetailsHeader key="VoucherHead" orderDetails={orderDetails} />
                    <PvOrderItemsTable key="VoucherItemsTable" currencyCode={orderDetails.currencyCode} voucherItems={orderDetails.voucherItems} />
                </ColumnStack>
            )
        } else {
            content = <NoResultHint />
        }
    }

    return <Box flex={1}>{content}</Box>
}
