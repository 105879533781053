import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetCheckHasMemo(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    if (!data.getCheckHasMemo || !source) {
        return
    }

    this.props.getCheckHasMemo?.().then((checkHasMemo) => {
        source.postMessage({ checkHasMemo }, "*")
    })
}
