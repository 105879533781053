import { useState, useEffect, useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Button, Table } from "@tm/controls"
import { notUndefinedOrNull } from "@tm/utils"
import {
    TelematicsDataResponse,
    TelematicSet,
    SetType,
    TelematicDateTimeSet,
    TelematicNumberSet,
    TelematicStringSet,
    LabelMap,
} from "../../../../data"

type Props = {
    data?: TelematicsDataResponse
}

export default function VehicleStatusComponent(props: Props) {
    const { translate, date, language } = useLocalization()
    const [activeList, setActiveList] = useState(0)
    const { data } = props

    useEffect(() => {
        setActiveList(0)
    }, [data])

    const { lists, labels } = useMemo(() => {
        return {
            lists: [
                data?.vehicleStatus,
                data?.chassisStatus,
                data?.environmentData,
                data?.electricCarSpecificStatus,
                data?.additionalServiceData,
            ].filter(notUndefinedOrNull),
            labels: [
                data?.vehicleStatusLabelMap,
                data?.chassisStatusLabelMap,
                data?.environmentDataLabelMap,
                data?.electricCarSpecificStatusLabelMap,
                data?.additionalServiceDataLabelMap,
            ].filter(notUndefinedOrNull),
        }
    }, [data])

    if (!lists.length || !labels.length) {
        return null
    }

    const renderValueCell = (item: TelematicSet) => {
        let value
        switch (item.type) {
            case SetType.DateTime: {
                const dateTimeSet = item as TelematicDateTimeSet
                value = date(dateTimeSet.value, "d")
                break
            }
            case SetType.Number: {
                const numberSet = item as TelematicNumberSet
                value = `${numberSet.value.format(numberSet.decimalPlaces)} ${numberSet.unit || ""}`
                break
            }
            default: {
                const stringSet = item as TelematicStringSet
                value = `${stringSet.value} ${stringSet.unit || ""}`
            }
        }
        return <Table.Cell>{value}</Table.Cell>
    }

    const renderTypeCell = (item: TelematicSet) => {
        return <Table.Cell>{item.labelTextMap[language.toLowerCase()] || ""}</Table.Cell>
    }

    const renderNavigationButtons = (label: LabelMap, index: number) => {
        return (
            <Button key={`${label}-${index}`} onClick={() => setActiveList(index)} skin={index === activeList ? "primary" : undefined}>
                {label[language.toLowerCase()]}
            </Button>
        )
    }

    const columns = [
        <Table.Column key="key" className="vehicle-status__key" renderItemContent={renderTypeCell}>
            {translate(578)}
        </Table.Column>,
        <Table.Column key="value" className="vehicle-status__value" renderItemContent={renderValueCell}>
            {translate(579)}
        </Table.Column>,
    ]

    return (
        <div className="vehicle-status">
            <div className="title headline--l">{translate(577)}</div>
            <div className="vehicle-status__nav">{labels.map(renderNavigationButtons)}</div>
            <Table data={lists[activeList] || []} columns={columns} />
        </div>
    )
}
