import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetCarInfoResponse } from ".."
import { getBundleParams } from "../../../utils"
import { mapCarInfoResponse } from "./mapper"

export * from "./model"

export const getVehicleModels = (tecDocNr?: number): Promise<GetCarInfoResponse> => {
    const url = `${getServiceUrl()}/GetCarInfo`
    const authorization = getStoredAuthorization()
    const body = { tecDocNr }

    return new Promise<GetCarInfoResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => resolve(mapCarInfoResponse(response)), reject)
    )
}

const getServiceUrl = () => {
    const bundlesParams = getBundleParams()
    return bundlesParams.vehicleServiceUrl
}
