import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { GetArticleAlternativeRequest } from "./model"
import { mapAlternativeArticles } from "./mapper"
import { FSArticle } from "../fastcalculator-calculation/mapper"

export function getArticleAlternatives(data: GetArticleAlternativeRequest): Promise<FSArticle[]> {
    const url = `${getServiceUrl()}/fastservice/GetArticleAlternatives`
    const authorization = getStoredAuthorization()
    const body = data

    return ajax({ url, body, authorization, method: "POST" }).then((res) => {
        return mapAlternativeArticles(res)
    })
}

function getServiceUrl() {
    const bundleParams = getBundleParams()
    return bundleParams.fastCalculatorServiceUrl
}
