import { PartsRoutes } from "@tm/models"
import { useMemo } from "react"
import { useUniSearchStatus } from "../../../helper"
import { getBundleParams } from "../../../utils"

export function usePartsRoutes(): Partial<PartsRoutes> {
    const uniSearchIsActive = useUniSearchStatus()
    const { partsRoutes } = getBundleParams()

    return useMemo(() => {
        if (partsRoutes) {
            return partsRoutes
        }
        return {
            vehicleParts: {
                list: "/:workTaskId/parts/vehicles/list",
                details: "/:workTaskId/parts/vehicles/details",
            },
            directSearch: {
                list: "/:workTaskId/parts/direct/list",
                details: "/:workTaskId/parts/direct/details",
            },
            ...(uniSearchIsActive && {
                universalParts: {
                    list: "/:workTaskId/parts/universal/list",
                    details: "/:workTaskId/parts/universal/details",
                },
            }),
        }
    }, [partsRoutes, uniSearchIsActive])
}
