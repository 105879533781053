import { Text } from "@tm/controls"
import { Box, styled } from "@tm/components"
import { FastServiceSelection } from "../../data/models"

type Props = {
    items: FastServiceSelection[]
}

const MultiAppendix: React.FunctionComponent<Props> = ({ items }) => {
    return (
        <MultiAppendixWrapper>
            {items.map((elem, idx) => (
                <>
                    <Text key={idx} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }} size="xl">
                        {`${elem.label}: `}
                    </Text>
                    <StyledLeftText>
                        {elem.items
                            .filter((x) => x.isSelected)
                            .map((text, index) => (
                                <Text modifiers={["strong"]} key={index} size="xl">
                                    {text.label}
                                </Text>
                            ))}
                    </StyledLeftText>
                </>
            ))}
        </MultiAppendixWrapper>
    )
}

export default MultiAppendix

const MultiAppendixWrapper = styled(Box)({
    display: "grid",
    gridTemplateColumns: "20% 1fr",
    rowGap: "0.5em",
    columnGap: "0.5em",
    width: "100%",
})

const StyledLeftText = styled(Box)({
    display: "flex",
    flexDirection: "column",
    marginRight: "0.5em",
    flex: 1,
    marginBottom: "0.25em",
})
