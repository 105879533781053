import { Article, DMS } from "@tm/models"

export function mapArticleToLookupItem(part: Article): DMS.LookupItem {
    return {
        id: part.id,
        referenceId: "",

        wholesalerArticleId: part.traderArticleNo,
        dataSupplierArticleId: part.supplierArticleNo,

        description: "",

        dataSupplier: {
            id: part.supplier.id,
            clientId: DMS.ClientType.TecDoc,
            description: part.supplier.name,
        },
        productGroup: {
            id: part.productGroup.id,
            clientId: DMS.ClientType.TecDoc,
            description: part.productGroup.name,
        },

        quantity: part.quantity,
    }
}
