import {
    Article,
    Vehicle,
    ArticleAttribute,
    FittingPosition,
    CompareWithVehicleRecordsRequest,
    AttributeModel,
    ShowVehicleRecordsRequest,
    ShowVehicleRecordsViewMode,
    AttributeFilterModel,
    ArticleAttributes,
    VehicleRecordArticleAddedToBasketEventRequest,
    VehicleRecordSingleEventRequest,
} from "@tm/models"

export function mapCompareWithVehicleRecordsRequest(vehicleId: string, articles: Array<Article>) {
    const request: CompareWithVehicleRecordsRequest = {
        vehicleId,
        attributes: [],
    }

    articles.forEach((part) => {
        const merged = mergeAttributes(part.attributes, part.fittingSide || FittingPosition.None, part.productGroup.id, true)

        merged.forEach((attribute) => {
            if (
                !request.attributes.some(
                    (x) =>
                        x.id === attribute.id &&
                        x.value === attribute.value &&
                        x.fittingSide === attribute.fittingSide &&
                        x.productGroupId === attribute.productGroupId
                )
            ) {
                request.attributes.push(attribute)
            }
        })
    })

    return request
}

export function mapVehicleRecordArticleAddedToBasketEventRequest(vehicle: Vehicle, article: Article): VehicleRecordArticleAddedToBasketEventRequest {
    return {
        vehicleId: vehicle.id,
        attributes: mergeAttributes(article.attributes, article.fittingSide || FittingPosition.None, article.productGroup.id, false),
        supplierId: article.supplier.id,
        supplierArticleNo: article.supplierArticleNo,
        wholesalerArticleNo: article.traderArticleNo,
    }
}

function mergeAttributes(attributes: Array<ArticleAttributes>, fittingSide: FittingPosition, productGroupId: number, topAttributesOnly: boolean) {
    const merged: Array<AttributeModel> = []

    attributes.forEach((articleAttributes) => {
        const gatheredAttributes = topAttributesOnly
            ? articleAttributes.topAttributes
            : [
                  ...articleAttributes.articleAttributes,
                  ...articleAttributes.partsListAttributes,
                  ...articleAttributes.topAttributes,
                  ...articleAttributes.vehicleAttributes,
              ]

        gatheredAttributes.forEach((articleAttribute) => {
            if (articleAttribute.key && !merged.some((x) => x.id === articleAttribute.id && x.value === articleAttribute.key)) {
                merged.push({ id: articleAttribute.id, value: articleAttribute.key, fittingSide, productGroupId })
            }
        })
    })

    return merged
}

export function mapShowVehicleRecordsFilter(
    vehicle: Vehicle,
    productGroupIds: string | Array<number>,
    fittingSide?: FittingPosition
): ShowVehicleRecordsRequest {
    const mappedProductGroupIds = Array.isArray(productGroupIds) ? productGroupIds.join(",") : productGroupIds
    return {
        fittingSide,
        productGroupIds: mappedProductGroupIds,
        vehicleId: vehicle.id,
        viewMode: ShowVehicleRecordsViewMode.ShowQuickFilter,
    }
}

export function mapFilterToVehicleRecordSingleEventRequest(vehicle: Vehicle, attribute: AttributeFilterModel): VehicleRecordSingleEventRequest {
    return {
        vehicleId: vehicle.id,
        attribute: {
            id: attribute.id,
            value: attribute.value,
            fittingSide: attribute.fittingSide,
            productGroupId: attribute.productGroupId,
        },
    }
}

export function mapAttributeToVehicleRecordSingleEventRequest(
    vehicle: Vehicle,
    attribute: ArticleAttribute,
    productGroupId: number,
    fittingPosition?: number
): VehicleRecordSingleEventRequest {
    return {
        vehicleId: vehicle.id,
        attribute: {
            id: attribute.id,
            value: attribute.key,
            fittingSide: fittingPosition || FittingPosition.None,
            productGroupId,
        },
    }
}
