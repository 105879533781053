import { styled } from "@tm/components"

export const ErpIconWrapper = styled("div")({
    display: "flex",
    flexFlow: "column",
    borderRight: "1px solid grey",
    ".iconWrapper": {
        margin: 0,
    },
    ".tk-erp .erp-info-details__prices & .erp-info-details__staggered": {
        ".btn": {
            padding: 0,
        },
        border: 0,
        marginRight: 0,
    },
    "&:empty": {
        display: "none",
    },
})
