import { ajax, getStoredAuthorization } from "@tm/utils"
import {
    DeleteCustomArticlesRequest,
    GetCustomArticlesCategoriesResponse,
    ShowCustomArticleItemsRequest,
    ShowCustomArticleItemsResponse,
    AddCustomArticleItemsRequest,
    AddCustomArticleItemsResponse,
    EditCustomArticleItemRequest,
    EditCustomArticleItemResponse,
} from "@tm/models"
import { getBundleParams } from "../../../utils"

function getServiceUrl() {
    return getBundleParams().customArticlesServiceUrl
}

export function showCustomArticleItems(body: ShowCustomArticleItemsRequest) {
    const url = `${getServiceUrl()}/ShowCustomArticleItems`
    const authorization = getStoredAuthorization()
    return ajax<ShowCustomArticleItemsResponse>({ url, body, authorization }).then((response) => ({
        data: response?.customArticleItems ?? [],
        pageIndex: body.pageIndex,
        endOfList: (response?.customArticleItems?.length ?? 0) < body.pageSize,
    }))
}

export function addCustomArticleItems(body: AddCustomArticleItemsRequest) {
    const url = `${getServiceUrl()}/AddCustomArticleItems`
    const authorization = getStoredAuthorization()
    return ajax<AddCustomArticleItemsResponse>({ url, body, authorization, method: "POST" })
}

export function editCustomArticleItem(body: EditCustomArticleItemRequest) {
    const url = `${getServiceUrl()}/EditCustomArticleItem`
    const authorization = getStoredAuthorization()
    return ajax<EditCustomArticleItemResponse>({ url, body, authorization, method: "POST" })
}

export function deleteCustomArticles(body: DeleteCustomArticlesRequest) {
    const url = `${getServiceUrl()}/Delete`
    const authorization = getStoredAuthorization()
    return ajax<void>({ url, body, authorization, method: "POST" })
}

export function getCustomArticlesCategories() {
    const url = `${getServiceUrl()}/GetCategories`
    const authorization = getStoredAuthorization()
    return ajax<GetCustomArticlesCategoriesResponse>({ url, authorization }).then((response) => response?.categories ?? [])
}
