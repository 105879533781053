import { CarModel, Vehicle } from "@tm/models"
import { concat } from "@tm/utils"
import { VehicleSimple } from "./models"

export function mapVehicleToVehicleSimple(vehicle: Vehicle | undefined): VehicleSimple | undefined {
    if (!vehicle) {
        return
    }

    return {
        description: concat(" ", vehicle.manufacturer, vehicle.modelSeries, vehicle.model),
        manufacturerThumbnail: vehicle.manufacturerThumbnail,
        modelSeriesThumbnail: vehicle.modelSeriesThumbnail,
        plateId: vehicle.plateId,
        vin: vehicle.vin,
        registrationNo: vehicle.registrationNo,
        initialRegistration: vehicle.initialRegistration,
        engineCode: vehicle.engineCode,
        mileAge: vehicle.mileAge,
        lastGeneralInspection: vehicle.lastGeneralInspection,
        nextGeneralInspection: vehicle.nextGeneralInspection,
        nextServiceDate: vehicle.nextServiceDate,
        longlife: vehicle.longlife,
    }
}

export function mapCarModelToVehicleSimple(model: CarModel | undefined): VehicleSimple | undefined {
    if (!model) {
        return
    }

    return {
        description: model.fullDescription || model.description,
        manufacturerThumbnail: model.manufacturerThumbnail,
        modelSeriesThumbnail: model.modelSeriesThumbnail,
        plateId: model.registrationNoDetails?.plateId,
        vin: model.registrationNoDetails?.vin,
        /** @todo Remove use of typScheinNr and additionalDetails as soon as the service maps this property to the registrationNo property */
        registrationNo:
            model.registrationNoDetails?.registrationNo ||
            model.registrationNoDetails?.typScheinNr ||
            model.registrationNoDetails?.additionalDetails.find((x) => x.type === 23)?.value,
        initialRegistration: model.registrationNoDetails?.initialRegistration,
        engineCode:
            model.registrationNoDetails?.engineCodes?.length === 1
                ? model.registrationNoDetails.engineCodes[0]
                : model.engineCodes?.length === 1
                ? model.engineCodes[0]
                : undefined,
        longlife: model.registrationNoDetails?.longlife,
    }
}
