import { ListItemIcon, ListItemText, MenuItemProps } from "@tm/components"
import { Link } from "react-router-dom"
import { LinkMenuItem } from "./LinkMenuItem"

export function SubLinkMenuItem(props: MenuItemProps<Link>) {
    const { children, ...rest } = props

    return (
        <LinkMenuItem {...rest}>
            <ListItemIcon sx={(theme) => ({ color: theme.palette.primary.main })}>▶</ListItemIcon>
            <ListItemText>{children}</ListItemText>
        </LinkMenuItem>
    )
}
