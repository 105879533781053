import { BundleComponent } from "@tm/morpheus"
import { reduce, receive } from "./business"
import component from "./component"

const navigation: BundleComponent<unknown, typeof component> = {
    name: "navigation",
    reduce,
    component,
    receive,
}

export default navigation
