import { SuggestionTextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { CarModelDetails } from "@tm/models"
import { FastCalculator } from "@tm/data"
import { getCalcStateLabel, getCurrencyFromUserContext } from "../../../data/helpers"

type Props = {
    item: FastCalculator.CalcInput
    onChangeCallBack?: (data: any) => void
    modelDetails?: CarModelDetails
}

export default function CalcInputText({ item, onChangeCallBack, modelDetails }: Props) {
    const userContext = useUser()?.userContext
    const { translateText } = useLocalization()

    const label = getCalcStateLabel(item.type, getCurrencyFromUserContext(userContext), translateText)
    const suggestions = (item.type == FastCalculator.ECalcInputType.EngineCode && modelDetails?.engineCodes) || []

    const handleChange = (value: string) => {
        if (value != item.value) {
            onChangeCallBack?.(value)
        }
    }

    return (
        <SuggestionTextField
            floatingLabel
            label={item.label || label}
            value={item.value}
            disabled={item.isDisabled}
            preventConfirmOnBlur={!!suggestions?.length}
            getSuggestionData={() => suggestions}
            onChangeConfirm={handleChange}
        />
    )
}
