import { useLocalization } from "@tm/localization"
import { Box, Button, Typography, styled } from "@tm/components"
import { mainActions, useDrivemotiveStore } from "../../data/state"
import { NavigationSteps } from "../../data/models"

export function NavigationComponent() {
    const { translateText } = useLocalization()
    const navigationStep = useDrivemotiveStore((state) => state.main.navigationStep)
    const isCalculationTabAvailable = useDrivemotiveStore((state) => state.main.isCalculationTabAvailable)

    const handleMoveToCalculation = () => {
        if (isCalculationTabAvailable) {
            mainActions.changeStep(NavigationSteps.Calculation)
        }
    }

    return (
        <StyledWrapper>
            <Typography variant="h2">{translateText(13689)}</Typography>
            <StyledNavigationTabs>
                <Button
                    onClick={() => mainActions.changeStep(NavigationSteps.Summary)}
                    color={navigationStep === NavigationSteps.Summary ? "primary" : "inherit"}
                    size="medium"
                >
                    {translateText(409)}
                </Button>
                <Button
                    onClick={handleMoveToCalculation}
                    color={navigationStep === NavigationSteps.Calculation ? "primary" : "inherit"}
                    size="medium"
                    sx={{ marginLeft: ".3rem" }}
                    disabled={!isCalculationTabAvailable}
                >
                    {translateText(613)}
                </Button>
            </StyledNavigationTabs>
        </StyledWrapper>
    )
}

const StyledWrapper = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginBottom: "1em",
})

const StyledNavigationTabs = styled(Box)({
    marginLeft: "2em",
})
