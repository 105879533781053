import { TreeNode } from "@tm/models"
import { Repositories } from "../../../data"
import { graphicActions, treeNavigationActions } from "../../../data/state"
import {
    GroupPartsResponse,
    ImageMapAreaV2Dto,
    NavigationDirection,
    NavigationLinksEnum,
    PartSectionsListV2Dto,
    PartsListByCategoryV2Dto,
    PartsSectionsResponseV2,
    PartV2Dto,
    TreeOptions,
    UnitInfoResponseV2,
    UnitShortListV2Dto,
    UnitShortV2Dto,
    UnitsResponse,
    YqNode,
    YqVehicleDetails,
} from "../../../models"

export function getGroupGraphic(token: string, vin: string) {
    Repositories.getGraphic<GroupPartsResponse>(NavigationLinksEnum.GetGroupParts, token, vin)
        .then((res) => {
            if (res?.data?.categories && res.data.categories.length > 0) {
                graphicActions.setGroup(res.data)
            } else {
                graphicActions.setError(true)
            }
        })
        .catch(() => graphicActions.setError(true))
}

export function getUnitsGraphic(token: string, vin: string) {
    Repositories.getGraphic<UnitsResponse>(NavigationLinksEnum.GetUnits, token, vin)
        .then((res) => {
            if (res?.data?.units && res.data.units.length > 0) {
                graphicActions.setUnit(res.data)
            } else {
                graphicActions.setError(true)
            }
        })
        .catch(() => graphicActions.setError(true))
}

export function getUnitInfo(token: string, vin: string, breadCrumbStrings: string[]) {
    Repositories.getTokenResponse<UnitInfoResponseV2>(NavigationLinksEnum.GetUnitInfo, token, vin, breadCrumbStrings)
        .then((res) => {
            if (res?.data) {
                graphicActions.setUnitInfo(res.data)
            } else {
                graphicActions.setError(true)
            }
        })
        .catch(() => graphicActions.setError(true))
}

export function getUnitParts(token: string, vin: string, breadCrumbStrings: string[]) {
    Repositories.getTokenResponse<PartsSectionsResponseV2>(NavigationLinksEnum.GetUnitParts, token, vin, breadCrumbStrings)
        .then((res) => {
            if (res?.data) {
                graphicActions.setUnitParts(res.data)
            } else {
                graphicActions.setError(true)
            }
        })
        .catch(() => graphicActions.setError(true))
}

export function getTreeNavigation(treeOption: TreeOptions, yqVehicle: YqVehicleDetails, vin: string) {
    const searchMode = treeOption === TreeOptions.Oe ? NavigationLinksEnum.GetNavigationTree : NavigationLinksEnum.GetGroups
    const navigationLink = yqVehicle.navigationLinks.filter((nL) => nL.action === searchMode)?.first()
    if (navigationLink) {
        switch (navigationLink.action) {
            case NavigationLinksEnum.GetGroups:
                Repositories.getGroups(navigationLink.token, vin)
                    .then((res) => {
                        if (res?.data) {
                            treeNavigationActions.setTreeNavigation(res?.data)
                        } else {
                            treeNavigationActions.setError(true)
                        }
                    })
                    .catch(() => {
                        treeNavigationActions.setError(true)
                    })
                break
            case NavigationLinksEnum.GetNavigationTree:
                Repositories.getNavigationTree(navigationLink.token, vin)
                    .then((res) => {
                        if (res?.data) {
                            treeNavigationActions.setTreeNavigation(res?.data)
                        } else {
                            treeNavigationActions.setError(true)
                        }
                    })
                    .catch(() => {
                        treeNavigationActions.setError(true)
                    })
                break
            default:
                break
        }
    }
}

export function getSelectedAreaInfo(selectedArea: ImageMapAreaV2Dto | undefined, unitParts: PartSectionsListV2Dto | undefined) {
    let partWithAreaCode: PartV2Dto | undefined
    if (unitParts?.partSections) {
        unitParts.partSections.every((partSelection) => {
            partWithAreaCode = partSelection?.parts?.find((part) => part.areaCode === selectedArea?.areaCode)
            if (partWithAreaCode) {
                return false
            }

            return true
        })
    }

    return partWithAreaCode
}

export function getBreadCrumbTreeNodes(selectedNode: YqNode | undefined) {
    const treeNavigation: TreeNode[] = []

    let parent = selectedNode
    let index = 1
    do {
        if (parent) {
            treeNavigation.push({ id: index, name: parent.name, hasChildNodes: true, extra: parent })
            index++
            parent = parent?.parent
        }
    } while (parent)

    treeNavigation.reverse()
    treeNavigation.shift()
    return treeNavigation
}

export function isUnitNavigationDisabled(
    treeOption: TreeOptions,
    groupGraphic: PartsListByCategoryV2Dto | undefined,
    unitGraphic: UnitShortListV2Dto | undefined
) {
    if (treeOption === TreeOptions.Category && groupGraphic) {
        const count = groupGraphic.categories.reduce((currentCount, row) => currentCount + row.units.length, 0)
        if (count > 1) {
            return false
        }

        return true
    }

    if (treeOption === TreeOptions.Oe && unitGraphic) {
        if (unitGraphic.units.length > 1) {
            return false
        }

        return true
    }
}

function getNextIndex(currentIndex: number, length: number, direction: NavigationDirection) {
    let newIndex = 0
    switch (direction) {
        case NavigationDirection.Right:
            if (direction === NavigationDirection.Right) {
                if (currentIndex + 1 >= length) {
                    newIndex = 0
                } else {
                    newIndex = currentIndex + 1
                }
            }
            break

        case NavigationDirection.Left:
            if (currentIndex - 1 < 0) {
                newIndex = length - 1
            } else {
                newIndex = currentIndex - 1
            }
            break

        default:
            newIndex = currentIndex
            break
    }

    return newIndex
}

export function unitNavigate(
    direction: NavigationDirection,
    treeOption: TreeOptions,
    selectedUnit: UnitShortV2Dto | undefined,
    groupGraphic: PartsListByCategoryV2Dto | undefined,
    unitGraphic: UnitShortListV2Dto | undefined
) {
    if (treeOption === TreeOptions.Category && groupGraphic) {
        const allUnits = groupGraphic.categories.flatMap((category) => category.units)
        const currentIndex = allUnits.findIndex((u) => u.unit.token === selectedUnit?.token)

        if (allUnits && typeof currentIndex === "number") {
            const newIndex = getNextIndex(currentIndex, allUnits.length, direction)
            if (newIndex === currentIndex) {
                return
            }

            graphicActions.setSelectedUnit(allUnits[newIndex].unit)
        }
    }

    if (treeOption === TreeOptions.Oe && unitGraphic) {
        const currentIndex = unitGraphic.units.findIndex((unit) => unit.token === selectedUnit?.token)
        if (typeof currentIndex === "number") {
            const newIndex = getNextIndex(currentIndex, unitGraphic.units.length, direction)
            if (newIndex === currentIndex) {
                return
            }

            graphicActions.setSelectedUnit(unitGraphic.units[newIndex])
        }
    }
}
