import { ShowCarModelDetailsRequest, ShowCarModelDetailsResponse } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"

export function showModelDetails(request: ShowCarModelDetailsRequest): Promise<ShowCarModelDetailsResponse> {
    const url = `${getServiceUrl()}/ShowModelDetails`
    const authorization = getStoredAuthorization()

    return new Promise<ShowCarModelDetailsResponse>((resolve, reject) =>
        ajax({ url, body: request, method: "GET", authorization }).then(resolve, reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.carsServiceUrl
}
