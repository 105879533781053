import { Dialog } from "@tm/controls"
import { Checkbox, FormControlLabel, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { connectComponent } from "@tm/morpheus"
import { Container } from "@tm/nexus"

import { ChangeEvent, ReactNode, useEffect, useMemo, useRef, useState } from "react"
import { State, IActions, Actions } from "./business"
import { News } from "../../data/models"

type Props = {
    state: State
    actions: IActions
    staticNews?: Array<News>
}

function FormConfirmationComponent(props: Props) {
    const { translateText } = useLocalization()
    const [checkboxConfirmStatus, setCheckBoxConfirmStatus] = useState<Array<boolean>>([])
    const isConfirmButtonDisabled = useMemo(() => {
        if (checkboxConfirmStatus.length) {
            return checkboxConfirmStatus.some((x) => !x)
        }
        return false
    }, [checkboxConfirmStatus])

    const dialogRef = useRef<Dialog>(null)
    const { news, fullNewsLength } = props.state

    useEffect(() => {
        const read = sessionStorage.getItem("readConfigNews")
        let { staticNews } = props
        if (read && staticNews) {
            staticNews = staticNews.filter((news) => !read.includes(news.newsId))
        }
        props.actions.getUnreadNews(staticNews || [])
    }, [])

    useEffect(() => {
        if (news.length) {
            dialogRef.current?.show()

            const newCheckboxStatus =
                news[0].shortDescription.match(new RegExp("{checkbox", "g"))?.map(() => {
                    return false
                }) ?? []

            setCheckBoxConfirmStatus(newCheckboxStatus)
        }
    }, [news])

    if (!news.length) {
        return null
    }

    function handleClose(selectedNews: News) {
        if (selectedNews.category.indexOf("mandatory") != -1) {
            Container.getInstance("Portal").callAction("logout")
        }
    }

    function handleConfirm(selectedNews: News) {
        props.actions.setNewsRead(selectedNews)
    }

    function handleCheckboxChange(event: ChangeEvent<HTMLInputElement>, checked: boolean) {
        const id = parseInt(event.target.id)
        const newStatus = checkboxConfirmStatus.map((value, index) => {
            if (index === id) {
                return !value
            }
            return value
        })

        setCheckBoxConfirmStatus(newStatus)
    }

    function renderShortDescription(shortDescription: string) {
        const textRegExp = new RegExp(/^[^{]+/)
        const markupsRegExp = new RegExp(/^\{(\w+):([^}]*)\}/)

        let textArray
        let markupsArray
        const content: Array<ReactNode> = []

        let currentShortDescriptionEntry = shortDescription
        let checkboxKey = 0
        while (currentShortDescriptionEntry.length) {
            textArray = textRegExp.exec(currentShortDescriptionEntry)
            if (textArray && textArray.length) {
                content.push(<Typography>{textArray[0]}</Typography>)
                currentShortDescriptionEntry = currentShortDescriptionEntry.substring(textArray[0].length)
            } else {
                markupsArray = markupsRegExp.exec(currentShortDescriptionEntry)
                if (markupsArray) {
                    if (markupsArray[1] === "checkbox") {
                        content.push(
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={checkboxKey.toString()}
                                        checked={checkboxConfirmStatus[checkboxKey]}
                                        onChange={handleCheckboxChange}
                                    />
                                }
                                label={markupsArray[2]}
                            />
                        )
                        checkboxKey += 1
                    }
                    currentShortDescriptionEntry = currentShortDescriptionEntry.substring(markupsArray[0].length)
                } else {
                    console.error(
                        "Faulty markup in shortDescription\nunexpected '{' / missing '}'\nCorrect Example: {checkbox:This is a checkbox label.}"
                    )
                    break
                }
            }
        }

        return content.map((item, idx) => (
            <div key={idx} className="item">
                {item}
            </div>
        ))
    }

    const currentNews = news[0]
    const { shortDescription, title, content } = currentNews

    const page = fullNewsLength - news.length + 1

    return (
        <Dialog
            className="form-confirmation"
            ref={dialogRef}
            onConfirm={() => handleConfirm(currentNews)}
            onClose={() => handleClose(currentNews)}
            preText={`${page} ${translateText(344)} ${fullNewsLength}`}
            text={title}
            iconName="info"
            layout="stretch"
            confirmButtonText={translateText(414)}
            doNotCloseOnConfirm
            confirmButtonDisabled={isConfirmButtonDisabled}
        >
            {renderShortDescription(shortDescription)}
            <iframe className="PDF-IFrame" src={`${content.trim()}#zoom=100`} />
        </Dialog>
    )
}

export default connectComponent(Actions, FormConfirmationComponent)
