import { BundleComponent } from "@tm/morpheus"
import { reduce } from "./business"
import component from "./component"

const bundleComponent: BundleComponent = {
    name: "page-navigation",
    reduce,
    component,
}

export default bundleComponent
