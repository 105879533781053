import { useWorkTask } from "@tm/context-distribution"
import { useCallback, useMemo } from "react"
import { AddCatalogPartListRequest, Article, CatalogPart, ErpInformation } from "@tm/models"
import { mapArticleToCatalogPart, useErpConfig } from "@tm/utils"
import { useBasketMemo } from "../../../../../../basket/src/hooks/useBasketMemo"
import { useWarehouse } from "../../../../../../basket/src/data/hooks/useWarehouse"
import { SearchType } from "../../../../business"
import { useVehicle } from "../useVehicle"
import { useBasketParts } from "../../../../../../basket/src/hooks/basketState/useBasketParts"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ArticleErpInfoFactory } from "../useErpInfos"

export function useHandleAddToBasket(article: Article | Article[]) {
    const { workTaskId, workTask } = useWorkTask() ?? {}
    const { customer } = workTask ?? {}
    const vehicle = useVehicle()
    const { erpSystemConfigs, useOrderByDistributor } = useErpConfig()
    const defaultErpSystem = useDefaultErpSystem()

    const inputArticle = useMemo(() => {
        return Array.isArray(article) ? article : [article]
    }, [article])

    // TODO: Searchtyp anpassen,
    const basketMemo = useBasketMemo(workTask, SearchType.PARTSLIST)
    const warehouseData = useWarehouse(inputArticle[0]?.id, defaultErpSystem?.id)
    const { addCatalogPartList } = useBasketParts()

    const handleAddToBasket = useCallback(
        (quantity: number, erpInfos?: ErpInformation[] | ErpInformation | null, erpSystemId?: number, enableCostEstimation?: boolean) => {
            if (!workTaskId) {
                return Promise.reject()
            }

            // send erpSystem only when useOrderByDistributor is true
            let erpSystem = defaultErpSystem
            if (useOrderByDistributor) {
                erpSystem = erpSystemConfigs?.find((x) => x.id === erpSystemId) ?? erpSystem
            }

            const addToBasketArticles = inputArticle.length === 1 ? [{ ...inputArticle[0], quantity }] : inputArticle
            const request: AddCatalogPartListRequest = {
                catalogParts: addToBasketArticles.map<CatalogPart>((basketArticle) => {
                    let erpInfo: ErpInformation | undefined
                    if (erpSystem) {
                        const erpInfoKey = ArticleErpInfoFactory.createKeyFromArticle(basketArticle, erpSystem.id)
                        erpInfo = Array.isArray(erpInfos) ? erpInfos?.find((erp) => erp.itemId === erpInfoKey.id) : erpInfos ?? undefined
                    }
                    return mapArticleToCatalogPart(
                        basketArticle,
                        erpInfo,
                        useOrderByDistributor ? erpSystem : undefined,
                        basketMemo.position,
                        warehouseData.warehouse
                    )
                }),
                workTaskId,
                vehicleId: vehicle?.id,
                customerId: customer?.id,
                usePercentageValues: true,
                // TODO: check which module shoudl be  logged
                // log: origin
                //     ? TmaHelper.AddCatalogPartToBasket.GetUserInteractionLog(
                //           addToBasketArticles.first()?.articleListPosition,
                //           TmaEModule.ARTICLE_LIST
                //       )
                //     : undefined,
            }

            return addCatalogPartList(request, enableCostEstimation)
        },
        [
            workTaskId,
            useOrderByDistributor,
            erpSystemConfigs,
            defaultErpSystem,
            inputArticle,
            vehicle?.id,
            customer?.id,
            basketMemo.position,
            warehouseData.warehouse,
        ]
    )
    return handleAddToBasket
}
