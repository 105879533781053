import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function fastCalculatorNextFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const fastCalculatorNextModule = getModuleFromUserContext(userContext, UserModuleType.FastCalculatorNext)

    if (!fastCalculatorNextModule?.uiConfigKeys?.includes("simplewidget")) {
        return undefined
    }

    return {
        "fast-calculator-next": {
            items: {
                "fast-calculator-next": {
                    active: true,
                    sort: 0,
                    disabledFunction: "FASTCALC-ANY-DISABLED",
                    route: "/:workTaskId/fast-calculator-next",
                    name: "service-calculator",
                },
            },
        },
    }
}
