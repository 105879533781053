import { useMemo } from "react"
import { ConfigFunctions, ConfigFunctionType } from "@tm/utils"
import { useUser, useWorkTask } from "@tm/context-distribution"

export function useDisabledFunction(item: { disabledFunction?: string; disabled?: string | boolean }) {
    const { userContext } = useUser()
    const { workTask } = useWorkTask()!

    const result = useMemo(() => {
        if (typeof item.disabled === "boolean") {
            return item.disabled
        }

        let disabledFunction: ConfigFunctionType | undefined = item.disabledFunction
            ? ConfigFunctions[item.disabledFunction as keyof typeof ConfigFunctions]
            : undefined

        if (!disabledFunction) {
            if (!item.disabled) {
                return false
            }

            try {
                disabledFunction = eval(`[${item.disabled}][0]`)
            } catch {
                return false
            }

            if (typeof disabledFunction !== "function") {
                return false
            }
        }

        const disabledResult = disabledFunction(userContext, workTask?.vehicle, workTask?.customer)

        if (typeof disabledResult !== "boolean") {
            return false
        }

        return disabledResult
    }, [userContext, workTask?.vehicle, workTask?.customer, item.disabled, item.disabledFunction])

    return { isDisabled: result }
}
