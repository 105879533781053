import { forwardRef, memo, useCallback, useMemo, useState } from "react"
import { Dialog, Dropdown, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { RepairshopAppointmentRejectionReasons, RepairshopAppointmentRejectionReasonsTextMap } from "../../../../data"
import { getComponentStyles } from "./styles"

type Props = {
    onConfirm(reason: RepairshopAppointmentRejectionReasons, text: string): void
    onClose(): void
}

const reasons = Object.keys(RepairshopAppointmentRejectionReasonsTextMap).map((reason) => ({
    reason: reason as RepairshopAppointmentRejectionReasons,
}))

export const AppointmentRejectionForm = memo(
    forwardRef<Dialog, Props>((props, ref) => {
        const classNames = useMemo(() => getComponentStyles(), [])
        const { translateText } = useLocalization()
        const [selection, setSelection] = useState(reasons[0])
        const [otherReasonText, setOtherReasonText] = useState("")

        const handleConfirm = useCallback(() => {
            const text =
                selection.reason === "otherReason" ? otherReasonText : translateText(RepairshopAppointmentRejectionReasonsTextMap[selection.reason])
            props.onConfirm(selection.reason, text)
        }, [selection.reason, otherReasonText])

        const disabled = selection.reason === "otherReason" && !otherReasonText

        return (
            <Dialog
                ref={ref}
                onConfirm={handleConfirm}
                confirmButtonText={translateText(642)}
                onClose={props.onClose}
                text={translateText(3013)}
                confirmButtonDisabled={disabled}
                className={classNames.main}
            >
                <Text className={classNames.headline} modifiers={["block", "strong"]}>
                    {translateText(12814)}
                </Text>
                <Dropdown<typeof reasons[number]>
                    items={reasons}
                    value={selection}
                    onChange={setSelection}
                    itemView={(item: typeof reasons[number]) => <>{translateText(RepairshopAppointmentRejectionReasonsTextMap[item.reason])}</>}
                    className={classNames.dropdown}
                />

                {selection.reason === "otherReason" && (
                    <TextField
                        className={classNames.textField}
                        floatingLabel
                        label={`${translateText(12816)} *`}
                        value={otherReasonText}
                        onChange={setOtherReasonText}
                    />
                )}
            </Dialog>
        )
    })
)
