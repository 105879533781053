import { useState } from "react"
import {
    DialogContent,
    DialogActions,
    DialogContentText,
    MuiTable as Table,
    MuiTableBody as TableBody,
    MuiTableRow as TableRow,
    MuiTableHead as TableHead,
    Button,
} from "@tm/components"
import { CustomerShortInfo } from "@tm/models"
import { useLocalization } from "@tm/localization"

import { useCustomerById, useCustomersByQuery } from "../hooks/useCustomerSearch"
import VehicleStart from "./vehicle-start"
import { ModalLoader, StyledCell } from "./_shared"
import { Props } from "./customer-start"

const CREATE_NEW = "new"
export function CustomerSearch({ chatId, tmCustomerId, customerInfo, openOptions }: Props) {
    const { translateText } = useLocalization()

    const { customersByQuery, customersByQueryLoading } = useCustomersByQuery(customerInfo, true)
    const [selectedCustomerShortInfo, setSelectedCustomerShortInfo] = useState<CustomerShortInfo | typeof CREATE_NEW>()
    const { customerById: selectedCustomer, customerByIdLoading: selectedCustomerLoading } = useCustomerById(
        selectedCustomerShortInfo === CREATE_NEW ? undefined : selectedCustomerShortInfo?.id
    )

    if (customersByQueryLoading || selectedCustomerLoading) {
        return <ModalLoader />
    }
    if (customersByQuery?.length && !selectedCustomerShortInfo) {
        return (
            <>
                <DialogContent>
                    <DialogContentText>{translateText(12989)}.</DialogContentText>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <StyledCell>{translateText(156)}</StyledCell>
                                <StyledCell>{translateText(155)}</StyledCell>
                                <StyledCell>{translateText(108)}</StyledCell>
                                <StyledCell>{translateText(111)}</StyledCell>
                                <StyledCell>{`${translateText(112)}/${translateText(113)}`}</StyledCell>
                                <StyledCell>{translateText(110)}</StyledCell>
                                <StyledCell>{translateText(109)}</StyledCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customersByQuery.map((customer) => (
                                <TableRow
                                    key={customer.id}
                                    sx={{ cursor: "pointer", "&:last-child td, &:last-child th": { border: 0 } }}
                                    onClick={() => {
                                        setSelectedCustomerShortInfo(customer)
                                    }}
                                >
                                    <StyledCell>{customer.customerNo}</StyledCell>
                                    <StyledCell>
                                        {customer.firstName || ""} {customer.lastName || ""}
                                    </StyledCell>
                                    <StyledCell>{customer.companyName}</StyledCell>
                                    <StyledCell>{customer.street}</StyledCell>
                                    <StyledCell>
                                        {customer.zip || ""} {customer.city || ""}
                                    </StyledCell>
                                    <StyledCell>{customer.phone}</StyledCell>
                                    <StyledCell>{customer.email}</StyledCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            setSelectedCustomerShortInfo("new")
                        }}
                    >
                        {translateText(1110)}
                    </Button>
                </DialogActions>
            </>
        )
    }

    return (
        <VehicleStart chatId={chatId} openOptions={openOptions} customer={selectedCustomer} tmCustomerId={tmCustomerId} customerInfo={customerInfo} />
    )
}
