import { Tooltip, TooltipProps } from "@tm/components"
import * as React from "react"

type Props = TooltipProps

const OverflowTooltip: React.FC<Props> = ({ children, ...props }) => {
    const contentRef = React.useRef<HTMLDivElement>(null)

    const [showTooltip, setShowTooltip] = React.useState(false)

    React.useEffect(() => {
        if (!contentRef.current?.children[0]) {
            return
        }

        const child = contentRef.current.children[0] as HTMLDivElement
        setShowTooltip(child.offsetWidth < child.scrollWidth)
    }, [children])

    const renderContent = () => {
        return (
            <div style={{ overflow: "hidden" }} ref={contentRef}>
                {children}
            </div>
        )
    }

    return <>{contentRef.current && showTooltip ? <Tooltip {...props}>{renderContent()}</Tooltip> : renderContent()}</>
}

export default OverflowTooltip
