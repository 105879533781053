import Morpheus, { ModuleConfig, IPlugin, PluginProps } from "@tm/morpheus"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { CSSProperties } from "react"
import { AuthToken } from "@tm/models"
import { getExternalToken } from "../data/repositories/portalAuthority"
import { ExternalWidgetConfig, ExternalWidgetsKeyValueLoader } from "./ExternalWidgets"

const STORAGE_KEY = "DevspireCms"

type ExternalWidgetsPluginProps = {
    externalSystemId: number
}

type DevspireCmsModuleProps = {
    devspireCms?: true
}

type LoaderProps = PluginProps<ModuleConfig<DevspireCmsModuleProps>, ExternalWidgetsPluginProps>

async function loadConfig(props: LoaderProps) {
    const { externalSystemId } = props.pluginProps

    let tokenInfo: AuthToken | null
    // try to get token
    try {
        tokenInfo = await getExternalToken(STORAGE_KEY)
    } catch (ex: any) {
        console.debug("DevspireCmsLoader token not loaded!", ex?.message)
        tokenInfo = null
    }

    if (!tokenInfo?.token) {
        return
    }

    if (ExternalWidgetsKeyValueLoader.existsKey(STORAGE_KEY)) {
        return
    }

    const { token } = tokenInfo

    const serviceUrl = `${Morpheus.getParams().authorityServiceUrl}/url/GetExternalResponse`
    const body = { externalSystemId, options: JSON.stringify({ token }) }

    const widgets = ((await loadWidgetsConfig(serviceUrl, body)) || []).map((x) => {
        const styles: CSSProperties = x.styles || {}
        // Only percentage width is allowed
        if (typeof styles.width === "string" && styles.width.indexOf("%") !== -1) {
            try {
                if (parseFloat(styles.width) > 100) {
                    styles.width = "100%"
                }
            } catch {
                delete styles.width
            }
        } else {
            delete styles.width
        }
        return {
            ...x,
            url: x.url.replace("{{token}}", token),
        }
    })
    ExternalWidgetsKeyValueLoader.publish(STORAGE_KEY, widgets)
}

async function loadWidgetsConfig(url: string, params: Record<string, unknown>): Promise<ExternalWidgetConfig[] | null> {
    try {
        const authorization = getStoredAuthorization()
        const response = await ajax({ url, authorization, body: params })
        if (Array.isArray(response)) {
            return response
        }
        if (typeof response === "object" && Array.isArray(response.data)) {
            return response.data
        }
    } catch (ex) {
        console.log("Error on loading the DevspireCMS configuration", ex)
    }
    return null
}

const plugin: IPlugin<ModuleConfig> = {
    name: "DevspireCmsLoader",
    type: "MODULE/MOUNT",
    plugin: (props: LoaderProps) => {
        if (props.module.props?.devspireCms) {
            setTimeout(() => loadConfig(props), 0)
        }
    },
}

export default plugin
