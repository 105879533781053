import { FastDtcBundleParams } from "@tm/models"

export const version = {
    name: "fast-dtc",
    version: "2.0.0",
}

let bundleParams: FastDtcBundleParams

export function initFastDtcBundle(params: FastDtcBundleParams) {
    bundleParams = params
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The toolkit params have to be set in order to use the toolkit "${version.name}"`)
    }

    return bundleParams
}
