import { VoucherType } from "@tm/models"
import { useQuery, useMutation, useQueryClient } from "react-query"
import * as Data from ".."
import { CostEstimationVoucher } from "../model"

const COST_ESTIMATION_KEY = "vouchers__useVoucherList__cost-estimations"
const ORDERS_KEY = "vouchers__useVoucherList__orders"
const RETURNS_KEY = "vouchers__useVoucherList__returns"

export function useVoucherList(
    voucherType: VoucherType,
    customerId?: string,
    vehicleId?: string,
    pageIndex?: number,
    pageSize?: number,
    enabled?: boolean,
    filterByUser?: boolean,
    searchQuery?: string
) {
    const { data: costEstimations, isLoading: costEstimationsLoading } = useQuery(
        [COST_ESTIMATION_KEY, customerId, vehicleId, searchQuery, pageIndex, pageSize, filterByUser],
        () => {
            if (searchQuery) {
                return Data.showCostEstimationsByArticle({ searchStr: searchQuery, customerId, vehicleId, pageIndex, pageSize, filterByUser })
            }
            if (customerId && vehicleId) {
                return Data.showCostEstimationsByVehicles({ vehicleIds: [vehicleId], pageIndex, pageSize, filterByUser })
            }
            if (customerId) {
                return Data.showCostEstimationsByCustomer({ customerId, pageIndex, pageSize, filterByUser })
            }

            return Data.showAllCostEstimations({ pageIndex, pageSize, filterByUser })
        },
        {
            enabled: enabled !== false && voucherType === VoucherType.CostEstimation,
            staleTime: 10 * 1000, // 10 seconds
        }
    )

    const { data: orders, isLoading: ordersLoading } = useQuery(
        [ORDERS_KEY, customerId, vehicleId, searchQuery, pageIndex, pageSize, filterByUser],
        () => {
            if (searchQuery) {
                return Data.showOrdersByArticle({ searchStr: searchQuery, customerId, vehicleId, pageIndex, pageSize, filterByUser })
            }
            if (customerId && vehicleId) {
                return Data.showOrdersByVehicles({ vehicleIds: [vehicleId], pageIndex, pageSize, filterByUser })
            }
            if (customerId) {
                return Data.showOrdersByCustomer({ customerId, pageIndex, pageSize, filterByUser })
            }
            return Data.showAllOrders({ pageIndex, pageSize, filterByUser })
        },
        {
            enabled: enabled !== false && voucherType === VoucherType.Order,
            staleTime: 10 * 1000, // 10 seconds
        }
    )

    const { data: returns, isLoading: returnsLoading } = useQuery(
        [RETURNS_KEY, customerId, vehicleId, searchQuery, pageIndex, pageSize, filterByUser],
        () => {
            // if (customerId && vehicleId) {
            //     return Data.showOrdersByVehicles({ vehicleIds: [vehicleId], pageIndex, pageSize })
            // }
            // if (customerId) {
            //     return Data.showOrdersByCustomer({ customerId, pageIndex, pageSize })
            // }
            if (searchQuery) {
                return Data.showReturnsByArticle({ searchStr: searchQuery, customerId, vehicleId, pageIndex, pageSize, filterByUser })
            }

            return Data.showAllReturns({ pageIndex, pageSize, filterByUser })
        },
        {
            enabled: enabled !== false && voucherType === VoucherType.Return,
            staleTime: 10 * 1000, // 10 seconds
        }
    )
    return { costEstimations, costEstimationsLoading, orders, ordersLoading, returns, returnsLoading }
}

export function useDeleteCostEstimation() {
    const queryClient = useQueryClient()
    const mutation = useMutation(Data.deleteCostEstimations, {
        onSuccess: (_, costEstimationIds) => {
            queryClient.setQueriesData<CostEstimationVoucher[] | undefined>(COST_ESTIMATION_KEY, (prev) =>
                prev?.filter((voucher) => !costEstimationIds.includes(voucher.id))
            )
        },
    })

    return { deleteCostEstimations: mutation.mutateAsync }
}
