import { Stack } from "@tm/components"
import { useUser, useWorkTask } from "@tm/context-distribution"
import { ConfigParams, RegisteredModels, ViewState, WorkTaskStatus } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { Suspense, useEffect, useState } from "react"
import { ESendCostEstimationMode } from "../../data/model"
import { ExportConfiguration } from "../../data/model/sap"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"
import WorkTaskOrderButtonComponent, { WorkTaskOrderButtonConfigProps } from "../_shared/order-button/worktask"
import { TransferButtons } from "../_shared/transfer-buttons"
import CloseOrAbortWorktask from "../close-abort-worktask-button/CloseOrAbortWorktask"
import { SAPExportButton } from "./SAPExportButton"

type Props = WorkTaskOrderButtonConfigProps

function Component(props: Props & { workTaskId: string }) {
    const { workTaskBasketLoading, basket } = useWorkTaskBasketState(props.workTaskId)
    const [exportConfiguration, setExportConfiguration] = useState<ExportConfiguration>()
    const { userContext } = useUser() ?? {}
    const workTask = useWorkTask()

    useEffect(() => {
        ;(async () => {
            const params = Morpheus.getParams<ConfigParams>()

            // Check if module is available
            if (!params.sapCXMLIntegrationAvailable && !params.sapOCIIntegrationAvailable) {
                return
            }

            try {
                let { value } = await Container.getInstance<ViewState<ExportConfiguration | undefined>>(RegisteredModels.ViewState)
                    .subscribe(`${props.workTaskId}__SAP_EXPORT_CONFIGURATION`)
                    .load()

                /** @todo MLE 25.08.2022 - Remove fallback some day, when there are no more ...OCI_EXPORT_CONFIGURATION entries in the StateManagement database table */
                if (!value && params.sapOCIIntegrationAvailable) {
                    value = (
                        await Container.getInstance<ViewState<ExportConfiguration | undefined>>(RegisteredModels.ViewState)
                            .subscribe(`${props.workTaskId}__OCI_EXPORT_CONFIGURATION`)
                            .load()
                    ).value
                }

                setExportConfiguration(value)

                // eslint-disable-next-line no-empty
            } catch {}
        })()
    }, [props.workTaskId])

    function renderOrderButton() {
        if (exportConfiguration) {
            return <SAPExportButton workTaskId={props.workTaskId} exportConfiguration={exportConfiguration} />
        }

        return (
            <>
                {userContext.parameter.showCloseWorktaskButton &&
                    workTask?.workTask?.telesalesStatusValue !== WorkTaskStatus.Canceled &&
                    workTask?.workTask?.telesalesStatusValue !== WorkTaskStatus.Completed && <CloseOrAbortWorktask />}
                <WorkTaskOrderButtonComponent {...props} orderGroups={basket.state.basketOrderGroups} orderGroupsLoading={workTaskBasketLoading} />
            </>
        )
    }

    return (
        <Stack direction="row" spacing={1}>
            <TransferButtons workTaskId={props.workTaskId} mode={ESendCostEstimationMode.Basket} />
            {renderOrderButton()}
        </Stack>
    )
}

export default function Wrapper(props: Props) {
    const workTaskId = useWorkTask()?.workTaskId
    return <Suspense fallback={null}>{workTaskId && <Component {...props} workTaskId={workTaskId} />}</Suspense>
}
