import { ajax, getStoredAuthorization } from "@tm/utils"
import { Vehicle, RegisteredModels, VehicleContainer, channel, getWorkTaskId, TelematicsProvider } from "@tm/models"
import { Container } from "@tm/nexus"
import { ClearanceStatusResponse, TelematicsDataSummaryResponse, TelematicsDataResponse } from "./model"
import { getBundleParams } from "../utils"
import { mapClearanceStatusResponse, mapTelematicsDataResponse } from "./mapper"

export * from "./model"

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.serviceUrl
}

export function requestClearance(dataProvider: TelematicsProvider, vinNumber: string): Promise<void> {
    const url = `${getServiceUrl()}/RequestClearance`
    const authorization = getStoredAuthorization()
    const body = { dataProvider, vinNumber }

    return new Promise<void>((resolve, reject) => ajax({ url, body, authorization, method: "POST" }).then(resolve, reject))
}

export function showClearanceStatus(dataProvider: TelematicsProvider, vinNumber: string): Promise<ClearanceStatusResponse> {
    const url = `${getServiceUrl()}/ShowClearanceStatus`
    const authorization = getStoredAuthorization()
    const body = { dataProvider, vinNumber }

    return new Promise<ClearanceStatusResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => resolve(mapClearanceStatusResponse(response)), reject)
    )
}

export function showTelematicsDataSummary(dataProvider: TelematicsProvider, vinNumber: string): Promise<TelematicsDataSummaryResponse> {
    const url = `${getServiceUrl()}/ShowTelematicsDataSummary`
    const authorization = getStoredAuthorization()
    const body = { dataProvider, vinNumber }

    return new Promise<TelematicsDataSummaryResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => resolve(mapTelematicsDataResponse(response)), reject)
    )
}

export function showTelematicsData(dataProvider: TelematicsProvider, vinNumber: string): Promise<TelematicsDataResponse> {
    const url = `${getServiceUrl()}/ShowTelematicsData`
    const authorization = getStoredAuthorization()
    const body = { dataProvider, vinNumber }

    return new Promise<TelematicsDataResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => resolve(mapTelematicsDataResponse(response)), reject)
    )
}

export function updateTelematicsData(dataProvider: TelematicsProvider, vinNumber: string): Promise<TelematicsDataResponse> {
    const url = `${getServiceUrl()}/UpdateTelematicsData`
    const authorization = getStoredAuthorization()
    const body = { dataProvider, vinNumber }

    return new Promise<TelematicsDataResponse>((resolve, reject) =>
        ajax({ url, body, authorization }).then((response) => resolve(mapTelematicsDataResponse(response)), reject)
    )
}

export async function updateVehicle(vehicle: Vehicle) {
    const container: VehicleContainer = Container.getInstance(RegisteredModels.Vehicle)
    const editedVehicle = await container.action("update")(vehicle)
    getWorkTaskId((id) => channel("WORKTASK", id).publish("VEHICLE/EDITED", editedVehicle))
}
