import { useRef } from "react"

/**
 * Only calls the callback once in the timespan of the delay.
 * Default delay is 1s
 * @param {T} callback - Callback function.
 * @param {number} [delay=1000] - Delay in milliseconds.
 */
export function useCallbackDebounce<T extends (...args: any[]) => void>(callbackFunction: T, delay = 1000): (...args: Parameters<T>) => void {
    const lastSubmitTimeRef = useRef<number | null>(null)

    return (...args: Parameters<T>) => {
        const now = Date.now()
        if (lastSubmitTimeRef.current && now - lastSubmitTimeRef.current < delay) {
            return
        }

        lastSubmitTimeRef.current = now
        callbackFunction(...args)
    }
}
