import { UpdateRepairShopRequest, getModuleFromUserContext, useUser, useWorkTask } from "@tm/context-distribution"
import { Widget } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import Morpheus from "@tm/morpheus"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { UserModuleType } from "@tm/models"
import { useRef } from "react"
import { getBundleParams } from "../../utils"
import { useGetReturnUrl } from "../../hooks/useGetReturnUrl"
import { openPartslifeInNewWindow } from "../../lib/helpers/openPartslifeInNewWindow"
import { getReturnUrlRequest } from "../../lib/helpers/returnUrl"
import ErrorNotification from "../shared/ErrorNotification"
import { Box, Icon } from "@tm/components"

type Props = {
    displayBehavior: "dashboard" | "startpage" | "both"
    icon?: string
    targetIcon?: string
}

export default function PartslifeWidget(props: Props) {
    const { workTaskId } = useWorkTask() || {}
    const { translateText, language } = useLocalization()
    const { userContext, userSettings, setUserSetting } = useUser()
    const contentRef = useRef<HTMLDivElement>(null)

    const moduleDisplay: typeof props.displayBehavior = getModuleFromUserContext(userContext, UserModuleType.Partslife)?.displayBehavior

    const getReturnUrlMutation = useGetReturnUrl({
        translateText,
        onSuccess(returnUrl) {
            openPartslifeInNewWindow(returnUrl, contentRef)
            setUserSetting?.("PARTSLIFE_SETTINGS", { ...userSettings?.partsLifeSettings, hasPartsLifeActive: true })
        },
    })

    if (!(props.displayBehavior === moduleDisplay || moduleDisplay === "both")) {
        return null
    }

    function onClick() {
        let route
        if (!userSettings?.partsLifeSettings.hasPartsLifeActive) {
            route = getBundleParams().moduleModalUrl
        } else {
            const request = getReturnUrlRequest(
                userSettings?.repairShop as UpdateRepairShopRequest,
                userSettings?.repairShop?.id ?? "",
                language,
                translateText
            )
            getReturnUrlMutation.mutate(request)
            return
        }

        if (!route) {
            return
        }

        const comparePageUrl = renderRoute(route, {
            workTaskId: encodeUniqueId(workTaskId ?? uniqueId()),
        })

        Morpheus.showView("1", comparePageUrl)
    }

    function widgetTitle() {
        return (
            <Box width="100%">
                {translateText(13584)}
                {props.targetIcon && <Icon name={props.targetIcon} className="target-icon" />}
            </Box>
        )
    }

    return (
        <ErrorNotification>
            <Widget forwardedRef={contentRef} size="1x1" iconName={props.icon ?? "partslife"} title={widgetTitle()} onClick={onClick} />
        </ErrorNotification>
    )
}
