import { callCarsService } from "."

type ShowKbaNumbersRequest = {
    modelId: number
}

type ShowKbaNumbersResponse = {
    numbers: Array<string>
}

export function showKbaNumbers(request: ShowKbaNumbersRequest) {
    return callCarsService<ShowKbaNumbersRequest, ShowKbaNumbersResponse>("ShowKbaNumbers", request)
}
