import { Dispatch } from "react"
import { RegisteredModels, ViewStateContainer, CisFindCustomerDto, CisCustomerContainer } from "@tm/models"
import { Container } from "@tm/nexus"
import * as Data from "../../../data/repositories"

type SearchType = "SEARCHED" | "CURRENT_CALLER"
export type PartnerSearchState = {
    partnerLoading: boolean
    partnerList?: Array<CisFindCustomerDto>
    searchType?: SearchType
    query?: string
    defaultQueryTypeId?: number
    searchHistory: SearchHistoryItem[]
}

export type ActionType =
    | { type: "HISTORY_LOADED"; payload: SearchHistoryItem[] }
    | { type: "PARTNER_LOADING"; payload?: string }
    | { type: "PARTNER_LOADED"; payload?: Array<CisFindCustomerDto>; searchType?: SearchType }
    | { type: "RESET" }
    | { type: "DEFAULT_QUERYTYPE_LOADED"; payload: number }

export const INITIAL_STATE: PartnerSearchState = {
    partnerLoading: false,
    searchHistory: [],
}

export function reduce(state: PartnerSearchState, action: ActionType): PartnerSearchState {
    switch (action.type) {
        case "HISTORY_LOADED": {
            return {
                ...state,
                searchHistory: action.payload,
            }
        }
        case "PARTNER_LOADING": {
            return {
                ...state,
                partnerLoading: true,
                query: action.payload,
                partnerList: undefined,
            }
        }
        case "PARTNER_LOADED": {
            return {
                ...state,
                partnerLoading: false,
                partnerList: action.payload,
                searchType: action.searchType || "SEARCHED",
            }
        }
        case "DEFAULT_QUERYTYPE_LOADED": {
            return {
                ...state,
                defaultQueryTypeId: action.payload,
            }
        }
        case "RESET": {
            return {
                ...state,
                partnerLoading: false,
                query: undefined,
                partnerList: undefined,
            }
        }
        default:
            break
    }
    return state
}

const VIEWSTATE_KEY = "TELESALES_PARTNERSEARCH"

export async function setDefaultQueryType(dispatch: Dispatch<ActionType>, state: PartnerSearchState, defaultQueryType: number) {
    const container = Container.getInstance(RegisteredModels.ViewState) as ViewStateContainer
    const viewState = {
        key: VIEWSTATE_KEY,
        value: {
            defaultQueryType,
            searchHistory: state.searchHistory,
        },
    }
    await container.action("saveViewState")(viewState)
    dispatch({ type: "DEFAULT_QUERYTYPE_LOADED", payload: defaultQueryType })
}

async function setHistory(state: PartnerSearchState, history: SearchHistoryItem[]) {
    const container = Container.getInstance(RegisteredModels.ViewState) as ViewStateContainer
    const viewState = {
        defaultQueryType: state.defaultQueryTypeId,
        searchHistory: history,
    }
    await container.action("saveViewState")({ key: VIEWSTATE_KEY, value: viewState })
}

export function searchPartner(dispatch: Dispatch<ActionType>, state: PartnerSearchState, query: string, queryTypeId: number) {
    dispatch({ type: "PARTNER_LOADING", payload: query })
    Data.searchPartner(query, queryTypeId)
        .then((result) => {
            dispatch({ type: "PARTNER_LOADED", payload: result })
            if (result.length) {
                const { searchHistory } = state
                const newHistory = [
                    { query, queryTypeId },
                    ...(searchHistory || []).filter((x) => !(x.query === query && x.queryTypeId === queryTypeId)).slice(0, 11),
                ]
                setHistory(state, newHistory)
                dispatch({ type: "HISTORY_LOADED", payload: newHistory })
            }
        })
        .catch(() => {
            dispatch({ type: "PARTNER_LOADED", payload: [] })
        })
}

export function searchCurrentActivePartner(dispatch: Dispatch<ActionType>) {
    dispatch({ type: "PARTNER_LOADING" })
    const container = Container.getInstance(RegisteredModels.CIS_Customer) as CisCustomerContainer
    container
        .action("loadCurrentCaller")()
        .then((response) => {
            dispatch({ type: "PARTNER_LOADED", payload: response, searchType: "CURRENT_CALLER" })
        })
        .catch(() => {
            dispatch({ type: "PARTNER_LOADED", payload: [], searchType: "CURRENT_CALLER" })
        })
}

export function resetPartner(dispatch: Dispatch<ActionType>) {
    dispatch({ type: "RESET" })
}

export type SearchHistoryItem = {
    query: string
    queryTypeId: number
}

export async function loadViewState(dispatch: Dispatch<ActionType>) {
    const container = Container.getInstance(RegisteredModels.ViewState) as ViewStateContainer
    const state = await container.action("loadViewState")(VIEWSTATE_KEY)
    dispatch({ type: "DEFAULT_QUERYTYPE_LOADED", payload: state.value.defaultQueryType })
    dispatch({ type: "HISTORY_LOADED", payload: state.value.searchHistory || [] })
}
