import { IncludedWorkRequest, RepairTime } from "@tm/models"

export type Work = RepairTime & {
    hourlyRate: number
    value: number
    calculatedTime: number
    includedWorks?: Array<IncludedWorkRequest>
    isSelected: boolean
    isExpanded: boolean
    total: number
    datCategoryOfWork: string
    type: EDatRepairworkType
    rtUnit: number
    time: number
    isFixPrice?: boolean
}

export enum EDatRepairworkType {
    PaintWork = 0,
    LabourWork = 1,
}
