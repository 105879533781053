import { Box, Icon, ListItemButton, ListItemIcon, ListItemText, ReplaceButton, Tooltip, styled } from "@tm/components"
import { CustomArticle } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import { getCurrencyFromUserContext } from "@tm/utils"
import { StyledListItem } from "./shared"

const StyledIcon = styled(Icon)(({ theme }) => ({
    fill: theme.overwrites?.components?.notesButton?.background || theme.palette.primary.main || "#202020",
}))

const ActionsGroup = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    gap: ".25em",
    alignItems: "center",
})

type Props = {
    item: CustomArticle
    articleNote?: string
    handleReplaceConsumable: (consumable: CustomArticle) => void
    handleEditConsumable: (consumable: CustomArticle) => void
    handleSaveDefaultForCategory: (consumable: CustomArticle) => void
}

export default function ConsumableItem({ item, articleNote, handleReplaceConsumable, handleEditConsumable, handleSaveDefaultForCategory }: Props) {
    const user = useUser()
    const { translateText } = useLocalization()
    const currencyString = getCurrencyFromUserContext(user?.userContext)
    const showPurchasePrice = user.userSettings?.showPurchasePrice

    return (
        <StyledListItem disablePadding>
            <ListItemButton sx={{ display: "grid", gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr 1fr" }} role={undefined} dense>
                <ListItemText id={item.articleNumber} primary={item.articleNumber} />
                <ListItemText id={item.description} primary={item.description} />
                <ListItemText
                    id={item.id}
                    sx={{ visibility: showPurchasePrice ? "visible" : "hidden" }}
                    primary={!!item.purchasePrice && `${item.purchasePrice?.toFixed(2)} ${currencyString}`}
                />
                <ListItemText id={item.id} primary={!!item.retailPrice && `${item.retailPrice?.toFixed(2)} ${currencyString}`} />
                <ListItemIcon>
                    <Icon
                        onClick={() => handleSaveDefaultForCategory(item)}
                        name={item.isDefaultForCategory ? "filled-star" : "vote-star"}
                        width="1.5em"
                        height="1.5em"
                    />
                </ListItemIcon>
                <ListItemIcon>
                    {articleNote?.length ? (
                        <Tooltip
                            title={
                                <>
                                    {translateText(12874)} <br /> {articleNote}
                                </>
                            }
                            variant="light"
                        >
                            <StyledIcon name="notes-added" />
                        </Tooltip>
                    ) : (
                        <></>
                    )}
                </ListItemIcon>
                <ActionsGroup>
                    <Tooltip title={translateText(122)} variant="light">
                        <Icon
                            onClick={() => handleEditConsumable(item)}
                            name="edit_change"
                            width="1.5em"
                            height="1.5em"
                            sx={{ fill: "#202020", marginRight: ".25em" }}
                        />
                    </Tooltip>
                    <ReplaceButton disableMargin onClick={() => handleReplaceConsumable(item)} />
                </ActionsGroup>
            </ListItemButton>
        </StyledListItem>
    )
}
