import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { TyresCritsRequest, TyresCritsResponse } from "./model"

export * from "./model"

export async function loadTyresCrits(request: TyresCritsRequest) {
    const url = `${getServiceUrl()}/Tyres/TyresCritsV2`
    const authorization = getStoredAuthorization()

    const res = await ajax<TyresCritsResponse>({ method: "POST", url, body: request, authorization })

    if (!res) {
        throw new Error("Empty data")
    }

    return res
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.wheelsBaseUrl
}
