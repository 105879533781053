import { useLocalization } from "@tm/localization"
import { useCallback } from "react"

export function usePdfDownload() {
    const { translateText } = useLocalization()

    const downloadPdf = useCallback(async (pdfUrl: string, fileName?: string) => {
        try {
            const response = await fetch(pdfUrl)
            if (response.ok) {
                const blob = await response.blob()
                const completeFileName = fileName || translateText(13811)
                const blobUrl = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }))

                const a = document.createElement("a")
                a.href = blobUrl
                a.download = completeFileName
                a.click()

                window.URL.revokeObjectURL(blobUrl)
                return true
            }

            return false
        } catch (error) {
            return false
        }
    }, [])

    return { downloadPdf }
}
