import * as React from "react"
import { Text } from "@tm/controls"
import { OE } from "@tm/models"
import { useLocalization } from "@tm/localization"

type PriceProps = {
    price: OE.OePartPrice
    quantity: number
}
export const Price: React.FC<PriceProps> = (props) => {
    const { currency } = useLocalization()
    const { price, quantity } = props

    return (
        <div className="oe-prices__price">
            <Text size="s" className="oe-prices__price__description">
                {price.description}
            </Text>
            <Text modifiers="strong" className="prices__price__value">
                {currency(price.value * quantity, price.currency || "")}
            </Text>
        </div>
    )
}
