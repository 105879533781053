import { Alert, Loader, Stack } from "@tm/components"
import { useCisCustomer } from "@tm/context-distribution"
import { Radio, Widget, WidgetSizes } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useState } from "react"
import { useCisShowTours } from "../../data/hooks/useCisShowTours"
import { useFindInvoicesSingle } from "../../data/hooks/useFindInvoicesSingle"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"
import AccountBalanceComponent from "./components/AccountBalance"
import GeneralInformationComponent from "./components/GeneralInformation"
import CreditComponent from "./components/credit"

type Props = {
    externalSystemId?: number
    hideCover?: boolean
    mainTitleTextId?: number
    showAccountBalance?: boolean
    showCreditInfo?: boolean
    showCustomerNumber?: boolean
    showRealTours?: boolean
    showVertical?: boolean
    size?: WidgetSizes
}

export default function WidgetComponent({
    showCreditInfo,
    showAccountBalance,
    showRealTours,
    hideCover,
    mainTitleTextId,
    externalSystemId,
    showVertical,
    size,
    showCustomerNumber,
}: Props) {
    const { translateText, translate } = useLocalization()

    const [active, setActive] = useState<boolean>(!!hideCover)
    const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)

    const { cisCustomer, cisCustomerLoading } = useCisCustomer(
        !externalSystemId && (showCreditInfo !== false || showAccountBalance !== false || showCustomerNumber !== false)
    )
    const { invoices, invoicesLoading } = useFindInvoicesSingle(showAccountBalance !== false)
    const { tours, toursLoading } = useCisShowTours(showRealTours === true)

    function handleWidgetClick() {
        setActive(true)
    }

    function handlePageChange(value: number) {
        setCurrentPageIndex(value)
    }

    function renderCurrentPage() {
        switch (currentPageIndex) {
            default:
            case 0: {
                if (showRealTours === true && toursLoading) {
                    return <Loader />
                }

                return (
                    <GeneralInformationComponent
                        tours={tours}
                        customer={cisCustomer}
                        customerLoading={cisCustomerLoading}
                        toursLoading={toursLoading}
                        showVertical={showVertical}
                    />
                )
            }
            case 1: {
                if (cisCustomerLoading) {
                    return <Loader />
                }

                if (cisCustomer) {
                    return <CreditComponent customer={cisCustomer} />
                }

                return <Alert severity="warning" title={translateText(323)} />
            }
            case 2: {
                if (cisCustomerLoading || invoicesLoading) {
                    return <Loader />
                }

                if (cisCustomer && invoices) {
                    return <AccountBalanceComponent customer={cisCustomer} invoices={invoices} />
                }

                return <Alert severity="warning" title={translateText(323)} />
            }
        }
    }

    function renderRadioButton(value: number) {
        return <Radio value={value} checked={currentPageIndex === value} onCheck={handlePageChange} />
    }

    if (!cisCustomer) {
        return null
    }

    return (
        <Widget
            size={size || "4x2"}
            active={active}
            title={translate(491)}
            iconName="users"
            header={<WidgetHeaderTitleComponent iconName="user" titleTextId={mainTitleTextId || 491} linkTextId={cisCustomer ? 426 : undefined} />}
            onClick={handleWidgetClick}
        >
            <Stack justifyContent="space-between" flex={1}>
                {renderCurrentPage()}
                {(showCreditInfo !== false || showAccountBalance !== false) && (
                    <Stack direction="row" spacing={0.5} alignSelf="center">
                        {renderRadioButton(0)}
                        {showCreditInfo !== false && renderRadioButton(1)}
                        {showAccountBalance !== false && renderRadioButton(2)}
                    </Stack>
                )}
            </Stack>
        </Widget>
    )
}
