import { getStoredAuthorization, ajax } from "@tm/utils"
import { StatusData, StatusIdType } from "../../model"
import { getBundleParams } from "../../params"
import { mapGetStatusDataResponse } from "./mapper"

export * from "./model"

export const getStatusData = (id: string, idType: StatusIdType): Promise<StatusData> => {
    const url = `${getServiceUrl()}/GetStatusData`
    const authorization = getStoredAuthorization()
    const body = { id, idType }

    return new Promise<StatusData>((resolve, reject) =>
        ajax({ url, body, authorization }).then(
            data => resolve(mapGetStatusDataResponse(data)),
            reject
        )
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.telematicsUrl
}