import { Box } from "@tm/components"
import { useLocalization } from "@tm/localization"

type Props = {
    alignCenter?: boolean
    mode: "onlyDate" | "onlyTime" | "dateSlashTime" | "dateAboveTime" | "dateDashTime"
    showHoursText?: boolean
    value: Date | undefined
}

export default function DateCellComponent({ value, mode, alignCenter, showHoursText }: Props) {
    const { date, translateText } = useLocalization()

    if (!value) {
        return <></>
    }

    switch (mode) {
        default:
        case "onlyDate":
            return <>{date(value, "d")}</>
        case "onlyTime":
            return <>{date(value, "t")}</>
        case "dateSlashTime":
            return <>{`${date(value, "d")} / ${date(value, "t")}`}</>
        case "dateAboveTime":
            return (
                <Box textAlign={alignCenter ? "center" : undefined}>
                    {date(value, "d")}
                    <br />
                    {date(value, "t")} {showHoursText && <>{translateText(12808)}</>}
                </Box>
            )
        case "dateDashTime":
            return (
                <>
                    {date(value, "d")} - {date(value, "t")} {showHoursText && <>{translateText(12808)}</>}
                </>
            )
    }
}
