import { useCallback, useState } from "react"
import { openStreamPdf } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { useUser } from "@tm/context-distribution"
import * as Data from ".."
import { mapPdfOrderGroup } from "../mapper"
import { useWorkTaskBasketState } from "../../hooks/basketState/useWorkTaskBasketState"

export function useShowWorkTaskOrderPdf(workTaskId: string) {
    const { translateText } = useLocalization()
    const { userSettings } = useUser() ?? {}
    const { workTaskBasketCalculation, basket } = useWorkTaskBasketState(workTaskId)

    const [loading, setLoading] = useState(false)

    const showWorkTaskOrderPdf = useCallback(() => {
        setLoading(true)

        const orderGroups = basket.state.basketOrderGroups?.map((orderGroup) => {
            return mapPdfOrderGroup(orderGroup)
        })

        Data.showWorkTaskOrderPdf({
            workTaskId,
            orderGroups,
            erpTotalsInOrder: workTaskBasketCalculation?.calculatedOrder?.erpTotalsInOrder || {},
            printOptions: {
                showPurchasePrice: userSettings ? userSettings.showPurchasePrice : true,
            },
        })
            .then((orderItemsPdf) => {
                if (orderItemsPdf) {
                    openStreamPdf(orderItemsPdf, translateText)
                }
            })
            .finally(() => setLoading(false))
    }, [workTaskId, workTaskBasketCalculation, basket.state.basketOrderGroups, userSettings, translateText])

    return { showWorkTaskOrderPdf, loadingWorkTaskOrderPdf: loading }
}
