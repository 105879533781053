import { TabWithImage, styled } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { uniqueId } from "@tm/utils"
import { useCallback } from "react"
import { Link } from "react-router-dom"

import { usePageNavigationTabInfo } from "../../../hooks/usePageNavigationTabInfo"
import { DataType, NavigationItem } from "../business"
import { ErrorBoundary } from "./errorBoundary"

type ComponentProps = {
    isSelected: boolean
    navItem?: NavigationItem
    dataType?: DataType
    resetWorkTask?: boolean
    hasDashboardIcon?: boolean
}

export type PageNavigationTabInfo = {
    carInfoText: string
    carThumbnail: string
    customerInfoText: string
}

const StyledLink = styled(Link)(({ theme }) => ({
    margin: `0 ${theme.spacing(1)}`,
    textDecoration: "none",
}))

export function ItemWithCar(props: ComponentProps) {
    const { navItem, isSelected, resetWorkTask, hasDashboardIcon } = props
    const worktask = useWorkTask()
    const { translateText } = useLocalization()

    const vehicle = worktask?.workTask?.vehicle
    const customer = worktask?.workTask?.customer

    const tabInfo = usePageNavigationTabInfo(customer, vehicle)

    const handleResetIconClick = useCallback(() => {
        worktask?.createWorkTask({ workTaskId: uniqueId() })
    }, [worktask])

    return (
        <ErrorBoundary>
            <StyledLink to={navItem?.url || ""} key={navItem?.id || "empty"}>
                <TabWithImage
                    title={tabInfo.carInfoText}
                    subtitle={tabInfo.customerInfoText}
                    emptyText={translateText(165)}
                    img={tabInfo.carThumbnail}
                    isSelected={isSelected}
                    onResetIconClick={resetWorkTask ? handleResetIconClick : undefined}
                    hasDashboardIcon={hasDashboardIcon}
                />
            </StyledLink>
        </ErrorBoundary>
    )
}
