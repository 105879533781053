import { useCallback, useState } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Button, Dialog, Icon, Typography } from "@tm/components"
import { useReplaceUrlTags, useTelesalesCustomerNumber, useWorkTask } from "@tm/context-distribution"
import { createQueryString, encodeUniqueId, renderRoute, useExternalCatalogUrl } from "@tm/utils"
import { CisVoucherType, RegisteredModels, ShowOrdersByArticleRequest, ShowOrdersByArticleResponse } from "@tm/models"
import { useHistory } from "react-router"
import { Container } from "@tm/nexus"
import { getBundleParams } from "../../utils"

type VoucherType = "cis" | "external" | "order"
type Props = {
    voucherTypeId?: CisVoucherType
    cisVoucherUrl?: string
    externalSystemId?: number
    orderNumber?: string
    orderVoucherId?: string
    variant?: "open" | "orderNumber"
    itemId?: string
    createNewWorkTaskOnClick?: boolean
    workTaskId?: string
}

export default function VoucherButton(props: Props) {
    const { externalSystemId, voucherTypeId, cisVoucherUrl, orderNumber, orderVoucherId, variant, itemId, createNewWorkTaskOnClick, workTaskId } =
        props
    const { translateText } = useLocalization()
    const { orderVoucherUrl } = getBundleParams()
    const history = useHistory()

    const { workTask, createWorkTask } = useWorkTask() ?? {}
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { externalCatalogUrl } = useExternalCatalogUrl({ externalSystemId, telesalesCustomerNo })
    const voucherUrl = externalCatalogUrl ?? cisVoucherUrl ?? orderVoucherUrl
    let voucherType: VoucherType = "order"
    if (cisVoucherUrl) {
        voucherType = "cis"
    } else if (externalCatalogUrl) {
        voucherType = "external"
    }

    const externalVoucherUrl = useReplaceUrlTags(voucherUrl, { orderId: voucherType === "external" ? orderNumber : orderVoucherId })
    const [showExternalVoucher, setShowExternalVoucher] = useState(false)
    const [loading, seLoading] = useState(false)

    const disabled = loading || (voucherType === "cis" ? !orderNumber : !orderVoucherId)

    const redirectToVoucher = useCallback(
        (id: string, subId?: string, typeId?: CisVoucherType) => {
            if (voucherUrl && workTaskId) {
                if (createNewWorkTaskOnClick && workTask) {
                    createWorkTask?.({
                        workTaskId,
                        customer: workTask.customer,
                        skipRedirect: true,
                    })
                }
                const openOrdersUrlParams = {
                    workTaskId: encodeUniqueId(workTaskId),
                    voucherTypeId: typeId,
                    id,
                    subId,
                }
                let url = renderRoute(voucherUrl, openOrdersUrlParams)
                if (voucherType === "cis") {
                    url += createQueryString({ singleSearch: true })
                }
                history.push(url)
            }
        },
        [createNewWorkTaskOnClick, createWorkTask, history, voucherType, voucherUrl, workTask, workTaskId]
    )

    function handleOpenVoucherClick() {
        if (voucherType === "external" && externalVoucherUrl) {
            setShowExternalVoucher(true)
        } else if (workTaskId) {
            if (voucherType === "cis" && !voucherTypeId && itemId) {
                seLoading(true)
                const request: ShowOrdersByArticleRequest = {
                    itemId,
                    orderNumber,
                }
                Container.getInstance<ShowOrdersByArticleResponse>(RegisteredModels.CIS_ShowOrdersByArticle)
                    .subscribe(request)
                    .load()
                    .then((response) => {
                        if (response.ordersByArticle.length) {
                            const order = response.ordersByArticle[0]
                            redirectToVoucher(order.orderNumber, order.orderSubNumber, order.positionStatusVoucherTypeId)
                        }
                    })
                    .finally(() => seLoading(false))
            } else if (voucherType === "cis" && orderNumber) {
                redirectToVoucher(orderNumber, undefined, voucherTypeId)
            } else if (voucherType === "order" && orderVoucherId) {
                redirectToVoucher(orderVoucherId)
            }
        }
    }

    return (
        <>
            {variant === "orderNumber" ? (
                <Button onClick={handleOpenVoucherClick} startIcon={<Icon name="partslink24" />} disabled={disabled} size="small">
                    {orderNumber}
                </Button>
            ) : (
                <Button disabled={disabled} onClick={handleOpenVoucherClick}>
                    {translateText(1163)}
                </Button>
            )}
            <Dialog fullWidth maxWidth="md" open={showExternalVoucher} position="top">
                <Box>
                    <Box display="flex" justifyContent="space-between" align-items="center">
                        <Box>
                            <Icon name="voucher" height="1.5em" width="1.5em" />
                            <Typography variant="h4" component="span" pl={2}>
                                {translateText(292)}
                            </Typography>
                        </Box>
                        <Button
                            startIcon={<Icon name="close" height="1.5em" width="1.5em" />}
                            variant="text"
                            onClick={() => setShowExternalVoucher(false)}
                        />
                    </Box>
                    <Box height="100vh" maxHeight="1000px">
                        <iframe src={externalVoucherUrl} title="External vouchers" style={{ height: "100%", width: "100%" }} />
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}
