import { memo } from "react"
import { Switch } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Compilation } from "../../../../../data"
import { useUpdateCompilation } from "../../../../../data/hooks/useCompilations"

export interface IProps {
    compilation: Compilation
}

export const CompilationVisibilityIndicatorNavigation = memo<IProps>(({ compilation }) => {
    const { updateCompilation } = useUpdateCompilation()
    const { translateText } = useLocalization()

    const handlePrivacyChange = () => {
        updateCompilation({
            compilationId: compilation.id,
            name: compilation.name,
            description: compilation.description,
            isPublic: !compilation.isPublic,
        })
    }

    return (
        <Switch
            disabled={!compilation.isOwn}
            label={translateText(1254)}
            status={compilation.isPublic}
            alignLabel="left"
            onChange={handlePrivacyChange}
        />
    )
})
