import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { LicensePlateField, RealTable, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { concat, plateCountryCodeMapper } from "@tm/utils"
import { style } from "typestyle"

import { Button, Icon, Tooltip } from "@tm/components"
import { DMS } from "@tm/models"
import { FC } from "react"
import { getComponentStyles, Selection } from "."
import { getCustomerNameAndCompany, getVehicleName } from "../../helpers"

type Props = {
    customers: Array<DMS.Customer>
    onCustomerSelect?(customer: DMS.Customer): void
    loading?: boolean
    label?: string
    noItemsMessage?: string
    onCustomerAndVehicleAttach?(customer: DMS.Customer, vehicle?: DMS.Vehicle): void
    onLoadNextCustomers?(): void
}

export const CustomerSelection: FC<Props> = (props) => {
    const className = style(getComponentStyles())
    const { translate } = useLocalization()
    const { plateCode } = useCountryCodeToLicensePlate()

    const renderNameAndCompany = (customer: DMS.Customer) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text>{getCustomerNameAndCompany(customer)}</Text>
            </RealTable.Cell>
        )
    }

    const renderStreet = (customer: DMS.Customer) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{customer.generalAddress?.street}</Text>
            </RealTable.Cell>
        )
    }

    const renderCity = (customer: DMS.Customer) => {
        return (
            <RealTable.Cell className={`${className}__cell`}>
                <Text size="s">{customer.generalAddress && concat(" ", customer.generalAddress.zip, customer.generalAddress.city)}</Text>
            </RealTable.Cell>
        )
    }

    const renderVehicleName = (customer: DMS.Customer) => {
        const { vehicles } = customer

        return (
            <RealTable.Cell className={`${className}__cell`}>{vehicles?.[0] && <Text size="s">{getVehicleName(vehicles[0])}</Text>}</RealTable.Cell>
        )
    }

    const renderLicensePlate = (customer: DMS.Customer) => {
        const { vehicles } = customer

        return (
            <RealTable.Cell className={`${className}__cell`}>
                {vehicles?.[0]?.registrationInformation?.plateId && (
                    <LicensePlateField
                        readonly
                        value={vehicles[0].registrationInformation.plateId}
                        shortCountryCode={plateCountryCodeMapper(vehicles[0].countryCode || plateCode)}
                    />
                )}
            </RealTable.Cell>
        )
    }

    const renderActions = (customer: DMS.Customer) => {
        const attachCustomer = (e: React.MouseEvent) => {
            e.stopPropagation()
            props.onCustomerAndVehicleAttach?.(customer)
        }
        const attachCustomerAndVehicle = (e: React.MouseEvent) => {
            e.stopPropagation()
            props.onCustomerAndVehicleAttach?.(customer, customer.vehicles?.[0])
        }

        return (
            <RealTable.Cell className={`${className}__cell`}>
                <div className={`${className}__actions`}>
                    {props.onCustomerAndVehicleAttach && (
                        <>
                            <Tooltip title={translate(12811)}>
                                <Button
                                    sx={{ width: "32px", height: "32px", mr: "5px" }}
                                    startIcon={<Icon style={{ width: "24px", height: "24px" }} name="plus" />}
                                    onClick={attachCustomer}
                                />
                            </Tooltip>
                            <Tooltip title={translate(13830)}>
                                <Button
                                    sx={{ width: "32px", height: "32px" }}
                                    startIcon={<Icon style={{ width: "24px", height: "24px" }} name="worktask-with-vehicle" />}
                                    onClick={attachCustomerAndVehicle}
                                />
                            </Tooltip>
                        </>
                    )}
                </div>
            </RealTable.Cell>
        )
    }

    const getTableColumns = () => {
        return [
            <RealTable.Column key="name" renderItemContent={renderNameAndCompany} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(155)} / {translate(108)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="street" renderItemContent={renderStreet} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(111)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="city" renderItemContent={renderCity} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(112)}, {translate(113)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="vehicle" renderItemContent={renderVehicleName} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(99)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column key="plate" renderItemContent={renderLicensePlate} className={`${className}__column`}>
                <Text size="xs" modifiers="sub" className={`${className}__header`}>
                    {translate(21)}
                </Text>
            </RealTable.Column>,
            <RealTable.Column
                key="actions"
                renderItemContent={renderActions}
                className={`${className}__column ${className}__column--align-right ${className}__column--nowrap`}
            />,
        ]
    }

    return (
        <Selection
            className={className}
            items={props.customers}
            columns={getTableColumns()}
            onSelect={props.onCustomerSelect}
            loading={props.loading}
            label={props.label}
            noItemsMessage={props.noItemsMessage}
            onLoadNextItems={props.onLoadNextCustomers}
        />
    )
}
