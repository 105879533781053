import { useLocalization } from "@tm/localization"
import { CisVoucherType } from "@tm/models"
import { Box, Icon, Stack, Button, Grid, Typography } from "@tm/components"
import { OrderDetails } from "../../../../../data/model"
import { OrderDetailsField } from "../../../../_shared/OrderDetailsField"
import { OrderDetailsBox } from "../../../../_shared/StyledComponents"
import { OrderDetailsWarehouseField } from "../../../../_shared/OrderDetailsWarehouseField"

type Props = {
    orderDetails: OrderDetails
    onPrintButtonClick(orderNumber: string, orderSubNumber?: string, voucherId?: string, warehouseId?: string, voucherTypeId?: CisVoucherType): void
}

export default function StgReturnVoucherDetailsHeaderComponent({ orderDetails, onPrintButtonClick }: Props) {
    const {
        voucherId,
        warehouseId,
        orderNumber,
        orderDate,
        orderSubNumber,
        orderTypeDescription,
        warehouseName1,
        warehouseStreet,
        warehousePostalCode,
        warehouseCity,
    } = orderDetails
    const { date, translateText } = useLocalization()

    function handlePrintButtonClick() {
        if (orderNumber) {
            onPrintButtonClick(orderNumber, orderSubNumber, voucherId, warehouseId, CisVoucherType.Returns)
        }
    }

    return (
        <Stack direction="column" alignItems="flex-end">
            <Box>
                <Button startIcon={<Icon name="print" />} color="primary" onClick={handlePrintButtonClick} title={translateText(13164)} />
            </Box>
            <Grid container rowSpacing={1} columnSpacing={2} my={1} columns={4}>
                {/* First Row */}
                <Grid item xs={1}>
                    <OrderDetailsField value={voucherId} label={translateText(700)} />
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField
                        value={orderDate && `${date(orderDate, "d")} - ${date(orderDate, "t")} ${translateText(12808)}`}
                        label={`${translateText(776)}  -  ${translateText(335)}`}
                    />
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} />
                {/* Second Row */}
                <Grid item xs={1}>
                    {warehouseId && (
                        <OrderDetailsWarehouseField
                            warehouseName={warehouseName1}
                            warehouseStreet={warehouseStreet}
                            warehousePostalCode={warehousePostalCode}
                            warehouseCity={warehouseCity}
                        />
                    )}
                </Grid>
                <Grid item xs={1}>
                    <OrderDetailsField value={orderTypeDescription} label={translateText(777)} />
                </Grid>
            </Grid>
        </Stack>
    )
}
