import { CustomWork, PanelState } from "../../../models"

export function getInitialCustomWorks(): Array<CustomWork> {
    return [
        {
            id: 0,
            providerId: "181",
            title: [612, 48],
            initiallyClosed: false,
            isCompleted: false,
            panelState: PanelState.None,
            location: "Check work",
        },
        {
            id: 1,
            providerId: "390",
            title: [12966],
            initiallyClosed: true,
            isCompleted: false,
            panelState: PanelState.None,
            location: "Check work",
        },
        {
            id: 2,
            title: [12953],
            initiallyClosed: true,
            isCompleted: false,
            panelState: PanelState.None,
            location: "Check work",
        },
    ]
}
