import { useLocalization } from "@tm/localization"
import { ConfigParams } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { SubUser, PermissionContainer } from "../../../data/model"
import SubUserPermissionGroup from "./SubUserPermissionGroup"

type Props = {
    permissionContainer?: PermissionContainer
    user: SubUser
    setUser(user: SubUser): void
}

export default function SubUserPermissions(props: Props) {
    const { translateText } = useLocalization()
    const { catalogName } = Morpheus.getParams<ConfigParams>()

    const { permissionContainer, user, setUser } = props

    function renderAppOnlyRights() {
        if (permissionContainer?.appPermissions?.length) {
            const title = translateText(13591).replaceAll("{0}", catalogName)
            return <SubUserPermissionGroup title={title} permissions={permissionContainer.appPermissions} user={user} setUser={setUser} />
        }
    }

    function renderCatalogOnlyRights() {
        if (permissionContainer?.catalogPermissions?.length) {
            const title = catalogName
            return <SubUserPermissionGroup title={title} permissions={permissionContainer.catalogPermissions} user={user} setUser={setUser} />
        }
    }

    function renderAppAndCatalogRights() {
        if (permissionContainer?.appAndCatalogPermissions?.length) {
            const title = translateText(13590).replaceAll("{0}", catalogName)
            const hint = translateText(13589).replaceAll("{0}", catalogName)
            return (
                <SubUserPermissionGroup
                    title={title}
                    permissions={permissionContainer.appAndCatalogPermissions}
                    hint={hint}
                    user={user}
                    setUser={setUser}
                />
            )
        }
    }

    return (
        <>
            {renderAppAndCatalogRights()}
            {renderCatalogOnlyRights()}
            {renderAppOnlyRights()}
        </>
    )
}
