import { Box, ScrollContainer, Skeleton, Typography, LoadingContainer, styled } from "@tm/components"
import { useEffect, useRef, useState } from "react"
import { useLocalization } from "@tm/localization"
import { useWorkTask } from "@tm/context-distribution"
import JobListItem from "./JobListItem"
import { mainActions } from "../../../data/state"
import { useGetJobList } from "../../../data/hooks"
import JobListError from "./JobListError"

type Props = {
    errorSearchQuery?: string
}

export default function JobList({ errorSearchQuery }: Props) {
    const { translateText } = useLocalization()
    const { workTask } = useWorkTask()!
    const [selectedRow, setSelectedRow] = useState<string>("")

    const { jobs, isLoading, isNextPageLoading, loadNextPage, isError, isSindriError, errorReason } = useGetJobList(
        workTask?.vehicle?.vin,
        errorSearchQuery
    )
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ref = useRef<any>(null)

    function handleSelectAccordion(jobId: string) {
        setSelectedRow((prevJobId) => {
            if (prevJobId === jobId) {
                return ""
            }

            return jobId
        })
    }

    useEffect(() => {
        if (isLoading) {
            return
        }

        if (jobs && jobs?.length > 0) {
            mainActions.setSelectedJob(jobs[0].uid)
        }
    }, [jobs, isLoading])

    useEffect(() => {
        if (isLoading || isNextPageLoading || isError) {
            return
        }

        if (ref?.current) {
            const { scrollHeight, clientHeight } = ref.current

            if (clientHeight >= scrollHeight) {
                loadNextPage?.()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, isLoading, isNextPageLoading])

    return (
        <LoadingContainer isLoading={isLoading} display="flex" flexDirection="column" gap="0.5em" flex="1" overflow="auto">
            <StyledListHeader>
                <div />
                <ListHeaderText variant="body3">{translateText(98)}</ListHeaderText>
                <ListHeaderText variant="body3">{translateText(125)}</ListHeaderText>
                <ListHeaderText variant="body3">{translateText(13528)}</ListHeaderText>
                <ListHeaderText variant="body3">{translateText(212)}</ListHeaderText>
                <ListHeaderText variant="body3">{translateText(13529)}</ListHeaderText>
                <div />
            </StyledListHeader>
            <ScrollContainer
                ref={ref}
                sx={{ display: "flex", flexDirection: "column", gap: "0.5em", flex: "1", overflow: "auto" }}
                onScrollBottom={() => loadNextPage?.()}
            >
                {isError ? (
                    <JobListError errorReason={errorReason} isSindriError={isSindriError} />
                ) : (
                    <>
                        {jobs?.map((job, index) => {
                            return (
                                <JobListItem
                                    key={job.uid}
                                    job={job}
                                    onRowClick={handleSelectAccordion}
                                    expanded={selectedRow === job.uid}
                                    isEvenRow={index % 2 === 0}
                                />
                            )
                        })}
                    </>
                )}
            </ScrollContainer>
            {isNextPageLoading && <Skeleton height={54} width="100%" />}
        </LoadingContainer>
    )
}

const ListHeaderText = styled(Typography)({
    textTransform: "uppercase",
})

const StyledListHeader = styled(Box)({
    marginBottom: "0.5em",
    display: "grid",
    gridTemplateColumns:
        "50px minMax(200px, 1fr) minMax(200px, 1fr) minMax(200px, 1fr) minMax(200px, 1fr) minMax(200px, 1fr) minMax(100px, 0.5fr) 10px",
})
