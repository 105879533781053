import { useEffect } from "react"
import { RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { Link } from "react-router-dom"
import { useLocalization } from "@tm/localization"
import { Text, Icon } from "@tm/controls"
import { ECounterType } from "@tm/models"
import Logo from "./logo"
import Home from "./logo.home"

type Props = RouteComponentProps<void> & {
    url: string
    title?: string
    logo?: string
    className?: string
}

function StartPageButtonComponent({ url, title, logo, className, history }: Props) {
    const { translateText } = useLocalization()

    useEffect(() => {
        const handleKeyUp = (e: KeyboardEvent) => {
            if ((e.which || e.keyCode) === 113) {
                // F2
                history.push(url)
            }
        }
        document.addEventListener("keyup", handleKeyUp)
        return () => {
            document.removeEventListener("keyup", handleKeyUp)
        }
    }, [])

    function renderTitle() {
        if (!title) {
            return null
        }

        return (
            <Text size="m" className="startpage-button__title">
                {/^\d+/.test(title) ? translateText(title) : title}
            </Text>
        )
    }

    let newClassName = `tab tab--worktask startpage-button tab--center ${className || ""}`
    if (history.location.pathname === url) {
        newClassName += " is-selected"
    }

    let ShowLogo = Logo
    switch (logo) {
        case "home": {
            ShowLogo = Home
            break
        }
        default: {
            if (logo) {
                ShowLogo = () => <Icon name={logo} size="l" />
            }
            break
        }
    }

    return (
        <Link
            to={url}
            className={newClassName}
            onClick={() => {
                TmaHelper.GeneralCountEvent.Call(ECounterType.StartPage)
            }}
        >
            <div className="tab__content">
                <div className="startpage-button__icon">
                    <ShowLogo />
                </div>
                {title && renderTitle()}
            </div>
        </Link>
    )
}

export default withRouter(StartPageButtonComponent)

/**
 * better use this with trader styled themes

function StartPageButtonComponent({ url, title, logo, className, history }: Props) {
    ....
    const isActive = history.location.pathname === url
    const handleClick = useCallback(() => {
        isActive ? history.goBack() : history.push(url)
        TmaHelper.GeneralCountEvent.Call(ECounterType.StartPage)
    }, [isActive, url])

    return (
        <GlobalNavigationItem
            active={isActive}
            name={logo || "home"}
            subtitle={title && /^\d+/.test(title) ? translateText(title) : title}
            onIconClick={handleClick}
        >
            <ShowLogo />
        </GlobalNavigationItem>
    )
}

 */
