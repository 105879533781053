import { channel } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { History } from "history"
import { encodeUniqueId, uniqueId } from "@tm/utils"
import {
    NavigationState,
    ComponentActionType,
    doWorkTaskLoaded,
    doModuleChanged,
    doWorkTaskIdChanged,
    doPageOpened,
    setAfterLoadHook,
    actionsAfterLoaded,
} from "."

export function subscribe(history: History): AsyncAction<ComponentActionType, NavigationState> {
    return (dispatch, getState) => {
        function changeUrlAction(): ComponentActionType {
            return {
                type: "CHANGE_URL",
                payload: `${history.location.pathname}${history.location.search}`,
            }
        }
        const unsubHistory = history.listen(() => dispatch(changeUrlAction()))

        const globalChannel = channel("GLOBAL")

        let workTaskUnsubs: Function[] = []
        function handleWorkTaskIdChanged(ids: { id?: string; previousId?: string }) {
            ;[...workTaskUnsubs].forEach((unsub) => unsub())
            workTaskUnsubs = []
            if (ids.id) {
                workTaskUnsubs.push(
                    channel("WORKTASK", ids.id).subscribe("WORKTASK/LOADED", (data) => dispatch(doWorkTaskLoaded(data, history)), true)
                )
                workTaskUnsubs.push(
                    channel("WORKTASK", ids.id).subscribe(
                        "MODULE/CHANGED",
                        (data) => {
                            const { loading } = getState()
                            if (loading) {
                                actionsAfterLoaded.push((dispatch) => dispatch(doModuleChanged(data)))
                            } else {
                                dispatch(doModuleChanged(data))
                            }
                        },
                        true
                    )
                )
                dispatch(doWorkTaskIdChanged(ids.id, history))
            }
        }

        function openNew(state?: NavigationState) {
            const emptyItem = state?.items.find((x) => !(x.customer || x.page || x.vehicle || x.voucher))
            if (emptyItem) {
                history.push(`/${encodeUniqueId(emptyItem.id)}`)
            } else {
                history.push(`/${encodeUniqueId(uniqueId())}`)
            }
        }

        function handleOpenNew({ openExisting }: { openExisting: boolean }) {
            if (openExisting) {
                const state = getState()

                if (state.loading) {
                    dispatch(setAfterLoadHook({ hook: openNew, once: true }))
                } else {
                    openNew(state)
                }
            } else {
                openNew()
            }
        }

        function handleDeleteWorktask({ id }: { id: string }) {
            const state = getState()
            dispatch({ type: "REMOVE_ITEMS", payload: [id] })
            if (state.activeItem == id) {
                history.push(`/`)
            }
        }

        const globalUnsubs = [
            globalChannel.subscribe("WORKTASK/ID_CHANGED", handleWorkTaskIdChanged, true),
            globalChannel.subscribe("PAGE/OPENED", (data) => dispatch(doPageOpened(data, history)), true),
            globalChannel.subscribe("WORKTASK/OPEN_NEW", handleOpenNew, true),
            globalChannel.subscribe("WORKTASK/DELETED", handleDeleteWorktask),
        ]
        return () => {
            ;[...workTaskUnsubs, ...globalUnsubs, unsubHistory].forEach((unsubscribe) => unsubscribe())
        }
    }
}
