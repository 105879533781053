import { useEffect, useMemo, useState } from "react"

export function useHasLoadingEnded(...states: (boolean | undefined)[]) {
    const filteredStates = useMemo(() => states.filter((x) => x !== undefined), [states])
    const [hasStarted, setHasStarted] = useState(filteredStates.every((x) => x))
    const [hasEnded, setHasEnded] = useState(false)

    useEffect(() => {
        if (!hasStarted && !filteredStates.every((x) => x)) {
            setHasStarted(true)
        }
    }, [filteredStates, hasStarted])

    useEffect(() => {
        setHasEnded((prev) => (!prev && hasStarted && filteredStates.every((x) => !x)) || prev)
    }, [filteredStates, hasStarted])

    return hasEnded
}
