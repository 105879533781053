import { getModuleFromUserContext, getParameterFromUserContextModule, useUser, useWorkTask } from "@tm/context-distribution"
import { CategoryType, UserModuleType } from "@tm/models"
import { merge } from "lodash"
import { useMultiWidgetTabsModules } from "../../../hooks/useMultiWidgetTabsModules"
import { AllWidgetKeys, Overrides, WidgetProp } from "../models"
import { useOtherModules } from "../../../hooks/useOtherModules"
import { IntegrationAreas } from "../../../../../plugins/should-render-by-mdm-module"

const defaultOverrides: Partial<Record<AllWidgetKeys, WidgetProp>> = {
    catalogSmallWidget: {
        subModules: {
            vehicleParts: {
                hidden: true,
            },
        },
        custom: {
            universalSearchButton: "smallLeft",
        },
    },
}
export function useStartTemplateFive(overrides?: Overrides) {
    const workTask = useWorkTask()
    const user = useUser()
    const userContext = user?.userContext

    const finalOverrides = merge(defaultOverrides, overrides)

    const { multiWidgetTabs } = useMultiWidgetTabsModules(userContext, "START", finalOverrides)

    function getShown(widgetKey: AllWidgetKeys): boolean {
        return !finalOverrides?.[widgetKey]?.hidden
    }

    const otherModules = useOtherModules("START", !!userContext, () => {
        const tyresModule = getModuleFromUserContext(userContext, UserModuleType.TMTires)

        const offersIntegrationParameter = getParameterFromUserContextModule(userContext, UserModuleType.Offers, "Integration")
        const displayOffersWidget =
            offersIntegrationParameter === IntegrationAreas.STARTANDDASHBOARD || offersIntegrationParameter === IntegrationAreas.STARTPAGE

        const hideCategories: CategoryType[] = []

        if (finalOverrides?.catalogSmallWidget?.subModules) {
            const catalogSmallWidgetCategories = finalOverrides.catalogSmallWidget.subModules

            Object.keys(catalogSmallWidgetCategories)?.forEach((key) => {
                if (catalogSmallWidgetCategories[key]?.hidden) {
                    hideCategories.push(key as CategoryType)
                }
            })
        }

        return {
            tyres: !!tyresModule,
            hideCategories,
            cisWidget: getShown("cisWidget"),
            cisCreditWidget: getShown("cisCreditWidget"),
            offersWidget: getShown("offersWidget") && displayOffersWidget,
            iframeWidget: getShown("iframeWidget"),
        }
    })

    return {
        workTaskId: workTask?.workTaskId,
        multiWidgetTabs,
        otherModules,
        finalOverrides,
    }
}
