import { useUser } from "@tm/context-distribution"
import { useState, useEffect, useCallback, Suspense } from "react"
import { useOwnUserId, Message, useSignalRMessage, useInitalizeChatStorage } from "../../data/hooks"
import { useOwnDisplayName } from "../../data/hooks/useOwnDisplayName"
import NotificationCenter from "./components/notification-center"
import { FastCheckReceiver } from "./components/fast-check-receiver"
import { MessageUser } from "./components/message-user"

function Content() {
    const { handleReceiveMessageChatStorage } = useInitalizeChatStorage()
    const ownUserId = useOwnUserId()
    const { displayName, updateDisplayName } = useOwnDisplayName()
    const userSettingsCompanyName = useUser()?.userSettings?.repairShop?.companyName
    const [message, setMessage] = useState<Message>()

    useEffect(() => {
        if (message) {
            const timeout = setTimeout(() => {
                setMessage(undefined)
            }, 5000)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [message])

    useEffect(() => {
        if (displayName && userSettingsCompanyName && displayName !== userSettingsCompanyName) {
            updateDisplayName(userSettingsCompanyName)
        }
    }, [displayName, updateDisplayName, userSettingsCompanyName])

    const handler = useCallback(
        (m: Message) => {
            handleReceiveMessageChatStorage(m)
            if (m.authorId !== ownUserId) {
                setMessage(m)
            }
        },
        [ownUserId, handleReceiveMessageChatStorage]
    )

    useSignalRMessage(handler)

    return (
        <>
            <NotificationCenter />
            {!!message && <MessageUser message={message} setMessage={setMessage} />}
            <FastCheckReceiver />
        </>
    )
}

export default function Wrapper() {
    return (
        <Suspense fallback={null}>
            <Content />
        </Suspense>
    )
}
