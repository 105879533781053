import { Vehicle } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { clone } from "@tm/utils"
import { BundleActionTypes } from "../../../business"
import { SummaryState } from "../../../components/summary/business"
import * as Repositories from "../../../data"
import { Statics } from "../../../data/helpers"
import { ErePosition, MmtGroup } from "../../../data/models"
import { SearchMicroState } from "./model"


export type ComponentActionType = BundleActionTypes
    | { type: "EUROTAX_CARS_LOADING" }
    | { type: "EUROTAX_CARS_ERROR" }
    | { type: "EUROTAX_CARS_LOADED", payload: { groups: MmtGroup[], usedTecDoc: number } }

    | { type: "SELECT_ERE_POSITION", payload: { position: ErePosition, group?: MmtGroup } }

const DEFAULT_STATE: SummaryState = {
    cars: {
        groups: []
    }
}

export function reduce(state = clone(DEFAULT_STATE), action: ComponentActionType): SearchMicroState {
    switch (action.type) {
        case "EUROTAX_CARS_LOADING": {
            return {
                ...state,
                cars: {
                    ...state.cars,
                    groups: [],
                    loading: true,
                    error: false
                }
            }
        }
        case "EUROTAX_CARS_ERROR": {
            return {
                ...state,
                cars: {
                    ...state.cars,
                    groups: [],
                    loading: false,
                    error: true
                },
            }
        }
        case "EUROTAX_CARS_LOADED": {

            const selectedPosition = !state.selectedMmtGroup?.selectedErePosition && action.payload.groups.length == 1 && action.payload.groups[0].positions.find(x => x.parentName == Statics.defaultGraphicParts)
            return {
                ...state,
                cars: {
                    ...state.cars,
                    groups: action.payload.groups,
                    usedTecDoc: action.payload.usedTecDoc,
                    loading: false,
                    error: false
                },
                ...selectedPosition && {
                    selectedMmtGroup: {
                        ...action.payload.groups[0],
                        selectedErePosition: selectedPosition
                    }
                }
            }
        }
        case "SELECT_ERE_POSITION": {
            const { group, position } = action.payload
            const usedGroup = group ?? state.selectedMmtGroup
            return {
                ...state,
                ...usedGroup && {
                    selectedMmtGroup: {
                        ...usedGroup,
                        selectedErePosition: position
                    }
                },

            }
        }
        case "VEHICLE_SET": {
            const { vehicle } = action.payload
            return {
                ...state,
                vehicle
            }
        }
    }
    return state
}




function loadEurotaxCars(): AsyncAction<ComponentActionType, SearchMicroState> {
    return (dispatch, getState) => {
        const { cars, vehicle } = getState()
        if (vehicle && vehicle.tecDocTypeId == cars.usedTecDoc)
            return


        if (vehicle && vehicle.tecDocTypeId) {
            dispatch({ type: "EUROTAX_CARS_LOADING" })
            Repositories.getCarInfo(vehicle.tecDocTypeId).then(
                response => dispatch({ type: "EUROTAX_CARS_LOADED", payload: { groups: response.mmtGroups, usedTecDoc: vehicle.tecDocTypeId } }),
                _error => dispatch({ type: "EUROTAX_CARS_ERROR" })
            )
        }
    }
}



function selectErePosition(group: MmtGroup, position: ErePosition): ComponentActionType {
    return { type: "SELECT_ERE_POSITION", payload: { group, position } }
}


const setVehicle = (vehicle: Vehicle, isFirstUpdate?: boolean): ComponentActionType =>
    ({ type: "VEHICLE_SET", payload: { vehicle, isFirstUpdate } })


export type IActions = typeof Actions

export const Actions = {
    loadEurotaxCars,
    selectErePosition,
    setVehicle
}