import { PropsWithChildren, ReactNode, useMemo } from "react"
import { classes, getStyleTheme, useStyle } from "@tm/context-distribution"
import { Collapsible, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Box } from "@tm/components"

type Props = {
    textId: number
    isDisabled?: boolean
    icon?: ReactNode
}

export default function CollapsibleWrapper({ children, textId, isDisabled, icon }: PropsWithChildren<Props>) {
    const { translateText } = useLocalization()
    const style = useMemo(() => getStyle(), [])

    const renderCollapseHeader = () => {
        const headerText = (
            <Text size="xl" className={style.paddingText} modifiers="strong">
                {translateText(textId)}
            </Text>
        )

        if (icon) {
            return (
                <Box display="flex" alignItems="center" gap="1em">
                    {icon}
                    {headerText}
                </Box>
            )
        }

        return headerText
    }

    return (
        <Collapsible
            className={classes(style.collapse, isDisabled && style.disabled)}
            layout="holo"
            renderHeaderAppendix={renderCollapseHeader}
            initiallyOpened={!isDisabled}
            disabled={isDisabled}
        >
            {children}
        </Collapsible>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        collapse: {
            marginTop: theme.margin.m,
            $nest: {
                ".collapsible__caption": {
                    display: "none",
                },
                ".collapsible__header": {
                    paddingLeft: 0,
                    alignItems: "center",
                },
                ".collapsible__header .icon": {
                    order: 2,
                },
            },
        },
        disabled: {
            $nest: {
                ".collapsible__header": {
                    opacity: ".5",
                },
            },
        },
        paddingText: {
            paddingRight: theme.margin.xs,
        },
    })(CollapsibleWrapper)
}
