import { Dialog, Stack, Typography, Button, Loader, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { StackRow } from "../../../StyledComponents"
import { BasketOrderGroup } from "../../../../models"
import { useGetExternalOrderUrl } from "../hooks/useGetExternalOrderUrl"

type Props = {
    showExternalOrderDialog: boolean
    loadingExternalSystem: boolean
    replacedOeExternalCatalogUrl?: string
    replacedExternalCatalogUrl?: string
    orderGroups?: BasketOrderGroup[]
    onCloseDialog(): void
    onExternalSystemLoaded(): void
}

export default function ExternalOrderDialog(props: Props) {
    const {
        showExternalOrderDialog,
        loadingExternalSystem,
        replacedOeExternalCatalogUrl,
        replacedExternalCatalogUrl,
        orderGroups,
        onCloseDialog,
        onExternalSystemLoaded,
    } = props
    const { translateText } = useLocalization()
    const getExternalOrderUrl = useGetExternalOrderUrl(replacedExternalCatalogUrl, orderGroups)

    return (
        <Dialog open={showExternalOrderDialog} position="top" fullScreen PaperProps={{ sx: { width: 1200 } }}>
            <Stack>
                <StackRow justifyContent="space-between">
                    <StackRow spacing={1} alignItems="center">
                        <Icon name="orders" />
                        <Typography variant="h2">{translateText(replacedOeExternalCatalogUrl ? 12639 : 292)}</Typography>
                    </StackRow>
                    <Button startIcon={<Icon name="close" />} variant="text" onClick={onCloseDialog} />
                </StackRow>
                <div>
                    {loadingExternalSystem && <Loader />}
                    <iframe
                        title="external-url"
                        style={{ width: "100%", height: "90vh" }}
                        src={replacedOeExternalCatalogUrl || getExternalOrderUrl()}
                        onLoad={onExternalSystemLoaded}
                    />
                </div>
            </Stack>
        </Dialog>
    )
}
