import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function alldataFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const alldataModule = getModuleFromUserContext(userContext, UserModuleType.TechDataAllData)

    if (!alldataModule) {
        return undefined
    }

    return {
        tech: {
            items: {
                alldata: {
                    sort: 2,
                    name: "ALLDATA",
                    active: true,
                    route: "/:workTaskId/td/alldata",
                    disabledFunction: "TECHDATA-ALLDATA-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        technicalData: "alldata",
                    },
                },
            },
        },
    }
}
