import { OE, RequestArticleListPayload, SearchFilters } from "@tm/models"
import { PartV2Dto } from "../../models"
import { useEdsStore } from "../state"

export function createArticleListRequest(selectedPart: PartV2Dto | undefined, parts: PartV2Dto[]): RequestArticleListPayload | undefined {
    const vehicle = useEdsStore.getState()?.vehicle?.data?.yqVehicle

    if (!selectedPart || !vehicle || !parts?.length) {
        return
    }

    const oePositions: OE.OePosition[] = []

    oePositions.push(mapOePosition(selectedPart, true))

    parts.forEach((part) => {
        if (part.areaCode === selectedPart.areaCode && part !== selectedPart) {
            oePositions.push(mapOePosition(part, false))
        }
    })

    return {
        direct: {
            query: selectedPart.partNumber,
            oeManufacturerIds: [vehicle.manufacturerId],
            searchFilter: SearchFilters.OeReference,
            oeInformationWithoutCar: true,
        },
        inModal: true,
        oePositions,
        extendedAssortment: true,
        hideOePrice: true,
    }
}

function mapOePosition(part: PartV2Dto, isSelected: boolean): OE.OePosition {
    return {
        number: part.partNumber,
        description: "",
        additionalDescription: "",
        parts: [],
        replacements: [],
        isSelected,
    }
}
