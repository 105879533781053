import { GetMemoTechnicalInfoRequest, GetMemoTechnicalInfoResponse, TechnicalNote } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getServiceUrl } from "../params"

export async function getMemoTechnicalInfo(body: GetMemoTechnicalInfoRequest): Promise<TechnicalNote[] | undefined> {

    const url = `${getServiceUrl()}/GetTechnicalNotes`
    const authorization = getStoredAuthorization()

    return ajax<GetMemoTechnicalInfoResponse>({ url, body, authorization, method: "GET" }).then(x => x?.technicalNotes)
}
