import { PayloadAction, AsyncAction, ActionDispatch } from "@tm/morpheus"
import { ComponentState } from "./model"
import { BundleActionType } from "../../../business"
import { CustomerRepository } from "../../../data"

export * from "./model"

export type ComponentActionType = BundleActionType

const DEFAULT_STATE: ComponentState = {}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ComponentState {
    switch (action.type) {
        case "VIEW": {
            return {
                ...state,
                view: action.payload.view,
                selectedId: action.payload.id,
            }
        }
        case "CUSTOMER_DETAILS": {
            return {
                ...state,
                customerDetails: action.payload,
            }
        }
        case "CUSTOMER_DELETED": {
            return {
                ...state,
                customerDetails: undefined,
            }
        }
        default:
            break
    }

    return state
}

export function transmit(action: PayloadAction<ComponentActionType>) {
    switch (action.type) {
        case "CUSTOMER_DELETED": {
            return action
        }
        default:
            break
    }
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "CUSTOMER_DETAILS":
        case "VIEW": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

function deleteCustomer(): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const { customerDetails } = getState()
        if (customerDetails) {
            CustomerRepository.hideCustomer(customerDetails.id).then(() =>
                dispatch({
                    type: "CUSTOMER_DELETED",
                    payload: customerDetails,
                })
            )
        }
    }
}

export type IActions = typeof Actions

export const Actions = {
    deleteCustomer,
}
