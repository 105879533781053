import { Stack, Tooltip } from "@tm/components"
import { concat } from "@tm/utils"
import { OverflowTypography } from "../../../../../_shared/StyledComponents"

type Props = {
    additionalDescription?: string
    description: string
    productGroupName?: string
    isBigScreen: boolean
    isLinkedItem?: boolean
}

export default function ArticleDescription({ description, productGroupName, additionalDescription, isBigScreen, isLinkedItem }: Props) {
    function renderContent() {
        return (
            <>
                {isLinkedItem && (
                    <OverflowTypography variant="body2" fontWeight="bold">
                        {description}
                    </OverflowTypography>
                )}
                <OverflowTypography variant="body2">{productGroupName}</OverflowTypography>
                <OverflowTypography variant="body2">
                    {isLinkedItem ? additionalDescription : concat(" ", description, additionalDescription)}
                </OverflowTypography>
            </>
        )
    }
    return isBigScreen ? (
        <Stack>{renderContent()}</Stack>
    ) : (
        <Tooltip title={renderContent()} variant="light">
            <Stack width="50px" whiteSpace="nowrap">
                {renderContent()}
            </Stack>
        </Tooltip>
    )
}
