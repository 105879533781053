import { TelematicsBundleParams } from "@tm/models"

export const version = {
    name: "telematics",
    version: "2.0",
}

let bundleParams: TelematicsBundleParams

export function initTelematicsBundle(params: TelematicsBundleParams) {
    bundleParams = params
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}
