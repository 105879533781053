import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
        },
        canvasWrapper: {
            alignSelf: "center",
            margin: margin.xl,
        },
        loaderWrapper: {
            display: "flex",
            alignSelf: "center",
            alignItems: "center",
            fontSize: "1.5rem",
        },
        loaderUploadText: {
            marginRight: margin.xl,
        },
        button: {
            padding: ".8rem 1rem",
        },
        toolbarWrapper: {
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
        },
        toolbarLink: {
            cursor: "pointer",
            lineHeight: "1.2",
            padding: ".4em",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
