import { BundleComponent } from "@tm/morpheus"
import { reduce, receive } from "./business"
import component from "./component"

const documents: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "details-documents",
    reduce,
    component,
    receive,
}

export default documents
