import { Container } from "@tm/nexus"

import { PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"

export default function handleLogout(this: PostMessageControllerComponent, data: PostMessageRequest) {
    if (!data.logout) {
        return
    }
    Container.getInstance("Portal").callAction("logout", data.logout.redirectUrl || undefined)
}
