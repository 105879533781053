import { Table, Button } from "@tm/controls"
import { renderRoute } from "@tm/utils"
import { style } from "typestyle"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../../utils"
import { useChatList, ChatInfo, useLastReadInfoDate } from "../../../data/hooks"
import PreviewText from "../../_shared/preview-text"
import NewMessageBadge from "../../_shared/new-message-badge"

const { Column, Cell } = Table

export function Content() {
    const list = useChatList()
    const { translateText } = useLocalization()
    const { lastReadInfoDate } = useLastReadInfoDate()

    if (!list?.length) {
        return null
    } // TODO: show nice message

    const url = renderRoute(getBundleParams().chatDetailsRoute, {})

    const isNew = (chat: ChatInfo) => chat.lastMessage?.inserted && lastReadInfoDate && new Date(chat.lastMessage.inserted) > lastReadInfoDate

    const columns = [
        <Column
            key="NewMessageBadge"
            renderItemContent={(item: ChatInfo) => (
                <Cell>
                    <NewMessageBadge isNew={!!isNew(item)} />
                </Cell>
            )}
        />,
        <Column
            key="PreviewText"
            className={style({ flex: 1, textAlign: "left", overflow: "hidden" })}
            renderItemContent={(item: ChatInfo) => (
                <Cell>
                    <PreviewText chat={item} size="s" bold={!!isNew(item)} />
                </Cell>
            )}
        />,
        <Column
            key="DetailsButton"
            renderItemContent={(item: ChatInfo) => (
                <Cell>
                    <Button size="s" linkTo={renderRoute(getBundleParams().chatDetailsRoute, { chatId: item.chatId })}>
                        {translateText(3079)}
                    </Button>
                </Cell>
            )}
        />,
    ]

    return (
        <>
            <div className={style({ width: "100%", overflowY: "auto", flex: 1 })}>
                <Table scrollable data={list} columns={columns} />
            </div>
            <div className={style({ marginTop: "0.2rem" })}>
                <Button linkTo={url}>{translateText(409)}</Button>
            </div>
        </>
    )
}
