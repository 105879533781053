import { getStoredAuthorization, ajax } from "@tm/utils"
import { getBundleParams } from "../../utils"
import { ExternalDocument } from "../model"

function getServiceUrl(): string {
    const bundleParams = getBundleParams()
    return bundleParams.repairShopServiceUrl
}

export function getExternalSystemDocuments(): Promise<Array<ExternalDocument>> {
    const url = `${getServiceUrl()}/GetExternalSystemDocuments`
    const authorization = getStoredAuthorization()
    const body = {}

    return new Promise<Array<ExternalDocument>>((resolve, reject) =>
        ajax({ url, body, authorization }).then((data) => resolve(data?.externalDocuments), reject)
    )
}
