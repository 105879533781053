import { BundleComponent } from "@tm/morpheus"
import { reduce, transmit, receive } from "./business"
import component from "./component"

const filters: BundleComponent<unknown, typeof component> = {
    name: "filters",
    reduce,
    component,
    transmit,
    receive,
}

export default filters
