import { Table, Text } from "@tm/controls"
import { SearchTreeNode } from "../../../data"

const { Column, Cell } = Table

type Props = {
    headlineNumber: string
    headlineDescription: string
    nodes: Array<SearchTreeNode>
    selectedNode?: SearchTreeNode
    onSelectNode: (nodeId: number) => void
}

const renderNumber = (node: SearchTreeNode) => (
    <Cell>
        <Text size="s">{node.repairTimesNo}</Text>
    </Cell>
)
const renderName = (node: SearchTreeNode) => (
    <Cell>
        <Text size="s">{node.name}</Text>
    </Cell>
)

export default function CategoryList(props: Props) {
    const getRowClassName = (node: SearchTreeNode): string => {
        const { selectedNode } = props

        let className = "is-clickable"
        if (selectedNode && selectedNode.id === node.id) {
            className += " is-selected"
        }

        return className
    }

    const handleSelectTableRow = (data: SearchTreeNode) => {
        props.onSelectNode(data.id)
    }

    const columns = [
        <Column className="fancy-list__block--number" renderItemContent={renderNumber}>
            {props.headlineNumber}
        </Column>,
        <Column className="fancy-list__block--description" renderItemContent={renderName}>
            {props.headlineDescription}
        </Column>,
    ]

    return <Table scrollable onClickRow={handleSelectTableRow} data={props.nodes} columns={columns} getRowClassName={getRowClassName} />
}
