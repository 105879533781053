import { memo } from "react"
import { Box, HTMLBox } from "@tm/components"
import { getBundleParams } from "../../../utils"

type Props = {
    information?: string
}

export const AdditionalInformationExtendedComponent = memo<Props>(({ information }) => {
    if (information) {
        if (getBundleParams().disableDangerouslyDirtyHtmlTooltip) {
            return <Box>{information}</Box>
        }

        return <HTMLBox innerHTML={information} />
    }

    return null
})
