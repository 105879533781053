import { PropsWithChildren, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Icon, LoadingContainer, Paper, styled, Typography } from "@tm/components"
import { Camera, FileUpload, ShowPicture } from ".."
import { useFastServiceStore } from "../../../data"
import { PrintImage } from "../../../data/models"
import { useWorkImages } from "../../../hooks/useWorkImages"

type Props = {
    path: string
    onPictureUploaded?: (value: boolean) => void
}

export default function Upload({ path, onPictureUploaded }: Props) {
    const { translateText } = useLocalization()
    const selectedStep = useFastServiceStore((state) => state.navigation.active)

    const { images: selectedImagesForPath, removePicture, savePicture, replacePicture, isLoading } = useWorkImages(selectedStep || "", path)

    const handleResetImage = (id: string) => {
        removePicture(id)
    }

    const handleImageUpload = (imageData: string) => {
        savePicture(imageData)
    }

    const changeImage = (id: string, imageData: string) => {
        replacePicture(id, imageData)
    }

    useEffect(() => {
        if (onPictureUploaded) {
            onPictureUploaded(!!selectedImagesForPath.length)
        }
    }, [selectedImagesForPath])

    const renderPicture = (picture: PrintImage) => {
        return (
            <StyledCameraWrapper key={picture.id} sx={{ width: "unset" }}>
                <ShowPicture imageId={picture.id} image={picture.imageUrl} removeImage={handleResetImage} updateImage={changeImage} />
            </StyledCameraWrapper>
        )
    }

    return (
        <LoadingContainer isLoading={isLoading}>
            <Box display="flex" flexWrap="wrap" gap="0.5em" marginTop="1em" alignItems="flex-start">
                <UploadPanel icon="camera" headline={translateText(12620)}>
                    <Camera disabled={selectedImagesForPath.length >= 10} onImageUpload={handleImageUpload} />
                </UploadPanel>

                {selectedImagesForPath?.map(renderPicture)}
                <FileUpload disabled={selectedImagesForPath.length >= 10} onImageUpload={handleImageUpload} />
            </Box>
        </LoadingContainer>
    )
}

const StyledCameraWrapper = styled(Paper)({
    maxWidth: "35rem",
    minHeight: "25rem",
    maxHeight: "25rem",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
})

function UploadPanel({ icon, headline, children }: PropsWithChildren<{ icon: string; headline: string }>) {
    return (
        <StyledCameraWrapper>
            <Box display="flex" flexDirection="column">
                <Box display="flex" alignItems="center" marginBottom="1em" gap="0.5em">
                    <Icon name={icon} />
                    <Typography variant="h3">{headline}</Typography>
                </Box>
                <Box display="flex" flex="1">
                    {children}
                </Box>
            </Box>
        </StyledCameraWrapper>
    )
}
