import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"
import { lightGrayBorder } from "../../styles"

export function getComponentStyles() {
    const { box, colors } = getStyleTheme()

    return stylesheet({
        message: {
            padding: "0.6rem 0.8rem",
            marginTop: "0.2rem",
            borderRadius: box.radius,
            minWidth: "10%",
            maxWidth: "80%",
        },
        messageOwn: {
            alignSelf: "flex-end",
            background: colors.primary,
        },
        messageForeign: {
            alignSelf: "flex-start",
            border: lightGrayBorder,
        },
        dateOwnMessage: {
            alignSelf: "flex-end",
            fontSize: "0.6rem",
            margin: 2,
        },
        dateForeignMessage: {
            alignSelf: "flex-start",
            fontSize: "0.6rem",
            margin: 2,
        },
        vehicleDisplayName: {
            marginTop: "0.4rem",
        },
        attachmentImage: {
            maxWidth: "20rem",
            maxHeight: "10rem",
            marginTop: "0.2rem",
            cursor: "pointer",
            background: "#fff",
        },
        attachmentPdf: {
            marginTop: "0.2rem",
        },
        attachmentPdfInfos: {
            marginTop: "0.4rem",
        },
        attachmentVideoAudio: {
            maxWidth: "20rem",
            maxHeight: "10rem",
            marginTop: "0.2rem",
        },
        metadataLink: {
            marginTop: "0.2rem",
        },
        metadataInitialVehicleDataText: {
            margin: "0.6rem 0",
        },
        metadataInitialCustomerData: {
            display: "flex",
            alignItems: "center",
            marginTop: "0.6rem",
        },
        metadataInitialCustomerDataFirst: {
            marginRight: "1rem",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
