import { BundleComponent } from "@tm/morpheus"
import { reduce, receive } from "./business"
import component from "./component"

const DetailsFrame: BundleComponent = {
    name: "details-frame",
    reduce,
    component,
    receive,
}

export default DetailsFrame
