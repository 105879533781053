import { VeFiles } from "@tm/data"
import { useCallback, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { extractFilenameAndExtension } from "../../helpers"
import { QueryKeys } from ".."

export function useUploadFileWithUrl() {
    const queryClient = useQueryClient()
    const { isLoading, mutateAsync } = useMutation(VeFiles.uploadFileWithUrl)
    const [uploadSuccesfull, setUploadSuccesfull] = useState(false)

    const uploadFileWithUrl = useCallback(
        async (fileUrl: string, vehicleId: string, category: string, mileage: string, date: Date, invoiceTotal?: string) => {
            const fileDetails = extractFilenameAndExtension(fileUrl)

            if (fileDetails) {
                const request: VeFiles.UploadFileWithUrlRequest = {
                    maxFileCount: 1,
                    application: "VeFiles",
                    vehicleId,
                    category,
                    mileage,
                    date,
                    invoiceTotal,
                    fileUrl,
                    fileMimeType: fileDetails.mimeType,
                    fileName: fileDetails.filename,
                }

                const data = await mutateAsync(request)
                if (data) {
                    queryClient.invalidateQueries([QueryKeys.GetFileList])
                    setUploadSuccesfull(true)
                }

                setUploadSuccesfull(false)
            }
        },
        [mutateAsync, queryClient]
    )

    return { isLoading, uploadFileWithUrl, uploadSuccesfull }
}
