import { WorkTaskBundleParams } from "@tm/models"
import { registerActions } from "./data"

export const version = {
    name: "worktask",
    version: "1.0.0",
}

let bundleParams: WorkTaskBundleParams

export function initWorkTaskBundle(params: any) {
    bundleParams = params
    registerActions()
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}
