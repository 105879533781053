import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import errorSearch from "./micros/errorSearch"
import { initFastDtcBundle, version } from "./utils"

const bundle: IBundle = {
    ...version,
    components: [Main],
    micros: [errorSearch],
    init: initFastDtcBundle,
}

export default bundle
