import { RegisteredModels, WorkTaskContainer } from "@tm/models"
import { Container } from "@tm/nexus"

export function findLatestWorkTaskId(vehicleId: string, customerId: string) {
    const workTaskContainer: WorkTaskContainer = Container.getInstance(RegisteredModels.Worktask)
    return workTaskContainer
        .action("findLatestWorkTaskByVehicleOrCustomer")({ vehicleId, customerId })
        .then(
            (response) => response?.workTaskId,
            () => undefined
        )
}
