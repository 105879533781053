import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { merge } from "lodash"
import { ModuleItemFactory } from "../models"

export function haynesProFactory(userContext: UserContext): ModuleItemFactory | undefined {
    let payload: ModuleItemFactory = {}

    const haynesProTruckModule = getModuleFromUserContext(userContext, UserModuleType.TechDataHaynesProTruck)

    const haynesProModule = getModuleFromUserContext(userContext, UserModuleType.TechDataHaynesPro)
    const serviceDataHaynesProModule = getModuleFromUserContext(userContext, UserModuleType.ServiceDataHaynesPro)

    const hasRepairTimes = haynesProTruckModule || haynesProModule?.uiConfigKeys?.includes("awdata")
    const hasInspection = haynesProTruckModule || serviceDataHaynesProModule
    const hasTechnicalData = haynesProTruckModule || haynesProModule

    if (hasInspection) {
        const newPayload: ModuleItemFactory = {
            inspection: {
                items: {
                    haynespro: {
                        sort: 0,
                        name: "HaynesPro",
                        route: "/:workTaskId/sd/haynespro",
                        active: true,
                        disabledFunction: "INSPECTIONDATA-HAYNESPRO-DISABLED",
                        setAsActiveVehicleDataProviderOnSelect: {
                            inspectionData: "haynespro",
                        },
                    },
                },
            },
        }

        payload = merge(payload, newPayload)
    }

    if (hasTechnicalData) {
        const newPayload: ModuleItemFactory = {
            tech: {
                items: {
                    haynespro: {
                        sort: 0,
                        name: "HaynesPro",
                        route: "/:workTaskId/td/haynespro",
                        active: true,
                        disabledFunction: "TECHDATA-HAYNESPRO-DISABLED",
                        setAsActiveVehicleDataProviderOnSelect: {
                            technicalData: "haynespro",
                        },
                    },
                },
            },
        }

        payload = merge(payload, newPayload)
    }

    if (hasRepairTimes) {
        const newPayload: ModuleItemFactory = {
            repairtimes: {
                items: {
                    haynespro: {
                        sort: 2,
                        name: "HaynesPro",
                        route: "/:workTaskId/repair-times/haynespro",
                        active: true,
                        disabledFunction: "REPAIRTIMES-HAYNESPRO-DISABLED",
                        setAsActiveVehicleDataProviderOnSelect: {
                            repairTimes: "haynespro",
                        },
                    },
                },
            },
        }

        payload = merge(payload, newPayload)
    }

    return payload
}
