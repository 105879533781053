import {
    Article,
    channel,
    DATWork,
    eOEArtType,
    ErpInformation,
    ImportRepairEstimationRequest,
    ImportResponse,
    OE,
    RegisteredModels,
    TmaEModule,
} from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { batch } from "react-redux"
import { ReactText } from "react"
import {
    createDATCostEstimationRequest,
    createDATGetCalculationDataRequest,
    createUpdateTotalsRequest,
} from "../components/calculation/business/helper"
import { MainState } from "../components/main"
import { MainActionsType } from "../components/main/business"
import { ICalculationItem } from "../data/model"
import { useContainer } from "../data/repositories"

const setErpArticles = (articles: ErpInformation[]): MainActionsType => ({ type: "SET_ERP_ARTICLES", payload: articles })

const getArticles =
    (contractId: string, translateText: (key: ReactText) => string): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        dispatch({ type: "CALCULATION_DATA_LOADING" })

        const request = createDATGetCalculationDataRequest(contractId)

        useContainer()
            .action("getDATCalculationData")(request)
            .then(
                (response) => {
                    const smallSparePart = response?.items?.find((x) => x.oeArticle.type === eOEArtType.SmallPartsAndConsumables)
                    if (smallSparePart) {
                        smallSparePart.oeArticle.description = `${translateText(1903)} (${smallSparePart.oeArticle.priceInPercent}%)`
                    }

                    const partsSurcharge = response?.items?.find((x) => x.oeArticle.type === eOEArtType.PartsSurcharge)
                    if (partsSurcharge) {
                        partsSurcharge.oeArticle.description = `${translateText(13382)} (${partsSurcharge.oeArticle.priceInPercent}%)`
                    }
                    dispatch({ type: "CALCULATION_DATA_LOADED", payload: response })
                },
                () => dispatch({ type: "CALCULATION_DATA_ERROR" })
            )
    }

const addToShoppingBasket =
    (
        translateText: (key: ReactText) => string,
        importToCostEstimation: (importRequest: ImportRepairEstimationRequest, tmaModule: TmaEModule) => Promise<ImportResponse | undefined>,
        division?: number,
        workTaskId?: string,
        memo?: string
    ): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        dispatch({ type: "ADD_TO_SHOPPING_BASKET_LOADING" })

        const {
            manager: { vehicle },
            calculation,
        } = getState()

        const request = createDATCostEstimationRequest(calculation, vehicle, workTaskId, memo, division)

        if (!request) {
            return
        }

        importToCostEstimation(request, TmaEModule.REPESTIMATE_DAT).then(
            () => {
                dispatch({ type: "ADD_TO_SHOPPING_BASKET_SUCCESS" })
            },
            () => {
                dispatch({ type: "ADD_TO_SHOPPING_BASKET_ERROR" })
                channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12890), closeDelay: 5000, skin: "danger" })
            }
        )
    }

const updateTotals =
    (translateText: (key: ReactText) => string, erpInfo?: ErpInformation[]): AsyncAction<MainActionsType, MainState> =>
    (dispatch, getState) => {
        dispatch({ type: "SET_UPDATED_TOTALS_START" })

        const { calculation } = getState()

        if (erpInfo) {
            dispatch(setErpArticles(erpInfo))
        }

        const request = createUpdateTotalsRequest(calculation, erpInfo || calculation.erpArticles)

        useContainer()
            .action("updateTotalsPrices")(request)
            .then(
                (response) => {
                    response && dispatch({ type: "SET_UPDATED_TOTALS", payload: response })
                },
                () => {
                    dispatch({ type: "SET_UPDATED_TOTALS_ERROR" })
                    channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(12892), closeDelay: 5000, skin: "danger" })
                }
            )
    }

const changeOeArticleQuantity =
    (translateText: (key: ReactText) => string, item: ICalculationItem, quantity: number): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "CHANGE_OE_ARTICLE_QUANTITY", payload: { item, quantity } })
            dispatch(updateTotals(translateText))
        })
    }

const replaceArticle = (oeArticle: ICalculationItem, part: Article | OE.OePart): MainActionsType => ({
    type: "REPLACE_ARTICLE",
    payload: { oeArticle, part },
})

const selectArticle =
    (translateText: (key: ReactText) => string, oeArticle: ICalculationItem): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "SELECT_ARTICLE", payload: { oeArticle } })
            dispatch(updateTotals(translateText))
        })
    }

const selectWork =
    (translateText: (key: ReactText) => string, work: DATWork): AsyncAction<MainActionsType, MainState> =>
    (dispatch) => {
        batch(() => {
            dispatch({ type: "SELECT_WORK", payload: { work } })
            dispatch(updateTotals(translateText))
        })
    }

export const CalculationActions = {
    addToShoppingBasket,
    selectArticle,
    replaceArticle,
    selectWork,
    changeOeArticleQuantity,
    setErpArticles,
    getArticles,
    updateTotals,
}
