import { TyreAxles } from "../../models"
import { DriveRightTiresRespone, TyresCritsResponse } from "../../repositories"
import { TireBrands } from "../../repositories/tires-tiresBrands/model"
import { isSameTires } from "./helper"
import { CollapsibleData, SelectedFilters, TabIdentifier, TireType, TyreWear, TyresWheelsSlice, TyresWheelsState, WheelsAndTyresTab } from "./model"
import { defaultSelectedFilters } from "./staticData"

export function updateDescription(state: TyresWheelsSlice, description: string, path: TabIdentifier): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels
    const { collapsibleName, tabType } = path

    const newTabs = getNewTabs<string>(state, tabType, collapsibleName, "description", description)
    const selectedTab = newTabs.find((x) => x.name === tabType)

    return {
        tyresWheels: {
            ...tyresWheelsState,
            tabs: newTabs,
            selectedTab: selectedTab ?? { ...tyresWheelsState.selectedTab },
        },
    }
}

export function updateSafetyStatus(state: TyresWheelsSlice, safetyStatus: number, path: TabIdentifier): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    const { collapsibleName, tabType } = path

    const newTabs = getNewTabs<number>(state, tabType, collapsibleName, "safetyStatus", safetyStatus)
    const selectedTab = newTabs.find((x) => x.name === tabType)

    return {
        tyresWheels: {
            ...tyresWheelsState,
            tabs: newTabs,
            selectedTab: selectedTab ?? { ...tyresWheelsState.selectedTab },
        },
    }
}

export function setTyreSpecification(state: TyresWheelsSlice, tyreSpecification: TyreAxles): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    return {
        tyresWheels: {
            ...tyresWheelsState,
            selectedTyreSpecification: tyreSpecification,
        },
    }
}

export function updateTyreWear(state: TyresWheelsSlice, tyreWear: TyreWear, path: TabIdentifier): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels
    const { collapsibleName, tabType } = path

    const newTabs = getNewTabs<TyreWear>(state, tabType, collapsibleName, "tyreWear", tyreWear)
    const selectedTab = newTabs.find((x) => x.name === tabType)

    return {
        tyresWheels: {
            ...tyresWheelsState,
            tabs: newTabs,
            selectedTab: selectedTab ?? { ...tyresWheelsState.selectedTab },
        },
    }
}

export function setSelectedTireType(state: TyresWheelsSlice, selectedTireType: TireType): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    return {
        tyresWheels: {
            ...tyresWheelsState,
            selectedTire: selectedTireType,
        },
    }
}

export function updateShowBonus(state: TyresWheelsSlice, showBonus: boolean): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    return {
        tyresWheels: {
            ...tyresWheelsState,
            showBonus,
        },
    }
}

export function updateSpareWheel(state: TyresWheelsSlice, hasSpareWheel: boolean): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    return {
        tyresWheels: {
            ...tyresWheelsState,
            spareWheel: hasSpareWheel,
        },
    }
}

export function setTyresCritsError(state: TyresWheelsSlice): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    return {
        tyresWheels: {
            ...tyresWheelsState,
            loadingCrits: false,
        },
    }
}

export function setTyresCritsLoaded(state: TyresWheelsSlice, tyresCrits: TyresCritsResponse): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    return {
        tyresWheels: {
            ...tyresWheelsState,
            tyresCrits,
            loadingCrits: false,
        },
    }
}

export function setTyresCritsLoading(state: TyresWheelsSlice): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    return {
        tyresWheels: {
            ...tyresWheelsState,
            loadingCrits: true,
        },
    }
}

export function updateCollapsibleData(
    state: TyresWheelsSlice,
    tabName: string,
    collapsibleName: string,
    data: CollapsibleData
): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels
    let newTabs: WheelsAndTyresTab[]

    if (collapsibleName === "rimCondition") {
        newTabs = tyresWheelsState.tabs.map((x) => ({
            ...x,
            collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, infoData: data } : { ...y })),
        }))
    } else {
        newTabs = tyresWheelsState.tabs.map((x) =>
            x.name === tabName
                ? { ...x, collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, infoData: data } : { ...y })) }
                : { ...x }
        )
    }

    return {
        tyresWheels: {
            ...tyresWheelsState,
            tabs: newTabs,
        },
    }
}

export function updateSelectedFilters(
    state: TyresWheelsSlice,
    tabName: string,
    tabType: string,
    selectedFilters: SelectedFilters
): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels
    let newTabs: WheelsAndTyresTab[]

    if (tabType === "front" && tyresWheelsState.selectedTire === undefined) {
        newTabs = tyresWheelsState.tabs.map((x) => ({
            ...x,
            selectedFilters: {
                ...selectedFilters,
                size: `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch} ${selectedFilters.loadIndex}${selectedFilters.speedIndex}`,
            },
        }))
    } else {
        newTabs = tyresWheelsState.tabs.map((x) =>
            x.type === tabType
                ? {
                      ...x,
                      selectedFilters: {
                          ...selectedFilters,
                          size: `${selectedFilters.width}/${selectedFilters.height} R${selectedFilters.inch} ${selectedFilters.loadIndex}${selectedFilters.speedIndex}`,
                      },
                  }
                : { ...x }
        )
    }

    return {
        tyresWheels: {
            ...tyresWheelsState,
            tabs: newTabs,
            selectedTab: {
                ...tyresWheelsState.selectedTab,
                selectedFilters,
            },
        },
    }
}

export function setTyresLoaded(
    state: TyresWheelsSlice,
    data: DriveRightTiresRespone,
    tecDocUsed: number,
    tiresBrands: TireBrands
): Partial<TyresWheelsSlice> {
    return {
        tyresWheels: {
            ...state.tyresWheels,
            tiresData: {
                ...data,
                loading: false,
            },
            tireBrands: tiresBrands,
            tecDocUsed,
        },
    }
}

export function setErrorTyres(state: TyresWheelsSlice): Partial<TyresWheelsSlice> {
    return {
        tyresWheels: {
            ...state.tyresWheels,
            tiresData: {
                frontTires: [],
                rearTires: [],
                loading: false,
            },
        },
    }
}

export function setTyresLoading(state: TyresWheelsSlice): Partial<TyresWheelsSlice> {
    return {
        tyresWheels: {
            ...state.tyresWheels,
            tiresData: {
                ...state.tyresWheels.tiresData,
                loading: true,
            },
        },
    }
}

export function selectTab(state: TyresWheelsSlice, tab: WheelsAndTyresTab): Partial<TyresWheelsSlice> {
    return {
        tyresWheels: {
            ...state.tyresWheels,
            selectedTab: tab,
        },
    }
}

export function completeCollapsible(state: TyresWheelsSlice, tabName: string, tabType: string, collapsibleName: string): Partial<TyresWheelsSlice> {
    const tyresWheelsState = state.tyresWheels

    let newTabs: WheelsAndTyresTab[]

    // TODO hardcodes values on string type
    if (
        (collapsibleName === "general" && isSameTires(state, defaultSelectedFilters) && tabType !== "rear" && tabType !== "spareWheel") ||
        collapsibleName === "rimCondition"
    ) {
        newTabs = tyresWheelsState.tabs.map((x) => ({
            ...x,
            collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, completed: true } : { ...y })),
        }))
    } else {
        newTabs = tyresWheelsState.tabs.map((x) =>
            x.name === tabName
                ? {
                      ...x,
                      isComplete: true,
                      collapsibleItems: x.collapsibleItems.map((y) => (y.name === collapsibleName ? { ...y, completed: true } : { ...y })),
                  }
                : { ...x }
        )
    }

    // check if all collapsibles are complete
    newTabs = newTabs.map((tab) => ({
        ...tab,
        isComplete:
            tab.name === "spareWheel"
                ? !tyresWheelsState.spareWheel
                    ? tab.collapsibleItems.filter((x) => x.name === "brakeSystem").last()?.completed || false
                    : tab.collapsibleItems.filter((x) => x.name !== "brakeSystem").every((colaps) => colaps.completed)
                : tab.collapsibleItems.every((colaps) => colaps.completed),
    }))

    const { index, isComplete } = newTabs?.filter((tab) => tab.isComplete).last() ?? {}

    return {
        tyresWheels: {
            ...tyresWheelsState,
            tabs: newTabs,
            ...(!!newTabs &&
                isComplete &&
                index !== undefined && {
                    selectedTab: newTabs.find((tab) => tab.index === index + 1) || newTabs[newTabs.length - 1],
                }),
        },
    }
}

function getNewTabs<T>(tyresWheelsState: TyresWheelsState, tabType: string, collapsibleName: string, key: string, value: T) {
    const newTabs: WheelsAndTyresTab[] = tyresWheelsState.tyresWheels.tabs.map((x) =>
        x.name === tabType
            ? {
                  ...x,
                  collapsibleItems: x.collapsibleItems.map((y) =>
                      y.name === collapsibleName
                          ? {
                                ...y,
                                [key]: value,
                            }
                          : { ...y }
                  ),
              }
            : { ...x }
    )

    return newTabs
}
