import { ETyresSeason, TyresSeason } from "@tm/models"

export function getSeasonTitle(value: TyresSeason) {
    switch (value) {
        case ETyresSeason.summer:
            return 800
        case ETyresSeason.winter:
            return 801
        case ETyresSeason.allSeason:
            return 13079
        default:
            return 0
    }
}
