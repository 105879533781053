import { Suspense, useMemo, useState } from "react"
import { Loader } from "@tm/components"
import { Button, Scrollbar, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useCondensedChatInfos } from "../../../../data/hooks"
import { getSharedChatInfoStyles } from "./_shared-styles"
import CustomerInfo from "./customer"
import OpenWorkTaskDialog from "./open-worktask-dialog"
import { getComponentStyles } from "./styles"
import VehicleInfo from "./vehicle"

type Props = { chatId: string }

function ChatInfos({ chatId }: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])
    const sharedClassNames = useMemo(() => getSharedChatInfoStyles(), [])
    const { translateText } = useLocalization()
    const infos = useCondensedChatInfos(chatId)
    const [vehiclesCompact, setVehiclesCompact] = useState(false)

    return (
        <div className={classNames.content}>
            {infos?.customerInfo && <OpenWorkTaskDialog chatId={chatId} tmCustomerId={infos.tmCustomerId} customerInfo={infos.customerInfo} />}
            {infos?.customerInfo && (
                <div className={classNames.customerWrapper}>
                    <CustomerInfo customer={infos.customerInfo} vehicles={infos.vehicles} />
                </div>
            )}
            {!!infos?.vehicles.length && (
                <>
                    <div className={sharedClassNames.infoHeader}>
                        <Text size="l" modifiers="strong">
                            {translateText(147)}
                        </Text>
                        <Button icon={vehiclesCompact ? "down" : "up"} layout={["ghost"]} onClick={() => setVehiclesCompact((prev) => !prev)} />
                    </div>
                    {!vehiclesCompact && (
                        <div className={classNames.infoArea}>
                            {infos.vehicles.map((v) => (
                                <VehicleInfo key={v.vehicleInfo.id} vehicle={v} />
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default function Wrapper(props: Props) {
    const classNames = useMemo(() => getComponentStyles(), [])

    return (
        <div className={classNames.main}>
            <Suspense fallback={<Loader />}>
                <Scrollbar>
                    <ChatInfos {...props} />
                </Scrollbar>
            </Suspense>
        </div>
    )
}
