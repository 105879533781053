import { BundleComponent } from "@tm/morpheus"
import component from "./Main"
import { withStoreProvider } from "../../utils"

export const mainComponent: BundleComponent = {
    name: "main",
    component: withStoreProvider(component as any) as any,
}

export default mainComponent
