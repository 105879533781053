import { useLocalization } from "@tm/localization"
import { useState, useEffect } from "react"
import { Loader, Headline, TextField, PanelSection, Button } from "@tm/controls"
import { renderRoute, RouteComponentProps, withRouter, encodeUniqueId } from "@tm/utils"
import { Customer, RegisteredModels, TitleType } from "@tm/models"
import { Container } from "@tm/nexus"

type Props = MicroProps &
    RouteComponentProps & {
        customerDetailsRoute: string
    }

type MicroProps = {
    customerId: string
}

function CustomerInfoComponent({ customerDetailsRoute, customerId, match }: Props) {
    const { translateText, date } = useLocalization()
    const [loading, setLoading] = useState<boolean>(true)
    const [customer, setCustomer] = useState<Customer>()
    const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false)

    useEffect(() => {
        Container.getInstance<Customer>(RegisteredModels.Customer)
            .subscribe(customerId)
            .load()
            .then((customer) => {
                setLoading(false)
                setCustomer(customer)
            })
    }, [customerId])

    function handleToggleAdditionalInfo() {
        setShowAdditionalInfo(!showAdditionalInfo)
    }

    function getTitle(title: TitleType | undefined) {
        switch (title) {
            case TitleType.Mister:
                return translateText(145)
            case TitleType.Miss:
                return translateText(146)
            case 3:
                return translateText(108)
            default:
                return ""
        }
    }

    function renderField(textId: number, value: string | undefined, className?: string) {
        return <TextField className={className} value={value || ""} floatingLabel label={translateText(textId)} readonly />
    }

    if (loading) {
        return <Loader />
    }

    if (!customer) {
        return null
    }

    const detailsUrl = renderRoute(customerDetailsRoute, { ...match.params, customerId: encodeUniqueId(customer.id) })

    return (
        <div className="tk-crm">
            <PanelSection className="customer-info">
                <div className="customer-info__title">
                    <Headline size="xs">{translateText(569)}</Headline>
                    <Button icon="pencil" size="s" linkTo={detailsUrl}>
                        {translateText(122)}
                    </Button>
                </div>
                <div className="customer-info__row">
                    <div className="customer-info__col">
                        {renderField(156, customer.customerNo, "customerNo")}
                        {renderField(118, getTitle(customer.titleType), "title")}
                        {renderField(76, customer.academicTitle, "title")}
                    </div>
                    <div className="customer-info__col">{renderField(119, customer.firstName)}</div>
                    <div className="customer-info__col">{renderField(104, customer.lastName)}</div>
                </div>
                <div className="customer-info__row">
                    <div className="customer-info__col">{renderField(108, customer.companyName)}</div>
                    <div className="customer-info__col">{renderField(109, customer.email)}</div>
                    <div className="customer-info__col">{renderField(110, customer.phone)}</div>
                </div>

                {showAdditionalInfo && (
                    <>
                        <div className="customer-info__row">
                            <div className="customer-info__col">{renderField(111, customer.street)}</div>
                            <div className="customer-info__col">
                                {renderField(75, customer.addressAddition, "addressAddition")}
                                {renderField(112, customer.zip)}
                            </div>
                            <div className="customer-info__col">{renderField(113, customer.city)}</div>
                        </div>
                        <div className="customer-info__row">
                            <div className="customer-info__col">{renderField(114, customer.country)}</div>
                            <div className="customer-info__col">{renderField(121, customer.fax)}</div>
                            <div className="customer-info__col">{renderField(77, customer.birthDate && date(customer.birthDate, "d"))}</div>
                        </div>
                    </>
                )}

                <Button className="btn--center btn-expand" icon={showAdditionalInfo ? "minus" : "plus"} onClick={handleToggleAdditionalInfo} />
            </PanelSection>
        </div>
    )
}

export default withRouter(CustomerInfoComponent)
