/* eslint-disable no-console */
import { FastServiceBundleParams } from "@tm/models"
import { isDevtoolEnabled } from "@tm/utils"

export const version = {
    name: "fast-service",
    version: "1.0",
}

let bundleParams: FastServiceBundleParams

export function initFastServiceBundle(params: FastServiceBundleParams) {
    bundleParams = params
    checkBundleParams(bundleParams)
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        throw new Error(`The bundle params have to be set in order to use the bundle "${version.name}"`)
    }

    return bundleParams
}

const checkBundleParams = ({ fastCalculatorServiceUrl, historyServiceUrl }: FastServiceBundleParams) => {
    if (!isDevtoolEnabled()) {
        return
    }

    if (!fastCalculatorServiceUrl) {
        console.warn("fastCalculator service url is not passed")
    }

    if (!historyServiceUrl) {
        console.warn("History service url is not passed")
    }
}
