import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { Paging } from "@tm/models"
import { notUndefinedOrNull } from "@tm/utils"
import { useMemo } from "react"
import { QueryFunctionContext, QueryKey, useInfiniteQuery } from "react-query"
import { CisFilters } from "../../business/model"
import { FindInvoicesRequest, FindInvoicesResponse } from "../model"
import * as Data from "../repositories/cis/invoices"

function findInvoices(filters: CisFilters | undefined, pageParam: PageParam | undefined, pageSize: number, customerNo: string | undefined) {
    const findInvoicesRequest: FindInvoicesRequest = {
        pageIndex: pageParam?.pageIndex || 1,
        pageSize: pageParam?.pageSize || pageSize,
        customerNo,
    }

    if (filters) {
        const currentFilters = filters
        findInvoicesRequest.dateFrom = currentFilters.dateFrom
        findInvoicesRequest.dateTo = currentFilters.dateTo
        findInvoicesRequest.processingStateId = currentFilters.processingStateId
    }

    return Data.findInvoices(findInvoicesRequest)
}

type PageParam = Paging

const KEY = "cis_useInvoices"
export function useInvoices(filters: CisFilters | undefined, pageSize = 50) {
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()

    const { data, isLoading, hasNextPage, fetchNextPage, isFetchingNextPage, error } = useInfiniteQuery(
        [KEY, telesalesCustomerNo, pageSize, filters],
        ({ pageParam }: QueryFunctionContext<QueryKey, PageParam>): Promise<FindInvoicesResponse | undefined> => {
            return findInvoices(filters, pageParam, pageSize, telesalesCustomerNo)
        },
        {
            getNextPageParam: (lastPage): PageParam | undefined => {
                if (lastPage && lastPage.invoices.length === lastPage.pageSize) {
                    return {
                        pageIndex: lastPage.pageIndex + 1,
                        pageSize: lastPage.pageSize,
                    }
                }
            },
        }
    )

    const invoices = useMemo(() => {
        return data?.pages.flatMap((page) => page?.invoices).filter(notUndefinedOrNull)
    }, [data?.pages])

    const invoicesData = useMemo(() => {
        return data?.pages?.last()
    }, [data?.pages])

    return {
        invoicesData,
        invoices,
        invoicesError: error,
        invoicesLoading: isLoading,
        invoicesLoadNextPage: hasNextPage ? fetchNextPage : undefined,
        invoicesNextPageLoading: isFetchingNextPage,
    }
}
