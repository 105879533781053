import { Box, BoxProps, Button, Checkbox, ListItemIcon, Loader, TextField, Typography } from "@tm/components"
import { ChangeEvent, useEffect, useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { getCurrencyFromUserContext } from "@tm/utils"
import { useUser } from "@tm/context-distribution"
import { ListItemComponent } from "./shared/ListItemComponent"
import { Genart } from "../../../data/models"
import { useFastServiceStore } from "../../../data"
import { FSArticle } from "../../../data/repositories/fastcalculator-calculation/mapper"

type Props = {
    genArt: Genart
    selectedGenArts?: Genart[]
    onHandleSelection: (item: Genart) => void
    onOeArticleChange: (fsArticleId: string, quantity: number, price: number, designation: string, oeNumber: string) => void
    enableOeReplace?: boolean
    showPartAlternatives: (genArt: Genart, article: FSArticle, oeArticle?: boolean) => void
} & BoxProps

export default function OePartItem({ genArt, onHandleSelection, selectedGenArts, onOeArticleChange, enableOeReplace, showPartAlternatives }: Props) {
    const { translateText } = useLocalization()
    const { userContext } = useUser()
    const currencyCode = useMemo(() => getCurrencyFromUserContext(userContext), [userContext])

    const [quantity, setQuantity] = useState<number>(0)
    const [price, setPrice] = useState<string>("0")
    const [designation, setDesignation] = useState<string>("")
    const [oeNumber, setOeNumber] = useState<string>("")

    const loading = useFastServiceStore((state) => state.articlesLoading)

    const handleSelection = (item: Genart) => () => {
        onHandleSelection(item)
    }

    const handleSetDesignation = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (designation !== event.target.value) {
            setDesignation(event.target.value)
        }
    }

    function handleSetDefaultQuantity(item: Genart) {
        if (!item.fsArticle!.quantity || item.fsArticle!.quantity === 0) {
            setQuantity(1)
            return
        }
        setQuantity(item.fsArticle!.quantity)
    }

    const handleSetPrice = (event: ChangeEvent<HTMLInputElement>) => {
        const inputPrice = event.target.value
        const regex = /^[0-9]*([.|,][0-9]{0,2})?$/

        if (regex.test(inputPrice) || inputPrice === "0" || inputPrice === "") {
            setPrice(inputPrice)
        }
    }

    const handleSetQuantity = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (quantity !== +event.target.value) {
            setQuantity(+event.target.value)
        }
    }

    const handleOeNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
        const oeNumber = event.target.value

        setOeNumber(oeNumber)
    }

    const handleOeChange = (item: Genart, userQuantity: number, userPrice: number, userDesignation: string, oeNumber: string) => {
        onOeArticleChange(item.fsArticle!.id, userQuantity, userPrice, userDesignation, oeNumber)
    }

    function requestAlternatives() {
        const part = genArt?.fsArticle
        if (!part) {
            return
        }

        showPartAlternatives(genArt, part, enableOeReplace)
    }

    useEffect(() => {
        if (designation === "") {
            if (genArt.fsArticle?.oeLabel && genArt.fsArticle?.oeLabel !== genArt.fsArticle?.oeNrs[0]) {
                setDesignation(genArt.fsArticle?.oeLabel)
            } else if (genArt.fsArticle?.supplierName) {
                setDesignation(`${genArt?.label || ""} ${genArt.fsArticle?.supplierName || ""}`)
            }
        }

        if (price === "0" && genArt.fsArticle?.price) {
            setPrice(genArt.fsArticle?.price.toString())
        }

        if (quantity === 0) {
            handleSetDefaultQuantity(genArt)
        }

        if (genArt.fsArticle?.oeNrs) {
            setOeNumber(genArt.fsArticle?.oeNrs[0])
        }
    }, [])

    useEffect(() => {
        if (!genArt) {
            return
        }
        handleOeChange(genArt, quantity, +price.replace(/,/g, "."), designation, oeNumber)
    }, [designation, quantity, price, oeNumber])

    return (
        <ListItemComponent disablePadding>
            <Box padding="16px" width="100%">
                <Box display="flex" alignItems="center">
                    <ListItemIcon>
                        <Checkbox
                            size="medium"
                            onChange={handleSelection(genArt)}
                            checked={selectedGenArts?.some((x) => x.label === genArt.label && x.genArtNr === genArt.genArtNr) || genArt.isMandatory}
                            disabled={genArt.isMandatory}
                        />
                    </ListItemIcon>
                    <Typography variant="h4" id={genArt.label}>
                        {loading ? <Loader size="small" /> : genArt.label}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginTop="0.5em">
                    <Box display="flex" flexDirection="row" gap="1em" justifyContent="flex-start">
                        <TextField label={translateText(87)} onChange={handleOeNumberChange} required value={oeNumber} size="extralarge" />
                        <TextField
                            label={translateText(13467)}
                            onChange={handleSetDesignation}
                            value={designation}
                            size="extralarge"
                            sx={{ width: "20em" }}
                            required
                        />
                        {enableOeReplace && (
                            <Button color="highlight" onClick={requestAlternatives}>
                                {translateText(13723)}
                            </Button>
                        )}
                    </Box>

                    <TextField label={currencyCode} onChange={handleSetPrice} value={price} size="extralarge" />
                    <TextField label={translateText(89)} type="number" onChange={handleSetQuantity} size="extralarge" value={quantity} />
                </Box>
            </Box>
        </ListItemComponent>
    )
}
