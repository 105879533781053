import * as React from "react"
import { Button, Headline, Modal } from "@tm/controls"
import { useLocalization } from "@tm/localization"

type Props = {
    resetFilterButton?: React.ReactNode
    disabled?: boolean
    children: React.ReactNode
}

const FilterModal: React.FC<Props> = ({ resetFilterButton, disabled, children }) => {
    let modalRef = React.createRef<HTMLDivElement>()

    const { translateText } = useLocalization()
    const [showFilterModal, setShowFilterModal] = React.useState(false)

    React.useEffect(() => {
        if (disabled && showFilterModal) {
            setShowFilterModal(!disabled)
        }
    }, [disabled])

    const handleShowFilterModal = () => {
        setShowFilterModal(true)
    }

    const handleCloseFilterModal = () => {
        setShowFilterModal(false)
    }

    return (
        <>
            <Button layout={["holo"]} disabled={disabled} onClick={handleShowFilterModal}>{translateText(209)}</Button>
            {
                showFilterModal &&
                <Modal onClose={handleCloseFilterModal}>
                    <div className="tk-parts filter-modal" ref={modalRef}>
                        <div className="filter-modal__header">
                            <Headline>{translateText(209)}</Headline>
                            <div>
                                {resetFilterButton}
                            </div>
                        </div>
                        {children}
                    </div>
                </Modal>
            }
        </>
    )
}

export default FilterModal