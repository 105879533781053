import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function damageCalculationDatFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const damageCalculationDatModule = getModuleFromUserContext(userContext, UserModuleType.TMDamageCalculationDat)

    if (!damageCalculationDatModule) {
        return undefined
    }

    return {
        "damage-calculation": {
            items: {
                "damage-calculation-dat": {
                    sort: 1,
                    name: "DAT",
                    active: true,
                    route: "/:workTaskId/damage-calculation/dat",
                    disabledFunction: "HAS-NO-VEHICLE",
                    setAsActiveVehicleDataProviderOnSelect: {
                        damageCalculation: "dat",
                    },
                },
            },
        },
    }
}
