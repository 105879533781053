import { z } from "zod"

export function getEmailSchema(translateText: (key: string | number) => string) {
    return z.object({
        email: z.string().min(1, translateText(1)).email(translateText(1)),
    })
}

export type EmailSchemaType = {
    email: string
}
