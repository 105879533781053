import { AsyncAction } from "@tm/morpheus"
import { BundleActions, BundleActionTypes } from "../../../business"
import { useContainer } from "../../../data/repositories"
import { MainState } from "../../main"
import { MainActionsType } from "../../main/business"
import { TransactionsState } from "./model"

export * from "./model"

export type ComponentActionType =
    | BundleActionTypes
    | { type: "GET_URL_LOADING" }
    | { type: "SET_TRANSACTIONPAGE_URL"; payload: string }
    | { type: "SET_TRANSACTIONPAGE_URL_ERROR" }

const DEFAULT_STATE: TransactionsState = {
    getUrlInProgress: false,
}

export const reduce = (state = DEFAULT_STATE, action: MainActionsType): TransactionsState => {
    switch (action.type) {
        case "RESET": {
            return {
                ...DEFAULT_STATE,
            }
        }
        case "GET_URL_LOADING": {
            return {
                ...DEFAULT_STATE,
                getUrlInProgress: true,
            }
        }
        case "SET_TRANSACTIONPAGE_URL": {
            return {
                ...state,
                getUrlInProgress: false,
                getUrlLoaded: true,
                url: action.payload,
            }
        }
        case "SET_TRANSACTIONPAGE_URL_ERROR": {
            return {
                ...DEFAULT_STATE,
                getUrlLoaded: true,
                getUrlError: true,
            }
        }
        default: {
            return state
        }
    }
}

const getUrlForPage = (): AsyncAction<MainActionsType, MainState> => (dispatch) => {
    const container = useContainer()
    dispatch({ type: "GET_URL_LOADING" })

    container
        .action("getUrlForPage")()
        .then(
            (res) => {
                dispatch({ type: "SET_TRANSACTIONPAGE_URL", payload: res })
            },
            () => dispatch({ type: "SET_TRANSACTIONPAGE_URL_ERROR" })
        )
}

export const Actions = { ...BundleActions, getUrlForPage }
