import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { ModuleItemFactory } from "../models"

export function datFactory(userContext: UserContext): ModuleItemFactory | undefined {
    const datModule = getModuleFromUserContext(userContext, UserModuleType.GTIDataDAT)

    if (!datModule) {
        return undefined
    }

    return {
        gti: {
            items: {
                dat: {
                    sort: 0,
                    name: "DAT",
                    route: "/:workTaskId/gpi/dat",
                    active: true,
                    disabledFunction: "GPI-DAT-DISABLED",
                    setAsActiveVehicleDataProviderOnSelect: {
                        gpi: "dat",
                    },
                },
            },
        },
    }
}
