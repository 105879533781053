import { memo } from "react"
import { Icon, Loader, Checkbox } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Compilation } from "../../../../data"
import { AddingState } from "../../component"

type Props = {
    compilation: Compilation
    containedInList: boolean
    articleId?: string
    state: AddingState | undefined
    onClick(compilationId: string, articleId?: string): void
}

export const CompilationItemDefault = memo<Props>(({ compilation, containedInList, state, onClick, articleId }) => {
    const { translateText } = useLocalization()

    const handleClick = () => {
        onClick(compilation.id, articleId)
    }

    let stateContent
    switch (state) {
        case "ADDING": {
            stateContent = <Loader />
            break
        }
        case "ERROR": {
            stateContent = (
                <div title={translateText(787)}>
                    <Icon skin="danger" name="alert" />
                </div>
            )
            break
        }
    }

    return (
        <div className="compilations__item">
            <div className="state">
                <Checkbox
                    checked={state === "ADDED" || containedInList}
                    onToggle={handleClick}
                    size="m"
                    label={compilation.name}
                    labelTitle={compilation.description}
                />
            </div>
            {stateContent}
        </div>
    )
})
