import { useWorkTask } from "@tm/context-distribution"
import { BikeModel, CarModel, RegistrationNoType, TruckModel, VehicleLookupConfig, VehicleShortInfo } from "@tm/models"
import { RouteComponentProps, TmaHelper, withRouter } from "@tm/utils"
import { useCallback, useEffect, useRef } from "react"
import { useResetFilters } from "../../../data/hooks"
import { getVehicleSearchUrl } from "../../../helpers/routing"
import { handleUniqueVehicleFound } from "../../../helpers/vrm"
import { VehicleSearchFieldSwitch, VehicleSearchfieldV1Props } from "../../_shared/VehicleSearchFieldSwitch"

type RouteParams = {
    workTaskId?: string
}

type SearchProps = RouteComponentProps<RouteParams> &
    Omit<VehicleSearchfieldV1Props, "onStartSearch"> & {
        selectedSearchConfig?: VehicleLookupConfig
    }

function Search(props: SearchProps) {
    const { vehicleType, history, match, selectedSearchConfig } = props
    const { attachToWorkTask, workTask } = useWorkTask() ?? {}
    const inputFieldRef = useRef<HTMLDivElement>(null)
    const resetFilters = useResetFilters(vehicleType)

    useEffect(() => {
        const timeout = window.setTimeout(() => {
            inputFieldRef.current?.focus()
        }, 500)
        return () => window.clearTimeout(timeout)
    }, [])

    const handleStartSearch = useCallback(
        (query: string, registrationNoType?: RegistrationNoType, selectedVehicleLookupConfig?: VehicleLookupConfig) => {
            resetFilters() // Reset filters before opening search page
            history.push(
                getVehicleSearchUrl(match.params, {
                    vehicleType,
                    query,
                    registrationNoType,
                })
            )
        },
        [resetFilters, history, match, vehicleType]
    )

    const handleUniqueVehicle = useCallback(
        (model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string) => {
            if (attachToWorkTask) {
                handleUniqueVehicleFound(
                    model,
                    vehicleType,
                    query,
                    attachToWorkTask,
                    undefined,
                    selectedSearchConfig?.countryCode,
                    selectedSearchConfig?.lookupTypeId
                )
            }
        },
        [attachToWorkTask, vehicleType, selectedSearchConfig]
    )

    const handleAttachVehicleId = useCallback(
        (vehicleId: string, query: string) => {
            if (!attachToWorkTask) {
                return
            }
            TmaHelper.VehicleSelection.Search.FromWidget({ dataSourceId: RegistrationNoType.VehicleBase, query })
            attachToWorkTask({ vehicle: vehicleId })
        },
        [attachToWorkTask]
    )

    return (
        <VehicleSearchFieldSwitch
            {...props}
            inputFieldRef={inputFieldRef}
            onStartSearch={handleStartSearch}
            onUniqueVehicleFound={attachToWorkTask ? handleUniqueVehicle : undefined}
            onAttachVehicleId={handleAttachVehicleId}
            showVrcScanButton={!workTask?.vehicle}
        />
    )
}

export default withRouter(Search)
