export enum ErrorReason {
    NotSpecified = 0,
    Other = 1,
    SindriError = 2,
    NotFound = 3,
}

export type ResponseBase = {
    error: WSError
}

export type WSError = {
    message: string
    reason: ErrorReason
}
