import { ModuleConfig, IPlugin, PluginProps } from "@tm/morpheus"

// controled by mdm module parameter "integration"/"einbindung"
function ShouldRenderByExtModule(data: MessengerProps) {
    const props = (data as any).component?.props
    if (!props) {
        return true
    }

    const { extModule } = props
    if (!extModule || typeof extModule != "object") {
        return true
    }

    const { parameter, value } = extModule
    if (!parameter || !value) {
        return true
    }

    const modules = window.userContext?.externalModules
    if (!modules) {
        return false
    }

    const module = modules.find((x) => x.parameter?.some((y) => y.key === parameter && y.value === value))
    if (!module) {
        return false
    }

    return true
}

const plugin: IPlugin<ModuleConfig> = {
    name: "ShouldRenderByExtModule",
    type: "COMPONENT/SHOULD_RENDER",
    plugin: ShouldRenderByExtModule,
}

export default plugin

type MessengerProps = PluginProps<ModuleConfig>
