import { Article, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { Repositories, maintenancePlanActions, navigationActions, stepNavigationActions, useFastServiceStore, worksActions } from "../../../data"
import { CalcState, FastServiceData, SpecificationsGenart } from "../../../data/models"
import { CalculationContextWorks } from "../../../data/repositories/fastcalculator-calculation/mapper"
import { createSubmitMultipleSelectionsRequest } from "../../../data/repositories/fastcalculator-submitMultipleSelections/mapper"
import { createSubmitSelectionRequest } from "../../../data/repositories/fastcalculator-submitSelection/mapper"
import { createGetMaintenancePlanRequest, getCorrectPath } from "../../../data/repositories/generalMapper"
import { getStepNameByLocation } from "../../../helpers"
import { ArticleIdentifierExt, mapCalcArticleToArticleIdentifier } from "../../../models"
import { createIncludeAdditionalWorksRequest } from "../../../data/repositories/fastcalculator-includeAdditionalWorks/mapper"
import { SelectionKeys } from "../../../data/state"

function processFastServiceMaintenanceResponse(res: FastServiceData, calcState?: CalcState) {
    maintenancePlanActions.setCalcState({ calcId: res.calcId ?? calcState?.calcId, eTag: res.eTag ?? calcState?.eTag })
    maintenancePlanActions.setMaintenanceData(res.cockpitContext)
    if (res.cockpitContext.recordWorks) {
        worksActions.updateAllWorks(res.cockpitContext.recordWorks)
        const existingKeys = Object.keys(res.cockpitContext.recordWorks).map((key) => getStepNameByLocation(key))
        stepNavigationActions.updateWorksSteps(existingKeys)
        if (res.eTag !== calcState?.eTag) {
            getCalculation()
        }
    }
}

export const initMaintenancePlan = (languageId?: string, defaultHourlyRate?: number, baseCalculationCalcId?: string) => {
    const {
        maintenancePlan: { modelDetails, vehicleFields: fields, stateVehicle, calcState },
    } = useFastServiceStore.getState()

    if (!stateVehicle || !modelDetails) {
        return
    }

    maintenancePlanActions.setMaintenanceDataLoading()

    const request = createGetMaintenancePlanRequest(stateVehicle, fields, modelDetails, languageId, defaultHourlyRate, baseCalculationCalcId)

    Repositories.getMaintenancePlan(request).then(
        (res) => {
            processFastServiceMaintenanceResponse(res, calcState)
            if (baseCalculationCalcId) {
                stepNavigationActions.completeStep("maintenancePlan")
                navigationActions.changeStep("technician")
            }
        },
        () => {
            maintenancePlanActions.setMaintenanceDataError()
        }
    )
}

export function submitIncludeAdditionalWorks(value: boolean) {
    const {
        maintenancePlan: { calcState },
    } = useFastServiceStore.getState()

    if (!calcState) {
        return
    }

    maintenancePlanActions.setMaintenanceDataLoading()

    const request = createIncludeAdditionalWorksRequest(calcState.calcId, calcState.eTag, value)
    Repositories.submitIncludeAddtionalWorks(request).then(
        (res) => {
            processFastServiceMaintenanceResponse(res, calcState)
        },
        () => {
            maintenancePlanActions.setMaintenanceDataError()
        }
    )
}

export const submitSelection = (
    single?: { items: string[]; selectionId: string },
    multiple?: { [key: string]: string[] },
    selectionKey?: SelectionKeys
) => {
    const {
        maintenancePlan: { calcState },
    } = useFastServiceStore.getState()

    if (!calcState) {
        return
    }

    maintenancePlanActions.setMaintenanceDataLoading()

    let webrequest: Promise<FastServiceData>
    if (single) {
        const { items, selectionId } = single
        const request = createSubmitSelectionRequest(calcState.calcId, calcState.eTag, items, selectionId)
        webrequest = Repositories.submitSelection(request)
    }

    if (multiple) {
        const request = createSubmitMultipleSelectionsRequest(calcState.calcId, calcState.eTag, multiple)
        webrequest = Repositories.submitMultipleSelections(request)
    }

    if (selectionKey) {
        maintenancePlanActions.deleteSelectionKey(selectionKey)
    }

    webrequest!.then(
        (response) => {
            maintenancePlanActions.setCalcState({ calcId: response.calcId ?? calcState?.calcId, eTag: response.eTag ?? calcState?.eTag })
            maintenancePlanActions.setMaintenanceData(response.cockpitContext)
            if (response.cockpitContext.recordWorks) {
                response.cockpitContext.recordWorks["Tires wheels"] = response.cockpitContext.recordWorks["Tires wheels"].slice(0, 1)
                worksActions.updateAllWorks(response.cockpitContext.recordWorks)
                // only if they are not loaded
                const existingKeys = Object.keys(response.cockpitContext.recordWorks).map((key) => getStepNameByLocation(key))
                stepNavigationActions.updateWorksSteps(existingKeys)
                if (response.eTag !== calcState.eTag) {
                    getCalculation()
                }
            }
        },
        () => {
            maintenancePlanActions.setMaintenanceDataError()
        }
    )
}

export const getCalculation = () => {
    const {
        maintenancePlan: { stateVehicle, calcState: { calcId, eTag } = {} },
        setLoadingArticles,
    } = useFastServiceStore.getState()

    if (!calcId || !eTag) {
        return
    }

    setLoadingArticles()

    Repositories.getCalculation({ calcId, eTag }).then((response) => {
        if (response?.works.length) {
            // TODO: SAVE ETAG IN MANAGER
            const articlesToLoad: Array<ArticleIdentifierExt> = []
            const truncArticles: Article[] = []
            let consumables: Array<SpecificationsGenart> = []

            response.works.forEach((work) => {
                work.genArts.forEach((genArt) => {
                    if (!genArt.article) {
                        return
                    }
                    if (!genArt.article.supplierArtNr || !genArt.article.supplierId) {
                        truncArticles.push(genArt.article)
                        return
                    }
                    articlesToLoad.push(mapCalcArticleToArticleIdentifier(genArt.article, genArt.genArtNr, stateVehicle?.tecDocTypeId))
                })
                consumables = work.consumables
            })

            if (truncArticles?.length > 0) {
                handleArticlesLoad(truncArticles, response.works)
            }

            loadArticles(articlesToLoad, response.works)
        } else {
            useFastServiceStore.getState().setErrorArticles()
        }
    })
}
// get the abose response and here for each the articles and repalce with real articles
export const loadArticles = (articlesToLoad: Array<ArticleIdentifierExt>, calculationWorks: CalculationContextWorks[]) => {
    if (articlesToLoad?.length === 0) {
        return
    }

    Container.getInstance<Array<Article>>(RegisteredModels.Articles_ByArticleNumbersWithOptionalVehicle)
        .subscribe(articlesToLoad)
        .load()
        .then((articles) => handleArticlesLoad(articles, calculationWorks))
        .catch(() => useFastServiceStore.getState().setErrorArticles())
}

const handleArticlesLoad = (articles: Article[], calculationWorks: CalculationContextWorks[]) => {
    const {
        maintenancePlan: { works },
    } = useFastServiceStore.getState()
    const calculationWOrks = calculationWorks.groupBy((x) => getCorrectPath(x.locationId))

    useFastServiceStore.getState().setLoadedArticles(articles)
    if (!articles?.length || !works) {
        useFastServiceStore.getState().setErrorArticles()
        return
    }

    const yy: typeof works = {}

    Object.entries(works).forEach(([key, value]) => {
        yy[key] = value.map((x) => {
            const fsWork = calculationWOrks[key].find((works) => works.providerId === x.providerId)
            return {
                ...x,
                genArts: x.genArts.map((y) => {
                    const foundArticle = articles.find((z) => {
                        if (z.fittingSide && y.binKrit100) {
                            return z.productGroup?.id === y.genArtNr && y.binKrit100 & z.fittingSide
                        }
                        return z.productGroup?.id === y.genArtNr
                    })
                    const fsArticle = fsWork?.genArts.find((genarts) => genarts.genArtNr === y.genArtNr)?.article
                    if (foundArticle && fsArticle && fsArticle?.quantity !== 1) {
                        foundArticle.quantity = fsArticle.quantity
                        useFastServiceStore.getState().setUpdateArticleQuantity(foundArticle, fsArticle.quantity)
                    }

                    return {
                        ...y,
                        article: foundArticle || undefined,
                        fsArticle,
                    }
                }),
                consumables: calculationWOrks[key].find((y) => y.label === x.label)?.consumables || [],
            }
        })
    })

    if (yy) {
        worksActions.updateAllWorks(yy)
    }
}
