import { useState, useRef, KeyboardEvent, useCallback, useMemo, useEffect } from "react"
import { useLocalization } from "@tm/localization"
import { Skeleton, MenuItem, Stack, Icon, Typography, Divider, Button, useTheme, useMediaQuery, Switch, TextField, Select } from "@tm/components"
import { ButtonKeyDefinition } from "@tm/utils"
import { NumberField, DecimalAmountField } from "@tm/controls"
import { ArticleInfoType, CostEstimationOptions, PriceType, SelectedPriceTypeMode, VatRate } from "@tm/models"
import { useCustomArticlesCategories } from "../../../../../data/hooks/useCustomArticlesCategories"
import { ValueLabelStack } from "../../../../ValueLabelStack"
import ArticleNumbers from "../../../../_shared/ArticleNumbers"
import { VatRateSelectionItem } from "../../../../../data/model"
import ArticleThumbnail from "../../../../_shared/PartsTableCells/ArticleThumbnail"
import { StackRow, StyledNumberField } from "../../../../StyledComponents"
import { VatRateSelector } from "../../../../VatRateSelector"
import { BasketPart } from "../../../../../models"
import { findPrice } from "../../../../../helpers"

type Props = {
    costEstimationOptions?: CostEstimationOptions
    articleInfoType?: ArticleInfoType
    customPartVatRates?: Array<VatRateSelectionItem>
    defaultCurrencyCode: string
    hidePurchasePrice?: boolean
    isEditMode?: boolean
    part?: BasketPart
    rebate?: number
    showManufacturer?: boolean
    showSupplierArticleNumbers?: boolean
    showWholesalerArticleNumbers?: boolean
    surcharge?: number
    onAddCustomPart?(
        saveAsCustomArticle: boolean,
        articleNumber: string | undefined,
        retailPrice: number | undefined,
        purchasePrice: number | undefined,
        description: string,
        quantityValue: number,
        rebate: number | undefined,
        surcharge: number | undefined,
        category?: number,
        vatRate?: VatRate
    ): void
    onClose(): void
    onEditPart?(
        saveAsCustomArticle: boolean,
        rebate?: number,
        surcharge?: number,
        regularPrice?: number,
        customPartNumber?: string,
        customPartDescription?: string,
        category?: number,
        vatRate?: VatRate
    ): void
    onSaveAsCustomArticle?(): void
}

export default function PartFields(props: Props) {
    const {
        part,
        defaultCurrencyCode,
        surcharge,
        rebate,
        articleInfoType,
        isEditMode,
        hidePurchasePrice,
        showSupplierArticleNumbers,
        showManufacturer,
        showWholesalerArticleNumbers,
        customPartVatRates,
        costEstimationOptions,
    } = props
    const { partItem, calculatedPart, erpInfoResponse } = part || {}
    const { articleInformation, costEstimationItem } = partItem || {}
    const { supplierName, description, articleNumber, productGroupName, wholesalerArticleNumber } = articleInformation || {}
    const { translateText, currency } = useLocalization()
    const { categories } = useCustomArticlesCategories()
    const theme = useTheme()
    const isBigScreen = useMediaQuery(theme.breakpoints.up("xl"))

    const erpPrices = erpInfoResponse?.prices ? Object.values(erpInfoResponse?.prices) : undefined
    const purchasePrice = findPrice(erpPrices, PriceType.Purchase)?.value
    const retailPrice = findPrice(erpPrices, PriceType.Retail)?.value

    const originalRebate = calculatedPart?.rebate ?? rebate ?? 0
    const originalSurcharge = calculatedPart?.surcharge ?? surcharge ?? 0
    const originalRegularPrice = calculatedPart?.regularPrice?.value ?? 0
    const originalRegularPriceWithSurcharge = calculatedPart?.regularPriceWithSurcharge?.value ?? 0
    const originalOfferPrice = calculatedPart?.offerPrice?.value ?? 0

    const [actualDescription, setDescription] = useState(description)
    const [actualArticleNumber, setArticleNumber] = useState(articleNumber)
    const [actualRegularPrice, setRegularPrice] = useState(originalRegularPrice)
    const [actualRebate, setRebate] = useState(originalRebate)
    const [actualSurcharge, setSurcharge] = useState(originalSurcharge)
    const [actualQuantity, setQuantity] = useState(1)
    const [actualRegularPriceWithSurcharge, setRegularPriceWithSurcharge] = useState(originalRegularPriceWithSurcharge)
    const [actualOfferPrice, setOfferPrice] = useState(originalOfferPrice)

    const [saveAsCustomArticle, setSaveAsCustomArticle] = useState(false)
    const [originalPricesExpanded, setOriginalPricesExpanded] = useState<boolean>(true)

    const [actualCategory, setActualCategory] = useState<number>(3060)
    const [selectedVatRate, setSelectedVatRate] = useState<VatRateSelectionItem | undefined>(undefined)

    const articleNumberRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLInputElement>(null)
    const quantityRef = useRef<NumberField>(null)
    const priceRef = useRef<NumberField>(null)
    const confirmationRef = useRef<HTMLButtonElement>(null)

    const isCustomPart = articleInformation?.articleInfoType === ArticleInfoType.CustomArticle || articleInfoType === ArticleInfoType.CustomArticle
    const isOePart = articleInformation?.articleInfoType === ArticleInfoType.OeArticle
    const isTecDocPart = articleInformation?.articleInfoType === ArticleInfoType.TecdocArticle
    const currencyCode = costEstimationItem?.currencyCode ?? defaultCurrencyCode
    const vatRates = costEstimationItem?.vatRates ?? customPartVatRates

    useEffect(() => {
        if (calculatedPart?.regularPrice?.value) {
            handleRegularPriceChange(calculatedPart.regularPrice.value)
        }
    }, [calculatedPart?.regularPrice?.value])

    const handleSaveAsCustomArticle = useCallback(() => {
        props.onSaveAsCustomArticle?.()
        setSaveAsCustomArticle((prev) => !prev)
    }, [props.onSaveAsCustomArticle])

    const mappedVatRate: VatRate | undefined = useMemo(() => {
        return selectedVatRate
            ? {
                  vatRate: selectedVatRate?.vatValue,
                  vatType: selectedVatRate?.vatType,
              }
            : undefined
    }, [selectedVatRate])

    const quantityField = useMemo(() => {
        const field = (
            <DecimalAmountField
                value={actualQuantity}
                disabled={isEditMode}
                onChange={({ value }: { value: number }) => setQuantity(value || 0)}
                maxAmount={9999}
                minAmount={0}
            />
        )

        return isCustomPart ? <div>{field}</div> : field
    }, [isCustomPart, actualQuantity, isEditMode])

    function calculatePrices(newSurcharge: number | undefined, newGaragePrice: number | undefined, newRebate: number | undefined) {
        const newGaragePriceWithSurcharge = (newGaragePrice ?? 0) + (newGaragePrice ?? 0) * ((newSurcharge ?? 0) / 100)
        setRegularPriceWithSurcharge(newGaragePriceWithSurcharge)
        setOfferPrice(newGaragePriceWithSurcharge - newGaragePriceWithSurcharge * ((newRebate ?? 0) / 100))
    }

    function handleArticleNumberKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter) {
            descriptionRef.current?.focus()
        }
    }

    function handleDescriptionKeyDown(e: KeyboardEvent) {
        if (e.key === ButtonKeyDefinition.Enter) {
            quantityRef.current?.focus()
        }
    }

    function handleRegularPriceKeyDown(e: KeyboardEvent) {
        if (e.key === (ButtonKeyDefinition.Enter || ButtonKeyDefinition.Tab)) {
            if (!actualArticleNumber) {
                articleNumberRef.current?.focus()
            } else if (!actualDescription) {
                descriptionRef.current?.focus()
            } else {
                confirmationRef.current?.focus()
            }
        }
    }

    function handleChangeSurcharge(value: number) {
        setSurcharge(value ?? 0)
        calculatePrices(value, actualRegularPrice, actualRebate)
    }

    function handleRegularPriceChange(value: number | undefined) {
        setRegularPrice(value ?? 0)
        calculatePrices(actualSurcharge, value, actualRebate)
    }

    function handleChangeRebate(value: number) {
        setRebate(value ?? 0)
        calculatePrices(actualSurcharge, actualRegularPrice, value)
    }

    function handleConfirmButtonClick() {
        if (!isEditMode && props.onAddCustomPart) {
            props.onAddCustomPart(
                saveAsCustomArticle,
                actualArticleNumber,
                !costEstimationOptions?.priceTypeMode ? actualRegularPrice : undefined,
                costEstimationOptions?.priceTypeMode === SelectedPriceTypeMode.Purchase ? actualRegularPrice : undefined,
                actualDescription ?? "",
                actualQuantity,
                actualRebate,
                actualSurcharge,
                actualCategory,
                mappedVatRate
            )
            setSaveAsCustomArticle(false)
        } else if (isEditMode && props.onEditPart && part) {
            props.onEditPart(
                saveAsCustomArticle,
                actualRebate,
                actualSurcharge,
                actualRegularPrice,
                actualArticleNumber,
                actualDescription,
                actualCategory,
                mappedVatRate
            )
            setSaveAsCustomArticle(false)
        }
    }

    function renderTitel() {
        return (
            <StackRow spacing={2}>
                {isCustomPart ? (
                    <>
                        <Icon name="individual-article" />
                        <Typography>{translateText(757)}</Typography>
                    </>
                ) : (
                    <>
                        {part && <ArticleThumbnail part={part} />}
                        {showManufacturer && <Typography>{supplierName}</Typography>}
                    </>
                )}
            </StackRow>
        )
    }

    function renderBasicInfo() {
        return (
            <StackRow spacing={2} flex={1}>
                {isCustomPart ? (
                    <StackRow spacing={0.5} flex={1}>
                        <TextField
                            inputProps={{ maxLength: 50 }}
                            inputRef={articleNumberRef}
                            label={translateText(87)}
                            size="large"
                            value={actualArticleNumber}
                            onChange={(e) => setArticleNumber(e.target.value)}
                            onKeyDown={handleArticleNumberKeyDown}
                        />
                        <TextField
                            fullWidth
                            inputProps={{ maxLength: 200 }}
                            inputRef={descriptionRef}
                            label={translateText(25)}
                            required
                            size="large"
                            value={actualDescription}
                            onChange={(e) => setDescription(e.target.value)}
                            onKeyDown={handleDescriptionKeyDown}
                        />
                    </StackRow>
                ) : (
                    <StackRow flex={1} gap={2}>
                        {(showSupplierArticleNumbers || showWholesalerArticleNumbers) && (
                            <ArticleNumbers
                                oeArticleNumber={showSupplierArticleNumbers && isOePart ? articleNumber : undefined}
                                supplierArticleNumber={showSupplierArticleNumbers && isTecDocPart ? articleNumber : undefined}
                                wholesalerArticleNumber={showWholesalerArticleNumbers ? wholesalerArticleNumber : undefined}
                            />
                        )}
                        <Typography flex={1} textAlign="center" variant="body2">
                            {description || productGroupName}
                        </Typography>
                    </StackRow>
                )}
            </StackRow>
        )
    }

    function renderOriginalPrices() {
        return (
            <StackRow spacing={1}>
                {!isCustomPart && (
                    <Stack>
                        {!hidePurchasePrice && (
                            <Typography variant="body3">
                                {`${translateText(55)}: ${purchasePrice ? currency(purchasePrice, currencyCode) : "-"}`}
                            </Typography>
                        )}
                        <Typography variant="body3">
                            {`${translateText(1620)}: ${retailPrice ? currency(retailPrice, currencyCode) : "-"}`}
                        </Typography>
                    </Stack>
                )}
                <StyledNumberField
                    onKeyDown={handleRegularPriceKeyDown}
                    ref={priceRef}
                    label={`${translateText(53)} ${currencyCode}`}
                    floatingLabel
                    value={actualRegularPrice}
                    onChangeConfirm={handleRegularPriceChange}
                    onChange={handleRegularPriceChange}
                    minimum={0.0}
                    maximum={9999999}
                    nullable
                    stepSize={0.01}
                />
                <StyledNumberField
                    label={`${translateText(12475)} %`}
                    floatingLabel
                    value={actualSurcharge}
                    minimum={0}
                    maximum={9999}
                    stepSize={0.01}
                    nullable
                    onChange={handleChangeSurcharge}
                    onChangeConfirm={handleChangeSurcharge}
                />
                <ValueLabelStack label={`${translateText(57)} ${currencyCode}`} value={actualRegularPriceWithSurcharge} />
            </StackRow>
        )
    }

    function renderCalculationFields() {
        return (
            <StackRow spacing={1}>
                {quantityField}
                {originalPricesExpanded && renderOriginalPrices()}
                {isEditMode && (
                    <Button
                        onClick={() => setOriginalPricesExpanded(!originalPricesExpanded)}
                        startIcon={<Icon name={originalPricesExpanded ? "arrow-right" : "arrow-left"} />}
                        variant="text"
                        size="large"
                    />
                )}
                <StyledNumberField
                    label={`${translateText(54)} %`}
                    floatingLabel
                    value={actualRebate}
                    minimum={0}
                    maximum={100}
                    stepSize={0.01}
                    nullable
                    onChange={handleChangeRebate}
                />
                <ValueLabelStack label={`${translateText(57)} ${currencyCode}`} value={actualOfferPrice} />
                {isCustomPart && vatRates && <VatRateSelector vatRates={vatRates} onChange={(newVatRate) => setSelectedVatRate(newVatRate)} />}
            </StackRow>
        )
    }

    function renderActions() {
        return (
            <StackRow spacing={0.5}>
                {isCustomPart && (
                    <StackRow spacing={1.5}>
                        <Switch
                            label={translateText(9)}
                            checked={saveAsCustomArticle}
                            labelPlacement="start"
                            size="small"
                            onChange={handleSaveAsCustomArticle}
                        />
                        {categories ? (
                            <Select
                                disabled={!saveAsCustomArticle}
                                onChange={(e) => setActualCategory(e.target.value as number)}
                                value={actualCategory ?? categories[0].textId}
                                size="large"
                            >
                                {categories?.map((row, index) => (
                                    <MenuItem key={index} value={row.textId}>
                                        {translateText(row.textId)}
                                    </MenuItem>
                                )) || []}
                            </Select>
                        ) : (
                            <Skeleton height={48} width={128} />
                        )}
                    </StackRow>
                )}
                <Button title={translateText(70)} onClick={() => props.onClose()} startIcon={<Icon name="close" />} size="large" />
                <Button
                    ref={confirmationRef}
                    title={translateText(9)}
                    onClick={handleConfirmButtonClick}
                    startIcon={<Icon name="check" />}
                    color="success"
                    size="large"
                    disabled={isCustomPart ? !actualDescription || !actualQuantity : false}
                />
            </StackRow>
        )
    }

    if (isBigScreen || !isCustomPart) {
        return (
            <StackRow spacing={2} justifyContent="space-between">
                {renderTitel()}
                {renderBasicInfo()}
                <Divider orientation="vertical" flexItem />
                {renderCalculationFields()}
                <Divider orientation="vertical" flexItem />
                {renderActions()}
            </StackRow>
        )
    }

    return (
        <Stack spacing={1}>
            <StackRow justifyContent="space-between">
                {renderTitel()}
                {renderActions()}
            </StackRow>
            {renderBasicInfo()}
            {renderCalculationFields()}
        </Stack>
    )
}
