import { useQuery } from "react-query"
import { useMemo } from "react"
import { Repositories } from ".."
import { getLiveItems } from "../helpers"
import { ErrorReason } from "../../models"

export function useGetJobDetails(jobId?: string) {
    const { data, isLoading, isError } = useQuery(
        ["sindri_query", "jobdetails", jobId],
        () => {
            return Repositories.getJobDetails(jobId!)
        },
        {
            enabled: !!jobId,
            cacheTime: 5 * 60 * 1000,
            staleTime: 5 * 60 * 1000,
        }
    )

    const liveItems = useMemo(() => getLiveItems(data?.vehicleScans?.scanItems), [data])
    const faultCodeGroups = useMemo(
        () =>
            data?.vehicleScans?.scanItems?.flatMap((scanItems) =>
                scanItems.faultCodes?.faultCodeGroups?.flatMap((faultCodeGroup) => {
                    return { name: faultCodeGroup.name, faultCodeItems: faultCodeGroup.faultCodeItems }
                })
            ),
        [data]
    )

    const errorReason = data?.error?.reason

    const hasError = isError || errorReason

    return {
        jobDetails: data,
        faultCodeGroups,
        liveItems,
        isLoading,
        isError: isError || hasError,
        isSindriError: errorReason === ErrorReason.SindriError,
        errorReason,
    }
}
