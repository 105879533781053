import { useSelector } from "react-redux"
import { Grid, SubTitle, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { initSelector } from "@tm/utils"
import { DocumentsState } from "../../bussiness/model"

const selector = initSelector((s: DocumentsState) => s.carModel)

export default function Selections() {
    const [vehicle] = useSelector(selector)
    const { translateText } = useLocalization()

    if (!vehicle) {
        return null
    }

    return (
        <Grid container>
            <Grid m={4}>
                <SubTitle>{translateText(71)}</SubTitle>
                <Text modifiers={["strong"]} size="l">
                    {vehicle.manufacturer?.description}
                </Text>
            </Grid>
            <Grid m={4}>
                <SubTitle>{translateText(73)}</SubTitle>
                <Text modifiers={["strong"]} size="l">
                    {vehicle.modelSeries?.description}
                </Text>
            </Grid>
            <Grid m={4}>
                <SubTitle>{translateText(74)}</SubTitle>
                <Text modifiers={["strong"]} size="l">
                    {vehicle.modelDetails?.description}
                </Text>
            </Grid>
        </Grid>
    )
}
