import { Box, styled, Typography } from "@tm/components"
import { Icon } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { GetMemoImportantNoteResponse } from "@tm/models"

type Props = {
    data: GetMemoImportantNoteResponse
}

function ImportantNotesMemo(props: Props) {
    const { translateText } = useLocalization()
    const { id, label } = props.data ?? {}

    return (
        <Box>
            <Box sx={{ display: "inline-flex", flex: 1, alignItems: "center", margin: (theme) => theme.spacing(1, 0) }} key={id}>
                <StyledIcon name="warning-dark" size="xl" />
                <Typography variant="h4">{translateText(71)}: {id}</Typography>
            </Box>
            <Typography> {label?.memoStr}</Typography>
        </Box>
    )
}

const StyledIcon = styled(Icon)(({ theme }) => ({
    margin: theme.spacing(0, 1)
}))

export default ImportantNotesMemo