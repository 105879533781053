import { useMemo } from "react"
import { Box, Select, MenuItem, styled } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { Headline, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { VrcLookupModule } from "../../../../data/model/vrc-lookup"
import SharedLayout from "../_shared-layout"
import Camera from "./camera"
import FastUp from "./fast-up"
import { FileUpload } from "./file"
import { getComponentStyles } from "./styles"
import Flag from "./flag"
import { UploadPanel } from "./UploadPanel"
import { VrcLocalFile, VrcUploadedFile } from "../../component"
import { TransferInfoResponse } from "../../../../data/repositories/fast-up"

type Props = {
    modules: Array<VrcLookupModule>
    activeModule: VrcLookupModule
    setActiveModule?(id: string): void
    transferInfo: TransferInfoResponse
    onFileUpload(file: VrcLocalFile | VrcUploadedFile): void
    flagOnly?: boolean
}

const StyledSelect = styled(Select)({
    lineHeight: 0,
})
const StyledMenuItem = styled(MenuItem)({
    justifyContent: "center",
})

export default function Upload(props: Props) {
    const classNames = getComponentStyles()

    const vehicle = useWorkTask()?.workTask?.vehicle
    const { translateText } = useLocalization()
    const { setActiveModule } = props

    const onSelectionChange = useMemo(() => {
        return (e: any) => {
            const { value } = e.target
            value && setActiveModule && setActiveModule(value.toString())
        }
    }, [setActiveModule])

    return (
        <SharedLayout>
            <Headline size="xs">{translateText(12618)}</Headline>

            {!vehicle?.countryCode && props.modules.length > 1 && (
                <Box mt={1} mb={2}>
                    <Text>{translateText(13119)}:</Text>

                    <Box mt={1}>
                        <StyledSelect disabled={!props.setActiveModule} value={props.activeModule.moduleId} onChange={onSelectionChange}>
                            {props.modules.map((module) => (
                                <StyledMenuItem key={module.moduleId} value={module.moduleId}>
                                    <Flag module={module} />
                                </StyledMenuItem>
                            ))}
                        </StyledSelect>
                    </Box>
                </Box>
            )}

            <Text>{translateText(12619)}</Text>
            <div className={classNames.wrapper}>
                <UploadPanel icon="camera" headline={translateText(12620)}>
                    <Camera onImageUpload={props.onFileUpload} />
                </UploadPanel>
                <UploadPanel icon="scan" headline={translateText(12679)}>
                    <FastUp
                        transferInfo={props.transferInfo}
                        fastUpTransferType={props.activeModule.fastUpTransferType}
                        onFileUpload={props.onFileUpload}
                    />
                </UploadPanel>
                <UploadPanel icon="upload" headline={translateText(12621)}>
                    <FileUpload onFileUpload={props.onFileUpload} />
                </UploadPanel>
            </div>
        </SharedLayout>
    )
}
