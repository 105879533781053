import { FastService } from "@tm/data"
import { useQuery } from "react-query"

export function useFileInfo(fileId: string, onSuccess: (data: FastService.GetFileInfoResponse) => void) {
    const { data, refetch, isLoading, isError } = useQuery(
        ["FAST_SERVICE_VEFILES_USE_FILE_INFO", fileId],
        () => FastService.getFileInfo({ fileId }),
        {
            enabled: false,
            staleTime: 10 * 60 * 1000,
            cacheTime: 10 * 60 * 1000,
            retry: 3,
            onSuccess: (response) => {
                if (response) {
                    onSuccess(response)
                }
            },
        }
    )

    return {
        fileThumbail: data?.fileMeta?.thumbnail?.url,
        isLoading,
        getFileInfo: refetch,
        isError,
    }
}
