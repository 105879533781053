import { CircularProgress, Box } from "@tm/components"
import { AttributeFilterModel } from "@tm/models"
import { showDefaultAttributeSelect } from "../../../../../../helper"
import DefaultSelectOptions from "./DefaultSelectOptions"
import GroupedSelectOptions from "./GroupedSelectOptions"

type FilterSelectOptionsProps = {
    options: AttributeFilterModel[] | undefined
    isLoading: boolean
    onSelect(filter: AttributeFilterModel): void
}

export default function FilterSelectOptions(props: FilterSelectOptionsProps) {
    const { options, isLoading, onSelect } = props

    if (isLoading || !options) {
        return (
            <Box textAlign="center" p={1}>
                <CircularProgress size={20} />
            </Box>
        )
    }

    if (showDefaultAttributeSelect(options)) {
        return <DefaultSelectOptions options={options} onSelect={onSelect} />
    }

    return <GroupedSelectOptions options={options} onSelect={onSelect} />
}
