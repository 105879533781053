import { Box, NumberSelect } from "@tm/components"
import { memo, useCallback } from "react"

type CustomItemQuantityFieldProps<TItem> = {
    item: TItem
    quantity: number
    onQuantityChange(item: TItem, quantity: number): void
}

export const CustomItemQuantityField = memo(<TItem,>({ item, quantity, onQuantityChange }: CustomItemQuantityFieldProps<TItem>) => {
    const handleChangeQuantity = useCallback(
        (value: number) => {
            onQuantityChange(item, value)
        },
        [item, onQuantityChange]
    )

    return (
        <Box pr={0.5}>
            <NumberSelect value={quantity || 1} onValueChange={handleChangeQuantity} maxAmount={9999} />
        </Box>
    )
})
