import { useState } from "react"
import { TextField, PasswordField, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Button, Stack, Icon, Loader } from "@tm/components"
import UserSettingsArea from "./UserSettingsArea"

type Props = {
    onSave(credentials: { username: string; password: string }): void
    onChangeAnyway(): void
    hasCredentials: boolean
    isLoading: boolean
}

export default function ConceptPage(props: Props) {
    const { onSave, hasCredentials, isLoading, onChangeAnyway } = props
    const { translateText } = useLocalization()
    const [dirty, setDirty] = useState(false)
    const [updated, setUpdated] = useState(false)
    const [username, setUsername] = useState<string>()
    const [password, setPassword] = useState<string>()

    function handleReset() {
        setUsername(undefined)
        setPassword(undefined)
        setDirty(false)
    }

    function handleSave() {
        if (username && password) {
            onSave({ username, password })
            handleReset()
            setUpdated(true)
        }
    }

    function handleChange(field: "username" | "password", text: string) {
        switch (field) {
            case "username":
                if (text !== username && !!password) {
                    setDirty(true)
                }
                setUsername(text)
                break
            case "password":
                if (text !== password && !!username) {
                    setDirty(true)
                }
                setPassword(text)
                break
            default:
                break
        }
    }

    function renderForm() {
        return (
            <Stack gap={1}>
                <Stack direction="row" spacing={0.5}>
                    <Button onClick={handleSave} disabled={!dirty} color="success">
                        {translateText(9)}
                    </Button>
                    <Button onClick={handleReset} disabled={!dirty}>
                        {translateText(48)}
                    </Button>
                </Stack>
                <Stack direction="row" spacing={0.5}>
                    <TextField
                        autoComplete="off"
                        label={translateText(186)}
                        value={username}
                        onChange={handleChange.bind(null, "username")}
                        floatingLabel
                    />
                    <PasswordField
                        autoComplete="new-password"
                        label={translateText(187)}
                        value={password}
                        onChange={handleChange.bind(null, "password")}
                        floatingLabel
                    />
                </Stack>
            </Stack>
        )
    }

    function renderAlreadySaved() {
        return (
            <>
                <Text modifiers={["block"]}>{translateText(1605)}</Text>
                <div>
                    <Button onClick={onChangeAnyway} color="highlight">
                        {translateText(309)}
                    </Button>
                </div>
            </>
        )
    }

    function renderSuccess() {
        return (
            <div className="success">
                <Icon name="check" size="l" color="success" /> {translateText(519)}
            </div>
        )
    }

    return (
        <UserSettingsArea id="user-settings__concept-page" title={translateText(1602)}>
            {isLoading ? <Loader size="small" /> : hasCredentials ? renderAlreadySaved() : updated ? renderSuccess() : renderForm()}
        </UserSettingsArea>
    )
}
