import { NumberField, Button, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { concat } from "@tm/utils"
import { ReactNode } from "react"

type Props = {
    currentPageIndex: number
    onChangePageIndex(page: number): void
    totalPages: number
    disabled: boolean
    maxPages?: number
}

export function OffersWidgetPagination({ totalPages, currentPageIndex, onChangePageIndex, disabled, maxPages }: Props) {
    const { translateText } = useLocalization()

    function previousPage() {
        let page = currentPageIndex - 1

        if (page < 0) {
            page = totalPages - 1
        }

        onChangePageIndex(page)
    }

    function nextPage() {
        let page = currentPageIndex + 1

        if (page >= totalPages) {
            page = 0
        }

        onChangePageIndex(page)
    }

    function changePage(page: number) {
        onChangePageIndex(page - 1) // The user inputs the index + 1
    }

    function renderPageNavigation() {
        const elements: Array<ReactNode> = []

        for (let pageIndex = 0; pageIndex < Math.min(totalPages, maxPages ?? Infinity); pageIndex++) {
            elements.push(
                <div
                    key={pageIndex}
                    className={concat(" ", "navigation-page", pageIndex === currentPageIndex && "selected")}
                    onClick={() => onChangePageIndex(pageIndex)}
                />
            )
        }

        return elements
    }

    return (
        <>
            <div className="navigation">{renderPageNavigation()}</div>
            <div className="navigation">
                <NumberField
                    className="navigation-input"
                    value={currentPageIndex + 1}
                    stepSize={1}
                    minimum={1}
                    maximum={totalPages}
                    disabled={disabled}
                    onChangeConfirm={changePage}
                />
                <Text>{`${translateText(344)} ${totalPages}`}</Text>
            </div>
            <div>
                <Button disabled={disabled} scaleIcon size="xs" icon="arrow-left" layout={["ghost"]} onClick={previousPage} />
                <Button disabled={disabled} scaleIcon size="xs" icon="arrow-right" layout={["ghost"]} onClick={nextPage} />
            </div>
        </>
    )
}
