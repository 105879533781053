import { memo, useCallback, useState } from "react"
import { Button, Icon } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { LoaderSmall, StackRow } from "../../../../StyledComponents"

type Props = {
    canSave: boolean
    onSave(): Promise<void>
    onAbort(): void
}

export const EditorButtons = memo<Props>(({ canSave, onSave, onAbort }) => {
    const { translateText } = useLocalization()
    const [saving, setSaving] = useState(false)

    const handleSave = useCallback(async () => {
        setSaving(true)

        try {
            await onSave()
            onAbort()
        } catch {
            setSaving(false)
        }
    }, [onSave, onAbort])

    return (
        <StackRow spacing={1} justifyContent="flex-end" alignSelf="flex-start">
            {saving && <LoaderSmall />}
            <Button title={translateText(317)} onClick={onAbort} startIcon={<Icon name="close" />} />
            <Button startIcon={<Icon name="check" />} color="success" onClick={handleSave} disabled={!canSave || saving} title={translateText(9)} />
        </StackRow>
    )
})
