import { StateCreator } from "zustand"
import { FastServiceStore } from "../.."
import { TechnicianSlice, TechnicianState } from "./model"

function getInitialState(): TechnicianState {
    return {
        technician: {
            firstName: "",
            lastName: "",
            comment: "",
        },
    }
}

export const createTechnicianSlice: StateCreator<FastServiceStore, [["zustand/devtools", never]], [], TechnicianSlice> = (set) => ({
    ...getInitialState(),
    technicianActions: {
        setSignature: (signature: string) => set((state) => ({ technician: { ...state.technician, signature } }), false, "Set technician signature"),
        setFirstName: (firstName: string) =>
            set((state) => ({ technician: { ...state.technician, firstName } }), false, `Set technician firstName: ${firstName}`),
        setLastName: (lastName: string) =>
            set((state) => ({ technician: { ...state.technician, lastName } }), false, `Set technician lastName: ${lastName}`),
        setComment: (comment: string) =>
            set((state) => ({ technician: { ...state.technician, comment } }), false, `Set technician comment: ${comment}`),
        reset: () => set(getInitialState(), false, "Reset Technician Slice"),
    },
})
