import { DisplayOptionKeys } from "../data/models"

export const getTextFromPrintOption = (printOption: DisplayOptionKeys, translateText: (key: string | number) => string): string => {
    switch (printOption) {
        case DisplayOptionKeys.InspectionTimes:
            return translateText(12964)
        case DisplayOptionKeys.Remarks:
            return translateText(12965)
        case DisplayOptionKeys.Images:
            return translateText(12685)
        case DisplayOptionKeys.Tyres:
            return translateText(240)
        case DisplayOptionKeys.NextMaintenance:
            return translateText(12966)
        case DisplayOptionKeys.ShowDefects:
            return translateText(12845)
        default:
            return ""
    }
}
