import { ErpInformationRequestItem, MemoType, ErpInformation, Memo, PriceType, Warehouse } from "@tm/models"
import { mapPrices } from "@tm/utils"
import { getBundleParams } from "./utils"

export type BundleActionType =
    | { type: "ERP_INFO_DETAILS_LOADED"; payload: { request: ErpInformationRequestItem; response: ErpInformation | undefined } }
    | { type: "ERP_INFO_DETAILS_ERROR"; payload: { message?: string } }
    | { type: "ERP_INFO_DETAILS_RESET" }

export type ScrollToPossibilities = "warehouses" | "teccom" | "prices" | "infos" | "obligated" | "linked" | "accessory" | "memo-tooltip-icon"

export function filterMemos(memos?: Array<Memo>): Array<Memo> {
    if (memos && memos.length) {
        return memos.filter(
            (memo) =>
                memo?.text?.trim() &&
                (memo.type === undefined ||
                    memo.type === MemoType.Undefined ||
                    memo.type === MemoType.Note ||
                    memo.type === MemoType.Mark ||
                    memo.type === MemoType.AdditionalDescription ||
                    memo.type === MemoType.ArticleListInformation)
        )
    }

    return []
}

export function getPriceDescription(translateText: (id: number) => string, type: PriceType, isReplacementPart: boolean) {
    switch (type) {
        case PriceType.Purchase:
            return translateText(1132)

        case PriceType.Retail:
            return translateText(1257)

        case PriceType.Deposit:
            return translateText(1287)

        case PriceType.Environmental:
            return translateText(getBundleParams().environmentalPriceTextId)

        case PriceType.Replacement:
            if (isReplacementPart) {
                return translateText(1614)
            }
            return translateText(1538)

        case PriceType.BasicPrice:
            return translateText(3138)

        case PriceType.ProcurementCosts:
            return translateText(13139)
        default:
            return undefined
    }
}

export function getPriceFromWarehouses(type: PriceType, warehouses?: Warehouse[]) {
    if (!warehouses) {
        return
    }

    for (let i = 0; i < warehouses.length; i++) {
        const prices = mapPrices(warehouses[i].prices)
        if (prices) {
            const price = prices.find((p) => p && p.type === type)
            if (price) {
                return price
            }
        }
    }
}
