import { DutchOrderPlatesBundleParams } from "@tm/models"
import { initRepositories } from "./data/repositories"

export const version = {
    name: "dutch-order-plates",
    version: "0.1.0",
}

let bundleParams: DutchOrderPlatesBundleParams

export function initDutchOrderPlatesBundle(params: DutchOrderPlatesBundleParams) {
    bundleParams = params
    initRepositories()
    return bundleParams
}

export function getBundleParams() {
    if (!bundleParams) {
        // eslint-disable-next-line no-throw-literal
        throw `The bundle params have to be set in order to use the bundle "${version.name}"`
    }

    return bundleParams
}
