import { Box, Loader, Stack } from "@tm/components"
import { Suspense, useEffect } from "react"
import { ProductGroupsModuleStateProvider, useProductGroupsModuleState } from "./ProductGroupsModuleState"
import ProductGroupsList from "./ProductGroupsList"
import { ModalModuleHeader } from "../components/ModuleHeader/ModalModuleHeader"

function ProductGroupsComponent() {
    const start = useProductGroupsModuleState((x) => x.start)

    useEffect(start, [start])

    return <ProductGroupsList />
}

export default function ProductGroups() {
    return (
        <Suspense
            fallback={
                <Box alignContent="center">
                    <Loader />
                </Box>
            }
        >
            <ProductGroupsModuleStateProvider>
                <Stack overflow="hidden">
                    <ModalModuleHeader />
                    <ProductGroupsComponent />
                </Stack>
            </ProductGroupsModuleStateProvider>
        </Suspense>
    )
}
