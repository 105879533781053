export function getIconNameByMimeType(mimeType: string) {
    switch (mimeType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
            return "pictures"
        case "application/pdf":
            return "file-pdf"
        case "video/mp4":
        case "video/webm":
        case "video/ogg":
            return "video"
        case "audio/mpeg":
        case "audio/ogg":
        case "audio/wav":
            return "audio"
        default:
            break
    }
    return "paperclip"
}

export function getTextByMimeType(mimeType: string) {
    switch (mimeType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
            return 195
        case "application/pdf":
            return 3096
        case "video/mp4":
        case "video/webm":
        case "video/ogg":
            return 3093
        case "audio/mpeg":
        case "audio/ogg":
        case "audio/wav":
            return 3094
        default:
            break
    }
    return 3095
}

export function getDateWithoutTime(date: Date | string) {
    const d = new Date(date)
    d.setHours(0, 0, 0, 0)
    return d
}

export function addDaysToDate(date: Date, days: number) {
    return new Date(new Date(date).setDate(date.getDate() + days))
}
