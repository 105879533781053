import { WorkTaskReadModel, RegisteredModels } from "@tm/models"
import { WorkTaskInfo } from "@tm/context-distribution"
import { AsyncAction } from "@tm/morpheus"
import { Container } from "@tm/nexus"
import { WorkTaskListItem } from "./components/search/business/model"

export type BundleActionType =
    | { type: "LIST_NEXTPAGE_LOADED"; payload: { currentWorkTasks: Array<WorkTaskListItem>; workTaskReadModel: Array<WorkTaskReadModel> } }
    | { type: "LIST_LOADED"; payload: Array<WorkTaskReadModel> }
    | { type: "LIST_LOADING"; payload?: string }
    | { type: "LIST_NEXTPAGE_LOADING"; payload?: string }
    | { type: "RESET_MODULE_NAVIGATION" }
    | { type: "REMOVE_TABS"; payload: string[] }
    | { type: "TOTAL_NUMBERS_LOADED"; payload: any }
    | { type: "TOTAL_NUMBERS_LOADING" }
    | { type: "WORKTASK_LOADED"; payload?: WorkTaskInfo }
    | { type: "WORKTASK_STATE_CHANGED"; payload: string }

export function loadTotalNumbers(workTaskIdList: Array<string>): AsyncAction<BundleActionType> {
    return (dispatch) => {
        dispatch({ type: "TOTAL_NUMBERS_LOADING" })
        const container = Container.getInstance<any>(RegisteredModels.Basket_CostEstimation_ShowTotalNumbersByWorkTask)
        container
            .subscribe(workTaskIdList)
            .load()
            .then((response) => {
                dispatch({ type: "TOTAL_NUMBERS_LOADED", payload: response })
            })
    }
}
