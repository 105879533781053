import { useKeyValue } from "@tm/utils"
import { useMemo } from "react"

type LastReadInfo = {
    messageId: string
    messageDate: string | Date
}

const request = { key: "CHAT_LAST_READ_MESSAGE_INFO", ownedByRepairShop: true }
export function useLastReadInfo() {
    const [value, setValue, state] = useKeyValue(request)

    const lastReadInfo = useMemo(() => {
        return value ? (JSON.parse(value) as LastReadInfo) : undefined
    }, [value])

    const setLastReadInfo = useMemo(() => {
        if (!setValue) {
            return
        }
        return (data: LastReadInfo | undefined) => {
            return setValue(data ? JSON.stringify(data) : undefined)
        }
    }, [setValue])

    return { lastReadInfo, setLastReadInfo, lastReadInfoLoaded: state === "hasValue" }
}

export function useLastReadInfoDate() {
    const { lastReadInfo } = useLastReadInfo()
    const lastReadInfoDate = lastReadInfo?.messageDate ? new Date(lastReadInfo.messageDate) : undefined
    return { lastReadInfoDate }
}
