import { Container } from "@tm/nexus"
import { ModuleGroupId, ModuleId, RegisteredModels, ViewStateContainer, channel } from "@tm/models"
import { useQuery, useQueryClient } from "react-query"
import { clone } from "@tm/utils"

type HideDemoBanner = Record<string | ModuleGroupId, Record<string | ModuleId, boolean>> | undefined
const KEY = "hide_demo_banner"

const container: ViewStateContainer = Container.getInstance(RegisteredModels.ViewState)

export function useHideDemoBanner(selectedModuleGroupId: ModuleGroupId, selectedModuleId?: ModuleId) {
    const queryClient = useQueryClient()

    const {
        data: hideModulesBanner,
        isLoading,
        error,
    } = useQuery<HideDemoBanner>(
        KEY,
        async () => {
            const data = await container.action("loadViewState")("HIDE_DEMO_BANNER")
            return data.value
        },
        { staleTime: Infinity, cacheTime: Infinity, enabled: !!selectedModuleGroupId && !!selectedModuleId }
    )

    const handleHideBanner = () => {
        if (!selectedModuleId) {
            return
        }

        const clonedHideModulesBanner = hideModulesBanner ? clone(hideModulesBanner) : {}
        if (!clonedHideModulesBanner[selectedModuleGroupId]) {
            clonedHideModulesBanner[selectedModuleGroupId] = {}
        }
        clonedHideModulesBanner[selectedModuleGroupId][selectedModuleId.toLowerCase()] = true

        queryClient.setQueryData(KEY, clonedHideModulesBanner)

        container
            .action("saveViewState")({ key: "HIDE_DEMO_BANNER", value: clonedHideModulesBanner })
            .catch(() => {})

        setTimeout(() => {
            channel("GLOBAL").publish("MVC/RESIZE", { moduleMode: selectedModuleId })
        }, 20)
    }

    const hideBanner = (!!selectedModuleId && hideModulesBanner?.[selectedModuleGroupId]?.[selectedModuleId.toLowerCase()]) || false

    return {
        handleHideBanner,
        hideBanner,
        hideModulesBanner,
    }
}
