import { GetCustomArticlesCategoriesResponse } from "@tm/models"
import { ajax, getStoredAuthorization } from "@tm/utils"
import { useQuery } from "react-query"
import { useMemo } from "react"
import { getBundleParams } from "../../utils"

function getReplaceArticlesCategory() {
    const url = getBundleParams().customArticlesServiceUrl
    return ajax<GetCustomArticlesCategoriesResponse>({ url: `${url}/GetCategories`, authorization: getStoredAuthorization() }).then(
        (data) => data?.categories
    )
}

export function useGetCustomArticlesCategory(genartNr: number | undefined, enabled: boolean) {
    const query = useQuery(["CustomArticlesGetCategories", enabled], () => getReplaceArticlesCategory(), {
        enabled: enabled && !!genartNr,
        staleTime: 30 * 60 * 1000,
    })

    const categoryTextId = useMemo(() => {
        if (query.data && genartNr) {
            return query.data.find((x) => x.genArt.includes(genartNr))?.textId
        }

        return undefined
    }, [query.data, genartNr])

    return { categoryTextId, ...query }
}
