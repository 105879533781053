import { Alert, Box, Button, Fade, Stack } from "@tm/components"
import { useState, useRef, useEffect } from "react"
import { useQueryClient } from "react-query"
import { Headline } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import NotificationsTable from "./NotificationsTable"
import { NotificationMessage } from "../../data/model/notificationMessage"
import { POLLING_QUERY_KEY, useNotificationPolling } from "../../data/hooks/useNotificationPolling"
import { setNotificationMessagesRead } from "../../data/setNotificationMessagesRead"

export default function NotificationCenter() {
    const notificationMessageBuffer = useRef(new Array<NotificationMessage>())
    const [notificationMessages, setNotificationMessages] = useState<NotificationMessage[]>([])
    const [hasUpdates, setHasUpdates] = useState(false)
    const { translateText } = useLocalization()
    const queryClient = useQueryClient()

    const { polledNotificationMessages } = useNotificationPolling()

    useEffect(() => {
        if (polledNotificationMessages) {
            notificationMessageBuffer.current = polledNotificationMessages
            if (notificationMessages.length === 0) {
                setNotificationMessages(notificationMessageBuffer.current)
            } else if (containsUpdates()) {
                setHasUpdates(true)
            }
        }
    }, [polledNotificationMessages])

    function onUpdateClicked() {
        setNotificationMessages(notificationMessageBuffer.current)
        setHasUpdates(false)
    }

    function containsUpdates(): boolean {
        const sameLength = notificationMessageBuffer.current.length === notificationMessages.length
        if (!sameLength) {
            return true
        }
        if (notificationMessageBuffer.current.length === 0 && notificationMessages.length === 0) {
            return false
        }
        for (let index = 0; index < notificationMessageBuffer.current.length; index++) {
            const currentItem = notificationMessageBuffer.current[index]
            const matchingItem = notificationMessages.find((x) => x.notificationMessageId === currentItem.notificationMessageId)
            if (!matchingItem || matchingItem.isRead !== currentItem.isRead) {
                return true
            }
        }

        return false
    }

    async function setRead(notificationMessageIds: string[]) {
        await setNotificationMessagesRead(notificationMessageIds)
        notificationMessageIds.forEach((id) => {
            const message = notificationMessages.find((x) => x.notificationMessageId === id)
            if (message) {
                message.isRead = true
            }
        })
        queryClient.invalidateQueries([POLLING_QUERY_KEY])
    }

    return (
        <Box sx={{ width: "1" }}>
            <Stack alignItems="stretch" justifyContent="stretch" sx={{ height: "1", padding: 2, background: "white" }} direction="column" spacing={2}>
                <Box>
                    <Headline>{translateText(505)}</Headline>
                </Box>
                {hasUpdates && (
                    <Fade in={hasUpdates}>
                        <Alert
                            severity="info"
                            variant="outlined"
                            size="medium"
                            title={translateText(13487)}
                            action={
                                <Button variant="contained" color="primary" onClick={onUpdateClicked}>
                                    {translateText(13490)}
                                </Button>
                            }
                        />
                    </Fade>
                )}
                <NotificationsTable notificationMessages={notificationMessages} setNotificationsRead={setRead} />
            </Stack>
        </Box>
    )
}
