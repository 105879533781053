import { Fragment } from "react"
import { Image } from "@tm/controls"
import { Link } from "react-router-dom"
import { useLocalization } from "@tm/localization"
import { useCisCustomer, useUser } from "@tm/context-distribution"
import { Tooltip } from "@tm/components"

type Props = {
    brandImage: string
    width?: number
    className?: string
    linkTo?: string
    tooltipMessage?: string | number
    hideLink?: boolean
}

export default function BrandInformationComponent(props: Props) {
    const { translateText } = useLocalization()
    const baseName = "brand-information"
    const { cisCustomer } = useCisCustomer()
    const applicationInformation = cisCustomer?.applicationInformation
    const { userContext } = useUser()
    const { catalogBrandImage } = userContext.parameter

    const { brandImage, width, className, hideLink, tooltipMessage, linkTo } = props
    const brandClassName = className ? `${baseName} ${className}` : baseName

    const wrapperProps = hideLink === true ? {} : { title: translateText(tooltipMessage || 910) }
    const Wrapper = hideLink === true ? Fragment : Tooltip

    const linkWrapperProps: any = hideLink === true ? {} : { to: linkTo || "/" }
    const LinkWrapper = hideLink === true ? Fragment : Link

    return (
        <div className="brand-information-wrapper">
            <div className={brandClassName}>
                <Wrapper {...wrapperProps}>
                    <LinkWrapper {...linkWrapperProps}>
                        <Image className="brand-image" url={catalogBrandImage ?? brandImage} width={width} />
                        {applicationInformation?.edition && <div className="application-edition">{applicationInformation?.edition}</div>}
                    </LinkWrapper>
                </Wrapper>
            </div>
            {applicationInformation?.mode && <div className="application-mode">{applicationInformation?.mode}</div>}
        </div>
    )
}
