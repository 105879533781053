import { GlobalNavigationItem, Icon, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { getBundleParams } from "../../utils"

const LoginNavigationItem = styled(GlobalNavigationItem)(({ theme }) => ({
    width: "132px !important",
    marginRight: "10px !important",
    flexDirection: "row",
    "&.global-navigation-flat": {
        borderRight: `1px solid ${theme.palette.grey.A400}60`,
    },
}))

export default function IndustryLoginButton() {
    const { translateText } = useLocalization()
    const { linkedCatalogLoginRoute } = getBundleParams()

    const handleClick = () => {
        linkedCatalogLoginRoute && window.location.replace(linkedCatalogLoginRoute)
    }

    return (
        <LoginNavigationItem componentType="element" onIconClick={handleClick}>
            <>
                <Icon name="user" size="16px" sx={{ margin: "0 0 0 10px !important" }} />
                <Typography margin="10px">{translateText(13517)}</Typography>
            </>
        </LoginNavigationItem>
    )
}
