import { useMemo } from "react"
import { Box, Icon, Table, TableCellData, TableColumnData, Tooltip, styled } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Warehouse } from "@tm/models"
import { getBundleParams } from "../../../../utils"
import { StockTuple, useStockTuple } from "../../../../data/hooks"
import AvailabilityWrapper from "../../../../_shared/availability-wrapper"
import WarehouseDetails from "./warehouse-details"

type Props = {
    warehouses: Array<Warehouse>
    requestedQuantity: number | undefined
}

const StyledTable = styled(Table)(() => {
    return {
        whiteSpace: "nowrap",
        overflowY: "auto",
    }
})

export default function DetailsWarehouseTable({ warehouses, requestedQuantity }: Props) {
    const { translateText } = useLocalization()
    const list = useStockTuple(warehouses)

    const showQuantity = useMemo(() => {
        for (let i = 0; i < list.length; i++) {
            if (getBundleParams().hideAllZeroQuantityColumn ? list[i].quantity.value : list[i].quantity.value !== undefined) {
                return true
            }
        }
        return false
    }, [list])

    function renderDetails(item: StockTuple) {
        if (
            item.warehouse.standardDelivery ||
            item.warehouse.collection ||
            item.warehouse.dayExpressDelivery ||
            item.warehouse.nightExpressDelivery
        ) {
            return (
                <Tooltip title={<WarehouseDetails warehouse={item.warehouse} />}>
                    <Icon name="info" />
                </Tooltip>
            )
        }
    }

    if (!list.length) {
        return null
    }

    function getColumns() {
        const columns: TableColumnData[] = [{ header: translateText(412) }]

        if (showQuantity) {
            columns.push({ header: translateText(89), minWidth: 50 })
        }

        columns.push({ header: translateText(144) }, { header: "" })

        return columns
    }

    function getCellData(item: StockTuple) {
        const cellData: TableCellData[] = [
            {
                displayValue: (
                    <Box sx={{ "& .icon": { height: "18px", width: "18px" } }}>
                        <AvailabilityWrapper
                            availability={item.quantity.availability}
                            tour={item.quantity.tour}
                            requestedQuantity={requestedQuantity}
                            warehouseTableCurrentItem={item.warehouse}
                        />
                    </Box>
                ),
                id: "item1",
            },
        ]

        if (showQuantity) {
            cellData.push({ displayValue: item.quantity.value, id: "item2" })
        }

        cellData.push(
            {
                displayValue: item.warehouse.name || item.warehouse.description,
                id: "item3",
            },
            { displayValue: renderDetails(item), id: "item4" }
        )

        return cellData
    }

    const displayData = list?.map((item, index) => ({
        cells: getCellData(item),
        id: `${index}`,
        customRow: false,
        active: false,
    }))

    return <StyledTable columns={getColumns()} rows={displayData} rowCap={2} variant="small" headerStyle="bold" sx={{ maxHeight: "20em" }} />
}
