import { Dialog, Typography, Button } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { StackRow } from "./StyledComponents"

type Props = {
    showClearBasketDialog: boolean
    onCloseDialog(): void
    onConfirmDialog(): void
}

export default function ClearBasketDialog({ showClearBasketDialog, onConfirmDialog, onCloseDialog }: Props) {
    const { translateText } = useLocalization()

    return (
        <Dialog open={showClearBasketDialog} skin="warning" position="top" fullWidth>
            <StackRow justifyContent="space-between">
                <Typography>{translateText(13169)}</Typography>
                <StackRow textAlign="center" spacing={1}>
                    <Button variant="contained" onClick={onCloseDialog}>
                        {translateText(584)}
                    </Button>
                    <Button variant="contained" color="success" onClick={onConfirmDialog}>
                        {translateText(585)}
                    </Button>
                </StackRow>
            </StackRow>
        </Dialog>
    )
}
