import { AddWholesalerPartListRequest, RegisteredModels } from "@tm/models"
import { useMutation } from "react-query"
import { useCallback } from "react"
import { Container } from "@tm/nexus"
import * as Data from "../../.."
import { useBasketUpdateWorkflow } from "../workflow/useBasketUpdateWorkflow"
import { reloadWholesalerArticleQuantities } from "../../.."
import { useBasketModuleParameter } from "../../../../hooks/useBasketModuleParameter"
import { useHandlePostSendOrderAction } from "../../../../components/_shared/order-button/hooks/useHandlePostSendOrderAction"
import { useCreateBasketPartPostMessage } from "../../../../hooks/useCreatePartPostMessage"

export function useAddWholesalerPartList(handleBasketUpdateWorkflow: ReturnType<typeof useBasketUpdateWorkflow>) {
    const basketModule = useBasketModuleParameter()
    const { getMessageByWholsalerPart } = useCreateBasketPartPostMessage()
    const handlePostSendOrderAction = useHandlePostSendOrderAction()
    const { mutateAsync: addWholesalerPartList } = useMutation(
        ({ request }: { request: AddWholesalerPartListRequest; skipResponseHandling?: boolean; skipReloadQuanities?: boolean }) =>
            Data.addWholesalerPartList(request),
        {
            onSuccess: (response, { request, skipResponseHandling, skipReloadQuanities }) => {
                if (!skipResponseHandling) {
                    if (response) {
                        handleBasketUpdateWorkflow(request.workTaskId, response)
                    }

                    // Save work task in the DB
                    Container.getInstance(RegisteredModels.Worktask_BasketActivityDone).subscribe().load()
                    if (!skipReloadQuanities) {
                        // The Add to basket buttons require the quantites added in the basket for the badge
                        request.wholesalerParts.forEach((article) => {
                            if (article.wholesalerArticleNumber) {
                                reloadWholesalerArticleQuantities(request.workTaskId, article)
                            }
                        })
                    }
                }
            },
        }
    )
    const addWholesalerParts = useCallback(
        (request: AddWholesalerPartListRequest, skipResponseHandling?: boolean, skipReloadQuanities?: boolean) => {
            if (basketModule?.addToNextBasket !== false) {
                return addWholesalerPartList({ request, skipResponseHandling, skipReloadQuanities })
            }
            if (basketModule.sendArticlesPostMessage) {
                const message = getMessageByWholsalerPart(request.wholesalerParts, request.workTaskId)
                handlePostSendOrderAction(request.workTaskId, message)
            }
            return Promise.reject(new Error("no function"))
        },
        [basketModule?.addToNextBasket, basketModule.sendArticlesPostMessage, getMessageByWholsalerPart]
    )

    return addWholesalerParts
}
