import { CalculationState } from "./model"

export const DEFAULT_STATE: CalculationState = {
    items: [],
    works: [],
    erpArticles: [],
    totals: {
        totalSparePartsPrice: 0,
        totalWorksPrice: 0,
        totalNetPrice: 0,
        totalGrossPrice: 0,
        discount: 0,
        discountTotal: 0,
    },
    initialSparePartsPrice: 0,
}
