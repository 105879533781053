import { useMemo } from "react"
import { useLocalization } from "@tm/localization"
import { Box, Typography, Icon, Loader } from "@tm/components"
import { CisCustomerResponse, CisTour } from "@tm/models"
import { getNextTourFromTourArray } from "../../business/helper"

type Props = {
    tours?: Array<CisTour>
    customer?: CisCustomerResponse
    toursLoading?: boolean
    customerLoading?: boolean
}

export default function NextTourComponent({ tours, customer, customerLoading, toursLoading }: Props) {
    const { translate, date } = useLocalization()

    const showTourStartTime = useMemo(() => {
        return tours?.some((tour) => !!tour.tourStartTime)
    }, [tours])

    if (customerLoading || toursLoading) {
        return <Loader />
    }

    if (tours && tours.length) {
        const nextTour = getNextTourFromTourArray(tours)

        if (nextTour && (nextTour.orderAcceptanceUntil || nextTour.tourStartTime)) {
            return (
                <Box display="flex" flexDirection="column">
                    <Icon name="next-tour" height="26px" width="26px" />
                    <Typography variant="h4" color="primary">
                        {translate(showTourStartTime ? 433 : 1129)}
                    </Typography>
                    {!!nextTour.tourStartTime && showTourStartTime && (
                        <Typography variant="h3" fontWeight="bold">
                            {date(nextTour.tourStartTime, "t")}
                        </Typography>
                    )}
                    {!!nextTour.orderAcceptanceUntil && (
                        <>
                            {showTourStartTime && <Typography variant="h4">{`${translate(1129)}: `}</Typography>}
                            <Typography variant="h3" fontWeight="bold">
                                {date(nextTour.orderAcceptanceUntil, "t")}
                            </Typography>
                        </>
                    )}
                </Box>
            )
        }
        return null
    }
    if (customer) {
        return (
            <Box display="flex" flexDirection="column" alignItems="center">
                <Icon name="next-tour" height="60px" width="60px" />
                <Typography variant="h3" color="primary">
                    {translate(433)}
                </Typography>
                <Typography variant="h3">{customer.nextTourInfo}</Typography>
            </Box>
        )
    }
    return null
}
