import { classes, useUser } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { useSelector } from "react-redux"
import { Box, Loader, Typography, styled } from "@tm/components"
import { getCurrencyFromUserContext } from "../../../data/helpers/userContextMethods"
import { calculationSelector } from "../../main/business"

type PanelDataModel = {
    panel: string
    total: string
    texts: { panelTitle: number }
    count?: number
}

export default function Totals() {
    const { translateText, currency } = useLocalization()
    const user = useUser()
    const currencyString = getCurrencyFromUserContext(user?.userContext)

    const { totals, items, works, initialSparePartsPrice, updateTotalsInProgress } = useSelector(calculationSelector)

    const showInitialSparePrice = items?.filter((x) => !!x.selectedPart).length > 0 && initialSparePartsPrice

    const panels: Array<PanelDataModel> = [
        {
            panel: "parts",
            count: items?.length,
            total: currency(totals.totalSparePartsPrice, currencyString),
            texts: { panelTitle: 90 },
        },
        {
            panel: "repair-times",
            count: works?.length ?? 0,
            total: currency(totals.totalWorksPrice, currencyString),
            texts: { panelTitle: 83 },
        },
        {
            panel: "total",
            total: currency(totals.totalNetPrice, currencyString),
            texts: { panelTitle: 62 },
        },
        {
            panel: "total vat",
            total: currency(totals.totalGrossPrice, currencyString),
            texts: { panelTitle: 263 },
        },
    ]

    const renderPanelSection = (data: PanelDataModel) => {
        const panelTitle = (data.count && `${data.count} ${translateText(data.texts.panelTitle)}`) || translateText(data.texts.panelTitle)
        return (
            <StyledBox className={classes(data.panel)}>
                <StyledTypography>{panelTitle}</StyledTypography>
                <Box className={classes("price", updateTotalsInProgress && "updating")}>
                    <StyledPriceTypography className="value">{data.total}</StyledPriceTypography>
                    {showInitialSparePrice && data.panel === "parts" && (
                        <Typography fontWeight="600" marginLeft="1.6em" className="value additional-info">
                            ({`${translateText(12564)}: ${currency(initialSparePartsPrice!, currencyString)}`})
                        </Typography>
                    )}
                </Box>
                {updateTotalsInProgress && (
                    <Box style={{ position: "absolute", top: "60%", left: "50%", zIndex: "2" }}>
                        <Loader size="extrasmall" />
                    </Box>
                )}
            </StyledBox>
        )
    }

    return (
        <Box
            sx={{
                alignContent: "center",
                height: "110px",
                display: "inline-flex",
                flex: "1",
                width: "100%",
                marginBottom: "1.8em",
                justifyContent: "space-between",
            }}
            className="articles-summary"
        >
            {panels.map(renderPanelSection)}
        </Box>
    )
}

const StyledBox = styled(Box)({
    backgroundColor: "transparent",
    boxShadow: "none",
    borderBottom: ".4em solid #c3c3c3",
    width: "23%",
    marginBottom: "0",
    marginTop: "1.1em",
})

const StyledTypography = styled(Typography)({
    marginTop: "auto",
    fontSize: "1.4rem",
    fontWeight: "200",
    marginLeft: "1.4em",
})

const StyledPriceTypography = styled(Typography)({
    fontSize: "1.5rem",
    fontWeight: "600",
    textIndent: "1rem",
    marginLeft: "0.5em",
})
