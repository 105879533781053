import { useReloadCisReturnItemsTotalNumbers } from "@tm/context-distribution"
import { AddReturnItemRequest, CisContainer, RegisteredModels } from "@tm/models"
import { Container } from "@tm/nexus"
import { useMutation } from "react-query"
import { useOrderDetails } from "./useOrderDetails"

export function useAddReturnItem(voucherId: string | undefined) {
    const { invalidateShowOrderDetails } = useOrderDetails(voucherId)
    const { resetCisReturnItemsTotalNumbers } = useReloadCisReturnItemsTotalNumbers()

    const mutation = useMutation(
        (request: AddReturnItemRequest) => {
            const container: CisContainer = Container.getInstance(RegisteredModels.CIS)
            return container.action("addReturnItem")(request)
        },
        {
            onSuccess: () => {
                invalidateShowOrderDetails()
                resetCisReturnItemsTotalNumbers()
            },
        }
    )

    return { addReturnItem: mutation.mutateAsync }
}
