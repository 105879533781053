import { Accordion, AccordionDetails, MuiAccordionSummary, Box, CheckSharp, Icon, Loader, PanoramaFishEyeRounded, styled, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { Dialog, Scrollbar } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { channel } from "@tm/models"
import { useVehicleModelMemoInfo } from "@tm/utils"
import { useEffect, useRef, useState } from "react"
import { VehicleMemoInfo } from "./components"

function VehicleMemoInfoDialog() {
    const { translateText } = useLocalization()
    const dialogRef = useRef<Dialog>(null)
    const { vehicle } = useWorkTask()?.workTask ?? {}
    const [expandedPanels, setExpandedPanels] = useState<string[]>([])

    const { tecDocManufacturerId: manufacturerId, tecDocModelId: modelSeriesId, tecDocTypeId: tecdoc, engineCode } = vehicle ?? {}

    const { vehicleMemoInfo, saveVehicleMemoInfo, vehicleMemoInfoError, vehicleMemoInfoIsLoading, showVehicleMemoDialog, memoWasConfirmed, stepsChanged, savedCompletedSteps, updateSavedCompletedSteps } = useVehicleModelMemoInfo({
        manufacturerId,
        modelSeriesId,
        tecdoc,
        engineCode
    },
        vehicle?.id)

    const [completedSteps, setCompletedSteps] = useState<Record<string, string[]>>(savedCompletedSteps ?? {})
    const { memoForManufacturer, memoForModel, memoForTecdoc, memoForEngineCode } = vehicleMemoInfo ?? {}

    useEffect(() => {
        const unsub = channel("GLOBAL").subscribe("MEMOTOOL/OPEN_VEHICLE_MEMO_DIALOG", params => {
            dialogRef?.current?.show()

            if (vehicle?.id)
                setCompletedSteps(params.completedSteps)
        })

        return () => {
            unsub?.()
        }
    }, [])

    useEffect(() => {
        if (showVehicleMemoDialog) {
            dialogRef?.current?.show()

            setCompletedSteps(savedCompletedSteps ?? {})
        }
    }, [showVehicleMemoDialog])

    useEffect(() => {
        if (vehicle?.id && !!savedCompletedSteps && (savedCompletedSteps[vehicle.id]?.length ?? 0) > 0 && (completedSteps?.[vehicle.id]?.length ?? 0) === 0)
            setCompletedSteps(savedCompletedSteps)
    }, [savedCompletedSteps])

    useEffect(() => {
        if (stepsChanged && vehicle?.id && !!completedSteps?.[vehicle.id]) {
            setCompletedSteps(state => ({
                ...state,
                [vehicle.id]: state[vehicle.id].filter(x => x != "engine-code")
            }))
        }
    }, [vehicle?.engineCode, stepsChanged])

    const steps = [
        ...(memoForManufacturer && vehicle?.id ? [{
            label: translateText(13180),
            sectionName: "manufacturer",
            content: <VehicleMemoInfo data={memoForManufacturer} section="manufacturer" handleConfirmProp={(section) => handleConfirmSection(section)} isConfirmed={completedSteps[vehicle.id]?.includes("manufacturer")} />,
            confirmed: completedSteps[vehicle.id]?.includes("manufacturer")
        }] : []),
        ...(memoForModel && vehicle?.id ? [{
            label: translateText(13181),
            sectionName: "model",
            content: <VehicleMemoInfo data={memoForModel} section="model" handleConfirmProp={(section) => handleConfirmSection(section)} isConfirmed={completedSteps[vehicle.id]?.includes("model")} />,
            confirmed: completedSteps[vehicle.id]?.includes("model")
        }] : []),
        ...(memoForTecdoc && vehicle?.id ? [{
            label: translateText(13182),
            sectionName: "tecdoc",
            content: <VehicleMemoInfo data={memoForTecdoc} section="tecdoc" handleConfirmProp={(section) => handleConfirmSection(section)} isConfirmed={completedSteps[vehicle.id]?.includes("tecdoc")} />,
            confirmed: completedSteps[vehicle.id]?.includes("tecdoc")
        }] : []),
        ...(memoForEngineCode && vehicle?.id ? [{
            label: translateText(13185),
            sectionName: "engine-code",
            content: <VehicleMemoInfo data={memoForEngineCode} section="engine-code" handleConfirmProp={(section) => handleConfirmSection(section)} isConfirmed={completedSteps[vehicle.id]?.includes("engine-code")} />,
            confirmed: completedSteps[vehicle.id]?.includes("engine-code")
        }] : [])
    ]

    const handleConfirmSection = (label: string) => {
        if (vehicle?.id) {
            setCompletedSteps(state => ({
                ...state,
                [vehicle.id]: !!state?.[vehicle.id] ? [...state[vehicle.id], label] : [label]
            }))
        }
    }

    const handleChange = (panelId: string) => {
        if (!expandedPanels?.includes(panelId)) {
            setExpandedPanels([...expandedPanels, panelId])
        } else {
            setExpandedPanels([...expandedPanels].filter((x) => x !== panelId))
        }
    }

    const handleConfirm = () => {
        saveVehicleMemoInfo?.(JSON.stringify(vehicleMemoInfo)?.hashCode()?.toString())
        updateSavedCompletedSteps?.(JSON.stringify(completedSteps))
    }

    const renderSteps = () => {
        const initialPanel = expandedPanels?.length === 0 ? steps?.find(x => !x.confirmed)?.sectionName : undefined

        return (
            <StepperBox>
                <Scrollbar>
                    <Box>
                        {steps?.map((section) => (
                            <Accordion
                                key={section.sectionName}
                                expanded={initialPanel === section.sectionName || expandedPanels?.includes(`${section.sectionName}`)}
                                onChange={() => handleChange(`${section.sectionName}`)}
                            >
                                {/* TODO: Check if it can be migrated to the existing customized `AccordionSummary` from @tm/components */}
                                <MuiAccordionSummary
                                    expandIcon={<Icon name="arrows_down" />}
                                    aria-controls={`${section.sectionName}_content`}
                                    id={`${section.sectionName}-header`}
                                >
                                    {section.confirmed ? <CheckSharp fontSize="small" color="success" /> : <PanoramaFishEyeRounded fontSize="small" />}
                                    <Typography sx={{ marginLeft: ".5em" }}>{section.label}</Typography>
                                </MuiAccordionSummary>
                                <AccordionDetails sx={{ display: "flex" }}>{section.content}</AccordionDetails>
                            </Accordion>
                        ))}
                    </Box>
                </Scrollbar>
            </StepperBox>
        )
    }

    return (
        <StyledDialog
            ref={dialogRef}
            text={translateText(13016)}
            layout="stretch"
            iconName="atu-logo-filled"
            hideCloseButton={!memoWasConfirmed}
            confirmButtonDisabled={memoWasConfirmed || (!!vehicle?.id && completedSteps[vehicle.id]?.length !== steps?.length)}
            confirmButtonText={translateText(13017)}
            onConfirm={handleConfirm}
            doNotCloseOnConfirm={true}
            closeOnlyWithDialogInteraction={true}
        >
            {vehicleMemoInfoError && <Typography variant="h3">{translateText(558)}</Typography>}
            {vehicleMemoInfoIsLoading && <Loader />}

            {!vehicleMemoInfoError && !vehicleMemoInfoIsLoading &&
                renderSteps()}

        </StyledDialog>
    )
}

const StyledDialog = styled(Dialog)({
    ".dialog-prompt__inner": {
        top: 0,
        ".dialog-prompt__icon > svg": {
            width: "4em",
            opacity: 1
        }
    },
})

const StepperBox = styled(Box)({
    display: "flex",
    flex: 1,
    flexDirection: "column",
    height: "100%"
})

export default VehicleMemoInfoDialog
