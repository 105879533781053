import { Suspense, useState, useEffect } from "react"
import { channel, IMicros, OrderWarehouseInfo, ShowWarehousesResponse } from "@tm/models"
import { useTelesalesCustomerNumber } from "@tm/context-distribution"
import { useDefaultErpSystem } from "@tm/utils"
import { Box, MenuItemWidget, Select, styled } from "@tm/components"
import { showWarehouses } from "../../data"

type Props = IMicros["erp"]["article-warehouse-selector"]

const StyledSelect = styled(Select)(({ theme }) => ({
    "&.Mui-focused": {
        backgroundColor: theme?.colors?.primary,
    },
}))

function ArticleWarehouseSelectorComponent({ data }: Props) {
    const [orderWarehouseResponse, setOrderWarehouseResponse] = useState<ShowWarehousesResponse>()
    const { telesalesCustomerNo } = useTelesalesCustomerNumber()
    const { erpSystemConfig } = useDefaultErpSystem()
    const [selectedWarehouse, setSelectedWarehouse] = useState<OrderWarehouseInfo>()

    const handleChange = (warehouse: OrderWarehouseInfo) => {
        if (data) {
            channel("WORKTASK").publish("ARTICLE/ARTICLE_WAREHOUSE_CHANGED", { articleId: data.id, warehouse })
        }
        setSelectedWarehouse(warehouse)
    }

    useEffect(() => {
        let unmounted = false

        showWarehouses({ telesalesCustomerNo, distributorId: erpSystemConfig?.id }).then((response) => {
            if (unmounted) {
                return
            }

            setOrderWarehouseResponse(response)

            const warehouse = response?.orderWarehouses?.find((w) => w.isDefault)

            if (data && warehouse) {
                setSelectedWarehouse(warehouse)
                // Is this actually needed? All the places I found that use this also call showDefaultWarehouse.
                channel("WORKTASK").publish("ARTICLE/ARTICLE_WAREHOUSE_CHANGED", { articleId: data.id, warehouse })
            }
        })

        return () => {
            unmounted = true
        }
    }, [telesalesCustomerNo, erpSystemConfig?.id])

    const items: Array<OrderWarehouseInfo> = []
    orderWarehouseResponse?.orderWarehouses?.forEach((warehouse) => {
        items.push({ id: warehouse.id, name: warehouse.name })
    })

    return (
        <Box>
            {!!items.length && selectedWarehouse && (
                <StyledSelect
                    color="primary"
                    value={items.find((warehouse) => warehouse.id === selectedWarehouse.id)}
                    variant="filled"
                    onChange={(e) => handleChange(e.target.value as OrderWarehouseInfo)}
                    disabled={items.length <= 1}
                >
                    {items.map((item) => (
                        <MenuItemWidget value={item} key={item.id}>
                            {item.name}
                        </MenuItemWidget>
                    ))}
                </StyledSelect>
            )}
        </Box>
    )
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={null}>
            <ArticleWarehouseSelectorComponent {...props} />
        </Suspense>
    )
}
