import { StateCreator } from "zustand"
import { MainSlice, MainState } from "./model"
import { SindriStore } from ".."
import { setSelectedJob, setShowLoginDialog, setSindriCredentials, setSindriToken, sindriLogin } from "./mainActions"

const initialState: MainState = {
    main: {
        sindriToken: "",
        showLoginDialog: false,
    },
}

export const createMainSlice: StateCreator<SindriStore, [["zustand/devtools", never]], [], MainSlice> = (set) => ({
    ...initialState,
    mainActions: {
        setSindriToken: (token) => set((state) => setSindriToken(state, token)),
        setShowLoginDialog: (showLoginDialog) => set((state) => setShowLoginDialog(state, showLoginDialog)),
        setSindriCredentials: (sindriCredentials) => set((state) => setSindriCredentials(state, sindriCredentials)),
        sindriLogin: (sindriCredentials, loginResponse) => set((state) => sindriLogin(state, sindriCredentials, loginResponse)),
        setSelectedJob: (jobId) => set((state) => setSelectedJob(state, jobId)),
    },
    resetStore: () => set(reset, false, "Reset Main Slice"),
})

function reset(): Partial<MainSlice> {
    return {
        ...initialState,
    }
}
