import { CategoryType, channel, RequestArticleListPayload, SearchFilters, SearchTreeProductGroup, TreeNode, Vehicle, TmaEModule } from "@tm/models"
import { AsyncAction } from "@tm/morpheus"
import { Dictionary, TmaHelper } from "@tm/utils"

import { BundleActions, BundleActionType, TabInfo } from "../../../business"
import { getQueryHistory } from "../../_shared/helper/queryhistory"

import { TreeConfig, WidgetState } from "./model"

export type ComponentActionType =
    | BundleActionType
    | { type: "INITIALIZE_TREES"; payload: { trees: Dictionary<TreeConfig>; vehicle?: Vehicle } }
    | { type: "SET_ACTIVE_CATEGORY"; payload: { category: CategoryType; manuallySelected: boolean } }
    | { type: "SEARCH_TREE_LOADING"; payload: CategoryType }
    | {
          type: "SEARCH_TREE_LOADED"
          payload: {
              category: CategoryType
              nodes: Array<TreeNode>
              topProductGroups: Array<SearchTreeProductGroup>
              parentNode?: TreeNode
          }
      }
    | { type: "CHANGE_BREADCRUMBS"; payload: Array<TreeNode> }

export type IActions = typeof Actions

function initializeTrees(trees: Dictionary<TreeConfig>, vehicle?: Vehicle): ComponentActionType {
    return { type: "INITIALIZE_TREES", payload: { trees, vehicle } }
}

function setActiveCategory(category: CategoryType, manuallySelected: boolean): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory } = getState()

        // If the supplied tree type is already active exit
        if (category === activeCategory) {
            return
        }

        dispatch({ type: "SET_ACTIVE_CATEGORY", payload: { category, manuallySelected } })
    }
}

function requestArticleList(request: RequestArticleListPayload): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { trees } = getState()

        Object.keys(trees).forEach((key) => {
            const tree = trees[key]
            if (!tree) {
                return
            }

            // Pass the current state of the searchTree to the searchTree component on the article list
            // Give a little delay for remount
            window.setTimeout(() => {
                dispatch(BundleActions.setSearchTreeData(key as CategoryType, tree.groups, tree.breadcrumbs, tree.selectedNode))
            }, 250)
        })

        channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
    }
}

function applyProductGroups(productGroups: Array<SearchTreeProductGroup>): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory, trees } = getState()
        const activeTree = trees[activeCategory]

        if (activeTree?.selectedNode) {
            // Clear the selectedNode by changing the breadcrumbs
            dispatch({ type: "CHANGE_BREADCRUMBS", payload: activeTree.breadcrumbs })
        }

        const productGroupIds = productGroups.map((x) => x.id).orderBy((x) => x)
        const activeParentNode = activeTree?.breadcrumbs?.last()

        switch (activeCategory) {
            case "vehicleParts": {
                TmaHelper.ArticleListFiltered.ArticleListFiltered.Search.NodeSelected({
                    mode: "fast-click-2.0",
                    origin: TmaEModule.DASHBOARD,
                    nodeId: activeParentNode?.id || 0,
                    treeId: activeTree.treeId,
                })

                dispatch(
                    requestArticleList({
                        productGroups: {
                            ids: productGroupIds,
                            treeId: activeTree.treeId,
                            nodeId: activeParentNode?.id,
                        },
                    })
                )
                break
            }

            case "universalParts": {
                TmaHelper.UniParts.SearchTree.NodeSelected({
                    mode: "fast-click-2.0",
                    origin: TmaEModule.DASHBOARD,
                    nodeId: activeParentNode?.id || 0,
                    treeId: activeTree.treeId,
                })

                dispatch(requestArticleList({ uniProductGroups: { ids: productGroupIds } }))
                break
            }

            case "predictive": {
                dispatch(requestArticleList({ productGroups: { ids: productGroupIds } }))
                break
            }

            default:
                break
        }
    }
}

function searchArticles(query: string, searchFilter?: SearchFilters, extendedSearch?: boolean): AsyncAction<ComponentActionType, WidgetState> {
    return (dispatch, getState) => {
        const { activeCategory } = getState()
        const request: RequestArticleListPayload & { direct?: { extendedSearch?: boolean } } = {}

        const tabInfo: TabInfo = {
            moduleFilter: `"${query}"`,
        }

        switch (activeCategory) {
            case "vehicleParts": {
                request.synonym = { query }
                tabInfo.moduleView = "{{389}}"
                break
            }
            case "universalParts": {
                request.uniSearch = { query }
                tabInfo.moduleView = "{{1009}}"
                break
            }
            case "directSearch": {
                request.direct = { query, searchFilter, extendedSearch }
                tabInfo.moduleView = "{{389}}"
                break
            }
            default:
        }
        dispatch({ type: "SET_TAB_INFO", payload: tabInfo })
        dispatch(requestArticleList(request))
    }
}

function loadQueryHistory(): AsyncAction<ComponentActionType> {
    return (dispatch) => {
        getQueryHistory().then((history) => dispatch({ type: "QUERY_HISTORY_LOADED", payload: history }))
    }
}

export const Actions = {
    ...BundleActions,

    initializeTrees,
    setActiveCategory,

    applyProductGroups,

    searchArticles,
    loadQueryHistory,
}
