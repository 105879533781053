import { getStyleTheme } from "@tm/context-distribution"
import { NestedCSSProperties } from "typestyle/lib/types"
import color from "tinycolor2"
import deepAssign from "deep-assign"

export function getItemStyles(baseStyles: NestedCSSProperties) {
    const { colors, opacity, pageNavigation } = getStyleTheme()
    const component = {
        fore: pageNavigation && pageNavigation.itemColor ? pageNavigation.itemColor : color(colors.light).setAlpha(opacity.secondary).toRgbString(),
        active: {
            back: color(colors.dark).lighten(84).toString(),
            fore: colors.dark,
        },
    }

    const themeStyles: NestedCSSProperties = {
        backgroundColor: "transparent",
        color: "#959595",
        borderLeftWidth: "1px",
        borderRightWidth: 0,
        borderLeftColor: "#ddd",
        borderRadius: 0,
        height: "100%",
        margin: 0,
        $nest: {
            "&:last-child": {
                borderRightWidth: "1px",
                borderRightColor: "#ddd",
            },
            "&:hover:not(&--selected)": {
                backgroundColor: "rgba(0, 0, 0, 0.03)",
                borderLeftColor: "#ddd",
                color: colors.primary,
                $nest: {
                    ".icon": {
                        fill: colors.primary,
                    },
                },
            },
            "&__info:not(:empty)": {
                marginTop: "0.25rem",
            },
            "&__close": {
                $nest: {
                    ".icon": {
                        fill: "#959595",
                    },
                    "&:hover .icon": {
                        fill: `${color(component.active.fore).setAlpha(0.54).toRgbString()} !important`,
                    },
                },
            },
            "&--selected, &:active": {
                backgroundColor: "transparent",
                borderBottomColor: colors.secondary,
                color: colors.secondary,
                borderLeftColor: "#ddd",
            },
            "&--selected": {
                transform: "none",
            },
            "&--selected .icon, &:active .icon": {
                fill: colors.secondary,
            },
            "&--more": {
                border: "none",
            },
            "&__telesales__info": {
                fontSize: "1rem",
                position: "static",
            },
            "&__telesales__title": {
                fontSize: "0.6875rem",
                position: "static",
                order: 2,
            },
        },
    }
    const mergedStyles = deepAssign(baseStyles, themeStyles)
    mergedStyles.$nest && delete mergedStyles.$nest["&:first-of-type:not(&--selected)"]
    return mergedStyles
}

export function getOverflowMenuStyles(baseStyles: NestedCSSProperties): NestedCSSProperties {
    const { colors, opacity } = getStyleTheme()
    const themeStyles: NestedCSSProperties = {
        $nest: {
            "&__btn": {
                borderLeft: "none",
                height: "100%",
                $nest: {
                    ".icon": {
                        fill: `#959595 !important`,
                    },
                    "&:hover": {
                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                        $nest: {
                            ".icon": {
                                fill: `${colors.primary} !important`,
                            },
                        },
                    },
                },
            },
        },
    }
    return deepAssign(baseStyles, themeStyles)
}
