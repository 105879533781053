import { useMutation, UseMutateAsyncFunction, useQueryClient } from "react-query"
import { loadOutlet, Outlet } from "../repositories"
import { EMPLOYEES_FIND_REQUEST_KEY } from "./useEmployees"
import { WORKTASK_REQUEST_KEYS } from "./useShowWorktask"

const REQUEST_KEY = "OUTLET_LOAD"

export const useFindSalesOutlet = (): {
    loadOutlet: UseMutateAsyncFunction<Outlet[]>
    isLoading: boolean
} => {
    const queryClient = useQueryClient()

    const mutation = useMutation(REQUEST_KEY, loadOutlet, {
        onSuccess: () => {
            queryClient.resetQueries(EMPLOYEES_FIND_REQUEST_KEY)
            queryClient.resetQueries(WORKTASK_REQUEST_KEYS.WORKTASK_LOAD)
        },
    })

    return { loadOutlet: mutation.mutateAsync, isLoading: mutation.isLoading }
}
