import { ClickAwayListener, MenuItem, MenuList } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, TmaHelper } from "@tm/utils"
import { KeyboardEvent, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory, useLocation } from "react-router"
import { Hit, getProductGroupSuggestions } from "../../../../../data/repositories"
import { getBundleParams } from "../../../../../utils"
import { usePartsRoutes } from "../../../hooks/usePartsRoutes"
import { useVehicle } from "../../../hooks/useVehicle"
import { SearchField } from "./SearchField"
import { Suggestions } from "./Suggestions"

export function VehiclePartsSearch() {
    const vehicle = useVehicle()
    const { workTaskId } = useWorkTask() ?? {}
    const { translateText } = useLocalization()
    const { search } = useLocation()
    const searchFieldRef = useRef<HTMLDivElement | null>(null)
    const timerRef = useRef<ReturnType<typeof setTimeout>>()
    const urlQuery = useMemo(() => new URLSearchParams(search).get("query") ?? "", [search])
    const [query, setQuery] = useState(urlQuery)
    const [suggestions, setSuggestions] = useState<Hit[]>([])
    const routes = usePartsRoutes()
    const history = useHistory()
    const [selectedSuggest, setSelectedSuggest] = useState<string>()
    const [showSuggestions, setShowSuggestions] = useState(false)

    useEffect(() => {
        setQuery(urlQuery)
    }, [urlQuery])
    
    useEffect(() => {
        clearTimeout(timerRef.current)
        if (query.length >= 2) {
            timerRef.current = setTimeout(async () => {
                const result = await getProductGroupSuggestions(query, vehicle?.tecDocTypeId)
                if (result) {
                    setSuggestions(result)
                } else {
                    setSuggestions([])
                }
            }, 200)
        } else {
            setSuggestions([])
        }
    }, [query, vehicle])

    const handleChange = useCallback((value: string) => {
        setQuery(value)
        setShowSuggestions(true)
    }, [])

    const handleStartSearch = useCallback(
        (directQuery?: string) => {
            const route = routes.vehicleParts?.list
            if (!route || !workTaskId) {
                return
            }
            const searchQuery = directQuery ?? selectedSuggest ?? query
            if (searchQuery.length < getBundleParams().minimumSearchLength.vehicleSearch ?? 2) {
                return
            }
            const baseUrl = renderRoute(route, { workTaskId: encodeUniqueId(workTaskId) })

            history.push(`${baseUrl}/synonym?query=${encodeURIComponent(searchQuery)}`)
            setQuery(searchQuery)
            setShowSuggestions(false)
        },
        [routes, history, query, selectedSuggest]
    )

    const handleKeyStroke = useCallback(
        (e: KeyboardEvent) => {
            const selectionIndex = suggestions.findIndex((x) => x.productGroup === selectedSuggest)
            if (e.code === "ArrowUp") {
                e.preventDefault()
                if (selectionIndex <= 0) {
                    setSelectedSuggest(suggestions[suggestions.length - 1]?.productGroup)
                } else {
                    setSelectedSuggest(suggestions[selectionIndex - 1]?.productGroup)
                }
            } else if (e.code === "ArrowDown") {
                e.preventDefault()
                if (selectionIndex === -1 || selectionIndex === suggestions.length - 1) {
                    setSelectedSuggest(suggestions[0]?.productGroup)
                } else {
                    setSelectedSuggest(suggestions[selectionIndex + 1]?.productGroup)
                }
            }
        },
        [selectedSuggest, suggestions]
    )

    return (
        <>
            <ClickAwayListener onClickAway={() => setShowSuggestions(false)}>
                <SearchField
                    id="vehiclePartsSearchQuery"
                    value={query}
                    ref={searchFieldRef}
                    onChange={handleChange}
                    onClick={() => setShowSuggestions(true)}
                    onStartSearch={handleStartSearch}
                    onKeyDown={handleKeyStroke}
                    placeholder={translateText(998)}
                />
            </ClickAwayListener>

            {searchFieldRef.current && (
                <Suggestions anchor={searchFieldRef.current} isOpen={showSuggestions && !!suggestions.length}>
                    <MenuList variant="menu">
                        {suggestions.map((x) => (
                            <MenuItem
                                selected={selectedSuggest === x.productGroup}
                                key={x.productGroup}
                                onClick={() => handleStartSearch(x.productGroup)}
                            >
                                {x.productGroup}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Suggestions>
            )}
        </>
    )
}
