import { useQuery } from "react-query"
import { useErpInfoEnabled } from "@tm/context-distribution"
import * as Data from "../../.."
import { CalculateWorkTaskBasketRequest } from "../../../model"
import { useWorkTaskBasketStore } from "../workflow/useWorkTaskBasketStore"

const KEY = "basket_useCalculateWorkTaskBasket"

export function useCalculateWorkTaskBasketData(
    workTaskId: string,
    request: CalculateWorkTaskBasketRequest,
    workEstimationLoaded: boolean,
    erpDataLoaded: boolean
) {
    const isErpInfoEnabled = useErpInfoEnabled()
    const basketCalculationIndex = useWorkTaskBasketStore((store) => store.getBasketCalculationIndex(workTaskId))
    const QUERY_KEY = [KEY, workTaskId, basketCalculationIndex]

    function isEnabled() {
        // No need to calculate when no works or parts are available
        if (!request.works?.length && !request.parts?.length) {
            return false
        }

        // Cannot be calculated yet if works are available but the work estimation is not done yet
        if (request.works?.length && !workEstimationLoaded) {
            return false
        }

        // Cannot be calculated yet if parts are available and ERP data should be loaded but isn't yet
        if (request.parts?.length && isErpInfoEnabled && !erpDataLoaded) {
            return false
        }

        return true
    }

    const { data, isLoading, isError } = useQuery(
        QUERY_KEY,
        () => {
            return Data.calculateWorkTaskBasket(request)
        },
        {
            enabled: isEnabled(),
            staleTime: 10 * 60 * 1000, // 10 Minutes
        }
    )

    return {
        workTaskBasketCalculation: data,
        workTaskBasketCalculationLoading: isLoading,
        workTaskBasketCalculationWithError: isError,
    }
}
