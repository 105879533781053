import { ControllerFieldState, ControllerRenderProps, FieldPath, FieldValues } from "react-hook-form"
import { MenuItem, TextField, styled } from "@tm/components"
import { ForwardedRef, forwardRef } from "react"
import FormItem from "./FormItem"

type Options = {
    key: number
    value: string
}

type Props = {
    options: Options[]
    fieldState: ControllerFieldState
    label: string
}

export const ComboBoxFormItem = forwardRef(function ComboBoxFormItem<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
    { label, options, fieldState, name, value, onChange, onBlur }: Props & ControllerRenderProps<TFieldValues, TName>,
    ref: ForwardedRef<HTMLDivElement>
) {
    return (
        <FormItem fieldState={fieldState}>
            <StyledComboBox
                ref={ref}
                required
                name={name}
                sx={{ minWidth: "100px", height: "48px" }}
                select
                label={label}
                size="extralarge"
                variant="filled"
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                error={Boolean(fieldState.error)}
            >
                {options.map((option) => (
                    <MenuItem key={option.key} value={option.key}>
                        {option.value}
                    </MenuItem>
                ))}
            </StyledComboBox>
        </FormItem>
    )
})

const StyledComboBox = styled(TextField)({
    ".MuiFilledInput-root": {
        height: "48px",
    },
})
