import { Tag, TagIcon, TagProps } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ArticleReference, ModificationState, channel } from "@tm/models"
import { concat, copyToClipboard } from "@tm/utils"
import { getBundleParams } from "../../../../utils"

type Props = {
    reference: ArticleReference
    tooltip: string
    minWidth?: number
    onClick(reference: ArticleReference): void
}

export function ReferenceNumber({ reference, tooltip, minWidth, onClick }: Props) {
    const { translateText } = useLocalization()

    const { showCopyNumberButton } = getBundleParams()
    let className = "article__reference"
    const icons: TagIcon[] = []

    switch (reference.modificationState) {
        case ModificationState.Added:
            icons.push({ name: "plus" /* , tooltip: translateText(894) */ })
            className = concat(" ", className, "article__reference--added")
            break
        case ModificationState.Removed:
            icons.push({ name: "minus" /* , tooltip: translateText(895) */ })
            className = concat(" ", className, "article__reference--removed")
            break
        default:
            break
    }

    function getValue(): TagProps["value"] {
        const value = reference.referenceNo + (reference?.information ? ` (${reference.information})` : "")

        if (!reference.productGroup?.name) {
            return value
        }

        return [
            {
                value: reference.productGroup.name,
            },
            value,
        ]
    }

    function getButtons(): TagProps["buttons"] {
        if (!showCopyNumberButton) {
            return
        }

        if (showCopyNumberButton === true || showCopyNumberButton.includes("details--references")) {
            return [
                {
                    buttonIcon: "copy",
                    onClick: () => {
                        copyToClipboard(reference.referenceNo)
                        channel("APP").publish("TOAST_MESSAGE/SHOW", { message: translateText(1920) })
                    },
                },
            ]
        }
    }

    return (
        <div className="article-details__references__button" style={{ minWidth }}>
            <Tag
                className={className}
                layout="holo"
                value={getValue()}
                tooltip={tooltip}
                icons={icons}
                onClick={() => onClick(reference)}
                buttons={getButtons()}
            />
        </div>
    )
}
