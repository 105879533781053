import { useMemo } from "react"
import { usePartsModuleState } from "../../PartsModuleState"

export function useListType() {
    const listSubType = usePartsModuleState((x) => x.params.startParams.type)
    return useMemo(() => {
        switch (listSubType) {
            case "node":
            case "productgroups":
            case "synonym":
                return "vehicle"
            case "uninode":
            case "unisearch":
                return "uni"
            default:
                return listSubType
        }
    }, [listSubType])
}
