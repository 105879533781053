import { Box, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { BoxWithAlignItems, BoxWithColumnPaddingRight, IconWithMargin, ResponsivTextfield } from "./StyledComponents"
import { MemoLengthWarning } from "../../MemoLengthWarning"

type Props = {
    customOrderNumberValue?: string
    customerOrderCommentValue?: string
    customerOrderNumberMaxLength?: number
    hasCustomerOrderNumber: boolean
    hasOrderComment: boolean
    orderCommentMaxLength?: number
    onChangeNumber: (val: string) => void
    onChangeComment: (val: string) => void
}

export default function AdditionalInformationTextFieldsComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const {
        hasCustomerOrderNumber,
        hasOrderComment,
        customOrderNumberValue,
        customerOrderCommentValue,
        customerOrderNumberMaxLength,
        orderCommentMaxLength,
    } = props

    const maxLength = customerOrderNumberMaxLength ?? 100

    return (
        <Box>
            <BoxWithAlignItems>
                <IconWithMargin name="accessories" />
                <Typography variant="h4">{translate(526)}</Typography>
            </BoxWithAlignItems>
            <BoxWithColumnPaddingRight pl="35px" pt="4px">
                {hasCustomerOrderNumber && (
                    <Box mb={0.5}>
                        <ResponsivTextfield
                            clearButton
                            inputCounter
                            InputProps={{
                                endAdornment: <MemoLengthWarning currentLength={customOrderNumberValue?.length || 0} maxLength={maxLength} />,
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{
                                maxLength,
                            }}
                            label={translateText(1249).toUpperCase()}
                            value={customOrderNumberValue}
                            onChange={(e) => props.onChangeNumber(e.target.value)}
                        />
                    </Box>
                )}
                {hasOrderComment && (
                    <ResponsivTextfield
                        clearButton
                        inputCounter
                        inputProps={{
                            maxLength: orderCommentMaxLength ?? 200,
                        }}
                        label={translateText(1248).toUpperCase()}
                        value={customerOrderCommentValue}
                        onChange={(e) => props.onChangeComment(e.target.value)}
                    />
                )}
            </BoxWithColumnPaddingRight>
        </Box>
    )
}
