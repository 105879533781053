import getValue from "get-value"
import { TMessageKeys } from "@tm/hermes"
import { NextChannels, channel } from "@tm/models"
import { IPlugin, ModuleConfig, PluginProps } from "@tm/morpheus"

function getChannelId(pattern?: string) {
    if (!pattern) {
        return
    }
    switch (pattern.toUpperCase()) {
        case ":WORKTASKID": {
            return channel("GLOBAL").last(1, "WORKTASK/ID_CHANGED")[0]?.content?.id
        }
    }
    return pattern
}

function publish(message: Message) {
    const [chn, cid] = Array.isArray(message.channel) ? message.channel : [message.channel]
    channel(chn, getChannelId(cid)).publish(message.topic, message.content)
}

type Message<C extends keyof NextChannels = any, M extends TMessageKeys<NextChannels, C> = any> = {
    type: string
    channel: C | [C, string]
    topic: M
    content: NextChannels[C][M]
}

type MessageBusProps = PluginProps<ModuleConfig, { propertyPath: string }>

function MessageBus(props: MessageBusProps) {
    if (!props.module.props || !props.pluginProps.propertyPath) {
        return
    }
    const message = getValue(props.module.props, props.pluginProps.propertyPath)
    if (!message) {
        return
    }

    if (Array.isArray(message)) {
        message.forEach(publish)
        return
    }
    publish(message)
}

const plugin: IPlugin<ModuleConfig> = {
    name: "ModuleMountMessenger",
    type: "MODULE/MOUNT",
    plugin: MessageBus,
}

export default plugin
