import { VoucherType } from "@tm/models"
import { atomFamily, useRecoilValue, useSetRecoilState } from "recoil"

const actionButtonStatusAtom = atomFamily<boolean, VoucherType>({
    key: "vouchers__actionButtonStatusAtom",
    default: false,
})

export function useActionButtonStatus(voucherType: VoucherType) {
    return useRecoilValue(actionButtonStatusAtom(voucherType))
}

export function useSetActionButtonStatus(voucherType: VoucherType) {
    return useSetRecoilState(actionButtonStatusAtom(voucherType))
}
