import { useEffect } from "react"
import { Link } from "react-router-dom"
import { ECounterType, StylingFromConfig } from "@tm/models"
import { Button } from "@tm/controls"
import { useCountryCodeToLicensePlate } from "@tm/context-distribution"
import { TmaHelper, useIsUrlAvailableCached, useResizeObserver } from "@tm/utils"
import { VehicleThumbnail } from "@tm/components"
import { getTemplateElement } from "./getTemplateElement"
import { NavigationItem, DataType } from "../business"
import { getContentComponentsFromItem } from "../business/mapper"
import { getRect } from "../../../helpers"
import { ErrorBoundary } from "./errorBoundary"
import { getBundleParams } from "../../../utils"

type ComponentProps = StylingFromConfig &
    NavigationItem & {
        isSelected: boolean
        className: string
        dataType?: DataType
        onClose(id: string, e: Event): void
        onResize(entry: ResizeObserverEntry): void
    }

export function Item(props: ComponentProps) {
    const { plateCode } = useCountryCodeToLicensePlate()
    const { id, url, isSelected, onClose, onResize, className, dataType } = props
    const { thumbnailsOnEachWorktask } = getBundleParams()

    const [ref, unsubRef] = useResizeObserver<HTMLAnchorElement>((entry) => entry && onResize && onResize(entry))

    useEffect(
        () => () => {
            unsubRef && unsubRef()
        },
        []
    )

    useEffect(() => {
        !onResize && unsubRef && unsubRef()
    }, [onResize])

    function handleRef(el: HTMLAnchorElement | null) {
        if (!el) {
            return
        }
        ref(el)
        onResize && onResize({ target: el, contentRect: getRect(el, "bound") } as any)
    }

    const { isAvailable } = useIsUrlAvailableCached(props.vehicle?.modelThumbnail)
    const [title, subtitle] = getContentComponentsFromItem(
        {
            ...props,
            countryCode: plateCode,
            vehicle: props.vehicle ? { ...props.vehicle, modelThumbnail: isAvailable ? props.vehicle?.modelThumbnail : undefined } : undefined,
        },
        dataType
    )
    const titleElement = title.template ? getTemplateElement(title.template, title.content) : title.content
    const subtitleElement = subtitle.template ? getTemplateElement(subtitle.template, subtitle.content) : subtitle.content

    let titleClassName = `${className}__title`
    let infoClassName = `${className}__info info`

    if (dataType === "TELESALES") {
        titleClassName += ` ${className}__telesales__title`
        infoClassName += ` ${className}__telesales__info`
    }

    const handleCloseEvent = (e: Event) => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.LastActivities)
        onClose(id, e)
    }

    const vehicleThumbnail = isAvailable ? props.vehicle?.modelThumbnail : props.vehicle?.modelSeriesImage
    let pageNavigationClassName = `${className} worktaskItem`
    pageNavigationClassName += `${isSelected ? ` ${className}--selected selected` : ""}`
    pageNavigationClassName += `${thumbnailsOnEachWorktask ? ` ${className}--thumbnail` : ""}`

    return (
        <ErrorBoundary>
            <Link innerRef={handleRef} className={pageNavigationClassName} to={url} title="">
                {thumbnailsOnEachWorktask && vehicleThumbnail && <VehicleThumbnail src={vehicleThumbnail || ""} />}
                <div key={id} className={`${className}__content `}>
                    <div className={titleClassName} title={title.content}>
                        {titleElement}
                    </div>
                    <div className={infoClassName} title={subtitle.content}>
                        {subtitleElement}
                    </div>
                </div>
                <Button className={`${className}__close`} onClick={handleCloseEvent} icon="close" />
            </Link>
        </ErrorBoundary>
    )
}
