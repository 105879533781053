import { PanelSection, TabControl } from '@tm/controls'
import { useLocalization } from '@tm/localization'
import * as React from 'react'
import { useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { TireSizeDropDown, TechnicalData, MountingPads, TireSizes } from '.'
import { NavigationItem, TireSize } from '../../../data/model'
import { MainState } from '../../main'
import { Actions } from '../business'

type Props = {
}

const RimInformation: React.FC<Props> = ({ }) => {
    const [selectedTab, setSelectedTab] = useState("TechnicalData")
    const { translateText } = useLocalization()

    const { rimInfoTireSizesResponse, selectedTireSize, isConti } = useSelector((s: MainState) => ({ 
        rimInfoTireSizesResponse: s.wheelsDetails.rimInfoTireSizesResponse, 
        selectedTireSize: s.wheelsDetails.selectedTireSize ,
        isConti: s.wheelsDetails.rimItem?.isConti
    }))
    const { registrationNos } = useSelector((s: MainState) => s.vehicleSelection)
    const dispatch = useDispatch()

    const handleSelectTireSize = (size: TireSize) => {
        batch(() => {
            dispatch(Actions.selectTireSize(size))
            dispatch(Actions.loadTiresRestrictions())
        })
    }

    const rimDetailsTabs = React.useMemo((): NavigationItem[] => {
        if (registrationNos) {
            if(isConti){
                return [
                    { path: "TechnicalData", translationId: 941 },
                    { path: "MountingPads", translationId: 942 },
                ]
            }

            return [
                { path: 'TechnicalData', translationId: 941 }
            ]
        }
        else {
            return [
                { path: 'TechnicalData', translationId: 941 },
                { path: 'MountingPads', translationId: 942 },
                { path: 'WheelSize', translationId: 967 }
            ]
        }
    }, [registrationNos])

    return (
        <PanelSection className="rim-information" >
            <div className="tabs">
                <TabControl selectedTabIdentifier={selectedTab} onTabSelect={setSelectedTab}>
                    {rimDetailsTabs.map((tab, idx) => (
                        <TabControl.Tab identifier={tab.path} key={idx}>
                            {translateText(tab.translationId)}
                        </TabControl.Tab>))}
                </TabControl>

                <TireSizeDropDown
                    items={rimInfoTireSizesResponse.data}
                    selectedValue={selectedTireSize}
                    onChange={handleSelectTireSize}
                    loading={rimInfoTireSizesResponse.loading}
                />
            </div>

            {selectedTab == 'TechnicalData' && <TechnicalData isLoading={!!rimInfoTireSizesResponse.loading} hidePrintButton={registrationNos && isConti}/>}
            {selectedTab == 'MountingPads' && <MountingPads />}
            {selectedTab == 'WheelSize' && <TireSizes />}
        </PanelSection>
    )
}

export default RimInformation