import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import * as React from "react"
import { getEurotaxSCArticles } from "../../data/helpers"


type Props = IMicros["standalone"]["sc-eurotax-openOeArticleList"]


const OpenEurotaxOeArticleList: React.FC<Props> = ({ part, text, className, getGroups, btnRef, isActive, vehicle, hideOePrice }) => {
    const { translateText } = useLocalization()

    const handlePartSelect = () => {
        const data = getEurotaxSCArticles(part, getGroups?.() ?? [], vehicle?.manufacturer ?? "", translateText, hideOePrice)
        window.DVSE?.openOEArticleList?.(data)
    }

    return (
        <Button
            ref={btnRef}
            fakeButton
            className={className}
            onClick={handlePartSelect}
            isActive={isActive}>
            {text}
        </Button>
    )
}


export default OpenEurotaxOeArticleList

