import { Suspense, useEffect } from "react"
import { ChangeItemsResponse, channel } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { useRecoilState } from "recoil"
import { selectedOrderOptionState } from "@tm/utils"
import { selectShipment, showItemOptionVersions } from "../../../data"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

function TeccomBasketListener() {
    const { workTaskId } = useWorkTask() ?? {}
    const [selectedOrderOption, setSelectedOrderOption] = useRecoilState(selectedOrderOptionState)

    useEffect(() => {
        const unsubErpModifiers = channel("GLOBAL").subscribe("ERP/TECCOM_ARTICLE_ADDED", async (data: ChangeItemsResponse) => {
            if (!selectedOrderOption.orderOption || !data.changedItems?.length) {
                return
            }

            const firstItem = data.changedItems.first()
            if (firstItem?.id && workTaskId) {
                const response = await showItemOptionVersions({ itemIds: [firstItem.id] })

                const versionKey = Object.keys(response?.itemOptionVersions ?? {})?.find((e) => e === firstItem.id)
                const selectedOption = versionKey ? response?.itemOptionVersions[versionKey] : undefined

                selectShipment({
                    workTaskId,
                    optionDescription: selectedOrderOption.orderOption?.description || "",
                    optionId: selectedOrderOption.orderOption?.id || "",
                    itemId: firstItem.id,
                    selectedOptionsItem: selectedOption ?? undefined,
                })
            }
        })

        return () => {
            unsubErpModifiers()
        }
    }, [selectedOrderOption, workTaskId])

    // clear recoil state if "dialog" closed
    useEffect(() => {
        return () => {
            setSelectedOrderOption({ orderOption: undefined, refreshErp: undefined })
        }
    }, [])

    return null
}

export default function Wrapper() {
    return (
        <div className="teccom-basket-listener">
            <Suspense fallback={null}>
                <TeccomBasketListener />
            </Suspense>
        </div>
    )
}
