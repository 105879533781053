
import { ajax, getStoredAuthorization } from "@tm/utils"
import { getBundleParams } from "../../../utils"
import { TiresFiltersRequest, TiresFiltersResponse } from "./model"


export async function loadTiresFilters(request: TiresFiltersRequest) {
    const url = `${getServiceUrl()}/Filters/AttributeFiltersCrits`
    const authorization = getStoredAuthorization()
    const body = request

    const response = await ajax<TiresFiltersResponse>({ method: 'POST', url, body, authorization })

    if (!response) {
        throw new Error("Empty response")
    }

    return response
}

function getServiceUrl(): string {
    const toolkitParams = getBundleParams()
    return toolkitParams.wheelsServiceURL
}
