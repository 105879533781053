import { UserContext } from "@tm/models"
import { useQuery } from "react-query"
import { Dictionary } from "@tm/morpheus"
import { ItemPosition, MultiWidgetTabItem, WidgetBaseProp } from "../helpers/models"
import { MultiWidgetTabsKeys, customerTab, vehicleTab, vouchersTab } from "../helpers/multiWidgetItems"
import { ItemData } from "../../tab-control"

export function useMultiWidgetTabsModules(
    userContext: UserContext,
    position: ItemPosition,
    overrides?: Partial<Record<"multiWidget", WidgetBaseProp<MultiWidgetTabsKeys>>>
) {
    function getSubSort(itemKey: MultiWidgetTabsKeys): number | undefined {
        return overrides?.multiWidget?.subModules?.[itemKey]?.sort
    }

    function getSubHidden(itemKey: MultiWidgetTabsKeys): boolean | undefined {
        return overrides?.multiWidget?.subModules?.[itemKey]?.hidden
    }

    const { data, isFetched } = useQuery(
        [`${position}_multiWidgetTabsModules`],
        () => {
            const multiWidgetTabs = [customerTab, vouchersTab, vehicleTab]

            const mappedTabs: Dictionary<ItemData> = {}

            multiWidgetTabs.forEach((tab: MultiWidgetTabItem) => {
                if (getSubHidden(tab.key)) {
                    return
                }

                mappedTabs[tab.key] = structuredClone(tab)
                mappedTabs[tab.key].sort = getSubSort(tab.key) ?? tab.sort
            })

            return { mappedTabs }
        },
        { enabled: !!userContext, staleTime: Infinity, cacheTime: Infinity, refetchOnMount: false, suspense: true, refetchOnWindowFocus: false }
    )

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { multiWidgetTabs: data!.mappedTabs, loaded: isFetched }
}
