import { BundleComponent } from "@tm/morpheus"

import component from "./component"

const ActiveOrderModal: BundleComponent<void, typeof component> = {
    name: "active-basket-modal",
    component,
}

export default ActiveOrderModal
