import { useMemo, useState } from "react"
import { useLocalization } from "@tm/localization"
import { encodeUniqueId, renderRoute, ConfigFunctions, getRepairTimeProviders } from "@tm/utils"
import { Collapsible } from "@tm/controls"
import { WorkType, RepairTimeProviderConfig, ActiveVehicleDataProviders, UserContext } from "@tm/models"
import { UserSettings, WorkTaskInfo } from "@tm/context-distribution"
import { Box, Icon, Loader, Stack, Typography, Button } from "@tm/components"
import WorksTable from "./WorksTable"
import WorksSelection from "./works-selection"
import { CustomRepairTimesComponent } from "../../../../_shared/custom-basket-items/custom-repair-times"
import AddWork from "./AddWork"
import ErrorAlert from "../../../../_shared/ErrorAlert"
import { WorksTotals } from "../totals/WorksTotals"
import { useWorkTaskBasketState } from "../../../../../hooks/basketState/useWorkTaskBasketState"

type Props = {
    activeProviders?: ActiveVehicleDataProviders
    creatingCostEstimation: boolean
    userContext: UserContext
    userSettings?: UserSettings
    vehicleId?: string
    workTask: WorkTaskInfo
}

export default function WorksListComponent(props: Props) {
    const { activeProviders, creatingCostEstimation, workTask, vehicleId, userContext, userSettings } = props

    const { translateText } = useLocalization()
    const { repairTimeProviders } = getRepairTimeProviders()

    const {
        costEstimation: costEstimationState,
        useRepairTimeCalculation,
        workEstimationLoading,
        workTaskBasketCalculationLoading,
        workTaskBasketLoading,
        workTaskBasketCalculation,
    } = useWorkTaskBasketState(workTask.id)

    const { calculatedCostEstimation } = workTaskBasketCalculation || {}

    const {
        works,
        costEstimation,
        hasRepairTimesWithError,
        currencyCode,
        currencySymbol,
        repairTimeDivision,
        showRepairTimesInHours,
        showSupplierArticleNumbers,
        showManufacturers,
    } = costEstimationState.state
    const {
        addCustomWorks,
        editWork,
        removeWorks,
        replaceWorkWithCustomWork,
        resetRepairTimes,
        selectAllWorks,
        toggleIncludeWorks,
        toggleWorkEditorOpen,
        toggleWorkExpand,
        toggleWorkSelect,
        unselectAllWorks,
    } = costEstimationState.actions

    const [customRepairTimesOpen, setCustomRepairTimesOpen] = useState(false)

    const selectedWorks = useMemo(() => {
        return works?.filter((item) => item.states.isSelected) ?? []
    }, [works])

    const [areAllWorksSelected, areAllWorksResetable] = useMemo(() => {
        return [works?.length === selectedWorks.length, !selectedWorks.some((work) => work.workItem.type !== WorkType.CustomMainWork)]
    }, [works, selectedWorks])

    function renderWorksSelection() {
        if (selectedWorks && selectedWorks?.length > 0) {
            return (
                <WorksSelection
                    selectedWorks={selectedWorks}
                    resetButtonEnabled={areAllWorksResetable}
                    allSelected={areAllWorksSelected}
                    loading={workTaskBasketLoading || workTaskBasketCalculationLoading || workEstimationLoading}
                    onSelectAll={selectAllWorks}
                    onUnselectAll={unselectAllWorks}
                    onRemoveWorks={removeWorks}
                    onResetRepairTimes={resetRepairTimes}
                    onIncludeExcludeWorks={toggleIncludeWorks}
                />
            )
        }
    }

    function renderCustomRepairTimesButton() {
        return (
            <Button
                startIcon={customRepairTimesOpen ? <Icon name="up" /> : <Icon name="down" />}
                variant="text"
                onClick={(e) => {
                    e?.stopPropagation?.()
                    setCustomRepairTimesOpen((prev) => !prev)
                }}
                size="small"
            >
                {translateText(852)}
            </Button>
        )
    }

    function renderWorksAddingTools() {
        let repairTimesUrl
        if (repairTimeProviders) {
            const rtProvidersValues = Object.values(repairTimeProviders)
            let provider = activeProviders?.repairTimes

            if (!provider) {
                let temp: RepairTimeProviderConfig | undefined
                for (let i = 0; i < rtProvidersValues.length; i++) {
                    const rtProvider = rtProvidersValues[i]

                    let disabledFunction = rtProvider.disabledFunction
                        ? ConfigFunctions[rtProvider.disabledFunction as keyof typeof ConfigFunctions]
                        : undefined

                    if (!disabledFunction) {
                        try {
                            disabledFunction = eval(`[${rtProvider.disabled}][0]`)

                            if (typeof disabledFunction !== "function") {
                                disabledFunction = undefined
                            }
                        } catch {}
                    }

                    const disabled = disabledFunction?.(userContext, workTask.vehicle, workTask.customer) ?? false
                    if (userSettings?.activeVehicleDataProviders.repairTimes === rtProvider.id && !disabled) {
                        temp = rtProvider
                        break
                    } else if ((!temp || (temp.sort || 100) > (rtProvider.sort || 100)) && !disabled) {
                        temp = rtProvider
                    }
                }

                if (temp) {
                    provider = temp.id
                }
            }

            const route = rtProvidersValues.find((x) => x.id === provider)?.route
            repairTimesUrl = route && renderRoute(route, { workTaskId: encodeUniqueId(workTask.id) })
        }

        return (
            <Box display="flex" justifyContent="center" p="0 16px 4px 16px">
                <AddWork
                    showRepairTimesInHours={showRepairTimesInHours}
                    costEstimation={costEstimation}
                    repairTimeDivision={repairTimeDivision}
                    currencyCode={currencyCode}
                    repairTimesUrl={repairTimesUrl}
                    vehicleId={vehicleId}
                    workTaskId={workTask.id}
                    onAddCustomWork={addCustomWorks}
                />
            </Box>
        )
    }

    function renderTable() {
        return works?.length && costEstimation?.hourlyRates ? (
            <WorksTable
                calculationLoading={workTaskBasketCalculationLoading}
                creatingCostEstimation={creatingCostEstimation}
                costEstimation={costEstimation}
                repairTimeDivision={repairTimeDivision}
                showManufacturer={showManufacturers}
                showRepairTimesInHours={showRepairTimesInHours}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                useRepairTimeCalculation={useRepairTimeCalculation}
                workEstimationLoading={workEstimationLoading}
                workTask={workTask}
                works={works}
                onEditWork={editWork}
                onIncludeExcludeWork={(work) => toggleIncludeWorks([work])}
                onRemoveWorks={removeWorks}
                onResetRepairTimes={(work, customerDefaults) => (customerDefaults ? resetRepairTimes([work], customerDefaults) : {})}
                onSelectWork={(id) => toggleWorkSelect([id])}
                onShowHideIncludes={toggleWorkExpand}
                onOpenCloseWorkEditor={(id) => toggleWorkEditorOpen([id])}
                onReplaceWorkWithCustomWork={replaceWorkWithCustomWork}
            />
        ) : (
            <Stack alignItems="center" spacing={2}>
                <Icon name="repairtimes" width="30px" height="30px" />
                <Typography>{translateText(919)}</Typography>
            </Stack>
        )
    }

    return (
        <Collapsible
            name={translateText(1668)}
            initiallyOpened
            skin="dark"
            renderHeaderAppendix={renderCustomRepairTimesButton}
            className="tk-basket cost-estimation__collabsible--small-caption"
        >
            {customRepairTimesOpen && costEstimation?.hourlyRates && (
                <CustomRepairTimesComponent
                    workTask={workTask}
                    currencySymbol={currencySymbol}
                    repairTimeDivision={repairTimeDivision}
                    showRepairTimesInHours
                    hourlyRates={costEstimation.hourlyRates}
                    vatRates={costEstimation?.defaultWorkVatRates}
                />
            )}
            <Box position="relative">
                {renderWorksSelection()}
                {hasRepairTimesWithError && <ErrorAlert />}
                <Box mb={1}>{workTaskBasketLoading ? <Loader size="small" /> : renderTable()}</Box>
            </Box>
            {renderWorksAddingTools()}
            <Box py={1} pr={1} display="flex" justifyContent="flex-end">
                <WorksTotals
                    priceVatMode={costEstimation?.costEstimationOptions.priceVatMode}
                    currencyCode={currencyCode}
                    showRepairTimesInHours={showRepairTimesInHours}
                    totalsLoading={workTaskBasketCalculationLoading}
                    totals={calculatedCostEstimation?.totalsB2B ?? calculatedCostEstimation?.totalsB2C}
                />
            </Box>
        </Collapsible>
    )
}
