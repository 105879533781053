import { useStyle } from "@tm/context-distribution"
import { Loader } from "@tm/controls"
import { useState } from "react"
import { ExternalWidgetConfig } from "../../../plugins/ExternalWidgets"

function ExternalWidget(props: ExternalWidgetConfig) {
    const { styles, url, scrolling } = props
    const [loading, setLoading] = useState(true)

    function handleLoaded() {
        setLoading(false)
    }

    return (
        <div className={`iframe-widget widget widget ${style.widget}`} style={styles}>
            <Loader visible={loading} />
            <iframe width="100%" height="100%" src={url} allowFullScreen scrolling={scrolling ? "yes" : "no"} onLoad={handleLoaded} />
        </div>
    )
}

const style = useStyle({
    widget: {
        $nest: {
            ".loader": {
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
            },
        },
    },
})(() => null)

export default ExternalWidget
