import { stylesheet } from "typestyle"
// import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    // const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            $nest: {
                ".dialog-prompt__inner": { minWidth: "55rem" },
            },
        },
        dropdown: {
            minWidth: "12em",
        },
        headline: {
            marginBottom: "0.5rem",
        },
        textField: {
            marginTop: "0.5rem",
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
