import { createSelector } from "reselect"
import { MainState } from ".."
import { validateVehicleFields } from "../../../data/helpers"

export const vehicleSelector = (s: MainState) => s.manager.vehicle
export const customerSelector = (s: MainState) => s.manager.customer

export const historySelector = (s: MainState) => s.summary.historyData
export const vehicleModelsSelector = (s: MainState) => s.summary.vehicleModels

export const erpArticlesSelector = (s: MainState) => s.calculation.erpArticles
export const itemsSelector = (s: MainState) => s.calculation.items
export const worksSelector = (s: MainState) => s.calculation.works

export const initialSparePartsPriceSelector = (s: MainState) => s.calculation.initialSparePartsPrice

export const navigationSelector = (s: MainState) => s.navigation

export const customerFieldsSelector = (s: MainState) => s.summary.customerFields

export const vehicleFieldsSelector = (s: MainState) => s.summary.vehicleInfoFields
export const modelStateSelector = createSelector(
    vehicleFieldsSelector,
    (_: unknown, translateText: (key: string | number) => string) => translateText,
    (fields, translateText) => validateVehicleFields(fields, translateText)
)
