import { useMemo } from "react"
import { MenuItem, MenuItemProps } from "@tm/components"
import { Link } from "react-router-dom"

export function LinkMenuItem(props: Omit<MenuItemProps<Link>, "target" | "rel">) {
    const isExternal = useMemo(() => {
        return /^(?:https?:)\/\//.test(props.to.toString())
    }, [props.to])

    return (
        <MenuItem
            component={Link}
            {...props}
            target={isExternal ? "_blank" : undefined}
            rel="noreferrer"
            to={isExternal ? { pathname: props.to } : props.to}
        />
    )
}
