import { CisVoucherType, PdfOrderDetailOptions } from "@tm/models"
import { openStreamPdf } from "@tm/utils"
import { ShowOrderDetailsPdfRequest, Voucher, PrintVoucherDetailsRequest, PrintVoucherListRequest, FindVoucherRequest } from "../../../data/model"
import * as Data from "../../../data/index"
import { getBundleParams } from "../../../utils"

export function printVoucherDetails(
    voucher: Voucher,
    printOptions: PdfOrderDetailOptions,
    translateText: (id: number) => string,
    customerNo?: string
) {
    const request: PrintVoucherDetailsRequest = {
        printOptions,
        voucher,
        customerNo,
    }

    Data.printVoucherDetails(request).then((response) => {
        if (response) {
            openStreamPdf(response, translateText)
        }
    })
}

export function printVoucherList(findVoucherRequest: FindVoucherRequest, translateText: (id: number) => string, customerNo?: string) {
    const bundleParams = getBundleParams()

    const request: PrintVoucherListRequest = {
        ...findVoucherRequest,
        printOptions: bundleParams.printing,
        customerNo,
    }
    Data.printVoucherList(request).then((response) => {
        if (response) {
            openStreamPdf(response, translateText)
        }
    })
}

export function showOrderPdf(
    printingOptions: PdfOrderDetailOptions,
    translateText: (id: number) => string,
    showPurchasePrice?: boolean,
    customerNo?: string,
    orderNumber?: string,
    orderSubNumber?: string,
    voucherId?: string,
    warehouseId?: string,
    voucherTypeId?: CisVoucherType
) {
    const request: ShowOrderDetailsPdfRequest = {
        orderNumber,
        orderSubNumber,
        printOptions: printingOptions,
        voucherId,
        voucherTypeId,
        warehouseId,
        customerNo,
        showPurchasePrice,
    }

    Data.showOrderDetailsPdf(request).then((response) => {
        if (response) {
            openStreamPdf(response, translateText)
        }
    })
}
