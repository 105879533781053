import { useLocalization } from "@tm/localization"
import { VehicleType } from "@tm/models"
import { Suspense } from "react"

import { useMainModelSeriesDetails } from "../../../data/hooks"
import SelectionsCard from "./SelectionsCard"

type Props = {
    vehicleType: VehicleType
    manufacturerId: number | undefined
    mainModelSeriesId: number | undefined
    backUrl: string
    isCurrent: boolean
}

export default function Wrapper(props: Props) {
    return (
        <Suspense fallback={<SelectionsCard isLoading isCurrent={props.isCurrent} />}>
            <CardMainModelSeries {...props} />
        </Suspense>
    )
}

function CardMainModelSeries({ manufacturerId, mainModelSeriesId, isCurrent, backUrl }: Props) {
    const { translateText } = useLocalization()
    const { mainModelSeriesDetails: mainModelSeries } = useMainModelSeriesDetails({ manufacturerId, mainModelSeriesId }) ?? {}

    return (
        <SelectionsCard
            isCurrent={isCurrent}
            description={!mainModelSeries ? translateText(72) : undefined}
            content={mainModelSeries?.description}
            resetUrl={mainModelSeries ? backUrl : undefined}
        />
    )
}
