import { useEffect, useMemo, useRef, useState } from "react"
import { equals, ModelState, validateField } from "@tm/utils"
import { useLocalization } from "@tm/localization"
import { Modal, TextField, Text } from "@tm/controls"
import { useUser } from "@tm/context-distribution"
import { Button, Box, Stack, Switch, Typography } from "@tm/components"
import { SubUser, UserAdminConfig, PermissionContainer, Permission } from "../../../data/model"
import { setSubUser, createSubUser } from "../../../data"
import { getBundleParams } from "../../../utils"
import SubUserPermissions from "./SubUserPermissions"

type Props = {
    subUser?: SubUser
    headline: number
    permissionContainer?: PermissionContainer
    userAdminConfig: UserAdminConfig
    type: "edit" | "create"
    onclose(reload: boolean): void
}

export default function SubUserEditorComponent(props: Props) {
    const { translateText, translate } = useLocalization()
    const { userContext } = useUser() ?? {}
    const { permissionContainer, type, headline, userAdminConfig, subUser } = props
    const [saving, setSaving] = useState<boolean>(false)
    const [serviceModelState, setServiceModelState] = useState<ModelState>()
    const [forceError, setForceError] = useState(false)
    const usernameInputRef = useRef<TextField | null>(null)

    const permissionsAsFlatList = useMemo(() => {
        let permissions: Permission[] = []
        if (permissionContainer) {
            permissions = permissions.concat(
                permissionContainer.appPermissions,
                permissionContainer.catalogPermissions,
                permissionContainer.appAndCatalogPermissions
            )
        }
        return permissions
    }, [permissionContainer])

    const emptySubUser: SubUser = useMemo(() => {
        const { subUserDefaultRevokedRights } = getBundleParams()

        return { username: "", revokedRights: subUserDefaultRevokedRights }
    }, [])

    const [user, setUser] = useState<SubUser>({ ...(subUser || emptySubUser) })

    useEffect(() => {
        if (subUser) {
            setUser(subUser)
        }
    }, [subUser])

    const allPermissionsGranted = useMemo(() => {
        if (permissionContainer && user.revokedRights) {
            return !(
                permissionContainer.appPermissions.some((permission) => user.revokedRights?.includes(permission.key)) ||
                permissionContainer.catalogPermissions.some((permission) => user.revokedRights?.includes(permission.key)) ||
                permissionContainer.appAndCatalogPermissions.some((permission) => user.revokedRights?.includes(permission.key))
            )
        }
        // permissionContainer or user.revokedRights is undefined
        return true
    }, [permissionContainer, user.revokedRights])

    function handleClose() {
        props.onclose(false)
    }

    function handleSave() {
        const modifiedUser = { ...user }
        modifiedUser.passwordConfirm = undefined

        if (getBundleParams().subUserPrefix === "customerNo" && type === "create") {
            if (!userContext || !userContext.account || !userContext.account.customerNo) {
                return
            }

            modifiedUser.username = `${userContext.account.customerNo}@${modifiedUser.username}`
        }

        setSaving(true)
        setForceError(false)

        let promise
        switch (type) {
            case "create":
                promise = createSubUser(modifiedUser)
                break
            case "edit":
                promise = setSubUser(modifiedUser)
                break
            default:
                break
        }

        if (promise) {
            promise.then(
                () => {
                    // this.setState({ saving: false })
                    props.onclose(true)
                },
                (error) => {
                    if (error) {
                        setSaving(false)
                        setForceError(true)
                        setServiceModelState(error.modelState.modelState)
                        usernameInputRef?.current?.focus()
                    }
                }
            )
        }
    }

    function handleReset() {
        setUser({ ...(subUser || emptySubUser) })
        setServiceModelState(undefined)
    }

    function handleChange(model: SubUser) {
        setUser(model)
        setServiceModelState(undefined)
    }

    function handleAllOnOffChange(state: boolean) {
        if (state) {
            setUser({
                ...user,
                revokedRights: [],
            })
        } else if (permissionContainer) {
            const permissionKeys = permissionsAsFlatList.map((permission) => permission.key)

            setUser({
                ...user,
                revokedRights: permissionKeys,
            })
        }
    }

    function validate(user: SubUser): ModelState {
        const modelstate: ModelState = {
            username: validateField(user.username).min(5, translateText(289)).messages,
            firstname: validateField(user.firstname).min(1, translateText(289)).messages,
            lastname: validateField(user.lastname).min(1, translateText(289)).messages,
            eMail: validateField(user.eMail).email(translateText(290)).min(1, translateText(289)).messages,
        }

        if (userAdminConfig.adminCanEditUserPassword && (type === "create" || user.password)) {
            if (!user.password || user.password.length < 8) {
                modelstate.password = translateText(1328).split("\n")
            } else {
                let passwordRulesFulfilled = 0
                if (/[a-z]+/.test(user.password)) {
                    passwordRulesFulfilled++
                }
                if (/[A-Z]+/.test(user.password)) {
                    passwordRulesFulfilled++
                }
                if (/[0-9]+/.test(user.password)) {
                    passwordRulesFulfilled++
                }
                if (/[!@#$%^&*()_+|~\- =\\`{}[\]:";'<>?,./]+/.test(user.password)) {
                    passwordRulesFulfilled++
                }

                if (passwordRulesFulfilled < 3) {
                    modelstate.password = translateText(1328).split("\n")
                }
            }
        }

        if (user.password !== user.passwordConfirm) {
            modelstate.passwordConfirm = [translateText(1277)]
        }

        return modelstate
    }

    const dirty = !equals(user, subUser || emptySubUser)

    const modelState = serviceModelState || validate(user)

    let valid = true
    Object.keys(modelState).forEach((key) => {
        const value = modelState[key]
        if (value && value.length) {
            valid = false
        }
    })

    let disableSave = false
    let userNamePrefix
    if (getBundleParams().subUserPrefix === "customerNo" && type === "create") {
        if (!userContext || !userContext.account || !userContext.account.customerNo) {
            disableSave = true
        } else {
            userNamePrefix = `${userContext.account.customerNo}@`
        }
    }

    return (
        <Modal onClose={handleClose}>
            <div className="user-settings__sub-user-editor">
                <Typography marginLeft={1} variant="h1">
                    {translateText(headline)}
                </Typography>
                <Stack direction="row" spacing={0.5} mb={1}>
                    <Button onClick={handleSave} disabled={!dirty || saving || !valid || disableSave} color="success">
                        {translateText(9)}
                    </Button>
                    <Button onClick={handleReset} disabled={!dirty || saving}>
                        {translateText(48)}
                    </Button>
                </Stack>
                <div className="user-settings__sub-user-editor__form">
                    <Typography marginLeft={1} variant="h3">
                        {translateText(1126)}
                    </Typography>
                    <div className="username-input-wrapper">
                        {!!userNamePrefix && (
                            <div className="username-prefix">
                                <Text>{userNamePrefix}</Text>
                            </div>
                        )}
                        <TextField
                            ref={usernameInputRef}
                            model={user}
                            path={["username"]}
                            onChange={handleChange}
                            modelState={modelState}
                            label={`${translateText(186)} *`}
                            autoComplete="off"
                            readonly={type === "edit"}
                            forceShowError={forceError}
                        />
                    </div>
                    {userAdminConfig.adminCanEditUserPassword && (
                        <>
                            <TextField
                                model={user}
                                path={["password"]}
                                onChange={handleChange}
                                modelState={modelState}
                                label={`${translateText(187)} (${translateText(1327)})${type === "create" ? " *" : ""}`}
                                type="password"
                                autoComplete="new-password"
                                forceShowError={forceError}
                            />
                            <TextField
                                model={user}
                                path={["passwordConfirm"]}
                                onChange={handleChange}
                                modelState={modelState}
                                label={`${translateText(1278)} (${translateText(1327)})${type === "create" ? " *" : ""}`}
                                type="password"
                                autoComplete="new-password"
                                forceShowError={forceError}
                            />
                        </>
                    )}
                    <br />
                    <TextField
                        model={user}
                        path={["firstname"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={`${translateText(119)} *`}
                        formatter={(value) => value.replace(/;/g, "")}
                        forceShowError={forceError}
                    />
                    <TextField
                        model={user}
                        path={["lastname"]}
                        onChange={handleChange}
                        modelState={modelState}
                        label={`${translateText(155)} *`}
                        formatter={(value) => value.replace(/;/g, "")}
                        forceShowError={forceError}
                    />
                    <TextField model={user} path={["eMail"]} onChange={handleChange} modelState={modelState} label={`${translateText(109)} *`} />
                    {type === "create" && (
                        <Text className="user-settings__sub-user-editor__form__hint" modifiers="block">
                            {translate(1751)}
                        </Text>
                    )}
                    {!!permissionsAsFlatList?.length && (
                        <div className="user-settings__sub-user-editor__permissions">
                            <Typography marginLeft={1} variant="h3">
                                {translateText(1246)}
                            </Typography>
                            <Box sx={{ margin: 1, marginLeft: 1 }}>
                                <Switch
                                    sx={{ marginLeft: 0.5 }}
                                    label={translateText(13511)}
                                    onChange={(state) => handleAllOnOffChange(state.target.checked)}
                                    checked={allPermissionsGranted}
                                />
                            </Box>
                            <SubUserPermissions permissionContainer={permissionContainer} user={user} setUser={setUser} />
                        </div>
                    )}
                </div>
            </div>
        </Modal>
    )
}
