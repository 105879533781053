import { ajax, getStoredAuthorization } from "@tm/utils"

import { getBundleParams } from "../../utils"

function getVinPickerUrl() {
    return getBundleParams().vinPickerUrl
}

export function getVinSuggestions(query: string, modelId: number): Promise<Array<string>> {
    return ajax({
        url: `${getVinPickerUrl()}/Vins`,
        authorization: getStoredAuthorization(),
        body: { query, modelId },
    }).then((data) => data.vins ?? [])
}
