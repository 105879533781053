import { style } from "typestyle"
// import { getStyleTheme } from "";

const countdownWrapper = style({
    width: "1200px",
    height: "150px",
    position: "relative",
    margin: "auto",
    background: "transparent url('/styles/wm/images/wmkatplus-event-countdown-banner.jpg') no-repeat",
})

const timerText = style({
    position: "absolute",
    left: "251px",
    top: "61px",
    height: "41px",
    width: "350px",
    color: "white",
    opacity: 0.87,
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    textTransform: "uppercase",
    paddingTop: "7px",
})

const timer = style({
    fontSize: "1.5rem",
    padding: "0px 5px",
    fontWeight: 600,
})

export const countdownStyle = {
    wrapper: countdownWrapper,
    timer,
    timerText,
}
