import { Article, DefaultArticleItemState, ListOptions, OE } from "@tm/models"
import { useMemo } from "react"
import { usePartsModuleState } from "../../PartsModuleState"
import { useDisableReason } from "./useDisableReason"
import { usePartsViewOptions } from "../usePartsViewOptions"

export function useArticleOptions(article: Article): DefaultArticleItemState["options"] {
    const options = usePartsModuleState((x) => x.options)
    return usePureArticleOptions(article, options)
}

export function usePureArticleOptions(article: Article, options: ListOptions) {
    const {
        partsViewSettings: { compactView, quantitySuggestionEnabled },
    } = usePartsViewOptions()
    const hasSuggestedQuantity = quantitySuggestionEnabled && !!article.suggestedQuantity

    const disableReason = useDisableReason(article)

    return useMemo(
        () => ({
            ...options,
            compactView,
            hasSuggestedQuantity,
            disableReason,
        }),
        [options, disableReason, compactView, hasSuggestedQuantity]
    )
}

export function useOeArticleOptions(article: OE.OePart): DefaultArticleItemState["options"] {
    const options = usePartsModuleState((x) => x.options)
    const {
        partsViewSettings: { compactView },
    } = usePartsViewOptions()

    return useMemo(
        () => ({
            ...options,
            compactView,
        }),
        [options, compactView]
    )
}
