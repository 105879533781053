export enum EFieldType {
    TextField = 1,
    SelectionField = 2,
}

export enum ETextType {
    TextBox = 1,
    Text = 2,
}

export enum ESelectionType {
    ButtonGroup = 1,
    Radio = 2,
    ComboBox = 3,
}
