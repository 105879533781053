import { useStyle, useWorkTask } from "@tm/context-distribution"
import { Icon } from "@tm/controls"
import { useActions } from "@tm/morpheus"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import { em } from "csx"
import * as React from "react"
import { useSelector } from "react-redux"
import { useVehicleCheck } from "../../../data/hooks"
import { MainActions, managerDataSelector } from "../../main/business"
import FirstPart from "./firstPart"
import SecondPart from "./secondPart"

type Props = RouteComponentProps<any> & {}

const Content: React.FC<Props> = ({ match: { params }, history }) => {
    const { statusDataLoaded, vinUsed } = useSelector(managerDataSelector) || {}
    const actions = useActions(MainActions, "getStatusData")
    const worktaskVehicleVin = useWorkTask()?.workTask?.vehicle?.vin

    const shouldLoadData = useVehicleCheck(statusDataLoaded, vinUsed)

    console.debug(shouldLoadData)

    const handleStatusData = () => {
        worktaskVehicleVin && actions.getStatusData(worktaskVehicleVin)
    }

    const handleClick = () => {
        const route = renderRoute("/:workTaskId/carmunication", params)
        history.push(route)
    }

    const renderContent = () => {
        if (shouldLoadData)
            return <FirstPart onButtonClick={handleStatusData} />
        else
            return <SecondPart handleClick={handleClick} />
    }

    return (
        <div className={style.widgetContent}>
            <div className={style.header}>
                <Icon name="telematics" />
                <h2 className="headline--s">{"Carmunication"}</h2>
            </div>
            <div className={style.slidesView}>
                {renderContent()}
            </div>
        </div>
    )
}

export default withRouter(Content)

const style = useStyle({
    widgetContent: {
        position: "relative",
        minHeight: em(17),
        width: em(33),
        display: "flex",
        flexDirection: "column"
    },
    header: {
        display: "flex",
        alignItems: "center"
    },
    slidesView: {
        display: "flex",
        flex: 1
    }
})(Content)