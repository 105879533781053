import { AsyncAction, ActionDispatch } from "@tm/morpheus"
import { Vehicle, Customer } from "@tm/models"
import * as Data from "../../data"
import { BundleActionType, bundleReduce, BundleState } from "../../business"
import { ServiceEvent, EventType } from "../../data"
import { getVehicleType } from "../../helpers/vehicle"

export type ComponentActionType =
    | BundleActionType
    | { type: "SET_WORKTASK_ID"; payload: string }
    | { type: "EVENT_LOADED"; payload?: ServiceEvent }
    | { type: "EVENT_LOADING" }

export type ComponentState = BundleState & {
    loading?: boolean
    eventLoading?: boolean
    event?: ServiceEvent
    typesLoading?: boolean
    types: Array<EventType>
    workTaskId?: string
}

const DEFAULT_STATE: ComponentState = {
    types: [],
}

export function receive(action: ComponentActionType, dispatch: ActionDispatch<ComponentActionType>) {
    switch (action.type) {
        case "EVENT_CREATED": {
            dispatch(action)
            break
        }
        default:
            break
    }
}

export function transmit(action: ComponentActionType) {
    switch (action.type) {
        case "EVENT_LOADED": {
            return action
        }
        default:
            break
    }
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): ComponentState {
    state = bundleReduce(state, action as BundleActionType) as ComponentState
    switch (action.type) {
        case "SET_WORKTASK_ID": {
            return {
                ...state,
                workTaskId: action.payload,
            }
        }
        case "TYPES_LOADING": {
            return {
                ...state,
                typesLoading: true,
            }
        }
        case "EVENT_LOADING": {
            return {
                ...state,
                eventLoading: true,
            }
        }
        case "TYPES_LOADED": {
            return {
                ...state,
                types: action.payload,
                loading: state.eventLoading,
                typesLoading: false,
            }
        }
        case "EVENT_LOADED":
        case "EVENT_CREATED": {
            return {
                ...state,
                event: action.payload,
                loading: state.typesLoading,
                eventLoading: false,
            }
        }
        default:
            break
    }
    return state
}

function loadEvent(workTaskId: string): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch) => {
        dispatch({ type: "SET_WORKTASK_ID", payload: workTaskId })

        dispatch({ type: "TYPES_LOADING" })
        Data.getEventTypes().then((result) => dispatch({ type: "TYPES_LOADED", payload: result }))

        dispatch({ type: "EVENT_LOADING" })
        Data.getServiceEvent(workTaskId).then((result) => dispatch({ type: "EVENT_LOADED", payload: result }))
    }
}

function saveCustomerAndVehicle(customer?: Customer, vehicle?: Vehicle): AsyncAction<ComponentActionType, ComponentState> {
    return (dispatch, getState) => {
        const state = getState()
        let { event } = state
        if (!event) {
            return
        }
        if (customer) {
            event = {
                ...event,
                customerNumber: customer.customerNo,
                firstName: customer.firstName,
                lastName: customer.lastName,
                company: customer.companyName,
                phoneNumber: customer.phone,
                street: customer.street,
                zipCode: customer.zip,
                city: customer.city,
                email: customer.email,
            } as ServiceEvent
        }
        if (vehicle) {
            event = {
                ...event,
                vehicleIdentificationNumber: vehicle.vin,
                firstRegistrationDate: vehicle.initialRegistration,
                licensePlate: vehicle.plateId,
                vehicleType: getVehicleType(vehicle),
                // vehicleTypeId: vehicle.tecDocTypeId,  // entspricht nicht der Tecdoc type id :(
                mileage: vehicle.mileAge,
                generalInspectionDate: vehicle.nextGeneralInspection,
            } as ServiceEvent
        }
        Data.updateServiceEvent(event).then((result) => dispatch({ type: "EVENT_LOADED", payload: result }))
    }
}

export type IActions = typeof Actions

export const Actions = {
    loadEvent,
    saveCustomerAndVehicle,
}
