import { WorkTaskInfo } from "@tm/context-distribution"

export function createMemo({ vehicle, customer }: WorkTaskInfo): string | undefined {
    const memoParts = []

    if (vehicle) {
        if (vehicle.plateId) {
            memoParts.push(vehicle.plateId)
        } else {
            memoParts.push(vehicle.manufacturer, vehicle.modelSeries, vehicle.model)
        }
    }

    if (customer?.firstName) {
        memoParts.push(customer.firstName)
    }

    if (customer?.lastName) {
        memoParts.push(customer.lastName)
    }

    if (memoParts.length) {
        return memoParts.join(" ")
    }
}
