import { ajax, getStoredAuthorization } from "@tm/utils"
import { getServiceUrl } from "."

export function importAppointment(): Promise<void> {
    return ajax({
        method: "POST",
        url: `${getServiceUrl()}/connect/ImportAppointment`,
        authorization: getStoredAuthorization(),
        // body: request,
    })
}
