import { load2DImages } from "../repositories"
import { useQuery } from "react-query"
import { ImagePathsRequest } from "../repositories/wheels-load2DImages/model"

const imagePathsKey = "iamge_paths_key"
export function useImagePaths(request: ImagePathsRequest) {
    const { data, isLoading } = useQuery([imagePathsKey, request.colorCode, request.idImage],
        () => load2DImages(request),
        {
            staleTime: Infinity
        })

    return { imgPaths: data, isLoading }
}