import { getModuleFromUserContext } from "@tm/context-distribution"
import { UserContext, UserModuleType } from "@tm/models"
import { WidgetCoverModules } from "../models"

export function uniPartsPremiumFactory(userContext: UserContext): WidgetCoverModules | undefined {
    const uniPartsPremiumModule = getModuleFromUserContext(userContext, UserModuleType.UnipartsPremium)

    if (!uniPartsPremiumModule) {
        return undefined
    }

    return {
        tools: {
            sort: 2,
            moduleKey: "tools",
            id: "uniparts-premium__widget",
            component: "widget-cover-only",
            target: {
                url: "/:workTaskId/parts/universal/list/uninode?treeId=90&nodeId=2097690",
            },
            title: "{{1101}}",
            icon: "wrench-screwdriver",
        },
    }
}
