import { stylesheet } from "typestyle"
import { getStyleTheme } from "@tm/context-distribution"

export function getComponentStyles() {
    const { margin } = getStyleTheme()

    return stylesheet({
        main: {
            display: "flex",
            alignItems: "center",
            // justifyContent: "space-between",
            marginBottom: margin.m,
        },
        area: {
            marginRight: margin.m,
        },
        licensePlateField: {
            $nest: { ".input__inner": { padding: ".2rem .4rem" } },
        },
    })

    // return deepAssign(merge("getComponentStyles", styles), styleOverwrite || {})
}
