import { useLocalization } from "@tm/localization"
import { ArticleInfoType, CisVoucherType, VatRate, CostEstimationOptions } from "@tm/models"
import { useVatRateText } from "@tm/utils"
import { CellContentPosition, Icon, Loader, Stack, Table, TableCellData, TableColumnData, Tooltip, Typography } from "@tm/components"
import { useWorkTask } from "@tm/context-distribution"
import PartEditor from "./PartEditor"
import PartActions from "./PartActions"
import ArticleNumbers from "../../../../_shared/ArticleNumbers"
import { BasketErpIndicator, BasketPart } from "../../../../../models"
import ArticleThumbnail from "../../../../_shared/PartsTableCells/ArticleThumbnail"
import ArticleQuantity from "../../../../_shared/PartsTableCells/ArticleQuantity"
import ArticleDescription from "../../../../_shared/PartsTableCells/ArticleDescription"
import PartType from "../../../../_shared/PartType"
import { useOpenBasketArticleDetails } from "../../../../../hooks/useOpenBasketArticleDetails"
import PartAlternativesButton from "../../../../_shared/PartsTableCells/PartAlternativesButton"
import PartPrices from "../../../../_shared/parts-list/components/part-list-item/components/partPrices"
import PartErpInfo from "../../../../_shared/PartErpInfo"
import PartFooter from "./PartFooter"
import { ValueLabelStack } from "../../../../ValueLabelStack"
import ArticleSupplier from "../../../../_shared/PartsTableCells/ArticleSupplier"

type Props = {
    costEstimationOptions?: CostEstimationOptions
    externalSystemId?: number
    calculationLoading?: boolean
    cisVoucherUrl?: string
    creatingCostEstimation: boolean
    basketErpIndicator: BasketErpIndicator
    parts: BasketPart[]
    showAllPrices?: boolean
    showManufacturer: boolean
    showPurchasePrice?: boolean
    showSupplierArticleNumbers: boolean
    showWholesalerArticleNumbers: boolean
    voucherTypeId?: CisVoucherType // When in the ui config is set
    currencyCode: string
    onChangeQuantity?(part: BasketPart, quantityValue: number): void
    onEditPart?(
        part: BasketPart,
        rebate?: number,
        surcharge?: number,
        regularPrice?: number,
        customPartNumber?: string,
        customPartDescription?: string,
        vatRate?: VatRate
    ): void
    onIncludeExcludePart(part: BasketPart): void
    onOpenClosePartEditor?(id: string): void
    onOpenArticleSearch?(query: string): void
    onRemoveParts?(id: string[]): void
    onSelectPart(id: string): void
    onOpenPartRepairTimes?(part: BasketPart): void
    onIncludeExcludeBasketPart?(part: BasketPart): void
}

export default function PartsTable(props: Props) {
    const {
        parts,
        cisVoucherUrl,
        creatingCostEstimation,
        voucherTypeId,
        showAllPrices,
        basketErpIndicator,
        showPurchasePrice,
        calculationLoading,
        showManufacturer,
        showSupplierArticleNumbers,
        showWholesalerArticleNumbers,
        currencyCode,
        costEstimationOptions,
        externalSystemId,
    } = props
    const { isLoading: erpInfoLoading, showAlternatives, showGraduatedPrices } = basketErpIndicator
    const { translateText, currency } = useLocalization()
    const { workTaskId } = useWorkTask() ?? {}
    const { vatRateTypeDisplayShortText } = useVatRateText(translateText)
    const handleOpenArticleDetails = useOpenBasketArticleDetails(workTaskId)

    function renderRebate(rebate?: number) {
        if (!rebate) {
            return null
        }

        return <ValueLabelStack label={translateText(54)} value={`${rebate.round(0.01)}%`} />
    }

    function renderVatRate(vatRate?: VatRate) {
        if (!vatRate) {
            return "-"
        }

        return <ValueLabelStack label={translateText(706)} value={vatRateTypeDisplayShortText(vatRate)} />
    }

    function renderPrice(priceLabelId: string, value?: number, currencyCodeString?: string) {
        if (!value || !currencyCodeString) {
            return undefined
        }

        return (
            <Stack justifyContent="flex-end">
                <Typography variant="label" alignSelf="flex-end">
                    {priceLabelId}
                </Typography>
                <Typography fontWeight="bold">{currency(value, currencyCodeString)}</Typography>
            </Stack>
        )
    }

    function getColumns() {
        const columns: TableColumnData[] = [{ header: "" }]
        if (showManufacturer) {
            columns.push({ header: "" })
        }
        columns.push({ header: "" }, { header: "" }, { header: "" })
        if (showGraduatedPrices) {
            columns.push({ header: "" })
        }
        if (showAlternatives) {
            columns.push({ header: "" })
        }
        if (showAllPrices) {
            columns.push({ header: "", alignContent: CellContentPosition.right })
        }
        columns.push(
            { header: "", alignContent: CellContentPosition.right },
            { header: "" },
            { header: "" },
            { header: "" },
            { header: "" },
            { header: "", alignContent: CellContentPosition.right },
            { header: "", alignContent: CellContentPosition.right }
        )

        return columns
    }

    function getModuleCellData(part: BasketPart): TableCellData[] {
        const { partItem, erpInfoResponse, calculatedPart, states } = part
        const { mainPartId, articleInformation, quantity, costEstimationItem } = partItem
        const { currencySymbol, purchaseNetPrice, isIncluded, rebate, retailNetPrice } = costEstimationItem || {}

        const currencyCodeString = costEstimationItem?.currencyCode
        const isCustomArticle = articleInformation.articleInfoType === ArticleInfoType.CustomArticle

        const isLinkedItem = !!mainPartId
        const cellData: TableCellData[] = [{ displayValue: <ArticleThumbnail part={part} />, id: "part_1" }]
        if (showManufacturer) {
            cellData.push({ displayValue: <ArticleSupplier part={part} />, id: "part_2" })
        }
        cellData.push(
            {
                displayValue: (
                    <ArticleNumbers
                        articleNumber={
                            articleInformation.articleInfoType === ArticleInfoType.CustomArticle && showSupplierArticleNumbers
                                ? articleInformation.articleNumber
                                : undefined
                        }
                        isLinkedItem={isLinkedItem}
                        oeArticleNumber={
                            articleInformation.articleInfoType === ArticleInfoType.OeArticle && showSupplierArticleNumbers
                                ? articleInformation.articleNumber
                                : undefined
                        }
                        productGroupId={articleInformation.productGroupId}
                        supplierArticleNumber={
                            articleInformation.articleInfoType === ArticleInfoType.TecdocArticle && showSupplierArticleNumbers
                                ? articleInformation.articleNumber
                                : undefined
                        }
                        supplierId={articleInformation.supplierId}
                        wholesalerArticleNumber={showWholesalerArticleNumbers ? articleInformation.wholesalerArticleNumber : undefined}
                        workTaskId={workTaskId}
                    />
                ),
                id: "part_3",
            },
            { displayValue: <ArticleDescription part={part} />, id: "part_4" },
            { displayValue: <PartType part={part} onOpenArticleSearch={props.onOpenArticleSearch} />, id: "part_5" }
        )
        if (showGraduatedPrices) {
            cellData.push({
                displayValue: erpInfoResponse?.hasGraduatedPurchasePrice ? (
                    <Tooltip title={translateText(705)}>
                        <Icon name="staffelpreis" />
                    </Tooltip>
                ) : undefined,
                id: "part_6",
            })
        }
        if (showAlternatives) {
            cellData.push({
                displayValue: <PartAlternativesButton part={part} onOpenArticleDetails={handleOpenArticleDetails} />,
                id: "part_7",
            })
        }
        // showAllprices is used in catalogs like create-business
        if (showAllPrices) {
            cellData.push({
                displayValue: (
                    <PartPrices
                        part={part}
                        loading={erpInfoLoading || calculationLoading}
                        showProcurementPrice
                        showRetailPrice
                        showPurchasePrice={showPurchasePrice}
                        showTotalPrices={false}
                    />
                ),
                id: "part_8",
            })
        }
        cellData.push(
            {
                displayValue: calculationLoading ? (
                    <Loader size="small" />
                ) : (
                    renderPrice(
                        translateText(costEstimationOptions?.priceTypeMode ? 55 : 1620),
                        calculatedPart?.regularPriceWithSurcharge?.value ?? purchaseNetPrice,
                        currencySymbol || currencyCodeString
                    )
                ),
                id: "part_9",
            },
            {
                displayValue: (
                    <ArticleQuantity
                        value={quantity.value}
                        division={isCustomArticle ? 0.01 : quantity.division}
                        disabled={
                            !isIncluded || creatingCostEstimation || erpInfoLoading || calculationLoading || isLinkedItem || states.isBeingUpdated
                        }
                        onChangeQuantity={(quantityValue) => props.onChangeQuantity?.(part, quantityValue)}
                    />
                ),
                id: "part_10",
            },
            {
                displayValue: (
                    <PartErpInfo showAvailabilityOnly part={part} erpInfoLoading={erpInfoLoading} onOpenArticleDetails={handleOpenArticleDetails} />
                ),
                id: "part_11",
            },
            { displayValue: renderRebate(calculatedPart ? calculatedPart.rebate : rebate), id: "part_12" },
            {
                displayValue: calculationLoading ? <Loader size="small" /> : renderVatRate(calculatedPart?.vatRate),
                id: "part_13",
            },
            {
                displayValue: calculationLoading ? (
                    <Loader size="small" />
                ) : (
                    renderPrice(translateText(57), calculatedPart?.offerPriceTotal?.value ?? retailNetPrice, currencySymbol || currencyCodeString)
                ),
                id: "part_14",
            },
            {
                displayValue: (
                    <PartActions
                        part={part}
                        isLinkedItem={isLinkedItem}
                        creatingCostEstimation={creatingCostEstimation || !!states.isBeingUpdated}
                        disableControls={!!calculationLoading || !!erpInfoLoading || states.isBeingUpdated}
                        onIncludeExcludeBasketPart={props.onIncludeExcludeBasketPart}
                        onIncludeExcludePart={props.onIncludeExcludePart}
                        onOpenArticleDetails={handleOpenArticleDetails}
                        onOpenPartEditor={props.onOpenClosePartEditor}
                        onOpenPartRepairTimes={props.onOpenPartRepairTimes}
                        onRemoveParts={props.onRemoveParts}
                        onSelectPart={props.onSelectPart}
                    />
                ),
                id: "part_15",
            }
        )

        return cellData
    }

    const displayData = parts?.map((part, index) => ({
        cells: getModuleCellData(part),
        id: `${index}`,
        customRow: part.states.isEditorOpen && (
            <PartEditor
                costEstimationOptions={costEstimationOptions}
                currencyCode={currencyCode}
                hidePurchasePrice={!showPurchasePrice}
                part={part}
                showManufacturer={showManufacturer}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                onClosePartEditor={props.onOpenClosePartEditor}
                onEditPart={props.onEditPart}
            />
        ),
        replaceCustomRow: part.states.isEditorOpen,
        active: false,
        extendedContent: (
            <PartFooter
                workTaskId={workTaskId}
                voucherTypeId={voucherTypeId}
                externalSystemId={externalSystemId}
                part={part}
                currencyCode={currencyCode}
                basketErpIndicator={basketErpIndicator}
                calculationLoading={calculationLoading}
                costEstimationOptions={costEstimationOptions}
                cisVoucherUrl={cisVoucherUrl}
                creatingCostEstimation={creatingCostEstimation}
                showAllPrices={showAllPrices}
                showManufacturer={showManufacturer}
                showSupplierArticleNumbers={showSupplierArticleNumbers}
                showWholesalerArticleNumbers={showWholesalerArticleNumbers}
                showPurchasePrice={showPurchasePrice}
                onSelectPart={props.onSelectPart}
                onIncludeExcludePart={props.onIncludeExcludePart}
            />
        ),
    }))

    // TODO: the new list (not implemented yet) should be used here insted of a table
    return <Table columns={getColumns()} rows={displayData} overflowY="unset" headerBackground="transparent" />
}
