import { WorkTaskInfo } from "@tm/context-distribution"
import { getStoredAuthorization, ajax, TmaHelper } from "@tm/utils"
import {
    AddCatalogPartListRequest,
    AddCustomPartListRequest,
    AddOePartListRequest,
    AddWholesalerPartListRequest,
    ChangeItemsResponse,
    Item,
    ReplaceByCatalogPartRequest,
} from "@tm/models"
import { notifyBasketChanges, publishToChannel } from "../../../helpers"
import { getBasketServiceUrl } from "../.."
import { ChangePartPriceCalculationResponse, ChangeQuantityResponse, EditCustomPartRequest, EditPriceRequest } from "../../model"

function getServiceUrl() {
    return `${getBasketServiceUrl()}/Parts`
}

export function addCatalogPartList(request: AddCatalogPartListRequest, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/AddCatalogPartList`
    const authorization = getStoredAuthorization()
    const firstCatalogArticle = request.catalogParts.first()

    request.log = request.log ?? TmaHelper.Shared.ByArticleAndUniParts.GetUserInteractionLog(firstCatalogArticle?.articleListPosition)

    return ajax<ChangeItemsResponse>({ url, body: request, authorization, method: "POST", skipCaching: true }).then(
        (response?: ChangeItemsResponse) => {
            notifyBasketChanges(workTask)
            publishToChannel(workTask?.id || request.workTaskId)
            return response
        }
    )
}

export function addCustomPartList(request: AddCustomPartListRequest, workTask?: WorkTaskInfo) {
    const authorization = getStoredAuthorization()
    const url = `${getServiceUrl()}/AddCustomPartList`

    request.log = request.log ?? TmaHelper.AddCustomToBasket.GetUserInteractionLog("customArticle")

    return ajax<ChangeItemsResponse>({ url, body: request, authorization, method: "POST", skipCaching: true }).then((response) => {
        notifyBasketChanges(workTask)
        publishToChannel(workTask?.id || request.workTaskId)
        return response
    })
}

export function addOePartList(request: AddOePartListRequest, workTask?: WorkTaskInfo) {
    const authorization = getStoredAuthorization()
    const url = `${getServiceUrl()}/AddOePartList`
    const firstOeArticle = request.oeParts?.first()

    request.log = request.log ?? TmaHelper.Shared.ByArticleAndUniParts.GetUserInteractionLog(firstOeArticle?.articleListPosition)

    return ajax<ChangeItemsResponse>({ url, body: request, authorization, method: "POST", skipCaching: true }).then((response) => {
        notifyBasketChanges(workTask)
        publishToChannel(workTask?.id || request.workTaskId)
        return response
    })
}

export function addWholesalerPartList(request: AddWholesalerPartListRequest, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/AddWholesalerPartList`
    const authorization = getStoredAuthorization()

    request.log = request.log ?? TmaHelper.AddCustomToBasket.GetUserInteractionLog("wholesalerPartList")

    return ajax<ChangeItemsResponse>({ url, body: request, authorization, method: "POST", skipCaching: true }).then((response) => {
        notifyBasketChanges(workTask)
        publishToChannel(workTask?.id || request.workTaskId)
        return response
    })
}

export function replacePartByCatalogPart(body: ReplaceByCatalogPartRequest, workTask?: WorkTaskInfo) {
    const url = `${getServiceUrl()}/ReplaceByCatalogPart`
    const authorization = getStoredAuthorization()

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" }).then((response) => {
        notifyBasketChanges(workTask)
        return response
    })
}

export function removePartList(workTaskId: string, idList: Array<string>) {
    const url = `${getServiceUrl()}/RemovePartList`
    const authorization = getStoredAuthorization()
    const body = { workTaskId, idList }

    return ajax<ChangeItemsResponse>({ url, body, authorization, method: "POST" })
}

export function changePartQuantity(item: Item, quantityValue: number) {
    const url = `${getServiceUrl()}/ChangeQuantity`
    const authorization = getStoredAuthorization()
    const body = { item, quantityValue }

    return ajax<ChangeQuantityResponse>({ url, body, authorization, method: "POST" })
}

export function editPartPrice(body: EditPriceRequest) {
    const url = `${getServiceUrl()}/EditPrice`
    const authorization = getStoredAuthorization()

    return ajax<ChangePartPriceCalculationResponse>({ url, body, authorization, method: "POST" })
}

export function editCustomPart(body: EditCustomPartRequest) {
    const url = `${getServiceUrl()}/EditCustomPart`
    const authorization = getStoredAuthorization()

    return ajax<ChangePartPriceCalculationResponse>({ url, body, authorization, method: "POST" })
}
