import { useMicro } from "@tm/morpheus"
import { Widget } from "@tm/controls"
import { IMicros } from "@tm/models"
import WidgetHeaderTitleComponent from "../_shared/WidgetHeaderTitle"

export default function WidgetFormsComponent() {
    const { renderMicro } = useMicro<IMicros>()

    return (
        <Widget size="4x4" iconName="voucher" header={<WidgetHeaderTitleComponent iconName="voucher" titleTextId={1719} />} active>
            {renderMicro("parts", "external-system-documents", {
                loadAllExternalSystemDocuments: true,
            })}
        </Widget>
    )
}
