import { useEffect } from "react"

type IFrameProps = {
    id: string
    className?: string
    children?: JSX.Element
    contextDependent?: boolean
    allowFullscreen?: boolean
    allow?: string
    srcDoc: string
    reloadIframe?: boolean
}

export default function CustomIFrame({ id, className, children, contextDependent, allow, allowFullscreen, srcDoc, reloadIframe }: IFrameProps) {
    let container: HTMLElement | null = null
    let iframe: HTMLIFrameElement | null = null
    let waitForBoundingRect = false

    useEffect(() => {
        if (iframe) {
            iframe.srcdoc = srcDoc
        }
    }, [srcDoc])

    useEffect(() => {
        const showIFrame = setTimeout(showOrCreateIFrame, 50)

        return () => {
            clearTimeout(showIFrame)
            removeIframe()
        }
    }, [])

    const removeIframe = () => {
        const iFrame = getIFrame()

        if (iFrame) {
            iFrame.style.display = "none"
            iFrame.style.pointerEvents = "none"
            iFrame.style.zIndex = "-1"
        }
        window.removeEventListener("resize", handleResize)
    }

    const showOrCreateIFrame = () => {
        iframe = getIFrame()

        if (!iframe) {
            iframe = document.createElement("iframe")

            if (contextDependent) {
                iframe.classList.add("context-dependent")
            }
            iframe.id = id
            iframe.srcdoc = srcDoc
            iframe.style.position = "absolute"
            iframe.style.display = "none"
            iframe.allowFullscreen = allowFullscreen || false
            iframe.allow = allow || ""

            document.body.appendChild(iframe)
        } else {
            if (!waitForBoundingRect) {
                iframe.style.display = ""
                iframe.style.pointerEvents = ""
                iframe.style.zIndex = ""
            }

            if (iframe.srcdoc !== srcDoc) {
                iframe.srcdoc = srcDoc
            } else if (reloadIframe) {
                iframe.contentWindow?.location.reload()
            }
        }

        setBoundingRect()
        window.addEventListener("resize", handleResize)
    }

    const setBoundingRect = () => {
        if (iframe && container) {
            if (!container.clientWidth) {
                setTimeout(setBoundingRect, 100)
                waitForBoundingRect = true
                return
            }
            waitForBoundingRect = false

            const containerBoundingRect = container.getBoundingClientRect()
            iframe.style.left = `${containerBoundingRect.left}px`
            iframe.style.top = `${containerBoundingRect.top}px`
            iframe.style.width = `${containerBoundingRect.width}px`
            iframe.style.height = `${containerBoundingRect.height}px`
            iframe.style.display = ""
            iframe.style.pointerEvents = ""
            iframe.style.zIndex = ""
        }
    }

    const getIFrame = () => {
        return document.querySelector<HTMLIFrameElement>(`#${id}`)
    }

    const handleResize = () => {
        setTimeout(() => setBoundingRect(), 200)
    }

    return (
        <div
            className={className || ""}
            ref={(el) => {
                if (el) {
                    container = el
                }
            }}
        >
            {children}
        </div>
    )
}
