import { useMemo } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { withRouter } from "@tm/utils"
import DetailsComponent from "../details/component"

function Main() {
    const style = useMemo(() => getStyle(), [])

    return (
        <div className={style.wrapper}>
            <DetailsComponent />
        </div >
    )
}

export default withRouter(Main)

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        wrapper: {
            flex: 1,
            padding: theme.margin.l,
        },
    })(Main)
}
