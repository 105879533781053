import { useCallback } from "react"
import { BasketOrderGroup, BasketPart } from "../../../../models"

function formatPart(part: BasketPart, separator: string): string {
    const formatUrlParam = (param: string | number | undefined) => {
        return param || ""
    }
    const { articleInformation, quantity, orderItem } = part.partItem
    const { articleNumber, supplierId, wholesalerArticleNumber } = articleInformation
    const partString = [
        formatUrlParam(supplierId),
        // Some supplier article and wholesaler numbers like "509013.5,5" that contain the same character as the separator may casue conflicts with the external url.
        formatUrlParam(articleNumber ? articleNumber.replaceAll(separator, "") : ""),
        formatUrlParam(wholesalerArticleNumber ? wholesalerArticleNumber.replaceAll(separator, "") : ""),
        formatUrlParam(quantity.value),
        // separator replaced with "|" to avoid conflicts with the external url
        formatUrlParam(orderItem?.memo?.replaceAll(separator, "|")),
    ].join(separator)
    return partString
}

export function useGetExternalOrderUrl(replacedExternalCatalogUrl?: string, orderGroups?: BasketOrderGroup[]) {
    const getExternalOrderUrl = useCallback(() => {
        if (replacedExternalCatalogUrl) {
            // The received external customer url (from MDM -> Trader -> Next -> external system) can contain a parameter "SEP" with the separator which needs to be used
            const sep = "&SEP=" // i.e. Neimcke supports the parameter "&SEP=;"
            const separatorIndex = replacedExternalCatalogUrl.indexOf(sep)
            let separator = ","
            if (separatorIndex > -1) {
                separator = replacedExternalCatalogUrl.substr(separatorIndex + sep.length, 1)
            }

            let url = replacedExternalCatalogUrl
            const parts = orderGroups
                ?.flatMap((orderGroup) => orderGroup.basketParts)
                .filter((part) => part.partItem.orderItem?.isIncluded && part.erpInfoResponse?.isOrderable)
                .map((part) => formatPart(part, separator))
                .join(separator)

            const orderGroupWithComment = orderGroups?.find((orderGroup) => orderGroup.selectedOrderOptions?.hasOrderComment)
            if (orderGroupWithComment) {
                url = url.replace(
                    "{{orderComment}}",
                    orderGroupWithComment.selectedOrderOptions?.orderComment
                        ? encodeURIComponent(orderGroupWithComment.selectedOrderOptions?.orderComment)
                        : ""
                )
            }
            url = parts ? url.replace("{{Basket}}", encodeURIComponent(parts)) : ""

            return url
        }
    }, [orderGroups, replacedExternalCatalogUrl])

    return getExternalOrderUrl
}
