import { useMemo } from "react"
import { useUser, WorkTaskInfo } from "@tm/context-distribution"
import { WorkTaskStatus } from "@tm/models"
import { createBasketMemo, useWorkTaskTruckData } from "@tm/utils"
import { WorkTask } from "../data/model"
import { getBundleParams } from "../utils"

export type BasketMemo = {
    position: string | undefined
    order: string | undefined
}

/**
 * @memberof Basket
 * This hook will return the basket memo containing the information of the supplied work task. It will be created based on the current user settings.
 * @returns An object containing the basket memo for a single position (e.g. part) and the basket memo for the whole order depending on the current user settings.
 */
export function useBasketMemo(workTask: WorkTask | WorkTaskInfo | undefined, searchType?: number): BasketMemo {
    // When the supplied workTask is of the limited format (for central order) map all the available information.
    if (workTask && "workTaskId" in workTask) {
        workTask = {
            id: workTask.workTaskId,
            statusValue: WorkTaskStatus.Undefined,
            customer: workTask.customerInfo,
            no: workTask.workTaskNumber,
            vehicle: workTask.vehicleInfo,
            workTaskReadModel: {
                workTaskId: workTask.workTaskId,
                statusValue: WorkTaskStatus.Undefined,
                customer: workTask.customerInfo,
                modifiedDate: workTask.modifiedDate,
                vehicle: workTask.vehicleInfo,
                workTaskNo: workTask.workTaskNumber,
                voucherNo: workTask.voucherNo,
            },
        } as WorkTaskInfo
    }

    const { workTaskTruckData } = useWorkTaskTruckData(workTask?.id)
    const { userSettings } = useUser() ?? {}
    const { orderOptions = {} } = userSettings ?? {}

    const workTaskInfoOnlyForVehicleParts =
        orderOptions.repairShopResponse?.workTaskInfoOnlyForVehicleParts ?? getBundleParams().workTaskInfoOnlyForVehicleParts
    const addWorkTaskInfoToOrderItemMemo = orderOptions.repairShopResponse?.addWorkTaskInfoToOrderItemMemo
    // DIRECT = 1,
    // UNINODE = 12,
    // UNISEARCH = 13,
    // UNIPRODUCTGROUPS = 15,
    const isUniOrDirectSearch = searchType === 1 || searchType === 12 || searchType === 13 || searchType === 15

    const createMemo = addWorkTaskInfoToOrderItemMemo && (!isUniOrDirectSearch || !workTaskInfoOnlyForVehicleParts)

    const memo = createMemo
        ? createBasketMemo({
              config: {
                  sections: orderOptions.basketMemoSections ?? [],
              },
              vehicle: workTask?.vehicle,
              customer: workTask?.customer,
              truckData: workTaskTruckData,
              workTask: workTask?.workTaskReadModel,
          })
        : undefined

    return useMemo<BasketMemo>(() => {
        return {
            position: orderOptions.basketMemoContext !== "perOrder" ? memo : undefined,
            order: orderOptions.basketMemoContext === "perOrder" ? memo : undefined,
        }
    }, [memo, orderOptions.basketMemoContext])
}
