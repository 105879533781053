import { TreeConfig, TreeConfigKeys } from "../../../../../parts/src"
import { WidgetBaseProp } from "./models"

export function createCatalogWidgetTree(
    modules: Record<TreeConfigKeys, boolean>,
    overrides?: Partial<Record<"catalogWidget", WidgetBaseProp<TreeConfigKeys>>>
) {
    function getSubHidden(itemKey: TreeConfigKeys): boolean | undefined {
        return overrides?.catalogWidget?.subModules?.[itemKey]?.hidden || !modules[itemKey]
    }

    const catalogWidgetTree: Record<TreeConfigKeys, TreeConfig> = {
        vehicleParts: { hideWidget: getSubHidden("vehicleParts") },
        directSearch: { hideWidget: getSubHidden("directSearch") },
        universalParts: { hideWidget: getSubHidden("universalParts") },
        tiresSearch: { hideWidget: getSubHidden("tiresSearch") },
        eurotaxSearch: { hideWidget: getSubHidden("eurotaxSearch") },
        fastDTC: { hideWidget: getSubHidden("fastDTC") },
        predictive: { hideWidget: getSubHidden("predictive") },
    }

    return catalogWidgetTree
}
