import { classes, useStyle } from "@tm/context-distribution"
import { Icon, Image } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { em, margin, rem } from "csx"
import * as React from "react"
import { getBundleParams } from "../../../data/params"

const ProvidersComponent: React.FC<any> = () => {
    const { translate } = useLocalization()
    const { providerLogos } = getBundleParams()

    if (!providerLogos) return null

    return (
        <div className={classes(style.providers, style.mainSection)}>
            <div className={style.header}>
                <Icon name="partner" />
                <div className="headline">{translate(839)}</div>
            </div>
            <div className={style.logoWrapper}>
                {
                    providerLogos.filter(x => !!x).map((logoPath: string, idx: number) => {
                        return (
                            <Image key={idx} className={style.logo} url={logoPath} />
                        )
                    })
                }
            </div>
        </div>
    )
}

const style = useStyle({
    providers: {
        padding: em(1),
        borderLeft: ".4375em solid #dfe4e7",
    },
    logo: {
        maxHeight: em(6),
        marginTop: em(.2)
    },
    logoWrapper: {
        display: "flex",
        flexFlow: "column",
        alignItems: "flex-start"
    },
    header: {
        display: "inline-flex",
        alignItems: "center",
        marginBottom: em(1),
        $nest: {
            ".icon": {
                marginRight: em(.3)
            }
        }
    },
    mainSection: {
        display: "flex",
        flex: 1,
        flexDirection: "column"
    }
})(ProvidersComponent)

export default ProvidersComponent