import { DOMElement, useEffect, useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { ajax, bem } from "@tm/utils"
import { convertStringToReactNode } from "./helpers"

type Props = {
    url: string
    selected?: boolean
}
const imagesMap = new Map()

export default function Svg({ url, selected }: Props) {
    const style = useMemo(() => getStyle(), [])
    const [svg, setSvg] = useState<DOMElement<Record<string, unknown>, Element> | null>(null)

    useEffect(() => {
        if (!url) {
            return
        }

        if (imagesMap.has(url)) {
            setSvg(imagesMap.get(url))
            return
        }

        ajax({ url, responseType: "text" }).then((svgString) => {
            if (!svgString?.startsWith("<svg")) {
                return
            }

            const svgElement = convertStringToReactNode(svgString, "image/svg+xml")

            imagesMap.set(url, svgElement)
            setSvg(svgElement)
        })
    }, [])

    return <div className={bem(style.svgContainer, selected && "selected")}>{svg}</div>
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        svgContainer: {
            $nest: {
                "&--selected svg > g > g >path:last-child": {
                    fill: theme.colors.primary,
                },
            },
        },
    })(Svg)
}
