import { memo } from "react"
import { Grid, Skeleton, Stack, styled } from "@tm/components"

const ArticleContainer = styled("div")(({ theme }) => ({
    margin: "12px 0",
    backgroundColor: "#fafafa",
    boxShadow: "0 1px 5px rgb(0 0 0 / 20%);",
    width: "100%",
    borderBottom: "3px #c3c3c3 solid",
}))

const GridItem = styled(Grid)({
    position: "relative",
    paddingRight: "12px",
    alignSelf: "stretch",
    "::after": {
        content: '" "',
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        borderRight: "solid 1px #c8c6c6",
    },
})

const SkeletonArticleItemComponent = () => {
    return (
        <ArticleContainer>
            <Grid container direction="row" wrap="nowrap" alignItems="start">
                {/* Expand Icon */}
                <Grid item alignItems="center" paddingRight="4px">
                    <Skeleton sx={{ marginTop: "14px" }} height="24px" width="24px" />
                </Grid>

                <GridItem item flexBasis="90px">
                    <Stack spacing={1}>
                        <Skeleton variant="rectangular" height="60px" width="60px" />
                        <Skeleton height="30px" width="60px" />
                    </Stack>
                </GridItem>

                <GridItem item xs p={1}>
                    <Stack spacing={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Stack>
                                <Skeleton height="26px" width="200px" />
                            </Stack>
                        </Stack>

                        <div>
                            <Skeleton height="30px" />
                        </div>
                    </Stack>
                </GridItem>

                {/* Actions */}
                <Grid item flex="0 0 250px" justifyContent="end" paddingX={2} paddingY={1}>
                    <Stack spacing={1}>
                        <Stack direction="row" justifyContent="end" spacing={1}>
                            <Skeleton width="30px" />
                            <Skeleton width="30px" />
                            <Skeleton width="30px" />
                        </Stack>
                        <Stack direction="row" justifyContent="end" flex={1} pb="2px" spacing={1}>
                            <Skeleton variant="rounded" width="80px" height="32px" />
                            <Skeleton variant="rounded" width="80px" height="32px" />
                            <Skeleton variant="rectangular" width="40px" height="32px" />
                        </Stack>
                        <Stack direction="row" justifyContent="space-between" spacing={2}>
                            <Skeleton variant="rounded" width={100} height={40} />
                            <Stack spacing="2px">
                                <Skeleton variant="rounded" width={70} height={20} />
                                <Skeleton variant="rounded" width={70} height={20} />
                            </Stack>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
        </ArticleContainer>
    )
}

export const SekeletonArticleItem = memo(SkeletonArticleItemComponent)
