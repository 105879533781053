import { RepairShops } from "@tm/data"
import { useCallback } from "react"

export function useEmailShare() {
    const handleSendEmail = useCallback(async (subject: string, content: string, emails: string[], bcc?: string[]) => {
        if (!content || !emails?.length) {
            return false
        }

        try {
            const request: RepairShops.SendEmailRequest = {
                emails,
                bcc,
                body: content,
                subject,
            }

            RepairShops.sendEmail(request)
        } catch (error) {
            return false
        }

        return true
    }, [])

    return {
        sendEmail: handleSendEmail,
    }
}
