import { Icon, Stack, Tooltip } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { ArticleListSortingMode } from "@tm/models"
import { concat } from "@tm/utils"
import { memo, useMemo } from "react"
import { useArticleListConfiguration } from "../../../ListV2/ArticleListConfiguration"
import { SortSelection } from "../../../_shared/SortSelection"

type Props = {
    value: ArticleListSortingMode | undefined
    onChange(value: ArticleListSortingMode | undefined): void
    groupArticlesCount: number | undefined
}

export const ArticleListSortSelection = memo(({ value, onChange, groupArticlesCount }: Props) => {
    const { translateText } = useLocalization()

    const { enabled, availableModes, maximumArticleCount, defaultMode } = useArticleListConfiguration().sorting

    const disabledReason = useMemo(() => {
        if (groupArticlesCount === undefined || groupArticlesCount > maximumArticleCount) {
            return translateText(13481).replace("{0}", `${maximumArticleCount}`)
        }
    }, [maximumArticleCount, groupArticlesCount, translateText])

    if (!enabled) {
        return null
    }

    return (
        <Stack spacing={0.5} direction="row" alignItems="center">
            <Tooltip title={disabledReason ?? ""}>
                <SortSelection sortingModes={availableModes} value={value ?? "default"} onChange={onChange} disabled={!!disabledReason} />
            </Tooltip>
            <Tooltip
                title={
                    <span style={{ whiteSpace: "pre-wrap" }}>
                        {concat("\n\n", translateText(13547), defaultMode !== undefined && translateText(13576))}
                    </span>
                }
            >
                <Icon name="info" />
            </Tooltip>
        </Stack>
    )
})
