import { ajax, getStoredAuthorization } from "@tm/utils"
import { AddCalculatedCostsRequest } from "@tm/models"
import { getBundleParams } from "../../utils"

export function addCalculatedCost(body: AddCalculatedCostsRequest) {
    const url = `${getBundleParams().repairShopServiceUrl}/AddCalculatedCosts`
    const authorization = getStoredAuthorization()

    return ajax({ url, body, authorization, method: "POST" })
}
