import { CisNote } from "@tm/models"
import { Typography, Image, Stack, Button, Box } from "@tm/components"
import { RowStack } from "../../_shared/StyledComponents"
import Field from "./Field"

type Props = {
    note: CisNote
}

export default function NoteComponent({ note }: Props) {
    const { description, text, url, urlText, imageUrl, iconUrl } = note
    const imageSource = imageUrl || iconUrl
    return (
        <Field title={description}>
            <RowStack>
                <Stack>
                    <Typography>{text}</Typography>
                    {url && urlText && (
                        <Box>
                            {/* LinkButton currently won't open a new tab */}
                            <Button href={url} target="_blank">
                                {urlText}
                            </Button>
                        </Box>
                    )}
                </Stack>
                {imageSource && <Image src={imageSource} height={60} width={120} />}
            </RowStack>
        </Field>
    )
}
