import { em } from "csx"
import { Fragment, MouseEvent, ReactNode, useMemo, useState } from "react"
import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { Button, Collapsible, Grid, Loader, Scrollbar, SearchField } from "@tm/controls"
import { bem } from "@tm/utils"
import { InfoDialog } from "."
import { FilterType } from "../../data/enums"
import { isInfoAvailable } from "../../data/helpers"

type Props = {
    filterId: FilterType
    title: string
    loading?: boolean
    children: ReactNode
    disabled?: boolean
    searchValue?: string
    active?: boolean
    searchEnabled?: boolean
    resetBtnDisabled?: boolean
    onSearchChange?(value: string): void
    onSearchVisibility?(): void
    onReset?(): void
    onCollapsibleChange?: () => void
    inModal?: boolean
    onToggle?: (filterId: FilterType) => void
    clippedFilters: FilterType
}

export default function Filter({ children, title, active, filterId, clippedFilters, disabled, loading, inModal, onCollapsibleChange, onReset, onSearchChange, onSearchVisibility, onToggle, resetBtnDisabled, searchEnabled, searchValue }: Props) {
    const style = useMemo(() => getStyle(), [])
    const [displayDialog, setDisplayDialog] = useState(false)

    const handleToggleSearch = (ev: MouseEvent<HTMLElement>) => {
        onSearchVisibility?.()
        ev.stopPropagation()
    }

    const handleXButton = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onReset?.()
    }

    const handleToogleClip = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        onToggle?.(filterId)
    }

    const onInfoClick = (e: MouseEvent) => {
        e.stopPropagation()
        setDisplayDialog(true)
    }

    const handleDialogCLose = () => {
        setDisplayDialog(false)
    }

    const renderHeader = () => {
        return (
            <>
                {isInfoAvailable(filterId) && <Button layout={["ghost"]} onClick={onInfoClick} icon="info" />}
                {onSearchChange && <Button layout={["ghost"]} icon="search" onClick={handleToggleSearch} />}
                {onReset && <Button layout={["ghost"]} disabled={resetBtnDisabled && !disabled} onClick={handleXButton} icon="remove-filter" />}
                {onToggle && <Button layout={["ghost"]} disabled={false} onClick={handleToogleClip} icon="paperclip" />}
                {loading && <Loader className={style.loader} />}
            </>
        )
    }

    const wrapItem = (content: ReactNode) => {
        if (inModal)
            return (
                <Grid m={3} s={4} xs={6} container className={style.gridItem}>
                    <Scrollbar>
                        {content}
                    </Scrollbar>
                </Grid>
            )
        return content
    }

    if ((!inModal ? clippedFilters : ~clippedFilters) & filterId)  {
        return null
    }

    return (
        <>
            {wrapItem(
                <Collapsible className={bem(style.collapsible, (disabled) && "disabled")} name={title} renderHeaderAppendix={renderHeader}
                    initiallyOpened={active} onChangeOpen={onCollapsibleChange}>
                    {searchEnabled && <SearchField
                        value={searchValue}
                        showClear
                        placeholder="Search..."
                        onChange={onSearchChange} />}
                    {children}
                    {displayDialog && <InfoDialog filterId={filterId} onDialogCLose={handleDialogCLose} />}
                </Collapsible>
            )}
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        collapsible: {
            visibility: "visible",
            $nest: {
                "&--disabled": {
                    cursor: "default",
                    pointerEvents: "none",
                    opacity: theme.opacity.disabled,
                    $nest: {
                        ".collapsible__content": {
                            display: "none"
                        }
                    }
                }
            }
        },
        gridItem: {
            height: em(20)
        },
        loader: {
            width: em(1),
            height: em(1)
        }
    })(Filter)
}
