import { style } from "typestyle"

export const className = style({
    height: 345,
    width: 650,
    $nest: {
        "&__tile": {
            position: "absolute",
            flex: "0 0 100%",
            width: 650,
            height: 345,
            margin: 0,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            transition: "background 1s ease-in-out",
        },

        "&__button-wrapper, &__adTileClickable": {
            height: "100%",
            width: "100%",
            cursor: "pointer",
        },

        "&__slideButton": {
            border: "none",
            height: "100%",
            width: 40,
            fontSize: 35,
            $nest: {
                "&:hover": {
                    backgroundColor: "rgba(163, 163, 163, 0.3)",
                    color: "rgb(240, 240, 240)",
                },
                "&:visited": {
                    backgroundColor: "hsla(120, 0%, 50%, 0.0)",
                    color: "rgb(40, 40, 40)",
                },
            },
        },
    },
})
