import { ItemTour } from "@tm/models"
import { SxProps } from "@tm/components"
import { isSameDay } from "@tm/utils"
import { TourIdContainer, TourIdTypography } from "./StyledComponents"

type Props = {
    tour: ItemTour
    sx?: SxProps
    lightMode: boolean
}

export default function TourComponent({ sx, tour, lightMode }: Props) {
    if (!tour.id) {
        return null
    }
    const tourDate = tour.tourOrderAcceptanceTimeLimit ?? tour.tourStart
    return (
        <TourIdContainer sx={sx} lightMode={lightMode} title={tour.description}>
            <TourIdTypography variant="body3" lightMode={lightMode} isNextDay={tourDate ? !isSameDay(tourDate, new Date()) : false}>
                {tour.id}
            </TourIdTypography>
        </TourIdContainer>
    )
}
