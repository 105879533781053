import { ajax, getStoredAuthorization } from "@tm/utils"
import { GetCalculationDataRequest, GetCalculationDataResponse } from ".."
import { getBundleParams } from "../../../utils"
import { mapDatCalculationData } from "./mappers"

export * from "./model"

export function getCalculationData(calculationDataRequest: GetCalculationDataRequest) {
    const url = `${getServiceUrl()}/GetCalculationData`
    const authorization = getStoredAuthorization()
    const body = calculationDataRequest

    // things to clarify
    // the vat may be changed inside the tool and has to be saved in our store, but not replaced in Next. Will be used for further calculations
    // customer has to be updated is gets modified inside the tool ?! i don't know

    return new Promise<GetCalculationDataResponse>((resolve, reject) =>
        ajax({ url, body, method: "POST", authorization }).then((response) => {
            if (!response?.calculationData?.errorMessage && response?.calculationData) {
                resolve(mapDatCalculationData(response.calculationData))
            } else {
                reject(response?.calculationData?.errorMessage)
            }
        }, reject)
    )
}

function getServiceUrl() {
    const bundlesParams = getBundleParams()
    return bundlesParams.glassServiceUrl
}
