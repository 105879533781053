export function formatDate(dateToFormat: string | Date): string {
    let date: Date
    if (typeof dateToFormat === "string") {
        if (!dateToFormat) {
            return ""
        }

        date = new Date(dateToFormat)
    } else {
        date = dateToFormat
    }

    const dateOptions: Intl.DateTimeFormatOptions = { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" }

    return date.toLocaleString(navigator.language, dateOptions)
}

export function getDaysUntilExpire(date?: string) {
    if (!date) {
        return "-"
    }

    const differenceInTime = new Date(date).getTime() - new Date().getTime()
    const diffInDays = Math.round(differenceInTime / (1000 * 3600 * 24))
    if (diffInDays <= 0) {
        return "-"
    }

    return diffInDays?.toString() || "-"
}
