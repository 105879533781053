import { concat, mapAvailabilityStatusToIconName, mapAvailabilityStatusToLevel } from "@tm/utils"
import { Icon, Text } from "@tm/controls"
import { IMicros } from "@tm/models"
import { Box, styled } from "@tm/components"

type Props = IMicros["erp"]["erp-info-availability-overview"]

const ItemBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    marginLeft: "0.5em",
    "& .icon": {
        marginRight: "0.25em",
    },
    "& .text": {
        display: "block",
    },
})

export default function ErpInfoAvailabilityOverviewComponent(props: Props) {
    if (!props.data?.length) {
        return null
    }

    return (
        <Box className="tk-erp">
            <Box display="flex">
                {props.data.map((item) => {
                    const className = "erp-info-availability-overview__item"

                    return (
                        <ItemBox
                            key={item.status}
                            className={concat(" ", className, `${className}--level-${mapAvailabilityStatusToLevel(item.status)}`)}
                        >
                            <Icon name={mapAvailabilityStatusToIconName(item.status)} size="l" />
                            <Box>
                                {item.text.split("\n").map((text, idx) => (
                                    <Text key={`${item.status}_${idx}`} size="xs">
                                        {text}
                                    </Text>
                                ))}
                            </Box>
                        </ItemBox>
                    )
                })}
            </Box>
        </Box>
    )
}
