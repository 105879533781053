import { DRTire } from "../../models"
import { DriveRightTiresRespone, FrontTire, RearTire } from "./model"

export function mapDriveRightTiresResponse(response: any): DriveRightTiresRespone {
    const { tyres: { frontTyres, rearTyres } } = response
    return {
        frontTires: frontTyres && frontTyres.map(mapFrontTire) || [],
        rearTires: rearTyres && rearTyres.map(mapRearTire) || [],
    }
}

export function mapFrontTire(tire: FrontTire): DRTire {
    return {
        height: tire.whRelationFront,
        width: tire.widthFront,
        inch: tire.diameterFront,
        loadIndex: tire.loadIndexFront,
        speedindex: tire.speedIndexFront,
        size: tire.sizeFront,
        rimSize: tire.rimSizeFront,
        offsetValue: tire.rimOffsetFront,
        isSummer: tire.saisonSummer,
        isWinter: tire.saisonWinter
    }
}

export function mapRearTire(tire: RearTire): DRTire {
    return {
        height: tire.whRelationRear,
        width: tire.widthRear,
        inch: tire.diameterRear,
        loadIndex: tire.loadIndexRear,
        speedindex: tire.speedIndexRear,
        size: tire.sizeRear,
        rimSize: tire.rimSizeRear,
        offsetValue: tire.rimOffsetRear,
        isSummer: tire.saisonSummer,
        isWinter: tire.saisonWinter
    }
}