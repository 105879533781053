import { useLocalization } from "@tm/localization"
import { ItemTour } from "@tm/models"
import { encodeUniqueId, isSameDay, renderRoute, uniqueId } from "@tm/utils"
import { useWorkTask } from "@tm/context-distribution"
import { Box, Icon, Loader, Stack, Tooltip, Typography, useTheme } from "@tm/components"
import { useHistory, useParams } from "react-router"
import { ReactNode } from "react"
import TourComponent from "./Tour"
import {
    FirstDateDay,
    FirstDateContainer,
    FirstDateTriangle,
    ToursContainerStack,
    TourHeadlineTypography,
    TourDateTypography,
} from "./StyledComponents"

type Props = {
    loading: boolean
    tours: Array<ItemTour> | undefined
    detailsCisRoute?: string
    lightMode: boolean
}

export default function NextToursComponent({ loading, tours, detailsCisRoute, lightMode }: Props) {
    const { translateText, date: formatDate } = useLocalization()
    const workTaskId = useWorkTask()?.workTaskId
    const theme = useTheme()
    const history = useHistory()
    const routeParams = useParams()

    const handleClick = () => {
        if (detailsCisRoute) {
            history.push(renderRoute(detailsCisRoute, { ...routeParams, workTaskId: encodeUniqueId(workTaskId || uniqueId()) }))
        }
    }

    function renderNextTourPrefix(tour: ItemTour) {
        const date = tour.tourOrderAcceptanceTimeLimit ?? tour.tourStart
        if (date && !isSameDay(date, new Date())) {
            return (
                <FirstDateDay variant="body2" lightMode={lightMode}>
                    {formatDate(date, "ddd")}
                </FirstDateDay>
            )
        }
    }

    function renderNextTour(tour: ItemTour) {
        const date = tour.tourOrderAcceptanceTimeLimit ?? tour.tourStart
        const overwrittenForegroundColor = theme.overwrites?.components?.erpTours?.foregroundColor
        const overwrittenIconColor = theme.overwrites?.components?.erpTours?.iconColor

        return (
            <>
                <TourComponent
                    tour={tour}
                    lightMode={lightMode}
                    sx={{
                        position: "absolute",
                        height: "15px",
                        border: overwrittenForegroundColor || lightMode ? `1px solid ${overwrittenForegroundColor ?? theme?.colors?.dark}` : "",
                        zIndex: 10000,
                    }}
                />
                <Stack direction="column" alignItems="center" justifyContent="flex-end">
                    <Icon name="next-tour" size="28px" color={overwrittenIconColor ?? (lightMode ? "black" : "white")} />
                    <Typography
                        variant="body3"
                        fontWeight="bold"
                        color={overwrittenForegroundColor ?? (lightMode ? "black" : "white")}
                        sx={{ margin: "0 1px" }}
                    >
                        {date && formatDate(date, "t")}
                    </Typography>
                </Stack>
            </>
        )
    }

    if (loading) {
        return <Loader size="small" />
    }

    if (!tours?.length) {
        return null
    }

    const moreTours: ReactNode[] = []

    for (let i = 1; i < tours.length; i++) {
        const previousDate = tours[i - 1].tourOrderAcceptanceTimeLimit ?? tours[i - 1].tourStart
        const date = tours[i].tourOrderAcceptanceTimeLimit ?? tours[i].tourStart

        if (date && previousDate && !isSameDay(date, previousDate)) {
            moreTours.push(
                // Add "_day" to key, to make sure the second box does not have the same key
                <Box key={`${tours[i].id}_day`}>
                    <TourDateTypography lightMode={lightMode}>{formatDate(date, "ddd")}</TourDateTypography>
                </Box>
            )
        }

        moreTours.push(
            <Box key={tours[i].id}>
                <TourComponent tour={tours[i]} lightMode={lightMode} />
                <TourDateTypography lightMode={lightMode}>{date && formatDate(date, "t")}</TourDateTypography>
            </Box>
        )
    }

    return (
        <Tooltip title={detailsCisRoute ? translateText(1084) : ""}>
            <ToursContainerStack lightMode={lightMode} onClick={handleClick} sx={{ cursor: detailsCisRoute ? "pointer" : "default" }}>
                <FirstDateContainer lightMode={lightMode}>
                    {renderNextTourPrefix(tours[0])}
                    {renderNextTour(tours[0])}
                </FirstDateContainer>
                <FirstDateTriangle lightMode={lightMode} />
                <Stack justifyContent="center" alignItems="center" sx={{ margin: "0 4px" }}>
                    <TourHeadlineTypography lightMode={lightMode}>
                        {translateText(tours[0].tourOrderAcceptanceTimeLimit ? 1129 : 433).toUpperCase()}
                    </TourHeadlineTypography>
                    <Stack direction="row">{moreTours}</Stack>
                </Stack>
            </ToursContainerStack>
        </Tooltip>
    )
}
