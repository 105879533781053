export type CheckList = {
    date: string
    name: string
    language: string
    templateId: string
    items: CheckListItems[]
}

export type CheckListItems = {
    id: string
    type: ECheckItemType
    text: string
    state: ECheckState
    comment: string
}

export enum ECheckItemType {
    HeaderYesNo,
    HeaderOkNok,
    SubsectionBegin,
    SubsectionEnd,
    RowNoInput,
    RowButtonsOnly,
    RowButtonsWithComment,
    RowCommentOnly,
}

export enum ECheckState {
    None,
    Option1,
    Option2,
    Option2Fixed,
}
