import { Dictionary } from "@tm/utils"
import { SectionUrl } from "./model"
import { BundleActionType, BundleActions } from "../../../business"

export type ComponentActionType = BundleActionType | { type: "SET_URLS"; payload: Dictionary<Dictionary<SectionUrl>> }

export function setUrls(urls: Dictionary<Dictionary<SectionUrl>>): ComponentActionType {
    return { type: "SET_URLS", payload: urls }
}

export type IActions = typeof Actions

export const Actions = {
    ...BundleActions,
    setUrls,
}
