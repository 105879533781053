import { Fragment } from "react"
import { Divider } from "@tm/components"
import { AdditionalMenuItemComponent } from "./AdditionalMenuItem"
import { ExternalModuleMdmMenu, useMdmMenuEntrys } from "../../hooks/useMdmMenuEntrys"
import { ExternalModuleMenuEntry } from "./ExternalModuleMenuEntry"

export type MdmMenuEntrys = {
    name: string
    order?: string
    entrys: ExternalModuleMdmMenu[]
}

type Props = {
    onClick?(): void
}

export function AdditionalMdmMenuItems({ onClick }: Props) {
    const mdmEntrys = useMdmMenuEntrys()

    if (!mdmEntrys?.length) {
        return null
    }

    return (
        <>
            {mdmEntrys.map((parent) => (
                <Fragment key={parent.name}>
                    <AdditionalMenuItemComponent item={{ label: parent.name }} onClick={onClick} />
                    {parent.entrys?.map((x) => (
                        <ExternalModuleMenuEntry key={x.id} item={x} onClick={onClick} />
                    ))}
                    <Divider />
                </Fragment>
            ))}
        </>
    )
}
