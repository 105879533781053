import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { isDevtoolEnabled } from "@tm/utils"
import { ErrorState, ErrorStore } from "./model"
import { clearError, setError } from "./actions"

const initialState: ErrorState = {
    isError: false,
    errorMessage: "",
}

export const useErrorStore = create<ErrorStore>()(
    devtools(
        (set) => ({
            ...initialState,
            errorActions: {
                setError: (error) => set((state) => setError(state, error)),
                clearError: () => set(clearError(initialState)),
            },
        }),
        { name: "Partslife Error Store", enabled: isDevtoolEnabled() }
    )
)
