import { useState, useEffect } from "react"
import { connectComponent } from "@tm/morpheus"
import { Widget, Button } from "@tm/controls"
import { renderRoute, uniqueId, encodeUniqueId, RouteComponentProps, withRouter } from "@tm/utils"
import { StartWidgetState, IActions, Actions, CalendarType } from "./business"
import { EventList } from "./components/event-list"
import { DayCalendar } from "./components/day-calendar"
import { MonthCalendar } from "./components/month-calendar"
import { DemoMessage } from "./components/demo-message"
import { useActivation } from "./useActivation"
import { Header } from "./components/header"
import { formatDate } from "../../helpers/date"

type Props = RouteComponentProps<void> &
    ConfigProps & {
        state: StartWidgetState
        actions: IActions
    }

type ConfigProps = {
    createRoute: string
    centerRoute: string
}

function StartWidgetComponent(props: Props) {
    const [activation] = useActivation()
    const [calendarType, setCalendarType] = useState<CalendarType>(activation ? "LIST" : "MONTH")
    const [date] = useState(new Date())
    const { history } = props

    useEffect(() => {
        // props.actions.loadServiceEvents([new Date, new Date])
    }, [])

    function handleActivate() {
        // setActivation(true)
        // setCalendarType("LIST")
    }
    function handleSelectDay(/* date: Date */) {
        // setDate(date)
        // setCalendarType("DAY")
    }

    const {
        state: { events, types, loading },
        createRoute,
    } = props

    const HeaderComponent = Header.bind(null, { calendarType, setCalendarType, loading })

    const className = `start-widget wigdet--w4 ${activation ? "" : "is-demo"}`
    const workTaskId = encodeUniqueId(uniqueId())
    const createUrl = createRoute && `/${workTaskId}?(1)=${renderRoute(createRoute, { workTaskId, date: formatDate(date) })}`

    return (
        <div className="bnd-schedules start-widget widget--w4">
            <Widget className={className} active header={<HeaderComponent />}>
                {calendarType === "MONTH" && <MonthCalendar {...{ events, types, history }} date={date} onSelectDay={handleSelectDay} />}
                {calendarType === "DAY" && <DayCalendar {...{ events, types, history }} date={date} centerRoute={props.centerRoute} />}
                {calendarType === "LIST" && <EventList {...{ events, types, history }} createUrl={createUrl} centerRoute={props.centerRoute} />}
                <Button
                    className="start-widget__add"
                    size="xl"
                    skin="highlight"
                    layout={["rounded"]}
                    icon="add"
                    linkTo={createUrl}
                    title="Neuen Servicetermin erstellen"
                />
            </Widget>
            {!activation && <DemoMessage onActivate={handleActivate} />}
        </div>
    )
}

export default connectComponent(Actions, withRouter(StartWidgetComponent))
