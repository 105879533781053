import { border, color, em, margin, px, rem } from "csx"
import { useCallback, useEffect, useMemo, useState } from "react"
import { getStyleTheme, useStyle, useWorkTask } from "@tm/context-distribution"
import { Button, DateField, SuggestionTextField, Switch, TextField, VinField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { classes } from "@tm/utils"
import { maintenancePlanActions, useFastServiceStore } from "../../../data"
import { useGetVins } from "../../../helpers/hooks/useGetVins"
import { IncludedAdditionalWorks } from "../../_shared"

type Props = {
    handleSubmit(): void
}

const VIN_LENGTH = 17
const VIN_LENGTH_SMALL = 7
const BMW_TECDOC_ID = 16

export default function VehicleFields({ handleSubmit }: Props) {
    const style = useMemo(() => getStyle(), [])
    const { translateText } = useLocalization()
    const { attachToWorkTask, workTask } = useWorkTask() || {}
    const [isLoading, setIsLoading] = useState(true)

    const { engineCode, engineCodes, initialRegistration, mileage, vin, longLife, minDate, calculateIncludedAdditionalWorks } = useFastServiceStore(
        (state) => state.maintenancePlan.vehicleFields
    )

    const stateVehicle = useFastServiceStore((state) => state.maintenancePlan.stateVehicle)
    const vehicleVinSuggestions: string[] = useGetVins("", stateVehicle?.tecDocTypeId) ?? []

    const getSuggestionData = useCallback(() => {
        return engineCodes || []
    }, [])

    const handleGetVinSuggestions = useCallback(
        (query: string): Array<string> => {
            return vehicleVinSuggestions?.length && query?.length
                ? vehicleVinSuggestions?.filter((vin) => vin.startsWith(query))
                : vehicleVinSuggestions
        },
        [stateVehicle, vin, vehicleVinSuggestions]
    )

    function handleChange(fieldType: string, value: any) {
        maintenancePlanActions.updateVehicleField(fieldType, value)
    }

    function handleChangeLongLife(name: string) {
        handleChange(name, !longLife ?? true)
    }

    function handleVinChange(vinValue: string) {
        handleChange("vin", vinValue)
    }

    const isVinValid = useMemo(() => {
        return vin?.length === VIN_LENGTH || (stateVehicle?.tecDocManufacturerId === BMW_TECDOC_ID && vin?.length === VIN_LENGTH_SMALL)
    }, [vin])

    const handleSubmitButton = () => {
        if (
            engineCode !== workTask?.vehicle?.engineCode ||
            mileage !== workTask?.vehicle?.mileAge ||
            initialRegistration !== workTask?.vehicle?.initialRegistration ||
            vin !== workTask?.vehicle?.vin ||
            longLife !== workTask?.vehicle?.longlife
        ) {
            stateVehicle &&
                attachToWorkTask?.({ vehicle: { ...stateVehicle, engineCode, mileAge: mileage, initialRegistration, vin, longlife: longLife } })
        }
        handleSubmit()
    }

    useEffect(() => {
        // Timeout needed for swiper to calculate heights before opening popups
        const timer = setTimeout(() => {
            setIsLoading(false)
        }, 300)
        return () => clearTimeout(timer)
    }, [])

    return (
        <>
            <div className={classes(style.fieldSeparator, engineCode ? style.borderredSuccess : style.borderredWarning, "col engine-code")}>
                <SuggestionTextField
                    preventConfirmOnBlur
                    floatingLabel
                    size="xl"
                    label={translateText(105)}
                    value={engineCode}
                    getSuggestionData={getSuggestionData}
                    onChangeConfirm={handleChange.bind(null, "engineCode")}
                    path={["engineCode"]}
                    autoFocus={!isLoading && !engineCode?.length}
                />
            </div>
            <div
                className={classes(
                    style.fieldSeparator,
                    initialRegistration ? style.borderredSuccess : style.borderredWarning,
                    "col initial-registration"
                )}
            >
                <DateField
                    size="xl"
                    useUtc
                    floatingLabel
                    label={translateText(124)}
                    value={initialRegistration}
                    minDate={minDate}
                    openToDate={initialRegistration || minDate}
                    onChange={handleChange.bind(null, "initialRegistration")}
                    path={["initialRegistration"]}
                    autoFocus={!isLoading && !!engineCode?.length && !initialRegistration}
                />
            </div>

            <div className={classes(style.fieldSeparator, mileage ? style.borderredSuccess : style.borderredWarning, "col mileAge")}>
                <TextField
                    size="xl"
                    floatingLabel
                    label={translateText(125)}
                    path={["mileage"]}
                    value={mileage || undefined}
                    onChangeConfirm={handleChange.bind(null, "mileage")}
                    pattern={/[\d.]*/}
                    formatter={(value) => value.replace(/[^\d]/, "").replace(",", "").replace(".", "")}
                    inputMode="numeric"
                    autoFocus={!isLoading && !!engineCode?.length && !!initialRegistration && !mileage}
                />
            </div>

            <div className={classes(style.fieldSeparator, isVinValid ? style.borderredSuccess : style.borderredWarning, "col vin")}>
                <VinField
                    size="xl"
                    floatingLabel
                    label={translateText(101)}
                    value={vin}
                    getSuggestionData={handleGetVinSuggestions}
                    path={["vin"]}
                    onChangeConfirm={handleVinChange}
                    onChange={handleVinChange}
                    autoFocus={!isLoading && !!engineCode?.length && !!initialRegistration && !!mileage && !vin}
                />
            </div>

            <div className={classes(style.col, "long-life", longLife === null ? style.switchNoStatus : "")}>
                <Switch label="LongLife" onClick={() => handleChangeLongLife("longLife")} disabled={false} status={longLife} />
            </div>

            <div
                className={classes(
                    style.includeAdditionalWorksSwitch,
                    style.col,
                    "long-life",
                    calculateIncludedAdditionalWorks === null ? style.switchNoStatus : ""
                )}
            >
                <IncludedAdditionalWorks
                    name="calculateIncludedAdditionalWorks"
                    label={translateText(13371)}
                    icon="partsindicator"
                    value={calculateIncludedAdditionalWorks}
                    handleChange={handleChange}
                />
            </div>

            <Button
                disabled={!(engineCode && initialRegistration && mileage && isVinValid)}
                className={style.submitButton}
                size="l"
                skin="highlight"
                icon="arrows_down"
                onClick={handleSubmitButton}
            />
        </>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        fieldSeparator: {
            minWidth: "fit-content",
            marginRight: em(0.5),
            $nest: {
                ".suggest__box--xl": {
                    fontSize: rem(1.25),
                },
            },
        },
        includeAdditionalWorksSwitch: {
            marginLeft: "1em !important",
        },
        borderredWarning: {
            borderLeft: border({ style: "solid", color: color(theme.colors.warning).mix(theme.colors.danger).toString() }),
        },
        borderredSuccess: {
            borderLeft: border({ style: "solid", color: theme.colors.success }),
        },
        col: {
            margin: margin(theme.margin.m, theme.margin.s),
            $nest: {
                ".suggest": {
                    flex: 1,
                    justifyContent: "center",
                    $nest: {
                        "&.scrollbar__view": {
                            top: px(20),
                        },
                    },
                },
                ".input": {
                    flex: 1,
                },
                "&.long-life": {
                    display: "flex",
                },
            },
        },
        switchNoStatus: {
            $nest: {
                ".switch__content:before": {
                    display: "none",
                },
                ".switch__icon": {
                    visibility: "hidden",
                },
                ".switch__content .text": {
                    visibility: "hidden",
                },
            },
        },
        submitButton: {
            marginRight: "auto",
            marginLeft: "auto",
            padding: em(0.7),
        },
    })(VehicleFields)
}
