import { BundleComponent } from "@tm/morpheus"
import component from "./component"
import { reduce } from "./business"

const IFrameModule: BundleComponent<ReturnType<typeof reduce>, typeof component> = {
    name: "iframe-module",
    component,
    reduce,
}

export default IFrameModule
