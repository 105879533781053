import * as React from "react"
import { Provider, connect } from "react-redux"
import { bindActionCreators, Dispatch, Action, Store } from "redux"

import { Omit } from "@tm/utils"

type StoreModel = { store: Store }
export function withStoreProvider<P extends StoreModel>(component: React.ComponentType<Omit<P, keyof StoreModel>>): React.ComponentType<P> {
    return class extends React.Component<P> {
        render() {
            const { store } = this.props
            return React.createElement(Provider, { store }, React.createElement(component, this.props))
        }
    }
}

type ActionType<V> = { actions?: V }
export function connector<P extends U & ActionType<V>, U, V, S>(
    component: React.ComponentType<P>,
    mapStToPr?: (store: S) => U,
    actions?: V
): React.ComponentType<Omit<P, keyof U | keyof ActionType<V>>> {
    function mapDisptachToProps(dispatch: Dispatch<Action<V>>, props: P): P {
        return {
            ...props,
            actions: actions && bindActionCreators(actions || {}, dispatch),
        }
    }

    function mapStateToProps(store: S, props: P): P {
        return {
            ...props,
            ...mapStToPr?.(store),
        }
    }

    return connect(mapStateToProps, mapDisptachToProps)(component as React.ComponentType<any>) as any
}
