import { Article, IMicros } from "@tm/models"
import Morpheus, { useMicro } from "@tm/morpheus"
import { useSelector } from "react-redux"
import { Box, NumberSelect, styled } from "@tm/components"
import { ReplaceButton } from "@tm/controls"
import { ICalculationItem } from "../../../data/models"
import { vehicleSelector } from "../../main/business"
import { mapOePart } from "../business/mappers"

type Props = {
    item: ICalculationItem
    onQuantityChange(value: number): void
    onDetailsClick(part: Article): void
    onSelect(): void
    onAlternativeClick(): void
    showAlternativeBtn?: boolean
}

type StyledBox = {
    isWm: boolean
}

export default function OeArticleItem({ item, showAlternativeBtn, onDetailsClick, onSelect, onQuantityChange, onAlternativeClick }: Props) {
    const vehicle = useSelector(vehicleSelector)
    const { renderMicro } = useMicro<IMicros>()
    const isWm = Morpheus.getParams("parts")?.templates?.articleItem?.bundle === "wm"

    const renderBasketButton = () => (
        <Box>
            <StyledBox isWm={isWm} className="add-to-basket-block">
                <NumberSelect minAmount={0} value={item.selectedPart?.quantity ?? item.oeArticle.quantityValue} onValueChange={onQuantityChange} />
                <ReplaceButton isWm={isWm} disabled={!showAlternativeBtn} onClick={onAlternativeClick} />
            </StyledBox>
        </Box>
    )

    const renderDefaultHeader = () => (
        <>
            {renderMicro("parts", "oe-part-item", {
                oePart: mapOePart(item.oeArticle),
                renderBasketButton,
                hiddenFakeActions: { costEstimation: true, selector: true, moreMenu: true, details: true },
                onArticleSelect: onSelect,
                isSelected: item.isSelected,
            })}
        </>
    )

    const renderAlternativeHeader = (selectedPart: Article) => (
        <>
            {renderMicro("parts", "part-item", {
                partToLoad: {
                    productGroupId: selectedPart.productGroup.id,
                    supplierArticleNo: selectedPart.supplierArticleNo,
                    supplierId: selectedPart.supplier.id,
                    tecdocTypeNo: vehicle?.tecDocTypeId,
                    quantity: selectedPart?.quantity,
                },
                renderBuyActions: renderBasketButton,
                onRequestArticleDetails: () => onDetailsClick(selectedPart),
                onArticleSelect: onSelect,
                isSelected: item.isSelected,
                showActions: true,
                showArticleImage: true,
                canFilterArticleAttributes: false,
            })}
        </>
    )

    if (item.selectedPart) {
        return renderAlternativeHeader(item.selectedPart)
    }

    return renderDefaultHeader()
}

const StyledBox = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isWm",
})<StyledBox>(({ isWm }) => ({
    display: "flex",
    ...(isWm
        ? {
            justifyContent: "space-between",
        }
        : {
            gap: ".5em",
            marginRight: ".2em",
            fontWeight: "500",
        }),
}))
