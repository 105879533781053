import { useEffect, useMemo } from "react"
import { Badge, Box, Paper, styled } from "@tm/components"
import { Icon, Scrollbar, TabControl } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { stepNavigationActions, tyresWheelsActions, useFastServiceStore } from "../../data"
import { useIsFirstRender } from "../../helpers"
import { CustomPanel, NextStep, TitleHeader } from "../_shared"
import WheelCheckTab from "./components/wheelCheckTab"

export default function TyresWheels() {
    const { translateText } = useLocalization()

    const selectedTab = useFastServiceStore((state) => state.tyresWheels.selectedTab)
    const tabs = useFastServiceStore((state) => state.tyresWheels.tabs)
    const showBonus = useFastServiceStore((state) => state.tyresWheels.showBonus)
    const currentStepName = "tiresWheels"
    const completeTabs = useMemo(() => tabs.every((x) => x.isComplete), [tabs])
    const isFirstMount = useIsFirstRender()

    const handleTabSelect = (tabName: string) => {
        const selectedTab = tabs.find((x) => x.name === tabName)
        selectedTab && tyresWheelsActions.selectTab(selectedTab)
    }

    useEffect(() => {
        !isFirstMount && stepNavigationActions.completeStep(currentStepName)

        if (completeTabs) {
            stepNavigationActions.updateNextStep(currentStepName)
        }
    }, [completeTabs])

    const handleClick = () => {
        tyresWheelsActions.updateShowBonus(!showBonus)
    }

    const renderPanelTitle = (title: string) => {
        return (
            <TitleHeader
                title={title}
                onStatusButtonClick={() => {}}
                selectedStatusButton={(tabs.every((x) => x.isComplete) && "checkButton") || "none"}
                readonly
            />
        )
    }

    return (
        <Scrollbar>
            <StyledWrapper>
                <StyledContainer>
                    <CustomPanel title={renderPanelTitle(translateText(13063))} initiallyClosed={false} />
                    <StyledTabControl selectedTabIdentifier={selectedTab.name} onTabSelect={handleTabSelect}>
                        {tabs.map((item, idx) => (
                            <TabControl.Tab className={item.index === selectedTab.index ? "hola" : ""} key={idx} identifier={item.name}>
                                {translateText(item.translation)}
                                {(item.isComplete || item.index === selectedTab.index) && (
                                    <StyledBadge isComplete={item.isComplete} badgeContent={<Icon skin="success" name="check" size="m" />} />
                                )}
                            </TabControl.Tab>
                        ))}
                    </StyledTabControl>
                    <Paper sx={{ flexGrow: 1 }}>
                        {tabs.map((item, idx) => (
                            <div key={idx}>{selectedTab.index === idx && <WheelCheckTab tab={item} />}</div>
                        ))}
                    </Paper>
                </StyledContainer>

                <NextStep currentStepName={currentStepName} buttonTextId={414} icon="arrows_down" active={completeTabs} />
            </StyledWrapper>
        </Scrollbar>
    )
}

const StyledWrapper = styled("div")({
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    position: "relative",
})

const StyledContainer = styled(Box)({
    paddingLeft: "1em",
    paddingRight: "0.75em",
    paddingTop: "0.5em",
})

const StyledTabControl = styled(TabControl)(({ theme }) => ({
    display: "flex",
    marginTop: "1em",
    flexGrow: 1,
    "& .tab": {
        flexGrow: 1,
        justifyContent: "center",
        position: "relative",
        margin: theme.margin?.xs ?? "0.1em",
        border: "1px solid transparent",
        borderRadius: theme.radius?.default,
        "&:first-of-type": {
            marginLeft: "0",
        },
        "&:last-of-type": {
            marginRight: "0",
        },
    },
    "& .hola": {
        border: `1px solid ${theme.colors?.primary ?? "inherit"} !important`,
        background: "white !important",
        color: "black !important",
    },
}))

const StyledBadge = styled(Badge)<{ isComplete: boolean }>(({ isComplete, theme }) => ({
    position: "absolute",
    top: "1px",
    right: "15px",
    "& .MuiBadge-badge": {
        padding: ".25rem",
        background: isComplete ? `${theme.colors?.success}` : "white",
        height: "unset",
        borderRadius: "1rem",
        borderColor: isComplete ? "white !important" : `${theme.colors?.success} !important`,
        border: "1px solid",
        "& .icon": {
            fill: isComplete ? "white !important" : `${theme.colors?.success} !important`,
        },
    },
}))
