import { ActivatableModule, ModuleOptionType } from "@tm/models"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleGetDemoModules(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    if (!data?.getDemoModules || !source) {
        return
    }

    let { moduleGroup } = data.getDemoModules

    if (!moduleGroup) {
        moduleGroup = "technicalDataTruck"
    }

    this.props.getActivatableModules?.().then((activatableModules) => {
        const group = activatableModules?.moduleGroups?.find((x) => x.id === moduleGroup)

        const demoModulesResponse =
            group?.modules?.map((m) => ({
                id: m.id ?? m.externalId,
                status: getDemoStatus(m),
            })) || []

        source.postMessage({ demoModulesResponse }, "*")
    })
}

enum DemoStatus {
    demo = "DEMO",
    full = "FULL",
    locked = "LOCKED",
    unknown = "UNKNOWN",
}

function getDemoStatus(activatableModule: ActivatableModule) {
    if (!activatableModule) {
        return DemoStatus.unknown
    }

    const defaultFullOptionModule = activatableModule.moduleOptions?.find((moduleOption) => moduleOption.type === ModuleOptionType.Full)
    const defaultDemoOptionModule = activatableModule.moduleOptions?.find((moduleOption) => moduleOption.type === ModuleOptionType.Demo)

    if (defaultDemoOptionModule?.active) {
        return DemoStatus.demo
    }

    if (defaultFullOptionModule?.active) {
        return DemoStatus.full
    }

    if (defaultDemoOptionModule?.activatable) {
        return DemoStatus.locked
    }

    return DemoStatus.unknown
}
