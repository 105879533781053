import { UserContext } from "@tm/models"
import { merge } from "lodash"
import { fastCheckWidgetCoverFactory } from "./widgetCovers/fastCheckWidgetCoverFactory"
import { ItemPosition, WidgetCoverModules } from "./models"
import { uniPartsPremiumFactory } from "./widgetCovers/uniPartsPremiumFactory"

export function createWidgetCoverModules(userContext: UserContext, position: ItemPosition) {
    let widgetCoverModules: WidgetCoverModules = {}

    const loadModule = (payload: WidgetCoverModules | undefined) => {
        if (!payload) {
            return
        }
        widgetCoverModules = merge(widgetCoverModules, payload)
    }

    loadModule(fastCheckWidgetCoverFactory(userContext))
    loadModule(uniPartsPremiumFactory(userContext))

    return widgetCoverModules
}
