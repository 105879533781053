import { Box, Button, Icon, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { UploadButton } from "../uploadButton/UploadButton"

export function NoResult() {
    const { translateText } = useLocalization()

    return (
        <Box width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
            <Box display="flex" flexDirection="column" alignItems="center" gap="1em">
                <Icon width="58" height="58" name="vefiles" />
                <Typography variant="h2">{translateText(13795)}</Typography>
                <UploadButton>
                    <Button size="extralarge">{translateText(3037)}</Button>
                </UploadButton>
            </Box>
        </Box>
    )
}
