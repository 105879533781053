import { useState } from "react"
import { style } from "typestyle"
import { AllowedFileSelectionTypes, Button, FileSelectButton, FileSelectError, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { Formular } from "../../modul/toolbar-form/component"
import { Email } from "../email"

const HeadlineStyle = style({
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    paddingBottom: "1em",
})

const LableInput = style({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    padding: "0.5em 0",
    $nest: {
        div: {
            width: "70%",
        },
        "& > div > div": {
            width: "100%",
        },
        "& > div > div > div": {
            width: "100%",
        },
    },
})

const textArea = style({
    whiteSpace: "break-spaces",
    padding: "1em 0",
    display: "block",
})

const inputMultiline = style({
    height: "120px",
    $nest: { "& > div > div": { height: "100%" } },
})

const submitButton = style({ display: "flex", justifyContent: "flex-end" })

const selectFiles = style({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
})
const selectedFile = style({
    paddingRight: "14px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
})

const requiredField = style({
    position: "relative",
    $nest: {
        "&::after": {
            content: "' *'",
            position: "absolute",
            right: 0,
            top: 0,
            transform: "translateX(15px)",
        },
    },
})

const MAXFILESIZE = 3072
const FILETYPES: AllowedFileSelectionTypes = ["jpg", "png", "gif"]

export type FeedbackProps = {
    preset: Formular
    onDecline: () => void
    onSubmit: (formValue: Formular) => void
}

export function FeedbackFormular(props: FeedbackProps) {
    const { preset, onDecline, onSubmit } = props
    const [formValue, setFormValue] = useState<Formular>(preset)
    const [isValid, setIsValid] = useState<boolean>(false)
    const [filename, setFilename] = useState<string>("")
    const [error, setError] = useState<string>("")

    const { translateText } = useLocalization()

    const handleOnNameChange = (value: string) => {
        setFormValue({ ...formValue, name: value })
    }

    const handleOnEmailChange = (value: string, valid: boolean) => {
        setIsValid(valid)
        setFormValue({ ...formValue, email: value })
    }

    const handleOnFileChange = (fileData: string, fileName: string, fileMimeType: string) => {
        setFilename(fileName)
        setFormValue({ ...formValue, file: { fileData, fileName, fileMimeType } })
    }

    const onFileUploadError = (type: FileSelectError) => {
        if (type === "FILE_TOO_BIG") {
            setError(translateText(12797).replace("{0}", (MAXFILESIZE / 1000).toString()))
        }
        if (type === "WRONG_FILE_TYPE") {
            setError(translateText(12798).replace("{0}", FILETYPES.toString()))
        }
    }

    const handleOnPhoneChange = (value: string) => {
        setFormValue({ ...formValue, phone: value })
    }

    const handleOnFeedbackTextChange = (value: string) => {
        setFormValue({ ...formValue, text: value })
    }

    return (
        <div>
            <div className={HeadlineStyle}>
                {translateText(12868)}
                <div>
                    <Button size="l" icon="close" onClick={onDecline} />
                </div>
            </div>
            <div>
                <TextField
                    multiline
                    className={`${inputMultiline} ${requiredField}`}
                    placeholder={translateText(1213)}
                    value={formValue.text}
                    onChange={handleOnFeedbackTextChange}
                />
                <Text size="s" className={textArea}>
                    {translateText(12867)}
                </Text>
                <div>
                    <div className={LableInput}>
                        <span>{translateText(155)}:</span>
                        <TextField placeholder={translateText(155)} value={formValue.name} onChange={handleOnNameChange} className={requiredField} />
                    </div>
                    <div className={LableInput}>
                        <span>{translateText(109)}:</span>
                        <Email
                            placeholder={translateText(109)}
                            value={formValue.email}
                            onHandleChange={handleOnEmailChange}
                            className={requiredField}
                        />
                    </div>
                    <div className={LableInput}>
                        <span>{translateText(110)}:</span>
                        <TextField placeholder={translateText(110)} value={formValue.phone} onChange={handleOnPhoneChange} />
                    </div>
                    <div className={LableInput}>
                        <span>{translateText(12863)}:</span>
                        <div className={selectFiles}>
                            <span className={selectedFile}>{filename}</span>
                            <FileSelectButton
                                allowedFileTypes={FILETYPES}
                                maxFileSize={MAXFILESIZE}
                                onLoad={handleOnFileChange}
                                onError={onFileUploadError}
                                onOpenFileDialog={() => setError("")}
                            >
                                {translateText(3030)}
                            </FileSelectButton>
                        </div>
                    </div>
                    {error && (
                        <Text modifiers="danger" className={textArea}>
                            {error}
                        </Text>
                    )}
                    <div>
                        <Text size="s">*) {translateText(12864)}</Text>
                    </div>
                    <Text size="s" className={textArea}>
                        {translateText(12865)}
                    </Text>
                    <div className={submitButton}>
                        <Button
                            size="l"
                            skin="highlight"
                            onClick={() => onSubmit(formValue)}
                            disabled={!isValid || !formValue.name || !formValue.email || !formValue.text}
                        >
                            {translateText(12866)}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}
