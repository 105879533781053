import { getStyleTheme, useStyle } from "@tm/context-distribution"
import { SuggestionTextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { useActions } from "@tm/morpheus"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Box, styled } from "@tm/components"
import { validateFields } from "../../../data/helpers"
import { RepairCoverages } from "../../../data/models"
import { MainActions, summarySelector } from "../../main/business"

export default function MainFields() {
    const { translateText, languageId } = useLocalization()
    const actions = useActions(MainActions, "updateFieldValue")
    const [selectedRepairCoverage, setSelectedRepairCoverage] = useState<RepairCoverages>()
    const { fields } = useSelector(summarySelector)
    const modelState = validateFields(fields, translateText)
    const style = useMemo(() => getStyle(), [])

    const repairScopes = useMemo<Record<RepairCoverages, string>>(
        () => ({
            0: translateText(12470),
            1: translateText(12471),
        }),
        [languageId]
    )

    const handleUpdateCoverageField = (value: string): void => {
        const key = (Object.keys(repairScopes) as Array<RepairCoverages>).find((x) => repairScopes[x] === value)

        actions.updateFieldValue("repairCoverage", key)
    }

    useEffect(() => {
        fields.repairCoverage && setSelectedRepairCoverage(fields.repairCoverage)
    }, [fields.repairCoverage, languageId])

    return (
        <Box borderTop=".1em #e2e2e2 solid" display="flex" alignItems="center">
            <SuggestionTextField
                floatingLabel
                className={style.processName}
                value={fields.processName}
                label={translateText(1471)}
                modelState={modelState}
                onChange={(x) => actions.updateFieldValue("processName", x)}
                path={["processName"]}
            />
            <StyledBox>
                <SuggestionTextField
                    floatingLabel
                    label={translateText(3141)}
                    value={selectedRepairCoverage && repairScopes[selectedRepairCoverage]}
                    getSuggestionData={() => Object.values(repairScopes)}
                    onChangeConfirm={handleUpdateCoverageField}
                    modelState={modelState}
                    path={["repairCoverage"]}
                />
            </StyledBox>
        </Box>
    )
}

function getStyle() {
    const theme = getStyleTheme()

    return useStyle({
        processName: {
            maxWidth: "20em",
            margin: theme.margin.s,
        },
        dropdown: {
            width: "15em",
            marginTop: theme.margin.s,
        },
    })(MainFields)
}

const StyledBox = styled(Box)({
    ".suggest__box": {
        paddingLeft: "0.25em",
        paddingRight: "0.25em",
    },
})
