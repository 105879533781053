import { VehicleImage, Box, styled, Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { useSelector } from "react-redux"
import { managerSelector } from "../../../selectors"

export default function Header() {
    const { translateText } = useLocalization()
    const { vehicle } = useSelector(managerSelector)

    if (!vehicle) {
        return null
    }

    return (
        <Box margin="1em 1em -1em 1em">
            <Box display="inline-flex">
                <Typography variant="h2" mb={1}>
                    {translateText(1458)}
                </Typography>
                <ProviderLogo />
            </Box>

            <Typography variant="body1">
                {translateText(1462)} {translateText(1463)}
            </Typography>

            <VehicleInfo>
                <Typography variant="body1">
                    {translateText(1464)} {translateText(1481)}
                </Typography>

                <Box display="flex" alignItems="center" width="4em" height="3em">
                    {vehicle?.modelThumbnail && (
                        <VehicleImage
                            modelImage={vehicle.modelThumbnail}
                            modelSeriesImage={vehicle.modelSeriesThumbnail || ""}
                            vehicleType={vehicle.vehicleType}
                            style={{ width: "3em", margin: "0 .5em" }}
                        />
                    )}
                </Box>

                <Typography>
                    {vehicle?.manufacturer} {vehicle?.modelSeries}
                </Typography>
            </VehicleInfo>
        </Box>
    )
}

const ProviderLogo = styled(Box)(() => ({
    marginLeft: "1em",
    width: "75px",
    height: "54px",
    backgroundImage: "url('/styles/base/images/dat-logo.png')",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
}))

const VehicleInfo = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flex: 1,
    margin: theme.spacing(1, 0),
}))
