import { IncludeAdditionalWorksRequest } from "./model"

export function createIncludeAdditionalWorksRequest(
    calcId: string,
    eTag: string,
    calculateIncludedAdditionalWork: boolean
): IncludeAdditionalWorksRequest {
    return {
        calcId,
        eTag,
        calculateIncludedAdditionalWork,
    }
}
