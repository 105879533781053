import { OE } from "@tm/models"

import { useLoadErpInfos } from "."
import { mapOePartToArticle } from "../../../../data/mapper/article"
import { useDefaultErpSystem } from "../useDefaultErpSystem"
import { ErpInfosData } from "../../models"

export type OePartErpRequest = { oePart: OE.OePart; quantity: number }

export function useLoadOePartErpInfos(erpInfosData: ErpInfosData) {
    const defaultErpSystem = useDefaultErpSystem()
    const loadErpInfos = useLoadErpInfos(erpInfosData)

    return (oePartErpRequests: OePartErpRequest[]) => {
        if (!oePartErpRequests) {
            return
        }

        const erpRequestArticles = oePartErpRequests.map((oePart) => ({
            article: mapOePartToArticle(oePart.oePart, oePart.quantity),
            quantity: oePart.quantity,
        }))

        if (defaultErpSystem?.hasOeArticleSupport) {
            loadErpInfos(erpRequestArticles)
        }
    }
}

export function useLoadOePositionsErpInfos(erpInfosData: ErpInfosData) {
    const loadOeParts = useLoadOePartErpInfos(erpInfosData)

    return (oePositions: OE.OePosition[]) => {
        const allOeParts: OE.OePart[] = []
        oePositions.map((op) => op.parts && allOeParts.push(...op.parts))
        oePositions.map((op) => op.replacements?.forEach((replacement) => replacement.parts && allOeParts.push(...replacement.parts)))
        loadOeParts(allOeParts.map((p) => ({ oePart: p, quantity: p.quantity || 1 })))
    }
}
