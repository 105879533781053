import { useEffect } from "react"
import { encodeUniqueId, renderRoute, uniqueId } from "@tm/utils"
import { Customer, Vehicle } from "@tm/models"
import { useWorkTask } from "@tm/context-distribution"
import { useLocalization } from "@tm/localization"
import { CustomerInfo, InitialRepairshopMetaData } from "../../../../../../data"
import { OpenWorkTaskDialogState } from ".."
import { ModalLoader } from "./_shared"
import { useSendChatMessage } from "../../../../../../data/hooks"
import { setVehicleInfoData, createNewVehicle, setCustomerInfoData, findLatestWorkTaskId } from "./helpers"

type Props = {
    chatId: string
    customer: Customer | undefined
    vehicle: Vehicle | undefined
    tmCustomerId: string | undefined
    customerInfo: CustomerInfo
    openOptions: OpenWorkTaskDialogState
    conflictResolution: "switch" | "new" | undefined
}

/**
 * Diagram:
 * https://confluence.dvse.de/display/PROM/Chat+API+and+Integration+in+Next#ChatAPIandIntegrationinNext-WorktaskcreationwithVehicle/CustomerSelection
 */
export default function Finish(props: Props) {
    const { translateText } = useLocalization()
    const { createWorkTask } = useWorkTask() || {}
    const send = useSendChatMessage()

    useEffect(() => {
        const { selectedChatVehicle, subRoute = "", skipRenderRoute, modal } = props.openOptions
        let cancelled = false // semicolon is necessary

        ;(async () => {
            const customer = setCustomerInfoData(props.customer || { id: uniqueId() }, props.customerInfo)

            let vehicle: Vehicle
            if (props.vehicle && props.conflictResolution !== "new") {
                vehicle = setVehicleInfoData(props.vehicle, selectedChatVehicle.vehicleInfo, customer.id)
            } else {
                vehicle = await createNewVehicle(selectedChatVehicle.vehicleInfo, customer.id)
            }

            if (cancelled) {
                return
            }

            const workTaskId = (await findLatestWorkTaskId(vehicle.id, customer.id)) || uniqueId()

            if (cancelled) {
                return
            }

            let subPage = skipRenderRoute
                ? subRoute
                : renderRoute(subRoute, {
                      vehicleType: vehicle.vehicleType,
                      vehicleId: encodeUniqueId(vehicle.id),
                      customerId: encodeUniqueId(customer.id),
                  })

            if (modal) {
                const params = new URLSearchParams()
                params.set(
                    "(1)",
                    renderRoute(modal.route, {
                        ...modal.params,
                        workTaskId: encodeUniqueId(workTaskId),
                    })
                )
                subPage += (subPage.includes("?") ? "&" : "?") + params.toString()
            }

            createWorkTask?.({ workTaskId, vehicle, customer, subPage }).then(() => {
                const metaData: InitialRepairshopMetaData = { type: "initial", action: "repairshopData", vehicles: [] }
                if (customer.id !== props.tmCustomerId) {
                    metaData.customer = { tmCustomerId: customer.id, seatbeltCustomerId: props.customerInfo.id }
                }

                if (vehicle.id !== selectedChatVehicle.tmVehicleId) {
                    metaData.vehicles.push({
                        tmVehicleId: vehicle.id,
                        seatbeltVehicleId: selectedChatVehicle.vehicleInfo.id,
                    })
                }

                if (metaData.customer || metaData.vehicles.length) {
                    const message = translateText(metaData.customer ? 3110 : 3109)
                    send({ chatId: props.chatId, message, attachment: { metaData } })
                }
            })
        })()

        return () => {
            cancelled = true
        }
    }, [props.customer, props.customerInfo, props.vehicle, props.conflictResolution, props.openOptions])

    return <ModalLoader />
}
