import { WidgetState } from "./model"

export * from "./model"

export type ComponentActionType = { type: "SET_SELECTED_PROVIDER"; payload: number }

const DEFAULT_STATE: WidgetState = {
    selected: 0,
}

export function reduce(state = DEFAULT_STATE, action: ComponentActionType): WidgetState {
    switch (action.type) {
        case "SET_SELECTED_PROVIDER": {
            return {
                ...state,
                selected: action.payload,
            }
        }
    }

    return state
}

export function setSelectedProvider(provider: number): ComponentActionType {
    return { type: "SET_SELECTED_PROVIDER", payload: provider }
}

export type IActions = typeof Actions

export const Actions = {
    setSelectedProvider,
}
