import { useLocalization } from "@tm/localization"
import { useQuery } from "react-query"
import { getBestsellerSearchtree } from "../../../../data/repositories/uni-parts"

export function useGetBestsellerSearchTree(enabled: boolean, nodeId?: number) {
    const { languageId } = useLocalization()
    const { data, isLoading, isIdle } = useQuery(
        ["parts__unisearch_bestsellersearchtree", languageId, nodeId],
        () => getBestsellerSearchtree({ nodeId }),
        {
            staleTime: 30 * 60 * 1000, // 30 minutes
            enabled,
        }
    )

    return { data: data?.nodes, isLoading, isIdle }
}
