// https://github.com/matthiasanderer/icsFormatter/blob/master/icsFormatter.js
export default class ICS {
    SEPARATOR = "\n"

    calendarEvents: Array<string> = []

    calendarStart = ["BEGIN:VCALENDAR", "VERSION:2.0"].join(this.SEPARATOR)

    calendarEnd = `${this.SEPARATOR}END:VCALENDAR`

    /**
     * Returns events array
     * @return {array} Events
     */
    events() {
        return this.calendarEvents
    }

    /**
     * Returns calendar
     * @return {string} Calendar in iCalendar format
     */
    calendar() {
        return this.calendarStart + this.SEPARATOR + this.calendarEvents.join(this.SEPARATOR) + this.calendarEnd
    }

    /**
     * Add event to the calendar
     */
    addEvent(subject: string, begin: Date, durationMinutes: number, description?: string) {
        const startYear = begin.getUTCFullYear().pad(4, "0")
        const startMonth = (begin.getUTCMonth() + 1).pad(2, "0")
        const startDay = begin.getUTCDate().pad(2, "0")
        const startHours = begin.getUTCHours().pad(2, "0")
        const startMinutes = begin.getUTCMinutes().pad(2, "0")
        const startSeconds = begin.getUTCSeconds().pad(2, "0")

        const start = `${startYear + startMonth + startDay}T${startHours}${startMinutes}${startSeconds}Z`

        const calendarEvent = [
            "BEGIN:VEVENT",
            "CLASS:PUBLIC",
            `DESCRIPTION:${description}` || "",
            `DTSTART;VALUE=DATE:${start}`,
            // 'DTEND;VALUE=DATE:' + end,
            // 'LOCATION:' + location,
            `SUMMARY;LANGUAGE=en-us:${subject}`,
            "TRANSP:TRANSPARENT",
            `DURATION:PT${durationMinutes}M`,
            "END:VEVENT",
        ].join(this.SEPARATOR)

        this.calendarEvents.push(calendarEvent)
        return calendarEvent
    }

    download(/* filename: string, extension?: string */) {
        if (!this.calendarEvents.length) {
            return
        }

        // ext = (typeof ext !== 'undefined') ? ext : '.ics';
        // filename = (typeof filename !== 'undefined') ? filename : 'calendar';
        const calendar = this.calendarStart + this.SEPARATOR + this.calendarEvents.join(this.SEPARATOR) + this.calendarEnd
        window.open(`data:text/calendar;charset=utf8,${encodeURI(calendar)}`)
    }

    getDataUrl() {
        if (!this.calendarEvents.length) {
            return
        }

        const calendar = this.calendarStart + this.SEPARATOR + this.calendarEvents.join(this.SEPARATOR) + this.calendarEnd
        return `data:text/calendar;charset=utf8,${encodeURI(calendar)}`
    }
}
