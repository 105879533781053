import { RequestArticleListPayload, channel } from "@tm/models"
import { uniqueId } from "@tm/utils"
import { PostMessageControllerComponent } from "../component"
import { PostMessageRequest } from "../../../data"

export default function handleOpenArticleListByWholesalerNumbers(this: PostMessageControllerComponent, data: PostMessageRequest) {
    const { openArticleListByWholesalerArticleNumbers } = data

    if (!openArticleListByWholesalerArticleNumbers?.wholesalerNumbers) {
        return
    }

    this.checkAndCreateWorktask()

    const request: RequestArticleListPayload = {
        traderArticleNos: {
            wholesalerParts: openArticleListByWholesalerArticleNumbers.wholesalerNumbers.map((x) => ({
                wholesalerArticleNumber: x,
                itemId: uniqueId(),
            })),
        },
        inModal: true,
        useNewModal: true,
    }

    channel("WORKTASK").publish("PARTS/REQUEST_LIST", request)
}
