import { Fragment, KeyboardEvent, MouseEvent, ReactNode, useEffect, useState } from "react"
import { useSelectedVehicleLookup } from "@tm/context-distribution"
import { Alert, Button, DateField, Icon, Image, Loader, SubTitle, Table, Text, TextField } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { BikeModel, CarModel, ECounterType, RegistrationNoType, TruckModel, VehicleImportData, VehicleType } from "@tm/models"
import { VehicleImage, Typography, Box, styled } from "@tm/components"
import { TmaHelper } from "@tm/utils"
import { getVinSuggestions } from "../../data/repositories/vin-picker"
import { onlyDigits } from "../../helpers"
import { constructionYearToDate, renderDateRange } from "../../helpers/construction-year"
import { getBundleParams } from "../../utils"

import { VinInputField } from "./VinInput"

type Props<T> = {
    models: Array<T>
    selectedModel?: T
    initialVin?: string
    engineCodeFilter?: string

    onSelect(model: T): void
    onApply(model: T, plateId?: string, vin?: string, initialRegistration?: Date, mileAge?: number): void
    onDetailsClick(model: T): void
    onSelectEngineCode?(code?: string): void

    modelLoading?: boolean
    importedData?: VehicleImportData
    vehicleType: VehicleType

    showManufacturerThumbnail: boolean
    showModelThumbnail: boolean
    showRegNoColumn?: boolean | string

    customerVehiclesAvailable?: boolean
    searchQuery?: string
    inlineFilterElement?: ReactNode
}

enum CollumClasses {
    Infos = "infos",
    Enginecode = "enginecode",
    Daterange = "daterange",
    PowerKw = "powerKw",
    PowerHp = "powerHp",
    Capacity = "capacity",
    CylinderCount = "cylinderCount",
    Fuel = "fuel",
    AxisConfig = "axisConfig",
    Tonnage = "tonnage",
    Actiopns = "actions",
    Inputs = "inputs",
    Power = "power",
    RegNo = "reg-no",
}

const ColumnHeader = styled("div")({
    cursor: "pointer",
    display: "flex",
})

const ColumnHeaderActive = styled(ColumnHeader)(({ theme }) => ({
    color: theme.palette.primary.main,
    fill: theme.palette.primary.main,
}))

export default function ModelListComponent<T extends CarModel | BikeModel | TruckModel>(props: Props<T>) {
    const { translateText, translate, date, number } = useLocalization()
    const {
        importedData,
        models,
        engineCodeFilter,
        modelLoading,
        vehicleType,
        showRegNoColumn,
        searchQuery,
        customerVehiclesAvailable,
        inlineFilterElement,
        showManufacturerThumbnail,
        showModelThumbnail,
        initialVin,
    } = props
    const [selectedModel, setSelectedModel] = useState<T | undefined>(props.selectedModel)
    const [selectedModelPlateId, setSelectedModelPlateId] = useState<string>()
    const [selectedModelVin, setSelectedModelVin] = useState<string | undefined>(initialVin)
    const [selectedModelInitialRegistration, setSelectedModelInitialRegistration] = useState<Date>()
    const [selectedModelMileAge, setSelectedModelMileAge] = useState<number>()
    const [validVin, setValidVin] = useState<boolean>()
    const [sortModels, setSortModels] = useState<Array<T> | undefined>()
    const [activeSort, setActiveSort] = useState<CollumClasses>()
    const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">()
    const { selectedVehicleLookup } = useSelectedVehicleLookup()

    useEffect(() => {
        setSelectedModel(props.selectedModel)
    }, [props.selectedModel])

    function getDataSourceLabel(dataSourceId: RegistrationNoType) {
        switch (dataSourceId) {
            case RegistrationNoType.Kba:
                return translateText(12592)
            case RegistrationNoType.TypenscheinDehrendinger:
            case RegistrationNoType.TypenscheinSchweizCarat:
            case RegistrationNoType.TypenscheinSchweizEsa:
            case RegistrationNoType.TypenscheinSchweizEurotax:
            case RegistrationNoType.TypenscheinSchweizHoststettler:
                return translateText(1730)
            case RegistrationNoType.KennzeichenEstland:
            case RegistrationNoType.KennzeichenFinnlandAd:
            case RegistrationNoType.KennzeichenFinnlandHlGroup:
            case RegistrationNoType.KennzeichenFinnlandParkanon:
            case RegistrationNoType.KennzeichenFinnlandSpareParts:
            case RegistrationNoType.KennzeichenIslandOderVrmIsland:
            case RegistrationNoType.KennzeichenNiederlande:
            case RegistrationNoType.KennzeichenNiederlandeCarat:
            case RegistrationNoType.KennzeichenNiederlandeSchwenker:
            case RegistrationNoType.KennzeichenNorwegenNbk:
            case RegistrationNoType.KennzeichenPortugal:
            case RegistrationNoType.KennzeichenSchwedenBanner:
            case RegistrationNoType.KennzeichenSchwedenKgk:
            case RegistrationNoType.KennzeichenSchwedenSchwenker:
            case RegistrationNoType.VINFranceInforauto:
                return translateText(21)
            case RegistrationNoType.NationalcodeAustria:
            case RegistrationNoType.NationalcodeFrankreich:
            case RegistrationNoType.NationalCodeFrankreichCarat:
            case RegistrationNoType.NationalcodeFrankreichHerthBuss:
                return translateText(1218)
            case RegistrationNoType.KTypNr:
                return translateText(123)
            case RegistrationNoType.TopmotiveVin:
            case RegistrationNoType.VinNorwegen:
            case RegistrationNoType.VinPortugal:
            case RegistrationNoType.VinSlowakei:
            case RegistrationNoType.VinSlowenien:
                return translateText(1931)
            case RegistrationNoType.Motorcode:
                return translateText(105)
            case RegistrationNoType.KeywordSearch:
                return translateText(1094)
            case RegistrationNoType.TopmotiveTypeId:
                return translateText(13462)
            default:
                break
        }
    }

    function handleKeyStroke(event: KeyboardEvent<HTMLDivElement>) {
        if (event.key === "Enter" && selectedModel) {
            handleApply(selectedModel)
        }
    }

    function handleDetailsClick(item: T, e: MouseEvent<HTMLButtonElement>) {
        e.stopPropagation()
        TmaHelper.GeneralCountEvent.Call(ECounterType.PassengerCarDetails)
        props.onDetailsClick(item)
    }

    function handleSelect(item: T) {
        if (!selectedModel || selectedModel != item) {
            setSelectedModel(item)
            if (importedData) {
                setSelectedModelInitialRegistration(importedData.initialRegistration)
                setSelectedModelMileAge(importedData.mileAge)
                setSelectedModelPlateId(importedData.plateId)
                setSelectedModelVin(importedData.vin)
            } else {
                setSelectedModelInitialRegistration(item.registrationNoDetails?.initialRegistration)
                setSelectedModelMileAge(undefined)
                setSelectedModelPlateId(item.registrationNoDetails?.plateId)
                setSelectedModelVin(item.registrationNoDetails?.vin)
            }

            props.onSelect(item)
        }
    }

    function handleApply(item: T) {
        if (selectedModel === item) {
            props.onApply(item, selectedModelPlateId, selectedModelVin, selectedModelInitialRegistration, selectedModelMileAge)
        } else {
            props.onApply(item)
        }
    }

    function handleGetVinSuggestions(query: string): Promise<Array<string>> {
        if (!selectedModel) {
            return Promise.resolve([])
        }

        return getVinSuggestions(query, selectedModel.id)
    }

    function handlePlateIdChange(plateId: string) {
        if (!selectedModel) {
            return
        }

        setSelectedModelPlateId(plateId)
    }

    function handleVinChangeConfirm(vin: string) {
        if (!selectedModel) {
            return
        }

        setSelectedModelVin(vin)
    }

    function handleVinChange(vin: string, valid?: boolean) {
        setValidVin(valid)
    }

    function handleInitialRegistrationChange(initialRegistriation: Date) {
        if (!selectedModel) {
            return
        }

        setSelectedModelInitialRegistration(initialRegistriation)
    }

    function handleMileAgeChange(mileAge: string) {
        if (!selectedModel) {
            return
        }

        const parsedMileAge = parseInt(mileAge)
        setSelectedModelMileAge(!isNaN(parsedMileAge) ? parsedMileAge : undefined)
    }

    function handleEngineCodeFilter(code: string | undefined, e: MouseEvent) {
        e?.stopPropagation()
        props.onSelectEngineCode?.(code)
    }

    function sortIndicator(type: CollumClasses) {
        const icon = activeSort === type ? (sortDirection === "ASC" ? "down" : "up") : "down"
        return <Icon key="button__icon" name={icon} size="m" className="btn__icon" />
    }

    function sortCell(type: CollumClasses) {
        let sortDirection: "ASC" | "DESC" = "ASC"
        if (activeSort === type) {
            sortDirection = sortDirection === "ASC" ? "DESC" : "ASC"
        }
        const sortModels = [...models].sort((a, b) => {
            let x
            let y
            switch (type) {
                case CollumClasses.Infos:
                    x = a.description
                    y = b.description
                    break
                case CollumClasses.Enginecode:
                    x = a.engineCodes
                    y = b.engineCodes
                    break
                case CollumClasses.Daterange:
                    x = new Date(
                        a.constructionYearFrom?.year ? a.constructionYearFrom?.year : 0,
                        a.constructionYearFrom?.month ? a.constructionYearFrom?.month : 0
                    )
                    y = new Date(
                        b.constructionYearFrom?.year ? b.constructionYearFrom?.year : 0,
                        b.constructionYearFrom?.month ? b.constructionYearFrom?.month : 0
                    )
                    break
                case CollumClasses.Power:
                    x = (a as CarModel | BikeModel).enginePowerHp
                    y = (b as CarModel | BikeModel).enginePowerHp
                    break
                case CollumClasses.PowerHp:
                    x = (a as TruckModel).enginePowerHpFrom
                    y = (b as TruckModel).enginePowerHpFrom
                    break
                case CollumClasses.PowerKw:
                    x = (a as TruckModel).enginePowerKwFrom
                    y = (b as TruckModel).enginePowerKwFrom
                    break
                case CollumClasses.Capacity:
                    x = a.engineCapacityCcm
                    y = b.engineCapacityCcm
                    break

                case CollumClasses.CylinderCount:
                    x = (a as CarModel | BikeModel).cylinderCount
                    y = (b as CarModel | BikeModel).cylinderCount
                    break

                case CollumClasses.Fuel:
                    if (vehicleType === VehicleType.CommercialVehicle) {
                        x = (a as TruckModel).engineTypeDescription
                        y = (b as TruckModel).engineTypeDescription
                    } else {
                        x = (a as CarModel | BikeModel).fuelType
                        y = (b as CarModel | BikeModel).fuelType
                    }
                    break
                case CollumClasses.AxisConfig:
                    x = (a as TruckModel).axisConfigDescription
                    y = (b as TruckModel).axisConfigDescription
                    break
                case CollumClasses.Tonnage:
                    x = (a as TruckModel).tonnage
                    y = (b as TruckModel).tonnage
                    break
                default:
                    break
            }

            if (x && y) {
                if (sortDirection === "DESC") {
                    return x > y ? 1 : x < y ? -1 : 0
                }
                return x > y ? -1 : x < y ? 1 : 0
            }
            return 0
        })
        setSortModels(sortModels)
        setActiveSort(type)
        setSortDirection(sortDirection)
    }

    function renderEngineCodes(codes: Array<string>) {
        return codes.map((code, idx) => {
            const className = `is-filter-element ${engineCodeFilter === code ? "selected" : ""}`

            return (
                <Fragment key={idx}>
                    {props.onSelectEngineCode ? (
                        <span className={className} onClick={(e) => handleEngineCodeFilter(engineCodeFilter === code ? undefined : code, e)}>
                            {code}
                        </span>
                    ) : (
                        code
                    )}
                    {idx === codes.length - 1 ? "" : ", "}
                </Fragment>
            )
        })
    }

    function renderModelCell(item: T) {
        const dataSource = item.dataSourceId != undefined ? getDataSourceLabel(item.dataSourceId) : undefined
        const manufacturerThumbnail =
            showManufacturerThumbnail && !getBundleParams().hideManufacturerLogos && item.manufacturerThumbnail?.replace(/https?:/, "")

        return (
            <Table.Cell>
                {dataSource && (
                    <SubTitle size="s" className="data-source-label">
                        {translate(1388)}: {dataSource}
                    </SubTitle>
                )}

                <strong>{item.fullDescription || item.description}</strong>
                {(!!manufacturerThumbnail || showModelThumbnail) && (
                    <div className="thumbs">
                        {!!manufacturerThumbnail && <Image url={manufacturerThumbnail} type="manufacturer" />}
                        {showModelThumbnail && (
                            <VehicleImage modelImage={item.thumbnail} modelSeriesImage={item.modelSeriesThumbnail} vehicleType={vehicleType} />
                        )}
                    </div>
                )}
            </Table.Cell>
        )
    }

    function renderRegistrationNumberCell(item: T) {
        const registrationNumber =
            item.registrationNoDetails?.additionalDetails?.find((x: any) => x.type === 23)?.value ??
            item.registrationNoDetails?.registrationNo ??
            (item.registrationNoDetails as any)?.typScheinNr

        return <Table.Cell>{registrationNumber}</Table.Cell>
    }

    function renderEngineCodeCell(item: T) {
        return <Table.Cell>{item.engineCodes?.length ? renderEngineCodes(item.engineCodes) : "-"}</Table.Cell>
    }

    function renderConstructionYearCell(item: T) {
        return <Table.Cell>{renderDateRange(item, date)}</Table.Cell>
    }

    function renderPowerCell(item: CarModel | BikeModel) {
        return (
            <Table.Cell>
                {!item.enginePowerKw && !item.enginePowerHp
                    ? "-"
                    : `${item.enginePowerKw ? `${item.enginePowerKw} ${translate(329)}` : "-"} / ${
                          item.enginePowerHp ? `${item.enginePowerHp} ${translate(330)}` : "-"
                      }`}
            </Table.Cell>
        )
    }

    function renderPowerHpCell(item: TruckModel) {
        let hp = ""
        if (item.enginePowerHpFrom) {
            hp += `${item.enginePowerHpFrom}`
        }
        if (item.enginePowerHpTo) {
            hp += ` - ${item.enginePowerHpTo}`
        }

        return <Table.Cell>{hp ? `${hp} ${translate(330)}` : "-"}</Table.Cell>
    }

    function renderPowerKwCell(item: TruckModel) {
        let kw = ""
        if (item.enginePowerKwFrom) {
            kw += `${item.enginePowerKwFrom}`
        }
        if (item.enginePowerKwTo) {
            kw += ` - ${item.enginePowerKwTo}`
        }

        return <Table.Cell>{kw ? `${kw} ${translate(329)}` : "-"}</Table.Cell>
    }

    function renderCapacityCell(item: CarModel | BikeModel) {
        return <Table.Cell>{item.engineCapacityCcm ? `${item.engineCapacityCcm} ${translate(331)}` : "-"}</Table.Cell>
    }

    function renderCylinderCountCell(item: CarModel | BikeModel) {
        return <Table.Cell>{item.cylinderCount || "-"}</Table.Cell>
    }

    function renderFuelTypeCell(item: CarModel | BikeModel) {
        return <Table.Cell>{item.fuelType || "-"}</Table.Cell>
    }

    function renderEngineTypeCell(item: TruckModel) {
        return <Table.Cell>{item.engineTypeDescription || "-"}</Table.Cell>
    }

    function renderAxisConfigCell(item: TruckModel) {
        return <Table.Cell>{item.axisConfigDescription || "-"}</Table.Cell>
    }

    function renderTonnageCell(item: TruckModel) {
        return <Table.Cell>{item.tonnage ? number(item.tonnage) : "-"}</Table.Cell>
    }

    const handleClickApplyEvent = (item: T) => {
        TmaHelper.GeneralCountEvent.Call(ECounterType.VehicleSelectionSelectVehicle)

        handleApply({
            ...item,
            registrationTypeId: item.registrationNoTypeId || selectedVehicleLookup.lookupTypeId,
            countryCode: item.countryCode || selectedVehicleLookup.countryCode,
        })
    }

    function renderActionsCell(item: T) {
        return (
            <Table.Cell>
                <Button size="s" title={translateText(43)} onClick={(e) => handleDetailsClick(item, e)}>
                    {translateText(43)}
                </Button>
                <Button
                    size="s"
                    title={translateText(22)}
                    onClick={() => {
                        handleClickApplyEvent(item)
                    }}
                >
                    {translateText(22)}
                </Button>
            </Table.Cell>
        )
    }

    function renderVehicleDataCell(item: T) {
        if (!selectedModel || selectedModel != item) {
            return <Table.Cell />
        }

        const constructionYearFrom = item.constructionYearFrom ? constructionYearToDate(item.constructionYearFrom) : undefined

        return (
            <Table.Cell>
                <div className="inner" onDoubleClick={(e) => e.stopPropagation()}>
                    {
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label className="tip-message">{translateText(819)}</label>
                    }
                    <div className="vehicle-data-inputs">
                        <TextField
                            className="plateId"
                            placeholder={translateText(21).toUpperCase()}
                            value={selectedModelPlateId}
                            onChangeConfirm={handlePlateIdChange}
                            formatter={(value: string) => value.toUpperCase()}
                        />
                        <DateField
                            className="initialRegistration"
                            placeholder={translateText(124).toUpperCase()}
                            value={selectedModelInitialRegistration}
                            onChange={handleInitialRegistrationChange}
                            minDate={constructionYearFrom}
                            openToDate={constructionYearFrom}
                            useUtc
                        />
                        <TextField
                            className="mileAge"
                            placeholder={translateText(125).toUpperCase()}
                            value={selectedModelMileAge?.toString()}
                            onChangeConfirm={handleMileAgeChange}
                            maxLength={7}
                            formatter={onlyDigits} // ML 30.09.2020 - Removed dot formatting because it is shitty to edit the mileage then
                        />
                        <VinInputField
                            showEdit
                            vehicleData={{
                                plateId: selectedModelPlateId,
                                vin: selectedModelVin,
                                tecDocManufacturerId: item.manufacturerId,
                            }}
                            textFieldProps={{
                                placeholder: translateText(101),
                            }}
                            onVinChange={handleVinChange}
                            onVinChangeConfirm={handleVinChangeConfirm}
                            getVinSuggestions={handleGetVinSuggestions}
                        />
                    </div>
                    {modelLoading ? (
                        <Loader />
                    ) : (
                        <Button disabled={validVin === false} icon="check" skin="success" onClick={() => handleApply(item)} />
                    )}
                </div>
            </Table.Cell>
        )
    }

    function renderColumnHeader(className: CollumClasses, text: ReactNode) {
        const Component = activeSort === className ? ColumnHeaderActive : ColumnHeader

        return (
            <Component onClick={() => sortCell(className)}>
                {sortIndicator(className)}
                {text}
            </Component>
        )
    }

    function renderCommercialTableColumns() {
        const columns = [
            <Table.Column className={CollumClasses.Infos} renderItemContent={renderModelCell}>
                {renderColumnHeader(CollumClasses.Infos, translate(74))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Enginecode} renderItemContent={renderEngineCodeCell}>
                {renderColumnHeader(CollumClasses.Enginecode, translate(105))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Daterange} renderItemContent={renderConstructionYearCell}>
                {renderColumnHeader(CollumClasses.Daterange, translate(127))}
            </Table.Column>,
            <Table.Column className={CollumClasses.PowerKw} renderItemContent={renderPowerKwCell}>
                {renderColumnHeader(
                    CollumClasses.PowerKw,
                    <>
                        {translate(94)} ({translate(329)})
                    </>
                )}
            </Table.Column>,
            <Table.Column className={CollumClasses.PowerHp} renderItemContent={renderPowerHpCell}>
                {renderColumnHeader(
                    CollumClasses.PowerHp,
                    <>
                        {translate(94)} ({translate(330)})
                    </>
                )}
            </Table.Column>,
            <Table.Column className={CollumClasses.Capacity} renderItemContent={renderCapacityCell}>
                {renderColumnHeader(CollumClasses.Capacity, translate(128))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Fuel} renderItemContent={renderEngineTypeCell}>
                {renderColumnHeader(CollumClasses.Fuel, translate(299))}
            </Table.Column>, // Fueltype is in the engineType field for commercial vehicles
            <Table.Column className={CollumClasses.AxisConfig} renderItemContent={renderAxisConfigCell}>
                {renderColumnHeader(CollumClasses.AxisConfig, translate(1904))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Tonnage} renderItemContent={renderTonnageCell}>
                {renderColumnHeader(CollumClasses.Tonnage, translate(1905))}
            </Table.Column>,
            <Table.Column className="actions" renderItemContent={renderActionsCell} />,
            <Table.Column className="inputs" renderItemContent={renderVehicleDataCell} />,
        ]

        return columns
    }

    function renderTableColumns() {
        const columns = [
            <Table.Column className={CollumClasses.Infos} renderItemContent={renderModelCell}>
                {renderColumnHeader(CollumClasses.Infos, translate(74))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Enginecode} renderItemContent={renderEngineCodeCell}>
                {renderColumnHeader(CollumClasses.Enginecode, translate(105))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Daterange} renderItemContent={renderConstructionYearCell}>
                {renderColumnHeader(CollumClasses.Daterange, translate(127))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Power} renderItemContent={renderPowerCell}>
                {renderColumnHeader(CollumClasses.Power, translate(94))}
            </Table.Column>,
            <Table.Column className={CollumClasses.Capacity} renderItemContent={renderCapacityCell}>
                {renderColumnHeader(CollumClasses.Capacity, translate(128))}
            </Table.Column>,

            <Table.Column className={CollumClasses.CylinderCount} renderItemContent={renderCylinderCountCell}>
                {renderColumnHeader(CollumClasses.CylinderCount, translate(305))}
            </Table.Column>,

            showRegNoColumn ? (
                <Table.Column className={CollumClasses.RegNo} renderItemContent={renderRegistrationNumberCell}>
                    {typeof showRegNoColumn === "string" ? showRegNoColumn : undefined}
                </Table.Column>
            ) : (
                <Table.Column className={CollumClasses.Fuel} renderItemContent={renderFuelTypeCell}>
                    {renderColumnHeader(CollumClasses.Fuel, translate(299))}
                </Table.Column>
            ),

            <Table.Column className="actions" renderItemContent={renderActionsCell} />,
            <Table.Column className="inputs" renderItemContent={renderVehicleDataCell} />,
        ]

        return columns
    }

    return (
        <div className="models" onKeyPress={handleKeyStroke}>
            <Typography variant="h2" mb={1} className="models__headline">
                {translate(12501)}:
            </Typography>
            <Box mb={2}>
                <Text modifiers="block" className="models__pre-text">
                    {customerVehiclesAvailable ? (
                        translate(12502)
                    ) : (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <Icon name="bulb" />
                            <span style={{ marginLeft: "0.25em" }}>{translate(12503)}</span>
                        </div>
                    )}
                </Text>
            </Box>
            {inlineFilterElement}
            {vehicleType === VehicleType.PassengerCar && // Only show message for PKW - see NEXT-16515
                models.length >= 1 &&
                models[0].dataSourceId === RegistrationNoType.KeywordSearch &&
                !searchQuery?.includes(models[0].fullDescription || models[0].description) && ( // Don't show message if search query is containing the vehicle description - see NEXT-16516
                    <div style={{ marginTop: "0.5em" }}>
                        <Alert message={translateText(12773)} description={translateText(12774)} icon="info" skin="warning" />
                    </div>
                )}
            <Table
                data={sortModels || models}
                columns={vehicleType === VehicleType.CommercialVehicle ? renderCommercialTableColumns() : renderTableColumns()}
                onClickRow={handleSelect}
                onDoubleClickRow={handleApply}
                className={vehicleType === VehicleType.CommercialVehicle ? "commercial" : ""}
                getRowClassName={(item) => `models__item ${props.selectedModel === item ? "is-selected" : ""}`}
            />
        </div>
    )
}
