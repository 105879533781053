import { BundleActionTypes } from "../business"
import { MainActionsType } from "../components/main/business"
import { DEFAULT_STATE } from "../components/navigation/business"
import { NavigationState } from "../components/navigation/business/model"
import { DatSelectionSteps, ExternalToolSteps, getExternalToolStepByStatus, getStatusByExternalTool } from "../data/enums"

export type NavigationActionTypes =
    | BundleActionTypes
    | { type: "CHANGE_STEP"; payload: { selectedStep: DatSelectionSteps; params?: any; disableNext?: boolean } }

export const reduce = (state = { ...DEFAULT_STATE }, action: MainActionsType): NavigationState => {
    switch (action.type) {
        case "@@redux/INIT":
        case "RESTART_PROCESS": {
            return {
                ...DEFAULT_STATE,
                active: DatSelectionSteps.SUMARRY,
                params: {},
            }
        }
        case "CHANGE_STEP": {
            const { selectedStep, params, disableNext } = action.payload

            const newState = { ...state }

            if (disableNext) {
                switch (selectedStep) {
                    case DatSelectionSteps.EXTERN: {
                        delete newState.params[DatSelectionSteps.CALCULATION]
                        delete newState.params[DatSelectionSteps.DETAILS]
                        break
                    }
                    case DatSelectionSteps.CALCULATION: {
                        delete newState.params[DatSelectionSteps.DETAILS]
                        break
                    }
                    default:
                }
            }

            newState.active = selectedStep
            newState.params = {
                ...newState.params,
                [selectedStep]: params ?? newState.params[selectedStep] ?? {},
            }

            if (selectedStep === DatSelectionSteps.EXTERN) {
                newState.externVisited = true

                if (disableNext) {
                    newState.calcVisited = false
                    newState.detailsVisited = false
                }
            } else if (selectedStep === DatSelectionSteps.CALCULATION) {
                newState.calcVisited = true

                if (disableNext) {
                    newState.detailsVisited = false
                }
            } else if (selectedStep === DatSelectionSteps.DETAILS) {
                newState.detailsVisited = true
            }

            return newState
        }
        case "INIT_MODULE": {
            const { contractId, view, productGroupId, supplierId, supplierArticleNo, status, partsDetailsSubPage } = action.payload

            return {
                ...state,
                initialized: true,
                ...(view === DatSelectionSteps.SUMARRY && {
                    active: DatSelectionSteps.SUMARRY,
                }),
                ...(view === DatSelectionSteps.EXTERN && {
                    active: DatSelectionSteps.EXTERN,
                    externVisited: true,
                    ...(status &&
                        getExternalToolStepByStatus(status) === ExternalToolSteps.Calculation && {
                            calcVisited: true,
                        }),
                }),
                ...(view === DatSelectionSteps.CALCULATION && {
                    active: DatSelectionSteps.CALCULATION,
                    externVisited: true,
                    calcVisited: true,
                }),
                ...(view === DatSelectionSteps.DETAILS && {
                    active: DatSelectionSteps.DETAILS,
                    externVisited: true,
                    calcVisited: true,
                }),
                params: {
                    ...state.params,
                    ...(view === DatSelectionSteps.EXTERN && {
                        [DatSelectionSteps.EXTERN]: {
                            contractId,
                            status,
                        },
                        ...(status &&
                            getExternalToolStepByStatus(status) === ExternalToolSteps.Calculation && {
                                [DatSelectionSteps.CALCULATION]: {
                                    contractId,
                                },
                            }),
                    }),
                    ...(view === DatSelectionSteps.CALCULATION && {
                        [DatSelectionSteps.EXTERN]: {
                            contractId,
                            status: getStatusByExternalTool(ExternalToolSteps.Calculation),
                        },
                        [DatSelectionSteps.CALCULATION]: {
                            contractId,
                        },
                    }),
                    ...(view === DatSelectionSteps.DETAILS && {
                        [DatSelectionSteps.DETAILS]: {
                            contractId,
                            status,
                            productGroupId,
                            supplierId,
                            supplierArticleNo,
                            partsDetailsSubPage,
                        },
                        [DatSelectionSteps.EXTERN]: {
                            contractId,
                            status: getStatusByExternalTool(ExternalToolSteps.Calculation),
                        },
                        [DatSelectionSteps.CALCULATION]: {
                            contractId,
                        },
                    }),
                },
            }
        }
        case "OPEN_HISTORY_CALCULATION": {
            return {
                ...state,
                externVisited: true,
                params: {
                    ...state.params,
                    [DatSelectionSteps.SUMARRY]: { ...state.params[DatSelectionSteps.SUMARRY] },
                    [DatSelectionSteps.EXTERN]: {
                        ...state.params[DatSelectionSteps.EXTERN],
                        contractId: action.payload,
                        status: getStatusByExternalTool(ExternalToolSteps.Calculation),
                    },
                    [DatSelectionSteps.CALCULATION]: { ...state.params[DatSelectionSteps.CALCULATION], contractId: action.payload },
                },
            }
        }
        case "UPDATE_EXTERN_PAGE": {
            const page = action.payload

            return {
                ...state,
                params: {
                    ...state.params,
                    extern: {
                        ...state.params[DatSelectionSteps.EXTERN],
                        status: getStatusByExternalTool(page),
                    },
                },
            }
        }
        default:
            return state
    }
}
