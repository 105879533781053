import {
    AttachCustomerAndVehicleRequest,
    AttachCustomerRequest,
    AttachVehicleRequest,
    CreateWorkTaskRequest,
    DetachCustomerRequest,
    RegisteredModels,
} from "@tm/models"
import { Container } from "@tm/nexus"
import { getBundleParams } from "../utils"
import {
    createWorktask,
    attachCustomerAndVehicle,
    attachVehicle,
    attachCustomer,
    detachCustomer,
    detachVehicle,
    saveSelected,
    showWorktaskDetailed,
    showCostEstimationDetails,
    changeCostEstimationDetails,
    findLatestWorkTaskByVehicleOrCustomer,
} from "./repositories/work-task"
import { findNotesBuffered, deleteNote, saveNote } from "./repositories/work-task-notes"

export * from "./model"
export * from "./repositories/state-management"
export * from "./repositories/work-task"

export function registerActions() {
    Container.register({
        name: RegisteredModels.Worktask,
        modelActions: {
            load: showWorktaskDetailed,
        },
        containerActions: {
            create: createWorktask,
            createAndReturnNewVersion: (request: CreateWorkTaskRequest) => createWorktask({ ...request, returnNewVersion: true }),
            attachCustomerAndVehicle,
            attachCustomerAndVehicleAndReturnNewVersion: (request: AttachCustomerAndVehicleRequest) =>
                attachCustomerAndVehicle({ ...request, returnNewVersion: true }),
            attachVehicle,
            attachVehicleAndReturnNewVersion: (request: AttachVehicleRequest) => attachVehicle({ ...request, returnNewVersion: true }),
            attachCustomer,
            attachCustomerAndReturnNewVersion: (request: AttachCustomerRequest) => attachCustomer({ ...request, returnNewVersion: true }),
            detachVehicle,
            detachCustomer,
            detachCustomerAndReturnNewVersion: (request: DetachCustomerRequest) => detachCustomer({ ...request, returnNewVersion: true }),
            saveSelected,
            findLatestWorkTaskByVehicleOrCustomer,
        },
    })

    Container.register({
        name: RegisteredModels.Worktask_BasketActivityDone,
        modelActions: {
            load: () => Promise.resolve(),
        },
    })

    Container.register({
        name: RegisteredModels.Worktask_CostEstimationDetails,
        modelActions: {
            load: showCostEstimationDetails,
            save: changeCostEstimationDetails,
        },
    })

    Container.register({
        name: RegisteredModels.WorkTask_Notes,
        containerActions: {
            loadWorkTaskNotesByNoteId: findNotesBuffered,
            saveWorkTaskNote: saveNote,
            deleteWorkTaskNote: deleteNote,
        },
    })
}

export function getWorktaskServiceUrl(): string {
    return getBundleParams().serviceUrl
}

export function getStateServiceUrl(): string {
    return getBundleParams().stateServiceUrl
}
