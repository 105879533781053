
import { FC, useCallback } from "react"
import { Button } from "@tm/controls"
import { useLocalization } from "@tm/localization"
import { ConfigParams, IMicros } from "@tm/models"
import Morpheus from "@tm/morpheus"
import { SmartClientHelper } from "../sc-helper"


type Props = IMicros["standalone"]["sc-add-rt-to-basket"] & {

}

const AddRtToSCBasketComponent: FC<Props> = ({ service, sourceId }) => {
    const { languageId, translateText } = useLocalization()
    const { deleteButtonColorError } = Morpheus.getParams<ConfigParams>()
    
    const handleClick = useCallback(() => {
        SmartClientHelper.addRepairTimesToSCBasket(service, sourceId)
    }, [service])

    return (
        <Button
            className="kva-button"
            layout={["bordered"]}
            skin={false || deleteButtonColorError ? "danger": "highlight"}
            title={translateText(916)}
            onClick={handleClick}
            icon={false ? "delete" : languageId == "1" ? "voucher-kva" : "voucher-kva-international"}
        />
    )
}

export default AddRtToSCBasketComponent
