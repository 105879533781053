import Morpheus from "@tm/morpheus"
import { createSelector } from "reselect"

export type BundleParams = {
    serviceUrl: string
}

const bundleParamsMapper = createSelector(
    (x: BundleParams) => x,
    (params: BundleParams) => ({
        ...params,
    })
)

export const getBundleParams = (): BundleParams => {
    const bundleParams = bundleParamsMapper(Morpheus.getParams("dutch-order-plates"))

    if (!bundleParams) {
        // eslint-disable-next-line no-console
        console.warn(`The toolkit params have to be set in order to use the bundle "dutch-order-plates"`)
    }
    return bundleParams
}
