import { Box, Button, Icon, Loader, Popover, Typography } from "@tm/components"
import { Article } from "@tm/models"
import { useLocalization } from "@tm/localization"
import { Suspense, useState } from "react"
import PartnerSystemsPopoverTableComponent from "./PartnerSystemsPopoverTable"

type Props = {
    article: Article
    anchorEl: HTMLElement | null
    closePopup(): void
}

export default function PartnerSystemsPopover(props: Props) {
    const { translateText } = useLocalization()
    const isOpen = props.anchorEl !== null
    const [isPopoverHidden, setIsPopoverHidden] = useState(false)

    return (
        <Suspense fallback={<Loader />}>
            <Popover
                open={isOpen}
                onClose={props.closePopup}
                anchorEl={props.anchorEl}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                hidden={isPopoverHidden}
            >
                <Box ml={2} mt={1} mr={1}>
                    <Typography fontSize={20} sx={{ display: "inline" }}>
                        {translateText(13516)}
                    </Typography>
                    <Button variant="text" sx={{ float: "right" }} onClick={props.closePopup} startIcon={<Icon name="close" />} />
                </Box>
                <Box p={2}>
                    <PartnerSystemsPopoverTableComponent article={props.article} onMultiplePartnerSystemsPopoverClosed={setIsPopoverHidden} />
                </Box>
            </Popover>
        </Suspense>
    )
}
