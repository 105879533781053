import { stylesheet } from "typestyle"

export function getComponentStyles() {
    return stylesheet({
        detailsButton: {
            marginBottom: "0.4rem",
        },
        chatsWrapper: {
            width: "40rem",
            maxHeight: "60vh",
            overflowY: "auto",
        },
    })
}
