import { useCallback } from "react"
import { useWorkTask } from "@tm/context-distribution"
import { SearchFilters } from "@tm/models"
import { encodeUniqueId, renderRoute } from "@tm/utils"
import Morpheus from "@tm/morpheus"
import { usePartsRoutes } from "./usePartsRoutes"

export function useHandleStartDirectSearch() {
    const { workTaskId } = useWorkTask() ?? {}
    const routes = usePartsRoutes()

    return useCallback(
        (query: string, searchFilter: SearchFilters) => {
            if (!workTaskId) {
                return
            }
            if (routes.directSearch?.list) {
                const url = renderRoute(routes.directSearch?.list, { workTaskId: encodeUniqueId(workTaskId) })
                const searchParams = new URLSearchParams()
                searchParams.append("query", query)
                searchParams.append("searchFilter", String(searchFilter))
                Morpheus.getHistory().push(`${url}?${searchParams.toString()}`)
            }
        },
        [routes, workTaskId]
    )
}
