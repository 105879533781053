import { useLocalization } from "@tm/localization"
import { Text, Icon, Image } from "@tm/controls"

export default function Slide4Component() {
    const { translateText } = useLocalization()

    return (
        <div className="slider__content slide-3">
            <div className="headline">{translateText(575)}</div>
            <div>
                <div className="rating">
                    <Text>3/5</Text>
                    <Image
                        className="chart"
                        url="https://media.carparts-cat.com/webdata1/ActionsData/OffersModuleData/Pictures/driving-analysis.svg"
                    />
                </div>
                <div className="description">
                    <div className="chart">
                        <Icon name="pulse" size="xl" />
                    </div>
                    <Text>{translateText(582)}</Text>
                </div>
            </div>
        </div>
    )
}
