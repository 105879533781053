import {
    BikeModel,
    CarModel,
    RegistrationNoType,
    TruckModel,
    VehicleLookupConfig,
    VehicleSearchType,
    VehicleShortInfo,
    VehicleType,
} from "@tm/models"
import { RefObject } from "react"
import { getBundleParams } from "../../utils"
import { VehicleSearchV2 } from "./VehicleSearchFieldV2/VehicleSearchV2"
import { VehicleSearchV1 } from "./VehicleSearchV1"

export type VehicleSearchfieldV1Props = {
    vehicleType: VehicleType
    inputFieldRef?: RefObject<HTMLDivElement>
    initialValue?: string
    onStartSearch(query: string, regNoType?: RegistrationNoType, selectedVehicleLookupConfig?: VehicleLookupConfig): void
    onUniqueVehicleFound?(model: CarModel | BikeModel | TruckModel | VehicleShortInfo, query: string): void
    onAttachVehicleId?(vehicleId: string, query: string): void
    disable?: boolean
    showVrcScanButton?: boolean
    placeholderPassengerCar?: string
    disableEdsSearch?: boolean
    hideTooltip?: boolean
    size?: "small" | "medium"
    isOnIndustryDashboard?: boolean
    hideSpecialLicensePlateSearchField?: boolean
    specialLicensePlatesProps?: {
        registrationNoType?: number
        placeholder?: string
        searchType?: VehicleSearchType
        size?: "xs" | "s" | "m" | "l" | "xl"
        className?: string
        onlyNumbersAndDigits?: boolean
        showSpecialLicensePlateMicro?: boolean
        onVehicleAttached?: () => void
    }
}

export function VehicleSearchFieldSwitch(props: VehicleSearchfieldV1Props) {
    const { simpleVehicleSearch } = getBundleParams()

    if (simpleVehicleSearch) {
        return <VehicleSearchV2 {...props} />
    }
    return <VehicleSearchV1 {...props} />
}
