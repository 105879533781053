import { Container } from "@tm/nexus"
import { AddCalculatedCostsRequest, RegisteredModels } from "@tm/models"
import { PostMessageRequest } from "../../../data"
import { PostMessageControllerComponent } from "../component"

export default function handleAddRapidCalculatedCostsRequest(this: PostMessageControllerComponent, data: PostMessageRequest, source: Window) {
    const { addRapidCalculatedCostsRequest } = data

    if (addRapidCalculatedCostsRequest) {
        const { calculatedCostType, creationDate, hasCausedCosts, moduleId, details } = addRapidCalculatedCostsRequest

        const request: AddCalculatedCostsRequest = {
            calculatedCostType,
            creationDate,
            hasCausedCosts,
            moduleId,
            details,
        }

        Container.getInstance(RegisteredModels.RepairShop_AddCalculatedCosts)
            .subscribe(request)
            .load()
            .catch(() => console.warn("AddCalculatedCosts failed", calculatedCostType, moduleId))
    }
}
