import { Typography } from "@tm/components"
import { useLocalization } from "@tm/localization"
import { Price } from "@tm/models"
import { RowStack } from "../../../../../../../../_shared/StyledComponents"

type Props = {
    retailTotal?: Price
}

export default function OrderTotal({ retailTotal }: Props) {
    const { translateText, currency } = useLocalization()

    if (!retailTotal) {
        return null
    }
    return (
        <RowStack textAlign="right" justifyContent="space-between" columnGap={4}>
            <RowStack alignItems="baseline" spacing={0.5}>
                <Typography>{translateText(854)}</Typography>
                <Typography variant="label">{translateText(694)}</Typography>
            </RowStack>
            <Typography textAlign="right" fontWeight="bold">
                {retailTotal ? currency(retailTotal.value, retailTotal.currencySymbol || retailTotal.currencySymbol) : "-"}
            </Typography>
        </RowStack>
    )
}
