import { Suspense } from "react"
import { useLocalization } from "@tm/localization"
import { IMicros } from "@tm/models"
import { Button, Icon, Loader } from "@tm/components"
import { useErpInfo } from "../../_helpers/useErpInfo"
import { ErpWrapper } from "../../../_shared/ErpWrapper"

type Props = IMicros["erp"]["erp-info-teccom"]

function ErpInfoTeccomComponent(props: Props) {
    const { translateText } = useLocalization()
    const { loading, erpInfo } = useErpInfo(props.data, "list")

    if (loading) {
        return <Loader size="extrasmall" />
    }

    if (!erpInfo?.isTeccomRequestAvailable) {
        return null
    }

    return (
        <Button onClick={props.onClick} startIcon={<Icon name="extended-delivery-request" />}>
            {translateText(1080)}
        </Button>
    )
}

export default function Wrapper(props: Props) {
    return (
        <ErpWrapper {...props}>
            <div className="tk-erp">
                <div className="erp-info-teccom">
                    <Suspense fallback={null}>
                        <ErpInfoTeccomComponent {...props} />
                    </Suspense>
                </div>
            </div>
        </ErpWrapper>
    )
}
