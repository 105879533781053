import { ajax } from "@tm/utils"
import { useCallback } from "react"
import { atom, selector, useRecoilState } from "recoil"
import { chatAuthorizationRecoilSelector, useChatAuthorization } from "."
import { getBundleParams } from "../../utils"

const chatOwnDisplayNameRecoilAtom = atom({
    key: "notifications_chat_own_displayname_atom",
    default: selector({
        key: "notifications_chat_own_displayname_selector",
        get: async ({ get }) => {
            const authorization = get(chatAuthorizationRecoilSelector)
            if (authorization) {
                const url = `${getBundleParams().chatApiUrl}/api/v1/users/getdisplayname`
                const response = await ajax<{ displayName: string }>({ url, method: "GET", authorization })
                return response?.displayName
            }
        },
    }),
})

export function useOwnDisplayName() {
    const [displayName, setDisplayName] = useRecoilState(chatOwnDisplayNameRecoilAtom)
    const authorization = useChatAuthorization()

    const updateDisplayName = useCallback(
        (name: string) => {
            if (authorization) {
                const url = `${getBundleParams().chatApiUrl}/api/v1/users/rename`
                const body = { name }
                setDisplayName(name)
                return ajax<{ displayName: string }>({ url, body, method: "POST", authorization })
            }
        },
        [setDisplayName, authorization]
    )

    return { displayName, updateDisplayName }
}
