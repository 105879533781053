import { Card, Icon, Headline, Button, Text } from "@tm/controls"
import { useLocalization } from "@tm/localization"

export function DemoMessage(props: { onActivate(): void }) {
    const { translate } = useLocalization()

    function handleClickActivation() {
        props.onActivate()
    }
    return (
        <Card className="demo">
            <Icon name="calendar" />
            <Headline size="xs">{translate(1634) /** Terminplaner testen? */}</Headline>
            <Text size="s" modifiers={["block"]}>
                {translate(1635) /** Testen Sie jetzt alle Vorteile der Terminplaner App kostenlos für 30 Tage. */}
            </Text>
            <Button icon="add" skin="highlight" onClick={handleClickActivation} />
        </Card>
    )
}
