import { IBundle } from "@tm/morpheus"
import Main from "./components/main"
import Widget from "./components/widget"
import {initCarmunicationBundle, version} from "./utils"

const bundle: IBundle = {
    ...version,
    components: [
        Main,
        Widget
    ],
    init: initCarmunicationBundle
}

export default bundle