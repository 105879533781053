import { RegisteredModels, VehicleContainer, VehicleSearchType } from "@tm/models"
import { Container } from "@tm/nexus"
import { useQuery } from "react-query"
import { MergedVehicle } from "../../../../../../data/hooks"

export function useVehicleById(tmVehicleId: string | undefined) {
    const { data, isLoading } = useQuery(
        ["Toolkits.Notifications_useVehicleSearch_by-id", tmVehicleId],
        () => (Container.getInstance(RegisteredModels.Vehicle) as VehicleContainer).subscribe(tmVehicleId).load(),
        {
            enabled: !!tmVehicleId,
            cacheTime: 0,
        }
    )

    return { vehicleById: data, vehicleByIdLoading: isLoading }
}

export function useVehiclesByQuery({ vehicleInfo }: MergedVehicle, enabled: boolean) {
    const query = vehicleInfo.vin || vehicleInfo.plateId
    const { data, isLoading } = useQuery(
        ["Toolkits.Notifications_useVehicleSearch_by-query", query],
        () =>
            query
                ? (Container.getInstance(RegisteredModels.Vehicle) as VehicleContainer).action("findVehicles")({
                      query,
                      searchType: VehicleSearchType.PlateIdOrVin,
                  })
                : undefined,
        {
            enabled: !!query && enabled,
            select: (data) => data?.vehicles,
            cacheTime: 0,
        }
    )

    return { vehiclesByQuery: data, vehiclesByQueryLoading: isLoading }
}
