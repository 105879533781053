import { Box, LinkButton, Loader, Tooltip } from "@tm/components"
import { useCisCustomer } from "@tm/context-distribution"
import { renderRoute, RouteComponentProps, withRouter } from "@tm/utils"
import WarehouseInfoComponent from "../_shared/customer/WarehouseInfo"
import WarehouseOpeningTimesComponent from "../_shared/customer/WarehouseOpeningTimes"
import { getBundleParams } from "../../utils"
import { VerticalDivider } from "../_shared/StyledComponents"

type Props = RouteComponentProps<{ workTaskId?: string }> & {}

function WarehouseSummaryComponent({ match }: Props) {
    const { cisCustomer, cisCustomerLoading } = useCisCustomer()
    const detailsUrl = match.params.workTaskId
        ? renderRoute(getBundleParams().summaryUrl, { ...match.params, workTaskId: match.params.workTaskId })
        : ""

    if (cisCustomerLoading) {
        return <Loader size="extrasmall" />
    }

    const defaultWarehouse = cisCustomer?.salesOutlets?.find((salesOutlet) => salesOutlet.isDefault)

    if (!defaultWarehouse) {
        return null
    }

    return (
        <Box display="flex" py={0.5}>
            <VerticalDivider />
            {defaultWarehouse.address || defaultWarehouse.name || defaultWarehouse.description || defaultWarehouse.electronicAddresses ? (
                <Tooltip
                    title={
                        <Box display="flex" flexDirection="column">
                            <WarehouseInfoComponent warehouse={defaultWarehouse} warehouseHeaderId={1089} lightMode />
                            <Box mt={2}>
                                <WarehouseOpeningTimesComponent
                                    businnessTimesMode="shopHoursToday"
                                    warehouse={defaultWarehouse}
                                    lightMode
                                    showNotes
                                />
                            </Box>
                        </Box>
                    }
                >
                    <Box alignSelf="center" ml={1}>
                        <LinkButton to={detailsUrl} variant="text">
                            {defaultWarehouse.id}
                        </LinkButton>
                    </Box>
                </Tooltip>
            ) : (
                <Box alignSelf="center" ml={1}>
                    <LinkButton to={detailsUrl} variant="text">
                        {defaultWarehouse.id}
                    </LinkButton>
                </Box>
            )}
        </Box>
    )
}

export default withRouter(WarehouseSummaryComponent)
