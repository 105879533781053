import * as React from "react"
import { IWithLocalization } from "@tm/localization"
import { WorkTaskListItem } from "./components/search/business/model"

export function removeBrackets(value?: string): string | undefined {
    if (!value) {
        return
    }
    const regex = /^(.*?)(?:\(.*?\))?$/
    const match = regex.exec(value)
    if (!match) {
        return value
    }
    return match[1]
}

export function getNavigationSplitPosition(
    el: HTMLDivElement,
    offsetEl: HTMLElement | undefined | null,
    hasIndustryModuleTabs?: boolean
): { pos: number; width: number } {
    const element = hasIndustryModuleTabs ? (el.childNodes[0]?.childNodes[0]?.childNodes[0] as HTMLDivElement) : el
    const parent = el.parentElement
    if ((hasIndustryModuleTabs ? element.children.length <= 1 : !element.children.length) || !parent) {
        return { pos: -1, width: 0 }
    }
    const parentStyle = window.getComputedStyle(parent)
    const parentWidth = parseFloat(parentStyle.width || "") - (offsetEl ? offsetEl.clientWidth : 0)
    if (parentWidth > element.clientWidth) {
        return { pos: hasIndustryModuleTabs ? element.children.length - 1 : element.children.length, width: element.clientWidth }
    }
    let itemsWidth = 0
    let i = 0
    for (i; i < element.children.length; i++) {
        const item = element.children[i] as HTMLElement
        const lastWidth = itemsWidth
        itemsWidth += getElementWidth(item)
        if (parentWidth < itemsWidth) {
            return { pos: hasIndustryModuleTabs ? i - 1 : i, width: lastWidth }
        }
    }
    return { pos: hasIndustryModuleTabs ? i : i + 1, width: itemsWidth }
}

export function getElementWidth(el: HTMLElement) {
    const style: any = window.getComputedStyle(el)
    let width = parseFloat(style.width)
    width += parseFloat(style.marginLeft) + parseFloat(style.marginRight)
    return width
}

type WorkTaskLabelling = { title: React.ReactNode; info?: React.ReactNode }

export function getWorkTaskLabellingFromListItem(item: WorkTaskListItem, localization: IWithLocalization): WorkTaskLabelling {
    const { translate } = localization
    if (item.customerDisplayName) {
        return { title: item.customerDisplayName, info: item.vehicleDisplayName }
    }
    if (item.vehicleDisplayName) {
        return { title: item.vehicleDisplayName }
    }
    return { title: translate(29) }
}
