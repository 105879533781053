import { useUser } from "@tm/context-distribution"
import { VoucherType } from "@tm/models"

export function useDefaultVoucherType() {
    const { userContext } = useUser() ?? {}

    const { showVoucherKVA, showVoucherOrder, showVoucherReturn } = userContext.parameter

    if (showVoucherKVA) {
        return VoucherType.CostEstimation
    }

    if (showVoucherOrder) {
        return VoucherType.Order
    }

    if (showVoucherReturn) {
        return VoucherType.Return
    }
}
