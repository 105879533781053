import SpecialIcons from "../../special-icons"

export function PromotionalIcon(props: { value?: string }) {
    const { value } = props
    return (
        <SpecialIcons
            icons={[
                {
                    topmotiveId: "sale",
                    useIconFromUrl: false,
                    url: "",
                    description: value,
                },
            ]}
        />
    )
}
