import { BundleComponent } from "@tm/morpheus"
import component from "./HistoryWrapper"
import { withStoreProvider } from "../../helpers"

const managerComponent: BundleComponent = {
    name: "main",
    component: withStoreProvider(component as any),
}

export default managerComponent
